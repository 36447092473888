<mat-card>
    <mat-card-title>
        <span [translate]="'admin.parametres.demat.pieceJointes.param.title' + pieceJointeParam.idPortee"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="row">
            <custom-input
                    customType="checkbox"
                    ngDefaultControl
                    [name]="'demandeAvance' + index"
                    [ngModel]="pieceJointeParam.actif"
                    [(customModel)]="pieceJointeParam.actif"
                    libelle="admin.parametres.demat.pieceJointes.param.activer"
                    postLibelle="admin.parametres.demat.pieceJointes.param.activerPost"
                    (ngModelChange)="onActifChange()"
            ></custom-input>
            <custom-input
                    customType="number"
                    ngDefaultControl
                    [name]="'taille'+ index"
                    [(ngModel)]="pieceJointeParam.tailleMax"
                    [libelle]="getTailleLibelle()"
                    suffix="Ko"
                    [disabled]="!pieceJointeParam.actif"
                    [min]="0" [max]="tailleMax"
            ></custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    customType="select"
                    [(ngModel)]="typeOption"
                    [selectOptions]="listeOptionActif"
                    libelle="admin.parametres.demat.pieceJointes.param.type"
                    [name]="'type' + index"
                    optionDisplay="libelle"
                    optionValue="type"
                    (onChange)="onTypeChange()"
                    [disabled]="!pieceJointeParam.actif"
            ></custom-input>
            <custom-input
                    ngDefaultControl
                    customType="select"
                    [(ngModel)]="libelleOption"
                    [selectOptions]="listeOptionActif"
                    libelle="admin.parametres.demat.pieceJointes.param.libelle"
                    [name]="'libelle' + index"
                    optionDisplay="libelle"
                    optionValue="type"
                    (onChange)="onLibelleChange()"
                    [disabled]="!pieceJointeParam.actif"
            ></custom-input>
        </div>
        <div class="row" *ngIf="isShowDisponible()">
            <custom-input
                    ngDefaultControl
                    customType="select"
                    [(ngModel)]="pieceJointeParam.modeGestion"
                    [selectOptions]="getDisponibleOption()"
                    libelle="admin.parametres.demat.pieceJointes.param.disponible"
                    [name]="'disponible' + index"
                    optionDisplay="libelle"
                    optionValue="type"
                    [disabled]="!pieceJointeParam.actif"
            ></custom-input>
        </div>
    </mat-card-content>
</mat-card>