/**
 * Enumération des types de fonction
 */
export enum TypeFonction {
	AUCUNE = "AUCUNE",
	DATE_TODAY = "DATE_TODAY",
	DATE_TODAY_NOW = "DATE_TODAY_NOW",
	DATE_WEEK = "DATE_WEEK",
	DATE_MONTH = "DATE_MONTH",
	DATE_QUARTER = "DATE_QUARTER",
	DATE_YEAR = "DATE_YEAR",
	DATE_DAY = "DATE_DAY",
	DATE_WEEKDAY = "DATE_WEEKDAY"
}

/**
 * Fonctions de l'énum
 */
export namespace TypeFonction {
	/**
	 * Renvoie la clé de traduction d'un TypeFonction
	 *
	 * @param fonction la fonction
	 */
	export function getTradKey(fonction: TypeFonction): string {
		return `rule.typeFonction.${fonction}`;
	}

	/**
	 * Renvoie la clé de traduction pour une durée de période glissante d'un TypeFonction
	 *
	 * @param fonction la fonction
	 */
	export function getPeriodTradKey(fonction: TypeFonction): string {
		return `rule.datePeriod.${fonction}`;
	}
}