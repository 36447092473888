import {Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EMPTY, Observable, Subject, Subscription} from 'rxjs';
import { ListView } from '@domain/common/list-view';
import { IntegrationMesFrais } from '@domain/ndf/integration-mes-frais';
import { ListeMesFrais } from '@domain/ndf/liste-mes-frais';
import { NdfFraisAddMesFraisItemComponent } from './ndf-frais-add-mes-frais-item/ndf-frais-add-mes-frais-item.component';
import { NdfFraisAddMesFraisService } from './ndf-frais-add-mes-frais.service';

/**
 * Composant d'ajout de mes frais
 */
@Component({
	host: { "data-test-id": "app-ndf-frais-add-frais" },
	selector: "ndf-frais-add-frais",
	templateUrl: "./ndf-frais-add-mes-frais.component.html",
})
export class NDFFraisAddMesFraisComponent implements OnInit, DoCheck, OnDestroy {
	/** Liste mes frais */
  	liste: ListView<ListeMesFrais, NdfFraisAddMesFraisItemComponent>;

  	/** Input idNdf */
	@Input() idNDF: number;

	/** Emetteur qui indique si la liste est vide */
	@Output() onListeEmpty: EventEmitter<boolean> = new EventEmitter<boolean>();

	/** Evenement qui déclenche le rafraichissement de la liste */
	eventRefresh: Subject<void> = new Subject<void>();

	/** Liste des souscriptions */
    listeSouscriptions: Array<Subscription> = new Array<Subscription>();

  	constructor(private mesFraisService: NdfFraisAddMesFraisService) {}

  	ngOnInit(): void {
		//Mise en place du refresh
		this.listeSouscriptions.push(
			this.eventRefresh.subscribe(() => {
				this.liste.refresh();
			})
		);

		//Définition de la liste
		this.liste = new ListView<ListeMesFrais, NdfFraisAddMesFraisItemComponent>({
			uri: `/controller/NDF/listeMesFrais/${this.idNDF}`,
			component: NdfFraisAddMesFraisItemComponent,
			isFilter: false,
			onRefresh: (liste: ListView<ListeMesFrais, NdfFraisAddMesFraisItemComponent>) => {					
				//On envoie l'event pour indiquer si la liste n'est pas vide
				this.onListeEmpty.emit(liste.data.listeResultats.length == 0);
			},
			extraOptions: {
				eventRefresh: this.eventRefresh
			}
		});
	}

	/**
	 * Evènement de selection de la liste mes frais
	 */
	select(): Observable<IntegrationMesFrais> {
		const selectedlisteMesFrais = this.liste.data.listeResultats.filter(f => f.isSelected);

		if (selectedlisteMesFrais.length > 0) {
			//Si une case est coché on link le frais
			return this.mesFraisService.linkFrais(this.idNDF, selectedlisteMesFrais);
		};
		
		return EMPTY;
	}

	/**
	 * Contrôle si la liste est cochée
	 */
	ngDoCheck() {
		if (this.liste?.data?.listeResultats != null) {
			//On regarde si un élément de la liste a été coché, si c'est pas le cas on considère que la liste est vide au niveau du comportement
			this.onListeEmpty.emit(!this.liste.data.listeResultats.some(d => d.isSelected));
		}
	}

	/**
	 * Unsubscribe du subject
	 */
	ngOnDestroy(): void {
		//Suppression des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
	}
}
