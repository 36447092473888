import {User} from "@domain/user/user";
import {Unite} from "@domain/prestation/unite";
import {Origine} from "@domain/geolocalisation/localisation";

/**
 * Géographie - Distance
 */
export class Distance {
    idDistance: number;
    idDepart: number;
    idVilleDepart?: number;
    idIataDepart?: number;
    idArrivee: number;
    idVilleArrivee?: number;
    idIataArrivee?: number;
    idUser?: number;
    user?: User;
    distance: number;
    origine: Origine;
    idUnite: number;
    unite?: Unite;
    strDepart: string;
    strArrivee: string;
    libelle?: string;

    constructor(dto?: any) {
        if (dto) {
            Object.assign(this,dto);
        }
    }
}