<!-- Popin de la liste des participants (od) / invités (dépense) -->
<h1 mat-dialog-title>
    <span [translate]="data.contexte == 'NDF_V' ? 'ndf.frais.invites' : 'od.voyage.voyage'"></span>
    <page-header #pageHeader [isSquareTab]="true" [listeItems]="listeTabs" (onSelectedItemChange)="onSelectedItemChange($event)">
    </page-header>
    <span class="actions" [mat-dialog-close]>
        <span><i class="zmdi zmdi-close"></i></span>
    </span>
</h1>
<!-- Onglet d'ajout des participants -->
<div mat-dialog-content *ngIf="selectedTab.code == 'PARTICIPANTS'">
    <participants-popin-interne *ngIf="data.canModifier"
                                [idObjet]="data.idObjet"
                                [idNDF]="data.idNDF"
                                [idPrestation]="data.idPrestation"
                                [contexte]="data.contexte"
                                (onParticipantCree)="ajouterParticipant($event)"></participants-popin-interne>

    <!-- Liste des participants à ajouter à la mission -->
    <list-view [liste]="listeParticipantsCreation" [hidden]="selectedTab.code == 'CREATION'"></list-view>
</div>

<!-- Bouton d'ajout des participants à la mission -->
<div mat-dialog-actions [hidden]="selectedTab.code == 'CREATION'">
    <button *ngIf="data.canModifier" mat-flat-button color="primary" [disabled]="listeParticipantsCreation?.data?.listeResultats?.length < 1 && data.contexte == 'OD'" (click)="save()"><span translate>global.actions.valider</span></button>
</div>

<!-- Onglet de création d'un participant externe -->
<div *ngIf="canAddParticipantExterne()" [hidden]="selectedTab.code != 'CREATION'">
    <participants-popin-externe [contexte]="data.contexte" [settings]="data.settings" [idTypeEntite]="data.idTypeEntite" [idCollab]="data.idUser" [participantExterne]="data.participant?.participantExterne"
                                          (onParticipantCree)="ajouterParticipant($event)"></participants-popin-externe>
</div>
