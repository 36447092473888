import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";

/**
 * Service de gestion de la licence de l'entreprise
 */
@Injectable()
export class LicenceService {
    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) {
    };

    /**
     * Récupération de la licence
     */
    loadLicence(): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Licence/consultLicence`, []).pipe(first());
    };
}