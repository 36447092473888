<h1 mat-dialog-title>
    <span translate>report.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="listview">
    <div class="listview-body">
        <div class="listview-item" *ngFor="let report of listeReports | report">
            <div class="lvi-content">
                <div class="avatar"><i class="nio icon-reporting"></i></div>
                <div class="lvi-body">
                    <div class="title">
                        <a [routerLink]="" (click)="executeReport(report.idReport)">{{ report.libelle }}</a>
                    </div>
                    <ul class="lvi-attrs">
                        <li *ngIf="report.groupeDefini !== 0 || report.groupe != null">
                            <strong translate="report.categorie"></strong>
                            <span>{{ report.groupeDefini !== 0 ? report.libelleGroupe : report.groupe.libelle }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
