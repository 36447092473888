import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {FraisAgence} from "@domain/admin/voyages/frais-agence";
import {VoyagesFraisAgenceService} from "@services/admin/voyages/voyages-frais-agence.service";
import {first} from 'rxjs/operators';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ModifyFraisAgencePopinComponent} from "@components/admin/voyages/frais-agence/modify-frais-agence-popin/modify-frais-agence-popin.component";

/**
 * Composant d'un item de la liste des frais d'agence
 */
@Component({
	host: {'data-test-id': 'frais-agence-list-item'},
	templateUrl: './frais-agence-list-item.component.html'
})
export class FraisAgenceListItemComponent extends ListViewItem<FraisAgence> {
	/**
	 * Constructeur
	 *
	 * @param matDialog le gestionnaire de popin
	 * @param fraisAgenceService le service de gestion des frais d'agences
	 * @param toastrService le toaster
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private matDialog: MatDialog,
		private fraisAgenceService: VoyagesFraisAgenceService,
		private toastrService: ToastrService,
		private translateService: TranslateService
	) {
		super();
	}

	/**
	 * Ouverture du frais d'agence
	 */
	openFrais(): void {
		//Ouverture de la popin de modification d'un frais d'agence
		this.matDialog.open(ModifyFraisAgencePopinComponent,{
			data: {fraisAgence: this.data}
		}).afterClosed().subscribe((fraisAgence: FraisAgence) => {
			//Vérification de la présence d'un frais d'agence
			if (fraisAgence) {
				//Sauvegarde
				this.saveFrais(fraisAgence);
			}
		});
	}

	/**
	 * Sauvegarde d'un frais d'agence
	 *
	 * @param fraisAgence le frais d'agence
	 */
	saveFrais(fraisAgence: FraisAgence): void {
		//Appel au service
		this.fraisAgenceService.saveFraisAgence(fraisAgence)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat de l'appel
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast success
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Rafraîchissement de la liste
					this.liste.refresh();
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}
