import {TranslateService} from "@ngx-translate/core";
import {AutocompleteOptions} from "../autocomplete";
import {Filter,TypeComparaison} from '@domain/common/list-view';
import {StatutLineItemComponent} from "@share/component/autocomplete/options/statut-line-item.component";
import {StatutSearchItemComponent} from "@share/component/autocomplete/options/statut-search-item.component";
import {EnumEtat} from "@domain/workflow/etat";

/**
 * Options des statuts
 */
export default class StatutOptions implements AutocompleteOptions {
	/**
	 * Affichage du composant dans l'autocomplete
	 *
	 * @param item Elément à afficher
	 */
	displayItem(item: any): any {
		return (item?.libelle || "");
	}

	/**
	 * Composant affiché dans l'autocomplete
	 */
	lineItemComponent() {
		return StatutLineItemComponent;
	}

	/**
	 * Composant affiché dans le search
	 */
	searchItemComponent() {
		return StatutSearchItemComponent;
	}

	/**
	 * Les filtres de l'autocomplete
	 *
	 * @param translateService Service de traduction
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		return [{
			title : translateService.instant('admin.ongletOutils.cadreMouvementWf.popup.libelle'),
			clef: 'libelle',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: translateService.instant('admin.ongletOutils.cadreMouvementWf.popup.code'),
			clef: 'code',
			isDefault: true,
			typeComparaison: TypeComparaison[TypeComparaison.LIKE]
		},{
			title: translateService.instant('admin.ongletOutils.cadreMouvementWf.popup.etat'),
			clef: 'idEtat',
			typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
			listeValues: [
				{
					code: '' + EnumEtat.ANNULE,
					libelle: translateService.instant('global.etats.annule')
				},{
					code: '' + EnumEtat.ARCHIVE,
					libelle: translateService.instant('global.etats.archive')
				},{
					code: '' + EnumEtat.COMPTABILISE_CLOTURE,
					libelle: translateService.instant('global.etats.comptabilise_cloture')
				},{
					code: '' + EnumEtat.EN_CREATION,
					libelle: translateService.instant('global.etats.creation')
				},{
					code: '' + EnumEtat.EN_VALIDATION,
					libelle: translateService.instant('global.etats.validation')
				},{
					code: '' + EnumEtat.VALIDE,
					libelle: translateService.instant('global.etats.valide')
				}
			]
		}];
	}

	/**
	 * Récupération de l'url
	 */
	url(): string {
		return `/controller/Statut/findAllStatuts`;
	}

}
