<form #form="ngForm">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.comptabilite.comptesBancaires.generalites.comptes.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset *ngIf="configCompte">
                <div class="row">
                    <custom-input
                        ngDefaultControl
                        id="isActif"
                        name="isActif"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.comptes.form.gestionCompte"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.comptes.form.gestionCompteInfo"
                        [postTooltip]="'admin.comptabilite.comptesBancaires.generalites.comptes.form.gestionCompteTooltip' | translate"
                        [ngModel]="configCompte.actif"
                        [(customModel)]="configCompte.actif">
                    </custom-input>
                    <custom-input
                        ngDefaultControl
                        id="autre"
                        name="autre"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.comptes.form.autres"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.comptes.form.autresInfo"
                        [ngModel]="configCompte.autre"
                        [(customModel)]="configCompte.autre">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                        ngDefaultControl
                        id="iban"
                        name="iban"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.comptes.form.iban"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.comptes.form.ibanInfo"
                        [ngModel]="configCompte.iban"
                        [(customModel)]="configCompte.iban">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.comptabilite.comptesBancaires.generalites.cartes.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset *ngIf="configCompte">
                <div class="row">
                    <custom-input
                        ngDefaultControl
                        id="carteEntreprise"
                        name="carteEntreprise"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.carteEntreprise"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.carteEntrepriseInfo"
                        [ngModel]="configCompte.cartePaiement"
                        [(customModel)]="configCompte.cartePaiement"
                        (ngModelChange)="onCarteEntrepriseChange()">
                    </custom-input>
                    <custom-input
                        ngDefaultControl
                        id="carteDebitCollab"
                        name="carteDebitCollab"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.carteDebitCollab"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.carteDebitCollabInfo"
                        [ngModel]="configCompte.carteAffaireDebitCollab"
                        [(customModel)]="configCompte.carteAffaireDebitCollab"
                        [disabled]="!configCompte.cartePaiement">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                        ngDefaultControl
                        id="gestionCartes"
                        name="gestionCartes"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.gestionCartes"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.gestionCartesInfo"
                        [ngModel]="configCompte.cartePersoPaiementCollaborateur"
                        [(customModel)]="configCompte.cartePersoPaiementCollaborateur"
                        [disabled]="!configCompte.cartePaiement">
                    </custom-input>
                    <custom-input
                        ngDefaultControl
                        id="carteDebitEnt"
                        name="carteDebitEnt"
                        customType="checkbox"
                        libelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.carteDebitEnt"
                        postLibelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.carteDebitEntInfo"
                        [ngModel]="configCompte.carteAffaireDebitEntrep"
                        [(customModel)]="configCompte.carteAffaireDebitEntrep"
                        [disabled]="!configCompte.cartePaiement"
                        (ngModelChange)="onCarteAffaireDebitEntrepChange()">
                    </custom-input>
                </div>
                <div class="row" *ngIf="listeCategories.length > 0">
                    <custom-input
                            ngDefaultControl
                            id="restrictionsSaisie"
                            name="restrictionsSaisie"
                            customType="multiselect"
                            libelle="admin.comptabilite.comptesBancaires.generalites.cartes.form.restrictionsSaisie"
                            [(ngModel)]="listeCategoriesSelected"
                            [selectOptions]="listeCategories"
                            optionValue="id"
                            optionDisplay="libelle"
                            (ngModelChange)="onSelectedCategoriesChange()"
                            [disabled]="!configCompte.cartePaiement || !configCompte.carteAffaireDebitEntrep">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</form>
<floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>