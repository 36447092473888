<label *ngIf="libelle" [ngClass]="['col-md-' + lCol + (lClass ? ' ' + lClass : '')]" [class.required]="required" [class.two-lines]="twoLineLabel" [class.has-error]="hasError()" [class.text-muted]="disabled" [matTooltip]="errorMessage"
       [class.text-secondary]="disabled" [class.align-items-start]="customType == 'textarea'" [class.p-t-15]="customType == 'textarea'">
    <span [translate]="libelle"></span>
</label>
<div [ngClass]="'col-md-' + rCol + (rClass ? ' ' + rClass : '') + ' ' + (customType === 'checkbox' ? 'd-flex flex-row align-items-center' : '')" [class.has-error]="hasError()" [matTooltip]="errorMessage">
    <!-- Autocomplete -->
    <ng-template [ngIf]="customType == 'autocomplete'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <mat-label *ngIf="!placeholder && !!matLabelHtml"><ng-container [ngTemplateOutlet]="matLabelHtml"></ng-container></mat-label>
                <input matInput name="field" class="mr-1 mat-input-disabled" [placeholder]="placeholder" [value]="(!!model && !!model[optionDisplay]) ? model[optionDisplay] : displayIfNull" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <!-- Modification interdite -->
            <ng-template [ngIf]="disabled">
                <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                    <mat-label *ngIf="autocompleteLabel">{{ autocompleteLabel }}</mat-label>
                    <mat-label class="float-label-extra" *ngIf="matLabelHtml != null"><ng-container [ngTemplateOutlet]="matLabelHtml"></ng-container></mat-label>
                    <input matInput name="field" class="mr-1 text-secondary text-dark" [placeholder]="placeholder"
                           [value]="(!!model && !!model[optionDisplay]) ? model[optionDisplay] : displayIfNull"
                           [nioTooltip]="fieldTooltipNio?.content"
                           [nioTooltipOptions]="fieldTooltipNio?.options"
                           disabled/>
                </mat-form-field>
            </ng-template>
            <!-- Modification autorisée -->
            <ng-template [ngIf]="!disabled">
                <div class="r-flex">
                    <autocomplete ngDefaultControl #autocomplete="ngModel" name="autocomplete" class="flex-grow-1"
                                  [type]="autocompleteType" [filter]="autocompleteFilter" [fromCustomInput]="true"
                                  [(ngModel)]="model" [required]="required" [placeholder]="placeholder"
                                  [floatLabel]="floatLabel" [label]="autocompleteLabel" [labelExtra]="matLabelHtml"
                                  [reinitListeObs]="reinitListeObs" [isSearchWhenNoFilter]="isSearchWhenNoFilter"
                                  [matError]="matError"
                                  (onSelect)="onChange.emit()"
                                  [nioTooltip]="fieldTooltipNio?.content"
                                  [nioTooltipOptions]="fieldTooltipNio?.options"
                    ></autocomplete>
                    <mat-icon class="mb-3 align-self-center material-icons-outlined" *ngIf="postTooltip" matSuffix [nioTooltip]="postTooltip">info</mat-icon>
                </div>
            </ng-template>
        </ng-template>
    </ng-template>

    <!-- Input -->
    <ng-template [ngIf]="customType == 'input'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <input matInput name="field" class="mr-1 mat-input-disabled" [ngClass]="{'primary-text-color': isDisableUsePrimaryColor}"
                       [ngModel]="model"
                       [nioTooltip]="fieldTooltipNio?.content"
                       [nioTooltipOptions]="fieldTooltipNio?.options"
                       disabled/>
                <mat-icon *ngIf="postTooltipNio" matSuffix class="material-icons-outlined" [class.clickable]="postTooltipNio.onClick != null" [ngClass]="postTooltipNio.tooltipClass ? postTooltipNio.tooltipClass : null"
                          [nioTooltip]="postTooltipNio.content"
                          [nioTooltipOptions]="postTooltipNio.options"
                          (click)="postTooltipNio.onClick && postTooltipNio.onClick()">{{ postTooltipNio.icon || 'info' }}</mat-icon>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                <input matInput #inputElement name="field" class="mr-1 text-secondary text-dark" [(ngModel)]="model" [placeholder]="placeholder" [disabled]="disabled" [required]="required" [maxlength]="maxlength"
                       (change)="onChange.emit()" (focus)="onFocus.emit()" (blur)="onBlur.emit()" autocomplete="off" aria-autocomplete="none"
                       [nioTooltip]="fieldTooltipNio?.content"
                       [nioTooltipOptions]="fieldTooltipNio?.options" />
                <mat-icon class="material-icons-outlined" *ngIf="postTooltip" [matTooltip]="postTooltip">info</mat-icon>
                <mat-icon *ngIf="postTooltipNio" matSuffix class="material-icons-outlined" [class.clickable]="postTooltipNio.onClick != null" [ngClass]="postTooltipNio.tooltipClass ? postTooltipNio.tooltipClass : null"
                          [nioTooltip]="postTooltipNio.content"
                          [nioTooltipOptions]="postTooltipNio.options"
                          (click)="postTooltipNio.onClick && postTooltipNio.onClick()">{{ postTooltipNio.icon || 'info' }}</mat-icon>
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Input Nombre -->
    <ng-template [ngIf]="customType == 'number'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <mat-label *ngIf="placeholder != null && floatLabel === 'always'">{{ placeholder }}</mat-label>
                <input matInput name="field" class="mr-1 mat-input-readonly" [ngModel]="model" [nNumber]="nbDecimales" disabled/>
                <span matSuffix *ngIf="suffix && !isSuffixTemplate">{{ suffix }}</span>
                <ng-container matSuffix *ngIf="suffix && isSuffixTemplate" [ngTemplateOutlet]="$any(suffix)"></ng-container>
                <i *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip" matTooltipPosition="right" class="material-icons-outlined ml-2">info</i>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                <mat-icon *ngIf="suffixIcon" [ngClass]="suffixIconClass ? suffixIconClass : ''">{{suffixIcon}}</mat-icon>
                <input matInput name="field" class="mr-1  text-secondary text-dark" [ngClass]="{ 'mt-2': suffixIcon}" [(ngModel)]="model" [placeholder]="placeholder" [disabled]="disabled"
                       [required]="required"
                       (change)="onChange.emit(ngModel.value)" (focus)="onFocus.emit()" (blur)="onBlur.emit()" [min]="min" [max]="max" [nNumber]="nbDecimales" autocomplete="off"/>
                <span matSuffix *ngIf="suffix && !isSuffixTemplate">{{ suffix }}</span>
                <ng-container matSuffix *ngIf="suffix && isSuffixTemplate" [ngTemplateOutlet]="$any(suffix)"></ng-container>
                <mat-icon class="material-icons-outlined" *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip">info</mat-icon>
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Input Distance -->
    <ng-template [ngIf]="customType == 'distance'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <input matInput name="field" class="mr-1 mat-input-readonly" [ngModel]="model" [nNumber]="nbDecimales" disabled/>
                <span matSuffix *ngIf="suffix && !isSuffixTemplate">{{ suffix }}</span>
                <ng-container matSuffix *ngIf="suffix && isSuffixTemplate" [ngTemplateOutlet]="$any(suffix)"></ng-container>
                <span *ngIf="selectedUnite">{{ selectedUnite.libelle }}</span>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <div class="row">
                <mat-form-field class="col-md-6" [floatLabel]="floatLabel">
                    <mat-icon *ngIf="suffixIcon" [ngClass]="suffixIconClass ? suffixIconClass : ''">{{suffixIcon}}</mat-icon>
                    <input matInput name="field" class="mr-1 text-secondary text-dark" [(ngModel)]="model" [placeholder]="placeholder" [disabled]="disabled" [required]="required"
                           (change)="onChange.emit(ngModel.value)" (focus)="onFocus.emit()" (blur)="onBlur.emit()"
                           [min]="min" [max]="max" [nNumber]="nbDecimales" autocomplete="off"/>
                    <span matSuffix *ngIf="suffix && !isSuffixTemplate">{{ suffix }}</span>
                    <ng-container matSuffix *ngIf="suffix && isSuffixTemplate" [ngTemplateOutlet]="$any(suffix)"></ng-container>
                </mat-form-field>
                <mat-form-field class="col-md-6">
                    <span *ngIf="selectedUnite && !listeUnites">{{ selectedUnite.libelle }}</span>
                    <mat-select [hidden]="!listeUnites" name="selectUnite" [(ngModel)]="selectedUnite" [disabled]="disabled" (selectionChange)="onUniteChange.emit($event.value)" [required]="required">
                        <mat-option *ngFor="let unite of listeUnites" [value]="unite">{{ unite.libelle }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
    </ng-template>

    <!-- Input obfusqué -->
    <ng-template [ngIf]="customType == 'input-obfuscated' || customType == 'iban-obfuscated'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <input matInput name="field" class="mr-1 mat-input-disabled" [ngModel]="model" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <mat-form-field class="d-flex" [class.mat-form-field-invalid]="hasError() && inputObfuscated?.control?.touched" [floatLabel]="floatLabel">
                <input #inputObfuscated="ngModel" matInput name="field" class="mr-1 text-secondary text-dark" [(ngModel)]="model" [placeholder]="placeholder" [disabled]="disabled" [required]="required" [maxlength]="maxlength"
                       (change)="onModelChanged()" (focus)="onModelFocused()" (blur)="onModelBlured()"
                       (ngModelChange)="customType == 'iban-obfuscated' ? onIbanChanged($event) : null"
                       autocomplete="off"/>
                <mat-icon [hidden]="!canReadClear || isDisplayClear" (mousedown)="changeVisibilite()" #visibilityToggle matSuffix class="c-primary" style="cursor:pointer;">visibility</mat-icon>
                <mat-icon [hidden]="!canReadClear || !isDisplayClear" (mouseup)="switchModelObfusque(modelBackup, false)" (mouseleave)="switchModelObfusque(modelBackup, false)" #visibilityOffToggle matSuffix class="c-primary" style="cursor:pointer;">visibility_off</mat-icon>
                <mat-icon *ngIf="!required" matSuffix style="cursor:pointer;" (click)="razModel();$event.stopPropagation();">backspace</mat-icon>
                <mat-icon class="material-icons-outlined" *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip">info</mat-icon>
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Date -->
    <ng-template [ngIf]="customType == 'date'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <input matInput name="field" class="mr-1 mat-input-disabled" [ngModel]="customModel | date:'shortDate'" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <!-- Modification interdite -->
            <ng-template [ngIf]="disabled">
                <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                    <input matInput name="field" class="mr-1 text-secondary text-dark" [ngModel]="customModel | date:'shortDate'" disabled/>
                </mat-form-field>
            </ng-template>
            <!-- Modification autorisée -->
            <ng-template [ngIf]="!disabled">
                <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                    <input name="date" #date="ngModel" matInput [matDatepicker]="datePicker" [placeholder]="placeholder" [min]="getdateMin()" [max]="getdateMax()"
                           [required]="required" [(ngModel)]="customModel" (dateChange)="onChange.emit(); dateChangeCheck($event)" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker [startAt]="dateStart"></mat-datepicker>
                </mat-form-field>
            </ng-template>
        </ng-template>
    </ng-template>

    <!-- Date Range -->
    <ng-template [ngIf]="customType === 'daterange'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <input matInput name="field" class="mr-1 mat-input-disabled" [ngModel]="customModel | date:'shortDate'" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification interdite -->
        <ng-template [ngIf]="disabled">
            <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                <mat-label *ngIf="floatLabel==='always'">{{ placeholder }}</mat-label>
                <input matInput name="field" class="mr-1 text-secondary text-dark" [ngModel]="customModel | date:'shortDate'" disabled/>
            </mat-form-field>
        </ng-template>

        <!-- Modification possible -->
        <date-range-custom #dateRange *ngIf="!readonly && !disabled" [label]="placeholder" [required]="required" [floatLabel]="floatLabel" [disabled]="disabled"
                           [rangeDeb]="rangeDeb" [rangeEnd]="rangeEnd" [dateStart]="dateStart" [(maDate)]="customModel"
                           (dateChange)="dateChangeCheck({value: $event}); onChange.emit();"></date-range-custom>
    </ng-template>

    <!-- Select -->
    <ng-template [ngIf]="customType == 'select'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <mat-icon class="material-icons-outlined" *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip">info</mat-icon>
                <input matInput name="field" class="mr-1 mat-input-disabled" [ngClass]="{'primary-text-color': isDisableUsePrimaryColor}"
                       [ngModel]="selectReadonlyValue | translate" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                <mat-icon class="material-icons-outlined" *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip">info</mat-icon>
                <mat-select name="select" [(ngModel)]="model" [disabled]="disabled" [placeholder]="placeholder || ('global.input.choisissez' | translate)" (selectionChange)="selectChange($event);onChange.emit()" [required]="required">
                    <mat-select-trigger *ngIf="optionSelected != undefined && optionDisplay != undefined && optionIcone != undefined">
                        <span class="pr-1" [innerHTML]="optionSelected[optionIcone] ? optionSelected[optionIcone] : optionIconePlaceholder"></span>
                        <span>{{ (isTranslated ? optionSelected[optionDisplay] : (optionSelected[optionDisplay] | translate)) }}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let option of selectOptions" [value]="optionValue ? option[optionValue] : option">
                        <span *ngIf="optionIcone != undefined" class="pr-1" [innerHTML]="option[optionIcone] ? option[optionIcone] : optionIconePlaceholder"></span>
                        <span>{{ isTranslated ? (optionDisplay ? option[optionDisplay] : option) : (optionDisplay ? option[optionDisplay] : option) | translate }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Objectselect -->
    <ng-template [ngIf]="customType == 'objectselect'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <mat-icon class="material-icons-outlined" *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip">info</mat-icon>
                <mat-icon class="material-icons-outlined" *ngIf="postTooltipNio" matSuffix [nioTooltip]="postTooltipNio.content" [nioTooltipOptions]="postTooltipNio.options"
                          [ngClass]="postTooltipNio.tooltipClass ? postTooltipNio.tooltipClass : null">{{ postTooltipNio.icon || 'info' }}</mat-icon>
                <input matInput name="field" class="mr-1 mat-input-disabled" [ngModel]="selectReadonlyValue" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <div class="r-flex align-items-center">
                <mat-form-field class="d-flex flex-grow-1" [floatLabel]="floatLabel">
                    <mat-icon class="material-icons-outlined" *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip">info</mat-icon>
                    <mat-select #objectselect="matSelect" (closed)="removeSelectPlaceHolder()" name="select" [ngModel]="model" [(value)]="model" [compareWith]="compareSelect" [placeholder]="placeholder || ('global.input.choisissez' | translate)"
                                [disabled]="disabled" (selectionChange)="onChange.emit($event.value);selectChange($event)" [required]="required">
                        <!--Affichage du libelle lorsque le select est fermé-->
                        <mat-select-trigger *ngIf="isSelectAvecInput && objetTampon && objetTampon[optionDisplay]">
                            {{ objetTampon[optionDisplay] }}
                        </mat-select-trigger>
                        <!--Option vide-->
                        <mat-option *ngIf="isSelectAvecVide">
                            {{ 'global.input.choisissez' | translate }}
                        </mat-option>
                        <!--Options de la liste d'options-->
                    <mat-option *ngFor="let option of selectOptions" [value]="option" [disabled]="option['isSectionTitle']" [class.font-italic]="option['isSectionTitle']">
                        <span [class.ml-4]="option['isSectionItem']">{{ (isTranslated ? option[optionDisplay] : (option[optionDisplay] | translate)) }}</span>
                        </mat-option>
                        <!--Option avec Input-->
                        <mat-option [value]="objetTampon" *ngIf="isSelectAvecInput">
                            <mat-form-field class="d-flex" floatLabel="never">
                                <input matInput [placeholder]="'liste.recherche.saisirValeur' | translate" name="inputTampon" class="mr-1 text-secondary text-dark"
                                       [(ngModel)]="objetTampon[optionDisplay]" [maxlength]="maxlength"
                                       [disabled]="disabled" (click)="$event.stopPropagation();" (keydown)="stopFauxInput($event);">
                                <mat-icon class="c-primary" *ngIf="objetTampon.libelle">done</mat-icon>
                            </mat-form-field>
                        </mat-option>
                    </mat-select>
                    <!-- Libelle d'erreur -->
                    <mat-error>
                        {{ matError?.error }}
                    </mat-error>
                </mat-form-field>
                <mat-icon *ngIf="postTooltipNio" class="material-icons-outlined" [class.clickable]="postTooltipNio.onClick != null" [ngClass]="postTooltipNio.tooltipClass ? postTooltipNio.tooltipClass : null"
                          [nioTooltip]="postTooltipNio.content"
                          [nioTooltipOptions]="postTooltipNio.options"
                          (click)="postTooltipNio.onClick && postTooltipNio.onClick()">{{ postTooltipNio.icon || 'info' }}</mat-icon>
            </div>
        </ng-template>
    </ng-template>

    <!-- Multi Select -->
    <ng-template [ngIf]="customType == 'multiselect'">
        <mat-form-field class="d-flex" [floatLabel]="floatLabel">
            <mat-select (closed)="removeInputVide()" name="multiselect" [(ngModel)]="model" [compareWith]="compareSelect" [placeholder]="placeholder || ('global.input.choisissez' | translate)"
                        [disabled]="disabled"
                        (selectionChange)="onChange.emit();selectChange($event)" [required]="required" multiple>
                <!--Affichage du libelle lorsque le select est fermé-->
                <mat-select-trigger [class.mat-input-disabled]="readonly">
                    <!--Affichage si l'input est sélectionné-->
                    <ng-container *ngIf="isSelectAvecInput && objetTampon && objetTampon[optionDisplay]">
                        {{ objetTampon[optionDisplay] }}
                    </ng-container>
                    <!-- Si on n'affiche pas de valeur dans mat-select-trigger la hauteur de l'élement n'est pas statique.
                        C'est le cas quand on coche le custominput sans libelle avant de le saisir.
                        On continue à afficher une valeur pour éviter cet effet indésirable  -->
                    <ng-container *ngIf="isSelectAvecInput && model.length > 0 && !model[0].libelle">
                        {{'global.input.choisissez' | translate}}
                    </ng-container>
                    <!--Affichage si l'input n'est pas sélectionné-->
                    <ng-container *ngIf="!isSelectAvecInput || !objetTampon || !objetTampon[optionDisplay]">
                        {{ model && model.length > 0 ? model[0][optionDisplay] : '' }}
                    </ng-container>
                    <!--Affichage du (+X autres)-->
                    <span *ngIf="model?.length > 1">
                            (+{{model.length - 1}} {{(model?.length === 2 ? 'od.voyage.travel.autreMin' : 'od.voyage.travel.autres') | translate}})
                    </span>
                </mat-select-trigger>
                <!--Options de la liste d'options-->
                <mat-option *ngFor="let option of selectOptions" [value]="option" [disabled]="readonly || option['readonly']">
                    {{ (isTranslated ? option[optionDisplay] : (option[optionDisplay] | translate)) }}
                </mat-option>
                <!--Option avec Input-->
                <mat-option #matOption *ngIf="isSelectAvecInput" [value]="objetTampon" (click)="changeFocus()" [disabled]="readonly">
                    <mat-form-field class="d-flex" floatLabel="never" (click)="stopFauxInput($event);">
                        <input #inputTampon matInput [placeholder]="'liste.recherche.saisirValeur' | translate" name="inputTampon" class="mr-1 text-secondary text-dark"
                               [(ngModel)]="objetTampon[optionDisplay]" [maxlength]="maxlength"
                               [disabled]="disabled || readonly" (keydown)="stopFauxInput($event);" (click)="preventDeselect($event)">
                    </mat-form-field>
                </mat-option>
            </mat-select>
            <mat-icon class="material-icons-outlined" *ngIf="postTooltip" [matTooltip]="postTooltip">info</mat-icon>
        </mat-form-field>
    </ng-template>

    <!-- Montant -->
    <ng-template [ngIf]="customType == 'montant'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <mat-label *ngIf="floatLabel==='always'">{{ placeholder }}</mat-label>
                <input matInput name="montant" class="text-right mr-1 mat-input-disabled" nNumber="2" [ngModel]="model" disabled/>
                <span *ngIf="montantDevise" class="mat-input-disabled">{{ montantDevise }}</span>
                <mat-icon *ngIf="postTooltipNio" matSuffix class="material-icons-outlined" [class.clickable]="postTooltipNio.onClick != null" [ngClass]="postTooltipNio.tooltipClass ? postTooltipNio.tooltipClass : null"
                          [nioTooltip]="postTooltipNio.content"
                          [nioTooltipOptions]="postTooltipNio.options"
                          (click)="postTooltipNio.onClick && postTooltipNio.onClick()">{{ postTooltipNio.icon || 'info' }}</mat-icon>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <div class="row">
                <mat-form-field class="col-md-6" [floatLabel]="floatLabel">
                    <input matInput name="montant" class="text-right mr-1 text-secondary text-dark" [nNumber]="2" [(ngModel)]="model" [placeholder]="placeholder"
                           [disabled]="disabled" [required]="required" [notZero]="required"
                           (change)="onChange.emit()" (focus)="onFocus.emit()" (blur)="onBlur.emit()" autocomplete="off"/>
                </mat-form-field>
                <mat-form-field [ngClass]="{'cursor-default': montantDevise && !listeDevise}" class="col-md-6" *ngIf="montantDevise || listeDevise || postTooltipNio">
                    <span *ngIf="montantDevise && !listeDevise">{{ montantDevise }}</span>
                    <mat-select [hidden]="!listeDevise" name="devise" [ngClass]="{'text-secondary':disabled,'text-dark':disabled}" [(ngModel)]="montantDevise" [disabled]="disabled" (selectionChange)="onDeviseChange.emit($event.value)" [required]="required">
                        <mat-option *ngFor="let devise of listeDevise" [value]="devise.code">{{ devise.code }}</mat-option>
                    </mat-select>
                    <mat-icon *ngIf="postTooltipNio" matSuffix class="material-icons-outlined" [class.clickable]="postTooltipNio.onClick != null" [ngClass]="postTooltipNio.tooltipClass ? postTooltipNio.tooltipClass : null"
                              [nioTooltip]="postTooltipNio.content"
                              [nioTooltipOptions]="postTooltipNio.options"
                              (click)="postTooltipNio.onClick && postTooltipNio.onClick()">{{ postTooltipNio.icon || 'info' }}</mat-icon>
                </mat-form-field>
            </div>
        </ng-template>
    </ng-template>

    <!-- Textarea -->
    <ng-template [ngIf]="customType == 'textarea'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <mat-label *ngIf="placeholder">
                    {{ placeholder }}
                    <mat-icon *ngIf="labelTooltip" matSuffix class="material-icons-outlined" [ngClass]="{'clickable':labelTooltip.onClick != null}"
                              [nioTooltip]="labelTooltip.content"
                              [nioTooltipOptions]="labelTooltip.options"
                              (click)="labelTooltip.onClick && labelTooltip.onClick()">{{ labelTooltip.icon || 'info' }}</mat-icon>
                </mat-label>

                <textarea matInput class="mat-input-disabled" [rows]="areaRows" #area="ngModel" name="area" [ngModel]="model" disabled></textarea>
                <i *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip" matTooltipPosition="right" class="material-icons-outlined ml-2">info</i>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                <mat-label *ngIf="!!placeholder">{{ placeholder }}</mat-label>
                <mat-label *ngIf="!!labelTooltip" class="float-label-extra">
                    <mat-icon class="material-icons-outlined" [ngClass]="{'clickable':labelTooltip.onClick != null}"
                              [nioTooltip]="labelTooltip.content"
                              [nioTooltipOptions]="labelTooltip.options"
                              (click)="labelTooltip.onClick && labelTooltip.onClick()">{{ labelTooltip.icon || 'info' }}</mat-icon>
                </mat-label>

                <textarea matInput name="area" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" autocomplete="off"
                          [(ngModel)]="model"
                          [disabled]="disabled" [required]="required" [maxlength]="maxlength"
                          [nioTooltip]="fieldTooltipNio?.content" [nioTooltipOptions]="fieldTooltipNio?.options"
                          (change)="onChange.emit()"></textarea>
                <i *ngIf="postTooltip" matSuffix [matTooltip]="postTooltip" matTooltipPosition="right" class="material-icons-outlined ml-2">info</i>
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Heures -->
    <ng-template [ngIf]="customType == 'heure'">
        <!-- Lecture seule -->
        <ng-template [ngIf]="readonly">
            <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                <input matInput name="heure" type="time" class="mr-1 mat-input-disabled" [ngModel]="model" disabled/>
            </mat-form-field>
        </ng-template>
        <!-- Modification possible -->
        <ng-template [ngIf]="!readonly">
            <mat-form-field class="d-flex" [floatLabel]="floatLabel">
                <input matInput #heure="ngModel" type="time" name="heure" class="mr-1 text-secondary text-dark" [(ngModel)]="model" [placeholder]="placeholder" [disabled]="disabled"
                       [required]="required" (change)="onChange.emit()" autocomplete="off">
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Checkbox -->
    <ng-template [ngIf]="customType == 'checkbox'">
        <ng-template [ngIf]="floatLabel !== 'always'">
            <div class="r-flex align-items-center form-min-height">
                <mat-checkbox color="primary" name="checkbox" [(ngModel)]="customModel" [disabled]="disabled || readonly" [required]="required" (change)="onChange.emit();changeCheck($event)">
                    <span [hidden]="!postLibelle">{{ postLibelle | translate }}</span>
                </mat-checkbox>
                <span [hidden]="!postTooltip" [matTooltip]="postTooltip" matTooltipPosition="right" class="material-icons-outlined">info</span>
            </div>
        </ng-template>
        <ng-template [ngIf]="floatLabel === 'always'">
            <mat-form-field class="mat-form-field-checkbox" appearance="none" floatLabel="always">
                <mat-label *ngIf="!!placeholder">{{ placeholder }}</mat-label>
                <div class="r-flex align-items-center">
                    <mat-checkbox name="checkbox" color="primary"
                                  [(ngModel)]="customModel"
                                  (change)="onChange.emit();changeCheck($event)"
                                  [disabled]="disabled || readonly"
                                  [required]="required">
                        <span [hidden]="!postLibelle">{{ postLibelle | translate }}</span>
                    </mat-checkbox>
                    <span [hidden]="!postTooltip" [nioTooltip]="postTooltip" nioTooltipPosition="right" class="material-icons-outlined">info</span>
                    <mat-icon *ngIf="postTooltipNio" matSuffix class="material-icons-outlined" [ngClass]="{'clickable':postTooltipNio.onClick != null}"
                              [nioTooltip]="postTooltipNio.content"
                              [nioTooltipOptions]="postTooltipNio.options"
                              (click)="postTooltipNio.onClick && postTooltipNio.onClick()">{{ postTooltipNio.icon || 'info' }}</mat-icon>
                </div>
                <!-- Input invisible présent uniquement pour afficher le label flottant du mat-form-field car il ne fonctionne pas avec les mat-checkbox... -->
                <input matInput class="cdk-visually-hidden" name="dummy" />
            </mat-form-field>
        </ng-template>
    </ng-template>

    <!-- Radio -->
    <ng-template [ngIf]="customType == 'radio' && radioOptions?.length > 0">
        <mat-radio-group [name]="name" class="vertical" [(ngModel)]="customModel" [disabled]="disabled || readonly">
            <span class="radio-prefixe" *ngIf="radioPrefix" [translate]="radioPrefix"></span>
            <div *ngFor="let option of radioOptions">
                <mat-radio-button [value]="option.code">
                    {{ option.label }}
                </mat-radio-button>
                <mat-icon *ngIf="option.tooltip" [matTooltip]="option.tooltip" matTooltipPosition="right" inline="true" style="padding: 3px 8px">
                    info_outline
                </mat-icon>
            </div>
        </mat-radio-group>
    </ng-template>
</div>