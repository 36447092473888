import {TypeProfil} from "@domain/user/user";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Habilitations d'un utilisateur, regroupées par profil
 */
export class UserHabilitation implements ListItem {
	/** Le type de profil */
	fonction: TypeProfil;

	/** La liste des habilitations de ce type de profil */
	liensRoleUser: LienRoleUser[];

	/**
	 * Constructeur
	 *
	 * @param fonction le profil
	 * @param liensRoleUser les habilitations
	 */
	constructor(fonction: TypeProfil,liensRoleUser: LienRoleUser[]) {
		this.fonction = fonction;
		this.liensRoleUser = liensRoleUser;
	}

	getKey(): number {
		return this.fonction;
	}
}