import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from "@angular/router";

import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {ListeAvance} from '@domain/avance/liste-avance';
import {environment} from '@environments/environment';
import {AlerteComponent} from '../../../workflow/alerte.component';
import {TypeProfil} from '@domain/user/user';
import {Store} from '@ngrx/store';
import {AppState} from '@domain/appstate';
import {first} from "rxjs/operators";

@Component({
	host: {'data-test-id': 'avance-list-item'},
	templateUrl: './avance-list-item.component.html'
})
export class AvanceListItemComponent extends ListViewItem<ListeAvance> implements OnInit {
	/** Elément courant */
	@Input() data: ListeAvance;

	/** Profil de l'utilisateur connecté **/
	fonction: TypeProfil;

	/** URL de base */
	baseUrl: string = environment.baseUrl;

	/**
	 * Constructeur
	 */
    constructor(protected router: Router,protected store: Store<AppState>,protected matDialog: MatDialog) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Sélection du profil de l'utilisateur
		this.store.select(state => state?.session?.user?.fonction).pipe(first()).subscribe(fonction => this.fonction = fonction);
	}

	/**
	 * Ouverture d'un élément
	 */
	navigateTo(): void {
		//Navigation vers l'élément
		this.router.navigate(['Avance',this.data?.getId()]);
	}

	/**
	 * Ouverture d'un OD
	 */
	openOd(idOd: number): void {
		//Navigation vers l'élément
		this.router.navigate(['OD',idOd]);
	}

	/**
	 * Affichage de la popup de la liste des alertes
	 */
	showListeAlertes(): void {
		//Affichage de la popup de la liste des alertes
		this.matDialog.open(AlerteComponent,{
			data: {
				alertes: this.data.listeAlertes.listeAlertes
			}
		});
	}

	/** Getter pour le mode Collaborateur */
	get isCollabMode(): boolean {
		return this.fonction == TypeProfil.COLLABORATEUR;
	}
}
