<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.detail.metier.selectModeRembPopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <list-view *ngIf="listeModeRemb" [liste]="listeModeRemb"></list-view>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="collab">
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>