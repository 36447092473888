<div class="lvi-content">
    <div class="avatar">
        <mat-icon>domain</mat-icon>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.close(data)">{{ data.enveloppe.libelleEnveloppe }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong translate="admin.entreprise.analytique.enveloppes.reaffecter.dateDebut"></strong>
                {{ data.exercice.dateDebut | date:'shortDate' }}
            </li>
            <li><strong translate="admin.entreprise.analytique.enveloppes.reaffecter.dateFin"></strong>
                {{ data.exercice.dateFin | date:'shortDate' }}
            </li>
            <li><strong translate="admin.entreprise.analytique.enveloppes.reaffecter.montant"></strong>
                {{ getMontant() }}
            </li>
        </ul>
    </div>
</div>