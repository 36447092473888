<!-- Template du cadre -->
<mat-card *ngIf="engagements?.listeEngagement?.length > 0">
    <mat-card-title>
        <span>{{ title }}</span>
    </mat-card-title>
    <mat-card-content>
        <!-- Chargement en cours -->
        <please-wait *ngIf="isLoading"></please-wait>
        <!-- Affichage des données après chargement -->
        <div *ngIf="!isLoading" class="listview">
            <div class="listview-body">
                <div class="listview-item">
                    <!-- Ligne du total -->
                    <div class="row no-gutters clickable" (click)="toggleDetail();">
                        <!-- Total prévisionnel -->
                        <ng-container *ngTemplateOutlet="templatePrevisionnel; context:{engagement:engagements.total, isTotal:true}"></ng-container>
                        <!-- Total réel -->
                        <ng-container *ngTemplateOutlet="templateReel; context:{engagement:engagements.total, isTotal:true}"></ng-container>
                    </div>
                    <div *ngIf="isOuvert" class="lvi-content px-0 mr-0 ml-5">
                        <!-- Liste des détails des engagements -->
                        <div class="listview flex-grow-1">
                            <div class="listview-body">
                                <!-- Ligne de détail de l'engagement -->
                                <div class="listview-item row no-gutters px-0 mr-0" *ngFor="let engagement of engagements.listeEngagement">
                                    <!-- prévisionnel de l'engagement -->
                                    <ng-container *ngTemplateOutlet="templatePrevisionnel; context:{engagement:engagement}"></ng-container>
                                    <!-- Réel de l'engagement -->
                                    <ng-container *ngTemplateOutlet="templateReel; context:{engagement:engagement}"></ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<!-- Template par défaut du prévisionnel, peut être surchargé si fournit en entrée du composant -->
<ng-template #previsionnel let-engagement="engagement" let-isTotal="isTotal">
    <div class="lvi-content col-6">
        <ng-template *ngTemplateOutlet="templateAvatar;context:{engagement:engagement,isPrevisionnel:true,isTotal:isTotal}"></ng-template>
        <div class="lvi-body">
            <div class="title">
                <a>{{ engagement?.getTraduction(true) | translate }}</a>
            </div>
            <!-- Bulles -->
            <ul class="lvi-attrs">
                <li><strong [translate]="'od.engagements.total'"></strong>{{ engagement?.montantPrevisionnel | montant:settings?.deviseEntreprise }}</li>
            </ul>
        </div>
    </div>
</ng-template>

<!-- Template par défaut du réél, peut être surchargé si fournit en entrée du composant -->
<ng-template #reel let-engagement="engagement" let-isTotal=isTotal>
    <div class="lvi-content col-6" [ngClass]="{'pl-4':!isTotal}">
        <ng-template *ngTemplateOutlet="templateAvatar;context:{engagement:engagement,isPrevisionnel:false,isTotal:isTotal}"></ng-template>
        <div class="lvi-body">
            <div class="title">
                <a>{{ engagement?.getTraduction() | translate }}</a>
            </div>
            <!-- Bulles -->
            <ul class="lvi-attrs">
                <li><strong [translate]="'od.engagements.total'"></strong>{{ engagement?.montantReel | montant:settings?.deviseEntreprise }}</li>
                <li><strong [translate]="'od.engagements.ecart'"></strong>{{ engagement?.pourcentageEcart | number:'1.2-2' }} %</li>
            </ul>
        </div>
        <div class="lvi-actions" *ngIf="isTotal">
            <button mat-icon-button>
                <mat-icon *ngIf="isOuvert" color="primary">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!isOuvert" color="primary">keyboard_arrow_down</mat-icon>
            </button>
        </div>
    </div>
</ng-template>
