<h1 mat-dialog-title>
    <span translate>ndf.creation.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>

    <div *ngIf="!isLoading" class="listview">
        <div class="listview-body">
            <!-- Création d'une NDF non rattachée à une mission -->
            <div *ngIf="isTypeEntite" class="listview-item">
                <div class="lvi-content">
                    <div class="avatar" [translate]="'ndf.creation.horsMissionAvatar'"></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" (click)="selectNoMission()">{{ 'ndf.creation.horsMission' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Création d'une NDF rattachée à une mission de type od -->
            <div *ngIf="countOd > 0" class="listview-item">
                <div class="lvi-content">
                    <div class="avatar"><i class="nio align-middle icon-ordre_de_mission"></i></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" (click)="openListeMission(TypePortee.OD)">{{ 'ndf.creation.ordreDeMission' | translate }}</a>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'ndf.creation.nbMissionsDisponibles.' + (countOd > 1 ? 'plural':'one')"></strong>{{countOd}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Création d'une NDF rattachée à une mission de type omp -->
            <div *ngIf="countOt > 0" class="listview-item">
                <div class="lvi-content">
                    <div class="avatar"><i class="nio align-middle icon-ordre_de_mission_permanent"></i></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" (click)="openListeMission(TypePortee.OT)">{{ 'ndf.creation.ordreDeMissionPermanent' | translate }}</a>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'ndf.creation.nbMissionsDisponibles.' + (countOt > 1 ? 'plural':'one')"></strong>{{countOt}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Si aucune option disponible, affichage d'un message -->
            <div *ngIf="countOd == 0 && countOt == 0 && !isTypeEntite" class="text-center">
                {{ 'ndf.creation.aucuneMissionDisponible' | translate }}
            </div>
        </div>
    </div>
</div>
