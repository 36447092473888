import {Component} from "@angular/core";

/**
 * Sous-menu Populations du menu Entreprise
 *
 * @author Tom JEAMMET
 */
@Component({
    host: {'data-test-id': 'entreprise-populations'},
    templateUrl: './entreprise-populations.component.html'
})
export class EntreprisePopulationsComponent {
}