import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ZoneUtilisateur} from "@domain/zone-utilisateur/zone-utilisateur";
import {Router} from "@angular/router";
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Composant d'un item de la liste des zones paramétrables
 */
@Component({
	host: {'data-test-id': 'zone-parametrable-list-item'},
	templateUrl: './zone-parametrable-list-item.component.html'
})
export class ZoneParametrableListItemComponent extends ListViewItem<ZoneUtilisateur> {
	/**
	 * Constructeur
	 *
	 * @param router le routeur Angular
	 */
	constructor(
		private router: Router
	) {
		super();
	}

	/**
	 * Récupère l'icone de la portée de l'item courant
	 */
	getIcone(): string {
		return TypePortee.getIconClass(this.data.idPortee)
	}

	/**
	 * Clic sur la ZU courante
	 */
	onSelect(): void {
		//Navigation vers la ZU sélectionnée
		this.router.navigate(['Admin/Bibliotheque/ZoneUtilisateur',this.data.idZU])
	}
}
