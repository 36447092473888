import {Component, OnInit, ViewChild} from '@angular/core';
import {LoginService} from "@share/login/login.service";
import {Result} from "@domain/common/http/result";
import {NgForm, NgModel} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {first} from "rxjs/operators";

/**
 * Composant de changement du mdp utilisateur
 */
@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

    /** Étapes du changement de mot de passe */
    public step: 'CURRENT_PASSWORD' | 'NEW_PASSWORD' = 'CURRENT_PASSWORD';

    /** Chargement en cours */
    public isLoading: boolean = false;

    /** Données du changement de mot de passe **/
    public passwordChange: { oldPassword?: string, newPassword?: string, confirmPassword?: string, passwordParams?: any, error?: number | null, success: boolean } = {
        passwordParams: {
            minChiffre: 1,
            minLength: 8,
            minSpecial: 0,
            minMajuscule: 1,
            minMinuscule: 1
        }, success: false
    };

    /** Mot de passe actuel */
    public currentUserPassword: string;

    /** Modèle du champ de confirmation du mot de passe **/
    @ViewChild('confirmPassword')
    private confirmPassword: NgModel;

    /** Expressions régulières sur la politique de mot de passe **/
    public RGX_MIN_LENGTH: RegExp;
    public RGX_MIN_MINUSCULE: RegExp;
    public RGX_MIN_MAJUSCULE: RegExp;
    public RGX_MIN_CHIFFRE: RegExp;
    public RGX_MIN_SPECIAL: RegExp;
    public RGX_FULL_PASSWORD: RegExp;

    /**
     * Constructeur
     *
     * @param loginService service de gestion du login (et donc des mots de passe)
     * @param translateService service de traduction
     * @param toastr service de notification
     */
    constructor(private loginService: LoginService,
                private translateService: TranslateService,
                private toastr: ToastrService) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Définition des expressions régulières de mot de passe par défaut
        this.computePasswordPatterns();

        //Chargement de la politique de mot de passe
        this.loginService.loadPasswordParams().pipe(first()).subscribe((result: Result) => {
            //Vérification du code d'erreur
            if (result?.codeErreur == 0) {
                //Vérification de la politique de gestion des mots de passe
                if (result?.data?.passwordParams) {
                    //Mise à jour de la politique de gestion des mots de passe
                    this.passwordChange.passwordParams = result.data.passwordParams;
                }

                //Définition des expressions régulières de mot de passe (depuis la politique de mot de passe)
                this.computePasswordPatterns();
            }
        });
    }

    /**
     * Calcul des expressions régulières pour la validation du mot de passe
     */
    computePasswordPatterns() {
        const minLengthRgx: string = `(?=.{${this.passwordChange?.passwordParams?.minLength || 8},})`;
        const minMinusculeRgx: string = `(?=.*[a-z]{${this.passwordChange?.passwordParams?.minMinuscule || 0},})`;
        const minMajusculeRgx: string = `(?=.*[A-Z]{${this.passwordChange?.passwordParams?.minMajuscule || 0},})`;
        const minChiffreRgx: string = `(?=.*[0-9]{${this.passwordChange?.passwordParams?.minChiffre || 0},})`;
        const minSpecialRgx: string = `(?=.*\W{${this.passwordChange?.passwordParams?.minSpecial || 0},})`;

        //Définition des expressions régulières
        this.RGX_MIN_LENGTH = new RegExp(minLengthRgx);
        this.RGX_MIN_MINUSCULE = new RegExp(minMinusculeRgx);
        this.RGX_MIN_MAJUSCULE = new RegExp(minMajusculeRgx);
        this.RGX_MIN_CHIFFRE = new RegExp(minChiffreRgx);
        this.RGX_MIN_SPECIAL = new RegExp(minSpecialRgx);
        this.RGX_FULL_PASSWORD = new RegExp(minLengthRgx + minMinusculeRgx + minMajusculeRgx + minChiffreRgx + minSpecialRgx);
    }

    /**
     * Fonction appelée au clic sur le bouton confirmer
     */
    onSubmit(form: NgForm): void {
        //Chargement en cours
        this.isLoading = true;

        //Suivant l'étape du traitement
        if (this.step == 'CURRENT_PASSWORD') {
            //On vérifie que le mdp saisi est bien celui de l'utilisateur courant
            this.loginService.checkPassword(this.currentUserPassword).subscribe({
                next: (result: boolean) => {
                    if (result) {
                        //Passage à l'étape suivante
                        this.step = 'NEW_PASSWORD';

                        //Mémorisation du mot de passe actuel
                        this.passwordChange.oldPassword = this.currentUserPassword;

                        //Réinitialisation du formulaire
                        form.resetForm();
                    } else {
                        //Notification de l'erreur de mot de passe
                        this.toastr.error(this.translateService.instant('login.passwordChange.error.passwordError'));

                        //Réinitialisation du formulaire
                        form.resetForm();
                    }
                },
                error: () => {
                    //Fin de traitement
                    this.isLoading = false;
                },
                complete: () => {
                    //Fin de traitement
                    this.isLoading = false;
                }
            });
        } else if (this.step == 'NEW_PASSWORD') {
            if (this.passwordChange.newPassword != this.passwordChange.confirmPassword) {
                //Notification de mots de passe qui ne concordent pas
                this.toastr.error(this.translateService.instant('login.passwordChange.rule.confirmPasswordNoMatch'));

                //Ajout d'une erreur sur le champ
                this.confirmPassword.control.setErrors({ nomatch: true });

                //Fin de traitement
                this.isLoading = false;
            } else {
                //Changement de mot de passe
                this.loginService.changePassword(this.passwordChange.oldPassword,this.passwordChange.newPassword).subscribe({
                    next: (result: Result) => {
                        //Vérification du résultat
                        if (result?.codeErreur == 0) {
                            //Message succès
                            this.toastr.success(this.translateService.instant('login.passwordChange.success'));

                            //Réinitialisation des mots de passe
                            this.passwordChange.oldPassword = undefined;
                            this.passwordChange.newPassword = undefined;

                            //Retour à l'étape initiale et réinitialisation du formulaire
                            this.onCancel(form);
                        } else {
                            //Message d'erreur suivant le code erreur
                            if ([10,11,12].includes(result?.codeErreur)) {
                                this.toastr.error(this.translateService.instant('login.passwordChange.error.noRespectHistory'));
                            } else {
                                this.toastr.error(this.translateService.instant('login.passwordChange.error.other'));
                            }
                        }
                    },
                    error: () => {
                        //Fin de traitement
                        this.isLoading = false;
                    },
                    complete: () => {
                        //Fin de traitement
                        this.isLoading = false;
                    }
                });
            }
        }
    }

    /**
     * Fonction appelée au clic sur le bouton annuler
     */
    onCancel(form: NgForm): void {
        //Réinitialisation du formulaire
        this.currentUserPassword = '';
        form.resetForm();

        //Retour à la première étape
        this.step = 'CURRENT_PASSWORD';
    }
}
