import {Component,Input,OnInit,ViewChild} from "@angular/core";
import {Entreprise} from "@domain/admin/entreprise/entreprise";
import {Devise} from "@domain/settings/devise";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {SettingsGlobalState} from "@domain/settings/settings";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {AdresseComponent} from "@share/component/adresse/adresse.component";
import {Licence} from "@domain/admin/licence/licence";
import {ControlContainer,NgForm} from "@angular/forms";

/**
 * La partie informations de l'entreprise dans l'admin
 *
 * @author Alexandre Soulard
 * @date 14/10/2022
 */
@Component({
    host: {'data-test-id': 'description-generalites'},
    selector: 'description-generalites',
    templateUrl: './description-generalites.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DescriptionGeneralitesComponent implements OnInit{
    /** Entreprise présente dans la table ns_entrep, utilisée pour remplir les différents champs */
    @Input() entreprise: Entreprise;

    /** Licence de l'entreprise, contenue dans ns_licence */
    @Input() licence: Licence;

    /** Liste des différentes devises enregistrées */
    listeDevises: Devise[];

    /** Permet de récupérer l'input de l'adresse */
    @ViewChild(AdresseComponent) adresse: AdresseComponent;

    /**
     * Constructeur
     *
     * @param store Store qui permet de récupérer les settings global et de récupérer la liste des devises
     */
    constructor(
        private store: Store<AppState>
    ) {
    }

    /**
     * Appelée si l'utilisateur sort de l'input du SIRET, et permet de copier les 9 premiers caractères dans le champ SIREN si ce dernier est vide
     */
    changeFocus() {
        //Si le champ siren est vide alors on va copier les caractères du champ siret dans le champ siren
        if (this.entreprise.siren == null || this.entreprise.siren == "" ) {
            var siret = this.entreprise.siret;
            var length = siret.length;

            //Si la longueur du siret est supérieure à 9 caractères alors on découpe une sous-chaîne et on la met dans siren
            if (length >= 9) {
                this.entreprise.siren = siret.substring(0,9);
            } else { //Sinon on prend toute la chaîne de caractères
                this.entreprise.siren = siret.substring(0,length);
            }
        }
    };

    /**
     * Méthode appelée par l'input de l'adresse quand une adresse validée par la géolocalisation est sélectionnée
     *
     * @param event
     */
    onSelect(event) {
        //S'il y a un événement alors c'est que l'administrateur a rentré une nouvelle adresse validé par la géolocalisation
        if (event) {
            this.entreprise.adresse = event.adresse;
        }
    }

    /**
     * Méthode appelée lors du changement de l'input de l'adresse, afin de mettre à jour l'adresse de l'entreprise quand l'adresse entrée n'est pas
     * validée par la géolocalisation
     *
     * @param event
     */
    onAdresseChange(event) {
        //Assignation de la valeur du champ à l'adresse de l'entreprise
        this.entreprise.adresseModel = {adresse: event.target.value, libelle: event.target.value};
    }

    /**
     * Appelée lors de l'initialisation du composant
     */
    ngOnInit() {
        //Récupère toutes les devises enregistrées en base
        this.store.select(state => state.settings?.Global).pipe(filterFirstNotNull()).subscribe(
            (settings: SettingsGlobalState) => {
                this.listeDevises = settings.listeDevises;
            });
    }
}
