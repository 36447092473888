import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {ProfilService} from "../../../profil/profil.service";
import {MatRadioModule} from "@angular/material/radio";
import {DroitAdmin} from "@core/security/droit-admin";
import {FacturesRelevesComponent} from "./releves/factures-releves.component";
import {FacturesFournisseursComponent} from "./fournisseurs/factures-fournisseurs.component";
import {FacturesTypesChargesComponent} from "./types-charges/factures-types-charges.component";
import {FacturesTolerancesComponent} from "./tolerances/factures-tolerances.component";
import {FraisFacturesComponent} from "./frais-factures.component";
import {ReleveFactureModule} from "@components/facture/releve-facture.module";

export const fraisFacturesRoutes : Routes = [
    {
        path: 'Releves',
        component: FacturesRelevesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_RELEVES_FACTURES] }
    }, {
        path: 'Fournisseurs',
        component: FacturesFournisseursComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ENTREPRISE_FOURNISSEUR] }
    }, {
        path: 'TypesCharges',
        component: FacturesTypesChargesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_INTERFACE_REF] }
    }, {
        path: 'Tolerances',
        component: FacturesTolerancesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_GESTION_TOLERANCE] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Releves'
    }, {
        path: '**',
        redirectTo: 'Releves'
    }
];

@NgModule({
    imports: [ShareModule, PreviewAlerteModule, MatDividerModule, TranslateModule, MatRadioModule, ReleveFactureModule],
    declarations: [
        FraisFacturesComponent,FacturesRelevesComponent,FacturesFournisseursComponent,FacturesTypesChargesComponent,FacturesTolerancesComponent
    ],
    entryComponents: [],
    exports: [
        FacturesRelevesComponent,FacturesFournisseursComponent,FacturesTypesChargesComponent,FacturesTolerancesComponent
    ],
    providers: [ProfilService, LoginService]
})
export class FraisFacturesModule {
}
