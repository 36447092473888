<h1 mat-dialog-title>
    <span>{{ fraisAgence.libelleTransaction }}</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content *ngIf="fraisAgence">
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="offline"
                    name="offline"
                    customType="number"
                    [(ngModel)]="fraisAgence.montantOffline"
                    libelle="admin.voyages.fraisAgence.fraisAgenceTab.modifyFraisAgencePopin.offline"
                    [min]="0"
                    [nbDecimales]="2"
                    [suffix]="'EUR HT'">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="online"
                    name="online"
                    customType="number"
                    [(ngModel)]="fraisAgence.montantOnline"
                    libelle="admin.voyages.fraisAgence.fraisAgenceTab.modifyFraisAgencePopin.online"
                    [min]="0"
                    [nbDecimales]="2"
                    [suffix]="'EUR HT'">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="fraisAgence" [disabled]="form?.invalid"><span [translate]="'global.actions.enregistrer'"></span></button>
</div>