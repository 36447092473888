import { Input,EventEmitter,Output,Directive } from '@angular/core';

import { ListView } from './list-view';
import { ListItem } from './list-item';

/**
 * Interface représentant une liste
 */
@Directive()
export abstract class ListViewItem<T extends ListItem> {
    /** Données */
    @Input() public data: T;

    /** Référence à la liste */
    @Input() public liste: ListView<T,any>;

    /** Options supplémentaires */
    @Input() public extraOptions: any;

    /** Affichage/masquage de l'élément */
    @Input() public toggleItem: (data: T) => void;

    /** Demande de suppression de l'élément de la liste */
    @Output() removeFromList = new EventEmitter<T>();
}