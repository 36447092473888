import {Component} from '@angular/core';
import {AutocompleteSearchItemComponent} from "../../../autocomplete-search-item.component";
import {TranslateService} from "@ngx-translate/core";
import {OdPipe} from "@share/pipe/od.pipe";
import {Od} from "@domain/od/od";
import {DatePipe} from "@angular/common";
import {MontantPipe} from "@share/pipe/montant";

/**
 * Composant d'affichage d'une ligne de la liste des missions dans le cas de la correction des rejets de facture
 *
 * @author Laurent Convert
 * @date 11/07/2023
 */
@Component({
    host: {'data-test-id': 'import-facture-mission-search-item'},
    templateUrl: './import-facture-mission.search-item.html',
})
export class ImportFactureMissionSearchItem extends AutocompleteSearchItemComponent<Od> {

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param odPipe Pipe de formatage des OD
     */
    constructor(public translateService: TranslateService,public datePipe: DatePipe,public montantPipe: MontantPipe,private odPipe: OdPipe) {
        super(translateService,datePipe,montantPipe);
    }

    /**
     * Retourne une chaîne formaté pour afficher une mission textuellement
     *
     * @param mission La mission à afficher
     * @return La chaîne formaté à afficher
     */
    formatMission(mission: Od): string {
        return this.odPipe.transform(mission);
    }

}