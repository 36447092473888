<div class="lvi-content" (click)="this.extraOptions.selectType(data)">
    <div class="avatar">
        <span>{{ data.libelle | slice:0:3 }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.libelle }}</a>
        </div>
    </div>
</div>
