import {Component,Input,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {HistoriquePopulation,TypeNature} from "@domain/admin/entreprise/population/historique-population";
import {PopulationLogChange,PopulationLogChangeType} from "@domain/admin/entreprise/population/Population-log-change";
import {TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";

/**
 * Composant d'affichage de la page AdminHistoriqueItem
 *
 * @author Lionel Gimenez
 * @date 13/12/2023
 */
@Component({
    host: {'data-test-id': 'population-historique-item'},
    templateUrl: './population-historique-item.component.html'
})
export class PopulationHistoriqueItemComponent implements OnInit {
    /** Modèle de l'item */
    @Input() data: HistoriquePopulation;

    /** Id de la population */
    @Input() idPopulation: number;

    /** Indique si le chevron est ouvert */
    isChevronOpen: boolean = false;

    /** Déclaration pour le template */
    PopulationLogChangeType = PopulationLogChangeType;
    TypeLog = TypeNature;

    /** Constante pour optimiser le chargement de l'écran */
    videString: string = '(' + this.translateService.instant('workflow.historique.tag.value.chevron.vide') + ')';

    /**
     * Constructeur de la classe.
     *
     * @param translateService Le service de traduction utilisé dans la classe.
     */
    constructor(
        private translateService: TranslateService
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Si on a une liste population
        if (this.data.listePopulationLogChange != null) {
            //On filtre les clefs qui ne sont pas de type IDPOPULATION
            this.data.listePopulationLogChange = this.data.listePopulationLogChange.filter(log => log.clef != PopulationLogChangeType.IDPOPULATION)
        }
    }

    /**
     * Formate le log
     * @param populationLogChange le log courant
     * @return le log formaté
     */
    formatLog(populationLogChange: PopulationLogChange): string {
        const oldValue = populationLogChange.ancienneValeur
            ?? this.videString;
        const newValue = populationLogChange.nouvelleValeur
            ?? this.videString;

        return oldValue + ' -> ' + newValue;
    }

    /**
     * Retourne le titre de l'item
     * @return le titre de l'item
     */
    getTitle() {
        let title = this.data.reference;

        //Si la nature est un service ou un collaborateur
        if (this.data.nature === TypeNature.SERVICE || this.data.nature === TypeNature.COLLABORATEUR) {
            title += ' - ';
            title += this.data.typeLien === TypeLien.EXCLUS
                ? this.translateService.instant('workflow.historique.tag.exclus')
                : this.translateService.instant('workflow.historique.tag.inclus');

            //Si la nature est un service
            if (this.data.nature === TypeNature.SERVICE) {
                title += ' - ';
                title += this.data.dependance
                    ? this.translateService.instant('workflow.historique.tag.avecDependance')
                    : this.translateService.instant('workflow.historique.tag.sansDependance');

            }
        }

        return title;
    }

    /**
     * Indique si on a la possibité d'avoir une zone avec chevron
     * @return true si on a la possibité d'avoir une zone avec chevron
     */
    isWithChevron(): boolean {
        return this.data.nature === TypeNature.POPULATION
            && this.data.listePopulationLogChange != null
            && this.data.listePopulationLogChange.length > 0;
    }
}