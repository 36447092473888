<h1 mat-dialog-title>
    <i *ngIf="zoneHasType() && !data.zone?.idOmZone" class="nio icon-transfere clickable" (click)="goBack()"></i>
    <span>{{ 'omp.zone.ajout.title' | translate }}</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div [hidden]="zoneHasType()" class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let typeZone of listeTypeZone">
                <div class="lvi-content clickable" [routerLink]="" (click)="selectTypeZone(typeZone.id);">
                    <div class="avatar">{{ getAvatarForTypeZone(typeZone.id) }}</div>
                    <div class="title align-self-md-center">
                        <a>{{ getTitleForTypeZone(typeZone.id) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form autocomplete="off" [hidden]="!zoneHasType()" #zoneAddForm="ngForm">
        <fieldset [disabled]="!data?.canModifier">
            <div class="row" [ngClass]="{ 'has-error': geographie.invalid }">
                <label class="col-md-4 required"><span [translate]="getTitleForTypeZone(data?.zone?.type)"></span></label>
                <div class="col-md-8">
                    <autocomplete type="geographie" name="geographie" #autocompleteGeographie #geographie="ngModel"
                        (onSelect)="onGeographieChange($event)"
                        [filter]="{ listeTypes: getAutoCompleteFilter(data?.zone?.type), contexte: porteeGeographie.OM_PERMANENT }"
                        [isSearchWhenNoFilter]="data?.zone?.type == typeOmZone.TYPE_ZONE || data?.zone?.type == typeOmZone.TYPE_TERRITOIRE"
                        [(ngModel)]="selectedGeographie"
                        ngDefaultControl [label]="'global.input.rechercher' | translate"
                        required="true"
                        [disabled]="!data?.canModifier">
                    </autocomplete>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div [hidden]="!zoneHasType()" mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="deleteZone()" [disabled]="isSaving" *ngIf="data?.zone?.idOmZone">
        <span *ngIf="!isSaving" translate>global.actions.supprimer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="zoneAddForm.invalid || !selectedGeographie?.id || isSaving" (click)="saveZone()">
        <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
