import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {ParticipantExterne,TypeExterne} from "@domain/participant/participant-externe";
import {UtilisateursExternesListItemComponent} from "@components/admin/entreprise/utilisateurs/utilisateurs-externes-list/utilisateurs-externes-list-item/utilisateurs-externes-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {EditExternePopinComponent} from "@components/admin/entreprise/utilisateurs/edit-externe-popin/edit-externe-popin.component";
import {MatDialog} from "@angular/material/dialog";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

@Component({
	host: {'data-test-id': 'utilisateurs-externes'},
	templateUrl: './utilisateurs-externes.component.html'
})
export class UtilisateursExternesComponent implements OnInit {
	/** Liste des externes */
	listeExternes: ListView<ParticipantExterne,UtilisateursExternesListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Traitement en cours */
	isPending: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param matDialog le gestionnaire de popin
	 * @param translateService le moteur de traduction
	 * @param userService le service de gestion des utilisateurs
	 * @param toastrService le toaster
	 */
	constructor(
		private matDialog: MatDialog,
		private translateService: TranslateService,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des externes
		this.listeExternes = new ListView<ParticipantExterne,UtilisateursExternesListItemComponent>({
			uri: '/controller/Externe/getListeExternes',
			title: this.translateService.instant('admin.entreprise.utilisateurs.externes.title'),
			component: UtilisateursExternesListItemComponent,
			listeFilters: [
				{
					title: this.translateService.instant('admin.entreprise.utilisateurs.externes.nom'),
					clef: 'nom',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.externes.prenom'),
					clef: 'prenom',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.externes.origine'),
					clef: 'type',
					isDefault: false,
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: TypeExterne.ADMINISTRATEUR.toString(),
							libelle: this.translateService.instant('admin.entreprise.utilisateurs.externes.administrateur')
						},{
							code: TypeExterne.UTILISATEUR.toString(),
							libelle: this.translateService.instant('admin.entreprise.utilisateurs.externes.utilisateur')
						}
					]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.externes.societe'),
					clef: 'societe',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.externes.fonction'),
					clef: 'fonction',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.externes.email'),
					clef: 'email',
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				}
			],
			extraOptions: {
				open: (externe: ParticipantExterne) => this.open(externe)
			}
		});

		//Définition de la liste des actions
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-ajouter',
				libelle: 'global.actions.creer',
				doAction: () => this.createExterne()
			},{
				type: TypeAction.SECONDARY,
				icone: 'material-icons-outlined',
				iconeName: 'close_fullscreen',
				libelle: this.translateService.instant('admin.entreprise.utilisateurs.externes.editExternePopin.fusionner'),
				doAction: () => this.fusionner(),
				isVisible: () => this.listeExternes.nbSelectedItems > 0,
				isDisabled: () => this.listeExternes.nbSelectedItems !== 2
			}
		])
	}

	/**
	 * Création d'un externe
	 */
	createExterne(): void {
		let externe: ParticipantExterne = new ParticipantExterne();

		//Initialisation d'actif à true
		externe.actif = true;

		//Ouverture
		this.open(externe);
	}

	/**
	 * Ouverture d'un externe pour modification
	 */
	open(externe: ParticipantExterne,editionPostFusion?: boolean): void {
		//Ouverture de la popin
		this.matDialog.open(EditExternePopinComponent,{
			data: {
				externe: externe,
				editionPostFusion: editionPostFusion
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			if (refresh) {
				//Refresh de la liste
				this.listeExternes.refresh();
			}
		});
	}

	/**
	 * Fusion de plusieurs externes
	 */
	fusionner(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.userService.fusionnerExternes(this.listeExternes.listeObjetsSectionnes)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Refresh de la liste
					this.listeExternes.refresh();

					//Ouverture de l'externe nouvellement fusionné
					this.open(result.data.externe,true);
				} else {
					//Toast erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.utilisateurs.externes.editExternePopin.fusionEchec'))
				}
			});
	}
}
