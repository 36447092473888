import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Periode} from "@domain/admin/comptabilite/periode";
import {ComptabiliteExercicesPeriodesService} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Exercice} from "@domain/admin/comptabilite/exercice";
import {finalize,first} from "rxjs/operators";

/**
 * Popin d'édition d'une période
 *
 * @author Alexis BOUVARD
 * @date 24/04/2023
 */
@Component({
	host: {'data-test-id': 'periode-edit'},
	templateUrl: './periode-edit.component.html'
})
export class PeriodeEditComponent implements OnInit {
	/** Période */
	periode: Periode;

	/** Libellé initial de la période avant une possible modification */
	libelleInitial: string;

	/** Chargement en cours */
	isPending: boolean;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée
	 * @param comptabiliteExercicesPeriodesService le service de gestion des exercices et périodes
	 * @param toastrService le service du toaster
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la référence à la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { periode: Periode,exercice: Exercice },
		private comptabiliteExercicesPeriodesService: ComptabiliteExercicesPeriodesService,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		protected matDialogRef: MatDialogRef<PeriodeEditComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la période
		this.periode = Object.assign({},this.data.periode);

		//Initialisation du libellé
		this.libelleInitial = this.data.periode.libelle;
	}

	/**
	 * Sauvegarde de la période
	 */
	savePeriode() {
		//Début de l'enregistrement
		this.isPending = true;

		//On set l'exercice avant d'enregistrer
		this.periode.exercice = this.data.exercice;

		//Appel au service
		this.comptabiliteExercicesPeriodesService.savePeriode(this.periode)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//On regarde si l'enregistrement s'est bien déroulé
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast success
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popin
					this.matDialogRef.close(this.periode.libelle);
				} else {
					//Toast error
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			});
	}
}
