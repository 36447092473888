import {Component, Input} from "@angular/core";
import {RgpdParam} from "@domain/admin/entreprise/rgpd-param";

/**
 * Composant contenant le formulaire avec les différentes règles RGPD de l'entreprise
 */
@Component({
    host: {'data-test-id': 'rgpd'},
    selector: 'rgpd',
    templateUrl: './rgpd.component.html'
})
export class RgpdComponent {
    /** Ensemble des paramètres RGPD de l'entreprise */
    @Input() rgpdParams: RgpdParam;
}