import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage de la pageMontantReaffectation
 *
 * @author Lionel Gimenez
 * @date 13/10/2023
 */
@Component({
    host: {'data-test-id': 'montant-reaffectation'},
    selector: 'montant-reaffectation',
    templateUrl: './montant-reaffectation.component.html'
})
export class MontantReaffectationComponent implements OnInit {

    /** Indicateur de sauvegarde */
    isSaving = false;

    /** Modèle de la valeur du montant */
    montantValue;

    /** Modèle de l'autocomplete */
    montantCible: MontantDto;

    /**
     * Constructeur
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            montant: MontantDto
        },
        private enveloppeDetailService: EnveloppeDetailService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private matDialogRef: MatDialogRef<MontantReaffectationComponent>,
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
    }

    /**
     * Handler du bouton réaffecter
     */
    onReaffecter() {
        this.isSaving = true;

        this.enveloppeDetailService.reaffecter(this.data.montant.idMontant, this.montantCible.idMontant, this.montantValue)
            .pipe(first(),finalize(() => this.isSaving = false))
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        this.matDialogRef.close(true);
                    } else {
                        //Message d'erreur
                        TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Indique si le montant est invalide
     * @return boolean
     */
    isMontantInvalid(): boolean {
        //Si la valeur saisie est plus grande que le montant sélectionné
        return this.montantValue > this.data.montant.montant;
    }
}