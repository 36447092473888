import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {first} from "rxjs/operators";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";

/**
 * Composant de lancement manuel des migrations
 */
@Component({
    host: {'data-test-id': 'manual-update'},
    selector: 'manual-update',
    templateUrl: './manual-update.component.html'
})
export class ManualUpdateComponent {
    /** Vérification de la structure de la BDD */
    isUpdateBdd: boolean = true;

    /** Lancement automatique des tâches de maintenance */
    isUpdateApp: boolean = true;

    /**
     * Constructeur
     */
    constructor(
        private migrationsService: MigrationsService,
        protected matDialogRef: MatDialogRef<ManualUpdateComponent>
    ) {}

    /**
     * Exécute la mise à jour (asynchrone) de la bdd et/ou le lancement des tâches de maintenance.
     */
    executeManualUpdate(): void {
        //Exécution de la MAJ puis rafraichissement du statut
        this.migrationsService.executeManualUpdate(this.isUpdateBdd,this.isUpdateApp).pipe(first()).subscribe(() => {
            //Demande de mise à jour du statut de l'appli
            this.migrationsService.checkAppliStatut();

            //Fermeture de la fenêtre
            this.matDialogRef.close();
        });
    }
}
