<div class="login">
    <div class="side left">
        <div class="logo" [ngStyle]="{ 'background-image': 'url('+getlogoPersonnalise()+')' }"></div>
        <div *ngIf="statut == KeyStatutApplication.HORS_LIGNE || statut == KeyStatutApplication.HORS_LIGNE_TEMPO" class="message-container">
            <div class="message-half-container">
                <div class="message-maintenance">
                    <mat-icon>error_outline</mat-icon>
                    <div class="message">
                        <strong>{{ 'admin.statut.application.horsLigne3' | translate }}</strong>
                        <div *ngIf="messageMaintenance">{{ messageMaintenance }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!!randomImage" class="random-image">
            <div [ngStyle]="{ 'background-image': 'url('+randomImage+')' }"></div>
        </div>
    </div>
    <div class="side right flex-wrap align-items-end">
        <div class="animated fadeIn">
            <div class="card card-default">
                <div class="card-heading">
                    <h3 class="card-title">
                        <div class="logo_corporate" [ngStyle]="{ 'background-image': 'url('+getLogoSecondaire()+')' }"></div>
                    </h3>
                </div>
                <div class="card-body card-padding" >
                    <!-- Loading pendant la récupération du mode de connexion -->
                    <ng-container *ngIf="!auth">
                        <please-wait class="content" *ngIf="isLoading"></please-wait>
                        <div class="alert alert-danger" *ngIf="loginError">
                            <span translate>{{ loginError }}</span>
                        </div>
                    </ng-container>
                    <!-- Bloc connexion locale -->
                    <ng-container *ngIf="auth && (auth?.loginType == LoginType.LOCAL || forceLoginLocal)">
                        <form autocomplete="off" *ngIf="!typeAction || forceLoginLocal">
                            <div *ngIf="step == 'LOGIN'">
                                <mat-form-field class="d-flex">
                                    <input matInput name="login" [(ngModel)]="user.login" autofocus/>
                                    <mat-placeholder>
                                        <span [translate]="'login.identifiant'"></span>
                                    </mat-placeholder>
                                </mat-form-field>
                                <div class="d-flex">
                                    <button mat-flat-button color="primary" (click)="doContinue();" [disabled]="!user.login"><span [translate]="'global.actions.continuer'"></span></button>
                                    <a *ngIf="!forceLoginLocal" [routerLink]="['/Login/ForgottenPassword']" class="text-center p-t-10" translate>login.forgottenPassword.libelle</a>
                                </div>
                            </div>
                            <div *ngIf="step == 'PASSWORD'">
                                <div class="alert alert-primary" *ngIf="loginError == 'logErrorAccountLocked' || loginError == 'logErrorMustWait'">
                                    <span translate>login.error.accountLocked</span>
                                </div>
                                <div class="alert alert-danger" *ngIf="loginError == 'logErrorAccountDisabled'">
                                    <span translate>login.error.accountDisabled</span>
                                </div>
                                <h5 class="text-center">{{ user.login }}</h5>
                                <mat-form-field class="d-flex" *ngIf="loginError != 'logErrorAccountDisabled'">
                                    <input matInput type="password" name="password" [(ngModel)]="user.password" autofocus/>
                                    <mat-placeholder>
                                        <span [translate]="'login.password'"></span>
                                    </mat-placeholder>
                                </mat-form-field>
                                <div class="d-flex">
                                    <div *ngIf="loginError == 'logErrorMustWait' || loginError == 'logErrorAccountLocked'" class="mx-auto mb-3">
                                        <div recaptcha #loginCaptcha="reCaptcha" (onRecaptchaSuccess)="onRecaptchaSuccess($event)"></div>
                                    </div>
                                    <button mat-flat-button color="primary" *ngIf="!isLoading && loginError != 'logErrorAccountDisabled'" (click)="doLogin();" [disabled]="!user.password || (loginError == 'logErrorMustWait' || loginError == 'logErrorAccountLocked') && !captchaToken"><span [translate]="'global.actions.continuer'"></span></button>
                                    <mat-spinner class="mx-auto mt-3" diameter="30" *ngIf="isLoading"></mat-spinner>
                                    <a *ngIf="statut != KeyStatutApplication.HORS_LIGNE && statut != KeyStatutApplication.HORS_LIGNE_TEMPO" [routerLink]="['/Login/ForgottenPassword']" class="text-center p-t-10" translate>login.forgottenPassword.libelle</a>
                                    <a [routerLink] *ngIf="!isLoading" class="text-center p-t-10" (click)="resetLogin();" translate>login.connexionWithOtherAccount</a>
                                </div>
                            </div>
                        </form>
                        <!-- Gestion du compte et du mot de passe accessible uniquement si pas en mode LoginLocal forcé -->
                        <ng-container *ngIf="!forceLoginLocal">
                            <form #forgottenPasswordForm="ngForm" autocomplete="off" *ngIf="typeAction == 'ForgottenPassword'">
                                <div *ngIf="forgottenPassword.isSent && !passwordChange.error">
                                    <div class="alert alert-primary">
                                        <span translate>login.forgottenPassword.recoveryRequestCompleted</span>
                                    </div>
                                </div>
                                <div class="alert alert-primary" *ngIf="passwordChange?.error == 8">
                                    <span translate>login.error.invalidCaptcha</span>
                                </div>
                                <div *ngIf="!forgottenPassword.isSent">
                                    <div class="alert alert-primary">
                                        <span translate>login.forgottenPassword.message</span>
                                    </div>
                                    <mat-form-field class="d-flex">
                                        <input matInput type="email" name="email" email [(ngModel)]="forgottenPassword.email" [disabled]="!!forgottenPassword.matricule || !!forgottenPassword.identifiant" [required]="!forgottenPassword.matricule && !forgottenPassword.identifiant"/>
                                        <mat-placeholder>
                                            <span [translate]="'login.forgottenPassword.email'"></span>
                                        </mat-placeholder>
                                    </mat-form-field>
                                    <mat-form-field class="d-flex">
                                        <input matInput type="text" name="matricule" [(ngModel)]="forgottenPassword.matricule" [disabled]="!!forgottenPassword.email || !!forgottenPassword.identifiant" [required]="!forgottenPassword.email && !forgottenPassword.identifiant"/>
                                        <mat-placeholder>
                                            <span [translate]="'login.forgottenPassword.matricule'"></span>
                                        </mat-placeholder>
                                    </mat-form-field>
                                    <mat-form-field class="d-flex">
                                        <input matInput type="text" name="identifiant" [(ngModel)]="forgottenPassword.identifiant" [disabled]="!!forgottenPassword.matricule || !!forgottenPassword.email" [required]="!forgottenPassword.matricule && !forgottenPassword.email"/>
                                        <mat-placeholder>
                                            <span [translate]="'login.forgottenPassword.identifiant'"></span>
                                        </mat-placeholder>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex">
                                    <div *ngIf="!forgottenPassword.isSent" class="mx-auto mb-3">
                                        <div recaptcha (onRecaptchaSuccess)="onRecaptchaSuccess($event)"></div>
                                    </div>
                                    <button *ngIf="!isLoading && !forgottenPassword.isSent" mat-flat-button color="primary" (click)="recoverForgottenPassword();" [disabled]="forgottenPasswordForm.invalid || isLoading || !captchaToken"><span [translate]="'login.forgottenPassword.recoverPassword'"></span></button>
                                    <mat-spinner class="mx-auto mt-3" diameter="30" *ngIf="isLoading"></mat-spinner>
                                    <a [routerLink]="['/Login']" class="text-center p-t-10" translate>login.returnToLogin</a>
                                </div>
                            </form>
                            <form #passwordChangeForm="ngForm" autocomplete="off" *ngIf="typeAction == 'PasswordChange' || typeAction == 'ActivateAccount' || typeAction == 'PasswordExpired'">
                                <div class="alert alert-primary" *ngIf="!passwordChange.error && !passwordChange.success">
                                    <strong>
                                        <span *ngIf="typeAction == 'PasswordChange'" translate>login.passwordChange.titre</span>
                                        <span *ngIf="typeAction == 'ActivateAccount'" translate>login.activateAccount.titre</span>
                                        <span *ngIf="typeAction == 'PasswordExpired'" translate>login.passwordExpired.titre</span>
                                    </strong>
                                    <div>{{ 'login.passwordChange.message' | translate:passwordChange.passwordParams }}</div>
                                    <div *ngIf="passwordChange.passwordParams?.minLength" class="ml-3">{{ 'login.passwordChange.rule.caracteres' | translate:{ number: passwordChange.passwordParams?.minLength } }}</div>
                                    <div *ngIf="passwordChange.passwordParams?.minMajuscule" class="ml-3">{{ 'login.passwordChange.rule.majuscules' | translate:{ number: passwordChange.passwordParams?.minMajuscule } }}</div>
                                    <div *ngIf="passwordChange.passwordParams?.minMinuscule" class="ml-3">{{ 'login.passwordChange.rule.minuscules' | translate:{ number: passwordChange.passwordParams?.minMinuscule } }}</div>
                                    <div *ngIf="passwordChange.passwordParams?.minChiffre" class="ml-3">{{ 'login.passwordChange.rule.chiffres' | translate:{ number: passwordChange.passwordParams?.minChiffre } }}</div>
                                    <div *ngIf="passwordChange.passwordParams?.minSpecial" class="ml-3">{{ 'login.passwordChange.rule.special' | translate:{ number: passwordChange.passwordParams?.minSpecial } }}</div>
                                </div>
                                <div class="alert alert-danger" *ngIf="passwordChange?.error == 12 || passwordChange?.error == 11 || passwordChange?.error == 10">
                                    <span translate>login.passwordChange.error.noRespectHistory</span>
                                </div>
                                <div class="alert alert-primary" *ngIf="passwordChange?.error == 8">
                                    <span translate>login.error.invalidCaptcha</span>
                                </div>
                                <div class="alert alert-primary" *ngIf="passwordChange?.error == 4">
                                    <span *ngIf="typeAction == 'PasswordChange'" translate>login.passwordChange.error.linkExpired</span>
                                    <span *ngIf="typeAction == 'ActivateAccount'" translate>login.activateAccount.error.linkExpired</span>
                                </div>
                                <div class="alert alert-primary" *ngIf="passwordChange?.success">
                                    <span *ngIf="typeAction == 'PasswordChange'" translate>login.passwordChange.success</span>
                                    <span *ngIf="typeAction == 'ActivateAccount'" translate>login.activateAccount.success</span>
                                </div>
                                <div *ngIf="passwordChange?.error != 4 && !passwordChange.success">
                                    <mat-form-field class="d-flex">
                                        <input matInput type="password" name="newPassword" #password="ngModel" [(ngModel)]="passwordChange.newPassword" [pattern]="RGX_FULL_PASSWORD" (ngModelChange)="checkPasswords()" required/>
                                        <mat-placeholder>
                                            <span translate>login.passwordChange.newPassword</span>
                                        </mat-placeholder>
                                        <mat-error *ngIf="password.invalid">
                                            <span translate>login.passwordChange.rule.passwordRequired</span>
                                        </mat-error>
                                        <mat-error *ngIf="passwordChange.passwordParams?.minLength && passwordChange?.newPassword?.match(RGX_MIN_LENGTH) == null">
                                            <span class="ml-3">{{ 'login.passwordChange.rule.caracteres' | translate:{ number: passwordChange.passwordParams?.minLength || 0 } }}</span>
                                        </mat-error>
                                        <mat-error *ngIf="passwordChange.passwordParams?.minMajuscule && passwordChange?.newPassword?.match(RGX_MIN_MAJUSCULE) == null">
                                            <span class="ml-3">{{ 'login.passwordChange.rule.majuscules' | translate:{ number: passwordChange.passwordParams?.minMajuscule || 0 } }}</span>
                                        </mat-error>
                                        <mat-error *ngIf="passwordChange.passwordParams?.minMinuscule && passwordChange?.newPassword?.match(RGX_MIN_MINUSCULE) == null">
                                            <span class="ml-3">{{ 'login.passwordChange.rule.minuscules' | translate:{ number: passwordChange.passwordParams?.minMinuscule || 0 } }}</span>
                                        </mat-error>
                                        <mat-error *ngIf="passwordChange.passwordParams?.minChiffre && passwordChange?.newPassword?.match(RGX_MIN_CHIFFRE) == null">
                                            <span class="ml-3">{{ 'login.passwordChange.rule.chiffres' | translate:{ number: passwordChange.passwordParams?.minChiffre || 0 } }}</span>
                                        </mat-error>
                                        <mat-error *ngIf="passwordChange.passwordParams?.minSpecial && passwordChange?.newPassword?.match(RGX_MIN_SPECIAL) == null">
                                            <span class="ml-3">{{ 'login.passwordChange.rule.special' | translate:{ number: passwordChange.passwordParams?.minSpecial || 0 } }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="d-flex">
                                        <input matInput type="password" name="confirmPassword" #confirmPassword="ngModel" [(ngModel)]="passwordChange.confirmPassword" (ngModelChange)="checkPasswords()" required/>
                                        <mat-placeholder>
                                            <span translate>login.passwordChange.confirmPassword</span>
                                        </mat-placeholder>
                                        <mat-error *ngIf="confirmPassword.invalid" class="pb-3">
                                            <mat-error>
                                                <span translate>login.passwordChange.rule.confirmPasswordNoMatch</span>
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex">
                                    <div class="mx-auto mb-3" *ngIf="!isLoading && passwordChange?.error != 4 && !passwordChange.success && typeAction != 'PasswordExpired'">
                                        <div recaptcha (onRecaptchaSuccess)="onRecaptchaSuccess($event)"></div>
                                    </div>
                                    <button mat-flat-button
                                        *ngIf="!isLoading && passwordChange?.error != 4 && !passwordChange.success"
                                        [disabled]="passwordChangeForm.invalid || (!captchaToken && typeAction != 'PasswordExpired')" color="primary"
                                        (click)="saveNewPassword()"
                                    ><span [translate]="'global.actions.enregistrer'"></span></button>
                                    <mat-spinner class="mx-auto mt-3" diameter="30" *ngIf="isLoading"></mat-spinner>
                                    <a (click)="returnToLogin()" class="text-center p-t-10" translate>login.returnToLogin</a>
                                </div>
                            </form>
                        </ng-container>
                    </ng-container>
                    <!-- Bloc SSO -->
                    <ng-container *ngIf="auth && auth.loginType !== LoginType.LOCAL && !forceLoginLocal">
                        <!-- Information de redirection vers l'idp -->
                        <ng-container *ngIf="!typeAction && !loginError">
                            <please-wait class="content" *ngIf="statut === KeyStatutApplication.EN_LIGNE && isLoading" [message]="'login.sso.redirectionIdp'"></please-wait>
                        </ng-container>
                        <!-- Information de déconnexion -->
                        <ng-container *ngIf="typeAction == 'Logout'">
                            <please-wait class="content" *ngIf="isLoading" [message]="'login.sso.deconnectionEnCours'"></please-wait>
                            <div *ngIf="!isLoading" class="d-flex">
                                <div class="alert alert-primary text-center">
                                    <span translate>login.sso.deconnecte</span>
                                </div>
                                <a [routerLink]="['/Login']" class="text-center p-t-10" translate>login.sso.seConnecter</a>
                            </div>
                        </ng-container>
                        <div *ngIf="!!loginError" class="alert alert-danger">
                            <span translate>{{ loginError }}</span>
                        </div>
                        <div *ngIf="statut !== KeyStatutApplication.EN_LIGNE" class="alert alert-danger">
                            <span>{{ 'login.error.appliNotOnline' | translate }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="align-self-end w-100 text-right copyright-text m-2">
            <a [translate]="'login.supprimerCookies'" (click)="deleteCookies()"></a> - <span [translate]="'login.copyright'" [translateParams]="{'currentYear': currentYear}"></span>
        </div>
    </div>
</div>
