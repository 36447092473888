import {Component,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {ZonePredefinie} from "@domain/zone-utilisateur/zone-predefinie";
import {ZonePredefinieListItemComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-predefinies/list-item/zone-predefinie-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Result} from "@domain/common/http/result";

@Component({
	host: {'data-test-id': 'zones-predefinies'},
	selector: 'zones-predefinies',
	templateUrl: './zones-predefinies.component.html'
})
export class ZonesPredefiniesComponent implements OnInit {
	/** Liste des zones prédéfinies */
	listeZonesPredefinies: ListView<ZonePredefinie,ZonePredefinieListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des zones prédéfinies
		this.listeZonesPredefinies = new ListView<ZonePredefinie,ZonePredefinieListItemComponent>({
			uri: '/controller/ZoneUtilisateur/getZonesPredefinies',
			title: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.listTitle'),
			component: ZonePredefinieListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => result?.data?.zonesPredefinies
		});
	}

}
