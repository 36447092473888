/**
 * Modèle des logs des modifications de la population
 */
export class PopulationLogChange {
    idLog: number;
    clef: PopulationLogChangeType;
    nouvelleValeur: string;
    ancienneValeur: string;
    ordre: number;
}

/**
 * Enumération de la clef PopulationLogChange
 */
export enum PopulationLogChangeType {
    IDPOPULATION = "id_population",
    REFERENCE = "reference",
    LIBELLE = "libelle",
    REMARQUE = "remarque",
    ACTIF = "actif"
}
