import {FieldContainer} from "@domain/settings/field";
import {PageHeaderItem} from "../../share/component/page-header/page-header";
import {Alerte,NiveauAlerte} from "../common/alerte/alerte";
import {ListeAlertes} from "../common/alerte/listeAlertes";
import {ListItem} from "../common/list-view/list-item";
import {Fournisseur} from "./fournisseur";
import {MontantPipe} from "@share/pipe/montant";

/**
 * Modèle représentant un relevé de facture
 */
export class ReleveFacture extends FieldContainer implements ListItem {
    idFactureReleve: number;
	fournisseur: Fournisseur;
	dateReleve: Date;
	numero: string;
	typeReconciliation: TypeReconciliation;
	montant: number;
	montantControle: number;
	remarque: string;
    compta: boolean;
    deviseFournisseur: string;
    quantite: number;
    importe: boolean;
    anomalieFactures: boolean;

    constructor(dto: any) {
        super(dto);

        if (dto) {
            this.idFactureReleve = dto.idFactureReleve;
            if (dto.fournisseur) { this.fournisseur = new Fournisseur(dto.fournisseur); }
            if (dto.dateReleve) { this.dateReleve = new Date(dto.dateReleve); }
            this.numero = dto.numero;
            this.typeReconciliation = dto.typeReconciliation;
            this.montant = dto.montant;
            this.montantControle = dto.montantControle;
            this.remarque = dto.remarque;
            this.compta = dto.compta;
            this.deviseFournisseur = dto.deviseFournisseur;
            this.quantite = dto.quantite;
            this.importe = dto.importe;
            this.anomalieFactures = dto.anomalieFactures;
        } else {
            //Par défaut un relevé est de type OM
            this.typeReconciliation = 2;
            this.montant = 0;
        }
    }

    /** Variable privée pour la gestion des alertes hardcodées */
    private _alertes: {
        facturesCount: number,
        montantPipe: MontantPipe,
        selectedItem: PageHeaderItem
    } = {
        facturesCount: undefined,
        montantPipe: undefined,
        selectedItem: undefined
    };

    /**
     * Setter pour le PageHeaderItem
     * @param selectedItem onlget sélectionné
     */
    set selectedItemForAlerte(selectedItem: PageHeaderItem) {
        setTimeout(() => { this._alertes.selectedItem = selectedItem; });
    }

    /**
     * Setter pour le MontantPipe
     * @param montantPipe Le pipe pour le formatage des montants
     */
    set montantPipeForAlerte(montantPipe: MontantPipe) {
        setTimeout(() => { this._alertes.montantPipe = montantPipe; });
    }

    /**
     * Setter pour le nombre d'éléments effectivement chargés dans la liste de factures
     * @param count le nombre d'éléments
     */
    set facturesCountForAlerte(count: number) {
        setTimeout(() => { this._alertes.facturesCount = count; });
    }

    /**
     * Getter pour la liste d'alertes hardcodées
     */
    get listeAlertes(): ListeAlertes {
        //Initialisation
        let listeAlertes: ListeAlertes = undefined;

        //Relevé
        if (this._alertes.montantPipe) {
            //Alerte hardcodée uniquement si divergence entre les montants
            if (this.montantControle && this.montantControle != 0.0 && this.montantControle != this.montant) {
                //Création d'une liste d'alertes hardcodée
                listeAlertes = new ListeAlertes();

                //Création d'une alerte hardcodée pour le montant
                const alerte: Alerte = new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    titre: 'facture.releve.alerte.controleMontant.title',
                    message: 'facture.releve.alerte.controleMontant.message'
                });

                //Attachement forcé des paramètres de traduction à l'objet
                alerte.traductionParams = {
                    montant: this._alertes.montantPipe.transform(this.montant,this.fournisseur?.devise),
                    montantControle: this._alertes.montantPipe.transform(this.montantControle,this.fournisseur?.devise)
                };

                //Ajout de l'alerte à la liste
                listeAlertes.add(alerte);
            }
        }

        //Onglet factures uniquement
        if (this._alertes.selectedItem?.code == 'FACTURES' && this._alertes.facturesCount != null) {
            //Alerte hardcodée uniquement si divergence du nombre d'éléments listés
            if (this.quantite != this._alertes.facturesCount) {
                //Création d'une liste d'alertes hardcodée si nécessaire
                listeAlertes = listeAlertes ?? new ListeAlertes();

                //Création d'une alerte hardcodée pour le montant
                const alerte: Alerte = new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    titre: 'facture.releve.alerte.extrait.title',
                    message: ''
                });

                //Paramétrage du message d'alerte
                if ((this.quantite - this._alertes.facturesCount) > 1) {
                    alerte.message = 'facture.releve.alerte.extrait.message.plural';
                } else if ((this.quantite - this._alertes.facturesCount) == 1) {
                    alerte.message = 'facture.releve.alerte.extrait.message.single';
                }

                //Attachement forcé des paramètres de traduction à l'objet
                alerte.traductionParams = {
                    count: (this.quantite - this._alertes.facturesCount)
                };

                //Ajout de l'alerte à la liste
                listeAlertes.add(alerte);
            }
        }

        //Si le relevé comporte une ou plusieurs factures en anomalie (import)
        if (this.anomalieFactures) {
            //Initialisation si besoin
            listeAlertes = listeAlertes ?? new ListeAlertes();

            //Ajout de l'alerte
            listeAlertes.add(new Alerte({
                niveau: NiveauAlerte.WARNING,
                titre: 'facture.releve.alerte.releveFactures.title',
                message: 'facture.releve.alerte.releveFactures.message',
            }));
        }
        
        //Retour
        return listeAlertes;
    }
    
    /**
     * Mise à jour de la devise en fonction du fournisseur
     */
    updateDevise(): void {
        this.deviseFournisseur = this.fournisseur?.devise;
    }

    /**
     * Récupération de l'identifiant de l'objet
     * @returns l'identifiant de l'objet
     */
    getKey(): number {
        return this.idFactureReleve;
    }

    /**
     * Retourne la version texte du statut calculé
     * @returns le statut de l'objet
     */
    getStatut(): string {
        return 'facture.releve.statut.' + (this.compta ? 'comptabilise' : 'nonComptabilise');
    }
}

/**
 * Énumération des types de réconciliation
 */
export enum TypeReconciliation {
    TYPE_RECONCILIATION_COLLAB = 1,
    TYPE_RECONCILIATION_OM = 2,
}