<div class="content" *ngIf="vehicule">
    <page-header [title]="'vehicule.title' | translate:{immatriculation: vehicule.immatriculation}"
                 [extraInfo]="getStatusLibelle(vehicule.statut)" [extraInfoTooltip]="motifRejet"
                 [listeItems]="listeTabItems"
                 [niveauAlerte]="listeAlertes?.niveau"
                 (onSelectedItemChange)="onSelectedItemChange($event)"
                 (onGoBack)="onGoBack()">
        <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
    </page-header>
    <div *ngIf="selectedItem?.code == 'GENERALITES'">
        <mat-card>
            <mat-card-title>
                <span [translate]="'vehicule.formulaire.label'"></span>
            </mat-card-title>
            <mat-card-content>
                <form autocomplete="off">
                    <fieldset>
                        <div class="row">
                            <custom-input #immatComponent
                                          [(ngModel)]="vehicule.immatriculation"
                                          customType="input-obfuscated"
                                          libelle="vehicule.liste.immatriculation"
                                          maxlength="20"
                                          [canReadClear]="connectedUser.fonction === TypeProfil.ADMINISTRATEUR"
                                          (onReadClearRequest)="getPlaqueImmatriculationVehiculeNonObfusquee()"
                                          name="immatriculation"
                                          ngDefaultControl
                                          [required]="true"
                                          [disabled]="true">
                            </custom-input>
                            <custom-input [ngModel]="vehicule.actif"
                                          [(customModel)]="vehicule.actif"
                                          customType="checkbox"
                                          libelle="vehicule.formulaire.actif"
                                          name="actif"
                                          ngDefaultControl
                                          [postLibelle]="'vehicule.formulaire.actifDescriptif'"
                                          [disabled]="true">
                            </custom-input>
                        </div>
                        <div class="row">
                            <custom-input [(ngModel)]="vehicule.marque"
                                          customType="input"
                                          libelle="vehicule.formulaire.marque"
                                          name="marque"
                                          ngDefaultControl
                                          [required]="true"
                                          [disabled]="true">
                            </custom-input>
                            <custom-input [(ngModel)]="vehicule.idPuissanceFiscale"
                                          customType="select"
                                          libelle="vehicule.liste.puissanceFiscale"
                                          name="puissanceFiscale"
                                          id="puissanceFiscale"
                                          [selectOptions]="listePuissance"
                                          optionValue="idPuissance"
                                          optionDisplay="libelle"
                                          ngDefaultControl
                                          [required]="true"
                                          [disabled]="true">
                            </custom-input>
                        </div>
                        <div class="row">
                            <custom-input [(ngModel)]="vehicule.modele"
                                          customType="input"
                                          libelle="vehicule.formulaire.modele"
                                          name="modele"
                                          ngDefaultControl
                                          [required]="true"
                                          [disabled]="true">
                            </custom-input>
                            <label class="col-md-2">
                                <span [translate]="'vehicule.formulaire.historiqueDistance'"></span>
                            </label>
                            <button mat-button color="primary" (click)="openHistoriqueDistance()" class="mb-2">
                                <mat-icon color="primary">search</mat-icon>
                            </button>
                        </div>
                        <div class="row">
                            <custom-input [(ngModel)]="vehicule.assurePro"
                                          [(customModel)]="vehicule.assurePro"
                                          customType="checkbox"
                                          libelle="vehicule.formulaire.assurance"
                                          name="assure"
                                          ngDefaultControl
                                          [postLibelle]="'vehicule.formulaire.assuranceDescriptif'"
                                          [disabled]="true">
                            </custom-input>
                        </div>
                    </fieldset>
                </form>
            </mat-card-content>
        </mat-card>
        <vehicule-documents *ngIf="vehicule.idPa" [canModifier]="false" [settings]="this.settings" [vehicule]="vehicule" [isResponsableVehicule]="true" [canGestionDocuments]="false"></vehicule-documents>
    </div>
    <div *ngIf="selectedItem?.code == 'COMPLEMENTS'">
        <document-list *ngIf="vehicule.idPa"
                       [context]="'PROFIL_VEHICULE'" [idObjetForPJ]="vehicule.idCollab"
                       [idObjet]="vehicule.idPa" [settings]="settings" [canAddDocument]="false" [canDeleteDocument]="false"
                       [uri]="'/controller/Document/searchVehiculeDocuments?id_vehicule='">
        </document-list>
    </div>
</div>
<please-wait class="content" *ngIf="!vehicule"></please-wait>
