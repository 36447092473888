import {ZUReferentiel} from "./zuReferentiel";
import {ListItem} from "@domain/common/list-view/list-item";
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Classe répresantant une zone utilisateur
 */
export class ZoneUtilisateur implements ListItem {
    /* Données */
    //Id du champ
    idZU: number;
    //Libellé du champ
    libelle: string;
    //Référence du champ
    reference: string;
    //Type du champ
    typeChamp: TypeZoneUtilisateur;
    //Libellé du tooltip
    description?: string;
	//Portée
	idPortee: TypePortee;
	//Ordre
	ordre: number;
	//Actif
	actif: boolean;
    //Champ obligatoire
    obligatoire: boolean;
    //Code du champ
    code?: string;
	//Valeur par défaut si c'est une ZU de type texte
	texteDefaut?: string;
	//Valeur par défaut si c'est une ZU de type checkbox
	boolDefaut?: boolean;
	//Valeur par défaut si c'est une ZU de type montant
	montantDefaut?: string;
	//Valeur par défaut si c'est une ZU de type date
	dateDefaut?: string;
	//Valeur par défaut si c'est une ZU de type heure
	heureDefaut?: string;

    //Affichage du référentiel des listes et de la saisie pour la liste multiple
    listeReferentiel?: ZUReferentiel[];

    //Saisie pour la liste unique
    idSaisieRef?: number;
    //Saisie autre pour liste unique et multiple
    saisieAutre?: boolean;
    //Saisie du texte
    saisieTexte?: string;
    //Saisie du montant
    saisieMontant?: number;
    //Saisie de la date
    saisieDate?: Date;
    //sSisie de l'heure
    saisieHeure?: string;
    //Saisie de la case à cocher
    saisieCheck?: boolean;

	//Nombre de données définies dans le référentiel pour la zone utilisateur
	nbData: number;
	//Existence de saisies pour la zone utilisateur
	hasData: boolean;

	getKey(): number {
		return this.idZU;
	}
}

/**
 * Enumération représantant les types de champ
 */
export enum TypeZoneUtilisateur {
    NONE,
    TEXTE,
    CHECKBOX,
    MONTANT,
    DATE,
    LISTE_UNIQUE,
    LISTE_MULTIPLE,
    LISTE_UNIQUE_AUTRE,
    LISTE_MULTIPLE_AUTRE,
    HEURE
}