import {Component,Input} from '@angular/core';
import {Facture} from "@domain/facture/facture";
import {TranslateService} from "@ngx-translate/core";
import {TypeFact} from "@domain/facture/type-fact";
import {TypeReconciliation} from "@domain/facture/releve-facture";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Représente une ligne de la liste des factures de référence du contentieux
 *
 * @author Laurent Convert
 * @date 20/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-reference-list-item'},
    templateUrl: './facture-reference-list-item.component.html'
})
export class FactureReferenceListItemComponent extends ListViewItem<Facture> {
    /** Déclaration pour accès dans le template */
    TypeFact = TypeFact;
    TypeReconciliation = TypeReconciliation;
    
    /** Facture de référence */
    @Input() data: Facture;
    
    /** Options supplémentaires */
    @Input() extraOptions: {
        withSelection: boolean,
        canModifier: boolean,
        removeFactureRef: (facture: Facture) => void
    };
    
    /**
     * Constructeur
     */
    constructor(public translateService: TranslateService) {
        super();
    }
    
}