import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {HistoriqueUtilisateur} from "@domain/user/historique-utilisateur";
import {HistoriqueUtilisateurChange} from "@domain/user/historique-utilisateur-change";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'un item de la liste de l'historique utilisateur
 */
@Component({
	host: {'data-test-id': 'user-historique'},
	selector: 'user-historique-list-item',
	templateUrl: './user-historique-list-item.component.html'
})
export class UserHistoriqueListItemComponent extends ListViewItem<HistoriqueUtilisateur> implements OnInit {
	/** Indicateur de l'état de l'affichage (plié/déplié) */
	isUnfolded: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Remplacement des valeurs vides par des "(Vide)"
		this.data.listeChanges?.forEach(change => {
			change.ancienneValeur = change.ancienneValeur ?? `(${this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.vide')})`;
			change.nouvelleValeur = change.nouvelleValeur ?? `(${this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.vide')})`;
		});
	}
}