<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.codeLangue }}</span>
    </div>
    <div class="lvi-body">
        <div class="title" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:800px">
            <a class="label" [title]="data.libelle | translate" (click)="extraOptions.onSelect(data)">{{ data.libelle | translate }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.internationalisation.libelleFiltres.cleTraduction'"></strong>
                <span>{{ data.cleTraduction }}</span>
            </li>
            <li *ngIf="data.messageCustom">
                <b *ngIf="!data.messageStandard" [translate]="'admin.bibliotheque.internationalisation.libelleFiltres.personnalise'"></b>
                <strong *ngIf="data.messageStandard" [translate]="'admin.bibliotheque.internationalisation.libelleFiltres.personnalise'"></strong>
                <mat-icon *ngIf="data.messageStandard" color="primary" style="font-size:1.1em;" [matTooltip]="('admin.bibliotheque.internationalisation.infosLibelle.libelleCard.standard' | translate) + ' : ' + data.messageStandard" >info_outline</mat-icon>
            </li>
        </ul>
    </div>
</div>