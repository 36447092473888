<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">settings</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.parent.openReferentiel(data)">{{ data.libelle || ('admin.voyages.travelhub.sbtConfig.referentiel.communaute.libelleNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="parametre"><strong translate>admin.voyages.travelhub.sbtConfig.referentiel.parametre</strong>{{ parametre }}</li>
            <li *ngIf="data.valeur"><strong translate>admin.voyages.travelhub.sbtConfig.referentiel.valeur</strong>{{ data.valeur }}</li>
            <li><strong translate>admin.voyages.travelhub.sbtConfig.referentiel.defaut</strong>{{ (data.defaut ? 'global.oui' : 'global.non') | translate }}</li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deleteCommunaute()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
