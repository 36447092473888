<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': isSoonToExpire,'danger': isExpired }">
        <mat-icon>assignment_ind</mat-icon>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="'profilUser.document.'+data.type" (click)="this.extraOptions.open(data)"></a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.titulaire">
                <strong [translate]="'profilUser.document.titulaire'"></strong>
                <span>{{ data.titulaire }}</span>
            </li>
            <li *ngIf="data.numero">
                <strong [translate]="'profilUser.document.numero'"></strong>
                <span>{{ data.numero }}</span>
            </li>
            <li *ngIf="data.lieuDelivrance">
                <strong [translate]="'profilUser.document.lieuDelivrance'"></strong>
                <span>{{ data.lieuDelivrance }}</span>
            </li>
            <li *ngIf="data.dateDelivrance">
                <strong [translate]="'profilUser.document.dateDelivrance'"></strong>
                <span>{{ data.dateDelivrance | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateExpiration">
                <strong [translate]="'profilUser.document.dateExpiration'"></strong>
                <span>{{ data.dateExpiration | date: 'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data.actif ? 'global.oui' : 'global.non'"></span>
            </li>
            <li *ngIf="data.toDelete">
                <strong [translate]="'profilUser.document.toDelete'"></strong>
                <span [translate]="'profilUser.document.toDeleteInfo'"></span>
            </li>
            <li *ngIf="isSoonToExpire || isExpired" [ngClass]="{ 'back-warning': isSoonToExpire,'back-danger': isExpired }">
                <strong [translate]="'workflow.alerte.title'"></strong><span [translate]="'vehicule.liste.alerte.' + (isExpired ? 'expired' : 'echeance')"></span>
            </li>
        </ul>
    </div>
</div>
