import {Injectable} from '@angular/core';
import {PieceJointeParam,PieceJointePath,TypeEntiteParam,TypePorteeParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {BehaviorSubject,Observable} from "rxjs";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {finalize,first} from "rxjs/operators";

@Injectable()
export class PieceJointeService {
    /** Paramètres des pièces par portée */
    pieceJointeParamsSubject: BehaviorSubject<PieceJointeParam[]>  = new BehaviorSubject<PieceJointeParam[]>(null);

    /** Chemin local des pièces jointes */
    pieceJointeLocalPathSubject: BehaviorSubject<PieceJointePath> = new BehaviorSubject<PieceJointePath>(null);

    /** Liste des types entités params */
    typeEntiteParamsSubject: BehaviorSubject<TypeEntiteParam[]> = new BehaviorSubject<TypeEntiteParam[]>(null);

    /** En cours de sauvegarde */
    isSavingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Constructeur
     */
    constructor(
        private dematService: DematService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
    ) {
    }

    /**
     * Initialise le modèle
     */
    load() {
        //On récupère les données du modèle
        this.dematService.loadPieceJointeParams().subscribe((result: Result) => {
            //Si on a pas d'erreur
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //On trie les params pour avoir un ordre similaire au front
                this.pieceJointeParamsSubject.next(this.sortListePieceJointeParamsByPortee(result.data.pieceJointeParams));

                this.pieceJointeLocalPathSubject.next({path: result.data.pieceJointeLocalPath,azureStorage: result.data.isAzureStorage});

                this.typeEntiteParamsSubject.next(result.data.typeEntiteParams);
            } else {
                //Erreur
                this.toastrService.error(this.translateService.instant('global.errors.chargement'));
            }
        })
    }

    /**
     * Nettoyage du service
     */
    reset() {
        this.pieceJointeParamsSubject.next(null);
        this.pieceJointeLocalPathSubject.next(null);
        this.typeEntiteParamsSubject.next(null);
    }

    /**
     * Sauvegarde du modèle
     */
    save(): void {
        this.isSavingSubject.next(true);

        this.dematService.savePieceJointeParams(
            this.pieceJointeParamsSubject.getValue(),
            this.pieceJointeLocalPathSubject.getValue().path
        ).pipe(first(),finalize(() => this.isSavingSubject.next(false))).subscribe(
            (result: Result) => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                } else {
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            }
        );
    }

    /**
     * Trie listePieceJointeParams pour avoir le même ordre que l'enum TypePorteeParam, c'est l'ordre d'affichage du front
     * @param listePieceJointeParams les paramètres des pièces jointes
     */
    private sortListePieceJointeParamsByPortee(listePieceJointeParams: PieceJointeParam[]) {
        //On affecte order avec le tri de base
        const order = [];
        for (let key in TypePorteeParam) {
            order.push(key);
        }

        //On effectue le trie
        const listePieceJonteParamsSorted = listePieceJointeParams.sort((a,b) => {
            const index1 = order.findIndex(key => TypePorteeParam[key] === a.idPortee);
            const index2 = order.findIndex(key => TypePorteeParam[key] === b.idPortee);
            return index1 - index2;
        });

        return listePieceJonteParamsSorted;
    }

    /**
     * Getter de pieceJointeParam
     * @returns un observable de pieceJointeParam
     */
    getPieceJointeParams(): Observable<PieceJointeParam[]> {
        return this.pieceJointeParamsSubject.asObservable();
    }

    /**
     * Getter de pieceJointeLocal
     * @returns un observable de pieceJointeLocal
     */
    getPieceJointeLocalPath() {
        return this.pieceJointeLocalPathSubject.asObservable();
    }

    /**
     * Getter de isSaving
     * @returns un observable de isSaving
     */
    getIsSaving(): Observable<boolean> {
        return this.isSavingSubject.asObservable();
    }

    /**
     * Getter de typeEntiteParam
     * @returns un observable de typeEntiteParam
     */
    getTypeEntiteParams() : Observable<TypeEntiteParam[]> {
        return this.typeEntiteParamsSubject.asObservable();
    }
}