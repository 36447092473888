/**
 * Types de filtre
 */
export enum TypeFilter {
    /** Enumération **/
    STRING,
    BOOLEAN,
    LONG,
    DATE,
    DECIMAL
}