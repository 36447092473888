import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {RuleDetail} from "@domain/rule/rule-detail";
import {RuleFilter} from '@domain/rule/rule-filter';
import {TypeRuleFilterOperator} from "@domain/rule/type-rule-filter-operator";
import {RuleBuilderService} from "@share/component/rule-builder/rule-builder.service";
import {TypeFonction} from "@domain/rule/type-fonction";
import {RuleFilterValue} from "@domain/rule/rule-filter-value";
import {BusinessDataService} from "@share/component/rule-builder/business-data/business-data.service";
import {EntityWrapperMember} from "@domain/entite/EntityWrapperMember";
import {first} from "rxjs/operators";
import {Rule} from '@domain/rule/rule';
import {EntiteService} from "@share/component/rule-builder/entite.service";
import {EntityWrapper} from "@domain/entite/EntityWrapper";
import {RuleRepository,TypeFieldType} from "@share/component/rule-builder/rule-repository.service";
import {ControlContainer,NgForm} from "@angular/forms";
import {v4} from 'uuid';
import * as moment from "moment";

/**
 * Composant d'une ligne d'une Rule
 */
@Component({
	host: {'data-test-id': 'rule-item'},
	selector: 'rule-item',
	templateUrl: './rule-item.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class RuleItemComponent implements OnInit {
	/** RuleDetail courant */
	_detail: RuleDetail;

	/** Écoute des changements dans le RuleDetail */
	@Output() detailChange: EventEmitter<RuleDetail> = new EventEmitter<RuleDetail>();

	/** Le RuleDetail */
	@Input()
	get detail() {
		return this._detail;
	}

	set detail(detail: RuleDetail) {
		this._detail = detail;
		this.detailChange.emit(this._detail);
	}

	/** Entité */
	@Input() entite: string;

	/** Sommes-nous dans le cas d'une règle embarquée */
	@Input() isEmbeddedRule?: boolean;

	/** Interception de la suppression */
	@Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

	/** Clé unique */
	key: string

	//Import des énums pour le DOM
	readonly TypeFonction = TypeFonction
	readonly TypeRuleFilterOperator = TypeRuleFilterOperator;
	readonly TypeFieldType = TypeFieldType;

	/**
	 * Constructeur
	 *
	 * @param ruleService le service de construction d'une Rule
	 * @param businessDataService le service de gestion des données métier
	 * @param entiteService le service de gestion des entités
	 * @param ruleRepository le repository des Rule
	 */
	constructor(
		private ruleService: RuleBuilderService,
		private businessDataService: BusinessDataService,
		private entiteService: EntiteService,
		protected ruleRepository: RuleRepository
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Génération d'une clé unique pour ce RuleItem
		this.key = v4().toString();

		//Si la valeur est une date
		if (this.detail.filter?.listeValues[0]?.dateValue) {
			//On doit la convertir au format YYYY-MM-DD afin que l'input date puisse la lire
			this.detail.filter.listeValues[0].dateValue = moment(this.detail.filter.listeValues[0].dateValue).format('YYYY-MM-DD');
		}
	}

	/**
	 * Appelé au clic sur une ligne
	 *
	 * @param cible est-ce qu'on veut mettre à jour le filter (false) ou le filterCible (true)
	 */
	onSelect(cible?: boolean): void {
		//Si l'on veut traiter le filter classique
		if (!cible) {
			//Création du filtre si nécessaire
			this.detail.filter = this.detail.filter ?? new RuleFilter();
		}

		//On définit si l'on souhaite afficher les relations 1n dans le catalogue des données métier
		const show1n: boolean = !cible && !this.isEmbeddedRule;

		//Ouverture de la popup de sélection d'une entité
		this.businessDataService.showBusinessData(this.entite,show1n)
			.subscribe((member: EntityWrapperMember) => {
				//Vérification de la présence d'un membre
				if (member) {
					//Mise à jour du filtre
					this.updateFilter(cible ? this.detail.filterCible : this.detail.filter,member);
				}
			});
	}

	/**
	 * Mise à jour du filter à la fermeture du catalogue des données métier
	 *
	 * @param filter le RuleFilter
	 * @param member la donnée métier sélectionnée
	 */
	updateFilter(filter: RuleFilter,member: EntityWrapperMember): void {
		//Mise à jour du filter
		Object.assign(filter,{
			filter: member.path,
			type: member.type,
			operateur: TypeRuleFilterOperator.EQUAL,
			listeValues: [new RuleFilterValue()],
			libelle: member.pathLibelle ?? member.libelle,
			embeddedRule: member.collection,
			fieldType: this.ruleService.getInputFieldType(member.type),
		});

		//Si l'on est dans le cas d'une règle embarquée
		if (filter.embeddedRule) {
			//Chargement des business data correspondantes
			this.entiteService.loadBusinessData(filter.type)
				.pipe(first())
				.subscribe((entite: EntityWrapper) => {
					//Mise à jour du filter
					Object.assign(filter,{
						childEntityLoaded: true,
						childEntity: entite,
						notExists: false
					});

					//Ajout d'une Rule en valeur du filter
					filter.listeValues[0].rule = new Rule(filter.type);
				});
		}

		//Vérification de la présence d'une énumération
		filter.enum = filter.fieldType.type === 'ENUM';
	}

	/**
	 * Interception du changement d'opérateur
	 */
	onOperateurChange(): void {
		//On supprime la valeur d'indice 1 (si elle existe)
		this.detail.filter.listeValues.splice(0);

		if (this.detail.filter.operateur === TypeRuleFilterOperator.ROLLING_PERIOD) {
			//Ajout de la valeur d'indice 0
			this.detail.filter.listeValues.push(Object.assign(new RuleFilterValue(),{
				fonction: TypeFonction.DATE_TODAY
			}));
		}

		//Initialisation de la valeur d'indice suivant
		this.detail.filter.listeValues.push(new RuleFilterValue());
	}

	/**
	 * Récupère la liste des opérateurs
	 */
	getListeOperateurs(): Array<{ code: TypeRuleFilterOperator,libelle: string }> {
		let operators = [];

		if (this.detail?.filter?.filter) {
			//Chargement de la liste des opérateurs
			operators = this.detail.filter.fieldType.listeOperators;

			//Si l'on est dans le cas d'une comparaison champ / champ
			if (this.detail.filterCible) {
				//On réduit la liste des opérateurs à ÉGAL / DIFFÉRENT DE
				operators = operators.filter(o => [TypeRuleFilterOperator.EQUAL,TypeRuleFilterOperator.NOT_EQUAL].includes(o.code));
			}
		}

		return operators;
	}

	/**
	 * Active la comparaison champ / champ
	 */
	enableFieldComparison(): void {
		//On initialise l'objet filterCible
		this.detail.filterCible = new RuleFilter();

		//On supprime les values du filter
		delete this.detail.filter.listeValues;
	}

	/**
	 * Repasse en comparaison champ / valeur
	 */
	disableFieldComparison(): void {
		//On supprime le filterCible
		delete this.detail.filterCible;

		//On initialise les values du filter
		this.detail.filter.listeValues = [new RuleFilterValue()];
	}

	/**
	 * Interception du changement de fonction de date
	 */
	onFonctionChange(): void {
		//Suppression de la date et de la valeur
		delete this.detail.filter.listeValues[0].dateValue;
		delete this.detail.filter.listeValues[0].value;
	}

	/**
	 * Suppression d'un RuleItem
	 */
	onRemoveItem(): void {
		//Notification de la suppression
		this.onDelete.emit();
	}
}
