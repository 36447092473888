<h1 mat-dialog-title>
    <span [translate]="'profilUser.cartePaiement.addTitle'"></span>
    <span [mat-dialog-close]="willRefreshList"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #addCarteForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input [(ngModel)]="data.carte.typeCarte"
                        [disabled]="!!data.carte.idCarte"
                        [selectOptions]="listeTypeCarte"
                        customType="select"
                        lCol="2"
                        libelle="profilUser.cartePaiement.typeCarte.libelle"
                        name="typeCarte"
                        ngDefaultControl
                        optionDisplay="libelle"
                        optionValue="type"
                        rCol="4"
                        required>
                </custom-input>
                <label [translate]="'profilUser.actif.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [(ngModel)]="data.carte.actif" color="primary" name="actif"></mat-checkbox>
                </div>
            </div>
            <div *ngIf="data.carte.typeCarte == typeCarte.AFFAIRE" class="row">
                <custom-input [(ngModel)]="data.carte.typeDebit"
                    [disabled]="!!data.carte.idCarte"
                    [selectOptions]="listeTypeDebit"
                    customType="select"
                    lCol="2"
                    libelle="profilUser.cartePaiement.debit"
                    name="debit"
                    ngDefaultControl
                    optionDisplay="libelle"
                    optionValue="type"
                    rCol="4"
                    required>
                </custom-input>
                <custom-input [(ngModel)]="data.carte.idContrat"
                    [selectOptions]="data.listeContrat"
                    customType="select"
                    lCol="2"
                    libelle="profilUser.cartePaiement.contrat"
                    name="contrat"
                    ngDefaultControl
                    optionDisplay="libelle"
                    optionValue="id"
                    rCol="4"
                    required>
                </custom-input>
            </div>
            <div class="row">
                <ng-container *ngIf="!data.carte.idCarte">
                    <label class="col-md-2 required" [ngClass]="{ 'has-error': numCarte.invalid || numCarte.errors}" [translate]="'profilUser.cartePaiement.numero'"></label>
                    <div class="col-md-4 d-flex flex-row align-items-center">
                        <mat-form-field>
                            <input [(ngModel)]="data.carte.numCarteClair" #numCarte="ngModel" class="mr-1 text-secondary text-dark" creditCard matInput name="numCarte" required type="text"/>
                        </mat-form-field>                
                    </div>
                </ng-container>
                <ng-container *ngIf="!!data.carte.idCarte">
                    <label [translate]="'profilUser.cartePaiement.numero'" class="col-md-2"></label>
                    <div class="col-md-4 d-flex flex-row align-items-center">
                        <mat-form-field>
                            <input [(ngModel)]="data.carte.numCarteClair" disabled class="mr-1 text-secondary text-dark" matInput name="numCarteClair" type="text"/>
                        </mat-form-field>                
                    </div>
                </ng-container>
                
                <custom-input [ngModel]="data.carte.dateFin"
                    [(customModel)]="data.carte.dateFin"
                    customType="date"
                    lCol="2"
                    libelle="profilUser.cartePaiement.date"
                    name="dateFin"
                    ngDefaultControl
                    rCol="4"
                    required
                    dateAfterNow
                    [isSameDateValid]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="data.carte.libelle"
                    customType="input"
                    lCol="2"
                    libelle="profilUser.cartePaiement.libelle"
                    name="libelle"
                    ngDefaultControl
                    rCol="4">
                </custom-input>
            </div>

        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="deleteCarte()" *ngIf="!!this.data.carte.idCarte" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="save()" [disabled]="addCarteForm.invalid || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="!data.carte.idCarte ?'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
