<div class="m-l-20 m-r-20">
    <input type="file" (change)="onFileSelected($event)" style="display:none" #fileInput accept="application/json">
    <div class="file-uploader alert clickable d-flex flex-row align-items-center justify-content-center m-t-20" [class.warning]="isDangerous && !custom.isCustom" (click)="onClick(fileInput)" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
        <div class="col">
            <div class="row">
                <div class="col-1 d-flex flex-row align-items-center justify-content-center"><i *ngIf="!custom.isCustom" class="zmdi zmdi-hc-3x zmdi-alert-triangle"></i></div>
                <div class="col-10 d-flex flex-row align-items-center justify-content-center">
                    <i *ngIf="!selectedFile" class="zmdi zmdi-hc-3x zmdi-download m-r-20"></i>
                    <strong *ngIf="!selectedFile && !isCreation" [translate]="'admin.bibliotheque.internationalisation.infosLangue.jsonSelector.' + (custom.isCustom ? 'custom' : 'standard')"></strong>
                    <strong *ngIf="!selectedFile && !isCreation">&nbsp;-&nbsp;</strong>
                    <strong *ngIf="!selectedFile" [translate]="'admin.bibliotheque.internationalisation.infosLangue.jsonSelector.dropFile'"></strong>
                    <strong *ngIf="!selectedFile && !isCreation">&nbsp;-&nbsp;</strong>
                    <strong *ngIf="!selectedFile && !isCreation" [translate]="'admin.bibliotheque.internationalisation.infosLangue.jsonSelector.' + (custom.isCustom ? 'custom' : 'standard')"></strong>
                    <ul *ngIf="selectedFile" class="fichier">
                        <li><strong translate="document.liste.fichier"></strong>{{ selectedFile.name }}</li>
                        <li><strong translate="document.liste.taille"></strong>{{ selectedFile?.size | filesize }}</li>
                    </ul>
                    <button *ngIf="selectedFile" mat-icon-button (click)="onDelete($event)">
                        <mat-icon color="primary">delete_forever</mat-icon>
                    </button>
                </div>
                <div class="col-1 d-flex flex-row align-items-center justify-content-center"><i *ngIf="!custom.isCustom" class="zmdi zmdi-hc-3x zmdi-alert-triangle"></i></div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center m-t-20" *ngIf="!isCreation">
        <button mat-flat-button color="primary" (click)="onDownload(custom.isCustom)">
            <span translate>{{ 'admin.bibliotheque.internationalisation.infosLangue.jsonSelector.' + (custom.isCustom ? 'downloadCustom' : 'downloadStd') }}</span>
        </button>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-center m-t-20" *ngIf="!isCreation && isSwichable">
        <a (click)="custom.isCustom = !custom.isCustom; selectedFile = null;" [style.font-weight]="custom.isCustom ? 'normal' : 'bold'">
            {{ custom.isCustom ? '>' : '<' }}
            <span *ngIf="custom.isCustom && isDangerous" translate>admin.bibliotheque.internationalisation.infosLangue.jsonSelector.advancedFr</span>
            <span *ngIf="custom.isCustom && !isDangerous" translate>admin.bibliotheque.internationalisation.infosLangue.jsonSelector.advancedNonFr</span>
            <span *ngIf="!custom.isCustom" translate>admin.bibliotheque.internationalisation.infosLangue.jsonSelector.backToCustom</span>
            {{ custom.isCustom ? '<' : '>' }}
        </a>
    </div>
</div>
