import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from "@angular/forms";
import {Distance} from "@domain/geographie/distance";
import {Unite} from "@domain/prestation/unite";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {Origine} from "@domain/geolocalisation/localisation";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {
    DistanceSelectLieuComponent
} from "@components/admin/bibliotheque/geographie/distances/details/generalites/distance-select-lieu/distance-select-lieu.component";
import {LieuDistance, TypeLieuDistance} from "@domain/geolocalisation/lieuDistance";

/**
 * Composant d'affichage de l'onglet Généralités d'une distance
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'distance-generalites'},
    selector: 'distance-generalites',
    templateUrl: './distance-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class DistanceGeneralitesComponent implements OnInit {
    /** Accès à l'enum dans la vue */
    readonly Origine = Origine;

    /** Distance */
    @Input() distance: Distance;

    /** Lieu de départ */
    depart: LieuDistance;

    /** Lieu d'arrivée */
    arrivee: LieuDistance;

    /** Liste des unités */
    listeUnites: Array<Unite>

    /** Indique si on est en création ou en modification */
    @Input() isCreation: boolean;

    /**
     * Constructeur
     *
     * @param geographieService Service géographie
     * @param translateService  Service des traductions
     * @param toastrService     Service des toasts
     * @param matDialog         Service de gestion des boîtes de dialogue
     */
    constructor(private geographieService: GeographieService,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private matDialog: MatDialog) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération de la liste des unités
        this.geographieService.getListeUnites().subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Récupération de la liste des unités pour l'autocomplete distance
                this.listeUnites = result.data.listeUnites;

                //Récupération de l'unité
                if (this.distance.idUnite) {
                    this.distance.unite = this.listeUnites.find(unite => unite.idUnite === this.distance.idUnite);
                } else {
                    this.distance.idUnite = this.listeUnites[0].idUnite;
                    this.distance.unite = this.listeUnites[0];
                }
            } else {
                //Message d'erreur
                TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
            }
        });

        //Récupération de l'utilisateur
        if (this.distance.origine === Origine.ORIGINE_USER) {
            this.geographieService.getUserForDistance(this.distance.idUser).subscribe(result => {
                if (result.codeErreur !== TypeCodeErreur.NO_ERROR) {
                    TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                } else {
                    this.distance.user = result.data.user;
                }
            })
        }

        //Définition du départ pour l'autocomplete
        this.depart = {
            idLieu: this.distance.idDepart,
            libelle: this.distance.strDepart,
            type: TypeLieuDistance.LOCALISATION,
        }

        //Définition de l'arrivée pour l'autocomplete
        this.arrivee = {
            idLieu: this.distance.idArrivee,
            libelle: this.distance.strArrivee,
            type: TypeLieuDistance.LOCALISATION
        }

        //Récupération des adresses de départ et d'arrivée
        if (this.distance.idDepart) {
            this.geographieService.getAdressesForDistance(this.distance.idDepart, this.distance.idArrivee).subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Récupération des adresses
                    this.depart.adresse = result.data.adresseDepart;
                    this.arrivee.adresse = result.data.adresseArrivee;
                } else {
                    //Message d'erreur
                    TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                }
            })
        }

    }

    /**
     * Mise à jour de l'objet de saisie avec l'unité sélectionnée dans l'autocomplete distance
     *
     * @param unite Unité
     */
    onUniteChange(unite: Unite) {
        this.distance.unite = unite;
        this.distance.idUnite = unite.idUnite;
    }

    /**
     * Ouverture de la popin de sélection du lieu (départ ou arrivée)
     * & mise à jour du lieu avec le lieu sélectionné dans la popin
     *
     * @param isDepart Indique s'il faut mettre à jour le départ (true) ou l'arrivée (false)
     */
    selectLieu(isDepart: boolean) {
        //Ouverture de la popin de sélection du lieu
        this.matDialog.open(DistanceSelectLieuComponent, {
            data: {
                isDepart: isDepart
            }
        }).afterClosed().subscribe(data => {
            //Mise à jour des identifiants (départ, arrivée, ville, iata) de la distance
            if (data) {
                //Mise à jour des identifiants de départ
                if (isDepart) {
                    this.depart = data.lieu;
                    switch (this.depart.type) {
                        case TypeLieuDistance.GARE:
                            this.distance.idIataDepart = this.depart.idLieu;
                            this.distance.idVilleDepart = 0;
                            this.distance.idDepart = 0;
                            break;
                        case TypeLieuDistance.AEROPORT:
                            this.distance.idIataDepart = this.depart.idLieu;
                            this.distance.idVilleDepart = 0;
                            this.distance.idDepart = 0;
                            break;
                        case TypeLieuDistance.VILLE:
                            this.distance.idVilleDepart = this.depart.idLieu;
                            this.distance.idIataDepart = 0;
                            this.distance.idDepart = 0;
                            break;
                        case TypeLieuDistance.LOCALISATION:
                            this.distance.idDepart = this.depart.idLieu;
                            this.distance.idVilleDepart = 0;
                            this.distance.idIataDepart = 0;
                    }
                } else {
                    //Mise à jour des identifiants d'arrivée
                    this.arrivee = data.lieu;
                    switch (this.arrivee.type) {
                        case TypeLieuDistance.GARE:
                            this.distance.idIataArrivee = this.arrivee.idLieu;
                            this.distance.idVilleArrivee = 0;
                            this.distance.idArrivee = 0;
                            break;
                        case TypeLieuDistance.AEROPORT:
                            this.distance.idIataArrivee = this.arrivee.idLieu;
                            this.distance.idVilleArrivee = 0;
                            this.distance.idArrivee = 0;
                            break;
                        case TypeLieuDistance.VILLE:
                            this.distance.idVilleArrivee = this.arrivee.idLieu;
                            this.distance.idIataArrivee = 0;
                            this.distance.idArrivee = 0;
                            break;
                        case TypeLieuDistance.LOCALISATION:
                            this.distance.idArrivee = this.arrivee.idLieu;
                            this.distance.idVilleArrivee = 0;
                            this.distance.idIataArrivee = 0;
                    }
                }
            }
        });
    }
}
