<mat-card>
    <mat-card-title>
        <span [translate]="'facture.releve.navigation.releve.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <form autocomplete="off" name="form_releve" #form="ngForm">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <custom-input ngDefaultControl [(ngModel)]="releveFacture.fournisseur"
                        name="fournisseur" #fournisseur="ngModel" customType="autocomplete"
                        libelle="facture.releve.navigation.releve.fournisseur"
                        idName="idFournisseur"
                        [readonly]="releveFacture.idFactureReleve && (releveFacture.importe === true || releveFacture.quantite > 0)" [disabled]="!canModifier" [required]="true"
                        (onChange)="updateDevise()"
                        optionDisplay="raisonSociale"
                        autocompleteType="fournisseur" [autocompleteFilter]="{ raisonSociale: releveFacture.fournisseur?.raisonSociale }">
                    </custom-input>
                    <custom-input ngDefaultControl [(ngModel)]="releveFacture.typeReconciliation"
                        name="typeReconciliation" #typeReconciliation="ngModel" customType="select"
                        libelle="facture.releve.navigation.releve.reconciliation"
                        [readonly]="releveFacture.idFactureReleve && (releveFacture.importe === true || releveFacture.quantite > 0)" [disabled]="!canModifier" [required]="true"
                        [selectOptions]="listeTypeReconciliation" optionValue="id" optionDisplay="libelle">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl [(ngModel)]="releveFacture.numero"
                        name="numero" #numero="ngModel" customType="input"
                        libelle="facture.releve.navigation.releve.numeroReleve"
                        [readonly]="false" [disabled]="!canModifier || releveFacture.importe" [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl [(ngModel)]="releveFacture.montant"
                        name="montant" #montant="ngModel" customType="montant"
                        libelle="facture.releve.navigation.releve.montantTtc"
                        [readonly]="true" [disabled]="!canModifier" [required]="false"
                        [montantDevise]="releveFacture.deviseFournisseur">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl [ngModel]="releveFacture.dateReleve" [(customModel)]="releveFacture.dateReleve"
                        name="date" #date="ngModel" customType="date"
                        libelle="facture.releve.navigation.releve.date"
                        [readonly]="false" [disabled]="!canModifier || releveFacture.importe" [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl [(ngModel)]="releveFacture.montantControle"
                        name="montantControle" #montantControle="ngModel" customType="montant"
                        libelle="facture.releve.navigation.releve.controleMontant"
                        [readonly]="false" [disabled]="!canModifier" [required]="false"
                        [montantDevise]="releveFacture.deviseFournisseur">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl lCol="2" rCol="10" [(ngModel)]="releveFacture.remarque"
                        name="remarque" #remarque="ngModel" customType="textarea"
                        libelle="facture.releve.navigation.releve.remarque"
                        [readonly]="false" [disabled]="!canModifier" [required]="false">
                    </custom-input>
                </div>

                <!-- Zone prédéfinie -->
                <zone-predefinie
                    [listeFieldParam]="listeFieldParam"
                    [fieldContainer]="releveFacture"
                ></zone-predefinie>
                
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>