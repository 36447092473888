<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined align-middle">insert_drive_file</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }} </a>
        </div>
        <ul class="lvi-attrs">
            <li >
                <strong [translate]="'admin.parametres.demat.types.tag.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li >
                <strong [translate]="'admin.parametres.demat.types.tag.objets'"></strong>
                <span>{{ getObjetsCount() }}</span>
            </li>
            <li>
                <strong [translate]="'admin.parametres.demat.types.tag.ocr'"></strong>
                <span>{{ (data.ocr ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
        </ul>
    </div>
    <!-- bouton supprimer -->
    <div class="lvi-actions" *ngIf="!data.used">
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="delete()" translate>global.actions.supprimer</button>
        </mat-menu>
    </div>
</div>
