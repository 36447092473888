import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from '@domain/common/list-view';
import {TypeProfil,User} from '@domain/user/user';
import {UserHabilitation} from '@domain/user/user-habilitation';
import {HabilitationListItemComponent} from '@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/habilitation-list-item/habilitation-list-item.component';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Page} from '@domain/common/http/list-result';
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {LienRoleUser,TypeLienRoleUser,TypePriorite} from "@domain/workflow/lienRoleUser";
import {AddHabilitationPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/add-habilitation-popin/add-habilitation-popin.component";

/**
 * Popin d'affichage de l'historique des habilitations
 */
@Component({
	host: {'data-test-id': 'habilitation-history-popin'},
	templateUrl: './habilitation-history-popin.component.html',
	styles: [
		'::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
		'::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
	]
})
export class HabilitationHistoryPopinComponent implements OnInit {
	/** Liste des habilitations de l'utilisateur */
	listeUserHabilitations: UserHabilitation[] = [];

	/** ListView des habilitations de l'utilisateur */
	listeHabilitations: ListView<UserHabilitation,HabilitationListItemComponent>;

	/** Liste des habilitations d'un profil */
	listeProfilUserHabilitations: LienRoleUser[] = null;

	//Import des énumérations pour le DOM
	readonly TypeProfil = TypeProfil;
	readonly TypeLienRoleUser = TypeLienRoleUser;
	readonly TypePriorite = TypePriorite

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param userService le service de gestion de l'utilisateur
	 * @param translateService le moteur de traduction
	 * @param matDialog le gestionnaire de popin
	 * @param matDialogRef la référence vers la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { user: User },
		private userService: EntrepriseUtilisateursService,
		private translateService: TranslateService,
		private matDialog: MatDialog,
		public matDialogRef: MatDialogRef<HabilitationHistoryPopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Construction de la liste des habilitations
		this.listeUserHabilitations = this.userService.buildListeUserHabilitation(this.data.user,true);

		//Initialisation de la liste des habilitations
		this.listeHabilitations = new ListView<UserHabilitation,HabilitationListItemComponent>({
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.historiqueHabilitations'),
			component: HabilitationListItemComponent,
			isFrontendList: true,
			listeActions: [
				{
					icon: 'close',
					onPress: () => this.matDialogRef.close()
				}
			],
			data: {
				listeResultats: this.listeUserHabilitations,
				numPage: 0,
				nbPagesTotal: 1,
				nbObjetsTotal: this.listeUserHabilitations.length,
				nbObjetsDansPage: this.listeUserHabilitations.length,
				nbObjetsParPage: this.listeUserHabilitations.length
			} as Page<UserHabilitation>,
			extraOptions: {
				isHistory: true,
				showHistory: this.showHistory.bind(this)
			}
		});
	}

	/**
	 * Affiche l'historique d'une fonction donnée
	 *
	 * @param fonction la fonction
	 */
	showHistory(fonction: TypeProfil): void {
		//Définition de la liste des habilitations du profil
		this.listeProfilUserHabilitations = this.listeUserHabilitations.filter(userHabilitation => userHabilitation.fonction === fonction)[0].liensRoleUser;
	}

	/**
	 * Retour à la liste classqiue des habilitations
	 */
	onGoBack(): void {
		//Suppression de la liste des habilitations du profil
		delete this.listeProfilUserHabilitations;
	}

	/**
	 * Ouverture d'une habilitation
	 *
	 * @param habilitation l'habilitation
	 */
	openHabilitation(habilitation: LienRoleUser): void {
		//Ouverture de la popin
		this.matDialog.open(AddHabilitationPopinComponent,{
			data: {
				isCreation: false,
				habilitation: habilitation,
				isCollab: this.data.user.listeLienRoleUsers.some(lru => lru.role.fonction === TypeProfil.COLLABORATEUR),
				isHistory: true
			},
			width: '80%'
		});
	}

	/**
	 * Récupération du libellé du profil consulté pour le titre
	 */
	getLibelleProfil(): string {
		return TypeProfil.getLibelle(this.translateService,this.listeProfilUserHabilitations[0].role.fonction);
	}
}
