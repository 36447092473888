import {Pipe, PipeTransform} from '@angular/core';
import {Report} from "../../domain/reporting/report";

@Pipe({
    name: 'report'
})
/**
 * Pipe pour filtrer les rapports en fonction du libellé et groupe défini
 */
export class ReportPipe implements PipeTransform {

    transform(items: Report[]): Report[] {
        return items?.sort((a, b) => {
            return a.groupeDefini !== b.groupeDefini ? a.groupeDefini - b.groupeDefini : a.libelle.localeCompare(b.libelle);
        });
    }
}
