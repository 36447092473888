<page-header
        [title]="(isCreation ? 'admin.entreprise.utilisateurs.detail.title.creation' : 'admin.entreprise.utilisateurs.detail.title.consultation') | translate: { matricule: userConsulte?.matricule || '' }"
        [listeItems]="listeTabItems"
        (onSelectedItemChange)="onSelectedItemChange($event)"
        [selectedIndex]="selectedIndex"
        (onGoBack)="onGoBack()">
</page-header>

<please-wait *ngIf="isLoading"></please-wait>

<div *ngIf="isGeneralitesLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.GENERALITES">
    <user-generalites
            [user]="userConsulte"
            (userChanged)="refreshUser()"
            [userConnecte]="session.user"
            [isReadOnly]="!isCardAllowed(RoleAdmin.DROIT_USER_GESTION)"
            [isDroitHabilitations]="isCardAllowed(RoleAdmin.DROIT_HABILITATION_GESTION)">
    </user-generalites>
</div>

<div *ngIf="isMetierLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.METIER">
    <user-metier
            [user]="userConsulte"
            [isCollabInit]="isCollabInit"
            (userChanged)="refreshUser()"
            [isDroitInfoMetier]="isCardAllowed(RoleAdmin.DROIT_USER_GESTION)"
            [isDroitFraisMission]="isCardAllowed(RoleAdmin.DROIT_USER_GESTION)"
            [isDroitPrefAnalytiques]="isCardAllowed(RoleAdmin.DROIT_USER_ANALYTIQUE)">
    </user-metier>
</div>

<div *ngIf="isProfilLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.PROFIL">
    <user-profil
            [idUser]="userConsulte.idUser"
            [isCollabInit]="isCollabInit"
            [isDroitProfilVoyageur]="isCardAllowed(RoleAdmin.DROIT_PROFIL_VOYAGEUR_GESTION)"
            [isDroitContactUrgence]="isCardAllowed(RoleAdmin.DROIT_PV_CONTACT_URGENCE)"
            [isDroitAdresse]="isCardAllowed(RoleAdmin.DROIT_PROFIL_VOYAGEUR_GESTION)"
            [isDroitIdentite]="isCardAllowed(RoleAdmin.DROIT_PV_GESTION_DOCUMENT)"
            [isDroitCarteAbonnement]="isCardAllowed(RoleAdmin.DROIT_PV_CARTES_ABONNEMENT)">
    </user-profil>
</div>

<div *ngIf="isInfosBancairesLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.INFORMATIONS_BANCAIRES">
    <user-infos-bancaires
            [user]="userConsulte"
            [isCollabInit]="isCollabInit">
    </user-infos-bancaires>
</div>

<div *ngIf="isVehiculesLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.VEHICULES">
    <user-vehicules
            [idUser]="userConsulte.idUser"
            [isCollabInit]="isCollabInit">
    </user-vehicules>
</div>

<div *ngIf="isMobiliteLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.MOBILITE">
    <user-mobilite
            [idUser]="userConsulte.idUser"
            [isCollabInit]="isCollabInit"
            [isAdmin]="session?.isAdmin">
    </user-mobilite>
</div>

<div *ngIf="isFiltresLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.FILTRES">
    <user-filtres
            [idUser]="userConsulte.idUser"
            [isCollabInit]="isCollabInit"
            [isResponsable]="userConsulte?.fonctionResponsable">
    </user-filtres>
</div>

<div *ngIf="isHistoriqueLoaded && userConsulte" [hidden]="selectedItem.code !== Onglet.HISTORIQUE">
    <user-historique [idUser]="userConsulte.idUser"></user-historique>
</div>
