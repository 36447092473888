<mat-form-field class="d-flex" [floatLabel]="floatLabel">
	<input
			type="text" [placeholder]="placeholder" matInput #input="matInput" [(ngModel)]="searchedValue"
			[title]="searchedValue ? searchedValue : ''" [readonly]="disabled"
			[formControl]="autocompleteControl" [matAutocomplete]="autocomplete"
			(change)="onChange($event); resetValue()" (blur)="onBlur($event)" [required]="required"/>
	<mat-icon *ngIf="isShowSearchIcon" [hidden]="disabled">search</mat-icon>
	<mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="onOptionSelected($event.option.value)" [panelWidth]="panelWidth">
		<mat-option *ngIf="isLoading">
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</mat-option>
		<ng-container *ngIf="!isLoading">
			<mat-option *ngFor="let item of listeItems" [value]="item">
				<span>{{ item.libelle }}</span>
			</mat-option>
		</ng-container>
	</mat-autocomplete>
</mat-form-field>
