import {Component, Input} from "@angular/core";
import {Entreprise} from "@domain/admin/entreprise/entreprise";
import {ControlContainer, NgForm} from "@angular/forms";

/**
 * La partie informations comptable de l'entreprise dans l'admin
 *
 * @author Alexandre Soulard
 * @date 14/10/2022
 */
@Component({
    host: {'data-test-id': 'description-comptable'},
    selector: 'description-comptable',
    templateUrl: './comptable.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ComptableComponent {
    /** Entreprise présente dans la table ns_entrep, utilisée pour remplir les différents champs */
    @Input() entreprise: Entreprise;
}