import {Component, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Lien} from "../../domain/boitelien/boitelien";

/**
 * Composant boîte à liens.
 */
@Component({
    selector: 'app-boite-lien-dialog',
    templateUrl: './boite-lien-dialog.component.html'
})
export class BoiteLienDialogComponent {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private matDialogRef: MatDialogRef<BoiteLienDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            listeBoiteALien: Array<Lien>
        }
    ) { }
}
