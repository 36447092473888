import {Component,EventEmitter,Input,Output} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant de sélection d'un fichier JSON
 */
@Component({
    selector: 'json-selector',
    templateUrl: './json-selector.component.html',
    styleUrls: ['./json-selector.component.scss']
})
export class JsonSelectorComponent {
    /** Fichier sélectionné */
    @Input() selectedFile: File = null;

    /** Survol en cours */
    isDragging: boolean = false;

    /** Flag d'avertissement (gère l'affichage en rouge et la fenêtre d'avertissement) */
    @Input() isDangerous: boolean = false;

    /** true si custom, false sinon */
    @Input() custom: { isCustom: boolean } = { isCustom: true };

    /** Flag de création */
    @Input() isCreation: boolean = false;

    /** Évènement de fichier sélectionné */
    @Output() fileSelected = new EventEmitter<File>();

    /** Langue concernée */
    @Input() codeLangue: string;

    /** Flag de bascule entre fichier std/custom */
    @Input() isSwichable: boolean = true;

    constructor(private toastrService: ToastrService, private translateService: TranslateService, private confirmService: ConfirmService) { }

    /**
     * Méthode de contrôle en cas de danger
     *
     * @param input input concerné
     */
    onClick(input: HTMLInputElement): void {
        //Si l'objet est paramétré comme dangereux
        if (this.isDangerous && !this.custom.isCustom) {
            //Demande de confirmation
            this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.warningFrEdit')).subscribe( (isConfirmed) => {
                //En cas de confirmation
                if (isConfirmed) {
                    //Ouverture de la fenêtre
                    input.click();
                }
            });
        } else {
            //Sinon ouverture de la fenêtre directement
            input.click();
        }
    }

    /**
     * Sélection d'un fichier
     *
     * @param event évènement de sélection de fichier
     */
    onFileSelected(event): void {
        //Récupération du fichier
        const file: File = <File>event.target.files[0];

        //Purge de la sélection
        event.target.value = '';

        //Vérification du fichier
        this.fileCheck(file);
    }

    /**
     * Survol souris avec un fichier
     *
     * @param event évènement de survol souris avec un fichier
     */
    onDragOver(event: DragEvent): void {
        //Interception de la propagation de l'évènement
        event.stopPropagation();
        event.preventDefault();

        //Bascule du flag de survol
        this.isDragging = true;
    }

    /**
     * Largage du fichier
     *
     * @param event évènement de largage du fichier
     */
    onDrop(event: DragEvent): void {
        //Interception de la propagation de l'évènement
        event.stopPropagation();
        event.preventDefault();

        //Bascule du flag de survol
        this.isDragging = false;

        //Si l'évènement contient des données
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            //Récupération du fichier
            const file: File = <File>event.dataTransfer.items[0].getAsFile();

            //Si l'objet est paramétré comme dangereux
            if (this.isDangerous && !this.custom.isCustom) {
                //Demande de confirmation
                this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.warningFrEdit')).subscribe( (isConfirmed) => {
                    //En cas de confirmation
                    if (isConfirmed) {
                        //Vérification du fichier
                        this.fileCheck(file);
                    }
                });
            } else {
                //Sinon vérification du fichier directement
                this.fileCheck(file);
            }
        }
    }

    /**
     * Vérification du fichier
     *
     * @param file fichier concerné
     */
    fileCheck(file: File): void {
        //Si le fichier a la bonne extension
        if (file.type.toLowerCase() == "application/json") {
            //Stockage du fichier
            this.selectedFile = file;

            //Emission du fichier
            this.fileSelected.emit(this.selectedFile);
        } else {
            //Sinon on affiche le message d'erreur extension invalide
            this.toastrService.error(this.translateService.instant('global.errors.extensionInvalide', { fileName: file.name }));
        }
    }

    /**
     * Suppression du fichier
     *
     * @param event évènement de suppression
     */
    onDelete(event: MouseEvent): void {
        //Interception de la propagation de l'évènement
        event.stopPropagation();
        event.preventDefault();

        //Purge du fichier
        this.selectedFile = null;

        //Emission sans fichier
        this.fileSelected.emit(null);
    }

    /**
     * Téléchargement du dictionnaire
     *
     * @param isCustom  dictionnaire custom si true, standard si false
     */
    onDownload(isCustom: boolean) {
        //Création d'un lien artificiel
        const url = `./lang/i18n/${this.codeLangue}_${isCustom ? 'custom' : 'std'}.json`;
        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop() || 'download.json';
        document.body.appendChild(a);

        //Click virtuel
        a.click();

        //Suppression du lien
        document.body.removeChild(a);
    }
}
