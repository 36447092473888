<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">assignment</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.reference">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.franchisePopin.reference'"></strong>
                <span>{{ data.reference }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.franchisePopin.debut'"></strong>
                <span>{{ data.dateDebut | date:'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.franchisePopin.fin'"></strong>
                <span>{{ data.dateFin | date:'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.franchisePopin.franchiseAller'"></strong>
                <span>{{ data.franchiseAller | number:'1.2-2' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.franchisePopin.franchiseAllerRetour'"></strong>
                <span>{{ data.franchiseAllerRetour | number:'1.2-2' }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button color="warn" (click)="deleteContrat()">
            <mat-icon class="c-danger" [matTooltip]="'global.actions.supprimer' | translate">clear</mat-icon>
        </button>
    </div>
</div>