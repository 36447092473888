<div class="lvi-content">
    <div class="avatar"></div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.manufacturer}} - {{ data.model }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.manufacturer">
                <strong [translate]="'profilUser.mobile.marque'"></strong>
                <span>{{ data.manufacturer }}</span>
            </li>
            <li *ngIf="data.model">
                <strong [translate]="'profilUser.mobile.modele'"></strong>
                <span>{{ data.model }}</span>
            </li>
            <li  *ngIf="data.lastCheckDate">
                <strong [translate]="'profilUser.mobile.derniereConnexion'"></strong>
                <span>{{ data.lastCheckDate }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data.actif ? 'global.oui' : 'global.non'"></span>
            </li>
        </ul>
    </div>
</div>
