import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ZUReferentiel} from "@domain/zone-utilisateur/zuReferentiel";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {ToastrService} from "ngx-toastr";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {MatDialog} from "@angular/material/dialog";
import {AddZuRefPopinComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/detail/config/add-zu-ref-popin/add-zu-ref-popin.component";

/**
 * Composant d'un item de la liste des référentiels d'une ZU
 */
@Component({
	host: {'data-test-id': 'zone-parametrable-config'},
	templateUrl: './zu-ref-list-item.component.html'
})
export class ZuRefListItemComponent extends ListViewItem<ZUReferentiel> {
	/**
	 * Constructeur
	 *
	 * @param matDialog le gestionnaire de popin
	 * @param confirmService le service de confirmation d'action
	 * @param translateService le moteur de traduction
	 * @param zoneUtilisateurService le service de gestion des ZU
	 * @param toastrService le toaster
	 */
	constructor(
		private matDialog: MatDialog,
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private zoneUtilisateurService: ZonesUtilisateursService,
		private toastrService: ToastrService
	) {
		super();
	}

	/**
	 * Ouverture du ZUReferentiel en édition
	 */
	open(): void {
		//Ouverture de la popin
		this.matDialog.open<AddZuRefPopinComponent,any,boolean>(AddZuRefPopinComponent,{
			data: {
				zuRef: this.data
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			if (refresh) {
				//Rafraîchissement de la liste
				this.liste.refresh();
			}
		});
	}

	/**
	 * Suppression du référentiel courant
	 */
	delete(): void {
		//Confirmation d'action
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service de suppression
				this.zoneUtilisateurService.deleteZURef(this.data.idZURef)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du result
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Rechargement de la liste
							this.liste.refresh();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}
