/**
 * Engagements - Représente une ligne d'affichage d'un engagement
 */
export abstract class EngagementsItem {
    /** Données */
    montantPrevisionnel: number;
    montantReel: number;
    ecart: number;
    pourcentageEcart: number;

    /** Indicateur de la ligne de total */
    isTotal: boolean = false;

    /**
     * Constructeur
     * @param isTotal Indique si la ligne est une ligne de total
     */
    constructor(isTotal: boolean) {
        this.isTotal = isTotal;
    }

    /** Accesseurs */
    abstract getType(): number;
    abstract getTraduction(previsionnel?: boolean): string;
}
