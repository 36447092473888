import {CustomField} from "./custom-field";
import {FieldUsed} from "@domain/travel/field-used";

/**
 * Représente la valeur appliquée à un custom field pour une configuration SBT
 *
 * @author Laurent Convert
 * @date 20/11/2023
 */
export class CustomFieldUsed extends FieldUsed {
	idCustomFieldUsed: number;
	customField: CustomField;

	constructor(dto?: FieldUsed) {
		super(dto);
	}

	getKey(): number {
		return this.idCustomFieldUsed;
	}

	getId(): number {
		return this.idCustomFieldUsed;
	}

	getCode(): string {
		return this.customField?.code;
	}

	get field(): CustomField {
		return this.customField;
	}

	set field(field: CustomField) {
		this.customField = field;
	}
}