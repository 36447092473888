import {Component,OnDestroy,OnInit} from '@angular/core';
import {TypeZoneUtilisateur,ZoneUtilisateur} from "@domain/zone-utilisateur/zone-utilisateur";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {BehaviorSubject,Subscription} from "rxjs";
import {ActivatedRoute,Router} from "@angular/router";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {Onglets as OngletsZU} from "@components/admin/bibliotheque/zones-utilisateurs/zones-utilisateurs.component";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant du détail d'une zone paramétrable
 */
@Component({
	host: {'data-test-id': 'zone-parametrable-detail'},
	templateUrl: './zone-parametrable-detail.component.html'
})
export class ZoneParametrableDetailComponent implements OnInit,OnDestroy {
	/**
	 * Liste des onglets
	 */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: Onglets.CONFIGURATION,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.tabs.configuration'),
		}
	];

	/** Onglet courant */
	_selectedItem: PageHeaderItem;

	/** Getter de l'onglet courant */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/** Setter de l'onglet courant (on le surcharge pour gérer le chargement des onglets) */
	set selectedItem(item: PageHeaderItem) {
		//Édition de la variable privée
		this._selectedItem = item;

		//Bascule du statut de chargement de l'onglet sélectionné
		switch (item.code) {
			case Onglets.CONFIGURATION:
				this.isConfigurationLoaded = true;
				break;
		}
	}

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Indicateur de chargement de l'onglet "CONFIGURATION" */
	isConfigurationLoaded: boolean = false;

	/** Alerte de l'entête */
	alerte: Alerte = new Alerte({
		niveau: NiveauAlerte.RGPD,
		message: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.alerte'),
		hasHtml: true,
		icon: 'policy'
	});

	/** Souscription aux changements d'idZU dans l'URL */
	idZuSubscription: Subscription;

	/** Zone utilisateur courante */
	zu: ZoneUtilisateur;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Traitement en cours */
	isPending: boolean;

	//Imports des énums pour le DOM
	readonly NiveauAlerte = NiveauAlerte;
	readonly Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param activatedRoute la route actuelle
	 * @param zonesUtilisateursService le service de gestion des ZU
	 * @param translateService le moteur de traduction
	 * @param toastrService le toasteur
	 * @param router le routeur d'Angular
	 * @param confirmService le service de confirmation
	 */
	constructor(
		private activatedRoute: ActivatedRoute,
		private zonesUtilisateursService: ZonesUtilisateursService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private router: Router,
		private confirmService: ConfirmService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Souscription aux changements d'idZU dans l'URL
		this.idZuSubscription = this.activatedRoute.params.subscribe(params => {
			//Récupération de l'idZU dans la route
			const idZU: number = +params['idZU'];

			//S'il vaut 0
			if (idZU === 0) {
				//Il s'agit d'une création, on initialise l'objet ZU
				this.zu = new ZoneUtilisateur();

				//Et on positionne les valeurs par défaut
				this.zu.idZU = idZU;
				this.zu.actif = true;
			} else {
				//Chargement de la ZU correspondante
				this.loadZU(idZU);
			}
		});

		//Listes des actions possibles
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-sauvegarde',
				libelle: 'global.actions.enregistrer',
				doAction: () => this.saveZU()
			},{
				type: TypeAction.SECONDARY,
				icone: 'nio icon-suppression',
				libelle: 'global.actions.supprimer',
				doAction: () => this.deleteZU(),
				isVisible: () => this.zu?.idZU > 0,
				isDisabled: () => this.zu?.hasData
			}
		]);
	}

	/**
	 * Chargement de la zone utilisateur
	 *
	 * @param idZU l'identifiant de la ZU
	 */
	loadZU(idZU: number): void {
		//Chargement en cours
		this.isPending = true;

		//Réinitialisation de la ZU courante
		delete this.zu;

		//Appel au service
		this.zonesUtilisateursService.loadZU(idZU)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Récupération de la ZU dans le result
					this.zu = result.data.zu;

					//Récupération du nombre de données définies dans le référentiel pour cette ZU
					this.zu.nbData = result.data.nbData

					//Récupération de l'existence de saisies pour la zone utilisateur
					this.zu.hasData = result.data.hasData;

					//Pour éviter l'alerte du custom input, on force à false la valeur par défaut si c'est une ZU de type checkbox
					if (this.zu.typeChamp === TypeZoneUtilisateur.CHECKBOX && this.zu.boolDefaut === undefined) {
						this.zu.boolDefaut = false;
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Enregistrement de la zone utilisateur
	 */
	saveZU(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.zonesUtilisateursService.saveZU(this.zu)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Si on est en création
					if (this.zu.idZU === 0) {
						//On navigue vers la ZU nouvellement créée
						this.router.navigate(['Admin/Bibliotheque/ZoneUtilisateur',result.data.id_zu]);
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de la zone utilisateur
	 */
	deleteZU(): void {
		//Confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Chargement en cours
				this.isPending = true;

				//Appel au service
				this.zonesUtilisateursService.deleteZU(this.zu.idZU)
					.pipe(first(),finalize(() => this.isPending = false))
					.subscribe((result: Result) => {
						//Vérification du result
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Retour à la liste
							this.onGoBack();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param item l'onglet sélectionné
	 */
	onSelectedItemChange(item: PageHeaderItem) {
		//Mise à jour de l'indicateur d'activation pour chaque onglet
		this.listeTabItems?.forEach(tab => {
			tab.selected = tab === item;
		});

		//Mise à jour de l'onglet sélectionnée
		item.selected = true;
		this.selectedItem = item;
		this.selectedIndex = this.listeTabItems.findIndex(i => i.code === item.code);
	}

	/**
	 * Clic sur le bouton retour
	 */
	onGoBack(): void {
		//Retour à la liste
		this.router.navigate(['Admin/Bibliotheque/ZonesUtilisateurs'],{state: {'tabToLoad': OngletsZU.ZONES_PARAMETRABLES}})
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		this.idZuSubscription.unsubscribe();
	}
}

/**
 * Onglets
 */
export enum Onglets {
	CONFIGURATION = "CONFIGURATION"
}