<mat-card>
    <mat-card-title>
        <span [translate]="'admin.ongletOutils.cadreGestionBudget.titre'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item clickable" (click)="showPopupProcedure(ProcedureBudgetaire.IMPUTATION)">
                    <div class="list-view-item-hover">
                        <div class="lvi-content">
                            <div class="avatar"><i class="material-icons">mail_outline</i></div>
                            <div class="lvi-body"><div class="title"><a [translate]="'admin.ongletOutils.cadreGestionBudget.Imputation'"></a></div></div>
                        </div>
                    </div>
                </div>
                <div class="listview-item clickable" (click)="showPopupProcedure(ProcedureBudgetaire.LIBERATION)">
                    <div class="list-view-item-hover">
                        <div class="lvi-content">
                            <div class="avatar"><i class="material-icons">mail_outline</i></div>
                            <div class="lvi-body"><div class="title"><a [translate]="'admin.ongletOutils.cadreGestionBudget.Liberation'"></a></div></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="typePortee == TypePortee.OD" class="listview-item clickable" (click)="showPopupProcedure(ProcedureBudgetaire.CLOTURE)">
                    <div class="list-view-item-hover">
                        <div class="lvi-content">
                            <div class="avatar"><i class="material-icons">mail_outline</i></div>
                            <div class="lvi-body"><div class="title"><a [translate]="'admin.ongletOutils.cadreGestionBudget.Cloture'"></a></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<ng-template #messageConfirmation>
    <span [translate]="'admin.ongletOutils.popupBudget.messageConfirmation'"></span><strong>{{titreProcedure | translate | uppercase}}</strong>
    <span>{{'admin.ongletOutils.pourLobjet' | translate}} "{{cleTradPortee | translate: {numero: idObjet} }}".</span>
</ng-template>