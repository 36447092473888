import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {forkJoin,Observable,of} from "rxjs";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {ChampComplementaire} from "@domain/user/champ-complementaire";
import {ProfilAlertesConfig} from "@domain/configuration/profil-alertes-config";
import {ExternesParams} from "@domain/participant/externes-params";

/**
 * Service du menu Entreprise > Utilisateurs > Paramètres
 */
@Injectable()
export class UtilisateursParametresService {
	/**
	 * Constructeur
	 *
	 * @param http le client HTTP
	 */
	constructor(
		private http: HttpClient
	) {
	}

	/**
	 * Chargement des champs complémentaires de l'utilisateur
	 */
	loadChampsComplementaires(): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Entreprise/getChampsComplementaires`);
	}

	/**
	 * Enregistrement d'un champ complémentaire utilisateur
	 *
	 * @param champ le champ complémentaire
	 */
	saveChampComplementaire(champ: ChampComplementaire): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entreprise/saveChampComplementaire`,champ);
	}

	/**
	 * Suppression d'un champ complémentaire utilisateur
	 *
	 * @param champ le champ complémentaire
	 */
	deleteChampComplementaire(champ: ChampComplementaire): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entreprise/deleteChampComplementaire`,champ);
	}

	/**
	 * Chargement de la configuration des alertes du profil
	 */
	loadProfilAlerteConfig(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilConfig/loadConfig`,null);
	}

	/**
	 * Chargement des paramètres relatifs aux externes
	 */
	loadExternesParams(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Externe/loadParams`,null);
	}

	/**
	 * Enregistrement des paramètres des utilisateurs
	 *
	 * @param config la configuration des alertes du profil
	 * @param params le paramétrage des externes, peut être undefined si c'est un sous-admin car il n'a pas accès aux paramètres des externes
	 */
	saveUsersParams(config: ProfilAlertesConfig,params?: ExternesParams): Observable<Result[]> {
		//Définition des sources
		const forks: Observable<Result>[] = [
			this.http.post<Result>(`${environment.baseUrl}/controller/ProfilConfig/saveConfig`,config),
			params ? this.http.post<Result>(`${environment.baseUrl}/controller/Externe/saveParams`,params) : of({codeErreur:TypeCodeErreur.NO_ERROR} as Result)
		];

		return forkJoin(forks);
	}
}
