import {Component} from '@angular/core';

/**
 * Composant de dashboard
 */
@Component({
    host: {'data-test-id': 'dashboard'},
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
}
