import {Component,OnDestroy,OnInit,ViewChild} from "@angular/core";
import {MaintenanceParam} from "@domain/admin/maintenance/maintenance-param";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {BehaviorSubject,Subscription} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ManualUpdateComponent} from "@components/admin/maintenance/migrations/parametres/manual-update.component";
import {KeyStatutApplication,KeyStatutWorkflow} from "@domain/admin/statut-application/statut-application";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {filter} from "rxjs/operators";
import {AdminPasswordPopupComponent} from "@components/admin/maintenance/migrations/parametres/admin-password-popup.component";
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";
import {NgForm} from "@angular/forms";

/**
 * Onglet paramètres
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'parametres'},
    templateUrl: './parametres.component.html',
    styleUrls: ['./parametres.component.scss']
})
export class ParametresComponent implements OnInit, OnDestroy {
    /** Clés des statuts workflow */
    readonly KeyStatutWorkflow = KeyStatutWorkflow;

    /** Paramètres de maintenance */
    maintenanceParam: MaintenanceParam = new MaintenanceParam();

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean = false;

    /** Souscriptions */
    souscriptions: Subscription[] = [];

    /** Statut du Workflow */
    synchroWfStatut: KeyStatutWorkflow;

    /** Statut de l'application */
    statutApplication: KeyStatutApplication;

    /** Clés de statut de l'application */
    readonly KeyStatutApplication = KeyStatutApplication;

    /** Message de maintenance */
    messageMaintenance: string;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /**
     * Constructeur
     */
    constructor(
        private migrationsService: MigrationsService,
        private statutWorkflowService: StatutWorkflowService,
        private statutApplicationUserService: StatutApplicationUserService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private matDialog: MatDialog
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Récupération des paramètres
        this.souscriptions.push(this.migrationsService.getParam().subscribe(maintenanceParam => this.maintenanceParam = maintenanceParam));

        //Abonnement à la mise à jour du statut du Workflow
        this.souscriptions.push(this.statutWorkflowService.synchroWFStatut$.subscribe(statut => this.synchroWfStatut = statut?.statut as KeyStatutWorkflow));

        //Abonnement à la mise à jour du statut de l'application
        this.souscriptions.push(this.migrationsService.appStatut$.pipe(filter(statut => !!statut)).subscribe((statut) => {
            //Récupération du statut
            this.statutApplication = statut?.applicationStatut as KeyStatutApplication;

            //Récupération du message de maintenance
            this.messageMaintenance = statut?.messageMaintenance;
        }));

        //Abonnement à la mise à jour de la maintenance en cours
        this.souscriptions.push(this.migrationsService.isMaintenanceEnCours$.subscribe((isMaintenanceEnCours) => this.isMaintenanceEnCours = isMaintenanceEnCours));

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save(),
            isDisabled: () => !this.form?.valid
        },{
            type: TypeAction.SECONDARY,
            icone: 'material-symbols-rounded',
            iconeName: 'rule_settings',
            libelle: 'admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.floatingButton',
            doAction: () => this.openManualUpdatePopup(),
            isVisible: () => !this.isMaintenanceEnCours && this.synchroWfStatut == KeyStatutWorkflow.HISTO_WF_NONE && this.statutApplication != KeyStatutApplication.HORS_LIGNE_TEMPO
        }]);
    }

    /** Sauvegarde de la page */
    save(): void {
        //Enregistrement des nouveaux paramètres
        this.migrationsService.saveMaintenanceParam(this.maintenanceParam).subscribe(result => {
            //S'il n'y a pas d'erreur, on refresh tout
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Récupération de l'ID
                this.maintenanceParam.idMaintenanceParam = result.data.idMaintenanceParam;

                //On affiche un message de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
            } else {
                //En cas d'erreur, on affiche un message
                TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
            }
        },error => {
            //En cas d'erreur, on affiche un message
            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
        });
    }

    /**
     * Ouverture de la popup de migration manuelle
     */
    openManualUpdatePopup(): void {
        //Ouverture de la popup
        this.matDialog.open(ManualUpdateComponent);
    }

    /**
     * Demande de changement du statut de l'application
     */
    switchAppStatus(): void {
        //Ouverture de la boîte de dialogue pour la saisie du mdp admin
        this.matDialog.open(AdminPasswordPopupComponent, {
            width: '60%',
            height: '200px'
        }).afterClosed().subscribe(result => {
            //Si la saisie du mdp est correcte
            if (result?.success) {
                //Si l'application est en ligne
                if (this.statutApplication == KeyStatutApplication.EN_LIGNE) {
                    //Demande du forçage en maintenance
                    this.migrationsService.forceAppToMaintenance(this.messageMaintenance).subscribe(() => {
                        this.migrationsService.checkAppliStatut();
                        this.statutApplicationUserService.checkPublicApplicationStatus(true);
                    });
                } else {
                    //Sinon demande du forçage en ligne
                    this.migrationsService.forceAppToOnline().subscribe(() => {
                        this.migrationsService.checkAppliStatut();
                        this.statutApplicationUserService.checkPublicApplicationStatus(true);
                    });
                }
            }
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Parcours des souscriptions
        for (const sub of this.souscriptions) {
            //Désabonnement
            sub.unsubscribe();
        }
    }
}
