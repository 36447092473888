import {Component,EventEmitter,Input,Output,ViewChild} from "@angular/core";
import {ControlContainer,NgForm} from "@angular/forms";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from '@angular/common';
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage des informations du cadre "langue"
 */
@Component({
    host: {'data-test-id': 'langue-infos-configuration'},
    selector: 'langue-infos-configuration',
    templateUrl: './langue-infos-configuration.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class LangueInfosConfigurationComponent {
    /** Langue sélectionnée */
    @Input() langue: Langue;

    /** Fichier sélectionné */
    @Input() selectedFile: File = null;

    /** true si custom, false sinon */
    @Input() custom: { isCustom: boolean } = { isCustom: false };

    /** Liste des variants possibles */
    @Input() listeVariant: string[];

    /** Évènement pour la sélection d'un fichier */
    @Output() fileSelected = new EventEmitter<File>();

    /** Validité du formulaire */
    @Output() isFormValid = new EventEmitter<boolean>();

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Présélection des variants les plus courants */
    shortListVariant: { code: string, libelle: string }[] = [
        { code: 'fr', libelle: this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.langueCard.libelleCodeFr') },
        { code: 'en', libelle: this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.langueCard.libelleCodeEn') },
        { code: 'en-GB', libelle: this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.langueCard.libelleCodeEnGB') }
    ];

    /** Variant sélectionné */
    selectedVariant: { code: string, libelle: string };

    /** Date pour la preview */
    previewDate: Date = new Date();

    /** Montant pour la preview */
    previewAmount: number = 123456.78;

    /** Variant pour la preview */
    previewVariant: string;

    constructor(private confirmService: ConfirmService, private translateService: TranslateService, private toastr: ToastrService) {}

    /**
     * Initialisation du composant
     */
    async ngOnInit() {
        //Recherche du variant actif dans la shortlist
        this.selectVariantFromShortList();

        //Rafraichissement de la validité
        this.onVariantChange();
    }

    /**
     * Mise à jour du fichier sélectionné
     *
     * @param file fichier concerné
     */
    onFileSelected(file: File): void {
        //Mise à jour du fichier
        this.selectedFile = file;

        //Emission du fichier aux abonnés
        this.fileSelected.emit(file);

        //Rafraichissement de la validité
        this.onVariantChange();
    }

    /**
     * Changement de valeur de la checkbox "Par défaut"
     *
     * @param event nouvelle valeur
     */
    onDefautChange(event): void {
        //Si la valeur est true
        if (event) {
            //Demande de confirmation
            this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.warningParDefaut')).subscribe( (isConfirmed) => {
                //Application de la valeur retournée
                this.langue.parDefaut = isConfirmed;
                this.isFormValid.emit(this.form.valid);
            });
        } else {
            //Sinon forçage à false
            this.langue.parDefaut = false;
            this.isFormValid.emit(this.form.valid);
        }
    }

    /**
     * Détection de modification du variant
     */
    async onVariantChange(): Promise<void> {
        //Si le variant sélectionné vaut null, on ignore l'appel
        if (this.selectedVariant) {
            //Sauvegarde du code
            const codeVariant = this.selectedVariant.code == "objetTampon" ? this.selectedVariant.libelle : this.selectedVariant.code;

            //Masquage du composant pour destruction
            this.selectedVariant = undefined;

            //Si le variant est invalide
            if (!this.listeVariant.includes(codeVariant)) {
                //Message d'erreur
                this.toastr.error(this.translateService.instant('admin.bibliotheque.internationalisation.infosLangue.langueCard.erreurVariant'));

                //Rétablissement du variant précédent
                setTimeout(() => this.selectedVariant = this.shortListVariant.find(v => v.code == this.langue.codeVariant));

                //Sortie forcée
                return;
            }

            //Application du nouveau variant sur la langue
            this.langue.codeVariant = codeVariant;

            //Recherche du variant actif dans la shortlist
            setTimeout(() => this.selectVariantFromShortList());

            //RAZ pour destruction de la preview
            this.previewVariant = null;

            //Import dynamique de la locale Angular
            const locale = await import(`@angular/common/locales/${this.langue.codeVariant}.js`);

            //Installation de la locale Angular
            registerLocaleData(locale.default);

            //Rafraichissement de la preview
            this.previewVariant = this.langue.codeVariant;

            //Gestion de la validité
            this.isFormValid.emit(this.form?.valid);
        }
    }

    /**
     * Sélection du variant dans la shortlist
     */
    selectVariantFromShortList(): void {
        //Recherche du variant actif dans la shortlist
        this.selectedVariant = this.shortListVariant.find(v => v.code == this.langue.codeVariant);

        //Si non trouvé
        if (!this.selectedVariant) {
            //Forçage du code variant personnalisé
            this.selectedVariant = { code: this.langue.codeVariant,libelle: this.langue.codeVariant };

            //Ajout à la liste
            this.shortListVariant.push(this.selectedVariant);
        }
    }
}
