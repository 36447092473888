<div class="row">
    <label class="col-md-4" [ngClass]="{ 'has-error': field.invalid, 'required': fieldParam.obligatoire }" [class.text-muted]="disabled" [class.text-secondary]="disabled">
        <span>{{ fieldParam.libelle }}</span>
    </label>
    <div class="col-md-8">            
        <mat-form-field class="d-flex">
            <input matInput
                #field="ngModel"
                [name]='"field_" + position'
                [(ngModel)]="fieldContainer['myField' + position]"
                [minlength]="fieldParam.tailleMinimale" [maxlength]="fieldParam.masque.length" 
                [pattern]="getPatternFor(fieldParam)" 
                [required]="fieldParam.obligatoire"
                [disabled]="disabled"/>
            <mat-icon *ngIf="!disabled" matSuffix [matTooltip]="'zonePredefini.description' | translate:{ masque: fieldParam.masque }">info</mat-icon>
            <mat-icon *ngIf="disabled" matSuffix [matTooltip]="'zonePredefini.inactive' | translate">info</mat-icon>
        </mat-form-field>
    </div>
</div>