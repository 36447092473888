<div class="lvi-content" [ngClass]="{ clickable: isWithChevron() }" (click)="isChevronOpen = !isChevronOpen">
    <div class="avatar">
        {{ this.data.reference | slice: 0:3}}
    </div>
    <div class="lvi-body">
        <div class="title">
            <a> {{ getTitle() }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.sourceLog != null">
                <strong translate="workflow.historique.tag.origine"></strong>
                {{ ("workflow.historique.tag.value.origine." + data.sourceLog) | translate }}
            </li>
            <li *ngIf="data.dateLog != null">
                <strong translate="workflow.historique.tag.date"></strong>
                {{ data.dateLog | date:"shortDate" }}
            </li>
            <li *ngIf="data.acteur != null">
                <strong translate="workflow.historique.tag.acteur"></strong>
                {{  data.acteur | user }}
            </li>
            <li *ngIf="data.typeLog != null">
                <strong translate="workflow.historique.tag.type"></strong>
                {{ ("workflow.historique.tag.value.type." + data.typeLog) | translate }}
            </li>
            <li *ngIf="data.nature != null">
                <strong translate="workflow.historique.tag.zone"></strong>
                {{ ("workflow.historique.tag.value.zone." + data.nature) | translate }}
            </li>
        </ul>
    </div>

    <div class="lvi-actions" *ngIf="isWithChevron()">
        <button mat-icon-button>
            <mat-icon *ngIf="isChevronOpen" color="primary">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isChevronOpen" color="primary">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="isChevronOpen && isWithChevron()" class="p-20">
    <form autocomplete="off" #formFrais="ngForm">
        <div *ngFor="let populationLogChange of data.listePopulationLogChange">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <label class="col-md-3">
                            <span [translate]="('workflow.historique.tag.value.chevron.'+populationLogChange.clef)"></span>
                        </label>
                        <div class="col-md-9">
                            <span>{{formatLog(populationLogChange) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>