import {ChangeDetectorRef,Component,OnDestroy,OnInit} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {MaintenanceTask,MaintenanceTaskFrequence,MaintenanceTaskPhase,MaintenanceTaskResult} from "@domain/admin/maintenance/maintenance-task";
import {MigrationsService,TaskStatut} from "@services/admin/maintenance/migrations.service";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {LogComponent} from "@components/admin/maintenance/migrations/maintenance/log.component";
import {Subscription} from "rxjs";
import {KeyStatutApplication,KeyStatutWorkflow} from "@domain/admin/statut-application/statut-application";
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";

/**
 * Composant pour afficher une tâche dans la liste des tâches de maintenance
 */
@Component({
    host: {'data-test-id': 'task-item'},
    templateUrl: './task-item.component.html',
    styleUrls: ['./task-item.component.scss']
})
export class TaskItemComponent extends ListViewItem<MaintenanceTask> implements OnInit, OnDestroy {
    /** Enum représentant la phase d'une tâche */
    MaintenanceTaskPhase = MaintenanceTaskPhase;

    /** Enum représentant le résultat d'une tâche */
    MaintenanceTaskResult = MaintenanceTaskResult;

    /** Enum représentant la fréquence d'une tâche */
    MaintenanceTaskFrequence = MaintenanceTaskFrequence;

    /** Différentiation de la liste d'historique */
    get isHistorique(): boolean { return this.liste?.uri?.includes("/historique") || this.liste?.extraOptions?.isHistorique; }

    /** Statut de la tâche */
    taskStatut: TaskStatut;

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean = false;

    /** Workflow en cours */
    isWorkflowEnCours: boolean = false;

    /** Mise hors ligne en cours */
    isMiseHorsLigneEnCours: boolean = false;

    /** Souscription */
    souscriptions: Subscription[] = [];

    /**
     * Constructeur
     */
    constructor(
        private migrationsService: MigrationsService,
        private statutWorkflowService: StatutWorkflowService,
        private toastrService: ToastrService,
        private statutApplicationUserService: StatutApplicationUserService,
        private translateService: TranslateService,
        private router: Router,
        protected matDialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef
    ) { super(); }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Abonnement aux mises à jour du statut de la tâche
        this.migrationsService.registrerTask(this.liste.className, this.data, (statut) => {
            //Mise à jour du statut de la tâche
            this.taskStatut = statut;

            //Si la tâche est en succès
            if (this.taskStatut?.isSuccess === true) {
                //Mise à jour de la pastille
                this.data.resultat = MaintenanceTaskResult.SUCCESS;

                //Mise à jour de la date
                this.data.date = this.taskStatut?.date;
            } else if (this.taskStatut?.isSuccess === false) {
                //Sinon mise à jour de la pastille
                this.data.resultat = MaintenanceTaskResult.FAIL;

                //Mise à jour de la date
                this.data.date = this.taskStatut?.date;
            }

            //Mise à jour explicite du composant
            this.changeDetectorRef.detectChanges();
        }, (isMaintenanceEnCours) => {
            //Mise à jour de la maintenance en cours
            this.isMaintenanceEnCours = isMaintenanceEnCours;

            //Mise à jour explicite du composant
            this.changeDetectorRef.detectChanges();
        });

        //Abonnement à la mise à jour du statut du Workflow
        this.souscriptions.push(this.statutWorkflowService.synchroWFStatut$.subscribe(statut => {
            //Mise à jour du statut Workflow
            this.isWorkflowEnCours = statut?.statut != KeyStatutWorkflow.HISTO_WF_NONE;

            //Mise à jour explicite du composant
            this.changeDetectorRef.detectChanges();
        }));

        //Abonnement à la mise à jour du statut de l'application
        this.souscriptions.push(this.statutApplicationUserService.statut$.subscribe(statut => {
            //Mise à jour du statut de l'application
            this.isMiseHorsLigneEnCours = statut == KeyStatutApplication.HORS_LIGNE_TEMPO;

            //Mise à jour explicite du composant
            this.changeDetectorRef.detectChanges();
        }));
    }

    /**
     * Exécution de la tâche
     */
    executeTask(): void {
        //Exécution de la tâche
        this.migrationsService.executeTasks([this.data]).subscribe(result => {
                //Rafraichissement du statut de l'application
                this.migrationsService.checkAppliStatut();
            }, error => {
                //Message d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.generic'));
            }
        );
    }

    /**
     * Navigation vers la tâche sélectionnée
     */
    openTask(): void {
        //Si on n'est pas sur une liste de la popup
        if (!this.liste?.extraOptions?.isPopup) {
            //Si la tâche est cliquée depuis la liste d'historique
            if (this.isHistorique) {
                //Ouverture de la popup de log
                this.matDialog.open(LogComponent, {
                    data: {
                        task: this.data
                    },
                    minWidth: 1200,
                    maxWidth: 1200,
                    minHeight: 600,
                    maxHeight: 600,
                    hasBackdrop: !this.liste?.extraOptions?.isNestedPopup
                });
            } else {
                //Sinon navigation vers la tâche sélectionnée
                this.router.navigate([`Admin/Maintenance/Migrations/Maintenance/${this.getTaskTechnicalName()}`]);
            }
        }
    }

    /**
     * Récupère le nom technique d'une tâche (incluant un éventuel préfixe de pré-migration)
     */
    private getTaskTechnicalName(): string {
        //Suppression du préfixe générique
        return this.data.taskName.replace("com.notilus.upgrader.task.","");
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement des mises à jour de la tâche
        this.migrationsService.unregisterTask(this.liste.className, this.data.taskName);

        //Désabonnements
        this.souscriptions.forEach(s => s.unsubscribe());
    }
}
