import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {Rule} from "@domain/rule/rule";
import {RuleBuilderService} from "@share/component/rule-builder/rule-builder.service";

/**
 * Composant de construction d'une Rule utilisé notament pour les Alertes
 * et les Conditions de Visibilité workflow
 */
@Component({
	host: {'data-test-id': 'rule-builder'},
	selector: 'rule-builder',
	templateUrl: './rule-builder.component.html'
})
export class RuleBuilderComponent implements OnInit {
	/** Rule courante */
	_rule?: Rule;

	/** Écoute des changements dans la Rule */
	@Output() ruleChange: EventEmitter<Rule> = new EventEmitter<Rule>();

	/** La Rule */
	@Input()
	get rule() {
		return this._rule;
	}

	set rule(rule: Rule) {
		this._rule = rule;
		this.ruleChange.emit(this._rule);
	}

	/** Entité correspondant à la Rule */
	@Input() entite: string;

	/**
	 * Constructeur
	 *
	 * @param ruleService le service de construction d'une Rule
	 */
	constructor(
		private ruleService: RuleBuilderService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la Rule
		this.rule = this.rule ?? new Rule(this.entite);

		//Chargement des entités des rules embarquées s'il y en a
		this.ruleService.loadEmbeddedRulesEntities(this.rule);

		//Typage des champs de saisie
		this.ruleService.assignFieldType(this.rule);
	}

}
