<div class="lvi-content">
    <div class="avatar" [nSelector]="{ liste: liste,item: data }"
            [ngClass]="{ 'warning': data.listeAlertes?.niveau == NiveauAlerte.WARNING,'danger': data.listeAlertes?.niveau == NiveauAlerte.ERROR }">
        <i class="nio icon-facture align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.openAnomalie(data)">
                {{ data.numeroFacture }} {{ data.fournisseur }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'facture.releve.anomalie.date'"></strong>{{ data.dateFacture | date:'shortDate' }}</li>
            <li><strong [translate]="'facture.releve.anomalie.collab'"></strong>{{ data.voyageurFormate }}</li>
            <li><strong [translate]="'facture.releve.anomalie.montant'"></strong>{{ data.montant | montant:data.devise }}</li>
            <li *ngIf="data.listeAlertes?.listeAlertes.length > 0" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == NiveauAlerte.ERROR, 'back-warning' : data.listeAlertes.niveau == NiveauAlerte.WARNING}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ data.listeAlertes.listeAlertes[0].titre | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div class="statut c-primary m-0">
            <i *ngIf="data.masque" class="nio icon-masquer" [nioTooltip]="'facture.releve.anomalie.statut.masque' | translate"></i>
        </div>
    </div>
</div>