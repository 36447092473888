import {Component,OnInit,ViewChild} from '@angular/core';
import {ExternesParams} from "@domain/participant/externes-params";
import {UtilisateursParametresService} from "@services/admin/entreprise/utilisateurs/utilisateurs-parametres.service";
import {first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {RadioOption} from "@share/component/custom-input/radio-option";
import {TranslateService} from "@ngx-translate/core";
import {NomParametreParId,ParticipantExterneParam} from "@domain/participant/participant-externe-param";
import {NgForm} from "@angular/forms";

/**
 * Composant des paramètres des externes
 */
@Component({
	host: {'data-test-id': 'externes-params'},
	selector: 'externes-params',
	templateUrl: './externes-params.component.html',
	styleUrls: ['./externes-params.component.scss']
})
export class ExternesParamsComponent implements OnInit {
	/** Paramètres des externes */
	externesParams: ExternesParams;

	/** Liste des options du radio button "Gestion dans la note de frais" */
	gestionNdfOptions: RadioOption[] = [
		{
			code: false,
			label: this.translateService.instant('admin.entreprise.utilisateurs.parametres.externesParams.simple'),
			tooltip: this.translateService.instant('admin.entreprise.utilisateurs.parametres.externesParams.simpleTooltip')
		},{
			code: true,
			label: this.translateService.instant('admin.entreprise.utilisateurs.parametres.externesParams.complete'),
			tooltip: this.translateService.instant('admin.entreprise.utilisateurs.parametres.externesParams.completeTooltip')
		}
	];

	/** Le formulaire */
	@ViewChild('form') form: NgForm;

	//Import de l'énum pour le dom
	readonly NomParametreParId = NomParametreParId;

	/**
	 * Constructeur
	 *
	 * @param usersParamsService le service de gestion des paramètres utilisateurs
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private usersParamsService: UtilisateursParametresService,
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement des paramètres
		this.usersParamsService.loadExternesParams()
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Initialisation des params
					this.externesParams = new ExternesParams();

					//Récupération des params
					this.externesParams.actif = result.data.actif;
					this.externesParams.gestionNdf = result.data.gestionNdf;
					this.externesParams.saisieCollab = result.data.saisieCollab;
					this.externesParams.listeParam = result.data.listeParam;

					//On inverse les lignes REFERENCE (index 2) et SOCIETE (index 3)
					const tmp: ParticipantExterneParam = this.externesParams.listeParam[2];
					this.externesParams.listeParam[2] = this.externesParams.listeParam[3];
					this.externesParams.listeParam[3] = tmp;
				}
			});
	}

	/**
	 * Définit si le paramètre est disabled ou non
	 *
	 * @param param le paramètre
	 */
	isDisabled(param: ParticipantExterneParam): boolean {
		//Renvoie true (disabled) si c'est le param d'ID 1 (nom), 2 (prénom) ou 4 (société)
		return [1,2,4].includes(param.id)
	}
}
