import {Component,OnInit,ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute,Router} from "@angular/router";
import {FiltreAxeAnalytiqueDTO} from "@domain/admin/entreprise/analytique/FiltreAxeAnalytiqueDto";
import {Dossier} from "@domain/admin/entreprise/analytique/dossier";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import * as moment from "moment";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import {filter,first} from "rxjs/operators";
import {AnalytiquePopupAjoutFiltresComponent} from "../../analytique-popup-ajout-filtres/analytique-popup-ajout-filtres.component";

/**
 * Enum des différents onglets disponibles sur la page projet
 */
export enum OngletsCodesProjet {
    GENERALITES = "Généralités",
}

/**
 * Enum des types de filtres possible (Mot-clef, Service, Catégorie, Type entité)
 */
export enum FiltreTypes {
    MC = "MC",
    SE = "SE",
    CA = "CA",
    TE = "TE"
}

/**
 * Composant d'affichage du formulaire de modification/création d'un code projet
 */
@Component({
    host: {'data-test-id': 'analytique-codes-projet-infos'},
    selector: 'analytique-codes-projet-infos',
    templateUrl: './analytique-codes-projet-infos.component.html'
})
export class AnalytiqueCodesProjetInfosComponent implements OnInit {
    /** Indique si le formulaire a été ouvert en création de codes projet ou en modification */
    isCreation: boolean = false;

    /** Formulaire de la page */
    @ViewChild('form') form: NgForm;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = OngletsCodesProjet;

    /** Liste des différents onglets disponibles dans le sous-menu projets */
    listeTabItems: Array<PageHeaderItem> = [{
        code: OngletsCodesProjet.GENERALITES,
        libelle: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.onglets.codeProjet')
    }];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    /** Code Projet sélectionné */
    codeProjet: Dossier;

    /** Pour permettre le pending sur le floating button */
    isSaving: boolean;

    /** Liste des filtres associés au code projet */
    listeFiltre: Array<FiltreAxeAnalytiqueDTO> = new Array<FiltreAxeAnalytiqueDTO>();

    /** Types des filtres */
    filtreTypes = FiltreTypes;

    /** Identifiant du code projet à afficher */
    idCodeProjet: number = 0;

    /**
     * Constructeur
     *
     * @param activatedRoute Route d'accès à cette page
     * @param confirmService Service de confirmation
     * @param translateService Service de traduction
     * @param matDialog Boîte de dialogue
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param analytiqueService Service de gestion des informations sur l'analytique
     * @param router Router de l'application pour naviguer entre les pages
     */
    constructor(private activatedRoute: ActivatedRoute,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private matDialog: MatDialog,
                private toastrService: ToastrService,
                private analytiqueService: AnalytiqueService,
                private router: Router
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération de l'id du code projet
        this.activatedRoute.params.pipe(first()).subscribe(
            params => {
                this.onInit(params['idCodeProjet']);
            }
        );
    }

    /**
     * Méthode appelée lorsque le code projet a été récupéré
     *
     * @param idCodeProjet Identifiant du code projet, récupéré dans l'url
     */
    onInit(idCodeProjet) {
        this.idCodeProjet = parseInt(idCodeProjet);

        //On est en mode création si l'id indiqué dans l'url est -1 sinon on est en mode modification
        this.isCreation = idCodeProjet === "-1";

        //Définition de la liste des actions possibles peu importe le mode (création ou non)
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save(),
        }]);

        //Si on est en mode création alors on initialise un nouveau code projet
        if (this.isCreation) {
            //Initialisation du nouveau code projet
            this.codeProjet = {
                idDossier: 0,
                reference: "",
                libelle: "",
                commentaire: "",
                idParent: 0,
                libelleParent: "",
                cache: false,
                isCache: false,
                code1: "",
                code2: "",
                isActif: true
            };
        } else {
            //Si on est en mode modification alors on ajoute la possibilité de supprimer le code projet
            this.listeActions.next([
                ...this.listeActions.getValue(),
                {
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-suppression',
                    libelle: 'global.actions.supprimer',
                    doAction: () => this.delete(),
                }
            ]);

            if (!isNaN(this.idCodeProjet)) {
                //Récupération du code projet possédant l'identifiant récupéré
                this.analytiqueService.getCodeProjet(this.idCodeProjet).subscribe(data => {
                    if (!!data?.data?.codeProjet) {
                        this.codeProjet = data.data.codeProjet;
                        //Mise à jour de l'information concernant le code projet et s'il est actif ou non
                        this.codeProjet.isActif = !this.codeProjet.cache;
        
                        //Récupération de la liste des filtres associés au code projet
                        this.analytiqueService.getListeFiltre(this.codeProjet).subscribe(data => {
                            this.listeFiltre = data.data.liste_filtre;
                        });
                    } else {
                        this.router.navigateByUrl('Admin/Entreprise/Analytique/Projets');
                    }
                });
            } else {
                this.router.navigateByUrl('Admin/Entreprise/Analytique/Projets');
            }
        }
    }

    /**
     * Méthode appelée lors de l'enregistrement du code projet
     */
    save() {
        //Si le formulaire est valide alors on enregistre le code projet
        if(this.form.valid) {
            this.isSaving = true;
            //Lorsqu'on récupère un code Projet déjà créé alors ces 3 champs ci-dessous sont égaux à 0, ce qui dérange lors de l'enregistrement, donc une mise à null est nécessaire
            if(this.codeProjet.idOrga === 0){
                this.codeProjet.idOrga = null;
            }
            if(this.codeProjet.idPeriodeDebut === 0){
                this.codeProjet.idPeriodeDebut = null;
            }
            if(this.codeProjet.idPeriodeFin === 0){
                this.codeProjet.idPeriodeFin = null;
            }

            //Modification de la variable "cache" pour l'enregistrement en base pour changer l'information sur le fait que le code projet soit actif ou non
            this.codeProjet.cache = !this.codeProjet.isActif;

            //Enregistrement en base du code projet modifié
            this.analytiqueService.saveCodeProjet(this.codeProjet).subscribe(data => {
                if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Si aucune erreur n'est renvoyée alors on affiche un message de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                } else if (data.codeErreur === 901) {
                    //Si une erreur doublon est renvoyée alors on affiche un message d'erreur
                    this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.erreurDoublon'));
                } else {
                    //Si une erreur est renvoyée alors on affiche un message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
                this.isSaving = false;
            });
        } else {
            //Si le formulaire n'est pas valide alors on renvoie une erreur
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Méthode appelée lors de la suppression d'un code projet
     */
    delete() {
        //Confirmation de la suppression du code projet
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression du code projet en base
                this.analytiqueService.deleteCodeProjet(this.codeProjet.idDossier).subscribe(res => {
                    if(res.codeErreur !== TypeCodeErreur.NO_ERROR) {
                        //Si le résultat contient une erreur alors on affiche un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    } else {
                        //Sinon on affiche un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        //Et on redirige vers la liste des codes projets
                        this.router.navigate(['Admin/Entreprise/Analytique/Projets']);
                    }
                })
            }
        });
    }

    /**
     * Méthode appelée lors de l'ajout d'un filtre pour le code projet
     */
	addFiltre() {
		//Affichage de la popup d'ajout d'un filtre
		this.matDialog.open(AnalytiquePopupAjoutFiltresComponent, {
			width: '40%',
			data: {
				idAxe: this.idCodeProjet,
                title: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.addFiltreTitle'),
                options: [{
                    libelle: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.ca'),
                    valeur: 7,
                    avatar: "CA",
                    libelleListe: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.caListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.mc'),
                    valeur: 5,
                    avatar: "MC",
                    libelleListe: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.mcListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.se'),
                    valeur: 8,
                    avatar: "SE",
                    libelleListe: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.seListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.te'),
                    valeur: 6,
                    avatar: "TE",
                    libelleListe: this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.teListeTitre')
                }]
			}
		}).afterClosed().subscribe((filtre: FiltreAxeAnalytiqueDTO) => {
			//Si la fenêtre est fermée après l'ajout d'un filtre alors on l'ajoute au code projet
			if (filtre !== null && filtre.idFiltre) {
				//On cherche la présence ou non du filtre à ajouter, dans la liste des filtre déjà associé au code projet
				if (this.listeFiltre.findIndex(p => p.idFiltre === filtre.idFiltre && p.type === filtre.type) === -1) {
					//S'il n'est pas déjà présent alors on l'associe au code projet et on l'enregistre en base
					this.analytiqueService.saveFiltre(filtre, 2).subscribe(res => {
						//Le 2 comme valeur de typeAxe correspond à l'axe représenté ici : Codes Projet
						if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
							//Si le résultat contient une erreur alors on affiche un message d'erreur d'enregistrement
							this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
						} else {
							//Sinon affichage d'un message de succès
							this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
							//Et ajout du filtre dans la liste des filtres
							this.listeFiltre.push(filtre);
						}
					})
				} else {
					//Si le filtre est déjà associé au code projet alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.codesProjet.infos.filtre.filtreDejaPresent'));
				}
			}
		});
	}

    /**
     * Méthode appelée lors de la suppression du lien entre un filtre et le code projet
     *
     * @param filtre Filtre à supprimer
     */
    deleteFiltre(filtre) {
        //Confirmation de la suppression du filtre
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression du lien entre le filtre et le code projet en base
                this.analytiqueService.deleteFiltre(filtre, 2).subscribe(res => {
                    //Le 2 comme valeur de typeAxe correspond à l'axe représenté ici : Codes Projet
                    if(res.codeErreur !== TypeCodeErreur.NO_ERROR) {
                        //Si le résultat contient un code d'erreur alors on affiche un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    } else {
                        //Sinon affichage d'un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        //Et suppression du filtre dans la liste des filtres du code projet
                        this.listeFiltre.splice(this.listeFiltre.findIndex(p => p.idFiltre === filtre.idFiltre), 1)
                    }
                })
            }
        });
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        this.selectedItem = selectedItem;
    }

    /**
     * Retour à la page précédente
     */
    onGoBack(): void {
        this.router.navigateByUrl('Admin/Entreprise/Analytique/Projets');
    }

    /**
     * Contrôle de la conformité des dates entre elles (qu'elles soient dans le bon ordre)
     * @returns boolean : date valide ou non
     */
    isDateFinValid(): boolean {
        const dateDebut = this.form?.form?.value?.dateDebut;
        const dateFin = this.form?.form?.value?.dateFin;

        if (dateDebut && dateFin) {
            if (!moment(dateFin).isAfter(moment(dateDebut))) {
                this.form.form.controls['dateFin'].setErrors({'incorrect': true});
                return false;
            } else {
                this.form.form.controls['dateFin'].setErrors(null);
            }
        }
        return true;
    }
}
