<div [class]="source === 'ndf-list-frais' ? 'dropzone' : 'alert clickable r-flex align-items-center justify-content-center h-100 position-relative'"
     [fileUploader]="fileUploader"
     [settings]="settings"
     [idObjet]="idObjet"
     (onFilesDrop)="onFilesDrop($event);uploadAll();"
     (onDragLeave)="onDragLeave()"
     (onAllItemsAdded)="uploadAll()"
>
    <ng-container *ngIf="!(isUploading | async)">
        <i class="zmdi zmdi-hc-3x zmdi-download m-r-20"></i>
        <strong [translate]="'document.actions.ajouterPiecesJointes'"></strong>
        <mat-icon *ngIf="source === 'saisie-frais'" [nioTooltip]="'ndf.frais.tipsUpload' | translate" class="material-icons-outlined mat-icon-tips">tips_and_updates</mat-icon>
    </ng-container>
    <ng-container *ngIf="isUploading | async">
        <mat-progress-spinner class="m-r-20" [diameter]="50" [color]="'primary'" [mode]="'indeterminate'"></mat-progress-spinner>
        <strong>{{ nbUploaded }} / {{ total }}</strong>
    </ng-container>
</div>