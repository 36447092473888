import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {OMPNdfListItemComponent} from "@components/omp/detail/ndf/omp-ndf-list-item.component";
import {OMPService} from "@components/omp/omp.service";
import {AppState} from '@domain/appstate';
import {ListView, TypeComparaison, TypeFilter} from "@domain/common/list-view";
import {Omp} from "@domain/omp/omp";
import {SettingsNFState} from '@domain/settings/settings';
import {TypeEntiteParamOT} from "@domain/typeentite/typeEntiteParam";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from '@ngrx/store';
import {TranslateService} from "@ngx-translate/core";
import * as settingsActions from "@reducers/settings";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ToastrService} from "ngx-toastr";
import {combineLatest, Subject, Subscription} from 'rxjs';
import {filter, first} from "rxjs/operators";

@Component({
    selector: 'omp-ndf-list',
    templateUrl: './omp-ndf-list.component.html'
})
export class OMPNdfListComponent implements OnInit, AfterViewInit, OnDestroy {
    /** Mission permanente */
    @Input() omp: Omp;

    /** Paramètres du type entité de la mission permanente */
    @Input() typeEntiteParam: TypeEntiteParamOT;

    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /** Données */
    liste: ListView<any,OMPNdfListItemComponent>;

    /** Paramètres NF */
    nfSettings: SettingsNFState;

    /** Souscription au paramétrage NF */
    ndfSettingsSouscription: Subscription;

    /** Indicateur pour savoir si la liste a déjà été chargée */
    ndfListLoaded$: Subject<boolean> = new Subject();

    /**
     * Constructeur
     */
    constructor(
        private ompService: OMPService,
        private translateService: TranslateService,
        private router: Router,
        private pleaseWaitService: PleaseWaitService,
        private toastrService:ToastrService,
        protected store: Store<AppState>
    ) { }

    ngOnInit(): void {

        //Vérification de la possibilité de création d'après les paramètres du type entité
        this.ompService.isUserCanCreateForTypeEntite(this.typeEntiteParam,TypePortee.NF)
            .pipe(first())
            .subscribe(userCanCreateForTypeEntite => {
                //Définition de la liste
                this.liste = new ListView<any,OMPNdfListItemComponent>({
                    uri: `/controller/OMPermanent/filtreNDF/${this.omp.idOMPermanent}`,
                    title: this.translateService.instant('omp.ndf.liste.title'),
                    component: OMPNdfListItemComponent,
                    listeFilters: [
                        {
                            //Ajout du filtre sur l'objet
                            clef: 'objet',
                            title: this.translateService.instant('ndf.liste.filtres.objet'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur l'id de la ndf
                            clef: 'idNDF',
                            title: this.translateService.instant('ndf.liste.filtres.id'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le numéro
                            clef: 'numeroPiece',
                            title: this.translateService.instant('ndf.liste.filtres.numero'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur l'OM
                            clef: '*od.idOd',
                            title: this.translateService.instant('ndf.liste.filtres.om'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur l'OMP
                            clef: '*omPermanent.idOMPermanent',
                            title: this.translateService.instant('ndf.liste.filtres.omp'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur la date de MAJ
                            clef: 'dateMaj',
                            title: this.translateService.instant('ndf.liste.filtres.dateStatut'),
                            type: TypeFilter[TypeFilter.DATE]
                        }, {
                            //Ajout du filtre sur le matricule
                            clef: 'user.matricule',
                            title: this.translateService.instant('ndf.liste.filtres.matricule'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le montant remboursable
                            clef: 'montantRemboursable',
                            title: this.translateService.instant('ndf.liste.filtres.montantRemboursable'),
                            isDefault: true,
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }, {
                            //Ajout du filtre sur le montant à rembourser
                            clef: 'montantARembourser',
                            title: this.translateService.instant('ndf.liste.filtres.montantARembourser'),
                            isDefault: true,
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }, {
                            clef: 'typeEntite.code',
                            title: this.translateService.instant('ndf.liste.filtres.type'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            clef: 'statut.code',
                            title: this.translateService.instant('ndf.liste.filtres.statut'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }
                    ],
                    listeActions: [{
                        icon: 'add',
                        isVisible: () => {
                            return this.canModifier //Objet en édition
                                && this.canCreate //Paramétrage WF
                                && userCanCreateForTypeEntite; //Paramétrage type entité
                        },
                        onPress: () => this.createNDF()
                    }],
                    extraOptions: {
                        canModifier: this.canModifier,
                        showNDF: this.showNDF.bind(this)
                    }
                });
                this.ndfListLoaded$.next(true);
            }
        );
    }

    /**
     * Apres initialisation de la vue
     */
    ngAfterViewInit(): void {

        //Selection du paramétrage NDF
        this.ndfSettingsSouscription = combineLatest([
            this.ndfListLoaded$,
            this.store.select(state => state.settings?.[TypePortee.NF])
        ])
        .pipe(filter(([loaded, settings]) => loaded === true && !!settings))
        .subscribe(([_, settings]) => {
            this.nfSettings = settings;
            
            //Ajout des valeurs aux filtres de liste NDF
            if (!!settings) {
                let statutFilter = this.liste?.listeFilters?.find(f => f.clef === 'statut.code');
                if (!!statutFilter) {
                    statutFilter.listeValues = [...settings.filtresStatut];
                }
                let typeFilter = this.liste?.listeFilters?.find(f => f.clef === 'typeEntite.code');
                if (!!typeFilter) {
                    typeFilter.listeValues = [...settings.typeEntiteList];
                }
            }
        });

        //Chargement du paramétrage NDF
        if (!this.nfSettings) {
            this.store.dispatch({
                type: settingsActions.LOAD_SETTINGS,
                payload: TypePortee.NF
            });
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //On n'oublie pas de se désabonner
        this.ndfSettingsSouscription?.unsubscribe();
    }

    /** Getter pour la création */
    get canCreate(): boolean {
        return this.omp?.getMapAction()?.actionsSupplementaires?.canCreationNdf?.possible === true;
    }

    /**
     * Création d'une NDF
     */
    createNDF(): void {
        let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

        //Handler sur une erreur lors de la création
        const errorHandler = (err) => {
            //Affichage d'un message d'erreur
            this.toastrService.error(this.translateService.instant("omp.ndf.liste.errorCreation"));

            //Fermeture de la popup d'attente
            stopWaitingHandler.call(this);
        };

        //Handler de fermeture de la popup d'attente
        const stopWaitingHandler = () => {
            //Fermeture de la popup d'attente
            matDialogRef.close();
        }

        //Affichage de la popup d'attente
        matDialogRef = this.pleaseWaitService.show();

        //Création de la NDF
        this.ompService.createNDF(this.omp).subscribe({
            next: idNDF => {
                if (idNDF && idNDF > 0) {
                    this.showNDF({idNDF: idNDF})
                        .then(stopWaitingHandler)
                        .catch(stopWaitingHandler);
                } else {
                    //Gestion de l'erreur
                    errorHandler.call(this);
                }
            },
            error: errorHandler
        });
    }

    /**
     * Affichage d'une NDF
     *
     * @param ndf La NDF à afficher
     */
    showNDF(ndf: {idNDF: number}): Promise<boolean> {
        return this.router.navigate(['NDF',ndf.idNDF]);
    }

}
