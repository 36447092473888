import {Component,EventEmitter,Input,Output} from '@angular/core';
import {MenuItem} from "../../../domain/common/menu/menu-item";
import {Action,Store} from "@ngrx/store";
import {AppState} from "../../../domain/appstate";

/**
 * Composant d'affichage d'un dropdown pour le menu latéral
 *
 * @author Laurent Convert
 * @date 08/11/2021
 */
@Component({
    host: {'data-test-id': 'menu-dropdown','[className]': 'css'},
    selector: 'menu-dropdown',
    templateUrl: './menu-dropdown.component.html',
    styleUrls: ['./menu-dropdown.component.scss'],
    // animations: [
    //     trigger('openClose', [
    //         transition(':enter', [
    //             style({ opacity: 0 }),
    //             animate(MenuDropdownComponent.TRANSITION_DURATION, style({ opacity: 1 })),
    //         ]),
    //         transition(':leave', [
    //             style({ opacity: 1 }),
    //             animate(MenuDropdownComponent.TRANSITION_DURATION, style({ opacity: 0 })),
    //         ])
    //     ])
    // ]
})
export class MenuDropdownComponent {
    /**
     * Durée des transitions fade-in / fade-out lors de l'ouverture / fermeture du dropdown.
     * Doit-être synchronisé avec la transition CSS permettant de supprimer le border-radius de l'entrée du menu (rechercher "MenuDropdownComponent" dans le fichier "menu.scss")
     */
    public static readonly TRANSITION_DURATION: number = 100;

    /**
     * Binding de l'animation d'affichage.<br />
     * Permet implicitement d'attacher la transition sur le composant, même si la variable n'est pas utilisée explicitement.
     * La transition doit être sur le host et non sur un élément enfant (dans le HTML) pour que le fade-out soit déclenché lors de la suppression du DOM par le détachement de l'overlay
     */
    // @HostBinding('@openClose') animation;

    /** Titre du dropdown */
    @Input() title: string;

    /** Classe CSS du dropdown. Permet uniquement de surcharger le style car il est appliqué directement sur l'élément host */
    @Input() css: string;

    /** Liste des entrées du dropdown */
    @Input() listeItems: Array<MenuItem>;

    /** évènement propagé au déclenchement de l'action */
    @Output() onDoAction: EventEmitter<Action> = new EventEmitter<Action>();

    /**
     * Constructeur
     */
    constructor(private store: Store<AppState>) {
    }

    /**
     * Déclenchement de l'action lié à l'entrée de menu
     *
     * @param menuItem l'item du menu
     */
    doAction(menuItem: MenuItem): void {
        //Vérification d'une action à exécuter
        if (menuItem?.doAction) {
            //Déclenchement de l'action
            menuItem.doAction((action: Action) => {
                this.store.dispatch(action);
    
                //Notification que l'action a été déclenchée
                this.onDoAction.emit(action);
            });
        }
    }

}
