import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {ListItem} from "@domain/common/list-view/list-item";
import {AbstractReferentiel} from "@domain/travel/abstract-referentiel";

/**
 * Représente une catégorie voyageur pour une configuration SBT
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
export class CategorieVoyageur implements ListItem,AbstractReferentiel {
	idCategorie: number;
	libelle: string;
	code: string;
	defaut: boolean = false;
	sbtConfigUsed: TravelHubSBTConfigUsed;
	used: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto?: any | CategorieVoyageur) {
		if (dto) {
			Object.assign(this,dto);

			if (dto.sbtConfigUsed) {
				this.sbtConfigUsed = new TravelHubSBTConfigUsed(dto.sbtConfigUsed);
			}
		}
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): number {
		return this.idCategorie;
	}
}