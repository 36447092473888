<div class="lvi-content clickable" (click)="extraOptions.close(data)">
    <div class="avatar">{{data?.libelle.substring(0,3) || '?'}}</div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.close(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data?.code"><strong [translate]="'admin.ongletOutils.cadreMouvementWf.popup.code'"></strong>{{data.code}}</li>
            <li *ngIf="data?.idEtat"><strong [translate]="'admin.ongletOutils.cadreMouvementWf.popup.etat'"></strong>
                <ng-container [ngSwitch]="data.idEtat">
                    <span *ngSwitchCase="EnumEtat.VALIDE" [translate]="'global.etats.valide'"></span>
                    <span *ngSwitchCase="EnumEtat.EN_VALIDATION" [translate]="'global.etats.validation'"></span>
                    <span *ngSwitchCase="EnumEtat.EN_CREATION" [translate]="'global.etats.creation'"></span>
                    <span *ngSwitchCase="EnumEtat.COMPTABILISE_CLOTURE" [translate]="'global.etats.comptabilise_cloture'"></span>
                    <span *ngSwitchCase="EnumEtat.ARCHIVE" [translate]="'global.etats.archive'"></span>
                    <span *ngSwitchCase="EnumEtat.ANNULE" [translate]="'global.etats.annule'"></span>
                </ng-container>
            </li>
        </ul>
    </div>
</div>