<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.niveauAlerte == 1,'danger': data.niveauAlerte == 2 }"><i class="material-icons">mail_outline</i></div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="detailEnveloppe()">{{ data.libelleEnveloppe }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'budget.liste.reference'"></strong>
                <span>{{ data.reference || ('budget.liste.aucune' | translate) }}</span>
            </li>
            <li>
                <strong [translate]="'budget.liste.exercice'"></strong>
                <span>{{ data.exercice }}</span>
            </li>
            <li>
                <strong [translate]="'budget.liste.debut'"></strong>
                <span>{{ data.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'budget.liste.fin'"></strong>
                <span>{{ data.dateFin | date: 'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'budget.liste.montant'"></strong>
                <span>{{ data.montant || 0 | montant: data.codeDevise }}</span>
            </li>
            <li>
                <strong [translate]="'budget.liste.consomme'"></strong>
                <span>{{ data.montantConsomme || 0 | montant: data.codeDevise }}</span>
            </li>
        </ul>
    </div>
</div>
