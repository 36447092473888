import {TypeEntiteState} from "../domain/typeentite/typeEntite";
import {Action, PayloadUpdateTypeEntite} from "../domain/action";

/**
 * Export des actions
 */
export const FIND_TYPE_ENTITE = 'FIND_TYPE_ENTITE';
export const UPDATE_TYPE_ENTITE = 'UPDATE_TYPE_ENTITE';

/**
 * Création du reducer
 */
export function typeEntiteReducer(state: TypeEntiteState = {},action: Action<PayloadUpdateTypeEntite>): TypeEntiteState {
    //Vérification de l'action
    switch (action.type) {
        case UPDATE_TYPE_ENTITE:
            //Mise à jour de l'état
            state[action.payload.portee] = action.payload.typeEntite;
            break;
    }

    //Retour de l'état
    return state;
}
