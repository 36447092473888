import {Component, Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import { TypeAdresseEnum } from '@domain/geolocalisation/type-adresse';
import {AdresseListItem} from "@domain/profil/adresse";

/**
 * Composant pour une ligne de la liste des adresses
 */
@Component({
  selector: 'profil-donnees-adresse-list-item',
  host: {'data-test-id': 'profil-donnees-adresse-list-item'},
  templateUrl: './profil-donnees-adresse-list-item.component.html'
})
export class ProfilDonneesAdresseListItemComponent extends ListViewItem<AdresseListItem> {
  /** Elément courant */
  @Input() data: AdresseListItem;

  /** Propriété servant à utiliser l'enum dans l'html pour savoir quelle enum afficher */
  typeAdresseEnum = TypeAdresseEnum;
}
