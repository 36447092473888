import { Component } from "@angular/core";

/**
 * Onglet notification accueil
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'notification-accueil'},
    templateUrl: './notification-accueil.component.html'
})
export class NotificationAccueilComponent {}
