import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {Router} from '@angular/router';

/**
 * Composant de la liste des pays
 *
 * @author Angeline Ha
 * @date 08/11/2023
 */
@Component({
	host: {'data-test-id': 'pays-list'},
	selector: 'pays-list',
	templateUrl: './pays-list.component.html'
})
export class PaysListComponent implements OnInit {
	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param geographieService	Service géographie
	 * @param router			Router de l'application
	 */
	constructor(public geographieService: GeographieService,
				private router: Router) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition de la liste des actions du bouton en bas à droite de l'écran
		this.listeActions.next([{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-ajouter',
				libelle: 'global.actions.creer',
				doAction: () => this.createNewPays()
			}]
		);
	}

	/**
	 * Création d'un nouveau pays
	 */
	createNewPays() {
		this.router.navigate(['Admin/Bibliotheque/Geographie/Pays/0']);
	}

}
