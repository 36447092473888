import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {UserDto} from "@domain/user/user-dto";

/**
 * Ligne de la liste de l'onglet "Usage"
 *
 * @author Laurent Convert
 * @date 19/03/2024
 */
@Component({
	host: {'data-test-id': 'profil-connexion-usage-list-item'},
	templateUrl: './profil-connexion-usage-list-item.component.html'
})
export class ProfilConnexionUsageListItemComponent extends ListViewItem<UserDto> {

}