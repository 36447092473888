import { EngagementsItem } from "../engagements-item";

/**
 * Engagements - Frais d'un OD
 */
export class EngagementsFraisItem extends EngagementsItem {
    /** Identifiant de la prestation */
    idPrestation: number;

    /** Libellé de la prestation */
    libellePrestation: string;

    /** Icône de la prestation */
    iconePrestation: string;

    /** quantité */
    quantite: number;

    /**
     * Constructeur
     *
     * @param item Item
     * @param isTotal Indicateur d'une ligne de total
     */
    constructor(item: EngagementsFraisItem,isTotal?: boolean) {
        super(isTotal);
        Object.assign(this,item);
    }

    /**
     * NOOP, Retourne null (Le type est porté par la prestation)
     */
    getType(): number {
        return null;
    }

    /**
     * Retourne la traduction
     *
     * @param previsionnel Indique si l'item est prévisionnel (sinon il est réel)
     */
    getTraduction(previsionnel?: boolean): string {
        //Vérification d'une ligne de total
        if (this.isTotal) {
            //Vérification de la colonne prévisionnelle ou non
            if (previsionnel) {
                //Prévisionnel
                return 'od.engagements.previsionnel';
            } else {
                //Réel
                return 'od.engagements.reel';
            }
        } else {
            //Libelle associé au type
            return this.libellePrestation;
        }
    }

}
