import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as moment from "moment";
import {ODService} from "@components/od/od.service";
import {TranslateService} from "@ngx-translate/core";
import * as _ from "lodash";
import {RegleAttribution} from "@domain/od/engagements/indemnites/regle-attribution";
import {filter, finalize} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {DatePipe} from "@angular/common";

/**
 * Popup de dégrèvement en masse
 *
 * @author Laurent SCIMIA
 * @date 25/08/2022
 */
@Component({
    host: {'data-test-id': 'od-degrements-masse'},
    templateUrl: './od-degrevements-masse.component.html'
})
export class OdDegrevementsMasseComponent implements OnInit {
    /** Liste des règles concernées par le dégrèvement */
    listeIndemnitesSelectionnees: Array<RegleAttribution>;

    /** Date de début pour le filtre du dégrèvement */
    dateDebut: moment.Moment;

    /** Date de fin pour le filtre du dégrèvement */
    dateFin: moment.Moment;

    /** Action à effectuer (dégrèvement ou rétablissement) */
    action: Action;

    /** Indique que l'enregistrement est en cours */
    isSaving: boolean = false;

    /** Liste des actions possibles */
    readonly listeActions: Array<{ id: Action, libelle: string }> = [{
        id: Action.DEGREVEMENT,
        libelle: this.translateService.instant('od.engagements.indemnites.degrevements.degrevement')
    }, {
        id: Action.RETABLISSEMENT,
        libelle: this.translateService.instant('od.engagements.indemnites.degrevements.retablissement')
    }];

    /**
     * Constructeur
     *
     * @param data              Informations de la popup
     * @param matDialogRef      Référence à la popup elle-même
     * @param translateService  Service de traduction
     * @param toastrService     Service de toast (bon app)
     * @param odService         Service des OD
     * @param confirmService    Service de la popup de confirmation
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { idOd: number, listeRegles: Array<RegleAttribution>, dateMin: moment.Moment, dateMax: moment.Moment, needRefresh: boolean},
                private matDialogRef: MatDialogRef<OdDegrevementsMasseComponent>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private odService: ODService,
                private confirmService: ConfirmService,
                private datePipe: DatePipe) {

    }

    /** Initialisation */
    ngOnInit(): void {
        //Par défaut, on sélectionne toutes les règles
        this.listeIndemnitesSelectionnees = _.cloneDeep(this.data.listeRegles);

        //Par défaut, on prend les dates extremes
        this.dateDebut = this.data.dateMin.clone();
        this.dateFin = this.data.dateMax.clone();

        //Par défaut, on fait du dégrèvement
        this.action = Action.DEGREVEMENT;
    }

    /** Envoie l'action de masse */
    enregistrer() {
        //On indique que ça enregistre
        this.isSaving = true;

        //On construit le message de confirmation
        let message: string = this.translateService.instant('od.engagements.indemnites.degrevements.action') + " : " + this.listeActions.find(a => a.id === this.action).libelle + "\r\n"
        + this.translateService.instant('od.engagements.indemnites.degrevements.Indemnites') + " : " + this.listeIndemnitesSelectionnees.map(ij => ij.libelle).join(", ") + "\r\n"
        + this.translateService.instant('od.engagements.indemnites.degrevements.du') + " : " + this.datePipe.transform(this.dateDebut.toDate(), 'shortDate') + "\r\n"
        + this.translateService.instant('od.engagements.indemnites.degrevements.au') + " : " + this.datePipe.transform(this.dateFin.toDate(), 'shortDate');

        //On demande si on est sûr d'être sûr
        this.confirmService.showConfirm(message).pipe(filter(isConfirmed => isConfirmed)).subscribe(() => {
            //On envoie au four
            this.odService.doDegrevementMasse(this.data.idOd, this.action === Action.RETABLISSEMENT, this.dateDebut, this.dateFin, this.listeIndemnitesSelectionnees)
                .pipe(finalize(() => this.isSaving = false))
                .subscribe(result => {
                    //S'il n'y a pas d'erreur, on refresh tout
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.matDialogRef.close(true);
                    } else {
                        //En cas d'erreur, on affiche un message
                        TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                    }
                });
        });
    }

    /**
     * Donne la date max pour le datepicker
     *
     * @param isDebut true si c'est pour la date de début, sinon false
     */
    getMaxDateRange(isDebut: boolean = true): moment.Moment {
        return isDebut ? this.dateFin : this.data.dateMax;
    }

    /**
     * Donne la date min pour le datepicker
     *
     * @param isDebut true si c'est pour la date de début, sinon false
     */
    getMinDateRange(isDebut: boolean = true): moment.Moment {
        return isDebut ? this.data.dateMin : this.dateDebut;
    }


    /**
     * Indique si la date de début est valide
     */
    isDateDuInvalide() {
        return this.dateDebut == null || this.dateFin != null && this.dateDebut.isAfter(this.dateFin);
    }

    /**
     * Indique si la date de fin est valide
     */
    isDateAuInvalide() {
        return this.dateFin == null || this.dateDebut != null && this.dateFin.isBefore(this.dateDebut);
    }
}

/** Enumération des actions possibles */
enum Action {
    DEGREVEMENT = 0,
    RETABLISSEMENT = 1
}
