<mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header *ngIf="liste.data.listeResultats.length > 1">
        <ng-container *ngTemplateOutlet="title;context: {count: countSelection(),nbDestinatairesMasques: countDestinatairesSelectionnesMasques()}"></ng-container>
        <ng-template #title let-count="count" let-nbDestinatairesMasques="nbDestinatairesMasques">
            <mat-panel-title>
                {{ ('portee.' + data.typeObjet | translate) + ' ' + data.idObjet + ' - ' + data.titreMail + ' - ' + data.libelleRole }}
                <div class="r-flex align-items-center">
                    <!-- Icône indiquant qu'au moins un destinataire sélectionné est masqué à cause des filtres de recherche -->
                    <mat-icon *ngIf="nbDestinatairesMasques > 0" [nioTooltip]="('workflow.notification.tips.nbDestinatairesMasques.' + (nbDestinatairesMasques == 1 ? 'one' : 'plural')) | translate: { count: nbDestinatairesMasques }">info</mat-icon>
                    <!-- Bouton permettant de sélectionner ou désélectionner tous les destinataires -->
                    <button mat-stroked-button
                            [color]="this.extraOptions.isMailObligatoire && count === 0 ? 'warn' : 'primary'"
                            [matMenuTriggerFor]="!data.isAuto ? listeActions : null" [matMenuTriggerData]="{mail: data}"
                            [disableRipple]="data.isAuto"
                            (click)="$event.stopPropagation()"
                            [nioTooltip]="data.isAuto ? null : ((this.extraOptions.isMailObligatoire && count === 0 ? 'workflow.notification.tips.noDestinataire' : ('workflow.notification.tips.nbDestinataires.' + (count > 1 ? 'plural' : 'one'))) | translate:{count: count})">
                        <span>{{ count }} / {{ data.destinataires.length }}</span>
                        <mat-icon *ngIf="!data.isAuto" matSuffix>arrow_drop_down</mat-icon>
                        <mat-icon class="material-icons-outlined mat-icon-info" *ngIf="data.isAuto" matSuffix [nioTooltip]="'workflow.notification.tips.selectionAutoDestinataires' | translate">info</mat-icon>
                    </button>
                </div>
            </mat-panel-title>
        </ng-template>
    </mat-expansion-panel-header>
    <div class="listview local">
        <div class="listview-body">
            <div *ngFor="let user of listeVisibleDestinataires" (click)="!data.isAuto && this.onDestinataireChange(user)" class="listview-item" [ngClass]="{'clickable': !data.isAuto}">
                <div class="lvi-content">
                    <div class="avatar">
                        <mat-icon>{{ user.isSelected ? 'done' : 'person'}}</mat-icon>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <span>{{ user | user }}</span>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'workflow.notification.priorite.title'"></strong><span
                                    [translate]="'workflow.notification.priorite.'+user.prioriteKey"></span></li>
                            <li><strong [translate]="'workflow.notification.role'"></strong><span>{{ user.libelleRole }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="data.destinataires.length > 0 && !hasDestinatairesVisible()" class="empty">
                <span [translate]="'workflow.notification.aucunDestinataireCorrespondant'"></span>
            </div>
            <div *ngIf="data.destinataires.length == 0" [ngClass]="{ 'alert-danger': data.obligatoire,'alert-warning': !data.obligatoire }" class="alert">
                <h4 [translate]="'workflow.notification.alerte.title.'+(data.obligatoire ? 'warning' : 'info')" class="alert-heading"></h4>
                <p [translate]="'workflow.notification.alerte.message.'+(data.isAuto ? 'auto' : 'manuel')"></p>
            </div>
        </div>
    </div>
</mat-expansion-panel>

<!-- Sélection / déselection par mail -->
<mat-menu #listeActions="matMenu">
    <ng-template matMenuContent let-mail="mail">
        <button mat-menu-item translate (click)="selectAll()">global.actions.toutSelectionner</button>
        <button mat-menu-item translate (click)="selectNone()">global.actions.toutDeselectionner</button>
    </ng-template>
</mat-menu>
