import {EngagementsResumeItem} from "./engagements-resume-item";

/**
 * Engagements - Résumé
 */
export class EngagementsResume {
    /** Données */
    listeEngagement: Array<EngagementsResumeItem>;
    total: EngagementsResumeItem;
    pourcentageAvance: number;

    /**
     * Constructeur
     *
     * @param engagementsResume Données
     */
    constructor(engagementsResume: EngagementsResume) {
        //Vérification de la présence de données
        if (engagementsResume) {
            //Copie des propriétés
            Object.assign(this,engagementsResume);

            //Instanciation des lignes d'engagements
            this.listeEngagement = engagementsResume.listeEngagement?.map(engagement => {
                //Instanciation à partir des données
                return new EngagementsResumeItem(engagement);
            });

            //Instanciation de la ligne de total à partir des données
            this.total = new EngagementsResumeItem(engagementsResume.total,true);
        }
    }

}