import {RuleFilter} from "@domain/rule/rule-filter";
import {TypeValue} from "@domain/rule/type-value";
import {TypeFonction} from "@domain/rule/type-fonction";
import {Rule} from "@domain/rule/rule";

/**
 * Modèle d'une valeur de filtre de règle
 */
export class RuleFilterValue {
	/** Identifiant */
	idValue: number;
	/** RuleFilter parent */
	filter: RuleFilter;
	/** Valeur string */
	value: string;
	/** Valeur date */
	dateValue: string;
	/** Valeur nombre */
	numberValue: number;
	/** Valeur booléenne */
	booleanValue: boolean;
	/** Type de valeur (SIMPLE OU OBJET) */
	type: TypeValue;
	/** Type de fonction */
	fonction: TypeFonction;
	/** Position */
	position: number;
	/** Rule embarquée */
	rule: Rule;
}