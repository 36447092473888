import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {Filter,TypeComparaison} from "@domain/common/list-view";
import {SearchType} from "@domain/common/list-view/sorting";
import {UserDto} from "@domain/user/user-dto";
import {UserPipeTransform} from "@share/pipe/user.pipe";
import {HttpParams} from "@angular/common/http";

/**
 * Options de l'autocomplete de recherche d'un collaborateur pour l'utilisateur courant
 */
export default class CollabOptions implements AutocompleteOptions {
    /** Mode de recherche */
    searchType = SearchType.CONTAINS;
    
    /**
     * Affichage d'un élément
     */
    displayItem(item: UserDto): string {
        return UserPipeTransform(item);
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.prenom'),
            clef: 'prenom',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }, {
            title: translateService.instant('filter.nom'),
            clef: 'nom',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }, {
            title: translateService.instant('filter.matricule'),
            clef: 'matricule',
            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
            isDefault: true
        }, {
            title: translateService.instant('filter.service'),
            clef: 'orga.libelle',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: false
        }];
    }

    /**
     * Récupération de l'URL
     */
    url(filter: any): string {
        //Construction des paramètres à envoyer pour la récupération de la liste des collaborateurs en fonction de la portée de l'objet à créer
        let params = new HttpParams();
        if (filter?.portee) {
            params = params.append("portee",filter.portee);
        }
        
        return `/controller/User/listeCollabPourCreation?` + params.toString();
    }

}
