import {AfterViewChecked, Directive, ElementRef, HostListener, Input} from '@angular/core';

/**
 * Directive permettant d'appliquer la hauteur du plus grand élément aux autres
 */
@Directive({
  selector: '[matchChildrenHeight]'
})
export class MatchChildrenHeightDirective implements AfterViewChecked {

  /** Le nom de la classe sur laquelle sera appliqué la taille */
  @Input()
  matchChildrenHeight: any;

  constructor(private el: ElementRef) {
  }

  /**
   * Listener Angular
   */
  ngAfterViewChecked() {
    this.matchHeight(this.el.nativeElement, this.matchChildrenHeight);
  }

  /**
   * Listener OnResize
   */
  @HostListener('window:resize')
  onResize() {
    this.matchHeight(this.el.nativeElement, this.matchChildrenHeight);
  }

  /**
   * Permet d'appliquer la taille du plus grand élément aux autres
   * @param parent element parent
   * @param className nom de classe
   */
  matchHeight(parent: HTMLElement, className: string) {
    if (!parent) return;
    const children = parent.getElementsByClassName(className);

    if (!children) return;

    //Reset de toutes les tailles de enfants
    Array.from(children).forEach((x: HTMLElement) => {
      x.style.height = 'initial';
    })

    //Récupération des tailles
    const itemHeights = Array.from(children)
        .map(x => x.getBoundingClientRect().height);

    //Récupération de la plus grande taille
    const maxHeight = itemHeights.reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

    //Application de la taille la plus grande sur tous les éléments
    Array.from(children)
        .forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
  }
}
