<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.zones.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToZoneDetail()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="nbPays">
                <strong [translate]="'admin.bibliotheque.geographie.zone.pays'"></strong>
                <span>{{ nbPays }}</span>
            </li>
            <li *ngIf="!nbPays">
                <strong [translate]="'admin.bibliotheque.geographie.zone.pays'"></strong>
                <span [translate]="'admin.bibliotheque.geographie.zone.aucunPays'"></span>
            </li>
        </ul>
    </div>
</div>