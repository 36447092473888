import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Report} from "../../domain/reporting/report";
import {ReportingService} from "./reporting.service";

@Component({
    selector: 'reporting',
    templateUrl: './reporting.component.html'
})
export class ReportingComponent implements OnInit {

    public isSaving: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public report: Report, private reportService: ReportingService, private matDialogRef: MatDialogRef<any, any>) {
    }

    ngOnInit(): void {
    }

    /**
     * Lancement du rapport
     */
    execute() {
        //Mise à jour flag
        this.isSaving = true;

        //Execution du rapport
        this.reportService.executeReport(this.report);

        //Mise à jour flag
        this.isSaving = false;

        //Fermeture de l'écran
        this.matDialogRef.close();
    }

}
