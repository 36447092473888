import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {CompteAdminComponent} from "@components/admin/maintenance/compte-admin/compte-admin.component";
import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {TranslateModule} from "@ngx-translate/core";
import {ProfilModule} from "@components/profil/profil.module";
import {GestionLogsComponent} from "@components/admin/maintenance/gestion-logs/gestion-logs.component";
import {GestionLogsModule} from "@components/admin/maintenance/gestion-logs/gestion-logs.module";
import {maintenanceMigrationsRoutes,MigrationsModule} from "@components/admin/maintenance/migrations/migrations.module";
import {MigrationsComponent} from "@components/admin/maintenance/migrations/migrations.component";

export const adminMaintenanceRoutes: Routes = [
	{
		path: 'CompteAdmin',
		component: CompteAdminComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'GestionLogs',
		component: GestionLogsComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Migrations',
		component: MigrationsComponent,
		canActivate: [AdminGuardProvider],
		children: maintenanceMigrationsRoutes
	},{
		path: '',
		redirectTo: 'CompteAdmin',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'CompteAdmin'
	}
];

@NgModule({
	imports: [ShareModule,TranslateModule,ProfilModule,GestionLogsModule,MigrationsModule],
	declarations: [CompteAdminComponent],
	exports: [CompteAdminComponent]
})
export class AdminMaintenanceModule {}
