import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Tournee} from "@domain/Tournee/tournee";
import {TourneeService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/tournee.service";
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import * as _ from "lodash";
import {filter} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {Unite} from "@domain/prestation/unite";
import {Adresse} from "@domain/profil/adresse";
import {EtapeTournee} from "@domain/Tournee/etape-tournee";
import {NameDialogComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/name-dialog/name-dialog.component";
import {StepperTourneeComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/stepper-tournee/stepper-tournee.component";
import {FraisService} from "@components/frais/frais.service";

/**
 * Composant qui affiche la popin permettant de rajouter une tournée
 */
@Component({
    host: {'data-test-id': 'ndf-frais-add-tournee'},
    selector: 'ndf-frais-add-tournee',
    templateUrl: './ndf-frais-add-tournee.component.html'
})
export class NDFFraisAddTourneeComponent implements OnInit {
    /** Subject signalant la demande de reset */
    resetSubject: Subject<void> = new Subject<void>();

    /** Le modèle du composant */
    tournee: Tournee;

    /** Le subcomponent stepperTournee */
    @ViewChild(StepperTourneeComponent) stepperTournee: StepperTourneeComponent;


    /**
     * Constructeur de l'objet
     */
    constructor(
        private translateService: TranslateService,
        private tourneeService: TourneeService,
        private confirmService: ConfirmService,
        private fraisService: FraisService,
        private matDialog: MatDialog,
        private matDialogRef: MatDialogRef<NDFFraisAddTourneeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            tournee: Tournee,
            unite: Unite,
            localisationDepart: Adresse,
            localisationArrivee: Adresse,
            allerRetour: boolean,
            etapesPersonnelles: boolean,
            gestionTempsGlobale: boolean,
            gestionTempsDetaillee: boolean
            quantiteModifiable: boolean,
            canModifier: boolean,
        }) {
    }

    /**
     * Initialiseur de l'objet
     */
    ngOnInit() {
        //On est en création
        if (this.data.tournee == null) {
            //On initialise les valeurs par défaut
            const tournee =  new Tournee();
            tournee.heureDebut = "";
            tournee.heureFin = "";
            tournee.unite = this.data.unite;

            tournee.listeEtapes = [];

            //Ajout des étapes à partir des champs départ et arrivé
            if (this.data.localisationDepart != null && this.data.localisationArrivee != null) {
                tournee.listeEtapes.push(this.getNewEtape(true,0));

                //Ajout d'une seconde étape si on a un aller retour
                if (this.data.allerRetour) {
                    tournee.listeEtapes.push(this.getNewEtape(false,1));
                }
            } else {
                //On crée une tournée vide
                tournee.listeEtapes.push(new EtapeTournee());
            }

            this.tournee = tournee;
        } else {
            //On est en modification
            //On clone pour éviter de modifier les données sans sauvegarder
            this.tournee = _.cloneDeep(this.data.tournee);
        }
    }

    /**
     * Ouvre le popin mémoriser
     */
    openMemoriserDialog(): void {
        this.matDialog.open(NameDialogComponent, {
            width: '600px',
            position: {
                top: '100px'
            },
            role: 'alertdialog',
            data: {
                tournee: this.tournee
            }
        });
    }

    /**
     * Handler du bouton fermer
     */
    onGoBack() {
        this.matDialogRef.close(null);
    }

    /**
     * Handler du bouton effacer
     */
    onEffacerChamps() {
        //Popin confirmation
        this.confirmService.showConfirm(this.translateService.instant('ndf.frais.ajouterTournee.confirmationEffacement'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //On émet la demande au subject
                this.resetSubject.next();
            }
        });
    }

    /**
     * Handler du bouton mémoriser
     */
    onMemoriser() {
        this.openMemoriserDialog();
    }

    /**
     * Handler du bouton enregistrer
     */
    onEnregistrer() {
        //On cumule la distance de chaque étape
        let distance = 0;
        this.tournee.listeEtapes.forEach(etape => {
            //Uniquement si perso n'est pas activé sur l'étape
            if (!etape.personnel) {
                distance += etape.distance;
            }
        })
        this.tournee.distance = distance;

        //On sauvegarde la tournée à partir du modèle
        this.matDialogRef.close(this.tournee)
    }

    /**
     * Retourne true si un formulaire d'un sous composant est en erreur
     */
    hasSubFormError(): boolean {
        if (this.stepperTournee != null) {
            return this.stepperTournee.hasFormError();
        }
    }

    /**
     * Retourne une nouvelle étape
     * @param isAller false pour inverser les localisation (retour)
     */
    getNewEtape(isAller: boolean, index: number): EtapeTournee {
        let etape = new EtapeTournee();
        etape.localisationDepart = isAller ?  this.data.localisationDepart : this.data.localisationArrivee;
        etape.localisationArrivee = isAller ?  this.data.localisationArrivee : this.data.localisationDepart;
        etape.ordre = index;

        //On calcule la distance
        this.fraisService.findDistance(etape.localisationDepart, etape.localisationArrivee, false)
            .subscribe(  (distance: number) => etape.distance = distance);

        return etape;
    }
}