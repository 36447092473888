import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {LienRoleUser,TypeLienRoleUser,TypePriorite} from "@domain/workflow/lienRoleUser";
import {TranslateService} from "@ngx-translate/core";
import {NgForm} from "@angular/forms";
import {TypeProfil,User} from '@domain/user/user';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {filter,finalize,first} from 'rxjs/operators';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {SelectEnveloppesPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-generalites/select-enveloppes-popin/select-enveloppes-popin.component";
import {Enveloppe} from "@domain/budget/enveloppe";
import * as moment from "moment";
import {TypeRoleOption} from "@domain/role/typeRoleOption.enum";
import {MatTabGroup} from "@angular/material/tabs";
import {ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {EnveloppeListItemComponent} from '../select-enveloppes-popin/enveloppe-list-item/enveloppe-list-item.component';
import {Page} from "@domain/common/http/list-result";
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {Store} from "@ngrx/store";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {AppState} from "@domain/appstate";

/**
 * Popin d'ajout d'une habilitation
 */
@Component({
	host: {'data-test-id': 'add-habilitation-popin'},
	templateUrl: './add-habilitation-popin.component.html'
})
export class AddHabilitationPopinComponent implements OnInit {
	/** L'utilisateur connecté */
	connectedUser: User;

	/** Habilitation */
	habilitation: LienRoleUser;

	/** Indique si on est en train d'éditer un rôle collaborateur */
	isRoleCollab: boolean;

	/** Filtres de l'autocomplete des rôles */
	roleAutocompleteFilters: Record<string,any> = {};

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/** MatTabGroup */
	@ViewChild('matTabGroup') matTabGroup: MatTabGroup;

	/** Type de priorité titulaire */
	readonly prioTitulaire: { code: TypePriorite,libelle: string } = {
		code: TypePriorite.TITULAIRE,
		libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.titulaire')
	};

	/** Type de priorité suppléant */
	readonly prioSuppleant: { code: TypePriorite,libelle: string } = {
		code: TypePriorite.SUPPLEANT,
		libelle: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.suppleant')
	};

	/** Liste des types de priorité disponibles */
	listeTypePriorite: Array<{ code: TypePriorite,libelle: string }> = [];

	/** Liste des enveloppes */
	listeEnveloppes: ListView<Enveloppe,EnveloppeListItemComponent>;

	/** Liste des enveloppes (en création) */
	@ViewChild('listeEnveloppesCreationComponent') listeEnveloppesCreationComponent: ListViewComponent<Enveloppe,EnveloppeListItemComponent>;
	listeEnveloppesCreation: ListView<Enveloppe,EnveloppeListItemComponent>;

	/** Sauvegarde en cours */
	isPending: boolean = false;

	/** Import des énums pour le DOM */
	readonly TypeProfil = TypeProfil;
	readonly TypeRoleOption = TypeRoleOption;

	/** Date du jour */
	today: Date = moment().startOf('day').toDate();

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param store le store de l'appli
	 * @param translateService le moteur de traduction
	 * @param userService le service de gestion de l'utilisateur
	 * @param toastrService le toaster
	 * @param matDialogRef ref du matDialog
	 * @param confirmService le service de confirmation
	 * @param matDialog le service de gestion des popups
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { isCreation: boolean,idUser?: number,habilitation?: LienRoleUser,isCollab: boolean,isHistory: boolean },
		private store: Store<AppState>,
		private translateService: TranslateService,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<AddHabilitationPopinComponent>,
		private confirmService: ConfirmService,
		private matDialog: MatDialog
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de l'utilisateur connecté
		this.store.select(state => state.session?.user).pipe(filterFirstNotNull()).subscribe(user => {
			this.connectedUser = user
		});

		if (this.data.isCreation) {
			//Initialisation de l'habilitation
			this.habilitation = new LienRoleUser();

			//Valeurs par défaut
			this.habilitation.idUser = this.data.idUser;
			this.habilitation.priorite = TypePriorite.TITULAIRE;
			this.habilitation.type = TypeLienRoleUser.HABILITATION;
			this.habilitation.listeEnveloppes = [];
		} else {
			//Récupération de l'habilitation
			this.habilitation = JSON.parse(JSON.stringify(this.data.habilitation));

			//Initialisation de la liste des priorités
			this.updateListePriorites(this.habilitation.priorite);

			//Si l'habilitation concerne un rôle budgétaire
			if (this.isRoleBudgetaire()) {
				//Initialisation de la liste des enveloppes
				this.initListeEnveloppes();
			}
		}

		//Initialisation de l'indicateur rôle collab
		this.isRoleCollab = this.habilitation.role?.fonction === TypeProfil.COLLABORATEUR

		//Construction des filtres de l'autocomplete des rôles
		this.buildRoleAutocompleteFilters();
	}

	/**
	 * Initialisation de la liste des priorités disponibles
	 */
	updateListePriorites(priorite?: TypePriorite) {
		//On commence par vider la liste des priorités
		this.listeTypePriorite.splice(0);

		//Si le mode titulaire est disponible (c'est qu'il est false...) ou que la priorité de base de l'habilitation est titulaire
		if (!this.habilitation.role.modeTitulaire || priorite === TypePriorite.TITULAIRE) {
			//On ajoute la priorité titulaire
			this.listeTypePriorite.push(this.prioTitulaire);
		}

		//Quoi qu'il arrive, on ajoute la priorité "suppléant"
		this.listeTypePriorite.push(this.prioSuppleant);

		//S'il n'y a qu'une priorité, on la sélectionne
		if (this.listeTypePriorite.length == 1) {
			this.habilitation.priorite = this.listeTypePriorite[0].code;
		} else if (!this.listeTypePriorite.some(p => p.code == priorite)) {
			//S'il y a plusieurs habilitations possibles et que la priorité courante n'est pas dans la liste
			//On sélectionne la titulaire
			this.habilitation.priorite = this.prioTitulaire.code;
		}
	}

	/**
	 * Initialisation de la liste des enveloppes
	 */
	initListeEnveloppes(): void {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			//Initialisation de la liste des enveloppes
			this.listeEnveloppes = new ListView<Enveloppe,EnveloppeListItemComponent>({
				uri: '/controller/Budget/getEnveloppes',
				title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.enveloppeTab.listTitle'),
				component: EnveloppeListItemComponent,
				defaultOrder: 'libelle',
				listeFilters: [
					{
						clef: 'reference',
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE],
						title: this.translateService.instant('filter.reference')
					},{
						clef: 'libelle',
						isDefault: true,
						typeComparaison: TypeComparaison[TypeComparaison.LIKE],
						title: this.translateService.instant('filter.libelle')
					}
				],
				listeStaticFilters: [{
					clef: 'listeLienRoleUser.idLienRoleUser',
					type: TypeFilter[TypeFilter.LONG],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					valeur: this.habilitation.idLienRoleUser.toString()
				}],
				onRefresh: (liste) => {
					//Mise à jour de l'habilitation
					this.habilitation.listeEnveloppes = liste.data.listeResultats;
				},
				listeActions: !this.data.isHistory && [{
					icon: "add",
					onPress: () => this.onAddEnveloppe()
				}]
			});
		} else {
			//Initialisation de la liste des enveloppes
			this.listeEnveloppesCreation = new ListView<Enveloppe,EnveloppeListItemComponent>({
				title: this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.enveloppeTab.listTitle'),
				component: EnveloppeListItemComponent,
				isFrontendList: true,
				data: {
					listeResultats: [],
					numPage: 0,
					nbObjetsParPage: 0,
					nbObjetsDansPage: 0,
					nbPagesTotal: 1,
					nbObjetsTotal: 0
				} as Page<Enveloppe>,
				listeActions: [{
					icon: "add",
					onPress: () => this.onAddEnveloppe()
				}]
			});

			//Rafraîchissement de la pagination
			setTimeout(() => {
				this.listeEnveloppesCreationComponent.refreshListPagination();
			})
		}
	}

	/**
	 * Construction des filtres de l'autocomplete des rôles
	 */
	buildRoleAutocompleteFilters(): void {
		//Si l'on est en consultation
		if (!this.data.isCreation) {
			//Si l'on consulte un rôle collab
			if (this.habilitation.role.fonction === TypeProfil.COLLABORATEUR) {
				//On définit le filtre isCollab à false et le filtre fonction à COLLABORATEUR
				this.roleAutocompleteFilters.isCollab = false;
				this.roleAutocompleteFilters.fonction = TypeProfil.COLLABORATEUR;
			} else {
				//On définit le filtre isCollab à data.isCollab et le filtre fonction à la fonction du rôle consulté
				this.roleAutocompleteFilters.isCollab = this.data.isCollab;
				this.roleAutocompleteFilters.fonction = this.habilitation.role.fonction;
			}
		} else {
			//Sinon, en création
			//On définit le filtre isCollab à data.isCollab et le filtre fonction à zéro
			this.roleAutocompleteFilters.isCollab = this.data.isCollab;
			this.roleAutocompleteFilters.fonction = 0;
		}
	}

	/**
	 * Déclenchée à chaque changement de rôle
	 */
	onRoleChange(): void {
		//Mise à jour d'idRole dans l'habilitation
		this.habilitation.idRole = this.habilitation.role?.idRole;

		//Reinitialisation des valeurs par défaut
		delete this.habilitation.listeEnveloppes;
		delete this.habilitation.population;
		delete this.habilitation.idPopulation;
		delete this.habilitation.dateDebut;
		delete this.habilitation.dateFin;

		//Mise à jour de la liste des modes gestionnaires
		this.updateListePriorites();

		//Si le nouveau rôle est un rôle budgétaire
		if (this.isRoleBudgetaire()) {
			//Initialisation de la liste des enveloppes
			this.initListeEnveloppes();
		}
	}

	/**
	 * Renvoie true si le rôle actuel à une spécificité de RESPONSABLE BUDGETAIRE
	 */
	isRoleBudgetaire(): boolean {
		return this.habilitation.role?.listeRoleOption?.some(ro => ro.typeRoleOption === TypeRoleOption[TypeRoleOption.RESPONSABLE_BUDGET]);
	}

	/**
	 * Ouverture de la popup de sélection des enveloppes
	 */
	onAddEnveloppe(): void {
		//Ouverture de la popup de sélection des enveloppes
		this.matDialog.open(SelectEnveloppesPopinComponent,{
			data: {
				idLienRoleUser: this.habilitation.idLienRoleUser,
				listeIdEnveloppe: this.habilitation.listeEnveloppes?.map(e => e.idEnveloppe)
			}
		}).afterClosed().subscribe((listeEnveloppe: Enveloppe[]) => {
			//Vérification de la liste des enveloppes
			if (listeEnveloppe) {
				//Si l'on est en consultation
				if (this.habilitation.idLienRoleUser) {
					//Appel au service
					this.userService.addEnveloppeToHabilitation(this.habilitation.idLienRoleUser,listeEnveloppe.map(enveloppe => enveloppe.idEnveloppe))
						.pipe(first())
						.subscribe((result: Result) => {
							//Vérification du result
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast succès
								this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

								//Refresh liste
								this.listeEnveloppes.refresh();
							}
						})
				} else {
					//Sinon, on est en création
					//Remplacement de la liste dans l'habilitation
					this.habilitation.listeEnveloppes = listeEnveloppe;

					//Mise à jour de la liste
					this.listeEnveloppesCreation.data = {
						listeResultats: this.habilitation.listeEnveloppes,
						numPage: 0,
						nbObjetsParPage: this.habilitation.listeEnveloppes.length,
						nbObjetsDansPage: this.habilitation.listeEnveloppes.length,
						nbPagesTotal: 1,
						nbObjetsTotal: this.habilitation.listeEnveloppes.length
					} as Page<Enveloppe>;

					//Désélection des éléments
					this.onUnselectAll();

					//Rafraîchissement de la pagination
					this.listeEnveloppesCreationComponent.refreshListPagination();
				}
			}
		});
	}

	/**
	 * Déclenchée à chaque changement de population
	 */
	onPopulationChange(): void {
		//Mise à jour d'idPopulation dans l'habilitation
		this.habilitation.idPopulation = this.habilitation.population?.idPopulation;
	}

	/**
	 * Détermine si le champ population est requis ou non
	 */
	isPopulationRequired(): boolean {
		//Si l'utilisateur connecté est sous-administrateur
		if (this.connectedUser.fonction === TypeProfil.SOUS_ADMINISTRATEUR) {
			//Le champ population est requis si tous les rôles de sous-administrateur de l'utilisateur connecté ont une population
			return this.connectedUser.listeLienRoleUsers
				.filter(lru => lru.role.fonction === TypeProfil.SOUS_ADMINISTRATEUR)
				.every(lru => lru.population);
		}

		return false;
	}

	/**
	 * Le bouton "Tout sélectionner" est-il affiché
	 */
	isSelectAllDisplayed(): boolean {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			return !!this.listeEnveloppes.data?.listeResultats?.length;
		} else {
			//Sinon, on est en création
			return !!this.listeEnveloppesCreation.data?.listeResultats?.length;
		}
	}

	/**
	 * Le bouton "Retirer la sélection" est-il affiché
	 */
	isRemoveSelectionDisplayed(): boolean {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			return !!this.listeEnveloppes.listeObjetsSectionnes?.length;
		} else {
			//Sinon, on est en création
			return !!this.listeEnveloppesCreation.listeObjetsSectionnes?.length;
		}
	}

	/**
	 * Sélection de toutes les enveloppes affichées
	 */
	onSelectAll(): void {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			//Sélection des enveloppes
			this.listeEnveloppes.selectAll(true);
		} else {
			//Sinon, on est en création, sélection des enveloppes
			this.listeEnveloppesCreation.selectAll(true);
		}
	}

	/**
	 * Le bouton "Tout sélectionner" est-il désactivé
	 */
	isSelectAllDisabled(): boolean {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			return this.listeEnveloppes.listeObjetsSectionnes.length === this.listeEnveloppes.data.listeResultats.length;
		} else {
			//Sinon, on est en création
			return this.listeEnveloppesCreation.listeObjetsSectionnes.length === this.listeEnveloppesCreation.data.listeResultats.length;
		}
	}

	/**
	 * Désélection de toutes les enveloppes affichées
	 */
	onUnselectAll(): void {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			//Sélection des enveloppes
			this.listeEnveloppes.selectAll(false);
		} else {
			//Sinon, on est en création, sélection des enveloppes
			this.listeEnveloppesCreation.selectAll(false);
		}
	}

	/**
	 * Le bouton "Tout désélectionner" est-il désactivé
	 */
	isUnselectAllDisabled(): boolean {
		//Si l'on est en consultation
		if (this.habilitation.idLienRoleUser) {
			return this.listeEnveloppes.listeObjetsSectionnes.length === 0;
		} else {
			//Sinon, on est en création
			return this.listeEnveloppesCreation.listeObjetsSectionnes.length === 0;
		}
	}

	/**
	 * Suppression des enveloppes sélectionnées
	 */
	onRemoveSelected(): void {
		//Ouverture de la boîte de dialogue pour confirmer la suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Si l'on est en consultation
				if (this.habilitation.idLienRoleUser) {
					//Suppression des objets sélectionnés
					this.userService.deleteEnveloppeFromHabilitation(this.habilitation.idLienRoleUser,this.listeEnveloppes.listeObjetsSectionnes.map(enveloppe => enveloppe.idEnveloppe))
						.pipe(first())
						.subscribe((result: Result) => {
							//Vérification du result
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast succès
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Refresh liste
								this.listeEnveloppes.refresh();
							}
						});
				} else {
					//Parcours des objets sélectionnés
					this.listeEnveloppesCreation.listeObjetsSectionnes.forEach((enveloppe: Enveloppe) => {
						//Récupération de l'index de l'enveloppe
						const index: number = this.listeEnveloppesCreation.data.listeResultats.findIndex(element => element.idEnveloppe === enveloppe.idEnveloppe);

						//Suppression de l'enveloppe
						this.listeEnveloppesCreation.data.listeResultats.splice(index,1);

						//Mise à jour des infos de pagination
						this.listeEnveloppesCreation.data = {
							listeResultats: this.listeEnveloppesCreation.data.listeResultats,
							numPage: 0,
							nbObjetsParPage: this.listeEnveloppesCreation.data.listeResultats.length,
							nbObjetsDansPage: this.listeEnveloppesCreation.data.listeResultats.length,
							nbPagesTotal: 1,
							nbObjetsTotal: this.listeEnveloppesCreation.data.listeResultats.length
						} as Page<Enveloppe>
					});

					//Déselection de tous les éléments
					this.onUnselectAll();

					//Rafraîchissement de la pagination
					this.listeEnveloppesCreationComponent.refreshListPagination();
				}
			});
	}

	/**
	 * Sauvegarde de l'habilitation
	 */
	onSave(): void {
		let habilitationToSave: LienRoleUser;

		//Chargement en cours
		this.isPending = true;

		//Création d'une copie de l'habilitation pour l'enregistrement car en modification la liste des enveloppes n'est pas envoyée,
		//(et si on supprime la liste de l'habilitation même temporairement l'écran va refléter cette modification le temps que l'enregistrement se fasse)
		if (this.habilitation.idLienRoleUser) {
			//Si le lien existe déjà, inutile d'envoyer les enveloppes car elles sont enregistrées à chaque ajout / suppression
			habilitationToSave = Object.assign(new LienRoleUser(),this.habilitation,{listeEnveloppes: null});
		} else {
			//En création, les enveloppes doivent être envoyées pour être associée à la nouvelle habilitation
			habilitationToSave = this.habilitation;
		}

		//Appel au service
		this.userService.saveHabilitation(habilitationToSave)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du résultat de l'appel
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popup
					this.matDialogRef.close(true);
				} else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
					//Erreur doublon
					this.toastrService.error(this.translateService.instant('admin.entreprise.utilisateurs.detail.generalites.habilitations.addHabilitationPopin.erreurDoublon'));
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de l'habilitation
	 */
	onDelete(): void {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Chargement en cours
					this.isPending = true;

					//Appel au service
					this.userService.deleteHabilitation(this.habilitation.idLienRoleUser)
						.pipe(first(),finalize(() => this.isPending = false))
						.subscribe((result: Result) => {
							//Vérification du résultat
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast succès
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Fermeture de la popup
								this.matDialogRef.close(true);
							} else {
								//Gestion de l'erreur
								TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
							}
						});
				}
			});
	}

	/**
	 * Méthode de vérification du formulaire
	 */
	isFormInvalid(): boolean {
		//Si le ngForm est valide
		if (this.form?.valid) {
			//On check si le rôle de l'habilitation a une spécificité 36 (budget)
			if (this.isRoleBudgetaire()) {
				//Si oui, on vérifie si la liste des enveloppes n'est pas vide
				return !(this.habilitation.listeEnveloppes && this.habilitation.listeEnveloppes.length > 0);
			}
			//Sinon le form est valide
			return false;
		}
		//Sinon le form est invalide
		return true;
	}
}
