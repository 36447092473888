import {Component,Input} from '@angular/core';

/**
 * Composant d'affichage d'un titre simple pour une branche d'un arbre hiérarchique (Juste un libellé, sans tag, sans avatar)</br>
 * Peut être étendu pour ajouter plus de fonctionnalités
 *
 * @author Laurent SCIMIA
 * @date 30/11/2023
 * @see ListItemTitleComponent
 */
@Component({
	host: {'data-test-id': 'item-title'},
	templateUrl: './item-title.component.html',
	styleUrls: ['./item-title.component.scss']
})
export class ItemTitleComponent<T> {
	/** Input du composant */
	@Input() input: Record<'data',{ libelle: string, infos?: T }>;
}