import {Component,EventEmitter,Input,Output,ViewChild} from '@angular/core';
import {LieuService} from "./lieu.service";
import {TypePortee} from "../../domain/workflow/workflow";
import {LieuOD} from "../../domain/lieu/lieuod";
import {TypeLieu} from "../../domain/lieu/typeLieu";
import {MatInput} from "@angular/material/input";
import {TypeEntiteParamOD,TypeEntiteParamOT,TypeEntiteParamPRF} from "../../domain/typeentite/typeEntiteParam";
import {TranslateService} from "@ngx-translate/core";
import {LieuProfil} from "../../domain/lieu/lieuProfil";
import {Lieu} from "../../domain/lieu/lieu";
import {Observable} from "rxjs";
import {LieuSelectionComponent} from "./selection/lieu-selection.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'lieu',
    host: {'data-test-id': 'lieu'},
    templateUrl: './lieu.component.html',
    exportAs: 'lieuComponent'
})
export class LieuComponent {
    /** Type de lieu */
    @Input() typeLieu: TypeLieu;
    @Output() typeLieuChange = new EventEmitter<TypeLieu>();

    /** Lieu */
    @Input() lieu: LieuProfil | LieuOD;
    @Output() lieuChange = new EventEmitter<LieuProfil | LieuOD>();

    /** Portée de l'objet (OD / OT / PRF) */
    @Input() idPortee: TypePortee;

    /** Identifiant de l'objet (OD / OMP) auquel le lieu est associé */
    @Input() idObject: number;

    /** Identifiant de l'utilisateur propriétaire de l'objet, et donc de l'adresse */
    @Input() idUser: number;

    /** Type entité associé */
    @Input() typeEntiteParam: TypeEntiteParamOT | TypeEntiteParamOD | TypeEntiteParamPRF;

    /** Champ */
    @ViewChild('input') input: MatInput;

    /** Composant désactivé ou non */
    @Input() disabled: boolean;

    /** Valeur obligatoire ou non */
    @Input() required: boolean;

    /** Fonction de suppression de la valeur */
    @Input() onClear: () => void;

    /** Placeholder du champ "Adresse" différent suivant les cas d'usage */
    get placeholder(): string {
        return this.lieu?.adresse ? ''
            : this.disabled ? (this.translateService.instant('lieu.aucuneSelection'))
            : ([TypePortee.PRF,TypePortee.ADM].includes(this.idPortee) ? this.translateService.instant('profilUser.adresses.add.selection') : this.translateService.instant('lieu.selectionnerLieu'));
    }

    /**
     * Constructeur
     */
    constructor(private lieuService: LieuService,
                private translateService: TranslateService,
                private matDialog: MatDialog) {}

    /**
     * Sélection d'une adresse
     *
     * @param $event événement utilisateur provenant du clic sur le la valeur
     */
    showPopupChoixLieu($event?: Event): void {
        //Si la source de l'action provient d'un événement utilisateur on stop la propagation de l'événement
        if ($event) {
            $event.stopPropagation();
        }

        //Composant désactivé : pas de raison d'ouvrir la popup
        if (this.disabled) {
            return;
        }

        //Ouverture de la popup
        this.openPopupChoixLieu(this.idUser,this.idPortee,this.idObject,this.typeEntiteParam,this.typeLieu,this.lieu).subscribe({
            next: data => {
                //Vérification du résultat
                if (data) {
                    //Mise à jour de l'objet et du type...
                    //Uniquement pour les lieux OD/OT : cela permet de revenir sur l'onglet "saisie d'adresse". Dans les autres cas ce n'est pas ce qu'on veut.
                    if (data.type && (this.idPortee == TypePortee.OD || this.idPortee == TypePortee.OT)) {
                        this.typeLieu = data.type;
                    }
                    this.lieu = {
                        ...this.lieu,
                        idLocalisation: data.objet?.idAdresse ?? data.objet?.id,
                        adresse: data.adresse,
                        libelleEtablissement: data.objet?.libelle,
                        rue: data.objet?.rue,
                        codePostal: data.objet?.codePostal,
                        ville: data.objet?.ville,
                        pays: data.objet?.pays,
                        codePays: data.objet?.codePays3
                    };

                    //... et propagation
                    this.typeLieuChange.emit(this.typeLieu);
                    this.lieuChange.emit(this.lieu);
                }
            }
        });
    }

    /**
     * Affichage de la popup de sélection d'une adresse
     *
     * @param idUser Identifiant du propriétaire de l'adresse
     * @param idPortee Identifiant de la portée
     * @param idObject Identifiant de l'objet
     * @param typeEntiteParam Paramètres associés au type entité
     * @param typeLieu Type de lieu
     * @param lieu L'objet LieuOD à compléter après sélection d'une adresse
     */
    private openPopupChoixLieu(idUser: number,idPortee: TypePortee,idObject: number,typeEntiteParam: any,typeLieu: number,lieu: Lieu | LieuOD): Observable<any> {
        //Affichage du chainage
        return this.matDialog.open(LieuSelectionComponent,{
            data: {
                getGeolocalisationTool: () => this.lieuService.getGeolocalisationTool(),
                loadAdresses: () => this.lieuService.loadAdresses(idPortee,idObject),
                isSaisieAutorisee: this.lieuService.isSaisieAutorisee(typeEntiteParam,idPortee),
                idPortee,
                idUser,
                typeEntiteParam,
                typeLieu,
                lieu
            },
            minWidth: 1000
        }).afterClosed();
    }

    /**
     * Vidage du champ
     *
     * @param $event Évènement (clic)
     */
    clear($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.onClear();
    }

}
