<page-header
        [title]="('admin.voyages.travelhub.sbtConfig.details.title' | translate) + (sbtConfigUsed?.libelle ? ' ' + sbtConfigUsed?.libelle : '')"
        [listeItems]="listeTabItems"
        [niveauAlerte]="sbtConfigUsed?.listeAlertes?.niveau"
        (onSelectedItemChange)="onSelectedItemChange($event)"
        (onGoBack)="onGoBack()">
    <preview-alerte #alerte *ngFor="let alerte of sbtConfigUsed?.listeAlertes?.listeAlertes" [alerte]="alerte" translateAlerte="true"></preview-alerte>
</page-header>

<please-wait *ngIf="!sbtConfigUsed"></please-wait>

<form #form="ngForm">
    <ng-container *ngIf="sbtConfigUsed">
        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <sbt-config-generalites
                [sbtConfigUsed]="sbtConfigUsed"
                [listeParamConnexion]="listeParamConnexion"
                [listeParamConnexionUsed]="listeParamConnexionUsed"
                [listeCustomFieldUsed]="listeCustomFieldUsed"
                [listeTripFieldUsed]="listeTripFieldUsed"
                [listeValeurParamConnexion]="listeValeurParamConnexion"
                [listeCustomField]="listeCustomField"
                [listeTripField]="listeTripField"
                [listePrestaAutorisee]="listePrestaAutorisee"
                [listeConfigTH]="listeConfigTH"
                [listeConfigSBT]="listeConfigSBT"
                (sbtConfigChanged)="onSbtConfigChanged()"
            ></sbt-config-generalites>
        </div>
        <div *ngIf="sbtConfigUsed?.idSBTConfigUsed > 0" [hidden]="selectedItem?.code !== Onglets.REFERENTIELS">
            <sbt-config-referentiels
                     [sbtConfigUsed]="sbtConfigUsed"
                     [sbtConfigChanged]="sbtConfigChanged$"
            ></sbt-config-referentiels>
        </div>
        <div *ngIf="sbtConfigUsed?.idSBTConfigUsed > 0" [hidden]="selectedItem?.code !== Onglets.PARAMETRES">
            <sbt-config-parametres
                    [sbtConfigUsed]="sbtConfigUsed"
                    [listeParamConnexion]="listeParamConnexion"
                    [listeParamConnexionUsed]="listeParamConnexionUsed"
                    [listeCustomFieldUsed]="listeCustomFieldUsed"
                    [listeTripFieldUsed]="listeTripFieldUsed"
                    [listeValeurParamConnexion]="listeValeurParamConnexion"
                    [listeCustomField]="listeCustomField"
                    [listeTripField]="listeTripField"
                    [sbtConfigChanged]="sbtConfigChanged$"
            ></sbt-config-parametres>
        </div>
    </ng-container>
</form>
<floating-button *ngIf="selectedItem?.code == Onglets.GENERALITES" [listeActions]="listeActions" [isPending]="isSaving || isLoading"></floating-button>
