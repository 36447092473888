import { Component } from "@angular/core";

/**
 * Composant d'affichage de la liste des référentiels de la gestion des frais
 */
@Component({
    host: {'data-test-id': 'admin-entreprise'},
    selector: 'admin-entreprise',
    templateUrl: './admin-entreprise.component.html'
})
export class AdminEntrepriseComponent {
}