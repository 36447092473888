import {Component,OnInit} from '@angular/core';
import {ChampComplementaire} from "@domain/user/champ-complementaire";
import {UtilisateursParametresService} from "@services/admin/entreprise/utilisateurs/utilisateurs-parametres.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {MatDialog} from "@angular/material/dialog";
import {ChampComplementairePopinComponent} from "@components/admin/entreprise/utilisateurs/utilisateurs-parametres/champs-complementaires/champ-complementaire-popin/champ-complementaire-popin.component";

/**
 * Composant des champs complémentaires utilisateurs
 */
@Component({
	host: {'data-test-id': 'champs-complementaires'},
	selector: 'champs-complementaires',
	templateUrl: './champs-complementaires.component.html'
})
export class ChampsComplementairesComponent implements OnInit {
	/** Champ 1 */
	champ1: ChampComplementaire;

	/** Champ 2 */
	champ2: ChampComplementaire;

	/** Champ 3 */
	champ3: ChampComplementaire;

	/** Champ 4 */
	champ4: ChampComplementaire;

	/**
	 * Constructeur
	 *
	 * @param usersParamsService le service de gestion des paramètres utilisateurs
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private usersParamsService: UtilisateursParametresService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private matDialog: MatDialog
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement des champs complémentaires
		this.loadChampsComplementaires();
	}

	/**
	 * Chargement des champs complémentaires
	 */
	loadChampsComplementaires(): void {
		//Réinitialisation des champs
		delete this.champ1;
		delete this.champ2;
		delete this.champ3;
		delete this.champ4;

		//Puis chargement
		this.usersParamsService.loadChampsComplementaires()
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Récupération des champs
					this.champ1 = result.data.champ1;
					this.champ2 = result.data.champ2;
					this.champ3 = result.data.champ3;
					this.champ4 = result.data.champ4;
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Ouverture de la popin de modification d'un champ complémentaire
	 *
	 * @param champ le champ à ouvrir
	 */
	open(champ: ChampComplementaire): void {
		//Ouverture de la popin
		this.matDialog.open(ChampComplementairePopinComponent,{
			data: {
				champ: champ
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de rafraîchir les champs complémentaires
			if (refresh) {
				//Chargement des champs complémentaires
				this.loadChampsComplementaires();
			}
		});
	}
}
