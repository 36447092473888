import {Component,Input} from '@angular/core';
import {Router} from "@angular/router";
import {SettingsGlobalState} from "@domain/settings/settings";

/**
 * Composant de gestion de la page de profil user
 */
@Component({
    selector: 'profil-user',
    templateUrl: './profil-user.component.html'
})
export class ProfilUserComponent {
    /** Indicateur de chargement */
    isLoading: boolean;
    
    /** Paramétrage */
    @Input()
    settings: SettingsGlobalState;

    /**
     * Constructeur
     *
     * @param router le routeur
     */
    constructor(private router: Router) { }

    /**
     * Ouverture de la page "Données de connexion"
     */
    goToDonneesConnexion() {
        //Navigation vers la page "Données de connexion"
        this.router.navigate(['/Profil/donneesConnexion']);
    }

    /**
     * Ouverture de la page application mobile
     */
    goToMobile() {
        //Navigation vers la page application mobile
        this.router.navigate(['/Profil/mobile']);
    }

    /**
     * Ouverture de la page de préférences analytiques
     */
    goToPrefAnalytiques() {
        //Vérification du paramétrage des préférences analytiques
        if (this.settings.analytiqueUserVisibility) {
            //Navigation vers la page préférences analytiques
            this.router.navigate(['/Profil/prefAnalytique']);
        }
    }

    /**
     * Ouverture de la page de personnalisation
     */
    goToPersonnalisation() {
        //Navigation vers la page préférences analytiques
        this.router.navigate(['/Profil/personnalisation']);
    }
}
