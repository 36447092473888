import {Component,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router} from "@angular/router";
import {filter,finalize,first} from "rxjs/operators";
import {ComptabiliteExercicesPeriodesService} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.service";
import {Exercice} from "@domain/admin/comptabilite/exercice";
import {ListView} from "@domain/common/list-view";
import {Periode} from "@domain/admin/comptabilite/periode";
import {PeriodeListItemComponent} from "@components/admin/comptabilite/exercices-periodes/exercices/exercice-detail-creation/periode-list-item/periode-list-item.component";
import {Page} from "@domain/common/http/list-result";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {NgForm} from "@angular/forms";
import * as moment from "moment";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {MatDialog} from "@angular/material/dialog";
import {PeriodeClotureComponent} from "@components/admin/comptabilite/exercices-periodes/exercices/exercice-detail-creation/popins/periode-cloture/periode-cloture.component";

/**
 * Enum pour les noms des différents onglets de la page Comptabilité/Exercices et Périodes/Exercice
 */
export enum OngletsExercice {
	GENERALITES = 'Generalites',
	PERIODES = 'Periodes'
}

/**
 * Consultation/Création/Modification d'un exercice
 *
 * @author Alexis BOUVARD
 * @date 19/04/2023
 */
@Component({
	host: {'data-test-id': 'exercice-detail-creation'},
	templateUrl: './exercice-detail-creation.component.html'
})
export class ExerciceDetailCreationComponent implements OnInit {
	/** Id de l'exercice courant */
	idExercice: number;

	/** Exercice courant si l'on est en consultation/modification */
	exercice: Exercice = null;

	/** Titre de la page */
	title: string;

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = OngletsExercice;

	/** Liste des différents onglets disponibles dans le menu Comptabilité/Exercices et Périodes/Exercice */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: OngletsExercice.GENERALITES,
			libelle: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.onglets.generalites')
		}
	];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	/** Flag de paramètrage des imputations */
	isProrataObligatoire: boolean = false;

	/** Liste des périodes de l'exercice */
	listePeriodes: ListView<Periode,PeriodeListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Formulaire */
	@ViewChild('form')
	form: NgForm;

	/** Chargement en cours */
	isPending: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param translateService service de gestion de la traduction
	 * @param activatedRoute la route courante
	 * @param comptabiliteExercicesPeriodesService le service de gestion des exercices et périodes
	 * @param router le routeur Angular
	 * @param toastrService le service du toaster
	 * @param confirmService popup de confirmation
	 * @param matDialog le service de gestion des popups
	 */
	constructor(
		private translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		private comptabiliteExercicesPeriodesService: ComptabiliteExercicesPeriodesService,
		private router: Router,
		private toastrService: ToastrService,
		private confirmService: ConfirmService,
		private matDialog: MatDialog
	) {
	}

	/**
	 * On initialise l'écran
	 */
	ngOnInit(): void {
		//récupération de l'ID de l'exercice dans l'URL
		this.activatedRoute.params.pipe(first()).subscribe(params => {
			this.idExercice = params['idExercice'];

			//On va récupérer l'exercice en base
			this.comptabiliteExercicesPeriodesService.loadExercice(this.idExercice)
				.pipe(first())
				.subscribe((result: Result) => {
					//Vérification du result
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Récupération du paramétrage
						this.isProrataObligatoire = result.data.isProrataObligatoire;

						//Si l'on est en consultation/modification
						if (this.idExercice > 0) {
							//On set l'exercice
							this.exercice = result.data.exercice;

							//Définition du flag isLast
							this.exercice.isLast = result.data.isLastExercice;

							//Récupération du titre de la page
							this.title = this.exercice.libelle;

							//On initialise la propriété closed
							this.exercice.closed = !this.exercice.listePeriode.some(p => !p.closed);

							//On rajoute l'onglet "Périodes" visible uniquement en consultation/modification
							this.listeTabItems.push(
								{
									code: OngletsExercice.PERIODES,
									libelle: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.onglets.periodes')
								}
							);

							//Initialisation de la liste des périodes
							this.listePeriodes = new ListView<Periode,PeriodeListItemComponent>({
								title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.title'),
								component: PeriodeListItemComponent,
								isFrontendList: true,
								data: {
									listeResultats: this.exercice.listePeriode,
									numPage: 0,
									nbObjetsParPage: 25,
									nbObjetsDansPage: this.exercice.listePeriode.length,
									nbPagesTotal: 1,
									nbObjetsTotal: this.exercice.listePeriode.length
								} as Page<Periode>,
								extraOptions: {
									exercice: this.exercice
								}
							});
						} else {
							//Définition du titre
							this.title = this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.title');

							//Sinon c'est une création, donc on initialise un exercice vide
							this.initNewExercice();
						}
					} else {
						//Gestion de l'erreur
						TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
					}
				});

			//Ajout de l'action d'enregistrement
			this.listeActions.next([
				{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-sauvegarde',
					libelle: 'global.actions.enregistrer',
					doAction: () => this.saveExercice(),
					isVisible: () => this.selectedItem?.code === this.Onglets.GENERALITES,
					isDisabled: () => this.form?.invalid
				},{
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					doAction: () => this.deleteExercice(),
					isVisible: () => this.selectedItem?.code === this.Onglets.GENERALITES && this.exercice?.isLast
				},{
					type: TypeAction.PRIMARY,
					icone: 'material-icons-outlined',
					iconeName: 'event_available',
					libelle: 'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.cloturer',
					doAction: () => this.cloturerPeriodes(),
					isVisible: () => this.selectedItem?.code === this.Onglets.PERIODES && this.listePeriodes.nbSelectedItems > 0 && this.exercice.listePeriode.filter(p => p.isSelected).every(p => !p.closed)
				},{
					type: TypeAction.PRIMARY,
					icone: 'material-icons-outlined',
					iconeName: 'edit_calendar',
					libelle: 'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.decloturer',
					doAction: () => this.decloturerPeriodes(),
					isVisible: () => this.selectedItem?.code === this.Onglets.PERIODES && this.listePeriodes.nbSelectedItems > 0 && this.exercice.listePeriode.filter(p => p.isSelected).every(p => p.closed)
				}
			]);
		});
	}

	/**
	 * Initialisation du prochain exercice
	 */
	initNewExercice(): void {
		//Récupération de la dernière année de la liste des exercices, ou année en cours
		this.comptabiliteExercicesPeriodesService.getLastExerciceYear().subscribe(year => {
			//Année à créer
			const yearToCreate: number = year + 1;

			//Initialisation de l'exercice
			let exercice: Exercice = new Exercice();

			//Définition de ses propriétés
			exercice.idExercice = 0;
			exercice.libelle = yearToCreate.toString();
			exercice.dateDebut = moment(`${yearToCreate}-01-01`).toDate();
			exercice.dateFin = moment(`${yearToCreate}-12-31`).toDate();
			exercice.nbPeriodes = 12;
			exercice.prorataTaxe = 100;

			this.exercice = exercice;
		});
	}

	/**
	 * Rechargement de l'exercice
	 */
	reloadExercice(): void {
		//Suppression de l'exercice, de la liste des périodes et du paramétrage d'imputation
		this.exercice = null;
		this.listePeriodes = null;
		this.isProrataObligatoire = false;

		//Rechargement de l'exercice
		this.comptabiliteExercicesPeriodesService.loadExercice(this.idExercice)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//On récupère l'exercice chargé
					this.exercice = result.data.exercice;

					//Définition du flag isLast
					this.exercice.isLast = result.data.isLastExercice;

					//Récupération du paramétrage
					this.isProrataObligatoire = result.data.isProrataObligatoire;

					//Récupération du titre de la page
					this.title = this.exercice.libelle;

					//Reconstruction de la liste des périodes
					this.listePeriodes = new ListView<Periode,PeriodeListItemComponent>({
						title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.title'),
						component: PeriodeListItemComponent,
						isFrontendList: true,
						data: {
							listeResultats: this.exercice.listePeriode,
							numPage: 0,
							nbObjetsParPage: 25,
							nbObjetsDansPage: this.exercice.listePeriode.length,
							nbPagesTotal: 1,
							nbObjetsTotal: this.exercice.listePeriode.length
						} as Page<Periode>,
						extraOptions: {
							exercice: this.exercice
						}
					});
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Sauvegarde de l'exercice
	 */
	saveExercice(): void {
		//Début de l'enregistrement
		this.isPending = true;

		//Appel au service
		this.comptabiliteExercicesPeriodesService.saveExercice(this.exercice)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//On regarde si l'enregistrement s'est bien déroulé
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Toast success
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Retour à la liste
					this.onGoBack();
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result?.codeErreur ?? TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de l'exercice
	 */
	deleteExercice(): void {
		//Début de la suppression
		this.isPending = true;

		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Suppression de l'exercice
				this.comptabiliteExercicesPeriodesService.deleteExercice(this.exercice.idExercice)
					.pipe(first(),finalize(() => this.isPending = false))
					.subscribe((result: Result) => {
						//Vérification de la suppression
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast success
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Retour à la liste
							this.onGoBack();
						} else {
							//Message d'erreur
							TypeCodeErreur.showError(result?.codeErreur ?? TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
						}
					});
			},
			complete: () => this.isPending = false
		});
	}

	/**
	 * Fonction de clôture des périodes
	 */
	cloturerPeriodes(): void {
		//Début du traitement
		this.isPending = true;

		//Récupération des ID des périodes sélectionnées
		const listeIdPeriodes: number[] = this.exercice.listePeriode
			.filter(p => p.isSelected)
			.map(p => p.idPeriode);

		//Si l'une des périodes sélectionnées a des objets à traiter
		if (this.exercice.listePeriode.filter(p => p.isSelected).some(p => p.nbObjetsOpened > 0)) {
			//Ouverture de la popup de clôture
			this.matDialog.open(PeriodeClotureComponent,{
				data: this.exercice.listePeriode.filter(p => p.isSelected)
			}).afterClosed().subscribe((idPeriodeCible: number) => {
				if (idPeriodeCible) {
					//Appel au service de clôture
					this.comptabiliteExercicesPeriodesService.doCloturePeriodes(this.idExercice,listeIdPeriodes,true,idPeriodeCible)
						.pipe(first(),finalize(() => this.isPending = false))
						.subscribe((result: Result) => {
							//Vérification du traitement
							if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast success
								this.toastrService.success(this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.toast.clotureSuccess.' + (listeIdPeriodes.length > 1 ? 'plural' : 'one'),{count: listeIdPeriodes.length}));

								//Rechargement de l'exercice
								this.reloadExercice();
							} else {
								//Message d'erreur
								TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
							}
						});
				} else {
					//Fin du chargement
					this.isPending = false;
				}
			});
		} else {
			//Appel au service de clôture
			this.comptabiliteExercicesPeriodesService.doCloturePeriodes(this.idExercice,listeIdPeriodes,true,0)
				.pipe(first(),finalize(() => this.isPending = false))
				.subscribe((result: Result) => {
					//Vérification du traitement
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Toast success
						this.toastrService.success(this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.toast.clotureSuccess.' + (listeIdPeriodes.length > 1 ? 'plural' : 'one'),{count: listeIdPeriodes.length}));

						//Rechargement de l'exercice
						this.reloadExercice();
					} else {
						//Message d'erreur
						TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
					}
				});
		}
	}

	/**
	 * Fonction de déclôture des périodes
	 */
	decloturerPeriodes(): void {
		//Début du traitement
		this.isPending = true;

		//Récupération des ID des périodes sélectionnées
		const listeIdPeriodes: number[] = this.exercice.listePeriode
			.filter(p => p.isSelected)
			.map(p => p.idPeriode);

		//Appel au service de clôture
		this.comptabiliteExercicesPeriodesService.doCloturePeriodes(this.idExercice,listeIdPeriodes,false,0)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du traitement
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast success
					this.toastrService.success(this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.toast.declotureSuccess.' + (listeIdPeriodes.length > 1 ? 'plural' : 'one'),{count: listeIdPeriodes.length}));

					//Rechargement de l'exercice
					this.reloadExercice();
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour à la page précédente
	 */
	onGoBack(): void {
		this.router.navigate(['Admin/Comptabilite/ExercicesPeriodes/Exercices']);
	}
}
