<h1 mat-dialog-title>
    <span [translate]="'global.liste.parametrage.sortPopup.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #delegForm="ngForm" (ngSubmit)="onFormSubmit()">
        <fieldset>
            <div class="row">
                <!-- Première clé -->
                <label class="col-md-2">
                    <span [translate]="'global.liste.parametrage.sortPopup.key1'"></span>
                </label>
                <div class="col-md-6">
                    <mat-form-field class="d-flex">
                        <mat-select name="cle1" #cle1="ngModel" [(ngModel)]="data.sorting.key1.colonne">
                            <mat-option *ngFor="let colonne of data.sorting.getSelectList('key1')" [value]="colonne">{{ colonne.title | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <button mat-stroked-button color="primary" type="button" (click)="data.sorting.key1.toggle()" [matTooltip]="('global.liste.parametrage.sortPopup.' + (data.sorting.key1.tri == 'ASC' ? 'asc' : 'desc')) | translate" style="margin-top:10px;">
                        <mat-icon *ngIf="data.sorting.key1.tri == 'ASC'">arrow_upward</mat-icon>
                        <mat-icon *ngIf="data.sorting.key1.tri == 'DESC'">arrow_downward</mat-icon>
                    </button>
                </div>
            </div>
            <div class="row">
                <!-- Deuxième clé -->
                <label class="col-md-2">
                    <span [translate]="'global.liste.parametrage.sortPopup.key2'"></span>
                </label>
                <div class="col-md-6">
                    <mat-form-field class="d-flex">
                        <mat-select name="cle2" #cle2="ngModel" [(ngModel)]="data.sorting.key2.colonne">
                            <mat-option *ngIf="!data.sorting.key3.colonne" [value]="null">{{ 'global.liste.parametrage.sortPopup.emptySelect' | translate }}</mat-option>
                            <mat-option *ngFor="let colonne of data.sorting.getSelectList('key2')" [value]="colonne">{{ colonne.title | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <button mat-stroked-button color="primary" type="button" (click)="data.sorting.key2.toggle()" [matTooltip]="('global.liste.parametrage.sortPopup.' + (data.sorting.key2.tri == 'ASC' ? 'asc' : 'desc')) | translate" [disabled]="!data.sorting.key2.colonne" style="margin-top:10px;">
                        <mat-icon *ngIf="data.sorting.key2.tri == 'ASC'">arrow_upward</mat-icon>
                        <mat-icon *ngIf="data.sorting.key2.tri == 'DESC'">arrow_downward</mat-icon>
                    </button>
                </div>
            </div>
            <div class="row">
                <!-- Troisième clé -->
                <label class="col-md-2">
                    <span [translate]="'global.liste.parametrage.sortPopup.key3'"></span>
                </label>
                <div class="col-md-6">
                    <mat-form-field class="d-flex">
                        <mat-select name="cle3" #cle3="ngModel" [(ngModel)]="data.sorting.key3.colonne" [disabled]="!data.sorting.key2.colonne">
                            <mat-option [value]="null">{{ 'global.liste.parametrage.sortPopup.emptySelect' | translate }}</mat-option>
                            <mat-option *ngFor="let colonne of data.sorting.getSelectList('key3')" [value]="colonne">{{ colonne.title | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <button mat-stroked-button color="primary" type="button" (click)="data.sorting.key3.toggle()" [matTooltip]="('global.liste.parametrage.sortPopup.' + (data.sorting.key3.tri == 'ASC' ? 'asc' : 'desc')) | translate" [disabled]="!data.sorting.key2.colonne || !data.sorting.key3.colonne" style="margin-top:10px;">
                        <mat-icon *ngIf="data.sorting.key3.tri == 'ASC'">arrow_upward</mat-icon>
                        <mat-icon *ngIf="data.sorting.key3.tri == 'DESC'">arrow_downward</mat-icon>
                    </button>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" type="submit" (click)="onReset()">
        <span [translate]="'global.liste.parametrage.sortPopup.reset'"></span>
    </button>
    <button mat-flat-button color="primary" type="submit" (click)="onFormSubmit()">
        <span [translate]="'global.liste.parametrage.sortPopup.confirm'"></span>
    </button>
</div>