import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ZonePredefinie} from "@domain/zone-utilisateur/zone-predefinie";
import {NgForm} from "@angular/forms";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant de la popin d'édition d'une zone utilisateur prédéfinie
 */
@Component({
	host: {'data-test-id': 'zone-predefinie-edit-popin'},
	templateUrl: './zone-predefinie-edit-popin.component.html',
	styleUrls: ['./zone-predefinie-edit-popin.component.scss']
})
export class ZonePredefinieEditPopinComponent implements OnInit {
	/** Zone prédéfinie */
	zone: ZonePredefinie;

	/** Formulaire du dom */
	@ViewChild('form')
	form: NgForm;

	/** Regexp du champ masque */
	masqueRegex: RegExp = /^[Z9*?]+$/;

	/** Traitement en cours */
	isPending: boolean;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param zonesUtilisateursService le service de gestion des zones utilisateurs
	 * @param toastrService le toaster
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la ref à cette popin
	 * @param confirmService gestion de la confirmation
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ZonePredefinie,
		private zonesUtilisateursService: ZonesUtilisateursService,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<ZonePredefinieEditPopinComponent>,
		private confirmService: ConfirmService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de la zone en entrée de la popin
		this.zone = Object.assign({},this.data);

		if (!this.zone.actif) {
			this.zone.actif = true;
		}

		if (this.zone.fixe) {
			this.zone.libelle = this.zone.champ;
		}
	}

	/**
	 * Analyse des changements de masque
	 *
	 * @param event l'évènement de changement de masque
	 */
	onMasqueChange(event: InputEvent) {
		//Si lors d'une insertion, on détecte un caractère autre que z, Z, *, 9 ou ?
		if (event.inputType === "insertText" && !['z','Z','*','9','?'].includes(event.data)) {
			//Détermination de l'index de ce caractère
			const idx: number = this.zone.masque.indexOf(event.data);

			//On toaste le caractère non autorisé
			this.toastrService.error(this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.caractereNonAutorise',{char: event.data}));

			//Suppression de celui-ci
			this.zone.masque = this.zone.masque.substring(0,idx) + this.zone.masque.substring(idx + 1);
		}

		//Mise en majuscule pour supporter le z minuscule
		this.zone.masque = this.zone.masque.toUpperCase();
	}

	/**
	 * Désactivation de la zone prédéfinie
	 */
	onDisable(): void {
		//Confirmation de désactivation
		this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.confirmationDesactivation'))
			.pipe(filter(isConfirm => isConfirm))
			.subscribe(() => {
				//Chargement en cours
				this.isPending = true;

				//Réinitialisation de tous les champs
				delete this.zone.libelle;
				this.zone.actif = false;
				delete this.zone.masque;
				this.zone.obligatoire = false;
				delete this.zone.tailleMinimale;

				//Enregistrement
				this.onSave();
			});
	}

	/**
	 * Enregistrement de la zone prédéfinie
	 */
	onSave(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.zonesUtilisateursService.saveZonePredefinie(this.zone)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popin
					this.matDialogRef.close(true);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}
