import {NgModule} from '@angular/core';
import {MatDividerModule} from "@angular/material/divider";
import {ComptableComponent} from "@components/admin/entreprise/description/comptable/comptable.component";
import {DescriptionGeneralitesComponent} from "@components/admin/entreprise/description/generalites/description-generalites.component";
import {ImportLicenceComponent} from "@components/admin/entreprise/description/licence/import/import-licence.component";
import {LicenceUploaderComponent} from "@components/admin/entreprise/description/licence/import/licence-uploader.component";
import {LicenceListModuleItemComponent} from "@components/admin/entreprise/description/licence/licence-list-module-item.component";
import {LicenceListPluginItemComponent} from "@components/admin/entreprise/description/licence/licence-list-plugin-item.component";
import {LicenceComponent} from "@components/admin/entreprise/description/licence/licence.component";
import {RgpdComponent} from "@components/admin/entreprise/description/rgpd/gestion/rgpd.component";
import {ProfilsRestreintsUserItemComponent} from "@components/admin/entreprise/description/rgpd/profils/profils-restreints-user-item.component";
import {ProfilsRestreintsComponent} from "@components/admin/entreprise/description/rgpd/profils/profils-restreints.component";
import {XmlUploaderModule} from "@components/admin/entreprise/licence/xml-uploader.module";
import {DocumentModule} from "@components/document/document.module";
import {LieuModule} from "@components/lieu/lieu.module";
import {ProfilModule} from "@components/profil/profil.module";
import {ProfilService} from "@components/profil/profil.service";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";

export const entrepriseDescriptionRoutes : Routes = [
    {
        path: 'Generalites',
        component: DescriptionGeneralitesComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Licence',
        component: LicenceComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_LICENCE] }
    }, {
        path: 'Rgpd',
        component: ProfilsRestreintsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_RGPD_GESTION] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Licence'
    }, {
        path: '**',
        redirectTo: 'Licence'
    }
];

@NgModule({
    imports: [ShareModule, LieuModule, ProfilModule, DocumentModule, XmlUploaderModule, PreviewAlerteModule, MatDividerModule],
    declarations: [
        DescriptionGeneralitesComponent,
        ComptableComponent,
        LicenceComponent,
        LicenceListModuleItemComponent,
        LicenceListPluginItemComponent,
        ImportLicenceComponent,
        LicenceUploaderComponent,
        RgpdComponent,
        ProfilsRestreintsComponent,
        ProfilsRestreintsUserItemComponent
    ],
    entryComponents: [],
    exports: [
        DescriptionGeneralitesComponent,
        ComptableComponent,
        ProfilModule,
        LicenceComponent,
        ImportLicenceComponent,
        LicenceUploaderComponent,
        RgpdComponent,
        ProfilsRestreintsComponent,
        ProfilsRestreintsUserItemComponent
    ],
    providers: [ProfilService, LoginService]
})
export class DescriptionModule {
}
