import { EngagementsFraisItem } from "./engagements-frais-item";

/**
 * Engagements - Liste des frais d'un OD
 */
export class EngagementsFrais {
    /** Liste des engagements */
    listeEngagement: Array<EngagementsFraisItem>;

    /** Ligne de total */
    total: EngagementsFraisItem;

    /**
     * Constructeur
     *
     * @param engagementsFrais Données
     */
    constructor(engagementsFrais: EngagementsFrais) {
        //Vérification de la présence de données
        if (engagementsFrais) {
            //Lignes des engagements
            this.listeEngagement = engagementsFrais.listeEngagement?.map(engagement => {
                //Instanciation à partir des données
                return new EngagementsFraisItem(engagement);
            });

            //Instanciation de la ligne de total à partir des données
            this.total = new EngagementsFraisItem(engagementsFrais.total,true);
        }
    }

}