import {Session} from '../domain/security/session';
import {Action} from '../domain/action';

/* Export des actions */

export const SESSION_FULFILLED = 'SESSION_FULFILLED';
/** Action déclenchée lorsqu'une requête vers le backend revient en 401  */
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
/** Action pour demander la réinitialisation de la session front */
export const SESSION_RESET = 'SESSION_RESET';
/** Action pour réinitialiser le redirect de la session */
export const SESSION_RESET_REDIRECT = 'SESSION_RESET_REDIRECT';
/** Action de mise à jour de l'utilisateur */
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LOGO_URL = 'UPDATE_LOGO_URL';
/** Action de changement de profil */
export const CHANGE_PROFIL = 'CHANGE_PROFIL';
/** Action lorsque le profil a fini de changer */
export const PROFIL_CHANGED = 'PROFIL_CHANGED';
/** Action de vérification de l'approbation des CGU */
export const CHECK_CGU_VALIDATION = 'CHECK_CGU_VALIDATION';
/**  */
export const INIT_SESSION_ADMIN = 'INIT_SESSION_ADMIN';

/**
 * Création du reducer
 */
export function sessionReducer(state: Session = new Session(),action: Action<any>): Session {
    //Vérification de l'action
    switch (action.type) {
        case INIT_SESSION_ADMIN:
        case SESSION_FULFILLED:
            //Session de l'utilisateur
            state = {
                ...state,
                ...action.payload
            };
            break;
        case SESSION_RESET:
            state = {
                ...new Session(),
                ...action.payload
            }
            break;
        case SESSION_RESET_REDIRECT:
            state.redirect = null;
            break;
        case UPDATE_USER:
            //Mise à jour de l'utilisateur
            state.user = action.payload;
            break;
        case UPDATE_LOGO_URL:
            //Mise à jour de l'URL du logo
            state.logoUrl = action.payload;
            break;
    }

    //Retour de l'état
    return state;
}
