/**
 * Enumération des types de facture prévisionnelle
 *
 * @author Laurent Convert
 * @date 18/01/2022
 */
export enum TypeFactPrev {
    FACTURE = 1,
    AVOIR = 2,
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace TypeFactPrev {

    /**
     * Retourne toutes les valeurs de l'énuméré
     */
    export function values(): TypeFactPrev[] {
        return [
            TypeFactPrev.FACTURE,
            TypeFactPrev.AVOIR
        ]
    }

    /**
     * Retourne le code de traduction qui correspondant au type
     *
     * @param typeFactPrev Type
     */
    export function traduction(typeFactPrev: TypeFactPrev): string {
        switch (typeFactPrev) {
            case TypeFactPrev.AVOIR: return 'od.frais.factures.types.avoir';
            case TypeFactPrev.FACTURE: return 'od.frais.factures.types.facture';
        }
    }
}