import {NgModule} from '@angular/core';

import {StepperTourneeComponent} from "./stepper-tournee/stepper-tournee.component";
import {StepperTourneeItemComponent} from "./stepper-tournee/stepper-tournee-item/stepper-tournee-item.component";
import {ShareModule} from "../../../../../../../share/share.module";
import {MatStepperModule} from "@angular/material/stepper";
import {TourneeService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/tournee.service";
import {NameDialogComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/name-dialog/name-dialog.component";
import {ValidatorModule} from "@share/directive/validator/validator.module";

@NgModule({
    imports: [
        ShareModule,
        MatStepperModule,
        ValidatorModule
    ],
    exports: [
        StepperTourneeComponent
    ],
    declarations: [
        StepperTourneeComponent,
        StepperTourneeItemComponent,
        NameDialogComponent
    ],
    providers: [
        TourneeService
    ],
})
export class TourneeModule {
}
