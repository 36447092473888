import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Modèle d'un contrat pour un utilisateur
 */
export class UserContrat implements ListItem {
	idContrat: number;
	idUser: number;
	libelle: string;
	reference: string;
	franchiseAller: number;
	franchiseAllerRetour: number;
	dateDebut: Date;
	dateFin: Date;

	getKey(): number {
		return this.idContrat;
	}
}