import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Statut} from "@domain/workflow/statut";
import {StatutListItemComponent} from "@components/admin/workflow/conditions/alertes/detail/select-statuts-popin/statut-list-item/statut-list-item.component";
import {Condition} from "@domain/workflow/condition";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Page} from "@domain/common/http/list-result";

/**
 * Popin de sélections des statuts
 */
@Component({
	host: {'data-test-id': 'select-statuts-popin'},
	templateUrl: './select-statuts-popin.component.html'
})
export class SelectStatutsPopinComponent implements OnInit {
	/** Liste des statuts */
	listeStatuts: ListView<Statut,StatutListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { alerte: Condition,listeStatuts: Statut[] },
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		/** Initialisation de la liste */
		this.listeStatuts = new ListView<Statut,StatutListItemComponent>({
			title: this.translateService.instant('workflow.conditions.alertes.detail.alerte.selectStatutsPopin.listTitle'),
			component: StatutListItemComponent,
			isFrontendList: true,
			data: {
				listeResultats: this.data.listeStatuts,
				numPage: 0,
				nbPagesTotal: 1,
				nbObjetsTotal: this.data.listeStatuts.length,
				nbObjetsParPage: this.data.listeStatuts.length,
				nbObjetsDansPage: this.data.listeStatuts.length
			} as Page<Statut>,
			extraOptions: {
				alerte: this.data.alerte
			},
			listeActions: [{
				icon: 'done_all',
				onPress: () => this.listeStatuts.selectAll(true),
				tooltip: this.translateService.instant('global.actions.toutSelectionner'),
				isVisible: () => !this.listeStatuts?.data.listeResultats.every(s => s.isSelected)
			},{
				icon: 'remove_done',
				onPress: () => this.listeStatuts.selectAll(false),
				tooltip: this.translateService.instant('global.actions.toutDeselectionner'),
				isVisible: () => this.listeStatuts?.data.listeResultats.every(s => s.isSelected)
			}]
		});
	}
}
