<div *ngIf="codeProjet">
    <page-header
            [title]="(isCreation ? 'admin.entreprise.analytique.codesProjet.infos.title' : 'admin.entreprise.analytique.codesProjet.infos.titleModification') | translate : { reference: codeProjet.reference }"
            [listeItems]="listeTabItems"
            (onSelectedItemChange)="onSelectedItemChange($event)"
            (onGoBack)="onGoBack()"
            [isPending]="isSaving">
    </page-header>

    <form #form="ngForm">
        <div [hidden]="selectedItem?.code !== Onglet.GENERALITES">
            <mat-card>
                <mat-card-title>
                    <span [translate]="isCreation ? 'admin.entreprise.analytique.codesProjet.infos.ajoutCodeProjet' : 'admin.entreprise.analytique.codesProjet.infos.title'"></span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="libelle"
                                            name="libelle"
                                            [(ngModel)]="codeProjet.libelle"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.libelle"
                                            [required]="true"></custom-input>
                            <custom-input ngDefaultControl
                                            id="isActif"
                                            name="isActif"
                                            [ngModel]="codeProjet.isActif"
                                            [(customModel)]="codeProjet.isActif"
                                            customType="checkbox"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.actif"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="reference"
                                            name="reference"
                                            [(ngModel)]="codeProjet.reference"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.reference"
                                            [required]="true"></custom-input>
                            <custom-input ngDefaultControl
                                            id="dateDebut"
                                            name="dateDebut"
                                            [ngModel]="codeProjet.dateDebut"
                                            [(customModel)]="codeProjet.dateDebut"
                                            customType="date"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.dateDebut"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="codeParent"
                                            name="codeParent"
                                            [(ngModel)]="codeProjet.parent"
                                            customType="autocomplete"
                                            rCol="2"
                                            optionValue="idDossier"
                                            optionDisplay="reference"
                                            autocompleteType="dossier"
                                            [autocompleteFilter]="{ reference: codeProjet.reference }"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.codeParent"></custom-input>
                            <custom-input ngDefaultControl
                                            id="dateFin"
                                            name="dateFin"
                                            [ngModel]="codeProjet.dateFin"
                                            [(customModel)]="codeProjet.dateFin"
                                            (ngModelChange)="checkEndAfterStart($event)"
                                            customType="date"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.dateFin"></custom-input>
                            <mat-error *ngIf="!isDateFinValid()" [ngStyle]="{ 'padding-top': '15px'}">
                                <span translate>admin.entreprise.analytique.codesProjet.infos.erreurDates</span>
                            </mat-error>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="code1"
                                            name="code1"
                                            [(ngModel)]="codeProjet.code1"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.code1"></custom-input>
                            <custom-input ngDefaultControl
                                            id="code2"
                                            name="code2"
                                            [(ngModel)]="codeProjet.code2"
                                            customType="input"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.codesProjet.infos.code2"></custom-input>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.entreprise.analytique.codesProjet.infos.filtres'"></span>
                    <span class="actions clickable">
                        <mat-icon (click)="addFiltre()">add</mat-icon>
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <div class="listview">
                        <div class="listview-body">
                            <div class="listview-item" *ngFor="let filtre of listeFiltre">
                                <div class="lvi-content">
                                    <div class="avatar" [ngSwitch]="filtre.type">
                                        <span *ngSwitchCase="5">{{ filtreTypes.MC }}</span>
                                        <span *ngSwitchCase="6">{{ filtreTypes.TE }}</span>
                                        <span *ngSwitchCase="7">{{ filtreTypes.CA }}</span>
                                        <span *ngSwitchDefault>{{ filtreTypes.SE }}</span>
                                    </div>
                                    <div class="lvi-body">
                                        <div class="title">
                                            <a class="label">{{ filtre.libelle }}</a>
                                        </div>
                                        <ul class="lvi-attrs">
                                            <li [ngSwitch]="filtre.type">
                                                <strong [translate]="'admin.entreprise.analytique.codesProjet.infos.type'"></strong>
                                                <span *ngSwitchCase="5" [translate]="'admin.entreprise.analytique.codesProjet.infos.filtre.mc'"></span>
                                                <span *ngSwitchCase="6" [translate]="'admin.entreprise.analytique.codesProjet.infos.filtre.te'"></span>
                                                <span *ngSwitchCase="7" [translate]="'admin.entreprise.analytique.codesProjet.infos.filtre.ca'"></span>
                                                <span *ngSwitchDefault [translate]="'admin.entreprise.analytique.codesProjet.infos.filtre.se'"></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="lvi-actions">
                                        <button mat-icon-button [matMenuTriggerFor]="listeActions">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #listeActions="matMenu">
                                            <button mat-menu-item (click)="deleteFiltre(filtre)" translate="global.actions.supprimer"></button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>

<floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>