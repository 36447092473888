import {Component, OnInit} from '@angular/core';
import {ReportingService} from "./reporting.service";
import {first} from "rxjs/operators";
import {Report} from "@domain/reporting/report";

@Component({
    selector: 'reporting-list',
    templateUrl: './reporting-list.component.html'
})
export class ReportingListComponent implements OnInit {

    /* Données */
    listeReporting: Report[];

    /**
     * Constructeur
     */
    constructor(private reportingService: ReportingService) {
    }

    /**
     * Initialisation
     */
    async ngOnInit() {
        //Chargement de la liste des rapports
        this.reportingService.loadListe().pipe(first()).subscribe({
            next: response => {
                //Mise à jour de la liste des rapports
                this.listeReporting = response;
            }
        });
    }

    /**
     * Affichage de la modal de paramétrage du rapport / Execution du rapport
     * @param idReport identifiant du rapport
     */
    executeReport(idReport: number) {
        //Exécution
        this.reportingService.executeReportById(idReport);
    }
}
