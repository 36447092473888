import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

/**
 * Composant qui permet d'afficher la popin pour renommer le trajet
 */
@Component({
    host: {'data-test-id': 'trajet-renommer'},
    selector: 'trajet-renommer',
    templateUrl: 'trajet-renommer.component.html'
})
export class TrajetRenommerComponent{
    /** Libellé saisi par l'user */
    libelle: String;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { libelle?: string }) {}
}