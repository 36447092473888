import {Component,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {FraisAgence} from "@domain/admin/voyages/frais-agence";
import {FraisAgenceListItemComponent} from "@components/admin/voyages/frais-agence/frais-agence-list/list-item/frais-agence-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Result} from "@domain/common/http/result";

/**
 * Composant de la liste des frais d'agence
 */
@Component({
	host: {'data-test-id': 'frais-agence-list'},
	selector: 'frais-agence-list',
	templateUrl: './frais-agence-list.component.html'
})
export class FraisAgenceListComponent implements OnInit {
	/** Liste des frais d'agence */
	listeFraisAgence: ListView<FraisAgence,FraisAgenceListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des frais d'agence
		this.listeFraisAgence = new ListView<FraisAgence,FraisAgenceListItemComponent>({
			uri: '/controller/FraisAgence/listeFrais',
			title: this.translateService.instant('admin.voyages.fraisAgence.fraisAgenceTab.liste.title'),
			component: FraisAgenceListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => {
				let listeFraisAgence: FraisAgence[] = result?.data?.fraisAgence;

				//On parcourt les frais d'agence pour leur valoriser les libellés de transaction et de type
				listeFraisAgence.forEach((fraisAgence: FraisAgence) => {
					fraisAgence.libelleTransaction = this.translateService.instant(`admin.voyages.fraisAgence.fraisAgenceTab.mapLibelleTransaction.${fraisAgence.typeFrais}`);
					fraisAgence.libelleType = this.translateService.instant(`admin.voyages.fraisAgence.fraisAgenceTab.mapLibelleType.${fraisAgence.typeFrais}`);
				});

				return listeFraisAgence;
			}
		});
	}
}
