<ng-template #alerteTemplate>
    <div class="d-flex flex-row">
        <mat-icon *ngIf="withIcon === true">error_outline</mat-icon>
        <mat-icon *ngIf="withIcon && withIcon !== true">{{ withIcon }}</mat-icon>
        <mat-icon *ngIf="withAccueilIcon">help_outline</mat-icon>
        <mat-icon *ngIf="withRGPDIcon">policy_outline</mat-icon>
        <mat-icon *ngIf="alerte?.icon != null" [ngClass]="{ 'large-icon': isLargeIcon }">{{ alerte.icon }}</mat-icon>
        <div [ngClass]="{ 'p-l-20': withIcon || withRGPDIcon || withAccueilIcon || alerte?.icon != null}">
            <!-- Le titre de l'alerte AVEC action au click -->
            <a *ngIf="titre && alerte?.action" [routerLink]="" class="clickable" (click)="alerte.action()"><strong>{{ titre }}</strong></a>
            <!-- Le titre de l'alerte SANS action au click -->
            <strong *ngIf="titre && !alerte?.action">{{ titre }}</strong>

            <!-- Le message de l'alerte TEXTE -->
            <p *ngIf="message != null && !hasHtml()">{{ message }}</p>
            <!-- Le message de l'alerte HTML -->
            <p *ngIf="message != null && hasHtml()" [innerHTML]="message"></p>
            <!-- Le message de l'alerte via un TEMPLATE (permet per exemple de brancher une action sur un bout de texte) -->
            <ng-container *ngIf="alerte?.template?.tpl" [ngTemplateOutlet]="alerte.template.tpl" [ngTemplateOutletContext]="{ctx: alerte.template.ctx}"></ng-container>
        </div>
    </div>
</ng-template>
