<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="module.idPortee == TypePortee.OD" class="nio icon-ordre_de_mission"></i>
        <i *ngIf="module.idPortee == TypePortee.NF" class="nio icon-frais"></i>
        <i *ngIf="module.idPortee == TypePortee.FC" class="nio icon-releve_de_facture"></i>
    <i *ngIf="module.idPortee == TypePortee.AV" class="nio icon-avance_2"></i>
    <i *ngIf="module.idPortee == TypePortee.OT" class="nio icon-ordre_de_mission_permanent"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
      <a class="label">{{ getNameToTranslate(module.idPortee) | translate }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.description.licence.model.actif'"></strong>
                <span>{{ (module.isActif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <!-- Pas affiché pour les portées AV et OT : temporaire en attendant la mise à jour de l'outil de génération des licences  -->
            <li *ngIf="module.idPortee !== TypePortee.AV && module.idPortee !== TypePortee.OT">
                <strong [translate]="'admin.entreprise.description.licence.model.controle'"></strong>
                <span>{{ (module.isControle ? 'global.oui' : 'global.non') | translate }}</span>
            </li>

            <ng-container *ngIf="module.isControle">
                <li *ngIf="module.dateDebut">
                    <strong [translate]="'admin.entreprise.description.licence.model.dateDebut'"></strong>
                    <span>{{ module.dateDebut | date: 'shortDate' }}</span>
                </li>
                <!-- Pour AV et OT : pas de max défini, donc ne s'affichera pas. Temporaire en attendant la mise à jour de l'outil de génération des licences  -->
                <li *ngIf="module.nbProfilsActifs" [ngClass]="{'back-success':module.pourcentageConsommePA < 80, 'back-warning':module.pourcentageConsommePA >= 80 && module.pourcentageConsommePA < 100, 'back-danger':module.pourcentageConsommePA >= 100 }">
                    <strong [translate]="'admin.entreprise.description.licence.model.profilActif'"></strong>
                    <span>{{ module.consommationPA ? module.consommationPA : 0 }} / {{ module.nbProfilsActifs ? module.nbProfilsActifs : 0 }} ({{ module.pourcentageConsommePA ? module.pourcentageConsommePA : 0 }} %)</span>
                </li>
                <!-- Pour AV et OT : pas de max défini, donc ne s'affichera pas. Temporaire en attendant la mise à jour de l'outil de génération des licences  -->
                <li *ngIf="module.volume" [ngClass]="{'back-success':module.pourcentageConsommeVolume < 80, 'back-warning':module.pourcentageConsommeVolume >= 80 && module.pourcentageConsommeVolume < 100, 'back-danger':module.pourcentageConsommeVolume >= 100 }">
                    <strong [translate]="'admin.entreprise.description.licence.model.volume'"></strong>
                    <span>{{ module.consommationVolume ? module.consommationVolume : 0 }} / {{ module.volume }} ({{ module.pourcentageConsommeVolume ? module.pourcentageConsommeVolume : 0 }} %)</span>
                </li>
                <!-- Pour AV et OT : temporaire en attendant la mise à jour de l'outil de génération des licences  -->
                <li *ngIf="TypePortee.AV === module.idPortee || TypePortee.OT === module.idPortee" [nioTooltip]="'admin.entreprise.description.licence.model.tooltip_OT' | translate" [nioTooltipDisabled]="TypePortee.AV === module.idPortee">
                    <strong [translate]="'admin.entreprise.description.licence.model.profilActif'"></strong>
                    <span>{{ module.consommationPA }}</span>
                </li>
                <!-- Pour AV et OT : temporaire en attendant la mise à jour de l'outil de génération des licences  -->
                <li *ngIf="TypePortee.AV === module.idPortee || TypePortee.OT === module.idPortee" [nioTooltip]="'admin.entreprise.description.licence.model.tooltip_OT' | translate" [nioTooltipDisabled]="TypePortee.AV === module.idPortee">
                    <strong [translate]="'admin.entreprise.description.licence.model.volume'"></strong>
                    <span>{{ module.consommationVolume }}</span>
                </li>
                <!-- Pas affiché pour les portées AV et OT : temporaire en attendant la mise à jour de l'outil de génération des licences  -->
                <li *ngIf="module.frequence && module.idPortee !== TypePortee.AV && module.idPortee !== TypePortee.OT" >
                    <strong [translate]="'admin.entreprise.description.licence.model.frequence'"></strong>
                    <span>{{ module.frequence }}</span>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
