import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {ListItem} from "@domain/common/list-view/list-item";
import {AbstractReferentiel} from "@domain/travel/abstract-referentiel";

/**
 * Représente un RLS d'une configuration SBT
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
export class Rls implements ListItem,AbstractReferentiel {
	idRLS: number;
	libelle: string;
	sbtConfigUsed: TravelHubSBTConfigUsed;
	valeur: string;
	defaut: boolean = false;
	used: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	constructor(dto?: any | Rls) {
		if (dto) {
			Object.assign(this,dto);

			if (dto.sbtConfigUsed) {
				this.sbtConfigUsed = new TravelHubSBTConfigUsed(dto.sbtConfigUsed);
			}
		}
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): number {
		return this.idRLS;
	}
}