<mat-card>
    <mat-card-title>
        <span [ngClass]="{ 'warning': vehicule.alertes.isWarning(),'danger': vehicule.alertes.isDanger() }">
            <mat-icon class="m-r-10" *ngIf="vehicule.alertes.isWarning()" [nioTooltip]="'vehicule.liste.alerte.documentManquant' | translate">error_outline</mat-icon>
            <span [translate]="'vehicule.formulaire.documents.label'"></span>
        </span>
        <span *ngIf="canGestionDocuments" class="actions">
            <mat-icon (click)="openAddDocument()">add</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div *ngFor="let carteGrise of vehicule.listeCarteGrise;" class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar" [translate]="'vehicule.avatar.carteGrise'"></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="openDocument(carteGrise.idVehiculeCarteGrise, TypeDocument.CARTE_GRISE)" [translate]="'vehicule.formulaire.documents.carteGrise.title'" [translateParams]="{ immatriculation: vehicule.immatriculation}"></a>
                            </div>
                            <ul class="lvi-attrs">
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.actif'"></strong>
                                    <span *ngIf="carteGrise"
                                          [translate]="isCarteGriseActive(carteGrise.dateImmatriculation) ? 'global.oui' : 'global.non'"></span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.piecesJointes'"></strong>
                                    <span *ngIf="carteGrise.nbPJ == 0" [translate]="'vehicule.formulaire.aucunePJ'"></span><span *ngIf="carteGrise.nbPJ > 0">{{carteGrise.nbPJ}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngFor="let assurance of vehicule.listeAssurance;" class="listview-item">
                    <div class="lvi-content">
                        <div [ngClass]="{ 'warning': isAlerteEcheance(assurance),'danger': isAlerteEchu(assurance) }" [translate]="'vehicule.avatar.assurance'" class="avatar"></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="openDocument(assurance.id, TypeDocument.ASSURANCE)" [translate]="'vehicule.formulaire.documents.assurance'" [translateParams]="{ numContrat: assurance.numContrat}"></a>
                            </div>
                            <ul class="lvi-attrs">
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.dateDeb'"></strong>
                                    <span>{{ assurance.dateDebut | date: 'shortDate' }}</span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.dateFin'"></strong>
                                    <span>{{assurance.dateFin | date: 'shortDate' }}</span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.actif'"></strong>
                                    <span [translate]=" isDocumentActif(assurance) ? 'global.oui': 'global.non'"></span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.piecesJointes'"></strong>
                                    <span *ngIf="assurance.nbPJ == 0" [translate]="'vehicule.formulaire.aucunePJ'"></span><span *ngIf="assurance.nbPJ > 0">{{assurance.nbPJ}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngFor="let autorisation of vehicule.listeAutorisation" class="listview-item">
                    <div class="lvi-content" *ngIf="autorisation">
                        <div class="avatar" [ngClass]="{ 'warning': isAlerteEcheance(autorisation.dateFin),'danger': isAlerteEchu(autorisation.dateFin) }" [translate]="'vehicule.avatar.autorisation'"></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="openDocument(autorisation.idVehiculeAutorisation, TypeDocument.AUTORISATION)" [translate]="'vehicule.formulaire.documents.autorisation'"></a>
                            </div>
                            <ul class="lvi-attrs">
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.dateDeb'"></strong>
                                    <span>{{ autorisation.dateDebut | date: 'shortDate' }}</span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.dateFin'"></strong>
                                    <span>{{autorisation.dateFin | date: 'shortDate' }}</span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.documents.actif'"></strong>
                                    <span [translate]="isDocumentActif(autorisation) ? 'global.oui' : 'global.non'"></span>
                                </li>
                                <li>
                                    <strong [translate]="'vehicule.formulaire.piecesJointes'"></strong>
                                    <span *ngIf="autorisation.nbPJ == 0" [translate]="'vehicule.formulaire.aucunePJ'"></span><span *ngIf="autorisation.nbPJ > 0">{{autorisation.nbPJ}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="!vehicule.listeCarteGrise?.length && !vehicule.listeAssurance?.length && !vehicule.listeAutorisation?.length"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>