<mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header *ngIf="liste.data.listeResultats.length > 1">
        <mat-panel-title>{{ ('portee.' + data.typeObjet | translate) + ' ' + data.idObjet }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="listview local">
        <div class="listview-body">
            <div *ngFor="let user of listeVisibleApprobateurs" (click)="selectApprobateur(user)" class="listview-item clickable">
                <div class="lvi-content">
                    <div class="avatar">
                        <mat-icon>{{ user?.isSelected ? 'done' : 'person'}}</mat-icon>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <span>{{ user | user }}</span>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'workflow.notification.priorite.title'"></strong><span
                                    [translate]="'workflow.notification.priorite.'+user.prioriteKey"></span></li>
                            <li><strong [translate]="'workflow.notification.role'"></strong><span>{{ user.libelleRole }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="data.approbateurs.length > 0 && !hasApprobateursVisible()" class="empty">
                <span [translate]="'workflow.notification.aucunApprobateurCorrespondant'"></span>
            </div>
            <div *ngIf="data.approbateurs.length == 0" class="alert alert-danger">
                <h4 [translate]="'workflow.notification.alerte.title.warning'" class="alert-heading"></h4>
                <p [translate]="'workflow.notification.alerte.message.approbateur'"></p>
            </div>
        </div>
    </div>
</mat-expansion-panel>
