<div class="lvi-content">
    <div class="avatar"></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="onSelect()">{{ (data.orga.numService ? (data.orga.numService + ' - ') : '') + data.orga.libelle + (data.orga.code ? (' (' + data.orga.code + ')') : '') }}</a>
        </div>
        <ul class="lvi-attrs">           
            <li >
                <strong [translate]="'analytique.dossier'"></strong>
                <span *ngIf="data.dossier">{{ (data.dossier.reference ? (data.dossier.reference + ' - ') : '') + data.dossier.libelle + (data.code1 ? (' (' + data.code1 + ')') : '') }}</span>
                <span *ngIf="!data.dossier" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li >
                <strong>{{ extraOptions?.settings?.libelleAxe5 || ('analytique.axe5' | translate) }}</strong>
                <span *ngIf="data.axe5">{{ (data.axe5.reference ? (data.axe5.reference + ' - ') : '') + data.axe5.libelle + (data.code1 ? (' (' + data.code1 + ')') : '') }}</span>
                <span *ngIf="!data.axe5" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li >
                <strong>{{ extraOptions?.settings?.libelleAxe6 || ('analytique.axe6' | translate) }}</strong>
                <span *ngIf="data.axe6">{{ (data.axe6.reference ? (data.axe6.reference + ' - ') : '') + data.axe6.libelle + (data.code1 ? (' (' + data.code1 + ')') : '') }}</span>
                <span *ngIf="!data.axe6" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li *ngIf="data.field5 != null && getFieldParam('OPERATION_1') as operation">
                <strong>{{ operation.libelle }}</strong><span *ngIf="data.field5">{{ data.field5 }}</span>
                <span *ngIf="!data.field5" [translate]="'liste.nonRenseigne'"></span> 
            </li>
            <li *ngIf="data.field6 != null && getFieldParam('OPERATION_2') as operation">
                <strong>{{ operation.libelle }}</strong><span>{{ data.field6 }}</span>
                <span *ngIf="!data.field6" [translate]="'liste.nonRenseigne'"></span> 
            </li>

        </ul>
    </div>
</div>
            