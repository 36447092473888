/** Énumération des spécificités (options) possibles pour un rôle */
export enum SpecificiteRoles {
	SP_LETTRAGE_AVANCE = 13,
	SP_TVTS = 23,
	SP_RESPONSABLE_BUDGET = 36,
	SP_SUIVI_BUDGETAIRE_DETAILLE = 40,
	SP_ROBOT_WORKFLOW = 41,
	SP_IMPORT_RELEVE_OPE = 43,
	SP_CONTACT_NOTILUS = 44,
	SP_CONNEXION_POUR = 45
}