import { Component,EventEmitter,Input,OnInit,Output } from '@angular/core';
import { Facture } from "@domain/facture/facture";
import { SettingsFCState } from "@domain/settings/settings";
import { TypeEntiteParamFC } from "@domain/typeentite/typeEntiteParam";
import { ListView } from "@domain/common/list-view";
import { FloatingButtonAction } from "@share/component/floating-button/floating-button";
import { FactureDepense } from "@domain/facture/facture-depense";
import { FactureDetailListItemComponent } from "./facture-detail-list-item.component";
import { TranslateService } from "@ngx-translate/core";
import { SearchSpec } from "@domain/common/list-view/searchSpec";
import { Page } from "@domain/common/http/list-result";
import { HttpParams } from "@angular/common/http";
import { FactureDetailCreationComponent } from "@components/facture/detail/detail/creation/facture-detail-creation.component";
import { MatDialog } from "@angular/material/dialog";
import { first } from "rxjs/operators";

/**
 * Liste du détail de la facture
 *
 * @author Laurent Convert
 * @date 11/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-detail-list'},
    selector: 'facture-detail-list',
    templateUrl: './facture-detail-list.component.html'
})
export class FactureDetailListComponent implements OnInit {
    /** Facture en cours */
    @Input() facture: Facture;
    
    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;
    
    /** Paramétrage global du module des factures */
    @Input() settings: SettingsFCState;
    
    /** Paramétrage spécifique au module des factures pour le type entité de la facture */
    @Input() typeEntiteParam: TypeEntiteParamFC;

    /** Évènement déclenché lors de la modification d'une ligne de dépense */
    @Output() onListeChanged: EventEmitter<void> = new EventEmitter<void>();

    /** Liste des lots */
    liste: ListView<FactureDepense,FactureDetailListItemComponent>;

    /** Liste des actions possibles */
    listeActions: Array<FloatingButtonAction> = [];

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,private matDialog: MatDialog) {
    }
    
    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Ajout des paramètres HTTP requis pour l'affichage de la liste
        const params: HttpParams = new HttpParams()
            .append('idReleve',this.facture.idReleve.toString(10))
            .append('idFacture',this.facture.idFacture.toString(10));
        
        //Définition de la liste
        this.liste = new ListView<FactureDepense,FactureDetailListItemComponent>({
            uri: '/controller/Facture/listeFactureDepense?' + params.toString(),
            title: this.translateService.instant('facture.factureDepense.liste.title'),
            component: FactureDetailListItemComponent,
            isFilter: false,
            mapRequest: (request: SearchSpec) => {
                //On force arbitrairement à 1000 éléments dans la page pour "désactiver" la pagination
                request.nbObjetsParPage = 1000;
                return request;
            },
            mapResult: (result: Page<FactureDepense>): Page<FactureDepense> => {
                //Transformation en instance de chaque item
                result.listeResultats = result.listeResultats.map(f => new FactureDepense(f));
                return result;
            },
            listeActions: [{
                //Bouton d'ajout de ligne de détail
                icon: "add",
                onPress: () => this.openDetail(),
                isVisible: () => this.canModifier
            }],
            extraOptions: {
                //Passage de la facture à laquelle est associée la ligne de détail
                facture: this.facture,
                //Bind de la méthode d'ouverture d'une ligne de détail
                openDetail: this.openDetail.bind(this)
            }
        });
    }
    
    /**
     * Ouvre la popin de détail / ajout d'une ligne
     *
     * @param factureDepense La ligne à afficher. Mode création si null.
     */
    openDetail(factureDepense?: FactureDepense) {
        this.matDialog.open(FactureDetailCreationComponent,{
           data: {
               factureDepense: {...factureDepense},
               facture: this.facture,
               canModifier: this.canModifier && this.facture.factureReleve?.importe === false,
               settings: this.settings
           },
           minWidth: 1000
        }).afterClosed()
            .pipe(first())
            .subscribe((refresh: boolean) => {
                if (refresh) {
                    //Rafraichissement de la liste
                    this.liste.refresh();

                    //Déclenchement de l'évènement de mise à jour de la liste
                    this.onListeChanged.emit();
                }
            });
    }
    
}