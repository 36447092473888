import {Ville} from "./ville";
import {DepartementAdmin} from "./departementAdmin";
import {RegionAdmin} from "./regionAdmin";
import {Pays} from "./pays";
import {Zone} from "./zone";

/**
 * Géographie - Géolocalisation (périmètre)
 */
export interface GeoLocalisation {
	idGeoLocalisation: number;
	idTerritoire: number;
	libelle: string;
	type: TypeGeoLocalisation;
	idVille?: number;
	ville?: Ville;
	idDepartementAdmin?: number;
	departementAdmin?: DepartementAdmin;
	idRegionAdmin?: number;
	regionAdmin?: RegionAdmin;
	idPays?: number;
	pays?: Pays;
	idZone?: number;
	zone?: Zone;
}

/**
 * Enumération des types de GeoLocalisation (périmètre) possibles
 */
export enum TypeGeoLocalisation {
	PAYS = 1,
	REGION = 2,
	DEPARTEMENT = 3,
	VILLE = 4,
	ZONE = 6
}