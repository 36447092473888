import {Component, Input} from '@angular/core';
import {ListViewItem} from "../../../../domain/common/list-view/list-view-item";
import {Telephone} from "../../../../domain/profil/telephone";


/**
 * Composant pour une ligne de la liste des téléphones
 */
@Component({
    selector: 'profil-donnees-telephone-list-item',
    templateUrl: './profil-donnees-telephone-list-item.component.html'
})
export class ProfilDonneesTelephoneListItemComponent extends ListViewItem<Telephone> {
    /** Elément courant */
    @Input() data: Telephone;
}
