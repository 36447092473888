import {Component,Input} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {HistoriquePopulation,TypeNature,TypeSource} from "@domain/admin/entreprise/population/historique-population";
import {Sorting} from "@domain/common/list-view/sorting";
import {PopulationHistoriqueItemComponent} from "@components/admin/entreprise/populations/population-details/admin-historique/admin-historique-item/population-historique-item.component";

/**
 * Composant d'affichage de la page AdminHistorique
 *
 * @author Lionel Gimenez
 * @date 11/12/2023
 */
@Component({
    host: {'data-test-id': 'population-historique'},
    selector: 'population-historique',
    templateUrl: './population-historique.component.html'
})
export class PopulationHistoriqueComponent {
    /** Liste historique */
    listeHistorique: ListView<HistoriquePopulation,PopulationHistoriqueItemComponent>;

    /** Id de la population */
    @Input() idPopulation: number;

    /**
     * Constructeur de la classe.
     *
     * @param translateService Le service de traduction utilisé dans la classe.
     */
    constructor(
        private translateService: TranslateService
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        this.initHistoriqueListe();
    }

    /**
     * Initialisation de la liste
     */
    initHistoriqueListe() {
        //Initialisation de la liste des population
        this.listeHistorique = new ListView({
            uri: `/controller/Population/showHistorique/${this.idPopulation}`,
            title: this.translateService.instant('admin.entreprise.populations.liste.title'),
            component: PopulationHistoriqueItemComponent,
            isFilter: true,
            defaultOrder: '-dateLog',
            listeFilters: [
                {
                    clef: 'reference',
                    title: this.translateService.instant('admin.entreprise.populations.liste.reference'),
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'dateLog',
                    type: TypeFilter[TypeFilter.DATE],
                    typeComparaison: 'EQUAL',
                    title: this.translateService.instant('ndf.frais.date')
                },{
                    clef: 'isTypeLien',
                    title: this.translateService.instant('admin.entreprise.populations.liste.exclus'),
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: false,
                    onloadValue: 'true'
                },{
                    clef: 'isDependance',
                    title: this.translateService.instant('workflow.historique.tag.dependance'),
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    isDefault: false,
                    onloadValue: 'true'
                },{
                    clef: 'nature',
                    title: this.translateService.instant('workflow.historique.tag.zone'),
                    listeValues: (Object.keys(TypeNature) as Array<keyof typeof TypeNature>)
                        .filter(key => !isNaN(Number(key)))
                        .map((key) => {
                            return {
                                code: key,
                                libelle: this.translateService.instant('workflow.historique.tag.value.zone.' + key)
                            }
                        })
                },{
                    clef: 'typeLog',
                    title: this.translateService.instant('workflow.historique.tag.type'),
                    listeValues: (Object.keys(TypeNature) as Array<keyof typeof TypeNature>)
                        .filter(key => !isNaN(Number(key)))
                        .map((key) => {
                            return {
                                code: key,
                                libelle: this.translateService.instant('workflow.historique.tag.value.type.' + key)
                            }
                        })
                },{
                    clef: 'sourceLog',
                    title: this.translateService.instant('workflow.historique.tag.origine'),
                    listeValues: (Object.keys(TypeSource) as Array<keyof typeof TypeSource>)
                        .filter(key => !isNaN(Number(key)))
                        .map((key) => {
                            return {
                                code: key,
                                libelle: this.translateService.instant('workflow.historique.tag.value.origine.' + key)
                            }
                        })
                }

            ]
        });

        //Définition des colonnes de tri
        this.listeHistorique.columns = [
            {key: 'reference',title: 'admin.entreprise.populations.liste.reference'},
            {key: 'typeLien',title: 'admin.entreprise.populations.liste.exclus'},
            {key: 'dependance',title: 'admin.entreprise.populations.liste.actif'},
            {key: 'typeEntite.code',title: 'global.liste.filtres.typeEntite'}
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeHistorique.sorting = new Sorting(this.listeHistorique.columns,"reference");
    }
}