<div class="lvi-content" *ngIf="data">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="cursor-default">{{ data.matricule }} - {{ data.nom }} {{ data.prenom }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.description.profilsRestreints.identifiant'"></strong>
                <span>{{ data.idUser }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.description.profilsRestreints.serviceAffectation'"></strong>
                <span>{{ data.orga.numService }} - {{ data.orga.libelle }}</span>
            </li>
            <li *ngIf="profils != ''">
                <strong [translate]="'admin.entreprise.description.profilsRestreints.profils'"></strong>
                <span>{{ profils }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.description.profilsRestreints.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
        </ul>
    </div>
</div>
