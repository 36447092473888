<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.populations.liste.ajoutUserTitle'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="py-1">
    <form autocomplete="off">
        <fieldset>
            <div class="row">
                <custom-input ngDefaultControl
                              name="collab"
                              libelle="admin.entreprise.populations.liste.collaborateur"
                              customType="autocomplete"
                              autocompleteType="user"
                              optionDisplay="nomPrenomMatricule"
                              [idName]="'idUser'"
                              [(ngModel)]="user"
                              [lCol]="4"
                              [rCol]="7"
                              [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input ngDefaultControl
                              id="excludeUser"
                              name="excludeUser"
                              [ngModel]="isExcludeUser"
                              [(customModel)]="isExcludeUser"
                              customType="checkbox"
                              [lCol]="4"
                              libelle="admin.entreprise.populations.liste.exclureUser">
                </custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div class="action-row d-flex flex-row-reverse">
    <div class="end-actions">
        <button mat-flat-button color="primary" (click)="addUser()">
            <span translate>global.actions.ajouter</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
    </div>
</div>