import {Component,Input} from '@angular/core';
import {UserDto} from "../../../domain/user/user-dto";

/**
 * Composant d'affichage d'un collaborateur en vue de la création d'un objet workflow.
 */
@Component({
    templateUrl: './collab-list-item.component.html',
    host: {'data-test-id': 'collab-list-item'}
})
export class CollabListItemComponent {
    /** User courant */
    @Input() data: UserDto;
}
