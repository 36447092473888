<h1 mat-dialog-title>
    <span translate>cgu.title</span>
</h1>
<div *ngIf="data.isOther" mat-dialog-content class="d-flex flex-row justify-content-center p-3">
    <div class="mat-dialog-alert">
        <div class="avatar"><mat-icon>policy_outline</mat-icon></div>
        <div class=""><span translate>cgu.other</span></div>
    </div>
</div>
<div mat-dialog-content>
    <div class="inner-html" [innerHTML]="data.cgu"></div>
    <div class="pb-1" style="position:relative;" #scrollEnd></div>
</div>
<div mat-dialog-actions>
    <div [matTooltip]="'cgu.tooltipValidation' | translate" [matTooltipDisabled]="!buttonDisabled">
        <button mat-stroked-button color="primary" mat-dialog-close [disabled]="buttonDisabled">
            <span>{{ 'cgu.actions.refuser' | translate | uppercase }}</span>
        </button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true" [disabled]="buttonDisabled" >
            <span>{{ 'cgu.actions.valider' | translate | uppercase }}</span>
        </button>
    </div>
</div>