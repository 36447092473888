<h1 mat-dialog-title>
    <span [translate]="'od.saisieTemps.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #saisieForm="ngForm">
        <!-- Type de saisie -->
        <div class="row">
            <custom-input ngDefaultControl name="typeSaisie" libelle="od.saisieTemps.typeSaisie" [(ngModel)]="saisie.forfait"
                          customType="select" [selectOptions]="typeSaisie" optionValue="id" optionDisplay="libelle"
                          lCol="5" rCol="6" [readonly]="!data.canModifier" [required]="true"></custom-input>
        </div>
        &nbsp;

        <!-- Template pour le forfait -->
        <ng-container *ngIf="saisie.forfait; else blocReel">
            <!-- Date de début de la mission -->
            <div class="row">
                <custom-input ngDefaultControl name="debutMission" libelle="od.saisieTemps.debutTransports" [ngModel]="saisie.debutForfaitInitial" [(customModel)]="saisie.debutForfaitInitial"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="true"></custom-input>
                <custom-input ngDefaultControl name="heureDebutMission" [(ngModel)]="saisie.heureDebForfaitInitiale"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="true"></custom-input>
            </div>
            <!-- Saisie au forfait (début) -->
            <div class="row" [hidden]="!saisie.forfait">
                <custom-input ngDefaultControl name="debutForfait" libelle="od.saisieTemps.saisieDebut" [ngModel]="saisie.debutForfait" [(customModel)]="saisie.debutForfait"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="!data.canModifier" [required]="true"></custom-input>
                <custom-input ngDefaultControl name="heureDebutForfait" [(ngModel)]="saisie.heureDebForfait"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="!data.canModifier"></custom-input>
                <div style="align-self: center"><span [matTooltip]="'od.saisieTemps.tempsSupp' | translate:{temps: saisie.deltaDebut}" class="material-icons-outlined">info</span></div>
            </div>
            &nbsp;
            <!-- Date de fin de la mission -->
            <div class="row">
                <custom-input ngDefaultControl name="finMission" libelle="od.saisieTemps.finTransports" [ngModel]="saisie.finForfaitInitiale" [(customModel)]="saisie.finForfaitInitiale"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="true"></custom-input>
                <custom-input ngDefaultControl name="heureFinMission" [(ngModel)]="saisie.heureFinForfaitInitiale"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="true"></custom-input>
            </div>
            <!-- Saisie au forfait (fin) -->
            <div class="row" [hidden]="!saisie.forfait">
                <custom-input ngDefaultControl name="finForfait" libelle="od.saisieTemps.saisieFin" [ngModel]="saisie.finForfait" [(customModel)]="saisie.finForfait"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="!data.canModifier" [required]="true"></custom-input>
                <custom-input ngDefaultControl name="heureFinForfait" [(ngModel)]="saisie.heureFinForfait"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="!data.canModifier"></custom-input>
                <div style="align-self: center"><span [matTooltip]="'od.saisieTemps.tempsSupp' | translate:{temps: saisie.deltaFin}" class="material-icons-outlined">info</span></div>
            </div>
        </ng-container>

        <!-- Template pour le réel -->
        <ng-template #blocReel>
            <!-- Date de début de la mission -->
            <div class="row">
                <custom-input ngDefaultControl name="debutMission" libelle="od.saisieTemps.debutMission" [ngModel]="saisie.debutReelInitial" [(customModel)]="saisie.debutReelInitial"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="true"></custom-input>
                <custom-input ngDefaultControl name="heureDebutMission" [(ngModel)]="saisie.heureDebReelInitiale"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="true"></custom-input>

            </div>
            <!-- Saisie au réel (début) -->
            <div class="row" [hidden]="saisie.forfait">
                <custom-input ngDefaultControl name="debutReel" libelle="od.saisieTemps.saisieDebut" [ngModel]="saisie.debutReel" [(customModel)]="saisie.debutReel"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="!data.canModifier" [required]="true"></custom-input>
                <custom-input ngDefaultControl name="heureDebutReel" [(ngModel)]="saisie.heureDebReel"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="!data.canModifier"></custom-input>
            </div>
            &nbsp;
            <!-- Date de fin de la mission -->
            <div class="row">
                <custom-input ngDefaultControl name="finMission" libelle="od.saisieTemps.finMission" [ngModel]="saisie.finReelInitiale" [(customModel)]="saisie.finReelInitiale"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="true"></custom-input>
                <custom-input ngDefaultControl name="heureFinMission" [(ngModel)]="saisie.heureFinReelInitiale"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="true"></custom-input>
            </div>
            <!-- Saisie au réel (fin) -->
            <div class="row" [hidden]="saisie.forfait">
                <custom-input ngDefaultControl name="finReel" libelle="od.saisieTemps.saisieFin" [ngModel]="saisie.finReel" [(customModel)]="saisie.finReel"
                              customType="date"
                              lCol="5" rCol="3" [readonly]="!data.canModifier" [required]="true"></custom-input>
                <custom-input ngDefaultControl name="heureFinReel" [(ngModel)]="saisie.heureFinReel"
                              customType="heure"
                              lCol="0" rCol="3" [readonly]="!data.canModifier"></custom-input>
            </div>
        </ng-template>
    </form>
</div>
<div mat-dialog-actions *ngIf="data.canModifier">
    <button mat-flat-button color="primary" (click)="saveSaisie()" [disabled]="isLoading || saisieForm.invalid">
        <span *ngIf="!isLoading" translate>global.actions.enregistrer</span>
        <mat-spinner *ngIf="isLoading" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
