import {Component} from '@angular/core';
import {ControlContainer,NgForm} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Communaute} from "@domain/travel/communaute";
import {
    ReferentielCommunauteListItemComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/communaute/communaute-list-item/referentiel-communaute-list-item.component";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {Result} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {AbstractReferentielComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/abstract-referentiel/abstract-referentiel.component";
import {ReferentielCommunauteAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/communaute/communaute-add/referentiel-communaute-add.component";

/**
 * Cadre du référentiel "Communauté" de l'onglet "Référentiels" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-communaute'},
    selector: 'referentiel-communaute',
    templateUrl: './referentiel-communaute.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReferentielCommunauteComponent extends AbstractReferentielComponent<Communaute,ReferentielCommunauteListItemComponent> {
    /**
     * Constructeur
     *
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param matDialog Boite de dialogue
     */
    constructor(adminTravelhubService: AdminTravelhubService,
                translateService: TranslateService,
                toastrService: ToastrService,
                matDialog: MatDialog) {
        super(
            adminTravelhubService,
            translateService,
            toastrService,
            matDialog,
            ReferentielCommunauteListItemComponent,
            ReferentielCommunauteAddComponent
        );
    }

    /**
     * Retourne l'uri propre au type de référentiel
     */
    protected getUri(): string {
        return `/controller/TravelHubConfig/${this.sbtConfigUsed.idSBTConfigUsed}/listeCommunaute`;
    }

    /**
     * Retourne la racine pour les traductions
     */
    protected getRootTrad(): string {
        return 'admin.voyages.travelhub.sbtConfig.referentiel.communaute';
    }

    /**
     * Post-traitement de la liste paginée retournée par le serveur
     *
     * @param result Résultat à traiter
     */
    protected mapResult(result: Result): void {
        //Récupération de la liste des SBT utilisés
        this.listeSBTConfigUsed = result.data.listeSBTConfigUsed;

        //Transformation des résultats en instance
        return result.data.listeCommunaute.map(item => this.newInstanceReferentiel(item));
    }

    /**
     * Retourne une instance du type de référentiel
     *
     * @param dto Source
     */
    protected newInstanceReferentiel(dto?: Communaute | any): Communaute {
        return new Communaute(dto);
    }

    /**
     * Met à jour le niveau d'erreur du référentiel
     */
    public updateAlertLevelList(): void {
        this.listeReferentiel.alertLevel = this.sbtConfigUsed.errorCommunaute ? NiveauAlerte.ERROR : null;
    }

    /**
     * Enregistrement d'une entrée du référentiel
     *
     * @param referentiel Référentiel à enregistrer
     */
    protected save(referentiel: Communaute): Observable<Result> {
        return this.adminTravelhubService.saveCommunaute(referentiel);
    }

    /**
     * Suppression d'une entrée du référentiel
     *
     * @param referentiel Référentiel à supprimer
     */
    protected delete(referentiel: Communaute): Observable<Result> {
        return this.adminTravelhubService.deleteCommunaute(referentiel);
    }

}