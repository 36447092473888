import {Component,Input,OnInit,ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';

import {SettingsGlobalState} from '@domain/settings/settings';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {InputRangeErrorStateMatcher} from "@share/directive/number/input-number.directive";
import {NgForm,NgModel} from "@angular/forms";
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {PatternService} from "@share/component/pattern/pattern.service";
import {Field} from "@domain/settings/field";
import {Analytique} from "../../analytique";
import {Axe5DTO,Axe6DTO,DossierDTO} from "@domain/od/od";
import {Subject} from "rxjs";


@Component({
    host: {'data-test-id': 'analytique-add'},
    selector: 'analytique-add',
    templateUrl: './analytique-add.component.html'
})
export class AnalytiqueAddComponent implements OnInit {
    /** Déclaration(s) pour le template */
    TypeEntiteAxe = AnalytiqueType;

    /** Indique si on affiche la ventilation */
    @Input() isShowVentilation: boolean  = true;

    /** Référence vers le formulaire */
    @ViewChild("analytiqueAddForm",{ static: true })
    analytiqueAddForm: NgForm;

    /** Référence vers le modèle de la ventilation */
    @ViewChild("ventilation",{ static: false })
    ventilation?: NgModel;

    /** Nombre de chiffres après la virgule pour la saisie de la ventilation, 2 par défaut */
    precision: number;

    /** Gestionnaire d'erreur pour la saisie du pourcentage qui doit être compris entre 0.01 et 100 */
    errorStateMatcherVentilation: InputRangeErrorStateMatcher = new InputRangeErrorStateMatcher(0.01,100);

    /** Liste des ventilations analytiques autres que celle en cours */
    listeAutreAnalytique: Array<Analytique>;

    /** Subject dossier pour le reset du champ autocomplete */
    reinitListeDossier$: Subject<void> = new Subject();

    /** Subject axe 6 pour le reset du champ autocomplete */
    reinitListeAxe6$: Subject<void> = new Subject();

    /** Subject axe 5 pour le reset du champ autocomplete */
    reinitListeAxe5$: Subject<void> = new Subject();

    /** Données intégrées dans la liste */
    @Input() data: {
        idMIB: number,
        listeAnalytique: Array<Analytique>,
        analytique: Analytique,
        idxAnalytique: number,
        typeEntite: TypeEntite,
        settings: SettingsGlobalState,
        precision: number,
        isOrgaFixe: boolean,
        idCollab: number
    }

    /**
     * Constructeur
     */
    constructor(
        private confirmService: ConfirmService,
        private translateService: TranslateService,
        private analytiqueService: AnalytiqueService,
        private patternService: PatternService,
        private matDialogRef: MatDialogRef<AnalytiqueAddComponent>) {
    }

    ngOnInit() {
         //Initialisation de la précision de la saisie de la ventilation, 2 si non précisé
         this.precision = this.data.precision || this.data.precision === 0 ? this.data.precision : 2;

         //Récupération et copie des ventilations analytiques
         this.listeAutreAnalytique = this.data.listeAnalytique ? [...this.data.listeAnalytique] : [];

        //Suppression de la ventilation courante
         if (this.listeAutreAnalytique.length > 0 && (this.data.idxAnalytique || this.data.idxAnalytique === 0)) {
             this.listeAutreAnalytique.splice(this.data.idxAnalytique,1);
         }
    }

    /**
     * Récupération d'un paramétrage analytique
     */
    getFieldParam(code: string): Field {
        //Récupération du champ
        return this.analytiqueService.getFieldParam(this.data.settings,code);
    }

    /**
     * Récupération de l'expression régulière liée au masque
     */
    getPatternFor(operation: Field): string {
        return this.patternService.getPatternForField(operation);
    }

    /**
     * Suppression de l'analytique
     */
    deleteAnalytique() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Fermeture de la popup
                this.matDialogRef.close({
                    isRemoved: true
                });
            }
        });
    }

    /**
     * Retourne l'état de validité du pourcentage de ventilation
     */
    isVentilationValid(): boolean {
        //Si on a pas activé la ventilation
        if (!this.isShowVentilation) {
            //Le contrôle est toujours ok
            return true;
        }

        //Si viewchild a récupéré le ngModel ventilation, on check le contrôle
        return this.ventilation!= null && !this.errorStateMatcherVentilation.isErrorState(this.ventilation.control,this.analytiqueAddForm);
    }

    /**
     * Vérifie si la répartition analytique existe déjà
     */
    isRepartitionAnalytiqueExists(): boolean {
        return this.analytiqueService.isRepartitionAnalytiqueExists(this.listeAutreAnalytique,this.data.analytique);
    }

    /**
     * Vérifie si la ventilation est valide
     */
    isAnalytiqueValid(): boolean {
        return this.isVentilationValid() && !this.isRepartitionAnalytiqueExists();
    }

    /**
     * Event de changement d'un champ autocomplete pour l'analytique
     */
    onAutocompleteAnalytiqueChanged(value: (String | Axe5DTO | Axe6DTO | DossierDTO), analytiqueType: AnalytiqueType) {
        //Si on a un champ vide on reset l'analytiqueType
        if (value === "") {
            if (analytiqueType == AnalytiqueType.Cinq) {
                this.data.analytique.axe5 = null;
            } else if (analytiqueType == AnalytiqueType.Six) {
                this.data.analytique.axe6 = null;
            } else {
                this.data.analytique.dossier = null
            }
        }
    }

    /**
     * Reset du champ dossier
     */
    resetDossier() {
        this.data.analytique.dossier = null;
        this.reinitListeDossier$.next();
    }

    /**
     * Reset du champ axe5
     */
    resetAxe5() {
        this.data.analytique.axe5 = null;
        this.reinitListeAxe5$.next();
    }

    /**
     * Reset du champ axe6
     */
    resetAxe6() {
        this.data.analytique.axe6 = null;
        this.reinitListeAxe6$.next();
    }
}

/** Enumération du type de l'analytique */
enum AnalytiqueType {
    Cinq,Six, Dossier
}
