import { TypePortee } from './workflow';

/**
 * Classe déterminant la possibilité de créer des objets workflow
 */
 export class WFCreationPossible {

    /** Différents objets workflow possibles */
    avancePossible: boolean;
    facturePossible: boolean;
    ndfPossible: boolean;
    odPossible: boolean;
    ompPossible: boolean;

    constructor(dto: any) {
        if (dto) {
            this.avancePossible = dto.avancePossible;
            this.facturePossible = dto.facturePossible;
            this.ndfPossible = dto.ndfPossible;
            this.odPossible = dto.odPossible;
            this.ompPossible = dto.ompPossible;
        }
    }

    /**
     * Retourne l'autorisation ou non de créer un objet de la portée concernée
     * @param portee la portée
     * @returns true si création autorisée sinon false
     */
    creationPossible(portee: TypePortee): boolean {
        switch (portee) {
            case TypePortee.AV : return this.avancePossible === true;
            case TypePortee.FC : return this.facturePossible === true;
            case TypePortee.NF : return this.ndfPossible === true;
            case TypePortee.OD : return this.odPossible === true;
            case TypePortee.OT : return this.ompPossible === true;
            default: return false;
        }
    }
}