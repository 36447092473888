import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {UserDto} from "@domain/user/user-dto";
import {ProfilConnexionUsageListItemComponent} from "./profil-connexion-usage-list-item/profil-connexion-usage-list-item.component";
import {Sorting} from "@domain/common/list-view/sorting";
import {TypeProfil} from "@domain/user/user";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@domain/common/http/list-result";

/**
 * Onglet "Usage" d'un profil de connexion TH
 *
 * @author Laurent Convert
 * @date 19/03/2024
 */
@Component({
	host: {'data-test-id': 'profil-connxion-usage'},
	selector: 'profil-connxion-usage',
	templateUrl: './profil-connxion-usage.component.html'
})
export class ProfilConnxionUsageComponent implements OnInit {
	/** Identifiant du profil de connexion */
	@Input()
	idProfilConnexion: number;

	/** Liste des utilisateurs actifs ayant le profil comme profil actif */
	listeUser: ListView<UserDto,ProfilConnexionUsageListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 */
	constructor(private translateService: TranslateService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		this.listeUser = new ListView<UserDto,ProfilConnexionUsageListItemComponent>({
			uri: `/controller/ProfilConnexion/listeUser/${this.idProfilConnexion}`,
			title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.onglets.usage'),
			component: ProfilConnexionUsageListItemComponent,
			mapResult: (page: Page<UserDto>) => {
				//Transformation en instance de chaque item
				page.listeResultats = page.listeResultats.map(user => new UserDto(user));

				return page;
			},
			isFilter: true,
			listeFilters: [{
				title: this.translateService.instant('admin.entreprise.utilisateurs.internes.identifiant'),
				clef: 'user.nomAcces',
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				title: this.translateService.instant('admin.entreprise.utilisateurs.internes.nom'),
				clef: 'user.nom',
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				title: this.translateService.instant('admin.entreprise.utilisateurs.internes.prenom'),
				clef: 'user.prenom',
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				title: this.translateService.instant('admin.entreprise.utilisateurs.internes.matricule'),
				clef: 'user.matricule',
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				title: this.translateService.instant('admin.entreprise.utilisateurs.internes.serviceAffectation'),
				clef: 'user.orga.numService,user.orga.libelle',
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				title: this.translateService.instant('admin.entreprise.utilisateurs.internes.profils'),
				clef: 'user.fonctions',
				typeComparaison: TypeComparaison[TypeComparaison.LIKE],
				multiple: true,
				listeValues: [
					{
						code: '' + TypeProfil.COMPTABLE,
						libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.COMPTABLE)
					},{
						code: '' + TypeProfil.RESPONSABLE,
						libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.RESPONSABLE)
					},{
						code: '' + TypeProfil.COLLABORATEUR,
						libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.COLLABORATEUR)
					},{
						code: '' + TypeProfil.ASSISTANT,
						libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.ASSISTANT)
					},{
						code: '' + TypeProfil.SOUS_ADMINISTRATEUR,
						libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.SOUS_ADMINISTRATEUR)
					},{
						code: '' + TypeProfil.FOURNISSEUR,
						libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.FOURNISSEUR)
					}
				]
			}]
		});

		//Définition des colonnes de tri
		this.listeUser.columns = [
			{ key: 'user.matricule', title: 'admin.entreprise.utilisateurs.internes.matricule' },
			{ key: 'user.nomAcces', title: 'admin.entreprise.utilisateurs.internes.identifiant' },
			{ key: 'user.nom', title: 'admin.entreprise.utilisateurs.internes.nom' },
			{ key: 'user.prénom', title: 'admin.entreprise.utilisateurs.internes.prénom' },
			{ key: 'user.orga.numService', title: 'admin.entreprise.utilisateurs.internes.serviceAffectation' },
		];

		//Persistance des filtres
		this.listeUser.isPersistFilters = true;
		this.listeUser.className = 'ProfilConnxionUsageComponent';

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeUser.sorting = new Sorting(this.listeUser.columns, "user.matricule");
	}

}