import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable,of,throwError} from "rxjs";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {map,switchMap} from "rxjs/operators";
import {Compte} from "@domain/admin/comptabilite/compte";
import {ModeRemb} from "@domain/admin/comptabilite/mode-remb";

/**
 * Service de gestion de l'écran Modes de remboursement du menu admin Comptabilité
 */
@Injectable({
	providedIn: 'root'
})
export class ComptabiliteModeRemboursementService {
	/**
	 * Constructeur
	 *
	 * @param http client HTTP
	 */
	constructor(private http: HttpClient) {
	}

	/**
	 * Renvoie la liste des comptes bancaires
	 */
	getListeCompte(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ModeRemb/listeCompte`,null)
			.pipe(map(result => {
				//Transformation en instance de chaque item
				result.data.listeCompte = result.data.listeCompte?.map(compte => new Compte(compte));

				return result;
			}));
	}

	/**
	 * Enregistrement d'un mode de remboursement
	 *
	 * @param modeRem Le mode de remboursement
	 */
	saveModeRemb(modeRem: ModeRemb) {
		return this.http.put<Result>(`${environment.baseUrl}/controller/ModeRemb/saveModeRemb`,modeRem).pipe(switchMap(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Envoi du résultat
				return of(result.data);
			} else {
				//Propagation d'une erreur
				return throwError(result?.codeErreur);
			}
		}));
	}

	/**
	 * Suppression d'un mode de remboursement
	 *
	 * @param modeRem Le mode de remboursement
	 */
	deleteModeRemb(modeRem: ModeRemb) {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ModeRemb/deleteModeRemb/${modeRem.idModeRemb}`)
			.pipe(switchMap(result => {
				//Vérification du résultat
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Envoi d'un résultat vide pour que le traitement puisse continuer
					return of(null);
				} else {
					//Propagation d'une erreur
					return throwError(result?.codeErreur);
				}
			}));
	}

}
