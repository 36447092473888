import {Component} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {MatDialogRef} from "@angular/material/dialog";

/**
 * Boite de dialogue pour l'import d'une licence pour l'administrateur
 */
@Component({
	selector: 'import-licence',
	templateUrl: './import-licence.component.html',
})
export class ImportLicenceComponent {
	/**
	 * Constructeur
	 *
	 * @param toastrService Service pour l'affichage des popups d'informations sur le succès ou non de l'enregistrement de la licence
	 * @param translateService Service pour la traduction
	 * @param dialogRef Référence de la boîte de dialogue
	 */
	constructor(private toastrService: ToastrService,
				private translateService: TranslateService,
				private dialogRef: MatDialogRef<ImportLicenceComponent>) {
	}

	/**
	 * Méthode appelée lorsque la licence a été enregistrée
	 *
	 * @param event
	 */
	onItemUploaded(event) {
		//Si dans la réponse reçue il y a une licence non vide alors c'est que c'est une nouvelle licence, sinon c'est que la nouvelle licence est la même que celle déjà présente
		if (event.result.data.licence) {
			this.toastrService.success(this.translateService.instant('admin.entreprise.description.licence.succes.import'));
			//On ferme la fenêtre en envoyant la licence au composant parent
			this.dialogRef.close(event.result.data.licence);
		}
	}
}
