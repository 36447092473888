import { Component,Input } from '@angular/core';
import { ListViewItem } from "../../../domain/common/list-view/list-view-item";
import { MatDialog } from "@angular/material/dialog";
import { EnveloppeComponent } from "../detail/enveloppe.component";
import { Enveloppe } from "../../../domain/budget/enveloppe";

@Component({
    templateUrl: './enveloppe-list-item.component.html'
})
/**
 * Composant d'un item de la liste des enveloppes budgétaires
 */
export class EnveloppeListItemComponent extends ListViewItem<Enveloppe> {

    /** Enveloppe courante */
    @Input() data: Enveloppe;

    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog) {
        //Héritage
        super();
    }

    /**
     * Fonction appelée au clic sur une enveloppe afin de consulter son détail
     */
    detailEnveloppe() {
        this.matDialog.open(EnveloppeComponent, {
            data: this.data,
            width: '80%'
        });
    }
}
