<h1 mat-dialog-title>
    <span translate>lot.ajout.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content *ngIf="liste">
    <list-view [liste]="liste"></list-view>
</div>
<div *ngIf="liste" class="action-row">
    <div class="start-actions">
        <button mat-stroked-button color="primary" [disabled]="liste.nbSelectedItems < 1" (click)="unselectAll()">
            <span translate>global.actions.toutDeselectionner</span>
        </button>
        <button mat-stroked-button color="primary" [disabled]="!(liste.data?.listeResultats?.length > 0) || liste.nbSelectedItems == liste.data.listeResultats.length" (click)="selectAll()">
            <span translate>global.actions.toutSelectionner</span>
        </button>
    </div>
    <div class="end-actions">
        <button mat-flat-button color="primary" [disabled]="liste.nbSelectedItems < 1" (click)="addToLot()">
            <span *ngIf="!isSaving" translate>global.actions.ajouter</span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
    </div>
</div>