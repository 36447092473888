<fieldset ngModelGroup="departement-generalites">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.departement.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="departement.libelle"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.territoire.libelle"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="region"
                                  name="region"
                                  [idName]="'libelle'"
                                  optionValue="libelle"
                                  (onChange)="onRegionChange();"
                                  [(ngModel)]="selectedRegion"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  optionDisplay="libelle"
                                  [autocompleteFilter]="{listeTypes: ['REGION_ADMINISTRATIVE']}"
                                  libelle="geographie.region"
                                  [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="code"
                                  name="code"
                                  [(ngModel)]="departement.code"
                                  customType="input"
                                  [required]="true"
                                  libelle="admin.bibliotheque.geographie.territoire.code">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
