<div class="with-floating-button">
    <div *ngIf="true" class="content">
        <page-header (onGoBack)="onGoBack()" [isPending]="isLoading" (onSelectedItemChange)="onSelectedItemChange($event)"
                     [listeItems]="listeTabItems"
                     [title]="'profilUser.mobile.title' | translate">
        </page-header>

        <div [hidden]="selectedItem.code != 'CONNECTED_DEVICES'">
            <list-view *ngIf="liste" [liste]="liste"></list-view>
        </div>

        <div [hidden]="selectedItem.code != 'CONNECT_DEVICE'">
            <mat-card>
                <mat-card-title>
                    <span [translate]="'profilUser.mobile.listTitle'"></span>
                </mat-card-title>
                <mat-card-content>
                    <div class="row">
                        <div class="col-md-8 d-flex flex-column justify-content-center align-items-center">
                            <p [translate]="'profilUser.mobile.downloadMessage'"></p>
                            <div class="w-100 r-flex justify-content-around align-items-center">
							<span class="text-left">
								<a href="https://itunes.apple.com/fr/app/notilus-inone/id1291382197" target="_new">
                                    <img alt="Get it on App Store" src="/assets/logo/app_store.svg" height="50"/>
                                </a>
							</span>
                            <span class="text-right">
								<a href="https://play.google.com/store/apps/details?id=com.dimo.notilus.inone" target="_new">
                                    <img alt="Get it on Google Play" src="/assets/logo/google_play.png" height="50"/>
                                </a>
							</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <qrcode *ngIf="qrCode" [errorCorrectionLevel]="'H'" [qrdata]="qrCode" [width]="256"></qrcode>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <floating-button [hidden]="selectedItem?.code != 'CONNECTED_DEVICES'" [isPending]="isLoading" [listeActions]="listeActions"></floating-button>
</div>
<please-wait *ngIf="!true" class="content"></please-wait>
