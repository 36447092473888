import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {ODService} from "../../../od.service";
import {Od} from "../../../../../domain/od/od";
import {SettingsODState} from "../../../../../domain/settings/settings";
import {Store} from "@ngrx/store";
import * as settingsActions from "../../../../../reducers/settings";
import {TypePortee} from "../../../../../domain/workflow/workflow";
import {AppState} from "../../../../../domain/appstate";
import {EngagementsResumeItem,TypeEngagement} from "../../../../../domain/od/engagements/resume/engagements-resume-item";
import {TranslateService} from "@ngx-translate/core";
import {EngagementsResume} from "../../../../../domain/od/engagements/resume/engagements-resume";
import {ToastrService} from "ngx-toastr";
import {finalize,first} from "rxjs/operators";
import {OdEngagementPipe} from "../od-engagement.pipe";

/**
 * Composant du cadre "Récapitulatif des montants"
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-engagements-resume',
	templateUrl: './od-engagements-resume.component.html'
})
export class OdEngagementsResumeComponent implements OnInit {
	/** Liste des types d'engagement */
	TypeEngagement = TypeEngagement;

	/** Ordre de mission */
	@Input() od: Od = null;

	/** Paramétrage de l'OD */
	settings: SettingsODState;

	/** Résumé */
	engagements: EngagementsResume;

	/** Avances */
	avances: EngagementsResumeItem;

	/** Evènement signalant la mise à jour du résumé */
	@Output() engagementsResumeChange = new EventEmitter<EngagementsResume>();

	/** Indicateur du chargement en cours */
	isLoading: boolean = false;

	/** Indique si le détail de la ligne est affiché */
	isOuvert: boolean = true;

	/**
	 * Constructeur
	 */
	constructor(private odService: ODService,
				private store: Store<AppState>,
				public translateService: TranslateService,
				private toastrService: ToastrService,
				private filterEngagement: OdEngagementPipe<EngagementsResumeItem>) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement du paramétrage de l'OD
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.OD
		});

		//Sélection du paramétrage
		this.store.select(state => state.settings?.[TypePortee.OD]).subscribe(settings => this.settings = settings);

		//Chargement des données
		this.refresh();
	}

	/**
	 * Rafraichissement des données
	 */
	refresh(): void{
		//Chargement des données
		this.isLoading = true;

		//Récupération des données du résumé
		this.odService.getEngagementsResume(this.od?.idOd)
			.pipe(first(), finalize(() => { this.isLoading = false; }))
			.subscribe({
				next: (engagementResume) => {
					//Instanciation du résumé
					this.engagements = new EngagementsResume(engagementResume);

					//On filtre pour récupérer uniquement les avances
					this.avances = this.engagements.listeEngagement.find(value => value.getType() == TypeEngagement.AVANCES.valueOf());

					//On filtre pour ignorer les avances et trier dans l'ordre Indemnités, Frais, Factures
					this.engagements.listeEngagement = this.filterEngagement.transform(this.engagements.listeEngagement,[TypeEngagement.INDEMNITES,TypeEngagement.FRAIS,TypeEngagement.FACTURES]);

					//émission de l'évènement signalant la mise à jour du résumé
					this.engagementsResumeChange.emit(this.engagements);
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.chargement'));
				}
			});
	}

	/**
	 * Retourne True si la ligne est celle des indemnités et si le montant prévisionnel est différent du montant réel
	 *
	 * @param engagement Ligne d'engagement
	 */
	isIndemnitesAndMontantPrevNotEqualsMontantOrigine(engagement: EngagementsResumeItem): boolean {
		return engagement?.getType() == TypeEngagement.INDEMNITES && engagement?.montantPrevisionnel !== engagement?.montantOrigine;
	}

}