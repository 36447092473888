import {NgModule} from '@angular/core';
import {ShareModule} from "@share/share.module";
import {PlanComptableComponent} from "./plan-comptable.component";
import {ComptabilitePlanComptableService} from "./comptabilite-plan-comptable.service";
import {ListeComptesComptablesComponent} from './comptes-comptables/liste-comptes-comptables.component';
import {CompteListItemComponent} from './comptes-comptables/compte-list-item/compte-list-item.component';
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {CompteComptableComponent} from '@components/admin/comptabilite/plan-comptable/comptes-comptables/detail-compte-comptable/compte-comptable.component';
import {PlanComptableParametresComponent} from './plan-comptable-parametres/plan-comptable-parametres.component';
import {CompteComptableGeneralitesComponent} from './comptes-comptables/detail-compte-comptable/compte-comptable-generalites/compte-comptable-generalites.component';
import {ZonePredefinieModule} from "@components/zone-predefinie/zone-predefinie.module";
import {DatesFixesPopinComponent} from './plan-comptable-parametres/dates-fixes-popin/dates-fixes-popin.component';
import {DroitAdmin} from "@core/security/droit-admin";


/**
 * Routes du menu admin Comptabilité / Plan comptable
 */
export const comptabilitePlanComptableRoutes: Routes = [
    {
        path: 'CompteComptable/:numero',
        component: CompteComptableComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_COMPTE_GESTION]}
    }
]

/**
 * Module Comptabilité / Plan Comptable
 */
@NgModule({
    imports: [
        ShareModule,
        ZonePredefinieModule,
    ],
    declarations: [
        PlanComptableComponent,
        ListeComptesComptablesComponent,
        CompteListItemComponent,
        CompteComptableComponent,
        PlanComptableParametresComponent,
        CompteComptableGeneralitesComponent,
        DatesFixesPopinComponent
    ],
    providers: [
        ComptabilitePlanComptableService
    ]
})
export class ComptabilitePlanComptableModule {
}
