import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Profil} from "@domain/profil/profil";
import {ProfilListItemComponent} from "@components/admin/workflow/conditions/alertes/detail/select-profils-popin/profil-list-item/profil-list-item.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Condition} from "@domain/workflow/condition";
import {TranslateService} from "@ngx-translate/core";
import {TypeProfil} from "@domain/user/user";
import {Page} from "@domain/common/http/list-result";

/**
 * Popin de sélections des profils
 */
@Component({
	host: {'data-test-id': 'select-profils-popin'},
	selector: 'select-profils-popin',
	templateUrl: './select-profils-popin.component.html'
})
export class SelectProfilsPopinComponent implements OnInit {
	/** Liste des profils */
	listeProfils: ListView<Profil,ProfilListItemComponent>;

	/** Données de la liste */
	listData: Profil[] = [
		new Profil(TypeProfil.COMPTABLE),
		new Profil(TypeProfil.RESPONSABLE),
		new Profil(TypeProfil.COLLABORATEUR),
		new Profil(TypeProfil.ASSISTANT),
		new Profil(TypeProfil.FOURNISSEUR),
	]

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { alerte: Condition },
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		/** Initialisation de la liste */
		this.listeProfils = new ListView<Profil,ProfilListItemComponent>({
			title: this.translateService.instant('workflow.conditions.alertes.detail.alerte.selectProfilsPopin.listTitle'),
			component: ProfilListItemComponent,
			isFrontendList: true,
			data: {
				listeResultats: this.listData,
				numPage: 0,
				nbPagesTotal: 1,
				nbObjetsTotal: this.listData.length,
				nbObjetsParPage: this.listData.length,
				nbObjetsDansPage: this.listData.length
			} as Page<Profil>,
			extraOptions: {
				alerte: this.data.alerte
			},
			listeActions: [{
				icon: 'done_all',
				onPress: () => this.listeProfils.selectAll(true),
				tooltip: this.translateService.instant('global.actions.toutSelectionner'),
				isVisible: () => !this.listeProfils?.data.listeResultats.every(s => s.isSelected)
			},{
				icon: 'remove_done',
				onPress: () => this.listeProfils.selectAll(false),
				tooltip: this.translateService.instant('global.actions.toutDeselectionner'),
				isVisible: () => this.listeProfils?.data.listeResultats.every(s => s.isSelected)
			}]
		});
	}

}
