<div class="with-floating-button">
    <div class="content">
        <page-header
                [hidden]="isHeaderHidden"
                [title]="'admin.voyages.travelhub.title' | translate"
                [listeItems]="listeTabItems"
                [selectedIndex]="selectedIndex"
                (onSelectedItemChange)="onSelectedItemChange($event)"
        ></page-header>

        <router-outlet></router-outlet>
    </div>
</div>
