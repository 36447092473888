/**
 * Engagements - Résumé
 * Représente une ligne du résumé
 */
import {EngagementsItem} from "../engagements-item";

export class EngagementsResumeItem extends EngagementsItem {
    /** Données */
    type: TypeEngagement;
    montantOrigine: number;
    montantDegrevement: number;
    montantDeviseTravel: number;

    constructor(item: EngagementsResumeItem,isTotal?: boolean) {
        super(isTotal);
        Object.assign(this,item);
    }

    /**
     * Retourne le type de l'item courant
     */
    getType(): number {
        return this.type;
    }

    /**
     * Retourne la traduction
     *
     * @param previsionnel Indique si l'item est prévisionnel (sinon il est réel)
     */
    getTraduction(previsionnel?: boolean): string {
        //Vérification d'une ligne de total
        if (this.isTotal) {
            //Vérification de la colonne prévisionnelle ou non
            if (previsionnel) {
                //Prévisionnel
                return 'od.engagements.previsionnel';
            } else {
                //Réel
                return 'od.engagements.reel';
            }
        } else {
            //Libelle associé au type
            return 'od.engagements.recapitulatif.type.' + TypeEngagement[this.type]?.toLowerCase();
        }
    }

}

/**
 * Enumeration des types de ligne du résumé
 */
export enum TypeEngagement {
    INDEMNITES = 1,
    FRAIS = 2,
    FACTURES = 3,
    AVANCES = 4,
}
