import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {UserContrat} from "@domain/user/user-contrat";
import {ToastrService} from "ngx-toastr";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Popin de création/modification d'un UserContrat
 */
@Component({
	host: {'data-test-id': 'user-contrat-detail-popin'},
	templateUrl: './user-contrat-detail-popin.component.html'
})
export class UserContratDetailPopinComponent implements OnInit {
	//Contrat utilisateur
	userContrat: UserContrat

	//Chargement en cours
	isPending: boolean;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param userService le service de gestion de l'utilisateur
	 * @param matDialogRef la ref de la popin
	 * @param toastrService le toasteur
	 * @param translateService le moteur de traduction
	 * @param confirmService le service de confirmation
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { userContrat: UserContrat },
		private userService: EntrepriseUtilisateursService,
		private matDialogRef: MatDialogRef<UserContratDetailPopinComponent>,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private confirmService: ConfirmService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération du contrat utilisateur
		this.userContrat = Object.assign({},this.data.userContrat);
	}

	/**
	 * Enregistrement d'un contrat utilisateur
	 */
	onSave(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.userService.saveContrat(this.userContrat)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popup
					this.matDialogRef.close(true);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression d'un contrat utilisateur
	 */
	onDelete(): void {
		//Confirmation auprès de l'utilisateur sur la suppression d'un compte bancaire
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Chargement en cours
					this.isPending = true;

					//Appel au service
					this.userService.deleteContrat(this.userContrat)
						.pipe(first(),finalize(() => this.isPending = false))
						.subscribe((result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast succès
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Fermeture de la popup
								this.matDialogRef.close(true);
							} else {
								//Gestion de l'erreur
								TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
							}
						});
				}
			});
	}
}
