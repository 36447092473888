import {Component} from '@angular/core';

import {AutocompleteLineItemComponent} from '../autocomplete-line-item.component';
import {Adresse} from "@domain/profil/adresse";

/**
 * Composant d'affichage d'une ligne adresse
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'adresse-line-item'},
    templateUrl: './adresse.line-item.html'
})
export class AdresseLineItemComponent extends AutocompleteLineItemComponent<Adresse> {

}