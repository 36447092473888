import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {ProfilService} from "../../../profil/profil.service";
import {MatRadioModule} from "@angular/material/radio";
import {DroitAdmin} from "@core/security/droit-admin";
import {NotificationMessagesComponent} from "./messages/notification-messages.component";
import {NotificationBalisesComponent} from "./balises/notification-balises.component";
import {NotificationRelancesComponent} from "./relances/notification-relances.component";
import {NotificationAccueilComponent} from "./accueil/notification-accueil.component";
import {NotificationMailsComponent} from "./mails/notification-mails.component";
import {BibliothequeNotificationsComponent} from "./bibliotheque-notifications.component";

export const bibliothequeNotificationsRoutes : Routes = [
    {
        path: 'Notifications',
        component: NotificationMessagesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_RELANCE_GESTION] }
    }, {
        path: 'Balises',
        component: NotificationBalisesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_RELANCE_GESTION] }
    }, {
        path: 'Relances',
        component: NotificationRelancesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_RELANCE_GESTION] }
    }, {
        path: 'Accueil',
        component: NotificationAccueilComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_RELANCE_GESTION] }
    }, {
        path: 'Mails',
        component: NotificationMailsComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Notifications'
    }, {
        path: '**',
        redirectTo: 'Notifications'
    }
];

@NgModule({
    imports: [ShareModule, PreviewAlerteModule, MatDividerModule, TranslateModule, MatRadioModule],
    declarations: [
        BibliothequeNotificationsComponent,NotificationMessagesComponent,NotificationBalisesComponent,NotificationRelancesComponent,NotificationAccueilComponent,NotificationMailsComponent
    ],
    entryComponents: [],
    exports: [
        NotificationMessagesComponent,NotificationBalisesComponent,NotificationRelancesComponent,NotificationAccueilComponent,NotificationMailsComponent
    ],
    providers: [ProfilService, LoginService]
})
export class BibliothequeNotificationsModule {
}
