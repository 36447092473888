<h1 mat-dialog-title>
    <span [translate]="'profilUser.infoContact.add.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="py-1">
    <form autocomplete="off" #addTelephoneForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input
                        [(ngModel)]="data.telephone.type"
                        [selectOptions]="listeTypeTelephone"
                        customType="select"
                        libelle="profilUser.infoContact.add.type.libelle"
                        name="typeTelephone"
                        ngDefaultControl
                        optionDisplay="libelle"
                        optionValue="type"
                        [required]="true">
                </custom-input>
                <label class="col-md-2 required"
                    [ngClass]="{ 'has-error': isNumeroChanged && addTelephoneForm.controls['telephoneObfusque']?.invalid || addTelephoneForm.controls['phone']?.invalid }"
                    [translate]="'profilUser.infoContact.add.numero'">
                </label>
                <!-- Input qui affiche le numéro obfusqué, non saisissable. Quand il reçoit le focus, il se cache pour laisser apparaitre le composant ngx-mat-intl-tel-input -->
                <custom-input [hidden]="isNumeroEditable"
                    ngDefaultControl
                    [ngModel]="data.telephone.numeroBrutObfusque"
                    id="telephoneObfusque"
                    name="telephoneObfusque"
                    [required]="true">
                </custom-input>

                <!-- Input qui permet la saisie et la validation d'un numéro de téléphone. Quand il perd le focus, si l'utilisateur n'a pas modifié le numéro, il se cache pour re-laisser apparaitre l'input ci-dessus -->
                <mat-form-field [hidden]="!isNumeroEditable" class="col-md-4" [ngClass]="{ 'mat-form-field-invalid': addTelephoneForm.controls['phone']?.invalid }">
                    <ngx-mat-intl-tel-input tabindex="0" id="idTelephone" #telephone [(ngModel)]="data.telephone.numeroBrut"
                        [enablePlaceholder]="false" [enableSearch]="true" [preferredCountries]="['fr']" [required]="true" format="national" 
                        name="phone" (input)="onTelephoneChanged()">
                    </ngx-mat-intl-tel-input>
                </mat-form-field>
            </div>
            <div class="row">
                <label [translate]="'profilUser.infoContact.add.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox color="primary" [(ngModel)]="data.telephone.actif" name="actif"></mat-checkbox>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button mat-stroked-button color="warn" *ngIf="data.telephone.idTelephone != 0" (click)="deleteTelephone()" [disabled]="isSaving || isDeleting">
        <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="save()" [disabled]="addTelephoneForm.invalid || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="data.telephone.idTelephone == 0 ? 'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
