import {ParticipantExterneParam} from "./participant-externe-param";

/**
 * Classe représentant le paramétrage des externes
 */
export class ExternesParams {
	actif: boolean;
	gestionNdf: boolean;
	saisieCollab: boolean;
	listeParam: ParticipantExterneParam[];
}