<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.participantInterne" class="icon-profil nio align-middle" [matTooltip]="'od.voyage.participants.interne' | translate"></i>
        <i *ngIf="data.participantExterne" class="material-icons-outlined" [matTooltip]="'od.voyage.participants.externe' | translate">people</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="this.extraOptions.canModifier ? extraOptions.showParticipant(data) : null">{{ data.participantExterne ? data.participantExterne.nom : data.participantInterne.nom }}
                - {{data.participantExterne ? data.participantExterne.prenom : data.participantInterne.prenom}}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.participantInterne?.matricule"><strong [translate]="'od.voyage.matricule'"></strong>{{ data.participantInterne.matricule }}</li>
            <li *ngIf="data.participantInterne?.orga || data.participantExterne?.societe">
                <strong>{{(data.participantInterne?.orga ? 'od.voyage.service' : 'od.voyage.societe') | translate}}</strong>{{ data.participantInterne?.orga ? data.participantInterne.orga.libelle : data.participantExterne.societe }}
            </li>
            <li *ngIf="data.notOrganisateur != null">
                <strong [translate]="'od.voyage.typeParticipant'"></strong>{{ (!data.notOrganisateur ? 'od.voyage.organisateur' : data.participantInterne != null ? 'od.voyage.participantInterne' : 'od.voyage.participantExterne') | translate }}
            </li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="this.extraOptions.canModifier && (extraOptions.isFromPopup || isCollabParticipePasAutorise || data.participantInterne?.idUser != extraOptions.idUserObjet)">
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button *ngIf="data.isFavoris && extraOptions.isFromPopup"  mat-menu-item (click)="retirerFavoris()" translate>od.voyage.participants.retraitFav</button>
            <button *ngIf="!data.isFavoris && extraOptions.isFromPopup" mat-menu-item (click)="ajouterFavoris()" translate>od.voyage.participants.ajoutFav</button>
            <button *ngIf="isCollabParticipePasAutorise === undefined || isCollabParticipePasAutorise || data.participantInterne?.idUser != extraOptions.idUserObjet" mat-menu-item (click)="retirerParticipant()" translate>od.voyage.participants.retraitMission</button>
        </mat-menu>
    </div>
</div>
