import {AfterViewInit,Component,EventEmitter,Input,OnDestroy,OnInit,Output,QueryList,TemplateRef,ViewChild,ViewChildren} from '@angular/core';
import {ProfilService} from "../profil.service";
import {Telephone} from "@domain/profil/telephone";
import {CarteVoyage} from "@domain/profil/carteVoyage";
import {AdresseListItem} from "@domain/profil/adresse";
import {TranslateService} from "@ngx-translate/core";
import {Document} from "@domain/profil/document";
import {CompteBancaire} from "@domain/profil/compteBancaire";
import {Vehicule} from "@domain/vehicule/vehicule";
import {ListView} from "@domain/common/list-view";
import {ProfilDonneesTelephoneListItemComponent} from "./telephone/profil-donnees-telephone-list-item.component";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ProfilDonneesAdresseListItemComponent} from "./adresse/profil-donnees-adresse-list-item.component";
import {ProfilDonneesDocumentListItemComponent} from "./document/profil-donnees-document-list-item.component";
import {ProfilDonneesCompteBancaireListItemComponent} from "./compteBancaire/profil-donnees-compte-bancaire-list-item.component";
import {CartePaiement} from "@domain/profil/cartePaiement";
import {ProfilDonneesCartePaiementListItemComponent} from "./cartePaiement/profil-donnees-carte-paiement-list-item.component";
import {ProfilDonneesCarteVoyageListItemComponent} from "./carteVoyage/profil-donnees-carte-voyage-list-item.component";
import {ProfilDonneesContactUrgenceListItemComponent} from "./contactUrgence/profil-donnees-contact-urgence-list-item.component";
import {MatDialog} from "@angular/material/dialog";
import {ProfilDonneesAddTelephoneComponent} from "./telephone/profil-donnees-add-telephone.component";
import {ProfilDonneesAddDocumentComponent} from "./document/profil-donnees-add-document.component";
import {SettingsGlobalState,SettingsVehiculeState} from "@domain/settings/settings";
import {ProfilDonneesAddCompteBancaireComponent} from "./compteBancaire/profil-donnees-add-compte-bancaire.component";
import {ProfilDonneesAddCartePaiementComponent} from "./cartePaiement/profil-donnees-add-carte-paiement.component";
import {filter,first,skip} from "rxjs/operators";
import {ProfilDonneesAddCarteVoyageComponent} from "./carteVoyage/profil-donnees-add-carte-voyage.component";
import {ProfilDonneesAddContactUrgenceComponent} from "./contactUrgence/profil-donnees-add-contact-urgence.component";
import {ProfilDonneesAddAdresseComponent} from "./adresse/profil-donnees-add-adresse.component";
import {ProfilDonneesAddVehiculeComponent} from "./vehicule/profil-donnees-add-vehicule.component";
import {Router} from "@angular/router";
import * as settingsActions from "../../../reducers/settings";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {ProfilDonneesVehiculeListItemComponent} from "./vehicule/profil-donnees-vehicule-list-item.component";
import {ToastrService} from "ngx-toastr";
import {TypeCompteBancaire} from '@domain/comptabilite/compte-bancaire'
import {TypeLieu} from '@domain/lieu/typeLieu';
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {BehaviorSubject,combineLatest,forkJoin,Subscription} from "rxjs";
import {ProfilVoyageur,ResultSynchro,TypeSaisie} from "@domain/profil/profilVoyageur";
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {LienUserProfilConnexion} from "@domain/profil/configAlerte";
import * as _ from 'lodash';
import {ProfilUser} from "@domain/profil/profilUser";
import {NiveauAlerte} from '@domain/common/alerte/alerte';
import {TypeContact} from '@domain/profil/typeContact';
import {ProfilDonneesPopupSuspensionRgpdComponent} from "@components/profil/donnees/popup-suspension-rgpd/profil-donnees-popup-suspension-rgpd.component";
import {TypeFormulaire} from "@domain/profil/DocumentAlerte";
import {TypeAdresseEnum,TypeAdresseRef} from "@domain/geolocalisation/type-adresse";

/**
 * Composant de gestion de l'onglet données du profil
 */
@Component({
	host: {'data-test-id': 'profil-donnees'},
	selector: 'profil-donnees',
	templateUrl: './profil-donnees.component.html',
	styleUrls: ['./profil-donnees.component.scss']
})
export class ProfilDonneesComponent implements OnInit,AfterViewInit,OnDestroy {

	/** Souscription aux observables */
	listeSubscription: Array<Subscription> = new Array<Subscription>();

	NiveauAlerte = NiveauAlerte;

	/** Subjects pour la détection et le stockage des différents paramètres requis dans la page */
	mainSettings: BehaviorSubject<SettingsGlobalState> = new BehaviorSubject<SettingsGlobalState>(undefined);
	vehiculeSettings: BehaviorSubject<SettingsVehiculeState> = new BehaviorSubject<SettingsVehiculeState>(undefined);

	/** Subject des alertes depuis le composant parent */
	@Input() alertes: BehaviorSubject<Result>;

	profilUser: ProfilUser;

	defaultCompte: CompteBancaire;

	/** Information sur le profil voyageur */
	profilVoyageur: ProfilVoyageur;

	/** Affiche le compte bancaire */
	isShowCompteBancaire: boolean = false;

	/** Affiche la carte de paiement */
	isShowCartePaiement: boolean = false;

	/** Niveau d'alerte pour les infos utilisateur */
	alertLevelInfoUser?: NiveauAlerte;

	listeTelephones: ListView<Telephone,ProfilDonneesTelephoneListItemComponent>

	listeCarteVoyage: ListView<CarteVoyage,ProfilDonneesCarteVoyageListItemComponent>;

	listeDocument: ListView<Document,ProfilDonneesDocumentListItemComponent>;

	listeAdresse: ListView<AdresseListItem,ProfilDonneesAdresseListItemComponent>;

	listeCartePaiement: ListView<CartePaiement,ProfilDonneesCartePaiementListItemComponent>;

	listeVehicule: ListView<Vehicule,ProfilDonneesVehiculeListItemComponent>;

	listeCompteBancaire: ListView<CompteBancaire,ProfilDonneesCompteBancaireListItemComponent>;

	listeContactUrgence: ListView<Telephone,ProfilDonneesContactUrgenceListItemComponent>;

	/** Liste des composants de type ListView */
	@ViewChildren(ListViewComponent)
	listeListView: QueryList<ListViewComponent<any,any>>;

	/** Popup de synchronisation du profil */
	@ViewChild('synchroTemplate') synchroTemplate: TemplateRef<any>;

	/** Évènement déclenché après que toutes les ListView soient chargées */
	@Output()
	onAllListeLoaded: EventEmitter<void> = new EventEmitter<void>();

	/** Évènement déclenché après la synchronisation du profil, pour mettre à jour l'état des synchronisations et/ou le statut global dans la barre d'onglets */
	@Output()
	onSynchroChanged: EventEmitter<SynchroSBTConfigUser[] | LienUserProfilConnexion> = new EventEmitter<SynchroSBTConfigUser[] | LienUserProfilConnexion>();

	@Output()
	refreshAlertes: EventEmitter<void> = new EventEmitter();

	constructor(
		private profilService: ProfilService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private matDialog: MatDialog,
		private router: Router,
		private store: Store<AppState>
	) {
	}

	ngOnInit(): void {

		//Chargement du paramétrage global
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: ['Global']
		});
		this.listeSubscription.push(this.store.select(state => state.settings?.['Global']).subscribe(settings => this.mainSettings.next(settings)));

		//Chargement du paramétrage des véhicules
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.VP
		});
		this.listeSubscription.push(this.store.select(state => state.settings?.[TypePortee.VP]).subscribe(settings => this.vehiculeSettings.next(settings)));

		//Attente du chargement de tous les éléments essentiels à la page
		combineLatest([
			this.mainSettings.pipe(filter(settings => !!settings)),
			this.vehiculeSettings.pipe(filter(settings => !!settings)),
			this.alertes.pipe(filter(alertes => !!alertes))
		]).pipe(first()).subscribe(() => {

			//Définition des listes bancaires
			this.initComptebancaire();
			this.initCartePaiement();

			//Définition de la liste des téléphones
			this.listeTelephones = new ListView<Telephone,ProfilDonneesTelephoneListItemComponent>({
				uri: `/controller/ProfilUser/listeTelephone`,
				title: this.translateService.instant('profilUser.infoContact.title'),
				component: ProfilDonneesTelephoneListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openTelephone(new Telephone())
				}],
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeTelephone
				},
				extraOptions: {
					open: (telephone) => this.openTelephone(telephone)
				}
			});

			//Définition de la liste des adresses
			this.listeAdresse = new ListView<AdresseListItem,ProfilDonneesAdresseListItemComponent>({
				uri: `/controller/ProfilUser/listeAdresse`,
				title: this.translateService.instant('profilUser.adresses.title'),
				component: ProfilDonneesAdresseListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openAdresse(new AdresseListItem())
				}],
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeAdresse
				},
				extraOptions: {
					open: (adresse) => this.openAdresse(adresse)
				}
			});

			//Définition de la liste des documents
			this.listeDocument = new ListView<Document,ProfilDonneesDocumentListItemComponent>({
				uri: `/controller/ProfilUser/listeDocument`,
				title: this.translateService.instant('profilUser.document.title'),
				component: ProfilDonneesDocumentListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openProfilDocument(new Document())
				}],
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeDocument
				},
				extraOptions: {
					open: (document) => this.openProfilDocument(document),
					settings: this.mainSettings.getValue().profilAlerteConfig
				}
			});

			//Définition de la liste des cartes de fidélité
			this.listeCarteVoyage = new ListView<CarteVoyage,ProfilDonneesCarteVoyageListItemComponent>({
				uri: `/controller/ProfilUser/listeCarteVoyage`,
				title: this.translateService.instant('profilUser.carteAbonnement.title'),
				component: ProfilDonneesCarteVoyageListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openCarteVoyage(new CarteVoyage())
				}],
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeCarte
				},
				extraOptions: {
					open: (carte) => this.openCarteVoyage(carte),
					settings: this.mainSettings.getValue().profilAlerteConfig
				}
			});

			//Définition de la liste des contacts d'urgence
			this.listeContactUrgence = new ListView<Telephone,ProfilDonneesContactUrgenceListItemComponent>({
				uri: `/controller/ProfilUser/listeContactUrgence`,
				title: this.translateService.instant('profilUser.contactUrgence.title'),
				component: ProfilDonneesContactUrgenceListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openContactUrgence(new Telephone())
				}],
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeContact
				},
				extraOptions: {
					open: (telephone) => this.openContactUrgence(telephone)
				}
			});

			//Définition de la liste des véhicules
			this.listeVehicule = new ListView<Vehicule,ProfilDonneesVehiculeListItemComponent>({
				uri: `/controller/ProfilUser/getListeVehicule`,
				title: this.translateService.instant('profilUser.vehicule.title'),
				component: ProfilDonneesVehiculeListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openVehicule()
				}],
				isSimple: true,
				mapResult: (result: Result) => {
					return result?.data?.listeVehicule
				},
				extraOptions: {
					settings: this.vehiculeSettings.getValue()
				}
			});

			//Traitement de l'affichage des alertes quand toutes les listes sont chargées
			combineLatest([
				this.listeCartePaiement && this.listeCartePaiement.loaded,
				this.listeCompteBancaire && this.listeCompteBancaire.loaded,
				this.listeVehicule.loaded,
				this.listeDocument.loaded,
				this.listeAdresse.loaded,
				this.listeTelephones.loaded,
				this.listeContactUrgence.loaded,
				this.listeCarteVoyage.loaded,
				this.alertes
			].filter(o => !!o)).pipe(first()).subscribe({next: () => this.handleAlertes()});
		});

		//Chargement des infos de profil de l'utilisateur (nom/prénom etc...)
		this.profilService.loadInfosProfilUser().pipe(first()).subscribe(res => {
			this.profilUser = res.data.profilUser;
		});

		//Récupération des infos sur le profil voyageur
		this.loadProfilVoyageur();
	}

	/**
	 * Récupération des infos sur le profil voyageur
	 */
	loadProfilVoyageur() {
		this.profilService.getSynchroProfilVoyageur().pipe(first()).subscribe(res => {
			this.profilVoyageur = res.data;

			//Vérification de la présence du profil voyageur
			if (this.profilVoyageur) {
				//Mise à jour de la liste des synchronisations
				this.profilVoyageur.listeAllSynchro = res.data.listeSynchro;
				//Mise à jour de la liste des synchronisations distinctes par SBT
				this.profilVoyageur.listeSynchro = this.getListeSynchro(res.data.listeSynchro);

				//Mise à jour de l'état des synchronisations dans la barre d'onglets
				this.updateLibelleSynchro();
			}
		});
	}

	/**
	 * Traitement à faire après le chargement du composant et des composants enfants
	 */
	ngAfterViewInit() {
		//Attente du premier évènement de fin de chargement de chaque liste
		forkJoin(this.listeListView?.map(item => item.onLoadEnd.pipe(first()))).subscribe(() => {
			//Déclenchement de l'évènement indiquant que toutes les listes sont chargées
			this.onAllListeLoaded.emit();
		});
	}

	/**
	 * Traite l'affichage des alertes
	 */
	handleAlertes(): void {
		//Initialisation des alertes des listes
		this.alertLevelInfoUser = undefined;
		if (this.listeVehicule) {
			this.listeVehicule.alertLevel = undefined
		}
		if (this.listeDocument) {
			this.listeDocument.alertLevel = undefined
		}
		if (this.listeCartePaiement) {
			this.listeCartePaiement.alertLevel = undefined
		}
		if (this.listeCarteVoyage) {
			this.listeCarteVoyage.alertLevel = undefined
		}
		if (this.listeAdresse) {
			this.listeAdresse.alertLevel = undefined
		}
		if (this.listeTelephones) {
			this.listeTelephones.alertLevel = undefined
		}
		if (this.listeContactUrgence) {
			this.listeContactUrgence.alertLevel = undefined
		}
		if (this.listeCompteBancaire) {
			this.listeCompteBancaire.alertLevel = undefined
		}

		//Traitement des alertes non bloquantes de documents arrivant à échéance
		if (this.alertes.getValue()?.data?.listeDocumentEcheance?.length) {
			for (const document of this.alertes.getValue()?.data?.listeDocumentEcheance) {
				switch (document.typeFormulaire) {
					case TypeFormulaire.VEHICULE:
						if (this.listeVehicule) {
							this.listeVehicule.alertLevel = NiveauAlerte.WARNING
						}
						break;
					case TypeFormulaire.PIECE_IDENTITE:
						if (this.listeDocument) {
							this.listeDocument.alertLevel = NiveauAlerte.WARNING
						}
						break;
					case TypeFormulaire.CARTE_PAIEMENT:
						if (this.listeCartePaiement) {
							this.listeCartePaiement.alertLevel = NiveauAlerte.WARNING
						}
						break;
					case TypeFormulaire.CARTE_ABO_FID:
						if (this.listeCarteVoyage) {
							this.listeCarteVoyage.alertLevel = NiveauAlerte.WARNING
						}
						break;
					default:
						break;
				}
			}
		}

		//Traitement des alertes bloquantes de documents échus
		if (this.alertes.getValue()?.data?.listeDocumentEchu?.length) {
			for (const document of this.alertes.getValue()?.data?.listeDocumentEchu) {
				switch (document.typeFormulaire) {
					case TypeFormulaire.VEHICULE:
						if (this.listeVehicule) {
							this.listeVehicule.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case TypeFormulaire.PIECE_IDENTITE:
						if (this.listeDocument) {
							this.listeDocument.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case TypeFormulaire.CARTE_PAIEMENT:
						if (this.listeCartePaiement) {
							this.listeCartePaiement.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case TypeFormulaire.CARTE_ABO_FID:
						if (this.listeCarteVoyage) {
							this.listeCarteVoyage.alertLevel = NiveauAlerte.ERROR
						}
						break;
					default:
						break;
				}
			}
		}

		//Traitement des alertes bloquantes de documents manquants
		if (this.alertes.getValue()?.data?.saisieObligatoire?.listeSaisieManquante?.length) {
			for (const saisie of this.alertes.getValue()?.data?.saisieObligatoire?.listeSaisieManquante) {
				switch (saisie) {
					case "INFORMATION_VOYAGEUR":
						this.alertLevelInfoUser = NiveauAlerte.ERROR;
						break;
					case "ADRESSE":
						if (this.listeAdresse) {
							this.listeAdresse.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case "TELEPHONE":
						if (this.listeTelephones) {
							this.listeTelephones.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case "CONTACT_URGENCE":
						if (this.listeContactUrgence) {
							this.listeContactUrgence.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case "COMPTE_BANCAIRE":
						if (this.listeCompteBancaire) {
							this.listeCompteBancaire.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case "VEHICULE":
						if (this.listeVehicule) {
							this.listeVehicule.alertLevel = NiveauAlerte.ERROR
						}
						break;
					case "PIECE_IDENTITE":
						if (this.listeDocument) {
							this.listeDocument.alertLevel = NiveauAlerte.ERROR
						}
						break;
					default:
						break;
				}
			}
		}
	}

	/**
	 * Renvoie la liste des synchros uniques par SBT.
	 *
	 * @param listeSynchro liste de toutes les synchros
	 * @return la liste des synchros uniques
	 */
	getListeSynchro(listeSynchro: SynchroSBTConfigUser[]): SynchroSBTConfigUser[] {
		return listeSynchro.filter((synchro,i,arr) => {
			return arr.findIndex(s => s.idSBTConfig === synchro.idSBTConfig) === i;
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des téléphones
	 * @param telephone Téléphone
	 */
	private openTelephone(telephone: Telephone) {
		this.matDialog.open(ProfilDonneesAddTelephoneComponent,{
			data: {
				telephone: _.cloneDeep(telephone),
				mobileActif: this.listeTelephones.data.listeResultats.filter(value => value.type == TypeContact.MOBILE && value.actif).length > 0,
				proActif: this.listeTelephones.data.listeResultats.filter(value => value.type == TypeContact.TELEPHONE_PROFESSIONNEL && value.actif).length > 0,
				persoActif: this.listeTelephones.data.listeResultats.filter(value => value.type == TypeContact.TELEPHONE_PERSONNEL && value.actif).length > 0
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement des infos sur le profil voyageur
					this.loadProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeTelephones.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.rafraichirAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeTelephones.refresh();
				}
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des contacts d'urgence
	 * @param telephone Téléphone
	 */
	private openContactUrgence(telephone: Telephone) {
		const contactActifListe = this.listeContactUrgence.data.listeResultats.filter(value => value.type == "CONTACT_URGENCE" && value.actif);
		const emailActifListe = this.listeContactUrgence.data.listeResultats.filter(value => value.type == "EMAIL_ASSISTANT" && value.actif);

		this.matDialog.open(ProfilDonneesAddContactUrgenceComponent,{
			data: {
				telephone: {...telephone},
				idContactActif: contactActifListe.length == 0 ? 0 : contactActifListe[0].id,
				idEmailActif: emailActifListe.length == 0 ? 0 : emailActifListe[0].id
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement des infos sur le profil voyageur
					this.loadProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeContactUrgence.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.rafraichirAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeContactUrgence.refresh();
				}
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des adresses
	 * @param adresse Adresse
	 */
	private openAdresse(adresse: AdresseListItem) {
		this.matDialog.open(ProfilDonneesAddAdresseComponent,{
			data: {
				adresse: {...adresse},
				listeAdresse: this.listeAdresse.data.listeResultats,
				settings: this.mainSettings.getValue(),
				typeLieu: TypeLieu.TYPE_LIEU_RESIDENCE_FAMILIALE,
				idPortee: TypePortee.PRF
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement des infos sur le profil voyageur
					this.loadProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeAdresse.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.rafraichirAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeAdresse.refresh();
				}
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des documents
	 * @param document Document
	 */
	private openProfilDocument(document: Document) {
		this.matDialog.open(ProfilDonneesAddDocumentComponent,{
			data: {
				document: document,
				settings: this.mainSettings.getValue(),
				infoSociete: this.profilUser
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement des infos sur le profil voyageur
					this.loadProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeDocument.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.rafraichirAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeDocument.refresh();
				}
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des comptes bancaires
	 * @param compte CompteBancaire
	 */
	private openCompteBancaire(compte: CompteBancaire) {
		this.matDialog.open(ProfilDonneesAddCompteBancaireComponent,{
			data: {
				compte: compte,
				compteParDefaut: this.listeCompteBancaire.data.listeResultats.find(c => c.defaut),
				settings: this.mainSettings.getValue()
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement des infos sur le profil voyageur
					this.loadProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeCompteBancaire.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.rafraichirAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeCompteBancaire.refresh();
				}
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des cartes de paiement
	 * @param carte CartePaiement
	 */
	private openCartePaiement(carte: CartePaiement) {
		this.profilService.loadCartePaiement(carte).pipe(first()).subscribe({
			next: result => {
				this.matDialog.open(ProfilDonneesAddCartePaiementComponent,{
					data: {
						listeContrat: result.data?.listeContrat,
						listeSynchro: result.data?.listeSynchro,
						carte: result.data && result.data.cartePaiement ? result.data.cartePaiement : carte,
						configCompte: this.mainSettings.getValue().configCompte
					},
					width: '80%'
				}).afterClosed().subscribe({
					next: refresh => {
						if (refresh) {
							//Rafraichissement des infos sur le profil voyageur
							this.loadProfilVoyageur();

							//Abonnement sur l'évènement de fin de rafraichissement de la liste
							this.listeCartePaiement.loaded.pipe(first()).subscribe(() => {
								//Rafraichissement des alertes
								this.rafraichirAlertes();
							});

							//Déclenchement du rafraichissement de la liste
							this.listeCartePaiement.refresh();
						}
					}
				});
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des cartes de voyage
	 * @param carte CarteVoyage
	 */
	private openCarteVoyage(carte: CarteVoyage) {
		this.matDialog.open(ProfilDonneesAddCarteVoyageComponent,{
			data: {
				carte: _.cloneDeep(carte),
				settings: this.mainSettings.getValue()
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement des infos sur le profil voyageur
					this.loadProfilVoyageur();

					//Abonnement sur l'évènement de fin de rafraichissement de la liste
					this.listeCarteVoyage.loaded.pipe(first()).subscribe(() => {
						//Rafraichissement des alertes
						this.rafraichirAlertes();
					});

					//Déclenchement du rafraichissement de la liste
					this.listeCarteVoyage.refresh();
				}
			}
		});
	}

	/**
	 * Ouverture de la fenêtre d'ajout/édition des véhicules
	 */
	private openVehicule() {
		this.profilService.loadPuissanceFiscale().pipe(first()).subscribe({
			next: result => {
				this.matDialog.open(ProfilDonneesAddVehiculeComponent,{
					data: {listePuissance: result.data.listePuissance},
					width: '80%'
				}).afterClosed().subscribe({
					next: data => {
						//Vérification des données pour la redirection vers la page du véhicule
						if (data) {
							//Redirection vers la page du véhicule
							this.router.navigate(['Profil/Vehicule/' + data.vehicule.idPa]);
						}
					}
				});
			}
		});
	}

	/**
	 * Lance un export XML du profil dans un nouvel onglet
	 */
	exportXML() {
		window.open(this.profilService.getExportUrl(),"_blank")
	}

	/**
	 * Vérification des profils de connexion SBT, pour savoir si le bouton de synchro peut s'afficher.
	 */
	public isProfilConnexionConfigured(): boolean {
		//Vérification des profils de connexion
		return this.profilVoyageur && (this.profilVoyageur.profilConnexionCollab != null || this.profilVoyageur.profilConnexionParDefaut != null);
	}

	/**
	 * Vérifie si on doit afficher le bouton de synchronisation du profil.
	 */
	public canSeeSynchronisation(): boolean {
		//Vérification de la synchronisation (présence d'un SBT)
		return this.profilVoyageur && this.profilVoyageur.listeAllSynchro.some(synchro => synchro.idSBTConfig > 0);
	}

	/**
	 * Synchronisation du profil voyageur.
	 */
	public synchronizeProfil() {
		//Popup de synchronisation de profil en cours
		const matDialogRef = this.matDialog.open(this.synchroTemplate,{
			disableClose: true,
			width: '600px',
			position: {
				top: '100px'
			},
			role: 'alertdialog',
		});

		//Appel du service de synchro
		this.profilService.syncProfilVoyageur().pipe(first()).subscribe({
				next: result => {
					//Si la synchro n'est pas autorisée
					if (result?.codeErreur === 901) {
						//Message d'info RGPD
						this.toastrService.warning(this.translateService.instant('profilUser.infoPerso.synchroPV.synchroImpossibleRGPD'),
							this.translateService.instant('profilUser.infoPerso.synchroPV.synchroImpossibleRGPDTitle'),
							{toastClass: "ngx-toastr toast-rgpd"});

						//Fermeture de la popup dans 1s, parce que faut laisser un peu le temps de l'admirer
						setTimeout(() => matDialogRef.close(),1000);

					} else if (result?.data.listeSynchro) {
						//Fermeture de la popup dans 1s
						setTimeout(() => matDialogRef.close(),1000);

						//Mise à jour de la liste des synchronisations
						this.profilVoyageur.listeAllSynchro = result.data.listeSynchro;
						//Mise à jour de la liste des synchronisations distinctes par SBT
						this.profilVoyageur.listeSynchro = this.getListeSynchro(result.data.listeSynchro);

						//Construction d'un objet typé LienUserProfilConnexion à partir de result.data
						const lienProfilConnexion = Object.assign(new LienUserProfilConnexion(),result.data.lienProfilConnexion);
						//Mise à jour de l'état des synchronisations et l'état global dans la barre d'onglets
						this.updateLibelleSynchro(lienProfilConnexion);

						//On gère les toasts de la synchro
						this.profilService.resultSynchroToast(<ResultSynchro[]>result.data.listeResultSynchro);

						//Mise à jour de la liste de documents et des cartes de voyage, car leur statut peut changer après synchro
						this.listeDocument.refresh();
						this.listeCarteVoyage.refresh();

						//Rafraichissement de l'affichage des alertes
						this.rafraichirAlertes();
					} else {
						//Erreur technique
						matDialogRef.close();
						this.toastrService.error(this.translateService.instant('profilUser.infoPerso.synchroPV.erreurSynchroTech'));
					}
				},
				error: () => {
					//Erreur technique
					matDialogRef.close();
					this.toastrService.error(this.translateService.instant('profilUser.infoPerso.synchroPV.erreurSynchroTech'));
				}
			}
		);
	}

	/**
	 * Rafraichir l'affichage des alertes
	 */
	rafraichirAlertes(): void {
		//Rafraichissement de l'affichage des alertes (on ignore la valeur retournée dès l'abonnement)
		this.alertes.pipe(skip(1),first()).subscribe(() => this.handleAlertes());

		//Rafraichissement des alertes
		this.refreshAlertes.emit();
	}

	/**
	 * Renvoie la liste des SBT synchronisés.
	 */
	public get listeSynchroEffectuee(): SynchroSBTConfigUser[] {
		return this.profilVoyageur.listeSynchro.filter(s => s.synchro);
	}

	/**
	 * Emet 1 (ou 2) message(s) vers le controller parent pour mettre à jour les états de synchro des SBT, et l'état de synchro global.
	 *
	 * @param lienProfilConnexion donne l'état de synchro global du profil de connexion de l'utilisateur
	 */
	public updateLibelleSynchro(lienProfilConnexion?: LienUserProfilConnexion): void {
		let listeSynchro: SynchroSBTConfigUser[] = null;

		//Construit la liste des synchros des différents SBT
		if (this.isProfilConnexionConfigured() && this.listeSynchroEffectuee.length > 0) {
			listeSynchro = this.listeSynchroEffectuee
				.filter(synchro => synchro.idSBTConfig > 0)
				.map(synchro => {
					synchro.message = this.profilVoyageur.profilConnexionCollab.code + " (" + synchro.codeConfig + ")";
					return synchro;
				});
		}
		//Envoie l'évènement
		this.onSynchroChanged.emit(listeSynchro);

		//Si un lienProfilConnexion est donné
		if (lienProfilConnexion) {
			//Envoi de l'évènement
			this.onSynchroChanged.emit(lienProfilConnexion);
		}
	}

	/**
	 * Permet de changer le statut d'accès au profil
	 */
	changeAccessStatus() {
		if (this.profilUser.rgpdSuspendu) {
			//Changement de l'accès
			this.profilService.changeAccessStatus().pipe(first()).subscribe(res => {
				if (res.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Mise à jour
					this.profilUser.rgpdSuspendu = res?.data?.isRgpdSuspendu;

					//Rafraichissement des alertes
					this.refreshAlertes.emit();
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			});
		} else {
			//Ouverture de la popin de confirmation de suspension d'accès aux données personnelles
			this.matDialog.open(ProfilDonneesPopupSuspensionRgpdComponent,{data: this.profilUser})
				.afterClosed().pipe(first()).subscribe(isSuspended => {
				//Vérification de la suspension d'accès au profil
				if (isSuspended) {
					//Rafraichissement des alertes
					this.refreshAlertes.emit();
				}
			});
		}
	}

	/**
	 * Chargement du compte bancaire par défaut qui est utilisé en cas de création
	 */
	loadDefaultCompte() {
		this.profilService.loadDefaultCompte().pipe(first()).subscribe(res => {
			const data = res.data;

			this.defaultCompte = new CompteBancaire();
			this.defaultCompte.typeCompte = TypeCompteBancaire.IBAN;
			this.defaultCompte.beneficiaire = data.beneficiaire;
			this.defaultCompte.idPays = data.pays.id;
			this.defaultCompte.devise = data.devise.code;
		});
	}


	/**
	 * Initialisation du compte bancaire
	 */
	initComptebancaire() {
		this.isShowCompteBancaire = this.mainSettings.getValue().configCompte.actif;

		if (this.isShowCompteBancaire) {
			this.loadDefaultCompte();

			//Définition de la liste des comptes bancaires
			this.listeCompteBancaire = new ListView<CompteBancaire,ProfilDonneesCompteBancaireListItemComponent>({
				uri: `/controller/ProfilUser/Compte/listeCompte`,
				title: this.translateService.instant('profilUser.compteBancaire.title'),
				component: ProfilDonneesCompteBancaireListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openCompteBancaire(Object.assign({},this.defaultCompte))//Clone et assigne le compte par défaut
				}],
				isSimple: true,
				mapResult: (result: Result) => result?.data?.listeCompte,
				extraOptions: {
					open: (compte: CompteBancaire) => this.openCompteBancaire(Object.assign({},compte))
				}
			});
		}
	}

	/**
	 * Initialisation de la carte bancaire
	 */
	initCartePaiement() {
		this.isShowCartePaiement = this.mainSettings.getValue().canSeeCartePaiement && this.mainSettings.getValue().configCompte.cartePaiement;

		if (this.isShowCartePaiement) {
			//Définition de la liste des cartes de paiement
			this.listeCartePaiement = new ListView<CartePaiement,ProfilDonneesCartePaiementListItemComponent>({
				uri: `/controller/ProfilUser/listeCartePaiement`,
				title: this.translateService.instant('profilUser.cartePaiement.title'),
				component: ProfilDonneesCartePaiementListItemComponent,
				listeActions: [{
					icon: "add",
					onPress: () => this.openCartePaiement(new CartePaiement())
				}],
				isSimple: true,
				mapResult: (result: Result) => result?.data?.listeCartePaiement,
				extraOptions: {
					open: (carte: CartePaiement) => this.openCartePaiement(carte),
					settings: this.mainSettings.getValue().profilAlerteConfig
				}
			});
		}
	}

	/**
	 * Indique si on affiche le tooltip des infos obligatoires
	 */
	isShowToolTipInfoObligatoireSyncro() {
		//Si on a au moins une autre alerte que le profil voyageur
		return this.profilVoyageur?.saisieObligatoire.listeSaisieManquante.length > 1
			|| (this.profilVoyageur?.saisieObligatoire.listeSaisieManquante.length == 1
				&& !this.profilVoyageur?.saisieObligatoire.listeSaisieManquante.includes(TypeSaisie.COMPTE_BANCAIRE));
	}

	ngOnDestroy() {
		//Désabonnements
		this.listeSubscription.forEach(sub => sub.unsubscribe());
	}
}
