<h1 mat-dialog-title>
    <span translate>od.frais.factures.creation.facturePrev.title</span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>
    <form autocomplete="off" #facturePrevAddForm="ngForm">
        <fieldset *ngIf="!isLoading" [disabled]="!data.canModifier">
            <div class="row">
                <div class="col-md-6">
                    <div class="row h-100" [ngClass]="{ 'has-error': typeFacture.invalid }">
                        <label class="col-md-4 required">
                            <span [translate]="'od.frais.factures.creation.facturePrev.type'"></span>
                        </label>
                        <div class="col-md-8 r-flex align-items-center">
                            <mat-radio-group #typeFacture="ngModel" class="r-flex ml-n2" name="typeFacture" [(ngModel)]="data.facturePrev.typeFacture" [required]="true">
                                <mat-radio-button class="ml-2" *ngFor="let typeFactPrev of TypeFactPrev.values()" [value]="typeFactPrev">{{ TypeFactPrev.traduction(typeFactPrev) | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" [ngClass]="{ 'has-error': fournisseur.invalid }">
                        <label class="col-md-4">
                            <span [translate]="'od.frais.factures.creation.facturePrev.fournisseur'"></span>
                        </label>
                        <div class="col-md-8">
                            <autocomplete type="fournisseur" name="fournisseur" ngDefaultControl #fournisseur="ngModel"
                                          [filter]="{ raisonSociale: data.facturePrev?.fournisseur?.raisonSociale }"
                                          [(ngModel)]="data.facturePrev.fournisseur"
                                          [floatLabel]="'never'" [label]="'od.frais.factures.creation.facturePrev.fournisseur' | translate"
                                          [disabled]="!data.canModifier"
                                          (onSelect)="onFournisseurChange($event)"></autocomplete>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row" [ngClass]="{ 'has-error': prestation.invalid }">
                        <label class="col-md-4 required">
                            <span [translate]="'od.frais.factures.creation.facturePrev.prestation'"></span>
                        </label>
                        <div class="col-md-8">
                            <autocomplete type="prestation" name="prestation" required="true" ngDefaultControl #prestation="ngModel"
                                          [filter]="{ isFacturePrevisionnelle: true,typeObjet: TypePortee.OD,idObjet: data.od.idOd }"
                                          [(ngModel)]="data.facturePrev.prestation"
                                          [floatLabel]="'never'" [label]="'od.frais.factures.creation.facturePrev.prestation' | translate"
                                          [disabled]="!data.canModifier"
                                          (onSelect)="onPrestationChange($event)"></autocomplete>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" [ngClass]="{ 'has-error': quantite.invalid }">
                        <label class="col-md-4" [ngClass]="{ 'required': data.facturePrev.prestation?.quantiteObligatoire }">
                            <span [translate]="'od.frais.factures.creation.facturePrev.quantite'"></span>
                        </label>
                        <div class="col-md-4">
                            <mat-form-field class="d-flex">
                                <input name="quantite" matInput #quantite="ngModel" [(ngModel)]="data.facturePrev.quantite" [nNumber]="2" 
                                    [required]="data.facturePrev.prestation?.quantiteObligatoire" 
                                    [readonly]="!data.facturePrev.prestation?.quantiteModifiable"/>
                                <span matSuffix *ngIf="data.facturePrev.prestation?.unite" class="p-l-5">{{ data.facturePrev.prestation?.unite.libelle }}</span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <label class="col-md-4">
                            <span [translate]="'od.frais.factures.creation.facturePrev.nature'"></span>
                        </label>
                        <div class="col-md-8">
                            <mat-form-field class="d-flex">
                                <mat-select #nature="ngModel" name="nature" [compareWith]="Nature.compareTo" [(ngModel)]="data.facturePrev.nature" [disabled]="!data.canModifier">
                                    <mat-select-trigger>
                                        <ng-container *ngTemplateOutlet="natureOption;context:{nature:nature.model}"></ng-container>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let nature of Nature.values()" [value]="nature">
                                        <ng-container *ngTemplateOutlet="natureOption;context:{nature:nature}"></ng-container>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" [ngClass]="{ 'has-error': montant.invalid }">
                        <label class="col-md-4 required">
                            <span [translate]="'od.frais.factures.creation.facturePrev.montant'"></span>
                        </label>
                        <div class="col-md-8">
                            <div class="d-flex">
                                <mat-form-field>
                                    <input name="montant" matInput #montant="ngModel" [(ngModel)]="data.facturePrev.montant" nNumber="2" class="m-r-10" required/>
                                    <mat-select #devise name="devise" [(ngModel)]="data.facturePrev.devise" [disabled]="!data.canModifier">
                                        <mat-option *ngFor="let devise of data.settings.listeDevises" [value]="devise.code">{{ devise.code }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': remarque.invalid }">
                <label class="col-md-2" [ngClass]="{ 'required': data?.facturePrev?.prestation?.remarqueObligatoire }">
                    <span>{{ prestation?.model?.libelleRemarque || ('od.frais.factures.creation.facturePrev.remarque' | translate) }}</span>
                </label>
                <div class="col-md-10">
                    <mat-form-field class="d-flex">
                        <textarea matInput #remarque="ngModel" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" name="remarque" [(ngModel)]="data.facturePrev.remarque" [required]="data?.facturePrev?.prestation?.remarqueObligatoire"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <button mat-stroked-button color="primary" (click)="deleteFacturePrev()" [disabled]="isProcessing || !data.canModifier" *ngIf="data.facturePrev.idFacturePrev">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="facturePrevAddForm.invalid || isProcessing || !data.canModifier" (click)="saveFacturePrev()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
<ng-template #natureOption let-nature="nature">
    <span *ngIf="Nature.icone(nature)" class="pr-1" [innerHTML]="Nature.icone(nature)"></span><span class="align-middle">{{ Nature.traduction(nature) | translate }}</span>
</ng-template>