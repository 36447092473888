import {TypeGeolocalisation} from "./typeGeolocalisation";

/**
 * Paramètres de géolocalisation
 */
export interface GeolocalisationParams {
    typeGeolocalisation: TypeGeolocalisation;
    typeCalculDistancier: TypeCalculDistancier;
    typeCiblage: TypeCiblage;
}

/**
 * Enumération représentant les types de calculs du distancier.
 */
export enum TypeCalculDistancier {
    CONSEIL_SITE = 'CONSEIL_SITE',
    RAPIDE = 'RAPIDE',
    COURT = 'COURT'
}

/**
 * Enumération représentant les niveaux de ciblage.
 */
export enum TypeCiblage {
    VILLE = 'VILLE',
    ADRESSE = 'ADRESSE'
}