<!-- Entête -->
<h1 mat-dialog-title>
    <i *ngIf="!etapeExistante" class="nio icon-transfere clickable" (click)="goBack()"></i>
    <span>
        <span [translate]="TypeNature.traduction(sbt.idNature)"></span>&nbsp;:&nbsp;<span>{{sbt.libelle}}</span>
    </span>
    <span class="actions" [mat-dialog-close]="true">
        <span><i class="zmdi zmdi-close"></i></span>
    </span>
</h1>

<!-- Contenu -->
<div mat-dialog-content>
    <mat-vertical-stepper #stepper (animationDone)="addEtape();">
        <mat-step *ngFor="let etape of listeEtapes; let index = index" [errorMessage]="getErrorMessage(etapeForm)" [hasError]="etapeForm.invalid">
            <form autocomplete="off" #etapeForm="ngForm">
                <!-- Formulaire pour les NON documents -->
                <ng-container *ngIf="sbt.idNature !== TypeNature.DOCUMENT">
                    <ng-template matStepLabel>
                        <strong *ngIf="etape.idEtape !== 0">
                            <span>{{ (etape.origine?.libelle ? etape.origine.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (etape.origine?.pays?.libelle ? etape.origine.pays.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}</span>
                            <span *ngIf="sbt.idNature !== TypeNature.HEBERGEMENT">
                                {{' - ' + (etape.destination?.libelle ? etape.destination.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (etape.destination?.pays?.libelle ? etape.destination.pays.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}
                            </span>
                        </strong>
                        <br>
                        <span *ngIf="(etape.typeDepartAller != null || ![TypeNature.AVION, TypeNature.TRAIN].includes($any(sbt.idNature))) && etape.jourDepartAller && etape.heureDepartAller">
                            {{listeTypeDepart[etape.typeDepartAller]?.libelle + ' : ' + (etape.jourDepartAller.toDate() | date:'shortDate') + ' ' + etape.heureDepartAller}}
                        </span>
                    </ng-template>
                    <fieldset [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked">
                        <!-- Origine + Aller -->
                        <div class="row">
                            <custom-input *ngIf="origineBackup || etape.idEtape === 0" ngDefaultControl name="origine"
                                          [(ngModel)]="etape.origine"
                                          [libelle]="(sbt.idNature === TypeNature.VOITURE_DE_LOCATION ? 'od.voyage.travel.priseEnCharge' : sbt.idNature === TypeNature.HEBERGEMENT ? 'od.voyage.travel.destination' : 'od.voyage.travel.origine')"
                                          [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                          [required]="true"
                                          (onChange)="onOrigineChanged(etape, index);"
                                          optionDisplay="libelle"
                                          customType="autocomplete"
                                          autocompleteType="geographie"
                                          [autocompleteFilter]="{ listeTypes: sbt.idNature === TypeNature.HEBERGEMENT ? ['VILLE'] :['VILLE','GARE','AEROPORT'] }">
                            </custom-input>
                            <div *ngIf="!origineBackup && etape.idEtape !== 0" class="col-md-6 row">
                                <label class="col-md-4 required"
                                       [class.text-secondary]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                       [class.text-muted]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked">
                                    <span [translate]="(sbt.idNature === TypeNature.VOITURE_DE_LOCATION ? 'od.voyage.travel.priseEnCharge' : sbt.idNature === TypeNature.HEBERGEMENT ? 'od.voyage.travel.destination' : 'od.voyage.travel.origine')"></span>
                                </label>
                                <span class="col-md-8 centered">{{ ('od.voyage.travel.villeInconnue' | translate) + ', ' + ('od.voyage.travel.paysInconnu' | translate) }}</span>
                            </div>
                            <!-- Filtrer les gare/aéroport pour hébergement -->
                            <custom-input
                                    *ngIf="[TypeNature.AVION, TypeNature.TRAIN, TypeNature.AVION_TRAIN].includes($any(sbt.idNature))"
                                    ngDefaultControl [(ngModel)]="etape.typeDepartAller" rCol="2"
                                    name="typeDepartAller"
                                    [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                    optionValue="valeur" optionDisplay="libelle"
                                    floatLabel="always" [placeholder]="'od.voyage.travel.aller' | translate"
                                    customType="select" [selectOptions]="listeTypeDepart"></custom-input>
                            <div class="col-md-2">
                                <date-range-custom [ngModel]="etape.jourDepartAller"
                                                   [(maDate)]="etape.jourDepartAller"
                                                   [label]="sbt.idNature === TypeNature.HEBERGEMENT ? 'od.voyage.travel.du' : null"
                                                   name="jourDepartAller" ngDefaultControl
                                                   (maDateChange)="updateConvenanceEtNuitees(etape,true);"
                                                   [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                                   [rangeDeb]="od.depart_le"
                                                   [rangeEnd]="od.retour_le"
                                                   [dateStart]="od.depart_le"
                                                   [required]="true"
                                                   [maxDate]="getMaxDateRange(etape,true)"
                                                   [minDate]="getMinDateRange(etape,true)"
                                                   notNullValidator></date-range-custom>
                            </div>
                            <div class="col-md-2" *ngIf="sbt.idNature === TypeNature.HEBERGEMENT">
                                <date-range-custom [ngModel]="etape.jourDepartRetour"
                                                   [(maDate)]="etape.jourDepartRetour"
                                                   label="od.voyage.travel.au"
                                                   name="jourDepartRetour" ngDefaultControl
                                                   (maDateChange)="updateConvenanceEtNuitees(etape,false);"
                                                   [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked || etape.typeDepartRetour == TypeDepart.AUCUN"
                                                   [rangeDeb]="od.depart_le"
                                                   [rangeEnd]="od.retour_le"
                                                   [dateStart]="od.retour_le"
                                                   [maxDate]="getMaxDateRange(etape,false)"
                                                   [minDate]="getMinDateRange(etape,false)"></date-range-custom>
                            </div>
                            <custom-input *ngIf="sbt.idNature !== TypeNature.HEBERGEMENT" ngDefaultControl
                                          [(ngModel)]="etape.heureDepartAller" rCol="2"
                                          name="heureDepartAller" [required]="true"
                                          (onChange)="updateConvenanceEtNuitees(etape, true);"
                                          customType="heure"
                                          [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"></custom-input>
                        </div>
                        <row-data *ngIf="sbt.idNature === TypeNature.HEBERGEMENT"
                                  [dataGauche]="{ traduction: 'od.voyage.travel.nbNuits', valeur:getNbNuits(etape) }"
                                  [isAffichageDouble]="true"></row-data>

                        <!-- Destination + Retour -->
                        <div class="row" *ngIf="sbt.idNature !== TypeNature.HEBERGEMENT">
                            <custom-input *ngIf="destinationBackup || etape.idEtape === 0" ngDefaultControl
                                          name="destination"
                                          [(ngModel)]="etape.destination"
                                          [libelle]="(sbt.idNature === TypeNature.VOITURE_DE_LOCATION ? 'od.voyage.travel.restitution' : 'od.voyage.travel.destination')"
                                          [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                          [required]="true"
                                          optionDisplay="libelle"
                                          customType="autocomplete"
                                          autocompleteType="geographie"
                                          [autoCompleteValue$]="listeAutocompleteDestination$.get(index)?.asObservable()"
                                          [autocompleteFilter]="{ listeTypes: ['VILLE','GARE','AEROPORT'] }">
                            </custom-input>
                            <div *ngIf="!destinationBackup && etape.idEtape !== 0" class="col-md-6 row">
                                <label class="col-md-4 required"
                                       [class.text-secondary]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                       [class.text-muted]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked">
                                    <span [translate]="(sbt.idNature === TypeNature.VOITURE_DE_LOCATION ? 'od.voyage.travel.restitution' : 'od.voyage.travel.destination')"></span>
                                </label>
                                <span class="col-md-8 centered">{{ ('od.voyage.travel.villeInconnue' | translate) + ', ' + ('od.voyage.travel.paysInconnu' | translate) }}</span>
                            </div>
                            <custom-input
                                    *ngIf="this.listeEtapes.length == 1 && [TypeNature.AVION, TypeNature.TRAIN, TypeNature.AVION_TRAIN].includes($any(sbt.idNature))"
                                    ngDefaultControl
                                    [(ngModel)]="etape.typeDepartRetour" rCol="2"
                                    name="typeDepartRetour"
                                    optionValue="valeur"
                                    optionDisplay="libelle"
                                    [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                    floatLabel="always"
                                    [placeholder]="'od.voyage.travel.retour' | translate"
                                    customType="select"
                                    (onChange)="resetDateHeureRetour(etape)"
                                    [selectOptions]="listeTypeRetour"></custom-input>
                            <div class="col-md-2">
                                <date-range-custom
                                        *ngIf="this.listeEtapes.length == 1 || sbt.idNature === TypeNature.VOITURE_DE_LOCATION"
                                        [ngModel]="etape.jourDepartRetour"
                                        [(maDate)]="etape.jourDepartRetour"
                                        name="jourDepartRetour" ngDefaultControl
                                        (maDateChange)="updateConvenanceEtNuitees(etape,false);"
                                        [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked || etape.typeDepartRetour == TypeDepart.AUCUN && sbt.idNature !== TypeNature.VOITURE_DE_LOCATION"
                                        [rangeDeb]="od.depart_le"
                                        [rangeEnd]="od.retour_le"
                                        [dateStart]="od.retour_le"
                                        [maxDate]="getMaxDateRange(etape,false)"
                                        [minDate]="getMinDateRange(etape,false)"
                                        [required]="sbt.idNature === TypeNature.VOITURE_DE_LOCATION"
                                        [notNullValidator]="sbt.idNature === TypeNature.VOITURE_DE_LOCATION"></date-range-custom>
                            </div>
                            <custom-input
                                    *ngIf="this.listeEtapes.length == 1 || sbt.idNature === TypeNature.VOITURE_DE_LOCATION"
                                    ngDefaultControl
                                    [(ngModel)]="etape.heureDepartRetour" rCol="2"
                                    name="heureDepart"
                                    [required]="etape.jourDepartRetour != null || sbt.idNature === TypeNature.VOITURE_DE_LOCATION"
                                    customType="heure"
                                    (onChange)="updateConvenanceEtNuitees(etape, false);"
                                    [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked || etape.typeDepartRetour == TypeDepart.AUCUN && sbt.idNature !== TypeNature.VOITURE_DE_LOCATION"></custom-input>
                        </div>
                        <br>

                        <!-- Si on est en online-->
                        <div class="row"
                             *ngIf="sbt.typeAiguillage === TypeAiguillage.ONLINE && sbt.idNature !== TypeNature.VOITURE_DE_LOCATION">
                            <!-- Train & Avion, on affiche la convenance -->
                            <ng-template [ngTemplateOutlet]="blocConvenance"></ng-template>
                        </div>

                        <!-- Si on n'est pas en Online, on va afficher les options -->
                        <div *ngIf="sbt.typeAiguillage !== TypeAiguillage.ONLINE">
                            <!-- Si on est sur une nature autorisée -->
                            <div *ngIf="[TypeNature.AVION, TypeNature.TRAIN, TypeNature.HEBERGEMENT, TypeNature.VOITURE_DE_LOCATION].includes($any(sbt.idNature))">

                                <ng-container *ngIf="sbt.idNature != TypeNature.VOITURE_DE_LOCATION">
                                    <!-- Fournisseur + Classe -->
                                    <div class="row">
                                        <custom-input *ngIf="listeFournisseurs" ngDefaultControl name="fournisseur"
                                                      customType="objectselect"
                                                      [isSelectAvecVide]="true"
                                                      [(ngModel)]="etape.fournisseur"
                                                      [libelle]="sbt.idNature == TypeNature.HEBERGEMENT ? 'od.voyage.travel.hotel' : 'od.voyage.travel.fournisseur'"
                                                      optionValue="idCompagnie"
                                                      optionDisplay="libelle" [disabled]="!canModifier"
                                                      [valeurIdTampon]="0"
                                                      [isSelectAvecInput]="true"
                                                      [selectOptions]="listeFournisseurs"></custom-input>

                                        <!-- Train & Avion, on affiche la convenance, Hébergement on affiche le confort/classe -->
                                        <ng-template [ngTemplateOutlet]="blocConvenance"></ng-template>
                                    </div>

                                    <!-- référence + cout -->
                                    <div class="row" *ngIf="sbt.idNature != TypeNature.HEBERGEMENT">
                                        <custom-input ngDefaultControl [(ngModel)]="etape.reference"
                                                      name="reference" libelle="od.voyage.travel.reference"
                                                      [disabled]="!canModifier"></custom-input>
                                        <ng-template [ngTemplateOutlet]="blocClasse"></ng-template>
                                    </div>
                                </ng-container>
                            </div>

                            <!-- Options + Motif véhicule -->
                            <div class="row">
                                <custom-input *ngIf="listeOptionsTrajet?.length > 0" ngDefaultControl
                                              [(ngModel)]="etape.options"
                                              name="options" libelle="od.voyage.travel.options"
                                              [disabled]="!canModifier"
                                              customType="multiselect" optionDisplay="libelle"
                                              optionValue="id" [selectOptions]="listeOptionsTrajet"></custom-input>
                                <custom-input *ngIf="sbt.idNature == TypeNature.HEBERGEMENT" ngDefaultControl
                                              [(ngModel)]="etape.telephone"
                                              name="reference" libelle="od.voyage.travel.telephone"
                                              [disabled]="!canModifier"></custom-input>
                                <custom-input *ngIf="[TypeNature.VOITURE_DE_LOCATION, TypeNature.VOITURE_DE_SOCIETE, TypeNature.VOITURE_PERSONNELLE].includes($any(sbt.idNature))
                                                 && listeOptionsAfficher?.length > 0"
                                              ngDefaultControl [(ngModel)]="etape.listeMotifs" name="listeMotifs"
                                              libelle="od.voyage.travel.motif"
                                              customType="multiselect" [disabled]="!canModifier" optionDisplay="motif"
                                              optionValue="idVehiculeMotif"
                                              [selectOptions]="listeOptionsAfficher"></custom-input>
                                <custom-input *ngIf="[TypeNature.AVION, TypeNature.TRAIN].includes($any(sbt.idNature))"
                                              ngDefaultControl [(ngModel)]="etape.cout"
                                              customType="montant" [montantDevise]="devise"
                                              name="cout" libelle="od.voyage.travel.cout"
                                              [disabled]="!canModifier"></custom-input>
                            </div>

                            <!-- Remarque -->
                            <div class="row">
                                <custom-input ngDefaultControl [(ngModel)]="etape.remarque"
                                              name="remarque" libelle="od.voyage.travel.remarque"
                                              customType="textarea"
                                              [disabled]="!canModifier" rCol="10"></custom-input>
                            </div>
                        </div>
                        <div mat-dialog-actions align="end">
                            <button mat-stroked-button color="primary" (click)="removeEtape(index)" *ngIf="index > 0">
                                <span translate>global.actions.supprimer</span></button>
                        </div>
                    </fieldset>
                </ng-container>

                <!-- Formulaire pour les documents -->
                <ng-container *ngIf="sbt.idNature === TypeNature.DOCUMENT">
                    <ng-template matStepLabel>
                        <strong>
                            <span *ngIf="etape.carteTravel?.typeCarte">{{ etape.carteTravel.typeCarte }}&nbsp;</span>
                            <span *ngIf="etape.typeDocument">{{ getCleTradPresta(etape) | translate }}&nbsp;</span>
                        </strong>
                        <span *ngIf="etape.carteTravel?.libelle">{{ '(' + etape.carteTravel.libelle + ')' }}</span>
                        <br>
                        <span *ngIf="etape.origine?.libelle">{{etape.origine.libelle + ', ' + etape.origine.pays?.libelle}}</span>
                        <span *ngIf="etape.destination?.libelle">{{' - ' + etape.destination.libelle + ', ' + etape.destination.pays?.libelle}}</span>
                    </ng-template>
                    <fieldset [disabled]="!canModifier">
                        <!-- Sélection du type de document -->
                        <div class="row">
                            <custom-input ngDefaultControl name="typeDocument"
                                          customType="select"
                                          [selectOptions]="listeTypeDocument"
                                          [(ngModel)]="etape.typeDocument"
                                          (onChange)="resetInfosDocument(etape, true)"
                                          [disabled]="!canModifier"
                                          [required]="true"
                                          [twoLineLabel]="true"
                                          libelle="od.voyage.travel.prestaConcernee"
                                          optionValue="valeur"
                                          optionDisplay="libelle"
                                          [placeholder]="'od.voyage.typeDocument' | translate"></custom-input>
                        </div>
                        <!-- Sélection de la carte -->
                        <div class="row"
                             *ngIf="![TypePresta.AUTRE, TypePresta.DOCUMENT].includes($any(etape.typeDocument))">
                            <custom-input ngDefaultControl name="carte"
                                          customType="autocomplete"
                                          autocompleteType="carteTravelHub"
                                          [autocompleteFilter]="{ typePresta: etape.typeDocument}"
                                          [(ngModel)]="etape.carteTravel"
                                          [disabled]="!canModifier"
                                          [required]="true"
                                          (onChange)="resetInfosDocument(etape)"
                                          libelle="od.voyage.travel.carte"
                                          optionDisplay="data.carte.carteReferentiel.libelle">
                            </custom-input>
                            <custom-input ngDefaultControl name="renouvellement"
                                          customType="checkbox"
                                          [ngModel]="etape.renouvellement"
                                          [(customModel)]="etape.renouvellement"
                                          libelle="od.voyage.travel.renouvellement">
                            </custom-input>
                        </div>
                        <!-- Détail de la carte -->
                        <row-data *ngIf="etape.carteTravel"
                                  [dataGauche]="{traduction: 'od.voyage.travel.fournisseur', valeur:etape.carteTravel?.compagnie?.libelle}"
                                  [dataDroite]="{traduction: 'od.voyage.travel.typeCarte', valeur:etape.carteTravel?.typeCarte}"></row-data>
                        <!-- Origine / Date début -->
                        <div class="row" *ngIf="etape.typeDocument !== TypePresta.AUTRE">
                            <custom-input ngDefaultControl name="origine"
                                          customType="autocomplete"
                                          autocompleteType="geographie"
                                          [autocompleteFilter]="{ listeTypes: ['VILLE'] }"
                                          [(ngModel)]="etape.origine"
                                          [disabled]="!canModifier"
                                          [required]="etape.isOrigineRequired"
                                          [libelle]="etape.typeDocument !== TypePresta.DOCUMENT ? 'od.voyage.travel.origine' : 'od.voyage.travel.destination'"
                                          optionDisplay="libelle">
                            </custom-input>
                            <label class="col-md-2"
                                   [ngClass]="{ 'has-error': isDateDepartInvalid(etape), 'required': etape.isJourDepartRequired }">
                                <span translate>od.voyage.travel.dateDeb</span>
                            </label>
                            <div class="col-md-4">
                                <date-range-custom [(maDate)]="etape.jourDepartAller"
                                                   [disabled]="!canModifier"
                                                   [rangeDeb]="od.depart_le" [rangeEnd]="od.retour_le"
                                                   [dateStart]="od.depart_le"
                                                   [required]="etape.isJourDepartRequired"
                                                   [minDate]="getMinDateRange(etape,true, false)"
                                                   [maxDate]="getMaxDateRange(etape,true, false)">
                                </date-range-custom>
                            </div>
                        </div>
                        <!-- Destination -->
                        <div class="row"
                             *ngIf="![TypePresta.AUTRE, TypePresta.DOCUMENT].includes($any(etape.typeDocument)) && [TypeChampsCarte.NUM,TypeChampsCarte.NUM_DU_AU_DE_A].includes($any(etape.carteTravel?.typeChamp))">
                            <custom-input ngDefaultControl name="destination"
                                          customType="autocomplete"
                                          autocompleteType="geographie"
                                          [autocompleteFilter]="{ listeTypes: ['VILLE'] }"
                                          [(ngModel)]="etape.destination"
                                          [disabled]="!canModifier"
                                          [required]="etape.isDestinationRequired"
                                          libelle="od.voyage.travel.destination"
                                          optionDisplay="libelle">
                            </custom-input>
                        </div>
                        <!-- Commentaire -->
                        <div class="row">
                            <custom-input ngDefaultControl name="remarque"
                                          [(ngModel)]="etape.remarque"
                                          libelle="od.voyage.travel.remarque"
                                          customType="textarea"
                                          [disabled]="!canModifier"
                                          [required]="etape.isRemarqueRequired" rCol="10">
                            </custom-input>
                        </div>
                    </fieldset>
                </ng-container>

                <!-- confort/classe -->
                <ng-template #blocClasse>
                    <custom-input *ngIf="listeClasses && sbt.typeAiguillage !== TypeAiguillage.ONLINE" ngDefaultControl
                                  name="classe"
                                  [(ngModel)]="etape.classe"
                                  [libelle]="sbt.idNature == TypeNature.HEBERGEMENT ? 'od.voyage.travel.confort' : 'od.voyage.travel.classe'"
                                  optionValue="idClasse"
                                  optionDisplay="libelle"
                                  customType="objectselect"
                                  [isSelectAvecVide]="sbt.idNature == TypeNature.HEBERGEMENT"
                                  [disabled]="!canModifier"
                                  [selectOptions]="listeClasses"
                                  [required]="[TypeNature.AVION, TypeNature.TRAIN].includes($any(sbt.idNature))">
                    </custom-input>
                </ng-template>

                <!-- Template pour les convenances / Classe pour l'hébergement -->
                <ng-template #blocConvenance>
                    <ng-container *ngIf="sbt.idNature != TypeNature.HEBERGEMENT; else blocClasse">
                        <custom-input *ngIf="etape.listeConvenancesSize > 0" ngDefaultControl name="convenance"
                                      [(ngModel)]="etape.typeConvenance"
                                      customType="objectselect"
                                      [selectOptions]="etape.listeConvenancesTriee"
                                      optionValue="valeur"
                                      optionDisplay="libelle"
                                      [libelle]="'od.voyage.travel.convenance'"
                                      [isTranslated]="false"
                                      [disabled]="!canModifier || sbt.typeAiguillage === TypeAiguillage.ONLINE && etape.booked"
                                      [matError]="settings.isDateTransportIncluse ? ('od.voyage.travel.convenanceError' | translate) : ''"
                                      [required]="true">
                        </custom-input>
                    </ng-container>
                </ng-template>
            </form>
        </mat-step>

        <!-- Stepper permettant d'ajouter une étape si le multi étape est possible et qu'on est pas en modification d'une étape existante -->
        <mat-step #stepAjout state="ajout"
                  *ngIf="sbt.multiEtapePossible && !etapeExistante && sbt.idNature !== TypeNature.DOCUMENT">
            <ng-template matStepLabel>
                <div><a translate>od.voyage.travel.ajouterEtape</a></div>
            </ng-template>
        </mat-step>

        <!-- Surcharge des icônes -->
        <ng-template matStepperIcon="ajout">
            <mat-icon>add</mat-icon>
        </ng-template>
    </mat-vertical-stepper>
</div>

<!-- Boutons -->
<div mat-dialog-actions *ngIf="isTimeToCheck" class="bouton-droite-gauche">
    <!-- Boutons de gauche -->
    <span>
        <ng-container *ngIf="showBoutonSwitchSbt()">
            <ng-container *ngFor="let sbt of getListeSbtForButton();">
                <button (click)="switchSbt(sbt)" [disabled]="isSaving" class="mr-2" color="primary" mat-stroked-button>
                    <span *ngIf="sbt.typeAiguillage === TypeAiguillage.OFFLINE"
                          [matTooltip]="'od.voyage.travel.switchAgenceTooltip' | translate">{{'od.voyage.travel.switchAgence' | translate}}{{ getLibelleNatureSbt(sbt)}}</span>
                    <span *ngIf="sbt.typeAiguillage === TypeAiguillage.ONLINE"
                          [matTooltip]="'od.voyage.travel.switchDirectTooltip' | translate">{{'od.voyage.travel.switchDirect' | translate}}</span>
                </button>
            </ng-container>
        </ng-container>
    </span>

    <!-- Boutons de droite, n'est-ce pas !   -->
    <span>
        <!-- Supprimer -->
        <button *ngIf="canSupprimer()" mat-stroked-button (click)="deleteEtape()" color="primary" class="m-r-10">
            <span translate>global.actions.supprimer</span>
        </button>

        <!-- Boutons ONLINE -->
        <ng-container
                *ngIf="sbt.typeAiguillage === TypeAiguillage.ONLINE && (!etapeExistante || etapeExistante.booked === false)">
            <!-- Enregistrer -->
            <button (click)="enregistrerEtapes()" [disabled]="isFormInvalid() || isSaving" class="mr-2" color="primary"
                    mat-stroked-button type="submit">
                <span *ngIf="!isSaving && !isBookingInProgress" translate>global.actions.enregistrer</span>
                <mat-spinner *ngIf="isBookingInProgress" class="m-5" diameter="28"></mat-spinner>
            </button>
            <!-- Réserver -->
            <span [matTooltip]="!isProfilVoyageurValide ? ('profilUser.infoPerso.synchroPV.profilInvalide' | translate) : (synchroProfilEnCours | async) ? ('profilUser.infoPerso.synchroPV.statutEnCours' | translate) : !sbt.synchro ? ('profilUser.infoPerso.synchroPV.synchroniseKO' | translate) : null">
                <button (click)="enregistrerEtapes(true)"
                        [disabled]="isFormInvalid() || isBookingInProgress || (synchroProfilEnCours | async) || !sbt.synchro || !isProfilVoyageurValide"
                        color="primary" mat-flat-button
                        type="submit">
                    <span *ngIf="!isSaving && !(synchroProfilEnCours | async)"
                          translate>od.voyage.travel.reserver</span>
                    <mat-spinner *ngIf="isSaving || (synchroProfilEnCours | async)" class="m-5"
                                 diameter="28"></mat-spinner>
                </button>
            </span>
        </ng-container>

        <!-- Boutons OFFLINE -->
        <ng-container *ngIf="sbt.typeAiguillage !== TypeAiguillage.ONLINE && canModifier">
            <!-- Enregister -->
            <button (click)="enregistrerEtapes()" [disabled]="isFormInvalid()" color="primary" mat-flat-button
                    type="submit">
                <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
                <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
            </button>
        </ng-container>
    </span>
</div>