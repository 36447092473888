import {Component,Input,OnInit,Type} from '@angular/core';
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {ListView} from "@domain/common/list-view";
import {TravelHubSbtConfigUsedParamConnexion} from "@domain/travel/travel-hub-sbt-config-used-param-connexion";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Observable} from "rxjs";
import {ListItem} from "@domain/common/list-view/list-item";
import {AbstractReferentiel} from "@domain/travel/abstract-referentiel";
import {findRefSbt,RefSbt} from "@domain/travel/ref-sbt";
import {TravelHubSbtConfigUsedCategorie} from "@domain/travel/travel-hub-sbt-config-used-categorie";

/**
 * Composant abstrait et générique pour la gestion des référentiels Communauté, Catégorie voyageur et RLS
 *
 * @author Laurent Convert
 * @date 01/12/2023
 */
@Component({
	template: ''
})
export abstract class AbstractReferentielComponent<U extends ListItem & AbstractReferentiel,V extends ListViewItem<U>> implements OnInit {
	/** Configuration SBT */
	@Input()
	sbtConfigUsed: TravelHubSBTConfigUsed;

	/** Liste des référentiels */
	listeReferentiel: ListView<U,V>;

	/** Liste des configurations SBT utilisées */
	listeSBTConfigUsed: Array<TravelHubSbtConfigUsedParamConnexion | TravelHubSbtConfigUsedCategorie>;

	/** Type de SBT sélectionné */
	sbt: RefSbt;

	/**
	 * Constructeur
	 *
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 * @param translateService Service de traduction
	 * @param toastrService Service de notification
	 * @param matDialog Boite de dialogue
	 * @param componentReferentiel Composant spécifique au type de référentiel
	 * @param componentAddReferentiel Composant d'ajout d'un référentiel suivant son type
	 */
	constructor(protected adminTravelhubService:AdminTravelhubService,
				protected translateService: TranslateService,
				protected toastrService: ToastrService,
				protected matDialog: MatDialog,
				protected componentReferentiel: Type<V>,
				protected componentAddReferentiel: Type<unknown>) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération du type de SBT sélectionné
		this.sbt = findRefSbt(this.sbtConfigUsed.sbtConfig.numSBT);

		//Définition de la liste des configurations TravelHub
		this.listeReferentiel = new ListView<U,V>({
			uri: this.getUri(),
			title: this.translateService.instant(`${this.getRootTrad()}.title`),
			component: this.componentReferentiel,
			mapResult: (result) => this.mapResult(result),
			isSimple: true,
			extraOptions: {
				parent: this,
				listeSBTConfigUsed: this.listeSBTConfigUsed,
			},
			listeActions: [
				{
					icon: "add",
					onPress: () => this.createReferentiel(),
					isVisible: () => this.adminTravelhubService.findConfigUsed(this.listeSBTConfigUsed,this.sbtConfigUsed) != null
				}
			]
		});

		//Mise à jour de l'alerte sur la liste à la création de la liste
		this.updateAlertLevelList();
	}

	/**
	 * Création d'un référentiel
	 */
	createReferentiel(): void {
		//Initialisation d'un nouveau référentiel
		let referentiel: U = this.newInstanceReferentiel();

		//Association avec la configuration SBT en cours
		referentiel.sbtConfigUsed = this.sbtConfigUsed;

		//Ouverture
		this.openReferentiel(referentiel);
	}

	/**
	 * Ouverture d'un référentiel
	 *
	 * @param referentiel Le référentiel à afficher
	 */
	openReferentiel(referentiel: U): void {
		this.matDialog.open(this.componentAddReferentiel,{
			width: '40%',
			data: {
				referentiel: referentiel,
				sbtConfigUsed: this.sbtConfigUsed,
				listeSBTConfigUsed: this.listeSBTConfigUsed,
				sbt: this.sbt,
				parent: this,
			}
		});
	}

	/**
	 * Enregistrement d'un référentiel
	 *
	 * @param referentiel Le référentiel à enregistrer
	 */
	saveReferentiel(referentiel: U): Observable<boolean> {
		return this.save(referentiel).map(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				//Rafraichissement de la liste
				this.refreshListe(result);

				return true;
			} else if (result?.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
				//Message spécial erreur doublon
				this.toastrService.error(this.translateService.instant(`${this.getRootTrad()}.erreurDoublon`));

				return false;
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);

				return false;
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);

			return false;
		});
	}

	/**
	 * Suppression d'un référentiel
	 *
	 * @param referentiel Le référentiel à supprimer
	 */
	deleteReferentiel(referentiel: U): Observable<boolean> {
		return this.delete(referentiel).map(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.suppression'));

				//Rafraichissement de la liste
				this.refreshListe(result);

				return true;
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);

				return false;
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);

			return false;
		});
	}

	/**
	 * Recharge la liste du référentiel après une action de création ou de suppression
	 *
	 * @param result Résultat de l'action
	 */
	refreshListe(result: Result): void {
		//Construction de la liste des alertes
		this.sbtConfigUsed.buildListeAlertes(result);

		//Rafraichissement de la liste
		this.listeReferentiel.refresh();

		//Mise à jour de l'alerte sur la liste au rafraichissement de la liste
		this.updateAlertLevelList();
	}

	/**
	 * Retourne l'uri propre au type de référentiel
	 */
	protected abstract getUri(): string;

	/**
	 * Retourne la racine pour les traductions
	 */
	protected abstract getRootTrad(): string;

	/**
	 * Post-traitement de la liste paginée retournée par le serveur
	 *
	 * @param result Résultat à traiter
	 */
	protected abstract mapResult(result: Result);

	/**
	 * Retourne une instance du type de référentiel
	 *
	 * @param dto Source
	 */
	protected abstract newInstanceReferentiel(dto?: U | any): U;

	/**
	 * Met à jour le niveau d'erreur du référentiel
	 */
	public abstract updateAlertLevelList(): void;

	/**
	 * Enregistrement d'une entrée du référentiel
	 *
	 * @param referentiel Référentiel à enregistrer
	 */
	protected abstract save(referentiel: U): Observable<Result>;

	/**
	 * Suppression d'une entrée du référentiel
	 *
	 * @param referentiel Référentiel à supprimer
	 */
	protected abstract delete(referentiel: U): Observable<Result>;
}