export class ConfigAlerte {
	alerteEcheanceDate?: Date;
	assuranceActif?: boolean;
	assuranceObligatoire?: boolean;
	autorisationActif?: boolean;
	carteGriseActif?: boolean;
	carteGriseObligatoire: boolean;
	messageAccueil?: string;
	nom?: string;
	prenom?: string;
	profilAlerteConfig?: any;
	titre?: number;
	lienProfilConnexion?: LienUserProfilConnexion;
	isProfilOnline?: boolean;
}

/**
 * Classe représentant le lien entre un utilisateur et un profil de connexion
 */
export class LienUserProfilConnexion {
	actif: boolean;
	updated: boolean;
}
