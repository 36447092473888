<mat-card>
    <mat-card-title><span [translate]="'workflow.roles.detail.options'"></span></mat-card-title>
    <mat-card-content>
        <form>
            <fieldset>
                <!-- Options Responsable et comptable -->
                <ng-container *ngIf="role.fonction === ProfilsAttribuables.RESPONSABLE || role.fonction === ProfilsAttribuables.COMPTABLE">
                    <div class="row">
                        <custom-input name="vehicules"
                                      *ngIf="this.role.fonction === ProfilsAttribuables.RESPONSABLE"
                                      customType="checkbox"
                                      libelle="workflow.roles.detail.vehicules"
                                      postLibelle="workflow.roles.detail.vehiculesLabel"
                                      [ngModel]="role.specificiteVehicules"
                                      [(customModel)]="role.specificiteVehicules" ngDefaultControl></custom-input>
                    </div>

                    <div class="row">
                        <custom-input name="budgetaire"
                                      *ngIf="this.role.fonction === ProfilsAttribuables.RESPONSABLE"
                                      customType="checkbox"
                                      libelle="workflow.roles.detail.budgetaire"
                                      postLibelle="workflow.roles.detail.budgetaireLabel"
                                      [ngModel]="role.specificiteBudgetaire"
                                      [(customModel)]="role.specificiteBudgetaire" ngDefaultControl></custom-input>
                    </div>

                    <div class="row">
                        <custom-input name="enveloppes"
                                      customType="checkbox"
                                      libelle="workflow.roles.detail.enveloppes"
                                      postLibelle="workflow.roles.detail.enveloppesLabel"
                                      [ngModel]="role.specificiteEnveloppes"
                                      [(customModel)]="role.specificiteEnveloppes" ngDefaultControl></custom-input>
                    </div>
                </ng-container>

                <!-- Options Sous admin -->
                <ng-container *ngIf="role.fonction === ProfilsAttribuables.SOUS_ADMINISTRATEUR">
                    <div class="row">
                        <custom-input name="connexion"
                                      customType="checkbox"
                                      libelle="workflow.roles.detail.connexion"
                                      postLibelle="workflow.roles.detail.connexionLabel"
                                      [ngModel]="role.specificiteConnexion"
                                      [(customModel)]="role.specificiteConnexion" ngDefaultControl></custom-input>

                        <custom-input name="contact"
                                      customType="checkbox"
                                      libelle="workflow.roles.detail.contact"
                                      postLibelle="workflow.roles.detail.contactLabel"
                                      [ngModel]="role.specificiteContact"
                                      [(customModel)]="role.specificiteContact" ngDefaultControl></custom-input>
                    </div>
                </ng-container>

                <!-- Options Fournisseur-->
                <ng-container *ngIf="role.fonction === ProfilsAttribuables.FOURNISSEUR">
                    <div class="row">
                        <custom-input name="robot"
                                      customType="checkbox"
                                      libelle="workflow.roles.detail.robot"
                                      postLibelle="workflow.roles.detail.robotLabel"
                                      [ngModel]="role.specificiteRobot"
                                      [(customModel)]="role.specificiteRobot" ngDefaultControl></custom-input>
                    </div>
                </ng-container>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>