import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FraisAgence} from "@domain/admin/voyages/frais-agence";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";

/**
 * Service de gestion des frais d'agence
 */
@Injectable({
	providedIn: 'root'
})
export class VoyagesFraisAgenceService {
	/**
	 * Constructeur
	 *
	 * @param http le client HTTP Angular
	 */
	constructor(
		private http: HttpClient
	) {
	}

	/**
	 * Sauvegarde d'un frais agence
	 *
	 * @param fraisAgence le frais d'agence
	 */
	saveFraisAgence(fraisAgence: FraisAgence): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/FraisAgence/saveFrais`,[fraisAgence]);
	}
}
