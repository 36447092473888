import {Component} from '@angular/core';
import {Router} from "@angular/router";

/**
 * Composant de la page "Données Connexion"
 */
@Component({
    selector: 'donnees-connexion',
    templateUrl: './donnees-connexion.component.html'
})
export class DonneesConnexionComponent {

    /**
     * Constructeur
     *
     * @param router le routeur
     */
    constructor(private router: Router) { }

    /**
     * Fonction appelée au clic sur le bouton retour : redirection à la page "Profil"
     */
    onGoBack(): void {
        //Navigation vers la page Profil
        this.router.navigate(['Profil']);
    }

}
