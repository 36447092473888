import {Component, Input} from '@angular/core';
import {TypeEntite} from "../../../../domain/typeentite/typeEntite";


/**
 * Composant pour une ligne de la liste des types entités des préférences analytiques.
 */
@Component({
  selector: 'profil-preference-analytiques-type-entite-list-item',
  templateUrl: './profil-preference-analytiques-type-entite-list-item.component.html',
})
export class ProfilPreferenceAnalytiquesTypeEntiteListItemComponent{
  /** Elément courant */
  @Input() data: TypeEntite;

  /** Options supplémentaires */
  @Input() extraOptions: any;
}
