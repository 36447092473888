<mat-card>
    <mat-card-title>
        <span [ngClass]="{ 'error': !isValid() }">
            <mat-icon class="m-r-10" *ngIf="!isValid()" [matTooltip]="'omp.zone.zoneObligatoire' | translate">error_outline</mat-icon>
            <span [translate]="'omp.zone.title'"></span>
        </span>
        <span class="actions" *ngIf="canModifier">
            <mat-icon (click)="showZone()">add</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item" *ngFor="let zone of omp?.listeZones">
                    <div class="lvi-content">
                        <div class="avatar">{{ getAvatarForTypeZone(zone.type) }}</div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="canModifier && showZone(zone)">
                                    {{ getTitleForZone(zone) }}
                                </a>
                            </div>
                            <ul class="lvi-attrs">
                                <li><strong [translate]="'omp.zone.typeZone'"></strong>{{ getTitleForTypeZone(zone.type) }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="!omp?.listeZones?.length"><span [translate]="'omp.zone.aucun'"></span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>