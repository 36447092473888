<div class="row line-item-list">
    <div class="body">
        <span class="row">
            <span class="col-md-12">
               {{ item.enveloppe.libelleEnveloppe }}
            </span>
        </span>
        <span class="row complement">
             <span class="col-md-12">
                <strong translate="admin.entreprise.analytique.enveloppes.reaffecter.date"></strong>
                {{ item.exercice.dateDebut | date:'shortDate' }} - {{ item.exercice.dateFin | date:'shortDate' }}
             </span>
        </span>
        <span class="row complement">
              <span class="col-md-12">
                <strong translate="admin.entreprise.analytique.enveloppes.reaffecter.montant"></strong>
                  {{ getMontant() }}
              </span>
        </span>
    </div>
</div>