import {Component, Input} from '@angular/core';
import {RecapObjet} from "@domain/dashboard/recap-objet";
import {TypePortee} from "@domain/workflow/workflow";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

/**
 * Item de la liste des recaps d'objets du Dashboard
 */
@Component({
    selector: 'objets-list-item',
    templateUrl: './objets-list-item.component.html'
})
export class ObjetsListItemComponent extends ListViewItem<RecapObjet> {

    /** Objet de récap courant */
    @Input() data: RecapObjet;

    /** Import de l'énum pour le DOM */
    typePortee: typeof TypePortee = TypePortee;

    /**
     * Constructeur
     *
     * @param translateService le service de traduction
     * @param router le routeur
     */
    constructor(private translateService: TranslateService,
                private router: Router) {
        super();
    }

    /**
     * Retourne le titre à afficher
     */
    getTitle(): string {
        switch (this.data.portee) {
            case TypePortee.OD:
                return this.translateService.instant('dashboard.listeObjets.od');
            case TypePortee.NF:
                return this.translateService.instant('dashboard.listeObjets.ndf');
            case TypePortee.OP:
                return this.translateService.instant('dashboard.listeObjets.frais');
            case TypePortee.AV:
                return this.translateService.instant('dashboard.listeObjets.av');
            case TypePortee.OT:
                return this.translateService.instant('dashboard.listeObjets.omp');
        }
    }

    /**
     * Navigue vers la liste correspondante
     */
    navigateTo(): void {
        switch (this.data.portee) {
            case TypePortee.OD:
                this.router.navigate(['ListeOD']);
                break;
            case TypePortee.NF:
                this.router.navigate(['ListeNDF']);
                break;
            case TypePortee.OP:
                this.router.navigate(['ListeNDF']);
                break;
            case TypePortee.AV:
                this.router.navigate(['ListeAvance']);
                break;
            case TypePortee.OT:
                this.router.navigate(['ListeOMP']);
        }
    }
}