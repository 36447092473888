<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.fonction === TypeProfil.COMPTABLE" class="nio icon-comptable"></i>
        <i *ngIf="data.fonction === TypeProfil.RESPONSABLE" class="nio icon-responsable"></i>
        <i *ngIf="data.fonction === TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs"></i>
        <i *ngIf="data.fonction === TypeProfil.ASSISTANT" class="nio icon-assistant"></i>
        <i *ngIf="data.fonction === TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur"></i>
        <i *ngIf="data.fonction === TypeProfil.FOURNISSEUR" class="nio icon-fournisseur"></i>
    </div>
    <div class="lvi-body clickable" (click)="extraOptions.isHistory ? showHistory(data.fonction) : isUnfolded = !isUnfolded">
        <div class="title">
            <a>{{ TypeProfil.getLibelle(translateService, data.fonction) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngFor="let role of listeDistinctRoles">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.role'"></strong>
                <span>{{ role.libelle }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button *ngIf="!extraOptions.isHistory" (click)="isUnfolded = !isUnfolded">
            <mat-icon *ngIf="isUnfolded">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isUnfolded">keyboard_arrow_down</mat-icon>
        </button>
        <button mat-icon-button *ngIf="extraOptions.isHistory" (click)="showHistory(data.fonction)">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="isUnfolded" class="pt-2">
    <div class="listview-item ml-5" *ngFor="let habilitation of data.liensRoleUser">
        <div class="list-view-item-hover">
            <div class="lvi-content">
                <div class="avatar">
                    <i *ngIf="data.fonction === TypeProfil.COMPTABLE" class="nio icon-comptable"></i>
                    <i *ngIf="data.fonction === TypeProfil.RESPONSABLE" class="nio icon-responsable"></i>
                    <i *ngIf="data.fonction === TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs"></i>
                    <i *ngIf="data.fonction === TypeProfil.ASSISTANT" class="nio icon-assistant"></i>
                    <i *ngIf="data.fonction === TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur"></i>
                    <i *ngIf="data.fonction === TypeProfil.FOURNISSEUR" class="nio icon-fournisseur"></i>
                </div>
                <div class="lvi-body">
                    <div class="title">
                        <a *ngIf="habilitation.type === TypeLienRoleUser.HABILITATION" (click)="openHabilitation(habilitation)">{{ habilitation.role.libelle }}</a>
                        <a *ngIf="habilitation.type === TypeLienRoleUser.DELEGATION" (click)="openDelegation(habilitation)" [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegation'" [translateParams]="{ libelle: habilitation.role.libelle }"></a>
                    </div>
                    <ul class="lvi-attrs">
                        <li *ngIf="habilitation.userDelegant">
                            <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.delegant'"></strong>
                            <span>{{ habilitation.userDelegant | user }}</span>
                        </li>
                        <li *ngIf="habilitation.dateDebut">
                            <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateDebut'"></strong>
                            <span>{{ habilitation.dateDebut | date:'shortDate' }}</span>
                        </li>
                        <li *ngIf="habilitation.dateFin">
                            <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.dateFin'"></strong>
                            <span>{{ habilitation.dateFin | date:'shortDate' }}</span>
                        </li>
                        <li *ngIf="habilitation.role.fonction !== TypeProfil.COLLABORATEUR">
                            <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.population'"></strong>
                            <span>{{ habilitation.population ? habilitation.population.libelle : ('admin.entreprise.utilisateurs.detail.generalites.habilitations.tousLesCollabs' | translate) }}</span>
                        </li>
                        <li *ngIf="habilitation.listeEnveloppes.length > 0">
                            <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.enveloppes'"></strong>
                            <span>{{ habilitation.listeEnveloppes.length }}</span>
                        </li>
                        <li>
                            <strong [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.priorite'"></strong>
                            <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.' + (habilitation.priorite === TypePriorite.TITULAIRE ? 'titulaire' : 'suppleant')"></span>
                        </li>
                    </ul>
                </div>
                <div class="lvi-actions">
                    <button mat-icon-button *ngIf="!extraOptions.isHistory && habilitation.type === TypeLienRoleUser.HABILITATION && habilitation.role.delegable" (click)="createDelegation(habilitation.role)" [matTooltip]="'admin.entreprise.utilisateurs.detail.generalites.habilitations.creerDelegation' | translate">
                        <mat-icon class="material-icons-outlined">waving_hand</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>