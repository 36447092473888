<list-view *ngIf="listeDevices" [liste]="listeDevices"></list-view>
<mat-card *ngIf="isCollabInit && isAdmin">
    <mat-card-title>
        <span [translate]="'admin.entreprise.utilisateurs.detail.mobilite.codeQrTitle'"></span>
    </mat-card-title>
    <mat-card-content>
        <p [translate]="'admin.entreprise.utilisateurs.detail.mobilite.codeQrText'"></p>
        <div style="text-align: center">
            <qrcode *ngIf="token" [qrdata]="token" [size]="256" [errorCorrectionLevel]="'H'"></qrcode>
        </div>
    </mat-card-content>
</mat-card>
