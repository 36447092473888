import {ListItem} from "../common/list-view/list-item";
import {Devise} from "../settings/devise";

/**
 * Contient les données sur l'imputation d'une avance
 *
 * @author Laurent Convert
 * @date 04/04/2024
 */
export class ImputationAvance implements ListItem {
	/** Identifiant */
	id: number;

	/** Type */
	typeImputation: TypeImputation;

	/** Identifiant de l'avance */
	idAvance: number;

	/** Libellé de l'avance */
	libelleObjet: string;

	/** Identifiant de l'od */
	idOd: number;

	/** Montant  */
	montant: number;

	/** Montant de la note */
	montantNote: number;

	/** Devise */
	devise: Devise;

	/** Cours */
	cours: number;

	/**
	 * Constructeur
	 *
	 * @param dto DTO source
	 */
	constructor(dto?: ImputationAvance) {
		if (dto) {
			Object.assign(this,dto);
		}
	}

	getKey(): string {
		return this.typeImputation + '-' + this.id;
	}
}

/**
 * Type pour la liste des avances imputées
 */
export enum TypeImputation {
	IMPUTATION = 'IMPUTATION',
	RESIDU = 'RESIDU',
}

/**
 * Extension de l'énumération TypeImputation
 */
export namespace TypeImputation {
	/**
	 * Retourne le code de traduction correspondant à un type de facture
	 *
	 * @param typeImputation Type d'imputation
	 */
	export function traduction(typeImputation: TypeImputation) {
		switch (typeImputation) {
			case TypeImputation.IMPUTATION: return 'ndf.imputationAvance.typeImputation';
			case TypeImputation.RESIDU: return 'ndf.imputationAvance.typeResidu';
			default: return null;
		}
	}
}