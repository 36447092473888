<ng-container *ngIf="!isLoading; else pleaseWait;">
    <page-header
            [title]="('admin.voyages.travelhub.profilConnexion.title' | translate) + (profilConnexion?.libelle ? ' ' + profilConnexion?.libelle : '')"
            [listeItems]="listeTabItems"
            (onSelectedItemChange)="onSelectedItemChange($event)"
            [isPending]="isPending"
            [niveauAlerte]="profilConnexion.listeAlertes?.niveau"
            (onGoBack)="onGoBack()">
        <preview-alerte #alerte *ngFor="let alerte of profilConnexion.listeAlertes?.listeAlertes" [alerte]="alerte" [translateAlerte]="true"></preview-alerte>
    </page-header>

    <!-- Onglet Généralités -->
    <div *ngIf="isTabLoaded(OngletsProfilConnexion.GENERALITES)" [hidden]="selectedItem?.code != OngletsProfilConnexion.GENERALITES">
        <!-- Généralités -->
        <profil-connexion-generalites
                [profilConnexion]="profilConnexion"
                [listeOnlineSBTConfig]="listeOnlineSBTConfig"
                [isCreation]="isCreation"
        ></profil-connexion-generalites>

        <!-- Liste des aiguillages -->
        <list-view *ngIf="listeAiguillage != null" [liste]="listeAiguillage"></list-view>

        <!-- Actions sur le profil de connexion -->
        <floating-button [listeActions]="listeActions" [isPending]="isPending"></floating-button>
    </div>

    <!-- Onglet Usage -->
    <div *ngIf="isTabLoaded(OngletsProfilConnexion.USAGE)" [hidden]="selectedItem?.code != OngletsProfilConnexion.USAGE">
        <profil-connxion-usage [idProfilConnexion]="profilConnexion.idProfilConnexion"></profil-connxion-usage>
    </div>
</ng-container>

<ng-template #pleaseWait>
    <please-wait></please-wait>
</ng-template>