import {TravelHubSBTConfig} from "./travel-hub-sbt-config";
import {ListItem} from "../common/list-view/list-item";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {Result} from "@domain/common/http/result";

/**
 * Représente une configuration SBT créée par l'utilisateur
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
export class TravelHubSBTConfigUsed implements ListItem {
	idSBTConfigUsed: number;
	sbtConfig: TravelHubSBTConfig;
	libelle: string;
	actif: boolean;
	dateCreation: Date;

	errorRLS: boolean;
	errorCommunaute: boolean;
	errorCategorieVoyageur: boolean;
	errorParamConnexion: boolean;

	/** Liste des alertes */
	listeAlertes?: ListeAlertes;

	/**
	 * Constructeur
	 *
	 * @param dto Objet source
	 * @param buildListeAlertes Construire la liste des alertes
	 */
	constructor(dto?: any | TravelHubSBTConfigUsed,buildListeAlertes: boolean = true) {
		//Vérification du passage de la source
		if (dto) {
			//Copie des propriétés
			Object.assign(this,dto);

			if (dto.sbtConfig) {
				this.sbtConfig = new TravelHubSBTConfig(dto.sbtConfig);
			}

			//Construction de la liste des alertes
			if (buildListeAlertes) {
				this.buildListeAlertes();
			}
		}
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
    getKey(): number {
		return this.idSBTConfigUsed;
	}

	/**
	 * Met à jour les indicateurs d'alertes
	 * @param result Le résultat du traitement back
	 */
	updateFlagsAlertes(result: Result): void {
		//Si le résultat contient le flag d'erreur sur les paramètres de connexion
		if (result?.data.errorParamConnexion != null) {
			//Mise à jour du flag
			this.errorParamConnexion = result.data.errorParamConnexion
		}

		//Si le résultat contient le flag d'erreur du référentiel "Catégorie voyageur"
		if (result?.data.errorCategorieVoyageur != null) {
			//Mise à jour du flag
			this.errorCategorieVoyageur = result.data.errorCategorieVoyageur
		}

		//Si le résultat contient le flag d'erreur du référentiel "Communauté"
		if (result?.data.errorCommunaute != null) {
			//Mise à jour du flag
			this.errorCommunaute = result.data.errorCommunaute
		}

		//Si le résultat contient le flag d'erreur du référentiel "RLS"
		if (result?.data.errorRLS != null) {
			//Mise à jour du flag
			this.errorRLS = result.data.errorRLS
		}
	}

	/**
	 * Construction de la liste des alertes
	 */
	buildListeAlertes(result?: Result): void {
		const listeAlertes: Array<Alerte> = [];

		//Ré-initialisation de la liste des alertes
		this.listeAlertes = new ListeAlertes();

		//Mise à jour des indicateurs d'alertes
		this.updateFlagsAlertes(result);

		//Création d'une alerte dans le cas où des paramètres obligatoires non automatiques sont manquants
		if (this.sbtConfig?.actif === false) {
			//Création de l'alerte
			let alerte = new Alerte();
			alerte.niveau = NiveauAlerte.WARNING;

			alerte.titre = "admin.voyages.travelhub.sbtConfig.alerte.sbtInactif.titre";
			alerte.message = "admin.voyages.travelhub.sbtConfig.alerte.sbtInactif.message";
			alerte.traductionParams = {
				sbt: this.sbtConfig?.code
			}

			//Ajout de l'alerte à la liste
			listeAlertes.push(alerte);
		}

		//Création d'une alerte dans le cas où des paramètres obligatoires non automatiques sont manquants
		if (this.errorParamConnexion) {
			//Création de l'alerte
			let alerte = new Alerte();
			alerte.niveau = NiveauAlerte.ERROR;

			alerte.titre = "admin.voyages.travelhub.sbtConfig.alerte.errorParamConnexion.titre";
			alerte.message = "admin.voyages.travelhub.sbtConfig.alerte.errorParamConnexion.message";

			//Ajout de l'alerte à la liste
			listeAlertes.push(alerte);
		}

		//Création d'une alerte dans le cas où une communauté est requise mais manquante
		if (this.errorCommunaute) {
			//Création de l'alerte
			let alerte = new Alerte();
			alerte.niveau = NiveauAlerte.ERROR;
			alerte.titre = "admin.voyages.travelhub.sbtConfig.alerte.errorCommunaute.titre";
			alerte.message = "admin.voyages.travelhub.sbtConfig.alerte.errorCommunaute.message";

			//Ajout de l'alerte à la liste
			listeAlertes.push(alerte);
		}

		//Création d'une alerte dans le cas où une catégorie voyageur est requise mais manquante
		if (this.errorCategorieVoyageur) {
			//Création de l'alerte
			let alerte = new Alerte();
			alerte.niveau = NiveauAlerte.ERROR;
			alerte.titre = "admin.voyages.travelhub.sbtConfig.alerte.errorCategorieVoyageur.titre";
			alerte.message = "admin.voyages.travelhub.sbtConfig.alerte.errorCategorieVoyageur.message";

			//Ajout de l'alerte à la liste
			listeAlertes.push(alerte);
		}

		//Création d'une alerte dans le cas où un RLS est requis mais manquant
		if (this.errorRLS) {
			//Création de l'alerte
			let alerte = new Alerte();
			alerte.niveau = NiveauAlerte.ERROR;
			alerte.titre = "admin.voyages.travelhub.sbtConfig.alerte.errorRLS.titre";
			alerte.message = "admin.voyages.travelhub.sbtConfig.alerte.errorRLS.message";

			//Ajout de l'alerte à la liste
			listeAlertes.push(alerte);
		}

		//Création de l'objet et ajout des alertes le cas échéant
		if (listeAlertes.length > 0) {
			listeAlertes.forEach(a => this.listeAlertes.add(a));
		}
	}

}