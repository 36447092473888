<div>
    <div class="content">
        <page-header [extraInfo]="configAlerte?.isProfilOnline ?
                        ((configAlerte?.lienProfilConnexion?.updated ? 'profilUser.infoPerso.synchroPV.synchroniseKO' : 'profilUser.infoPerso.synchroPV.synchroniseOK') | translate) :
                        null"
                     (onExtraInfoClick)="showListeSynchro()"
                     (onSelectedItemChange)="onSelectedItemChange($event)"
                     [listeItems]="listeTabItems"
                     [niveauAlerte]="niveauAlerte"
                     [title]="'profilUser.title'| translate">
            <preview-alerte
                    *ngIf="isShowRGPD() || (isShowAlerte() && (!listeAlertesAff || listeAlertesAff.length == 0))"
                    [htmlMessage]="!isShowRGPD()"
                    [message]="isShowRGPD() ? ('profilUser.infoPerso.accessInterdit' | translate) : configAlerte?.messageAccueil"
                    [withAccueilIcon]="isShowAlerte() && !isShowRGPD()"
                    [withRGPDIcon]="isShowRGPD()"
                    [withIcon]="!(isShowRGPD() || isShowAlerte())">
            </preview-alerte>
            <preview-alerte *ngFor="let alerte of listeAlertesAff" [message]="alerte.message" [withIcon]="true"></preview-alerte>
        </page-header>

        <div [hidden]="selectedItem.code != 'PROFIL'">
            <profil-user [settings]="settings"></profil-user>
        </div>

        <div [hidden]="selectedItem.code != 'DONNEES'">
            <profil-donnees (refreshAlertes)="refreshAlertes()" (onAllListeLoaded)="onAllListeLoaded()" (onSynchroChanged)="updateSynchroStatut($event)" [alertes]="alertesSubject"></profil-donnees>
        </div>

        <div *ngIf="selectedItem.code == 'PJ'">
            <document-list 
                [idObjet]="user.idUser"
                [idCollaborateur]="user.idUser"
                [context]="'PROFIL'"
                [settings]="settings"
                [canAddDocument]="false"
                [canDeleteDocument]="false"
            ></document-list>
        </div>

        <div [hidden]="selectedItem.code != 'CGU'">
            <profil-cgu [infoCgu]="infoCgu"></profil-cgu>
        </div>

        <div [hidden]="selectedItem.code != 'APROPOS'">
            <a-propos></a-propos>
        </div>
    </div>
    <please-wait *ngIf="false" class="content"></please-wait>
</div>

<!-- Template de la popup d'historique des synchronisations SBT -->
<ng-template let-data #listeSynchroTemplate>
    <div>
        <h1 mat-dialog-title>
            <span translate>profilUser.infoPerso.synchroPV.synchronisationsTitle</span>
            <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
        </h1>
        <div mat-dialog-content class="d-flex">
            <div *ngFor="let synchro of data.liste">
                <span [translate]="'profilUser.infoPerso.synchroPV.statutSynchronise'"
                    [translateParams]="{libelle: synchro.message, date: synchro.dateSynchro | date:'medium'}">
                </span>
                <br/>
            </div>
        </div>
    </div>
</ng-template>
