import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Composant permettant de saisir le motif des frais
 */
@Component({
    host: { 'data-test-id': 'ndf-frais-add-motif' },
    selector: 'ndf-frais-add-motif',
    templateUrl: './ndf-frais-add-motif.component.html',
    styles: [
        '::ng-deep #motifForm .mat-tab-body-content { padding-top: 10px; }'
    ]
})
export class NDFFraisAddMotifComponent {
    /* Motif saisi dans le textArea */
    motif: string = '';

    constructor(private matDialogRef: MatDialogRef<NDFFraisAddMotifComponent>) {

    }

    /* Event du click sur le bouton rejet */
    onRejeterFrais(): void {
        //On renvoie le motif saisie        
        this.matDialogRef.close(this.motif);
    }
}