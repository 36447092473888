import {NgModule} from '@angular/core';
import {ShareModule} from "@share/share.module";
import {DematComponent} from "./demat.component";
import {DematModuleComponent} from "@components/admin/parametre/demat/module/demat-module.component";
import {ModuleItemComponent} from "@components/admin/parametre/demat/module/module-item/module-item.component";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {ExtensionComponent} from './extension/extension.component';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ExtensionListeItemComponent} from "@components/admin/parametre/demat/extension/extension-liste-item/extension-liste-item.component";
import {ExtensionAddComponent} from "@components/admin/parametre/demat/extension/extension-add/extension-add.component";
import {DematOcrComponent} from "@components/admin/parametre/demat/ocr/demat-ocr.component";
import {WorkflowModule} from "@components/workflow/workflow.module";
import {Routes} from "@angular/router";
import {PieceJointeComponent} from "@components/admin/parametre/demat/piece-jointe/piece-jointe.component";
import {ParamPorteeComponent} from "@components/admin/parametre/demat/piece-jointe/param-portee/param-portee.component";
import {FiltreParamComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-param.component";
import {PieceJointeService} from "@components/admin/parametre/demat/piece-jointe/piece-jointe.service";
import {ParamPorteeService} from "@components/admin/parametre/demat/piece-jointe/param-portee/param-portee.service";
import {FiltreParamItemComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-param-item/filtre-param-item.component";
import {FiltreParamService} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-param.service";
import {FiltreDialogComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-dialog/filtre-dialog.component";
import {FiltreDialogItemComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-dialog/filtre-dialog-item/filtre-dialog-item.component";
import {StockageLocalComponent} from "@components/admin/parametre/demat/piece-jointe/local/stockage-local.component"
import {DocumentModule} from "@components/document/document.module";
import {TypeComponent} from "@components/admin/parametre/demat/type/type.component";
import {TypeItemComponent} from "@components/admin/parametre/demat/type/type-item/type-item.component";
import {TypeDialogComponent} from "@components/admin/parametre/demat/type/type-dialog/type-dialog.component";
import {FormsModule} from '@angular/forms';
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {JsonPipe} from "@angular/common";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";

export const parametreDematRoutes: Routes = [
	{
		path: 'Modules',
		component: DematModuleComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_DEMATERIALISATION] }
	},{
		path: 'PiecesJointes',
		component: PieceJointeComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Extensions',
		component: ExtensionComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Types',
		component: TypeComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Ocr',
		component: DematOcrComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_DEMATERIALISATION] }
	},{
		path: '',
		redirectTo: 'Modules',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Modules'
	}
]

@NgModule({
	imports: [ShareModule,WorkflowModule,PreviewAlerteModule,DocumentModule,FormsModule,CodemirrorModule,PreviewAlerteModule],
	declarations: [
		DematComponent,
		DematModuleComponent,
		DematOcrComponent,
		ModuleItemComponent,
		ModuleItemComponent,
        ExtensionComponent,
        ExtensionListeItemComponent,
        ExtensionAddComponent,
		PieceJointeComponent,
		ParamPorteeComponent,
		FiltreParamComponent,
		FiltreParamItemComponent,
		FiltreDialogComponent,
		FiltreDialogItemComponent,
		StockageLocalComponent,
		TypeComponent,
		TypeItemComponent,
		TypeDialogComponent,
	],
	exports: [
		DematComponent
	],
	providers: [
        DematService,
		PieceJointeService,
		ParamPorteeService,
		FiltreParamService,
		JsonPipe
	]
})
export class DematModule {
}
