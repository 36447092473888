<h1 mat-dialog-title>
    <span>{{ rootTradField + '.title' | translate }}</span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="!fieldUsed"></please-wait>
    <form #form="ngForm" autocomplete="off">
        <fieldset *ngIf="!!fieldUsed">
            <div class="row">
                <!-- Code -->
                <custom-input ngDefaultControl
                              lCol="4" rCol="8"
                              id="code" name="code"
                              customType="objectselect" libelle="admin.voyages.travelhub.sbtConfig.parametres.code"
                              [(ngModel)]="fieldUsed.field"
                              optionValue="code"
                              optionDisplay="code"
                              [selectOptions]="data.listeField"
                              [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Libellé -->
                <custom-input ngDefaultControl
                              lCol="4" rCol="8"
                              id="libelle" name="libelle"
                              customType="input" libelle="admin.voyages.travelhub.sbtConfig.parametres.libelle"
                              [(ngModel)]="data.field.libelleCustomCode"
                              [postTooltip]="rootTradField + '.tooltip.saisieCodePersonnalise' | translate"
                              [required]="true">
                </custom-input>
            </div>

            <!-- Valeur par sélection depuis une liste de valeurs possibles -->
            <div class="row">
                <label class="col-md-4 required" [class.has-error]="valeurFixe.invalid || valeurSql?.invalid || valeurField.invalid">{{ 'admin.voyages.travelhub.sbtConfig.parametres.valeur' | translate }}</label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex" appearance="none" floatLabel="never">
                        <span class="clickable flex-grow-1" (click)="openValeur()">
                            <span class="r-flex align-items-center justify-content-between" style="border-bottom: 1px solid;">
                                <ng-container *ngIf="!!fieldUsed.idValeur; else tplNoChamp">
                                    {{ FiltreChamp.traduction(champ.filtre) | translate }} &rarr; {{ champ.libelle | translate }}
                                </ng-container>
                                <ng-template #tplNoChamp>{{'admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.selectionnerValeur' | translate}}</ng-template>
                                <mat-icon>search</mat-icon>
                            </span>
                            <input matInput ngDefaultControl #valeurField="ngModel" name="valeurField"
                                   [hidden]="true"
                                   [class.cdk-visually-hidden]="true"
                                   [ngModel]="!fieldUsed.idValeur ? null : fieldUsed.idValeur"
                                   [required]="![AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE,AdminTravelhubService.TYPE_FIELD_SQL].includes(fieldUsed.idValeur)">
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <!-- SQL -->
            <div class="row" [hidden]="fieldUsed.idValeur !== AdminTravelhubService.TYPE_FIELD_SQL">
                <div class="col-md-8 offset-4">
                    <ngx-codemirror *ngIf="opened$ | async"
									#codeMirror
                                    name="valeurSql"
                                    [options]="codeMirrorOptions"
                                    [(ngModel)]="fieldUsed.requete"
                                    [required]="fieldUsed.idValeur == AdminTravelhubService.TYPE_FIELD_SQL"
                    ></ngx-codemirror>
                </div>
            </div>

            <!-- Valeur fixe -->
            <div class="row" [hidden]="fieldUsed.idValeur !== AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE">
                <custom-input ngDefaultControl
							  #valeurFixe="ngModel"
                              lCol="4" rCol="8"
                              rClass="offset-md-4"
                              name="valeurFixe"
                              libelle=""
                              customType="input"
                              [(ngModel)]="fieldUsed.valeur"
                              [required]="fieldUsed.idValeur == AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE"
                ></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!!fieldUsed">
    <!-- Bouton de suppression -->
    <button mat-stroked-button color="warn" [disabled]="isProcessing" (click)="deleteField()" *ngIf="fieldUsed.getId() > 0">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <!-- Bouton d'enregistrement' -->
    <button mat-flat-button color="primary" [disabled]="form.invalid || isProcessing" (click)="saveField()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
