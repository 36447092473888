import {Component, OnInit} from '@angular/core';
import { Version } from '@domain/a-propos/version';
import { first } from 'rxjs/operators';
import { ProfilService } from '../profil.service';

/**
 * Composant portant les liens vers les sites du produit et de l'éditeur, ainsi que les mentions légales
 */
@Component({
    host: {'data-test-id': 'a-propos'},
    selector: 'a-propos',
    templateUrl: './a-propos.component.html',
    styleUrls: ['./a-propos.component.scss']
})
export class AProposComponent implements OnInit {

    /**
     * Infos de version de l'application
     */
    appInfos: Version;

    /**
     * Constructeur
     */
    constructor(
        private profilService : ProfilService
    ) { }


    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Chargement des informations de version de l'application
        this.profilService.getAppInfos().pipe(first()).subscribe(result => {
            this.appInfos = result?.data?.version;
        });
    }
}
