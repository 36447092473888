<mat-card>
  <mat-card-title>
            <span [translate]="'admin.bibliotheque.internationalisation.infosLangue.langueCard.title'"></span>
  </mat-card-title>
  <mat-card-content>
    <form #form="ngForm" *ngIf="langue" (change)="onVariantChange($event)">
      <fieldset>
        <div class="row">
          <custom-input ngDefaultControl
                        id="code"
                        name="code"
                        [(ngModel)]="langue.code"
                        customType="input"
                        maxlength="2"
                        minlength="2"
                        libelle="admin.bibliotheque.internationalisation.infosLangue.langueCard.code"
                        [required]="true"
                        [readonly]="langue.idLangue"></custom-input>
          <custom-input ngDefaultControl
                        id="isActif"
                        name="isActif"
                        [ngModel]="langue.parDefaut"
                        [(customModel)]="langue.parDefaut"
                        customType="checkbox"
                        (customModelChange)="onDefautChange($event)"
                        libelle="admin.bibliotheque.internationalisation.infosLangue.langueCard.parDefaut"></custom-input>
        </div>
        <div class="row">
          <custom-input ngDefaultControl
                        id="libelle"
                        name="libelle"
                        [(ngModel)]="langue.libelle"
                        customType="input"
                        libelle="admin.bibliotheque.internationalisation.infosLangue.langueCard.libelle"
                        [required]="true"></custom-input>
          <custom-input *ngIf="langue.idLangue" ngDefaultControl
                        id="dateModification"
                        name="dateModification"
                        libelle="admin.bibliotheque.internationalisation.infosLangue.langueCard.dateModification"
                        [ngModel]="langue.dateModification"
                        [(customModel)]="langue.dateModification"
                        customType="date"
                        [readonly]="true"></custom-input>
        </div>
        <div class="row">
          <custom-input ngDefaultControl
                        *ngIf="!!selectedVariant"
                        id="variant"
                        name="variant"
                        customType="objectselect"
                        [(ngModel)]="selectedVariant"
                        libelle="admin.bibliotheque.internationalisation.infosLangue.langueCard.codeVariant"
                        optionValue="code"
                        optionDisplay="libelle"
                        [isSelectAvecVide]="false"
                        [valeurIdTampon]="'objetTampon'"
                        (onChange)="onVariantChange()"
                        [postTooltip]="'admin.bibliotheque.internationalisation.infosLangue.langueCard.codeVariantTooltip' | translate"
                        [required]="true"
                        [isSelectAvecInput]="true"
                        [selectOptions]="shortListVariant"></custom-input>
          <custom-input ngDefaultControl
                        id="preview"
                        name="preview"
                        [ngModel]='(previewDate | date:"shortDate":undefined:previewVariant) + "   " + (previewDate | date:"shortTime":undefined:previewVariant) + "   " + (previewAmount | montant: "EUR":previewVariant)'
                        customType="input"
                        libelle="admin.bibliotheque.internationalisation.infosLangue.langueCard.preview"
                        [readonly]="true"></custom-input>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <div class="col-10"><json-selector [selectedFile]="selectedFile" (fileSelected)="onFileSelected($event)" [isDangerous]="langue?.code == 'fr'" [custom]="custom" [isCreation]="!langue.idLangue" [codeLangue]="langue.code" [isSwichable]="langue.standard"></json-selector></div>
          <div class="col-1"></div>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="langue?.idLangue">
  <mat-card-title>
    <span [translate]="'admin.bibliotheque.internationalisation.infosLangue.infosCard.title'"></span>
  </mat-card-title>
  <mat-card-content>
    <form>
      <fieldset>
        <row-data [dataGauche]="{traduction: 'admin.bibliotheque.internationalisation.infosLangue.infosCard.standard.title', valeur:(('global.' + (langue.standard ? 'oui' : 'non')) | translate), tooltip:('admin.bibliotheque.internationalisation.infosLangue.infosCard.standard.tooltip'| translate)}"
                  [dataDroite]="{traduction: 'admin.bibliotheque.internationalisation.infosLangue.infosCard.statistiques.title', valeur:('admin.bibliotheque.internationalisation.infosLangue.infosCard.statistiques.count' | translate:{ count: langue.useCount }), tooltip:('admin.bibliotheque.internationalisation.infosLangue.infosCard.statistiques.tooltip'| translate)}"></row-data>
      </fieldset>
    </form>
  </mat-card-content>
</mat-card>
