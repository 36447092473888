<h1 mat-dialog-title>
    <span [translate]="'od.engagements.indemnites.degrevements.degrevementMasse'"></span>
    <span [mat-dialog-close]="data.needRefresh"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #actionMasseForm="ngForm">
        <!-- Indemnités à modifier -->
        <div class="row">
            <custom-input ngDefaultControl name="indemnite"
                customType="multiselect"
                libelle="od.engagements.indemnites.degrevements.Indemnites"
                [(ngModel)]="listeIndemnitesSelectionnees"
                [required]="true"
                optionValue="id"
                optionDisplay="libelle"
                [selectOptions]="data.listeRegles"
                lCol="4"
                rCol="7">
            </custom-input>
        </div>

        <!-- Date de début des actions de masse -->
        <div class="row">
            <label class="col-md-4 required" [ngClass]="{ 'has-error': isDateDuInvalide() }">
                <span translate>od.engagements.indemnites.degrevements.du</span>
            </label>
            <div class="col-md-7">
                <date-range-custom [ngModel]="dateDebut"
                    [(maDate)]="dateDebut"
                    name="dateDebut" ngDefaultControl
                    [rangeDeb]="data.dateMin"
                    [rangeEnd]="data.dateMax"
                    [dateStart]="data.dateMin"
                    [required]="true"
                    [maxDate]="getMaxDateRange()"
                    [minDate]="getMinDateRange()"
                    notNullValidator>
                </date-range-custom>
            </div>
        </div>

        <!-- Date de fin des actions de masse -->
        <div class="row">
            <label class="col-md-4 required" [ngClass]="{ 'has-error': isDateAuInvalide() }">
                <span translate>od.engagements.indemnites.degrevements.au</span>
            </label>
            <div class="col-md-7">
                <date-range-custom [ngModel]="dateFin"
                    [(maDate)]="dateFin"
                    name="dateDebut" ngDefaultControl
                    [rangeDeb]="data.dateMin"
                    [rangeEnd]="data.dateMax"
                    [dateStart]="data.dateMax"
                    [required]="true"
                    [maxDate]="getMaxDateRange(false)"
                    [minDate]="getMinDateRange(false)"
                    notNullValidator>
                </date-range-custom>
            </div>
        </div>

        <!-- Action à effectuer -->
        <div class="row">
            <custom-input ngDefaultControl name="action"
                customType="select"
                libelle="od.engagements.indemnites.degrevements.action"
                [(ngModel)]="action"
                [required]="true"
                optionValue="id"
                optionDisplay="libelle"
                [selectOptions]="listeActions"
                lCol="4"
                rCol="7">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [disabled]="isSaving" [mat-dialog-close]="data.needRefresh">
        <span translate>global.actions.fermer</span>
    </button>
    <button mat-flat-button color="primary" [disabled]="isSaving || actionMasseForm.invalid || isDateDuInvalide() || isDateAuInvalide()" (click)="enregistrer()">
        <span *ngIf="!isSaving"  translate>global.actions.valider</span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
