import {Component,Input,OnInit} from '@angular/core';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {ListItem} from '@domain/common/list-view/list-item';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {environment} from '@environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmService} from '@share/component//confirmation/confirm.service';
import {ImageViewerService} from '@share/component//image-viewer/image-viewer.service';
import {ToastrService} from 'ngx-toastr';
import {filter,mergeMap} from 'rxjs/operators';
import {IMAGE_TYPES} from './document';
import {DocumentService} from './document.service';
import {ContextDocument} from "@domain/document/context-document";

@Component({
    templateUrl: './document-list-item.component.html'
})
export class DocumentListItemComponent extends ListViewItem<any> implements OnInit {
    /** Elément courant **/
    @Input() data: any;

    /** Alignement de l'image à droite */
    @Input() isAlignRight: boolean = false;

    /** Activation du téléchargement d'un document */
    canDownloadDocument: boolean = false;

    /** Étiquettes personnalisées */
    get customTags(): (data: any) => { libelle: string, value: string }[] {
        return this.extraOptions?.customTags;
    }

    /**
     * Constructeur
     */
    constructor(
            private documentService: DocumentService,
            private confirmService: ConfirmService,
            private translateService: TranslateService,
            private imageViewerService: ImageViewerService,
            private toastService: ToastrService) {
        //Héritage
        super();
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        if (this.data?.idObjet !== 0) {
            this.canDownloadDocument = true;
        }
    }

    /**
     * Téléchargement du fichier
     */
    download(): void {
        if (this.extraOptions.context) {
            this.documentService.downloadFile(this.data.idDocument,this.data.fichier,this.extraOptions.context).subscribe(url => {
                if (!url) {
                    this.toastService.error(this.translateService.instant('document.erreur.telechargementImpossible'));
                }
            });
        } else {
            this.toastService.error(this.translateService.instant('document.erreur.contexteInconnu'));
        }
    }

    /**
     * Lecture du fichier
     */
    open() {
        //Si le click n'a pas été désactivé
        if (this.extraOptions.isClickable == null || this.extraOptions.isClickable === true) {
            //Vérification du type de document
            if (IMAGE_TYPES.indexOf(this.data.contentType) != -1) {
                //Visualisation de l'image
                this.imageViewerService.show(`${environment.baseUrl}/controller/Document/readDocument/${this.data.idDocument}?contexte=${this.extraOptions.context}`, this.isAlignRight);
            } else {
                //Ouverture du fichier
                window.open(`${environment.baseUrl}/controller/Document/readDocument/${this.data.idDocument}`);
            }
        }
    }

    /**
     * Suppression du fichier
     */
    delete() {
        //Vérification de la possibilité de suppression
        if (this.extraOptions.canDeleteDocument === false) {
            return false;
        }

        //Suppression après confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(
            filter(isConfirmed => isConfirmed),
            mergeMap(() => this.documentService.delete(this.extraOptions.context,this.data.idDocument,this.extraOptions.idObjet))
        ).subscribe(
            (result: Result) => {
                //Si le document est déjà utilisé et qu'on a un message custom pour ce cas
                if (result.codeErreur == TypeCodeErreur.ERROR_ALREADY_USED && this.extraOptions.errorAlreadyUsedMessage != null) {
                    this.toastService.error(this.translateService.instant(this.extraOptions.errorAlreadyUsedMessage));
                } else if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    //On lance l'événement de suppression pour avertir le parent
                    this.extraOptions?.onDelete(this.data.libelle);

                    //Pas d'erreur on peut enlever le document de la liste
                    (this.data as ListItem).removeFromListView();

                } else {
                    //Erreur globale
                    this.toastService.error(this.translateService.instant('global.errors.suppression'));
                }
            }
        );
    }

    /**
     * Indique si on affiche le type
     * @param context le context de l'item
     */
    isShowType(context: ContextDocument) {
        const listeContextAAfficher = [
            ContextDocument.PROFIL_VEHICULE_CARTE_GRISE,
            ContextDocument.PROFIL_VEHICULE_ASSURANCE,
            ContextDocument.PROFIL_VEHICULE_AUTORISATION
        ]

        return listeContextAAfficher.includes(context);
    }

    /**
     * Retourne le texte à afficher pour le type
     * @param context le contexte courant
     */
    getTypeTexte(context: ContextDocument) {
        let translate;

        //On sélectionne l'id à traduire
        switch(context) {
            case ContextDocument.PROFIL_VEHICULE_CARTE_GRISE:
                translate = "carteGrise";
                break;
            case ContextDocument.PROFIL_VEHICULE_ASSURANCE:
                translate = "certificatAssurance";
                break;
            case ContextDocument.PROFIL_VEHICULE_AUTORISATION:
                translate = "autorisationCirculer";
                break;
            default:
                return null;
        }

        //On traduit le message
        let translated = this.translateService.instant("document.type." + translate);

        //On rajoute l'immatriculation
        translated = `${translated} (${this.data.attributC})`;

        return translated;
    }
}
