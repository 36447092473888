<h1 mat-dialog-title>
    <span [translate]="isCreation ? 'admin.voyages.referentiels.infos.headerTitleCarteVoyageurCreation' : 'admin.voyages.referentiels.infos.headerTitleCarteVoyageur'"
          [translateParams]="{ libelle: carteVoyageur.libelle }"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="py-1">
    <form #form="ngForm">
        <fieldset *ngIf="carteVoyageur">
            <div class="row">
                <custom-input ngDefaultControl
                              id="libelle"
                              name="libelle"
                              [(ngModel)]="carteVoyageur.libelle"
                              customType="input"
                              rCol="2"
                              [readonly]="!carteVoyageur.custom && !isCreation"
                              libelle="admin.voyages.referentiels.infos.libelle"
                              [required]="true"></custom-input>

                <custom-input ngDefaultControl
                              id="isActif"
                              name="isActif"
                              [ngModel]="carteVoyageur.actif"
                              [(customModel)]="carteVoyageur.actif"
                              customType="checkbox"
                              lCol="4"
                              rCol="2"
                              libelle="admin.voyages.referentiels.infos.actif"
                              [postTooltip]="'admin.voyages.referentiels.cartesVoyageursToolTip' | translate"></custom-input>

            </div>
            <div class="row" *ngIf="carteVoyageur.compagnie && listeCompagnies">
                <custom-input ngDefaultControl
                              id="compagnie"
                              name="compagnie"
                              [(ngModel)]="carteVoyageur.compagnie"
                              customType="objectselect"
                              rCol="2"
                              [selectOptions]="listeCompagnies"
                              [readonly]="!carteVoyageur.custom && !isCreation"
                              optionValue="idCompagnie"
                              optionDisplay="libelle"
                              libelle="admin.voyages.referentiels.infos.compagnie"></custom-input>
                <custom-input *ngIf="!isCreation && carteVoyageur.dateCreated"
                              ngDefaultControl
                              id="dateCreated"
                              name="dateCreated"
                              [ngModel]="carteVoyageur.dateCreated"
                              [(customModel)]="carteVoyageur.dateCreated"
                              customType="date"
                              lCol="4"
                              rCol="2"
                              [readonly]="true"
                              libelle="admin.voyages.referentiels.infos.dateCreation"></custom-input>
            </div>
            <div class="row" *ngIf="typeCarte && typesCarte">
                <custom-input ngDefaultControl
                              id="typeCarte"
                              name="typeCarte"
                              [(ngModel)]="typeCarte"
                              customType="objectselect"
                              rCol="2"
                              [selectOptions]="typesCarte"
                              [readonly]="!carteVoyageur.custom && !isCreation"
                              optionValue="id"
                              optionDisplay="libelle"
                              libelle="admin.voyages.referentiels.infos.typeCarte"></custom-input>
                <custom-input *ngIf="!isCreation && carteVoyageur.dateUpdated"
                              ngDefaultControl
                              id="dateUpdated"
                              name="dateUpdated"
                              [ngModel]="carteVoyageur.dateUpdated"
                              [(customModel)]="carteVoyageur.dateUpdated"
                              customType="date"
                              lCol="4"
                              rCol="2"
                              [readonly]="true"
                              libelle="admin.voyages.referentiels.infos.derniereSynchronisation"></custom-input>
            </div>
            <div class="row" *ngIf="typePresta && typesPresta">
                <custom-input ngDefaultControl
                              id="typePrestation"
                              name="typePrestation"
                              [(ngModel)]="typePresta"
                              customType="objectselect"
                              rCol="2"
                              [selectOptions]="typesPresta"
                              [readonly]="!carteVoyageur.custom && !isCreation"
                              optionValue="id"
                              optionDisplay="libelle"
                              libelle="admin.voyages.referentiels.infos.typePrestation"></custom-input>
            </div>
            <div class="row" *ngIf="typeChamp && typesChamps">
                <custom-input ngDefaultControl
                              id="typeChamp"
                              name="typeChamp"
                              [(ngModel)]="typeChamp"
                              customType="objectselect"
                              rCol="2"
                              [selectOptions]="typesChamps"
                              [readonly]="!carteVoyageur.custom && !isCreation"
                              optionValue="id"
                              optionDisplay="libelle"
                              libelle="admin.voyages.referentiels.infos.typeChamp"></custom-input>
            </div>
            <div class="row" *ngIf="carteVoyageur.code != null">
                <custom-input ngDefaultControl
                              id="code"
                              name="code"
                              [(ngModel)]="carteVoyageur.code"
                              customType="input"
                              rCol="2"
                              libelle="admin.voyages.referentiels.infos.code"
                              [readonly]="!carteVoyageur.custom && !isCreation"
                              [required]="true"></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="delete()" *ngIf="!this.isCreation && this.carteVoyageur.custom" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="save()" [disabled]="isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
