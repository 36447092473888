import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from "moment";

@Component({
	selector: 'referentiel-externe-add',
	templateUrl: './referentiel-externe-add.component.html'
})
export class ReferentielExterneAddComponent implements OnInit {
	/** Référence externe **/
	public referenceExterne: any;

	/**
	 * Constructeur
	 */
	constructor(private dialogRef: MatDialogRef<ReferentielExterneAddComponent>, @Inject(MAT_DIALOG_DATA) public data: { referenceExterne: any }) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la référence externe
		this.referenceExterne = this.data.referenceExterne;
	}

	/**
	 * Fermeture de la pop-up pour enregistrement
	 */
	save() {
		//Construction de la date+heure
		this.computeDates();

		//Fermeture de la popup
		this.dialogRef.close(this.referenceExterne);
	}

	/**
	 * Construction de la date avec momentjs, pour concaténer la date avec l'heure.
	 */
	computeDates(): void {
		//Construction de la date momentjs
		let date = moment(this.referenceExterne.date);

		//Ajout de l'heure à la date
		if (this.referenceExterne.heure?.length == 5) {
			date.hours(parseInt(this.referenceExterne.heure.substr(0, 2), 10));
			date.minutes(parseInt(this.referenceExterne.heure.substr(3, 2), 10));
		}

		//Mise à jour de l'objet Date dans referenceExterne
		this.referenceExterne.date = date.toDate();
	}
}
