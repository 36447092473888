import {Component,Inject,OnInit} from "@angular/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {
	AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent
} from "@components/admin/entreprise/analytique/organigramme/organigramme-infos/organigramme-liste-utilisateur-item/organigramme-liste-utilisateur-item.component";
import {ListView,TypeFilter} from "@domain/common/list-view";
import {UserDto} from "@domain/user/user-dto";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";

/**
 * Composant d'affichage de la liste des utilisateurs affectés à un service
 */
@Component({
    host: {'data-test-id': 'organigramme-liste-utilisateur'},
    selector: 'organigramme-liste-utilisateur',
    templateUrl: './organigramme-liste-utilisateur.component.html'
})
export class AnalytiqueOrganigrammeOrgaListeUtilisateurComponent implements OnInit {
    /** Liste des utilisateurs affectés à un service */
    listeUtilisateurs: ListView<UserDto, AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent>;

    /**
     * Initialisation du composant
     *
     * @param analytiqueService Service de gestion des informations sur l'analytique
     * @param translateService Service de traduction
     * @param matDialogRef Référence vers une boîte de dialogue
     * @param data Données injectées dans le composant
     */
    constructor(
        private analytiqueService: AnalytiqueService,
        private translateService: TranslateService,
        private matDialogRef: MatDialogRef<AnalytiqueOrganigrammeOrgaListeUtilisateurComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            idOrga: number
        }
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Initialisation de la liste des utilisateurs pour utiliser la list-view
        this.listeUtilisateurs = new ListView<UserDto,AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent>({
            uri: `/controller/Orga/getUsersOrga/` + this.data.idOrga,
            title: this.translateService.instant('admin.entreprise.analytique.organigramme.listeUtilisateurs.utilisateurs'),
            component: AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent,
            isLocal: true,
            defaultOrder: 'nom',
            listeFilters: [
                {
                    clef: 'nom',
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                },
                {
                    clef: 'prenom',
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                },
                {
                    clef: 'nomAcces',
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                },{
                    clef: 'matricule',
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                },{
                    clef: 'orga.libelle',
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                }
            ]

        });
    }
}
