import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute,Router} from '@angular/router';
import {VehiculeService} from '@components/vehicule/vehicule.service';
import {AppState} from '@domain/appstate';
import {ListeAlertes} from '@domain/common/alerte/listeAlertes';
import {SettingsVehiculeState} from '@domain/settings/settings';
import {PuissanceFiscale} from '@domain/vehicule/puissancefiscale';
import {Status,Vehicule} from '@domain/vehicule/vehicule';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {CustomInputComponent} from '@share/component/custom-input/custom-input.component';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {ToastrService} from 'ngx-toastr';
import * as settingsActions from "@reducers/settings";
import {TypePortee} from '@domain/workflow/workflow';
import {filter,first} from "rxjs/operators";
import {VehiculeHistoriqueDistanceComponent} from '@components/vehicule/vehicule-historique-distance.component';
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Subscription} from "rxjs";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {TypeProfil,User} from "@domain/user/user";

/**
 * Composant d'affichage du détail d'un véhicule
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'vehicule-detail'},
    templateUrl: './vehicule-detail.component.html'
})
export class VehiculeDetailComponent implements OnInit,OnDestroy {
    /** Utilisateur connecté */
    connectedUser: User;

    /** Liste des onglets */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet sélectionné */
    selectedItem: PageHeaderItem = null;

    /** Vehicule */
    vehicule: Vehicule;

    /** Plaque d'immatriculation en clair du vehicule */
    immatClair: string;

    /** Liste des alertes */
    listeAlertes: ListeAlertes;

    /** Paramétrage */
    settings: SettingsVehiculeState;

    /** Liste des puissances */
    listePuissance: Array<PuissanceFiscale>;

    /** Motif de rejet du véhicule */
    motifRejet: string;

    /** Référence vers le composant de l'immatriculation */
    @ViewChild("immatComponent")
    immatComponent: CustomInputComponent;

    /** Souscription aux paramètres de navigation */
    souscription: Subscription;

    //Import de l'énum pour le DOM
    protected readonly TypeProfil = TypeProfil;

    /**
     * Constructeur
     *
     * @param store le store de l'application
     * @param translateService le moteur de traduction
     * @param vehiculeService le service de gestion des véhicules
     * @param toastrService le toaster
     * @param matDialog le gestionnaire de popin
     * @param router le routeur Angular
     * @param route la route courante
     */
    constructor(private store: Store<AppState>,
                private translateService: TranslateService,
                private vehiculeService: VehiculeService,
                private toastrService: ToastrService,
                private matDialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Récupération de l'utilisateur connecté
        this.store.select(state => state.session?.user).pipe(filterFirstNotNull()).subscribe((user: User) => {
            //Récupération de l'utilisateur connecté
            this.connectedUser = user

            //Chargement du paramétrage du véhicule
            this.store.dispatch({
                type: settingsActions.LOAD_SETTINGS,
                payload: TypePortee.VP
            });

            //Sélection du paramétrage
            this.store.select(state => state.settings?.[TypePortee.VP]).pipe(filter(settings => !!settings),first()).subscribe(settings => {
                //Récupération du paramétrage
                this.settings = settings;

                //Récupération des paramètres de navigation
                this.souscription = this.route.params.subscribe(params => {
                    //Chargement du véhicule
                    this.loadVehicule(params.idVehicule);
                });
            });

            //Définition des onglets
            this.listeTabItems = [{
                code: 'GENERALITES',
                libelle: this.translateService.instant('vehicule.navigation.generalites')
            },{
                code: 'COMPLEMENTS',
                libelle: this.translateService.instant('vehicule.navigation.complements')
            }];
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désouscription à l'abonnement
        this.souscription.unsubscribe();
    }

    /**
     * Récupération du véhicule
     *
     * @param idPa identifiant du véhicule à récupérer
     */
    loadVehicule(idPa: number): void {
        //Récupération du véhicule
        this.vehiculeService.loadVehicule(idPa).pipe(first()).subscribe((result: Result) => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Construction du véhicule
                this.vehicule = result.data.vehicule;

                //Réinitialisation du backup de l'immatriculation, pour le bon fonctionnement de l'input obfusqué
                setTimeout(() => {
                    this.immatComponent?.initModelBackup();
                });

                //Récupération de la liste des puissances disponibles
                this.listePuissance = result.data.listePuissancefiscale;

                //Vérification si le véhicule est rejeté afin de récupérer son motif de rejet
                if (this.vehicule.statut == Status.STATUT_REFUSE) {
                    this.motifRejet = result.data.vehicule.vehiculeApprobation.motif;
                }

                //Récupération des alertes
                this.listeAlertes = this.vehiculeService.buildAlertes(this.vehicule, this.settings).toListeAlertes();
            } else {
                //Message d'erreur
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);

                //Retour à la liste des véhicules
                this.onGoBack();
            }
        });
    }

    /**
     * Permet de récupérer la traduction du statut véhicule
     *
     * @param status Status
     */
    getStatusLibelle(status: Status) {
        switch (status) {
            case Status.STATUT_NON_TRAITE:
                return this.translateService.instant('vehicule.statut.nonTraite');
            case Status.STATUT_VALIDE:
                return this.translateService.instant('vehicule.statut.valide');
            case Status.STATUT_REFUSE:
                return this.translateService.instant('vehicule.statut.refuse');
            case Status.STATUT_BROUILLON:
                return this.translateService.instant('vehicule.statut.brouillon');
            default:
                return "";
        }
    }

    /**
     * Ouvre la popup d'affichage de l'historique de distance
     */
    openHistoriqueDistance(): void {
        this.matDialog.open(VehiculeHistoriqueDistanceComponent, {
            data: {
                listeCompteur: this.vehicule.listeCompteur
            }
        })
    }

    /**
     * Retour vers la liste
     */
    onGoBack(): void {
        //Navigation vers la liste
        this.router.navigate(['Admin/Entreprise/Vehicules/Vehicules']);
    }

    /**
     * Changement d'onglet
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Récupère le numéro d'immatriculation en clair
     */
    getPlaqueImmatriculationVehiculeNonObfusquee() {
        //Le numéro d'immatriculation en clair n'a pas encore été récupéré
        if (!this.immatClair) {
            //Récupération du numéro d'immatriculation en clair
            this.vehiculeService.getPlaqueImmatriculationVehiculeNonObfusquee(this.vehicule.idPa).subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                  this.immatClair = result.data.immatriculation;

                  //Change la visibilité
                  this.immatComponent.switchModelObfusque(this.immatClair, true);
                } else {
                    //Message d'erreur
                   TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            });
        } else {
            //Change la visibilité
            this.immatComponent.switchModelObfusque(this.immatClair, true);
        }
    }
}
