import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";

/**
 * Module de gestion du TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@NgModule({
	imports: [ShareModule],
	declarations: [
	],
	exports: [
	],
	providers: [
		AdminTravelhubService,
	]
})
export class ProfilConnexionModule {}