import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CollabListItemComponent} from "./collab-list-item.component";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {UserDto} from "@domain/user/user-dto";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TypePortee} from "@domain/workflow/workflow";
import {HttpParams} from "@angular/common/http";

/**
 * Composant d'affichage d'une liste de collaborateurs en vue de la création d'un objet workflow.
 */
@Component({
    templateUrl: './collab-list.component.html',
    host: {'data-test-id': 'collab-list'}
})
export class CollabListComponent implements OnInit {
    /** Liste des collaborateurs */
    liste: ListView<UserDto, CollabListItemComponent>;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                @Inject(MAT_DIALOG_DATA) private data: {portee: TypePortee}) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Construction des paramètres à envoyer pour la récupération de la liste des collaborateurs en fonction de la portée de l'objet à créer
        const params = new HttpParams()
            .append("portee",this.data.portee);
        
        //Définition de la liste
        this.liste = new ListView<UserDto, CollabListItemComponent>({
            uri: `/controller/User/listeCollabPourCreation?` + params.toString(),
            title: this.translateService.instant('workflow.selectionCollab.liste.title'),
            isLocal: true,
            isFilter: true,
            component: CollabListItemComponent,
            listeFilters: [{
                title: this.translateService.instant('filter.prenom'),
                clef: 'prenom',
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            }, {
                title: this.translateService.instant('filter.nom'),
                clef: 'nom',
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            }, {
                title: this.translateService.instant('filter.matricule'),
                clef: 'matricule',
                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                isDefault: true
            }, {
                title: this.translateService.instant('filter.service'),
                clef: 'orga.libelle',
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: false
            }]
        });
    }
}
