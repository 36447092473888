import {Component,Inject,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {SettingsODState} from '../../../../../../../domain/settings/settings';
import {ConfirmService} from '../../../../../../../share/component/confirmation/confirm.service';
import {AppState} from '../../../../../../../domain/appstate';
import {SaisieState} from '../../../../../../../domain/saisie/saisie';
import {SettingsService} from "../../../../../../settings/settings.service";
import {Od} from "../../../../../../../domain/od/od";
import {ODService} from "../../../../../od.service";
import {filter,finalize} from "rxjs/operators";
import {FacturePrev} from "../../../../../../../domain/factureprev/facture-prev";
import {TypeFactPrev} from "../../../../../../../domain/factureprev/type-fact-prev";
import {Fournisseur} from "../../../../../../../domain/facture/fournisseur";
import {Prestation,TypePrestation} from "../../../../../../../domain/prestation/prestation";
import {FormControl} from "@angular/forms";
import {MatSelect} from "@angular/material/select";
import {Nature} from "../../../../../../../domain/prestation/nature";
import {NatureVoyage} from "../../../../../../../domain/prestation/nature-voyage";
import {TypePortee} from "../../../../../../../domain/workflow/workflow";

/**
 * Composant d'affichage de la popin de création d'une facture prévisionnelle
 *
 * @author Laurent Convert
 * @date 04/01/2021
 */
@Component({
    templateUrl: './od-facture-previsionnelle-add.component.html',
    //Surcharge du style des icônes dans le select de la nature
    styles: [
        '::ng-deep .mat-option .material-icons-outlined, ::ng-deep mat-select .material-icons-outlined { font-size: 1.1rem !important; }',
        '::ng-deep .mat-option .nio, ::ng-deep mat-select .nio { font-size: 1.1rem !important; }'
    ]
})
export class OdFacturePrevisionnelleAddComponent implements OnInit,OnDestroy {
    /** Déclaration pour accès depuis le template */
    TypeFactPrev = TypeFactPrev;
    Nature = Nature;
    TypePortee = TypePortee;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur du chargement en cours */
    isLoading: boolean = false;
    
    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving ||this.isDeleting;
    }

    /** Informations de saisie */
    saisieState: SaisieState = null;

    /** Liste des souscriptions */
    listeSouscriptions: Array<Subscription> = new Array<Subscription>();

    @ViewChild("montant")
    montant: FormControl;

    @ViewChild("devise")
    devise: MatSelect;

    /**
     * Constructeur
     */
    constructor(private odService: ODService,
                private store: Store<AppState>,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private confirmService: ConfirmService,
                private settingsService: SettingsService,
                private matDialogRef: MatDialogRef<any,any>,
                @Inject(MAT_DIALOG_DATA) public data: { facturePrev: FacturePrev,refreshListe: (message: string) => void,od: Od,settings: SettingsODState,canModifier: boolean }) {

    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Chargement d'une facture prévisionnelle existante
        if (this.data.facturePrev?.idFacturePrev > 0) {
            //Chargement des données
            this.isLoading = true;

            //Chargement de la facture prévisionnelle
            this.odService.loadFacturePrev(this.data.facturePrev?.idFacturePrev)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe((facturePrev: FacturePrev) => {
                    this.data.facturePrev = facturePrev;

                    //Initialisation
                    this.init();
                });
        } else {
            //Initialisation
            this.init();
        }
    }

    /**
     * Initialisation post-chargement de l'objet
     */
    init(): void {
        //Ecoute de la saisie
        this.listeSouscriptions.push(this.store.select(state => state.saisie).subscribe(saisie => {
            this.saisieState = saisie;
        }));
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Suppression des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * Changement de prestation
     *
     * @param prestation Prestation sélectionnée
     */
    onPrestationChange(prestation: Prestation) {
        //Mise à jour de la nature à partir de celle paramétrée sur la prestation
        this.data.facturePrev.nature = prestation ? NatureVoyage.getNature(prestation.natureVoyage) : null;

        //Mise à jour de la quantité
        this.onPrestationChangeUpdateQuantite(prestation, this.data.facturePrev);
    }

    /**
	 * Force la mise à jour de la quantité sur le frais suivant la prestation sélectionnée
	 *
	 * @param prestation La prestation sélectionnée
	 * @param facturePrev la facture previsionnelle
	 */
	onPrestationChangeUpdateQuantite(prestation: Prestation, facturePrev: {quantite?: number}) {
		//Vérification de la quantité
		if (prestation.quantiteDefaut) {
			//Mise à jour de la quantité par défaut
			facturePrev.quantite = prestation.quantiteDefaut;
		} else if (prestation.type == TypePrestation.FORFAIT && !prestation.quantiteModifiable) {
			//Mise à jour de la quantité
			facturePrev.quantite = 1;
		}
	}

    /**
     * Changement de fournisseur
     *
     * @param fournisseur Fournisseur sélectionné
     */
    onFournisseurChange(fournisseur: Fournisseur) {
        //Si la saisie est invalide (comprenant l'absence de montant puisque le champ est requis), la devise du fournisseur est sélectionnée automatiquement
        if (fournisseur && fournisseur.devise && this.montant?.invalid) {
            //On récupère les options du select, puis on recherche la devise du fournisseur et on la sélectionne
            this.devise?.options?.find((item) => item.value == fournisseur.devise)?.select();
        }
    }

    /**
     * Enregistrement de la dépense
     */
    saveFacturePrev() {
        //Enregistrement en cours
        this.isSaving = true;

        //Mise à jour des clefs étrangères sur la prestation et le fournisseur
        this.data.facturePrev.idPrest = this.data.facturePrev.prestation?.idPrestation;
        this.data.facturePrev.idFournisseur = this.data.facturePrev.fournisseur?.idFournisseur;

        //Enregistrement de la dépense
        this.odService.saveFacturePrev(this.data.facturePrev)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe({
                next: idFacturePrev => {
                    //Vérification de l'enregistrement
                    if (idFacturePrev) {
                        //Rafraichissement de la liste
                        if (this.data.refreshListe) { this.data.refreshListe('global.success.enregistrement'); }

                        //Fermeture de l'écran (on passe true pour déclencher le rechargement de la liste)
                        this.matDialogRef.close({refresh: true});
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                },
                error: () => {
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Suppression de la facture prévisionnelle
     */
    deleteFacturePrev() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression en cours
                this.isDeleting = true;

                //Suppression
                this.odService.deleteFacturePrev(this.data.facturePrev)
                    .pipe(finalize(() => this.isDeleting = false))
                    .subscribe({
                        next: isSuccess => {
                            //Vérification de l'enregistrement
                            if (isSuccess) {
                                //Fermeture de l'écran (on passe true pour déclencher le rechargement de la liste)
                                this.matDialogRef.close({refresh: true, message: 'global.success.suppression'});
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        },
                        error: () => {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
            }
        });
    }

}