import {AfterViewInit,Component,Input,OnDestroy,ViewChild} from '@angular/core';
import {ControlContainer,NgForm} from "@angular/forms";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {ParamConnexion} from "@domain/travel/param-connexion";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {CustomFieldUsed} from "@domain/travel/custom-field-used";
import {TripFieldUsed} from "@domain/travel/trip-field-used";
import {ParamConnexionValeur} from "@domain/travel/param-connexion-valeur";
import {CustomField} from "@domain/travel/custom-field";
import {TripField} from "@domain/travel/trip-field";
import {Observable,Subscription} from "rxjs";
import {ParamConnexionComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion.component";
import {FieldComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/field/field.component";
import {FieldUsed} from "@domain/travel/field-used";

/**
 * Onglet "Paramètres" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'sbt-config-parametres'},
    selector: 'sbt-config-parametres',
    templateUrl: './sbt-config-parametres.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SbtConfigParametresComponent implements AfterViewInit,OnDestroy {
    /* Déclaration pour accès dans le template */
    public readonly TripFieldUsed = TripFieldUsed;
    public readonly CustomFieldUsed = CustomFieldUsed;

    /** Configuration SBT */
    @Input() sbtConfigUsed: TravelHubSBTConfigUsed;

    /** Liste de paramètres de connexions */
    @Input() listeParamConnexion: Array<ParamConnexion> = [];

    /* Listes de paramètres utilisés */
    @Input() listeParamConnexionUsed: Array<ParamConnexionUsed> = [];
    @Input() listeCustomFieldUsed: Array<CustomFieldUsed> = [];
    @Input() listeTripFieldUsed: Array<TripFieldUsed> = [];

    /* Liste de paramètres */
    @Input() listeValeurParamConnexion: Array<ParamConnexionValeur> = [];
    @Input() listeCustomField: Array<CustomField> = [];
    @Input() listeTripField: Array<TripField> = [];

    /** Observable indiquant un changement de SBT */
    @Input() sbtConfigChanged: Observable<void>;

    /** Composant contenant la listview des paramètres de connexion */
    @ViewChild("paramConnexionComponent")
    paramConnexionComponent: ParamConnexionComponent;

    /** Composant contenant la listview des CustomField */
    @ViewChild("customFieldUsedFieldComponent")
    customFieldUsedFieldComponent: FieldComponent<FieldUsed>;

    /** Composant contenant la listview des TripField */
    @ViewChild("tripFieldUsedFieldComponent")
    tripFieldUsedFieldComponent: FieldComponent<FieldUsed>;

    /** Souscription à l'observable de mise à jour du SBT */
    subscriptionSbtConfigChanged: Subscription;

    /**
     * Après l'initialisation de la vue du composant
     */
    ngAfterViewInit() {
        //Abonnement au changement de SBT
        this.subscriptionSbtConfigChanged = this.sbtConfigChanged.subscribe(() => {
                this.paramConnexionComponent.refreshListe();
                this.customFieldUsedFieldComponent.refreshListe();
                this.tripFieldUsedFieldComponent.refreshListe();
            });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement
        this.subscriptionSbtConfigChanged?.unsubscribe();
    }
}