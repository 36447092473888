import {TypeFact} from "./type-fact";
import {Fournisseur} from "./fournisseur";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {MapAction} from "../workflow/mapAction";
import {ZoneUtilisateur} from "../zone-utilisateur/zone-utilisateur";
import {ZoneUtilisateurSaisie} from "../zone-utilisateur/zone-utilisateur-saisie";
import {ListeAlertes} from "../common/alerte/listeAlertes";
import {Analytique} from "@components/analytique/analytique";
import {Statut} from "../workflow/statut";
import {UserDto} from "../user/user-dto";
import {TranslateService} from "@ngx-translate/core";
import {TypePortee} from "../workflow/workflow";
import {TypeEntite,TypeEntiteFC} from "@domain/typeentite/typeEntite";
import {FactureLienOd} from "@domain/facture/facture-lien-od";
import {Od} from "@domain/od/od";
import {PeriodeDTO} from "@domain/exercice/periodeDTO";
import * as moment from 'moment';
import {ListItem} from "@domain/common/list-view/list-item";
import {ReleveFacture} from "@domain/facture/releve-facture";
import {FactureContentieux} from "@domain/facture/facture-contentieux";

/**
 * Représente une facture
 *
 * @author Laurent Convert
 * @date 11/01/2023
 */
export class Facture extends AbstractObjetWorkflow implements ListItem {
    /** Identifiant de la facture */
    idFacture: number;
    /** Identifiant du relevé de facture */
    idReleve: number;
    /** Liste des liens Facture OD */
    listeLiensFacOD: Array<FactureLienOd>;
    /** Créateur associé */
    user: UserDto;
    /** Statut de la facture */
    statut: Statut;
    /** Relevé associé à la facture */
    factureReleve: ReleveFacture;
    /** Contentieux associé à la facture */
    contentieux: FactureContentieux;
    /** Liste des contentieux faisant référence à cette facture */
    listeContentieuxReference: Array<number>;
    /** Numéro de facture */
    numero: string;
    /** Type de facture */
    type: TypeFact;
    /** Date de la facture */
    date: moment.Moment;
    /** Période de la facture */
    periode: PeriodeDTO;
    /** Ventilation analytique associée */
    listeVentilations: Array<Analytique>;
    /** Montant de la facture */
    montant: number;
    /** Code devise du montant de la facture */
    devise: string;
    /** Contrôle montant */
    montantControle: number;
    /** Cours de la devise */
    cours: number;
    /** Montant de la contrepartie */
    contrepartie: number;
    /** Zone personnalisée 1 */
    field1: string;
    /** Zone personnalisée 2 */
    field2: string;
    /** Zone personnalisée 3 */
    field3: string;
    /** Zone personnalisée 4 */
    field4: string;
    /** Type d'entite de la facture */
    typeEntite: TypeEntiteFC;
    /** Commentaire/remarque sur la facture */
    commentaire: string;
    /** Fournisseur associé à la facture */
    fournisseur: Fournisseur;
    /** Liste des zones utilisateurs */
    listeZU: ZoneUtilisateur[];
    /** Liste des zones utilisateurs saisies */
    listeZUSaisies: ZoneUtilisateurSaisie[];
    /** Liste des HistoWorkflow associés */
    listeHistoWorkflow: MapAction;
    /** Liste des alertes */
    listeAlertes: ListeAlertes;
    /** Indique un écart en pourcent entre le montant total et le devis */
    ecartDevis: boolean;
    /** Indique un écart en montant entre le montant total et le devis */
    ecartDevisMontant: boolean;
    /** Montant total des factures sur l'OM */
    montantTotal: number;
    /** Montant du devis */
    montantDevis: number;
    /** Indicateur d'affichage de l'alerte d'un écart de montant entre la facture et le devis */
    alerteEcartDevis: boolean;
    /** Indicateur d'affichage de l'alerte d'un écart de montant entre la facture et le montant de contrôle */
    alerteMontantControle: boolean;

    /* Données transientes */
    /** Identifiant du fournisseur (provient de la facture ou à défaut du relevé) */
    idFournisseur: number;
    /** Libellé du fournisseur (provient de la facture ou à défaut du relevé) */
    libelleFournisseur: string;

    /**
     * Constructeur
     * @param dto Facture source
     */
    constructor(dto?:Facture) {
        super();

        //Vérification du passage d'un DTO
        if (dto) {
            //Copie des propriétés
            Object.assign(this,dto);

            //Conversion de la date en objet Moment le cas échéant
            if (dto.date) {
                this.date = moment(dto.date);
            }

            //Conversion en instance de l'OD lié
            if (dto.od) {
                this.od = new Od(dto.od);
            }

            //Conversion en instance de l'utilisateur lié
            if (dto.user) {
                this.user = new UserDto(dto.user);
            }

            //Conversion en instance du relevé lié
            if (dto.factureReleve) {
                this.factureReleve = new ReleveFacture(dto.factureReleve);
            }

            //Conversion en instance du contentieux
            if (dto.contentieux) {
                this.contentieux = new FactureContentieux(dto.contentieux);
            }

            //Conversion en instance des informations du WF
            if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }

            this.masque = dto.masque;
        }
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        return (this.user.nom ? this.user.nom + ' ' : '')
            + (this.user.prenom ? this.user.prenom : '');
    }

    /**
     * Getter pour l'ID de l'objet
     */
    getId(): number {
        return this.idFacture;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return this.idFacture + ' - ' + (this.numero ?? translateService.instant('facture.liste.numeroNonDefini'));
    }

    /**
     * Getter de la liste des alertes
     */
    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    /**
     * Getter des droits WF sur l'objet
     */
    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    /**
     * Getter du type de portée de l'objet
     */
    getPortee(): TypePortee {
        return TypePortee.FC;
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return true;
    }

    /**
     * Setter de la liste des alertes
     */
    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter du type entité de la facture
     */
    getTypeEntite(): TypeEntite {
        return this.typeEntite;
    }

    /**
     * Getter de l'OD de réconciliation
     */
    get od(): Od | undefined {
        //Un seul OD malgré le fait que le modèle soit une liste...
        return this.listeLiensFacOD?.length > 0 ? this.listeLiensFacOD[0].od : null;
    }

    /**
     * Setter de l'OD de réconciliation
     */
    set od(od: Od){
        //Un seul OD malgré le fait que le modèle soit une liste...
        const lien: FactureLienOd = this.listeLiensFacOD?.length > 0 ? this.listeLiensFacOD[0] : new FactureLienOd();
        lien.idOd = od?.idOd;
        lien.od = od;
        lien.idFacture = this.idFacture;
        this.listeLiensFacOD = [lien];
    }

    getKey(): number {
        return this.getId();
    }

    /** Indicateur de sélection de l'élément */
    isSelected: boolean;
}