<!-- Affichage du cadre de récapitulatif des factures -->
<od-engagements-card
        [title]="'od.engagements.factures.title' | translate"
        [engagements]="engagements"
        [avatar]="avatar"></od-engagements-card>

<!-- Template de l'avatar -->
<ng-template #avatar let-engagement="engagement" let-isPrevisionnel="isPrevisionnel" let-isTotal="isTotal">
    <div class="avatar" [nioTooltip]="engagement?.getTraduction(isPrevisionnel) | translate" nioTooltipPosition="right">
        <!-- Icône en fonction du type -->
        <ng-container *ngIf="!isTotal" [ngSwitch]="engagement?.getType()">
            <i *ngSwitchCase="TypeEngagementFacture.TRAIN" class="icon-train nio align-middle"></i>
            <i *ngSwitchCase="TypeEngagementFacture.AVION" class="icon-avion nio align-middle"></i>
            <i *ngSwitchCase="TypeEngagementFacture.HOTEL" class="material-icons-outlined align-middle">hotel</i>
            <i *ngSwitchCase="TypeEngagementFacture.VOITURE" class="icon-voiture nio align-middle"></i>
            <i *ngSwitchCase="TypeEngagementFacture.DIVERS" class="material-icons-outlined align-middle">help_outline</i>
            <i *ngSwitchCase="TypeEngagementFacture.FRAIS" class="icon-frais nio align-middle"></i>
            <i *ngSwitchDefault class="material-icons-outlined align-middle">question_mark</i>
        </ng-container>
        <span *ngIf="isTotal && isPrevisionnel" class="align-middle" [translate]="'od.engagements.previsionnelAvatar'"></span>
        <span *ngIf="isTotal && !isPrevisionnel" class="align-middle" [translate]="'od.engagements.reelAvatar'"></span>
    </div>
</ng-template>