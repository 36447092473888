import { Directive,ElementRef,AfterViewInit } from '@angular/core';

@Directive({
    selector: '[autofocus]'
})
export class AutoFocusDirective implements AfterViewInit {
	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef<HTMLInputElement>) {}

	/**
     * Détection de l'affichage de la vue
     */
	ngAfterViewInit() {
		//Activation du focus
		setTimeout(() => this.elementRef.nativeElement.focus());
	}
}