import {CommonModule,registerLocaleData} from '@angular/common';
import {HttpClient,HttpClientModule} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {APP_INITIALIZER,DEFAULT_CURRENCY_CODE,NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateLoader,TranslateModule} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import 'hammerjs';
import 'mousetrap';
import {CookieService} from 'ngx-cookie-service';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {ComponentModule} from '@components/component.module';
import {CoreModule} from '@core/core.module';
import {metaReducer,reducers} from '@reducers/reducers';
import {routes} from './routes';
import {LayoutService} from '@share/layout/layout.service';
import {ShareModule} from '@share/share.module';
import {LocaleProvider} from "@services/admin/langue/locale.provider";
import {LangueService} from "@services/admin/langue/langue.service";

//Ajout de codemirror pour l'affichage de code JSON / Javascript
import 'codemirror/mode/javascript/javascript';
//Ajout de codemirror pour l'affichage de SQL
import 'codemirror/mode/sql/sql';

//Enregistrement de la locale par défaut
registerLocaleData(localeFr);

/**
 * Création d'un loader pour les traductions
 */
export function createTranslateLoader(http: HttpClient) {
    //Création du loader
    return new MultiTranslateHttpLoader(http, [
        { prefix: './lang/i18n/', suffix: '_std.json' },
        { prefix: './lang/i18n/', suffix: '_custom.json' }
    ]);
}

/**
 * Chargement des traductions au lancement de l'application
 * @param langueService Service de gestion des Langues
 * @return Une fonction de chargement retournant elle-même une promesse permettant de rester sur le loading tant qu'elle n'a pas été résolue
 */
export function initLocale(langueService: LangueService) {
    return () => langueService.initLocale();
}

@NgModule({
    imports: [BrowserModule,BrowserAnimationsModule,HttpClientModule,CommonModule,FormsModule,
        CoreModule,ShareModule,ComponentModule,
        FilterPipeModule,
        StoreModule.forRoot(reducers,{
            metaReducers: [metaReducer]
        }),
        EffectsModule.forRoot([]),
        RouterModule.forRoot(routes,{
            useHash: true,
            scrollPositionRestoration: 'enabled',
            enableTracing: false
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        CookieService,
        LayoutService,
        LocaleProvider,
        { provide: DEFAULT_CURRENCY_CODE,useValue: '' }, //Pas de devise par défaut, sinon celle de la locale sera utilisée
        { provide: APP_INITIALIZER,useFactory: initLocale, deps: [LangueService], multi: true},
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {}