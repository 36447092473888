import { TypeAction } from '../workflow/workflow';

/**
 * Modèle représentant un historique workflow
 */
 export class WorkflowHistorique {
    /** Données **/
    listeActions?: Array<WorkflowHistoriqueAction>;
    listeActeurs?: Array<WorkflowHistoriqueActeur>;
}

/**
 * Modèle représentant une action d'un historique workflow
 */
export class WorkflowHistoriqueAction {
    /** Données **/
    nom: string;
    prenom: string;
    matricule?: string;
    libelleAction?: string;
    idAction?: number;
    dateAction?: Date;
    libelleStatut?: string;
    libelleRole?: string;
    nomResponsable?: string;
    prenomResponsable?: string;
    motif?: string;

    constructor(dto?: any) {
        if (dto) {
            this.nom = dto.nom;
            this.prenom = dto.prenom;
            this.matricule = dto.matricule;
            this.libelleAction = dto.libelleAction;
            this.idAction = dto.idAction;
            if (dto.dateAction) { this.dateAction = new Date(dto.dateAction); }
            this.libelleStatut = dto.libelleStatut;
            this.libelleRole = dto.libelleRole;
            this.nomResponsable = dto.nomResponsable;
            this.prenomResponsable = dto.prenomResponsable;
            this.motif = dto.motif;
        }
    }
}

/**
 * Modèle représentant un acteur d'un historique workflow
 */
export class WorkflowHistoriqueActeur {
    /** Données **/
    nom: string;
    prenom: string;
    matricule?: string;
    email?: string;
    libelleRole?: string;
    idProfil?: number;
    actions?: Array<TypeAction>;
}