<mat-card *ngIf="licence">
    <mat-card-title>
        <span>{{ licence.raisonSociale }} - {{ licence.codeCRM }}</span>
    </mat-card-title>
    <mat-card-content *ngIf="licence.listeModule">
        <div class="listview">
            <div class="listview-body">
                <div *ngFor="let module of licence.listeModule">
                    <div class="listview-item" [ngClass]="{ 'active': true }">
                        <div class="list-view-item-hover" [ngClass]="{ 'warning': false,'danger': false }">
                            <licence-list-module-item [module]="module"></licence-list-module-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="licence">
    <mat-card-title>
        <span [translate]="'admin.entreprise.description.licence.plugin'"></span>
    </mat-card-title>
    <mat-card-content *ngIf="licence.listePlugin">
        <div class="listview">
            <div class="listview-body">
                <div *ngFor="let plugin of licence.listePlugin">
                    <div class="listview-item" [ngClass]="{ 'active': true }">
                        <div class="list-view-item-hover" [ngClass]="{ 'warning': false,'danger': false }">
                            <licence-list-plugin-item [plugin]="plugin"></licence-list-plugin-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="d-flex flex-row-reverse">
    <button mat-flat-button color="primary"
            (click)="import()">
        <span [translate]="'admin.entreprise.description.licence.import'"></span>
    </button>
</div>