import {Component,OnDestroy,OnInit} from '@angular/core';
import {ActivatedRoute,NavigationEnd,Router} from "@angular/router";
import {Subscription} from "rxjs";
import {filter,map} from "rxjs/operators";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {DematService} from "@services/admin/parametre/demat/demat.service";

/**
 * Admin - Liste des lots comptables<br />
 * Ce composant n'est qu'un wrapper de la vue existante d'un lot par un comptable, mais sans droits.<br />
 * Donne également accès à un onglet "Archivage" (demat) pour l'admin et les sous admin
 *
 * @author Laurent Convert
 * @date 01/08/2024
 */
@Component({
    host: {'data-test-id': 'lots-comptables'},
    templateUrl: './comptabilite-lots-comptables.component.html'
})
export class ComptabiliteLotsComptablesComponent implements OnInit,OnDestroy {
    /* Déclarations pour accès depuis le template */
    readonly NiveauAlerte = NiveauAlerte;

    /** Liste des différents onglets disponibles */
    listeTabItems: Array<PageHeaderItem>;

    /** Alerte à afficher lorsqu'un archivage est en cours  */
    alerteSynchro: Alerte = new Alerte({
        niveau: NiveauAlerte.NO_CONTROL,
        message: this.translateService.instant("admin.parametres.demat.archivage.attenteSynchro")
    });

    /** Indicateur d'affichage du détail d'un lot */
    isInDetail: boolean = false;

    /** Mémorisation de la souscription aux évènements de navigation */
    subscription: Subscription;

    /**
     * Constructeur
     *
     * @param router Routeur angular
     * @param route Route courante
     * @param translateService Service de traduction
     * @param dematService Service de gestion de la demat
     */
    constructor(private router: Router,private route: ActivatedRoute,private translateService: TranslateService,public dematService: DematService) {
        //Souscription aux évènements de navigation pour détecter automatiquement l'affichage du détail d'un lot
        this.subscription = this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map(() => this.route.firstChild),
        ).subscribe(childRoute => {
            //Mise à jour de l'indicateur
            this.isInDetail = childRoute != null;
        });
    }

    /**
     * Handler d'initialisation du composant
     */
    ngOnInit() {
        //Définition de la liste des onglets
        this.listeTabItems = [{
            code: Onglets.LISTE_LOTS,
            libelle: this.translateService.instant('lot.liste.title')
        }];

        //Chargement (stateful) dans le service du statut du module Dématérialisation.
        this.dematService.loadStatutDemat().subscribe();
    }

    /**
     * Handler de destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement
        this.subscription?.unsubscribe();
    }
}

/**
 * Enum des différents onglets
 */
export enum Onglets {
    LISTE_LOTS = "LISTE_LOTS"
}