<h1 mat-dialog-title>
    <span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinCloture.title'" class="warning"></span>
    <span [mat-dialog-close]=""><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="page-header warning">
    <card-alerte [niveau]="niveauAlerte.WARNING">
        <preview-alerte [alerte]="alerte"></preview-alerte>
    </card-alerte>
</div>
<div mat-dialog-content>
    <p [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinCloture.text'"></p>
    <form #form="ngForm" *ngIf="listePeriodesOuvertes.length > 0">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="periodeCible"
                    name="periodeCible"
                    customType="select"
                    [selectOptions]="listePeriodesOuvertes"
                    optionValue="idPeriode"
                    optionDisplay="libelle"
                    libelle="admin.comptabilite.exercicesPeriodes.exercice.periodes.popinCloture.moisImputation"
                    [(ngModel)]="idPeriodeCible"
                    lCol="4"
                    required>
            </custom-input>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary" (click)="valider()" [disabled]="form.invalid || isPending">
                <span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinCloture.reaffecterEtCloturer'"></span>
            </button>
        </div>
    </form>
</div>
