<h1 mat-dialog-title>
    <span *ngIf="!isCreation" [translate]="'admin.bibliotheque.devises.infosDevise.taux.modifTaux'"></span>
    <span *ngIf="isCreation" [translate]="'admin.bibliotheque.devises.infosDevise.taux.ajoutTaux'"></span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input ngDefaultControl
                          id="dateApplication"
                          name="dateApplication"
                          libelle="admin.bibliotheque.devises.infosDevise.taux.dateApplication"
                          [ngModel]="coursDevise.dAppli"
                          [(customModel)]="coursDevise.dAppli"
                          customType="date"
                          rCol="2"
                          [required]="true"></custom-input>

            <custom-input ngDefaultControl
                          id="cours"
                          name="cours"
                          [(ngModel)]="coursDevise.taux"
                          customType="number"
                          nbDecimales="8"
                          lCol="4"
                          rCol="2"
                          libelle="admin.bibliotheque.devises.infosDevise.taux.cours"
                          [required]="true"></custom-input>
        </div>
        <div class="row" *ngIf="coursDevise.codeDeviseContrepartie">
            <custom-input libelle="admin.bibliotheque.devises.infosDevise.taux.deviseContrepartie"
                          id="devise"
                          rCol="2"
                          customType="select"
                          [selectOptions]="listeDevises"
                          optionValue="code"
                          optionDisplay="code"
                          name="devise"
                          required
                          [(ngModel)]="coursDevise.codeDeviseContrepartie"
                          ngDefaultControl
                          [required]="true"></custom-input>

            <div class="col-md-4 offset-md-4 py-3">
                    <span class="d-flex" *ngIf="coursDevise.codeDeviseContrepartie">
                        1 {{ data.codeDevise }} = {{ coursDevise.taux ? coursDevise.taux : "?" }} {{ coursDevise.codeDeviseContrepartie }}
                    </span>
            </div>
        </div>
        <div class="d-flex flex-row-reverse" *ngIf="!isCreation">
            <button mat-flat-button color="primary" class="ml-2"
                    (click)="modifyTaux()"
                    [disabled]="form.invalid">
                <span [translate]="'global.actions.enregistrer'"></span>
            </button>
            <button mat-stroked-button color="primary"
                    (click)="deleteTaux()"
                    [disabled]="form.invalid">
                <span [translate]="'global.actions.supprimer'"></span>
            </button>
        </div>
        <div class="d-flex flex-row-reverse" *ngIf="isCreation">
            <button mat-flat-button color="primary"
                    (click)="modifyTaux()"
                    [disabled]="form.invalid">
                <span [translate]="'global.actions.enregistrer'"></span>
            </button>
        </div>
    </form>
</div>
