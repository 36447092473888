import {Component,Input,OnChanges,OnInit,ViewChild} from '@angular/core';
import {ZoneUtilisateurComponent} from './../../../zone-utilisateur/zone-utilisateur.component';

import {SettingsOTState} from '../../../../domain/settings/settings';
import {TypePortee} from "../../../../domain/workflow/workflow";
import {FormControl,NgForm} from "@angular/forms";
import {TypeEntiteParamOT} from "../../../../domain/typeentite/typeEntiteParam";
import {OMPService} from "../../omp.service";
import * as moment from 'moment';
import {forkJoin,Observable} from "rxjs";
import {Map} from "core-js";
import {GeographieView} from "../../../../domain/geographie/geographieView";
import {Omp} from "../../../../domain/omp/omp";
import {NiveauAlerte} from '@domain/common/alerte/alerte';

@Component({
    selector: 'omp-generalites',
    templateUrl: './omp-generalites.component.html'
})
export class OMPGeneralitesComponent implements OnInit,OnChanges{
    //Constantes
    NiveauAlerte = NiveauAlerte;
    TypePortee = TypePortee;

    /** Mission permanente */
    @Input() omp: Omp;

    /** date/heure de début */
    @ViewChild("dateDebut")
    dateDebutForm: FormControl;
    dateDebut: moment.Moment;
    heureDebut: string;

    /** date/heure de fin */
    @ViewChild("dateFin")
    dateFinForm: FormControl;
    dateFin: moment.Moment;
    heureFin: string;

    /* Etats de validité de la période suivant la configuration */
    isExerciceChevalValid: boolean = true;
    isPeriodeAnValid: boolean = true;

    /** Récupération des identifiants des exercices en cours */
    isGetExercicesPending: boolean = false;

    /** Cache contenant les identifiants des exercices récupérés par le service et indexés par date au format YYYY-MM-DD */
    cacheIdExerciceForDate: Map<string,number> = new Map<string, number>();

    /** Indicateur d'initialisation du cache */
    isCacheInit: boolean = false;

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /** Paramétrage de la mission permanente */
    @Input() settings: SettingsOTState;

    /** Paramètres du type entité de la mission permanente */
    @Input() typeEntiteParam: TypeEntiteParamOT;

    /** Formulaire des généralités */
    @ViewChild('form') form: NgForm;

    /** Composant enfant zone utilisateur */
    @ViewChild(ZoneUtilisateurComponent) childZoneUtilisateurComponent: ZoneUtilisateurComponent;

    /**
     * Constructeur
     *
     * @param ompService Service de gestion des OMP
     */
    constructor(private ompService: OMPService) {
    }

    /**
     * Handler à l'initialisation du composant
     */
    ngOnInit(): void {
        //Actualisation des objets date pour mettre à jour les contrôles
        this.computeDates();
    }

    /**
     * Handler des changements dans le composant
     */
    ngOnChanges(): void {
        //Actualisation des objets date pour mettre à jour les contrôles
        this.computeDates();
    }

    /**
     * Construction des objets date avec momentjs
     */
    computeDates(): void {
        //Construction des dates grâce à momentjs
        this.dateDebut = moment(this.omp.dateDebut);
        this.dateFin = moment(this.omp.dateFin);

        //Heures
        this.heureDebut = this.omp.heureDebut;
        this.heureFin = this.omp.heureFin;

        //Ajout de l'heure à la date de début
        if (this.heureDebut?.length == 5) {
            this.dateDebut.hours(parseInt(this.heureDebut.substr(0,2),10));
            this.dateDebut.minutes(parseInt(this.heureDebut.substr(3,2),10));
        }

        //Ajout de l'heure à la date de fin
        if (this.heureFin?.length == 5) {
            this.dateFin.hours(parseInt(this.heureFin.substr(0,2),10));
            this.dateFin.minutes(parseInt(this.heureFin.substr(3,2),10));
        }

        //Vérification si la période est à cheval
        this.checkExerciceCheval();

        //Vérification du dépassement de durée
        this.checkPeriodeAn();
    }

    /**
     * Met à jour la destination sur la mission permanente lors du choix dans l'autocomplete
     *
     * @param destination La destination de l'OMP
     */
    setDestination(destination?: GeographieView): void {
        //Si une destination est sélectionnée
        if (destination) {
            //Si une ville est sélectionnée
            if (destination.ville) {
                //Définition de la ville
                this.omp.idVille = destination.ville.idVille;
                //Définition du pays
                this.omp.idPays = destination.ville.pays?.idPays;
            } else if (destination.pays) { //Si un pays est sélectionné
                //Reset de la ville
                this.omp.idVille = null;
                //Définition du pays
                this.omp.idPays = destination.pays.idPays;
            }
        } else {
            //Pas de destination sélectionnée, on reset la ville et le pays
            this.omp.idVille = null;
            this.omp.idPays = null;
        }
    }

    /**
     * Vérifie si la destination est valide et correspond au paramétrage
     *
     * @return True si valide, False sinon
     */
    isDestinationValid(): boolean {
        return this.typeEntiteParam.villeObligatoireOT ? (!!(this.omp.idPays && this.omp.idVille))
            : !this.typeEntiteParam.gestionVilleOT ? (!!this.omp.idPays)
            : (!!this.omp.idPays);
    }

    /**
     * Vérifie si la période (date de début ET date de fin) de la mission est valide
     *
     * @return True si valide, False sinon
     */
    isPeriodeValid(): boolean {
        return this.isDateDebutValid() && this.isDateFinValid()
            && this.isDateFinAfterOrEqualsDateDebut()
            && this.isExerciceChevalValid
            && this.isPeriodeAnValid;
    }

    /**
     * Vérifie que la date de début est valide
     *
     * @return True si valide, False sinon
     */
    isDateDebutValid(): boolean {
        return this.dateDebut.isValid();
    }

    /**
     * Vérifie que la date de fin est valide
     *
     * @return True si valide, False sinon
     */
    isDateFinValid(): boolean {
        return this.dateFin.isValid();
    }

    /**
     * Vérifie qu'un exercice existe pour la date de début.
     *
     * @return True si Oui, False si non ou null si le cache n'est pas encore initialisé.
     */
    dateDebutHasExercice(): boolean|null {
        return this.dateHasExercice(this.dateDebut);
    }

    /**
     * Vérifie qu'un exercice existe pour la date de retour.
     *
     * @return True si Oui, False si non ou null si le cache n'est pas encore initialisé.
     */
    dateFinHasExercice(): boolean|null {
        return this.dateHasExercice(this.dateFin);
    }

    /**
     * Recherche dans le cache des identifiants d'exercice si la une correspondance est trouvée pour une date donnée.
     * Retourne null si le cache n'est pas encore initialisé.
     *
     * @param date L'objet momentjs représentant la date
     * @return True si la date a un exercice dans le cache, False sinon ou null si le cache n'est pas initialisé
     */
    dateHasExercice(date: moment.Moment): boolean|null {
        return !this.isCacheInit ? null
            : this.cacheIdExerciceForDate.has(this.getCacheKeyForDate(date)) && this.cacheIdExerciceForDate.get(this.getCacheKeyForDate(date)) > 0;
    }

    /**
     * Vérifie que la date de fin est égale ou postérieure à la date de début
     *
     * @return True si valide, False sinon
     */
    isDateFinAfterOrEqualsDateDebut(): boolean {
        return this.dateDebut?.isSameOrBefore(this.dateFin)
    }

    /**
     * Vérification du chevauchement d'exercice (si activé dans la configuration)
     *
     * @return une promesse qui une fois résolue retourne : True si valide, False sinon
     */
    getExercices(): Promise<boolean> {
        let dateDebutKey: string;
        let dateFinKey: string;
        let dates: Set<string> = new Set<string>();
        let observables:Array<Observable<number>>;

        //Construction du Set de dates absentes du cache
        if (this.dateDebut.isValid()) {
            dateDebutKey = this.getCacheKeyForDate(this.dateDebut);
            if (!this.cacheIdExerciceForDate.has(dateDebutKey) || this.cacheIdExerciceForDate.get(dateDebutKey) == 0) {
                dates.add(dateDebutKey);
            }
        }
        if (this.dateFin.isValid()) {
            dateFinKey = this.getCacheKeyForDate(this.dateFin);
            if (!this.cacheIdExerciceForDate.has(dateFinKey) || this.cacheIdExerciceForDate.get(dateFinKey) == 0) {
                dates.add(dateFinKey);
            }
        }

        //Ajout des dates au cache pour éviter les appels concurrents
        dates.forEach((dateKey) => this.cacheIdExerciceForDate.set(dateKey,null));

        //Création d'une promesse qui contiendra le resultat
        return new Promise<boolean>((resolve,reject) => {
            //Vérification de la configuration
            if (this.settings?.isExerciceChevalInterditOMPermanent) {
                //Construction d'un tableau contenant les observables des appels serveur
                observables = Array.from(dates).map((val) => {
                    //Appel serveur pour récupérer l'identifiant de l'exercice correspondant
                    return this.ompService.getIdExerciceForDate(val);
                });

                //Récupération des identifiants des exercices correspondants à la date de début et à la date de retour
                if (observables.length > 0) {
                    this.isGetExercicesPending = true;

                    //Au moins un identifiant est manquant dans le cache, on attend que le ou les appels serveur soient terminés
                    forkJoin( observables ).subscribe({
                        next: (exerciceIds) => {
                            //Ajout des identifiants récupérés dans le cache
                            Array.from(dates).forEach((dateKey,index) => {
                                this.cacheIdExerciceForDate.set(dateKey,(exerciceIds[index] ? exerciceIds[index] : 0));
                            });

                            //Résolution de la promesse
                            this.isCacheInit = true;
                            resolve(this.cacheIdExerciceForDate.get(dateDebutKey) == this.cacheIdExerciceForDate.get(dateFinKey));
                        },
                        error: () => {
                            //En cas d'erreur on supprime du cache
                            dates.forEach((dateKey,index) => {
                                this.cacheIdExerciceForDate.delete(dateKey);
                            });
                            reject();
                        },
                        complete: () => {
                            this.isGetExercicesPending = false;
                        }
                    });
                } else {
                    resolve(this.cacheIdExerciceForDate.get(dateDebutKey) == this.cacheIdExerciceForDate.get(dateFinKey));
                }
            } else {
                resolve(true);
            }
        });
    }

    /**
     * Retourne la clef utilisée par le cache correspondant à une date
     *
     * @param date L'objet momentjs représentant la date
     * @return la clef
     */
    getCacheKeyForDate(date: moment.Moment): string {
        return date.format('YYYY-MM-DD');
    }

    /**
     * Vérification si la période est à cheval sur 2 périodes (si activé dans la configuration)
     * Met à jour l'attribut isExerciceChevalValid en conséquence
     */
    checkExerciceCheval(): void {
        if (!this.settings?.isExerciceChevalInterditOMPermanent) {
            this.isExerciceChevalValid = true;
        } else if (!this.isGetExercicesPending) {
            this.getExercices().then(isExerciceChevalValid => {
                this.isExerciceChevalValid = isExerciceChevalValid;
            });
        }
    }

    /**
     * Vérification du dépassement d'une année (si activé dans la configuration)
     * Met à jour l'attribut isPeriodeAnValid en conséquence
     */
    checkPeriodeAn(): void {
        //Calcul de la différences en jours entre la date de début et la date de retour
        const diff = this.dateFin.diff(this.dateDebut, 'days');

        //Vérification du nombre de jours suivant si l'année est bissextile ou non
        this.isPeriodeAnValid = this.settings?.isPeriodeAnInterditOMPermanent ? diff < (this.dateDebut.isLeapYear() || this.dateDebut.isLeapYear() ? 366 : 365) : true;
    }

    /**
     * Vérifie si les données sont valides
     *
     * @return True si valide, False sinon
     */
    isValid(): boolean {
        return this.isDestinationValid() && this.isPeriodeValid() && this.form.valid && this.childZoneUtilisateurComponent.isValid();
    }

    /**
     * Met à jour le dto avant la sauvegarde
     */
    public beforeSave(): void { 
        //Appelle le composant enfant zone utilisateur pour intégrer les données du formulaire zone utilisateur
        this.omp.listeZU = this.childZoneUtilisateurComponent.getListeZoneUtilisateursBeforeSave();
    }

}