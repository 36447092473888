import {Component, Input} from '@angular/core';
import {MessageAdmin} from "@domain/dashboard/message-admin";
import {TypeMessage} from "@domain/dashboard/type-message.enum";
import {MatDialog} from "@angular/material/dialog";
import {InfoComponent} from "./detail/info.component";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Item de la liste des Infos du Dashboard
 */
@Component({
    host: {'data-test-id': 'infos-list-item'},
    templateUrl: './infos-list-item.component.html'
})
export class InfosListItemComponent extends ListViewItem<MessageAdmin> {

    /** Info courante */
    @Input() data: MessageAdmin;

    /** Import de l'énum pour le DOM */
    typeMessage: typeof TypeMessage = TypeMessage;

    /**
     * Constructeur
     *
     * @param matDialog
     */
    constructor(private matDialog: MatDialog) {
        super();
    }

    /**
     * Ouverture du détail d'une info
     */
    showMessage(message: MessageAdmin) {
        this.matDialog.open(InfoComponent, {
            maxWidth: '80%',
            disableClose: false,
            data: message
        });
    }
}
