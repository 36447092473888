import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {GeographieView} from '@domain/geographie/geographieView';
import {Pays} from '@domain/geographie/pays';
import {TypeGeographie} from "@domain/geographie/typeGeographie";

/**
 * Composant de l'onglet Généralités d'un Pays
 *
 * @author Angeline Ha
 * @date 08/11/2023
 */
@Component({
    host: {'data-test-id': 'pays-generalites'},
    selector: 'pays-generalites',
    templateUrl: './pays-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class PaysGeneralitesComponent implements OnInit {

    /** Pays */
    @Input() pays: Pays;

    /** Indique si on est en mode création */
    @Input() isCreation: boolean;

    /** Modèle pour l'autocomplete de la zone */
    selectedZone: GeographieView;

    /** Modèle pour l'autocomplete de la devise */
    selectedDevise: { code: string };

    /**
     * Initialisation
     */
    ngOnInit() {
        //Récupération de la zone pour l'autocomplete
        this.selectedZone = {
            id: this.pays.zone?.idZone,
            type: TypeGeographie.ZONE,
            libelle: this.pays.zone?.libelle,
            zone: this.pays.zone
        }

        //Récupération de la devise pour l'autocomplete
        this.selectedDevise = {
            code: this.pays.devise
        };
    }

    /**
     * Mise à jour de l'objet de saisie avec la zone sélectionnée dans l'autocomplete
     */
    onZoneChange() {
        this.pays.idZone = this.selectedZone?.id;
    }

    /**
     * Mise à jour de l'objet de saisie avec la devise sélectionnée dans l'autocomplete
     */
    onDeviseChange() {
        this.pays.devise = this.selectedDevise?.code;
    }

}
