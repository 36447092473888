import { Injectable } from "@angular/core";
import { Field } from "../../../domain/settings/field";

/**
 * Service de gestion des patterns les champs complémentaires
 */
@Injectable()
export class PatternService {
    /**
     * Récupération de l'expression régulière liée au masque
     */
    getPatternFor(masque: string,tailleMinimale: number): string {
        let pattern: string = '';
        let position: number = 0;

        //Définition du pattern
        pattern = '^';

        //Parcours du masque de saisie
        masque.split('').forEach(i => {
            //Vérification du type de caractère
            if (i == '9')
                //Ajout du numérique au pattern
                pattern += '[0-9]';
            else if (i == 'Z')
                //Ajout de l'alphabétique au pattern
                pattern += '[A-Za-z]';
            else if (i == '?')
                //Ajout de l'alpha-numérique au pattern
                pattern += '[0-9A-Za-z]';
            else if (i == '*')
                //Ajout de n'importe quel caractère au pattern
                pattern += '[\\S ]';

            //Vérification de la saisie obligatoire
            if (position++ < tailleMinimale)
                //Saisie obligatoire
                pattern += '{1}';
            else
                //Saisie facultative
                pattern += '{0,1}';
        });

        //Caractère de fin
        pattern += '$';

        return pattern;
    }

    /**
     * Récupération de l'expression régulière liée au masque d'un champ
     *
     * @param field Le champ
     */
    getPatternForField(field: Field): string {
        return this.getPatternFor(field.masque,field.tailleMinimale);
    }
}