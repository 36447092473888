import {Component,Input,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Device} from "@domain/admin/entreprise/user/device";
import {DeviceListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-mobilite/device-list-item/device-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Result} from "@domain/common/http/result";

/**
 * Onglet "Mobilité" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-mobilite'},
	selector: 'user-mobilite',
	templateUrl: './user-mobilite.component.html'
})
export class UserMobiliteComponent implements OnInit {
	/** ID de l'utilisateur courant */
	@Input() idUser: number;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	@Input() isCollabInit: boolean;

	/** Utilisateur admin ou non */
	@Input() isAdmin: boolean;

	/** Liste des appareils de l'utilisateur */
	listeDevices: ListView<Device,DeviceListItemComponent>;

	/** Token de connection à l'application mobile */
	token: string;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des appareils
		this.listeDevices = new ListView<Device,DeviceListItemComponent>({
			uri: `/controller/User/loadMobile?idUser=${this.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.mobilite.listeDevices.title'),
			component: DeviceListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => {
				//Récupération du token de connection à l'application mobile
				this.token = result?.data?.token;

				//Rénvoi de la liste des appareils de l'utilisateur
				return result?.data?.listeDevices
			}
		});
	}
}
