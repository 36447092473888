<h1 mat-dialog-title>
    <span [translate]="'vehicule.rejetDialog.title'"></span>
    <span [mat-dialog-close] (click)="close()"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #form="ngForm">
        <fieldset>
            <div class="row" [ngClass]="{ 'has-error': remarque?.invalid }">
                <label class="col-md-4 required"><span [translate]="'vehicule.rejetDialog.motif'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <textarea #remarque="ngModel" [(ngModel)]="motif" matInput name="remarque" rows="3" required></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="validate()" [disabled]="form?.invalid" color="primary" mat-flat-button>
        <span [translate]="'global.actions.valider'"></span>
    </button>
</div>