import {Component} from '@angular/core';

/**
 * Composant temporaire vide. En attendant que les vrais soient développés.
 * Les routes comportent forcément un composant si elles n'ont pas d'enfants.
 * TODO: A supprimer une fois toutes les routes v10 implémentées
 */
@Component({
	host: {'data-test-id': 'frame-route'},
	templateUrl: './frame-route.component.html'
})
export class FrameRouteComponent {}
