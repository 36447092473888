/**
 * Énumération des types de facture
 *
 * @author Laurent Convert
 * @date 06/07/2023
 */
export enum TypeFacture {
	FAC = 'D', //Facture
	AVO = 'C' //Avoir
}
