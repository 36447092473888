import {Component, OnInit} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ReferentielDto} from "@domain/admin/entreprise/referentielDto";

/**
 * Composant pour afficher un référentiel dans la liste de tous les référentiels
 */
@Component({
    host: {'data-test-id': 'referentiel-item'},
    selector: 'referentiel-item',
    templateUrl: './referentiel-item.component.html'
})
export class ReferentielItemComponent extends ListViewItem<ReferentielDto> implements OnInit {
    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de changer le type pour le transformer d'une lettre à un mot ou un ensemble de mots compréhensible par l'utilisateur
        switch (this.data.type) {
            case "C": {
                this.data.type = "Catégorie";
                break;
            }
            case "E": {
                this.data.type = "Statut Collaborateur";
                break;
            }
            case "M": {
                this.data.type = "Mot-clef";
                break;
            }
            case "Q": {
                this.data.type = "Type de personnel";
                break;
            }
            case "T": {
                this.data.type = "Titre";
                break;
            }
        }
    }

    /**
     * Méthode qui permet d'afficher les 3 premiers caractères du type dans le rond bleu de chaque référentiel
     */
    displayAvatar() {
        return this.data.type.substring(0,3 < this.data.type.length ? 3 : this.data.type.length);
    }
}