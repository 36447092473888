/**
 * Résultat d'une requête HTTP pour les listes
 */
export type Page<T> = {
    listeResultats: T[],
    numPage: number,
    nbObjetsParPage: number,
    nbObjetsDansPage: number,
    nbPagesTotal: number,
    nbObjetsTotal: number
}

/**
 * Retourne une page vide.
 */
export function emptyPage<T>(): Page<T> {
    return {
        listeResultats: [],
        numPage: 0,
        nbObjetsParPage: 0,
        nbObjetsDansPage: 0,
        nbPagesTotal: 0,
        nbObjetsTotal: 0
    } as Page<T>;
}

/**
 * Requête de page
 */
export type Pageable = {
    offset: number,
    pageNumber: number,
    pageSize: number,
    paged: boolean,
    sort: Sort,
    unpaged: false
}

/**
 * Tri
 */
export type Sort = {
    sorted: boolean,
    unsorted: false
}
