import {ListItem} from "../common/list-view/list-item";
import {TypeContact} from "./typeContact";

/**
 * Téléphone
 */
export class Telephone implements ListItem{
    actif?: boolean;
    codePays?: string;
    genre?: string;
    id?: number;
    idTelephone?: number;
    idUser?: number;
    indicatif?: string;
    numero?: string;
    numeroBrut?: string;
    numeroBrutHash?: string;
    type?: TypeContact;
    isOpened?: boolean;
    nom?: string;
    prenom?: string;
    email?: string;
    numeroChanged?: boolean;
    nomChanged?: boolean;
    prenomChanged?: boolean;

    //Côté front uniquement, pour gérer l'obfuscation
    numeroBrutObfusque?: string;

    getKey(): number {
        return this.idTelephone;
    }
    

    constructor(tel?: Telephone) {
        //Vérification de l'initialisation à partir d'un autre téléphone
        if (tel) {
            //On est dans le constructeur, pas besoin de supprimer d'éventuelles données présentes
            this.initFrom(tel,false);
        } else {
            //Valeurs par défaut
            this.actif = true;
            this.idTelephone = 0;
        }
    }
    
    /**
     * Initialisation de l'objet à partir d'un autre téléphone
     * @param tel Téléphone source
     * @param clean Suppression des éventuelles valeurs déjà présentes
     */
    initFrom(tel: Telephone,clean: boolean = true) {
        //Suppression de toutes les données du téléphone
        if (clean) {
            for (const key of Object.keys(this)) {
                delete this[key];
            }
        }
        
        //Copie des propriétés communes aux différents types
        Object.assign(this,{
            id: tel.id,
            idTelephone: tel.idTelephone,
            type: tel.type,
            actif: tel.actif,
        } as Telephone);
    
        //Copie des propriétés en fonction du type (données devenues incohérentes suite au changement de type)
        switch (tel.type) {
            case TypeContact.EMAIL_ASSISTANT:
                //Copie des propriétés propres au type (avec les propriétés xxxChanged à true pour forcer la mise à jour des éventuelles données obfusquées)
                Object.assign(this,{
                    email: tel.email,
                    numeroChanged: true,
                    nomChanged: true,
                    prenomChanged: true,
                } as Telephone);
                break;
            case TypeContact.CONTACT_URGENCE:
                //Copie des propriétés propres au type
                Object.assign(this,{
                    genre: tel.genre,
                    indicatif: tel.indicatif,
                    codePays: tel.codePays,
                    numero: tel.numero,
                    numeroBrut: tel.numeroBrut,
                    numeroBrutHash: tel.numeroBrutHash,
                    numeroBrutObfusque: tel.numeroBrutObfusque,
                    nom: tel.nom,
                    prenom: tel.prenom,
                    numeroChanged: tel.numeroChanged,
                    nomChanged: tel.nomChanged,
                    prenomChanged: tel.prenomChanged,
                } as Telephone);
                break;
        }
    }
}
