import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    templateUrl: './cgu.component.html'
})
export class CguComponent implements OnInit, OnDestroy {
    /** Élément situé à l'extrémité du composant */
    @ViewChild('scrollEnd',{ static: true }) scrollEnd: ElementRef<HTMLElement>;
     
    /**  Observateur de l'apparition de l'extrémité du composant dans la fenêtre */
    private observer: IntersectionObserver;

    /** Indique si le bouton est désactivé */
    buttonDisabled: boolean = true;

    /**
     * Constructeur
     * @param data object avec cgu pour les informations des cgu et isOther qui indique que les cgu sont signées par une autre personne
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { cgu: string, isOther: boolean }) {}

    /**
     * Initialisation du composant
     */
     ngOnInit() {
        //Définition de l'observateur
        this.observer = new IntersectionObserver(([entry]) => {
            //Notification du scroll lorsque l'élément observé apparait           
            if (entry.isIntersecting) {
                //On arrive à la fin du document
                this.onScrollEnd();
            }
        },{});
        
        //Détection de l'apparition de l'extrémité du composant
        this.observer.observe(this.scrollEnd.nativeElement);
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Déconnexion de l'observateur
        this.observer.disconnect();
    }

    /**
     * Event quand on arrive à la fin du scroll
     */
    onScrollEnd(): void {
        this.buttonDisabled = false;
    }

}
