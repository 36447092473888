/**
 * Représentation du compte bancaire
 */
import {Pays} from "../geographie/pays";

export interface CompteBancaire{
    idCompte: number;
    libelle: string;
    numero: string;
    typeCompte: TypeCompteBancaire;
    defaut: boolean;
    devise: string;
    beneficiaire: string;
    domiciliation: string;
    bic: string;
    actif: boolean;
    idUser: number;
    idPays: number;
    nbPJ: number;
    pays: Pays; //Pays
}

/**
 * Enumération représentant le type de compte bancaire
 */
export enum TypeCompteBancaire {
    UNDEFINED = 'UNDEFINED',
    IBAN = 'IBAN',
    RIB = 'RIB',
    AUTRE = 'AUTRE'
}
