import {Component,OnInit} from '@angular/core';
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {Router} from "@angular/router";

/**
 * Composant de la liste des territoires
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'territoire-list'},
	selector: 'territoire-list',
	templateUrl: './territoire-list.component.html'
})
export class TerritoireListComponent implements OnInit {
	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param geographieService	Service géographie
	 * @param router			Router de l'application
	 */
	constructor(public geographieService: GeographieService,
				private router: Router) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition de la liste des actions du bouton en bas à droite de l'écran
		this.listeActions.next([{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-ajouter',
				libelle: 'global.actions.creer',
				doAction: () => this.createNewTerritoire()
			}]
		);
	}

	/**
	 * Création d'un nouveau territoire
	 */
	createNewTerritoire() {
		this.router.navigate(['Admin/Bibliotheque/Geographie/Territoire/0']);
	}

}
