import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {Result} from '../../domain/common/http/result';
import {environment} from '../../environments/environment';

@Injectable()
export class CguService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {}

    /**
     * Récupération des informations de validation des CGU par l'utilisateur
     */
    getInfosForCgu(): Observable<{ dateAcceptCgu?: Date,cguDisabledByParam?: boolean, isCguLastVersionValid?: boolean }> {
        //Récupération des informations de validation des CGU par l'utilisateur
        return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/getInfosValidCgu`,null).pipe(
            first(),
            map(result => ({
                dateAcceptCgu: result?.data?.dateAcceptCgu,
                cguDisabledByParam: result?.data?.cguDisabledByParam,
                isCguLastVersionValid: result?.data?.isCguLastVersionValid
            }))
        );
    }

    /**
     * Récupération de la dernière version des CGU en HTML
     */
    getLastCguVersion(): Observable<string> {
        //Récupération de la dernière version des CGU en HTML
        return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/getLastCguVersionHTML`,null).pipe(
            first(),
            map(result => result?.data?.cguHtml)
        )
    }

    /**
     * Validation ou rejet des CGU
     */
    validateOrRejectCgu(isValidation: boolean): Observable<any> {
        //Validation ou rejet des CGU
        return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/validerCgu/${isValidation}`,null).pipe(
            first()
        );
    }

    /**
     * Validation ou rejet des CGU
     * @param isValidation true si on valide, false si on rejette
     * @param idCollaborateur id du collaborateur pour qui on valide
     */
     validateOrRejectCguByOther(isValidation: boolean, idCollaborateur: number): Observable<any> {
        //Validation ou rejet des CGU
        return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/validerCguByOther/${isValidation}`,idCollaborateur).pipe(
            first()
        );
    }

    /**
     * Retourne true si le user a validé ses CGU
     * @param idUser id de l'user
     */
    hasValidCgu(idUser: number): Observable<boolean> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Cgu/hasValidCgu/${idUser}`).pipe(
            first(),
            map(result => result?.data?.hasValidCgu)
        );
    }
}
