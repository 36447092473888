<mat-card>
    <mat-card-title>
        <span [translate]="'profilUser.infoPerso.title'" [class.warning]="alertLevelInfoUser == NiveauAlerte.ERROR" [class.error]="alertLevelInfoUser == NiveauAlerte.ERROR"></span>
        <span class="actions" (click)="onClickModify()"><mat-icon>search</mat-icon></span>
    </mat-card-title>
    <mat-card-content>
        <form autocomplete="off" name="infosPersoForm">
            <div class="row">
                <!-- Nom -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.nom'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.nom }}</span></div>

                <!-- Nom d'usage -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.nomUsage'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.infoVoyageur?.nomUsage }}</span></div>

                <!-- Prénom -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.prenom'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.prenom }}</span></div>

                <!-- Nom de naissance -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.nomNaissance'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.nomNaissance }}</span></div>

                <!-- Ville de naissance -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.villeNaissance'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.infoVoyageur?.newVille ? profilUser.infoVoyageur?.ville : profilUser.infoVoyageur?.villeNaissance?.libelle  }}</span></div>

                <!-- Date de naissance -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.dateNaissance'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.infoVoyageur?.dateNaissance | date: 'shortDate' }}</span></div>

                <!-- Pays de naissance -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.paysNaissance'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.infoVoyageur?.newVille ? profilUser.infoVoyageur?.paysNaissance?.libelle : profilUser.infoVoyageur?.villeNaissance?.pays?.libelle }}</span></div>

                <!-- Nationalité -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.nationalite'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.infoVoyageur?.paysNationalite?.libelle }}</span></div>

                <!-- Identifiant -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.identifiant'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.nomAcces }}</span></div>

                <!-- Matricule -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.matricule'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.matricule }}</span></div>

                <!-- Email -->
                <label class="col-md-2"><span [translate]="'profilUser.infoPerso.email'"></span></label>
                <div class="col-md-4"><span>{{ profilUser.email }}</span></div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
