import { Component,Inject } from '@angular/core';
import { IJChange } from "../../../../../../domain/od/engagements/indemnites/ij-change";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from "../../../../../../domain/user/user";

/**
 * Composant d'affichage de l'historique des indemnités
 *
 * @author Laurent Convert
 * @date 18/11/2021
 */
@Component({
    selector: 'od-engagements-indemnites-historique',
    templateUrl: './od-engagements-indemnites-historique.component.html'
})
export class OdEngagementsIndemnitesHistoriqueComponent {

    /** Liste des historiques des indemnités */
    listeHistoriqueIJ: Array<IJChange>;

    /** Devise entreprise */
    deviseEntreprise: string;

    /** Utilsateur connecté */
    user: User;

    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { listeHistoriqueIJ: Array<IJChange>,deviseEntreprise: string,user: User }) {
        //Récupération des valeurs fournies à l'ouverture de la popin
        this.listeHistoriqueIJ = data.listeHistoriqueIJ.map(histo => new IJChange(histo));
        this.deviseEntreprise = data.deviseEntreprise;
        this.user = data.user;
    }

}