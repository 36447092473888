/**
 * Type entité
 */
import {TypeEntiteParam, TypeEntiteParamFC, TypeEntiteParamNF, TypeEntiteParamOD, TypeEntiteParamOT} from "./typeEntiteParam";
import {TypePortee} from "../workflow/workflow";
import {ListItem} from "../common/list-view/list-item";

/**
 * Type entité
 */
export class TypeEntite implements ListItem {
    idTypeEntite: number;
    libelle: string;
    code: string;
    codeProjet: boolean;
    codeProjetObligatoire: boolean;
    axe5: boolean;
    axe5Obligatoire: boolean;
    axe6: boolean;
    axe6Obligatoire: boolean;
    field5: boolean;
    field6: boolean;
    field5Obligatoire: boolean;
    field6Obligatoire: boolean;
    typeEntiteParam: TypeEntiteParam;

    getKey(): number {
        return this.idTypeEntite;
    }
}

/**
 * Type entité avec le paramètrage des factures
 */
export interface TypeEntiteFC extends TypeEntite {
    typeEntiteParam: TypeEntiteParamFC;
}

/**
 * Type entité avec le paramètrage des NDF
 */
export interface TypeEntiteNF extends TypeEntite {
    typeEntiteParam: TypeEntiteParamNF;
}

/**
 * Type entité avec le paramètrage des OD
 */
export interface TypeEntiteOD extends TypeEntite {
    typeEntiteParam: TypeEntiteParamOD;
}

/**
 * Type entité avec le paramétrage des OMP
 */
export interface TypeEntiteOT extends TypeEntite {
    typeEntiteParam: TypeEntiteParamOT;
}

/**
 * Etat pour le store des types entités.
 */
export interface TypeEntiteState {
    [TypePortee.FC]?: TypeEntiteFC;
    [TypePortee.NF]?: TypeEntiteNF;
    [TypePortee.OD]?: TypeEntiteOD;
    [TypePortee.OT]?: TypeEntiteOT;
}
