import {Component,Input,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Profil} from "@domain/profil/profil";
import {Condition} from '@domain/workflow/condition';
import {TypeProfil} from "@domain/user/user";
import {TranslateService} from '@ngx-translate/core';

/**
 * Item de la liste des profils
 */
@Component({
	host: {'data-test-id': 'select-statuts-popin'},
	templateUrl: './profil-list-item.component.html'
})
export class ProfilListItemComponent extends ListViewItem<Profil> implements OnInit {
	/** L'alerte courante */
	@Input() extraOptions: {
		alerte: Condition
	}

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de isSelected
		if (this.extraOptions.alerte.fonctions) {
			this.data.isSelected = this.extraOptions.alerte.fonctions.split(' ').includes(this.data.typeProfil.valueOf().toString());
		} else {
			this.data.isSelected = false;
		}
	}

	/**
	 * Icône du profil
	 */
	getIcon(): string {
		return TypeProfil.getClasseIcone(this.data.typeProfil);
	}

	/**
	 * Libellé du profil
	 */
	getLibelle(): string {
		return TypeProfil.getLibelle(this.translateService,this.data.typeProfil);
	}
}
