<form #form="ngForm">
    <please-wait *ngIf="!collab"></please-wait>
    <fieldset *ngIf="collab?.infosMetier">
        <mat-card *ngIf="isDroitInfoMetier">
            <mat-card-title>
                <span [translate]="'admin.entreprise.utilisateurs.detail.metier.informationsMetier.title'"></span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="titre"
                            name="titre"
                            customType="select"
                            [(ngModel)]="collab.idTitre"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.titre"
                            [selectOptions]="listeTitre"
                            optionDisplay="libelle"
                            optionValue="idReferentiel">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="categorie"
                            name="categorie"
                            customType="select"
                            [(ngModel)]="collab.idCateg"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.categorie"
                            [selectOptions]="listeCategorie"
                            optionDisplay="libelle"
                            optionValue="idReferentiel"
                            [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="qualification"
                            name="qualification"
                            customType="select"
                            [(ngModel)]="collab.infosMetier.idQualification"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.qualification"
                            [selectOptions]="listeQualification"
                            optionDisplay="libelle"
                            optionValue="idReferentiel">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="statut"
                            name="statut"
                            customType="select"
                            [(ngModel)]="collab.infosMetier.idStatut"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.statut"
                            [selectOptions]="listeStatut"
                            optionDisplay="libelle"
                            optionValue="idReferentiel">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="complement1"
                            name="complement1"
                            customType="input"
                            [(ngModel)]="collab.field1"
                            [libelle]="entreprise.def1 || 'admin.entreprise.utilisateurs.detail.metier.informationsMetier.complement1'"
                            [minlength]="entreprise.len1"
                            [maxlength]="entreprise.masque1?.length"
                            [pattern]="masqueToRegex(entreprise.masque1)"
                            [required]="entreprise.oblig1"
                            [disabled]="!entreprise.def1"
                            [postTooltip]="!entreprise.def1 ? ('admin.entreprise.utilisateurs.detail.metier.informationsMetier.complementTooltip' | translate) : null">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="complement2"
                            name="complement2"
                            customType="input"
                            [(ngModel)]="collab.field2"
                            [libelle]="entreprise.def2 || 'admin.entreprise.utilisateurs.detail.metier.informationsMetier.complement2'"
                            [minlength]="entreprise.len2"
                            [maxlength]="entreprise.masque2?.length"
                            [pattern]="masqueToRegex(entreprise.masque2)"
                            [required]="entreprise.oblig2"
                            [disabled]="!entreprise.def2"
                            [postTooltip]="!entreprise.def2 ? ('admin.entreprise.utilisateurs.detail.metier.informationsMetier.complementTooltip' | translate) : null">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="complement3"
                            name="complement3"
                            customType="input"
                            [(ngModel)]="collab.field3"
                            [libelle]="entreprise.def3 || 'admin.entreprise.utilisateurs.detail.metier.informationsMetier.complement3'"
                            [minlength]="entreprise.len3"
                            [maxlength]="entreprise.masque3?.length"
                            [pattern]="masqueToRegex(entreprise.masque3)"
                            [required]="entreprise.oblig3"
                            [disabled]="!entreprise.def3"
                            [postTooltip]="!entreprise.def3 ? ('admin.entreprise.utilisateurs.detail.metier.informationsMetier.complementTooltip' | translate) : null">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="complement4"
                            name="complement4"
                            customType="input"
                            [(ngModel)]="collab.field4"
                            [libelle]="entreprise.def4 || 'admin.entreprise.utilisateurs.detail.metier.informationsMetier.complement4'"
                            [minlength]="entreprise.len4"
                            [maxlength]="entreprise.masque4?.length"
                            [pattern]="masqueToRegex(entreprise.masque4)"
                            [required]="entreprise.oblig4"
                            [disabled]="!entreprise.def4"
                            [postTooltip]="!entreprise.def4 ? ('admin.entreprise.utilisateurs.detail.metier.informationsMetier.complementTooltip' | translate) : null">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="attribut1"
                            name="attribut1"
                            customType="input"
                            [(ngModel)]="collab.infosMetier.attribut1"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.attribut1">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="attribut2"
                            name="attribut2"
                            customType="input"
                            [(ngModel)]="collab.infosMetier.attribut2"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.attribut2">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="attribut3"
                            name="attribut3"
                            customType="input"
                            [(ngModel)]="collab.infosMetier.attribut3"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.attribut3">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="attribut4"
                            name="attribut4"
                            customType="input"
                            [(ngModel)]="collab.infosMetier.attribut4"
                            libelle="admin.entreprise.utilisateurs.detail.metier.informationsMetier.attribut4">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="isDroitFraisMission">
            <mat-card-title>
                <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.title'"></span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <label class="col-md-2 required" [class.has-error]="countNbModeRemb() === 0">
                        <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.modeRemb.lib'"></span>
                    </label>
                    <div class="col-md-4" style="padding-left: 0; align-self: center">
                        <button mat-button color="primary" (click)="onClickModeRemb()" style="margin-bottom: 0.5rem">
                            <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.modeRemb.' + (countNbModeRemb() < 2 ? 'one' : 'plural')"
                                  [translateParams]="{ nb: countNbModeRemb() }"></span>
                            <mat-icon color="primary">search</mat-icon>
                        </button>
                    </div>
                    <custom-input
                            ngDefaultControl
                            id="devise"
                            name="devise"
                            customType="select"
                            [(ngModel)]="collab.codeDevise"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.devise"
                            [selectOptions]="settings.listeDevises"
                            optionDisplay="code"
                            optionValue="code"
                            [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="compteTiers"
                            name="compteTiers"
                            customType="select"
                            [(ngModel)]="collab.numCompta"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.compteTiers"
                            [selectOptions]="listePlanComptable"
                            optionDisplay="libelle"
                            optionValue="numero">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="unite"
                            name="unite"
                            customType="select"
                            [(ngModel)]="collab.idUnite"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.unite"
                            [selectOptions]="listeUnite"
                            optionDisplay="libelle"
                            optionValue="idUnite">
                    </custom-input>
                </div>
                <div class="row">
                    <label class="col-md-2">
                        <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.franchise.lib'"></span>
                    </label>
                    <div class="col-md-4" style="padding-left: 0">
                        <button mat-button color="primary" (click)="onClickFranchise()" style="margin-bottom: 0.5rem">
                            <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.franchise.' + (nbContratUser > 1 ? 'plural' : 'one')"
                                  [translateParams]="{ nb: nbContratUser || 0}"></span>
                            <mat-icon color="primary">search</mat-icon>
                        </button>
                    </div>
                    <label class="col-md-2">
                        <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.compteurFiscal'"></span>
                    </label>
                    <div class="col-md-4" style="padding-left: 0">
                        <button mat-button color="primary" (click)="onClickCompteurFiscal()" style="margin-bottom: 0.5rem">
                            <span [translate]="'admin.entreprise.utilisateurs.detail.metier.fraisMission.historiqueDistances'"></span>
                            <mat-icon color="primary">search</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="missionsPermanentes"
                            name="missionsPermanentes"
                            [ngModel]="collab.omPermanent"
                            [(customModel)]="collab.omPermanent"
                            customType="checkbox"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.missionsPermanentes"
                            postLibelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.missionsPermanentesTooltip">`
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="modificateurIndemnite"
                            name="modificateurIndemnite"
                            customType="number"
                            [(ngModel)]="collab.modificateurIndemns"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.modificateurIndemnite"
                            [min]="0"
                            [max]="100"
                            [nbDecimales]="0"
                            suffix="%">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="avances"
                            name="avances"
                            [ngModel]="collab.avances"
                            [(customModel)]="collab.avances"
                            customType="checkbox"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.avances"
                            postLibelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.avancesTooltip">`
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="compteAvance"
                            name="compteAvance"
                            customType="select"
                            [(ngModel)]="collab.compteAvance"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.compteAvance"
                            [selectOptions]="listePlanComptable"
                            optionDisplay="libelle"
                            optionValue="numero"
                            [disabled]="!collab.avances">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="deplafonnement"
                            name="deplafonnement"
                            [ngModel]="collab.deplaf"
                            [(customModel)]="collab.deplaf"
                            customType="checkbox"
                            libelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.deplafonnement"
                            postLibelle="admin.entreprise.utilisateurs.detail.metier.fraisMission.deplafonnementTooltip">`
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
    </fieldset>
</form>

<list-view *ngIf="isDroitPrefAnalytiques && settings?.analytiqueAdminVisibility && listePrefAnalytique" [liste]="listePrefAnalytique"></list-view>

<floating-button *ngIf="listeActions" [listeActions]="listeActions" [isPending]="isPending"></floating-button>
