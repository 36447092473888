import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ShareModule } from '../../share/share.module';
import { ZoneUtilisateurComponent } from './zone-utilisateur.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    ZoneUtilisateurComponent
  ],
  imports: [
    ShareModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [
    ZoneUtilisateurComponent
  ]
})
export class ZoneUtilisateurModule { }
