import {Injectable} from "@angular/core";
import {TerritoireItemComponent} from "@components/admin/bibliotheque/geographie/territoire/territoire-list/territoire-item/territoire-item.component";
import {TerritoireItem} from "@domain/admin/bibliotheque/geographie/territoireItem";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {PorteeTerritoireCode} from "@components/admin/bibliotheque/geographie/geographie.component";
import {ZoneItem} from "@domain/admin/bibliotheque/geographie/zoneItem";
import {ZoneItemComponent} from "@components/admin/bibliotheque/geographie/zone/zone-list/zone-item/zone-item.component";
import {Zone} from "@domain/geographie/zone";
import {Sorting} from "@domain/common/list-view/sorting";
import {PaysItem} from "@domain/admin/bibliotheque/geographie/paysItem";
import {PaysItemComponent} from "@components/admin/bibliotheque/geographie/pays/pays-list/pays-item/pays-item.component";
import {Observable,Subject} from "rxjs";
import {Result} from "@domain/common/http/result";
import {HttpClient,HttpParams} from "@angular/common/http";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Pays} from "@domain/geographie/pays";
import {RegionItem} from "@domain/admin/bibliotheque/geographie/regionItem";
import {RegionItemComponent} from "@components/admin/bibliotheque/geographie/regions/region-list/region-item/region-item.component";
import {RegionAdmin} from "@domain/geographie/regionAdmin";
import {DepartementItem} from "@domain/admin/bibliotheque/geographie/departementItem";
import {DepartementItemComponent} from "@components/admin/bibliotheque/geographie/departements/departement-list/departement-item/departement-item.component";
import {Page} from "@domain/common/http/list-result";
import {DepartementAdmin} from "@domain/geographie/departementAdmin";
import {VilleItem} from "@domain/admin/bibliotheque/geographie/villeItem";
import {VilleItemComponent} from "@components/admin/bibliotheque/geographie/ville/ville-list/ville-item/ville-item.component";
import {Ville} from "@domain/geographie/ville";
import {Adresse} from "@domain/profil/adresse";
import {AdresseItem} from "@domain/admin/bibliotheque/geographie/adresseItem";
import {AdresseItemComponent} from "@components/admin/bibliotheque/geographie/adresses/adresse-list/adresse-item/adresse-item.component";
import {Origine} from "@domain/geolocalisation/localisation";
import {GeoLocalisation} from "@domain/geographie/geoLocalisation";
import {Territoire} from "@domain/geographie/territoire";
import {GeolocalisationParams} from "@domain/geolocalisation/geolocalisationParams";
import {IataItem} from "@domain/admin/bibliotheque/geographie/iataItem";
import {IataItemComponent} from "@components/admin/bibliotheque/geographie/iata/iata-list/iata-item/iata-item.component";
import {Iata, TypeIata} from "@domain/geographie/iata";
import {
    DistanceItemComponent
} from "@components/admin/bibliotheque/geographie/distances/distance-list/distance-item/distance-item.component";
import {DistanceItem} from "@domain/admin/bibliotheque/geographie/distanceItem";
import {Distance} from "@domain/geographie/distance";

/**
 * Service de gestion Géographie
 */
@Injectable()
export class GeographieService {
	/**
	 * Indique si une page de détail d'un objet géographie (territoire, zone, ...) a été ouverte.
	 * Cela permet de savoir s'il faut afficher les listes du menu géographie ou non.
	 */
	isShowDetail: boolean;

	/** Liste des territoires */
	private _listeTerritoires: ListView<TerritoireItem,TerritoireItemComponent>;

	/** Indique s'il faut refresh la liste des géolocalisations d'un territoire */
	private _refreshListeGeoLocalisations$: Subject<void> = new Subject<void>();

	/** Observable de l'indicateur de refresh de la liste des géolocalisations */
	private refreshListeGeoLocalisationsAsObservable$ = this._refreshListeGeoLocalisations$.asObservable();

	get refreshListeGeoLocalisations$(): Observable<void> {	return this.refreshListeGeoLocalisationsAsObservable$; }

	/** Liste des zones */
	private _listeZones: ListView<ZoneItem,ZoneItemComponent>;

	/** Indique s'il faut refresh la liste des périmètres d'une zone */
	private _refreshListePerimetresZone$: Subject<void> = new Subject<void>();

	/** Observable de l'indicateur de refresh de la liste des périmètres d'une zone */
	private refreshListePerimetresZoneAsObservable$ = this._refreshListePerimetresZone$.asObservable();

	/* Accesseur */
	get refreshListePerimetresZone$() { return this.refreshListePerimetresZoneAsObservable$; }

	/** Liste des départements */
	private _listeDepartements: ListView<DepartementItem,DepartementItemComponent>;

	/** Liste des pays */
	private _listePays: ListView<PaysItem,PaysItemComponent>;

	/** Liste des régions */
	private _listeRegions: ListView<RegionItem,RegionItemComponent>;

	/** Liste des villes */
	private _listeVilles: ListView<VilleItem,VilleItemComponent>;

	/** Liste des adresses */
	private _listeAdresses: ListView<AdresseItem,AdresseItemComponent>;

	/** Liste des iata */
	private _listeIata: ListView<IataItem, IataItemComponent>;

	/** Liste des distances */
	private _listeDistances: ListView<DistanceItem, DistanceItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param http             Client http
	 */
	constructor(private translateService: TranslateService,
				private http: HttpClient) {
	}

	/**
	 * Permet d'indiquer qu'il faut refresh la liste des géolocalisations
	 */
	refreshListeGeoLocalisations() {
		this._refreshListeGeoLocalisations$.next();
	}

	/**
	 * Permet d'indiquer qu'il faut refresh la liste des périmètres d'une zone
	 */
	refreshListePerimetresZone() {
		this._refreshListePerimetresZone$.next();
	}

	/**
	 * Liste des territoires
	 */
	get listeTerritoires() {
		if (!this._listeTerritoires) {
			this.initListeTerritoires();
		}

		return this._listeTerritoires;
	}

	/**
	 * Initialisation de la liste des territoires
	 */
	private initListeTerritoires() {
		//Définition de la liste des territoires
		this._listeTerritoires = new ListView<TerritoireItem,TerritoireItemComponent>({
			uri: `/controller/Territoire/getListeTerritoires`,
			title: this.translateService.instant('admin.bibliotheque.geographie.territoires.title'),
			component: TerritoireItemComponent,
			defaultOrder: 'libelle',
			//Définition des filtres
			listeFilters: [{
				clef: 'libelleNettoye',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'code',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.code'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'visibilite',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.visibilite'),
				typeComparaison: TypeComparaison[TypeComparaison.LIKE],
				listeValues: [
					{
						code: '%' + PorteeTerritoireCode[PorteeTerritoireCode.IND],
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.indemnite')
					},{
						code: '%' + PorteeTerritoireCode[PorteeTerritoireCode.TAX],
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.taxe')
					},{
						code: '%' + PorteeTerritoireCode[PorteeTerritoireCode.OMP],
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.omp')
					},
				]
			},{
				clef: 'isActif',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.actif'),
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				type: TypeFilter[TypeFilter.BOOLEAN],
				listeValues: [
					{
						code: "true",
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoires.isActif')
					},{
						code: "false",
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoires.notActif')
					}
				]
			}],
			isFilter: true
		})

		//Définition des colonnes de tri
		this._listeTerritoires.columns = [
			{key: 'libelleNettoye',title: 'filter.libelle'},
			{key: 'code',title: 'admin.bibliotheque.geographie.territoire.code'},
			{key: 'isActif',title: 'admin.bibliotheque.geographie.territoire.actif'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this._listeTerritoires.sorting = new Sorting(this._listeTerritoires.columns,"libelleNettoye");

		//Aucun filtre sélectionné par défaut
		this._listeTerritoires.listeSelectedFilters = [];
	}

	/**
	 * Récupère un territoire
	 *
	 * @param id Identifiant du territoire
	 * @return Observable<Result> Résultat contenant le territoire
	 */
	getTerritoireById(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Territoire/${id}`,null).pipe(first());
	}

	/**
	 * Enregistrement d'une géolocalisation (périmètre) pour un territoire
	 *
	 * @param geoLocalisation GeoLocalisation à enregistrer
	 * @return Observable<Result> Résultat de l'enregistrement
	 */
	saveGeoLocalisation(geoLocalisation: GeoLocalisation): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Territoire/saveGeoLocalisation`,geoLocalisation).pipe(first());
	}

	/**
	 * Suppression d'une GeoLocalisation (périmètre)
	 *
	 * @param idGeoLocalisation id de la GeoLocalisation à supprimer
	 * @return Observable<Result> Résultat de la suppression
	 */
	deleteGeoLocalisation(idGeoLocalisation: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Territoire/deleteGeoLocalisation/${idGeoLocalisation}`,null).pipe(first());
	}

	/**
	 * Enregistrement ou création d'un territoire
	 *
	 * @param isCreation Indique si on est en création ou en modification
	 * @param territoire Territoire à enregistrer
	 * @return Observable<Result> Résultat de l'enregistrement
	 */
	saveTerritoire(isCreation: boolean,territoire: Territoire): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Territoire/saveTerritoire/` + isCreation,territoire).pipe(first());
	}

	/**
	 * Suppression d'un territoire
	 *
	 * @param idTerritoire id du territoire à supprimer
	 * @return Observable<Result> Résultat de la suppression
	 */
	deleteTerritoire(idTerritoire: number): Observable<Result> {
		const params: HttpParams = new HttpParams().append("id_territoire", idTerritoire.toString())
		return this.http.post<Result>(`${environment.baseUrl}/controller/Territoire/deleteTerritoire`,null, {params: params}).pipe(first());
	}

	/**
	 * Liste des zones
	 */
	get listeZones() {
		if (!this._listeZones) {
			this.initListeZones();
		}

		return this._listeZones;
	}

	/**
	 * Initialisation de la liste des zones
	 */
	private initListeZones() {
		//Définition de la liste des zones
		this._listeZones = new ListView<ZoneItem,ZoneItemComponent>({
			uri: `/controller/Geographie/getListeZones`,
			title: this.translateService.instant('admin.bibliotheque.geographie.zones.title'),
			component: ZoneItemComponent,
			defaultOrder: 'libelle',
			listeFilters: [{
				clef: 'libelleNettoye',
				title: this.translateService.instant('filter.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			}],
			isFilter: true
		})

		//Aucun filtre sélectionné par défaut
		this._listeZones.listeSelectedFilters = [];

		this._listeZones.columns = [
			{key: 'libelleNettoye',title: 'filter.libelle'}
		]

		this._listeZones.sorting = new Sorting(this._listeZones.columns,"libelleNettoye");
	}

	/**
	 * Récupère une zone
	 *
	 * @param idZone Id de la zone
	 */
	getZone(idZone: number) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/getZone/${idZone}`,null).pipe(first());
	}

	/**
	 * Récupère le nombre de pays associé à une zone
	 *
	 * @param idZone Id de la zone
	 */
	getNombrePaysByZone(idZone: number) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/getNombrePaysByZone/${idZone}`,null).pipe(first());
	}

	/**
	 * Enregistrement de la zone
	 *
	 * @param zone Id de la zone
	 */
	saveZone(zone: Zone) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/saveZone`,zone).pipe(first())
	}

	/**
	 * Suppression de la zone
	 *
	 * @param idZone Id de la zone
	 */
	deleteZone(idZone: number) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/deleteZone/${idZone}`,null).pipe(first());
	}

	/**
	 * Suppression d'un périmètre (pays) associé à une zone
	 *
	 * @param idPays Id du pays
	 */
	deletePerimetreFromZone(idPays: number) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/deleteZoneFromPays/${idPays}`,null).pipe(first());
	}

	/**
	 * Ajout d'un périmètre (pays)
	 *
	 * @param idPays Id du pays
	 * @param idZone Id de la zone
	 */
	savePerimetreForZone(idPays: number, idZone: number) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/saveZoneForPays/${idPays}/${idZone}`,null).pipe(first());
	}

	/**
	 * Liste des pays
	 */
	get listePays() {
		if (!this._listePays) {
			this.initListePays();
		}

		return this._listePays
	}

	/**
	 * Initialisation de la liste des pays
	 */
	private initListePays() {
		//Définition de la liste des pays
		this._listePays = new ListView<PaysItem,PaysItemComponent>({
			uri: `/controller/Geographie/getListePays`,
			title: this.translateService.instant('admin.bibliotheque.geographie.pays.title', {libelle:""}),
			component: PaysItemComponent,
			defaultOrder: 'libelle',
			//Définition des filtres
			listeFilters: [{
				clef: 'libelleNettoye',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'code2',
				title: this.translateService.instant('admin.bibliotheque.geographie.pays.code2'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'code3',
				title: this.translateService.instant('admin.bibliotheque.geographie.pays.code3'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'devise',
				title: this.translateService.instant('admin.bibliotheque.geographie.pays.devise'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'isActif',
				title: this.translateService.instant('admin.bibliotheque.geographie.pays.actif'),
				isDefault: false,
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				type: TypeFilter[TypeFilter.BOOLEAN]
			}],
			isFilter: true
		})

		//Définition des colonnes de tri
		this._listePays.columns = [
			{key: 'libelleNettoye',title: 'filter.libelle'},
			{key: 'code2',title: 'admin.bibliotheque.geographie.pays.code2'},
			{key: 'code3',title: 'admin.bibliotheque.geographie.pays.code3'},
			{key: 'devise',title: 'admin.bibliotheque.geographie.pays.devise'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this._listePays.sorting = new Sorting(this._listePays.columns,"libelleNettoye");

		//Aucun filtre sélectionné par défaut
		this._listePays.listeSelectedFilters = [];
	}

	/**
	 * Récupère un pays
	 *
	 * @param id Identifiant du pays
	 */
	getPaysById(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/getPays/${id}`,null).pipe(first());
	}

	/**
	 * Enregistrement ou création d'un pays
	 *
	 * @param pays Pays à enregistrer
	 */
	savePays(pays: Pays): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/savePays/${pays.idPays}`,pays).pipe(first());
	}

	/**
	 * Liste des régions
	 */
	get listeRegions() {
		if (!this._listeRegions) {
			this.initListeRegions();
		}

		return this._listeRegions;
	}

	/**
	 * Initialisation de la liste des régions
	 */
	private initListeRegions() {
		//Définition de la liste des régions
		this._listeRegions = new ListView<RegionItem,RegionItemComponent>({
			uri: `/controller/Geographie/getListeRegions`,
			title: this.translateService.instant('admin.bibliotheque.geographie.regions.title'),
			component: RegionItemComponent,
			defaultOrder: 'pays.ordre,pays.libelle,libelle',
			//Définition des filtres
			listeFilters: [{
				clef: 'libelle',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'pays.libelle',
				title: this.translateService.instant('filter.pays'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			}],
			isFilter: true
		})

		//Définition des colonnes de tri
		this._listeRegions.columns = [
			{key: 'libelle',title: 'filter.libelle'},
			{key: 'pays.libelle',title: 'filter.pays'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this._listeRegions.sorting = new Sorting(this._listeRegions.columns,"pays.libelle");

		//Aucun filtre sélectionné par défaut
		this._listeRegions.listeSelectedFilters = [];
	}

	/**
	 * Récupère une région
	 *
	 * @param id Identifiant de la région
	 */
	getRegionById(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/getRegion/${id}`,null).pipe(first());
	}

	/**
	 * Enregistrement ou création d'une région
	 *
	 * @param isCreation Indique si on est en création ou en modification
	 * @param region Région à enregistrer
	 */
	saveRegion(isCreation: boolean,region: RegionAdmin): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/saveRegion/${isCreation}`,region).pipe(first());
	}

	/**
	 * Suppression d'une région
	 *
	 * @param idRegion id de la région à supprimer
	 */
	deleteRegion(idRegion: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/deleteRegion/${idRegion}`,null).pipe(first());
	}

	/**
	 * Liste des départements
	 */
	get listeDepartements() {
		if (!this._listeDepartements) {
			this.initListeDepartements();
		}

		return this._listeDepartements;
	}

	/**
	 * Initialisation de la liste des départements
	 */
	private initListeDepartements() {
		//Définition de la liste des départements
		this._listeDepartements = new ListView<DepartementItem,DepartementItemComponent>({
			uri: `/controller/Geographie/getListeDepartements`,
			title: this.translateService.instant('admin.bibliotheque.geographie.departements.title'),
			component: DepartementItemComponent,
			defaultOrder: 'regionAdmin.pays.ordre,regionAdmin.pays.libelle,regionAdmin.libelle,libelle',
			//Définition des filtres
			listeFilters: [{
				clef: 'libelleNettoye',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'code',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.code'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'regionAdmin.pays.libelle',
				title: this.translateService.instant('filter.pays'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'regionAdmin.libelle',
				title: this.translateService.instant('geographie.region'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			}],
			mapResult: (result: Page<DepartementItem>): Page<DepartementItem> => {
				//Récupère les infos liées au pays
				result.listeResultats = result.listeResultats.map(d => {
					d.idPays = d.regionAdmin?.idPays;
					d.pays = d.regionAdmin?.pays;
					return d;
				})
				return result;
			},
			isFilter: true
		})

		//Définition des colonnes de tri
		this._listeDepartements.columns = [
			{key: 'libelleNettoye',title: 'filter.libelle'},
			{key: 'regionAdmin.pays.libelle', title: 'filter.pays'},
			{key: 'regionAdmin.libelle', title: 'geographie.region'},
			{key: 'code', title: 'filter.code'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this._listeDepartements.sorting = new Sorting(this._listeDepartements.columns,"libelleNettoye");

		//Aucun filtre sélectionné par défaut
		this._listeDepartements.listeSelectedFilters = [];
	}

	/**
	 * Récupère un département
	 *
	 * @param id Identifiant du département
	 */
	getDepartementById(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/Departement/${id}`,null).pipe(first());
	}

	/**
	 * Enregistrement ou création d'un département
	 *
	 * @param departement Département à enregistrer
	 */
	saveDepartement(departement: DepartementAdmin): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/saveDepartement`,departement).pipe(first());
	}

	/**
	 * Suppression d'un département
	 *
	 * @param idDepartement id du département à supprimer
	 */
	deleteDepartement(idDepartement: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/deleteDepartement/${idDepartement}`,null).pipe(first());
	}

	/**
	 * Liste des villes
	 */
	get listeVilles() {
		if (!this._listeVilles) {
			this.initListeVilles();
		}

		return this._listeVilles;
	}

	/**
	 * Initialisation de la liste des villes
	 */
	private initListeVilles() {
		//Définition de la liste des villes
		this._listeVilles = new ListView<VilleItem,VilleItemComponent>({
			uri: `/controller/Geographie/getListeVilles`,
			title: this.translateService.instant('admin.bibliotheque.geographie.villes.title'),
			component: VilleItemComponent,
			defaultOrder: 'pays.libelle,libelle',
			//Définition des filtres
			listeFilters: [{
				clef: 'libelleNettoye',
				title: this.translateService.instant('filter.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'pays.libelleNettoye',
				title: this.translateService.instant('filter.pays'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: '*departementAdmin.libelleNettoye',
				title: this.translateService.instant('geographie.departement'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'codeIATA',
				title: this.translateService.instant('admin.bibliotheque.geographie.villes.codeIata'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'isActif',
				title: this.translateService.instant('admin.bibliotheque.geographie.villes.actif'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				type: TypeFilter[TypeFilter.BOOLEAN],
				listeValues: [
					{
						code: "true",
						libelle: this.translateService.instant('global.oui')
					},{
						code: "false",
						libelle: this.translateService.instant('global.non')
					}
				]
			}],
			isFilter: true
		})

		//Définition des colonnes de tri
		this._listeVilles.columns = [
			{key: 'pays.libelleNettoye', title: 'filter.pays'},
			{key: 'libelleNettoye',title: 'filter.libelle'},
			{key: 'departementAdmin.libelle', title: 'geographie.departement'},
			{key: 'codeIATA',title: 'filter.code'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this._listeVilles.sorting = new Sorting(this._listeVilles.columns,"pays.libelleNettoye");

		//Aucun filtre sélectionné par défaut
		this._listeVilles.listeSelectedFilters = [];
	}

	/**
	 * Récupère une ville
	 *
	 * @param id Identifiant de la ville
	 * @return Observable<Result> Résultat contenant la ville
	 */
	getVilleById(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/getVille/${id}`,null).pipe(first());
	}

	/**
	 * Enregistrement ou création d'une ville
	 *
	 * @param ville Ville à enregistrer
	 * @return Observable<Result> Résultat de l'enregistrement
	 */
	saveVille(ville: Ville): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/saveVille`,ville).pipe(first());
	}

	/**
	 * Suppression d'une ville
	 *
	 * @param idVille id de la ville à supprimer
	 * @return Observable<Result> Résultat de la suppression
	 */
	deleteVille(idVille: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/deleteVille/${idVille}`,null).pipe(first());
	}

	/**
	 * Liste des adresses
	 */
	get listeAdresses() {
		if (!this._listeAdresses) {
			this.initListeAdresses();
		}

		return this._listeAdresses;
	}

	/**
	 * Initialisation de la liste des adresses
	 */
	private initListeAdresses() {
		//Définition de la liste des adresses
		this._listeAdresses = new ListView<AdresseItem,AdresseItemComponent>({
			uri: `/controller/AdresseAdmin/searchListeAdresses`,
			title: this.translateService.instant('admin.bibliotheque.geographie.adresses.title'),
			component: AdresseItemComponent,
			defaultOrder: '-idAdresse',
			//Définition des filtres
			listeFilters: [{
				clef: 'rue',
				title: this.translateService.instant('admin.bibliotheque.geographie.adresses.voie'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},
				{
					clef: 'ville,*adresseVille.libelle',
					title: this.translateService.instant('filter.ville'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				}, {
					clef: 'pays,*adressePays.libelle',
					title: this.translateService.instant('filter.pays'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				}, {
					clef: 'latitude,longitude',
					title: this.translateService.instant('admin.bibliotheque.geographie.adresses.localisee'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: '0',
							libelle: this.translateService.instant('global.non')
						}, {
							code: '#adresseLocalisee',
							libelle: this.translateService.instant('global.oui')
						}
					]
				}, {
					clef: 'origine',
					title: this.translateService.instant('admin.bibliotheque.geographie.adresses.origine'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: Origine.ORIGINE_ADMIN.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.adresses.admin')
						}, {
							code: Origine.ORIGINE_USER.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.adresses.user')
						}, {
							code: Origine.ORIGINE_IMPORT.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.adresses.import')
						}, {
							code: Origine.ORIGINE_DISTANCE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.adresses.distance')
						}
					]
				}],
			isFilter: true
		})

		//Définition des colonnes de tri
		this._listeAdresses.columns = [
			{key: 'idAdresse', title: 'admin.bibliotheque.geographie.adresses.identifiant'},
			{key: '#rue',title: 'admin.bibliotheque.geographie.adresses.voie'},
			{key: '#ville',title: 'filter.ville'},
			{key: '#pays',title: 'filter.pays'},
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this._listeAdresses.sorting = new Sorting(this._listeAdresses.columns,"-idAdresse");

		//Aucun filtre sélectionné par défaut
		this._listeAdresses.listeSelectedFilters = [];
	}

	/**
	 * Récupère une adresse
	 *
	 * @param id Identifiant de l'adresse
	 * @return Observable<Result> Résultat contenant l'adresse
	 */
	getAdresseById(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/AdresseAdmin/${id}`,null).pipe(first());
	}

	/**
	 * Enregistrement ou création d'une adresse
	 *
	 * @param adresse Adresse à enregistrer
	 * @return Observable<Result> Résultat de l'enregistrement
	 */
	saveAdresse(adresse: Adresse): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Adresse/saveAdresse`,adresse).pipe(first());
	}

	/**
	 * Suppression d'une adresse
	 *
	 * @param idAdresse id de l'adresse à supprimer
	 * @return Observable<Result> Résultat de la suppression
	 */
	deleteAdresse(idAdresse: number): Observable<Result> {
		let params = new HttpParams().append("idAdresse",idAdresse.toString());
		return this.http.post<Result>(`${environment.baseUrl}/controller/Adresse/deleteAdresse`,null, {params: params}).pipe(first());
	}

	/**
	 * Récupère la configuration actuelle du paramétrage géolocalisation
	 */
	loadGeolocalisationParams(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geolocalisation/loadParams`,null).pipe(first());
	}

	/**
	 * Sauvegarde le paramétrage géolocalisation
	 *
	 * @param params configuration à sauvegarder
	 */
	saveGeolocalisationParams(params: GeolocalisationParams): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geolocalisation/saveParams`,params).pipe(first());
	}

	/**
	 * Calcul de la distance entre 2 villes ou 2 adresses pour tester les paramètres géolocalisation
	 *
	 * @param idDepart Identifiant de l'élément de départ
	 * @param idArrivee Identifiant de l'élément d'arrivée
	 * @param typeCiblage Indique le type de ciblage (ville ou adresse)
	 */
	calculDistance(idDepart: number, idArrivee: number, typeCiblage: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Geolocalisation/calculDistance/${idDepart}/${idArrivee}`,typeCiblage).pipe(first());
	}

    /**
     * Liste des iata
     */
    get listeIata() {
        if (!this._listeIata) {
            this.initListeIata();
        }

        return this._listeIata;
    }

    /**
     * Initialisation de la liste des iata
     */
    private initListeIata() {
        //Définition de la liste des iata
        this._listeIata = new ListView<IataItem, IataItemComponent>({
            uri: `/controller/Geographie/getListeIata`,
            title: this.translateService.instant('admin.bibliotheque.geographie.iata.titleListe'),
            component: IataItemComponent,
            defaultOrder: 'libelle',
            //Définition des filtres
            listeFilters: [{
                clef: 'libelle',
                title: this.translateService.instant('admin.bibliotheque.geographie.territoire.libelle'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }, {
                clef: 'codeIata,codeGare',
                title: this.translateService.instant('admin.bibliotheque.geographie.territoire.code'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }, {
                clef: '*ville.libelle',
                title: this.translateService.instant('filter.ville'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }, {
                clef: 'type',
                title: this.translateService.instant('filter.type'),
                listeValues: [{
                    code: TypeIata.AEROPORT.toString(),
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.iata.aeroport')
                }, {
                    code: TypeIata.GARE.toString(),
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.iata.gare')
                }]
            }],
            isFilter: true
        });

        //Définition des colonnes de tri
        this._listeIata.columns = [
            {key: 'libelleNettoye', title: 'filter.libelle'},
            {key: 'codeIata', title: 'admin.bibliotheque.geographie.iata.codeAeroport'},
            {key: 'codeGare', title: 'admin.bibliotheque.geographie.iata.codeGare'},
			{key: 'ville.libelle', title: 'filter.ville'},
            {key: 'type', title: 'filter.type'}
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this._listeIata.sorting = new Sorting(this._listeIata.columns, "libelleNettoye");

        //Aucun filtre sélectionné par défaut
        this._listeIata.listeSelectedFilters = [];
    }

    /**
     * Récupère un iata
     *
     * @param id Identifiant du iata
     */
    getIataById(id: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Geographie/Iata/${id}`, null).pipe(first());
    }

    /**
     * Enregistrement ou création d'un iata
     *
     * @param iata Iata à enregistrer
     */
    saveIata(iata: Iata): Observable<Result> {
        return this.http.put <Result>(`${environment.baseUrl}/controller/Geographie/saveIata`, iata).pipe(first());
    }

	/**
	 * Liste des distances
	 */
	get listeDistances() {
		if (!this._listeDistances) {
			this.initListeDistances();
		}

		return this._listeDistances;
	}

	/**
	 * Initialisation de la liste des distances
	 */
	private initListeDistances() {
		//Définition de la liste des distances
		this._listeDistances = new ListView<DistanceItem, DistanceItemComponent>({
			uri: `/controller/Adresse/getListeDistances`,
			title: this.translateService.instant('admin.bibliotheque.geographie.distances.title'),
			component: DistanceItemComponent,
			defaultOrder: 'libelle',
			//Définition des filtres
			listeFilters: [
				{
					clef: '#libelleDistance',
					title: this.translateService.instant('admin.bibliotheque.geographie.distance.filterLibelle'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				}, {
					clef: 'distance',
					title: this.translateService.instant('admin.bibliotheque.geographie.distance.distance'),
					type: TypeFilter[TypeFilter.DECIMAL]
				}, {
					clef: 'origine',
					title: this.translateService.instant('admin.bibliotheque.geographie.distances.origine'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: Origine.ORIGINE_ADMIN.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.distances.admin')
						},
						{
							code: Origine.ORIGINE_USER.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.distances.user')
						}, {
							code: Origine.ORIGINE_IMPORT.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.geographie.distances.import')
						}
					]
				}
			],
			isFilter: true
		})

		//Aucun filtre sélectionné par défaut
		this._listeDistances.listeSelectedFilters = [];
	}

	/**
	 * Récupère la liste de toutes les unités possibles pour les distances
	 */
	getListeUnites(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Adresse/getUnitesDistance`, null).pipe(first());
	}

	/**
	 * Récupère les adresses de départ et d'arrivée d'une distance
	 *
	 * @param idDepart Identifiant de l'adresse de départ
	 * @param idArrivee Identifiant de l'adresse d'arrivée
	 * @return Observable<Result> Résultat contenant l'adresse
	 */
	getAdressesForDistance(idDepart: number, idArrivee: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Adresse/getAdressesForDistance/${idDepart}/${idArrivee}`).pipe(first());
	}

	/**
	 * Récupère une distance
	 *
	 * @param id Identifiant de la distance
	 * @return Observable<Result> Résultat contenant la distance
	 */
	getDistanceById(id: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Adresse/getDistance/${id}`).pipe(first());
	}

	/**
	 * Récupère un utilisateur
	 *
	 * @param idUser Identifiant de l'utilisateur
	 * @return Observable<Result> Résultat contenant l'utilisateur
	 */
	getUserForDistance(idUser: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/User/getUser/${idUser}`).pipe(first());
	}

	/**
	 * Enregistrement ou création d'une distance
	 *
	 * @param distance Distance à enregistrer
	 * @return Observable<Result> Résultat de l'enregistrement
	 */
	saveDistance(distance: Distance): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/Adresse/saveDistance`, distance).pipe(first());
	}

	/**
	 * Suppression d'une distance
	 *
	 * @param idDistance id de la distance à supprimer
	 * @return Observable<Result> Résultat de la suppression
	 */
	deleteDistance(idDistance: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Adresse/deleteDistance/${idDistance}`).pipe(first());
	}

}
