<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="libelle"
                    name="libelle"
                    customType="input"
                    [(ngModel)]="zuRef.libelle"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.libelle"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="actif"
                    name="actif"
                    customType="checkbox"
                    [ngModel]="zuRef.actif"
                    [(customModel)]="zuRef.actif"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.actif">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="code"
                    name="code"
                    customType="input"
                    [(ngModel)]="zuRef.code"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.code">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="defaut"
                    name="defaut"
                    customType="checkbox"
                    [ngModel]="zuRef.defaut"
                    [(customModel)]="zuRef.defaut"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.defaut"
                    postLibelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.defautTooltip">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="attribut1"
                    name="attribut1"
                    customType="input"
                    [(ngModel)]="zuRef.attribut1"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.attribut1">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="attribut2"
                    name="attribut2"
                    customType="input"
                    [(ngModel)]="zuRef.attribut2"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.attribut2">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="attribut3"
                    name="attribut3"
                    customType="input"
                    [(ngModel)]="zuRef.attribut3"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.attribut3">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="attribut4"
                    name="attribut4"
                    customType="input"
                    [(ngModel)]="zuRef.attribut4"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addZuRefPopin.attribut4">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]>
        <span [translate]="'global.actions.annuler'"></span>
    </button>
    <button mat-flat-button color="primary" (click)="add()" [disabled]="form.invalid || isPending">
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>