import {ComponentRef,Directive,ElementRef,HostListener,Input,OnChanges,OnDestroy,OnInit,Optional,SecurityContext,SimpleChanges,TemplateRef,Type} from '@angular/core';
import {Overlay,OverlayPositionBuilder,OverlayRef} from "@angular/cdk/overlay";
import {TooltipComponent} from "./tooltip.component";
import {ComponentPortal} from "@angular/cdk/portal";
import {DomSanitizer} from "@angular/platform-browser";
import {ConnectedPosition} from "@angular/cdk/overlay/position/flexible-connected-position-strategy";
import {MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";

/**
 * Directive de tooltip custom
 *
 * @author Laurent Convert
 * @date 08/11/2021
 */
@Directive({
    selector: '[nioTooltip]',
    exportAs: 'nioTooltip'
})
export class TooltipDirective implements OnInit,OnDestroy,OnChanges {

    /**
     * Contenu du tooltip à afficher. Peut être :<br>
     * - une référence vers un template<br>
     * - un composant identifié par son type<br>
     * - une chaine (HTML géré)
     */
	@Input('nioTooltip')
	private content: TooltipContent;

    /** Le context à passer au template ou au composant pour résoudre les variables */
	@Input('nioTooltipContext')
	private context: any;

    /**
     * Position du tooltip.<br>
     * L'option 'track' permet au tooltip de suivre la souris.<br>
     * Si la place n'est pas suffisante pour afficher le tooltip, il s'affichera à l'opposé.<br>
     * Défaut : en bas
     */
	@Input('nioTooltipPosition')
	private position: TooltipPosition = 'bottom';

	/**
	 * Alignement horizontal du tooltip par rapport à l'élément parent.<br>
	 * Note : Inutile si paramètre nioTooltipWidth = 'host' ou 'hostNoPadding'<br>
	 * Défaut : center
	 */
	@Input('nioTooltipAlign')
	private align: TooltipAlign = 'center';

	/**
	 * Largeur du tooltip.<br>
	 * <ul>
	 *     <li>'auto' : taille auto, sans contrainte</li>
	 *     <li>'host' : Taille de l'élément parent</li>
	 *     <li>hostNoPadding : Taille de l'élément parent sans ses padding droite et gauche</li>
	 *     <li>number : Taille fixe en px</li>
	 * </ul><br>
	 * Défaut : auto.
	 */
	@Input('nioTooltipWidth')
	private width: TooltipWidth = 'auto';

    /**
     * Offset (en px) permettant de décaler le tooltip par rapport à l'élément de référence.<br>
     * Défaut : 5px.
     */
    @Input('nioTooltipOffset')
    private offset: number = 5;

    /**
     * Classe CSS du tooltip<br>
     * Utilisé uniquement si le contenu est de type string, autrement l'affichage est laissé au template / composant
     */
	@Input('nioTooltipClass')
	private tooltipClass: string;

    /** Indicateur de désactivation du tooltip, ce qui empêche sa création et donc son affichage */
	@Input('nioTooltipDisabled')
	private isDisabled: boolean = false;

    /** Object global des options du tooltip. Surcharge les options passées individuellement le cas échéant */
    @Input('nioTooltipOptions')
    private options: TooltipOptions;

    /** Position "en bas" */
    private _bottomPos: ConnectedPosition;

    /** Position "en haut" */
    private _topPos: ConnectedPosition;

    /** Position "à gauche" */
    private _leftPos: ConnectedPosition;

    /** Position "à droite" */
    private _rightPos: ConnectedPosition;

    /** Référence de l'overlay */
	private overlayRef: OverlayRef;

    /** Référence du tooltip */
	private tooltipRef: ComponentRef<TooltipComponent>;

    /** Souscription sur la fermeture de la popup (le cas échéant) */
    private subDialogBeforeClose: Subscription;

	/**
	 * Constructeur
	 */
	constructor(private overlay: Overlay,
				private overlayPositionBuilder: OverlayPositionBuilder,
				private elementRef: ElementRef<HTMLElement>,
                private sanitizer: DomSanitizer,
                @Optional() private matDialogRef: MatDialogRef<any>) {}

	/**
     * Détection de l'affichage de la vue
     */
    ngOnInit() {
		//Souscription sur la fermeture de la popup le cas échéant
		this.subDialogBeforeClose = this.matDialogRef?.beforeClosed().subscribe(() => {
			//On force la fermeture du tooltip juste avant la fermeture de la popin car il peut arriver que la popin soit fermée avant la destruction de l'overlay et du composant associé au tooltip
			//le tooltip ne peut alors plus être fermé et on se retrouve avec un tooltip persistant
			this.closeTooltip();
		});

		this.fetchOptions();
	}

	/**
	 * Initialise les options individuelles du tooltip depuis l'objet global si passé en paramètre
	 */
	private fetchOptions(): void {
		//Cas du passage d'un objet contenant les options du tooltip
		if (this.options) {
			this.context = this.options.context ?? this.context;
			this.position = this.options.position ?? this.position;
			this.align = this.options.align ?? this.align;
			this.width = this.options.width ?? this.width;
			this.offset = this.options.offset ?? this.offset;
			this.tooltipClass = this.options.tooltipClass ?? this.tooltipClass;
			this.isDisabled = this.options.isDisabled ?? this.isDisabled;
		}
	}

	/**
	 * Détection des changements de valeurs sur les Input() du composant
	 *
	 * @param changes Propriétés modifiées
	 */
	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes['options'] != null) {
			this.fetchOptions();
		}

		//Si le tooltip passe en disabled alors qu'il existe déjà, on le ferme
		if (this.isDisabled || changes && (changes['isDisabled']?.currentValue === true)) {
			//Fermeture
			this.closeTooltip();
		}
	}

	/**
	 * Destruction du tooltip
	 */
	ngOnDestroy(): void {
		//Destruction du tooltip
		this.tooltipRef?.destroy();
		this.tooltipRef = null;

		//Suppression de l'overlay
		this.overlayRef?.dispose();
		this.overlayRef = null;

		//Désabonnement de la souscription à la fermeture de la popin le cas échéant
		this.subDialogBeforeClose?.unsubscribe();
	}

	/**
	 * Initialise (si besoin) et retourne la position pour un positionnement du tootip "en dessous"
	 */
	get bottomPos(): ConnectedPosition {
		if (this._bottomPos == null) {
			//Initialisation
			this._bottomPos = {
				originX: 'center',
				originY: 'bottom',
				overlayX: 'center',
				overlayY: 'top',
				offsetY: +this.offset
			};
		}

		return this._bottomPos;
	}

	/**
	 * Initialise (si besoin) et retourne la position pour un positionnement du tooltip "au-dessus"
	 */
	get topPos(): ConnectedPosition {
		if (this._topPos == null) {
			//Initialisation
			this._topPos = {
				originX: 'center',
				originY: 'top',
				overlayX: 'center',
				overlayY: 'bottom',
				offsetY: -this.offset
			};
		}

		return this._topPos;
	}

	/**
	 * Initialise (si besoin) et retourne la position pour un positionnement du tooltip "à gauche"
	 */
	get leftPos(): ConnectedPosition {
		if (this._leftPos == null) {
			//Initialisation
			this._leftPos = {
				originX: 'start',
				originY: 'center',
				overlayX: 'end',
				overlayY: 'center',
				offsetX: -this.offset
			};
		}

		return this._leftPos;
	}

	/**
	 * Initialise (si besoin) et retourne la position pour un positionnement du tooltip "à droite"
	 */
	get rightPos(): ConnectedPosition {
		//Initialisation
		if (this._rightPos == null) {
			this._rightPos = {
				originX: 'end',
				originY: 'center',
				overlayX: 'start',
				overlayY: 'center',
				offsetX: +this.offset
			};
		}
		return this._rightPos;
	}
    
    /**
     * Fermeture du tooltip
     */
    closeTooltip() {
        //La fermeture du tooltip revient à sa destruction
        this.ngOnDestroy();
    }

    /**
     * Construction et affichage du tooltip lorsque le pointer entre dans l'élément
     */
    @HostListener('mouseenter',['$event'])
    show(event?: MouseEvent) {
        //Vérification que le tooltip n'est pas désactivé
        if (!this.isDisabled && !!this.content) {
            if (!this.overlayRef) {
                //Création de l'overlay
                this.createOverlay(event);

                //Création du tooltip
                this.createTooltip();

                //Recalcul de la position du tooltip quelques ms après son affichage pour corriger si besoin
                //(besoin si l'affichage lors de la création à la volée d'un élément, notamment dans le cas des options d'un mat-select)
                setTimeout(() => {
                    this.overlayRef?.updatePosition();
                },100);
            }
        }
    }

    /**
     * Masquage et destruction du tooltip lorsque le pointer quitte l'élément
     */
    @HostListener('mouseleave')
    hide() {
        //Fermeture du tooltip
        this.closeTooltip();
    }

    /**
     * Déplacement du tooltip pour suivre le curseur de la souris (si et seulement si la position est définie sur "track")
     *
     * @param event Évènement lié au déplacement de la souris
     */
    @HostListener('mousemove', ['$event'])
    updatePositionFromMouse(event: MouseEvent) {
        //Vérification que le tooltip n'est pas désactivé
        if (!this.isDisabled && !!this.content) {
			//Vérification de mode 'track'
			if (this.position == 'track') {
				//Définition des offsets de positionnement.
				//Obligatoire sinon le tooltip s'insère entre l'élément et le pointer et le hover est perdu (et on rentre dans une boucle enter/leave infinie)
				const offsetY = 0;
				const offsetX = 15;

				//Définition des coordonnées top et left : récupération des coordonnées de la souris et ajout de l'offset
				let top: number = event.clientY + offsetY;
				let left: number = event.clientX + offsetX;

				//Récupération de la largeur et de la hauteur du tooltip
				const boundingClientRect = this.overlayRef.overlayElement.getBoundingClientRect();
				const width = boundingClientRect.width;
				const height = boundingClientRect.height;

				//Si le tooltip dépasse de l'écran en largeur on swap à gauche
				if (width + left > window.innerWidth) {
					left = event.clientX - width - offsetX;
				}

				//Si le tooltip dépasse de l'écran en largeur on swap en haut
				if (height + top > window.innerHeight) {
					top = event.clientY - height - offsetY;
				}

				//Définition de la position globale (coordonnées absolues dans le viewport)
				const positionStrategy = this.overlayPositionBuilder
					.global()
					.top(top + 'px')
					.left(left + 'px');

				//On applique les nouvelles coordonnées au tooltip
				this.overlayRef.updatePositionStrategy(positionStrategy);
			}
		}
    }

    /**
     * Création de l'overlay
     *
     * @param mouseEvent Evènement lors de l'entrée
     */
    private createOverlay(mouseEvent?: MouseEvent): void {
        if (this.position == 'track') {
            //Création de l'overlay
            this.overlayRef = this.overlay.create();

            //Mise à jour de la position à partir de la position de la souris
            this.updatePositionFromMouse(mouseEvent);
        } else {
            let positions: ConnectedPosition[];

            //Si la position passée est de type Array on peut utiliser les positions directement
            if (this.position instanceof Array) {
                positions = this.position;
            } else {
                //Définition de l'ordre des positions avec basculement du côté opposé si la place ne permet pas l'affichage sur la position demandée
                switch(this.position) {
                    case "top":
                        //Haut, puis bas
                        positions = [this.topPos,this.bottomPos];
                        break;
                    case "right":
                        //droite, puis gauche
                        positions = [this.rightPos,this.leftPos];
                        break;
                    case "bottom":
                        //bas, puis haut
                        positions = [this.bottomPos,this.topPos];
                        break;
                    case "left":
                        //gauche, puis droite
                        positions = [this.leftPos,this.rightPos];
                        break;
                }
            }

            //Vérification d'une spécification d'alignement horizontal
            if (this.align !== 'center') {
                positions.forEach(p => {
                    p.originX = p.overlayX = this.align;
                });
            }

            //Définition de la stratégie de positionnement
            const positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(this.elementRef)
                .withPositions(positions)
                .withPush(true);

            //Définition de la stratégie du scroll (reposition du tooltip ou blocage du scroll à l'affichage du tooltip)
            const scrollStrategy = this.overlay.scrollStrategies.reposition();

            //Création de l'overlay
            this.overlayRef = this.overlay.create({ positionStrategy,scrollStrategy });
        }
    }

    /**
     * Création du tooltip
     */
    private createTooltip(): void {
		let hostWidth: number;
		let computedStyle: CSSStyleDeclaration;

        //Création du composant d'affichage du tooltip et attachement à l'overlay
        this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));

        //Vérification du type de tooltip
        if (this.content instanceof TemplateRef) { //Tooltip basé sur un template
            //Passage du template
            this.tooltipRef.instance.template = this.content;

            //Passage du context
            this.tooltipRef.instance.context = this.context;
        } else if (this.content instanceof Type) { //Tooltip basé sur un composant
            //Passage du composant
            this.tooltipRef.instance.component = this.content;

            //Passage du context
            this.tooltipRef.instance.context = this.context;
        } else { //Tooltip basé sur une chaine (HTML ou simple)
            //Sécurisation du HTML pour éviter les injections XSS
            this.tooltipRef.instance.text = this.sanitizer.sanitize(SecurityContext.HTML,this.content);

            //Ajout de la classe CSS
            this.tooltipRef.instance.tooltipClass = this.tooltipClass;
        }

        //Largeur du tooltip égale à celle de l'élément parent
        if (this.width === 'host' || this.width === 'hostNoPadding') {
			if (this.elementRef.nativeElement instanceof HTMLElement) {
				//Récupération de la largeur du parent (sans les border ni les margin éventuelles)
				hostWidth = this.elementRef.nativeElement.getBoundingClientRect().width;

				//Si on ne veut pas les padding
				if (this.width === 'hostNoPadding') {
					//Récupération du style calculé
					computedStyle = window.getComputedStyle(this.elementRef.nativeElement);

					//D'abord le padding gauche, toujours. Seulement si en px sinon c'est trop galère :x.
					if (computedStyle.paddingLeft && computedStyle.paddingLeft.endsWith('px')) {
						hostWidth -= parseInt(computedStyle.paddingLeft.replace('px',''));
					}

					//Puis le padding droite. Et seulement si en px. Toujours.
					if (computedStyle.paddingRight && computedStyle.paddingRight.endsWith('px')) {
						hostWidth -= parseInt(computedStyle.paddingRight.replace('px',''));
					}
				}

				//Et puis on l'applique
				this.tooltipRef.instance.width = hostWidth + 'px';
			} else {
				//Log d'erreur dans la console
				console.error('Erreur tooltip : élement HTML requis.');
			}
        } else if (typeof this.width === 'number') {
            this.tooltipRef.instance.width = this.width + 'px';
        }
    }
}

/** Positions supportées pour le placement du tooltip par rapport à l'élément host */
export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left' | 'track' | ConnectedPosition[];

/** Alignements supportés */
export type TooltipAlign = 'start' | 'center' | 'end';

/** Largeurs supportées */
export type TooltipWidth = 'auto' | 'host' | 'hostNoPadding' | number;

/** Types de contenus supportés */
export type TooltipContent = TemplateRef<any> | Type<any> | string;

/** Options disponibles */
export type TooltipOptions = {
    context?: any,
    position?: TooltipPosition,
    align?: TooltipAlign,
    width?: TooltipWidth,
    offset?: number,
    tooltipClass?: string,
    isDisabled?: boolean,
};
