import {Component} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {SbtConfigListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-list-item/sbt-config-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@domain/common/http/list-result";
import {Sorting} from "@domain/common/list-view/sorting";
import {Router} from "@angular/router";
import {TypeConfig} from "@domain/voyage/travel/constants";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {filter,finalize} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";

/**
 * Onglet 'Configuration SBT' du module d'administration 'Voyages/TravelHub'
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@Component({
    host: {'data-test-id': 'sbt-config'},
    templateUrl: './sbt-config.component.html'
})
export class SbtConfigComponent {
    /** Liste des configurations SBT */
    listeSbtConfig: ListView<TravelHubSBTConfigUsed,SbtConfigListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router Routeur Angular
     * @param toastrService Service de notification
     * @param confirmService Service de confirmation utilisateur via modale
     * @param pleaseWaitService Service d'affichage du loading
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private toastrService: ToastrService,
                private confirmService: ConfirmService,
                private pleaseWaitService: PleaseWaitService,
                private adminTravelhubService: AdminTravelhubService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Définition de la liste des configurations SBT
        this.listeSbtConfig = new ListView<TravelHubSBTConfigUsed,SbtConfigListItemComponent>({
            uri: '/controller/TravelHubConfig/listeSBTConfigUsed',
            title: this.translateService.instant('admin.voyages.travelhub.sbtConfig.liste.title'),
            component: SbtConfigListItemComponent,
            mapResult: (result: Page<TravelHubSBTConfigUsed>) => {
                //Transformation des résultats en instance
                result.listeResultats = result.listeResultats.map(item => {
                    return new TravelHubSBTConfigUsed(item);
                });
                return result;
            },
            defaultOrder: 'libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.voyages.travelhub.sbtConfig.libelle'),
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'sbtConfig.typeAiguillage',
                    title: this.translateService.instant('admin.voyages.travelhub.sbtConfig.type'),
                    type: TypeFilter[TypeFilter.LONG],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    listeValues: [{
                        code: TypeConfig.OFFLINE.toString(10),
                        libelle: this.translateService.instant(TypeConfig.traduction(TypeConfig[TypeConfig[TypeConfig.OFFLINE]]))
                    },{
                        code: TypeConfig.ONLINE.toString(10),
                        libelle: this.translateService.instant(TypeConfig.traduction(TypeConfig[TypeConfig[TypeConfig.ONLINE]]))
                    }]
                }
            ],
            extraOptions: {
                parent: this
            }
        });

        //Définition des colonnes de tri
        this.listeSbtConfig.columns = [
            { key: 'libelle', title: 'admin.voyages.travelhub.sbtConfig.libelle' },
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeSbtConfig.sorting = new Sorting(this.listeSbtConfig.columns, "libelle");

        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createSbtConfig()
        }]);
    }

    /**
     * Ouvre la popup de création / modification d'une configuration SBT
     *
     * @param sbtConfigUsed La configuration SBT à éditer
     */
    openSbtConfig(sbtConfigUsed: TravelHubSBTConfigUsed): void {
        this.adminTravelhubService.navigateToConfigSbtDetail(sbtConfigUsed.idSBTConfigUsed);
    }

    /**
     * Ouvre la popup de création
     */
    createSbtConfig(): void {
        this.adminTravelhubService.navigateToNewConfigSbt();
    }

    /**
     * Duplique la configuration en base et redirige sur la copie
     *
     * @param configSource La configuration SBT à copîer
     */
    copySbtConfig(configSource: TravelHubSBTConfigUsed): void {
        //Modale de chargement
        let pleaseWaitRef = this.pleaseWaitService.show();

        this.adminTravelhubService.copySbtConfig(configSource)
            .pipe(finalize(() => pleaseWaitRef.close()))
            .subscribe(result => {
                //Vérification du résultat
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Redirection vers la copie
                    this.adminTravelhubService.navigateToConfigSbtDetail(result.data.idSBTConfigUsed);
                } else {
                    //Message d'erreur
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
        },() => {
            //Message d'erreur générique
            TypeCodeErreur.showError(null,this.translateService,this.toastrService);
        });
    }

    /**
     * Suppression d'une configuration
     *
     * @param sbtConfigUsed La configuration SBT à supprimer
     */
    deleteSbtConfig(sbtConfigUsed: TravelHubSBTConfigUsed): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() =>{
                //Modale de chargement
                let pleaseWaitRef = this.pleaseWaitService.show();

                //Suppression
                this.adminTravelhubService.deleteSbtConfig(sbtConfigUsed.idSBTConfigUsed)
                    .pipe(finalize(() => pleaseWaitRef.close()))
                    .subscribe(result => {
                        if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Message de succès
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Rechargement de la liste
                            this.listeSbtConfig.refresh();
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    },() => {
                        //Message d'erreur
                        TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
                    });
            });
    }

}