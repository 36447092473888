import {Component,Input} from '@angular/core';
import {Prestation} from "@domain/prestation/prestation";

/**
 * Tooltip pour afficher le détail d'une prestation
 *
 * @author Laurent Convert
 * @date 09/11/2023
 */
@Component({
    host: {'data-test-id': 'tooltip-prestation'},
    templateUrl: './tooltip-prestation.component.html',
    styleUrls: ['./tooltip-prestation.component.scss']
})
export class TooltipPrestationComponent {
    /** La prestation */
    @Input()
    prestation: Prestation;
}