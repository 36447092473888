import {NgModule} from '@angular/core';
import {ShareModule} from '@share/share.module';
import {ComptabiliteModeRemboursementService} from "./comptabilite-mode-remboursement.service";
import {ComptabiliteModeRemboursementComponent} from "@components/admin/comptabilite/mode-remboursement/comptabilite-mode-remboursement.component";
import {ComptabiliteModeRemboursementListItemComponent} from "@components/admin/comptabilite/mode-remboursement/list-item/comptabilite-mode-remboursement-list-item.component";
import {ComptabiliteModeRemboursementAddComponent} from "@components/admin/comptabilite/mode-remboursement/creation/comptabilite-mode-remboursement-add.component";
import {NgxIbanModule} from "ngx-iban";
import {LieuModule} from "@components/lieu/lieu.module";

/**
 * Module Comptabilité / Modes de remboursement
 */
@NgModule({
	imports: [
		ShareModule,
		NgxIbanModule,
		LieuModule
	],
	declarations: [
		ComptabiliteModeRemboursementComponent,
		ComptabiliteModeRemboursementListItemComponent,
		ComptabiliteModeRemboursementAddComponent,
	],
	providers: [
		ComptabiliteModeRemboursementService
	]
})
export class ComptabiliteModeRemboursementModule {
}
