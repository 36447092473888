import {NiveauAutorisation} from "@domain/vehicule/autorisation";
import {TranslateService} from "@ngx-translate/core";

/**
 * Classe représentant les options des véhicules
 */
export class OptionsVehicules {
    certifImmatriculation: boolean;
    certifImmatriculationObligatoire: boolean;
    attestationAssurance: boolean;
    attestationObligatoire: boolean;
    approbation: boolean;
    autorisationCirculer: boolean;
    idNotifValid: number;
    idNotifRefus: number;
    idNotifEmission: number;
    ndfApprobation: NiveauAutorisation;
    ndfAutorisation: NiveauAutorisation;
    odApprobation: NiveauAutorisation;
    odAutorisation: NiveauAutorisation;

    /** Front only - pour la gestion du formulaire */
    certifImmatriculationEtat: SelectOptionConfiguration;
    attestationAssuranceEtat: SelectOptionConfiguration;

    /**
     * Constructeur
     *
     * @param dto DTO ou objet source à copier
     */
    constructor(dto?: any | OptionsVehicules) {
        if (dto) {
            //Copie
            Object.assign(this, dto);

            //Définition de la valeur de la configuration du certificat d'immatriculation
            if (this.certifImmatriculationObligatoire) {
                this.certifImmatriculationEtat = SelectOptionConfiguration.ACTIF_OBLIGATOIRE;
            } else if (this.certifImmatriculation) {
                this.certifImmatriculationEtat = SelectOptionConfiguration.ACTIF;
            } else {
                this.certifImmatriculationEtat = SelectOptionConfiguration.DESACTIVE;
            }

            //Définition de la valeur de la configuration de l'assurance
            if (this.attestationObligatoire) {
                this.attestationAssuranceEtat = SelectOptionConfiguration.ACTIF_OBLIGATOIRE;
            } else if (this.attestationAssurance) {
                this.attestationAssuranceEtat = SelectOptionConfiguration.ACTIF;
            } else {
                this.attestationAssuranceEtat = SelectOptionConfiguration.DESACTIVE;
            }
        }
    }
}

/**
 * Enum des types de configuration
 */
export enum SelectOptionConfiguration {
    DESACTIVE,
    ACTIF,
    ACTIF_OBLIGATOIRE
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace SelectOptionConfiguration {
    /**
     * Renvoie un tableau contenant tous les éléments de l'énuméré
     */
    export function values(): Array<SelectOptionConfiguration> {
        return [SelectOptionConfiguration.DESACTIVE, SelectOptionConfiguration.ACTIF, SelectOptionConfiguration.ACTIF_OBLIGATOIRE];
    }

    /**
     * Traduction des options de configuration.
     * Renvoi la chaîne traduite si le service est passé en paramètre et le code de traduction dans le cas contraire.
     *
     * @param config Configuration
     * @param translateService Service de traduction (facultatif, dans ce cas seul le code de traduction sera renvoyé au lieu de la chaîne traduite)
     */
    export function traduction(config: SelectOptionConfiguration, translateService?: TranslateService): string {
        let code: string;

        //Suivant la configuration
        switch (config) {
            case SelectOptionConfiguration.DESACTIVE:
                code = 'admin.entreprise.vehicules.parametres.configuration.desactive';
                break;
            case SelectOptionConfiguration.ACTIF:
                code = 'admin.entreprise.vehicules.parametres.configuration.actif';
                break;
            case SelectOptionConfiguration.ACTIF_OBLIGATOIRE:
                code = 'admin.entreprise.vehicules.parametres.configuration.obligatoire';
                break;
        }

        //Vérification du passage du service de traduction
        if (translateService) {
            //Traduction
            code = translateService.instant(code);
        }

        return code;
    }
}