<h1 mat-dialog-title>
	<span translate>profilUser.popupRGPD.title</span>
	<span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
	<i class="material-icons rgpd">policy</i>
	<div>
		<b class="rgpd" translate>profilUser.popupRGPD.subtitle</b>
		<pre>{{ 'profilUser.popupRGPD.text' | translate }}</pre>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-stroked-button color="primary" [mat-dialog-close]="false"><span [translate]="'global.actions.annuler'"></span></button>
	<button mat-flat-button color="primary" (click)="changeAccessStatus()" [disabled]="isLoading">
		<span *ngIf="!isLoading" translate>global.actions.confirmer</span>
		<mat-spinner class="m-5" diameter="28" *ngIf="isLoading"></mat-spinner>
	</button>
</div>
