import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Status,Vehicule} from "@domain/vehicule/vehicule";
import {AlertesVehicule} from "@domain/vehicule/alertesVehicule";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {MatDialog} from "@angular/material/dialog";
import {VehiculeListAlerteComponent} from "../../../vehicule/list/vehicule-list-alerte.component";
import {VehiculeService} from "../../../vehicule/vehicule.service";
import {SettingsVehiculeState} from "@domain/settings/settings";

/**
 * Composant de l'item de liste d'un véhicule
 */
@Component({
	selector: 'profil-donnees-vehicule-list-item',
	templateUrl: './profil-donnees-vehicule-list-item.component.html'
})
export class ProfilDonneesVehiculeListItemComponent extends ListViewItem<Vehicule> {
	/** Elément courant */
	@Input() data: Vehicule;

	/** Options supplémentaires **/
	@Input() extraOptions: {
		settings: SettingsVehiculeState,
		isAdmin?: boolean
	};

	isDanger: boolean;
	alertes: AlertesVehicule;

	/**
	 * Constructeur
	 *
	 * @param router le routeur d'Angular
	 * @param translateService le moteur de traduction
	 * @param vehiculeService le service de gestion des véhicules
	 * @param store le store de l'application
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private router: Router,
		private translateService: TranslateService,
		private vehiculeService: VehiculeService,
		private store: Store<AppState>,
		private matDialog: MatDialog) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	async ngOnInit() {
		this.data = Object.assign(new Vehicule(),this.data);

		//Mise en place des alertes
		this.alertes = this.vehiculeService.buildAlertes(this.data,this.extraOptions?.settings);
	}

	/**
	 * Ouverture d'un véhicule
	 */
	navigateToVehicule(vehicule: Vehicule): void {
		//Navigation vers le véhicule
		if (this.extraOptions.isAdmin) {
			this.router.navigate(['Admin/Entreprise/Utilisateurs/User',vehicule.user.idUser,'Vehicule',vehicule.idPa]);
		} else {
			this.router.navigate(['Profil/Vehicule/' + vehicule.idPa]);
		}
	}

	/**
	 * Permet de récupérer la traduction du statut véhicule
	 *
	 * @param status Status
	 */
	getStatusLibelle(status: Status) {
		switch (status) {
			case Status.STATUT_NON_TRAITE:
				return this.translateService.instant('vehicule.statut.nonTraite');
			case Status.STATUT_VALIDE:
				return this.translateService.instant('vehicule.statut.valide');
			case Status.STATUT_REFUSE:
				return this.translateService.instant('vehicule.statut.refuse');
			case Status.STATUT_BROUILLON:
				return this.translateService.instant('vehicule.statut.brouillon');
			default:
				return "";
		}
	}

	/**
	 * Permet d'afficher la liste des alertes
	 */
	showListeWarning() {
		//Affichage de la popup de la liste des alertes
		this.matDialog.open(VehiculeListAlerteComponent,{
			data: {
				alertes: this.alertes,
				approbation: this.data.vehiculeApprobation
			},
		});
	}
}
