import {Component,EventEmitter,Input,OnDestroy,OnInit,Output} from '@angular/core';
import {Approbateur,Priorite,WFUser} from "@domain/workflow/evenement";
import {FilterValue,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {ApprobateurListItemComponent} from "./approbateur-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ListViewService} from "@share/component/list-view/list-view.service";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Composant d'affichage de la liste des approbateurs
 *
 * @author Laurent Convert
 * @date 18/04/2024
 */
@Component({
	host: {'data-test-id': 'approbateur-list'},
	selector: 'approbateur-list',
	templateUrl: './approbateur-list.component.html'
})
export class ApprobateurListComponent implements OnInit,OnDestroy {
	/** Liste des approbateurs */
	@Input() listeApprobateur: Array<Approbateur>;

	/** Évènement émit lors de la sélection de l'approbateur */
	@Output() onApprobateurChange: EventEmitter<{approbateur: WFUser,listeApprobateurs: Approbateur}> = new EventEmitter();

	/** Listview des approbateurs  */
	listViewApprobateur: ListView<Approbateur,ApprobateurListItemComponent>;

	/** Approbateur sélectionné */
	selectedApprobateur: WFUser;

	/** Souscription à l'évènement de sélection de l'approbateur */
	selectionSub: Subscription;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param listViewService Service de gestion des ListView
	 */
	constructor(private translateService: TranslateService,private listViewService: ListViewService<Approbateur,ApprobateurListItemComponent>) {
		//Abonnement au changement de l'approbateur
		this.selectionSub = this.onApprobateurChange.subscribe(val => {
			//Si un approbateur était déjà sélectionné
			if (this.selectedApprobateur != null) {
				//On le désélectionne
				this.selectedApprobateur.isSelected = false;
			}

			//Si un approbateur a été sélectionné
			if (val?.approbateur != null) {
				//On met à jour l'objet
				val.approbateur.isSelected = true;
			}

			//On le mémorise sur le composant
			this.selectedApprobateur = val?.approbateur;
		});
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		const mapRoleFilter: Map<number,FilterValue> = new Map<number,FilterValue>();

		//Construction de la liste des rôles filtrables à partir des utilisateurs
		this.listeApprobateur.forEach(appro => {
			appro.users.forEach(u => {
				if (!mapRoleFilter.has(u.idRole)) {
					mapRoleFilter.set(u.idRole,{
						code: String(u.idRole),
						libelle: u.libelleRole,
					});
				}
			});
		});

		//Initialisation de la listview
		this.listViewApprobateur = new ListView<Approbateur,ApprobateurListItemComponent>({
			title: this.translateService.instant('workflow.notification.approbateur'),
			component: ApprobateurListItemComponent,
			extraOptions: {
				onApprobateurChange: this.onApprobateurChange,
			},
			onRefresh: this.onListeRefreshed.bind(this),
			//Liste des filtres : ils ne concernent que les utilisateurs de chaque groupe (le niveau 2 de la liste)
			listeFilters: [{
				clef: 'nom',
				title: this.translateService.instant('workflow.notification.nom'),
				isDefault: true,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'prenom',
				title: this.translateService.instant('workflow.notification.prenom'),
				isDefault: true,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'matricule',
				title: this.translateService.instant('workflow.notification.matricule'),
				isDefault: true,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'idRole',
				title: this.translateService.instant('workflow.notification.role'),
				isDefault: false,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.IN],
				listeValues: new Array<FilterValue>(...mapRoleFilter.values()),
				multiple: true,
			},{
				clef: 'prioriteKey',
				title: this.translateService.instant('workflow.notification.priorite.title'),
				isDefault: false,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.IN],
				listeValues: Priorite.values().map(priorite => {
					return {
						code: priorite.valueOf(),
						libelle: this.translateService.instant(`workflow.notification.priorite.${priorite.valueOf()}`)
					}
				}),
				multiple: true,
			}],
			data: {
				numPage: 0,
				nbPagesTotal: 1,
				listeResultats: this.listeApprobateur,
				nbObjetsDansPage: this.listeApprobateur.length,
				nbObjetsParPage: this.listeApprobateur.length,
				nbObjetsTotal: this.listeApprobateur.length,
			},
			isFrontendList: true,
			isFilter: true,
			isSearchBar: true,
			isFilterVisible: true,
			showPagination: false,
		});

		//Définition des colonnes de tri
		this.listViewApprobateur.columns = [
			{key: 'prioriteForFilter',title: 'workflow.notification.priorite.title'},
			{key: 'nom',title: 'workflow.notification.nom'},
			{key: 'prenom',title: 'workflow.notification.prenom'},
			{key: 'matricule',title: 'workflow.notification.matricule'},
			{key: 'libelleRole',title: 'workflow.notification.role'},
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listViewApprobateur.sorting = new Sorting(this.listViewApprobateur.columns,"prioriteForFilter,nom,prenom");
	}

	/**
	 * Rafraichissement de la liste des approbateurs en fonction des filtres / tris
	 *
	 * @param liste Liste des approbateurs
	 */
	onListeRefreshed(liste: ListView<Approbateur,ApprobateurListItemComponent>) {
		//Parcours des "groupes" d'approbateurs (fonctionnellement il ne devrait y en avoir qu'un seul)
		liste.data.listeResultats.forEach(appro => {
			//Parcours des utilisateurs
			appro.approbateurs.forEach(u => {
				//Mise à jour de l'utilisateur pour indiquer s'il correspond aux filtres ou non
				u.isFiltre = !this.listViewService.isObjectMatchSearch(u,liste);
			});

			//Tri de la liste des utilisateurs
			if (liste.sorting?.columns?.length > 0) {
				this.listViewService.sortFrontendList(appro.approbateurs,liste.sorting.getFormattedSorting());
			}
		});

		//Actualisation de l'affichage
		liste.onFiltrageFront$.next();
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.selectionSub?.unsubscribe();
	}

}