<h1 mat-dialog-title>
    <span [translate]="'profilUser.infoPerso.title'"></span>
    <span [mat-dialog-close]=""><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" name="modifyInfosPersoForm" #modifyInfosPersoForm="ngForm">
        <div class="row">
            <custom-input ngDefaultControl customType="input" name="nom_usage" libelle="profilUser.infoPerso.nomUsage"
                          [(ngModel)]="profilUser.infoVoyageur.nomUsage" [maxlength]="100" required>
            </custom-input>
            <custom-input ngDefaultControl customType="autocomplete" name="nationalite" libelle="profilUser.infoPerso.nationalite"
                          [(ngModel)]="profilUser.infoVoyageur.paysNationalite" autocompleteType="geographie" optionDisplay="libelle"
                          [autocompleteFilter]="{ listeTypes: ['PAYS'] }" (onChange)="onPaysNationaliteChange()">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl customType="date" name="date_naissance" libelle="profilUser.infoPerso.dateNaissance"
                          [ngModel]="profilUser.infoVoyageur.dateNaissance" [(customModel)]="profilUser.infoVoyageur.dateNaissance" [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input *ngIf="!profilUser.infoVoyageur.newVille" ngDefaultControl customType="autocomplete" name="ville_naissance" libelle="profilUser.infoPerso.villeNaissance"
                          [(ngModel)]="profilUser.infoVoyageur.villeNaissance" autocompleteType="geographie" optionDisplay="libelle"
                          [autocompleteFilter]="{ listeTypes: ['VILLE'] }" (onChange)="onVilleNaissanceChange()">
            </custom-input>
            <custom-input *ngIf="!!profilUser.infoVoyageur.newVille" ngDefaultControl customType="input" name="ville_naissance" libelle="profilUser.infoPerso.villeNaissance"
                          [(ngModel)]="profilUser.infoVoyageur.ville" [maxlength]="200">
            </custom-input>
            <custom-input ngDefaultControl customType="checkbox" name="new_ville" libelle="profilUser.infoPerso.newVille" postLibelle="profilUser.infoPerso.newVilleDesc"
                          [ngModel]="profilUser.infoVoyageur.newVille" [(customModel)]="profilUser.infoVoyageur.newVille">
            </custom-input>
        </div>
        <div class="row" *ngIf="!!profilUser.infoVoyageur.newVille">
            <custom-input ngDefaultControl customType="input" name="code_postal" libelle="profilUser.infoPerso.codePostal"
                          [(ngModel)]="profilUser.infoVoyageur.codePostal" [required]="true">
            </custom-input>
            <custom-input ngDefaultControl customType="autocomplete" name="pays_naissance" libelle="profilUser.infoPerso.paysNaissance"
                          [(ngModel)]="profilUser.infoVoyageur.paysNaissance" autocompleteType="geographie" optionDisplay="libelle"
                          [autocompleteFilter]="{ listeTypes: ['PAYS'] }" (onChange)="onPaysNaissanceChange()" [required]="true">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]="false"><span [translate]="'global.actions.annuler'"></span></button>
    <button mat-flat-button color="primary" [disabled]="modifyInfosPersoForm.invalid" [mat-dialog-close]="profilUser"><span [translate]="'global.actions.enregistrer'"></span></button>
</div>
