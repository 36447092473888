import { Component } from "@angular/core";

/**
 * Composant d'affichage de l'onglet bibliotheque
 */
@Component({
    host: {'data-test-id': 'admin-bibliotheque'},
    selector: 'admin-bibliotheque',
    templateUrl: './admin-bibliotheque.component.html'
})
export class AdminBibliothequeComponent {
}