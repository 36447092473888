<mat-card>
    <mat-card-title>
        <span [translate]="'analytique.title'"></span>
        <span class="actions" mat-icon-button>
            <mat-icon class="material-icons-outlined" (click)="showCalculDonneesBudgetPopup()">auto_fix_high</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item" *ngFor="let ventilation of listeVentilations">
                    <ventilation [ventilation]="ventilation" [typeEntite]="typeEntite" [globalSettings]="globalSettings"></ventilation>
                </div>
                <div class="empty" *ngIf="!listeVentilations?.length"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>