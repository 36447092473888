import {Component,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {EnergieListItemComponent} from "@components/admin/entreprise/vehicules/vehicules-energies/energie-list-item/energie-list-item.component";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {MatDialog} from "@angular/material/dialog";
import {EnergieDetailComponent} from "@components/admin/entreprise/vehicules/vehicules-energies/energie-detail/energie-detail.component";
import {TranslateService} from "@ngx-translate/core";
import {EnergieFiscale} from "@domain/admin/entreprise/vehicules/energieFiscale";
import {Result} from "@domain/common/http/result";

/**
 * Page admin de Véhicules / Energies
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'vehicules-energies'},
    templateUrl: './vehicules-energies.component.html'
})
export class VehiculesEnergiesComponent implements OnInit {
    /** Liste des énergies */
    listeEnergies: ListView<EnergieFiscale,EnergieListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param matDialog Boîte de dialogue
     * @param translateService Service des traductions
     */
    constructor(private matDialog: MatDialog,
                private translateService: TranslateService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Création de la liste des énergies
        this.listeEnergies = new ListView<EnergieFiscale,EnergieListItemComponent>({
            uri: `/controller/VehiculeAdmin/listeEnergies`,
            title: this.translateService.instant('admin.entreprise.vehicules.energies.title'),
            isSimple: true,
            component: EnergieListItemComponent,
            mapResult: (result: Result) => result?.data?.listeEnergies,
            extraOptions: {
                openEnergie: this.openEnergie.bind(this)
            }
        });

        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.openEnergie()
        }]);
    }

    /**
     * Ouverture d'une énergie
     *
     * @param energie Energie. null => création
     */
    openEnergie(energie?: EnergieFiscale) {
        //Ouverture de l'énergie
        this.matDialog.open(EnergieDetailComponent, {
            width: '60%',
            data: {
                energie: new EnergieFiscale(energie ?? {idEnergie: 0, ordre: 0})
            }
        }).afterClosed().subscribe((isReload: boolean) => {
            //Vérification du besoin de recharger la liste
            if (isReload) {
                //Rechargement
                this.listeEnergies.refresh();
            }
        });
    }

}
