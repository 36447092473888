<form #form="ngForm">
    <mat-card>
        <mat-card-title>
                    <span [translate]="isCreation ? 'admin.entreprise.analytique.enveloppes.details.ajoutEnveloppe' : 'admin.entreprise.analytique.enveloppes.details.enveloppe'">
                    </span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="enveloppe.libelle"
                                  customType="input"
                                  libelle="admin.entreprise.analytique.enveloppes.details.libelle"
                                  maxlength="2000"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="alerteEdited"
                                  name="alerteEdited"
                                  [ngModel]="enveloppe.alerteEdited"
                                  [(customModel)]="enveloppe.alerteEdited"
                                  customType="checkbox"
                                  libelle="admin.entreprise.analytique.enveloppes.details.controle"
                                  postLibelle="admin.entreprise.analytique.enveloppes.details.controlePostLibelle"
                                  (ngModelChange)="onControleChange()">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="reference"
                                  name="reference"
                                  [(ngModel)]="enveloppe.reference"
                                  customType="input"
                                  maxlength="2000"
                                  libelle="admin.entreprise.analytique.enveloppes.details.reference">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  [hidden]="!enveloppe.alerteEdited"
                                  id="pourcentageNonBloquant"
                                  name="pourcentageNonBloquant"
                                  [(ngModel)]="enveloppe.pourcentageNonBloquant"
                                  customType="number"
                                  min="0"
                                  max="100"
                                  rCol="3"
                                  libelle="admin.entreprise.analytique.enveloppes.details.nonBloquant"
                                  [nbDecimales]="0"
                                  suffixIcon="warning"
                                  suffixIconClass="warning"
                                  [suffix]="'admin.entreprise.analytique.enveloppes.details.alertBudgetConsomme' | translate"
                                  [readonly]="!enveloppe.alerteEdited"
                                  [required]="enveloppe.alerteEdited">
                    </custom-input>
                </div>
                <div class="row ">
                    <!--Utilisé pour remplir le vide l'offset ne fonctionnant pas correctement sur le custom input-->
                    <span class="col-md-6"></span>
                    <custom-input ngDefaultControl
                                  [hidden]="!enveloppe.alerteEdited"
                                  id="pourcentageBloquant"
                                  name="pourcentageBloquant"
                                  [(ngModel)]="enveloppe.pourcentageBloquant"
                                  customType="number"
                                  min="0"
                                  max="100"
                                  rCol="3"
                                  libelle="admin.entreprise.analytique.enveloppes.details.bloquant"
                                  [nbDecimales]="0"
                                  suffixIcon="warning"
                                  suffixIconClass="error"
                                  [suffix]="'admin.entreprise.analytique.enveloppes.details.alertBudgetConsomme' | translate"
                                  [readonly]="!enveloppe.alerteEdited"
                                  [required]="enveloppe.alerteEdited"
                                  numberSuperieurOther
                                  [otherNumber]="enveloppe.pourcentageNonBloquant"
                                  [isSameValid]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="commentaire"
                                  name="commentaire"
                                  [(ngModel)]="enveloppe.commentaire"
                                  maxlength="2000"
                                  customType="textarea"
                                  rCol="9"
                                  libelle="admin.entreprise.analytique.enveloppes.details.commentaire">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
    <analytique
		#analytique="analytique"
        [listeAnalytiques]="listeAnalytique" [precision]="0"
        [typeEntite]="typeEntite" [settings]="settings"
        [canModifier]="true" [canSaisie]="true"
        [isAdmin]="true"
        (deleteAnalytique)="deleteAnalytique($event)"
        (onModification)="onModificationAnalytique()"
        [preferenceAnalytiqueCount]="0"
        [isShowTab]="false"
    ></analytique>
</form>
