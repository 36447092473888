import {Component,OnInit} from '@angular/core';
import {ListView,TypeFilter} from "@domain/common/list-view";
import {DelegationsListItemComponent} from "./delegations-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {MatDialog} from "@angular/material/dialog";
import {DelegationCreationComponent} from "./creation/delegation-creation.component";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {DelegationsService} from "@components/delegations/delegations.service";
import {first} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@Component({
	templateUrl: './delegations-list.component.html'
})
/**
 * Composant de liste des délégations
 */
export class DelegationsListComponent implements OnInit {

	/** Liste des délégations */
	listeDeleg: ListView<LienRoleUser,DelegationsListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService le service de traduction
	 * @param matDialog la fenêtre modale de création/modification
	 * @param delegationService le service de gestion des délégations
	 */
	constructor(
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private delegationService: DelegationsService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement de la liste des délégations
		this.listeDeleg = new ListView<LienRoleUser,DelegationsListItemComponent>({
			uri: "/controller/Perso/listeDelegations",
			title: this.translateService.instant("delegations.liste.title"),
			component: DelegationsListItemComponent,
			isFilter: true,
			listeFilters: [{
				clef: "user.nom",
				title: this.translateService.instant("filter.nom"),
				isDefault: true
			},{
				clef: "user.prenom",
				title: this.translateService.instant("filter.prenom"),
				isDefault: true
			},{
				clef: "user.matricule",
				title: this.translateService.instant("filter.matricule"),
				isDefault: true
			},{
				clef: "role.libelle",
				title: this.translateService.instant("filter.role"),
				isDefault: true
			},{
				clef: "dateDebut",
				title: this.translateService.instant("filter.dateDebut"),
				type: TypeFilter[TypeFilter.DATE]
			},{
				clef: "dateFin",
				title: this.translateService.instant("filter.dateFin"),
				type: TypeFilter[TypeFilter.DATE]
			}],
			defaultOrder: "-dateFin",
			extraOptions: {
				createModifyDeleg: this.createModifyDeleg.bind(this)
			}
		});

		//On vérifie s'il est possible de créer une délégation avec l'utilisateur actuellement connecté
		this.delegationService.isDelegationCreatable().pipe(first()).subscribe(isCreatable => {
			if (isCreatable) {
				//Définition de l'action de création
				this.listeActions.next([{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-ajouter',
					libelle: 'global.actions.creer',
					doAction: () => this.createModifyDeleg()
				}]);
			}
		});
	}

	/**
	 * Création/modification d'une délégation
	 */
	createModifyDeleg(deleg?: LienRoleUser) {
		this.matDialog.open(DelegationCreationComponent,{
			data: {
				//Permet d'envoyer new LienRoleUser() si deleg n'est pas fourni, et de récupérer les valeurs de deleg s'il l'est
				deleg: {...new LienRoleUser(),...deleg}
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: data => {
				if (data) {
					//Rafraichissement de la liste
					this.listeDeleg.refresh();
				}
			}
		});
	}

}