import {ListItem} from "../common/list-view/list-item";
import {Population} from "./population";
import {UserDto} from "../user/user-dto";
import {Role} from "./role";
import {Enveloppe} from "@domain/budget/enveloppe";

/**
 * DTO Délégation (LienRoleUser)
 */
export class LienRoleUser implements ListItem {

	idLienRoleUser: number;
	priorite: TypePriorite;
	dateDebut: Date = null;
	dateFin: Date = null;
	role: Role = null;
	idRole: number;
	user: UserDto;
	idUser: number;
	userDelegant: UserDto;
	idUserDelegant: number;
	population: Population;
	idPopulation: number;
	listeEnveloppes: Enveloppe[];
	used: boolean = false;
	type: TypeLienRoleUser;
	idMotClef: number;

	getKey(): number {
		return this.idLienRoleUser;
	}

	/**
	 * Convertit un objet front Delegation en objet back ConfigDelegue pour réutiliser les appels WS existants
	 */
	static toConfigDelegue(delegation: LienRoleUser): any {
		return {
			idOldDelegation: delegation.idLienRoleUser,
			idRole: delegation.role.idRole,
			idUserDelegue: delegation.user?.idUser,
			idUserDelegant: delegation.idUserDelegant,
			idPopulation: delegation.population?.idPopulation,
			dateDebut: delegation.dateDebut,
			dateFin: delegation.dateFin
		};
	}
}

/**
 * Type de lienRoleUser (ici la classe délégation)
 */
export enum TypeLienRoleUser {
	HABILITATION = 1,
	DELEGATION = 2
}

/**
 * Priorité d'un lienRoleUser
 */
export enum TypePriorite {
	TITULAIRE = 1,
	SUPPLEANT = 2
}
