import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Vehicule} from "@domain/vehicule/vehicule";
import {MatDialog} from "@angular/material/dialog";
import {VehiculeAddDocumentComponent} from "./vehicule-add-document.component";
import {SettingsVehiculeState} from "@domain/settings/settings";
import {VehiculeCarteGriseComponent} from "./carte-grise/vehicule-carte-grise.component";
import {VehiculeAssuranceComponent} from "./assurance/vehicule-assurance.component";
import {VehiculeAutorisationComponent} from "./autorisation/vehicule-autorisation.component";
import {TypeDocument} from "@domain/vehicule/typeDocument";
import * as moment from 'moment';
import {VehiculeService} from "../vehicule.service";
import {Assurance} from "@domain/vehicule/assurance";
import {DateUtilsService} from "@share/utils/date-utils/date-utils.service";

/**
 * Composant pour la gestion des documents véhicules
 */
@Component({
    host: {'data-test-id': 'vehicule-documents'},
    selector: 'vehicule-documents',
    templateUrl: './vehicule-documents.component.html'
})
export class VehiculeDocumentsComponent {

    /** Véhicule auquel sont rattachés les documents  */
    @Input()
    vehicule: Vehicule;

    /** Paramètrage des véhicules */
    @Input()
    settings: SettingsVehiculeState;

    /** Booléen indiquant si les modifications sont possibles */
    @Input()
    canModifier: boolean;

    /** Booléen indiquant si on peut gérer les documents */
    @Input()
    canGestionDocuments: boolean;

    /** Booléen indiquant si on est responsable véhicule */
    @Input()
    isResponsableVehicule: boolean;

    /** Événement généré à la suite de l'upload d'un document **/
    @Output() onDocumentUploaded: EventEmitter<any> = new EventEmitter<any>();

    /** Énumération pour le type de document */
    TypeDocument = TypeDocument;

    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog,private vehiculeService:VehiculeService) {
    }

    /**
     * Ouverture de la popup de saisie des documents
     * @param id du document
     * @param type TypeDocument
     */
    openDocument(id: number, type: TypeDocument) {
        let component;

        switch (type) {
            case TypeDocument.CARTE_GRISE:
                component = VehiculeCarteGriseComponent;
                break;
            case TypeDocument.ASSURANCE:
                component = VehiculeAssuranceComponent;
                break;
            case TypeDocument.AUTORISATION:
                component = VehiculeAutorisationComponent;
                break;
        }

        this.matDialog.open(component, {
            data: {
                vehicule: this.vehicule,
                settings: this.settings,
                idDocument: id ?? 0,
                canModifier: id == 0 ? true : this.canModifier
            }
        }).afterClosed().subscribe({
            next: () => {
                this.onDocumentUploaded.emit({});
            }
        });
    }

    /**
     * Ouverture de la popup de choix de type de document
     */
    openAddDocument() {
        this.matDialog.open(VehiculeAddDocumentComponent, {
            data: {
                vehicule: this.vehicule,
                settings: this.settings,
                isResponsableVehicule: this.isResponsableVehicule
            }
        }).afterClosed().subscribe({
            next: (type) => {
                //Ouvre la popup d'ajout de document en fonction du type
                if (type)
                    this.openDocument(0, type.type);
            }
        });
    }


    /**
     * Permet de tester si les dates de début/fin d'un document comprennent la date courante
     * @param obj Document
     */
    isDocumentActif(obj: any): boolean {
        return this.vehiculeService.isDocumentActif(obj);
    }

    /**
     * Permet de tester si la date d'immatriculation de la carte grise est avant la date courante
     * @param date Date immatriculation
     */
    isCarteGriseActive(date: Date): boolean {
        return moment().isAfter(date,'day');
    }

    /**
     * Vérification de l'affichage de l'alerte d'échéance
     * @param assurance Assurance
     */
    isAlerteEcheance(assurance: Assurance): boolean {
        //Récupération de l'assurance la plus récente
        const lastAssurance = this.getLastAssurance();

        return (!lastAssurance || assurance.idVehiculeAssurance == lastAssurance.idVehiculeAssurance)
            && this.settings.profilAlerteConfig?.alerteEcheanceActif
            && moment().add(this.settings.profilAlerteConfig.delaiAlerteEcheance, "d").isAfter(moment(assurance.dateFin),'day');
    }

    /**
     * Vérification de l'affichage de l'alerte de document échu
     * @param assurance Assurance
     */
    isAlerteEchu(assurance: Assurance): boolean {
        //Récupération de l'assurance la plus récente
        const lastAssurance = this.getLastAssurance();

        return (!lastAssurance || assurance.idVehiculeAssurance == lastAssurance.idVehiculeAssurance)
            && this.settings.profilAlerteConfig?.alerteEchuActif && moment().isAfter(moment(assurance.dateFin),'day');
    }

    /**
     * Retourne l'assurance la plus récente (date de fin la plus grande)
     * @private
     */
    private getLastAssurance(): Assurance {
        return this.vehicule.listeAssurance.reduce((previousValue,currentVealue) => !previousValue || DateUtilsService.compareDates(currentVealue.dateFin,previousValue.dateFin) > 0 ? currentVealue : previousValue,null)
    }

    /**
     * Détermine s'il y a des documents requis pour l'approbation et vérifie leur présence le cas échéant
     */
    isValidForApprobation(): boolean {
        return !this.vehicule.alertes.isMissingAssurance && !this.vehicule.alertes.isMissingCarteGrise;
    }

}