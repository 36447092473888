import {Injectable} from '@angular/core';
import {PrestTaux} from "@domain/prestation/prest-taux";
import {SaisieState} from "@domain/saisie/saisie";
import {FraisMission} from "@domain/od/engagements/indemnites/frais-mission";
import {FIND_TAUX_FOR_PRESTATION} from "@reducers/saisie";
import * as moment from "moment";
import {Prestation,TypePrestation} from "@domain/prestation/prestation";
import {AppState} from "@domain/appstate";
import {Store} from "@ngrx/store";
import {Pays} from "@domain/geographie/pays";
import {Ville} from "@domain/geographie/ville";
import {SettingsService} from "../settings/settings.service";
import {SettingsGlobalState} from "@domain/settings/settings";
import {Observable,of} from "rxjs";
import {AdresseService} from '@share/component/adresse/adresse.service';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Page} from "@domain/common/http/list-result";
import {MontantPipe} from "@share/pipe/montant";
import {map} from "rxjs/operators";

/**
 * Service de gestion des frais (dépenses NDF) et des frais prévisionnels (OD)
 */
@Injectable()
export class FraisService {
	/**
	 * Constructeur
	 */
	constructor(private montantPipe: MontantPipe,
				private store: Store<AppState>,
				private settingsService: SettingsService,
				private adresseService: AdresseService,
				private http: HttpClient) { }

	/**
	 * Force la mise à jour de la quantité sur le frais suivant la prestation sélectionnée
	 *
	 * @param prestation La prestation sélectionnée
	 * @param frais Le frais
	 */
	onPrestationChangeUpdateQuantite(prestation: Prestation,frais: {quantite?: number}) {
		//Vérification de la quantité
		if (prestation == null) {
			//Pas de presation on efface la quantité par défaut;
			delete frais.quantite;
		} else if (prestation.quantiteDefaut) {
			//Mise à jour de la quantité par défaut
			frais.quantite = prestation.quantiteDefaut;
		} else if (prestation.type == TypePrestation.FORFAIT && !prestation.quantiteModifiable) {
			//Mise à jour de la quantité
			frais.quantite = 1;
		}
	}

	/**
	 * Retourne le cours à partir de la devise sélectionnée.
	 *
	 * @param deviseSelection Devise sélectionnée
	 * @param date Date du frais
	 * @param deviseBase Devise de référence
	 * @param coursDeviseBase Cours de la devise de référence
	 */
	findCoursForDevise(deviseSelection:string,date: Date,deviseBase: string,coursDeviseBase?: number): Observable<number> {
		//Vérification de la devise
		if (deviseSelection != deviseBase) {
			//Recherche du cours de la devise
			return this.settingsService.findCoursForDevise({
				codeDevise: deviseSelection,
				date: moment(date).toDate(),
				coursDeviseBase: coursDeviseBase
			});
		} else {
			//Mise à jour du cours
			return of(1);
		}
	}

	/**
	 * Rafraichissement du taux de prestation
	 *
	 * @param prestation La prestation du frais
	 * @param date La date du frais
	 * @param pays Le pays du frais
	 * @param ville La ville du frais
	 * @param idNdf id de la ndf
	 */
	refreshTauxPrestation(prestation: Prestation, date: Date, pays: Pays, ville?: Ville, idNdf: number = null) {
		//Envoi du message
		this.store.dispatch({
			type: FIND_TAUX_FOR_PRESTATION,
			payload: {
				idPrestation: prestation?.idPrestation,
				idPays: pays?.idPays,
				idVille: ville?.idVille,
				date: moment(date).valueOf(),
				idNdf: idNdf
			}
		});
	}

	/**
	 * Récupération du taux de la prestation formaté (string) ou non
	 *
	 * @param saisieState Contient les indemnités relatives à la saisie en cours
	 * @param settings Paramètres de l'application
	 * @param devise Devise du frais
	 * @param cours Cours pour la devise du frais
	 * @param taux Taux d'indemnité du frais
	 */
	getTauxForPrestation(saisieState: SaisieState,settings: SettingsGlobalState,devise: string,cours: number,taux: FraisMission): number {
		let montant: number;
		let devisePrestation: string;
		let prestTaux: PrestTaux;
		let coursDevise = 1;

		//Récupération du taux
		prestTaux = this.findTaux(saisieState,taux?.idTaux);

		//Récupération du montant et de la devise du taux sélectionné
		montant = prestTaux?.montant || 0;

		//Vérification d'un montant non null
		if (montant > 0) {
			devisePrestation = prestTaux?.devise;

			//Vérification de la devise
			if (devisePrestation != devise) {
				//Devise de la prestation
				coursDevise = saisieState?.tauxPrestation?.coursParDevise[devisePrestation] || 1;

				//Cours inversé (ou pas...)
				if (!settings.isTauxInverse) {
					cours = 1/cours;
					coursDevise = 1/coursDevise;
				}

				//Appliquer le cours de la devise
				montant = montant * (cours || 1) / coursDevise;
			}
		}

		//Retour de la valeur brute (nombre) ou formatée
		return montant;
	}

	/**
	 * Récupération du cumul selon la devise
	 *
	 * @param saisieState état de la saisie du frais
	 * @param settings setting du frais
	 * @param devise devise du frais
	 * @param cours cours du frais
	 * @param taux taux du frais
	 * @param montant montant du frais
	 */
	getCumulRestant(saisieState: SaisieState, settings: SettingsGlobalState, devise: string, cours: number, taux: number, montant: number):string {
		let coursDevise = 1;
		let cumulPlafond = 0;

		let cumulRestant: number = saisieState?.tauxPrestation?.cumul ?? 0;

		//Vérification d'un montant non null
		if (cumulRestant > 0) {
			//Cours inversé (ou pas...)
			if (!settings.isTauxInverse) {
				cours = 1/cours;
				coursDevise = 1/coursDevise;
			}

			//Appliquer le cours de la devise
			cumulRestant = cumulRestant * (cours ?? 1);
			montant = montant * (cours ?? 1);

			
			//Si le taux est supérieur au montant saisi
			if (montant < taux) {
				//On affiche un cumul plafonné à partir du cumul avec le taux moins la différence entre le taux et le montant
				cumulPlafond = cumulRestant + montant ;
			} else {
				//On affiche simplement le cumul restant ajouté au taux
				cumulPlafond = cumulRestant + taux;
			}
		}	

		//Si le taux est inférieur au cumul on ne renvoie pas de valeur
		if (cumulPlafond <= taux ) {
			return "";
		}
		
		//Retour de la valeur formatée
		return this.montantPipe.transform(cumulPlafond,devise);
	}

	/**
	 * Recherche d'un taux par identifiant. Si aucun identifiant passé, retourne le taux simple
	 *
	 * @param saisieState Contient les indemnités relatives à la saisie en cours
	 * @param idTaux Identifiant du taux
	 */
	findTaux(saisieState: SaisieState,idTaux?: number): PrestTaux {
		if (idTaux) {
			//Recherche et renvoi du taux à partir de l'identifiant
			return saisieState?.tauxPrestation?.allTaux?.find(value => value.idTaux == idTaux);
		} else {
			//Renvoi du taux simple
			return saisieState?.tauxPrestation?.taux;
		}
	}

	/**
	 * Comparateur entre le taux sélectionnable et celui défini sur le frais prévisionnel
	 *
	 * @param taux1 Taux 1
	 * @param taux2 Taux 2
	 */
	compareTaux(taux1: FraisMission,taux2: FraisMission): boolean {
		return taux1?.idTaux == taux2?.idTaux;
	}

	/**
	 * Converti un objet de type PrestTaux (taux spécial) en objet FraisMission (indemnité)
	 *
	 * @param prestTaux Taux spécial sélectionné
	 */
	prestTauxToFraisMission(prestTaux?: PrestTaux): FraisMission {
		return prestTaux ? {
			idTaux: prestTaux.idTaux,
			libelleSpecial: prestTaux.libelleSpecial,
			dateApplication: prestTaux.dateApplication,
			montant: prestTaux.montant,
			devise: prestTaux.devise,
			periodeMin: prestTaux.periodeMin,
			periodeMax: prestTaux.periodeMax,
			fraisMissionRegion: {
				idTaux: prestTaux.idTaux,
				libelleTerritoire: prestTaux.libelleTerritoire
			}
		} : null as FraisMission;
	}

	/**
	 * Formatage de la période associée au taux
	 *
	 * @param taux Le taux
	 */
	getPeriode(taux: PrestTaux): string {
		let str: string = '';

		//Vérification de la présence d'une période minimale
		if (taux.periodeMin) {
			//Récupération de la période minimale
			str += taux.periodeMin;

			//Vérification de la présence d'une période maximale
			if (taux.periodeMax) {
				//Récupération de la période minimale et ajout au résultat
				str += ' - ' + taux.periodeMax;
			} else {
				//Pas de période maximale, ajout du caractère "+"
				str += ' +';
			}
		} else {
			//Pas de période minimale
			str += '-';
		}

		return str;
	}

	/**
	 * Retourne une valeur en tant que nombre (lors de la saisie on se retrouve avec une chaine...)
	 *
	 * @param value Valeur numérique représentée, soit par une chaine, soit par un nombre
	 */
	getValueAsNumber(value: string | number): number {
		if (typeof value == 'string') {
			return Number(value?.replace(/[^,\d.-]/g,'').replace(/[,]/g,'.'));
		} else {
			return value;
		}
	}

	/**
	 * Retourne la distance entre 2 points arrondie à 2 chiffres après la virgule
	 *
	 * @param depart Localisation de départ
	 * @param arrivee Localisation d'arrivée
	 * @param isAllerRetour Indicateur d'aller/retour
	 */
	findDistance(depart: any,arrivee: any,isAllerRetour: boolean): Observable<number> {
		//Vérification du départ et de l'arrivée
		if (depart?.latitude && depart?.longitude && arrivee?.latitude && arrivee?.longitude)
			//Retour de la distance
			return this.adresseService
				.findDistance({lat: depart.latitude,lng: depart.longitude},{lat: arrivee.latitude,lng: arrivee.longitude},isAllerRetour)
				.pipe(map(distance => Math.round(distance * 100) / 100));
		else
			//Aucun calcul possible
			return of(null);
	}

	/**
	 * Retourne la prestation
	 *
	 * @param idPays l'id du pays
	 * @param idTypeEntite l'id du type entite
	 * @param typeObjet le type objet, la portée
	 * @param idObjet l'id de l'objet
	 * @param idPrestation l'id de la prestation
	 */
	findPrestation(idPays: number, idTypeEntite: number, typeObjet, idObjet: number, idPrestation: number): Observable<Page<Prestation>> {
		return this.http.post<Page<Prestation>>(`${environment.baseUrl}/controller/Settings/filtrePrestations/${idPays || 0}/${idTypeEntite || 0}/${typeObjet || ''}/${idObjet || 0}`
			+ `?idPrestation=${idPrestation || '0'}`,{});
	}
}
