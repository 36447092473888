import {Component,OnInit} from "@angular/core";
import {MigrationItemComponent} from "@components/admin/maintenance/migrations/historique/migration-item.component";
import {Migration} from "@domain/admin/maintenance/migration";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Page} from "@domain/common/http/list-result";
import {TranslateService} from "@ngx-translate/core";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Onglet historique
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'historique'},
    templateUrl: './historique.component.html'
})
export class HistoriqueComponent implements OnInit {

    /** Liste des migrations, pour utiliser le composant ListViewComponent */
    liste: ListView<Migration, MigrationItemComponent>;

    /**
     * Constructeur
     */
    constructor(protected translateService: TranslateService) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Création de la liste des tâches
        this.liste = new ListView<Migration, MigrationItemComponent>({
            uri: `/controller/Maintenance/listeBDDUpgrade`,
            title: this.translateService.instant('admin.maintenance.migrations.onglets.historique.histoMigrations'),
            component: MigrationItemComponent,
            mapResult: (result: Page<Migration>): Page<Migration> => {
                //Transformation en instance de chaque item
                result.listeResultats = result.listeResultats.map(t => new Migration(t));

                //Retour
                return result;
            },
            isFilter: true,
            defaultOrder: '-date',
            listeFilters: [
                {
                    clef: 'origine',
                    title: this.translateService.instant('admin.maintenance.migrations.onglets.historique.versionOrigine'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'origineBuild',
                    title: this.translateService.instant('admin.maintenance.migrations.onglets.historique.buildOrigine'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'cible',
                    title: this.translateService.instant('admin.maintenance.migrations.onglets.historique.versionCible'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'cibleBuild',
                    title: this.translateService.instant('admin.maintenance.migrations.onglets.historique.buildCible'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'date',
                    title: this.translateService.instant('admin.maintenance.migrations.onglets.historique.date'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.DATE]
                }
            ]
        });

        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'origine', title: 'admin.maintenance.migrations.onglets.historique.versionOrigine' },
            { key: 'origineBuild', title: 'admin.maintenance.migrations.onglets.historique.buildOrigine' },
            { key: 'cible', title: 'admin.maintenance.migrations.onglets.historique.versionCible' },
            { key: 'cibleBuild', title: 'admin.maintenance.migrations.onglets.historique.buildCible' },
            { key: 'date', title: 'admin.maintenance.migrations.onglets.historique.date' }
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.liste.sorting = new Sorting(this.liste.columns, "-date");

        //Nom de la classe
        this.liste.className = 'HistoriqueComponent';

        //Liste avec persistence
        this.liste.isPersistFilters = true;
    }
}
