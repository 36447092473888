import {TravelHubSBTConfig} from "./travel-hub-sbt-config";

/**
 * Paramètre de connexion de la configuration SBT
 *
 * @author Laurent Convert
 * @date 20/11/2023
 */
export class ParamConnexion {
	idParamConnexion: number;
	sbtConfig: TravelHubSBTConfig;
	code: string;
	libelle: string;
	min: number;
	max: number;
	obligatoire: boolean;
	regex: string;
	typeParamConnexion: TypeParamConnexion;
	transmisPour: TransmisPour;
	notionMetier: NotionMetier;
	typeControle: TypeControle;
	dateUpdated: Date;
	actif: boolean;
	automatique: boolean;

	constructor(dto?: ParamConnexion) {
		if (dto) {
			Object.assign(this,dto);

			if (dto.sbtConfig) {
				this.sbtConfig = new TravelHubSBTConfig(dto.sbtConfig);
			}
		}
	}
}

/** Enumération des types de paramètres de connexion */
export enum TypeParamConnexion {
	STRING = "STRING",
	ENTIER = "ENTIER",
	DECIMAL = "DECIMAL",
	DATE = "DATE",
	BOOLEAN = "BOOLEAN",
	LISTE_VALEURS = "LISTE_VALEURS",
	METIER = "METIER",
}
/** Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré */
export namespace TypeParamConnexion {
	export function traduction(typeParamConnexion: TypeParamConnexion): string {
		return typeParamConnexion ? 'admin.voyages.travelhub.TypeParamConnexion.' + typeParamConnexion : null;
	}
}

/** Enumération des types de transmission */
export enum TransmisPour {
	GLOBAL = "GLOBAL",
	PROFIL = "PROFIL",
	TOUTE_PRESTA = "TOUTE_PRESTA",
	AVION = "AVION",
	TRAIN = "TRAIN",
	AVION_TRAIN = "AVION_TRAIN",
	HOTEL = "HOTEL",
	VOITURE_LOC = "VOITURE_LOC",
	DOCUMENT = "DOCUMENT",
}
/** Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré */
export namespace TransmisPour {
	/**
	 * Renvoie le libelle de traduction correspondant
	 */
	export function traduction(transmisPour: TransmisPour) : string {
		return transmisPour ? 'admin.voyages.travelhub.transmisPour.' + transmisPour : null;
	}
}

/** Enumération des notions métier */
export enum NotionMetier {
	ReasonCode = 'ReasonCode',
	ProfilFullfilment = 'ProfilFullfilment',
	Communaute = 'Communaute',
	Rls = 'Rls',
}

/** Enumération des types de contrôle */
export enum TypeControle {
	NON_CONTROLE = 'NON_CONTROLE',
	NUMERIQUE = 'NUMERIQUE',
	REGEX = 'REGEX',
}
/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypeControle {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(typeControle: TypeControle): string {
		switch (typeControle) {
			case TypeControle.NON_CONTROLE:
				return 'global.non';
			case TypeControle.NUMERIQUE:
			case TypeControle.REGEX:
				return 'global.oui';
			default:
				return null;
		}
	}
}

/** Liste des ReasonCode */
export enum TypeReasonCode {
	LIBELLE = "LIBELLE",
	CODE = "CODE",
	ATTRIBUT_1 = "ATTRIBUT_1",
	ATTRIBUT_2 = "ATTRIBUT_2",
	ATTRIBUT_3 = "ATTRIBUT_3",
	ATTRIBUT_4 = "ATTRIBUT_4",
}
/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypeReasonCode {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(typeReasonCode: TypeReasonCode): string {
		return typeReasonCode ? 'admin.voyages.travelhub.TypeRaisonCode.' + typeReasonCode : null;
	}
	/** Renvoie l'énumération sous forme de tableau */
	export function values(): Array<TypeReasonCode> {
		return [
			TypeReasonCode.LIBELLE,
			TypeReasonCode.CODE,
			TypeReasonCode.ATTRIBUT_1,
			TypeReasonCode.ATTRIBUT_2,
			TypeReasonCode.ATTRIBUT_3,
		];
	}
}

/** Énumération des ProfilFullfilment */
export enum TypeProfilFullfilment {
	NUMERO_SERVICE_USER = 'NUMERO_SERVICE_USER',
	CODE_SERVICE_USER = 'CODE_SERVICE_USER',
	NUMERO_SERVICE_MISSION = 'NUMERO_SERVICE_MISSION',
	CODE_SERVICE_MISSION = 'CODE_SERVICE_MISSION',
	AUTRE = 'AUTRE',
}
/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypeProfilFullfilment {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(typeProfilFullfilment: TypeProfilFullfilment): string {
		return typeProfilFullfilment ? 'admin.voyages.travelhub.TypeProfilFullfilment.' + typeProfilFullfilment : null;
	}
	/** Renvoie l'énumération sous forme de tableau */
	export function values(): Array<TypeProfilFullfilment> {
		return [
			TypeProfilFullfilment.NUMERO_SERVICE_USER,
			TypeProfilFullfilment.CODE_SERVICE_USER,
			TypeProfilFullfilment.NUMERO_SERVICE_MISSION,
			TypeProfilFullfilment.CODE_SERVICE_MISSION,
			TypeProfilFullfilment.AUTRE,
		];
	}
}

/** Énumération des types de saisie du RLV */
export enum TypeRLV {
	RLV = "RLV",
	AUTRE = "AUTRE",
}
/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypeRLV {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(typeRLV: TypeRLV): string {
		return typeRLV ? 'admin.voyages.travelhub.TypeRLV.' + typeRLV : null;
	}
	/** Renvoie l'énumération sous forme de tableau */
	export function values(): Array<TypeRLV> {
		return [TypeRLV.RLV,TypeRLV.AUTRE];
	}
}

/** Énumération des communautés */
export enum TypeCommunaute {
	COMMUNAUTE = "COMMUNAUTE",
	AUTRE = "AUTRE",
}
/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypeCommunaute {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(typeCommunaute: TypeCommunaute): string {
		return typeCommunaute ? 'admin.voyages.travelhub.TypeCommunaute.' + typeCommunaute : null;
	}
	/** Renvoie l'énumération sous forme de tableau */
	export function values(): Array<TypeCommunaute> {
		return [TypeCommunaute.COMMUNAUTE,TypeCommunaute.AUTRE];
	}
}

/** Énumération des types de saisie du RLS */
export enum TypeRLS {
	RLS = "RLS",
	AUTRE = "AUTRE",
}
/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypeRLS {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(typeRLS: TypeRLS): string {
		return typeRLS ? 'admin.voyages.travelhub.TypeRLS.' + typeRLS : null;
	}
	/** Renvoie l'énumération sous forme de tableau */
	export function values(): Array<TypeRLS> {
		return [TypeRLS.RLS,TypeRLS.AUTRE];
	}
}
