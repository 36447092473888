<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">account_tree</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'workflow.conditions.conditionsVisibilite.liste.portee'"></strong>{{ 'portee.' + data.idPortee | translate }}</li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="onDelete()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>