import {Component,Inject,OnInit} from "@angular/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypeReferentiel} from "@domain/admin/referentiels/type-referentiel";

/**
 * Composant d'affichage des icônes disponbiles pour les référentiels
 */
@Component({
    host: {'data-test-id': 'frais-referentiel-infos-icones'},
    templateUrl: './frais-referentiel-infos-icones.component.html'
})
export class FraisReferentielInfosIconesComponent implements OnInit {
    /** Liste des icônes disponibles pour le type de référentiel "Rubrique" */
    iconesR = ['', 'help', 'local_dining', 'local_parking', 'airport_shuttle', 'local_hotel', 'local_gas_station', 'shopping_cart', 'streetview', 'flight', 'local_cafe', 'local_car_wash', 'local_grocery_store', 'group', 'av_timer', 'restaurant', 'local_taxi'
        , 'local_phone', 'train', 'directions_car'];

    /** Liste des icônes disponibles pour les types de référentiel "Famille" et "Indemnité" */
    iconesFI = ['spoke',
        'help_outline',
        'restaurant_menu',
        'restaurant',
        'local_hotel',
        'local_parking',
        'airport_shuttle',
        'directions_car',
        'local_taxi',
        'local_car_wash',
        'local_airport',
        'train',
        'local_gas_station',
        'shopping_cart',
        'streetview',
        'local_cafe',
        'av_timer',
        'group',
        'phone'];

    /** Liste des icônes à afficher */
    listeIcones = new Array<String>();

    /**
     * Constructeur
     *
     * @param dialogRef Référence vers la boîte de dialogue du composant
     * @param data Données passées en paramètre : type du référentiel pour afficher les icônes correspondantes
     */
    constructor(public dialogRef: MatDialogRef<FraisReferentielInfosIconesComponent>,
                @Inject(MAT_DIALOG_DATA) public data:
                    { type: string }
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Si le type du référentiel est F ou I alors on affiche la liste des icônes FI
        if(this.data.type === TypeReferentiel.FAMILLE || this.data.type === TypeReferentiel.INDEMNITE) {
            this.listeIcones = this.iconesFI;
        } else {
            //Sinon on affiche la liste des icônes R
            this.listeIcones = this.iconesR;
        }
    }

    /**
     * Méthode appelée lors de la sélection d'une icône
     *
     * @param icone Icône sélectionnée
     */
    onSelect(icone){
        //Fermeture de la boîte de dialogue et envoie de l'icône sélectionnée au composant parent
        this.dialogRef.close(icone);
    }
}