<form #form="ngForm">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.description.rgpd.titre'">
            </span>
        </mat-card-title>
        <mat-card-content>
            <fieldset *ngIf="rgpdParams">
                <div class="row">
                    <custom-input libelle="admin.entreprise.description.rgpd.emailContact"
                                  customType="input"
                                  name="emailContactRgpd"
                                  [(ngModel)]="rgpdParams.emailContactRgpd"
                                  [(customModel)]="rgpdParams.emailContactRgpd"
                                  [postTooltip]="'admin.entreprise.description.rgpd.infoEmailsContact' | translate"
                                  ngDefaultControl></custom-input>

                    <custom-input ngDefaultControl name="recueilCgu" [ngModel]="rgpdParams.recueilCgu" [(customModel)]="rgpdParams.recueilCgu"
                                  customType="checkbox"
                                  libelle="admin.entreprise.description.rgpd.affichageCGU" postLibelle="admin.entreprise.description.rgpd.recueilConsentement"></custom-input>
                </div>

                <div class="row">
                    <custom-input ngDefaultControl name="notifContactRgpd" [ngModel]="rgpdParams.notifContactRgpd" [(customModel)]="rgpdParams.notifContactRgpd"
                                  customType="checkbox"
                                  libelle="admin.entreprise.description.rgpd.contactsRGPD" postLibelle="admin.entreprise.description.rgpd.toujoursAjoutCopie"></custom-input>

                    <custom-input ngDefaultControl name="notifApprobationCgu" [ngModel]="rgpdParams.notifApprobationCgu" [(customModel)]="rgpdParams.notifApprobationCgu"
                                  customType="checkbox"
                                  libelle="admin.entreprise.description.rgpd.approbationCGU" postLibelle="admin.entreprise.description.rgpd.notifierUtilisateurCGU"></custom-input>
                </div>

                <div class="row mt-3">
                    <custom-input ngDefaultControl name="notifSuspensionRgpd" [ngModel]="rgpdParams.notifSuspensionRgpd" [(customModel)]="rgpdParams.notifSuspensionRgpd"
                                  customType="checkbox"
                                  libelle="admin.entreprise.description.rgpd.suspensionProfils" postLibelle="admin.entreprise.description.rgpd.notifierUtilisateur"></custom-input>

                    <custom-input ngDefaultControl name="notifReactivationRgpd" [ngModel]="rgpdParams.notifReactivationRgpd" [(customModel)]="rgpdParams.notifReactivationRgpd"
                                  customType="checkbox"
                                  libelle="admin.entreprise.description.rgpd.reactivationProfils" postLibelle="admin.entreprise.description.rgpd.notifierUtilisateurProfils"></custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</form>
