import * as moment from "moment";

/**
 * Représente l'historique des calculs d'IJ pour un OD.
 */
export class IJChange {
    /** Données */
    idIJChange: number;
    ancienMontantEntrep: number;
    nouveauMontantEntrep: number;
    dateMaj: Date;

    /**
     * Constructeur
     *
     * @param ijChange Données
     */
    constructor(ijChange: IJChange) {
        Object.assign(this,ijChange);
    }

    /**
     * Retourne la date de mise à jour formatée pour affichage
     *
     * @param userLocale Locale de l'utilisateur
     */
    printDate(userLocale: string): string {
        let dateMoment: moment.Moment;
        let dateStr: string;

        //Vérification de la présence de la date de mise à jour (il n'y en a aucune pour le premier changement)
        if (this.dateMaj) {
            //Vérification du passage de la locale
            if (userLocale) {
                //Construction d'un objet moment avec la locale de l'utilisateur
                dateMoment = moment(this.dateMaj).locale(userLocale);
            } else {
                //Construction d'un objet moment dans la locale par défaut
                dateMoment = moment(this.dateMaj);
            }

            //Formatage avec la 1ère lettre en majuscule
            dateStr = (dateStr = dateMoment.format('dddd D MMMM')).charAt(0).toUpperCase() + dateStr.slice(1);
        }

        return dateStr;
    }

}