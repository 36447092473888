import {Injectable} from "@angular/core";
import {environment} from '@environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";
import {Entreprise} from "@domain/admin/entreprise/entreprise";
import {Result} from "@domain/common/http/result";

/**
 * Service de gestion de l'entreprise
 */
@Injectable()
export class EntrepriseService {
    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) {};

    /**
     * Récupération de l'entreprise
     */
    getEntreprise(): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Entreprise/getEntreprise`).pipe(first())
    }

    /**
     * Mise à jour de l'entreprise dans la table ns_entrep de la base de données
     *
     * @param entreprise Objet Entreprise avec les informations de l'entreprise à mettre à jour
     */
    saveEntreprise(entreprise: Entreprise): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Entreprise/saveEntreprise`,entreprise)
    }
}