<h1 mat-dialog-title>
    <span translate>chainage.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <mat-tab-group #matTabGroup dynamicHeight animationDuration="0ms" [(selectedIndex)]="tabIndex" *ngIf="workflowHistorique">
        <mat-tab label="{{ 'chainage.actions.title' | translate }}">
            <div class="listview">
                <div class="listview-body">
                    <div class="listview-item mt-1">
                        <div class="mx-5">
                            <ng-container *ngFor="let action of workflowHistorique?.listeActions; index as i">
                                <chainage-action-wf [action]="action" [index]="i"></chainage-action-wf>
                                <div class="empty" *ngIf="!workflowHistorique?.listeActions?.length"><span translate="liste.noData"></span></div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'chainage.acteurs.title' | translate }}">
            <div class="listview">
                <div class="listview-body">
                    <div class="listview-item" *ngFor="let acteur of workflowHistorique?.listeActeurs">
                        <div class="lvi-content">
                            <div class="avatar">
                                <span [ngSwitch]="acteur.idProfil">
                                    <i *ngSwitchCase="TypeProfil.COMPTABLE" class="nio icon-comptable icone"></i>
                                    <i *ngSwitchCase="TypeProfil.RESPONSABLE" class="nio icon-responsable icone"></i>
                                    <i *ngSwitchCase="TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs icone"></i>
                                    <i *ngSwitchCase="TypeProfil.ASSISTANT" class="nio icon-assistant icone"></i>
                                    <i *ngSwitchCase="TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur icone"></i>
                                    <i *ngSwitchCase="TypeProfil.FOURNISSEUR" class="nio icon-fournisseur icone"></i>
                                </span>
                            </div>
                            <div class="lvi-body">
                                <div class="chainage-title">
                                    <span>{{ acteur | user }}</span>
                                </div>
                                <ul class="lvi-attrs">
                                    <li><strong [translate]="'chainage.actions.role'"></strong><span *ngIf="acteur.libelleRole">{{ acteur.libelleRole }}</span>
                                        <span *ngIf="!acteur.libelleRole" [translate]="'liste.nonRenseigne'"></span></li>
                                    <li><strong [translate]="'chainage.acteurs.mail'"></strong><a [href]="'mailto:' + acteur.email" target="_top" *ngIf="acteur.email">{{ acteur.email }}</a>
                                        <span *ngIf="!acteur.email" [translate]="'liste.nonRenseigne'"></span></li>

                                    <ng-container *ngIf="acteur.actions.length === 1">
                                        <li><strong [translate]="'chainage.acteurs.action'"></strong>
                                            <span>{{ afficherActions(acteur.actions) }}</span>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="acteur.actions.length > 1">
                                        <li [matTooltip]="afficherActions(acteur.actions)" matTooltipPosition="right"><strong [translate]="'chainage.acteurs.actions'"></strong>
                                            <span>{{acteur.actions.length}}</span></li>
                                    </ng-container>
                                    <ng-container *ngIf="!acteur.actions"><span [translate]="'liste.nonRenseigne'"></span></ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="empty" *ngIf="!workflowHistorique?.listeActeurs?.length"><span [translate]="'liste.noData'"></span></div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <please-wait *ngIf="!workflowHistorique"></please-wait>
</div>
