import {Alerte,NiveauAlerte} from "./alerte";

/**
 * Liste des alertes d'un objet
 */
export class ListeAlertes {
    niveau: NiveauAlerte;
    listeAlertes: Array<Alerte>;

    constructor(listeAlertes?: ListeAlertes) {
        //Initialisation
        this.niveau = listeAlertes?.niveau;
        this.listeAlertes = new Array<Alerte>();

        //Vérification des alertes
        if (listeAlertes?.listeAlertes && Array.isArray(listeAlertes?.listeAlertes)) {
            //Ajout des alertes
            this.listeAlertes.push(...listeAlertes.listeAlertes.map(al => new Alerte(al)));
        }
    }

    /**
     * Ajout d'une ou plusieurs alertes à la liste. Le niveau max est recalculé automatiquement.
     * @param alertes Alerte(s) à ajouter
     * @param ignoreDoublons Si False, les alertes de même titre seront supprimées avant ajout. Si True, les doublons sont conservés. False Par défaut.
     */
    add(alertes: Alerte | Array<Alerte>,ignoreDoublons: boolean = false): void {
        //Initialisation de la liste des alertes si besoin
        this.listeAlertes = this.listeAlertes ?? new Array<Alerte>();

        //Si le paramètre n'est pas un tableau d'alerte
        if (!Array.isArray(alertes)) {
            //Transformation en tableau pour pouvoir mutualiser le traitement
            alertes = [alertes];
        }
        
        //Parcours des alertes
        alertes.forEach(alerte => {
            //Suppression pour éviter les éventuels doublons sans re-calcul du niveau
            if (!ignoreDoublons) {
                this._remove(alerte);
            }

            //Ajout de l'alerte à la liste
            this.listeAlertes.push(alerte);
        });

        //Calcul du niveau max
        this.computeNiveau();
    }

    /**
     * Supprime une ou plusieurs alertes de la liste à partir du titre
     * @param alertes Alerte(s) à supprimer
     */
    remove(alertes: Alerte | Array<Alerte>): void {
        //Si le paramètre n'est pas un tableau d'alerte
        if (!Array.isArray(alertes)) {
            //Transformation en tableau pour pouvoir mutualiser le traitement
            alertes = [alertes];
        }
    
        //Parcours des alertes
        alertes.forEach(alerte => {
            this._remove(alerte);
        });

        //Calcul du niveau max
        this.computeNiveau();
    }

    /**
     * Supprime une alerte de la liste à partir de son titre
     * @param alerte Alerte à ajouter
     */
    private _remove(alerte: Alerte): void {
        //Vérification que la liste n'est pas nulle ou vide
        if (this.listeAlertes && this.listeAlertes.length >= 0) {
            //Récupération de l'index correspondant à l'alerte
            let index = this.listeAlertes.findIndex(al => alerte.getKey() != null ? al.getKey() == alerte.getKey() : al.message == alerte.message);

            //Si un index a été trouvé
            if (index >= 0) {
                //Suppression de l'élément correspondant
                this.listeAlertes.splice(index,1);
            }
        }
    }

    /**
     * Détermine le niveau max en fonction de la liste des alertes
     */
    computeNiveau(): void {
        //Calcul du niveau max
        this.niveau = this.listeAlertes.length === 0 ? undefined
            : this.listeAlertes.some(al => al.niveau == NiveauAlerte.ERROR) ? NiveauAlerte.ERROR
            : this.listeAlertes.some(al => al.niveau == NiveauAlerte.WARNING) ? NiveauAlerte.WARNING
            : NiveauAlerte.NO_CONTROL;
    }
}
