import {Component,Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LienPopulationUser,TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";
import {User} from "@domain/user/user";

/**
 * Composant d'affichage du formulaire d'ajout de collaborateur à une population
 */
@Component({
    host: {'data-test-id': 'population-add-collaborateur'},
    templateUrl: './population-add-collaborateur.component.html'
})
export class PopulationAddCollaborateurComponent {

    /** Utilisateur */
    user: User;

    /** Indique si on doit exclure l'user */
    isExcludeUser: boolean = false;

    /** Flag permettant de signaler la sauvegarde */
    isSaving: boolean = false;

    /**
     * Constructeur de la classe.
     *
     * @param matDialogRef La référence du dialogue modal.
     * @param data Les données transmises au dialogue modal.
     */
    constructor(
        private matDialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) private data: { idPopulation: number }
    ) { }

    /**
     * Ajout de l'utilisateur sélectionné à la population
     */
    addUser() {
        //Si on a un user
        if (this.user) {
            //On crée un lien population user
            const lienPopulationUser: LienPopulationUser = new LienPopulationUser();
            lienPopulationUser.idPopulation = this.data.idPopulation;
            lienPopulationUser.idUser = this.user.idUser;
            lienPopulationUser.typeLien = this.isExcludeUser ? TypeLien.EXCLUS : TypeLien.INCLUS;

            //Fermeture de la popup
            this.matDialogRef.close(lienPopulationUser);
        }
    }
}