<h1 mat-dialog-title>
    <span translate="admin.parametres.demat.types.ajout.title"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="content">
    <mat-dialog-content>
        <form autocomplete="off" #form="ngForm">
            <div class="row">
                <custom-input
                        [(ngModel)]="typeItem.libelle"
                        customType="input"
                        libelle="admin.parametres.demat.types.ajout.libelle"
                        name="libelle"
                        ngDefaultControl
                        required="true"
                ></custom-input>
                <custom-input
                        ngDefaultControl
                        customType="checkbox"
                        name="actif"
                        [ngModel]="typeItem.actif"
                        [(customModel)]="typeItem.actif"
                        libelle="admin.parametres.demat.types.ajout.actif"
                ></custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        customType="multiselect"
                        [(ngModel)]="listeRestrictions"
                        name="restriction"
                        libelle="admin.parametres.demat.types.ajout.restriction"
                        optionValue="id" optionDisplay="libelle"
                        [isSelectAvecInput]="false"
                        [selectOptions]="restrictionSelectOptions"
                ></custom-input>
                <custom-input
                        ngDefaultControl
                        customType="checkbox"
                        name="ocr"
                        [ngModel]="typeItem.ocr"
                        [(customModel)]="typeItem.ocr"
                        libelle="admin.parametres.demat.types.ajout.ocr"
                        postLibelle="admin.parametres.demat.types.ajout.ocrPostLibelle"
                        [readonly]="true"
                ></custom-input>
            </div>
            <div class="d-flex flex-row-reverse">
                <button mat-flat-button color="primary" class="ml-2" (click)="onSave()" [disabled]="form.invalid || isSaving">
                    <span translate>global.actions.enregistrer</span>
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>