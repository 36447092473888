import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OdDegrevementsMasseComponent } from "@components/od/detail/engagements/indemnites/degrevements/masse/od-degrevements-masse.component";
import { ODService } from "@components/od/od.service";
import { AppState } from "@domain/appstate";
import { ListView } from "@domain/common/list-view";
import { FraisMission } from "@domain/od/engagements/indemnites/frais-mission";
import { IJDetail } from "@domain/od/engagements/indemnites/ij-detail";
import { IjPageItem } from "@domain/od/engagements/indemnites/ij-page-item";
import { IjWeekday } from "@domain/od/engagements/indemnites/ij-weekday";
import { Regle } from "@domain/od/engagements/indemnites/regle";
import { RegleAttribution } from "@domain/od/engagements/indemnites/regle-attribution";
import { Od } from "@domain/od/od";
import { SettingsODState } from "@domain/settings/settings";
import { User } from "@domain/user/user";
import { TypePortee } from "@domain/workflow/workflow";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmService } from "@share/component/confirmation/confirm.service";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { OdEngagementsIndemnitesDegrevementsItemComponent } from "./od-engagements-indemnites-degrevements-item.component";
import {DatePipe} from "@angular/common";

/**
 * Composant d'affichage de la popin des indemnités / dégrèvements
 *
 * @author Laurent Convert
 * @date 05/11/2021
 */
@Component({
    templateUrl: './od-engagements-indemnites-degrevements.component.html'
})
export class OdEngagementsIndemnitesDegrevementsComponent implements OnInit {
    /** Ordre de mission */
    od: Od = null;

    /** Paramétrage de l'OD */
    settings: SettingsODState;

    /** Utilisateur connecté */
    user: User;

    /** Liste des détails des indemnités */
    liste: ListView<IjPageItem, OdEngagementsIndemnitesDegrevementsItemComponent> = null;

    /** Liste des détails d'indemnités */
    searchResult: any;

    /** Devise entreprise */
    deviseEntreprise: string;

    /** Le calendrier : objet principal utilisé pour construire l'affichage */
    calendrier: Array<Array<IjWeekday>>;

    /** Liste des détails d'indemnités */
    listeDetails: Array<IJDetail> = new Array<IJDetail>();

    /** Liste des détails d'indemnités qui ont été modifiés */
    listeDetailsModifies: Map<number, IJDetail> = new Map<number, IJDetail>();

    /** Liste des règles d'attribution */
    listeRegles: Array<RegleAttribution>;

    /** Table des jours feriés */
    listeFeries: Array<Date>;

    /** Coefficient modificateur du collaborateur */
    coefficientModificateurCollaborateur: number = 100.0;

    /** Coefficient modificateur de la mission. 100% par défaut, peut être modifié par un Responsable */
    coefficientModificateurOd: number = 100.0;

    /** Indicateur du chargement en cours */
    isLoading: boolean = false;

    /** Indicateur de modification possible */
    canModifier: boolean = false;

    /** Indicateur de complétion possible */
    canCompleter: boolean = false;

    /** Indicateur d'enregistrement */
    isSaving: boolean = false;

    /** Date minimale des dégrèvements */
    dateMin: moment.Moment;

    /** Date maximale des dégrèvements */
    dateMax: moment.Moment;

    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { od: Od, coefficientModificateurCollaborateur: number, coefficientModificateurOd: number, canModifier: boolean, canCompleter: boolean },
                private matDialogRef: MatDialogRef<any>,
                private odService: ODService,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private store: Store<AppState>,
                private datePipe: DatePipe,
                private matDialog: MatDialog,
                private confirmService: ConfirmService) {

        // Récupération des données transmises à l'ouverture de la popin
        this.od = data.od;
        this.coefficientModificateurCollaborateur = data.coefficientModificateurCollaborateur;
        this.coefficientModificateurOd = data.coefficientModificateurOd;
        this.canModifier = data.canModifier;
        this.canCompleter = data.canCompleter;
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Début du chargement
        this.isLoading = true;

        //Sélection du paramétrage
        this.store.select(state => state.settings?.[TypePortee.OD]).subscribe(settings => this.settings = settings);

        //Sélection de l'utilisateur connecté
        this.store.select(state => state.session?.user).subscribe(user => this.user = user);

        //Chargement des détails des indemnités
        this.odService.getDetailsIndemnites(this.od?.idOd).subscribe((result) => {
            //Récupération des valeurs
            this.listeFeries = result.listeFeries;
            this.listeRegles = result.listeRegles.map((regle) => {
                return new RegleAttribution(regle)
            });
            this.dateMin = moment(result.dateMin);
            this.dateMax = moment(result.dateMax);

            //Récupération de la devise depuis les paramètres
            this.deviseEntreprise = this.settings.deviseEntreprise;

            //Définition de la liste
            this.liste = new ListView<IjPageItem, OdEngagementsIndemnitesDegrevementsItemComponent>({
                uri: `/controller/OD/${this.od?.idOd}/filtreDetailsIndemnites`,
                component: OdEngagementsIndemnitesDegrevementsItemComponent,
                isFilter: false,
                mapResult: (result: any) => {
                    //reset
                    this.calendrier = new Array<Array<IjWeekday>>();

                    //Récupération des données
                    this.listeDetails = result.listeResultats.flatMap((resultat) => {
                        return resultat.listeIjDetails;
                    });

                    //Génération du calendrier à partir des données récupérées
                    this.genererCalendrier();

                    //Aplatissement des résultats pour n'avoir qu'un seul tableau contenant tous les détails (initialement par jour)
                    result.listeResultats = this.calendrier.flatMap((week) => {
                        return week;
                    });
                },
                nbObjetsParPage: 14,
                extraOptions: {
                    canModifier: this.canModifier,
                    canCompleter: this.canCompleter,
                    deviseEntreprise: this.deviseEntreprise,
                    coefficientModificateurCollaborateur: this.coefficientModificateurCollaborateur,
                    coefficientModificateurOd: this.coefficientModificateurOd,
                    parentComponent: this
                }
            });

            //Fin du chargement
            this.isLoading = false;
        });
    }

    /**
     * Fabrication du json architecturé en semaine et en jour pour l'affichage dans le tableau.
     * Le calendrier contient des semaines, qui contiennent les 7 jours.
     * Un jour contient un libelle de date à afficher, le numéro du jour de la semaine,
     * et la liste des règles d'attributions (pour l'affichage en colonne) dans lesquelles sont stockés les détails d'ij
     */
    genererCalendrier(): void {
        let currentDay: IjWeekday;

        //Initialisation du calendrier
        this.calendrier = [];
        let week: Array<IjWeekday> = null;
        let dayOfWeekTemp: number = 7;

        //Mise en ordre de la liste des règles
        this.listeRegles.sort(function (a, b) {
            if (a.heureDeb < b.heureDeb || (a.heureDeb == b.heureDeb && a.heureFin < b.heureFin)) {
                //a est antérieur à b
                return -1;
            } else if (a.heureDeb == b.heureDeb && a.heureFin == b.heureFin) {
                //Heure de début et de fin identiques
                return 0;
            } else {
                //Autres cas, a est postérieur à b
                return 1;
            }
        });

        //Parcours de la liste des détails d'ij
        this.listeDetails.forEach(detail => {
            //Récupération du jour de la semaine (numéro de 0 (lundi) à 6 (dimanche))
            detail.dayOfWeek = moment(detail.date).isoWeekday() - 1;

            //Vérification d'une nouvelle semaine à créer et à ajouter au calendrier
            if (detail.dayOfWeek < dayOfWeekTemp) {
                //Copier et ajout d'une semaine vierge
                week = this.getNewEmptyWeek();
                this.calendrier.push(week);

                //Parcours des jours de la semaine
                week.forEach(day => {
                    //Initialisation des détails d'ij par règle (pour former les colonnes)
                    day.regles = new Array<Regle>();

                    //Parcours de la liste des règles
                    this.listeRegles.forEach((regle) => {
                        //Création d'une structure de donnée pour cette règle (colonne)
                        day.regles.push(new Regle(regle));
                    });
                });
            }

            //Mémorisation du numéro du jour de la semaine courant
            dayOfWeekTemp = detail.dayOfWeek;

            //Stockage du detail dans la semaine au jour correspondant
            currentDay = week[dayOfWeekTemp];

            //Ajout des détails pour ce jour dans la regle (colonne) correspondante
            currentDay.regles.forEach(regle => {
                //Vérification de la règle
                if (regle.idRegle == detail.ij.regleAttribution.id) {
                    //Ajout des détails à la colonne
                    regle.details.push(detail);
                    regle.quantite += detail.quantite;

                    //S'il y a un frais spécial pour ce détail, on récupère le montant spécial
                    if (detail.fraisSpecial != null) {
                        regle.montant += detail.fraisSpecial.montant;
                    } else {
                        regle.montant += detail.montantAbattementCoeff;
                    }

                    //Ajout de la liste des différents frais applicables pour la cellule
                    detail.listeFrais.forEach(frais => {
                        let hashIdRegion: string = this.getHashTerritoireKeyFromFrais(frais);
                        hashIdRegion += frais.libelleSpecial != null ? frais.libelleSpecial : '';

                        //Ajout que si aucun doublon
                        if (regle.idsFraisRegion.indexOf(hashIdRegion) < 0) {
                            regle.frais.push(frais);
                            regle.idsFraisRegion.push(hashIdRegion);
                        }
                    });
                }
            });

            //Construction de la date formatée pour ce jour, dans la locale de l'utilisateur
            currentDay.date = moment(detail.date).format('DD');
            currentDay.dateString = this.datePipe.transform(detail.date, 'fullDate');
        });

        //Parcours du calendrier
        for (let w of this.calendrier) {
            //Parcours de chaque semaine du calendrier
            for (let currentDay of w) {
                //Parcours de chaque règle pour chaque jour du calendrier
                for (let regle of currentDay.regles) {
                    let listeFrais: Array<FraisMission> = regle.frais;
                    regle.frais = [];

                    //Parcours de chaque frais
                    for (let frais of listeFrais) {
                        //S'il s'agit du frais standard on ajoute le frais au début de la liste
                        if (frais.libelleSpecial == null || frais.libelleSpecial == "") {
                            //le frais standard est le 1er dans la liste car elle a déjà été triée côté back
                            if (regle.fraisStandard == null) {
                                regle.fraisStandard = frais;
                            }
                        } else {
                            regle.frais.push(frais);
                        }
                    }
                    //Ajout du frais standard en début de liste
                    if (regle.fraisStandard != null) {
                        regle.frais.unshift(regle.fraisStandard);
                    }

                    //Initialisation du frais sélectionné par défaut pour cette règle
                    //Si aucun detail ne pointe sur un frais spécial, on met le frais standard
                    let oneSpecialAtLeast: boolean = false;
                    let idFraisIndemnSpecial: number = null;

                    //Parcours des détails
                    for (let detail of regle.details) {
                        //Si le détail a un fraisMissionIndemn spécial, on place le flag à true et on mémorise l'id du frais
                        // /!\ le fraisSpecial du detail n'est pas un FraisMission mais un FraisMissionIndemnite
                        if (detail.fraisSpecial != null) {
                            oneSpecialAtLeast = true;
                            idFraisIndemnSpecial = detail.fraisSpecial.fraisMission.idTaux;
                        }
                    }

                    //S'il y a eu au moins un frais special
                    if (oneSpecialAtLeast) {
                        //Recherche du FraisMission dans la liste à partir de l'id trouvé dans le FraisMissionIndemnite
                        for (let frais of regle.frais) {
                            //Si l'id correspond, on selectionne ce FraisMission
                            if (frais.idTaux == idFraisIndemnSpecial) {
                                regle.selectedFrais = frais;
                            }
                        }
                    } else {
                        //Si aucun frais special n'est sélectionné, on sélectionne le standard
                        if (regle.fraisStandard != null) {
                            regle.selectedFrais = regle.fraisStandard;
                        } else {
                            //S'il n'y a pas de standard, on prend le premier
                            regle.selectedFrais = regle.frais[0];
                        }
                    }
                }
            }

            this.completeDayStringsOfWeek(w);
        }
    }

    /**
     * Fonction indiquant si la date (au format moment) est un jour férié
     *
     * @param date Date à vérifier
     */
    isJourFerie(date: moment.Moment): boolean {
        let isFerie: boolean = false;

        //Parcours de la liste des jours fériés
        for (let jour of this.listeFeries) {
            //Si la date en examen est égal à ce jour férié
            if (moment(jour).isSame(date, "date")) {
                isFerie = true;
                break;
            }
        }

        return isFerie;
    }

    /**
     * Fonction pour compléter les dates au format string à afficher
     * manquantes dans la liste du fait de l'absence d'ij pour ce jour-là
     *
     * @param week Semaine à afficher
     */
    completeDayStringsOfWeek(week: Array<IjWeekday>): void {
        //Date issue de la librairie moment
        let momentDate: moment.Moment = null;

        //Recherche d'un jour contenant une date de référence
        for (let day of week) {
            //Si le jour contient une date
            if (day.dateString != null) {
                //Détail
                let detail: IJDetail;

                //On cherche un détail pour ce jour dans la liste des règles d'attributions
                for (let r of day.regles) {
                    //Si on trouve un détail, on le prend
                    if (r.details[0] != null) {
                        detail = r.details[0];
                        break;
                    }
                }

                //On fait démarrer la date au lundi à partir de la date du détail trouvée
                momentDate = moment(detail.date).subtract(day.dayOfWeek, 'days');
                break;
            }
        }

        //Vérification de l'objet moment
        if (momentDate) {
            //Parcours de la semaine pour remplir les jours vides
            for (let day of week) {
                //Jour férié
                if (this.isJourFerie(momentDate)) {
                    day.ferie = true;
                }

                //Si aucune string de date n'a été enregistrée
                if (day.dateString == null) {
                    //Génération de la string de la date
                    day.date = momentDate.format('DD');
                    day.dateString = this.datePipe.transform(momentDate.toDate(), 'fullDate');
                }

                //Incrémentation du jour de 1
                momentDate.add(1, 'days');
            }
        }
    }

    /**
     * Changement d'état d'un checkbox pour dégrèvement
     *
     * @param regle Règle contenant tous les détails IJ
     */
    changeDegrevement(regle: Regle): void {
        //Égalisation des dégrèvements pour tous les détails de la case
        for (let detail of regle.details) {
            detail.used = regle.details[0].used;

            //On ajoute les détails modifiés à la liste des détails modifiés
            this.listeDetailsModifies.set(detail.idDetail, detail);
        }
    }

    /**
     * Sélection d'un frais applicable dans la liste déroulante
     *
     * @param regle Règle contenant tous les détails IJ
     * @param frais Le frais choisi
     */
    changeFrais(regle: Regle, frais: FraisMission): void {
        regle.montant = 0;

        //Insertion du frais sélectionné pour chacun des details d'ij pour lesquels le domaine de prestation match
        for (let detail of regle.details) {
            //Identifiant du domaine de prestation du détail d'ij concerné
            var idDom = detail.ij.prestation.idDomainePrestation;

            //On choisit par défaut le frais standard (donc null) s'il n'y a pas de frais spécial trouvé pour ce domaine de prestation
            detail.fraisSpecial = null;

            //On vérifie que le détail d'ij possède bien le frais spécial qu'on veut lui attribuer
            var fraisTrouve = false;
            for (let f of detail.listeFrais) {
                if (f.idTaux == frais.idTaux) {
                    fraisTrouve = true;
                    break;
                }
            }

            if (fraisTrouve) {
                //Parcours des FraisMissionIndemnite contenus pour le frais choisi
                for (let fraisIndemn of frais.listeFraisMissionIndemnite) {
                    //Si l'id du domaine de prestation correspond
                    if (fraisIndemn.idDomaine == idDom) {
                        //Si c'est un frais spécial
                        if (frais.libelleSpecial) {
                            //On attribue le frais spécial correspondant
                            detail.fraisSpecial = fraisIndemn;
                        } else {
                            //Sinon, on met à jour le montant de l'indemnité
                            detail.montantAbattementCoeff = fraisIndemn.montant * this.coefficientModificateurOd / 100;
                        }
                        break;
                    }
                }
            }

            //Rafraichissement du montant total pour la case
            if (detail.fraisSpecial) {
                regle.montant += detail.fraisSpecial.montant;
            } else {
                regle.montant += detail.montantAbattementCoeff;
            }

            //On ajoute les détails modifiés à la liste des détails modifiés
            this.listeDetailsModifies.set(detail.idDetail, detail);
        }
    }

    /**
     * Enregistre les dégrèvements
     *
     * @param refreshAfter Indique si on souhaite que l'OD soit refresh après l'enregistrement
     */
    saveDegrevement(refreshAfter: boolean = true): Observable<boolean> {
        //Début de l'enregistrement
        this.isSaving = true;

        let sujet: Subject<boolean> = new Subject<boolean>();

        //Enregistrement
        this.odService.saveDetailsIndemnites(this.od.idOd, Array.from(this.listeDetailsModifies.values()))
            .pipe(finalize(() => this.isSaving = false))
            .subscribe({
                next: (result) => {
                    //Vérification du code d'erreur
                    if (result.codeErreur == 0) {
                        //Message de réussite
                        this.toastrService.success(this.translateService.instant('od.engagements.indemnites.degrevements.message.enregistrementOk'));

                        //Si on veut un refresh après l'enregistrement
                        if (refreshAfter) {
                            //Fermeture de la popin
                            this.matDialogRef.close(true);
                        }
                    } else {
                        //Message d'échec
                        this.toastrService.error(this.translateService.instant('od.engagements.indemnites.degrevements.message.enregistrementKo'));
                    }

                    sujet.next(result.codeErreur === 0);
                },
                error: () => {
                    //Message d'échec
                    this.toastrService.error(this.translateService.instant('od.engagements.indemnites.degrevements.message.enregistrementKo'));
                }
            });

        return sujet.asObservable();
    }

    /**
     * Retourne une string identifiant le territoire, incluant la zone, la region, le pays et la ville
     * qui permet une comparaison plus rapide
     *
     * @param frais Le frais à hacher-menu
     */
    getHashTerritoireKeyFromFrais(frais: FraisMission): string {
        let hashIdRegion: string = '';
        hashIdRegion += frais.fraisMissionRegion.idZone != null ? frais.fraisMissionRegion.idZone.toString() : '00';
        hashIdRegion += frais.fraisMissionRegion.idRegion != null ? frais.fraisMissionRegion.idRegion.toString() : '00';
        hashIdRegion += frais.fraisMissionRegion.idPays != null ? frais.fraisMissionRegion.idPays.toString() : '00';
        hashIdRegion += frais.fraisMissionRegion.idVille != null ? frais.fraisMissionRegion.idVille.toString() : '00';

        return hashIdRegion;
    }

    /**
     * Construit un tableau représentant une semaine
     */
    getNewEmptyWeek(): Array<IjWeekday> {
        //Création du tableau de 7 jours
        return new Array(7)
            .fill(null)
            .map((value, index) => {
                //On utilise l'index dans le tableau pour initialiser le jour
                return new IjWeekday(index)
            });
    }

    /**
     * Ouvre la popup des dégrèvements en masse
     */
    degrevementMasse() {
        //Si l'utilisateur a fait des modifications
        if (this.listeDetailsModifies?.size > 0) {
            //On demande à l'utilisateur s'il veut enregistrer ses modifs avant d'aller sur le dégrèvement en masse
            this.confirmService.showConfirm(this.translateService.instant('od.engagements.indemnites.degrevements.confirmEnregistrement'), {type: 'oui-non'}).subscribe(isConfirme => {
                //Si l'utilisateur confirme l'enregistrement
                if (isConfirme) {
                    //On enregistre les modifications
                    this.saveDegrevement(false).subscribe(isOk => {
                        //Si l'enregistrement s'est bien passé
                        if (isOk) {
                            //On va sur le dégrèvement en masse
                            this.gotoDegrevementEnMasse(true);
                        }
                    });
                } else {
                    //Si l'utilisateur ne veut pas enregistrer, on va direct sur le dégrèvement en masse
                    this.gotoDegrevementEnMasse();
                }
            });
        } else {
            //Si l'utilisateur n'a pas fait de modifications, on va sur le dégrèvement en masse
            this.gotoDegrevementEnMasse();
        }
    }

    /**
     * Lance la popup de dégrèvement en masse
     *
     * @param needReresh Indique si on a besoin d'un refresh a la fermeture de la popup, quelle que soit la raison
     */
    gotoDegrevementEnMasse(needReresh: boolean = false) {
        //Ouverture de la popup de dégrèvement en masse
        this.matDialog.open<any, any, boolean>(OdDegrevementsMasseComponent, {
            data: {
                idOd: this.od.idOd,
                listeRegles: this.listeRegles,
                dateMin: this.dateMin,
                dateMax: this.dateMax,
                needRefresh: needReresh
            }
        }).afterClosed().subscribe(refresh => {
            //Si on a besoin d'un refresh
            if (refresh) {
                //Fermeture de la popin
                this.matDialogRef.close(true);
            }
        });
    }
}
