import {AfterViewInit,ChangeDetectorRef,Component,EventEmitter,Input,OnInit,Output,TemplateRef,ViewChild} from '@angular/core';
import {NiveauAlerte} from '@domain/common/alerte/alerte';
import {Facture} from "@domain/facture/facture";
import {SettingsFCState} from "@domain/settings/settings";
import {ModeAnalytique,ModeMIB,TypeEntiteParamFC,TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {FillingRow} from "@share/component/filling-row/filling-row";
import {ODService} from "@components/od/od.service";
import {NgForm} from "@angular/forms";
import {TypeFact} from "@domain/facture/type-fact";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";
import {Field,FieldContainerWrapper} from "@domain/settings/field";
import {Router} from "@angular/router";
import {ReleveFacture,TypeReconciliation} from "@domain/facture/releve-facture";
import {UserDto} from "@domain/user/user-dto";
import {finalize,first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {PreferenceAnalytique} from "@domain/profil/preferenceAnalytique";
import {Analytique} from "@components/analytique/analytique";
import {Od} from "@domain/od/od";
import {FactureService} from "@components/facture/facture.service";
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {AppState} from "@domain/appstate";
import {Store} from "@ngrx/store";
import {SettingsService} from "@components/settings/settings.service";
import {ZoneUtilisateurComponent} from "@components/zone-utilisateur/zone-utilisateur.component";
import {TypeProfil,User} from "@domain/user/user";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Détail d'une facture - Onglet "Généralités"
 *
 * @author Laurent Convert
 * @date 11/01/2023
 */
@Component({
	host: {'data-test-id': 'facture-generalites'},
	selector: 'facture-generalites',
	templateUrl: './facture-generalites.component.html'
})
export class FactureGeneralitesComponent implements OnInit,AfterViewInit {
	/** Déclaration pour accès dans le template */
	NiveauAlerte = NiveauAlerte;
	ModeMIB = ModeMIB;
	TypeProfil = TypeProfil;

	/** Relevé de facture associé à la facture */
	@Input() releve: ReleveFacture;

	/** Identifiant de la période courante pour la sélection par défaut du MIB */
	@Input() idPeriodeCourante: number;

	/** Facture en cours */
	@Input() facture: Facture;

	/** Champs customs */
	@Input() listeFieldParam: Field[];

    /** Utilisateur connecté */
    @Input() user: User;

	/** Indicateur de mise à jour possible */
	@Input() canModifier: boolean;

	/** Paramétrage global du module des factures */
	@Input() settings: SettingsFCState;

	/** Paramétrage spécifique au module des factures pour le type entité de la facture */
	@Input() typeEntiteParam: TypeEntiteParamFC;

	/** Paramétrage spécifique au module des OD pour le type entité de l'OD associé à la facture */
	@Input() typeEntiteParamOd: TypeEntiteParamOD;

	/** Indique à l'élément parent qu'un chargement est en cours */
	@Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** Indique à l'élément parent que la liste des factures de référence doit être rechargée */
    @Output() refreshListeFactureReference: EventEmitter<void> = new EventEmitter<void>();

	/** Liste des types de facture */
	listeTypeFacture: Array<TypeFactEnum> = [
		{valeur: TypeFact.FACTURE,libelle: this.translateService.instant(TypeFact.traduction(TypeFact.FACTURE))},
		{valeur: TypeFact.AVOIR,libelle: this.translateService.instant(TypeFact.traduction(TypeFact.AVOIR))}
	];

	/** Liste des éléments à afficher dans la colonne de gauche */
	listeTemplatesGauche: Array<FillingRow> = [];

	/** Liste des éléments à afficher dans la colonne de droite */
	listeTemplatesDroite: Array<FillingRow> = [];

	/** Indique si la page a été chargée */
	isLoaded = false;

	/** Collaborateur associé à la facture */
	collaborateur: UserDto;

	/** Od associé à la facture */
	od: Od;

	/** Wrapper pour les champs complémentaires de la facture */
	fieldContainer: FieldContainerWrapper;

	/** Formulaire des généralités */
	@ViewChild('form') form: NgForm;

	/* Liste des templates à afficher (ou pas) dans la page */
	@ViewChild('tplNumero',{static: true}) tplNumero: TemplateRef<any>;
	@ViewChild('tplOd',{static: true}) tplOd: TemplateRef<any>;
	@ViewChild('tplCollab',{static: true}) tplCollab: TemplateRef<any>;
	@ViewChild('tplOdTooltip',{static: true}) tplOdTooltip: TemplateRef<any>;
	@ViewChild('tplDate',{static: true}) tplDate: TemplateRef<any>;
	@ViewChild('tplTypeFacture',{static: true}) tplTypeFacture: TemplateRef<any>;
	@ViewChild('tplFournisseur',{static: true}) tplFournisseur: TemplateRef<any>;
    @ViewChild('tplNumeroReleve',{static: true}) tplNumeroReleve: TemplateRef<any>;
	@ViewChild('tplMoisImputation',{static: true}) tplMoisImputation: TemplateRef<any>;
	@ViewChild('tplMontant',{static: true}) tplMontant: TemplateRef<any>;
	@ViewChild('tplMontantControle',{static: true}) tplMontantControle: TemplateRef<any>;

	/** Composant enfant zone utilisateur */
	@ViewChild(ZoneUtilisateurComponent) childZoneUtilisateurComponent;

	/** Évènement déclenché pour mettre à jour l'analytique en fonction du choix de la réconciliation.
	 * Le paramètre contient le nombre de préférences analytiques de l'utilisateur */
	@Output() updateAnalytique: EventEmitter<number> = new EventEmitter<number>();

    /** Évènement déclenché pour la remise à zéro de la liste de suggestions de l'autocomplete */
    @Output() resetAutocomplete: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Constructeur
	 */
    constructor(
        private store: Store<AppState>,
				private odService: ODService,
				private translateService: TranslateService,
				private cd: ChangeDetectorRef,
				private factureService: FactureService,
				private analytiqueService: AnalytiqueService,
				private toastrService: ToastrService,
                private settingsService: SettingsService,
        private confirmService: ConfirmService,
        private router: Router
    ) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Construction du wrapper pour les champs complémentaires
		this.fieldContainer = new FieldContainerWrapper(this.facture);

		//Initialisation de la variable utilisée pour la sélection du collaborateur
		//(on ne cible pas directement le champ 'user' de la facture car l'autocomplete change le NgModel avec la valeur tapée dans le champ ><)
		if (this.releve.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_OM) {
			this.od = this.facture.od != null ? new Od(this.facture.od) : null;
		} else if (this.releve.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_COLLAB) {
			this.collaborateur = this.facture.user != null ? new UserDto(this.facture.user) : null;
		}

		//Ajout des templates des éléments de gauche
		this.listeTemplatesGauche.push(new FillingRow(this.tplNumero));
		if (this.releve?.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_OM) {
			this.listeTemplatesGauche.push(new FillingRow(this.tplOd));
		} else if (this.releve?.typeReconciliation == TypeReconciliation.TYPE_RECONCILIATION_COLLAB) {
			this.listeTemplatesGauche.push(new FillingRow(this.tplCollab));
		}
		this.listeTemplatesGauche.push(new FillingRow(this.tplDate));
		this.listeTemplatesGauche.push(new FillingRow(this.tplTypeFacture));

		//Ajout des templates des éléments de droite
		this.listeTemplatesDroite.push(new FillingRow(this.tplFournisseur));
        this.listeTemplatesDroite.push(new FillingRow(this.tplNumeroReleve));
		this.listeTemplatesDroite.push(new FillingRow(this.tplMoisImputation));
		this.listeTemplatesDroite.push(new FillingRow(this.tplMontant));
		this.listeTemplatesDroite.push(new FillingRow(this.tplMontantControle));
	}

	/**
	 * Après initialisation de la vue
	 */
	ngAfterViewInit(): void {
		//On relance une vérification des changements pour esquiver une erreur "le contenu a changé après l'initialisation"
		this.cd.detectChanges();

		//On indique que la page est chargée au prochain cycle
		setTimeout(() => this.isLoaded = true);
	}

    /**
     * Ré-initialisation de la liste des factures de référence le cas échéant
     */
    resetListeFactureReference() {
        //Vérification de la présence de la sélection de factures de référence
        if (this.facture.contentieux?.listeFactureReference?.length > 0 || this.facture.listeContentieuxReference?.length > 0) {
            //Affichage d'un message informant l'utilisateur que la liste des factures de référence va être réinitialisée
            this.confirmService.showConfirm(this.translateService.instant('facture.releve.alertes.changementReconciliation.message'),{
                title: 'facture.releve.alertes.changementReconciliation.title',
                type: 'ok'
            }).subscribe(() => {
                //Réinitialisation de la liste des factures de référence
                this.facture.contentieux.listeFactureReference = [];
                this.facture.listeContentieuxReference = [];

                //Déclenchement de l'évènement de rechargement de la liste (vide) des factures de référence
                this.refreshListeFactureReference.emit();
            });
        }
    }

	/**
	 * Changement du collaborateur
	 */
	userChanged() {
        //Ré-initialisation de la liste des factures de référence
        this.resetListeFactureReference();

		//Reset de la ventilation
		this.facture.listeVentilations = [];

		//Vérification qu'un collaborateur a bien été sélectionné
		if (this.collaborateur) {
			//Chargement en cours
			this.isLoading.emit(true);

			//Copie du collab sélectionné vers la facture
			this.facture.user = new UserDto(this.collaborateur);

			//Récupération des préférences analytiques de l'utilisateur pour initialiser la ventilation de la facture
			this.analytiqueService.getPreferenceAnalytique(this.facture.typeEntite?.idTypeEntite,this.facture.user?.idUser)
				.pipe(first(),finalize(() => this.isLoading.emit(false)))
				.subscribe((result: Result) => {
					if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
						const preferenceAnalytique = result.data.preferenceAnalytique as PreferenceAnalytique;
						const preferenceAnalytiqueCount = result.data.preferenceAnalytiqueCount as number;

						//Vérification de la présence de la préférence analytique
						if (preferenceAnalytique) {
							//Ventilation analytique à partir des préférences analytiques de l'utilisateur
							this.facture.listeVentilations = [new Analytique(preferenceAnalytique)];
						} else {
							//Définition de la ventilation selon le service d'affectation du collaborateur
							this.facture.listeVentilations = this.buildListeVentilationParDefaut();
						}

						//Mise à jour de l'analytique
						this.updateAnalytique.emit(preferenceAnalytiqueCount);
					} else {
						//Affichage d'un message d'erreur
						TypeCodeErreur.showError(result?.codeErreur ?? TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
					}
				},() => {
					//Message d'erreur (spécifique)
					this.toastrService.error(this.translateService.instant('facture.errors.erreurChargementPreferenceAnalytique'));
				});
		}
	}

	/**
	 * Changement du collaborateur
	 */
	odChanged() {
        //Ré-initialisation de la liste des factures de référence
        this.resetListeFactureReference();

		//Vérification qu'un OD a bien été sélectionné
		if (this.od) {
			//Début du traitement
			this.isLoading.emit(true);

			//Récupération du type entité et de ses paramètres pour l'OD sélectionné
			this.factureService.getMibAndVentilForOd(this.od.idOd)
				.pipe(finalize(() => this.isLoading.emit(false)))
				.subscribe((result: Result) => {
					if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
						const typeEntiteParamOD = result.data.typeEntiteParamOD as TypeEntiteParamOD;
						const preferenceAnalytique = result.data.preferenceAnalytique as PreferenceAnalytique;
						const listeVentilation = result.data.listeVentilation as Array<Analytique>;
						const preferenceAnalytiqueCount = result.data.preferenceAnalytiqueCount as number;

						//Mise à jour des paramètres du type entité sur l'OD (absent du DTO de la liste car redondant et inutile)
						this.typeEntiteParamOd = typeEntiteParamOD;

						//Association de la facture avec l'OD sélectionné
						this.facture.od = new Od(this.od);

						//Mise à jour du collaborateur à partir de celui défini sur l'OD
						this.facture.user = new UserDto(this.facture.od.user);

						//Vérification du mode d'héritage de la facture pour le MIB
						if (typeEntiteParamOD.modeMIBFacture != ModeMIB.MIB_NON_HERITE) { //MIB hérité
							//Vérification de la non clôture de la période de l'od en vérifiant si la période de l'OD fait partie de la liste des périodes ouvertes
							if (this.settings.listePeriodes.some(periode => periode.idPeriode == this.facture.od.periode.idPeriode)) {
								//Période de l'OD ouverte : Mise à jour du MIB de la facture avec celle de l'OD
								this.facture.periode = this.facture.od.periode;
							} else { //Période de l'OD fermée
								//Début du traitement
								this.isLoading.emit(true);

								//Récupération de la plus proche période ouverte après celle de l'od
								this.factureService.getPeriodeOuverte(this.facture)
									.pipe(first(),finalize(() => this.isLoading.emit(false)))
									.subscribe(periode => {
										//Vérification de la présence d'une période
										if (periode) {
											//Mise à jour du MIB de la facture avec le résultat
											this.facture.periode = periode;
										}
									},error => {
										//Affichage d'un message d'erreur
										TypeCodeErreur.showError(error,this.translateService,this.toastrService);
									});
							}
						} else {
							//MIB non hérité : mise à jour du MIB de la facture avec la période courante
							this.facture.periode = this.settings.listePeriodes.find(periode => periode.idPeriode == this.idPeriodeCourante);
						}

						//Si l'analytique des factures est héritée des OD
						if ([ModeAnalytique.HERITE_NON_MODIFIABLE,ModeAnalytique.HERITE_MODIFIABLE].includes(typeEntiteParamOD.modeAnalytiqueFacture)) {
							//On remplace la ventilation de la facture par celle de l'OD
							this.facture.listeVentilations = listeVentilation
								.filter(v => !!v.orga)
								.map(v => Object.assign(new Analytique(),v,{id: null}));
						} else if (preferenceAnalytique) {
							//Ventilation analytique à partir des préférences analytiques de l'utilisateur
							this.facture.listeVentilations = [new Analytique(preferenceAnalytique)];
						} else {
							//Définition de la ventilation selon le service d'affectation du collaborateur
							this.facture.listeVentilations = this.buildListeVentilationParDefaut();
						}

						//Mise à jour de l'analytique
						this.updateAnalytique.emit(preferenceAnalytiqueCount);
					} else {
						//Affichage d'un message d'erreur
						TypeCodeErreur.showError(result?.codeErreur ?? TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
					}
				},() => {
					this.toastrService.error('global.errors.chargement');
				});
		} else {
			//Reset de la ventilation
			this.facture.listeVentilations = [];

			//Association de la facture avec l'OD sélectionné
			this.facture.od = null;

			//Mise à jour du collaborateur à partir de celui défini sur l'OD
			this.facture.user = null;
		}
	}

	/**
	 * Construit la ventilation par défaut pour le collaborateur de la facture
	 */
	private buildListeVentilationParDefaut(): Array<Analytique> {
		const listeVentilation: Array<Analytique> = new Array<Analytique>();

		//Vérification de la présence de l'organisme du collaborateur de la facture
		if (this.facture.user?.orga) {
			//Construction d'une ventilation par défaut
			const ventil = new Analytique();
			ventil.pourcentage = 100;
			ventil.orga = this.facture.user.orga;
			listeVentilation.push(ventil);
		}

		return listeVentilation;
	}

	/**
	 * Changement de date
	 */
	dateChanged(date: moment.Moment) {
		//Mise à jour du cours de la devise
		this.updateCoursDevise();
	}

	/**
	 * Changement de devise
	 *
	 * @param devise Code de la devise sélectionnée
	 */
	deviseChanged(devise: string) {
		//Mise à jour de la devise sur la facture
		this.facture.devise = devise;

		//Mise à jour du cours de la devise
		this.updateCoursDevise();
	}

	/**
	 * Mise à jour du cours de la devise sur la facture en fonction de la date et de la devise sélectionnée
	 */
	updateCoursDevise() {
		//Début du chargement
		this.isLoading.emit(true);

		//Vérification de la devise et de la date
		if (this.facture.devise != null && this.facture.date != null) {
			this.settingsService.findCoursForDevise({codeDevise: this.facture.devise,date: this.facture.date.toDate()})
				.pipe(first(),finalize(() => this.isLoading.emit(false)))
				.subscribe(cours => {
					this.facture.cours = cours;
				});
		} else {
			//Pas de devise ou pas de date = pas de cours
			this.facture.cours = 0;
		}
	}

	/**
	 * Met à jour le dto avant la sauvegarde
	 */
	public beforeSave(): void {
		//Appelle le composant enfant zone utilisateur pour intégrer les données du formulaire zone utilisateur
		this.facture.listeZU = this.childZoneUtilisateurComponent.getListeZoneUtilisateursBeforeSave();
	}

	/**
	 * Vérifie si les données sont valides
	 *
	 * @return True si valide, False sinon
	 */
	isValid(): boolean {
		return this.form?.valid;
	}

	/**
	 * Retour au relevé de la facture (réservé au comptable qui accède à une facture depuis un relevé)
	 */
	backToReleve(): void {
		//Go relevé !
		this.factureService.showReleve(this.releve.idFactureReleve);
	}

    //Navigation vers l'OM
    navigateToOd(): void {
        this.router.navigateByUrl(`OD/${this.facture.od.idOd}?idFacture=${this.facture.idFacture}`);
    }
}

type TypeFactEnum = {
	valeur: string;
	libelle: string;
}
