import {Component} from '@angular/core';
import {AutocompleteSearchItemComponent} from "../../../autocomplete-search-item.component";
import {UserDto} from "@domain/user/user-dto";
import {TranslateService} from "@ngx-translate/core";
import {UserPipe} from "@share/pipe/user.pipe";
import {DatePipe} from "@angular/common";
import {MontantPipe} from "@share/pipe/montant";

/**
 * Composant d'affichage d'une ligne de la liste des collaborateurs dans le cas de la correction des rejets de facture
 *
 * @author Laurent Convert
 * @date 11/07/2023
 */
@Component({
    host: {'data-test-id': 'import-facture-collab-search-item'},
    templateUrl: './import-facture-collab.search-item.html',
})
export class ImportFactureCollabSearchItem extends AutocompleteSearchItemComponent<UserDto> {

    constructor(public translateService: TranslateService,public datePipe: DatePipe,public montantPipe: MontantPipe,private userPipe: UserPipe) {
        super(translateService,datePipe,montantPipe);
    }

    formatUser(user: UserDto): string {
        return this.userPipe.transform(user);
    }

}