import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {OMPService} from "../../omp.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {OmZone} from "@domain/omp/omZone";
import {AutocompleteComponent} from "@share/component/autocomplete/autocomplete.component";
import {GeographieView} from "@domain/geographie/geographieView";
import {TypeOmZone} from "@domain/omp/typeOmZone";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {PorteeGeographie} from "@share/component/autocomplete/options/geographie";

@Component({
    selector: 'omp-zone-add',
    templateUrl: './omp-zone-add.component.html',
})
export class OMPZoneAddComponent implements OnInit {
    /** Pour l'utilisation de l'enum dans le tempalte */
    readonly porteeGeographie: typeof PorteeGeographie = PorteeGeographie;
    readonly typeOmZone: typeof TypeOmZone = TypeOmZone;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Liste des types de zone */
    listeTypeZone: Array<any>;

    /** Objet géographie sélectionné */
    selectedGeographie: GeographieView;

    /** Autocomplete Géographie */
    @ViewChild("autocompleteGeographie")
    autocompleteGeographie: AutocompleteComponent;

    /**
     * Constructeur
     */
    constructor(private ompService: OMPService, private toastrService: ToastrService,private translateService: TranslateService,
                private confirmService: ConfirmService,private matDialogRef: MatDialogRef<any,any>,
                @Inject(MAT_DIALOG_DATA) public data: { zone: OmZone, canModifier: boolean }) {

    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        this.listeTypeZone = [];
        let val: number;

        //Parcours de l'énumération des types de zone pour construire la liste utilisées par le select
        for (const [key, value] of Object.entries(TypeOmZone)) {
            //Vérification que la valeur est bien un nombre
            if (!isNaN(val = Number(value))) {
                //Ajout d'un objet contenant les informations nécessaires à la liste
                this.listeTypeZone.push({
                    name: `${key}`,
                    id: value,
                    libelle: this.ompService.translateTypeZone(val)
                })
            }
        }

        //Construction d'un objet GeographieView à partir des différents objets de la zone
        this.selectedGeographie = this.getGeographie(this.data.zone);
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeZone Type de zone de déplacement
     */
    getAvatarForTypeZone(typeZone: number): string {
        return this.ompService.getAvatarForTypeZone(typeZone);
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeZone Type de zone de déplacement
     */
    getTitleForTypeZone(typeZone: number): string {
        return this.ompService.translateTypeZone(typeZone);
    }

    /**
     * Sélection du mode de transport par l'utilisateur
     *
     * @param typeZone Type de zone de déplacement
     */
    selectTypeZone(typeZone: number): void {
        //Changement du type par celui sélectionné
        this.data.zone.type = typeZone;

        //Ré-initialisation des informations liées à la géographie
        this.setGeographie(null);
        this.selectedGeographie = null;
        this.autocompleteGeographie.listeItems = null;
    }

    /**
     * Retourne True si la zone en cours d'édition a un type
     */
    zoneHasType(): boolean {
        return this.data.zone.type && this.data.zone.type > 0;
    }

    /**
     * Retour à la sélection du type de transport depuis la liste
     */
    goBack(): void {
        this.data.zone.type = null;
    }

    /**
     * Retourne le type de filtre utilisé par l'autocomplete de choix de la zone en fonction du type choisi
     *
     * @param typeZone Type de zone de déplacement
     */
    getAutoCompleteFilter(typeZone): Array<String> {
        let filter = Array<String>();

        switch(typeZone) {
            case TypeOmZone.TYPE_ZONE:
                filter.push('ZONE');
                break;
            case TypeOmZone.TYPE_PAYS:
                filter.push('PAYS');
                break;
            case TypeOmZone.TYPE_TERRITOIRE:
                filter.push('REGION');
                break;
            case TypeOmZone.TYPE_REGION:
                filter.push('REGION_ADMINISTRATIVE');
                break;
            case TypeOmZone.TYPE_DEPARTEMENT:
                filter.push('DEPARTEMENT_ADMINISTRATIF');
                break;
            case TypeOmZone.TYPE_VILLE:
                filter.push('VILLE');
                break;
            default:
                filter.push('ZONE','PAYS','REGION','REGION_ADMINISTRATIVE','DEPARTEMENT_ADMINISTRATIF','VILLE');
        }

        return filter;
    }

    /**
     * Construit un objet géographie "light" correspondant au type de la zone
     */
    getGeographie(zone: OmZone): GeographieView {
        let geographie:GeographieView;

        switch(zone.type) {
            case TypeOmZone.TYPE_ZONE:
                geographie = { type: TypeGeographie.ZONE, id: zone.idOmZone, libelle: zone.zone.libelle };
                break;
            case TypeOmZone.TYPE_PAYS:
                geographie = { type: TypeGeographie.PAYS, id: zone.idOmZone, libelle: zone.pays.libelle };
                break;
            case TypeOmZone.TYPE_TERRITOIRE:
                geographie = { type: TypeGeographie.REGION, id: zone.idOmZone, libelle: zone.territoire.libelle };
                break;
            case TypeOmZone.TYPE_REGION:
                geographie = { type: TypeGeographie.REGION_ADMINISTRATIVE, id: zone.idOmZone, libelle: zone.region.libelle };
                break;
            case TypeOmZone.TYPE_DEPARTEMENT:
                geographie = { type: TypeGeographie.DEPARTEMENT_ADMINISTRATIF, id: zone.idOmZone, libelle: zone.departement.libelle };
                break;
            case TypeOmZone.TYPE_VILLE:
                geographie = { type: TypeGeographie.VILLE, id: zone.idOmZone, libelle: zone.ville.libelle };
                break;
            default:
                geographie = null;
                break;
        }

        return geographie;
    }

    /**
     * Met à jour la zone à partir de l'objet géographie
     *
     * @param geographie Objet géographie
     */
    setGeographie(geographie: GeographieView): void {
        let typeZone: TypeOmZone = geographie ? this.data.zone.type : null;

        //ré-initialisation des champs
        this.data.zone.idZone = null;
        this.data.zone.zone = null;

        this.data.zone.idPays = null;
        this.data.zone.pays = null;

        this.data.zone.idRegion = null;
        this.data.zone.territoire = null;

        this.data.zone.idRegionAdmin = null;
        this.data.zone.region = null;

        this.data.zone.idDepartementAdmin = null;
        this.data.zone.departement = null;

        this.data.zone.idVille = null;
        this.data.zone.ville = null;

        switch(typeZone) {
            //Zone
            case TypeOmZone.TYPE_ZONE:
                //Vérification de la correspondance du type entre la zone et la geographie passée en paramètre
                if (geographie.type == TypeGeographie.ZONE) {
                    this.data.zone.idZone = geographie.id;
                }
                break;
            //Pays
            case TypeOmZone.TYPE_PAYS:
                //Vérification de la correspondance du type entre la zone et la geographie passée en paramètre
                if (geographie.type == TypeGeographie.PAYS) {
                    this.data.zone.idPays = geographie.id;
                }
                break;
            //Territoire
            case TypeOmZone.TYPE_TERRITOIRE:
                //Vérification de la correspondance du type entre la zone et la geographie passée en paramètre
                if (geographie.type == TypeGeographie.REGION) {
                    this.data.zone.idRegion = geographie.id;
                }
                break;
            //Région
            case TypeOmZone.TYPE_REGION:
                //Vérification de la correspondance du type entre la zone et la geographie passée en paramètre
                if (geographie.type == TypeGeographie.REGION_ADMINISTRATIVE) {
                    this.data.zone.idRegionAdmin = geographie.id;
                }
                break;
            //Département
            case TypeOmZone.TYPE_DEPARTEMENT:
                //Vérification de la correspondance du type entre la zone et la geographie passée en paramètre
                if (geographie.type == TypeGeographie.DEPARTEMENT_ADMINISTRATIF) {
                    this.data.zone.idDepartementAdmin = geographie.id;
                }
                break;
            //Ville
            case TypeOmZone.TYPE_VILLE:
                this.data.zone.idVille = geographie.id;
                break;
            default:
                break;
        }
    }

    /**
     * Met à jour la zone à partir de l'objet géographie sélectionné
     *
     * @param geographie Objet géographie source
     */
    onGeographieChange(geographie: GeographieView): void {
        this.setGeographie(geographie);
    }

    /**
     * Suppression de la zone
     */
    deleteZone(): void {
        //Demande de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //Enregistrement en cours
                    this.isSaving = true;

                    //Suppression de la zone
                    this.ompService.deleteZone(this.data.zone).subscribe({
                        next: data => {
                            //Vérification de l'enregistrement
                            if (data.codeErreur == 0) {
                                //Message d'information
                                this.toastrService.success(this.translateService.instant('global.success.suppression'));

                                //Fermeture de l'écran
                                this.matDialogRef.close(data.data);
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        },
                        complete: () => {
                            //Fin de l'enregistrement
                            this.isSaving = false;
                        }
                    });
                }
            });
    }

    /**
     * Enregistrement de la zone
     */
    saveZone(): void {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement de la zone de déplacement
        this.ompService.saveZone(this.data.zone).subscribe({
            next: data => {
                //Vérification de l'enregistrement
                if (data.codeErreur === 0) {
                    //Message d'information
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de l'écran
                    this.matDialogRef.close(data.data);
                } else {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            },
            complete: () => {
                //Fin de l'enregistrement
                this.isSaving = false;
            }
        });

    }

}
