import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../autocomplete-line-item.component";
import {TypeFrais} from '@domain/ndf/liste-mes-frais';
import {Prestation} from "@domain/prestation/prestation";

/**
 * Affichage d'une ligne de prestation de frais
 *
 * @author Lionel GIMENEZ
 * @date 06/06/2023
 */
@Component({
	templateUrl: './prestation-frais-line-item.component.html',
	host: {'data-test-id': 'prestation-frais-line-item'},
})
export class PrestationFraisLineItemComponent extends AutocompleteLineItemComponent<Prestation> {
	//Énum pour le DOM
	TypeFrais = TypeFrais;

	/**
	 * Construction du libellé d'un véhicule
	 */
	getLibelleVehicule(): string {
		return `${this.item.vehicule.marque} ${this.item.vehicule.modele} (${this.item.vehicule.immatriculation})`;
	}
}