<div class="with-floating-button">
    <div class="content" *ngIf="distance">
        <page-header
                     [title]="isCreation ? ('admin.bibliotheque.geographie.distance.titleCreation' | translate)
                     : ('admin.bibliotheque.geographie.distance.title' | translate: {libelle: distance.libelle || ''})"
                     [listeItems]="listeTabItems" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm">
                <distance-generalites [distance]="distance" [isCreation]="isCreation"></distance-generalites>
            </form>
        </div>

        <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!distance"></please-wait>
</div>