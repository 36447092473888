import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Filtre,TypeFiltre} from "@domain/admin/entreprise/user/filtre";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter} from "rxjs/operators";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {TypeProfil} from "@domain/user/user";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant de l'item de liste d'un filtre
 */
@Component({
	host: {'data-test-id': 'filtre-list-item'},
	templateUrl: './filtre-list-item.component.html'
})
export class FiltreListItemComponent extends ListViewItem<Filtre> {
	/** Les options supplémentaires */
	extraOptions: {
		fonction: TypeProfil
	}

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param confirmService le service de confirmation
	 * @param userService le service de gestion de l'utilisateur
	 * @param toastrService le toaster
	 */
	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService
	) {
		super();
	}

	/**
	 * Récupération du libellé du type de filtre
	 */
	getLibelleTypeFiltre(): string {
		return TypeFiltre.getLibelle(this.data.typeFiltre,this.translateService);
	}

	/**
	 * Suppression du filtre
	 */
	deleteFilter(): void {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service
				this.userService.deleteFiltre(this.data,this.extraOptions.fonction)
					.subscribe((result: Result) => {
						//Vérification du result
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Refresh de la liste
							this.liste.refresh();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}
