import {Component,Input,OnInit} from '@angular/core';
import {TypeEntiteParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Composant des items de la liste des filtres pour la popin
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Component({
    host: {'data-test-id': 'filtre-dialog-item'},
    selector: 'filtre-dialog-item',
    templateUrl: './filtre-dialog-item.component.html'
})

export class FiltreDialogItemComponent extends ListViewItem<TypeEntiteParam> implements OnInit {
    /** Elément courant */
    @Input() data: TypeEntiteParam;

    /**
     * Hook Initialisation
     */
    ngOnInit() {
        //Le back tronque isSelected qui est utilisé pour indiquer les cases cochées par selected
        //On réassigne sur la bonne variable pour la liste et on fera l'inverse à la sauvegarde.
        this.data.isSelected = this.data.selected;
    }

    /**
     * Retourne l'icone à partir du libellé
     * @param libelle le libellé
     * @return le libellé
     */
    getIconeFromLibelle(libelle: string): string {
        if (libelle != null) {
            return libelle.substring(0,3);
        } else {
            return "";
        }
    }
}