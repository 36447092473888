import { Component } from "@angular/core";

/**
 * Onglet ndf frais cartes
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'ndf-frais-cartes'},
    templateUrl: './ndf-frais-cartes.component.html'
})
export class NdfFraisCartesComponent {

    /** Utilisé pour attendre le chargement de la première frame avant de charger la seconde */
    //TODO: A supprimer quand l'implémentation v10 sera terminée
    isFirstLoaded: boolean = false;

    /**
     * Méthode appelée lorsque l'iFrame a terminé un chargement
     * TODO: A supprimer quand l'implémentation v10 sera terminée
     */
    loaded() {
        //Si c'est le premier chargement
        if (!this.isFirstLoaded) {
            //Sinon basculement du flag
            this.isFirstLoaded = true;
        }
    }
}
