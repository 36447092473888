<h1 mat-dialog-title>
    <span [translate]="'profilUser.document.add.title'"></span>
    <span [mat-dialog-close]="willRefreshList"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #addTelephoneForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input [(ngModel)]="data.document.type"
                              [selectOptions]="listeTypeDocument"
                              customType="select"
                              lCol="2"
                              libelle="profilUser.document.add.type.libelle"
                              name="typeDocument"
                              ngDefaultControl
                              optionDisplay="libelle"
                              optionValue="type"
                              rCol="4"
                              [required]="true"
                              [readonly]="isReadOnly">
                </custom-input>
                <label [translate]="'profilUser.document.add.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [(ngModel)]="data.document.actif" name="actif" [disabled]="isReadOnly" color="primary"></mat-checkbox>
                </div>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="data.document.titulaire"
                              customType="input"
                              lCol="2"
                              libelle="profilUser.document.titulaire"
                              name="titulaire"
                              ngDefaultControl
                              rCol="4"
                              [required]="true"
                              [readonly]="isReadOnly">
                </custom-input>
                <custom-input [ngModel]="data.document.dateDelivrance"
                              [(customModel)]="data.document.dateDelivrance"
                              customType="date"
                              lCol="2"
                              libelle="profilUser.document.dateDelivrance"
                              name="dateDelivrance"
                              ngDefaultControl
                              rCol="4"
                              [required]="true"
                              [readonly]="isReadOnly"
                              (customModelChange)="onDateDelivranceChange($event)"
                              [errorMessage]="isShowDelivranceError ? ('profilUser.document.add.delivranceError'| translate) : '' ">
                </custom-input>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="data.document.numero"
                              customType="input"
                              lCol="2"
                              libelle="profilUser.document.numero"
                              name="numero"
                              ngDefaultControl
                              rCol="4"
                              [required]="true"
                              [readonly]="isReadOnly"
                              [disabled]="data.document.idDocument > 0">
                </custom-input>
                <custom-input
                        [ngModel]="data.document.dateExpiration"
                        [(customModel)]="data.document.dateExpiration"
                        customType="date"
                        lCol="2"
                        libelle="profilUser.document.dateExpiration"
                        name="dateExpiration"
                        ngDefaultControl
                        rCol="4"
                        [required]="data.document.type != 'PERMIS_DE_CONDUIRE'"
                        [readonly]="isReadOnly"
                        (customModelChange)="onDateExpirationChange($event)"
                        [errorMessage]="isShowExpirationError ? ('profilUser.document.add.expirationError'| translate) : '' ">
                </custom-input>
            </div>
            <div class="row flex-nowrap align-items-center">
                <custom-input *ngIf="!isLieuNonExistant"
                              [(ngModel)]="ville"
                              [autocompleteFilter]="{ listeTypes: ['VILLE'] }"
                              autocompleteType="geographie"
                              customType="autocomplete"
                              lCol="2"
                              libelle="profilUser.document.lieuDelivrance"
                              name="lieuDelivrance"
                              ngDefaultControl
                              optionDisplay="libelle"
                              rCol="4"
                              [readonly]="isReadOnly">
                </custom-input>
                <custom-input *ngIf="isLieuNonExistant"
                              [(ngModel)]="data.document.lieuDelivrance"
                              customType="input"
                              lCol="2"
                              libelle="profilUser.document.lieuDelivrance"
                              name="lieuDelivranceNonExistant"
                              ngDefaultControl
                              rCol="4"
                              [readonly]="isReadOnly">
                </custom-input>
                <label [translate]="'profilUser.document.add.lieuNonExistant'" class="col-md-2">
                </label>
                <div class="col-md-4">
                    <mat-checkbox 
                        [(ngModel)]="isLieuNonExistant" class="mr-2" color="primary" name="lieuNonExistant" 
                        [disabled]="isReadOnly" (change)="onIsLieuNonExistantChange($event)">
                    </mat-checkbox>
                    <span [translate]="'profilUser.document.add.lieuNonExistantTexte'" class="mat-caption"></span>
                </div>

            </div>
            <div *ngIf="isLieuNonExistant" class="row">
                <custom-input [(ngModel)]="pays"
                              [autocompleteFilter]="{ listeTypes: ['PAYS'] }"
                              autocompleteType="geographie"
                              customType="autocomplete"
                              lCol="2"
                              libelle="profilUser.document.PaysDelivrance"
                              name="lieuDelivrancePays"
                              ngDefaultControl
                              optionDisplay="libelle"
                              rCol="4"
                              [required]="true"
                              [readonly]="isReadOnly">
                </custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="deleteDocument()" *ngIf="this.data.document.idDocument != 0" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting && !isSaving" [translate]="this.data.document.toDelete ? 'profilUser.document.add.cancelDelete' : 'global.actions.supprimer'"></span>
        <mat-spinner *ngIf="isDeleting " class="m-5" diameter="28"></mat-spinner>
    </button>
    <button *ngIf="!isDeleted" (click)="save()" [disabled]="addTelephoneForm.invalid || isError() || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving && !isDeleting" [translate]="data.document.idDocument == 0 ?'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving || isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
