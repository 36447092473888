import {Component,Input} from '@angular/core';
import {WorkflowHistoriqueAction} from "@domain/chainage/workflowHistorique";
import {TypeAction} from "@domain/workflow/workflow";

@Component({
    host: {'data-test-id': 'chainage-action-wf'},
    selector: 'chainage-action-wf',
    templateUrl: './chainage-action-wf.component.html',
    styleUrls: ['./chainage-action-wf.component.scss']
})
export class ChainageActionWfComponent {

    /** Action wf qui a été effectuée */
    @Input() action: WorkflowHistoriqueAction;

    /** Index de la ligne de chainage */
    @Input() index: number;

    /** Indique si le list item est ouvert */
    isOuvert: boolean = false;

    /** Indique le type d'action qui a été effectué */
    protected readonly TypeAction = TypeAction;

}
