import { DatePipe, Location } from "@angular/common";
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AppState } from "@domain/appstate";
import { Result } from "@domain/common/http/result";
import { ConfigAlerte, LienUserProfilConnexion } from "@domain/profil/configAlerte";
import { InfosValidCgu } from "@domain/profil/infosValidCgu";
import { ProfilAlertes } from "@domain/profil/profilAlertes";
import { SettingsGlobalState } from "@domain/settings/settings";
import { User } from "@domain/user/user";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as settingsActions from "@reducers/settings";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { Alerte, NiveauAlerte } from "@domain/common/alerte/alerte";
import { SynchroSBTConfigUser } from "@domain/voyage/travel/synchro-sbt-config-user";
import { ProfilDonneesComponent } from "./donnees/profil-donnees.component";
import { ProfilService } from "./profil.service";

/**
 * Composant de gestion du profil
 */
@Component({
    host: {'data-test-id': 'profil'},
    selector: 'profil',
    templateUrl: './profil.component.html'
})
export class ProfilComponent implements OnInit,OnDestroy {
    /* Déclaration pour accès dans le template */
    NiveauAlerte = NiveauAlerte;

    /** Référence vers le composant des données du profil */
    @ViewChild(ProfilDonneesComponent)
    profilDonnees: ProfilDonneesComponent;

    /** Liste des onglets */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet sélectionné */
    selectedItem: PageHeaderItem = null;

    /** Code de l'onglet à afficher à l'affichage du profil */
    tabOnLoad: string;

    /** Sélecteur CSS désignant l'élément HTML à afficher à l'affichage du profil (scroll automatique) */
    sectionOnLoad: string;

    /** Profil */
    profil: any;

    /** Configuration des alertes */
    configAlerte: ConfigAlerte;

    /** Subject des alertes */
	alertesSubject: BehaviorSubject<Result> = new BehaviorSubject<Result>(undefined);

    /** Information sur la validation des CGU */
    infoCgu: InfosValidCgu;

    /** Utilisateur connecté */
    user: User;

    /** Paramétrage */
    settings: SettingsGlobalState;

    /** Template de la popup de synchronisation du profil */
    @ViewChild('listeSynchroTemplate') listeSynchroTemplate: TemplateRef<any>;

    /** Liste des synchronisations des SBT. S'affiche dans une popup sur un clic dans les extra infos de la barre d'onglets */
    private listeSynchro: SynchroSBTConfigUser[];

    /** Souscription aux observables */
    listeSubscription: Array<Subscription> = new Array<Subscription>();

    /** Niveau d'alerte du profil */
    private _alertLevel: NiveauAlerte;

    /** Liste des alertes du profil */
    private listeAlertes: Alerte[];

    /**
     * Getter pour le niveau d'alerte de la page
     * Dans le cas où l'accès aux données est interdit, le niveau qui prévaut est RGPD
     */
    get niveauAlerte(): NiveauAlerte {
        //Vérification du blocage RGPD
        if (this.isShowRGPD()) {
            //Dans ce cas le niveau est RGPD
            return NiveauAlerte.RGPD;
        } else {
            //Pas de blocage RGPD : niveau max des alertes ou à défaut celui de la notification le cas échéant (configurable)
            const niveauAlerte: NiveauAlerte = this.isShowAlerte() ? NiveauAlerte.PROFIL_USER : null;
            return this._alertLevel == null ? niveauAlerte : Math.max(niveauAlerte,this._alertLevel);
        }
    }

    /**
     * Getter pour la liste des alertes à afficher
     * Dans le cas où l'accès aux données est interdit, seule "l'alerte" RGPD est affichée
     */
    get listeAlertesAff(): Alerte[] {
        return this.isShowRGPD() ? null : this.listeAlertes;
    }

    constructor(private store: Store<AppState>,
                private location: Location,
                private router: Router,
                private datePipe: DatePipe,
                private activatedRoute: ActivatedRoute,
                private translateService: TranslateService,
                private profilService: ProfilService,
                private matDialog: MatDialog) {

        //Récupération du state de la route courante
        const state = this.router.getCurrentNavigation().extras?.state;

        //Vérification de la présence d'un state sur la route
        if (state) {
            //Récupération des valeurs du state
            this.tabOnLoad = state.tab;
            this.sectionOnLoad = state?.section;
        }
    }

    ngOnInit(): void {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: ['Global']
        });

        //Définition des onglets
        this.listeTabItems = [{
            code: 'PROFIL',
            libelle: this.translateService.instant('profilUser.navigation.profil')
        }, {
            code: 'DONNEES',
            libelle: this.translateService.instant('profilUser.navigation.donnees')
        }, {
            code: 'PJ',
            libelle: this.translateService.instant('profilUser.navigation.pj')
        }, {
            code: 'CGU',
            libelle: this.translateService.instant('profilUser.navigation.cgu')
        }, {
            code: 'APROPOS',
            libelle: this.translateService.instant('profilUser.navigation.aPropos')
        }];

        //Activation d'un onglet particulier au chargement de la page
        if (this.tabOnLoad) {
            //Recherche de l'onglet correspondant et activation
            this.listeTabItems.find(value => value.code === this.tabOnLoad).selected = true;
        }

        //Sélection du paramétrage
        this.listeSubscription.push(this.store.select(state => state.settings?.Global).pipe(first(value => !!value)).subscribe(settings => {
            //Récupération des settings
            this.settings = settings;
        }));

        //Sélection de l'utilisateur connecté
        this.listeSubscription.push(this.store.select(state => state.session?.user).pipe(first(value => !!value)).subscribe(user => this.user = user));

        this.profilService.getInfosValidCgu().pipe(first()).subscribe(v => {
            this.infoCgu = v.data;
        });

        //Rafraichissement des alertes
        this.refreshAlertes();
    }

    /**
     * Rafraichissement des alertes
     */
    refreshAlertes(): void {
        //Initialisation
        this.listeAlertes = [];
        this._alertLevel = null;

        //Récupération des alertes et des configurations des alertes
        combineLatest([this.profilService.loadProfilAlertes(),this.profilService.loadConfigAlerte()])
            .pipe(first())
            .subscribe((res) => {
                //Chargement des données
                this.configAlerte = res[1].data as ConfigAlerte;

                //Emission auprès des enfants qui écoutent
                this.alertesSubject.next(res[0]);

                //Traitement pour ce présent composant
                this.handleAlertes(res[0].data as ProfilAlertes);
            });
    }

    /**
	 * Traite l'affichage des alertes
	 * 
	 * @param profilAlertes Alertes du profil voyageur
	 */
	private handleAlertes(profilAlertes: ProfilAlertes): void {
        //Initialisation
        if (!this.listeAlertes) { this.listeAlertes = [] }
        
        if (profilAlertes?.listeDocumentEchu?.length || profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length) {
            //Niveau d'alerte
            this._alertLevel = Math.max(this._alertLevel ?? NiveauAlerte.ERROR,NiveauAlerte.ERROR);

            //Traitement des alertes bloquantes de documents manquants
            if (profilAlertes?.saisieObligatoire?.listeSaisieManquante?.length) {
                for (const saisie of profilAlertes?.saisieObligatoire?.listeSaisieManquante) {
                    this.listeAlertes.push(new Alerte({
                        titre: null,
                        message: this.translateService.instant("profil.alerte.obligatoire." + saisie),
                        niveau: NiveauAlerte.ERROR,
                    }));
                }
            }

            //Traitement des alertes bloquantes de documents échus
            if (profilAlertes?.listeDocumentEchu?.length) {
                for (const document of profilAlertes?.listeDocumentEchu) {
                    const dateEcheance: Date = new Date(document.dateEcheance);
                    this.listeAlertes.push(new Alerte({
                        titre: null,
                        message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
                                + this.translateService.instant("profil.alerte.echu." + document.typeFormulaire, {
                                    libelle: document.libelle,
                                    numero: document.numero,
                                    date: this.datePipe.transform(dateEcheance, 'shortDate')
                                }),
                        niveau: NiveauAlerte.ERROR
                    }));
                }
            }
        }

        //Ajout des alertes sur les documents arrivant à arrivant à échéance
        if (profilAlertes?.listeDocumentEcheance?.length) {
            //Niveau d'alerte
            this._alertLevel = Math.max(this._alertLevel ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

            //Traitement des alertes non bloquantes de documents arrivant à échéance
            for (const document of profilAlertes?.listeDocumentEcheance) {
                const dateEcheance: Date = new Date(document.dateEcheance);
                const now: Date = new Date();
                this.listeAlertes.push(new Alerte({
                    titre: null,
                    message: this.translateService.instant("profil.alerte.document." + document.typeDocument)
                            + this.translateService.instant("profil.alerte.echeance." + document.typeFormulaire, {
                                libelle: document.libelle,
                                numero: document.numero,
                                date: this.datePipe.transform(dateEcheance, 'shortDate'),
                                nbJours: Math.ceil(Math.abs(dateEcheance.valueOf() - now.valueOf()) / (1000 * 60 * 60 * 24))
                            }),
                    niveau: NiveauAlerte.WARNING
                }));
            }
        }

        //Ajout de l'alerte informant que le profil voyageur doit être synchronisé
        if (this.configAlerte?.lienProfilConnexion?.updated && profilAlertes.saisieObligatoire.profilVoyageurValide) {
            //Niveau d'alerte
            this._alertLevel = Math.max(this._alertLevel ?? NiveauAlerte.WARNING,NiveauAlerte.WARNING);

            //Ajout de l'alerte
            this.listeAlertes.push(new Alerte({
                titre: null,
                message: this.translateService.instant("profil.alerte.synchro"),
                niveau: NiveauAlerte.WARNING
            }));
        }
	}

    ngOnDestroy() {
        //Désabonnements
        this.listeSubscription.forEach(sub => sub.unsubscribe());
    }

    /**
     * Change l'onglet de la page de profil
     * @param selectedItem Nouvel onglet séléctionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

  /**
   * Affiche l'alerte de base
   * @return true si on doit afficher l'alerte
   */
  isShowAlerte() {
    return this.configAlerte?.profilAlerteConfig?.messageActif && this.configAlerte?.messageAccueil != '';
  }

  /**
  * Affiche l'alerte rgpd
  * @return true si on doit afficher l'alerte
  */
  isShowRGPD() {
    return this.selectedItem?.code == 'DONNEES' && this.profilDonnees?.profilUser?.rgpdSuspendu;
  }

    /**
     * Scroll automatique après chargement des données vers l'élément définit dans le state de la route
     */
    onAllListeLoaded(): void {
        //Vérification d'un élément à afficher et que le scroll est à 0 (pour éviter de déclencher le scroll alors que l'utilisateur a déjà commencé à le faire manuellement)
        if (this.sectionOnLoad && window.scrollY == 0) {
            //Déclenchement au prochain cycle (pour attendre le rendu)
            setTimeout(() => {
                //Récupération de l'élément HTML et scroll automatique en mode "smooth"
                document.querySelector(this.sectionOnLoad)?.scrollIntoView({behavior: "smooth",block: "start",inline: "nearest"});
            });
        }
    }

    /**
     * Reçoit un évènement pour mettre à jour la liste des états de synchro des SBT, ou l'état global de synchro.
     * @param event liste des états de synchro des SBT, ou état global de synchro.
     */
    updateSynchroStatut(event: SynchroSBTConfigUser[] | LienUserProfilConnexion): void {
        //Si l'évènement est l'état global de la synchro du profil voyageur de l'utilisateur
        if (event instanceof LienUserProfilConnexion) {
            this.configAlerte.lienProfilConnexion = event;
        } else {
            //Sinon c'est la liste des états de synchro des SBT
            this.listeSynchro = event;
        }
    }

    /**
     * Ouvre la popup qui affiche les états de synchro des SBT pour l'utilisateur.
     */
    public showListeSynchro(): void {
        //Ouverture de la popup
        this.matDialog.open(this.listeSynchroTemplate, {
            disableClose: false,
            data: {
                liste: this.listeSynchro
            },
            width: '600px',
            position: {
                top: '100px'
            },
            role: 'alertdialog',
        });
    }

}
