import { Component,OnInit } from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import { EnveloppeListItemComponent } from "./enveloppe-list-item.component";
import { TranslateService } from "@ngx-translate/core";
import {Enveloppe} from "@domain/budget/enveloppe";
import {Sorting} from "@domain/common/list-view/sorting";

@Component({
    templateUrl: './enveloppe-list.component.html'
})
/**
 * Composant de la liste des enveloppes budgétaires
 */
export class EnveloppeListComponent implements OnInit {

    /** Liste des enveloppes */
    listeEnv: ListView<Enveloppe, EnveloppeListItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService le moteur de trad
     */
    constructor(private translateService: TranslateService) { }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Chargement de la liste
        this.listeEnv = new ListView<Enveloppe, EnveloppeListItemComponent>({
            uri: '/controller/Budget/listeEnveloppesByUser',
            title: this.translateService.instant("budget.liste.title"),
            component: EnveloppeListItemComponent,
            isFilter: true,
            defaultOrder: 'enveloppe.libelle,periodeDebut.dateDebut',
            listeFilters: [{
                title: this.translateService.instant("filter.libelle"),
                clef: "enveloppe.libelle",
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            },{
                title: this.translateService.instant("filter.reference"),
                clef: "enveloppe.reference",
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            },{
                title: this.translateService.instant("filter.exercice"),
                clef: "periodeDebut.dateDebut",
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }]
        });

        //Définition des colonnes de tri
        this.listeEnv.columns = [
            {key: 'enveloppe.libelle',title: 'filter.libelle'},
            {key: 'enveloppe.reference',title: 'filter.reference'},
            {key: 'periodeDebut.dateDebut',title: 'filter.exercice'}
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeEnv.sorting = new Sorting(this.listeEnv.columns,'enveloppe.libelle,periodeDebut.dateDebut');

        //Persistence des filtres
        this.listeEnv.isPersistFilters = true;

        //Nom de la classe
        this.listeEnv.className = 'BudgetEnveloppeListComponent';

        //Par défaut on filtre sur l'année courante
        const anneeCourante: string = new Date().getFullYear().toString();
        this.listeEnv.listeSelectedFilters.push({
            title: this.translateService.instant("budget.liste.exercice"),
            clef: "periodeDebut.dateDebut",
            valeur: anneeCourante + '%',
            displayedValeur: anneeCourante
        });
    }
}
