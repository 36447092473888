import {Component,Input,OnInit} from '@angular/core';
import {OptionVisibilite,Territoire} from "@domain/geographie/territoire";
import {PorteeTerritoireCode} from "@components/admin/bibliotheque/geographie/geographie.component";
import {TranslateService} from "@ngx-translate/core";
import {ControlContainer,NgForm} from '@angular/forms';

/**
 * Composant de l'onglet Généralités d'un Territoire
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'territoire-generalites'},
	selector: 'territoire-generalites',
	templateUrl: './territoire-generalites.component.html',
	viewProviders: [{provide: ControlContainer,useExisting: NgForm}]
})
export class TerritoireGeneralitesComponent implements OnInit{

	/** Territoire */
	@Input() territoire: Territoire;

	/** Indique si on est en création ou en modification */
	@Input() isCreation: boolean;

	/** Liste de toutes les visibilités possibles */
	listeVisibilites: OptionVisibilite[];

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 */
	constructor(private translateService: TranslateService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		this.listeVisibilites = [{
			id: PorteeTerritoireCode.IND,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.indemnite')
		},{
			id: PorteeTerritoireCode.OMP,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.omp')
		},{
			id: PorteeTerritoireCode.TAX,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.taxe')
		}]
	}

}
