<div class="r-flex line-item-list">
    <div class="avatar" [innerHTML]="options.getIcon(item)"></div>
    <div class="body">
        <span class="row">
            <span class="col-md-12">
                {{ options.displayItem(item) }}
            </span>
        </span>
        <span class="row complement">
            <span class="col-md-12" *ngIf="item?.code">
                <strong>{{ 'code' | translate }}</strong>{{ item.code }}
            </span>
        </span>
    </div>
</div>
