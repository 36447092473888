<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.portee == typePortee.OD" class="nio icon-ordre_de_mission"></i>
        <i *ngIf="data.portee == typePortee.OT" class="nio icon-ordre_de_mission_permanent"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" [mat-dialog-close]="data">
                {{ 'ndf.creation.mission' | translate:{ id: data.id,objet:data.objet || ('ndf.liste.objetNonDefini' | translate) } }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li><strong translate="ndf.liste.typeEntite"></strong>{{ data.typeEntite?.libelle }}</li>
            <li>
                <strong [translate]="(data.portee == typePortee.OT ? 'od' : 'omp')+'.liste.libelleVille'"></strong>
                <span class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()" *ngIf="data.pays"></span>{{ (data.ville?.libelle ? data.ville.libelle + ', ' : '') + (data.pays?.libelle || '') }}
            </li>
        </ul>
    </div>
</div>
