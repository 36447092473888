<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.fonction === TypeProfil.COMPTABLE" class="nio icon-comptable"></i>
        <i *ngIf="data.fonction === TypeProfil.RESPONSABLE" class="nio icon-responsable"></i>
        <i *ngIf="data.fonction === TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs"></i>
        <i *ngIf="data.fonction === TypeProfil.ASSISTANT" class="nio icon-assistant"></i>
        <i *ngIf="data.fonction === TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur"></i>
        <i *ngIf="data.fonction === TypeProfil.FOURNISSEUR" class="nio icon-fournisseur"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" style="cursor: default;">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.reporting.infosReporting.filtres.profil.title'"></strong>
                <span [translate]="'admin.bibliotheque.reporting.infosReporting.filtres.profil.' + data.fonction"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="delete()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
