import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ShareModule} from '@share/share.module';
import {ZonePredefinieComponent} from './zone-predefinie.component';
import {ZonePredefinieItemComponent} from './zone-predefinie-item/zone-predefinie-item.component';

@NgModule({
  declarations: [
    ZonePredefinieComponent,
    ZonePredefinieItemComponent
  ],
  imports: [
    CommonModule,
    ShareModule
  ],
  exports: [
    ZonePredefinieComponent
  ]
})
export class ZonePredefinieModule { }
