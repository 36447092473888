<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.code }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.onSelect(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.coursActuel">
                <strong [translate]="'admin.bibliotheque.devises.deviseFiltres.cours'"></strong>
                <span>{{ data.coursActuel.taux | number:'1.8' }}</span>
            </li>
            <li *ngIf="data.coursActuel">
                <strong [translate]="'admin.bibliotheque.devises.deviseFiltres.date'"></strong>
                <span>{{ data.coursActuel.dAppli | date: 'shortDate'}}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.devises.deviseFiltres.actif'"></strong>
                <span>{{ (data.isActive ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
        </ul>
    </div>
</div>
