import {Component,OnInit} from '@angular/core';
import {ListView,TypeFilter} from "@domain/common/list-view";
import {Exercice} from "@domain/admin/comptabilite/exercice";
import {ExercicesListItemComponent} from "@components/admin/comptabilite/exercices-periodes/exercices/exercice-list-item/exercices-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {Router} from "@angular/router";

/**
 * Onglet Exercices de la page admin Comptabilité/Exercices et Périodes
 *
 * @author Alexis BOUVARD
 * @date 19/04/2023
 */
@Component({
	host: {'data-test-id': 'exercices-periodes-exercices'},
	templateUrl: './exercices-periodes-exercices.component.html'
})
export class ExercicesPeriodesExercicesComponent implements OnInit {

	/** Liste des exercices */
	listeExercices: ListView<Exercice,ExercicesListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService le service de gestion de la traduction
	 * @param router le routeur Angular
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition de la liste des exercices
		this.listeExercices = new ListView<Exercice,ExercicesListItemComponent>({
			uri: '/controller/Exercice/getListeExercice',
			title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercices.liste.title'),
			component: ExercicesListItemComponent,
			defaultOrder: '-dateDebut',
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercices.liste.libelle'),
					isDefault: true,
					type: TypeFilter[TypeFilter.STRING]
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercices.liste.dateDebut'),
					isDefault: false,
					type: TypeFilter[TypeFilter.DATE]
				},{
					clef: 'dateFin',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercices.liste.dateFin'),
					isDefault: false,
					type: TypeFilter[TypeFilter.DATE]
				},{
					clef: 'isClosed',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercices.liste.cloture'),
					isDefault: false,
					type: TypeFilter[TypeFilter.BOOLEAN]
				}
			]
		});

		//Définition de la liste des actions
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-ajouter',
			libelle: 'global.actions.creer',
			doAction: () => this.router.navigate(['Admin/Comptabilite/ExercicesPeriodes/Exercice',0])
		}]);
	}
}
