<mat-card>
    <mat-card-title>
            <span [translate]="'admin.maintenance.migrations.onglets.parametres.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <form #form="ngForm">
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="contactAdmin"
                                  name="contactAdmin"
                                  [(ngModel)]="maintenanceParam.emailContactAdmin"
                                  customType="input"
                                  libelle="admin.maintenance.migrations.onglets.parametres.parametresCard.contactAdmin"
                                  [postTooltip]="'admin.maintenance.migrations.onglets.parametres.parametresCard.tooltipAdresses' | translate"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="migrationsAuto"
                                  name="migrationsAuto"
                                  lCol="3" rCol="3"
                                  [ngModel]="maintenanceParam.migrationAuto"
                                  [(customModel)]="maintenanceParam.migrationAuto"
                                  customType="checkbox"
                                  libelle="admin.maintenance.migrations.onglets.parametres.parametresCard.migrationsAuto">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="contactNotilus"
                                  name="contactNotilus"
                                  [(ngModel)]="maintenanceParam.emailContactNotilus"
                                  customType="input"
                                  libelle="admin.maintenance.migrations.onglets.parametres.parametresCard.contactNotilus"
                                  [postTooltip]="'admin.maintenance.migrations.onglets.parametres.parametresCard.tooltipAdresses' | translate"
                                  [required]="true">
                    </custom-input>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>
<mat-card>
    <mat-card-title>
        <span [translate]="'admin.maintenance.migrations.onglets.parametres.messageCard.' + (statutApplication == KeyStatutApplication.EN_LIGNE ? 'horsLigne' : 'ligne')"></span>
    </mat-card-title>
    <mat-card-content>
        <form>
            <fieldset>
                <div class="row" *ngIf="statutApplication != null && statutApplication != KeyStatutApplication.HORS_LIGNE_TEMPO">
                    <custom-input ngDefaultControl
                                  id="message"
                                  name="message"
                                  [(ngModel)]="messageMaintenance"
                                  customType="input"
                                  maxlength="600"
                                  [readonly]="statutApplication == KeyStatutApplication.HORS_LIGNE"
                                  rCol="10"
                                  libelle="admin.maintenance.migrations.onglets.parametres.messageCard.message"
                                  [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button
                            [ngClass]="{ 'maintenance-button': statutApplication == KeyStatutApplication.HORS_LIGNE_TEMPO || statutApplication == KeyStatutApplication.HORS_LIGNE }"
                            [color]="statutApplication == KeyStatutApplication.EN_LIGNE ? 'warn' : 'primary'"
                            mat-flat-button
                            (click)="switchAppStatus()"
                            [disabled]="isMaintenanceEnCours || synchroWfStatut != KeyStatutWorkflow.HISTO_WF_NONE || (statutApplication == KeyStatutApplication.EN_LIGNE && !messageMaintenance)">
                            <span [translate]="'admin.maintenance.migrations.onglets.parametres.messageCard.' + (statutApplication == KeyStatutApplication.EN_LIGNE ? 'horsLigneButton' : 'ligneButton')"></span>
                        </button>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>
<floating-button [listeActions]="listeActions"></floating-button>
