import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {Assurance} from "../../../../domain/vehicule/assurance";
import {Status,Vehicule} from "../../../../domain/vehicule/vehicule";
import {SettingsVehiculeState} from "../../../../domain/settings/settings";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {VehiculeService} from "../../vehicule.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "../../../../share/component/confirmation/confirm.service";
import {TypeDocument} from "../../../../domain/vehicule/typeDocument";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "../../../../domain/common/http/result";
import {DocumentListComponent} from "../../../document/document-list.component";
import * as moment from "moment";
import {ErrorStateMatcher} from "@angular/material/core";
import {FormControl,FormGroupDirective,NgForm} from "@angular/forms";
import {DateUtilsService} from "../../../../share/utils/date-utils/date-utils.service";
import {DocumentService} from "../../../document/document.service";

@Component({
    host: {'data-test-id': 'vehicule-assurance'},
    templateUrl: './vehicule-assurance.component.html',
    providers: [DocumentService]
})
/**
 * Composant pour les assurances de véhicules
 */
export class VehiculeAssuranceComponent implements OnInit {
    /** Déclaration pour accès direct dans le template */
    DateUtilsService = DateUtilsService;
    Status = Status;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Vehicule auquel est rattaché la carte grise */
    vehicule: Vehicule;

    /** Certificat d'assurance */
    assurance: Assurance;

    /** Paramètres de la page de véhicule */
    settings: SettingsVehiculeState;

    /** Booléen pour savoir si on peut faire des modifications */
    canModifier: boolean = true;

    /** Date d'aujourd'hui pour les contrôles de date dans le formulaire */
    aujourdhui: moment.Moment = moment().startOf('day');

    /** Référence vers le composant de la liste des documents */
    @ViewChild('listeDocument')
    listeDocument?: DocumentListComponent;

    /** Gestionnaire d'erreur sur les dates */
    errorStateMatcherDates: errorStateMatcherDates;

    constructor(
        private dialogRef: MatDialogRef<VehiculeAssuranceComponent>,
        private vehiculeService: VehiculeService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        @Inject(MAT_DIALOG_DATA) public data: {
            vehicule: any,
            settings: SettingsVehiculeState,
            idDocument?: any,
            canModifier: boolean
        }
    ) { }

    ngOnInit(): void {
        //Récupération des différentes données
        this.canModifier = this.data.canModifier;
        this.vehicule = this.data.vehicule;
        this.settings = this.data.settings;

        // Chargement de l'objet complet dans le cas d'une modification
        if (this.data.idDocument != 0) {
            this.vehiculeService.loadVehiculeAssurance(this.data.idDocument).pipe(first()).subscribe(res => {
                //Mise à jour de l'instance avec les données récupérées
                this.assurance = Object.assign(new Assurance(),res.data.vehiculeAssurance);

                //Initialisation du gestionnaire d'erreur
                this.errorStateMatcherDates = new errorStateMatcherDates(this.vehicule,this.assurance);
            });
        } else {
            this.assurance = new Assurance();
            this.assurance.idPa = this.vehicule.idPa;

            //Initialisation du gestionnaire d'erreur
            this.errorStateMatcherDates = new errorStateMatcherDates(this.vehicule,this.assurance);
        }
    }

    /**
     * Sauvegarde de l'assurance
     */
    saveAssurance() {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement du document
        this.vehiculeService.saveVehiculeDocument(this.assurance,TypeDocument.ASSURANCE)
            .pipe(first(),finalize(() => this.isSaving = false))
            .subscribe({
                next: (result) => {
                    //Vérification de l'enregistrement
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        const data: {idAssurance: number} = result.data;

                        //Vérification de l'enregistrement
                        if (data.idAssurance) {
                            //S'il y a des documents en attente de liaison avec l'objet
                            if (this.listeDocument?.hasPendingDocumentToLink()) {
                                //Liaison des documents
                                this.listeDocument.linkAllPendingDocument(data.idAssurance).subscribe((success) => {
                                    //Vérification du succès de l'opération
                                    if (success) {
                                        //Message d'information
                                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                                        //Fermeture de l'écran
                                        this.dialogRef.close(true);
                                    } else {
                                        //Message d'erreur
                                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                                    }
                                });
                            } else {
                                //Message d'information
                                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                                //Fermeture de l'écran
                                this.dialogRef.close(true);
                            }
                        } else {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                        }
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Supression de l'assurance
     */
    deleteAssurance() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                this.vehiculeService.deleteVehiculeAssurance(this.assurance.id).subscribe({
                    next: (result: Result) => {
                        //Vérification du code erreur
                        if (result?.codeErreur == 0) {
                            //Affichage d'un message de succès
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                            //Fermeture de la popup
                            this.dialogRef.close(true);
                        } else {
                            //Affichage d'un message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    },
                    error: () => {
                        //Affichage d'un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                });
            }
        });
    }

}

/**
 * Classe de gestion des erreurs sur les dates
 */
class errorStateMatcherDates implements ErrorStateMatcher {

    /** Statut courant d'invalidité */
    _invalid: boolean = false;

    /** Getter du statut courant d'invalidité */
    get invalid(): boolean {
        return this._invalid;
    }

    constructor(private vehicule: Vehicule,
                private assurance: Assurance) {
    }

    /**
     * Vérifie si une autre assurance du véhicule possède les mêmes dates que l'assurance en cours
     */
    isDoublon(): boolean {
        return this.vehicule.listeAssurance
            .filter(assu => assu.idVehiculeAssurance != this.assurance.idVehiculeAssurance)
            .some(assu => DateUtilsService.compareDates(assu.dateDebut,this.assurance.dateDebut) === 0 && DateUtilsService.compareDates(assu.dateFin,this.assurance.dateFin) === 0);
    }

    /**
     * Fonction de vérification de la validité du champ
     */
    isErrorState(control: FormControl | null,form: FormGroupDirective | NgForm | null): boolean {
        //Retour de l'état d'erreur si le champ n'a pas encore été "touché"
        return this._invalid = control && (control.invalid || this.isDoublon()) && (control.dirty || control.touched);
    }

}