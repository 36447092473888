<form>
    <mat-card *ngIf="geolocalisationParams">
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.geolocalisation.configuration.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="service"
                                  name="service"
                                  [(ngModel)]="geolocalisationParams.typeGeolocalisation"
                                  (onChange)="onGeolocalisationChange()"
                                  customType="select"
                                  [selectOptions]="listeTypeGeolocalisation"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  libelle="admin.bibliotheque.geographie.geolocalisation.configuration.service"></custom-input>
                    <custom-input ngDefaultControl
                                  id="niveau"
                                  name="niveau"
                                  [(ngModel)]="geolocalisationParams.typeCiblage"
                                  (onChange)="onCiblageChange()"
                                  customType="select"
                                  [selectOptions]="listeTypeCiblage"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  libelle="admin.bibliotheque.geographie.geolocalisation.configuration.niveau"
                                  [postTooltip]="'admin.bibliotheque.geographie.geolocalisation.configuration.tooltipNiveau' | translate"></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="mode"
                                  name="mode"
                                  [(ngModel)]="geolocalisationParams.typeCalculDistancier"
                                  (onChange)="onCalculChange()"
                                  customType="select"
                                  [selectOptions]="listeTypeCalculDistancier"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  libelle="admin.bibliotheque.geographie.geolocalisation.configuration.mode"
                                  [disabled]="geolocalisationParams.typeGeolocalisation===TypeGeolocalisation.GOOGLE_MAPS"></custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
    <mat-card *ngIf="geolocalisationParams">
        <mat-card-title>
            <span [class.warning]="!isParamsValid"
                  [translate]="'admin.bibliotheque.geographie.geolocalisation.test.title'"
                  [translateParams]="{config: isParamsValid ? (geolocalisationParams.typeGeolocalisation === TypeGeolocalisation.GOOGLE_MAPS ? '(Google Maps)'
                  : geolocalisationParams.typeGeolocalisation === TypeGeolocalisation.VIA_MICHELIN ? '(ViaMichelin)' : '')
                  : ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <card-alerte *ngIf="!isParamsValid" [niveau]="NiveauAlerte.WARNING">
                <preview-alerte [alerte]="alerte"></preview-alerte>
            </card-alerte>
            <div class="row">
                <custom-input *ngIf="geolocalisationParams.typeCiblage===TypeCiblage.VILLE"
                              ngDefaultControl
                              id="departVille"
                              name="departVille"
                              [idName]="'libelle'"
                              optionValue="libelle"
                              [(ngModel)]="selectedDepartVille"
                              (onChange)="onDepartVilleChange()"
                              customType="autocomplete"
                              autocompleteType="geographie"
                              optionDisplay="libelle"
                              [autocompleteFilter]="{listeTypes: ['VILLE']}"
                              libelle="admin.bibliotheque.geographie.geolocalisation.test.depart"
                              [disabled]="!isParamsValid"></custom-input>
                <custom-input *ngIf="geolocalisationParams.typeCiblage===TypeCiblage.ADRESSE"
                              ngDefaultControl
                              id="departAdresse"
                              name="departAdresse"
                              [idName]="'libelle'"
                              optionValue="libelle"
                              [(ngModel)]="selectedDepartAdresse"
                              (onChange)="onDepartAdresseChange()"
                              customType="autocomplete"
                              autocompleteType="adresse"
                              optionDisplay="libelle"
                              [autocompleteFilter]="{}"
                              libelle="admin.bibliotheque.geographie.geolocalisation.test.depart"
                              [disabled]="!isParamsValid"></custom-input>
                <custom-input ngDefaultControl
                              name="distance"
                              rCol="2"
                              customType="number"
                              [readonly]="true"
                              libelle="admin.bibliotheque.geographie.geolocalisation.test.distance"
                              [(ngModel)]="distance"
                              [suffix]="unite.libelle"
                              [postTooltip]="'admin.bibliotheque.geographie.geolocalisation.test.tooltip' | translate">
                </custom-input>
            </div>
            <div class="row">
                <custom-input *ngIf="geolocalisationParams.typeCiblage===TypeCiblage.VILLE"
                              ngDefaultControl
                              id="arriveeVille"
                              name="arriveeVille"
                              [idName]="'libelle'"
                              optionValue="libelle"
                              [(ngModel)]="selectedArriveeVille"
                              (onChange)="onArriveeVilleChange()"
                              customType="autocomplete"
                              autocompleteType="geographie"
                              optionDisplay="libelle"
                              [autocompleteFilter]="{listeTypes: ['VILLE']}"
                              libelle="admin.bibliotheque.geographie.geolocalisation.test.arrivee"
                              [disabled]="!isParamsValid"></custom-input>
                <custom-input *ngIf="geolocalisationParams.typeCiblage===TypeCiblage.ADRESSE"
                              ngDefaultControl
                              id="arriveeAdresse"
                              name="arriveeAdresse"
                              [idName]="'libelle'"
                              optionValue="libelle"
                              [(ngModel)]="selectedArriveeAdresse"
                              (onChange)="onArriveeAdresseChange()"
                              customType="autocomplete"
                              autocompleteType="adresse"
                              optionDisplay="libelle"
                              [autocompleteFilter]="{}"
                              libelle="admin.bibliotheque.geographie.geolocalisation.test.arrivee"
                              [disabled]="!isParamsValid"></custom-input>
            </div>
        </mat-card-content>
    </mat-card>
</form>
<please-wait class="content" *ngIf="!geolocalisationParams"></please-wait>
<floating-button [listeActions]="listeActions" [isPending]="isPending"></floating-button>
