import {Type} from '@angular/core';
import {Filter} from '.';
import {Action} from './action';
import {ListItem} from './list-item';
import {Sorting} from './sorting';
import {Page} from '../http/list-result';
import {NiveauAlerte} from '../alerte/alerte';
import {BehaviorSubject,Subject} from 'rxjs';
import {Result} from "@domain/common/http/result";
import {FloatingButtonAction} from "@share/component/floating-button/floating-button";

/**
 * Composant décrivant une liste
 */
export class ListView<T extends ListItem, K> {
    /** Données **/
    title?: string;
    uri?: string;
    defaultOrder?: string;
    data: Page<T>;
    listeFilters?: Filter[];
    listeActions?: Action[];
    component: Type<K>;
    factory?: new () => any;
    listeStaticFilters: Filter[] = [];
    listeSelectedFilters: Filter[] = [];
    /** Indique que la recherche peut être effectuée si aucun filtre n'est renseigné (Oui par défaut) */
    isSearchWhenNoFilter?: boolean = true;
    extraOptions?: any;
    /** Pagination locale avec des flèches positionnées dans le coin haut droite de la liste */
    isLocal?: boolean;
    /** Indique si la liste est gérée dans le front (retrait de la barre de recherche entre autre) false par défaut */
    isFrontendList?: Boolean;
    /** Liste simple (pas de searchspec, de filtre, de tri) */
    isSimple?: boolean;
    /** Indique si la liste est une liste du dashboard */
    isDashboardList?: boolean;
    /** Indique si on doit afficher la barre de recherche
     * On a déjà ce paramétrage avec isFrontendList/isSimple/isDashboardList mais ce paramétrage permet de forcer */
    isSearchBar?: boolean;
    /** Indique si on doit afficher le scroll infini */
    isInfiniteScroll?: boolean
    /** Dashboard list only : adresse de redirection au clic sur le titre */
    redirect: string;
    isAffichageDeuxColonnes?: boolean = false;
    isFilter?: boolean;

    /** true si les filtres par défaut doivent être supprimés dans le cas où la liste est vide. Ne se fait qu'une fois, ensuite le champ est passé à false */
    removeFiltreOnceIfEmpty?: boolean;

    /** Indique si on affiche le bouton de filtre */
    isFilterVisible?: boolean;
    nbSelectedItems?: number;
    nbObjetsParPage?: number;
    /** Fonction de mapping de la requête pour le loadData */
    mapRequest?: (request: any) => any;
    /** Fonction de mapping du résultat du loadData */
    mapResult?: (result: Result | Page<T>) => Page<T> | Array<T>;
    onRefresh?: (liste: ListView<T, K>, result: any) => void;
    annexData?: { uri: string, onLoad: (result: any) => void }
    /** Objet permettant la gestion du count (uri: endpoint java du count, onLoad: mapping entre le result du get et la valeur du count, loadStatus: Statut actuel du count) */
    loadCount?: { uri: string, onLoad: (result: Result) => number, loadStatus: BehaviorSubject<CountStatus> };
    isLoading?: boolean;
    /** Indicateur de préchargement en cours */
    isPreloading?: boolean;
    /** Données préchargées */
    preloadedData?: BehaviorSubject<Page<T>>;
    alertLevel?: NiveauAlerte;
    loaded: Subject<void> = new Subject<void>();
    sorting?: Sorting;
    /** Indicateur de la persistence des filtres */
    isPersistFilters: boolean = false;
    /** Nom de la classe pour la persistence des filtres */
    className?: string;
    /** Colonnes triables */
    columns?: { key: string, title: string }[];
    /** Initialisation des filtres */
    initFilters?: () => void;
    /** Méthode pour déterminer les spécificités intrinsèques de chaque liste : type de liste, composants, etc. */
    initListCore?: () => void;
    /** Méthode pour déterminer les spécificités de chaque liste : filtres, tri etc. */
    initListSpecific?: (initOnlyColumns?: boolean) => void;
    /** Taille de la liste la dernière fois qu'on a vérifié (pour s'assurer que de nouvelles données sont chargées) */
    previousLength: number = -1;
    /** Indique s'il y a eu des nouvelles données au dernier chargement */
    hasNewData: boolean = true;
    /** Garde en mémoire le count s'il a été exécuté */
    countTotal: number = -1;
    /** Subject de demande de mise à jour de la pagination de la liste */
    updatePagination$: Subject<void> = new Subject<void>();
    /** Texte à afficher lorsque la liste est vide */
    emptyMessage?: string;
    /** Activation de l'affichage de la pagination */
    showPagination: boolean;
    /** Subject indiquant que la liste des résultats a été filtrée côté front et qu'il faut actualiser l'affichage '*/
    onFiltrageFront$: Subject<void> = new Subject<void>();

    /**
     * Rafraichissement de la liste
     */
    refresh?: () => void;

    /**
     * Ajout d'un ou plusieurs éléments à la liste
     * @param item objet(s) concerné(s)
     * @param append Ajout du ou des éléments à la fin de la liste. False par défaut.
     */
    addItem?: (item: T | T[], append?: boolean) => void;

    /**
     * Suppression d'un élément à la liste
     */
    removeItem?: (item: T) => boolean;

    /**
     * Suppression de tous les éléments à la liste
     */
    clear?: () => void;

    /** Liste des actions spécifiques qui doivent apparaitre à la sélection d'un élément */
    listeActionsSpecifiques?: BehaviorSubject<Array<FloatingButtonAction>>;

    /** Liste des objets sélectionnés */
    get listeObjetsSectionnes(): T[] {
        return this.data?.listeResultats?.filter(e => e.isSelected);
    }

    /**
     * Définition de la page de résultats avec une véritable instance pour chaque résultat
     *
     * @param page Page contenant les résultats
     * @param type Type des objets à instancier
     */
    public initDataFrom(page: Page<T>,type: Type<T>) {
        //Copie des informations de la page excepté la liste de résultats
        this.data = (({ listeResultats, ...p }) => { return { listeResultats: null, ...p}; })(page);

        //Conversion de chaque résultat de la liste en instance
        this.data.listeResultats = (page?.listeResultats ?? []).map(item => {
            return ListView.basic2Instance(item,type);
        });
    }

    /**
     * Nombre d'éléments actuellement chargés de la liste
     * @returns {number} Nombre d'éléments chargés
     */
    get nbElementsCharges(): number {
        //Si on a bien les informations sur la page
        if (this.data?.numPage != null && this.data?.nbObjetsParPage != null && this.data?.nbObjetsDansPage != null) {
            //On renvoie le nombre d'objets x le nombre de pages + le nombre d'objets dans la page actuelle
            return (this.data.numPage * this.data.nbObjetsParPage) + this.data.nbObjetsDansPage
        } else {
            //Si on n'a pas les infos, on renvoie 0, tant pis.
            0;
        }
    }

    /**
     * Fonction de conversion d'un objet "basic" vers son instance
     *
     * @param source object "basic" (simple)
     * @param type Type des objets à instancier
     */
    private static basic2Instance = <T>(source: T,type: Type<T>): T => {
        const destinationConstructor: Constructor<T> = type;

        //Copie des données de l'objet dans l'instance
        return Object.assign(new destinationConstructor(),source);
    }

    /**
     * Constructeur
     */
    constructor({
                    title,
                    uri,
                    defaultOrder,
                    component,
                    factory,
                    listeFilters = [],
                    listeStaticFilters = [],
                    listeActions = [],
                    extraOptions = null,
                    isLocal = false,
                    isFrontendList = false,
                    showPagination = true,
                    isSimple = false,
                    isDashboardList = false,
                    isFilter = false,
                    removeFiltreOnceIfEmpty = false,
                    nbObjetsParPage,
                    mapRequest,
                    mapResult,
                    onRefresh,
                    isAffichageDeuxColonnes = false,
                    annexData,
                    loadCount,
                    data = null,
                    listeActionsSpecifiques,
                    isSearchBar,
                    isInfiniteScroll,
                    isFilterVisible,
                    isSearchWhenNoFilter,
                    emptyMessage
                }: { title?: string, uri?: string, defaultOrder?: string, component: Type<K>, factory?: new () => any, listeFilters?: Filter[], listeStaticFilters?: Filter[], listeActions?: Action[], extraOptions?: any, isLocal?: boolean, isFrontendList?: boolean,showPagination?: boolean, isSimple?: boolean, isDashboardList?: boolean, isFilter?: boolean, removeFiltreOnceIfEmpty?: boolean, nbObjetsParPage?: number, mapRequest?: (request: any) => any, mapResult?: (result: any) => any, onRefresh?: (liste: ListView<T, K>, result: any) => void, isAffichageDeuxColonnes?: boolean, annexData?: { uri: string, onLoad: (result: any) => any }, loadCount?: { uri: string, onLoad: (result: Result) => number }, data?: Page<T>, listeActionsSpecifiques?: BehaviorSubject<Array<FloatingButtonAction>>, isSearchBar?: boolean, isInfiniteScroll?: boolean, isFilterVisible?: boolean, isSearchWhenNoFilter?: boolean, emptyMessage?: string }) {
        //Définition des données
        this.title = title;
        this.uri = uri;
        this.defaultOrder = defaultOrder;
        this.component = component;
        this.factory = factory;
        this.listeFilters = listeFilters;
        this.listeStaticFilters = listeStaticFilters;
        this.listeActions = listeActions;
        this.extraOptions = extraOptions;
        this.isLocal = isLocal;
        this.isFrontendList = isFrontendList;
        this.showPagination = showPagination;
        this.isSimple = isSimple;
        this.isDashboardList = isDashboardList;
        this.isFilter = isFilter;
        this.removeFiltreOnceIfEmpty = removeFiltreOnceIfEmpty;
        this.nbObjetsParPage = isLocal ? 10 : nbObjetsParPage;
        this.mapRequest = mapRequest;
        this.mapResult = mapResult;
        this.onRefresh = onRefresh;
        this.isAffichageDeuxColonnes = isAffichageDeuxColonnes;
        this.annexData = annexData;
        if (!!loadCount) {
            this.loadCount = {...loadCount, loadStatus: new BehaviorSubject<CountStatus>(CountStatus.NONE)};
        }
        this.data = data;
        this.listeActionsSpecifiques = listeActionsSpecifiques;
        this.isSearchBar = isSearchBar;
        this.isInfiniteScroll = isInfiniteScroll;
        this.isFilterVisible = isFilterVisible;
        this.isSearchWhenNoFilter = isSearchWhenNoFilter ?? true;
        this.emptyMessage = emptyMessage;
    }

    /**
     * Indique si la liste a bien chargé tous ses éléments
     *
     * @returns {boolean} true si la liste est totalement chargée, sinon false
     */
    isTotalementChargee(): boolean {
        const isChargee = this.data.numPage + 1 >= this.data.nbPagesTotal //Si on a atteint le nombre de pages total
            || this.data.listeResultats.length % (this.nbObjetsParPage ?? nbObjetsParPageDefaut) !== 0 //Si le nombre total d'éléments n'est pas divisible par le nombre d'objets par page (la dernière page n'était pas pleine)
            || !this.hasNewData; //S'il n'y a pas eu de nouveaux éléments au dernier chargement

        //Si la page est totalement chargée
        if (isChargee) {
            //On met à jour le count total
            this.countTotal = this.nbElementsCharges;

            //On indique dans le load que c'est chargé
            if (this.loadCount) {
                this.loadCount.loadStatus.next(CountStatus.LOADED);
            }

            //On met à jour la pagination
            this.updatePagination$.next();
        }

        return isChargee;
    }

    /**
     * Sélectionne ou désélectionne tous les éléments affichés de la liste
     *
     * @param isSelect              true pour tout sélectionner, false pour tout désélectionner
     */
    selectAll(isSelect: boolean): void {
        //Définition de la liste des éléments sélectionnés
        this.data?.listeResultats?.forEach(d => d.isSelected = isSelect);

        //Mise à jour du compteur
        this.nbSelectedItems = isSelect ? this.data?.listeResultats?.length : 0;
        this.updatePagination$.next();
    }
}

/** Constante pour la valeur par défaut du nombre d'objets par page */
export const nbObjetsParPageDefaut: number = 25;

/** Définition d'un type générique permettant de déclarer un constructeur par défaut sur un objet */
type Constructor<T extends {} = {}> = new (...args: any[]) => T;

/** Statut possible pour le chargement du count */
export enum CountStatus {
    NONE,//Rien n'a été fait sur le count
    LOADING,//Le count est en cours de chargement
    LOADED//Le count a bien été chargé
}