import {Component,OnInit,ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {EntrepriseParametres} from '@domain/admin/entreprise/analytique/parametres';
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import {RadioOption} from '@share/component/custom-input/radio-option';
import {finalize} from "rxjs/operators";

/**
 * Onglet paramètres du sous-menu Entreprise/Analytique
 *
 * @author Tom JEAMMET
 * @date 23/02/2023
 */
@Component({
    host: {'data-test-id': 'analytique-parametres'},
    selector: 'analytique-parametres',
    templateUrl: './analytique-parametres.component.html',
    styleUrls: [ './analytique-parametres.component.scss' ]
})
export class AnalytiqueParametresComponent implements OnInit {

    /** Parametres analytique de l'entreprise */
    parametres: EntrepriseParametres;

    /** Formulaire de la page */
    @ViewChild('form') parametresForm: NgForm;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Différentes options de restitution */
    restitutionOptions: RadioOption[] = [
        {
            code: "NORMAL",
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.organigramme.restitutionOption1Label')
        }, {
            code: "RESTREINT",
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.organigramme.restitutionOption2Label')
        }, {
            code: "FIXE",
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.organigramme.restitutionOption3Label')
        }
    ];

    niveauBloquantSuffix: string = this.translateService.instant('admin.entreprise.analytique.parametres.cards.enveloppes.alertBloquantAide');
    niveauNonBloquantSuffix: string = this.translateService.instant('admin.entreprise.analytique.parametres.cards.enveloppes.alertNonBloquantAide');

    /** Différentes options de la gestion de la taxe */
    gestionTaxeOptions: RadioOption[] = [
        {
            code: 1,
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.enveloppes.taxeOption1Label')
        }, {
            code: 3,
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.enveloppes.taxeOption2Label')
        }, {
            code: 2,
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.enveloppes.taxeOption3Label')
        }
    ];

    /** Différentes options de restitution */
    rechercheOptions: RadioOption[] = [
        {
            code: 0,
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.repartitions.rechercheOption1Label')
        }, {
            code: 1,
            label: this.translateService.instant('admin.entreprise.analytique.parametres.cards.repartitions.rechercheOption2Label')
        }
    ];

    /** Pour permettre le pending sur le floating button */
    isSaving: boolean;

    constructor(
        private translateService: TranslateService,
        private analytiqueService: AnalytiqueService,
        private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        //Chargement des parametres analytique entreprise
        this.analytiqueService.loadGeneralites().subscribe(response => {
            if (response?.data) {
                this.parametres = response.data;
            } else {
                //Affichage d'un message d'erreur indiquant que les paramètres n'ont pas pu être chargés
                this.toastrService.error(this.translateService.instant('global.errors.chargement'));
            }
        });

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save()
        }]);
    }

    adminVisibilityChange(adminVisibility: boolean) {
        if (!adminVisibility) {
            this.parametres.adminManagement = false;
        }
    }

    userVisibilityChange(userVisibility: boolean) {
        if (!userVisibility) {
            this.parametres.userManagement = false;
        }
    }

    /**
     * Méthode permettant l'enregistrement en base des paramètres
     */
    save() {
        this.parametres.restitution = this.parametresForm.value.restitution;
        //Si le formulaire est valide alors on enregistre le référentiel
        if(this.parametresForm.valid) {
            this.isSaving = true;

            //Enregistrement en base du référentiel modifié
            this.analytiqueService.saveAnalytiqueParameters({ ...this.parametres })
                .pipe(finalize(() => { this.isSaving = false; }))
                .subscribe(response => {
                if (!response) {
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                } else {
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                }
            });
        } else {
            //Affichage d'un message d'erreur indiquant que les paramètres n'ont pas pu être chargés
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }
}
