import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ODService } from '@components/od/od.service';
import { Result, TypeCodeErreur } from "@domain/common/http/result";
import { Od } from '@domain/od/od';
import { SaisieTemps } from "@domain/od/saisie-temps";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";

/**
 * Popup de saisie des temps
 *
 * @author Laurent SCIMIA
 * @date 31/01/2022
 */
@Component({
	host: {'data-test-id': 'od-generalites-saisie-temps'},
	templateUrl: './od-generalites-saisie-temps.component.html'
})
export class OdGeneralitesSaisieTempsComponent implements OnInit {

	/** Saisie des temps à traiter */
	saisie: SaisieTemps;

	/** Ordre de mission courant */
	od: Od;

	/** true si il y a un chargement back */
	isLoading: boolean = false;

	/** Tableau des types de saisie possible */
	readonly typeSaisie = [
		{id: false,libelle: this.translateService.instant('od.saisieTemps.mission')}
	];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { canModifier: boolean,saisie: SaisieTemps,od: Od, saveSaisieTemps: (idOd: number,saisie: SaisieTemps) => Observable<Result>},
		private translateService: TranslateService,
		private odService: ODService,
		private matDialogRef: MatDialogRef<OdGeneralitesSaisieTempsComponent>,
		private toastr: ToastrService
	) { }

	/** Initialisation */
	ngOnInit(): void {
		//On récupère la saisie
		this.saisie = this.data?.saisie;
		//On récupère l'od
		this.od = this.data?.od;
		//On définit si la saisie en mode transport est autorisée
		this.setSaisieTransportAutorisee();
	}

	/** Définit si la saisie en mode transport est autorisée */
	private setSaisieTransportAutorisee(): void {
		this.odService.countEtapesTransport(this.od.idOd).subscribe(result => {
			if (result?.data?.countEtapes > 0) {
				this.typeSaisie.push({id: true,libelle: this.translateService.instant('od.saisieTemps.transport')});
			}
		});
	}

	/** Enregistrement de la saisie des temps */
	saveSaisie() {
		let momentDebut;
		let momentFin;

		//On récupère la date de début et la date de fin
		if (this.saisie.forfait) {
			momentDebut = moment(this.saisie.debutForfait);
			momentDebut.hour(this.saisie.heureDebForfait.substring(0,2));
			momentDebut.minute(this.saisie.heureDebForfait.substring(3,2));

			momentFin = moment(this.saisie.finForfait);
			momentFin.hour(this.saisie.heureFinForfait.substring(0,2));
			momentFin.minute(this.saisie.heureFinForfait.substring(3,2));
		} else {
			momentDebut = moment(this.saisie.debutReel);
			momentDebut.hour(this.saisie.heureDebReel.substring(0,2));
			momentDebut.minute(this.saisie.heureDebReel.substring(3,2));

			momentFin = moment(this.saisie.finReel);
			momentFin.hour(this.saisie.heureFinReel.substring(0,2));
			momentFin.minute(this.saisie.heureFinReel.substring(3,2));
		}

		//Si la date de début est après la date de fin
		if (momentDebut.isAfter(momentFin)) {
			//Bam erreur !
			this.toastr.error(this.translateService.instant('od.saisieTemps.erreurDate'));
		} else {
			//Tout est correct on enregistre
			this.isLoading = true;
			this.data.saveSaisieTemps(this.data.od.idOd,this.saisie)
				.pipe(finalize(() => {
					//On n'oublie pas de retirer le isLoading à la fin
					this.isLoading = false
				}))
				.subscribe((result) => {
					//Si tout s'est bien passé
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						//On ferme la popup avec un true comme succès
						this.matDialogRef.close(true);
					} else if (result.codeErreur === TypeCodeErreur.ERROR_PERMISSION) {
						//On met une erreur de permission
						this.toastr.error(this.translateService.instant('global.errors.permission'));
					} else {
						//On met une erreur d'enregistrement
						this.toastr.error(this.translateService.instant('global.errors.enregistrement'));
					}
				});
		}
	}
}
