import {User} from '../user/user';

/**
 * Modèle représentant une session
 */
export class Session {
    /** Données **/
    isLogged: boolean;
    /** Indique si l'utilisateur est un Admin */
    isAdmin: boolean;
    /** Indique si l'utilisateur est un Sous-Admin */
    isSousAdmin: boolean;
    /** Indique si la session a été créée suite à la "connexion en tant que" d'un admin ou sous-admin.  */
    isConnectAs: boolean;
    /** Indique si le mot de passe de l'utilisateur est expiré */
    isPasswordExpired: boolean;
    user: User;
    loginError: string | null;
    logoUrl?: string;
    loginLocal?: boolean;

    /** URL de redirection après reloging suite à l'expiration de la session */
    redirect?: string;
}
