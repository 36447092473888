import { Injectable,ComponentFactoryResolver,ApplicationRef,Injector,ComponentRef,EmbeddedViewRef } from '@angular/core';

import { ImageViewerComponent } from './image-viewer.component';

@Injectable()
export class ImageViewerService {
    /**  Référence vers le viewer **/
    private imageViewerRef: ComponentRef<ImageViewerComponent> = null;

    /**
     * Constructeur
     */
    constructor(private componentFactoryResolver: ComponentFactoryResolver,private appRef: ApplicationRef,private injector: Injector) {

    }

    /**
     * Affichage du viewer
     */
    show(url: string, isAlignRight = false) {
        //Création du composant
        this.imageViewerRef = this.componentFactoryResolver.resolveComponentFactory(ImageViewerComponent).create(this.injector);

        //Définition de la source de l'image
        this.imageViewerRef.instance.imageSource = url;

        //Alignement à droite
        this.imageViewerRef.instance.isAlignRight = isAlignRight;

        //Interception de la fermeture
        this.imageViewerRef.instance.onClose.subscribe(() => this.hide());

        //Ajout du composant à l'application
        this.appRef.attachView(this.imageViewerRef.hostView);

        //Ajout du composant à la page
        document.body.appendChild((this.imageViewerRef.hostView as EmbeddedViewRef<ImageViewerComponent>).rootNodes[0] as HTMLElement);
    }

    /**
     * Masquage du viewer
     */
    hide() {
        //Vérification de l'affichage du viewer
        if (this.imageViewerRef) {
            //Retrait du composant de l'application
            this.appRef.detachView(this.imageViewerRef.hostView);
            
            //Suppression du composant
            this.imageViewerRef.destroy();

            //Retrait de la référence
            this.imageViewerRef = null;
        }
    }
}