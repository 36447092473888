import {Component,Input,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Communaute} from "@domain/travel/communaute";
import {ReferentielCommunauteComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/communaute/referentiel-communaute.component";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {filter,finalize,first} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {TravelHubSbtConfigUsedParamConnexion} from "@domain/travel/travel-hub-sbt-config-used-param-connexion";

/**
 * Ligne de la liste du référentiel "Communauté" de l'onglet "Référentiels" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-communaute-list-item'},
    templateUrl: './referentiel-communaute-list-item.component.html'
})
export class ReferentielCommunauteListItemComponent extends ListViewItem<Communaute> implements OnInit {

    /** Options supplémentaires */
    @Input() public extraOptions: {
        parent: ReferentielCommunauteComponent,
        listeSBTConfigUsed: Array<TravelHubSbtConfigUsedParamConnexion>
    };

    /** Code du paramètre associé */
    parametre: string;

    /**
     * Constructeur
     *
     * @param pleaseWaitService Service d'affichage du loading
     * @param confirmService Service de confirmation utilisateur via modale
     * @param translateService Service de traduction
     */
    constructor(private pleaseWaitService: PleaseWaitService,
                private confirmService: ConfirmService,
                private translateService: TranslateService
    ) {
        super();
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        this.parametre = this.extraOptions.listeSBTConfigUsed?.find(c => c.idSBTConfigUsed === this.data.sbtConfigUsed.idSBTConfigUsed).codeParam;
    }

    /**
     * Suppression de la communauté
     */
    deleteCommunaute(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() =>{
                //Modale de chargement
                let pleaseWaitRef = this.pleaseWaitService.show();

                //Suppression
                this.extraOptions.parent.deleteReferentiel(this.data)
                    .pipe(first(),finalize(() => pleaseWaitRef.close()))
                    .subscribe();
            });
    }

}