<div class="lvi-content">
    <div class="avatar">
        {{ extraOptions.avatar }}
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
        </ul>
    </div>
</div>