<mat-card>
    <mat-card-title>
        <span [translate]="'admin.parametres.demat.pieceJointes.local.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="row" *ngIf="(pieceJointeService.getPieceJointeLocalPath() | async) as localPath">
            <div class="col-md-6">
                <div class="row">
                    <custom-input
                            class=""
                            customType="input"
                            ngDefaultControl
                            name="local"
                            [(ngModel)]="localPath.path"
                            libelle="admin.parametres.demat.pieceJointes.local.stockage"
                            [lCol]="5" [rCol]="7"
                            [postTooltip]="'admin.parametres.demat.pieceJointes.local.tooltip' | translate"
                            [disabled]="localPath.azureStorage"
                    ></custom-input>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>