import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VoyagesReferentielsCartesVoyageursItemComponent } from "@components/admin/voyages/referentiels/cartes-voyageurs/voyages-referentiels-cartes-voyageurs-item/voyages-referentiels-cartes-voyageurs-item.component";
import { CartesVoyagesDto } from "@domain/admin/voyages/referentiels/cartesVoyagesDto";
import { ListView, TypeComparaison } from "@domain/common/list-view";
import { Sorting } from "@domain/common/list-view/sorting";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

/**
 * Composant permettant la gestion et l'affichage des cartes voyageurs
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-cartes-voyageurs'},
    selector: 'voyages-referentiels-cartes-voyageurs',
    templateUrl: './voyages-referentiels-cartes-voyageurs.component.html'
})
export class VoyagesReferentielsCartesVoyageursComponent implements OnInit {
    /** Liste des cartes voyageurs, pour utiliser le composant ListViewComponent */
    listeCartesVoyages: ListView<CartesVoyagesDto, VoyagesReferentielsCartesVoyageursItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param router Router de l'application
     */
    constructor(
        protected translateService: TranslateService,
        private toastrService: ToastrService,
        public router: Router,
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Création de la liste des cartes voyageurs
        this.listeCartesVoyages = new ListView<CartesVoyagesDto, VoyagesReferentielsCartesVoyageursItemComponent>({
            uri: `/controller/TravelHubReferentiel/listeAllCartesVoyageurs`,
            title: this.translateService.instant('admin.voyages.referentiels.onglets.cartesVoyageurs'),
            component: VoyagesReferentielsCartesVoyageursItemComponent,
            isFilter: true,
            defaultOrder: 'libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.voyages.referentiels.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'typePrestation',
                    title: this.translateService.instant('admin.voyages.referentiels.prestation'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: "0",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
                    },{
                        code: "1",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
                    },{
                        code: "2",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
                    },{
                        code: "3",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
                    },{
                        code: "4",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
                    },{
                        code: "5",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
                    },{
                        code: "7",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
                    }]
                },{
                    clef: 'code',
                    title: this.translateService.instant('admin.voyages.referentiels.code'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'isCustom',
                    title: this.translateService.instant('admin.voyages.referentiels.personnalise'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: "0",
                        libelle: this.translateService.instant('confirmation.non')
                    },{
                        code: "1",
                        libelle: this.translateService.instant('confirmation.oui')
                    }]
                },{
                    clef: 'isActif',
                    title: this.translateService.instant('admin.voyages.referentiels.actif'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: "0",
                        libelle: this.translateService.instant('confirmation.non')
                    },{
                        code: "1",
                        libelle: this.translateService.instant('confirmation.oui')
                    }]
                },{
                    clef: 'typeCarte',
                    title: this.translateService.instant('admin.voyages.referentiels.infos.typeCarte'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: "0",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.fidelite')
                    },{
                        code: "1",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.abonnement')
                    },{
                        code: "2",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.supplier')
                    }]
                }
            ]
        });

        //Définition des colonnes de tri
        this.listeCartesVoyages.columns = [
            { key: 'libelle', title: 'admin.voyages.referentiels.libelle' },
            { key: 'prestation', title: 'admin.voyages.referentiels.prestation' },
            { key: 'code', title: 'admin.voyages.referentiels.code' },
            { key: 'personnalise', title: 'admin.voyages.referentiels.personnalise' },
            { key: 'typeCarte', title: 'admin.voyages.referentiels.infos.typeCarte' },
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeCartesVoyages.sorting = new Sorting(this.listeCartesVoyages.columns, "libelle");

    }
}