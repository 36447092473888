import {Tolerance} from "../typecharge/tolerance";
import {TypeNature} from "@domain/typecharge/type-nature";

/**
 * Représente une ligne de rapprochement entre un montant de devis et un montant de facture
 *
 * @author Laurent Convert
 * @date 26/01/2023
 */
export class LigneRapprochement {
    idObjet: number;
    level: Level;
    nature?: TypeNature;
    reference?: string;
    reference2?: string;
    montantDevis?: number;
    montantFacture?: number;
    montantEcart?: number;
    ordre?: string;
    unfolded?: boolean;
    tolerance?: Tolerance;
    
    //Front only
    childs?: Array<LigneRapprochement>;
}

/**
 * Enumération représentant le niveau de récupération des lignes de rapprochement
 */
export enum Level {
    NATURE = 'NATURE',
    FACTURE = 'FACTURE',
    LIGNE_FACTURE = 'LIGNE_FACTURE'
}