import {Component,OnInit} from '@angular/core';
import {TypeGeolocalisation} from "@domain/geolocalisation/typeGeolocalisation";
import {TranslateService} from "@ngx-translate/core";
import {GeographieView} from "@domain/geographie/geographieView";
import {Adresse} from "@domain/profil/adresse";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {GeolocalisationParams,TypeCalculDistancier,TypeCiblage} from "@domain/geolocalisation/geolocalisationParams";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {ToastrService} from "ngx-toastr";
import {TypeCodeErreur} from "@domain/common/http/result";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {Unite} from "@domain/prestation/unite";
import {finalize,first} from "rxjs/operators";

/**
 * Composant de l'onglet Géolocalisation
 *
 * @author Angeline Ha
 * @date 19/02/2024
 */
@Component({
    selector: 'geolocalisation',
    templateUrl: './geolocalisation.component.html'
})
export class GeolocalisationComponent implements OnInit {

    /** Accès à l'enum dans la vue */
    readonly TypeCiblage = TypeCiblage;
    readonly TypeGeolocalisation = TypeGeolocalisation;
    readonly NiveauAlerte = NiveauAlerte;

    /** Paramètres de géolocalisation */
    geolocalisationParams: GeolocalisationParams;

    /** Indique la présence d'une licence ViaMichelin */
    isLicenceVM: boolean;

    /** Indique si le paramétrage est sauvegardé donc valide et testable */
    isParamsValid: boolean = false;

    /** Liste des types de geolocalisation disponibles */
    listeTypeGeolocalisation: OptionTypeGeolocalisation[];

    /** Liste des types de calcul disponibles */
    listeTypeCalculDistancier: OptionTypeCalculDistancier[];

    /** Liste des types de ciblage disponibles */
    listeTypeCiblage: OptionTypeCiblage[];

    /** Modèle pour l'autocomplete de la ville de départ */
    selectedDepartVille: GeographieView

    /** Modèle pour l'autocomplete de l'adresse de départ */
    selectedDepartAdresse: Adresse;

    /** Modèle pour l'autocomplete de la ville d'arrivée */
    selectedArriveeVille: GeographieView

    /** Modèle pour l'autocomplete de l'adresse d'arrivée */
    selectedArriveeAdresse: Adresse;

    /** Distance */
    distance: number;

    /** Unite */
    unite: Unite;

    /** Alerte */
    alerte: Alerte;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Indicateur de chargement */
    isPending: boolean;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param geographieService Service géographie
     * @param toastrService Service des toasts
     */
    constructor(private translateService: TranslateService,
                private geographieService: GeographieService,
                private toastrService: ToastrService
    ) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération de la configuration actuelle
        this.geographieService.loadGeolocalisationParams().subscribe(result => {
            this.geolocalisationParams = result.data.params;
            this.isLicenceVM = result.data.isLicenceVM;
            this.isParamsValid = true;

            //Définition de la liste des geolocalisation pour le select
            this.listeTypeGeolocalisation = [
                {
                    id: TypeGeolocalisation.DISABLED,
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.disabled')
                }, {
                    id: TypeGeolocalisation.GOOGLE_MAPS,
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.maps')
                }
            ];

            //Ajout de l'option ViaMichelin s'il y a une licence
            if (this.isLicenceVM) {
                this.listeTypeGeolocalisation.push({
                    id: TypeGeolocalisation.VIA_MICHELIN,
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.michelin')
                });
            }
        });

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveGeolocalisation()
        }]);

        //Définition du message d'alerte
        this.alerte = {
            niveau: NiveauAlerte.WARNING,
            message: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.test.message')
        };

        //Définition de l'unité de la distance
        this.unite = {
            idUnite: 1,
            typeDistance: 1,
            libelle: 'km'
        };

        //Définition de la liste de types de calcul pour le select
        this.listeTypeCalculDistancier = [
            {
                id: TypeCalculDistancier.CONSEIL_SITE,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.conseille')
            }, {
                id: TypeCalculDistancier.COURT,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.court')
            }, {
                id: TypeCalculDistancier.RAPIDE,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.rapide')
            }
        ];

        //Définition de la liste des types de ciblage pour le select
        this.listeTypeCiblage = [
            {
                id: TypeCiblage.ADRESSE,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.adresse')
            }, {
                id: TypeCiblage.VILLE,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.geolocalisation.configuration.ville')
            }
        ];
    }

    /**
     * Lorsque l'on change le service utilisé
     */
    onGeolocalisationChange() {
        //La configuration n'est pas valide tant qu'elle n'a pas été sauvegardée
        this.isParamsValid = false;

        //Si ViaMichelin
        if (this.geolocalisationParams.typeGeolocalisation === TypeGeolocalisation.VIA_MICHELIN) {
            //Type de calcul 'conseillé par le site' par défaut
            this.geolocalisationParams.typeCalculDistancier = TypeCalculDistancier.CONSEIL_SITE;
        } else {
            //Pas de choix dans le mode de calcul sinon
            this.geolocalisationParams.typeCalculDistancier = null;
        }

        //Remise à zéro de la distance
        this.distance = null;
    }

    /**
     * Lorsque l'on change le niveau de ciblage
     */
    onCiblageChange() {
        //La configuration n'est pas valide tant qu'elle n'a pas été sauvegardée
        this.isParamsValid = false;

        //Remise à zéro de la distance
        this.distance = null;
    }

    /**
     * Lorsque l'on change le mode de calcul
     */
    onCalculChange() {
        //La configuration n'est pas valide tant qu'elle n'a pas été sauvegardée
        this.isParamsValid = false;

        //Remise à zéro de la distance
        this.distance = null;
    }

    /**
     * Lorsque l'on change la ville de départ
     */
    onDepartVilleChange() {
        //On vérifie qu'on a bien une ville d'arrivée
        if (this.selectedArriveeVille) {
            //Recalcul de la distance
            this.geographieService.calculDistance(this.selectedDepartVille.id,this.selectedArriveeVille.id,0).subscribe(result => {
                if (result.codeErreur===TypeCodeErreur.NO_ERROR) {
                    this.distance = result.data.distance;
                }
            });
        }
    }

    /**
     * Lorsque l'on change la ville d'arrivée
     */
    onArriveeVilleChange() {
        //On vérifie qu'on a bien une ville de départ
        if (this.selectedDepartVille) {
            //Recalcul de la distance
            this.geographieService.calculDistance(this.selectedDepartVille.id,this.selectedArriveeVille.id,0).subscribe(result => {
                if (result.codeErreur===TypeCodeErreur.NO_ERROR) {
                    this.distance = result.data.distance;
                }
            });
        }
    }

    /**
     * Lorsque l'on change l'adresse de départ
     */
    onDepartAdresseChange() {
        //On vérifie qu'on a bien une adresse d'arrivée
        if (this.selectedArriveeAdresse) {
            //Recalcul de la distance
            this.geographieService.calculDistance(this.selectedDepartAdresse.idAdresse,this.selectedArriveeAdresse.idAdresse,1).subscribe(result => {
                if (result.codeErreur===TypeCodeErreur.NO_ERROR) {
                    this.distance = result.data.distance;
                }
            });
        }
    }

    /**
     * Lorsque l'on change l'adresse d'arrivée
     */
    onArriveeAdresseChange() {
        //On vérifie qu'on a bien une adresse de départ
        if (this.selectedDepartAdresse) {
            //Recalcul de la distance
            this.geographieService.calculDistance(this.selectedDepartAdresse.idAdresse,this.selectedArriveeAdresse.idAdresse,1).subscribe(result => {
                if (result.codeErreur===TypeCodeErreur.NO_ERROR) {
                    this.distance = result.data.distance;
                }
            });
        }
    }

    /**
     * Sauvegarde de la configuration
     */
    saveGeolocalisation() {
        //Début de la sauvegarde
        this.isPending = true;

        //Sauvegarde de la configuration
        this.geographieService.saveGeolocalisationParams(this.geolocalisationParams)
            .pipe(first(), finalize(() => this.isPending = false))
            .subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Configuration validée
                    this.isParamsValid = true;

                    //Toast de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'))
                } else {
                    //Toast d'erreur
                    TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE, this.translateService, this.toastrService);
                }
            });
    }
}

/**
 * Modèle des options de type de geolocalisation
 */
export interface OptionTypeGeolocalisation {
    id: TypeGeolocalisation,
    libelle: string,
}

/**
 * Modèle des options de mode de calcul
 */
export interface OptionTypeCalculDistancier {
    id: TypeCalculDistancier,
    libelle: string,
}

/**
 * Modèle des options de niveau de ciblage
 */
export interface OptionTypeCiblage {
    id: TypeCiblage,
    libelle: string,
}
