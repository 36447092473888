import {Component,EventEmitter,Input,OnDestroy,OnInit,Output,ViewChild} from '@angular/core';
import {first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ListView} from '@domain/common/list-view';
import {DocumentListItemComponent} from './document-list-item.component';
import {DocumentService} from './document.service';
import {SettingsGlobalState} from '@domain/settings/settings';
import {Observable,Subscription} from "rxjs";
import {DocumentUploaderComponent} from './document-uploader.component';
import {ToastrService} from "ngx-toastr";
import {Page} from "@domain/common/http/list-result";
import {Document} from "@domain/document/document";

/**
 * Composant de gestion de la liste de document du profil
 */
@Component({
    selector: 'document-list',
    host: {'data-test-id': 'document-list'},
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit,OnDestroy {
    /** Identifiant de l'objet auquel sont joints les documents */
    @Input() idObjet: number;

    /** Identifiant de l'objet qui rassemble les documents */
    @Input() idObjetForPJ: number;

    /** Identifiant du collaborateur associé au document */
    @Input() idCollaborateur?: number;

    /** Contexte d'utilisation des documents */
    @Input() context: string;

    /** Permet de mettre les documents en pending au lieu d'enregistrer immédiatement */
    @Input() isUploadToPending: boolean = false;

    /** Type d'affichage (avec ou sans titre/filtres) */
    @Input() isSimpleDocumentList?: boolean = false;

    /** Activation de l'ajout */
    @Input() canAddDocument?: boolean = true;

    /** Activation de la suppression d'un document */
    @Input() canDeleteDocument?: boolean = true;

    /** Paramétrage */
    @Input() settings: SettingsGlobalState;

    /** uri utilisée si différente de celle par défaut */
    @Input() uri: string;

    /** Évènement généré à la suite de la suppression d'un document */
    @Output() onDocumentRemoved: EventEmitter<string> = new EventEmitter<string>();

    /** Évènement généré à la suite de l'upload + lien d'un document */
    @Output() onDocumentUploaded: EventEmitter<Document> = new EventEmitter<Document>();

    /** Données */
    liste: ListView<any, DocumentListItemComponent>;

    /** Message d'erreur custom à afficher */
    @Input() errorAlreadyUsedMessage: string = null;

    /** Composant enfant document uploader */
    @ViewChild(DocumentUploaderComponent)
    documentUploaderComponent: DocumentUploaderComponent;

    /** Extensions autorisées */
    extensions: string = "";

    /** File picker simple */
    @Input() isBasicFilePicker?: boolean = false;

    /** Liste sans backend */
    @Input() isFrontendList?: boolean = false;

    /** Fichier simple sélectionné */
    basicFile: File;

    /** Mise à jour simple de la liste (sans appel au backend) */
    private _frontendList: any[];
    @Input() set frontendList(liste: any[]) {
        this._frontendList = liste;
        this.setFrontendList(liste);
    }

    /** Étiquettes personnalisées pour les items */
    @Input() customTags: (data: any) => { libelle: string, value: string }[];

    /** Titre de la liste */
    @Input() title: string;

    /** Titre du cadre d'upload */
    @Input() uploadTitle: string = 'document.actions.ajouterPiecesJointes';

    /** Liste clickable */
    @Input() isClickable?: boolean = true;

    @Input() refreshList$: Observable<void>;
    onRefreshListSub: Subscription;

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private documentService: DocumentService) {
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        let uri: string = this.uri;

        //Si la liste n'est pas gérée dans le front
        if (!this.isFrontendList)  {
            //Paramètres
            const contextesAnnexesParams: string = this.buildContextesAnnexesParams();

            //On initialise une uri si on n'a pas d'uri spécifié
            if (uri == null) {
                if (!!contextesAnnexesParams) {
                    uri = `/controller/Document/searchDocumentContexts?contexte=${this.context}&contextes_annexes=${contextesAnnexesParams}&id_objet=${this.idObjet}`;
                } else {
                    uri = `/controller/Document/searchDocumentContexts?contexte=${this.context}&id_objet=${this.idObjet}`;
                }
            } else {
                uri = uri + this.idObjet;
            }
        }

        //Définition de la liste
        this.liste = new ListView<any, DocumentListItemComponent>({
            uri,
            component: DocumentListItemComponent,
            nbObjetsParPage: 5,
            defaultOrder: '-dateCreation',
            isLocal: true,
            isFrontendList: this.isFrontendList,
            extraOptions: {
                idObjet: this.idObjet,
                context: this.context,
                canDeleteDocument: this.canDeleteDocument,
                errorAlreadyUsedMessage: this.errorAlreadyUsedMessage,
                onDelete: this.onDelete.bind(this),
                customTags: this.customTags,
                isClickable: this.isClickable
            },
            title: !this.isSimpleDocumentList ? this.translateService.instant('document.liste.title') : null,
            listeFilters: !this.isSimpleDocumentList ? [{
                clef: 'libelle',
                isDefault: true
            }, {
                clef: 'fichier',
                isDefault: true
            }, {
                clef: 'userInfo',
                isDefault: true
            }] : []
        });

        //Si la liste est gérée dans le front
        if (this.isFrontendList) {
            //Mise à jour de celle-ci
            this.setFrontendList(this._frontendList);
        }

        //Extensions autorisées
        if (this.settings?.listeExtensions) {
            this.settings.listeExtensions.forEach(ext => this.extensions += "." + ext + ",");
        } else {
            this.extensions = "*/*";
        }

        //Abonnement aux demandes de rafraichissement de la liste
        this.onRefreshListSub = this.refreshList$?.subscribe(() => {
            this.liste.refresh();
        });
    }

    ngOnDestroy() {
        this.onRefreshListSub?.unsubscribe();
    }

    /**
     * Mise à jour manuelle de la liste
     *
     * @param liste nouvelle liste
     */
    setFrontendList(liste: any[]) {
        if (liste && this.liste) {
            this.liste.data = {
                numPage: 0,
                nbObjetsDansPage: liste.length,
                nbObjetsTotal: liste.length,
                nbObjetsParPage: liste.length,
                nbPagesTotal: 1,
                listeResultats: liste
            } as Page<any>;
        }
    }

    /**
     * Construction des paramètres pour la recherche de documents
     *
     * @return contextes annexes de documents
     */
    buildContextesAnnexesParams(): string {
        //Dans le cas du contexte PROFIL, on va chercher des contextes annexes de documents
        if (this.context === 'PROFIL') {
            return 'PROFIL_COMPTE,PROFIL_VEHICULE,PROFIL_VEHICULE_CARTE_GRISE,PROFIL_VEHICULE_ASSURANCE,PROFIL_VEHICULE_AUTORISATION,PROFIL_CARTE,PROFIL_DOCUMENT';
        }
        return null;
    }

    /**
     * Renvoi True si un ou plusieurs documents sont en attente d'être liés à leur objet
     */
    hasPendingDocumentToLink(): boolean {
        return this.documentService.listeDocumentToLink.length > 0;
    }

    /**
     * Lie les documents en attente
     *
     * @param idObjet Identifiant de l'objet auquel les documents doivent être liés
     * @param context Context du document link, si null le context global est utilisé
     */
    linkAllPendingDocument(idObjet: number, context: string = null): Observable<boolean> {
        return this.documentService.linkAllPendingDocument(idObjet,context ?? this.context);
    }

    /**
     * Finalisation de l'upload d'un fichier
     */
    onItemUploaded({result}) {
        if (result?.data?.document) {
            //Ajout du document à la liste
            this.liste.addItem(result.data.document);

            //Vérification de la présence de l'identifiant de l'objet.
            //Dans le cas d'une création l'objet n'existe pas encore, il faudra alors appeler la fonction linkDocumentToObject manuellement après son enregistrement
            if (this.idObjet && !this.isUploadToPending) {
                //Liaison du document à son objet
                this.documentService.linkDocumentToObject(this.context, this.idObjet, result.data.document)
                    .pipe(first())
                    .subscribe(() => {
                        this.onDocumentUploaded.emit(result.data.document);
                    });
            } else {
                this.documentService.listeDocumentToLink.push(result.data.document);

                this.onDocumentUploaded.emit(result.data.document);
            }
        }
    }

    /**
     * Event de suppression de l'item par le nom
     */
    onDelete(name: string): void {
        this.documentUploaderComponent?.onItemRemoved(name);
        //Remontée de l'événement au composant parent
        this.onDocumentRemoved?.emit(name);
    }

    /**
     * Sélection d'un fichier simple
     *
     * @param event évènement de la sélection
     */
    onBasicFileChanged(event: any): void {
        //S'il y a un fichier
        if (event.target.files[0]) {
            //Vérification du fichier
            this.fileCheck(event.target.files[0]);
        } else {
            //Sinon suppression du fichier
            this.onDeleteBasicFile(null);
        }
    }
    /**
     * Suppression du fichier
     *
     * @param event évènement de suppression
     */
    onDeleteBasicFile(event: MouseEvent): void {
        //Interception de la propagation de l'évènement
        event?.stopPropagation();
        event?.preventDefault();

        //Sinon suppression du fichier
        this.basicFile = undefined;
    }

    /**
     * Survol souris avec un fichier
     *
     * @param event évènement de survol souris avec un fichier
     */
    onDragOver(event: DragEvent): void {
        //Interception de la propagation de l'évènement
        event.stopPropagation();
        event.preventDefault();
    }

    /**
     * Largage du fichier
     *
     * @param event évènement de largage du fichier
     */
    onDrop(event: DragEvent): void {
        //Interception de la propagation de l'évènement
        event.stopPropagation();
        event.preventDefault();

        //Si l'évènement contient des données
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            //Vérification du fichier
            this.fileCheck(<File>event.dataTransfer.items[0].getAsFile());
        }
    }

    /**
     * Vérification du fichier
     *
     * @param file fichier concerné
     */
    fileCheck(file: File): void {
        //Initialisation
        const extension: string = file.name.split('.').pop().toLowerCase();

        //Si le fichier a la bonne extension
        if (this.settings?.listeExtensions?.some(e => e == extension)) {
            //Stockage du fichier
            this.basicFile = file;
        } else {
            //Sinon suppression du fichier
            this.basicFile = undefined;

            //Et on affiche le message d'erreur extension invalide
            this.toastrService.error(this.translateService.instant('global.errors.extensionInvalide', { fileName: file.name }));
        }
    }
}
