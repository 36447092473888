import {Component,OnInit} from "@angular/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {AnalytiqueOrganigrammeItemComponent} from "@components/admin/entreprise/analytique/organigramme/organigramme-item/analytique-organigramme-item.component";
import {Sorting} from "@domain/common/list-view/sorting";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {BehaviorSubject} from "rxjs";
import {Orga} from "@domain/admin/entreprise/analytique/orga";

/**
 * Composant d'affichage de l'organigramme
 */
@Component({
    host: {'data-test-id': 'analytique-organigramme'},
    selector: 'analytique-organigramme',
    templateUrl: './analytique-organigramme.component.html'
})
export class AnalytiqueOrganigrammeComponent implements OnInit {

    /** Liste des services de niveau 0 pour l'organigramme */
    listeOrganigramme: ListView<Orga, AnalytiqueOrganigrammeItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router le routeur Angular
     */
    constructor(
        private translateService: TranslateService,
        private router: Router
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Initialisation de la liste des services de niveau 0 pour l'organigramme
        this.listeOrganigramme = new ListView<Orga,AnalytiqueOrganigrammeItemComponent > ({
            uri: `/controller/Orga/niveau0Page`,
            title: this.translateService.instant('admin.entreprise.analytique.organigramme.title'),
            component: AnalytiqueOrganigrammeItemComponent,
            isFilter: true,
            defaultOrder: 'numService',
            listeFilters: [
                {
                    clef: 'code',
                    title: this.translateService.instant('admin.entreprise.analytique.organigramme.filters.code'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'numService',
                    title: this.translateService.instant('admin.entreprise.analytique.organigramme.numero'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'libelle',
                    title: this.translateService.instant('admin.entreprise.analytique.organigramme.filters.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'isAffectable',
                    title: this.translateService.instant('admin.entreprise.analytique.organigramme.filters.affectable'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                },{
                    clef: 'isImputable',
                    title: this.translateService.instant('admin.entreprise.analytique.organigramme.filters.imputable'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                },{
                    clef: 'actif',
                    title: this.translateService.instant('admin.entreprise.analytique.organigramme.filters.actif'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                }
            ]
        });

        //Définition des colonnes de tri
        this.listeOrganigramme.columns = [
            { key: 'code', title: 'admin.entreprise.analytique.organigramme.filters.code' },
            { key: 'numService', title: 'admin.entreprise.analytique.organigramme.numero' },
            { key: 'libelle', title: 'admin.entreprise.analytique.organigramme.filters.libelle' },
            { key: 'isAffectable', title: 'admin.entreprise.analytique.organigramme.filters.affectable' },
            { key: 'isImputable', title: 'admin.entreprise.analytique.organigramme.filters.imputable' },
            { key: 'isCache', title: 'admin.entreprise.analytique.organigramme.filters.actif' }
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeOrganigramme.sorting = new Sorting(this.listeOrganigramme.columns, "code");

        //Persistence des filtres
        this.listeOrganigramme.isPersistFilters = true;
        this.listeOrganigramme.className = 'AnalytiqueOrganigrammeComponent';

		//Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.create(),
        }]);
    }

    /**
     * Méthode appelée lors de la création d'un nouveau service
     */
    create() {
        this.router.navigate(['Admin/Entreprise/Analytique/Services/-1']);
    }
}