/**
 * Modèle représentant un fournisseur
 */
 export class Fournisseur {
    idFournisseur: number;
	raisonSociale: string;
    devise: string;

    constructor(dto: any) {
        if (dto) {
            this.idFournisseur = dto.idFournisseur;
            this.raisonSociale = dto.raisonSociale;
            this.devise = dto.devise;
        }
    }
}