import {Component} from '@angular/core';

import {AutocompleteSearchItemComponent} from '../autocomplete-search-item.component';
import {AdresseListItem} from "@domain/profil/adresse";

/**
 * Composant d'affichage d'une ligne dans la popup de recherche avancée 'Adresse'
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'adresse-search-item'},
    templateUrl: './adresse.search-item.html',
})
export class AdresseSearchItemComponent extends AutocompleteSearchItemComponent<AdresseListItem> {
}