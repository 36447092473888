<card-alerte *ngIf="listeAlertes?.length" [niveau]="niveauAlerteProfil" [isCard]="true">
    <preview-alerte *ngFor="let alerte of listeAlertes" [alerte]="alerte"></preview-alerte>
</card-alerte>
<mat-card *ngIf="isDroitProfilVoyageur">
    <mat-card-title>
        <span [translate]="'admin.entreprise.utilisateurs.detail.profil.profilConnexion.title'">
        </span>
        <span class="actions">
            <button mat-icon-button (click)="onClickSynchronize()" [disabled]="!profilVoyageur?.saisieObligatoire.profilVoyageurValide"
                    [matTooltip]="'admin.entreprise.utilisateurs.detail.profil.profilConnexion.actionTooltip' | translate">
                <mat-icon>sync</mat-icon>
            </button>
        </span>
    </mat-card-title>
    <mat-card-content>
        <please-wait *ngIf="idProfilConnexionSelected == null"></please-wait>
        <form *ngIf="idProfilConnexionSelected != null">
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="profilConnexion"
                        name="profilConnexion"
                        customType="select"
                        [(ngModel)]="idProfilConnexionSelected"
                        libelle="admin.entreprise.utilisateurs.detail.profil.profilConnexion.profilConnexion"
                        [selectOptions]="listeProfilConnexion"
                        optionDisplay="libelle"
                        optionValue="idProfilConnexion"
                        (ngModelChange)="onProfilConnexionChange()">
                </custom-input>
            </div>
            <ng-container *ngFor="let pvParam of listePvParams">
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            [id]="'communauteTest' + pvParam.idSbt"
                            [name]="'communaute' + pvParam.idSbt"
                            customType="select"
                            [(ngModel)]="pvParam.idCommunauteSelected"
                            [libelle]="'admin.entreprise.utilisateurs.detail.profil.profilConnexion.communaute' | translate:{libelle:pvParam.libelle}"
                            [selectOptions]="pvParam.listeCommunaute"
                            optionDisplay="libelle"
                            optionValue="idCommunaute"
                            [disabled]="!pvParam.listeCommunaute?.length"
                            [postTooltip]="!pvParam.listeCommunaute?.length && ('admin.entreprise.utilisateurs.detail.profil.profilConnexion.aucune' | translate)">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            [id]="'rlsTest' + pvParam.idSbt"
                            [name]="'rls' + pvParam.idSbt"
                            customType="select"
                            [(ngModel)]="pvParam.idRlsSelected"
                            [libelle]="'admin.entreprise.utilisateurs.detail.profil.profilConnexion.rls' | translate:{libelle:pvParam.libelle}"
                            [selectOptions]="pvParam.listeRLS"
                            optionDisplay="libelle"
                            optionValue="idRLS"
                            [disabled]="!pvParam.listeRLS?.length"
                            [postTooltip]="!pvParam.listeRLS?.length && ('admin.entreprise.utilisateurs.detail.profil.profilConnexion.aucune' | translate)">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            [id]="'categorieTest' + pvParam.idSbt"
                            [name]="'categorie' + pvParam.idSbt"
                            customType="select"
                            [(ngModel)]="pvParam.idCategorieVoyageurSelected"
                            [libelle]="'admin.entreprise.utilisateurs.detail.profil.profilConnexion.categVoyageur' | translate:{libelle:pvParam.libelle}"
                            [selectOptions]="pvParam.listeCategorieVoyageur"
                            optionDisplay="libelle"
                            optionValue="idCategorie"
                            [disabled]="!pvParam.listeCategorieVoyageur?.length"
                            [postTooltip]="!pvParam.listeCategorieVoyageur?.length && ('admin.entreprise.utilisateurs.detail.profil.profilConnexion.aucune' | translate)">
                    </custom-input>
                </div>
            </ng-container>
        </form>
    </mat-card-content>
</mat-card>
<mat-card *ngIf="isDroitProfilVoyageur">
    <mat-card-title>
        <span [translate]="'admin.entreprise.utilisateurs.detail.profil.infoVoyageur.title'"
              [class.error]="infoVoyageurAlertLevel === NiveauAlerte.ERROR"></span>
    </mat-card-title>
    <mat-card-content>
        <please-wait *ngIf="!infoVoyageur"></please-wait>
        <form #formInfoVoyageur="ngForm" *ngIf="infoVoyageur">
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="nomUsage"
                        name="nomUsage"
                        customType="input"
                        [(ngModel)]="infoVoyageur.nom"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.nomUsage"
                        [required]="true">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="dateNaissance"
                        name="dateNaissance"
                        customType="date"
                        [ngModel]="infoVoyageur.dateNaissance"
                        [(customModel)]="infoVoyageur.dateNaissance"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.dateNaissance"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="nationalite"
                        name="nationalite"
                        customType="autocomplete"
                        autocompleteType="geographie"
                        [autocompleteFilter]="{ listeTypes: ['PAYS'] }"
                        optionDisplay="libelle"
                        [(ngModel)]="infoVoyageur.paysNationalite"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.nationalite"
                        (onChange)="onPaysNationaliteChange()">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        *ngIf="!infoVoyageur.newVille"
                        ngDefaultControl
                        id="villeNaissance"
                        name="villeNaissance"
                        customType="autocomplete"
                        autocompleteType="geographie"
                        [autocompleteFilter]="{ listeTypes: ['VILLE'] }"
                        optionDisplay="libelle"
                        [(ngModel)]="infoVoyageur.villeNaissance"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeNaissance"
                        (onChange)="onVilleNaissanceChange()">
                </custom-input>
                <custom-input
                        *ngIf="infoVoyageur.newVille"
                        ngDefaultControl
                        id="villeNaissanceLibelle"
                        name="villeNaissanceLibelle"
                        customType="input"
                        [(ngModel)]="infoVoyageur.ville"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeNaissance"
                        [maxlength]="2000">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="villeInconnue"
                        name="villeInconnue"
                        customType="checkbox"
                        [ngModel]="infoVoyageur.newVille"
                        [(customModel)]="infoVoyageur.newVille"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeInconnue"
                        postLibelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeInconnueRight"
                        (ngModelChange)="onVilleInconnueChange()">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="codePostal"
                        name="codePostal"
                        customType="input"
                        [(ngModel)]="infoVoyageur.codePostal"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.codePostal"
                        [postTooltip]="!infoVoyageur.newVille ? ('admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeInconnueTooltip' | translate) : null"
                        [maxlength]="10"
                        [required]="infoVoyageur.newVille"
                        [disabled]="!infoVoyageur.newVille">
                </custom-input>
                <custom-input
                        *ngIf="!infoVoyageur.newVille"
                        ngDefaultControl
                        id="paysNaissanceDisabled"
                        name="paysNaissanceDisabled"
                        customType="input"
                        [(ngModel)]="infoVoyageur.paysNaissance"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.paysNaissance"
                        [postTooltip]="'admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeInconnueTooltip' | translate"
                        [disabled]="true">
                </custom-input>
                <custom-input
                        *ngIf="infoVoyageur.newVille"
                        ngDefaultControl
                        id="paysNaissance"
                        name="paysNaissance"
                        customType="autocomplete"
                        autocompleteType="geographie"
                        [autocompleteFilter]="{ listeTypes: ['PAYS'] }"
                        optionDisplay="libelle"
                        [(ngModel)]="infoVoyageur.paysNaissance"
                        libelle="admin.entreprise.utilisateurs.detail.profil.infoVoyageur.paysNaissance"
                        [postTooltip]="!infoVoyageur.newVille ? ('admin.entreprise.utilisateurs.detail.profil.infoVoyageur.villeInconnueTooltip' | translate) : null"
                        (onChange)="onPaysNaissanceChange()"
                        [required]="infoVoyageur.newVille">
                </custom-input>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<list-view *ngIf="listeTelephones && isDroitContactUrgence" [liste]="listeTelephones"></list-view>
<list-view *ngIf="listeAdresses && isDroitAdresse" [liste]="listeAdresses"></list-view>
<list-view *ngIf="listeDocuments && isDroitIdentite" [liste]="listeDocuments"></list-view>
<list-view *ngIf="listeCartesVoyage && isDroitCarteAbonnement" [liste]="listeCartesVoyage"></list-view>
<list-view *ngIf="listeContactUrgence && isDroitContactUrgence" [liste]="listeContactUrgence"></list-view>

<floating-button *ngIf="listeActions" [listeActions]="listeActions" [isPending]="isSaving"></floating-button>

<!--Template de la popup de synchro du profil voyageur -->
<ng-template #synchroTemplate>
    <div>
        <h1 mat-dialog-title>
            <span [translate]="'profilUser.infoPerso.synchroPV.synchroniserTitle'"></span>
        </h1>
        <div mat-dialog-content class="d-flex align-items-center">
            <mat-spinner diameter="50"></mat-spinner>
            <strong class="p-t-10" [translate]="'profilUser.infoPerso.synchroPV.statutEnCours'"></strong>
            <span [translate]="'liste.pleaseWait'"></span>
        </div>
    </div>
</ng-template>