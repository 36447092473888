<div
    *ngIf="appStatut?.applicationStatutAdmin?.messagePrincipal || synchroWfStatut?.messagePrincipal"
    class="statut-container"
    [class.non-clickable]="isNonClickable && !synchroWfStatut?.messagePrincipal"
    [class.locked]="appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.ERREUR_TASK || (isNonClickable && !synchroWfStatut?.messagePrincipal)">
    <span *ngIf="isHorsLigne && appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE_TEMPO && appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE" class="mode-hors-ligne" translate="admin.statut.application.horsLigne"></span>
    <mat-icon [class.non-clickable]="isNonClickable">error_outline</mat-icon>
    <div *ngIf="appStatut?.applicationStatutAdmin?.messagePrincipal && !synchroWfStatut?.messagePrincipal" class="statut-message"
         [class.non-clickable]="isNonClickable && !synchroWfStatut?.messagePrincipal"
         (click)="goToMaintenance()">
        <div class="message-principal">{{ appStatut.applicationStatutAdmin.messagePrincipal | translate }}</div>
        <div>{{ appStatut.applicationStatutAdmin.messageSecondaire | translate }}</div>
        <mat-progress-bar
                *ngIf="appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.ERREUR_TASK && appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE"
                class="statut-bar"
                mode="buffer"
                [bufferValue]="bufferValue"
                [value]="progression">
        </mat-progress-bar>
    </div>
    <div *ngIf="synchroWfStatut?.messagePrincipal" class="statut-message" (click)="handleSynchroWFClick()">
        <div class="message-principal">{{ synchroWfStatut.messagePrincipal | translate }}</div>
        <div>{{ synchroWfStatut.messageSecondaire | translate }}</div>
        <mat-progress-bar
                class="statut-bar"
                [mode]="modeProgressBar"
                [bufferValue]="100"
                [value]="progression">
        </mat-progress-bar>
    </div>
</div>
