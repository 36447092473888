<div class="with-floating-button">
    <div class="content" *ngIf="zone">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.geographie.zone.title' | translate: {libelle: zone.libelle}" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>
        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.zone.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="zone">
                <zone-generalites [zone]="zone"></zone-generalites>
            </form>
        </div>

        <div *ngIf="!isCreation" [hidden]="selectedItem?.code !== Onglets.PERIMETRE">
            <zone-perimetre [idZone]="zone.idZone"></zone-perimetre>
        </div>

        <floating-button [listeActions]="listeActions"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!zone"></please-wait>
</div>