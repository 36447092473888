/**
 * Enumération des types de profil attribuables à un rôle
 */
export enum ProfilsAttribuables {
	COMPTABLE = 1,
	RESPONSABLE = 2,
	COLLABORATEUR = 3,
	ASSISTANT = 4,
	SOUS_ADMINISTRATEUR = 5,
	FOURNISSEUR = 6,
}

/** Extension de l'énumération des profils attribuables */
export namespace ProfilsAttribuables {

	/** Tableau des libellés des profils */
	export const profilsAttribuablesListe: Array<{ libelle: string,val: number }> =
		[
			{libelle: "profil.comptable",val: 1},
			{libelle: "profil.responsable",val: 2},
			{libelle: "profil.collaborateur",val: 3},
			{libelle: "profil.assistant",val: 4},
			{libelle: "profil.sousAdministrateur",val: 5},
			{libelle: "profil.fournisseur",val: 6}
		]
}