import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import Cropper from 'cropperjs';

@Component({
    selector: 'image-viewer',
    templateUrl: './image-viewer.component.html'
})
export class ImageViewerComponent implements AfterViewInit {
    /** Référence vers l'image **/
    @ViewChild('imageViewer',{ static: false })
    public imageViewer: ElementRef;

    /** Source de l'image **/
    @Input() imageSource: string;

    @Input() isAlignRight: boolean = false;

    /** Fermeture du composant **/
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

    /** Cropper **/
    private cropper: Cropper;

    /**
     * Constructeur
     */
    constructor() {}

    /**
     * Chargement de la vue
     */
    ngAfterViewInit() {
        //Initialisation de isAlignRight pour être utilisé dans la méthode ready du cropper car this.isAlignRight sera undefined
        const isAlignRight = this.isAlignRight;

        //Création du cropper
        this.cropper = new Cropper(this.imageViewer.nativeElement,{
            dragMode: 'move',
            background: false,
            modal: false,
            checkCrossOrigin: false,
            center: false,
            ready: function () {
                //Si on a un alignment à droite
                if (isAlignRight) {
                    //Récupération des données du cropper pour calculer l'alignement à droite
                    const maxWidth = this.cropper.containerData.width;
                    const imageWitdh = this.cropper.canvas.clientWidth;
                    //On garde un espace vide fixe avec le bord
                    const emptySpace = 75;

                    //Calcul de du déplacement
                    let xTranslate = maxWidth /2 - imageWitdh /2 - emptySpace;

                    //Si déplacement positif
                    if (xTranslate > 0) {
                        //On déplace le cropper
                        this.cropper.move(xTranslate, 0);
                    }
                }
            }
        });
    }

    /**
     * Rotation de l'image
     */
    rotate(mode: 'LEFT' | 'RIGHT') {
        //Rotation de l'image
        this.cropper.rotate(mode == 'LEFT' ? -90 : 90);
    }

    /**
     * Zoom sur l'image
     */
    zoom(mode: 'IN' | 'OUT') {
        //Zoom sur l'image
        this.cropper.zoom(mode == 'IN' ? .1 : -.1);
    }
}
