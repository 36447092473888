<page-header
        [title]="'workflow.conditions.alertes.detail.title' | translate"
        [listeItems]="listeTabItems"
        (onSelectedItemChange)="onSelectedItemChange($event)"
        (onGoBack)="onGoBack()">
</page-header>

<div [hidden]="selectedItem?.code !== Onglets.ALERTE">
    <please-wait *ngIf="!alerte"></please-wait>
    <form #form="ngForm" *ngIf="alerte">
        <fieldset>
            <mat-card>
                <mat-card-title>
                    <span [translate]="'workflow.conditions.alertes.detail.alerte.' + (isCreation ? 'creation' : 'modification')"></span>
                </mat-card-title>
                <mat-card-content>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="libelle"
                                name="libelle"
                                customType="input"
                                [(ngModel)]="alerte.libelle"
                                libelle="workflow.conditions.alertes.detail.alerte.libelle"
                                [maxlength]="2000"
                                [required]="true">
                        </custom-input>
                        <label class="col-md-2">
                            <span [translate]="'workflow.conditions.alertes.detail.alerte.filtreStatut'"></span>
                        </label>
                        <button mat-button class="mb-2" color="primary" (click)="onClickStatut()">
                            <span *ngIf="!alerte.listeStatuts?.length" [translate]="'workflow.conditions.alertes.detail.alerte.tousStatuts'"></span>
                            <span *ngIf="alerte.listeStatuts?.length" [translate]="'workflow.conditions.alertes.detail.alerte.nbStatuts'" [translateParams]="{ nb: alerte.listeStatuts.length }"></span>
                            <mat-icon color="primary">search</mat-icon>
                        </button>
                    </div>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="portee"
                                name="portee"
                                customType="select"
                                [(ngModel)]="alerte.idPortee"
                                libelle="workflow.conditions.alertes.detail.alerte.portee"
                                [selectOptions]="listePortees"
                                optionDisplay="libelle"
                                optionValue="idPortee"
                                (onChange)="onPorteeChange()"
                                [required]="true">
                        </custom-input>
                        <label class="col-md-2">
                            <span [translate]="'workflow.conditions.alertes.detail.alerte.filtreProfil'"></span>
                        </label>
                        <button mat-button class="mb-2" color="primary" (click)="onClickProfil()">
                            <span *ngIf="!alerte.fonctions?.trim().split(' ').length" [translate]="'workflow.conditions.alertes.detail.alerte.tousProfils'"></span>
                            <span *ngIf="alerte.fonctions?.trim().split(' ').length" [translate]="'workflow.conditions.alertes.detail.alerte.nbProfils'" [translateParams]="{ nb: alerte.fonctions?.trim().split(' ').length }"></span>
                            <mat-icon color="primary">search</mat-icon>
                        </button>
                    </div>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="niveauAlerte"
                                name="niveauAlerte"
                                customType="select"
                                [(ngModel)]="alerte.niveauAlerte"
                                libelle="workflow.conditions.alertes.detail.alerte.niveau"
                                [selectOptions]="listeNiveaux"
                                optionDisplay="libelle"
                                optionValue="code"
                                optionIcone="icone">
                        </custom-input>
                    </div>
                    <div class="row" *ngIf="alerte.sql">
                        <label class="col-md-2 align-items-start p-t-15">
                            <span [translate]="'workflow.conditions.alertes.detail.alerte.conditionSQL'"></span>
                        </label>
                        <div class="col-md-9">
                            <ngx-codemirror
                                    id="sql"
                                    name="sql"
                                    [(ngModel)]="alerte.sql"
                                    [options]="codeMirrorOptions">
                            </ngx-codemirror>
                        </div>
                        <mat-icon class="material-icons-outlined" [matTooltip]="getSqlTooltip()">info</mat-icon>
                    </div>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="messageAlerte"
                                name="messageAlerte"
                                customType="textarea"
                                [(ngModel)]="alerte.commentaire"
                                libelle="workflow.conditions.alertes.detail.alerte.messageAlerte"
                                [required]="true"
                                [maxlength]="1000"
                                rCol="9">
                        </custom-input>
                    </div>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="commentaire"
                                name="commentaire"
                                customType="textarea"
                                [(ngModel)]="alerte.commentaireAdmin"
                                libelle="workflow.conditions.alertes.detail.alerte.commentaire"
                                [maxlength]="1000"
                                rCol="9">
                        </custom-input>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="entite">
                <mat-card-title>
                    <span [translate]="'workflow.conditions.alertes.detail.alerte.constructionCondition'"></span>
                </mat-card-title>
                <mat-card-content>
                    <rule-builder [(rule)]="alerte.rule" [entite]="entite.type"></rule-builder>
                </mat-card-content>
            </mat-card>
        </fieldset>
    </form>
</div>

<floating-button [listeActions]="listeActions"></floating-button>

<div [hidden]="selectedItem?.code !== Onglets.TEST">
    <alerte-test></alerte-test>
</div>