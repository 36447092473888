import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Role } from "@domain/reporting/role";
import { ReportingService } from "@services/admin/reporting/reporting.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { TypeCodeErreur } from "@domain/common/http/result";

/**
 * Composant d'affichage du formulaire de création d'un rôle
 */
@Component({
    host: {'data-test-id': 'report-role-create'},
    selector: 'report-role-create',
    templateUrl: './report-role-create.component.html'
})
export class ReportRoleCreateComponent {
    /** Rôle sélectionné */
    role: Role;

    /**
     * Constructeur
     */
    constructor(protected matDialogRef: MatDialogRef<ReportRoleCreateComponent>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private reportingService: ReportingService,
                @Inject(MAT_DIALOG_DATA) public data: { idReport: number }) {}

    /**
     * Enregistrement
     */
    save() {
        this.reportingService.saveRole(this.data.idReport,this.role.idRole)
            .pipe(first())
            .subscribe(res => {
                if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
                    //On affiche le message d'erreur
                    TypeCodeErreur.showError(res.codeErreur,this.translateService,this.toastrService);
                } else {
                    //On ferme la boîte de dialogue en indiquant qu'il faut mettre à jour la liste
                    this.matDialogRef.close(true);

                    //Si aucune erreur n'est renvoyée alors on affiche un message de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                }
            });
    }
}
