import {Component,OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {ProfilService} from "@components/profil/profil.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {LangueService} from "@services/admin/langue/langue.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {LocaleService} from "@services/admin/langue/locale.service";

/**
 * Composant de gestion de la personnalisation pour le profil
 */
@Component({
    selector: 'profil-personnalisation',
    templateUrl: './profil-personnalisation.component.html',
})
export class ProfilPersonnalisationComponent implements OnInit {

    /** liste des langues possibles */
    listeLangues: Langue[];

    /** Code de la langue sélectionnée */
    selectedLangue: string;

    /** Flag de sauvegarde en cours */
    isSaving: boolean = true;

    /** Flag de chargement en cours */
    isLoading: boolean = true;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Alerte affichée en cas de changement de langue */
    alerte: Alerte = new Alerte({
        niveau: NiveauAlerte.WARNING,
        titre: this.translateService.instant('profilUser.personnalisation.alerteLangueTitle'),
        message: this.translateService.instant('profilUser.personnalisation.alerteLangueDetails')
    });

    /** Niveau de l'alerte à afficher */
    niveauAlerte: NiveauAlerte = NiveauAlerte.ERROR;

    /** Flag de reconnexion nécessaire */
    isReconnexionNecessaire: boolean = false;

    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private router: Router,
        private confirmService: ConfirmService,
        private profilService: ProfilService,
        private langueService: LangueService,
        private localeService: LocaleService
    ) {}

    ngOnInit(): void {
        //Récupération de la liste des langues possibles
        this.profilService.getUserLangues().subscribe((result) => {
            //Si le retour contient une liste
            if (result?.data?.userLangues) {
                //Instanciation des objets
                this.listeLangues = result.data.userLangues.map(l => new Langue(l));

                //Flag de sauvegarde terminée
                this.isSaving = false;

                //Flag de chargement terminé
                this.isLoading = false;

                //Gestion du flag de reconnexion
                this.isReconnexionNecessaire = !!this.langueService.getWaitingLangue();

                //Recherche de la langue dans la liste
                const codeLangue: string = this.langueService.getWaitingLangue() || this.localeService.getCodeLangueActive();
                this.selectedLangue = this.listeLangues.find(l => l.code == codeLangue).code;
            }
        });

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.SECONDARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save()
        }]);
    }

    /**
     * Retour arrière
     */
    onGoBack() {
        //Navigation vers la liste
        this.router.navigate(['Profil']);
    }

    /**
     * Enregistrement
     */
    save() {
        //Demande de confirmation
        this.confirmService.showConfirm(this.translateService.instant('profilUser.personnalisation.confirmLangue')).subscribe( (isConfirmed) => {
            //En cas de confirmation
            if (isConfirmed) {
                //Flag de sauvegarde en cours
                this.isSaving = true;

                //Appel pour l'enregistrement de la langue
                this.profilService.saveUserLangue(this.selectedLangue).subscribe(result => {
                    //Flag de sauvegarde terminée
                    this.isSaving = false;

                    //En cas d'erreur
                    if (result.codeErreur != TypeCodeErreur.NO_ERROR) {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    } else {
                        //Mise à jour du SessionStorage
                        this.langueService.setWaitingLangue(this.selectedLangue);

                        //Gestion du flag de reconnexion
                        this.isReconnexionNecessaire = !!this.langueService.getWaitingLangue();

                        //Toast par défaut indiquant le succès de l'enregistrement
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    }
                },() => {
                    //Affichage d'un message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                });
            }
        });
    }
}
