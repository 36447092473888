<h1 mat-dialog-title>
    <span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinEdit.title'" [translateParams]="{ libelle: libelleInitial }"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="libelle"
                    name="libelle"
                    customType="input"
                    libelle="admin.comptabilite.exercicesPeriodes.exercice.periodes.popinEdit.libelle"
                    [(ngModel)]="periode.libelle">
            </custom-input>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary" (click)="savePeriode()" [disabled]="form.invalid || isPending">
                <span [translate]="'global.actions.enregistrer'"></span>
            </button>
        </div>
    </form>
</div>