<div class="lvi-content">
    <div class="avatar">
        <mat-icon class="material-icons-outlined">filter_alt</mat-icon>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.libelleObjet }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeFiltreZu.filtre'"></strong>
                <span [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.typeFiltreZU.' + data.typeFiltre"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="delete()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>