import {Component} from '@angular/core';

import {AutocompleteSearchItemComponent} from '../autocomplete-search-item.component';

/**
 * Composant d'affichage d'une ligne dans la popup de recherche avancée 'Géographie'
 */
@Component({
    templateUrl: './geographie.search-item.html',
})
export class GeographieSearchItemComponent extends AutocompleteSearchItemComponent<any> { }