<div class="lvi-content">
    <div class="avatar">
        <i class="icon-profil nio align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" [mat-dialog-close]="data">{{ data.prenom }} {{ data.nom }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong translate>matricule</strong>{{ data.matricule }}</li>
            <li><strong translate>service</strong>{{ data.orga?.libelle}}</li>
        </ul>
    </div>
</div>