import {Component,EventEmitter,Input,OnInit,Output} from "@angular/core";
import {LieuService} from "../../lieu.service";
import {ListView,TypeComparaison,TypeFilter} from "../../../../domain/common/list-view";
import {LieuAdresseListItemComponent} from "./lieu-adresse-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Adresse,AdresseListItem} from "../../../../domain/profil/adresse";
import {TypeAdresseEnum,TypeAdresseRef} from "../../../../domain/geolocalisation/type-adresse";

/**
 * Composant gérant l'affichage de la liste des adresses
 */
@Component({
    selector: 'adresse-list',
    host: {'data-test-id': 'adresse-list'},
    templateUrl: './lieu-adresse-list.component.html',
})
export class LieuAdresseListComponent implements OnInit {

    /** Event émis lors de la sélection d'une adresse */
    @Output() onAdresseSelect = new EventEmitter<Adresse>();

    /** Liste des adresses pour les lieux OD / OT */
    @Input() listeAdresse: Array<Adresse>;

    /** Liste des adresses du profil utilisateur */
    listeAdresseProfil: ListView<AdresseListItem, LieuAdresseListItemComponent>;

    /** Affiche le composant pour le profil */
    @Input() isProfilAdresse = false;

    /** Affiche le composant pour l'admin */
    @Input() isAdminAdresse = false;

    /**
     * Constructeur
     */
    constructor(private lieuService: LieuService,private translateService: TranslateService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Vérification de l'affichage dans le cas du profil (dans ce cas la liste est filtrable et affiche toutes les adresses (localisations) de l'utilisateur)
        if (this.isProfilAdresse || this.isAdminAdresse) {
            //Définition de la liste des adresses
            this.listeAdresseProfil = new ListView<AdresseListItem, LieuAdresseListItemComponent>({
                uri: this.isAdminAdresse ? `/controller/Adresse/searchAdresse` : `/controller/ProfilUser/getAdresse`,
                title: this.translateService.instant(this.isAdminAdresse ? 'lieu.tabs.adresses' : 'lieu.tabs.mesAdresses'),
                component: LieuAdresseListItemComponent,
                listeFilters: [{
                    clef: 'rue',
                    title: this.translateService.instant('lieu.rue'),
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'ville',
                    title: this.translateService.instant('lieu.ville'),
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'codePostal',
                    title: this.translateService.instant('lieu.codePostal'),
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                },{
                    clef: 'pays',
                    title: this.translateService.instant('lieu.pays'),
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    isDefault: true
                }],
                extraOptions: {
                    selectAdresse: (adresse) => this.selectAdresse(adresse),
                    getIconeForTypeAdresse: (typeAdresse) => this.getIconeForTypeAdresse(typeAdresse)
                }
            });
        }
    }

    /**
     * Selection d'une adresse dans la liste
     *
     * @param adresse
     */
    selectAdresse(adresse: Adresse): void {
        //Formatage de l'adresse
        if (!this.isAdresseIncomplete(adresse)) {
            if (this.isProfilAdresse) {
                adresse.rue = this.lieuService.formatAdressetoString(adresse);
            } else if (this.isAdminAdresse) {
                adresse.rue = this.lieuService.getAdresseFromLocalisation(adresse);
            }
        }

        this.onAdresseSelect.emit(adresse);
    }

    /**
     * Vérifie si des informations obligatoires sont manquantes sur une adresse
     *
     * @param adresse Adresse à vérifier
     */
    isAdresseIncomplete(adresse: Adresse): boolean {
        return this.lieuService.isAdresseIncomplete(adresse);
    }

    /**
     * Retourne l'icône à afficher suivant le type d'adresse personnelle / professionelle
     *
     * @param typeAdresse personnelle / professionelle
     */
    getIconeForTypeAdresse(typeAdresse: string): string {
        return TypeAdresseRef.find(typeAdresse)?.type == TypeAdresseEnum.PERSONNELLE ? 'home' : 'business';
    }

}
