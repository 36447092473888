import {Component} from '@angular/core';
import {OmpListItemComponent} from "../../../omp/omp-list/omp-list-item/omp-list-item.component";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {MatDialog} from "@angular/material/dialog";
import {OMPService} from "../../../omp/omp.service";

/**
 * Item de la liste des OMP du Dashboard
 */
@Component({
    selector: 'dashboard-omp-list-item',
    templateUrl: './dashboard-omp-list-item.component.html'
})
export class DashboardOmpListItemComponent extends OmpListItemComponent {

    /**
     * Constructeur
     *
     * @param ompService le service de gestion des OMP
     * @param store le store
     * @param matDialog le composant des popups
     */
    constructor(protected ompService: OMPService,
                protected store: Store<AppState>,
                protected matDialog: MatDialog) {
        super(ompService, store, matDialog);
    }
}
