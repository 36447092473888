<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.entreprise.referentiels.onglets.generalites' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.GENERALITES ">
            <list-view *ngIf="list" [liste]="list"></list-view>
        </div>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>