import {Component} from '@angular/core';
import {ControlContainer,NgForm} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {CategorieVoyageur} from "@domain/travel/categorie-voyageur";
import {
	ReferentielCategorieVoyageurListItemComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/categorie-voyageur/categorie-voyageur-list-item/referentiel-categorie-voyageur-list-item.component";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {
	ReferentielCategorieVoyageurAddComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/categorie-voyageur/categorie-voyageur-add/referentiel-categorie-voyageur-add.component";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {AbstractReferentielComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/abstract-referentiel/abstract-referentiel.component";
import {TravelHubSbtConfigUsedCategorie} from "@domain/travel/travel-hub-sbt-config-used-categorie";

/**
 * Cadre du référentiel "Catégorie voyageur" de l'onglet "Référentiels" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-categorie-voyageur'},
    selector: 'referentiel-categorie-voyageur',
    templateUrl: './referentiel-categorie-voyageur.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReferentielCategorieVoyageurComponent extends AbstractReferentielComponent<CategorieVoyageur,ReferentielCategorieVoyageurListItemComponent> {
    /**
     * Constructeur
     *
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param matDialog Boite de dialogue
     */
    constructor(adminTravelhubService: AdminTravelhubService,
                translateService: TranslateService,
                toastrService: ToastrService,
                matDialog: MatDialog) {
        super(
            adminTravelhubService,
            translateService,
            toastrService,
            matDialog,
            ReferentielCategorieVoyageurListItemComponent,
            ReferentielCategorieVoyageurAddComponent
        );
    }

	/**
	 * Retourne l'uri propre au type de référentiel
	 */
    protected getUri(): string {
        return `/controller/TravelHubConfig/${this.sbtConfigUsed.idSBTConfigUsed}/listeCategorieVoyageur`;
    }

	/**
	 * Retourne la racine pour les traductions
	 */
    protected getRootTrad(): string {
        return 'admin.voyages.travelhub.sbtConfig.referentiel.categorieVoyageur';
    }

	/**
	 * Post-traitement de la liste paginée retournée par le serveur
	 *
	 * @param result Résultat à traiter
	 */
    protected mapResult(result: Result): void {
        //Récupération de la liste des SBT utilisés
        this.listeSBTConfigUsed = result.data.listeSBTConfigUsed as Array<TravelHubSbtConfigUsedCategorie>;

        //Transformation des résultats en instance
        return result.data.listeCategorieVoyageur.map(item => this.newInstanceReferentiel(item));
    }

	/**
	 * Retourne une instance du type de référentiel
	 *
	 * @param dto Source
	 */
    protected newInstanceReferentiel(dto?: CategorieVoyageur | any): CategorieVoyageur {
        return new CategorieVoyageur(dto);
    }

	/**
	 * Met à jour le niveau d'erreur du référentiel
	 */
    public updateAlertLevelList(): void {
        this.listeReferentiel.alertLevel = this.sbtConfigUsed.errorCategorieVoyageur ? NiveauAlerte.ERROR : null;
    }

	/**
	 * Enregistrement d'une entrée du référentiel
	 *
	 * @param referentiel Référentiel à enregistrer
	 */
    protected save(referentiel: CategorieVoyageur): Observable<Result> {
        return this.adminTravelhubService.saveCategorieVoyageur(referentiel);
    }

	/**
	 * Suppression d'une entrée du référentiel
	 *
	 * @param referentiel Référentiel à supprimer
	 */
	protected delete(referentiel: CategorieVoyageur): Observable<Result> {
        return this.adminTravelhubService.deleteCategorieVoyageur(referentiel);
    }

}