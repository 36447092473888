<div class="lvi-content">
    <div class="avatar">{{ libelleType.substring(0,3)}}</div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">
                <span *ngIf="data.type === TypeGeoLocalisation.PAYS && data.codePays" class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.codePays.toLowerCase()"></span>
                {{ data.libelle }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.territoire.perimetre.type'"></strong>
                <span>{{ libelleType }}</span>
            </li>
            <li *ngIf="data.type !== TypeGeoLocalisation.PAYS && data.type !== TypeGeoLocalisation.ZONE">
                <strong [translate]="'admin.bibliotheque.geographie.territoire.perimetre.pays'"></strong>
                <span *ngIf="data.codePays" class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.codePays.toLowerCase()"></span>
                <span>{{ data.libellePays }}</span>
            </li>
            <li *ngIf="data.hierarchie">
                <strong [translate]="'admin.bibliotheque.geographie.territoire.perimetre.hierarchie'"></strong>
                <span>{{ data.hierarchie }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deletePerimetre()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>