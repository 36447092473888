<div class="lvi-content">
    <div class="avatar"><i class="zmdi zmdi-hc-3x zmdi-file"></i></div>
    <div class="lvi-body">
        <div class="title">
            <a
                    [ngStyle]="{ 'cursor': extraOptions.isClickable === false ? 'default' : undefined, 'text-decoration': extraOptions.isClickable === false ? 'none' : undefined }"
                    (click)="open()">
                {{ data.libelle || data.fichier }}
            </a>
        </div>
        <ul class="lvi-attrs" *ngIf="!customTags">
            <li *ngIf="data.dateCreation"><strong translate="document.liste.dateCreation"></strong>{{ data.dateCreation | date:'short' }}</li>
            <li *ngIf="data.userInfo"><strong translate="document.liste.origine"></strong>{{ data.userInfo }}</li>
            <li *ngIf="data.libelle"><strong translate="document.liste.fichier"></strong>{{ data.fichier }}</li>
            <li *ngIf="data.fichier"><strong translate="document.liste.format"></strong>{{ data.fichier.slice(data.fichier.indexOf('.')+1) }}</li>
            <li *ngIf="data.taille"><strong translate="document.liste.taille"></strong>{{ data.taille | filesize }}</li>
            <li *ngIf="data.context != null && isShowType(data.context)">
                <strong translate="document.liste.typeDocument"></strong>{{ getTypeTexte(data.context) }}
            </li>
        </ul>
        <ul class="lvi-attrs" *ngIf="customTags">
            <li *ngFor="let tag of customTags(data)"><strong translate="{{ tag.libelle }}"></strong>{{ tag.value }}</li>
        </ul>
    </div>
    <div *ngIf="canDownloadDocument || (!data.readOnly && extraOptions.canDeleteDocument)" class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item *ngIf="canDownloadDocument" (click)="download()" translate="global.actions.telecharger"></button>
            <button mat-menu-item *ngIf="!data.readOnly && extraOptions.canDeleteDocument" (click)="delete()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
