import {Component} from '@angular/core';

import {AutocompleteLineItemComponent} from '../autocomplete-line-item.component';

/**
 * Composant d'affichage d'une ligne d'autocomplete
 */
@Component({
    host: {'data-test-id': 'devise-line-item'},
    templateUrl: './devise.line-item.html'
})
export class DeviseLineItemComponent extends AutocompleteLineItemComponent<any> {

}