import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Sorting} from '@domain/common/list-view/sorting';

/**
 * Composant de tri
 */
@Component({
    templateUrl: './sorting.component.html',
    host: {'data-test-id': 'sorting'},
})
export class SortingComponent {

    /**
     * Constructeur
     *
     * @param matDialogRef la référence à la popup
     * @param data la délégation à modifier (si modification)
     */
    constructor(private matDialogRef: MatDialogRef<SortingComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { sorting: Sorting }) { }

    /**
     * Méthode de soumission du formulaire
     */
    onFormSubmit() {
        //Fermeture de la popup
        this.matDialogRef.close({
            sorting: this.data.sorting
        });
    }

    /**
     * Méthode de reset
     */
    onReset() {
        //Fermeture de la popup
        this.matDialogRef.close({
            reset: true
        });
    }
}
