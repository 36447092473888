<h1 mat-dialog-title>
    <span translate="admin.entreprise.analytique.organigramme.listeUtilisateurs.title"></span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <list-view *ngIf="listeUtilisateurs" [liste]="listeUtilisateurs"></list-view>
        </div>
    </div>
</div>