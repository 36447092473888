import {TranslateService} from '@ngx-translate/core';
import {Filter,TypeFilter} from '@domain/common/list-view';
import {TypePortee} from '@domain/workflow/workflow';
import {AutocompleteOptions} from '../autocomplete';
import {EntityAttribute} from './entity-attribute';

/**
 * Options des types entités
 */
export default class TypeEntiteOptions implements AutocompleteOptions {
	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		if (filter) {
			if (filter.isAdmin) {
				return '/controller/TypeEntite/getListeTypeEntiteForAutocomplete?idPortee=' + filter.idPortee;
			}
			return `/controller/TypeEntite/listeTypeEntite/${filter.idPortee}?isNewObjet=true&idCollab=${filter?.idCollab || ''}`;
		} else {
			return '/controller/TypeEntite/getListeTypeEntite';
		}
	}

	/**
	 * Liste des filtres statiques
	 */
	listeStaticFilters(filter: any) {
		//Retour de la liste des fitres statiques
		return [filter?.idPortee == TypePortee.NF && filter?.isRattachementOMNonObligatoire && {
			clef: 'typeEntiteParamNF.isRattachementOMObligatoire',
			valeur: 'false',
			type: TypeFilter[TypeFilter.BOOLEAN]
		},{
			clef: 'actif',
			valeur: 'true',
			type: TypeFilter[TypeFilter.BOOLEAN]
		}].filter(i => !!i);
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) {
		return (item?.libelle || '');
	}

	/**
	 * Filtres disponibles
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			title: translateService.instant('filter.reference'),
			clef: 'code',
			isDefault: true
		},{
			title: translateService.instant('filter.libelle'),
			clef: 'libelle',
			isDefault: true
		}];
	}

	/**
	 * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
	 */
	listDisplayedAttributes(item: any): Array<EntityAttribute> {
		return [{
			clef: 'filter.code',
			valeur: item.code
		}];
	}
}