import {NgModule} from '@angular/core';
import {ShareModule} from "@share/share.module";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {ZonesUtilisateursComponent} from '@components/admin/bibliotheque/zones-utilisateurs/zones-utilisateurs.component';
import {ZonesPredefiniesComponent} from './zones-predefinies/zones-predefinies.component';
import {ZonesParametrablesComponent} from './zones-parametrables/zones-parametrables.component';
import {ZonePredefinieListItemComponent} from './zones-predefinies/list-item/zone-predefinie-list-item.component';
import {ZonePredefinieEditPopinComponent} from './zones-predefinies/edit-popin/zone-predefinie-edit-popin.component';
import {ZoneParametrableListItemComponent} from './zones-parametrables/list-item/zone-parametrable-list-item.component';
import {ZoneParametrableDetailComponent} from './zones-parametrables/detail/zone-parametrable-detail.component';
import {ZoneParametrableConfigComponent} from './zones-parametrables/detail/config/zone-parametrable-config.component';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ZuRefListItemComponent} from './zones-parametrables/detail/config/zu-ref-list-item/zu-ref-list-item.component';
import {FiltreZuListItemComponent} from './zones-parametrables/detail/config/filtre-zu-list-item/filtre-zu-list-item.component';
import {AddFiltreZuPopinComponent} from './zones-parametrables/detail/config/add-filtre-zu-popin/add-filtre-zu-popin.component';
import {AddZuRefPopinComponent} from './zones-parametrables/detail/config/add-zu-ref-popin/add-zu-ref-popin.component';

/**
 * Module des zones utilisateurs
 */
@NgModule({
	imports: [
		ShareModule,
		PreviewAlerteModule
	],
	declarations: [
		ZonesUtilisateursComponent,
		ZonesPredefiniesComponent,
		ZonesParametrablesComponent,
		ZonePredefinieListItemComponent,
		ZonePredefinieEditPopinComponent,
		ZoneParametrableListItemComponent,
		ZoneParametrableDetailComponent,
		ZoneParametrableConfigComponent,
		ZuRefListItemComponent,
		FiltreZuListItemComponent,
		AddFiltreZuPopinComponent,
		AddZuRefPopinComponent
	],
	providers: [
		ZonesUtilisateursService
	]
})
export class ZonesUtilisateursModule {
}
