<div class="lvi-content">
    <div class="avatar" [class.danger]="!data?.preMigrationSuccess || !data?.bddUpgradeSuccess || !data?.postMigrationSuccess">
        <i class="material-icons">move_up</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="openMigration()">
                <span>
                    {{ 'admin.maintenance.migrations.onglets.historique.version' | translate }}
                    {{ data.origine }}
                    ({{ 'admin.maintenance.migrations.onglets.historique.build' | translate }}
                    {{ data.origineBuild }})
                    <mat-icon class="clickable">trending_flat</mat-icon>
                    {{ 'admin.maintenance.migrations.onglets.historique.version' | translate }}
                    {{ data.cible }}
                    ({{ 'admin.maintenance.migrations.onglets.historique.build' | translate }}
                    {{ data.cibleBuild }})
                </span>
            </a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.maintenance.migrations.onglets.historique.date'"></strong>
                <span>{{ data.date | date: 'shortDate' }} {{ data.date | date: 'shortTime' }}</span>
            </li>
            <li [class.li-danger]="data?.preMigration && !data?.preMigrationSuccess">
                <strong [translate]="'admin.maintenance.migrations.onglets.historique.preMigration'"></strong>
                <mat-icon *ngIf="data?.preMigration && data?.preMigrationSuccess" class="success-icon">done</mat-icon>
                <mat-icon *ngIf="data?.preMigration && !data?.preMigrationSuccess" class="fail-icon">close</mat-icon>
                <span *ngIf="!data?.preMigration" [translate]="'admin.maintenance.migrations.onglets.historique.nonApplicable'"></span>
            </li>
            <li [class.li-danger]="data?.bddUpgrade && !data?.bddUpgradeSuccess">
                <strong [translate]="'admin.maintenance.migrations.onglets.historique.migration'"></strong>
                <mat-icon *ngIf="data?.bddUpgrade && data?.bddUpgradeSuccess" class="success-icon">done</mat-icon>
                <mat-icon *ngIf="data?.bddUpgrade && !data?.bddUpgradeSuccess" class="fail-icon">close</mat-icon>
                <span *ngIf="!data?.bddUpgrade" [translate]="'admin.maintenance.migrations.onglets.historique.nonApplicable'"></span>
            </li>
            <li [class.li-danger]="data?.postMigration && !data?.postMigrationSuccess">
                <strong [translate]="'admin.maintenance.migrations.onglets.historique.postMigration'"></strong>
                <mat-icon *ngIf="data?.postMigration && data?.postMigrationSuccess" class="success-icon">done</mat-icon>
                <mat-icon *ngIf="data?.postMigration && !data?.postMigrationSuccess" class="fail-icon">close</mat-icon>
                <span *ngIf="!data?.postMigration" [translate]="'admin.maintenance.migrations.onglets.historique.nonApplicable'"></span>
            </li>
        </ul>
    </div>
</div>
