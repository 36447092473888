import {Component, Input} from '@angular/core';

@Component({
    selector: 'please-wait',
    templateUrl: './please-wait.component.html'
})
export class PleaseWaitComponent {

    /** Indique si l'icône de chargement est la version miniature */
    @Input() small: boolean = false;

    /** Message à afficher pendant le chargement */
    @Input() message: string;

}