import {Component,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";

/**
 * Page admin de Comptabilité / Plan comptable
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'plan-comptable'},
    templateUrl: './plan-comptable.component.html'
})
export class PlanComptableComponent implements OnInit {
    /** Référence vers l'enum pour l'utiliser dans le template */
    readonly Onglets = Onglets;

    /** Liste des différents onglets disponibles */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem = {
        code: Onglets.COMPTES_COMPTABLES,
        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.title.liste'),
        selected: true
    };

    /**
     * Constructeur
     *
     * @param translateService Service de gestion de la traduction
     * @param planComptableService Service du plan comptable
     */
    constructor(private translateService: TranslateService,
                private planComptableService: ComptabilitePlanComptableService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Définition des onglets
        this.listeTabItems = [{
            code: Onglets.COMPTES_COMPTABLES,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.title.liste')
        }, {
            code: Onglets.PARAMETRES,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.parametres.title')
        }];
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Indique si on est dans le détail d'un compte comptable
     */
    get isInDetail() {
        return this.planComptableService.isInDetailCompte;
    }

}

/**
 * Enum pour les différents onglets de la page Comptabilite / Plan comptable
 */
export enum Onglets {
    COMPTES_COMPTABLES = "Comptes_comptables",
    PARAMETRES = "Parametres"
}
