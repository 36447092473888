<h1 mat-dialog-title>
    <span translate="confirmation.title"></span>
</h1>
<div class="content">
    <mat-dialog-content>
        <span translate>admin.entreprise.referentiels.confirmationSuppression</span>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary" class="ml-2"
                    (click)="closeDialog(true)">
                <span translate>global.actions.confirmer</span>
            </button>
            <button mat-stroked-button color="primary"
                    (click)="closeDialog(false)">
                <span translate>global.actions.annuler</span>
            </button>
        </div>
    </mat-dialog-content>
</div>