import {Component} from '@angular/core';
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {TranslateService} from "@ngx-translate/core";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {finalize} from "rxjs/operators";
import {ModuleItem,TypeModule} from "@domain/admin/parametre/demat/module-item";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";

/**
 * Composant d'affichage des modules de la page Dématérialisation.
 *
 * @author François Turin
 * @date 27/06/2023
 */
@Component({
	selector: 'demat-module',
	templateUrl: './demat-module.component.html'
})
export class DematModuleComponent {

	/** Liste des modules. L'ordre des modules dans la liste est important pour l'affichage et doit être conservé */
	listeModules: ModuleItem[] = [];

	/** Statuts d'archivage */
	statutArchivage: StatutArchivage;

	/** Indicateur de test en cours */
	isTesting: boolean = false;

	/** Tooltip du bouton de test */
	tooltipButton: string;

	constructor(private dematService: DematService,
				private translateService: TranslateService,
				private toastrService: ToastrService) {
		//Module Certification
		let moduleCertification = new ModuleItem(TypeModule.LICENCE);
		moduleCertification.libelle = this.translateService.instant("admin.entreprise.description.licence.certification");
		this.listeModules.push(moduleCertification);

		//Environnement
		let environnement = new ModuleItem(TypeModule.ENV);
		environnement.libelle = this.translateService.instant("admin.parametres.demat.modules.environnement");
		environnement.statut = EnvironnementType.NOENV;
		this.listeModules.push(environnement);

		//Module Archivage
		let moduleArchivage = new ModuleItem(TypeModule.LICENCE);
		moduleArchivage.libelle = this.translateService.instant("admin.entreprise.description.licence.archivage");
		this.listeModules.push(moduleArchivage);

		//Module e-mail
		let moduleEmail = new ModuleItem(TypeModule.MAIL);
		moduleEmail.libelle = this.translateService.instant("admin.parametres.demat.modules.email");
		this.listeModules.push(moduleEmail);

		//Module OCR
		let moduleOcr = new ModuleItem(TypeModule.ENV);
		moduleOcr.libelle = this.translateService.instant("admin.parametres.demat.modules.ocr");
		moduleOcr.statut = EnvironnementType.NOENV;
		this.listeModules.push(moduleOcr);

		//Module GED
		let moduleGed = new ModuleItem(TypeModule.GED);
		moduleGed.libelle = this.translateService.instant("admin.parametres.demat.modules.ged");
		this.listeModules.push(moduleGed);

		//Chargement des statuts de la licence Démat et services annexes
		this.dematService.loadStatutDemat().subscribe(result => {
			moduleArchivage.statut = !!result.data.licence.ARCHIVAGE;
			moduleCertification.statut = !!result.data.licence.CERTIFICATION;
			environnement.statut = result.data.environnement;
			moduleOcr.statut = result.data.environnement;
			moduleEmail.statut = result.data.typeServiceMail;
			moduleGed.statut = !!result.data.isGedCloud;

			//Définition du tootlip du bouton Tester
			this.initTooltip();
		});
	}

	/**
	 * Test de connexion au DematHub et/ou à la GED Azure.
	 */
	tester(): void {
		this.isTesting = true;

		//Récupération de l'état de la connexion au DematHub
		this.dematService.tester()
			.pipe(finalize(() => this.isTesting = false))
			.subscribe(result => {
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Résultat du test de connexion DematHub
					if (result.data.isOkDemat !== undefined) {
						if (result.data.isOkDemat) {
							//Message de succès
							this.toastrService.success(this.translateService.instant("admin.parametres.demat.modules.tester.testDematOk"));
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant("admin.parametres.demat.modules.tester.testDematKo"));
						}
					}

					//Résultat du test de connexion GED
					if (result.data.isOkGed !== undefined) {
						if (result.data.isOkGed) {
							//Message de succès
							this.toastrService.success(this.translateService.instant("admin.parametres.demat.modules.tester.testGedOk"));
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant("admin.parametres.demat.modules.tester.testGedKo"));
						}
					}
				}
			});
	}

	/**
	 * Initialise le message du tooltip du bouton "tester".
	 */
	private initTooltip(): void {
		let tip: string = this.translateService.instant("admin.parametres.demat.modules.tester.tooltip");

		let isDematActif: boolean = this.listeModules.some(value => value.type === TypeModule.LICENCE && value.statut === true);
		if (isDematActif) {
			tip += " " + this.translateService.instant("admin.parametres.demat.modules.tester.tooltipDemat");
		}

		let isGedActif: boolean = this.listeModules.some(value => value.type === TypeModule.GED && value.statut === true);

		if (isGedActif) {
			if (isDematActif) {
				tip += " " + this.translateService.instant("admin.parametres.demat.modules.tester.tooltipEt");
			}
			tip += " " + this.translateService.instant("admin.parametres.demat.modules.tester.tooltipGed");
		}

		this.tooltipButton = tip;
	}
}

/**
 * Types d'environnement d'exécution.
 */
export enum EnvironnementType {
	PROD = "PROD",
	TEST = "TEST",
	NOENV = "NOENV"
}
