import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ReferentielDto} from "@domain/admin/referentiels/referentielDto";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first,map} from "rxjs/operators";
import {ConfigCompte} from "@domain/settings/configCompte";

/**
 * Service de gestion de l'écran Compte Bancaires du menu admin Comptabilité
 */
@Injectable({
	providedIn: 'root'
})
export class ComptabiliteComptesBancairesService {
	/**
	 * Constructeur
	 *
	 * @param http client HTTP
	 */
	constructor(
		private http: HttpClient
	) {
	}

	/**
	 * Récupération des catégories
	 */
	getCategories(): Observable<ReferentielDto[]> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/CompteBancaireAdmin/loadParams`,null).pipe(
			first(),
			map(result => result?.data?.listeCategorie)
		);
	}

	/**
	 * Sauvegarde de la configuration des comptes
	 *
	 * @param configCompte la configuration des comptes
	 */
	saveConfigCompte(configCompte: ConfigCompte): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/CompteBancaireAdmin/saveParams`,configCompte);
	}
}
