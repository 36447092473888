import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Classe } from "@domain/admin/voyages/referentiels/classe";
import { TypesPrestationClasse } from "@domain/admin/voyages/referentiels/types-prestation-classe.enum";
import { TypeCodeErreur } from "@domain/common/http/result";
import { TranslateService } from "@ngx-translate/core";
import { VoyagesReferentielsService } from "@services/admin/voyages/voyages-referentiels.service";
import { ConfirmService } from "@share/component/confirmation/confirm.service";
import { FloatingButtonAction } from "@share/component/floating-button/floating-button";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";

/**
 * Composant d'affichage des informations d'une classe et du formulaire de création
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-classes-infos'},
    selector: 'voyages-referentiels-classes-infos',
    templateUrl: './voyages-referentiels-classes-infos.component.html'
})
export class VoyagesReferentielsClassesInfosComponent implements OnInit, OnDestroy {
    /** Booléen pour savoir si l'affichage de la page se fait en mode création d'une classe ou non */
    isCreation: boolean = false;

    /** Classe contenant les informations à afficher sur la page */
    classe: Classe = {
        actif: false,
        code: "",
        custom: false,
        libelle: "",
        typePrestation: 0
    };

    /** Formulaire de la classe */
    @ViewChild('form') form: NgForm;

    /** Pending pour le bouton d'enregistrement */
    isSaving: boolean;

    /** Pending pour le bouton de suppression */
    isDeleting: boolean;

    /** Ensemble des types de prestations possibles pour une classe */
    typesPrestation = [{
        id: 0,
        type: TypesPrestationClasse.AVION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
    }, {
        id: 1,
        type: TypesPrestationClasse.TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
    }, {
        id: 2,
        type: TypesPrestationClasse.HOTEL,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
    }, {
        id: 3,
        type: TypesPrestationClasse.VOITURE_DE_LOCATION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
    }, {
        id: 4,
        type: TypesPrestationClasse.VOITURE_SOCIETE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
    }, {
        id: 5,
        type: TypesPrestationClasse.VOITURE_PERSONNELLE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
    }, {
        id: 7,
        type: TypesPrestationClasse.AVION_TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
    }];

    /** Liste des souscriptions, pour faire un unsubscribe sur chacun lors de la destruction du composant */
    souscriptions: Subscription[] = [];

    /**
     * Constructeur
     *
     * @param activatedRoute Route d'accès à cette page
     * @param confirmService Service de confirmation
     * @param translateService Service de traduction
     * @param voyagesReferentielsService Service de gestion des référentiels du menu Voyages
     * @param matDialogRef service de référence de la popup
     * @param data données transmises à la popup
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param router Router de l'application pour naviguer entre les pages
     */
    constructor(private activatedRoute: ActivatedRoute,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private voyagesReferentielsService: VoyagesReferentielsService,
                private matDialogRef: MatDialogRef<VoyagesReferentielsClassesInfosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { idClasse: number },
                private toastrService: ToastrService,
                private router: Router) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //On est en mode création si l'identifiant de la classe est égal à 0 (sinon on est en mode modification)
        this.isCreation = this.data.idClasse === 0;

        if (this.isCreation) {
            //Une classe créée manuellement est custom
            this.classe.custom = true;
        } else {
            //Sinon on récupère la classe sélectionnée et on récupère toutes ses informations à afficher
            this.souscriptions.push(this.voyagesReferentielsService.getClasseById(this.data.idClasse).subscribe(data => {
                //Récupération des informations de la classe sélectionnée
                this.classe = data.data.classe;
            }));
        }
    }

    /**
     * Méthode appelée lors de l'enregistrement de la classe sélectionnée
     */
    save() {
        //Si le formulaire est valide alors on enregistre la classe
        if (this.form.valid) {
            //Activation du pending sur le bouton
            this.isSaving = true;

            //Enregistrement de la classe en base
            this.souscriptions.push(this.voyagesReferentielsService.saveClasse(this.classe).subscribe(data => {
                //Si la réponse contient un code d'erreur
                if (data.codeErreur != TypeCodeErreur.NO_ERROR) {
                    //Affichage d'un message d'erreur d'enregistrement
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                } else {
                    //Si la réponse ne contient pas de message d'erreur alors on affiche un message de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de la popup
                    this.matDialogRef.close(true);
                }

                //Désactivation du pending sur le bouton
                this.isSaving = false;
            }));
        } else {
            //Si, au contraire, le formulaire n'est pas valide alors affichage d'un message disant que le formulaire n'est pas valide
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Suppression de la classe en base
     */
    delete() {
        //Activation du pending sur le bouton
        this.isDeleting = true;

        //Confirmation de la suppression de la classe
        this.souscriptions.push(this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(
            filter(isConfirmed => isConfirmed),
            mergeMap(() => this.voyagesReferentielsService.deleteClasse(this.classe.idClasse))
        ).subscribe(
            data => {
                //Si la réponse contient un code d'erreur, affichage d'un message d'erreur de suppression
                if (data.codeErreur != TypeCodeErreur.NO_ERROR) {
                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                } else {
                    //Sinon on affiche un message de succès
                    this.toastrService.success(this.translateService.instant('global.success.suppression'));

                    //Fermeture de la popup
                    this.matDialogRef.close(true);
                }

                //Désactivation du pending sur le bouton
                this.isDeleting = false;
            }
        ));
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Application de "unsubscribe" à toutes les souscriptions
        this.souscriptions.forEach(souscription => souscription.unsubscribe());
    }
}