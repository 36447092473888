<infinite-scroll (scrolled)="hasInfiniteScroll() && onEndReached()">
    <mat-card class="p-0" [ngClass]="{ 'fill-dashboard-list': liste.isDashboardList && data?.listeResultats.length < 5 }">
        <mat-card-content>
            <div class="listview" [ngClass]="{ 'local': liste.isLocal || liste.isSimple || liste.isDashboardList || !liste.showPagination,'has-selected-items': liste.nbSelectedItems }">
                <list-view-search *ngIf="liste.title || liste.isFilter || liste.listeActions?.length > 0" class="listview-search" [liste]="liste"></list-view-search>
                <div *ngIf="header">
                    <ng-template [ngTemplateOutlet]="header.template"></ng-template>
                </div>
                <div class="listview-body" [ngClass]="{ 'popin-list': popinList }">
                    <div [ngClass]="{'listview-body-row row' : liste.isAffichageDeuxColonnes}">
                        <div *ngFor="let data of data?.listeResultats" class="listview-item" [ngClass]="{ 'active': data.isDisplayed, 'col-md-6' : liste.isAffichageDeuxColonnes }">
                            <div [ngClass]="{ 'warning': (data.listeAlertes?.niveau == 1 || data.niveauAlerte == 1),'danger': (data.listeAlertes?.niveau == 2 || data.niveauAlerte == 2), 'list-view-item-hover': hover }">
                                <list-view-item [data]="data" [toggleItem]="toggleItem"
                                                [liste]="liste" [component]="liste.component" [extraOptions]="liste.extraOptions" (onRemove)="onRemove($event)">
                                </list-view-item>
                            </div>
                        </div>
                    </div>
                    <div class="empty" *ngIf="!liste?.isLoading && data?.listeResultats?.length == 0">
                        <div *ngIf="!liste.isSearchWhenNoFilter && liste.listeSelectedFilters.length == 0; else noResult">
                            <!-- Pas de filtre renseigné alors que c'est obligatoire -->
                            <span [translate]="'liste.noFilter'"></span>
                        </div>
                        <ng-template #noResult>
                            <!-- Pas de résultat -->
                            <span [translate]="liste.emptyMessage ? liste.emptyMessage : (!isListeATraiter() ? 'liste.noData' : 'liste.noDataToProcess')"></span>
                            <a *ngIf="isListeATraiter()" [routerLink]="" (click)="viewAll()" [translate]="'liste.voirTout'"></a>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="liste?.isLoading" class="text-center">
                    <please-wait></please-wait>
                </div>
                <div *ngIf="footer">
                    <ng-template [ngTemplateOutlet]="footer.template"></ng-template>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</infinite-scroll>
