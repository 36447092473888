import {Component,Inject,TemplateRef} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {showConfirmOptions} from "./confirm.service";
import {IPopupIgnoreFloatingButton} from "@share/component/popup/IPopupIgnoreFloatingButton";

@Component({
    templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements IPopupIgnoreFloatingButton {

    /* Message ou templateRef à afficher */
    message : String;
    templateRef : TemplateRef<any>;

    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string | TemplateRef<any>, options: showConfirmOptions }) {
        if (typeof this.data.message == "string") {
            this.message = this.data.message;
        } else if (this.data.message instanceof TemplateRef) {
            this.templateRef = this.data.message;
        }
    }

    /**
     * Retourne le texte du bouton "Annuler / Non"
     */
    get boutonAnnuler(): string {
        return this.data.options?.type === 'oui-non' ? 'confirmation.non' : 'global.actions.annuler';
    }

    /**
     * Retourne le texte du bouton "Confirmer / Oui"
     */
    get boutonConfirmer(): string {
        //Récupération du libellé personnalisé depuis les options
        let libelle = this.data.options?.buttons?.confirm?.libelle;

        //Si pas de libellé personnalisé, définition d'un libellé standard suivant le type
        if (!libelle) {
            //Suivant le type de dialog
            switch (this.data.options?.type) {
                case 'oui-non':
                    libelle = 'confirmation.oui';
                    break;
                case 'ok':
                    libelle = 'global.actions.fermer';
                    break;
                case "defaut":
                default:
                    libelle = 'global.actions.confirmer';
                    break;
            }
        }

        return libelle;
    }

    /** @inheritDoc */
    idPopupIgnoreFloatingButton: string = "ConfirmComponent";
}