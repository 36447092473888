import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction, TypeAction} from '@share/component/floating-button/floating-button';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ActivatedRoute, Router} from '@angular/router';

/**
 * Composant de la liste des adresses dans le menu géographie
 *
 * @author Angeline Ha
 * @date 07/12/2023
 */
@Component({
    host: {'data-test-id': 'adresse-geo-list'},
    selector: 'adresse-geo-list',
    templateUrl: './adresse-list.component.html'
})
export class AdresseListComponent implements OnInit {
    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param geographieService Service géographie
     * @param route             Route active
     * @param router            Router de l'application
     */
    constructor(public geographieService: GeographieService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.createNewAdresse()
            }]
        );
    }

    /**
     * Création d'une nouvelle adresse
     */
    createNewAdresse() {
        this.router.navigate(["Adresse",0], {relativeTo: this.route});
    }

}
