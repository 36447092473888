import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {VoyagesReferentielsService} from "@services/admin/voyages/voyages-referentiels.service";
import {ToastrService} from "ngx-toastr";
import {TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant de synchronisation des référentiels pour les voyages
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-synchronisations'},
    selector: 'voyages-referentiels-synchronisations',
    templateUrl: './voyages-referentiels-synchronisations.component.html'
})
export class VoyagesReferentielsSynchronisationsComponent {
    /** Information sur si le référentiel des compagnies doit être synchronisé ou non */
    synchroCompagnies: boolean = true;

    /** Information sur si le référentiel des cartes voyageurs doit être synchronisé ou non */
    synchroCartesVoyageurs: boolean = true;

    /** Information sur si le référentiel des classes doit être synchronisé ou non */
    synchroClasses: boolean = true;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param voyagesReferentielsService Service de gestion des référentiels du menu Voyages
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     */
    constructor(private translateService: TranslateService,
                private voyagesReferentielsService: VoyagesReferentielsService,
                private toastrService: ToastrService) {
    }

    /**
     * Méthode appelée lors de l'appui sur le bouton "synchroniser"
     */
    save() {
        this.voyagesReferentielsService.synchroniser(this.synchroCompagnies, this.synchroCartesVoyageurs, this.synchroClasses).subscribe(res => {
            //Si la réponse contient un code d'erreur
            if (res.codeErreur != TypeCodeErreur.NO_ERROR) {
                //Affichage d'un message d'erreur de synchronisation
                this.toastrService.error(this.translateService.instant('admin.voyages.referentiels.synchronisations.erreurSynchronisation'));
            } else {
                //Si la réponse ne contient pas de message d'erreur alors on affiche un message de succès
                this.toastrService.success(this.translateService.instant('admin.voyages.referentiels.synchronisations.succesSynchronisation'));
            }
        })
    }

    /**
     * Méthode appelée lors du changement de la valeur de synchroCartesVoyageurs
     */
    changeCartesVoyageurs() {
        //On met la valeur de synchroCompagnies à true si la valeur de synchroCartesVoyageurs change
        this.synchroCompagnies = true;
    }
}