import {Component, Input} from '@angular/core';
import {ListViewItem} from "../../../../domain/common/list-view/list-view-item";
import {CartePaiement, TypeCarte} from "../../../../domain/profil/cartePaiement";
import * as moment from "moment";

/**
 * Composant pour une ligne de la liste des cartes de paiement
 */
@Component({
    selector: 'profil-donnees-carte-paiement-list-item',
    templateUrl: './profil-donnees-carte-paiement-list-item.component.html'
})
export class ProfilDonneesCartePaiementListItemComponent extends ListViewItem<CartePaiement> {
    /** Déclaration pour utilisation dans le template */
    TypeCarte = TypeCarte;

    /** Elément courant */
    @Input() data: CartePaiement;

    /** Options supplémentaires */
    @Input() extraOptions: any;

    /** État d'échéance proche */
    isSoonToExpire: boolean;

    /** État d'expiration */
    isExpired: boolean;

    /** Initialisation du composant */
    ngOnInit() {
        let momentToday: moment.Moment;
        let dateFin: moment.Moment;

        //Vérification de la date de fin
        if (this.data.dateFin) {
            //Construction des objets Moment
            momentToday = moment().startOf('day');
            dateFin = moment(this.data.dateFin).startOf('day');

            //Calcul de l'état d'expiration
            this.isExpired = this.extraOptions?.settings?.alerteEchuActif && momentToday.isAfter(dateFin);
            this.isSoonToExpire = !this.isExpired && this.extraOptions?.settings?.alerteEcheanceActif && momentToday.add(this.extraOptions?.settings?.delaiAlerteEcheance, 'days').isSameOrAfter(dateFin);
        }
    }
}
