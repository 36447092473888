import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { VoyagesReferentielsCartesVoyageursInfosComponent } from "@components/admin/voyages/referentiels/cartes-voyageurs/cartes-voyageurs-infos/voyages-referentiels-cartes-voyageurs-infos.component";
import { TypesPrestation } from "@domain/admin/voyages/referentiels/carte";
import { CartesVoyagesDto } from "@domain/admin/voyages/referentiels/cartesVoyagesDto";
import { ListViewItem } from "@domain/common/list-view/list-view-item";
import { TranslateService } from "@ngx-translate/core";
import { VoyagesReferentielsService } from "@services/admin/voyages/voyages-referentiels.service";
import { ToastrService } from "ngx-toastr";

/**
 * Composant permettant l'affichage d'une carte voyageur dans la liste
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-cartes-voyageurs-item'},
    selector: 'voyages-referentiels-cartes-voyageurs-item',
    templateUrl: './voyages-referentiels-cartes-voyageurs-item.component.html'
})
export class VoyagesReferentielsCartesVoyageursItemComponent extends ListViewItem<CartesVoyagesDto> implements OnInit {
    /** Types de prestations disponibles pour une carte voyageur */
    typesPresta = [{
        id: 0,
        type: TypesPrestation.AVION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
    },{
        id: 1,
        type: TypesPrestation.TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
    },{
        id: 2,
        type: TypesPrestation.HOTEL,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
    },{
        id: 3,
        type: TypesPrestation.VOITURE_DE_LOCATION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
    },{
        id: 4,
        type: TypesPrestation.VOITURE_SOCIETE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
    },{
        id: 5,
        type: TypesPrestation.VOITURE_PERSONNELLE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
    },{
        id: 6,
        type: TypesPrestation.DOCUMENT,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.document')
    },{
        id: 7,
        type: TypesPrestation.AVION_TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
    },{
        id: 8,
        type: TypesPrestation.AVANCE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avance')
    },{
        id: 9,
        type: TypesPrestation.TRANSPORT_1,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport1')
    },{
        id: 10,
        type: TypesPrestation.TRANSPORT_2,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport2')
    },{
        id: 11,
        type: TypesPrestation.TRANSPORT_3,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport3')
    },{
        id: 12,
        type: TypesPrestation.SERVICE_1,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service1')
    },{
        id: 13,
        type: TypesPrestation.SERVICE_2,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service2')
    },{
        id: 14,
        type: TypesPrestation.SERVICE_3,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service3')
    }];

    /** Types de cartes pour une carte voyageur */
    typeCartes = [{
        id: 0,
        type: 'FIDELITE',
        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.fidelite')
    },{
        id: 1,
        type: 'ABONNEMENT',
        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.abonnement')
    },{
        id: 2,
        type: 'SUPPLIER',
        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.supplier')
    }];

    /** Type de la prestation, à afficher */
    typePresta: string;

    /** Type de la carte, à afficher */
    typeCarte: string;

    /** Enum pour l'utiliser dans le template */
    typesPrestaEnum = TypesPrestation;

    /** Type de la prestation de la carte voyageur, pour afficher l'icône correspondante dans l'avatar */
    avatar: string;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param voyagesReferentielsService Service de gestion des référentiels du menu Voyages
     * @param router Router de l'application
     * @param matDialog Boîte de dialogue
     */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private voyagesReferentielsService: VoyagesReferentielsService,
        public router: Router,
        private matDialog: MatDialog
    ) {
        super();
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération du type de la carte
        let typeCarte = this.data.typeCarte;
        //Boucle pour afficher correctement l'information dans le template
        this.typeCartes.forEach(type => {
            if(type.type === typeCarte) {
                this.typeCarte = type.libelle;
            }
        })

        //Récupération du type de la prestation de la carte
        let typePrestation = this.data.typePrestation;

        //Récupération du type de la prestation correspondant à la valeur de la prestation de la carte voyageur
        let presta = this.typesPresta.find(presta => presta.type === typePrestation);
        this.typePresta = presta.libelle;
        this.avatar = presta.type;
    }

    /**
     * Méthode appelée lors de la sélection d'une carte.
     *
     * @param carteSelected Carte sélectionnée
     */
    openCarte(carteSelected: CartesVoyagesDto) {
        //Ouverture de la popup
        this.matDialog.open(VoyagesReferentielsCartesVoyageursInfosComponent, {
            data: {
                idCarte: carteSelected.idCarte,
            },
            width: '80%'
        }).afterClosed().subscribe({
            next: refresh => {
                if (refresh) {
                    //Rafraichissement de la liste
                    this.liste.refresh();
                }
            }
        });
    }
}
