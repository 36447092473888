import {AfterContentInit,Component,EventEmitter,Input,OnDestroy,OnInit,Output,TemplateRef,ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import {AppState} from '@domain/appstate';
import {TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {FactureListItemComponent} from './facture-list-item/facture-list-item.component';
import {ListeFacture} from '@domain/facture/liste-facture';
import {ReleveFacture} from '@domain/facture/releve-facture';
import {WorkflowService} from '../../workflow/workflow.service';
import {TypeAction,TypePortee} from '@domain/workflow/workflow';
import {AbstractWorkflowListComponent} from '../../workflow/abstract-workflow-list.component';
import {NavigationExtras,Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';
import {AutocompleteService} from "@share/component/autocomplete/autocomplete.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {Subscription} from "rxjs";
import {User} from "@domain/user/user";
import {WFCreationPossible} from "@domain/workflow/wf-creation-possible";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {StatutLitige} from "@domain/facture/facture-contentieux";
import {UserDto} from "@domain/user/user-dto";
import {LotItemType} from "@domain/comptabilite/lot";
import {ComptabiliteService} from "@components/comptabilite/comptabilite.service";
import {TypeAlerteEcartMontant} from "@components/facture/detail/facture.component";
import {FactureService} from "@components/facture/facture.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

@Component({
    host: {'data-test-id': 'facture-list'},
    selector: 'facture-list',
    templateUrl: './facture-list.component.html'
})
export class FactureListComponent extends AbstractWorkflowListComponent<ListeFacture, FactureListItemComponent> implements OnInit,OnDestroy,AfterContentInit {
    /* Déclaration pour accès dans le template */
    TypeAlerteEcartMontant = TypeAlerteEcartMontant;

    /** Gestion des actions dans le composant parent */
    @Input() remoteActions: boolean = false;

    /** Relevé facture */
    @Input() releveFacture: ReleveFacture = null;

    /** Pointeur vers le composant lui-même */
    @Output() onFactureListeLoaded: EventEmitter<FactureListComponent> = new EventEmitter<FactureListComponent>();

    /** Émis lors de la suppression d'une facture depuis les actions WF de la liste */
    @Output() onFactureDeleted: EventEmitter<void> = new EventEmitter<void>();

    /** Référence vers le template pour l'affichage de la popin de détail de l'alerte sur les montants */
    @ViewChild("tplAlerteMontant")
    tplAlerteMontant: TemplateRef<any>;

    /** Souscriptions aux observables à désabonner lors de la destruction du composant */
    listeSubscriptions = new Array<Subscription>();

    /**
     * Constructeur
     */
    constructor(
        protected translateService: TranslateService,
        protected store: Store<AppState>,
        protected router: Router,
        protected workflowService: WorkflowService,
        protected matDialog: MatDialog,
        protected sessionStorageService: SessionStorageService,
        protected cguService: CguService,
        protected toastrService: ToastrService,
        protected autocompleteService: AutocompleteService,
        protected pleaseWaitService: PleaseWaitService,
        private comptabiliteService: ComptabiliteService,
        protected factureService: FactureService
    ) {
        //Constructeur parent
        super(
            translateService,
            store,
            workflowService,
            matDialog,
            TypePortee.FC,
            '/controller/Facture',
            'facture.liste.title',
            '-idFacture',
            'FactureListComponent',
            FactureListItemComponent,
            sessionStorageService,
            cguService,
            toastrService
        );
    }

    /**
     * Initialisation du composant
     */
    async ngOnInit(): Promise<void> {
        //Ajout à la liste des souscriptions en cours
        this.listeSubscriptions.push(
            //Souscription à l'évènement d'exécution d'une action WF
            this.onActionWorkflowDone.subscribe((param) => {
                //Si l'action était une suppression et qu'elle s'est correctement déroulée
                if (param.typeAction === TypeAction.SUPPRIMER && param.success) {
                    //On avertit le parent de la suppression
                    this.onFactureDeleted.emit();
                }
            })
        );

        //Déclenchement du code dans le parent
        return super.ngOnInit();
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        this.listeSubscriptions?.forEach(sub => sub.unsubscribe());

        //Déclenchement du code dans le parent
        super.ngOnDestroy();
    }

    /**
     * Retourne l'uri de la liste des factures en fonction du relevé en cours
     */
    protected get listeUri(): string {
        //Si on est dans un relevé
        if (this.releveFacture) {
            //On redirige vers la liste des factures du relevé
            return `${this.controllerUri}/listeFacture/${this.releveFacture.idFactureReleve}`;
        } else {
            //Sinon, on redirige vers la liste standard
            return super.listeUri;
        }
    }

    /**
     * Spécificités de la liste héritière
     *
     * @param initOnlyColumns True si on veut initialiser uniquement les colonnes de la liste (false par défaut)
     */
    protected initListSpecific(initOnlyColumns?: boolean): void {
        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'idFacture', title: 'facture.liste.columns.idFacture' },
            { key: 'numero', title: 'facture.liste.columns.numero' },
            { key: 'factureReleve.fournisseur.raisonSociale', title: 'facture.liste.columns.factureReleve.fournisseur.raisonSociale' },
            { key: 'date', title: 'facture.liste.columns.date' },
            { key: 'type', title: 'facture.liste.columns.type' },
            { key: 'listeLiensFacOD.idOd', title: 'facture.liste.filtres.reconciliation' },
            { key: 'user.matricule', title: 'facture.liste.columns.user.matricule' },
            { key: 'contentieux.litige', title: 'facture.liste.columns.contentieux.litige' },
            { key: 'statut.idStatut', title: 'facture.liste.columns.statut' }
        ];

        //Si on ne veut pas initialiser que les colonnes
        if (!initOnlyColumns) {
            //Ajout des filtres spécifiques
            this.liste.listeFilters = [
                ...[
                    {
                        //Ajout du filtre sur le fournisseur
                        clef: 'factureReleve.fournisseur.raisonSociale',
                        title: this.translateService.instant('facture.liste.filtres.fournisseur'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur le numéro
                        clef: 'numero',
                        title: this.translateService.instant('facture.liste.filtres.numero'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur la date
                        clef: 'date',
                        title: this.translateService.instant('facture.liste.filtres.date'),
                        type: TypeFilter[TypeFilter.DATE]
                    }, {
                        //Ajout du filtre sur le type
                        clef: 'type',
                        title: this.translateService.instant('facture.liste.filtres.type'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        listeValues: [{
                            code: 'FAC',
                            libelle: this.translateService.instant('facture.liste.filtres.typeFAC')
                        }, {
                            code: 'AVO',
                            libelle: this.translateService.instant('facture.liste.filtres.typeAVO')
                        }]
                    }, {
                        //Ajout du filtre sur la réconciliation
                        clef: '*listeLiensFacOD.idOd,*user.nom,*user.prenom,*user.matricule',
                        title: this.translateService.instant('facture.liste.filtres.reconciliation'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur la réconciliation
                        clef: 'user.matricule',
                        title: this.translateService.instant('facture.liste.filtres.matricule'),
                        isDefault: true,
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                    }, {
                        //Ajout du filtre sur l'alerte d'écart de montant devis
                        clef: 'alerteEcartDevis',
                        title: this.translateService.instant('facture.liste.filtres.ecartDevis'),
                        type: TypeFilter[TypeFilter.BOOLEAN],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                    }, {
                        //Ajout du filtre sur le litige
                        clef: '#factureLitige',
                        title: this.translateService.instant('facture.liste.columns.contentieux.litige'),
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        listeValues: [{
                            code: StatutLitige[StatutLitige.AUCUN],
                            libelle: this.translateService.instant('facture.liste.columns.contentieux.litige.aucun')
                        },{
                            code: StatutLitige[StatutLitige.OUVERT],
                            libelle: this.translateService.instant('facture.liste.columns.contentieux.litige.ouvert')
                        }, {
                            code: StatutLitige[StatutLitige.FERME],
                            libelle: this.translateService.instant('facture.liste.columns.contentieux.litige.ferme')
                        }]
                    },
                ],
                ...this.liste.listeFilters
            ];
        }

        //Lorsqu'on est sur un relevé (la liste peut être affichée en standalone lorsque l'utilisateur connecté n'est pas comptable)
        if (this.releveFacture) {
            //Mise à jour du nombre total de factures affichable au rafraichissement de la liste
            this.liste.onRefresh = (liste,result) => {
                this.releveFacture.facturesCountForAlerte = result.nbObjetsTotal;
            };
        }
    }

    /**
     * Finalisation
     */
    ngAfterContentInit(): void {
        //Emission du pointeur vers le composant lui-même
        this.onFactureListeLoaded.emit(this);
    }

    /**
     * Création d'un objet
     *
     * @param collab Collab pour lequel on crée l'objet WF (cas d'un assistant/responsable/comptable)
     */
    protected create(collab?: UserDto): void {
        //Affichage de la popup de sélection du type entité
        this.autocompleteService.showSearch({
            type: 'typeEntite',
            filter: {
                idPortee: TypePortee.FC,
                idCollab: collab?.idUser
            }
        }).subscribe({
            next: (typeEntite: TypeEntite) => {
                if (typeEntite) {
                    //Définition d'un objet contenant les données à passer au composant pour la création
                    const extras: NavigationExtras = {
                        state: {
                            idReleve: this.releveFacture?.idFactureReleve,
                            idTypeEntite: typeEntite.idTypeEntite
                        }
                    };

                    //Si on vient d'un relevé
                    if (this.releveFacture?.idFactureReleve) {
                        //On rajoute le relevé dans la navigation pour retourner sur le relevé et pas la liste des factures
                        this.router.navigate(['ReleveFacture',this.releveFacture.idFactureReleve,'Facture',0],extras);
                    } else {
                        //On redirige sur une nouvelle facture
                        this.router.navigate(['Facture',0],extras);
                    }
                }
            }
        });
    }

    /**
     * Permet la création d'un lot comptable depuis la sélection
     */
    protected comptabiliser() {
        //Mise en session des items à ajouter au lot
        this.comptabiliteService.setItemsForLot(LotItemType.FACTURE,this.liste.data.listeResultats);

        //Création d'un nouveau lot
        this.router.navigate(['Lot', 0]);
    }

    /**
     * Retourne l'objet qui définit la possibilité de création d'objet
     *
     * @param user Utilisateur connecté
     */
    protected getWFCreationPossible(user: User): WFCreationPossible {
        //Récupération des droits de création par défaut
        let wfCreationPossible = new WFCreationPossible(user.creationsPossibles);

        //Dans le cas d'un relevé importé
        if (this.releveFacture && this.releveFacture.importe) {
            //La création de facture n'est pas possible
            wfCreationPossible.facturePossible = false;
        }

        return wfCreationPossible;
    }

    /**
     * Ajout des alertes spécifiques à la liste des factures
     *
     * @param listeResultats L'objet ListeFacture, correspondant à une ligne de la liste
     */
    protected initAlertSpecific(listeResultats: Array<ListeFacture>) {
        listeResultats?.forEach(objet => {
            const listeAlertes: Array<Alerte> = new Array<Alerte>();

            //Vérification de la non-désactivation des alertes et de la présence d'un écart entre devis et facture
            if (objet.alerteEcartDevis) {
                //Ajout de l'alerte
                listeAlertes.push(new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    template: {
                        tpl: this.tplAlerteMontant,
                        ctx: {
                            type: TypeAlerteEcartMontant.FACTURE_ECART_DEVIS,
                            objet: objet
                        }
                    }
                }));
            }

            //Vérification de l'écart de montant
            if (objet.alerteMontantControle) {
                //Ajout de l'alerte
                listeAlertes.push(new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    template: {
                        tpl: this.tplAlerteMontant,
                        ctx: {
                            type: TypeAlerteEcartMontant.FACTURE_MONTANT_CONTROLE,
                            objet: objet
                        }
                    }
                }));
            }

            //Vérification de la présence d'alertes spécifiques à ajouter
            if (listeAlertes.length > 0) {
                //Initialisation de la liste des alertes le cas échéant
                objet.listeAlertes = objet.listeAlertes ?? new ListeAlertes();

                //Ajout des alertes spécifiques
                objet.listeAlertes.add(listeAlertes);
            }
        });
    }

    /** @inheritdoc */
    protected hideDisplayItems(displayMode: boolean) {
        //Appel au service
        this.factureService.hideDisplay(this.liste.listeObjetsSectionnes.map(f => f.idFacture),displayMode)
            .subscribe((result: Result) => {
                //Vérification du result
                if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast succès
                    this.toastrService.success(this.translateService.instant(`global.success.${displayMode ? 'demasquer' : 'masquer'}`,{nb: result.data.nbItems}));

                    //Refresh liste
                    this.liste.refresh();
                } else {
                    //Gestion de l'erreur
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            });
    }
}
