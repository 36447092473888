import {Component, Input, OnInit} from '@angular/core';
import {SuiviBudget} from "../../../domain/budget/suivi-budget";
import {BudgetService} from "../budget.service";
import {finalize, first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {NiveauAlerte} from "../../../domain/common/alerte/alerte";
import * as settingsActions from "../../../reducers/settings";
import {TypePortee} from "../../../domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {AppState} from "../../../domain/appstate";
import {SettingsGlobalState} from "../../../domain/settings/settings";

/**
 * Composant d'affichage de la consommation des enveloppes budgétaires par un objet
 *
 * @author Laurent Convert
 * @date 06/04/2022
 */
@Component({
    host: {'data-test-id': 'suivi-budget'},
    selector: 'suivi-budget',
    templateUrl: './suivi-budget.component.html'
})
export class SuiviBudgetComponent implements OnInit {
    /** Accès à l'enum dans la vue */
    NiveauAlerte = NiveauAlerte;

    /** Type de l'objet */
    @Input()
    idTypeObjet: number;

    /** Identifiant de l'objet */
    @Input()
    idObjet: number;

    /** Etat du chargement */
    isLoading = false;

    /** Liste des suivis budgétaires */
    listeSuivi: Array<SuiviBudget>;

    /** Paramétrage */
    settings: SettingsGlobalState;

    /**
     * Constructeur
     */
    constructor(private budgetService: BudgetService,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private store: Store<AppState>) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.BGT
        });

        //Sélection du paramétrage pour savoir si le suivi budgétaire est activé
        this.store.select(state => state.settings?.[TypePortee.BGT]).subscribe(settings => {
            this.settings = settings;

            //Si le suivi budgétaire est activé
            if (this.settings?.isSuiviBudgetaire) {
                //Chargement du suivi budgétaire
                this.loadSuiviBudget();
            }
        });
    }

    /**
     * Chargement des données relatives à l'objet
     */
    loadSuiviBudget() {
        //Chargement en cours
        this.isLoading = true;

        //Chargement des données
        this.budgetService.loadSuiviBudget(this.idTypeObjet,this.idObjet)
            .pipe(first(),finalize(() => this.isLoading = false))
            .subscribe(
                (result) => {
                    //Récupération de la liste des suivis
                    this.listeSuivi = result.filter(s => s.montant > 0);
                },
                () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.chargement'));
                }
            );
    }

}