<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.externes.editExternePopin.title.' + (externe?.idParticipantExterne ? 'modification' : 'creation')"></span>
    <span [mat-dialog-close]="refreshAtClosing"><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="page-header" *ngIf="alerteFusion">
    <card-alerte [niveau]="NiveauAlerte.NO_CONTROL">
        <preview-alerte [alerte]="alerteFusion"></preview-alerte>
    </card-alerte>
</div>
<div mat-dialog-content *ngIf="externe">
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="societe"
                    name="societe"
                    customType="input"
                    [(ngModel)]="externe.societe"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.societe"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="actif"
                    name="actif"
                    customType="checkbox"
                    [ngModel]="externe.actif"
                    [(customModel)]="externe.actif"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.actif">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="nom"
                    name="nom"
                    customType="input"
                    [(ngModel)]="externe.nom"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.nom"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="reference"
                    name="reference"
                    customType="input"
                    [(ngModel)]="externe.reference"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.reference">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="prenom"
                    name="prenom"
                    customType="input"
                    [(ngModel)]="externe.prenom"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.prenom"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="fonction"
                    name="fonction"
                    customType="input"
                    [(ngModel)]="externe.fonction"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.fonction">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="telephone"
                    name="telephone"
                    customType="input"
                    [(ngModel)]="externe.telephone"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.telephone">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="email"
                    name="email"
                    customType="input"
                    [(ngModel)]="externe.email"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.email">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="add1"
                    name="add1"
                    customType="input"
                    [(ngModel)]="externe.add1"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.add1">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="add2"
                    name="add2"
                    customType="input"
                    [(ngModel)]="externe.add2"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.add2">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="add3"
                    name="add3"
                    customType="input"
                    [(ngModel)]="externe.add3"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.add3">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="add4"
                    name="add4"
                    customType="input"
                    [(ngModel)]="externe.add4"
                    libelle="admin.entreprise.utilisateurs.externes.editExternePopin.add4">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button *ngIf="externe.idParticipantExterne && externe.type !== TypeExterne.ADMINISTRATEUR" mat-stroked-button color="primary" (click)="onPromote()" [disabled]="isPending">
        <span [translate]="'admin.entreprise.utilisateurs.externes.editExternePopin.promouvoir'"></span>
    </button>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="form?.invalid || isPending">
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>
