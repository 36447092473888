import {CollabInfo} from "@domain/user/collab-info";

/**
 * Modèle d'un collab
 */
export class Collaborateur {
	idCollab: number;
	matricule: string;
	idCateg: number;
	idTitre: number;
	field1: string;
	field2: string;
	field3: string;
	field4: string;
	numCompta: string;
	deplaf: boolean;
	avances: boolean;
	compteAvance: string;
	modeRemb: string;
	idModeRemb: number;
	codeDevise: string;
	omPermanent: boolean;
	idUnite: number;
	modificateurIndemns: number;
	infosMetier: CollabInfo;
}
