import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from '@domain/common/list-view';
import {PaysItem} from "@domain/admin/bibliotheque/geographie/paysItem";
import {ZonePerimetreItemComponent} from "@components/admin/bibliotheque/geographie/zone/details/perimetre/zone-perimetre-item/zone-perimetre-item.component";
import {Subscription} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {Sorting} from '@domain/common/list-view/sorting';
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {ZonePerimetreAddComponent} from "@components/admin/bibliotheque/geographie/zone/details/perimetre/zone-perimetre-add/zone-perimetre-add.component";
import {MatDialog} from "@angular/material/dialog";

/**
 * Composant d'affichage de l'onglet Périmètre d'une Zone
 */
@Component({
	host: {'data-test-id': 'zone-perimetre'},
	selector: 'zone-perimetre',
	templateUrl: './zone-perimetre.component.html'
})
export class ZonePerimetreComponent implements OnInit, OnDestroy {

	/** Identifiant de la zone */
	@Input() idZone: number;

	/** Liste des périmètres */
	listePerimetres: ListView<PaysItem,ZonePerimetreItemComponent>;

	/** Souscription à l'indicateur de refresh de la liste */
	refreshSub: Subscription;

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param geographieService	Service géographie
	 * @param matDialog			Boîte de dialogue
	 */
	constructor(private translateService: TranslateService,
				private geographieService: GeographieService,
				private matDialog: MatDialog) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition de la liste des périmètres
		this.listePerimetres = new ListView<PaysItem,ZonePerimetreItemComponent>({
			uri: `/controller/Geographie/Zone/${this.idZone}/getListePays`,
			title: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.perimetre'),
			component: ZonePerimetreItemComponent,
			defaultOrder: 'libelle',
			listeFilters: [{
				clef: 'libelleNettoye',
				title: this.translateService.instant('filter.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			}],
			listeActions: [{
				icon: 'add',
				onPress: () => this.addPerimetre()
			}],
			isFilter: true
		})

		this.listePerimetres.columns = [
			{key: 'libelleNettoye',title: 'filter.libelle'}
		]

		this.listePerimetres.sorting = new Sorting(this.listePerimetres.columns,"libelleNettoye");

		//Refresh la liste si besoin (après suppression)
		this.refreshSub = this.geographieService.refreshListePerimetresZone$.subscribe(() => {
				this.listePerimetres.refresh();
			}
		)
	}

	/**
	 * Destruction
	 */
	ngOnDestroy() {
		this.refreshSub.unsubscribe();
	}

	/**
	 * Ajout d'un périmètre
	 */
	addPerimetre() {
		//Ouverture de la boîte de dialogue
		this.matDialog.open(ZonePerimetreAddComponent,{
			data: {
				idZone: this.idZone
			}
		}).afterClosed().subscribe({
			next: retour => {
				if (retour) {
					if (retour.success) {
						//Refresh la liste des périmètres si ajout successful
						this.listePerimetres.refresh();
					}
				}
			}
		});
	}

}