import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {first,mergeMap} from "rxjs/operators";
import {EntityWrapper} from "@domain/entite/EntityWrapper";
import {MatDialog} from "@angular/material/dialog";
import {BusinessDataPopinComponent} from "@share/component/rule-builder/business-data/business-data-popin.component";
import {EntityWrapperMember} from "@domain/entite/EntityWrapperMember";
import {EntiteService} from "@share/component/rule-builder/entite.service";

@Injectable()
export class BusinessDataService {
	/**
	 * Constructeur
	 *
	 * @param entiteService le service de gestion des entité
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private entiteService: EntiteService,
		private matDialog: MatDialog
	) {
	}

	/**
	 * Ouverture de la popin de sélection des données métier
	 *
	 * @param entite l'entité
	 * @param show1n l'affichage des relations 1n est-il autorisé ?
	 */
	showBusinessData(entite: string,show1n: boolean): Observable<EntityWrapperMember> {
		//Chargement des données métiers de l'entité
		return this.entiteService.loadBusinessData(entite).pipe(
			first(),
			mergeMap((businessData: EntityWrapper) => {
					//Ouverture de la popin et return de la promesse de fermeture
					return this.matDialog.open(BusinessDataPopinComponent,{
						data: {
							entite,
							businessData,
							show1n
						}
					}).afterClosed();
				}
			));
	}
}
