import {Component, Input} from "@angular/core";
import {ConfigLogging} from "@domain/admin/logs/config-logging";

/**
 * Composant de gestion du fichier de logs
 */
@Component({
    host: {'data-test-id': 'fichier-logs'},
    selector: 'fichier-logs',
    templateUrl: './fichier-logs.component.html'
})
export class FichierLogsComponent {
    /** Config */
    @Input() configLoging: ConfigLogging;
}
