import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Status,Vehicule} from "@domain/vehicule/vehicule";
import {Router} from "@angular/router";
import {AlertesVehicule} from "@domain/vehicule/alertesVehicule";
import {TranslateService} from "@ngx-translate/core";
import {VehiculeService} from "@components/vehicule/vehicule.service";
import {MatDialog} from "@angular/material/dialog";
import {VehiculeListAlerteComponent} from "@components/vehicule/list/vehicule-list-alerte.component";

/**
 * Item de la liste des véhicules (menu admin Entreprise > Véhicules)
 */
@Component({
    host: {'data-test-id': 'vehicule-list-item'},
    templateUrl: './vehicule-list-item.component.html'
})
export class VehiculeListItemComponent extends ListViewItem<Vehicule> implements OnInit {
    /** Alertes */
    alertes: AlertesVehicule;

    /**
     * Constructeur
     *
     * @param router Router
     * @param translateService Service des traductions
     * @param vehiculeService Service des véhicules
     * @param matDialog Service des popin
     */
    constructor(private router: Router,
                private translateService: TranslateService,
                private vehiculeService: VehiculeService,
                private matDialog: MatDialog) {
        super();
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Mise en place des alertes
        this.alertes = this.vehiculeService.buildAlertes(this.data,this.extraOptions?.settings);
    }

    /**
     * Ouverture d'un véhicule
     */
    navigateToVehicule(): void {
        //Navigation vers le véhicule
        this.router.navigate(['Admin/Entreprise/Vehicules/Vehicules/' + this.data.idPa]);
    }

    /**
     * Permet de récupérer la traduction du statut véhicule
     * @param status Status
     */
    getStatusLibelle(status: Status) {
        switch (status) {
            case Status.STATUT_NON_TRAITE:
                return this.translateService.instant('vehicule.statut.nonTraite');
            case Status.STATUT_VALIDE:
                return this.translateService.instant('vehicule.statut.valide');
            case Status.STATUT_REFUSE:
                return this.translateService.instant('vehicule.statut.refuse');
            case Status.STATUT_BROUILLON:
                return this.translateService.instant('vehicule.statut.brouillon');
            default:
                return "";
        }
    }

    /**
     * Permet d'afficher la liste des alertes
     */
    showListeWarning() {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(VehiculeListAlerteComponent, {
            data: {
                alertes: this.alertes,
                approbation: this.data.vehiculeApprobation
            },
        });
    }

}
