<h1 mat-dialog-title>
    <span [translate]="assurance?.id ? 'vehicule.formulaire.documents.assurance' : 'vehicule.formulaire.documents.ajoutAssurance'" [translateParams]="{numContrat: assurance?.numContrat}"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait class="content" *ngIf="!assurance"></please-wait>
    <form autocomplete="off" #form="ngForm">
        <fieldset *ngIf="assurance" [disabled]="!canModifier">
            <div class="row" [ngClass]="{ 'has-error': numContrat.invalid }">
                <label class="col-md-4 text-right required" [for]="numContrat">
                    <span [translate]="'vehicule.formulaire.documents.reference'"></span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #numContrat="ngModel" [(ngModel)]="assurance.numContrat" matInput name="reference" required>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': dateDeb.invalid }" class="row">
                <label class="col-md-4 text-right required" [for]="dateDeb">
                    <span [translate]="'vehicule.formulaire.documents.dateDeb'"></span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #dateDeb="ngModel" matInput name="dateDeb" required
                               [(ngModel)]="assurance.dateDebut"
                               [matDatepicker]="$any(dateDebPicker)"
                               [max]="assurance.dateFin"
                               [errorStateMatcher]="errorStateMatcherDates">
                        <mat-datepicker-toggle [for]="dateDebPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #dateDebPicker [disabled]="!canModifier"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': dateFin.invalid }" class="row">
                <label class="col-md-4 text-right required" [for]="dateFin">
                    <span [translate]="'vehicule.formulaire.documents.dateFin'"></span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <!-- Date de fin (control : inférieur o la date de début et à la date courante) -->
                        <input #dateFin="ngModel" matInput name="dateFin" required
                               [(ngModel)]="assurance.dateFin"
                               [matDatepicker]="$any(dateFinPicker)"
                               [min]="!assurance.dateDebut || DateUtilsService.compareDates(assurance.dateDebut,aujourdhui) < 0 ? aujourdhui : assurance.dateDebut"
                               [errorStateMatcher]="errorStateMatcherDates">
                        <mat-datepicker-toggle [for]="dateFinPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #dateFinPicker [disabled]="!canModifier"></mat-datepicker>
                        <mat-error *ngIf="assurance.dateDebut && assurance.dateFin && DateUtilsService.compareDates(assurance.dateDebut,assurance.dateFin) > 0"><span [translate]="'global.errors.dateFinAndDateDeb'"></span></mat-error>
                        <mat-error *ngIf="errorStateMatcherDates?.isDoublon()"><span [translate]="'vehicule.formulaire.errors.assuranceDoublon'"></span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-4"><span [translate]="'ndf.frais.remarque'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <textarea [(ngModel)]="assurance.commentaire" matInput name="remarque" rows="3"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
    <document-list #listeDocument *ngIf="assurance && settings" [idObjet]="assurance.id" [idObjetForPJ]="vehicule.idCollab" [context]="'PROFIL_VEHICULE_ASSURANCE'" [settings]="settings"
                   [isSimpleDocumentList]="true" [canAddDocument]="canModifier" [canDeleteDocument]="canModifier"></document-list>
</div>
<div mat-dialog-actions align="end" *ngIf="assurance && canModifier">
    <button (click)="deleteAssurance()" *ngIf="assurance.id && (vehicule.statut == Status.STATUT_REFUSE || vehicule.statut == Status.STATUT_BROUILLON)" [disabled]="isSaving" color="warn" mat-stroked-button>
        <span [translate]="'global.actions.supprimer'"></span>
    </button>
    <button (click)="saveAssurance()" [disabled]="form?.invalid || errorStateMatcherDates?.invalid || isSaving" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
