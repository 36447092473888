import { NgModule } from '@angular/core';

import { DashboardComponent } from './dashboard.component';
import { ShareModule } from '../../share/share.module';
import { ObjetsListComponent } from './listes/objets/objets-list.component';
import { ObjetsListItemComponent } from './listes/objets/objets-list-item.component';
import { DashboardService } from "./dashboard.service";
import { DashboardOdListComponent } from './listes/od/dashboard-od-list.component';
import { DashboardOdListItemComponent } from './listes/od/dashboard-od-list-item.component';
import { InfosListComponent } from './listes/infos/infos-list.component';
import { InfosListItemComponent } from './listes/infos/infos-list-item.component';
import { DashboardNdfListComponent } from './listes/ndf/dashboard-ndf-list.component';
import { DashboardNdfListItemComponent } from './listes/ndf/dashboard-ndf-list-item.component';
import { DashboardAvancesListItemComponent } from './listes/avances/dashboard-avances-list-item.component';
import { DashboardAvancesListComponent } from './listes/avances/dashboard-avances-list.component';
import { DashboardOmpListComponent } from './listes/omp/dashboard-omp-list.component';
import { DashboardOmpListItemComponent } from './listes/omp/dashboard-omp-list-item.component';
import { InfoComponent } from './listes/infos/detail/info.component';
import { OdListComponent } from "../od/od-list/od-list.component";
import { NdfListComponent } from "../ndf/ndf-list/ndf-list.component";
import { OmpListComponent } from "../omp/omp-list/omp-list.component";
import { InfosListExtendedComponent } from './listes/infos/infos-list-extended/infos-list-extended.component';
import { WorkflowModule } from "../workflow/workflow.module";

@NgModule({
    imports: [ShareModule,WorkflowModule],
    declarations: [DashboardComponent, ObjetsListComponent, ObjetsListItemComponent, DashboardOdListComponent, DashboardOdListItemComponent, InfosListComponent, InfosListItemComponent, DashboardNdfListComponent, DashboardNdfListItemComponent, DashboardAvancesListItemComponent, DashboardAvancesListComponent, DashboardOmpListComponent, DashboardOmpListItemComponent, InfoComponent, InfosListExtendedComponent],
    providers: [DashboardService, OdListComponent, NdfListComponent, OmpListComponent]
})
export class DashboardModule {
}