import {ListItem} from "@domain/common/list-view/list-item";
import {TypePortee} from "@domain/workflow/workflow";
import {Statut} from "@domain/workflow/statut";
import {Rule} from "@domain/rule/rule";

/**
 * Classe représentant une condition
 */
export class Condition implements ListItem {
	idCondition: number;
	idPortee: TypePortee;
	libelle: string;
	sql?: string;
	commentaire?: string;
	commentaireAdmin?: string;
	typeCondition: TypeCondition;
	niveauAlerte: number;
	fonctions?: string;
	listeStatuts: Statut[];
	rule: Rule;

	/**
	 * Constructeur
	 *
	 * @param type le type de condition à créer
	 */
	constructor(type: TypeCondition) {
		this.typeCondition = type;
	}

	getKey(): number {
		return this.idCondition;
	}
}

/**
 * Énumération des types de condition
 */
export enum TypeCondition {
	ALERTE = 'A',
	USER = 'U',
	WORKFLOW = 'W'
}