import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";

/**
 * Composant pour afficher une langue dans la liste des langues
 */
@Component({
    host: {'data-test-id': 'langue-item'},
    templateUrl: './langue-item.component.html'
})
export class LangueItemComponent extends ListViewItem<Langue> {
}
