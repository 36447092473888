<!-- TODO LCO : ADMIN_V10_BETA - Mise en place d'une vraie page d'accueil ? -->
<!-- <mat-card>
    <div class="ribbon ribbon-top-right"><span>V10 BETA</span></div>
    <mat-card-content>
        <br>
        <h2>Bienvenue sur l'interface d'administration</h2>
        <br>
        <br>
        <br>
    </mat-card-content>
</mat-card> -->
<router-outlet></router-outlet>