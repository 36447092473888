<!-- Entête -->
<h1 mat-dialog-title>
    <span [translate]="'od.voyage.voyage'"></span>
    <span class="actions" [mat-dialog-close]>
            <span><i class="zmdi zmdi-close"></i></span>
        </span>
</h1>

<!-- Contenu -->
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let niveauNature of listeSbtParNature | async | triListeSbt" (click)="selectionne(niveauNature)">
                <ng-container *ngTemplateOutlet="optionTravel; context:{
                        portee:niveauNature.nature,
                        isOnline:niveauNature.getSbtPrincipal().typeAiguillage === TypeAiguillage.ONLINE,
                        isOffline:niveauNature.getSbtPrincipal().typeAiguillage === TypeAiguillage.OFFLINE || niveauNature.listeSbt.length > 1}">
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #optionTravel let-portee="portee" let-isOnline="isOnline" let-isOffline="isOffline">
    <div class="lvi-content clickable">
        <div class="avatar" *ngIf="TypeNature.icone(portee) as iconTypeNature">
            <!-- Icône de l'option -->
            <span [class]="iconTypeNature.cssClass">{{ iconTypeNature.icon }}</span>
        </div>
        <div class="lvi-body">
            <div class="title">
                <a><strong>{{ TypeNature.traduction(portee) | translate }}</strong></a>
            </div>
            <!-- Bulles -->
            <ul class="lvi-attrs">
                <li><strong translate>od.voyage.travel.reservation</strong>{{ (isOnline && isOffline ? 'od.voyage.travel.directOuAgence' : isOnline ? 'od.voyage.travel.direct' : 'od.voyage.travel.agence') | translate }}</li>
            </ul>
        </div>
    </div>
</ng-template>
