<div class="with-floating-button">
    <div class="content" *ngIf="report">
        <page-header [title]=" isCreation ? ('admin.bibliotheque.reporting.infosReporting.titleCreation' | translate) : libelle" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()" [extraInfo]="('admin.bibliotheque.reporting.infosReporting.' + (report.standard ? 'standard' : 'nonStandard')) | translate">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.GENERALITES">
            <form #form="ngForm" *ngIf="report">
                <reporting-infos-generalites [report]="report" [isCreation]="isCreation"></reporting-infos-generalites>
                <reporting-infos-fichier #fichierComponent [report]="report"></reporting-infos-fichier>
            </form>
        </div>

        <div *ngIf="!isCreation" [hidden]="selectedItem?.code !== Onglet.PARAMETRES">
            <parametres [report]="report" [isCreation]="isCreation"></parametres>
        </div>
    </div>
    <mat-spinner diameter="20" *ngIf="!report"></mat-spinner>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>
