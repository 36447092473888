import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {ODCreationItemComponent} from './od-creation-item.component';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
    templateUrl: './od-creation.component.html',
    host: {'data-test-id': 'od-creation'}
})
export class ODCreationComponent implements OnInit {
    /** Liste des missions */
    liste: ListView<any, ODCreationItemComponent>;

    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: {idCollab?: number},private translateService: TranslateService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Définition de la liste
        this.liste = new ListView<any, ODCreationItemComponent>({
            uri: '/controller/OD/filtreMissionsPermPourCreation' + (this.data.idCollab ? '?idCollab=' + this.data.idCollab : ''),
            isLocal: true,
            component: ODCreationItemComponent,
            listeFilters: [{
                title: this.translateService.instant('filter.numeroMissionPermanente'),
                clef: 'idOMPermanent',
                type: TypeFilter[TypeFilter.LONG],
                typeComparaison: 'EQUAL',
                isDefault: true
            }, {
                title: this.translateService.instant('filter.objet'),
                clef: 'objet',
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            }, {
                title: this.translateService.instant('filter.typeEntite'),
                clef: 'typeEntite.libelle',
                isDefault: true
            }, {
                title: this.translateService.instant('filter.pays'),
                clef: '*pays.libelle',
                isDefault: true
            }, {
                title: this.translateService.instant('filter.ville'),
                clef: '*ville.libelle',
                isDefault: true
            }],
            defaultOrder: '-idOMPermanent',
            onRefresh: (liste: ListView<any, ODCreationItemComponent>, result: any) => {
                //Vérification de la page
                if (result.numPage == 0) {
                    //Ajout d'un élément virtuel
                    liste.data.listeResultats = [{}, ...liste.data.listeResultats];
                }

                //Vérification du nombre de résultats
                if (liste.data.listeResultats?.length > 1 || liste.listeSelectedFilters?.length > 0) {
                    //Affichage du filtre
                    liste.isFilter = true;
                    liste.title = this.translateService.instant('menu.missionsPermanentes');
                } else {
                    //Masquage du filtre
                    liste.isFilter = false;
                    liste.title = undefined;
                }
            },
            extraOptions: {
                idCollab: this.data.idCollab
            }
        });
    }
}
