import {ListItem} from "@domain/common/list-view/list-item";
import {Compte} from "@domain/admin/comptabilite/compte";
import {TranslateService} from "@ngx-translate/core";
import {FilterValue} from "@domain/common/list-view";
import {Localisation} from "@domain/geolocalisation/localisation";

/**
 * Classe représentant un mode de remboursement
 *
 * @author Laurent Convert
 * @date 15/06/2023
 */
export class ModeRemb implements ListItem {
	idModeRemb: number;
	libelle: number;
	code: string;
	typeRemb: TypeRemb;
	compte: Compte;
	defaut: boolean;
	actif: boolean;
	iban: string;
	bic: string;
	numEmetteur: string;
	idLocalisation: number;
	adresse: Localisation;

	/**
	 * Constructeur
	 *
	 * @param dto DTO ou objet source à copier
	 */
	constructor(dto?: any|ModeRemb) {
		if (dto) {
			//Copie
			Object.assign(this,dto);

			//Transformation en instance du compte
			if (dto.compte) {
				this.compte = new Compte(dto.compte);
			}

			//On s'assure d'avoir une valeur dans les champs booléens, sinon paf le custom-input :x
			this.actif = dto.actif === true;
			this.defaut = dto.defaut === true;
		}
	}

	getKey(): number | string {
		return this.idModeRemb;
	}
}

/**
 * Énuméré des types de remboursement
 */
export enum TypeRemb {
	VIREMENT=0,
	DEVISE=1,
	CHEQUE=2,
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace TypeRemb {
	/**
	 * Les enums en JS/TS c'est du caca, alors on émule une fonction values() pour renvoyer un tableau contenant tous les éléments de l'énuméré et pouvoir itérer dessus...
	 */
	export function values(): Array<TypeRemb> {
		return [TypeRemb.VIREMENT,TypeRemb.DEVISE,TypeRemb.CHEQUE];
	}

	/**
	 * Renvoi la liste des filtres de recherche à partir des différents types de remboursement possibles
	 *
	 * @param translateService Service de traduction
	 */
	export function listeFilters(translateService: TranslateService): Array<FilterValue> {
		return TypeRemb.values().map(typeRemb => {
			return {
				code: typeRemb.toString(10),
				libelle: TypeRemb.traduction(typeRemb,translateService)
			}
		});
	}

	/**
	 * Traduction du type de remboursement.
	 * Renvoi la chaîne traduite si le service est passé en paramètre et le code de traduction dans le cas contraire.
	 *
	 * @param typeRemb Type de remboursement
	 * @param translateService Service de traduction (facultatif, dans ce cas seul le code de traduction sera renvoyé au lieu de la chaîne traduite)
	 */
	export function traduction(typeRemb: TypeRemb,translateService?: TranslateService): string {
		let code;

		//Suivant le type de remboursement
		switch (typeRemb) {
			case TypeRemb.VIREMENT:
				code = 'admin.comptabilite.modeRemb.typeRemb.virement';
				break;
			case TypeRemb.DEVISE:
				code = 'admin.comptabilite.modeRemb.typeRemb.devise';
				break;
			case TypeRemb.CHEQUE:
				code = 'admin.comptabilite.modeRemb.typeRemb.cheque';
				break;
		}

		//Vérification du passage du service de traduction
		if (translateService) {
			//Traduction
			code = translateService.instant(code);
		}

		return code;
	}
}