import {Component,Input} from '@angular/core';
import {ControlContainer,NgForm} from '@angular/forms';
import {Field,FieldContainer} from '@domain/settings/field';

/**
 * Composant affichant les zones prédéfinies sur les objets
 */
@Component({
    host: {'data-test-id': 'zone-predefinie'},
	selector: 'zone-predefinie',
	templateUrl: './zone-predefinie.component.html',
    viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})

export class ZonePredefinieComponent {
    /** Champs customs */
    @Input() listeFieldParam: Field[];

    /** Objet contenant les champs fields */
    @Input() fieldContainer: FieldContainer;

    /** Permet de disable les champs non actifs */
    @Input() isDisableInactive?: boolean = false;
}