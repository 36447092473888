import {Injectable,Type} from "@angular/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {RoleListe} from "@domain/workflow/roleListe";
import {TypeProfil} from "@domain/user/user";
import {Sorting} from "@domain/common/list-view/sorting";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {RoleLong} from "@domain/workflow/roleLong";
import {Result} from "@domain/common/http/result";
import {ItemHierarchique} from "@share/component/affichage-hierarchique/ItemHierarchique";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Service de gestion des Rôles
 */
@Injectable()
export class WorkflowRolesService<T extends ListViewItem<RoleListe>> {

	/** Liste des rôles */
	private _getListeRoles: ListView<RoleListe,T>;

	/** Indique si une page de détail a été ouverte */
	isInDetail: boolean = false;

	/** Nombre de rôles collaborateur existants */
	nbRolesCollab: number;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param http             Client http
	 */
	constructor(private translateService: TranslateService,
				private http: HttpClient) {
	}

	/** Liste des rôles. Ce n'est pas un getter uniquement pour éviter les dépendances circulaires :(
	 *
	 * @param component Composant pour initialiser la liste des rôles. Dans les faits c'est forcément RolesListeItemComponent, mais le spécifier pose un problème de dépendance circulaire
	 * @return {ListView<RoleListe, T>}
	 */
	getListeRoles(component: Type<T>): ListView<RoleListe,T> {
		//Si la liste n'est pas encore initialisée
		if (!this._getListeRoles) {
			//On initialise la liste
			this.initListeRoles(component);
		}

		//On renvoie la liste
		return this._getListeRoles;
	}

	/**
	 * Initialisation de la liste des rôles
	 *
	 * @param component Composant à utiliser pour la liste des rôles
	 */
	private initListeRoles(component: Type<T>) {
		this._getListeRoles = new ListView<RoleListe,T>({
			uri: '/controller/Role/listeAllRoles',
			title: this.translateService.instant('workflow.roles.title'),
			component: component,
			defaultOrder: 'libelle',
			isFilter: true,
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('workflow.roles.libelle'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},
				{
					clef: 'fonction',
					title: this.translateService.instant('workflow.roles.liste.profil'),
					listeValues: [
						{code: TypeProfil.COMPTABLE.toString(),libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.COMPTABLE)},
						{code: TypeProfil.RESPONSABLE.toString(),libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.RESPONSABLE)},
						{code: TypeProfil.COLLABORATEUR.toString(),libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.COLLABORATEUR)},
						{code: TypeProfil.ASSISTANT.toString(),libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.ASSISTANT)},
						{code: TypeProfil.SOUS_ADMINISTRATEUR.toString(),libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.SOUS_ADMINISTRATEUR)},
						{code: TypeProfil.FOURNISSEUR.toString(),libelle: TypeProfil.getLibelle(this.translateService,TypeProfil.FOURNISSEUR)}]
				},
				{
					clef: 'code',
					title: this.translateService.instant('workflow.roles.liste.code'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},
				{
					clef: 'isDelegable',
					title: this.translateService.instant('workflow.roles.liste.delegable'),
					type: TypeFilter[TypeFilter.BOOLEAN],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
				},
			]
		});

		//Liste des colonnes triables
		this._getListeRoles.columns = [
			{key: 'libelle',title: 'workflow.roles.libelle'},
			{key: 'fonction',title: 'workflow.roles.liste.profil'},
			{key: 'code',title: 'workflow.roles.liste.code'},
			{key: 'isDelegable',title: 'workflow.roles.liste.delegable'}
		]

		//On initialise le tri
		this._getListeRoles.sorting = new Sorting(this._getListeRoles.columns,'libelle');
	}

	/**
	 * Récupère un Role par son ID
	 *
	 * @param idRole Identifiant du rôle cherché
	 * @returns {Observable<RoleListe>}
	 */
	public getRole(idRole: number): Observable<RoleLong> {
		return this.http.get<RoleLong>(`${environment.baseUrl}/controller/Role/${idRole}`);
	}

	/**
	 * Suppression du rôle
	 *
	 * @param idRole Id du rôle à supprimer
	 */
	supprimerRole(idRole: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Role/deleteRole/${idRole}`);
	}


	/**
	 * Enregistrement du rôle.
	 *
	 * @param role 				Rôle à enregistrer
	 * @param listeAllDroits	Liste des droits pour le sous-admin
	 * @return {Observable<Result>}
	 */
	enregistrerRole(role: RoleLong,listeAllDroits: Array<ItemHierarchique>): Observable<Result> {
		//On s'assure que les spécificités du rôle sont bien à jour
		role.computeSpecificites();

		//On s'assure que les gestionnaires du rôle sont bien à jour
		role.computeTypeGestionnaire();

		//On s'assure que les droits sont à jour
		role.computeDroits(listeAllDroits);

		//Enregistrement du rôle
		return this.http.post<Result>(`${environment.baseUrl}/controller/Role/enregistrerRole`,role);
	}

	/**
	 * Compte le nombre de rôles collaborateurs existants.
	 */
	countRolesCollab(): Observable<number> {
		return this.http.get<number>(`${environment.baseUrl}/controller/Role/countRolesCollab`);
	}
}
