<div class="lvi-content">
    <div class="avatar">
        <i [hidden]="data.type !== typeAdresseEnum[typeAdresseEnum.PERSONNELLE]" class="material-icons-outlined">home</i>
        <i [hidden]="data.type === typeAdresseEnum[typeAdresseEnum.PERSONNELLE]" class="material-icons-outlined">business</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="'profilUser.adresses.'+data.type" (click)="this.extraOptions.open(data)"></a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'profilUser.adresses.ville'"></strong>
                <span>{{ data.ville }}</span>
            </li>
            <li *ngIf="data.codePostal">
                <strong [translate]="'profilUser.adresses.cp'"></strong>
                <span>{{ data.codePostal }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.adresses.pays'"></strong>
                <span>{{ data.pays }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data.actif ? 'global.oui' : 'global.non'"></span>
            </li>
        </ul>
    </div>
</div>
