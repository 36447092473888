/**
 * Modèle représentant un contentieux de facture
 */
export class FactureContentieux {
    idFactCont: number;
	litige: boolean;
    litigeLeve: boolean;
    alerte: boolean;
    motif: string;
    idFacture: number;
    listeFactureReference: Array<number>;

    constructor(dto: any) {
        if (dto) {
            Object.assign(this,dto);
        }
    }
}

/**
 * Énumération des différents statuts possibles du litige
 */
export enum StatutLitige {
    AUCUN = 'AUCUN',
    OUVERT = 'OUVERT',
    FERME = 'FERME'
}
