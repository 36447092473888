import { Component,Input,OnInit,Optional } from "@angular/core";
import { MatDialog,MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlerteComponent } from "@components/workflow/alerte.component";
import { AppState } from "@domain/appstate";
import { ListItem } from "@domain/common/list-view/list-item";
import { ListViewItem } from "@domain/common/list-view/list-view-item";
import { LotAvanceDTO,LotFactureDTO,LotItemType,LotNdfDTO } from "@domain/comptabilite/lot";
import { SettingsGlobalState } from "@domain/settings/settings";
import { TypeProfil } from "@domain/user/user";
import { TypePortee } from "@domain/workflow/workflow";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { filterFirstNotNull } from "@share/utils/rxjs-custom-operator";

/**
 * Composant d'ajout d'un objet au lot comptable
 *
 * @author Laurent SCIMIA
 * @date 16/03/2022
 */
@Component({
    template: ''
})
export class LotAddObjectItemComponent<T extends ListItem & (LotNdfDTO | LotFactureDTO | LotAvanceDTO)> extends ListViewItem<T> implements OnInit {
    /** Mise à disposition de l'enum dans le HTML */
    LotItemType = LotItemType;

    /** Élément courant */
    @Input() data: T;
    
    /** Options supplémentaires */
    @Input() extraOptions: {
        //Permet de déterminer si le lot est en mode création ou à défaut seulement en visualisation
        isLotCreation: boolean
    };

    /** Paramétrage */
    settings: SettingsGlobalState;

    /** Profil de l'utilisateur connecté */
    fonction: TypeProfil;

    /**
     * Constructeur
     */
    constructor(protected store: Store<AppState>,
                protected router: Router,
                protected matDialog: MatDialog,
                protected translateService: TranslateService,
                @Optional() protected matDialogRef: MatDialogRef<LotAddObjectItemComponent<T>>) {
        //Héritage
        super();
    }

    /** Initialisation */
    ngOnInit(): void {
        //Sélection du paramétrage
        this.store.select(state => state.settings?.[TypePortee.CPT]).pipe(filterFirstNotNull()).subscribe(settings => this.settings = settings);
        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user.fonction).pipe(first()).subscribe(fonction => this.fonction = fonction);
    }

    /** Navigation vers l'objet cliqué */
    navigateTo(type: LotItemType, idObjet: number): void {
        //Si le lot n'est pas en création, on autorise la navigation
        if (!this.extraOptions?.isLotCreation) {
            //On ferme la popup
            this.matDialogRef?.close();

            let route = '';

            switch (type) {
                case LotItemType.NDF:
                    route = `Lot/${this.data.lot.idLot}/NDF`;
                    this.router.navigate([route, idObjet]);
                    break;
                case LotItemType.FACTURE:
                    route = `Lot/${this.data.lot.idLot}/Facture`;
                    this.router.navigate([route, idObjet]);
                    break;
                case LotItemType.AVANCE:
                    route = `Lot/${this.data.lot.idLot}/Avance`;
                    this.router.navigate([route, idObjet]);
                    break;
            }
        }
    }

    /**
     * Ouverture de l'OD associé à l'objet
     */
    openOd(idOd: number): void {
        //Si le lot n'est pas en création, on autorise la navigation
        if (!this.extraOptions?.isLotCreation) {
            //On ferme la popup
            this.matDialogRef?.close();

            //Navigation vers l'élément
            this.router.navigate(['OD', idOd]);
        }
    }

    /**
     * Affichage de la popup de la liste des alertes
     */
    showListeAlertes(): void {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: this.data.listeAlertes.listeAlertes
            }
        });
    }
}
