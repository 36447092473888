<h1 mat-dialog-title>
    <span [translate]="isCreation ? 'admin.voyages.referentiels.infos.headerTitleCompagnieCreation' : 'admin.voyages.referentiels.infos.headerTitleCompagnie'" [translateParams]="{ libelle: compagnie.code }"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="py-1">
    <form #form="ngForm">
        <fieldset>
            <div class="row">
                <custom-input ngDefaultControl
                              id="code"
                              name="code"
                              [(ngModel)]="compagnie.code"
                              customType="input"
                              rCol="2"
                              libelle="admin.voyages.referentiels.infos.code"
                              [readonly]="!compagnie.custom && !isCreation"
                              [required]="true"></custom-input>

                <custom-input ngDefaultControl
                              id="isActif"
                              name="isActif"
                              [ngModel]="compagnie.actif"
                              [(customModel)]="compagnie.actif"
                              customType="checkbox"
                              lCol="4"
                              rCol="2"
                              libelle="admin.voyages.referentiels.infos.actif"
                              [postTooltip]="'admin.voyages.referentiels.infos.actifTooltip' | translate"></custom-input>
            </div>
            <div class="row">
                <custom-input ngDefaultControl
                              id="libelle"
                              name="libelle"
                              [(ngModel)]="compagnie.libelle"
                              customType="input"
                              rCol="2"
                              [readonly]="!compagnie.custom && !isCreation"
                              libelle="admin.voyages.referentiels.infos.libelle"
                              [required]="true"></custom-input>

                <custom-input ngDefaultControl
                              id="parite"
                              name="parite"
                              [(ngModel)]="compagnie.typePrestation"
                              customType="select"
                              lCol="4"
                              rCol="2"
                              [selectOptions]="typesPrestation"
                              [readonly]="!compagnie.custom && !isCreation"
                              optionValue="id"
                              optionDisplay="libelle"
                              libelle="admin.voyages.referentiels.infos.typePrestation"></custom-input>
            </div>
            <div class="row" *ngIf="compagnie.typePrestation === 0">
                <label class="col-md-2">
                    <span [translate]="'admin.voyages.referentiels.infos.lowCost'"></span>
                </label>
                <div class="col-md-1 d-flex flex-row align-items-center">
                    <mat-checkbox color="primary" id="lowCost" name="lowCost" [(ngModel)]="compagnie.lowCost"></mat-checkbox>
                    <div class="px-2">
                        <mat-icon class="material-icons mb-md-2" [matTooltip]="'admin.voyages.referentiels.compagnie.lowCostToolTip' | translate" matTooltipPosition="right" [inline]="true">
                            info_outline
                        </mat-icon>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!isCreation">
                <custom-input *ngIf="compagnie.dateCreated"
                              ngDefaultControl
                              id="dateCreated"
                              name="dateCreated"
                              [ngModel]="compagnie.dateCreated"
                              [(customModel)]="compagnie.dateCreated"
                              customType="date"
                              rCol="2"
                              [readonly]="true"
                              libelle="admin.voyages.referentiels.infos.dateCreation"></custom-input>

                <custom-input *ngIf="compagnie.dateUpdated"
                              ngDefaultControl
                              id="dateUpdated"
                              name="dateUpdated"
                              [ngModel]="compagnie.dateUpdated"
                              [(customModel)]="compagnie.dateUpdated"
                              customType="date"
                              lCol="4"
                              rCol="2"
                              [readonly]="true"
                              libelle="admin.voyages.referentiels.infos.derniereSynchronisation"></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="delete()" *ngIf="!this.isCreation && this.compagnie.custom" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="save()" [disabled]="isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
