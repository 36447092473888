import {Component,Input} from '@angular/core';

/**
 * Composant d'affichage d'une checkbox pour le composant d'affichage hiérarchique
 *
 * @author Laurent SCIMIA
 * @date 11/12/2023
 */
@Component({
	host: {'data-test-id': 'checkbox-detail'},
	templateUrl: './checkbox-detail.component.html',
	styleUrls: ['./checkbox-detail.component.scss']
})
export class CheckboxDetailComponent {
	/** Paramètres du composant reçus depuis la factory du ComponentHolderComponent */
	@Input() input: Record<'element'|'subject',any>;

	/** Action déclenchée lors du clic sur la checkbox */
	clickCheckbox() {
		//Si on a reçu un subject en paramètre
		if (this.input.subject) {
			//On délègue la gestion du clic au subject
			this.input.subject.next(this.input.element);
		} else {
			//Pas de subject, on check la box
			this.input.element.checked = !this.input.element.checked;
		}
	}
}