import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {TravelHubPrestation} from "@domain/travel/travel-hub-prestation";

/**
 * Représente la configuration SBT
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
export class TravelHubSBTConfig {
	idSBTConfig: number;
	config: TravelHubConfig;
	numSBT: number;
	agence: string;
	typeAiguillage: number;
	code: string;
	dateUpdated: Date;
	actif: boolean;
	tripFieldLibre: boolean;
	customFieldLibre: boolean;
	maxTripField: number;
	maxCustomField: number;

	listePrestation: Array<TravelHubPrestation>;

	/**
	 * Constructeur
	 * @param dto source
	 */
	constructor(dto?: TravelHubSBTConfig) {
		if (dto) {
			Object.assign(this,dto);

			if (dto.config) {
				this.config = new TravelHubConfig(dto.config);
			}

			if (dto.listePrestation) {
				this.listePrestation = dto.listePrestation.map(p => new TravelHubPrestation(p));
			}
		}
	}
}