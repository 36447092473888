import {NgModule} from '@angular/core';


import {CoreComponentsModule} from '../core-components.module';
import {PageHeaderComponent} from './page-header.component';
import {TooltipModule} from "../../directive/tooltip/tooltip.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";

@NgModule({
	imports: [CoreComponentsModule,TooltipModule,PreviewAlerteModule],
    declarations: [PageHeaderComponent],
    exports: [PageHeaderComponent]
})
export class PageHeaderModule {}
