<h1 mat-dialog-title>
    <span [translate]="'workflow.conditions.alertes.detail.alerte.selectProfilsPopin.popinTitle'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <list-view *ngIf="listeProfils" [liste]="listeProfils"></list-view>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" [mat-dialog-close]="listeProfils.listeObjetsSectionnes">
        <span [translate]="'global.actions.confirmer'"></span>
    </button>
</div>