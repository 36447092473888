import {Component,Input,OnInit} from '@angular/core';
import {TypeEntiteParam,TypePorteeParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {ListView} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {FiltreParamItemComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-param-item/filtre-param-item.component";
import {FiltreParamService} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-param.service";
import {MatDialog} from "@angular/material/dialog";
import {FiltreDialogComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-dialog/filtre-dialog.component";


/**
 * Composant d'affichage de la liste des filtres.
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Component({
    host: {'data-test-id': 'filtre-param'},
    selector: 'filtre-param',
    templateUrl: './filtre-param.component.html',
    providers: [FiltreParamService]
})

export class FiltreParamComponent implements OnInit {
    /** Id de la portée */
    @Input() idPortee: TypePorteeParam

    /** ListeView des params */
    typeEntiteParamListView: ListView<TypeEntiteParam,FiltreParamItemComponent>;

    /** Constructeur */
    constructor(
        private translateService: TranslateService,
        private matDialog: MatDialog) {
    }

    /** Initialisation */
    ngOnInit() {
        //Définition de la liste
        this.typeEntiteParamListView = new ListView<TypeEntiteParam,FiltreParamItemComponent>({
            title: this.translateService.instant('admin.parametres.demat.pieceJointes.filtre.title' + this.idPortee),
            component: FiltreParamItemComponent,
            uri: `/controller/PieceJointe/listeTypeEntiteActifByPortee/${this.idPortee}`,
            listeActions: [{
                icon: "add",
                onPress: () => this.openDialog(this.idPortee)
            }],
            isFilter: false,
            isFilterVisible: false,
            isSearchBar: false,
            isInfiniteScroll: false,
            nbObjetsParPage: 5,
            emptyMessage: 'admin.parametres.demat.pieceJointes.filtre.listeVide'
        });
    }

    /** Ouverture de la popin */
    openDialog(idPortee : TypePorteeParam) {
        this.matDialog.open(FiltreDialogComponent,{
            data: {
                idPortee: idPortee,
                listeIdInitiallySelected: this.typeEntiteParamListView.data.listeResultats.map(typeEntiteParam => typeEntiteParam.idTypeEntite)
            },
            hasBackdrop: true
        }).afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.typeEntiteParamListView.refresh();
            }
        });
    }
}
