import {Component, Input, OnInit} from '@angular/core';
import {RegionAdmin} from "@domain/geographie/regionAdmin";
import {ControlContainer, NgForm} from "@angular/forms";
import {GeographieView} from '@domain/geographie/geographieView';
import {TypeGeographie} from '@domain/geographie/typeGeographie';

/**
 * Composant de l'onglet Généralités d'une région
 *
 * @author Angeline Ha
 * @date 09/11/2023
 */
@Component({
    host: {'data-test-id': 'region-generalites'},
    selector: 'region-generalites',
    templateUrl: './region-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class RegionGeneralitesComponent implements OnInit {

    /** Modèle pour l'autocomplete du pays */
    selectedPays: GeographieView;

    /** Région */
    @Input() region: RegionAdmin;

    /**
     * Initialisation
     */
    ngOnInit() {
        //Récupération du pays pour l'autocomplete
        this.selectedPays = {
            id: this.region.idPays,
            type: TypeGeographie.PAYS,
            libelle: this.region.pays?.libelle,
            pays: this.region.pays,
        }
    }

    /**
     * Mise à jour de l'objet de saisie avec le pays sélectionné dans l'autocomplete
     */
    onPaysChange() {
        this.region.idPays = this.selectedPays.id;
    }

}
