import {Component,Input} from '@angular/core';
import {TypeDocument} from "@domain/admin/parametre/demat/type-document";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage de la pageTypeItem
 *
 * @author Lionel Gimenez
 * @date 02/10/2023
 */
@Component({
    host: {'data-test-id': 'type-item.ts'},
    templateUrl: './type-item.component.html'
})
export class TypeItemComponent extends ListViewItem<TypeDocument> {
    /** Elément courant **/
    @Input() data: TypeDocument;

    /** Options supplémentaires */
    @Input() extraOptions: { open: (type: TypeDocument) => void };

    /**
     * Constructeur
     */
    constructor(
        private dematService: DematService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {
        super();
    }

    /**
     * Handler du click de sélection
     */
    onSelect() {
        //On ouvre le popin
        this.extraOptions.open(this.data);
    }

    /**
     * Retourne le nombre d'objets associé à l'item en cours
     * @return le nombre d'objets associé à l'item en cours
     */
    getObjetsCount(): string {
        //Si null ou vide
        if (this.data.typeObjet == null || this.data.typeObjet.length == 0) {
            //on retourne 0
            return this.translateService.instant('admin.parametres.demat.types.tag.tous');
        }

        //On retourne le nombre de types séparé par une virgule
       return this.data.typeObjet.split(',').length.toString(10);
    }

    /**
     * Supprime le TypeDocument en cours
     */
    delete(): void {
        this.dematService.deleteTypeDocument(this.data.idTypeDocument)
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        this.liste.refresh();
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                }
            });
    }
}