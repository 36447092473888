<mat-card>
    <mat-card-title>
        <span [translate]="'omp.transports.title'"></span>
        <span class="actions" *ngIf="canModifier">
            <mat-icon (click)="showTransport()">add</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item" *ngFor="let transport of listeTransports">
                    <div class="lvi-content">
                        <div class="avatar">{{ getAvatarForTypeTransport(transport.type) }}</div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="canModifier && showTransport(transport)">
                                    {{ getTitleForTypeTransport(transport.type) }}
                                </a>
                            </div>
                            <ul class="lvi-attrs">
                                <li *ngIf="transport.confort">
                                    <strong [translate]="'omp.transports.confort'"></strong>{{transport.confort.libelle}}
                                </li>
                                <li *ngIf="transport.vehicule?.immatriculation">
                                    <strong [translate]="'omp.transports.immatriculation'"></strong>{{transport.vehicule.immatriculation}}
                                </li>
                                <li *ngIf="transport.vehicule?.marque">
                                    <strong [translate]="'omp.transports.marque'"></strong>{{transport.vehicule.marque}}
                                </li>
                                <li *ngIf="transport.vehicule?.modele">
                                    <strong [translate]="'omp.transports.modele'"></strong>{{transport.vehicule.modele}}
                                </li>
                                <li *ngIf="transport.numerique1 > 0">
                                    <strong [translate]="'omp.transports.kmAutorises'"></strong>{{transport.numerique1}}
                                </li>
                                <li *ngIf="transport.attribut1">
                                    <strong [translate]="'omp.transports.complement'"></strong>
                                    {{transport.attribut1.length > 50 ? transport.attribut1.substring(0,50) + '...' : transport.attribut1}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="!listeTransports?.length"><span [translate]="'omp.transports.aucun'"></span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>