<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': isSoonToExpire,'danger': isExpired }">
        <i class="material-icons-outlined">credit_card</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="this.extraOptions.open(data)">{{ data.libelle ? data.libelle : ('profilUser.cartePaiement.typeCarte.' + TypeCarte[this.data.typeCarte]) | translate }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'profilUser.cartePaiement.type'"></strong>
                <span>{{ 'profilUser.cartePaiement.typeCarte.' + TypeCarte[this.data.typeCarte] | translate }}</span>
            </li>
            <li *ngIf="data.libelle">
                <strong [translate]="'profilUser.cartePaiement.libelle'"></strong>
                <span>{{ data.libelle }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.cartePaiement.date'"></strong>
                <span>{{ data.dateFin | date:'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data.actif ? 'global.oui' : 'global.non'"></span>
            </li>
            <li *ngIf="isSoonToExpire || isExpired" [ngClass]="{ 'back-warning': isSoonToExpire,'back-danger': isExpired }">
                <strong [translate]="'workflow.alerte.title'"></strong><span [translate]="'profilUser.cartePaiement.alerte.' + (isExpired ? 'expired' : 'echeance')"></span>
            </li>
        </ul>
    </div>    
</div>
