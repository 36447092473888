import {AfterViewInit,Component,Input,OnDestroy,ViewChildren} from '@angular/core';
import {ControlContainer,NgForm} from "@angular/forms";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {Observable,Subscription} from "rxjs";
import {AbstractReferentielComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/abstract-referentiel/abstract-referentiel.component";
import {AbstractReferentiel} from "@domain/travel/abstract-referentiel";
import {ListItem} from "@domain/common/list-view/list-item";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Onglet "Référentiels" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'sbt-config-referentiels'},
    selector: 'sbt-config-referentiels',
    templateUrl: './sbt-config-referentiels.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SbtConfigReferentielsComponent<U extends ListItem & AbstractReferentiel,V extends ListViewItem<U>> implements AfterViewInit,OnDestroy {
    /** Configuration SBT */
    @Input()
    sbtConfigUsed: TravelHubSBTConfigUsed;

    /** Observable indiquant un changement de SBT */
    @Input()
    sbtConfigChanged: Observable<void>;

    /** Liste des références des différents référentiels */
    @ViewChildren("referentiel")
    listeReferentiels: Array<AbstractReferentielComponent<U,V>>;

    /** Souscription à l'observable de mise à jour du SBT */
    subscriptionSbtConfigChanged: Subscription;

    /**
     * Après initialisation de la vue
     */
    ngAfterViewInit() {
        //Abonnement au changement de SBT
        this.subscriptionSbtConfigChanged = this.sbtConfigChanged?.subscribe(() => {
                //Mise à jour du niveau d'alerte de la liste, qui est dépendant du SBT sélectionné
                this.listeReferentiels?.forEach(ref => ref.updateAlertLevelList())
            });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement
        this.subscriptionSbtConfigChanged?.unsubscribe();
    }
}