import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {CompteurDetail} from "@domain/ik/compteur-detail";
import {CompteurDetailListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-detail-list-item/compteur-detail-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Result} from "@domain/common/http/result";
import {Compteur} from "@domain/vehicule/compteur";
import * as moment from "moment";
import {DecimalPipe} from "@angular/common";

/**
 * Popin du détail d'un compteur sur une période
 */
@Component({
	host: {'data-test-id': 'compteur-detail-popin'},
	templateUrl: './compteur-detail-popin.component.html',
	styles: [
		'::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
		'::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
	]
})
export class CompteurDetailPopinComponent implements OnInit {
	/** Liste détail période */
	listeCompteurDetail: ListView<CompteurDetail,CompteurDetailListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la ref à cette popin
	 * @param decimalPipe le pipe des nombres décimaux
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { libellePeriode: string,compteur: Compteur },
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<CompteurDetailPopinComponent>,
		private decimalPipe: DecimalPipe
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Date d'arrêt du compteur
		const dateArrete: string = moment(this.data.compteur.date).format('x');

		//Définition de l'URI
		let uri: string;

		if (this.data.compteur.vehicule) {
			uri = `/controller/IK/showDetailCompteur?dateArrete=${dateArrete}&idUser=${this.data.compteur.idUser}&idVehicule=${this.data.compteur.vehicule.id}`
		} else {
			uri = `/controller/IK/showDetailCompteur?dateArrete=${dateArrete}&idUser=${this.data.compteur.idUser}`
		}

		//Définition du titre
		let title: string = this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.title',{libelle: this.data.libellePeriode});

		if (this.data.compteur.compteurReel) {
			title += ' : ' + this.decimalPipe.transform(this.data.compteur.compteurReel,'1.2-2') + ' ' + this.data.compteur.unite.libelle;
		}

		//Initialisation de la liste
		this.listeCompteurDetail = new ListView<CompteurDetail,CompteurDetailListItemComponent>({
			uri: uri,
			title: title,
			component: CompteurDetailListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeDetail,
			listeActions: [
				{
					icon: 'close',
					onPress: () => this.matDialogRef.close()
				}
			]
		});
	}
}
