import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Exercice} from "@domain/admin/comptabilite/exercice";
import {Router} from "@angular/router";

/**
 * Item de la liste des exercices
 *
 * @author Alexis BOUVARD
 * @date 19/04/2023
 */
@Component({
	host: {'data-test-id': 'exercices-list-item'},
	templateUrl: './exercices-list-item.component.html'
})
export class ExercicesListItemComponent extends ListViewItem<Exercice> {
	/**
	 * Constructeur
	 *
	 * @param router le routeur Angular
	 */
	constructor(
		private router: Router
	) {
		//Constructeur parent
		super();
	}

	/**
	 * Au clic sur un exercice
	 */
	onClick() {
		//Navigation vers l'exercice courant
		this.router.navigate(['Admin/Comptabilite/ExercicesPeriodes/Exercice',this.data.idExercice]);
	}
}
