<!-- Panneau Récapitulatif -->
<od-engagements-resume #resume [od]="od" (engagementsResumeChange)="indemnites.updateTotaux(resume?.engagements)"></od-engagements-resume>

<!-- Panneau Frais -->
<od-engagements-frais #frais [od]="od"></od-engagements-frais>

<!-- Panneau Factures -->
<od-engagements-factures #factures [od]="od"></od-engagements-factures>

<!-- Panneau Indemnités -->
<od-engagements-indemnites #indemnites [od]="od" [contexte]="Contexte.ENGAGEMENTS" [resume]="resume" (onDegrevementsChanged)="refresh()"></od-engagements-indemnites>