import {Injectable,TemplateRef} from '@angular/core';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

import {ConfirmComponent} from './confirm.component';

/**
 * Service de gestion de la pop-up de confirmation d'une action
 */
@Injectable()
export class ConfirmService {
    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog) {
    }

    /**
     * Ouverture d'une pop-up de confirmation
     *
     * @param message   Message à afficher dans la popup
     * @param options   Options de la popup
     *
     * @return {Observable<boolean>} La valeur retournée (true pour une confirmation, false pour une annulation)
     */
    showConfirm(message: string | TemplateRef<any>,options?: showConfirmOptions): Observable<boolean> {
        //Ouverture de la pop-up de confirmation
        return this.showConfirmDialog(message,options)
            .afterClosed();
    }

    /**
     * Ouverture d'une pop-up de confirmation
     *
     * @param message   Message à afficher dans la popup
     * @param options   Options de la popup
     *
     * @returns {MatDialogRef<ConfirmComponent, boolean>} Référence de la popup de confirmation
     */
    showConfirmDialog(message: string | TemplateRef<any>,options?: showConfirmOptions): MatDialogRef<ConfirmComponent,boolean> {
        //Merge des options passées en paramètres avec les options par défaut
        options = {type: 'defaut',...options};
        
        //Ouverture de la pop-up de confirmation / alerte
        return this.matDialog.open<ConfirmComponent, any, boolean>(ConfirmComponent, {
            width: '600px',
            position: {
                top: '100px'
            },
            role: 'alertdialog',
            disableClose: options?.disableClose ?? options?.type !== 'ok',
            data: {
                message: message,
                options: options
            }
        });
    }
}

/** Type des options de la popup de confirmation */
export type showConfirmOptions = {
    /**
     * Permet de modifier l'affichage des boutons suivant les besoins
     * <ul>
     *     <li>'defaut' : Confirmer / Annuler</li>
     *     <li>'oui-non' : Oui / Non</li>
     *     <li>'ok' : Uniquement le bouton de confirmation</li>
     * </ul>
     */
    type?: 'defaut' | 'oui-non' | 'ok';
    title?: string;
    disableClose?: boolean;
    buttons?: {
        confirm: {
            libelle: string
        }
    };
};
