import {Component,Input} from '@angular/core';

import {AutocompleteOptions} from './autocomplete';

@Component({
    templateUrl: './autocomplete-line-item.component.html'
})
export class AutocompleteLineItemComponent<T> {
    /** Élément à afficher */
    @Input() item: T;

    /** Options de l'autocomplete */
    @Input() options: AutocompleteOptions;
}