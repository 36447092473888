<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">account_balance_wallet</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.openModeRemboursement(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.comptabilite.modeRemb.type'"></strong>
                <span>{{ TypeRemb.traduction(data.typeRemb) | translate }}</span>
            </li>
            <li *ngIf="!!data.compte?.numero || data.compte?.libelle">
                <strong [translate]="'admin.comptabilite.modeRemb.compte'"></strong>
                <span>{{ data.compte.libelleForDisplay }}</span>
            </li>
            <li *ngIf="!!data.code">
                <strong [translate]="'admin.comptabilite.modeRemb.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.comptabilite.modeRemb.defaut'"></strong>
                <span>{{ 'filter.valeurOuiNon.' + !!data.defaut | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.comptabilite.modeRemb.actif'"></strong>
                <span>{{ 'filter.valeurOuiNon.' + !!data.actif | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deleteModeRemb()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
