<div class="with-floating-button">
    <div class="content">
        <page-header
                [title]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.title' | translate"
                [listeItems]="listeTabItems"
                (onSelectedItemChange)="onSelectedItemChange($event)"
                [selectedIndex]="selectedIndex"
                [niveauAlerte]="NiveauAlerte.RGPD"
                (onGoBack)="onGoBack()">
            <preview-alerte [alerte]="alerte" [withIcon]="false" [isLargeIcon]="true"></preview-alerte>
        </page-header>

        <please-wait *ngIf="!zu"></please-wait>

        <div *ngIf="isConfigurationLoaded && zu" [hidden]="selectedItem.code !== Onglets.CONFIGURATION">
            <zone-parametrable-config [zu]="zu"></zone-parametrable-config>
        </div>
    </div>

    <floating-button [listeActions]="listeActions" [isPending]="isPending"></floating-button>
</div>
