<h1 mat-dialog-title>
    <span *ngIf="data.compte.idCompte !== 0" [translate]="'profilUser.compteBancaire.add.titleUpdate'"></span>
    <span *ngIf="data.compte.idCompte === 0" [translate]="'profilUser.compteBancaire.add.titleAdd'"></span>
    <span [mat-dialog-close]="willRefreshList"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #addCompteBancaireForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input [(ngModel)]="data.compte.typeCompte"
                    [selectOptions]="listeTypeCompte"
                        [disabled]="data.compte.idCompte !== 0"
                    customType="select"
                    lCol="2"
                    libelle="profilUser.compteBancaire.type"
                    name="typeCompte"
                    ngDefaultControl
                    optionDisplay="libelle"
                    optionValue="type"
                    rCol="4"
                    [required]="true">
                </custom-input>
                <label [translate]="'profilUser.compteBancaire.add.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [(ngModel)]="data.compte.actif" color="primary" name="actif" (change)="onActifChanged($event)"></mat-checkbox>
                </div>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="data.compte.libelle"
                    customType="input"
                    lCol="2"
                    libelle="profilUser.compteBancaire.add.libelle"
                    name="libelle"
                    ngDefaultControl
                    rCol="4"
                    [required]="true">
                </custom-input>
                <custom-input [(ngModel)]="data.compte.beneficiaire"
                    customType="input"
                    lCol="2"
                    libelle="profilUser.compteBancaire.titulaire"
                    name="titulaire"
                    ngDefaultControl
                    rCol="4">
                </custom-input>
            </div>
            <div class="row">
                <ng-template [ngIf]="data.compte.typeCompte == 'IBAN'">
                    <label class="col-md-2 required" [ngClass]="{ 'has-error': iban.invalid }" [translate]="'profilUser.compteBancaire.numero'"></label>
                    <div class="col-md-4">
                        <mat-form-field class="d-flex">
                            <input #iban="ngModel"
                                [(ngModel)]="data.compte.numero"
                                [ngxIban]="pays?.pays?.code2"
                                [disabled]="data.compte.idCompte !== 0"
                                matInput
                                name="iban"
                                required
                                type="text">
                        </mat-form-field>
                    </div>
                </ng-template>
                <custom-input *ngIf="data.compte.typeCompte != 'IBAN'"
                              [(ngModel)]="data.compte.numero"
                              [disabled]="data.compte.idCompte !== 0"
                              customType="input"
                              lCol="2"
                              libelle="profilUser.compteBancaire.numero"
                              name="numero"
                              ngDefaultControl
                              rCol="4"
                              [required]="true">
                </custom-input>
                <custom-input [(ngModel)]="data.compte.domiciliation"
                              customType="input"
                              lCol="2"
                              libelle="profilUser.compteBancaire.domiciliation"
                              name="domiciliation"
                              ngDefaultControl
                              rCol="4"
                              [required]="true">
                </custom-input>
            </div>
            <div *ngIf="data.compte.typeCompte == 'IBAN'" class="row">
                <custom-input [(ngModel)]="pays"
                              [autocompleteFilter]="{ listeTypes: ['PAYS'] }"
                              autocompleteType="geographie"
                              customType="autocomplete"
                              optionDisplay="libelle"
                              (onChange)="onPaysChange()"
                              lCol="2"
                              libelle="profilUser.compteBancaire.pays"
                              name="pays"
                              ngDefaultControl
                              rCol="4">
                </custom-input>
                <custom-input [(ngModel)]="data.compte.bic"
                              customType="input"
                              lCol="2"
                              libelle="profilUser.compteBancaire.bic"
                              name="bic"
                              ngDefaultControl
                              rCol="4"
                              [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="data.compte.devise"
                              [selectOptions]="listeDeviseFormated"
                              customType="select"
                              lCol="2"
                              libelle="profilUser.compteBancaire.devise"
                              name="devise"
                              ngDefaultControl
                              optionDisplay="libelle"
                              optionValue="code"
                              [required]="true"
                              rCol="4">
                </custom-input>
                <label [translate]="'profilUser.compteBancaire.default'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [disabled]="!data.compte.actif" [(ngModel)]="data.compte.defaut" color="primary" name="defaut"></mat-checkbox>
                </div>
            </div>

        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="deleteCompte()" *ngIf="this.data.compte.idCompte != 0" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="saveCompte()" [disabled]="addCompteBancaireForm.invalid || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="data.compte.idCompte == 0 ?'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
