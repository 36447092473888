<h1 mat-dialog-title>
   <span [translate]="'admin.parametres.demat.pieceJointes.filtre.title'  + data.idPortee"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <list-view [liste]="typeEntiteParamListView" *ngIf="typeEntiteParamListView"></list-view>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="onEnregistrer()" color="primary" mat-flat-button>
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>

