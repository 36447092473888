import {Component,OnInit} from '@angular/core';
import {ListView} from '@domain/common/list-view';
import {ZoneItemComponent} from "@components/admin/bibliotheque/geographie/zone/zone-list/zone-item/zone-item.component";
import {ZoneItem} from '@domain/admin/bibliotheque/geographie/zoneItem';
import {BehaviorSubject,Subscription} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {Router} from "@angular/router";

/**
 * Composant de la liste des zones
 *
 * @author Angeline Ha
 * @date 07/11/2023
 */
@Component({
	host: {'data-test-id': 'zone-list'},
	selector: 'zone-list',
	templateUrl: './zone-list.component.html'
})
export class ZoneListComponent implements OnInit {
	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param geographieService Service géographie
	 * @param router			Router
	 */
	constructor(public geographieService: GeographieService,
				private router: Router) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition de la liste des actions du bouton en bas à droite de l'écran
		this.listeActions.next([{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-ajouter',
				libelle: 'global.actions.creer',
				doAction: () => this.createNewZone()
			}]
		);
	}

	/**
	 * Création d'une nouvelle zone
	 */
	createNewZone() {
		this.router.navigate(['Admin/Bibliotheque/Geographie/Zone/0']);
	}

}
