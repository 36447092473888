import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Modèle du paramétrage des pièces jointes
 * @author Lionel Gimenez
 * @date 07/09/2023
 */
export class PieceJointeParam {
    idPortee: TypePorteeParam;
    actif: boolean;
    tailleMax: number;
    modeGestion: number;
    type: boolean;
    typeObligatoire: boolean;
    libelle: boolean;
    libelleObligatoire: boolean;
}

/**
 * Modèle du paramétrage du path local
 *
 * @author Lionel Gimenez
 * @date 07/09/2023
 */
export class PieceJointePath {
    path: string;
    azureStorage: boolean;
}

/**
 * Enum des types de portée spécifique aux paramètres PJ
 * L'ordre de cet énum est utilisé pour l'affichage
 */
export enum TypePorteeParam {
    OT = 'OT',
    OD = 'OD',
    AV = 'AV',
    NF = 'NF',
    FC = 'FC',
    PV = 'PV'
}

/**
 * Enum des types actif
 */
export enum SelectOptionType {
    DESACTIVE,
    ACTIF,
    ACTIF_OBLIGATOIRE
}

/**
 * Enum des types ndf pour la disponibilité
 */
export enum DisponibiliteNDFType {
    NOTE_ET_FRAIS = 1,
    NOTE = 2,
    FRAIS = 3
}

/**
 * Enum des types facture pour la disponibilité
 */
export enum DisponibiliteFCType {
    RELEVE_ET_FACTURE = 1,
    RELEVE = 2,
    FACTURE = 3
}

export class TypeEntiteParam implements  ListItem{
    idPortee: TypePorteeParam;
    idTypeEntite: number;
    libelle: string;
    code: string;
    actif: boolean;
    selected: boolean;
    isSelected: boolean;

    getKey(): number | string {
        return this.idPortee;
    }
}
