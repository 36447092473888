import {ListItem} from "@domain/common/list-view/list-item";
import {TravelHubSBTConfigUsed} from "../travel/travel-hub-sbt-config-used";
import {ListeAlertes} from "../common/alerte/listeAlertes";

/**
 * Profil de connexion au TH
 *
 * @author Laurent Convert
 * @date 12/03/2024
 */
export class ProfilConnexion implements ListItem {
	idProfilConnexion: number;
	libelle: string;
	code: string;
	actif: boolean;
	forceOffline: boolean;
	choixOnOff: boolean;
	synchroProfil: boolean;
	defaut: boolean;
	online: boolean;
	offline: boolean;
	interne: boolean;
	travelHubSBTConfigUsed: TravelHubSBTConfigUsed;
	nbUser: number;

	/** Liste des alertes */
	listeAlertes?: ListeAlertes;

	constructor(dto?: ProfilConnexion) {
		if (dto) {
			Object.assign(this,dto);
		} else {
			this.idProfilConnexion = 0;
			this.actif = true;
			this.forceOffline = false;
			this.choixOnOff = false;
			this.synchroProfil = false;
			this.defaut = false;
			this.online = false;
			this.offline = false;
			this.interne = false;
			this.nbUser = 0;
		}
	}

	getKey(): number {
		return this.idProfilConnexion;
	}

}