<page-header
        [title]="'workflow.conditions.conditionsVisibilite.detail.title' | translate"
        [listeItems]="listeTabItems"
        (onSelectedItemChange)="onSelectedItemChange($event)"
        (onGoBack)="onGoBack()">
</page-header>

<div [hidden]="selectedItem?.code !== Onglets.CONDITION_VISIBILITE">
    <please-wait *ngIf="!condition"></please-wait>
    <form #form="ngForm" *ngIf="condition">
        <fieldset>
            <mat-card>
                <mat-card-title>
                    <span [translate]="'workflow.conditions.conditionsVisibilite.detail.condition.' + (isCreation ? 'creation' : 'modification')"></span>
                </mat-card-title>
                <mat-card-content>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="libelle"
                                name="libelle"
                                customType="input"
                                [(ngModel)]="condition.libelle"
                                libelle="workflow.conditions.conditionsVisibilite.detail.condition.libelle"
                                [maxlength]="2000"
                                [required]="true">
                        </custom-input>
                        <custom-input
                                ngDefaultControl
                                id="portee"
                                name="portee"
                                customType="select"
                                [selectOptions]="listePortees"
                                optionDisplay="libelle"
                                optionValue="idPortee"
                                [(ngModel)]="condition.idPortee"
                                libelle="workflow.conditions.conditionsVisibilite.detail.condition.portee"
                                (onChange)="onPorteeChange()"
                                [required]="true"
                                rCol="3">
                        </custom-input>
                    </div>
                    <div class="row" *ngIf="condition.sql">
                        <label class="col-md-2 align-items-start p-t-15">
                            <span [translate]="'workflow.conditions.conditionsVisibilite.detail.condition.conditionSQL'"></span>
                        </label>
                        <div class="col-md-9">
                            <ngx-codemirror
                                    id="sql"
                                    name="sql"
                                    [(ngModel)]="condition.sql"
                                    [options]="codeMirrorOptions">
                            </ngx-codemirror>
                        </div>
                        <mat-icon class="material-icons-outlined" [matTooltip]="getSqlTooltip()">info</mat-icon>
                    </div>
                    <div class="row">
                        <custom-input
                                ngDefaultControl
                                id="commentaire"
                                name="commentaire"
                                customType="textarea"
                                [(ngModel)]="condition.commentaire"
                                libelle="workflow.conditions.conditionsVisibilite.detail.condition.commentaire"
                                [maxlength]="1000"
                                [required]="true"
                                rCol="9">
                        </custom-input>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="entite">
                <mat-card-title>
                    <span [translate]="'workflow.conditions.conditionsVisibilite.detail.condition.constructionCondition'"></span>
                </mat-card-title>
                <mat-card-content>
                    <rule-builder [(rule)]="condition.rule" [entite]="entite.type"></rule-builder>
                </mat-card-content>
            </mat-card>
        </fieldset>
    </form>
</div>

<floating-button [listeActions]="listeActions"></floating-button>

<div [hidden]="selectedItem?.code !== Onglets.TEST">
    <p>Prochainement...</p>
</div>