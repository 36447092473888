import { Action } from '../domain/action';

/**
 * Export des actions
 */
export const UPDATE_FRAME_LOCATION = 'UPDATE_FRAME_LOCATION';

/**
 * Interface
 */
export interface NavigationState {
	/** Données **/
	frameLocation: string;
}

/**
 * Création du reducer
 */
export function navigationReducer(state: NavigationState,action: Action<any>): NavigationState {
	//Vérification de l'action
	switch (action.type) {
	case UPDATE_FRAME_LOCATION:
		//Définition du state
		state = {
			...state,
			frameLocation: action.payload
		};
		break;
	}

	//Retour de l'état
	return state;
}