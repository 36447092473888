<!-- On n'affiche la ligne que si on a au moins une valeur quelque part -->
<div class="row" *ngIf="dataGauche?.valeur || dataDroite?.valeur">
    <!-- Affichage de la colonne de gauche -->
    <ng-container *ngTemplateOutlet="info; context:{ traduction: dataGauche?.traduction, valeur:dataGauche?.valeur, tooltip:dataGauche?.tooltip }"></ng-container>
    <!-- Affichage de la colonne de droite si on n'est pas en affichage simple -->
    <ng-container *ngIf="isAffichageDouble" [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{ traduction: dataDroite?.traduction, valeur:dataDroite?.valeur, tooltip:dataDroite?.tooltip }"></ng-container>
</div>

<!-- Template de vérification de la présence d'une valeur -->
<ng-template #info let-traduction="traduction" let-valeur="valeur" let-tooltip="tooltip">
    <ng-container *ngTemplateOutlet="blocInfo; context:{traduction:traduction, valeur:valeur, tooltip:tooltip}"></ng-container>
</ng-template>

<!-- Template d'affichage d'une valeur -->
<ng-template #blocInfo let-traduction="traduction" let-valeur="valeur" let-tooltip="tooltip">
    <!-- Label avec traduction -->
    <label class="col-md-2">
        <span translate="{{ traduction }}"></span>
        <mat-icon style="margin-left:10px" *ngIf="tooltip" matSuffix [matTooltip]="tooltip">info</mat-icon>
    </label>
    <!-- Affichage de la valeur (sauf s'il n'y a pas de traduction car c'est qu'on veut laisser un vide) -->
    <div [ngClass]="isAffichageDouble ? 'col-md-4' : 'col-md-10'">
        {{ traduction ? (valeur || '-') : '' }}
    </div>
</ng-template>
