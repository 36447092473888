import { NgModule } from '@angular/core';


import { CoreComponentsModule } from '../core-components.module';
import { ThumbComponent } from './thumb.component';

@NgModule({
    imports: [CoreComponentsModule],
    declarations: [ThumbComponent],
    exports: [ThumbComponent]
})
export class ThumbModule {}