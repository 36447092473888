import {Component} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant du menu admin : Voyages > Frais d'agence
 */
@Component({
	host: {'data-test-id': 'voyages-frais-agence'},
	selector: 'voyages-frais-agence',
	templateUrl: './voyages-frais-agence.component.html'
})
export class VoyagesFraisAgenceComponent {
	/** Liste des différents onglets */
	listeTabItems: Array<PageHeaderItem> = [{
		code: Onglets.FRAIS_AGENCE,
		libelle: this.translateService.instant('admin.voyages.fraisAgence.tabs.fraisAgence')
	}];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	//Import de l'énumération pour le DOM
	readonly Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}
}

/**
 * Onglets de l'écran du menu admin : Voyages > Frais d'agence
 */
export enum Onglets {
	FRAIS_AGENCE = "FRAIS_AGENCE"
}
