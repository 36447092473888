<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">directions_car</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.ndf'" [translateParams]="{ numero: data.numeroNote }"></a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.compteurInitial'"></strong>
                <span>{{ data.compteurInitial | number:'1.2-2' }} {{ data.unite }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.distanceValidee'"></strong>
                <span>{{ data.quantite | number:'1.2-2' }} {{ data.unite }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.dateFrais'"></strong>
                <span>{{ data.dateFrais | date:'shortDate' }}</span>
            </li>
            <li *ngIf="data.immatriculation">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.vehicule'"></strong>
                <span>{{ data.marque }} {{ data.modele }} - {{ data.immatriculation }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurDetailPopin.validePar'"></strong>
                <span>{{ data.dateValidation | date:'shortDate' }} - {{ data.prenomValideur }} {{ data.nomValideur }}</span>
            </li>
        </ul>
    </div>
</div>