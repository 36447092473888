import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {Role} from "@domain/reporting/role";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {ReportingService} from "@services/admin/reporting/reporting.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TypeProfil} from "@domain/user/user";

/**
 * Composant pour afficher un param dans la liste
 */
@Component({
    host: {'data-test-id': 'role-item'},
    templateUrl: './role-item.component.html'
})
export class RoleItemComponent extends ListViewItem<Role>{
    /** Enum type de profil */
    public readonly TypeProfil = TypeProfil;

    constructor(
        public translateService: TranslateService,
        private confirmService: ConfirmService,
        private reportingService: ReportingService,
        private toastrService: ToastrService) {
        super();
    }

    /**
     * Suppression du filtre
     */
    delete() {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    this.reportingService.deleteRole(this.extraOptions.idReport,this.data.idRole)
                        .pipe(first())
                        .subscribe(res => {
                            if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
                                //Si le r�sultat contient une erreur, alors on affiche un message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            } else {
                                //Rafraichissement de la liste
                                this.extraOptions.refresh();

                                //Sinon, on affiche un message de succ�s
                                this.toastrService.success(this.translateService.instant('global.success.suppression'));
                            }
                        });
                }
            });
    }
}
