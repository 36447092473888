<mat-card *ngIf="entreprise">
  <mat-card-title>
    <span [translate]="'admin.entreprise.description.generalites.title'"></span>
  </mat-card-title>
  <mat-card-content>
    <fieldset>
      <div class="row">
        <custom-input libelle="admin.entreprise.description.generalites.tresorerie"
                      customType="input"
                      name="tresorerie"
                      maxlength="2000"
                      [(ngModel)]="entreprise.tresorerie"
                      ngDefaultControl></custom-input>
        <custom-input libelle="admin.entreprise.description.generalites.budgetLibelle"
                      customType="input"
                      name="libelle_budget"
                      maxlength="2000"
                      [(ngModel)]="entreprise.libelleBudget"
                      ngDefaultControl></custom-input>
      </div>
      <div class="row">
        <custom-input libelle="admin.entreprise.description.generalites.numeroCodique"
                      customType="input"
                      name="num_codique"
                      maxlength="2000"
                      [(ngModel)]="entreprise.numeroCodique"
                      ngDefaultControl></custom-input>
        <custom-input libelle="admin.entreprise.description.generalites.budgetCode"
                      customType="input"
                      name="code_budget"
                      maxlength="2000"
                      [(ngModel)]="entreprise.codeBudget"
                      ngDefaultControl></custom-input>
      </div>
      <div class="row">
        <custom-input libelle="admin.entreprise.description.generalites.codeEtablissement"
                      customType="input"
                      name="code_etablissement"
                      maxlength="2000"
                      [(ngModel)]="entreprise.codeEtablissement"
                      ngDefaultControl></custom-input>
      </div>
    </fieldset>
  </mat-card-content>
</mat-card>
