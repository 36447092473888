import {Component,Input} from '@angular/core';
import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TravelhubComponent} from "@components/admin/voyages/travelhub/travelhub/travelhub.component";
import {filter,finalize,first} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";

/**
 * Composant d'affichage d'une ligne de la liste des différentes configurations TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@Component({
    host: {'data-test-id': 'travelhub-config-list-item'},
    templateUrl: './travelhub-config-list-item.component.html'
})
export class TravelhubConfigListItemComponent extends ListViewItem<TravelHubConfig> {

    /** Options supplémentaires */
    @Input() public extraOptions: {
        parent: TravelhubComponent
    };

    /**
     * Constructeur
     *
     * @param confirmService Service de confirmation utilisateur via modale
     * @param translateService Service de traduction
     * @param pleaseWaitService Service d'affichage du loading
     */
    constructor(private confirmService: ConfirmService,
                private translateService: TranslateService,
                private pleaseWaitService: PleaseWaitService
    ) {
        super();
    }

    /**
     * Duplication de la configuration
     */
    copyConfig(): void {
        this.extraOptions.parent.copyConfig(this.data);
    }

    /**
     * Suppression de la configuration
     */
    deleteConfig(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() =>{
                //Modale de chargement
                let pleaseWaitRef = this.pleaseWaitService.show();

                //Suppression
                this.extraOptions.parent.deleteConfig(this.data)
                    .pipe(first(),finalize(() => pleaseWaitRef.close()))
                    .subscribe();
            });
    }

}