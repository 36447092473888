import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../autocomplete-line-item.component";
import {ParticipantAvecFavoris} from "@domain/participant/participant-avec-favoris";

/**
 * Affichage d'une ligne de participant
 *
 * @author Laurent SCIMIA
 * @date 27/10/2021
 */
@Component({
	templateUrl: './participant-line-item.component.html'
})
export class ParticipantLineItemComponent extends AutocompleteLineItemComponent<ParticipantAvecFavoris> {
}