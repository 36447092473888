import {Component,Inject,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {Facture} from "@domain/facture/facture";
import {FactureReferenceListItemComponent} from "./facture-reference-list-item.component";
import {FactureContentieux} from "@domain/facture/facture-contentieux";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@domain/common/http/list-result";
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import {ToastrService} from "ngx-toastr";
import {FactureService} from "@components/facture/facture.service";

/**
 * Liste des factures de référence sélectionnables pour rapprochement
 *
 * @author Laurent Convert
 * @date 24/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-reference-add-list'},
    templateUrl: './facture-reference-add-list.component.html'
})
export class FactureReferenceAddListComponent implements OnInit {
    /** Liste des factures de référence */
    liste: ListView<Facture,FactureReferenceListItemComponent>;

    /**
     * Constructeur
     *
     * @param data Paramètres passés à l'ouverture de la popin
     * @param matDialogRef
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param factureService Service de gestion des factures
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: {facture: Facture,contentieux: FactureContentieux},
                private matDialogRef: MatDialogRef<FactureReferenceAddListComponent>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private factureService: FactureService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Définition de la liste
        this.liste = new ListView<Facture,FactureReferenceListItemComponent>({
            uri: this.factureService.getUriSearchFactureReference(this.data.facture,this.data.contentieux),
            component: FactureReferenceListItemComponent,
            isFilter: false,
            listeFilters: [{
                //Ajout du filtre sur le numéro
                clef: 'numero',
                title: this.translateService.instant('facture.liste.filtres.numero'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }],
            mapRequest: (request: SearchSpec) => {
                //On force arbitrairement à 1000 éléments dans la page pour "désactiver" la pagination
                request.nbObjetsParPage = 1000;
                return request;
            },
            mapResult: (result: Page<Facture>): Page<Facture> => {
                result.listeResultats = result.listeResultats.map(f => {
                    return new Facture(f);
                });
                return result;
            },
            extraOptions: {
                //Activation de la sélection de lignes
                withSelection: true
            }
        });
    }

    /**
     * Validation de la sélection
     */
    validerSelection(): void {
        this.matDialogRef.close(this.liste?.data?.listeResultats.filter(i => i.isSelected));
    }

}