import {Injectable} from "@angular/core";
import {CanActivate} from "@angular/router";
import {LoginService} from "@share/login/login.service";
import {Session} from "@domain/security/session";

/**
 * Gardien des pages du profil utilisateur : accès interdit si c'est un admin (ou sous-admin) "connecté en tant que".
 */
@Injectable()
export class ProfilGuardProvider implements CanActivate {

	/**
	 * Constructeur
	 *
	 * @param loginService			Service de gestion du login et des mdp
	 */
	constructor(private loginService: LoginService) {
	}

	/**
	 * Activation du composant.
	 */
	async canActivate(): Promise<boolean> {
		let session: Session;

		//Récupération de la session
		session = this.loginService.getSession();

		return !session?.isConnectAs;
	}
}
