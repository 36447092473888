import {Component,Input} from '@angular/core';
import {OdListeFactureItem} from "@domain/od/frais/od-liste-facture";
import {environment} from "@environments/environment";
import {TypePortee} from "@domain/workflow/workflow";
import {Router} from "@angular/router";
import {WorkflowService} from "../../../../workflow/workflow.service";
import {ODService} from "../../../od.service";
import {MatDialog} from "@angular/material/dialog";
import {TypeFactPrev} from "@domain/factureprev/type-fact-prev";
import {TypeFact} from "@domain/facture/type-fact";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {OdFacturePrevisionnelleAddComponent} from "./creation/facture-previsionnelle/od-facture-previsionnelle-add.component";
import {SettingsODState} from "@domain/settings/settings";
import {Od} from "@domain/od/od";

/**
 * Composant d'affichage d'une ligne de la liste des factures rattachées à un OD
 *
 * @author Laurent Convert
 * @date 20/12/2021
 */
@Component({
    selector: 'od-frais-factures-item',
    templateUrl: './od-frais-factures-item.component.html'
})
export class OdFraisFacturesItemComponent {
    /** Déclaration des enums pour accès direct dans le template */
    TypeFactPrev = TypeFactPrev;
    TypeFact = TypeFact;
    NiveauAlerte = NiveauAlerte;
    TypePortee = TypePortee;

    /** Elément courant **/
    @Input() data: OdListeFactureItem;

    /** Options supplémentaires **/
    @Input() extraOptions: {
        canModifierFacturePrev: boolean,
        od: Od,
        settings: SettingsODState,
        refreshListe: (message: string) => void
    };

    /** URL de base **/
    baseUrl: string = environment.baseUrl;

    /**
     * Constructeur
     */
    constructor(private router: Router,
                private workflowService: WorkflowService,
                private odService: ODService,
                private matDialog: MatDialog) {
    }

    /**
     * Affichage de la facture
     */
    showFacture() {
        this.router.navigate([`OD/${this.extraOptions.od.idOd}/Facture`,this.data?.facture?.idFacture]);
    }

    /**
     * Affichage de la facture prévisionnelle
     */
    showFacturePrev() {
        this.matDialog.open(OdFacturePrevisionnelleAddComponent,{
            data: {
                facturePrev: this.data?.facturePrev,
                od: this.extraOptions.od,
                settings: this.extraOptions.settings,
                canModifier: this.extraOptions?.canModifierFacturePrev
            },
            hasBackdrop: true,
            disableClose: true
        }).afterClosed().subscribe((res: {refresh: boolean, message: string}) => {
            if (res?.refresh) {
                this.extraOptions.refreshListe(res?.message);
            }
        });
    }

    /**
     * Affichage des alertes de la mission liée
     */
    showListeAlertes(portee: TypePortee,idObjet: number) {
        //Affichage de la popup de liste des alertes
        this.workflowService.showListeAlertes(portee,idObjet);
    }
}