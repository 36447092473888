<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.distances.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a *ngIf="!data.libelle" class="label" (click)="goToDetailDistance()">{{ data.strDepart + " <> " + data.strArrivee }}</a>
            <a *ngIf="data.libelle" class="label" (click)="goToDetailDistance()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.distances.distance'"></strong>
                <span>{{ data.distance | distance:data.libelleUnite }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.distances.origine'"></strong>
                <ng-container [ngSwitch]="data.origine">
                    <span *ngSwitchCase=Origine.ORIGINE_USER [translate]="'admin.bibliotheque.geographie.distances.user'"></span>
                    <span *ngSwitchCase=Origine.ORIGINE_IMPORT [translate]="'admin.bibliotheque.geographie.distances.import'"></span>
                    <span *ngSwitchDefault [translate]="'admin.bibliotheque.geographie.distances.admin'"></span>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteDistance()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>