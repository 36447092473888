import {ListItem} from "../common/list-view/list-item";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {Axe5DTO,Axe6DTO,DossierDTO,OrgaDTO} from "@domain/od/od";

/**
 * Préférence analytique
 */
export class PreferenceAnalytique implements ListItem {
    id: number;
    idUser: number;
    idTypeEntite: number;
    idOrga: number;
    idDossier: number;
    idAxe5: number;
    idAxe6: number;
    orga: OrgaDTO;
    dossier: DossierDTO;
    axe5?: Axe5DTO;
    axe6?: Axe6DTO;
    field5: string;
    field6: string;
    defaut?: boolean;
    dateApplication: Date;
    typeEntite: TypeEntite;
    presentationOrga: string;
    presentationDossier: string;
    presentationAxe5: string;
    presentationAxe6: string;
    
    getKey(): number {
        return this.id;
    }
}
