import {Component, Input} from '@angular/core';
import {TypePlugins} from "@domain/admin/licence/licence";
import {Plugin} from "@domain/admin/licence/plugin";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant pour une ligne de la liste des plugin de la licence de l'entreprise
 */
@Component({
    selector: 'licence-list-plugin-item',
    host: {'data-test-id': 'licence-list-plugin-item'},
    templateUrl: './licence-list-plugin-item.component.html',
    styleUrls: ['./licence.component.scss']
})
export class LicenceListPluginItemComponent {
    /** Un des plugins de la licence */
    @Input() plugin: Plugin;

    /** Enum des types de plugins */
    Plugins = TypePlugins;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     */
    constructor(protected translateService: TranslateService){}

    /**
     * Méthode utilisée pour gérer et afficher les différents cas pour la valeur du code du plugin
     *
     * @param code Code du plugin, indiquant si c'est Via Michelin, Archivage, Notes de frais ou Marque Blanche
     */
    displayLabel(code: string) {
        //On réalise un switch sur le code pour les différents cas
        switch (code) {
            case this.Plugins.VIAMICHELIN: {
                return this.translateService.instant('admin.entreprise.description.licence.viaMichelin');
            }
            case this.Plugins.CERTIFICATION: {
                return this.translateService.instant('admin.entreprise.description.licence.certification');
            }
            case this.Plugins.ARCHIVAGE: {
                return this.translateService.instant('admin.entreprise.description.licence.archivage');
            }
            case this.Plugins.MARQUEBLANCHE: {
                return this.translateService.instant('admin.entreprise.description.licence.marqueBlanche');
            }
            case this.Plugins.GED: {
                return this.translateService.instant('admin.entreprise.description.licence.ged');
            }
        }
    }
}
