<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.code }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.onSelect(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.dateModification">
                <strong [translate]="'admin.bibliotheque.internationalisation.langueFiltres.dateModification'"></strong>
                <span>{{ data.dateModification | date: 'shortDate'}}</span>
            </li>
            <li *ngIf="data.personnalisee">
                <b [translate]="'admin.bibliotheque.internationalisation.langueFiltres.personnalisee'"></b>
            </li>
            <li *ngIf="data.parDefaut">
                <b [translate]="'admin.bibliotheque.internationalisation.langueFiltres.parDefaut'"></b>
            </li>
            <li *ngIf="data.standard">
                <b [translate]="'admin.bibliotheque.internationalisation.langueFiltres.standard'"></b>
            </li>
        </ul>
    </div>
</div>
