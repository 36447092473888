import {Component,Inject} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {WorkflowRolesService} from "@services/admin/workflow/workflow-roles.service";
import {RoleListe} from "@domain/workflow/roleListe";
import {RolesListeItemComponent} from "@components/admin/workflow/roles/roles/liste/roles-liste-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

/**
 * Liste des rôles
 *
 * @author Laurent SCIMIA
 * @date 12/06/2023
 */
@Component({
	host: {'data-test-id': 'roles-liste'},
	selector: 'roles-liste',
	templateUrl: './roles-liste.component.html'
})
export class RolesListeComponent {
	/** Liste des rôles */
	listeRoles: ListView<RoleListe,RolesListeItemComponent>;

	/** Indique si le routing de détail a été activé */
	get isInDetail() {
		return this.service.isInDetail;
	}

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param router			Router
	 * @param service			Service des rôles
	 */
	constructor(private translateService: TranslateService,
				private router: Router,
				@Inject('WORKFLOW_ROLE_SERVICE') private service: WorkflowRolesService<RolesListeItemComponent>) {
		//Récupère la liste des rôles
		this.listeRoles = this.service.getListeRoles(RolesListeItemComponent);

		//Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.creerRole(),
        }]);
	}

	/**
	 * Redirection pour création du rôle
	 */
	creerRole() {
		this.router.navigate(['/Admin/Workflow/Roles/Roles/0']);
	}
}