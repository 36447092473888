import {Component,OnDestroy,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router} from "@angular/router";
import {BehaviorSubject,Subscription} from 'rxjs';

/**
 * Énum des onglets du menu Workflow - Conditions
 */
enum Onglets {
	ALERTES = "Alertes",
	CONDITIONS_VISIBILITE = "ConditionsVisibilite"
}

/**
 * Composant du menu admin Workflow - Conditions
 */
@Component({
	host: {'data-test-id': 'workflow-conditions'},
	selector: 'workflow-conditions',
	templateUrl: './workflow-conditions.component.html'
})
export class WorkflowConditionsComponent implements OnInit,OnDestroy {
	/** Possibilité de masquer le header quand un enfant en a un également */
	isHeaderHidden: boolean = false;

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = [{
		code: Onglets.ALERTES,
		libelle: this.translateService.instant('workflow.conditions.alertes.title'),
		url: 'Admin/Workflow/Conditions/Alertes'
	},{
		code: Onglets.CONDITIONS_VISIBILITE,
		libelle: this.translateService.instant('workflow.conditions.conditionsVisibilite.title'),
		url: 'Admin/Workflow/Conditions/ConditionsVisibilite'
	}];

	/** Index de l'onglet courant */
	selectedItem$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	//On stocke la souscription pour pouvoir unsub
	souscription: Subscription;

	/** Import de l'énum pour le DOM */
	protected readonly Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur Angular
	 * @param activatedRoute la route actuelle
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On souscrit à la route
		this.souscription = this.activatedRoute.url.subscribe(() => {
			//Récupération du path courant
			const path: string = this.activatedRoute.snapshot.firstChild.url[0].path;

			//Si un onglet correspond au path
			if (Object.values(Onglets).find(value => value === path)) {
				//On navigue vers celui-ci
				this.onSelectedItemChange(this.listeTabItems.find(item => item.code === path));

				//On affiche le header
				this.isHeaderHidden = false;
			} else {
				//Sinon, on masque le header
				this.isHeaderHidden = true;
			}
		})
	}

	/**
	 * Changement d'onglet
	 *
	 * @param item l'onglet sélectionné
	 */
	onSelectedItemChange(item: PageHeaderItem) {
		//Récupération de l'index de l'onglet sélectionné
		const index = this.listeTabItems.findIndex(i => i.code === item.code);

		//S'il est différent de l'index courant
		if (index !== this.selectedItem$.value) {
			//On met à jour l'index courant
			this.selectedItem$.next(this.listeTabItems.findIndex(i => i.code === item.code));

			//Et on navigue vers l'URL correspondante
			this.router.navigateByUrl(item.url);
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//On désouscrit aux changements de route
		this.souscription.unsubscribe();
	}
}
