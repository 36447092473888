/**
 * Classe représentant le paramétrage des participants externes au niveau ns_params (il y a aussi un niveau dans le type entité)
 */
export class ParticipantExterneParam {
	id?: number;
	libelleClient?: string;
	actifOm?: boolean;
	obligatoireOm?: boolean;
	actifNdf?: boolean;
	obligatoireNdf?: boolean;
	nomParametre?: NomParametreParId;
}

/**
 * Énumération des noms de paramètres pour les participants externes
 */
export enum NomParametreParId {
	NOM = "NOM",
	PRENOM = "PRENOM",
	REFERENCE = "REFERENCE",
	SOCIETE = "SOCIETE",
	TELEPHONE = "TELEPHONE",
	EMAIL = "EMAIL",
	FONCTION = "FONCTION",
	ADD1 = "ADD1",
	ADD2 = "ADD2",
	ADD3 = "ADD3",
	ADD4 = "ADD4"
}

/**
 * Enrichissement de l'énum NomParametreParId
 */
export namespace NomParametreParId {
	/**
	 * Renvoie le code trad associé au paramètre
	 *
	 * @param param le paramètre
	 */
	export function traduction(param: NomParametreParId) {
		switch (param) {
			case NomParametreParId.NOM:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.nom';
			case NomParametreParId.PRENOM:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.prenom';
			case NomParametreParId.REFERENCE:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.reference';
			case NomParametreParId.SOCIETE:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.societe';
			case NomParametreParId.TELEPHONE:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.telephone';
			case NomParametreParId.EMAIL:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.email';
			case NomParametreParId.FONCTION:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.fonction';
			case NomParametreParId.ADD1:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.add1';
			case NomParametreParId.ADD2:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.add2';
			case NomParametreParId.ADD3:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.add3';
			case NomParametreParId.ADD4:
				return 'admin.entreprise.utilisateurs.externes.editExternePopin.add4';
		}
	}
}