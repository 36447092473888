<mat-card>
    <mat-card-title>
        <span [translate]="'admin.parametres.demat.modules.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview local">
            <div class="listview-body">
                <div class="listview-body-row row">
                    <div class="listview-item col-md-6" *ngFor="let module of listeModules">
                        <div>
                            <module-item [moduleItem]="module"></module-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div class="d-flex flex-row-reverse">
    <button (click)="tester()" color="primary" mat-flat-button [disabled]="isTesting" [nioTooltip]="tooltipButton">
        <span *ngIf="!isTesting" [translate]="'admin.parametres.demat.modules.tester.bouton'"></span>
        <mat-spinner *ngIf="isTesting" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
