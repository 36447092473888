<div class="listview-item">
    <div class="row">
        <div class="col-md-12">
            <div class="lvi-content clickable" (click)="toggleDetail();">
                <div class="avatar" [nioTooltip]="prestation?.libelle" nioTooltipPosition="right">
                    <!--
                        Petit hack ici pour que les images fonctionnent en dév avec le proxy ET en prod.
                        Le pb étant que statutImage est stocké en bdd et qu'il contient un chemin relatif vers l'image, qui ne fonctionne pas pour Angular car Angular est déployé à la racine de la webapp,
                        contrairement à AngularJS et XSL.
                    -->
                    <mat-icon>{{prestation?.icone}}</mat-icon>
                </div>
                <div class="lvi-body">
                    <div class="title">
                        <a>{{ prestation?.libelle }}</a>
                    </div>
                    <!-- Bulles -->
                    <ul class="lvi-attrs">
                        <li [ngClass]="{'back-warning':isQteOrigineCumulNotEqualsQteCumul(prestation)}">
                            <strong [translate]="'od.engagements.indemnites.quantite'"></strong>{{ prestation?.quantiteCumulIndemnites | number:'.2-2' }}
                            <mat-icon *ngIf="isQteOrigineCumulNotEqualsQteCumul(prestation)"
                                      [nioTooltip]="'od.engagements.indemnites.tips.diffQuantite' | translate:{'quantite':prestation?.quantiteOrigineCumulIndemnites}">error_outline</mat-icon>
                        </li>
                        <li><strong [translate]="'od.engagements.total'"></strong>{{ prestation?.montantCumulIndemnites | montant:settings?.deviseEntreprise}}</li>
                    </ul>
                </div>
                <div class="lvi-actions">
                    <button mat-icon-button>
                        <mat-icon *ngIf="isOuvert" color="primary">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="!isOuvert" color="primary">keyboard_arrow_down</mat-icon>
                    </button>
                </div>
            </div>
            <!-- Cadre de détail -->
            <div *ngIf="isOuvert" class="mx-5">
                <div class="listview-item" *ngFor="let ij of prestation.listeIndemnitesLinkedToOd">
                    <div class="lvi-content">
                        <div class="avatar" [ngClass]="{'warning':isQteOrigineCumulNotEqualsQteCumul(ij)}"
                                            [nioTooltipDisabled]="!isQteOrigineCumulNotEqualsQteCumul(ij)"
                                            [nioTooltip]="'od.engagements.indemnites.tips.diffQuantite' | translate:{'quantite':ij?.quantiteOrigineCumulIndemnites}">
                            {{ ij.quantiteCumulIndemnites | number:'.0-2' }}
                        </div>
                        <div class="lvi-body">
                            <div class="title">
                                <span class="flag-icon align-middle mr-1" [ngClass]="'flag-icon-'+ij.pays?.code2?.toLowerCase()"></span>
                                <a><span *ngIf="ij.ville">{{ij.ville.libelle}}, </span>{{ij.pays.libelle}}<span *ngIf="ij.fraisSpecial"> ({{ij.fraisSpecial.fraisMission.libelleSpecial}})</span></a>
                            </div>
                            <!-- Bulles -->
                            <ul class="lvi-attrs">
                                <li>
                                    <strong [translate]="'od.engagements.indemnites.montant'"></strong>{{ ij.montantCumulIndemnites | montant:prestation.devise }}
                                </li>
                                <li *ngIf="ij.montantCumulIndemnitesDeviseLocale != null && getDeviseIndemnite(ij) != prestation.devise">
                                    <strong [translate]="'od.engagements.indemnites.montant'"></strong>{{ ij.montantCumulIndemnitesDeviseLocale | montant:getDeviseIndemnite(ij) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>