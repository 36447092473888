<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }">
        <i class="nio icon-ordre_de_mission_permanent"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="dashboard-list-title" [routerLink]="" (click)="navigateTo()">
                {{ data.idOMPermanent || ('omp.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('omp.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.location" [matTooltip]="data.location + ', ' + data.pays.libelle.toUpperCase()">
                <strong [translate]="'dashboard.listeOMP.destination'"></strong>
                <span *ngIf="data.pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"></span>
                <span>{{ data.location }}</span></li>
        </ul>
    </div>
    <div class="info">
        <statut [statut]="data.statut" [isMinimized]="true"></statut>
    </div>
</div>