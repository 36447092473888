import {AfterViewInit,ChangeDetectorRef,Component,Input,OnInit,TemplateRef,ViewChild} from '@angular/core';
import {NgForm,NgModel} from "@angular/forms";
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {NiveauAlerte} from '@domain/common/alerte/alerte';
import {GeographieView} from "@domain/geographie/geographieView";
import {Od} from "@domain/od/od";
import {SaisieTemps} from "@domain/od/saisie-temps";
import {TypeTraiterAvantLe} from "@domain/od/type-traiter-avant-le";
import {SettingsODState} from '@domain/settings/settings';
import {TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {EnumEtat} from "@domain/workflow/etat";
import {TypePortee} from '@domain/workflow/workflow';
import {FillingRow} from "@share/component/filling-row/filling-row";
import * as moment from "moment";
import {OMPService} from "../../../omp/omp.service";
import {ZoneUtilisateurComponent} from '../../../zone-utilisateur/zone-utilisateur.component';
import {ODService} from "../../od.service";
import {ODVoyageService} from "../voyage/od-voyage.service";
import {OdGeneralitesSaisieTempsComponent} from './saisie-temps/od-generalites-saisie-temps.component';
import {PorteeGeographie} from "@share/component/autocomplete/options/geographie";
import {TranslateService} from "@ngx-translate/core";
import {OdListChevauchementDatesComponent} from "@components/od/detail/generalite/od-list-chevauchement-dates/od-list-chevauchement-dates.component";
import {Router} from "@angular/router";
import {LoginService} from "@share/login/login.service";
import {TypeProfil} from "@domain/user/user";
import {OdChevauchement} from "@domain/od/od-chevauchement";
import { PostTooltipNio } from '@share/component/custom-input/custom-input.component';
import {Result} from "@domain/common/http/result";

/**
 * Composant de l'onglet "Généralités" d'un OD
 */
@Component({
    host: {'data-test-id': 'od-generalites'},
    selector: 'od-generalites',
    templateUrl: './od-generalites.component.html'
})
export class ODGeneralitesComponent implements OnInit, AfterViewInit {
    //Constantes
    NiveauAlerte = NiveauAlerte;

    /** Pour l'utilisation de l'enum dans le tempalte */
    porteeGeographie = PorteeGeographie;

    /** Déclaration pour accès direct dans le template */
    TypePortee = TypePortee;

    /** Déclaration pour accès direct dans le template */
    EnumEtat = EnumEtat;

    /** Ordre de mission */
    @Input() od: Od;

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /** Paramétrage de l'ordre de mission */
    @Input() settings: SettingsODState;

    /** Paramètres du type entité **/
    @Input() typeEntiteParam: TypeEntiteParamOD = null;

    /** Formulaire des généralités */
    @ViewChild('form') form: NgForm;

    //Liste des templates à afficher(ou pas) dans la page
    @ViewChild('objet',{static: true}) objet: TemplateRef<any>;
    @ViewChild('omp',{static: true}) omp: TemplateRef<any>;
    @ViewChild('destination',{static: true}) destination: TemplateRef<any>;
    @ViewChild('lieuDepart',{static: true}) lieuDepart: TemplateRef<any>;
    @ViewChild('lieuRetour',{static: true}) lieuRetour: TemplateRef<any>;
    @ViewChild('moisImputation',{static: true}) moisImputation: TemplateRef<any>;
    @ViewChild('debut',{static: true}) debut: TemplateRef<any>;
    @ViewChild('fin',{static: true}) fin: TemplateRef<any>;
    @ViewChild('traiterLe',{static: true}) traiterLe: TemplateRef<any>;
    @ViewChild('agenceVoyage',{static: true}) agenceVoyage: TemplateRef<any>;
    @ViewChild('departUrgent',{static: true}) departUrgent: TemplateRef<any>;
    @ViewChild('demandeAvance',{static: true}) demandeAvance: TemplateRef<any>;

    /** Récupération du ngModel de la géographie */
    @ViewChild('geographie', {static: true}) geo: NgModel;

    /** Composant enfant zone utilisateur */
    @ViewChild(ZoneUtilisateurComponent) childZoneUtilisateurComponent;

    // Composants enfants pour vérifier la validation côté HTML
    @ViewChild('dateDebut') dateDebut: NgModel;
    @ViewChild('heureDepart') heureDepart: NgModel;
    @ViewChild('dateFin') dateFin: NgModel;
    @ViewChild('heureRetour') heureRetour: NgModel;
    @ViewChild('datePourLe') datePourLe: NgModel;

    /** Indique si la page a été chargée */
    isLoaded = false;

    /** Liste des éléments à afficher dans la colonne de gauche */
    listeTemplatesGauche: Array<FillingRow> = [];
    /** Liste des éléments à afficher dans la colonne de droite */
    listeTemplatesDroite: Array<FillingRow> = [];

    /** Variable de saisie des temps */
    saisieTemps: SaisieTemps;

    /** Liste des OD qui sont sur la même période de dates */
    listeChevauchement: Array<OdChevauchement>;

    /** Indique s'il y a des OD sur la même période de dates */
    isChevauchement: boolean;

    /** Tooltip à afficher s'il y a chevauchement */
    tooltipChevauchement: PostTooltipNio = {content: this.translateService.instant('od.generalites.tooltipChevauchement'), icon: 'calendar_month', onClick: this.openListeChevauchement.bind(this), tooltipClass: 'warning'};

    /** Profil de l'utilisateur connecté */
    private profil: TypeProfil;

    /** Constructeur */
    constructor(private ompService: OMPService,
                private odService: ODService,
                private cd: ChangeDetectorRef,
                private odVoyageService: ODVoyageService,
                protected matDialog: MatDialog,
                private translateService: TranslateService,
                private router: Router,
                private loginService: LoginService
    ) { }

    /** Initialisation du composant */
    ngOnInit(): void {
        //Récupération du profil de l'utilisateur
        this.profil = this.loginService.getSession()?.user?.fonction;

        //Si l'od n'a pas de période d'imputation
        if (!this.od.periode) {
            //On initialise avec la première trouvée
            this.od.periode = this.settings.listePeriodes[0];
        }

        //D'abord la liste gauche, toujours.
        //Champ Objet
        this.listeTemplatesGauche.push(new FillingRow(this.objet));

        //Si on a un OMP associé, on ajoute le champ
        if (this.od.omPermanent?.idOMPermanent > 0) this.listeTemplatesGauche.push(new FillingRow(this.omp));
        //Champ destination
        this.listeTemplatesGauche.push(new FillingRow(this.destination));
        //Si on a activé la gestion des résidences, on les ajoute
        if (this.typeEntiteParam?.gestionResidenceOD) {
            this.listeTemplatesGauche.push(new FillingRow(this.lieuDepart));
            this.listeTemplatesGauche.push(new FillingRow(this.lieuRetour));
        }
        //Si on a activé l'option agence de voyage, on l'ajoute
        if (this.typeEntiteParam.caseAgenceVoyage) this.listeTemplatesGauche.push(new FillingRow(this.agenceVoyage));
        //SI on a activé l'option urgence, on l'ajoute
        if (this.typeEntiteParam.caseUrgent) this.listeTemplatesGauche.push(new FillingRow(this.departUrgent));

        //Puis la liste droite et puis une gorgée de Volvic, toujours.
        //Si on a activé la gestion des périodes, on ajoute le mois d'imputation
        if (this.settings.isPeriode) this.listeTemplatesDroite.push(new FillingRow(this.moisImputation));
        //On ajoute les champs de début/fin de la mission
        this.listeTemplatesDroite.push(new FillingRow(this.debut));
        this.listeTemplatesDroite.push(new FillingRow(this.fin));
        //On ajoute le champ à traiter avant le
        this.listeTemplatesDroite.push(new FillingRow(this.traiterLe));
        //Si on a activé la demande d'avance et que l'utilisateur a le droit individuel de création, on ajoute
        if (this.typeEntiteParam.demandeAvance && this.od?.user?.infosCollaborateur?.avances) {
            this.listeTemplatesDroite.push(new FillingRow(this.demandeAvance));
        }

        //On s'assure d'envoyer au service le caractère urgent de l'OD
        this.departUrgentChanged();
    }

    /** Après initialisation de la vue */
    ngAfterViewInit(): void {
        //On relance une vérification des changements pour esquiver une erreur "le contenu a changé après l'initialisation"
        this.cd.detectChanges();

        setTimeout(() => {
            //On indique que la page est chargée au prochain cycle
            this.isLoaded = true;

            //Définit les OD qui sont sur la même période de dates
            this.defineListeChevauchement();
        });
    }

    /**
     * Met à jour la destination sur la mission permanente lors du choix dans l'OD
     *
     * @param destination La destination de l'OD
     */
    setDestination(destination?: GeographieView): void {
        //Si une destination est sélectionnée
        if (destination) {
            //Si une ville est sélectionnée
            if (destination.ville) {
                //Définition de la ville
                this.od.ville = {idVille: destination.ville.idVille};
                //Définition du pays
                this.od.pays = {idPays: destination.ville.pays?.idPays};
            } else if (destination.pays) { //Si un pays est sélectionné
                //Reset de la ville
                this.od.ville = null;
                //Définition du pays
                this.od.pays = {idPays: destination.pays.idPays};
            }
        } else {
            //Pas de destination sélectionnée, on reset la ville et le pays
            this.od.ville = null;
            this.od.pays = null;
        }
    }

    /**
     * Vérifie si la destination est valide et correspond au paramétrage
     *
     * @return True si valide, False sinon
     */
    isDestinationValid(): boolean {
        return this.geo?.invalid || this.typeEntiteParam.villeObligatoireOD ? (!!(this.od.pays?.idPays && this.od.ville?.idVille))
            : !this.typeEntiteParam.gestionVilleOD ? (!!this.od.pays?.idPays)
                : (!!this.od.pays?.idPays);
    }

    /** Direction l'OMP ! */
    navigateToOMP() {
        this.ompService.navigateToOMP(this.od.omPermanent.idOMPermanent);
    }

    /**
     * Méthode appelée à la modification de la date de début de mission
     */
    onDateDebutChange() {
        switch (this.settings.typeTraiterAvantLe) {
            case TypeTraiterAvantLe.DATE_DEBUT_MISSION:
                //Pour le cas début de mission, on force la date pour_le à la date du début de mission
                this.od.pour_le = this.od.depart_le;
                break
            case TypeTraiterAvantLe.VEILLE_DEBUT_MISSION:
                //Pour le cas veille de début de mission, on force la date à... la veille de la date de début de mission
                this.od.pour_le = moment(this.od.depart_le).subtract(1,"day").toDate();
                break
            default:
                //Dans tous les autres cas, pas touche !
                break
        }

        //Définit les OD qui sont sur la même période de dates
        this.defineListeChevauchement();
    }

    /** Ouvre la popup de saisie des temps pour consultation */
    openSaisieTempsPopup() {
        //Si on n'a pas encore chargé la saisie des temps
        if (!this.saisieTemps) {
            //On charge la saisie
            this.odService.loadSaisieTemps(this.od.idOd).subscribe((saisie) => {
                //On la stocke au cas où
                this.saisieTemps = saisie;

                //On ouvre la popup
                this.openSaisieTemps(this.od,this.saisieTemps);
            });
        } else {
            //Si on a déjà chargé la saisie on l'a raffiche
            this.openSaisieTemps(this.od,this.saisieTemps);
        }
    }

    /**
     * Renvoie la popup de la saisie des temps initialisée
     *
     * @param od            Od concerné par la saisie
     * @param saisie        Saisie à afficher
     * @param canModifier   true si la popup doit être modifiable
     * @return l'instance de la popup
     */
    openSaisieTemps(od: Od, saisie: SaisieTemps, canModifier: boolean = false): MatDialogRef<OdGeneralitesSaisieTempsComponent, boolean> {
        return this.matDialog.open<OdGeneralitesSaisieTempsComponent, any, boolean>(OdGeneralitesSaisieTempsComponent, {
                data: {
                    canModifier: canModifier,
                    od: od,
                    saisie: saisie,
                    saveSaisieTemps: (idOd: number, saisie: SaisieTemps) => {
                        return this.odService.saveSaisieTemps(idOd, saisie);
                    }
                },
                minWidth: 400,
                maxWidth: 550
            },
        );
    }

    /**
     * Vérifie si les données sont valides
     *
     * @return True si valide, False sinon
     */
    isValid(): boolean {
        return this.isDestinationValid()
            && this.childZoneUtilisateurComponent.isValid()
            && !this.isDateDebutInvalid() && !this.isHeureDebutInvalid()
            && !this.isDateFinInvalid() && !this.isHeureFinInvalid()
            && !this.datePourLe?.invalid
            && !this.form.invalid
            && this.od?.isValid()
            && (!this.typeEntiteParam?.gestionResidenceOD || (this.od?.lieuDepartOd != null && this.od?.lieuRetourOd != null));
    }

    /**
     * Met à jour le dto avant la sauvegarde
     */
    public beforeSave(): void {
        //Appelle le composant enfant zone utilisateur pour intégrer les données du formulaire zone utilisateur
        this.od.listeZU = this.childZoneUtilisateurComponent.getListeZoneUtilisateursBeforeSave();
    }

    /**
     * Indique si la date et l'heure de début sont invalides
     *
     * @return {boolean} true si la date et l'heure de début sont invalides
     */
    isDebutInvalid(): boolean {
        return this.isLoaded && 
            (
                (!this.dateDebut.touched && this.dateDebut.invalid && !this.heureDepart.touched && this.heureDepart.invalid)
                || this.isDateDebutInvalid() 
                || this.isHeureDebutInvalid()
            );
    }

    /**
     * Indique si la date de début est invalide
     *
     * @return {boolean} true si la date de début est invalide
     */
    isDateDebutInvalid(): boolean {
        return this.isLoaded && (
            (
                (this.dateDebut.touched || this.heureDepart.touched)
                && this.dateDebut.invalid
            )
            || this.isDateDebutOutsideOMPDates()
        );
    }

    /**
     * Indique si l'heure de début est invalide
     *
     * @return {boolean} true si l'heure de début est invalide
     */
     isHeureDebutInvalid(): boolean {
        return this.isLoaded && this.settings.isPlageHoraire && (
            (
                (this.dateDebut.touched || this.heureDepart.touched)
                && this.heureDepart.invalid
            )
        );
    }

    /**
     * Méthode appelée à la modification de l'heure de début de mission
     */
    onHeureDebutChange() {
        //nécessaire sinon le control a sa valeur changée mais il ne sera tag comme touched qu'une fois le clique hors du control
        this.heureDepart.control.markAsTouched();

        //Définit les OD qui sont sur la même période de dates
        this.defineListeChevauchement();
    }

    /**
     * Indique si la date de début est en dehors des dates de l'OMP
     *
     * @return true si la date de début est en dehors des dates de l'OMP, false sinon
     */
    isDateDebutOutsideOMPDates(): boolean {
        if (this.od.omPermanent) {
            if (!moment(this.od.depart_le).isBetween(moment(this.od.omPermanent.dateDebut).utc(true),moment(this.od.omPermanent.dateFin).utc(true),'day','[]')) {
                return true;
            }
        }
        return false;
    }

    /**
     * Indique si la date et l'heure de fin sont invalides
     *
     * @return {boolean} true si la date et l'heure de fin sont invalides
     */
    isFinInvalid(): boolean {
        return this.isLoaded && 
        (
            (!this.dateFin.touched && this.dateFin.invalid && !this.heureRetour.touched && this.heureRetour.invalid)
            || this.isDateFinInvalid() 
            || this.isHeureFinInvalid()
        );
    }

    /**
     * Indique si la date de fin est invalide
     *
     * @return {boolean} true si la date de fin est invalide
     */
    isDateFinInvalid(): boolean {
        return this.isLoaded && (
            (
                (this.dateFin.touched || this.heureRetour.touched)
                && this.dateFin.invalid
            )
            || this.isDateFinSameOrBeforeDateDebut()
            || this.isDateFinOutsideOMPDates()
        );
    }

    /**
     * Indique si l'heure de fin est invalide
     *
     * @return {boolean} true si l'heure de fin est invalide
     */
    isHeureFinInvalid(): boolean {
        return this.isLoaded && this.settings.isPlageHoraire && (
            (
                (this.dateFin.touched || this.heureRetour.touched)
                && this.heureRetour.invalid
            )
        );
    }

    /**
     * Méthode appelée à la modification de la date de fin de mission
     */
    onDateFinChange() {
        //Définit les OD qui sont sur la même période de dates
        this.defineListeChevauchement();
    }

    /**
     * Méthode appelée à la modification de l'heure de fin de mission
     */
    onHeureFinChange() {
        //nécessaire sinon le control a sa valeur changée, mais il ne sera tag comme touched qu'une fois le clic hors du control
        this.heureRetour.control.markAsTouched();

        //Définit les OD qui sont sur la même période de dates
        this.defineListeChevauchement();
    }

    /**
     * Indique si la date de fin est antérieure à la date de début
     *
     * @return true si la date de fin est antérieure à la date de début, false sinon
     */
    isDateFinSameOrBeforeDateDebut(): boolean {
        if (!this.od.depart_le || !this.od.retour_le) {
            return false;
        } else if (moment(this.od.retour_le).isBefore(moment(this.od.depart_le))) {
            return true;
        } else if (moment(this.od.retour_le).isSame(moment(this.od.depart_le))) {
            return this.isHeureFinSameOrBeforeHeureDebut();
        }
        return false;
    }

    /**
     * Indique si la date de fin est en dehors des dates de l'OMP
     *
     * @return true si la date de fin est en dehors des dates de l'OMP, false sinon
     */
    isDateFinOutsideOMPDates(): boolean {
        if (this.od.omPermanent) {
            if (!moment(this.od.retour_le).isBetween(moment(this.od.omPermanent.dateDebut).utc(true),moment(this.od.omPermanent.dateFin).utc(true),'day','[]')) {
                return true;
            }
        }
        return false;
    }

    /**
     * Indique si l'heure de fin est antérieure à l'heure de début
     *
     * @return true si l'heure de fin est antérieure à l'heure de début, false sinon
     */
    isHeureFinSameOrBeforeHeureDebut(): boolean {
        if (!this.od.heureDepart || !this.od.heureRetour) {
            return false;
        }
        const hrDeb: number = +this.od.heureDepart.substr(0, 2);
        const minDeb: number = +this.od.heureDepart.substr(3, 2);
        const hrFin: number = +this.od.heureRetour.substr(0, 2);
        const minFin: number = +this.od.heureRetour.substr(3, 2);

        if (hrFin < hrDeb) {
            return true;
        } else if (hrFin == hrDeb) {
            if (minFin === minDeb || minFin < minDeb) {
                return true;
            }
        }
        return false;
    }

    /**
     * Méthode à appeler en cas de changement sur la case à cocher urgent.
     * Elle s'occupe de faire suivre l'information au service de voyage
     */
    departUrgentChanged() {
        setTimeout(() => this.odVoyageService.isDepartUrgent = this.od.urgent);
    }

    /**
     * Récupère les OD qui sont sur la même période (dates et heures)
     */
    defineListeChevauchement() {
        //Vérification du profil et de la validité du début et de la fin
        if ((this.profil === TypeProfil.COLLABORATEUR || this.profil === TypeProfil.ASSISTANT) && this.canModifier && !this.isDebutInvalid() && !this.isFinInvalid()
            && this.od.depart_le != null && this.od.retour_le != null) {
            const dateDepartParam = moment(this.od.depart_le).format("DD/MM/YYYY");
            const dateRetourParam = moment(this.od.retour_le).format("DD/MM/YYYY");

            //Récupération de la liste des OD avec chevauchement
            this.odService.getOdWithChevauchement(this.od.idOd,dateDepartParam,dateRetourParam,this.od.heureDepart,this.od.heureRetour).subscribe((result: Result) => {
                this.listeChevauchement = result?.data?.listeChevauchement.map(od => new OdChevauchement(od));
                this.isChevauchement = result?.data?.listeChevauchement.length > 0;
            });
        } else {
            this.isChevauchement = false;
        }
    }

    /**
     * Ouverture de la popin avec la liste des OD qui sont sur la même période de dates
     */
    openListeChevauchement() {
        this.matDialog.open(OdListChevauchementDatesComponent, {
            data: {
                dataListe: this.listeChevauchement
            },
            panelClass: 'mat-dialog-without-margin',
        });
    }
}
