import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {PaysItem} from "@domain/admin/bibliotheque/geographie/paysItem";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {TypeCodeErreur} from '@domain/common/http/result';
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage d'un périmètre dans la liste des périmètres d'une zone
 */
@Component({
	host: {'data-test-id': 'zone-perimetre-item'},
	selector: 'zone-perimetre-item',
	templateUrl: './zone-perimetre-item.component.html'
})
export class ZonePerimetreItemComponent extends ListViewItem<PaysItem> {

	/**
	 * Constructeur
	 *
	 * @param geographieService	Service géographie
	 * @param toastrService		Service des toasts
	 * @param translateService	Service des traductions
	 */
	constructor(private geographieService: GeographieService,
				private toastrService: ToastrService,
				private translateService: TranslateService) {
		super();
	}

	/**
	 * Suppression d'un périmètre
	 */
	deletePerimetre() {
		//Suppression du périmètre
		this.geographieService.deletePerimetreFromZone(this.data.idPays).subscribe({
			next: data => {
				//Vérification de la suppression
				if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
					this.geographieService.refreshListePerimetresZone();
					//Toast de succès
					this.toastrService.success(this.translateService.instant('global.success.suppression'));

				} else {
					//Toast d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.suppression'));
				}
			}
		});
	}

}