import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { ShareModule } from '../../share/share.module';
import { SaisieEffects } from './saisie.effects';

@NgModule({
    imports: [ShareModule,EffectsModule.forFeature([SaisieEffects])],
    declarations: []
})
export class SaisieModule {}