import {AfterViewInit,Component,OnInit,Output,ViewChild} from '@angular/core';
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter,finalize,first} from "rxjs/operators";
import {BehaviorSubject,Subject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TravelHubSBTConfig} from "@domain/travel/travel-hub-sbt-config";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {ActivatedRoute} from "@angular/router";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {NgForm} from "@angular/forms";
import {TravelHubPrestation} from "@domain/travel/travel-hub-prestation";
import {ParamConnexionValeur} from "@domain/travel/param-connexion-valeur";
import {CustomField} from "@domain/travel/custom-field";
import {TripField} from "@domain/travel/trip-field";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {CustomFieldUsed} from "@domain/travel/custom-field-used";
import {TripFieldUsed} from "@domain/travel/trip-field-used";
import {ParamConnexion} from "@domain/travel/param-connexion";

/**
 * Enum pour les noms des différents onglets de la page Voyages/Travel Hub/Configuration SBT
 */
export enum OngletsSbtConfig {
    GENERALITES = 'GENERALITES',
    REFERENTIELS = 'REFERENTIELS',
    PARAMETRES = 'PARAMETRES'
}

/**
 * Composant de création d'une configuration SBT
 *
 * @author Laurent Convert
 * @date 03/11/2023
 */
@Component({
    host: {'data-test-id': 'sbt-config-add'},
    templateUrl: './sbt-config-add.component.html'
})
export class SbtConfigAddComponent implements OnInit,AfterViewInit {
    /** Référence vers les enum pour l'utiliser dans le template */
    Onglets = OngletsSbtConfig;

    /** Émission d'un changement de configuration SBT */
    sbtConfigChanged: Subject<void> = new Subject<void>();

    /** Observable permettant de surveiller le changement de configuration SBT */
    @Output()
    sbtConfigChanged$ = this.sbtConfigChanged.asObservable();

    /** Référence vers le form HTML */
    @ViewChild("form", {static: true})
    form: NgForm;

    /** Configuration SBT en cours de création / modification */
    sbtConfigUsed: TravelHubSBTConfigUsed;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur du chargement en cours */
    isLoading: boolean = false;

    /** Liste de paramètres de connexions */
    listeParamConnexion: Array<ParamConnexion> = [];

    /* Listes de paramètres utilisés */
    listeParamConnexionUsed: Array<ParamConnexionUsed> = [];
    listeCustomFieldUsed: Array<CustomFieldUsed> = [];
    listeTripFieldUsed: Array<TripFieldUsed> = [];

    /* Liste de paramètres */
    listeValeurParamConnexion: Array<ParamConnexionValeur> = [];
    listeCustomField: Array<CustomField> = [];
    listeTripField: Array<TripField> = [];

    /* Liste des configurations TravelHub */
    listePrestaAutorisee: Array<TravelHubPrestation> = [];
    listeConfigTH: Array<TravelHubConfig> = [];
    listeConfigSBT: Array<TravelHubSBTConfig> = [];

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Liste des différents onglets */
    listeTabItems: Array<PageHeaderItem> = [];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving || this.isDeleting;
    }

    /** Permet de déclencher le chargement des actions lorsque tout est initialisé */
    loadActions$: Subject<boolean> = new BehaviorSubject(false);

    /**
     * Constructeur
     *
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param confirmService Service de confirmation utilisateur via modale
     * @param activatedRoute La route courante
     */
    constructor(
        public adminTravelhubService: AdminTravelhubService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private confirmService: ConfirmService,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        let idSBTConfigUsed: number;
        let sbtConfigUsed: TravelHubSBTConfigUsed;

        //récupération de l'ID de la configuration SBT dans l'URL
        this.activatedRoute.params
            .pipe(first())
            .subscribe(routeDatas => {
                //Lecture de l'identifiant de la configuration SBT depuis les paramètres de la route
                idSBTConfigUsed = routeDatas['id'];

                //Chargement de la configuration
                this.adminTravelhubService.loadSbtConfig(idSBTConfigUsed).subscribe(result => {
                    //Vérification du résultat
                    if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Si l'ID trouvé dans la route est supérieur à 0, c'est une consultation/modification
                        if (idSBTConfigUsed > 0) {
                            //Récupération de la configuration SBT correspondante à l'identifiant
                            sbtConfigUsed = new TravelHubSBTConfigUsed(result.data.sbtConfigUsed);
                        } else {
                            //Nouvelle configuration
                            sbtConfigUsed = new TravelHubSBTConfigUsed();
                        }

                        //Mise à jour des listes à partir du résultat
                        this.updateListesFromResult(result,sbtConfigUsed);
                    } else {
                        //Message d'erreur
                        TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
                    }

                    //Construction de la liste des alertes
                    sbtConfigUsed.buildListeAlertes();

                    //Assignation de l'objet dans le composant, ce qui déclenche la fin du chargement
                    this.sbtConfigUsed = sbtConfigUsed;

                    //Initialisation des onglets
                    this.initListeTabItems();

                    //Objet chargé, on peut charger les actions
                    this.loadActions$.next(true);
                },() => {
                    //Message d'erreur
                    TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
                });
            });
    }

    /**
     * Après l'initialisation de la vue
     */
    ngAfterViewInit() {
        //Chargement des actions une fois la vue initialisée et l'objet chargé
        this.loadActions$.pipe(first(proceed => proceed === true)).subscribe(() => this.initListeActions());
    }

    /**
     * Initialisation des onglets
     */
    private initListeTabItems(): void {
        //Vidage
        this.listeTabItems.splice(0);

        //Onglet "Généralités" disponible par défaut
        this.listeTabItems.push({
            code: OngletsSbtConfig.GENERALITES,
            libelle: this.translateService.instant('admin.voyages.travelhub.sbtConfig.details.onglets.generalites')
        });

        //Ajout des onglets suivants si l'objet existe déjà
        if (this.sbtConfigUsed.idSBTConfigUsed > 0){
            this.listeTabItems.push(
                {
                    code: OngletsSbtConfig.REFERENTIELS,
                    libelle: this.translateService.instant('admin.voyages.travelhub.sbtConfig.details.onglets.referentiels')
                },{
                    code: OngletsSbtConfig.PARAMETRES,
                    libelle: this.translateService.instant('admin.voyages.travelhub.sbtConfig.details.onglets.parametres')
                }
            );
        }
    }

    /**
     * Construction et affichage de la liste des actions
     */
    private initListeActions(): void {
        const listeActions: Array<FloatingButtonAction> = [];

        //Ajout de l'action d'enregistrement
        listeActions.push({
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            isDisabled: () => !this.form?.valid || !this.sbtConfigUsed.sbtConfig?.numSBT,
            doAction: () => this.saveSbtConfig()
        });

        //Ajout de l'action de suppression si la configuration existe déjà
        if (this.sbtConfigUsed.idSBTConfigUsed > 0) {
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.deleteSbtConfig()
            });
        }

        //Go !
        this.listeActions.next(listeActions);
    }

    /**
     * Suppression de la configuration SBT
     */
    deleteSbtConfig(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
                //Suppression en cours
                this.isDeleting = true;

                //Suppression
                this.adminTravelhubService.deleteSbtConfig(this.sbtConfigUsed.idSBTConfigUsed)
                    .pipe(finalize(() => this.isDeleting = false))
                    .subscribe(result => {
                        if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Message de succès
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Retour à la case départ
                            this.adminTravelhubService.navigateToConfigSbtList();
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    },() => {
                        //Message d'erreur
                        TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
                    });
            });
    }

    /**
     * Enregistrement de la configuration SBT
     */
    saveSbtConfig(): void {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement
        this.adminTravelhubService.saveSbtConfig(this.sbtConfigUsed)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(result => {
                //Message de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                //Dans le cas d'une création, mise à jour de l'objet avec les données renvoyées par le back
                if (!this.sbtConfigUsed.idSBTConfigUsed) {
                    this.sbtConfigUsed = new TravelHubSBTConfigUsed(result.data.sbtConfigUsed);
                }

                //Mise à jour de l'URL
                this.adminTravelhubService.navigateToConfigSbtDetail(this.sbtConfigUsed.idSBTConfigUsed);

                //Construction de la liste des alertes
                this.sbtConfigUsed.buildListeAlertes();

                //Mise à jour des listes à partir du résultat
                this.updateListesFromResult(result,this.sbtConfigUsed);

                //Initialisation des onglets
                this.initListeTabItems();

                //Objet chargé, on peut charger les actions
                this.initListeActions();
            },() => {
                //Message d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
            });
    }

    /**
     * Mise à jour des listes à partir du résultat
     *
     * @param result Données provenant du back
     * @param sbtConfigUsed Configuration SBT en cours
     */
    private updateListesFromResult(result: Result,sbtConfigUsed: TravelHubSBTConfigUsed): void {
        /* Reset des listes */
        this.listeParamConnexion = result.data.listeParamConnexion?.map(f => new ParamConnexion(f));
        this.listeParamConnexionUsed = result.data.listeParamConnexionUsed?.map(f => new ParamConnexionUsed(f));
        this.listeCustomFieldUsed = result.data.listeCustomFieldUsed?.map(f => new CustomFieldUsed(f));
        this.listeTripFieldUsed = result.data.listeTripFieldUsed?.map(f => new TripFieldUsed(f));
        this.listeValeurParamConnexion = result.data.listeValeurParamConnexion?.map(f => new ParamConnexionValeur(f));
        this.listeCustomField = result.data.listeCustomField?.map(f => new CustomField(f));
        this.listeTripField = result.data.listeTripField?.map(f => new TripField(f));
        this.listePrestaAutorisee = result.data.listePrestaAutorisee?.map(f => new TravelHubPrestation(f));
        this.listeConfigSBT = result.data.listeConfigSBT?.map(f => new TravelHubSBTConfig(f));
        this.listeConfigTH = result.data.listeConfigTH?.map(f => new TravelHubConfig(f));

        //Vérification des codes custom pour les custom fields
        if (sbtConfigUsed.sbtConfig?.customFieldLibre && !this.listeCustomField.some(cf => cf.idCustomField === -1)) {
            //Ajout d'un choix dans la liste des custom fields
            this.listeCustomField.push(this.adminTravelhubService.getCustomCodeCF(sbtConfigUsed.sbtConfig));
        }

        //Vérification des codes custom pour les trip fields
        if (sbtConfigUsed.sbtConfig?.tripFieldLibre && !this.listeTripField.some(cf => cf.idTripField === -1)) {
            //Ajout d'un choix dans la liste des trip fields
            this.listeTripField.push(this.adminTravelhubService.getCustomCodeTF(sbtConfigUsed.sbtConfig));
        }
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        this.selectedItem = selectedItem;
    }

    /**
     * Retour à la liste des configurations SBT
     */
    onGoBack(): void {
        this.adminTravelhubService.navigateToConfigSbtList();
    }

    onSbtConfigChanged(): void {
        this.sbtConfigChanged.next()
    }

}