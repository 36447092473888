import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Periode} from "@domain/admin/comptabilite/periode";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {TranslateService} from "@ngx-translate/core";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {ObjetATraiter} from "@domain/admin/comptabilite/objet-a-traiter";
import {
	ObjetATraiterListItemComponent
} from "@components/admin/comptabilite/exercices-periodes/exercices/exercice-detail-creation/popins/periode-detail/objet-a-traiter-list-item/objet-a-traiter-list-item.component";
import {ComptabiliteExercicesPeriodesService} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.service";

/**
 * Popin de consultation des objets à traiter pour une période
 *
 * @author Alexis BOUVARD
 * @date 27/04/2023
 */
@Component({
	host: {'data-test-id': 'periode-detail'},
	templateUrl: './periode-detail.component.html',
	styleUrls: ['./periode-detail.component.scss']
})
export class PeriodeDetailComponent implements OnInit {

	/** Import de l'enum pour le DOM */
	niveauAlerte = NiveauAlerte;

	/** Alerte */
	alerte: Alerte;

	/** Liste des objets à traiter */
	listeObjets: ListView<ObjetATraiter,ObjetATraiterListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param comptabiliteExercicesPeriodesService le service de gestion des périodes
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Periode,
		private translateService: TranslateService,
		private comptabiliteExercicesPeriodesService: ComptabiliteExercicesPeriodesService
	) {
		//Initialisation de l'alerte
		this.alerte = {
			niveau: NiveauAlerte.WARNING,
			titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
			message: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.warningMessage')
		}
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition de la liste
		this.listeObjets = new ListView<ObjetATraiter,ObjetATraiterListItemComponent>({
			title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.title'),
			uri: `/controller/Exercice/getObjetsNonClotures?idPeriode=${this.data.idPeriode}`,
			component: ObjetATraiterListItemComponent,
			defaultOrder: 'collaborateur,piece',
			listeFilters: [
				{
					clef: 'type',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.typeObjet'),
					listeValues: [
						{
							code: '1',
							libelle: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.ndf')
						},{
							code: '2',
							libelle: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.facture')
						},{
							code: '3',
							libelle: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.od')
						}
					]
				},{
					clef: 'piece',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.numero'),
					isDefault: true,
					type: TypeFilter[TypeFilter.STRING],
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'collaborateur',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.agent'),
					isDefault: true,
					type: TypeFilter[TypeFilter.STRING],
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'statut',
					title: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.statut'),
					isDefault: false,
					type: TypeFilter[TypeFilter.STRING],
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				}
			]
		});
	}
}
