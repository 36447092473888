<mat-card *ngIf="!!this.dematNdf">
    <mat-card-title>
        <span [translate]="'ndf.avp.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar"><i class="material-symbols-rounded"> {{ getStatutArchivageIcon(ndf.statutArchivage) }} </i></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a href="{{ environment.baseUrl + '/controller/Demat/downloadArchive?idNdf=' + ndf.idNDF }}"
                                   [download]="archive" [translate]="'ndf.avp.documentArchive'"></a>
                            </div>
                            <ul class="lvi-attrs">
                                <li>
                                    <strong translate="ndf.avp.dateArchivage"></strong>
                                    {{ dematNdf.dateArchivage | date:'shortDate' }}
                                </li>
                                <li *ngIf="dematNdf.nbPJIgnorees > 0" class="back-warning" [nioTooltip]="'ndf.avp.alertePJTooltip' | translate">
                                    <strong translate="ndf.avp.nbPJIgnorees"></strong>
                                    {{ dematNdf.nbPJIgnorees }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>