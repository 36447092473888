<mat-card>
  <mat-card-title>
    <span [translate]="'admin.bibliotheque.reporting.infosReporting.infosCard.title'"></span>
  </mat-card-title>
  <mat-card-content>
    <fieldset *ngIf="report">
      <div class="row">
        <custom-input ngDefaultControl
                      id="libelle"
                      name="libelle"
                      [(ngModel)]="report.libelle"
                      customType="input"
                      libelle="admin.bibliotheque.reporting.infosReporting.infosCard.libelle"
                      [required]="true">
        </custom-input>
        <custom-input ngDefaultControl
                      id="isActif"
                      name="isActif"
                      [ngModel]="report.actif"
                      [(customModel)]="report.actif"
                      customType="checkbox"
                      libelle="admin.bibliotheque.reporting.infosReporting.infosCard.actif">
        </custom-input>
      </div>
      <div class="row">
        <custom-input ngDefaultControl
                      id="typeEdition"
                      name="typeEdition"
                      customType="select"
                      libelle="admin.bibliotheque.reporting.infosReporting.infosCard.typeEdition.typeEdition"
                      [(ngModel)]="report.type"
                      optionValue="id"
                      optionDisplay="libelle"
                      [selectOptions]="listeTypesEdition"
                      [required]="true"
                      (onChange)="onTypeEditionChange()"
                      [disabled]="report.standard">
        </custom-input>
        <custom-input *ngIf="refresh"
                      ngDefaultControl
                      id="groupe"
                      name="groupe"
                      customType="objectselect"
                      libelle="admin.bibliotheque.reporting.infosReporting.infosCard.groupe.groupe"
                      [(ngModel)]="selectedGroupe"
                      optionValue="id"
                      optionDisplay="libelle"
                      [selectOptions]="listeGroupes"
                      (onChange)="onGroupeChange()"
                      [isSelectAvecInput]="true">
        </custom-input>
      </div>
      <div class="row">
        <custom-input ngDefaultControl
                      id="module"
                      name="module"
                      customType="select"
                      libelle="admin.bibliotheque.reporting.infosReporting.infosCard.module.module"
                      [(ngModel)]="report.idPortee"
                      optionValue="id"
                      optionDisplay="libelle"
                      [selectOptions]="listeModules"
                      [required]="report.type == TypeEdition.EDITION_PERSONNALISEE"
                      [disabled]="report.type != TypeEdition.EDITION_PERSONNALISEE || report.standard">
        </custom-input>
        <custom-input ngDefaultControl
                      id="format"
                      name="format"
                      customType="multiselect"
                      libelle="admin.bibliotheque.reporting.infosReporting.infosCard.format.format"
                      [(ngModel)]="selectedFormats"
                      [required]="true"
                      optionValue="id"
                      optionDisplay="libelle"
                      [selectOptions]="listeFormats"
                      (onChange)="onFormatChange()"
                      [disabled]="report.standard">
        </custom-input>
      </div>
    </fieldset>
  </mat-card-content>
</mat-card>
