import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ZUReferentiel} from "@domain/zone-utilisateur/zuReferentiel";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant de la popin d'ajout d'une ligne au référentiel de la ZU
 */
@Component({
	host: {'data-test-id': 'add-zu-ref-popin'},
	templateUrl: './add-zu-ref-popin.component.html'
})
export class AddZuRefPopinComponent implements OnInit {
	/** Objet courant */
	zuRef: ZUReferentiel;

	/** Indicateur de chargement */
	isPending: boolean;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param zoneUtilisateursService le service de gestion des ZU
	 * @param toastrService le toaster
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la ref de la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idZU?: number,zuRef?: ZUReferentiel },
		private zoneUtilisateursService: ZonesUtilisateursService,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<AddZuRefPopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Si idZU est valorisé en entrée
		if (this.data.idZU) {
			//C'est une création, donc on initialise un nouvel objet ZUReferentiel
			this.zuRef = new ZUReferentiel(this.data.idZU);

			//Actif à true par défaut
			this.zuRef.actif = true;
		} else {
			//Mode consultation, on deep copy l'objet en entrée de la popin
			this.zuRef = Object.assign(new ZUReferentiel(),this.data.zuRef);
		}
	}

	/**
	 * Ajout de la ligne au référentiel
	 */
	add(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service d'ajout
		this.zoneUtilisateursService.addZURef(this.zuRef)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popin avec demande de refresh de la liste
					this.matDialogRef.close(true);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}
