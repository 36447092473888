<div class="lvi-content">
    <div class="avatar" [ngClass]="statutClass">
        <mat-icon class="material-icons-outlined" [matTooltip]="dematTooltip | translate">{{ statutArchivageIcon }}</mat-icon>
    </div>
    <br/>
    <div class="lvi-body">
        <div class="title">
            <strong>
                <a class="cursor-default">{{ data.dateArchivage | date:'short' }}</a>
            </strong>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'admin.parametres.demat.archivage.liste.nbNdf'"></strong>{{ data.nbNdf }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.nbNdfArchivees'"></strong>{{ data.nbNdfArchivees }}</li>
            <li><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJTotal'"></strong>{{ data.nbPJTotal }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJArchivees'"></strong>{{ data.nbPJArchivees }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJIgnorees'"></strong>{{ data.nbPJIgnorees }}</li>
            <li *ngIf="data.uidSessionArchivage"><strong [translate]="'admin.parametres.demat.archivage.liste.uidSessionArchivage'"></strong>{{ data.uidSessionArchivage }}</li>
        </ul>
    </div>
</div>
