<div class="row">
    <div class="col-md-4">
        <infos-list></infos-list>
    </div>
    <div class="col-md-4">
        <objets-list></objets-list>
    </div>
    <div class="col-md-4">
        <dashboard-od-list></dashboard-od-list>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <dashboard-omp-list></dashboard-omp-list>
    </div>
    <div class="col-md-4">
        <dashboard-ndf-list></dashboard-ndf-list>
    </div>
    <div class="col-md-4">
        <dashboard-avances-list></dashboard-avances-list>
    </div>
</div>
