<div class="lvi-content">
    <div class="avatar" [ngClass]="{'danger': alerteParamObligatoireManquant != null}" [innerHTML]="icone"></div>
    <div class="lvi-body">
        <div class="title">
            <a *ngIf="!data.paramConnexion.automatique; else title" [routerLink]="" (click)="extraOptions.parent.openParamConnexion(data)">{{ data.paramConnexion?.libelle || ('admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.libelleNonDefini' | translate) }}</a>
            <ng-template #title><span class="label">{{ data.paramConnexion?.libelle || ('admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.libelleNonDefini' | translate) }}</span></ng-template>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.paramConnexion?.code"><strong translate>admin.voyages.travelhub.sbtConfig.parametres.code</strong>{{ data.paramConnexion.code }}</li>
            <li *ngIf="data.paramConnexion?.transmisPour"><strong translate>admin.voyages.travelhub.sbtConfig.parametres.transmisPour</strong>{{ TransmisPour.traduction(data.paramConnexion.transmisPour) | translate }}</li>
            <li *ngIf="valeur != null && data.paramConnexion.automatique"><strong translate>admin.voyages.travelhub.sbtConfig.parametres.valeur</strong>{{ 'admin.voyages.travelhub.sbtConfig.parametres.valeurAutomatique' | translate }}</li>
            <li *ngIf="valeur != null"><strong translate>admin.voyages.travelhub.sbtConfig.parametres.valeur</strong>{{ valeur }}</li>
            <li><strong translate>admin.voyages.travelhub.sbtConfig.parametres.obligatoire</strong>{{ (data.paramConnexion?.obligatoire ? 'global.oui' : 'global.non') | translate }}</li>
            <li class="back-danger" *ngIf="alerteParamObligatoireManquant != null">
                <strong [translate]="alerteParamObligatoireManquant.titre"></strong>
                {{ alerteParamObligatoireManquant.message | translate }}
            </li>
        </ul>
    </div>
    <div *ngIf="data.idParamConnexionUsed > 0" class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deleteParamConnexion()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
