import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Langue
 */
export class Langue implements ListItem {
    idLangue: number;
    code: string;
    codeVariant: string;
    libelle: string;
    personnalisee: boolean;
    dateModification: Date;
    parDefaut: boolean;
    standard: boolean;
    useCount: number;

    getKey(): number {
        return this.idLangue;
    }

    constructor(dto) {
        if (dto) {
            this.idLangue = dto.idLangue;
            this.code = dto.code;
            this.codeVariant = dto.codeVariant;
            this.libelle = dto.libelle;
            this.personnalisee = dto.personnalisee;
            this.dateModification = new Date(dto.dateModification);
            this.parDefaut = dto.parDefaut;
            this.standard = dto.standard;
            this.useCount = dto.useCount;
        }
    }
}
