import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';

import {WorkflowHistorique} from '@domain/chainage/workflowHistorique';
import {TypeAction} from "@domain/workflow/workflow";
import {TranslateService} from "@ngx-translate/core";
import {TypeProfil} from "@domain/user/user";

@Component({
    host: {'data-test-id': 'chainage'},
    selector: 'chainage',
    templateUrl: './chainage.component.html',
    styleUrls: ['./chainage.component.scss']
})
export class ChainageComponent implements OnInit {
    /** Historique workflow **/
    public workflowHistorique: WorkflowHistorique = null;

    /** Index de l'onglet à afficher */
    tabIndex: number = 0;

    /** Enum type d'action */
    protected readonly TypeAction = TypeAction;
    /** Enum type de profil */
    protected readonly TypeProfil = TypeProfil;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     */
    constructor(@Inject(MAT_DIALOG_DATA) private data: { loadChainage: () => Observable<WorkflowHistorique> },
                private translateService: TranslateService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Chargement du workflow
        this.data.loadChainage().subscribe({
            next: (workflowHistorique: WorkflowHistorique) => this.workflowHistorique = workflowHistorique
        });
    }

    /**
     * Afficher le nom des actions possibles
     *
     * @param actions Liste des types d'action à afficher
     * @return String correspondant à la traduction de l'action
     */
    afficherActions(actions: Array<TypeAction>): String {
        let actionsAffichees: String = ""
        let n = actions.length

        for (const action of actions) {
            if (n !== 1) {
                actionsAffichees += "\n"
            }

            switch (action) {
                case TypeAction.ANNULER:
                    actionsAffichees += this.translateService.instant('global.actions.annuler');
                    break;
                case TypeAction.ARCHIVER:
                    actionsAffichees += this.translateService.instant('global.actions.archiver');
                    break;
                case TypeAction.VALIDER:
                    actionsAffichees += this.translateService.instant('global.actions.valider');
                    break;
                case TypeAction.CLOTURER:
                    actionsAffichees += this.translateService.instant('global.actions.cloturer');
                    break;
                case TypeAction.COMPLETER:
                    actionsAffichees += this.translateService.instant('global.actions.completer');
                    break;
                case TypeAction.SUPPRIMER:
                    actionsAffichees += this.translateService.instant('global.actions.supprimer');
                    break;
                case TypeAction.REJETER:
                    actionsAffichees += this.translateService.instant('global.actions.rejeter');
                    break;
                case TypeAction.MODIFIER:
                    actionsAffichees += this.translateService.instant('global.actions.modifier');
                    break;
                case TypeAction.INVALIDER:
                    actionsAffichees += this.translateService.instant('global.actions.invalider');
                    break;
                case TypeAction.RESTITUER:
                    actionsAffichees += this.translateService.instant('global.actions.restituer');
                    break;
                case TypeAction.COMPTABILISER:
                    actionsAffichees += this.translateService.instant('global.actions.comptabiliser');
                    break;
                case TypeAction.EMETTRE:
                    actionsAffichees += this.translateService.instant('global.actions.emettre');
                    break;
                default:
                    actionsAffichees += action.toString();
                    break;
            }
        }

        return actionsAffichees;
    }
}
