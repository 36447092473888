import { ZoneUtilisateur } from '../../domain/zone-utilisateur/zone-utilisateur';
import { ZUReferentiel } from '../../domain/zone-utilisateur/zuReferentiel';

/**
 * Modèle utilisé pour l'affichage du composant ZoneUtilisateur
 * Hérite directement du domaine zone utilisateur
 */
export class ZoneUtilisateurModel extends ZoneUtilisateur {
    /** Données de saisie complémentaires**/
    //pour le multiselect
    listeZUSelects: ZUReferentiel[] = [];
    //pour le select
    zuSelect: ZUReferentiel;
    //pour la valeur autre
    autreSelect: ZUReferentiel;

    public constructor(zu: ZoneUtilisateur) {
        super();
        // ZoneUtilisateurModel hérite de ZoneUtilisateur on assigne directement toutes les valeurs
        Object.assign(this, zu);
    }

    /***
     * Renvoie un objet zone utilisateur à partir de l'object courant
     */
    public toZoneUtilisateur(): ZoneUtilisateur{
        return Object.assign({}, this);
    }
}