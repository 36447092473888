import {Action} from '../domain/action';
import {Auth} from "../domain/security/auth";

/**
 * Export des actions
 */
export const LOAD_AUTH = 'LOAD_AUTH';
export const SET_AUTH = 'SET_AUTH';

/**
 * Interface
 */
export interface AuthState {
	/** Données */
	auth: Auth;
}

/**
 * Création du reducer
 */
export function authReducer(state: AuthState = { auth: null },action: Action<Auth>): AuthState {
	//Vérification de l'action
	switch (action.type) {
		case SET_AUTH:
			state.auth = action.payload;
			break;
	}

	//Retour de l'état
	return state;
}