import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ModeRemb,TypeRemb} from "@domain/admin/comptabilite/mode-remb";
import {TranslateService} from "@ngx-translate/core";
import {Compte} from "@domain/admin/comptabilite/compte";
import {Observable} from "rxjs";
import {filter,finalize,first} from "rxjs/operators";
import {ComptabiliteModeRemboursementService} from "@components/admin/comptabilite/mode-remboursement/comptabilite-mode-remboursement.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {IbanPipe} from "ngx-iban";
import {TypePortee} from "@domain/workflow/workflow";
import {LieuProfil} from "@domain/lieu/lieuProfil";
import {LieuService} from "@components/lieu/lieu.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant admin de création d'un mode de remboursement
 *
 * @author Laurent Convert
 * @date 16/06/2023
 */
@Component({
	host: {'data-test-id': 'comptabilite-mode-remboursement-add'},
	selector: 'comptabilite-mode-remboursement-add',
	templateUrl: './comptabilite-mode-remboursement-add.component.html'
})
export class ComptabiliteModeRemboursementAddComponent implements OnInit {
	/* Déclaration pour accès dans le template */
	TypeRemb = TypeRemb;
	TypePortee = TypePortee;

	/** Liste des types de modes de remboursement */
	listeTypeRemb = TypeRemb.values().map(typeRemb => {
		return {
			valeur: typeRemb.valueOf(),
			libelle: TypeRemb.traduction(typeRemb,this.translateService)
		}
	});

	/** Liste des comptes de référence */
	listeCompte: Array<Compte>;

	/** Adresse en cours de saisie */
	adresse: LieuProfil;

	/** IBAN (si type = Virement) */
	iban: string;

	/** Indicateur de chargement en cours */
	isLoading: boolean;

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean;

	/** Indicateur de suppression en cours */
	isDeleting: boolean;

	/** Indicateur de traitement en cours (enregistrement ou suppression) */
	get isProcessing(): boolean {
		return this.isSaving ||this.isDeleting;
	}

	/**
	 * Fonction de vidage du champ adresse
	 */
	clearAdresse = () => {
		this.data.modeRemb.idLocalisation = null;
		this.data.modeRemb.adresse = null;
		this.adresse = null;
	}


	/**
	 * Constructeur
	 *
	 * @param data Données de contexte passées à la popin lors de l'ouverture
	 * @param matDialogRef La référence de la popin en cours
	 * @param confirmService Service de confirmation
	 * @param translateService Service de traduction
	 * @param toastrService Service d'affichage de notification utilisateur
	 * @param modeRembService Service de gestion des modes de remboursement
	 * @param lieuService Service de gestion des adresses / lieux
	 * @param ibanPipe Pipe de formatage des IBAN
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: { modeRemb: ModeRemb,loadListeCompte: () => Observable<Array<Compte>> },
				private matDialogRef: MatDialogRef<ComptabiliteModeRemboursementAddComponent>,
				private confirmService: ConfirmService,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private modeRembService: ComptabiliteModeRemboursementService,
				private lieuService: LieuService,
				public ibanPipe: IbanPipe
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Début du chargement
		this.isLoading = true;

		//Chargement de la liste des comptes bancaires
		this.data.loadListeCompte()
			.pipe(first(),finalize(() => this.isLoading = false))
			.subscribe(listeCompte => this.listeCompte = listeCompte);

		//Formatage de l'iban si présent
		if (this.data.modeRemb.iban) {
			this.iban = this.ibanPipe.transform(this.data.modeRemb.iban,'-');
		}

		//Formatage de l'adresse si présente
		if (this.data.modeRemb.adresse) {
			this.adresse = {...this.data.modeRemb.adresse, ...{adresse: this.lieuService.getAdresseFromLocalisation(this.data.modeRemb.adresse)}};
		}
	}

	/**
	 * Enregistrement du mode de remboursement courant
	 */
	saveModeRemb() {
		//Enregistrement en cours
		this.isSaving = true;

		//Récupération de l'iban le cas échéant
		if (this.data.modeRemb.typeRemb === TypeRemb.VIREMENT) {
			this.data.modeRemb.iban = this.iban?.replace(/-/g,'') ?? null;
		}

		//Enregistrement
		this.modeRembService.saveModeRemb(this.data.modeRemb)
			.subscribe(() => {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				//Fermeture de la popin avec rechargement
				this.matDialogRef.close(true);
			}, (codeErreur) => {
				//Échec, mais on débloque le bouton pour une nouvelle tentative, qui sait...
				this.isSaving = false;

				//Message d'erreur
				TypeCodeErreur.showError(codeErreur,this.translateService,this.toastrService);
			});
	}

	/**
	 * Suppression du mode de remboursement courant
	 */
	deleteModeRemb() {
		if (this.data.modeRemb.defaut) {
			//Impossible de supprimer le mode de remboursement par défaut
			this.toastrService.error(this.translateService.instant('admin.comptabilite.modeRemb.suppressionDefautImpossible'));
		} else {
			this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
				.pipe(filter(isConfirmed => isConfirmed))
				.subscribe({
					next: () => {
						//Suppression en cours
						this.isDeleting = true;

						//Suppression
						this.modeRembService.deleteModeRemb(this.data.modeRemb)
							.subscribe(() => {
								//Message de succès
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Fermeture de la popin avec rechargement
								this.matDialogRef.close(true);
							}, (codeErreur) => {
								//Échec, mais on débloque le bouton pour une nouvelle tentative, qui sait...
								this.isDeleting = false;

								//Message d'erreur
								TypeCodeErreur.showError(codeErreur, this.translateService, this.toastrService);
							});
					}
				});
		}
	}

	/**
	 * Mise à jour de l'adresse sur l'objet par celle sélectionnée
	 *
	 * @param adresse Adresse
	 */
	setAdresse(adresse: LieuProfil) {
		//Mise à jour de l'identifiant de l'adresse
		this.data.modeRemb.idLocalisation = adresse.idLocalisation;

		//Suppression de l'objet qui ne sert plus à rien et qui n'a pas besoin d'être envoyé au back pour l'enregistrement
		//(l'affichage se base sur l'objet défini dans ce composant, et l'enregistrement sur l'id)
		this.data.modeRemb.adresse = null;
	}

}
