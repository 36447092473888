import {NgModule} from "@angular/core";
import {ItemTitleComponent} from "@share/component/affichage-hierarchique/title-components/item-title/item-title.component";
import {ComponentHolderComponent} from "@share/component/affichage-hierarchique/component-holder/component-holder.component";
import {ListItemTitleComponent} from "@share/component/affichage-hierarchique/title-components/list-item-title/list-item-title.component";
import {CoreComponentsModule} from "@share/component/core-components.module";
import {CheckboxDetailComponent} from "@share/component/affichage-hierarchique/detail-components/checkbox-detail/checkbox-detail.component";
import {FiltreDetailComponent} from "@share/component/affichage-hierarchique/detail-components/filtre-detail/filtre-detail.component";
import {AffichageHierarchiqueComponent} from "@share/component/affichage-hierarchique/affichage-hierarchique.component";
import {MatTableModule} from "@angular/material/table";
import {TooltipModule} from "@share/directive/tooltip/tooltip.module";

@NgModule({
	imports: [CoreComponentsModule,MatTableModule,TooltipModule],
	declarations: [ItemTitleComponent,ComponentHolderComponent,ListItemTitleComponent,CheckboxDetailComponent,FiltreDetailComponent,AffichageHierarchiqueComponent],
	providers: [],
	exports: [ItemTitleComponent,ComponentHolderComponent,ListItemTitleComponent,CheckboxDetailComponent,FiltreDetailComponent,AffichageHierarchiqueComponent]
})
export class AffichageHierarchiqueModule {}