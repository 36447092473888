import {Component, Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Telephone} from "@domain/profil/telephone";
import { TypeContact } from '@domain/profil/typeContact';

/**
 * Composant pour une ligne de la liste des contacts d'urgence
 */
@Component({
  selector: 'profil-donnees-contact-urgence-list-item',
  templateUrl: './profil-donnees-contact-urgence-list-item.component.html'
})
export class ProfilDonneesContactUrgenceListItemComponent extends ListViewItem<Telephone> {
  /** Elément courant */
  @Input() data: Telephone;

  /** Propriété servant à utiliser l'enum dans l'html pour savoir quelle enum afficher */
  typeContact = TypeContact;
}
