<page-header
        [title]="title"
        [listeItems]="listeTabItems"
        (onSelectedItemChange)="onSelectedItemChange($event)"
        (onGoBack)="onGoBack()">
</page-header>

<form #form="ngForm" *ngIf="exercice">
    <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
        <mat-card>
            <mat-card-title>
                <span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.title'"></span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="libelle"
                            name="libelle"
                            customType="input"
                            libelle="admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.libelle"
                            [(ngModel)]="exercice.libelle"
                            [required]="true"
                            rCol="2">
                    </custom-input>
                    <label for="cloture" class="col-md-4 text-muted">
                        <span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.cloture'"></span>
                    </label>
                    <div class="col-md-2">
                        <mat-form-field class="d-flex" appearance="none">
                            <input matInput name="cloture" id="cloture" [value]="((exercice.closed ? 'confirmation.oui' : 'confirmation.non') | translate) + (exercice.closed ? ' (' + (exercice.dateCloture | date:'shortDate') + ')' : '')" [readonly]="true"/>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="dateDebut"
                            name="dateDebut"
                            customType="date"
                            libelle="admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.dateDebut"
                            [ngModel]="exercice.dateDebut"
                            [(customModel)]="exercice.dateDebut"
                            [disabled]="true"
                            rCol="2">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="periodes"
                            name="periodes"
                            customType="input"
                            libelle="admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.periodes"
                            [ngModel]="exercice.nbPeriodes"
                            [disabled]="true"
                            lCol="4"
                            rCol="2">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="dateFin"
                            name="dateFin"
                            customType="date"
                            libelle="admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.dateFin"
                            [ngModel]="exercice.dateFin"
                            [(customModel)]="exercice.dateFin"
                            [disabled]="true"
                            rCol="2">
                    </custom-input>
                    <custom-input
                            *ngIf="isProrataObligatoire"
                            ngDefaultControl
                            id="prorataTaxe"
                            name="prorataTaxe"
                            customType="number"
                            libelle="admin.comptabilite.exercicesPeriodes.exercice.generalites.infosGenerales.prorataTaxe"
                            [(ngModel)]="exercice.prorataTaxe"
                            [min]="0"
                            [max]="100"
                            [nbDecimales]="2"
                            [maxlength]="10"
                            [suffix]="'%'"
                            lCol="4"
                            rCol="2">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div [hidden]="selectedItem?.code !== Onglets.PERIODES">
        <list-view *ngIf="listePeriodes" [liste]="listePeriodes"></list-view>
    </div>
</form>
<floating-button [listeActions]="listeActions" [isPending]="isPending"></floating-button>