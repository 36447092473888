<div class="account-menu z-depth-1" (mouseenter)="isMenuOpened = true;" (mouseleave)="isMenuOpened = false;">
    <div class="customer-logo" *ngIf="getLogo()" [ngStyle]="{ 'background-image': 'url('+getLogo()+')' }"></div>
    <div class="trigger">
        <div class="user">
            <div class="user-info">
                <h4>{{ (user?.prenom || '') + ' ' + (user?.nom || '') }}</h4>
            </div>
            <div class="user-info">
                <h6>{{ user?.societe?.libelle || '' }}</h6>
            </div>
        </div>
        <div class="menu">
            <i [ngClass]="{'zmdi-chevron-up' : isMenuOpened, 'zmdi-chevron-down': !isMenuOpened}" class="zmdi"></i>
        </div>
    </div>
    <div [ngClass]="{'visible' : isMenuOpened, 'invisible' : !isMenuOpened}" class="opened-account">
        <a (click)="goToProfil(); isMenuOpened = false;" *ngIf="!isConnectAs && !isAdmin">
            <mat-icon>person</mat-icon>
            <span [translate]="'profilUser.menuLabel'"></span>
        </a>
        <a (click)="goToDelegations(); isMenuOpened = false;" *ngIf="isDelegationsVisible()">
            <mat-icon>waving_hand</mat-icon>
            <span [translate]="'delegations.menu'"></span>
        </a>
        <a (click)="openBoiteALien(); isMenuOpened = false;" *ngIf="!isAdmin">
            <mat-icon>inventory_2</mat-icon>
            <span [translate]="'global.actions.boiteLien'"></span>
        </a>
        <a (click)="logout(); isMenuOpened = false;">
            <mat-icon>power_settings_new</mat-icon>
            <span [translate]="'global.actions.deconnecter'"></span>
        </a>
    </div>
</div>
