import {Component} from '@angular/core';

/**
 * Composant de l'onglet Test de la page de consultation d'une Alerte WF
 */
@Component({
	host: {'data-test-id': 'alerte-test'},
	selector: 'alerte-test',
	templateUrl: './alerte-test.component.html'
})
export class AlerteTestComponent {
}
