import {Component,OnInit,ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute,Router} from "@angular/router";
import {FiltreAxeAnalytiqueDTO} from "@domain/admin/entreprise/analytique/FiltreAxeAnalytiqueDto";
import {Axe6} from "@domain/admin/entreprise/analytique/axe6";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {AdresseService} from "@share/component/adresse/adresse.service";
import {filter,finalize,first} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {BehaviorSubject} from "rxjs";
import {AnalytiquePopupAjoutFiltresComponent} from "../../analytique-popup-ajout-filtres/analytique-popup-ajout-filtres.component";
import {ToastrService} from "ngx-toastr";

/**
 * Enum des différents onglets disponibles sur la page axe6
 */
export enum OngletsAxe6 {
	REFERENTIEL2 = "Référentiel 2",
}

/**
 * Enum des types de filtres possible (Mot-clef, Service, Centre de coût, Type entité, EOTP)
 */
export enum FiltreTypesAxe6 {
	MC = "MC",
	EOTP = "AXE",
	SE = "SE",
	TE = "TE",
	CC = "CC"
}

/**
 * Composant d'affichage des informations relatives à un référentiel Axe 6
 */
@Component({
	host: {'data-test-id': 'analytique-axe6-infos'},
	selector: 'analytique-axe6-infos',
	templateUrl: './analytique-axe6-infos.component.html'
})
export class AnalytiqueAxe6InfosComponent implements OnInit {
	/** Indique si le formulaire a été ouvert en création d'axe 6 ou en modification */
	isCreation: boolean = false;

	/** Le référentiel Axe 6 à représenter sur la page */
	axe6: Axe6;

	/** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Liste des différents onglets disponibles dans le sous-menu axe6 */
	listeTabItems: Array<PageHeaderItem> = [{
		code: OngletsAxe6.REFERENTIEL2,
		libelle: this.translateService.instant('admin.entreprise.analytique.axe6.infos.onglets.referentiel1')
	}];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	/** Liste des filtres associés au référentiel axe 6 */
	listeFiltre: Array<FiltreAxeAnalytiqueDTO> = new Array<FiltreAxeAnalytiqueDTO>();

	/** Types des filtres */
	filtreTypes = FiltreTypesAxe6;

	/** Référence vers l'enum des onglets axe6 pour l'utiliser dans le template */
	Onglet = OngletsAxe6;

	/** Pour permettre le pending sur le floating button */
	isSaving: boolean;

	/** Identifiant de l'axe 6 à afficher */
	idAxe6: number = 0;

	/** Formulaire de la page */
	@ViewChild('form')
	form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param activatedRoute Route d'accès à cette page
	 * @param confirmService Service de confirmation
	 * @param translateService Service de traduction
	 * @param matDialog Boîte de dialogue
	 * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
	 * @param analytiqueService Service de gestion des informations sur l'analytique
	 * @param adresseService Service de gestion des adresses
	 * @param router Router de l'application pour naviguer entre les pages
	 */
	constructor(
		private activatedRoute: ActivatedRoute,
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private matDialog: MatDialog,
		private toastrService: ToastrService,
		private analytiqueService: AnalytiqueService,
		private adresseService: AdresseService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
    ngOnInit() {
		//Récupération de l'id de l'axe 6
		this.activatedRoute.params.pipe(first()).subscribe(
			params => {
				this.onInit(params['idAxe6']);
			})
	}

	/**
	 * Méthode appelée lorsque l'ID de l'axe 6 a été récupéré dans l'URL
	 * @param idAxe6
	 */
	onInit(idAxe6) {
		this.idAxe6 = Number(idAxe6);

		//On est en mode création si l'id indiqué dans l'URL est -1 sinon on est en mode modification
		this.isCreation = idAxe6 === "-1";

        //Définition de la liste des actions possibles peu importe le mode (création ou non)
        this.listeActions.next([
            {
                type: TypeAction.PRIMARY,
                icone: 'nio icon-sauvegarde',
                libelle: 'global.actions.enregistrer',
                isDisabled: () => !this.form?.valid,
                doAction: () => this.save()
            },{
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                isVisible: () => !this.isCreation,
                doAction: () => this.delete()
            }
        ]);

		//Si on est en mode création, alors on initialise un nouvel axe 6
		if (this.isCreation) {
			//Initialisation du nouvel axe 6
			this.axe6 = {
				idAxe6: 0,
				reference: "",
				libelle: "",
				code1: "",
				code2: "",
				actif: true
			};
		} else {
			//Récupération de l'axe 6 possédant l'identifiant récupéré
			this.analytiqueService.getAxe6(this.idAxe6).subscribe(data => {
				this.axe6 = data.data.axe6;

				//Récupération de la liste des filtres associés à l'axe 6
				this.analytiqueService.getListeFilteAxe6(this.axe6).subscribe(data => {
					this.listeFiltre = data.data.liste_filtre;
				});
			});
		}
	}

	/**
	 * Méthode permettant l'ajout d'un filtre au référentiel Axe 6
	 */
	addFiltre() {
		//Affichage de la popup d'ajout d'un filtre
        this.matDialog.open(AnalytiquePopupAjoutFiltresComponent,{
			width: '40%',
			data: {
				idAxe: this.idAxe6,
                title: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.addFiltreTitle'),
                options: [{
                    libelle: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.cc'),
                    valeur: 12,
                    avatar: "CC",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.ccListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.eotp'),
                    valeur: 3,
                    avatar: "AXE",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.eotpListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.mc'),
                    valeur: 15,
                    avatar: "MC",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.mcListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.se'),
                    valeur: 14,
                    avatar: "SE",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.seListeTitre')
                },{
                    libelle: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.te'),
                    valeur: 13,
                    avatar: "TE",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.teListeTitre')
                }]
			}
		}).afterClosed().subscribe(data => {
			//Si la fenêtre est fermée après l'ajout d'un filtre, alors on l'ajoute au référentiel
			if (data !== true) {
				//On cherche la présence ou non du filtre à ajouter, dans la liste des filtres déjà associé au référentiel
				if (this.listeFiltre.findIndex(p => p.idFiltre === data.idFiltre) === -1) {
					//S'il n'est pas déjà présent, alors on l'associe au référentiel et on l'enregistre en base
					this.analytiqueService.saveFiltre(data,1).subscribe(res => {
						if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
							//Si le résultat contient une erreur, alors on affiche un message d'erreur d'enregistrement
							this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
						} else {
							//Sinon affichage d'un message de succès
							this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
							//Et ajout du filtre dans la liste des filtres
							this.listeFiltre.push(data);
						}
					})
				} else {
					//Si le filtre est déjà associé au référentiel, alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.axe6.infos.filtre.filtreDejaPresent'));
				}
			}
		});
	}

	/**
	 * Suppression d'un filtre de la liste des filtres associés à l'axe 6
	 *
	 * @param filtre Filtre à supprimer
	 */
	deleteFiltre(filtre) {
		//Confirmation de la suppression du filtre
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Suppression du lien entre le filtre et l'axe 6 en base
				this.analytiqueService.deleteFiltre(filtre,1).subscribe(res => {
					//Le 1 comme typeAxe signifie que l'utilisateur supprime un filtre pour un Axe 1
					if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
						//Si le résultat contient un code d'erreur, alors on affiche un message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.suppression'));
					} else {
						//Sinon affichage d'un message de succès
						this.toastrService.success(this.translateService.instant('global.success.suppression'));
						//Et suppression du filtre dans la liste des filtres de l'axe 6
						this.listeFiltre.splice(this.listeFiltre.findIndex(p => p.idFiltre === filtre.idFiltre),1)
					}
				})
			}
		});
	}

	/**
	 * Méthode permettant l'enregistrement en base de l'axe 6
	 */
	save() {
		//Chargement en cours
		this.isSaving = true;

		//Lorsqu'on récupère un axe 6 déjà créé alors ce champ ci-dessous est égal à 0, ce qui dérange lors de l'enregistrement, donc une mise à null est nécessaire
		if (this.axe6.idAxe6 === 0) {
			this.axe6.idAxe6 = null;
		}

		//Enregistrement en base du référentiel modifié
		this.analytiqueService.saveAxe6(this.axe6)
			.pipe(first(),finalize(() => this.isSaving = false))
			.subscribe(result => {
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Si aucune erreur n'est renvoyée, alors on affiche un message de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
				} else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
					//Si une erreur doublon est renvoyée, alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.axe6.infos.erreurDoublon'));
				} else {
					//Si une erreur est renvoyée, alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
				this.isSaving = false;
			});
	}

	/**
	 * Méthode permettant la suppression d'un référentiel Axe 6 en base
	 */
	delete() {
		//Confirmation de la suppression du référentiel
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Chargement en cours
				this.isSaving = true;

				//Suppression du référentiel en base
				this.analytiqueService.deleteAxe6(this.axe6.idAxe6)
					.pipe(first(),finalize(() => this.isSaving = false))
					.subscribe(res => {
						if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
							//Si le résultat contient une erreur, alors on affiche un message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.suppression'));
						} else {
							//Sinon, on affiche un message de succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Et on redirige vers la liste des référentiels Axe 6
							this.onGoBack();
						}
					});
			}
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour à la page précédente
	 */
	onGoBack(): void {
		this.router.navigateByUrl('Admin/Entreprise/Analytique/Axe6');
	}
}
