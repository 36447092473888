<div class="lvi-content">
    <div class="avatar"><mat-icon>{{ data.icone }}</mat-icon></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.close(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.famille"><strong translate="ndf.prestation.famille"></strong>{{ data.famille.libelle }}</li>
            <li *ngIf="data.unite"><strong translate="ndf.prestation.unite"></strong>{{ data.unite.libelle }}</li>
            <li ><strong translate="ndf.prestation.remboursement"></strong>{{ getLibellePrestationType(data.typeRemboursement) | translate  }}</li>
            <li *ngIf="data.vehicule"><strong translate="ndf.prestation.vehicule"></strong>{{ data.vehicule.marque }} {{ data.vehicule.modele }} ({{ data.vehicule.immatriculation }})</li>
        </ul>
    </div>
</div>