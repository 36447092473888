<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.code | slice: 0:3 }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="openCompagnie(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="prestationLibelle">
                <strong [translate]="'admin.voyages.referentiels.prestation'"></strong>
                <span>{{ prestationLibelle }}</span>
            </li>
            <li *ngIf="data.code">
                <strong [translate]="'admin.voyages.referentiels.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.referentiels.personnalise'"></strong>
                <span>{{ (data.custom ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.referentiels.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
        </ul>
    </div>
</div>
