import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {EntrepriseVehiculesService} from "@components/admin/entreprise/vehicules/entreprise-vehicules.service";
import {ToastrService} from "ngx-toastr";
import {filter,finalize,first} from 'rxjs/operators';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {EnergieFiscale} from "@domain/admin/entreprise/vehicules/energieFiscale";

/**
 * Popin d'affichage du détail d'une énergie
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'energie-detail'},
    templateUrl: './energie-detail.component.html'
})
export class EnergieDetailComponent {
    /** Indicateur de chargement en cours */
    isLoading: boolean;

    /** Indique si on est en création ou modification */
    isCreation: boolean = this.data.energie.idEnergie === 0;

    /**
     * Constructeur
     *
     * @param data Données injectées dans le composant
     * @param matDialogRef Référence à cette popin
     * @param confirmService Service de confirmation
     * @param translateService Service des traductions
     * @param pleaseWaitService Service d'affichage du loading
     * @param vehiculeService Service des véhicules
     * @param toastrService Service des toasts
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { energie: EnergieFiscale },
                private matDialogRef: MatDialogRef<EnergieDetailComponent>,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private pleaseWaitService: PleaseWaitService,
                private vehiculeService: EntrepriseVehiculesService,
                private toastrService: ToastrService
    ) {
    }

    /**
     * Enregistrement de l'énergie
     */
    saveEnergie(): void {
        //Enregistrement en cours
        this.isLoading = true;

        //Enregistrement de l'énergie
        this.vehiculeService.saveEnergie(this.data.energie).subscribe((result: Result) => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Message d'information
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                //Fermeture de la popin avec rechargement
                this.matDialogRef.close(true);
            } else {
                //On débloque le bouton
                this.isLoading = false;

                //Message d'erreur
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });
    }

    /**
     * Suppression de l'énergie
     */
    deleteEnergie(): void {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
                //Ouverture de la modale de chargement
                let waitDialogRef = this.pleaseWaitService.show();

                //Suppression puis fermeture de la modale de chargement
                this.vehiculeService.deleteEnergie(this.data.energie.idEnergie)
                    .pipe(first(),finalize(() => waitDialogRef.close()))
                    .subscribe((result: Result) => {
                        if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Message d'information
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Fermeture de la popin avec rechargement
                            this.matDialogRef.close(true);
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    });
            });
    }

}
