import {Component,OnInit} from "@angular/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {LangueItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-item/langue-item.component";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {Result} from "@domain/common/http/result";

/**
 * Composant concernant l'internationalisation
 */
@Component({
    host: {'data-test-id': 'internationalisation'},
    templateUrl: './internationalisation.component.html'
})
export class InternationalisationComponent implements OnInit {
    /** Liste des différents onglets disponibles dans le menu "Internationalisation" */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem = {
        code: Onglets.LANGUES,
        libelle: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.langues'),
        selected: true
    };

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Liste des langues, pour utiliser le composant ListViewComponent */
    liste: ListView<Langue, LangueItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router Router de l'application
     */
    constructor(
        protected translateService: TranslateService,
        public router: Router,
    ) {
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de générer la liste des différents onglets disponibles (ici il n'y a qu'un seul onglet : "Langue")
        this.listeTabItems = [{
            code: Onglets.LANGUES,
            libelle: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.langues')
        },{
            code: Onglets.MESSAGES,
            libelle: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.messages')
        },{
            code: Onglets.REFERENTIELS,
            libelle: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.referentiels')
        }];

        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createLangue(),
        }]);

        //Création de la liste des langues
        this.liste = new ListView<Langue, LangueItemComponent>({
            uri: `/controller/Traduction/listeLangue`,
            title: this.translateService.instant('admin.bibliotheque.internationalisation.onglets.langues'),
            component: LangueItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            isSimple: true,
            mapResult: (result: Result) => {
                return result?.data?.listeLangue
            },
        });
    }

    /**
     * Méthode appelée lors de la sélection d'une langue
     *
     * @param langueSelected Langue sélectionnée
     */
    onSelect(langueSelected) {
        //Navigation vers les informations de la langue sélectionnée
        this.router.navigate(['Admin/Bibliotheque/Langue/'+langueSelected.code]);
    }

    /**
     * Méthode appelée lors de la création d'une nouvelle langue
     */
    createLangue() {
        //Navigation vers le formulaire de création d'une langue
        this.router.navigate(['Admin/Bibliotheque/Langue/0']);
    }
}

/**
 * Enum pour les noms des différents onglets de la page Langues et de la page d'informations d'une langue
 */
export enum Onglets {
    LANGUES = "Langues",
    MESSAGES = "Messages",
    REFERENTIELS = "Referentiels"
}
