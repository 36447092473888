<div *ngIf="parametres">
    <form #form="ngForm">
        <mat-card>
            <mat-card-title><span [translate]="'admin.entreprise.analytique.parametres.cards.organigramme.title'"></span></mat-card-title>
            <mat-card-content>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.organigramme.ruleSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="restitution"
                            name="restitution"
                            [(ngModel)]="parametres.restitution"
                            [customModel]="parametres.restitution"
                            customType="radio"
                            [radioOptions]="restitutionOptions"
                            libelle="admin.entreprise.analytique.parametres.cards.organigramme.restitutionControlLabel">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title><span [translate]="'admin.entreprise.analytique.parametres.cards.projets.title'"></span></mat-card-title>
            <mat-card-content>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.projets.ruleSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="projetDossierOrgaCascade"
                        name="projetDossierOrgaCascade"
                        [ngModel]="parametres.dossierOrgaCascade"
                        postLibelle="admin.entreprise.analytique.parametres.cards.projets.organisationControlSuffixe"
                        [postTooltip]="'admin.entreprise.analytique.parametres.cards.projets.organisationControlTooltip' | translate"
                        [(customModel)]="parametres.dossierOrgaCascade"
                        customType="checkbox"
                        libelle="admin.entreprise.analytique.parametres.cards.projets.organisationControlLabel">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title><span [translate]="'admin.entreprise.analytique.parametres.cards.axe5.title'"></span></mat-card-title>
            <mat-card-content>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.axe5.generalSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="libelleAxe5"
                        name="libelleAxe5"
                        [(ngModel)]="parametres.libelleAxe5"
                        customType="input"
                        rCol="2"
                        libelle="admin.entreprise.analytique.parametres.cards.axe6.organisationControlLabel"
                        [required]="true">
                    </custom-input>
                </div>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.axe5.ruleSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="axe5OrgaCascade"
                        name="axe5OrgaCascade"
                        [ngModel]="parametres.axe5OrgaCascade"
                        postLibelle="admin.entreprise.analytique.parametres.cards.axe5.organisationControlSuffixe"
                        [postTooltip]="'admin.entreprise.analytique.parametres.cards.axe5.organisationControlTooltip' | translate"
                        [(customModel)]="parametres.axe5OrgaCascade"
                        customType="checkbox"
                        libelle="admin.entreprise.analytique.parametres.cards.axe5.organisationControlLabel">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title><span [translate]="'admin.entreprise.analytique.parametres.cards.axe6.title'"></span></mat-card-title>
            <mat-card-content>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.axe6.generalSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="libelleAxe6"
                        name="libelleAxe6"
                        [(ngModel)]="parametres.libelleAxe6"
                        customType="input"
                        rCol="2"
                        libelle="admin.entreprise.analytique.parametres.cards.axe6.organisationControlLabel"
                        [required]="true">
                    </custom-input>
                </div>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.axe6.ruleSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="axe6OrgaCascade"
                        name="axe6OrgaCascade"
                        [ngModel]="parametres.axe6OrgaCascade"
                        postLibelle="admin.entreprise.analytique.parametres.cards.axe6.organisationControlSuffixe"
                        [postTooltip]="'admin.entreprise.analytique.parametres.cards.axe6.organisationControlTooltip' | translate"
                        [(customModel)]="parametres.axe6OrgaCascade"
                        customType="checkbox"
                        libelle="admin.entreprise.analytique.parametres.cards.axe6.organisationControlLabel">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title><span [translate]="'admin.entreprise.analytique.parametres.cards.enveloppes.title'"></span></mat-card-title>
            <mat-card-content>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.enveloppes.controleBudgetSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="controleOM"
                        name="controleOM"
                        [ngModel]="parametres.controleOM"
                        postLibelle="admin.entreprise.analytique.parametres.cards.enveloppes.missionOptionLabel"
                        [(customModel)]="parametres.controleOM"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.enveloppes.missionControlLabel">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="controleNDF"
                        name="controleNDF"
                        [ngModel]="parametres.controleNDF"
                        postLibelle="admin.entreprise.analytique.parametres.cards.enveloppes.noteOptionLabel"
                        [(customModel)]="parametres.controleNDF"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.enveloppes.noteControlLabel">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="controleFacture"
                        name="controleFacture"
                        [ngModel]="parametres.controleFacture"
                        postLibelle="admin.entreprise.analytique.parametres.cards.enveloppes.factureOptionLabel"
                        [(customModel)]="parametres.controleFacture"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.enveloppes.factureControlLabel">
                    </custom-input>
                </div>
                <div class="row">
                    <label class="col-md-2"><span [translate]="'admin.entreprise.analytique.parametres.cards.enveloppes.alerteControlLabel'"></span></label>
                    <div class="col-md-10">
                        <div class="row">
                            <custom-input ngDefaultControl
                                id="niveauNonBloquant"
                                name="niveauNonBloquant"
                                [(ngModel)]="parametres.niveauNonBloquant"
                                customType="number"
                                [min]="parametres.niveauNonBloquant != null ? 0 : null"
                                [max]="parametres.niveauNonBloquant != null ? 100 : null"
                                rCol="1"
                                libelle="admin.entreprise.analytique.parametres.cards.enveloppes.alertNonBloquantLabel"
                                [nbDecimales]="0"
                                suffixIcon="warning"
                                suffixIconClass="warning"
                                [required]="true"
                                [disabled]="!this.parametres.controleOM && !this.parametres.controleNDF && !this.parametres.controleFacture">
                            </custom-input>
                            <label class="col-md-2"><span [translate]="niveauNonBloquantSuffix"></span></label>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                id="niveauBloquant"
                                name="niveauBloquant"
                                [(ngModel)]="parametres.niveauBloquant"
                                customType="number"
                                [min]="parametres.niveauBloquant != null ? 0 : null"
                                [max]="parametres.niveauBloquant != null ? 100 : null"
                                rCol="1"
                                libelle="admin.entreprise.analytique.parametres.cards.enveloppes.alertBloquantLabel"
                                [nbDecimales]="0"
                                suffixIcon="warning"
                                suffixIconClass="error"
                                [required]="true"
                                [disabled]="!this.parametres.controleOM && !this.parametres.controleNDF && !this.parametres.controleFacture">
                            </custom-input>
                            <label class="col-md-2"><span [translate]="niveauBloquantSuffix"></span></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="interdictionEnveloppeVide"
                        name="interdictionEnveloppeVide"
                        [ngModel]="parametres.interdictionEnveloppeVide"
                        postLibelle="admin.entreprise.analytique.parametres.cards.enveloppes.controleControlOptionLabel"
                        [(customModel)]="parametres.interdictionEnveloppeVide"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.enveloppes.controleControlLabel">
                    </custom-input>
                </div>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.enveloppes.imputationSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input
                            ngDefaultControl
                            id="modeGestionTaxe"
                            name="modeGestionTaxe"
                            [(ngModel)]="parametres.modeGestionTaxe"
                            [customModel]="parametres.modeGestionTaxe"
                            customType="radio"
                            radioPrefix="admin.entreprise.analytique.parametres.cards.enveloppes.taxeControlAide"
                            [radioOptions]="gestionTaxeOptions"
                            libelle="admin.entreprise.analytique.parametres.cards.enveloppes.taxeControlLabel">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title><span [translate]="'admin.entreprise.analytique.parametres.cards.preferences.title'"></span></mat-card-title>
            <mat-card-content>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.preferences.adminParamSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="adminVisibility"
                        name="adminVisibility"
                        [ngModel]="parametres.adminVisibility"
                        postLibelle="admin.entreprise.analytique.parametres.cards.preferences.adminVisibiliteOptionLabel"
                        [(customModel)]="parametres.adminVisibility"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.preferences.adminVisibiliteControlLabel"
                        (customModelChange)="adminVisibilityChange($event)">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="adminManagement"
                        name="adminManagement"
                        [ngModel]="parametres.adminManagement"
                        postLibelle="admin.entreprise.analytique.parametres.cards.preferences.adminEditionOptionLabel"
                        [(customModel)]="parametres.adminManagement"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.preferences.adminEditionControlLabel"
                        [disabled]="!parametres.adminVisibility">
                    </custom-input>
                </div>
                <div class="header-row">
                    <span class="header" [translate]="'admin.entreprise.analytique.parametres.cards.preferences.userParamSectionTitle'"></span>
                    <span class="divider"></span>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="userVisibility"
                        name="userVisibility"
                        [ngModel]="parametres.userVisibility"
                        postLibelle="admin.entreprise.analytique.parametres.cards.preferences.userVisibiliteOptionLabel"
                        [(customModel)]="parametres.userVisibility"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.preferences.userVisibiliteControlLabel"
                        (customModelChange)="userVisibilityChange($event)">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                        id="userManagement"
                        name="userManagement"
                        [ngModel]="parametres.userManagement"
                        postLibelle="admin.entreprise.analytique.parametres.cards.preferences.userEditionOptionLabel"
                        [(customModel)]="parametres.userManagement"
                        customType="checkbox"
                        rCol="10"
                        libelle="admin.entreprise.analytique.parametres.cards.preferences.userEditionControlLabel"
                        [disabled]="!parametres.userVisibility">
                    </custom-input>
                </div>
            </mat-card-content>
        </mat-card>
    </form>

    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
