import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Item dans la liste des types de pi�ce jointe
 *
 * @author Lionel GIMENEZ
 * @date 02/10/2023
 */
export class TypeDocument implements ListItem {
    idTypeDocument: number
    libelle: string;
    actif: boolean;
    typeObjet: string;
    ocr: boolean;
    used:boolean;

    getKey(): number {
        return this.idTypeDocument;
    }

}
