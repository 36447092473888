<div class="with-floating-button">
    <div class="content" *ngIf="objetWorkflow && settings">
        <page-header #pageHeaderComponent [title]="objetWorkflow.idAvance != 0 ? ('avance.title' | translate:{ numero: objetWorkflow.idAvance }) : 'avance.creation.title' | translate"
                     [extraInfo]="objetWorkflow.statut?.libelle" [listeItems]="listeTabItems" [niveauAlerte]="listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onExtraInfoClick)="showChainage()" (onGoBack)="onGoBack()" [isPending]="isSaving">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
        </page-header>

        <div [hidden]="selectedItem.code != 'GENERALITES'">
            <avance-generalites [avance]="objetWorkflow" [canModifier]="canModifier" [settings]="settings"></avance-generalites>
        </div>

        <div [hidden]="selectedItem.code != 'HISTORIQUE'">
            <avance-historique-list [avance]="objetWorkflow" [settings]="settings"></avance-historique-list>
        </div>

        <div [hidden]="selectedItem.code != 'FICHECOLLABORATEUR'">
            <fiche-collaborateur *ngIf="idCollaborateur" [idCollaborateur]="idCollaborateur"></fiche-collaborateur>
        </div>

        <div *ngIf="objetWorkflow.idAvance != 0" [hidden]="selectedItem.code != 'COMPLEMENTS'">
            <document-list
                    [idObjet]="objetWorkflow.idAvance"
                    [idObjetForPJ]="objetWorkflow.idAvance"
                    [idCollaborateur]="idCollaborateur"
                    [context]="'AVANCE'"
                    [settings]="settings"
                    [canAddDocument]="canModifier && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite()?.idTypeEntite)"
                    [canDeleteDocument]="canModifier && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite()?.idTypeEntite)"
            ></document-list>
            <referentiel-externe *ngIf="settings?.isReferentielExterneAvailable" [typeReferentiel]="portee" [idObject]="objetWorkflow.idAvance"></referentiel-externe>
        </div>

        <div *ngIf="selectedItem?.code === OngletsAdmin.WORKFLOW">
            <onglet-workflow
                    [portee]="TypePortee.AV"
                    [idObjet]="objetWorkflow.idAvance"
                    [isObjetValide]="isValid()"
            ></onglet-workflow>
        </div>

        <div *ngIf="selectedItem?.isLoaded" [hidden]="selectedItem?.code != 'OUTILS'">
            <outils [idObjet]="objetWorkflow.idAvance" [typePortee]="TypePortee.AV"></outils>
        </div>
    </div>
    <please-wait class="content" *ngIf="objetWorkflow === undefined"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
