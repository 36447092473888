import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions,Effect,ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Action} from '../../domain/action';
import {Session} from '../../domain/security/session';
import {CHECK_CGU_VALIDATION} from '../../reducers/session';
import {LoginService} from '../../share/login/login.service';
import {CguComponent} from './cgu.component';
import {CguService} from './cgu.service';

@Injectable()
export class CguEffects {
    /**
     * Constructeur
     */
    constructor(private actions$: Actions,private matDialog: MatDialog,private loginService: LoginService,private cguService: CguService) {}

    /**
     * Vérification de l'approbation CGU de l'utilisateur
     */
    @Effect({ dispatch: false })
    checkCguValidation$: Observable<Action<Session>> = this.actions$.pipe(
        ofType(CHECK_CGU_VALIDATION),
        map((action: Action<Session>) => {
            //Récupération des informations de validation des CGU par l'utilisateur
            action.payload?.isLogged && this.cguService.getInfosForCgu().subscribe({
                next: result => {
                    //Vérification de l'absence de date d'approbation
                    if (!result.cguDisabledByParam && (!result.dateAcceptCgu || !result.isCguLastVersionValid)) {
                        //Récupération de la dernière version des CGU
                        this.cguService.getLastCguVersion().subscribe({
                            next: cgu => {
                                //Affichage de la popup des CGU
                                this.matDialog.open(CguComponent,{
                                    data: {
                                        cgu,
                                        isOther: !!action.payload.isConnectAs
                                    },
                                    hasBackdrop: true,
                                    disableClose: true,
                                    width: '98%'
                                }).afterClosed().subscribe({
                                    next: isValidated => {
                                        //Vérification de la validation des CGU
                                        if (isValidated) {
                                            //Validation des CGU
                                            this.cguService.validateOrRejectCgu(true).subscribe();
                                        } else {
                                            //Rejet des CGU
                                            this.cguService.validateOrRejectCgu(false).subscribe({
                                                complete: () => {
                                                    //Déconnexion de l'utilisateur
                                                    this.loginService.logout();
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            });
 
            //Retour de l'action
            return action;
        })
    );
}