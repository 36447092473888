import {Nature} from "@domain/prestation/nature";

/**
 * Enumération des natures d'une prestation (famille de charge à l'écran côté admin)
 */
export enum NatureVoyage {
    AVION   = 'A',
    TRAIN   = 'T',
    VOITURE = 'R',
    HOTEL   = 'H',
    DIVERS  = 'D',
    FRAIS   = 'F',
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace NatureVoyage {

    /**
     * Retourne la nature associée au champ natureVoyage d'une prestation
     *
     * @param natureVoyage
     */
    export function getNature(natureVoyage: NatureVoyage): Nature {
        switch (natureVoyage) {
            case this.AVION:   return Nature.AVION;
            case this.TRAIN:   return Nature.TRAIN;
            case this.VOITURE: return Nature.VOITURE;
            case this.HOTEL:   return Nature.HOTEL;
            case this.DIVERS:  return Nature.DIVERS;
            case this.FRAIS:   return Nature.FRAIS;
            default:           return Nature.AUCUNE;
        }
    }

}