import {Component} from '@angular/core';

import {AutocompleteLineItemComponent} from '../autocomplete-line-item.component';
import {GeographieView} from "@domain/geographie/geographieView";

@Component({
	templateUrl: './geographie.line-item.html'
})
export class GeographieLineItemComponent extends AutocompleteLineItemComponent<GeographieView> {

}