<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.codeDeviseContrepartie }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.onSelect(data)">{{ data.dAppli | date:"shortDate" }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.devises.infosDevise.taux.cours'"></strong>
                <span>{{ data.taux | number:'1.8' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.devises.infosDevise.taux.deviseContrepartie'"></strong>
                <span>{{ data.codeDeviseContrepartie }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.devises.infosDevise.taux.origine'"></strong>
                <span>{{ (data.codeOrigine === Origine.SAISIE ? 'admin.bibliotheque.devises.infosDevise.taux.saisie' :
                    data.codeOrigine === Origine.IMPORT_TAUX ? 'admin.bibliotheque.devises.infosDevise.taux.importTauxChange' :
						'admin.bibliotheque.devises.infosDevise.taux.importOpCarte') | translate }}</span>
            </li>
        </ul>
    </div>
</div>
