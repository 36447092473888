import {Module} from "@domain/admin/licence/module";

/**
 * DTO de la licence de l'entreprise
 */
export class Licence {
    raisonSociale: string;
    listeModule: Module[];
    listePlugin: Plugin[];
    signature: string;
    codeCRM: string;
    isValid: boolean;
    version: number;
}

/**
 * Enumération des plugins
 */
export enum TypePlugins {
    GED = 'GED',
    VIAMICHELIN = 'VIA_MICHELIN',
    CERTIFICATION = 'CERTIFICATION',
    ARCHIVAGE = 'ARCHIVAGE',
    MARQUEBLANCHE = 'MARQUE_BLANCHE'
}
