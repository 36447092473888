<h1 mat-dialog-title>
    <span [translate]="'admin.comptabilite.modeRemb.title.creation'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>
    <form autocomplete="off" #form="ngForm" name="form-mode-remboursement">
        <fieldset *ngIf="!isLoading">
            <div class="row">
                <!-- Type -->
                <custom-input ngDefaultControl name="type" libelle="admin.comptabilite.modeRemb.type" required="true"
                              customType="select" optionValue="valeur" optionDisplay="libelle"
                              [(ngModel)]="data.modeRemb.typeRemb" [selectOptions]="listeTypeRemb"></custom-input>
                <!-- Actif / inactif -->
                <custom-input ngDefaultControl name="actif" libelle="admin.comptabilite.modeRemb.actif"
                              customType="checkbox" [ngModel]="data.modeRemb.actif" [(customModel)]="data.modeRemb.actif"></custom-input>
            </div>
            <div class="row">
                <!-- Libellé -->
                <custom-input ngDefaultControl name="libelle" libelle="admin.comptabilite.modeRemb.libelle" required="true"
                              customType="input" [(ngModel)]="data.modeRemb.libelle" maxlength="2000"></custom-input>
                <!-- Par défaut -->
                <custom-input ngDefaultControl name="defaut" libelle="admin.comptabilite.modeRemb.defaut"
                              customType="checkbox" [ngModel]="data.modeRemb.defaut" [(customModel)]="data.modeRemb.defaut" [disabled]="data.modeRemb.defaut"></custom-input>
            </div>
            <div class="row">
                <!-- Compte -->
                <custom-input ngDefaultControl name="compte" libelle="admin.comptabilite.modeRemb.compte" required="true"
                              customType="objectselect" optionValue="numero" optionDisplay="libelleForDisplay"
                              [(ngModel)]="data.modeRemb.compte" [selectOptions]="listeCompte"></custom-input>
                <!-- Code -->
                <custom-input ngDefaultControl name="code" libelle="admin.comptabilite.modeRemb.code"
                              customType="input" [(ngModel)]="data.modeRemb.code" maxlength="10"></custom-input>
            </div>
            <div class="row" *ngIf="data.modeRemb.typeRemb === TypeRemb.VIREMENT">
                <!-- IBAN -->
                <custom-input ngDefaultControl name="iban" libelle="admin.comptabilite.modeRemb.iban" required="true"
                              customType="iban-obfuscated" [(ngModel)]="iban"></custom-input>
                <!-- BIC -->
                <custom-input ngDefaultControl name="bic" libelle="admin.comptabilite.modeRemb.bic" required="true"
                              customType="input" [(ngModel)]="data.modeRemb.bic" maxlength="20"></custom-input>
            </div>
            <div class="row" *ngIf="data.modeRemb.typeRemb === TypeRemb.VIREMENT">
                <!-- Émetteur -->
                <custom-input ngDefaultControl name="emetteur" libelle="admin.comptabilite.modeRemb.emetteur"
                              customType="input" [(ngModel)]="data.modeRemb.numEmetteur" maxlength="6"></custom-input>
                <!-- Adresse -->
                <label class="col-md-2"><span [translate]="'admin.comptabilite.modeRemb.adresse'"></span></label>
                <div class="col-md-4">
                    <lieu ngDefaultControl
                          name="adresse"
                          [(ngModel)]="adresse"
                          [(lieu)]="adresse"
                          [idPortee]="TypePortee.ADM"
                          (lieuChange)="setAdresse($event)"
                          [onClear]="clearAdresse">
                    </lieu>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <button mat-stroked-button color="warn" (click)="deleteModeRemb()" [disabled]="isProcessing" *ngIf="data.modeRemb.idModeRemb">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="form.invalid || isProcessing" (click)="saveModeRemb()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
