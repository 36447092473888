/**
 * Interface d'un type d'adresse
 */
export interface TypeAdresse {
    type: TypeAdresseEnum;
    value: number;
    tabIndex: number;
}

/**
 * Enumération des types d'adresse possible
 */
export enum TypeAdresseEnum {
    PERSONNELLE,
    PROFESSIONNELLE,
    ETABLISSEMENT,
    AUTRE
}

/**
 * Classe permettant d'accéder facilement aux types d'adresses possibles
 */
export class TypeAdresseRef {
    /** Déclaration static de chaque type d'adresse */
    public static PERSONNELLE: TypeAdresse = { type: TypeAdresseEnum.PERSONNELLE, value: 1, tabIndex: 0 };
    public static PROFESSIONNELLE: TypeAdresse = { type: TypeAdresseEnum.PROFESSIONNELLE, value: 2, tabIndex: 0 };
    public static ETABLISSEMENT: TypeAdresse = { type: TypeAdresseEnum.ETABLISSEMENT, value: 3, tabIndex: 1 };
    public static AUTRE: TypeAdresse = { type: TypeAdresseEnum.AUTRE, value: 4, tabIndex: 2 };

    /** tableau de tous les types d'adresses */
    public static values = new Array<TypeAdresse>(
        TypeAdresseRef.PERSONNELLE,
        TypeAdresseRef.PROFESSIONNELLE,
        TypeAdresseRef.ETABLISSEMENT,
        TypeAdresseRef.AUTRE
    );

    /**
     * Retourne l'objet TypeAdresse correspondant à un type donné.
     * undefined est retourné si aucune correspondance.
     *
     * @param search Type (chaine) recherché
     */
    public static find(search: string): TypeAdresse {
        return TypeAdresseRef.values.find((val,index) => TypeAdresseEnum[val.type].toUpperCase() === search?.toUpperCase() );
    }

    /**
     * Retourne l'objet TypeAdresse correspondant à un type donné.
     * undefined est retourné si non aucune correspondance.
     *
     * @param search Type (chaine) recherché
     */
    public find(search: string): TypeAdresse {
        return TypeAdresseRef.find(search);
    }

    /**
     * Retourne l'objet TypeAdresse correspondant à un énuméré donné
     *
     * @param type Type recherché
     */
    public static get(type: TypeAdresseEnum): TypeAdresse {
        return TypeAdresseRef.values.find(adr => adr.type === type);
    }

}