import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ParticipantLien} from "../../domain/participant/participant-lien";
import {SettingsNFState, SettingsODState} from "../../domain/settings/settings";

/**
 * Représente un élement de la liste des participants
 *
 * @author Laurent Convert
 * @date 07/03/2022
 */
@Component({
    host: {'data-test-id': 'participant-list-item'},
    templateUrl: './participant-list-item.component.html'
})
export class ParticipantListItemComponent implements OnInit {
    /** Élément courant */
    @Input() data: ParticipantLien;

    /** Options supplémentaires */
    @Input() extraOptions: {
        eventRetirerFavoris: EventEmitter<ParticipantLien>,
        eventAjouterFavoris: EventEmitter<ParticipantLien>,
        eventRetirerParticipant: EventEmitter<ParticipantLien>,
        showParticipant: (participant: ParticipantLien) => void,
        idUserObjet: number,
        canModifier: boolean,
        isFromPopup: boolean,
        settings: SettingsODState | SettingsNFState,
    };

    isCollabParticipePasAutorise: boolean;

    ngOnInit() {
        this.isCollabParticipePasAutorise = (this.extraOptions.settings as SettingsODState)?.isCollabParticipePasAutorise;
    }

    /** On émet l'évènement de retrait des favoris */
    retirerFavoris() {
        (<EventEmitter<ParticipantLien>>this.extraOptions.eventRetirerFavoris).emit(this.data);
    }

    /** On émet l'évènement d'ajout aux favoris */
    ajouterFavoris() {
        (<EventEmitter<ParticipantLien>>this.extraOptions.eventAjouterFavoris).emit(this.data);
    }

    /** On émet l'évènement de retrait du participant */
    retirerParticipant() {
        (<EventEmitter<ParticipantLien>>this.extraOptions.eventRetirerParticipant).emit(this.data);
    }
}
