import {TranslateService} from '@ngx-translate/core';

import {Filter} from '@domain/common/list-view';
import {AutocompleteOptions} from '../autocomplete';
import {GeographieSearchItemComponent} from './geographie.search-item';
import {GeographieLineItemComponent} from './geographie.line-item';
import {GeographieView} from "@domain/geographie/geographieView";
import {TypeGeographie} from "@domain/geographie/typeGeographie";

/**
 * Options de l'entité géographique
 */
export default class GeographieOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     */
    url(filter: any) {
        //URL
        return `/controller/Geographie/searchGeographie?listeTypes=${filter.listeTypes || ''}&contexte=${filter.contexte || ''}&actifsOnly=${filter.actifsOnly ?? true}&isZoneNull=${filter.isZoneNull ?? false}`;
    }

    /**
     * Composant d'affichage d'une ligne dans la popup de recherche
     */
    searchItemComponent() { return GeographieSearchItemComponent; }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() { return GeographieLineItemComponent; }

    /**
     * Affichage d'un élément
     */
    displayItem(item: GeographieView) {
        return (item?.libelle || '')
            + (item?.type != TypeGeographie.DEPARTEMENT_ADMINISTRATIF && item?.departementAdmin?.libelle ? ', ' + item.departementAdmin.libelle : '')
            + (item?.type != TypeGeographie.PAYS && item?.pays?.libelle ? ', ' + item.pays.libelle : '');
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.libelle'),
            clef: 'libelleSain',
            isDefault: true
        },{
            title: translateService.instant('filter.pays'),
            clef: '*pays.libelle',
            isDefault: true
        },{
            title: translateService.instant('filter.codeISO2'),
            clef: '*pays.code2',
            isDefault: true
        }];
    }

    /** Définition de la taille de l'autocomplete */
    width = 500;

    /** Pas de recherche tant qu'un filtre n'a pas été renseigné */
    isSearchWhenNoFilter = false;
}

/**
 * Enumération de la portée géographie
 */
export enum PorteeGeographie {
    INDEMNITE = "INDEMNITE",
    OM_PERMANENT = "OM_PERMANENT",
    PRECO_VOYAGE = "PRECO_VOYAGE",
    TAXE = "TAXE",
    UTILISATEUR = "UTILISATEUR",
    NDF = "NDF",
    DISTANCIER_INTERNE ="DISTANCIER_INTERNE",
    ADMIN_TEST = "ADMIN_TEST",
    OD = "OD"
}
