import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Compteur} from "../../domain/vehicule/compteur";
import * as moment from 'moment';

@Component({
  host: {'data-test-id': 'vehicule-historique-distance'},
  templateUrl: './vehicule-historique-distance.component.html'
})
/**
 * Composant d'affichage de l'historique de distance d'un véhicule
 */
export class VehiculeHistoriqueDistanceComponent {

  constructor(
      private translateService: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: {
        listeCompteur: Array<Compteur>
      },
      private matDialogRef: MatDialogRef<VehiculeHistoriqueDistanceComponent>
  ) { }

  /**
   * Ferme la popup
   */
  closePopup(): void{
    this.matDialogRef.close();
  }

  /**
   * Renvoie la date de fin des compteurs kilométrique
   * @param date Date de début du compteur kilométrique
   */
  getCompteur(date: Date): Date{
    return moment(date).set('month', 11).set('date', 31).toDate();
  }
}
