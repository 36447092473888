import {Component,ComponentFactory,ComponentFactoryResolver,ComponentRef,HostBinding,Input,OnInit,TemplateRef,Type,ViewChild,ViewContainerRef} from '@angular/core';
import {animate,style,transition,trigger} from '@angular/animations';

/**
 * Composant d'affichage d'un tooltip
 *
 * @author Laurent Convert
 * @date 08/11/2021
 */
@Component({
    templateUrl: './tooltip.component.html',
    animations: [
        trigger('tooltip', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 })),
            ]),
        ]),
    ]
})
export class TooltipComponent implements OnInit {

    /** Récepteur de composant */
    @ViewChild('holder',{ read: ViewContainerRef,static: true }) holder: ViewContainerRef;

    /** Contenu de type Texte */
    @Input() text: string;

    /** Contenu de type Template */
    @Input() template: TemplateRef<any>;

    /** Contenu de type Component */
    @Input() component: Type<any>;

    /** Le context à passer au template ou au composant pour résoudre les variables */
    @Input() context: any;

    /** Classe CSS du tooltip (texte uniquement) */
    @Input() _tooltipClass: string;

    /** Accesseurs de la classe CSS */
    get tooltipClass(): string { return this._tooltipClass ?? (this.text ? 'nio-tooltip-text' : 'nio-tooltip-details'); }
    set tooltipClass(tooltipClass: string) { this._tooltipClass = tooltipClass; }

    /** Accesseur à la largeur du tooltip définie dans de l'attribut style */
    @HostBinding('style.width')
    public width: string = '';

    /**
     * Constructeur
     */
    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Vérification de la source du tooltip
        if (this.template) {
            //Création du tooltip à partir du template
            this.holder.createEmbeddedView(this.template,this.context || {});
        } else if (this.component) { //Création du tooltip à partir du type de composant
            let componentRef: ComponentRef<any>;
            let factory: ComponentFactory<any>;

            //Récupération de la fabrique
            factory = this.componentFactoryResolver.resolveComponentFactory(this.component);

            //Création du composant
            componentRef = this.holder.createComponent(factory);

            //Si un context est passé, on passe toutes les variables à l'instance du composant
            if (this.context) {
                Object.assign(componentRef.instance,this.context);
            }

            //Ajout du composant
            this.holder.insert(componentRef.hostView);
        } else if (this.text != null) {
            //Texte simple ou HTML
            (this.holder.element.nativeElement as HTMLElement).parentElement.innerHTML = this.text;
        }
    }

}
