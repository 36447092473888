import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';


import { CoreComponentsModule } from '../core-components.module';
import { FloatingButtonComponent } from './floating-button.component';
import { FloatingButtonService } from "@share/component/floating-button/floating-button.service";
import { FloatingButtonDirective } from "@share/component/floating-button/floating-button.directive";

@NgModule({
    imports: [CoreComponentsModule,TranslateModule],
    declarations: [FloatingButtonComponent,FloatingButtonDirective],
    exports: [FloatingButtonComponent,FloatingButtonDirective],
    providers: [FloatingButtonService]
})
export class FloatingButtonModule {}