import {NgModule} from '@angular/core';

import {ShareModule} from "../../share/share.module";
import {ReportingListComponent} from "./reporting-list.component";
import {ReportingService} from "./reporting.service";
import {ReportingComponent} from './reporting.component';
import {ReportPipe} from './report.pipe';

@NgModule({
	declarations: [ReportingListComponent, ReportingComponent, ReportPipe],
	imports: [ShareModule],
	exports: [
		ReportPipe
	],
	providers: [ReportingService]
})
export class ReportingModule {}
