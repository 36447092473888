import {NgModule} from '@angular/core';

import {ShareModule} from '@share/share.module';
import {ChainageComponent} from './chainage.component';
import {ChainageService} from './chainage.service';
import {ChainageActionWfComponent} from './chainage-action-wf/chainage-action-wf.component';

@NgModule({
    imports: [ShareModule],
    declarations: [ChainageComponent, ChainageActionWfComponent],
    exports: [ChainageComponent],
    providers: [ChainageService]
})
export class ChainageModule {}
