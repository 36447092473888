import {ComponentRef,Directive,ElementRef,HostListener,Input,OnDestroy,OnInit} from "@angular/core";
import {ConnectedPosition} from "@angular/cdk/overlay/position/flexible-connected-position-strategy";
import {Overlay,OverlayPositionBuilder,OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {MenuItem} from "@domain/common/menu/menu-item";
import {MenuDropdownComponent} from "./menu-dropdown.component";
import {Subject,Subscription} from "rxjs";
import {throttleTime} from "rxjs/operators";

/**
 * Affichage d'un dropdown au survol d'une entrée de menu
 *
 * @author Laurent Convert
 * @date 27/04/2022
 */
@Directive({
	selector: '[menu-dropdown]'
})
export class MenuDropdownDirective implements OnInit,OnDestroy {
	//Délai avant fermeture du dropdown (en ms)
	private readonly DELAY_CLOSE_DROPDOWN: number = 0;

	//Délai entre 2 déclenchements de l'ouverture du dropdown (permet d'éviter le spam en cas de déplacement furieux du pointer)
	private readonly DELAY_THROTTLE_OPEN_DROPDOWN: number = 500;

	/** Listes des entrées du dropdown */
	@Input('menu-dropdown')
	listeItems: Array<MenuItem>;

	/** Listes des entrées du dropdown */
	@Input('dropdown-title')
	title: string;

	/** Classe CSS du dropdown */
	@Input('dropdown-css')
	css: string;

	/**
	 * Position du dropdown.<br>
	 * Si la place n'est pas suffisante pour afficher le tooltip, il s'affichera à l'opposé.<br>
	 * Défaut : à droite
	 */
	@Input('dropdown-position')
	position: Position = 'right';

	/** Désactive l'affichage du dropdown */
	@Input('disabled')
	isDisabled: boolean = false;

	/** Référence de l'overlay */
	private overlayRef: OverlayRef;

	/** Abonnement sur l'observable déclenché lors du (re)positionnement du dropdrown */
	private positionStrategySubscription: Subscription;

	/** Référence du composant du dropdown */
	private dropdownRef: ComponentRef<MenuDropdownComponent>;

	/** Subject permettant d'ouvrir le dropdown via un observable */
	private onOpenDropdown: Subject<MouseEvent> = new Subject<MouseEvent>();

	/** Référence du timeout sauvegardée lors d'une demande de fermeture du dropdown */
	private refTimeoutCloseDropdown: number = null;

	private readonly dropdownRight: string = 'dropdown-right';
	private readonly dropdownLeft: string = 'dropdown-left';
	private readonly dropdownTop: string = 'dropdown-top';
	private readonly dropdownBottom: string = 'dropdown-bottom';
	private readonly dropdownPositions: string[] = [this.dropdownRight,this.dropdownLeft,this.dropdownTop,this.dropdownBottom];

	private readonly roundedTopRight: string = 'rounded-top-right';
	private readonly roundedBottomRight: string = 'rounded-bottom-right';
	private readonly roundedTopLeft: string = 'rounded-top-left';
	private readonly roundedBottomLeft: string = 'rounded-bottom-left';
	private readonly roundedPositions: string[] = [this.roundedTopRight,this.roundedBottomRight,this.roundedTopLeft,this.roundedBottomLeft];

	/**
	 * Constructeur
	 */
	constructor(private overlay: Overlay,
				private overlayPositionBuilder: OverlayPositionBuilder,
				private elementRef: ElementRef) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Création d'un observable pour l'ouverture du dropdown
		this.onOpenDropdown
			.pipe(throttleTime(this.DELAY_THROTTLE_OPEN_DROPDOWN)) //throttle pour limiter le spam sur l'ouverture du dropdown
			.subscribe(mouseEvent => {
				const eventTarget = mouseEvent.relatedTarget;

				//Vérification que l'overlay n'est pas déjà attaché au DOM (et donc que le dropdown n'est pas déjà ouvert)
				if (!this.overlayRef?.hasAttached()) {
					//Vérification que le dropdown n'est pas désactivé et qu'on ne vient pas du dropdown (ce qui signifie qu'il existe déjà)
					if (!this.isDisabled && !(eventTarget instanceof HTMLElement && eventTarget.tagName.toLowerCase() == 'menu-dropdown')) {
						//Création de l'overlay
						this.createOverlay();

						//Création du dropdown
						this.createDropdown();
					}
				}
			});
	}

	/**
	 * A la destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement des observables
		this.onOpenDropdown.unsubscribe();
		this.positionStrategySubscription?.unsubscribe();
	}

	/**
	 * Construction et affichage du dropdown lorsque le pointer entre dans l'élément
	 *
	 * @param event Évènement à l'entrée de la souris sur l'élément (le host de la directive)
	 */
	@HostListener('mouseenter',['$event'])
	show(event: MouseEvent) {
		if (this.listeItems && this.listeItems.length > 0) {
			//Annulation de la fermeture si déjà déclenchée
			this.cancelCloseDropdownDelayed();

			//Affichage du dropdown
			this.onOpenDropdown.next(event);
		}
	}

	/**
	 * Ferme le dropdown lorsque le pointer quitte l'élément
	 *
	 * @param event Évènement à la sortie de la souris de l'élément (le host de la directive)
	 */
	@HostListener('mouseleave',['$event'])
	hide(event: MouseEvent) {
		//Fermeture du dropdown
		this.closeDropdownDelayed(event);
	}

	/**
	 * Ouvre / Ferme le dropdown au clic sur l'élément (au cas où...)
	 *
	 * @param event Événement de la souris à l'origine de la fermeture. Null autorisé, dans ce cas le dropdown sera fermé immédiatement.
	 */
	@HostListener('click',['$event'])
	hideFallback(event: MouseEvent) {
		//Vérification du dropdown ouvert
		if (this.overlayRef?.hasAttached()) {
			//Fermeture
			this.closeDropdownNow();
		} else {
			//Ouverture
			this.show(event);
		}
	}

	/**
	 * Fermeture du dropdown après xxx ms de délais.
	 * Peut être interrompu par un appel à la fonction "cancelCloseDropdownDelayed"
	 *
	 * @param event Événement de la souris à l'origine de la fermeture. Null autorisé, dans ce cas le dropdown sera fermé immédiatement.
	 */
	private closeDropdownDelayed(event: MouseEvent) {
		//Forçage de la fermeture immédiate si aucun évènement
		const forceClose = event === null;

		//Vérification de la cible de l'évènement : on ferme le dropdown si on ne va pas sur l'élément host ou sur le dropdown lui-même.
		if (forceClose || ((this.getElementRef(event?.relatedTarget as HTMLElement) != this.elementRef.nativeElement)
			&& (!(event?.relatedTarget instanceof HTMLElement && event.relatedTarget.closest('menu-dropdown'))))
		) {
			//Annulation de la fermeture si déjà déclenchée
			this.cancelCloseDropdownDelayed();

			//Vérification de la fermeture immédiate
			if (forceClose) {
				this.close();
			} else {
				//Sauvegarde de la référence du setTimeout pour pouvoir l'annuler au besoin
				this.refTimeoutCloseDropdown = setTimeout((() => {
					//Fermeture du dropdown
					this.close();
				}) as TimerHandler,this.DELAY_CLOSE_DROPDOWN);
			}
		}
	}

	/**
	 * Fermeture immédiate du dropdown (alias de closeDropdownDelayed(null)).
	 */
	private closeDropdownNow() {
		//Fermeture sans événement : fermeture immédiate
		this.closeDropdownDelayed(null);
	}

	/**
	 * Annulation de la fermeture du dropdown en cours le cas échéant
	 */
	private cancelCloseDropdownDelayed() {
		//Vérification que la fermeture avait été déclenchée
		if (this.refTimeoutCloseDropdown) {
			//C'est bien le cas : on l'annule
			clearTimeout(this.refTimeoutCloseDropdown);
		}
	}

	/**
	 * Fermeture du dropdown
	 */
	private close(): void {
		//Vérification que l'overlay est bien attaché au DOM (et donc que le dropdown est bien ouvert)
		if (this.overlayRef?.hasAttached()) {
			//Suppression de la classe CSS indiquant que le menu est ouvert
			(this.elementRef.nativeElement as HTMLElement).closest('li').classList.remove('selected','dropdown-opened',...this.dropdownPositions);

			//Détache l'overlay
			this.overlayRef.detach();
		}
	}

	/**
	 * Création de l'overlay
	 */
	private createOverlay(): void {
		//Récupération du 'li' parent (pour une entrée de menu) ou à défaut de l'élément host lui-même
		let elementRefForClass: HTMLElement = (this.elementRef.nativeElement as HTMLElement).closest('li') ?? (this.elementRef.nativeElement as HTMLElement);

		//Définition de la stratégie de positionnement
		const positionStrategy = this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withPositions(this.getPositions())
			.withPush(true)
			.withLockedPosition(true);

		//Souscription sur l'observable déclenché lors du positionnement du dropdown
		this.positionStrategySubscription = positionStrategy.positionChanges.subscribe((positionChange) => {
			let dropdownRef: HTMLElement = (this.dropdownRef?.location.nativeElement as HTMLElement);

			let classElementRef: string = '';
			let classDropDown: string = '';

			//On détermine la classe CSS appropriée suivant la position du dropdown (et pas seulement suivant la position définie à la création car elle peut varier suivant la place disponible à l'écran)
			if (positionChange?.connectionPair) {
				if (positionChange.connectionPair.overlayX == 'start' && positionChange.connectionPair.originX == 'end') {
					//Menu à droite de l'élément
					classElementRef = this.dropdownRight;

					if (positionChange.connectionPair.overlayY == 'top' && positionChange.connectionPair.originY == 'top') {
						classDropDown = this.roundedTopRight;
					} else {
						classDropDown = this.roundedBottomRight;
					}
				} else if (positionChange.connectionPair.overlayX == 'end' && positionChange.connectionPair.originX == 'start') {
					//Menu à gauche de l'élément
					classElementRef = this.dropdownLeft;

					if (positionChange.connectionPair.overlayY == 'top' && positionChange.connectionPair.originY == 'top') {
						classDropDown = this.roundedTopLeft;
					} else {
						classDropDown = this.roundedBottomLeft;
					}
				} else if (positionChange.connectionPair.overlayY == 'bottom' && positionChange.connectionPair.originY == 'top') {
					//Menu au dessus de l'élément
					classElementRef = this.dropdownTop;

					if (positionChange.connectionPair.overlayX == 'start' && positionChange.connectionPair.originX == 'start') {
						classDropDown = this.roundedBottomLeft;
					} else {
						classDropDown = this.roundedBottomRight;
					}
				} else if (positionChange.connectionPair.overlayY == 'top' && positionChange.connectionPair.originY == 'bottom') {
					//Menu en dessous de l'élément
					classElementRef = this.dropdownBottom;

					if (positionChange.connectionPair.overlayX == 'start' && positionChange.connectionPair.originX == 'start') {
						classDropDown = this.roundedTopLeft;
					} else {
						classDropDown = this.roundedTopRight;
					}
				}
			}

			//Ajout du CSS indiquant l'ouverture et la position du dropdown
			elementRefForClass.classList.remove(...this.dropdownPositions);
			elementRefForClass.classList.add('selected','dropdown-opened',classElementRef);

			//Ajout du CSS au dropdown suivant sa position par rapport à l'élément de référence
			dropdownRef.classList.remove(...this.roundedPositions);
			dropdownRef.classList.add(classDropDown);
		});

		//Création de l'overlay
		this.overlayRef = this.overlay.create({positionStrategy: positionStrategy,scrollStrategy: this.overlay.scrollStrategies.reposition()});
	}

	/**
	 * Création du dropdown
	 */
	private createDropdown(): void {
		let domElement: HTMLElement;

		//Création du composant d'affichage du dropdown et attachement à l'overlay
		this.dropdownRef = this.overlayRef.attach(new ComponentPortal(MenuDropdownComponent));

		//Récupération de l'élément DOM associé au dropdown
		domElement = this.dropdownRef.location.nativeElement as HTMLElement;

		//Attachement d'un évènement sur le dropdown créé pour le fermer lorsqu'on le quitte
		domElement.addEventListener('mouseleave',(event) => {
			if ((this.getElementRef(event?.relatedTarget as HTMLElement) != this.elementRef.nativeElement)) {
				//On vient de sortir du dropdown : on demande une fermeture
				this.closeDropdownNow();
			}
		});

		//Attachement d'un évènement sur le dropdown créé pour indiquer qu'il faut garder le menu ouvert
		domElement.addEventListener('mouseenter',(event) => {
			//On vient d'entrer dans le dropdown : on annule toute demande de fermeture éventuelle
			this.cancelCloseDropdownDelayed();
		});

		//Passage du context
		this.dropdownRef.instance.listeItems = this.listeItems;
		this.dropdownRef.instance.title = this.title;
		this.dropdownRef.instance.css = this.css;

		//Après exécution d'une des actions du dropdown on ferme le menu
		this.dropdownRef.instance.onDoAction.subscribe(() => {
			//L'action a été réalisée : Mission accomplie, formation du lézard. Et on ferme le menu.
			this.closeDropdownNow();
		});
	}

	/**
	 * Remonte l'arbre DOM pour retrouver l'élément host à partir d'un nœud
	 *
	 * @param elt Le nœud DOM à partir duquel remonter
	 */
	private getElementRef(elt: HTMLElement): HTMLElement {
		if (elt && elt == this.elementRef.nativeElement) {
			//L'élément recherché est déjà le nœud de départ
			return elt;
		} else if (elt && elt.parentElement) {
			//Appel récursif avec le parent pour remonter
			return this.getElementRef(elt.parentElement);
		} else {
			//L'élément n'a pas été trouvé
			return null;
		}
	}

	/**
	 * Définition de l'ordre des positions avec basculement du côté opposé si la place ne permet pas l'affichage sur la position demandée
	 */
	private getPositions(): ConnectedPosition[] {
		let positions: ConnectedPosition[];

		//Vérification de la position du menu par rapport à l'élément
		switch (this.position) {
			case "top":
			case "bottom":
				//Haut, puis bas
				positions = [{
					originX: 'start',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'bottom'
				},{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top'
				}];
				break;
			case "right":
			case "left":
				//droite (haut/bas), puis gauche (haut/bas)
				positions = [{
					originX: 'end',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'top'
				},{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'bottom'
				},{
					originX: 'start',
					originY: 'top',
					overlayX: 'end',
					overlayY: 'top'
				},{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'bottom'
				}];
				break;
		}

		//Les positions ont été initialisées pour un affichage en haut ou à droite : inversion des positions si c'est l'inverse.
		if (["bottom","left"].includes(this.position)) {
			positions = positions.reverse();
		}

		return positions;
	}

}

/** Type représentant les différentes positions possibles du dropdown */
export type Position = 'top' | 'right' | 'bottom' | 'left';