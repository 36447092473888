import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ListView} from '@domain/common/list-view';
import {AutocompleteSearchData} from './autocomplete';
import {AutocompleteSearchItemComponent} from './autocomplete-search-item.component';

@Component({
    selector: 'autocomplete-search-component',
    templateUrl: './autocomplete-search.component.html'
})
export class AutocompleteSearchComponent {
    /** Données **/
    liste: ListView<any,AutocompleteSearchItemComponent<any>>;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private dialogRef: MatDialogRef<AutocompleteSearchComponent>,
                @Inject(MAT_DIALOG_DATA) public data: AutocompleteSearchData) {

        //Définition de la liste
        this.liste = new ListView<any,AutocompleteSearchItemComponent<any>>({
            uri: typeof this.data.options.url == 'function' ? this.data.options.url(this.data.filter) : this.data.options.url,
            component: this.data.options.searchItemComponent && this.data.options.searchItemComponent() || AutocompleteSearchItemComponent,
            extraOptions: {
                displayItem: this.data.options.displayItem,
                getIcon: this.data.options.getIcon,
                close: (item) => dialogRef.close(item),
                listDisplayedAttributes: this.data.options.listDisplayedAttributes,
                searchType: this.data?.options?.searchType
            },
            isLocal: true,
            isFilter: true,
            isSearchWhenNoFilter: this.data.options.isSearchWhenNoFilter,
            listeFilters: this.data.options.listeFilters(this.translateService,this.data.filter),
            listeStaticFilters: this.data.options.listeStaticFilters?.(this.data.filter),
            defaultOrder: this.data.options.getOrderBy ? this.data.options.getOrderBy() : this.data.options.listeFilters(this.translateService,this.data.filter).filter(f => f.isDefault).map(f => f.clef).join(','),
            mapRequest: this.data.options.mapRequest,
            mapResult: this.data.options.mapResult
        });       
    }
}
