<!-- Ligne de facture de type Facture Prévisionnelle -->
<div *ngIf="data.facturePrev" class="lvi-content">
    <div class="avatar" [nioTooltip]="'od.frais.factures.liste.types.facture_prev' | translate">
        <i class="nio icon-facture_previsionnelle align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="showFacturePrev(); $event.stopPropagation();">{{ data.facturePrev.prestation?.libelle || ('od.frais.factures.liste.objetNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.frais.factures.type'"></strong>{{ TypeFactPrev.traduction(data.facturePrev.typeFacture) | translate }}</li>
            <li *ngIf="data.facturePrev.fournisseur"><strong [translate]="'od.frais.factures.fournisseur'"></strong>{{ data.facturePrev.fournisseur?.raisonSociale }}</li>
            <li><strong [translate]="'od.frais.factures.quantite'"></strong>{{ data.facturePrev.quantite }}</li>
            <li><strong [translate]="'od.frais.factures.montant'"></strong>{{ data.facturePrev.montant | montant:data.facturePrev.devise }}</li>
            <li><strong [translate]="'od.frais.factures.contrepartie'"></strong>{{ data.facturePrev.contrepartie | montant:data.facturePrev.deviseContrepartie }}</li>
        </ul>
    </div>
</div>
<!-- Ligne de facture de type Facture -->
<div *ngIf="data.facture" class="lvi-content">
    <div class="avatar" [ngClass]="NiveauAlerte.getClass(data.listeAlertes?.niveau)"
                        [nioTooltip]="'od.frais.factures.liste.types.facture' | translate">
        <i class="nio icon-facture align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="showFacture(); $event.stopPropagation();">{{ data.facture.numero || ('od.frais.factures.liste.objetNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.frais.factures.type'"></strong>{{ TypeFact.traduction(data.facture.type) | translate }}</li>
            <li *ngIf="data.facture.libelle"><strong [translate]="'od.frais.factures.libelle'"></strong>{{ data.facture.libelle }}</li>
            <li *ngIf="data.facture.date"><strong [translate]="'od.frais.factures.date'"></strong>{{ data.facture.date | date:'shortDate' }}</li>
            <li><strong [translate]="'od.frais.factures.montant'"></strong>{{ (data.facture.montant || 0) | montant:data.facture.devise }}</li>
            <li><strong [translate]="'od.frais.factures.contrepartie'"></strong>{{ (data.facture.contrepartie || 0) | montant:extraOptions.settings?.deviseEntreprise }}</li>
            <li *ngIf="data.listeAlertes"
                (click)="showListeAlertes(TypePortee.FC,data.facture.idFacture)"
                [ngClass]="NiveauAlerte.getClass(data.listeAlertes?.niveau,true)"
                class="clickable">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span></li>
        </ul>
    </div>
    <div *ngIf="data.facture.statut" class="info">
        <statut [statut]="data.facture.statut"></statut>
    </div>
</div>