<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }">
        <i class="nio icon-avance_2"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="dashboard-list-title" [routerLink]="" (click)="navigateTo()">
                {{ data.idAvance || ('avance.liste.numeroNonDefini' | translate) }} - {{ data.libelleObjet || ('avance.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'dashboard.listeAV.montant'"></strong><span>{{ data.montant | montant: data.devise }}</span></li>
        </ul>
    </div>
    <div class="info">
        <statut [statut]="data.statut" [isMinimized]="true"></statut>
    </div>
</div>
