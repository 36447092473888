<div class="row line-item-list">
    <div class="col-md-2 avatar">
            <mat-icon class="icon-centered">{{ item.icone }}</mat-icon>
    </div>
    <div class="col-md-10 body">
        <span class="row">
            <span class="col-md-12">
                {{ options.displayItem(item) }}
            </span>
        </span>
        <span class="row complement">
            <span class="col-md-12" *ngIf="item?.famille && !item.vehicule">
                <strong [translate]="'ndf.prestation.famille'"></strong>{{ item.famille.libelle }}
            </span>
            <span class="col-md-12" *ngIf="item.vehicule">
                <strong [translate]="'ndf.prestation.vehicule'"></strong>{{ getLibelleVehicule() }}
            </span>
        </span>
    </div>
</div>
