import {Component, OnInit, ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {Ville} from "@domain/geographie/ville";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction, TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TypeCodeErreur} from '@domain/common/http/result';
import {filter, finalize} from 'rxjs/operators';

/**
 * Composant d'affichage du détail d'une ville
 *
 * @author Angeline Ha
 * @date 28/11/2023
 */
@Component({
    host: {'data-test-id': 'ville'},
    selector: 'ville',
    templateUrl: './ville.component.html'
})
export class VilleComponent implements OnInit {

    /** Liste des différents onglets disponibles dans une Ville */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Ville à afficher */
    ville: Ville;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param router            Router
     * @param route             Route d'accès à cette page
     * @param geographieService Service géographie
     * @param toastrService     Service de gestion des toasts
     * @param confirmService    Service de confirmation
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant géographie qu'on affiche le détail d'une ville
        this.geographieService.isShowDetail = true;

        //Longueur de l'url pour récupérer l'identifiant de la ville
        let length = this.route.snapshot.url.length;
        //Récupération de l'identifiant de la ville
        let villeId: number = Number(this.route.snapshot.url[length - 1].path);

        //Indique si on est en mode création
        this.isCreation = villeId === 0;

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveVille(),
            isDisabled: () => !this.form?.valid
        }])

        //Récupération de la ville
        if (this.isCreation) {
            //Création d'une nouvelle ville
            this.ville = {
                idVille: 0,
                pays: {idPays: null},
                libelle: "",
                latitude: null,
                longitude: null,
                codeIATA: "",
                codeGare: "",
                codeKDS: "",
                idRegion: null,
                idDepartement: null,
                actif: true
            }
        } else {
            //Récupération de la ville existante
            this.geographieService.getVilleById(villeId).subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    this.ville = result.data.ville;
                } else {
                    this.ville = null;
                    TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD, this.translateService, this.toastrService);
                }
            });

            //On ajoute l'action de suppression d'une ville seulement en modification
            this.listeActions.next(
                [...this.listeActions.getValue(),
                    {
                        type: TypeAction.SECONDARY,
                        icone: 'nio icon-suppression',
                        libelle: 'global.actions.supprimer',
                        doAction: () => this.deleteVille()
                    }
                ]);
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'une ville
        this.geographieService.isShowDetail = false;
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Retour vers la liste des villes
     */
    onGoBack() {
        this.router.navigate(['../..'], {relativeTo: this.route});
    }

    /**
     * Enregistrement de la ville
     */
    saveVille() {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement de la ville
        this.geographieService.saveVille(this.ville)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                if (this.isCreation) {
                    //On n'est plus en mode création
                    this.isCreation = false;

                    //Ajout de l'action de suppression
                    this.listeActions.next(
                        [...this.listeActions.getValue(),
                            {
                                type: TypeAction.SECONDARY,
                                icone: 'nio icon-suppression',
                                libelle: 'global.actions.supprimer',
                                doAction: () => this.deleteVille()
                            }
                        ]);

                    //Navigation avec le bon id
                    this.router.navigate(['../' + result.data.idVille], {relativeTo: this.route});
                }

                //Récupération des infos mises à jour (identifiant, libellé externe)
                this.geographieService.getVilleById(result.data.idVille).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.ville = result.data.ville;
                    } else {
                        this.ville = null;
                        this.toastrService.error(this.translateService.instant('global.errors.chargement'));
                    }
                });
            } else if (result.codeErreur === TypeCodeErreur.ERROR_ALREADY_USED) {
                //Toast d'erreur : le code existe déjà pour une autre ville
                this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.ville.erreurCode'))
            } else {
                //Toast d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
            }
        });
    }

    /**
     * Suppression de la ville
     */
    deleteVille() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.ville.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({ next: () => {
                //Suppression en cours
                this.isDeleting = true;

                //Suppression de la ville
                this.geographieService.deleteVille(this.ville.idVille)
                    .pipe(finalize(() => this.isDeleting = false ))
                    .subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Retour vers la liste des villes
                        this.onGoBack();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                })
            }
        })
    }

}

/**
 * Enum des différents onglets d'une ville
 */
export enum Onglets {
    GENERALITES = "Généralités"
}
