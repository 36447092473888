import {Component, Input} from '@angular/core';

import {MatDialog} from "@angular/material/dialog";
import {OMPService} from "../../omp.service";
import {OMPZoneAddComponent} from "./omp-zone-add.component";
import {OmZone} from "../../../../domain/omp/omZone";
import {Omp} from "../../../../domain/omp/omp";
import {TypeEntiteParamOT} from "../../../../domain/typeentite/typeEntiteParam";
import {TypeOmZone} from "../../../../domain/omp/typeOmZone";

@Component({
    selector: 'omp-zones',
    templateUrl: './omp-zone-list.component.html'
})
export class OMPZoneListComponent {
    /** Mission permanente */
    @Input() omp: Omp;

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /** Paramètres du type entité de la mission permanente */
    @Input() typeEntiteParam: TypeEntiteParamOT;

    /**
     * Constructeur
     */
    constructor(private ompService: OMPService,private matDialog: MatDialog) {}

    /**
     * Affichage/création d'une zone
     *
     * @param zone Zone à afficher
     */
    showZone(zone?: OmZone): void {
        //Affichage de la popup
        this.matDialog.open(OMPZoneAddComponent,{
            data: {
                zone: Object.assign({
                    idOmZone: 0,
                    idOmPermanent: this.omp.idOMPermanent,
                    type: ''
                },zone),
                canModifier: this.canModifier
            },
            minWidth: 600
        }).afterClosed().subscribe({
            next: data => {
                //Vérification du résultat
                if (data) {
                    this.omp.listeZones = data.listeZones;
                }
            }
        });
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeZone Type de zone
     */
    getAvatarForTypeZone(typeZone: TypeOmZone): string {
        return this.ompService.getAvatarForTypeZone(typeZone);
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeZone Type de zone
     */
    getTitleForTypeZone(typeZone: TypeOmZone): string {
        return this.ompService.translateTypeZone(typeZone);
    }

    /**
     * Retourne le titre d'une ligne d'une zone de déplacement (son type, traduit)
     *
     * @param zone Zone de déplacement
     */
    getTitleForZone(zone: OmZone): string {
        let titre = '';

        switch (zone.type) {
            //Zone
            case TypeOmZone.TYPE_ZONE:
                //Libellé de la zone
                titre = zone.zone?.libelle;
                break;
            //Pays
            case TypeOmZone.TYPE_PAYS:
                //Libellé du pays
                titre = zone.pays?.libelle;
                break;
            //Territoire
            case TypeOmZone.TYPE_TERRITOIRE:
                //Libellé du territoire
                titre = zone.territoire?.libelle;

                //Ajout du libellé du pays
                if (zone.territoire?.pays?.libelle) {
                    titre += ', ' + zone.territoire.pays.libelle;
                }
                break;
            //Région
            case TypeOmZone.TYPE_REGION:
                //Libellé de la région
                titre = zone.region?.libelle;

                //Ajout du libellé du pays de la région
                if (zone.region?.pays?.libelle) {
                    titre += ', ' + zone.region.pays.libelle;
                }
                break;
            //Département
            case TypeOmZone.TYPE_DEPARTEMENT:
                //Libellé du département
                titre = zone.departement?.libelle;

                //Ajout du libellé de la région
                if (zone.departement?.regionAdmin?.libelle) {
                    titre += ', ' + zone.departement.regionAdmin.libelle;
                }

                //Ajout du libellé de la région
                if (zone.departement?.regionAdmin?.pays?.libelle) {
                    titre += ', ' + zone.departement.regionAdmin.pays.libelle;
                }
                break;
            //Ville
            case TypeOmZone.TYPE_VILLE:
                //Libellé de la ville
                titre = zone.ville?.libelle;

                //Ajout du libellé du département
                if (zone.ville.departementAdmin?.libelle) {
                    titre += ', ' + zone.ville.departementAdmin.libelle;
                }

                //Ajout du libellé de la région
                if (zone.ville.regionAdmin?.libelle) {
                    titre += ', ' + zone.ville.regionAdmin.libelle;
                }

                //Ajout du libellé du pays
                if (zone.ville.pays?.libelle) {
                    titre += ', ' + zone.ville.pays.libelle;
                }
                break;
            default:
                break;
        }

        return titre;
    }

    /**
     * Vérification de la validité des zones de déplacement
     */
    isValid(): boolean {
        //Si la gestion des déplacements est activée dans la confugiration, au moins une zone doit être renseignée
        return this.typeEntiteParam.gestionDeplacement === true ? this.omp.listeZones.length > 0 : true;
    }

    /**
     * Retourne le type enuméré TypeZone pour permettre son accès dans le template
     */
    public get TypeZone() {
        return TypeOmZone;
    }

}