import {Component, Input, OnInit} from '@angular/core';
import {Adresse, OptionVisibilite} from "@domain/profil/adresse";
import {ControlContainer, NgForm} from "@angular/forms";
import {GeographieView} from '@domain/geographie/geographieView';
import {TypeGeographie} from '@domain/geographie/typeGeographie';
import {TranslateService} from "@ngx-translate/core";
import {Origine, Visibilite} from "@domain/geolocalisation/localisation";
import {AdresseService} from "@share/component/adresse/adresse.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage de l'onglet généralité d'une adresse
 *
 * @author Angeline Ha
 * @date 07/12/2023
 */
@Component({
    host: {'data-test-id': 'adresse-generalites'},
    selector: 'adresse-generalites',
    templateUrl: './adresse-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class AdresseGeneralitesComponent implements OnInit {

    /** Adresse */
    @Input() adresse: Adresse;

    /** Modèle pour l'autocomplete de la ville */
    selectedVille: GeographieView;

    /** Modèle pour l'autocomplete du pays */
    selectedPays: GeographieView;

    /** Modèle pour l'autocomplete de la région */
    selectedRegion: GeographieView;

    /** Modèle pour l'autocomplete du département */
    selectedDepartement: GeographieView;

    /** Liste de toutes les visibilités possibles */
    listeVisibilitesMultiselect: OptionVisibilite[];

    /** Indique si on est en création ou en modification */
    @Input() isCreation: boolean;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param adresseService   Service des adresses
     * @param toastrService    Service des toasts
     */
    constructor(private translateService: TranslateService,
                private adresseService: AdresseService,
                private toastrService: ToastrService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération des informations pour les autocompletes géographie
        this.initAutocompletesGeographieValues();

        //Récupération de l'utilisateur lié à l'adresse
        this.getUser();

        //Définition de la liste des options de visibilité du multiselect
        this.defineOptionsVisibilites();
    }

    /**
     * Récupération des informations pour les autocompletes géographie
     */
    initAutocompletesGeographieValues(){
        //Récupération de la ville pour l'autocomplete
        this.selectedVille = this.adresse.adresseVille ? {
            id: this.adresse.adresseVille.idVille,
            type: TypeGeographie.VILLE,
            libelle: this.adresse.adresseVille.libelle,
            ville: this.adresse.adresseVille
        } : this.adresse.ville ? {
            //id non null (mais qui ne correspond à rien) afin que l'autocomplete fonctionne
            id: 0,
            type: TypeGeographie.VILLE,
            libelle: this.adresse.ville
        } : null;

        //Récupération du pays pour l'autocomplete - à partir de la ville ou du libellé 'pays'
        this.selectedPays = {
            id: this.adresse.adresseVille?.pays?.idPays,
            type: TypeGeographie.PAYS,
            libelle: this.adresse.adresseVille? this.adresse.adresseVille.pays?.libelle : this.adresse.pays,
            pays: this.adresse.adresseVille?.pays,
        };

        //Récupération de la région pour l'autocomplete - à partir de la ville ou du libellé 'region'
        this.selectedRegion = {
            id: this.adresse.adresseVille?.regionAdmin?.idRegion,
            type: TypeGeographie.REGION_ADMINISTRATIVE,
            libelle: this.adresse.adresseVille? this.adresse.adresseVille?.regionAdmin?.libelle : this.adresse.region,
            regionAdmin: this.adresse.adresseVille?.regionAdmin,
        };

        //Récupération du département pour l'autocomplete - à partir de la ville ou du libellé 'departement'
        this.selectedDepartement = {
            id: this.adresse.adresseVille?.idDepartement,
            type: TypeGeographie.DEPARTEMENT_ADMINISTRATIF,
            libelle: this.adresse.adresseVille? this.adresse.adresseVille.departementAdmin?.libelle : this.adresse.departement,
            departementAdmin: this.adresse.adresseVille?.departementAdmin,
        };
    }

    /**
     * Récupération de l'utilisateur
     */
    getUser() {
        //Si l'adresse a été créée par un utilisateur
        if (this.adresse.origine === Origine.ORIGINE_USER) {
            this.adresseService.getUser(this.adresse.idUser).subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    this.adresse.user = result.data.user;
                } else {
                    this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.adresse.proprietaires.errorUser'));
                }
            });
        }
    }

    /**
     * Définition de la liste des options de visibilité du multiselect
     */
    defineOptionsVisibilites() {
        this.listeVisibilitesMultiselect = [{
            id: Visibilite.FILTRE_V_NF,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.adresse.nf')
        }, {
            id: Visibilite.FILTRE_V_ADM,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.adresse.adm')
        }];
    }

    /**
     * Mise à jour des objets de saisie (ville, pays, région, département) avec la ville sélectionnée dans l'autocomplete
     */
    onVilleChange() {
        //Ville
        this.adresse.idVille = this.selectedVille.id;
        this.adresse.ville = this.selectedVille.libelle;
        this.adresse.adresseVille = this.selectedVille.ville;

        //Pays
        this.adresse.idPays = this.selectedVille.pays?.idPays;
        this.adresse.pays = this.selectedVille.pays?.libelle;
        this.selectedPays = {
            id: this.selectedVille.pays?.idPays,
            type: TypeGeographie.PAYS,
            libelle: this.selectedVille.pays?.libelle,
            pays: this.selectedVille.pays,
        };

        //Région
        this.adresse.idRegion = this.selectedVille.regionAdmin?.idRegion;
        this.adresse.region = this.selectedVille.regionAdmin?.libelle;
        this.selectedRegion = {
            id: this.selectedVille.regionAdmin?.idRegion,
            type: TypeGeographie.REGION_ADMINISTRATIVE,
            libelle: this.selectedVille.regionAdmin?.libelle,
            regionAdmin: this.selectedVille.regionAdmin,
        };

        //Département
        this.adresse.idDepartement = this.selectedVille.departementAdmin?.idDepartement;
        this.adresse.departement = this.selectedVille.departementAdmin?.libelle;
        this.selectedDepartement = {
            id: this.selectedVille.departementAdmin?.idDepartement,
            type: TypeGeographie.DEPARTEMENT_ADMINISTRATIF,
            libelle: this.selectedVille.departementAdmin?.libelle,
            departementAdmin: this.selectedVille.departementAdmin,
        };
    }

    /**
     * Définition de la liste des visibilités pour l'enregistrement
     */
    onVisibilitesChange() {
        //Parcours de toutes les options de visibilité du multiselect
        this.listeVisibilitesMultiselect.forEach(optionVisibilite => {
            //Cette option de visibilité est sélectionnée
            if (this.adresse.visibilites.some(optionVisibiliteSelectionnee => optionVisibiliteSelectionnee.id === optionVisibilite.id)) {
                //La visibilité est non présente dans la liste à enregistrer en base
                if (!this.adresse.listeVisibilite.some(visibilite => visibilite === optionVisibilite.id)) {
                    //Ajout de la visibilité
                    this.adresse.listeVisibilite.push(optionVisibilite.id);
                }
            } else {
                //Cette option de visibilité n'est pas sélectionnée - on la supprime si jamais elle est dans la liste à enregistrer
                this.adresse.listeVisibilite = this.adresse.listeVisibilite.filter(v => v !== optionVisibilite.id);
            }
        })
    }
}
