import {Component,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {TypeDocument} from "@domain/admin/parametre/demat/type-document";
import {TypeItemComponent} from "@components/admin/parametre/demat/type/type-item/type-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {TypeDialogComponent} from "@components/admin/parametre/demat/type/type-dialog/type-dialog.component";
import {Page} from "@domain/common/http/list-result";

/**
 * Composant d'affichage de l'onglet des types de pièce jointe
 *
 * @author Lionel Gimenez
 * @date 02/10/2023
 */
@Component({
    host: {'data-test-id': 'demat-type'},
    selector: 'demat-type',
    templateUrl: './type.component.html'
})
export class TypeComponent implements OnInit {
    /** La liste du des types */
    liste: ListView<TypeDocument,TypeItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private matDialog: MatDialog,
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Définition de la liste types documents
        this.liste = new ListView<TypeDocument,TypeItemComponent>({
            uri: `/controller/PieceJointe/listeTypeDocument`,
            title: this.translateService.instant('admin.parametres.demat.types.listeTitle'),
            component: TypeItemComponent,
            isSimple: true,
            mapResult: (result: Page<TypeDocument>) => result.listeResultats,
            listeActions: [{
                icon: "add",
                onPress: () => this.openTypeItemDialog()
            }],
            //isSimple: true,
            extraOptions: {
                open: (type: TypeDocument) => this.openTypeItemDialog(type)
            }
        });
    }

    /**
     * Ouvre la popin de détail d'un type
     * @param typeItem type item utilisé pour ouvrir la popin
     */
    openTypeItemDialog(typeItem: TypeDocument = null): void {
        //Si on n'a pas l'objet, on le crée
        if (typeItem == null) {
            typeItem = new TypeDocument();
            typeItem.ocr = false;
            typeItem.libelle = "";
            typeItem.typeObjet = "";
            typeItem.idTypeDocument = 0;
        }

        //Ouvre la popin
        this.matDialog.open(TypeDialogComponent, {
            data: {
                typeItem: typeItem
            },
            width: '80%'
        }).afterClosed().subscribe(
             refresh => {
                    if (refresh) {
                        //Rafraichissement des infos sur le profil voyageur
                        this.liste.refresh()
                    }
             }
        );
    }
}