import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Report} from "@domain/reporting/report";
import {ReportingService} from "../../reporting/reporting.service";
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Composant d'affichage de la liste des rapports d'extraction d'un objet workflow.
 */
@Component({
	selector: 'report-list',
	templateUrl: './report-list.component.html'
})
export class ReportListComponent {

	/** Liste des rapports */
	listeReports: Report[];

	/** Portée */
	portee: TypePortee;

	/** Id de l'objet workflow */
	idObjet: number;

	constructor(private reportingService: ReportingService, private matDialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private data: { listeReports: Report[], portee: TypePortee, idObjet: number }) {
		this.listeReports = data.listeReports;
		this.portee = data.portee;
		this.idObjet = data.idObjet;
	}

	/**
	 * Exécution du rapport.
	 *
	 * @param idReport Identifiant du rapport
	 */
	executeReport(idReport: number) {
		//Exécution et affichage du rapport
		this.reportingService.executeReportWFO(idReport, this.portee, this.idObjet);

		//Fermeture de la popin
		this.matDialogRef.close();
	}
}
