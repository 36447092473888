<h1 mat-dialog-title>
    <span>
        <span [translateParams]="{ immatriculation: vehicule.immatriculation}" [translate]="'vehicule.formulaire.documents.carteGrise.title'"></span>
        <mat-icon [matTooltip]="'vehicule.formulaire.documents.carteGrise.info' | translate" matSuffix>info</mat-icon>
    </span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="!carteGrise" class="content"></please-wait>
    <form autocomplete="off" #form="ngForm">
        <fieldset *ngIf="carteGrise && settings" [disabled]="!canModifier">
            <div class="row">
                <label class="col-md-4 text-right">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.immatriculation'"></span>
                    <span> [A]</span>
                </label>
                <div class="col-md-8">
                    <span class="d-flex">
                        {{vehicule.immatriculation}}
                    </span>
                </div>
            </div>
            <div class="row">
                <label class="col-md-4 text-right">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.marque'"></span>
                    <span> [D.1]</span>
                </label>
                <div class="col-md-8">
                    <span class="d-flex">
                        {{vehicule.marque}}
                    </span>
                </div>
            </div>
            <div class="row">
                <label class="col-md-4 text-right">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.modele'"></span>
                    <span> [D.2]</span>
                </label>
                <div class="col-md-8">
                    <span class="d-flex">
                        {{vehicule.modele}}
                    </span>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': dateDeb.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.dateImmatriculation'"></span>
                    <span> [B]</span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #dateDeb="ngModel" [(ngModel)]="carteGrise.dateImmatriculation" [matDatepicker]="$any(dateDebPicker)" [max]="aujourdhui" matInput name="dateDeb" required/>
                        <mat-datepicker-toggle [for]="dateDebPicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #dateDebPicker [disabled]="!canModifier"></mat-datepicker>
                        <mat-error *ngIf="carteGrise.dateImmatriculation && DateUtilsService.compareDates(carteGrise.dateImmatriculation,aujourdhui) > 0"><span [translate]="'vehicule.formulaire.errors.carteGriseDateImmat'"></span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': typeVehicule.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.genreNational'"></span>
                    <span> [J.1]</span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <mat-select #typeVehicule="ngModel" [(ngModel)]="carteGrise.idTypeVehicule" [disabled]="!canModifier" name="genreNational" required>
                            <mat-option *ngFor="let genre of settings.listeTypeVehicule" [value]="genre.idFiltre">{{ genre.libelle }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': typeEnergie.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.energie'"></span>
                    <span> [P.3]</span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <mat-select #typeEnergie="ngModel" [(ngModel)]="carteGrise.idEnergie" [disabled]="!canModifier" name="energie" required>
                            <mat-option *ngFor="let energie of settings.listeEnergieVehicule" [value]="energie.id">{{ energie.libelle }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': puissance.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.puissanceAdministrative'"></span>
                    <span> [P.6]</span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #puissance="ngModel" [(ngModel)]="carteGrise.puissanceAdministrative" matInput name="puissanceAdministrative" required type="number"/>
                    </mat-form-field>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': tauxCO2.invalid }" class="row">
                <label class="col-md-4 text-right required">
                    <span [translate]="'vehicule.formulaire.documents.carteGrise.tauxCo2'"></span>
                    <span> [V.7]</span>
                </label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input #tauxCO2="ngModel" [(ngModel)]="carteGrise.tauxCO2" matInput name="tauxCo2" required type="number"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-4"><span [translate]="'ndf.frais.remarque'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <textarea [(ngModel)]="carteGrise.commentaire" matInput name="remarque" rows="3"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
    <document-list #listeDocument *ngIf="carteGrise && settings" [context]="'PROFIL_VEHICULE_CARTE_GRISE'" [idObjetForPJ]="vehicule.idCollab" [idObjet]="carteGrise.id" [isSimpleDocumentList]="true"
                   [settings]="settings" [canAddDocument]="canModifier" [canDeleteDocument]="canModifier"></document-list>
</div>
<div *ngIf="carteGrise && canModifier" align="end" mat-dialog-actions>
    <button (click)="deleteCarteGrise()" *ngIf="carteGrise.id && (vehicule.statut == 2 || vehicule.statut == 3)" [disabled]="isSaving" color="warn" mat-stroked-button>
        <span [translate]="'global.actions.supprimer'"></span>
    </button>
    <button (click)="saveCarteGrise()" [disabled]="form?.invalid || isSaving" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
