import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

/**
 * Composant qui permet d'afficher la confirmation de suppression d'un référentiel
 */
@Component({
    host: {'data-test-id': 'delete-referentiel'},
    selector: 'delete-referentiel',
    templateUrl: './delete-referentiel.component.html'
})
export class DeleteReferentielComponent {
    /**
     * Constructeur
     *
     * @param dialogRef Référence de la boîte de dialogue associée à ce composant
     */
    constructor(public dialogRef: MatDialogRef<DeleteReferentielComponent>) {}

    /**
     * Méthode appelée lors du clic sur le bouton "confirmer" ou sur le bouton "annuler"
     *
     * @param deleteReferentiel Variable indiquant si l'utilisateur veut supprimer le référentiel ou non
     */
    closeDialog(deleteReferentiel: boolean) {
        //Fermeture de la boîte de dialogue et on renvoie la valeur indiquant si on veut supprimer le référentiel ou non
        this.dialogRef.close(deleteReferentiel);
    }
}