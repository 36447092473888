import {Injectable} from '@angular/core';
import {TypeRuleFilterOperator} from "@domain/rule/type-rule-filter-operator";
import {TranslateService} from "@ngx-translate/core";
import {Rule} from "@domain/rule/rule";
import {EntityTypeValue,RuleRepository,TypeFieldType} from "@share/component/rule-builder/rule-repository.service";
import {EntiteService} from "@share/component/rule-builder/entite.service";
import {first} from "rxjs/operators";
import {EntityWrapper} from "@domain/entite/EntityWrapper";

@Injectable()
export class RuleBuilderService {
	/** Rule mise en surbrillance */
	selectedRule: Rule = null;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param ruleRepository le repository des rules
	 * @param entiteService le service de gestion des entités
	 */
	constructor(
		private translateService: TranslateService,
		private ruleRepository: RuleRepository,
		private entiteService: EntiteService
	) {
	}

	/**
	 * Détermination du type de champs de saisie des filtres de la règle
	 *
	 * @param rule la Rule
	 */
	assignFieldType(rule: Rule): void {
		//Vérification de la présence de détails sur la règle
		if (rule?.listeDetails?.length) {
			//Parcours des détails de la règle
			rule.listeDetails.forEach(detail => {
				//Vérification de la présence d'un filtre
				if (detail.filter) {
					//Détermination du type de champs de saisie
					detail.filter.fieldType = this.getInputFieldType(detail.filter.type);

					//Vérification de la présence d'une énumération
					detail.filter.enum = detail.filter.fieldType?.type === TypeFieldType.ENUM
				} else {
					//Détermination du type de champs de saisie des règles internes
					this.assignFieldType(detail.rule);
				}
			});
		}
	}

	/**
	 * Récupération du détail d'un type
	 *
	 * @param type le type
	 */
	getInputFieldType(type: string): EntityTypeValue {
		let fieldType: EntityTypeValue;

		try {
			//Tentative de récupération du type
			fieldType = eval('this.ruleRepository.listeInputFields.' + type);
		} catch (e) {
		}

		//Vérification de la présence du type
		if (fieldType) {
			//Vérification du type d'élément
			if (fieldType.type === TypeFieldType.ENUM) {
				//Vérification de l'absence des opérateurs
				if (!fieldType.listeOperators) {
					//Ajout des opérateurs par défaut
					fieldType.listeOperators = [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					}];
				}
			}
		} else {
			//Définition d'un type générique
			fieldType = {
				type: TypeFieldType.NUMBER,
				listeOperators: [{
					code: TypeRuleFilterOperator.IS_NULL,
					libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
				},{
					code: TypeRuleFilterOperator.IS_NOT_NULL,
					libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
				}]
			};
		}

		//Retour du type de champ pour le type d'entité fourni en entrée
		return fieldType;
	}

	/**
	 * Chargement des entités des rules embarquées s'il y en a
	 *
	 * @param rule la Rule à traiter
	 */
	loadEmbeddedRulesEntities(rule: Rule): void {
		//Vérification de la présence de détails sur la règle
		if (rule?.listeDetails?.length) {
			//Parcours des détails de la règle
			rule.listeDetails.forEach(detail => {
				//Si le détail contient une règle embarquée
				if (detail?.filter?.embeddedRule) {
					//On charge l'entité associée
					this.entiteService.loadBusinessData(detail.filter.type)
						.pipe(first())
						.subscribe((entite: EntityWrapper) => {
							detail.filter.childEntity = entite;
							detail.filter.childEntityLoaded = true;
						});
				}

				//Si le détail est en fait un groupe de règles
				if (detail.rule) {
					//Appel récursif sur la règle qu'il contient
					this.loadEmbeddedRulesEntities(detail.rule);
				}
			});
		}
	}

	/**
	 * Renvoie true si la rule passée en paramètre est celle mise en surbrillance, false sinon
	 *
	 * @param rule une Rule
	 */
	isSelectedRule(rule: Rule): boolean {
		return rule === this.selectedRule;
	}

	/**
	 * Mise en surbrillance d'une Rule
	 *
	 * @param rule la Rule
	 */
	selectRule(rule: Rule) {
		if (this.selectedRule !== rule) {
			this.selectedRule = rule;
		} else {
			this.selectedRule = null;
		}
	}
}
