import {FicheCollaborateur} from '../../domain/collaborateur/fiche-collaborateur';
import {Component, Input, OnInit} from '@angular/core';
import {FicheCollaborateurService} from './fiche-collaborateur.service';
import {Adresse} from "../../domain/profil/adresse";

@Component({
	selector: 'fiche-collaborateur',
	templateUrl: './fiche-collaborateur.component.html'
})
export class FicheCollaborateurComponent implements OnInit {

	@Input() idCollaborateur;

	// Modèle de données affiché
	fc: FicheCollaborateur = new FicheCollaborateur();

	// Contrôle l'affichage du chargement de la page
	isLoaded: boolean = false;

	constructor(private ficheCollaborateurService: FicheCollaborateurService) {

	}

	ngOnInit(): void {
		// Charge les données du backend dans le modèle via l'idCollaborateur
		this.loadFicheCollaborateur(this.idCollaborateur);
	}

	/**
	 * Charge les données du backend dans le modèle
	 * @param idCollaborateur 
	 */
	private loadFicheCollaborateur(idCollaborateur: number) {
		this.ficheCollaborateurService
			.loadCollaborateur(idCollaborateur)
			.subscribe(
				data => {
					this.fc.idCollaborateur = data.idCollab;

					this.fc.categorie = data.categorie;
					this.fc.email = data.email;
					this.fc.nom = data.nom;
					this.fc.prenom = data.prenom;
					this.fc.matricule = data.matricule;
					this.fc.service = data.service;
					this.fc.societe = data.societe;
					this.fc.titre = data.titre;

					this.fc.residenceAdministrative = this.formatAdresse(data.adresseProfessionnelle);
					this.fc.residencePersonnelle = this.formatAdresse(data.adressePersonnelle);

					this.fc.libelleChamp1 = data.libelleChamp1;
					this.fc.libelleChamp2 = data.libelleChamp2;
					this.fc.libelleChamp3 = data.libelleChamp3;
					this.fc.libelleChamp4 = data.libelleChamp4;

					this.fc.champ1 = data.champ1;
					this.fc.champ2 = data.champ2;
					this.fc.champ3 = data.champ3;
					this.fc.champ4 = data.champ4;

					this.fc.attribut1 = data.attribut1;
					this.fc.attribut2 = data.attribut2;
					this.fc.attribut3 = data.attribut3;
					this.fc.attribut4 = data.attribut4;

					this.isLoaded = true;
				}
			);
	}

	/**
	 * Formate l'adresse du collaborateur au format attendu par l'ihm.
	 */
	private formatAdresse(adresse: Adresse): string {
		var adresseFormattee = '';
		if (adresse) {
			if (adresse.rue) {
				adresseFormattee += adresse.rue + ' ';
			}

			if (adresse.codePostal) {
				adresseFormattee += adresse.codePostal + ' ';
			}				

			if (adresse.ville) {
				adresseFormattee += adresse.ville + ' ';
			}				

			if (adresse.codePays) {
				adresseFormattee += adresse.codePays + ' ';
			}				
		}

		return adresseFormattee;
	}
}
