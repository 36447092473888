import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Traduction
 */
export class Traduction implements ListItem {
    idMessageTraduction: number;
    codeLangue: string;
    cleTraduction: string;
    messageStandard: string;
    messageCustom: string;

    getKey(): number {
        return this.idMessageTraduction;
    }

    constructor(dto) {
        if (dto) {
            this.idMessageTraduction = dto.idMessageTraduction;
            this.codeLangue = dto.codeLangue;
            this.cleTraduction = dto.cleTraduction;
            this.messageStandard = dto.messageStandard;
            this.messageCustom = dto.messageCustom;
        }
    }

    /**
     * Libellé pour affichage dans la liste
     */
    get libelle(): string {
        return this.messageCustom || this.messageStandard || this.cleTraduction;
    }
}
