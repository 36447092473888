<div class="with-floating-button">
    <div class="content">
        <page-header
                *ngIf="isDisplayed"
                [title]="'admin.maintenance.migrations.title' | translate"
                [listeItems]="listeTabItems"
                [selectedIndex]="selectedIndex"
                (onSelectedItemChange)="onSelectedItemChange($event)"
                [extraInfo]="applicationStatut | translate"
                [niveauAlerte]="isOngletMaintenance() ? migrationsService?.listeAlertes?.niveau : null">
            <ng-container *ngIf="isOngletMaintenance()"><preview-alerte *ngFor="let alerte of migrationsService?.listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte></ng-container>
        </page-header>

        <router-outlet></router-outlet>
    </div>
</div>