<div class="lvi-content">
    <div class="avatar">
        <i class="nio" [ngClass]="getAvatarIcon()"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="extraOptions.close(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'filter.profil'"></strong>{{ TypeProfil.getLibelle(translateService, data.fonction) }}</li>
        </ul>
    </div>
</div>