import {Component,Inject,OnInit} from '@angular/core';
import {Alerte,NiveauAlerte} from '@domain/common/alerte/alerte';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Periode} from "@domain/admin/comptabilite/periode";
import {TranslateService} from "@ngx-translate/core";
import {ComptabiliteExercicesPeriodesService} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {finalize,first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

/**
 * Popin de clôture d'une période
 *
 * @author Alexis BOUVARD
 * @date 24/04/2023
 */
@Component({
	host: {'data-test-id': 'periode-cloture'},
	templateUrl: './periode-cloture.component.html',
	styleUrls: ['./periode-cloture.component.scss']
})
export class PeriodeClotureComponent implements OnInit {

	/** Import de l'enum pour le DOM */
	niveauAlerte = NiveauAlerte;

	/** Alerte */
	alerte: Alerte;

	/** Chargement en cours */
	isPending: boolean;

	/** Liste des périodes ouvertes */
	listePeriodesOuvertes: Periode[] = [];

	/** ID de la période cible */
	idPeriodeCible: number;

	/**
	 * Constructeur
	 *
	 * @param data données en entrée de la popin : liste des périodes en cours de clôture
	 * @param translateService le moteur de traduction
	 * @param comptabiliteExercicesPeriodesService le service de gestion des périodes
	 * @param toastrService service de gestion des toasts
	 * @param matDialogRef la ref pointant vers la popup
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Periode[],
		private translateService: TranslateService,
		private comptabiliteExercicesPeriodesService: ComptabiliteExercicesPeriodesService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<PeriodeClotureComponent>
	) {
		//Initialisation de l'alerte
		this.alerte = {
			niveau: NiveauAlerte.WARNING,
			titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
			message: this.translateService.instant('admin.comptabilite.exercicesPeriodes.exercice.periodes.popinCloture.warningMessage')
		}
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Début du chargement
		this.isPending = true;

		//Récupération de la liste des périodes ouvertes
		this.comptabiliteExercicesPeriodesService.getListePeriodesOuvertes()
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//On check si le chargement s'est bien passé
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Récupération des périodes ouvertes en filtrant les périodes en cours de clôture
					this.listePeriodesOuvertes = result.data.listePeriodes.filter(p => this.data.every(periode => periode.idPeriode !== p.idPeriode))

					//Sélection par défaut de la période suivant la dernière période sélectionnée
					this.idPeriodeCible = this.listePeriodesOuvertes.filter(p => p.dateDebut > this.data[this.data.length - 1].dateFin)[0].idPeriode;
				} else {
					//Toast error
					this.toastrService.error(this.translateService.instant('global.errors.chargement'));
				}
			});
	}

	/**
	 * Validation du formulaire
	 */
	valider(): void {
		//Fermeture du matDialog
		this.matDialogRef.close(this.idPeriodeCible);
	}
}
