import {Role} from "./role";
import {TypeAction} from "./workflow";

/**
 * Modèle représentant les droits du user connecté sur un objet workflow
 */
export class MapAction {
    canValider: WFAction;
	canRejeter: WFAction;
	canInvalider: WFAction;
	canAnnuler: WFAction;
	canSupprimer: WFAction;
	canEmettre: WFAction;
	canArchiver: WFAction;
	canComptabiliser: WFAction;
	canRestituer: WFAction;
    canModifier: WFAction;
    canCloturer: WFAction;
    canCompleter: WFAction;
    canAttribuer: WFAction;

    actionsSupplementaires: WFActionsSupplementaires;

    roles: Role[];

    constructor(dto: any) {
        if (dto) {
            if (dto.canValider) { this.canValider = new WFAction(dto.canValider); }
            if (dto.canRejeter) { this.canRejeter = new WFAction(dto.canRejeter); }
            if (dto.canInvalider) { this.canInvalider = new WFAction(dto.canInvalider); }
            if (dto.canAnnuler) { this.canAnnuler = new WFAction(dto.canAnnuler); }
            if (dto.canSupprimer) { this.canSupprimer = new WFAction(dto.canSupprimer); }
            if (dto.canEmettre) { this.canEmettre = new WFAction(dto.canEmettre); }
            if (dto.canArchiver) { this.canArchiver = new WFAction(dto.canArchiver); }
            if (dto.canComptabiliser) { this.canComptabiliser = new WFAction(dto.canComptabiliser); }
            if (dto.canRestituer) { this.canRestituer = new WFAction(dto.canRestituer); }
            if (dto.canModifier) { this.canModifier = new WFAction(dto.canModifier); }
            if (dto.canCloturer) { this.canCloturer = new WFAction(dto.canCloturer); }
            if (dto.canCompleter) { this.canCompleter = new WFAction(dto.canCompleter); }
            if (dto.canAttribuer) { this.canAttribuer = new WFAction(dto.canAttribuer); }

            if (dto.actionsSupplementaires) { this.actionsSupplementaires = new WFActionsSupplementaires(dto.actionsSupplementaires); }

            if (dto.roles && dto.roles.length) { this.roles = dto.roles.map(r => new Role(r)) }
        }
    }

    /**
     * Vérifie l'autorisation sur une action
     * @param typeAction type d'action
     * @returns true si l'action est autorisée
     */
    getWFAction(typeAction: TypeAction): WFAction {
        switch (typeAction) {
            case TypeAction.ANNULER: return this.canAnnuler;
            case TypeAction.ARCHIVER: return this.canArchiver;
            case TypeAction.CLOTURER: return this.canCloturer;
            case TypeAction.COMPTABILISER: return this.canComptabiliser;
            case TypeAction.EMETTRE: return this.canEmettre;
            case TypeAction.INVALIDER: return this.canInvalider;
            case TypeAction.MODIFIER: return this.canModifier;
            case TypeAction.REJETER: return this.canRejeter;
            case TypeAction.RESTITUER: return this.canRestituer;
            case TypeAction.SUPPRIMER: return this.canSupprimer;
            case TypeAction.VALIDER: return this.canValider;
            case TypeAction.COMPLETER: return this.canCompleter;
            case TypeAction.ATTRIBUER: return this.canAttribuer;
            default: throw new Error('Type non supporté.');
        }
    }
}

/**
 * Class représentant une action workflow
 */
export class WFAction {
    /** Action possible avec l'utilisateur connecté */
    possible: boolean;

    /** Action possible en masse avec l'utilisateur connecté */
    possibleMasse: boolean;

    /** Choix de l'approbateur */
    choixApprobateur: boolean;

    /** Envoi de mail */
    mail: boolean;

    //Indicateurs pour le motif
    motifRejet: boolean;
	motifValidation: boolean;
	motifRejetObligatoire: boolean;
	motifValidationObligatoire: boolean;

    /** Message de traduction */
    message: string;

    constructor(dto: any) {
        if (dto) {
            this.possible = dto.possible;
            this.possibleMasse = dto.possibleMasse;
            this.choixApprobateur = dto.choixApprobateur;
            this.mail = dto.mail;
            this.motifRejet = dto.motifRejet;
            this.motifValidation = dto.motifValidation;
            this.motifRejetObligatoire = dto.motifRejetObligatoire;
            this.motifValidationObligatoire = dto.motifValidationObligatoire;
            this.message = dto.message;
        }
    }
}

/**
 * Modèle représentant les actions Workflow supplémentaires
 */
export class WFActionsSupplementaires {
    canOdDefinirTemps: WFAction;
	canCreationNdf: WFAction;
	canOmpCreationOd: WFAction;
    canOdCreationHebergement: WFAction;
    canOdCreationAvance: WFAction;
    canOdCreationDossierVoyage: WFAction;
    canOdCreationFacture: WFAction;
    canOdCreationFacturePrev: WFAction;
    canOdCreationFraisPrev: WFAction;
    canOdCreationFormulaireAutre: WFAction;
    canOdCreationTransport: WFAction;

    constructor(dto: any) {
        if (dto) {
            if (dto.canOdDefinirTemps) { this.canOdDefinirTemps = new WFAction(dto.canOdDefinirTemps); }
            if (dto.canCreationNdf) { this.canCreationNdf = new WFAction(dto.canCreationNdf); }
            if (dto.canOmpCreationOd) { this.canOmpCreationOd = new WFAction(dto.canOmpCreationOd); }
            if (dto.canOdCreationHebergement) { this.canOdCreationHebergement = new WFAction(dto.canOdCreationHebergement); }
            if (dto.canOdCreationAvance) { this.canOdCreationAvance = new WFAction(dto.canOdCreationAvance); }
            if (dto.canOdCreationDossierVoyage) { this.canOdCreationDossierVoyage = new WFAction(dto.canOdCreationDossierVoyage); }
            if (dto.canOdCreationFacture) { this.canOdCreationFacture = new WFAction(dto.canOdCreationFacture); }
            if (dto.canOdCreationFacturePrev) { this.canOdCreationFacturePrev = new WFAction(dto.canOdCreationFacturePrev); }
            if (dto.canOdCreationFraisPrev) { this.canOdCreationFraisPrev = new WFAction(dto.canOdCreationFraisPrev); }
            if (dto.canOdCreationFormulaireAutre) { this.canOdCreationFormulaireAutre = new WFAction(dto.canOdCreationFormulaireAutre); }
            if (dto.canOdCreationTransport) { this.canOdCreationTransport = new WFAction(dto.canOdCreationTransport); }
        }
    }
}