import { Component,Input,ViewChild } from '@angular/core';
import { Od } from "../../../../domain/od/od";
import { OdEngagementsResumeComponent } from "./resume/od-engagements-resume.component";
import { OdEngagementsIndemnitesComponent } from "./indemnites/od-engagements-indemnites.component";
import { OdEngagementsFraisComponent } from "./frais/od-engagements-frais.component";
import { OdEngagementsFacturesComponent } from "./factures/od-engagements-factures.component";
import { Contexte } from "../../../../domain/od/engagements/indemnites/contexte";

/**
 * Composant de l'onglet "Engagements" de l'OD
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-engagements',
	templateUrl: './od-engagements.component.html'
})
export class OdEngagementsComponent {
	/* Déclaration pour usage depuis le template */
	Contexte = Contexte;

	/** Ordre de mission */
	@Input() od: Od = null;

	/** Composant du résumé */
	@ViewChild("resume")
	resume: OdEngagementsResumeComponent;

	/** Composant des frais */
	@ViewChild("frais")
	frais: OdEngagementsFraisComponent;

	/** Composant des factures */
	@ViewChild("factures")
	factures: OdEngagementsFacturesComponent;

	/** Composant des indemnités */
	@ViewChild("indemnites")
	indemnites: OdEngagementsIndemnitesComponent;

	/**
	 * Rafraichissement de tous les composants enfants
	 */
	refresh(): void {
		this.resume.refresh();
		this.frais.refresh();
		this.factures.refresh();
		this.indemnites.refresh();
	}

}