<div class="lvi-content" *ngIf="data.idOMPermanent">
    <div class="avatar">{{ data.typeEntite.code.substring(0, 3) }}</div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" [mat-dialog-close]="{ od: data, typeEntite: data.typeEntite }">{{ 'od.creation.mission' | translate:{
				idOMPermanent: data.idOMPermanent,
				objet: data.objet || ('ndf.liste.objetNonDefini' | translate)
			} }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'filter.typeEntite'"></strong>{{ data.typeEntite.libelle }}</li>
            <li><strong [translate]="'od.liste.libelleVille'"></strong><span class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"
                                                                 *ngIf="data.pays"></span>{{ (data.ville?.libelle ? data.ville.libelle + ', ' : '') + (data.pays?.libelle || '') }}</li>
        </ul>
    </div>
</div>
<div class="lvi-content" *ngIf="!data.idOMPermanent">
    <div class="avatar" [translate]="'ndf.creation.horsMissionAvatar'"></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" [translate]="'od.creation.horsMission'" (click)="selectNoMission()"></a>
        </div>
    </div>
    <div class="info">
        <mat-icon>chevron_right</mat-icon>
    </div>
</div>
