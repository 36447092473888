import { NgModule } from '@angular/core';
import { DeviseInfosComponent } from "@components/admin/bibliotheque/devises/devise-infos/devise-infos.component";
import { DeviseGestionAddPaysComponent } from "@components/admin/bibliotheque/devises/devise-infos/generalites/add-pays/devise-gestion-add-pays.component";
import { DeviseGestionAvancesComponent } from "@components/admin/bibliotheque/devises/devise-infos/generalites/devise-gestion-avances/devise-gestion-avances.component";
import { DeviseGestionPaysItemComponent } from "@components/admin/bibliotheque/devises/devise-infos/generalites/devise-gestion-pays/devise-gestion-pays-item/devise-gestion-pays-item.component";
import { DeviseGestionPaysComponent } from "@components/admin/bibliotheque/devises/devise-infos/generalites/devise-gestion-pays/devise-gestion-pays.component";
import { DeviseInfosGeneralitesComponent } from "@components/admin/bibliotheque/devises/devise-infos/generalites/devise-infos-generalites.component";
import { DeviseTauxItemComponent } from "@components/admin/bibliotheque/devises/devise-infos/taux/devise-taux-item/devise-taux-item.component";
import { DeviseTauxModifyComponent } from "@components/admin/bibliotheque/devises/devise-infos/taux/devise-taux-modify/devise-taux-modify.component";
import { DeviseTauxComponent } from "@components/admin/bibliotheque/devises/devise-infos/taux/devise-taux.component";
import { ShareModule } from "@share/share.module";
import { DeviseItemComponent } from "@components/admin/bibliotheque/devises/devise-item/devise-item.component";
import { DevisesComponent } from "./devises.component";

@NgModule({
    imports: [ShareModule],
    declarations: [
        DevisesComponent,
        DeviseItemComponent,
        DeviseInfosComponent,
        DeviseInfosGeneralitesComponent,
        DeviseGestionPaysComponent,
        DeviseGestionPaysItemComponent,
        DeviseGestionAvancesComponent,
        DeviseTauxComponent,
        DeviseTauxItemComponent,
        DeviseTauxModifyComponent,
        DeviseGestionAddPaysComponent
    ],
    entryComponents: [DeviseItemComponent, DeviseTauxItemComponent],
    exports: [
        DevisesComponent,
        DeviseInfosGeneralitesComponent,
        DeviseGestionPaysComponent,
        DeviseGestionAvancesComponent
    ],
    providers: []
})
export class DevisesModule {
}
