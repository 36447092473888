import { Injectable } from "@angular/core";
import { FloatingButtonComponent } from "@share/component/floating-button/floating-button.component";
import { FloatingButtonAction } from "@share/component/floating-button/floating-button";
import { BehaviorSubject } from "rxjs";

/**
 * Service de gestion des boutons d'action flottants.<br>
 * À utiliser conjointement avec la {@link FloatingButtonDirective}
 *
 * @author Laurent SCIMIA
 * @date 24/02/2023
 */
@Injectable()
export class FloatingButtonService {
	/** true si le bouton affiche actuellement les actions spécifiques */
	public isActionsSpecifiques: boolean = false;

	/** Composant des boutons flottants */
	private _floatingButtonComponent: FloatingButtonComponent;

	/** Backup des actions disponibles avant modification */
	private _initialActionList: BehaviorSubject<Array<FloatingButtonAction>>;

	/** Liste des actions disponibles avant modification */
	public get initialActionList(): BehaviorSubject<Array<FloatingButtonAction>> {
		return this._initialActionList;
	}

	/** Accès au composant des boutons flottants */
	public get floatingButtonComponent(): FloatingButtonComponent {
		return this._floatingButtonComponent;
	}

	/** Liste des actions actuellement appliquées aux boutons flottants */
	public get listeActions(): BehaviorSubject<Array<FloatingButtonAction>> {
		return this._floatingButtonComponent.listeActions;
	}

	/**
	 * Liste des actions actuellement appliquées aux boutons flottants
	 *
	 * @param listeActions          Liste des actions à afficher sur les boutons flottants
	 * @param isActionSecondaire    true s'il ne s'agit pas des actions par défaut (celles avant modification)
	 */
	public setListeActions(listeActions: BehaviorSubject<Array<FloatingButtonAction>>, isActionSecondaire: boolean = true) {
		this._floatingButtonComponent.listeActions = listeActions;
		this._floatingButtonComponent.isShowBorder = isActionSecondaire;
	}

	/** Initialisation du service (récupération du composant et backup des actions d'origine) */
	public initFloatingButtonService(floatingButtonComponent: FloatingButtonComponent): void {
		//On backup les actions disponibles à l'origine
		this._initialActionList = floatingButtonComponent.listeActions;

		//On récupère le composant des boutons
		this._floatingButtonComponent = floatingButtonComponent;
	}

	/**
	 * Reset les actions possibles sur les boutons flottants à partir du backup enregistré
	 */
	public resetFloatingButtonActions(): void {
		//On réintègre les actions de base sur les boutons
		this.floatingButtonComponent.listeActions = this.initialActionList;
		this.floatingButtonComponent.isShowBorder = false;
	}
}