<div class="lvi-content">
    <div class="avatar">
        <i class="icon-profil nio align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.close(data)">{{ formatUser(data) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngFor="let attr of attributes"><strong [translate]="attr.clef"></strong>{{ attr.valeur }}</li>
        </ul>
    </div>
</div>
