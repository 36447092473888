import {ListItem} from "../../common/list-view/list-item";

/**
 * DTO d'un référentiel
 */
export class ReferentielDto implements ListItem {
    idReferentiel: number;
    type: string;
    libelle: string;
    code: string;
    ordre: number;
    defaut: boolean = false;

    getKey(): number {
        return this.idReferentiel;
    }

}
