<h1 mat-dialog-title>
    <i *ngIf="transportHasType() && !data.transport?.idTransport" class="nio icon-transfere clickable" (click)="goBack()"></i>
    <span translate>omp.transports.ajout.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div [hidden]="transportHasType()" class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let typeTransport of listeTypeTransport">
                <div class="lvi-content clickable" [routerLink]="" (click)="selectTypeTransport(typeTransport.id);">
                    <div class="avatar">{{ getAvatarForTypeTransport(typeTransport.id) }}</div>
                    <div class="title align-self-md-center">
                        <a>{{ getTitleForTypeTransport(typeTransport.id) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form autocomplete="off" [hidden]="!transportHasType()" #transportAddForm="ngForm">
        <fieldset [disabled]="!data?.canModifier">
            <div class="row">
                <label class="col-md-4"><span [translate]="'omp.transports.typeTransport'"></span></label>
                <div class="col-md-8">
                    <input matInput [value]="getTitleForTypeTransport(data?.transport.type)" [readonly]="true" />
                </div>
            </div>
            <div *ngIf="data?.transport.type === TypeTransport.TYPE_PERSONNEL" class="row" [ngClass]="{ 'has-error': !data?.transport.idVehicule }">
                <label class="col-md-4 required"><span [translate]="'omp.transports.vehicule'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <mat-select name="vehicule" [(ngModel)]="data?.transport.idVehicule" [required]="true">
                            <mat-option value="" [translate]="'global.input.choisissez'"></mat-option>
                            <mat-option *ngFor="let vehicule of data?.listeVehicule" [value]="vehicule.idPa">
                                {{ vehicule.immatriculation }} - {{ vehicule.marque }} - {{ vehicule.modele }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="data?.transport.type === TypeTransport.TYPE_PERSONNEL" class="row">
                <label class="col-md-4"><span [translate]="'omp.transports.autorisation'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input matInput name="numerique1" [(ngModel)]="data?.transport.numerique1" [nNumber]="2" autocomplete="off"/>
                        <span matSuffix [translate]="'omp.transports.kmAutorises'" class="p-l-5"></span>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="[TypeTransport.TYPE_AVION,TypeTransport.TYPE_TRAIN].includes(data.transport?.type?.valueOf())" class="row" [ngClass]="{ 'has-error': !data?.transport.idConfort }">
                <label class="col-md-4 required"><span [translate]="'omp.transports.confort'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <mat-select name="confort" [(ngModel)]="data?.transport.idConfort" [required]="true">
                            <mat-option value="" [translate]="'global.input.choisissez'"></mat-option>
                            <mat-option *ngFor="let confort of getListeConfortForTypeTransport(data?.transport.type)" [value]="confort.idConfort">{{ confort.libelle }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="data.transport.type !== -1 && data.transport.type !== TypeTransport.TYPE_PERSONNEL" class="row" [ngClass]="{ 'has-error': (data.transport.type === TypeTransport.TYPE_AUTRE && !attribut1.model) || attribut1.invalid }">
                <label class="col-md-4" [ngClass]="{ 'required': data.transport.type === TypeTransport.TYPE_AUTRE }"><span [translate]="'omp.transports.complements'"></span></label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex">
                        <input matInput #attribut1="ngModel" name="attribut1" [(ngModel)]="data.transport.attribut1" maxlength="2000" [required]="data.transport.type === TypeTransport.TYPE_AUTRE"/>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div *ngIf="transportHasType()" mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="deleteTransport()" [disabled]="isSaving" *ngIf="data?.transport.idTransport">
        <span *ngIf="!isSaving" translate>global.actions.supprimer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="transportAddForm.invalid || isSaving" (click)="saveTransport()">
        <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
