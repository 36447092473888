import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { VoyagesReferentielsCompagniesInfosComponent } from "@components/admin/voyages/referentiels/compagnies/compagnie-infos/voyages-referentiels-compagnies-infos.component";
import { CompagniesDTO } from "@domain/admin/voyages/referentiels/compagniesDto";
import { ListViewItem } from "@domain/common/list-view/list-view-item";
import { VoyagesReferentielsService } from "@services/admin/voyages/voyages-referentiels.service";

/**
 * Composant d'affichage d'une compagnie dans la liste des différentes compagnies disponibles
 */
@Component({
	host: {'data-test-id': 'voyages-referentiels-compagnies-item'},
	selector: 'voyages-referentiels-compagnies-item',
	templateUrl: './voyages-referentiels-compagnies-item.component.html'
})
export class VoyagesReferentielsCompagniesItemComponent extends ListViewItem<CompagniesDTO> implements OnInit {
	/** Liste des prestations possibles pour une compagnie */
	listePrestations = this.voyagesReferentielsService.listePrestations;

	/** Libellé de la prestation de la compagnie */
	prestationLibelle: string;

	/**
	 * Constructeur
	 *
	 * @param matDialog Boîte de dialogue
	 * @param voyagesReferentielsService Service de gestion des référentiels du menu Voyages
	 */
	constructor(private matDialog: MatDialog,
				private voyagesReferentielsService: VoyagesReferentielsService) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération du libellé correspondant à la prestation de la compagnie
		this.prestationLibelle = this.listePrestations.find(element => element.id == this.data.typePrestation).libelle;
	}

	/**
	 * Méthode appelée lors de la sélection d'une compagnie
	 *
	 * @param compagnieSelected Compagnie sélectionnée
	 */
	openCompagnie(compagnieSelected: CompagniesDTO) {
		//Ouverture de la popup
		this.matDialog.open(VoyagesReferentielsCompagniesInfosComponent, {
			data: {
				idCompagnie: compagnieSelected.idCompagnie,
			},
			width: '80%'
		}).afterClosed().subscribe({
			next: refresh => {
				if (refresh) {
					//Rafraichissement de la liste
					this.liste.refresh();
				}
			}
		});
	}
}
