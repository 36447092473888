import {Ij} from "../od/engagements/indemnites/ij";
import {Unite} from "./unite";
import {NatureVoyage} from "./nature-voyage";
import {Referentiel} from "@domain/admin/referentiels/referentiel";
import {Vehicule} from "@domain/vehicule/vehicule";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Prestation associée à une indemnité d'un OD
 */
export class Prestation implements ListItem {
	/** Données */
	idPrestation: number;
	libelle: string;
	code: string;
	icone: string;
	type: TypePrestation;
	devise: string;
	bareme: boolean;
	idDomainePrestation: number;
	unite: Unite;
	saisieTaxe: boolean;
	quantiteObligatoire: boolean;
	quantiteModifiable: boolean;
	quantiteDefaut: number;
	remarqueObligatoire: boolean;
	libelleRemarque: string;
	natureVoyage: NatureVoyage;
	montantUnitaireModifiable: boolean;
	libelleField1: string;
	field1Obligatoire: boolean;
	libelleField2: string;
	field2Obligatoire: boolean;
	genre: Genre;
	modeParticipant: ModeParticipants;
	idAppTva: number;

	/** Trajets personnels */
	saveTrajet: boolean;

	/** Gestion des étapes */
	gestionEtapes: boolean;

	/** Géolocatlisation */
	geolocalisation: boolean;

	/** Etapes personnelles */
	etapesPersonnelles: boolean;

	/** Gestion des heures de la tournée */
	gestionTempsGlobale: boolean;

	/** Gestion des heures sur chaque étape de la tournée */
	gestionTempsDetaillee: boolean;

	/* Transient */
	dateApplication: Date;
	quantiteOrigineCumulIndemnites: number;
	quantiteCumulIndemnites: number;
	montantCumulIndemnites: number;
	listeIndemnitesLinkedToOd: Array<Ij>;
	famille: Referentiel;
	vehicule: Vehicule;

	getKey(): number {
		return this.idPrestation;
	}
}

/**
 * Enumération des types de prestation
 */
export enum TypePrestation {
	/** Enumération **/
	REEL = 0,
	PLAFOND = 1,
	FORFAIT = 2
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace TypePrestation {

	/**
	 * Fonction de traduction des libellés de l'énuméré
	 *
	 * @param typePrestation Le type de prestation à traduire
	 */
	export function traduction(typePrestation: TypePrestation): string {
		switch (typePrestation) {
			case this.REEL:
				return 'ndf.prestation.typeRemboursement.REEL';
			case this.PLAFOND:
				return 'ndf.prestation.typeRemboursement.PLAFOND';
			case this.FORFAIT:
				return 'ndf.prestation.typeRemboursement.FORFAIT';
			default:
				return '';
		}
	}

	/**
	 * Retourne le libellé du type de prestation
	 *
	 * @param prestationType
	 */
	export function traductionShort(prestationType: number): string {
		switch (prestationType) {
			case TypePrestation.FORFAIT:
				return "ndf.prestation.typeRemboursement.REEL";
			case TypePrestation.REEL:
				return "ndf.prestation.typeRemboursement.forfaitShort";
			case TypePrestation.PLAFOND:
				return "ndf.prestation.typeRemboursement.plafondShort";
			default:
				return '';
		}
	}

}

/**
 * Réceptions - Usage
 * Si "Réception", autorise la gestion des invités sur les NDF
 */
export enum Genre {
	STANDARD = ' ',
	RECEPTION = 'R',
}

/**
 * Mode de gestion des participants
 */
export enum ModeParticipants {
	TOUS = 0,
	INTERNES = 1,
	EXTERNES = 2,
}