<div class="with-floating-button">
    <div class="content" *ngIf="devise">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.devises.infosDevise.title' | translate:{ libelle: devise.libelle }" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.devises.infosDevise.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.GENERALITES">
            <form #form="ngForm" *ngIf="devise">
                <devise-infos-generalites [devise]="devise" [isCreation]="isCreation"></devise-infos-generalites>
                <devise-gestion-pays [devise]="devise" [listePays]="listePays"></devise-gestion-pays>
                <devise-gestion-avances [devise]="devise"></devise-gestion-avances>
            </form>
        </div>

        <div *ngIf="!isCreation" [hidden]="selectedItem?.code !== Onglet.TAUX">
            <devise-taux [codeDevise]="devise.code"></devise-taux>
        </div>
    </div>
    <mat-spinner diameter="20" *ngIf="!devise"></mat-spinner>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>
