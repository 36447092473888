import {Injectable} from "@angular/core";
import {HttpClient,HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Langue} from "@domain/admin/bibliotheque/internationalisation/langue";
import {LocaleService} from '@services/admin/langue/locale.service';
import {TranslateService} from "@ngx-translate/core";
import {SessionStorageService} from "@domain/common/services/session-storage.service";

/**
 * Service de gestion des Langues
 */
@Injectable()
export class LangueService {

    /** Constantes */
    private static SERVICE_STORAGE_KEY = 'LangueService';
    private static WAITING_LANGUE_STORAGE_KEY = 'waitingLangue';

    /**
     * Constructeur
     */
    constructor(private http: HttpClient,private localeService: LocaleService,private translateService: TranslateService,private sessionStorageService: SessionStorageService) {
    }

    /**
     * Récupération de la langue de l'utilisateur connecté
     *
     * @param isReload      blocage temporaire du rechargement si false (true par défaut)
     */
    setUserLangue(isReload: boolean = true): void {
        this.http.get<Result>(`${environment.baseUrl}/controller/Traduction/getUserLangue`).pipe(first()).subscribe((result: Result) => {
            //Si l'utilisateur a une langue associée
            if (result.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.langue) {
                //Application de la langue de l'utilisateur
                this.localeService.setLocale(result.data.langue.code,result.data.langue.codeVariant,isReload);

                //Suppression de la langue en attente
                this.sessionStorageService.remove(LangueService.SERVICE_STORAGE_KEY,LangueService.WAITING_LANGUE_STORAGE_KEY);
            }
        });
    }

    /**
     * Paramétrage de la langue en attente
     */
    setWaitingLangue(codeLangue: string): void {
        //En cas de divergence des langues
        if (codeLangue != this.localeService.getCodeLangueActive()) {
            //Sauvegarde la nouvelle langue en attente
            this.sessionStorageService.save(LangueService.SERVICE_STORAGE_KEY,LangueService.WAITING_LANGUE_STORAGE_KEY,codeLangue);
        } else {
            //Sinon purge de la langue en attente
            this.sessionStorageService.remove(LangueService.SERVICE_STORAGE_KEY,LangueService.WAITING_LANGUE_STORAGE_KEY);
        }
    }

    /**
     * Récupération de la langue en attente
     */
    getWaitingLangue(): string | null {
        return this.sessionStorageService.fetch(LangueService.SERVICE_STORAGE_KEY,LangueService.WAITING_LANGUE_STORAGE_KEY)?.replace(/"/g, "");
    }

    /**
     * Récupération de la liste des variants
     */
    getListeVariant(): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Traduction/listeVariant`).pipe(first());
    }

    /**
     * Récupération de la langue de l'entreprise
     */
    initLocale() {
        let codeLangue: string;
        let variant: string;

        //Locale par défaut
        this.translateService.setDefaultLang('fr');

        //Initialisation de la locale en fonction de celle définie dans la configuration
        return new Promise<void>((resolve) => {
            this.http.get<Result>(`${environment.baseUrl}/controller/Traduction/getEntrepriseLangue`).subscribe((result: Result) => {
                //Si l'entreprise a une langue associée
                if (result?.codeErreur == TypeCodeErreur.NO_ERROR && result.data?.langueEntreprise) {
                    //Langue de l'entreprise
                    codeLangue = result.data.langueEntreprise.code;
                    variant = result.data.langueEntreprise.codeVariant;
                } else {
                    //Fallback
                    codeLangue = navigator.language?.substring(0,2) || 'fr';
                    variant = navigator.language?.substring(0,2) || 'fr';
                }

                //Initialisation de la locale
                this.localeService.initLocale(codeLangue,variant);

                //On force la récupération des traductions, ainsi le splashcreen reste affiché tant qu'elles n'ont pas été récupérées
                this.translateService.getTranslation(codeLangue).subscribe(() => {
                    resolve();
                });
            }, () => {
                //Fallback
                this.localeService.initLocale(navigator.language?.substring(0,2) || 'fr',navigator.language?.substring(0,2) || 'fr');

                //Langue chargée : résolution de la promesse pour continuer le chargement de l'application
                resolve();
            });
        });
    }

    /**
     * Récupération d'une langue à l'aide de son code
     */
    getLangueByCode(code: string): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Traduction/loadLangue/${code}`).pipe(first());
    }

    /**
     * Enregistrement ou création d'une langue
     *
     * @param langue        langue à enregistrer en base
     * @param file          fichier associé
     * @param isCustom      true si custom, sinon false
     */
    saveLangue(langue: Langue, file: File, isCustom: boolean): Observable<Result> {
        //Initialisation
        const formData: FormData = new FormData();

        //Ajout du fichier
        formData.append('langue', new Blob([JSON.stringify(langue)],{type: "application/json"}));
        formData.append('file', file);
        formData.append('isCustom', isCustom.toString());

        //Upload du fichier
        return this.http.post<Result>(`${environment.baseUrl}/controller/Traduction/${ (langue.code == "fr" && !isCustom) ? "saveCleTraduction" : "saveLangue" }`, formData).pipe(first());
    }

    /**
     * Suppression d'une langue
     *
     * @param codeLangue    code de la langue à supprimer
     */
    deleteLangue(codeLangue: string): Observable<Result> {
        //Suppression
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Traduction/deleteLangue/${codeLangue}`).pipe(first());
    }

    /**
     * Mise à jour des labels personnalisés
     *
     * @param codeLangue            code de la langue concernée
     * @param cle                   la clé à traduire
     * @param valeur                la valeur associée
     */
    saveTraduction(codeLangue: string, cle: string, valeur: string): Observable<Result> {
        //Initialisation
        let params: HttpParams = new HttpParams();

        //Ajout des valeurs
        params = params.append('cle', cle);
        params = params.append('valeur', valeur);

        //Sauvegarde
        return this.http.put<Result>(`${environment.baseUrl}/controller/Traduction/saveTraductionCustom/${ codeLangue }`,null, {
            params: params
        }).pipe(first());
    }
}
