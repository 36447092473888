import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Etablissement} from "../../../../domain/lieu/etablissement";

/**
 * Composant gérant l'affichage de la liste des établissements
 */
@Component({
    selector: 'etablissement-list',
    host: {'data-test-id':'etablissement-list'},
    templateUrl: './lieu-etablissement-list.component.html',
})
export class LieuEtablissementListComponent {

    /** Event émis lors de la sélection d'une adresse */
    @Output() onEtablissementSelected = new EventEmitter<Etablissement>();

    /** Liste des établissements */
    @Input() listeEtablissement: Array<Etablissement>;

    /**
     * Selection d'un établissement dans la liste
     *
     * @param etab établissement
     */
    selectEtablissement(etab: Etablissement): void {
        this.onEtablissementSelected.emit(etab);
    }

}