<!-- Affichage du cadre de récapitulatif des montants -->
<od-engagements-card [title]="'od.engagements.recapitulatif.title' | translate"
                     [engagements]="engagements"
                     [avatar]="avatar"
                     [previsionnel]="previsionnel"
                     [reel]="reel"></od-engagements-card>

<!-- Template du prévisionnel -->
<ng-template #previsionnel let-engagement="engagement" let-isTotal="isTotal">
    <div class="lvi-content col-6">
        <ng-template *ngTemplateOutlet="avatar;context:{engagement:engagement,isPrevisionnel:true,isTotal:isTotal}"></ng-template>
        <div class="lvi-body">
            <div [ngClass]="{'d-flex flex-row' : isTotal}">
                <div class="title">
                    <a>{{ engagement?.getTraduction(true) | translate }}</a>
                    <span *ngIf="isTotal" class="material-icons"
                          [nioTooltip]="('od.engagements.recapitulatif.tips.previsionnel' | translate) + (od?.deviseTravel ? ('<br>' + ('od.engagements.recapitulatif.tips.previsionnelTravel' | translate:{devise:od?.deviseTravel})) : '')"
                    >info</span>
                </div>
                <ul *ngIf="isTotal" class="lvi-attrs mt-0 ml-3">
                    <li class="m-0" [ngClass]="{'back-warning':isIndemnitesAndMontantPrevNotEqualsMontantOrigine(engagement)}">
                        <strong [translate]="'od.engagements.total'"></strong>
                        <span>{{ engagement?.montantPrevisionnel | montant:settings?.deviseEntreprise }}</span>
                        <mat-icon *ngIf="isIndemnitesAndMontantPrevNotEqualsMontantOrigine(engagement)"
                                  [nioTooltip]="'od.engagements.recapitulatif.tips.degrevementEffectue' | translate">error_outline</mat-icon>
                    </li>
                </ul>
            </div>
            <!-- Bulles -->
            <ul class="lvi-attrs">
                <li *ngIf="!isTotal" [ngClass]="{'back-warning':isIndemnitesAndMontantPrevNotEqualsMontantOrigine(engagement)}">
                    <strong [translate]="'od.engagements.total'"></strong>
                    <span>{{ engagement?.montantPrevisionnel | montant:settings?.deviseEntreprise }}</span>
                    <mat-icon *ngIf="isIndemnitesAndMontantPrevNotEqualsMontantOrigine(engagement)"
                              [nioTooltip]="'od.engagements.recapitulatif.tips.degrevementEffectue' | translate">error_outline</mat-icon>
                </li>
                <!-- Bulle spéciale affichant le montant des avances sur la ligne du total prévisionnel -->
                <li *ngIf="isTotal && !!avances">
                    <strong [translate]="'od.engagements.recapitulatif.avances'"></strong>
                    <span>{{ avances.montantPrevisionnel | montant:settings?.deviseEntreprise }}</span>
                    <mat-icon [nioTooltip]="'od.engagements.recapitulatif.tips.pourcentageAvance' | translate:{'pourcentage':engagements.pourcentageAvance }">error_outline</mat-icon>
                </li>
            </ul>
        </div>
    </div>
</ng-template>

<!-- Template du réel -->
<ng-template #reel let-engagement="engagement" let-isTotal=isTotal>
    <div class="lvi-content col-6" [ngClass]="{'pl-4':!isTotal}">
        <ng-template *ngTemplateOutlet="avatar;context:{engagement:engagement,isPrevisionnel:false,isTotal:isTotal}"></ng-template>
        <div class="lvi-body">
            <div [ngClass]="{'d-flex flex-row' : isTotal}">
                <div class="title">
                    <a>{{ engagement?.getTraduction() | translate }}</a>
                    <span *ngIf="isTotal" class="material-icons"
                          [nioTooltip]="'od.engagements.recapitulatif.tips.reel' | translate">info</span>
                </div>
                <ul class="lvi-attrs mt-0 ml-3" *ngIf="isTotal">
                    <li class="m-0"><strong [translate]="'od.engagements.total'"></strong>{{ engagement?.montantReel | montant:settings?.deviseEntreprise }}</li>
                </ul>
            </div>
            <!-- Bulles -->
            <ul class="lvi-attrs">
                <li *ngIf="!isTotal"><strong [translate]="'od.engagements.total'"></strong>{{ engagement?.montantReel | montant:settings?.deviseEntreprise }}</li>
                <li><strong [translate]="'od.engagements.ecart'"></strong>{{ engagement?.pourcentageEcart | number:'1.2-2' }} %</li>
            </ul>
        </div>
        <div class="lvi-actions" *ngIf="isTotal">
            <button mat-icon-button>
                <mat-icon *ngIf="isOuvert" color="primary">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!isOuvert" color="primary">keyboard_arrow_down</mat-icon>
            </button>
        </div>
    </div>
</ng-template>

<!-- Template de l'avatar -->
<ng-template #avatar let-engagement="engagement" let-isPrevisionnel="isPrevisionnel" let-isTotal="isTotal">
    <div class="avatar" [nioTooltip]="engagement?.getTraduction(isPrevisionnel) | translate" nioTooltipPosition="right">
        <!-- Icône en fonction du type -->
        <ng-container *ngIf="!isTotal" [ngSwitch]="engagement?.getType()">
            <i *ngSwitchCase="TypeEngagement.INDEMNITES" [ngClass]="'icon-frais' + (isPrevisionnel ? '_previsionnel_2' : '') " class="nio align-middle"></i>
            <i *ngSwitchCase="TypeEngagement.FRAIS" [ngClass]="'icon-frais' + (isPrevisionnel ? '_previsionnel_2' : '')" class="nio align-middle"></i>
            <i *ngSwitchCase="TypeEngagement.FACTURES" [ngClass]="'icon-facture' + (isPrevisionnel ? '_previsionnelle' : '')" class="nio align-middle"></i>
        </ng-container>
        <span *ngIf="isTotal" class="align-middle" [translate]="'od.engagements.' + (isPrevisionnel ? 'previsionnelAvatar' : 'reelAvatar')"></span>
    </div>
</ng-template>