import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputNumberDirective } from './input-number.directive';

@NgModule({
    imports: [],
    declarations: [InputNumberDirective],
    exports: [InputNumberDirective],
    providers: [DecimalPipe]
})
export class InputNumberModule {}