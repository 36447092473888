import {Component,OnDestroy} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {LoginService} from "@share/login/login.service";
import {NgForm} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";

/**
 * Composant de popup pour la saisie du mot de passe admin
 */
@Component({
    host: {'data-test-id': 'admin-password-popup'},
    templateUrl: './admin-password-popup.component.html'
})
export class AdminPasswordPopupComponent implements OnDestroy {
    /** Mot de passe saisi */
    typedPassword: string;

    /** Chargement en cours */
    isLoading: boolean = false;

    /** Souscription */
    souscription: Subscription;

    /**
     * Constructeur
     */
    constructor(
        protected matDialogRef: MatDialogRef<AdminPasswordPopupComponent>,
        protected translateService: TranslateService,
        private loginService: LoginService,
        private toastr: ToastrService
    ) {}

    /**
     * Vérification du mot de passe
     *
     * @param form formulaire
     */
    checkPassword(form: NgForm): void  {
        //Traitement en cours
        this.isLoading = true;

        //On vérifie le mdp saisi
        this.souscription = this.loginService.checkPassword(this.typedPassword).subscribe({
            next: (result: boolean) => {
                if (result) {
                    //Fermeture de la popup, et envoi d'un succès
                    this.matDialogRef.close({ success: true });
                } else {
                    //Notification de l'erreur de mot de passe
                    this.toastr.error(this.translateService.instant('login.passwordChange.error.passwordError'));

                    //Réinitialisation du formulaire
                    form.resetForm();
                }
            },
            error: () => {
                //Fin de traitement
                this.isLoading = false;
            },
            complete: () => {
                //Fin de traitement
                this.isLoading = false;
            }
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement
        this.souscription?.unsubscribe();
    }
}
