<!-- Cadre "Paramètres de connexion" -->
<param-connexion #paramConnexionComponent
    [sbtConfigUsed]="sbtConfigUsed"
    [listeParamConnexion]="listeParamConnexion"
    [listeParamConnexionUsed]="listeParamConnexionUsed"
    [listeValeurParamConnexion]="listeValeurParamConnexion"
></param-connexion>

<!-- Cadre "CustomFields" -->
<field #customFieldUsedFieldComponent
        [sbtConfigUsed]="sbtConfigUsed"
        [listeField]="listeCustomField"
        [listeFieldUsed]="listeCustomFieldUsed"
        [type]="CustomFieldUsed"
></field>

<!-- Cadre "TripFields" -->
<field #tripFieldUsedFieldComponent
        [sbtConfigUsed]="sbtConfigUsed"
        [listeField]="listeTripField"
        [listeFieldUsed]="listeTripFieldUsed"
        [type]="TripFieldUsed"
></field>
