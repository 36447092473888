import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from '@ngx-translate/core';

import {MatDividerModule} from "@angular/material/divider";
import {MenuDropdownComponent} from "./menu-dropdown.component";
import {MenuDropdownDirective} from "./menu-dropdown.directive";
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [CommonModule,TranslateModule,MatDividerModule,RouterModule],
    declarations: [MenuDropdownComponent,MenuDropdownDirective],
    providers: [MenuDropdownDirective],
    exports: [MenuDropdownComponent,MenuDropdownDirective]
})
export class MenuDropdownModule {}
