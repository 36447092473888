import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {Axe5DTO} from "@domain/admin/entreprise/analytique/axe5Dto";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Composant d'affichage d'un référentiel Axe 5 dans la liste de tous les référentiels
 */
@Component({
	host: {'data-test-id': 'analytique-axe5-item'},
	selector: 'analytique-axe5-item',
	templateUrl: './analytique-axe5-item.component.html'
})
export class AnalytiqueAxe5ItemComponent extends ListViewItem<Axe5DTO> {
	/**
	 * Constructeur
	 *
	 * @param router Router de l'application
	 */
	constructor(
		private router: Router
	) {
		super();
	}

	/**
	 * Méthode appelée lors de la sélection d'un référentiel Axe 5
	 */
	onSelect() {
		//Navigation vers la page d'informations du référentiel
		this.router.navigate(['Admin/Entreprise/Analytique/Axe5/' + this.data.idAxe5]);
	}
}