import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";
import {ExerciceDto} from "@domain/admin/entreprise/analytique/exerciceDto";
import {TypeBudget} from "@domain/budget/type-budget.enum";

/**
 * Service pour le détail des enveloppes
 *
 * @author Tom Jeammet
 * @date 01/07/2023
 */
@Injectable()
export class EnveloppeDetailService {

    /**
     * Constructeur
     */
    constructor(
        private http: HttpClient,
    ) {
    }

    /**
     * Appel back pour récupérer la somme des imputations d'une enveloppe
     *
     * @param idEnveloppe l'ID de l'enveloppe
     * @param idMontant l'ID du montant utilisé
     * @param typeBudget le type de budget (réel=0 ou prévisionnel=1)
     */
    computeMontantTotalImpute(idEnveloppe: number,idMontant: number,typeBudget: TypeBudget): Observable<number> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/computeMontantTotalImpute?idEnveloppe=` + idEnveloppe + "&idMontant=" + idMontant + "&typeBudget=" + typeBudget,null).pipe(
            map(result => result?.data.montantTotalImpute)
        );
    }

    /**
     * Supprime l'analytique
     *
     * @param idAnalytique l'id de l'analytique
     */
    deleteAnalytique(idAnalytique: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/deleteAnalytique?idAnalytique=${ idAnalytique }`, null);
    }

    /**
     * Sauvegarde un montant
     *
     * @param montant le montant
     */
    saveMontant(montant: MontantDto): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/saveMontant`,montant);
    }

    /**
     * Supprime le montant
     */
    deleteMontant(idMontant: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/deleteMontant?idMontant=${idMontant}`,null);
    }

    /**
     * Récupère la liste des exercices
     */
    listeExercices(): Observable<ExerciceDto[]> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Budget/listeExercices`).pipe(
            map(result => result?.data.exercices)
        );
    }

    /**
     * Réaffecte le montant d'une enveloppe
     * @param idMontantSource id du montant initial
     * @param idMontantDestination id du montant cible
     * @param montant montant
     */
    reaffecter(idMontantSource: number, idMontantDestination: number, montant: number): Observable<Result> {
        return this.http.post<Result>(
            `${environment.baseUrl}/controller/Budget/doReaffectation?idMontantSource=${idMontantSource}&idMontantDestination=${idMontantDestination}&montant=${montant}`,null);
    }
}