import {NgModule} from '@angular/core';
import {ShareModule} from "@share/share.module";
import {VoyagesFraisAgenceComponent} from './voyages-frais-agence.component';
import {FraisAgenceListComponent} from './frais-agence-list/frais-agence-list.component';
import {FraisAgenceListItemComponent} from './frais-agence-list/list-item/frais-agence-list-item.component';
import {VoyagesFraisAgenceService} from "@services/admin/voyages/voyages-frais-agence.service";
import {ModifyFraisAgencePopinComponent} from './modify-frais-agence-popin/modify-frais-agence-popin.component';

/**
 * Module du menu admin : Voyages > Frais d'agence
 */
@NgModule({
	declarations: [
		VoyagesFraisAgenceComponent,
		FraisAgenceListComponent,
		FraisAgenceListItemComponent,
		ModifyFraisAgencePopinComponent
	],
	imports: [
		ShareModule
	],
	providers: [
		VoyagesFraisAgenceService
	]
})
export class VoyagesFraisAgenceModule {
}