import {AbstractField} from "@domain/travel/abstract-field";

/**
 * Représente le paramétrage d'un custom field
 *
 * @author Laurent Convert
 * @date 20/11/2023
 */
export class CustomField extends AbstractField {
	idCustomField: number;

	constructor(dto?: CustomField | any) {
		super(dto);
	}
}