import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from "@angular/forms";
import {Compte, OptionTypeCompte, TypeCompte} from "@domain/admin/comptabilite/compte";
import {TranslateService} from "@ngx-translate/core";
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {Field, FieldContainer} from "@domain/settings/field";

/**
 * Onglet Généralités du détail d'un compte comptable du menu Comptablité / Plan Comptable
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'compte-comptable-generalites'},
    selector: 'compte-comptable-generalites',
    templateUrl: './compte-comptable-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class CompteComptableGeneralitesComponent implements OnInit {

    /** Compte à afficher */
    @Input() compte: Compte;

    /** Liste de tous les types de compte possibles */
    listeTypes: OptionTypeCompte[];

    /** Champs attribut */
    @Input() listeFieldParam: Field[];

    /** Wrapper pour les champs attribut */
    @Input() fieldContainer: FieldContainer;

    /**
     * Constructeur
     *
     * @param translateService Service des traductions
     * @param planComptableService Service du plan comptable
     */
    constructor(private translateService: TranslateService,
                private planComptableService: ComptabilitePlanComptableService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des types de comptes possibles pour le select
        this.listeTypes = [{
            id: TypeCompte.NON_DEFINI,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.nonDef')
        }, {
            id: TypeCompte.COMPTE_TIERS,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.tiers')
        }, {
            id: TypeCompte.COMPTE_TAXE,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.taxe')
        }, {
            id: TypeCompte.COMPTE_BANQUE,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.banque')
        }, {
            id: TypeCompte.COMPTE_CHARGE,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.charge')
        }, {
            id: TypeCompte.PERTE_GAIN_CHANGE,
            libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.change')
        }];

        //Récupération de la configuration des champs 'attribut'
        this.planComptableService.getListeFieldParam().subscribe(result => {
            this.listeFieldParam = result.data.listeFieldParam;
            this.listeFieldParam.forEach(fieldParam => {
                if (!fieldParam.masque) {
                    fieldParam.masque = '';
                }
            });
        });
    }

}
