import {Injectable} from '@angular/core';

import {Analytique} from './analytique';
import {SettingsGlobalState} from "../../domain/settings/settings";
import {TypeEntite} from "../../domain/typeentite/typeEntite";
import {PatternService} from "../../share/component/pattern/pattern.service";
import {Field} from "../../domain/settings/field";
import {Alerte,NiveauAlerte} from "../../domain/common/alerte/alerte";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from 'rxjs';
import {HttpClient,HttpParams} from '@angular/common/http';
import {first} from "rxjs/operators";
import {Result} from "@domain/common/http/result";

@Injectable()
export class AnalytiqueService {

    constructor(private patternService: PatternService,private translateService: TranslateService,private http: HttpClient) {
    }

    /**
     * Vérification de la validité d'une répartition analytique
     */
    public isValid(listeAnalytiques: Array<Analytique>,typeEntite: TypeEntite,settings: SettingsGlobalState): boolean {
        let isValid: boolean;
        let ope1: Field;
        let ope2: Field;

        //Récupération des champs complémentaires (actifs) de l'analytique
        ope1 = this.getFieldParam(settings,'OPERATION_1');
        ope2 = this.getFieldParam(settings,'OPERATION_2');

        //Vérification de la répartition complète
        isValid = listeAnalytiques?.reduce((total,analytique) => total + (Number(analytique.pourcentage) || 0),0) == 100
            && !listeAnalytiques?.some(analytique => Number(analytique.pourcentage) == 0);

        //Vérification du nombre de répartitions maximum autorisées (si 0, alors illimité et donc valide)
        isValid = isValid && (!settings?.nbRepartitionsMax || listeAnalytiques?.length <= settings?.nbRepartitionsMax);

        //Vérification de la saisie des champs obligatoires
        listeAnalytiques?.forEach(analytique => {
            //Vérification de la saisie de l'organisme
            isValid = isValid && !!analytique.orga;

            //Vérification de la saisie du code projet (dossier)
            isValid = isValid && (!typeEntite.codeProjetObligatoire || !!analytique.dossier);

            //Vérification de la saisie de l'axe 5
            isValid = isValid && (!typeEntite.axe5Obligatoire || !!analytique.axe5);

            //Vérification de la saisie de l'axe 6
            isValid = isValid && (!typeEntite.axe6Obligatoire || !!analytique.axe6);

            //Vérification de la saisie du champ ope 1 si activé
            if (isValid && typeEntite.field5) {
                //Pas de valeur saisie
                if (!analytique.code1) {
                    //On vérifie si le champ est facultatif
                    isValid = !typeEntite.field5Obligatoire;
                } else if (ope1) {
                    //Si une valeur est saisie, vérification du pattern
                    isValid = new RegExp(this.patternService.getPatternForField(ope1)).test(analytique.code1);
                }
            }

            //Vérification de la saisie du champ ope 2 si activé
            if (isValid && typeEntite.field6) {
                //Pas de valeur saisie
                if (!analytique.code2) {
                    //On vérifie si le champ est facultatif
                    isValid = !typeEntite.field6Obligatoire;
                } else if (ope2) {
                    //Si une valeur est saisie, vérification du pattern
                    isValid = new RegExp(this.patternService.getPatternForField(ope2)).test(analytique.code2);
                }
            }
        });

        //Vérification de l'unicité de chaque répartition
        isValid = isValid && listeAnalytiques?.length === listeAnalytiques?.map(analytique => AnalytiqueService.repartitionToString(analytique))
            .filter((elt,idx,array) => array.indexOf(elt) === idx)
            .length;

        return isValid;
    }

    /**
     * Retourne une alerte "hardcodée" à afficher dans le page header lorsque l'analytique est invalide
     */
    buildAlerteAnalytiqueInvalid(): Alerte {
        return new Alerte({
            niveau: NiveauAlerte.ERROR,
            titre: this.translateService.instant('analytique.alerte.saisieInvalide.title'),
            message: this.translateService.instant('analytique.alerte.saisieInvalide.message'),
            action: () => {
                window.document.getElementById('blocAnalytique').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            }
        });
    }

    /**
     * Vérifie si une ligne de répartition analytique existe déjà dans une liste
     *
     * @param listeAnalytiques La liste des répartitions existantes
     * @param ligne La ligne de répartition à vérifier
     */
    isRepartitionAnalytiqueExists(listeAnalytiques: Array<Analytique>, ligne: Analytique) {
        return listeAnalytiques?.some(analytique => this.isEquals(analytique,ligne));
    }

    /**
     * Comparateur entre 2 répartitions analytiques
     *
     * @param a1 Répartition 1 à comparer
     * @param a2 Répartition 2 à comparer
     */
    private isEquals(a1: Analytique,a2: Analytique): boolean {
        return a1.orga?.idOrga == a2.orga?.idOrga
            && a1.dossier?.idDossier == a2.dossier?.idDossier
            && a1.axe5?.idAxe5 == a2.axe5?.idAxe5
            && a1.axe6?.idAxe6 == a2.axe6?.idAxe6;
    }


    /**
     * Transforme une répartition analytique en chaine à partir de ses axes
     *
     * @param analytique La répartition analytique
     */
    private static repartitionToString(analytique: Analytique): string {
        return [analytique.orga?.idOrga,analytique.dossier?.idDossier,analytique.axe5?.idAxe5,analytique.axe6?.idAxe6].join(';');
    }

    /**
     * Retourne le champ complémentaire configuré dans l'analytique à partir de son code
     *
     * @param settings Les paramètres
     * @param code Le code du champ
     */
    getFieldParam(settings: SettingsGlobalState,code: string): Field | undefined {
        //Récupération du champ
        return settings.listeFieldParams?.find(p => p.champ == code);
    }
    
    /**
     * Retourne les préférences analytiques par défaut à la date courante d'un utilisateur pour un type entité donné.
     *
     * @param idTypeEntite Identifiant du type entité
     * @param idUser Identifiant de l'utilisateur
     */
    getPreferenceAnalytique(idTypeEntite:number,idUser:number): Observable<Result> {
        //Construction des paramètres HTTP pour la récupération des préférences analytiques
        const params: HttpParams = new HttpParams()
            .set('idTypeEntite',idTypeEntite.toString(10))
            .set('idCollab',idUser.toString(10))
        
        return this.http.post<Result>(`/controller/PreferencesAnalytiques/getPreferenceAnalytiqueParDefaut`,null, {params: params})
            .pipe(first());
    }

    /**
     * Retourne l'orga lié à l'objet selon le paramétrage globale et de l'utilisateur
     * @param idUserObjet id de l'utilisateur
     */
    getOrgaParDefaut(idUserObjet: number): Observable<Result> {
        return this.http.get<Result>(`/controller/PreferencesAnalytiques/getOrgaParDefaut/${idUserObjet}`).pipe(first());
    }
}