import { NgModule } from '@angular/core';
import { ShareModule } from "@share/share.module";
import { GestionLogsComponent } from "./gestion-logs.component";
import { ServeurCentralisationComponent } from "./serveur-centralisation/serveur-centralisation.component";
import { FichierLogsComponent } from "./fichier-logs/fichier-logs.component";
import { LogsService } from "@services/admin/logs/logs.service";

@NgModule({
    imports: [ShareModule],
    declarations: [GestionLogsComponent,ServeurCentralisationComponent,FichierLogsComponent],
    exports: [GestionLogsComponent,ServeurCentralisationComponent,FichierLogsComponent],
    providers: [LogsService]
})
export class GestionLogsModule {
}
