import {Component} from '@angular/core';
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ToastrService} from "ngx-toastr";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {EntrepriseVehiculesService} from "@components/admin/entreprise/vehicules/entreprise-vehicules.service";
import {EnergieFiscale} from "@domain/admin/entreprise/vehicules/energieFiscale";

/**
 * Item de la liste des énergies
 */
@Component({
    host: {'data-test-id': 'energie-list-item'},
    templateUrl: './energie-list-item.component.html'
})
export class EnergieListItemComponent extends ListViewItem<EnergieFiscale> {
    /** Surcharge pour typage des options */
    extraOptions: { openEnergie: (energie?: EnergieFiscale) => void };

    /**
     * Constructeur
     *
     * @param confirmService Service de confirmation
     * @param translateService Service des traductions
     * @param pleaseWaitService Service d'affichage du loading
     * @param vehiculeService Service des véhicules
     * @param toastrService Service des toasts
     */
    constructor(private confirmService: ConfirmService,
                private translateService: TranslateService,
                private pleaseWaitService: PleaseWaitService,
                private vehiculeService: EntrepriseVehiculesService,
                private toastrService: ToastrService) {
        super();
    }

    /**
     * Suppression de l'énergie
     */
    deleteEnergie(): void {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
                //Ouverture de la modale de chargement
                let waitDialogRef = this.pleaseWaitService.show();

                //Suppression puis fermeture de la modale de chargement
                this.vehiculeService.deleteEnergie(this.data.idEnergie)
                    .pipe(first(), finalize(() => waitDialogRef.close()))
                    .subscribe((result: Result) => {
                        if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Message d'information
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Rechargement de la liste
                            this.liste.refresh();
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    });
            });
    }
}
