/**
 * Enumération des types de géographie
 */
export enum TypeGeographie {
    NON_DEFINI = 'NON_DEFINI',
    ZONE = 'ZONE',
    PAYS = 'PAYS',
    REGION = 'REGION',
    REGION_ADMINISTRATIVE = 'REGION_ADMINISTRATIVE',
    DEPARTEMENT_ADMINISTRATIF = 'DEPARTEMENT_ADMINISTRATIF',
    VILLE = 'VILLE',
    LOCALISATION = 'LOCALISATION',
    GARE = 'GARE',
    AEROPORT = 'AEROPORT'
}