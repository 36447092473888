<h1 mat-dialog-title>
    <span [translate]="'rule.businessDataPopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div *ngIf="businessData">
        <ul class="breadcrumb" *ngIf="listeOpenedEntities.length > 0">
            <li *ngFor="let entite of listeOpenedEntities; let index = index; let last = last">
                <a *ngIf="!last" (click)="goBackToEntity(index + 1)">{{ entite.libelle }}</a>
                <span *ngIf="last">{{ entite.libelle }}</span>
            </li>
        </ul>
        <div class="scrollable">
            <ul class="list-group" *ngIf="listeOpenedEntities.length > 0">
                <li class="list-group-item" *ngFor="let membre of getListeMembres()" (click)="selectItem(membre)">
                    <span class="c-primary">{{ membre.libelle }}</span>
                    <mat-icon class="pull-right c-primary" *ngIf="membre.enclosedWrapper">chevron_right</mat-icon>
                    <mat-icon class="pull-right c-primary" *ngIf="membre.collection">arrow_drop_down</mat-icon>
                </li>
            </ul>
        </div>
    </div>
</div>