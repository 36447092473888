<mat-card>
    <mat-card-title>
        <span [translate]="'profilUser.cgu.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div [innerHTML]="cgu" class="inner-html"></div>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="infoCgu">
    <mat-card-title>
        <span [translate]="'profilUser.cgu.accept.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <!-- Validation des CGU par l'utilisateur lui même -->
        <div *ngIf="!infoCgu.cguDisabledByParam && infoCgu.userIsValidator"
             [translate]="'profilUser.cgu.accept.text'"
             [translateParams]="{date: infoCgu.dateAcceptCgu | date: 'shortDate'}"></div>
        <!-- Validation des CGU par un autre utilisateur -->
        <div *ngIf="!infoCgu.cguDisabledByParam && !infoCgu.userIsValidator && !infoCgu.adminIsValidator"
             [translate]="'profilUser.cgu.accept.textOther'"
             [translateParams]="{ nom: infoCgu.nomAcceptCgu, date: infoCgu.dateAcceptCgu | date: 'shortDate'}"></div>
        <!-- Validation des CGU par l'administrateur -->
        <div *ngIf="!infoCgu.cguDisabledByParam && infoCgu.adminIsValidator"
             [translate]="'profilUser.cgu.accept.textAdmin'"
             [translateParams]="{ nom: infoCgu.nomAcceptCgu, date: infoCgu.dateAcceptCgu | date: 'shortDate'}"></div>
        <!-- CGU désactivées -->
        <div *ngIf="infoCgu.cguDisabledByParam" [translate]="'profilUser.cgu.accept.textEntreprise'"></div>
    </mat-card-content>
</mat-card>

<div align="end" class="pb-2" *ngIf="!infoCgu?.cguDisabledByParam">
    <button (click)="refuseCgu()" class="mr-2" color="warn" mat-stroked-button>
        <span translate>global.actions.refuser</span>
    </button>
    <button (click)="acceptCgu()" color="primary" mat-flat-button>
        <span translate>global.actions.accepter</span>
    </button>
</div>
