<mat-card *ngIf="appInfos">
    <mat-card-title>
        <span [translate]="'profilUser.aPropos.version'" [translateParams]="{application: appInfos?.application, build: appInfos?.build}"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="row padded">
            <a href="https://www.cegid.com/fr/produits/cegid-notilus/secteur-public/" target="_blank" class="col-md-6 logo-container" [matTooltip]="'https://www.cegid.com/fr/produits/cegid-notilus/secteur-public/'">
                <div class="a-propos-logo produit"></div>
            </a>
            <a href="https://www.cegid.com/" target="_blank" class="col-md-6 logo-container" [matTooltip]="'https://www.cegid.com/'">
                <div class="a-propos-logo editeur"></div>
            </a>
        </div>
        <div class="row">
            <div class="col-md-12 mention">
                <span [translate]="'profilUser.aPropos.mention1'"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mention">
                <span [translate]="'profilUser.aPropos.mention2'"></span>
            </div>
        </div>
    </mat-card-content>
</mat-card>