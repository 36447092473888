import {ListeAlertes} from "../common/alerte/listeAlertes";
import {ListItem} from "../common/list-view/list-item";
import {User} from "../user/user";
import {Statut} from "../workflow/statut";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {TypePortee} from "../workflow/workflow";
import {MapAction} from "../workflow/mapAction";
import {Pays} from "../geographie/pays";
import {TranslateService} from "@ngx-translate/core";

/**
 * Modèle représentant un OMP listé
 */
export class ListeOmp extends AbstractObjetWorkflow implements ListItem {
    idOMPermanent: number;
	objet: string;
	dateDebut: Date;
	dateFin: Date;
	ville: string;
    libelleVille: string;
	pays: Pays;
	lieu: string;
	typeEntite: string;
    statut: Statut;
    user: User;
	listeAlertes: ListeAlertes;
	listeHistoWorkflow: MapAction;
    nbChilds: number;

    constructor(dto: any) {
        super();
        if (dto) {
            this.idOMPermanent = dto.idOMPermanent;
            this.objet = dto.objet;
            if (dto.dateDebut) { this.dateDebut = new Date(dto.dateDebut) };
            if (dto.dateFin) { this.dateFin = new Date(dto.dateFin) };
            this.ville = dto.ville;
            this.libelleVille = dto.libelleVille;
            this.pays = dto.pays as Pays;
            this.lieu = dto.lieu;
            this.typeEntite = dto.typeEntite;
            this.statut = dto.statut as Statut;
            this.user = dto.user as User;
            this.listeAlertes = dto.listeAlertes as ListeAlertes;
            if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
            this.nbChilds = dto.nbChilds;
            this.masque = dto.masque;
        }
    }

    /** Indicateur d'affichage de l'élément */
    isDisplayed?: boolean;

    /** Indicateur de sélection de l'élément */
    isSelected?: boolean;

    /**
     * Getter pour l'affichage de l'endroit
     */
    get location(): string {
        if (this.libelleVille) { return this.libelleVille; }
        if (this.ville) { return this.ville; }
        if (this.lieu) { return this.lieu; }
        if (this.pays?.libelle) { return this.pays.libelle; }
        return null;
    }

    /**
     * Récupération de l'identifiant de l'objet
     * @returns l'identifiant de l'objet
     */
    getKey(): number {
        return this.idOMPermanent;
    }

    getId(): number {
        return this.idOMPermanent;
    }

    getPortee(): TypePortee {
        return TypePortee.OT;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        return (this.user.nom ? this.user.nom + ' ' : '')
                + (this.user.prenom ? this.user.prenom : '');
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.idOMPermanent || translateService.instant('omp.liste.numeroNonDefini')) + ' - ' + (this.objet || translateService.instant('omp.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return this.objet != null && this.location != null;
    }

    /**
     * Retourne True si l'objet à au moins une mission ou une ndf liée, False sinon.
     */
    hasChilds(): boolean {
        return this.nbChilds > 0;
    }
}