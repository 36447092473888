import {Component} from "@angular/core";
import {LienPopulationOrga} from "@domain/admin/entreprise/population/lienPopulationOrga";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ListItem} from "@domain/common/list-view/list-item";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {PopulationsService} from "@services/admin/entreprise/population.service";
import {ToastrService} from "ngx-toastr";
import {TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";
import {TypeAction} from "@domain/workflow/workflow";
import {filter} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant d'affichage d'un service dans la liste des services d'une population
 */
@Component({
    host: {'data-test-id': 'population-service-item'},
    templateUrl: './population-service-item.component.html'
})
export class PopulationServiceItemComponent extends ListViewItem<LienPopulationOrga> {
    /** Déclaration pour le template */
    TypeLien = TypeLien;
    TypeAction = TypeAction

    /**
     * Constructeur de la classe.
     *
     * @param populationsService Le service PopulationsService utilisé dans la classe pour gérer les populations.
     * @param translateService Le service de traduction utilisé dans la classe.
     * @param toastrService Le service ToastrService utilisé dans la classe pour afficher des messages de notification.
     * @param confirmService Le service de confirmation
     */
    constructor(
        private populationsService: PopulationsService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private confirmService: ConfirmService
    ) {
        super();
    }

    /**
     * Supprime l'organisme
     */
    deleteOrga(): void {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    this.populationsService.deleteOrga(this.data).subscribe(result => {
                        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                            (this.data as ListItem).removeFromListView();
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        } else {
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    })
                }
            });
    }
}