/**
 * Décrit une option (select) avec une icône
 *
 * @author Laurent Convert
 * @date 06/02/2023
 */
export class OptionWithIcon {
    icone?: string;
    libelle: string;
    value: any;
    
    constructor(option?: OptionWithIcon) {
        Object.assign(this,option);
    }
}