import {NgModule} from '@angular/core';

import {ShareModule} from '../../share/share.module';
import {LieuComponent} from './lieu.component';
import {LieuSelectionComponent} from './selection/lieu-selection.component';
import {LieuGeolocalisationListComponent} from './selection/geolocalisation/lieu-geolocalisation-list.component';
import {LieuService} from "./lieu.service";
import {LieuAdresseListComponent} from "./selection/adresse/lieu-adresse-list.component";
import {LieuEtablissementListComponent} from "./selection/etablissement/lieu-etablissement-list.component";
import {LieuLocalisationComponent} from "./selection/localisation/lieu-localisation.component";
import {LieuAdresseListItemComponent} from "./selection/adresse/lieu-adresse-list-item.component";

@NgModule({
    imports: [ShareModule],
    declarations: [LieuComponent,LieuSelectionComponent,LieuAdresseListComponent,LieuAdresseListItemComponent,LieuEtablissementListComponent,LieuLocalisationComponent,LieuGeolocalisationListComponent],
    exports: [LieuComponent,LieuSelectionComponent,LieuAdresseListComponent,LieuAdresseListItemComponent,LieuEtablissementListComponent,LieuLocalisationComponent,LieuGeolocalisationListComponent],
    providers: [LieuService]
})
export class LieuModule {}