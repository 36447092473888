/**
 * Enumération des types de frais
 *
 * @author Laurent Convert
 * @date 10/01/2022
 */
export enum OdTypeFrais {
    frais_prev = 'FRAIS_PREV',
    ndf = 'NDF',
    avance = 'AVANCE'
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace OdTypeFrais {

    /**
     * Retourne le code de traduction correspondant à un type de facture
     *
     * @param typeFrais Type de frais
     */
    export function traduction(typeFrais: OdTypeFrais) {
        switch (typeFrais) {
            case this.frais_prev: return 'od.frais.frais.liste.types.frais_prev';
            case this.ndf:        return 'od.frais.frais.liste.types.ndf';
            case this.avance:     return 'od.frais.frais.liste.types.avance';
        }
    }

    /**
     * Retourne le code de traduction pour la création d'une facture suivant son type
     *
     * @param typeFrais Type de frais
     */
    export function traductionForCreation(typeFrais: OdTypeFrais) {
        switch (typeFrais) {
            case this.frais_prev: return 'od.frais.frais.creation.choix.types.frais_prev';
            case this.ndf:        return 'od.frais.frais.creation.choix.types.ndf';
            case this.avance:     return 'od.frais.frais.creation.choix.types.avance';
        }
    }

    /**
     * Retourne l'icône qui correspondant à un type de facture
     *
     * @param typeFrais Type de frais
     */
    export function getIcone(typeFrais: OdTypeFrais) {
        switch (typeFrais) {
            case this.frais_prev: return 'icon-frais_previsionnel_2';
            case this.ndf:        return 'icon-note_de_frais';
            case this.avance:     return 'icon-avance_2';
        }
    }

}