import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from "@angular/common/http";

import {Result} from '@domain/common/http/result';
import {environment} from '@environments/environment';
import {Observable} from "rxjs";
import {Telephone} from "@domain/profil/telephone";
import {Document} from "@domain/profil/document";
import {CompteBancaire} from "@domain/profil/compteBancaire";
import {CartePaiement} from "@domain/profil/cartePaiement";
import {CarteVoyage} from "@domain/profil/carteVoyage";
import {Adresse,AdresseListItem} from "@domain/profil/adresse";
import {Vehicule} from "@domain/vehicule/vehicule";
import {PreferenceAnalytique} from "@domain/profil/preferenceAnalytique";
import {MobileDevice} from "@domain/profil/mobileDevice";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ProfilConnexion,ResultSynchro} from "@domain/profil/profilVoyageur";
import {InfoVoyageur} from "@domain/profil/infoVoyageur";
import {first} from "rxjs/operators";
import {Page} from "@domain/common/http/list-result";

@Injectable({
	providedIn: 'root'
})
export class ProfilService {

	constructor(private http: HttpClient,
				private toastrService: ToastrService,
				private translateService: TranslateService) {
	}

	/**
	 * Récupération de la liste simplifiée des langues utilisables
	 */
	getUserLangues(): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Traduction/getUserLangues`).pipe(first());
	}

	/**
	 * Mise à jour de la langue de l'utilisateur connecté
	 */
	saveUserLangue(codeLangue: string): Observable<Result> {
		return this.http.patch<Result>(`${environment.baseUrl}/controller/Traduction/saveUserLangue`,codeLangue).pipe(first());
	}

	getInfosValidCgu(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/getInfosValidCgu`,{});
	}

	/**
	 * Charge les alertes du profil voyageur
	 *
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'ADMIN)
	 */
	loadConfigAlerte(idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadConfigAlerte/${idUser}`,{}) :
			this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadConfigAlerte`,{});
	}

	/**
	 * Charge les infos pour la synchro du profil voyageur.
	 */
	getSynchroProfilVoyageur(idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadProfil/${idUser}`,{}) :
			this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadProfil`,{});
	}

	/**
	 * Charge les alertes du profil voyageur
	 *
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'ADMIN)
	 */
	loadProfilAlertes(idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadProfilAlertes/${idUser}`,{}) :
			this.http.post<Result>(`${environment.baseUrl}/controller/Profil/loadProfilAlertes`,{});
	}

	/**
	 * Synchronise le profil voyageur.
	 */
	syncProfilVoyageur(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/synchronizeProfil`,{});
	}

	/**
	 * Synchronise le profil voyageur d'un collaborateur.
	 *
	 * @param idCollab identifiant du collaborateur à synchroniser
	 */
	syncProfilVoyageurCollab(idCollab: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/synchronizeProfil/${idCollab}`,{});
	}

	/**
	 * Chargement des infos du profil de l'utilisateur
	 */
	loadInfosProfilUser(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/loadInfosProfilUser`,{});
	}

	/**
	 * Récupération de la liste des profils de connexion
	 */
	getListeProfilConnexion(): Observable<Page<ProfilConnexion>> {
		return this.http.post<Page<ProfilConnexion>>(`${environment.baseUrl}/controller/ProfilConnexion/listeProfilConnexion`,null);
	}

	/**
	 * Sauvegarde du profil de connexion
	 *
	 * @param idUser l'ID de l'utilisateur consulté
	 * @param idProfilConnexion l'identifiant du profil de connexion
	 */
	saveProfilConnexion(idUser: number,idProfilConnexion: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveProfilConnexion/${idUser}?idProfilConnexion=${idProfilConnexion}`,null);
	}

	/**
	 * Chargement de la liste des communautés
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 * @param idProfilConnexion l'identifiant du profil de connexion
	 */
	getListeCommunaute(idUser: number,idProfilConnexion: number): Observable<Result> {
		//Construction des paramètres HTTP
		const params: HttpParams = new HttpParams()
			.set('idUser',String(idUser))
			.set('idProfilConnexion',String(idProfilConnexion));

		return this.http.post<Result>(`${environment.baseUrl}/controller/User/listeCommunaute`,null,{params: params});
	}

	/**
	 * Enregistrement de la communauté sélectionnée
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 * @param listeIdCommunauteSelected liste des identifiants des communautés sélectionnées
	 */
	saveCommunaute(idUser: number,listeIdCommunauteSelected: number[]): Observable<Result> {
		//Construction des paramètres HTTP
		let params: HttpParams = new HttpParams()
			.set('idUser',String(idUser))
			.set('listeIdCommunaute',String(listeIdCommunauteSelected));

		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveListeCommunaute`,null,{params: params});
	}

	/**
	 * Chargement de la liste des catégories voyageur
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 * @param idProfilConnexion l'identifiant du profil de connexion
	 */
	getListeCategorieVoyageur(idUser: number,idProfilConnexion: number): Observable<Result> {
		//Construction des paramètres HTTP
		const params: HttpParams = new HttpParams()
			.set('idUser',String(idUser))
			.set('idProfilConnexion',String(idProfilConnexion));

		return this.http.post<Result>(`${environment.baseUrl}/controller/User/listeCategorieVoyageur`,null,{params: params});
	}

	/**
	 * Enregistrement de la catégorie sélectionnée
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 * @param listeIdCategorieSelected liste des identifiants des catégories sélectionnées
	 */
	saveCategorie(idUser: number,listeIdCategorieSelected: number[]): Observable<Result> {
		//Construction des paramètres HTTP
		let params: HttpParams = new HttpParams()
			.set('idUser',String(idUser))
			.set('listeIdCategorie',String(listeIdCategorieSelected));

		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveListeCategorie`,null,{params: params});
	}

	/**
	 * Chargement de la liste des RLS
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 * @param idProfilConnexion l'identifiant du profil de connexion
	 */
	getListeRLS(idUser: number,idProfilConnexion: number): Observable<Result> {
		//Construction des paramètres HTTP
		const params: HttpParams = new HttpParams()
			.set('idUser',String(idUser))
			.set('idProfilConnexion',String(idProfilConnexion));

		return this.http.post<Result>(`${environment.baseUrl}/controller/User/listeRLS`,null,{params: params});
	}

	/**
	 * Enregistrement du RLS sélectionné
	 *
	 * @param idUser l'identifiant de l'utilisateur
	 * @param listeIdRlsSelected liste des identifiants des RLS sélectionnés
	 */
	saveRLS(idUser: number,listeIdRlsSelected: number[]): Observable<Result> {
		//Construction des paramètres HTTP
		let params: HttpParams = new HttpParams()
			.set('idUser',String(idUser))
			.set('listeIdRLS',String(listeIdRlsSelected));

		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveListeRLS`,null,{params: params});
	}

	/**
	 * Mise à jour des infos du profil de l'utilisateur
	 *
	 * @param infoVoyageur les infos voyageur à sauvegarder
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'ADMIN)
	 */
	updateInfosProfilUser(infoVoyageur: InfoVoyageur,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/updateInfosProfilUser?idUser=${idUser}`,infoVoyageur)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/updateInfosProfilUser`,infoVoyageur);
	}

	loadQrCode(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/mobileToken`,{});
	}

	/**
	 * Charge les valeurs du compte par défaut pour l'utilisateur
	 */
	loadDefaultCompte(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/Compte/loadCompte?idCompte=0`,{});
	}

	getLastCguVersionHTML(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/getLastCguVersionHTML`,{});
	}

	validerCgu(isValide: boolean): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Cgu/validerCgu/${isValide}`,null);
	}

	/**
	 * Sauvegarde d'un téléphone
	 *
	 * @param telephone téléphone
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'ADMIN)
	 */
	saveTelephone(telephone: Telephone,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveTelephone?idUser=${idUser}`,telephone)
			: this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveTelephone`,telephone);
	}

	/**
	 * Suppression d'un téléphone
	 *
	 * @param telephone téléphone
	 */
	deleteTelephone(telephone: Telephone): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/deleteTelephone?idTelephone=${telephone.idTelephone}`,null);
	}

	/**
	 * Récupération de l'entité géographique pour un idVille
	 * @param idVille identifiant de la ville
	 */
	getEntiteGeoForIdVille(idVille: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Geographie/getGeoForVille/${idVille}`);
	}

	/**
	 * Récupération de l'entité géographique pour un idPays
	 * @param idPays identifiant du pays
	 */
	getEntiteGeoForIdPays(idPays: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/Geographie/getGeoForPays/${idPays}`);
	}

	/**
	 * Sauvegarde d'un document profil
	 *
	 * @param document document à sauvegarder
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'ADMIN)
	 */
	saveDocument(document: Document,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveDocument?idUser=${idUser}`,document)
			: this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveDocument`,document);
	}

	/**
	 * Suppression d'un document profil
	 *
	 * @param document document à supprimer
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	deleteDocument(document: Document,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/deleteDocument?idUser=${idUser}`,document)
			: this.http.post<Result>(`${environment.baseUrl}/controller/Profil/deleteDocument`,document);
	}

	/**
	 * Suppression d'un compte bancaire
	 *
	 * @param compte compte bancaire à supprimer
	 * @param isAdmin Est-ce un appel depuis l'admin ?
	 */
	deleteCompteBancaire(compte: CompteBancaire,isAdmin?: boolean): Observable<Result> {
		return isAdmin ? this.http.delete<Result>(`${environment.baseUrl}/controller/CompteBancaireAdmin/deleteCompte/${compte.idCompte}`)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/Compte/deleteCompte`,compte);
	}

	/**
	 * Sauvegarde d'un compte bancaire
	 *
	 * @param compte compte basncaire à sauvegarder
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	saveCompteBancaire(compte: CompteBancaire,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/CompteBancaireAdmin/saveCompte?idUser=${idUser}`,compte)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/Compte/saveCompte`,compte);
	}

	/**
	 * Supression d'une carte de paiement
	 *
	 * @param carte la carte de paiement
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	deleteCartePaiement(carte: CartePaiement,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/CarteBancaireAdmin/deleteCartePaiement`,carte)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/deleteCartePaiement`,carte);
	}

	/**
	 * Sauvegarde d'une carte de paiement
	 *
	 * @param carte la carte de paiement
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	saveCartePaiement(carte: CartePaiement,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/CarteBancaireAdmin/saveCartePaiement/${carte.idCarte ? 'false' : 'true'}`,carte)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/saveCartePaiement/${carte.idCarte ? 'false' : 'true'}`,carte);
	}

	/**
	 * Chargement d'une carte de paiement
	 *
	 * @param carte la carte de paiement
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	loadCartePaiement(carte: CartePaiement,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/CarteBancaireAdmin/loadCartePaiement?idUser=${idUser}`,carte)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/loadCartePaiement`,carte);
	}

	/**
	 * Supression d'une carte de fidélité
	 *
	 * @param carte voygage
	 */
	deleteCarteVoyage(carte: CarteVoyage): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Profil/deleteCarte`,carte);
	}

	/**
	 * Sauvegarde d'une carte de fidélité
	 *
	 * @param carte voyage
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	saveCarteVoyage(carte: CarteVoyage,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveCarte?idUser=${idUser}`,carte)
			: this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveCarte`,carte);
	}

	/**
	 * Sauvegarde d'une adresse
	 *
	 * @param adresse adresse
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	saveAdresse(adresse: Adresse,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/AdresseAdmin/saveAdresse?idUser=${idUser}`,adresse)
			: this.http.post<Result>(`${environment.baseUrl}/controller/Profil/saveAdresse`,adresse);
	}

	/**
	 * Supression d'une adresse
	 *
	 * @param adresse adresse
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	deleteAdresse(adresse: AdresseListItem,idUser?: number): Observable<Result> {
		return idUser ? this.http.delete<Result>(`${environment.baseUrl}/controller/AdresseAdmin/deleteAdresse/${adresse.idAdresse}?idUser=${idUser}`)
			: this.http.post<Result>(`${environment.baseUrl}/controller/Profil/deleteAdresse/${adresse.idAdresse}`,null);
	}

	/**
	 * Sauvegarde d'un vehicule
	 *
	 * @param vehicule vehicule
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	saveVehicule(vehicule: Vehicule,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/User/saveVehicule?idUser=${idUser}`,vehicule)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/saveVehicule`,vehicule);
	}

	/**
	 * Chargement de la liste des puissances fiscales pour les véhicules
	 */
	loadPuissanceFiscale(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/listePuissanceVehicule`,null);
	}

	/**
	 * Sauvegarde d'une préférence analytique
	 *
	 * @param preferenceAnalytique préférence analytique
	 * @param idUser l'ID de l'utilisateur consulté (valorisé uniquement depuis l'admin)
	 */
	savePreferenceAnalytique(preferenceAnalytique: PreferenceAnalytique,idUser?: number): Observable<Result> {
		return idUser ? this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/Analytique/savePrefAnalytique/${idUser}`,preferenceAnalytique)
			: this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/Analytique/savePrefAnalytique`,preferenceAnalytique);
	}

	/**
	 * Suppression d'une préférence analytique
	 * @param idPrefAnalytique id de la préférence analytique
	 */
	deletePreferenceAnalytique(idPrefAnalytique: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/Analytique/deletePrefAnalytique/${idPrefAnalytique}`,null);
	}

	/**
	 * Renvoie l'url d'export xml du profil
	 */
	getExportUrl() {
		return `${environment.baseUrl}/controller/ProfilUser/exportXml`;
	}

	/**
	 * Permet ou interdit l'accès au profil
	 */
	changeAccessStatus(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/switchSuspensionRgpd`,null);
	}

	/**
	 * Supression d'un device mobile
	 * @param id identifiant du device
	 */
	deleteMobileDevice(id: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/deleteMobileDevice/${id}`,null);
	}

	/**
	 * Sauvegarde d'un device mobile
	 * @param mobileDevice Device mobile
	 */
	saveMobileDevice(mobileDevice: MobileDevice) {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilUser/saveMobileDevice`,mobileDevice);
	}

	/**
	 * Récupération de la version courante de l'applicatif
	 */
	getAppInfos(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/APropos/load`,null);
	}

	/**
	 * Affiche les toasts du résultat de la synchronisation du profil voyageur
	 *
	 * @param listeResultSynchro Résultat de la synchronisation du profil voyageur
	 * @returns Si la synchro est réussie ou non
	 */
	resultSynchroToast(listeResultSynchro: ResultSynchro[]): boolean {
		//Récupération des compteurs
		let nbValides: number = 0;

		let listeInfos: string[] = [];
		//Parcours de la liste des résultats de synchronisation
		listeResultSynchro.forEach(resultSynchro => {
			//Vérification du message
			if (resultSynchro.message?.length > 0) {
				//Ajout du message
				listeInfos.push(resultSynchro.message);
			}

			//Si la synchro est un succès
			if (resultSynchro.ok) {
				//On incrémente le nombre de synchros valides
				++nbValides;
			}
		});

		//On calcule le nombre de synchro en erreur
		const nbErreurs: number = listeResultSynchro.length - nbValides;

		//Message d'information en liste HTML
		const msgListeInfos = listeInfos.reduce((previousValue,currentValue) => previousValue + "<li>" + currentValue + "</li>","<ul>") + "</ul>";

		if (nbValides > 0 && nbErreurs == 0) {
			//Message d'information
			this.toastrService.success(
				msgListeInfos,
				this.translateService.instant("profilUser.infoPerso.synchroPV.statutSuccess"),
				{enableHtml: true}
			);
			return true;

		} else if (nbValides > 0 && nbErreurs > 0) {
			//Message d'alerte
			this.toastrService.warning(
				msgListeInfos,
				this.translateService.instant("profilUser.infoPerso.synchroPV.statutWarning"),
				{enableHtml: true}
			);

		} else {
			//Message d'erreur
			this.toastrService.error(
				msgListeInfos,
				this.translateService.instant("profilUser.infoPerso.synchroPV.statutError"),
				{enableHtml: true}
			);
		}
		return false;
	}
}