import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Result } from '../../domain/common/http/result';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FicheCollaborateurService {

  constructor(private http: HttpClient) { }

  /**
   * Chargement du collaborateur
   */
  loadCollaborateur(idCollaborateur: number): Observable<any> {
        return this.http.get<Result>(
      `${environment.baseUrl}/controller/FicheCollab/loadFicheCollaborateur/${idCollaborateur}`)
      .pipe(first(), map((result: Result) => result.data));
  }
}
