import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, first } from "rxjs/operators";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import { TranslateService } from "@ngx-translate/core";
import { ListView } from "@domain/common/list-view";
import { TaskItemComponent } from "@components/admin/maintenance/migrations/maintenance/task-item.component";
import { Result } from "@domain/common/http/result";
import { MaintenanceTask } from "@domain/admin/maintenance/maintenance-task";
import { KeyStatutApplication } from "@domain/admin/statut-application/statut-application";
import { MigrationsService } from "@services/admin/maintenance/migrations.service";
import { Subscription } from "rxjs";

/**
 * Page de détail d'une tâche de maintenance
 *
 * @author Guillaume TRAVOT
 * @date 13/02/2024
 */
@Component({
    host: {'data-test-id': 'maintenance-detail'},
    templateUrl: './maintenance-detail.component.html'
})
export class MaintenanceDetailComponent implements OnInit, OnDestroy {
    /** Nom technique de la tâche à afficher */
    taskName: string = null;

    /** Statut de l'application */
    applicationStatutAdmin: string;

    /** Liste des différents onglets disponibles */
    listeTabItems: Array<PageHeaderItem> = [
        {
            code: null,
            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.details.historiqueExecution')
        }
    ];

    /** Index de l'onglet courant */
    selectedIndex: number = 0;

    /** Nom nettoyé pour la tâche de maintenance */
    displayName: string;

    /** Liste des tâches, pour utiliser le composant ListViewComponent */
    liste: ListView<MaintenanceTask, TaskItemComponent>;

    /** Souscription */
    souscription: Subscription;

    /**
     * Constructeur
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private migrationsService: MigrationsService,
        protected translateService: TranslateService
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération du nom technique de la tâche
        this.activatedRoute.params.pipe(first()).subscribe(params => {
            //Stockage du nom technique de la tâche
            this.taskName = String(params['taskName']);

            //Création de la liste des tâches
            this.liste = new ListView<MaintenanceTask, TaskItemComponent>({
                uri: `/controller/Maintenance/consultTask/${this.taskName}/historique`,
                title: this.translateService.instant('admin.maintenance.migrations.onglets.maintenance.details.historiqueExecution'),
                component: TaskItemComponent,
                mapResult: (result: Result): MaintenanceTask[] => {
                    //Instanciation des éléments de la liste
                    const historique: MaintenanceTask[] = result?.data?.historique?.map(r => new MaintenanceTask(r));

                    //Récupération du nom de la tâche
                    this.displayName = historique[0]?.displayName ?? this.taskName;

                    //Retour du mapping
                    return historique;
                },
                isSimple: true
            });
        });

        //Abonnement à la mise à jour du statut de l'application
        this.souscription = this.migrationsService.appStatut$.pipe(filter(statut => !!statut)).subscribe(statut => this.applicationStatutAdmin = 'admin.maintenance.migrations.statutApplication.' + KeyStatutApplication[statut?.applicationStatutAdmin?.statut]);
    }

    /**
     * Méthode appelée lors du clic sur le bouton de retour en arrière pour retourner vers la liste des tâches
     */
    onGoBack() {
        //Navigation vers la liste
        this.router.navigate(['Admin/Maintenance/Migrations/Maintenance']);
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement
        this.souscription?.unsubscribe();
    }
}