import {Component,Input,OnInit} from '@angular/core';
import {LotArchivage} from "@domain/demat/lot-archivage";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {getStatutArchivageIcon,StatutArchivage} from "@domain/comptabilite/statut-archivage";

/**
 * Composant d'affichage d'une ligne d'historique d'archivage d'un lot.
 *
 * @author François Turin
 * @date 31/08/2023
 */
@Component({
	host: {'data-test-id': 'historique-lot-list-item'},
	templateUrl: './historique-lot-list-item.component.html'
})
export class HistoriqueLotListItemComponent extends ListViewItem<LotArchivage> implements OnInit {
	protected readonly StatutArchivage = StatutArchivage;

	/** Lot courant */
	@Input() data: LotArchivage;

	/** Nom de l'icône du statut d'archivage */
	statutArchivageIcon: string;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de l'icône et la classe CSS en fonction du statut d'archivage
		if (this.data.statutArchivage) {
			this.statutArchivageIcon = getStatutArchivageIcon(this.data.statutArchivage);
		} else {
			this.statutArchivageIcon = "unarchive";
		}
	}

	/**
	 *  Nom de la classe css de l'icône du statut d'archivage
	 */
	get statutClass(): string {
		if (this.data.statutArchivage) {
			switch (this.data.statutArchivage) {
				case StatutArchivage.PARTIEL:
					return 'warning';
				case StatutArchivage.ECHEC:
					return 'danger';
				case StatutArchivage.EN_ATTENTE:
					return '';
				case StatutArchivage.SYNCHRONISE:
					return 'success';
				default:
					return '';
			}
		}
	}

	/**
	 * Tooltip de l'icône de dématérialisation.
	 */
	get dematTooltip(): string {
		switch (this.data.statutArchivage) {
			case StatutArchivage.EN_ATTENTE:
				return "admin.parametres.demat.archivage.synchro.enAttente";
			case StatutArchivage.SYNCHRONISE:
				return "admin.parametres.demat.archivage.synchro.synchronise";
			case StatutArchivage.ECHEC:
				return "admin.parametres.demat.archivage.synchro.echec";
			case StatutArchivage.PARTIEL:
				return "admin.parametres.demat.archivage.synchro.partiel";
			default:
				return "";
		}
	}

}
