import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../../../autocomplete-line-item.component";
import {TypeCharge} from "@domain/prestation/type-charge";

/**
 * Composant d'affichage d'une ligne de l'autocomplete des types de charge dans le cas de la correction des rejets de facture
 *
 * @author Laurent Convert
 * @date 11/07/2023
 */
@Component({
	host: {'data-test-id': 'type-charge-line-item'},
	templateUrl: './type-charge.line-item.html'
})
export class TypeChargeLineItem extends AutocompleteLineItemComponent<TypeCharge> {}