<mat-card>
    <mat-card-title>
        <span [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <please-wait *ngIf="!(champ1 && champ2 && champ3 && champ4)"></please-wait>
        <form #form="ngForm" *ngIf="champ1 && champ2 && champ3 && champ4">
            <div class="row">
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.champ1'"></span>
                </label>
                <div class="col-md-4 r-flex align-items-center pl-0 form-min-height">
                    <button mat-button color="primary" class="mb-2" (click)="open(champ1)">
                        <span *ngIf="!champ1.name" [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.nonDefini'"></span>
                        <span *ngIf="champ1.name">{{ champ1.name }}</span>
                    </button>
                </div>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.champ2'"></span>
                </label>
                <div class="col-md-4 r-flex align-items-center pl-0 form-min-height">
                    <button mat-button color="primary" class="mb-2" (click)="open(champ2)">
                        <span *ngIf="!champ2.name" [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.nonDefini'"></span>
                        <span *ngIf="champ2.name">{{ champ2.name }}</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.champ3'"></span>
                </label>
                <div class="col-md-4 r-flex align-items-center pl-0 form-min-height">
                    <button mat-button color="primary" class="mb-2" (click)="open(champ3)">
                        <span *ngIf="!champ3.name" [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.nonDefini'"></span>
                        <span *ngIf="champ3.name">{{ champ3.name }}</span>
                    </button>
                </div>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.champ4'"></span>
                </label>
                <div class="col-md-4 r-flex align-items-center pl-0 form-min-height">
                    <button mat-button color="primary" class="mb-2" (click)="open(champ4)">
                        <span *ngIf="!champ4.name" [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.nonDefini'"></span>
                        <span *ngIf="champ4.name">{{ champ4.name }}</span>
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>