import {Component,OnInit} from '@angular/core';
import {environment} from "@environments/environment";
import {FileUploader,FileWithProgress,ProgressType} from "@share/directive/file-uploader/file-uploader";
import {SettingsGlobalState} from "@domain/settings/settings";
import {AppState} from "@domain/appstate";
import {Store} from "@ngrx/store";
import {filter,first} from "rxjs/operators";
import {Receipt,ValueAndConfidence} from "@domain/admin/parametre/demat/receipt";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe,DecimalPipe,JsonPipe} from "@angular/common";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {forkJoin} from "rxjs";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage de l'onglet OCR du module Dématérialisation
 *
 * @author Laurent Convert
 * @date 20/09/2023
 */
@Component({
    host: {'data-test-id': 'demat-ocr'},
    selector: 'demat-ocr',
    templateUrl: './demat-ocr.component.html',
    styleUrls: ['./demat-ocr.component.scss']
})
export class DematOcrComponent implements OnInit {
    /** Déclaration pour accès dans le template */
    ThumbnailType = ThumbnailType;

    /** Paramétrage */
    settings: SettingsGlobalState;

    /** Upload de fichiers */
    fileUploader: FileUploader = {
        url: `${environment.baseUrl}/controller/DematHubConfig/tryOcr`,
        onItemUploaded: this.onItemUploaded.bind(this),
        onItemUploading: this.onItemUploading.bind(this),
        autoRemove: true,
        isCountDown: false,
        multiple: false
    };

    /** Options pour l'affichage du flux JSON */
    codeMirrorOptions: any = {
        theme: 'material',
        mode: 'application/ld+json',
        lineNumbers: true,
        lineWrapping: true,
    };

    /** Traitement OCR en cours */
    isProcessing: boolean;

    /** Retour de l'OCR */
    fluxOcr: Receipt = null;

    /** Date formatée pour l'affichage */
    dateOcrStr: string;

    /** Index de l'onglet à afficher */
    tabIndex: number = 0;

    /** Objet contenant les informations nécessaires à l'affichage de l'aperçu */
    thumbnail: { file?: File,type?: ThumbnailType } = {};

    /**
     * Constructeur
     *
     * @param store Store de l'application
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param decimalPipe Formatage de nombre
     * @param datePipe Formatage de date
     * @param jsonPipe Formatage de JSON
     */
    constructor(private store: Store<AppState>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private decimalPipe: DecimalPipe,
                private datePipe: DatePipe,
                private jsonPipe: JsonPipe) {
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Sélection du paramétrage
        this.store.select(state => state.settings?.['Global'])
            .pipe(filter(val => !!val))
            .subscribe(settings => {
                this.settings = settings;
            });
    }

    /**
     * Formate le tooltip pour afficher la valeur de confiance retournée par l'OCR
     * @param field Champ
     */
    getTooltipIndiceConfiance(field: ValueAndConfidence<any>): string {
        return this.translateService.instant('admin.parametres.demat.ocr.indiceConfiance', {
                indice: this.decimalPipe.transform(field?.confidence,'1.2-2') ?? '-'
            });
    }

    /**
     * Réinitialisation des champs
     */
    resetForm() {
        this.fluxOcr = null;
        this.dateOcrStr = null;
        this.thumbnail = {};
    }

    /**
     * Fichier sélectionné
     */
    onDocumentsAdded(queue: Array<FileWithProgress>) {
        //Réinitialisation de l'écran
        this.resetForm();

        //Vérification de la queue
        if (queue.length >= 1) {
            //Récupération du fichier pour l'aperçu
            this.thumbnail.file = queue[0];

            //Vérification du type MIME pour l'aperçu
            if (this.thumbnail.file.type.startsWith('image/')) {
                this.thumbnail.type = ThumbnailType.IMAGE;
            } else if (this.thumbnail.file.type == 'application/pdf') {
                this.thumbnail.type = ThumbnailType.PDF;
            }

            //Upload
            queue[0].ProgressType = ProgressType.Uploading;
            forkJoin(this.fileUploader.uploadItem(0)).pipe(first()).subscribe();
        }
    }

    /**
     * Fichier en cours d'upload et de traitement OCR
     */
    onItemUploading(file: FileWithProgress) {
        //Début du traitement : affichage d'un loading
        this.isProcessing = true;
    }

    /**
     * Retour du traitement OCR suite à l'upload
     */
    onItemUploaded(result: Result) {
        let fluxOcr: Receipt;

        //Fin du traitement
        this.isProcessing = false;

        //Récupération du retour de l'OCR
        if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
            if ((fluxOcr = result.data.ocr)) {
                //Transformation du JSON en objet puis passage dans le pipe angular pour lui faire une beauté
                fluxOcr.rawJSON = fluxOcr.rawJSON ? this.jsonPipe.transform(JSON.parse(fluxOcr.rawJSON)) : null;

                //Association sur le composant, déclenchant l'affichage
                this.fluxOcr = fluxOcr;

                //Formatage de la date
                this.dateOcrStr = this.fluxOcr.date ? this.datePipe.transform(this.fluxOcr.date,'shortDate') : null;
            } else {
                //message d'erreur
                this.toastrService.error(this.translateService.instant('ndf.generalites.errorGenerationFrais'));
            }
        } else {
            //message d'erreur générique
            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
        }
    }

}

/** Énumération des types de fichier gérés par l'aperçu */
export enum ThumbnailType {
    IMAGE,PDF
}