import {AfterContentChecked,Component,Inject,Input,OnDestroy,OnInit,QueryList,ViewChild,ViewChildren} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MatStep,MatVerticalStepper} from "@angular/material/stepper";
import {NgForm} from "@angular/forms";
import {EtapeTournee} from "@domain/Tournee/etape-tournee";
import {Tournee} from "@domain/Tournee/tournee";
import {Observable,Subscription} from "rxjs";
import {Adresse} from "@domain/profil/adresse";
import * as _ from "lodash";
import {FraisService} from "@components/frais/frais.service";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {DOCUMENT} from "@angular/common";
import {ToastrService} from "ngx-toastr";

@Component({
    host: {'data-test-id': 'stepper-tournee'},
    selector: 'stepper-tournee',
    templateUrl: './stepper-tournee.component.html',
    providers: [
        {
            //On ajoute ce Provider pour permettre la customisation du stepper
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {
                displayDefaultIndicatorType: false,//Permet de customiser les icônes
                showError: true//Permet de gérer les erreurs sur les steps
            }
        }
    ],
})
export class StepperTourneeComponent implements OnInit,OnDestroy,AfterContentChecked {
    /** Accès direct au stepper */
    @ViewChild('stepper') stepper: MatVerticalStepper;

    /** Accès direct à la step d'ajout de trajet */
    @ViewChild('stepAjout') stepAjout: MatStep;

    /** Liste des ngForm du stepper */
    @ViewChildren('stepForm') stepForms: QueryList<NgForm>;

    /** NgForm du formulaire pour l'heure */
    @ViewChild('heureForm') heureForm: NgForm;

    /** Le modèle */
    @Input() tournee: Tournee;

    /** Observateur pour la demande de reset */
    @Input() resetObs: Observable<void>;

    /** Paramètre etapes personnelles */
    @Input() etapesPersonnelles: boolean;

    /** Paramètre gestion temps globale */
    @Input() gestionTempsGlobale: boolean;

    /** Paramètre gestion temps détaillée */
    @Input() gestionTempsDetaillee: boolean;

    /** Paramètre quantité modifiable */
    @Input() quantiteModifiable: boolean;

    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /** Tag permettant de savoir si on a demandé l'ajout d'une étape */
    hasClick: boolean = false;

    /** Liste de souscription à delete */
    listeSouscriptions: Subscription[] = [];

    constructor(private translateService: TranslateService,
                private fraisService: FraisService,
                private toastrService: ToastrService,
                @Inject(DOCUMENT) private document: Document) {
    }

    /**
     * Initialisateur du composant
     */
    ngOnInit() {
        //Gestion du reset
        this.listeSouscriptions.push(
            this.resetObs.subscribe(
                () => {
                    this.resetTournee();
                }
            )
        );
    }

    /**
     * Réinitialisation les éléments de la tournée
     */
    private resetTournee(): void {
        this.tournee.listeEtapes = [];
        this.tournee.listeEtapes.push(new EtapeTournee());
        this.tournee.heureDebut = "";
        this.tournee.heureFin = "";
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Annulation des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
    }

    /** Méthode d'ajout d'une étape dans les steps */
    addEtape() {
        //On vérifie que le clic a été fait sur le step d'ajout d'une étape
        if (this.stepper.selected.state == 'ajout') {
            //On va sélectionner le step précédent sinon on a un problème d'index (vu qu'ils vont être recalculés).
            this.stepper.previous();

            //On marque le step d'ajout d'étape comme intacte (pour garder le logo).
            this.stepAjout.interacted = false;

            //On ajoute une étape
            this.pushNewEtape();

            //On indique qu'on a cliqué pour ajouter une étape (la gestion continue dans le ngAfterContentChecked)
            this.hasClick = true;
        }
    }

    /** Méthode de suppression d'une étape dans le stepper */
    removeEtape(index: number) {
        //Si ce n'est la dernière étape
        if (this.tournee.listeEtapes.length - 1 != index) {
            //On récupère l'étape précédente
            const beforeEtape = this.tournee.listeEtapes[index - 1];

            //On récupère l'étape suivante
            const nextEtape = this.tournee.listeEtapes[index + 1];

            //On assigne la destination de l'étape précédente sur l'étape suivante
            nextEtape.localisationDepart = _.cloneDeep(beforeEtape.localisationArrivee);

            //On recalcule la distance
            this.updateDistance(nextEtape);
        }

        //On supprime l'étape
        this.tournee.listeEtapes.splice(index,1);
        this.stepper.previous();
    }

    /** Après la vérification du contenu */
    ngAfterContentChecked(): void {
        //Si on a cliqué sur le bouton pour ajouter une étape
        if (this.hasClick) {
            //On récupère la liste des mat-step
            const steps = this.document.querySelectorAll('div .mat-step');

            //S'il y a autant de mat-step que d'étapes, c'est que l'ajout a enfin eu lieu côté material.
            if ((steps.length - 1) == this.tournee.listeEtapes.length) {
                //On peut diriger sur la step qui vient d'être créée sans se prendre une erreur angular sur le lifecycle
                this.stepper.next();

                //On retire le tag du click vu que c'est bon, on a géré la sauce
                this.hasClick = false;
            }
        }
    }

    /**
     * Méthode de création d'une étape ET ajout de celle-ci à la liste des étapes
     */
    pushNewEtape() {
        const etapeTournee = new EtapeTournee();

        //On récupère les infos de la dernière étape avant d'ajouter l'étape
        const lastIndex = this.tournee.listeEtapes.length -1;

        //On ajoute l'ordre
        etapeTournee.ordre = lastIndex + 1;

        //On ajoute l'étape
        this.tournee.listeEtapes.push(etapeTournee);

        //Si on a déjà un index, on reprend la location d'arrivée
        if (lastIndex >= 0) {
            const lastLocationArrive =  this.tournee.listeEtapes[lastIndex].localisationArrivee;

            //On met à jour la localisation
            this.updateNextEtapeLocalisationArrivee(lastLocationArrive,lastIndex)
        }
    }

    /**
     * Retourne le titre du stepper en cours
     *
     * @param etape l'étape en cours
     */
    getStepperTitle(etape: EtapeTournee) {
        let title = "";

        //Si on n'a pas de localisation, on assigne un libellé par défaut
        if (etape.localisationDepart == null && etape.localisationArrivee == null) {
            return this.translateService.instant('ndf.frais.ajouterTournee.nouvelleEtape')
        }

        title += this.getLocalisationFormatted(etape.localisationDepart);

        if (etape.localisationDepart != null && etape.localisationArrivee != null) {
            title += " - ";
        }

        title += this.getLocalisationFormatted(etape.localisationArrivee);

        return title;
    }

    /**
     * Retourne la localisation formatée pour le titre
     *
     * @param localisation la localisation a formatter
     */
    getLocalisationFormatted(localisation: Adresse): string {
        let title = "";

        if (localisation != null) {
            if (localisation.ville != null) {
                title += localisation.ville
            }
            if (localisation.ville != null && localisation.pays != null) {
                title += ", "
            }
            if (localisation.pays != null) {
                title += localisation.pays
            }
        }

        return title;
    }

    /**
     * Retourne le corps du titre du stepper
     *
     * @param etape
     */
    getStepperTitleBody(etape) {
        let body = "";

        if (etape.heureDebut) {
            body = this.translateService.instant('ndf.frais.ajouterTournee.depart') + ": " + etape.heureDebut + " ";
        }

        if (etape.heureFin) {
            body += this.translateService.instant('ndf.frais.ajouterTournee.arrivee') + ": " + etape.heureFin + " ";
        }

        if (etape.distance) {
            body += this.translateService.instant('ndf.frais.ajouterTournee.distance') + ": "
                +  etape.distance + " "
                + "(" + this.tournee.unite.libelle + ")"  + " ";
        }

        body += this.translateService.instant('ndf.frais.ajouterTournee.perso') + ": "
            +  this.translateService.instant(etape.personnel ? 'global.oui' : 'global.non');

        return body;
    }

    /**
     * Handler si la localisation arrivée change
     *
     * @param localisationArrivee la localisation arrive
     * @param index l'index courant
     */
    onLocalisationArriveeChange(localisationArrivee: Adresse, index): void {
        this.updateNextEtapeLocalisationArrivee(localisationArrivee, index);
    }

    /**
     * Handler si la localisation arrivée change
     *
     * @param localisationArrivee la localisation arrive
     * @param index l'index courant
     */
    updateNextEtapeLocalisationArrivee(localisationArrivee: Adresse, index): void {
        //On récupère l'étape suivante si possible
        const nextEtape = this.getEtapeSuivante(index);

        //Si on une autre étape
        if (nextEtape != null) {
            //On assigne la localisation
            nextEtape.localisationDepart = _.cloneDeep(localisationArrivee);

            //On recalcule la distance
            this.updateDistance(nextEtape);
        }
    }

    /**
     * Handler si l'heure de début a changé
     *
     * @param heure la nouvelle heure
     * @param index l'index de liste étape
     */
    onHeureFinChange(heure: string,index): void {
        //On récupère l'étape suivante si possible
        const nextEtape = this.getEtapeSuivante(index);

        //Si on n'a pas d'autre étape, c'est qu'il faut changer l'heure de fin sur la tournée.
        if (nextEtape == null) {
            this.tournee.heureFin = _.cloneDeep(heure);
        }

        //Hack pour forcer la revalidation des validators notamment pour comparer date de fin et date de début sur un step différent
        this.tournee.listeEtapes = _.cloneDeep(this.tournee.listeEtapes);
    }

    /**
     * Handler si l'heure de fin a changé
     *
     * @param heure la nouvelle heure
     * @param index l'index de liste étape
     */
    onHeureDebutChange(heure: string, index): void {
        //On récupère l'étape précédente si possible
        const beforeEtape = this.getEtapePrécédente(index);

        //Si on n'a pas d'autre étape, c'est qu'il faut changer l'heure de fin sur la tournée.
        if (beforeEtape == null) {
            this.tournee.heureDebut = _.cloneDeep(heure);
        }

        //Hack pour forcer la revalidation des validator notamment pour comparer date de fin et date de début sur un step différent
        this.tournee.listeEtapes = _.cloneDeep(this.tournee.listeEtapes);
    }

    /**
     * Retourne l'étape suivante par rapport à l'étape courante
     *
     * @param index l'étape courante
     */
    getEtapeSuivante(index): EtapeTournee {
        return this.tournee.listeEtapes.length - 1  >= index + 1  ? this.tournee.listeEtapes[index + 1] : null;
    }

    /**
     * Retourne l'étape précédente par rapport à l'étape courante
     *
     * @param index l'étape courante
     */
    getEtapePrécédente(index): EtapeTournee {
        return 0 <= index - 1  ? this.tournee.listeEtapes[index - 1] : null;
    }

    /**
     * Retourne true si on doit afficher les heures globales
     */
    hasShowHeureGlobale(): boolean {
        return this.gestionTempsGlobale || this.gestionTempsDetaillee;
    }

    /**
     * Retourne true si le paramétrage gestion détaillé activé
     */
    isGestionHeureGlobalReadOnly() {
        return this.gestionTempsDetaillee;
    }

    /**
     * Retourne l'heure de fin de l'étape précédente
     *
     * @param index index courant
     */
    getHeureFinEtapePrecedente(index: number) {
        return index != 0  ? this.tournee.listeEtapes[index-1].heureFin : null
    }

    /**
     * Retourne true sur un formulaire est en erreur
     */
    hasFormError(): boolean {
        if (this.stepForms != null) {
            //On cherche si au moins un formulaire est invalide
            const stepFormInvalid = this.stepForms.find(stepForm => stepForm.invalid);

            //True si on n'a pas de résultat ou si la saisie d'heure est invalide
            return stepFormInvalid != null || (this.heureForm != null && this.heureForm.invalid);
        }
    }

    /**
     * Calcule le message d'erreur d'une étape
     *
     * @param etapeForm Formulaire de l'étape concernée
     */
    getErrorMessage(form: NgForm): string {
        let listeErreurs: string[] = [];

        //Si on a bien le contrôle du formulaire
        if (form?.controls) {

            //On parcourt tous les champs du formulaire
            for (const name in form.controls) {

                //Si le champ est invalide
                if (form.controls[name].invalid) {
                    // Utilise une expression régulière pour remplacer tous les chiffres par une chaîne vide
                    const currentName = name.replace(/\d/g, '');

                    //Traitement en fonction du champ en erreur
                    switch (currentName) {
                        case 'origine' :
                            listeErreurs.push(this.translateService.instant('ndf.frais.ajouterTournee.origine'));
                            break;
                        case 'destination' :
                            listeErreurs.push(this.translateService.instant('ndf.frais.ajouterTournee.destination'));
                            break;
                        case 'heureDebut' :
                            listeErreurs.push(this.translateService.instant('ndf.frais.ajouterTournee.depart'));
                            break;
                        case 'heureFin' :
                            listeErreurs.push(this.translateService.instant('ndf.frais.ajouterTournee.arrivee'));
                            break;
                        case 'distance' :
                            listeErreurs.push(this.translateService.instant('ndf.frais.ajouterTournee.Distance'));
                            break;
                        default:
                            //On renvoie le nom du champ avec une majuscule en première lettre
                            listeErreurs.push(name[0].toUpperCase() + name.slice(1));
                            break;
                    }
                }
            }

            //On concatène tout dans un string et on renvoie l'erreur
            return this.translateService.instant('ndf.frais.ajouterTournee.champsErreur') + listeErreurs.join(', ');
        }

        //Pas de formulaire pas d'erreur, pas d'erreur pas de message
        return '';
    }

    /**
     * Calcule et met à jour la distance
     * @param nextEtape l'étape à mettre à jour
     */
    updateDistance(nextEtape: EtapeTournee): void {
        this.fraisService.findDistance(nextEtape.localisationDepart,nextEtape.localisationArrivee,false)
            .subscribe({
                next: (distance: number) => {
                    nextEtape.distance = distance;
                },
                error: () => {
                    //Pas de calcul possible on affecte la valeur par défaut
                    nextEtape.distance = 0;
                    this.toastrService.error(this.translateService.instant('global.errors.chargement'));
                }
            });
    }
}
