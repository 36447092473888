import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {OdListItemComponent} from "../../../od/od-list/od-list-item/od-list-item.component";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {MatDialog} from "@angular/material/dialog";
import {OMPService} from "../../../omp/omp.service";

/**
 * Item de la liste des OD du Dashboard
 */
@Component({
    selector: 'dashboard-od-list-item',
    templateUrl: './dashboard-od-list-item.component.html'
})
export class DashboardOdListItemComponent extends OdListItemComponent {

    /**
     * Constructeur
     *
     * @param router le routeur
     * @param store le store
     * @param matDialog le composant Material des Popups
     * @param ompService le service de gestion des OMP
     */
    constructor(protected router: Router,
                protected store: Store<AppState>,
                protected matDialog: MatDialog,
                protected ompService: OMPService) {
        super(router, store, matDialog, ompService);
    }
}
