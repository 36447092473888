import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {UserContrat} from "@domain/user/user-contrat";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {UserContratDetailPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/franchise-popin/user-contrat-detail-popin/user-contrat-detail-popin.component";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Item de liste d'un contrat
 */
@Component({
	host: {'data-test-id': 'user-contrat-list-item'},
	templateUrl: './user-contrat-list-item.component.html'
})
export class UserContratListItemComponent extends ListViewItem<UserContrat> {

	/**
	 * Constructeur
	 *
	 * @param matDialog le gestionnaire de popin
	 * @param userService le service de gestion de l'utilisateur
	 * @param toastrService le toasteur
	 * @param translateService le moteur de traduction
	 * @param confirmService le service de confirmation
	 */
	constructor(
		private matDialog: MatDialog,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private confirmService: ConfirmService
	) {
		super();
	}

	/**
	 * Clic sur le contrat
	 */
	onSelect(): void {
		//Ouverture de la popin de consultation/modification
		this.matDialog.open(UserContratDetailPopinComponent,{
			data: {userContrat: this.data},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			if (refresh) {
				//Rafraîchissement de la liste
				this.liste.refresh();
			}
		})
	}

	/**
	 * Suppression de ce contrat
	 */
	deleteContrat(): void {
		//Confirmation auprès de l'utilisateur sur la suppression d'un contrat
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Appel au service
					this.userService.deleteContrat(this.data)
						.pipe(first())
						.subscribe((result: Result) => {
							//Vérification du code d'erreur
							if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
								//Toast succès
								this.toastrService.success(this.translateService.instant('global.success.suppression'));

								//Rafraîchissement de la liste
								this.liste.refresh()
							} else {
								//Gestion de l'erreur
								TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
							}
						});
				}
			});

	}
}
