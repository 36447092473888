<h1 mat-dialog-title>
    <span [translate]="'vehicule.historiqueDistance.titreDialog'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body" *ngFor="let compteur of data.listeCompteur">
            <div class="listview-item">
                <div class="lvi-content">
                    <div class="avatar"><i class="nio icon-voiture align-middle"></i></div>
                    <div class="lvi-body">
                        <div class="title">
                            <strong>
                                <a>{{ compteur.date  | date: 'shortDate' }} - {{ getCompteur(compteur.date)  | date: 'shortDate' }}</a>
                            </strong>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'vehicule.historiqueDistance.distanceEnCours'"></strong>{{ (compteur.compteurTheorique - compteur.compteurReel) | number:'1.2-2' }} {{compteur.unite.libelle}}</li>
                            <li><strong [translate]="'vehicule.historiqueDistance.distanceValidee'"></strong>{{ compteur.compteurReel | number:'1.2-2' }} {{compteur.unite.libelle}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="data.listeCompteur.length == 0">
        <span style="vertical-align: middle" [translate]="'vehicule.historiqueDistance.noData'"></span>
    </div>
</div>