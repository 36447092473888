import {Component,OnInit} from "@angular/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {DeviseItemComponent} from "@components/admin/bibliotheque/devises/devise-item/devise-item.component";
import {DeviseItem} from "@domain/admin/bibliotheque/devises/deviseItem";
import {Sorting} from "@domain/common/list-view/sorting";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

/**
 * Composant concernant les devises de l'entreprise
 */
@Component({
    host: {'data-test-id': 'devises'},
    templateUrl: './devises.component.html'
})
export class DevisesComponent implements OnInit {
    /** Liste des différents onglets disponibles dans le menu "Devises" de l'entreprise */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem = {
        code: Onglets.DEVISES,
        libelle: this.translateService.instant('admin.bibliotheque.devises.onglets.devises'),
        selected: true
    };

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = Onglets;

    /** Liste des devises, pour utiliser le composant ListViewComponent */
    liste: ListView<DeviseItem, DeviseItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService  Service de traduction
     * @param router            Router de l'application
     */
    constructor(
        protected translateService: TranslateService,
        public router: Router,
    ) {
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de générer la liste des différents onglets disponibles (ici il n'y a qu'un seul onglet : "Devises")
        this.listeTabItems = [this.selectedItem];

        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createNewDevise(),
        }]);

        //Création de la liste des devises
        this.liste = new ListView<DeviseItem, DeviseItemComponent>({
            uri: `/controller/Devise/listeAllDevise`,
            title: this.translateService.instant('admin.bibliotheque.devises.title'),
            component: DeviseItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            isFilter: true,
            defaultOrder: 'code',
            listeFilters: [
                {
                    //Filtre de type select sur le type
                    clef: 'libelle',
                    title: this.translateService.instant('admin.bibliotheque.devises.deviseFiltres.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'code',
                    title: this.translateService.instant('admin.bibliotheque.devises.deviseFiltres.code'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                },{
                    clef: 'isActive',
                    title: this.translateService.instant('admin.bibliotheque.devises.deviseFiltres.actif'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                },
            ]
        });

        //Définition des colonnes de tri
        this.liste.columns = [
            { key: 'libelle', title: 'admin.bibliotheque.devises.deviseFiltres.libelle' },
            { key: 'code', title: 'admin.bibliotheque.devises.deviseFiltres.code' },
            { key: 'actif', title: 'admin.bibliotheque.devises.deviseFiltres.actif' },
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.liste.sorting = new Sorting(this.liste.columns, "libelle");
    }

    /**
     * Méthode appelée lors de la sélection d'une devise
     *
     * @param deviseSelected Devise sélectionnée
     */
    onSelect(deviseSelected) {
        //Navigation vers les informations de la devise sélectionnée
        this.router.navigate(['Admin/Bibliotheque/Devises/'+deviseSelected.code]);
    }

    /**
     * Méthode appelée lors de la création d'une nouvelle devise
     */
    createNewDevise() {
        //Navigation vers le formulaire de création d'une devise
        this.router.navigate(['Admin/Bibliotheque/Devises/0']);
    }
}

/**
 * Enum pour les noms des différents onglets de la page Devises et de la page d'informations d'une devise
 */
export enum Onglets {
    DEVISES = "Devises"
}
