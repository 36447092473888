import {Component,Input} from '@angular/core';
import {TypeEntiteParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {FiltreParamService} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-param.service";
import {ListView} from '@domain/common/list-view';

/**
 * Composant d'affichage des items de la liste des filtres.
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Component({
    host: {'data-test-id': 'filtre-param-item-'},
    selector: 'filtre-param-item',
    templateUrl: './filtre-param-item.component.html'
})

export class FiltreParamItemComponent {
    /** Elément courant */
    @Input() data: TypeEntiteParam;

    /** Liste courante */
    @Input() liste: ListView<TypeEntiteParam, FiltreParamItemComponent>

    /**
     * Constructeur
     */
    constructor(
        private filtreParamService: FiltreParamService
    ) {
    }
    
    /**
     * Retourne l'icone à partir du libellé
     * 
     * @param libelle le libellé
     */
    getIconeFromLibelle(libelle: string): string {
        if (libelle != null) {
            return libelle.substring(0,3);
        } else {
            return "";
        }
    }

    /**
     * Handler du bouton supprimer
     */
    onSupprimer(): void {
        this.filtreParamService.deleteFiltre(this.data, this.liste.refresh);
    }
}