import {ListItem} from "../common/list-view/list-item";
import {HistoriqueUtilisateurChange} from "@domain/user/historique-utilisateur-change";

/**
 * Classe représentant une ligne de l'historique utilisateur
 */
export class HistoriqueUtilisateur implements ListItem {
	idUser: number;
	nature: TypeNature;
	idLog: number;
	idActeur: number;
	acteur: string;
	dateLog: Date;
	typeLog: TypeLog;
	sourceLog: TypeSourceLog;
	reference: string;
	listeChanges: HistoriqueUtilisateurChange[];

	getKey(): string {
		return "" + this.idUser + this.nature + this.idLog;
	}
}

/**
 * Nature de log
 */
export enum TypeNature {
	UTILISATEUR = 1,
	HABILITATION = 2,
	CONTRAT = 3
}

/**
 * Type de log
 */
export enum TypeLog {
	AJOUT = 1,
	MODIFICATION = 2,
	SUPPRESSION = 3
}

/**
 * Type de source de log
 */
export enum TypeSourceLog {
	ADMINISTRATEUR = 1,
	IMPORT = 2,
	UTILISATEUR = 3
}