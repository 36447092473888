<h1 mat-dialog-title>
    <span>{{ 'admin.maintenance.migrations.enCours' | translate }}</span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <list-view *ngIf="liste" [liste]="liste"></list-view>
    <div class="d-flex flex-row-reverse">
        <button mat-flat-button color="primary" (click)="navigateToMaintenance()">
            <span [translate]="'admin.maintenance.migrations.goToMaintenanceButton'"></span>
        </button>
    </div>
</div>
