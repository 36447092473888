<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addFiltreZuPopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="type"
                    name="type"
                    customType="select"
                    [(ngModel)]="selectedType"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addFiltreZuPopin.type"
                    [selectOptions]="listeTypeFiltre"
                    optionValue="code"
                    optionDisplay="libelle"
                    (onChange)="onSelectedTypeChange()"
                    [required]="true"
                    [disabled]="isPending">
            </custom-input>
            <custom-input
                    *ngIf="selectedType"
                    ngDefaultControl
                    id="filter"
                    name="filter"
                    customType="autocomplete"
                    [(ngModel)]="selectedFiltre"
                    libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.addFiltreZuPopin.valeur"
                    [autocompleteType]="getAutocompleteType()"
                    [autocompleteFilter]="getAutocompleteFilter()"
                    [idName]="getIdName()"
                    [required]="true"
                    [disabled]="isPending">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]>
        <span [translate]="'global.actions.annuler'"></span>
    </button>
    <button mat-flat-button color="primary" (click)="add()" [disabled]="form.invalid || isPending">
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>