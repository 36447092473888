import {Injectable,Optional,SkipSelf} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {NavigationEnd,NavigationStart,Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorageService} from "@domain/common/services/session-storage.service";
import {BehaviorSubject} from 'rxjs';
import {first} from "rxjs/operators";

/**
 * Service de gestion de la locale
 */
@Injectable({
    providedIn: 'root',
})
export class LocaleService {
    /** Constantes */
    private static SERVICE_NAME: string = "LocaleService";
    private static CODE_LANGUE_STORAGE_KEY: string = "codeLangue";
    private static VARIANT_STORAGE_KEY: string = "variant";

    /** État */
    private isInitialized = false;

    /** Observer permettant de connaitre l'état courant de navigation */
    private navigationObserver: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Variant */
    private variant: string;

    /** Locale active */
    get currentLocale(): string { return this.variant; }

    /** Flag pour empêcher ponctuellement le rechargement si false */
    private isReload: boolean = true;

    /**
     * Retourne le code de la langue active
     */
    getCodeLangueActive(): string {
        return this.sessionStorageService.fetch(LocaleService.SERVICE_NAME,LocaleService.CODE_LANGUE_STORAGE_KEY).replace(/"/g, "");
    }

    /**
     * Constructeur
     */
    constructor(
        private router: Router,
        private translate: TranslateService,
        private sessionStorageService: SessionStorageService,
        @Optional() @SkipSelf() otherInstance: LocaleService,
    ) {
        //Protection contre les doublons
        if (otherInstance) throw 'LocaleService should have only one instance.';

        //Observable permettant de récupérer les évènements de navigation
        router.events.map((val) => {
            if (val instanceof NavigationStart) {
                //Navigation en cours
                return true;
            } else if (val instanceof NavigationEnd) {
                //Navigation terminée
                return false;
            }
        }).subscribe(isNavigating => {
            if (isNavigating != null) {
                //Envoi de l'état sur la navigation en cours
                this.navigationObserver.next(isNavigating);
            }
        });
    }

    /**
     * Abonnement à la mise à jour de la locale pour reparcours des routes
     */
    private subscribeToLangChange() {
        this.translate.onLangChange.subscribe(async (langChangeEvent) => {
            //Comme on va recharger la route, on vérifie que la navigation n'est pas en cours, sinon on attend la fin
            this.navigationObserver
                //Attente de la fin de la navigation
                .pipe(first((isNavigating) => !isNavigating))
                .subscribe(async () => {
                    //Si le rechargement est autorisé
                    if (this.isReload) {
                        //Rechargement de la route pour actualiser l'affichage des directives
                        this.reloadCurrentRoute();
                    } else {
                        //Sinon autorisation du rechargement pour la suite
                        this.isReload = true;
                    }
                });
        });
    }

    /**
     * Hack pour forcer le rechargement d'une route sans recharger l'application.
     * Utile dans le cas d'un router configuré avec l'option onSameUrlNavigation='ignore' (valeur par défaut)
     */
    private reloadCurrentRoute() {
        //Récupération de la route courante
        const currentUrl = this.router.url;

        //Navigation vers un composant vide sans changer l'URL pour que ça soit transparent pour l'utilisateur
        this.router.navigate(['Blank'], {skipLocationChange: true}).then(() => {
            //Navigation vers l'URL précédente
            this.router.navigate([currentUrl]);
        });
    }

    /**
     * Initialisation de la locale
     *
     * @param codeLangue    le code de langue
     * @param variant       le variant
     */
    initLocale(codeLangue: string,variant: string) {
        //Retour si déjà fait
        if (this.isInitialized) return;

        //Abonnement aux changements de locale
        this.subscribeToLangChange();

        //Récupération de la locale depuis le SessionStorage
        const storageCodeLangue: string = this.sessionStorageService.fetch(LocaleService.SERVICE_NAME,LocaleService.CODE_LANGUE_STORAGE_KEY);

        //Récupération du variant depuis le SessionStorage
        const storageVariant: string = this.sessionStorageService.fetch(LocaleService.SERVICE_NAME,LocaleService.VARIANT_STORAGE_KEY);

        //Locale active
        this.setLocale(!!storageCodeLangue ? storageCodeLangue.replace(/"/g, "") : codeLangue,!!storageVariant ? storageVariant.replace(/"/g, "") : variant,false);

        //Basculement du flag d'initialisation
        this.isInitialized = true;
    }

    /**
     * Changement de la locale active
     *
     * @param codeLangue    le code de langue
     * @param variant       le variant
     * @param isReload      blocage temporaire du rechargement si false (true par défaut)
     */
    async setLocale(codeLangue: string,variant: string,isReload: boolean = true) {
        //Récupération du flag de rechargement
        this.isReload = isReload;

        //Import dynamique de la locale Angular
        const locale = await import(`@angular/common/locales/${variant}.js`);

        //Installation de la locale Angular
        registerLocaleData(locale.default);

        //Application du variant
        this.variant = variant;

        //Locale active
        this.translate.use(codeLangue);

        //Stockage de la locale dans le SessionStorage
        this.sessionStorageService.save(LocaleService.SERVICE_NAME,LocaleService.CODE_LANGUE_STORAGE_KEY,codeLangue);

        //Si présence d'un variant
        if (variant) {
            //Stockage du variant dans le SessionStorage
            this.sessionStorageService.save(LocaleService.SERVICE_NAME,LocaleService.VARIANT_STORAGE_KEY,variant);
        } else {
            //Purge du variant dans le SessionStorage
            this.sessionStorageService.remove(LocaleService.SERVICE_NAME,LocaleService.VARIANT_STORAGE_KEY);
        }
    }
}
