import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {FiltreZU} from "@domain/zone-utilisateur/filtre-zu";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'un item de la liste des filtres d'une ZU
 */
@Component({
	host: {'data-test-id': 'filtre-zu-list-item'},
	templateUrl: './filtre-zu-list-item.component.html'
})
export class FiltreZuListItemComponent extends ListViewItem<FiltreZU> {
	/**
	 * Constructeur
	 *
	 * @param confirmService le service de confirmation d'action
	 * @param translateService le moteur de traduction
	 * @param zoneUtilisateurService le service de gestion des ZU
	 * @param toastrService le toaster
	 */
	constructor(
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private zoneUtilisateurService: ZonesUtilisateursService,
		private toastrService: ToastrService
	) {
		super();
	}

	/**
	 * Suppression du filtre courant
	 */
	delete(): void {
		//Confirmation d'action
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service de suppression
				this.zoneUtilisateurService.deleteFiltre(this.data)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du result
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Rechargement de la liste
							this.liste.refresh();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}
