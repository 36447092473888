import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Classe de transfert des {@link ParticipantExterne} vers le front
 */
export class ParticipantExterne implements ListItem {
	/** Identifiant du participant */
	idParticipantExterne?: number;

	/** Nom */
	nom?: string;

	/** Prénom */
	prenom?: string;

	/** Société */
	societe?: string;

	/** Référence */
	reference?: string;

	/** Téléphone */
	telephone?: string;

	/** Indique si le téléphone a été modifié par l'utilisateur */
	telephoneChanged?: boolean;

	/** Email */
	email?: string;

	/** Fonction */
	fonction?: string;

	/** Complément 1 */
	add1?: string;

	/** Complément 2 */
	add2?: string;

	/** Complément 3 */
	add3?: string;

	/** Complément 4 */
	add4?: string;

	/** Actif ? */
	actif?: boolean;

	/** Type */
	type?: TypeExterne;

	/**
	 * Constructeur
	 *
	 * @param participantExterne Participant externe source
	 */
	constructor(participantExterne?: ParticipantExterne) {
		if (participantExterne) {
			Object.assign(this,participantExterne);
		}
	}

	getKey(): number {
		return this.idParticipantExterne;
	}
}

/**
 * Type de participant externe
 */
export enum TypeExterne {
	ADMINISTRATEUR = 1,
	UTILISATEUR = 2
}