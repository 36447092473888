import { Component,Input,OnInit,ViewChild,ElementRef } from '@angular/core';

@Component({
    selector: 'thumb',
    templateUrl: './thumb.component.html',
    styleUrls: ['./thumb.component.scss']
})
export class ThumbComponent implements OnInit {
    /** Fichier **/
    @Input('file') file: File;

    /** Canvas **/
    @ViewChild('thumb') thumb: ElementRef;

    /**
     * Constructeur
     */
    constructor() {

    }

    /**
     * Initialisation
     */
    ngOnInit() {
        let fileReader: FileReader = new FileReader();

        //Création du lecteur de fichier
        fileReader.onload = (f: any) => {
            //Définition de l'image de fond
            this.thumb.nativeElement.style.backgroundImage = 'url('+f.target.result as string+')';            
        }

        //Lecture du fichier
        fileReader.readAsDataURL(this.file);
    }
}