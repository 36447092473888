import {NgModule} from '@angular/core';
import {EnveloppeListComponent} from './liste/enveloppe-list.component';
import {EnveloppeListItemComponent} from './liste/enveloppe-list-item.component';
import {ShareModule} from "../../share/share.module";
import {EnveloppeComponent} from './detail/enveloppe.component';
import {DetailEnveloppeListComponent} from './detail/liste/detail-enveloppe-list.component';
import {DetailEnveloppeListItemComponent} from './detail/liste/detail-enveloppe-list-item.component';
import {BudgetService} from "./budget.service";
import {SuiviBudgetComponent} from "./suivi/suivi-budget.component";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";

@NgModule({
    imports: [ShareModule,PreviewAlerteModule],
    declarations: [
        EnveloppeComponent,
        EnveloppeListComponent, EnveloppeListItemComponent,
        DetailEnveloppeListComponent, DetailEnveloppeListItemComponent,
        SuiviBudgetComponent
    ],
    exports: [
        SuiviBudgetComponent
    ],
    providers: [BudgetService]
})
export class BudgetModule { }
