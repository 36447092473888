import {Ville} from "../geographie/ville";
import {Pays} from "../geographie/pays";
import {User} from "../user/user";
import {ListeAlertes} from "../common/alerte/listeAlertes";
import {GeographieView} from "../geographie/geographieView";
import {LieuOD} from "../lieu/lieuod";
import {TypeEntite,TypeEntiteOD} from "../typeentite/typeEntite";
import {Analytique} from "@components/analytique/analytique";
import {OdOmp} from "./od-omp";
import {EnumEtat} from "../workflow/etat";
import {TypePortee} from "../workflow/workflow";
import {ZoneUtilisateur} from "../zone-utilisateur/zone-utilisateur";
import {ZoneUtilisateurSaisie} from "../zone-utilisateur/zone-utilisateur-saisie";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {MapAction} from "../workflow/mapAction";
import {TranslateService} from "@ngx-translate/core";
import {TypeAiguillageLiteral} from "../voyage/travel/constants";
import {PeriodeDTO} from "@domain/exercice/periodeDTO";
import {OdPipeTransform} from "@share/pipe/od.pipe";

export interface Axe5DTO {
	idAxe5: number;
	libelle: string;
	reference: string;
}

export interface Axe6DTO {
	idAxe6: number;
	libelle: string;
	reference: string;
}

export interface StatutDTO {
	code: string;
	libelle: string;
	idEtat: EnumEtat;
}

export interface OrgaDTO {
	idOrga?: number;
	code?: string;
	libelle: string;
	numService?: string;
}

export interface DossierDTO {
	idDossier: number;
	libelle: string;
	reference: string;
}

export class Od extends AbstractObjetWorkflow {
	idOd: number;
	typeEntite: TypeEntiteOD;
	objet: string;
	statut: StatutDTO;
	listeVentilations: Analytique[];
	depart_le: Date;
	retour_le: Date;
	montantProposition: number;
	pour_le: Date;
	lieuDepart: number;
	lieuRetour: number;
	lieuDepartOd: LieuOD;
	lieuRetourOd: LieuOD;
	lieu: string;
	periode: PeriodeDTO;
	heureDepart: string;
	heureRetour: string;
	obs: string;
	remarque: string;
	idFaitGenerateur: number;
	referenceFaitGenerateur: string;
	dateFaitGenerateur: Date;
	listeAlertes: ListeAlertes;
	ville: Ville;
	pays: Pays;
	deviseTravel: string;
	user: User;
	plafondAvance?: number;
	urgent: boolean;
	private prestationAgv: boolean;
	demandeAvance: boolean;
	tempsForfaitSaisis: boolean;
	listeZU: ZoneUtilisateur[];
	listeZUSaisies: ZoneUtilisateurSaisie[];
	dateDebRL: Date;
	listeHistoWorkflow: MapAction;
	aiguillage: TypeAiguillageLiteral;
	collabPresence: CollabPresence;

	/* Attributs ajoutés lors du chargement d'un OD pour faciliter l'utilisation dans les sous-composants */
	destination: GeographieView;
	omPermanent: OdOmp;

	constructor(dto?: any) {
		super();
		this.prestationAgv = true;
		Object.assign(this,dto);
		if (dto) {
			if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
		}
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 * @returns l'identifiant de l'objet
	 */
	getKey(): number {
		return this.idOd;
	}

	getId(): number {
		return this.idOd;
	}

	getPortee(): TypePortee {
		return TypePortee.OD;
	}

	getListeAlertes(): ListeAlertes {
		return this.listeAlertes;
	}

	getListeVentilation(): Array<Analytique> {
		return this.listeVentilations;
	}

	getTypeEntite(): TypeEntite {
		return this.typeEntite as TypeEntiteOD;
	}

	getMapAction(): MapAction {
		return this.listeHistoWorkflow;
	}

	setListeAlertes(listeAlertes: ListeAlertes): void {
		this.listeAlertes = listeAlertes;
	}

	/**
	 * Getter pour le collaborateur
	 */
	getCollaborateur(): String {
		if (!this.user) { return null; }
		return (this.user.nom ? this.user.nom + ' ' : '')
			+ (this.user.prenom ? this.user.prenom : '');
	}

	/**
	 * Getter pour la liste de rôles format texte
	 */
	getRoles(): string {
		return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
	}

	/**
	 * Getter pour le nombre de rôles
	 */
	getRolesCount(): number {
		return this.getMapAction()?.roles?.length;
	}

	/**
	 * Getter pour l'ID Collab
	 */
	getIdCollab(): number {
		return this.user?.idUser;
	}

	/**
	 * Getter pour l'intitulé de l'objet
	 * @param translateService service de traduction
	 */
	getLibelleObjet(translateService: TranslateService): string {
		return OdPipeTransform(this,translateService);
	}

	/**
	 * Getter pour la validité de l'objet
	 */
	isValid(): boolean {
		//Hardcodé en attendant l'implémentation des données calculées
		return this.objet != null && this.depart_le != null && this.retour_le != null;
	}
}

/** Énumération de toutes les valeurs possibles pour le champ collabPresence */
export enum CollabPresence {
	NON_GERE = 0,
	PARTICIPE = 1,
	PARTICIPE_PAS = 2,
	INVITE = 3
}