import {Component,ElementRef,EventEmitter,Input,NgZone,OnInit,Output,ViewChild} from '@angular/core';
import {DomSanitizer,SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute,NavigationExtras,Router} from '@angular/router';

import {LayoutService} from './layout.service';
import {FRAME_ROUTES} from "@share/layout/frame-routes";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";

@Component({
	selector: 'custom-frame',
    templateUrl: './frame.component.html',
	styles: ['::ng-deep please-wait.no-bg .mat-card { background: none; }']
})
export class FrameComponent implements OnInit {
	/** Source */
	public safeSrc: SafeResourceUrl;

	/** Indicateur de chargement de la frame */
	isFrameLoading: boolean = true;

	/** Frame spécifique à charger */
	@Input() frame: string;

	/** ID de l'objet à charger */
	@Input() idParam: string;

	/** Masquage du titre */
	@Input() isTitleHidden: boolean = false;

	/** Évènement de chargement terminé */
	@Output() load = new EventEmitter();

	/** Indicateur de fin de chargement */
	private isFirstLoad: boolean = true;

	/** iFrame HTML */
	@ViewChild("frameHtml") frameHtml: ElementRef;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService,
				private domSanitize: DomSanitizer,
				private router: Router,
				private ngZone: NgZone,
				private route: ActivatedRoute,
				private statutWorkflowService: StatutWorkflowService,
				private migrationsService: MigrationsService
	) {
		//Enregistrement global de la méthode de navigation
		(<any>window).navigateTo = this.navigateTo.bind(this);

		//Enregistrement global de la méthode d'enregistrement des URLs consultées
		(<any>window).saveUrl = this.saveUrl.bind(this);

		//Enregistrement global de la méthode de rafraichissement du statut de l'application
		(<any>window).loadApplicationStatut = this.loadApplicationStatut.bind(this);

		//Enregistrement global de la méthode de rafraichissement du statut de la synchro Workflow
		(<any>window).refreshStatutSynchroWF = this.refreshStatutSynchroWF.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la frame spécifique à charger
		const frame = this.frame ?? this.route.snapshot['_routerState']?.url;

		//Récupération d'un éventuel ID objet à charger
		const idParam = FRAME_ROUTES.get(frame).idParam ? (this.frame ? this.idParam : this.route.data['idParam']) : '';

		//Application de la frame spécifique
		frame && FRAME_ROUTES.get(frame)?.path && ((<any>window).isAngularFrameAdmin = true) && this.layoutService.setFrameLocation(FRAME_ROUTES.get(frame).path + idParam);

		//Purge de l'URL
		this.safeSrc = undefined;

		//Définition de l'URL à appliquer
		this.defineUrl();

		//Masquage du titre si nécessaire
		(<any>window).isAngularFrameAdminTitleHidden = this.isTitleHidden;
	}

	/**
	 * Rafraichissement du statut de l'application'
	 */
	loadApplicationStatut(): void {
		this.migrationsService.checkAppliStatut();
	}

	/**
	 * Rafraichissement du statut de la synchro Workflow
	 */
	refreshStatutSynchroWF(): void {
		this.statutWorkflowService.loadSynchroWFStatut();
	}

	/**
	 * Méthode appelée lorsque l'iFrame a terminé un chargement
	 */
	loaded() {
		//Si la page n'a pas encore fini de charger (premier appel) et qu'on est sous chrome
		if (this.isFirstLoad && this.getBrowserInfo() == 'chrome') {
			//Indication du premier appel
			this.isFirstLoad = false;
		} else {
			//Sinon retour à la normale
			this.isFrameLoading = false;
			this.isFirstLoad = true;

			//Emission de l'évènement
			this.load.emit();
		}
	}

	/**
	 * Définition de l'URL à appliquer
	 */
	private defineUrl(): void {
		//Sécurisation de l'URL demandée
		const url = this.layoutService.getFrameLocation() && this.domSanitize.bypassSecurityTrustResourceUrl(this.layoutService.getFrameLocation()) || null;

		//Vérification du changement d'URL
		if (this.safeSrc != url) {
			//L'URL va être changée et la frame va commencer à se charger
			this.isFrameLoading = true;
		}

		//Définition de l'URL
		this.safeSrc = url;
	}

	/**
	 * Gestion de la navigation depuis la frame
	 */
	public navigateTo(route: string, params?: NavigationExtras) {
		this.ngZone.run(
			//Navigation
			() => params && this.router.navigate([route], params) || this.router.navigate([route])
		);
	}

	/**
	 * Enregistrement de l'URL contenue dans l'iframe
	 */
	public saveUrl(url: string) {
		//Vérification de la présence d'une URL
		if (url)
			//Enregistrement de l'url
			this.layoutService.setFrameLocation(url);
	}

	/**
	 * Récupération du navigateur
	 */
	private getBrowserInfo(): string {
		//Récupération du UserAgent
		const userAgent = window.navigator.userAgent;

		//Diverses possibilités courantes
		const browsers = { chrome: /chrome/i, firefox: /firefox/i, safari: /safari/i, ie: /internet explorer/i, edge: /edge/i };

		//Parcours des possibilités
		for (let key in browsers) {
			//Si le UserAgent contient la clé testée
			if (browsers[key].test(userAgent)) {
				//On renvoie le navigateur trouvé
				return key;
			}
		}

		//Retour par défaut
		return 'unknown';
	}
}
