import { Component } from "@angular/core";

/**
 * Onglet factures fournisseurs
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'factures-fournisseurs'},
    templateUrl: './factures-fournisseurs.component.html'
})
export class FacturesFournisseursComponent {}
