import {Component,Inject,Input,OnInit,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypePortee} from "@domain/workflow/workflow";
import {Localisation} from "@domain/geolocalisation/localisation";
import {TypeAdresse,TypeAdresseEnum,TypeAdresseRef} from "@domain/geolocalisation/type-adresse";
import {forkJoin,Observable,of} from "rxjs";
import {GeolocalisationTool} from "@domain/geolocalisation/geolocalisationTool";
import {MatTabGroup} from "@angular/material/tabs";
import {TypeGeolocalisation} from "@domain/geolocalisation/typeGeolocalisation";
import {Lieu} from "@domain/lieu/lieu";
import {LieuService} from "../lieu.service";
import {LieuLocalisationComponent} from "./localisation/lieu-localisation.component";
import {Etablissement} from "@domain/lieu/etablissement";
import {Adresse} from "@domain/profil/adresse";

@Component({
    templateUrl: './lieu-selection.component.html',
    host: {'data-test-id': 'lieu-selection'},
})
export class LieuSelectionComponent implements OnInit {
    /** Définition du type pour le template */
    readonly TypePortee = TypePortee;

    /** Index de l'onglet à afficher suivant le type de lieu */
    @Input() tabIndex: number = 0;

    /** Composant de saisie d'une adresse */
    @ViewChild(LieuLocalisationComponent)
    lieuLocalisationComponent: LieuLocalisationComponent;

    /** Le groupe d'onglets */
    @ViewChild("matTabGroup")
    matTabGroup: MatTabGroup;

    /** Type de l'adresse */
    typeAdresse: TypeAdresse;

    /** Liste des adresses personnelles / professionelles */
    listeAdresse:Array<Adresse>;

    /** Liste des établissements */
    listeEtablissement: Array<Etablissement>;

    /** Objet utilisé pour la saisie, qui sera initialisé avec les valeurs du lieu passé en paramètre de la popup */
    localisation: Localisation = {};

    /** Configuration de la géolocalisation, désactivée par défaut */
    geolocalisationTool: GeolocalisationTool = {
        geolocalisationTool: TypeGeolocalisation.DISABLED
    };

    /** Paramètre de configuration indiquant si la saisie d'une adresse est autorisée */
    isSaisieAutorisee: boolean = false;

    /** Getter permettant de savoir si on est dans le contexte du profil ou non */
    get isProfilAdresse(): boolean {
        return this.data.idPortee == TypePortee.PRF;
    }

    /** Getter permettant de savoir si on est dans le contexte de l'admin ou non */
    get isAdminAdresse(): boolean {
        return this.data.idPortee == TypePortee.ADM;
    }

    /**
     * Constructeur
     */
    constructor(private lieuService: LieuService,
                private matDialogRef: MatDialogRef<any,any>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    getGeolocalisationTool: () => Observable<GeolocalisationTool>,
                    loadAdresses: () => Observable<{ listeAdresses: Array<Adresse>,listeEtablissements: Array<Etablissement> }>,
                    isSaisieAutorisee: boolean,
                    idPortee: TypePortee,
                    idUser: number,
                    typeEntiteParam: any,
                    typeLieu: number,
                    lieu: Lieu
                }) {

        //Vérification de l'autorisation de saisie d'une adresse depuis la configuration du type entité
        this.isSaisieAutorisee = this.data.isSaisieAutorisee;

        //Récupération du type d'adresse correspondant au type de lieu
        this.typeAdresse = TypeAdresseRef.values.find(adr => adr.value === this.data.typeLieu);

        //Index de l'onglet à afficher suivant le type d'adresse déterminé
        this.tabIndex = this.typeAdresse?.tabIndex;

        //Construction d'un objet de géolocalisation si le lieu provient d'une saisie
        if (this.typeAdresse?.type === TypeAdresseEnum.AUTRE) {
            //Initialisation de l'objet
            this.localisation = {
                adresse: this.data.lieu?.adresse,
                rue: this.data.lieu?.rue,
                codePostal: this.data.lieu?.codePostal,
                ville: this.data.lieu?.ville,
                pays: this.data.lieu?.pays,
                codePays: this.data.lieu?.codePays
            }
        }

    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Utilisation d'un forkjoin pour pouvoir attendre l'execution de tous les appels ajax
        forkJoin([
            this.data.getGeolocalisationTool(),
            [TypePortee.PRF,TypePortee.ADM].includes(this.data.idPortee) ? of(null) : this.data.loadAdresses()
        ]).subscribe({
            next: ([geolocalisationTool,adresses]) => {
                //Récupération de la configuration de la géolocalisation et surcharge de la conf initialisée par défaut
                this.geolocalisationTool = {
                    ...this.geolocalisationTool,
                    ...geolocalisationTool
                };

                //Récupération de la liste des adresses et des établissements
                if (adresses) {
                    this.listeAdresse = adresses.listeAdresses;
                    this.listeEtablissement = adresses.listeEtablissements ?? [];
                }
            }
        });
    }

    /**
     * Retourne True si l'onglet affiché est celui de la saisie, False sinon.
     */
    setTabSaisieActif() {
        this.tabIndex = TypeAdresseRef.get(TypeAdresseEnum.AUTRE).tabIndex;
    }

    /**
     * Sélection d'une adresse personnelle/professionnelle
     *
     * @param adresse Adresse personnelle/professionnelle (source : onglet mes adresses)
     */
    selectAdresse(adresse: Adresse): void {
        //Sur le profil, dans le cas d'une adresse incomplete, on passe à l'onglet de saisie pour la compléter
        if (this.data.idPortee === TypePortee.PRF && this.lieuService.isAdresseIncomplete(adresse)) {
            this.setTabSaisieActif();
            this.lieuLocalisationComponent.initFromAdresse(adresse);
        } else {
            //Fermeture de l'écran
            this.matDialogRef.close({
                adresse: adresse.rue,
                objet: adresse,
                type: adresse.type ? TypeAdresseRef.find(adresse.type).value : null
            });
        }
    }

    /**
     * Sélection d'un établissement
     *
     * @param etablissement Etablissement (source : onglet Etablissements)
     */
    selectEtablissement(etablissement: Etablissement): void {
        //Fermeture de l'écran
        this.matDialogRef.close({
            adresse: etablissement.rue,
            objet: etablissement,
            type: TypeAdresseRef.get(TypeAdresseEnum.ETABLISSEMENT).value
        });
    }

    /**
     * Sélection d'une adresse saisie manuellement ou géolocalisée.
     *
     * @param localisation  Objet représentant la localisation de l'adresse.
     */
    selectAutre(localisation?: Localisation): void {
        if (!localisation) {
            this.matDialogRef.close();
        } else {
            //Sélection de l'adresse à la fermeture de la popin
            this.matDialogRef.close({
                adresse: localisation?.adresse,
                objet: localisation,
                type: TypeAdresseRef.get(TypeAdresseEnum.AUTRE).value
            });
        }
    }

}
