import {Action} from '../domain/action';

/**
 * Actions
 */
export const UPDATE_LAYOUT_DATA = 'UPDATE_LAYOUT_DATA';
export const INIT_LAYOUT = 'INIT_LAYOUT';

/**
 * Interface
 */
export interface LayoutState {
    /** Données **/
    routeData?: any
}

/**
 * Création du reducer
 */
export function layoutReducer(state: LayoutState = {},action: Action<any>): LayoutState {
    //Vérification de l'action
    switch (action.type) {
    case UPDATE_LAYOUT_DATA:
        //Titre de la page
        state = {
            ...state,
            routeData: action.payload   
        };
        break;
    }

    //Retour de l'état
    return state;
}
