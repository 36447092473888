<h1 mat-dialog-title>
    <span>
        {{ 'admin.maintenance.migrations.onglets.parametres.messageCard.popup.title' | translate }}
    </span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="popup-content">
    <form autocomplete="off" #passwordForm="ngForm" (submit)="checkPassword(passwordForm)">
        <fieldset [disabled]="isLoading">
            <div class="row">
                <label for="adminPassword" class="col-md-3" style="height: 60px">
                    <span [translate]="'admin.maintenance.migrations.onglets.parametres.messageCard.popup.mdpAdmin'"></span>
                </label>
                <div class="col-md-9">
                    <mat-form-field class="d-flex">
                        <input matInput type="password" id="adminPassword" name="adminPassword" autocomplete="new-password" [(ngModel)]="typedPassword" required>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
    <div class="text-right">
        <button type="submit" color="primary" mat-flat-button (click)="checkPassword(passwordForm)" [disabled]="isLoading">
            <span [translate]="'global.actions.confirmer'"></span>
        </button>
    </div>
</div>
