import {TravelHubSBTConfig} from "@domain/travel/travel-hub-sbt-config";

/**
 * Contient les informations en commun entre un CustomField et un TripField
 *
 * @author Laurent Convert
 * @date 19/12/2023
 */
export abstract class AbstractField {
	sbtConfig: TravelHubSBTConfig;
	code: string;
	dateUpdated: Date;
	actif: boolean;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	protected constructor(dto?: AbstractField) {
		if (dto) {
			Object.assign(this,dto);
		}
	}
}