<div class="lvi-content">
    <div class="avatar">
        <i [ngClass]="getIconeClass(data.fonction)"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.fonction"><strong [translate]="'workflow.roles.liste.profil'"></strong>{{ getTraductionProfil(data.fonction) }}</li>
            <li *ngIf="data.code"><strong [translate]="'workflow.roles.liste.code'"></strong>{{ data.code }}</li>
            <li><strong [translate]="'workflow.roles.liste.delegable'"></strong>{{ (data.delegable ? 'global.oui' : 'global.non') | translate }}</li>
            <li *ngIf="data.defaut"><strong [translate]="'workflow.roles.liste.defaut'"></strong>{{ 'global.oui' | translate }}</li>
        </ul>
    </div>
</div>