import {Component, Input, OnInit} from "@angular/core";
import {ListView, TypeComparaison, TypeFilter} from "@domain/common/list-view";
import {DeviseTauxItemComponent} from "@components/admin/bibliotheque/devises/devise-infos/taux/devise-taux-item/devise-taux-item.component";
import {CoursDeviseItem} from "@domain/admin/bibliotheque/devises/coursDeviseItem";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DeviseTauxModifyComponent} from "@components/admin/bibliotheque/devises/devise-infos/taux/devise-taux-modify/devise-taux-modify.component";

/**
 * Composant d'affichage de la liste des taux d'une devise
 */
@Component({
    host: {'data-test-id': 'devise-taux'},
    selector: 'devise-taux',
    templateUrl: './devise-taux.component.html'
})
export class DeviseTauxComponent implements OnInit {
    /** Code de la devise sélectionnée */
    @Input() codeDevise: string;

    /** Liste des taux, pour utiliser le composant ListViewComponent */
    listeTaux: ListView<CoursDeviseItem, DeviseTauxItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param matDialog Boite de dialogue permettant de créer un taux pour la devise
     */
    constructor(
        protected translateService: TranslateService,
        private toastrService: ToastrService,
        private matDialog: MatDialog
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Création de la liste des taux des devises
        this.listeTaux = new ListView<CoursDeviseItem, DeviseTauxItemComponent>({
            uri: `/controller/Devise/listeAllTauxForDevise?code_devise=`+ this.codeDevise,
            title: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.title'),
            component: DeviseTauxItemComponent,
            extraOptions: {
                onSelect: this.onSelect.bind(this)
            },
            isFilter: true,
            defaultOrder: '-dAppli',
            listeFilters: [
                {
                    clef: 'dAppli',
                    title: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.dateApplication'),
                    isDefault: true,
                    type: TypeFilter[TypeFilter.DATE]
                },{
                    clef: 'taux',
                    title: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.cours'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'codeDeviseContrepartie',
                    title: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.deviseContrepartie'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'codeOrigine',
                    title: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.origine'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }
            ]
        });

        //Définition des actions possibles pour la liste des taux (ajout d'un taux)
        this.listeTaux.listeActions = [{
            icon: 'add',
            onPress: () => this.addTaux(),
            isVisible: () => true
        }]
    }

    /**
     * Méthode appelée lorsqu'un taux a été sélectionné
     *
     * @param taux Taux sélectionné
     */
    onSelect(taux: CoursDeviseItem) {
        //Ouverture de la boîte de dialogue pour modifier le taux sélectionné
        this.matDialog.open(DeviseTauxModifyComponent, {
            width: '60%',
            data: {
                taux: taux,
                codeDevise: this.codeDevise
            }
        }).afterClosed().subscribe(refresh => {
            if (refresh) {
                //On met à jour la liste affichée
                this.listeTaux.refresh();
            }
        });
    }

    /**
     * Méthode appelée lors de l'ajout d'un taux pour la devise
     */
    addTaux() {
        //Ouverture de la boîte de dialogue permettant l'ajout d'un taux
        this.matDialog.open(DeviseTauxModifyComponent, {
            width: '60%',
            data: {
                codeDevise: this.codeDevise
            }
        }).afterClosed().subscribe(refresh => {
            if (refresh) {
                //On met à jour la liste affichée
                this.listeTaux.refresh();
            }
        });
    }
}
