<!-- Liste des adresses d'un lieu (OD / OMP) -->
<mat-card-content *ngIf="!isProfilAdresse && !isAdminAdresse">
    <div class="empty" *ngIf="!listeAdresse || listeAdresse.length === 0"><span [translate]="'lieu.aucuneAdresse'"></span></div>
    <div class="listview" *ngIf="listeAdresse && listeAdresse.length > 0">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let adr of listeAdresse">
                <div class="lvi-content">
                    <div class="avatar"><i class="material-icons-outlined">{{ getIconeForTypeAdresse(adr.type) }}</i></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" (click)="selectAdresse(adr)">{{ adr.rue }}</a>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'lieu.type.type'"></strong>{{ 'lieu.type.' + adr.type?.toLowerCase() | translate }}</li>
                            <li><strong [translate]="'lieu.actif.actif'"></strong>{{ 'global.' + (adr.actif ? 'oui' : 'non') | translate }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card-content>

<!-- Liste des adresses du profil -->
<div class="pt-2" *ngIf="isProfilAdresse || isAdminAdresse">
    <list-view [liste]="listeAdresseProfil"></list-view>
</div>
