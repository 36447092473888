import {Component,EventEmitter,Inject,Input,Output} from "@angular/core";
import {RoleLong,TypeGestionnaire} from "@domain/workflow/roleLong";
import {ProfilsAttribuables} from "@domain/workflow/profilsAttribuables";
import {WorkflowRolesService} from "@services/admin/workflow/workflow-roles.service";
import {RolesListeItemComponent} from "@components/admin/workflow/roles/roles/liste/roles-liste-item.component";

/**
 * Composant du cadre rôle dans l'onglet généralité des rôles en admin
 *
 * @author Laurent SCIMIA
 * @date 09/10/2023
 */
@Component({
	host: {'data-test-id': 'role-role'},
	selector: 'role-role',
	templateUrl: './role-role.component.html'
})
export class RoleRoleComponent {
	//Accès à l'enum pour le template
	readonly ProfilsAttribuables = ProfilsAttribuables;

	/** Rôle ouvert */
	@Input() role: RoleLong;

	/** Indique si on est en train d'enregistrer */
	@Input() isPending!: boolean;

	/** Indique si le profil du rôle a changé */
	@Output() profilChanged: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Constructeur
	 *
	 * @param roleService Service de gestion des rôles
	 */
	constructor(@Inject('WORKFLOW_ROLE_SERVICE') private roleService: WorkflowRolesService<RolesListeItemComponent>) {
	}

	/** Liste des gestionnaires pour le select */
	readonly listeOptionsGestionnaires: Array<{ libelle: string,val: number }> = [
		{
			libelle: "workflow.roles.detail.aucun",
			val: TypeGestionnaire.AUCUN
		},{
			libelle: "workflow.roles.detail.gestionnairesTout",
			val: TypeGestionnaire.TOUS
		},{
			libelle: "workflow.roles.detail.gestionnaireSuppléant",
			val: TypeGestionnaire.SUPPLEANT
		}
	];

	/** Tooltip à afficher dans le select du gestionnaire */
	getTooltip(): string {
		return this.role.typeGetionnaire === TypeGestionnaire.SUPPLEANT ? 'workflow.roles.detail.supleantTooltip' : '';
	}

	/**
	 * Indique si le changement de profil doit être bloqué
	 */
	isDisableProfilChange(): boolean {
		return this.role.backupFonction === ProfilsAttribuables.COLLABORATEUR
			&& (this.isLastRoleCollab()
				|| this.role.defaut);
	}

	/**
	 * Indique si le rôle ouvert est le dernier rôle collaborateur
	 */
	isLastRoleCollab(): boolean {
		return this.roleService.nbRolesCollab === 0
				|| this.roleService.nbRolesCollab == 1 && this.role.idRole != null;
	}

	/**
	 * Indique s'il faut désactiver la case à cocher "Défaut"
	 */
	isDisableCheckDefaut(): boolean {
		return this.role.backupDefaut || this.role.defaut && this.isPending;
	}

	/**
	 * Action au changement de profil
	 */
	onProfilChange(): void {
		//On indique un changement de profil
		this.profilChanged.emit();

		//Si on se remet sur un profil collaborateur et qu'il n'y en a pas d'autre
		if (this.role.fonction === ProfilsAttribuables.COLLABORATEUR && (this.roleService.nbRolesCollab === 0 || this.role.backupDefaut)) {
			//On rend le profil "par défaut"
			this.role.defaut = true;
			this.role.backupDefaut = true;
		}
	}

	/** Getter pour la liste des profils disponibles dans le select */
	get profilsAttribuablesListe(): Array<{ libelle: string,val: number }> {
		return ProfilsAttribuables.profilsAttribuablesListe;
	}
}
