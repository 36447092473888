import {Component} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";

/**
 * Enum pour les noms des différents onglets de la page Comptabilité/Comptes bancaires
 */
export enum Onglets {
	GENERALITES = "Generalites"
}

/**
 * Page admin de Comptabilité/Comptes bancaires
 *
 * @author Alexis BOUVARD
 * @date 18/04/2023
 */
@Component({
	host: {'data-test-id': 'comptes-bancaires'},
	templateUrl: './comptabilite-comptes-bancaires.component.html'
})
export class ComptabiliteComptesBancairesComponent {

	/** Liste des différents onglets disponibles dans le menu Comptabilité/Comptes bancaires */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: Onglets.GENERALITES,
			libelle: this.translateService.instant('admin.comptabilite.comptesBancaires.onglets.generalites'),
			url: 'Admin/Comptabilite/ComptesBancaires/Generalites'
		}
	];

	/** Index de l'onglet courant */
	selectedIndex: number;

	/**
	 * Constructeur
	 *
	 * @param translateService service de gestion de la traduction
	 */
	constructor(
		protected translateService: TranslateService,
	) {
	}
}
