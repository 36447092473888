<div class="lvi-content">
    <div class="avatar">{{ weekday?.date }}</div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ weekday?.dateString }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngFor="let regle of weekday?.regles">
                <span class="r-flex">
                    <strong>{{ regle.libelle }}</strong>

                    <!-- Bloc affiché si au moins un frais -->
                    <ng-container *ngIf="!!regle.selectedFrais">
                        <mat-checkbox *ngIf="canModifier || canCompleter" class="mr-1" name="degrev" color="primary" [(ngModel)]="regle.details[0].used"
                                      (click)="$event.stopPropagation();"
                                      (change)="changeDegrevement(regle)"></mat-checkbox>

                        <!-- Affichage du drapeau -->
                        <span *ngIf="regle.getCodePays()" class="mr-1" [ngClass]="{ 'back-disabled': isFraisNotUsed(regle) }">
                            <span class="flag-icon" [ngClass]="'flag-icon-'+(regle.getCodePays() | lowercase)"></span>
                        </span>

                        <!-- Bloc visible si en modification et que sélection d'un frais spécial possible -->
                        <ng-container *ngIf="(canModifier || canCompleter) && regle.frais.length > 1;else blocReadonly">
                            <!-- Dropdown de sélection d'un frais spécial -->
                            <mat-select #selectTaux="matSelect"
                                        (selectionChange)="changeFrais(regle)"
                                        [(ngModel)]="regle.selectedFrais"
                                        [compareWith]="isSameFrais"
                                        [disabled]="isFraisNotUsed(regle)"
                                        [nioTooltip]="templateTooltip"
                                        [nioTooltipContext]="{weekday:weekday,regle:regle,overFraisList:false,montantFraisSpecial:computeMontantFraisSpecial(regle,regle?.selectedFrais)}">
                                <mat-option *ngFor="let frais of regle.frais" [value]="frais"
                                            #tooltip="nioTooltip"
                                            [nioTooltip]="templateTooltip"
                                            [nioTooltipContext]="{weekday:weekday,regle:regle,overFraisList:true,montantFraisSpecial:computeMontantFraisSpecial(regle,frais)}"
                                            nioTooltipPosition="right"
                                            [nioTooltipDisabled]="!selectTaux.panelOpen"
                                            (onSelectionChange)="tooltip.closeTooltip()" >
                                    <ng-container *ngTemplateOutlet="templateFrais; context:{regle:regle,frais:frais}"></ng-container>
                                </mat-option>
                            </mat-select>
                        </ng-container>

                        <!-- Bloc visible si pas de modification ou qu'il n'y a pas de frais spécial -->
                        <ng-template #blocReadonly>
                            <span class="align-middle ml-1"
                                  [ngClass]="{ 'back-disabled': isFraisNotUsed(regle) }"
                                  [nioTooltip]="templateTooltip"
                                  [nioTooltipContext]="{weekday:weekday,regle:regle,overFraisList:false,montantFraisSpecial:computeMontantFraisSpecial(regle,regle?.selectedFrais)}">
                                <ng-container *ngTemplateOutlet="templateFrais; context:{frais:regle.selectedFrais}"></ng-container>
                            </span>
                        </ng-template>
                    </ng-container>

                    <!-- Bloc affiché si pas de frais -->
                    <span *ngIf="!regle.selectedFrais" [translate]="'od.engagements.indemnites.degrevements.aucun'"></span>
                </span>
            </li>
        </ul>
    </div>
</div>

<!-- Template d'affichage d'un frais -->
<ng-template #templateFrais let-frais="frais">
    <span>
        <span *ngIf="frais?.libelleSpecial != null">{{frais?.libelleSpecial}}</span>
        <ng-container *ngIf="frais?.libelleSpecial == null">
            <span *ngIf="frais?.fraisMissionRegion?.ville">{{frais.fraisMissionRegion.ville.libelle}}, {{frais.fraisMissionRegion.ville.pays.libelle}}</span>
            <span *ngIf="frais?.fraisMissionRegion?.region">{{frais.fraisMissionRegion.region.libelle}} </span>
            <span *ngIf="frais?.fraisMissionRegion?.region?.pays">, {{frais.fraisMissionRegion.region.pays.libelle}}</span>
            <span *ngIf="frais?.fraisMissionRegion?.pays">{{frais.fraisMissionRegion.pays.libelle}}</span>
            <span *ngIf="frais?.fraisMissionRegion?.zone">{{frais.fraisMissionRegion.zone.libelle}}</span>
        </ng-container>
    </span>
</ng-template>

<!-- Template du tooltip d'information d'un frais spécial -->
<ng-template #templateTooltip
             let-overFraisList="overFraisList"
             let-regle="regle"
             let-montantFraisSpecial="montantFraisSpecial">
    <div class="row">
        <label class="col-sm-12 justify-content-center m-0" [ngSwitch]="regle.regleAttribution?.modeGestion">
            {{ weekday?.dateString }}
        </label>
        <label class="col-sm-12 justify-content-center m-0" [ngSwitch]="regle.regleAttribution?.modeGestion">
            {{ regle.libelle }}
            <span *ngSwitchCase="TypeModeGestion[TypeModeGestion.TEMPS_PASSE]">&nbsp;({{regle.regleAttribution?.dureeMin}} - {{regle.regleAttribution?.dureeMax}})</span>
            <span *ngSwitchCase="TypeModeGestion[TypeModeGestion.TRANCHE_HORAIRE]">&nbsp;({{regle.regleAttribution?.heureDeb}} - {{regle.regleAttribution?.heureFin}})</span>
        </label>
    </div>
    <hr />
    <div class="row">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.mode.mode'"></label>
        <div class="col-sm-6 pl-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.mode.' + regle?.details[0]?.ij?.regleAttribution?.modeGestion.toLocaleString()?.toLowerCase()"></div>
    </div>
    <div class="row">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.localisation'"></label>
        <div class="col-sm-6 pl-1"><span *ngIf="regle?.details[0]?.ij?.ville">{{regle?.details[0]?.ij?.ville?.libelle}}, </span>{{regle?.details[0]?.ij?.pays?.libelle}}</div>
    </div>
    <div class="row">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.prestation'"></label>
        <div class="col-sm-6 pl-1">
            <span *ngFor="let detail of regle.details"><span *ngIf="detail != regle.details[0]"> + </span>{{detail?.ij?.prestation?.libelle}}</span>
        </div>
    </div>
    <div class="row">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.type'"></label>
        <div class="col-sm-6 pl-1">
            <span *ngFor="let detail of regle.details; let i = index;">
                <span *ngIf="i > 0">&nbsp;</span>
                <span *ngIf="detail.ij.prestation.type == 0" [translate]="'od.engagements.indemnites.degrevements.popupInfos.reel'"></span>
                <span *ngIf="detail.ij.prestation.type == 1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.plafonne'"></span>
                <span *ngIf="detail.ij.prestation.type == 2 && detail.ij.prestation.bareme" [translate]="'od.engagements.indemnites.degrevements.popupInfos.bareme'"></span>
                <span *ngIf="detail.ij.prestation.type == 2 && !detail.ij.prestation.bareme" [translate]="'od.engagements.indemnites.degrevements.popupInfos.forfait'"></span>
            </span>
        </div>
    </div>
    <div class="row">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.montant'"></label>
        <div class="col-sm-6 pl-1">
            <!--Si on n'est pas dans la liste déroulante de choix de frais, et qu'il s'agit d'un taux spécial-->
            <span *ngIf="!overFraisList && regle.selectedFrais?.libelleSpecial">{{ regle.montant * coefficientModificateurOd / 100 * regle.details[0]?.coursDeviseEntrep | montant:deviseEntreprise }}</span>
            <!--Si on n'est pas dans la liste déroulante de choix de frais, et qu'il ne s'agit pas d'un taux spécial-->
            <span *ngIf="!overFraisList && !regle.selectedFrais?.libelleSpecial">{{ regle.montant * regle.details[0]?.coursDeviseEntrep | montant:deviseEntreprise }}</span>
            <!--Si on est dans la liste déroulante de choix de frais-->
            <span *ngIf="overFraisList">{{ montantFraisSpecial * regle.details[0]?.coursDeviseEntrep | montant:deviseEntreprise }}</span>
        </div>
    </div>
    <div class="row" *ngIf="regle.details[0].coursDeviseLocale != null">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.montantLocal'"></label>
        <div class="col-sm-6 pl-1">
            <!--Si on n'est pas dans la liste déroulante de choix de frais, et qu'il s'agit d'un taux spécial-->
            <span *ngIf="!overFraisList && regle.selectedFrais?.libelleSpecial">
                {{ (regle.montant * coefficientModificateurOd * regle.details[0].coursDeviseLocale / 100) | montant:getDeviseLocale(regle) }}
            </span>
            <!--Si on n'est pas dans la liste déroulante de choix de frais, et qu'il ne s'agit pas d'un taux spécial-->
            <span *ngIf="!overFraisList && !regle.selectedFrais.libelleSpecial">
                {{ (regle.montant * regle.details[0].coursDeviseLocale) | montant:getDeviseLocale(regle) }}
            </span>
            <!--Si on est dans la liste déroulante de choix de frais-->
            <span *ngIf="overFraisList">
                {{ (montantFraisSpecial * regle.details[0].coursDeviseLocale) | montant:getDeviseLocale(regle) }}
            </span>
        </div>
    </div>
    <div class="row">
        <label class="col-sm-6 pr-1" [translate]="'od.engagements.indemnites.degrevements.popupInfos.quantite'"></label>
        <div class="col-sm-6 pl-1">{{ regle.quantite }}</div>
    </div>
</ng-template>
