import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {GeographieView} from '@domain/geographie/geographieView';
import {AutocompleteComponent} from '@share/component/autocomplete/autocomplete.component';
import {TypeCodeErreur} from '@domain/common/http/result';
import {Filter} from "@domain/common/list-view";

/**
 * Composant d'ajout d'un périmètre pour une zone
 */
@Component({
	host: {'data-test-id': 'zone-perimetre-add'},
	selector: 'zone-perimetre-add',
	templateUrl: './zone-perimetre-add.component.html',
	providers: [GeographieService]
})
export class ZonePerimetreAddComponent {

	/** Type de périmètre */
	listeType: String[] = new Array<String>('PAYS');

	/** Périmètre à ajouter */
	selectedPerimetre: number;

	/** Objet géographie sélectionné dans l'autocomplete */
	selectedGeographie: GeographieView;

	/** Autocomplete Géographie */
	@ViewChild("autocompleteGeographie")
	autocompleteGeographie: AutocompleteComponent;

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param geographieService	Service géographie
	 * @param toastrService		Service des toasts
	 * @param matDialogRef		Référence à la boîte de dialogue
	 * @param data				Données injectées dans la boîte de dialogue
	 */
	constructor(private translateService: TranslateService,
				private geographieService: GeographieService,
				private toastrService: ToastrService,
				private matDialogRef: MatDialogRef<ZonePerimetreAddComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { idZone: number }) {
	}

	/**
	 * Met à jour le périmètre à partir de l'objet géographie sélectionné
	 *
	 * @param geographie Objet géographie
	 */
	setGeographie(geographie: GeographieView): void {
		this.selectedPerimetre = geographie.id;
	}

	/**
	 * Enregistrement du périmètre
	 */
	savePerimetre(): void {
		this.isSaving = true;

		//Enregistrement du périmètre
		this.geographieService.savePerimetreForZone(this.selectedPerimetre, this.data.idZone).subscribe({
			next: data => {
				//Vérification de l'enregistrement
				if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de l'écran
					this.matDialogRef.close({success: true});
				} else {
					//Toast d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));

					//Fermeture de l'écran
					this.matDialogRef.close({success: false});
				}
			},
			complete: () => {
				this.isSaving = false;
			}
		});
	}

}