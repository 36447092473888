import {Component} from "@angular/core";
import {AutocompleteSearchItemComponent} from "../autocomplete-search-item.component";
import {TypeProfil} from "@domain/user/user";
import {Role} from "@domain/workflow/role";

/**
 * Ligne d'affichage dans l'autocomplete Rôle
 */
@Component({
	host: {'data-test-id': 'role-search-item'},
	templateUrl: './role-search-item.component.html'
})
export class RoleSearchItemComponent extends AutocompleteSearchItemComponent<Role> {
	//Import de l'énum pour le DOM
	readonly TypeProfil = TypeProfil;

	/**
	 * Récupération de l'icône de l'avatar
	 */
	getAvatarIcon(): string {
		switch (this.data.fonction) {
			case TypeProfil.COMPTABLE:
				return 'icon-comptable';
			case TypeProfil.RESPONSABLE:
				return 'icon-responsable';
			case TypeProfil.COLLABORATEUR:
				return 'icon-collaborateurs';
			case TypeProfil.ASSISTANT:
				return 'icon-assistant';
			case TypeProfil.SOUS_ADMINISTRATEUR:
				return 'icon-sous_administrateur';
			case TypeProfil.FOURNISSEUR:
				return 'icon-fournisseur';
		}
	}
}