<h1 mat-dialog-title>
    <span translate>global.actions.boiteLien</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <div *ngIf="data.listeBoiteALien.length > 0">
                <div class="listview-item" *ngFor="let lien of data.listeBoiteALien">
                    <div class="lvi-content">
                        <div class="avatar"></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a href="{{lien.url}}" target="_blank"> {{lien.libelle}} </a>
                            </div>
    <!--                        <ul class="lvi-attrs">-->
    <!--                            <li><strong translate>remarque</strong>{{lien.remarque}}</li>-->
    <!--                        </ul>-->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="data.listeBoiteALien.length === 0">
                <b translate>liens.aucunLien</b>
            </div>
        </div>
    </div>
</div>