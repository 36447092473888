import {ParamConnexion} from "./param-connexion";

/**
 * Représente une valeur disponible pour un paramètre de connexion parmi une liste de valeur
 *
 * @author Laurent Convert
 * @date 20/11/2023
 */
export class ParamConnexionValeur {
	idValeur: number;
	paramConnexion: ParamConnexion;
	valeur: string;
	actif: boolean;

	constructor(dto?: ParamConnexionValeur) {
		if (dto) {
			Object.assign(this,dto);

			if (dto.paramConnexion) {
				this.paramConnexion = new ParamConnexion(dto.paramConnexion);
			}
		}
	}
}