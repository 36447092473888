import {Component, Inject} from '@angular/core';
import {LieuDistance, TypeLieuDistance} from "@domain/geolocalisation/lieuDistance";
import {GeographieView} from "@domain/geographie/geographieView";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {TypePortee} from "@domain/workflow/workflow";
import {Adresse} from "@domain/profil/adresse";

/**
 * Composant de la popin de sélection d'un lieu pour une distance (départ ou arrivée)
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'distance-select-lieu'},
    templateUrl: './distance-select-lieu.component.html'
})
export class DistanceSelectLieuComponent {

    /* Accès à l'enum dans la vue */
    readonly TypePortee = TypePortee;
    readonly TypeLieuDistance = TypeLieuDistance;

    /** Liste des types de lieu possibles */
    readonly listeTypeLieu: OptionTypeLieuDistance[] = [{
        id: TypeLieuDistance.VILLE,
        libelle: this.translateService.instant('admin.bibliotheque.geographie.distance.lieu.ville')
    }, {
        id: TypeLieuDistance.LOCALISATION,
        libelle: this.translateService.instant('admin.bibliotheque.geographie.distance.lieu.adresse')
    }, {
        id: TypeLieuDistance.GARE,
        libelle: this.translateService.instant('admin.bibliotheque.geographie.distance.lieu.gare')
    }, {
        id: TypeLieuDistance.AEROPORT,
        libelle: this.translateService.instant('admin.bibliotheque.geographie.distance.lieu.aeroport')
    }];

    /** Type du lieu à sélectionner */
    typeLieuSelected: TypeLieuDistance = null;

    /** Lieu (géographie ou adresse) à sélectionner */
    selectedLieu: LieuDistance = {
        idLieu: null,
        type: null,
        libelle: ""
    };

    /** Adresse en cours de saisie */
    selectedAdresse: Adresse = null;

    /** Objet géographie sélectionné dans l'autocomplete géographie */
    selectedGeographie: GeographieView;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param matDialogRef Référence à la popin
     * @param data Données passées en entrée
     */
    constructor(private translateService: TranslateService,
                private matDialogRef: MatDialogRef<DistanceSelectLieuComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { isDepart: boolean }) {
    }

    /**
     * Enregistre le type de lieu sélectionné dans la popin
     *
     * @param id identifiant du type de lieu sélectionné
     */
    selectTypeLieu(id: TypeLieuDistance) {
        this.typeLieuSelected = id;
    }

    /**
     * Pour retourner au choix du type de lieu
     */
    goBack() {
        //Retour à l'écran de choix de type de lieu
        this.typeLieuSelected = null;

        //Désélectionne la géographie de l'autocomplete
        this.selectedGeographie = null;

        //Met à null le lieu sélectionné
        this.selectedLieu = {
            idLieu: null,
            type: null,
            libelle: "",
            adresse: null
        };

        //Met à null l'adresse sélectionnée
        this.selectedAdresse = null;
    }

    /**
     * Retourne le type de filtre utilisé par l'autocomplete géographie en fonction du type de lieu sélectionné
     *
     * @param typeLieu Type de lieu
     * @return liste des filtres de l'autocomplete
     */
    getAutoCompleteFilter(typeLieu: TypeLieuDistance): Array<String> {
        let filter: String[] = Array<String>();

        switch (typeLieu) {
            case TypeLieuDistance.VILLE:
                filter.push('VILLE');
                break;
            case TypeLieuDistance.GARE:
                filter.push('GARE');
                break;
            case TypeLieuDistance.AEROPORT:
                filter.push('AEROPORT');
                break;
            default:
                filter.push('VILLE', 'GARE', 'AEROPORT');
        }

        return filter;
    }

    /**
     * Met à jour le lieu à partir de l'objet géographie sélectionné (autocomplete géographie)
     *
     * @param geographie Objet géographie sélectionné
     */
    setGeographie(geographie: GeographieView): void {
        //Identifiant
        this.selectedLieu.idLieu = geographie.id;

        //Libellé
        this.selectedLieu.libelle = geographie.libelle
            + (geographie.departementAdmin?.libelle ? ', ' + geographie.departementAdmin.libelle : '')
            + (geographie.pays?.libelle ? ', ' + geographie.pays.libelle : '');

        //Type et identifiants de la ville et de la ville iata
        switch (geographie.type) {
            case TypeGeographie.VILLE:
                this.selectedLieu.type = TypeLieuDistance.VILLE;
                break;
            case TypeGeographie.GARE:
                this.selectedLieu.type = TypeLieuDistance.GARE;
                break;
            case TypeGeographie.AEROPORT:
                this.selectedLieu.type = TypeLieuDistance.AEROPORT;
                break;
        }
    }

    /**
     * Met à jour l'adresse et le lieu à partir de l'adresse sélectionnée (autocomplete adresse)
     *
     * @param adresse Adresse sélectionnée
     */
    setAdresse(adresse: Adresse) {
        //Définition de l'adresse pour l'autocomplete
        this.selectedAdresse = adresse;

        //Définition du lieu pour validation
        this.selectedLieu.idLieu = adresse.idAdresse;
        this.selectedLieu.libelle = Adresse.libelleForDisplay(adresse);
        this.selectedLieu.type = TypeLieuDistance.LOCALISATION;
        this.selectedLieu.adresse = adresse;
    }

    /**
     * Validation de la sélection du lieu
     */
    validateLieu(): void {
        //Fermeture de la popin avec envoi du lieu sélectionné
        this.matDialogRef.close({lieu: this.selectedLieu});
    }
}

/**
 * Modèle des options de type de lieu
 */
export interface OptionTypeLieuDistance {
    id: TypeLieuDistance,
    libelle: string
}
