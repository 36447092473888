import {NgModule} from '@angular/core';
import {Routes} from "@angular/router";
import {ComptesBancairesGeneralitesComponent} from './generalites/comptes-bancaires-generalites.component';
import {AdminGuardProvider} from '@core/security/admin-guard.provider';
import {ShareModule} from '@share/share.module';
import {ComptabiliteComptesBancairesComponent} from "@components/admin/comptabilite/comptes-bancaires/comptabilite-comptes-bancaires.component";
import {ComptabiliteComptesBancairesService} from "@components/admin/comptabilite/comptes-bancaires/comptabilite-comptes-bancaires.service";

/**
 * Routes du menu admin Comptabilité / Comptes bancaires
 */
export const comptabiliteComptesBancairesRoutes: Routes = [
	{
		path: 'Generalites',
		component: ComptesBancairesGeneralitesComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: '',
		redirectTo: 'Generalites',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Generalites'
	}
]

/**
 * Module Comptabilité / Comptes bancaires
 */
@NgModule({
	imports: [
		ShareModule
	],
	declarations: [
		ComptabiliteComptesBancairesComponent,
		ComptesBancairesGeneralitesComponent
	],
	providers: [
		ComptabiliteComptesBancairesService
	]
})
export class ComptabiliteComptesBancairesModule {
}
