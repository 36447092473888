import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Compteur} from "@domain/ik/compteur";
import * as moment from "moment";
import {MatDialog} from "@angular/material/dialog";
import {CompteurDetailPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-detail-popin/compteur-detail-popin.component";
import {DatePipe,TitleCasePipe} from "@angular/common";

/**
 * Item de liste d'un compteur
 */
@Component({
	host: {'data-test-id': 'compteur-list-item'},
	templateUrl: './compteur-list-item.component.html'
})
export class CompteurListItemComponent extends ListViewItem<Compteur> {
	/** Options supplémentaires */
	@Input() extraOptions: {
		isBaremeMensuel: boolean,
		modeCalculIK: number
	}

	/**
	 * Constructeur
	 *
	 * @param matDialog le service de gestion des popins
	 * @param datePipe le pipe date d'Angular
	 * @param titleCasePipe le pipe titleCase d'Angular
	 */
	constructor(
		private matDialog: MatDialog,
		private datePipe: DatePipe,
		private titleCasePipe: TitleCasePipe
	) {
		super();
	}

	/**
	 * Construction du libellé
	 *
	 * @param noTrim flag pour forcer le libellé de la période
	 */
	getLibelle(noTrim?: boolean): string {
		let index: number;
		let dateDebut: Date;
		let dateFin: Date;

		//Récupération de l'index
		index = this.liste.data.listeResultats.indexOf(this.data);

		if (noTrim || index === 0 || this.liste.data.listeResultats[index].date !== this.liste.data.listeResultats[index - 1].date) {
			//Date de début
			dateDebut = moment(this.data.date).startOf('day').toDate();

			//Vérification du barème mensuel
			if (this.extraOptions.isBaremeMensuel) {
				//Calcul de la date de fin
				dateFin = moment(this.data.date).startOf('day').add(1,'M').toDate();
			} else {
				//Calcul de la date de fin
				dateFin = moment(this.data.date).startOf('day').add(11,'M').toDate();
			}

			//Construction de la période
			return this.titleCasePipe.transform(this.datePipe.transform(dateDebut,'MMMM YYYY')) + " - "
				+ this.titleCasePipe.transform(this.datePipe.transform(dateFin,'MMMM YYYY'));
		} else {
			return "-";
		}
	}

	/**
	 * Voir le détail de ce compteur
	 */
	viewDetail(): void {
		this.matDialog.open(CompteurDetailPopinComponent,{
			data: {
				libellePeriode: this.getLibelle(true),
				compteur: this.data
			},
			panelClass: 'mat-dialog-without-margin',
			width: '80%'
		})
	}
}
