<div class="lvi-content">
    <div class="avatar">
        <mat-icon class="material-icons-outlined">settings_applications</mat-icon>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="open()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeZuRef.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeZuRef.defaut'"></strong>
                <span [translate]="'global.' + (data.defaut ? 'oui' : 'non')"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="delete()"
                [matTooltip]="(data.used ? 'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeZuRef.usedTooltip' : 'global.actions.supprimer') | translate"
                [disabled]="data.used">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>