import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {UserContrat} from "@domain/user/user-contrat";
import {UserContratListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/franchise-popin/user-contrat-list-item/user-contrat-list-item.component";
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Result} from "@domain/common/http/result";
import {UserContratDetailPopinComponent} from './user-contrat-detail-popin/user-contrat-detail-popin.component';

/**
 * Popin des franchises
 */
@Component({
	host: {'data-test-id': 'franchise-popin'},
	templateUrl: './franchise-popin.component.html',
	styles: [
		'::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
		'::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
	]
})
export class FranchisePopinComponent implements OnInit {
	/** Liste des UserContrat */
	listeUserContrat: ListView<UserContrat,UserContratListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la ref de cette popin
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idUser: number },
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<FranchisePopinComponent>,
		private matDialog: MatDialog
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listeUserContrat = new ListView<UserContrat,UserContratListItemComponent>({
			uri: `/controller/User/listeContrat/${this.data.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.franchisePopin.title'),
			component: UserContratListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeContrat?.data,
			listeActions: [
				{
					icon: 'add',
					onPress: () => this.createContrat()
				},{
					icon: 'close',
					onPress: () => this.matDialogRef.close(this.listeUserContrat.data.nbObjetsTotal)
				}
			]
		});
	}

	/**
	 * Création d'un contrat
	 */
	createContrat(): void {
		let contrat: UserContrat;

		//Initialisation du contrat
		contrat = new UserContrat();

		//Valeurs par défaut
		contrat.idUser = this.data.idUser;
		contrat.franchiseAller = 0;
		contrat.franchiseAllerRetour = 0;

		//Ouverture de la popin de consultation/modification
		this.matDialog.open(UserContratDetailPopinComponent,{
			data: {userContrat: contrat},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			if (refresh) {
				//Rafraîchissement de la liste
				this.listeUserContrat.refresh();
			}
		})
	}
}