import { NgModule } from '@angular/core';

import { CoreComponentsModule } from '../core-components.module';
import { AdresseComponent } from './adresse.component';
import { AdresseService } from './adresse.service';

@NgModule({
	imports: [CoreComponentsModule],
	declarations: [AdresseComponent],
	providers: [AdresseService],
	exports: [AdresseComponent]
})
export class AdresseModule { }