import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ListView, TypeComparaison} from "@domain/common/list-view";
import {ClassesDto} from "@domain/admin/voyages/referentiels/classesDto";
import {VoyagesReferentielsClassesItemComponent} from "@components/admin/voyages/referentiels/classes/voyages-referentiels-classes-item/voyages-referentiels-classes-item.component";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Composant d'affichage de la liste des classes
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-classes'},
    selector: 'voyages-referentiels-classes',
    templateUrl: './voyages-referentiels-classes.component.html'
})
export class VoyagesReferentielsClassesComponent implements OnInit{
    /** Liste des classes, pour utiliser le composant ListViewComponent */
    listeClasses: ListView<ClassesDto, VoyagesReferentielsClassesItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     */
    constructor(protected translateService: TranslateService) {
    }

    ngOnInit() {
        this.listeClasses = new ListView<ClassesDto, VoyagesReferentielsClassesItemComponent>({
            uri: `/controller/TravelHubReferentiel/listeAllClasse`,
            title: this.translateService.instant('admin.voyages.referentiels.onglets.classes'),
            component: VoyagesReferentielsClassesItemComponent,
            isFilter: true,
            defaultOrder: 'libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.voyages.referentiels.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'typePrestation',
                    title: this.translateService.instant('admin.voyages.referentiels.prestation'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: "0",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
                    },{
                        code: "1",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
                    },{
                        code: "2",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
                    },{
                        code: "3",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
                    },{
                        code: "4",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
                    },{
                        code: "5",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
                    },{
                        code: "7",
                        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
                    }]
                },{
                    clef: 'code',
                    title: this.translateService.instant('admin.voyages.referentiels.code'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'isCustom',
                    title: this.translateService.instant('admin.voyages.referentiels.personnalise'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                    listeValues: [{
                        code: "0",
                        libelle: this.translateService.instant('confirmation.non')
                    },{
                        code: "1",
                        libelle: this.translateService.instant('confirmation.oui')
                    }]
                }
            ]
        });

        //Définition des colonnes de tri
        this.listeClasses.columns = [
            { key: 'libelle', title: 'admin.voyages.referentiels.libelle' },
            { key: 'typePrestation', title: 'admin.voyages.referentiels.prestation' },
            { key: 'code', title: 'admin.voyages.referentiels.code' },
            { key: 'isCustom', title: 'admin.voyages.referentiels.personnalise' }
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeClasses.sorting = new Sorting(this.listeClasses.columns, "libelle");
    }
}
