import {AfterViewInit,ChangeDetectorRef,Component,ElementRef,HostListener,Inject,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ProfilService} from "@components/profil/profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Telephone} from "@domain/profil/telephone";
import {filter,first} from "rxjs/operators";
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import {TypeContact} from '@domain/profil/typeContact';
import {NgForm} from "@angular/forms";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant d'ajout/modification de contact d'urgence pour le profil utilisateur
 */
@Component({
    selector: 'profil-donnees-add-contact-urgence',
    templateUrl: './profil-donnees-add-contact-urgence.component.html'
})
export class ProfilDonneesAddContactUrgenceComponent implements OnInit, OnDestroy, AfterViewInit {

	/* Enum pour utilisation dans le template */
	public typeContactEnum: typeof TypeContact = TypeContact;

	/** Indicateur de sauvegarde */
	isSaving: boolean = false;

	/** Indicateur de suppression */
	isDeleting: boolean = false;

	/** Formulaire des généralités */
	@ViewChild("addContactUrgence") form: NgForm;

	/** Indique si le numéro de téléphone est en mode édition */
	isNumeroEditable: boolean = false;

	/** Indique si le numéro de téléphone a été modifié */
	isNumeroChanged: boolean = false;

	/** Composant de saisie du numéro de téléphone */
	@ViewChild('telephone', {static: false})
	private telephoneComponent: NgxMatIntlTelInputComponent;

	/** Elément DOM du composant de saisie du numéro de téléphone */
	private telephoneElement: HTMLInputElement;

	/** Elément DOM de l'input numérique de saisie du numéro de téléphone */
	private telephoneInputElement: HTMLInputElement;

	/** Elément DOM d'affichage du numéro obfusqué */
	private telephoneObfusqueElement: HTMLInputElement;

	/** Listener onBlur sur le composant de saisie du numéro de téléphone */
	private telephoneBlurListener: any;

	/** Listener onBlur sur l'input numérique de la saisie du téléphone */
	private telephoneInputBlurListener: any;

	/** Listener onFocus sur l'affichage du numéro obfusqué */
	private telephoneObfusqueFocusListener: any;

	/** Liste des types de téléphone pour le select */
	listeTypeTelephone: Array<any> = [
		{type: "CONTACT_URGENCE", libelle: this.translateService.instant("profilUser.infoContact.CONTACT_URGENCE")},
		{type: "EMAIL_ASSISTANT", libelle: this.translateService.instant("profilUser.infoContact.EMAIL_ASSISTANT")},
	];

	/**
	 * Constructeur
	 *
	 * @param translateService service de traduction
	 * @param confirmService service de confirmation
	 * @param profilService service pour la gestion du profil
	 * @param toastrService service de toast d'alerte
	 * @param elementRef service d'accès aux éléments DOM
	 * @param cdRef service de détection des changements
	 * @param matDialogRef service de référence de la popup
	 * @param data données transmises à la popup
	 */
	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private elementRef:ElementRef,
		private cdRef: ChangeDetectorRef,
		private matDialogRef: MatDialogRef<ProfilDonneesAddContactUrgenceComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { telephone: Telephone,idContactActif: number,idEmailActif: number,idUser?: number }
	) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		this.data.telephone = Object.assign(new Telephone(),this.data.telephone);
		
		if (!this.data.telephone.type) {
			this.data.telephone.type = this.listeTypeTelephone[0].type;
		}

		//Le composant a besoin du + pour afficher le numéro, retrocomp v9
		if(this.data.telephone.numeroBrut != null && !this.data.telephone.numeroBrut.startsWith("+")) {
			this.data.telephone.numeroBrut = '+' + this.data.telephone.numeroBrut;
		}

		//Backup des valeurs obfusquées
		this.data.telephone.numeroBrutObfusque = this.data.telephone.numeroBrut;
	}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		if (this.data.telephone.type == TypeContact.CONTACT_URGENCE) {
			//Installation des listeners pour gérer les 2 inputs du numéro de téléphone
			this.installListeners();

			//Désactivation du composant de saisie du numéro, pour qu'il n'empêche pas la validation du formulaire
			setTimeout(() => {
				if (this.form.controls.phone) {
					this.form.controls["phone"].disable({emitEvent: true});
					this.cdRef.detectChanges();
				}
			});
		}
	}

	/**
	 * Installation des listeners pour gérer les 2 inputs du numéro de téléphone.
	 */
	installListeners() {
		setTimeout(() => {
			//Listener sur le composant de saisie du numéro de téléphone (englobant sélecteur de pays et input numérique)
			this.telephoneElement = this.elementRef.nativeElement.querySelector('ngx-mat-intl-tel-input');
			this.telephoneBlurListener = this.onTelephoneBlur.bind(this);
			this.telephoneElement.addEventListener('blur', this.telephoneBlurListener);

			//Listener sur l'input numérique du composant de saisie du numéro de téléphone
			this.telephoneInputElement = this.elementRef.nativeElement.querySelector('ngx-mat-intl-tel-input input');
			this.telephoneInputBlurListener = this.onTelephoneInputBlur.bind(this);
			this.telephoneInputElement.addEventListener('blur', this.telephoneInputBlurListener);

			//Listener sur le composant d'affichage du numéro de téléphone obfusqué
			this.telephoneObfusqueElement = this.elementRef.nativeElement.querySelector('#telephoneObfusque input');
			this.telephoneObfusqueFocusListener = this.showSaisieTelephone.bind(this)
			this.telephoneObfusqueElement.addEventListener('focus', this.telephoneObfusqueFocusListener);
		});
	}

	/**
	 * Destruction de la popup.
	 */
	removeListeners() {
		//Suppression des listeners
		if (this.telephoneBlurListener) {
			this.telephoneElement.removeEventListener('blur', this.telephoneBlurListener);
		}
		if (this.telephoneInputBlurListener) {
			this.telephoneInputElement.removeEventListener('blur', this.telephoneInputBlurListener);
		}
		if (this.telephoneObfusqueFocusListener) {
			this.telephoneObfusqueElement.removeEventListener('focus', this.telephoneObfusqueFocusListener);
		}
	}

	/**
	 * Destruction de la popup.
	 */
	ngOnDestroy() {
		//Suppression des listeners
		this.removeListeners();
	}

	/**
	 * Affiche le composant de saisie du numéro de téléphone.
	 */
	showSaisieTelephone() {
		//switch le flag d'édition, ce qui a pour effet de masquer l'input d'affichage du numéro et d'afficher le composant d'édition
		this.isNumeroEditable = true;

		if (this.telephoneComponent) {
			//Permet d'afficher directement l'indicatif du champ téléphone
			this.telephoneComponent.focused = true;

			//Suppression du numéro
			this.telephoneComponent.reset();

			//Activation du composant de saisie du numéro, pour qu'il n'empêche pas la validation du formulaire
			this.form.controls["phone"].enable({emitEvent: true});

			//Donne le focus au composant de saisie du numéro
			setTimeout(() => {
				this.telephoneInputElement.focus();
			});
		}
	}

	/**
	 * Lorsque le composant d'édition du numéro perd le focus, on réaffiche le numéro obfusqué si le numéro n'a pas été modifié.
	 */
	onTelephoneBlur() {
		//Si le numéro de téléphone n'est pas pré-existant pas ou s'il existe mais n'a pas changé suite à l'édition
		if (!this.data.telephone.idTelephone || !this.isNumeroChanged) {
			//On attend à peine pour évaluer si le composant d'édition du téléphone (englobant sélecteur de pays et input numérique)
			//Car quand on sélectionne un pays, on perd le focus un instant puis on le récupère à nouveau
			setTimeout(() => {
				this.disableTelephoneEditionSiNonFocus();
			}, 200);
		}
	}

	/**
	 * Désactivation de l'édition du numéro de téléphone
	 */
	disableTelephoneEditionSiNonFocus(): void {
		//On récupère l'élément DOM du composant d'édition du téléphone (englobant sélecteur de pays et input numérique)
		//On vérifie s'il a le focus ou non
		const parent = this.elementRef.nativeElement.querySelector('ngx-mat-intl-tel-input.cdk-focused');
		//S'il n'a pas le focus
		if (!parent) {
			//On est bien sorti de l'ensemble du composant d'édition du téléphone, on peut désactiver l'édition
			//On switch le flag d'édition, ce qui a pour effet de masquer le composant d'édition du numéro et d'afficher l'input d'affichage
			this.isNumeroEditable = false;

			//Désactivation du composant de saisie du numéro, pour qu'il n'empêche pas la validation du formulaire
			this.form.controls["phone"]?.disable({emitEvent:true});
		} else {
			//Si le composant parent a encore le focus : on vient de sélectionner un pays
			//On peut donc remettre le focus sur l'input numérique
			this.telephoneInputElement.focus();
		}
	}

	/**
	 * Si l'utilisateur a modifié le numéro, on le flag comme étant modifié.
	 */
	onTelephoneChanged() {
		//Flag de numéro modifié côté front
		this.isNumeroChanged = true;

		//Flag de numéro modifié côté back
		this.data.telephone.numeroChanged = true;

		//On valorise pour que le formulaire soit valide
		this.data.telephone.numeroBrutObfusque = this.data.telephone.numeroBrut;
	}

	/**
	 * Lorsque l'input de saisie perd le focus, on déclenche la perte de focus sur le parent (composant material) 
	 * pour gérer l'affichage des inputs obfusqués ou non
	 */
	onTelephoneInputBlur(): void {
		this.onTelephoneBlur();
	}

	/**
	 * Résout le problème connu du composant material pour des versions "élevées" d'angular :
	 * quand on écrit plusieurs lettres pour rechercher un pays, seulement la première est prise en compte avant la perte de focus
	 */
	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent() {
		const countrySearch = document.querySelector('.country-search');
		if (countrySearch) {
			const countrySearchElement = countrySearch as HTMLElement;
			setTimeout(() => countrySearchElement.focus(), 200);
		}
	}

	/**
	 * Sauvegarde du contact urgence
	 */
	save() {
		//Vérification de la présence de numéro du même type actif
		if (this.data.telephone.actif && (
			//Si on a un objet identique qui est déjà actif et qui n'est pas celui modifié
			(this.data.telephone.type == TypeContact.CONTACT_URGENCE && this.data.idContactActif != 0 && this.data.idContactActif != this.data.telephone.id) ||
			(this.data.telephone.type == TypeContact.EMAIL_ASSISTANT && this.data.idEmailActif != 0 && this.data.idEmailActif != this.data.telephone.id)
		)) {
			//Confirmation auprès de l'utilisateur sur la création d'un numéro inactif
			this.confirmService.showConfirm(this.translateService.instant('profilUser.infoContact.add.dejaActif'))
				.pipe(filter(isConfirmed => isConfirmed))
				.subscribe({
					next: () => {
						this.executeSave();
					}
				});
		} else {
			this.executeSave();
		}
	}

	/**
	 * Execution de la sauvegarde du contact urgence via le service
	 */
	private executeSave() {
		let telephoneToSave: Telephone;
		
		//Indicateur de sauvegarde
		this.isSaving = true;

		//Si le numéro n'a pas changé, on restaure le numéro brut obfusqué (il a été ràz par le composant telInput car un numéro obfusqué n'est pas un numéro valide...)
		if (!this.isNumeroChanged) {
			this.data.telephone.numeroBrut = this.data.telephone.numeroBrutObfusque;
		}
		
		//Création de l'objet à enregistrer à partir de l'objet en cours d'édition afin de ne garder que les propriétés cohérentes pour le type
		telephoneToSave = new Telephone(this.data.telephone);
		//Sauvegarde du téléphone
		this.profilService.saveTelephone(telephoneToSave,this.data.idUser).pipe(first()).subscribe({
			next: (result: Result) => {
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de l'écran
					this.matDialogRef.close(true);
				}
			}
		});
	}

	/**
	 * Suppression du contact urgence
	 */
	deleteTelephone() {
		//Confirmation auprès de l'utilisateur sur la suppression d'un numéro
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					this.isDeleting = true;
					this.profilService.deleteTelephone(this.data.telephone).pipe(first()).subscribe({
						next: () => {
							//Message d'information
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Fermeture de l'écran
							this.matDialogRef.close(true);
						}
					});
				}
			});
	}

	/** Vide entièrement le numéro de téléphone et sa version obfusquée */
	razTelephone() {
		this.data.telephone.numero = null;
		this.data.telephone.numeroBrut = null;
		this.data.telephone.numeroBrutObfusque = null;
		this.onTelephoneChanged();
	}
}
