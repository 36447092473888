import {ListItem} from "@domain/common/list-view/list-item";
import {MaintenanceTask} from "@domain/admin/maintenance/maintenance-task";

/**
 * Classe représentant une migration
 */
export class Migration implements ListItem {
    idUpgrade: number;
    origine: string;
    origineBuild: string;
    cible: string;
    cibleBuild: string;
    date: Date;
    preMigration: boolean;
    bddUpgrade: boolean;
    postMigration: boolean;
    preMigrationSuccess: boolean;
    bddUpgradeSuccess: boolean;
    postMigrationSuccess: boolean;
    listePreTask: MaintenanceTask[];
    listeBddUpgradeDetail: MigrationDetail[];
    listePostTask: MaintenanceTask[];

    constructor(dto?: any) {
        if (dto) {
            this.idUpgrade = dto.idUpgrade;
            this.origine = dto.origine;
            this.origineBuild = dto.origineBuild;
            this.cible = dto.cible;
            this.cibleBuild = dto.cibleBuild;
            if (dto.date) { this.date = new Date(dto.date); }
            this.preMigration = dto.preMigration;
            this.bddUpgrade = dto.bddUpgrade;
            this.postMigration = dto.postMigration;
            this.preMigrationSuccess = dto.preMigrationSuccess;
            this.bddUpgradeSuccess = dto.bddUpgradeSuccess;
            this.postMigrationSuccess = dto.postMigrationSuccess;
            if (dto.listePreTask) { this.listePreTask = dto.listePreTask.map(task => new MaintenanceTask(task)); }
            if (dto.listeBddUpgradeDetail) { this.listeBddUpgradeDetail = dto.listeBddUpgradeDetail.map(detail => new MigrationDetail(detail)); }
            if (dto.listePostTask) { this.listePostTask = dto.listePostTask.map(task => new MaintenanceTask(task)); }
        }
    }

    getKey(): number {
        return this.idUpgrade;
    }
}

/**
 * Classe représentant une migration
 */
export class MigrationDetail implements ListItem {
    idUpgrade: number;
    num: number;
    sql: string;
    result: UpgradeBddResult;
    log: string;

    constructor(dto?: any) {
        if (dto) {
            this.idUpgrade = dto.idUpgrade;
            this.num = dto.num;
            this.sql = dto.sql;
            this.result = dto.result;
            this.log = dto.log;
        }
    }

    getKey(): number {
        return this.idUpgrade;
    }
}

/**
 * Enum représentant le résultat d'une mise à jour de BDD
 */
export enum UpgradeBddResult {
    SUCCESS = 0,
    FAIL = 1
}
