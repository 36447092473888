import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Classe regroupant les informations de compteur d'une dépense d'IK
 */
export class CompteurDepense {
    /** Quantité */
    quantite: number;
    /** Unité */
    unite: string;
    /** Compteur initial théorique */
    compteurInitialTheorique: number;
    /** Compteur final théorique */
    compteurFinalTheorique: number;
    /** Compteur en cours théorique */
    compteurEnCoursTheorique: number;
    /** Date d'exécution théorique */
    dateExecutionTheorique: Date;
    /** Compteur initial réel */
    compteurInitialReel: number;
    /** Compteur final réel */
    compteurFinalReel: number;
    /** Compteur en cours réel */
    compteurEnCoursReel: number;
    /** Date exécution réel */
    dateExecutionReel: Date;

    /**
     * Constructeur
     */
    constructor(objet: any) {
        if (objet) {
            this.quantite = objet.quantite;
            this.unite = objet.unite;
            this.compteurInitialTheorique = objet.compteurInitialTheorique;
            this.compteurFinalTheorique = objet.compteurFinalTheorique;
            this.compteurEnCoursTheorique = objet.compteurEnCoursTheorique;
            if (objet.dateExecutionTheorique) { this.dateExecutionTheorique = new Date(objet.dateExecutionTheorique); }
            this.compteurInitialReel = objet.compteurInitialReel;
            this.compteurFinalReel = objet.compteurFinalReel;
            this.compteurEnCoursReel = objet.compteurEnCoursReel;
            if (objet.dateExecutionReel) { this.dateExecutionReel = new Date(objet.dateExecutionReel); }
        }
    }

    /**
     * Méthode de conversion de l'objet courant en Array de CompteurDepenseListItem
     */
    toCompteurDepenseList(): CompteurDepenseListItem[] {
        return [
            new CompteurDepenseListItem(
                "ndf.frais.popupDetailIk.compteurDepense.compteurInitial",
                this.compteurFinalTheorique - this.quantite - this.compteurEnCoursTheorique ?? 0,
                this.compteurFinalReel != this.compteurInitialReel ? this.compteurInitialReel : 0
            ),
            new CompteurDepenseListItem(
                "ndf.frais.popupDetailIk.compteurDepense.distanceEnCours",
                this.compteurEnCoursTheorique ?? 0,
                this.compteurFinalReel != this.compteurInitialReel ? this.compteurFinalReel - this.quantite - this.compteurInitialReel : 0
            ),
            new CompteurDepenseListItem(
                "ndf.frais.popupDetailIk.compteurDepense.distanceDuFrais",
                this.quantite ?? 0,
                this.compteurFinalReel != this.compteurInitialReel ? this.quantite : 0
            ),
            new CompteurDepenseListItem(
                "ndf.frais.popupDetailIk.compteurDepense.compteurFinal",
                this.compteurFinalTheorique ?? 0,
                this.compteurFinalReel != this.compteurInitialReel ? this.compteurFinalReel : 0
            )
        ];
    };
}

/**
 * Modèle représentant une ligne de la liste du compteur de la dépense
 */
export class CompteurDepenseListItem implements ListItem {
    /** Titre de la ligne */
    title: string;
    /** Compteur théorique */
    theorique: number;
    /** Compteur réel */
    reel: number;

    /**
     * Constructeur
     */
    constructor(title: string, theorique: number, reel: number) {
        this.title = title;
        this.theorique = theorique;
        this.reel = reel;
    }

    getKey(): number {
        return 0;
    }
}