<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.libelle | slice: 0:3 }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToDetailCompte()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li [ngSwitch]="data.type">
                <strong [translate]="'admin.comptabilite.planComptable.compteComptable.type'"></strong>
                <span *ngSwitchCase="TypeCompte[TypeCompte.NON_DEFINI]" [translate]="'admin.comptabilite.planComptable.compteComptable.nonDef'"></span>
                <span *ngSwitchCase="TypeCompte[TypeCompte.COMPTE_TIERS]" [translate]="'admin.comptabilite.planComptable.compteComptable.tiers'"></span>
                <span *ngSwitchCase="TypeCompte[TypeCompte.COMPTE_TAXE]" [translate]="'admin.comptabilite.planComptable.compteComptable.taxe'"></span>
                <span *ngSwitchCase="TypeCompte[TypeCompte.COMPTE_BANQUE]" [translate]="'admin.comptabilite.planComptable.compteComptable.banque'"></span>
                <span *ngSwitchCase="TypeCompte[TypeCompte.COMPTE_CHARGE]" [translate]="'admin.comptabilite.planComptable.compteComptable.charge'"></span>
                <span *ngSwitchCase="TypeCompte[TypeCompte.PERTE_GAIN_CHANGE]" [translate]="'admin.comptabilite.planComptable.compteComptable.change'"></span>
            </li>
            <li *ngIf="data.numero">
                <strong [translate]="'admin.comptabilite.planComptable.compteComptable.numero'"></strong>
                <span>{{ data.numero }}</span>
            </li>
            <li>
                <strong [translate]="'admin.comptabilite.planComptable.compteComptable.actif'"></strong>
                <span *ngIf="data.actif" [translate]="'global.oui'"></span>
                <span *ngIf="!data.actif" [translate]="'global.non'"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteCompte()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>
