import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";

@Component({
	templateUrl: './delegations-list-item.component.html'
})
/**
 * Composant d'un item de liste des délégations
 */
export class DelegationsListItemComponent extends ListViewItem<LienRoleUser> {

	/** Délégation courante */
	@Input() data: LienRoleUser;

	/** Options supplémentaires */
	@Input() extraOptions: any;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

}
