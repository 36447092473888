import {PopulationLogChange} from "./Population-log-change";
import {User} from "../../../user/user";
import {ListItem} from "@domain/common/list-view/list-item";
import {TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";

/**
 * Modèle pour l'historique de la population
 */
export class HistoriquePopulation implements ListItem {
    id: number;
    nature: TypeNature;
    idPopulation: number;
    idLog: number;
    dateLog: Date;
    typeLog: TypeLog;
    sourceLog: number;
    reference: string;
    typeLien: TypeLien | null;
    dependance: boolean | null;

    acteur: User;
    listePopulationLogChange: PopulationLogChange[];

    getKey(): number {
        return this.id;
    }
}

/** Enumération des natures des historiques de population */
export enum TypeNature {
    POPULATION = 1,
    SERVICE = 2,
    COLLABORATEUR = 3
}


/** Enumération des types de modification */
export enum TypeLog {
    AJOUT = 1,
    MODIFICATION = 2,
    SUPPRESSION = 3
}

/** Enumération des types de source */
export enum TypeSource {
    ADMINISTRATEUR = 1,
    IMPORT = 2,
    UTILISATEUR = 3
}