import {CurrencyPipe} from "@angular/common";
import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {AdminComponent} from "@components/admin/admin.component";
import {AdminBibliothequeComponent} from "@components/admin/bibliotheque/admin-bibliotheque.component";
import {AdminBibliothequeModule,adminBibliothequeRoutes} from '@components/admin/bibliotheque/admin-bibliotheque.module';
import {AdminEntrepriseModule,adminEntrepriseRoutes} from '@components/admin/entreprise/admin-entreprise.module';
import {DescriptionModule} from "@components/admin/entreprise/description/description.module";
import {XmlUploaderModule} from "@components/admin/entreprise/licence/xml-uploader.module";
import {AdminFraisComponent} from "@components/admin/frais/admin-frais.component";
import {AdminFraisModule,adminFraisRoutes} from '@components/admin/frais/admin-frais.module';
import {AdminVoyagesComponent} from "@components/admin/voyages/admin-voyages.component";
import {AdminVoyagesModule,adminVoyagesRoutes} from '@components/admin/voyages/admin-voyages.module';
import {DevisesService} from "@services/admin/devises/devises.service";
import {EntrepriseService} from "@services/admin/entreprise/entreprise.service";
import {LicenceService} from "@services/admin/entreprise/licence.service";
import {ReferentielsService} from "@services/admin/referentiels/referentiels.service";
import {RgpdService} from "@services/admin/entreprise/rgpd.service";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ShareModule} from "@share/share.module";
import {AdminEntrepriseComponent} from "./entreprise/admin-entreprise.component";
import {AdminWorkflowModule} from "@components/admin/workflow/admin-workflow.module";
import {LangueService} from "@services/admin/langue/langue.service";
import {AdminComptabiliteModule,adminComptabiliteRoutes} from "@components/admin/comptabilite/admin-comptabilite.module";
import {AdminComptabiliteComponent} from './comptabilite/admin-comptabilite.component';
import {AdminParametreModule,adminParametreRoutes} from "@components/admin/parametre/admin-parametre.module";
import {AdminParametreComponent} from "@components/admin/parametre/admin-parametre.component";
import {ReportingService} from "@services/admin/reporting/reporting.service";
import {adminWorkflowRoutes} from "@components/admin/workflow/admin-workflow.module";
import {AdminWorkflowComponent} from "@components/admin/workflow/admin-workflow.component";
import {AdminMaintenanceModule,adminMaintenanceRoutes} from "@components/admin/maintenance/admin-maintenance.module";
import {AdminTravelhubModule} from "@components/admin/voyages/travelhub/admin-travelhub.module";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";

export const adminRoutes: Routes = [
	{
		path: 'Entreprise',
		component: AdminEntrepriseComponent,
		canActivate: [AdminGuardProvider],
		children: adminEntrepriseRoutes
	},{
		path: 'Comptabilite',
		component: AdminComptabiliteComponent,
		canActivate: [AdminGuardProvider],
		children: adminComptabiliteRoutes
	},{
		path: 'Bibliotheque',
		component: AdminBibliothequeComponent,
		canActivate: [AdminGuardProvider],
		children: adminBibliothequeRoutes
	},{
		path: 'Parametre',
		component: AdminParametreComponent,
		canActivate: [AdminGuardProvider],
		children: adminParametreRoutes
	},{
		path: 'Frais',
		component: AdminFraisComponent,
		children: adminFraisRoutes
	},{
		path: 'Voyages',
		component: AdminVoyagesComponent,
		children: adminVoyagesRoutes
	},{
		path: 'Workflow',
		component: AdminWorkflowComponent,
		canActivate: [AdminGuardProvider],
		children: adminWorkflowRoutes
	},{
		path: 'Maintenance',
		children: adminMaintenanceRoutes
	},{
		path: '',
		redirectTo: 'Entreprise',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Entreprise'
	}
]

/**
 * Module d'administration
 *
 * @author Laurent Convert
 * @date 05/10/2022
 */
@NgModule({
	imports: [
		ShareModule,
		PreviewAlerteModule,
		DescriptionModule,
		XmlUploaderModule,
		AdminEntrepriseModule,
		AdminFraisModule,
		AdminBibliothequeModule,
		AdminWorkflowModule,
		AdminComptabiliteModule,
		AdminParametreModule,
		AdminVoyagesModule,
		AdminTravelhubModule,
		AdminMaintenanceModule
	],
	providers: [
		CurrencyPipe,
		EntrepriseService,
		LicenceService,
		RgpdService,
		DevisesService,
		LangueService,
		ReportingService,
		ReferentielsService
	],
	declarations: [
		AdminComponent
	],
})
export class AdminModule {
}