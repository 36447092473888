import {NgModule} from '@angular/core';
import {Routes} from "@angular/router";
import {EntrepriseVehiculesComponent} from "./entreprise-vehicules.component";
import {EntrepriseVehiculesService} from "./entreprise-vehicules.service";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {ShareModule} from "@share/share.module";
import {VehiculesListComponent} from './vehicules-list/vehicules-list.component';
import {VehiculesEnergiesComponent} from './vehicules-energies/vehicules-energies.component';
import {VehiculesPuissancesFiscalesComponent} from './vehicules-puissances-fiscales/vehicules-puissances-fiscales.component';
import {VehiculesTypesComponent} from './vehicules-types/vehicules-types.component';
import {VehiculesParametresComponent} from './vehicules-parametres/vehicules-parametres.component';
import {VehiculeDetailComponent} from '@components/admin/entreprise/vehicules/vehicules-list/vehicule-detail/vehicule-detail.component';
import {EnergieListItemComponent} from './vehicules-energies/energie-list-item/energie-list-item.component';
import {EnergieDetailComponent} from './vehicules-energies/energie-detail/energie-detail.component';
import {LieuModule} from "@components/lieu/lieu.module";
import {PuissanceFiscaleDetailComponent} from './vehicules-puissances-fiscales/puissance-fiscale-detail/puissance-fiscale-detail.component';
import {PuissanceFiscaleListItemComponent} from './vehicules-puissances-fiscales/puissance-fiscale-list-item/puissance-fiscale-list-item.component';
import {VehiculeTypeListItemComponent} from './vehicules-types/vehicule-type-list-item/vehicule-type-list-item.component';
import {VehiculeTypeDetailComponent} from './vehicules-types/vehicule-type-detail/vehicule-type-detail.component';
import {VehiculeListItemComponent} from './vehicules-list/vehicule-list-item/vehicule-list-item.component';
import {DocumentModule} from "@components/document/document.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {VehiculeModule} from "@components/vehicule/vehicule.module";
import {DroitAdmin} from "@core/security/droit-admin";


/**
 * Routes du menu Entreprise > Véhicules
 */
export const entrepriseVehiculesRoutes: Routes = [
    {
        path: 'Vehicules',
        component: VehiculesListComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_CONSULTATION_VEHICULE]}
    }, {
        path: 'Vehicules/:idVehicule',
        component: VehiculeDetailComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_CONSULTATION_VEHICULE]}
    }, {
        path: 'Energies',
        component: VehiculesEnergiesComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_GESTION_PARAMETRAGE_VEHICULES]}
    }, {
        path: 'PuissancesFiscales',
        component: VehiculesPuissancesFiscalesComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_GESTION_PARAMETRAGE_VEHICULES]}
    }, {
        path: 'Types',
        component: VehiculesTypesComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_GESTION_PARAMETRAGE_VEHICULES]}
    }, {
        path: 'Parametres',
        component: VehiculesParametresComponent,
        canActivate: [AdminGuardProvider],
        data: {sousAdminCredentials: [DroitAdmin.DROIT_GESTION_PARAMETRAGE_VEHICULES]}
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Vehicules'
    }, {
        path: '**',
        redirectTo: 'Vehicules'
    }
];

/**
 * Module du menu Entreprise > Vehicules
 */
@NgModule({
    declarations: [
        EntrepriseVehiculesComponent,
        VehiculesListComponent,
        VehiculeListItemComponent,
        VehiculeDetailComponent,
        VehiculesEnergiesComponent,
        EnergieListItemComponent,
        EnergieDetailComponent,
        VehiculesPuissancesFiscalesComponent,
        PuissanceFiscaleDetailComponent,
        PuissanceFiscaleListItemComponent,
        VehiculesTypesComponent,
        VehiculeTypeListItemComponent,
        VehiculeTypeDetailComponent,
        VehiculesParametresComponent
    ],
    imports: [
        ShareModule,
        LieuModule,
        DocumentModule,
        PreviewAlerteModule,
        VehiculeModule
    ],
    providers: [
        EntrepriseVehiculesService
    ]
})
export class EntrepriseVehiculesModule {
}
