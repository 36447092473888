import {NgModule} from '@angular/core';
import {RuleBuilderComponent} from './rule-builder.component';
import {RuleDetailComponent} from './rule-detail/rule-detail.component';
import {RuleItemComponent} from './rule-item/rule-item.component';
import {CoreComponentsModule} from "@share/component/core-components.module";
import {BusinessDataPopinComponent} from 'app/share/component/rule-builder/business-data/business-data-popin.component';
import {CustomInputModule} from "@share/component/custom-input/custom-input.module";
import {RuleBuilderService} from "@share/component/rule-builder/rule-builder.service";
import {RuleRepository} from "@share/component/rule-builder/rule-repository.service";
import {BusinessDataService} from "@share/component/rule-builder/business-data/business-data.service";
import {EntiteService} from "@share/component/rule-builder/entite.service";

@NgModule({
	declarations: [
		RuleBuilderComponent,
		RuleDetailComponent,
		RuleItemComponent,
		BusinessDataPopinComponent
	],
	exports: [
		RuleBuilderComponent
	],
	imports: [
		CoreComponentsModule,
		CustomInputModule
	],
	providers: [
		RuleBuilderService,
		RuleRepository,
		BusinessDataService,
		EntiteService
	]
})
export class RuleBuilderModule {
}
