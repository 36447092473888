import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CoursDeviseItem } from "@domain/admin/bibliotheque/devises/coursDeviseItem";
import { AppState } from "@domain/appstate";
import { TypeCodeErreur } from "@domain/common/http/result";
import { Devise } from "@domain/settings/devise";
import { SettingsGlobalState } from "@domain/settings/settings";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DevisesService } from "@services/admin/devises/devises.service";
import { ConfirmService } from "@share/component/confirmation/confirm.service";
import { filterFirstNotNull } from "@share/utils/rxjs-custom-operator";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

/**
 * Composant d'affichage du formulaire de modification d'un taux d'une devise
 */
@Component({
    host: {'data-test-id': 'devise-taux-modify'},
    selector: 'devise-taux-modify',
    templateUrl: './devise-taux-modify.component.html'
})
export class DeviseTauxModifyComponent implements OnInit, OnDestroy {
    /** Booléen indiquant si on est en mode création ou en mode modification */
    isCreation: boolean;

    /** Liste des différentes devises enregistrées */
    listeDevises: Devise[];

    /** Informations du nouveau taux créé */
    coursDevise: CoursDeviseItem = new CoursDeviseItem();

    /** Événement pour indiquer au composant parent que le taux a été modifié */
    @Output() modifyTauxEvent = new EventEmitter();

    /** Événement emit lors de la suppression du taux */
    @Output() deleteTauxEvent = new EventEmitter<any>();

    /** Liste des souscriptions, pour faire un unsubscribe sur chacun lors de la destruction du composant */
    souscriptions: Subscription[] = [];

    /**
     * Constructeur
     *
     * @param store Store de l'application
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param translateService Service de traduction
     * @param devisesService Service de gestion des devises
     * @param matDialog Boîte de dialogue pour afficher la confirmation de suppression du taux
     * @param confirmService Service de confirmation
     * @param matDialogRef
     * @param data Données passées en entrée de cette boîte de dialogue
     */
    constructor(
        private store: Store<AppState>,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private devisesService: DevisesService,
        private matDialog: MatDialog,
        private confirmService: ConfirmService,
        protected matDialogRef: MatDialogRef<DeviseTauxModifyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            taux: CoursDeviseItem,
            codeDevise: string
        }
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Si la variable "taux" de data est égale à null alors c'est qu'on est en mode création
        if(this.data.taux == null) {
            this.isCreation = true;
        } else {
            //Sinon, on récupère juste le taux sélectionné
            this.coursDevise = this.data.taux;
        }

        //Récupère toutes les devises enregistrées en base
        this.store.select(state => state.settings?.Global).pipe(filterFirstNotNull()).subscribe(
            (settings: SettingsGlobalState) => {
                this.listeDevises = settings.listeDevises;

                if (this.isCreation) {
                    //Suppression de la devise sélectionnée de la liste des devises, pour éviter de la choisir comme devise de contrepartie
                    this.listeDevises.splice(this.listeDevises.findIndex(p => p.code === this.data.codeDevise), 1);
                    //Initialisation de la devise de contrepartie avec la devise de l'entreprise
                    this.coursDevise.codeDeviseContrepartie = settings.deviseEntreprise;
                }
            });
    }

    /**
     * Méthode appelée lors de la validation de modification du taux
     */
    modifyTaux() {
        if (this.isCreation) {
            //Initialisation du code de l'origine du taux à SAISIE
            this.coursDevise.codeOrigine = 0;
            this.coursDevise.codeDevise = this.data.codeDevise;
        }
        //Sauvegarde en base de la modification du taux
        this.devisesService.saveTauxForDevise(this.coursDevise).subscribe(res => {
            if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Si aucune erreur n'est renvoyée alors on affiche un message de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                //On ferme la boîte de dialogue en indiquant qu'il faut mettre à jour la liste des taux
                this.matDialogRef.close(true);
            } else {
                //Si une erreur renvoyée alors on affiche un message d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
            }
        });
    }

    /**
     * Méthode appelée lors de la suppression du taux
     */
    deleteTaux() {
        //Ouverture de la boîte de dialogue pour confirmer la suppression du taux
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression du taux
                this.devisesService.deleteTauxForDevise(this.coursDevise).subscribe(res => {
                    if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Si aucune erreur n'est renvoyée alors on affiche un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        //On ferme la boîte de dialogue en indiquant qu'il faut mettre à jour la liste des taux
                        this.matDialogRef.close(true);
                    } else {
                        //Si une erreur renvoyée alors on affiche un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                });
            }
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Application de "unsubscribe" à toutes les souscriptions
        this.souscriptions.forEach(souscription => souscription.unsubscribe());
    }
}
