<div class="lvi-content">
  <div class="avatar">
    <mat-icon>domain</mat-icon>
  </div>
  <div class="lvi-body">
    <div class="title">
      <a *ngIf="data.libelle || data.rue" [routerLink]=""
         (click)="extraOptions.close(data)">{{ data.libelle ? data.libelle + (data.rue ? ', ' + data.rue : '') : data.rue}}</a>
      <a *ngIf="!(data.libelle || data.rue)" [routerLink]="" (click)="extraOptions.close(data)"
         [translate]="'admin.bibliotheque.geographie.adresses.noRue'"
         [translateParams]="{id: data.idAdresse}"></a>
    </div>
    <ul class="lvi-attrs">
      <li *ngIf="data.ville  || data.adresseVille?.libelle">
        <strong [translate]="'admin.bibliotheque.geographie.adresses.ville'"></strong>
        <span>{{ data.ville ? data.ville : data.adresseVille?.libelle }}</span>
      </li>
      <li *ngIf="data.pays || data.adressePays?.libelle">
        <strong [translate]="'admin.bibliotheque.geographie.adresses.pays'"></strong>
        <span *ngIf="data.codePays" class="flag-icon mr-1"
              [ngClass]="'flag-icon-'+ data.codePays.toLowerCase()"></span>
        <span *ngIf="!data.codePays && data.adressePays?.code2" class="flag-icon mr-1"
              [ngClass]="'flag-icon-'+ data.adressePays?.code2.toLowerCase()"></span>
        <span>{{ data.pays ? data.pays : data.adressePays.libelle }}</span>
      </li>
      <li>
        <strong [translate]="'admin.bibliotheque.geographie.adresses.localisee'"></strong>
        <span *ngIf="data.latitude !== 0 && data.longitude !== 0"
              [translate]="'global.oui'"></span>
        <span *ngIf="data.latitude === 0 || data.longitude === 0"
              [translate]="'global.non'"></span>
      </li>
    </ul>
  </div>
</div>
