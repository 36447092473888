import { Component,Input,OnInit } from '@angular/core';
import { Od } from "../../../../../domain/od/od";
import { SettingsODState } from "../../../../../domain/settings/settings";
import { EngagementsFrais } from "../../../../../domain/od/engagements/frais/engagements-frais";
import { ODService } from "../../../od.service";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../domain/appstate";
import * as settingsActions from "../../../../../reducers/settings";
import { TypePortee } from "../../../../../domain/workflow/workflow";
import { environment } from "../../../../../environments/environment";
import { finalize,first } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

/**
 * Composant du cadre "Frais"
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-engagements-frais',
	templateUrl: './od-engagements-frais.component.html'
})
export class OdEngagementsFraisComponent implements OnInit {
	/** URL de base */
	public baseUrl: string = environment.baseUrl;

	/** Ordre de mission */
	@Input() od: Od;

	/** Paramétrage de l'OD */
	settings: SettingsODState;

	/** Frais */
	engagements: EngagementsFrais;

	/** Indicateur du chargement en cours */
	isLoading: boolean = false;

	/** Indique si le détail de la ligne est affiché */
	isOuvert: boolean = true;

	/**
	 * Constructeur
	 */
	constructor(private odService: ODService,
				private store: Store<AppState>,
				private translateService: TranslateService,
				private toastrService: ToastrService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement des données
		this.isLoading = true;

		//Chargement du paramétrage de l'OD
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.OD
		});

		//Sélection du paramétrage
		this.store.select(state => state.settings?.[TypePortee.OD]).subscribe(settings => this.settings = settings);

		//Chargement des données
		this.refresh();
	}

	/**
	 * Rafraichissement des données
	 */
	refresh(): void {
		//Chargement des données
		this.isLoading = true;

		//Récupération des données sur les frais
		this.odService.getEngagementsFrais(this.od?.idOd)
			.pipe(first(), finalize(() => { this.isLoading = false; }))
			.subscribe({
				next: (engagementsFrais) => {
					//Instanciation de l'objet contenant les données sur les frais
					this.engagements = new EngagementsFrais(engagementsFrais);
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.chargement'));
				}
			});
	}

}