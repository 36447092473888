<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.type === TypeObjetATraiter.NDF" class="nio icon-note_de_frais"></i>
        <i *ngIf="data.type === TypeObjetATraiter.FACTURE" class="nio icon-releve_de_facture"></i>
        <i *ngIf="data.type === TypeObjetATraiter.OD" class="nio icon-ordre_de_mission"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a *ngIf="data.type === TypeObjetATraiter.NDF" class="label"><span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.ndf'"></span> : {{ data.piece }}
            </a>
            <a *ngIf="data.type === TypeObjetATraiter.FACTURE" class="label"><span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.facture'"></span>
                : {{ data.piece }}</a>
            <a *ngIf="data.type === TypeObjetATraiter.OD" class="label"><span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.od'"></span> : {{ data.piece }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.collaborateur">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.agent'"></strong>
                <span>{{ data.collaborateur }}</span>
            </li>
            <li *ngIf="data.statut">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.liste.statut'"></strong>
                <span>{{ data.statut }}</span>
            </li>
        </ul>
    </div>
</div>
