import {Component,Input,OnInit} from '@angular/core';
import {Analytique} from "@components/analytique/analytique";
import {TypeEntite} from '@domain/typeentite/typeEntite';
import {SettingsGlobalState} from "@domain/settings/settings";

/**
 * Composant d'affichage d'une ventilation analytique
 */
@Component({
  host: {'data-test-id': 'ventilation'},
  selector: 'ventilation',
  templateUrl: './ventilation.component.html',
  styleUrls: ['./ventilation.component.scss']
})
export class VentilationComponent implements OnInit{

  /** La ventilation analytique à afficher */
  @Input() ventilation : Analytique;

  /** Type entité */
  @Input() typeEntite: TypeEntite;

  /** Paramétrage global */
  @Input() globalSettings: SettingsGlobalState;

  /** Indique si la ligne est dépliée */
  isOuvert: boolean = false;

  /** Paramétrages analytiques */
  operation1;
  operation2;

  /**
   * Initialisation du composant
   */
  ngOnInit(): void {
    //Récupération des paramétrages analytiques
    this.operation1 = this.getFieldParam('OPERATION_1');
    this.operation2 = this.getFieldParam('OPERATION_2');
  }

  /**
   * Récupération d'un paramétrage analytique
   */
  getFieldParam(code: string): any {
    //Récupération du champ
    return this.globalSettings?.listeFieldParams?.find(p => p.champ == code);
  }

}
