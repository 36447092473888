<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.voyages.referentiels.title' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.COMPAGNIES ">
            <voyages-referentiels-compagnies></voyages-referentiels-compagnies>
        </div>
        <div [hidden]="selectedItem?.code !== Onglet.CARTES_VOYAGEURS ">
            <voyages-referentiels-cartes-voyageurs></voyages-referentiels-cartes-voyageurs>
        </div>
        <div [hidden]="selectedItem?.code !== Onglet.CLASSES ">
            <voyages-referentiels-classes></voyages-referentiels-classes>
        </div>
        <div [hidden]="selectedItem?.code !== Onglet.SYNCHRONISATIONS ">
            <voyages-referentiels-synchronisations></voyages-referentiels-synchronisations>
        </div>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>