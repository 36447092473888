<mat-card>
    <mat-card-title>
        <span [translate]="'od.engagements.indemnites.title'"></span>
        <!-- Acccès aux dégrèvement suivant la configuration générale et du type entité -->
        <span class="actions">
            <mat-icon (click)="openIndemnites()">search</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <please-wait *ngIf="isLoading"></please-wait>
        <form autocomplete="off" *ngIf="!isLoading">
            <div class="row">
                <label class="col-md-3 ">
                    {{ 'od.engagements.indemnites.montantTotal' | translate }}
                </label>
                <div class="col-md-3 r-flex mb-2 align-items-center">
                    <span>{{ montantOrigine | montant:settings?.deviseEntreprise }}</span>
                    <mat-icon *ngIf="listeHistoriqueIJ?.length > 0" class="clickable ml-1"
                              (click)="openHistorique()"
                              [nioTooltip]="'od.engagements.indemnites.tips.montantRecalcule' | translate">search</mat-icon>
                </div>

                <!-- Début bloc visible uniquement dans le contexte de l'onglet "Frais" -->
                <ng-container *ngIf="contexte == Contexte.ENGAGEMENTS">
                    <label class="col-md-3">{{ 'od.engagements.indemnites.montantDegreve' | translate }}</label>
                    <span class="col-md-3 ">{{ montantDegrevement | montant:settings?.deviseEntreprise }}</span>
                </ng-container>
                <!-- Fin bloc -->

                <!-- Début bloc visible uniquement dans le contexte de l'onglet "Engagements" (et profil non collaborateur) -->
                <ng-container *ngIf="contexte == Contexte.FRAIS && user?.fonction != TypeProfil.COLLABORATEUR">
                    <label class="col-md-3 required">
                        {{ 'od.frais.indemnites.coefficient' | translate }}
                    </label>
                    <div *ngIf="!canModifier" class="col-md-3 r-flex mb-2 align-items-center">
                        <span>{{ coefficientModificateurOd | number:'.2-2' }} %</span>
                        <mat-icon *ngIf="!!coefficientModificateurCollaborateur && coefficientModificateurCollaborateur != coefficientModificateurOd"
                                  class="warning ml-1"
                                  [nioTooltip]="'od.frais.indemnites.tips.recalculerIndemnites' | translate:{'coeff': coefficientModificateurCollaborateur}"
                        >warning_amber</mat-icon>
                    </div>
                    <div *ngIf="canModifier" class="ml-2 r-flex align-items-center">
                        <mat-form-field class="d-flex" [floatLabel]="'never'">
                            <input matInput #coefficientModificateurOdInput name="coefficientModificateurOd" [(ngModel)]="this.coefficientModificateurOd"
                                   [required]="true"
                                   [nNumber]="0"
                                   [errorStateMatcher]="coeffIjErrorStateMatcher"
                                   (keydown.enter)="coefficientModificateurOdInput.blur();"
                                   (change)="changeCoeffIj()">
                            <span matSuffix>&#160;%</span>
                        </mat-form-field>

                        <button matSuffix mat-icon-button color="primary" (click)="applyCoeffIj();" [nioTooltip]="'od.frais.indemnites.tips.recalculerIndemnites' | translate">
                            <mat-icon class="material-icons-outlined">sync</mat-icon>
                        </button>
                        <mat-icon *ngIf="!!coefficientModificateurCollaborateur && coefficientModificateurCollaborateur != coefficientModificateurOd"
                                  [nioTooltip]="'od.frais.indemnites.tips.coefficientCollaborateur' | translate:{'coeff': coefficientModificateurCollaborateur}"
                                  class="warning ml-1">warning_amber</mat-icon>
                    </div>
                </ng-container>
                <!-- Fin bloc -->
            </div>
            <div class="listview">
                <div class="listview-body">
                    <od-engagements-indemnites-item *ngFor="let prestation of indemnites?.indemnites" [prestation]="prestation" [od]="od" [settings]="settings"></od-engagements-indemnites-item>
                    <div class="empty" *ngIf="indemnites?.indemnites?.length == 0"><span [translate]="'od.engagements.indemnites.noData'"></span></div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
