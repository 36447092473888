import {Component,Input,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {FieldComponent} from "../field.component";
import {filter,finalize,first} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {Champ,FiltreChamp} from "@domain/travel/champ";
import {AdminTravelhubService} from "../../../../../admin-travelhub.service";
import {FieldUsed} from "@domain/travel/field-used";

/**
 * Ligne du cadre "fields"
 *
 * @author Laurent Convert
 * @date 18/12/2023
 */
@Component({
	host: {'data-test-id': 'field-list-item'},
	templateUrl: './field-list-item.component.html'
})
export class FieldListItemComponent<U extends FieldUsed> extends ListViewItem<U> implements OnInit {
	/** Options supplémentaires */
	@Input() public extraOptions: {
		listeChamps: Array<Champ>,
		parent: FieldComponent<U>
	};

	/** Code du Field */
	code: string;

	/** Valeur du Field */
	valeur: string;

	/** Icône déterminée en fonction du paramètre */
	icone: string;

	/**
	 * Constructeur
	 *
	 * @param confirmService Service de confirmation utilisateur via modale
	 * @param pleaseWaitService Service d'affichage du loading
	 * @param translateService Service de traduction
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 */
	constructor(private confirmService: ConfirmService,
				private pleaseWaitService: PleaseWaitService,
				private translateService: TranslateService,
				private adminTravelhubService: AdminTravelhubService) {
		super();
	}

	/**
	 * initialisation du composant
	 */
	ngOnInit() {
		let champ: Champ;

		//Récupération du code
		this.code = this.data.getCode() ?? this.data.libelleCustomCode;

		//Vérification de la valeur
		if (!!this.data.valeur) {
			//Retour de la valeur
			this.valeur = this.data.valeur;
		} else {
			//Recherche du champ correspondant au paramètre
			champ = this.adminTravelhubService.findChamp(this.data.idValeur);

			//Retour du code traduit
			this.valeur = champ ? `${this.translateService.instant(FiltreChamp.traduction(champ.filtre))} → ${this.translateService.instant(champ.libelle)}` : null;
		}

		//Récupération de l'icône en fonction du champ
		this.icone = this.adminTravelhubService.getIconeForField(this.data);
	}

	/**
	 * Suppression du Field
	 */
	deleteField(): void {
		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() =>{
				//Modale de chargement
				let pleaseWaitRef = this.pleaseWaitService.show();

				//Suppression
				this.extraOptions.parent.deleteField(this.data)
					.pipe(first(),finalize(() => pleaseWaitRef.close()))
					.subscribe();
			});
	}
}