<h1 mat-dialog-title>
    <span [translate]="'ndf.frais.popupDetailIk.title'" [translateParams]="{ marque: vehicule?.marque, modele: vehicule?.modele, immatriculation: vehicule?.immatriculation }"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="content">
    <card-alerte *ngIf="alerte" [niveau]="alerte.niveau">
        <preview-alerte [alerte]="alerte"></preview-alerte>
    </card-alerte>
    <mat-dialog-content *ngIf="!alerte">
        <list-view [liste]="listeCompteurDepense" *ngIf="listeCompteurDepense"></list-view>
        <list-view [liste]="listeDetailCalcul" *ngIf="listeDetailCalcul"></list-view>
    </mat-dialog-content>
</div>
