import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Result} from "@domain/common/http/result";
import {Observable} from "rxjs";
import {Population} from "@domain/admin/entreprise/population/population";
import {first} from "rxjs/operators";
import {LienPopulationOrga} from "@domain/admin/entreprise/population/lienPopulationOrga";
import {LienPopulationUser} from "@domain/admin/entreprise/population/lienPopulationUser";

/**
 * Service du menu Populations
 */
@Injectable()
export class PopulationsService {

    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) { }

    /**
     * Récupération d'une population
     *
     * @param idPopulation Identifiant de la population à récupérer
     */
    getPopulation(idPopulation: number): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Population/getPopulation/` + idPopulation).pipe(first());
    }

    /**
     * Enregistrement d'une population
     *
     * @param population Population à enregistrer
     */
    savePopulation(population: Population): Observable<Result> {
        return this.http.put<Result>(`${environment.baseUrl}/controller/Population/savePopulation`,population).pipe(first());
    }

    /**
     * Suppression d'une population
     *
     * @param idPopulation Identifiant de la population à supprimer
     */
    deletePopulation(idPopulation: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Population/deletePopulation?id_population=` + idPopulation, null).pipe(first());
    }

    /**
     * Suppression d'un lien collaborateur - population
     *
     * @param lienPopulationUserDto Lien utilisateur - population à supprimer
     */
    deleteUser(lienPopulationUserDto: LienPopulationUser): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Population/deleteUser`,lienPopulationUserDto).pipe(first());
    }

    /**
     * Suppression d'un lien service - population
     *
     * @param lienPopulationOrgaDto lien population - service à supprimmer
     */
    deleteOrga(lienPopulationOrgaDto: LienPopulationOrga): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Population/deleteService`,lienPopulationOrgaDto).pipe(first());
    }

    /**
     * Récupération des utilisateurs non rattachés à la population
     *
     * @param lienPopulationUser Lien population - user
     */
    addUserToPopulation(lienPopulationUser: LienPopulationUser): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Population/addUser`,lienPopulationUser).pipe(first());
    }

    /**
     * Récupération des services non rattachés à la population
     *
     * @param lienPopulationOrga Lien population - service
     */
    addOrgaToPopulation(lienPopulationOrga: LienPopulationOrga): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Population/addService`,lienPopulationOrga).pipe(first());
    }
}