import {AfterViewInit,ChangeDetectorRef,Component,OnInit,TemplateRef,ViewChild} from "@angular/core";
import {ActivatedRoute,NavigationExtras,Router} from "@angular/router";
import {filter,finalize,first} from "rxjs/operators";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ReferentielsService} from "@services/admin/referentiels/referentiels.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {NgForm} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {FraisReferentielInfosIconesComponent} from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-infos-icones.component";
import {ReferentielDto} from "@domain/admin/referentiels/referentielDto";
import {FillingRow} from "@share/component/filling-row/filling-row";
import {BehaviorSubject} from "rxjs";
import {TypeReferentiel} from "@domain/admin/referentiels/type-referentiel";
import {TypesDistances} from "@domain/admin/referentiels/types-distances";
import {NatureOcr} from "@domain/admin/parametre/demat/nature-ocr";
import {Onglets} from "@components/admin/frais/referentiel/onglets";

/**
 * Composant d'affichage du formulaire de création/modification d'un référentiel
 */
@Component({
	host: {'data-test-id': 'frais-referentiel-infos'},
	templateUrl: './frais-referentiel-infos.component.html'
})
export class FraisReferentielInfosComponent implements OnInit,AfterViewInit {
	/* Déclaration pour accès dans le template */
	TypeReferentiel = TypeReferentiel;

	/** Liste des différents types de référentiels disponibles */
	readonly listeTypeReferentiel = [{
		code: TypeReferentiel.TYPE_PRESTATION,
		libelle: this.translateService.instant('admin.frais.referentiels.types.typePrestation')
	},{
		code: TypeReferentiel.FAMILLE,
		libelle: this.translateService.instant('admin.frais.referentiels.types.famille')
	},{
		code: TypeReferentiel.LIASSE,
		libelle: this.translateService.instant('admin.frais.referentiels.types.liasse')
	},{
		code: TypeReferentiel.INDEMNITE,
		libelle: this.translateService.instant('admin.frais.referentiels.types.indemnite')
	},{
		code: TypeReferentiel.RUBRIQUE,
		libelle: this.translateService.instant('admin.frais.referentiels.types.rubrique')
	},{
		code: TypeReferentiel.NS_UNITE_DISTANCE,
		libelle: this.translateService.instant('admin.frais.referentiels.types.distance')
	},{
		code: TypeReferentiel.NS_UNITE,
		libelle: this.translateService.instant('admin.frais.referentiels.types.unite')
	}];

	/** Liste des différents types de distances disponibles */
	readonly listeTypesDistance = [{
		code: TypesDistances.KM,
		libelle: this.translateService.instant('admin.frais.referentiels.typesDistances.km')
	},{
		code: TypesDistances.MILES,
		libelle: this.translateService.instant('admin.frais.referentiels.typesDistances.miles')
	}];

	/** Liste des natures OCR **/
	readonly listeNaturesOCR = [{
		code: NatureOcr.NON_DEFINI,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.nonDefini',
		icone: 'help'
	},{
		code: NatureOcr.REPAS,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.repas',
		icone: 'local_dining'
	},{
		code: NatureOcr.PARKING,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.parking',
		icone: 'local_parking'
	},{
		code: NatureOcr.TRANSPORT,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.transport',
		icone: 'airport_shuttle'
	},{
		code: NatureOcr.HOTEL,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.hotel',
		icone: 'local_hotel'
	},{
		code: NatureOcr.CARBURANT,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.carburant',
		icone: 'local_gas_station'
	},{
		code: NatureOcr.FOURNITURE,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.fourniture',
		icone: 'shopping_cart'
	},{
		code: NatureOcr.PEAGE,
		libelle: 'admin.frais.referentiels.infos.listeNaturesOCR.peage',
		icone: 'streetview'
	}];

	/** Indicateur de si le formulaire est en mode création ou en mode modification */
	isCreation: boolean = false;

	/** Liste des différents onglets disponibles */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglet = OngletsReferentiel;

	/** Référentiel à afficher sur le formulaire */
	referentiel: ReferentielDto;

	/** Indicateur pour le floating button pour savoir si c'est en cours de sauvegarde/suppression */
	isSaving: boolean;

	/** Définit si le reste du formulaire (autre que le champ de sélection du type) doit être visible ou non sur la page */
	showForm: boolean;

	/** Formulaire de la page */
	@ViewChild('form') form: NgForm;

	/* Liste des templates à afficher (ou pas) dans la page */
	@ViewChild('tplType',{static: true}) tplType: TemplateRef<any>;
	@ViewChild('tplIcone',{static: true}) tplIcone: TemplateRef<any>;
	@ViewChild('tplLibelle',{static: true}) tplLibelle: TemplateRef<any>;
	@ViewChild('tplOrdre',{static: true}) tplOrdre: TemplateRef<any>;
	@ViewChild('tplDistance',{static: true}) tplDistance: TemplateRef<any>;
	@ViewChild('tplCode2',{static: true}) tplCode2: TemplateRef<any>;
	@ViewChild('tplCode3',{static: true}) tplCode3: TemplateRef<any>;

	/** Liste des éléments à afficher dans la colonne de gauche */
	listeTemplatesGauche: Array<FillingRow> = [];

	/** Liste des éléments à afficher dans la colonne de droite */
	listeTemplatesDroite: Array<FillingRow> = [];

	isTypeChanged: boolean;

	/** Onglet cible lors du retour à la liste des référentiels */
	targetTab: PageHeaderItem = null;

	/**
	 * Constructeur
	 *
	 * @param activatedRoute Route d'accès à cette page
	 * @param translateService Service de traduction
	 * @param confirmService
	 * @param toastrService
	 * @param referentielsService
	 * @param matDialog
	 * @param router Router de l'application pour naviguer entre les pages
	 * @param cd service de détection des changements
	 */
	constructor(
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private toastrService: ToastrService,
		private referentielsService: ReferentielsService,
		private matDialog: MatDialog,
		private router: Router,
		private cd: ChangeDetectorRef
	) {
		//Récupération de l'onglet sur lequel on était positionné sur l'écran précédent
		this.targetTab = this.router.getCurrentNavigation()?.extras?.state?.tab;
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'id du référentiel
		this.activatedRoute.params.pipe(first()).subscribe(params => {
			this.onInit(params['idReferentiel'],params['type']);
		});
	}

	/**
	 * Après initialisation de la vue
	 */
	ngAfterViewInit(): void {
		//On relance une vérification des changements pour esquiver une erreur "le contenu a changé après l'initialisation"
		this.cd.detectChanges();
	}

	/**
	 * Méthode d'initialisation du composant
	 *
	 * @param idReferentiel Identifiant, du référentiel courant, récupéré dans l'url
	 * @param type Type, du référentiel courant, récupéré dans l'url
	 */
	onInit(idReferentiel: string,type: string) {
		//On est en mode création si l'id indiqué dans l'url est -1 sinon on est en mode modification
		this.isCreation = idReferentiel === "-1";

		//Définition de la liste des actions possibles peu importe le mode (création ou non)
		this.listeActions.next([
			...this.listeActions.getValue() ?? [],
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-sauvegarde',
				libelle: 'global.actions.enregistrer',
				doAction: () => this.save(),
			}
		]);

		//Si on est en mode création alors on initialise un nouveau référentiel
		if (this.isCreation) {
			//Initialisation du nouveau référentiel
			this.referentiel = {
				type: "",
				libelle: "",
				code: "",
				icone: "",
				readOnly: false,
				ordre: 0,
				defaut: false
			}

			//Si l'on a un targetTab
			if (this.targetTab) {
				//Mise à jour du type de référentiel
				this.defineTypeFromTargetTab();

				//On déclenche manuellement changeType afin de mettre à jour la vue
				this.changeType();
			} else {
				//Mise à jour de l'affichage
				this.updateLayout();
			}
		} else {
			//Si on est en mode modification alors on ajoute la possibilité de supprimer le référentiel
			this.listeActions.next([
				...this.listeActions.getValue() ?? [],
				{
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					doAction: () => this.delete(),
				}
			]);

			//On récupère toutes les informations du référentiel sélectionné
			this.referentielsService.loadReferentiel({id: Number(idReferentiel),type}).subscribe(data => {
				this.referentiel = data.data.referentiel;

				//On affiche le formulaire avec toutes les informations associées au référentiel
				this.showForm = true;

				//Mise à jour de l'affichage
				this.updateLayout();
			})
		}

		//Initialisation des différents onglets disponibles sur la page
		this.listeTabItems = [{
			code: OngletsReferentiel.REFERENTIEL,
			libelle: this.translateService.instant('admin.frais.referentiels.infos.onglets.referentiel')
		}];

		//Initialisation de l'onglet sélectionné par défaut
		this.selectedItem = this.listeTabItems[0];
		this.selectedItem.selected = true;
	}

	/**
	 * Mise à jour de l'affichage de l'écran en fonction du type du référentiel
	 */
	updateLayout() {
		//(Ré)Initialisation de l'affichage des templates gauche / droite
		this.listeTemplatesGauche.splice(0);
		this.listeTemplatesDroite.splice(0);

		//Affichage du type dans tous les cas
		this.listeTemplatesGauche.push(new FillingRow(this.tplType));

		//Vérification de l'affichage du reste du formulaire
		if (this.showForm) {
			//Affichage du libellé
			this.listeTemplatesGauche.push(new FillingRow(this.tplLibelle));

			//Affichage du champ Distance pour un référentiel de type 'Distance' (ty Cpt Obvious)
			if (this.referentiel.type === TypeReferentiel.NS_UNITE_DISTANCE) {
				this.listeTemplatesGauche.push(new FillingRow(this.tplDistance));
			}

			//Code (même champ sur l'objet utilisé pour stocker soit le type de distance, soit un code correspondant au type du référentiel)
			if (this.referentiel.type === TypeReferentiel.RUBRIQUE) {
				this.listeTemplatesGauche.push(new FillingRow(this.tplCode2));
			} else if (this.referentiel.type !== TypeReferentiel.NS_UNITE_DISTANCE && this.referentiel.type !== TypeReferentiel.NS_UNITE && this.referentiel.type !== TypeReferentiel.RUBRIQUE) {
				this.listeTemplatesGauche.push(new FillingRow(this.tplCode3));
			}

			//Icône suivant le type de référentiel
			if ([TypeReferentiel.FAMILLE.valueOf(),TypeReferentiel.INDEMNITE.valueOf(),TypeReferentiel.RUBRIQUE.valueOf()].includes(this.referentiel.type)) {
				this.listeTemplatesDroite.push(new FillingRow(this.tplIcone));
			}

			//Affichage d'une notion d'ordre pour les référentiels dont le type est différent de Distance et Unité
			if (this.referentiel.type !== TypeReferentiel.NS_UNITE_DISTANCE && this.referentiel.type !== TypeReferentiel.NS_UNITE) {
				this.listeTemplatesDroite.push(new FillingRow(this.tplOrdre));
			}
		}

		//On relance une vérification des changements pour rafraichir l'affichage des templates
		this.cd.detectChanges();
	}

	/**
	 * Définit le type de référentiel à partir du targetTab
	 */
	defineTypeFromTargetTab() {
		//On définit le type par défaut
		switch (this.targetTab?.code) {
			case Onglets.FAMILLE:
				this.referentiel.type = TypeReferentiel.FAMILLE;
				break;
			case Onglets.INDEMNITE:
				this.referentiel.type = TypeReferentiel.INDEMNITE;
				break;
			case Onglets.LIASSE:
				this.referentiel.type = TypeReferentiel.LIASSE;
				break;
			case Onglets.TYPE_PRESTATION:
				this.referentiel.type = TypeReferentiel.TYPE_PRESTATION;
				break;
			case Onglets.RUBRIQUE:
				this.referentiel.type = TypeReferentiel.RUBRIQUE;
				break;
			case Onglets.UNITE:
				this.referentiel.type = TypeReferentiel.NS_UNITE;
				break;
		}
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Méthode appelée lors de l'enregistrement du référentiel courant
	 */
	save() {
		//On sauvegarde si le formulaire est valide
		if (this.form.valid) {
			//On affiche sur le floating button le fait que la sauvegarde est en cours
			this.isSaving = true;
			//Si le type du référentiel est F (Famille) ou I (Indemnité) alors on vérifie l'icône
			if (this.referentiel.type === TypeReferentiel.FAMILLE || this.referentiel.type === TypeReferentiel.INDEMNITE) {
				if (!this.referentiel.icone) {
					//Si le formulaire n'est pas valide alors on renvoie une erreur
					this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
					this.isSaving = false;
					return;
				}
			}
			//On enregistre le référentiel en base
			this.referentielsService.saveReferentiel(this.referentiel)
				.pipe(finalize(() => this.isSaving = false))
				.subscribe({
					next: (res) => {
						if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Si aucune erreur n'est renvoyée alors on affiche un message de succès
							this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

							//On retourne sur la liste des référentiels
							this.onGoBack(this.referentiel);
						} else {
							//Si une erreur renvoyée alors on affiche un message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
						}
					},
					error: (err) => {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
					}
				});
		} else {
			//Si le formulaire n'est pas valide alors on renvoie une erreur
			this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
		}
	}

	/**
	 * Suppression du référentiel en base
	 */
	delete() {
		//Affichage de la boîte de dialogue de confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Si l'utilisateur a confirmé la suppression alors on supprime le référentiel en base
					this.referentielsService.deleteReferentiel(this.referentiel).subscribe((res: Result) => {
						if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Si le résultat renvoyé ne contient pas d'erreur alors on affiche un message de succès pour la suppression du référentiel
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//On retourne sur la liste des référentiels
							this.onGoBack();
						} else {
							//Sinon on affiche un message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.suppression'));
						}
					});
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.suppression'));
				}
			});
	}

	/**
	 * Méthode appelée lors de la sélection d'affichage des icônes
	 */
	onSelect() {
		//Ouverture de la boîte de dialogue affichant toutes les icônes disponibles en fonction du type du référentiel
		this.matDialog.open(FraisReferentielInfosIconesComponent,{
			data: {
				type: this.referentiel.type
			},
			width: '10%'
		}).afterClosed().subscribe(icone => {
			//Si une icône a été sélectionnée alors on met à jour celle du référentiel
			if (icone !== true) {
				this.referentiel.icone = icone;
			}
		});
	}

	/**
	 * Retour en arrière, vers la liste des référentiels
	 */
	onGoBack(referentiel?: ReferentielDto) {
		//Définition des extras
		const extras: NavigationExtras = {
			state: {
				referentiel: referentiel || null,
				tab: referentiel ? null : this.targetTab
			}
		}

		//Navigation vers la liste des référentiels
		this.router.navigate(['Admin/Frais/Referentiels'],extras);
	}

	/**
	 * Méthode appelée lorsque le type du référentiel change
	 */
	changeType() {
		this.isTypeChanged = true;
		this.cd.detectChanges();
		this.isTypeChanged = false;

		//Si aucun type de référentiel n'est sélectionné alors on affiche plus le formulaire
		this.showForm = this.referentiel.type != "";

		//Mise à jour de l'affichage
		this.updateLayout();
	}
}

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum OngletsReferentiel {
	REFERENTIEL = "Référentiel",
}