import { FicheCollaborateurService } from './fiche-collaborateur.service';
import { FicheCollaborateurComponent } from './fiche-collaborateur.component';
import { NgModule } from '@angular/core';

import { ShareModule } from '../../share/share.module';


@NgModule({
    imports: [ShareModule],
    declarations: [FicheCollaborateurComponent],
    exports: [FicheCollaborateurComponent],
    providers: [FicheCollaborateurService]
})
export class FicheCollaborateurModule { }