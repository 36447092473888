import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Alerte,NiveauAlerte} from '@domain/common/alerte/alerte';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";

@Component({
    selector: 'alerte',
    templateUrl: './alerte.component.html'
})
export class AlerteComponent implements OnInit {
    /* Déclaration pour accès dans le template */
    NiveauAlerte = NiveauAlerte;
    
    /** Liste des alertes */
    listeAlertes: Array<Alerte>;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService, 
        @Inject(MAT_DIALOG_DATA) private data: { alertes?: any[], retrieveListeAlertes?: () => Observable<ListeAlertes>, translate?: boolean}) {}
    
    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Liste d'alertes fournies
        if (this.data.alertes) {
            //Mise à jour de la liste des alertes
            this.listeAlertes = this.data.alertes;
            this.findTranslateMessage();
        }
        //Alertes à récupérer
        else if (this.data.retrieveListeAlertes) {
            //Récupération de la liste des alerts
            this.data.retrieveListeAlertes().subscribe({
                next: result => {
                    //Mise à jour de la liste des alertes
                    this.listeAlertes = result.listeAlertes;
                    this.findTranslateMessage();
                }
            });
        }
        //Erreur
        else {
            throw new Error("Fournir obligatoirement soit une liste peuplée soit une méthode à appeler");
        }
    }

    /** Ajoute le titre et le message sur l'alerte pour les alertes qui ne sont pas customs */
    findTranslateMessage(): void {
        this.listeAlertes.forEach((alerte: Alerte) => {
            //Si on n'a pas de message et titre
            if (alerte.titre == null) {
                //On récupère le nom dans le fichier de traduction
                const titreTranslate = 'workflow.alerte.type.titre.' + alerte.type;
                const messageTranslate = 'workflow.alerte.type.message.' + alerte.type;

                //On tente de traduire
                const titreTranslated = this.translateService.instant(titreTranslate);
                const messageTranslated = this.translateService.instant(messageTranslate);

                //Si on a récupéré un titre
                if (titreTranslate != titreTranslated) {
                    //On l'applique
                    alerte.titre = titreTranslated;
                }

                //Si on a récupéré un message
                if (messageTranslate != messageTranslated) {
                    //On l'applique
                    alerte.message = messageTranslated;
                }
            } else if (this.data.translate) {
                //Traduction du titre de l'alerte
                if (alerte.titre) {
                    alerte.titre = this.translateService.instant(alerte.titre,alerte.traductionParams);
                }

                //Traduction du message de l'alerte
                if (alerte.message) {
                    alerte.message = this.translateService.instant(alerte.message,alerte.traductionParams);
                }
            }
        });
    }
}