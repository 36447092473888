<!-- Titre -->
<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.referentiels.modificationTitle'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>

<!-- Onglets -->
<div mat-dialog-content>
    <mat-tab-group #matTabGroup dynamicHeight="dynamicHeight" animationDuration="500ms" >
        <mat-tab label="{{ 'admin.entreprise.referentiels.onglets.generalites' | translate }}">
            <form #form="ngForm">
                <div class="row">
                    <custom-input name="Type" customType="input" libelle="admin.entreprise.referentiels.type"
                                  [(ngModel)]="data.referentiel.type" ngDefaultControl [readonly]="true"></custom-input>
                </div>
                <div class="row">
                    <custom-input [(ngModel)]="data.referentiel.libelle"
                                  id="libelle"
                                  name="libelle"
								  #libelle="ngModel"
                                  customType="input"
                                  libelle="admin.entreprise.referentiels.libelle"
                                  maxlength="50"
                                  [required]="true"
                                  ngDefaultControl>
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input [(ngModel)]="data.referentiel.code"
                                  id="code"
                                  name="code"
								  #code="ngModel"
                                  customType="input"
                                  libelle="admin.entreprise.referentiels.code"
                                  ngDefaultControl>
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input [(ngModel)]="data.referentiel.ordre"
                                  id="ordre"
                                  name="ordre"
								  #ordre="ngModel"
                                  customType="number"
                                  min="0"
                                  libelle="admin.entreprise.referentiels.ordre"
                                  ngDefaultControl>
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input *ngIf="data.referentiel.type === 'Catégorie'"
                                  [ngModel]="data.referentiel.defaut"
                                  [(customModel)]="data.referentiel.defaut"
                                  id="isDefaut"
                                  name="isDefaut"
                                  customType="checkbox"
                                  libelle="admin.entreprise.referentiels.isDefaut"
                                  [disabled]="data.referentiel.defaut"
                                  ngDefaultControl>
                    </custom-input>
                </div>
                <div class="d-flex flex-row-reverse">
                    <button mat-flat-button color="primary" class="ml-2"
                            (click)="saveReferentiel()"
                            [disabled]="form.invalid">
                        <span translate>global.actions.enregistrer</span>
                    </button>
                    <button  mat-stroked-button color="warn"
                            (click)="deleteReferentiel()"
                            [disabled]="form.invalid">
                        <span translate>global.actions.supprimer</span>
                    </button>
                </div>
            </form>
        </mat-tab>
        <mat-tab label="{{ 'admin.entreprise.referentiels.onglets.usage' | translate }}">
            <list-view *ngIf="listeUtilisateurs" [liste]="listeUtilisateurs"></list-view>
        </mat-tab>
    </mat-tab-group>
</div>
