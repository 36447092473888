import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Classe représentant les énergie fiscales des véhicules
 */
export class EnergieFiscale implements ListItem {
    code?: string;
    idEnergie?: number;
    libelle?: string;
    ordre?: number;

    getKey(): number {
        return this.idEnergie;
    }

    /**
     * Constructeur
     *
     * @param dto DTO ou objet source à copier
     */
    constructor(dto?: any | EnergieFiscale) {
        if (dto) {
            //Copie
            Object.assign(this, dto);
        }
    }
}