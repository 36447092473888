<h1 mat-dialog-title>
    <span class="actions" [mat-dialog-close]>
        <span><i class="zmdi zmdi-close"></i></span>
    </span>
</h1>
<div mat-dialog-content>
    <mat-card-content>
        <form autocomplete="off">
            <div class="row">
                <custom-input ngDefaultControl name="checkAllCreation" [ngModel]="checkAllCreation" [(customModel)]="checkAllCreation"
                              customType="checkbox" lCol="3" rCol="9"
                              libelle="od.creationOdParticipants.creation" postLibelle="od.creationOdParticipants.creationPost"></custom-input>
            </div>
            <div class="row" *ngIf="data.isEmissionAutorisee">
                <custom-input ngDefaultControl name="checkAllEmission" [ngModel]="checkAllEmission" [(customModel)]="checkAllEmission"
                              customType="checkbox" lCol="3" rCol="9"
                              libelle="od.creationOdParticipants.Emission" postLibelle="od.creationOdParticipants.EmissionPost"></custom-input>
            </div>
            <list-view [liste]="liste" *ngIf="liste"></list-view>
        </form>
    </mat-card-content>
</div>

<!-- Bouton d'ajout des participants à la mission -->
<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="valider()"><span translate>global.actions.valider</span>
    </button>
</div>
