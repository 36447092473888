/**
 * Nature d'une avance
 */
export enum NatureAvance {
    ESPECE = "ESP",
    VIREMENT = "VIR"
}

/**
 * On crée un namespace avec le même nom que l'énumération pour lui implémenter des fonctionnalités supplémentaires
 */
export namespace NatureAvance {
    /**
     * Méthode qui revoie le libelle de traduction correspondant à NatureAvance en paramètre
     */
    export function traduction(natureAvance: NatureAvance): string {
        switch (NatureAvance[natureAvance]) {
            case this.ESPECE:
                return 'avance.liste.typeESP';
            case this.VIREMENT:
                return 'avance.liste.typeVIR';
        }
    }
}