<div class="lvi-content">
    <div class="avatar">
        <i [ngClass]="getIcone()"></i>
    </div>
    <div class="lvi-body">
        <div class="title" (click)="onSelect()">
            <a>{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.portee'"></strong>
                <span [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + data.idPortee"></span>
            </li>
            <li *ngIf="data.reference">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.reference'"></strong>
                <span>{{ data.reference }}</span>
            </li>
            <li *ngIf="data.code">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.ordre'"></strong>
                <span>{{ data.ordre }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.obligatoire'"></strong>
                <span [translate]="'global.' + (data.obligatoire ? 'oui' : 'non')"></span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.type'"></strong>
                <span [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + data.typeChamp"></span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.actif'"></strong>
                <span [translate]="'global.' + (data.actif ? 'oui' : 'non')"></span>
            </li>
        </ul>
    </div>
</div>