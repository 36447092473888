import {Nature} from "@domain/prestation/nature";

/**
 * Enumération des types de nature
 *
 * @author Laurent Convert
 * @date 30/01/2023
 */
export enum TypeNature {
    INCONNUE = 'INCONNUE',
    TRAIN = 'TRAIN',
    AVION = 'AVION',
    HEBERGEMENT = 'HEBERGEMENT',
    VOITURE = 'VOITURE',
    DIVERS = 'DIVERS',
    FRAIS = 'FRAIS',
}

export namespace TypeNature {
    /**
     * Retourne l'icône associée à la nature
     *
     * @param typeNature La nature
     */
    export function icone(typeNature: TypeNature): string {
        let nature: Nature;
        let icone: string;
        
        if (TypeNature[typeNature]) {
            typeNature = TypeNature[typeNature];
        }
        
        switch (typeNature) {
            case TypeNature.TRAIN:
                nature = Nature.TRAIN;
                break;
            case TypeNature.AVION:
                nature = Nature.AVION;
                break;
            case TypeNature.HEBERGEMENT:
                nature = Nature.HOTEL;
                break;
            case TypeNature.VOITURE:
                nature = Nature.VOITURE;
                break;
            case TypeNature.DIVERS:
                nature = Nature.DIVERS;
                break;
            case TypeNature.FRAIS:
                nature = Nature.FRAIS;
                break;
            case TypeNature.INCONNUE:
                break;
            default:
                nature = Nature.AUCUNE;
                break;
        }
        
        if (nature) return Nature.icone(nature);
        else return '<i class="material-icons-outlined align-middle">question_mark</i>';
    }
    
    /**
     * Retourne le code de traduction correspondant à une nature
     *
     * @param typeNature La nature
     */
    export function traduction(typeNature: TypeNature): string {
        return 'facture.nature.' + typeNature.toLowerCase();
    }
}