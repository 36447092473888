import {AfterViewInit,Component,EventEmitter,forwardRef,Input,OnDestroy,OnInit,Output} from '@angular/core';
import {ControlValueAccessor,FormControl,NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime,filter,finalize,switchMap,tap} from 'rxjs/operators';

import {AdresseService} from './adresse.service';
import {ToastrService} from "ngx-toastr";
import {LieuService} from "@components/lieu/lieu.service";
import {GeolocalisationTool} from "@domain/geolocalisation/geolocalisationTool";
import {TypeGeolocalisation} from "@domain/geolocalisation/typeGeolocalisation";
import {TranslateService} from "@ngx-translate/core";
import {Localisation} from "@domain/geolocalisation/localisation";
import {Adresse} from "@domain/profil/adresse";
import {FloatLabelType} from "@angular/material/form-field";

@Component({
	selector: 'adresse',
	templateUrl: './adresse.component.html',
	exportAs: 'adresse',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => AdresseComponent),
		multi: true
	}]
})
export class AdresseComponent implements OnInit,OnDestroy,ControlValueAccessor,AfterViewInit {
	/** État du composant **/
	@Input() disabled?: boolean;

	/** Sélection d'un élément **/
	@Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

	/** Placeholder **/
	@Input() placeholder: string = '';

	/** Caractère obligatoire du champ */
	@Input() required?: boolean = false;

	/** Indique si on doit afficher l'icone search */
	@Input() isShowSearchIcon: boolean = false;

	/** Largeur de la liste */
	@Input() panelWidth: string = "";

	/** Indique si l'input doit remonter une erreur si l'user à saisie une valeur sans avoir sélectionné une valeur dans la liste */
	@Input() isSelectedValueRequired = false;

	/** Type du float label à afficher (never par défaut) */
	@Input() floatLabel?: FloatLabelType = 'never';

	/** Contrôle **/
	autocompleteControl = new FormControl();

	/** Données recherchées **/
	searchedValue: string;

	/** Liste des adresses **/
	listeItems: Array<Localisation>;

	/** Indicateur de chargement **/
	isLoading: boolean = false;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Adresse courante **/
	_value: any;

	/** Liste des souscriptions */
	private listeSouscriptions: Array<Subscription> = new Array<Subscription>();

	/** Configuration de la géolocalisation, désactivée par défaut */
	geolocalisationTool: GeolocalisationTool = {
		geolocalisationTool: TypeGeolocalisation.DISABLED
	};

	/**
	 * Constructeur
	 */
	constructor(private lieuService: LieuService,
				private adresseService: AdresseService,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private adressService: AdresseService) {}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération des outils de géolocalisation
		this.lieuService.getGeolocalisationTool().subscribe({
			next: (geolocalisationTool) => {
				//Récupération de la configuration de la géolocalisation et surcharge de la conf initialisée par défaut
				this.geolocalisationTool = {
					...this.geolocalisationTool,
					...geolocalisationTool
				};
			}
		})

		//Filtrage des options possibles
		this.listeSouscriptions.push(this.autocompleteControl.valueChanges.pipe(
			//On espace les appels d'au moins 750ms
			debounceTime(750),
			//On n'appelle pas le back si l'écran n'est pas init, si l'utilisateur a saisi moins de 3 caractères, ou si les params de géolocalisation ne sont pas ok.
			filter(i => this.isInit && i?.length > 2 && this.searchedValue !== this._value?.libelle && this.isGeolocOk()),
			tap(() => {
				//Suppression des valeurs existantes
				this.listeItems = null;

				//Indicateur de chargement
				this.isLoading = true;
			}),
			switchMap(value => this.adresseService.searchLocation(this.searchedValue).pipe(
				finalize(() => {
					//Fin du chargement
					this.isLoading = false;
				})
			))
		).subscribe({
			next: listeItems => this.listeItems = listeItems?.map?.(i => ({ ...i,libelle: this.buildLibelle(i) }))
		}));
	}

	/**
	 * Check si les paramètres de géolocalisation sont bien ok pour un appel
	 *
	 * @return true si les paramètres de géolocalisation sont ok, false sinon
	 */
	private isGeolocOk(): boolean {
		//On vérifie que la géolocalisation est bien activée
		if (this.geolocalisationTool.geolocalisationTool !== TypeGeolocalisation.DISABLED) {
			if (this.geolocalisationTool.geolocalisationTool === TypeGeolocalisation.VIA_MICHELIN) {
				//Si la géolocalisation est paramétrée sur ViaMichelin, on vérifie que le quota de requêtes n'est pas atteint
				if (this.geolocalisationTool.quotaLicence.quotaAtteint) {
					//S'il est atteint, on toaste une erreur et on renvoie false
					this.toastrService.error(this.translateService.instant('lieu.quotaGeolocAtteint'));
					return false;
				}
			}
			//Si la géolocalisation est Google Maps ou si elle est ViaMichelin sans dépassement de quota, aucun problème on renvoie true
			return true;
		}
		//Sinon on toaste une erreur et on renvoie false
		this.toastrService.error(this.translateService.instant('lieu.geolocDesactivee'));
		return false;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Annulation des souscriptions
		this.listeSouscriptions.forEach(s => s.unsubscribe());
	}

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation effectuée
			this.isInit = true;

			//Définition de la valeur affichée
			this.setSearchedValue(this._value);
		});
	}

	/**
	 * Interception d'un changement de valeur
	 */
	onOptionSelected(value: any) {
		//Définition du modèle lié à l'autocomplete
		this.value = value;

		//Mise à jour de la suggestion
		this.searchedValue = value?.libelle;

		//Sélection d'un élément
		this.onSelect.emit(value);

		//Remise à zéro de la liste déroulante
		this.listeItems = null;
	}

	/**
	 * Vérification du changement de valeur de la saisie
	 */
	onBlur($event) {
		//Vérification de la valeur saisie
		if (!$event.target.value)
			//Réinitialisation du modèle
			this.onOptionSelected(null);
	}

	/**
	 * Mise à jour de la valeur depuis l'extérieur du composant
	 */
	writeValue(value: any) {
		//Mise à jour de la valeur
		this.value = value;

		//Mise à jour de la suggestion
		this.setSearchedValue(value);
	}

	/**
	 * Enregistrement d'un changement
	 */
	registerOnChange(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement d'un appui
	 */
	registerOnTouched(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Vérification de la valeur
		if (value !== undefined && value !== this.value) {
			//Déclenchement de l'interception du changement
			this.isInit && this.onChange?.(value);

			//Déclenchement de l'interception de l'appui
			this.isInit && this.onTouch?.(value);

			//Enregistrement de la valeur
			this._value = value;
		}
	}

	/**
	 * Fabrication du libellé en fonction de la localisation
	 *
	 * @param loc la localisation
	 */
	private buildLibelle(loc: Localisation): string {
		//Dans le cas de ViaMichelin, on doit fabriquer le libellé
		if (this.geolocalisationTool.geolocalisationTool === TypeGeolocalisation.VIA_MICHELIN) {
			//Si l'adresse comporte une rue
			if (loc.rue) {
				//On retourne : "{Numéro} {Rue}, {CodePostal} {Ville}, {Pays}"
				return (loc.numero ? loc.numero + ' ' : '') + loc.rue + ', ' + loc.codePostal + ' ' + loc.ville + ', ' + loc.pays;
			} else {
				//Cas d'une ville : on retourne "{CodePostal} {Ville}, {Département}, {Pays}"
				return (loc.codePostal ? loc.codePostal + ' ' : '') + loc.ville + ', ' + loc.departement + ', ' + loc.pays;
			}
		} else {
			//Dans le cas de Google Maps, l'API est de qualité et s'en charge
			return loc.adresse;
		}
	}

	/**
	 * Setter pour searchedValue
	 *
	 * @param adresse l'adresse a affecter
	 */
	setSearchedValue(adresse: Adresse) {
		//Si on a un libellé sur l'adresse
		if (adresse?.libelle != null && adresse?.libelle != "") {
			//On l'utilise
			this.searchedValue = adresse.libelle
		} else {
			//Sinon on le cherche
			this.searchedValue = this.tryToFindLibelle(adresse);
		}
	}

	/**
	 * On essaye de récupérer le libellé via l'adresse
	 *
	 * @param adresse l'adresse sans libellé
	 */
	tryToFindLibelle(adresse: Adresse): string {
		//Si on a pas de valeur on renvoie rien
		if (adresse == null) {
			return '';
		}

		//On récupère la ville dans l'adresse
		return this.adressService.getVilleFromAdress(adresse);
	}

	/**
	 * Reset value
	 */
	resetValue() {
		//Si on a activé le contrôle sur la sélection
		if (this.isSelectedValueRequired) {
			//On reset value qui sera renseigné à la sélection dans la liste par l'user
			this.value = null;
		}
	}
}