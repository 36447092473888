import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../autocomplete-line-item.component";
import {MontantPipe} from "@share/pipe/montant";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";

/**
 * Item de l'autocomplete pour l'enveloppe
 *
 * @author Lionel GIMENEZ
 * @date 30/10/2023
 */
@Component({
    host: {'data-test-id': 'enveloppe-line-item'},
    templateUrl: './enveloppe-line-item.html'
})
export class EnveloppeLineItemComponent extends AutocompleteLineItemComponent<MontantDto> {

    /**
     * Constructeur
     */
    constructor(public montantPipe: MontantPipe) {
        super();
    }

    /**
     * Retourne le montant formaté
     * @return le montant
     */
    getMontant(): string {
        return this.montantPipe.transform(this.item.montant,this.item.codeDevise);
    }
}