<mat-card>
    <mat-card-title-group>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.devises.infosDevise.gestionAvances.title'"></span>
        </mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
        <div class="row">
            <custom-input ngDefaultControl
                          id="isAvanceVirement"
                          name="isAvanceVirement"
                          [ngModel]="devise.avanceVirement"
                          [(customModel)]="devise.avanceVirement"
                          customType="checkbox"
                          libelle="admin.bibliotheque.devises.infosDevise.gestionAvances.virement"
                          postLibelle="admin.bibliotheque.devises.infosDevise.gestionAvances.virementPostLibelle"></custom-input>

            <custom-input ngDefaultControl
                          id="isAvanceEspece"
                          name="isAvanceEspece"
                          [ngModel]="devise.avanceEspece"
                          [(customModel)]="devise.avanceEspece"
                          customType="checkbox"
                          libelle="admin.bibliotheque.devises.infosDevise.gestionAvances.especes"
                          postLibelle="admin.bibliotheque.devises.infosDevise.gestionAvances.especesPostLibelle"></custom-input>
        </div>

        <div class="row">
            <custom-input ngDefaultControl
                          id="navance"
                          name="navance"
                          [(ngModel)]="devise.navance"
                          customType="input"
                          rCol="2"
                          libelle="admin.bibliotheque.devises.infosDevise.gestionAvances.ecritureAvances"
                          [disabled]="!devise.avanceVirement"
                          maxlength="10"></custom-input>

            <custom-input ngDefaultControl
                          id="nrendu"
                          name="nrendu"
                          [(ngModel)]="devise.nrendu"
                          maxlength="10"
                          customType="input"
                          lCol="4"
                          rCol="2"
                          libelle="admin.bibliotheque.devises.infosDevise.gestionAvances.renduAvances"
                          [disabled]="!devise.avanceVirement"></custom-input>
        </div>
    </mat-card-content>
</mat-card>
