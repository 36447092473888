import {HttpErrorResponse,HttpEvent,HttpHandler,HttpInterceptor,HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable,throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ToastrService} from "ngx-toastr";

/**
 * Interception des erreurs 406 du WAF pour afficher un toast d'erreur.
 *
 * @author François Turin
 * @date 30/06/2023
 */
@Injectable()
export class FirewallInterceptor implements HttpInterceptor {
	/**
	 * Constructeur
	 */
	constructor(private router: Router,private confirmService: ConfirmService,private toastrService: ToastrService,private translateService: TranslateService) {
	}

	/**
	 * Interception de la réponse HTTP et gestion de l'erreur 406
	 */
	intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
		//Vérification du code http 406
		return next.handle(request).pipe(catchError(error => {
			//Vérification d'un code HTTP 406
			if (error instanceof HttpErrorResponse && error.status === 406) {
				//Affichage du toast
				this.toastrService.error(this.translateService.instant('global.http.errors.406.message'),this.translateService.instant('global.http.errors.406.title'));
			}

			//Retour de la réponse en erreur
			return throwError(error);
		}));
	}
}
