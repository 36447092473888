<h1 mat-dialog-title>
    <span translate>workflow.alerte.liste.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div *ngIf="listeAlertes" class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let alerte of listeAlertes">
                <div class="lvi-content">
                    <div class="avatar" [ngClass]="{ 'warning': alerte.niveau == NiveauAlerte.WARNING,'danger': alerte.niveau == NiveauAlerte.ERROR }">
                        <mat-icon class="material-icons-outlined" style="vertical-align: middle;transform: scale(1.8);">
                            {{ alerte.niveau == NiveauAlerte.WARNING ? 'error_outline' : (alerte.niveau == NiveauAlerte.ERROR ? 'dangerous' : '') }}
                        </mat-icon>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <!-- Le titre de l'alerte -->
                            <div class="bold">{{ alerte.titre }}</div>

                            <!-- Le message de l'alerte TEXTE -->
                            <div *ngIf="!alerte.hasHtml">{{ alerte.message }}</div>
                            <!-- Le message de l'alerte HTML -->
                            <div *ngIf="alerte.hasHtml" [innerHtml]="alerte.message"></div>
                            <!-- Le message de l'alerte via un TEMPLATE (permet per exemple de brancher une action sur un bout de texte) -->
                            <ng-container *ngIf="alerte?.template?.tpl" [ngTemplateOutlet]="alerte.template.tpl" [ngTemplateOutletContext]="{ctx: alerte.template.ctx}"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <please-wait *ngIf="!listeAlertes"></please-wait>
</div>
