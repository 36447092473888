import {RefSbt} from "@domain/travel/ref-sbt";

/**
 * Types d'aiguillage
 */
export enum TypeAiguillage {
    ONLINE = 0,
    OFFLINE = 1,
    INTERNE = 2
}

export namespace TypeAiguillage {
    /**
     * Code de traduction d'un type d'aiguillage
     *
     * @param typeAiguillage Type d'aiguillage
     */
    export function traduction(typeAiguillage: TypeAiguillage): string {
        switch (typeAiguillage) {
            case TypeAiguillage.ONLINE:
                return 'travel.typeAiguillage.online';
            case TypeAiguillage.OFFLINE:
                return 'travel.typeAiguillage.offline';
            case TypeAiguillage.INTERNE:
                return 'travel.typeAiguillage.interne';
            default:
                return null;
        }
    }

    /**
     * Méthode qui renvoie la liste de tous les TypeNature
     */
    export function values(): Array<TypeAiguillage> {
        return [
            TypeAiguillage.INTERNE,
            TypeAiguillage.OFFLINE,
            TypeAiguillage.ONLINE,
        ];
    }
}

/**
 * Version littérale des types d'aiguillage
 */
export enum TypeAiguillageLiteral {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    INTERNE = 'INTERNE'
}

/**
 * Extension de la version littérale des types d'aiguillage
 */
export namespace TypeAiguillageLiteral {
    /**
     * Méthode permettant de s'assurer que deux aiguillages sont égaux
     *
     * @param aiguillage        Type d'aiguillage numérique
     * @param aiguillageLiteral Type d'aiguillage littéral
     */
    export function equals(aiguillage: TypeAiguillage, aiguillageLiteral: TypeAiguillageLiteral): boolean {
        //Si les deux aiguillages sont null, ils sont égaux
        if (aiguillage === null && aiguillageLiteral === null) {
            return true;
        }

        //On compare cas par cas
        return aiguillage === TypeAiguillage.ONLINE && aiguillageLiteral === TypeAiguillageLiteral.ONLINE
            || aiguillage === TypeAiguillage.OFFLINE && aiguillageLiteral === TypeAiguillageLiteral.OFFLINE
            || aiguillage === TypeAiguillage.INTERNE && aiguillageLiteral === TypeAiguillageLiteral.INTERNE;
    }

    /**
     * Récupère un TypeAiguillage à partir de son équivalent literal
     *
     * @param aiguillageLiteral Aiguillage recherché (INTERNE par défaut)
     */
    export function getTypeAiguillage(aiguillageLiteral: TypeAiguillageLiteral): TypeAiguillage {
        let aiguillage: TypeAiguillage;

        //On récupère l'aiguillage
        switch (aiguillageLiteral) {
            case TypeAiguillageLiteral.ONLINE:
                aiguillage = TypeAiguillage.ONLINE;
                break;
            case TypeAiguillageLiteral.OFFLINE:
                aiguillage = TypeAiguillage.OFFLINE;
                break;
            case TypeAiguillageLiteral.INTERNE:
            default:
                aiguillage = TypeAiguillage.INTERNE;
                break;
        }

        return aiguillage;
    }
}

/** Énumération des types de nature */
export enum TypeNature {
    AVION_TRAIN = 0,
    HEBERGEMENT = 1,
    VOITURE_DE_LOCATION = 2,
    VOITURE_DE_SOCIETE = 3,
    VOITURE_PERSONNELLE = 4,
    TAXI = 5,
    AUTRE = 6,
    AVION = 7,
    TRAIN = 8,
    DOCUMENT = 9
}

/**
 * On crée un namespace avec le même nom que l'énumération pour lui implémenter des fonctionnalités supplémentaires
 */
export namespace TypeNature {
    /**
     * Méthode qui renvoie le libelle de traduction correspondant au TypeNature en paramètre
     */
    export function traduction(typeNature: TypeNature): string {
        switch (typeNature) {
            case this.AVION_TRAIN:
                return 'od.voyage.travel.avionTrain';
            case this.HEBERGEMENT:
                return 'od.voyage.travel.hebergement';
            case this.VOITURE_DE_LOCATION:
                return 'od.voyage.travel.voitureLoc';
            case this.VOITURE_DE_SOCIETE:
                return 'od.voyage.travel.voitureSoc';
            case this.VOITURE_PERSONNELLE:
                return 'od.voyage.travel.voiturePerso';
            case this.TAXI:
                return 'od.voyage.travel.taxi';
            case this.AUTRE:
                return 'od.voyage.travel.autre';
            case this.AVION:
                return 'od.voyage.travel.avion';
            case this.TRAIN:
                return 'od.voyage.travel.train';
            case this.DOCUMENT:
                return 'od.voyage.travel.document';
        }
    }

    /**
     * Méthode qui renvoie l'icône correspondante au TypeNature en paramètre
     */
    export function icone(typeNature: TypeNature): { cssClass: string, icon?: string } {
        switch (typeNature) {
            case TypeNature.TRAIN:               return { cssClass: "icon-train nio align-middle" };
            case TypeNature.AVION:               return { cssClass: "icon-avion nio align-middle" };
            case TypeNature.AVION_TRAIN:         return { cssClass: "icon-aviontrain nio align-middle" };
            case TypeNature.VOITURE_DE_LOCATION:
            case TypeNature.VOITURE_DE_SOCIETE:
            case TypeNature.VOITURE_PERSONNELLE: return { cssClass: "icon-voiture nio align-middle" };
            case TypeNature.HEBERGEMENT:         return { cssClass: "material-icons-outlined align-middle", icon: "hotel" };
            case TypeNature.DOCUMENT:            return { cssClass: "material-icons-outlined align-middle", icon: "insert_drive_file" };
            default:                             return { cssClass: "material-icons-outlined align-middle", icon: "help_outline" };
        }
    }

    /**
     * Méthode qui renvoie la liste de tous les TypeNature
     */
    export function values(): Array<TypeNature> {
        return [
            TypeNature.AVION_TRAIN,
            TypeNature.HEBERGEMENT,
            TypeNature.VOITURE_DE_LOCATION,
            TypeNature.VOITURE_DE_SOCIETE,
            TypeNature.VOITURE_PERSONNELLE,
            TypeNature.TAXI,
            TypeNature.AUTRE,
            TypeNature.AVION,
            TypeNature.TRAIN,
            TypeNature.DOCUMENT,
        ];
    }
}

/**
 * Représente le type de configuration SBT
 */
export enum TypeConfig {
    OFFLINE,
    ONLINE
}

/**
 * Extension des types de configuration SBT
 */
export namespace TypeConfig {
    export function traduction(typeAiguillage: TypeConfig): string {
        switch (typeAiguillage) {
            case TypeConfig.ONLINE:
                return 'travel.typeAiguillage.online';
            case TypeConfig.OFFLINE:
                return 'travel.typeAiguillage.offline';
        }
    }
}

/**
 * énumération des id des SBT gérés par le TH
 */
export enum NUM_SBT {
    AMI = 1,
    KDS = 2,
    Egencia = 3,
    Traveldoo = 4,
    GetThere = 5,
    NOTS = 6,
    GTMS = 7,
    Clipper = 8,
    ELT = 9,
    EgenciaOff = 10,
    HRS = 11,
    Cytric = 12,
    Maya = 13,
    PortailSncf = 14,
    AmadeusOffline = 15,
    Cds = 16,
    Carbookr = 17,
    KdsNeo = 18,
    TestOnline = 19,
    Egencia2 = 20,
}

/**
 * Liste des SBT disponibles
 */
export const listeSBT: Array<RefSbt> = [
    {
        num_sbt: NUM_SBT.AMI,
        libelle: 'AMI'
    }, {
        num_sbt: NUM_SBT.KDS,
        libelle: 'KDS'
    }, {
        num_sbt: NUM_SBT.Egencia,
        libelle: 'Egencia'
    }, {
        num_sbt: NUM_SBT.Traveldoo,
        libelle: 'Traveldoo'
    }, {
        num_sbt: NUM_SBT.GetThere,
        libelle: 'GetThere'
    }, {
        num_sbt: NUM_SBT.NOTS,
        libelle: 'NOTS'
    }, {
        num_sbt: NUM_SBT.GTMS,
        libelle: 'GTMS'
    }, {
        num_sbt: NUM_SBT.Clipper,
        libelle: 'Clipper'
    }, {
        num_sbt: NUM_SBT.ELT,
        libelle: 'ELT'
    }, {
        num_sbt: NUM_SBT.EgenciaOff,
        libelle: 'EgenciaOff'
    }, {
        num_sbt: NUM_SBT.HRS,
        libelle: 'HRS'
    }, {
        num_sbt: NUM_SBT.Cytric,
        libelle: 'Cytric'
    }, {
        num_sbt: NUM_SBT.Maya,
        libelle: 'Maya'
    }, {
        num_sbt: NUM_SBT.PortailSncf,
        libelle: 'PortailSncf'
    }, {
        num_sbt: NUM_SBT.AmadeusOffline,
        libelle: 'AmadeusOffline'
    }, {
        num_sbt: NUM_SBT.Cds,
        libelle: 'Cds'
    }, {
        num_sbt: NUM_SBT.Carbookr,
        libelle: 'Carbookr'
    }, {
        num_sbt: NUM_SBT.KdsNeo,
        libelle: 'KdsNeo'
    }, {
        num_sbt: NUM_SBT.TestOnline,
        libelle: 'TestOnline'
    }, {
        num_sbt: NUM_SBT.Egencia2,
        libelle: 'Egencia2'
    }
];

/** Énumération des prestations */
export enum Presta {
    AVION_TRAIN = 'AvionTrain',
    AVION = 'Avion',
    TRAIN = 'Train',
    HOTEL = 'Hôtel',
    VOITURE_DE_LOCATION = 'VoitureDeLocation',
    VOITURE_SOCIETE = 'VoitureSociété',
    VOITURE_PERSONNELLE = 'VoiturePersonnelle',
    TAXI = 'Taxi',
    AUTRE = 'Autre',
    DOCUMENT = 'Document',
}

/** Énumération des portées en lien avec le TH */
export enum TypePortee {
    DV = 'DV',
    TR = 'TR',
    TV = 'TV',
}