import { AutocompleteOptions } from "../autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { Filter,TypeComparaison } from "@domain/common/list-view";
import { SearchType } from "@domain/common/list-view/sorting";
import { Role } from "@domain/reporting/role";
import { EntityAttribute } from "@share/component/autocomplete/options/entity-attribute";
import { TypeProfil } from "@domain/user/user";

/**
 * Options de l'autocomplete de recherche d'un rôle à filtrer pour le reporting
 */
export default class ReportRoleOptions implements AutocompleteOptions {
    /** Mode de recherche */
    searchType = SearchType.CONTAINS;

    /**
     * Affichage d'un élément
     */
    displayItem(item: Role): string {
        return item?.libelle;
    }

    /** Ordre de tri des résultats */
    getOrderBy() {
        return "libelle";
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
                //Ajout du filtre sur le libellé
                clef: 'libelle',
                title: translateService.instant('admin.bibliotheque.reporting.infosReporting.filtres.popup.libelle'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }];
    }

    /**
     * Récupération de l'URL
     */
    url(filter: any): string {
        return `/controller/Report/listeFiltreRole/${filter.idReport}`;
    }

    /**
     * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
     */
    listDisplayedAttributes(item: Role,translateService: TranslateService): Array<EntityAttribute> {
        return [{
            //Profil
            clef: 'admin.bibliotheque.reporting.infosReporting.filtres.profil.title',
            valeur: translateService.instant('admin.bibliotheque.reporting.infosReporting.filtres.profil.' + item.fonction)
        }];
    }

    /**
     * Icône
     *
     * @param item le rôle
     */
    getIcon(item: Role): string {
        switch (item.fonction) {
            case TypeProfil.COMPTABLE:
                return '<i class="nio icon-comptable"></i>';
            case TypeProfil.RESPONSABLE:
                return '<i class="nio icon-responsable"></i>';
            case TypeProfil.COLLABORATEUR:
                return '<i class="nio icon-collaborateurs"></i>';
            case TypeProfil.ASSISTANT:
                return '<i class="nio icon-assistant"></i>';
            case TypeProfil.SOUS_ADMINISTRATEUR:
                return '<i class="nio icon-sous_administrateur"></i>';
            case TypeProfil.FOURNISSEUR:
                return '<i class="nio icon-fournisseur"></i>';
        }
    }
}
