/**
 * Enumération des types de facture
 *
 * @author Laurent Convert
 * @date 10/01/2022
 */
export enum OdTypeFacture {
    facture_prev = 'FACTURE_PREV',
    facture = 'FACTURE'
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace OdTypeFacture {

    /**
     * Retourne le code de traduction qui correspondant à un type de facture
     *
     * @param typeFacture Type de facture
     */
    export function traduction(typeFacture: OdTypeFacture) {
        switch (typeFacture) {
            case this.facture_prev: return 'od.frais.factures.liste.types.facture_prev';
            case this.facture:      return 'od.frais.factures.liste.types.facture';
        }
    }

    /**
     * Retourne le code de traduction qui correspondant à un type de facture
     *
     * @param typeFacture Type de facture
     */
    export function traductionForCreation(typeFacture: OdTypeFacture) {
        switch (typeFacture) {
            case this.facture_prev: return 'od.frais.factures.creation.choix.types.facture_prev';
            case this.facture:      return 'od.frais.factures.creation.choix.types.facture';
        }
    }

    /**
     * Retourne l'icône qui correspondant à un type de facture
     *
     * @param typeFacture Type de facture
     */
    export function getIcone(typeFacture: OdTypeFacture) {
        switch (typeFacture) {
            case this.facture_prev: return 'icon-frais_previsionnel_2';
            case this.facture:      return 'icon-note_de_frais';
        }
    }

}