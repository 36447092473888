import {Component,OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {DossierDTO} from "@domain/admin/entreprise/analytique/dossierDto";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage d'un code projet dans la liste des différents codes projets disponibles
 */
@Component({
    host: {'data-test-id': 'analytique-codes-projet-item'},
    selector: 'analytique-codes-projet-item',
    templateUrl: './analytique-codes-projet-item.component.html'
})
export class AnalytiqueCodesProjetItemComponent extends ListViewItem<DossierDTO> implements OnInit {
    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param analytiqueService Service de gestion des informations sur l'analytique
     * @param router Router de l'application
     */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private analytiqueService: AnalytiqueService,
        private router: Router
    ) {
        super();
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Mise à jour de l'information par rapport au fait que le code projet soit actif ou non
        this.data.isActif = !this.data.cache;
    }

    /**
     * Méthode appelée lors de la sélection du code projet
     */
    onSelect() {
        //Navigation vers la page d'informations du code projet
        this.router.navigate(['Admin/Entreprise/Analytique/Projets/' + this.data.idDossier]);
    }
}