import { Component, Input } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DeviseGestionAddPaysComponent } from "@components/admin/bibliotheque/devises/devise-infos/generalites/add-pays/devise-gestion-add-pays.component";
import { TypeCodeErreur } from "@domain/common/http/result";
import { Pays } from "@domain/geographie/pays";
import { Devise } from "@domain/settings/devise";
import { TranslateService } from "@ngx-translate/core";
import { DevisesService } from "@services/admin/devises/devises.service";
import { ToastrService } from "ngx-toastr";

/**
 * Composant d'affichage de la liste des pays de la devise
 */
@Component({
    host: {'data-test-id': 'devise-gestion-pays'},
    selector: 'devise-gestion-pays',
    templateUrl: './devise-gestion-pays.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DeviseGestionPaysComponent {
    /** Liste des pays reliés à la devise */
    @Input() listePays: Pays[];

    /** Liste des pays dont l'ajout de la devise courante comme devise a changé l'ancienne devise du pays en question */
    listePaysErreur: Pays[] = [];

    /** Devise sélectionnée */
    @Input() devise: Devise;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param deviseService Service permettant de gérer les devises
     * @param matDialog Boite de dialogue permettant d'ajouter un pays à la devise
     */
    constructor(
        protected translateService: TranslateService,
        private toastrService: ToastrService,
        private deviseService: DevisesService,
        private matDialog: MatDialog
    ) {
    }

    /**
     * Méthode appelée lors du clic sur le bouton d'ajout d'un pays à la devise
     */
    addNewCountry() {
        //Ouverture de la boîte de dialogue permettant d'ajouter un pays à la devise
        const dialog: MatDialogRef<DeviseGestionAddPaysComponent> = this.matDialog.open(DeviseGestionAddPaysComponent, {
            width: '80%',
        });

        //Lors de la fermeture de la fenêtre, on récupère le pays ajouté
        dialog.afterClosed().subscribe(data => {
            //Si une donnée est renvoyée à la fermeture de la boite de dialogue, on ajoute le nouveau pays dans la liste affichée
            if (data.idPays) {
                //Si la liste des pays n'est pas définie (création d'une devise), alors on l'initialise à un tableau vide
                if(!this.listePays) {
                    this.listePays = [];
                }
                //Si l'id n'est pas déjà présent alors on ajoute le pays à la liste
                if (!this.listePays.some(pays => pays.idPays === data.idPays)) {
                    //On ajoute la devise pour le pays sélectionné, en base de données
                    this.deviseService.addPays(data.idPays, this.devise.code).subscribe(res => {
                        //Si la requête ne renvoie pas d'erreur alors on ajoute le pays à la liste et on affiche un message de succès
                        if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Ajout du nouveau pays à la liste
                            this.listePays.push(data);
                            if (res.data.contientDevise) {
                                this.listePaysErreur.push(data);
                            }
                            //Si le pays sélectionné a bien été ajouté à la devise alors on affiche un message de succès
                            this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        } else {
                            //Sinon on affiche un message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                        }
                    });
                } else {
                    //Si le pays sélectionné est déjà présent dans la liste alors on le notifie à l'utilisateur
                    this.toastrService.error(this.translateService.instant('admin.bibliotheque.devises.infosDevise.gestionAvances.paysDejaPresent'));
                }
            }
        })
    }

    /**
     * Méthode permettant de supprimer un pays dans la liste des pays reliés à la devise
     *
     * @param event Id du pays à supprimer
     */
    deletePaysFromDevise(event) {
        //On parcourt la liste des pays affichés pour pouvoir supprimer le bon pays de la liste
        this.listePays.splice(this.listePays.findIndex(p => p.idPays === event), 1);
    }
}
