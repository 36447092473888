import {ListItem} from "../../common/list-view/list-item";

/**
 * Classe d'un objet à traiter de la popin PeriodeDetail
 */
export class ObjetATraiter implements ListItem {
	piece: string;
	type: TypeObjetATraiter;
	collaborateur: string;
	statut: string;

	getKey(): string {
		return this.piece;
	}
}

/**
 * Type d'objet à traiter
 */
export enum TypeObjetATraiter {
	NDF = "NDF",
	FACTURE = "FACTURE",
	OD = "OD"
}