import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Store} from '@ngrx/store';

import {AppState} from "@domain/appstate";
import {Filter, FilterValue, ListView, TypeComparaison, TypeFilter} from "@domain/common/list-view";
import {VehiculeListItemComponent} from "./vehicule-list-item.component";
import {TypeProfil} from "@domain/user/user";
import {Status, Vehicule} from "@domain/vehicule/vehicule";
import {SettingsVehiculeState} from "@domain/settings/settings";
import * as settingsActions from "@reducers/settings";
import {TypePortee} from "@domain/workflow/workflow";
import {finalize, first} from "rxjs/operators";
import {VehiculeService} from "../vehicule.service";

@Component({
    host: {'data-test-id': 'vehicule-list'},
    templateUrl: './vehicule-list.component.html'
})
/**
 * Composant de la liste des véhicules
 */
export class VehiculeListComponent implements OnInit {
    /** Données */
    liste: ListView<Vehicule, VehiculeListItemComponent>;

    /** Profil de l'utilisateur connecté */
    fonction: TypeProfil;

    /** Paramétrage */
    settings: SettingsVehiculeState;

    constructor(private translateService: TranslateService,private store: Store<AppState>,private vehiculeService: VehiculeService) {
    }

    async ngOnInit() {
        let filter: Filter;

        //Chargement du paramétrage des véhicules
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.VP
        });

        //Sélection du paramètrage
        this.store.select(state => state.settings?.[TypePortee.VP]).pipe(first(settings => !!settings)).subscribe(settings => {
            this.settings = settings;

            this.store.select(state => state.session.user.fonction).pipe(first(fonction => !!fonction)).subscribe(fonction => {
                //Définition du type de profil
                this.fonction = fonction;

                //Définition de la liste
                this.liste = new ListView<Vehicule, VehiculeListItemComponent>({
                    uri: `/controller/Vehicule/search`,
                    title: this.translateService.instant('vehicule.liste.title'),
                    component: VehiculeListItemComponent,
                    isFilter: true,
                    extraOptions: {
                        settings: this.settings
                    },
                    listeFilters: [
                        {
                            title: this.translateService.instant('vehicule.liste.filtre.immatriculation'),
                            clef: 'immatriculation',
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                            isDefault: true
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.marque'),
                            clef: 'marque',
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                            isDefault: true
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.modele'),
                            clef: 'modele',
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                            isDefault: true
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.puissanceFiscale'),
                            clef: 'puissanceFiscale.idPuissance',
                            isDefault: false,
                            listeValues: [],
                            multiple: true,
                            loading: true
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.distance'),
                            clef: 'listeCompteur.compteurReel',
                            type: TypeFilter[TypeFilter.DECIMAL],
                            isDefault: false
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.assurance'),
                            clef: 'isAssurePro',
                            type: TypeFilter[TypeFilter.BOOLEAN],
                            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                            isDefault: false
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.collaborateur'),
                            clef: 'user.matricule,user.nom,user.prenom',
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                            isDefault: true
                        }
                        , {
                            title: this.translateService.instant('vehicule.liste.filtre.collaborateurActif'),
                            clef: 'user.isActif',
                            type: TypeFilter[TypeFilter.BOOLEAN],
                            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                            isDefault: false,
                            onloadValue: 'true'
                        }, {
                            title: this.translateService.instant('vehicule.liste.filtre.actif'),
                            clef: 'isActif',
                            type: TypeFilter[TypeFilter.BOOLEAN],
                            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                            isDefault: false,
                            onloadValue: 'true'
                        }, {
                            title: this.translateService.instant('vehicule.liste.statut'),
                            clef: 'statut',
                            multiple: true,
                            listeValues: Object.keys(Status)
                                .filter(k => isNaN(Number(k)) && k != Status[Status.STATUT_BROUILLON]) //On affiche pas le statut brouillon
                                .map(key => <FilterValue>{code: Status[key].toString(), libelle: this.getStatusLibelle(Status[key])}),
                            isDefault: false,
                            onloadValue: [Status.STATUT_NON_TRAITE.toString()]
                        }
                    ]
                });

                //Persistence des filtres
                this.liste.isPersistFilters = true;

                //Nom de la classe
                this.liste.className = 'VehiculeListComponent';

                //Récupération de la liste des puissances fiscales et mise à jour du filtre en conséquence
                if ((filter = this.liste.listeFilters.find(f => f.clef == 'puissanceFiscale.idPuissance'))) {
                    this.vehiculeService.getListePuissanceVehicule()
                        .pipe(first(),finalize(() => filter.loading = false))
                        .subscribe(liste => {
                            //Conversion de la liste des puissances fiscales en liste de valeurs pour le filtre
                            filter.listeValues = liste.map(pf => <FilterValue>{code: pf.id.toString(10),libelle: pf.libelle});
                        });
                }

            });
        });
    }

    /**
     * Permet de récupérer la traduction du statut véhicule
     * @param status Status
     */
    getStatusLibelle(status: Status) {
        switch (status) {
            case Status.STATUT_NON_TRAITE:
                return this.translateService.instant('vehicule.statut.nonTraite');
            case Status.STATUT_VALIDE:
                return this.translateService.instant('vehicule.statut.valide');
            case Status.STATUT_REFUSE:
                return this.translateService.instant('vehicule.statut.refuse');
            case Status.STATUT_BROUILLON:
                return this.translateService.instant('vehicule.statut.brouillon');
            default:
                return "";
        }
    }
}
