import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../../../autocomplete-line-item.component";
import {UserDto} from "@domain/user/user-dto";

/**
 * Composant d'affichage d'une ligne de l'autocomplete des collaborateurs dans le cas de la correction des rejets de facture
 *
 * @author Laurent Convert
 * @date 11/07/2023
 */
@Component({
	host: {'data-test-id': 'import-facture-collab-line-item'},
	templateUrl: './import-facture-collab.line-item.html'
})
export class ImportFactureCollabLineItem extends AutocompleteLineItemComponent<UserDto> {

}