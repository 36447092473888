import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {finalize, first} from "rxjs/operators";
import { TypeCodeErreur } from '@domain/common/http/result';

/**
 * Popin des jours fériés pour Comptabilité / Plan Comptable - Paramètres
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'dates-fixes-popin'},
    templateUrl: './dates-fixes-popin.component.html'
})
export class DatesFixesPopinComponent {
    /** Jour férié à ajouter */
    newJourFerie: string;

    /** Indicateur de chargement */
    isPending: boolean = false;

    /**
     * Constructeur
     *
     * @param matDialogRef Référence de la boîte de dialogue
     * @param data Données injectées
     * @param translateService Service des traductions
     * @param toastrService Service des toasts
     * @param planComptableService Service du plan comptable
     */
    constructor(private matDialogRef: MatDialogRef<DatesFixesPopinComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { listeJoursFeries: string[], congesFeries: string },
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private planComptableService: ComptabilitePlanComptableService) {
    }

    /**
     * Ajout d'un jour férié
     */
    addJour() {
        let jourStr: string;

        if (this.newJourFerie) {
            // Vérification de la valeur
            if (isNaN(Date.parse(new Date().getFullYear() + '/' + this.newJourFerie))) {
                //Message d'erreur
                this.toastrService.error(this.translateService.instant('admin.comptabilite.planComptable.parametres.popinDatesFixes.dateIncorrecte'))
            } else {
                //Formatage au format attendu
                jourStr = this.newJourFerie.replace('/','');

                //Vérification de l'existence de ce jour férié
                if (!this.data.listeJoursFeries.includes(jourStr)) {
                    //Ajout de la date à la liste des jours fériés
                    this.data.listeJoursFeries.push(jourStr);
                } else {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('admin.comptabilite.planComptable.parametres.popinDatesFixes.jourDejaAjoute'))
                }
            }

            //Clear la valeur de l'input
            this.newJourFerie = '';
        }
    }

    /**
     * Suppression d'un jour férié
     *
     * @param jour Jour à supprimer
     */
    deleteJour(jour: string) {
        //Suppression du jour
        this.data.listeJoursFeries = this.data.listeJoursFeries.filter((jourListe: string) => jourListe != jour);
    }

    /**
     * Ajoute les jours fériés à la string congesFeries
     */
    buildCongesFeriesToString() {
        //Séparation entre les congés et les jours fériés
        this.data.congesFeries = this.data.congesFeries + ',';

        //Parcours de la liste des jours fériés
        this.data.listeJoursFeries.forEach(date => {
            //Ajout de chaque jour férié
            this.data.congesFeries = this.data.congesFeries + ' ' + date;
        });
    }

    /**
     * Enregistrement des jours fériés
     */
    saveJoursFeries() {
        //Début de l'enregistrement
        this.isPending = true;

        //Construction de la liste
        this.buildCongesFeriesToString();

        //Enregistrement des nouveaux jours fériés
        this.planComptableService.saveCongesFeries(this.data.congesFeries)
            .pipe(first(), finalize(() => this.isPending = false))
            .subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de la popin
                    this.matDialogRef.close({
                        //Nouvelle valeur des jours fériés
                        listeJoursFeries: this.data.listeJoursFeries
                    });

                } else if (result.codeErreur === TypeCodeErreur.ERROR_SAVE) {
                    //Toast d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
                }
            });
    }

    /**
     * Fermeture de la popin
     */
    close() {
        this.matDialogRef.close();
    }

}
