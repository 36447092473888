<h1 mat-dialog-title>
    <span translate>facture.factureRef.liste.title</span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <list-view [liste]="liste" *ngIf="liste"></list-view>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]="null"><span [translate]="'global.actions.annuler'"></span></button>
    <button mat-flat-button color="primary" (click)="validerSelection();"><span [translate]="'global.actions.valider'"></span></button>
</div>
