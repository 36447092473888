import {Component,Directive,ElementRef,EventEmitter,Input,OnChanges,OnDestroy,OnInit,Output,SimpleChanges,TemplateRef,ViewChild,ViewContainerRef} from '@angular/core';
import {AbstractControl,NG_VALIDATORS,NgModel,ValidationErrors,Validator} from '@angular/forms';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatOption} from "@angular/material/core";
import {FloatLabelType} from "@angular/material/form-field";
import {MatSelect,MatSelectChange} from "@angular/material/select";
import {ZUReferentiel} from "@domain/zone-utilisateur/zuReferentiel";
import * as moment from "moment";
import {Observable,Subscription} from "rxjs";
import {Devise} from "@domain/settings/devise";
import {AutocompleteType} from "@share/component/autocomplete/options/autocomplete-type";
import {RadioOption} from '@share/component/custom-input/radio-option';
import {IbanDirective,IbanPipe} from "ngx-iban";
import {TooltipContent,TooltipOptions} from "@share/directive/tooltip/tooltip.directive";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {Unite} from "@domain/prestation/unite";

@Component({
    selector: 'custom-input',
    templateUrl: './custom-input.component.html',
    styleUrls: ['custom-input.component.scss']
})
export class CustomInputComponent implements OnInit,OnDestroy,OnChanges {
    /** Type d'input */
    @Input() customType?: 'autocomplete' | 'input' | 'iban-obfuscated' | 'input-obfuscated' | 'date' | 'select' | 'montant' | 'textarea' | 'heure' | 'multiselect' | 'objectselect' | 'checkbox' | 'daterange' | 'number' | 'radio' | 'distance' = 'input';

    /** Taille de la colonne de gauche (bootstrap) */
    @Input() lCol?: number = 2;

    /** Taille de la colonne de droite (bootstrap) */
    @Input() rCol?: number = 4;

    /** Classe CSS custom pour la colonne de gauche */
    @Input() lClass?: string;

    /** Classe CSS custom pour la colonne de droite */
    @Input() rClass?: string;

    /** Libelle */
    @Input() libelle: string;

    /** Lecture seule */
    @Input() readonly?: boolean = false;

    /** Possibilite de voir la valeur non obfusquee */
    @Input() canReadClear: boolean = false;

    /** Modification non autorisée */
    @Input() disabled?: boolean = false;

    /** Champ obligatoire */
    @Input() required?: boolean = false;

    /** Type de l'autocomplete */
    @Input() autocompleteType?: AutocompleteType;

    /** Filtres de l'autocomplete */
    @Input() autocompleteFilter?: any;

    /** Liste des options du select */
    @Input() selectOptions?: any[];

    /** Propriété d'identification de l'objet listé sélectionné */
    @Input() optionValue?: string;

    /** Nom de la propriété qui contient l'icône à afficher pour chaque option. Facultatif. */
    @Input() optionIcone?: string;

    /** Placeholder de la taille d'un icone pour conserver l'alignement dans le select */
    optionIconePlaceholder: string = '<i class="material-icons-outlined invisible">warning</i>';

    /** Propriété de l'objet listé sélectionné à afficher */
    @Input() optionDisplay?: string;

    /** Devise du montant */
    @Input() montantDevise?: string;

    /** Liste des devises du montant */
    @Input() listeDevise?: Array<Devise>;

    /** Nombre de lignes d'un textarea */
    @Input() areaRows?: number;

    /** Placeholder a afficher dans l'input */
    @Input() placeholder?: string;

    /** Label à afficher au dessus de l'autocomplete */
    @Input() autocompleteLabel?: string;

    /** Permet d'afficher du contenu HTML après le label de l'autocomplete */
    @Input() matLabelHtml?: TemplateRef<any>;

    /** Affichage d'une infobulle à droite du label flottant */
    @Input() labelTooltip?: PostTooltipNio;

    /** Indique si le selection doit comprendre un input de saisie libre */
    @Input() isSelectAvecInput?: boolean = false;

    /** Indique si la sélection doit comprendre un input de saisie vide */
    @Input() isSelectAvecVide?: boolean = false;

    /** Indique si le textarea a une taille limitée */
    maxlengthDefault: number = 524288;
    @Input() maxlength: number = this.maxlengthDefault;

    /** Objet pour initialiser l'input du select ou du multiselect */
    @Input() selectInput?: ZUReferentiel;

    /** On a un Input spécifique pour les dates et checkbox pck ça ne marche pas avec notre gestion du ngModel */
    @Input() customModel?: any;

    /** Code de traduction du libellé à afficher après la checkbox */
    @Input() postLibelle?: string;

    /** String déjà traduite à afficher dans un tooltip d’information après le postLibelle s’il y en a un ou après la case en cas contraire. Egalement utilisé pour les champs de type input ou input-obfuscated */
    @Input() postTooltip?: string;

    /** Option pour les montants : est-ce que renseigner 0 constitue une erreur ou non. Oui par défaut. */
    @Input() allowZero?: boolean = false;

    /**
     * Tooltip custom positionné sur une icône à droite du champ.
     * Actuellement implémenté pour le(s) type(s) :<br/>
     * <ul>
     *   <li>objectselect</li>
     *   <li>input</li>
     * <ul>
     *
     * @property content Contenu du tooltip
     * @property options Options du tooltip (optionnel)
     * @property icon Icône Material sur laquelle sera appliquée le tooltip (optionnel)
     * @property onClick Action déclenchée lors du clic sur l'icône (optionnel)
     */
    @Input() postTooltipNio: PostTooltipNio;

    /**
     * Tooltip custom positionné sur le champ lui-même.
     * Actuellement implémenté pour le(s) type(s) :<br/>
     * <ul>
     *   <li>input</li>
     *   <li>textarea</li>
     * <ul>
     *
     * @property content Contenu du tooltip
     * @property options Options du tooltip (optionnel)
     * @property icon Icône Material sur laquelle sera appliquée le tooltip (optionnel)
     * @property onClick Action déclenchée lors du clic sur l'icône (optionnel)
     */
    @Input() fieldTooltipNio: PostTooltipNio;

    /** Affichage d'un message d'erreur */
    @Input() errorMessage: string;

    /** Type du float label à afficher (never par défaut) */
    @Input() floatLabel?: FloatLabelType = 'never';

    /** Date minimale pour la sélection d'une date dans le datepicker */
    @Input() dateMin?: Date;

    /** Date maximale pour la sélection d'une date dans le datepicker */
    @Input() dateMax?: Date;

    /** Date d'ouverture du datepicker */
    @Input() dateStart?: Date | moment.Moment = null;

    /** Date de début du range de comparaison pour le rangeDate */
    @Input() rangeDeb?: Date | moment.Moment = null;

    /** Date de fin du range de comparaison pour le rangeDate */
    @Input() rangeEnd?: Date | moment.Moment = null;

    /** Valeur minimale pour un input de type number ou montant */
    @Input() min?: number = null;

    /** Valeur maximale pour un input de type number ou montant */
    @Input() max?: number = null;

    /** Nombre de décimales pour un input de type number */
    @Input() nbDecimales?: number = null;

    /** Liste des unités pour un input de type distance */
    @Input() listeUnites?: Array<Unite>;

    /** Unité sélectionnée pour un input de type distance */
    @Input() selectedUnite?: Unite;

    /** Indique si les labels transmis sont déjà traduits ou non (<b>TRUE</b> par défaut) */
    @Input() isTranslated?: boolean = true;

    /** Indique si les labels transmis seront affichés sur deux lignes : il faut remonter légèrement l'étoile marquant le caractère requis au besoin */
    @Input() twoLineLabel?: boolean = false;

    /**
     * Définit le nom de l'id si différent de id
     * Utilisé pour l'autocomplete dans le contrôle des erreurs pour identifier l'id utilisé
     */
    @Input() idName = "id";

    /** Contenu du préfixe explicatif dans le cas où le radio-button en nécessite un */
    @Input() radioPrefix: string;

    /** Options disponibles en cas de radio-button */
    @Input() radioOptions: RadioOption[] = null;

    /** Nom à donner à l'input, nécessaire notamment pour les radio-buttons sinon des valeurs peuvent ne pas être bien mappées */
    @Input() name: string;

    /** Forcer l'utilisation de la couleur de base même en disable */
    @Input() isDisableUsePrimaryColor: boolean = false;

    /** Texte à afficher dans un autocomplete si la valeur de celui-ci n'est pas renseignée */
    @Input() displayIfNull: string = "";

    /** Output du modèle spécifique aux dates et checkbox */
    @Output() customModelChange = new EventEmitter<any>();

    /** Évènement émis lors de la modification du modèle associé */
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    /** Evenement emis lors de la de-obfuscation temporaire du champs */
    @Output() onReadClearRequest: EventEmitter<void> = new EventEmitter<void>();

    /** Évènement émis lors de la modification de la devise associée à un montant */
    @Output() onDeviseChange: EventEmitter<string> = new EventEmitter<string>();

    /** Évènement émis lors de la modification de l'unité associée à une distance */
    @Output() onUniteChange: EventEmitter<Unite> = new EventEmitter<Unite>();

    /** Flag utilisé pour les inputs obfusqués, pour indiquer que la valeur a été modifiée par l'utilisateur */
    isModelChanged: boolean;

    /** Sauvegarde du modèle d'origine (donc obfusqué) pour les inputs obfusqués */
    modelBackup: any;

    /** Évènement émis lors du focus */
    @Output() onFocus: EventEmitter<void> = new EventEmitter<void>();

    /** Évènement émis lors de la perte de focus */
    @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();

    /** Objet tampon pour le select avec input. Il contient un libelle et un ID, le nom de la propriété d'id dépend de optionValue et est initialisée dans le onInit */
    objetTampon: any;

    /** Input pour le multiselect */
    @ViewChild('inputTampon')
    inputTampon: ElementRef;

    /** Option avec input pour le multiselect */
    @ViewChild('matOption')
    matOption: MatOption;

    /** Option avec input pour l'autocomplete */
    @ViewChild('autocomplete')
    autocomplete: NgModel;

    /** Option avec input pour l'autocomplete */
    @ViewChild('objectselect')
    objectselect: MatSelect;

    /** Option avec input pour l'autocomplete */
    @ViewChild('inputObfuscated')
    inputObfuscated: NgModel;

    /** Valeur de l'ID du tampon */
    @Input() readonly valeurIdTampon: string | number = 'objetTampon';

    /** ID de l'objet tampon */
    idTampon: any;

    /** Observable permettant de mettre à jour la valeur de l'autocomplete de façon externe */
    @Input() autoCompleteValue$?: Observable<any>;

    /** Observable qui permet de reset la liste des items quand un event est envoyé */
    @Input() reinitListeObs: Observable<void>;

    /** Enregistrement de la souscription pour unsub à la fin du composant */
    private souscription: Subscription;

    /** Erreur material a afficher */
    _matError?: { error: string, css?: string };
    @Input("matError")
    set matError(matError: string | { error: string, css?: string }) {
        if (typeof matError === 'string') {
            this._matError = { error: matError };
        } else {
            this._matError = matError;
        }
    }
    get matError(): string | { error: string, css?: string } {
        return this._matError;
    }

    /** Hint material a afficher */
    @Input() matHint?: string;

    /** MatSuffix utilisé dans les inputs */
    @Input() suffix?: string | TemplateRef<any>;

    /** Icone de suffixe */
    @Input() suffixIcon: string;

    /** Couleur de l'icone de suffixe */
    @Input() suffixIconClass: string;

    /** Getter permettant de déterminer si le suffix affiché à droite de l'input est un template, auquel cas il sera affiché grâce à ng-container */
    get isSuffixTemplate(): boolean {
        return this.suffix instanceof TemplateRef;
    }

    /** Dans le cas d'un champ obfusqué, indique si le champ est affiché en clair */
    isDisplayClear: boolean = false;

    /** Option du select (simple) sélectionnée */
    optionSelected: any = undefined;

    /**
     * Constructeur
     */
    constructor(private el: ElementRef, public ngModel?: NgModel, private ibanPipe?: IbanPipe) {
        this.model = this.ngModel.model;
    }

    /** Récupération du modèle */
    get model(): any {
        return this.ngModel.model;
    }

    /** Mise à jour du modèle */
    set model(value: any) {
        this.ngModel.reset(value);

        //Dans le cas d'un champ obfusqué
        if ((this.customType === 'input-obfuscated' || this.customType === 'iban-obfuscated') && !this.modelBackup) {
            //Backup de la valeur obfusquée
            this.initModelBackup();
        }
    }

    /**
     * Popup de recherche de l'autocomplete : Pas de recherche tant qu'un filtre n'a pas été renseigné sauf pour les ZONES et TERRITOIRES (= REGION) de l'autocomplete Géographie
     *
     * @return false si l'on souhaite obliger la saisie pour rechercher, true sinon
     */
    get isSearchWhenNoFilter(): boolean {
        return this.autocompleteType !== "geographie"
            || this.autocompleteFilter?.listeTypes?.includes(TypeGeographie.ZONE)
            || this.autocompleteFilter?.listeTypes?.includes(TypeGeographie.REGION);
    }

    /**
     * Initialisation de la page
     */
    ngOnInit(): void {
        this.idTampon = this.valeurIdTampon;

        //Vérification du type
        if (!this.customType) {
            //Déclenchement d'une exception
            throw `${this.name} : Le type doit être renseigné`;
        }

        //Vérification checkbox
        if (this.customType == 'checkbox' && (this.customModel === undefined || this.ngModel === undefined)) {
            //Déclenchement d'une exception
            throw `${this.name} : Les paramètres customModel ET ngModel sont à renseigner`;
        }

        //Vérification autocomplete
        if (this.customType == 'autocomplete' && (!this.optionDisplay || !this.autocompleteType)) {
            //Déclenchement d'une exception
            throw `${this.name} : Les paramètres autocomplete sont à renseigner`;
        }

        //Vérification du select
        if (this.customType == 'select' && !this.selectOptions) {
            //Déclenchement d'une exception
            throw `${this.name} : Les paramètres select sont à renseigner`;
        }

        //Vérification du daterange
        if (this.customType == 'daterange' && this.customModel && !(this.customModel instanceof moment)) {
            //Déclenchement d'une exception
            throw `${this.name} : La date doit être de type Moment`;
        }

        //Vérification de l'objectselect
        if (this.customType == 'objectselect') {
            if((!this.optionDisplay || !this.optionValue || !this.selectOptions)) {
                //Déclenchement d'une exception
                throw `${this.name} : Les paramètres objectselect sont à renseigner`;
            }

            this.alterObjectSelectOptionsForComparaison();
        }

        //Vérification et initialisation du multiselect
        if (this.customType == 'multiselect') {
            if (!this.optionDisplay || !this.optionValue || !this.selectOptions) {
                //Déclenchement d'une exception
                throw `${this.name} : Les paramètres du multiselect sont à renseigner`;
            }

            //On ajoute la propriété optionValue à chaque option
            //C'est pour pouvoir utiliser cette propriété dans le compareSelect
            //C'est une méthode qui est utilisée dans le contexte du composant du mat-select
            // et on ne peut donc pas faire opt[this.optionValue]
            //car this.optionValue n'existe pas dans le contexte du composant du mat-select
            this.selectOptions.forEach(opt => {
                opt.optionValue = opt[this.optionValue];
            });

            //Même combine pour les valeurs dans le model
            if (this.model) {
                this.model.forEach(opt => {
                    opt.optionValue = opt[this.optionValue] ? opt[this.optionValue] : '';
                })
            }
        }

        //Select simple
        if (this.customType == 'select') {
            //Initialisation de la valeur initialement sélectionnée
            this.updateOptionSelected();

            //Souscription à l'évent de mise à jour
            this.souscription = this.ngModel.valueChanges.subscribe(value => {
                //Mise à jour de la variable qui stocke l'option sélectionnée
                this.updateOptionSelected();
            });
        }

        //Initialisation du select et multiselect avec input
        if (this.isSelectAvecInput) {
            //On récupère le modèle de l'input dans l'attribut dédié
            let mod = this.selectInput;

            //S'il est renseigné
            if (mod) {
                //Si le tampon a un attribut d'id
                if (mod[this.optionValue]) {
                    //On le récupère
                    this.idTampon = mod[this.optionValue];
                } else {
                    //S'il n'y a pas d'id

                    //Si on est en multiSelect
                    if (this.customType === 'multiselect' && this.model) {
                        //On regarde si le model contient l'input
                        let find = this.model.find(opt => opt[this.optionDisplay] === mod[this.optionDisplay]);

                        //Si le modèle contient bien l'input
                        if (find) {
                            //On lui ajoute un ID
                            find[this.optionValue] = this.idTampon;
                            //On lui ajoute aussi la propriété pour le compareWith
                            find.optionValue = this.idTampon;
                        }
                    }
                    //On ajoute l'id standard à l'input
                    mod[this.optionValue] = this.idTampon;
                }
            } else if (this.model) {
                //S'il n'est pas renseigné, on va le chercher dans le ngModel
                //Si on est dans un select et que le modèle est l'input
                if (this.customType === 'objectselect' && this.model[this.optionValue] === this.idTampon) {
                    //On récupère le modèle
                    mod = this.model;
                } else if (this.customType === 'multiselect') {
                    //Dans le cas d'un multiselect on récupère aussi si le modèle contient l'input
                    mod = this.model.find(opt => opt[this.optionValue] === this.idTampon);
                }
            }

            //Si le modèle contenait l'input
            if (mod) {
                //On initialise l'objetTampon avec l'input
                this.objetTampon = mod;
            } else {
                //Sinon on initialise l'objet tampon
                this.objetTampon = {};
                this.objetTampon[this.optionValue] = this.idTampon;
                this.objetTampon[this.optionDisplay] = '';
            }

            //On recommence la combine décrite dans l'initialisation du multiselect
            if (this.customType === 'multiselect') {
                //On ajoute la propriété optionValue
                this.objetTampon.optionValue = this.idTampon;
            }
        }

        //On n'initialise un maxlength par défaut différent selon le type de custominput
        if(this.maxlength == this.maxlengthDefault) {
            if (this.customType === 'textarea') {
                this.maxlength = 2000;
            } else if (this.customType === 'objectselect' || this.customType === 'multiselect')  {
                this.maxlength = 100;
            } else if (this.customType === 'input') {
                this.maxlength = null;
            }
        }

        //Dans le cas d'un autocomplete
        if (this.customType === 'autocomplete') {
            setTimeout(() => {
                //Mise à jour de la valeur du control de l'autocomplete avec la valeur (model)
                this.autocomplete?.control?.setValue(this.model);
                //Rafraichissement de la validité
                this.ngModel.control?.updateValueAndValidity();
            });

            //Si un observable d'autocomplete est fourni
            if (!!this.autoCompleteValue$) {
                //On sub pour mettre à jour l'autocomplete de façon externe
                this.souscription = this.autoCompleteValue$?.subscribe(value => {
                    setTimeout(() => {
                        //Mise à jour de la valeur du control de l'autocomplete avec la valeur (model)
                        this.autocomplete.control?.setValue(value);
                        //Rafraichissement de la validité
                        this.ngModel.control?.updateValueAndValidity();
                    });
                });
            }
        }

        //Dans le cas d'un IBAN obfusqué
        if (this.customType === 'iban-obfuscated' && this.maxlength) {
            //Taille max du champ iban
            this.maxlength = 34;
        }
    }

    /**
     * Handler des modifications des données en entrée du composant
     * @param changes Liste des changements
     */
    ngOnChanges(changes: SimpleChanges) {
        //Vérification d'un changement ultérieur à celui de l'initialisation (firstChange)
        if (this.customType === 'objectselect' && !changes.firstChange) {
            this.alterObjectSelectOptionsForComparaison();
        }
    }

    /** Suppression du composant */
    ngOnDestroy(): void {
        //On n'oublie pas de unsub
        this.souscription?.unsubscribe();
    }

    /**
     * Modification des entrées du select de type "ObjectSelect" pour la méthode de comparaison.
     * par l'ajout de la propriété optionValue à chaque option
     */
    alterObjectSelectOptionsForComparaison(): void {
        //on ajoute la propriété optionValue à chaque option
        this.selectOptions.forEach(opt => {
            opt.optionValue = opt[this.optionValue];
        });

        //on ajoute la propriété optionValue au model
        if (this.model) {
            this.model.optionValue = this.model[this.optionValue] ? this.model[this.optionValue] : '';
        }
    }

    /** Valeur d'affichage pour la lecture seule du select */
    get selectReadonlyValue(): string | number {
        let object: any;
        if (this.ngModel.model != null) {
            if (this.customType == 'select') {
                object = this.selectOptions?.find(o => o[this.optionValue] == this.ngModel.model);
            } else if (this.customType == 'objectselect' && this.objetTampon?.libelle) {
                //Dans le cas d'un select avec saisie manuelle il faut aller chercher la valeur dans l'objet tampon
                object = this.objetTampon;
            } else {
                object = this.selectOptions?.find(o => o[this.optionValue] == this.ngModel.model[this.optionValue]);
            }
        }

        return object ? object[this.optionDisplay] : null;
    }

    /**
     * Méthode appelée lors d'un changement de sélection dans un select
     */
    selectChange($event: MatSelectChange) {
        //Si on est sur un select avec Input
        if (this.isSelectAvecInput) {
            //Si on est sur un select simple et qu'on vient de sélectionner autre chose que l'input
            if (this.customType == 'objectselect' && (!$event.value || $event.value[this.optionValue] != this.idTampon)) {
                //On réinitialise la valeur tapée dans l'input
                this.objetTampon[this.optionDisplay] = '';
            } else if (this.customType == 'multiselect') {
                //Si on est en multiselect et que l'option avec input n'est pas sélectionnée
                if (!$event.value.find(t => t[this.optionValue] == this.idTampon)) {
                    //On vide sa valeur
                    this.objetTampon[this.optionDisplay] = '';
                }
            }
        } else if (this.customType == 'select') {
            //Mise à jour de l'option sélectionnée
            this.updateOptionSelected($event.value);
        }
    }

    /**
     * Mise à jour de la variable qui stocke l'option sélectionnée
     *
     * @param value Valeur à définir. Si ignoré, la valeur du model sera utilisée.
     */
    updateOptionSelected(value?: any) {
        //Option sélectionnée ou à défaut la courante
        value = value ?? this.model;

        //Activation de l'option à afficher
        this.optionSelected = this.optionValue ? this.selectOptions?.find(o => o[this.optionValue] == value) : value;
    }

    /**
     *  Méthode qui évite la propagation d'un appui sur une touche ou d'un clic, sauf entrer
     *  On fait ça pck sinon un appui sur espace valide (comme entrer)
     *  et un appui sur une lettre lance la recherche dans le select.
     *
     *  Cette méthode permet aussi dans le cas du multiselect de s'assurer
     *  que l'option est sélectionnée quand on tape dans l'input
     */
    stopFauxInput($event: KeyboardEvent | MouseEvent) {
        if (this.customType === 'multiselect') {
            this.matOption.select();
            $event.stopPropagation();
        } else if ('code' in $event && $event.code.toLowerCase() !== 'enter') {
            $event.stopPropagation();
        }
    }

    /**
     * Méthode de comparaison des objets pour le select et multiselect
     *
     * @param opt1 Premier objet à comparer
     * @param opt2 Second objet à comparer
     */
    compareSelect = (opt1, opt2): boolean => {
        return opt1 && opt2 ? opt1.optionValue === opt2.optionValue || opt1[this.optionValue] === opt2[this.optionValue] : opt1 === opt2;
    }

    /** Trigger appelé lors d'un click sur l'option avec input du multiselect */
    changeFocus() {
        //On s'assure que le curseur soit sur l'input
        this.inputTampon.nativeElement.focus();
    }

    /** Trigger appelé lors d'un click sur l'input du multiselect avec input */
    preventDeselect($event: MouseEvent) {
        //Si l'option avec input est déjà sélectionnée
        if (this.matOption.selected) {
            //On empêche la propagation de l'event pour pas qu'il soit désélectionné
            $event.stopPropagation();
        }
    }

    /** Trigger appelé à la fermeture du multiselect */
    removeInputVide() {
        //Si l'input est vide
        if (this.objetTampon && this.objetTampon[this.optionDisplay] == '') {
            //On le désélectionne
            this.matOption.deselect();
        }
    }

    /** Emet la nouvelle valeur de la checkbox */
    changeCheck($event: MatCheckboxChange) {
        this.customModelChange.emit($event.checked);
    }

    /** Emet la nouvelle valeur du model pour la date */
    dateChangeCheck($event) {
        this.customModelChange.emit($event.value);
    }

    /** Indique s'il y a une erreur dans le formulaire */
    hasError(): boolean {
        return this.errorMessage || this.hasCheckboxError() || this.hasMontantError() || this.hasAutoCompleteError()
            || this.hasNumberError() || this.ngModel?.errors?.required  || this.ngModel?.invalid;
    }

    /** Indique si on utilise une checkbox et qu'il y a une erreur */
    hasCheckboxError(): boolean {
        return this.customType == 'checkbox' && this.required && this.customModel == false;
    }

    /** Indique si on utilise un input montant et qu'il y a une erreur */
    hasMontantError(): boolean {
        return this.customType == 'montant' && this.required && ((this.allowZero == false && this.model == 0) || this.model != null &&
            (this.min != null && this.model < Number(this.min) //Et que le min n'est pas respecté
                || this.max != null && this.model > Number(this.max) //Ou que le max n'est pas respecté
            )
        );
    }

    /** Indique si on utilise un input number et qu'il y a une erreur */
    hasNumberError(): boolean {
        //Contrôle des number
        return this.customType === 'number' &&
            (this.required && this.model == null //Si la donnée est requise, mais absente
                //Si on a une donnée
                || this.model != null &&
                (this.min != null && this.model <  Number(this.min) //Et que le min n'est pas respecté
                    || this.max != null && this.model > Number(this.max) //Ou que le max n'est pas respecté
                )
            );
    }

    /** Indique si on utilise un autocomplete et s'il y a une erreur */
    hasAutoCompleteError(): boolean {
        return this.customType == 'autocomplete' && this.required
            //Si l'autocomplete est disabled ou readonly, le model correspond à la propriété indiquée via "optionDisplay" donc dans ce cas pas d'objet (et donc pas d'id)
            && (this.disabled || this.readonly ? this.model == null : (this.model == null || this.model[this.idName] == null));
    }

    /** Trigger appelé à la fermeture du select qui permet de supprimer un input autre selectionné vide */
    removeSelectPlaceHolder() {
        if (this.model && this.model[this.optionDisplay] == '') {
            this.ngModel.reset(undefined);
        }
    }

    /** Listener utilisé pour les champs obfusqués pour détecter si la valeur a été modifiée par l'utilisateur */
    onModelChanged() {
        this.isModelChanged = true;
        this.onChange.emit();
    }

    /** Listener utilisé pour les champs obfusqués pour ràz la valeur si elle n'a pas déjà été modifiée par l'utilisateur */
    onModelFocused() {
        if (!this.isModelChanged) {
            this.model = "";
        }
    }

    /** Listener utilisé pour les champs obfusqués pour restaurer la valeur initiale obfusquée si la valeur n'a pas été modifiée par l'utilisateur */
    onModelBlured() {
        if (!this.isModelChanged) {
            this.model = this.modelBackup;
        }
    }

    /** Vide entièrement le model et son backup : utilisé pour les champs obfusqués */
    razModel() {
        this.model = '';
        this.modelBackup = ''
        this.onModelChanged();
    }

    /** Récupération de la date minimale */
    getdateMin(): Date {
        return this.dateMin;
    }

    /** Récupération de la date maximale */
    getdateMax(): Date {
        return this.dateMax;
    }

    /** Réinitialisation du backup du model : utilisé pour les champs obfusqués */
    initModelBackup() {
        this.modelBackup = this.model;
        this.isModelChanged = false;
    }

    /** Utilise pour les champs obfusques : affiche / cache la valeur en clair  */
    changeVisibilite() {
        this.isDisplayClear = !this.isDisplayClear;

        if (this.isDisplayClear) {
            this.onReadClearRequest.emit();
        } else {
            this.ngModel.reset(this.modelBackup);
        }
    }

    /** Change la visibilité du champ obfusqué et reinitialise le model */
    switchModelObfusque(model: string, affichageClair: boolean) {
        this.isDisplayClear = affichageClair;
        this.ngModel.reset(model);
    }

    /**
     * IBAN en cours de modification par l'utilisateur
     * @param iban IBAN
     */
    onIbanChanged(iban: string) {
        this.formatIban(iban);
        this.onModelChanged();
    }

    /**
     * Formatage de l'IBAN en appelant explicitement la directive de formatage.
     * On utilise cette méthode plutôt que de mettre le pipe dans le template car ce dernier est mutualisé pour le type générique input-obfusqué
     * @param iban IBAN
     */
    formatIban(iban: string) {
        this.model = this.ibanPipe.transform(iban,'-');
    }
}

export type PostTooltipNio = {
    content: TooltipContent,
    options?: TooltipOptions,
    icon?: string,
    tooltipClass?: 'warning' | 'error' | 'success',
    onClick?: () => void,
}

/**
 * Validateur des custom-input.
 *
 * @author Laurent Convert
 * @date 21/04/2022
 */
@Directive({
    selector: "<custom-input>",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: CustomInputValidatorDirective,
        multi: true
    }]
})
export class CustomInputValidatorDirective implements Validator {
    /** Composant Custom Input associé */
    private customInput: CustomInputComponent;

    /** Directive de validation des IBAN */
    private ibanDirective: IbanDirective;

    /**
     * Constructeur
     */
    constructor(private view: ViewContainerRef) {
    }

    /**
     * Récupération du composant associé au control
     */
    ngAfterViewInit(): void {
        this.customInput = this.view.injector.get(CustomInputComponent, null);
    }

    /**
     * Fonction de validation
     *
     * @param control Le control associé à l'élément du formulaire
     */
    validate(control: AbstractControl) : ValidationErrors | null {
        //Vérification du type autocomplete pour éviter les effets de bord sur les autres types
        if (this.customInput && this.customInput.customType === 'autocomplete' && !this.customInput.disabled && !this.customInput.readonly
                //Si le model a été renseigné, mais que l'input n'a pas été modifié, on ne fait pas le contrôle car le ngmodel n'est mis à partir du modèle uniquement lors de l'intervention de l'user
                //En résumé on ne renvoie pas d'erreur si la donnée vient du back, c'est pas beau mais ça marche
                && (this.customInput.autocomplete.model != null && control.dirty)) {
                    //On force la mise à jour de la validité de l'autocomplete
            this.customInput.autocomplete?.control?.updateValueAndValidity({emitEvent: false, onlySelf: true});

            //On retourne les éventuelles erreurs
            return this.customInput.autocomplete?.errors;
        } else if (this.customInput && this.customInput.customType === 'number') {
            //Vérification de l'invalidité du control
            if (this.customInput.hasNumberError()) {
                //Ajout d'une erreur 'custom' et renvoi des erreurs
                return {'invalid': true,...this.customInput.autocomplete?.errors};
            }
        } else if (this.customInput && this.customInput.customType === 'iban-obfuscated') {
            //On doit vérifier la valeur uniquement si elle a été modifiée, dans le cas contraire la valeur est obfusquée et donc forcément invalide
            //On vérifie si la valeur est en cours de modification en plus du champ 'isModelChanged' car ce dernier n'est mis à jour qu'au blur sur le champ
            if (this.customInput.isModelChanged || (this.customInput.inputObfuscated.control.touched && this.customInput.inputObfuscated.value?.length > 0)) {
                //Instanciation de la directive ngx-iban le cas échéant
                if (!this.ibanDirective) {
                    this.ibanDirective = new IbanDirective();
                }

                //On retourne le résultat de la validation faite par la directive
                return this.ibanDirective.validate(control);
            }
        }

        return null;
    }
}
