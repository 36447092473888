import {Component,OnInit,ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {SettingsGlobalState} from "@domain/settings/settings";
import {ConfigCompte} from "@domain/settings/configCompte";
import {ComptabiliteComptesBancairesService} from "@components/admin/comptabilite/comptes-bancaires/comptabilite-comptes-bancaires.service";
import {ReferentielDto} from "@domain/admin/referentiels/referentielDto";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {NgForm} from "@angular/forms";
import {finalize,first} from "rxjs/operators";

/**
 * Onglet Généralités du menu admin Comptabilité / Comptes bancaires
 *
 * @author Alexis BOUVARD
 * @date 18/04/2023
 */
@Component({
	host: {'data-test-id': 'comptes-bancaires-generalites'},
	selector: 'comptes-bancaires-generalites',
	templateUrl: './comptes-bancaires-generalites.component.html'
})
export class ComptesBancairesGeneralitesComponent implements OnInit {
	/** Configuration des comptes bancaires */
	configCompte: ConfigCompte;

	/** Liste des catégories */
	listeCategories: ReferentielDto[] = [];

	/** Liste des catégories sélectionnées */
	listeCategoriesSelected: ReferentielDto[] = [];

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Formulaire */
	@ViewChild('form')
	form: NgForm;

	/** Enregistrement en cours */
	isSaving: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param store Store qui permet de récupérer les settings global
	 * @param comptabiliteCompteBancaireService Service de gestion de l'écran Compte Bancaires du menu admin Comptabilité
	 * @param toastrService Service de gestion des toasts
	 * @param translateService Service de gestion de la traduction
	 */
	constructor(
		private store: Store<AppState>,
		private comptabiliteCompteBancaireService: ComptabiliteComptesBancairesService,
		private toastrService: ToastrService,
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Appel back pour récupérer la liste des catégories possibles
		this.comptabiliteCompteBancaireService.getCategories().subscribe(liste => {
			this.listeCategories = liste.sort(function (a,b) {
				return a.id - b.id
			});

			//Récupération dans le store des settings globaux qui contiennent la configuration des comptes bancaires
			this.store.select(state => state.settings?.Global).pipe(filterFirstNotNull()).subscribe((settings: SettingsGlobalState) => {
				this.configCompte = settings.configCompte;

				//Initialisation des catégories sélectionnées
				this.initCategoriesSelected();

				//Ajout de l'action d'enregistrement
				this.listeActions.next([
					{
						type: TypeAction.PRIMARY,
						icone: 'nio icon-sauvegarde',
						libelle: 'global.actions.enregistrer',
						doAction: () => this.saveConfigCompte(),
						isDisabled: () => this.form.invalid
					}
				]);
			});
		});
	}

	/**
	 * Initialisation des catégories sélectionnées
	 */
	initCategoriesSelected() {
		this.configCompte.listeCategorie.forEach(id => this.listeCategoriesSelected.push(this.listeCategories.find(categ => categ.id === id)));
	}

	/**
	 * À la désactivation de la gestion des cartes de paiement, on décoche toutes les autres cases
	 */
	onCarteEntrepriseChange() {
		//On filtre pour ne garder que les fois où on décoche
		if (!this.configCompte.cartePaiement) {
			//On décoche la Gestion des cartes bancaires par les collaborateurs
			this.configCompte.cartePersoPaiementCollaborateur = false;

			//On décoche les cartes affaires à débit collaborateur
			this.configCompte.carteAffaireDebitCollab = false;

			//On décoche les cartes affaires à débit entreprise
			this.configCompte.carteAffaireDebitEntrep = false;

			//Appel à la méthode de gestion de la désactivation des cartes bancaires par les collaborateurs
			this.onCarteAffaireDebitEntrepChange();
		}
	}

	/**
	 * À la désactivation de la gestion des cartes bancaires par les collaborateurs, on décoche toutes les autres cases
	 */
	onCarteAffaireDebitEntrepChange() {
		//On filtre pour ne garder que les fois où on décoche
		if (!this.configCompte.carteAffaireDebitEntrep) {
			//On vide les catégories sélectionnées et on met à jour dans l'objet
			this.listeCategoriesSelected = [];
			this.onSelectedCategoriesChange();
		}
	}

	/**
	 * Mise à jour de l'objet configCompte à chaque changement dans la liste des catégories sélectionnées
	 */
	onSelectedCategoriesChange() {
		this.configCompte.listeCategorie = this.listeCategoriesSelected.map(c => c.id);
	}

	/**
	 * Enregistrement du formulaire
	 */
	saveConfigCompte() {
		//Début de l'enregistrement
		this.isSaving = true;

		//Appel au service
		this.comptabiliteCompteBancaireService.saveConfigCompte(this.configCompte)
			.pipe(first(),finalize(() => this.isSaving = false))
			.subscribe((result: Result) => {
				//On regarde si l'enregistrement s'est bien déroulé
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast success
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
				} else {
					//Toast error
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			});
	}
}
