import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {EtapeTournee} from "@domain/Tournee/etape-tournee";
import {Unite} from "@domain/prestation/unite";
import {FraisService} from "@components/frais/frais.service";
import {Localisation} from "@domain/geolocalisation/localisation";
import {ControlContainer,NgForm} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant qui affiche un élément du stepper
 */
@Component({
    host: {'data-test-id': 'stepper-tournee-item'},
    selector: 'stepper-tournee-item',
    templateUrl: './stepper-tournee-item.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class StepperTourneeItemComponent implements OnInit {
    /** Emetteur de changement de la localisation d'arrivée */
    @Output() localisationArriveChange = new EventEmitter<Localisation>();

    /** Emetteur de changement de l'heure de début */
    @Output() heureDebutChange = new EventEmitter<string>();

    /** Emetteur de changement de l'heure de fin */
    @Output() heureFinChange = new EventEmitter<string>();

    /** Etape tournée */
    @Input() etape: EtapeTournee;

    /** Unité */
    @Input() unite: Unite;

    /** Indique si c'est la première étape */
    @Input() isFirstEtape: boolean;

    /** Localisation de l'étape précédente */
    @Input() beforeEtapeLocalisation: Localisation;

    /** Paramètre étapes personnelles */
    @Input() etapesPersonnelles: boolean;

    /** Paramètre temps détaillé, pour la saisie du temps à chaque stepper */
    @Input() gestionTempsDetaillee: boolean;

    /** Paramètre quantité modifiable */
    @Input() quantiteModifiable: boolean;

    /** Heure fin de l'étape précédente */
    @Input() beforeEtapeheureFin: string;

    /** Index de l'étape */
    @Input() index: number;

    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /**
     * Constructeur
     */
    constructor(private fraisService: FraisService, private toastrService: ToastrService, private translateService: TranslateService) {
    }

    /**
     * Initialisateur
     */
    ngOnInit() {
        this.etape.personnel = this.etape.personnel ?? false;
    }

    /**
     * Indique si on a la gestion des heures détaillées
     */
    hasShowGestionHeureDetaillee(): boolean {
        return this.gestionTempsDetaillee;
    }

    /**
     * Handler qui permet de gérer la gestion du changement de localisation
     */
    onLocalisationChange(): void {
        //Si le départ et la destination sont renseignés on calcule la distance
        if (this.etape.localisationDepart != null && this.etape.localisationArrivee != null) {
            //On calcule la distance
            this.fraisService.findDistance(this.etape.localisationDepart, this.etape.localisationArrivee, false)
                .subscribe( {
                    next: (distance: number) => {
                        this.etape.distance = distance;
                    },
                    error: () => {
                        //Pas de calcul possible on affecte la valeur par défaut
                        this.etape.distance = 0;
                        this.toastrService.error(this.translateService.instant('global.errors.chargement'));
                    }
                });
        }
    }

    /**
     * Handler qui gère le changement de localisation d'arrivée
     */
    onLocalisationArriveChanged(): void {
        this.localisationArriveChange.emit(this.etape.localisationArrivee);
    }

    /**
     * Handler qui gère le changement d'heure du début
     */
    onHeureDebutChange(): void {
        this.heureDebutChange.emit(this.etape.heureDebut);
    }

    /**
     * Handler qui gère le changement d'heure de fin
     */
    onHeureFinChange(): void {
        this.heureFinChange.emit(this.etape.heureFin);
    }

}