import {Component,EventEmitter,Input,Output,ViewChild} from '@angular/core';
import {Facture} from "@domain/facture/facture";
import {FactureContentieux} from "@domain/facture/facture-contentieux";
import {NgForm} from "@angular/forms";

/**
 * Bloc "Contentieux" de la facture
 *
 * @author Laurent Convert
 * @date 11/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-contentieux'},
    selector: 'facture-contentieux',
    templateUrl: './facture-contentieux.component.html'
})
export class FactureContentieuxComponent {
    /** Facture en cours */
    @Input() facture: Facture;
    
    /** Contentieux de la facture en cours */
    @Input() contentieux: FactureContentieux;
    
    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;
    
    /** Évènement déclenché lors de l'activation / désactivation de l'alerte via la checkbox prévue à cet effet */
    @Output() onDesactivationAlerte: EventEmitter<void> = new EventEmitter<void>();
    
    /** Formulaire */
    @ViewChild('form') form: NgForm;
    
    /* Accesseurs sur le champ "alerte" permettant d'inverser la valeur : true = non cochée, false = coché 🙃 */
    get alerte(): boolean { return !this.contentieux?.alerte; }
    set alerte(alerte: boolean) { this.contentieux.alerte = !alerte; }
    
    /**
     * Vérifie si les données sont valides
     *
     * @return True si valide, False sinon
     */
    isValid(): boolean {
        return this.form?.valid;
    }
    
}