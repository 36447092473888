import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first, map} from "rxjs/operators";
import {SuiviBudget} from "@domain/budget/suivi-budget";
import {TypePortee} from "@domain/workflow/workflow";
import {ProcedureBudgetaire} from "@share/component/outils/gestion-budgetaire/gestion-budgetaire.component";

@Injectable({
    providedIn: 'root'
})
/**
 * Service du budget
 */
export class BudgetService {

    /**
     * Constructeur
     *
     * @param http client HTTP
     */
    constructor(private http: HttpClient) { }

    /**
     * Appel back pour récupérer la somme des imputation d'une enveloppe
     *
     * @param idEnveloppe l'ID de l'enveloppe
     * @param idMontant l'ID du montant que le consulte
     * @param typeBudget le type de budget (réel=0 ou prévisionnel=1)
     */
    computeMontantTotalImpute(idEnveloppe: number, idMontant: number, typeBudget: number): Observable<number> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/computeMontantTotalImpute?idEnveloppe=` + idEnveloppe + "&idMontant=" + idMontant + "&typeBudget=" + typeBudget,null).pipe(
            map(result => result?.data.montantTotalImpute)
        );
    }

    /**
     * Récupération du suivi budgétaire d'un objet
     *
     * @param idTypeObjetWf Type d'objet Workflow
     * @param idObjetWf Identifiant de l'objet
     */
    loadSuiviBudget(idTypeObjetWf: number,idObjetWf: number): Observable<Array<SuiviBudget>> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/BudgetUser/loadSuiviBudget?idTypeObjetWf=${idTypeObjetWf}&idObjetWf=${idObjetWf}`).pipe(
            map((result: Result) => {
                return result?.data.listeSuivi?.map((suivi) => new SuiviBudget(suivi));
            })
        );
    }

    /**
     * Force l'exécution d'une synchro budgétaire sur l'objet concerné
     *
     * @param idPortee Portée de l'objet
     * @param idObjet  Identifiant de l'objet
     */
    synchroHistoBudget(idPortee: String, idObjet: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Budget/${idPortee}/${idObjet}/synchroHistoBudget`, null);
    }

    /**
     * Effectue une procédure budgétaire sur un objet workflow
     *
     * @param idObjet       Identifiant de l'objet
     * @param typeObjet     Type de l'objet
     * @param action        Type de procédure à effectuer (1-Imputation ; 2-Libération ; 3-Clôture pour OD)
     * @param idRole        Identifiant du rôle effectuant la procédure
     * @param isValidation  Indique s'il s'agit d'une validation
     *
     * @return true si tout s'est bien passé
     */
    doProcedureBudgetaire(idObjet: number, typeObjet: TypePortee, action: ProcedureBudgetaire, idRole: number, isValidation: boolean): Observable<boolean> {
        let params: HttpParams = new HttpParams()
            .append('action', action.toString())
            .append('idRole', idRole.toString())
            .append('isValidation', isValidation.toString());

        return this.http.post<boolean>(`${environment.baseUrl}/controller/Budget/${typeObjet}/doProcedureBudgetaire/${idObjet}`, null, { params: params }).pipe(first());
    }
}
