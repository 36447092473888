/**
 * Représente une unité
 */
export class Unite {
	idUnite: number;
	libelle: string;
	typeDistance: TypeDistance;
}

/**
 * Enumération des différents types de distance
 */
export enum TypeDistance {
	TYPE_DISTANCE_NONE = 0,
	TYPE_DISTANCE_KM = 1,
	TYPE_DISTANCE_MILES = 2,
}
