import {Component, Input, OnInit} from '@angular/core';
import {ListViewItem} from "../../../../domain/common/list-view/list-view-item";
import {CarteVoyage} from "../../../../domain/profil/carteVoyage";
import * as moment from "moment";
import {TypePresta} from "../../../../domain/travel/type-presta";

/**
 * Composant pour une ligne de la liste des cartes de voyage
 */
@Component({
    selector: 'profil-donnees-carte-voyage-list-item',
    templateUrl: './profil-donnees-carte-voyage-list-item.component.html'
})
export class ProfilDonneesCarteVoyageListItemComponent extends ListViewItem<CarteVoyage> implements OnInit {
    /** Elément courant */
    @Input() data: CarteVoyage;

    /** Options supplémentaires */
    @Input() extraOptions: any;

    /** État d'échéance proche */
    isSoonToExpire: boolean;

    /** État d'expiration */
    isExpired: boolean;

    /** Initialisation du composant */
    ngOnInit() {
        let momentToday: moment.Moment;
        let dateValiditeFin: moment.Moment;

        //Vérification de la date de fin de validité
        if (this.data?.dateValiditeFin) {
            //Construction des objets Moment
            momentToday = moment().startOf('day');
            dateValiditeFin = moment(this.data.dateValiditeFin).startOf('day');

            //Calcul de l'état d'expiration
            this.isExpired = this.extraOptions?.settings?.alerteEchuActif && momentToday.isAfter(dateValiditeFin);
            this.isSoonToExpire = !this.isExpired && this.extraOptions?.settings?.alerteEcheanceActif && momentToday.add(this.extraOptions?.settings?.delaiAlerteEcheance, 'days').isSameOrAfter(dateValiditeFin);
        }
    }

    /** Renvoie le clé de texte à traduire selon le type de prestation */
    getTypePrestationKey(typePresta: TypePresta): string {
    if (typePresta != null) {
        return 'profilUser.carteAbonnement.typeCarte.' + typePresta.toString();
    }

    return '';
  }
}
