import {NgModule} from "@angular/core";

import {ShareModule} from "@share/share.module";
import {WorkflowModule} from "../workflow/workflow.module";
import {ReleveFactureComponent} from "./releve-facture.component";
import {ReleveComponent} from "./releve/releve.component";
import {ReleveFactureService} from "./releve-facture.service";
import {AnalytiqueModule} from "../analytique/analytique.module";
import {ReferentielExterneModule} from "../referentiel-externe/referentiel-externe.module";
import {DocumentModule} from "../document/document.module";
import {ReleveListItemComponent} from "./releve-list/releve-list-item/releve-list-item.component";
import {ReleveListComponent} from "./releve-list/releve-list.component";
import {FactureListComponent} from "./facture-list/facture-list.component";
import {FactureListItemComponent} from "./facture-list/facture-list-item/facture-list-item.component";
import {ZonePredefinieModule} from "@components/zone-predefinie/zone-predefinie.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {FactureAnomalieListComponent} from "@components/facture/anomalie/facture-anomalie-list/facture-anomalie-list.component";
import {FactureAnomalieListItemComponent} from "@components/facture/anomalie/facture-anomalie-list/facture-anomalie-list-item/facture-anomalie-list-item.component";
import {FactureAnomalieDetailComponent} from "@components/facture/anomalie/facture-anomalie-detail.component";

@NgModule({
    imports: [
        ShareModule,
        WorkflowModule,
        AnalytiqueModule,
        ReferentielExterneModule,
        DocumentModule,
        ZonePredefinieModule,
        PreviewAlerteModule,
    ],
    declarations: [
        ReleveFactureComponent,
        ReleveComponent,
        ReleveListItemComponent,
        ReleveListComponent,
        FactureListComponent,
        FactureListItemComponent,
        FactureAnomalieListComponent,
        FactureAnomalieListItemComponent,
        FactureAnomalieDetailComponent,
    ],
    providers: [ReleveFactureService],
    exports: [
        ReleveListComponent
    ]
})
export class ReleveFactureModule {}
