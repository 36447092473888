import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';

import {AppState} from '@domain/appstate';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {ListeFacture} from '@domain/facture/liste-facture';
import {TypeProfil} from '@domain/user/user';
import {environment} from '@environments/environment';
import {AlerteComponent} from '../../../workflow/alerte.component';
import {first} from "rxjs/operators";
import {LayoutService} from '@share/layout/layout.service';
import {TranslateService} from "@ngx-translate/core";
import {TypeReconciliation} from "@domain/facture/releve-facture";

@Component({
    host: {'data-test-id': 'facture-list-item'},
    templateUrl: './facture-list-item.component.html'
})
export class FactureListItemComponent extends ListViewItem<ListeFacture> implements OnInit {
    /* Déclaration pour accès dans le template */
    TypeReconciliation = TypeReconciliation;

    /** Elément courant */
    @Input() data: ListeFacture;

    /** Profil de l'utilisateur connecté **/
    fonction: TypeProfil;

    /** URL de base */
    baseUrl: string = environment.baseUrl;

    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog,private store: Store<AppState>,private layoutService: LayoutService,private router: Router,public translateService: TranslateService) {
        //Héritage
        super();
    }
    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user.fonction).pipe(first()).subscribe(fonction => this.fonction = fonction);
    }

    /**
     * Ouverture d'un élément
     */
    navigateTo(): void {
        //Navigation vers l'élément
        this.router.navigate([`ReleveFacture/${this.data.factureReleve.idFactureReleve}/Facture`,this.data?.getId()]);
    }

    /**
     * Affichage de la popup de la liste des alertes
     */
    showListeAlertes(): void {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: this.data.listeAlertes.listeAlertes
            }
        });
    }

    /**
     * Getter pour le mode Collaborateur
     */
    get isCollabMode(): boolean {
        return this.fonction == TypeProfil.COLLABORATEUR;
    }

    /**
     * Ouverture d'un OD
     */
    openOd(idOd: number): void {
        //Navigation vers l'élément
        this.router.navigate(['OD',idOd]);
    }

}
