import {Component,Inject,OnInit} from '@angular/core';
import {Champ,FiltreChamp} from "@domain/travel/champ";
import {AdminTravelhubService} from "../../../../../../admin-travelhub.service";
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {Page} from "@domain/common/http/list-result";
import {ParamConnexionChamp} from "@domain/travel/param-connexion-champ";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {
	ParamConnexionSelectionListItemComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/selection/param-connexion-selection-list-item.component";
import {MatTabChangeEvent} from "@angular/material/tabs";

/**
 * Popin de sélection du champ à associer à un paramètre
 *
 * @author Laurent Convert
 * @date 14/12/2023
 */
@Component({
	host: {'data-test-id': 'param-connexion-selection'},
	templateUrl: './param-connexion-selection.component.html',
	styles: [':host ::ng-deep .mat-tab-body-wrapper { margin-top: 10px }']
})
export class ParamConnexionSelectionComponent implements OnInit {
	/* Déclaration pour accès dans le template */
	public readonly FiltreChamp = FiltreChamp;

	/** Liste des champs correspondants au filtre courant (déterminé suivant l'onglet) */
	listeChamps: ListView<ParamConnexionChamp,ParamConnexionSelectionListItemComponent>;

	/** Liste des filtres */
	listeFilter: Array<FiltreChamp> = [];

	/** Index de l'onglet courant */
	selectedIndex: number = 0;

	/**
	 * Constructeur
	 *
	 * @param data Données injectées lors de l'ouverture de la popin
	 * @param matDialogRef Référence de la popup
	 * @param translateService Service de traduction
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 */
	constructor(@Inject(MAT_DIALOG_DATA) private data: {champ: Champ},
				private matDialogRef: MatDialogRef<any>,
				private translateService: TranslateService,
				private adminTravelhubService: AdminTravelhubService,
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Groupement des champs par catégorie (filtre)
		this.listeFilter = this.adminTravelhubService.LISTE_CHAMPS
			//Récupération des filtres
			.map(c => c.filtre)
			//distinct
			.filter((value, index, array) => array.indexOf(value) === index)
			//Tri par nom sauf pour le filtre "Autre" qui est placé en dernier
			.sort((a, b) => {
				return a === FiltreChamp.AUTRE ? 1
					: b === FiltreChamp.AUTRE ? -1
					: FiltreChamp.traduction(a).localeCompare(FiltreChamp.traduction(b))
			});

			//Initialisation de la liste des champs
			this.listeChamps = new ListView<ParamConnexionChamp,ParamConnexionSelectionListItemComponent>({
				component: ParamConnexionSelectionListItemComponent,
				isFrontendList: true,
				showPagination: true,
				extraOptions: {
					parent: this
				},
				data: {
					listeResultats: [],
					numPage: 0,
					nbPagesTotal: 1,
					nbObjetsTotal: 0,
					nbObjetsDansPage: 0,
					nbObjetsParPage: 0
				} as Page<ParamConnexionChamp>
			});

		//Recherche du filtre à partir de la valeur précédente pour afficher la liste de champs correspondants
		if (this.data.champ != null) {
			this.selectedIndex = this.listeFilter.findIndex(f => f == this.data.champ.filtre);
		}
	}

	/**
	 * Rafraichissement de la liste en fonction du filtre sélectionné
	 *
	 * @param filtre Filtre sélectionné
	 */
	refreshListe(filtre: FiltreChamp) {
		//Construction de la liste des champs correspondants au filtre
		let listeChamps = this.adminTravelhubService.LISTE_CHAMPS
			.filter(c => c.filtre == filtre)
			.sort((a, b) => {
				//Tri des valeurs par libellé traduit
				a.libelle = this.translateService.instant(a.libelle);
				b.libelle = this.translateService.instant(b.libelle);
				return a.libelle.localeCompare(b.libelle);
			})
			.map(c => new ParamConnexionChamp(c));

		//Vidage de la liste
		this.listeChamps.clear();

		//Peuplement de la liste
		this.listeChamps.addItem(listeChamps,true);
	}

	/**
	 * Handler au changement d'onglet
	 *
	 * @param event Évènement
	 */
	onTabChanged(event: MatTabChangeEvent): void {
		// this.selectedIndex = event.index;
		this.refreshListe(this.listeFilter[event.index]);
	}

	/**
	 * Handler au clic sur une des lignes de la liste
	 *
	 * @param paramConnexionChamp
	 */
	selectParamConnexion(paramConnexionChamp: ParamConnexionChamp): void {
		this.matDialogRef.close(paramConnexionChamp);
	}

}