import {Component,Inject} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {LieuService} from "../../lieu.service";
import {Localisation,Origine,Visibilite} from "../../../../domain/geolocalisation/localisation";
import {SaveAdresseResult} from "../../../../domain/geolocalisation/saveAdresseResult";
import {Geographie} from "../../../../domain/geolocalisation/geographie";
import {ToastrService} from "ngx-toastr";
import {Adresse} from "../../../../domain/profil/adresse";
import {TypePortee} from "@domain/workflow/workflow";

@Component({
    templateUrl: './lieu-geolocalisation-list.component.html',
    host:{'data-test-id':'lieu-geocalisation-list'},
})
export class LieuGeolocalisationListComponent {
    //Flag permettant de savoir si un chargement (ajax) est en cours
    isLoading: boolean;

    //Liste des adresses issues du service de géolocalisation suite à une saisie
    listeGeolocalisation: Array<Localisation> = null;

    constructor(private lieuService: LieuService,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private matDialogRef: MatDialogRef<any,any>,
                @Inject(MAT_DIALOG_DATA) public data: { idUser: number,adresse: Adresse,geolocalisation: Localisation,idPortee: TypePortee }) {

        //Début du chargement
        this.isLoading = true;

        //Récupération de la liste des adresses géolocalisées trouvées à partir de la saisie
        this.lieuService.getListeLocalisation(this.data.geolocalisation).subscribe((listeLocalisation) => {
            //Mise à jour des objets retournés
            listeLocalisation.forEach((geolocalisation) => {
                //Définition de la rue (ajout du numéro)
                geolocalisation.rue = geolocalisation.numero ? geolocalisation.numero + ' ' + geolocalisation.rue : geolocalisation.rue;

                //Reconstruction de l'adresse pour afficher le même format que les établissements et adresses perso/pro
                geolocalisation.adresse = this.lieuService.getAdresseFromLocalisation(geolocalisation);
            });

            //Mise à jour de la liste des suggestions et affichage
            this.listeGeolocalisation = listeLocalisation;

            //Fin du chargement
            this.isLoading = false;
        });

    }

    /**
     * Sélection d'une adresse géolocalisée
     *
     * @param geolocalisation Géolocalisation sélectionnée
     */
    public selectLocalisation(geolocalisation: Localisation): void {
        //Paramètres pour l'enregistrement de l'adresse
        const adresse: Localisation = {
            ...(this.data.adresse ?? {}),
            ...geolocalisation,
            listeVisibilite: this.data.idPortee === TypePortee.ADM ? [Visibilite.FILTRE_V_ADM] : [Visibilite.FILTRE_V_NF],
            origine: this.data.idPortee === TypePortee.ADM ? Origine.ORIGINE_ADMIN : Origine.ORIGINE_USER,
            idUser: this.data.idUser ?? (this.data.idPortee === TypePortee.ADM ? 0 : undefined)
        };

        //Début de l'enregistrement
        this.isLoading = true;

        //Enregistrement d'une adresse à partir de la géolocalisation sélectionnée
        this.lieuService.saveAdresse(new Adresse(adresse)).subscribe((result: SaveAdresseResult) => {
            //Fin de l'enregistrement
            this.isLoading = false;

            //Récupération de l'objet geographie associé à l'adresse enregistrée
            const geographie: Geographie = result.geographie;

            //Vérifie si l'adresse est complète
            if (geographie && (this.data.idPortee === TypePortee.ADM
                                || ((this.data.idPortee === TypePortee.OD || this.data.idPortee === TypePortee.OT) && geographie.entiteGeo?.libelleVille && geographie.entiteGeo?.libellePays)
                                || (geographie.entiteGeo?.libelle && geographie.entiteGeo?.libelleVille && geographie.entiteGeo?.libellePays))) {
                //Mise à jour de l'adresse du lieu
                this.selectAutre({
                    id: geographie.id,
                    adresse: geolocalisation.adresse,
                    rue: geographie.entiteGeo?.libelle,
                    codePostal: geographie.entiteGeo?.codePostal,
                    ville: geographie.entiteGeo?.libelleVille,
                    pays: geographie.entiteGeo?.libellePays,
                    codePays: geographie.entiteGeo?.codePays2,
                    codePays3: geographie.entiteGeo?.codePays3
                });
            } else if (geographie) {
                //Une adresse a été sélectionnée mais elle est incomplète : retour à la saisie
                this.toastrService.warning(this.translateService.instant("lieu.adresseSelectionneeIncomplete"));
                this.close();
            } else {
                //Pas d'adresse trouvée : retour à la saisie
                this.toastrService.error(this.translateService.instant("lieu.aucuneAdresseGeoloc"));
                this.close();
            }
        });
    }

    /**
     * Fermeture de la popup
     */
    close(): void {
        this.matDialogRef.close();
    }

    /**
     * Envoie la géolocalisation sélectionnée au parent pour traitement
     *
     * @param geolocalisation Géolocalisation sélectionnée
     */
    selectAutre(geolocalisation: Localisation): void {
        this.matDialogRef.close({
            geolocalisation: geolocalisation
        });
    }

}
