import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ParamConnexionChamp} from "@domain/travel/param-connexion-champ";
import {FiltreChamp} from "@domain/travel/champ";
import {
	ParamConnexionSelectionComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/selection/param-connexion-selection.component";

/**
 * Ligne de la popup de sélection du champ à associer à un paramètre
 *
 * @author Laurent Convert
 * @date 14/12/2023
 */
@Component({
	host: {'data-test-id': 'param-connexion-selection-list-item'},
	selector: 'param-connexion-selection-list-item',
	templateUrl: './param-connexion-selection-list-item.component.html'
})
export class ParamConnexionSelectionListItemComponent extends ListViewItem<ParamConnexionChamp> implements OnInit {

	/** Options supplémentaires */
	extraOptions: {
		parent: ParamConnexionSelectionComponent
	}

	/** Icône déterminée en fonction du paramètre */
	icone: string;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'icône en fonction de la catégorie du champ
		this.icone = FiltreChamp.icone(this.data.champ.filtre);
	}

}