import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ShareModule} from "@share/share.module";
import {MatRadioModule} from "@angular/material/radio";
import {ParametresComponent} from "./parametres/parametres.component";
import {MaintenanceComponent} from "./maintenance/maintenance.component";
import {HistoriqueComponent} from "./historique/historique.component";
import {MigrationsComponent} from "@components/admin/maintenance/migrations/migrations.component";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {ManualUpdateComponent} from "@components/admin/maintenance/migrations/parametres/manual-update.component";
import {TaskItemComponent} from "@components/admin/maintenance/migrations/maintenance/task-item.component";
import {MaintenanceDetailComponent} from "@components/admin/maintenance/migrations/maintenance/maintenance-detail.component";
import {LogComponent} from "@components/admin/maintenance/migrations/maintenance/log.component";
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {MigrationBddPopupComponent} from "@components/admin/maintenance/migrations/migration-bdd-popup.component";
import {ListeMigrationPopupComponent} from "@components/admin/maintenance/migrations/liste-migration-popup.component";
import {MigrationItemComponent} from "@components/admin/maintenance/migrations/historique/migration-item.component";
import {MigrationPopupComponent} from "@components/admin/maintenance/migrations/historique/migration-popup.component";
import {MigrationDetailItemComponent} from "@components/admin/maintenance/migrations/historique/migration-detail-item.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {AdminPasswordPopupComponent} from "@components/admin/maintenance/migrations/parametres/admin-password-popup.component";

export const maintenanceMigrationsRoutes : Routes = [
    {
        path: 'Historique',
        component: HistoriqueComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Maintenance',
        component: MaintenanceComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Maintenance/:taskName',
        component: MaintenanceDetailComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: 'Parametres',
        component: ParametresComponent,
        canActivate: [AdminGuardProvider]
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Historique'
    }, {
        path: '**',
        redirectTo: 'Historique'
    }
];

@NgModule({
    imports: [ShareModule, PreviewAlerteModule, MatDividerModule, TranslateModule, MatRadioModule, CodemirrorModule, MatExpansionModule],
    declarations: [MaintenanceComponent,ParametresComponent,HistoriqueComponent,MigrationsComponent,ManualUpdateComponent,TaskItemComponent,MaintenanceDetailComponent,LogComponent,MigrationBddPopupComponent,ListeMigrationPopupComponent,MigrationItemComponent,MigrationPopupComponent,MigrationDetailItemComponent,AdminPasswordPopupComponent],
    entryComponents: [],
    exports: [MaintenanceComponent,ParametresComponent,HistoriqueComponent,MigrationsComponent,ManualUpdateComponent,TaskItemComponent,MaintenanceDetailComponent,LogComponent,MigrationBddPopupComponent,ListeMigrationPopupComponent,MigrationItemComponent,MigrationPopupComponent,MigrationDetailItemComponent,AdminPasswordPopupComponent],
    providers: [MigrationsService]
})
export class MigrationsModule {
}
