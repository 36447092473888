import {TranslateService} from '@ngx-translate/core';

import {Filter} from '@domain/common/list-view';
import {AutocompleteOptions} from '../autocomplete';
import {TypeCharge} from "@domain/prestation/type-charge";
import {EntityAttribute} from "@share/component/autocomplete/options/entity-attribute";
import {NatureVoyage} from "@domain/prestation/nature-voyage";
import {Nature} from "@domain/prestation/nature";
import {TypeChargeLineItem} from "@share/component/autocomplete/options/type-charge/line-item/type-charge.line-item";

/**
 * Options des types charge
 */
export default class TypeChargeOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     * @param filter filtre
     * @returns l'URL
     */
    url(filter: {isImportFacture: boolean,idTypeEntite: number,idCollab: number}): any {
        //Vérification du filtre spécial pour la correction des rejets de facture
        if (filter.isImportFacture) {
            //URL pour la correction des rejets de facture
            return `/controller/ImportFacture/searchTypeCharge?idTypeEntite=${filter.idTypeEntite || 0}&idCollab=${filter.idCollab || '0'}`;
        } else {
            //URL par défaut
            return `/controller/TypeCharge/searchTypeCharge`;
        }
    }

    /**
     * Affichage d'un élément
     * @param item élément à afficher
     * @returns affichage
     */
    displayItem(item: TypeCharge): any { return item?.libelle || ''; }

    /**
     * Filtres disponibles
     * @param translateService service de traduction
     * @param filter Filtres
     * @returns la liste des filtres
     */
    listeFilters(translateService: TranslateService,filter: any): Array<Filter> {
        const listeFilter: Array<Filter> = new Array<Filter>();

        listeFilter.push({
            title: 'libelle',
            clef: 'libelle',
            isDefault: true
        },{
            title: 'code',
            clef: 'code',
            isDefault: true
        });

        if (!filter?.idFournisseur) {
            listeFilter.push({
                clef: 'idTypeFile',
                valeur: filter.idFournisseur
            });
        }

        return listeFilter;
    }

    /**
     * Liste des filtres statiques
     */
    listeStaticFilters(filter: any) {
        const listeFilter: Array<Filter> = new Array<Filter>();

        if (filter?.idFournisseur) {
            listeFilter.push({
                clef: 'idTypeFile',
                valeur: filter.idFournisseur,
                hiddenChip: true
            });
        }

        return listeFilter;
    }

    /**
     * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
     */
    listDisplayedAttributes(item: TypeCharge): Array<EntityAttribute> {
        const listeAttributs: Array<EntityAttribute> = [];

        listeAttributs.push({
            clef: 'code',
            valeur: `${item.code}`
        });

        return listeAttributs;
    }

    // searchItemComponent?: () => any,
    lineItemComponent() { return TypeChargeLineItem; }

    getIcon(item: TypeCharge): string {
        return Nature.icone(NatureVoyage.getNature(item.codeFamille));
    }
}