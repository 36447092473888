import {Component,OnInit,ViewChild} from '@angular/core';
import {UtilisateursParametresService} from "@services/admin/entreprise/utilisateurs/utilisateurs-parametres.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ProfilAlertesConfig} from "@domain/configuration/profil-alertes-config";
import {MessageNotif} from "@domain/notification/message-notif";
import {TypeFormulaire} from "@domain/profil/DocumentAlerte";
import {NgForm} from "@angular/forms";

/**
 * Composant de configuration des alertes du profil
 */
@Component({
	host: {'data-test-id': 'profil-alertes-config'},
	selector: 'profil-alertes-config',
	templateUrl: './profil-alertes-config.component.html'
})
export class ProfilAlertesConfigComponent implements OnInit {
	/** Configuration des alertes du profil */
	profilAlertesConfig: ProfilAlertesConfig;

	/** Liste des messages de notification */
	listeMessages: MessageNotif[];

	/** Liste des types de documents à relancer **/
	listeFormulaires: Array<{ code: TypeFormulaire,libelle: string }> = [
		{
			code: TypeFormulaire.VEHICULE,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.codeFormulaire.vehicule')
		},{
			code: TypeFormulaire.PIECE_IDENTITE,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.codeFormulaire.pieceIdentite')
		},{
			code: TypeFormulaire.CARTE_ABO_FID,
			libelle: this.translateService.instant('admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.codeFormulaire.carteAboFid')
		}
	];

	/** Liste des formulaires relancés avant échéance */
	listeFormulaireEcheanceSelected: Array<{ code: TypeFormulaire,libelle: string }> = [];

	/** Liste des formulaires relancés une fois échu */
	listeFormulaireEchuSelected: Array<{ code: TypeFormulaire,libelle: string }> = [];

	/** Le formulaire */
	@ViewChild('form') form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param usersParamsService le service de gestion des paramètres utilisateurs
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 */
	constructor(
		private usersParamsService: UtilisateursParametresService,
		private translateService: TranslateService,
		private toastrService: ToastrService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Chargement de la config
		this.usersParamsService.loadProfilAlerteConfig()
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Récupération de la configuration des alertes du profil
					this.profilAlertesConfig = result.data.profilAlerteConfig;

					//Récupération de la liste des message de notification
					this.listeMessages = result.data.listeMessages;

					//Vérification de la gestion des cartes de paiement
					if (result.data.isCartePaiement) {
						//Ajout à la liste des formulaires
						this.listeFormulaires.push({
							code: TypeFormulaire.CARTE_PAIEMENT,
							libelle: this.translateService.instant('admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.codeFormulaire.cartePaiement')
						});
					}

					//Initialisation des multiselect
					this.listeFormulaires.forEach(f => {
						if (this.profilAlertesConfig.listeFormulaireEcheance.includes(f.code)) {
							this.listeFormulaireEcheanceSelected.push(f);
						}
						if (this.profilAlertesConfig.listeFormulaireEchu.includes(f.code)) {
							this.listeFormulaireEchuSelected.push(f);
						}
					});
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Appelé à chaque modification des types de documents à échéance relancés par mail
	 */
	onTypeDocEcheanceChange(): void {
		this.profilAlertesConfig.listeFormulaireEcheance = this.listeFormulaireEcheanceSelected.map(opt => opt.code);
	}

	/**
	 * Appelé à chaque modification des types de documents échus relancés par mail
	 */
	onTypeDocEchuChange(): void {
		this.profilAlertesConfig.listeFormulaireEchu = this.listeFormulaireEchuSelected.map(opt => opt.code);
	}
}
