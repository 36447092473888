import {Component,EventEmitter,Input,Output} from '@angular/core';
import {SettingsGlobalState} from '@domain/settings/settings';
import {NDFService} from '../../../ndf.service';
import {Ndf} from "@domain/ndf/ndf";
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

@Component({
    selector: 'ndf-generation-frais',
    host: {'data-test-id': 'ndf-generation-frais'},
    templateUrl: './ndf-generation-frais.component.html'
})
export class NDFGenerationFraisComponent {
    /** Note de frais **/
    @Input() ndf: Ndf;

    /** Paramétrage **/
    @Input() settings: SettingsGlobalState;

    /** Lance l'output de mise à jour de alerte frais */
    @Output() checkFraisAlerte = new EventEmitter<string>();

    /**
     * Constructeur
     */
    constructor(private ndfService: NDFService, private toastrService: ToastrService, private translateService: TranslateService) {

    }

    /**
     * Finalisation de l'upload d'un fichier
     */
    onItemUploaded({ result,file}) {
        if (result?.data?.document) {
            //Vérification du document
            //Traitement OCR du justificatif
            this.ndfService.processJustificatif(result.data.document,true,this.ndf.idNDF).pipe(first(),
                finalize(() => {
                    //Si le fichier a bien été intégré
                    if (!file.isKo) {
                        //Emission de l'alerte
                        this.checkFraisAlerte.emit();
                    }
                })).subscribe({
                    next: (result: Result) => {
                        if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //On contrôle l'intégration du fichier
                            file.isKo = result.data.nbUploaded == 0;

                            //Si l'intégration est ko
                            if (file.isKo) {
                                //message d'erreur
                                this.toastrService.error(this.translateService.instant('ndf.generalites.errorGenerationFrais'));
                            }
                        } else if (result.codeErreur === TypeCodeErreur.ERROR_PERMISSION) {
                            //Intégration du fichier KO
                            file.isKo = true;

                            //On met une erreur de permission
                            this.toastrService.error(this.translateService.instant('global.errors.permission'));
                        } else {
                            //Intégration du fichier KO
                            file.isKo = true;

                            //On met une erreur d'enregistrement
                            this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                        }
                    }
                }
            );
        }
    }
}
