<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">directions_car</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="viewDetail()">{{ getLibelle() }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.vehicule?.immatriculation">
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurFiscalPopin.vehicule'"></strong>
                <span>{{ data.vehicule.marque + ' ' + data.vehicule.modele + ' - ' + data.vehicule.immatriculation }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurFiscalPopin.distanceEnCours'"></strong>
                <span>{{ data.compteurTheorique | number:'1.2-2' }} {{ data.unite.libelle }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.compteurFiscalPopin.distanceValidee'"></strong>
                <span>{{ data.compteurReel | number:'1.2-2' }} {{ data.unite.libelle }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="extraOptions.modeCalculIK === 1">
        <button mat-icon-button (click)="viewDetail()">
            <mat-icon color="primary">search</mat-icon>
        </button>
    </div>
</div>