<mat-card>
    <mat-card-title *ngIf="licence">
        <span *ngIf="licence.codeCRM && licence.raisonSociale != null">{{ licence.raisonSociale }} - {{ licence.codeCRM }}</span>
        <span *ngIf="!licence.codeCRM && licence.raisonSociale != null">{{ licence.raisonSociale }}</span>
    </mat-card-title>
    <mat-card-content>
        <fieldset>
            <div class="row">
                <custom-input libelle="admin.entreprise.description.generalites.enseigne"
                              customType="input"
                              name="enseigne"
                              maxlength="30"
                              required
                              [(ngModel)]="entreprise.enseigne"
                              ngDefaultControl></custom-input>

                <custom-input libelle="admin.entreprise.description.generalites.siret"
                              customType="input"
                              name="siret"
                              maxlength="14"
                              minlength="14"
                              required
                              (focusout)="changeFocus()"
                              [(ngModel)]="entreprise.siret"
                              ngDefaultControl></custom-input>
            </div>
            <div class="row">
                <label for="adresse" class="col-md-2" [ngClass]="{ 'has-error': entreprise.adresse == null }"><span [translate]="'admin.entreprise.description.generalites.adresse'"></span></label>
                <div class="col-md-4">
                    <adresse id="adresse" name="adresse" [(ngModel)]="entreprise.adresseModel" [disabled]="false" [required]="true" (onSelect)="onSelect($event)" (change)="onAdresseChange($event)"></adresse>
                </div>

                <custom-input libelle="admin.entreprise.description.generalites.siren"
                              customType="input"
                              name="siren"
                              maxlength="9"
                              minlength="9"
                              required
                              [(ngModel)]="entreprise.siren"
                              ngDefaultControl></custom-input>
            </div>
            <div *ngIf="entreprise.devise" class="row" >
                <custom-input libelle="admin.entreprise.description.generalites.devise"
                              rClass="select-width-auto"
                              customType="select"
                              [selectOptions]="listeDevises"
                              optionValue="code"
                              optionDisplay="code"
                              name="devise"
                              required
                              [(ngModel)]="entreprise.devise.code"
                              ngDefaultControl></custom-input>
            </div>
        </fieldset>
    </mat-card-content>
</mat-card>
