import {NgModule} from '@angular/core';

import {LayoutModule} from './layout/layout.module';
import {LoginModule} from './login/login.module';
import {ShareComponentModule} from './component/share-component.module';
import {UserPipe} from './pipe/user.pipe';
import {MontantPipe} from "./pipe/montant";
import {MatchChildrenHeightDirective} from './directive/match-children-height/match-children-height.directive';
import {DateUtilsModule} from "./utils/date-utils/date-utils.module";
import {DistancePipe} from "@share/pipe/distance.pipe";
import {OdPipe} from "@share/pipe/od.pipe";

@NgModule({
    imports: [LayoutModule, LoginModule, ShareComponentModule],
    exports: [LayoutModule, ShareComponentModule, UserPipe, MatchChildrenHeightDirective, MontantPipe, DateUtilsModule, DistancePipe, OdPipe],
    declarations: [UserPipe, MatchChildrenHeightDirective, MontantPipe, DistancePipe, OdPipe]
})
export class ShareModule {
}
