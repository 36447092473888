import {Od} from "@domain/od/od";

/**
 * Lien entre les factures et les OMs
 *
 * @author Laurent Convert
 * @date 12/01/2023
 */
export class FactureLienOd {
    /** Identifiant interne du lien */
    idLienFacOD: number
    
    /** Identifiant de l'ordre de mission */
    idOd: number;
    
    /** Ordre de mission */
    od: Od;
    
    /** Identifiant de la facture */
    idFacture: number;
}
