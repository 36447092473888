import { NgModule } from '@angular/core';
import { DeleteReferentielComponent } from "@components/admin/entreprise/referentiels/modifyReferentiel/delete-referentiel.component";
import { ModifyReferentielComponent } from "@components/admin/entreprise/referentiels/modifyReferentiel/modify-referentiel.component";
import { ReferentielItemComponent } from "@components/admin/entreprise/referentiels/referentiel-item.component";
import { ReferentielUsageUserItemComponent } from "@components/admin/entreprise/referentiels/referentiel-usage-user-item.component";
import { ShareModule } from "@share/share.module";
import { CreateNewReferentielComponent } from "./create-new-referentiel.component";
import { EntrepriseReferentielsComponent } from './entreprise-referentiels.component';

@NgModule({
    imports: [ShareModule],
    declarations: [
        EntrepriseReferentielsComponent,
        CreateNewReferentielComponent,
        ReferentielItemComponent,
        ModifyReferentielComponent,
        ReferentielUsageUserItemComponent,
        DeleteReferentielComponent],
    entryComponents: [ReferentielItemComponent],
    exports: [
        CreateNewReferentielComponent,
        ModifyReferentielComponent,
        DeleteReferentielComponent,
        EntrepriseReferentielsComponent
    ],
    providers: [ReferentielItemComponent]
})
export class EntrepriseReferentielsModule {
}
