/**
 * Interface marqueur permettant d'identifier un composant de popup qui est sous objet de liste.<br>
 * C'est utilisé pour savoir si la popup doit déclencher des traitements spéciaux sur la liste
 *
 * @see ListViewSelectorDirective
 *
 * @author Laurent SCIMIA
 * @date 01/03/2023
 */
export interface IPopupIgnoreFloatingButton {
	/** Membre permettant de marquer les objets qui implémentent l'interface {@link IPopupIgnoreFloatingButton} (sa valeur n'a pour le moment aucune importance, seule sa présence est importante) */
	idPopupIgnoreFloatingButton : string;
}

/**
 * Methode permettant de déterminer si l'objet en paramètre implémente l'interface {@link IPopupIgnoreFloatingButton}
 *
 * @param objet         Objet à analyser
 * @returns {boolean}   true si l'objet implémente l'interface
 */
export function implementsIPopupIgnoreFloatingButton(objet: any): objet is IPopupIgnoreFloatingButton {
	//On vérifie que l'objet en est bien un, et qu'il implémente la méthode marqueur de l'interface
	return objet instanceof Object && "idPopupIgnoreFloatingButton" in objet;
}