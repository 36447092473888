<mat-card-content *ngIf="listeEtablissement">
    <div class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let etablissement of listeEtablissement">
                <div class="lvi-content">
                    <div class="avatar"><i class="material-icons-outlined">business</i></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" (click)="selectEtablissement(etablissement)">{{ etablissement.rue }}</a>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong [translate]="'lieu.etab.libelle'"></strong>{{ etablissement.libelle }}</li>
                            <li><strong [translate]="'lieu.actif.actif'"></strong>{{ 'global.' + (etablissement.isActif ? 'oui' : 'non') | translate }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="empty" *ngIf="!listeEtablissement?.length"><span [translate]="'lieu.aucuneAdresse'"></span></div>
        </div>
    </div>
</mat-card-content>
