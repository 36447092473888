import {Component} from '@angular/core';

/**
 * Page admin de Comptabilité
 *
 * @author Alexis BOUVARD
 * @date 18/04/2023
 */
@Component({
    host: { 'data-test-id': 'admin-comptabilite' },
    selector: 'admin-comptabilite',
    templateUrl: './admin-comptabilite.component.html'
})
export class AdminComptabiliteComponent {
    /**
     * Constructeur
     */
    constructor() { }
}
