import {Component,Input} from '@angular/core';
import {FactureDepense} from "@domain/facture/facture-depense";
import {Facture} from "@domain/facture/facture";
import {Nature} from "@domain/prestation/nature";
import {NatureVoyage} from "@domain/prestation/nature-voyage";

/**
 * Ligne de la liste du détail de la facture
 *
 * @author Laurent Convert
 * @date 19/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-detail-list-item'},
    selector: 'facture-detail-list-item',
    templateUrl: './facture-detail-list-item.component.html'
})
export class FactureDetailListItemComponent {
    /** Déclaration pour accès depuis le template */
    Nature = Nature;
    NatureVoyage = NatureVoyage;
    
    /** Elèment à afficher */
    @Input() data: FactureDepense;
    
    /** Options supplémentaires */
    @Input() extraOptions: {
        facture: Facture,
        openDetail: (factureDepense?: FactureDepense) => void
    };
}