import { AutocompleteOptions } from "../autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { Filter } from '../../../../domain/common/list-view';
import { UserDto } from "../../../../domain/user/user-dto";
import * as moment from "moment";

/**
 * Options de l'autocomplete des délégués (form de création/modif d'une délégation)
 */
export default class DelegueOptions implements AutocompleteOptions {

    /**
     * Ce que l'on affiche dans l'autocomplete
     *
     * @param item l'utilisateur
     */
    displayItem(item: UserDto): string {
        return item ? item.nom + " " + item.prenom + " (" + item.matricule + ")" : "";
    }

    /**
     * Les filtres de l'autocomplete
     *
     * @param translateService service de traduction
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        return [{
            title: translateService.instant('filter.nom'),
            clef: 'nom',
            isDefault: true
        },{
            title: translateService.instant('filter.prenom'),
            clef: 'prenom',
            isDefault: true
        },{
            title: translateService.instant('filter.matricule'),
            clef: 'matricule',
            isDefault: true
        }];
    }

    /**
     * Endpoint back à appeler pour la recherche d'un délégué dans le formulaire de création d'une délégation
     *
     * @param filter critères de recherche
     */
    url(filter: { idRole: number, idUserDelegant: number, dateDebut: Date, dateFin: Date }): string {
        const dateDebut = moment(filter.dateDebut).format("DD/MM/YYYY");
        const dateFin = moment(filter.dateFin).format("DD/MM/YYYY");
        return `/controller/User/filtreDelegues?idRole=${filter.idRole}&idUserDelegant=${filter.idUserDelegant}&dateDebut=${dateDebut}&dateFin=${dateFin}`;
    }

}