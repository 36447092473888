/**
 * Représente un document arrivant à échéance ou échu
 *
 * @author Laurent Convert
 * @date 28/07/2022
 */
export class DocumentAlerte {
    idDocument: number;
    idReference: number;
    numero: string;
    libelle: string;
    dateEcheance: Date;
    typeFormulaire: TypeFormulaire;
    typeDocument: string;
    reference: string;
}

/**
 * Enumération des types de formulaires
 */
export enum TypeFormulaire {
    VEHICULE = 'VEHICULE',
    PIECE_IDENTITE = 'PIECE_IDENTITE',
    CARTE_ABO_FID = 'CARTE_ABO_FID',
    CARTE_PAIEMENT = 'CARTE_PAIEMENT'
}