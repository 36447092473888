import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {Rule} from "@domain/rule/rule";
import {RuleDetail} from '@domain/rule/rule-detail';
import {TypeRuleOperator} from "@domain/rule/type-rule-operator";
import {RuleBuilderService} from "@share/component/rule-builder/rule-builder.service";
import {ControlContainer,NgForm} from "@angular/forms";

/**
 * Composant du détail d'une Rule
 */
@Component({
	host: {'data-test-id': 'rule-detail'},
	selector: 'rule-detail',
	templateUrl: './rule-detail.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class RuleDetailComponent implements OnInit {
	/** Rule courante */
	_rule?: Rule;

	/** Écoute des changements dans la Rule */
	@Output() ruleChange: EventEmitter<Rule> = new EventEmitter<Rule>();

	/** La Rule */
	@Input()
	get rule() {
		return this._rule;
	}

	set rule(rule: Rule) {
		this._rule = rule;
		this.ruleChange.emit(this._rule);
	}

	/** Entité correspondant à la Rule */
	@Input() entite: string;

	/** Sommes-nous dans le cas d'une règle embarquée */
	@Input() isEmbeddedRule?: boolean;

	/** Est-ce un groupe */
	@Input() isGroup?: boolean;

	/** Interception de la suppression */
	@Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

	/** Import des énums pour le DOM */
	readonly TypeRuleOperator = TypeRuleOperator;
	readonly TypeItem = TypeItem;

	/**
	 * Constructeur
	 *
	 * @param ruleService le service de construction d'une Rule
	 */
	constructor(
		private ruleService: RuleBuilderService
	) {
		//Initialisation de la Rule
		this.rule = this.rule ?? new Rule(this.entite);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Typage des champs de saisie
		this.ruleService.assignFieldType(this.rule);
	}

	/**
	 * Ajout d'un item
	 *
	 * @param type le type d'item RULE ou GROUPE
	 */
	onAddItem(type: TypeItem): void {
		//Initialisation de la liste de details si besoin
		this.rule.listeDetails = this.rule.listeDetails ?? [];

		if (type === TypeItem.GROUPE) {
			//Initialisation d'une Rule
			let rule: Rule = new Rule();

			//Initialisation d'un détail
			let detail: RuleDetail = new RuleDetail();
			detail.rule = rule;

			//Ajout d'un groupe de règle
			this.rule.listeDetails.push(detail);
		} else {
			//Ajout d'un détail
			this.rule.listeDetails.push(new RuleDetail());
		}
	}

	/**
	 * Suppression d'un item
	 */
	onRemoveItem(): void {
		//Vérification du nombre d'observateurs
		if (!this.onDelete.observers.length) {
			//Suppression de la règle
			delete this.rule.listeDetails;
		} else {
			//Notification de la suppression
			this.onDelete.emit();
		}
	}

	/**
	 * Initialisation d'une règle
	 */
	initRule(): void {
		//Ajout d'un détail
		this.rule.listeDetails = [new RuleDetail()];
	}

	/**
	 * Renvoie true si la rule courante est celle mise en surbrillance, false sinon
	 */
	isSelectedRule(): boolean {
		//Appel au service
		return this.ruleService.isSelectedRule(this.rule);
	}

	/**
	 * Mise en surbrillance de la Rule courante
	 */
	selectRule(): void {
		//Appel au service
		this.ruleService.selectRule(this.rule);
	}
}

/**
 * Type d'item à rajouter
 */
enum TypeItem {
	RULE,
	GROUPE
}
