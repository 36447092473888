<h1 mat-dialog-title>
    <span translate="confirmation.title"></span>
</h1>
<div mat-dialog-content>
    <p>
        <span>{{ 'workflow.message.'+libelle+'.'+(data.listeItems?.length > 1 ? 'plural' : 'one') | translate:{ count: data.listeItems?.length } }}</span>
    </p>

    <form autocomplete="off" #workflowActionForm="ngForm">
        <fieldset *ngIf="data.isMotif">
            <div class="row" *ngIf="listeMotifs?.length">
                <div class="col-md-6">
                    <mat-form-field class="d-flex">
                        <mat-label translate="workflow.motif.message"></mat-label>
                        <mat-select name="selectedMotif" [(ngModel)]="selectedMotif" (selectionChange)="onMotifChange($event)">
                            <mat-option value="" translate="global.aucun"></mat-option>
                            <mat-option *ngFor="let motif of listeMotifs" [value]="motif">{{ motif.libelle }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="d-flex">
                        <mat-label [ngClass]="{ 'required': data.isMotifObligatoire }" translate="workflow.motif.saisirMotif"></mat-label>
                        <textarea matInput rows="3" name="motif" [(ngModel)]="motif" [required]="data.isMotifObligatoire"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button class="m-r-10" mat-dialog-close translate="global.actions.annuler"></button>
    <button mat-flat-button color="primary" [mat-dialog-close]="{ isConfirmed: true,idMotif: selectedMotif?.idMotif,motif: motif }" translate="global.actions.confirmer" [disabled]="workflowActionForm.invalid"></button>
</div>