<div class="row line-item-list">
    <div class="col-md-1 avatar">
        <i *ngIf="item?.typeParticipant == 'EXTERNE'" class="material-icons-outlined" [matTooltip]="'od.voyage.participants.externe' | translate">people</i>
        <i *ngIf="item?.typeParticipant == 'INTERNE'" class="icon-profil nio align-middle" [matTooltip]="'od.voyage.participants.interne' | translate" style="font-size: 20px; line-height: inherit"></i>
    </div>
    <div class="col-md-10 body">
        <span class="row">
            <span class="col-md-12">
                {{ options.displayItem(item) }}
            </span>
        </span>
        <span class="row complement">
            <span class="col-md-12" *ngIf="item?.libelleService">
                <strong>{{'od.voyage.service' | translate}}</strong>{{ item.libelleService }}
            </span>
            <span class="col-md-12" *ngIf="item?.libelleSociete">
                <strong>{{'od.voyage.societe' | translate}}</strong>{{ item.libelleSociete }}
            </span>
        </span>
    </div>
    <span class="col-md-1 info">
        <span *ngIf="item?.idUserOrigineFav != null" class="col-md-1 material-icons-outlined">star</span>
    </span>
</div>
