import {Component,EventEmitter,Input,Output} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {TypeCodeErreur} from "@domain/common/http/result";
import {Pays} from "@domain/geographie/pays";
import {TranslateService} from "@ngx-translate/core";
import {DevisesService} from "@services/admin/devises/devises.service";
import {ToastrService} from "ngx-toastr";
import {AlerteComponent} from "@components/workflow/alerte.component";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {filter} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant pour afficher un pays de la liste des pays reliés à la devise sélectionnée
 */
@Component({
    host: {'data-test-id': 'devise-gestion-item-pays'},
    selector: 'devise-gestion-item-pays',
    templateUrl: './devise-gestion-pays-item.component.html'
})
export class DeviseGestionPaysItemComponent {
    /** Pays à afficher */
    @Input() pays: Pays;

    /** Code de la devise sélectionnée */
    @Input() codeDevise: string;

    /** Indique si le pays à afficher est en erreur ou non */
    @Input() erreur: boolean;

    /** Événement émit lorsque l'utilisateur veut supprimer un pays relié à la devise */
    @Output() deletePaysFromDeviseEvent = new EventEmitter<number>();

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param deviseService Service permettant de gérer les devises
     * @param matDialog Boite de dialogue permettant de créer un référentiel
     * @param confirmService Service du popin de confirmation
     */
    constructor(
        protected translateService: TranslateService,
        private toastrService: ToastrService,
        private deviseService: DevisesService,
        private matDialog: MatDialog,
        private confirmService: ConfirmService
    ) {
    }

    /**
     * Méthode appelée lors de la suppression du lien entre le pays et la devise
     */
    deletePays() {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
                //On supprime le lien entre la devise et le pays
                this.deviseService.deleteDeviseFromPays(this.pays.idPays,this.codeDevise).subscribe(res => {
                    //Si le résultat ne comporte pas d'erreur alors on avertit le composant parent et on affiche un message de succès
                    if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Emission d'un message de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        //On émet un signal au composant parent pour dire que la suppression du lien s'est bien passée
                        this.deletePaysFromDeviseEvent.emit(this.pays.idPays);
                    } else {
                        //Si une erreur est survenue lors de la suppression du lien entre le pays et la devise alors on affiche un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                });
            });
    }

    /**
     * Méthode qui permet d'afficher les 3 premiers caractères du nom du pays dans le rond bleu de l'avatar
     */
    displayAvatar() {
        //Récupération de seulement les 3 premiers caractères
        return this.pays.libelle.substring(0, 3 < this.pays.libelle.length ? 3 : this.pays.libelle.length);
    }

    showListeAlertes() {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: [new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    titre: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.alerte.title'),
                    message: this.translateService.instant('admin.bibliotheque.devises.infosDevise.taux.alerte.message')
                })]
            }
        });
    }
}
