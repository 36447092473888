/**
 * Extension pour les pièces jointes
 * @author Lionel GIMENEZ
 * @date 25/09/2023
 */
export class PieceJointeExtension {
    idExtension: number;
    valeur: string;
    origine: OrigineExtension;
    demat: boolean;

    getKey(): number {
        return this.idExtension;
    }
}

/**
 * Enum pour les origines de l'extension
 */
export enum OrigineExtension {
    STANDARD = "STANDARD",
    ADMINISTRATEUR = "ADMINISTRATEUR"
}