/**
 * Représente les différents statuts d'archivage des lots comptable
 */
export enum StatutArchivage {
    EN_ATTENTE = 'EN_ATTENTE',
    SYNCHRONISE = 'SYNCHRONISE',
    ECHEC = 'ECHEC',
    PARTIEL = 'PARTIEL'
}

/**
 * Récupère l'icône du statut d'archivage du lot comptable
 *
 * @param statutArchivage statut d'archivage du lot comptable
 */
 export function getStatutArchivageIcon(statutArchivage: string): string {
    switch (statutArchivage) {
        case StatutArchivage.EN_ATTENTE:
            return 'cloud_upload';
        case StatutArchivage.SYNCHRONISE:
            return 'cloud_done';
        case StatutArchivage.ECHEC:
            return 'cloud_off';
        case StatutArchivage.PARTIEL:
            return 'cloud';
        default:
            return null;
    }
}

/**
 * Récupère la couleur de l'icône du statut d'archivage du lot comptable.
 *
 * @param statutArchivage statut d'archivage du lot comptable.
 */
export function getDematIconClass(statutArchivage: string): string {
    switch (statutArchivage) {
        case StatutArchivage.PARTIEL:
            return 'c-warning';
        case StatutArchivage.ECHEC:
            return 'c-danger';
        case StatutArchivage.EN_ATTENTE:
            return 'c-info';
        case StatutArchivage.SYNCHRONISE:
            return 'c-success';
        default:
            return '';
    }
}
