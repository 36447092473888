import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Représente une configuration TravelHub
 */
export class TravelHubConfig implements ListItem {
	/** identifiant de la configuration */
	idConfig: number;

	/** Libellé de la configuration */
	libelle: string;

	/** Code client transmis au TravelHub */
	codeClient: string;

	/** Mot de passe pour la connexion au TravelHub. Contient des "*" lors du chargement d'une configuration existante */
	password: string;

	/** URL du TravelHub */
	url: string;

	/** Flag utilisé pour indiquer que la valeur a été modifiée par l'utilisateur */
	passwordChanged: boolean;

	/**
	 * Constructeur
	 * @param data Données en provenance du back
	 */
	constructor(data?: any | TravelHubConfig) {
		//Vérification du passage d'un DTO ou d'un objet
		if (data) {
			//Copie
			Object.assign(this,data);
		}
	}

	/**
	 * Récupération de l'identifiant de l'objet
	 */
	getKey(): number {
		return this.idConfig;
	}
}