<fieldset ngModelGroup="distance-generalites">
    <!-- Cadre Généralités -->
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.distance.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <!-- Libellé -->
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="distance.libelle"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.distance.libelle">
                    </custom-input>
                    <!-- Distance -->
                    <custom-input ngDefaultControl
                                  name="distance"
                                  customType="distance"
                                  nbDecimales="2"
                                  required="true"
                                  libelle="admin.bibliotheque.geographie.distance.distance"
                                  [(ngModel)]="distance.distance"
                                  [selectedUnite]="distance.unite"
                                  [listeUnites]="listeUnites"
                                  (onUniteChange)="onUniteChange($event)"
                    ></custom-input>
                </div>
                <div class="row">
                    <!-- Départ -->
                    <label class="col-md-2 required" [ngClass]="{ 'has-error': departModel.invalid }">
                        <span [translate]="'admin.bibliotheque.geographie.distance.depart'"></span>
                    </label>
                    <mat-form-field class="col-md-4 d-flex clickable" (click)="selectLieu(true);">
                        <input type="text"
                               class="clickable"
                               readonly
                               matInput
                               #departInput="matInput"
                               [required]="true"
                               #departModel="ngModel"
                               [(ngModel)]="depart.libelle"
                               name="depart"
                               id="depart"
                        />
                        <mat-icon class="align-middle ml-1" matSuffix>search</mat-icon>
                    </mat-form-field>

                    <!-- Arrivée -->
                    <label class="col-md-2 required" [ngClass]="{ 'has-error': arriveeModel.invalid }">
                        <span [translate]="'admin.bibliotheque.geographie.distance.arrivee'"></span>
                    </label>
                    <mat-form-field class="col-md-4 d-flex clickable" (click)="selectLieu(false);">
                        <input type="text"
                               class="clickable"
                               readonly
                               matInput
                               #arriveeInput="matInput"
                               [required]="true"
                               #arriveeModel="ngModel"
                               [(ngModel)]="arrivee.libelle"
                               name="arrivee"
                               id="arrivee"
                        />
                        <mat-icon class="align-middle ml-1" matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>

    <!-- Cadre Propriétaires -->
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.distance.proprietaire.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="listview">
                <div class="listview-body">
                    <div class="listview-item">
                        <div class="lvi-content">
                            <div class="avatar">
                                <i class="nio icon-collaborateurs"></i>
                            </div>
                            <div class="lvi-body">
                                <div class="title">
                                    <ng-container [ngSwitch]="distance.origine">
                                        <ng-container *ngSwitchCase="Origine.ORIGINE_USER">
                                            <a class="label">{{ (distance.user?.nom?.toUpperCase() + ' ' + distance.user?.prenom).trim() }}</a>
                                            <ul class="lvi-attrs">
                                                <li *ngIf="distance.user?.matricule"><strong
                                                        [translate]="'admin.bibliotheque.geographie.distance.proprietaire.matricule'"></strong>{{ distance.user?.matricule }}
                                                </li>
                                            </ul>
                                        </ng-container>
                                        <a *ngSwitchCase="Origine.ORIGINE_IMPORT" class="label"
                                           [translate]="'admin.bibliotheque.geographie.distance.proprietaire.import'"></a>
                                        <a *ngSwitchDefault class="label"
                                           [translate]="'admin.bibliotheque.geographie.distance.proprietaire.administrateur'"></a>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</fieldset>