import {Injectable} from '@angular/core';
import {PieceJointeParam,SelectOptionType} from "@domain/admin/parametre/demat/piece-jointe-param";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

/**
 * Service lié aux paramètres de la portée
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Injectable()
export class ParamPorteeService {
	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 */
	constructor(
		private translateService: TranslateService,
		private toastrService: ToastrService) {
	}

	/**
	 * Applique au modèle type les valeurs correspondant au type selon la sélection de l'user
	 *
	 * @param pieceJointeParam la pièce jointe param en cours
	 * @param selectionOptionType la sélection de l'user
	 */
	setTypePieceJointeBySelection(pieceJointeParam: PieceJointeParam,selectionOptionType: SelectOptionType): void {
		switch (selectionOptionType) {
			case SelectOptionType.ACTIF:
				pieceJointeParam.type = true;
				pieceJointeParam.typeObligatoire = false;
				break;
			case SelectOptionType.ACTIF_OBLIGATOIRE:
				pieceJointeParam.type = true;
				pieceJointeParam.typeObligatoire = true;
				break;
			case SelectOptionType.DESACTIVE:
				pieceJointeParam.type = false;
				pieceJointeParam.typeObligatoire = false;
				break;
			default:
				this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
		}
	}

	/**
	 * Applique au modèle libellé les valeurs correspondant au type selon la sélection de l'user
	 *
	 * @param pieceJointeParam la pièce jointe param en cours
	 * @param selectionOptionType la sélection de l'user
	 */
	setLibellePieceJointeBySelection(pieceJointeParam: PieceJointeParam,selectionOptionType: SelectOptionType): void {
		switch (selectionOptionType) {
			case SelectOptionType.ACTIF:
				pieceJointeParam.libelle = true;
				pieceJointeParam.libelleObligatoire = false;
				break;
			case SelectOptionType.ACTIF_OBLIGATOIRE:
				pieceJointeParam.libelle = true;
				pieceJointeParam.libelleObligatoire = true;
				break;
			case SelectOptionType.DESACTIVE:
				pieceJointeParam.libelle = false;
				pieceJointeParam.libelleObligatoire = false;
				break;
			default:
				this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
		}
	}

	/**
	 * Retourne un choix de la liste selon le paramétrage
	 *
	 * @param isActif actif
	 * @param isObligatoire obligatoire
	 * @return le type de selection
	 */
	getOption(isActif: boolean,isObligatoire: boolean): SelectOptionType {
		//L'affichage de la liste dépend de deux paramétrages
		if (isActif && isObligatoire) {
			return SelectOptionType.ACTIF_OBLIGATOIRE;
		} else if (isActif) {
			return SelectOptionType.ACTIF;
		} else {
			return SelectOptionType.DESACTIVE;
		}
	}
}