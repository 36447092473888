/**
 * Énumération des différents types de distance (unités)
 *
 * @author Laurent Convert
 * @date 25/04/2023
 */
export enum TypesDistances {
	KM = 'Km',
	MILES = 'Miles'
}
