<h1 mat-dialog-title>
    <span translate>od.frais.frais.creation.choix.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl name="objet" customType="input" libelle="ndf.trajet.nomDistance"
                    [(ngModel)]="data.libelle"
                    lCol="6" rCol="6"
            >
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button class="m-r-10" [mat-dialog-close]="null">
        {{ "global.actions.annuler" | translate }}
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="data.libelle">
        {{ "global.actions.confirmer" | translate }}
    </button>
</div>