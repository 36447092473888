<mat-card>
    <mat-card-title>
        <span [translate]="'avance.historique.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <avance-historique-list-item [avance]="avance" [historique]="histo" [isFirst]="isFirst" [settings]="settings" *ngFor="let histo of avance.listeAvanceChainage; first as isFirst"></avance-historique-list-item>
            </div>
        </div>
    </mat-card-content>
</mat-card>