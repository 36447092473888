<h1 mat-dialog-title>
    <span [translate]="'profilUser.infoContact.add.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="py-1">
    <form autocomplete="off" #addContactUrgence="ngForm">
        <fieldset>
            <div class="row">
                <custom-input [(ngModel)]="data.telephone.type"
                              [selectOptions]="listeTypeTelephone"
                              (onChange)="data.telephone.type === typeContactEnum.CONTACT_URGENCE ? installListeners() : removeListeners()"
                              customType="select"
                              libelle="profilUser.infoContact.add.type.libelle"
                              name="typeTelephone"
                              ngDefaultControl
                              optionDisplay="libelle"
                              optionValue="type"
                              [required]="true">
                </custom-input>
                <div *ngIf="data.telephone.type === typeContactEnum.CONTACT_URGENCE" class="col-md-6 row" style="padding: 0px; margin: 0px">
                    <label class="col-md-4 required"
                        [ngClass]="{ 'has-error': !isNumeroEditable && addContactUrgence.controls['telephoneObfusque']?.invalid || isNumeroChanged && addContactUrgence.controls['phone']?.invalid }"
                        [translate]="'profilUser.infoContact.add.numero'">
                    </label>
                    <!-- Input qui affiche le numéro obfusqué, non saisissable. Quand il reçoit le focus, il se cache pour laisser apparaitre le composant ngx-mat-intl-tel-input -->
                    <custom-input [hidden]="isNumeroEditable"
                        ngDefaultControl
                        [ngModel]="data.telephone.numeroBrutObfusque"
                        id="telephoneObfusque"
                        name="telephoneObfusque"
                        rCol="8"
                        [required]="true">
                    </custom-input>

                    <!-- Input qui permet la saisie et la validation d'un numéro de téléphone. Quand il perd le focus, si l'utilisateur n'a pas modifié le numéro, il se cache pour re-laisser apparaitre l'input ci-dessus -->
                    <mat-form-field class="col-md-8" [hidden]="!isNumeroEditable" [ngClass]="{ 'mat-form-field-invalid': addContactUrgence.controls['phone']?.invalid }">
                        <ngx-mat-intl-tel-input id="idTelephone" #telephone [(ngModel)]="data.telephone.numeroBrut"
                            tabindex="0" [enablePlaceholder]="false" [enableSearch]="true" [preferredCountries]="['fr']"
                            [required]="true" (input)="onTelephoneChanged()" format="national" name="phone">
                        </ngx-mat-intl-tel-input>
                    </mat-form-field>
                </div>
                <div *ngIf="data.telephone.type === typeContactEnum.EMAIL_ASSISTANT" class="col-md-6 row" style="padding: 0px; margin: 0px">
                    <label class="col-md-4 required" [ngClass]="{ 'has-error': addContactUrgence.controls['email']?.invalid }" [translate]="'profilUser.infoContact.email'"></label>

                    <mat-form-field class="col-md-8">
                        <input [(ngModel)]="data.telephone.email" email matInput name="email" required type="email" autocomplete="off" aria-autocomplete="none" />
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="data.telephone.type === typeContactEnum.CONTACT_URGENCE" class="row">
                <custom-input
                        [(ngModel)]="data.telephone.nom"
                        (onChange)="data.telephone.nomChanged = true"
                        customType="input-obfuscated"
                        libelle="profilUser.infoContact.nom"
                        name="nomContact"
                        ngDefaultControl
                        [required]="true">
                </custom-input>
                <custom-input
                        [(ngModel)]="data.telephone.prenom"
                        (onChange)="data.telephone.prenomChanged = true"
                        customType="input-obfuscated"
                        libelle="profilUser.infoContact.prenom"
                        name="prenomContact"
                        ngDefaultControl
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <label [translate]="'profilUser.infoContact.add.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [(ngModel)]="data.telephone.actif" name="actif" color="primary"></mat-checkbox>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="deleteTelephone()" *ngIf="this.data.telephone.idTelephone != 0" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="save()" [disabled]="addContactUrgence.invalid || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="data.telephone.idTelephone == 0 ?'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
