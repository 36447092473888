<h1 mat-dialog-title>
    <span [translate]="'delegations.form.' + (isModif ? 'titleModif' : 'titleCrea')"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <fieldset>
            <div class="row">
                <!-- Rôle -->
                <custom-input
                        ngDefaultControl
                        id="role"
                        name="role"
                        customType="select"
                        [(ngModel)]="data.deleg.idRole"
                        [selectOptions]="listeRolesDelegables"
                        libelle="delegations.form.role"
                        optionValue="idRole"
                        optionDisplay="libelle"
                        (onChange)="onRoleChange()"
                        [disabled]="isSaving || isDeleting"
                        [readonly]="data.deleg.used || data.isFromAdmin"
                        [required]="true">
                </custom-input>
                <!-- Date début -->
                <custom-input
                        ngDefaultControl
                        id="dateDebut"
                        name="dateDebut"
                        customType="date"
                        [ngModel]="data.deleg.dateDebut"
                        [(customModel)]="data.deleg.dateDebut"
                        libelle="delegations.form.du"
                        [dateMin]="!isModif && aujourdhui"
                        [dateMax]="data.deleg.dateFin ? data.deleg.dateFin : null"
                        [disabled]="isSaving || isDeleting"
                        [readonly]="data.deleg.used"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Population -->
                <custom-input
                        ngDefaultControl
                        id="population"
                        name="population"
                        customType="select"
                        [(ngModel)]="data.deleg.idPopulation"
                        [selectOptions]="listePopulations"
                        libelle="delegations.form.population"
                        optionValue="idPopulation"
                        optionDisplay="libelle"
                        (onChange)="onPopulationChange()"
                        [disabled]="!data.deleg.idRole || isSaving || isDeleting"
                        [readonly]="data.deleg.used"
                        [required]="true">
                </custom-input>
                <!-- Date fin -->
                <custom-input
                        ngDefaultControl
                        id="dateFin"
                        name="dateFin"
                        customType="date"
                        [ngModel]="data.deleg.dateFin"
                        [(customModel)]="data.deleg.dateFin"
                        libelle="delegations.form.au"
                        [dateMin]="moment(data.deleg.dateDebut).isAfter(aujourdhui) ? data.deleg.dateDebut : aujourdhui"
                        [dateMax]="data.deleg.used ? dateFinInitiale : null"
                        [disabled]="isSaving || isDeleting"
                        [readonly]="moment(data.deleg.dateFin).isBefore(aujourdhui,'day')"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Délégué -->
                <custom-input
                        ngDefaultControl
                        id="delegue"
                        name="delegue"
                        customType="autocomplete"
                        [(ngModel)]="data.deleg.user"
                        libelle="delegations.form.delegue"
                        autocompleteType="delegue"
                        [autocompleteFilter]="{ idRole: data.deleg.idRole, idUserDelegant: data.deleg.idUserDelegant, dateDebut: data.deleg.dateDebut, dateFin: data.deleg.dateFin }"
                        idName="idUser"
                        optionDisplay="matricule"
                        [disabled]="!data.deleg.population || !data.deleg.dateDebut || !data.deleg.dateFin || isSaving || isDeleting"
                        [readonly]="data.deleg.used"
                        [required]="true">
                </custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="warn" *ngIf="isModif" (click)="onDelete()" [disabled]="isSaving || isDeleting || data.deleg.used">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" type="submit" (click)="onSave()" [disabled]="form.invalid || !data.deleg.user?.idUser || isSaving || isDeleting || (data.deleg.used && moment(data.deleg.dateFin).isBefore(aujourdhui,'day'))">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>