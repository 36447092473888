<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'info': data.type == typeMessage.INFORMATION, 'success': data.type == typeMessage.CONFIRMATION, 'warning': data.type == typeMessage.AVERTISSEMENT, 'danger': data.type == typeMessage.ALERTE }">
        <i *ngIf="data.type == typeMessage.INFORMATION" class="material-icons-outlined">info</i>
        <i *ngIf="data.type == typeMessage.CONFIRMATION" class="material-icons-outlined">check_circle</i>
        <i *ngIf="data.type == typeMessage.AVERTISSEMENT || data.type == typeMessage.ALERTE" class="material-icons-outlined">report_problem</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="dashboard-list-title" (click)="showMessage(data)"><span>{{ data.objet }}</span></a>
        </div>
    </div>
</div>