import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';

import {Result} from '@domain/common/http/result';
import {ReferentielExterneAddComponent} from './referentiel-externe-add.component';
import {ReferentielExterneService} from './referentiel-externe.service';
import {Session} from "@domain/security/session";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypePortee} from "@domain/workflow/workflow";

@Component({
	selector: 'referentiel-externe',
	templateUrl: './referentiel-externe.component.html'
})
export class ReferentielExterneComponent implements OnInit {
	/** Type de référentiel */
	@Input() typeReferentiel: TypePortee | 'D' | 'V';

	/** Identifiant de l'objet */
	@Input() idObject: number;

	/** Nombre d'éléments parents */
	public nombreElementsParents: number;

	/** Liste du référentiel externe */
	public listeRefExternes: Array<any>;

	/** Liste des anciennes référence d'une référence */
	public mapRefFils: Map<number,Array<any>>;

	/** Statut du chargement */
	public isLoading: boolean = true;

	/** Indique la connexion en tant qu'admin */
	public isAdmin: boolean;

	/**
	 * Constructeur
	 */
	constructor(private dialog: MatDialog,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private referentielExterneService: ReferentielExterneService,
				private store: Store<AppState>) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement des données
		this.loadData();

		//Sélection de la session
		this.store.select<Session>(s => s.session).subscribe(session => {
				//Mémorisation de la session
				this.isAdmin = session.isAdmin;
			}
		)
	}

	/**
	 * Chargement des données
	 */
	loadData() {
		//Définition du chargement
		this.isLoading = true;

		//Chargement du référentiel externe
		this.referentielExterneService.loadReferentiel(this.typeReferentiel,this.idObject).pipe(take(1)).subscribe({
			next: (result: Result) => {
				//Vérification du code erreur
				if (result?.codeErreur == 0) {
					//Définition de la liste des référentiels externes
					this.listeRefExternes = result.data.listeRefExternes;

					//Définition des anciennes références
					this.mapRefFils = result.data.mapRefFils;

					//Définition du nombre d'éléments parents
					this.nombreElementsParents = result.data.nombreElementsParents;
				}
			},
			complete: () => {
				//Définition du chargement
				this.isLoading = false;
			}
		});
	}

	/**
	 * Ajout d'une référence au référentiel externe
	 */
	addReferenceExterne() {
		//Affichage de la popup
		this.dialog.open(ReferentielExterneAddComponent,{
			data: {
				referenceExterne: {
					type: this.typeReferentiel
				}
			}
		}).afterClosed().subscribe({
			next: (item) => {
				//Vérification de l'élément sélectionné
				if (item) {
					//Enregistrement
					this.saveReferenceExterne(item);
				}
			}
		});
	}

	/**
	 * Enregistrement de la référence externe
	 */
	saveReferenceExterne(referenceExterne: any) {
		//Enregistrement de la référence externe
		this.referentielExterneService.saveReference(this.idObject,referenceExterne).subscribe({
			next: (result: Result) => {
				//Vérification du code erreur
				if (result?.codeErreur == 0) {
					//Affichage d'un message de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Rafraichissement de la liste
					this.loadData();
				} else
					//Affichage d'un message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
			},
			error: () => {
				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
			}
		});
	}
}
