import {Component,OnInit} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {TranslateService} from "@ngx-translate/core";
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant géographie
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'geographie'},
	selector: 'geographie',
	templateUrl: './geographie.component.html',
	providers: [GeographieService]
})
export class GeographieComponent implements OnInit {

	/** Liste des différents onglets disponibles dans le menu "Géographie" */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet courant */
	selectedItem: PageHeaderItem = {
		code: Onglets.TERRITOIRES,
		libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.territoires'),
		selected: true
	};

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param geographieService	Service geographie
	 */
	constructor(private translateService: TranslateService,
				private geographieService: GeographieService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition des onglets
		this.listeTabItems = [{
			code: Onglets.TERRITOIRES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.territoires')
		}, {
			code: Onglets.ZONES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.zones')
		}, {
			code: Onglets.PAYS,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.pays')
		}, {
			code: Onglets.REGIONS,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.regions')
		}, {
			code: Onglets.DEPARTEMENTS,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.departements')
		}, {
			code: Onglets.VILLES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.villes')
		}, {
			code: Onglets.ADRESSES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.adresses')
		}, {
			code: Onglets.DISTANCES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.distances')
		}, {
			code: Onglets.IATA,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.iata')
		}, {
			code: Onglets.GEOLOCALISATION,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.onglets.geolocalisation'),
			url: 'Admin/Bibliotheque/Geographie/Geolocalisation'
		}];
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Indique si on est dans le détail d'un objet
	 */
	get isInDetail() {
		return this.geographieService.isShowDetail;
	}

}

/**
 * Enum des différents onglets de la page Géographie
 */
export enum Onglets {
	TERRITOIRES = "Territoires",
	ZONES = "Zones",
	PAYS = "Pays",
	REGIONS = "Regions",
	DEPARTEMENTS = "Departements",
	VILLES = 'Villes',
	ADRESSES = "Adresses",
	DISTANCES = "Distances",
	GEOLOCALISATION = "Geolocalisation",
	IATA = "IATA"
}

/**
 * Enum des portées d'un territoire
 */
export enum PorteeTerritoireCode {
	IND = "IND",
	OMP = "OMP",
	TAX = "TAX"
}
