/**
 * Règle d'attribution pour les indemnités journalières
 */
export class RegleAttribution {
    /** Données */
    id: number;
    libelle: string;
    heureDeb: string;
    heureFin: string;
    type: number;
    visibilite: string;
    dureeMin: number;
    dureeMax: number;
    modeGestion: TypeModeGestion;
    isActif: boolean;

    /**
     * Constructeur
     *
     * @param regleAttribution Données
     */
    constructor(regleAttribution?: RegleAttribution) {
        Object.assign(this,regleAttribution);
    }
}

/**
 * Enumeration des modes de gestion
 */
export enum TypeModeGestion {
    UNE_FOIS_PAR_JOUR,
    TEMPS_PASSE,
    TRANCHE_HORAIRE
}