import {AutocompleteOptions} from '../autocomplete';
import {Role} from "@domain/workflow/role";
import {TranslateService} from '@ngx-translate/core';
import {Filter,TypeComparaison} from '@domain/common/list-view'
import {TypeProfil} from "@domain/user/user";
import {SearchType} from "@domain/common/list-view/sorting";
import {RoleSearchItemComponent} from "@share/component/autocomplete/options/role-search-item.component";

/**
 * Options des rôles
 */
export default class RoleOptions implements AutocompleteOptions {
	/**
	 * Définition de l'URL
	 */
	url(filter: any): string {
		return `/controller/Role/getRolesForAutocomplete?isCollab=${filter.isCollab}&fonction=${filter.fonction}`;
	}

	/**
	 * Affichage d'un élément
	 *
	 * @param item le rôle
	 */
	displayItem(item: Role): string {
		return item ? item.libelle : null;
	}

	/**
	 * Composant d'affichage d'une ligne dans la popup de recherche
	 */
	searchItemComponent() {
		return RoleSearchItemComponent;
	}

	/**
	 * Filtres disponibles
	 *
	 * @param translateService le moteur de traduction
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		//Liste des filtres
		return [
			{
				title: translateService.instant('filter.code'),
				clef: 'code',
				isDefault: true
			},{
				title: translateService.instant('filter.libelle'),
				clef: 'libelle',
				isDefault: true
			},{
				title: translateService.instant('filter.profil'),
				clef: 'fonction',
				typeComparaison: TypeComparaison[TypeComparaison.LIKE],
				listeValues: [
					{
						code: '' + TypeProfil.COMPTABLE,
						libelle: TypeProfil.getLibelle(translateService,TypeProfil.COMPTABLE)
					},{
						code: '' + TypeProfil.RESPONSABLE,
						libelle: TypeProfil.getLibelle(translateService,TypeProfil.RESPONSABLE)
					},{
						code: '' + TypeProfil.COLLABORATEUR,
						libelle: TypeProfil.getLibelle(translateService,TypeProfil.COLLABORATEUR)
					},{
						code: '' + TypeProfil.ASSISTANT,
						libelle: TypeProfil.getLibelle(translateService,TypeProfil.ASSISTANT)
					},{
						code: '' + TypeProfil.SOUS_ADMINISTRATEUR,
						libelle: TypeProfil.getLibelle(translateService,TypeProfil.SOUS_ADMINISTRATEUR)
					},{
						code: '' + TypeProfil.FOURNISSEUR,
						libelle: TypeProfil.getLibelle(translateService,TypeProfil.FOURNISSEUR)
					}
				]
			}
		];
	}

	/**
	 * Ordre de tri des résultats
	 */
	getOrderBy(): string {
		return "libelle";
	}

	/** Mode de recherche */
	searchType = SearchType.CONTAINS;
}