<h1 mat-dialog-title>
    <span>
        {{ 'admin.maintenance.migrations.onglets.historique.detailMigration' | translate }}
        {{ data.migration.origine }} ({{ data.migration.origineBuild }})
        <mat-icon>trending_flat</mat-icon>
        {{ data.migration.cible }} ({{ data.migration.cibleBuild }})
        | {{ data.migration.date | date: 'shortDate' }} {{ data.migration.date | date: 'shortTime' }}
    </span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="popup-content">
    <please-wait *ngIf="!migration"></please-wait>
    <div *ngIf="migration">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="onSelectedTabChange($event)">
            <mat-tab *ngFor="let item of listeTabItems" [label]="item.libelle"></mat-tab>
        </mat-tab-group>
        <list-view *ngIf="listePreMigration" [hidden]="selectedItem?.code != Onglets.PRE_MIGRATION" [liste]="listePreMigration"></list-view>
        <list-view *ngIf="listeBddUpgrade" [hidden]="selectedItem?.code != Onglets.MIGRATION" [liste]="listeBddUpgrade"></list-view>
        <list-view *ngIf="listePostMigration" [hidden]="selectedItem?.code != Onglets.POST_MIGRATION" [liste]="listePostMigration"></list-view>
    </div>
</div>
