<fieldset ngModelGroup="pays-generalites">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.pays.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <label class="col-md-2 required" [class.has-error]="!pays.libelle" [class.text-muted]="!isCreation" [class.text-secondary]="!isCreation">
                        <span [translate]="'admin.bibliotheque.geographie.territoire.libelle'"></span>
                    </label>
                    <div class="col-md-4">
                        <mat-form-field class="d-flex">
                            <span matPrefix *ngIf="!isCreation && pays.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-' + pays.code2.toLowerCase()"></span>
                            <input matInput class="mr-1 text-secondary text-dark" name="libelle" [(ngModel)]="pays.libelle" required [disabled]="!isCreation" autocomplete="off" aria-autocomplete="none"/>
                        </mat-form-field>
                    </div>
                    <custom-input ngDefaultControl
                                  id="actif"
                                  name="actif"
                                  [ngModel]="pays.actif"
                                  [(customModel)]="pays.actif"
                                  customType="checkbox"
                                  libelle="admin.bibliotheque.geographie.territoire.actif">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="zone"
                                  name="zone"
                                  [idName]="'id'"
                                  optionValue="id"
                                  [(ngModel)]="selectedZone"
                                  libelle="admin.bibliotheque.geographie.pays.zone"
                                  (onChange)="onZoneChange();"
                                  optionDisplay="libelle"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  [autocompleteFilter]="{ listeTypes: ['ZONE'] }">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="libelleExterne"
                                  name="libelleExterne"
                                  [ngModel]="pays.libelleNettoye"
                                  [(customModel)]="pays.libelleNettoye"
                                  customType="input"
                                  [disabled]="true"
                                  libelle="admin.bibliotheque.geographie.pays.libelleExterne">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  name="devise"
                                  id="devise"
                                  [idName]="'code'"
                                  optionValue="code"
                                  [(ngModel)]="selectedDevise"
                                  libelle="admin.bibliotheque.geographie.pays.devise"
                                  (onChange)="onDeviseChange();"
                                  optionDisplay="code"
                                  customType="autocomplete"
                                  autocompleteType="devise"
                                  [required]="true"
                                  [autocompleteFilter]="{}">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="code2"
                                  name="code2"
                                  [(ngModel)]="pays.code2"
                                  maxlength="2"
                                  customType="input"
                                  [disabled]="!isCreation"
                                  [required]="true"
                                  [postTooltip]="'admin.bibliotheque.geographie.pays.code2Tooltip' | translate"
                                  libelle="admin.bibliotheque.geographie.pays.codeAlpha2">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="codeTVA"
                                  name="codeTVA"
                                  [(ngModel)]="pays.nomTVA"
                                  customType="input"
                                  [maxlength]="5"
                                  [required]="true"
                                  libelle="admin.bibliotheque.geographie.pays.codeTVA">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="code3"
                                  name="code3"
                                  [(ngModel)]="pays.code3"
                                  maxlength="3"
                                  customType="input"
                                  [disabled]="!isCreation"
                                  [required]="true"
                                  libelle="admin.bibliotheque.geographie.pays.codeAlpha3">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="risque"
                                  name="risque"
                                  [(ngModel)]="pays.risque"
                                  customType="number"
                                  [nbDecimales]="0"
                                  libelle="admin.bibliotheque.geographie.pays.nvRisque">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="id"
                                  name="id"
                                  [(ngModel)]="pays.idPays"
                                  customType="number"
                                  [disabled]="!isCreation"
                                  [required]="true"
                                  min="0"
                                  libelle="admin.bibliotheque.geographie.pays.codeNum">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
