/**
 * Type représentant une action
 */
export type Action = {
    icon: string,
    onPress: () => void,
    isVisible?: () => boolean,
    disabled?: boolean,
    tooltip?: string
}

/**
 * Filtre les actions dont la visibilité est à True ou non défini
 * @param action Une actions à filtrer
 */
export function filterActionVisible(action: Action): boolean {
    //Vérification de la condition de visibilité.
    //Si pas de condition définie, l'action est disponible par défaut
    return (typeof action.isVisible === 'function' ? action.isVisible() : true);
}