<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-voiture align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.openPuissance(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.unite">
                <strong [translate]="'admin.entreprise.vehicules.puissances.unite'"></strong>
                <span>{{ data.unite.libelle }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deletePuissance()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>