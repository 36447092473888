<h1 mat-dialog-title>
    <i *ngIf="typeLieuSelected !== null" class="nio icon-transfere clickable" (click)="goBack()"></i>
    <span [translate]="data.isDepart ? 'admin.bibliotheque.geographie.distance.lieu.depart' : 'admin.bibliotheque.geographie.distance.lieu.arrivee'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div [hidden]="typeLieuSelected !== null" class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let typeLieu of listeTypeLieu">
                <div class="list-view-item-hover">
                    <div class="lvi-content clickable" (click)="selectTypeLieu(typeLieu.id)">
                        <div class="avatar">{{ typeLieu.libelle.substring(0, 3) }}</div>
                        <div class="lvi-body">
                            <div class="title">
                                <a>{{ typeLieu.libelle }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--  Formulaire autocomplete géographie ou adresse  -->
    <form [hidden]="typeLieuSelected == null" autocomplete="off">
        <fieldset>
            <div class="row" [ngClass]="{ 'has-error': geographie.invalid || adresse.invalid }">
                <label class="col-md-4 required"><span [translate]="TypeLieuDistance.traduction(typeLieuSelected)"></span></label>
                <div class="col-md-8">
                    <autocomplete [hidden]="typeLieuSelected ? typeLieuSelected === TypeLieuDistance.LOCALISATION : true"
                                  type="geographie" name="geographie" #geographie="ngModel"
                                  (onSelect)="setGeographie($event)"
                                  [filter]="{ listeTypes: getAutoCompleteFilter(typeLieuSelected) }"
                                  [(ngModel)]="selectedGeographie"
                                  ngDefaultControl
                                  [label]="'global.input.rechercher' | translate"
                                  required="true">
                    </autocomplete>
                    <autocomplete [hidden]="typeLieuSelected ? typeLieuSelected !== TypeLieuDistance.LOCALISATION : true"
                                  type="adresse" name="adresse" #adresse="ngModel"
                                  (onSelect)="setAdresse($event)"
                                  [(ngModel)]="selectedAdresse"
                                  ngDefaultControl
                                  [label]="'global.input.rechercher' | translate"
                                  required="true">
                    </autocomplete>
                </div>
            </div>
        </fieldset>
    </form>
</div>

<div [hidden]="typeLieuSelected == null" mat-dialog-actions align="end">
    <button mat-flat-button color="primary"
            [disabled]="!selectedGeographie?.id && !selectedAdresse?.idAdresse" (click)="validateLieu()">
        <span [translate]="'admin.bibliotheque.geographie.distance.lieu.selectionner'"></span>
    </button>
</div>