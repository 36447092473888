import {Routes} from "@angular/router";
import {FrameRouteComponent} from "@share/layout/frame-route.component";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Fichier prévisionnel pour le futur composant V10
 * TODO: Appliquer les évolutions appropriées le moment venu
 */

/**
 * Droits de routage correspondant
 * TODO: Adapter, déplacer ou supprimer en fonction du besoin pour la page
 */
export const fraisIndemnitesRoutes : Routes = [
    {
        path: 'Indemnites',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: 'ReglesAttribution',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: 'Paramètres',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IJ_GESTION] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Indemnites'
    }, {
        path: '**',
        redirectTo: 'Indemnites'
    }
];
