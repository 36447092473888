<!-- listeBoucle ne contient rien, on l'utilise uniquement pour gérer les indexes -->
<ng-template ngFor [ngForOf]="listeBoucle">
    <div class="row">
        <ng-template #holderGauche></ng-template>
        <ng-template #holderDroite></ng-template>
    </div>
</ng-template>

<!-- Template vide pour laisser un trou si besoin -->
<ng-template #empty>
    <div class="col-md-6"></div>
</ng-template>
