import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {TravelhubComponent} from "./travelhub.component";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {TravelhubConfigListItemComponent} from "@components/admin/voyages/travelhub/travelhub/travelhub-config-list-item/travelhub-config-list-item.component";
import {TravelhubConfigAddComponent} from "@components/admin/voyages/travelhub/travelhub/travelhub-config-add/travelhub-config-add.component";

/**
 * Module de l'onglet "Travel Hub" de l'administration du module Voyages / TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@NgModule({
	imports: [ShareModule],
	declarations: [TravelhubComponent,TravelhubConfigListItemComponent,TravelhubConfigAddComponent],
	exports: [TravelhubComponent,TravelhubConfigListItemComponent,TravelhubConfigAddComponent],
	providers: [AdminTravelhubService]
})
export class TravelhubModule {}