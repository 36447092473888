<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.referentiels.referentielUsageUser.nom'"></strong>
                <span>{{ data.nom }}</span>
            </li>
            <li *ngIf="data.prenom">
                <strong [translate]="'admin.entreprise.referentiels.referentielUsageUser.prenom'"></strong>
                <span>{{ data.prenom }}</span>
            </li>
            <li *ngIf="data.matricule">
                <strong [translate]="'admin.entreprise.referentiels.referentielUsageUser.matricule'"></strong>
                <span>{{ data.matricule }}</span>
            </li>
        </ul>
    </div>
</div>
