<div mat-dialog-content>
    <form autocomplete="off" #participantForm="ngForm">
        <fieldset>

            <div class="row" *ngFor="let champ of listeChamps; let i = index; let even = even">
                <ng-container *ngIf="even">
                    <ng-container *ngTemplateOutlet="champ"></ng-container>
                    <ng-container *ngIf="listeChamps[i + 1]">
                        <ng-container *ngTemplateOutlet="listeChamps[i + 1]"></ng-container>
                    </ng-container>
                </ng-container>
            </div>

            <!-- Templates affichables pour la création d'un participant externe
                 L'affichage est géré par le controller via listeChamps -->
            <ng-template #templateNom>
                <label class="col-md-2" [ngClass]="{ 'has-error': nom.invalid, 'required': isFieldRequired(nomParametreParId.NOM) }">
                    <span>{{ getLibelle(nomParametreParId.NOM) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': nom.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="nom" matInput #nom="ngModel" [(ngModel)]="participantExterne.nom"
                               [required]="isFieldRequired(nomParametreParId.NOM)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templatePrenom>
                <label class="col-md-2" [ngClass]="{ 'has-error': prenom.invalid, 'required': isFieldRequired(nomParametreParId.PRENOM) }">
                    <span>{{ getLibelle(nomParametreParId.PRENOM) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': prenom.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="prenom" matInput #prenom="ngModel" [(ngModel)]="participantExterne.prenom"
                               [required]="isFieldRequired(nomParametreParId.PRENOM)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateRef>
                <label class="col-md-2" [ngClass]="{ 'has-error': reference.invalid, 'required': isFieldRequired(nomParametreParId.REFERENCE) }">
                    <span>{{ getLibelle(nomParametreParId.REFERENCE) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': reference.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="reference" matInput #reference="ngModel" [(ngModel)]="participantExterne.reference"
                               [required]="isFieldRequired(nomParametreParId.REFERENCE)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateTel>
                <custom-input #telephone
                        [(ngModel)]="participantExterne.telephone"
                        (onChange)="participantExterne.telephoneChanged = true"
                        customType="input-obfuscated"
                        [libelle]="getLibelle(nomParametreParId.TELEPHONE)"
                        name="telephone"
                        ngDefaultControl
                        [required]="isFieldRequired(nomParametreParId.TELEPHONE)"
                ></custom-input>
            </ng-template>
            <ng-template #templateFonction>
                <label class="col-md-2" [ngClass]="{ 'has-error': fonction.invalid, 'required': isFieldRequired(nomParametreParId.FONCTION) }">
                    <span>{{ getLibelle(nomParametreParId.FONCTION) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': fonction.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="fonction" matInput #fonction="ngModel" [(ngModel)]="participantExterne.fonction"
                               [required]="isFieldRequired(nomParametreParId.FONCTION)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateSociete>
                <label class="col-md-2" [ngClass]="{ 'has-error': societe.invalid, 'required': isFieldRequired(nomParametreParId.SOCIETE) }">
                    <span>{{ getLibelle(nomParametreParId.SOCIETE) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': societe.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="societe" matInput #societe="ngModel" [(ngModel)]="participantExterne.societe"
                               [required]="isFieldRequired(nomParametreParId.SOCIETE)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateMail>
                <label class="col-md-2" [ngClass]="{ 'has-error': email.invalid, 'required': isFieldRequired(nomParametreParId.EMAIL) }">
                    <span>{{ getLibelle(nomParametreParId.EMAIL) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': email.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="email" name="email" matInput #email="ngModel" [(ngModel)]="participantExterne.email"
                               [required]="isFieldRequired(nomParametreParId.EMAIL)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateAdd1>
                <label class="col-md-2" [ngClass]="{ 'has-error': complement1.invalid, 'required': isFieldRequired(nomParametreParId.ADD1) }">
                    <span>{{ getLibelle(nomParametreParId.ADD1) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': complement1.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="complement1" matInput #complement1="ngModel" [(ngModel)]="participantExterne.add1"
                               [required]="isFieldRequired(nomParametreParId.ADD1)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateAdd2>
                <label class="col-md-2" [ngClass]="{ 'has-error': complement2.invalid, 'required': isFieldRequired(nomParametreParId.ADD2) }">
                    <span>{{ getLibelle(nomParametreParId.ADD2) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': complement2.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="complement2" matInput #complement2="ngModel" [(ngModel)]="participantExterne.add2"
                               [required]="isFieldRequired(nomParametreParId.ADD2)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateAdd3>
                <label class="col-md-2" [ngClass]="{ 'has-error': complement3.invalid, 'required': isFieldRequired(nomParametreParId.ADD3) }">
                    <span>{{ getLibelle(nomParametreParId.ADD3) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': complement3.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="complement3" matInput #complement3="ngModel" [(ngModel)]="participantExterne.add3"
                               [required]="isFieldRequired(nomParametreParId.ADD3)"/>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-template #templateAdd4>
                <label class="col-md-2" [ngClass]="{ 'has-error': complement4.invalid, 'required': isFieldRequired(nomParametreParId.ADD4) }">
                    <span>{{ getLibelle(nomParametreParId.ADD4) }}</span>
                </label>
                <div class="col-md-4" [ngClass]="{ 'has-error': complement4.invalid }">
                    <mat-form-field class="d-flex">
                        <input type="text" name="complement4" matInput #complement4="ngModel" [(ngModel)]="participantExterne.add4"
                               [required]="isFieldRequired(nomParametreParId.ADD4)"/>
                    </mat-form-field>
                </div>
            </ng-template>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="participantForm.invalid || isSaving" (click)="creerParticipant()">
        <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
