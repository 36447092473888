import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject,Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {KeyStatutApplication} from "@domain/admin/statut-application/statut-application";

/**
 * Service de gestion du statut public de l'application
 */
@Injectable()
export class StatutApplicationUserService {

	/** Constante pour la durée (ms) de temporisation avant mise en maintenance forcée */
	static readonly TOTAL_TEMPO_MAINTENANCE: number = 120000;

	/** Durée (ms) avant prochain rafraichissement du statut (veille) */
	static readonly TEMPO_VEILLE: number = 30000;

	/** Durée (ms) avant prochain rafraichissement du statut (maintenance lancée) */
	static readonly TEMPO_MAINTENANCE: number = 10000;

	/** Durée (ms) avant prochain rafraichissement local de la progression */
	static readonly TEMPO_PROGRESSION_LOCALE: number = 100;

	/** Flag d'activité' */
	private isRefreshing: boolean = false;

	/** Flag de verrouillage du rafraichissement */
	private isRefreshLocked: boolean = false;

	/** Statut de l'application */
	private _statut: BehaviorSubject<KeyStatutApplication> = new BehaviorSubject<KeyStatutApplication>(null);
	get statut$() { return this._statut.asObservable(); }

	/** Progression de la temporisation */
	private _progression: BehaviorSubject<number> = new BehaviorSubject<number>(0.00);
	get progression$() { return this._progression.asObservable(); }

	/** Message de maintenance administrateur */
	private _messageMaintenance: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	get messageMaintenance$() { return this._messageMaintenance.asObservable(); }

	/** ID de l'intervalle */
	private intervalId: NodeJS.Timeout;

	/** Temps (ms) restant avant écoulement de la temporisation */
	private millisecondsToTrigger: number;

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Récupération du statut public de l'application
	 */
	private getPublicApplicationStatus(): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/StatutApplication/getPublicApplicationStatus`).pipe(first());
	}

	/**
	 * Lancement de la surveillance du statut de l'application
	 *
	 * @param isForced true si forçage du rafraichissement
	 */
	checkPublicApplicationStatus(isForced = false): void {
		//Si la surveillance du statut de l'application n'est pas déjà en cours
		if (!this.isRefreshing || isForced) {
			//Bascule du flag de surveillance activée
			this.isRefreshing = true;

			//Abonnement à la mise à jour du statut de l'application
			this.getPublicApplicationStatus().subscribe((result) => this.handleStatus(result),(error) => {});
		}
	}

	/**
	 * Gestion du statut retourné
	 *
	 * @param result résultat retourné
	 */
	private handleStatus(result: Result): void {
		//Emission du statut de l'application si nécessaire
		this._statut.getValue() != result?.data?.applicationStatut && this._statut.next(result?.data?.applicationStatut as KeyStatutApplication);

		//Reset de l'intervalle précédent
		clearInterval(this.intervalId);

		//Si l'application est hors ligne ou en attente
		if (result?.data?.applicationStatut == KeyStatutApplication.HORS_LIGNE || result?.data?.applicationStatut == KeyStatutApplication.HORS_LIGNE_TEMPO) {
			//Emission du message de maintenance
			this._messageMaintenance.next(result?.data?.messageMaintenance);

			//Si l'application est en attente de hors ligne
			if (result?.data?.applicationStatut == KeyStatutApplication.HORS_LIGNE_TEMPO) {
				//Sauvegarde du temps restant
				this.millisecondsToTrigger = result?.data?.millisecondsToTrigger;

				//Définition d'un intervalle de progression pour faire évoluer la barre en attendant le prochain appel
				this.intervalId = setInterval(() => {
					//Incrément local du temps restant
					this.millisecondsToTrigger -= StatutApplicationUserService.TEMPO_PROGRESSION_LOCALE;

					//Calcul et émission du pourcentage d'avancement de la temporisation
					this._progression.next((StatutApplicationUserService.TOTAL_TEMPO_MAINTENANCE - this.millisecondsToTrigger) * 100 / StatutApplicationUserService.TOTAL_TEMPO_MAINTENANCE);
				}, StatutApplicationUserService.TEMPO_PROGRESSION_LOCALE);

				//Sinon, si l'application est en attente de maintenance, alors calcul et émission du pourcentage d'avancement de la temporisation
				this._progression.next((StatutApplicationUserService.TOTAL_TEMPO_MAINTENANCE - result?.data?.millisecondsToTrigger) * 100 / StatutApplicationUserService.TOTAL_TEMPO_MAINTENANCE);
			}
		}

		//Si le rafraichissement est autorisé
		if (!this.isRefreshLocked) {
			//Gestion de la fréquence de vérification en fonction du statut de l'application
			setTimeout(() => this.getPublicApplicationStatus().subscribe((result) => this.handleStatus(result),(error) => {}), result?.data?.applicationStatut == KeyStatutApplication.HORS_LIGNE_TEMPO ? StatutApplicationUserService.TEMPO_MAINTENANCE : StatutApplicationUserService.TEMPO_VEILLE);
		}
	}

	/**
	 * Verrouillage du rafraichissement de statut
	 */
	lockRefresh(): void {
		//Bascule du flag de blocage
		this.isRefreshLocked = true;

		//Bascule du flag de rafraichissement en cours
		this.isRefreshing = false;
	}

	/**
	 * Déverrouillage du rafraichissement de statut
	 */
	unlockRefresh(): void {
		//Bascule du flag de blocage
		this.isRefreshLocked = false;
	}
}
