import {NgModule} from '@angular/core';
import {TooltipModule} from "../tooltip.module";
import {TooltipPrestationComponent} from "./tooltip-prestation.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule,FormsModule,MatIconModule,TranslateModule,TooltipModule],
    declarations: [TooltipPrestationComponent],
    exports: [TooltipPrestationComponent],
    providers: []
})
export class TooltipPrestationModule {}