import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {DefaultRequestInterceptor} from "./default-request-interceptor.provider";
import {FirewallInterceptor} from "./firewall-interceptor.service";

/**
 * Export des intercepteurs de façon ordonnée.
 *
 * @author François Turin
 * @aate 30/06/2023
 */
export const httpInterceptorProviders = [{
	provide: HTTP_INTERCEPTORS,
	useClass: DefaultRequestInterceptor,
	multi: true
},{
	provide: HTTP_INTERCEPTORS,
	useClass: FirewallInterceptor,
	multi: true
}].filter(i => !!i);
