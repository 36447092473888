import {Component,Input,OnInit} from "@angular/core";
import {Enveloppe} from '@domain/admin/entreprise/analytique/enveloppe';
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";
import {ListView} from "@domain/common/list-view";
import {MontantItemComponent} from './montant-item/montant-item.component';
import {TranslateService} from "@ngx-translate/core";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {MatDialog} from "@angular/material/dialog";
import {MontantEditComponent} from "./montant-edit/montant-edit.component";
import {ToastrService} from "ngx-toastr";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";

/**
 * Montants d'une enveloppe
 *
 * @author Tom JEAMMET
 * @date 10/05/2023
 */
@Component({
    host: {'data-test-id': 'enveloppe-montants'},
    selector: 'enveloppe-montants',
    templateUrl: './enveloppe-montants.component.html'
})
export class EnveloppeMontantsComponent implements OnInit {
    /** Une enveloppe */
    @Input() enveloppe: Enveloppe;

    /** Modèle de la liste */
    listeMontants: ListView<MontantDto, MontantItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private toastrService: ToastrService,
        private enveloppeDetailService: EnveloppeDetailService
    ) { }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Initialisation de la liste des montants de l'enveloppe
        this.listeMontants = new ListView<MontantDto, MontantItemComponent>({
            uri: `/controller/Budget/Enveloppe/${this.enveloppe.idEnveloppe}/montants`,
            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.onglets.montants'),
            component: MontantItemComponent,
            defaultOrder: 'idMontant',
            isSimple: true,
            mapResult: (result: Result) => {
                return result?.data?.montants;
            },
            listeActions: [{
                icon: "add",
                onPress: () => this.openEditMontantDialog()
            }]
        });
    }

    /**
     * Ouvre la popin montant
     */
    openEditMontantDialog(): void {
        this.matDialog.open<MontantEditComponent,any,MontantDto>(MontantEditComponent,{
            data: {
                montant: {
                    idMontant: 0,
                    libelle: '',
                    idEnveloppe: this.enveloppe.idEnveloppe
                }
            }
        }).afterClosed().subscribe(data => {
            if (data != null) {
                this.addMontantToEnveloppe(data);
            }
        });
    }

    /**
     * Sauvegarde le montant
     *
     * @param montant le montant
     */
    addMontantToEnveloppe(montant: MontantDto): void {
        this.enveloppeDetailService.saveMontant(montant).subscribe({
            next: (result: Result) => {
                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    //Message de success
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    //On refresh la liste
                    this.listeMontants.refresh();
                } else if (result.codeErreur == TypeCodeErreur.ERROR_DOUBLON) {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.enveloppes.montants.erreurDoublon'));
                } else {
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            },
            error: () => {
                //Message d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
            }
        });
    }
}