import {Component,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {PuissanceFiscaleListItemComponent} from "@components/admin/entreprise/vehicules/vehicules-puissances-fiscales/puissance-fiscale-list-item/puissance-fiscale-list-item.component";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {PuissanceFiscaleDetailComponent} from "@components/admin/entreprise/vehicules/vehicules-puissances-fiscales/puissance-fiscale-detail/puissance-fiscale-detail.component";
import {Unite} from "@domain/vehicule/unite";
import {EntrepriseVehiculesService} from "@components/admin/entreprise/vehicules/entreprise-vehicules.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {PuissanceFiscale} from "@domain/admin/entreprise/vehicules/puissanceFiscale";


/**
 * Page admin de Véhicules / Puissances fiscales
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'vehicules-puissances-fiscales'},
    templateUrl: './vehicules-puissances-fiscales.component.html'
})
export class VehiculesPuissancesFiscalesComponent implements OnInit {
    /** Liste des puissances fiscales */
    listePuissances: ListView<PuissanceFiscale,PuissanceFiscaleListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Liste des unités possibles pour une puissance */
    listeUnites: Array<Unite>;

    /**
     * Constructeur
     *
     * @param matDialog Boîte de dialogue
     * @param translateService Service des traductions
     * @param vehiculeService Service des véhicules
     * @param toastrService Service des toasts
     */
    constructor(private matDialog: MatDialog,
                private translateService: TranslateService,
                private vehiculeService: EntrepriseVehiculesService,
                private toastrService: ToastrService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération de la liste des unités
        this.vehiculeService.getListeUnites().subscribe((result: Result) => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                this.listeUnites = result.data.listeUnites;

                //Création de la liste des puissances fiscales
                this.listePuissances = new ListView<PuissanceFiscale,PuissanceFiscaleListItemComponent>({
                    uri: `/controller/VehiculeAdmin/listePuissances`,
                    title: this.translateService.instant('admin.entreprise.vehicules.puissances.title'),
                    component: PuissanceFiscaleListItemComponent,
                    mapResult: (result: Result) => result?.data?.listePuissancesFiscales,
                    isSimple: true,
                    extraOptions: {
                        openPuissance: this.openPuissance.bind(this)
                    }
                });
            } else {
                //Message d'erreur
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });

        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.openPuissance()
        }]);
    }

    /**
     * Ouverture d'une puissance fiscale
     *
     * @param puissance Puissance fiscale. null => création
     */
    openPuissance(puissance?: PuissanceFiscale) {
        //Ouverture de la puissance fiscale
        this.matDialog.open(PuissanceFiscaleDetailComponent, {
            width: '60%',
            data: {
                puissance: new PuissanceFiscale(puissance ?? {idPuissance: 0}),
                listeUnites: this.listeUnites
            }
        }).afterClosed().subscribe((isReload: boolean) => {
            //Vérification du besoin de recharger la liste
            if (isReload) {
                //Rechargement
                this.listePuissances.refresh();
            }
        });
    }

}
