<h1 mat-dialog-title>
    <span class="left">
        <span>{{ (data.idPortee == TypePortee.PRF || data.idPortee == TypePortee.ADM ? 'lieu.titleAdresse' : 'lieu.title') | translate }}</span>
    </span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <mat-tab-group #matTabGroup dynamicHeight animationDuration="0ms" [(selectedIndex)]="tabIndex">
        <mat-tab label="{{ (this.isAdminAdresse ? 'lieu.tabs.adresses' : 'lieu.tabs.mesAdresses') | translate }}">
            <please-wait *ngIf="!listeAdresse && !isProfilAdresse && !isAdminAdresse"></please-wait>
            <adresse-list *ngIf="listeAdresse || isProfilAdresse || isAdminAdresse"
                [listeAdresse]="listeAdresse"
                [isProfilAdresse]="isProfilAdresse"
                [isAdminAdresse]="isAdminAdresse"
                (onAdresseSelect)="selectAdresse($event);"></adresse-list>
        </mat-tab>
        <mat-tab *ngIf="!isProfilAdresse && !isAdminAdresse && listeEtablissement?.length !== 0" label="{{ 'lieu.tabs.etablissements' | translate }}">
            <please-wait *ngIf="!listeEtablissement"></please-wait>
            <etablissement-list [listeEtablissement]="listeEtablissement" (onEtablissementSelected)="selectEtablissement($event);"></etablissement-list>
        </mat-tab>
        <mat-tab #tabSaisie="matTab" *ngIf="isSaisieAutorisee" label="{{ 'lieu.tabs.saisieAdresse' | translate }}">
            <localisation [idPortee]="data.idPortee"
                          [idUser]="data.idUser"
                          [typeEntiteParam]="data.typeEntiteParam"
                          [geolocalisationTool]="geolocalisationTool"
                          [localisation]="localisation"
                          [typeAdresse]="typeAdresse"
                          (onLocalisationSelected)="selectAutre($event);"></localisation>
        </mat-tab>
    </mat-tab-group>
</div>
