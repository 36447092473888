/**
 * transport autorisé pour l'ordre de mission permanent
 */
export interface Transport {
    idTransport: number;
    idOmPermanent: number;
    type: TypeTransport;

    //Véhicule personnel
    idVehicule?: number;
    numerique1?: number;
    vehicule?: any;

    //Véhicule administratif ou Avion ou Train ou Autre
    attribut1?: string;

    //Avion ou Train
    idConfort?: number;
    confort?: any;
}

/**
 * Type de transport
 */
export enum TypeTransport {
    /** Enumération **/
    TYPE_PERSONNEL = 1,
    TYPE_ADMINISTRATIF = 2,
    TYPE_AVION = 3,
    TYPE_TRAIN = 4,
    TYPE_AUTRE = 5
}
