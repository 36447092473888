import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Result} from '@domain/common/http/result';
import {ChainageComponent} from './chainage.component';
import {environment} from '@environments/environment';
import {WorkflowHistorique} from '@domain/chainage/workflowHistorique';
import {TypePortee} from '@domain/workflow/workflow';

/**
 * Service de gestion du chainage
 */
@Injectable()
export class ChainageService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient,private matDialog: MatDialog) {}

    /**
     * Affichage du chainage pour un objet
     */
    showChainageForObject(idPortee: TypePortee,idObject: number) {
        //Préchargement de l'observable
        const loadChainage: () => Observable<WorkflowHistorique> = () => { return this.loadChainageForObject(idPortee,idObject) };

        //Affichage du chainage
        return this.matDialog.open(ChainageComponent,{
            data: {
                loadChainage
            }
        }).afterClosed();
    }

    /**
     * Chargement du chainage
     */
    loadChainageForObject(idPortee: TypePortee,idObject: number): Observable<WorkflowHistorique> {
        //Chargement du chainage
        return this.http.post<Result>(`${environment.baseUrl}/controller/WorkflowUser/loadHistoriqueWorkflow/${idPortee}/${idObject}`,null).pipe(
            map(result => result.data?.workflowHistorique)
        );
    }
}
