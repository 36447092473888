<!-- Titre -->
<h1 mat-dialog-title>
    <span translate>admin.voyages.travelhub.sbtConfig.parametres.selection.title</span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<!-- Contenu -->
<div mat-dialog-content class="overflow-hidden d-flex">
    <!-- Onglets -->
    <mat-tab-group dynamicHeight="dynamicHeight" animationDuration="0" [(selectedIndex)]="selectedIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab *ngFor="let filter of listeFilter" label="{{ FiltreChamp.traduction(filter) | translate }}"></mat-tab>
    </mat-tab-group>
    <!-- Liste des champs -->
    <div class="flex-grow-1 overflow-auto">
        <list-view [liste]="listeChamps" (onLoadEnd)="refreshListe(listeFilter[selectedIndex])"></list-view>
    </div>
</div>
