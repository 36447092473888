import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {ModeRembListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/select-mode-remb-popin/mode-remb-list-item/mode-remb-list-item.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ModeRemboursement} from '@domain/remboursement/mode-remboursement';
import {Collaborateur} from "@domain/user/collaborateur";
import {Page} from "@domain/common/http/list-result";

/**
 * Popin de sélection du mode de remboursement d'un collaborateur
 */
@Component({
	host: {'data-test-id': 'select-mode-remb-popin'},
	templateUrl: './select-mode-remb-popin.component.html'
})
export class SelectModeRembPopinComponent implements OnInit {
	/** Collaborateur */
	collab: Collaborateur;

	/** Liste des modes de remboursement */
	listeModeRemb: ListView<ModeRemboursement,ModeRembListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { collab: Collaborateur },
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation du collaborateur
		this.collab = Object.assign({},this.data.collab);

		//Initialisation de la liste
		this.listeModeRemb = new ListView<ModeRemboursement,ModeRembListItemComponent>({
			uri: '/controller/ModeRemb/listeModeRemb',
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.selectModeRembPopin.liste.title'),
			component: ModeRembListItemComponent,
			isSimple: true,
			mapResult: (result: Page<ModeRemboursement>) => result?.listeResultats,
			extraOptions: {
				collab: this.collab,
			}
		});
	}
}
