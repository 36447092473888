import {Component,Input} from '@angular/core';
import {ProfilsAttribuables} from "@domain/workflow/profilsAttribuables";
import {RoleLong} from "@domain/workflow/roleLong";
import {SpecificiteRoles} from "@domain/workflow/specificiteRoles";

/**
 * Composant du cadre des options d'un rôle
 *
 * @author Laurent SCIMIA
 * @date 10/10/2023
 */
@Component({
	host: {'data-test-id': 'role-options'},
	selector: 'role-options',
	templateUrl: './role-options.component.html'
})
export class RoleOptionsComponent {
	//Accès aux enums pour le template
	readonly ProfilsAttribuables = ProfilsAttribuables;
	readonly SpecificiteRoles = SpecificiteRoles;

	/** Rôle ouvert */
	@Input() role: RoleLong;
}