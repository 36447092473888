import {Component, OnInit} from '@angular/core';
import {ListView, TypeComparaison, TypeFilter} from '@domain/common/list-view';
import {FloatingButtonAction, TypeAction} from '@share/component/floating-button/floating-button';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Router} from "@angular/router";
import {CompteItem, TypeCompte} from "@domain/admin/comptabilite/compte";
import {CompteListItemComponent} from "@components/admin/comptabilite/plan-comptable/comptes-comptables/compte-list-item/compte-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {ListViewService} from "@share/component/list-view/list-view.service";

/**
 * Liste des comptes comptables de Comptabilité / Plan comptable
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'comptes-comptables'},
    selector: 'comptes-comptables',
    templateUrl: './liste-comptes-comptables.component.html'
})
export class ListeComptesComptablesComponent implements OnInit {

    /** Liste des comptes comptables */
    listeComptes: ListView<CompteItem, CompteListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Souscription à l'indicateur de refresh de la liste */
    refreshSub: Subscription;

    /**
     * Constructeur
     *
     * @param router Router
     * @param translateService Service des traductions
     * @param planComptableService Service du plan comptable
     * @param listViewService Service des listes
     */
    constructor(private router: Router,
                private translateService: TranslateService,
                private planComptableService: ComptabilitePlanComptableService,
                private listViewService: ListViewService<CompteItem, CompteListItemComponent>) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des comptes
        this.listeComptes = new ListView<CompteItem, CompteListItemComponent>({
            uri: '/controller/Compte/listeComptesComptables',
            title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.title.liste'),
            component: CompteListItemComponent,
            isFilter: true,
            defaultOrder: 'numero',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('filter.libelle'),
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                }, {
                    clef: 'numero',
                    title: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.numero'),
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING]
                }, {
                    clef: 'type',
                    title: this.translateService.instant('filter.type'),
                    listeValues: [{
                        code: TypeCompte.NON_DEFINI.toString(),
                        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.nonDef')
                    }, {
                        code: TypeCompte.COMPTE_TIERS.toString(),
                        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.tiers')
                    }, {
                        code: TypeCompte.COMPTE_TAXE.toString(),
                        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.taxe')
                    }, {
                        code: TypeCompte.COMPTE_BANQUE.toString(),
                        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.banque')
                    }, {
                        code: TypeCompte.COMPTE_CHARGE.toString(),
                        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.charge')
                    }, {
                        code: TypeCompte.PERTE_GAIN_CHANGE.toString(),
                        libelle: this.translateService.instant('admin.comptabilite.planComptable.compteComptable.change')
                    }]
                }, {
                    clef: 'isActif',
                    title: this.translateService.instant('filter.isActif'),
                    isSelected: true,
                    valeur: 'true',
                    onloadValue: 'true',
                    displayedValeur: this.translateService.instant('filter.valeurOuiNon.true'),
                    listeValues: [{
                        code: 'true',
                        libelle: this.translateService.instant('filter.valeurOuiNon.true')
                    }, {
                        code: 'false',
                        libelle: this.translateService.instant('filter.valeurOuiNon.false')
                    }],
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                }
            ]
        });

        //Rafraichissement des filtres sélectionnés par défaut pour forcer le filtre isActif
        this.listViewService.refreshListeSelectedFilters(this.listeComptes);

        //Définition des colonnes de tri
        this.listeComptes.columns = [
            {key: 'numero', title: 'admin.comptabilite.planComptable.compteComptable.numero'},
            {key: 'libelle', title: 'filter.libelle'},
            {key: 'type', title: 'filter.type'}
        ];

        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.createNewCompte()
            }]
        );

        //Souscription à l'indicateur de refresh de la liste
        this.refreshSub = this.planComptableService.refreshListeComptesObservable$.subscribe( () => {
            this.listeComptes.refresh();
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        this.refreshSub.unsubscribe();
    }

    /**
     * Création d'un nouveau compte comptable
     */
    createNewCompte() {
        this.router.navigate(['Admin/Comptabilite/PlanComptable/CompteComptable/0']);
    }

}
