<!-- Ligne de frais de type NDF -->
<div *ngIf="data.ndf" class="lvi-content">
    <div class="avatar" [nioTooltip]="'od.frais.frais.liste.types.ndf' | translate"
                        [ngClass]="NiveauAlerte.getClass(data.listeAlertes?.niveau)">
        <i class="nio icon-note_de_frais align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="showNDF(); $event.stopPropagation();">{{ data.ndf.objet || ('od.frais.frais.liste.objetNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.frais.frais.liste.ndf.numero'"></strong>{{ data.ndf.numeroPiece }}</li>
            <li><strong [translate]="'od.frais.frais.liste.ndf.montantRemboursable'"></strong>{{ data.ndf.montantRemboursable | montant:data.ndf.devise?.code }}</li>
            <li><strong [translate]="'od.frais.frais.liste.ndf.montantARembourser'"></strong>{{ data.ndf.montantARembourser | montant:data.ndf.devise?.code }}</li>
            <ng-container *ngTemplateOutlet="alertes;context:{listeAlertes: data.listeAlertes, idObjet:data.ndf.idNDF, portee: TypePortee.NF}"></ng-container>
        </ul>
    </div>
    <ng-container *ngTemplateOutlet="statut;context:{statut: data.ndf.statut}"></ng-container>
</div>

<!-- Ligne de frais de type Avance -->
<div *ngIf="data.avance" class="lvi-content">
    <div class="avatar" [nioTooltip]="'od.frais.frais.liste.types.avance' | translate"
                        [ngClass]="NiveauAlerte.getClass(data.listeAlertes?.niveau)">
        <i class="nio icon-avance_2 align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="showAvance(); $event.stopPropagation();">{{ data.avance.libelleObjet || ('od.frais.frais.liste.objetNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.frais.frais.liste.avance.numero'"></strong>{{ data.avance.idAvance }}</li>
            <li><strong [translate]="'od.frais.frais.liste.avance.nature'"></strong>{{ NatureAvance.traduction(data.avance.nature) | translate }}</li>
            <li><strong [translate]="'od.frais.frais.liste.avance.montant'"></strong>{{ data.avance.montant | montant:data.avance.devise?.code }}</li>
            <li><strong [translate]="'od.frais.frais.liste.avance.contrepartie'"></strong>{{ data.avance.montantContrepartie | montant:data.avance.devise?.code }}</li>
            <ng-container *ngTemplateOutlet="alertes;context:{listeAlertes: data.listeAlertes, idObjet:data.avance.idAvance, portee: TypePortee.AV}"></ng-container>
        </ul>
    </div>
    <ng-container *ngTemplateOutlet="statut;context:{statut: data.avance.statut}"></ng-container>
</div>

<!-- Ligne de frais de type Frais Prévisionnel -->
<div *ngIf="data.fraisPrev" class="lvi-content">
    <div class="avatar" [nioTooltip]="'od.frais.frais.liste.types.frais_prev' | translate">
        <i class="nio icon-frais_previsionnel_2 align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="showFraisPrev(); $event.stopPropagation();">{{ data.fraisPrev.prestation?.libelle || ('od.frais.frais.liste.objetNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.frais.frais.liste.fraisPrev.date'"></strong>{{ data.fraisPrev.date | date:'shortDate' }}</li>
            <li><strong [translate]="'od.frais.frais.liste.fraisPrev.prestation'"></strong>{{ data.fraisPrev.prestation?.libelle }}</li>
            <li><strong [translate]="'od.frais.frais.liste.fraisPrev.quantite'"></strong>{{ data.fraisPrev.quantite }}</li>
            <li><strong [translate]="'od.frais.frais.liste.fraisPrev.montant'"></strong>{{ data.fraisPrev.montant | montant:data.fraisPrev.devise }}</li>
            <li>
                <strong [translate]="'od.frais.frais.liste.fraisPrev.lieu'"></strong>
                <span class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.fraisPrev.pays.code2.toLowerCase()" *ngIf="data.fraisPrev.pays"></span>
                {{ (data.fraisPrev.ville?.libelle ? data.fraisPrev.ville.libelle + ', ' : '') + (data.fraisPrev.pays?.libelle || '') }}
            </li>
        </ul>
    </div>
</div>

<!-- Template d'affichage du tag des alertes -->
<ng-template #alertes let-listeAlertes="listeAlertes" let-idObjet="idObjet" let-portee="portee">
    <li *ngIf="listeAlertes"
        (click)="showListeAlertes(portee,idObjet)"
        [ngClass]="NiveauAlerte.getClass(listeAlertes?.niveau,true)"
        class="clickable">
        <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + listeAlertes.niveau | translate) }}</span></li>
</ng-template>

<!-- Template d'affichage du statut workflow -->
<ng-template #statut let-statut="statut">
    <div *ngIf="statut" class="info">
        <statut [statut]="statut"></statut>
    </div>
</ng-template>