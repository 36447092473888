<mat-card-content>
    <form autocomplete="off">
        <div class="row">
            <label class="col-md-3 required" [for]="'idParticipant'">
                <span translate>od.voyage.participants.participant</span>
            </label>
            <div class="col-md-9">
                <autocomplete type="participant" name="participantInterne"
                    [filter]="{ idObjet: idObjet,idNDF: idNDF,idPrestation: idPrestation,contexte: contexte }"
                    [(ngModel)]="participant"
                    (onSelect)="addParticipant($event);"
                    ngDefaultControl
                    required
                    [isReinitAfterSelect]="true"
                    [placeholder]="'liste.recherche.saisirValeur' | translate"
                    [floatLabel]="'never'"
                    [inputId]="'idParticipant'">
                </autocomplete>
            </div>
        </div>
    </form>
</mat-card-content>
