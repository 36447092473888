import { Component } from "@angular/core";

/**
 * Onglet factures types charges
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'factures-types-charges'},
    templateUrl: './factures-types-charges.component.html'
})
export class FacturesTypesChargesComponent {}
