<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.parametres.demat.title' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedTabChange($event)" [selectedIndex]="selectedIndex"
                     [niveauAlerte]="listeAlertes?.niveau">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte" [withIcon]="false" [isLargeIcon]="true"></preview-alerte>
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.MODULES">
            <demat-module></demat-module>
        </div>
        <div [hidden]="selectedItem?.code !== Onglet.PIECES_JOINTES">
            <piece-jointe></piece-jointe>
        </div>
        <div [hidden]="selectedItem?.code !== Onglet.EXTENSIONS">
            <extension></extension>
        </div>
        <div [hidden]="selectedItem?.code !== Onglet.TYPES">
            <demat-type></demat-type>
        </div>
        <div *ngIf="selectedItem?.code == Onglet.OCR">
            <demat-ocr></demat-ocr>
        </div>
    </div>
</div>
