import {Component,Input,OnInit} from '@angular/core';
import {TypeProfil,User} from '@domain/user/user';
import {Store} from '@ngrx/store';
import {AppState} from "@domain/appstate";
import {first} from "rxjs/operators";
import {UserDto} from "@domain/user/user-dto";

/**
 * Composant pour afficher le propriétaire sur un objet
 */
@Component({
	selector: 'proprietaire',
    host: {'data-test-id': 'proprietaire'},
	templateUrl: './proprietaire.component.html',
	exportAs: 'proprietaire',
    styles: [':host { display: contents; }']
})
export class ProprietaireComponent implements OnInit {
    /** Déclaration pour accès dans le template */
    TypeProfil = TypeProfil;
    
    /** Utilisateur propriétaire de l'objet */
    @Input() proprietaireUser: User | UserDto;

    /** User connecté */
    user: User;

    constructor( protected store: Store<AppState>,) {}

    ngOnInit(): void {
       //Sélection de l'utilisateur connecté
       this.store.select(state => state.session?.user)
           .pipe(first())
           .subscribe(user => this.user = user);
    }
} 