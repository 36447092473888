<h1 mat-dialog-title>
    <span [translate]="'od.engagements.indemnites.degrevements.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait class="content" *ngIf="isLoading"></please-wait>
    <list-view *ngIf="!!liste" [liste]="liste"></list-view>
</div>
<div mat-dialog-actions *ngIf="canModifier || canCompleter">
    <button mat-stroked-button color="primary" [disabled]="isSaving" (click)="degrevementMasse()">
        <span translate>od.engagements.indemnites.degrevements.degrevementMasse</span>
    </button>
    <button mat-flat-button color="primary" [disabled]="isSaving" (click)="saveDegrevement()">
        <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
