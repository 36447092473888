import { Component,OnInit } from '@angular/core';
import { LotAvanceDTO } from "@domain/comptabilite/lot";
import { LotAddObjectItemComponent } from "./lot-add-object-item.component";

@Component({
    host: {'data-test-id': 'lot-add-avance-item'},
    templateUrl: './lot-add-avance-item.component.html'
})
/**
 * Composant pour ajouter une avance au lot comptable
 */
export class LotAddAvanceItemComponent extends LotAddObjectItemComponent<LotAvanceDTO> implements OnInit {
    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        super.ngOnInit();
    }
}
