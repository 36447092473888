import {ListItem} from "../common/list-view/list-item";

/**
 * Document
 */
export class Document implements ListItem {
    isOpened: boolean;
    actif?: boolean;
    dateDelivrance?: Date;
    dateExpiration?: Date;
    id?: number;
    idDocument?: number;
    idUser?: number;
    idVille?: number;
    idPays?: number;
    isActif?: boolean;
    lieuDelivrance?: string;
    nbPJ?: number;
    numero?: string;
    titulaire?: string;
    toDelete?: boolean;
    type?: string;

    getKey(): number {
        return this.idDocument;
    }

    constructor() {
        this.actif = true;
        this.idDocument = 0;
    }
}
