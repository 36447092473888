<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons">savings</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.exercice.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data?.exercice.dateDebut">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montants.dateOuverture'"></strong>
                <span>{{ data?.exercice.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data?.exercice.dateFin">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montants.dateFermeture'"></strong>
                <span>{{ data?.exercice.dateFin | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data?.montant !== null">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantTotal'"></strong>
                <span>{{ data.montant | montant: data.codeDevise }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantEngage'"></strong>
                <span>{{ data?.montantEngage | montant: data.codeDevise }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantDisponible'"></strong>
                <span>{{ data?.montantDisponible | montant: data.codeDevise }}</span>
            </li>
        </ul>
    </div>
    <!-- bouton supprimer pour le frais ocr -->
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="delete()" translate>global.actions.supprimer</button>
            <button mat-menu-item (click)="openReaffectation()" translate>admin.entreprise.analytique.enveloppes.montants.reaffecter</button>
        </mat-menu>
    </div>
</div>