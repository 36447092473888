import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {FileWithProgress, XmlUploader} from './xml-uploader';
import {Result} from "@domain/common/http/result";

@Injectable()
export class XmlUploaderService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Upload d'un fichier.
     */
    public upload(fileUploader: XmlUploader, file: FileWithProgress): void {
        const formData: FormData = new FormData();

        //Ajout du fichier
        formData.append('licenceFile', file);

        //Upload du fichier
        this.http.post<Result>(fileUploader.url, formData).subscribe({
            next: event => {
                if (event) {
                    file.result = event;

                    //Le fichier a fini d'être upload
                    fileUploader.onItemUploaded && fileUploader.onItemUploaded(event, file);
                }
            }
        });

    }
}
