/**
 * Représente un champ qui sera utilisé comme valeur d'un paramètre de connexion
 *
 * @author Laurent Convert
 * @date 20/12/2023
 */
export class Champ {
	id: number;
	libelle: string;
	filtre: FiltreChamp;

	/**
	 * Constructeur
	 *
	 * @param id Identifiant du champ
	 * @param filtre Catégorie du champ (les champs d'un même filtre sont regroupés à l'affichage)
	 */
	constructor(id: number,filtre: FiltreChamp) {
		this.id = id;
		this.libelle = 'admin.voyages.travelhub.Champ.' + id;
		this.filtre = filtre;
	}
}

/** Énumération des différents filtres (groupe) de champs */
export enum FiltreChamp {
	MISSION = "MISSION",
	VOYAGEUR = "VOYAGEUR",
	UTILISATEUR_CONNECTE = "UTILISATEUR_CONNECTE",
	AUTRE = "AUTRE",
}
export namespace FiltreChamp {
	/** Renvoie le libelle de traduction correspondant */
	export function traduction(filtreChamp: FiltreChamp): string {
		return filtreChamp ? 'admin.voyages.travelhub.FiltreChamp.' + filtreChamp : null;
	}
	/** Renvoie le libelle de traduction correspondant */
	export function icone(filtreChamp: FiltreChamp): string {
		switch (filtreChamp) {
			case FiltreChamp.MISSION: return `<i class="nio icon-ordre_de_mission"></i>`;
			case FiltreChamp.VOYAGEUR: return `<span class="material-symbols-rounded">work</span>`;
			case FiltreChamp.UTILISATEUR_CONNECTE: return `<i class="nio icon-assistant"></i>`;
			case FiltreChamp.AUTRE: return `<span class="material-icons-outlined">text_fields</span>`;
		}
	}
}