import { Component } from "@angular/core";

/**
 * Onglet API Clefs
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'api-clefs'},
    templateUrl: './api-clefs.component.html'
})
export class ApiClefsComponent {}
