import { Component, Input, OnInit } from '@angular/core';
import { WorkflowService } from "@components/workflow/workflow.service";
import { OmpOd } from "@domain/omp/ompOd";
import { TypeProfil } from '@domain/user/user';
import { TypePortee } from "@domain/workflow/workflow";
import { environment } from "@environments/environment";

@Component({
    templateUrl: './omp-od-list-item.component.html'
})
export class OMPOdListItemComponent implements OnInit {

    /** Ordre de mission courant **/
    @Input() data: OmpOd;

    /** Options supplémentaires **/
    @Input() extraOptions: any;

    /** Profil de l'utilisateur connecté **/
    fonction: TypeProfil;

    /** URL de base **/
    public baseUrl: string = environment.baseUrl;

    /** Localisation */
    location: string;

    constructor(
        private workflowService: WorkflowService
    ) { }

    ngOnInit(): void {
        this.location = this.getLocation();
    }

    /**
     * Affichage des alertes de l'OD
     */
    showListeAlertes(idObjet: number) {
        //Affichage de la popup de liste des alertes
        this.workflowService.showListeAlertes(TypePortee.OD,idObjet);
    }

    /**
     * Getter pour l'affichage de l'endroit
     */
    getLocation(): string {
        if (this.data.libelleVille) { return this.data.libelleVille; }
        if (this.data.ville) { return this.data.ville; }
        if (this.data.lieu) { return this.data.lieu; }
        if (this.data.pays?.libelle) { return this.data.pays.libelle; }
        return null;
    }

    /** Getter pour le mode Collaborateur */
    get isCollabMode(): boolean {
        return this.fonction == TypeProfil.COLLABORATEUR;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.data.user) { return null; }
        return (this.data.user.nom ? this.data.user.nom + ' ' : '')
                + (this.data.user.prenom ? this.data.user.prenom : '');
    }

}