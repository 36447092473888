<div>
    <span *ngIf="!!title" class="header">{{ title | translate }}</span>
    <mat-divider *ngIf="!!title"></mat-divider>
    <ul>
        <li *ngFor="let item of listeItems">
            <a [routerLink]="item.url" (click)="doAction(item)">
                <i class="{{ item.icone }}"></i>
                <span [translate]="item.libelle"></span>
            </a>
        </li>
    </ul>
</div>
