import {Directive,Input} from '@angular/core';
import {AbstractControl,NG_VALIDATORS,ValidationErrors,Validator} from '@angular/forms';


/**
 *  Directive de validation qui permet de contrôler que l'input number est supérieur
 */
@Directive({
    selector: '[numberSuperieurOther]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NumberSuperiorOtherDirective, multi: true }]
})
export class NumberSuperiorOtherDirective implements Validator {
    /** L'autre valeur number */
    @Input() otherNumber: number;

    /** Si renseigné à true le validator ne renvoie pas d'erreur si les nombres sont égaux */
    @Input() isSameValid: boolean = false;

    /** Méthode qui effectue une validation synchrone par rapport au contrôle fourni. */
    validate(control: AbstractControl): ValidationErrors | null {
        return checkNumber(control.value, this.otherNumber, this.isSameValid);
    }
}

/**
 * Fonction de validation qui permet de contrôler l'input number
 *
 * @param current le number à controler
 * @param otherHour l'autre number à contrôler
 */
function checkNumber(current: number, otherNumber: number, isSameValid: boolean): ValidationErrors {
    //Si l'input courant et l'autre ne sont pas nulls
    if (current != null && otherNumber != null) {
        if (current > otherNumber || (isSameValid && current == otherNumber)) {
            return null;
        }
    } else {
        //Si un null pas de contrôle
        return null;
    }

    //On renvoie une erreur
    return { after: 'error' };
}