import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ReferentielUsageUserDto} from "@domain/admin/entreprise/referentiel-usage-userDto";

/**
 * Composant pour afficher un utilisateur qui est relié au référentiel sélectionné
 */
@Component({
    host: {'data-test-id': 'referentiel-usage-user-item'},
    selector: 'referentiel-usage-user-item',
    templateUrl: './referentiel-usage-user-item.component.html'
})
export class ReferentielUsageUserItemComponent extends ListViewItem<ReferentielUsageUserDto> {
}