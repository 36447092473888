<div class="lvi-content">
    <div class="avatar" [ngClass]="{'warning': isAdresseIncomplete(data)}"><i class="material-icons-outlined">{{ getIconeForTypeAdresse(data.type) }}</i></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="selectAdresse(data)">{{ isAdresseIncomplete(data) ? ('lieu.adresseIncomplete' | translate) : data.rue }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.ville"><strong [translate]="'lieu.ville'"></strong>{{ data.ville }}</li>
            <li *ngIf="data.pays"><strong [translate]="'lieu.pays'"></strong>{{ data.pays }}</li>
            <li *ngIf="data.codePostal"><strong [translate]="'lieu.codePostal'"></strong>{{ data.codePostal }}</li>
        </ul>
    </div>
</div>