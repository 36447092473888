import { NgModule } from "@angular/core";
import { CoreComponentsModule } from '../core-components.module';
import { ProprietaireComponent } from "./proprietaire.component";
import {ShareModule} from "@share/share.module";

@NgModule({
    imports: [CoreComponentsModule, ShareModule],
    declarations: [ProprietaireComponent],
    exports: [ProprietaireComponent]
})
export class ProprietaireModule {}