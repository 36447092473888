import {Component,OnInit} from '@angular/core';
import {Filter,FilterValue,ListView,TypeComparaison,TypeFilter} from '@domain/common/list-view';
import {Status,Vehicule} from "@domain/vehicule/vehicule";
import {VehiculeListItemComponent} from "@components/admin/entreprise/vehicules/vehicules-list/vehicule-list-item/vehicule-list-item.component";
import {TranslateService} from '@ngx-translate/core';
import {SettingsVehiculeState} from '@domain/settings/settings';
import {TypePortee} from '@domain/workflow/workflow';
import {finalize,first} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as settingsActions from "@reducers/settings";
import {Subscription} from 'rxjs';
import {VehiculeService} from "@components/vehicule/vehicule.service";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";

/**
 * Page admin de Véhicules / Véhicules
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'vehicules-list'},
    templateUrl: './vehicules-list.component.html'
})
export class VehiculesListComponent implements OnInit {
    /** Paramétrage */
    settings: SettingsVehiculeState;

    /** Paramètres relatifs aux véhicules */
    vehiculeSettings: SettingsVehiculeState;

    /** Souscription aux paramètres */
    subscription: Subscription = new Subscription();

    /** Liste des véhicules */
    listeVehicules: ListView<Vehicule,VehiculeListItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService Service des traductions
     * @param store Store de l'application
     * @param vehiculeService Service de gestion des véhicules
     */
    constructor(private translateService: TranslateService,
                private store: Store<AppState>,
                private vehiculeService: VehiculeService) {
    }

    /**
     * Initialisation
     */
    async ngOnInit() {
        let filter: Filter;

        //Chargement du paramétrage des véhicules
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.VP
        });

        //Souscription aux paramètres
        this.subscription = this.store.select(state => state.settings?.[TypePortee.VP]).pipe(filterFirstNotNull()).subscribe(settings => {
            this.vehiculeSettings = settings;

            //Initialisation de la liste des véhicules
            this.listeVehicules = new ListView<Vehicule,VehiculeListItemComponent>({
                uri: `/controller/VehiculeAdmin/listeVehicules`,
                title: this.translateService.instant('profilUser.vehicule.title'),
                component: VehiculeListItemComponent,
                extraOptions: {
                    settings: this.vehiculeSettings
                },
                defaultOrder: 'idCollab,immatriculation',
                listeFilters: [
                    {
                        title: this.translateService.instant('vehicule.liste.filtre.immatriculation'),
                        clef: 'immatriculation',
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                        isDefault: true
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.marque'),
                        clef: 'marque',
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                        isDefault: true
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.modele'),
                        clef: 'modele',
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                        isDefault: true
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.puissanceFiscale'),
                        clef: 'puissanceFiscale.idPuissance',
                        isDefault: false,
                        listeValues: [],
                        multiple: true,
                        loading: true
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.distance'),
                        clef: 'listeCompteur.compteurReel',
                        type: TypeFilter[TypeFilter.DECIMAL],
                        isDefault: false
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.assurance'),
                        clef: 'isAssurePro',
                        type: TypeFilter[TypeFilter.BOOLEAN],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        isDefault: false
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.collaborateur'),
                        clef: 'user.matricule,user.nom,user.prenom',
                        typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                        isDefault: true
                    }
                    , {
                        title: this.translateService.instant('vehicule.liste.filtre.collaborateurActif'),
                        clef: 'user.isActif',
                        type: TypeFilter[TypeFilter.BOOLEAN],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        isDefault: false,
                        onloadValue: 'true'
                    }, {
                        title: this.translateService.instant('vehicule.liste.filtre.actif'),
                        clef: 'isActif',
                        type: TypeFilter[TypeFilter.BOOLEAN],
                        typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                        isDefault: false,
                        onloadValue: 'true'
                    }, {
                        title: this.translateService.instant('vehicule.liste.statut'),
                        clef: 'statut',
                        multiple: true,
                        listeValues: Object.keys(Status)
                            .filter(k => isNaN(Number(k)) && k != Status[Status.STATUT_BROUILLON]) //On affiche pas le statut brouillon
                            .map(key => <FilterValue>{code: Status[key].toString(), libelle: this.getStatutLibelle(Status[key])}),
                        isDefault: false,
                        onloadValue: [Status.STATUT_NON_TRAITE.toString()]
                    }
                ]
            });

            //Persistence des filtres
            this.listeVehicules.isPersistFilters = true;

            //Récupération de la liste des puissances fiscales et mise à jour du filtre en conséquence
            if ((filter = this.listeVehicules.listeFilters.find(f => f.clef == 'puissanceFiscale.idPuissance'))) {
                this.vehiculeService.getListePuissanceVehicule()
                    .pipe(first(),finalize(() => filter.loading = false))
                    .subscribe(liste => {
                        //Conversion de la liste des puissances fiscales en liste de valeurs pour le filtre
                        filter.listeValues = liste.map(pf => <FilterValue>{code: pf.id.toString(10),libelle: pf.libelle});
                    });
            }
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Désouscription à l'abonnement
        this.subscription.unsubscribe();
    }

    /**
     * Permet de récupérer la traduction du statut véhicule
     * @param statut Status
     */
    getStatutLibelle(statut: Status) {
        switch (statut) {
            case Status.STATUT_NON_TRAITE:
                return this.translateService.instant('vehicule.statut.nonTraite');
            case Status.STATUT_VALIDE:
                return this.translateService.instant('vehicule.statut.valide');
            case Status.STATUT_REFUSE:
                return this.translateService.instant('vehicule.statut.refuse');
            case Status.STATUT_BROUILLON:
                return this.translateService.instant('vehicule.statut.brouillon');
            default:
                return "";
        }
    }

}
