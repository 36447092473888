import {Directive, Input} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

/**
 * Directive qui s'assure qu'un input n'est pas null
 *
 * @author Laurent SCIMIA
 * @date 17/06/2022
 */
@Directive({
    selector: '[notNullValidator]',
    providers: [{provide : NG_VALIDATORS, useExisting: NotNullValidatorDirective, multi: true}]
})
export class NotNullValidatorDirective implements Validator {
    /** Indique si le contrôle doit être actif ou non (true par défaut) */
    @Input() notNullValidator: boolean = true;

    /**
     * Remonte une erreur si le contrôle a une valeur nulle
     */
    validate(control: AbstractControl): ValidationErrors | null {
        return this.notNullValidator && control.value == null ? {isNull: true} : null;
    }
}
