import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {MigrationDetail, UpgradeBddResult} from "@domain/admin/maintenance/migration";

/**
 * Composant pour afficher une mise à jour BDD
 */
@Component({
    host: {'data-test-id': 'migration-detail-item'},
    templateUrl: './migration-detail-item.component.html',
    styleUrls: ['./migration-detail-item.component.scss']
})
export class MigrationDetailItemComponent extends ListViewItem<MigrationDetail> {
    /** Enum des résultats possibles */
    readonly UpgradeBddResult = UpgradeBddResult;

    /** Options pour l'affichage du log */
    codeMirrorOptions: any = {
        theme: 'material',
        mode: null,
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true,
        viewportMargin: Infinity
    };
}
