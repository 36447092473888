import {Pipe, PipeTransform} from '@angular/core';
import {TypeNature} from "../../../../../../../../domain/voyage/travel/constants";
import {NiveauNature} from "@components/od/detail/voyage/od-voyage.service";

/**
 * Pipe pour filtrer les regroupements de SBT d'un OD. Le tableau retourné conserve l'ordre du filtre.
 */
@Pipe({
    name: 'triListeSbt'
})
export class TriListeSbtPipe implements PipeTransform {

	/** Tableau d'ordre d'affichage des types nature dans la popup */
	readonly ordreTypeNature: TypeNature[] = [
		TypeNature.AVION,
		TypeNature.TRAIN,
		TypeNature.AVION_TRAIN,
		TypeNature.HEBERGEMENT,
		TypeNature.VOITURE_DE_LOCATION,
		TypeNature.VOITURE_PERSONNELLE,
		TypeNature.VOITURE_DE_SOCIETE,
		TypeNature.DOCUMENT
	];

    /**
     * Trie la liste des regroupements SBT disponibles pour l'OD.
     *
     * @param listeSbtParNature Liste des SBT à trier
     */
    transform(listeSbtParNature: NiveauNature[]): Array<NiveauNature> {
		//Si on a bien une liste
        if (listeSbtParNature) {
			//On la trie d'après la méthode adéquate
            return listeSbtParNature.sort((n1: NiveauNature, n2: NiveauNature) => this.triSbt(n1, n2));
        } else {
            return new Array<NiveauNature>();
        }
    }

	/**
	 * Comparateur qui trie les regroupements de SBT dans le même ordre que this.ordreTypeNature puis par type d'aiguillage
	 *
	 * @param n1 Premier regroupement à comparer
	 * @param n2 Second regroupement à comparer
	 */
	triSbt(n1: NiveauNature, n2: NiveauNature): number {
		//On récupère l'ordre du SBT dans le tableau d'ordre des natures
		const i1 = this.ordreTypeNature.indexOf(n1.nature);
		const i2 = this.ordreTypeNature.indexOf(n2.nature);

		//Si on n'a pas trouvé un des SBT, on le met en dernier
		if (i1 != -1 && i2 == -1) {
			return -1;
		} else if (i1 == -1 && i2 != -1) {
			return 1
		} else if (i1 == -1 && i2 == -1) {
			return 0;
		} else {
			//Si on a bien trouvé les 2 SBT, on les renvoie dans le même ordre que le tableau des natures
			if (i1 < i2) {
				return -1;
			} else if (i1 > i2) {
				return 1;
			}
		}

		return 0;
	}
}
