import { EngagementsFacturesItem } from "./engagements-factures-item";

/**
 * Engagements - Liste des factures d'un OD
 */
export class EngagementsFactures {
    /** Liste des engagements */
    listeEngagement: Array<EngagementsFacturesItem>;

    /** Ligne de total */
    total: EngagementsFacturesItem;

    /**
     * Constructeur
     *
     * @param engagementsFactures Données
     */
    constructor(engagementsFactures: EngagementsFactures) {
        //Vérification de la présence de données
        if (engagementsFactures) {
            //Lignes des engagements
            this.listeEngagement = engagementsFactures.listeEngagement?.map(engagement => {
                //Instanciation à partir des données
                return new EngagementsFacturesItem(engagement);
            });

            //Instanciation de la ligne de total à partir des données
            this.total = new EngagementsFacturesItem(engagementsFactures.total,true);
        }
    }

}