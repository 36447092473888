import {Component,QueryList,ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MatTab} from "@angular/material/tabs/tab";
import {ActivatedRoute,Params,Router} from '@angular/router';
import {AnalytiqueComponent} from '@components/analytique/analytique.component';
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {ChainageService} from "@components/chainage/chainage.service";
import {OMPService} from '@components/omp/omp.service';
import {ReportingService} from "@components/reporting/reporting.service";
import {AbstractObjetWorkflowComponent,OngletsAdmin} from "@components/workflow/abstract-objet-workflow.component";
import {WorkflowService} from "@components/workflow/workflow.service";
import {AppState} from "@domain/appstate";
import {Omp} from '@domain/omp/omp';
import {SettingsOTState} from "@domain/settings/settings";
import {TypeEntiteParamOT} from "@domain/typeentite/typeEntiteParam";
import {TypeProfil} from '@domain/user/user';
import {TypeAction,TypeAction as TypeActionWorkflow,TypePortee} from '@domain/workflow/workflow';
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {PageHeaderComponent} from '@share/component/page-header/page-header.component';
import {ToastrService} from "ngx-toastr";
import {Observable,of} from "rxjs";
import {first} from 'rxjs/operators';
import {OMPGeneralitesComponent} from "@components/omp/detail/generalites/omp-generalites.component";
import {OMPZoneListComponent} from "@components/omp/detail/zone/omp-zone-list.component";
import {Location} from "@angular/common";

import * as _ from "lodash";
import {SettingsService} from "@components/settings/settings.service";

@Component({
	selector: 'omp',
	templateUrl: './omp.component.html'
})
export class OMPComponent extends AbstractObjetWorkflowComponent<Omp,SettingsOTState> {
	/** Accès à l'enum dans la vue */
	readonly TypePortee = TypePortee;
	readonly OngletsAdmin = OngletsAdmin;

	/** Map de référence des conforts de transport, indexée par portée */
	mapConfort: any = null;

	/** Liste des véhicules de l'utilisateur */
	listeVehicule: any = null;

	/** Paramètres du type entité */
	typeEntiteParam: TypeEntiteParamOT = null;

    /** Nombre de préférences analytiques disponibles */
	preferenceAnalytiqueCount: number = 0;

    /** Données de backup de l'OMP. On va s'en servir pour savoir s'il a été modifié */
    backupOMP: Omp;

	/** Répartition analytique */
	@ViewChild(AnalytiqueComponent)
	private analytique: AnalytiqueComponent;

	/** Liste des zones de déplacement autorisées */
	@ViewChild(OMPZoneListComponent)
	private ompZoneListComponent: OMPZoneListComponent;

	/** Généralités */
	@ViewChild(OMPGeneralitesComponent)
	private ompGeneralitesComponent: OMPGeneralitesComponent;

	/**
	 * Constructeur
	 */
	constructor(
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected toastrService: ToastrService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected workflowService: WorkflowService,
		protected settingsService: SettingsService,
		protected chainageService: ChainageService,
		protected analytiqueService: AnalytiqueService,
		protected ompService: OMPService,
		protected reportingService: ReportingService,
		protected matDialog: MatDialog,
		protected location: Location
	) {
		super(
			translateService,
			store,
			toastrService,
			activatedRoute,
			router,
			workflowService,
			chainageService,
			analytiqueService,
			reportingService,
			matDialog,
			location,
			TypePortee.OT,
			ompService
		);
	}

    /**
     * Initialise la donnée de backup de l'objet avec les valeurs de l'objet en paramètre
     *
     * @param omp OMP à backuper
     */
    private initBackupData(omp: Omp): void {
        //On clone l'omp initial
        this.backupOMP = _.cloneDeep(omp);

        //On retire la liste des transports (la modification engendre automatiquement un enregistrement)
        delete this.backupOMP.listeTransports;

        //On retire la liste des zones de déplacement (la modification engendre automatiquement un enregistrement)
        delete this.backupOMP.listeZones;
    }

    /**
     * Indique si les infos de l'OMP ont été modifiées
     *
     * @returns {boolean} true si c'est modifié sinon false
     */
    isInfosOMPModifiees(): boolean {
        //On parcourt toutes les clés de l'objet de backup
        for (let cle of Object.keys(this.backupOMP)) {
            //Si la valeur de l'objet n'est pas la même que celle du backup
            if (!_.isEqual(this.objetWorkflow[cle], this.backupOMP[cle])) {
                //On indique qu'il y a eu modification des informations
                return true;
            }
        }

        //Si on arrive ici, c'est que rien n'a bougé
        return false;
    }

	/**
	 * Intercepte les clics sur les onglets, pour pouvoir enregistrer l'OMP au changement d'onglet, et empêcher le changement d'onglet en cas d'erreur.
	 * Admin : intercepte les clics sur les onglets admin afin de vérifier la validité de l'objet
	 */
	registerTabClick(): void {
		//Le ViewChild #pageHeader n'est pas accessible directement au moment du ngAfterViewInit à cause du *ngIf="objetWorkflow"
		//Donc on s'abonne au 1er changement, qui a lieu quand le composant pageHeader devient visible et accessible
		this.pageHeaders.changes.pipe(first()).subscribe((comps: QueryList<PageHeaderComponent>) => {
			//Récupération du composant PageHeader (il n'y en a qu'un)
			const pageHeader = comps.first;

			//Récupération du click handler par défaut
			const handleTabClick = pageHeader.tabGroupe._handleClick;

			//Modification du click handler
			pageHeader.tabGroupe._handleClick = (tab: MatTab,tabHeader: any,index: number) => {
				//Si on est sur l'onglet Généralités et qu'on clique sur un autre onglet
				if (this.selectedItem?.code === 'GENERALITES' && this.listeTabItems[index].code !== 'GENERALITES' && this.isInfosOMPModifiees() || !this.isValid()) {
					//On enregistre l'OMP (si c'est possible)
					this.saveBeforeAction(TypeAction.MODIFIER).then(isSuccess => {
						//Vérification que l'enregistrement a réussi
						if (isSuccess) {
							//On met à jour le backup de l'objet
							this.initBackupData(this.objetWorkflow);

							//On exécute click handler par défaut pour changer d'onglet
							handleTabClick.apply(pageHeader.tabGroupe,[tab,tabHeader,index]);
						}
					});
				} else {
					//Si on ne passe pas de l'onglet Généralités vers un autre, on ne fait rien à part exécuter le click hanlder par défaut
					handleTabClick.apply(pageHeader.tabGroupe,[tab,tabHeader,index]);
				}
			};
		});
	}

	/**
	 * Initialisation des onglets
	 */
	initTabs(): void {
		//Définition des onglets
		this.listeTabItems = [{
			code: 'GENERALITES',
			libelle: this.translateService.instant('omp.navigation.generalites')
		},{
			code: 'MISSIONS',
			libelle: this.translateService.instant('omp.navigation.missions')
		},{
			code: 'FRAIS',
			libelle: this.translateService.instant('omp.navigation.frais')
		}];

		if (this.user.fonction === TypeProfil.RESPONSABLE || this.user.fonction === TypeProfil.ASSISTANT || this.user.fonction === TypeProfil.COMPTABLE || this.isAdmin) {
			this.listeTabItems.push({
				code: 'FICHECOLLABORATEUR',
				libelle: this.translateService.instant('omp.navigation.ficheCollaborateur'),
			});
		}

		this.listeTabItems.push({
			code: 'COMPLEMENTS',
			libelle: this.translateService.instant('omp.navigation.complements')
		});

		//Ajout de l'onglet outils
		super.initTabs();
	}

	/**
	 * Construction de l'OMP à partir des données brutes non typées.
	 *
	 * @param data données brutes non typées
	 * @returns une instance de l'OMP
	 */
	protected buildLoadedObjet(data: any): Omp {
		//Mise à jour de la mission permanente et de la destination
		const omp = new Omp(data.omp);
		omp.destination = data.destination;

		//Transformation des heures de début / fin représentées par des String et pouvant être au format xxHxx
		omp.heureDebut = this.ompService.normalizeTime(omp.heureDebut);
		omp.heureFin = this.ompService.normalizeTime(omp.heureFin);

		//Récupération des informations depuis la réponse
		this.mapConfort = data.mapConfort;
		this.listeVehicule = data.listeVehicule;
		this.typeEntiteParam = data.typeEntiteParam;

		this.preferenceAnalytiqueCount = data.preferenceAnalytiqueCount;

        //On enregistre les infos de l'OMP au chargement pour vérifier si elles ont changé
        this.initBackupData(omp);

		//Retour
		return omp;
	}

	/**
	 * Construction d'un nouvel OMP.
	 *
	 * @returns une instance de l'OMP
	 */
	protected buildNewObjet(): Promise<Omp> {
		//Retour
		return new Promise<Omp>((resolve) => {
			resolve(new Omp());
		});
	}

	/**
	 * Vérifie si le formulaire est valide. Utilisé pour autoriser l'enregistrement d'un formulaire ou des traitements admin.
	 *
	 * @return boolean True si le formulaire est valide, False sinon
	 */
	isValid(): boolean {
		return this.ompGeneralitesComponent?.isValid()
			&& (this.ompZoneListComponent ? this.ompZoneListComponent.isValid() : true)
			&& (this.analytique ? this.analytique.isValid() : true);
	}

	/**
	 * Préparation de l'OMP avant sauvegarde.
	 *
	 * @param omp OMP à préparer
	 * @returns OMP préparé
	 */
	protected beforeSaveObjet(omp: Omp): Omp {
		//Met à jour omp à partir des valeurs saisies
		this.ompGeneralitesComponent.beforeSave();

		//Retour
		return omp;
	}

	/**
	 * Appelé avant d'exécuter une action workflow sur l'objet en vérifiant l'absence d'alertes bloquantes.
	 * Dans le cas d'une action de suppression, vérifie qu'aucun objet enfant n'est lié.
	 *
	 * @param typeAction Type d'action
	 */
	checkBeforeActionWorkflow(typeAction: TypeActionWorkflow): Observable<boolean> {
		let nbChilds = 0;

		//Vérification sur l'action de suppression
		if (typeAction == TypeAction.SUPPRIMER) {
			//Pas d'enfant, on peut continuer le processus d'exécution de l'action
			if (!this.objetWorkflow.hasChilds()) {
				//Continuation du processus d'exécution de l'action
				return super.checkBeforeActionWorkflow(typeAction);
			} else {
				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('omp.errors.suppressionEnfants'));

				//Retour de l'échec de la vérification
				return of(false);
			}
		} else {
			//Continuation du processus d'exécution de l'action
			return super.checkBeforeActionWorkflow(typeAction);
		}
	}

	/**
	 * Récupération des paramètres du contexte de navigation
	 *
	 * @param activatedRouteParams query params de la route courante
	 */
	protected initRoutingContext(activatedRouteParams: Params): void {
		//On goback, retour sur le menu missions pour l'admin et le sous-admin
		if (this.isAdmin || this.user.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
			this.routingContext = {
				returnRoute: 'Admin/Voyages/Missions',
				extras: {
					//Permet de retourner sur le bon onglet du menu missions
					queryParams: {
						tabIndex: 1
					}
				}
			}
		//Sinon retour à la liste des omp
		} else {
			this.routingContext = {
				returnRoute: 'ListeOMP'
			};
		}
	}
}
