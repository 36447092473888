import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {SearchType} from "@domain/common/list-view/sorting";
import {Filter,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Od} from "@domain/od/od";
import {OdPipeTransform} from "@share/pipe/od.pipe";
import {ImportFactureMissionSearchItem} from "@share/component/autocomplete/options/import-facture-mission/search-item/import-facture-mission.search-item";
import {ImportFactureMissionLineItem} from "@share/component/autocomplete/options/import-facture-mission/line-item/import-facture-mission.line-item";

/**
 * Options de l'autocomplete de recherche d'un OD pour le traitement des rejets d'import de factures
 */
export default class ImportFactureMissionOptions implements AutocompleteOptions {
    /** Mode de recherche */
    searchType = SearchType.CONTAINS;

    /**
     * Affichage d'un élément
     */
    displayItem(item: Od,translateService?: TranslateService): string {
        return OdPipeTransform(item,translateService);
    }

    /**
     * Composant d'affichage d'une ligne dans la popup de recherche
     */
    searchItemComponent() { return ImportFactureMissionSearchItem; }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() { return ImportFactureMissionLineItem; }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            //Ajout du filtre sur le numéro
            clef: 'idOd',
            title: translateService.instant('od.liste.filtres.id'),
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        },{
            //Ajout du filtre sur l'objet
            clef: 'objet',
            title: translateService.instant('od.liste.filtres.objet'),
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        },{
            //Ajout du filtre sur la date de départ
            clef: 'depart_le',
            title: translateService.instant('od.liste.filtres.depart_le'),
            type: TypeFilter[TypeFilter.DATE]
        }, {
            //Ajout du filtre sur la date de retour
            clef: 'retour_le',
            title: translateService.instant('od.liste.filtres.retour_le'),
            type: TypeFilter[TypeFilter.DATE]
        },{
            //Ajout du filtre sur le matricule
            clef: 'user.matricule,user.nom,user.prenom',
            title: translateService.instant('od.liste.collaborateur'),
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        }];
    }

    /**
     * Liste des filtres statiques
     */
    listeStaticFilters(filter: any): Array<Filter> {
        return [!!filter?.idCollab && {
            clef: 'user.idUser',
            valeur: filter?.idCollab,
            type: TypeFilter[TypeFilter.LONG],
            typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
        }].filter(i => !!i);
    }

    /**
     * Récupération de l'URL
     */
    url(filter?: any): string {
        return `/controller/ImportFacture/searchMission`;
    }

}
