<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }" [nSelector]="{ liste: liste,item: data,isNotSelectable: !extraOptions.isLotCreation }"><i class="icon-facture nio"></i></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo(LotItemType.FACTURE,this.data?.idFacture)" class="font-weight-bold">
                {{ data.idFacture || ('releve.liste.numeroNonDefini' | translate) }} - {{ data.type === "FAC" ? ('lot.detail.listes.itemTitle.facture' | translate:{numero: data.numero}) : ('lot.detail.listes.itemTitle.avoir' | translate:{numero: data.idFacture}) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'lot.detail.listes.type'"></strong>{{ data.type == "FAC" ? ('lot.detail.listes.types.facture' | translate) : ('lot.detail.listes.types.avoir' | translate) }}</li>
            <li><strong [translate]="'lot.detail.listes.montant'"></strong>{{ data.montant | montant: data.devise }}</li>
            <li><strong [translate]="'lot.detail.listes.contrepartie'"></strong>{{ data.contrepartie | montant: this.settings?.deviseEntreprise }}</li>
            <li><strong [translate]="'lot.detail.listes.user'"></strong>{{ data.user | user }}</li>
            <li *ngIf="data.listeLiensFacOD?.length > 0">
                <strong [translate]="'lot.detail.listes.od'"></strong>
                {{ data.listeLiensFacOD?.map(mapperOdFacture).join(' - ') }}
            </li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
            <li *ngIf="data.factureReleve?.numero != null"><strong [translate]="'lot.detail.listes.releve'"></strong>{{data.factureReleve?.numero}}</li>
        </ul>
    </div>
    <div class="info">
        <statut *ngIf="data.statut?.image" [statut]="data.statut"></statut>
    </div>
</div>
