import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction, TypeAction} from '@share/component/floating-button/floating-button';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {Router} from '@angular/router';

/**
 * Composant de la liste des départements
 *
 * @author Angeline Ha
 * @date 10/11/2023
 */
@Component({
    host: {'data-test-id': 'departement-list'},
    selector: 'departement-list',
    templateUrl: './departement-list.component.html'
})
export class DepartementListComponent implements OnInit {
    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param geographieService Service géographie
     * @param router            Router de l'application
     */
    constructor(public geographieService: GeographieService,
                private router: Router) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.createNewDepartement()
            }]
        );
    }

    /**
     * Création d'un nouveau département
     */
    createNewDepartement() {
        this.router.navigate(['Admin/Bibliotheque/Geographie/Departement/0']);
    }

}
