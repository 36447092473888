import {Component,Input,OnInit} from '@angular/core';
import {NotificationDestinataire} from "@domain/workflow/notification-destinataire";
import {FilterValue,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Priorite} from "@domain/workflow/evenement";
import {TranslateService} from "@ngx-translate/core";
import {DestinataireListItemComponent} from "./destinataire-list-item.component";
import {ListViewService} from "@share/component/list-view/list-view.service";
import {CleDeTri,Sorting} from "@domain/common/list-view/sorting";
import {Observable} from "rxjs";

/**
 * Composant d'affichage de la liste des destinataires
 *
 * @author Laurent Convert
 * @date 18/04/2024
 */
@Component({
	host: {'data-test-id': 'destinataire-list'},
	selector: 'destinataire-list',
	templateUrl: './destinataire-list.component.html'
})
export class DestinataireListComponent implements OnInit {
	/** Liste des mails de notification */
	@Input() listeDestinataires: Array<NotificationDestinataire>;

	/** Indicateur de mail obligatoire */
	@Input() isMailObligatoire: boolean;

	/** Évènement émit lors de la sélection / désélection de tous les destinataires de chaque mail de notification */
	@Input() selectAll$: Observable<boolean>;

	/** Listview des utilisateurs (niveau 2) pour chaque mail de notification (niveau 1) */
	listviewDestinataire: ListView<NotificationDestinataire,DestinataireListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param listViewService Service de gestion des listview
	 */
	constructor(private translateService: TranslateService,
				private listViewService: ListViewService<NotificationDestinataire,DestinataireListItemComponent>) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		const mapRoleFilter: Map<number,FilterValue> = new Map<number,FilterValue>();

		//Construction de la liste des rôles filtrables à partir des utilisateurs
		this.listeDestinataires.forEach(notif => {
			notif.destinataires.forEach(u => {
				if (!mapRoleFilter.has(u.idRole)) {
					mapRoleFilter.set(u.idRole,{
						code: String(u.idRole),
						libelle: u.libelleRole,
					});
				}
			});
		});

		//Initialisation de la listview
		this.listviewDestinataire = new ListView<NotificationDestinataire,DestinataireListItemComponent>({
			title: this.translateService.instant('workflow.notification.destinataires'),
			component: DestinataireListItemComponent,
			extraOptions: {
				isMailObligatoire: this.isMailObligatoire,
				selectAll$: this.selectAll$,
			},
			onRefresh: this.onListeRefreshed.bind(this),
			//Liste des filtres : ils ne concernent que les utilisateurs de chaque notification (le niveau 2 de la liste)
			listeFilters: [{
				clef: 'nom',
				title: this.translateService.instant('workflow.notification.nom'),
				isDefault: true,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'prenom',
				title: this.translateService.instant('workflow.notification.prenom'),
				isDefault: true,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'matricule',
				title: this.translateService.instant('workflow.notification.matricule'),
				isDefault: true,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: 'idRole',
				title: this.translateService.instant('workflow.notification.role'),
				isDefault: false,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.IN],
				listeValues: new Array<FilterValue>(...mapRoleFilter.values()),
				multiple: true,
			},{
				clef: 'prioriteKey',
				title: this.translateService.instant('workflow.notification.priorite.title'),
				isDefault: false,
				type: TypeFilter[TypeFilter.STRING],
				typeComparaison: TypeComparaison[TypeComparaison.IN],
				listeValues: Priorite.values().map(priorite => {
					return {
						code: priorite.valueOf(),
						libelle: this.translateService.instant(`workflow.notification.priorite.${priorite.valueOf()}`)
					}
				}),
				multiple: true,
			}],
			isFrontendList: true,
			isFilter: true,
			isSearchBar: true,
			isFilterVisible: true,
			showPagination: false,
			data: {
				numPage: 0,
				nbPagesTotal: 1,
				listeResultats: this.listeDestinataires,
				nbObjetsDansPage: this.listeDestinataires.length,
				nbObjetsParPage: this.listeDestinataires.length,
				nbObjetsTotal: this.listeDestinataires.length
			}
		});

		//Définition des colonnes de tri
		this.listviewDestinataire.columns = [
			{key: 'destinataires.prioriteForFilter',title: 'workflow.notification.priorite.title'},
			{key: 'destinataires.nom',title: 'workflow.notification.nom'},
			{key: 'destinataires.prenom',title: 'workflow.notification.prenom'},
			{key: 'destinataires.matricule',title: 'workflow.notification.matricule'},
			{key: 'libelleRole',title: 'workflow.notification.role'},
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listviewDestinataire.sorting = new Sorting(this.listviewDestinataire.columns,"destinataires.prioriteForFilter,destinataires.nom,destinataires.prenom");
	}

	/**
	 * Rafraichissement de la liste des destinataires en fonction des filtres / tris
	 *
	 * @param liste Liste des destinataires
	 */
	onListeRefreshed(liste: ListView<NotificationDestinataire,DestinataireListItemComponent>) {
		//Listes des clés de tri sous forme de tableau pour leur manipulation
		const listeCleDeTriDestinataires: Array<CleDeTri> = [];
		const listeCleDeTriNotifications: Array<CleDeTri> = [];

		//Listes des clés de tri sous forme de chaîne pour application
		let cleDeTriDestinataires: string;
		let cleDeTriNotifications: string;

		//Dispatch des clés dans la bonne liste suivant leur préfixe
		[liste.sorting?.key1,liste.sorting?.key2,liste.sorting?.key3].forEach(key => {
			if (key?.colonne?.key.startsWith('destinataires.')) {
				//Clé applicable sur les destinataires (niveau 2 de la liste)
				listeCleDeTriDestinataires.push(new CleDeTri({...key.colonne,key: key.colonne.key.substring('destinataires.'.length)},key.tri));
			} else if (!!key?.colonne) {
				//Clé applicable sur les notifications (niveau 1 de la liste)
				listeCleDeTriNotifications.push(key);
			}
		});

		//Formatage des clés en chaîne
		cleDeTriDestinataires = Sorting.getSortingAsFormattedString(listeCleDeTriDestinataires);
		cleDeTriNotifications = Sorting.getSortingAsFormattedString(listeCleDeTriNotifications);

		//Parcours des mails de notification
		liste.data.listeResultats.forEach(notif => {
			//Parcours des destinataires
			notif.destinataires.forEach(u => {
				//Mise à jour du destinataire pour indiquer s'il correspond aux filtres ou non
				u.isFiltre = !this.listViewService.isObjectMatchSearch(u,liste);
			});

			//Tri de la liste des destinataires
			if (liste.sorting?.columns?.length > 0) {
				this.listViewService.sortFrontendList(notif.destinataires,cleDeTriDestinataires);
			}
		});

		//Tri de la liste des notifications
		if (liste.sorting?.columns?.length > 0) {
			this.listViewService.sortFrontendList(liste.data.listeResultats,cleDeTriNotifications);
		}

		//Actualisation de l'affichage
		liste.onFiltrageFront$.next();
	}

}