/**
 * Action workflow contenant les informations nécessaires à un mouvement workflow par api
 */
export class ActionWorkflow {
	/** Type d'action à effectuer */
	typeAction: string;

	/** Statut cible de l'objet workflow */
	statutCible: string;

	/** Activation des actions comptables */
	traitementComptable?: boolean;

	/** Remarque */
	remarque?: string;

	/** Constructeur avec les éléments principaux */
	constructor(typeAction: string,statutCible: string) {
		this.typeAction = typeAction;
		this.statutCible = statutCible;
	}
}