import {NgModule} from '@angular/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {InputNumberModule} from '../../directive/number/input-number.module';
import {AutocompleteModule} from '../autocomplete/autocomplete.module';
import {ValidatorModule} from '../../directive/validator/validator.module';


import {CoreComponentsModule} from '../core-components.module';
import {CustomInputComponent} from './custom-input.component';
import {MatDividerModule} from "@angular/material/divider";
import {CommonModule} from "@angular/common";
import {NgxIbanModule} from "ngx-iban";
import {NgxMatIntlTelInputModule} from "ngx-mat-intl-tel-input";
import {DateRangeCustomComponent} from "./sub-component/date-range-custom/date-range-custom.component";
import {TooltipModule} from "@share/directive/tooltip/tooltip.module";

@NgModule({
    imports: [CoreComponentsModule, CommonModule, TranslateModule, AutocompleteModule, MatSelectModule, MatDatepickerModule, InputNumberModule, MatDividerModule, NgxIbanModule,NgxMatIntlTelInputModule,ValidatorModule,TooltipModule],
    declarations: [CustomInputComponent,DateRangeCustomComponent],
    exports: [CustomInputComponent,DateRangeCustomComponent]
})
export class CustomInputModule {}
