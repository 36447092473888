<div class="with-floating-button">
    <div class="content">
        <page-header
                [title]="'admin.frais.referentiels.title' | translate"
                [listeItems]="listeTabItems"
                [selectedTab]="selectedItem$.asObservable()"
                (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <list-view *ngIf="listeReferentiels" [liste]="listeReferentiels"></list-view>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>