<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.pays.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToDetailPays()">
                <span *ngIf="data.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.code2.toLowerCase()"></span>
                {{ data.libelle }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code2">
                <strong [translate]="'admin.bibliotheque.geographie.pays.code2'"></strong>
                <span>{{ data.code2 }}</span>
            </li>
            <li *ngIf="data.code3">
                <strong [translate]="'admin.bibliotheque.geographie.pays.code3'"></strong>
                <span>{{ data.code3 }}</span>
            </li>
            <li *ngIf="data.devise">
                <strong [translate]="'admin.bibliotheque.geographie.pays.devise'"></strong>
                <span>{{ data.devise }}</span>
            </li>
            <li *ngIf="!data.actif">
                <strong [translate]="'admin.bibliotheque.geographie.pays.inactif'"></strong>
                <span>{{ 'global.oui' | translate }}</span>
            </li>
        </ul>
    </div>
</div>
