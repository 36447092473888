import {User} from "../user/user";
import {ParticipantLien} from "../participant/participant-lien";
import {ParticipantExterne} from "../participant/participant-externe";
import {ParticipantAvecFavoris} from "../participant/participant-avec-favoris";

/**
 * Classe de transfert des {@link LienParticipant} vers le front
 *
 * @author Laurent Convert
 * @date 07/03/2022
 */
export class LienParticipant extends ParticipantLien {
	/** Identifiant de la liaison facture - ordre de mission */
	idLienParticipant: number;

	/** Participant interne */
	user: User;

	get id(): number { return this.idLienParticipant; }
	set id(id: number) { this.idLienParticipant = id; }

	get participantInterne(): User { return this.user; }
	set participantInterne(participantInterne: User) { this.user = participantInterne; }

	set notOrganisateur(notOrganisateur: boolean ) { }
	get notOrganisateur(): boolean { return undefined; }

	/**
	 * Constructeur
	 *
	 * @param participant Participant utilisé pour initialiser les valeurs
	 */
	constructor(participant: ParticipantExterne | ParticipantAvecFavoris | LienParticipant) {
		super();

		//Le participant passé en paramètre vient de la création d'un participant externe
		if (participant && participant instanceof ParticipantExterne) {
			this.initFromParticipantExterne(participant);
		} else if (participant && ParticipantAvecFavoris.isParticipantAvecFavoris(participant)) {
			//Cas du participant avec favoris
			this.initFromParticipantAvecFavoris(participant);
		} else {
			//Le paramètre est de type LienParticipant
			Object.assign(this,participant);
		}
	}

}