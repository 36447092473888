<div class="lvi-content clickable">
    <div class="avatar">
        {{ getIconeFromLibelle(data.libelle) }}
    </div>
    <div class="lvi-body">
        <div class="title" *ngIf="data.libelle">
            <a class="hover-disabled">
                {{ data.libelle }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code">
                <strong translate>admin.parametres.demat.pieceJointes.filtre.code</strong>
                {{ data.code }}
            </li>
        </ul>
    </div>
    <!-- bouton supprimer -->
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="onSupprimer()" translate>global.actions.supprimer</button>
        </mat-menu>
    </div>
</div>
