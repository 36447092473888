import {Component,EventEmitter,Input,OnInit,Output,ViewChild} from '@angular/core';
import {TypeProfil,User} from '@domain/user/user';
import {Collaborateur} from "@domain/user/collaborateur";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ReferentielDto} from "@domain/admin/entreprise/referentielDto";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {SettingsGlobalState} from '@domain/settings/settings';
import {filterFirstNotNull} from '@share/utils/rxjs-custom-operator';
import {Compte} from "@domain/comptabilite/compte";
import {Unite} from "@domain/prestation/unite";
import {MatDialog} from "@angular/material/dialog";
import {SelectModeRembPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/select-mode-remb-popin/select-mode-remb-popin.component";
import {FranchisePopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/franchise-popin/franchise-popin.component";
import {CompteurFiscalPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-fiscal-popin.component";
import * as moment from "moment";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {NgForm} from '@angular/forms';
import {CollabInfo} from '@domain/user/collab-info';
import {Entreprise} from "@domain/admin/entreprise/entreprise";
import {ListView} from "@domain/common/list-view";
import {PreferenceAnalytique} from "@domain/profil/preferenceAnalytique";
import {ProfilPreferenceAnalytiquesListItemComponent} from "@components/profil/user/preferences-analytiques/profil-preference-analytiques-list-item.component";
import {ProfilPreferenceAnalytiquesAddComponent} from "@components/profil/user/preferences-analytiques/profil-preference-analytiques-add.component";

/**
 * Onglet "Métier" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-metier'},
	selector: 'user-metier',
	templateUrl: './user-metier.component.html'
})
export class UserMetierComponent implements OnInit {
	/** Utilisateur courant */
	@Input() user: User;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	@Input() isCollabInit: boolean;

	/** Indicateur de modification de l'utilisateur */
	@Output() userChanged: EventEmitter<void> = new EventEmitter();

	/** Visibilité des infos métier */
	@Input() isDroitInfoMetier: boolean;

	/** Visibilité des frais mission */
	@Input() isDroitFraisMission: boolean;

	/** Visibilité des préférences analytiques */
	@Input() isDroitPrefAnalytiques: boolean;

	/** Les settings globaux de l'appli */
	settings: SettingsGlobalState

	/** Collaborateur courant */
	collab: Collaborateur;

	/** Indicateur de chargement */
	isPending: boolean = false;

	/** Liste des titres */
	listeTitre: ReferentielDto[];

	/** Liste des catégories */
	listeCategorie: ReferentielDto[];

	/** Liste des qualifications */
	listeQualification: ReferentielDto[];

	/** Liste des statuts */
	listeStatut: ReferentielDto[];

	/** Infos de l'entreprise */
	entreprise: Entreprise;

	/** Liste des plans comptables */
	listePlanComptable: Compte[];

	/** Liste des unités */
	listeUnite: Unite[];

	/** Nombre de franchises */
	nbContratUser: number;

	/** Gère-t-on les barèmes kilométriques au mois ? */
	isBaremeMensuel: boolean;

	/** Mode de calcul des IK */
	modeCalculIK: number;

	/** Liste des préférences analytiques */
	listePrefAnalytique: ListView<PreferenceAnalytique,ProfilPreferenceAnalytiquesListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param store le store
	 * @param userService le service de gestion de l'utilisateur
	 * @param translateService le moteur de traduction
	 * @param toastrService le service du toaster
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private store: Store<AppState>,
		private userService: EntrepriseUtilisateursService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private matDialog: MatDialog
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Début de chargement
		this.isPending = true;

		//Chargement des settings dans le store
		this.store.select(state => state.settings?.Global).pipe(filterFirstNotNull()).subscribe((settings: SettingsGlobalState) => {
			//Récupération des settings
			this.settings = settings;

			//Chargement de l'utilisateur
			this.userService.loadCollab(this.user.idUser)
				.pipe(first(),finalize(() => this.isPending = false))
				.subscribe((result: Result) => {
					//Vérification du résultat
					if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
						//Récupération du collaborateur
						this.collab = result.data.collab;

						//Initialisation des infos collab si elles ne sont pas valorisées
						if (!this.collab.infosMetier) {
							this.collab.infosMetier = new CollabInfo();
							this.collab.infosMetier.idUser = this.user.idUser;
						}

						//Initialisation de l'unité si elle n'est pas valorisée
						if (!this.collab.idUnite) {
							this.collab.idUnite = 0;
						}

						//Initialisation des comptes s'ils ne sont pas valorisés
						if (!this.collab.numCompta) {
							this.collab.numCompta = '';
						}
						if (!this.collab.compteAvance) {
							this.collab.compteAvance = '';
						}
					} else {
						//Initialisation d'un collaborateur
						this.collab = new Collaborateur();

						//Valeurs par défaut
						this.collab.idCollab = this.user.idUser;
						this.collab.matricule = this.user.matricule;
						this.collab.modificateurIndemns = 100;
						this.collab.codeDevise = settings.deviseEntreprise;
						this.collab.infosMetier = new CollabInfo();
						this.collab.infosMetier.idUser = this.user.idUser;
					}

					//Récupération de la liste des titres
					let titre: ReferentielDto = new ReferentielDto();
					titre.idReferentiel = 0;
					titre.libelle = this.translateService.instant('global.input.choisissez');
					this.listeTitre = [titre,...result.data.listeTitre];

					//Récupération de la liste des catégories
					this.listeCategorie = result.data.listeCategorie;

					//Récupération de la liste des qualifications
					let qualification: ReferentielDto = new ReferentielDto();
					qualification.idReferentiel = 0;
					qualification.libelle = this.translateService.instant('global.input.choisissez');
					this.listeQualification = [qualification,...result.data.listeQualification];

					//Récupération de la liste des statuts collab
					let statut: ReferentielDto = new ReferentielDto();
					statut.idReferentiel = 0;
					statut.libelle = this.translateService.instant('global.input.choisissez');
					this.listeStatut = [statut,...result.data.listeStatut];

					//Récupération des infos entreprise
					this.entreprise = result.data.entreprise;

					//Récupération de la liste des plans comptables
					let compte: Compte = new Compte();
					compte.numero = '';
					compte.libelle = '(' + this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.fraisMission.compteDefaut') + ')'
					this.listePlanComptable = [compte,...result.data.listePlanComptable.map((c: Compte) => {
						return {
							...c,
							libelle: c.numero + ' - ' + c.libelle
						}
					})];

					//Récupération de la liste des unités
					let unite: Unite = new Unite();
					unite.idUnite = 0;
					unite.libelle = this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.fraisMission.uniteDefaut');
					this.listeUnite = [unite,...result.data.listeUnite];

					//Récupération du nombre de franchises
					this.nbContratUser = result.data.nbContratUser;

					//Récupération du flag de gestion des barèmes IK
					this.isBaremeMensuel = result.data.isBaremeMensuel;

					//Récupération du mode de calcul des IK
					this.modeCalculIK = result.data.modeCalculIK;

					//Définition des actions possibles
					this.listeActions.next([
						{
							type: TypeAction.PRIMARY,
							icone: 'nio icon-sauvegarde',
							libelle: 'global.actions.enregistrer',
							doAction: () => this.saveCollab(),
							isDisabled: () => this.form?.invalid || this.countNbModeRemb() === 0 || this.isPending
						}
					]);
				});

			//Initialisation de la liste des préférences analytiques
			this.listePrefAnalytique = new ListView<PreferenceAnalytique,ProfilPreferenceAnalytiquesListItemComponent>({
				uri: `/controller/ProfilUser/Analytique/getListePrefAnalytiques/${this.user.idUser}`,
				title: this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.prefAnalytiques.title'),
				component: ProfilPreferenceAnalytiquesListItemComponent,
				isFilter: false,
				extraOptions: {
					settings: this.settings,
					isAdmin: true
				},
				listeActions: this.settings?.analytiqueAdminManagement && [{
					icon: 'add',
					onPress: () => this.addPrefAnalytique()
				}]
			});
		});
	}

	/**
	 * Appelé au clic sur le champ "Mode de remboursement"
	 */
	onClickModeRemb(): void {
		//Ouverture de la popin de sélection du mode de remboursement
		this.matDialog.open(SelectModeRembPopinComponent,{
			data: {collab: this.collab}
		}).afterClosed().subscribe((collab: Collaborateur) => {
			if (collab) {
				//On modifie le collaborateur courant
				this.collab = collab;
			}
		});
	}

	/**
	 * Compte du nombre de modes de remboursement
	 */
	countNbModeRemb(): number {
		if (this.collab?.modeRemb && this.collab.modeRemb.trim() != "") {
			return this.collab.modeRemb.trim().split(' ').length;
		} else {
			return 0;
		}
	}

	/**
	 * Appelé au clic sur le champ "Franchise"
	 */
	onClickFranchise(): void {
		//Ouverture de la popin des franchises
		this.matDialog.open(FranchisePopinComponent,{
			data: {idUser: this.user.idUser},
			panelClass: 'mat-dialog-without-margin',
			width: '60%'
		}).afterClosed().subscribe((nbFranchises: number) => {
			this.nbContratUser = nbFranchises;
		});
	}

	/**
	 * Appelé au clic sur le champ "Compteur fiscal"
	 */
	onClickCompteurFiscal(): void {
		//Ouverture de la popin du compteur fiscal
		this.matDialog.open(CompteurFiscalPopinComponent,{
			data: {
				idUser: this.user.idUser,
				isBaremeMensuel: this.isBaremeMensuel,
				modeCalculIK: this.modeCalculIK
			},
			panelClass: 'mat-dialog-without-margin',
			width: '60%'
		});
	}

	/**
	 * Sauvegarde du collaborateur
	 */
	saveCollab(): void {
		//Chargement en cours
		this.isPending = true;

		//Recherche de l'identifiant du rôle collaborateur
		const idRoleCollab: number = this.user.listeLienRoleUsers
			.filter(lru => !lru.dateFin || moment().isBefore(lru.dateFin,'day'))
			.find(lru => lru.role.fonction === TypeProfil.COLLABORATEUR)?.role.idRole;

		//Appel au service
		this.userService.saveCollab(this.collab,idRoleCollab)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du résultat de l'appel
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Si le collaborateur n'était pas encore initialisé
					if (!this.isCollabInit) {
						//On recharge l'utilisateur pour réinitialiser le flag isCollabInit
						this.userChanged.emit();
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Conversion d'un masque en Regex
	 *
	 * @param masque le masque du champ
	 */
	masqueToRegex(masque: string): RegExp {
		//Initialisation du modèle
		let pattern: string = "^(";

		//Parcours des caractères du masque
		for (let i = 0; i < masque?.length; i++) {
			//Pour chaque caractère, on ajoute le morceau de regex correspondant
			switch (masque.charAt(i)) {
				case 'Z' :
					pattern += "[a-zA-Z]";
					break;
				case '9' :
					pattern += "[0-9]";
					break;
				case '?' :
					pattern += "[a-zA-Z0-9]";
					break;
				case '*' :
					pattern += "[\\S ]";
					break;
			}
		}

		//Fin de la regex
		pattern += "){1}$";

		return new RegExp(pattern);
	}

	/**
	 * Ajout d'une préférence analytique
	 */
	addPrefAnalytique(): void {
		this.matDialog.open<ProfilPreferenceAnalytiquesAddComponent,any,boolean>(ProfilPreferenceAnalytiquesAddComponent,{
			data: {
				idUser: this.user.idUser,
				settings: this.settings,
				isFromAdmin: true
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification du retour
			if (refresh) {
				//Rechargement de la liste
				this.listePrefAnalytique.refresh();
			}
		});
	}
}