<mat-card>
    <mat-card-title>
        <span [translate]="'referentielExterne.title'"></span>
        <span *ngIf="!isAdmin" class="actions">
            <mat-icon (click)="addReferenceExterne()">add</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body" *ngIf="!isLoading">
                <div class="listview-item" *ngFor="let referenceExterne of listeRefExternes">
                    <referentiel-externe-item [referenceExterne]="referenceExterne" [listeChildrenReferenceExterne]="mapRefFils[referenceExterne.idRef]"></referentiel-externe-item>
                </div>
                <div class="empty" *ngIf="!listeRefExternes?.length && !isLoading"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
        <please-wait class="content" *ngIf="isLoading"></please-wait>
    </mat-card-content>
</mat-card>