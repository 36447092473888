import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OdEngagementsIndemnitesComponent } from "@components/od/detail/engagements/indemnites/od-engagements-indemnites.component";
import { Contexte } from "@domain/od/engagements/indemnites/contexte";
import { Od } from "@domain/od/od";
import { first } from "rxjs/operators";

/**
 * Composant d'affichage des indemnités
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-frais-indemnites',
	templateUrl: './od-frais-indemnites.component.html'
})
export class OdFraisIndemnitesComponent {
	/** Déclaration pour usage depuis le template */
	Contexte = Contexte;

	/** Ordre de mission */
	@Input() od: Od = null;

	/** Composant des indemnités */
	@ViewChild("indemnites") indemnites: OdEngagementsIndemnitesComponent;

	/** Indicateur de modification possible */
	@Input() canModifier: boolean = false;
	
	/** Indicateur de complétion possible */
	@Input() canCompleter: boolean = false;
	
	/** Évènement déclenché après la mise à jour des dégrèvements (dans la popin) */
	@Output() onDegrevementsChanged = new EventEmitter<void>();

	/**
	 * Rechargement du composant des indemnités
	 */
	refreshOnChangeDegrevements(): void {
		//Écoute de l'évènement de fin du calcul des indemnités
		this.indemnites.onCalculIndemnitesDone.pipe(first()).subscribe(() => {
			this.onDegrevementsChanged.emit();
		});
		
		//Déclenchement du calcul des indemnités
		this.indemnites.refresh();
	}
}
