import {Directive,DoCheck,ElementRef,HostListener,Inject,Input,OnDestroy,OnInit,Optional} from '@angular/core';

import {ListView} from '@domain/common/list-view';
import {ListItem} from '@domain/common/list-view/list-item';
import {FloatingButtonService} from "@share/component/floating-button/floating-button.service";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {implementsIPopupIgnoreFloatingButton} from "@share/component/popup/IPopupIgnoreFloatingButton";

@Directive({
	selector: '[nSelector]'
})
export class ListViewSelectorDirective implements OnInit,DoCheck,OnDestroy {
	/** Données */
	@Input() nSelector: { liste: ListView<any,any>,item: ListItem,isNotSelectable?: boolean };

	/** Liste des souscriptions à ne pas oublier */
	private listeSouscriptions: Array<Subscription> = new Array<Subscription>();

	/**
	 * Constructeur
	 *
	 * @param elementRef            Injection du DOM pour pouvoir le modifier
	 * @param matDialog             Service des popups material
	 * @param floatingButtonService Injection du service de gestion des boutons d'action (l'instanciation est gérée par le provider de {@link FloatingButtonDirective}). Peut être null (@Optional) si la page concernée n'a pas une gestion avancée des boutons d'action
	 */
	constructor(private elementRef: ElementRef<HTMLInputElement>,
				private matDialog: MatDialog,
				@Optional() @Inject('FLOATING_BUTTON_SERVICE') private floatingButtonService?: FloatingButtonService) {
	}

	ngOnInit() {
		//Si c'est un item sélectionnable
		if (!this.nSelector.isNotSelectable) {
			//Ajout de la classe
			this.elementRef.nativeElement.classList.add('selectable');

			//Définition de l'icône de base
			this.elementRef.nativeElement.setAttribute('data-selected','\uf279');

			//Calcul du nombre initial d'éléments sélectionnés
			this.nSelector.liste.nbSelectedItems = this.nSelector.liste.data?.listeResultats?.reduce((previousValue,currentValue) => previousValue + (currentValue?.isSelected ? 1 : 0),0);
		}

		//S'il y a une gestion des floating buttons active
		if (!!this.floatingButtonService) {
			//On va s'abonner aux ouvertures de toutes les popups pour tout désélectionner dans la liste à l'ouverture
			//Attention, on ne veut pas que ce soit le cas sur toutes les popup. Les popup à ignorer doivent implémenter IPopupIgnoreFloatingButton
			this.listeSouscriptions.push(this.matDialog.afterOpened.subscribe(dialog => {
				//Si on n'est pas sur une popup qui implémente IPopupIgnoreFloatingButton
				if (!implementsIPopupIgnoreFloatingButton(dialog.componentInstance)) {
					//On désélectionne tout (ça va également lancer un reset via la souscription sur le updatePagination$)
					this.nSelector.liste.selectAll(false);
				}
			}));

			//On va s'abonner aux mises à jour de pagination de la liste
			this.listeSouscriptions.push(this.nSelector.liste.updatePagination$.subscribe(() => {
				//S'il n'y a pas d'objets sélectionnés
				if (this.nSelector.liste.nbSelectedItems === 0) {
					//On reset les actions des boutons
					this.resetListeActions();
				}
			}));
		}
	}

	/**
	 * Interception d'un click sur l'élément
	 */
	@HostListener('click')
	onClick() {
		//Cet item n'est pas sélectionnable on ignore le selector
		if (this.nSelector.isNotSelectable) {
			return;
		}

		let isSelected: boolean;

		//Sélection de l'élément
		this.nSelector.item.isSelected = !this.nSelector.item.isSelected;

		//Lecture de l'état
		isSelected = this.nSelector.item.isSelected;

		//Mise à jour du nombre d'éléments sélectionnés
		this.nSelector.liste.nbSelectedItems = (this.nSelector.liste.nbSelectedItems || 0) + (isSelected ? 1 : -1);

		//Si on a un service de gestion des boutons flottants
		if (!!this.floatingButtonService) {
			// Si la liste n'a plus d'objets sélectionnés
			if (this.nSelector.liste.nbSelectedItems === 0) {
				//On reset les boutons d'action s'ils sont définis pour la liste
				this.resetListeActions();
			} else if (this.nSelector.liste.listeActionsSpecifiques != null && this.floatingButtonService.listeActions != this.nSelector.liste.listeActionsSpecifiques) {
				//S'il y a une gestion des actions spécifiques à la liste et qu'on vient de sélectionner un premier élément, on change les actions disponibles
				this.floatingButtonService.isActionsSpecifiques = true;
				this.floatingButtonService.setListeActions(this.nSelector.liste.listeActionsSpecifiques);
			}
		}
	}

	/**
	 * Réinitialise la liste des actions
	 */
	private resetListeActions(): void {
		//On reset les boutons d'action s'ils sont définis pour la liste
		this.floatingButtonService.resetFloatingButtonActions();

		//On indique qu'on n'est plus sur des actions spécifiques
		this.floatingButtonService.isActionsSpecifiques = false;
	}

	/**
	 * Vérification des changements
	 */
	ngDoCheck() {
		//Cet item n'est pas sélectionnable on ignore le selector
		if (this.nSelector.isNotSelectable) {
			return;
		}

		//Mise à jour de l'icône
		this.elementRef.nativeElement.setAttribute('data-selected',this.nSelector.item.isSelected ? '\uf26b' : '\uf279');
	}

	/**
	 * À la destruction du composant
	 */
	ngOnDestroy(): void {
		//S'il y a une gestion des boutons flottants
		if (!!this.floatingButtonService) {
			//On remet à jour les actions
			this.resetListeActions();
		}

		//On n'oublie pas de désouscrire à tout
		this.listeSouscriptions.forEach(sub => sub.unsubscribe());
	}
}