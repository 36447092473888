import {Component, Inject, OnInit} from '@angular/core';
import {NDFService} from "@components/ndf/ndf.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {first} from "rxjs/operators";
import {CalculIkDetail} from "@domain/ik/calcul-ik-detail";
import {CompteurDepense, CompteurDepenseListItem} from "@domain/ik/compteur-depense";
import {Vehicule} from "@domain/vehicule/vehicule";
import {ListView} from "@domain/common/list-view";
import {CompteurDepenseListItemComponent} from "@components/ndf/popup-detail-calcul-ik/list-items/compteur-depense-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {DetailCalculIkListItemComponent} from "@components/ndf/popup-detail-calcul-ik/list-items/detail-calcul-ik-list-item.component";
import {Alerte, NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Composant de la popup de détail du calcul d'une indemnité kilométrique
 */
@Component({
    host: { 'data-test-id': 'popup-detail-calcul-ik' },
    selector: 'popup-detail-ik',
    templateUrl: './popup-detail-calcul-ik.component.html'
})
export class PopupDetailCalculIkComponent implements OnInit {

    /** Véhicule */
    vehicule: Vehicule;

    /** Compteur de la dépense */
    compteurDepense: CompteurDepense;

    /** Détails du calcul de l'IK */
    detailsCalculIk: CalculIkDetail[];

    /** Liste du compteur de la dépense */
    listeCompteurDepense: ListView<CompteurDepenseListItem,CompteurDepenseListItemComponent>;

    /** Liste du détail du calcul de l'IK */
    listeDetailCalcul: ListView<CalculIkDetail,DetailCalculIkListItemComponent>;

    /** Alerte en cas de manque de données (IK créées avant la 8.6.1) */
    alerte: Alerte;

    /**
     * Constructeur
     *
     * @param data les paramètres en entrée
     * @param ndfService le service de gestion des NDF
     * @param translateService le service de traduction
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { idDepense: number, idVehicule: number },
                private ndfService: NDFService,
                private translateService: TranslateService) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Chargement du détail du calcul de l'IK
        this.ndfService.loadDetailCalculIk(this.data.idDepense,this.data.idVehicule).pipe(first()).subscribe(result => {
            this.vehicule = result.vehicule;
            this.detailsCalculIk = result.listeDetailBareme;
            this.compteurDepense = new CompteurDepense(result.compteurDepense);

            if (this.detailsCalculIk.length > 0) {
                //Appel à la méthode d'initialisation des listes
                this.initLists();
            } else {
                //Création d'une alerte
                this.alerte = {
                    niveau: NiveauAlerte.ERROR,
                    titre: this.translateService.instant('ndf.frais.popupDetailIk.erreur'),
                    message: this.translateService.instant('ndf.frais.popupDetailIk.noData')
                }
            }

        });
    }

    /**
     * Méthode d'initialisation des listes
     */
    private initLists(): void {
        //Initialisation de la liste représentant le compteur de la dépense
        this.listeCompteurDepense = new ListView<CompteurDepenseListItem,CompteurDepenseListItemComponent>({
            title: this.translateService.instant('ndf.frais.popupDetailIk.compteurDepense.title',{ marque: this.vehicule.marque, modele: this.vehicule.modele, immatriculation: this.vehicule.immatriculation, puissanceFiscale: this.vehicule.libellePuissanceFiscale }),
            component: CompteurDepenseListItemComponent,
            isFrontendList: true,
            data: {
                listeResultats: this.compteurDepense.toCompteurDepenseList(),
                numPage: 0,
                nbPagesTotal: 0,
                nbObjetsTotal: 0,
                nbObjetsParPage: 0,
                nbObjetsDansPage: 0
            },
            extraOptions: {
                unite: this.compteurDepense.unite,
                dateExecutionTheorique: this.compteurDepense.dateExecutionTheorique,
                dateExecutionReel: this.compteurDepense.dateExecutionReel
            }
        });

        //Initialisation de la liste représentant le détail du calcul de l'IK
        this.listeDetailCalcul = new ListView<CalculIkDetail,DetailCalculIkListItemComponent>({
            title: this.translateService.instant('ndf.frais.popupDetailIk.detailCalcul.title'),
            component: DetailCalculIkListItemComponent,
            isFrontendList: true,
            data: {
                listeResultats: this.detailsCalculIk,
                numPage: 0,
                nbPagesTotal: 0,
                nbObjetsTotal: 0,
                nbObjetsParPage: 0,
                nbObjetsDansPage: 0
            }
        });
    }
}
