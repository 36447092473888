import {Component,Inject,OnDestroy} from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {WorkflowRolesService} from "@services/admin/workflow/workflow-roles.service";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {BehaviorSubject,merge,Subject,Subscription} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TranslateService} from "@ngx-translate/core";
import {filter,finalize,first} from "rxjs/operators";
import {RoleLong} from "@domain/workflow/roleLong";
import {ProfilsAttribuables} from "@domain/workflow/profilsAttribuables";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ItemHierarchique} from "@share/component/affichage-hierarchique/ItemHierarchique";
import {RolesListeItemComponent} from '../liste/roles-liste-item.component';
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant de gestion du détail d'un rôle
 *
 * @author Laurent SCIMIA
 * @date 12/06/2023
 */
@Component({
	host: {'data-test-id': 'roles-detail'},
	templateUrl: './roles-detail.component.html'
})
export class RolesDetailComponent implements OnDestroy {
	//Accès à l'enum pour le template
	readonly ProfilsAttribuables = ProfilsAttribuables;

	//Constante d'erreur
	readonly ERREUR_ROLE_UTILISE = 901;

	/** Rôle ouvert */
	role: RoleLong;

	/** Liste des différents onglets disponibles dans le menu "Devises" de l'entreprise */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Liste de tous les droits disponibles à plat. Est initialisée ici pour l'accès, mais remplie dans RoleDroitsComponent */
	listeAllDroits: Array<ItemHierarchique> = [];

	/** Map qui retient quels onglets ont été chargés */
	ongletsCharges: Map<string,boolean> = new Map();

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = Onglets;

	/** Indique si on est en train d'enregistrer le rôle */
	isSaving: boolean = false;

	/** Indique si on est en train de charger le rôle */
	isLoading: Subject<boolean> = new Subject<boolean>();

	/** Souscription pour désouscription à la fin */
	souscription: Subscription;

	/** Subject pour indiquer qu'il faut rafraîchir les onglets */
	refreshOnglet: Subject<void> = new Subject<void>();

	/**
	 * Constructeur
	 *
	 * @param router			Service de routing
	 * @param roleService		Service de gestion du rôle
	 * @param translateService	Service de traduction
	 * @param toastrService		Service de gestion des toasts
	 * @param route				Accès à la route du composant
	 * @param confirmService	Service de confirmation pour la suppression
	 */
	constructor(private router: Router,
				@Inject('WORKFLOW_ROLE_SERVICE') private roleService: WorkflowRolesService<RolesListeItemComponent>,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private confirmService: ConfirmService,
				private route: ActivatedRoute) {

		//On indique au service qu'on est dans le détail
		this.roleService.isInDetail = true;

		//Indique que le rôle est en chargement
		this.isLoading.next(true);

		//On souscrit au changement sur le chargement de l'objet ainsi que sur la demande de refresh des onglets
		this.souscription = merge(this.isLoading.pipe(filter(isLoading => !isLoading)),this.refreshOnglet).subscribe(() => {
			// L'onglet généralité est toujours présent
			this.listeTabItems = [
				{
					code: Onglets.GENERALITE,
					libelle: this.translateService.instant('workflow.roles.navigation.generalites'),
					selected: true
				}
			];

			// L'onglet des droits si le profil sélectionné est SSA
			if (this.role.fonction === ProfilsAttribuables.SOUS_ADMINISTRATEUR) {
				this.listeTabItems.push({
					code: Onglets.DROITS,
					libelle: this.translateService.instant('workflow.roles.navigation.droits'),
				});
			}

			// Onglet usage si on n'est pas sur une création de rôle
			if (this.role.idRole != null) {
				this.listeTabItems.push({
					code: Onglets.USAGE,
					libelle: this.translateService.instant('workflow.roles.navigation.usage'),
				});
			}

			//On sélectionne le premier onglet si aucun déjà sélectionné ou que celui sélectionné n'est plus accessible
			if (this.selectedItem == null || !this.listeTabItems.some(tab => tab.code === this.selectedItem.code)) {
				this.onSelectedItemChange(this.listeTabItems[0]);
			}

			//Liste des actions possibles
			this.listeActions.next([
				{
					type: TypeAction.PRIMARY,
					icone: 'nio icon-sauvegarde',
					libelle: 'global.actions.enregistrer',
					isDisabled: () => !this.role.isValid(),
					doAction: () => this.enregistrerRole()
				},{
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					isVisible: () => !!this.role.idRole,
					doAction: () => this.supprimerRole(),
					isDisabled: () => this.role.used || this.role.fonction === ProfilsAttribuables.COLLABORATEUR && (this.roleService.nbRolesCollab < 2 || this.role.defaut),
					tooltip: () => this.getTooltipSuppression()
				}]);
		});

		// On récupère l'id du rôle demandé dans la route
		let idRole = this.route.snapshot.paramMap.get("idDetail");

		this.initRole(Number(idRole));
	}

	/**
	 * Renvoie le tooltip à afficher sur le bouton de suppression
	 *
	 * @returns {any}
	 */
	getTooltipSuppression(): string {
		let text = 'global.actions.supprimer';

		//Si le rôle est utilisé
		if (this.role.used) {
			text = 'workflow.roles.detail.erreurRoleUtilise'
		} else if (this.role.fonction === ProfilsAttribuables.COLLABORATEUR) {
			//Si le rôle est le dernier rôle collaborateur disponible
			if (this.roleService.nbRolesCollab < 2) {
				text = 'workflow.roles.detail.erreurDernierRoleCollab';
			} else if (this.role.defaut) {
				//Si le rôle est indiqué comme étant par défaut
				text = 'workflow.roles.detail.erreurRoleDefaut';
			}
		}

		//On renvoie la traduction du tooltip à afficher
		return this.translateService.instant(text);
	}

	/**
	 * Initialisation du rôle
	 *
	 * @param idRole Identifiant du rôle à initialiser
	 */
	private initRole(idRole: number) {
		//On récupère le nombre de rôles collaborateurs qui existent déjà
		this.roleService.countRolesCollab().pipe(first()).subscribe((nbRoles: number) => {
			this.roleService.nbRolesCollab = nbRoles

			// Si l'id est 0, c'est une création
			if (idRole == 0) {
				//On crée un nouveau rôle et on dit que c'est chargé
				this.role = new RoleLong();

				//S'il n'y a pas de rôle collab par défaut, on initialise celui-là pour qu'il le soit
				this.role.defaut = nbRoles === 0;
				this.role.backupDefaut = this.role.defaut;

				this.isLoading.next(false);
			} else {
				// Si on a un id, on charge le rôle
				this.roleService.getRole(idRole).pipe(first())
					.subscribe(role => {
						this.role = new RoleLong(role);
						this.isLoading.next(false);
					});
			}
		});
	}

	/** Destruction du composant */
	ngOnDestroy() {
		// On indique qu'on quitte le détail
		this.roleService.isInDetail = false;

		// Et bien sûr, on n'oublie pas de se protéger (des mémory leak)
		this.souscription.unsubscribe();
	}

	/** Méthode de retour arrière */
	goBack() {
		// Retour à la liste des rôles
		this.router.navigate(['Admin/Workflow/Roles/Roles']);
	}

	/**
	 * Enregistrement du rôle
	 */
	enregistrerRole() {
		this.isSaving = true;

		//Enregistrement du rôle
		this.roleService.enregistrerRole(this.role,this.listeAllDroits).pipe(finalize(() => this.isSaving = false)).subscribe(result => {
			if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
				// Si c'est bien enregistré, on met un message
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				// On refresh la liste pour voir les changements
				this.roleService.getListeRoles(RolesListeItemComponent).refresh();

				this.isLoading.next(true);

				//Rechargement du rôle
				this.initRole(result.data.idRole);

				//On s'assure que l'id du rôle soit bien dans la route
				this.router.navigate(['Admin/Workflow/Roles/Roles/' + result.data.idRole]);
			} else {
				// Si ça ne s'est pas bien passé, on met un message d'erreur
				let messageErreur: string;

				//Gestion des erreurs
				switch (result.codeErreur) {
					case TypeCodeErreur.ERROR_DOUBLON:
						//Cas d'un doublon
						messageErreur = "workflow.roles.detail.erreurDoublon";
						break;
					case TypeCodeErreur.ERROR_VALIDATE_INPUT:
						//Cas d'un input invalide
						messageErreur = "global.errors.formInvalid";
						break;
					default:
						//Cas générique
						messageErreur = "global.errors.enregistrement";
						break;
				}

				//Message d'erreur
				this.toastrService.error(this.translateService.instant(messageErreur));
			}
		});
	}

	/**
	 * Suppression du rôle
	 */
	supprimerRole() {
		//On demande une confirmation de suppression
		 this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
				//Suppression du rôle
				this.roleService.supprimerRole(this.role.idRole).subscribe(result => {
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						// Si ça s'est bien supprimé, on met un message
						this.toastrService.success(this.translateService.instant('global.success.suppression'));

						// On refresh la liste pour voir les changements
						this.roleService.getListeRoles(RolesListeItemComponent).refresh();

						// On retourne à la liste
						this.goBack();
					} else {
						// Si ça ne s'est pas bien passé, on met un message d'erreur
						let messageErreur: string;

						//Gestion des erreurs
						switch (result.codeErreur) {
							case this.ERREUR_ROLE_UTILISE:
								//Cas du rôle déjà utilisé
								messageErreur = "workflow.roles.detail.erreurRoleUtilise";
								break;
							default:
								//Erreur générique
								messageErreur = "global.errors.suppression";
								break;
						}

						//Message d'erreur
						this.toastrService.error(this.translateService.instant(messageErreur));
					}
				});
			});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		if (!!selectedItem) {
			//Mise à jour de l'onglet sélectionné
			this.selectedItem = selectedItem;

			//Changement d'onglet
			this.ongletsCharges.set(selectedItem.code,true)
		}
	}
}

/**
 * Enum pour les noms des différents onglets de la page Devises et de la page d'informations d'une devise
 */
export enum Onglets {
	GENERALITE = "Generalite",
	DROITS = "Droits",
	USAGE = "Usage"
}
