import {Axe5DTO,Axe6DTO,DossierDTO,OrgaDTO} from "@domain/od/od";
import {PreferenceAnalytique} from "@domain/profil/preferenceAnalytique";
import {SuiviBudget} from "@domain/budget/suivi-budget";

/**
 * Modèle représentant une ligne d'analytique
 */
export class Analytique {
    /* Données */
    id?: number
    pourcentage: number;
    orga?: OrgaDTO;
    dossier?: DossierDTO;
    axe5?: Axe5DTO;
    axe6?: Axe6DTO;
    code1?: string;
    code2?: string;
    listeEnveloppes?: Array<SuiviBudget>;

    idEnveloppe?: number;
    idDossier?: number;
    idOrga?: number;
    idAxe5?: number;
    idAxe6?: number;

    /** Indique si l'analytique a été mis à jour */
    isUpdated?: boolean;
    
    /**
     * Construit une ventilation analytique à partir d'une préférence analytique d'un utilisateur
     *
     * @param preferenceAnalytique Préférence analytique de l'utilisateur
     */
    constructor(preferenceAnalytique?: PreferenceAnalytique) {
        if(preferenceAnalytique) {
            this.pourcentage = 100;
            this.orga = preferenceAnalytique.orga;
            this.dossier = preferenceAnalytique.dossier;
            this.axe5 = preferenceAnalytique.axe5
            this.axe6 = preferenceAnalytique.axe6;
            this.code1 = preferenceAnalytique.field5;
            this.code2 = preferenceAnalytique.field6;
        }
    }
}

export class AnalytiqueSaisie extends Analytique{
    isSelection: boolean;
    isRemoved: boolean;
}