import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'avanceDevise'
})
/**
 * Pipe pour filtrer les devises en fonction de la nature d'une avance
 */
export class AvanceDevisePipe implements PipeTransform {

  transform(items: any[], nature: String): any {
    //On filtre la liste des devises en fonction de la nature de l'avance
    return items && nature ? nature == "ESPECE" ? items.filter(item => item.avanceEspece) : items.filter(item => item.avanceVirement) : items;
  }

}
