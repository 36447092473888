import {ListItem} from "../common/list-view/list-item";
import {TypeProfil} from "@domain/user/user";
import {RoleOption} from "@domain/workflow/roleOption";

/**
 * DTO Rôle
 */
export class Role implements ListItem {
	idRole: number;
	libelle: string;
	fonction: TypeProfil;
	code: string;
	specificite: string;
	delegable: boolean;
	listeRoleOption: Array<RoleOption>;
	/** A initialiser manuellement à partir de listeRoleOption */
	isRespBudg: boolean;
	modeTitulaire: boolean;

	constructor(dto: any) {
		if (dto) {
			this.idRole = dto.idRole;
			this.libelle = dto.libelle;
            this.fonction = dto.idProfil;
            this.code = dto.code;
		}
	}

	getKey(): number {
		return this.idRole;
	}
}