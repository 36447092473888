<h1 mat-dialog-title>
    <span [translate]="'admin.frais.referentiels.infos.icones.title'"></span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-md-2 clickable" *ngFor="let icone of listeIcones">
            <i *ngIf="icone" class="material-icons-outlined" (click)="onSelect(icone)" style="font-size: 3em;">{{ icone }}</i>
            <span *ngIf="!icone" (click)="onSelect(icone)">Aucune</span>
        </div>
    </div>
</div>