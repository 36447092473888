<div class="with-floating-button">
    <div *ngIf="!isLoading" class="content">
        <page-header (onGoBack)="onGoBack()" [title]="'profilUser.personnalisation.title' | translate" [niveauAlerte]="(isReconnexionNecessaire && niveauAlerte) || null">
            <preview-alerte *ngIf="isReconnexionNecessaire" [alerte]="alerte"></preview-alerte>
        </page-header>

        <mat-card>
            <mat-card-title>
                <span [translate]="'profilUser.personnalisation.cardTitle'"></span>
            </mat-card-title>
            <mat-card-content>
                <form autocomplete="off">
                    <fieldset>
                        <div class="row">
                            <custom-input name="Langue"
                                          id="Langue"
                                          customType="select"
                                          libelle="profilUser.personnalisation.langue"
                                          [(ngModel)]="selectedLangue"
                                          optionValue="code"
                                          optionDisplay="libelle"
                                          ngDefaultControl
                                          [selectOptions]="listeLangues"
                                          [required]="true"></custom-input>
                        </div>
                    </fieldset>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <floating-button [isPending]="isSaving" [listeActions]="listeActions"></floating-button>
</div>