import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {OrigineExtension,PieceJointeExtension} from "@domain//admin/parametre/demat/piece-jointe-extension";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage d'un item de la liste des extensions
 *
 * @author Lionel Gimenez
 * @date 25/09/2023
 */
@Component({
    templateUrl: './extension-liste-item.component.html',
    host: {'data-test-id': 'extension-liste-item'},
})
export class ExtensionListeItemComponent extends ListViewItem<PieceJointeExtension> {
    /** Déclaration(s) pour le template */
    OrigineExtension = OrigineExtension;

    /**
     * Constructeur
     */
    constructor(
        private dematService: DematService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {
        super();
    }

    /**
     * Supprime l'extension
     */
    delete(): void {
        this.dematService.deleteExtension(this.data.idExtension)
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));

                        //On refresh la liste
                        this.liste.refresh();
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                }
            });
    }

}