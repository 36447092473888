<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': alertes.isWarning(),'danger': alertes.isDanger() }" [nSelector]="{ liste: liste,item: data }">
        <i class="nio icon-voiture align-middle"></i>
    </div>
    <br/>
    <div class="lvi-body">
        <div class="title">
            <strong>
                <a [routerLink]="" (click)="navigateToVehicule(data)">{{ data.immatriculation }} - {{ data.marque }} {{ data.modele }}</a>
            </strong>
        </div>
        <ul class="lvi-attrs">
            <ng-template [ngIf]="this.isResponsable()">
                <li><strong [translate]="'vehicule.liste.user'"></strong>{{ data.user | user }}</li>
                <li><strong [translate]="'vehicule.liste.puissanceFiscale'"></strong>{{ data.puissanceFiscale?.libelle }}</li>
                <!-- Affichage des compteurs (normalement il ne devrait y avoir qu'un seul, celui de l'année courante) -->
                <li *ngFor="let cpt of data.listeCompteur"><strong [translate]="'vehicule.liste.distance'"></strong>{{ cpt.compteurReel }} {{ cpt.unite?.libelle }}</li>
                <li><strong [translate]="'vehicule.liste.assurance'"></strong>{{ (data.assurePro ? 'global.oui' : 'global.non') | translate }}</li>
                <li><strong [translate]="'vehicule.liste.statut'"></strong>{{ getStatusLibelle(data.statut) | translate }}</li>
                <li *ngIf=" alertes.isWarning() || alertes.isDanger()" (click)="showListeWarning()" class="clickable" [ngClass]="{ 'back-warning': alertes.isWarning(),'back-danger': alertes.isDanger() }"><strong
                    [translate]="'workflow.alerte.title'"></strong><span [translate]="'vehicule.liste.alerte.'+(alertes.isRejet ? 'vehiculeRejete' : 'documentManquant')
"></span></li>
            </ng-template>
        </ul>
    </div>
</div>
