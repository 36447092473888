import {Component} from '@angular/core';

/**
 * Composant d'affichage de l'usage des rôles
 *
 * @author Laurent SCIMIA
 * @date 09/10/2023
 */
@Component({
	host: {'data-test-id': 'role-usage'},
	selector: 'role-usage',
	templateUrl: './role-usage.component.html'
})
export class RoleUsageComponent {
}