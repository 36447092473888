import {ChangeDetectorRef,Component,OnDestroy,OnInit} from '@angular/core';
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {IStatutApplication,KeyStatutApplication,KeyStatutWorkflow,LISTE_STATUTS_APPLICATION,TEMPO_STATUT} from "@domain/admin/statut-application/statut-application";
import {Subscription} from "rxjs";
import {AppStatut,MigrationsService} from "@services/admin/maintenance/migrations.service";
import {MatDialog} from "@angular/material/dialog";
import {MigrationBddPopupComponent} from "@components/admin/maintenance/migrations/migration-bdd-popup.component";
import {ListeMigrationPopupComponent} from "@components/admin/maintenance/migrations/liste-migration-popup.component";
import {Router} from "@angular/router";
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";

/**
 * Composant de gestion du statut de l'application
 */
@Component({
    selector: 'statut-application',
    templateUrl: './statut-application.component.html',
    styleUrls: ['./statut-application.component.scss']
})
export class StatutApplicationComponent implements OnInit,OnDestroy {
    /** Clés de statut de l'application */
    protected readonly KeyStatutApplication = KeyStatutApplication;

    /** Statut de l'application */
    appStatut: AppStatut;

    /** Statut du Workflow */
    synchroWfStatut: IStatutApplication;

    /** Souscriptions */
    souscriptions: Subscription[] = [];

    /** Progression */
    progression: number = 0.00;

    /** Mode de la progress bar */
    modeProgressBar: "buffer" | "indeterminate" = "buffer";

    /** Valeur du buffer de la progressbar */
    bufferValue: 0 | 100;

    /** ID de l'intervalle */
    private intervalId: NodeJS.Timeout;

    /** Temps (ms) restant avant écoulement de la temporisation */
    private millisecondsToTrigger: number;

    /** Statut de mise hors ligne */
    isHorsLigne: boolean = false;

    /** Élément non clickable */
    get isNonClickable(): boolean { return this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.HORS_LIGNE || this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.HORS_LIGNE_TEMPO; }

    /**
     * Constructeur
     */
    constructor(
        private statutWorkflowService: StatutWorkflowService,
        private statutApplicationUserService: StatutApplicationUserService,
        private migrationsService: MigrationsService,
        protected matDialog: MatDialog,
        private ref: ChangeDetectorRef,
        private router: Router) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void  {
        //Rafraichissement du statut de l'application
        this.migrationsService.checkAppliStatut();

        //Rafraichissement du statut Workflow
        this.statutWorkflowService.loadSynchroWFStatut();

        //Abonnement à la mise à jour du statut du Workflow
        this.souscriptions.push(this.statutWorkflowService.synchroWFStatut$.subscribe(statut => {
            //Mise à jour du statut
            this.synchroWfStatut = statut;

            //Si retour en none
            if (statut?.statut == KeyStatutWorkflow.HISTO_WF_NONE) {
                //Réinitialisation du statut de l'application
                this.appStatut = {
                    etape: undefined,
                    nbDone: 0,
                    nbTotal: 0,
                    progression: 0
                } as AppStatut;
            }

            //Gestion de la tempo si besoin
            this.handleTempo();

            //Détection forcée des changements du composant
            this.ref.detectChanges();
        }));

        //Abonnement à la mise à jour du mode hors ligne
        this.souscriptions.push(this.statutApplicationUserService.statut$.subscribe(statut => this.isHorsLigne = (statut == KeyStatutApplication.HORS_LIGNE || statut == KeyStatutApplication.HORS_LIGNE_TEMPO)));

        //Appel pour vérification du mode hors ligne
        this.statutApplicationUserService.checkPublicApplicationStatus();

        //Abonnement à la mise à jour du statut de l'application
        this.souscriptions.push(this.migrationsService.appStatut$.subscribe(statut => {
            //Si le statut Admin est EN_LIGNE et le statut application est MAINTENANCE_FORCED
            if (statut?.applicationStatut == KeyStatutApplication.HORS_LIGNE && statut?.applicationStatutAdmin?.statut == KeyStatutApplication.EN_LIGNE) {
                //On force l'affichage de la maintenance pour que l'admin n'oublie pas qu'il est en maintenance forcée
                statut.applicationStatutAdmin = LISTE_STATUTS_APPLICATION.get(KeyStatutApplication.HORS_LIGNE);
            }

            //Mise à jour du statut
            this.appStatut = statut;

            //Si retour en ligne
            if (statut?.applicationStatutAdmin?.statut == KeyStatutApplication.EN_LIGNE) {
                //Réinitialisation du statut de l'application
                this.appStatut = {
                    etape: undefined,
                    nbDone: 0,
                    nbTotal: 0,
                    progression: 0
                } as AppStatut;
            }

            //Si l'application est en erreur
            if (statut?.applicationStatutAdmin?.statut == KeyStatutApplication.ERREUR_TASK) {
                //Si l'erreur concerne la BDD
                if (statut?.isBddUpgradeError) {
                    //Application du message secondaire approprié
                    this.appStatut.applicationStatutAdmin.messageSecondaire = 'admin.statut.application.locked3';
                } else if (statut?.isMigrationError) {
                    //Sinon si elle concerne les tâches de migration, alors message secondaire correspondant
                    this.appStatut.applicationStatutAdmin.messageSecondaire = 'admin.statut.application.locked2';
                }
            }

            //Si le statut est en attente maintenance forcée
            if (this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.HORS_LIGNE_TEMPO) {
                //Gestion de la tempo
                this.handleTempo();

                //Paramétrage de la valeur du buffer de la progressbar
                this.bufferValue = 100;
            } else if (this.appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE) {
                //Sinon progression issue du statut
                this.progression = this.appStatut?.progression || 0;

                //Paramétrage de la valeur du buffer de la progressbar
                this.bufferValue = 0;
            }

            //Détection forcée des changements du composant
            this.ref.detectChanges();
        }));
    }

    /**
     * Navigation vers la page de maintenance des migrations ou popup pour la BDD
     */
    goToMaintenance(): void {
        //Si l'application n'est ni en maintenance ni en passe de l'être
        if (this.appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE && this.appStatut?.applicationStatutAdmin?.statut != KeyStatutApplication.HORS_LIGNE_TEMPO) {
            //Si l'application est en cours de migration de la BDD
            if (this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.PRE_MIGRATION || this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.MIGRATION) {
                //Ouverture de la popup de migration BDD
                this.matDialog.open(MigrationBddPopupComponent, {
                    minWidth: 600,
                    maxWidth: 600,
                    minHeight: 200
                });
            } else if (this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.ERREUR_TASK) {
                //Sinon si l'application est bloquée, on vérifie si c'est une erreur de BDD
                if (this.appStatut.isBddUpgradeError) {
                    //Navigation vers l'historique
                    this.router.navigate(['Admin/Maintenance/Migrations/Historique']);
                } else if (this.appStatut.isMigrationError) {
                    //Sinon si c'est une erreur de migration, navigation vers la liste de tâches avec forçage du filtre par défaut
                    this.router.navigate(['Admin/Maintenance/Migrations/Maintenance'], { queryParams: { forceAExecuter: true } });
                }
            } else {
                //Ouverture de la popup avec la liste des migrations
                this.matDialog.open(ListeMigrationPopupComponent, {
                    minWidth: 1200,
                    maxWidth: 1200,
                    minHeight: 100,
                    maxHeight: 800
                });
            }
        }
    }

    /**
     * Clic sur le bouton d'annulation de la tempo Workflow
     */
    handleSynchroWFClick(): void {
        //Forçage de la progression à 100%
        this.progression = 100;

        //Emission du signal pour shunter la temporisation
        this.statutWorkflowService.handleSynchroWFClick();
    }

    /**
     * Gestion de la temporisation
     */
    handleTempo(): void {
        //Reset de l'intervalle précédent
        clearInterval(this.intervalId);

        //Si le statut est de type COUNTDOWN
        if (this.synchroWfStatut?.statut == KeyStatutWorkflow.HISTO_WF_COUNTDOWN || this.appStatut?.applicationStatutAdmin?.statut == KeyStatutApplication.HORS_LIGNE_TEMPO) {
            //Adaptation du mode de la progress bar
            this.modeProgressBar = "buffer";

            //Application de la constante appropriée
            const total: number = this.synchroWfStatut?.statut == KeyStatutWorkflow.HISTO_WF_COUNTDOWN ? StatutApplicationUserService.TEMPO_VEILLE : StatutApplicationUserService.TOTAL_TEMPO_MAINTENANCE;

            //Application de la valeur appropriée
            const valeur: number = this.synchroWfStatut?.statut == KeyStatutWorkflow.HISTO_WF_COUNTDOWN ? this.synchroWfStatut[TEMPO_STATUT] : this.appStatut[TEMPO_STATUT];

            //Sauvegarde du temps restant
            this.millisecondsToTrigger = valeur;

            //Définition d'un intervalle de progression pour faire évoluer la barre en attendant le prochain appel
            this.intervalId = setInterval(() => {
                //Incrément local du temps restant
                this.millisecondsToTrigger -= StatutApplicationUserService.TEMPO_PROGRESSION_LOCALE;

                //Calcul du pourcentage d'avancement de la tempo
                this.progression = (total - this.millisecondsToTrigger) * 100 / total;
            }, StatutApplicationUserService.TEMPO_PROGRESSION_LOCALE);

            //Calcul du pourcentage d'avancement de la tempo
            this.progression = (total - valeur) * 100 / total;
        } else {
            //Sinon adaptation du mode de la progress bar
            this.modeProgressBar = "indeterminate";
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //Désabonnements
        this.souscriptions.forEach(souscription => souscription.unsubscribe());
    }
}
