import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Filtre d'une zone utilisateur
 */
export class FiltreZU implements ListItem {
	idZU: number;
	typeFiltre: TypeFiltreZU;
	idObjet: number;
	libelleObjet: string;
	actif: boolean;

	getKey(): string {
		return this.idZU + this.typeFiltre + this.idObjet;
	}
}

/**
 * Énumération des types de filtre d'une Zone Utilisateur
 */
export enum TypeFiltreZU {
	TYPE_ENTITE = "TYPE_ENTITE",
	ROLE = "ROLE"
}