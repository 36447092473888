import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Compagnie} from "@domain/profil/compagnie";
import {TranslateService} from "@ngx-translate/core";
import {Carte} from "@domain/admin/voyages/referentiels/carte";
import {Classe} from "@domain/admin/voyages/referentiels/classe";

/**
 * Service de gestion des référentiels des voyages
 */
@Injectable()
export class VoyagesReferentielsService {
    /** Liste des prestations possibles pour une compagnie */
    readonly listePrestations = [{
        id: 0,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
    },{
        id: 1,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
    },{
        id: 2,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
    },{
        id: 3,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
    },{
        id: 4,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
    },{
        id: 5,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
    },{
        id: 6,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.document')
    },{
        id: 7,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
    },{
        id: 8,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avance')
    },{
        id: 9,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport1')
    },{
        id: 10,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport2')
    },{
        id: 11,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport3')
    },{
        id: 12,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service1')
    },{
        id: 13,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service2')
    },{
        id: 14,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service3')
    }];

    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     * @param translateService Service de traduction
     */
    constructor(private http: HttpClient,
                private translateService: TranslateService) {
    }

    /**
     * Récupération d'une compagnie selon un identifiant
     *
     * @param idCompagnie Identifiant de la compagnie à récupérer
     */
    getCompagnieById(idCompagnie: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/consultCompagnie?idCompagnie=` + idCompagnie, null).pipe(first());
    }

    /**
     * Récupération de la liste des compagnies, sans filtre
     */
    getListeCompagnies(): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/listeCompagnieNoFilter`).pipe(first());
    }

    /**
     * Sauvegarde de la compagnie
     *
     * @param compagnie Informations de la compagnie à sauvegarder
     */
    saveCompagnie(compagnie: Compagnie): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/saveCompagnie`, compagnie).pipe(first());
    }

    /**
     * Suppression d'une compagnie
     *
     * @param idCompagnie Identifiant de la compagnie à supprimer
     */
    deleteCompagnie(idCompagnie: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/deleteCompagnie?idCompagnie=` + idCompagnie, null).pipe(first());
    }

    /**
     * Récupération d'une carte voyageur selon un identifiant
     *
     * @param idCarte Identifiant de la carte voyageur à récupérer
     */
    getCarteVoyageurById(idCarte: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/consultCarte?idCarte=` + idCarte, []).pipe(first());
    }

    /**
     * Sauvegarde d'une carte voyageur
     *
     * @param carte Carte voyageur à sauvegarder en base de données
     */
    saveCarteVoyageur(carte: Carte): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/saveCarte`, carte).pipe(first());
    }

    /**
     * Suppression d'une carte voyageur
     *
     * @param idCarte Identifiant de la carte voyageur à supprimer
     */
    deleteCarte(idCarte: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/deleteCarte?idCarte=` + idCarte, []).pipe(first());
    }

    /**
     * Récupération d'une classe selon un identifiant
     *
     * @param idClasse Identifiant de la classe à récupérer
     */
    getClasseById(idClasse: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/consultClasse?idClasse=` + idClasse, []).pipe(first());
    }

    /**
     * Sauvegarde d'une classe
     *
     * @param classe Classe à sauvegarder en base
     */
    saveClasse(classe: Classe): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/saveClasse`, classe).pipe(first());
    }

    /**
     * Suppression d'une classe
     *
     * @param idClasse Identifiant de la classe à supprimer
     */
    deleteClasse(idClasse: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/deleteClasse?idClasse=` + idClasse, []).pipe(first());
    }

    /**
     * Synchronisation des différentes données dans le menu Référentiels du menu Voyage
     *
     * @param isSynchroCompagnie Information sur si les compagnies doivent être synchronisées ou non
     * @param isSynchroCarte Information sur si les cartes voyageurs doivent être synchronisées ou non
     * @param isSynchroClasse Information sur si les classes doivent être synchronisées ou non
     */
    synchroniser(isSynchroCompagnie: boolean, isSynchroCarte: boolean, isSynchroClasse: boolean): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubReferentiel/synchroniser?isSynchroCompagnie=` + isSynchroCompagnie + `&isSynchroCarte=` + isSynchroCarte + `&isSynchroClasse=` + isSynchroClasse, []).pipe(first());
    }
}
