import {Component,Inject,OnDestroy,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {ODService} from "../../../../../od.service";
import {Result} from "@domain/common/http/result";
import {finalize,first} from "rxjs/operators";
import {Od} from "@domain/od/od";
import {Motif,SaisieEtapeDTO,TypeDepart} from "@domain/travel/saisie-etape-dto";
import {BehaviorSubject,Observable,Subscription} from "rxjs";
import {TypeAiguillage,TypeNature} from "@domain/voyage/travel/constants";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {ProfilService} from "@components/profil/profil.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ResultSynchro} from "@domain/profil/profilVoyageur";
import {MapAction} from "@domain/workflow/mapAction";
import {NiveauNature} from "@components/od/detail/voyage/od-voyage.service";

/**
 * Composant de popup pour le choix de la nature et le remplissage des trajets
 *
 * @author Laurent SCIMIA
 * @date 04/01/2022
 */
@Component({
    host: {'data-test-id': 'od-voyage-travel-popup'},
    templateUrl: './od-voyage-travel-popup.component.html'
})
export class OdVoyageTravelPopupComponent implements OnInit, OnDestroy {
    /** Indique si on doit afficher la liste des SBT ou la page de création d'une étape */
    showTrajet: boolean = true;

    /** SBT sélectionné */
    sbt: SynchroSBTConfigUser;

    /** Regroupement des SBT pour la nature sélectionné */
    niveauNature: NiveauNature;

    /** Etape à visualiser (dans le cas où on a cliqué sur une étape déjà existante, sinon null */
    etape: SaisieEtapeDTO = null;

    /** Liste des motifs pour les étapes de type véhicule */
    listeOptionMotifs: Motif[] = null;

    /** Liste des SBT disponibles pour l'OD */
    listeSbt: Observable<SynchroSBTConfigUser[]>;

    /** Liste des SBT organisés par nature */
	listeSbtParNature: Observable<Array<NiveauNature>>;

    /** Souscription pour éviter Alzheimer (la fuite mémoire) */
    souscription: Subscription;

    /** Indique si la synchronisation du profil est en cours */
    synchroProfilEnCours: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    /** Constructeur */
    constructor(private odService: ODService,
                private matDialogRef: MatDialogRef<OdVoyageTravelPopupComponent, [boolean, number[], number]>,
                private profilService: ProfilService,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: { devise: string, od: Od, etape?: SaisieEtapeDTO, listeSbt: Observable<SynchroSBTConfigUser[]>, listeParNature: Observable<Array<NiveauNature>>, isSbtDejaSynchro$: BehaviorSubject<boolean>, hasProposition: boolean, hasEtape: boolean, dvActions: MapAction, canModifier: boolean, isProfilVoyageurValide: boolean }) {
    }

    /**
     * Méthode lancée à l'initialisation du composant
     */
    ngOnInit() {
        //On récupère la liste des SBT
        this.listeSbt = this.data.listeSbt;

        //On récupère la liste des SBT organisée par nature
        this.listeSbtParNature = this.data.listeParNature;

        //Lors de la mise à jour de la liste des SBT
        this.souscription = this.listeSbt.subscribe(listeSbt => {
            //Si on a au moins un SBT de véhicule qui n'est pas ONLINE
            if (listeSbt.find(sbt => sbt.typeAiguillage != TypeAiguillage.ONLINE && [TypeNature.VOITURE_DE_LOCATION, TypeNature.VOITURE_DE_SOCIETE, TypeNature.VOITURE_PERSONNELLE].includes(sbt.idNature))) {
                //On charge la liste des motifs
                this.loadVehiculeMotifs();
            }

            //Si la liste des SBT n'a pas encore été synchro
            if (!this.data.isSbtDejaSynchro$.getValue()) {

                //On vérifie si le profil doit être synchronisé et lance la synchronisation le cas échéant
                let synchroProfilReussie: boolean = this.verifSynchroSbt(listeSbt);
                if (synchroProfilReussie) {
                    //On indique que la liste a déjà été synchro
                    this.data.isSbtDejaSynchro$.next(true);
                }
            } else {
                this.synchroProfilEnCours.next(false);
            }
        });

        //Si on est sur de la visualisation d'étape existante
        if (this.data.etape) {
            //On signale qu'on ne veut pas voir la sélection du trajet, on va direct visualiser l'étape
            this.showTrajet = false;

            //S'il n'y a pas de date de retour, on passe le type du retour à aucun
            if (this.data.etape.jourDepartRetour == null) {
                this.data.etape.typeDepartRetour = TypeDepart.AUCUN;
            }

            //On récupère les infos du SBT
            this.listeSbt.pipe(filterFirstNotNull()).subscribe(listeSbt => {
                //On récupère le SBT correspondant à l'étape
                this.sbt = listeSbt
                    .find(s => s.idSBTConfig == this.data.etape.sbt.idSBTConfig //On cherche à récupérer une config SBT égale
                                                    && (s.idNature == this.data.etape.sbt.idNature //Et soit ce SBT propose la nature exacte recherchée
                                                        || s.typeAiguillage == TypeAiguillage.ONLINE && s.idNature == TypeNature.AVION_TRAIN && [TypeNature.AVION, TypeNature.TRAIN].includes(this.data.etape.sbt.idNature)));//Soit on est en ONLINE, et la nature recherchée est Avion ou Train et le SBT propose AVION/TRAIN

                //On crée le regroupement par nature correspondant au SBT
                this.niveauNature = new NiveauNature(this.sbt.idNature);
                this.niveauNature.listeSbt.push(this.sbt);

                //On met le SBT trouvé dans l'étape chargée sinon il va écraser celui de la nouvelle saisie étape (alors qu'il n'est pas complet).
                this.data.etape.sbt = this.sbt;

                //On crée l'étape à afficher correspondant à l'étape du back
                const etape = new SaisieEtapeDTO(this.sbt, this.data.etape.jourDepartAller, this.data.etape.jourDepartRetour, this.data.etape.heureDepartAller, null, null, true, this.data.etape.typeConvenance);
                this.etape = Object.assign(etape, this.data.etape);
            });
        }
    }

    /**
     * Vérifie si le profil voyageur doit être synchronisé et lance la synchronisation le cas échéant.
     *
     * @param listeSbt Liste des SBT concernés
     * @returns Si la synchro est réussie ou non
     */
    private verifSynchroSbt(listeSbt: SynchroSBTConfigUser[]): boolean {
        let synchroProfilReussie: boolean = false;
        //Si le profil voyageur est valide et qu'il y a des SBT non synchronisés ou si le profil a été mis à jour
        if (this.data.isProfilVoyageurValide && listeSbt.some(sbt => sbt.typeAiguillage === TypeAiguillage.ONLINE && (sbt.updated === true || sbt.synchro === false))) {
            //Appel du service de synchro
            this.profilService.syncProfilVoyageurCollab(this.data.od.getIdCollab()).pipe(first(), finalize(() => this.synchroProfilEnCours.next(false))).subscribe({
                next: result => {
                    //Si la synchro n'est pas autorisée
                    if (result?.codeErreur === 901) {
                        //Message d'info RGPD
                        this.toastrService.warning(this.translateService.instant('profilUser.infoPerso.synchroPV.synchroImpossibleRGPD'),
                            this.translateService.instant('profilUser.infoPerso.synchroPV.synchroImpossibleRGPDTitle'),
                            {toastClass: "ngx-toastr toast-rgpd"});
                    } else if (result?.codeErreur === 0 && result?.data.listeSynchro) {
                        //On récupère la liste des synchro ONLINE
                        let listeSynchroOnline = (<SynchroSBTConfigUser[]>result.data.listeSynchro).filter(sync => sync.typeAiguillage === TypeAiguillage.ONLINE);

                        //On parcourt la liste des SBT Online
                        listeSbt.filter(sbt => sbt.typeAiguillage === TypeAiguillage.ONLINE)
                            //Et on met à jour leur synchro
                            .forEach(sbt => sbt.synchro = listeSynchroOnline.find(sync => sync.idParam === sbt.idParam)?.synchro);

                        //On gère les toasts de la synchro
                        synchroProfilReussie = this.profilService.resultSynchroToast(<ResultSynchro[]>result.data.listeResultSynchro);
                    } else {
                        //Erreur technique
                        this.toastrService.error(this.translateService.instant('profilUser.infoPerso.synchroPV.erreurSynchroTech'));
                    }
                }
            });
        } else {
            this.synchroProfilEnCours.next(false);
        }
        return synchroProfilReussie;
    }

    /**
     *  A la destruction du composant
     */
    ngOnDestroy() {
        //On oublie pas de unsub
        this.souscription.unsubscribe();
    }

    /**
     * Sélection du regroupement de SBT à utiliser
     */
    selectNiveauNature($event: NiveauNature) {
        this.sbt = $event.getSbtPrincipal();
        this.niveauNature = $event;
        this.showTrajet = false;
    }

    /**
     * Retour de la création d'étape vers la liste des SBT
     */
    retourArriere() {
        this.sbt = null;
        this.showTrajet = true;
    }

    /**
     * Chargement des motifs de véhicule
     */
    private loadVehiculeMotifs() {
        //Si on n'a pas déjà chargé la liste des motifs
        if (this.listeOptionMotifs == null) {
            //On charge la liste des motifs
            this.odService.loadVehiculeMotifs().pipe(first()).subscribe((result: Result) => {
                this.listeOptionMotifs = result.data.listeOptionMotifs;
            });
        }
    }

    /**
     * A l'enregistrement
     *
     * @param listeIdEtapesOnline Liste des ID des étapes à enregistrer
     */
    onEnregistrementEffectue(listeIdEtapesOnline: number[]) {
        //On ferme la popup en indiquant "true" comme enregistrement
        this.matDialogRef.close([true, listeIdEtapesOnline, this.niveauNature.getSbtPrincipal().idSBTConfig]);
    }
}
