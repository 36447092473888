<div class="image-viewer">
    <div class="actions">
        <ul>
            <li>
                <button mat-icon-button (click)="rotate('LEFT')">
                    <mat-icon color="primary">rotate_left</mat-icon>
                </button>
            </li>
            <li>
                <button mat-icon-button (click)="rotate('RIGHT')">
                    <mat-icon color="primary">rotate_right</mat-icon>
                </button>
            </li>
            <li>
                <button mat-icon-button (click)="zoom('IN')">
                    <mat-icon color="primary">add_circle_outline</mat-icon>
                </button>
            </li>
            <li>
                <button mat-icon-button (click)="zoom('OUT')">
                    <mat-icon color="primary">remove_circle_outline</mat-icon>
                </button>
            </li>
            <li>
                <button mat-icon-button (click)="onClose.emit()">
                    <mat-icon color="primary">close</mat-icon>
                </button>
            </li>
        </ul>
    </div>

    <img #imageViewer [src]="imageSource" [hidden]="true"/>

</div>
