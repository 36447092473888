import {Injectable,Type} from "@angular/core";
import {HttpClient,HttpParams} from "@angular/common/http";
import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {TranslateService} from "@ngx-translate/core";
import {Presta,TypeAiguillage,TypeConfig,TypeNature,TypePortee} from "@domain/voyage/travel/constants";
import {Communaute} from "@domain/travel/communaute";
import {TravelHubSbtConfigUsedParamConnexion} from "@domain/travel/travel-hub-sbt-config-used-param-connexion";
import {Rls} from "@domain/travel/rls";
import {CategorieVoyageur} from "@domain/travel/categorie-voyageur";
import {TravelHubSbtConfigUsedCategorie} from "@domain/travel/travel-hub-sbt-config-used-categorie";
import {NotionMetier,TypeCommunaute,TypeParamConnexion,TypeProfilFullfilment,TypeReasonCode,TypeRLS,TypeRLV} from "@domain/travel/param-connexion";
import {Champ,FiltreChamp} from "@domain/travel/champ";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {TripFieldUsed} from "@domain/travel/trip-field-used";
import {CustomField} from "@domain/travel/custom-field";
import {TripField} from "@domain/travel/trip-field";
import {TravelHubSBTConfig} from "@domain/travel/travel-hub-sbt-config";
import {FieldUsed} from "@domain/travel/field-used";
import {CustomFieldUsed} from "@domain/travel/custom-field-used";
import {AbstractField} from "@domain/travel/abstract-field";
import {Router} from "@angular/router";
import {ProfilConnexion} from "@domain/travelhub/profil-connexion";
import {ProfilConnexionParam} from "@domain/travelhub/profil-connexion-param";

/**
 * Service de gestion de l'administration TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@Injectable({providedIn: 'root'})
export class AdminTravelhubService {
	/** URL de la liste des configurations TravelHub */
	static readonly URL_TH_CONFIG: string = 'Admin/Voyages/TravelHub/Config';
	/** URL de la liste des configurations SBT */
	static readonly URL_SBT_CONFIG: string = 'Admin/Voyages/TravelHub/SbtConfig';
	/** URL de la liste des profils de connexion */
	static readonly URL_PROFIL_CONNEXION: string = 'Admin/Voyages/TravelHub/ProfilConnexion';

	/** Code spécifique au paramètre RLV */
	static readonly PARAM_CODE_RLV: string = 'RLV';
	/** Code spécifique à une valeur fixe saisie */
	static readonly PARAM_CODE_MAPPING_AUTRE: string = 'AUTRE';
	/** Identifiant d'une valeur de type SQL */
	static readonly TYPE_FIELD_SQL: number = 19;
	/** Identifiant d'une valeur fixe (saisie) */
	static readonly TYPE_FIELD_VALEUR_FIXE: number = 41;

	/** Liste des différents champs disponibles pour un paramètre */
	readonly LISTE_CHAMPS: Array<Champ> = [
		new Champ(1,FiltreChamp.MISSION),
		new Champ(2,FiltreChamp.VOYAGEUR),
		new Champ(3,FiltreChamp.MISSION),
		new Champ(4,FiltreChamp.MISSION),
		new Champ(5,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(6,FiltreChamp.MISSION),
		new Champ(7,FiltreChamp.MISSION),
		new Champ(8,FiltreChamp.MISSION),
		new Champ(9,FiltreChamp.VOYAGEUR),
		new Champ(10,FiltreChamp.VOYAGEUR),
		new Champ(11,FiltreChamp.VOYAGEUR),
		new Champ(12,FiltreChamp.VOYAGEUR),
		new Champ(13,FiltreChamp.VOYAGEUR),
		new Champ(14,FiltreChamp.VOYAGEUR),
		new Champ(15,FiltreChamp.VOYAGEUR),
		new Champ(16,FiltreChamp.VOYAGEUR),
		new Champ(17,FiltreChamp.VOYAGEUR),
		new Champ(18,FiltreChamp.VOYAGEUR),
		new Champ(AdminTravelhubService.TYPE_FIELD_SQL,FiltreChamp.AUTRE),
		new Champ(20,FiltreChamp.MISSION),
		new Champ(21,FiltreChamp.MISSION),
		new Champ(22,FiltreChamp.MISSION),
		new Champ(23,FiltreChamp.MISSION),
		new Champ(24,FiltreChamp.MISSION),
		new Champ(25,FiltreChamp.MISSION),
		new Champ(27,FiltreChamp.MISSION),
		new Champ(28,FiltreChamp.MISSION),
		new Champ(30,FiltreChamp.MISSION),
		new Champ(31,FiltreChamp.MISSION),
		new Champ(32,FiltreChamp.MISSION),
		new Champ(33,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(34,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(35,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(36,FiltreChamp.VOYAGEUR),
		new Champ(37,FiltreChamp.VOYAGEUR),
		new Champ(38,FiltreChamp.VOYAGEUR),
		new Champ(39,FiltreChamp.VOYAGEUR),
		new Champ(40,FiltreChamp.VOYAGEUR),
		new Champ(AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE,FiltreChamp.AUTRE),
		new Champ(42,FiltreChamp.VOYAGEUR),
		new Champ(43,FiltreChamp.VOYAGEUR),
		new Champ(44,FiltreChamp.VOYAGEUR),
		new Champ(45,FiltreChamp.VOYAGEUR),
		new Champ(46,FiltreChamp.MISSION),
		new Champ(47,FiltreChamp.MISSION),
		new Champ(48,FiltreChamp.MISSION),
		new Champ(49,FiltreChamp.MISSION),
		new Champ(50,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(51,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(52,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(53,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(54,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(55,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(56,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(57,FiltreChamp.UTILISATEUR_CONNECTE),
		new Champ(58,FiltreChamp.UTILISATEUR_CONNECTE),
	];

	/** CustomField - Code personnalisé **/
	readonly customCodeCF: CustomField = new CustomField({
		idCustomField: -1,
		code: this.translateService.instant('admin.voyages.travelhub.sbtConfig.codePersonnalise'),
		sbtConfig: null,
		dateUpdated: null,
		actif: true
	});

	/** TripField - Code personnalisé **/
	readonly customCodeTF: TripField = new TripField({
		idTripField: -1,
		code: this.translateService.instant('admin.voyages.travelhub.sbtConfig.codePersonnalise'),
		sbtConfig: null,
		dateUpdated: null,
		actif: true
	});

	/** Liste des TypeNature */
	readonly listeTypeNature: Array<{value: TypeNature,libelle: string}> = TypeNature.values().map(tn => {
		return {value: tn,libelle: this.translateService.instant(TypeNature.traduction(tn)) };
	});

	/** Liste des TypeAiguillage */
	readonly listeTypeAiguillage: Array<{value: TypeAiguillage,libelle: string}> = TypeAiguillage.values().map(ta => {
		return {value: ta,libelle: this.translateService.instant(TypeAiguillage.traduction(ta)) };
	});

	/** Mapping des portées disponibles en fonction de la nature */
	readonly mapTypeNaturePortees: Map<TypeNature,Array<TypePortee.DV|TypePortee.TR|TypePortee.TV>> = new Map();

	/** Mapping des prestations disponibles en fonction de la nature */
	readonly mapTypeNaturePresta: Map<TypeNature,Array<Presta>> = new Map();

	/**
	 * Constructeur
	 *
	 * @param http le client HTTP
	 * @param router Le routeur angular
	 * @param translateService Service de traduction
	 */
	constructor(private http: HttpClient,
				private router: Router,
				private translateService: TranslateService) {

		//Initialisation du mapping des portées disponibles en fonction de la nature
		for (const typeNature of TypeNature.values()) {
			switch (typeNature) {
				case TypeNature.AVION_TRAIN:
				case TypeNature.AVION:
				case TypeNature.TRAIN:
					this.mapTypeNaturePortees.set(typeNature,[TypePortee.DV,TypePortee.TR]);
					break;
				case TypeNature.VOITURE_DE_LOCATION:
				case TypeNature.VOITURE_DE_SOCIETE:
				case TypeNature.VOITURE_PERSONNELLE:
				case TypeNature.TAXI:
				case TypeNature.AUTRE:
					this.mapTypeNaturePortees.set(typeNature,[TypePortee.DV,TypePortee.TR,TypePortee.TV]);
					break;
				case TypeNature.DOCUMENT:
					this.mapTypeNaturePortees.set(typeNature,[TypePortee.DV]);
					break;
			}
		}

		//Initialisation du mapping des prestations disponibles en fonction de la nature
		this.mapTypeNaturePresta.set(TypeNature.AVION_TRAIN,[Presta.AVION_TRAIN,Presta.AVION,Presta.TRAIN]);
		this.mapTypeNaturePresta.set(TypeNature.HEBERGEMENT,[Presta.HOTEL]);
		this.mapTypeNaturePresta.set(TypeNature.VOITURE_DE_LOCATION,[Presta.VOITURE_DE_LOCATION]);
		this.mapTypeNaturePresta.set(TypeNature.VOITURE_DE_SOCIETE,[Presta.VOITURE_SOCIETE]);
		this.mapTypeNaturePresta.set(TypeNature.VOITURE_PERSONNELLE,[Presta.VOITURE_PERSONNELLE]);
		this.mapTypeNaturePresta.set(TypeNature.TAXI,[Presta.TAXI]);
		this.mapTypeNaturePresta.set(TypeNature.AUTRE,[Presta.AUTRE]);
		this.mapTypeNaturePresta.set(TypeNature.AVION,[Presta.AVION]);
		this.mapTypeNaturePresta.set(TypeNature.TRAIN,[Presta.TRAIN]);
		this.mapTypeNaturePresta.set(TypeNature.DOCUMENT,[Presta.DOCUMENT]);
	}

	/**
	 * Navigation vers la liste des configurations SBT
	 */
	navigateToConfigSbtList(): Promise<boolean> {
		//Affiche la liste des configurations SBT
		return this.router.navigate([AdminTravelhubService.URL_SBT_CONFIG]);
	}

	/**
	 * Navigation vers le détail d'une configuration SBT
	 */
	navigateToConfigSbtDetail(id: number): Promise<boolean> {
		//Affiche le détail d'une configuration SBT
		return this.router.navigate([AdminTravelhubService.URL_SBT_CONFIG,id]);
	}

	/**
	 * Navigue vers la page de création d'une configuration SBT
	 */
	navigateToNewConfigSbt(): Promise<boolean> {
		//Affiche la page de création d'une configuration SBT
		return this.router.navigate([AdminTravelhubService.URL_SBT_CONFIG,0]);
	}

	/**
	 * Enregistrement d'une configuration TravelHub
	 *
	 * @param config Nouvelle configuration à enregistrer
	 */
	saveConfig(config: TravelHubConfig): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveConfig`,config);
	}

	/**
	 * Enregistrement d'une copie de configuration
	 *
	 * @param config Nouvelle configuration à enregistrer
	 * @param from Configuration source
	 */
	copyConfig(config: TravelHubConfig,from: TravelHubConfig): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/copyConfig/${from.idConfig}`,config);
	}

	/**
	 * Suppression d'une configuration TravelHub
	 *
	 * @param idConfig Identifiant de la configuration à supprimer
	 */
	deleteConfig(idConfig: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteConfig/${idConfig}`);
	}

	/**
	 * Test de la connexion d'une configuration TravelHub
	 *
	 * @param config La configuration à tester
	 * @param from Configuration source (uniquement lors d'une copie)
	 */
	testConfig(config: TravelHubConfig,from?: TravelHubConfig): Observable<Result> {
		const configToTest = new TravelHubConfig(config);

		//Si la configuration est en cours de copie
		if (from) {
			//On récupère l'id pour que si le mdp n'a pas été modfié il soit récupéré à partir de la configuration existante
			configToTest.idConfig = from.idConfig;
		}

		//Test de la connexion
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/testConfig`,configToTest);
	}

	/**
	 * Charge une configuration SBT
	 *
	 * @param idSBTConfigUsed Identifiant de la configuration SBT à charger
	 */
	loadSbtConfig(idSBTConfigUsed: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/loadSBTConfig/${idSBTConfigUsed}`,null);
	}

	/**
	 * Enregistrement d'une configuration SBT
	 *
	 * @param sbtConfigUsed Nouvelle configuration SBT à enregistrer
	 */
	saveSbtConfig(sbtConfigUsed: TravelHubSBTConfigUsed): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveSBTConfig`,sbtConfigUsed);
	}

	/**
	 * Duplique une configuration SBT
	 *
	 * @param sbtConfigUsed Nouvelle configuration SBT à enregistrer
	 */
	copySbtConfig(sbtConfigUsed: TravelHubSBTConfigUsed): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/copySBTConfig/${sbtConfigUsed.idSBTConfigUsed}`,null);
	}

	/**
	 * Suppression d'une configuration SBT
	 *
	 * @param idSbtConfig Identifiant de la configuration SBT à supprimer
	 */
	deleteSbtConfig(idSbtConfig: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteSBTConfig/${idSbtConfig}`);
	}

	/**
	 * Retourne le libellé traduit correspondant au type de configuration SBT
	 *
	 * @param sbtConfig SBT
	 */
	getTypeConfig(sbtConfig: TravelHubSBTConfig): string {
		return sbtConfig?.typeAiguillage >= 0 ? this.translateService.instant(TypeConfig.traduction(TypeConfig[TypeConfig[sbtConfig.typeAiguillage]])) : '';
	}

	/**
	 * Synchronisation d'une configuration
	 *
	 * @param sbtConfigUsed Configuration SBT
	 */
	synchroDetailSBT(sbtConfigUsed: TravelHubSBTConfigUsed): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/synchroDetailSBT`,sbtConfigUsed);
	}

	/**
	 * Supprime les valeurs des paramètres enregistrées
	 *
	 * @param sbtConfigUsed Configuration SBT
	 */
	refreshParam(sbtConfigUsed: TravelHubSBTConfigUsed): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/refreshParam`,sbtConfigUsed);
	}

	/**
	 * Recherche d'un paramètre associé à une configuration SBT
	 *
	 * @param listeSBTConfigUsed Liste des paramètres
	 * @param sbtConfigUsed Configuration SBT
	 */
	findConfigUsed<T extends TravelHubSbtConfigUsedParamConnexion | TravelHubSbtConfigUsedCategorie>(listeSBTConfigUsed: Array<T>,sbtConfigUsed: TravelHubSBTConfigUsed): T {
		return listeSBTConfigUsed?.find(c => c.idSBTConfigUsed === sbtConfigUsed.idSBTConfigUsed);
	}

	/**
	 * Chargement d'un référentiel de type Communauté d'une configuration SBT
	 *
	 * @param communaute La communauté
	 * @param listeSBTConfigUsed
	 */
	loadCommunaute(communaute: Communaute,listeSBTConfigUsed: Array<TravelHubSbtConfigUsedParamConnexion>): Observable<Result> {
		const idParamConnexion = communaute.sbtConfigUsed ? this.findConfigUsed(listeSBTConfigUsed,communaute.sbtConfigUsed).idParamConnexion : 0;

		let params: HttpParams = new HttpParams().append('idParamConnexion',idParamConnexion.toString(10));

		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/loadCommunaute/${communaute.idCommunaute ?? 0}`,null,{
			params: params
		});
	}

	/**
	 * Enregistrement d'un référentiel de type Communauté d'une configuration SBT
	 *
	 * @param communaute La communauté à enregistrer
	 */
	saveCommunaute(communaute: Communaute): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveCommunaute`,communaute);
	}

	/**
	 * Suppression d'un référentiel de type Communauté d'une configuration SBT
	 *
	 * @param communaute La communauté à supprimer
	 */
	deleteCommunaute(communaute: Communaute): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteCommunaute/${communaute.idCommunaute}`);
	}

	/**
	 * Chargement d'un référentiel de type RLS d'une configuration SBT
	 *
	 * @param rls Le RLS
	 * @param listeSBTConfigUsed
	 */
	loadRls(rls: Rls,listeSBTConfigUsed: Array<TravelHubSbtConfigUsedParamConnexion>): Observable<Result> {
		const idParamConnexion = rls.sbtConfigUsed ? (listeSBTConfigUsed.find(c => c.idSBTConfigUsed === rls.sbtConfigUsed.idSBTConfigUsed)?.idParamConnexion ?? 0) : 0;

		let params: HttpParams = new HttpParams().append('idParamConnexion',idParamConnexion.toString(10));

		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/loadRLS/${rls.idRLS ?? 0}`,null,{
			params: params
		});
	}

	/**
	 * Enregistrement d'un référentiel de type RLS d'une configuration SBT
	 *
	 * @param rls Le RLS à enregistrer
	 */
	saveRls(rls: Rls): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveRLS`,rls);
	}

	/**
	 * Suppression d'un référentiel de type RLS d'une configuration SBT
	 *
	 * @param rls Le RLS à supprimer
	 */
	deleteRls(rls: Rls): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteRLS/${rls.idRLS}`);
	}

	/**
	 * Enregistrement d'un référentiel de type Communauté d'une configuration SBT
	 *
	 * @param categorieVoyageur La catégorie voyageur à enregistrer
	 */
	saveCategorieVoyageur(categorieVoyageur: CategorieVoyageur): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveCategorie`,categorieVoyageur);
	}

	/**
	 * Suppression d'un référentiel de type Catégorie voyageur d'une configuration SBT
	 *
	 * @param categorieVoyageur La catégorie voyageur à supprimer
	 */
	deleteCategorieVoyageur(categorieVoyageur: CategorieVoyageur): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteCategorie/${categorieVoyageur.idCategorie}`);
	}

	/**
	 * Recherche d'un champ par son id
	 *
	 * @param idFiltre Identifiant du filtre
	 */
	findChamp(idFiltre: number): Champ {
		return this.LISTE_CHAMPS.find(c => c.id === idFiltre);
	}

	/**
	 * Retourne la valeur à afficher textuellement en fonction du paramètre de connexion configuré
	 *
	 * @param paramConnexionUsed Paramètre de connexion configuré
	 */
	getValeurForParamConnexionValue(paramConnexionUsed: ParamConnexionUsed): string {
		let valeur: string;
		let champ: Champ;

		//Recherche du champ correspondant au paramètre
		if (paramConnexionUsed.typeField != null) {
			champ = this.findChamp(paramConnexionUsed.typeField);
		}

		//Cas d'un type de paramètre booléen
		if (paramConnexionUsed.paramConnexion?.typeParamConnexion == TypeParamConnexion.BOOLEAN) {
			//Valeur Oui / Non
			valeur = (paramConnexionUsed.valeur != null ? this.translateService.instant((paramConnexionUsed.valeur == '1' ? 'global.oui' : 'global.non')) : null)
		} else if (paramConnexionUsed.paramConnexion?.typeParamConnexion == TypeParamConnexion.LISTE_VALEURS) {
			//Valeur sélectionnée
			valeur = paramConnexionUsed.paramConnexionValeur?.valeur
		} else if (paramConnexionUsed.paramConnexion) {
			//Cas d'un champ associé à une notion métier ou d'un RLV
			if (paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.ReasonCode
				|| paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.ProfilFullfilment
				|| paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.Communaute
				|| paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.Rls
				|| paramConnexionUsed.paramConnexion.code == AdminTravelhubService.PARAM_CODE_RLV
			) {
				//Vérification de la présence d'un codeMapping contenant la	valeur de la nontion métier
				if (paramConnexionUsed.codeMapping != null) {
					//Vérification de la notion métier
					if (paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.ReasonCode) {
						//Lecture de la valeur associée
						valeur = TypeReasonCode.traduction(TypeReasonCode[paramConnexionUsed.codeMapping]);
					} else if (paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.ProfilFullfilment) {
						//Lecture de la valeur associée
						valeur = this.translateService.instant(TypeProfilFullfilment.traduction(TypeProfilFullfilment[paramConnexionUsed.codeMapping]));
					} else if (paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.Communaute) {
						//Lecture de la valeur associée
						valeur = this.translateService.instant(TypeCommunaute.traduction(TypeCommunaute[paramConnexionUsed.codeMapping]));
					} else if (paramConnexionUsed.paramConnexion.notionMetier == NotionMetier.Rls) {
						//Lecture de la valeur associée
						valeur = this.translateService.instant(TypeRLS.traduction(TypeRLS[paramConnexionUsed.codeMapping]));
					} else if (paramConnexionUsed.paramConnexion.code == AdminTravelhubService.PARAM_CODE_RLV) {
						//Lecture de la valeur associée
						valeur = this.translateService.instant(TypeRLV.traduction(TypeRLV[paramConnexionUsed.codeMapping]));
					}

					//Vérification du code
					if (paramConnexionUsed.codeMapping == AdminTravelhubService.PARAM_CODE_MAPPING_AUTRE) {
						//Ajout de la valeur saisie
						valeur += ' (' + paramConnexionUsed.valeur + ')';
					}
				}
			} else if (!!paramConnexionUsed.valeur) {
				//Retour de la valeur saisie manuellement
				if (champ?.id === AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE) {
					valeur = `${this.translateService.instant(champ.libelle)} (${paramConnexionUsed.valeur})`;
				} else {
					valeur = paramConnexionUsed.valeur;
				}
			} else {
				//Retour du code traduit
				valeur = champ ? `${this.translateService.instant(champ.libelle)}` : null;
			}

			//Affichage du champ duquel la valeur est associée le cas échéant
			if (champ) {
				valeur = `${this.translateService.instant(FiltreChamp.traduction(champ.filtre))} → ${valeur}`;
			}
		}

		return valeur;
	}

	/**
	 * Retourne l'icône au format HTML à afficher en fonction du paramètre de connexion configuré
	 *
	 * @param paramConnexionUsed Paramètre de connexion configuré
	 */
	getIconeForParamConnexionValue(paramConnexionUsed: ParamConnexionUsed): string {
		let icone: string;

		//Cas d'un paramètre associé à une notion métier ou d'un RLV
		if ([NotionMetier.ReasonCode,NotionMetier.ProfilFullfilment,NotionMetier.Communaute,NotionMetier.Rls].includes(paramConnexionUsed.paramConnexion.notionMetier)
			|| paramConnexionUsed.paramConnexion.code == AdminTravelhubService.PARAM_CODE_RLV) {

			//Dans le cas du choix "Autre" la valeur est saisie manuellement
			if (paramConnexionUsed.codeMapping == AdminTravelhubService.PARAM_CODE_MAPPING_AUTRE) {
				icone = FiltreChamp.icone(FiltreChamp.AUTRE);
			} else {
				//Sinon c'est une valeur qui a été sélectionnée parmi une liste de valeurs
				icone = `<span class="material-symbols-rounded">list</span>`;
			}
		} else if (paramConnexionUsed.paramConnexion.typeParamConnexion == TypeParamConnexion.LISTE_VALEURS) {
			//Valeur qui a été sélectionnée parmi une liste de valeurs
			icone = `<span class="material-symbols-rounded">list</span>`;
		} else if (paramConnexionUsed.paramConnexion.typeParamConnexion == TypeParamConnexion.BOOLEAN) {
			//Valeur booléenne
			icone = `<span class="material-symbols-rounded">toggle_on</span>`;
		} else if (!!paramConnexionUsed.valeur) {
			//Valeur saisie manuellement
			icone = FiltreChamp.icone(FiltreChamp.AUTRE);
		} else if (!!paramConnexionUsed.requete) {
			//Requête SQL
			icone = `<span class="material-symbols-rounded">database</span>`;
		} else if (!!paramConnexionUsed.typeField) {
			//Icône déterminée par le filtre du champ sélectionné
			icone = FiltreChamp.icone(this.findChamp(paramConnexionUsed.typeField)?.filtre);
		}

		return icone;
	}

	/**
	 * Retourne l'icône au format HTML à afficher en fonction du CustomField / TripField configuré
	 *
	 * @param fieldUsed CustomField / TripField configuré
	 */
	getIconeForField(fieldUsed: FieldUsed): string {
		let icone: string;

		//Vérification de la présence d'une valeur saisie
		if (!!fieldUsed.valeur) {
			//Retour de l'icône correspondant à une saisie
			icone = FiltreChamp.icone(FiltreChamp.AUTRE);
		} else if (!!fieldUsed.requete) {
			//Retour de l'icône correspondante à une requête SQL
			icone = `<span class="material-symbols-rounded">database</span>`;
		} else if (!!fieldUsed.idValeur) {
			//Retour de l'icône correspondante au filtre du champ
			icone = FiltreChamp.icone(this.findChamp(fieldUsed.idValeur)?.filtre);
		}

		return icone;
	}

	/**
	 * Enregistrement d'un paramètre de connexion
	 *
	 * @param paramConnexionUsed Paramètre à enregistrer
	 */
	saveParamConnexion(paramConnexionUsed: ParamConnexionUsed): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveParamConnexion`,paramConnexionUsed);
	}

	/**
	 * Suppression d'un paramètre de connexion
	 *
	 * @param paramConnexionUsed Paramètre à supprimer
	 */
	deleteParamConnexion(paramConnexionUsed: ParamConnexionUsed): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteParamConnexion/${paramConnexionUsed.idParamConnexionUsed}`);
	}

	/**
	 * Enregistrement d'un TripField
	 *
	 * @param tripFieldUsed TripField à enregistrer
	 */
	saveTripField(tripFieldUsed: TripFieldUsed): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveTripField`,tripFieldUsed);
	}

	/**
	 * Suppression d'un TripField
	 *
	 * @param tripFieldUsed TripField à supprimer
	 */
	deleteTripField(tripFieldUsed: TripFieldUsed): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteTripField/${tripFieldUsed.idTripFieldUsed}`);
	}

	/**
	 * Enregistrement d'un CustomField
	 *
	 * @param customFieldUsed CustomField à enregistrer
	 */
	saveCustomField(customFieldUsed: CustomFieldUsed): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/TravelHubConfig/saveCustomField`,customFieldUsed);
	}

	/**
	 * Suppression d'un CustomField
	 *
	 * @param customFieldUsed CustomField à supprimer
	 */
	deleteCustomField(customFieldUsed: CustomFieldUsed): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/TravelHubConfig/deleteCustomField/${customFieldUsed.idCustomFieldUsed}`);
	}

	/**
	 * Construit et retourne un CustomField pour le cas du code personnalisé
	 *
	 * @param sbtConfig Configuration SBT à laquelle le CustomField se rapporte
	 */
	getCustomCodeCF(sbtConfig: TravelHubSBTConfig): CustomField {
		return new CustomField({...this.customCodeCF,sbtConfig: sbtConfig});
	}

	/**
	 * Construit et retourne un TripField pour le cas du code personnalisé
	 *
	 * @param sbtConfig Configuration SBT à laquelle le TripField se rapporte
	 */
	getCustomCodeTF(sbtConfig: TravelHubSBTConfig): TripField {
		return new TripField({...this.customCodeTF,sbtConfig: sbtConfig});
	}

	/**
	 * Retourne un CustomField ou un TripField (suivant le type passé en paramètre) pour le cas du code personnalisé
	 *
	 * @param type Type de field (CustomField / TripField)
	 * @param sbtConfig Configuration SBT à laquelle le field se rapporte
	 */
	getCustomCode<U extends FieldUsed>(type: Type<U>,sbtConfig: TravelHubSBTConfig): AbstractField {
		switch (type.name) {
			case CustomFieldUsed.name:
				return this.getCustomCodeCF(sbtConfig);
			case TripFieldUsed.name:
				return this.getCustomCodeTF(sbtConfig);
			default:
				return null;
		}
	}

	/**
	 * Suppression d'un CustomField / TripField en base
	 *
	 * @param fieldUsed CustomFieldUsed / TripFieldUsed à supprimer
	 */
	deleteField(fieldUsed: FieldUsed): Observable<Result> {
		if (fieldUsed instanceof TripFieldUsed) {
			//Suppression d'un TripField
			return this.deleteTripField(fieldUsed);
		} else if (fieldUsed instanceof CustomFieldUsed) {
			//Suppression d'un CustomField
			return this.deleteCustomField(fieldUsed);
		}
	}

	/**
	 * Enregistrement d'un CustomField / TripField en base
	 *
	 * @param fieldUsed CustomFieldUsed / TripFieldUsed à enregistrer
	 */
	saveField(fieldUsed: FieldUsed): Observable<Result> {
		if (fieldUsed instanceof TripFieldUsed) {
			//Enregistrement d'un TripField
			return this.saveTripField(fieldUsed);
		} else if (fieldUsed instanceof CustomFieldUsed) {
			//Enregistrement d'un CustomField
			return this.saveCustomField(fieldUsed);
		}
	}

	/**
	 * Retourne une instance de CustomFieldUsed ou TripFieldUsed en fonction du type passé en paramètre
	 *
	 * @param type Type
	 * @param field Objet source / DTO (optionnel)
	 */
	buildField<U extends FieldUsed>(type: Type<U>,field?: FieldUsed): FieldUsed {
		switch (type.name) {
			case CustomFieldUsed.name:
				return new CustomFieldUsed(field);
			case TripFieldUsed.name:
				return new TripFieldUsed(field);
			default:
				return null;
		}
	}

	/**
	 * Retourne le nombre maximum de CustomField / TripField autorisé par le SBT
	 *
	 * @param type CustomFieldUsed / TripFieldUsed
	 * @param sbtConfigUsed Configuration SBT
	 */
	getMaxField<U extends FieldUsed>(type: Type<U>,sbtConfigUsed: TravelHubSBTConfigUsed): number {
		switch (type.name) {
			case CustomFieldUsed.name:
				return sbtConfigUsed.sbtConfig?.maxCustomField;
			case TripFieldUsed.name:
				return sbtConfigUsed.sbtConfig?.maxTripField
			default:
				return 0;
		}
	}

	/**
	 * Retourne la racine du path des traductions en fonction du type
	 *
	 * @param type CustomFieldUsed / TripFieldUsed
	 */
	getRootTradField<U extends FieldUsed>(type: Type<U>): string {
		switch (type.name) {
			case CustomFieldUsed.name:
				return 'admin.voyages.travelhub.sbtConfig.parametres.customFields';
			case TripFieldUsed.name:
				return 'admin.voyages.travelhub.sbtConfig.parametres.tripFields';
			default:
				return null;
		}
	}

	/**
	 * Vide le tableau passé en paramètre et ajoute les éléments donnés.
	 * Permet de conserver la référence d'un tableau.
	 *
	 * @param array Le tableau à mettre à jour
	 * @param elts Les éléments à ajouter
	 */
	clearAndFill<U>(array: Array<U>,elts: Array<U>) {
		//Vidage du tableau
		array.splice(0);

		//Ajout des éléments au tableau
		if (elts) {
			array.push(...elts);
		}
	}

	/**
	 * Chargement d'un profil de connexion TH
	 *
	 * @param idProfilConnexion Identifiant du profil de connexion à charger
	 */
	loadProfilConnexion(idProfilConnexion: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilConnexion/loadProfilConnexion/${idProfilConnexion}`,null)
	}

	/**
	 * Enregistrement d'un profil de connexion TH
	 *
	 * @param profilConnexion Profil de connexion à enregistrer
	 */
	saveProfilConnexion(profilConnexion: ProfilConnexion): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/ProfilConnexion/saveProfilConnexion`,profilConnexion);
	}

	/**
	 * Suppression d'un profil de connexion TH
	 *
	 * @param idProfilConnexion Identifiant du profil de connexion à supprimer
	 */
	deleteProfilConnexion(idProfilConnexion: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ProfilConnexion/deleteProfilConnexion/${idProfilConnexion}`);
	}

	/**
	 * Duplication d'un profil de connexion TH
	 *
	 * @param idProfilConnexion Identifiant du profil de connexion à supprimer
	 */
	copyProfilConnexion(idProfilConnexion: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilConnexion/copyProfilConnexion/${idProfilConnexion}`,null);
	}

	/**
	 * Suppression d'un paramètre de profil de connexion TH
	 *
	 * @param idProfilConnexionParam Identifiant du paramètre à supprimer
	 */
	loadProfilConnexionParam(idProfilConnexionParam: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ProfilConnexion/loadParam/${idProfilConnexionParam}`,null);
	}

	/**
	 * Enregistrement d'un paramètre de profil de connexion TH
	 *
	 * @param profilConnexionParam Paramètre à supprimer
	 */
	saveProfilConnexionParam(profilConnexionParam: ProfilConnexionParam): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/ProfilConnexion/saveParam`,profilConnexionParam);
	}

	/**
	 * Suppression d'un paramètre de profil de connexion TH
	 *
	 * @param idProfilConnexionParam Identifiant du paramètre à supprimer
	 */
	deleteProfilConnexionParam(idProfilConnexionParam: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/ProfilConnexion/deleteParam/${idProfilConnexionParam}`);
	}

}
