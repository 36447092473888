<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined align-middle">insert_drive_file</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">{{ data.valeur }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.origine">
                <strong [translate]="'admin.parametres.demat.extensions.tag.origine.title'"></strong>
                <span [translate]="'admin.parametres.demat.extensions.tag.origine.'+ data.origine"></span>
            </li>
            <li>
                <strong [translate]="'admin.parametres.demat.extensions.tag.dematerialisation'"></strong>
                <span [translate]="'filter.valeurOuiNon.' + data.demat"></span>
            </li>
        </ul>
    </div>

    <!-- bouton supprimer pour l'extension -->
    <div class="lvi-actions" *ngIf="data.origine === OrigineExtension.ADMINISTRATEUR">
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="delete()" translate>global.actions.supprimer</button>
        </mat-menu>
    </div>
</div>
