import {Component, Inject} from '@angular/core';
import {MessageAdmin} from "../../../../../domain/dashboard/message-admin";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TypeMessage} from "../../../../../domain/dashboard/type-message.enum";

/**
 * Composant de la popup d'un message d'information
 */
@Component({
  selector: 'info',
  templateUrl: './info.component.html'
})
export class InfoComponent {

  /** Import de l'énum pour le DOM */
  typeMessage: typeof TypeMessage = TypeMessage;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageAdmin) { }
}
