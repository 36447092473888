import {Tournee} from "./tournee";
import {Adresse} from "../profil/adresse";

/**
 * Modèle d'une étape d'une tournée
 */
export class EtapeTournee {
    idEtape: number;

    tournee: Tournee;

    idLocDepart: number;

    idLocArrivee: number;

    distance: number;

    distanceComputed: number;

    heureDebut: string;

    heureFin: string;

    ordre: number;

    personnel: boolean;

    localisationDepart: Adresse;

    localisationArrivee: Adresse;
}