import {NgModule} from '@angular/core';

import {TooltipDirective} from './tooltip.directive';
import {TooltipComponent} from "./tooltip.component";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [CommonModule],
    declarations: [TooltipDirective,TooltipComponent],
    exports: [TooltipDirective],
    providers: []
})
export class TooltipModule {}