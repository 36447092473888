/**
 * Représente une saisie de taxe sur une facture
 *
 * @author Laurent Convert
 * @date 08/02/2023
 */
export class Taxe {
    idSaisie: number;
    idDepense: number;
    idTaux: number;
    taux: number;
    montant: number;
    montantBase: number;
    montantHt: number;
    montantHtBase: number;
    montantTtc: number;
    montantTtcBase: number;
    numLigne: number;
    
    //Front only
    defaut: boolean;
    
    /**
     * Constructeur
     * @param dto DTO provenant du back
     */
    constructor(dto?: Taxe) {
        if (dto) {
            //Initialisation de l'objet avec les données du DTO
            Object.assign(this,dto);
        }
    }
    
    /**
     * Calcul le montant max en fonction du taux
     *
     * @param montantDepense Montant TTC de la dépense
     */
    getMontantMax(montantDepense: number): number {
        return (montantDepense ?? 0) * (1.0 - 1.0 / (1.0 + this.taux / 100.0));
    }
    
    /**
     * Remise à zéro de tous les montants
     */
    reset(): void {
        this.montantBase = 0.0;
        this.montantHt = 0.0;
        this.montantHtBase = 0.0;
        this.montantTtc = 0.0;
        this.montantTtcBase = 0.0;
    }

}