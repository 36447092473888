import {NgModule} from '@angular/core';

import {XmlUploaderDirective} from './xml-uploader.directive';
import {XmlUploaderService} from './xml-uploader.service';

@NgModule({
    imports: [],
    declarations: [XmlUploaderDirective],
    exports: [XmlUploaderDirective],
    providers: [XmlUploaderService]
})
export class XmlUploaderModule {}