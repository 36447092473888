import {Component,OnInit} from '@angular/core';
import {AppState} from '@domain/appstate';
import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {SettingsODState} from '@domain/settings/settings';
import {TypePortee} from '@domain/workflow/workflow';
import {Store} from '@ngrx/store';
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {OdChevauchement} from "@domain/od/od-chevauchement";

/**
 * OD dans la liste des od pour les chevauchements de dates
 *
 * @author Angeline Ha
 * @date 16/05/2024
 */
@Component({
    host: {'data-test-id': 'od-list-item-chevauchement-dates'},
    templateUrl: './od-list-item-chevauchement-dates.component.html'
})
export class OdListItemChevauchementDatesComponent extends ListViewItem<OdChevauchement> implements OnInit {
    /** Paramétrage */
    settings: SettingsODState;

    /**
     * Constructeur
     *
     * @param store store de l'application
     */
    constructor(private store: Store<AppState>) {
        //Héritage
        super();
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection du paramétrage
        this.store.select(state => state.settings?.[TypePortee.OD]).pipe(filterFirstNotNull()).subscribe(settings => this.settings = settings);
    }

}
