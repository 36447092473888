<div class="lvi-content">
    <div class="avatar">
        <span *ngIf="plugin.code === Plugins.VIAMICHELIN">{{ 'admin.entreprise.description.licence.plugins.vm' | translate }}</span>
        <span *ngIf="plugin.code === Plugins.GED">{{ 'admin.entreprise.description.licence.plugins.ged' | translate }}</span>
        <span *ngIf="plugin.code === Plugins.CERTIFICATION">{{ 'admin.entreprise.description.licence.plugins.ce' | translate }}</span>
        <span *ngIf="plugin.code === Plugins.ARCHIVAGE">{{ 'admin.entreprise.description.licence.plugins.ar' | translate }}</span>
        <span *ngIf="plugin.code === Plugins.MARQUEBLANCHE">{{ 'admin.entreprise.description.licence.plugins.mb' | translate }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">{{ displayLabel(plugin.code) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.description.licence.model.actif'"></strong>
                <span>{{ (plugin.isActif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.description.licence.model.controle'"></strong>
                <span>{{ (plugin.controle ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li *ngIf="plugin.volume">
                <strong [translate]="'admin.entreprise.description.licence.model.volume'"></strong>
                <span>{{ plugin.volume }}</span>
            </li>
            <li *ngIf="plugin.pourcentageConsomme">
                <strong [translate]="'admin.entreprise.description.licence.model.consommation'"></strong>
                <span>{{ plugin.pourcentageConsomme }} %</span>
            </li>
        </ul>
    </div>
</div>
