import {Component,OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {BehaviorSubject} from "rxjs";
import {EnveloppeItemComponent} from "./enveloppe-item/enveloppe-item.component";
import {Sorting} from "@domain/common/list-view/sorting";
import {EnveloppeDto} from '@domain/admin/entreprise/analytique/enveloppeDto';
import {SettingsGlobalState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";

/**
 * Onglet enveloppes du sous-menu Entreprise/Analytique
 *
 * @author Tom JEAMMET
 * @date 23/02/2023
 */
@Component({
    host: {'data-test-id': 'analytique-enveloppes'},
    templateUrl: './analytique-enveloppes.component.html'
})
export class AnalytiqueEnveloppesComponent implements OnInit {
    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Liste des enveloppes */
    enveloppes: ListView<EnveloppeDto, EnveloppeItemComponent>;

    /** Settings */
    settings: SettingsGlobalState;

    /**
     * Constructeur
     *
     * @param store le store de l'appli
     * @param translateService le moteur de traduction
     * @param router le routeur
     */
    constructor(
        private store: Store<AppState>,
        private translateService: TranslateService,
        private router: Router
    ) { }

    ngOnInit(): void {
        //Récupération du paramétrage pour avoir les niveaux de bloquage des enveloppes
        this.store.select(state => state.settings?.Global)
            .pipe(filterFirstNotNull())
            .subscribe((settings: SettingsGlobalState) => {
                //Création de la liste des référentiels
                this.enveloppes = new ListView<EnveloppeDto,EnveloppeItemComponent>({
                    uri: `/controller/Budget/listeEnveloppesBudgetaires`,
                    title: this.translateService.instant('admin.entreprise.analytique.enveloppes.title'),
                    component: EnveloppeItemComponent,
                    isFilter: true,
                    defaultOrder: 'libelle',
                    listeFilters: [
                        {
                            clef: 'libelle',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.libelle'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        },{
                            clef: 'reference',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.reference'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        },{
                            clef: 'listeMontant.exercice.libelle',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.exercice'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        },{
                            clef: 'listeMontant.periodeDebut.dateDebut',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.debut'),
                            type: TypeFilter[TypeFilter.DATE]
                        },{
                            clef: 'listeMontant.periodeFin.dateFin',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.fin'),
                            type: TypeFilter[TypeFilter.DATE]
                        },{
                            clef: 'listeMontant.montant',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.montantTotal'),
                            type: TypeFilter[TypeFilter.DECIMAL]
                        },{
                            clef: 'listeMontant.etatBudget.montant',
                            title: this.translateService.instant('admin.entreprise.analytique.enveloppes.montantEngage'),
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }
                    ],
                    extraOptions: {
                        settings: settings
                    }
                });

                //Définition des colonnes de tri
                this.enveloppes.columns = [
                    {key: 'libelle',title: 'admin.entreprise.analytique.enveloppes.libelle'},
                    {key: 'reference',title: 'admin.entreprise.analytique.enveloppes.reference'},
                    {key: 'listeMontant.exercice.libelle',title: 'admin.entreprise.analytique.enveloppes.exercice'},
                    {key: 'listeMontant.periodeDebut.dateDebut',title: 'admin.entreprise.analytique.enveloppes.debut'},
                    {key: 'listeMontant.periodeFin.dateFin',title: 'admin.entreprise.analytique.enveloppes.fin'},
                    {key: 'listeMontant.montant',title: 'admin.entreprise.analytique.enveloppes.montantTotal'},
                    {key: 'listeMontant.etatBudget.montant',title: 'admin.entreprise.analytique.enveloppes.montantEngage'}
                ];

                //Ajout du tri de la liste selon l'ordre voulu
                this.enveloppes.sorting = new Sorting(this.enveloppes.columns,"libelle");

                //Persistence des filtres
                this.enveloppes.isPersistFilters = true;

                //Nom de la classe
                this.enveloppes.className = 'AnalytiqueEnveloppesComponent';
            });

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.create()
        }]);
    }

    /**
     * Méthode appelée lors de la création d'un nouveau service
     */
    create() {
        this.router.navigate(['Admin/Entreprise/Analytique/Enveloppes/-1']);
    }
}
