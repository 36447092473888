import {Component,OnDestroy,OnInit,ViewChild} from "@angular/core";
import {ActivatedRoute,Router} from "@angular/router";
import {TypeBudget} from "@domain/budget/type-budget.enum";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject,forkJoin,Observable,Subscription} from "rxjs";
import {filter,finalize,first} from "rxjs/operators";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {AppState} from "@domain/appstate";
import {Store} from "@ngrx/store";
import {TypePortee} from "@domain/workflow/workflow";
import {SettingsGlobalState} from "@domain/settings/settings";
import * as settingsActions from "@reducers/settings";
import {Enveloppe as EnveloppeConsommation} from "@domain/budget/enveloppe";
import {Enveloppe} from "@domain/admin/entreprise/analytique/enveloppe";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";
import {formatCurrency} from "@angular/common";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {Analytique} from "@components/analytique/analytique";
import {EnveloppeGeneraliteComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-generalite/enveloppe-generalite.component";
import * as _ from "lodash";

/**
 * Détails d'une enveloppe
 *
 * @author Tom JEAMMET
 * @date 23/02/2023
 */
@Component({
	host: {'data-test-id': 'enveloppe-details'},
	selector: 'enveloppe-details',
	templateUrl: './enveloppe-details.component.html'
})
export class EnveloppeDetailsComponent implements OnInit,OnDestroy {
	/** Déclarations pour le template */
	TypeBudget = TypeBudget;
	NiveauAlerte = NiveauAlerte;

	/** Indique si le formulaire a été ouvert en création ou en modification */
	isCreation: boolean = false;

	/** Liste des différents onglets disponibles dans le sous-menu enveloppe */
	listeTabItems: Array<PageHeaderItem> = [{
		code: OngletsEnveloppe.ENVELOPPE,
		libelle: this.translateService.instant('admin.entreprise.analytique.enveloppes.onglets.enveloppe')
	}];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	/** Enveloppe sélectionnée */
	enveloppe: Enveloppe;

	/** Enveloppe consommation */
	enveloppeConsommation: EnveloppeConsommation

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Pour permettre le pending sur le floating button */
	isSaving: boolean;

	/** Alerte de la page */
	alerte: Alerte = null;

	/** Paramètres */
	settings: SettingsGlobalState;

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = OngletsEnveloppe;

	/** Le type entite pour la liste analytique */
	typeEntite: TypeEntite;

	/** route de l'analytique enveloppes */
	readonly enveloppesUrl: string = 'Admin/Entreprise/Analytique/Enveloppes';

	/** Indique si l'onglet Montants a été chargé */
	isMontantTabLoaded: boolean = false;

	/** Indique si l'onglet reel a été chargé */
	isReelTabLoaded: boolean = false;

	/** Indique si l'onglet previsionnel a été chargé */
	isPrevisionnelTabLoaded: boolean = false;

	/** Composant enfant zone utilisateur */
	@ViewChild(EnveloppeGeneraliteComponent) enveloppeGeneraliteComponent: EnveloppeGeneraliteComponent;

	/** Montant imputé de l'enveloppe */
	sommeMontantImputes: number;

	/** Cache du montant réel */
	sommeMontantImputesReel: number;

	/** Cache du montant prévisionnel */
	sommeMontantImputesPrevisonnel: number;

	/** Souscriptions */
	listeSubscription: Subscription[] = [];

	/**
	 * Constructeur
	 *
	 * @param activatedRoute Route d'accès à cette page
	 * @param confirmService Service de confirmation
	 * @param translateService Service de traduction
	 * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
	 * @param analytiqueService Service de gestion des informations sur l'analytique
	 * @param enveloppeDetailService Service de gestion du détail de l'enveloppe
	 * @param router Router de l'application pour naviguer entre les pages
	 * @param store store de l'application
	 */
	constructor(
		private activatedRoute: ActivatedRoute,
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private analytiqueService: AnalytiqueService,
		private enveloppeDetailService: EnveloppeDetailService,
		private router: Router,
		private store: Store<AppState>,
	) {
	}

	/**
	 * Hook initialisation
	 */
	ngOnInit(): void {
		//Récupération de l'identifiant de l'enveloppe à afficher
		this.listeSubscription.push(this.activatedRoute.params.subscribe(params => {
			this.onInit(params['idEnveloppe']);
		}));
	}

	/**
	 * Méthode appelée lorsque l'identifiant contenu dans l'url a été récupéré
	 *
	 * @param idEnveloppe identifiant de l'enveloppe, se trouvant dans l'url
	 */
	onInit(idEnveloppe: string): void {
		//On est en mode création si l'id indiqué dans l'url est -1 sinon on est en mode modification
		this.isCreation = idEnveloppe === "-1";

		//Définition de la liste des actions possibles peu importe le mode (création ou non)
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			doAction: () => this.save(),
		}]);

		//Si on est en mode création, alors on initialise une nouvelle enveloppe
		if (this.isCreation) {
			//Initialisation d'une nouvelle enveloppe
			this.enveloppe = {
				libelle: '',
				reference: '',
				alerteEdited: true,
				pourcentageBloquant: undefined,
				pourcentageNonBloquant: undefined,
				commentaire: ''
			};
		} else {
			//Si on est en mode modification, alors on ajoute la possibilité de supprimer l'enveloppe
			this.listeActions.next([
				...this.listeActions.getValue(),
				{
					type: TypeAction.SECONDARY,
					icone: 'nio icon-suppression',
					libelle: 'global.actions.supprimer',
					doAction: () => this.delete(),
				}
			]);

			//Ajout des onglets
			this.listeTabItems.push({
				code: OngletsEnveloppe.MONTANTS,
				libelle: this.translateService.instant('admin.entreprise.analytique.enveloppes.onglets.montants')
			});
			this.listeTabItems.push({
				code: OngletsEnveloppe.CONSOMMATION_PREVISIONNEL,
				libelle: this.translateService.instant('admin.entreprise.analytique.enveloppes.onglets.consommationPrevisionnel')
			});
			this.listeTabItems.push({
				code: OngletsEnveloppe.CONSOMMATION_REEL,
				libelle: this.translateService.instant('admin.entreprise.analytique.enveloppes.onglets.consommationReel')
			});


			//Récupération de l'enveloppe à modifier
			this.analytiqueService.getEnveloppe(Number(idEnveloppe)).subscribe(data => {
				this.enveloppe = data.data.enveloppe;
			});

			//Récupération de l'enveloppe à modifier
			this.analytiqueService.getEnveloppeConsommation(Number(idEnveloppe)).subscribe(data => {
				this.enveloppeConsommation = data.data.enveloppe;
			});
		}

		//Chargement du paramétrage
		this.store.dispatch({
			type: settingsActions.LOAD_SETTINGS,
			payload: TypePortee.BGT
		});

		//Sélection du paramétrage pour récupérer les niveaux de bloquage des enveloppes
		this.listeSubscription.push(this.store.select(state => state.settings?.[TypePortee.BGT]).subscribe(settings => {
			this.settings = settings;
		}));
	}

	/**
	 * Méthode de création de l'alerte.
	 * @return true si on a une alerte qui a été créée
	 */
	computeAlerte(): boolean {
		//Si on a pas d'enveloppe pas besoin de calculer l'alerte
		if (this.enveloppeConsommation == null) {
			return false;
		}

		//Création de l'alerte s'il y a lieu d'en avoir une
		if (this.enveloppeConsommation.niveauAlerte != NiveauAlerte.NO_CONTROL) {
			if (this.enveloppeConsommation.niveauAlerte == NiveauAlerte.WARNING) {
				this.alerte = new Alerte({
					niveau: NiveauAlerte.WARNING,
					titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
					message: this.translateService.instant('budget.detail.alerte',{'pourcentage': this.enveloppe.alerteEdited ? this.enveloppe.pourcentageNonBloquant || this.settings.budgetNiveauNonBloquant : this.settings.budgetNiveauNonBloquant})
				});
				return true;
			} else if (this.enveloppeConsommation.niveauAlerte == NiveauAlerte.ERROR) {
				this.alerte = new Alerte({
					niveau: NiveauAlerte.ERROR,
					titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
					message: this.translateService.instant('budget.detail.alerte',{'pourcentage': this.enveloppe.alerteEdited ? this.enveloppe.pourcentageBloquant || this.settings.budgetNiveauBloquant : this.settings.budgetNiveauBloquant})
				});
				return true;
			}
		}
		return false;
	}

	/**
	 * Récupération et formatage du montant total à afficher
	 */
	getMontantTotalImpute(): string {
		if (this.selectedItem != null && this.sommeMontantImputes != null) {
			return this.translateService.instant(
				this.selectedItem.code == OngletsEnveloppe.CONSOMMATION_PREVISIONNEL ? "budget.detail.montantPrevImpute" : "budget.detail.montantReelImpute",
				{"montant": formatCurrency(this.sommeMontantImputes,'fr',this.enveloppeConsommation.codeDevise,".2-2")});
		} else {
			return null;
		}
	}

	/**
	 * Sauvegarde du formulaire
	 */
	save(): void {
		//Si le formulaire est valide alors, on enregistre le service
		if (this.enveloppeGeneraliteComponent.valid()) {
			this.isSaving = true;

			let listeAnalytique: Analytique[] = this.enveloppe.listeAnalytique;
			//On deep copy pour éviter de modifier le modèle tant qu'on n'a pas fini le traitement
			let enveloppeSaved = _.cloneDeep(this.enveloppe);

			//Si on est en création, on s'assure que la liste analytique est vide pour la première sauvegarde
			if (this.isCreation) {
				enveloppeSaved.listeAnalytique = [];
			} else {
				//On est en modification, on filtre tous les nouvelles analytiques
				enveloppeSaved.listeAnalytique = this.enveloppe.listeAnalytique.filter(analytique => analytique.id != null);
			}

			//Enregistrement de l'enveloppe
			this.analytiqueService.saveEnveloppe(enveloppeSaved).subscribe(result => {
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					const forks: Observable<Result>[] = [];

					//On ajoute dans la liste des forks les analytiques à mettre à jour
					//On le fait séparement pour reprendre le comportement v9 car que toute modification de l'analytique est loggée
					listeAnalytique?.forEach(analytique => {
						if (analytique.isUpdated) {
							//Les ids sont renvoyés par le back, on les met à jour pour la sauvegarde
							analytique.idEnveloppe = result.data.enveloppe.idEnveloppe;
							analytique.idOrga = analytique.orga?.idOrga;
							analytique.idDossier = analytique.dossier?.idDossier;
							analytique.idAxe5 = analytique.axe5?.idAxe5;
							analytique.idAxe6 = analytique.axe6?.idAxe6;
							analytique.isUpdated = false;
							//On rajoute la maj
							forks.push(this.analytiqueService.saveAnalytique(analytique));
						}
					});

					//Si on a de l'analytique à mettre à jour
					if (forks.length > 0) {
						forkJoin(forks)
							.pipe(first())
							.subscribe((results: Result[]) => {
								//On regarde si on a une erreur sur une maj
								const resultWithError = results.find(result => result.codeErreur != TypeCodeErreur.NO_ERROR);

								//On n'a pas d'erreur
								if (resultWithError == null) {
									//Si aucune erreur n'est renvoyée, on affiche un message de succès
									this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

									//Navigation vers l'enveloppe crée
									this.router.navigate([this.enveloppesUrl + '/' + result.data.enveloppe.idEnveloppe]);

									//Récupération de l'enveloppe à modifier, on la récupère en base pour avoir analytique à jour
									this.analytiqueService.getEnveloppe(Number(result.data.enveloppe.idEnveloppe)).subscribe(data => {
										this.enveloppe = data.data.enveloppe;
									});
								} else {
									TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
								}
							});
					} else {
						//On met à jour l'enveloppe courante
						this.enveloppe = result.data.enveloppe;

						//Si aucune erreur n'est renvoyée alors, on affiche un message de succès
						this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

						//Navigation vers l'enveloppe crée
						this.router.navigate([this.enveloppesUrl + '/' + result.data.enveloppe.idEnveloppe]);
					}
				} else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
					//Toast erreur doublon
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.enveloppes.details.erreurDoublon'));
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			},finalize(() => this.isSaving = false));
		} else {
			//Si, au contraire, le formulaire n'est pas valide alors affichage d'un message disant que le formulaire n'est pas valide
			this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
		}
	}

	/**
	 * Méthode appelée lors de la suppression d'une enveloppe
	 */
	delete(): void {
		//Affichage de la popin de confirmation de suppression d'une enveloppe
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Si l'utilisateur a appuyé sur valider alors on supprime l'enveloppe
				this.analytiqueService.deleteEnveloppe(this.enveloppe.idEnveloppe).subscribe(data => {
					if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Si aucune erreur n'est renvoyée alors on affiche un message de succès
						this.toastrService.success(this.translateService.instant('global.success.suppression'));
						//Navigation vers les enveloppes
						this.router.navigate([this.enveloppesUrl]);
					} else {
						//Si une erreur renvoyée alors on affiche un message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.suppression'));
					}
				})
			}
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		this.selectedItem = selectedItem;

		//On reset le montant
		this.sommeMontantImputes = null;

		//Gestion de lazy loading pour indiquer ce qui est chargé
		if (selectedItem.code === OngletsEnveloppe.MONTANTS) {
			this.isMontantTabLoaded = true;
		} else if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_REEL) {
			this.isReelTabLoaded = true;
		} else if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_PREVISIONNEL) {
			this.isPrevisionnelTabLoaded = true;
		}

		//Si on a un cache du montant
		if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_REEL && this.sommeMontantImputesReel != null) {
			this.sommeMontantImputes = this.sommeMontantImputesReel;
		} else if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_PREVISIONNEL && this.sommeMontantImputesPrevisonnel != null) {
			this.sommeMontantImputes = this.sommeMontantImputesPrevisonnel;
		} else if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_PREVISIONNEL || selectedItem.code === OngletsEnveloppe.CONSOMMATION_REEL) {
			//Pas de cache !!!
			//Si onglet courant est l'onglet prev ou reel, on le calcule
			if (this.enveloppeConsommation != null) {
				this.enveloppeDetailService.computeMontantTotalImpute(
					this.enveloppeConsommation.idEnveloppe,
					this.enveloppeConsommation.idBudget,
					this.selectedItem.code === OngletsEnveloppe.CONSOMMATION_PREVISIONNEL ? TypeBudget.PREVISIONNEL : TypeBudget.REEL
				).pipe(first()).subscribe(result => {
					this.sommeMontantImputes = result;

					//Mise en cache
					if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_REEL) {
						this.sommeMontantImputesReel = this.sommeMontantImputes;
					} else if (selectedItem.code === OngletsEnveloppe.CONSOMMATION_PREVISIONNEL) {
						this.sommeMontantImputesPrevisonnel = this.sommeMontantImputes;
					}
				})
			}
		}
	}

	/**
	 * Retour à la page précédente
	 */
	onGoBack(): void {
		this.router.navigateByUrl(this.enveloppesUrl);
	}

	/**
	 * Indique si on est sur un onglet consommation
	 */
	isConsommationTab(): boolean {
		return this.selectedItem.code == OngletsEnveloppe.CONSOMMATION_PREVISIONNEL
			|| this.selectedItem.code == OngletsEnveloppe.CONSOMMATION_REEL;
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désouscription aux abonnements
		this.listeSubscription.forEach(sub => sub.unsubscribe());
	}
}

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum OngletsEnveloppe {
	ENVELOPPE = "Enveloppe",
	MONTANTS = "Montants",
	CONSOMMATION_PREVISIONNEL = "Consommation prévisionnel",
	CONSOMMATION_REEL = "Consommation réel"
}