import {NgModule} from '@angular/core';
import {CommonModule,CurrencyPipe,DatePipe,TitleCasePipe} from '@angular/common';
import {MAT_DATE_FORMATS,MatRippleModule} from '@angular/material/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {TranslateModule} from '@ngx-translate/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MAT_DIALOG_DEFAULT_OPTIONS,MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS,MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {NgxFilesizeModule} from 'ngx-filesize';
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
    imports: [CommonModule,FormsModule,ReactiveFormsModule,FilterPipeModule,RouterModule,TranslateModule,MatButtonModule,MatInputModule,MatFormFieldModule,MatAutocompleteModule,MatMenuModule,MatIconModule,MatDialogModule,MatProgressBarModule,MatProgressSpinnerModule,MatCardModule,MatChipsModule,MatTooltipModule,MatRippleModule,MatTabsModule,MatDatepickerModule,MatMomentDateModule,MatSelectModule,NgxFilesizeModule,MatCheckboxModule,MatRadioModule,MatExpansionModule],
    exports: [CommonModule,FormsModule,ReactiveFormsModule,FilterPipeModule,RouterModule,TranslateModule,MatButtonModule,MatInputModule,MatFormFieldModule,MatAutocompleteModule,MatMenuModule,MatIconModule,MatDialogModule,MatProgressBarModule,MatProgressSpinnerModule,MatCardModule,MatChipsModule,MatTooltipModule,MatRippleModule,MatTabsModule,MatDatepickerModule,MatMomentDateModule,MatSelectModule,NgxFilesizeModule,MatCheckboxModule,MatRadioModule,MatExpansionModule],
    providers: [{
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
            autoFocus: false,
            minWidth: 800,
            hasBackdrop: true,
            disableClose: true
        }
    },{
        provide: MAT_DATE_FORMATS,
        useValue: {
            display: {
                dateInput: 'L',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
            },
            parse: {
                dateInput: ['L','DD/MM/YYYY','DDMMYYYY','DD/MM/YY','DDMMYY','DD/MM','DDMM']
            }
        }
    },{
        provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
        useValue: {
            useUtc: false,
            strict: true
        }
    },CurrencyPipe,DatePipe,TitleCasePipe]
})
export class CoreComponentsModule {
}
