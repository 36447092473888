<h1 mat-dialog-title>
    <span>{{ selectedTypeEntite ? ('profilUser.prefAnalytique.item.titleWoDate' | translate:{'typeEntite': selectedTypeEntite.libelle}) : ('profilUser.prefAnalytique.add.title' | translate) }}</span>
    <span [mat-dialog-close]="willRefreshList"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <!-- Liste des type entité pour sélection à la création -->
    <list-view *ngIf="!selectedTypeEntite && liste" [liste]="liste"></list-view>

    <!-- Formulaire de création et d'édition visible une fois que le type entité a été sélectionné -->
    <form autocomplete="off" *ngIf="selectedTypeEntite" #addPrefAnalytiqueForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input [ngModel]="preferenceAnalytique.dateApplication"
                    [(customModel)]="preferenceAnalytique.dateApplication"
                    customType="date"
                    libelle="profilUser.prefAnalytique.add.dateApplication"
                    name="dateApplication"
                    ngDefaultControl
                    required>
                </custom-input>
                <label [translate]="'profilUser.prefAnalytique.add.defaut'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox color="primary" [(ngModel)]="preferenceAnalytique.defaut" name="defaut"><span [translate]="'profilUser.prefAnalytique.add.defautText'"></span></mat-checkbox>
                </div>
            </div>
            <div [ngClass]="{ 'has-error': service.invalid }" class="row">
                <label class="col-md-2 required"><span [translate]="'analytique.service'"></span></label>
                <div class="col-md-10">
                    <autocomplete #service="ngModel" (onSelect)="resetDossier(); resetAxe5(); resetAxe6();" [(ngModel)]="preferenceAnalytique.orga"
                                  [filter]="{ idTypeEntite: selectedTypeEntite.idTypeEntite }" [required]="true" name="service"
                                  ngDefaultControl type="service"></autocomplete>
                </div>
            </div>
            <div *ngIf="selectedTypeEntite.codeProjet" [ngClass]="{ 'has-error': dossier.invalid }" class="row">
                <label class="col-md-2" [ngClass]="{ 'required': selectedTypeEntite.codeProjetObligatoire }"><span [translate]="'analytique.dossier'"></span></label>
                <div class="col-md-10">
                    <autocomplete #dossier="ngModel" (onSelect)="resetAxe5(); resetAxe6();" [(ngModel)]="preferenceAnalytique.dossier"
                                  [filter]="{ idTypeEntite: selectedTypeEntite.idTypeEntite,idOrga: preferenceAnalytique.orga?.idOrga }"
                                  [required]="selectedTypeEntite.codeProjetObligatoire" name="dossier" ngDefaultControl
                                  type="dossier"></autocomplete>
                </div>
            </div>
            <div *ngIf="selectedTypeEntite.axe5" [ngClass]="{ 'has-error': axe5.invalid }" class="row">
                <label class="col-md-2" [ngClass]="{ 'required': selectedTypeEntite.axe5Obligatoire }"><span>{{ data?.settings?.libelleAxe5 || ('analytique.axe5' | translate) }}</span></label>
                <div class="col-md-10">
                    <autocomplete #axe5="ngModel" (onSelect)="resetAxe6();" [(ngModel)]="preferenceAnalytique.axe5"
                                  [filter]="{ idTypeEntite: selectedTypeEntite.idTypeEntite,idOrga: preferenceAnalytique.orga?.idOrga,idDossier: preferenceAnalytique.dossier?.idDossier }"
                                  [required]="selectedTypeEntite.axe5Obligatoire" name="axe5" ngDefaultControl type="axe5"></autocomplete>
                </div>
            </div>
            <div *ngIf="selectedTypeEntite.axe6" [ngClass]="{ 'has-error': axe6.invalid }" class="row">
                <label class="col-md-2" [ngClass]="{ 'required': selectedTypeEntite.axe6Obligatoire }"><span>{{ data?.settings?.libelleAxe6 || ('analytique.axe6' | translate) }}</span></label>
                <div class="col-md-10">
                    <autocomplete #axe6="ngModel" [(ngModel)]="preferenceAnalytique.axe6"
                                  [filter]="{ idTypeEntite: selectedTypeEntite.idTypeEntite,idOrga: preferenceAnalytique.orga?.idOrga,idDossier: preferenceAnalytique.dossier?.idDossier,idAxe5: preferenceAnalytique.axe5?.idAxe5 }"
                                  [required]="selectedTypeEntite.axe6Obligatoire"
                                  name="axe6" ngDefaultControl type="axe6"></autocomplete>
                </div>
            </div>
            <ng-container *ngIf="selectedTypeEntite.field5 && getFieldParam('OPERATION_1') as operation">
                <div [ngClass]="{ 'has-error': operation1.invalid }" class="row">
                    <label class="col-md-2" [ngClass]="{ 'required': selectedTypeEntite.field5Obligatoire || operation.obligatoire }"><span>{{ operation.libelle }}</span></label>
                    <div class="col-md-3">
                        <mat-form-field class="d-flex">
                            <input #operation1="ngModel" [(ngModel)]="preferenceAnalytique.field5" [maxlength]="operation.masque.length" [minlength]="operation.tailleMinimale"
                                   [pattern]="getPatternFor(operation)"
                                   [required]="selectedTypeEntite.field5Obligatoire || operation.obligatoire" matInput name="operation_1"/>
                            <mat-icon [matTooltip]="'analytique.ajout.operationDescription' | translate:{ masque: operation.masque }" matSuffix>info</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedTypeEntite.field6 && getFieldParam('OPERATION_2') as operation">
                <div [ngClass]="{ 'has-error': operation2.invalid }" class="row">
                    <label class="col-md-2" [ngClass]="{ 'required': selectedTypeEntite.field6Obligatoire || operation.obligatoire }"><span>{{ operation.libelle }}</span></label>
                    <div class="col-md-3">
                        <mat-form-field class="d-flex">
                            <input #operation2="ngModel" [(ngModel)]="preferenceAnalytique.field6" [maxlength]="operation.masque.length" [minlength]="operation.tailleMinimale"
                                   [pattern]="getPatternFor(operation)"
                                   [required]="selectedTypeEntite.field6Obligatoire || operation.obligatoire" matInput name="operation_2"/>
                            <mat-icon [matTooltip]="'analytique.ajout.operationDescription' | translate:{ masque: operation.masque }" matSuffix>info</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="selectedTypeEntite">
    <button (click)="deletePref()" *ngIf="!!this.data.pref?.id" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="savePref()" [disabled]="addPrefAnalytiqueForm?.invalid || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="!!this.data.pref?.id ? 'global.actions.enregistrer' : 'global.actions.ajouter'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
