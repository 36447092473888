import {Component,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ZonePredefinie} from "@domain/zone-utilisateur/zone-predefinie";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ZonePredefinieEditPopinComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-predefinies/edit-popin/zone-predefinie-edit-popin.component";

/**
 * Composant d'un item de la liste des zones prédéfinies
 */
@Component({
	host: {'data-test-id': 'zone-predefinie-list-item'},
	templateUrl: './zone-predefinie-list-item.component.html'
})
export class ZonePredefinieListItemComponent extends ListViewItem<ZonePredefinie> implements OnInit {
	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private translateService: TranslateService,
		private matDialog: MatDialog
	) {
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation du champ type
		this.initType();
	}

	/**
	 * Récupération du libellé du type
	 */
	initType(): void {
		if (this.data.actif) {
			if (this.data.masque.includes('9') && !this.data.masque.includes('Z') && !this.data.masque.includes('?')) {
				//Si le masque contient un 9, mais pas de Z ni de ? --> champ numérique
				this.data.type = this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.libelleType.numerique');
			} else if (this.data.masque.includes('Z') && !this.data.masque.includes('9') && !this.data.masque.includes('?')) {
				//Si le masque contient un Z, mais pas de 9 ni de ? --> champ alphabétique
				this.data.type = this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.libelleType.alphabetique');
			} else if (this.data.masque.includes('9') || this.data.masque.includes('Z') || this.data.masque.includes('?')) {
				//Si le masque contient un Z ou un 9 ou un ? --> champ alphanumérique
				this.data.type = this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.libelleType.alphanumérique');
			} else {
				//Sinon --> Spécial
				this.data.type = this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.predefinies.libelleType.special');
			}
		}
	}

	/**
	 * Clic sur une zone
	 */
	onSelect(): void {
		//Ouverture de la popin d'édition de la zone
		this.matDialog.open(ZonePredefinieEditPopinComponent,{
			data: this.data,
			width: '60%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//Vérification de la nécessité de rafraîchir la liste
			if (refresh) {
				this.liste.refresh();
			}
		});
	}
}
