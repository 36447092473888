/**
 * Définition des type de contexte pour les documents
 */
export enum ContextDocument {
    FRAIS = 'FRAIS',
    NDF_V = 'NDF_V',
    PROFIL_VEHICULE_CARTE_GRISE = 'PROFIL_VEHICULE_CARTE_GRISE',
    PROFIL_VEHICULE_ASSURANCE = 'PROFIL_VEHICULE_ASSURANCE',
    PROFIL_VEHICULE_AUTORISATION = 'PROFIL_VEHICULE_AUTORISATION',
    REPORTING = 'REPORTING',
    LOGO_ORGA = 'LOGO_ORGA'
}
