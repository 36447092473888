import {Devise} from "@domain/settings/devise";

/**
 * DTO de l'entreprise
 */
export class Entreprise {
	enseigne: string;
	adresse: string;
	devise: Devise;
	siret: string;
	siren: string;
	tresorerie: string;
	numeroCodique: string;
	codeEtablissement: string;
	libelleBudget: string;
	codeBudget: string;
	codeCRM: string;
	def1: string;
	len1: number;
	oblig1: boolean;
	masque1: string;
	def2: string;
	len2: number;
	oblig2: boolean;
	masque2: string;
	def3: string;
	len3: number;
	oblig3: boolean;
	masque3: string;
	def4: string;
	len4: number;
	oblig4: boolean;
	masque4: string;

	//Contient le modèle que doit recevoir le champ "Adresse" du formulaire pour pouvoir afficher correctement l'adresse de l'entreprise
	adresseModel: { adresse: string,libelle: string };

	constructor(value: Object) {
		Object.assign(this,value);

		//Initialisation de la variable adresseModel avec l'adresse de l'entreprise, utilisé pour le champ "Adresse" dans le formulaire
		this.adresseModel = {
			adresse: this.adresse,
			libelle: this.adresse
		}
	}
}