<div class="lvi-content clickable" (click)="isOpened = !isOpened">
    <div class="avatar">
        <i *ngIf="data.typeMontant === typeMontant.NDF" class="nio icon-note_de_frais" style="vertical-align: middle;"></i>
        <i *ngIf="data.typeMontant === typeMontant.IJ || data.typeMontant === typeMontant.FRAIS_PREVISIONNEL" class="nio icon-frais_previsionnel_2" style="vertical-align: middle;"></i>
        <i *ngIf="data.typeMontant === typeMontant.FACTURE" class="nio icon-facture" style="vertical-align: middle;"></i>
        <i *ngIf="data.typeMontant === typeMontant.DOSSIER_VOYAGE" class="nio icon-dossier_voyage" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a *ngIf="data.typeBudget === typeBudget.REEL">
                {{ getLibelleTypeMontant() }} - {{ data.piece }} : {{ data.montant | montant: data.devise }}
            </a>
            <a *ngIf="data.typeBudget === typeBudget.PREVISIONNEL">
                {{ getLibelleTypeMontant() }} - {{ 'budget.detail.liste.om' | translate }} - {{ data.idOd }} : {{ data.montant | montant: data.devise }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li><strong translate>budget.detail.liste.agent</strong>{{ {nom: data.nomUser, prenom: data.prenomUser, matricule: data.matriculeUser} | user}}</li>
            <li *ngIf="data.numServ || data.libServ">
                <strong translate>budget.detail.liste.organisation</strong>
                <span *ngIf="data.numServ">{{ data.numServ }}</span>
                <span *ngIf="data.numServ && data.libServ"> - </span>
                <span *ngIf="data.libServ">{{ data.libServ }}</span>
            </li>
            <li *ngIf="data.refDossier || data.libDossier">
                <strong translate>budget.detail.liste.dossier</strong>
                <span *ngIf="data.refDossier">{{ data.refDossier }}</span>
                <span *ngIf="data.refDossier && data.libDossier"> - </span>
                <span *ngIf="data.libDossier">{{ data.libDossier }}</span>
            </li>
            <li *ngIf="data.datePeriode">
                <strong translate>budget.detail.liste.periode</strong>
                <span>{{ data.datePeriode | date: 'MM/yyyy' }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button>
            <mat-icon *ngIf="isOpened" color="primary">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOpened" color="primary">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="isOpened" class="p-20">
    <form autocomplete="off">
        <row-data [dataGauche]="{ traduction: 'budget.detail.liste.periode', valeur: data.datePeriode | date: 'shortDate' }"
                  [dataDroite]="{ traduction: 'budget.detail.liste.type', valeur: getLibelleTypeMontant() }">
        </row-data>
        <row-data [dataGauche]="{ traduction: 'budget.detail.liste.om', valeur: data.idOd }"
                  [dataDroite]="{ traduction: 'budget.detail.liste.piece', valeur: data.piece }">
        </row-data>
        <row-data [dataGauche]="{ traduction: 'budget.detail.liste.numServ', valeur: data.numServ }"
                  [dataDroite]="{ traduction: 'budget.detail.liste.libServ', valeur: data.libServ }">
        </row-data>
        <row-data [dataGauche]="{ traduction: 'budget.detail.liste.refDossier', valeur: data.refDossier }"
                  [dataDroite]="{ traduction: 'budget.detail.liste.libDossier', valeur: data.libDossier }">
        </row-data>
        <row-data [dataGauche]="{ traduction: 'budget.detail.liste.matricule', valeur: data.matriculeUser }"
                  [dataDroite]="{ traduction: 'budget.detail.liste.agent', valeur: data.nomUser + ' ' + data.prenomUser }">
        </row-data>
        <row-data [dataGauche]="{ traduction: 'budget.detail.liste.montantOrigine', valeur: data.montant | montant: data.devise }"
                  [dataDroite]="{ traduction: 'budget.detail.liste.montantImpute', valeur: data.montantBudget | montant: data.devise }">
        </row-data>
    </form>
</div>
