import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Population} from "@domain/workflow/population";

/**
 * Composant de l'item de liste d'une population
 */
@Component({
	templateUrl: './population-list-item.component.html'
})
export class PopulationListItemComponent extends ListViewItem<Population> {
}
