<div *ngIf="!data.log"><ng-container *ngTemplateOutlet="content"></ng-container></div>
<mat-expansion-panel *ngIf="data.log">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ngx-codemirror
        class="d-flex flex-grow-1 margin-top"
        name="log"
        [(ngModel)]="data.log"
        [options]="codeMirrorOptions"
    ></ngx-codemirror>
</mat-expansion-panel>
<ng-template #content>
    <div class="lvi-content">
        <div class="avatar" [class.danger]="data.result != UpgradeBddResult.SUCCESS">
            <i class="material-icons">move_up</i>
        </div>
        <div class="lvi-body">
            <div class="title">
                <a class="label" [class.non-clickable]="!data.log">
                    <span>{{ data.sql }}</span>
                </a>
            </div>
            <ul class="lvi-attrs">
                <li>
                    <strong [translate]="'admin.maintenance.migrations.onglets.historique.ordre'"></strong>
                    <span>{{ data.num }}</span>
                </li>
                <li [class.li-danger]="data.result != UpgradeBddResult.SUCCESS">
                    <strong [translate]="'admin.maintenance.migrations.onglets.historique.statut'"></strong>
                    <span>{{ ('admin.maintenance.migrations.onglets.historique.' + (data.result == UpgradeBddResult.SUCCESS ? 'success' : 'fail')) | translate }}</span>
                </li>
            </ul>
        </div>
    </div>
</ng-template>
