import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Component} from "@angular/core";
import {CoursDeviseItem} from "@domain/admin/bibliotheque/devises/coursDeviseItem";

/**
 * Composant d'affichage d'un taux d'une devise
 */
@Component({
    host: {'data-test-id': 'devise-taux-item'},
    templateUrl: './devise-taux-item.component.html'
})
export class DeviseTauxItemComponent extends ListViewItem<CoursDeviseItem> {
    /** Enum de l'origine des taux, pour l'utiliser dans le template */
    Origine = OrigineTaux;
}

/**
 * Enum pour les noms des différentes origines des taux
 */
export enum OrigineTaux {
    SAISIE = 0,
    IMPORT_TAUX = 1,
    IMPORT_OP_CARTE = 2
}
