import {Component,Inject} from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {PleaseWaitService} from '@share/component/please-wait/please-wait.service';
import {ToastrService} from 'ngx-toastr';
import {EntrepriseVehiculesService} from '../../entreprise-vehicules.service';
import {filter,finalize,first} from 'rxjs/operators';
import {Unite} from "@domain/vehicule/unite";
import {PuissanceFiscale} from "@domain/admin/entreprise/vehicules/puissanceFiscale";


/**
 * Popin d'affichage du détail d'une puissance fiscale
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'puissance-fiscale-detail'},
    templateUrl: './puissance-fiscale-detail.component.html'
})
export class PuissanceFiscaleDetailComponent {
    /** Indicateur de chargement en cours */
    isLoading: boolean;

    /** Indique si on est en création ou modification */
    isCreation: boolean = this.data.puissance.idPuissance === 0;

    /**
     * Constructeur
     *
     * @param data Données injectées dans le composant
     * @param matDialogRef Référence à cette popin
     * @param confirmService Service de confirmation
     * @param translateService Service des traductions
     * @param pleaseWaitService Service d'affichage du loading
     * @param vehiculeService Service des véhicules
     * @param toastrService Service des toasts
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { puissance: PuissanceFiscale, listeUnites: Array<Unite> },
                private matDialogRef: MatDialogRef<PuissanceFiscaleDetailComponent>,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private pleaseWaitService: PleaseWaitService,
                private vehiculeService: EntrepriseVehiculesService,
                private toastrService: ToastrService
    ) {
    }

    /**
     * Enregistrement de la puissance fiscale
     */
    savePuissance(): void {
        //Enregistrement en cours
        this.isLoading = true;

        //Enregistrement de la puissance
        this.vehiculeService.savePuissance(this.data.puissance).subscribe((result: Result) => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Message d'information
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                //Fermeture de la popin avec rechargement
                this.matDialogRef.close(true);
            } else {
                //On débloque le bouton
                this.isLoading = false;

                //Message d'erreur
                TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
            }
        });
    }

    /**
     * Suppression de la puissance fiscale
     */
    deletePuissance(): void {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() => {
                //Ouverture de la modale de chargement
                let waitDialogRef = this.pleaseWaitService.show();

                //Suppression puis fermeture de la modale de chargement
                this.vehiculeService.deletePuissance(this.data.puissance.idPuissance)
                    .pipe(first(),finalize(() => waitDialogRef.close()))
                    .subscribe((result: Result) => {
                        if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                            //Message d'information
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));

                            //Fermeture de la popin avec rechargement
                            this.matDialogRef.close(true);
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    });
            });
    }

}
