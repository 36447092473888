import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Params,Router} from "@angular/router";
import {Condition,TypeCondition} from "@domain/workflow/condition";
import {BehaviorSubject,Subscription} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {NgForm} from '@angular/forms';
import {ToastrService} from "ngx-toastr";
import {EntityWrapper} from "@domain/entite/EntityWrapper";
import {WorkflowConditionsService} from "@services/admin/workflow/workflow-conditions.service";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {EntiteService} from "@share/component/rule-builder/entite.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TypePortee} from '@domain/workflow/workflow';
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";

/**
 * Onglets de la page du détail d'une condition de visibilité
 */
export enum Onglets {
	CONDITION_VISIBILITE = 'CONDITION_VISIBILITE',
	TEST = 'TEST'
}

/**
 * Composant du détail d'une condition de visibilité
 */
@Component({
	host: {'data-test-id': 'condition-visibilite-detail'},
	templateUrl: './condition-visibilite-detail.component.html',
	styleUrls: ['./condition-visibilite-detail.component.scss']
})
export class ConditionVisibiliteDetailComponent implements OnInit,OnDestroy {
	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: Onglets.CONDITION_VISIBILITE,
			libelle: this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.tabs.conditionVisibilite')
		},{
			code: Onglets.TEST,
			libelle: this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.tabs.test')
		}
	];

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Souscription aux changements de route */
	routeSubscription: Subscription;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Condition actuelle */
	condition: Condition;

	/** Entité */
	entite: EntityWrapper;

	/** Mode création d'une condition de visibilité (si false : mode consultation/modification) */
	isCreation: boolean;

	/** Liste des portées */
	listePortees: Array<{ idPortee: string,libelle: string }>;

	/** Options pour l'affichage de la requête SQL */
	codeMirrorOptions = {
		theme: 'material',
		mode: 'text/x-mssql',
		lineNumbers: true,
		lineWrapping: true,
		readOnly: true
	};

	/** Formulaire de la page */
	@ViewChild('form')
	form: NgForm;

	/** Import des onglets pour le DOM */
	readonly Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur Angular
	 * @param activatedRoute la route
	 * @param toastrService le toaster
	 * @param workflowConditionsService le service du menu Workflow - Conditions
	 * @param entiteService le service de gestion des entités
	 * @param confirmService le service de confirmation d'action
	 * @param statutWorkflowService le service de gestion du statut du workflow
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private toastrService: ToastrService,
		private workflowConditionsService: WorkflowConditionsService,
		private entiteService: EntiteService,
		private confirmService: ConfirmService,
		private statutWorkflowService: StatutWorkflowService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On s'abonne aux changements de route
		this.routeSubscription = this.activatedRoute.params
			.subscribe((params: Params) => {
				//Récupération de l'identifiant de la condition de visibilité courante
				const idCondition: number = +params['idCondition'];

				//Chargement de la condition de visibilité
				this.loadConditionVisibilite(idCondition);
			});

		//Définition de la liste des actions possibles
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-sauvegarde',
				libelle: 'global.actions.enregistrer',
				isVisible: () => !!this.condition,
				isDisabled: () => this.form?.invalid,
				doAction: () => this.saveCondition()
			},{
				type: TypeAction.SECONDARY,
				icone: 'nio icon-suppression',
				libelle: 'global.actions.supprimer',
				isVisible: () => !!this.condition && !this.isCreation,
				doAction: () => this.deleteCondition()
			}
		]);
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Chargement d'une condition de visiblité et des données de formulaire
	 *
	 * @param idCondition l'identifiant de la condition de visibilité
	 */
	loadConditionVisibilite(idCondition: number) {
		//Suppression de la condition de visibilité courante s'il y en a déjà une
		delete this.condition;

		//Appel au service
		this.workflowConditionsService.loadCondition(idCondition)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Récupération de la liste des portées
					this.listePortees = result.data.listePortees;

					if (idCondition === 0) {
						//Mode création
						this.isCreation = true;

						//Initialisation d'une condition de visibilité
						this.condition = new Condition(TypeCondition.WORKFLOW);
					} else {
						//Mode consultation/modification
						this.isCreation = false;

						//Récupération de la condition de visibilité
						this.condition = result.data.condition;

						//Récupération de l'entité de la condition de visibilité
						this.loadBusinessData();
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Chargement de l'entité de la condition de visiblité
	 */
	loadBusinessData() {
		//Appel au service
		this.entiteService.loadBusinessData(EntiteService.getEntiteByPortee(this.condition.idPortee))
			.pipe(first())
			.subscribe((entite: EntityWrapper) => this.entite = entite);
	}

	/**
	 * Appelé à chaque changement de portée
	 */
	onPorteeChange(): void {
		//Réinitialisation de la rule et de l'entité
		delete this.condition.rule;
		delete this.entite;

		//Chargement de l'entité
		this.loadBusinessData();
	}

	/**
	 * Construction du tooltip du champ SQL
	 */
	getSqlTooltip(): string {
		let tooltip: string;

		switch (this.condition.idPortee) {
			case TypePortee.OD:
				tooltip = "- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.od') + "\n";
				break;
			case TypePortee.NF:
				tooltip = "- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.nf') + "\n";
				break;
			case TypePortee.AV:
				tooltip = "- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.av') + "\n";
				break;
			case TypePortee.FC:
				tooltip = "- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.fc') + "\n";
				break;
			case TypePortee.OT:
				tooltip = "- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.ot') + "\n";
				break;
			default:
				tooltip = "";
		}

		tooltip += ("- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.user') + "\n");
		tooltip += ("- " + this.translateService.instant('workflow.conditions.conditionsVisibilite.detail.condition.tooltipSQL.orga'));

		return tooltip;
	}

	/**
	 * Sauvegarde de la condition de visibilité
	 */
	saveCondition(): void {
		//Appel au service
		this.workflowConditionsService.saveCondition(this.condition)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat de l'appel
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Rafraichissement du statut de la synchro workflow
					this.statutWorkflowService.loadSynchroWFStatut();

					//Si l'on est en mode création
					if (this.isCreation) {
						//Navigation vers la page de consultation de l'alerte
						this.router.navigateByUrl(`Admin/Workflow/Conditions/ConditionVisibilite/${result.data.idCondition}`);
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de la condition de visibilité
	 */
	deleteCondition(): void {
		//Confirmation de l'action
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service
				this.workflowConditionsService.deleteCondition(this.condition.idCondition)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du résultat
						if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Rafraichissement du statut de la synchro workflow
							this.statutWorkflowService.loadSynchroWFStatut();

							//Retour à la liste
							this.onGoBack();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}

	/**
	 * Clic sur le bouton retour
	 */
	onGoBack(): void {
		//Retour à la liste des alertes
		this.router.navigateByUrl('Admin/Workflow/Conditions/ConditionsVisibilite');
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désabonnement
		this.routeSubscription.unsubscribe();
	}
}
