/**
 * Classe représentant les cartes grises pour les véhicules
 */
export class CarteGrise {
    id?: number;
    idEnergie?: number;
    idPa: number;
    idTypeVehicule?: number;
    idVehiculeCarteGrise?: number;
    dateImmatriculation?: Date;
    commentaire?: string;
    nbPJ?: number;
    puissanceAdministrative?: number;
    tauxCO2?: number;

    constructor() {
        this.id = 0;
        this.idVehiculeCarteGrise = 0;
    }
}
