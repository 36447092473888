import {SynchroSBTConfigUser} from "../voyage/travel/synchro-sbt-config-user";
import {TypeAiguillage, TypeNature} from "../voyage/travel/constants";
import {GeographieView} from "../geographie/geographieView";
import * as moment from "moment";
import {CarteTravel} from "../profil/carteTravel";
import {TypePresta} from "./type-presta";

/**
 * Modèle permettant la saisie d'une étape dans la popup de création de trajet<br>
 * <b>Attention :</b> Cette classe ne correspond pas à une entité dans le back, c'est du custom.
 *
 * @author Laurent SCIMIA
 * @date 13/04/2022
 */
export class SaisieEtapeDTO {
    /** Identifiant de l'étape si elle existe déjà */
    private idEtape: number;

    /** Type de départ de l'aller (Départ/Arrivée) */
    typeDepartAller: TypeDepart;

    /** Type de départ du retour (Départ/Arrivée) */
    typeDepartRetour: TypeDepart;

    /** Nature de l'étape (Attention la nature peut être différente de celle du SBT dans le back (AVION_TRAIN en offline devient AVION ou Train) c'est pour ça qu'on la gère en dehors du SBT) */
    nature: TypeNature;

    /** SBT de l'étape */
    sbt: SynchroSBTConfigUser;

    /** Jour du départ (ne pas prendre les heures en compte) */
    jourDepartAller: moment.Moment;

    /** Jour du retour (ne pas prendre les heures en compte) */
    jourDepartRetour?: moment.Moment;

    /** Heures du départ */
    heureDepartAller: string;

    /** Heures du retour */
    heureDepartRetour?: string;

    /** Référence demandée */
    reference?: string;

    /** Cout renseigné */
    cout?: number;

    /** Remarque pour le SBT */
    remarque?: string;

    /** Lieu de départ */
    origine: GeographieView;

    /** Lieu d'arrivée */
    destination: GeographieView;

    /** Fournisseur souhaité (airFrance, sncf, ...) */
    fournisseur?: FournisseurEtape;

    /** Classe demandée (1ère, économique, ...) */
    classe?: ClasseEtape;

    /** Options souhaitées */
    options?: Array<OptionEtape>;

    /** Numéro de téléphone */
    telephone?: string

    /** Liste des motifs de véhicule */
    listeMotifs?: Array<Motif>;

    /** Type de la convenance */
    typeConvenance: Convenance;

    /** Liste des convenances disponibles pour l'étape */
    _listeConvenances: Set<Convenance> = new Set();

    /** Objet de gestion de la convenance pour l'étape (front only) */
    gestionConvenance: { isConvAller: boolean, isConvRetour: boolean } = {isConvAller: false, isConvRetour: false}

    /** True s'il y a déjà une réservation pour cette étape */
    booked: boolean;

    /** Nombre de nuits maximum pour l'hôtel */
    maxNbNuits: number = null;

    /** Type du document (dans le cas d'une nature Document) */
    typeDocument: TypePresta;

    /** Carte souhaitée dans l'étape */
    carteTravel: CarteTravel;

    /** Indique s'il s'agit d'un renouvellement de carte */
    renouvellement: boolean = false;

    /** Indique si le champ origine est obligatoire (Front only) */
    private _isOrigineRequired: boolean = false;

    /** Indique si le champ destination est obligatoire (Front only) */
    private _isDestinationRequired: boolean = false;

    /** Indique si le champ jour départ est obligatoire (Front only) */
    private _isJourDepartRequired: boolean = false;

    /** Indique si le champ remarque est obligatoire (Front only) */
    private _isRemarqueRequired: boolean = false;

    /** Constructeur
     * @param sbt           SBT de l'étape
     * @param jourDepart    Jour de début de l'OD
     * @param jourRetour    Jour de fin de l'OD
     * @param heureDepart   Heure de départ
     * @param heureRetour   Heure de retour
     * @param classe        Classe voulue (utile uniquement pour les avions offline)
     * @param isFirstEtape  Indique si c'est l'initialisation de la première étape
     * @param convenance    Convenance de l'étape
     */
    constructor(sbt: SynchroSBTConfigUser, jourDepart: moment.Moment, jourRetour: moment.Moment, heureDepart: string, heureRetour: string,classe: ClasseEtape, isFirstEtape: boolean = true, convenance: Convenance = null) {
        let currentConvenance: Convenance;

        this.idEtape = 0;
        this.typeDepartAller = TypeDepart.DEPART;
        this.typeDepartRetour = TypeDepart.DEPART;

        //Attention la nature peut être différente de celle du SBT dans le back (AVION_TRAIN en offline devient AVION ou Train) c'est pour ça qu'on la récupère en dehors du SBT
        this.nature = sbt.idNature;

        this.sbt = sbt;

        if (convenance) {
            //On complète la convenance avec l'ensemble des propriétés
            currentConvenance = TypeConvenance.getConvenance(convenance.valeur);
        } else {
            //Par défaut, pas de convenance
            currentConvenance = TypeConvenance.getConvenance(TypeConvenance.Aucune);
        }

        //Mise à jour de la convenance sur l'étape
        this.typeConvenance = currentConvenance;
        //Initialisation des convenances
        this._listeConvenances.add(currentConvenance);

        //A la création, l'étape n'est pas réservée
        this.booked = false;

        //En avion offline, on initialise la classe
        if (sbt.typeAiguillage === TypeAiguillage.OFFLINE && [TypeNature.AVION, TypeNature.TRAIN].includes(sbt.idNature)) {
            this.classe = classe;
        }

        //Si c'est la première étape
        if (isFirstEtape) {
            //On veut initialiser la date de départ
            this.jourDepartAller = jourDepart;

            //S'il y a une heure, on veut initialiser l'heure de départ
            if (heureDepart) {
                this.heureDepartAller = heureDepart;
            }

            //On veut initialiser la date de retour
            this.jourDepartRetour = jourRetour;

            //S'il y a une heure, on veut initialiser l'heure de retour
            if (heureRetour) {
                this.heureDepartRetour = heureRetour;
            }
        } else if (sbt.idNature === TypeNature.DOCUMENT) {
            this.jourDepartAller = jourDepart;
        }
    }

    /**
     * Met à jour l'objet de gestion des convenances
     *
     * @param isAller true s'il s'agit de la convenance aller, false pour retour
     * @param valeur valeur à définir pour la convenance
     */
    updateGestionConvenance(isAller: boolean, valeur: boolean): void {
        if (isAller) {
            this.gestionConvenance.isConvAller = valeur;
        } else {
            this.gestionConvenance.isConvRetour = valeur;
        }
    }

    /** On met un getter pour pouvoir le passe de façon dynamique à un sous composant */
    public getMaxNbNuits() {
        return this.maxNbNuits;
    }

	/* Accesseurs */
    /**
     * Récupère le set des convenances sous forme de liste triée par valeurs
     */
    public get listeConvenancesSize(): number {
        return this._listeConvenances.size;
    }

    public get listeConvenancesTriee(): Array<Convenance> {
        return Array.from(this._listeConvenances.values()).sort((a, b) => a.valeur - b.valeur);
    }

	public getIdEtape(): number {return this.idEtape};
	public get isOrigineRequired(): boolean {return this._isOrigineRequired;}
	public get isDestinationRequired(): boolean {return this._isDestinationRequired;}
	public get isJourDepartRequired(): boolean {return this._isJourDepartRequired;}
	public get isRemarqueRequired(): boolean {return this._isRemarqueRequired;}

	public set isOrigineRequired(value: boolean) {this._isOrigineRequired = value;}
	public set isDestinationRequired(value: boolean) {this._isDestinationRequired = value;}
	public set isJourDepartRequired(value: boolean) {this._isJourDepartRequired = value;}
	public set isRemarqueRequired(value: boolean) {this._isRemarqueRequired = value;}
}

/* Interfaces */
/** Modèle des options d'une étape */
export interface OptionEtape {
    id: IdOptionEtape,
    libelle: string,
}

/** Modèle d'une classe pour une étape */
export interface ClasseEtape {
    id: number,
    libelle: string,
}

/** Modèle d'un fournisseur pour une étape */
export interface FournisseurEtape {
    id: number,
    libelle: string,
}

/** Modèle pour le motif d'une étape véhicule */
export interface Motif {
    idVehiculeMotif: number;
    typeVehicule: TypeVehicule;
    motif: string;
    isObligatoire: boolean;
}


/* Enumérations */
/** Enumération des types de départ possibles pour une étape */
export enum TypeDepart {
    AUCUN = -1,
    DEPART = 0,
    ARRIVEE = 1
}

/** Enumération des options possibles pour une étape */
export enum IdOptionEtape {
    TRAJET_DIRECT = 'TRAJET_DIRECT',
    TOUS_TRANSPORTS = 'TOUS_TRANSPORTS',
    RESTAURANT = 'RESTAURANT',
    ARRIVEE_TARDIVE = 'ARRIVEE_TARDIVE',
    PARKING = 'PARKING',
    PETIT_DEJ = 'PETIT_DEJ',
    GPS = 'GPS',
    BOITE_AUTO = 'BOITE_AUTO',
}

/** Enumération des types de véhicule. Normalement il n'y a plus que Location, Pro, et Perso qui sont utilisés, mais sait-on jamais... */
export enum TypeVehicule {
    VOITURE = 0,
    TAXI = 1,
    MINIBUS = 2,
    VOITURE_CHAUFFEUR = 3,
    LOCATION = 4,
    PRO = 5,
    PERSO = 6,
    AUTRE = 7,
}

/** Enrichissement de l'enum TypeVehicule */
export namespace TypeVehicule {

    /**
     * Renvoie le type de véhicule correspondant à un type nature
     *
     * @param typeNature Type nature à traiter
     */
    export function getFromTypeNature(typeNature: TypeNature): TypeVehicule | null {
        switch (typeNature) {
            case TypeNature.VOITURE_DE_LOCATION:
                return this.LOCATION;
            case TypeNature.VOITURE_DE_SOCIETE:
                return this.PRO;
            case TypeNature.VOITURE_PERSONNELLE:
                return this.PERSO;
            case TypeNature.TAXI:
                return this.TAXI;
            default:
                //Les autres types nature ne sont pas des véhicules
                return null

        }
    }
}

/** Enum des types de convenance */
export enum TypeConvenance {
    Aucune,
    Aller,
    Retour,
    AllerRetour
}

/** Extension de l'enum des types de convenance */
export namespace TypeConvenance {
    //Déclaration des objets correspondants
    const aucun: Convenance = {valeur: TypeConvenance.Aucune, libelle: 'od.voyage.travel.aucune'}
    const aller: Convenance = {valeur: TypeConvenance.Aller, libelle: 'od.voyage.travel.aller'};
    const retour: Convenance = {valeur: TypeConvenance.Retour, libelle: 'od.voyage.travel.retour'};
    const allerRetour: Convenance = {valeur: TypeConvenance.AllerRetour, libelle: 'od.voyage.travel.allerRetour'};

    /**
     * Récupère l'objet correspondant à la convenance
     *
     * @param type    Convenance recherchée
     */
    export function getConvenance(type: TypeConvenance): Convenance {
        switch (type) {
            case TypeConvenance.Aucune:
                return aucun;
            case TypeConvenance.Aller:
                return aller;
            case TypeConvenance.Retour:
                return retour;
            case TypeConvenance.AllerRetour:
                return allerRetour;
        }
    }
}

/** Type des convenances */
export type Convenance = { valeur: TypeConvenance, libelle: string };
