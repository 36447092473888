import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TypeMontant} from "@domain/budget/type-montant.enum";
import {TypeBudget} from "@domain/budget/type-budget.enum";
import {DetailEnveloppe} from "@domain/budget/detailEnveloppe";
import {EnveloppeConsommationItemComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-consommation/enveloppe-consommation-item.component";
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: 'enveloppe-consommation',
    host: {'data-test-id': 'enveloppe-consommation'},
    templateUrl: './enveloppe-consommation.component.html'
})
/**
 * Composant de détail d'une enveloppe
 */
export class EnveloppeConsommationComponent implements OnInit {

    /** Id de l'enveloppe en cours */
    @Input() idEnveloppe: number

    /** Le type de budget (réel ou prévisionnel) que l'on souhaite consulter */
    @Input() typeBudget: TypeBudget;

    /** Liste des lignes d'imputation de budget de l'enveloppe */
    listeImpBudg: ListView<DetailEnveloppe, EnveloppeConsommationItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService le moteur de trad
     */
    constructor(private translateService: TranslateService) { }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //On initialise la liste pour afficher la consommation
        this.listeImpBudg = new ListView<DetailEnveloppe,EnveloppeConsommationItemComponent>({
            uri: '/controller/Budget/listeConsommation/' + this.idEnveloppe + '?typeBudget=' + this.typeBudget, //Uniquement si on a une enveloppe consommation
            title: this.translateService.instant("budget.detail.liste.title"),
            component: EnveloppeConsommationItemComponent,
            isFilter: true,
            listeFilters: [{
                title: this.translateService.instant("filter.typeMontant"),
                clef: "typeMontant",
                isDefault: false,
                listeValues: [{
                    code: TypeMontant.NDF.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.ndf")
                },{
                    code: TypeMontant.IJ.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.ij")
                },{
                    code: TypeMontant.FACTURE.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.facture")
                },{
                    code: TypeMontant.DOSSIER_VOYAGE.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.dossierVoyage")
                },{
                    code: TypeMontant.FRAIS_PREVISIONNEL.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.fraisPrev")
                }]
            },{
                title: this.translateService.instant("filter.agent"),
                clef: "matriculeUser,nomUser,prenomUser",
                isDefault: true
            },{
                title: this.translateService.instant("filter.organisation"),
                clef: "numServ,libServ",
                isDefault: true
            },{
                title: this.translateService.instant("filter.dossier"),
                clef: "refDossier,libDossier",
                isDefault: true
            },{
                title: this.translateService.instant("filter.exercice"),
                clef: "libelleExercice",
                type: TypeFilter[TypeFilter.STRING],
                typeComparaison: TypeComparaison[TypeComparaison.LIKE],
                isDefault: true
            }]
        });

        //Persistence des filtres
        this.listeImpBudg.isPersistFilters = true;
        this.listeImpBudg.className = 'EnveloppeConsommationComponent' + this.typeBudget;
    }

}
