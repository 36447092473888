<div class="lvi-content">
    <div class="avatar">
        <ng-container *ngIf="extraOptions.getIcon == null; else tplIcon">{{ extraOptions.displayItem(data, translateService, datePipe, montantPipe).substr(0, 3) }}</ng-container>
        <ng-template #tplIcon><span [innerHtml]="extraOptions.getIcon(data)"></span></ng-template>
    </div>
    <div class="lvi-body">
        <div class="title"><a [routerLink]="" (click)="extraOptions.close(data)">{{ extraOptions.displayItem(data,translateService,datePipe) }}</a></div>
        <ul class="lvi-attrs" *ngIf="attributes">
            <li *ngFor="let attr of attributes">
                <strong [translate]="attr.clef"></strong>
                {{ attr.valeur }}
            </li>
        </ul>
    </div>
</div>