import {Component} from "@angular/core";

/**
 * Sous-menu personnalisation
 *
 * @author Guillaume TRAVOT
 * @date 26/06/2024
 */
@Component({
    host: {'data-test-id': 'personnalisation'},
    templateUrl: './personnalisation.component.html'
})
export class PersonnalisationComponent {}
