import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TypeCodeErreur } from "@domain/common/http/result";
import { Compagnie } from "@domain/profil/compagnie";
import { TranslateService } from "@ngx-translate/core";
import { VoyagesReferentielsService } from "@services/admin/voyages/voyages-referentiels.service";
import { ToastrService } from "ngx-toastr";

/**
 * Composant d'affichage des informations d'une compagnie (création et modification)
 */
@Component({
	host: {'data-test-id': 'voyages-referentiels-compagnies-infos'},
	templateUrl: './voyages-referentiels-compagnies-infos.component.html'
})
export class VoyagesReferentielsCompagniesInfosComponent implements OnInit {
	/** Booléen pour savoir si l'affichage de la page se fait en mode création d'une compagnie ou non */
	isCreation: boolean = false;

	/** Compagnie contenant les informations à afficher sur la page */
	compagnie: Compagnie = {
		actif: false,
		code: "",
		custom: false,
		libelle: "",
		lowCost: false,
		typePrestation: 0
	};

	/** Formulaire de la compagnie */
	@ViewChild('form') form: NgForm;

	/** Ensemble des types de prestations possibles pour une compagnie */
	typesPrestation = this.voyagesReferentielsService.listePrestations;

	/** Pending pour le bouton d'enregistrement */
	isSaving: boolean;

	/** Pending pour le bouton de suppression */
	isDeleting: boolean;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param voyagesReferentielsService Service de gestion des référentiels du menu Voyages
	 * @param matDialogRef service de référence de la popup
	 * @param data données transmises à la popup
	 * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
	 * @param router Router de l'application pour naviguer entre les pages
	 */
	constructor(private translateService: TranslateService,
				private voyagesReferentielsService: VoyagesReferentielsService,
				private matDialogRef: MatDialogRef<VoyagesReferentielsCompagniesInfosComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { idCompagnie: number },
				private toastrService: ToastrService,
				private router: Router) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//On est en mode création si l'identifiant de la compagnie est égal à 0 (sinon on est en mode modification)
		this.isCreation = this.data.idCompagnie === 0;

		//Si on est en création alors on crée un nouvel objet du type Compagnie
		if (this.isCreation) {
			//Une classe créée manuellement est custom
			this.compagnie.custom = true;
		} else {
			//on charge la compagnie sélectionnée et on récupère toutes ses informations à afficher
			this.voyagesReferentielsService.getCompagnieById(this.data.idCompagnie).subscribe(data => {
				//Récupération des informations de la compagnie sélectionnée
				this.compagnie = data.data.compagnie;
			});
		}
	}

	/**
	 * Méthode appelée lors de l'enregistrement de la compagnie sélectionnée
	 */
	save() {
		//Si le formulaire est valide alors on enregistre la compagnie
		if (this.form.valid) {
			//Activation du pending sur le bouton
			this.isSaving = true;

			//Enregistrement de la compagnie en base
			this.voyagesReferentielsService.saveCompagnie(this.compagnie).subscribe(data => {
				//Si la réponse contient un code d'erreur
				if (data.codeErreur === TypeCodeErreur.ERROR_SAVE) {
					//Affichage d'un message d'erreur d'enregistrement
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				} else if (data.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
					//Affichage d'un message d'erreur d'enregistrement à cause d'un doublon
					this.toastrService.error(this.translateService.instant('admin.voyages.referentiels.compagnie.erreurDoublon'));
				} else {
					//Si la réponse ne contient pas de message d'erreur alors on affiche un message de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popup
					this.matDialogRef.close(true);
				}

				//Désactivation du pending sur le bouton
				this.isSaving = false;
			});
		} else {
			//Si, au contraire, le formulaire n'est pas valide alors affichage d'un message disant que le formulaire n'est pas valide
			this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
		}
	}

	/**
	 * Suppression de la compagnie en base
	 */
	delete() {
		//Activation du pending sur le bouton
		this.isDeleting = true;

		//Suppression de la compagnie dans la base
		this.voyagesReferentielsService.deleteCompagnie(this.compagnie.id).subscribe(data => {
			//Si la réponse contient un code d'erreur, affichage d'un message d'erreur de suppression
			if (data.codeErreur !== TypeCodeErreur.NO_ERROR) {
				this.toastrService.error(this.translateService.instant('global.errors.suppression'));
			} else {
				//Sinon on affiche un message de succès
				this.toastrService.success(this.translateService.instant('global.success.suppression'));

				//Fermeture de la popup
				this.matDialogRef.close(true);
			}

			//Désactivation du pending sur le bouton
			this.isDeleting = false;
		});
	}
}
