import {Component,Input,OnInit} from '@angular/core';
import {TypeZoneUtilisateur,ZoneUtilisateur} from "@domain/zone-utilisateur/zone-utilisateur";
import {TranslateService} from "@ngx-translate/core";
import {TypePortee} from "@domain/workflow/workflow";
import {ListView} from "@domain/common/list-view";
import {ZUReferentiel} from "@domain/zone-utilisateur/zuReferentiel";
import {ZuRefListItemComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/detail/config/zu-ref-list-item/zu-ref-list-item.component";
import {FiltreZU} from "@domain/zone-utilisateur/filtre-zu";
import {FiltreZuListItemComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/detail/config/filtre-zu-list-item/filtre-zu-list-item.component";
import {MatDialog} from "@angular/material/dialog";
import {AddFiltreZuPopinComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/detail/config/add-filtre-zu-popin/add-filtre-zu-popin.component";
import {AddZuRefPopinComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/detail/config/add-zu-ref-popin/add-zu-ref-popin.component";

/**
 * Composant de l'onglet Configuration de la page de détail d'une zone paramétrable
 */
@Component({
	host: {'data-test-id': 'zone-parametrable-config'},
	selector: 'zone-parametrable-config',
	templateUrl: './zone-parametrable-config.component.html'
})
export class ZoneParametrableConfigComponent implements OnInit {
	/** Zone utilisateur courante */
	@Input() zu: ZoneUtilisateur;

	/** Liste des types de zone */
	listeTypeZone: Array<{ code: TypeZoneUtilisateur,libelle: string }> = [
		{
			code: null,
			libelle: this.translateService.instant('global.input.choisissez')
		},{
			code: TypeZoneUtilisateur.TEXTE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.TEXTE)
		},{
			code: TypeZoneUtilisateur.CHECKBOX,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.CHECKBOX)
		},{
			code: TypeZoneUtilisateur.MONTANT,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.MONTANT)
		},{
			code: TypeZoneUtilisateur.DATE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.DATE)
		},{
			code: TypeZoneUtilisateur.LISTE_UNIQUE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_UNIQUE)
		},{
			code: TypeZoneUtilisateur.LISTE_MULTIPLE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_MULTIPLE)
		},{
			code: TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE)
		},{
			code: TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE)
		},{
			code: TypeZoneUtilisateur.HEURE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.HEURE)
		}
	];

	/** Liste des portée */
	listePortee: Array<{ code: TypePortee,libelle: string }> = [
		{
			code: null,
			libelle: this.translateService.instant('global.input.choisissez')
		},{
			code: TypePortee.OD,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + TypePortee.OD)
		},{
			code: TypePortee.OT,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + TypePortee.OT)
		},{
			code: TypePortee.NF,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + TypePortee.NF)
		},{
			code: TypePortee.AV,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + TypePortee.AV)
		},{
			code: TypePortee.FC,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + TypePortee.FC)
		},{
			code: TypePortee.OP,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.porteeLibelle.' + TypePortee.OP)
		}
	];

	/** Liste du référentiel des valeurs de la zone parametrable */
	listeZuRef: ListView<ZUReferentiel,ZuRefListItemComponent>;

	/** Liste des filtres de la zone paramétrable */
	listeFiltreZU: ListView<FiltreZU,FiltreZuListItemComponent>;

	/** Import de l'énum pour le DOM */
	readonly TypeZoneUtilisateur = TypeZoneUtilisateur;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private translateService: TranslateService,
		private matDialog: MatDialog
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste de référentiel
		this.listeZuRef = new ListView<ZUReferentiel,ZuRefListItemComponent>({
			uri: `/controller/ZoneUtilisateur/getListeZoneUtilisateurReferentiel?idZU=${this.zu.idZU}`,
			title: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeZuRef.title'),
			component: ZuRefListItemComponent,
			isLocal: true,
			listeActions: [
				{
					icon: 'add',
					onPress: () => this.addZURef()
				}
			]
		});

		//Forçage de la pagination à 5 éléments
		this.listeZuRef.nbObjetsParPage = 5

		//Initialisation de la liste des filtres
		this.listeFiltreZU = new ListView<FiltreZU,FiltreZuListItemComponent>({
			uri: `/controller/ZoneUtilisateur/getListeFiltreZoneUtilisateur?idZU=${this.zu.idZU}`,
			title: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeFiltreZu.title'),
			component: FiltreZuListItemComponent,
			isLocal: true,
			defaultOrder: "typeFiltre,libelleObjet",
			emptyMessage: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.listeFiltreZu.noData'),
			listeActions: [
				{
					icon: 'add',
					onPress: () => this.addFiltre()
				}
			]
		});

		//Forçage de la pagination à 5 éléments
		this.listeFiltreZU.nbObjetsParPage = 5;
	}

	/**
	 * Appelé au changement de type de zone
	 */
	onTypeZoneChange(): void {
		//Réinitialisation de tous les champs valeur par défaut
		delete this.zu.texteDefaut;
		delete this.zu.boolDefaut;
		delete this.zu.montantDefaut;
		delete this.zu.dateDefaut;
		delete this.zu.heureDefaut;
	}

	/**
	 * Inidique s'il faut afficher ou non la liste du référentiel
	 */
	hasReferentiel(): boolean {
		return [TypeZoneUtilisateur.LISTE_UNIQUE,TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE,TypeZoneUtilisateur.LISTE_MULTIPLE,TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(this.zu.typeChamp);
	}

	/**
	 * Ajout d'une ligne au référentiel
	 */
	addZURef(): void {
		//Ouverture de la popin
		this.matDialog.open<AddZuRefPopinComponent,any,boolean>(AddZuRefPopinComponent,{
			data: {
				idZU: this.zu.idZU
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			if (refresh) {
				//Rechargement de la liste du référentiel
				this.listeZuRef.refresh();
			}
		});
	}

	/**
	 * Ajout d'un filtre sur la zone
	 */
	addFiltre(): void {
		//Ouverture de la popin
		this.matDialog.open<AddFiltreZuPopinComponent,any,boolean>(AddFiltreZuPopinComponent,{
			data: {
				idZU: this.zu.idZU,
				idPortee: this.zu.idPortee
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			if (refresh) {
				//Rechargement de la liste des filtres
				this.listeFiltreZU.refresh();
			}
		});
	}
}
