import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import {MatDialog} from "@angular/material/dialog";
import {OdEngagementsIndemnitesDegrevementsComponent} from "@components/od/detail/engagements/indemnites/degrevements/od-engagements-indemnites-degrevements.component";
import {OdEngagementPipe} from "@components/od/detail/engagements/od-engagement.pipe";
import {OdEngagementsResumeComponent} from "@components/od/detail/engagements/resume/od-engagements-resume.component";
import {ODService} from "@components/od/od.service";
import {AppState} from "@domain/appstate";
import {TypeCodeErreur} from "@domain/common/http/result";
import {Contexte} from "@domain/od/engagements/indemnites/contexte";
import {EngagementsIndemnites} from "@domain/od/engagements/indemnites/engagements-indemnites";
import {IJChange} from "@domain/od/engagements/indemnites/ij-change";
import {EngagementsResume} from "@domain/od/engagements/resume/engagements-resume";
import {EngagementsResumeItem, TypeEngagement} from "@domain/od/engagements/resume/engagements-resume-item";
import {Od} from "@domain/od/od";
import {SettingsODState} from "@domain/settings/settings";
import {TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {TypeProfil, User} from "@domain/user/user";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {finalize, mergeMap} from "rxjs/operators";
import {OdEngagementsIndemnitesHistoriqueComponent} from "./historique/od-engagements-indemnites-historique.component";

/**
 * Composant du cadre "Indemnités journalières"
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-engagements-indemnites',
	templateUrl: './od-engagements-indemnites.component.html'
})
export class OdEngagementsIndemnitesComponent implements OnInit {
	/* Déclaration pour usage depuis le template */
	Contexte = Contexte;
	TypeProfil = TypeProfil;

	/** Portée de l'objet */
	readonly portee: TypePortee = TypePortee.OD;

	/** Ordre de mission */
	@Input() od: Od = null;

	/** Indicateur de modification possible */
	@Input() canModifier: boolean = false;

	/** Indicateur de complétion possible */
	@Input() canCompleter: boolean = false;

	/** Composant du résumé */
	@Input("resume") resume: OdEngagementsResumeComponent;

	/** Indique si le composant est inclus depuis l'onglet des frais ou celui des engagements */
	@Input("contexte") contexte: Contexte;

	/** Évènement déclenché après la mise à jour des dégrèvements (dans la popin) */
	@Output() onDegrevementsChanged = new EventEmitter<void>();
	
	/** Évènement déclenché après le calcul des indemnités (déclenché après enregistrement des dégrèvements) */
	@Output() onCalculIndemnitesDone = new EventEmitter<void>();

	/** Utilisateur connecté */
	user: User;

	/** Paramétrage de l'OD */
	settings: SettingsODState;

	/** Paramètres du type entité */
	typeEntiteParam: TypeEntiteParamOD;

	/** Montant total des indemnités */
	montantOrigine: number;

	/** Montant total des dégrèvements */
	montantDegrevement: number;

	/** Indemnités journalières */
	indemnites: EngagementsIndemnites;

	/** Coefficient modificateur du collaborateur */
	coefficientModificateurCollaborateur: number;

	/** Coefficient modificateur de la mission */
	coefficientModificateurOd: number;

	/** Liste des historiques de calcul des indemnités */
	listeHistoriqueIJ: Array<IJChange>;

	/** Indicateur du chargement en cours */
	isLoading: boolean = false;

	/** Gestionnaire d'erreur du champ du coefficient modificateur des indemnités */
	coeffIjErrorStateMatcher: coeffIjErrorStateMatcher;

	/**
	 * Constructeur
	 */
	constructor(private odService: ODService,
				private store: Store<AppState>,
				public translateService: TranslateService,
				private toastrService: ToastrService,
				private odEngagementPipe: OdEngagementPipe<EngagementsResumeItem>,
				private matDialog: MatDialog) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement des données
		this.isLoading = true;

		//Gestion des erreurs de saisie du coefficient modificateur des indemnités
		this.coeffIjErrorStateMatcher = new coeffIjErrorStateMatcher();

		//Sélection du paramétrage
		this.store.select(state => state.settings?.[this.portee]).subscribe(settings => this.settings = settings);

		//Sélection de l'utilisateur connecté
		this.store.select(state => state.session?.user).subscribe(user => this.user = user);

		//Récupération des paramètres du type entité
		this.store.select(state => state.typeEntite?.[this.portee]).subscribe(typeEntite => this.typeEntiteParam = typeEntite?.typeEntiteParam);

		//Chargement des données
		this.refresh();
	}

	/**
	 * Rafraichissement des données
	 */
	refresh(): void {
		//Chargement des données
		this.isLoading = true;

		//Calcul des IJ puis récupération des données
		this.odService.calculIndemnites(this.od?.idOd)
			.pipe(
				mergeMap(() => {
					//Récupération des indemnités
					return this.odService.getIndemnites(this.od?.idOd);
				}),
				finalize(() => this.isLoading = false)
			)
			.subscribe({
				next: (result: EngagementsIndemnites) => {
					//Récupération des données
					this.indemnites = result;

					//Si le collaborateur a un coefficient modificateur d'IJ configuré, récupère la valeur. Sinon, l'initialise à 100%.
					if(result.coefficientModificateurCollaborateur != null) {
						this.coefficientModificateurCollaborateur = result.coefficientModificateurCollaborateur;
					} else {
						this.coefficientModificateurCollaborateur = 100;
					}

					//Si la mission a un coefficient modificateur d'IJ configuré, récupère la valeur. Sinon, l'initialise avec le coefficient du collaborateur.
					if(result.coefficientModificateurOd != null) {
						this.coefficientModificateurOd = result.coefficientModificateurOd;
					} else {
						this.coefficientModificateurOd = this.coefficientModificateurCollaborateur;
					}

					//Calcule le total de l'indemnité.
					this.montantOrigine = 0;
					for(let prestation of this.indemnites.indemnites) {
						this.montantOrigine += prestation.montantCumulIndemnites;
					}

					//On met à jour l'information sur l'OD
					this.od.plafondAvance = result.plafondAvance;

					//Historique des changements du montant total de l'IJ (arpès validation)
					this.listeHistoriqueIJ = this.indemnites.historiqueIJ;
					
					//Déclenchement de l'évènement de fin du calcul des indemnités
					this.onCalculIndemnitesDone.emit();
				},
				error: () => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.chargement'));
				}
			});
	}

	/**
	 * Mise à jour du montant total et du montant dégrevé avec les valeurs provenant du résumé
	 *
	 * @param resume Données du résumé
	 */
	updateTotaux(resume: EngagementsResume): void {
		//Récupération de la ligne des indemnités
		const resumeIndemnites: EngagementsResumeItem = this.odEngagementPipe.transform(resume.listeEngagement, [TypeEngagement.INDEMNITES])[0];

		//Mise à jour des montants
		this.montantOrigine = resumeIndemnites.montantOrigine;
		this.montantDegrevement = resumeIndemnites.montantDegrevement;
	}

	/**
	 * Ouvre la popin des indemnités
	 */
	openIndemnites(): void {
		//Affichage de la popup de confirmation/saisie du motif
		this.matDialog.open(OdEngagementsIndemnitesDegrevementsComponent,{
			data: {
				od: this.od,
				coefficientModificateurCollaborateur: this.coefficientModificateurCollaborateur,
				coefficientModificateurOd: this.coefficientModificateurOd,
				canModifier: this.canModifier && this.settings.idModeDegrevement == 2 && this.typeEntiteParam.gestionDegrevement,
				canCompleter: this.canCompleter
			},
			hasBackdrop: true
		}).afterClosed().subscribe(changed => {
			//Si les dégrèvements ont été modifiés, déclenchement de l'évènement
			if (changed) {
				this.onDegrevementsChanged.emit();
			}
		});
	}

	/**
	 * Vérifie le coefficient et lance son application si valide
	 */
	changeCoeffIj(): void {
		//Vérification de la validité de l'input
		if (!this.coeffIjErrorStateMatcher.errorState) {
			//Applique le coefficient
			this.applyCoeffIj();
		}
	}

	/**
	 * Modification du coefficient des indemnités
	 */
	applyCoeffIj(): void {
		//Vérification de la validité de l'input
		if (!this.coeffIjErrorStateMatcher.errorState) {
			//Indicateur de chargement
			this.isLoading = true;

			//Appel au service pour modifier le coefficient et lancer le recalcul
			this.odService.changeCoeffIJ(this.od?.idOd,this.coefficientModificateurOd)
				.subscribe(
					(result) => {
						if (result && result.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message de succès
							this.toastrService.success(this.translateService.instant('od.frais.indemnites.changeCoeffIjOk'));

							//Rafraichissement des données
							this.refresh();
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));

							//Fin du chargement des données
							this.isLoading = false;
						}
					},
					() => {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));

						//Fin du chargement des données
						this.isLoading = false;
					});
		}
	}

	/**
	 * Ouvre la popin des historiques
	 */
	openHistorique(): void {
		//Affichage de la popup de confirmation/saisie du motif
		this.matDialog.open(OdEngagementsIndemnitesHistoriqueComponent,{
			data: {
				listeHistoriqueIJ: this.listeHistoriqueIJ,
				deviseEntreprise: this.settings.deviseEntreprise,
				user: this.user
			},
			hasBackdrop: true,
			minWidth: 500
		}).afterClosed();
	}

}

/**
 * Classe de gestion des erreurs du champ permettant de modifier le coefficient des IJ
 */
class coeffIjErrorStateMatcher implements ErrorStateMatcher {
	/** Valeur minimale acceptée pour la saisie du coefficient */
	min: number = 0;

	/** Valeur maximale acceptée pour la saisie du coefficient */
	max: number = 100;

	/** Indicateur courant de l'état, en erreur ou non */
	errorState: boolean = false;

	/**
	 * Fonction de vérification de la validité du champ
	 */
	isErrorState(control: FormControl | null,form: FormGroupDirective | NgForm | null): boolean {
		let value: number;
		let isInvalid: boolean = control.invalid;

		//Vérification ssi le champ n'est pas déjà invalide
		if (!isInvalid && control.value) {
			//Récupération de la valeur en nombre
			value = parseFloat(control.value);

			//Vérification que la valeur est comprise dans la plage autorisée
			isInvalid = value < this.min || value > this.max;
		}

		//Retour de l'état d'erreur si le champ n'a pas encore été "touché"
		return this.errorState = control && isInvalid && (control.dirty || control.touched);
	}

}