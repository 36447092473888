import {Component,Inject,TemplateRef,ViewChild} from '@angular/core';
import {TypeAction,TypePortee} from "@domain/workflow/workflow";
import {TranslateService} from "@ngx-translate/core";
import {Statut} from "@domain/workflow/statut";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter} from "rxjs/operators";
import {WorkflowService} from "@components/workflow/workflow.service";
import {Router} from "@angular/router";
import {ActionWorkflow} from "@domain/workflow/action-workflow";
import {ToastrService} from "ngx-toastr";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Composant du formulaire pour effectuer un mouvement workflow par api sur l'objet ou le dossier voyage (od uniquement)
 */
@Component({
	host: {'data-test-id': 'popup-mouvement-wf-objet'},
	templateUrl: './popup-mouvement-wf.component.html'
})
export class PopupMouvementWfComponent {
	//Accès à l'enum dans la vue
	readonly TypeAction = TypeAction;
	readonly TypePortee = TypePortee;
	readonly NiveauAlerte = NiveauAlerte;

	/** Indicateur de chargement */
	isLoading: boolean = false;

	/** Liste des actions possibles */
	listeActions: Array<{ type: string,libelle: String }> = [
		{type: TypeAction[TypeAction.ANNULER],libelle: this.translateService.instant('global.actions.annuler')},
		{type: TypeAction[TypeAction.EMETTRE],libelle: this.translateService.instant('global.actions.emettre')},
		{type: TypeAction[TypeAction.INVALIDER],libelle: this.translateService.instant('global.actions.invalider')},
		{type: TypeAction[TypeAction.REJETER],libelle: this.translateService.instant('global.actions.rejeter')},
		{type: TypeAction[TypeAction.VALIDER],libelle: this.translateService.instant('global.actions.valider')}
	];

	/** Action sélectionnée */
	action: string;

	/** Libellé de l'action sélectionnée à afficher dans la popup de confirmation */
	libelleAction: string;

	/** Checkbox qui indique si les traitements comptable doivent être effectués */
	checkTraitements: boolean = false;

	/** Libellé indiquant dans la popup de confirmation si les traitements comptables sont sélectionnés */
	traitementsSelected: string;

	/** Statut sélectionné */
	statut: Statut;

	/** Remarque */
	remarque: string = this.translateService.instant('admin.ongletOutils.cadreMouvementWf.popup.rqFallback');

	/** Action workflow à effectuer */
	actionWf: ActionWorkflow;

	/** Composant du template ref du message de confirmation */
	@ViewChild('messageConfirmation') messageConfirmation: TemplateRef<any>;

	/** Message d'alerte */
	alerte: Alerte = new Alerte({
		niveau: NiveauAlerte.WARNING,
		message: this.translateService.instant('admin.ongletOutils.cadreMouvementWf.popup.attentionMessage'),
	})
	/**
	 * Constructeur
	 *
	 * @param router			Router
	 * @param translateService  Service de traduction
	 * @param matDialogRef      Référence de la popup
	 * @param confirmService    Service de confirmation
	 * @param workflowService   Service du workflow
	 * @param toastrService		Service des toasts
	 * @param data				Données passées en entrée de cette boîte de dialogue
	 */
	constructor(private router: Router,
				private translateService: TranslateService,
				private matDialogRef: MatDialogRef<PopupMouvementWfComponent>,
				private confirmService: ConfirmService,
				private workflowService: WorkflowService,
				private toastrService: ToastrService,
				@Inject(MAT_DIALOG_DATA) public data: { idObjet: number, typePortee: TypePortee }) {
	}

	/**
	 * Ouvre la popup de confirmation lorsqu'on clique sur le bouton 'Confirmer'
	 */
	openPopupConfirmation() {
		//Définition du libellé de l'action à partir de l'action sélectionnée dans le formulaire
		switch (this.action) {
			case TypeAction[TypeAction.EMETTRE]:
				this.libelleAction = this.translateService.instant('global.actions.emettre');
				break;
			case TypeAction[TypeAction.VALIDER]:
				this.libelleAction = this.translateService.instant('global.actions.valider');
				break;
			case TypeAction[TypeAction.ANNULER]:
				this.libelleAction = this.translateService.instant('global.actions.annuler');
				break;
			case TypeAction[TypeAction.INVALIDER]:
				this.libelleAction = this.translateService.instant('global.actions.invalider');
				break;
			case TypeAction[TypeAction.REJETER]:
				this.libelleAction = this.translateService.instant('global.actions.rejeter');
				break;
		}

		//Récupération du libellé pour les traitements comptable
		if (this.checkTraitements) {
			this.traitementsSelected = this.translateService.instant('confirmation.oui');
		} else {
			this.traitementsSelected = this.translateService.instant('confirmation.non')
		}

		//Ouverture de la popup de confirmation
		this.confirmService.showConfirm(this.messageConfirmation).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Lorsque l'on a confirmé les traitements workflow dans la popup de confirmation

				//Définition de l'action workflow à effectuer
				this.actionWf = new ActionWorkflow(this.action,this.statut.code);
				if (!!this.remarque) {
					this.actionWf.remarque = this.remarque;
				}
				if (this.action === TypeAction[TypeAction.INVALIDER] || this.action === TypeAction[TypeAction.VALIDER]) {
					this.actionWf.traitementComptable = this.checkTraitements;
				}

				//On effectue le mouvement workflow
				this.workflowService.doMouvementWf(this.data.idObjet,this.data.typePortee,this.actionWf).subscribe((result: Result) => {
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Rechargement de l'objet pour maj le statut
						this.workflowService.needRefresh$.next();
						//Success
						this.toastrService.success(this.translateService.instant('admin.ongletOutils.cadreMouvementWf.success'));
					} else {
						this.toastrService.error(this.translateService.instant('global.errors.generic'));
					}
				});

				//Fermeture de la popup
				this.matDialogRef.close();
			}
		});
	}
}
