/**
 * Compte comptable
 */
export class Compte {
	numero: string;
	libelle: string;
	type: TypeCompte;
}

/**
 * Énumération des types de comptes
 */
export enum TypeCompte {
	NON_DEFINI,
	COMPTE_TIERS,
	COMPTE_TAXE,
	COMPTE_BANQUE,
	COMPTE_CHARGE,
	PERTE_GAIN_CHANGE
}