/**
 * Enumération des natures
 */
export enum Nature {
    AUCUNE = "",
    AVION = 2,
    TRAIN = 1,
    VOITURE = 4,
    HOTEL = 3,
    DIVERS = 5,
    FRAIS = 6
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace Nature {

    /**
     * Retourne toutes les valeurs de l'énuméré
     */
    export function values(): Nature[] {
        return [
            Nature.AUCUNE
            ,Nature.AVION
            ,Nature.TRAIN
            ,Nature.VOITURE
            ,Nature.HOTEL
            ,Nature.DIVERS
            ,Nature.FRAIS
        ]
    }

    /**
     * Enumération des natures d'une prestation (famille de charge à l'écran côté admin)
     */
    enum NatureVoyage {
        AVION   = 'A',
        TRAIN   = 'T',
        VOITURE = 'R',
        HOTEL   = 'H',
        DIVERS  = 'D',
        FRAIS   = 'F',
    }

    /**
     * Retourne la nature associée à une prestation à partir d'une nature
     *
     * @param nature La nature
     */
    export function natureVoyage(nature: Nature): NatureVoyage {
        switch (nature) {
            case Nature.AVION:        return NatureVoyage.AVION;
            case Nature.TRAIN:        return NatureVoyage.TRAIN;
            case Nature.VOITURE:      return NatureVoyage.VOITURE
            case Nature.HOTEL:        return NatureVoyage.HOTEL;
            case Nature.DIVERS:       return NatureVoyage.DIVERS;
            case Nature.FRAIS:        return NatureVoyage.FRAIS;
        }
    }

    /**
     * Retourne le code de traduction correspondant à un type de nature
     *
     * @param nature La nature
     */
    export function traduction(nature: Nature): string {
        switch (nature) {
            case Nature.AVION:        return 'prestation.natures.avion';
            case Nature.TRAIN:        return 'prestation.natures.train';
            case Nature.VOITURE:      return 'prestation.natures.voiture';
            case Nature.HOTEL:        return 'prestation.natures.hotel';
            case Nature.DIVERS:       return 'prestation.natures.divers';
            case Nature.FRAIS:        return 'prestation.natures.frais';
            case Nature.AUCUNE:
            default:                  return 'prestation.natures.aucune';
        }
    }

    /**
     * Retourne l'icône associée à la nature
     *
     * @param nature La nature
     */
    export function icone(nature: Nature): string {
        switch (nature) {
            case Nature.AVION:        return '<i class="icon-avion nio align-middle"></i>';
            case Nature.TRAIN:        return '<i class="icon-train nio align-middle"></i>';
            case Nature.VOITURE:      return '<i class="icon-voiture nio align-middle"></i>';
            case Nature.HOTEL:        return '<i class="material-icons-outlined align-middle">hotel</i>';
            case Nature.DIVERS:       return '<i class="material-icons-outlined align-middle">help_outline</i>';
            case Nature.FRAIS:        return '<i class="icon-frais nio align-middle"></i>';
            default:                  return '<i class="material-icons-outlined align-middle">question_mark</i>';
        }
    }

    /**
     * Comparateur entre 2 natures
     *
     * @param n1 Nature à comparer
     * @param n2 Nature à comparer
     */
    export function compareTo(n1?: Nature,n2?: Nature): boolean {
        const nature1: string = n1 ? Nature[n1] : '';
        const nature2: string = n2 ? Nature[n2] : '';

        return nature1 === nature2;
    }

}