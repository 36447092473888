import {Pipe,PipeTransform} from "@angular/core";
import {CurrencyPipe} from "@angular/common";

/**
 * Formate un montant à 2 chiffres après la virgule, et avec le code de la devise
 *
 * @author Laurent Convert
 * @date 10/01/2022
 */
@Pipe({
    name: 'montant'
})
export class MontantPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {
    }

    /**
     * Formate une valeur monétaire à 2 chiffres après la virgule et ajoute le code de la devise
     *
     * @param value         La valeur monétaire
     * @param codeDevise    Le code de la devise
     * @param variant       (optionnel) Le variant
     * @return La valeur formatée
     */
    transform(value: number | string,codeDevise: string,variant?:string): string | null {
        //Utilisation du CurrencyPipe intégré d'Angular pour obtenir le format avec code de devise
        const formattedAmountWithCode = this.currencyPipe.transform(value ?? 0,codeDevise,'code','.2-2',variant);

        //Si le montant formaté vaut null
        if (!formattedAmountWithCode) {
            //Retour par défaut
            return null;
        }

        //Extrait uniquement le montant numérique en enlevant le code de devise
        const amount = formattedAmountWithCode.replace(codeDevise,'').trim();

        //Reconstitue le format avec le symbole de la devise à droite
        return `${amount} ${codeDevise}`;
    }

}
