import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DossierTravel} from "@domain/od/proposition";
import {ODService} from "../../../../od.service";
import {TypeAiguillage} from "@domain/voyage/travel/constants";
import {MapAction} from "@domain/workflow/mapAction";

/**
 * Composant d'affichage d'une ligne de dossier dans la proposition
 *
 * @author Laurent SCIMIA
 * @date 11/05/2022
 */
@Component({
    host: {'data-test-id': 'dossier-item'},
    selector: 'dossier-item',
    templateUrl: './od-proposition-dossier-item.component.html',
    styleUrls: ['./od-proposition-dossier-item.component.scss']
})
export class OdPropositionDossierItemComponent {
    /** Référence de l'enum pour le HTML */
    TypeAiguillage = TypeAiguillage;

    /** Dossier Travel à afficher */
    @Input() dossier: DossierTravel;

    /** Id de l'OD en cours */
    @Input() idOd: number;

    /** Type de l'aiguillage du dossier */
    @Input() aiguillage: TypeAiguillage;

    /** Actions possibles pour l'OD */
    @Input() odMapActions: MapAction;

    /** Évènement émis pour rafraichir la liste des propositions/étapes */
    @Output() needRefresh = new EventEmitter<void>();

    /** Booléen pour bloquer le refresh de la page ou non */
    private isBlockReload: boolean = false;

    /** Indique si le cadre de détail est ouvert */
    isOuvert: boolean = true;

    constructor(private odService: ODService) { }

    /**
     * Méthode de redirection vers le SBT
     *
     * @param idDossier Identifiant du dossier à ouvrir dans le SBT
     */
    goToSBT(idDossier: number) {
        //On bloque le rechargement de la page
        this.isBlockReload = true;

        //Connexion au portail
        this.odService.goToPortail(idDossier, this.idOd).subscribe(() => {
            //On débloque le chargement de la page
            this.isBlockReload = false;

            //On indique qu'il faut refresh les infos
            this.needRefresh.emit();
        });
    }

    /**
     * Méthode qui bloque le rechargement de la page si isBlockReload est à true
     *
     * @param $event Évènement de rechargement de la page
     */
    @HostListener('window:beforeunload', ['$event'])
    blockReload($event) {
        if (this.isBlockReload) $event.returnValue = '';
    }

    /**
     * Indique si on peut se connecter au SBT sur cette étape.
     *
     * @param isPremiereEtape Indique si c'est la première étape du dossier
     * @returns {boolean} True si on peut se reconnecter au SBT
     */
    canGoToSbt(isPremiereEtape: boolean): boolean {
        return isPremiereEtape && this.aiguillage === TypeAiguillage.ONLINE && (this.odMapActions.canModifier.possible || this.odMapActions.canCompleter.possible);
    }
}
