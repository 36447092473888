import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ListeOd} from "@domain/od/liste-od";
import {ListView} from "@domain/common/list-view";
import {OdListItemChevauchementDatesComponent} from "@components/od/detail/generalite/od-list-chevauchement-dates/od-list-item-chevauchement-dates/od-list-item-chevauchement-dates.component";
import {TranslateService} from "@ngx-translate/core";
import {OdChevauchement} from "@domain/od/od-chevauchement";

/**
 * Popin de la liste des od pour les chevauchements de dates
 *
 * @author Angeline Ha
 * @date 16/05/2024
 */
@Component({
    host: {'data-test-id': 'od-list-chevauchement-dates'},
    templateUrl: './od-list-chevauchement-dates.component.html',
    styles: [
        '::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
        '::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
    ]
})
export class OdListChevauchementDatesComponent implements OnInit {
    /** Liste des OD */
    listeOd: ListView<OdChevauchement,OdListItemChevauchementDatesComponent>;

    /**
     * Constructeur
     *
     * @param data Données passées en entrée de la popin
     * @param matDialogRef Référence à la popin
     * @param translateService Service des traductions
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { dataListe: Array<ListeOd> },
                private matDialogRef: MatDialogRef<OdListChevauchementDatesComponent>,
                private translateService: TranslateService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des od qui sont sur la même période de dates
        this.listeOd = new ListView<OdChevauchement,OdListItemChevauchementDatesComponent>({
            title: this.translateService.instant('od.generalites.popinOdChevauchementTitle'),
            component: OdListItemChevauchementDatesComponent,
            isFrontendList: true,
            listeActions: [{
                icon: 'close',
                onPress: () => this.matDialogRef.close()
            }],
            data: {
                listeResultats: this.data.dataListe,
                numPage: 0,
                nbPagesTotal: 1,
                nbObjetsTotal: this.data.dataListe.length,
                nbObjetsDansPage: this.data.dataListe.length,
                nbObjetsParPage: this.data.dataListe.length
            }
        });
    }

}
