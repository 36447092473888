<mat-card *ngIf="listeImputation?.length > 0">
    <mat-card-title>
        <span [translate]="'ndf.imputationAvance.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item" *ngFor="let imputAv of listeImputation">
                    <div class="lvi-content">
                        <div class="avatar">
                            <i class="nio icon-avance_2 align-middle"></i>
                        </div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="['Avance',imputAv.idAvance]">
                                    {{ imputAv.idAvance }} - {{ imputAv.libelleObjet }}
                                </a>
                            </div>
                            <ul class="lvi-attrs">
                                <li *ngIf="imputAv.idOd > 0"><strong [translate]="'ndf.imputationAvance.om'"></strong> {{ imputAv.idOd }}</li>
                                <li><strong [translate]="'ndf.imputationAvance.type'"></strong> {{ TypeImputation.traduction(imputAv.typeImputation) | translate }}</li>
                                <li><strong [translate]="'ndf.imputationAvance.montant'"></strong>
                                    {{ imputAv.montant | montant:imputAv.devise?.code }}
                                    {{ imputAv.devise?.code != deviseEntreprise ? ' (' + (imputAv.montantNote | montant:deviseEntreprise) + ')' : '' }}</li>
                                <li><strong [translate]="'ndf.imputationAvance.cours'"></strong> {{ imputAv.cours | number:'1.4-4' }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>