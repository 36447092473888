<div class="lvi-content" [ngClass]=" {'clickable': action.motif} " (click)="isOuvert = !isOuvert">
    <div class="avatar" [matTooltip]="action.libelleAction" matTooltipPosition="left">
        <div *ngIf="action.libelleRole !== 'API'; else apiIcon">
            <!-- La validation et la création ont le même idAction 1 :( Si on est sur la 1ère action et que idAction est 1, on considère que c'est une création -->
            <i *ngIf="action.idAction === TypeAction.VALIDER && index == 0; else noCreation" class="nio icon-ajouter icone"></i>
            <ng-template [ngSwitch]="action.idAction" #noCreation>
                <i *ngSwitchCase="TypeAction.VALIDER" class="nio icon-validation icone"></i>
                <i *ngSwitchCase="TypeAction.REJETER" class="nio icon-rejet icone"></i>
                <i *ngSwitchCase="TypeAction.INVALIDER" class="nio icon-invalidation icone"></i>
                <i *ngSwitchCase="TypeAction.ANNULER" class="nio icon-annulation icone"></i>
                <i *ngSwitchCase="TypeAction.MODIFIER" class="nio icon-modification icone"></i>
                <i *ngSwitchCase="TypeAction.SUPPRIMER" class="nio icon-suppression icone"></i>
                <i *ngSwitchCase="TypeAction.EMETTRE" class="nio icon-emission icone"></i>
                <i *ngSwitchCase="TypeAction.ARCHIVER" class="nio icon-archive icone"></i>
                <i *ngSwitchCase="TypeAction.COMPTABILISER" class="nio icon-comptabilisation icone"></i>
                <i *ngSwitchCase="TypeAction.COMPLETER" class="nio icon-completion icone"></i>
                <i *ngSwitchCase="TypeAction.RESTITUER" class="nio icon-restitution icone"></i>
                <i *ngSwitchCase="TypeAction.CLOTURER" class="nio icon-cloture icone"></i>
                <i *ngSwitchCase="TypeAction.ATTRIBUER" class="nio icon-octroi icone"></i>
            </ng-template>
        </div>
        <ng-template #apiIcon>
            <i class="material-icons icone">api</i>
        </ng-template>
    </div>
    <div class="lvi-body">
        <div class="chainage-title">
            <div *ngIf="action.libelleRole !== 'API'; else apiTitre">
                <span>{{ action | user}}</span>
            </div>
            <ng-template #apiTitre>
                <span>{{ action.prenom }}</span>
            </ng-template>
        </div>
        <ul class="lvi-attrs row">
            <li *ngIf="action.dateAction"><strong [translate]="'chainage.actions.date'"></strong><span>{{ action.dateAction | date:'short' }}</span></li>
            <li *ngIf="action.nomResponsable"><strong [translate]="'chainage.actions.approbateur'"></strong><span>{{{nom: action.nomResponsable, prenom: action.prenomResponsable} | user}}</span></li>
            <li *ngIf="action.libelleRole !== 'API'"><strong [translate]="'chainage.actions.role'"></strong><span *ngIf="action.libelleRole">{{ action.libelleRole }}</span>
                <span *ngIf="!action.libelleRole" [translate]="'liste.nonRenseigne'"></span></li>
            <li><strong translate="chainage.actions.statutSuivant"></strong><span *ngIf="action.libelleStatut">{{ action.libelleStatut }}</span>
                <span *ngIf="!action.libelleStatut" [translate]="'liste.nonRenseigne'"></span></li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="action.motif">
        <button mat-icon-button>
            <mat-icon *ngIf="isOuvert" color="primary" [matTooltip]="'chainage.actions.replier' | translate">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOuvert" color="primary" [matTooltip]="'chainage.actions.deplier' | translate">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>

<div class="p-20 motif-box">
    <div class="motif-content" [ngClass]="isOuvert ? 'open' : 'closed'">
        <form autocomplete="off">
            <ng-container>
                <row-data [dataGauche]="{traduction: 'chainage.actions.motif', valeur:action.motif}"></row-data>
            </ng-container>
        </form>
    </div>
</div>
