import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ListeTrajet,TrajetType} from "@domain/Trajet/ListeTrajet";
import {TrajetService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet.service";
import {AdresseService} from "@share/component/adresse/adresse.service";
import {MatDialog} from "@angular/material/dialog";
import {TrajetRenommerComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet-renommer.component";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant affichant un item de l'onglet trajet
 */
@Component({
    host: {'data-test-id': 'trajet-list-item'},
    templateUrl: './trajet-list-item.component.html'
})
export class TrajetListItemComponent extends ListViewItem<ListeTrajet> {
    /** Déclaration pour utilisation dans le template */
    TrajetType = TrajetType;

    /** Indique si l'action aller retour a été utilisé */
    isActionAllerRetourActif: boolean = false;

    /** Constructeur */
    constructor(
            private trajetService: TrajetService,
            private adressService: AdresseService,
            private matDialog: MatDialog,
            private toastrService: ToastrService,
            private translateService: TranslateService) {
        super()
    }

    /**
     * Retourne true si on a une distance admin
     */
    isAdminDistance(): boolean {
        return this.data.idUser == null || this.data.idUser === 0;
    }

    /**
     * Handler qui permet de gérer l'action de sélection
     */
    onSelect(): void {
        //Retourne la valeur selectionnée au parent
        this.extraOptions.selectEvent(this.data);

    }

    /**
     * Handler ajoutant l'aller retour
     */
    onActionAllerRetour(): void {
        this.isActionAllerRetourActif = true;

        //Si ce n'est pas déjà un aller/retour
        if (!this.data.allerRetour) {
            this.data.allerRetour = true;
            //On rajoute la distance à la distance courante
            this.data.distance += this.data.distance;
        }
    }

    /**
     * Handler pour l'aller simple
     */
    onActionAllerSimple(): void {
        this.isActionAllerRetourActif = false;

        //Si c'était un aller-retour
        if (this.data.allerRetour) {
            this.data.allerRetour = false;
            //On divise la distance par 2
            this.data.distance = this.data.distance / 2;
        }
    }

    /**
     * Handler qui inverse le départ et l'arrivé
     */
    onActionInverser(): void {
        let tmpAdresseDepart = this.data.adresseDepart
        this.data.adresseDepart = this.data.adresseArrivee;
        this.data.adresseArrivee = tmpAdresseDepart;
    }

    /**
     * Handler qui permet de renommer le trajet
     */
    onActionRenommer(): void {
        let libelle = null;

        //Si on a un libellé perso
        if (this.data.libelle != null && this.data.libelle != "") {
            //On assigne le libellé perso
            libelle = this.data.libelle;
        } else {
            //On génère un libellé à partir de l'adresse
            libelle = this.getdefautLibelleFromAdresse();
        }

        //Ouverture de la popin
        this.matDialog.open(TrajetRenommerComponent, {
            minWidth: '700px',
            data: {
                libelle: libelle
            },
        }).afterClosed().subscribe((libelle: string) => {
            //Si l'utilisateur n'a pas annulé
            if (libelle != null) {
                //Si l'utilisateur a effacé le champ
                if (libelle == "") {
                    //On affecte null pour avoir le nom par défaut en base
                    libelle = null;
                }

                const trajetLibelle = {
                    id: this.data.id,
                    type: this.data.type,
                    libelle: libelle
                }
                //On envoie la demande de changement
                this.trajetService.updateLibelle(trajetLibelle)
                    .subscribe({
                        next: (result: Result) => {
                            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                                //Si on n'a pas de nom, on initialise le nom par défaut pour que le libellé affiché de la liste soit à jour
                                //Sans rafraichir la liste
                                if (libelle == "") {
                                    //On affecte le nom par défaut
                                    libelle = this.getdefautLibelleFromAdresse();
                                }

                                //On change sur l'objet courant
                                this.data.libelle = libelle;
                            } else {
                                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                            }
                        }
                    });
            }
        });
    }

    /**
     * Handler qui permet de gérer l'action supprimer
     */
    onActionSupprimer(): void {
        this.trajetService.delete(this.data.id, this.data.type)
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //On recharge la liste
                        this.extraOptions.refreshEvent();
                    } else {
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                }
            });
    }

    /**
     * Retourne un libellé par défaut à partir de l'adresse
     * @return un libellé par défaut à partir de l'adresse
     */
    getdefautLibelleFromAdresse(): string {
        return this.adressService.getVilleFromAdress(this.data.adresseDepart)
            + ' / ' + this.adressService.getVilleFromAdress(this.data.adresseArrivee);
    }
}