import {Component,OnDestroy,OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

import {AppState} from '@domain/appstate';
import {TypeProfil,User} from '@domain/user/user';
import {LoginService} from '../login/login.service';
import {Lien} from "@domain/boitelien/boitelien";
import {BoiteLienDialogComponent} from "./boite-lien-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "@environments/environment";
import {filter,first} from "rxjs/operators";
import {Subscription} from "rxjs";
import {DelegationsService} from "@components/delegations/delegations.service";
import {Session} from "@domain/security/session";

/**
 * Composant du menu déroulant du compte de l'utilisateur connecté
 */
@Component({
    selector: 'account-menu',
    templateUrl: './account-menu.component.html'
})
export class AccountMenuComponent implements OnInit,OnDestroy {

    /** Souscription au changement d'utilisateur */
    userSouscription: Subscription;

    /** Utilisateur connecté **/
    public user?: User;

    /** URL de base **/
    public baseUrl: string = environment.baseUrl;

    /** URL du logo **/
    public logoUrl?: string;

    /** Indique si un admin ou sous-admin s'est "connecté en tant que" un autre utilisateur : dans ce cas, on n'affiche pas le menu profil */
    public isConnectAs: boolean;

    /** Indique si un admin ou sous-admin est connecté dans ce cas, on n'affiche pas le menu profil */
    public isAdmin: boolean;

    /** Logo secondaire */
    public logoSecondaire?: string = undefined;

    /** Logo fallback */
    public logoFallback = './assets/logo/logo.png';

    /** Liste des liens à afficher */
    public listeLien?: Array<Lien>;

    /** Indique si le menu est déplié ou non */
    isMenuOpened: boolean = false;

    /** Indique si le menu délégation doit être accessible */
    isDelegationViewable: boolean = false;

    /**
     * Constructeur par défaut
     */
    constructor(private store: Store<AppState>,
                private loginService: LoginService,
                private router: Router,
                private matDialog: MatDialog,
                private delegationService: DelegationsService) { }

    /**
     * Initialisation
     */
    ngOnInit() {
        this.userSouscription = this.store.select(state => state.session.user).pipe(filter(value => !!value)).subscribe(user => {
            //Stockage de l'utilisateur
            this.user = user;

            if (user.fonction === TypeProfil.RESPONSABLE || user.fonction === TypeProfil.ASSISTANT) {
                this.delegationService.isDelegationViewable().pipe(first()).subscribe(isViewable => {
                    this.isDelegationViewable = isViewable;
                });
            } else {
                this.isDelegationViewable = false;
            }
        });

        //Sélection de l'indicateur "connecté en tant que"
        this.store.select<boolean>(s => s.session?.isConnectAs).subscribe(val => {
            //Stockage de l'utilisateur
            this.isConnectAs = val;
        });

        //Sélection de l'indicateur admin
        this.store.select<Session>(s => s.session).subscribe(val => {
            //Stockage des données
            this.isAdmin = val.isAdmin;
        });

        //Sélection du logo
        this.store.select<string>(s => s.session?.logoUrl).subscribe(logoUrl => {
            //Stockage du logo
            if (logoUrl) {
                /**
                    Petit hack ici pour que les images fonctionnent en dév avec le proxy ET en prod.
                    Le pb étant que statutImage est stocké en bdd et qu'il contient un chemin relatif vers l'image, qui ne fonctionne pas pour Angular car Angular est déployé à la racine de la webapp,
                    contrairement à AngularJS et XSL.
                */
                this.logoUrl = this.baseUrl + "/img/" + logoUrl;
            }
        });

        //Sélection du logo secondaire
        this.store.select(state => state.settings?.['Global']).pipe(filter(val => !!val)).subscribe(settings => {
            this.logoSecondaire = settings?.logoSecondaire ?? null;
        });

        //Chargement des liens de la boîte à liens
        this.loginService.getListeBoiteLien().subscribe(res=>{
            this.listeLien = res.data.listeLien;
        })
    }

    /**
     * Déconnexion
     */
    logout() {
        //Déconnexion
        this.loginService.logout();
    }

    /**
     * Fonction qui conditionne l'affichage du bouton redirigeant vers l'écran des délégations
     */
    isDelegationsVisible(): boolean {
        return [TypeProfil.RESPONSABLE,TypeProfil.ASSISTANT].includes(this.user?.fonction?.valueOf()) && this.isDelegationViewable;
    }

    /**
     * Accès à l'écran des délégations
     */
    goToDelegations(): void {
        this.router.navigate(['Delegations']);
    }

    /**
     * Ouvre la popup de la boîte à lien
     */
    openBoiteALien(): void {
        this.matDialog.open(BoiteLienDialogComponent,{
            data: {
                listeBoiteALien: this.listeLien
            }
        });
    }

    /**
     * Redirige vers le profil
     */
    goToProfil(): void {
        this.router.navigate(['Profil']);
    }

    /** Retourne le logo par ordre de priorité */
    getLogo(): string {
        //Afin d'éviter le clignotement le temps du chargement, on se limite au logo du service tant qu'on n'a pas encore déterminé si le logo secondaire (client / entreprise) est actif
        if (this.logoSecondaire === undefined) {
            //Retour du logo du service (sera undefined si absent)
            return this.logoUrl;
        } else {
            //Retour du logo "final" par ordre de priorité
            return this.logoUrl ?? this.logoSecondaire ?? this.logoFallback;
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //On se désabonne
        this.userSouscription?.unsubscribe();
    }
}
