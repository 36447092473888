<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }">
        <i class="nio icon-releve_de_facture" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo(data.idFactureReleve)">{{ data.numero || ('facture.releve.liste.numeroNonDefini' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'facture.releve.liste.fournisseur'"></strong>{{ data.fournisseur?.raisonSociale }}</li>
            <li><strong [translate]="'facture.releve.liste.date'"></strong>{{ data.dateReleve | date:'shortDate' }}</li>
            <li><strong [translate]="'facture.releve.liste.montant'"></strong>{{ data.montant | montant:data.fournisseur?.devise }}</li>
            <li><strong [translate]="'facture.releve.liste.statut'"></strong>{{ data.getStatut() | translate }}</li>
            <li><strong [translate]="'facture.releve.liste.nbPieces'"></strong>{{ data.quantite }}</li>
            <li *ngIf="data.listeAlertes as listeAlertes" (click)="showListeAlertes(listeAlertes)" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == NiveauAlerte.ERROR, 'back-warning' : data.listeAlertes.niveau == NiveauAlerte.WARNING}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
</div>
