<div class="with-floating-button">
    <div class="content">
        <page-header
                [title]="'admin.bibliotheque.zonesUtilisateurs.title' | translate"
                [listeItems]="listeTabItems"
                (onSelectedItemChange)="onSelectedItemChange($event)"
                [selectedIndex]="selectedIndex">
        </page-header>

        <div *ngIf="isZonesPredefiniesLoaded" [hidden]="selectedItem.code !== Onglets.ZONES_PREDEFINIES">
            <zones-predefinies></zones-predefinies>
        </div>

        <div *ngIf="isZonesParametrablesLoaded" [hidden]="selectedItem.code !== Onglets.ZONES_PARAMETRABLES">
            <zones-parametrables></zones-parametrables>
        </div>
    </div>
</div>