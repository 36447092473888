import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {LienPopulationUser,TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";
import {PopulationsService} from "@services/admin/entreprise/population.service";
import {ListItem} from "@domain/common/list-view/list-item";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter} from "rxjs/operators";

/**
 * Composant d'affichage d'un collaborateur dans la liste des collaborateurs d'une population
 */
@Component({
	host: {'data-test-id': 'population-collaborateur-item'},
	templateUrl: './population-collaborateur-item.component.html'
})
export class PopulationCollaborateurItemComponent extends ListViewItem<LienPopulationUser> {

	/** Déclaration pour le template */
	TypeLien = TypeLien;

	/** Constructeur */
	constructor(
		private populationsService: PopulationsService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private confirmService: ConfirmService
	) {
		super();
	}

	/**
	 * Suppression de l'user
	 */
	deleteUser(): void {
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					this.populationsService.deleteUser(this.data).subscribe(result => {
						//Si on a pas d'erreur
						if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
							(this.data as ListItem).removeFromListView();
							this.toastrService.success(this.translateService.instant('global.success.suppression'));
						} else {
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
				}
			});
	}
}