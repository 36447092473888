import {Component,ComponentFactoryResolver,ComponentRef,Input,OnInit,ReflectiveInjector,Type,ViewChild,ViewContainerRef} from '@angular/core';

import {AutocompleteOptions} from './autocomplete';
import {AutocompleteLineItemComponent} from './autocomplete-line-item.component';

@Component({
    selector: 'autocomplete-line',
    templateUrl: './autocomplete-line.component.html'
})
export class AutocompleteLineComponent implements OnInit {
    /** Elèment à afficher **/
    @Input() item: any;

    /** Options de l'autocomplete **/
    @Input() options: AutocompleteOptions;

    /** Récepteur de composant **/
    @ViewChild('holder',{ read: ViewContainerRef,static: true }) private holder: ViewContainerRef;
    
    /**
     * Constructeur
     */
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {

    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        let component;

        //Création du composant
        component = this.createComponent(this.holder,this.options.lineItemComponent ? this.options.lineItemComponent() : AutocompleteLineItemComponent);

        //Définition des données
        component.instance.item = this.item;
        component.instance.options = this.options;

        //Ajout du composant au DOM
        this.holder.insert(component.hostView);
    }

    /**
     * Création du composant
     */
    createComponent(viewContainer: ViewContainerRef,type: Type<AutocompleteLineItemComponent<any>>): ComponentRef<AutocompleteLineItemComponent<any>> {
        let factory;
        let injector;

        //Récupération de la fabrique
        factory = this.componentFactoryResolver.resolveComponentFactory(type);
    
        //Récupération de l'injecteur
        injector = ReflectiveInjector.fromResolvedProviders([],viewContainer.parentInjector);
    
        //Création du composant
        return factory.create(injector);
    }
}