<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }" [nSelector]="{ liste: liste,item: data,isNotSelectable: !extraOptions.isLotCreation }"><i class="icon-note_de_frais nio"></i></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo(LotItemType.NDF, data.idNDF)" class="font-weight-bold">
                {{ data.numeroPiece || ('ndf.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('ndf.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.od" (click)="openOd(data.od.idOd)" style="cursor:pointer;">
                <strong [translate]="'ndf.liste.om'"></strong>
                {{ data.od.idOd }}
                <mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
            </li>
            <li><strong [translate]="'lot.detail.listes.user'"></strong>{{ data.user | user}}</li>
            <li><strong [translate]="'ndf.liste.montantRemboursable'"></strong>{{ data.montantRemboursable | montant:data.devise?.code }}</li>
            <li><strong [translate]="'ndf.liste.contrepartie'"></strong>{{ data.montantRemboursable / data.cours | montant:this.settings?.deviseEntreprise  }}</li>
            <li><strong [translate]="'ndf.liste.montantARembourser'"></strong>{{ data.montantARembourser / data.cours | montant:this.settings?.deviseEntreprise  }}</li>
            <li><strong [translate]="'ndf.liste.type'"></strong>{{ data.typeEntite.libelle || data.typeEntite }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
            <li *ngIf="statutArchivageIcon" [matTooltip]="getDematTooltip()">
                <strong [translate]="'ndf.liste.demat'"></strong>
                <mat-icon class="material-icons-outlined" [ngClass]="statutArchivageClass">{{ statutArchivageIcon }}</mat-icon>
            </li>
        </ul>
    </div>
    <div class="info">
        <statut *ngIf="data.statut?.image" [statut]="data.statut"></statut>
    </div>
</div>
