import { Component,OnInit,ViewChild,TemplateRef,ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'list-view-footer',
    templateUrl: './list-view-footer.component.html'
})
export class ListViewFooterComponent implements OnInit {
    /** Template du footer **/
    @ViewChild('footerTemplate')
    public template: TemplateRef<any>;

    /**
     * Constructeur 
     */
    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    /**
     * Initialisation
     */
    ngOnInit() {
        //Détection des changements
        this.changeDetectorRef.detectChanges();
    }
}