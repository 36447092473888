import {Component,Input,OnInit} from '@angular/core';
import {ParamConnexion} from "@domain/travel/param-connexion";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {ParamConnexionValeur} from "@domain/travel/param-connexion-valeur";
import {ListView} from "@domain/common/list-view";
import {Page} from "@domain/common/http/list-result";
import {ParamConnexionListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-list-item/param-connexion-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ParamConnexionAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/param-connexion-add.component";
import {TypeCodeErreur} from "@domain/common/http/result";
import {Observable} from "rxjs";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ToastrService} from "ngx-toastr";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {ListeAlertes} from '@domain/common/alerte/listeAlertes';

/**
 * Cadre "Paramètres de connexion"
 *
 * @author Laurent Convert
 * @date 05/12/2023
 */
@Component({
	host: {'data-test-id': 'param-connexion'},
	selector: 'param-connexion',
	templateUrl: './param-connexion.component.html'
})
export class ParamConnexionComponent implements OnInit {
	/** Configuration SBT */
	@Input() sbtConfigUsed: TravelHubSBTConfigUsed;

	/** Liste de paramètres de connexions */
	@Input() listeParamConnexion: Array<ParamConnexion> = [];

	/** Listes de paramètres utilisés */
	@Input() listeParamConnexionUsed: Array<ParamConnexionUsed> = [];

	/** Liste de paramètres */
	@Input() listeValeurParamConnexion: Array<ParamConnexionValeur> = [];

	/** ListView des paramètres de connexion configurés */
	listeParametres: ListView<ParamConnexionUsed,ParamConnexionListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 * @param translateService Service de traduction
	 * @param toastrService Service de notification
	 * @param matDialog Boîte de dialogue
	 */
	constructor(private adminTravelhubService: AdminTravelhubService,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private matDialog: MatDialog) {
	}

	/**
	 * Initilisation du composant
	 */
	ngOnInit() {
		//Initialisation de la liste des paramètres
		this.listeParametres = new ListView<ParamConnexionUsed,ParamConnexionListItemComponent>({
			title: this.translateService.instant('admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.title'),
			component: ParamConnexionListItemComponent,
			isFrontendList: true,
			showPagination: false,
			listeActions: [{
				icon: "add",
				onPress: () => this.addParamConnexion()
			}],
			extraOptions: {
				parent: this
			},
			data: {
				listeResultats: [],
				numPage: 0,
				nbPagesTotal: 1,
				nbObjetsTotal: 0,
				nbObjetsDansPage: 0,
				nbObjetsParPage: 0
			} as Page<ParamConnexionUsed>
		});
	}

	/**
	 * Rafraichissement de la liste
	 */
	refreshListe(): void {
		//Vidage de la liste
		this.listeParametres.clear();

		if (this.listeParamConnexionUsed) {
			//Ajout des paramètres déjà configurés
			this.listeParamConnexionUsed.forEach(p => {
				this.listeParametres.addItem(p,true);
			});

			//Ajout des paramètres automatiques ou obligatoires qui ne sont pas déjà présents dans la liste
			this.listeParamConnexion
				.filter(p => (p.automatique || p.obligatoire) && !this.listeParamConnexionUsed.some(pu => pu.paramConnexion.code === p.code))
				.forEach(p => {
					//Création d'une instance pour le paramètre à ajouter à la liste
					let param: ParamConnexionUsed = new ParamConnexionUsed();
					param.paramConnexion = p;

					//Paramètres automatiques
					if (p.automatique) {
						//Ajout du paramètre à la fin de la liste
						this.listeParametres.addItem(param,true);
					}
					//Paramètre obligatoire (non automatique) manquants
					else if (p.obligatoire) {
						//Définition de la liste des alertes
						param.listeAlertes = new ListeAlertes();

						//Ajout d'une alerte indiquant que le paramètre obligatoire n'a pas été renseigné
						param.listeAlertes.add(new Alerte({
							niveau: NiveauAlerte.ERROR,
							titre: 'workflow.alerte.title',
							message: 'admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.paramObligatoireManquant'
						}))

						//Ajout du paramètre en début de liste
						this.listeParametres.addItem(param);
					}
				});
		}

		//Mise à jour du niveau d'alerte de la liste
		this.listeParametres.alertLevel = this.sbtConfigUsed.errorParamConnexion ? NiveauAlerte.ERROR : null;
	}

	/**
	 * Ajout d'un nouveau paramètre de connexion
	 */
	addParamConnexion(): void {
		this.openParamConnexion(null);
	}

	/**
	 * Ouverture du détail d'un paramètre de connexion
	 *
	 * @param paramConnexion Le paramètre de connexion à afficher
	 */
	openParamConnexion(paramConnexion: ParamConnexionUsed): void {
		//Obtention d'une copie en créant une nouvelle instance du paramètre
		let param = new ParamConnexionUsed(paramConnexion);
		param.sbtConfigUsed = this.sbtConfigUsed;

		//Ouverture de la popup
		this.matDialog.open(ParamConnexionAddComponent,{
			width: '40%',
			data: {
				paramConnexion: param,
				listeParamConnexion: this.listeParamConnexion,
				listeValeurParamConnexion: this.listeValeurParamConnexion,
				parent: this
			}
		});
	}

	/**
	 * Enregistrement d'un paramètre de connexion
	 *
	 * @param paramConnexion Le paramètre de connexion à enregistrer
	 * @returns Un observable contenant True si l'enregistrement s'est correctement déroulé, undefined sinon
	 */
	saveParamConnexion(paramConnexion: ParamConnexionUsed): Observable<boolean|undefined> {
		return this.adminTravelhubService.saveParamConnexion(paramConnexion).map(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				//Récupération des paramètres
				this.listeParamConnexionUsed = result.data.listeParamConnexionUsed;

				//Construction de la liste des alertes
				this.sbtConfigUsed.buildListeAlertes(result);

				//Rafraichissement de la liste
				this.refreshListe();

				return true;
			} else if (result?.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
				//Message spécial erreur doublon
				this.toastrService.error(this.translateService.instant(`admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.erreurDoublon`));

				return false;
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);

				return false;
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);

			return false;
		});
	}

	/**
	 * Suppression d'un paramètre de connexion
	 *
	 * @param paramConnexion Le paramètre de connexion à enregistrer
	 */
	deleteParamConnexion(paramConnexion: ParamConnexionUsed): Observable<boolean> {
		return this.adminTravelhubService.deleteParamConnexion(paramConnexion).map(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.suppression'));

				//Récupération des paramètres
				this.listeParamConnexionUsed = result.data.listeParamConnexionUsed;
				this.listeParamConnexion = result.data.listeParamConnexion;

				//Construction de la liste des alertes
				this.sbtConfigUsed.buildListeAlertes(result);

				//Rafraichissement de la liste
				this.refreshListe();

				return true;
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);

				return false;
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);

			return false;
		});
	}
}