<div class="content" *ngIf="parametres">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.vehicules.parametres.configuration.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <form autocomplete="off" name="form_vehicules_configuration">
                <fieldset>
                    <div class="row">
                        <custom-input [(ngModel)]="parametres.certifImmatriculationEtat"
                                      (onChange)="onCertificatImmatriculationChange()"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.configuration.certifImmatriculation"
                                      name="immatriculation"
                                      id="immatriculation"
                                      [selectOptions]="listeOptionsConfig"
                                      optionValue="id"
                                      optionDisplay="libelle"
                                      ngDefaultControl>
                        </custom-input>
                        <custom-input [(ngModel)]="parametres.attestationAssuranceEtat"
                                      (onChange)="onAssuranceChange()"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.configuration.assurance"
                                      name="assurance"
                                      id="assurance"
                                      [selectOptions]="listeOptionsConfig"
                                      optionValue="id"
                                      optionDisplay="libelle"
                                      ngDefaultControl>
                        </custom-input>
                    </div>
                </fieldset>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.vehicules.parametres.approbation.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <form autocomplete="off" name="form_vehicules_approbation">
                <fieldset>
                    <div class="row">
                        <custom-input [ngModel]="parametres.approbation"
                                      [(customModel)]="parametres.approbation"
                                      (onChange)="onApprobationChange()"
                                      customType="checkbox"
                                      libelle="admin.entreprise.vehicules.parametres.approbation.title"
                                      name="approbation"
                                      ngDefaultControl
                                      [postLibelle]="'admin.entreprise.vehicules.parametres.approbation.approbationMessage'">
                        </custom-input>
                    </div>
                    <div class="row">
                        <custom-input [(ngModel)]="parametres.idNotifValid"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.approbation.notifValidation"
                                      name="notifValidation"
                                      id="notifValidation"
                                      [selectOptions]="listeEmailsNotif"
                                      optionValue="idMessage"
                                      optionDisplay="titre"
                                      [disabled]="!parametres.approbation"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.approbation.notifValidationTooltip' | translate"
                                      ngDefaultControl>
                        </custom-input>
                        <custom-input [(ngModel)]="parametres.idNotifEmission"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.approbation.notifEmission"
                                      name="notifEmission"
                                      id="notifEmission"
                                      [selectOptions]="listeEmailsNotif"
                                      optionValue="idMessage"
                                      optionDisplay="titre"
                                      [disabled]="!parametres.approbation"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.approbation.notifEmissionTooltip' | translate"
                                      ngDefaultControl>
                        </custom-input>
                    </div>
                    <div class="row">
                        <custom-input [(ngModel)]="parametres.idNotifRefus"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.approbation.notifRejet"
                                      name="notifRejet"
                                      id="notifRejet"
                                      [selectOptions]="listeEmailsNotif"
                                      optionValue="idMessage"
                                      optionDisplay="titre"
                                      [disabled]="!parametres.approbation"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.approbation.notifRejetTooltip' | translate"
                                      ngDefaultControl>
                        </custom-input>
                    </div>
                    <div class="row">
                        <custom-input [(ngModel)]="parametres.odApprobation"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.approbation.od"
                                      name="odApprobation"
                                      id="odApprobation"
                                      [selectOptions]="listeOptionsNiveauAutorisation"
                                      optionValue="id"
                                      optionDisplay="libelle"
                                      [disabled]="!parametres.approbation"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.tooltipAlerteOdNdf' | translate"
                                      ngDefaultControl>
                        </custom-input>
                        <custom-input [(ngModel)]="parametres.ndfApprobation"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.approbation.ndf"
                                      name="ndfApprobation"
                                      id="ndfApprobation"
                                      [selectOptions]="listeOptionsNiveauAutorisation"
                                      optionValue="id"
                                      optionDisplay="libelle"
                                      [disabled]="!parametres.approbation"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.tooltipAlerteOdNdf' | translate"
                                      ngDefaultControl>
                        </custom-input>
                    </div>
                </fieldset>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.vehicules.parametres.autorisationDeCirculer.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <form autocomplete="off" name="form_vehicules_autorisation">
                <fieldset>
                    <div class="row">
                        <custom-input [ngModel]="parametres.autorisationCirculer"
                                      [(customModel)]="parametres.autorisationCirculer"
                                      (onChange)="onAutorisationChange()"
                                      customType="checkbox"
                                      libelle="admin.entreprise.vehicules.parametres.autorisationDeCirculer.title"
                                      name="autorisationCirculer"
                                      [disabled]="!parametres.approbation"
                                      ngDefaultControl
                                      [postLibelle]="'admin.entreprise.vehicules.parametres.autorisationDeCirculer.autorisationMessage'">
                        </custom-input>
                    </div>
                    <div class="row">
                        <custom-input [(ngModel)]="parametres.odAutorisation"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.autorisationDeCirculer.od"
                                      name="odAutorisation"
                                      id="odAutorisation"
                                      [selectOptions]="listeOptionsNiveauAutorisation"
                                      optionValue="id"
                                      optionDisplay="libelle"
                                      [disabled]="!parametres.approbation || !parametres.autorisationCirculer"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.tooltipAlerteOdNdf' | translate"
                                      ngDefaultControl>
                        </custom-input>
                        <custom-input [(ngModel)]="parametres.ndfAutorisation"
                                      customType="select"
                                      libelle="admin.entreprise.vehicules.parametres.autorisationDeCirculer.ndf"
                                      name="ndfAutorisation"
                                      id="ndfAutorisation"
                                      [selectOptions]="listeOptionsNiveauAutorisation"
                                      optionValue="id"
                                      optionDisplay="libelle"
                                      [disabled]="!parametres.approbation || !parametres.autorisationCirculer"
                                      [postTooltip]="'admin.entreprise.vehicules.parametres.tooltipAlerteOdNdf' | translate"
                                      ngDefaultControl>
                        </custom-input>
                    </div>
                </fieldset>
            </form>
        </mat-card-content>
    </mat-card>
    <floating-button [listeActions]="listeActions" [isPending]="isLoading"></floating-button>
</div>
<please-wait class="content" *ngIf="!parametres"></please-wait>
