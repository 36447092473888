import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions,Effect,ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {finalize,first,map} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {Action} from '@domain/action';
import {environment} from '@environments/environment';
import {AppState} from '@domain/appstate';
import {Result} from '@domain/common/http/result';
import {LOAD_SETTINGS,UPDATE_SETTINGS} from '@reducers/settings';
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {SettingsState} from "@domain/settings/settings";

@Injectable()
export class SettingsEffects {
    /**
     * Constructeur
     */
    constructor(private actions$: Actions,
                private http: HttpClient,
                private store: Store<AppState>) {
    }

    /** Mémorisation du chargement en cours des settings */
    private loading = {};

    /**
     * Chargement du paramétrage
     */
    @Effect({dispatch: false})
    loadSettings$: Observable<Action<keyof SettingsState>> = this.actions$.pipe(
        ofType(LOAD_SETTINGS),
        map((action: Action<keyof SettingsState>) => {
            //Sélection du paramétrage
            this.store.select(state => {
                //Si les settings ne sont pas encore init, on ne veut pas aller plus loin (on renvoie null pour qu'il soit intercepté par le filtre)
                if (state.settings == null) {
                    //On renvoie null
                    return null;
                } else {
                    //Sinon, on renvoie un boolean qui indique si les settings de la portée sont déjà présents ou non
                    return !!(state.settings[action.payload]);
                }
            }).pipe(filterFirstNotNull()).subscribe(settings => {
                //On récupère les settings que quand le user n'est pas null
                this.store.select(state => {
                        if (state.session == null) {
                        return null;
                    }
                    return state.session?.user;
                }).pipe(filterFirstNotNull()).subscribe(() => {
                    //Vérification du paramétrage et qu'il n'est pas déjà en cours de chargement
                    if (!settings && !this.loading.hasOwnProperty(action.payload)) {
                        //Chargement en cours
                        this.loading[action.payload] = true;

                        //Chargement du paramétrage
                        this.http.post<Result>(`${environment.baseUrl}/controller/Settings/loadSettings/${action.payload}`, null).pipe(
                            first(),
                            map(result => result?.data),
                            finalize(() => delete this.loading[action.payload])
                        ).subscribe({
                            next: settings => {
                                //Mise à jour du paramétrage
                                this.store.dispatch({
                                    type: UPDATE_SETTINGS,
                                    payload: {
                                        typePortee: action.payload,
                                        settings
                                    }
                                });
                            }
                        });
                    }
                });
            });

            //Retour de l'action
            return action;
        })
    );
}