import {Component,Input} from '@angular/core';
import {ProfilConnexion} from "@domain/travelhub/profil-connexion";
import {ControlContainer,NgForm} from "@angular/forms";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";

/**
 * Cadre "Généralités" du profil de connexion TH
 *
 * @author Laurent Convert
 * @date 19/03/2024
 */
@Component({
	host: {'data-test-id': 'profil-connexion-generalites'},
	selector: 'profil-connexion-generalites',
	templateUrl: './profil-connexion-generalites.component.html',
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProfilConnexionGeneralitesComponent {
	/** Profil de connexion courant */
	@Input()
	profilConnexion: ProfilConnexion;

	/** Liste des configurations de type ONLINE disponibles */
	@Input()
	listeOnlineSBTConfig: Array<TravelHubSBTConfigUsed>

	/** Permet de savoir si on est en création ou non */
	@Input()
	isCreation: boolean;
}