import {Component,OnInit} from "@angular/core";
import {ActivatedRoute,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {first} from "rxjs/operators";

/**
 * Détails d'une population
 *
 * @author Tom JEAMMET
 */
@Component({
    host: {'data-test-id': 'population-details'},
    templateUrl: './population-details.component.html'
})
export class PopulationDetailsComponent implements OnInit {
    /** Déclaration pour le template */
    Onglets = Onglets;

    /** Liste des différents onglets disponibles dans le menu entreprise */
    listeTabItems: PageHeaderItem[] = [{
        code: Onglets.GENERALITES,
        libelle: this.translateService.instant('admin.entreprise.populations.onglets.generalites')
    }];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    /** Index de l'onglet courant */
    selectedIndex: number = 0;

    /** Id de la population */
    idPopulation: number;

    /**
     * Constructeur de la classe.
     *
     * @param translateService Le service de traduction utilisé dans la classe.
     * @param router Le routeur utilisé dans la classe.
     * @param activatedRoute Le service ActivatedRoute utilisé dans la classe.
     */
    constructor(
        private translateService: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Récupération de l'identifiant du service à afficher
        this.activatedRoute.params.pipe(first()).subscribe(params => {
            this.idPopulation = params['idPopulation'];
        });

        this.addModificationOnglet();
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem): void {
        //Si on n'a pas encore initialisé un selectItem
        if (!this.selectedItem) {
            this.selectedItem = selectedItem;
        }
        if (selectedItem.code !== this.selectedItem.code) {
            //Si le code n'est pas le code courant, on change
            this.selectedItem = selectedItem;
            this.selectedIndex = this.listeTabItems.findIndex(item => selectedItem.code === item.code);
        }
    }

    /**
     * Onglet affiché uniquement en modification
     */
    addModificationOnglet(): void {
        //Si on est plus en création
        if (this.idPopulation != -1) {
            //On ajoute les onglets complémentaires
            this.listeTabItems = [
                ...this.listeTabItems,
                {
                    code: Onglets.PERIMETRE,
                    libelle: this.translateService.instant('admin.entreprise.populations.onglets.perimetre')
                },{
                    code: Onglets.HISTORIQUE,
                    libelle: this.translateService.instant('admin.entreprise.populations.onglets.historique')
                }]
        }
    }

    /**
     * Retour arrière
     */
    onGoBack(): void {
        this.router.navigate(['Admin/Entreprise/Populations']);
    }

    /** Mise à jour de l'id population par un composant enfant */
    idPopulationChange(event: number): void {
        this.idPopulation = event;
        this.addModificationOnglet();
    }
}

/**
 * Enum pour les noms des différents onglets de la page Entreprise/population
 */
export enum Onglets {
    GENERALITES = "Généralités",
    PERIMETRE = "Périmètre",
    HISTORIQUE = "Historique"
}
