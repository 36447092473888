import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';


/**
 *  Directive de validation qui permet de controler que l'input date est supérieur à la date du jour
 */
@Directive({
	selector: '[dateAfterNow]',
	providers: [{ provide: NG_VALIDATORS, useExisting: DateAfterNowDirective, multi: true }]
})
export class DateAfterNowDirective implements Validator {
	/** Si renseigné à true le validator ne renvoie pas d'erreur si la date est égale à la date du jour */
    @Input() isSameDateValid: boolean = false;

	validate(control: AbstractControl): ValidationErrors | null {
		return checkDateBeforeNow(control.value, this.isSameDateValid);
	}
}

/** Fonction de validation qui permet de contrôler que l'input date est supérieur à la date du jour */
function checkDateBeforeNow(date: Date, isSameDateValid: boolean): ValidationErrors {
    const nowDate = moment();

    //Si la date n'est pas null
    //Et la date du jour avant la date ou même date si accepté
    if (date != null 
        && (nowDate.isBefore(date, 'day') || (isSameDateValid && nowDate.isSame(date, 'day')))) {
        //Pas d'erreur
        return null;
    }

    //On renvoie une erreur
	return {creditCard: 'error'};
}