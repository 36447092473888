import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {TypePorteeParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PieceJointeService} from "@components/admin/parametre/demat/piece-jointe/piece-jointe.service";
import {NgForm} from '@angular/forms';

/**
 * Composant d'affichage de la page des paramètres des pièces jointes.
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 */
@Component({
	host: {'data-test-id': 'piece-jointe'},
	selector: 'piece-jointe',
	templateUrl: './piece-jointe.component.html'
})
export class PieceJointeComponent implements OnInit,OnDestroy {
	/** Déclaration(s) pour affichage dans le template */
	TypePorteeParam = TypePorteeParam;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Le formulaire */
	@ViewChild('form') form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param pieceJointeService le service de gestion des PJ
	 */
	constructor(
		public pieceJointeService: PieceJointeService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation des actions possibles
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			doAction: () => this.save(),
			isDisabled: () => this.form?.invalid,
		}]);

		//On charge le service pour récupérer le modèle dans le sous-composant
		this.pieceJointeService.load();
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//On reset le service lié
		this.pieceJointeService.reset();
	}

	/**
	 * Sauvegarde du modèle
	 */
	save(): void {
		//Uniquement si le form est valide
		if (this.form.valid) {
			this.pieceJointeService.save();
		}
	}
}



