<div class="with-floating-button">
    <div class="content" *ngIf="territoire">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.geographie.territoire.title' | translate: {libelle: territoire.libelle}" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.territoire.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="territoire">
                <territoire-generalites [territoire]="territoire" [isCreation]="isCreation"></territoire-generalites>
            </form>
        </div>

        <div *ngIf="!isCreation" [hidden]="selectedItem?.code !== Onglets.PERIMETRE">
            <territoire-perimetre [idTerritoire]="territoire.idTerritoire"></territoire-perimetre>
        </div>
        <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!territoire"></please-wait>
</div>