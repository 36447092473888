<mat-card>
    <mat-card-title>
        <span [translate]="'profilUser.menuLabel'"></span>
    </mat-card-title>
    <mat-card-content>
        <please-wait class="content" *ngIf="isLoading"></please-wait>

        <div *ngIf="!isLoading" class="listview">
            <div class="listview-body">
                <div class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar"><mat-icon>key</mat-icon></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a (click)="goToDonneesConnexion()" [translate]="'profilUser.monProfil.donneesConnexion'"></a>
                            </div>
                            <ul class="lvi-attrs">
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar"><mat-icon>smartphone</mat-icon></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a (click)="goToMobile()" [translate]="'profilUser.monProfil.applicationMobile'"></a>
                            </div>
                            <ul class="lvi-attrs">
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="settings?.analytiqueUserVisibility" class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar"><mat-icon>account_tree</mat-icon></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a (click)="goToPrefAnalytiques()" [translate]="'profilUser.monProfil.preferencesAnalytiques'"></a>
                            </div>
                            <ul class="lvi-attrs">
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar"><mat-icon>handyman</mat-icon></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a  (click)="goToPersonnalisation()" [translate]="'profilUser.monProfil.personnalisation'"></a>
                            </div>
                            <ul class="lvi-attrs">
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
