import {ListItem} from "../common/list-view/list-item";

/**
 * Carte de paiement
 */
export class CartePaiement implements ListItem {
	isOpened: boolean;
	idCarte: string;
	libelle: string;
	idUser: number;
	idContrat: number;
	dateFin: Date;
	refCollab: string;
	typeDebit: TypeDebit;
	compteBanque: string;
	compteTiers: string;
	actif: boolean;
	referenceImport: string;
	numCarteClair: string;
	typeCarte: TypeCarte;
	typeCartePaiement: TypeCartePaiement;
	contrat: Contrat;

	getKey(): number {
		return this.idContrat;
	}

	/**
	 * Constructeur
	 *
	 * @param idUser l'ID de l'utilisateur rattaché à la carte (valorisé depuis l'admin uniquement)
	 */
	constructor(idUser?: number) {
		if (idUser) {
			this.idUser = idUser;
		}

		this.actif = true;
	}
}

/**
 * Contrat pour la carte de paiement
 */
export interface Contrat {
	idContrat: number;
	numero: string;
	libelle: string;
	commentaire: string;
	idFormatCarte: number;
	idFormatFacture: number;
	isCreationAuto: boolean;
	idTypeEntite: number;
	idStatutValide: number;
	idStatutErreur: number;
	idFournisseur: number;
	typeImport: TypeImport;
	typeDebit: TypeDebit;
	dateDebut: Date;
	dateFin: Date;
}

export enum TypeDebit {
	SOCIETE = "SOCIETE",
	COLLAB = "COLLAB"
}

export enum TypeCarte {
	AFFAIRE = "AFFAIRE",
	PERSO = "PERSO"
}

export enum TypeCartePaiement {
	INDETERMINE,
	AMERICAN_EXPRESS,
	DINERS_CLUB,
	DISCOVER,
	EUROCARD,
	MASTERCARD,
	VISA
}

export enum TypeImport {
	CARTE,
	FACTURE
}
