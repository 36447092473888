<h1 mat-dialog-title>
    <span>{{ 'admin.maintenance.migrations.popup.title' | translate }}</span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form>
        <div class="row" *ngIf="isMaintenanceEnCours">
            <div class="col text-center bold">
                {{ etape | translate }}
            </div>
        </div>
        <div class="row" *ngIf="isMaintenanceEnCours">
            <mat-progress-bar [value]="progression"></mat-progress-bar>
        </div>
        <div class="row">
            <div class="col-6"></div>
            <div class="col-3 text-center bold title">{{ 'admin.maintenance.migrations.popup.version' | translate }}</div>
            <div class="col-3 text-center bold title">{{ 'admin.maintenance.migrations.popup.build' | translate }}</div>
        </div>
        <div class="row">
            <div class="col-6 bold title">{{ 'admin.maintenance.migrations.popup.bdd' | translate }}</div>
            <div class="col-3 text-center bold red">{{ appStatut?.versionBdd }}</div>
            <div class="col-3 text-center bold red">{{ appStatut?.buildBdd }}</div>
        </div>
        <div class="row">
            <div class="col-6 bold title">{{ 'admin.maintenance.migrations.popup.app' | translate }}</div>
            <div class="col-3 text-center bold">{{ appStatut?.versionAppli }}</div>
            <div class="col-3 text-center bold">{{ appStatut?.buildAppli }}</div>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary" (click)="upgradeBdd()" [disabled]="isMaintenanceEnCours">
                <span [translate]="'admin.maintenance.migrations.popup.button'"></span>
            </button>
        </div>
    </form>
</div>
