import {Component,OnInit,ViewChild} from '@angular/core';
import {ProfilAlertesConfigComponent} from "@components/admin/entreprise/utilisateurs/utilisateurs-parametres/alertes-profil-config/profil-alertes-config.component";
import {ExternesParamsComponent} from "@components/admin/entreprise/utilisateurs/utilisateurs-parametres/externes-params/externes-params.component";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {UtilisateursParametresService} from "@services/admin/entreprise/utilisateurs/utilisateurs-parametres.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {isAllowed} from "@core/security/role-admin-helpers";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {Session} from "@domain/security/session";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Composant de l'onglet "Paramètres" de la page "Utilisateurs" de l'admin V10
 */
@Component({
	host: {'data-test-id': 'utilisateurs-parametres'},
	templateUrl: './utilisateurs-parametres.component.html'
})
export class UtilisateursParametresComponent implements OnInit {
	/** Composant enfant : configuration des alertes du profil */
	@ViewChild(ProfilAlertesConfigComponent)
	profilAlertesConfigComponent: ProfilAlertesConfigComponent;

	/** Composant enfant : paramétrage des externes */
	@ViewChild(ExternesParamsComponent)
	externesParamsComponent: ExternesParamsComponent;

	/** Liste des actions possibles pour le floatingButton en bas à droite */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean

	/** Session */
	private session: Session;

	/**
	 * Constructeur
	 *
	 * @param store le sotre de l'appli
	 * @param usersParamsService le service de gestion des paramètres utilisateurs
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 */
	constructor(private store: Store<AppState>,
				private usersParamsService: UtilisateursParametresService,
				private translateService: TranslateService,
				private toastrService: ToastrService
	) {
		//Récupération de la session
		this.store.select(state => state.session).pipe(filterFirstNotNull()).subscribe((session: Session) => {
			//Stockage de la session
			this.session = session;
		});
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition de la liste des actions
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			isDisabled: () => this.profilAlertesConfigComponent?.form?.invalid || this.externesParamsComponent?.form?.invalid,
			doAction: () => this.save()
		}]);
	}

	/**
	 * Sauvegarde des paramètres
	 */
	save(): void {
		//Enregistrement en cours
		this.isSaving = true;

		//Appel au service
		this.usersParamsService.saveUsersParams(this.profilAlertesConfigComponent.profilAlertesConfig,this.externesParamsComponent?.externesParams)
			.pipe(first(),finalize(() => this.isSaving = false))
			.subscribe((results: Result[]) => {
				//Recherche d'un result en erreur
				let resultWithError: Result = results.find((result: Result) => result.codeErreur !== TypeCodeErreur.NO_ERROR);

				//S'il y a une erreur
				if (resultWithError) {
					//Gestion de l'erreur
					TypeCodeErreur.showError(resultWithError.codeErreur,this.translateService,this.toastrService);
				} else {
					//Sinon, on affiche le toast de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
				}
			});
	}

	/**
	 * Indique si l'utilisateur connecté peut voir la carte configuration des alertes.
	 */
	canSeeAlerteConfig(): boolean {
		return isAllowed(this.session,[DroitAdmin.DROIT_USER_ALERTE_PROFIL]);
	}

	/**
	 * Indique si l'utilisateur connecté est Administrateur.
	 */
	isAdmin(): boolean {
		return this.session.isAdmin;
	}
}
