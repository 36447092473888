<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }">
        <i class="nio icon-note_de_frais" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.showNDF(data); $event.stopPropagation();">
                {{ data.numeroPiece || ('ndf.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('ndf.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.idOd" (click)="openOd(data.idOd)" style="cursor:pointer;">
                <strong [translate]="'ndf.liste.om'"></strong>
                {{ data.idOd }}
                <mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
            </li>
            <li *ngIf="!isCollabMode">
                <strong [translate]="'ndf.liste.collaborateur'"></strong>
                {{ data.user | user }}
            </li>
            <li><strong [translate]="'omp.ndf.liste.typeEntite'"></strong>{{ data.typeEntite?.libelle }}</li>
            <li *ngIf="data.dateMaj"><strong [translate]="'ndf.liste.dateStatut'"></strong>{{ data.dateMaj | date:'shortDate' }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes(data.idNDF)" class="clickable">
                <strong [translate]="'workflow.alerte.title'"></strong>
                <span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div class="text-right text-secondary font-weight-bold">
                {{ data.montantRemboursable | montant:data.devise?.code }}
            </div>
        </div>
    </div>
</div>
