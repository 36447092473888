import {TranslateService} from '@ngx-translate/core';
import {AutocompleteOptions} from '../autocomplete';
import {Filter} from "@domain/common/list-view";
import {SearchType} from '@domain/common/list-view/sorting';
import {Devise} from "@domain/settings/devise";
import {DeviseLineItemComponent} from "@share/component/autocomplete/options/devise.line-item";
import {DeviseSearchItemComponent} from "@share/component/autocomplete/options/devise.search-item";

/**
 * Options des Devises
 */
export default class DeviseOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     */
    url(filter: any) { return `/controller/Devise/searchListeDevise/${filter?.isActif || true}`; }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() { return DeviseLineItemComponent }

    /**
     * Composant d'affichage d'une ligne dans la popup de recherche
     */
    searchItemComponent() { return DeviseSearchItemComponent }

    /**
     * Affichage d'un élément
     */
    displayItem(item: Devise) { return (item?.code ? `${item.code}` : ''); }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.code'),
            clef: 'code',
            isDefault: true
        }, {
            title: translateService.instant('filter.libelle'),
            clef: 'libelle',
            isDefault: true
        }]
    }

    /**
     * Mode de recherche
     */
    searchType = SearchType.CONTAINS;
}