import { Injectable } from '@angular/core';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';

import { PleaseWaitDialogComponent } from './please-wait-dialog.component';

@Injectable()
export class PleaseWaitService {
    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog) {}

    /**
     * Affichage de la popup d'attente
     */
    show({ title }: { title?: string } = {}): MatDialogRef<PleaseWaitDialogComponent> {
        let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

        //Ouverture de la popup
        matDialogRef = this.matDialog.open(PleaseWaitDialogComponent,{
            data: {
                title
            },
            hasBackdrop: true,
            disableClose: true
        });

        return matDialogRef;
    }
}