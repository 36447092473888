import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {AlertesVehicule} from "../../../domain/vehicule/alertesVehicule";
import {VehiculeApprobation} from "../../../domain/vehicule/vehiculeapprobation";

/**
 * Composant d'affichage d'alerte pour les véhicules
 */
@Component({
  host: {'data-test-id': 'vehicule-list-alerte'},
  templateUrl: './vehicule-list-alerte.component.html'
})
export class VehiculeListAlerteComponent {

  /**
   * Constructeur
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: { alertes: AlertesVehicule, approbation: VehiculeApprobation }) {}


}
