import { Component,Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { TypePortee } from '@domain/workflow/workflow';
import { AutocompleteService } from '@share/component/autocomplete/autocomplete.service';

@Component({
    templateUrl: './od-creation-item.component.html'
})
export class ODCreationItemComponent {
    /** Elément courant */
    @Input() data: any;

    /** Options complémentaires */
    @Input() extraOptions: { idCollab: number }

    /**
     * Constructeur
     */
    constructor(private autocompleteService: AutocompleteService, private matDialogRef: MatDialogRef<any>) {

    }

    /**
     * Création d'un OD hors mission permanente
     */
    selectNoMission() {
        //Affichage de la popup de sélection du type entité
        this.autocompleteService.showSearch({
            type: 'typeEntite',
            filter: {
                idPortee: TypePortee.OD,
                idCollab: this.extraOptions.idCollab
            }
        }).subscribe({
            next: (item) => {
                //Fermeture de la popup avec sélection du type entité
                item && this.matDialogRef.close({
                    typeEntite: item
                });
            }
        });
    }
}
