import {ListItem} from "../common/list-view/list-item";
import {Ville} from "@domain/geographie/ville";
import {Origine, Visibilite} from "@domain/geolocalisation/localisation";
import {User} from "@domain/user/user";
import {IataVille} from "@domain/geographie/iata-ville";
import {Pays} from "@domain/geographie/pays";

/**
 * Modèle pour l'adresse
 */
export class Adresse {
    actif?: boolean;
    adresseImport?: boolean;
    codePays?: string;
    codePostal?: string;
    id?: number;
    idAdresse?: number;
    idLocalisation?: number;
    idUser?: number;
    isActif?: boolean;
    pays?: string;
    idPays?: number;
    region?: string;
    departement?: string;
    rue?: string;
    type?: string;
    ville?: string;
    libelle?: string;
    adresseVille?: Ville;
    iataVille?: IataVille;
    complement?: string;
    idVille?: number;
    code?: string;
    idDepartement?: number;
    idRegion?: number;
    latitude?: number;
    longitude?: number;
    adressePays?: Pays;
    origine?: Origine;
    attribut1?: number;
    attribut2?: number;
    attribut3?: number;
    attribut4?: number;
    listeVisibilite?: Array<Visibilite>;


    /** Concaténation des champs de l'adresse (front only) */
    adresse?: string;

    /** Utilisateur lié à l'adresse (front only) */
    user?: User;

    /** Visibilités (front only) pour le multiselect - à définir à partir de listeVisibilite */
    visibilites?: Array<OptionVisibilite>;

    constructor(dto?: any) {
        if (dto) {
            Object.assign(this,dto);
        }
    }

    /**
     * Retourne une chaîne formatée pour l'affichage du libellé complet
     */
    static libelleForDisplay(adresse: Adresse): string {
        const ville: string = adresse.ville ?? adresse.adresseVille?.libelle ?? '';
        const pays: string = adresse.pays ?? adresse.adresseVille?.pays?.libelle ?? '';

        return [adresse.libelle, adresse.rue, ville, pays].filter(str => str).join(', ');
    }
}

/**
 * Modèle pour l'adresse dans un list item
 */
export class AdresseListItem extends Adresse implements ListItem  {
    getKey(): number {
        return this.idAdresse;
    }

    constructor() {
        super()
        this.isActif = true;
        this.idAdresse = 0;
        this.idLocalisation = 0;
    }
}

/**
 * Modèle des options de visibilité d'une adresse
 */
export interface OptionVisibilite {
    id: Visibilite,
    libelle: string,
}