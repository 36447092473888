import {Component,Input,OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {Orga} from "@domain/admin/entreprise/analytique/orga";

/**
 * Composant d'affichage d'un sous service dans l'organigramme
 */
@Component({
    host: {'data-test-id': 'analytique-organigramme-item-sous-orga'},
    selector: 'analytique-organigramme-item-sous-orga',
    templateUrl: './analytique-organigramme-item-sous-orga.component.html',
    styleUrls: ['./analytique-organigramme-item-sous-orga.component.scss']
})
export class AnalytiqueOrganigrammeItemSousOrgaComponent implements OnInit {

    /** Service à afficher */
    @Input() orga: Orga;

    /** niveau de l'élément dans l'arbre => utile pour le décalage css */
    @Input() niveau: number = 0;

    /** Permet de savoir si l'item est ouvert ou non, pour savoir s'il faut afficher les sous services */
    _isOuvert: boolean = false;

    /** Liste des sous services à afficher */
    listeSousOrga: Orga[];

    /**
     * Constructeur
     *
     * @param analytiqueService Service de gestion des informations de l'analytique
     * @param router Router de l'application
     */
    constructor(
        private analytiqueService: AnalytiqueService,
        private router: Router
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Ré-affichage des sous-services affichés le cas échéant
        if (this.analytiqueService.isOrgaUnfolded(this.orga.idOrga)) {
            this.showChild();
        }
    }

    /**
     * Méthode appelée lors de la sélection d'un service
     */
    onSelect() {
        //Navigation vers les informations du service
        this.router.navigate([`Admin/Entreprise/Analytique/Services/${this.orga.idOrga}`]);
    }

    /**
     * Getter permettant de savoir si les sous-services sont affichés ou non
     */
    get isOuvert(): boolean {
        return this._isOuvert;
    }

    /**
     * Setter permettant de savoir si les sous-services sont affichés ou non.<br>
     * Note : lance une requête back pour récupérer les services à afficher
     */
    set isOuvert(isOuvert: boolean) {
        this._isOuvert = isOuvert;
        this.analytiqueService.setOrgaFoldedUnfolded(this.orga.idOrga,isOuvert);
    }

    /**
     * Affichage, ou non, des sous services du service courant
     */
    showChild() {
        //Si les sous services sont déjà affichés
        if (this.isOuvert) {
            //On replie le service sélectionné
            this.isOuvert = false;
        } else {
            //Sinon on affiche les sous services
            this.isOuvert = true;
            //Récupération des sous services si on ne les a pas encore récupérés
            if (!this.listeSousOrga) {
                this.analytiqueService.getChild(this.orga.idOrga).subscribe(data => {
                    this.listeSousOrga = data.data.listeSousServices;
                });
            }
        }
    }
}
