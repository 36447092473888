<div class="lvi-content">
    <div class="avatar">
        <!-- Icone de l'étape -->
        <span [ngSwitch]="data.idPortee">
            <i *ngSwitchCase="'TT'" class="icon-train nio align-middle"></i>
            <i *ngSwitchCase="'TA'" class="icon-avion nio align-middle"></i>
            <i *ngSwitchCase="'TR'" class="icon-aviontrain nio align-middle"></i>
            <i *ngSwitchCase="'TV'" class="icon-voiture nio align-middle"></i>
            <i *ngSwitchCase="'HE'" class="material-icons-outlined align-middle">hotel</i>
            <i *ngSwitchCase="'FA'" class="material-icons-outlined align-middle">insert_drive_file</i>
            <i *ngSwitchDefault class="material-icons-outlined align-middle">help_outline</i>
        </span>
    </div>
    <div class="lvi-body">
        <ng-container *ngIf="data.idPortee !== 'FA'; else itemDocument">
            <div class="title row">
                <!-- Colonne départ -->
                <span class="col-md-6">
                    <!-- Titre pour les étapes qui ne sont pas des hébergements online (online = idNature renseigné) sauf s'ils ont bien une ville ou un pays -->
                    <a *ngIf="!isHebergementOnline || !!data.villeDepart?.libelle || !!data.paysDepart?.libelle" [routerLink]="" (click)="navigateToVoyage(data)">
                        <span class="flag-icon mr-1 col-md-6" [ngClass]="data.paysDepart ? 'flag-icon-' + data.paysDepart.code2.toLowerCase() : ''"></span>
                        {{ (data.villeDepart?.libelle ? data.villeDepart?.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (data.paysDepart?.libelle ? data.paysDepart?.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}

                        <!-- Séparateur si on va afficher le nom de l'hôte -->
                        <ng-container *ngIf="this.data.idPortee === 'HE' && !!this.data.idNature && !!nomHebergement">&nbsp;-&nbsp;</ng-container>
                    </a>
                    <!-- Titre pour les étapes d'hébergement online -->
                    <a *ngIf="this.data.idPortee === 'HE' && !!this.data.idNature" [routerLink]="" (click)="navigateToVoyage(data)">
                        <span *ngIf="isHebergementOnline && !data.villeDepart?.libelle && !data.paysDepart?.libelle" class="flag-icon mr-1 col-md-6"></span>
                        {{ nomHebergement }}
                    </a>
                </span>
                <!-- Colonne arrivée -->
                <span class="col-md-6" *ngIf="!(['HE', 'FA'].includes(data.idPortee))">
                    <a [routerLink]="" (click)="navigateToVoyage(data)">
                        <span class="flag-icon mr-1 col-md-6" [ngClass]="'flag-icon-'+data.paysArrivee?.code2.toLowerCase()"></span>
                        {{ (data.villeArrivee?.libelle ? data.villeArrivee?.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (data.paysArrivee?.libelle ? data.paysArrivee?.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}
                    </a>
                </span>
            </div>
            <!-- Bulles d'informations -->
            <ul class="lvi-attrs row">
                <!-- Colonne départ -->
                <span class="col-md-6">
                    <li *ngIf="data.iataDepart"><strong [translate]="'od.voyage.depart'"></strong>{{ data.iataDepart.libelle }}</li>
                    <li *ngIf="data.dateDebut">
                        <strong [translate]="'od.voyage.debut'"></strong>{{ data.dateDebut | date:'shortDate' }}<span *ngIf="data.idPortee != 'HE'"> - {{data.heureDebut}}</span>
                    </li>
                    <li *ngIf="data.idPortee == 'HE' && data.dateFin"><strong [translate]="'od.voyage.fin'"></strong>{{data.dateFin | date:'shortDate'}}</li>
                    <li *ngIf="data.idNature === 0" class="back-warning">
                        <strong [translate]="'od.voyage.etat'"></strong>{{'od.voyage.nonReservee' | translate}}
                        <mat-icon [matTooltip]="'od.voyage.etapeNonReservee' | translate" matSuffix>comment</mat-icon>
                    </li>
                    <li *ngIf="data.remarques">
                        <strong translate>od.voyage.travel.remarque</strong><mat-icon [matTooltip]="data.remarques">comment</mat-icon>
                    </li>
                </span>
                <!-- Colonne arrivée -->
                <span class="col-md-6">
                    <li *ngIf="data.iataArrivee"><strong [translate]="'od.voyage.arrivee'"></strong>{{ data.iataArrivee.libelle }}</li>
                    <li *ngIf="data.idPortee == 'TV' && data.dateFin">
                        <strong [translate]="'od.voyage.fin'"></strong>{{ data.dateFin | date:'shortDate' }} - {{data.heureFin}}
                    </li>
                </span>
            </ul>
        </ng-container>
    </div>
</div>

<ng-template #itemDocument>
    <div class="title">
        <a [routerLink]="" (click)="navigateToVoyage(data)">
            {{ (!!data.carteTravel ? data.carteTravel.typeCarte + ' ' : '') }} {{ getCleTradPresta() | translate }}
        </a>
    </div>
    <!-- Bulles d'informations -->
    <ul class="lvi-attrs">
        <!-- Origine -->
        <li *ngIf="data.villeDepart || data.paysDepart"><strong [translate]="data.typePresta !== TypePresta.DOCUMENT ? 'od.voyage.travel.origine' : 'od.voyage.travel.destination'"></strong>
            <span class="flag-icon mr-1" *ngIf="data.paysDepart" [ngClass]="'flag-icon-'+data.paysDepart.code2.toLowerCase()"></span>
            {{ (data.villeDepart?.libelle ? data.villeDepart?.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (data.paysDepart?.libelle ? data.paysDepart?.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}
        </li>
        <!-- Destination -->
        <li *ngIf="data.villeArrivee || data.paysArrivee"><strong [translate]="'od.voyage.travel.destination'"></strong>
            <span class="flag-icon mr-1" *ngIf="data.paysArrivee" [ngClass]="'flag-icon-'+data.paysArrivee.code2.toLowerCase()"></span>
            {{ (data.villeArrivee?.libelle ? data.villeArrivee?.libelle : ('od.voyage.travel.villeInconnue' | translate)) + ', ' + (data.paysArrivee?.libelle ? data.paysArrivee?.libelle : ('od.voyage.travel.paysInconnu' | translate)) }}
        </li>
        <!-- Date de début -->
        <li *ngIf="data.dateDebut">
            <strong [translate]="'od.voyage.debut'"></strong>{{ data.dateDebut | date:'shortDate' }}
        </li>
        <!-- Fournisseur -->
        <li *ngIf="data.carteTravel">
            <strong translate>od.voyage.travel.fournisseur</strong>{{ data.carteTravel.compagnie.libelle }}
        </li>
        <!-- Libellé carte -->
        <li *ngIf="data.carteTravel">
            <strong translate>od.voyage.travel.carte</strong>{{ data.carteTravel.libelle }}
        </li>
        <!-- Remarques -->
        <li *ngIf="data.remarques">
            <strong translate>od.voyage.travel.remarque</strong>
            <mat-icon [matTooltip]="data.remarques">comment</mat-icon>
        </li>
    </ul>
</ng-template>