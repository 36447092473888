import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypeDocument} from "../../../domain/vehicule/typeDocument";
import {SettingsVehiculeState} from "../../../domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "../../../domain/appstate";
import {Status,Vehicule} from "../../../domain/vehicule/vehicule";

@Component({
    host: {'data-test-id': 'vehicule-add-document'},
    templateUrl: './vehicule-add-document.component.html'
})
/**
 * Composant d'ajout de documents aux véhicules
 */
export class VehiculeAddDocumentComponent {
    /** Déclaration pour accès direct dans le template */
    Status = Status;

    /** Énumération pour le type de document */
    TypeDocument = TypeDocument;

    constructor(
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<VehiculeAddDocumentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            isResponsableMode: boolean,
            isCollabMode: boolean,
            vehicule: Vehicule,
            settings: SettingsVehiculeState,
            isResponsableVehicule: boolean
        }
    ) { }

    /**
     * Fermeture de la popup
     */
    closePopup(): void {
        this.dialogRef.close();
    }

    /**
     * Ouverture de la popup d'ajout de document
     * @param type TypeDocument
     */
    addDocument(type: TypeDocument) {
        this.dialogRef.close({type: type});
    }
}
