<ul class="rule-detail" [ngClass]="{ 'selected-rule': isSelectedRule(), 'z-depth-1': (isGroup || isEmbeddedRule) }">
    <li class="header">
        <div class="btn-group">
            <button class="btn" [ngClass]="rule.operateur === TypeRuleOperator.AND ? 'btn-primary' : 'btn-light'" (click)="rule.operateur = TypeRuleOperator.AND">
                <span [translate]="'rule.typeRuleOperator.and'"></span>
            </button>
            <button class="btn" [ngClass]="rule.operateur === TypeRuleOperator.OR ? 'btn-primary' : 'btn-light'" (click)="rule.operateur = TypeRuleOperator.OR">
                <span [translate]="'rule.typeRuleOperator.or'"></span>
            </button>
        </div>
        <div class="pull-right">
            <button *ngIf="!isSelectedRule()" mat-icon-button color="primary" (click)="selectRule()" [matTooltip]="'rule.select' | translate">
                <i class="fa fa-toggle-off" style="font-size: large"></i>
            </button>
            <button *ngIf="isSelectedRule()" mat-icon-button color="primary" (click)="selectRule()" [matTooltip]="'rule.unselect' | translate">
                <i class="fa fa-toggle-on" style="font-size: large"></i>
            </button>
            <button mat-icon-button color="primary" [matMenuTriggerFor]="listeActions">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #listeActions="matMenu" xPosition="before">
                <button mat-menu-item (click)="onAddItem(TypeItem.RULE)" translate="rule.addRule"></button>
                <button mat-menu-item (click)="onAddItem(TypeItem.GROUPE)" translate="rule.addGroup"></button>
                <button mat-menu-item (click)="onRemoveItem()" translate="global.actions.supprimer"></button>
            </mat-menu>
        </div>
    </li>
    <li class="content">
        <ul class="rule-list">
            <li *ngFor="let detail of rule.listeDetails; let idxDetail = index;">
                <rule-item *ngIf="!detail.rule" [(detail)]="rule.listeDetails[idxDetail]" [entite]="entite" [isEmbeddedRule]="isEmbeddedRule" (onDelete)="rule.listeDetails.splice(idxDetail,1)"></rule-item>
                <rule-detail *ngIf="detail.rule" [(rule)]="rule.listeDetails[idxDetail].rule" [entite]="entite" [isEmbeddedRule]="isEmbeddedRule" (onDelete)="rule.listeDetails.splice(idxDetail,1)" [isGroup]="true"></rule-detail>
            </li>
            <li *ngIf="!rule.listeDetails?.length">
                <div class="rule-item hoverable">
                    <a (click)="initRule()" [translate]="'rule.initRule'"></a>
                </div>
                <!-- On empêche la validation du formulaire avec listeDetails vide -->
                <input type="hidden" name="liste-details" [(ngModel)]="rule.listeDetails" required/>
                <input type="hidden" *ngIf="rule.listeDetails" name="liste-details" [(ngModel)]="rule.listeDetails[0]" required/>
            </li>
        </ul>
    </li>
</ul>
