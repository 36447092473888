import {Component,Input,OnInit,ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Field} from '@domain/settings/field';
import {TranslateService} from '@ngx-translate/core';
import {ReleveFacture} from '@domain/facture/releve-facture';

@Component({
    selector: 'releve',
    templateUrl: './releve.component.html'
})
export class ReleveComponent implements OnInit {
    /** Relevé facture */
    @Input() releveFacture: ReleveFacture;

    /** Champs customs */
    @Input() listeFieldParam: Field[];

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /** Types de réconciliation */
	listeTypeReconciliation: any[];

    /** Formulaire des généralités */
    @ViewChild('form') form: NgForm;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService) {}

    /**
     * Initialisaton
     */
    ngOnInit(): void {
        //Peuplement du select de réconciliation
        this.listeTypeReconciliation = [{
            id: 1,
            libelle: this.translateService.instant('facture.releve.reconciliation.collaborateur')
        },{
            id: 2,
            libelle: this.translateService.instant('facture.releve.reconciliation.om')
        }];
    }

    /**
     * Mise à jour de la devise
     */
    updateDevise(): void {
        this.releveFacture.updateDevise();
    }

    /** Retourne true si le formulaire est valide */
    isValid(): boolean {
        return this.form.valid;
    }
}