import {TypeAiguillage,TypeNature} from "../voyage/travel/constants";
import {ListItem} from "../common/list-view/list-item";
import {ProfilConnexion} from "./profil-connexion";
import {TravelHubSBTConfigUsed} from "../travel/travel-hub-sbt-config-used";
import {Portee} from "../portee/portee";

/**
 * Paramètre d'un profil de connexion
 *
 * @author Laurent Convert
 * @date 13/03/2024
 */
export class ProfilConnexionParam implements ListItem {
	idProfilConnexionParam: number;
	typeNature: TypeNature;
	typeAiguillage: TypeAiguillage;
	portee: Portee;
	libelle: string;
	travelHubSBTConfigUsed: TravelHubSBTConfigUsed;
	profilConnexion: ProfilConnexion;

	//Front only. Permet de savoir si le paramètre est manquant et doit être renseigné
	isMissingParam: boolean;

	constructor(dto?: ProfilConnexionParam) {
		if (dto) {
			Object.assign(this,dto);

			if (dto.travelHubSBTConfigUsed) {
				this.travelHubSBTConfigUsed = new TravelHubSBTConfigUsed(dto.travelHubSBTConfigUsed,false);
			}

			if (dto.profilConnexion) {
				this.profilConnexion = new ProfilConnexion(dto.profilConnexion);
			}

			if (dto.typeNature && typeof dto.typeNature === 'string') {
				this.typeNature = TypeNature[<string><unknown>dto.typeNature];
			}

			if (dto.typeAiguillage && typeof dto.typeAiguillage === 'string') {
				this.typeAiguillage = TypeAiguillage[<string><unknown>dto.typeAiguillage];
			}
		}
	}

	getKey(): number {
		return this.idProfilConnexionParam;
	}
}