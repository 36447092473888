import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {NotificationDestinataire} from "@domain/workflow/notification-destinataire";
import {WFUser} from "@domain/workflow/evenement";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Observable,Subscription} from "rxjs";

/**
 * Composant d'affichage d'une ligne à 2 niveaux dans la liste des destinataires :
 * <ul>
 *   <li>Niveau 1 : le mail de notification</li>
 *   <li>Niveau 2 : les destinataires associés</li>
 * </ul>
 *
 * @author Laurent Convert
 * @date 18/04/2024
 */
@Component({
	host: {'data-test-id': 'destinataire-list-item'},
	templateUrl: './destinataire-list-item.component.html'
})
export class DestinataireListItemComponent extends ListViewItem<NotificationDestinataire> implements OnInit,OnDestroy {

	/** Options supplémentaires */
	@Input() public extraOptions: {
		/** Indicateur de mail obligatoire */
		isMailObligatoire: boolean,
		selectAll$: Observable<boolean>,
	}

	/** Abonnement à l'observable de sélection de masse */
	subSelectAll: Subscription;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Abonnement à la sélection de masse
		this.subSelectAll = this.extraOptions.selectAll$.subscribe(select => {
			if (select) {
				this.selectAll();
			} else {
				this.selectNone();
			}
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.subSelectAll?.unsubscribe();
	}

	/**
	 * Retourne le nombre de destinataires sélectionnés mais filtrés
	 */
	countDestinatairesSelectionnesMasques(): number {
		return this.data.destinataires.filter(d => d.isSelected && d.isFiltre).length;
	}

	/**
	 * Retourne True si au moins un destinataire n'est pas filtré
	 */
	hasDestinatairesVisible(): boolean {
		return this.data.destinataires.some(d => !d.isFiltre);
	}

	/**
	 * Retourne le nombre de destinataires sélectionnés.
	 */
	countSelection(): number {
		return this.data.destinataires.filter(dest => dest.isSelected).length;
	}

	/**
	 * Sélection d'un destinataire
	 */
	onDestinataireChange(destinataire: WFUser) {
		destinataire.isSelected = !destinataire.isSelected;
	}

	/**
	 * Sélection des destinataires d'un mail
	 */
	selectAll() {
		//Si la sélection n'est pas automatique
		if (!this.data.isAuto) {
			//Sélection de tous les destinataires
			this.data.destinataires.forEach(dest => dest.isSelected = true);
		}
	}

	/**
	 * Désélectionner des destinataires d'un mail
	 */
	selectNone() {
		//Si la sélection n'est pas automatique
		if (!this.data.isAuto) {
			//Désélection de tous les destinataires
			this.data.destinataires.forEach(dest => dest.isSelected = false);
		}
	}

	/**
	 * Renvoie la liste des destinataires visibles (non masqués par les filtres)
	 */
	get listeVisibleDestinataires(): Array<WFUser> {
		return this.data.destinataires.filter(u => !u.isFiltre);
	}

}