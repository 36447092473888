/**
 * Type de zone de déplacement
 */
export enum TypeOmZone {
    /** Enumération **/
    TYPE_ZONE = 1,
    TYPE_PAYS = 2,
    TYPE_TERRITOIRE = 3, // Type géographie correspondant : Region  ミ●﹏☉ミ
    TYPE_REGION = 4, // Type géographie correspondant : RegionAdmin ミ●﹏☉ミ
    TYPE_DEPARTEMENT = 5,
    TYPE_VILLE = 6
}