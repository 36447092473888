/**
 * Interface décrivant une méthode d'authentification
 *
 * @author Laurent Convert
 * @date 13/06/2022
 */
export interface Auth {
    loginType?: LoginType;
}

/**
 * Méthode d'authentification locale
 */
export class AuthLocal implements Auth {
    loginType = LoginType.LOCAL;
}

/**
 * Méthode d'authentification SAML
 */
export class AuthSAML implements Auth {
    loginType = LoginType.SAML;
    mode: ModeSAML;
}

/**
 * Factory permettant de construire la méthode d'authentification paramétrée dans le back
 *
 * @param auth Données venant du back
 */
export function AuthFactory(auth: Auth): Auth {
    if (auth && auth.loginType == LoginType.SAML) {
        //Authentification SAML
        return Object.assign(new AuthSAML(),auth);
    } else {
        //Authentification locale
        return Object.assign(new AuthLocal(),auth);
    }
}

/**
 * Énuméré des différents types de login
 */
export enum LoginType {
    LOCAL = 'LOCAL',
    SAML = 'SAML',
}

/**
 * Énuméré des différents types de login SAML
 */
export enum ModeSAML {
    SP = 'SP',
    IDP = 'IDP',
}
