<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.reference | slice: 0:3 }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.reference }} - {{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code1">
                <strong [translate]="'admin.entreprise.analytique.codesProjet.code1'"></strong>
                <span>{{ data.code1 }}</span>
            </li>
            <li *ngIf="data.code2">
                <strong [translate]="'admin.entreprise.analytique.codesProjet.code2'"></strong>
                <span>{{ data.code2 }}</span>
            </li>
            <li *ngIf="data.dateDebut">
                <strong [translate]="'admin.entreprise.analytique.codesProjet.dateOuverture'"></strong>
                <span>{{ data.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateFin">
                <strong [translate]="'admin.entreprise.analytique.codesProjet.dateFermeture'"></strong>
                <span>{{ data.dateFin | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.isActif !== null">
                <strong [translate]="'admin.entreprise.analytique.codesProjet.actif'"></strong>
                <span>{{ (data.isActif ? 'global.oui' : 'global.non' ) | translate }}</span>
            </li>
        </ul>
    </div>
</div>
