import {Component} from '@angular/core';
import {PieceJointeService} from '../piece-jointe.service';

/**
 * Composant d'affichage du composant local
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Component({
    host: {'data-test-id': 'stockage-local'},
    selector: 'stockage-local',
    templateUrl: './stockage-local.component.html'
})
export class StockageLocalComponent {
    /**Constructeur */
    constructor(
        public pieceJointeService: PieceJointeService
    ) {
    }
}