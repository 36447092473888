import {NgModule} from '@angular/core';


import {CoreComponentsModule} from '../core-components.module';
import {CardAlerteComponent} from './card-alerte.component';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";

@NgModule({
	imports: [CoreComponentsModule,PreviewAlerteModule],
    declarations: [CardAlerteComponent],
    exports: [CardAlerteComponent]
})
export class CardAlerteModule {}
