import {Component, OnInit, ViewChild} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {VoyagesReferentielsCompagniesInfosComponent} from "@components/admin/voyages/referentiels/compagnies/compagnie-infos/voyages-referentiels-compagnies-infos.component";
import {MatDialog} from "@angular/material/dialog";
import {VoyagesReferentielsCompagniesComponent} from "@components/admin/voyages/referentiels/compagnies/voyages-referentiels-compagnies.component";
import {VoyagesReferentielsCartesVoyageursInfosComponent} from "@components/admin/voyages/referentiels/cartes-voyageurs/cartes-voyageurs-infos/voyages-referentiels-cartes-voyageurs-infos.component";
import {VoyagesReferentielsCartesVoyageursComponent} from "@components/admin/voyages/referentiels/cartes-voyageurs/voyages-referentiels-cartes-voyageurs.component";
import {VoyagesReferentielsClassesInfosComponent} from "@components/admin/voyages/referentiels/classes/classes-infos/voyages-referentiels-classes-infos.component";
import {VoyagesReferentielsClassesComponent} from "@components/admin/voyages/referentiels/classes/voyages-referentiels-classes.component";
import { BehaviorSubject } from "rxjs";

/**
 * Composant d'affichage des informations des référentiels dans le menu Voyages
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels'},
    templateUrl: './voyages-referentiels.component.html'
})
export class VoyagesReferentielsComponent implements OnInit {
    /** Liste des différents onglets disponibles dans le menu "Référentiels" des voyages */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem = {
        code: Onglets.COMPAGNIES,
        libelle: this.translateService.instant('admin.voyages.referentiels.onglets.compagnies'),
        selected: true
    };

    /** Composant enfant liste des compagnies */
    @ViewChild(VoyagesReferentielsCompagniesComponent) compagniesComponent: VoyagesReferentielsCompagniesComponent;

    /** Composant enfant liste des cartes */
    @ViewChild(VoyagesReferentielsCartesVoyageursComponent) cartesComponent: VoyagesReferentielsCartesVoyageursComponent;

    /** Composant enfant liste des classes */
    @ViewChild(VoyagesReferentielsClassesComponent) classesComponent: VoyagesReferentielsClassesComponent;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = Onglets;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router Router de l'application
     * @param matDialog Boîte de dialogue
     */
    constructor(
        private translateService: TranslateService,
        public router: Router,
        private matDialog: MatDialog) {
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de générer la liste des différents onglets disponibles
        this.listeTabItems = [{
            code: Onglets.COMPAGNIES,
            libelle: this.translateService.instant('admin.voyages.referentiels.onglets.compagnies')
        }, {
            code: Onglets.CARTES_VOYAGEURS,
            libelle: this.translateService.instant('admin.voyages.referentiels.onglets.cartesVoyageurs')
        }, {
            code: Onglets.CLASSES,
            libelle: this.translateService.instant('admin.voyages.referentiels.onglets.classes')
        }, {
            code: Onglets.SYNCHRONISATIONS,
            libelle: this.translateService.instant('admin.voyages.referentiels.onglets.synchronisations')
        },];

        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createItem(),
        }]);
    }

    /**
     * Méthode appelée lors de la création d'un nouvel objet (Compagnie, Carte Voyageur, ...)
     */
    createItem() {
        if (this.selectedItem.libelle === this.translateService.instant('admin.voyages.referentiels.onglets.compagnies')) {
            //Ouverture de la popup de création
            this.matDialog.open(VoyagesReferentielsCompagniesInfosComponent, {
                data: {
                    idCompagnie: 0,
                },
                width: '80%'
            }).afterClosed().subscribe({
                next: refresh => {
                    if (refresh) {
                        //Rafraichissement de la liste
                        this.compagniesComponent.listeCompagnies.refresh();
                    }
                }
            });
        } else if (this.selectedItem.libelle === this.translateService.instant('admin.voyages.referentiels.onglets.cartesVoyageurs')) {
            //Ouverture de la popup de création
            this.matDialog.open(VoyagesReferentielsCartesVoyageursInfosComponent, {
                data: {
                    idCarte: 0
                },
                width: '80%'
            }).afterClosed().subscribe({
                next: refresh => {
                    if (refresh) {
                        //Rafraichissement de la liste
                        this.cartesComponent.listeCartesVoyages.refresh();
                    }
                }
            });
        } else if (this.selectedItem.libelle === this.translateService.instant('admin.voyages.referentiels.onglets.classes')) {
            //Ouverture de la popup de création
            this.matDialog.open(VoyagesReferentielsClassesInfosComponent, {
                data: {
                    idClasse: 0,
                },
                width: '80%'
            }).afterClosed().subscribe({
                next: refresh => {
                    if (refresh) {
                        //Rafraichissement de la liste
                        this.classesComponent.listeClasses.refresh();
                    }
                }
            });
        }

    }
}

/**
 * Enum pour les noms des différents onglets de la page Référentiels du menu Voyages
 */
export enum Onglets {
    COMPAGNIES = "Compagnies",
    CARTES_VOYAGEURS = "Cartes voyageurs",
    CLASSES = "Classes",
    SYNCHRONISATIONS = "Synchronisations"
}