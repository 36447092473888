import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

/**
 * Composant de rejet d'un véhicule
 */
@Component({
  host: {'data-test-id': 'vehicule-rejet'},
  templateUrl: './vehicule-rejet.component.html'
})
export class VehiculeRejetComponent {

  /** Motif pour lequel le véhicule est rejeté */
  motif: string;

  constructor(private matDialogRef: MatDialogRef<VehiculeRejetComponent>) { }

  /**
   * Ferme la popup et renvoi le motif de rejet
   */
  validate(): void {
    this.matDialogRef.close({motif: this.motif})
  }

  /**
   * Ferme la popup
   */
  close(): void {
    this.matDialogRef.close()
  }
}
