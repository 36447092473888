<h1 mat-dialog-title>
    <span translate>report.execution.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #reportForm="ngForm">
        <fieldset>
            <div class="row" *ngIf="this.report.format < 3">
                <label class="col-md-4"><span translate="report.execution.formatExport"></span></label>
                <div class="col-md-6">
                    <mat-form-field class="d-flex">
                        <mat-select name="formatExport" [(ngModel)]="report.format">
                            <mat-option value="1" *ngIf="report.pdf" translate="report.execution.format.pdf"></mat-option>
                            <mat-option value="2" *ngIf="report.csv" translate="report.execution.format.csv"></mat-option>
                            <mat-option value="3" *ngIf="report.html" translate="report.execution.format.html"></mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="this.report.reportParamList?.length > 0">
                <div *ngFor="let param of report.reportParamList" [ngSwitch]="param.type">
                    <div *ngSwitchCase="1" [ngClass]="{ 'has-error' : paramInput.invalid, 'row': true}">
                        <!--Texte-->
                        <label class="col-md-4" [ngClass]="{ 'required': param.obligatoire }">
                            <span>{{param.libelle}}</span>
                            <span *ngIf="param.commentaire" [matTooltip]="param.commentaire" matTooltipPosition="left" class="material-icons">info</span>
                        </label>
                        <span *ngIf="param.commentaire" [matTooltip]="param.commentaire" matTooltipPosition="left" class="material-icons" style="align-self: center">info</span>
                        <div class="col-md-6">
                            <mat-form-field class="d-flex">
                                <input #paramInput="ngModel" [(ngModel)]="param.value" [required]="param.obligatoire" matInput type="text" [name]="param.name">
                            </mat-form-field>
                        </div>
                    </div>
                    <!--Date-->
                    <div *ngSwitchCase="2" [ngClass]="{ 'has-error' : paramInput.invalid, 'row': true}">
                        <label class="col-md-4" [ngClass]="{ 'required': param.obligatoire }">
                            <span>{{param.libelle}}</span>
                            <span *ngIf="param.commentaire" [matTooltip]="param.commentaire" matTooltipPosition="left" class="material-icons">info</span>
                        </label>
                        <div class="col-md-6">
                            <mat-form-field class="d-flex">
                                <input #paramInput="ngModel" [(ngModel)]="param.value" [required]="param.obligatoire" matInput [matDatepicker]="datePicker" [name]="param.name">
                                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <!--Number-->
                    <div *ngSwitchCase="3" [ngClass]="{ 'has-error' : paramInput.invalid, 'row': true}">
                        <label class="col-md-4" [ngClass]="{ 'required': param.obligatoire }">
                            <span>{{param.libelle}}</span>
                            <span *ngIf="param.commentaire" [matTooltip]="param.commentaire" matTooltipPosition="left" class="material-icons">info</span>
                        </label>
                        <div class="col-md-6">
                            <mat-form-field class="d-flex">
                                <input #paramInput="ngModel" [(ngModel)]="param.value" [required]="param.obligatoire" matInput type="number" [name]="param.name">
                            </mat-form-field>
                        </div>
                    </div>
                    <!--Select-->
                    <div *ngSwitchCase="4" [ngClass]="{ 'has-error' : paramInput.invalid, 'row': true}">
                        <label class="col-md-4" [ngClass]="{ 'required': param.obligatoire }">
                            <span>{{param.libelle}}</span>
                            <span *ngIf="param.commentaire" [matTooltip]="param.commentaire" matTooltipPosition="left" class="material-icons">info</span>
                        </label>
                        <div class="col-md-6">
                            <mat-form-field class="d-flex">
                                <mat-select #paramInput="ngModel" name="formatExport" [(ngModel)]="param.value" [required]="param.obligatoire">
                                    <mat-option *ngIf="!param.obligatoire" value="" translate="report.execution.noparam"></mat-option>
                                    <mat-option *ngFor="let obj of param.listeObjet" [value]="obj[0]">{{obj[1]}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!--Multi-Select-->
                    <div *ngSwitchCase="5" [ngClass]="{ 'has-error' : paramInput.invalid, 'row': true}">
                        <label class="col-md-4" [ngClass]="{ 'required': param.obligatoire }">
                            <span>{{param.libelle}}</span>
                            <span *ngIf="param.commentaire" [matTooltip]="param.commentaire" matTooltipPosition="left" class="material-icons">info</span>
                        </label>
                        <div class="col-md-6">
                            <mat-form-field class="d-flex">
                                <mat-select #paramInput="ngModel" name="formatExport" [(ngModel)]="param.value" [required]="param.obligatoire" multiple>
                                    <mat-option *ngFor="let obj of param.listeObjet" [value]="obj[0]">{{obj[1]}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="reportForm.invalid || isSaving " (click)="execute()">
        <span *ngIf="!isSaving" translate>global.actions.executer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
