import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ParticipantExterne,TypeExterne} from "@domain/participant/participant-externe";
import {NgForm} from "@angular/forms";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Composant de la popin d'édition d'un externe
 */
@Component({
	host: {'data-test-id': 'edit-externe-popin'},
	templateUrl: './edit-externe-popin.component.html'
})
export class EditExternePopinComponent implements OnInit {
	/** Externe */
	externe: ParticipantExterne;

	/** Alerte post-fusion */
	alerteFusion: Alerte;

	/** Raffraîchir la liste à la fermture de la popin */
	refreshAtClosing: boolean = false;

	/** Traitement en cours */
	isPending: boolean = false;

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/** Import des énums pour le front */
	TypeExterne: typeof TypeExterne = TypeExterne;
	NiveauAlerte: typeof NiveauAlerte = NiveauAlerte;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param userService le service de gestion des utilisateurs
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 * @param matDialogRef la référence à la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { externe: ParticipantExterne,editionPostFusion: boolean },
		private userService: EntrepriseUtilisateursService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<EditExternePopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Copie des données en entrée
		this.externe = Object.assign({},this.data.externe);

		if (this.data.editionPostFusion) {
			//Initialisation de l'alerte
			this.alerteFusion = new Alerte({
				niveau: NiveauAlerte.NO_CONTROL,
				message: this.translateService.instant('admin.entreprise.utilisateurs.externes.editExternePopin.fusionSucces')
			});
		}
	}

	/**
	 * Promotion de l'externe
	 */
	onPromote(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.userService.promouvoirExterne(this.externe.idParticipantExterne)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('admin.entreprise.utilisateurs.externes.editExternePopin.pomotionSucces'));

					//Passage du type à ADMINISTRATEUR
					this.externe.type = TypeExterne.ADMINISTRATEUR;

					//Passage du flag à true
					this.refreshAtClosing = true;
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Enregistrement de l'externe
	 */
	onSave(): void {
		//Chargement en cours
		this.isPending = true;

		//Appel au service
		this.userService.saveExterne(this.externe)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popin
					this.matDialogRef.close(true);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}
