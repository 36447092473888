import { Pipe,PipeTransform } from '@angular/core';
import { isArray } from "rxjs/internal-compatibility";
import { EngagementsItem } from "../../../../domain/od/engagements/engagements-item";

/**
 * Pipe pour filtrer les engagements des OD. Le tableau de retour conserve l'ordre du filtre
 */
@Pipe({
    name: 'filterEngagement'
})
export class OdEngagementPipe<T extends EngagementsItem> implements PipeTransform {

    /**
     * Filtre d'une liste d'engagements, et tri par type
     *
     * @param items Les engagements à filtrer
     * @param filter Les types d'engagement à conserver
     */
    transform(items: Array<T>,filter: Array<number> | number): Array<T> {
        //Vérification des données en entrée
        if (items && filter) {
            //Transformation d'un filtre unique en tableau à 1 élément
            const filters: Array<number> = isArray(filter) ? filter : [filter];

            //Filtre
            return items.filter(engagement => filters.includes(engagement.getType()))
                //Tri par ordre de filtre
                .sort((engagement1,engagement2) => filters.indexOf(engagement1.getType()) - filters.indexOf(engagement2.getType()));
        } else {
            return items;
        }
    }

}
