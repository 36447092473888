import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {Filter,TypeComparaison} from "@domain/common/list-view";
import {SearchType} from "@domain/common/list-view/sorting";
import {UserDto} from "@domain/user/user-dto";
import {UserPipeTransform} from "@share/pipe/user.pipe";

/**
 * Options de l'autocomplete de recherche d'un collaborateur
 */
export default class UserOptions implements AutocompleteOptions {
    /** Mode de recherche */
    searchType = SearchType.CONTAINS;
    
    /**
     * Affichage d'un élément
     */
    displayItem(item: UserDto): string {
        return UserPipeTransform(item);
    }

    /**
     * Filtres disponibles
     * @param translateService le service de traduction
     * @return une liste de filtre
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.prenom'),
            clef: 'prenom',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }, {
            title: translateService.instant('filter.nom'),
            clef: 'nom',
            typeComparaison: TypeComparaison[TypeComparaison.LIKE],
            isDefault: true
        }, {
            title: translateService.instant('filter.matricule'),
            clef: 'matricule',
            typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
            isDefault: true
        }];
    }

    /**
     * Récupération de l'URL
     * @param filter les filtres
     * @return une url
     */
    url(filter: any): string {
        return `/controller/User/filtreUsers`;
    }
}
