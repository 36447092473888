import {User} from "@domain/user/user";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * DTO du lien population user
 */
export class LienPopulationUser implements ListItem {
    idPopulation: number;
    idUser: number;
    typeLien: TypeLien;
    user: User;

    getKey(): number {
        return this.idPopulation;
    }
}

/**
 * Enumération type lien
 */
export enum TypeLien {
    INCLUS = 0,
    EXCLUS = 1
}