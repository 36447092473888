import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {BehaviorSubject,Observable} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {TravelhubConfigListItemComponent} from "@components/admin/voyages/travelhub/travelhub/travelhub-config-list-item/travelhub-config-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {Sorting} from '@domain/common/list-view/sorting';
import {TravelhubConfigAddComponent} from "@components/admin/voyages/travelhub/travelhub/travelhub-config-add/travelhub-config-add.component";
import {Page} from "@domain/common/http/list-result";
import {TypeCodeErreur} from "@domain/common/http/result";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ToastrService} from "ngx-toastr";

/**
 * Onglet 'TravelHub' du module d'administration 'Voyages/TravelHub' avec la liste des différentes configurations au TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@Component({
    host: {'data-test-id': 'travelhub'},
    templateUrl: './travelhub.component.html'
})
export class TravelhubComponent implements OnInit {
    /** Liste des configurations du TravelHub */
    listeTravelHub: ListView<TravelHubConfig,TravelhubConfigListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param matDialog Boite de dialogue permettant de créer un nouvel élément dans la liste
     * @param toastrService Service de notification
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     */
    constructor(private translateService: TranslateService,
                private matDialog:MatDialog,
                private toastrService: ToastrService,
                private adminTravelhubService: AdminTravelhubService
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Définition de la liste des configurations TravelHub
        this.listeTravelHub = new ListView<TravelHubConfig,TravelhubConfigListItemComponent>({
            uri: '/controller/TravelHubConfig/listeConfig',
            title: this.translateService.instant('admin.voyages.travelhub.travelhub.liste.title'),
            component: TravelhubConfigListItemComponent,
            mapResult: (result: Page<TravelHubConfig>) => {
                //Transformation des résultats en instance
                result.listeResultats = result.listeResultats.map(item => {
                    const config = new TravelHubConfig(item);
                    //Définition d'un masque pour le champ mdp
                    config.password = "*****";
                    return config;
                });
                return result;
            },
            defaultOrder: 'libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.voyages.travelhub.travelhub.libelle'),
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'codeClient',
                    title: this.translateService.instant('admin.voyages.travelhub.travelhub.codeClient'),
                    isDefault: true,
                    type: TypeFilter[TypeFilter.STRING],
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }
            ],
            extraOptions: {
                parent: this
            }
        });

        //Définition des colonnes de tri
        this.listeTravelHub.columns = [
            { key: 'libelle', title: 'admin.voyages.travelhub.travelhub.libelle' },
            { key: 'codeClient', title: 'admin.voyages.travelhub.travelhub.codeClient' },
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeTravelHub.sorting = new Sorting(this.listeTravelHub.columns, "libelle");

        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.createConfig()
        }]);
    }

    /**
     * Ouvre la popup de création / modification d'une configuration
     *
     * @param config La configuration à éditer
     * @param configSource Uniquement dans le cas d'une copie, configuration d'origine
     */
    openConfig(config: TravelHubConfig,configSource?: TravelHubConfig): void {
        //Ouverture de la popup
        this.matDialog.open<TravelhubConfigAddComponent,any,boolean>(TravelhubConfigAddComponent, {
            width: '40%',
            data: {
                config: new TravelHubConfig(config),
                configSource: configSource,
            }
        }).afterClosed().subscribe(refresh => {
            if (refresh) {
                //On met à jour la liste affichée
                this.listeTravelHub.refresh();
            }
        });
    }

    /**
     * Ouvre la popup de création
     */
    private createConfig(): void {
        this.openConfig(null);
    }

    /**
     * Ouvre la popup de création avec les champs pré-remplis à partir d'une configuration existante
     *
     * @param configSource La configuration à copîer
     */
    copyConfig(configSource: TravelHubConfig): void {
        //Création d'une copie
        const config = new TravelHubConfig(configSource);

        //Initialisation de la copie
        config.idConfig = 0;
        config.libelle = config.libelle + this.translateService.instant('admin.voyages.travelhub.travelhub.suffixeCopie');

        //Ouverture de la copie pour enregistrement
        this.openConfig(config,configSource);
    }

    /**
     * Suppression d'un paramètre de connexion
     *
     * @param config La configuration à supprimer
     */
    deleteConfig(config: TravelHubConfig): Observable<boolean> {
        return this.adminTravelhubService.deleteConfig(config.idConfig).map(result => {
            //Vérification du résultat
            if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Message de succès
                this.toastrService.success(this.translateService.instant('global.success.suppression'));

                //Rafraichissement de la liste
                this.listeTravelHub.refresh();

                return true;
            } else {
                //Message d'erreur
                TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);

                return false;
            }
        },() => {
            //Message d'erreur
            TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);

            return false;
        });
    }

}