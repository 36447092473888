<form #form="ngForm" *ngIf="profilAlertesConfig">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.utilisateurs.parametres.profilAlertesConfig.infosObligatoires.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="pieceIdentite"
                        name="pieceIdentite"
                        customType="checkbox"
                        [ngModel]="profilAlertesConfig.pieceIdentiteObligatoire"
                        [(customModel)]="profilAlertesConfig.pieceIdentiteObligatoire"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.infosObligatoires.pieceIdentite"
                        postLibelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.infosObligatoires.pieceIdentiteTooltip">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="contactUrgence"
                        name="contactUrgence"
                        customType="checkbox"
                        [ngModel]="profilAlertesConfig.contactUrgenceObligatoire"
                        [(customModel)]="profilAlertesConfig.contactUrgenceObligatoire"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.infosObligatoires.contactUrgence"
                        postLibelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.infosObligatoires.contactUrgenceTooltip">
                </custom-input>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="alerteEcheance"
                        name="alerteEcheance"
                        customType="checkbox"
                        [ngModel]="profilAlertesConfig.alerteEcheanceActif"
                        [(customModel)]="profilAlertesConfig.alerteEcheanceActif"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.activation"
                        postLibelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.alerteEcheanceTooltip">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="delaiAlerteEcheance"
                        name="delaiAlerteEcheance"
                        customType="number"
                        [(ngModel)]="profilAlertesConfig.delaiAlerteEcheance"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.delaiAlerteEcheance"
                        [disabled]="!profilAlertesConfig.alerteEcheanceActif">
                </custom-input>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <custom-input
                        ngDefaultControl
                        id="relanceEcheance"
                        name="relanceEcheance"
                        customType="checkbox"
                        [ngModel]="profilAlertesConfig.relanceEcheanceActif"
                        [(customModel)]="profilAlertesConfig.relanceEcheanceActif"
                        postLibelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.relanceTooltip">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="listeFormulaireEcheance"
                        name="listeFormulaireEcheance"
                        customType="multiselect"
                        [(ngModel)]="listeFormulaireEcheanceSelected"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.typeDocRelance"
                        [selectOptions]="listeFormulaires"
                        optionValue="code"
                        optionDisplay="libelle"
                        [disabled]="!profilAlertesConfig.relanceEcheanceActif"
                        (ngModelChange)="onTypeDocEcheanceChange()">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="messageEcheance"
                        name="messageEcheance"
                        customType="select"
                        [(ngModel)]="profilAlertesConfig.idMessageEcheance"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.emailTransmettre"
                        [selectOptions]="listeMessages"
                        optionValue="idMessage"
                        optionDisplay="titre"
                        [disabled]="!profilAlertesConfig.relanceEcheanceActif"
                        [required]="profilAlertesConfig.relanceEcheanceActif">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="delaiRelanceEcheance"
                        name="delaiRelanceEcheance"
                        customType="number"
                        [(ngModel)]="profilAlertesConfig.delaiRelanceEcheance"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.delaiRelanceEcheance"
                        [disabled]="!profilAlertesConfig.relanceEcheanceActif">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="nbRelance"
                        name="nbRelance"
                        customType="number"
                        [(ngModel)]="profilAlertesConfig.nbRelance"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.nbRelance"
                        [disabled]="!profilAlertesConfig.relanceEcheanceActif">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="alerteEchu"
                        name="alerteEchu"
                        customType="checkbox"
                        [ngModel]="profilAlertesConfig.alerteEchuActif"
                        [(customModel)]="profilAlertesConfig.alerteEchuActif"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.activation"
                        postLibelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.alerteEchuTooltip">
                </custom-input>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <custom-input
                        ngDefaultControl
                        id="relanceEchu"
                        name="relanceEchu"
                        customType="checkbox"
                        [ngModel]="profilAlertesConfig.relanceEchuActif"
                        [(customModel)]="profilAlertesConfig.relanceEchuActif"
                        postLibelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.relanceEchuTooltip">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="listeFormulaireEchu"
                        name="listeFormulaireEchu"
                        customType="multiselect"
                        [(ngModel)]="listeFormulaireEchuSelected"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.typeDocRelance"
                        [selectOptions]="listeFormulaires"
                        optionValue="code"
                        optionDisplay="libelle"
                        [disabled]="!profilAlertesConfig.relanceEchuActif"
                        (ngModelChange)="onTypeDocEchuChange()">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="messageEchu"
                        name="messageEchu"
                        customType="select"
                        [(ngModel)]="profilAlertesConfig.idMessageEchu"
                        libelle="admin.entreprise.utilisateurs.parametres.profilAlertesConfig.alertesProfil.emailTransmettre"
                        [selectOptions]="listeMessages"
                        optionValue="idMessage"
                        optionDisplay="titre"
                        [disabled]="!profilAlertesConfig.relanceEchuActif"
                        [required]="profilAlertesConfig.relanceEchuActif">
                </custom-input>
            </div>
        </mat-card-content>
    </mat-card>
</form>
