import {ItemHierarchique,ItemHierarchiqueDetailType} from "@share/component/affichage-hierarchique/ItemHierarchique";

/** classe d'un arbre hiérarchique */
export class ArbreHierarchique {

	/** Clé de traduction du titre de l'arbre */
	arbreTitleKey: string;

	/** Indique si l'affichage de l'arbre est replié ou non */
	folded: boolean = true;

	/** Liste des items formant le détail de l'arbre */
	private listeComposantsDetail: Array<ItemHierarchiqueDetailType> = [];

	/** Liste des inputs pour les items de détail de l'arbre */
	private listeInputsComposantsDetail: Array<any> = [];

	/** Liste des branches à afficher */
	listeBranches: Array<ItemHierarchique>;

	/**
	 * Constructeur
	 *
	 * @param libelle				Libellé de l'arbre
	 * @param listeBranches			Liste des branches de l'arbre
	 * @param listeComposantsDetail	Liste des items formant le détail de l'arbre
	 */
	constructor(libelle: string,listeBranches: Array<ItemHierarchique>,listeComposantsDetail?: Array<ItemHierarchiqueDetailType>) {
		//Initialisation de l'arbre hiérarchique
		this.arbreTitleKey = libelle
		this.listeBranches = listeBranches;
		this.listeComposantsDetail = listeComposantsDetail;
		this.listeInputsComposantsDetail = [];

		//S'il y a des composants pour le détail de l'arbre (normalement oui)
		if (this.listeComposantsDetail && Object.keys(this.listeComposantsDetail).length > 0) {
			//On parcourt la liste des composants par clé
			for (let cle of Object.keys(this.listeComposantsDetail)) {
				//On ajoute l'arbre hiérarchique en input de tous les composants de détail au cas où il soit nécessaire
				this.listeInputsComposantsDetail[cle] = {...this.listeInputsComposantsDetail[cle],element: this};
			}
		}
	}

	/**
	 * Vérifie le status de "cochage" de tous les éléments de l'arbre
	 *
	 * @returns {CheckStatus}
	 */
	checkStatus(): CheckStatus {
		//On vérifie combien d'éléments de l'arbre sont cochés
		let nbChecked = this.listeBranches.filter(branche => branche.checked || branche.disabled).length;

		//Si aucun élément n'est coché
		if (nbChecked <= 0) {
			return CheckStatus.AUCUN;
		} else if (nbChecked < this.listeBranches.length) {
			//S'il y a des éléments cochés, mais pas tous
			return CheckStatus.PARTIEL;
		} else {
			//Si tous les éléments sont cochés
			return CheckStatus.TOUS;
		}
	}
}

/** Enum des différents états de cochage possible pour un arbre hiérarchique */
export enum CheckStatus {
	AUCUN,
	PARTIEL,
	TOUS
}