import {Component, Input} from '@angular/core';
import {TypeEntiteParamOD} from "../../../../../domain/typeentite/typeEntiteParam";
import {FaitGenerateur} from "../../../../../domain/om/fait-generateur";
import {Od} from "../../../../../domain/od/od";

/**
 * Cadre des faits générateurs dans l'onglet des généralités d'un OD
 *
 * @author Laurent SCIMIA
 * @date 26/01/2022
 */
@Component({
	selector: 'od-generalite-fait-generateur',
	templateUrl: './od-generalite-fait-generateur.component.html'
})
export class OdGeneraliteFaitGenerateurComponent {
	/** Indicateur de mise à jour possible */
	@Input() canModifier: boolean;

    /** Ordre de mission */
    @Input() od: Od;

    /** Paramètres du type entité **/
    @Input() typeEntiteParam: TypeEntiteParamOD = null;

    /** Liste des faits générateurs */
    @Input() listeFaitGenerateurs: FaitGenerateur[];
}
