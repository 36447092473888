<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">{{ data.nom }} {{ data.prenom }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.matricule">
                <strong [translate]="'admin.entreprise.analytique.organigramme.listeUtilisateurs.matricule'"></strong>
                <span>{{ data.matricule }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.listeUtilisateurs.identifiant'"></strong>
                <span>{{ data.nomAcces }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.listeUtilisateurs.service'"></strong>
                <span>{{ data.orga.libelle }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.listeUtilisateurs.profils'"></strong>
                <span>{{ profils }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.listeUtilisateurs.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non' ) | translate }}</span>
            </li>
        </ul>
    </div>
</div>
