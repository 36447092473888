<h1 mat-dialog-title>
    <span translate>od.engagements.indemnites.historique.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let histo of listeHistoriqueIJ">
                <div class="lvi-content">
                    <div class="avatar"><i class="nio align-middle icon-frais_previsionnel_2"></i></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="">{{ histo.dateMaj ? histo.printDate(this.user?.locale) : ('od.engagements.indemnites.historique.montantInitial' | translate) }}</a>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong>{{ 'od.engagements.indemnites.historique.montant' | translate }}</strong>{{ histo.nouveauMontantEntrep | montant:deviseEntreprise }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
