<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.niveauAlerte == 1,'danger': data.niveauAlerte == 2 }">
        <i [ngClass]="getIconeClass(data.idPortee)"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'workflow.conditions.alertes.liste.objet'"></strong>{{ 'portee.' + data.idPortee | translate }}</li>
            <li><strong [translate]="'workflow.conditions.alertes.liste.niveau'"></strong>{{ 'workflow.alerte.niveau.' + data.niveauAlerte | translate }}</li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="onDelete()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>