import {Component,Input,OnDestroy,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {TerritoirePerimetreItem} from "@domain/admin/bibliotheque/geographie/territoirePerimetreItem";
import {TerritoirePerimetreItemComponent} from "@components/admin/bibliotheque/geographie/territoire/details/territoire-perimetre/territoire-perimetre-item/territoire-perimetre-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@domain/common/http/list-result";
import {MatDialog} from "@angular/material/dialog";
import {TerritoirePerimetreAddComponent} from "@components/admin/bibliotheque/geographie/territoire/details/territoire-perimetre/territoire-perimetre-add/territoire-perimetre-add.component";
import {TypeGeoLocalisation} from "@domain/geographie/geoLocalisation";
import {Subscription} from "rxjs";
import {Sorting} from '@domain/common/list-view/sorting';
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant de la liste des périmètres d'un territoire
 *
 * @author Angeline Ha
 * @date 02/11/2023
 */
@Component({
	host: {'data-test-id': 'territoire-perimetre'},
	selector: 'territoire-perimetre',
	templateUrl: './territoire-perimetre.component.html'
})
export class TerritoirePerimetreComponent implements OnInit,OnDestroy {

	/** Identifiant du territoire */
	@Input() idTerritoire: number;

	/** La liste des périmètres */
	listePerimetres: ListView<TerritoirePerimetreItem,TerritoirePerimetreItemComponent>;

	/** Souscription à l'indicateur de refresh de la liste */
	refreshSub: Subscription;

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param matDialog			Boîte de dialogue
	 * @param geographieService	Service des territoires
	 */
	constructor(private translateService: TranslateService,
				private matDialog: MatDialog,
				private geographieService: GeographieService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Définition de la liste des périmètres
		this.listePerimetres = new ListView<TerritoirePerimetreItem,TerritoirePerimetreItemComponent>({
			uri: `/controller/Territoire/${this.idTerritoire}/getListeGeolocalisations`,
			title: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.title'),
			component: TerritoirePerimetreItemComponent,
			listeFilters: [{
				clef: 'type',
				title: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.type'),
				isDefault: false,
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				listeValues: [{
					code: TypeGeoLocalisation.ZONE.toString(),
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.zone')
				},{
					code: TypeGeoLocalisation.VILLE.toString(),
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.ville')
				},{
					code: TypeGeoLocalisation.REGION.toString(),
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.region')
				},{
					code: TypeGeoLocalisation.PAYS.toString(),
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.pays')
				},{
					code: TypeGeoLocalisation.DEPARTEMENT.toString(),
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.departement')
				}]
			},{
				clef: 'libelle,*pays.libelle,*regionAdmin.libelle,*departementAdmin.libelle,*ville.libelle',
				title: this.translateService.instant('filter.libelle'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			},{
				clef: '*pays.libelle,*departementAdmin.*regionAdmin.*pays.libelle,*regionAdmin.*pays.libelle,*ville.*pays.libelle',
				title: this.translateService.instant('filter.pays'),
				isDefault: true,
				typeComparaison: TypeComparaison[TypeComparaison.LIKE]
			}],
			listeActions: [{
				icon: 'add',
				onPress: () => this.addPerimetre()
			}],
			mapResult: (result: Page<TerritoirePerimetreItem>): Page<TerritoirePerimetreItem> => {
				//Récupère les infos à afficher (libelle, hierarchie ...)
				result.listeResultats = result.listeResultats.map(g => {
					return this.recupInfosTerritoire(g);
				})
				return result;
			},
			isFilter: true
		});

		//Définition des colonnes de tri
		this.listePerimetres.columns = [
			{key: 'libelle',title: 'filter.libelle'},
			{key: 'type',title: 'filter.type'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listePerimetres.sorting = new Sorting(this.listePerimetres.columns,"libelle");

		//Refresh la liste si besoin (après suppression)
		this.refreshSub = this.geographieService.refreshListeGeoLocalisations$.subscribe(() => {
				this.listePerimetres.refresh();
		})
	}

	/**
	 * Destruction
	 */
	ngOnDestroy() {
		this.refreshSub.unsubscribe();
	}

	/**
	 * Récupère les informations du périmètre à afficher (libellé, hiérarchie, pays)
	 * @param g périmètre à modifier
	 */
	recupInfosTerritoire(g: TerritoirePerimetreItem): TerritoirePerimetreItem {
		//Hiérarchie
		g.listeHierarchie = new Array<string>();

		//Pays
		if (g.pays) {
			g.libelle = g.pays.libelle;
			g.libellePays = g.pays.libelle;
			g.codePays = g.pays.code2;
			if (g.pays.zone) {
				g.listeHierarchie.push(g.pays.zone.libelle);
			}

			//Région
		} else if (g.regionAdmin) {
			g.libelle = g.regionAdmin.libelle;
			g.libellePays = g.regionAdmin.pays.libelle;
			g.codePays = g.regionAdmin.pays.code2;

			//Département
		} else if (g.departementAdmin) {
			g.libelle = g.departementAdmin.libelle;
			g.libellePays = g.departementAdmin.regionAdmin.pays.libelle;
			g.codePays = g.departementAdmin.regionAdmin.pays.code2;
			g.listeHierarchie.push(g.departementAdmin.regionAdmin.libelle);

			//Ville
		} else if (g.ville) {
			g.libelle = g.ville.libelle;
			g.libellePays = g.ville.pays.libelle;
			g.codePays = g.ville.pays.code2;
			if (g.ville.regionAdmin) {
				g.listeHierarchie.push(g.ville.regionAdmin.libelle);
			}
			if (g.ville.departementAdmin) {
				g.listeHierarchie.push(g.ville.departementAdmin.libelle);
			}
		}

		g.hierarchie = g.listeHierarchie.join(' > ');

		return g;
	}

	/**
	 * Ajout d'un périmètre
	 */
	addPerimetre() {
		//Ouverture de la boîte de dialogue
		this.matDialog.open(TerritoirePerimetreAddComponent,{
			data: {
				idTerritoire: this.idTerritoire
			}
		}).afterClosed().subscribe({
			next: retour => {
				if (retour) {
					if (retour.success) {
						//Refresh de la liste si ajout successful
						this.listePerimetres.refresh();
					}
				}
			}
		});
	}
}
