<div class="lvi-content">
    <div class="avatar" [nSelector]="{ liste: liste,item: data }">
        <i class="material-icons">date_range</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="editPeriode()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.dateDebut">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.dateDebut'"></strong>
                <span>{{ data.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateFin">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.dateFin'"></strong>
                <span>{{ data.dateFin | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.nbObjetsOpened > 0" class="back-warning clickable" (click)="showObjetsATraiter()">
                <strong [translate]="'workflow.alerte.title'"></strong>
                <span *ngIf="data.nbObjetsOpened == 1" [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.objetsATraiter.one'"></span>
                <span *ngIf="data.nbObjetsOpened > 1" [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.objetsATraiter.plural'"
                      [translateParams]="{ count: data.nbObjetsOpened }"></span>
            </li>
        </ul>
    </div>
    <div class="info" *ngIf="data.closed">
        <mat-icon [matTooltip]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.liste.cloturee' | translate">lock</mat-icon>
    </div>
</div>
