import {Component, Input} from '@angular/core';

import {OMPTransportAddComponent} from "./omp-transport-add.component";
import {MatDialog} from "@angular/material/dialog";
import {OMPService} from "../../omp.service";
import {Transport, TypeTransport} from "../../../../domain/omp/transport";
import {Omp} from "../../../../domain/omp/omp";

@Component({
    selector: 'omp-transports',
    templateUrl: './omp-transport-list.component.html'
})
export class OMPTransportListComponent {
    /** Mission permanente */
    @Input() omp: Omp;

    /** Liste des transports associés à l'OMP */
    @Input() listeTransports: Array<Transport>;

    /** Liste des véhicules approuvés de l'utilisateur */
    @Input() listeVehicule: Array<any>;

    /** Liste de référence des conforts possibles */
    @Input() mapConfort: Array<any>;

    /** Indicateur de mise à jour possible */
    @Input() canModifier: boolean;

    /**
     * Constructeur
     */
    constructor(private ompService: OMPService,private matDialog: MatDialog) {}

    /**
     * Affichage/création d'un frais
     *
     * @param transport Le transport à afficher
     */
    showTransport(transport?: Transport): void {
        //Affichage de la popup
        this.matDialog.open(OMPTransportAddComponent,{
            data: {
                transport: Object.assign({
                    idTransport: 0,
                    idOmPermanent: this.omp.idOMPermanent,
                    type: undefined,
                    idVehicule: undefined,
                    idConfort: undefined
                },transport),
                mapConfort: this.mapConfort,
                listeVehicule: this.listeVehicule,
                canModifier: this.canModifier
            },
            minWidth: 600
        }).afterClosed().subscribe({
            next: data => {
                //Vérification du résultat
                if (data) {
                    this.listeTransports = data.listeTransports;
                }
            }
        })
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeTransport Type de transport
     */
    getAvatarForTypeTransport(typeTransport: TypeTransport): string {
        return this.ompService.getAvatarForTypeTransport(typeTransport);
    }

    /**
     * Retourne le titre d'une ligne de transport autorisé (son type, traduit)
     *
     * @param typeTransport Type de transport
     */
    getTitleForTypeTransport(typeTransport: TypeTransport): string {
        return this.ompService.translateTypeTransport(typeTransport);
    }

}