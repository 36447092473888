<div class="rule-item hoverable">
    <div class="row">
        <!-- Sélection de la donnée métier -->
        <div class="text-left" [ngClass]="detail.filter?.embeddedRule ? 'col-sm-11' : 'col-sm-4'">
            <a [ngClass]="{'bold error': !detail.filter?.filter}" (click)="onSelect(false)">
                <span *ngIf="!detail.filter?.filter" [translate]="'rule.selectDonneeMetier'"></span>
                <span *ngIf="detail.filter?.filter">{{ detail.filter.libelle }}</span>
            </a>
            <span *ngIf="detail.filter?.filter && detail.filter?.embeddedRule" [translate]="'rule.selonSousCondition'"></span>
            <!-- On empêche la validation du formulaire avec detail.filter.filter vide -->
            <input type="hidden" *ngIf="detail" [name]="key + '-filter'" [(ngModel)]="detail.filter" required>
            <input type="hidden" *ngIf="detail.filter" [name]="key + '-filter-filter'" [(ngModel)]="detail.filter.filter" required>
        </div>

        <div class="col-sm-7" *ngIf="!detail.filter?.embeddedRule">
            <div class="row">
                <!-- Select opérateur -->
                <div class="col-sm-4">
                    <div class="form-group">
                        <select class="form-control" *ngIf="!detail.filter" disabled></select>
                        <select class="form-control" *ngIf="detail.filter" [name]="key + '-operateur'" [(ngModel)]="detail.filter.operateur" (change)="onOperateurChange()" [disabled]="!detail.filter.filter" required>
                            <option *ngFor="let operateur of getListeOperateurs()" [ngValue]="operateur.code">{{ operateur.libelle }}</option>
                        </select>
                    </div>
                </div>

                <!-- Activation/désactivation de la comparaison champ/champ -->
                <div class="col-sm-1">
                    <button class="icon-button-small" *ngIf="!detail.filterCible" mat-icon-button color="primary" (click)="enableFieldComparison()" [matTooltip]="'rule.compareField' | translate">
                        <mat-icon class="material-icons-outlined">compare_arrows</mat-icon>
                    </button>
                    <button class="icon-button-small" *ngIf="detail.filterCible" mat-icon-button color="primary" (click)="disableFieldComparison()" [matTooltip]="'rule.cancel' | translate">
                        <mat-icon class="material-icons-outlined">cancel</mat-icon>
                    </button>
                </div>

                <!-- Valeur -->
                <div class="col-sm-7" *ngIf="detail.filter?.fieldType && detail.filter.operateur !== TypeRuleFilterOperator.IS_NULL && detail.filter.operateur !== TypeRuleFilterOperator.IS_NOT_NULL && !detail.filterCible">
                    <div class="form-group" [ngClass]="{ 'has-error': !detail.filter.listeValues[0].value && detail.filter.listeValues[0].numberValue === undefined && detail.filter.listeValues[0].booleanValue === undefined && detail.filter.listeValues[0].object === undefined && detail.filter.listeValues[0].fonction === undefined }">
                        <div *ngIf="detail.filter.fieldType.type === TypeFieldType.CHAINE">
                            <input class="form-control" type="text" [name]="key + '-value'" [(ngModel)]="detail.filter.listeValues[0].value" required>
                        </div>
                        <div *ngIf="detail.filter.fieldType.type === TypeFieldType.DATE">
                            <div *ngIf="detail.filter.operateur !== TypeRuleFilterOperator.ROLLING_PERIOD">
                                <div class="row">
                                    <div [ngClass]="(detail.filter.listeValues[0].fonction === TypeFonction.DATE_WEEKDAY || detail.filter.listeValues[0].fonction === TypeFonction.AUCUNE) ? 'col-sm-6' : 'col-sm-12'">
                                        <!-- Fonction date -->
                                        <div class="form-group" [ngClass]="{ 'has-error': !detail.filter.listeValues[0].fonction }">
                                            <select class="form-control" [name]="key + '-fonction'" [(ngModel)]="detail.filter.listeValues[0].fonction" (change)="onFonctionChange()" required>
                                                <option [ngValue]="undefined" [translate]="'rule.selectOption'"></option>
                                                <option *ngFor="let temporal of ruleRepository.getListeTemporalValue(detail.filter.operateur)" [ngValue]="temporal.code">{{ temporal.libelle }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <!-- Jour de la semaine -->
                                        <div class="form-group" [ngClass]="{ 'has-error': !detail.filter.listeValues[0].value }" *ngIf="detail.filter.listeValues[0].fonction === TypeFonction.DATE_WEEKDAY">
                                            <select class="form-control" [name]="key + '-weekday'" [(ngModel)]="detail.filter.listeValues[0].value" required>
                                                <option [ngValue]="undefined" [translate]="'rule.selectOption'"></option>
                                                <option *ngFor="let jour of ruleRepository.listeWeekDays" [ngValue]="jour.code">{{ jour.libelle }}</option>
                                            </select>
                                        </div>
                                        <!-- Input date -->
                                        <div class="form-group" [ngClass]="{ 'has-error': !detail.filter.listeValues[0].dateValue }" *ngIf="detail.filter.listeValues[0].fonction === TypeFonction.AUCUNE">
                                            <input class="form-control" type="date" [name]="key + '-dateValue'" [(ngModel)]="detail.filter.listeValues[0].dateValue" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="detail.filter.operateur === TypeRuleFilterOperator.ROLLING_PERIOD">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <!-- Libellé aujourd'hui -->
                                        <p>{{ ('rule.typeFonction.DATE_TODAY' | translate) + (detail.filter.listeValues[1].numberValue > 0 ? ' +' : '') }}</p>
                                    </div>
                                    <div class="col-sm-2">
                                        <!-- Input nb -->
                                        <div class="form-group" [ngClass]="{ 'has-error': !detail.filter.listeValues[1].numberValue }">
                                            <input class="form-control" type="number" step="1" [name]="key + '-rolling-period-numberValue'" [(ngModel)]="detail.filter.listeValues[1].numberValue" required>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <!-- Select temporalité -->
                                        <div class="form-group" [ngClass]="{ 'has-error': !detail.filter.listeValues[1].fonction }">
                                            <select class="form-control" [name]="key + '-rolling-period-temporal'" [(ngModel)]="detail.filter.listeValues[1].fonction" required>
                                                <option [ngValue]="undefined" [translate]="'rule.selectOption'"></option>
                                                <option *ngFor="let temporal of ruleRepository.getListeTemporalForPeriod()" [ngValue]="temporal.code">{{ temporal.libelle }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="detail.filter.fieldType.type === TypeFieldType.NUMBER">
                            <input class="form-control" type="text" [name]="key + '-numberValue'" [(ngModel)]="detail.filter.listeValues[0].numberValue" required>
                        </div>
                        <div *ngIf="detail.filter.fieldType.type === TypeFieldType.BOOLEAN">
                            <!-- On empêche la validation du formulaire avec detail.filter.listeValues[0].booleanValue vide -->
                            <input type="hidden" [name]="key + '-booleanValue'" [(ngModel)]="detail.filter.listeValues[0].booleanValue" required>
                            <div class="btn-group btn-group-sm">
                                <button class="btn" [ngClass]="detail.filter.listeValues[0].booleanValue === true ? 'btn-primary' : 'btn-light'" (click)="detail.filter.listeValues[0].booleanValue = true">
                                    <span [translate]="'confirmation.oui'"></span>
                                </button>
                                <button class="btn" [ngClass]="detail.filter.listeValues[0].booleanValue === false ? 'btn-primary' : 'btn-light'" (click)="detail.filter.listeValues[0].booleanValue = false">
                                    <span [translate]="'confirmation.non'"></span>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="detail.filter.fieldType.type === TypeFieldType.ENUM">
                            <select class="form-control" [name]="key + '-value'" [(ngModel)]="detail.filter.listeValues[0].value" required>
                                <option [ngValue]="undefined" [translate]="'rule.selectOption'"></option>
                                <option *ngFor="let value of detail.filter.fieldType.listeValues" [ngValue]="value.code">{{ value.libelle }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Sélection du filterCible -->
                <div class="col-sm-7 text-left" *ngIf="detail.filterCible">
                    <a (click)="onSelect(true)" [ngClass]="{ 'bold error': !detail.filterCible.filter }">
                        <span *ngIf="!detail.filterCible.filter" [translate]="'rule.selectDonneeMetier'"></span>
                        <span *ngIf="detail.filterCible.filter">{{ detail.filterCible.libelle }}</span>
                    </a>
                    <input type="hidden" [name]="key + '-filter-cible'" [(ngModel)]="detail.filterCible.filter" required>
                </div>

                <!-- Div vide de largeur 4 pour l'alignement -->
                <div class="col-sm-4" *ngIf="!detail.filterCible && !detail.filter?.fieldType"></div>
            </div>
        </div>

        <!-- Suppression de l'item -->
        <div class="col-sm-1 hovered-item">
            <button class="pull-right icon-button-small" mat-icon-button color="primary" (click)="onRemoveItem()" [matTooltip]="'rule.deleteLine' | translate">
                <mat-icon class="material-icons">delete</mat-icon>
            </button>
        </div>
    </div>

    <!-- Règle embarquée -->
    <div *ngIf="detail.filter?.embeddedRule && detail.filter.childEntityLoaded && detail.filter.listeValues[0].rule">
        <div class="btn-group exists-tabs">
            <button class="btn" [ngClass]="!detail.filter.notExists ? 'btn-primary' : 'btn-light'" (click)="detail.filter.notExists = false">EXISTS</button>
            <button class="btn" [ngClass]="detail.filter.notExists ? 'btn-primary' : 'btn-light'" (click)="detail.filter.notExists = true">NOT EXISTS</button>
        </div>
        <rule-detail [(rule)]="detail.filter.listeValues[0].rule" [entite]="detail.filter.childEntity.type" [isEmbeddedRule]="true"></rule-detail>
    </div>
</div>
