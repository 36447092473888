import {listeSBT} from "@domain/voyage/travel/constants";

/**
 * Contient le nom d'un SBT en fonction de son numéro
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
export interface RefSbt {
	num_sbt: number;
	libelle: string;
}

/**
 * Recherche du détail d'un SBT à partir de son numéro
 * @param numSBT Numéro du SBT
 */
export function findRefSbt(numSBT: number): RefSbt {
	return listeSBT.find(refSbt => refSbt.num_sbt === numSBT);
}