import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {AvanceListItemComponent} from "../../../avance/avance-list/avance-list-item/avance-list-item.component";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {MatDialog} from "@angular/material/dialog";

/**
 * Item de la liste des Avances du Dashboard
 */
@Component({
    selector: 'dashboard-avances-list-item',
    templateUrl: './dashboard-avances-list-item.component.html'
})
export class DashboardAvancesListItemComponent extends AvanceListItemComponent {

    /**
     * Constructeur
     *
     * @param router le routeur
     * @param store le store
     * @param matDialog le composant des popups
     */
    constructor(protected router: Router,
                protected store: Store<AppState>,
                protected matDialog: MatDialog) {
        super(router, store, matDialog);
    }
}
