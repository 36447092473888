<mat-card>
    <mat-card-title>
        <span [translate]="'profilUser.donneesConnexion.changementMdp.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <form autocomplete="off" #changePasswordForm="ngForm" (submit)="onSubmit(changePasswordForm)">
            <fieldset [disabled]="isLoading">
                <div class="row" *ngIf="step == 'CURRENT_PASSWORD'">
                    <label for="oldpassword" class="col-md-2 required" style="height: 60px">
                        <span [translate]="'login.passwordChange.currentPassword'"></span>
                    </label>
                    <div class="col-md-4">
                        <mat-form-field class="d-flex">
                            <input matInput type="password" id="oldpassword" name="oldpassword" autocomplete="new-password" #currentPassword="ngModel" [(ngModel)]="currentUserPassword" autofocus  required >
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="step == 'NEW_PASSWORD'">
                    <label for="newPassword" class="col-md-2 required" style="height: 60px">
                        <span [translate]="'login.passwordChange.newPassword'"></span>
                    </label>
                    <div class="col-md-4">
                        <mat-form-field class="d-flex mb-10">
                            <input matInput type="password" id="newPassword" name="newPassword" autocomplete="new-password" #newPassword="ngModel" [(ngModel)]="passwordChange.newPassword" [pattern]="RGX_FULL_PASSWORD" required>
                            <mat-error *ngIf="newPassword.invalid">
                                <span [translate]="'login.passwordChange.rule.passwordRequired'"></span>
                            </mat-error>
                            <mat-error *ngIf="passwordChange.passwordParams?.minLength && passwordChange?.newPassword?.match(RGX_MIN_LENGTH) == null">
                                <span class="ml-3">{{ 'login.passwordChange.rule.caracteres' | translate:{ number: passwordChange.passwordParams?.minLength || 0 } }}</span>
                            </mat-error>
                            <mat-error *ngIf="passwordChange.passwordParams?.minMajuscule && passwordChange?.newPassword?.match(RGX_MIN_MAJUSCULE) == null">
                                <span class="ml-3">{{ 'login.passwordChange.rule.majuscules' | translate:{ number: passwordChange.passwordParams?.minMajuscule || 0 } }}</span>
                            </mat-error>
                            <mat-error *ngIf="passwordChange.passwordParams?.minMinuscule && passwordChange?.newPassword?.match(RGX_MIN_MINUSCULE) == null">
                                <span class="ml-3">{{ 'login.passwordChange.rule.minuscules' | translate:{ number: passwordChange.passwordParams?.minMinuscule || 0 } }}</span>
                            </mat-error>
                            <mat-error *ngIf="passwordChange.passwordParams?.minChiffre && passwordChange?.newPassword?.match(RGX_MIN_CHIFFRE) == null">
                                <span class="ml-3">{{ 'login.passwordChange.rule.chiffres' | translate:{ number: passwordChange.passwordParams?.minChiffre || 0 } }}</span>
                            </mat-error>
                            <mat-error *ngIf="passwordChange.passwordParams?.minSpecial && passwordChange?.newPassword?.match(RGX_MIN_SPECIAL) == null">
                                <span class="ml-3">{{ 'login.passwordChange.rule.special' | translate:{ number: passwordChange.passwordParams?.minSpecial || 0 } }}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <label for="confirmPassword" class="col-md-2" style="height: 60px">
                        <span [translate]="'login.passwordChange.confirmPassword'"></span>
                    </label>
                    <div class="col-md-4">
                        <mat-form-field class="d-flex">
                            <input matInput type="password" id="confirmPassword" name="confirmPassword" autocomplete="new-password" #confirmPassword="ngModel" [(ngModel)]="passwordChange.confirmPassword" required>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
    <div class="text-right">
        <button *ngIf="step == 'NEW_PASSWORD'" color="secondary" class="mr-2" mat-stroked-button (click)="onCancel(changePasswordForm)">
            <span [translate]="'global.actions.annuler'"></span>
        </button>
        <button type="submit" color="primary" mat-flat-button (click)="onSubmit(changePasswordForm)" [disabled]="changePasswordForm.invalid || isLoading">
            <span [translate]="'global.actions.confirmer'"></span>
        </button>
    </div>
</mat-card>