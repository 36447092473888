import {Component, Input, OnInit} from "@angular/core";
import {ControlContainer, NgForm} from "@angular/forms";
import {Report, ReportGroup} from "@domain/reporting/report";
import {TranslateService} from "@ngx-translate/core";
import {ReportingService} from "@services/admin/reporting/reporting.service";
import {first} from "rxjs/operators";

/**
 * Composant d'affichage des généralités d'un rapport
 */
@Component({
    host: {'data-test-id': 'reporting-infos-generalites'},
    selector: 'reporting-infos-generalites',
    templateUrl: './reporting-infos-generalites.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReportingInfosGeneralitesComponent implements OnInit {
    /** Rapport sélectionné (avec setter spécifique) */
    private _report: Report;
    @Input() set report(report: Report) { setTimeout(() => { this.syncSelected(report); this._report = report;}); }
    get report(): Report { return this._report; }

    /** Booléen pour savoir si l'affichage du formulaire se fait en création ou en modification, pour le champ du code (lecture seule si on est en modification) */
    @Input() isCreation: boolean;

    /** Objet utilisé pour la sélection du groupe */
    selectedGroupe: GroupeSelector;

    /** Objet utilisé pour la sélection des formats */
    selectedFormats: Array<{ id: Format, libelle: string, readonly?: boolean}>

    /** Types édition */
    TypeEdition = TypeEdition;

    /** Refresh flag (utilisation d'un *ngIf pour forcer le rafraichissement d'un composant) */
    refresh: boolean = true;

    /** Liste des types édition possibles */
    readonly listeTypesEdition: Array<{ id: TypeEdition, libelle: string }> = [{
        id: TypeEdition.STANDARD,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.typeEdition.standard')
    }, {
        id: TypeEdition.EDITION_PERSONNALISEE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.typeEdition.1')
    }, {
        id: TypeEdition.LISTING_REGLEMENTS,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.typeEdition.2')
    }, {
        id: TypeEdition.VIREMENTS,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.typeEdition.3')
    }, {
        id: TypeEdition.LOT_COMPTABLE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.typeEdition.4')
    }];

    /** Liste des groupes possibles */
    listeGroupes: Array<GroupeSelector> = [{
        id: 0,
        groupeDefini: Groupe.AUCUN,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.0')
    }, {
        id: 1,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.groupesPredefinis'),
        isSectionTitle: true
    }, {
        id: 2,
        groupeDefini: Groupe.NOTE_DE_FRAIS,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.1'),
        isSectionItem: true
    }, {
        id: 3,
        groupeDefini: Groupe.ORDRE_MISSION,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.2'),
        isSectionItem: true
    }, {
        id: 4,
        groupeDefini: Groupe.AVANCE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.3'),
        isSectionItem: true
    }, {
        id: 5,
        groupeDefini: Groupe.FACTURE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.4'),
        isSectionItem: true
    }, {
        id: 6,
        groupeDefini: Groupe.ADMINISTRATION,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.5'),
        isSectionItem: true
    }];

    /** Liste des modules possibles */
    readonly listeModules: Array<{ id: Module, libelle: string }> = [{
        id: Module.AVANCE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.module.AV')
    }, {
        id: Module.FACTURE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.module.FC')
    }, {
        id: Module.NOTE_FRAIS,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.module.NF')
    }, {
        id: Module.ORDRE_MISSION,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.module.OD')
    }, {
        id: Module.ORDRE_MISSION_PERMANENT,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.module.OT')
    }];

    /** Liste des formats possibles */
    readonly listeFormats: Array<{ id: Format, libelle: string, readonly?: boolean }> = [{
        id: Format.EXCEL,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.format.excel')
    }, {
        id: Format.CSV,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.format.csv')
    }, {
        id: Format.PDF,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.format.pdf')
    }, {
        id: Format.HUGE_FILE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.format.hugeFile')
    }];

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,private reportingService: ReportingService) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Groupe par défaut
        this.selectedGroupe = this.listeGroupes[0];

        //Si c'est une création
        if (this.isCreation) {
            //Récupération de la liste des groupes custom
            this.reportingService.getListeGroupes().pipe(first()).subscribe(data => {
                //S'il y a des groupes custom
                if (data?.data?.listeGroupes) {
                    //Peuplement de la liste de groupes custom
                    this.populateGroupesCustom(data.data.listeGroupes);
                }
            });
        }
    }

    /**
     * Peuplement de la liste de groupes custom
     *
     * @param data données brutes des groupes custom
     */
    populateGroupesCustom(data: any): void {
        //Reset de la liste
        this.listeGroupes.splice(7);

        //S'il y a des données
        if (data) {
            //Casting des données
            const listeGroupesCustom: ReportGroup[] = data as ReportGroup[];

            //Si le tableau n'est pas vide
            if (listeGroupesCustom.length) {
                //Ajout d'une section custom dans la liste
                this.listeGroupes.push({
                    id: 7,
                    libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.infosCard.groupe.groupesPerso'),
                    isSectionTitle: true
                });

                //Index
                let index: number = this.listeGroupes.length - 1;

                //Boucle sur les groupes custom
                for (const groupe of listeGroupesCustom) {
                    //Ajout du groupe dans la liste
                    this.listeGroupes.push({
                        id: ++index,
                        idGroupe: groupe.idGroupe,
                        libelle: groupe.libelle,
                        isSectionItem: true
                    });
                }
            }
        }
    }

    /**
     * Synchronise les données
     *
     * @param   report rapport
     */
    syncSelected(report: Report): void {
        //Peuplement de la liste de groupes custom
        this.populateGroupesCustom(report.listeGroupesCustom);

        //Bascule du flag pour le forçage du rafraichissement
        this.refresh = false;

        //Recherche du groupe correspondant
        this.selectedGroupe = this.listeGroupes.find(g => {
            //Si un groupe custom est fourni
            if (report.idGroupe) {
                //Recherche du groupe custom avec l'ID concerné
                return g.idGroupe == report.idGroupe;
            } else if (report.groupeDefini) {
                //Sinon recherche du groupe défini
                return g.groupeDefini == report.groupeDefini;
            } else {
                //Sinon retour dès la première valeur
                return true;
            }
        });

        //Bascule du flag pour le forçage du rafraichissement
        this.refresh = true;

        //Initialisation des formats
        this.selectedFormats = [];

        //Parcours des formats
        for (const format of this.listeFormats) {
            switch (format.id) {
                //Sélection des formats
                case Format.EXCEL:
                    if (report.excel) { this.selectedFormats.push(format); }
                    break;
                case Format.CSV:
                    if (report.csv) { this.selectedFormats.push(format); }
                    break;
                case Format.PDF:
                    if (report.pdf) { this.selectedFormats.push(format); }
                    break;
                case Format.HUGE_FILE:
                    if (report.hugeFile) { this.selectedFormats.push(format); }
                    break;
            }
        }

        //Synchro formats
        this.onFormatChange();
    }

    /**
     * Changement du type édition
     */
    onTypeEditionChange(): void {
        //Si le type est l'édition personnalisée
        if (this.report.type != TypeEdition.EDITION_PERSONNALISEE) {
            //Réinitialisation de la portée
            this.report.idPortee = undefined;
        }
    }

    /**
     * Changement du groupe
     */
    onGroupeChange(): void {
        //Si le groupe est prédéfini
        if (this.selectedGroupe.groupeDefini) {
            this.report.groupeCustom = undefined;
            this.report.idGroupe = undefined;
            this.report.groupeDefini = this.selectedGroupe.groupeDefini;
            this.report.libelleGroupe = this.selectedGroupe.libelle;
        } else if (this.selectedGroupe.idGroupe) {
            //Sinon si le groupe est custom
            this.report.groupeCustom = this.selectedGroupe.libelle;
            this.report.idGroupe = this.selectedGroupe.idGroupe;
            this.report.groupeDefini = undefined;
            this.report.libelleGroupe = this.selectedGroupe.libelle;
        } else {
            //Sinon c'est une création custom
            this.report.groupeCustom = this.selectedGroupe.libelle;
            this.report.idGroupe = 0;
            this.report.groupeDefini = undefined;
            this.report.libelleGroupe = this.selectedGroupe.libelle;
        }
    }

    /**
     * Changement des formats
     */
    onFormatChange(): void {
        //Initialisation
        let excel;
        let csv;
        let pdf;
        let hugeFile;
        const EXCEL_INDEX = 0, CSV_INDEX = 1, PDF_INDEX = 2, HUGE_FILE_INDEX = 3;

        //Boucle sur les formats
        for (const format of this.selectedFormats) {
            //Sauvegarde des formats
            switch (format.id) {
                case Format.EXCEL:
                    excel = format;
                    break;
                case Format.CSV:
                    csv = format;
                    break;
                case Format.PDF:
                    pdf = format;
                    break;
                case Format.HUGE_FILE:
                    hugeFile = format;
                    break;
            }
        }

        //Si le format Excel est sélectionné
        if (excel) {
            //Libération du fichier volumineux
            this.listeFormats[HUGE_FILE_INDEX].readonly = undefined;

            //Blocage de CSV et PDF
            this.listeFormats[CSV_INDEX].readonly = true;
            this.listeFormats[PDF_INDEX].readonly = true;
        } else if (!csv && !pdf) {
            //Déblocage de CSV et PDF
            this.selectedFormats = [];
            this.listeFormats[CSV_INDEX].readonly = undefined;
            this.listeFormats[PDF_INDEX].readonly = undefined;

            //Blocage du fichier volumineux
            this.listeFormats[HUGE_FILE_INDEX].readonly = true;
        }

        //Si CSV ou PDF est sélectionné
        if (csv || pdf) {
            //Blocage Excel et du fichier volumineux
            this.listeFormats[EXCEL_INDEX].readonly = true;
            this.listeFormats[HUGE_FILE_INDEX].readonly = true;
        } else if (!excel) {
            //Déblocage de Excel
            this.listeFormats[EXCEL_INDEX].readonly = undefined;
        }

        //Si le rapport est initialisé
        if (this.report) {
            //Application de la sélection
            this.report.excel = !!excel;
            this.report.csv = !!csv;
            this.report.pdf = !!pdf;
            this.report.hugeFile = !!hugeFile;
        }
    }
}

/** Enumération types éditions possibles */
enum TypeEdition {
    STANDARD = 0,
    EDITION_PERSONNALISEE = 1,
    LISTING_REGLEMENTS = 2,
    VIREMENTS = 3,
    LOT_COMPTABLE = 4
}

/** Enumération groupes possibles */
enum Groupe {
    AUCUN = 0,
    NOTE_DE_FRAIS = 1,
    ORDRE_MISSION = 2,
    AVANCE = 3,
    FACTURE = 4,
    ADMINISTRATION = 5
}

/** Interface de sélection de groupe */
interface GroupeSelector {
    id: number;
    libelle: string;
    groupeDefini?: Groupe;
    idGroupe?: number;
    isSectionTitle?: boolean;
    isSectionItem?: boolean
}

/** Enumération modules possibles */
export enum Module {
    AVANCE = "AV",
    FACTURE = "FC",
    NOTE_FRAIS = "NF",
    ORDRE_MISSION = "OD",
    ORDRE_MISSION_PERMANENT = "OT"
}

/** Enumération formats possibles */
enum Format {
    EXCEL = "EXCEL",
    CSV = "CSV",
    PDF = "PDF",
    HUGE_FILE = "HUGE_FILE"
}
