import {Component,Input} from '@angular/core';
import {AlerteComponent} from "../../../../workflow/alerte.component";
import {MatDialog} from "@angular/material/dialog";
import {NiveauAlerte} from '@domain/common/alerte/alerte';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {FactureErreurImport} from "@domain/facture/facture-erreur-import";

/**
 * Composant d'affichage d'une ligne d'anomalie (rejet d'import) dans la liste
 *
 * @author Laurent Convert
 * @date 03/07/2023
 */
@Component({
	host: {'data-test-id': 'facture-anomalie-list-item'},
	selector: 'facture-anomalie-list-item',
	templateUrl: './facture-anomalie-list-item.component.html'
})
export class FactureAnomalieListItemComponent extends ListViewItem<FactureErreurImport> {
	/** Déclaration pour accès dans le template */
	NiveauAlerte = NiveauAlerte;

	/** Options supplémentaires */
	@Input() public extraOptions: {
		openAnomalie: (factureAnomalie: FactureErreurImport) => void
	};

	/**
	 * Constructeur
	 *
	 * @param matDialog Référence à la popup elle-même
	 */
	constructor(private matDialog: MatDialog) {
		super();
	}

	/**
	 * Affichage des alertes de l'objet lié
	 */
	showListeAlertes(): void {
		//Affichage de la popup de la liste des alertes
		this.matDialog.open(AlerteComponent,{
			data: {
				alertes: this.data.listeAlertes.listeAlertes,
				translate: true
			}
		});
	}

}