import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {TypeRuleFilterOperator} from "@domain/rule/type-rule-filter-operator";
import {TypeFonction} from "@domain/rule/type-fonction";

@Injectable()
export class RuleRepository {
	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Liste des types de champs de saisie par entité
	 */
	readonly listeInputFields: EntityType = {
		'boolean': {
			type: TypeFieldType.BOOLEAN,
			listeOperators: [{
				code: TypeRuleFilterOperator.EQUAL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
			},{
				code: TypeRuleFilterOperator.NOT_EQUAL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
			}]
		},
		'double': {
			type: TypeFieldType.NUMBER,
			listeOperators: [{
				code: TypeRuleFilterOperator.EQUAL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
			},{
				code: TypeRuleFilterOperator.NOT_EQUAL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
			},{
				code: TypeRuleFilterOperator.GREATER,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
			},{
				code: TypeRuleFilterOperator.LESS,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
			},{
				code: TypeRuleFilterOperator.IS_NULL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
			},{
				code: TypeRuleFilterOperator.IS_NOT_NULL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
			}]
		},
		'int': {
			type: TypeFieldType.NUMBER,
			listeOperators: [{
				code: TypeRuleFilterOperator.EQUAL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
			},{
				code: TypeRuleFilterOperator.NOT_EQUAL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
			},{
				code: TypeRuleFilterOperator.GREATER,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
			},{
				code: TypeRuleFilterOperator.LESS,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
			},{
				code: TypeRuleFilterOperator.IS_NULL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
			},{
				code: TypeRuleFilterOperator.IS_NOT_NULL,
				libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
			}]
		},
		'java': {
			'lang': {
				'String': {
					type: TypeFieldType.CHAINE,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					},{
						code: TypeRuleFilterOperator.CONTAINS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.CONTAINS))
					},{
						code: TypeRuleFilterOperator.NOT_CONTAINS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_CONTAINS))
					},{
						code: TypeRuleFilterOperator.STARTS_WITH,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.STARTS_WITH))
					},{
						code: TypeRuleFilterOperator.NOT_STARTS_WITH,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_STARTS_WITH))
					},{
						code: TypeRuleFilterOperator.IS_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NOT_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
					}]
				},
				'Double': {
					type: TypeFieldType.NUMBER,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					},{
						code: TypeRuleFilterOperator.GREATER,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
					},{
						code: TypeRuleFilterOperator.LESS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
					},{
						code: TypeRuleFilterOperator.IS_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NOT_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
					}]
				},
				'Integer': {
					type: TypeFieldType.NUMBER,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					},{
						code: TypeRuleFilterOperator.GREATER,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
					},{
						code: TypeRuleFilterOperator.LESS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
					},{
						code: TypeRuleFilterOperator.IS_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NOT_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
					}]
				},
				'Long': {
					type: TypeFieldType.NUMBER,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					},{
						code: TypeRuleFilterOperator.GREATER,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
					},{
						code: TypeRuleFilterOperator.LESS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
					},{
						code: TypeRuleFilterOperator.IS_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NOT_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
					}]
				},
				'Boolean': {
					type: TypeFieldType.BOOLEAN,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					}]
				}
			},
			'util': {
				'Date': {
					type: TypeFieldType.DATE,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					},{
						code: TypeRuleFilterOperator.GREATER,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
					},{
						code: TypeRuleFilterOperator.GREATER_OR_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER_OR_NULL))
					},{
						code: TypeRuleFilterOperator.LESS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
					},{
						code: TypeRuleFilterOperator.LESS_OR_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS_OR_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NOT_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
					},{
						code: TypeRuleFilterOperator.ROLLING_PERIOD,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.ROLLING_PERIOD))
					}]
				},
				'GregorianCalendar': {
					type: TypeFieldType.DATE,
					listeOperators: [{
						code: TypeRuleFilterOperator.EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.EQUAL))
					},{
						code: TypeRuleFilterOperator.NOT_EQUAL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.NOT_EQUAL))
					},{
						code: TypeRuleFilterOperator.GREATER,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER))
					},{
						code: TypeRuleFilterOperator.GREATER_OR_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.GREATER_OR_NULL))
					},{
						code: TypeRuleFilterOperator.LESS,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS))
					},{
						code: TypeRuleFilterOperator.LESS_OR_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.LESS_OR_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NULL))
					},{
						code: TypeRuleFilterOperator.IS_NOT_NULL,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.IS_NOT_NULL))
					},{
						code: TypeRuleFilterOperator.ROLLING_PERIOD,
						libelle: this.translateService.instant(TypeRuleFilterOperator.getTradKey(TypeRuleFilterOperator.ROLLING_PERIOD))
					}]
				}
			}
		},
		'com': {
			'notilus': {
				'data': {
					'avance': {
						'Avance$NatureAvance': {
							type: TypeFieldType.ENUM,
							listeValues: [{
								code: 'ESP',
								libelle: this.translateService.instant('businessData.enum.natureAvance.espece')
							},{
								code: 'VIR',
								libelle: this.translateService.instant('businessData.enum.natureAvance.virement')
							}]
						}
					},
					'travelhub': {
						'connexion': {
							'TypeAiguillage': {
								type: TypeFieldType.ENUM,
								listeValues: [{
									code: 'ONLINE',
									libelle: this.translateService.instant('businessData.enum.typeAiguillage.online')
								},{
									code: 'OFFLINE',
									libelle: this.translateService.instant('businessData.enum.typeAiguillage.offline')
								},{
									code: 'INTERNE',
									libelle: this.translateService.instant('businessData.enum.typeAiguillage.interne')
								}]
							}
						}
					}
				}
			}
		}
	}

	/**
	 * Liste des choix de temporalité (filtrée par l'opérateur sélectionné)
	 *
	 * @param operator l'opérateur
	 */
	getListeTemporalValue(operator: TypeRuleFilterOperator): Array<{ code: TypeFonction,libelle: string }> {
		//Liste des temporalités possibles
		const listeTemporals: TypeFonction[] = [
			TypeFonction.DATE_TODAY,
			TypeFonction.DATE_TODAY_NOW,
			TypeFonction.DATE_WEEK,
			TypeFonction.DATE_MONTH,
			TypeFonction.DATE_QUARTER,
			TypeFonction.DATE_YEAR,
			TypeFonction.DATE_WEEKDAY,
			TypeFonction.AUCUNE
		];

		return listeTemporals.filter(fonction => {
			//Filtrage en fonction de l'opérateur
			return !operator || operator === TypeRuleFilterOperator.EQUAL || operator === TypeRuleFilterOperator.NOT_EQUAL || fonction != TypeFonction.DATE_WEEKDAY
		}).map((fonction: TypeFonction) => {
			//Construction du modèle code/libellé
			return {code: fonction,libelle: this.translateService.instant(TypeFonction.getTradKey(fonction))}
		});
	}

	/**
	 * Liste des choix de durée de période glissante
	 */
	getListeTemporalForPeriod(): Array<{ code: TypeFonction,libelle: string }> {
		//Liste des temporalités possibles
		const listeTemporals: TypeFonction[] = [
			TypeFonction.DATE_DAY,
			TypeFonction.DATE_WEEK,
			TypeFonction.DATE_MONTH,
			TypeFonction.DATE_QUARTER,
			TypeFonction.DATE_YEAR
		];

		return listeTemporals.map((fonction: TypeFonction) => {
			//Construction du modèle code/libellé
			return {code: fonction,libelle: this.translateService.instant(TypeFonction.getPeriodTradKey(fonction))}
		});
	}

	/**
	 * Liste des jours de la semaine
	 */
	readonly listeWeekDays: Array<{ code: string,libelle: string }> = [{
		code: '1',
		libelle: this.translateService.instant('rule.weekDays.lundi')
	},{
		code: '2',
		libelle: this.translateService.instant('rule.weekDays.mardi')
	},{
		code: '3',
		libelle: this.translateService.instant('rule.weekDays.mercredi')
	},{
		code: '4',
		libelle: this.translateService.instant('rule.weekDays.jeudi')
	},{
		code: '5',
		libelle: this.translateService.instant('rule.weekDays.vendredi')
	},{
		code: '6',
		libelle: this.translateService.instant('rule.weekDays.samedi')
	},{
		code: '7',
		libelle: this.translateService.instant('rule.weekDays.dimanche')
	}];
}

/**
 * Type représentant un type d'entité
 */
export type EntityType = {
	[key: string]: EntityTypeValue | EntityType
}

/**
 * Type représentant une valeur de type d'entité
 */
export type EntityTypeValue = {
	type: TypeFieldType,
	rootKey?: string,
	autocomplete?: string,
	autocompleteFilter?: string,
	listeOperators?: Array<{ code: TypeRuleFilterOperator,libelle: string }>
	listeGroupements?: Array<string>,
	listeValues?: Array<{ code: string,libelle: string }>
}

/**
 * Type de fieldType
 */
export enum TypeFieldType {
	CHAINE = "CHAINE",
	NUMBER = "NUMBER",
	DATE = "DATE",
	BOOLEAN = "BOOLEAN",
	ENUM = "ENUM"
}