import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RecapObjet} from "@domain/dashboard/recap-objet";
import {environment} from "@environments/environment";
import {Result} from "@domain/common/http/result";
import {map} from "rxjs/operators";
import {MessageAdmin} from "@domain/dashboard/message-admin";

/**
 * Service de gestion du Dashboard
 */
@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    /**
     * Constructeur
     *
     * @param http client HTTP
     */
    constructor(private http: HttpClient) { }

    /**
     * Appel back pour récupérer le contenu du cadre "Informations" du Dashboard Collaborateur
     */
    getListeMessages(): Observable<MessageAdmin[]> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/getListeMessage`,null).pipe(
            map(result => result?.data.listeMessage)
        );
    }

    /**
     * Appel back pour récupérer le contenu du cadre "Mes Objets" du Dashboard Collaborateur
     */
    getRecapObjets(): Observable<RecapObjet[]> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Dashboard/getRecapObjets`,null).pipe(
            map(result => result?.data.listeRecapsObjets)
        );
    }
}
