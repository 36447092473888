import {Component,Inject,OnDestroy,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {SettingsODState} from '@domain/settings/settings';
import {TypePrestation} from '@domain/prestation/prestation';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {AppState} from '@domain/appstate';
import {SaisieState} from '@domain/saisie/saisie';
import {FraisPrev} from "@domain/fraisprev/frais-prev";
import {Od} from "@domain/od/od";
import {ODService} from "../../../../../od.service";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {filter,finalize} from "rxjs/operators";
import {PrestTaux} from "@domain/prestation/prest-taux";
import {FraisService} from "@components/frais/frais.service";
import {TypePortee} from "@domain/workflow/workflow";
import * as moment from "moment";

/**
 * Composant d'affichage du formulaire d'ajout d'un frais prévisionnel
 */
@Component({
    templateUrl: './od-frais-previsionnel-add.component.html'
})
export class OdFraisPrevisionnelAddComponent implements OnInit,OnDestroy {
    /* Déclaration pour accès dans le template */
    TypePrestation = TypePrestation;
    TypePortee = TypePortee;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;
    
    /** Indicateur du chargement en cours */
    isLoading: boolean = false;
    
    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving ||this.isDeleting;
    }

    /** Informations de saisie */
    saisieState: SaisieState = null;

    /** Date du frais */
    maDate: moment.Moment; 

    /** Liste des souscriptions */
    private listeSouscriptions: Array<Subscription> = new Array<Subscription>();

    /**
     * Constructeur
     */
    constructor(private odService: ODService,
                private store: Store<AppState>,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private confirmService: ConfirmService,
                public fraisService: FraisService,
                private matDialogRef: MatDialogRef<any,any>,
                @Inject(MAT_DIALOG_DATA) public data: { fraisPrev: FraisPrev,od: Od,settings: SettingsODState,canModifier: boolean }) {

    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Chargement d'un frais prévisionnel existant
        if (this.data.fraisPrev?.idFraisPrev > 0) {
            //Chargement des données
            this.isLoading = true;

            //Chargement du frais prévisionnel
            this.odService.loadFraisPrev(this.data.fraisPrev?.idFraisPrev)
                .pipe(finalize(() => this.isLoading = false))
                .subscribe((fraisPrev: FraisPrev) => {
                    this.data.fraisPrev = fraisPrev;

                    //Initialisation
                    this.init();
                });
        } else {
            //Initialisation
            this.init();
        }
    }

    /**
     * Retourne le montant calculé d'une prestation de type forfait.
     * Si la prestation n'est pas de ce type, retourne undefined.
     */
    getMontantForfait(): number {
        //Vérification du type de prestation Forfait
        if (this.data.fraisPrev.prestation?.type == TypePrestation.FORFAIT) {
            //Calcul
            return ((this.fraisService.getValueAsNumber(this.data.fraisPrev.quantite) || 1)
                * (this.getTauxForPrestation() || 0));
        } else {
            return undefined;
        }
    }

    /**
     * Initialisation post-chargement de l'objet
     */
    init(): void {
        //Définition de la géographie
        this.data.fraisPrev.geographie = null;

        //Vérification du type de lieu
        if (this.data.fraisPrev.ville) {
            //Construction d'un objet géographie de type Ville
            this.data.fraisPrev.geographie = {
                id: this.data.fraisPrev.ville.idVille,
                type: TypeGeographie.VILLE,
                libelle: this.data.fraisPrev.ville.libelle,
                libelleSain: this.data.fraisPrev.ville.libelleNettoye,
                ville: this.data.fraisPrev.ville,
                regionAdmin: this.data.fraisPrev.ville.regionAdmin,
                departementAdmin: this.data.fraisPrev.ville.departementAdmin,
                pays: this.data.fraisPrev.pays
            }
        } else if (this.data.fraisPrev.pays) {
            //Construction d'un objet géographie de type Pays
            this.data.fraisPrev.geographie = {
                id: this.data.fraisPrev.pays.idPays,
                type: TypeGeographie.PAYS,
                libelle: this.data.fraisPrev.pays.libelle,
                libelleSain: this.data.fraisPrev.pays.libelleNettoye,
                pays: this.data.fraisPrev.pays
            }
        }

        //Ecoute de la saisie
        this.listeSouscriptions.push(
            //Souscription pour observer la nécéssité de mettre à jour la combo de sélection des indemnités
            this.store.select(state => state.saisie).subscribe((saisieState) => {
                this.saisieState = saisieState;

                if (this.saisieState?.tauxPrestation) {
                    let prestTaux: PrestTaux;

                    if (this.data.fraisPrev.taux) {
                        if (this.findTaux(this.data.fraisPrev.taux.idTaux) == null) {
                            this.data.fraisPrev.taux = null;
                        }
                    } else {
                        if (this.data.fraisPrev.idFraisPrev == 0) {
                            //Si on a pas de taux et qu'on est en création alors on saisie le taux par défaut
                            //Le taux par défaut n'est pas celui avec un libellé spécial
                            this.data.fraisPrev.taux = saisieState?.tauxPrestation?.allTaux?.find(value => value.libelleSpecial == null || value.libelleSpecial == '');
                        }
                    }
                }
            })
        );

        //Construction de l'objet moment à partir de la date du frais
        this.maDate = this.data.fraisPrev.date ? moment(this.data.fraisPrev.date) : null;

        //Mise à jour du taux de prestation
        this.refreshTauxPrestation();
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Suppression des souscriptions
        this.listeSouscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * Changement de prestation
     */
    onPrestationChange(prestation: any) {
        this.data.fraisPrev.taux = null;

        //Mise à jour de la quantité
        this.fraisService.onPrestationChangeUpdateQuantite(prestation,this.data.fraisPrev);

        //Rafraichissement du taux de prestation si nécessaire
        this.refreshTauxPrestation();
    }

    /**
     * Changement de géographie
     */
    onGeographieChange(geographie: any) {
        //Rafraichissement du taux de prestation si nécessaire
        this.refreshTauxPrestation();
    }

    /**
     * Changement de date
     */
    onDateChange() {
        //Mise à jour de la date sur le frais
        this.data.fraisPrev.date = this.maDate?.toDate();

        //Rafraichissement du taux de prestation si nécessaire
        this.refreshTauxPrestation();
    }

    /**
     * Enregistrement du frais prévisionnel
     */
    saveFraisPrev() {
        //Mise à jour de la ville / pays sur l'objet à partir de l'autocomplete
        this.data.fraisPrev.pays = this.data.fraisPrev.geographie.pays;
        this.data.fraisPrev.ville = this.data.fraisPrev.geographie.ville || null;

        //Dans le cas d'une prestation de type forfait le montant n'est pas saisissable mais calculé
        if (this.data.fraisPrev.prestation?.type == TypePrestation.FORFAIT) {
            this.data.fraisPrev.montant = this.getMontantForfait();
        }

        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement de la dépense
        this.odService.saveFraisPrev(this.data.fraisPrev)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe({
                next: idFraisPrev => {
                    //Vérification de l'enregistrement
                    if (idFraisPrev) {
                        //Fermeture de l'écran (on passe true pour déclencher le rechargement de la liste)
                        this.matDialogRef.close({refresh: true});
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                },
                error: () => {
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Suppression du frais prévisionnel
     */
    deleteFraisPrev() {
        //Message de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression en cours
                this.isDeleting = true;

                //Suppression
                this.odService.deleteFraisPrev(this.data.fraisPrev)
                    .pipe(finalize(() => this.isDeleting = false))
                    .subscribe({
                        next: (isSuccess) => {
                            //Vérification de la suppression
                            if (isSuccess) {
                                //Fermeture de l'écran (on passe true pour déclencher le rechargement de la liste)
                                this.matDialogRef.close({refresh: true, message: 'global.success.suppression'});
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        },
                        error: () => {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
            }
        });
    }

    /**
     * Rafraichissement du taux de prestation
     */
    refreshTauxPrestation() {
        this.fraisService.refreshTauxPrestation(
            this.data.fraisPrev.prestation,
            this.data.fraisPrev.date,
            this.data.fraisPrev.geographie?.pays,
            this.data.fraisPrev.geographie?.ville,
            null
        );
    }

    /**
     * Changement de devise
     *
     * @param change: évènement déclenché lors du choix d'une option
     */
    onDeviseChange(change: MatSelectChange) {
        //Mise à jour suite au changement de la devise
        this.fraisService.findCoursForDevise(
            change?.value,
            this.data.fraisPrev.date,
            this.data.settings.deviseEntreprise,
            1
        ).subscribe(cours => this.data.fraisPrev.cours = cours);
    }

    /**
     * Récupération du taux/montant de la prestation
     */
    getTauxForPrestation(): number {
        return this.fraisService.getTauxForPrestation(
            this.saisieState,
            this.data.settings,
            this.data.fraisPrev.devise,
            this.data.fraisPrev.cours,
            this.data.fraisPrev.taux
        );
    }

    /**
     * Retourne le taux correspondant à un id.
     *
     * @param idTaux Identifiant du taux. Si vide/undefined/null, retourne le taux qui sera utilisé "par défaut"
     */
    findTaux(idTaux?: number): PrestTaux {
        return this.fraisService.findTaux(this.saisieState,idTaux);
    }

}