import {Component} from '@angular/core';
import {MontantPipe} from "@share/pipe/montant";

import {AutocompleteSearchItemComponent} from '../autocomplete-search-item.component';
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";

/**
 * Item dans le search de l'autocomplete pour l'enveloppe
 *
 * @author Lionel GIMENEZ
 * @date 30/10/2023
 */
@Component({
    host: {'data-test-id': 'enveloppe-search-item'},
    templateUrl: './enveloppe-search-item.html',
})
export class EnveloppeSearchItemComponent extends AutocompleteSearchItemComponent<MontantDto> {
    constructor(
        public translateService: TranslateService,
        public datePipe: DatePipe,
        public montantPipe: MontantPipe) {
        super(translateService,datePipe,montantPipe);
    }

    /**
     * Retourne le montant formaté
     * @return le montant
     */
    getMontant(): string {
        return this.montantPipe.transform(this.data.montant,this.data.codeDevise);
    }
}