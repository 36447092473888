import {NgModule} from '@angular/core';

import {ShareModule} from '../../share/share.module';
import {FraisService} from "./frais.service";

@NgModule({
	imports: [ShareModule],
	declarations: [],
	exports: [],
	providers: [FraisService]
})
export class FraisModule { }