import { ListItem } from "../../../common/list-view/list-item";
import {OrgaDTO} from "@domain/od/od";
import {TypeLien} from "@domain/admin/entreprise/population/lienPopulationUser";

/**
 * DTO du lien population
 */
export class LienPopulationOrga implements ListItem {
    idPopulation: number;
    idOrga: number;
    typeLien: TypeLien;
    dependance: boolean;
    orga: OrgaDTO;

    getKey(): number {
        return this.idPopulation;
    }
}