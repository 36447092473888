import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Classe pour la manipulation d'un param�tre de reporting
 */
export class ReportParam implements ListItem {
	idReport: number;
	name: string;
	libelle: string;
	commentaire: string;
	type: ReportParamType;
	obligatoire: boolean;
	sql: string;

	getKey(): number {
		return this.idReport;
	}

	constructor(dto: any) {
		if (dto) {
			this.idReport = dto.idReport;
			this.name = dto.name;
			this.libelle = dto.libelle;
			this.commentaire = dto.commentaire;
			this.type = dto.type;
			this.obligatoire = dto.obligatoire;
			this.sql = dto.sql;
		}
	}
}

/**
 * Enum pour les diff�rents types de param�tres
 */
export enum ReportParamType {
	TYPE_TEXTE = 1,
	TYPE_DATE = 2,
	TYPE_NUMERIQUE = 3,
	TYPE_LISTE_CHOIX_UNIQUE = 4,
	TYPE_LISTE_CHOIX_MULTIPLE = 5
}
