<div class="lvi-content">
    <div class="avatar"><i class="material-icons">supervisor_account</i></div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="extraOptions.createModifyDeleg(data)">{{ data.role.libelle }} - {{ data.user.nom }} {{ data.user.prenom }} ({{ data.user.matricule }})</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'delegations.liste.dateDebut'"></strong>{{ (data.dateDebut | date: 'shortDate') || ' - ' }}</li>
            <li><strong [translate]="'delegations.liste.dateFin'"></strong>{{ (data.dateFin | date: 'shortDate') || ' - ' }}</li>
        </ul>
    </div>
</div>