import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {ListView} from "../../../../../domain/common/list-view";
import {MessageAdmin} from "../../../../../domain/dashboard/message-admin";
import {InfosListItemComponent} from "../infos-list-item.component";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Page} from "../../../../../domain/common/http/list-result";

/**
 * Liste complète des Infos (notifications)
 */
@Component({
    host: {'data-test-id': 'app-infos-list-extended'},
    templateUrl: './infos-list-extended.component.html'
})
export class InfosListExtendedComponent implements OnInit,AfterViewInit {

    /** Liste des infos */
    liste: ListView<MessageAdmin,InfosListItemComponent>;

    /** Objet utilisé pour le stockage temporaire des data de la liste reçue */
    tempData: Page<MessageAdmin>;

    /**
     * Constructeur
     *
     * @param listeBackup la liste des informations
     */
    constructor(@Inject(MAT_DIALOG_DATA) public listeBackup: ListView<MessageAdmin,InfosListItemComponent>) { }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        this.liste = this.listeBackup;

        this.tempData = this.listeBackup.data;
    }

    /**
     * Après l'initialisation des composants
     */
    ngAfterViewInit(): void {
        this.liste.data = this.tempData;
    }
}
