<h1 mat-dialog-title>
    <span>{{ ('admin.maintenance.migrations.onglets.maintenance.details.log.title' | translate) + ' ' + data?.task?.displayName }}</span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="!log"></please-wait>
    <form *ngIf="log">
        <div class="row filters">
            <custom-input ngDefaultControl
                          id="erreur"
                          name="erreur"
                          lCol="4" rCol="1"
                          [ngModel]="isError"
                          [(customModel)]="isError"
                          (ngModelChange)="updateDisplay()"
                          customType="checkbox"
                          libelle="admin.maintenance.migrations.onglets.maintenance.details.log.filtre.title"
                          postLibelle="admin.maintenance.migrations.onglets.maintenance.details.log.filtre.ERROR">
            </custom-input>
            <custom-input ngDefaultControl
                          id="debug"
                          name="debug"
                          lCol="1" rCol="1"
                          [ngModel]="isDebug"
                          [(customModel)]="isDebug"
                          (ngModelChange)="updateDisplay()"
                          customType="checkbox"
                          postLibelle="admin.maintenance.migrations.onglets.maintenance.details.log.filtre.DEBUG">
            </custom-input>
            <custom-input ngDefaultControl
                          id="info"
                          name="info"
                          lCol="1" rCol="1"
                          [ngModel]="isInfo"
                          [(customModel)]="isInfo"
                          (ngModelChange)="updateDisplay()"
                          customType="checkbox"
                          postLibelle="admin.maintenance.migrations.onglets.maintenance.details.log.filtre.INFO">
            </custom-input>
            <custom-input ngDefaultControl
                          id="warning"
                          name="warning"
                          lCol="1" rCol="1"
                          [ngModel]="isWarning"
                          [(customModel)]="isWarning"
                          (ngModelChange)="updateDisplay()"
                          customType="checkbox"
                          postLibelle="admin.maintenance.migrations.onglets.maintenance.details.log.filtre.WARN">
            </custom-input>
        </div>
        <ngx-codemirror
                class="d-flex flex-grow-1"
                name="log"
                [(ngModel)]="displayLog"
                [options]="codeMirrorOptions"
        ></ngx-codemirror>
    </form>
</div>
