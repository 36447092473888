import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
    host: {'data-test-id': 'workflow-action'},
    templateUrl: './workflow-action.component.html'
})
export class WorkflowActionComponent implements OnInit {
    /** Libellé de l'action */
    libelle?: string;

    /** Liste des motifs */
    listeMotifs?: Array<any>;

    /** Motif sélectionné */
    selectedMotif: any;

    /** Motif renseigné */
    motif: string;

    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { libelle: string,getListeMotifs: () => Observable<Array<any>>,isMotif: boolean,isMotifObligatoire: boolean,listeItems: Array<any> }) {

    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Récupération du libellé de l'action
        this.libelle = this.data.libelle;

        //Récupération de la liste des motifs disponibles
        this.data.isMotif && this.data.getListeMotifs().subscribe({
            next: listeMotifs => {
                //Mise à jour de la liste des motifs
                this.listeMotifs = listeMotifs;
            }
        });
    }

    /**
     * Changement de motif
     */
    onMotifChange({ value }) {
        //Mise à jour du motif
        this.motif = value.motif;
    }
}
