/**
 * Enum pour les noms des différents onglets de la page référentiels (Ils ont leur propre fichier pour éviter les dépendances circulaires)
 */
export enum Onglets {
	FAMILLE = "Famille",
	INDEMNITE = "Indemnite",
	LIASSE = "Liasse",
	TYPE_PRESTATION = "TypePrestation",
	RUBRIQUE = "Rubrique",
	UNITE = "Unite"
}