/**
 * Énumération des types d'opérateur de filtre de règles
 */
export enum TypeRuleFilterOperator {
	EQUAL = "EQUAL",
	NOT_EQUAL = "NOT_EQUAL",
	IN = "IN",
	NOT_IN = "NOT_IN",
	STARTS_WITH = "STARTS_WITH",
	NOT_STARTS_WITH = "NOT_STARTS_WITH",
	CONTAINS = "CONTAINS",
	NOT_CONTAINS = "NOT_CONTAINS",
	LESS = "LESS",
	LESS_OR_NULL = "LESS_OR_NULL",
	GREATER = "GREATER",
	GREATER_OR_NULL = "GREATER_OR_NULL",
	IS_NULL = "IS_NULL",
	IS_NOT_NULL = "IS_NOT_NULL",
	ROLLING_PERIOD = "ROLLING_PERIOD"
}

/**
 * Fonctions de l'énum
 */
export namespace TypeRuleFilterOperator {
	/**
	 * Renvoie la clé de traduction d'un TypeRuleFilterOperator
	 *
	 * @param operator l'opérateur
	 */
	export function getTradKey(operator: TypeRuleFilterOperator): string {
		return `rule.typeRuleFilterOperator.${operator}`;
	}
}