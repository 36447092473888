import {Component,Input} from '@angular/core';

/**
 * Composant d'affichage d'une coche pour le composant d'affichage hiérarchique
 *
 * @author Laurent SCIMIA
 * @date 11/12/2023
 */
@Component({
	host: {'data-test-id': 'filtre-detail'},
	selector: 'filtre-detail',
	templateUrl: './filtre-detail.component.html',
	styleUrls: ['./filtre-detail.component.scss']
})
export class FiltreDetailComponent {
	/** Paramètres du composant reçus depuis la factory du ComponentHolderComponent */
	@Input() input: Record<'show'|'tooltip'|'icone',any>;
}