<div class="listview-item">
    <div class="lvi-content ml-5">
        <div class="avatar" [ngClass]="NiveauAlerte.getClass(suivi.niveauAlerte)"><i class="material-icons">mail_outline</i></div>
        <div class="lvi-body">
            <div class="title">
                <a>{{ suivi.libelle }}</a>
            </div>
            <ul class="lvi-attrs">
                <li><strong [translate]="'budget.suivi.montant'"></strong><span>{{ suivi.montant | montant:suivi.devise }}</span></li>
                <li [ngClass]="NiveauAlerte.getClass(suivi.niveauAlerte,true)">
                    <strong [translate]="'budget.suivi.consomme'"></strong><span>{{ suivi.totalConsomme | montant:suivi.devise }}
                    - {{ (suivi.montant ? suivi.totalConsomme * 100 / suivi.montant : 0) | number:'1.2-2' }}%</span>
                </li>
                <li>
                    <strong>
                        {{ 'budget.suivi.objet' | translate }}
                        <mat-icon *ngIf="suivi.prevObjet" [nioTooltip]="'budget.suivi.tips.impactObjetPrev' | translate">error_outline</mat-icon>
                        <mat-icon *ngIf="suivi.reelObjet" [nioTooltip]="'budget.suivi.tips.impactObjetReel' | translate">error_outline</mat-icon>
                    </strong>
                    <span *ngIf="suivi.prevObjet">{{ suivi.prevObjet | montant:suivi.devise }} - {{ (suivi.montant ? suivi.prevObjet * 100 / suivi.montant : 0) | number:'1.2-2' }}%</span>
                    <span *ngIf="suivi.reelObjet">{{ suivi.reelObjet | montant:suivi.devise }} - {{ (suivi.montant ? suivi.reelObjet * 100 / suivi.montant : 0) | number:'1.2-2' }}%</span>
                    <span *ngIf="!suivi.prevObjet && !suivi.reelObjet">{{ 0 | montant:suivi.devise }} - {{ 0 | number:'1.2-2' }}%</span>
                </li>
            </ul>
        </div>
    </div>
</div>

