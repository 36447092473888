<list-view [liste]="liste" *ngIf="liste">
  <list-view-header>
    <mat-card-title *ngIf="title">
      <span [translate]="title"></span>
    </mat-card-title>
    <div *ngIf="canAddDocument && isBasicFilePicker"
         class="alert clickable d-flex flex-row align-items-center justify-content-center m-t-20 m-l-20 m-r-20 file-uploader"
         (click)="fileUpload.click()"
         (dragover)="onDragOver($event)"
         (drop)="onDrop($event)">
      <input type="file" class="file-input" (change)="onBasicFileChanged($event)" #fileUpload style="display:none;" [accept]="extensions">
      <div class="row">
        <div class="col-12 d-flex flex-row align-items-center justify-content-center">
          <i *ngIf="!basicFile" class="zmdi zmdi-hc-3x zmdi-download m-r-20"></i>
          <strong *ngIf="!basicFile" [translate]="uploadTitle"></strong>
          <ul *ngIf="basicFile" class="fichier">
            <li><strong translate="document.liste.fichier"></strong>{{ basicFile.name }}</li>
            <li><strong translate="document.liste.taille"></strong>{{ basicFile?.size | filesize }}</li>
          </ul>
          <button *ngIf="basicFile" mat-icon-button (click)="onDeleteBasicFile($event)">
            <mat-icon color="primary">delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <document-uploader *ngIf="canAddDocument && !isBasicFilePicker"
        [context]="context"
        [idCollaborateur]="idCollaborateur"
        [idObjet]="idObjet"
        (onDocumentUploaded)="onItemUploaded($event)"
        [settings]="settings"
        [title]="uploadTitle"
    ></document-uploader>
  </list-view-header>
</list-view>
