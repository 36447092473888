import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {AutocompleteOptions} from './autocomplete';
import {AutocompleteSearchComponent} from './autocomplete-search.component';
import * as options from './options';
import {MatDialogConfig} from "@angular/material/dialog/dialog-config";

@Injectable()
export class AutocompleteService {
    /**
     * Constructeur
     */
    constructor(private matDialog: MatDialog,private translateService: TranslateService) {}

    /**
     * Récupération des options d'autocomplete d'un type générique
     */
    public getOptionsForType(type: string): AutocompleteOptions {
        //Retour d'une instance de la classe des options de l'autocomplete
        return new options[type];
    }

    /**
     * Affichage de la recherche
     */
    showSearch({ label,options,type,filter,dialogConfig }: { label?: string,options?: AutocompleteOptions,type?: string,filter?: any,dialogConfig?:MatDialogConfig }): Observable<any> {
        //Vérification des options
        options = options || this.getOptionsForType(type);

        //Affichage de la popup
        return this.matDialog.open(AutocompleteSearchComponent,{
            ...dialogConfig,
            data: {
                title: label || this.translateService.instant('autocomplete.title'),
                options: options,
                filter: filter
            }
        }).afterClosed();
    }
}