/**
 * Classe représentant les logs d'une tâche de maintenance
 */
export class Log {
    /** Liste des lignes de log */
    private readonly logLines: LogLine[];

    /**
     * Constructeur
     */
    constructor(dto?: string[]) {
        //Si le dto est bien un tableau
        if (dto && Array.isArray(dto)) {
            let logline: LogLine;
            let niveau: LogLevel;

            //Initialisation du tableau
            this.logLines = [];

            //Itération sur le tableau
            for (const line of dto) {
                //Si le dto est de la bonne taille
                if (line && line.length > 26) {
                    //Isolation du niveau
                    const debutLigne: string = line.substring(26);

                    //Peuplement de l'objet en fonction de son niveau
                    if (debutLigne.startsWith("[DEBUG]")) {
                        niveau = LogLevel.DEBUG;
                    } else if (debutLigne.startsWith("[ERROR]")) {
                        niveau = LogLevel.ERROR;
                    } else if (debutLigne.startsWith("[INFO ]")) {
                        niveau = LogLevel.INFO;
                    } else if (debutLigne.startsWith("[WARN ]")) {
                        niveau = LogLevel.WARN;
                    }
                }

                //Ajout d'une nouvelle ligne
                this.logLines.push(new LogLine(line,niveau));
            }
        }
    }

    /**
     * Récupère le string formaté pour les logs
     *
     * @param isError       afficher les logs de niveau Error
     * @param isDebug       afficher les logs de niveau Debug
     * @param isInfo        afficher les logs de niveau Info
     * @param isWarning     afficher les logs de niveau Warning
     */
    getLog(isError: boolean, isDebug: boolean, isInfo: boolean, isWarning: boolean): string {
        //Retourne null si l'object est vide
        if (!this.logLines) { return; }

        //Initialisation de la variable retour
        let log: string = '';

        //Itération sur le tableau
        for (const line of this.logLines) {
            //Si la ligne est valide
            if (((line.logLevel === LogLevel.ERROR && isError) ||
                (line.logLevel === LogLevel.DEBUG && isDebug) ||
                (line.logLevel === LogLevel.INFO && isInfo) ||
                (line.logLevel === LogLevel.WARN && isWarning)) && line.log) {
                //Alors on l'ajoute à la valeur retour
                log += line.log + '\n';
            }
        }

        //Retour
        return log;
    }
}

/**
 * Classe représentant une ligne de log
 */
class LogLine {
    /** Niveau de log */
    logLevel: LogLevel;

    /** Valeur du log */
    log: string;

    /**
     * Constructeur
     */
    constructor(line: string,level: LogLevel) {
        this.log = line;
        this.logLevel = level;
    }

}

/**
 * Enum représentant les niveaux de log
 */
enum LogLevel {
    DEBUG = "DEBUG",
    ERROR = "ERROR",
    INFO = "INFO",
    WARN = "WARN"
}
