import {Component, Input} from "@angular/core";
import {Devise} from "@domain/settings/devise";
import {ControlContainer, NgForm} from "@angular/forms";

/**
 * Composant de gestion des avances pour une devise
 */
@Component({
    host: {'data-test-id': 'devise-gestion-avances'},
    selector: 'devise-gestion-avances',
    templateUrl: './devise-gestion-avances.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DeviseGestionAvancesComponent {
    /** Devise sélectionnée */
    @Input() devise: Devise;

    /** Indique si on accède à la page en mode création d'une devise ou en mode modification d'une devise */
    @Input() isCreation: boolean;
}