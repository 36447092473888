<h1 mat-dialog-title>
    <span [translate]="data.mode === PasswordPopinMode.ACTIVATION ? 'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.passwordPopin.activation.title' : 'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.passwordPopin.reset.title'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content *ngIf="data.mode === PasswordPopinMode.ACTIVATION">
    <p [innerHTML]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.passwordPopin.activation.text' | translate: { prenom: data.user.prenom, nom: data.user.nom, email: data.user.email }"></p>
    <p [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.passwordPopin.activation.text2'"></p>
</div>
<div mat-dialog-content *ngIf="data.mode === PasswordPopinMode.RESET_MDP">
    <p [innerHTML]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.passwordPopin.reset.text' | translate: { prenom: data.user.prenom, nom: data.user.nom, email: data.user.email }"></p>
    <p [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.passwordPopin.reset.text2'"></p>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]="false"><span [translate]="'global.actions.annuler'"></span></button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true"><span [translate]="'global.actions.confirmer'"></span></button>
</div>