<document-list #listeDocument
               [context]="ContextDocument.REPORTING"
               [canDeleteDocument]="false"
               [isSimpleDocumentList]="true"
               [isBasicFilePicker]="true"
               [frontendList]="fileList"
               [isFrontendList]="true"
               [canAddDocument]="!report.standard || !report.idReport"
               [settings]="settings"
               [customTags]="customTags"
               [title]="'admin.bibliotheque.reporting.infosReporting.files.title'"
               [uploadTitle]="'admin.bibliotheque.reporting.infosReporting.files.uploadPlaceholder'"
               [isClickable]="false">
</document-list>
