import {Component,Inject} from "@angular/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {FiltreAxeAnalytiqueDTO} from "@domain/admin/entreprise/analytique/FiltreAxeAnalytiqueDto";
import {FiltreOption} from '@domain/admin/entreprise/analytique/filtre-option';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {Sorting} from "@domain/common/list-view/sorting";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueFiltreItemComponent} from "./analytique-filtre-item/analytique-filtre-item.component";

/**
 * Composant permettant d'afficher les informations d'un filtre à ajouter au code projet
 */
@Component({
    host: {'data-test-id': 'analytique-popup-ajout-filtres'},
    selector: 'analytique-popup-ajout-filtres',
    templateUrl: './analytique-popup-ajout-filtres.component.html'
})
export class AnalytiquePopupAjoutFiltresComponent {

    /** Permet de savoir dans quel état on est : affichage des types de filtres ou alors affichage des filtres */
    showOptions: boolean = true;

    /** Option sélectionnée */
    optionSelected: FiltreOption;

    /** Liste des filtres */
    listeFiltres: ListView<FiltreAxeAnalytiqueDTO, AnalytiqueFiltreItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param matDialogRef Référence de la boîte de dialogue
     * @param data Données injectées dans la boîte de dialogue
     */
    constructor(
        private translateService: TranslateService,
        private matDialogRef: MatDialogRef<AnalytiquePopupAjoutFiltresComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {idAxe: number, title: string, options: FiltreOption[]}
    ) { }

    /**
     * Méthode appelée lors de la sélection d'un type de filtre
     *
     * @param option Type de filtre sélectionné
     */
    selectOption(option){
        //Mise à jour de l'option sélectionnée
        this.optionSelected = option;
        //Récupération des filtres correspondant au type sélectionné
        this.listeFiltres = new ListView<FiltreAxeAnalytiqueDTO, AnalytiqueFiltreItemComponent>({
            uri: `/controller/Analytique/getListeAddFiltre/${this.optionSelected.valeur}/${this.data.idAxe}`,
            title: this.optionSelected.libelleListe,
            component: AnalytiqueFiltreItemComponent,
            isFilter: true,
            defaultOrder: 'libelle',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }
            ],
            extraOptions: {
                avatar: this.optionSelected.avatar,
                selectEvent: this.selectFiltre.bind(this)
            }
        });

        //Définition des colonnes de tri
        this.listeFiltres.columns = [
            { key: 'libelle', title: 'admin.entreprise.analytique.codesProjet.libelle' }
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeFiltres.sorting = new Sorting(this.listeFiltres.columns, "libelle");

        //Passage à l'écran de visualisation des filtres disponibles
        this.showOptions = false;
    }

    /**
     * Méthode appelée lors du passage de la liste des filtres à la liste des types de filtres
     */
    onGoBack() {
        //Passage à l'écran de visualisation de la liste des types de filtres
        this.showOptions = true;
    }

    /**
     * Méthode appelée lors de la sélection d'un filtre
     * @param filtre
     */
    selectFiltre(filtre: FiltreAxeAnalytiqueDTO) {
        //Fermeture de la boîte de dialogue en renvoyant le filtre sélectionné
        this.matDialogRef.close({ ...filtre, ...{ type: this.optionSelected.valeur } });
    }
}