import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "../../../../share/component/confirmation/confirm.service";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {CarteVoyage} from "../../../../domain/profil/carteVoyage";
import {SettingsGlobalState} from "../../../../domain/settings/settings";
import {filter,first} from "rxjs/operators";
import {TypeCodeErreur} from "../../../../domain/common/http/result";
import {TypeChampsCarte} from "../../../../domain/profil/typeChampsCarte";
import {Store} from '@ngrx/store';
import {AppState} from '../../../../domain/appstate';
import {User} from '../../../../domain/user/user';
import * as moment from 'moment';

/**
 * Composant pour la page d'ajout de carte de voyage
 */
@Component({
	selector: 'profil-donnees-add-carte-voyage',
	host: {'data-test-id': 'profil-donnees-add-carte-voyage'},
	templateUrl: './profil-donnees-add-carte-voyage.component.html'
})
export class ProfilDonneesAddCarteVoyageComponent implements OnInit {
	/** Déclaration du type pour le template */
	TypeChampsCarte = TypeChampsCarte;

	/** Indicateur de sauvegarde */
	isSaving: boolean = false;

	/** Indicateur de suppression */
	isDeleting: boolean = false;

	/** La suppression a déjà été demandée */
	isDeleted: boolean = false;

	/** Indiciateur de modification */
	isReadOnly: boolean = true;

	/** Indicateur pour refresh la liste à la sortie de la popup */
	willRefreshList: boolean = false;

	/** Erreur sur la date de validité de fin */
	isShowDateValiditeFinError: boolean = false;

	/** Traductions pour les types de carte */
	listeTranslationTypeCarte = {
		FIDELITE: this.translateService.instant("profilUser.carteAbonnement.FIDELITE"),
		ABONNEMENT: this.translateService.instant("profilUser.carteAbonnement.ABONNEMENT"),
		SUPPLIER: this.translateService.instant("profilUser.carteAbonnement.SUPPLIER")
	}

	/** Liste des types de cartes pour le select */
	listeTypeCarte: Array<any> = [{
		type: 'AVION',
		libelle: this.translateService.instant("profilUser.carteAbonnement.typeCarte.AVION")
	},{
		type: 'TRAIN',
		libelle: this.translateService.instant("profilUser.carteAbonnement.typeCarte.TRAIN")
	},{
		type: 'HOTEL',
		libelle: this.translateService.instant("profilUser.carteAbonnement.typeCarte.HOTEL")
	},{
		type: 'VOITURE_DE_LOCATION',
		libelle: this.translateService.instant("profilUser.carteAbonnement.typeCarte.VOITURE_DE_LOCATION")
	},{
		type: 'DOCUMENT',
		libelle: this.translateService.instant("profilUser.carteAbonnement.typeCarte.DOCUMENT")
	}];

	typePrestation: string;

	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<ProfilDonneesAddCarteVoyageComponent>,
		private store: Store<AppState>,
		@Inject(MAT_DIALOG_DATA) public data: { carte: CarteVoyage,settings: SettingsGlobalState,idUser?: number }
	) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		this.typePrestation = this.data.carte.carteReferentiel.typePrestation;

		this.isDeleted = this.data.carte.toDelete;
		this.isReadOnly = this.isDeleted;

		//Si on est en création et qu'idUser n'est pas valorisé (car l'user session serait alors l'admin)
		if (this.data.carte.idCarte == 0 && !this.data.idUser) {
			this.store.select<User>(s => s.session?.user).pipe(first()).subscribe(user => {
				//Remplissage automatique en cas de création du nom de l'utilisateur
				this.data.carte.titulaire = user.nom + ' ' + user.prenom;
			});
		}
	}

	/**
	 * Suppression d'une carte voyage
	 */
	deleteCarte() {
		//Confirmation auprès de l'utilisateur sur la suppression d'une carte voyage
		this.confirmService.showConfirm(this.translateService.instant(this.isDeleted
			? 'profilUser.document.add.cancelDeleteConfirmation' : 'global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					this.isDeleting = true;
					this.profilService.deleteCarteVoyage(this.data.carte).pipe(first()).subscribe({
						next: result => {
							//Message d'information
							this.toastrService.success(this.translateService.instant(result.codeErreur == TypeCodeErreur.NO_ERROR ? 'global.success.suppression' : 'global.errors.suppression'));

							//Fermeture de l'écran
							this.matDialogRef.close(result.codeErreur == TypeCodeErreur.NO_ERROR);
						}
					});
				}
			});
	}

	/**
	 * Sauvegarde d'un compte bancaire
	 */
	save() {
		this.isSaving = true;

		//Si la carte référentiel n'a pas de date
		if (this.data.carte.carteReferentiel?.typeChamp != TypeChampsCarte[TypeChampsCarte.NUM_DU_AU]
			&& this.data.carte.carteReferentiel?.typeChamp != TypeChampsCarte[TypeChampsCarte.NUM_DU_AU_DE_A]) {
			//On supprime les dates déjà saisies
			this.data.carte.dateValiditeDebut = null;
			this.data.carte.dateValiditeFin = null;
		}

		this.profilService.saveCarteVoyage(this.data.carte,this.data.idUser).pipe(first()).subscribe({
			next: result => {
				//Message d'information
				this.toastrService.success(this.translateService.instant(result.codeErreur == TypeCodeErreur.NO_ERROR ? 'global.success.enregistrement' : 'global.errors.enregistrement'));

				//Fermeture de l'écran
				this.matDialogRef.close(result.codeErreur == TypeCodeErreur.NO_ERROR);
			}
		});
	}

	/**
	 * Contrôle de la validité des données saisies
	 */
	checkError() {
		const nowDate = moment();

		//Contrôle date de fin supérieur ou égale date du jour
		this.isShowDateValiditeFinError = this.data.carte.dateValiditeFin != null && nowDate.isAfter(this.data.carte.dateValiditeFin,'day');

		// Contrôle date de fin supérieure ou égale à la date date de début si on a une date de début
		if (!this.isShowDateValiditeFinError && this.data.carte.dateValiditeDebut != null && this.data.carte.dateValiditeFin != null) {
			this.isShowDateValiditeFinError = this.data.carte.dateValiditeFin < this.data.carte.dateValiditeDebut;
		}
	}

	/**
	 * Renvoie true si une erreur est levée
	 */
	isError(): boolean {
		return this.isShowDateValiditeFinError;
	}

	/**
	 * Event changement date debut
	 */
	onDateValiditeDebutChange(dateDebut: Date): void {
		this.data.carte.dateValiditeDebut = dateDebut;
		this.checkError();
	}

	/**
	 * Event changement date fin
	 */
	onDateValiditeFinChange(dateFin: Date): void {
		this.data.carte.dateValiditeFin = dateFin;
		this.checkError();
	}
}
