import { NgModule } from '@angular/core';

import { ShareModule } from '../../share/share.module';
import { AnalytiqueComponent } from './analytique.component';
import { AnalytiqueAddComponent } from './Popin/add/analytique-add.component';
import { AnalytiqueSelectComponent } from './Popin/select/analytique-select.component';
import { AnalytiquePopinComponent } from './Popin/analytique-popin.component';
import { AnalytiqueService } from '@components/analytique/analytique.service';
import { AnalytiqueItemComponent } from './Popin/select/analytique-item.component'

@NgModule({
    imports: [ShareModule],
    declarations: [
        AnalytiqueComponent,
        AnalytiqueAddComponent,
        AnalytiquePopinComponent,
        AnalytiqueSelectComponent,
        AnalytiqueItemComponent
    ],
    exports: [AnalytiqueComponent],
    providers: [AnalytiqueService]
})
export class AnalytiqueModule {}