import {Component,Input,ViewChild} from '@angular/core';
import {SettingsGlobalState} from "@domain/settings/settings";
import {Lot} from "@domain/comptabilite/lot";
import {TypeDateLotComptable} from "@domain/admin/comptabilite/optionsTransverses";
import {NgForm} from '@angular/forms';

/**
 * Composant d'affichage des généralités pour les lots comptables
 */
@Component({
    selector: 'lot-generalites',
    templateUrl: './lot-generalite.component.html',
    styleUrls: ['./lot-generalite.component.scss']
})
export class LotGeneraliteComponent {
    /** Accès à l'enum dans la vue */
    readonly TypeDateLotComptable = TypeDateLotComptable;

    /** Lot comptable */
    @Input() lot: Lot;

    /** Paramétrage */
    @Input() settings: SettingsGlobalState;

    /** Flag déterminant si l'objet est modifiable */
    @Input() canModifier: boolean;

    /** Formulaire de l'avance */
    @ViewChild('form') form: NgForm;

    /** Indique si le détail de la ligne est affiché */
    isOuvert: boolean = true;

    constructor() { }

    /**
     * Ouvre / Ferme le détail de la ligne
     */
     toggleDetail() {
        this.isOuvert = !this.isOuvert;
    }

    /**
     * Méthode appelée à la modification de la période
     */
    onPeriodeChange() {
        //Changement de la date du lot en fonction du type de date
         switch (this.settings.typeDateLot) {
             case TypeDateLotComptable.PREMIER_JOUR_PERIODE:
                 this.lot.date = this.lot.periode.dateDebut;
                 break;
             case TypeDateLotComptable.DERNIER_JOUR_PERIODE:
                 this.lot.date = this.lot.periode.dateFin;
                 break;
         }
    }
}
