import {InjectionToken} from '@angular/core';
import {Action} from '@ngrx/store';

import {Session} from '../../security/session';
import {TypePortee} from '../../workflow/workflow';
import {SettingsGlobalState} from "../../settings/settings";
import {Router} from "@angular/router";

/** Token représentant les éléments du menu **/
export const MENU_ITEMS = new InjectionToken<Array<MenuItem>>('MENU_ITEMS');

/**
 * Description d'un élément du menu
 */
export interface MenuItem {
    libelle: string;
    url?: string;
    icone?: string;
    scope?: Array<Scope>;
    isSelected?: (session: Session,typePortee: TypePortee) => boolean;
    canSee?: (router: Router, session: Session, settings: SettingsGlobalState) => boolean;
    doAction?: (dispatch: <V extends Action>(action: V) =>  void) => void;
    children?: Array<MenuItem>;
}

/**
 * Énumération des différents scopes de menu
 */
export enum Scope {
    PROFIL = 'PROFIL', //Menu du profil utilisateur en haut à droite
    MENU_USER = 'MENU', //Menu latéral gauche utilisateur
    MENU_ADMIN = 'ADMIN' // Menu latéral gauche admin
}
