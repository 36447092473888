import {Component} from '@angular/core';
import {ProfilConnexionParam} from "@domain/travelhub/profil-connexion-param";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TypeAiguillage,TypeNature} from "@domain/voyage/travel/constants";

/**
 * Composant d'affichage d'une ligne de la liste des aiguillages d'un profil de connexion TH
 *
 * @author Laurent Convert
 * @date 14/03/2024
 */
@Component({
	host: {'data-test-id': 'aiguillage-list-item'},
	templateUrl: './aiguillage-list-item.component.html'
})
export class AiguillageListItemComponent extends ListViewItem<ProfilConnexionParam> {
    /* Déclaration pour accès dans le template */
    TypeAiguillage = TypeAiguillage;
    TypeNature = TypeNature;

    /** Options supplémentaires */
    extraOptions: {
        openAiguillage: (aiguillage: ProfilConnexionParam) => void,
        deleteAiguillage: (idProfilConnexionParam: number) => void
    }

    /**
     * Ouverture de l'aiguillage
     *
     * @param aiguillage Aiguillage à afficher
     */
    openAiguillage(aiguillage: ProfilConnexionParam): void {
        this.extraOptions.openAiguillage(aiguillage);
    }

    /**
     * Suppression de l'aiguillage
     *
     * @param idProfilConnexionParam Identifiant de l'aiguillage à supprimer
     */
    deleteAiguillage(idProfilConnexionParam: number): void {
        this.extraOptions.deleteAiguillage(idProfilConnexionParam);
    }

}