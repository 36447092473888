import {NgModule} from "@angular/core";
import {Routes} from "@angular/router";
import {ShareModule} from "@share/share.module";
import {AdminEntrepriseComponent} from "./admin-entreprise.component";
import {DescriptionComponent} from '@components/admin/entreprise/description/description.component';
import {EntrepriseAnalytiqueComponent} from '@components/admin/entreprise/analytique/entreprise-analytique.component';
import {EntrepriseReferentielsComponent} from '@components/admin/entreprise/referentiels/entreprise-referentiels.component';
import {EntrepriseAnalytiqueModule,entrepriseAnalytiqueRoutes} from '@components/admin/entreprise/analytique/entreprise-analytique.module';
import {EntrepriseReferentielsModule} from '@components/admin/entreprise/referentiels/entreprise-referentiels.module';
import {EntrepriseService} from '@services/admin/entreprise/entreprise.service';
import {LicenceService} from '@services/admin/entreprise/licence.service';
import {RgpdService} from '@services/admin/entreprise/rgpd.service';
import {AnalytiqueService} from '@services/admin/entreprise/analytique.service';
import {DescriptionModule,entrepriseDescriptionRoutes} from '@components/admin/entreprise/description/description.module';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {EntreprisePopulationsComponent} from "./populations/entreprise-populations.component";
import {entreprisePopulationsRoutes,EntreprisePopulationsModule} from "./populations/entreprise-populations.module";
import {EntrepriseUtilisateursComponent} from './utilisateurs/entreprise-utilisateurs.component';
import {EntrepriseUtilisateursModule,entrepriseUtilisateursRoutes} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.module";
import {DroitAdmin} from "@core/security/droit-admin";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {EntrepriseVehiculesComponent} from './vehicules/entreprise-vehicules.component';
import {EntrepriseVehiculesModule,entrepriseVehiculesRoutes} from "@components/admin/entreprise/vehicules/entreprise-vehicules.module";

export const adminEntrepriseRoutes: Routes = [
	{
		path: 'Analytique',
		component: EntrepriseAnalytiqueComponent,
		children: entrepriseAnalytiqueRoutes
	},{
		path: 'Description',
		component: DescriptionComponent,
		canActivate: [AdminGuardProvider],
		children: entrepriseDescriptionRoutes
	},{
		path: 'Referentiels',
		component: EntrepriseReferentielsComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_USER_REFERENTIEL] }
	},{
		path: 'Populations',
		component: EntreprisePopulationsComponent,
		canActivate: [AdminGuardProvider],
		children: entreprisePopulationsRoutes
	},{
		path: 'Utilisateurs',
		component: EntrepriseUtilisateursComponent,
		canActivate: [AdminGuardProvider],
		children: entrepriseUtilisateursRoutes
	}, {
		path: 'Vehicules',
		component: EntrepriseVehiculesComponent,
		canActivate: [AdminGuardProvider],
		children: entrepriseVehiculesRoutes
	},{
		path: '',
		redirectTo: 'Utilisateurs',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Utilisateurs'
	}
];

/**
 * Module d'administration
 *
 * @author Tom JEAMMET
 * @date 23/02/2023
 */
@NgModule({
	imports: [
		ShareModule,
		EntrepriseAnalytiqueModule,
		EntreprisePopulationsModule,
		EntrepriseReferentielsModule,
		EntrepriseUtilisateursModule,
		EntrepriseVehiculesModule,
		DescriptionModule,
		PreviewAlerteModule
	],
	providers: [
		EntrepriseService,
		LicenceService,
		RgpdService,
		AnalytiqueService
	],
	declarations: [
		AdminEntrepriseComponent,
		DescriptionComponent
	],
})
export class AdminEntrepriseModule {
}
