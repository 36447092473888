<h1 mat-dialog-title>
    <span [translate]="'admin.comptabilite.exercicesPeriodes.exercice.periodes.popinDetail.title'" [translateParams]="{ libelle: data.libelle }" class="warning"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="page-header warning">
    <card-alerte [niveau]="niveauAlerte.WARNING">
        <preview-alerte [alerte]="alerte"></preview-alerte>
    </card-alerte>
</div>
<div mat-dialog-content>
    <list-view *ngIf="listeObjets" [liste]="listeObjets"></list-view>
</div>

