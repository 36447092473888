import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Result} from '../../domain/common/http/result';
import {environment} from '../../environments/environment';
import {TypePortee} from "@domain/workflow/workflow";

@Injectable()
export class ReferentielExterneService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }
	
	/**
	 * Chargement du référentiel externe
	 */
	loadReferentiel(typeReferentiel: TypePortee | 'D'| 'V',idObject: number): Observable<Result> {
		//Chargement du référentiel externe
		return this.http.post<Result>(`${environment.baseUrl}/controller/ReferentielExterne/loadReferentiel/${typeReferentiel}/${idObject}`,null);
	}

	/**
	 * Enregistrement d'une référence
	 */
	saveReference(idObject: number,reference: any): Observable<Result> {
		//Enregistrement d'une référence
		return this.http.post<Result>(`${environment.baseUrl}/controller/ReferentielExterne/saveNewReference/${idObject}`,reference);
	}

}
