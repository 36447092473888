/** Enumération des états (cf. Etat.java#EnumEtat) */
export enum EnumEtat {
	UNKNOWN = 0,
	EN_CREATION = 1,
	EN_VALIDATION = 2,
	VALIDE = 3,
	COMPTABILISE_CLOTURE = 4,
	ARCHIVE = 5,
	ANNULE = 6
}
