import {Component, Input} from '@angular/core';
import {ListViewItem} from "../../../domain/common/list-view/list-view-item";
import {Router} from "@angular/router";
import {Status, Vehicule} from "../../../domain/vehicule/vehicule";
import {TypeProfil} from "../../../domain/user/user";
import {Store} from "@ngrx/store";
import {AppState} from "../../../domain/appstate";
import {TranslateService} from "@ngx-translate/core";
import {AlertesVehicule} from "../../../domain/vehicule/alertesVehicule";
import {VehiculeListAlerteComponent} from "./vehicule-list-alerte.component";
import {MatDialog} from "@angular/material/dialog";
import {first} from "rxjs/operators";
import {VehiculeService} from "../vehicule.service";

/**
 * Composant d'affichage en liste des véhicules
 */
@Component({
    host: {'data-test-id': 'vehicule-list-item'},
    templateUrl: './vehicule-list-item.component.html'
})
export class VehiculeListItemComponent extends ListViewItem<any> {
    /** Elément courant */
    @Input() data: Vehicule;

    /** Options supplémentaires */
    @Input() extraOptions: any;

    /** Profil de l'utilisateur connecté */
    private fonction: TypeProfil;

    /** Alertes */
    alertes: AlertesVehicule;


    constructor(private router: Router,
                private translateService: TranslateService,
                private vehiculeService: VehiculeService,
                private store: Store<AppState>,
                private matDialog: MatDialog) {
        super();
    }

    async ngOnInit() {
        //Sélection du profil de l'utilisateur
        this.store.select(state => state.session.user.fonction).pipe(first()).subscribe(fonction => {
            this.fonction = fonction;
        });

        this.data = Object.assign(new Vehicule(),this.data);

        //Mise en place des alertes
        this.alertes = this.vehiculeService.buildAlertes(this.data,this.extraOptions?.settings);
    }

    /**
     * Ouverture d'un véhicule
     */
    navigateToVehicule(vehicule: Vehicule): void {
        //Navigation vers la note de frais
        this.router.navigate(['Vehicule', vehicule.idPa]);
    }


    /**
     * Indique si l'utilisateur est responsable
     */
    isResponsable(): boolean {
        return this.fonction == 2;
    }

    /**
     * Permet de récupérer la traduction du statut véhicule
     * @param status Status
     */
    getStatusLibelle(status: Status) {
        switch (status) {
            case Status.STATUT_NON_TRAITE:
                return this.translateService.instant('vehicule.statut.nonTraite');
            case Status.STATUT_VALIDE:
                return this.translateService.instant('vehicule.statut.valide');
            case Status.STATUT_REFUSE:
                return this.translateService.instant('vehicule.statut.refuse');
            case Status.STATUT_BROUILLON:
                return this.translateService.instant('vehicule.statut.brouillon');
            default:
                return "";
        }
    }

    /**
     * Permet d'afficher la liste des alertes
     */
    showListeWarning() {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(VehiculeListAlerteComponent, {
            data: {
                alertes: this.alertes,
                approbation: this.data.vehiculeApprobation
            },
        });
    }
}
