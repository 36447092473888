import {TravelHubSBTConfig} from "@domain/travel/travel-hub-sbt-config";

/**
 * Représente une prestation autorisée pour une configuration SBT
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
export class TravelHubPrestation {
	idPrestation: number;
	sbtConfig: TravelHubSBTConfig;
	libelle: string;
	actif: boolean;

	constructor(dto?: any | TravelHubPrestation) {
		if (dto) {
			Object.assign(this,dto);
		}
	}
}