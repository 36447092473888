import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from "@angular/forms";
import {DepartementAdmin} from "@domain/geographie/departementAdmin";
import {GeographieView} from "@domain/geographie/geographieView";
import {TypeGeographie} from '@domain/geographie/typeGeographie';

/**
 * Composant de l'onglet Généralités d'un département
 *
 * @author Angeline Ha
 * @date 10/11/2023
 */
@Component({
    host: {'data-test-id': 'departement-generalites'},
    selector: 'departement-generalites',
    templateUrl: './departement-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class DepartementGeneralitesComponent implements OnInit {

    /** Département */
    @Input() departement: DepartementAdmin;

    /** Modèle pour l'autocomplete de la région */
    selectedRegion: GeographieView

    /**
     * Initialisation
     */
    ngOnInit() {
        //Récupération de la région pour l'autocomplete
        this.selectedRegion = {
            id: this.departement.idRegion,
            type: TypeGeographie.REGION_ADMINISTRATIVE,
            libelle: this.departement.regionAdmin?.libelle,
            regionAdmin: this.departement.regionAdmin,
        }
    }

    /**
     * Mise à jour de l'objet de saisie avec la région sélectionnée dans l'autocomplete
     */
    onRegionChange() {
        this.departement.idRegion = this.selectedRegion.id;
    }

}
