<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.entreprise.description.entreprise' | translate" [listeItems]="listeTabItems" [niveauAlerte]="profilRestreintInfo?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)">
            <preview-alerte *ngIf="selectedItem?.code === Onglet.RGPD" [alerte]="profilRestreintInfo" [withRGPDIcon]="true" [withIcon]="false"></preview-alerte>
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.GENERALITES">
            <form #entrepForm="ngForm" *ngIf="entreprise">
                <description-generalites [entreprise]="entreprise" [licence]="licence"></description-generalites>
                <description-comptable [entreprise]="entreprise"></description-comptable>
            </form>
        </div>

        <div [hidden]="selectedItem?.code !== Onglet.LICENCE">
            <licence></licence>
        </div>

        <div [hidden]="selectedItem?.code !== Onglet.RGPD">
            <form #rgpdForm="ngForm">
                <rgpd [rgpdParams]="rgpdParams"></rgpd>
                <profils-restreints></profils-restreints>
            </form>
        </div>
    </div>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
