<div class="lvi-content">
    <document-dropper *ngIf="showUploader" [source]="'ndf-list-frais'" [settings]="extraOptions.settings"
                       [idCollaborateur]="this.extraOptions.idCollab"
                       [idObjet]="isFrais() ? this.data.idFrais : this.data.idDepense"
                       [context]="isFrais() ? ContextDocument.FRAIS : ContextDocument.NDF_V"
                       (onAllDocumentUploaded)="onAllPjUploaded($event)"
                       (onHide)="showUploader = false"
    ></document-dropper>
    <div class="avatar warning clickable" [nSelector]="{liste: liste, item: data, isNotSelectable: !this.extraOptions.canModifier}"
         [ngClass]="{'danger': data.prestation?.libelle == null || data.rejete || data.hasError || data.hasFrequenceError,
                     'warning': data.listeFraisIntegres != null &&  data.listeFraisIntegres.length > 0}">
        <i *ngIf="!data.rejete" class="nio icon-frais"></i>
        <i *ngIf="data.rejete" class="nio icon-note_de_frais_refuse" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body clickable" (click)="data.isDisplayed = !data.isDisplayed">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.showFrais(data); $event.stopPropagation();">{{ data.prestation?.libelle || ('ndf.frais.fraisCompleter' | translate) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'ndf.frais.date'"></strong>{{ data.date | date:'shortDate' }}</li>
            <li>
                <strong [translate]="'ndf.frais.montant'"></strong>
                <span *ngIf="!data.idFrais">{{ data.montant | montant:data.operation.devise.code }}</span>
                <span *ngIf="data.idFrais">{{ data.montant | montant:data.deviseCodeFrais }}</span>
                <mat-icon class="tag-info-icon" *ngIf="data.quantiteOrigine > data.quantite && !data.rejete && data.numIndemnite > 0"
                          [nioTooltip]="'ndf.frais.fraisTooltip.fraisIjDegrevement' | translate"
                >info_outline</mat-icon>
                <mat-icon *ngIf="data.abattement != null &&  data.abattement > 0" class="c-warning" [matTooltip]="'ndf.frais.abattement' | translate: {montant: data.abattement}" inline="true">
                    error_outline
                </mat-icon>
            </li>
            <li *ngIf="!data.idFrais">
                <strong [translate]="'ndf.frais.montantRemboursable'"></strong>
                {{ getMontantRemboursable() }}
                <mat-icon class="tag-info-icon" [hidden]="data.prestation.cumul === ''" [nioTooltip]="cumulTooltip" [nioTooltipPosition]="'track'">info_outline</mat-icon>
            </li>
            <li>
                <strong [translate]="'ndf.frais.quantite'"></strong>
                {{ (data.quantite | number:'1.2-2') + ' ' + ((!data.idFrais && data.prestation.unite?.libelle) || '') }}
            </li>
            <li *ngIf="data.justificatif?.numJustificatif">
                <strong [translate]="'ndf.frais.justificatif'"></strong>
                {{ 'ndf.frais.numJustificatif' | translate:{numero: data.justificatif.numJustificatif} }}
            </li>
            <li *ngIf="data.commentaire">
                <strong [translate]="'ndf.frais.remarque'"></strong>
                <mat-icon [matTooltip]="data.commentaire">comment</mat-icon>
            </li>
            <li *ngIf="data.listeDocuments != null && data.listeDocuments.length > 0">
                <strong [translate]="'ndf.frais.pieceJointes'"></strong>
                <span>{{data.listeDocuments.length}}</span>
            </li>
            <li *ngIf="data.listeFraisRejetes != null && data.listeFraisRejetes.length > 0" class="li-danger">
                <strong [translate]="'ndf.frais.fraisRejete'"></strong>
                <mat-icon [nioTooltip]="fraisRejeteTooltip" [nioTooltipPosition]="'track'">comment</mat-icon>
            </li>
            <li *ngIf="data.listeFraisIntegres != null && data.listeFraisIntegres.length > 0" class="li-warning">
                <strong [translate]="'ndf.frais.fraisIntegre'"></strong>
                <mat-icon [nioTooltip]="fraisIntegreTooltip" [nioTooltipPosition]="'track'">comment</mat-icon>
            </li>
            <!-- Affichage de la bulle d'alerte -->
            <li *ngIf="data.listeAlertes?.listeAlertes?.length > 0" (click)="showListeAlertes()" class="clickable back-danger">
                <strong [translate]="'workflow.alerte.title'"></strong>
                <!-- Si on qu'une alerte -->
                <span *ngIf="data.listeAlertes.listeAlertes.length == 1">{{ ('workflow.alerte.type.titre.' + data.listeAlertes.listeAlertes[0].type | translate) }}</span>
                <!-- Si on a plusieurs alertes -->
                <span *ngIf="data.listeAlertes.listeAlertes.length > 1">{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions" (click)="data.isDisplayed = !data.isDisplayed">
        <button mat-icon-button>
            <mat-icon *ngIf="data.isDisplayed" color="primary">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!data.isDisplayed" color="primary">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="data.isDisplayed" class="p-20">
    <form autocomplete="off" #formFrais="ngForm">
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.date'"></span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex">
                            <span>{{ data.date | date:'shortDate' }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.villePays'"></span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex flex-row">
                            <!-- On affiche le drapeau à partir de l'opération ou du frais -->
                            <span class="flag-icon mr-1"
                                  [ngClass]="data.operation?.pays?.code2 != null
                                    ? 'flag-icon-'+ data.operation.pays.code2.toLowerCase()
                                    : data.paysFrais != null
                                        ? 'flag-icon-'+ data.paysFrais.code2.toLowerCase()
                                        : ''">
                            </span>

                            <!-- Affiche de la ville + pays -->
                            <span *ngIf="data.operation?.pays?.code2 || data.ville">
                                <ng-container *ngIf="data.operation?.pays?.code2; else templateVillePays">
                                    {{ (data.ville ? data.ville.libelle + ', ' : '') + data.operation.pays.libelle }}
                                </ng-container>
                                <ng-template #templateVillePays>
                                    <!-- Affichage à partir du frais-->
                                    <ng-container *ngIf="data.paysFrais != null">
                                        {{ (data.ville ? data.ville.libelle + ', ' : '') + data.paysFrais.libelle }}
                                    </ng-container>
                                    <ng-container *ngIf="data.paysFrais == null">
                                        <!--  Pas de pays on affiche que la ville -->
                                        {{ data.ville.libelle}}
                                    </ng-container>
                                </ng-template>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.prestation'"></span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex">
                            <span>{{ data.prestation?.libelle }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.quantite'"></span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex">
                            <span>{{ (data.quantite | number:'1.2-2') + ' ' + ((!data.idFrais && data.prestation.unite?.libelle) || '') }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.montant'"></span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex">
                            <span *ngIf="getDeviseCode() != null">{{ data.montant | montant:getDeviseCode() }}</span>
                            <span *ngIf="getDeviseCode() == null">{{ data.montant}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.montantRemboursable'"></span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex">
                            <span>{{ getMontantRemboursable() }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3">
                        <span [translate]="'ndf.frais.remarque'"></span>
                    </label>
                    <div class="col-md-9">
                        <span>{{ data.commentaire }}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <document-viewer [listeDocuments]="data.listeDocuments" [isAlignRight]="true"></document-viewer>
            </div>
        </div>
    </form>
</div>


<!-- Template du tooltip d'information du frais intégré -->
<!-- fonctionnellement on ne devrait pas avoir plus d'un frais intégré par item même si on reçoit une liste, on prend donc l'index 0-->
<ng-template #fraisIntegreTooltip>
    <table>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.date</label></td>
            <td>{{ data.listeFraisIntegres[0].dateRejet | date:'shortDate' }}</td>
        </tr>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.par</label></td>
            <td>{{ data.listeFraisIntegres[0].responsable.nom + " " + data.listeFraisIntegres[0].responsable.prenom}}</td>
        </tr>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.motif</label></td>
            <td>{{ data.listeFraisIntegres[0].motifRejet }}</td>
        </tr>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.ndf</label></td>
            <td>{{ data.listeFraisIntegres[0].depense.ndf.objet }}</td>
        </tr>
    </table>
</ng-template>

<!-- Template du tooltip d'information du frais rejeté -->
<!-- fonctionnellement on ne devrait pas avoir plus d'un frais rejeté par item même si on reçoit une liste, on prend donc l'index 0-->
<ng-template #fraisRejeteTooltip>
    <table>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.date</label></td>
            <td>{{ data.listeFraisRejetes[0].dateRejet | date:'shortDate' }}</td>
        </tr>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.par</label></td>
            <td>{{ data.listeFraisRejetes[0].responsable.nom + " " + data.listeFraisRejetes[0].responsable.prenom}}</td>
        </tr>
        <tr>
            <td><label translate>ndf.frais.fraisTooltip.motif</label></td>
            <td>{{ data.listeFraisRejetes[0].motifRejet }}</td>
        </tr>
    </table>
</ng-template>

<!-- Template du tooltip d'informations sur le cumul de la prestation -->
<ng-template #cumulTooltip>
    <strong translate>ndf.frais.prestationCumul.title</strong>
    <table>
        <tr>
            <td><span translate>ndf.frais.prestationCumul.cumul</span></td>
            <td>{{ data.prestation.libelle }}</td>
        </tr>
        <tr>
            <td><span translate>ndf.frais.prestationCumul.montant</span></td>
            <td>
                <span *ngIf="!data.idFrais">{{ data.prestation.cumul | montant:data.operation.devise.code }}</span>
                <span *ngIf="data.idFrais">{{ data.prestation.cumul | montant:data.deviseCodeFrais }}</span>
            </td>
        </tr>
    </table>
</ng-template>
