<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="avatar === typesPrestaEnum.VOITURE_PERSONNELLE || avatar === typesPrestaEnum.VOITURE_DE_LOCATION || avatar === typesPrestaEnum.VOITURE_SOCIETE" class="nio icon-voiture align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.AVION" class="nio icon-avion align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.AVION_TRAIN" class="nio icon-aviontrain align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.TRAIN" class="nio icon-train align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.HOTEL" class="nio icon-accueil align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.AVANCE" class="nio icon-avance align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.DOCUMENT || avatar === typesPrestaEnum.SERVICE_1 || avatar === typesPrestaEnum.SERVICE_2 || avatar === typesPrestaEnum.SERVICE_3" class="nio icon-dossier_voyage align-middle"></i>
        <i *ngIf="avatar === typesPrestaEnum.TRANSPORT_1 || avatar === typesPrestaEnum.TRANSPORT_2 || avatar === typesPrestaEnum.TRANSPORT_3" class="nio icon-vehicules align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="openCarte(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="typePresta">
                <strong [translate]="'admin.voyages.referentiels.prestation'"></strong>
                <span>{{ typePresta }}</span>
            </li>
            <li *ngIf="data.compagnie">
                <strong [translate]="'admin.voyages.referentiels.compagnieLibelle'"></strong>
                <span>{{ data.compagnie.libelle }}</span>
            </li>
            <li *ngIf="typeCarte">
                <strong [translate]="'admin.voyages.referentiels.type'"></strong>
                <span>{{ typeCarte }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.referentiels.personnalise'"></strong>
                <span>{{ (data.custom ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.referentiels.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
        </ul>
    </div>
</div>
