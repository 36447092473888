<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm" *ngIf="zone">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="libelle"
                    name="libelle"
                    customType="input"
                    [(ngModel)]="zone.libelle"
                    libelle="admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.libelle"
                    [maxlength]="50"
                    [disabled]="isPending"
                    [required]="true"
                    [readonly]="data.fixe">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="actif"
                    name="actif"
                    customType="checkbox"
                    [ngModel]="zone.actif"
                    [(customModel)]="zone.actif"
                    libelle="admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.actif"
                    [disabled]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="masque"
                    name="masque"
                    customType="input"
                    [(ngModel)]="zone.masque"
                    libelle="admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.masque"
                    [maxlength]="zone.champ === 'NUMERO_SERVICE' ? 1000 : 50"
                    [pattern]="masqueRegex"
                    [postTooltipNio]="{ content: masqueTooltip }"
                    (input)="onMasqueChange($event)"
                    [disabled]="isPending"
                    [required]="true">
            </custom-input>
            <custom-input
                    *ngIf="!['OPERATION_1','OPERATION_2'].includes(zone.champ)"
                    ngDefaultControl
                    id="obligatoire"
                    name="obligatoire"
                    customType="checkbox"
                    [ngModel]="zone.obligatoire"
                    [(customModel)]="zone.obligatoire"
                    libelle="admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.obligatoire"
                    postLibelle="admin.bibliotheque.zonesUtilisateurs.predefinies.obligatoireTooltip"
                    [disabled]="isPending">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="tailleMin"
                    name="tailleMin"
                    customType="number"
                    [(ngModel)]="zone.tailleMinimale"
                    libelle="admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.tailleMin"
                    [min]="zone.obligatoire ? 1 : 0"
                    [max]="zone.masque?.length || 1"
                    [disabled]="isPending"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="tailleMax"
                    name="tailleMax"
                    customType="number"
                    [ngModel]="zone.masque?.length || 0"
                    libelle="admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.tailleMax"
                    [disabled]="isPending"
                    [readonly]="true">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" *ngIf="data.actif && !data.fixe" (click)="onDisable()" [disabled]="isPending">
        <span [translate]="'global.actions.desactiver'"></span>
    </button>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="form?.invalid || isPending">
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>

<ng-template #masqueTooltip>
    <form autocomplete="off" class="nio-tooltip-details">
        <table>
            <tr>
                <td [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.masqueTooltip.title'"></td>
            </tr>
            <tr>
                <td>Z : <span [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.masqueTooltip.carAlphabetique'"></span></td>
            </tr>
            <tr>
                <td>9 : <span [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.masqueTooltip.carNumerique'"></span></td>
            </tr>
            <tr>
                <td>? : <span [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.masqueTooltip.carAlphanumerique'"></span></td>
            </tr>
            <tr>
                <td>* : <span [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.editPopin.masqueTooltip.carAll'"></span></td>
            </tr>
        </table>
    </form>
</ng-template>