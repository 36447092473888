/**
 * Paramètres d'un type entité
 */
import {TypePortee} from "../workflow/workflow";
import {ModeParticipants} from "../settings/ns-params";
import {Pays} from "../geographie/pays";
import {Ville} from "../geographie/ville";

export interface TypeEntiteParam {
    idTypeEntite: number;
    typeTypeEntite: TypePortee;
    listeRole?: Array<number>;
}

/**
 * Paramètres du type entité pour les missions permanentes
 */
export interface TypeEntiteParamOT extends TypeEntiteParam {
    gestionDeplacement?: boolean;
    creationOM?: boolean;
    creationNDFOT?: boolean;
    modeAnalytiqueOM?: ModeAnalytique;
    modeAnalytiqueNDFOT?: ModeAnalytique;
    gestionVilleOT?: boolean;
    villeObligatoireOT?: boolean;
    idPaysDefautOT?: number;
    idVilleDefautOT?: number;
    destinationModifiableOT?: boolean;
    gestionResidenceOT?: boolean;
    saisieAdresseOT?: boolean;
    gestionTransportAutoriseOT?: boolean;
}

/**
 * Paramètres du type entité pour les OD
 */
export interface TypeEntiteParamOD extends TypeEntiteParam {
    creationNDF?: boolean;
    creationFacture?: boolean;
    creationFacturePrev?: boolean;
    modeAnalytiqueNDFOD?: ModeAnalytique;
    modeAnalytiqueFacture?: ModeAnalytique;
    participant?: ModeParticipants;
    prestaTrain: boolean;
    prestaAvion: boolean;
    prestaHebergement: boolean;
    prestaVoiture: boolean;
    prestaAutre: boolean;
    demandeAvance?: boolean;
    fraisPrev?: boolean;
    gestionTemps: GestionTemps;
    gestionVilleOD?: boolean;
    villeObligatoireOD?: boolean;
    destinationModifiableOD?: boolean;
    caseUrgent?: boolean;
    caseAgenceVoyage?: boolean;
    gestionResidenceOD?: boolean;
    saisieAdresseOD?: boolean;
    gestionDegrevement?: boolean;
    gestionFaitGenerateur?: boolean;
    modeMIBFacture: ModeMIB;
}

/**
 * Paramètres du type entité pour les NDF
 */
export interface TypeEntiteParamNF extends TypeEntiteParam {
    rattachementOM?: boolean;
    rattachementOMObligatoire?: boolean;
    modeAnalytiqueNF?: number;
    gestionVilleNF?: boolean;
    villeObligatoireNF?: boolean;
    destinationModifiableNF?: boolean;
    idPaysDefautNF?: number;
    idVilleDefautNF?: number;
    lieuDepense?: LieuDepense;
    applicationFranchise?: boolean;
    paysDefault?: Pays;
    villeDefault?: Ville;
}

/**
 * Paramètres du type entité pour les factures
 */
export interface TypeEntiteParamFC extends TypeEntiteParam {
    modeAnalytiqueFC?: ModeAnalytiqueFC;
}

/**
 * Paramètres du type entité pour le profil
 */
export interface TypeEntiteParamPRF extends TypeEntiteParam {    
}

export enum ModeAnalytique {
    HERITE_NON_MODIFIABLE = 1,
    HERITE_MODIFIABLE = 2,
    NON_HERITE = 3
}

export enum ModeAnalytiqueFC {
    ANALYTIQUE_FACTURE = 1,
    ANALYTIQUE_FRAIS = 2,
    ANALYTIQUE_FACTURE_FRAIS = 3
}

export enum GestionTemps {
    TEMPS_ACTIF_MODIFIABLE = 1,
	TEMPS_ACTIF_NON_MODIFIABLE = 2,
	TEMPS_INACTIF = 3
}

export enum LieuDepense {
    LIEU_DEPENSE_DEFAUT = 0,
    LIEU_DEPENSE_OM = 1,
    LIEU_DEPENSE_VIDE = 2,
}

export enum ModeMIB {
    MIB_HERITE_NON_MODIFIABLE = 1,
    MIB_HERITE_MODIFIABLE = 2,
    MIB_NON_HERITE = 3,
}