<mat-card>
    <mat-card-title>
        <span>
            <span [translate]="'admin.bibliotheque.devises.infosDevise.gestionAvances.titlePays'"></span>
            <mat-icon class="m-l-10" [matTooltip]="'admin.bibliotheque.devises.infosDevise.gestionAvances.tooltip' | translate" [matTooltipPosition]="'right'">info</mat-icon>
        </span>
        <span class="actions clickable" (click)="addNewCountry()">
            <mat-icon>add</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div *ngFor="let pays of listePays">
                    <div class="listview-item active">
                        <div class="list-view-item-hover">
                            <devise-gestion-item-pays [erreur]="listePaysErreur.includes(pays)" (deletePaysFromDeviseEvent)="deletePaysFromDevise($event)" [codeDevise]="devise.code" [pays]="pays"></devise-gestion-item-pays>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>