import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {TypeCodeErreur} from "@domain/common/http/result";
import {SettingsGlobalState} from "@domain/settings/settings";
import {environment} from "@environments/environment";
import {XmlUploader} from "@components/admin/entreprise/licence/xml-uploader";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Subscription} from "rxjs";

/**
 * La partie upload d'une licence pour l'administrateur
 */
@Component({
    selector: 'licence-uploader',
    host: {'data-test-id': 'licence-uploader'},
    templateUrl: './licence-uploader.component.html',
    styleUrls: ['../licence.component.scss']
})
export class LicenceUploaderComponent implements OnInit, OnDestroy {
    /** Upload de fichiers **/
    xmlUploader: XmlUploader;

    /** Paramétrage **/
    settings: SettingsGlobalState;

    /** Evènement généré à la suite de l'upload d'un document **/
    @Output() onDocumentUploaded: EventEmitter<any> = new EventEmitter<any>();

    /** Souscription pour éviter une fuite de mémoire */
    souscription: Subscription;

    /**
     * Constructeur
     *
     * @param store Store pour récupérer les settings
     * @param toastrService Service pour l'affichage des popup d'informations sur le succès ou non de l'enregistrement de la licence
     * @param translateService Service pour la traduction
     */
    constructor(private toastrService: ToastrService,
                private translateService: TranslateService,
                private store: Store<AppState>) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Définition de l'upload de documents
        this.xmlUploader = {
            url: `${environment.baseUrl}/controller/Licence/importXML`,
            onItemUploaded: this.onItemUploaded.bind(this)
        };

        //Récupération des settings
        this.souscription = this.store.select(state => state.settings?.Global).subscribe(
            settings => {
                this.settings = settings;
            }
        );
    }

    ngOnDestroy() {
        this.souscription.unsubscribe();
    }

    /**
     * Finalisation de l'upload d'un fichier
     */
    onItemUploaded(result,file) {
        //Indique que le fichier a été enregistré
        file.isUploaded = true;
        file.result = result;

        //S'il y a eu un code d'erreur indiquant qu'il y a eu une erreur sur la sauvegarde de la licence
        if (result.codeErreur == TypeCodeErreur.ERROR_SAVE) {
            file.isKo = true;
            //Si extension valide
            if(!result.data.isExtensionValide) {
                //On affiche le message d'erreur extension invalide
                this.toastrService.error(this.translateService.instant('global.errors.extensionInvalide', { fileName: file.name }));
            }
            //Si taille invalide
            if(!result.data.isTailleValide) {
                //On affiche le message d'erreur taille invalide
                this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: file.name }));
            }
        } else if (result.codeErreur == TypeCodeErreur.ERROR_LOAD) {
            //On notifie de l'échec
            this.toastrService.error(this.translateService.instant('admin.entreprise.description.licence.erreur.load'));
        } else if (result.codeErreur == TypeCodeErreur.ERROR_LICENCE) {
            //On notifie de l'échec
            this.toastrService.error(this.translateService.instant('admin.entreprise.description.licence.erreur.plusValide'));
        } else if (result.codeErreur == TypeCodeErreur.ERROR_DOWNLOAD) {
            //On notifie de l'échec
            this.toastrService.error(this.translateService.instant('admin.entreprise.description.licence.erreur.donwload'));
        } else if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
            //Si l'upload a fonctionné correctement : émission de l'évènement de fin d'upload
            this.onDocumentUploaded.emit({ result,file });
        }
    }

    /**
     * Upload d'un fichier
     */
    uploadItem() {
        //Upload du fichier
        this.xmlUploader.uploadItem();
    }

    /**
     * Gestion du drop de fichiers
     *
     * @param files Tableau des fichiers à enregistrer
     */
    onXmlsDrop(files: Array<any>) {
        let uploadedExtension;

        //Parcours des fichiers sélectionnés (1 max)
        for (const file of files) {
            //Vérification du type de fichier
            if (file instanceof File) {
                //Récupération de l'extension
                uploadedExtension = file.name.split('.').pop().toLowerCase();

                //Calcul de la taille en Ko
                const size = file.size / 1024;

                //Vérification de la taille max
                if (this.settings.tailleMax != null &&  this.settings.tailleMax < size) {
                    //Si le fichier est trop gros
                    this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: file.name }));
                } else if (!("xml" === uploadedExtension)) {
                    //Si extension invalide
                    //Affichage d'un message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.fileBlocked',{ fileName: file.name }));
                } else {
                    //Ajout du fichier à la queue
                    this.xmlUploader.file = file;

                    //Déclenchement de l'intercepteur de fichier ajouté
                    this.xmlUploader.onItemAdded && this.xmlUploader.onItemAdded(file);
                }
            }
        }
    }
}
