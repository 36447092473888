<h1 mat-dialog-title>
    <span translate>admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.title</span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="!selectedParamConnexion"></please-wait>
    <form #form="ngForm" autocomplete="off">
        <fieldset *ngIf="!!selectedParamConnexion">
            <div class="row">
                <!-- Code -->
                <custom-input ngDefaultControl
                        lCol="4" rCol="8"
                        id="code" name="code"
                        customType="objectselect" libelle="admin.voyages.travelhub.sbtConfig.parametres.code"
                        [(ngModel)]="selectedParamConnexion.paramConnexion"
                        optionValue="idParamConnexion"
                        optionDisplay="code"
                        [selectOptions]="data.listeParamConnexion"
                        (onChange)="onChangeCode()"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Libellé -->
                <custom-input ngDefaultControl
                        lCol="4" rCol="8"
                        id="libelle" name="libelle"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.parametres.libelle"
                        [ngModel]="selectedParamConnexion.paramConnexion?.libelle || '-'"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Transmis pour -->
                <custom-input ngDefaultControl
                        lCol="4" rCol="3"
                        id="transmisPour" name="transmisPour"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.parametres.transmisPour"
                        [ngModel]="(TransmisPour.traduction(selectedParamConnexion.paramConnexion?.transmisPour) | translate) || '-'"
                        [readonly]="true">
                </custom-input>

                <!-- Obligatoire -->
                <custom-input ngDefaultControl
                        lCol="3" rCol="2"
                        id="obligatoire" name="obligatoire"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.parametres.obligatoire"
                        [ngModel]="(selectedParamConnexion.paramConnexion != null ? ((selectedParamConnexion.paramConnexion?.obligatoire ? 'global.oui' : 'global.non') | translate) : null) || '-'"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Type de valeur -->
                <custom-input ngDefaultControl
                        lCol="4" rCol="3"
                        id="typeValeur" name="typeValeur"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.parametres.typeValeur"
                        [ngModel]="(TypeParamConnexion.traduction(selectedParamConnexion.paramConnexion?.typeParamConnexion) | translate) || '-'"
                        [readonly]="true">
                </custom-input>

                <!-- Contrôle de format -->
                <custom-input ngDefaultControl
                        lCol="3" rCol="2"
                        id="controleFormat" name="controleFormat"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.parametres.controleFormat"
                        [ngModel]="(TypeControle.traduction(selectedParamConnexion.paramConnexion?.typeControle) | translate) || '-'"
                        [readonly]="true">
                </custom-input>
            </div>

            <!-- Valeur par sélection depuis une liste de valeurs possibles -->
            <div class="row" *ngIf="selectedParamConnexion.paramConnexion?.typeParamConnexion == TypeParamConnexion.STRING && listeValeurNotionMetier == null">
                <label class="col-md-4 required" [class.has-error]="valeurFixe.invalid || valeurSql.invalid || valeurField.invalid">{{ 'admin.voyages.travelhub.sbtConfig.parametres.valeur' | translate }}</label>
                <div class="col-md-8">
                    <mat-form-field class="d-flex" appearance="none" floatLabel="never">
                        <span class="clickable flex-grow-1" (click)="openValeur()">
                            <span class="r-flex align-items-center justify-content-between" style="border-bottom: 1px solid;">
                                <ng-container *ngIf="!!selectedParamConnexion.typeField; else tplNoChamp">
                                    {{ FiltreChamp.traduction(champ.filtre) | translate }} → {{ champ.libelle | translate }}
                                </ng-container>
                                <ng-template #tplNoChamp>{{'admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.selectionnerValeur' | translate}}</ng-template>
                                <mat-icon>search</mat-icon>
                            </span>
                            <input matInput ngDefaultControl #valeurField="ngModel" name="valeurField"
                                   [hidden]="true"
                                   [class.cdk-visually-hidden]="true"
                                   [ngModel]="!selectedParamConnexion.typeField ? null : selectedParamConnexion.typeField"
                                   [required]="![AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE,AdminTravelhubService.TYPE_FIELD_SQL].includes(selectedParamConnexion.typeField)">
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <!-- Numérique -->
            <div class="row" *ngIf="selectedParamConnexion.paramConnexion?.typeControle == TypeControle.NUMERIQUE || selectedParamConnexion.paramConnexion?.typeParamConnexion == TypeParamConnexion.ENTIER || selectedParamConnexion.paramConnexion?.typeParamConnexion == TypeParamConnexion.DECIMAL">
                <custom-input ngDefaultControl
                              lCol="4" rCol="4"
                              name="valeurNumerique"
                              libelle="admin.voyages.travelhub.sbtConfig.parametres.valeur"
                              customType="number"
                              [(ngModel)]="selectedParamConnexion.valeur"
                              [min]="selectedParamConnexion.paramConnexion?.min > 0 ? selectedParamConnexion.paramConnexion?.min : null"
                              [max]="selectedParamConnexion.paramConnexion?.max > 0 ? selectedParamConnexion.paramConnexion?.max : null"
                              [nbDecimales]="selectedParamConnexion.paramConnexion?.typeParamConnexion == TypeParamConnexion.DECIMAL ? 2 : 0"
                              [required]="true"
                              [postTooltip]="selectedParamConnexion.paramConnexion?.min > 0 && selectedParamConnexion.paramConnexion?.max > 0
                                    ? ('admin.voyages.travelhub.sbtConfig.parametres.tooltip.controlNumerique.plage' | translate:{min: selectedParamConnexion.paramConnexion?.min, max: selectedParamConnexion.paramConnexion?.max})
                                    : selectedParamConnexion.paramConnexion?.min > 0 ?
                                        ('admin.voyages.travelhub.sbtConfig.parametres.tooltip.controlNumerique.min' | translate:{min: selectedParamConnexion.paramConnexion?.min})
                                    : selectedParamConnexion.paramConnexion?.max > 0 ?
                                        ('admin.voyages.travelhub.sbtConfig.parametres.tooltip.controlNumerique.max' | translate:{min: selectedParamConnexion.paramConnexion?.max})
                                    : null"
                ></custom-input>
            </div>

            <!-- Liste de valeurs -->
            <div class="row" *ngIf="selectedParamConnexion.paramConnexion?.typeParamConnexion == TypeParamConnexion.LISTE_VALEURS">
                <!-- Sélection d'une valeur parmi une liste de possibilités définies par le paramètre -->
                <custom-input ngDefaultControl
                              lCol="4" rCol="8"
                              name="paramConnexionValeur"
                              libelle="admin.voyages.travelhub.sbtConfig.parametres.valeur"
                              customType="objectselect"
                              optionValue="idValeur"
                              optionDisplay="valeur"
                              [(ngModel)]="selectedParamConnexion.paramConnexionValeur"
                              [selectOptions]="listeValeurParamConnexionFiltered"
                              [required]="true"
                ></custom-input>
            </div>

            <!-- Booléen -->
            <div class="row" *ngIf="selectedParamConnexion.paramConnexion?.typeParamConnexion == TypeParamConnexion.BOOLEAN">
                <!-- Booléen oui / non -->
                <custom-input ngDefaultControl
                              lCol="4" rCol="8"
                              name="valeurBooleen"
                              libelle="admin.voyages.travelhub.sbtConfig.parametres.valeur"
                              customType="select"
                              optionValue="value"
                              optionDisplay="display"
                              [(ngModel)]="selectedParamConnexion.valeur"
                              [selectOptions]="listeOuiNon"
                              [required]="true"
                ></custom-input>
            </div>

            <!-- Spécial notion métier et RLV -->
            <div class="row" *ngIf="listeValeurNotionMetier != null">
                <custom-input ngDefaultControl
                              lCol="4" rCol="8"
                              name="codeMapping"
                              libelle="admin.voyages.travelhub.sbtConfig.parametres.valeur"
                              customType="select"
                              optionValue="value"
                              optionDisplay="display"
                              [(ngModel)]="selectedParamConnexion.codeMapping"
                              [selectOptions]="listeValeurNotionMetier"
                              [required]="true"
                              (onChange)="selectedParamConnexion.valeur = null"
                ></custom-input>
            </div>

            <!-- SQL -->
            <div class="row" [hidden]="selectedParamConnexion.typeField !== AdminTravelhubService.TYPE_FIELD_SQL">
                <div class="col-md-8 offset-4">
                    <ngx-codemirror #valeurSql="ngModel"
                                    #codeMirror
                                    name="valeurSql"
                                    [options]="codeMirrorOptions"
                                    [(ngModel)]="selectedParamConnexion.requete"
                                    [required]="selectedParamConnexion.typeField == AdminTravelhubService.TYPE_FIELD_SQL"
                    ></ngx-codemirror>
                </div>
            </div>

            <!-- Valeur fixe -->
            <div class="row" [hidden]="selectedParamConnexion.codeMapping !== AdminTravelhubService.PARAM_CODE_MAPPING_AUTRE && selectedParamConnexion.typeField !== AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE">
                <custom-input ngDefaultControl
                              #valeurFixe="ngModel"
                              lCol="4" rCol="8"
                              rClass="offset-md-4"
                              name="valeurFixe"
                              libelle=""
                              customType="input"
                              [(ngModel)]="selectedParamConnexion.valeur"
                              [regExpValidator]="regExpValidatorValeur"
                              [postTooltipNio]="tooltipValeurFixe"
                              [required]="selectedParamConnexion.codeMapping == AdminTravelhubService.PARAM_CODE_MAPPING_AUTRE || selectedParamConnexion.typeField == AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE"
                ></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!!selectedParamConnexion">
    <!-- Bouton de suppression -->
    <button mat-stroked-button color="warn" [disabled]="isProcessing" (click)="deleteParamConnexion()" *ngIf="selectedParamConnexion.idParamConnexionUsed > 0">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <!-- Bouton d'enregistrement' -->
    <button mat-flat-button color="primary" [disabled]="form.invalid || isProcessing" (click)="saveParamConnexion()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
