import {Adresse} from "@domain/profil/adresse";

/**
 * Géographie - Lieu d'une distance (départ ou arrivée)
 */
export interface LieuDistance {
	idLieu: number;
	libelle: string;
	type: TypeLieuDistance;
	adresse?: Adresse;
}

/**
 * Enumération des types de lieu pour une distance (départ ou arrivée) possibles
 */
export enum TypeLieuDistance {
	VILLE = 6,
	LOCALISATION = 7,
	GARE = 8,
	AEROPORT = 9
}

/**
 * Namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace TypeLieuDistance {

	/**
	 * Retourne le code de traduction qui correspondant à un type de lieu
	 *
	 * @param typeLieu Type de lieu
	 */
	export function traduction(typeLieu: TypeLieuDistance) {
		switch (typeLieu) {
			case this.VILLE:
				return 'admin.bibliotheque.geographie.distance.lieu.ville';
			case this.LOCALISATION:
				return 'admin.bibliotheque.geographie.distance.lieu.adresse';
			case this.GARE:
				return 'admin.bibliotheque.geographie.distance.lieu.gare';
			case this.AEROPORT:
				return 'admin.bibliotheque.geographie.distance.lieu.aeroport';
		}
	}
}