import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {Adresse} from "@domain/profil/adresse";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute,Router} from '@angular/router';
import {GeographieService} from '@services/admin/geographie/geographie.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {TypeCodeErreur} from '@domain/common/http/result';
import {filter, first} from "rxjs/operators"
import {Origine,Visibilite} from "@domain/geolocalisation/localisation";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypeProfil,User} from "@domain/user/user";
import {LieuService} from "@components/lieu/lieu.service";
import {filterFirstNotNull} from '@share/utils/rxjs-custom-operator';

/**
 * Composant d'affichage du détail d'une adresse
 *
 * @author Angeline Ha
 * @date 07/12/2023
 */
@Component({
    host: {'data-test-id': 'adresse'},
    selector: 'adresse',
    templateUrl: './adresse.component.html'
})
export class AdresseComponent implements OnInit, OnDestroy {

    /** Liste des différents onglets disponibles dans une Adresse */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Adresse à afficher */
    adresse: Adresse;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Utilisateur connecté */
    user: User;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService    Service de traduction
     * @param router              Router
     * @param route               Route d'accès à cette page
     * @param geographieService   Service géographie
     * @param lieuService         Service de lieu
     * @param toastrService       Service de gestion des toasts
     * @param confirmService      Service de confirmation
     * @param store               Store
     */
    constructor(private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private lieuService: LieuService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService,
                private store: Store<AppState>,) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Sélection de l'utilisateur connecté
        this.store.select(state => state.session?.user).pipe(filterFirstNotNull()).subscribe(user => this.user = user);

        //Indique au composant géographie qu'on affiche le détail d'une adresse
        this.geographieService.isShowDetail = true;

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];

        //Récupération de l'identifiant de l'adresse
        this.route.params.pipe(first()).subscribe(params => {
            const idAdresse = params['idAdresse'];

            //Indique si on est en mode création
            this.isCreation = idAdresse === '0';

            //Définition de la liste des actions possibles
            this.defineListeActionsPossibles();

            //Récupération de l'adresse
            if (this.isCreation) {
                //Création d'une nouvelle adresse
                this.adresse = {
                    id: 0,
                    idUser: this.user.idUser,
                    user: this.user,
                    code: '',
                    libelle: '',
                    rue: '',
                    complement: '',
                    idVille: null,
                    adresseVille: null,
                    codePostal: '',
                    idDepartement: null,
                    idRegion: null,
                    idPays: null,
                    latitude: null,
                    longitude: null,
                    origine: this.user.fonction === TypeProfil.ADMINISTRATEUR ? Origine.ORIGINE_ADMIN : Origine.ORIGINE_USER,
                    attribut1: null,
                    attribut2: null,
                    attribut3: null,
                    attribut4: null,
                    listeVisibilite: []
                }
            } else {
                //Récupération de l'adresse existante
                this.geographieService.getAdresseById(idAdresse).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        this.adresse = result.data.adresse;
                        this.initVisibilites();

                    } else if (result.codeErreur === TypeCodeErreur.ERROR_LOAD) {
                        //Message d'erreur
                        TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD, this.translateService, this.toastrService);
                    }
                });
            }
        });
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'une adresse
        this.geographieService.isShowDetail = false;
    }

    /**
     * Définition de la valeur de la liste des actions possibles
     */
    defineListeActionsPossibles() {
        //Liste des actions
        const listeActions: Array<FloatingButtonAction> = [{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveAdresse(),
            isDisabled: () => !this.form?.valid || !this.adresse.rue.trim()
        }];

        //Ajout de l'action de suppression lorsqu'on est en modification
        if (!this.isCreation) {
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.deleteAdresse()
            });
        }

        this.listeActions.next(listeActions);
    }

    /**
     * Initialisation des visibilités cochées dans le multiselect
     */
    private initVisibilites() {
        this.adresse.visibilites = this.adresse.listeVisibilite.map(v => {
            if (v === Visibilite.FILTRE_V_ADM) {
                return {
                    id: Visibilite.FILTRE_V_ADM,
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.adresse.adm')
                };
            } else if (v === Visibilite.FILTRE_V_NF) {
                return {
                    id: Visibilite.FILTRE_V_NF,
                    libelle: this.translateService.instant('admin.bibliotheque.geographie.adresse.nf')
                };
            }
        }).filter(v => v != undefined);
    }

    /**
     * Retour vers la liste des adresses
     */
    onGoBack() {
        this.router.navigate(['../..'], {relativeTo: this.route});
    }

    /**
     * Enregistrement de l'adresse
     */
    saveAdresse() {
        //Enregistrement de l'adresse
        this.geographieService.saveAdresse(this.adresse).subscribe(result => {
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                //Mise à jour de l'adresse d'origine à partir du retour
                this.adresse.id = result.data?.idAdresse;
                this.adresse.idAdresse = result.data?.idAdresse;
                this.adresse.rue = result.data?.rue;
                this.adresse.ville = result.data?.ville;
                this.adresse.codePostal = result.data?.codePostal;
                this.adresse.pays = result.data?.pays;
                this.adresse.codePays = result.data?.codePays;

                if (this.isCreation) {
                    //On n'est plus en mode création
                    this.isCreation = false;

                    //Définition de la liste des actions possibles
                    this.defineListeActionsPossibles();

                    //Navigation avec le bon id
                    this.router.navigate(['../' + result.data?.idAdresse], {relativeTo: this.route});
                }
            } else if (result.codeErreur === TypeCodeErreur.ERROR_ALREADY_USED) {
                //Toast d'erreur doublon
                this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.adresse.erreurDoublon'))
            } else {
                //Toast d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
            }
        });
    }

    /**
     * Suppression de l'adresse
     */
    deleteAdresse() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.adresse.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression de l'adresse
                this.geographieService.deleteAdresse(this.adresse.idAdresse).subscribe(data => {
                    if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Retour vers la liste des adresses
                        this.onGoBack();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                })
            }
        })
    }

}

/**
 * Enum des différents onglets d'une adresse
 */
export enum Onglets {
    GENERALITES = "Généralités"
}
