import {Component,OnInit} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Referentiel} from "@domain/admin/referentiels/referentiel";
import {NavigationExtras,Router} from "@angular/router";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {Onglets} from "@components/admin/frais/referentiel/onglets";

/**
 * Composant d'affichage d'un référentiel dans la liste des référentiels de la gestion des frais
 */
@Component({
    host: {'data-test-id': 'frais-referentiel-item'},
    templateUrl: './frais-referentiel-item.component.html'
})
export class FraisReferentielItemComponent extends ListViewItem<Referentiel> implements OnInit {
    /** Type initial du référentiel à afficher */
    typeInitial: string;

    /** Onglet cible en cas de retour à l'écran précédent */
    targetTab: PageHeaderItem;

    /**
     * Constructeur
     *
     * @param router Router de l'application
     */
    constructor(
        private router: Router
    ) {
        super();
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Permet de changer le type pour le transformer d'une lettre à un mot ou un ensemble de mots compréhensible par l'utilisateur
        switch (this.data.type) {
            case "D": {
                this.typeInitial = "D";
                this.data.type = "Type prestation";
                this.targetTab = this.extraOptions.listeTabItems.find(tab => tab.libelle === this.data.type)
                break;
            }
            case "F": {
                this.typeInitial = "F";
                this.data.type = "Famille";
                this.targetTab = this.extraOptions.listeTabItems.find(tab => tab.libelle === this.data.type)
                break;
            }
            case "L": {
                this.typeInitial = "L";
                this.data.type = "Liasse";
                this.targetTab = this.extraOptions.listeTabItems.find(tab => tab.libelle === this.data.type)
                break;
            }
            case "I": {
                this.typeInitial = "I";
                this.data.type = "Indemnité";
                this.targetTab = this.extraOptions.listeTabItems.find(tab => tab.libelle === this.data.type)
                break;
            }
            case "R": {
                this.typeInitial = "R";
                this.data.type = "Rubrique";
                this.targetTab = this.extraOptions.listeTabItems.find(tab => tab.libelle === this.data.type)
                break;
            }
            default: {
                this.typeInitial = this.data.type;
                this.targetTab = this.extraOptions.listeTabItems.find(tab => tab.code === Onglets.UNITE)
            }
        }
    }

    /**
     * Méthode appelée lors de la sélection du référentiel affiché
     */
    onSelect() {
        //Définition des extras
        const extras: NavigationExtras = {
            state: {
                tab: this.targetTab
            }
        }

        //Navigation vers la page d'informations du référentiel
        this.router.navigate(['Admin/Frais/Referentiel/' + this.data.id + '/' + this.typeInitial],extras);
    }
}