<h1 mat-dialog-title>
    <i *ngIf="isSelectedTypePerimetre()" class="nio icon-transfere clickable" (click)="goBack()"></i>
    <span>{{ 'admin.bibliotheque.geographie.territoire.perimetre.ajout' | translate }}</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div [hidden]="isSelectedTypePerimetre()" class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let typePerimetre of listeTypePerimetre">
                <div class="list-view-item-hover">
                    <div class="lvi-content clickable" (click)="selectTypePerimetre(typePerimetre.id)">
                        <div class="avatar">{{ typePerimetre.libelle.substring(0, 3) }}</div>
                        <div class="lvi-body">
                            <div class="title">
                                <a>{{ typePerimetre.libelle }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form autocomplete="off" [hidden]="!isSelectedTypePerimetre()" #perimetreAddForm="ngForm">
        <fieldset>
            <div class="row" [ngClass]="{ 'has-error': geographie.invalid }">
                <label class="col-md-4 required"><span [translate]="translateTypePerimetre(typePerimetreSelected)"></span></label>
                <div class="col-md-8">
                    <autocomplete type="geographie" name="geographie" #autocompleteGeographie #geographie="ngModel"
                                  (onSelect)="setGeographie($event)"
                                  [filter]="{ listeTypes: getAutoCompleteFilter(typePerimetreSelected) }"
                                  [isSearchWhenNoFilter]="typePerimetreSelected === TypeGeolocalisation.ZONE"
                                  [(ngModel)]="selectedGeographie"
                                  ngDefaultControl
                                  [label]="'global.input.rechercher' | translate"
                                  required="true">
                    </autocomplete>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div [hidden]="!isSelectedTypePerimetre()" mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="perimetreAddForm.invalid || !selectedGeographie?.id || isSaving" (click)="savePerimetre()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>