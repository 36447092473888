import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {User} from "@domain/user/user";

/**
 * Popin de gestion des dates d'entrée/sortie d'un user
 */
@Component({
	host: {'data-test-id': 'user-entree-sortie-popin'},
	templateUrl: './user-entree-sortie-popin.component.html'
})
export class UserEntreeSortiePopinComponent implements OnInit {
	/** Utilisateur */
	user: User;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { user: User }
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de l'utilisateur
		this.user = Object.assign({},this.data.user);
	}
}
