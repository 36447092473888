import {ArbreHierarchique} from "./ArbreHierarchique";

/** Classe d'une carte hiérarchique (correspond à un mat-card) */
export class CarteHierarchique {
	/** Clé de traduction du titre de la carte */
	cleTradCarte: string;

	/** Logo de la carte */
	logo: string;

	/** Liste des arbres à afficher */
	listeArbresHierarchiques: Array<ArbreHierarchique>;

	/** Indique si tous les arbres de la carte sont repliés ou non */
	folded: boolean = true;

	/**
	 * Constructeur
	 *
	 * @param cleTradCarte				Clé de traduction de la carte
	 * @param listeArbresHierarchiques	Liste des arbres de la carte
	 * @param logo						Logo à afficher à côté du titre
	 */
	constructor(cleTradCarte: string, listeArbresHierarchiques: Array<ArbreHierarchique>, logo: string) {
		this.logo = logo;
		this.cleTradCarte = cleTradCarte;
		this.listeArbresHierarchiques = listeArbresHierarchiques;
	}

	/** Déplie/replie tous les arbres de la carte */
	foldUnfold(): void {
		this.folded = !this.folded;
		this.listeArbresHierarchiques.forEach(abre => abre.folded = this.folded);
	}

	/** Détermine si le logo est issu de material */
	isLogoMaterialIcon(): boolean {
		return !this.logo.includes("nio ");
	}
}