<h1 mat-dialog-title>
    <span translate>od.frais.frais.creation.choix.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <ng-container *ngFor="let typeFrais of listeTypes">
                <div class="listview-item">
                    <div class="lvi-content">
                        <div class="avatar"><i [ngClass]="'nio align-middle ' + OdTypeFrais.getIcone(typeFrais)"></i></div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="doAction(typeFrais)">{{ OdTypeFrais.traductionForCreation(typeFrais) | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>