import {Directive,Input} from '@angular/core';
import {AbstractControl,NG_VALIDATORS,ValidationErrors,Validator} from '@angular/forms';

/**
 * Directive de validation qui permet de contrôler que la valeur de l'input remplie une condition définie par une regExp
 */
@Directive({
	selector: '[regExpValidator]',
	providers: [{ provide: NG_VALIDATORS, useExisting: RegexDirective, multi: true }]
})
export class RegexDirective implements Validator {
	@Input('regExpValidator') regExp: RegExp;

	validate(control: AbstractControl): ValidationErrors | null {
		return this.regExp != null && typeof control?.value === 'string' && !(control.value ?? '').match(this.regExp)
			? { isMatchingRegex: false }
			: null;
	}
}
