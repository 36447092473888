import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ProfilDonneesModifyInfosPersoComponent} from "./profil-donnees-modify-infos-perso.component";
import {ProfilUser} from "@domain/profil/profilUser";
import {ProfilService} from "../../profil.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {first} from "rxjs/operators";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {InfoVoyageur} from "@domain/profil/infoVoyageur";

/**
 * Composant des infos personnelles de l'utilisateur
 */
@Component({
    selector: 'profil-donnees-infos-perso',
    templateUrl: './profil-donnees-infos-perso.component.html'
})
export class ProfilDonneesInfosPersoComponent implements OnInit {
    /** Déclaration pour accès dans le remplate */
    NiveauAlerte = NiveauAlerte;

    /** Infos perso de l'utilisateur */
    @Input()
    profilUser: ProfilUser;

    /** Niveau d'alerte pour les infos utilisateur */
    @Input()
    alertLevelInfoUser?: NiveauAlerte;

    /** Lance la mise à jour des informations du profil */
    @Output() infosPersoUpdated = new EventEmitter<void>();

    /**
     * Constructeur
     *
     * @param translateService le service de traduction
     * @param matDialog le composant des popups
     * @param profilService service de gestion du profil
     * @param toastrService service de notification
     */
    constructor(private translateService: TranslateService,
                private matDialog: MatDialog,
                private profilService: ProfilService,
                private toastrService: ToastrService) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Si les infos voyageur sont déjà définies
        if (this.profilUser.infoVoyageur) {
            //Récupération du pays de nationalité correspondant à l'ID afin de peupler l'autocomplete
            if (this.profilUser.infoVoyageur.idPaysNationalite) {
                this.profilService.getEntiteGeoForIdPays(this.profilUser.infoVoyageur.idPaysNationalite).pipe(first()).subscribe(result => {
                    this.profilUser.infoVoyageur.paysNationalite = result.data?.geographie?.pays;
                });
            }

            //Récupération de la ville correspondant à l'ID afin de peupler l'autocomplete
            if (this.profilUser.infoVoyageur.idVilleNaissance) {
                this.profilService.getEntiteGeoForIdVille(this.profilUser.infoVoyageur.idVilleNaissance).pipe(first()).subscribe(result => {
                    this.profilUser.infoVoyageur.villeNaissance = result.data?.geographie?.ville;
                });
            }

            //Récupération du pays de naissance correspondant à l'ID afin de peupler l'autocomplete
            if (this.profilUser.infoVoyageur.idPaysNaissance) {
                this.profilService.getEntiteGeoForIdPays(this.profilUser.infoVoyageur.idPaysNaissance).pipe(first()).subscribe(result => {
                    this.profilUser.infoVoyageur.paysNaissance = result.data?.geographie?.pays;
                });
            }
        } else {
            //Si les infos voyageurs ne sont pas définies, on en fait de nouvelles
            this.profilUser.infoVoyageur = new InfoVoyageur();
        }
    }

    /**
     * Fonction appelée au clic sur la loupe afin de modifier les infos perso
     */
    onClickModify(): void {
        this.matDialog.open(ProfilDonneesModifyInfosPersoComponent,{
            data: this.profilUser,
            width: '80%',
            disableClose: false
        }).afterClosed().subscribe({
            next: (profilUser: ProfilUser) => {
                //Si un profil user est renvoyé (on renvoie false si l'utilisateur annule sa modification)
                if (profilUser) {
                    //Appel back de mise à jour
                    this.profilService.updateInfosProfilUser(profilUser.infoVoyageur).subscribe(result => {
                        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                            //Notification de succès
                            this.toastrService.success(this.translateService.instant('global.success.enregistrement'))
                            //Mise à jour de l'écran
                            this.profilUser = profilUser;
                            //Déclenchement de l'évènement indiquant que les informations du profil ont été mises à jour
                            this.infosPersoUpdated.emit();
                        } else {
                            //Affichage du message d'erreur correspondant au code
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    });
                }
            }
        });
    }
}
