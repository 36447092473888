import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ConfigLogging} from "@domain/admin/logs/config-logging";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first,map} from "rxjs/operators";

/**
 * Service de gestion de la configuration des logs
 */
@Injectable()
export class LogsService {

    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {}

    /**
     * Récupération de la configuration
     */
    loadConfig(): Observable<ConfigLogging> {
        //Appel au backend
        return this.http.post<Result>(`${environment.baseUrl}/controller/ConfigLogging/consultConfig`,null).pipe(first(),map(result => {
            //Retour de la config si elle existe ou sinon un nouvel objet
            return new ConfigLogging(result?.data?.configLogging || { actif: true });
        }));
    }

    /**
     * Envoi d'un flux UDP de test au serveur de logs
     *
     * @param configLogging la configuration à tester
     */
    sendTestFeed(configLogging: ConfigLogging): Observable<Result> {
        //Appel au backend
        return this.http.post<Result>(`${environment.baseUrl}/controller/ConfigLogging/testConfig`,configLogging);
    }

    /**
     * Enregistrement de la configuration du serveur de centralisation des logs
     *
     * @param configLogging la configuration à enregistrer
     */
    saveConfig(configLogging: ConfigLogging): Observable<Result> {
        //Appel au backend
        return this.http.post<Result>(`${environment.baseUrl}/controller/ConfigLogging/saveConfig`,configLogging);
    }
}
