import { NgModule } from '@angular/core';

import { CoreComponentsModule } from '../core-components.module';
import { ImageViewerService } from './image-viewer.service';
import { ImageViewerComponent } from './image-viewer.component';

@NgModule({
    imports: [CoreComponentsModule],
    declarations: [ImageViewerComponent],
    providers: [ImageViewerService]
})
export class ImageViewerModule {}