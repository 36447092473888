import {Type} from "@angular/core";
import {ItemTitleComponent} from "@share/component/affichage-hierarchique/title-components/item-title/item-title.component";
import {ListItemTitleComponent} from "@share/component/affichage-hierarchique/title-components/list-item-title/list-item-title.component";
import {ItemTitleInput} from "@share/component/affichage-hierarchique/title-components/item-title/ItemTitleInput";
import {FiltreDetailComponent} from "@share/component/affichage-hierarchique/detail-components/filtre-detail/filtre-detail.component";
import {CheckboxDetailComponent} from "@share/component/affichage-hierarchique/detail-components/checkbox-detail/checkbox-detail.component";

/** Type du record contenant le composant à utiliser pour le détail et les inputs d'un ItemHierarchique */
export type RecordsDetail = {composantRecord: Record<string,ItemHierarchiqueDetailType>, inputsRecord: Record<string,any>};

/**
 * Classe représentant un item dans un arbre hiérarchique
 *
 * @see ArbreHierarchique
 */
export class ItemHierarchique {
	/** Composant à afficher en lieu et place du titre de l'item */
	itemTitleComponent: ItemHierarchiqueTitleType;

	/** Inputs pour le composant du titre de l'item */
	itemTitleInputs: Record<"data",ItemTitleInput>;

	/** Liste des composants à afficher dans les colonnes de détail */
	listeComposantsDetail: Record<string,ItemHierarchiqueDetailType>;

	/** Liste des inputs pour les composants des colonnes de détail */
	listeInputsComposantsDetail: Record<string,any>;

	/** Identifiant de l'item */
	identifiant: string;

	/** Indique si l'item a été checké ou non */
	private _checked: boolean = false;

	/** Indique si l'item a été désactivé */
	disabled: boolean = false;

	/** Priorité pouvant être utile à la gestion concurrentielle des items (ex: gestion de check exclusif) */
	priorite: number;

	/**
	 * Constructeur
	 *
	 * @param composantTitre	Composant à utiliser pour le titre de l'item
	 * @param identifiant		Identifiant unique de l'item
	 * @param inputsTitre		Inputs pour le composant du titre
	 * @param recordsDetail		Record contenant le composant à utiliser pour le détail et les inputs à lui associer
	 * @param priorite			Priorité de l'item
	 */
	constructor(composantTitre: ItemHierarchiqueTitleType,identifiant: string,inputsTitre: ItemTitleInput,recordsDetail: RecordsDetail,priorite: number = null) {
		//Initialisation de l'item hiérarchique
		this.itemTitleComponent = composantTitre;
		this.itemTitleInputs = {"data": inputsTitre};
		this.priorite = priorite;
		this.identifiant = identifiant;
		
		this.listeComposantsDetail = recordsDetail.composantRecord;
		this.listeInputsComposantsDetail = recordsDetail.inputsRecord;

		//On parcourt les composants de détail, et on ajoute l'item au cas où il soit utile
		for (let cle of Object.keys(this.listeComposantsDetail)) {
			this.listeInputsComposantsDetail[cle] = {...this.listeInputsComposantsDetail[cle], ...{element: this}};
		}
	}

	get checked(): boolean {return this._checked;}
	set checked(value: boolean) {this._checked = value;}
}

/** Type utilisé pour les composants de titre d'un Item hiérarchique */
export type ItemHierarchiqueTitleType = Type<ItemTitleComponent<any>> | Type<ListItemTitleComponent>;

/** Type utilisé pour les composants de détail de l'item hiérarchique */
export type ItemHierarchiqueDetailType = Type<FiltreDetailComponent> | Type<CheckboxDetailComponent>;