<div class="lvi-content clickable">
    <div
            class="avatar"
            [nSelector]="{ liste: liste,item: data }">
        {{ getIconeFromLibelle(data.libelle) }}
    </div>
    <div class="lvi-body">
        <div class="title" *ngIf="data.libelle">
            <a class="hover-disabled">
                {{ data.libelle }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code">
                <strong translate>admin.parametres.demat.pieceJointes.filtre.code</strong>
                {{ data.code }}
            </li>
        </ul>
    </div>
</div>
