import { TranslateService } from '@ngx-translate/core';

import { Filter } from '../../../../domain/common/list-view';
import { AutocompleteOptions } from '../autocomplete';

/**
 * Options des types entités
 */
export default class InviteOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     */
    url(filter: any) {
        //URL
        return filter?.typeInvite == 'INTERNE' ? '/controller/Participant/filtreCollaborateurs' : '/controller/Participant/filtreExternes';
    }

    /**
     * Affichage d'un élément
     */
    displayItem(item: any) { return item ? `${item.prenom} ${item.nom}`+(item.matricule ? ` (${item.matricule})` : '')+(item.societe ? ` (${item.societe})` : '') : ''; }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService,filter?: any): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.nom'),
            clef: 'nom',
            isDefault: true
        },{
            title: translateService.instant('filter.prenom'),
            clef: 'prenom',
            isDefault: true
        },filter?.typeInvite == 'INTERNE' && {
            title: translateService.instant('filter.matricule'),
            clef: 'matricule',
            isDefault: true
        },filter?.typeInvite == 'EXTERNE' && {
            title: translateService.instant('filter.societe'),
            clef: 'societe',
            isDefault: true
        }].filter(f => !!f)
    }
}