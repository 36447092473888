import { Component } from "@angular/core";

/**
 * Onglet API REST
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'api-rest'},
    templateUrl: './api-rest.component.html'
})
export class ApiRestComponent {}
