import {Component,QueryList,ViewChild} from '@angular/core';
import {MatDialog,MatDialogRef} from "@angular/material/dialog";
import {MatTab} from "@angular/material/tabs/tab";
import {ActivatedRoute,Params,Router} from '@angular/router';
import {AnalytiqueComponent} from '@components/analytique/analytique.component';
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {SuiviBudgetComponent} from "@components/budget/suivi/suivi-budget.component";
import {ChainageService} from '@components/chainage/chainage.service';
import {ODGeneralitesComponent} from '@components/od/detail/generalite/od-generalites.component';
import {OdGeneralitesSaisieTempsComponent} from '@components/od/detail/generalite/saisie-temps/od-generalites-saisie-temps.component';
import {ODVoyageComponent} from '@components/od/detail/voyage/od-voyage.component';
import {ODVoyageService} from "@components/od/detail/voyage/od-voyage.service";
import {MapCreationParticipantType} from "@components/od/detail/voyage/participants/map-creation-participants.type";
import {ODService} from '@components/od/od.service';
import {ReportingService} from "@components/reporting/reporting.service";
import {TypeEntiteService} from "@components/type-entite/type-entite.service";
import {AbstractObjetWorkflowComponent,OngletsAdmin} from '@components/workflow/abstract-objet-workflow.component';
import {WorkflowService} from '@components/workflow/workflow.service';
import {AppState} from '@domain/appstate';
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {Result,TypeCodeErreur} from '@domain/common/http/result';
import {CollabPresence,Od} from "@domain/od/od";
import {SaisieTemps} from "@domain/od/saisie-temps";
import {FaitGenerateur} from "@domain/om/fait-generateur";
import {ModeParticipants} from "@domain/settings/ns-params";
import {SettingsODState} from '@domain/settings/settings';
import {GestionTemps,TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {TypeProfil} from '@domain/user/user';
import {TypeAiguillage,TypeAiguillageLiteral} from "@domain/voyage/travel/constants";
import {IMapData} from "@domain/workflow/IMapData";
import {TypeAction as TypeActionWorkflow,TypePortee,TypePorteeInt} from '@domain/workflow/workflow';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import * as typeEntiteActions from "@reducers/type-entite";
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {PageHeaderComponent} from "@share/component/page-header/page-header.component";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ToastrService} from 'ngx-toastr';
import {Observable,of} from "rxjs";
import {finalize,first,mergeMap} from 'rxjs/operators';
import {Location} from "@angular/common";
//On force l'import de mergeMap sinon il considère qu'il est importé alors qu'en fait non ¯\_(ツ)_/¯
import 'rxjs/add/operator/mergeMap';
import {GeographieView} from "@domain/geographie/geographieView";
import {SettingsService} from "@components/settings/settings.service";

/**
 * Composant d'affichage d'un OD
 *
 * @author Laurent SCIMIA
 * @date 04/01/2022
 */
@Component({
	selector: 'od',
	templateUrl: './od.component.html',
	providers: [ODVoyageService], //Service de gestion des voyages (il est providé ici afin d'avoir une durée de vie calquée sur celle de l'od)
})
export class ODComponent extends AbstractObjetWorkflowComponent<Od,SettingsODState> {
	/** Accès à l'enum dans la vue */
	readonly NiveauAlerte = NiveauAlerte;
	readonly TypePorteeInt = TypePorteeInt;
	readonly TypePortee = TypePortee;
	readonly OngletsAdmin = OngletsAdmin;

	/** true si le chainage de l'OD contient une émission */
	private hasEmissionChainage: boolean;

	/** Type d'aiguillage de l'OD */
	aiguillageOd: TypeAiguillage;

	/** Getter de l'onglet sélectionné */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/** Setter de l'onglet sélectionné (on le surcharge pour gérer le chargement des onglets) */
	set selectedItem(item: PageHeaderItem) {
		//Edition de la variable privée
		this._selectedItem = item;

		//Bascule du statut de chargement de l'onglet voyage
		if (item.code == 'VOYAGE') {
			this.isVoyageLoaded = true;
		}

		//Bascule du statut de chargement de l'onglet généralité
		if (item.code == 'GENERALITES') {
			this.isGeneraliteLoaded = true;
		}
	}

	/** Indicateur de modification possible de l'analytique */
	canModifierAnalytique: boolean = false;

    /** Nombre de préférences analytiques disponibles */
	preferenceAnalytiqueCount: number = 0;

	/** Répartition analytique */
	@ViewChild(AnalytiqueComponent)
	private analytique: AnalytiqueComponent;

	/** Généralités */
	@ViewChild(ODGeneralitesComponent)
	private odGeneralitesComponent: ODGeneralitesComponent;

	/** Composant enfant SuiviBudget */
	@ViewChild(SuiviBudgetComponent)
	suiviBudgetComponent: SuiviBudgetComponent;

	/** Composant enfant ODVoyage */
	@ViewChild(ODVoyageComponent)
	odVoyageComponent: ODVoyageComponent;

	/** Paramètres du type entité */
	typeEntiteParam: TypeEntiteParamOD = null;

	/** Liste des faits générateurs */
	listeFaitGenerateurs: FaitGenerateur[] = null;

	/** Indique si l'onglet des voyages est chargé */
	isVoyageLoaded: boolean = false;

	/** Indique s'il s'agit d'un OD invité */
	isOdInvite: boolean;

	/** Indique si l'onglet généralité est chargé */
	isGeneraliteLoaded: boolean = false;

	/** Mode participant pour l'OD récupéré du type entité ou à défaut de ns_params */
	modeParticipant: ModeParticipants;

	/** Indique si l'onglet voyage doit être disponible ou non */
	isOngletVoyageActif: boolean = false;

	/** Indique si l'OD est susceptible de contenir du travel */
	isVoyageAutorise: boolean = false;

	/** Nombre de lancements de la synchro du WF en cours d'exécution */
	counterSyncWF: number = 0;

	/**
	 * Constructeur
	 * @param translateService  Service de traductions
	 * @param store             Store de l'appli
	 * @param toastrService     Service de gestion des toast
	 * @param activatedRoute    Route active
	 * @param router            Service de routing
	 * @param workflowService   Service des objets workflow
	 * @param settingsService Service des objets workflow lié aux PJ
	 * @param chainageService   Service des chainages
	 * @param analytiqueService Service de l'analytique
	 * @param odService         Service de l'OD
	 * @param typeEntiteService Service des types entité
	 * @param odVoyageService   Service de voyage
	 * @param reportingService  Service de gestion des rapports
	 * @param matDialog         Service de popup
	 * @param location			Location (URL)
	 * @param pleaseWaitService Service d'affichage du loading
	 */
	constructor(
		protected translateService: TranslateService,
		protected store: Store<AppState>,
		protected toastrService: ToastrService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router,
		protected workflowService: WorkflowService,
		protected settingsService: SettingsService,
		protected chainageService: ChainageService,
		protected analytiqueService: AnalytiqueService,
		protected odService: ODService,
		private typeEntiteService: TypeEntiteService,
		private odVoyageService: ODVoyageService,
		protected reportingService: ReportingService,
		protected matDialog: MatDialog,
		protected location: Location,
		private pleaseWaitService: PleaseWaitService
	) {
		super(
			translateService,
			store,
			toastrService,
			activatedRoute,
			router,
			workflowService,
			chainageService,
			analytiqueService,
			reportingService,
			matDialog,
			location,
			TypePortee.OD,
			odService
		);
	}

	/**
	 * Intercepte les clics sur les onglets, pour pouvoir enregistrer l'OD au changement d'onglet, et empêcher le changement d'onglet en cas d'erreur.
	 */
	registerTabClick(): void {
		//Le ViewChild #pageHeader n'est pas accessible directement au moment du ngAfterViewInit à cause du *ngIf="objetWorkflow"
		//Donc on s'abonne au 1er changement, qui a lieu quand le composant pageHeader devient visible et accessible
		this.pageHeaders.changes.pipe(first()).subscribe((comps: QueryList<PageHeaderComponent>) => {
			//Récupération du composant PageHeader (il n'y en a qu'un)
			const pageHeader = comps.first;

			//Récupération du click handler par défaut
			const handleTabClick = pageHeader.tabGroupe._handleClick;

			//Modification du click handler
			pageHeader.tabGroupe._handleClick = (tab: MatTab,tabHeader: any,index: number) => {
				// Si on est sur l'onglet Généralités et qu'on clique sur un autre onglet et que les infos concernant les IJ ont été modifiées (on lance le save) OU si le formulaire n'est pas valides (on lance le save pour lancer la gestion d'erreur)
				if (this.objetWorkflow?.getMapAction()?.canModifier?.possible && this.selectedItem?.code === 'GENERALITES' && this.listeTabItems[index].code !== 'GENERALITES' && this.isInfosIJModifiees() || !this.isValid()) {
					//On enregistre l'OD (si c'est possible)
					this.save().pipe(first()).subscribe(isSuccess => {
						//Vérification que l'enregistrement a réussi
						if (isSuccess) {
							//On exécute click handler par défaut pour changer d'onglet
							handleTabClick.apply(pageHeader.tabGroupe,[tab,tabHeader,index]);
						}
					});
				} else {
					//Si on ne passe pas de l'onglet Généralités vers un autre, ou qu'on n'a pas les droits de modification, on ne fait rien à part exécuter le click handler par défaut
					handleTabClick.apply(pageHeader.tabGroupe,[tab,tabHeader,index]);
				}
			};
		})
	}

	/**
	 * Indique si les infos de l'OD concernant les IJ ont été modifiées
	 * (destination, date et heure de départ, date et heure de retour)
	 *
	 * @returns {boolean} true si c'est modifié sinon false
	 */
	isInfosIJModifiees(): boolean {
		//On parcourt toutes les clés de l'objet de backup
		for (let cle of Object.keys(this.backupOD)) {
			//Si la valeur de l'objet n'est pas la même que celle du backup
			if (this.objetWorkflow[cle] !== this.backupOD[cle]) {
				//On indique qu'il y a eu modification des informations des IJ
				return true;
			}
		}

		//Si on arrive ici, c'est que rien n'a bougé
		return false;
	}

	/** Données de backup de l'OD. On va s'en servir pour savoir s'il a été modifié */
	backupOD: {
		destination: GeographieView,
		depart_le: Date,
		retour_le: Date,
		heureDepart: string,
		heureRetour: string
	}
		= {
		destination: null,
		depart_le: null,
		retour_le: null,
		heureDepart: null,
		heureRetour: null
	}

	/**
	 * Initialisation des onglets
	 */
	initTabs(): void {
		//Définition des onglets
		this.listeTabItems = [{
			code: 'GENERALITES',
			libelle: this.translateService.instant('od.navigation.generalites')
		},{
			code: 'FRAIS',
			libelle: this.translateService.instant('od.navigation.frais')
		},{
			code: 'ENGAGEMENTS',
			libelle: this.translateService.instant('od.navigation.engagements')
		}];

		if (this.user.fonction === TypeProfil.RESPONSABLE || this.user.fonction === TypeProfil.ASSISTANT || this.user.fonction === TypeProfil.COMPTABLE || this.isAdmin) {
			this.listeTabItems.push({
				code: 'FICHECOLLABORATEUR',
				libelle: this.translateService.instant('od.navigation.ficheCollaborateur'),
			});
		}

		this.listeTabItems.push({
			code: 'COMPLEMENTS',
			libelle: this.translateService.instant('od.navigation.complements')
		});

		//On regarde si l'onglet voyage doit être activé
		if (this.isOngletVoyageActif) {
			//Définition de l'onglet "Voyage"
			let ongletVoyage = {
				code: 'VOYAGE',
				libelle: this.translateService.instant('od.navigation.voyage')
			};

			//On l'ajoute juste après l'onglet général
			this.listeTabItems.splice(1,0,ongletVoyage);
		}

		//Ajout de l'onglet outils
		super.initTabs();
	}

	/**
	 * Retourne l'onglet des généralités
	 */
	getDefaultTab(): PageHeaderItem {
		return this.listeTabItems.find(tab => tab.code === 'GENERALITES');
	}

	/**
	 * Synchronisation du WF de l'objet
	 *
     * @param message message à traduire utilisé pour les toasts
	 */
	onSynchroNeeded(message: string = null): void {
		//Mise à jour l'indicateur informant que le traitement est en cours
		super.isSaving = true;

		//Incrémentation du nombre de synchro WF en cours
		this.counterSyncWF++;

		//Synchro Workflow
		this.workflowService.synchroWorkflow('OD',this.objetWorkflow.getId()).subscribe({
			next: result => {
				//Rechargement de l'OD
				super.reloadObjetAfterSave(this.objetWorkflow.getId())
					.pipe(first(),finalize(() => {
						//Synchro et rechargement de l'objet terminés : décrémentation du nombre de synchro WF en cours (utilisation de math.max comme garde-fou)
						this.counterSyncWF = Math.max(this.counterSyncWF - 1,0);

						//Mise à jour l'indicateur s'il n'y a plus de synchro en cours
						if (this.counterSyncWF === 0) {
							super.isSaving = false;
						}

                        //Rechargement de l'onglet voyage si le composant est présent (et donc déjà chargé, sinon inutile puisqu'il se chargera à l'activation de l'onglet le cas échéant)
                        this.odVoyageComponent?.loadVoyage();
					}))
					.subscribe(() => {
							//Gestion du toast de confirmation
							if (result.codeErreur != TypeCodeErreur.NO_ERROR) {
								this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
							} else {
								//On affiche le toast selon le message
								if (message != null) {
									this.toastrService.success(this.translateService.instant(message));
								} else {
									//Toast par défaut indiquant le succès de l'enregistrement
									this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
								}
							}
						},
						() => {
							//Affichage d'un message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
						});
			},
			error: () => {
				//Affichage d'un message d'erreur
				this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));

				//Décrémentation du nombre de synchro WF en cours (utilisation de math.max comme garde-fou)
				this.counterSyncWF = Math.max(this.counterSyncWF - 1,0);

				//Mise à jour l'indicateur s'il n'y a plus de synchro en cours
				if (this.counterSyncWF === 0) {
					super.isSaving = false;
				}
			}
		});
	}

	/**
	 * Recharge l'objet après un enregistrement.
	 * @param objetId Identifiant de l'objet
	 */
	protected reloadObjetAfterSave(objetId: any): Observable<Od> {
		return super.reloadObjetAfterSave(objetId).pipe(mergeMap((objet) => {
			/** Chargement des informations du suivi budgétaire */
			this.suiviBudgetComponent.loadSuiviBudget();

			return of(objet);
		}));
	}

	/**
	 * Construction de l'OD à partir des données brutes non typées.
	 *
	 * @param data données brutes non typées
	 * @returns une instance de l'OD
	 */
	protected buildLoadedObjet(data: any): Od {
		//Mise à jour de l'OD
		const od: Od = new Od(data.od);

		//Heures
		od.heureDepart = od.heureDepart.replace("H",":");
		od.heureRetour = od.heureRetour.replace("H",":");

		//Définition de la géographie
		od.destination = data.destination;

		//Paramétrages
		this.listeFaitGenerateurs = data.listeFaitGenerateurs;
		this.typeEntiteParam = data.typeEntiteParam;
		this.hasEmissionChainage = data.hasEmissionChainage;

		//Récupération de l'aiguillage
		this.aiguillageOd = TypeAiguillageLiteral.getTypeAiguillage(od.aiguillage);

		//On vérifie s'il s'agit d'un OD d'un invité
		this.isOdInvite = od.collabPresence == CollabPresence.INVITE;

		//Si le mode des participants du type entité est celui par défaut
		if (this.typeEntiteParam.participant == ModeParticipants.DEFAUT) {
			//On prend celui de ns_params
			this.modeParticipant = (this.settings as SettingsODState).modeParticipants;
		} else {
			//Sinon on récupère le mode du type entité
			this.modeParticipant = this.typeEntiteParam.participant;
		}

		//On vérifie si l'OD peut contenir un voyage
		this.isVoyageAutorise = this.typeEntiteParam.prestaAvion || this.typeEntiteParam.prestaTrain || this.typeEntiteParam.prestaHebergement || this.typeEntiteParam.prestaVoiture || this.typeEntiteParam.prestaAutre;

		//On vérifie si on doit afficher l'onglet voyage
		this.isOngletVoyageActif = this.modeParticipant !== ModeParticipants.AUCUN || this.isVoyageAutorise;

		//Vérification que l'OD est en modification
		if (od.canModifier()) {
			//vérification du cas d'un OD lié à un OMP parent
			if (od.omPermanent?.idOMPermanent) {
				//OD lié : vérification de la configuration du type entité du parent
				this.typeEntiteService.isAnalytiqueHeriteModifiable(od.typeEntite?.idTypeEntite,TypePortee.OD,TypePortee.OT)
					.subscribe(isEditable => {
						this.canModifierAnalytique = isEditable;
					});
			} else {
				//OD non lié : modification de l'analytique possible
				this.canModifierAnalytique = true;
			}
		}

		this.preferenceAnalytiqueCount = data.preferenceAnalytiqueCount;

		//Chargement du type entité et du paramétrage associé à la portée de l'objet
		this.store.dispatch({
			type: typeEntiteActions.FIND_TYPE_ENTITE,
			payload: {
				idTypeEntite: od.typeEntite?.idTypeEntite,
				portee: TypePortee.OD
			}
		});

		//On envoie les informations au service de voyage pour un filtre des SBT disponibles
		this.odVoyageService.profilConnexion = data.profilConnexion;
		this.odVoyageService.aiguillageOd = od.aiguillage;
		this.odVoyageService.typeEntiteParam = this.typeEntiteParam;

		//On enregistre les infos de l'OD au chargement pour vérifier si elles ont changé
		this.initBackupData(od);

		//Retour
		return od;
	}

	/**
	 * Construction d'un nouvel OD.
	 *
	 * @returns une instance de l'OD
	 */
	protected buildNewObjet(): Promise<Od> {
		//Retour
		return new Promise<Od>((resolve) => {
			resolve(new Od());
		});
	}

	/**
	 * On s'interpose avant la réalisation de l'action Workflow pour gérer les différents cas
	 *
	 * @param typeAction type d'action
	 */
	doBeforeWorkflowAction(typeAction: TypeActionWorkflow): Observable<IMapData> {
		//Si on tente une émission et que la saisie des temps est activée sur le type entité et que le segment d'émission a la case cochée
		if (typeAction === TypeActionWorkflow.EMETTRE && this.typeEntiteParam.gestionTemps !== GestionTemps.TEMPS_INACTIF && this.objetWorkflow?.getMapAction()?.actionsSupplementaires?.canOdDefinirTemps?.possible) {
			this.isSaving = true;
			//On initialise la saisie des temps
			return this.odService.loadSaisieTemps(this.objetWorkflow.idOd).pipe(first(),finalize(() => this.isSaving = false))
				.mergeMap<SaisieTemps,IMapData>((saisie) => {

					//On ouvre la popup de saisie des temps
					return this.openSaisieTemps(this.objetWorkflow,saisie,true).afterClosed()
						.mergeMap<boolean,IMapData>((data: boolean) => {
							//Si la popup se termine avec true c'est que la saisie est enregistrée, sinon c'est qu'on a quitté la popup et on ne fait rien.
							if (data) {
								//Du coup, on continue
								return this.continueActionWF(typeAction);
							} else {
								//Sinon c'est qu'on a quitté la popup et on ne fait rien
								return of(<IMapData>{isOk: false});
							}
						});
				});
		} else {
			//On peut continuer l'action
			return this.continueActionWF(typeAction);
		}
	}

	/**
	 * Renvoie la popup de la saisie des temps initialisée
	 *
	 * @param od            Od concerné par la saisie
	 * @param saisie        Saisie à afficher
	 * @param canModifier   true si la popup doit être modifiable
	 * @return l'instance de la popup
	 */
	openSaisieTemps(od: Od,saisie: SaisieTemps,canModifier: boolean = false): MatDialogRef<OdGeneralitesSaisieTempsComponent,boolean> {
		return this.matDialog.open<OdGeneralitesSaisieTempsComponent,any,boolean>(OdGeneralitesSaisieTempsComponent,{
				data: {
					canModifier: canModifier,
					od: od,
					saisie: saisie,
					saveSaisieTemps: (idOd: number,saisie: SaisieTemps) => {
						return this.odService.saveSaisieTemps(idOd,saisie);
					}
				},
				minWidth: 400,
				maxWidth: 550
			},
		);
	}

	/**
	 * On vient se brancher entre la saisie des temps et la réelle exécution de l'action WF
	 * pour gérer les cas d'émission avec participant.
	 *
	 * @param typeAction            type d'action
	 *
	 */
	continueActionWF(typeAction: TypeActionWorkflow): Observable<IMapData> {
		//Dans le cas d'une émission avec la création d'OD pour les participants d'activée, et s'il n'y a pas déjà eu une émission sur cet OD.
		if (typeAction == TypeActionWorkflow.EMETTRE && (this.settings as SettingsODState).isCreationPourParticipant && !this.hasEmissionChainage && !this.isOdInvite) {

			//On charge la liste des participants concernés
			return this.odService.getListeParticipantsPourCreationOd(this.objetWorkflow.idOd).mergeMap<Result,IMapData>(result => {
				//S'il n'y a pas d'erreur à la récupération
				if (result.codeErreur == 0) {
					//S'il y a des participants concernés
					if (result.data.listeUsers?.length > 0) {

						//On ouvre la popup de création pour les participants
						return this.odService.openCreationParticipantOd(result.data.listeUsers,(this.settings as SettingsODState).isEmissionPourParticipant)
							.afterClosed().mergeMap<MapCreationParticipantType,IMapData>(data => {
								//Si la popup se termine avec des valeurs, c'est que la saisie est validée
								if (data) {
									//On renvoie les valeurs de la popup
									return of({isOk: true,isCheckAllCreation: data.isCheckAllCreation,isCheckAllEmission: data.isCheckAllEmission});
								} else {
									//Sinon c'est qu'on a quitté la popup et on ne fait rien
									return of({isOk: false});
								}
							});
					} else {
						//S'il n'y a pas de participants, on renvoie que tout est bon
						return of({isOk: true});
					}
				} else {
					//On renvoie qu'il y a eu une erreur
					return of({isOk: false});
				}
			})
		} else {
			//Il n'y a rien à faire, on renvoie que c'est bon
			return of({isOk: true});
		}
	}

	/**
	 * Vérifie si le formulaire est valide. Utilisé pour autoriser l'enregistrement d'un formulaire ou des traitements admin.
	 *
	 * @return boolean True si le formulaire est valide, False sinon
	 */
	isValid(): boolean {
		return this.odGeneralitesComponent?.isValid() && this.analytique?.isValid();
	}

	/**
	 * Préparation de l'OD avant sauvegarde.
	 *
	 * @param od OD à préparer
	 * @returns OD préparé
	 */
	protected beforeSaveObjet(od: Od): Od {
		//Met à jour od à partir des valeurs saisies
		this.odGeneralitesComponent.beforeSave();

		//Retour
		return od;
	}

	/**
	 * Rechargement de l'od après modification des dégrèvements depuis le composant enfant dédié
	 */
	onDegrevementsChanged() {
		super.reloadObjet(this.objetWorkflow.idOd).subscribe();
	}

	/**
	 * Gestion des erreurs lors de l'enregistrement
	 *
	 * @param result les résultat de l'enregistrement
	 */
	protected onSaveError(result: Result) {
		//Vérification du code d'erreur du résultat retourné
		if (result.codeErreur === TypeCodeErreur.ERROR_VALIDATE_INPUT) {
			//Vérification du cas où la destination de l'OD n'a pas de code devise en base
			if (result.data.destinationSansDevise) {
				//Message d'erreur spécifique
				this.toastrService.error(this.translateService.instant('od.errors.aucuneDevisePourDest'));
			} else {
				//Message générique informant que la validation des données côté back a échoué (TODO : Faire remonter le détail à l'utilisateur)
				this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
			}
		} else {
			//Gestion générique de l'erreur
			super.onSaveError(result);
		}
	}

	/**
	 * Récupération des paramètres du contexte de navigation
	 *
	 * @param activatedRouteParams query params de la route courante
	 */
	protected initRoutingContext(activatedRouteParams: Params): void {
		//On goback, retour sur le menu missions pour l'admin et le sous-admin
		if (this.isAdmin || this.user.fonction == TypeProfil.SOUS_ADMINISTRATEUR) {
			this.routingContext = {
				returnRoute: 'Admin/Voyages/Missions',
				extras: {
					//Permet de retourner sur le bon onglet du menu missions
					queryParams: {
						tabIndex: 0
					}
				}
			}
		} else if (!!activatedRouteParams?.idFacture && !!activatedRouteParams?.idReleve) {
			this.routingContext = {
				returnRoute: `/ReleveFacture/${activatedRouteParams?.idReleve}/Facture/${activatedRouteParams?.idFacture}`
			};
		} else {
			//Sinon retour à la liste des OD
			this.routingContext = {
				returnRoute: 'ListeOD'
			};
		}
	}

	/**
	 * Initialise la donnée de backup de l'objet avec les valeurs de l'objet en paramètre
	 *
	 * @param od OD d'initialisation
	 */
	private initBackupData(od: Od): void {
		//On parcourt toutes les clés qu'on veut backuper
		for (let cle of Object.keys(this.backupOD)) {
			//Pour chaque clé, on récupère sa valeur actuelle de l'OD
			this.backupOD[cle] = od[cle];
		}
	}
}