<h1 mat-dialog-title>
    <span translate>ndf.frais.motifRejet.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div>
        <form autocomplete="off" id="motifForm" #motifForm="ngForm">
            <fieldset>
                <div class="row">
                    <div class="col-md-10">
                        <span translate>ndf.frais.motifRejet.renseigner</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <mat-form-field class="d-flex">
                            <textarea matInput rows="3" name="remarque" [(ngModel)]="motif" required maxlength="2000"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>    
</div>
<div align="end">
    <button mat-stroked-button color="warn" (click)="onRejeterFrais()" [disabled]="motifForm.invalid" >
        <span translate>ndf.frais.rejeterFrais</span>
    </button>
</div>
