<h1 mat-dialog-title>
    <span translate [ngClass]="{'warning': !!this.data.deviseSecondaire}">od.voyage.detailNature</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="page-header warning" *ngIf="!!this.data.deviseSecondaire">
    <card-alerte [niveau]="NiveauAlerte.WARNING">
        <preview-alerte [alerte]="alerte" [translateAlerte]="true"></preview-alerte>
    </card-alerte>
</div>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <!-- Train -->
            <div class="listview-item" *ngIf="data.proposition.coutTrain > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="icon-train nio align-middle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.train</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.coutTrain | montant:data.devisePrimaire }}</li>
                            <li *ngIf="data.proposition.coutTrainOrigine > 0"><strong
                                    translate>od.voyage.montantOrigine</strong>{{ data.proposition.coutTrainOrigine | montant:data.deviseSecondaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Avion -->
            <div class="listview-item" *ngIf="data.proposition.coutAvion > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="icon-avion nio align-middle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.avion</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.coutAvion | montant:data.devisePrimaire }}</li>
                            <li *ngIf="data.proposition.coutAvionOrigine > 0"><strong
                                    translate>od.voyage.montantOrigine</strong>{{ data.proposition.coutAvionOrigine | montant:data.deviseSecondaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Voiture -->
            <div class="listview-item" *ngIf="data.proposition.coutVoiture > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="icon-voiture nio align-middle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.voiture</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.coutVoiture | montant:data.devisePrimaire }}</li>
                            <li *ngIf="data.proposition.coutVoitureOrigine > 0"><strong
                                    translate>od.voyage.montantOrigine</strong>{{ data.proposition.coutVoitureOrigine | montant:data.deviseSecondaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Hebergement -->
            <div class="listview-item" *ngIf="data.proposition.coutHebergement > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="material-icons-outlined">hotel</i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.hebergement</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.coutHebergement | montant:data.devisePrimaire }}</li>
                            <li *ngIf="data.proposition.coutHebergementOrigine > 0"><strong
                                    translate>od.voyage.montantOrigine</strong>{{ data.proposition.coutHebergementOrigine | montant:data.deviseSecondaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Autre -->
            <div class="listview-item" *ngIf="data.proposition.coutDivers > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="material-icons-outlined">help_outline</i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.autre</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.coutDivers | montant:data.devisePrimaire }}</li>
                            <li *ngIf="data.proposition.coutDiversOrigine > 0"><strong
                                    translate>od.voyage.montantOrigine</strong>{{ data.proposition.coutDiversOrigine | montant:data.deviseSecondaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Frais d'agence -->
            <div class="listview-item" *ngIf="data.proposition.fraisAgence > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.fraisAgence</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.fraisAgence | montant:data.devisePrimaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Meilleur Tarif -->
            <div class="listview-item" *ngIf="data.proposition.lowestFare > 0">
                <div class="lvi-content">
                    <div class="avatar">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" translate>od.voyage.meilleurTarif</div>
                        </div>
                        <ul class="lvi-attrs">
                            <li><strong translate>od.voyage.montantTotal</strong>{{ data.proposition.lowestFare | montant:data.devisePrimaire }}</li>
                            <li *ngIf="data.proposition.lowestFareOrigine > 0"><strong
                                    translate>od.voyage.montantOrigine</strong>{{ data.proposition.lowestFareOrigine | montant:data.deviseSecondaire }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
