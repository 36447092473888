import { NgModule } from '@angular/core';

import { ShareModule } from '../../share/share.module';
import { TypeEntiteService } from "./type-entite.service";
import { EffectsModule } from "@ngrx/effects";
import { TypeEntiteEffects } from "./type-entite.effects";

@NgModule({
    imports: [ShareModule,EffectsModule.forFeature([TypeEntiteEffects])],
    declarations: [],
    providers: [TypeEntiteService]
})
export class TypeEntiteModule {}