import {Routes} from "@angular/router";
import {FrameRouteComponent} from "@share/layout/frame-route.component";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Fichier prévisionnel pour le futur composant V10
 * TODO: Appliquer les évolutions appropriées le moment venu
 */

/**
 * Droits de routage correspondant
 * TODO: Adapter, déplacer ou supprimer en fonction du besoin pour la page
 */
export const parametreAuthentificationRoutes : Routes = [
    {
        path: 'Generalites',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider]
    }, {
        path: 'SAML',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider]
    }, {
        path: 'ListeNoire',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider]
    }, {
        path: 'ComptesVerrouilles',
        component: FrameRouteComponent, //TODO: Implémenter le vrai composant v10
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_COMPTES_VERROUILLES] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ComptesVerrouilles'
    }, {
        path: '**',
        redirectTo: 'ComptesVerrouilles'
    }
];
