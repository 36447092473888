/**
 * Énumération des différents types de référentiels
 *
 * @author Laurent Convert
 * @date 25/04/2023
 */
export enum TypeReferentiel {
	TYPE_PRESTATION = 'D',
	FAMILLE = 'F',
	INDEMNITE = 'I',
	LIASSE = 'L',
	MOTS_CLEFS = 'M',
	RUBRIQUE = 'R',
	TYPE_VEHICULE = 'V',
	NS_UNITE_DISTANCE = 'Distance',
	NS_UNITE = 'Unite'
}

/**
 * Énumération des différents types de référentiels avec valeur numérique
 */
export enum TypeReferentielNumber {
	DOMAINE_TAXE = 0,
	PUISSANCE = 1,
	CATEGORIE = 2,
	TYPE_PRESTATION = 3,
	STATUT_COL = 4,
	FAMILLE = 5,
	SUB_UNIT = 6,
	TRAVELLER_TYPE = 7,
	INDEMNITE = 8,
	CATEGORIE_PARC = 9,
	LIASSE = 10,
	MOTS_CLEFS = 11,
	AVANTAGE_NATURE = 12,
	PRESTATION = 13,
	QUALIFICATION = 14,
	RUBRIQUE = 15,
	SEJOUR = 16,
	SEJOUR_SOUS_UNITE = 17,
	TITRE = 18,
	UNITE = 19,
	UNITE_KM_BAREME = 20,
	TYPE_VEHICULE = 21,
	MOTS_CLEFSWF = 22,
	OM_LISTE = 23,
	NS_UNITE = 24,
	NS_UNITE_DISTANCE = 25
}
