import {NgModule} from '@angular/core';
import {ShareModule} from '../../share/share.module';
import {ParticipantsService} from "./participants.service";
import {ParticipantListItemComponent} from "./participant-list-item.component";
import {ParticipantsPopinComponent} from "./popin/participants-popin.component";
import {ParticipantsPopinExterneComponent} from "./popin/externe/participants-popin-externe.component";
import {ParticipantsPopinInterneComponent} from "./popin/interne/participants-popin-interne.component";

@NgModule({
  imports: [ShareModule],
  declarations: [ParticipantListItemComponent,ParticipantsPopinComponent,ParticipantsPopinExterneComponent,ParticipantsPopinInterneComponent],
  exports: [ParticipantListItemComponent,ParticipantsPopinComponent,ParticipantsPopinExterneComponent,ParticipantsPopinInterneComponent],
  providers: [ParticipantsService]
})
export class ParticipantsModule { }
