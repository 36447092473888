<!-- Titre -->
<h1 mat-dialog-title>
    <span translate>analytique.ajout.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>

<!--Sans onglets-->
<ng-container *ngIf="!data.isShowTab">
    <analytique-add [data]="data" [isShowVentilation]="data.isShowVentilation"></analytique-add>
</ng-container>

<!-- Avec onglets -->
<ng-container *ngIf="data.isShowTab">
    <mat-tab-group #matTabGroup dynamicHeight="dynamicHeight" animationDuration="500ms">
        <mat-tab *ngIf="data.isShowSaisie" label="{{ 'analytique.tab.saisie' | translate }}">
            <analytique-add [data]="data" [isShowVentilation]="data.isShowVentilation"></analytique-add>
        </mat-tab>
        <mat-tab *ngIf="data.isShowSelect" label="{{ 'analytique.tab.preference' | translate }}">
            <analytique-select
                    [idTypeEntite]="data.typeEntite.idTypeEntite"
                    [idMIB]="data.idMIB"
                    [idCollab]="data.idCollab"
            ></analytique-select>
        </mat-tab>
    </mat-tab-group>
</ng-container>
