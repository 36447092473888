import {Component, Input} from '@angular/core';
import {ListViewItem} from "../../../../domain/common/list-view/list-view-item";
import {CompteBancaire} from "../../../../domain/profil/compteBancaire";

/**
 * Composant pour une ligne de la liste des comptes bancaire
 */
@Component({
  selector: 'profil-donnees-compte-bancaire-list-item',
  templateUrl: './profil-donnees-compte-bancaire-list-item.component.html'
})
export class ProfilDonneesCompteBancaireListItemComponent extends ListViewItem<CompteBancaire> {
  /** Elément courant */
  @Input() data: CompteBancaire;
}
