import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Device} from "@domain/admin/entreprise/user/device";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant d'un item de la liste des devices
 */
@Component({
	host: {'data-test-id': 'device-list-item'},
	templateUrl: './device-list-item.component.html'
})
export class DeviceListItemComponent extends ListViewItem<Device> {
	/**
	 * Constructeur
	 *
	 * @param userService le service de gestion de l'utilisateur
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 * @param confirmService le service de confirmation
	 */
	constructor(
		private userService: EntrepriseUtilisateursService,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private confirmService: ConfirmService
	) {
		super();
	}

	/**
	 * Active/désactive l'appareil
	 */
	switchActivation(): void {
		//On crée une copie du device courant pour ne pas affecter le listItem tant que l'enregistrement n'est pas OK
		let device: Device = Object.assign({},this.data);

		//Activation/désactivation
		device.actif = !device.actif;

		//Appel au service
		this.userService.saveMobileDevice(device)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Est-ce une activation
					if (device.actif) {
						//Toast succès activation
						this.toastrService.success(this.translateService.instant('admin.entreprise.utilisateurs.detail.mobilite.listeDevices.succesActivation'));
					} else {
						//Toast succès désactivation
						this.toastrService.success(this.translateService.instant('admin.entreprise.utilisateurs.detail.mobilite.listeDevices.succesDesactivation'));
					}

					//Refresh liste
					this.liste.refresh();
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de l'appareil
	 */
	delete(): void {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service
				this.userService.deleteMobileDevice(this.data)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du résultat
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Refresh liste
							this.liste.refresh();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}
