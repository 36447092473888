import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ProfilConnexionParam} from "@domain/travelhub/profil-connexion-param";
import {Presta,TypeAiguillage,TypeNature,TypePortee} from '@domain/voyage/travel/constants';
import {TranslateService} from "@ngx-translate/core";
import {AdminTravelhubService} from "../../../../admin-travelhub.service";
import {filter,finalize,mergeMap} from "rxjs/operators";
import {Portee} from "@domain/portee/portee";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";

/**
 * Composant du détail d'un aiguillage
 *
 * @author Laurent Convert
 * @date 15/03/2024
 */
@Component({
	host: {'data-test-id': 'aiguillage-add'},
	templateUrl: './aiguillage-add.component.html'
})
export class AiguillageAddComponent implements OnInit {
    /* Déclaration pour accès dans le template */
    protected readonly TypeNature = TypeNature;
    protected readonly TypeAiguillage = TypeAiguillage;

    /** Portée par défaut d'un aiguillage */
    protected readonly porteeParDefaut: string = TypePortee.DV;

    /** Aiguillage en cours */
    aiguillage: ProfilConnexionParam;

    /** Liste des portées */
    private _listePortee: Array<Portee>;

    /** Liste des portées disponibles en fonction des options choisies sur le formulaire */
    get listePortee(): Array<Portee> {
        const listePorteeForNature = this.adminTravelhubService.mapTypeNaturePortees
            .get(this.aiguillage.typeNature)
            .map(tp => tp.valueOf());

        return this._listePortee.filter(portee => listePorteeForNature?.includes(portee.idPortee));
    }

    /** Liste des types d'aiguillage disponibles */
    listeTypeAiguillage: Array<{value: TypeAiguillage,libelle: string}>;

    /** Liste des configurations SBT récupérées depuis le back */
    private _listeConfigSBT: Array<TravelHubSBTConfigUsed>;

    /** Liste des configurations SBT disponibles en fonction des options choisies sur le formulaire */
    listeSBT: Array<TravelHubSBTConfigUsed> = [];

    /** Indicateur de chargement en cours */
    isLoading: boolean = true;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean;

    /** Indicateur de suppression en cours */
    isDeleting: boolean;

    /** Accesseur permettant de savoir si on est en création ou non */
    get isCreation(): boolean { return this.aiguillage?.idProfilConnexionParam === 0; }

    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean { return this.isSaving || this.isDeleting; }

    /**
     * Constructeur
     *
     * @param data Données injectées lors de l'ouverture de la popin
     * @param matDialogRef Référence de la popup
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param confirmService Service de confirmation utilisateur via modale
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     */
    constructor(@Inject(MAT_DIALOG_DATA) private data: { aiguillage: ProfilConnexionParam },
                private matDialogRef: MatDialogRef<AiguillageAddComponent>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService,
                public adminTravelhubService: AdminTravelhubService,
    ) {
        //Récupération de l'aiguillage depuis les données fournies à la popin
        this.aiguillage = data.aiguillage;
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        this.adminTravelhubService.loadProfilConnexionParam(this.aiguillage.idProfilConnexionParam)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(result => {
                //Définition de la liste des portées
                this._listePortee = result.data.listePortees;

                //Chargement de la liste des configurations SBT
                this._listeConfigSBT = result.data.listeConfigSBT;

                //Définition de la liste des types d'aiguillage disponibles à la sélection suivant le profil
                this.listeTypeAiguillage = this.adminTravelhubService.listeTypeAiguillage;

                //Chargement de la liste des configurations SBT
                this.updateListeSBT();
            },() => {
                //Message d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
            });
    }

    /**
     * Mise à jour de la liste des SBT disponibles
     */
    updateListeSBT(): void {
        let listePresta: Array<Presta>;

        //Vérification de la sélection de la nature et de l'aiguillage
        if (this.aiguillage.typeNature != null && this.aiguillage.typeAiguillage != null && this.aiguillage.typeAiguillage != TypeAiguillage.INTERNE) {
            //Mise à jour de la liste des SBT filtrés par nature
            this.listeSBT.splice(0);

            //Récupération de la liste des prestations pour la nature de l'aiguillage
            listePresta = this.adminTravelhubService.mapTypeNaturePresta.get(this.aiguillage.typeNature);

            //Définition de la liste des configurations SBT triées par nature
            this._listeConfigSBT?.filter(sbt => {
                //Filtre sur le type d'aiguillage sélectionné
                return sbt.sbtConfig.typeAiguillage === (this.aiguillage.typeAiguillage == TypeAiguillage.ONLINE ? 1 : 0);
            }).forEach(config => {
                //Intersection entre les prestations compatibles avec la nature sélectionnée et celles offertes par le SBT
                if (config.sbtConfig.listePrestation.some(presta => listePresta.map(p => p.valueOf()).includes(presta.libelle))) {
                    //Ajout à la liste des SBT filtrés
                    this.listeSBT.push(config);
                }
            });

            //Réinitialisation de la configuration sélectionnée précédemment si elle n'est plus dans la liste
            if (this.aiguillage.travelHubSBTConfigUsed != null && !this.listeSBT.some(sbt => sbt.idSBTConfigUsed === this.aiguillage.travelHubSBTConfigUsed?.idSBTConfigUsed)) {
                this.aiguillage.travelHubSBTConfigUsed = null;
            }
        } else {
            //Aucune configuration TravelHub
            this.aiguillage.travelHubSBTConfigUsed = null;
        }
    }

    /**
     * Mise à jour du libellé
     */
    updateLibelle(): void {
        //Configuration SBT utilisée
        const travelHubSBTConfigUsed = this.aiguillage.travelHubSBTConfigUsed;

        //Mise à jour du libellé
        this.aiguillage.libelle = travelHubSBTConfigUsed?.sbtConfig.agence + ' - ' + travelHubSBTConfigUsed?.libelle;
    }

    /**
     * Enregistrement de l'aiguillage
     */
    saveAiguillage(): void {
        //Début de l'enregistrement
        this.isSaving = true;

        //Mise à jour de la portée par celle par défaut
        if (this.aiguillage.portee == null || this.aiguillage.typeAiguillage !== TypeAiguillage.INTERNE) {
            this.aiguillage.portee = this.aiguillage.portee ?? this._listePortee.find(p => p.idPortee === this.porteeParDefaut);
        }

        //Enregistrement
        this.adminTravelhubService.saveProfilConnexionParam(this.aiguillage)
            .pipe(finalize(()  => this.isSaving = false))
            .subscribe(result => {
                //Vérification du résultat
                if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Message d'erreur
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de la popin avec rechargement de la liste
                    this.matDialogRef.close({
                        isOnline: result.data.isOnline,
                        isOffline: result.data.isOffline,
                        isInterne: result.data.isInterne,
                        listeAiguillageOnline: result.data.listeAiguillageOnline,
                    });
                } else if (result?.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
                    //Aiguillage existant
                    const existingAiguillage = TypeAiguillage.values().find(ta => TypeAiguillage[ta] === result.data.typeAiguillageUsed);

                    //Vérification de l'aiguillage
                    if (this.aiguillage.typeAiguillage != existingAiguillage) {
                        //Message d'erreur spécifique
                        this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.profilConnexion.messageNatureConflit',{
                            'nature': this.translateService.instant(TypeNature.traduction(this.aiguillage.typeNature)),
                            'aiguillage': this.translateService.instant(TypeAiguillage.traduction(this.aiguillage.typeAiguillage)),
                            'existingAiguillage': this.translateService.instant(TypeAiguillage.traduction(existingAiguillage)),
                        }));
                    } else {
                        //Message d'erreur spécifique
                        this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.profilConnexion.messageNatureDoublon',{
                            'nature': this.translateService.instant(TypeNature.traduction(this.aiguillage.typeNature)),
                            'aiguillage': this.translateService.instant(TypeAiguillage.traduction(this.aiguillage.typeAiguillage)),
                        }));
                    }
                } else {
                    //Message d'erreur
                    TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
                }
            },() => {+
                //Message d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
            });
    }

    /**
     * Suppression de l'aiguillage
     */
    deleteAiguillage(): void {
        //Début de la suppression
        this.isDeleting = true;

        //Demande de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(
                filter(isConfirmed => isConfirmed),
                mergeMap(() => this.adminTravelhubService.deleteProfilConnexionParam(this.aiguillage.idProfilConnexionParam)),
                finalize(()  => this.isDeleting = false)
            ).subscribe(result => {
                //Vérification du résultat
                if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Message d'erreur
                    this.toastrService.success(this.translateService.instant('global.success.suppression'));

                    //Fermeture de la popin avec rechargement de la liste
                    this.matDialogRef.close({
                        isOnline: result.data.isOnline,
                        isOffline: result.data.isOffline,
                        isInterne: result.data.isInterne,
                        listeAiguillageOnline: result.data.listeAiguillageOnline,
                    });
                } else {
                    //Message d'erreur
                    TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
                }
            }, () => {
                //Message d'erreur
                TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
            });
    }
}
