import {NgModule} from "@angular/core";

import {ShareModule} from "@share/share.module";
import {WorkflowModule} from "@components/workflow/workflow.module";
import {AnalytiqueModule} from "@components/analytique/analytique.module";
import {ReferentielExterneModule} from "@components/referentiel-externe/referentiel-externe.module";
import {DocumentModule} from "@components/document/document.module";
import {ZonePredefinieModule} from "@components/zone-predefinie/zone-predefinie.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {FactureComponent} from "@components/facture/detail/facture.component";
import {FactureGeneralitesComponent} from "@components/facture/detail/generalites/facture-generalites.component";
import {FactureDetailListComponent} from "@components/facture/detail/detail/liste/facture-detail-list.component";
import {FactureContentieuxComponent} from "@components/facture/detail/contentieux/facture-contentieux.component";
import {FactureService} from "@components/facture/facture.service";
import {ProprietaireModule} from "@share/component/proprietaire/proprietaire.module";
import {ZoneUtilisateurModule} from "@components/zone-utilisateur/zone-utilisateur.module";
import {FactureDetailListItemComponent} from "@components/facture/detail/detail/liste/facture-detail-list-item.component";
import {FactureReferenceListComponent} from "@components/facture/detail/contentieux/facture-reference-list/facture-reference-list.component";
import {FactureReferenceListItemComponent} from "@components/facture/detail/contentieux/facture-reference-list/facture-reference-list-item.component";
import {FactureReferenceAddListComponent} from "@components/facture/detail/contentieux/facture-reference-list/facture-reference-add-list.component";
import {FactureEcartsComponent} from "@components/facture/detail/contentieux/ecarts/facture-ecarts.component";
import {FactureDetailCreationComponent} from "@components/facture/detail/detail/creation/facture-detail-creation.component";
import {BudgetModule} from "@components/budget/budget.module";
import {AdminWorkflowModule} from "@components/admin/workflow/admin-workflow.module";
import {AdminOutilsModule} from "@share/component/outils/admin-outils.module";

@NgModule({
    imports: [
        ShareModule,
        WorkflowModule,
        AnalytiqueModule,
        ReferentielExterneModule,
        DocumentModule,
        ZonePredefinieModule,
        PreviewAlerteModule,
        ProprietaireModule,
        ZoneUtilisateurModule,
        BudgetModule,
        AdminWorkflowModule,
		AdminOutilsModule
    ],
    declarations: [
        FactureComponent,
        FactureGeneralitesComponent,
        FactureDetailListComponent,
        FactureDetailListItemComponent,
        FactureContentieuxComponent,
        FactureReferenceListComponent,
        FactureReferenceListItemComponent,
        FactureReferenceAddListComponent,
        FactureEcartsComponent,
        FactureDetailCreationComponent,
    ],
    providers: [FactureService],
})
export class FactureModule {}
