import {Component,OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AnalytiqueCodesProjetItemComponent} from "@components/admin/entreprise/analytique/codes-projet/codes-projets-item/analytique-codes-projet-item.component";
import {DossierDTO} from "@domain/admin/entreprise/analytique/dossierDto";
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Sorting} from "@domain/common/list-view/sorting";
import {TranslateService} from "@ngx-translate/core";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {BehaviorSubject} from "rxjs";

/**
 * Composant d'affichage de la liste des codes projets
 */
@Component({
    host: {'data-test-id': 'analytique-codes-projet'},
    selector: 'analytique-codes-projet',
    templateUrl: './analytique-codes-projet.component.html'
})
export class AnalytiqueCodesProjetComponent implements OnInit {

    /** Liste des codes projet */
    listeCodesProjet: ListView<DossierDTO, AnalytiqueCodesProjetItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param router router angular
     */
    constructor(
        private translateService: TranslateService,
        private router: Router
    ) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Initialisation de la liste des codes projet
        this.listeCodesProjet = new ListView<DossierDTO, AnalytiqueCodesProjetItemComponent>({
            uri: `/controller/Analytique/getListeDossiersResult`,
            title: this.translateService.instant('admin.entreprise.analytique.codesProjet.title'),
            component: AnalytiqueCodesProjetItemComponent,
            isFilter: true,
            defaultOrder: 'reference',
            listeFilters: [
                {
                    clef: 'libelle',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.libelle'),
                    isDefault: true
                },{
                    clef: 'reference',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.reference'),
                    isDefault: true
                },{
                    clef: 'code1',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.code1'),
                    isDefault: true
                },{
                    clef: 'code2',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.code2'),
                    isDefault: true
                },{
                    clef: 'dateDebut',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.dateOuverture'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.DATE],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                },{
                    clef: 'dateFin',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.dateFermeture'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.DATE],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                }, {
                    clef: 'isCache',
                    title: this.translateService.instant('admin.entreprise.analytique.codesProjet.actif'),
                    isDefault: false,
                    type: TypeFilter[TypeFilter.BOOLEAN],
                    typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
                }
            ]
        });

        //Définition des colonnes de tri
        this.listeCodesProjet.columns = [
            { key: 'libelle', title: 'admin.entreprise.analytique.codesProjet.libelle' },
            { key: 'reference', title: 'admin.entreprise.analytique.codesProjet.reference' },
            { key: 'code1', title: 'admin.entreprise.analytique.codesProjet.code1' },
            { key: 'code2', title: 'admin.entreprise.analytique.codesProjet.code2' },
            { key: 'dateDebut', title: 'admin.entreprise.analytique.codesProjet.dateOuverture' },
            { key: 'dateFin', title: 'admin.entreprise.analytique.codesProjet.dateFermeture' },
            { key: 'isCache', title: 'admin.entreprise.analytique.codesProjet.actif' }
        ];

        //Ajout du tri de la liste selon l'ordre voulu
        this.listeCodesProjet.sorting = new Sorting(this.listeCodesProjet.columns, "reference");

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.create(),
        }]);
    }

    /**
     * Méthode appelée lors de la création d'un nouveau code projet
     */
    create() {
        this.router.navigate(['Admin/Entreprise/Analytique/Projets/-1']);
    }
}
