<div class="row line-item-list">
    <div class="col-md-1 avatar d-flex justify-content-center">
        <i class="nio icon-ordre_de_mission m-0"></i>
    </div>
    <div class="col-md-10 body d-flex justify-content-center">
        <span class="row">
            <span class="col-md-12">
                {{ options.displayItem(item,translateService) }}
            </span>
        </span>
    </div>
</div>
