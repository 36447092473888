<div class="lvi-content clickable" (click)="isChevronOpen = data.listeAlertes?.listeAlertes?.length > 0 && !isChevronOpen">
    <div class="avatar" [ngClass]="{'danger': data.listeAlertes?.listeAlertes?.length > 0 }"
            [nSelector]="{ liste: liste,item: data, isNotSelectable: hasAlerte() }"
            [matTooltip]="'ndf.fraisPrev.erreur.tooltip' | translate" [matTooltipDisabled]="!hasAlerte()">
        <i *ngIf="data.type == TypeFrais.frais" class="nio icon-frais_ocr" style="vertical-align: middle;"></i>
        <i *ngIf="data.type == TypeFrais.previsionnel" class="nio icon-frais_previsionnel_2" style="vertical-align: middle;"></i>
        <i *ngIf="data.type == TypeFrais.rejete" class="nio icon-note_de_frais_refuse" style="vertical-align: middle;"></i> 
        <!-- <i class="nio icon-frais_refuse" style="vertical-align: middle;"></i>  -->
    </div>
    <div class="lvi-body">
        <div class="title" *ngIf="data.titre">
            <a class="hover-disabled">
                {{data.titre}}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'ndf.frais.mesFrais.type'"></strong>
                <span *ngIf="data.type == TypeFrais.frais" [translate]="'ndf.frais.mesFrais.ocr'"></span>
                <span *ngIf="data.type == TypeFrais.previsionnel" [translate]="'ndf.frais.mesFrais.previsionnel'"></span>
                <span *ngIf="data.type == TypeFrais.rejete" [translate]="'ndf.frais.mesFrais.rejete'"></span>
            </li>
            <li *ngIf="data.date">
                <strong [translate]="'ndf.frais.mesFrais.date'"></strong>
                {{data.date | date:'shortDate'}}
            </li>
            <li *ngIf="data.ville && !data.pays">
                <strong [translate]="'ndf.frais.mesFrais.ville'"></strong>
                {{data.ville}}
            </li>
            <li *ngIf="data.pays && !data.ville">
                <strong [translate]="'ndf.frais.mesFrais.pays'"></strong>
                {{data.pays}}
            </li>
            <li *ngIf="data.pays && data.ville">
                <strong [translate]="'ndf.frais.mesFrais.ville'"></strong>
                {{data.ville + ', ' + data.pays}}
            </li>
            <li *ngIf="data.montant">
                <strong [translate]="'ndf.frais.mesFrais.montant'"></strong>
                {{data.montant | montant: data.devise}}
            </li>
            <li *ngIf="data.quantite">
                <strong translate="ndf.frais.mesFrais.quantite"></strong>
                {{(data.quantite | number:'1.0-2') + ' ' +  (data.unite || '')}} 
            </li>
            <li *ngIf="data.type == TypeFrais.frais">
                <strong translate="ndf.frais.mesFrais.justificatif"></strong>
                <span translate="global.oui"></span>
            </li>
        </ul>
    </div>

    <!-- bouton supprimer -->
    <div class="lvi-actions"  *ngIf="data.type == TypeFrais.frais">
        <button mat-icon-button [matMenuTriggerFor]="listeActions" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deleteFraisOcr()"translate>global.actions.supprimer</button>
        </mat-menu>
    </div>

    <div class="lvi-actions" *ngIf="data.listeAlertes?.listeAlertes.length > 0">
        <button mat-icon-button>
            <mat-icon *ngIf="isChevronOpen" color="primary">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isChevronOpen" color="primary">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="isChevronOpen" class="p-20">
    <form autocomplete="off" #formFrais="ngForm">
        <div *ngFor="let alerte of data.listeAlertes?.listeAlertes" class="row">
            <div class="col-md-12">
                <div class="row">
                    <label class="col-md-2 danger">
                        <span [translate]="'ndf.frais.mesFrais.alerte'"></span>
                    </label>
                    <div class="col-md-10">
                        <div class="d-flex">
                            <span class="danger">{{getAlerteTranslate(alerte.type)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </form>
</div>
