<div class="lvi-content">
    <div class="avatar"><mat-icon>domain</mat-icon></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.close(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.regionAdmin"><strong translate="geographie.region"></strong>{{ data.regionAdmin.libelle }}</li>
            <li *ngIf="data.departementAdmin"><strong translate="geographie.departement"></strong>{{ data.departementAdmin.libelle }}</li>
            <li *ngIf="data.pays && data.type != 'PAYS'"><strong translate="geographie.pays"></strong>{{ data.pays.libelle }}</li>
        </ul>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <span class="flag-icon" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()" *ngIf="data.pays"></span>
    </div>
</div>