<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.selectEnveloppesPopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <list-view *ngIf="listeEnveloppes" [liste]="listeEnveloppes"></list-view>
</div>
<div mat-dialog-actions *ngIf="listeEnveloppes" style="justify-content: space-between">
    <div>
        <button mat-stroked-button color="primary" (click)="listeEnveloppes.selectAll(false)" [disabled]="isUnselectAllDisabled()">
            <span [translate]="'global.actions.toutDeselectionner'"></span>
        </button>
        <button mat-stroked-button color="primary" (click)="listeEnveloppes.selectAll(true)" [disabled]="isSelectAllDisabled()">
            <span [translate]="'global.actions.toutSelectionner'"></span>
        </button>
    </div>
    <button mat-flat-button color="primary" [mat-dialog-close]="listeEnveloppes.listeObjetsSectionnes">
        <span [translate]="'global.actions.confirmer'"></span>
    </button>
</div>