import {Component,OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

/**
 * Composant de la page Admin : Bibliothèque > Zones Utilisateurs
 */
@Component({
	host: {'data-test-id': 'zones-utilisateurs'},
	templateUrl: './zones-utilisateurs.component.html'
})
export class ZonesUtilisateursComponent implements OnInit {
	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: Onglets.ZONES_PREDEFINIES,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.tabs.predefinies')
		},{
			code: Onglets.ZONES_PARAMETRABLES,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.tabs.parametrables')
		}
	];

	/** Index de l'onglet courant */
	selectedIndex: number;

	/** Indicateur de chargement de l'onglet ZONES_PREDEFINIES */
	isZonesPredefiniesLoaded: boolean = false;

	/** Indicateur de chargement de l'onglet ZONES_PARAMETRABLES */
	isZonesParametrablesLoaded: boolean = false;

	/** Onglet courant */
	_selectedItem: PageHeaderItem;

	/** Getter de l'onglet courant */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/** Setter de l'onglet courant (on le surcharge pour gérer le chargement des onglets) */
	set selectedItem(item: PageHeaderItem) {
		//Mise à jour de _selectedItem
		this._selectedItem = item;

		//Bascule du statut de chargement de l'onglet sélectionné
		switch (item.code) {
			case Onglets.ZONES_PREDEFINIES:
				this.isZonesPredefiniesLoaded = true;
				break;
			case Onglets.ZONES_PARAMETRABLES:
				this.isZonesParametrablesLoaded = true;
				break;
		}
	}

	/** Code de l'onglet à afficher au chargement de l'écran */
	onLoadTab: string;

	/** Import des onglets pour le DOM */
	readonly Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur d'Angular
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
		//Récupération du state de la route courante
		const state = this.router.getCurrentNavigation().extras?.state;

		//Vérification de la présence d'un state sur la route
		if (state) {
			//Récupération de l'onglet à charger
			this.onLoadTab = state.tabToLoad;
		}
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On check si l'on doit afficher un onglet en particulier au chargement
		if (this.onLoadTab) {
			//Navigation vers cet onglet
			this.onSelectedItemChange(this.listeTabItems.find((value: PageHeaderItem) => value.code === this.onLoadTab));

			//Réinitialisation de onLoadTab
			delete this.onLoadTab;
		}
	}

	/**
	 * Changement d'onglet
	 *
	 * @param item l'onglet sélectionné
	 */
	onSelectedItemChange(item: PageHeaderItem) {
		//Mise à jour de l'indicateur d'activation pour chaque onglet
		this.listeTabItems?.forEach(tab => {
			tab.selected = tab === item;
		});

		//Mise à jour de l'onglet sélectionnée
		item.selected = true;
		this.selectedItem = item;
		this.selectedIndex = this.listeTabItems.findIndex(i => i.code === item.code);
	}
}

/**
 * Onglets
 */
export enum Onglets {
	ZONES_PREDEFINIES = 'ZONES_PREDEFINIES',
	ZONES_PARAMETRABLES = 'ZONES_PARAMETRABLES'
}
