import { AfterContentInit,ContentChild,Directive } from "@angular/core";
import { FloatingButtonComponent } from "@share/component/floating-button/floating-button.component";
import { FloatingButtonService } from "@share/component/floating-button/floating-button.service";

/**
 * Directive permettant de customiser les actions pour une liste.<br>
 * La directive est à appliquer sur l'élément DOM contenant le composant {@link FloatingButtonComponent}
 *
 * @author Laurent SCIMIA
 * @date 24/02/2023
 */
@Directive({
	selector: '[actionsFlottantesCustom]',
	providers: [{provide:'FLOATING_BUTTON_SERVICE', useExisting: FloatingButtonService}] //On fournit aux composants enfants le service récupéré dans cette directive pour qu'ils puissent agir sur les boutons d'action de la page
})
export class FloatingButtonDirective implements AfterContentInit {
	/** Récupération du FloatingButtonComponent que l'on va modifier */
	@ContentChild(FloatingButtonComponent) floatingButtonComponent: FloatingButtonComponent

	/**
	 * Constructeur
	 *
	 * @param floatingButtonService Service de gestion des actions. Il va être injecté aux composants fils pour qu'ils puissent tous agir sur les boutons flottants de la page
	 */
	constructor(private floatingButtonService: FloatingButtonService) {
	}

	/**
	 * Appel après initialisation du contenu de la page
	 */
	ngAfterContentInit(): void {
		//Après le chargement du contenu de la page, on donne accès au composant des boutons flottants au service
		this.floatingButtonService.initFloatingButtonService(this.floatingButtonComponent);
	}
}