import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

import {FileWithProgress, XmlUploader} from './xml-uploader';
import {XmlUploaderService} from './xml-uploader.service';
import {SettingsGlobalState} from "@domain/settings/settings";

@Directive({
    selector: '[xmlUploader]'
})
export class XmlUploaderDirective implements OnInit {
    /** Uploader **/
    @Input('xmlUploader') xmlUploader: XmlUploader;

    /** Paramétrage **/
    @Input('settings') settings: SettingsGlobalState;

    /** Evènement généré lors du drag and drop de fichiers **/
    @Output('onXmlsDrop') onXmlsDrop = new EventEmitter<Array<File>>();

    /** Classe CSS ajoutée à l'élément lors du drag sur l'élément **/
    @HostBinding('class.active')
    isActif = false;

    /** Sélecteur de fichiers **/
    inputElement: HTMLInputElement;

    /** Temps pour le compte à rebours avant upload, en seconde */
    coutdownTime: number = 5;

    /**
     * Constructeur
     */
    constructor(private element: ElementRef,
                private xmlUploaderService: XmlUploaderService,
                private toastrService: ToastrService,
                private translateService: TranslateService) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Ajout de la classe
        this.element.nativeElement.classList.add('file-uploader');

        //Création du sélecteur de fichiers
        this.createFileInput();

        //Définition des méthodes de l'uploader
        this.wrapUploaderMethods();
    }

    /**
     * Création du sélecteur de fichiers
     */
    createFileInput() {
        //Création du sélecteur de fichier
        this.inputElement = document.createElement('input');

        //Définition du sélecteur de fichier
        this.inputElement.setAttribute('type','file');
        this.inputElement.setAttribute('accept','.xml');

        //Définition du style
        this.inputElement.style.display = 'none';

        //Interception de la sélection de fichiers
        this.inputElement.onchange = (event) => {
            //Parcours des fichiers sélectionnés
            for (let idxFile = 0;idxFile < this.inputElement.files.length;idxFile++) {
                //Vérification du type de fichier
                if (this.inputElement.files.item(idxFile) instanceof File) {
                    //Récupération de l'extension
                    const uploadedExtension = this.inputElement.files.item(idxFile).name.split('.').pop().toLowerCase();

                    //Récupération de la taille en ko
                    const size = this.inputElement.files.item(idxFile).size / 1024;

                    //Vérification de l'extension du fichier, si l'extension n'existe pas
                    if ("xml" !== uploadedExtension) {
                        this.toastrService.error(this.translateService.instant('global.errors.fileBlocked',{ fileName: this.inputElement.files.item(idxFile).name }));
                    } else if (this.settings.tailleMax != null &&  this.settings.tailleMax < size) {
                        //Si le fichier est trop gros
                        this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: this.inputElement.files.item(idxFile).name }));
                    } else {
                        //Ajout du fichier à la queue
                        this.xmlUploader.file = this.inputElement.files.item(idxFile);

                        //Déclenchement de l'intercepteur de fichier ajouté
                        this.xmlUploader.onItemAdded && this.xmlUploader.onItemAdded(this.inputElement.files.item(idxFile));
                    }
                }
            }

            //Remise à zéro du sélecteur
            this.inputElement.value = '';
        };

        //Ajout du sélecteur de fichiers
        this.element.nativeElement.append(this.inputElement);
    }

    /**
     * Sélection d'un fichier via un clic sur le composant
     */
    @HostListener('click')
    onClick() {
        //Ouverture du sélecteur de fichiers
        this.inputElement.click();
    }

    /**
     * Gestion du drop
     */
    @HostListener('drop',['$event'])
    onDrop(event: DragEvent) {
        let listeFiles;

        //Lecture du DataTransfer
        const { dataTransfer } = event;

        //Annulation de l'évènement
        event.preventDefault();

        //Suppression de la classe CSS
        this.isActif = false;

        //Vérification des éléments transférés
        if (dataTransfer.items) {
            //Initialisation de la liste de fichiers
            listeFiles = [];

            //Parcours des éléments
            for (let idxItem = 0;idxItem < dataTransfer.items.length;idxItem++) {
                //Vérification du type d'élément droppé
                if (dataTransfer.items[idxItem].kind === 'file')
                    //Ajout du fichier dans la liste
                    listeFiles.push(dataTransfer.items[idxItem].getAsFile());
            }

            //Suppression des éléments du DataTransfer
            dataTransfer.items.clear();

            //Emission des fichiers
            this.onXmlsDrop.emit(listeFiles);
        } else {
            //Récupération de la liste de fichiers
            listeFiles = dataTransfer.files;

            //Suppression des données du DataTransfer
            dataTransfer.clearData();

            //Emission des fichiers
            this.onXmlsDrop.emit(Array.from(listeFiles));
        }
    }

    /**
     * Gestion du dragover
     */
    @HostListener('dragover',['$event'])
    onDragOver(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Arrêt de la propagation
        event.stopPropagation();

        //Ajout de la classe CSS indiquant la zone de drag and drop
        this.isActif = true;
    }

    /**
     * Gestion du dragleave
     */
    @HostListener('dragleave',['$event'])
    onDragLeave(event: DragEvent) {
        //Suppression de la classe CSS
        this.isActif = false;
    }

    /**
     * Gestion du dragover sur le body
     */
    @HostListener('body:dragover',['$event'])
    onBodyDragOver(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Arrêt de la propagation
        event.stopPropagation();

        //Mise à jour de l'évènement
        event.dataTransfer.effectAllowed = 'none';
        event.dataTransfer.dropEffect = 'none';
    }

    /**
     * Gestion du drop sur le body
     */
    @HostListener('body:drop',['$event'])
    onBodyDrop(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Mise à jour de l'évènement
        event.dataTransfer.effectAllowed = 'none';
        event.dataTransfer.dropEffect = 'none';
    }

    /**
     * Définition des méthodes de l'uploader
     */
    wrapUploaderMethods() {
        //Définition des méthodes de l'uploader
        this.xmlUploader.addItem = this.onClick.bind(this);
        this.xmlUploader.removeItem = this.removeItem.bind(this);
        this.xmlUploader.uploadItem = this.uploadItem.bind(this);
    }

    /**
     * Upload d'un fichier
     */
    uploadItem(): void {
        let file: FileWithProgress;

        //Récupération du fichier
        file = this.xmlUploader.file;

        //Appel du service d'upload
        this.xmlUploaderService.upload(this.xmlUploader,file);
    }

    /**
     * Suppression du fichier
     */
    removeItem() {
        this.xmlUploader.file = null;
    }
}
