<mat-card>
    <mat-card-title>
        <span>
            <span translate="report.title"></span>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item" *ngFor="let report of listeReporting | report">
                    <div class="lvi-content">
                        <div class="avatar"> <i *ngIf="!report.codeGroupe" class="nio icon-reporting"></i>{{ report.codeGroupe?.substring(0, 3)  }}</div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="executeReport(report.idReport)">{{ report.libelle }}</a>
                            </div>
                            <ul class="lvi-attrs">
                                <li *ngIf="report.libelleGroupe">
                                    <strong translate="report.categorie"></strong>
                                    <span>{{ report.groupeDefini != 0 ? report.libelleGroupe : report.groupeCustom }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="!listeReporting?.length"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
