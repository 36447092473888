import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ParticipantExterne} from "@domain/participant/participant-externe";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";

/**
 * Service de gestion des participants à un OD ou une dépense
 */
@Injectable()
export class ParticipantsService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) { }


    /**
     * Enregistre un participant externe
     *
     * @param participantExterne    Participant à enregistrer
     * @param idTypeEntite          Identifiant du type entité de l'objet sur lequel on enregistre le participant
     * @param idCollab              Identifiant du collaborateur créant le participant
     * @param contexte              Contexte OD / NDF_V
     * @returns {Observable<Result>} Observable de l'appel
     */
    saveParticipantExterne(participantExterne: ParticipantExterne,idTypeEntite: number,idCollab: number,contexte: string): Observable<Result> {
        //Enregistrement du participant externe
        return this.http.put<Result>(`${environment.baseUrl}/controller/Participant/saveParticipantExterne?idTypeEntite=${idTypeEntite}&idCollab=${idCollab}&contexte=${contexte}`,participantExterne);
    }

    /**
     * Ajoute un participant aux favoris
     *
     * @param idParticipant Identifiant du participant à ajouter aux favoris
     * @param idCollab      Identifiant du collaborateur propriétaire de l'objet
     * @param isInterne     Indique si le participant à ajouter est interne
     */
    ajouterFavoris(idParticipant: number,idCollab: number,isInterne: boolean): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Participant/ajouterFavoris/${idParticipant}?idCollab=${idCollab}&isInterne=${isInterne}`,null);
    }

    /**
     * Retire un participant des favoris
     *
     * @param idParticipant Identifiant du participant à retirer des favoris
     * @param idCollab      Identifiant du collaborateur propriétaire de l'objet
     * @param isInterne     Indique si le participant à retirer est interne
     */
    retirerFavoris(idParticipant: number,idCollab: number,isInterne: boolean): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Participant/retirerFavoris/${idParticipant}?idCollab=${idCollab}&isInterne=${isInterne}`,null);
    }

}