import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {PreviewAlerteComponent} from "@share/component/alerte/preview-alerte.component";

@Component({
    selector: 'card-alerte',
    templateUrl: './card-alerte.component.html',
    styleUrls: ['./card-alerte.component.scss']
})
export class CardAlerteComponent {
    /** Énumération des niveaux d'alerte */
    public niveauAlerteEnum: typeof NiveauAlerte = NiveauAlerte;

    /** Liste des alertes */
    @ContentChildren(PreviewAlerteComponent)
    listeAlertes: QueryList<PreviewAlerteComponent>;

    /** Niveau Alerte */
    @Input() niveau: NiveauAlerte;

    /** Permet de faire apparaître le composant comme une card seule */
    @Input() isCard: boolean = false;
}
