<div class="listview-item clickable" *ngIf="getLibelle()" (click)="toggleFullView()">
    <div class="lvi-content">
        <div class="avatar">{{getLibelle().substring(0, 3)}}</div>
        <div class="lvi-body">
            <div class="title"><a>{{getLibelle()}}</a></div>
            <ul class="lvi-attrs">
                <li><strong [translate]="'avance.historique.date'"></strong>{{historique.dateChainage | date:'short'}}</li>
                <li *ngIf="checkMontant()"><strong [translate]="'avance.historique.montant'"></strong>{{getMontant() | montant:avance.devise.code }}</li>
                <li *ngIf="getRemarque()"><strong [translate]="'avance.historique.remarque'"></strong>{{getRemarque().length > 30 ? (getRemarque() | slice:0:30) + '...' : getRemarque()}}</li>
            </ul>
            <div *ngIf="isFull" class="p-20">
                <form autocomplete="off" #formHistorique="ngForm">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <label class="col-md-3">
                                    <span [translate]="'avance.historique.date'"></span>
                                </label>
                                <div class="col-md-9">
                                    <div class="d-flex">
                                        <span>{{ historique.dateChainage | date:'shortDate' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-md-3">
                                    <span [translate]="'avance.historique.montant'"></span>
                                </label>
                                <div class="col-md-9">
                                    <div class="d-flex">
                                        <span>{{ getMontant() | montant:avance.devise.code }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="getRemarque()">
                                <label class="col-md-3">
                                    <span [translate]="'avance.historique.remarque'"></span>
                                </label>
                                <div class="col-md-9">
                                    <span>{{ getRemarque() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
