import {TranslateService} from '@ngx-translate/core';
import {Filter, TypeComparaison} from '@domain/common/list-view';

import {AutocompleteOptions} from '../autocomplete';
import {AdresseSearchItemComponent} from "@share/component/autocomplete/options/adresse.search-item";
import {AdresseLineItemComponent} from "@share/component/autocomplete/options/adresse.line-item";
import {Adresse} from "@domain/profil/adresse";

/**
 * Options des adresses
 */
export default class AdresseOptions implements AutocompleteOptions {
    /** Définition de la taille de l'autocomplete */
    width = 500;

    /** Pas de recherche tant qu'un filtre n'a pas été renseigné */
    isSearchWhenNoFilter = false;

    /**
     * Récupération de l'URL
     */
    url() {
        //URL
        return `/controller/Adresse/searchAdresse`;
    }

    /**
     * Affichage d'un élément
     */
    displayItem(item: Adresse) {
        return item ? Adresse.libelleForDisplay(item) : '';
    }

    /**
     * Composant d'affichage d'une ligne dans la popup de recherche
     */
    searchItemComponent() {
        return AdresseSearchItemComponent;
    }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() {
        return AdresseLineItemComponent;
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.libelle'),
            clef: 'libelle,rue,ville,*adresseVille.libelle',
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        }, {
            clef: 'pays,*adressePays.libelle',
            title: translateService.instant('filter.pays'),
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        }]
    }
}