import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Result } from "@domain/common/http/result";
import { IntegrationMesFrais } from "@domain/ndf/integration-mes-frais";
import { ListeMesFrais } from "@domain/ndf/liste-mes-frais";
import { environment } from "@environments/environment";

/**
 * Service mes frais
 */
@Injectable({
  	providedIn: "root",
})
export class NdfFraisAddMesFraisService {

	constructor(private http: HttpClient) {}

	/**
	 * Appel back pour attacher les frais à une dépense
	 *
	 * @param idNDF				L'id de la ndf
	 * @param listeMesFrais		La liste mes frais sélectionnée
	 */
	linkFrais(idNDF: number, listeMesFrais: ListeMesFrais[]): Observable<IntegrationMesFrais> {
		return this.http
			.post<Result>(`${environment.baseUrl}/controller/NDF/linkFrais/${idNDF}`, listeMesFrais)
			.pipe(map((result: Result) => result.data.integrationMesFrais));
	}

	/**
	 * Supprime le frais
	 * @param idFrais id du frais
	 */
	 deleteFraisAndFraisOcr(idFrais: number) {
		return this.http
			.delete<Result>(`${environment.baseUrl}/controller/NDF/deleteFraisAndFraisOcr/${idFrais}`);
	}
}
