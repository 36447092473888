<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == NiveauAlerte.WARNING, 'danger': data.listeAlertes?.niveau == NiveauAlerte.ERROR}">
        <i class="material-icons-outlined">settings</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="extraOptions.parent.openSbtConfig(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.voyages.travelhub.sbtConfig.configTravelhub'"></strong>
                <span>{{ data.sbtConfig?.config?.libelle }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.travelhub.sbtConfig.sbt'"></strong>
                <span>{{ data.sbtConfig?.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.travelhub.sbtConfig.type'"></strong>
                <span>{{ adminTravelhubService.getTypeConfig(data.sbtConfig) }}</span>
            </li>
            <li *ngIf="data.listeAlertes && data.listeAlertes.listeAlertes.length > 0" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == NiveauAlerte.ERROR, 'back-warning' : data.listeAlertes.niveau == NiveauAlerte.WARNING}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="extraOptions.parent.copySbtConfig(data)" translate="global.actions.dupliquer"></button>
            <button mat-menu-item (click)="extraOptions.parent.deleteSbtConfig(data)" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
