<div class="lvi-content">
    <div class="avatar">
        <mat-icon class="material-icons-outlined">settings_suggest</mat-icon>
    </div>
    <div class="lvi-body">
        <div class="title" (click)="onSelect()">
            <a>{{ data.libelle || ('[' + data.champ + ']') }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.groupe'"></strong>
                <span [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.libelleGroupe.' + data.section"></span>
            </li>
            <li [matTooltip]="data.fixe ? ('admin.bibliotheque.zonesUtilisateurs.predefinies.statiqueTooltip' | translate) : undefined">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.clef'"></strong>
                <span>{{ data.champ }}</span>
                <mat-icon *ngIf="data.fixe">info_outline</mat-icon>
            </li>
            <li *ngIf="data.type">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.type'"></strong>
                <span>{{ data.type }}</span>
            </li>
            <li *ngIf="data.actif">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.obligatoire'"></strong>
                <span [translate]="'global.' + (data.obligatoire ? 'oui' : 'non')"></span>
            </li>
            <li *ngIf="data.actif">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.tailleMin'"></strong>
                <span>{{ data.tailleMinimale }}</span>
            </li>
            <li *ngIf="data.actif">
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.tailleMax'"></strong>
                <span>{{ data.masque.length }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.zonesUtilisateurs.predefinies.actif'"></strong>
                <span [translate]="'global.' + (data.actif ? 'oui' : 'non')"></span>
            </li>
        </ul>
    </div>
</div>
