<h1 mat-dialog-title>
    <span translate>admin.voyages.travelhub.sbtConfig.referentiel.categorieVoyageur.title</span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>
    <form #form="ngForm" autocomplete="off">
        <fieldset *ngIf="!isLoading">
            <div class="row">
                <!-- Configuration SBT -->
                <custom-input
                        lCol="4" rCol="8"
                        ngDefaultControl id="configSbt" name="configSbt"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.configSbt"
                        [ngModel]="data.sbtConfigUsed.libelle"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- SBT -->
                <custom-input
                        lCol="4" rCol="8"
                        ngDefaultControl id="sbt" name="sbt"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.sbt"
                        [ngModel]="data.sbt.libelle"
                        [readonly]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Valeur -->
                <custom-input
                        lCol="4" rCol="8"
                        ngDefaultControl id="valeur" name="valeur"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.referentiel.valeur"
                        [(ngModel)]="categorieVoyageur.code"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Libellé -->
                <custom-input
                        lCol="4" rCol="8"
                        ngDefaultControl id="libelle" name="libelle"
                        customType="input" libelle="admin.voyages.travelhub.sbtConfig.referentiel.libelle"
                        [(ngModel)]="categorieVoyageur.libelle">
                </custom-input>
            </div>
            <div class="row">
                <!-- Communauté par défaut pour cette configuration -->
                <custom-input
                        lCol="4" rCol="8"
                        ngDefaultControl id="defaut" name="defaut"
                        customType="checkbox"
                        libelle="admin.voyages.travelhub.sbtConfig.referentiel.defaut"
                        postLibelle="admin.voyages.travelhub.sbtConfig.referentiel.categorieVoyageur.defaut"
                        [ngModel]="categorieVoyageur.defaut"
                        [(customModel)]="categorieVoyageur.defaut"
                >
                </custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <!-- Bouton de suppression -->
    <button mat-stroked-button color="warn" [disabled]="isProcessing" (click)="deleteCategorieVoyageur()" *ngIf="categorieVoyageur.idCategorie > 0">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <!-- Bouton d'enregistrement' -->
    <button mat-flat-button color="primary" [disabled]="form.invalid || isProcessing" (click)="saveCategorieVoyageur()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
