import {AfterViewChecked,ChangeDetectorRef,Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {ActivatedRoute,Params,Router} from "@angular/router";
import {WorkflowConditionsService} from "@services/admin/workflow/workflow-conditions.service";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {Condition,TypeCondition} from "@domain/workflow/condition";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {TypePortee} from "@domain/workflow/workflow";
import {Statut} from "@domain/workflow/statut";
import {MatDialog} from "@angular/material/dialog";
import {SelectStatutsPopinComponent} from "@components/admin/workflow/conditions/alertes/detail/select-statuts-popin/select-statuts-popin.component";
import {SelectProfilsPopinComponent} from "@components/admin/workflow/conditions/alertes/detail/select-profils-popin/select-profils-popin.component";
import {Profil} from "@domain/profil/profil";
import {EntityWrapper} from "@domain/entite/EntityWrapper";
import {EntiteService} from "@share/component/rule-builder/entite.service";
import {NgForm} from '@angular/forms';
import {BehaviorSubject,Subscription} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Onglets de la page du détail d'une alerte
 */
export enum Onglets {
	ALERTE = 'ALERTE',
	TEST = 'TEST'
}

/**
 * Composant du détail d'une alerte
 */
@Component({
	host: {'data-test-id': 'alerte-detail'},
	templateUrl: './alerte-detail.component.html',
	styleUrls: ['./alerte-detail.component.scss']
})
export class AlerteDetailComponent implements OnInit,AfterViewChecked,OnDestroy {
	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = [
		{
			code: Onglets.ALERTE,
			libelle: this.translateService.instant('workflow.conditions.alertes.detail.tabs.alerte')
		},{
			code: Onglets.TEST,
			libelle: this.translateService.instant('workflow.conditions.alertes.detail.tabs.test')
		}
	];

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Souscription aux changements de route */
	routeSubscription: Subscription;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Alerte actuelle */
	alerte: Condition;

	/** Entité */
	entite: EntityWrapper;

	/** Mode création d'une alerte (si false : mode modification) */
	isCreation: boolean;

	/** Liste des portées */
	listePortees: Array<{ idPortee: string,libelle: string }>;

	/** Liste des statuts */
	listeStatuts: Array<Statut>;

	/** Liste des niveaux d'alerte */
	listeNiveaux: Array<{ code: number,libelle: string,icone?: string }> = [
		{
			code: NiveauAlerte.NO_CONTROL,
			libelle: this.translateService.instant('workflow.alerte.niveau.0')
		},{
			code: NiveauAlerte.WARNING,
			libelle: this.translateService.instant('workflow.alerte.niveau.1'),
			icone: '<i class="material-icons-outlined warning align-middle">warning</i>'
		},{
			code: NiveauAlerte.ERROR,
			libelle: this.translateService.instant('workflow.alerte.niveau.2'),
			icone: '<i class="material-icons-outlined error align-middle">warning</i>'
		}
	];

	/** Options pour l'affichage de la requête SQL */
	codeMirrorOptions = {
		theme: 'material',
		mode: 'text/x-mssql',
		lineNumbers: true,
		lineWrapping: true,
		readOnly: true
	};

	/** Formulaire de la page */
	@ViewChild('form')
	form: NgForm;

	/** Import des onglets pour le DOM */
	readonly Onglets = Onglets;

	/**
	 * Constructeur
	 *
	 * @param workflowConditionsService le service du menu Workflow - Conditions
	 * @param activatedRoute la route
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 * @param router le routeur Angular
	 * @param matDialog le gestionnaire de popin d'Angular
	 * @param entiteService le service de gestion des entités
	 * @param confirmService le service de confirmation d'une action
	 * @param cd le détecteur de changements
	 */
	constructor(
		private workflowConditionsService: WorkflowConditionsService,
		private activatedRoute: ActivatedRoute,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private router: Router,
		private matDialog: MatDialog,
		private entiteService: EntiteService,
		private confirmService: ConfirmService,
		private cd: ChangeDetectorRef
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On s'abonne aux changements de route
		this.routeSubscription = this.activatedRoute.params
			.subscribe((params: Params) => {
				//Récupération de l'identifiant de l'alerte courante
				const idAlerte: number = +params['idAlerte'];

				//Chargement de l'alerte (si consultation) et des données de formulaire
				this.loadAlerte(idAlerte);
			});

		//Définition de la liste des actions possibles
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			isVisible: () => !!this.alerte,
			isDisabled: () => this.form?.invalid,
			doAction: () => this.saveAlerte()
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-suppression',
			libelle: 'global.actions.supprimer',
			isVisible: () => !!this.alerte && !this.isCreation,
			doAction: () => this.deleteAlerte()
		}]);
	}

	/**
	 * Après la vérification de la vue
	 */
	ngAfterViewChecked() {
		//Détection des changements
		this.cd.detectChanges();
	}

	/**
	 * Chargement d'une alerte et des données de formulaire
	 *
	 * @param idAlerte l'ID de l'alerte
	 */
	loadAlerte(idAlerte: number): void {
		//Suppression de l'alerte courante s'il y en a déjà une
		delete this.alerte;

		//Appel au service
		this.workflowConditionsService.loadAlerte(idAlerte)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Récupération de la liste des portées
					this.listePortees = result.data.listePortees;

					//Récupération de la liste des statuts
					this.listeStatuts = result.data.listeStatuts;

					if (idAlerte === 0) {
						//Mode création
						this.isCreation = true;

						//Initialisation d'une alerte
						this.alerte = new Condition(TypeCondition.ALERTE);
					} else {
						//Mode consultation/modification
						this.isCreation = false;

						//Récupération de l'alerte
						this.alerte = result.data.alerte;

						//Récupération de l'entité de l'alerte
						this.loadBusinessData();
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Appelé au clic sur le champ de modification des statuts
	 */
	onClickStatut(): void {
		//Ouverture de la popin
		this.matDialog.open(SelectStatutsPopinComponent,{
			data: {
				alerte: this.alerte,
				listeStatuts: this.listeStatuts
			}
		}).afterClosed().subscribe((listeStatuts: Statut[]) => {
			//Vérification de la présence de la liste des statuts
			if (listeStatuts) {
				//Mise à jour dans l'alerte
				this.alerte.listeStatuts = listeStatuts;
			}
		});
	}

	/**
	 * Appelé à chaque changement de portée
	 */
	onPorteeChange(): void {
		//Réinitialisation de la rule et de l'entité
		delete this.alerte.rule;
		delete this.entite;

		//Chargement de la business data
		this.loadBusinessData()
	}

	/**
	 * Appelé au clic sur le champ de modification des profils
	 */
	onClickProfil(): void {
		//Ouverture de la popin
		this.matDialog.open(SelectProfilsPopinComponent,{
			data: {
				alerte: this.alerte
			}
		}).afterClosed().subscribe((listeProfils: Profil[]) => {
			//Vérification de la présence de la liste des profils
			if (listeProfils) {
				if (listeProfils.length > 0) {
					//Mise à jour dans l'alerte
					this.alerte.fonctions = listeProfils.map(p => p.typeProfil).join(' ') + ' ';
				} else {
					//On positionne le champ fonctions à null
					this.alerte.fonctions = null;
				}
			}
		});
	}

	/**
	 * Construction du tooltip du champ SQL
	 */
	getSqlTooltip(): string {
		let tooltip: string;

		switch (this.alerte.idPortee) {
			case TypePortee.OD:
				tooltip = "- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.od') + "\n";
				break;
			case TypePortee.NF:
				tooltip = "- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.nf') + "\n";
				break;
			case TypePortee.AV:
				tooltip = "- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.av') + "\n";
				break;
			case TypePortee.FC:
				tooltip = "- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.fc') + "\n";
				break;
			case TypePortee.OT:
				tooltip = "- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.ot') + "\n";
				break;
			default:
				tooltip = "";
		}

		tooltip += ("- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.user') + "\n");
		tooltip += ("- " + this.translateService.instant('workflow.conditions.alertes.detail.alerte.tooltipSQL.orga'));

		return tooltip;
	}

	/**
	 * Chargement de la business data
	 */
	loadBusinessData(): void {
		//Appel au service
		this.entiteService.loadBusinessData(EntiteService.getEntiteByPortee(this.alerte.idPortee))
			.pipe(first())
			.subscribe((entite: EntityWrapper) => this.entite = entite);
	}

	/**
	 * Sauvegarde de l'alerte
	 */
	saveAlerte(): void {
		//Appel au service
		this.workflowConditionsService.saveAlerte(this.alerte)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du résultat de l'appel
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Si l'on est en mode création
					if (this.isCreation) {
						//Navigation vers la page de consultation de l'alerte
						this.router.navigateByUrl(`Admin/Workflow/Conditions/Alerte/${result.data.idCondition}`);
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Suppression de l'alerte
	 */
	deleteAlerte(): void {
		//Confirmation de l'action
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service
				this.workflowConditionsService.deleteAlerte(this.alerte.idCondition)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du résultat
						if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Retour à la liste
							this.onGoBack();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}

	/**
	 * Clic sur le bouton retour
	 */
	onGoBack(): void {
		//Retour à la liste des alertes
		this.router.navigateByUrl('Admin/Workflow/Conditions/Alertes');
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Désabonnement
		this.routeSubscription.unsubscribe();
	}
}
