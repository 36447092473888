import {Component,OnInit} from '@angular/core';
import {Filter,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TypeZoneUtilisateur,ZoneUtilisateur} from "@domain/zone-utilisateur/zone-utilisateur";
import {ZoneParametrableListItemComponent} from "@components/admin/bibliotheque/zones-utilisateurs/zones-parametrables/list-item/zone-parametrable-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Sorting} from "@domain/common/list-view/sorting";
import {TypePortee} from "@domain/workflow/workflow";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {Router} from "@angular/router";

/**
 * Composant de l'onglet Zones Paramétrables du menu admin Bibliothèque > Zones Utilisateurs
 */
@Component({
	host: {'data-test-id': 'zones-parametrables'},
	selector: 'zones-parametrables',
	templateUrl: './zones-parametrables.component.html'
})
export class ZonesParametrablesComponent implements OnInit {
	/** Liste des zones paramétrables */
	listeZonesParametrables: ListView<ZoneUtilisateur,ZoneParametrableListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur Angular
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des zones paramétrables
		this.listeZonesParametrables = new ListView({
			uri: '/controller/ZoneUtilisateur/getListeZoneUtilisateur',
			title: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.title'),
			component: ZoneParametrableListItemComponent,
			defaultOrder: 'idPortee,libelle,ordre',
			listeFilters: [
				{
					clef: 'idPortee',
					title: this.translateService.instant('filter.portee'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: TypePortee.OD,
							libelle: this.translateService.instant('portee.OD')
						},{
							code: TypePortee.OT,
							libelle: this.translateService.instant('portee.OT')
						},{
							code: TypePortee.NF,
							libelle: this.translateService.instant('portee.NF')
						},{
							code: TypePortee.AV,
							libelle: this.translateService.instant('portee.AV')
						},{
							code: TypePortee.FC,
							libelle: this.translateService.instant('portee.FC')
						},{
							code: TypePortee.OP,
							libelle: this.translateService.instant('portee.OP')
						}
					]
				},{
					clef: 'libelle',
					title: this.translateService.instant('filter.libelle'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'reference',
					title: this.translateService.instant('filter.reference'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'code',
					title: this.translateService.instant('filter.code'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'isObligatoire',
					title: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.obligatoire'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					type: TypeFilter[TypeFilter.BOOLEAN]
				},{
					clef: 'typeChamp',
					title: this.translateService.instant('filter.type'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: TypeZoneUtilisateur.TEXTE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.TEXTE)
						},{
							code: TypeZoneUtilisateur.CHECKBOX.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.CHECKBOX)
						},{
							code: TypeZoneUtilisateur.MONTANT.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.MONTANT)
						},{
							code: TypeZoneUtilisateur.DATE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.DATE)
						},{
							code: TypeZoneUtilisateur.LISTE_UNIQUE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_UNIQUE)
						},{
							code: TypeZoneUtilisateur.LISTE_MULTIPLE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_MULTIPLE)
						},{
							code: TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE)
						},{
							code: TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE)
						},{
							code: TypeZoneUtilisateur.HEURE.toString(),
							libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.liste.typeLibelle.' + TypeZoneUtilisateur.HEURE)
						}
					]
				},{
					clef: 'isActif',
					title: this.translateService.instant('filter.isActif'),
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					type: TypeFilter[TypeFilter.BOOLEAN]
				}
			]
		});

		//Par défaut, on n'affiche que les zones actives
		this.listeZonesParametrables.listeSelectedFilters = [
			...this.listeZonesParametrables.listeFilters
				.filter((filter: Filter) => filter.clef === 'isActif')
				.map((filter: Filter) => {
					filter.isSelected = true;
					filter.valeur = 'true';
					filter.displayedValeur = this.translateService.instant('global.oui');

					return filter;
				})
		];

		//Définition des colonnes de tri
		this.listeZonesParametrables.columns = [
			{key: 'idPortee',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.portee'},
			{key: 'libelle',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.libelle'},
			{key: 'reference',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.reference'},
			{key: 'code',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.code'},
			{key: 'ordre',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.ordre'},
			{key: 'isObligatoire',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.obligatoire'},
			{key: 'typeChamp',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.type'},
			{key: 'isActif',title: 'admin.bibliotheque.zonesUtilisateurs.parametrables.liste.actif'},
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeZonesParametrables.sorting = new Sorting(this.listeZonesParametrables.columns,'idPortee,libelle,ordre');

		//Persistence des filtres
		this.listeZonesParametrables.isPersistFilters = true;
		this.listeZonesParametrables.className = 'ZonesParametrablesComponent';

		//Liste des actions possibles
		this.listeActions.next([
			{
				type: TypeAction.PRIMARY,
				icone: 'nio icon-ajouter',
				libelle: 'global.actions.creer',
				doAction: () => this.addNewZone()
			}
		]);
	}

	/**
	 * Ajout d'une nouvelle zone paramétrable
	 */
	addNewZone(): void {
		//Navigation vers le formulaire de création
		this.router.navigate(['Admin/Bibliotheque/ZoneUtilisateur',0]);
	}
}
