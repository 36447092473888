<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">phone</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="'profilUser.infoContact.'+data.type" (click)="this.extraOptions.open(data)"></a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'profilUser.infoContact.numero'"></strong>
                <span *ngIf="data.numero">{{data.numeroBrut}}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data.actif ? 'global.oui' : 'global.non'"></span>
            </li>
        </ul>
    </div>
</div>
