<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.type | slice: 0:3 }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ data.libelle }}</a>
            <span *ngIf="data.defaut" class="material-icons" [matTooltip]="'admin.frais.referentiels.defaut' | translate" [matTooltipPosition]="'right'">info</span>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.frais.referentiels.referentielFiltres.type'"></strong>
                <span>{{ data.type }}</span>
            </li>
            <li *ngIf="data.code">
                <strong [translate]="'admin.frais.referentiels.referentielFiltres.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li *ngIf="data.ordre">
                <strong [translate]="'admin.frais.referentiels.referentielFiltres.ordre'"></strong>
                <span>{{ data.ordre }}</span>
            </li>
            <li *ngIf="data.icone">
                <strong [translate]="'admin.frais.referentiels.referentielFiltres.icone'"></strong>
                <i class="material-icons" style="font-size: 14px;">{{ data.icone }}</i>
            </li>
        </ul>
    </div>
</div>
