import {Injectable} from '@angular/core';
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {Role} from "@domain/workflow/role";
import {Population} from "@domain/workflow/population";

@Injectable({
	providedIn: 'root'
})
/**
 * Service des délégations
 */
export class DelegationsService {

	/**
	 * Constructeur
	 *
	 * @param http client HTTP
	 */
	constructor(private http: HttpClient) {
	}

	/**
	 * Regarde si l'utilisateur courant peut accéder au menu des délégations
	 */
	isDelegationViewable(): Observable<boolean> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/User/isDelegationViewable`).pipe(
			map(result => result?.data?.isViewable)
		);
	}

	/**
	 * Regarde si l'utilisateur courant peut créer une délégation
	 */
	isDelegationCreatable(): Observable<boolean> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/User/isDelegationCreatable`).pipe(
			map(result => result?.data?.isCreatable)
		);
	}

	/**
	 * Récupération des rôles délégables pour l'utilisateur courant
	 */
	loadRolesDelegablesByIdUser(idUser: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/loadRolesDelegablesByIdUser?idUser=` + idUser,null);
	}

	/**
	 * Récupération des populations
	 */
	loadPopsByDeleg(delegation: LienRoleUser): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/loadPopsForDeleg`,LienRoleUser.toConfigDelegue(delegation));
	}

	/**
	 * Sauvegarde d'une délégation
	 */
	saveDelegation(delegation: LienRoleUser): Observable<number> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/saveDelegue`,LienRoleUser.toConfigDelegue(delegation)).pipe(
			map(result => result?.codeErreur)
		);
	}

	/**
	 * Suppression d'une délégation
	 */
	deleteDelegation(delegation: LienRoleUser): Observable<any> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/deleteDelegation?id_delegation=` + delegation.idLienRoleUser,null);
	}
}
