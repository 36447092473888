<div class="lvi-content">
    <div class="avatar" [nSelector]="{ liste: liste,item: data }">
        <i class="nio icon-profil"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="extraOptions.open(data)">{{ getTitle() }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="getLibelleOrigine()">
                <strong [translate]="'admin.entreprise.utilisateurs.externes.origine'"></strong>
                <span>{{ getLibelleOrigine() }}</span>
            </li>
            <li *ngIf="data.societe">
                <strong [translate]="'admin.entreprise.utilisateurs.externes.societe'"></strong>
                <span>{{ data.societe }}</span>
            </li>
            <li *ngIf="data.fonction">
                <strong [translate]="'admin.entreprise.utilisateurs.externes.fonction'"></strong>
                <span>{{ data.fonction }}</span>
            </li>
            <li *ngIf="data.email">
                <strong [translate]="'admin.entreprise.utilisateurs.externes.email'"></strong>
                <span>{{ data.email }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="copy()" translate="admin.entreprise.utilisateurs.externes.copier"></button>
            <button mat-menu-item (click)="delete()" translate="admin.entreprise.utilisateurs.externes.supprimer"></button>
        </mat-menu>
    </div>
</div>