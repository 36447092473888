import { FraisMission } from "./frais-mission";
import { IJDetail } from "./ij-detail";
import { RegleAttribution } from "./regle-attribution";

/**
 * Objet contenant les données associées à une règle
 */
export class Regle {
    /** Données */
    idRegle: number;
    libelle: string;
    details: IJDetail[];
    frais: FraisMission[];
    idsFraisRegion: string[];
    montant: number = 0;
    quantite: number = 0;

    regleAttribution: RegleAttribution;
    fraisStandard: FraisMission;
    selectedFrais: FraisMission;

    /**
     * Constructeur
     *
     * @param regleAttribution Données
     */
    constructor(regleAttribution: RegleAttribution) {
        this.regleAttribution = regleAttribution;

        //Ajout des données pour cette règle (colonne)
        this.idRegle = regleAttribution.id;
        this.libelle = regleAttribution.libelle;

        //Initialisation de la liste des détails pour cette règle (colonne)
        this.details = [];

        //Initialisation de la liste des différents frais attribuables pour cette règle à ce jour (colonne)
        this.frais = [];

        //Mémoire des id des régions de frais de mission des IJ déjà observés pour ce jour et de la liste des frais applicables associés
        this.idsFraisRegion = [];

        //Initialisation du montant et de la quantité qui seront calculés lors de la complétion de la semaine
        this.montant = 0;
        this.quantite = 0;
    }

    /**
     * Retourne le code du pays pour afficher le drapeau correspondant
     */
    getCodePays(): string {
        let code: string = null;

        //Deux drapeaux possible, suivant qu'il s'agisse d'un pays...
        code = this.selectedFrais?.fraisMissionRegion?.pays?.code2;

        //... ou d'une ville
        if (!code) {
            code = this.selectedFrais?.fraisMissionRegion?.ville?.pays?.code2;
        }

        //Si rien n'a été trouvé, on utilise le drapeau du détail d'IJ
        if (!code) {
            code = this.details[0]?.ij?.pays?.code2;
        }

        return code;
    }
}