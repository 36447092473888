/**
 * Enum pour les différents types de prestation
 */
export enum TypesPrestationClasse {
    AVION = 'AVION',
    TRAIN = 'TRAIN',
    HOTEL = 'HOTEL',
    VOITURE_DE_LOCATION = 'VOITURE_DE_LOCATION',
    VOITURE_SOCIETE = 'VOITURE_SOCIETE',
    VOITURE_PERSONNELLE = 'VOITURE_PERSONNELLE',
    AVION_TRAIN = 'AVION_TRAIN',
}