import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";

import {TypeVehicule} from "@domain/admin/entreprise/vehicules/typeVehicule";
import {EnergieFiscale} from "@domain/admin/entreprise/vehicules/energieFiscale";
import {PuissanceFiscale} from "@domain/admin/entreprise/vehicules/puissanceFiscale";
import {OptionsVehicules} from "@domain/admin/entreprise/vehicules/optionsVehicules";

/**
 * Service du menu Entreprise > Véhicules
 */
@Injectable({
    providedIn: 'root'
})
export class EntrepriseVehiculesService {
    /**
     * Constructeur
     *
     * @param http client HTTP
     */
    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Enregistrement d'une énergie
     *
     * @param energie Energie à enregistrer
     * @return result Résultat de l'enregistrement
     */
    saveEnergie(energie: EnergieFiscale): Observable<Result> {
        return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/saveEnergie`, energie).pipe(first());
    }

    /**
     * Suppression d'une énergie
     *
     * @param idEnergie Identifiant de l'énergie à supprimer
     * @return result Résultat de la suppression
     */
    deleteEnergie(idEnergie: number): Observable<Result> {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/deleteEnergie/${idEnergie}`);
    }

    /**
     * Récupère la liste de toutes les unités de type distance (pour les puissances fiscales)
     */
    getListeUnites(): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/getUnitesDistance`).pipe(first());
    }

    /**
     * Enregistrement d'une puissance fiscale
     *
     * @param puissance Puissance à enregistrer
     * @return result Résultat de l'enregistrement
     */
    savePuissance(puissance: PuissanceFiscale): Observable<Result> {
        return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/savePuissance`, puissance).pipe(first());
    }

    /**
     * Suppression d'une puissance fiscale
     *
     * @param idPuissance Identifiant de la puissance à supprimer
     * @return result Résultat de la suppression
     */
    deletePuissance(idPuissance: number): Observable<Result> {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/deletePuissance/${idPuissance}`);
    }

    /**
     * Enregistrement d'un type de véhicule
     *
     * @param type Type à enregistrer
     * @return result Résultat de l'enregistrement
     */
    saveTypeVehicule(type: TypeVehicule): Observable<Result> {
        return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/saveTypeVehicule`,type).pipe(first());
    }

    /**
     * Suppression d'un type de véhicule
     *
     * @param type Type à supprimer
     * @return result Résultat de la suppression
     */
    deleteTypeVehicule(type: TypeVehicule): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/deleteTypeVehicule`,type).pipe(first());
    }

    /**
     * Récupère les paramètres de gestion des véhicules
     *
     * @return result contenant les paramètres
     */
    getParametres() {
        return this.http.get<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/loadOptions`).pipe(first());
    }

    /**
     * Enregistre les paramètres de gestion des véhicules
     *
     * @return result Résultat de l'enregistrement
     */
    saveParametres(parametres: OptionsVehicules) {
        return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeAdmin/saveOptions`, parametres).pipe(first());
    }
}
