import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {LayoutComponent} from './layout.component';
import {SplashComponent} from './splash.component';
import {NavigatorComponent} from './navigator.component';
import {HeaderComponent} from './header.component';
import {MenuComponent} from './menu.component';
import {AccountMenuComponent} from './account-menu.component';
import {menu} from '../../menu';
import {MENU_ITEMS} from '@domain/common/menu/menu-item';
import {ShareComponentModule} from '../component/share-component.module';
import {LayoutEffects} from './layout.effects';
import {EffectsModule} from '@ngrx/effects';
import {BoiteLienDialogComponent} from './boite-lien-dialog.component';
import {MenuDropdownModule} from "./menu-dropdown/menu-dropdown.module";
import {FrameComponent} from './frame.component';
import {BlankComponent} from "@share/layout/blank.component";
import {FrameRouteComponent} from "@share/layout/frame-route.component";
import {StatutApplicationModule} from "@share/layout/statut-application/statut-application.module";

@NgModule({
    imports: [RouterModule,CommonModule,TranslateModule,ShareComponentModule,EffectsModule.forFeature([LayoutEffects]),MenuDropdownModule,StatutApplicationModule],
    declarations: [LayoutComponent,SplashComponent,NavigatorComponent,HeaderComponent,MenuComponent,AccountMenuComponent, BoiteLienDialogComponent,FrameComponent,BlankComponent,FrameRouteComponent],
    providers: [{ provide: MENU_ITEMS,useValue: menu }],
    exports: [LayoutComponent,SplashComponent,BlankComponent,FrameComponent,FrameRouteComponent]
})
export class LayoutModule {}
