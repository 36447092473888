<form #form="ngForm">
  <mat-card>
    <mat-card-title>
              <span [translate]="'admin.voyages.referentiels.infos.titleSynchronisations'">
              </span>
    </mat-card-title>
    <mat-card-content>
      <div class="row">
        <custom-input ngDefaultControl
                      customType="checkbox"
                      id="compagnies"
                      name="compagnies"
                      libelle="admin.voyages.referentiels.onglets.compagnies"
                      postLibelle="admin.voyages.referentiels.synchronisations.compagniesPostLibelle"
                      [ngModel]="synchroCompagnies"
                      [(customModel)]="synchroCompagnies"
                      [disabled]="synchroCartesVoyageurs">
        </custom-input>
      </div>
      <div class="row">
        <custom-input ngDefaultControl
                      customType="checkbox"
                      id="cartesvoyageurs"
                      name="cartesvoyageurs"
                      libelle="admin.voyages.referentiels.onglets.cartesVoyageurs"
                      postLibelle="admin.voyages.referentiels.synchronisations.cartesVoyageursPostLibelle"
                      [ngModel]="synchroCartesVoyageurs"
                      [(customModel)]="synchroCartesVoyageurs"
                      (onChange)="changeCartesVoyageurs()">
        </custom-input>
      </div>
      <div class="row">
        <custom-input ngDefaultControl
                      customType="checkbox"
                      id="classes"
                      name="classes"
                      libelle="admin.voyages.referentiels.onglets.classes"
                      postLibelle="admin.voyages.referentiels.synchronisations.classesPostLibelle"
                      [ngModel]="synchroClasses"
                      [(customModel)]="synchroClasses">
        </custom-input>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="d-flex flex-row-reverse">
    <button mat-flat-button color="primary"
            (click)="save()"
            [disabled]="!synchroClasses && !synchroCartesVoyageurs && !synchroCompagnies">
      <span [translate]="'admin.voyages.referentiels.synchronisations.synchroniserBouton'"></span>
    </button>
  </div>
</form>