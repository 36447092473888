import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Enveloppe} from "../../../domain/budget/enveloppe";
import {PageHeaderItem} from "../../../share/component/page-header/page-header";
import {TypeBudget} from "../../../domain/budget/type-budget.enum";
import {TranslateService} from "@ngx-translate/core";
import {Alerte, NiveauAlerte} from "../../../domain/common/alerte/alerte";
import {BudgetService} from "../budget.service";
import {formatCurrency} from "@angular/common";
import {TypePortee} from "../../../domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {AppState} from "../../../domain/appstate";
import * as settingsActions from "../../../reducers/settings";
import {SettingsGlobalState} from "../../../domain/settings/settings";

@Component({
    templateUrl: './enveloppe.component.html'
})
/**
 * Composant du détail d'une enveloppe
 */
export class EnveloppeComponent implements OnInit {

    /** Liste des onglets */
    listeTabs: Array<PageHeaderItem>;

    /** Onglet sélectionné */
    selectedTab: PageHeaderItem;

    /** Alerte de la page */
    alerte: Alerte = null;

    /** Import de l'énum pour le DOM */
    typeBudget: typeof TypeBudget = TypeBudget;

    /** Somme des montants imputés de l'enveloppe */
    sommeMontantImputes: number;

    /** Paramètres */
    settings: SettingsGlobalState;

    /**
     * Constructeur
     *
     * @param enveloppe enveloppe que l'on consulte
     * @param store le store de l'appli
     * @param translateService service de traduction
     * @param budgetService service lié au budget
     */
    constructor(@Inject(MAT_DIALOG_DATA) public enveloppe: Enveloppe,
                private store: Store<AppState>,
                private translateService: TranslateService,
                private budgetService: BudgetService) { }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: TypePortee.BGT
        });

        //Sélection du paramétrage pour récupérer les niveaux de bloquage des enveloppes
        this.store.select(state => state.settings?.[TypePortee.BGT]).subscribe(settings => {
            this.settings = settings;
        });

        //Initialisation de la liste des onglets
        this.listeTabs = [{
            code: TypeBudget.PREVISIONNEL.toString(),
            libelle: this.translateService.instant('budget.typeBudget.previsionnelle')
        },{
            code: TypeBudget.REEL.toString(),
            libelle: this.translateService.instant('budget.typeBudget.reelle')
        }];
    }

    /**
     * Appelé à chaque clic sur un onglet
     */
    onSelectedItemChange(selectedTab: PageHeaderItem) {
        //Changement d'onglet courant
        this.selectedTab = selectedTab;
        this.budgetService.computeMontantTotalImpute(this.enveloppe.idEnveloppe,this.enveloppe.idBudget,Number(this.selectedTab.code)).subscribe(result => {
            this.sommeMontantImputes = result;
        })
    }

    /**
     * Méthode de création de l'alerte. Renvoie un booléen car utilisée dans le ngIf du DOM afin d'être appelée une fois que les settings sont chargés.
     */
    computeAlerte(): boolean {
        //Création de l'alerte s'il y a lieu d'en avoir une
        if (this.enveloppe.niveauAlerte != 0) {
            if (this.enveloppe.niveauAlerte == 1) {
                this.alerte = new Alerte({
                    niveau: NiveauAlerte.WARNING,
                    titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
                    message: this.translateService.instant('budget.detail.alerte',{'pourcentage': this.enveloppe.alerteEdited ? this.enveloppe.pourcentageNonBloquant || this.settings.budgetNiveauNonBloquant : this.settings.budgetNiveauNonBloquant })
                });
                return true;
            } else if (this.enveloppe.niveauAlerte == 2) {
                this.alerte = new Alerte({
                    niveau: NiveauAlerte.ERROR,
                    titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
                    message: this.translateService.instant('budget.detail.alerte',{'pourcentage': this.enveloppe.alerteEdited ? this.enveloppe.pourcentageBloquant || this.settings.budgetNiveauBloquant : this.settings.budgetNiveauBloquant})
                });
                return true;
            }
        }
        return false;
    }

    /**
     * Récupération et formatage du montant total à afficher
     */
    getMontantTotalImpute(): string {
        if (this.selectedTab?.code == TypeBudget.PREVISIONNEL.toString()) {
            return this.translateService.instant("budget.detail.montantPrevImpute",{"montant": formatCurrency(this.sommeMontantImputes || 0,'fr',this.enveloppe.codeDevise,".2-2")})
        } else if (this.selectedTab?.code == TypeBudget.REEL.toString()) {
            return this.translateService.instant("budget.detail.montantReelImpute",{"montant": formatCurrency(this.sommeMontantImputes || 0,'fr',this.enveloppe.codeDevise,".2-2")})
        }
    }
}
