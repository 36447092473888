import {Component, OnInit} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {UserDto} from "@domain/user/user-dto";

/**
 * Composant d'affichage des informations d'un utilisateur dans la liste des utilisateurs affectés à un service
 */
@Component({
    host: {'data-test-id': 'organigramme-liste-utilisateur-item'},
    selector: 'organigramme-liste-utilisateur-item',
    templateUrl: './organigramme-liste-utilisateur-item.component.html'
})
export class AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent extends ListViewItem<UserDto> implements OnInit {
    /** Ensemble des fonctions de l'utilisateur */
    profils: string = '';

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        if(this.data) {
            //Formation de l'ensemble des fonctions de l'utilisateur pour l'affichage
            for(let char of this.data.fonctions) {
                //Pour chaque valeur (1, 2, ...) je me suis reporté au TypeProfil dans User
                switch (char.toString()) {
                    case "1":
                        this.profils += "CPT ";
                        break;
                    case "2":
                        this.profils += "RSP ";
                        break;
                    case "3":
                        this.profils += "COL ";
                        break;
                    case "4":
                        this.profils += "AST ";
                        break;
                    case "5":
                        this.profils += "SAD ";
                        break;
                    case "6":
                        this.profils += "FRN ";
                        break;
                }
            }
        }
    }
}