<splash></splash>
<navigator (menuToggle)="menuRef.toggle()" *ngIf="isLayoutPostLogin"></navigator>

<div class="page-content">
    <header *ngIf="isLayoutPostLogin"></header>
    <app-menu #menuRef [ngClass]="{ 'full-width': !isLayoutPostLogin}" [settings]="settings">
        <div [ngClass]="{ 'container-fluid': isLayoutPostLogin}">
            <router-outlet></router-outlet>
        </div>
    </app-menu>
</div>
