import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {CoursDeviseItem} from "@domain/admin/bibliotheque/devises/coursDeviseItem";
import {Devise} from "@domain/settings/devise";

/**
 * Service de gestion des Devises
 */
@Injectable()
export class DevisesService {
    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Récupération d'une devise à l'aide de son code
     */
    getDeviseByCode(code: string): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/consultDevise`, code).pipe(first());
    }

    /**
     * Enregistrement en base d'un taux pour une devise
     *
     * @param coursDevise nouveau taux à enregistrer
     */
    saveTauxForDevise(coursDevise: CoursDeviseItem): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/saveTauxForDevise`, coursDevise).pipe(first());
    }

    /**
     * Suppression d'un taux d'une devise
     *
     * @param coursDevise taux à supprimer
     */
    deleteTauxForDevise(coursDevise: CoursDeviseItem): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/deleteTauxForDevise`, coursDevise).pipe(first());
    }

    /**
     * Enregistrement ou création d'une devise
     *
     * @param isCreation booléen pour savoir si on est en création d'une devise ou en modification
     * @param devise devise à enregistrer en base
     */
    saveDevise(isCreation: boolean, devise: Devise): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/saveDevise/` + isCreation, devise).pipe(first());
    }

    /**
     * Suppression d'une devise
     *
     * @param codeDevise code de la devise à supprimer
     */
    deleteDevise(codeDevise: string): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/deleteDevise`, codeDevise).pipe(first());
    }

    /**
     * Ajout d'un lien entre un pays et une devise
     *
     * @param idPays id du pays
     * @param codeDevise id de la devise
     */
    addPays(idPays: number, codeDevise: string): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Devise/addPays?id_pays=`+ idPays + `&code_devise=` + codeDevise, []).pipe(first());
    }

    /**
     * Suppression du lien entre un pays et une devise
     *
     * @param idPays id du pays
     * @param codeDevise code de la devise
     */
    deleteDeviseFromPays(idPays: number, codeDevise: string) {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Devise/deleteDeviseFromPays/` + idPays + `/` + codeDevise).pipe(first());
    }
}
