import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {HistoriqueUtilisateur,TypeLog,TypeNature,TypeSourceLog} from "@domain/user/historique-utilisateur";
import {UserHistoriqueListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-historique/user-historique-list-item/user-historique-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Onglet "Historique" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-historique'},
	selector: 'user-historique',
	templateUrl: './user-historique.component.html'
})
export class UserHistoriqueComponent implements OnInit {
	/** ID de l'utilisateur courant */
	@Input() idUser: number;

	/** Liste de l'historique de l'utilisateur */
	listeHistorique: ListView<HistoriqueUtilisateur,UserHistoriqueListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listeHistorique = new ListView<HistoriqueUtilisateur,UserHistoriqueListItemComponent>({
			uri: `/controller/User/getHistorique?idUser=${this.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.title'),
			component: UserHistoriqueListItemComponent,
			defaultOrder: '-dateLog',
			listeFilters: [
				{
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.origine'),
					clef: 'sourceLog',
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: '' + TypeSourceLog.ADMINISTRATEUR,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeSourceLog.${TypeSourceLog.ADMINISTRATEUR}`)
						},{
							code: '' + TypeSourceLog.IMPORT,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeSourceLog.${TypeSourceLog.IMPORT}`)
						},{
							code: '' + TypeSourceLog.UTILISATEUR,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeSourceLog.${TypeSourceLog.UTILISATEUR}`)
						}
					]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.date'),
					clef: 'dateLog',
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.acteur'),
					clef: 'acteur',
					isDefault: true
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.typeModif'),
					clef: 'typeLog',
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: '' + TypeLog.AJOUT,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeLog.${TypeLog.AJOUT}`)
						},{
							code: '' + TypeLog.MODIFICATION,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeLog.${TypeLog.MODIFICATION}`)
						},{
							code: '' + TypeLog.SUPPRESSION,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeLog.${TypeLog.SUPPRESSION}`)
						}
					]
				},{
					title: this.translateService.instant('admin.entreprise.utilisateurs.detail.historique.zone'),
					clef: 'nature',
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: '' + TypeNature.UTILISATEUR,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeNature.${TypeNature.UTILISATEUR}`)
						},{
							code: '' + TypeNature.HABILITATION,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeNature.${TypeNature.HABILITATION}`)
						},{
							code: '' + TypeNature.CONTRAT,
							libelle: this.translateService.instant(`admin.entreprise.utilisateurs.detail.historique.typeNature.${TypeNature.CONTRAT}`)
						}
					]
				}
			]
		});

		//Définition des colonnes de tri
		this.listeHistorique.columns = [
			{key: 'sourceLog',title: 'admin.entreprise.utilisateurs.detail.historique.origine'},
			{key: 'dateLog',title: 'admin.entreprise.utilisateurs.detail.historique.date'},
			{key: 'acteur',title: 'admin.entreprise.utilisateurs.detail.historique.acteur'},
			{key: 'typeLog',title: 'admin.entreprise.utilisateurs.detail.historique.typeModif'},
			{key: 'nature',title: 'admin.entreprise.utilisateurs.detail.historique.zone'}
		];

		//Initialisation du tri
		this.listeHistorique.sorting = new Sorting(this.listeHistorique.columns,'-dateLog');
	}
}
