<h1 mat-dialog-title>
    <span>{{ 'admin.bibliotheque.geographie.territoire.perimetre.ajout' | translate }}</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #perimetreAddForm="ngForm">
        <fieldset>
            <div class="row" [ngClass]="{ 'has-error': geographie.invalid }">
                <label class="col-md-4 required"><span [translate]="'filter.pays'"></span></label>
                <div class="col-md-8">
                    <autocomplete type="geographie" name="geographie" #autocompleteGeographie #geographie="ngModel"
                                  (onSelect)="setGeographie($event)"
                                  [filter]="{ listeTypes: listeType, isZoneNull: true }"
                                  [(ngModel)]="selectedGeographie"
                                  ngDefaultControl [label]="'global.input.rechercher' | translate"
                                  required="true">
                    </autocomplete>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="perimetreAddForm.invalid || !selectedGeographie?.id || isSaving" (click)="savePerimetre()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>