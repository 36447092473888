import {Injectable} from '@angular/core';
import {TypeEntiteParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {TypeCodeErreur} from '@domain/common/http/result';
import {TranslateService} from '@ngx-translate/core';
import {DematService} from '@services/admin/parametre/demat/demat.service';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {ToastrService} from 'ngx-toastr';
import {filter,first} from 'rxjs/operators';

/**
 * Service des filtres params
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Injectable()
export class FiltreParamService {
    constructor(
        private confirmService: ConfirmService,
        private translateService: TranslateService,
        private dematService: DematService,
        private toastrService: ToastrService
    ) {        
    }

    /**
     * Supression d'un filtre type entité
     * 
     * @param typeEntiteParam type entité lié attaché aux pièces jointes
     * @param refresh fonction qui est utilisée quand la liste doit être rafraichie
     */
    deleteFiltre(typeEntiteParam: TypeEntiteParam, refresh: () => void): void {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                this.dematService.deleteFiltrePieceJointe(typeEntiteParam).pipe(first()).subscribe({
                    next: result => {
                        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        } else {
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }

                        //Refresh de la liste
                        refresh();
                    },
                    error: () => {
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                });
            }
        });
    }
}

