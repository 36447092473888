<h1 mat-dialog-title>
    <span [translate]="isCreation ? 'admin.entreprise.vehicules.types.creation' : 'admin.entreprise.vehicules.types.modification'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <fieldset>
            <div class="row">
                <custom-input ngDefaultControl
                              id="libelle"
                              name="libelle"
                              [(ngModel)]="data.type.libelle"
                              customType="input"
                              libelle="admin.entreprise.vehicules.types.libelle"
                              maxlength="50"
                              required="true"></custom-input>
                <custom-input ngDefaultControl
                              id="ordre"
                              name="ordre"
                              [(ngModel)]="data.type.ordre"
                              customType="number"
                              libelle="admin.entreprise.vehicules.types.ordre"></custom-input>
            </div>
            <div class="row">
                <custom-input ngDefaultControl
                              id="code"
                              name="code"
                              [(ngModel)]="data.type.code"
                              customType="input"
                              libelle="admin.entreprise.vehicules.types.code"
                              maxlength="50"></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <button mat-stroked-button color="warn" (click)="deleteType()" [disabled]="isLoading" *ngIf="data.type.idReferentiel">
        <span *ngIf="!isLoading" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isLoading"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="form.invalid || isLoading" (click)="saveType()">
        <span *ngIf="!isLoading" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isLoading"></mat-spinner>
    </button>
</div>