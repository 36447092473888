import {ListeAlertes} from "../common/alerte/listeAlertes";
import {ListItem} from "../common/list-view/list-item";
import {Statut} from "../workflow/statut";
import {Pays} from "../geographie/pays";

/**
 * Modèle représentant un OD dans la liste des od avec chevauchement de dates
 */
export class OdChevauchement implements ListItem {
    idOd: number;
    objet: string;
    depart_le: Date;
    retour_le: Date;
    heureDepart?: string;
    heureRetour?: string;
    ville: string;
    libelleVille: string;
    pays: Pays;
    lieu: string;
    typeEntite: string;
    montantProposition: number;
    statut: Statut;
    listeAlertes: ListeAlertes;

    getKey(): number {
        return this.idOd;
    }

    constructor(dto: any) {
        if (dto) {
            this.idOd = dto.idOd;
            this.objet = dto.objet;
            if (dto.depart_le) { this.depart_le = new Date(dto.depart_le) }
            if (dto.retour_le) { this.retour_le = new Date(dto.retour_le) }
            this.heureDepart = dto.heureDepart;
            this.heureRetour = dto.heureRetour;
            this.ville = dto.ville;
            this.libelleVille = dto.libelleVille;
            this.pays = dto.pays as Pays;
            this.lieu = dto.lieu;
            this.typeEntite = dto.typeEntite;
            this.montantProposition = dto.montantProposition;
            this.statut = dto.statut as Statut;
            this.listeAlertes = dto.listeAlertes as ListeAlertes;
        }
    }

    /**
     * Getter pour l'affichage de l'endroit
     */
    get location(): string {
        if (this.libelleVille) { return this.libelleVille; }
        if (this.ville) { return this.ville; }
        if (this.lieu) { return this.lieu; }
        if (this.pays?.libelle) { return this.pays.libelle; }
        return null;
    }
}