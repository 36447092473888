import { Component, Input, OnInit } from "@angular/core";
import { PreferenceAnalytique } from "@domain/profil/preferenceAnalytique";
import { ListViewItem } from "../../../../domain/common/list-view/list-view-item";
import {SettingsGlobalState} from "@domain/settings/settings";



/** Item de la liste pour la sélection analytique */
@Component({
    host: {'data-test-id': 'analytique-item'},
    selector: 'analytique-item',
    templateUrl: './analytique-item.component.html'
})
export class AnalytiqueItemComponent extends ListViewItem<PreferenceAnalytique> {

    /** Donnéees de la préférence analytique */
    @Input() data: PreferenceAnalytique;

    /** Options supplémentaires */
    @Input() extraOptions: {
        select: (preferenceAnalytique: PreferenceAnalytique) => void,
        settings: SettingsGlobalState
    };

    /** Event de la sélection de l'item */
    onSelect() {
        //On lance l'event de sélection au composant parent
        this.extraOptions.select(this.data);
    }

    /**
     * Récupération d'un paramétrage analytique
     */
     getFieldParam(code: string): any {
        //Récupération du champ
        return this.extraOptions.settings?.listeFieldParams?.find(p => p.champ == code);
    }

}