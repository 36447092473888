import {Component} from '@angular/core';
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {MatDialogRef} from "@angular/material/dialog";
import {PieceJointeExtension} from "@domain/admin/parametre/demat/piece-jointe-extension";

/**
 * Composant de la popin pour ajouter une extension
 *
 * @author Lionel Gimenez
 * @date 26/09/2023
 */
@Component({
    host: {'data-test-id': 'extension-add'},
    templateUrl: './extension-add.component.html'
})
export class ExtensionAddComponent {
    /** Indicateur de sauvegarde en cours */
    isSaving = false;

    /** Modèle du composant */
    extensionName: string;

    /**
     * Constructeur
     */
    constructor(
        private dematService: DematService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private matDialogRef: MatDialogRef<PieceJointeExtension, { refresh: boolean}>,
    ) {
    }

    /**
     * Sauvegarde l'extension
     */
    save(): void {
        //On passe l'indicateur de sauvegarde à true
        this.isSaving = true;

        //On lance la sauvegarde
        this.dematService.addExtension(this.extensionName)
            .pipe(first(),finalize(() => this.isSaving = false))
            .subscribe( {
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        //Fermeture de l'écran (on passe true pour déclencher le rechargement de la liste)
                        this.matDialogRef.close( {refresh: true});
                    } else {
                        if (result.codeErreur == TypeCodeErreur.ERROR_RGPD_SUSPENDED) {
                            //Message d'erreur sécurité
                            this.toastrService.error(this.translateService.instant('admin.parametres.demat.extensions.dialog.erreurSecurite'));
                        } else if (result.codeErreur == TypeCodeErreur.ERROR_DOUBLON) {
                            //Message d'erreur doublon
                            this.toastrService.error(this.translateService.instant('admin.parametres.demat.extensions.dialog.erreurDoublon'));
                        } else {
                            //Message d'erreur
                            TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                        }
                    }
                },
                error: () => {
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Handler quand le nom est changé
     */
    onExtensionNameChange() {
        //On supprime tous les symboles du string
        this.extensionName = this.extensionName.replace(/[^a-zA-Z0-9 ]/g, '');
    }
}