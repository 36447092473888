import {AfterViewInit, Component, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {FillingRow} from "./filling-row";

/**
 * Composant qui permet de faire un alignement Bootstrap à base de row tout en permettant de ne pas laisser de trou dans
 * l’affichage au cas où un élément d’une colonne ne doit pas être affiché.
 *
 * @author Laurent SCIMIA
 * @date 27/01/2022
 */
@Component({
	selector: 'filling-row',
	templateUrl: './filling-row.component.html',
})
export class FillingRowComponent implements OnInit,AfterViewInit {
	/**
	 * Liste des éléments à afficher dans la colonne de gauche<br>
	 * L'ordre est important, il s'agit de l'ordre d'affichage<br>
	 * La propriété template peut être null, auquel cas un trou sera laissé
	 */
	@Input()
	listeGauche?: Array<FillingRow>;

	/**
	 * Liste des éléments à afficher dans la colonne de droite<br>
	 * L'ordre est important, il s'agit de l'ordre d'affichage<br>
	 * La propriété template peut être null, auquel cas un trou sera laissé
	 */
	@Input()
	listeDroite?: Array<FillingRow>;

	/** Liste des holder de template pour les éléments de gauche */
	@ViewChildren('holderGauche',{read: ViewContainerRef}) listeHolderGauche: QueryList<ViewContainerRef>;

	/** Liste des holder de template pour les éléments de droite */
	@ViewChildren('holderDroite',{read: ViewContainerRef}) listeHolderDroite: QueryList<ViewContainerRef>;

	/** Template vide pour laisser un trou si besoin */
	@ViewChild('empty') emptyTemplate: TemplateRef<any>;

	/** Liste vide destinée à faire le for dans la vue */
	listeBoucle: Array<void>;

	/** Offset à appliquer aux éléments de droite au cas où il y ait des lignes complètes dans les éléments de gauche */
	offset: number = 0;

	/** Initialisation */
	ngOnInit(): void {
		let tailleGauche = this.listeGauche?.length ?? 0;
		let tailleDroite = this.listeDroite?.length ?? 0;

		//On initialise la taille de la boucle avec la taille de la plus grande liste à afficher
		this.listeBoucle = new Array<void>(tailleGauche > tailleDroite ? tailleGauche : tailleDroite).fill(null);

		//Compteur d'offset à prendre en compte
		let cptOffset = 0;

		//On parcourt tous les éléments de la colonne de gauche
		for (let i = 0; i < tailleGauche; i++) {
			//Si l'élément doit prendre toute la ligne et qu'il aurait dû y avoir un élément à droite
			if (this.listeGauche[i].isFullRow && this.listeDroite[i - cptOffset]) {
				//On ajoute un offset
				cptOffset++;
				//On augmente le nombre de lignes à afficher
				this.listeBoucle.push(null);
			}
		}
	}

	/** Une fois que la vue a été initialisée (tous les placeholder ont été générés) */
	ngAfterViewInit(): void {
		//On parcourt les éléments à afficher
		for (let i = 0; i < this.listeBoucle.length; i++) {
			//Cas de la colonne de gauche
			if (this.listeGauche[i]?.template != null) {
				//S'il y a un template à afficher, on affiche le template
				this.listeHolderGauche.get(i).createEmbeddedView(this.listeGauche[i].template as TemplateRef<any>);
			} else {
				//Sinon, on insère le template empty
				this.listeHolderGauche.get(i).createEmbeddedView(this.emptyTemplate);
			}

			//Cas de la colonne de droite
			//Si l'élément de gauche prend toute la ligne
			if (this.listeGauche[i]?.isFullRow) {
				//On offset les éléments de droite
				++this.offset;

				//On vérifie que la listeBoucle a suffisamment d'éléments pour pouvoir offset les éléments de droite
				if (this.listeBoucle.length < this.listeDroite.length + this.offset) {
					this.listeBoucle.push(null);
				}
			} else if (this.listeDroite[i - this.offset]?.template != null) {
				//S'il y a un template à afficher, on affiche le template. On n'oublie pas de prendre en compte l'offset
				this.listeHolderDroite.get(i).createEmbeddedView(this.listeDroite[i - this.offset].template as TemplateRef<any>);
			} else {
				//Sinon, on insère le template empty
				this.listeHolderGauche.get(i).createEmbeddedView(this.emptyTemplate);
			}
		}
	}
}
