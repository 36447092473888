import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ModeRemb,TypeRemb} from "@domain/admin/comptabilite/mode-remb";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ComptabiliteModeRemboursementService} from "@components/admin/comptabilite/mode-remboursement/comptabilite-mode-remboursement.service";
import {filter,finalize,first} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Item de la liste des modes de remboursement
 *
 * @author Laurent Convert
 * @date 15/06/2023
 */
@Component({
	host: {'data-test-id': 'comptabilite-mode-remboursement-list-item'},
	templateUrl: './comptabilite-mode-remboursement-list-item.component.html'
})
export class ComptabiliteModeRemboursementListItemComponent extends ListViewItem<ModeRemb> {
	/* Déclaration pour accès dans le template */
	TypeRemb = TypeRemb;

	/** Surcharge pour typage des options... */
	extraOptions: { openModeRemboursement: (modeRemb?: ModeRemb) => void };

	/**
	 * Constructeur
	 */
	constructor(private pleaseWaitService: PleaseWaitService,
				private comptabiliteModeRemboursementService: ComptabiliteModeRemboursementService,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private confirmService: ConfirmService
	) {
		//Constructeur parent
		super();
	}

	/**
	 * Suppression du mode de remboursement
	 */
	deleteModeRemb(): void  {
		if (this.data.defaut) {
			//Impossible de supprimer le mode de remboursement par défaut
			this.toastrService.error(this.translateService.instant('admin.comptabilite.modeRemb.suppressionDefautImpossible'));
		} else {
			this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
				.pipe(filter(isConfirmed => isConfirmed))
				.subscribe(() => {
					//Ouverture de la modale de chargement
					let waitDialogRef = this.pleaseWaitService.show();

					//Suppression puis fermeture de la modale de chargement
					this.comptabiliteModeRemboursementService.deleteModeRemb(this.data)
						.pipe(first(), finalize(() => waitDialogRef.close()))
						.subscribe(() => {
							//Message d'information
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Rechargement de la liste
							this.liste.refresh();
						}, (codeErreur: TypeCodeErreur) => {
							//Message d'erreur
							TypeCodeErreur.showError(codeErreur, this.translateService, this.toastrService);
						});
				});
		}
	}
}
