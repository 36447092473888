<h1 mat-dialog-title>
    <span [translate]="'admin.ongletOutils.cadreNiveauxRisque.popupDetails.titre'"></span>
    <span mat-dialog-close=true><i class="zmdi zmdi-close"></i></span>
</h1>

<div class="listview">
    <div class="listview-body">
        <div *ngFor="let pays of listePays" class="listview-item">
            <div class="lvi-content">
                <div class="avatar">{{pays.code2}}</div>
                <div class="lvi-body">
                    <div class="title">
                        <a>{{pays.libelle}} </a>
                        <span *ngIf="pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+pays.code2.toLowerCase()"></span>
                    </div>
                    <ul class="lvi-attrs">
                        <li><strong [translate]="'admin.ongletOutils.cadreNiveauxRisque.popupDetails.niveau'"></strong><span>{{pays.risque}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="listePays?.length == 0">
            <div class="lvi-content title"><span [translate]="'admin.ongletOutils.cadreNiveauxRisque.popupDetails.aucunPays'"></span></div>
        </div>
    </div>
</div>