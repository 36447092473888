import { Component } from "@angular/core";

/**
 * Onglet API SOAP
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'api-soap'},
    templateUrl: './api-soap.component.html'
})
export class ApiSoapComponent {}
