<h1 mat-dialog-title>
    <span [translate]="'profilUser.carteAbonnement.addTitle'"></span>
    <span [mat-dialog-close]="willRefreshList"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #addCarteVoyageForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input
                        [(ngModel)]="typePrestation"
                        [selectOptions]="listeTypeCarte"
                        [isSelectAvecVide]="true"                        
                        customType="select"
                        libelle="profilUser.carteAbonnement.type"
                        name="typeCompte"
                        ngDefaultControl
                        optionDisplay="libelle"
                        optionValue="type"
                        required
                        [readonly]="isReadOnly || data.carte.synchroTH"
                        [isDisableUsePrimaryColor]="true"
                >
                </custom-input>
                <label [translate]="'profilUser.carteAbonnement.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [(ngModel)]="data.carte.actif" color="primary" name="actif"></mat-checkbox>
                </div>
            </div>
            <div class="row">
                <custom-input
                        *ngIf="!data.carte.synchroTH"
                        [(ngModel)]="data.carte.carteReferentiel"
                        [autocompleteFilter]="{ typePresta: typePrestation}"
                        [disabled]="!typePrestation"
                        autocompleteType="carteTravelHub"
                        customType="autocomplete"
                        libelle="profilUser.carteAbonnement.carteTH"
                        name="libelle"
                        ngDefaultControl
                        optionDisplay="data.carte.carteReferentiel.libelle"
                        [required]="true"
                        [readonly]="isReadOnly"
                ></custom-input>
                <ng-container *ngIf="data.carte.synchroTH">
                    <label class="col-md-2">
                        <span translate="profilUser.carteAbonnement.carteTH"></span>
                    </label>
                    <div class="col-md-4">
                        {{ data.carte.carteReferentiel.libelle }}
                    </div>
                </ng-container>
            </div>
            <div *ngIf="data.carte.carteReferentiel?.compagnie" class="row">
                <custom-input
                        [(ngModel)]="data.carte.carteReferentiel.compagnie.libelle"
                        customType="input"
                        disabled
                        libelle="profilUser.carteAbonnement.compagnie.libelle"
                        name="compagnieLibelle"
                        ngDefaultControl
                        readonly
                        [readonly]="isReadOnly"
                ></custom-input>
                <custom-input
                        [(ngModel)]="listeTranslationTypeCarte[data.carte.carteReferentiel.typeCarte]"
                        customType="input"
                        disabled
                        libelle="profilUser.carteAbonnement.compagnie.type"
                        name="compagnieType"
                        ngDefaultControl
                        readonly
                        [readonly]="isReadOnly"
                ></custom-input>
            </div>
            <div class="row">
                <custom-input
                        [(ngModel)]="data.carte.numero"
                        customType="input"
                        libelle="profilUser.carteAbonnement.numero"
                        name="numero"
                        ngDefaultControl
                        required
                        [readonly]="isReadOnly || data.carte.synchroTH"
                        [isDisableUsePrimaryColor]="true"
                ></custom-input>
                <custom-input
                        *ngIf="data.carte.carteReferentiel?.typeChamp == TypeChampsCarte[TypeChampsCarte.NUM_DU_AU]
                            || data.carte.carteReferentiel?.typeChamp == TypeChampsCarte[TypeChampsCarte.NUM_DU_AU_DE_A]"
                        [ngModel]="data.carte.dateValiditeDebut"
                        [(customModel)]="data.carte.dateValiditeDebut"
                        customType="date"
                        libelle="profilUser.carteAbonnement.debutValidite"
                        name="datedeb"
                        ngDefaultControl
                        required
                        [readonly]="isReadOnly"
                        (customModelChange)="onDateValiditeDebutChange($event)"
                ></custom-input>
            </div>
            <div class="row">
                <custom-input
                        [(ngModel)]="data.carte.titulaire"
                        customType="input"
                        libelle="profilUser.carteAbonnement.titulaire"
                        name="titulaire"
                        ngDefaultControl
                        required
                        [readonly]="isReadOnly"
                ></custom-input>
                <custom-input
                        *ngIf="data.carte.carteReferentiel?.typeChamp == TypeChampsCarte[TypeChampsCarte.NUM_DU_AU]
                            || data.carte.carteReferentiel?.typeChamp == TypeChampsCarte[TypeChampsCarte.NUM_DU_AU_DE_A]"
                        [ngModel]="data.carte.dateValiditeFin"
                        [(customModel)]="data.carte.dateValiditeFin"
                        customType="date"
                        libelle="profilUser.carteAbonnement.finValidite"
                        name="datefin"
                        ngDefaultControl
                        required
                        [readonly]="isReadOnly"
                        (customModelChange)="onDateValiditeFinChange($event)"
                        [errorMessage]="isShowDateValiditeFinError ? ('profilUser.carteAbonnement.dateFinError' | translate): ''"
                ></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="deleteCarte()" *ngIf="this.data.carte.idCarte != 0" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting && !isSaving" [translate]="this.data.carte.toDelete ? 'profilUser.document.add.cancelDelete' : 'global.actions.supprimer'"></span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button *ngIf="!isDeleted" (click)="save()" [disabled]="addCarteVoyageForm.invalid || isSaving || isDeleting || isError()" color="primary" mat-flat-button>
        <span *ngIf="!isSaving && !isDeleting" [translate]="data.carte.idCarte == 0 ?'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
