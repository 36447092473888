import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from "rxjs";
import {SynchroSBTConfigUser} from "@domain/voyage/travel/synchro-sbt-config-user";
import {TypeAiguillage, TypeAiguillageLiteral, TypeNature} from "@domain/voyage/travel/constants";
import {TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {filter} from "rxjs/operators";
import {ProfilConnexion} from "@domain/profil/profilVoyageur";

/**
 * Service de gestion des voyages
 *
 * Principalement utilisé pour filtrer dynamiquement la liste des SBT disponibles pour un OD
 */
@Injectable()
export class ODVoyageService implements OnDestroy {
    /** Indique si l'OD a des participants */
    private _hasParticipants: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Indique si l'OD est urgent */
    private _isDepartUrgent: Subject<boolean> = new Subject<boolean>();

    /** Indique l'aiguillage de l'OD (s'il y a déjà des étapes ON/OFF) */
    private _aiguillageOd: Subject<TypeAiguillageLiteral> = new Subject<TypeAiguillageLiteral>();

    /** Liste de tous les SBT disponibles avant le filtrage */
    private _listeSBT: Subject<Array<SynchroSBTConfigUser>> = new Subject<Array<SynchroSBTConfigUser>>();

    /** Paramètres du type entité de l'OD */
    private _typeEntiteParam: Subject<TypeEntiteParamOD> = new Subject<TypeEntiteParamOD>();

    /** Profil de connexion utilisé pour la réservation */
    private _profilConnexion: Subject<ProfilConnexion> = new Subject<ProfilConnexion>();

    /** Liste des SBT filtrée utilisée par les autres composants */
    private _listeSBTFiltree: BehaviorSubject<Array<SynchroSBTConfigUser>> = new BehaviorSubject<Array<SynchroSBTConfigUser>>(null);

    /** Liste des SBT filtrés regroupés par nature, utilisée par les autres composants */
    private _listeSBTParNature: BehaviorSubject<Array<NiveauNature>> = new BehaviorSubject<Array<NiveauNature>>(null);

    /** Souscription pour ne pas oublier d'unsub */
    private souscription: Subscription;
    private souscription2: Subscription;

    /**
     * Constructeur
     */
    constructor() {
        //On récupère toutes les infos dont on a besoin pour filtrer les SBT
        this.souscription = combineLatest([//Le combineLatest s'assure qu'on ait au moins une valeur pour TOUS les observables avant de faire le subscribe
            this._aiguillageOd,//Seul observable qu'on accepte null car ça veut dire que l'OD n'a pas d'aiguillage
            this._isDepartUrgent.pipe(filter(val => val != null)),
            this._hasParticipants,
            this._listeSBT.pipe(filter(val => val != null)),
            this._typeEntiteParam.pipe(filter(val => val != null)),
            this._profilConnexion.pipe(filter(val => val != null)),
        ]).subscribe(([aiguillage, isUrgent, hasParticipant, listeSbt, typeEntiteParam, profilConnexion]) => {
            //Liste des SBT filtrée à renvoyer
            let resultat: Array<SynchroSBTConfigUser>;

            //Filtre à appliquer sur l'aiguillage (aucun filtre si null)
            let filtreAiguillage: TypeAiguillageLiteral = null;

            //Si l'OD n'a pas déjà un aiguillage
            if (aiguillage == null) {
                //Si c'est urgent ou qu'il y a des participants, ou que le profil de connexion force le offline
                if (isUrgent || hasParticipant || profilConnexion.forceOffline) {
                    //On filtre le offline
                    filtreAiguillage = TypeAiguillageLiteral.OFFLINE;
                } else if (profilConnexion.choixOnOff === false) {
                    filtreAiguillage = TypeAiguillageLiteral.ONLINE;
                }
            } else {
                //Si l'OD a déjà un aiguillage, on filtre dessus
                filtreAiguillage = aiguillage;
            }

            //On filtre sur l'aiguillage
            resultat = listeSbt.filter(sbt => filtreAiguillage === null || TypeAiguillageLiteral.equals(sbt.typeAiguillage, filtreAiguillage));

            //On filtre sur les natures
            resultat = resultat.filter(sbt =>
                (typeEntiteParam.prestaAvion || sbt.idNature !== TypeNature.AVION && sbt.idNature !== TypeNature.AVION_TRAIN) &&
                (typeEntiteParam.prestaTrain || sbt.idNature !== TypeNature.TRAIN && sbt.idNature !== TypeNature.AVION_TRAIN) &&
                (typeEntiteParam.prestaHebergement || sbt.idNature !== TypeNature.HEBERGEMENT) &&
                (typeEntiteParam.prestaVoiture || sbt.idNature !== TypeNature.VOITURE_DE_LOCATION) &&
                (typeEntiteParam.prestaAutre || sbt.idNature !== TypeNature.DOCUMENT)
            );

            //On renvoie la liste filtrée
            this._listeSBTFiltree.next(resultat);
        });

        //On crée les regroupements de SBT
        this.souscription2 = this._listeSBTFiltree.pipe(filter(val => !!val)).subscribe(listeSbt => {
            //Liste de tous les niveaux nature
            let listeNiveauNature: Array<NiveauNature> = new Array<NiveauNature>();

            //Niveau nature spécifique pour l'avion_train (pck s'il y a du Avion/Train en Online, il va regroupper les Avion ou Train offline)
            let niveauNatureAvionTrain: NiveauNature = null;

            //On cherche un Avion_Train online
            if (listeSbt.some(sbt => sbt.typeAiguillage === TypeAiguillage.ONLINE && sbt.idNature === TypeNature.AVION_TRAIN)) {
                //Si on a bien un avion_train online, on crée le niveau correspondant
                niveauNatureAvionTrain = new NiveauNature(TypeNature.AVION_TRAIN);

                //Et on l'ajoute à la liste
                listeNiveauNature.push(niveauNatureAvionTrain);
            }

            //On va parcourir les sbt
            for (let sbt of listeSbt) {
                let niveauNature: NiveauNature;

                //Si on a une nature spécifique avion_train et qu'on est sur un avion ou un train
                if (niveauNatureAvionTrain != null && [TypeNature.AVION, TypeNature.TRAIN].includes(sbt.idNature)) {
                    //On va l'ajouter à l'avion_train
                    niveauNature = niveauNatureAvionTrain;
                } else {
                    //Sinon (pas d'avion_train online OU on est pas sur de l'avion ni du train) on regarde si le niveau nature existe déjà
                    niveauNature = listeNiveauNature.find(pn => pn.nature === sbt.idNature);

                    //Si le niveau nature n'existe pas encore
                    if (niveauNature == null) {
                        //On crée le niveau nature
                        niveauNature = new NiveauNature(sbt.idNature);
                        //Et on l'ajoute à la liste
                        listeNiveauNature.push(niveauNature);
                    }
                }

                //On ajoute le niveau aiguillage au niveau nature
                niveauNature.listeSbt.push(sbt);
            }

            this._listeSBTParNature.next(listeNiveauNature);
        });
    }

    /**
     * À la destruction du composant
     */
    ngOnDestroy(): void {
        //On unsub le tout
        this.souscription.unsubscribe();
        this.souscription2.unsubscribe();
    }

    /* GETTERS */
    /**
     * Liste des SBT filtrée
     */
    get listeSBTFiltree(): Observable<Array<SynchroSBTConfigUser>> {
        return this._listeSBTFiltree.asObservable();
    }

    /**
     * Liste des SBT regroupés par nature
     */
    get listeSBTParNature(): Observable<Array<NiveauNature>> {
        return this._listeSBTParNature.asObservable();
    }

    /* SETTERS*/
    /**
     * Indique si l'OD a des participants
     */
    set hasParticipants(value: boolean) {
        this._hasParticipants.next(value);
    }

    /**
     * Indique l'aiguillage de l'OD (s'il y a déjà des étapes ON/OFF)
     */
    set aiguillageOd(value: TypeAiguillageLiteral) {
        this._aiguillageOd.next(value);
    }

    /**
     * Liste de tous les SBT disponibles avant le filtrage
     */
    set listeSBT(value: Array<SynchroSBTConfigUser>) {
        this._listeSBT.next(value);
    }

    /**
     * Paramètres du type entité de l'OD
     */
    set typeEntiteParam(value: TypeEntiteParamOD) {
        this._typeEntiteParam.next(value);
    }

    /**
     * Profil de connexion utilisé pour la réservation
     */
    set profilConnexion(value: ProfilConnexion) {
        this._profilConnexion.next(value);
    }

    /**
     *  Indique si l'OD est urgent
     */
    set isDepartUrgent(value: boolean) {
        this._isDepartUrgent.next(value);
    }
}

/** Classe de regroupement des SBT par nature */
export class NiveauNature {
    /** Nature de regroupement */
    nature: TypeNature;

    /** Liste des SBT disponibles pour la nature */
    listeSbt: Array<SynchroSBTConfigUser>;

    /**
     * Constructeur
     *
     * @param nature Nature pour le regroupement
     */
    constructor(nature: TypeNature) {
        this.nature = nature;
        this.listeSbt = new Array<SynchroSBTConfigUser>();
    }

    /**
     * Renvoie le SBT principal (Online de préférence)
     */
    getSbtPrincipal(): SynchroSBTConfigUser | null {
        //S'il n'y a qu'un SBT
        if (this.listeSbt.length === 1) {
            //On renvoie le seul SBT disponible
            return this.listeSbt[0];
        }

        //Normalement si on arrive jusqu'ici, c'est qu'il y a forcément du ONLINE
        return this.listeSbt.find(s => s.typeAiguillage === TypeAiguillage.ONLINE);
    }
}
