import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {environment} from '@environments/environment';
import {Document} from "@domain/document/document";
import {finalize,first,map} from "rxjs/operators";

/**
 * Service de gestion des documents
 */
@Injectable()
export class DocumentService {
	/** Liste des documents en attente d'être liés à leur objet  */
	listeDocumentToLink: Array<Document> = new Array<Document>();

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {
	}

	/**
	 * Renvoi True si un ou plusieurs documents sont en attente d'être liés à leur objet
	 */
	hasPendingDocumentToLink(): boolean {
		return this.listeDocumentToLink.length > 0;
	}

	/**
	 * Lie les documents en attente
	 *
	 * @param idObjet Identifiant de l'objet auquel les documents doivent être liés
	 * @param context Context du document link, si null le context global est utilisé
	 */
	linkAllPendingDocument(idObjet: number, context: string = null): Observable<boolean> {
		return this.linkDocumentToObject(context,idObjet,this.listeDocumentToLink)
			.pipe(first(),
				map((result) => result.codeErreur == TypeCodeErreur.NO_ERROR),
				finalize(() => this.listeDocumentToLink.splice(0))
			);
	}

	/**
	 * Liaison d'un document à son objet principal
	 */
	public linkDocumentToObject(context: string,idObjet: number,document: Document|Array<Document>): Observable<Result> {
		let listeDocuments: Array<Document>;

		//Envoi d'une liste de documents
		if (Array.isArray(document)) {
			listeDocuments = document;
		} else {
			//Envoi d'un seul document
			listeDocuments = [document]
		}

		//Liaison du document à son objet principal
		return this.http.post<Result>(`${environment.baseUrl}/controller/Document/linkDocumentsToObject/${context}/${idObjet}`,listeDocuments);
	}

	/**
	 * Suppression d'un document
	 */
	public delete(contexte: string,idDocument: number,idObjet?: number): Observable<any> {
		//Suppression du document
		return this.http.post<any>(`${environment.baseUrl}/controller/Document/deleteDocument/${contexte}?id_document=${idDocument}&id_objet=${idObjet}`,{});
	}

	/**
	 * Téléchargement du fichier
	 *
	 * @param idDocument Identifiant du document à télécharger
	 * @param context Contexte du document
	 *
	 * @return observable d'un objet FileDownload englobant le contenu fichier et son nom
	 */
	public downloadFile(idDocument: number,fileName: string,context: string): Observable<string> {
		return this.http.get(`${environment.baseUrl}/controller/Document/readDocument/${idDocument}?contexte=${context}&download=true`,{responseType: 'blob',observe: 'response'})
			.map(response => {
				const url = window.URL.createObjectURL(response.body);
				//Create hidden dom element (so it works in all browsers)
				const a = document.createElement('a');
				a.setAttribute('style','display:none;');
				document.body.appendChild(a);
				//Create file, attach to hidden element and open hidden element
				a.href = url;
				a.download = fileName;
				a.click();

				return url;
			});
	}
}
