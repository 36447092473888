import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Compte comptable
 */
export class Compte {
	numero: string;
	libelle: string;
	type: TypeCompte;
	actif?: boolean;
	attribut1?: string;
	attribut2?: string;
	attribut3?: string;
	attribut4?: string;
	new?: boolean;

	constructor(dto?: any) {
		if (dto) {
			Object.assign(this,dto);
		}
	}

	/**
	 * Retourne une chaîne formatée pour l'affichage textuel du compte.<br>
	 * Forme : <numéro> - <libellé>
	 */
	get libelleForDisplay(): String {
		return [this.numero,this.libelle].join(' - ');
	}
}

/**
 * Compte comptable dans la liste des comptes comptables (menu Comptabilite/Plan Comptable)
 */
export class CompteItem implements ListItem {
	id: number;
	numero: string;
	libelle: string;
	type: TypeCompte;
	actif: boolean;

	getKey(): number {
		return this.id;
	}
}

/**
 * Énumération des types de comptes
 */
export enum TypeCompte {
	NON_DEFINI = 'NON_DEFINI',
	COMPTE_TIERS = 'COMPTE_TIERS',
	COMPTE_TAXE = 'COMPTE_TAXE',
	COMPTE_BANQUE = 'COMPTE_BANQUE',
	COMPTE_CHARGE = 'COMPTE_CHARGE',
	PERTE_GAIN_CHANGE = 'PERTE_GAIN_CHANGE'
}

/**
 * Modèle des options de type d'un compte
 */
export interface OptionTypeCompte {
	id: TypeCompte,
	libelle: string,
}