/**
 * DTO des participants avec favoris
 */
export class ParticipantAvecFavoris {
	/** Identifiant unique d'un participant interne (id@ns_users) */
	idParticipant: number;

	/** Type du participant */
	typeParticipant: TypeParticipant;

	/** Nom de l'utilisateur */
	nom: string;

	/** Prénom de l'utilisateur */
	prenom: string;

	/** Matricule de l'utilisateur */
	matricule: string;

	/** Identifiant de la personne ayant mis l'utilisateur en favoris */
	idUserOrigineFav: number;

	/** Nom du service de l'utilisateur (libelle@ns_orga) */
	libelleService: string;

	/** Nom de la société de l'utilisateur */
	libelleSociete: string;

	/**
	 * Constructeur
	 *
	 * @param participantAvecFavoris Un objet de type participantAvecFavoris. Peut être null;
	 */
	constructor(participantAvecFavoris?: ParticipantAvecFavoris) {
		if (participantAvecFavoris) {
			Object.assign(this,participantAvecFavoris);
		}
	}

	/**
	 * Vérifie si l'objet passé en paramètre est de type ParticipantAvecFavoris
	 *
	 * @param object Objet à vérifier
	 */
	static isParticipantAvecFavoris(object: any): object is ParticipantAvecFavoris {
		return 'idParticipant' in object;
	}
}

/**
 * Énumération des types de participants
 */
export enum TypeParticipant {
	INTERNE = 'INTERNE',
	EXTERNE = 'ENTERNE'
}
