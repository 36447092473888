import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmService } from "@share/component/confirmation/confirm.service";
import { FloatingButtonAction, TypeAction } from "@share/component/floating-button/floating-button";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { Report } from "@domain/reporting/report";
import { ReportingService as UserReportingService } from "@components/reporting/reporting.service";
import { ReportingService } from "@services/admin/reporting/reporting.service";
import { TypeCodeErreur } from "@domain/common/http/result";
import { filter } from "rxjs/operators";
import { ReportingInfosFichierComponent } from "@components/admin/bibliotheque/reporting/reporting-infos/fichier/reporting-infos-fichier.component";
import { Location } from "@angular/common";

/**
 * Composant d'affichage des informations d'un reporting et de création d'un rapport
 */
@Component({
    host: {'data-test-id': 'reporting-infos'},
    templateUrl: './reporting-infos.component.html'
})
export class ReportingInfosComponent implements OnInit {
    /** Boolean pour savoir s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Rapport à afficher sur la page */
    report: Report;

    /** Formulaire du composant */
    @ViewChild('form') form: NgForm;

    /** Liste des différents onglets disponibles dans le menu "Devise" de la bibliothèque */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = OngletsInfos;

    /** Liste des actions possibles pour le floatingButton en bas à droite */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Libellé */
    libelle: string;

    /** Composant de fichier */
    @ViewChild('fichierComponent') fichierComponent?: ReportingInfosFichierComponent;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private reportingService: ReportingService,
                private userReportingService: UserReportingService,
                private activatedRoute: ActivatedRoute,
                private confirmService: ConfirmService,
                private toastrService: ToastrService,
                private location: Location,
                private router: Router) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Longueur de l'URL pour récupérer l'ID du rapport
        const length = this.activatedRoute.snapshot.url.length;
        //Récupération de l'ID du rapport
        const reportId: string = this.activatedRoute.snapshot.url[length - 1].path;

        //Permet de générer la liste des différents onglets disponibles
        this.listeTabItems = [{
            code: OngletsInfos.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.reporting.onglets.generalites')
        }];

        //Sélection de l'onglet Généralités par défaut
        this.selectedItem = this.listeTabItems[0];
        this.selectedItem.selected = true;

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save(),
            isDisabled: () => this.isSaveInvalid()
        }]);

        //Si l'ID du rapport est "0" alors on est en création
        if (reportId === "0"){
            //Flag de création
            this.isCreation = true;

            //Initialisation d'un nouveau rapport (celui qui va être créé)
            this.report = {
                idReport: 0,
                type: 0,
                idGroupe: 0,
                groupeDefini: 0,
                actif: true
            } as Report;
        } else {
            //Si l'ID du rapport n'est pas "0" alors on est en modification
            this.isCreation = false;

            //On ajoute l'action d'extraction quand on est en modification, pour ne pas l'avoir en création
            this.listeActions.next([...this.listeActions.getValue(),
                {
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-reporting',
                    libelle: 'admin.bibliotheque.reporting.extraction',
                    doAction: () => this.printReport()
                }
            ]);

            //On ajoute l'action de suppression
            this.listeActions.next([...this.listeActions.getValue(),
                {
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-suppression',
                    libelle: 'global.actions.supprimer',
                    doAction: () => this.delete(),
                    isVisible: () => !this.report?.standard
                }
            ]);

            //Récupération du rapport à modifier à l'aide de son ID
            this.reportingService.getReportById(reportId).subscribe(data => {
                //Initialisation du rapport
                this.report = data.data.report as Report;
                this.report.listeGroupesCustom = data.data.listeGroupes;

                //Libellé
                this.libelle = this.report.libelle;
            });

            //Comme on est en modification, on ajoute un onglet "Paramètres"
            this.addParamTabIfNeeded();
        }
    }

    /**
     * Ajout de l'onglet des paramètres
     */
    private addParamTabIfNeeded(): void {
        if (this.listeTabItems.length != 2) {
            this.listeTabItems.push({
                code: OngletsInfos.PARAMETRES,
                libelle: this.translateService.instant('admin.bibliotheque.reporting.onglets.parametres')
            });
        }
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Sauvegarde des changements apportés
     */
    save() {
        //Exécution
        this.reportingService.saveReport(this.report, this.fichierComponent?.listeDocument?.basicFile).subscribe({
            next: result => {
                //Vérification de l'enregistrement
                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    //Message d'information
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //MAJ du rapport
                    this.report = result.data?.report as Report;
                    this.report.listeGroupesCustom = result.data?.listeGroupes;

                    //Effacement du fichier fourni
                    this.fichierComponent.listeDocument.basicFile = undefined;

                    //Libellé
                    this.libelle = this.report.libelle;

                    //Ajout de l'onglet "Paramètres"
                    this.addParamTabIfNeeded();

                    //Si création
                    if (this.isCreation) {
                        //Flag de création
                        this.isCreation = false;

                        //Mise à jour de l'URL
                        this.location.replaceState(`/Admin/Bibliotheque/Reporting/${this.report.idReport}`);
                    }
                } else {
                    //On affiche le message d'erreur
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            },
            error: () => {
                //Message d'erreur
                this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
            }
        });
    }

    /**
     * Suppression du rapport
     */
    delete() {
        //Affichage de la confirmation de suppression de la devise
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //Exécution
                    this.reportingService.deleteReport(this.report?.idReport).subscribe({
                        next: result => {
                            //Vérification de l'enregistrement
                            if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                                //Message d'information
                                this.toastrService.success(this.translateService.instant('global.success.suppression'));

                                //Navigation vers la liste
                                this.router.navigate(['Admin/Bibliotheque/Reporting']);
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        },
                        error: () => {
                            //Message d'erreur
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }
                    });
                }
            });
    }

    /**
     * Impression du rapport
     */
    printReport() {
        //Exécution
        this.userReportingService.executeReportById(this.report?.idReport);
    }

    /**
     * Méthode appelée lors du clic sur le bouton de retour en arrière pour retourner vers la liste des rapports
     */
    onGoBack() {
        //Navigation vers la liste
        this.router.navigate(['Admin/Bibliotheque/Reporting']);
    }

    /**
     * Validité de l'enregistrement
     */
    isSaveInvalid(): boolean {
        //Retour
        return !(this.report?.libelle && (this.report?.pdf || this.report?.csv || this.report?.excel)) ||
            (!this.fichierComponent?.listeDocument?.liste?.data?.listeResultats?.length && !this.fichierComponent?.listeDocument?.basicFile) ||
            (this.report.type == 1 && !this.report.idPortee);
    }
}

/**
 * Enum pour les noms des différents onglets de la page Reporting
 */
export enum OngletsInfos {
    GENERALITES = "Généralités",
    PARAMETRES = "Paramètres"
}
