import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ListView,TypeFilter} from '@domain/common/list-view';
import {TypePortee} from '@domain/workflow/workflow';
import {SettingsService} from "@components/settings/settings.service";
import {AutocompleteService} from "@share/component/autocomplete/autocomplete.service";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {HttpClient,HttpParams} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {NDFCreationItemComponent} from "@components/ndf/ndf-creation/ndf-creation-item/ndf-creation-item.component";

/**
 * Liste des missions sélectionnables à la création d'une NDF
 */
@Component({
    host: {'data-test-id': 'ndf-creation-list'},
    templateUrl: './ndf-creation-list.component.html'
})
export class NDFCreationListComponent implements OnInit {
    /** Déclaration pour accès dans le template */
    TypePortee = TypePortee
    
    /** Liste des missions */
    liste: ListView<any,NDFCreationItemComponent>;
    
    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private settingsService: SettingsService,
                private autocompleteService: AutocompleteService,
                private matDialogRef: MatDialogRef<any>,
                private http: HttpClient,
                private toastrService: ToastrService,
                @Inject(MAT_DIALOG_DATA) private data: {
                    portee: TypePortee.OD | TypePortee.OT,
                    idCollab: number
                }) {

    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Pkey en fonction de la portée de l'objet
        const keyId: string = TypePortee.OT == this.data.portee ? 'idOMPermanent' : 'idOd';
        //Titre de la liste en fonction de la portée de l'objet
        const title: string = TypePortee.OT == this.data.portee ? 'ordreDeMissionPermanent' : 'ordreDeMission';
        
        //Construction des paramètres de la requête HTTP de récupération de la liste des missions
        let params: HttpParams = new HttpParams()
            .append("portee",this.data.portee);
        
        //Ajout de l'identifiant du collaborateur pour lequel l'utilisateur doit créér l'objet (cas du "connecté en tant que")
        if (this.data.idCollab) {
            params = params.append("idCollab",this.data.idCollab.toString(10));
        }
        
        //Définition de la liste des OD
        this.liste = new ListView<any,NDFCreationItemComponent>({
            uri: `/controller/NDF/filtreMissionsForCreation?`+params.toString(),
            title: this.translateService.instant('ndf.creation.'+title),
            isLocal: true,
            isFilter: true,
            component: NDFCreationItemComponent,
            listeFilters: [{
                title: this.translateService.instant('filter.numeroMission'),
                clef: keyId,
                type: TypeFilter[TypeFilter.LONG],
                typeComparaison: 'EQUAL',
                isDefault: true
            },{
                title: this.translateService.instant('filter.objet'),
                clef: 'objet',
                isDefault: true
            },{
                title: this.translateService.instant('filter.typeEntite'),
                clef: 'typeEntite.libelle',
                isDefault: true
            },{
                title: this.translateService.instant('filter.pays'),
                clef: 'pays.libelle',
                isDefault: true
            },{
                title: this.translateService.instant('filter.ville'),
                clef: '*ville.libelle',
                isDefault: true
            }],
            defaultOrder: '-' + keyId,
        });
    }

}