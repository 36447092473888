import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {Filtre,TypeFiltre} from "@domain/admin/entreprise/user/filtre";
import {FiltreListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-filtres/filtre-list-item/filtre-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {TypeProfil} from "@domain/user/user";
import {MatDialog} from "@angular/material/dialog";
import {CreateFiltrePopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-filtres/create-filtre-popin/create-filtre-popin.component";
import {Population} from "@domain/workflow/population";
import {PopulationListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-filtres/population-list-item/population-list-item.component";
import {Result} from "@domain/common/http/result";
import {PrestationsAutoriseesPopinComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-filtres/prestations-autorisees-popin/prestations-autorisees-popin.component";

/**
 * Onglet "Filtres" de l'écran de consultation d'un utilisateur
 */
@Component({
	host: {'data-test-id': 'user-filtres'},
	selector: 'user-filtres',
	templateUrl: './user-filtres.component.html'
})
export class UserFiltresComponent implements OnInit {
	/** ID de l'utilisateur courant */
	@Input() idUser: number;

	/** L'utilisateur a-t-il une ligne dans ns_collab */
	@Input() isCollabInit: boolean;

	/** Utilisateur responsable ou non */
	@Input() isResponsable: boolean;

	/** Liste des filtres collaborateur */
	listeFiltreCollaborateur: ListView<Filtre,FiltreListItemComponent>;

	/** Liste des filtres responsable */
	listeFiltreResponsable: ListView<Filtre,FiltreListItemComponent>;

	/** Liste des filtres populations */
	listePopulation: ListView<Population,PopulationListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param matDialog le gestionnaire de popin
	 */
	constructor(
		private translateService: TranslateService,
		private matDialog: MatDialog,
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des filtres collaborateur
		this.listeFiltreCollaborateur = new ListView<Filtre,FiltreListItemComponent>({
			uri: `/controller/User/getListeFiltreCollaborateur/${this.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.filtres.listeFiltreCollaborateur.title'),
			component: FiltreListItemComponent,
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('filter.libelle'),
					isDefault: true
				},{
					clef: 'typeFiltre',
					title: this.translateService.instant('filter.type'),
					isDefault: false,
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: TypeFiltre.M,
							libelle: TypeFiltre.getLibelle(TypeFiltre.M,this.translateService)
						},{
							code: TypeFiltre.T,
							libelle: TypeFiltre.getLibelle(TypeFiltre.T,this.translateService)
						}
					]
				}
			],
			listeActions: [
				{
					icon: 'search',
					tooltip: this.translateService.instant('admin.entreprise.utilisateurs.detail.filtres.listeFiltreCollaborateur.controlerPrestations'),
					onPress: () => this.openPrestations()
				},{
					icon: 'add',
					onPress: () => this.createFilter(TypeProfil.COLLABORATEUR),
					disabled: !this.isCollabInit,
					tooltip: !this.isCollabInit ? this.translateService.instant('admin.entreprise.utilisateurs.detail.errorCollab') : null
				}
			],
			extraOptions: {
				fonction: TypeProfil.COLLABORATEUR
			}
		});

		//Initialisation de la liste des filtres responsable
		this.listeFiltreResponsable = new ListView<Filtre,FiltreListItemComponent>({
			uri: `/controller/User/getListeFiltreResponsable/${this.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.filtres.listeFiltreResponsable.title'),
			component: FiltreListItemComponent,
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('filter.libelle'),
					isDefault: true
				},{
					clef: 'typeFiltre',
					title: this.translateService.instant('filter.type'),
					isDefault: false,
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					listeValues: [
						{
							code: TypeFiltre.D,
							libelle: TypeFiltre.getLibelle(TypeFiltre.D,this.translateService)
						},{
							code: TypeFiltre.M,
							libelle: TypeFiltre.getLibelle(TypeFiltre.M,this.translateService)
						},{
							code: TypeFiltre.O,
							libelle: TypeFiltre.getLibelle(TypeFiltre.O,this.translateService)
						},{
							code: TypeFiltre.T,
							libelle: TypeFiltre.getLibelle(TypeFiltre.T,this.translateService)
						}
					]
				}
			],
			listeActions: [
				{
					icon: 'add',
					onPress: () => this.createFilter(TypeProfil.RESPONSABLE)
				}
			],
			extraOptions: {
				fonction: TypeProfil.RESPONSABLE
			}
		});

		//Initialisation de la liste des populations
		this.listePopulation = new ListView<Population,PopulationListItemComponent>({
			uri: `/controller/User/getListePopulation/${this.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.filtres.listePopulation.title'),
			component: PopulationListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listePopulation
		});
	}

	/**
	 * Ouverture de la popin de visualisation des prestations autorisées
	 */
	openPrestations(): void {
		//Ouverture de la popin
		this.matDialog.open(PrestationsAutoriseesPopinComponent,{
			data: {
				idUser: this.idUser
			},
			panelClass: 'mat-dialog-without-margin',
			maxHeight: '80%'
		});
	}

	/**
	 * Ouverture de la popin de création d'un filtre
	 *
	 * @param fonction le profil sur lequel ajouter un filtre
	 */
	createFilter(fonction: TypeProfil): void {
		//Ouverture de la popin
		this.matDialog.open(CreateFiltrePopinComponent,{
			data: {
				idUser: this.idUser,
				fonction: fonction
			},
			width: '80%'
		}).afterClosed().subscribe((refresh: boolean) => {
			//S'il faut rafraîchir une liste
			if (refresh) {
				//Si l'on vient d'ajouter un filtre collaborateur
				if (fonction === TypeProfil.COLLABORATEUR) {
					//Rafraichissement de la liste des filtres collaborateur
					this.listeFiltreCollaborateur.refresh();
				} else if (fonction === TypeProfil.RESPONSABLE) {
					//Sinon, rafraichissement de la liste des filtres responsable
					this.listeFiltreResponsable.refresh();
				}
			}
		});
	}
}
