import {Component,Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {ReleveFacture} from '@domain/facture/releve-facture';
import {environment} from '@environments/environment';
import {AlerteComponent} from '../../../workflow/alerte.component';
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";
import {NiveauAlerte} from "@domain/common/alerte/alerte";

@Component({
    templateUrl: './releve-list-item.component.html'
})
export class ReleveListItemComponent extends ListViewItem<ReleveFacture> {
    /** Accès à l'enum dans la vue */
    readonly NiveauAlerte = NiveauAlerte;

    /** Elément courant */
    @Input() data: ReleveFacture;

    /** URL de base */
    baseUrl: string = environment.baseUrl;

    /**
     * Constructeur
     */
    constructor(private router: Router,
                private matDialog: MatDialog) {
        //Héritage
        super();
    }

    /**
     * Ouverture d'un élément
     * @param id ID de l'objet à charger
     */
    navigateTo(id: number): void {
        //Navigation vers l'élément
        this.router.navigate(['ReleveFacture',id]);
    }

    /**
     * Affichage de la popup de la liste des alertes
     *
     * @param listAlertes Liste des alertes à afficher
     */
    showListeAlertes(listAlertes: ListeAlertes): void {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{ 
            data: {
                alertes: listAlertes.listeAlertes,
                translate: true
            } 
        });
    }
}