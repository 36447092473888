import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Observable, OperatorFunction, pipe, UnaryFunction} from "rxjs";
import {Result, TypeCodeErreur} from "../../domain/common/http/result";
import {filter, first, map} from "rxjs/operators";

/**
 * Combinaison des opérateurs rxjs filter() pour retirer les valeurs nulles<br>
 * Suivi d'un first() pour ne prendre que la première value et unsub
 *
 * @return L'observable tel qu'il est entré s'il n'est pas null, sinon rien
 */
export function filterFirstNotNull<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(filter(val => val != null), first());
}

/**
 * Map un Result en son objet contenu (ne fonctionne que pour les Result avec une seule data<br>
 * Les erreurs sont gérées automatiquement, et null est renvoyé en cas d'erreur
 *
 * @return L'objet voulu ou null en cas d'erreur
 */
export function mapToObject<R>(name: string, toastr: ToastrService, translate: TranslateService): OperatorFunction<Result, R> {
    return map(result => {
        if (result == null) {//S'il n'y a pas de résultat
            //On affiche une erreur générique
            TypeCodeErreur.showError(null, translate, toastr);
            return null;
        } else if (result.codeErreur === TypeCodeErreur.NO_ERROR) {//S'il n'y a pas d'erreur
            //Si le nom du paramètre est null
            if (name == null) {
                //On ne renvoie rien (R est probablement void)
                return;
            } else {
                //On renvoie l'objet voulu
                return result.data[name] as R;
            }
        } else {//S'il y a une erreur
            //On affiche un toast d'erreur
            TypeCodeErreur.showError(result.codeErreur, translate, toastr);
            return null;
        }
    });
}
