import {Component, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {OmpListComponent} from "../../../omp/omp-list/omp-list.component";
import {AutocompleteService} from "@share/component/autocomplete/autocomplete.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {OMPService} from "../../../omp/omp.service";
import {TranslateService} from "@ngx-translate/core";
import {WorkflowService} from "../../../workflow/workflow.service";
import {DashboardOmpListItemComponent} from "./dashboard-omp-list-item.component";
import {OmpListItemComponent} from "../../../omp/omp-list/omp-list-item/omp-list-item.component";
import {ListeOmp} from "@domain/omp/liste-omp";
import {IDashboardComponent} from "../../IDashboardComponent";
import {ListViewComponent} from "@share/component/list-view/list-view.component";
import {MatDialog} from "@angular/material/dialog";
import {CguService} from '@components/cgu/cgu.service';
import {ToastrService} from 'ngx-toastr';
import {TypeProfil} from "@domain/user/user";
import {SessionStorageService} from '@domain/common/services/session-storage.service';
import {Router} from "@angular/router";

/**
 * Liste des OMP du Dashboard
 */
@Component({
    host: {'data-test-id': 'dashboard-omp-list'},
    selector: 'dashboard-omp-list',
    templateUrl: './dashboard-omp-list.component.html'
})
export class DashboardOmpListComponent extends OmpListComponent implements IDashboardComponent<ListeOmp, OmpListItemComponent> {

    /** Référence au composant de la liste (pour mise à jour au changement de profil) */
    @ViewChild('listViewComponent') listViewComponent: ListViewComponent<ListeOmp,OmpListItemComponent>;

    /**
     * Constructeur
     *
     * @param autocompleteService le service d'autocomplete
     * @param pleaseWaitService le service de chargement
     * @param ompService le service de gestion des OMP
     * @param translateService le service de traduction
     * @param store le store
     * @param workflowService le service de gestion du workflow
     * @param matDialog le composant des popups
     * @param sessionStorageService Service de gestion du Session Storage
     * @param cguService Service des CGU
     * @param toastrService Service de messages au format toast
     * @param router le routeur de l'appli
     */
    constructor(protected autocompleteService: AutocompleteService,
                protected pleaseWaitService: PleaseWaitService,
                protected ompService: OMPService,
                protected translateService: TranslateService,
                protected store: Store<AppState>,
                protected workflowService: WorkflowService,
                protected matDialog: MatDialog,
                protected sessionStorageService: SessionStorageService,
                protected cguService: CguService,
                protected toastrService: ToastrService,
                private router: Router) {
        super(autocompleteService,pleaseWaitService,ompService,translateService,store,workflowService,matDialog,sessionStorageService,cguService,toastrService);
    }

    /**
     * Spécificités de la liste
     */
    protected initListCore() {
        //Il s'agit d'une liste du dashboard
        this.liste.isDashboardList = true;

        //Définition du list item du dashboard
        this.liste.component = DashboardOmpListItemComponent;

        //Adresse de redirection au clic sur le titre
        this.liste.redirect = "/ListeOMP";
    }

    /**
     * Spécificités de la liste
     */
    protected initListSpecific() {
        //Ajout du bouton d'ajout d'un OMP
        this.liste.listeActions = [{
            icon: 'add',
            onPress: () => this.fonction == TypeProfil.COLLABORATEUR ? this.create() : this.createWithCollab(),
            isVisible: () => this.creationsPossibles.ompPossible
        },{
            icon: 'search',
            onPress: () => this.router.navigate([this.liste.redirect]),
            isVisible: () => !this.creationsPossibles.ompPossible
        }];
    }
}
