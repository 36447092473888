import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Referentiel} from "@domain/admin/referentiels/referentiel";
import {ReferentielsService} from "@services/admin/referentiels/referentiels.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ListView, TypeComparaison} from "@domain/common/list-view";
import {ReferentielUsageUserDto} from "@domain/admin/entreprise/referentiel-usage-userDto";
import {ReferentielUsageUserItemComponent} from "../referentiel-usage-user-item.component";
import {DeleteReferentielComponent} from "./delete-referentiel.component";
import {Subscription} from "rxjs";

/**
 * Composant qui permet d'afficher les utilisateurs reliés au référentiel sélectionné, ainsi que de modifier le référentiel
 */
@Component({
    host: {'data-test-id': 'modify-referentiel'},
    selector: 'modify-referentiel',
    templateUrl: './modify-referentiel.component.html'
})
export class ModifyReferentielComponent implements OnInit, OnDestroy {
    /** Liste des utilisateurs reliés au référentiel, pour utiliser le composant ListViewComponent */
    listeUtilisateurs: ListView<ReferentielUsageUserDto, ReferentielUsageUserItemComponent>;

    /** Liste des souscriptions, pour faire un unsubscribe sur chacun lors de la destruction du composant */
    souscriptions: Subscription[] = [];

    /**
     * Constructeur
     *
     * @param translateService Service pour l'utilisation de fr.json
     * @param toastrService Service pour l'affichage des popup d'informations sur le succès ou non de la modification et la suppression du référentiel
     * @param referentielService Service pour récupérer les informations sur le référentiel
     * @param matDialog Boite de dialogue permettant de confirmer la suppression du référentiel
     * @param dialogRef Référence de la boîte de dialogue associée à ce composant
     * @param data Données transmises par referentiels.component.ts pour connaître le référentiel sélectionné par l'utilisateur
     */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private referentielService: ReferentielsService,
        private matDialog: MatDialog,
        public dialogRef: MatDialogRef<ModifyReferentielComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            referentiel: Referentiel
        }) {
    }

    /**
     * Sauvegarde de la modification du référentiel
     */
    saveReferentiel() {
        //Permet de changer le type du référentiel en une lettre pour l'enregistrer correctement en base
        this.changeTypeToCode();
        //Fermeture de la boîte de dialogue et envoie les données nécessaires au traitement des informations
        this.dialogRef.close({ isSave: true, referentiel: this.data.referentiel });
    }

    /**
     * Suppression du référentiel (ouverture de la boite de dialogue de confirmation et suppression du référentiel)
     */
    deleteReferentiel() {
        if (this.data.referentiel.type === 'Catégorie' && this.data.referentiel.defaut) {
            //Impossible de supprimer le référentiel par défaut
            this.toastrService.error(this.translateService.instant('admin.entreprise.referentiels.suppressionDefautImpossible'));
        } else {
            const dialog = this.matDialog.open(DeleteReferentielComponent);

            //Souscription à la fermeture de la boîte de dialogue, pour effectuer les traitements nécessaires (la suppression du référentiel)
            dialog.afterClosed().subscribe((deleteReferentiel: boolean) => {
                //Si l'utilisateur a confirmé sa suppression du référentiel alors on le supprime en base
                if (deleteReferentiel) {
                    //Permet de changer le type du référentiel en une lettre pour rechercher dans la base le bon référentiel, sinon une erreur est levée
                    this.changeTypeToCode();
                    //Fermeture de la boîte de dialogue et envoie les données nécessaires au traitement des informations
                    this.dialogRef.close({isSave: false, referentiel: this.data.referentiel});
                }
            });
        }
    }

    /**
     * Changement du type du référentiel du libellé complet au code d'une lettre, pour l'enregistrement en base, sinon une erreur est levée
     */
    changeTypeToCode() {
        switch (this.data.referentiel.type) {
            case "Catégorie": {
                this.data.referentiel.type = "C";
                break;
            }
            case "Statut Collaborateur": {
                this.data.referentiel.type = "E";
                break;
            }
            case "Mot-clef": {
                this.data.referentiel.type = "M";
                break;
            }
            case "Type de personnel": {
                this.data.referentiel.type = "Q";
                break;
            }
            case "Titre": {
                this.data.referentiel.type = "T";
                break;
            }
        }
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Création de la liste des utilisateurs rattachés au référentiel sélectionné, pour utiliser le composant ListViewComponent
        this.listeUtilisateurs = new ListView<ReferentielUsageUserDto, ReferentielUsageUserItemComponent>({
            uri: `/controller/Referentiel/listeAllUsageReferentiel?typeUsage=UTILISATEUR&typeReferentiel=`+ this.getRequestType() +`&idReferentiel=` + this.data.referentiel.id,
            title: this.translateService.instant('admin.entreprise.referentiels.referentielUtilisateursTitle'),
            component: ReferentielUsageUserItemComponent,
            extraOptions: {

            },
            isFilter: true,
            defaultOrder: 'nom',
            listeFilters: [
                {
                    clef: 'nom',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielUsageUser.nom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'prenom',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielUsageUser.prenom'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'matricule',
                    title: this.translateService.instant('admin.entreprise.referentiels.referentielUsageUser.matricule'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },
            ]
        });
    }

    /**
     * Méthode permettant de récupérer le type du référentiel, pour le faire correspondre en base, pour récupérer les utilisateurs reliés au référentiel
     */
    getRequestType(): string {
        switch (this.data.referentiel.type) {
            case "Catégorie": {
                return "CATEGORIE";
            }
            case "Statut Collaborateur": {
                return "STATUT_COL";
            }
            case "Mot-clef": {
                return "MOTS_CLEFS";
            }
            case "Type de personnel": {
                return "QUALIFICATION";
            }
            case "Titre": {
                return "TITRE";
            }
        }

        //Si le type n'est pas reconnu alors on renvoie une chaîne de caractères vide
        return "";
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //On appelle unsubscribe sur chaque souscription, pour éviter les fuites de mémoire
        this.souscriptions.forEach(subscription => subscription.unsubscribe());
    }
}