<div class="with-floating-button">
    <div class="content" *ngIf="role">
        <page-header [title]="('workflow.roles.detail.role' | translate) + (role.libelle ? ' ' + role.libelle : '')"
                     [listeItems]="listeTabItems"
                     [niveauAlerte]="listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)"
                     (onGoBack)="goBack()"
                     [isPending]="isSaving || (isLoading | async)">
        </page-header>

        <!-- Onglet des généralités -->
        <div *ngIf="ongletsCharges.get(selectedItem?.code)" [hidden]="selectedItem?.code !== Onglets.GENERALITE">
            <!-- Cadre rôle -->
            <role-role [role]="role" [isPending]="isSaving || (isLoading | async)" (profilChanged)="refreshOnglet.next()"></role-role>

            <!-- Cadre Options -->
            <role-options *ngIf="role.fonction != null && role.fonction !== ProfilsAttribuables.COLLABORATEUR && role.fonction !== ProfilsAttribuables.ASSISTANT" [role]="role"></role-options>
        </div>

        <!-- Onglet des droits -->
        <div *ngIf="ongletsCharges.get(selectedItem?.code) && role.fonction === ProfilsAttribuables.SOUS_ADMINISTRATEUR" [hidden]="selectedItem?.code !== Onglets.DROITS">
            <!-- Cadre droits -->
            <role-droits [role]="role" [(listeAllDroits)]="listeAllDroits"></role-droits>
        </div>

        <!-- Onglet usage -->
        <div *ngIf="ongletsCharges.get(selectedItem?.code) && role.idRole != null" [hidden]="selectedItem?.code !== Onglets.USAGE">
            <!-- Cadre usage -->
            <role-usage></role-usage>
        </div>
    </div>

    <please-wait class="content" *ngIf="!role"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving || (isLoading | async)"></floating-button>
</div>