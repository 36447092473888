import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Origine,Population,TypeCreation} from "@domain/admin/entreprise/population/population";

/**
 * Composant d'affichage d'une population dans la liste des populations
 */
@Component({
    host: {'data-test-id': 'population-list-item'},
    templateUrl: './population-list-item.component.html'
})
export class PopulationListItemComponent extends ListViewItem<Population> {

    /* Declaration pour dans le template */
    Origine = Origine;
    TypeCreation = TypeCreation;

    /**
     * Constructeur
     *
     * @param router Router de l'application
     */
    constructor(
        private router: Router
    ) {
        super();
    }

    /**
     * Méthode appelée lors de la sélection de la population
     */
    onSelect() {
        //Navigation vers la page d'informations de la population
        this.router.navigate(['Admin/Entreprise/Populations/Population/' + this.data.idPopulation]);
    }
}
