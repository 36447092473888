<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-frais"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="openFrais()">{{ data.libelleTransaction }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.voyages.fraisAgence.fraisAgenceTab.liste.type'"></strong>
                <span>{{ data.libelleType }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.fraisAgence.fraisAgenceTab.liste.offlineHt'"></strong>
                <span>{{ data.montantOffline | montant:'EUR' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.fraisAgence.fraisAgenceTab.liste.onlineHt'"></strong>
                <span>{{ data.montantOnline | montant:'EUR' }}</span>
            </li>
        </ul>
    </div>
</div>