<mat-card id="bloc-contentieux">
    <mat-card-title>
        <span translate>facture.contentieux.title</span>
    </mat-card-title>
    <mat-card-content>
        <form autocomplete="off" #form="ngForm" name="form_contentieux">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <custom-input ngDefaultControl name="litige" [ngModel]="contentieux.litige" [(customModel)]="contentieux.litige"
                                  customType="checkbox"
                                  libelle="facture.contentieux.litige" postLibelle="facture.contentieux.litigeTexte"></custom-input>

                    <custom-input ngDefaultControl name="alerte" [ngModel]="alerte" [(customModel)]="alerte"
                                  customType="checkbox" (customModelChange)="onDesactivationAlerte.emit();"
                                  libelle="facture.contentieux.alerte" postLibelle="facture.contentieux.alerteTexte"></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl name="motif" customType="textarea" libelle="facture.contentieux.motif" rCol="10"
                                  [(ngModel)]="contentieux.motif" [disabled]="!canModifier" [required]="contentieux.litige || !contentieux.alerte"></custom-input>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>