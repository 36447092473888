import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Carte, TypesCarte, TypesChamp, TypesPrestation } from "@domain/admin/voyages/referentiels/carte";
import { TypeCodeErreur } from "@domain/common/http/result";
import { TranslateService } from "@ngx-translate/core";
import { VoyagesReferentielsService } from "@services/admin/voyages/voyages-referentiels.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import {Compagnie} from "@domain/profil/compagnie";
import {first} from "rxjs/operators";

/**
 * Composant permettant l'affichage du formulaire de création et de modification d'une carte voyageur
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-cartes-voyageurs-infos'},
    selector: 'voyages-referentiels-cartes-voyageurs-infos',
    templateUrl: './voyages-referentiels-cartes-voyageurs-infos.component.html'
})
export class VoyagesReferentielsCartesVoyageursInfosComponent implements OnInit, OnDestroy {
    /** Booléen pour savoir si on doit afficher le formulaire en mode création ou non */
    isCreation: boolean = false;

    /** Carte voyageur à afficher sur le formulaire */
    carteVoyageur: Carte = {
        actif: true,
        custom: false,
        compagnie: {},
        libelle: "",
        typeCarte: "",
        typeChamp: "",
        typePrestation: "",
        code: null
    };

    /** Formulaire de la carte voyageur */
    @ViewChild('form') form: NgForm;

    /** Liste des prestations disponibles */
    typesPresta = [{
        id: 0,
        type: TypesPrestation.AVION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
    },{
        id: 1,
        type: TypesPrestation.TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
    },{
        id: 2,
        type: TypesPrestation.HOTEL,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
    },{
        id: 3,
        type: TypesPrestation.VOITURE_DE_LOCATION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
    },{
        id: 4,
        type: TypesPrestation.VOITURE_SOCIETE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
    },{
        id: 5,
        type: TypesPrestation.VOITURE_PERSONNELLE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
    },{
        id: 6,
        type: TypesPrestation.DOCUMENT,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.document')
    },{
        id: 7,
        type: TypesPrestation.AVION_TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
    },{
        id: 8,
        type: TypesPrestation.AVANCE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avance')
    },{
        id: 9,
        type: TypesPrestation.TRANSPORT_1,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport1')
    },{
        id: 10,
        type: TypesPrestation.TRANSPORT_2,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport2')
    },{
        id: 11,
        type: TypesPrestation.TRANSPORT_3,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.transport3')
    },{
        id: 12,
        type: TypesPrestation.SERVICE_1,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service1')
    },{
        id: 13,
        type: TypesPrestation.SERVICE_2,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service2')
    },{
        id: 14,
        type: TypesPrestation.SERVICE_3,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.service3')
    }];

    /** Liste des types de cartes disponibles */
    typesCarte = [{
        id: 0,
        type: TypesCarte.FIDELITE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.fidelite')
    },{
        id: 1,
        type: TypesCarte.ABONNEMENT,
        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.abonnement')
    },{
        id: 2,
        type: TypesCarte.SUPPLIER,
        libelle: this.translateService.instant('admin.voyages.referentiels.typeCarte.supplier')
    }];

    /** Liste des champs disponibles */
    typesChamps = [{
        id: 0,
        type: TypesChamp.NUM,
        libelle: this.translateService.instant('admin.voyages.referentiels.typesChamp.numeroUniquement')
    },{
        id: 1,
        type: TypesChamp.NUM_DU_AU,
        libelle: this.translateService.instant('admin.voyages.referentiels.typesChamp.numeroPeriode')
    },{
        id: 2,
        type: TypesChamp.NUM_DU_AU_DE_A,
        libelle: this.translateService.instant('admin.voyages.referentiels.typesChamp.numeroPeriodeTroncon')
    }]

    /** Liste des compagnies disponibles */
    listeCompagnies: Compagnie[];

    /** Prestation à afficher dans le formulaire */
    typePresta = this.typesPresta[0];

    /** Type de la carte voyageur à afficher */
    typeCarte = this.typesCarte[0]

    /** Type du champ de la carte voyageur à afficher */
    typeChamp = this.typesChamps[0];

    /** Liste des souscriptions, pour faire un unsubscribe sur chacun lors de la destruction du composant */
    souscriptions: Subscription[] = [];

    /** Pending pour le bouton d'enregistrement */
    isSaving: boolean;

    /** Pending pour le bouton de suppression */
    isDeleting: boolean;

    /**
     * Constructeur
     *
     * @param activatedRoute Route d'accès à cette page
     * @param translateService Service de traduction
     * @param voyagesReferentielsService Service de gestion des référentiels du menu Voyages
     * @param matDialogRef service de référence de la popup
     * @param data données transmises à la popup
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param router Router de l'application pour naviguer entre les pages
     */
    constructor(private activatedRoute: ActivatedRoute,
                private translateService: TranslateService,
                private voyagesReferentielsService: VoyagesReferentielsService,
                private matDialogRef: MatDialogRef<VoyagesReferentielsCartesVoyageursInfosComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { idCarte: number, isCarteCustom:boolean },
                private toastrService: ToastrService,
                private router: Router) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        this.onInit(this.data.idCarte, this.data.isCarteCustom);
    }

    /**
     * Méthode appelée lorsque le code de la carte voyageur a été récupérée
     *
     * @param idCarte id de la carte à charger
     * @param isCarteCustom indique si la carte est personnalisée (donc modifiable)
     */
    onInit(idCarte: number, isCarteCustom:boolean) {
        //On est en mode création si l'identifiant de la compagnie est égal à 0 (sinon on est en mode modification)
        this.isCreation = idCarte === 0;

        //Si on est en création
        if (this.isCreation) {
            //Récupération de la liste des compagnies disponibles, pour le choix d'une compagnie pour la carte voyageur
            this.voyagesReferentielsService.getListeCompagnies().pipe(first()).subscribe(res => {
                this.listeCompagnies = res.data.listeCompagnies;
            });
            //Une carte créée manuellement est custom
            this.carteVoyageur.custom = true;
        } else {
            //On force le custom pour que les champs soient enabled ou disabled tout de suite, sans attendre le chargement de la carte
            this.carteVoyageur.custom = isCarteCustom;

            //On charge la carte voyageur sélectionnée et on récupère toutes ses informations à afficher
            this.voyagesReferentielsService.getCarteVoyageurById(idCarte).pipe(first()).subscribe(data => {
                //Récupération des informations de la carte voyageur sélectionnée
                this.carteVoyageur = data.data.carte;

                //Si la compagnie n'est pas modifiable, pas besoin de charger la liste des compagnies
                if (!this.carteVoyageur.custom) {
                    this.listeCompagnies = [this.carteVoyageur.compagnie];
                } else {
                    this.voyagesReferentielsService.getListeCompagnies().pipe(first()).subscribe(res => {
                        this.listeCompagnies = res.data.listeCompagnies;
                    });
                }

                //Récupération du type de la carte voyageur (Abonnement, Fidélité, Fournisseur)
                let typeCarte = data.data.carte.typeCarte;
                //Boucle pour afficher correctement l'information dans le custom-input (objectselect)
                this.typesCarte.forEach(type => {
                    if (type.type === typeCarte) {
                        this.typeCarte = type;
                    }
                })

                //Récupération du type de prestation de la carte voyageur
                let typePrestation = data.data.carte.typePrestation;
                //Boucle pour afficher correctement l'information dans le custom-input (objectselect)
                this.typesPresta.forEach(presta => {
                    if(presta.type === typePrestation) {
                        this.typePresta = presta;
                    }
                });

                //Récupération du type du champ de la carte voyageur
                let typeChamp = data.data.carte.typeChamp;
                //Boucle pour afficher correctement l'information dans le custom-input (objectselect)
                this.typesChamps.forEach(champ => {
                    if(champ.type === typeChamp) {
                        this.typeChamp = champ;
                    }
                });
            });
        }
    }

    /**
     * Méthode appelée lors de la sauvegarde en base des informations de la carte voyageur
     */
    save() {
        //Mise à jour du champ "typeCarte" de la carte voyageur, avec les informations du champ associé du formulaire
        this.carteVoyageur.typeCarte = this.typeCarte.type;
        //Mise à jour du champ "typePrestation" de la carte voyageur, avec les informations du champ associé du formulaire
        this.carteVoyageur.typePrestation = this.typePresta.type;
        //Mise à jour du champ "typeChamp" de la carte voyageur, avec les informations du champ associé du formulaire
        this.carteVoyageur.typeChamp = this.typeChamp.type;

        //Si le formulaire est valide alors on enregistre/met à jour la carte voyageur en base
        if (this.form.valid) {
            this.souscriptions.push(this.voyagesReferentielsService.saveCarteVoyageur(this.carteVoyageur).subscribe(res => {
                //Si la réponse retourne une erreur
                if (res.codeErreur != TypeCodeErreur.NO_ERROR) {
                    //Si le code erreur est égal à 6 alors c'est qu'il y a un problème de doublon
                    if (res.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
                        //Affichage d'un message d'erreur d'enregistrement dû à la présence d'un doublon
                        this.toastrService.error(this.translateService.instant('admin.voyages.referentiels.errorDoublon'));
                    } else {
                        //Sinon on affiche un message d'erreur d'enregistrement
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                } else {
                    //Si la réponse ne contient pas de message d'erreur alors on affiche un message de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de la popup
                    this.matDialogRef.close(true);
                }
            }));
        } else {
            //Si, au contraire, le formulaire n'est pas valide alors affichage d'un message disant que le formulaire n'est pas valide
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Méthode appelée lors de la suppression de la carte voyageur en base
     */
    delete() {
        //Activation du pending sur le bouton
        this.isDeleting = true;

        //Suppression de la carte voyageur en base
        this.souscriptions.push(this.voyagesReferentielsService.deleteCarte(this.carteVoyageur.idCarte).subscribe(res => {
            //Si la réponse contient un code d'erreur, affichage d'un message d'erreur de suppression
            if (res.codeErreur != TypeCodeErreur.NO_ERROR) {
                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
            } else {
                //Sinon on affiche un message de succès
                this.toastrService.success(this.translateService.instant('global.success.suppression'));

                //Fermeture de la popup
                this.matDialogRef.close(true);
            }

            //Désactivation du pending sur le bouton
            this.isDeleting = false;
        }));
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Application de "unsubscribe" à toutes les souscriptions
        this.souscriptions.forEach(souscription => souscription.unsubscribe());
    }
}
