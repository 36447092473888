import {Action} from '../domain/action';

/**
 * Export des actions
 */
export const RULE_FULFILLED = 'RULE_FULFILLED';

/**
 * Interface
 */
export interface ListViewState {
    /** Données **/
    rule?: any
}

/**
 * Création du reducer
 */
export function listviewReducer(state: ListViewState = {},action: Action<any>): ListViewState {
    //Vérification de l'action
    switch (action.type) {
    case RULE_FULFILLED:
        //Session de l'utilisateur
        state = {
            ...state,
            rule: action.payload   
        };
        break;
    }

    //Retour de l'état
    return state;
}
