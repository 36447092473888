import {Component,Input,OnChanges,SimpleChanges,ViewChild} from '@angular/core';
import {Enveloppe} from "@domain/admin/entreprise/analytique/enveloppe";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {Analytique} from "@components/analytique/analytique";
import {SettingsGlobalState} from "@domain/settings/settings";
import {first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {NgForm} from "@angular/forms";

/**
 * Composant d'affichage de la pageEnveloppeGeneralite
 *
 * @author Lionel Gimenez
 * @date 29/11/2023
 */
@Component({
    host: {'data-test-id': 'enveloppe-generalite'},
    selector: 'enveloppe-generalite',
    templateUrl: './enveloppe-generalite.component.html'
})
export class EnveloppeGeneraliteComponent implements OnChanges {

    /** Enveloppe sélectionnée */
    @Input() enveloppe: Enveloppe;

    /** Paramètres */
    @Input() settings: SettingsGlobalState;

    /** Indique si le formulaire a été ouvert en création ou en modification */
    @Input() isCreation: boolean = false;

    /** La liste analytique */
    listeAnalytique: Array<Analytique> = new Array<Analytique>();

    /** Le type entite pour la liste analytique */
    typeEntite: TypeEntite;

    /** Formulaire de la page */
    @ViewChild('form') form: NgForm;

    /**
     * Constructeur
     */
    constructor(
        private enveloppeDetailService: EnveloppeDetailService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {
    }

    /** Hook on change */
    ngOnChanges(changes: SimpleChanges): void {
        //Si l'enveloppe a changé
        if (changes.enveloppe) {
            this.initAnalytique();
        }
    }

    /**
     * Indique si le composant est valide pour une sauvegarde
     * @return true si le composant est valide
     */
    valid(): boolean {
        return this.form.valid;
    }

    /**
     * Initialise l'analytique
     */
    initAnalytique(): void {
        //Création du type entite custom pour l'analytique
        this.typeEntite = new TypeEntite();
        this.typeEntite.codeProjet = true;
        this.typeEntite.axe5 = true;
        this.typeEntite.axe6 = true;
        this.typeEntite.field5 = false;
        this.typeEntite.field6 = false;

        //Si on a une liste analytique
        if (this.enveloppe.listeAnalytique != null) {
            //On assigne la liste
            this.listeAnalytique = Array.from(this.enveloppe.listeAnalytique);
        }
    }

    /**
     * Handler lors d'une modification de la case contrôle budgétaire
     */
    onControleChange(): void {
        //Si on décoche la case
        if (!this.enveloppe.alerteEdited) {
            //On reset les champs bloquants et non bloquants
            this.enveloppe.pourcentageBloquant = null;
            this.enveloppe.pourcentageNonBloquant = null;
        }
    }

    /**
     * A la modification de l'analytique, on met à jour l'enveloppe
     */
    onModificationAnalytique(): void {
        this.enveloppe.listeAnalytique = this.listeAnalytique.map(analytique => {
            analytique.pourcentage = null;
            return analytique;
        });
    }

    /**
     * Méthode appelée pour la supression d'un analytique
     */
    deleteAnalytique(id: number): void {
        this.enveloppeDetailService.deleteAnalytique(id)
            .pipe(first())
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                }
            });
    }
}