import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState } from "@domain/appstate";
import { DossierTravel } from '@domain/od/proposition';
import { NatureVoyage } from "@domain/voyage/nature/nature-voyage";
import { Store } from "@ngrx/store";
import { filterFirstNotNull } from "@share/utils/rxjs-custom-operator";

@Component({
    host: {'data-test-id': 'nature-item'},
    selector: 'nature-item',
    templateUrl: './od-proposition-nature-item.component.html',
    styleUrls: ['./od-proposition-nature-item.component.scss']
})
export class ODPropositionnatureItemComponent implements OnInit {

    /** Élément courant */
    @Input() nature: NatureVoyage;

    /** Dossier Travel à afficher */
    @Input() dossier: DossierTravel;

    /** True si c'est la première nature du dossier */
    @Input() canGoToSbt?: boolean = false;

    /** Envoie un évènement pour se connecter au SBT */
    @Output() goToSbt: EventEmitter<void> = new EventEmitter<void>();

    /* Devise entreprise */
    deviseEntrep: string;

    /* Tooltip du bouton de reconnexion au portail */
    portalButtonTooltip: string;

    /** Indique si le cadre de détail est ouvert */
    isOuvert: boolean = false;

    constructor(
        private datePipe: DatePipe,
        private store: Store<AppState>
    ) {

        //Sélection du paramétrage pour savoir si le suivi budgétaire est activé
        this.store.select(state => state.settings?.['Global']).pipe(filterFirstNotNull()).subscribe(settings => {
            this.deviseEntrep = settings.deviseEntreprise;
        });
    }

    ngOnInit(): void {
        this.getPortalButtonTooltip();
    }

    /**
     * Renvoie la String d'affichage de la bulle du lieu de départ pour la nature
     */
    getLibelleLieuDepart(): string {
        //On prend toutes les données pouvant constituer les informations de départ
        let donnees = [
            this.nature.stationPrise,
            this.nature.lieuDebut?.libelle
        ];

        //On retire celles qui sont nulles, et on les joint avec un tiret
        return donnees.filter(e => e).join(' - ');
    }

    /**
     * Renvoie la String d'affichage de la bulle de l'horaire de départ pour la nature
     */
    getLibelleHoraireDepart(): string {
        //On prend toutes les données pouvant constituer les informations de départ
        let donnees = [
            this.datePipe.transform(this.nature.dateDebut, 'shortDate'),
            this.nature.heureDebut
        ];

        //On retire celles qui sont nulles, et on les joint avec un tiret
        return donnees.filter(e => e).join(' - ');
    }

    /**
     * Renvoie la String d'affichage de la bulle du lieu d'arrivée pour la nature
     */
    getLibelleLieuArrivee(): string {
        //On prend toutes les données pouvant constituer les informations de départ
        let donnees = [
            this.nature.stationRendu,
            this.nature.lieuFin?.libelle,
        ];

        //On retire celles qui sont nulles, et on les joint avec un tiret
        return donnees.filter(e => e).join(' - ');
    }

    /**
     * Renvoie la String d'affichage de la bulle de l'horaire d'arrivée pour la nature
     */
    getLibelleHoraireArrivee(): string {
        //On prend toutes les données pouvant constituer les informations de départ
        let donnees = [
            this.datePipe.transform(this.nature.dateFin, 'shortDate'),
            this.nature.heureFin
        ];

        //On retire celles qui sont nulles, et on les joint avec un tiret
        return donnees.filter(e => e).join(' - ');
    }

    /**
     * Redirige vers le SBT
     */
    goToSbtOnline(): void {
        //On émet l'évènement pour indiquer qu'on souhaite une redirection vers le SBT
        this.goToSbt.next();
    }

    /**
     * Permet de déterminer le tooltip du bouton de reconnexion au portail
     */
    getPortalButtonTooltip() {
        if (!this.dossier.booked && this.dossier.pve) {
            this.portalButtonTooltip = "od.voyage.propositionNonReservee";
        } else if (this.dossier.booked && !this.dossier.pve) {
            this.portalButtonTooltip = "od.voyage.propositionAntiPVE";
        } else if (!this.dossier.booked && !this.dossier.pve) {
            this.portalButtonTooltip = "od.voyage.propositionAntiPVENonReservee";
        } else {
            this.portalButtonTooltip = "od.voyage.goToPortail";
        }
    }
}