<h1 mat-dialog-title>
    <span [translate]="'referentielExterne.add'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #referentielExterneForm="ngForm">
        <fieldset [disabled]="false">
            <div class="row">
                <label class="col-md-2 required"><span [translate]="'referentielExterne.libelle'"></span></label>
                <div class="col-md-10">
                    <mat-form-field class="d-flex">
                        <input matInput name="libelle" [(ngModel)]="referenceExterne.libelle" required/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-2"><span [translate]="'referentielExterne.code'"></span></label>
                <div class="col-md-6">
                    <mat-form-field class="d-flex">
                        <input matInput name="code" [(ngModel)]="referenceExterne.code"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-2 required"><span [translate]="'referentielExterne.valeur'"></span></label>
                <div class="col-md-10">
                    <mat-form-field class="d-flex">
                        <input matInput name="valeur" [(ngModel)]="referenceExterne.valeur" required/>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-2"><span [translate]="'referentielExterne.date'"></span></label>
                <div class="col-md-4">
                    <mat-form-field class="d-flex">
                        <input name="date" matInput [matDatepicker]="datePicker" [(ngModel)]="referenceExterne.date">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field class="d-flex">
                        <input type="time" name="heure" matInput [(ngModel)]="referenceExterne.heure">
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <label class="col-md-2 align-items-start p-t-15"><span [translate]="'referentielExterne.description'"></span></label>
                <div class="col-md-10">
                    <mat-form-field class="d-flex">
                        <textarea rows="3" matInput name="description" [(ngModel)]="referenceExterne.description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" [disabled]="referentielExterneForm.invalid" (click)="save()"><span [translate]="'global.actions.enregistrer'"></span></button>
</div>
