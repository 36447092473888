import {NgModule} from '@angular/core';
import {ShareModule} from "../../share/share.module";
import {ProfilComponent} from "./profil.component";
import {ProfilService} from "./profil.service";
import {ProfilUserComponent} from './user/profil-user.component';
import {ProfilDonneesComponent} from './donnees/profil-donnees.component';
import {ProfilCguComponent} from './cgu/profil-cgu.component';
import {AProposComponent} from './a-propos/a-propos.component';
import {ProfilAppMobileComponent} from './user/app-mobile/profil-app-mobile.component';
import {ProfilAppMobileListItemComponent} from './user/app-mobile/profil-app-mobile-list-item.component';
import {QRCodeModule} from "angularx-qrcode";
import {DocumentModule} from "../document/document.module";
import {ProfilDonneesTelephoneListItemComponent} from './donnees/telephone/profil-donnees-telephone-list-item.component';
import {ProfilDonneesAdresseListItemComponent} from './donnees/adresse/profil-donnees-adresse-list-item.component';
import {ProfilDonneesDocumentListItemComponent} from './donnees/document/profil-donnees-document-list-item.component';
import {ProfilDonneesCompteBancaireListItemComponent} from './donnees/compteBancaire/profil-donnees-compte-bancaire-list-item.component';
import {ProfilDonneesCartePaiementListItemComponent} from './donnees/cartePaiement/profil-donnees-carte-paiement-list-item.component';
import {ProfilDonneesCarteVoyageListItemComponent} from './donnees/carteVoyage/profil-donnees-carte-voyage-list-item.component';
import {ProfilDonneesVehiculeListItemComponent} from './donnees/vehicule/profil-donnees-vehicule-list-item.component';
import {ProfilDonneesContactUrgenceListItemComponent} from './donnees/contactUrgence/profil-donnees-contact-urgence-list-item.component';
import {ProfilDonneesAddTelephoneComponent} from './donnees/telephone/profil-donnees-add-telephone.component';
import {ProfilDonneesAddAdresseComponent} from './donnees/adresse/profil-donnees-add-adresse.component';
import {ProfilDonneesAddDocumentComponent} from './donnees/document/profil-donnees-add-document.component';
import {ProfilDonneesAddCompteBancaireComponent} from './donnees/compteBancaire/profil-donnees-add-compte-bancaire.component';
import {NgxIbanModule} from "ngx-iban";
import {ProfilDonneesAddCartePaiementComponent} from './donnees/cartePaiement/profil-donnees-add-carte-paiement.component';
import {CreditCardDirectivesModule} from "angular-cc-library";
import {ProfilDonneesAddCarteVoyageComponent} from './donnees/carteVoyage/profil-donnees-add-carte-voyage.component';
import {ProfilDonneesAddContactUrgenceComponent} from './donnees/contactUrgence/profil-donnees-add-contact-urgence.component';
import {ProfilDonneesAddVehiculeComponent} from './donnees/vehicule/profil-donnees-add-vehicule.component';
import {ProfilPreferencesAnalytiquesComponent} from './user/preferences-analytiques/profil-preferences-analytiques.component';
import {ProfilPreferenceAnalytiquesListItemComponent} from './user/preferences-analytiques/profil-preference-analytiques-list-item.component';
import {ProfilPreferenceAnalytiquesAddComponent} from './user/preferences-analytiques/profil-preference-analytiques-add.component';
import {ProfilPreferenceAnalytiquesTypeEntiteListItemComponent} from './user/preferences-analytiques/profil-preference-analytiques-type-entite-list-item.component';
import {NgxMatIntlTelInputModule} from "ngx-mat-intl-tel-input";
import {LieuModule} from '../lieu/lieu.module';
import {DonneesConnexionComponent} from './user/donnees-connexion/donnees-connexion.component';
import {ChangePasswordComponent} from './user/donnees-connexion/change-password/change-password.component';
import {LoginService} from "../../share/login/login.service";
import {ProfilDonneesInfosPersoComponent} from './donnees/infosPerso/profil-donnees-infos-perso.component';
import {ProfilDonneesModifyInfosPersoComponent} from './donnees/infosPerso/profil-donnees-modify-infos-perso.component';
import {ProfilDonneesPopupSuspensionRgpdComponent} from "@components/profil/donnees/popup-suspension-rgpd/profil-donnees-popup-suspension-rgpd.component";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ProfilPersonnalisationComponent} from "@components/profil/user/personnalisation/profil-personnalisation.component";

@NgModule({
    declarations: [ProfilComponent,
        ProfilUserComponent,
        ProfilDonneesComponent,
        ProfilCguComponent,
        ProfilAppMobileComponent,
        ProfilAppMobileListItemComponent,
        ProfilDonneesTelephoneListItemComponent,
        ProfilDonneesAdresseListItemComponent,
        ProfilDonneesDocumentListItemComponent,
        ProfilDonneesCompteBancaireListItemComponent,
        ProfilDonneesCartePaiementListItemComponent,
        ProfilDonneesCarteVoyageListItemComponent,
        ProfilDonneesVehiculeListItemComponent,
        ProfilDonneesContactUrgenceListItemComponent,
        ProfilDonneesAddTelephoneComponent,
        ProfilDonneesAddAdresseComponent,
        ProfilDonneesAddDocumentComponent,
        ProfilDonneesAddCompteBancaireComponent,
        ProfilDonneesAddCartePaiementComponent,
        ProfilDonneesAddCarteVoyageComponent,
        ProfilDonneesAddContactUrgenceComponent,
        ProfilDonneesAddVehiculeComponent,
        ProfilPreferencesAnalytiquesComponent,
        ProfilPersonnalisationComponent,
        ProfilPreferenceAnalytiquesListItemComponent,
        ProfilPreferenceAnalytiquesAddComponent,
        ProfilPreferenceAnalytiquesTypeEntiteListItemComponent,
        ProfilDonneesInfosPersoComponent,
        ProfilDonneesModifyInfosPersoComponent,
        ProfilDonneesPopupSuspensionRgpdComponent,
        DonneesConnexionComponent,
        ChangePasswordComponent,
        AProposComponent
    ],
    imports: [
        ShareModule,
        QRCodeModule,
        DocumentModule,
        NgxIbanModule,
        CreditCardDirectivesModule,
        NgxMatIntlTelInputModule,
        LieuModule,
        PreviewAlerteModule,
    ],
    exports: [
        ProfilDonneesAddAdresseComponent,
        ChangePasswordComponent
    ],
    providers: [ProfilService, LoginService]
})
export class ProfilModule { }
