import {Component,Inject,OnInit} from '@angular/core';
import {FactureDepense} from "@domain/facture/facture-depense";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Nature} from '@domain/prestation/nature';
import {SettingsFCState} from "@domain/settings/settings";
import {Facture} from "@domain/facture/facture";
import {TypePortee} from '@domain/workflow/workflow';
import {Prestation,TypePrestation} from "@domain/prestation/prestation";
import {HttpClient} from "@angular/common/http";
import {TypeCodeErreur} from "@domain/common/http/result";
import {filter,finalize,first} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {Field,FieldContainerWrapper} from "@domain/settings/field";
import {OptionWithIcon} from "@share/component/custom-input/sub-component/select/option-with-icon";
import {NatureVoyage} from "@domain/prestation/nature-voyage";
import {SettingsService} from "@components/settings/settings.service";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {Taxe} from "@domain/taxe/taxe";
import {Taxe as SaisieTaxe} from "@domain/facture/taxe";
import {DecimalPipe} from "@angular/common";
import roundHalfEven from "round-half-even";
import {Observable,of} from "rxjs";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {FactureService} from "@components/facture/facture.service";

/**
 * Création / modification d'une ligne de détail
 *
 * @author Laurent Convert
 * @date 02/02/2023
 */
@Component({
    host: {'data-test-id': 'facture-detail-creation'},
    selector: 'facture-detail-creation',
    templateUrl: './facture-detail-creation.component.html'
})
export class FactureDetailCreationComponent implements OnInit {
    /** Déclaration pour accès dans le template */
    Nature = Nature;
    TypePortee = TypePortee;
    
    /** Liste des champs customs */
    listeFieldParam: Field[];
    
    /** Wrapper pour les champs complémentaires de la ligne de détail */
    fieldContainer: FieldContainerWrapper;
    
    /** Prestation associée à la ligne en cours */
    prestation: Prestation;
    
    /** Définition de la liste des natures */
    listeNature: Array<OptionWithIcon> = Nature.values().map(nature => {
        return new OptionWithIcon({
            icone: Nature.icone(nature),
            libelle: Nature.traduction(nature),
            value: nature.valueOf()
        });
    });
    
    /** Montant HT calculé */
    montantHT: number;
    
    /** Indicateur du chargement en cours */
    isLoading: boolean = false;
    
    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;
    
    /** Indicateur de requête HTTP en cours */
    isPending: boolean = false;
    
    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving || this.isDeleting || this.isPending;
    }
    
    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: {factureDepense: FactureDepense,facture: Facture,canModifier:boolean,settings: SettingsFCState},
                private matDialogRef: MatDialogRef<FactureDetailCreationComponent,boolean>,
                private http: HttpClient,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private store: Store<AppState>,
                private decimalPipe: DecimalPipe,
                private settingsService: SettingsService,
                private confirmService: ConfirmService,
                private factureService: FactureService) {
    }
    
    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Création : Initialisation
        if (!(this.data.factureDepense?.idDetFacture > 0)) {
            this.data.factureDepense = new FactureDepense({
                idDetFacture: 0,
                idEntFacture: this.data.facture.idFacture,
                nature: Nature.AUCUNE,
                quantite: 1
            } as FactureDepense);
        }
        
        //Début du chargement
        this.isLoading = true;
    
        //Chargement
        this.factureService.loadFactureDepense(this.data.factureDepense.idDetFacture)
            .pipe(first())
            .subscribe(listeFieldParam => {
                //Récupération de la liste des champs prédéfinis configurés
                this.listeFieldParam = listeFieldParam;

                //Construction du wrapper permettant la gestion des champs prédéfinis
                this.fieldContainer = new FieldContainerWrapper(this.data.factureDepense);
                
                //Récupération de la prestation dans un champ à part pour l'autocomplete (pour ne pas modifier l'objet sur la dépense tant qu'un élément n'a pas été sélectionné...)
                this.prestation = this.data.factureDepense.prestation;
                
                //Rafraichissement des champs de saisie de la taxe en fonction de la conf de la prestation
                this.refreshTaxeForPrestation().pipe(finalize(() => this.isLoading = false)).subscribe();
            }, error => {
                //Affichage d'un message d'erreur
                TypeCodeErreur.showError(error,this.translateService,this.toastrService);
    
                //Fin du chargement
                this.isLoading = false;
            });
    }
    
    /**
     * Getter permettant de savoir si on est en taux multiple
     */
    get isTauxMultiple(): boolean {
        return this.data.factureDepense.listeTaxe?.length > 1;
    }
    
    /**
     * Changement de prestation
     */
    onPrestationChange() {
        //Mise à jour de la ligne avec la prestation sélectionnée via l'autocomplete
        this.data.factureDepense.prestation = this.prestation;
        
        //Mise à jour de la nature à partir de celle paramétrée sur la prestation
        this.data.factureDepense.nature = this.prestation ? NatureVoyage.getNature(this.prestation.natureVoyage) : null;
        
        //Mise à jour de la quantité
        this.onPrestationChangeUpdateQuantite(this.prestation,this.data.factureDepense);
        
        //Début du rafraichissement
        this.isPending = true;
        
        //Rafraichissement des taxes pour la prestation sélectionnée
        this.refreshTaxeForPrestation().pipe(finalize(() => this.isPending = false)).subscribe();
    }
    
    /**
     * Force la mise à jour de la quantité sur le frais suivant la prestation sélectionnée
     *
     * @param prestation La prestation sélectionnée
     * @param depense la ligne de détail
     */
    onPrestationChangeUpdateQuantite(prestation: Prestation,depense: {quantite?: number}) {
        //Vérification de la quantité
        if (prestation.quantiteDefaut) {
            //Mise à jour de la quantité par défaut
            depense.quantite = prestation.quantiteDefaut;
        } else if (prestation.type == TypePrestation.FORFAIT && !prestation.quantiteModifiable) {
            //Mise à jour de la quantité
            depense.quantite = 1;
        }
    }
    
    /**
     * Rafraichissement de la taxe liée à la prestation
     */
    refreshTaxeForPrestation(): Observable<void> {
        //Vérification de la prestation
        if (this.data.factureDepense.prestation?.idAppTva && this.data.settings.idPays) {
            //Recherche de la taxe
            return this.settingsService.findTaxeForVilleAndPays({
                idDomaine: this.data.factureDepense.prestation.idAppTva,
                idPays: this.data.settings.idPays,
                date: this.data.facture.date.toDate()
            }).pipe(first())
            .mergeMap((taxe: Taxe) => {
                //Mise à jour de la taxe sur la dépense
                this.data.factureDepense.listeTaxe = taxe.listeTaux.map(tauxConfig => {
                    //Recherche du taux dans la liste de ceux paramétrés
                    const saisie = this.data.factureDepense.listeTaxe?.find(taux => taux.idTaux == tauxConfig.idTaux);
                    
                    //Création d'un objet pour la saisie de la taxe correspondant à ce taux
                    let taxeSaisie: SaisieTaxe = new SaisieTaxe(saisie);
                    taxeSaisie.idDepense = this.data.factureDepense.idDetFacture;
                    taxeSaisie.idTaux = tauxConfig.idTaux;
                    taxeSaisie.taux = tauxConfig.taux;
                    taxeSaisie.defaut = tauxConfig.defaut;
                    
                    //Si aucune taxe n'avait été saisie auparavant pour ce taux, on initialise les montants à 0.0
                    if (saisie == undefined) {
                        //Initialisation des montants à 0.0
                        taxeSaisie.reset();
                    }
                    
                    return taxeSaisie;
                });
                
                //Calcul du montant HT
                this.computeMontantHT();
                
                return of();
            });
        } else {
            //Retrait de la taxe
            this.data.factureDepense.listeTaxe = [];
            
            return of();
        }
    }
    
    /**
     * Calcul automatique du montant HT à partir du montant TTC et des taxes
     */
    computeMontantHT() {
        const montantTTC = (this.data.factureDepense.montant ?? 0);
        const montantTaxes = (this.data.factureDepense.listeTaxe ?? [])
            .map(taux => {
                return taux.montantBase ?? 0;
            })
            .reduce((previousValue,currentValue) => previousValue + currentValue,0);
        
        this.montantHT = montantTTC - montantTaxes;
    }
    
    /**
     * Gestion de la taxe
     *
     * @param type Type de montant saisi à partir duquel effectuer les calculs des autres champs
     */
    gestionTaxe(type: 'TTC'|'HT'|'TAXE') {
        let taux: SaisieTaxe;
        
        //S'il n'y a pas de prestation alors il n'y a rien à calculer
        if (!this.data.factureDepense.prestation) {
            return;
        }
        
        //Vérification de taux unique / multiple
        if (this.data.factureDepense.listeTaxe?.length == 1) {
            //On prend le taux
            taux = this.data.factureDepense.listeTaxe[0];
        } else {
            //On prend le taux par défaut le cas échéant
            taux = this.data.factureDepense.listeTaxe?.find(t => t.defaut);
        }
            
        //Vérification du type
        if (type === 'HT') {
            //Définition de la taxe
            this.data.factureDepense.taxePrimaire = this.montantHT * taux.taux / 100;
    
            //Définition du montant TTC
            this.data.factureDepense.montant = this.montantHT + (this.montantHT * (taux.taux / 100.0));
    
            //Définition des données du taux de taxe
            taux.montant = this.data.factureDepense.taxePrimaire
            taux.montantBase = this.data.factureDepense.taxePrimaire;
            taux.montantTtcBase = this.data.factureDepense.montant;
            taux.montantHtBase = this.montantHT;
            taux.montantTtc = this.data.factureDepense.montant;
            taux.montantHt = this.montantHT;
        } else if (type === 'TTC') {
            //Vérification de l'application de la taxe et du taux
            if (this.data.factureDepense.prestation.idAppTva > 0 && taux) {
                //Définition des données du taux de taxe
                taux.montantBase = 0.0;
        
                //Calcul de la taxe
                this.calculTaxe(taux);
        
                //Définition des données du taux de taxe
                taux.montant = taux.montantBase;
                taux.montantTtc = taux.montantTtcBase;
                taux.montantHt = taux.montantTtcBase;
        
                //Parcours des taxes
                for (const t of this.data.factureDepense.listeTaxe) {
                    //Vérification que le taux courant n'est pas le principal
                    if (t.idTaux !== taux.idTaux) {
                        //Remise à zéro des montants
                        t.reset();
                    }
                }
        
                //Définition des montants de la ligne de détail
                this.data.factureDepense.taxePrimaire = taux.montantBase;
                this.montantHT = this.data.factureDepense.montant/(1 + taux.taux/100);
            } else {
                //Remise à zéro de la saisie de taxe
                this.resetTaxe();
            }
        } else {
            //Vérification du taux
            if (taux) {
                //Vérification du montant TTC : calcul du montant HT
                if (this.data.factureDepense.montant) {
                    //Définition des données du taux de taxe
                    taux.montantBase = this.data.factureDepense.taxePrimaire;
            
                    //Vérification de la taxe saisie
                    this.checkTaux(taux);
            
                    //Mise à jour de la taxe saisie
                    this.data.factureDepense.taxePrimaire = taux.montantBase;
            
                    //Définition du montant HT
                    this.montantHT =  this.data.factureDepense.montant - this.data.factureDepense.taxePrimaire;
            
                    //Définition des données du taux de taxe
                    taux.montant = this.data.factureDepense.taxePrimaire
                    taux.montantTtc = taux.montantTtcBase;
                    taux.montantHt = taux.montantHtBase;
            
                    //Vérification du montant HT : calcul du montant TTC
                } else if (this.montantHT) {
                    //Définition du montant TTC
                    this.data.factureDepense.montant = this.montantHT + this.data.factureDepense.taxePrimaire;
            
                    //Définition des données du taux de taxe
                    taux.montant = this.data.factureDepense.taxePrimaire
                    taux.montantBase = this.data.factureDepense.taxePrimaire;
                    taux.montantTtcBase = (taux.montantBase / taux.getMontantMax(this.data.factureDepense.montant)) * this.data.factureDepense.montant;
                    taux.montantHtBase = taux.montantTtcBase - taux.montantBase;
                    taux.montantTtc = taux.montantTtcBase;
                    taux.montantHt = taux.montantHtBase;
                }
            }
        }
    }
    
    /**
     * Remise à zéro de la saisie de taxe
     */
    resetTaxe() {
        //Réinitialisation des montants de la ligne de détail
        this.data.factureDepense.taxePrimaire = null;
        this.montantHT = this.data.factureDepense.montant;
        
        //Parcours des taxes
        for (const taux of this.data.factureDepense.listeTaxe) {
            //Définition des montants
            taux.reset();
        }
    }
    
    /**
     * Calcul de la taxe
     */
    calculTaxe(taux: SaisieTaxe) {
        let prorata;
        
        //Récupération du montant TTC de la dépense
        const montantDepense = this.data.factureDepense.montant;
        
        //Initialisation du montant de la taxe à 0 le cas échéant
        if (taux.montantBase == null) {
            taux.montantBase = 0;
        }
        
        //Vérification du montant
        if (taux.montantBase !== 0.0) {
            //Ré-initialisation du montant
            taux.reset();
        } else {
            //Initialisation du prorata
            prorata = 1.0;
            
            //Calcul du prorata restant
            for (const t of this.data.factureDepense.listeTaxe) {
                if (t.idTaux != taux.idTaux) {
                    //Retrait du montant
                    prorata -= t.montantBase / t.getMontantMax(montantDepense);
                }
            }
            
            //Vérification du prorata
            if (!(prorata > 0 && montantDepense > 0 || prorata < 0 && montantDepense < 0)) {
                prorata = 0.0;
            }

            //Calcul de la taxe (on arrondi ici sinon ça sera la directive 'number' du champ qui le fera, et peut-être pas de la même manière...)
            taux.montantBase = roundHalfEven(prorata * taux.getMontantMax(montantDepense),2);

            //Calcul du montant TTC de base
            taux.montantTtcBase = prorata * montantDepense;
            
            //Calcul du montant HT de base
            taux.montantHtBase = taux.montantTtcBase - taux.montantBase;
        }
    }
    
    /**
     * Vérification de la saisie de taxe
     *
     * @param taux Taxe à vérifier
     */
    checkTaux(taux: SaisieTaxe) {
        //Vérification de la saisie
        if (!this.checkAllTaux(this.data.factureDepense.listeTaxe)) {
            //Ré-initialisation du montant pour pouvoir lancer le calcul auto
            taux.montantBase = 0;
            
            //Calcul auto du montant max de la taxe
            this.calculTaxe(taux);

            //Arrondi du montant à 2 chiffres
            const montantBaseRounded = roundHalfEven(taux.montantBase,2);

            //Vérification du montant
            if (montantBaseRounded == 0.0) {
                //Affichage du message d'erreur
                this.toastrService.error(this.translateService.instant('facture.errors.taxeDejaVentilee'));
            } else if (montantBaseRounded > 0.0) {
                //Affichage du message d'erreur
                this.toastrService.error(this.translateService.instant('facture.errors.montantTaxeMax',{montant:this.decimalPipe.transform(montantBaseRounded,'1.2-2')}));
            }
        } else {
            //Calcul du montant TTC de base
            taux.montantTtcBase = (taux.montantBase / taux.getMontantMax(this.data.factureDepense.montant)) * this.data.factureDepense.montant;

            //Calcul du montant HT de base
            taux.montantHtBase = taux.montantTtcBase - taux.montantBase;
        }

        //Calcul du montant HT de la dépense
        this.computeMontantHT();
    }
    
    /**
     * Vérifie que l'utilisateur n'a pas saisi plus de montant de taxe que ce qui est possible.
     *
     * @param listeTaux liste des taux saisis
     * @return true si les taux saisis sont valides, sinon false
     */
    checkAllTaux(listeTaux): boolean {
        let prorata = 1.0;
        
        //Parcours des taux
        for (const t of this.data.factureDepense.listeTaxe) {
            //Retrait du montant
            prorata -= t.montantBase / t.getMontantMax(this.data.factureDepense.montant);
        }
        
        //Si le delta est négatif, c'est qu'on a saisi trop de taxe, sinon c'est OK (on se garde une marge de 1 centime pour les pb d'arrondis)
        return prorata >= -0.01;
    }
    
    /**
     * Ajuste la valeur des montants TTC et HT des taxes saisies pour faire en sorte que la somme des montants TTC soit identique au montant de la dépense.
     * #45813
     *
     *  @param listeTauxSaisis liste des taux saisis
     */
    annulerErreursArrondi(listeTauxSaisis: SaisieTaxe[]) {
        let sommeTaxeTTC, deltaTTC, nbTauxSaisis;
        
        //Calcul de la somme des montants TTC des taxes
        sommeTaxeTTC = listeTauxSaisis.reduce((somme, taux) => somme + taux.montantTtcBase, 0);
        
        //Différence entre le montant TTC de la dépense et la somme TTC des taxes
        deltaTTC = this.data.factureDepense.montant - sommeTaxeTTC;
        
        //Si la différence n'est pas nulle
        if (deltaTTC !== 0.0) {
            //Nombre de taux saisis
            nbTauxSaisis = listeTauxSaisis.length;
            
            if (nbTauxSaisis > 0) {
                //On calcule le montant à répartir sur chaque taux saisi
                deltaTTC = deltaTTC / nbTauxSaisis;
                
                //Pour chaque taux saisi, on va ajuster la valeur du montant TTC (et donc du HT associé) pour annuler les erreurs d'arrondis,
                //et faire en sorte que la somme des montants TTC des taxes soit identique au montant TTC de la dépense.
                listeTauxSaisis.forEach(taux => {
                    //Ajustement du montant TTC de la taxe
                    taux.montantTtcBase += deltaTTC;
                    
                    //Re-calcul du montant HT de la taxe après ajustement
                    taux.montantHtBase = taux.montantTtcBase - taux.montantBase;
                })
            }
        }
    }
    
    /**
     * Enregistrement de la ligne de détail de la facture
     */
    saveFactureDepense() {
        //Début de l'enregistrement
        this.isSaving = true;
    
        //Liste des taux saisis (= non nuls)
        const listeTauxSaisis = this.data.factureDepense.listeTaxe?.filter(taux => taux.montantBase > 0);
    
        //Ajustement des montants TTC et HT pour supprimer les erreurs d'arrondi
        if (listeTauxSaisis?.length > 0) {
            this.annulerErreursArrondi(listeTauxSaisis);
        }
    
        //Parcours des taux saisis
        for (const taux of this.data.factureDepense.listeTaxe) {
            //Définition du montant de taxe récupéré
            taux.montant = roundHalfEven(taux.montantBase,2);
        
            //Définition des montants TTC & HT arrondis
            taux.montantTtc = roundHalfEven(taux.montantTtcBase,2);
            taux.montantHt = roundHalfEven(taux.montantHtBase,2);
        
            //Taxe et montants arrondis
            taux.montantBase = roundHalfEven(taux.montantBase,2);
            taux.montantTtcBase = roundHalfEven(taux.montantTtcBase,2);
            taux.montantHtBase = roundHalfEven(taux.montantHtBase,2);
        }
    
        //Enregistrement
        this.factureService.saveLigneDetail(this.data.factureDepense)
            .pipe(first(),finalize(() => { this.isSaving = false; }))
            .subscribe(montant => {
                //Mise à jour du montant de la facture
                this.data.facture.montant = montant;
                
                //Fermeture de la popin courante avec la valeur "true" pour notifier de recharger la liste parente
                this.matDialogRef.close(true);
            },error => {
                //Message d'erreur
                TypeCodeErreur.showError(error,this.translateService,this.toastrService);
            });
    }
    
    /**
     * Suppression de la ligne de détail de la facture
     */
    deleteFactureDepense() {
        //Ouverture de la boîte de dialogue pour confirmer la suppression du taux
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Début de la suppression
                this.isDeleting = true;
    
                //Enregistrement
                this.factureService.deleteDetail(this.data.factureDepense)
                    .pipe(first(),finalize(() => { this.isDeleting = false; }))
                    .subscribe(montant => {
                        //Mise à jour du montant de la facture
                        this.data.facture.montant = montant;
            
                        //Fermeture de la popin courante avec la valeur "true" pour notifier de recharger la liste parente
                        this.matDialogRef.close(true);
                    },error => {
                        //Message d'erreur
                        TypeCodeErreur.showError(error ?? TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
                    });
            }
        });
    }
    
}