import {Component, Inject} from '@angular/core';
import {finalize, first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ProfilService} from "@components/profil/profil.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ProfilUser} from "@domain/profil/profilUser";

/**
 * Composant de la popin RGPD de confirmation de suspension d'accès aux données personnelles
 *
 * @author Laurent Convert
 * @date 22/07/2022
 */
@Component({
    host: {'data-test-id': 'popup-suspension-rgpd'},
    templateUrl: './profil-donnees-popup-suspension-rgpd.component.html',
    styleUrls: ['./profil-donnees-popup-suspension-rgpd.component.scss']
})
export class ProfilDonneesPopupSuspensionRgpdComponent {

    /** Indicateur de chargement */
    isLoading: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public profilUser: ProfilUser,
                public dialogRef: MatDialogRef<ProfilDonneesPopupSuspensionRgpdComponent>,
                private matDialog: MatDialog,
                private profilService: ProfilService,
                private translateService: TranslateService,
                private toastrService: ToastrService) {
    }

    /**
     * Permet de changer le statut d'accès au profil
     */
    changeAccessStatus() {
        //Indicateur
        this.isLoading = true;

        //Changement de l'accès
        this.profilService.changeAccessStatus()
            .pipe(first(),finalize(() => this.isLoading = false))
            .subscribe(res => {
                if (res.codeErreur == TypeCodeErreur.NO_ERROR) {
                    //Message d'information
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Mise à jour
                    this.profilUser.rgpdSuspendu = res?.data?.isRgpdSuspendu;

                    //Fermeture de la popin avec la valeur true pour indiquer au parent de recharger les alertes
                    this.dialogRef.close(true);
                } else {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

}
