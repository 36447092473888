import {AfterViewInit,Component,EventEmitter,Input,OnInit,Output,TemplateRef,ViewChild} from '@angular/core';
import {SettingsNFState,SettingsODState} from "@domain/settings/settings";
import {ParticipantExterne} from "@domain/participant/participant-externe";
import {NomParametreParId,ParticipantExterneParam} from "@domain/participant/participant-externe-param";
import {ParticipantsService} from "../../participants.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {TypeCodeErreur} from "@domain/common/http/result";
import {finalize,first} from "rxjs/operators";
import {CustomInputComponent} from "@share/component/custom-input/custom-input.component";

/**
 * Onglet de création des participants externes dans la popin des participants d'un OD
 *
 * @author Laurent SCIMIA
 * @date 26/10/2021
 */
@Component({
	selector: 'participants-popin-externe',
	templateUrl: './participants-popin-externe.component.html'
})
export class ParticipantsPopinExterneComponent implements OnInit, AfterViewInit {
	/** Indique si on est en train d'enregistrer */
	isSaving: boolean;

	/** Paramètres pour l'od */
	@Input()
	settings: SettingsODState | SettingsNFState;

	/** Contexte OD / Dépense */
	@Input()
	contexte: 'OD' | 'NDF_V';

	@Input()
	idTypeEntite: number;

	@Input()
	idCollab: number;

	@Input()
	participantExterne?: ParticipantExterne;

	/** Évènement de création d'un participant externe */
	@Output()
	onParticipantCree: EventEmitter<ParticipantExterne> = new EventEmitter<ParticipantExterne>();

	/** Énumération des noms de paramètres des participants externes pour utilisation dans le HTML */
	nomParametreParId = NomParametreParId;

	/** Map des paramètres des participants externes avec en clé le type du paramètre et en valeur le paramètre en question */
	mapParamsExternes: Map<NomParametreParId,ParticipantExterneParam> = new Map<NomParametreParId,ParticipantExterneParam>();

	/** Liste des champs à afficher pour la création d'un participant externe */
	listeChamps: Array<TemplateRef<any>> = [];

	/** Référence vers le template du nom */
	@ViewChild('templateNom', {read: TemplateRef})
	templateNom: TemplateRef<any>;

	/** Référence vers le template du prénom */
	@ViewChild('templatePrenom', {read: TemplateRef})
	templatePrenom: TemplateRef<any>;

	/** Référence vers le template de la référence */
	@ViewChild('templateRef', {read: TemplateRef})
	templateRef: TemplateRef<any>;

	/** Référence vers le template du téléphone */
	@ViewChild('templateTel', {read: TemplateRef})
	templateTel: TemplateRef<any>;

	/** Composant de saisie du téléphone */
	@ViewChild('telephone')
	telephoneComponent: CustomInputComponent;

	/** Référence vers le template de la fonction */
	@ViewChild('templateFonction', {read: TemplateRef})
	templateFonction: TemplateRef<any>;

	/** Référence vers le template de la société */
	@ViewChild('templateSociete', {read: TemplateRef})
	templateSociete: TemplateRef<any>;

	/** Référence vers le template du mail */
	@ViewChild('templateMail', {read: TemplateRef})
	templateMail: TemplateRef<any>;

	/** Référence vers le template du complément 1 */
	@ViewChild('templateAdd1', {read: TemplateRef})
	templateAdd1: TemplateRef<any>;

	/** Référence vers le template du complément 2 */
	@ViewChild('templateAdd2', {read: TemplateRef})
	templateAdd2: TemplateRef<any>;

	/** Référence vers le template du complément 3 */
	@ViewChild('templateAdd3', {read: TemplateRef})
	templateAdd3: TemplateRef<any>;

	/** Référence vers le template du complément 4 */
	@ViewChild('templateAdd4', {read: TemplateRef})
	templateAdd4: TemplateRef<any>;

	/**
	 * Constructeur
	 */
	constructor(private participantsService: ParticipantsService,
	            private toastrService: ToastrService,
	            private translateService: TranslateService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//On met les paramètres dans une sorte de Map pour y accéder plus facilement via leur nom
		this.settings.listeParamExternes.forEach(param => {
			this.mapParamsExternes.set(param.nomParametre,param);
		});

		//S'il n'y a pas de participant externe passé en paramètre
		if (this.participantExterne == null) {
			//On en crée un nouveau
			this.participantExterne = new ParticipantExterne();
		}
	}

	/**
	 * Enregistre un Participant externe
	 */
	creerParticipant() {
		this.isSaving = true;

		this.participantsService.saveParticipantExterne(this.participantExterne,this.idTypeEntite,this.idCollab,this.contexte)
			.pipe(first(),finalize(() => this.isSaving = false))
			.subscribe(result => {
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Référence au participant externe créé
					const partExt: ParticipantExterne = new ParticipantExterne(result.data.participantExterne);

					//On récupère le numéro de téléphone obfusqué s'il a été modifié
					if (this.participantExterne.telephoneChanged) {
						//On met à jour le numéro avec le nouveau numéro obfusqué
						this.participantExterne.telephone = partExt.telephone;
						//On reset les variables de gestion du changement
						this.participantExterne.telephoneChanged = false;
						this.telephoneComponent.isModelChanged = false;
					}

					//On renvoie le participant créé
					this.onParticipantCree.emit(partExt);

					//On notifie de la réussite
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
				} else {
					//Affichage du message d'erreur correspondant au code
					TypeCodeErreur.showError(result?.codeErreur ?? TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Ajoute les champs à afficher en fonction du paramétrage à la liste des champs
	 * On fait ça pour repositionner dynamiquement les champs actifs
	 */
	private ajoutChamps() {
		//Parcours de la liste des champs
		this.mapParamsExternes.forEach((param,nomParam) => {
			//Vérification de l'affichage du champ en fonction du contexte
			if ((this.contexte == 'OD' && param.actifOm) || (this.contexte == 'NDF_V' && param.actifNdf)) {
				//Construction du mapping NOM_PARAMETRE => Template
				switch (nomParam) {
					case NomParametreParId.NOM:
						this.listeChamps.push(this.templateNom);
						break;
					case NomParametreParId.PRENOM:
						this.listeChamps.push(this.templatePrenom);
						break;
					case NomParametreParId.REFERENCE:
						this.listeChamps.push(this.templateRef);
						break;
					case NomParametreParId.TELEPHONE:
						this.listeChamps.push(this.templateTel);
						break;
					case NomParametreParId.FONCTION:
						this.listeChamps.push(this.templateFonction);
						break;
					case NomParametreParId.SOCIETE:
						this.listeChamps.push(this.templateSociete);
						break;
					case NomParametreParId.EMAIL:
						this.listeChamps.push(this.templateMail);
						break;
					case NomParametreParId.ADD1:
						this.listeChamps.push(this.templateAdd1);
						break;
					case NomParametreParId.ADD2:
						this.listeChamps.push(this.templateAdd2);
						break;
					case NomParametreParId.ADD3:
						this.listeChamps.push(this.templateAdd3);
						break;
					case NomParametreParId.ADD4:
						this.listeChamps.push(this.templateAdd4);
						break;
				}
			}
		});
	}

	/**
	 * Vérifie si le champ passé en paramètre est obligatoire
	 *
	 * @param field Nom du paramètre
	 */
	isFieldRequired(field: NomParametreParId): boolean {
		return (this.contexte == 'OD' && this.mapParamsExternes.get(field)?.obligatoireOm) || (this.contexte == 'NDF_V' && this.mapParamsExternes.get(field)?.obligatoireNdf);
	}

	/**
	 * Retourne le libelle client associé au paramètre, ou à défaut le libelle traduit à partir du code du libellé par défaut
	 *
	 * @param field Nom du paramètre
	 */
	getLibelle(field: NomParametreParId): string {
		let libelle: string = this.mapParamsExternes.get(field)?.libelleClient;
		let codeTraduction: string;

		//Si aucun libellé personnalisé
		if (!libelle) {
			//Définition du code de traduction suivant le champ
			switch (field) {
				case NomParametreParId.NOM:
					codeTraduction = 'filter.nom';
					break;
				case NomParametreParId.PRENOM:
					codeTraduction = 'filter.prenom';
					break;
				case NomParametreParId.REFERENCE:
					codeTraduction = 'filter.reference';
					break;
				case NomParametreParId.TELEPHONE:
					codeTraduction = 'od.voyage.telephone';
					break;
				case NomParametreParId.FONCTION:
					codeTraduction = 'od.voyage.participants.fonction';
					break;
				case NomParametreParId.SOCIETE:
					codeTraduction = 'od.voyage.societe';
					break;
				case NomParametreParId.EMAIL:
					codeTraduction = 'od.voyage.participants.email';
					break;
				case NomParametreParId.ADD1:
					codeTraduction = 'od.voyage.participants.complement1';
					break;
				case NomParametreParId.ADD2:
					codeTraduction = 'od.voyage.participants.complement2';
					break;
				case NomParametreParId.ADD3:
					codeTraduction = 'od.voyage.participants.complement3';
					break;
				case NomParametreParId.ADD4:
					codeTraduction = 'od.voyage.participants.complement4';
					break;
			}

			//Récupération de la traduction
			libelle = this.translateService.instant(codeTraduction);
		}

		return libelle;
	}

	/** Méthode lancée après l'initialisation de la vue */
	ngAfterViewInit(): void {
		//On ajoute les champs à afficher
		this.ajoutChamps();
	}
}
