import { Component,OnInit } from '@angular/core';
import { LotFactureDTO } from "@domain/comptabilite/lot";
import { LotAddObjectItemComponent } from "./lot-add-object-item.component";

@Component({
    host: {'data-test-id': 'lot-add-facture-item'},
    templateUrl: './lot-add-facture-item.component.html'
})
/**
 * Composant pour ajouter une facture au lot comptable
 */
export class LotAddFactureItemComponent extends LotAddObjectItemComponent<LotFactureDTO> implements OnInit {
    
    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Initialisation du composant parent
        super.ngOnInit();
    }

    /**
     * Permet de récuperer les Ids OD des factures pour affichage
     * @param lien LienFacOD
     */
    mapperOdFacture(lien) {
        return lien.idOd;
    }

}
