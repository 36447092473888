import {ListItem} from "../common/list-view/list-item";
import {Adresse} from "../profil/adresse";

/**
 * Modèle du trajet utilisé dans la liste
 */
export class ListeTrajet implements ListItem {
    id: number;
    type: TrajetType;
    adresseDepart: Adresse;
    adresseArrivee: Adresse;
    allerRetour: boolean;
    idUser: number;
    distance: number;
    libelle: string;
    unite: {
        libelle: string
    }
    adresseVille: Adresse;
    nombreEtape: number;

    getKey(): number {
        return this.id;
    }
}

/**
 * Enumération du type trajet
 */
export enum TrajetType {
    TRAJET_DISTANCE= "TRAJET_DISTANCE",
    TRAJET_TOURNEE = "TRAJET_TOURNEE"
}