import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {filter,first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {CartePaiement,Contrat,TypeCarte,TypeDebit} from "@domain/profil/cartePaiement";
import {ConfigCompte} from '@domain/settings/configCompte';

@Component({
	selector: 'profil-donnees-add-carte-paiement',
	templateUrl: './profil-donnees-add-carte-paiement.component.html'
})
/**
 * Composant d'ajout/modification de carte de paiement pour le profil utilisateur
 */
export class ProfilDonneesAddCartePaiementComponent implements OnInit {
	/** Indicateur de sauvegarde */
	isSaving: boolean = false;

	/** Indicateur de suppression */
	isDeleting: boolean = false;

	/** Indicateur pour refresh la liste à la sortie de la popup */
	willRefreshList: boolean = false;

	/** Enumération pour le html */
	typeCarte = TypeCarte;

	/** Liste des types de carte pour le select */
	listeTypeCarte: Array<{ type: TypeCarte,libelle: string }> = [];

	/** Liste des types de débit pour le select */
	listeTypeDebit: Array<{ type: TypeDebit,libelle: string }> = [];

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param confirmService le service de confirmation
	 * @param profilService le service de gestion du profil
	 * @param toastrService le toaster
	 * @param matDialogRef la ref de cette popin
	 * @param data les données en entrée de la popin
	 */
	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<ProfilDonneesAddCartePaiementComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			listeContrat: Array<Contrat>,
			listeSynchro: Array<any>,
			carte: CartePaiement
			configCompte: ConfigCompte,
			idUser?: number
		}
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		const configCompte = this.data.configCompte;

		//Si carte débit est activée, on la rajoute dans la sélection
		if (configCompte.carteAffaireDebitCollab || configCompte.carteAffaireDebitEntrep) {
			this.listeTypeCarte.push({type: TypeCarte.AFFAIRE,libelle: this.translateService.instant("profilUser.cartePaiement.typeCarte.AFFAIRE")});

			//C'est la seule sélection possible, on la renseigne par défaut
			this.data.carte.typeCarte = TypeCarte.AFFAIRE;

			if (configCompte.carteAffaireDebitCollab) {
				this.listeTypeDebit.push({type: TypeDebit.COLLAB,libelle: this.translateService.instant("profilUser.cartePaiement.typeDebit.COLLAB")});

				//Si c'est la seule sélection possible, on la renseigne par défaut
				if (!configCompte.carteAffaireDebitEntrep) {
					this.data.carte.typeDebit = TypeDebit.COLLAB;
				}
			}

			if (configCompte.carteAffaireDebitEntrep) {
				this.listeTypeDebit.push({type: TypeDebit.SOCIETE,libelle: this.translateService.instant("profilUser.cartePaiement.typeDebit.SOCIETE")});

				//Si c'est la seule sélection possible, on la renseigne par défaut
				if (!configCompte.carteAffaireDebitCollab) {
					this.data.carte.typeDebit = TypeDebit.SOCIETE;
				}
			}
		}
	}

	/**
	 * Suppression d'une carte de paiement
	 */
	deleteCarte() {
		//Confirmation auprès de l'utilisateur sur la suppression d'une carte de paiement
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					this.isDeleting = true;
					this.profilService.deleteCartePaiement(this.data.carte,this.data.idUser).pipe(first()).subscribe({
						next: result => {
							//Message d'information
							this.toastrService.success(this.translateService.instant(result.codeErreur == TypeCodeErreur.NO_ERROR ? 'global.success.suppression' : 'global.errors.suppression'));

							//Fermeture de l'écran
							this.matDialogRef.close(result.codeErreur == TypeCodeErreur.NO_ERROR);
						}
					});
				}
			});
	}

	/**
	 * Sauvegarde d'une carte de paiement
	 */
	save() {
		this.isSaving = true;

		this.profilService.saveCartePaiement(this.data.carte,this.data.idUser).pipe(first()).subscribe({
			next: result => {
				//S'il n'y a pas d'erreur
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Dans le cas d'une création, refresh du compte pour les pj
					if (this.data.carte.idContrat == 0) {
						this.willRefreshList = true;
					} else {
						//Fermeture de l'écran
						this.matDialogRef.close(true);
					}
				} else {
					//Message d'erreur à afficher
					let message: string = 'global.errors.enregistrement';

					//En cas d'erreur doublon, on récupère le message dédié
					if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
						message = 'profilUser.cartePaiement.doublon';
					}

					//On affiche le toas d'erreur
					this.toastrService.error(this.translateService.instant(message));
				}

				this.isSaving = false;
			}
		});
	}
}
