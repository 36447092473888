import {Component,OnInit} from '@angular/core';
import {LotNdfDTO} from "@domain/comptabilite/lot";
import {getDematIconClass,getStatutArchivageIcon,StatutArchivage} from '@domain/comptabilite/statut-archivage';
import {LotAddObjectItemComponent} from "./lot-add-object-item.component";

@Component({
    host: {'data-test-id': 'lot-add-ndf-item'},
    templateUrl: './lot-add-ndf-item.component.html'
})
/**
 * Composant pour ajouter une ndf au lot comptable
 */
export class LotAddNdfItemComponent extends LotAddObjectItemComponent<LotNdfDTO> implements OnInit {

    /** Nom de l'icône du statut d'archivage de la ndf */
    statutArchivageIcon: string;

    /** Nom de la classe css de l'icône du statut d'archivage de la ndf */
    statutArchivageClass: string;

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Initialisation du composant parent
        super.ngOnInit();

        this.statutArchivageIcon = getStatutArchivageIcon(this.data.statutArchivage);
        this.statutArchivageClass = getDematIconClass(this.data.statutArchivage);
    }

    /**
     * Permet de définir quel tooltip afficher sur l'icone de dématérialisation
     */
    getDematTooltip(): string {
        switch (this.data.statutArchivage) {
            case StatutArchivage.EN_ATTENTE:
                return this.translateService.instant("ndf.synchro.enAttente");
            case StatutArchivage.SYNCHRONISE:
                return this.translateService.instant("ndf.synchro.ndfSynchronise");
            case StatutArchivage.ECHEC:
                return this.translateService.instant("ndf.synchro.echec");
            case StatutArchivage.PARTIEL:
                return this.translateService.instant("ndf.synchro.partiel");
            default:
                return "";
        }
    }
}
