import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../autocomplete-line-item.component";
import {Statut} from "@domain/workflow/statut";

/**
 * Affichage d'une ligne de statut
 *
 * @author Angeline HA
 * @date 07/08/2023
 */
@Component({
	templateUrl: './statut-line-item.component.html'
})
export class StatutLineItemComponent extends AutocompleteLineItemComponent<Statut> {
}
