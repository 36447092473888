import {Injectable} from '@angular/core';
import {HttpClient,HttpEventType,HttpRequest} from '@angular/common/http';
import {BehaviorSubject,Observable,of,Subject} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {FileUploader,FileWithProgress} from './file-uploader';

@Injectable()
export class FileUploaderService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Upload de fichiers
     */
    public upload(fileUploader: FileUploader,listeFiles: Array<File>): Array<Observable<number>> {
        //Retour des fichiers
        return listeFiles.map((f: FileWithProgress) => {
            return of(f).pipe(
                flatMap(f => {
                    const formData: FormData = new FormData();
                    let progress: Subject<number> = new BehaviorSubject<number>(0);

                    //Ajout du fichier
                    formData.append('file',f);
                    formData.append('libelle',f.name);

                    //Définition de la progression
                    f.progress = progress.asObservable();

                    //Upload du fichier
                    this.http.request(new HttpRequest('POST',fileUploader.url,formData,{
                        reportProgress: true
                    })).subscribe({
                        next: event => {
                            //Vérification du type d'évènement
                            if (event.type == HttpEventType.UploadProgress) {
                                //Appel du callback de fichier en cours d'upload
                                fileUploader.onItemUploading && fileUploader.onItemUploading(f);

                                //Notification de la progression
                                progress.next(Math.round(100 * event.loaded / event.total));
                            } else if (event.type == HttpEventType.Response) {
                                const idDocument = (event.body as any).data.document?.idDocument
                                
                                f.result = event.body;
                                f.idDocument = idDocument;

                                //Appel du calback de fichier uploadé
                                fileUploader.onItemUploaded && fileUploader.onItemUploaded(event.body,f);

                                //Fin de l'upload
                                progress.next(100);

                                //Fin du traitement
                                progress.complete();
                            }
                        },
                        error: () => progress.complete()
                    });

                    return f.progress;
                })
            )
        });
    }

    /**
     * Supression du fichier sur le serveur
     * @param fileUploader fileuploader
     * @param idDocument id du document
     * @returns observable avec un type qui dépend de l'appel effectué
     */
    public delete(fileUploader: FileUploader,idDocument: number, idObjet: number = 0): Observable<any> {
        return this.http.post(fileUploader.deleteUrl + idDocument + (idObjet != 0 ? '&id_objet=' + idObjet : ''), null);
    }
}
