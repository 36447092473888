import {Champ} from "./champ";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Item de la liste des champs disponibles pour le mappage d'une valeur dans les paramètres TravelHub
 *
 * @author Laurent Convert
 * @date 14/12/2023
 */
export class ParamConnexionChamp implements ListItem {
	/** Champ disponible pour mappage avec un paramètre */
	champ: Champ;

	constructor(champ: Champ) {
		this.champ = champ;
	}

	getKey(): number | string {
		return this.champ.id;
	}
}