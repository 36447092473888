import {Component,OnInit} from '@angular/core';
import {TypeAction} from "@domain/workflow/workflow";
import {ActionWf} from "@domain/admin/workflow/action-wf";
import {TypeProfil} from "@domain/user/user";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TypeRoleOption} from "@domain/role/typeRoleOption.enum";

@Component({
	host: {'data-test-id': 'action-wf'},
	templateUrl: './action-wf.component.html',
	styleUrls: ['./action-wf.component.scss']
})
export class ActionWfComponent extends ListViewItem<ActionWf> implements OnInit{

	/** Indique le type d'action qui peut être effectuée */
	readonly TypeAction = TypeAction;

	/** Indique le type de profil */
	readonly TypeProfil = TypeProfil;

	/** Libellé de l'action */
	libelleAction: string;

	get isOpened(){
		//isOpened est undefined au chargement de la liste
		//on veut que tous les éléments (dépliables) soient dépliés par défaut : undefined correspond à true
		return this.data.isOpened || this.data.isOpened == undefined
	}

	ngOnInit(): void {
		this.idToLibelle();
		this.findRespBudg();
	}

	/** Récupère le libellé en fonction de l'id de l'action */
	idToLibelle(): void {
		switch (this.data.idAction) {
			case TypeAction.CLOTURER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.cloture";
				break;
			case TypeAction.ARCHIVER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.archive";
				break;
			case TypeAction.ANNULER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.annulation";
				break;
			case TypeAction.COMPLETER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.completion";
				break;
			case TypeAction.COMPTABILISER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.comptabilisation";
				break;
			case TypeAction.INVALIDER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.invalidation";
				break;
			case TypeAction.MODIFIER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.modification";
				break;
			case TypeAction.REJETER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.rejet";
				break;
			case TypeAction.RESTITUER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.restitution";
				break;
			case TypeAction.SUPPRIMER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.suppression";
				break;
			case TypeAction.VALIDER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.validation";
				break;
			case TypeAction.EMETTRE:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.emission";
				break;
			case TypeAction.ATTRIBUER:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.attribution";
				break;
			default:
				this.libelleAction = "admin.ongletWF.cadreWF.actions.indefinie";
		}
	}

	/**
	 * Détermine quels rôles sont responsables budgétaires
	 */
	findRespBudg() {
		this.data.listeRoles.forEach(role => {
			role.isRespBudg = role.listeRoleOption.some(roleOption => roleOption.typeRoleOption === TypeRoleOption[TypeRoleOption.RESPONSABLE_BUDGET]);
		})
	}

}