import {Component, Input} from '@angular/core';
import {LieuService} from "../../lieu.service";
import {Adresse, AdresseListItem} from "../../../../domain/profil/adresse";

/**
 * Composant d'affichage d'une adresse du profil
 *
 * @author Laurent Convert
 * @date 02/06/2022
 */
@Component({
    host: {'data-test-id': 'lieu-adresse-list-item'},
    templateUrl: './lieu-adresse-list-item.component.html'
})
export class LieuAdresseListItemComponent {

    /** Options supplémentaires */
    @Input() extraOptions: {
        selectAdresse: (adresse: Adresse) => void,
        getIconeForTypeAdresse: (typeAdresse: string) => string
    };

    /** Adresse à afficher */
    @Input() data: AdresseListItem;

    /**
     * Constructeur
     */
    constructor(private lieuService:LieuService) {
    }

    /**
     * Sélection de l'adresse à partir de la liste.
     *
     * @param adresse
     */
    selectAdresse(adresse: Adresse) {
        this.extraOptions.selectAdresse(adresse);
    }

    /**
     * Vérifie si des informations obligatoires sont manquantes sur une adresse
     *
     * @param adresse Adresse à vérifier
     */
    isAdresseIncomplete(adresse: Adresse): boolean {
        return this.lieuService.isAdresseIncomplete(adresse);
    }

    /**
     * Retourne l'icône à afficher suivant le type d'adresse personnelle / professionelle
     *
     * @param typeAdresse personnelle / professionelle
     */
    getIconeForTypeAdresse(typeAdresse: string): string {
        return this.extraOptions.getIconeForTypeAdresse(typeAdresse);
    }

}
