<form #form="ngForm" *ngIf="externesParams">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="actif"
                        name="actif"
                        customType="checkbox"
                        [ngModel]="externesParams.actif"
                        [(customModel)]="externesParams.actif"
                        libelle="admin.entreprise.utilisateurs.parametres.externesParams.activer">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="autoriserColAst"
                        name="autoriserColAst"
                        customType="checkbox"
                        [ngModel]="externesParams.saisieCollab"
                        [(customModel)]="externesParams.saisieCollab"
                        libelle="admin.entreprise.utilisateurs.parametres.externesParams.autoriserColAst"
                        [disabled]="!externesParams.actif">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="gestionNdf"
                        name="gestionNdf"
                        customType="radio"
                        [(ngModel)]="externesParams.gestionNdf"
                        [customModel]="externesParams.gestionNdf"
                        [radioOptions]="gestionNdfOptions"
                        libelle="admin.entreprise.utilisateurs.parametres.externesParams.gestionNdf"
                        [disabled]="!externesParams.actif">
                </custom-input>
            </div>
            <div class="row header">
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.libelle'" style="text-align: center"></span>
                </label>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.libellePerso'"></span>
                </label>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.odActif'"></span>
                </label>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.odOblig'"></span>
                </label>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.ndfActif'"></span>
                </label>
                <label class="col-md-2">
                    <span [translate]="'admin.entreprise.utilisateurs.parametres.externesParams.ndfOblig'"></span>
                </label>
            </div>
            <div class="row param" *ngFor="let param of externesParams.listeParam; let index = index">
                <div class="col-md-2">
                    <span [translate]="NomParametreParId.traduction(param.nomParametre)"></span>
                </div>
                <div class="col-md-2">
                    <input matInput [name]="'libelleClient-' + index" [(ngModel)]="param.libelleClient" maxlength="2000">
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" [name]="'actifOm-' + index" [(ngModel)]="param.actifOm" [disabled]="isDisabled(param)"></mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" [name]="'obligatoireOm-' + index" [(ngModel)]="param.obligatoireOm" [disabled]="isDisabled(param)"></mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" [name]="'actifNdf-' + index" [(ngModel)]="param.actifNdf" [disabled]="isDisabled(param)"></mat-checkbox>
                </div>
                <div class="col-md-2">
                    <mat-checkbox color="primary" [name]="'obligatoireNdf-' + index" [(ngModel)]="param.obligatoireNdf" [disabled]="isDisabled(param)"></mat-checkbox>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</form>