import {ListItem} from "@domain/common/list-view/list-item";
import {ZoneUtilisateurSaisie} from "@domain/zone-utilisateur/zone-utilisateur-saisie";

/**
 * Classe représentant le référentiel disponible pour les listes des zones utilisateurs
 */
export class ZUReferentiel implements ListItem {
    idZURef: number;
	idZU: number;
	code: string;
    libelle: string
	attribut1?: string;
	attribut2?: string;
	attribut3?: string;
	attribut4?: string;
	actif: boolean;
	defaut: boolean;
	used: boolean;

    selected: boolean;
    idZuSaisie: number;

	/**
	 * Constructeur
	 *
	 * @param idZU l'identifiant de la ZU auquel il est rattaché
	 */
	constructor(idZU?: number) {
		if (idZU) {
			this.idZU = idZU;
		}
		this.actif = true;
		this.defaut = false;
	}

	getKey(): number {
		return this.idZURef;
	}
}