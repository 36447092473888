import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {finalize} from "rxjs/operators";
import {LangueService} from "@services/admin/langue/langue.service";
import {Traduction} from "@domain/admin/bibliotheque/internationalisation/traduction";

/**
 * Popup de saisie des traductions custom
 *
 * @author Guillaume TRAVOT
 * @date 10/05/2023
 */
@Component({
	host: {'data-test-id': 'popup-libelle'},
	templateUrl: './popup-libelle.component.html'
})
export class PopupLibelleComponent implements OnInit {

	/** Traduction */
	traduction: Traduction;

	/** true si il y a un chargement back */
	isLoading: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { traduction: Traduction },
		private translateService: TranslateService,
		private langueService: LangueService,
		private matDialogRef: MatDialogRef<PopupLibelleComponent>,
		private toastr: ToastrService
	) { }

	/** Initialisation */
	ngOnInit(): void {
		//On récupère la traduction
		this.traduction = new Traduction(this.data?.traduction);
	}

	/**
	 * Sauvegarde d'une traduction custom
	 */
	save() {
		//Bascule du flag de chargement
		this.isLoading = true;

		//Sauvegarde
		this.langueService.saveTraduction(this.traduction.codeLangue, this.traduction.cleTraduction, this.traduction.messageCustom).pipe(finalize(() => {
			//On n'oublie pas de retirer le loading à la fin
			this.isLoading = false
		})).subscribe((result) => {
			//Si tout s'est bien passé
			if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Mise à jour du libellé dans la liste
				this.data.traduction.messageCustom = this.traduction.messageCustom;

				//On ferme la popup avec un true comme succès
				this.matDialogRef.close(true);

				//On affiche un message de succès
				this.toastr.success(this.translateService.instant('global.success.enregistrement'));
			} else if (result.codeErreur === TypeCodeErreur.ERROR_PERMISSION) {
				//On met une erreur de permission
				this.toastr.error(this.translateService.instant('global.errors.permission'));
			} else {
				//On met une erreur d'enregistrement
				this.toastr.error(this.translateService.instant('global.errors.enregistrement'));
			}
		});
	}
}
