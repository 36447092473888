import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {SbtConfigComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config.component";
import {SbtConfigListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-list-item/sbt-config-list-item.component";
import {SbtConfigAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/sbt-config-add.component";
import {ReferentielCategorieVoyageurComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/categorie-voyageur/referentiel-categorie-voyageur.component";
import {ReferentielCommunauteComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/communaute/referentiel-communaute.component";
import {ReferentielRlsComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/rls/referentiel-rls.component";
import {SbtConfigReferentielsComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/sbt-config-referentiels.component";
import {SbtConfigParametresComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/sbt-config-parametres.component";
import {SbtConfigGeneralitesComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/generalites/sbt-config-generalites.component";
import {
	ReferentielCommunauteListItemComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/communaute/communaute-list-item/referentiel-communaute-list-item.component";
import {
	ReferentielCategorieVoyageurListItemComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/categorie-voyageur/categorie-voyageur-list-item/referentiel-categorie-voyageur-list-item.component";
import {ReferentielRlsListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/rls/rls-list-item/referentiel-rls-list-item.component";
import {ReferentielCommunauteAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/communaute/communaute-add/referentiel-communaute-add.component";
import {
	ReferentielCategorieVoyageurAddComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/categorie-voyageur/categorie-voyageur-add/referentiel-categorie-voyageur-add.component";
import {ReferentielRlsAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/rls/rls-add/referentiel-rls-add.component";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ParamConnexionComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion.component";
import {ParamConnexionAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/param-connexion-add.component";
import {ParamConnexionListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-list-item/param-connexion-list-item.component";
import {
	ParamConnexionSelectionComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/selection/param-connexion-selection.component";
import {
	ParamConnexionSelectionListItemComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/selection/param-connexion-selection-list-item.component";
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {FieldComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/field/field.component";
import {FieldListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/field/field-list-item/field-list-item.component";
import {FieldAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/field/field-add/field-add.component";
import {ProfilConnexionComponent} from "@components/admin/voyages/travelhub/profil-connexion/profil-connexion.component";
import {ProfilConnexionListItemComponent} from "@components/admin/voyages/travelhub/profil-connexion/profil-connexion-list-item/profil-connexion-list-item.component";
import {ProfilConnexionAddComponent} from "../profil-connexion/profil-connexion-add/profil-connexion-add.component";
import {AiguillageListItemComponent} from "../profil-connexion/profil-connexion-add/aiguillage/aiguillage-list-item/aiguillage-list-item.component";
import {AiguillageAddComponent} from "../profil-connexion/profil-connexion-add/aiguillage/aiguillage-add/aiguillage-add.component";
import {ProfilConnexionGeneralitesComponent} from "../profil-connexion/profil-connexion-add/generalites/profil-connexion-generalites.component";
import {ProfilConnxionUsageComponent} from "../profil-connexion/profil-connexion-add/usage/profil-connxion-usage.component";
import {ProfilConnexionUsageListItemComponent} from "../profil-connexion/profil-connexion-add/usage/profil-connexion-usage-list-item/profil-connexion-usage-list-item.component";

/**
 * Module de gestion du TravelHub
 *
 * @author Laurent Convert
 * @date 02/11/2023
 */
@NgModule({
	imports: [ShareModule,PreviewAlerteModule,CodemirrorModule],
	declarations: [
		SbtConfigComponent,SbtConfigListItemComponent,SbtConfigAddComponent,
		SbtConfigGeneralitesComponent,
		SbtConfigReferentielsComponent,
		ReferentielCommunauteComponent,ReferentielCommunauteListItemComponent,ReferentielCommunauteAddComponent,
		ReferentielCategorieVoyageurComponent,ReferentielCategorieVoyageurListItemComponent,ReferentielCategorieVoyageurAddComponent,
		ReferentielRlsComponent,ReferentielRlsListItemComponent,ReferentielRlsAddComponent,
		SbtConfigParametresComponent,
		ParamConnexionComponent,ParamConnexionListItemComponent,ParamConnexionAddComponent,ParamConnexionSelectionComponent,ParamConnexionSelectionListItemComponent,
		FieldComponent,FieldListItemComponent,FieldAddComponent,
		ProfilConnexionComponent,ProfilConnexionListItemComponent,
		ProfilConnexionAddComponent,ProfilConnexionGeneralitesComponent,AiguillageListItemComponent,AiguillageAddComponent,ProfilConnxionUsageComponent,ProfilConnexionUsageListItemComponent,
	],
	exports: [
		SbtConfigComponent,SbtConfigListItemComponent,SbtConfigAddComponent,
		SbtConfigGeneralitesComponent,
		SbtConfigReferentielsComponent,
		ReferentielCommunauteComponent,ReferentielCommunauteListItemComponent,ReferentielCommunauteAddComponent,
		ReferentielCategorieVoyageurComponent,ReferentielCategorieVoyageurListItemComponent,ReferentielCategorieVoyageurAddComponent,
		ReferentielRlsComponent,ReferentielRlsListItemComponent,ReferentielRlsAddComponent,
		SbtConfigParametresComponent,
		ParamConnexionComponent,ParamConnexionListItemComponent,ParamConnexionAddComponent,ParamConnexionSelectionComponent,ParamConnexionSelectionListItemComponent,
		FieldComponent,FieldListItemComponent,FieldAddComponent,
		ProfilConnexionComponent,ProfilConnexionListItemComponent,
		ProfilConnexionAddComponent,ProfilConnexionGeneralitesComponent,AiguillageListItemComponent,AiguillageAddComponent,ProfilConnxionUsageComponent,ProfilConnexionUsageListItemComponent,
	],
	providers: [
		AdminTravelhubService,
	]
})
export class SbtConfigModule {}