<div class="lvi-content">
    <div class="avatar">
        <i class="icon-profil nio align-middle" [matTooltip]="'od.voyage.participants.interne' | translate"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="">{{ data.nom }} - {{data.prenom}}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.voyage.matricule'"></strong>{{ data.matricule }}</li>
            <li *ngIf="data.orga">
                <strong>{{'od.voyage.service' | translate}}</strong>{{ data.orga.libelle }}
            </li>
            <li *ngIf="!data.infosCollaborateur.avances" class="back-warning">
                <strong>{{'od.creationOdParticipants.avance' | translate}}</strong>
                {{'od.creationOdParticipants.interdite' | translate}}
               <mat-icon [matTooltip]="'od.creationOdParticipants.droitAvance' | translate" matTooltipPosition="right" class="material-icons-outlined">comment</mat-icon>
            </li>
        </ul>
    </div>
</div>
