<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.iata.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToDetailIata()">{{ (data.type === TypeIata.AEROPORT ? (data.codeIata ? data.codeIata : '') : (data.codeGare ? data.codeGare : '')) + ' ' + data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.iata.type'"></strong>
                <span *ngIf="data.type===TypeIata.AEROPORT" [translate]="'admin.bibliotheque.geographie.iata.aeroport'"></span>
                <span *ngIf="data.type===TypeIata.GARE" [translate]="'admin.bibliotheque.geographie.iata.gare'"></span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.iata.ville'"></strong>
                <span>{{ data.ville?.libelle }}</span>
            </li>
        </ul>
    </div>
</div>
