<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-voiture"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ getTitle() }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'ndf.frais.popupDetailIk.detailCalcul.theorique'"></strong>
                {{ (data.quantiteTheorique | distance:data.unite) + ' : ' + (data.montantTheorique | montant:data.devise) }}
            </li>
            <li>
                <strong [translate]="'ndf.frais.popupDetailIk.detailCalcul.reel'"></strong>
                {{ data.quantiteReelle ? (data.quantiteReelle | distance:data.unite) + ' : ' + (data.montantReel | montant:data.devise) : ' - ' }}
            </li>
        </ul>
    </div>
</div>
