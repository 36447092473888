import { NgModule } from "@angular/core";
import { AdminModule } from "@components/admin/admin.module";
import { UserModule } from "@components/user.module";

@NgModule({
	imports: [
		UserModule,
		AdminModule
	],
})
export class ComponentModule {}
