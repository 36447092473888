import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from "@angular/common/http";
import {ZonePredefinie} from "@domain/zone-utilisateur/zone-predefinie";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {FiltreZU,TypeFiltreZU} from "@domain/zone-utilisateur/filtre-zu";
import {ZUReferentiel} from "@domain/zone-utilisateur/zuReferentiel";
import {ZoneUtilisateur} from "@domain/zone-utilisateur/zone-utilisateur";

/**
 * Service de gestion des zones utilisateurs
 */
@Injectable({
	providedIn: 'root'
})
export class ZonesUtilisateursService {
	/**
	 * Constructeur
	 *
	 * @param http le client HTTP
	 */
	constructor(
		private http: HttpClient
	) {
	}

	/**
	 * Enregistrement d'une zone prédéfinie
	 *
	 * @param zone la zone à enregistrer
	 */
	saveZonePredefinie(zone: ZonePredefinie): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/saveZonePredefinieBody`,zone);
	}

	/**
	 * Chargement d'une zone utilisateur
	 *
	 * @param idZU l'identifiant de la ZU
	 */
	loadZU(idZU: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/getZoneUtilisateur/${idZU}`);
	}

	/**
	 * Enregistrement d'une zone utilisateur
	 *
	 * @param zu la ZU
	 */
	saveZU(zu: ZoneUtilisateur): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/saveZoneUtilisateurBody`,zu)
	}

	/**
	 * Suppression d'une zone utilisateur
	 *
	 * @param idZU l'identifiant de la ZU
	 */
	deleteZU(idZU: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/deleteZoneUtilisateur?id_zu=${idZU}`,null);
	}

	/**
	 * Ajout d'une ligne au référentiel
	 *
	 * @param ref l'item à ajouter
	 */
	addZURef(ref: ZUReferentiel): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/saveData`,ref);
	}

	/**
	 * Suppression d'une ligne du référentiel
	 *
	 * @param idZURef l'item à supprimer
	 */
	deleteZURef(idZURef: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/deleteData?id_zu_ref=${idZURef}`,null);
	}

	/**
	 * Ajout d'un filtre
	 *
	 * @param idZU l'identifiant de la ZU
	 * @param idObjet l'identifiant de l'objet du filtre
	 * @param typeFiltre le type de filtre
	 */
	addFiltre(idZU: number,idObjet: number,typeFiltre: TypeFiltreZU): Observable<Result> {
		//Paramètres HTTP
		let params = new HttpParams()
			.append('id_zu',idZU.toString())
			.append('id_filtre',idObjet.toString())
			.append('type_filtre',typeFiltre);

		//Appel HTTP
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/addFiltre`,null,{params});
	}

	/**
	 * Supression d'un filtre
	 *
	 * @param filtre le filtre à supprimer
	 */
	deleteFiltre(filtre: FiltreZU): Observable<Result> {
		//Paramètres HTTP
		let params = new HttpParams()
			.append('id_zu',filtre.idZU.toString())
			.append('id_filtre',filtre.idObjet.toString())
			.append('type_filtre',filtre.typeFiltre.toString());

		//Appel HTTP
		return this.http.post<Result>(`${environment.baseUrl}/controller/ZoneUtilisateur/deleteFiltre`,null,{params});
	}
}
