<fieldset>
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.comptabilite.planComptable.compteComptable.title.compte'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="compte.libelle"
                                  customType="input"
                                  maxlength="50"
                                  libelle="admin.comptabilite.planComptable.compteComptable.libelle"
                                  [required]="true"></custom-input>
                    <custom-input ngDefaultControl
                                  id="isActif"
                                  name="isActif"
                                  [ngModel]="compte.actif"
                                  [(customModel)]="compte.actif"
                                  customType="checkbox"
                                  libelle="admin.comptabilite.planComptable.compteComptable.actif"></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="numero"
                                  name="numero"
                                  [(ngModel)]="compte.numero"
                                  maxlength="20"
                                  customType="input"
                                  libelle="admin.comptabilite.planComptable.compteComptable.numero"
                                  [required]="true"
                                  [disabled]="!compte.new"></custom-input>
                    <custom-input ngDefaultControl
                                  id="type"
                                  name="type"
                                  [(ngModel)]="compte.type"
                                  customType="select"
                                  [selectOptions]="listeTypes"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  libelle="admin.comptabilite.planComptable.compteComptable.type"></custom-input>
                </div>
                <!-- Champs 'attribut' gérés avec le composant dédié aux zones utilisateur prédéfinies -->
                <zone-predefinie [listeFieldParam]="listeFieldParam" [fieldContainer]="fieldContainer"
                                 [isDisableInactive]="true"></zone-predefinie>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
