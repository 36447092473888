import {Component,Input} from '@angular/core';
import {Zone} from "@domain/geographie/zone";
import {ControlContainer,NgForm} from "@angular/forms";

/**
 * Composant d'affichage du cadre Généralités d'une Zone
 *
 * @author Angeline Ha
 * @date 07/11/2023
 */
@Component({
  host: {'data-test-id': 'zone-generalites'},
  selector: 'zone-generalites',
  templateUrl: './zone-generalites.component.html',
  viewProviders: [{provide: ControlContainer,useExisting: NgForm}]
})
export class ZoneGeneralitesComponent {
  @Input() zone: Zone;
}
