import {Component} from "@angular/core";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {MontantEditComponent} from "../montant-edit/montant-edit.component";
import {MatDialog} from "@angular/material/dialog";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {filter} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";
import {MontantReaffectationComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-montants/reaffectation/montant-reaffectation.component";

/**
 * Composant d'affichage d'un montant d'une enveloppe budgétaire
 */
@Component({
    host: {'data-test-id': 'montant-item'},
    selector: 'montant-item',
    templateUrl: './montant-item.component.html'
})
export class MontantItemComponent extends ListViewItem<MontantDto> {

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private matDialog: MatDialog,
        private enveloppeDetailService: EnveloppeDetailService,
        private confirmService: ConfirmService
    ) {
        super();
    }

    /**
     * Handler de selection d'un item
     */
    onSelect(): void {
        this.openEditMontantDialog(this.data);
    }

    /**
     * Ouvre la boite de dialog d'édition d'un montant
     * @param montant le montant
     */
    openEditMontantDialog(montant: MontantDto): void {
        this.matDialog.open<MontantEditComponent>(MontantEditComponent,{
            data: {
                montant
            }
        }).afterClosed().subscribe(data => {
            if (data != null) {
                this.saveMontant(data);
            }
        });
    }

    /**
     * Sauvegarde d'un montant
     * @param montant le montant
     */
    saveMontant(montant: MontantDto): void {
        this.enveloppeDetailService.saveMontant(montant)
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        //On refresh
                        this.liste.refresh();
                    } else {
                        //Message d'erreur
                        TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Supprime un montant
     */
    delete() {
        //On confirme
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //On supprime
                    this.enveloppeDetailService.deleteMontant(this.data.idMontant)
                        .subscribe({
                            next: (result: Result) => {
                                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                                    //Message de success
                                    this.toastrService.success(this.translateService.instant('global.success.suppression'));
                                    //On refresh
                                    this.liste.refresh();
                                } else {
                                    //Message d'erreur
                                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                                }
                            },
                            error: () => {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        });
                }
            });
    }

    /**
     * Ouvre la popin réaffectation
     */
    openReaffectation(): void {
        this.matDialog.open(MontantReaffectationComponent,{
            data: {
                montant: this.data
            }
        }).afterClosed().subscribe(isRefresh => {
            if (isRefresh) {
                this.liste.refresh()
            }
        });
    }
}
