import {Component,OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

import {AppState} from '@domain/appstate';
import {Session} from '@domain/security/session';
import {LayoutService} from '@share/layout/layout.service';
import * as moment from "moment-timezone";
import {LoginService} from "@share/login/login.service";

/**
 * Composant principal
 */
@Component({
    selector: 'body',
    template: '<layout></layout>',
    host: {
        '[class.full-width]': '!session?.isLogged || session?.isAdmin || session?.isPasswordExpired'
    }
})
export class AppComponent implements OnInit {
    /** Données **/
    session: Session = null;

    /**
     * Constructeur par défaut
     */
    constructor(public router: Router,
                private store: Store<AppState>,
                private layoutService: LayoutService,
                private loginService: LoginService) {
        //Définition de la timezone MomentJS
        moment.tz.setDefault('Europe/Paris');
    }

    /**
     * Initialisation
     */
    async ngOnInit() {
        //Attente du chargement du mode d'authentification (normalement il a déjà été chargé lors du login)
        await this.loginService.loadAuth().toPromise();

        //Sélection de la session
        this.store.select<Session>(s => s.session).subscribe(session => {
            //Mémorisation de la session
            this.session = session;

            //Initialisation du layout
            this.layoutService.init(session);
        });
    }
}
