import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { ShareModule } from '../../share/share.module';
import { CguComponent } from './cgu.component';
import { CguService } from './cgu.service';
import { CguEffects } from './cgu.effects';

@NgModule({
    imports: [ShareModule,EffectsModule.forFeature([CguEffects])],
    declarations: [CguComponent],
    providers: [CguService]
})
export class CguModule {}