import {Component} from '@angular/core';
import {ControlContainer,NgForm} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Rls} from "@domain/travel/rls";
import {ReferentielRlsListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/rls/rls-list-item/referentiel-rls-list-item.component";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {Result} from "@domain/common/http/result";
import {Observable} from "rxjs";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {ReferentielRlsAddComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/rls/rls-add/referentiel-rls-add.component";
import {AbstractReferentielComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/abstract-referentiel/abstract-referentiel.component";

/**
 * Cadre du référentiel "RLS" de l'onglet "Référentiels" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-rls'},
    selector: 'referentiel-rls',
    templateUrl: './referentiel-rls.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReferentielRlsComponent extends AbstractReferentielComponent<Rls,ReferentielRlsListItemComponent> {
    /**
     * Constructeur
     *
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param matDialog Boite de dialogue
     */
    constructor(adminTravelhubService: AdminTravelhubService,
                translateService: TranslateService,
                toastrService: ToastrService,
                matDialog: MatDialog) {
        super(
            adminTravelhubService,
            translateService,
            toastrService,
            matDialog,
            ReferentielRlsListItemComponent,
            ReferentielRlsAddComponent
        );
    }

	/**
	 * Retourne l'uri propre au type de référentiel
	 */
    protected getUri(): string {
        return `/controller/TravelHubConfig/${this.sbtConfigUsed.idSBTConfigUsed}/listeRLS`;
    }

	/**
	 * Retourne la racine pour les traductions
	 */
    protected getRootTrad(): string {
        return 'admin.voyages.travelhub.sbtConfig.referentiel.rls';
    }

	/**
	 * Post-traitement de la liste paginée retournée par le serveur
	 *
	 * @param result Résultat à traiter
	 */
    protected mapResult(result: Result): void {
        //Récupération de la liste des SBT utilisés
        this.listeSBTConfigUsed = result.data.listeSBTConfigUsed;

        //Transformation des résultats en instance
        return result.data.listeRls.map(item => this.newInstanceReferentiel(item));
    }

	/**
	 * Retourne une instance du type de référentiel
	 *
	 * @param dto Source
	 */
    protected newInstanceReferentiel(dto?: Rls | any): Rls {
        return new Rls(dto);
    }

	/**
	 * Met à jour le niveau d'erreur du référentiel
	 */
    public updateAlertLevelList(): void {
        this.listeReferentiel.alertLevel = this.sbtConfigUsed.errorRLS ? NiveauAlerte.ERROR : null;
    }

	/**
	 * Enregistrement d'une entrée du référentiel
	 *
	 * @param referentiel Référentiel à enregistrer
	 */
    protected save(referentiel: Rls): Observable<Result> {
        return this.adminTravelhubService.saveRls(referentiel);
    }

	/**
	 * Suppression d'une entrée du référentiel
	 *
	 * @param referentiel Référentiel à supprimer
	 */
    protected delete(referentiel: Rls): Observable<Result> {
        return this.adminTravelhubService.deleteRls(referentiel);
    }
}