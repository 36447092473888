<div class="lvi-content">
    <div class="avatar">
        <span>{{ data.typeEntite.libelle | slice: 0:3 }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="data.dateApplication ? 'profilUser.prefAnalytique.item.title' : 'profilUser.prefAnalytique.item.titleWoDate'"
                [translateParams]="data.dateApplication ? { typeEntite: data.typeEntite?.libelle, date: data.dateApplication | date} : { typeEntite: data.typeEntite?.libelle}"
                (click)="showPreferenceAnalytique()"></a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'analytique.service'"></strong>
                <span *ngIf="data.orga">{{ data.orga.libelle + ' (' + data.orga.numService + ')' }}</span>
                <span *ngIf="!data.orga" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li *ngIf="data.typeEntite?.codeProjet">
                <strong [translate]="'analytique.dossier'"></strong>
                <span *ngIf="data.dossier">{{ data.dossier.libelle + (data.dossier.reference ? ' (' + data.dossier.reference + ')' : '') }}</span>
                <span *ngIf="!data.dossier" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li *ngIf="data.typeEntite?.axe5">
                <strong>{{ extraOptions.settings?.libelleAxe5 || ('analytique.axe5' | translate) }}</strong>
                <span *ngIf="data.axe5">{{ data.axe5.libelle + (data.axe5.reference ? ' (' + data.axe5.reference + ')' : '') }}</span>
                <span *ngIf="!data.axe5" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li *ngIf="data.typeEntite?.axe6">
                <strong>{{ extraOptions.settings?.libelleAxe6 || ('analytique.axe6' | translate) }}</strong>
                <span *ngIf="data.axe6">{{ data.axe6.libelle + (data.axe6.reference ? ' (' + data.axe6.reference + ')' : '') }}</span>
                <span *ngIf="!data.axe6" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li *ngIf="data.typeEntite?.field5 && getFieldParam('OPERATION_1') as operation">
                <strong>{{ operation.libelle }}</strong><span *ngIf="data.field5">{{ data.field5 }}</span>
                <span *ngIf="!data.field5" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li *ngIf="data.typeEntite?.field6 && getFieldParam('OPERATION_2') as operation">
                <strong>{{ operation.libelle }}</strong><span *ngIf="data.field6">{{ data.field6 }}</span>
                <span *ngIf="!data.field6" [translate]="'liste.nonRenseigne'"></span>
            </li>
            <li>
                <strong [translate]="'profilUser.prefAnalytique.item.defaut'"></strong>
                <span [translate]="data.defaut ? 'global.oui' : 'global.non'"></span>
            </li>
        </ul>
    </div>
</div>
