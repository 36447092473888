import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {ActivatedRoute, Router} from "@angular/router";

/**
 * Composant d'affichage de la liste des distances
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'distance-list'},
    selector: 'distance-list',
    templateUrl: './distance-list.component.html'
})
export class DistanceListComponent implements OnInit {

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param geographieService Service géographie
     * @param router            Router de l'application
     * @param route             Route active
     */
    constructor(public geographieService: GeographieService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Définition de la liste des actions du bouton en bas à droite de l'écran
        this.listeActions.next([{
                type: TypeAction.PRIMARY,
                icone: 'nio icon-ajouter',
                libelle: 'global.actions.creer',
                doAction: () => this.createNewDistance()
            }]
        );
    }

    /**
     * Création d'une nouvelle distance
     */
    createNewDistance() {
        this.router.navigate(["Distance",0], {relativeTo: this.route});
    }

}
