<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2 }">
        <i class="nio icon-ordre_de_mission" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.showOD(data); $event.stopPropagation();">
                {{ data.idOd || ('od.liste.numeroNonDefini' | translate) }} - {{ data.objet || ('od.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="!isCollabMode">
                <strong [translate]="'od.liste.collaborateur'"></strong>
                {{ data.user | user }}
            </li>
            <li><strong [translate]="'omp.od.liste.typeEntite'"></strong>{{ data.typeEntite.libelle }}</li>
            <li *ngIf="data.depart_le"><strong [translate]="'od.liste.depart_le'"></strong>{{ data.depart_le | date:'shortDate' }}</li>
            <li *ngIf="data.retour_le"><strong [translate]="'od.liste.retour_le'"></strong>{{ data.retour_le | date:'shortDate' }}</li>
            <li *ngIf="location" [matTooltip]="location + ', ' + data.pays.libelle.toUpperCase()">
                <strong [translate]="'od.liste.libelleVille'"></strong>
                <span *ngIf="data.pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"></span>
                {{ location }}
            </li>
            <li *ngIf="data.dateLimiteApprobation"><strong [translate]="'od.liste.limiteApprobation'"></strong>{{ data.dateLimiteApprobation | date:'shortDate' }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes(data.idOd)" class="clickable"><strong [translate]="'workflow.alerte.title'">
                </strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div *ngIf="extraOptions?.settings?.deviseEntreprise" class="text-right text-secondary font-weight-bold">
                {{ data.montantProposition | montant:extraOptions?.settings?.deviseEntreprise }}
            </div>
        </div>
    </div>
</div>
