import {Component,OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Alerte} from "@domain/common/alerte/alerte";
import {UserDto} from "@domain/user/user-dto";
import {ProfilsRestreintsUserItemComponent} from "@components/admin/entreprise/description/rgpd/profils/profils-restreints-user-item.component";
import {ListView,TypeComparaison} from "@domain/common/list-view";

/**
 * Composant pour afficher les profils restreints
 */
@Component({
	host: {'data-test-id': 'profils-restreints'},
	selector: 'profils-restreints',
	templateUrl: './profils-restreints.component.html'
})
export class ProfilsRestreintsComponent implements OnInit {
	/** Paramètre permettant d'afficher l'alerte pour les profils restreints */
	profilRestreintInfo: Alerte;

	/** Liste des profils restreints, pour utiliser le composant ProfilsRestreintsUserItemComponent */
	listeProfilsRestreints: ListView<UserDto,ProfilsRestreintsUserItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param translateService Service pour l'utilisation de fr.json
	 */
	constructor(
		private translateService: TranslateService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Permet d'initialiser l'alerte présente sur la page
		this.profilRestreintInfo = {
			niveau: -1,
			titre: this.translateService.instant('admin.entreprise.description.profilsRestreints.informationPresentes'),
			message: ""
		};

		//Création de la liste des référentiels
		this.listeProfilsRestreints = new ListView<UserDto,ProfilsRestreintsUserItemComponent>({
			uri: `/controller/User/listePageUtilisateursRgpdBloques`,
			title: this.translateService.instant('admin.entreprise.description.profilsRestreints.titre'),
			component: ProfilsRestreintsUserItemComponent,
			defaultOrder: 'nom',
			isFilter: true,
			listeFilters: [
				{
					title: this.translateService.instant('filter.nom'),
					clef: 'nom',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('filter.prenom'),
					clef: 'prenom',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('filter.matricule'),
					clef: 'matricule',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('admin.entreprise.description.profilsRestreints.identifiant'),
					clef: 'idUser',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					title: this.translateService.instant('admin.entreprise.description.profilsRestreints.serviceAffectation'),
					clef: 'orga.numService,orga.libelle',
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				}
			]
		});
	}
}