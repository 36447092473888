import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filter,first} from "rxjs/operators";
import {DelegationsService} from "../../delegations.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {Role} from "@domain/workflow/role";
import {Population} from "@domain/workflow/population";
import * as moment from "moment";
import {DateUtilsService} from "@share/utils/date-utils/date-utils.service";

/**
 * Composant du formulaire de création/modification d'une délégation
 */
@Component({
	host: {'data-test-id': 'delegation-creation'},
	templateUrl: './delegation-creation.component.html'
})
export class DelegationCreationComponent implements OnInit {
	/** La liste des rôles délégables de l'utilisateur courant */
	listeRolesDelegables: Role[] = [];

	/** La liste des populations */
	listePopulations: Population[] = [new Population(-1,this.translateService.instant("delegations.form.toutesMesPopulations"))];

	/** Date du jour */
	aujourdhui: Date = moment().startOf('day').toDate();

	/** Mode modification */
	isModif: boolean;

	/** Date de fin initiale (sert pour la modification des délégations utilisées) */
	dateFinInitiale: Date;

	/** Enregistrement en cours */
	isSaving: boolean = false;

	/** Suppression en cours */
	isDeleting: boolean = false;

	/** Import de momentJS pour le DOM */
	readonly moment = moment;

	/**
	 * Constructeur
	 *
	 * @param store permet de récupérer l'état de l'appli
	 * @param translateService le service de traduction
	 * @param toastrService le service de notification
	 * @param confirmService service de confirmation via popup
	 * @param delegService le service des délégations
	 * @param matDialogRef la référence à la popup
	 * @param data la délégation à modifier (si modification)
	 */
	constructor(
		private store: Store<AppState>,
		private translateService: TranslateService,
		private toastrService: ToastrService,
		private confirmService: ConfirmService,
		private delegService: DelegationsService,
		private matDialogRef: MatDialogRef<DelegationCreationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { deleg: LienRoleUser,isFromAdmin?: boolean }) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Définition du mode
		this.isModif = !!this.data.deleg.idLienRoleUser;

		//Si l'idUserDelegant n'est pas défini
		if (!this.data.deleg.idUserDelegant) {
			//Sélection de l'utilisateur connecté
			this.store.select(state => state.session?.user).subscribe(user => {
				//Récupération de l'ID de l'utilisateur courant
				this.data.deleg.idUserDelegant = user.idUser;

				//Chargement des rôles
				this.loadRoles();
			});
		} else {
			//Chargement des rôles
			this.loadRoles();
		}

		//S'il n'y a pas de population définie
		if (!this.data.deleg.idPopulation) {
			//Initialisation de la population par défaut
			this.data.deleg.idPopulation = -1;
			this.data.deleg.population = this.listePopulations.find(pop => pop.idPopulation === this.data.deleg.idPopulation);
		}

		//Si la délégation est utilisée, on deep copy la date de fin afin de fix un bug sur la dateMax de la délégation
		//En effet avec une date de fin au 31 par exemple, si on la passait au 15 alors qu'on voulait la passer au 16,
		//on se retrouvait bloqué comme dateMax de dateFin était dateFin.
		if (this.data.deleg.used) {
			this.dateFinInitiale = new Date(this.data.deleg.dateFin);
		}
	}

	/**
	 * (Re)chargement des rôles
	 */
	loadRoles(): void {
		//Chargement des rôles délégables de l'utilisateur délégant
		this.delegService.loadRolesDelegablesByIdUser(this.data.deleg.idUserDelegant)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Récupération des rôles
					this.listeRolesDelegables = result.data.roles;

					//Si l'user n'a plus l'habilitation qu'il avait autrefois déléguée, et que cette délégation est utilisée (donc plus modifiable)
					if (!this.listeRolesDelegables.some(r => r.idRole === this.data.deleg.idRole) && this.data.deleg.used) {
						//On rajoute le rôle de la délégation actuelle à listeRolesDelegables pour fix le bug d'affichage
						this.listeRolesDelegables.push(this.data.deleg.role);
					}

					//Si un rôle est défini
					if (this.data.deleg.role) {
						//Chargement des populations associées
						this.loadPopulations();
					}
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			});
	}

	/**
	 * Déclenché à chaque changement de rôle
	 */
	onRoleChange(): void {
		//Récupération du rôle correspondant à l'id sélectionné
		this.data.deleg.role = this.listeRolesDelegables.find(role => role.idRole === this.data.deleg.idRole);

		//Réinitialisation de la population
		this.data.deleg.idPopulation = -1;
		this.onPopulationChange();

		//Rechargement des populations associées
		this.loadPopulations();
	}

	/**
	 * (Re)chargement des populations
	 */
	loadPopulations(): void {
		//On ne garde que la population d'indice 1
		this.listePopulations = this.listePopulations.slice(0,1);

		//Chargement des populations
		this.delegService.loadPopsByDeleg(this.data.deleg)
			.pipe(first())
			.subscribe((result: Result) => {
				//Vérification du result
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Récupération des populations
					let populations: Population[] = result.data.populations;

					//S'il y a une population d'ID 0
					if (populations.some(pop => pop.idPopulation === 0)) {
						//On change son libellé
						populations.find(pop => pop.idPopulation === 0).libelle = this.translateService.instant("delegations.form.tousLesCollabs");
					}

					//On ajoute la liste des populations récupérées
					this.listePopulations = this.listePopulations.concat(populations);
				}
			});
	}

	/**
	 * Déclenché à chaque changement de population
	 */
	onPopulationChange(): void {
		//Récupération de la population correspondante à l'id sélectionné
		this.data.deleg.population = this.listePopulations.find(pop => pop.idPopulation === this.data.deleg.idPopulation);

		//Réinitialisation de l'utilisateur
		delete this.data.deleg.user;
	}

	/**
	 * Méthode de soumission du formulaire
	 */
	onSave() {
		//Début de l'enregistrement
		this.isSaving = true;

		//Création de la délégation
		this.delegService.saveDelegation(this.data.deleg).pipe(first()).subscribe({
			next: codeErreur => {
				if (codeErreur == 1) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				} else if (codeErreur == 4) {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('delegations.errors.aucunePopPourCriteres'));
				} else {
					//Message d'information
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popup
					this.matDialogRef.close(this.data);
				}
			},
			complete: () => {
				//Fin de l'enregistrement
				this.isSaving = false;
			}
		});
	}

	/**
	 * Suppression d'une délégation
	 */
	onDelete() {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Enregistrement en cours
				this.isDeleting = true;

				//Suppression de la délégation
				this.delegService.deleteDelegation(this.data.deleg).pipe(first()).subscribe({
					next: result => {
						//Vérification de l'enregistrement
						if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Message d'information
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Fermeture de la popup
							this.matDialogRef.close(result.data);
						} else {
							//Message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.suppression'));
						}
					},
					complete: () => {
						//Fin de l'enregistrement
						this.isDeleting = false;
					}
				});
			}
		});
	}
}
