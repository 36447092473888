import {FillingRowComponent} from "./filling-row.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [FillingRowComponent],
    exports: [FillingRowComponent]
})
export class FillingRowModule {}
