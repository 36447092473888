<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-voiture"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="data.title"></a>
        </div>
        <ul class="lvi-attrs">
            <li [matTooltip]="'ndf.frais.popupDetailIk.compteurDepense.tooltipTheorique' | translate">
                <strong [translate]="'ndf.frais.popupDetailIk.compteurDepense.theorique'"></strong>
                {{ (data.theorique | distance:extraOptions.unite) }}
            </li>
            <li [matTooltip]="'ndf.frais.popupDetailIk.compteurDepense.tooltipReel' | translate">
                <strong [translate]="'ndf.frais.popupDetailIk.compteurDepense.reel'"></strong>
                {{ extraOptions.dateExecutionReel ? (data.reel | distance:extraOptions.unite) : " - " }}
            </li>
        </ul>
    </div>
</div>
