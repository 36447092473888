import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Report} from "@domain/reporting/report";
import {ReportParam} from "@domain/reporting/reportParam";

/**
 * Service de gestion des Rapports
 */
@Injectable()
export class ReportingService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {}

    /**
     * Récupération d'un rapport par son ID
     *
     * @param id    ID du rapport à récupérer
     */
    getReportById(id: string): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/consultReport/${id}`,null).pipe(first());
    }

    /**
     * Récupération de la liste des groupes
     */
    getListeGroupes(): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/getListeGroupes`,null).pipe(first());
    }

    /**
     * Sauvegarde d'un rapport
     *
     * @param report    rapport à enregistrer
     * @param file      fichier associé
     */
    saveReport(report: Report, file: File): Observable<Result> {
        //Initialisation
        const formData: FormData = new FormData();

        //Ajout des données
        if (file) { formData.append('file', file); }
        formData.append('report', new Blob([JSON.stringify(report)], {type: "application/json"}));

        //Upload du fichier et sauvegarde des données
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/saveReport`, formData).pipe(first());
    }

    /**
     * Suppression d'un rapport
     *
     * @param reportId  ID du rapport à supprimer
     */
    deleteReport(reportId: number): Observable<Result> {
        //Initialisation
        const formData: FormData = new FormData();

        //Ajout des données
        formData.append('id_report', reportId.toString());

        //Upload du fichier et sauvegarde des données
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/deleteReport`, formData).pipe(first());
    }

    /**
     * Sauvegarde d'un rôle
     *
     * @param idReport  ID du rapport concerné
     * @param idRole    ID du rôle à ajouter
     */
    saveRole(idReport: number,idRole: number): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/saveRole/${idReport}`, idRole).pipe(first());
    }

    /**
     * Suppression d'un rôle
     *
     * @param idReport  ID du rapport concerné
     * @param idRole    ID du rôle à supprimer
     */
    deleteRole(idReport: number,idRole: number): Observable<Result> {
        //Initialisation
        const formData: FormData = new FormData();

        //Ajout des données
        formData.append('idReport', idReport.toString());
        formData.append('idRole', idRole.toString());

        //Upload du fichier et sauvegarde des données
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/deleteRole`, formData).pipe(first());
    }

    /**
     * Sauvegarde d'un paramètre
     *
     * @param idReport      ID du rapport concerné
     * @param param         paramètre à enregistrer
     * @param originalName  nom original du paramètre
     */
    saveParam(idReport: number,param: ReportParam,originalName?: string): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/saveParam/${idReport}${originalName ? '/' + encodeURI(originalName) : ''}`, param).pipe(first());
    }

    /**
     * Suppression d'un paramètre
     *
     * @param idReport  ID du rapport concerné
     * @param name      nom du paramètre à supprimer
     */
    deleteParam(idReport: number,name: string): Observable<Result> {
        //Initialisation
        const formData: FormData = new FormData();

        //Ajout des données
        formData.append('idReport', idReport.toString());
        formData.append('name', name);

        //Upload du fichier et sauvegarde des données
        return this.http.post<Result>(`${environment.baseUrl}/controller/Report/deleteParam`, formData).pipe(first());
    }
}
