import { Component,Input } from '@angular/core';
import { Prestation } from "../../../../../domain/prestation/prestation";
import { Od } from "../../../../../domain/od/od";
import { SettingsODState } from "../../../../../domain/settings/settings";
import { environment } from "../../../../../environments/environment";
import { Ij } from "../../../../../domain/od/engagements/indemnites/ij";

/**
 * Composant d'affichage d'une ligne dans la liste des IJ
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-engagements-indemnites-item',
	templateUrl: './od-engagements-indemnites-item.component.html'
})
export class OdEngagementsIndemnitesItemComponent {
	/** Ordre de mission */
	@Input() od: Od;

	/** Prestation de l'indemnité */
	@Input() prestation: Prestation;

	/** Paramétrage de l'OD */
	@Input() settings: SettingsODState;

	/** URL de base */
	public baseUrl: string = environment.baseUrl;

	/** Indique si le détail de la ligne est affiché */
	isOuvert: boolean = false;

	/**
	 * Ouvre / Ferme le détail de la ligne
	 */
	toggleDetail() {
		this.isOuvert = !this.isOuvert;
	}

	/**
	 * Retourne True si la quantité est différente de la quantité d'origine
	 *
	 * @param item l'indemnité ou la prestation à vérifier
	 */
	isQteOrigineCumulNotEqualsQteCumul(item: Prestation | Ij): boolean {
		return item?.quantiteOrigineCumulIndemnites != item?.quantiteCumulIndemnites;
	}

	/**
	 * Retourne la devise du pays/de la ville d'une indemnité
	 *
	 * @param ij l'indemnité
	 */
	getDeviseIndemnite(ij: Ij): string {
		return ij.pays != null ? ij.pays.devise : ij.ville?.pays?.devise;
	}

}