import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CompteItem, TypeCompte} from "@domain/admin/comptabilite/compte";
import {ComptabilitePlanComptableService} from "@components/admin/comptabilite/plan-comptable/comptabilite-plan-comptable.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {filter,finalize,first} from "rxjs/operators";
import {TypeCodeErreur} from '@domain/common/http/result';
import {MatDialogRef} from '@angular/material/dialog';
import {PleaseWaitDialogComponent} from '@share/component/please-wait/please-wait-dialog.component';
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";

/**
 * Compte comptable dans la liste des comptes
 *
 * @author Angeline Ha
 * @date 29/02/2024
 */
@Component({
    host: {'data-test-id': 'compte-list-item'},
    templateUrl: './compte-list-item.component.html'
})
export class CompteListItemComponent extends ListViewItem<CompteItem> {

    /** Accès à l'enum dans la vue */
    readonly TypeCompte = TypeCompte;

    /**
     * Constructeur
     *
     * @param planComptableService Service du plan comptable
     * @param router Router
     * @param route Route active
     * @param confirmService Service de confirmation
     * @param translateService Service des traductions
     * @param toastrService Service des toasts
     * @param pleaseWaitService Service d'affichage du loading
     */
    constructor(private planComptableService: ComptabilitePlanComptableService,
                private router: Router,
                private route: ActivatedRoute,
                private confirmService: ConfirmService,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private pleaseWaitService: PleaseWaitService) {
        super();
    }

    /**
     * Affiche le détail du compte
     */
    goToDetailCompte() {
        //Indique au service planComptable qu'on affiche le détail d'un compte comptable
        this.planComptableService.isInDetailCompte = true;

        //Navigue vers le détail
        this.router.navigate(["CompteComptable", this.data.numero], {relativeTo: this.route});
    }

    /**
     * Suppression du compte
     */
    deleteCompte() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.comptabilite.planComptable.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

                //Affichage de la popup d'attente
                matDialogRef = this.pleaseWaitService.show();

                //Suppression du compte
                this.planComptableService.deleteCompte(this.data.numero)
                    .pipe(first(),finalize(() => matDialogRef.close()))
                    .subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Indique au composant plan comptable de refresh la liste des comptes
                        this.planComptableService.refreshListeComptes();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                });
            }
        });
    }
}
