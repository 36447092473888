import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {first,map} from 'rxjs/operators';
import * as moment from 'moment';

import {environment} from '@environments/environment';
import {Result} from '@domain/common/http/result';
import {TypePortee} from '@domain/workflow/workflow';
import {SettingsWFObjectState} from "@domain/settings/settings";

@Injectable()
export class SettingsService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {

    }

    /**
     * Récupération du cours d'une devise
     */
    public findCoursForDevise({ codeDevise,date,coursDeviseBase = 1 }: { codeDevise: string,date: Date,coursDeviseBase?: number }): Observable<number> {
        //Récupération du cours de la devise
        return this.http.post<Result>(`${environment.baseUrl}/controller/Settings/findCoursForDevise/${codeDevise}/${date.valueOf()}?coursDeviseBase=${coursDeviseBase}`,null).pipe(
            first(),
            map(result => result?.data?.cours || 1)
        );
    }

    /**
     * Récupération de la taxe pour une zone géographique
     */
    public findTaxeForVilleAndPays({ idDomaine,idPays,idVille,date }: { idDomaine: number,idPays: number,idVille?: number,date: Date }): Observable<any> {
        let params: URLSearchParams = new URLSearchParams();

        //Ajout des paramètres
        params.append('idDomaine',idDomaine.toString());
        params.append('idPays',idPays.toString());
        params.append('idVille',(idVille || 0).toString());
        params.append('date',moment(date).format('DD/MM/YYYY'));

        //Récupération de la taxe applicable
        return this.http.post<Result>(`${environment.baseUrl}/controller/TaxeUser/selectTaxeForVillePays`,params.toString(),{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).pipe(
            first(),
            map(result => result?.data?.taxe)
        );
    }

    /**
     * Permet d'indiquer si le paramétage du menu admin permet l'ajout/suppression de pièce jointe sur l'objet
     *
     * @param settings les paramétrages de l'objet workflow
     * @param idTypeEntite id du type entité
     * @return true si le paramétage du menu admin permet l'ajout/suppression de pièce jointe sur l'objet
     */
    isPjParamForTypeEntite(settings: SettingsWFObjectState,idTypeEntite: number): boolean {
        //Si la portée est active pour les pj et qu'on n'a aucun type entité paramétré ou qu'on a un type entite paramétré et correspondant
        return settings.isPorteePjActif
            && (settings.listeTypeEntiteIdPjAutorise.length == 0
                || settings.listeTypeEntiteIdPjAutorise.some(id => id == idTypeEntite));
    }
}