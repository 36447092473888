import {TypeRule} from "@domain/rule/type-rule";
import {RuleDetail} from "@domain/rule/rule-detail";
import {TypeRuleOperator} from "@domain/rule/type-rule-operator";

/**
 * Modèle d'une règle du moteur WF
 */
export class Rule {
	/** Identifiant */
	idRule: number;
	/** Entité */
	entite: string;
	/** Type de règle (INITIALE / INTERNE) */
	type: TypeRule;
	/** Liste des RuleDetail */
	listeDetails: RuleDetail[];
	/** Opérateur de la règle (ET / OU) */
	operateur: TypeRuleOperator;

	//Données utilisées dans le front uniquement
	_type: string;

	/**
	 * Constructeur
	 */
	constructor(entite?: string) {
		if (entite) {
			this.entite = entite;
		}
		this.listeDetails = [];
		this.operateur = TypeRuleOperator.AND;
		this._type = 'com.notilus.data.rule.Rule';
	}
}