import {Ville} from "@domain/geographie/ville";

/**
 * DTO des Iata
 */
export class Iata {
    idIataVille: number;
    libelle: string;
    actif: boolean;
    codeIata: string;
    codeGare: string;
    codeKDS: string;
    type: number;
    ville: Ville;
    idVille: number;
    latitude: number;
    longitude: number;
}

/**
 * Modèle des options de type d'un iata
 */
export interface OptionType {
    id: TypeIata,
    libelle: string,
}

/**
 * Enumération des types de code IATA
 */
export enum TypeIata {
    AEROPORT = 1,
    GARE = 2
}