import {Component} from "@angular/core";

/**
 * Composant d'affichage de la page des Paramètres.
 *
 * @author François Turin
 * @date 27/06/2023
 */
@Component({
	templateUrl: './admin-parametre.component.html'
})
export class AdminParametreComponent {
}
