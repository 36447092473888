<div *ngIf="axe6">
    <page-header [title]="(isCreation ? 'admin.entreprise.analytique.axe6.infos.title' : 'admin.entreprise.analytique.axe6.infos.titleModification') | translate : { reference: axe6.reference }"
        [listeItems]="listeTabItems"
        (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
    </page-header>

    <form #form="ngForm">
        <div [hidden]="selectedItem?.code !== Onglet.REFERENTIEL2">
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.entreprise.analytique.axe6.infos.ajoutCodeProjet'">
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset>
                        <div class="row" *ngIf="axe6.actif != null">
                            <custom-input ngDefaultControl
                                            id="libelle"
                                            name="libelle"
                                            [(ngModel)]="axe6.libelle"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.libelle"
                                            [required]="true"></custom-input>
                            <custom-input ngDefaultControl
                                            id="isActif"
                                            name="isActif"
                                            [ngModel]="axe6.actif"
                                            [(customModel)]="axe6.actif"
                                            customType="checkbox"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.actif"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="reference"
                                            name="reference"
                                            [(ngModel)]="axe6.reference"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.reference"
                                            [required]="true"></custom-input>
                            <custom-input ngDefaultControl
                                            id="dateDebut"
                                            name="dateDebut"
                                            [ngModel]="axe6.dateDebut"
                                            [(customModel)]="axe6.dateDebut"
                                            customType="date"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.dateDebut"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="code1"
                                            name="code1"
                                            [(ngModel)]="axe6.code1"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.code1"></custom-input>
                            <custom-input ngDefaultControl
                                            id="dateFin"
                                            name="dateFin"
                                            [ngModel]="axe6.dateFin"
                                            [(customModel)]="axe6.dateFin"
                                            [dateMin]="axe6.dateDebut"
                                            customType="date"
                                            lCol="4"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.dateFin"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                            id="code2"
                                            name="code2"
                                            [(ngModel)]="axe6.code2"
                                            customType="input"
                                            rCol="2"
                                            libelle="admin.entreprise.analytique.axe6.infos.code2"></custom-input>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.entreprise.analytique.axe6.infos.filtres'"></span>
                    <span class="actions clickable">
                        <mat-icon (click)="addFiltre()">add</mat-icon>
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <div class="listview">
                        <div class="listview-body">
                            <div class="listview-item" *ngFor="let filtre of listeFiltre">
                                <div class="lvi-content">
                                    <div class="avatar" [ngSwitch]="filtre.type">
                                        <span *ngSwitchCase="12">{{ filtreTypes.CC }}</span>
                                        <span *ngSwitchCase="3">{{ filtreTypes.EOTP }}</span>
                                        <span *ngSwitchCase="15">{{ filtreTypes.MC }}</span>
                                        <span *ngSwitchCase="14">{{ filtreTypes.SE }}</span>
                                        <span *ngSwitchDefault>{{ filtreTypes.TE }}</span>
                                    </div>
                                    <div class="lvi-body">
                                        <div class="title">
                                            <a class="label">{{ filtre.libelle }}</a>
                                        </div>
                                        <ul class="lvi-attrs">
                                            <li [ngSwitch]="filtre.type">
                                                <strong [translate]="'admin.entreprise.analytique.axe6.infos.type'"></strong>
                                                <span *ngSwitchCase="12" [translate]="'admin.entreprise.analytique.axe6.infos.filtre.cc'"></span>
                                                <span *ngSwitchCase="3" [translate]="'admin.entreprise.analytique.axe6.infos.filtre.eotp'"></span>
                                                <span *ngSwitchCase="15" [translate]="'admin.entreprise.analytique.axe6.infos.filtre.mc'"></span>
                                                <span *ngSwitchCase="14" [translate]="'admin.entreprise.analytique.axe6.infos.filtre.se'"></span>
                                                <span *ngSwitchDefault [translate]="'admin.entreprise.analytique.axe6.infos.filtre.te'"></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="lvi-actions">
                                        <button mat-icon-button [matMenuTriggerFor]="listeActions">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #listeActions="matMenu">
                                            <button mat-menu-item (click)="deleteFiltre(filtre)" translate="global.actions.supprimer"></button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>
<floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>