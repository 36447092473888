import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {FraisAgence} from "@domain/admin/voyages/frais-agence";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {NgForm} from '@angular/forms';

/**
 * Popin de modification d'un frais d'agence
 */
@Component({
	host: {'data-test-id': 'modify-frais-agence-popin'},
	templateUrl: './modify-frais-agence-popin.component.html'
})
export class ModifyFraisAgencePopinComponent implements OnInit {
	/** Frais d'agence */
	fraisAgence: FraisAgence;

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { fraisAgence: FraisAgence }
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération du frais d'agence en entrée de la popin
		this.fraisAgence = Object.assign({},this.data.fraisAgence);
	}
}
