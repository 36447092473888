<div class="row line-item-list">
    <div class="col-md-1 avatar">
        <i class="icon-profil nio align-middle" style="font-size: 20px; line-height: inherit"></i>
    </div>
    <div class="col-md-10 body">
        <span class="row">
            <span class="col-md-12">
                {{ options.displayItem(item) }}
            </span>
        </span>
        <span class="row complement">
            <span class="col-md-12" *ngIf="item?.orga">
                <strong>{{ 'analytique.service' | translate }}</strong>{{ item.orga?.numService }} - {{ item.orga?.libelle }}
            </span>
        </span>
    </div>
</div>
