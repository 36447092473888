import {ProfilsAttribuables} from "@domain/workflow/profilsAttribuables";
import {SpecificiteRoles} from "@domain/workflow/specificiteRoles";
import {ItemHierarchique} from "@share/component/affichage-hierarchique/ItemHierarchique";
import {RoleAdmin} from "@core/security/droit-admin";

/**
 * DTO Rôle
 */
export class RoleLong {
	//Données standard
	idRole: number = null;
	libelle: string = null;
	fonction: ProfilsAttribuables = null;
	commentaire: string = null;
	code: string = null;
	delegable: boolean = false;
	modeTitulaire: boolean = false;
	attribuable: boolean = false;
	autoValidation: boolean = false;
	defaut: boolean = false;
	specificite: string = null;
	listeRoleAdmins: Array<RoleAdmin> = [];
	used: boolean = false;

	/** Concaténation des champs modeTitulaire et attribuable pour utilisation dans un select */
	typeGetionnaire: TypeGestionnaire = TypeGestionnaire.AUCUN;

	/** Map pour gérer les spécificités un peu plus facilement qu'une concaténation de string */
	mapSpecificites: Map<SpecificiteRoles,boolean> = new Map<SpecificiteRoles,boolean>();

	/** Valeur du champ défaut à utiliser pour revenir en arrière */
	backupDefaut: boolean = false;

	/** Valeur du champ fonction à utiliser pour revenir en arrière */
	backupFonction: ProfilsAttribuables = null;

	/**
	 * Constructeur
	 *
	 * @param role Rôle à recopier. Peut être null pour un nouveau rôle.
	 */
	constructor(role?: RoleLong) {
		// On initialise les spécificités en les refusant toutes
		Object.keys(SpecificiteRoles).forEach(spec => {
			this.mapSpecificites.set(SpecificiteRoles[spec],false);
		})

		//S'il y a un rôle à recopier
		if (!!role) {
			// On recopie les champs
			this.idRole = role.idRole;
			this.libelle = role.libelle;
			this.fonction = role.fonction;
			this.backupFonction = role.fonction;
			this.commentaire = role.commentaire;
			this.code = role.code;
			this.delegable = role.delegable;
			this.modeTitulaire = role.modeTitulaire;
			this.attribuable = role.attribuable;
			this.autoValidation = role.autoValidation;
			this.defaut = role.defaut;
			this.backupDefaut = role.defaut;
			this.specificite = " " + (role.specificite ?? "");
			this.listeRoleAdmins = role.listeRoleAdmins;
			this.used = role.used;

			// Si le rôle a un profil qui n'est pas/plus attribuable (fournisseur/administrateur)
			if (!Object.values(ProfilsAttribuables).includes(this.fonction)) {
				//On retire le profil pour obliger à le re renseigner
				delete this.fonction;
			}

			// Gestion des booléens de type gestionnaire pour que ce soit utilisable dans un select
			if (!this.attribuable) {
				this.typeGetionnaire = TypeGestionnaire.AUCUN;
			} else if (!this.modeTitulaire) {
				this.typeGetionnaire = TypeGestionnaire.TOUS
			} else {
				this.typeGetionnaire = TypeGestionnaire.SUPPLEANT;
			}

			// On remplit la map des spécificités avec la string homonyme
			this.specificite.split(" ")
				.filter(spec => spec != null && spec != "")
				.map(spec => Number(spec))
				.forEach(spec => {
					this.mapSpecificites.set(spec as SpecificiteRoles,true);
				});
		}
	}

	/** Indique si le rôle est valide */
	isValid(): boolean {
		return this.libelle != null && this.libelle != "" && this.code != null && this.code != "" && this.fonction != null;
	}

	/**
	 * Utilise la map des spécificités pour remplir le champ "specificite" correctement
	 */
	computeSpecificites(): void {
		let specificite = "";

		// On parcourt la map des spécificités
		this.mapSpecificites.forEach((isActif,key) => {
			if (isActif) {
				//Si la spécificité est activée, on l'ajoute à la string
				specificite += key.valueOf() + " ";
			}
		});

		this.specificite = specificite;
	}

	/**
	 * Utilise le champ type gestionnaire pour remplir les booléens modeTitulaire et attribuable correctement
	 */
	computeTypeGestionnaire() {
		switch (this.typeGetionnaire) {
			case TypeGestionnaire.AUCUN:
				this.modeTitulaire = false;
				this.attribuable = false;
				break;
			case TypeGestionnaire.SUPPLEANT:
				this.modeTitulaire = true;
				this.attribuable = true;
				break;
			case TypeGestionnaire.TOUS:
				this.modeTitulaire = false;
				this.attribuable = true;
				break;
		}
	}

	/**
	 * Met à jour les droits du rôle en fonction des droits qui ont été cochés dans la liste de tous les droits
	 *
	 * @param listeAllDroits Liste de tous les droits à scruter
	 */
	computeDroits(listeAllDroits: Array<ItemHierarchique>) {
		//On commence par retirer tous les droits
		this.listeRoleAdmins.splice(0);

		//On parcourt la liste de tous les droits
		listeAllDroits
			.filter(droit => droit.checked)//On ne récupère que ceux qui ont été cochés
			.forEach(droit => {
				//On rajoute les droits cochés à la liste des droits du rôle
				this.listeRoleAdmins.push(new RoleAdmin(this.idRole,droit.identifiant));
			});
	}

	/* Accesseurs */
	get specificiteImports() { return this.mapSpecificites.get(SpecificiteRoles.SP_IMPORT_RELEVE_OPE); }
	set specificiteImports(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_IMPORT_RELEVE_OPE,isActif); }

	get specificiteAvances() { return this.mapSpecificites.get(SpecificiteRoles.SP_LETTRAGE_AVANCE); }
	set specificiteAvances(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_LETTRAGE_AVANCE,isActif); }

	get specificiteEnveloppes() { return this.mapSpecificites.get(SpecificiteRoles.SP_SUIVI_BUDGETAIRE_DETAILLE); }
	set specificiteEnveloppes(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_SUIVI_BUDGETAIRE_DETAILLE,isActif); }

	get specificiteVehicules() { return this.mapSpecificites.get(SpecificiteRoles.SP_TVTS); }
	set specificiteVehicules(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_TVTS,isActif); }

	get specificiteBudgetaire() { return this.mapSpecificites.get(SpecificiteRoles.SP_RESPONSABLE_BUDGET); }
	set specificiteBudgetaire(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_RESPONSABLE_BUDGET,isActif); }

	get specificiteConnexion() { return this.mapSpecificites.get(SpecificiteRoles.SP_CONNEXION_POUR); }
	set specificiteConnexion(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_CONNEXION_POUR,isActif); }

	get specificiteContact() { return this.mapSpecificites.get(SpecificiteRoles.SP_CONTACT_NOTILUS); }
	set specificiteContact(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_CONTACT_NOTILUS,isActif); }

	get specificiteRobot() { return this.mapSpecificites.get(SpecificiteRoles.SP_ROBOT_WORKFLOW); }
	set specificiteRobot(isActif: boolean) { this.mapSpecificites.set(SpecificiteRoles.SP_ROBOT_WORKFLOW,isActif) }
}

/** Énumération des différents types de gestionnaires disponibles */
export enum TypeGestionnaire {
	AUCUN = 0,
	TOUS = 1,
	SUPPLEANT = 2
}
