import {Component, Input} from '@angular/core';
import {TypePortee} from "@domain/workflow/workflow";
import {Module} from "@domain/admin/licence/module";

/**
 * Composant pour une ligne de la liste des modules de la licence de l'entreprise
 */
@Component({
    selector: 'licence-list-module-item',
    host: {'data-test-id': 'licence-list-module-item'},
    templateUrl: './licence-list-module-item.component.html',
    styleUrls: ['./licence.component.scss']
})
export class LicenceListModuleItemComponent {
    /** Début de la clé de traduction pour les portées */
    static TRANSLATE_KEY = "admin.entreprise.description.licence.";

    /** Un des modules de la licence */
    @Input() module: Module;

    /** Référence vers l'enum pour l'utiliser dans le template */
    TypePortee = TypePortee;

    /**
     * Récupère le nom via l'id portée
     *
     * @param idPortee l'id portée
     */
    getNameToTranslate(idPortee: TypePortee): string {
        switch(idPortee) {
            case TypePortee.OD:
                return LicenceListModuleItemComponent.TRANSLATE_KEY + "od";
            case TypePortee.AV:
                return LicenceListModuleItemComponent.TRANSLATE_KEY + "av";
            case TypePortee.FC:
                return LicenceListModuleItemComponent.TRANSLATE_KEY + "factures";
            case TypePortee.NF:
                return LicenceListModuleItemComponent.TRANSLATE_KEY+ "nf";
            case TypePortee.OT:
                return LicenceListModuleItemComponent.TRANSLATE_KEY + "omp";
        }

        return "";
    }
}
