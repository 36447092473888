import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReportParam, ReportParamType } from "@domain/reporting/reportParam";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ReportingService } from "@services/admin/reporting/reporting.service";
import { first } from "rxjs/operators";
import { TypeCodeErreur } from "@domain/common/http/result";

/**
 * Composant d'affichage du formulaire de modification d'un paramètre
 */
@Component({
    host: {'data-test-id': 'report-param-modify'},
    selector: 'report-param-modify',
    templateUrl: './report-param-modify.component.html'
})
export class ReportParamModifyComponent {
    /** Booléen indiquant si on est en mode création ou en mode modification */
    isCreation: boolean;

    /** Objet paramètre */
    reportParam: ReportParam;

    /** Enum pour les différents types de paramètres */
    ReportParamType = ReportParamType;

    /** Nom original du paramètre */
    originalName: string;

    /** Options de type */
    typeOptions: { type: number, libelle: string }[] = [{
        type: ReportParamType.TYPE_TEXTE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.parametres.type.1')
    },{
        type: ReportParamType.TYPE_DATE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.parametres.type.2')
    },{
        type: ReportParamType.TYPE_NUMERIQUE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.parametres.type.3')
    },{
        type: ReportParamType.TYPE_LISTE_CHOIX_UNIQUE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.parametres.type.4')
    },{
        type: ReportParamType.TYPE_LISTE_CHOIX_MULTIPLE,
        libelle: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.parametres.type.5')
    },];

    /**
     * Constructeur
     */
    constructor(protected matDialogRef: MatDialogRef<ReportParamModifyComponent>,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private reportingService: ReportingService,
                @Inject(MAT_DIALOG_DATA) public data: { idReport: number, reportParam: ReportParam }) {
        this.reportParam = !!data.reportParam ? data.reportParam : new ReportParam(null);
        this.isCreation = !data.reportParam;
        this.originalName = !this.isCreation && data?.reportParam?.name;
    }

    /**
     * Enregistrement
     */
    save() {
        this.reportingService.saveParam(this.data.idReport,this.reportParam,this.originalName)
            .pipe(first())
            .subscribe(res => {
                if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
                    //On affiche le message d'erreur
                    TypeCodeErreur.showError(res.codeErreur,this.translateService,this.toastrService);
                } else {
                    //On ferme la boîte de dialogue en indiquant qu'il faut mettre à jour la liste
                    this.matDialogRef.close(true);

                    //Si aucune erreur n'est renvoyée alors on affiche un message de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                }
            });
    }

    /**
     * Purge du champ SQL en cas de type incompatible
     */
    onTypeChange() {
        if (!(this.reportParam.type == 4 || this.reportParam.type == 5)) {
            this.reportParam.sql = undefined;
        }
    }
}
