<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">filter_alt</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'filter.type'"></strong>
                <span>{{ getLibelleTypeFiltre() }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteFilter()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>