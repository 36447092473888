import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {Result} from '../../domain/common/http/result';
import {TypePortee} from "../../domain/workflow/workflow";
import {TypeEntite} from "../../domain/typeentite/typeEntite";
import {ModeAnalytique, TypeEntiteParamOD, TypeEntiteParamOT} from "../../domain/typeentite/typeEntiteParam";

@Injectable()
export class TypeEntiteService {
    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {

    }

    /**
     * Recherche d'un type entité par son identifiant
     *
     * @param idTypeEntite Identifiant du type entité
     * @param portee Portée pour la récupération des paramètres associés
     * @return le type entité trouvé et ses paramètres
     */
    public findTypeEntite(idTypeEntite: number,portee: TypePortee): Observable<TypeEntite> {
        //Récupération du cours de la devise
        return this.http.post<Result>(`${environment.baseUrl}/controller/TypeEntite/${idTypeEntite}/${portee}`,null).pipe(
            first(),
            map(result => result?.data?.typeEntite)
        );
    }

    /**
     * Permet de vérifier si le type entité autorise la modification de l'analytique sur ses objets rattachés (ex : une NDF ou OD rattaché à un OMP)
     *
     * @param idTypeEntite Identifiant du type entité
     * @param porteeObjet Portée de l'objet en cours
     * @param porteeObjetParent Portée de l'objet parent
     */
    public isAnalytiqueHeriteModifiable(idTypeEntite: number,porteeObjet: TypePortee,porteeObjetParent: TypePortee): Observable<boolean> {
        return this.findTypeEntite(idTypeEntite,porteeObjetParent).pipe(map((typeEntite) => {
            let modeanalytique: ModeAnalytique;

            switch (porteeObjetParent) {
                //Objet parent de type OMP
                case TypePortee.OT:
                    //Récupération du paramétrage du type entité, casté suivant la portée de l'objet parent
                    const typeEntiteParamOT:TypeEntiteParamOT = typeEntite.typeEntiteParam;

                    if (porteeObjet == TypePortee.NF) {
                        //Objet rattaché à un OMP de type NDF
                        modeanalytique = typeEntiteParamOT.modeAnalytiqueNDFOT;
                    } else if (porteeObjet == TypePortee.OD) {
                        //Objet rattaché à un OMP de type OD, casté suivant la portée de l'objet parent
                        modeanalytique= typeEntiteParamOT.modeAnalytiqueOM;
                    }
                    break;
                //Objet parent de type OD
                case TypePortee.OD:
                    //Récupération du paramètrage du type entité
                    const typeEntiteParamOD:TypeEntiteParamOD = typeEntite.typeEntiteParam as TypeEntiteParamOD;

                    if (porteeObjet == TypePortee.FC) {
                        //Objet rattaché à un OD de type Facture
                        modeanalytique = typeEntiteParamOD.modeAnalytiqueFacture;
                    } else if (porteeObjet == TypePortee.NF) {
                        //Objet rattaché à un OD de type NDF
                        modeanalytique = typeEntiteParamOD.modeAnalytiqueNDFOD;
                    }
                    break;
            }

            return modeanalytique && modeanalytique !== ModeAnalytique.HERITE_NON_MODIFIABLE;
        }));
    }

}
