import {Component,Inject,OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Document} from "@domain/profil/document";
import {SettingsGlobalState} from "@domain/settings/settings";
import {filter,first} from "rxjs/operators";
import * as moment from 'moment';
import {InfosSociete} from '@domain/profil/infosSociete';
import {MatCheckboxChange} from '@angular/material/checkbox';

/**
 * Composant d'ajout/modification de documents pour le profil utilisateur
 */
@Component({
	selector: 'profil-donnees-add-document',
	templateUrl: './profil-donnees-add-document.component.html'
})
export class ProfilDonneesAddDocumentComponent implements OnInit {

	/** Indicateur de sauvegarde */
	isSaving: boolean = false;

	/** Indicateur de suppression */
	isDeleting: boolean = false;

	/** La suppression a déjà été demandé */
	isDeleted: boolean = false;

	/** Indicateur de validité */
	isValid: boolean = true;

	/** Indiciateur de modification */
	isReadOnly: boolean = true;

	/** Indicateur pour refresh la liste à la sortie de la popup */
	willRefreshList: boolean = false;

	/** Indicateur de saisie manuelle de lieu */
	isLieuNonExistant: boolean = false;

	/** Pays */
	pays: any;

	/** Ville */
	ville: any;

	/** Affichage message d'erreur custom delivrance */
	isShowDelivranceError: boolean = false;

	/** Affichage message d'erreur custom expiration */
	isShowExpirationError: boolean = false;

	/** Liste des types de document pour le select */
	listeTypeDocument: Array<any> = [
		{type: "PASSEPORT",libelle: this.translateService.instant("profilUser.document.PASSEPORT")},
		{type: "PERMIS_DE_CONDUIRE",libelle: this.translateService.instant("profilUser.document.PERMIS_DE_CONDUIRE")},
		{type: "CARTE_NATIONALE_IDENTITE",libelle: this.translateService.instant("profilUser.document.CARTE_NATIONALE_IDENTITE")}
	];

	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<ProfilDonneesAddDocumentComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { document: Document,settings: SettingsGlobalState,infoSociete: InfosSociete,idUser?: number }
	) {
	}

	/**
	 * Initialisation (permet de renseigner la ville / le pays)
	 */
	ngOnInit(): void {
		if (this.data.document && this.data.document.idVille) {
			this.profilService.getEntiteGeoForIdVille(this.data.document.idVille).pipe(first()).subscribe(
				result => this.ville = result.data?.geographie
			);
		} else if (this.data.document.idPays) {
			this.isLieuNonExistant = true;
			this.profilService.getEntiteGeoForIdPays(this.data.document.idPays).pipe(first()).subscribe(
				result => this.pays = result.data?.geographie
			);
		}

		//Si création de document, on renseigne nom + prénom
		if (this.data.document.titulaire == null && this.data.infoSociete.nom && this.data.infoSociete.prenom) {
			this.data.document.titulaire = this.data.infoSociete.nom + " " + this.data.infoSociete.prenom;
		}

		this.isDeleted = this.data.document.toDelete;
		this.isReadOnly = this.isDeleted;
	}

	/**
	 * Suppression d'un document
	 */
	deleteDocument() {
		//Confirmation auprès de l'utilisateur sur la suppression d'un document
		this.confirmService.showConfirm(this.translateService.instant(this.isDeleted
			? 'profilUser.document.add.cancelDeleteConfirmation' : 'global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					this.isDeleting = true;
					this.profilService.deleteDocument(this.data.document,this.data.idUser).pipe(first()).subscribe({
						next: result => {
							//Message d'information
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Fermeture de l'écran
							this.matDialogRef.close(true);
						}
					});
				}
			});
	}

	/**
	 * Sauvegarde d'un document
	 */
	save() {
		this.isSaving = true;

		if (this.isLieuNonExistant) {
			this.data.document.idPays = this.pays.id;
			this.data.document.idVille = null;
		} else {
			this.data.document.idVille = this.ville?.id ?? null;
			this.data.document.idPays = null;
			this.data.document.lieuDelivrance = this.ville?.libelle ?? '';
		}

		this.profilService.saveDocument(this.data.document,this.data.idUser).pipe(first()).subscribe({
			next: result => {
				//Message d'information
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				//Fermeture de l'écran
				this.matDialogRef.close(true);
			}
		});
	}

	/**
	 * Contrôle de la validité des données saisies
	 */
	checkError() {
		const nowDate = moment();

		//Contrôle date délivrance inférieure ou égale à date du jour
		this.isShowDelivranceError = this.data.document.dateDelivrance != null && nowDate.isBefore(this.data.document.dateDelivrance,'day');

		//Contrôle date expiration supérieur ou égale date du jour
		this.isShowExpirationError = this.data.document.dateExpiration != null && nowDate.isAfter(this.data.document.dateExpiration,'day');

		// Contrôle date expiration supérieure ou égale date de délivrance si on a une date de délivrance
		if (!this.isShowExpirationError && this.data.document.dateDelivrance != null && this.data.document.dateExpiration != null) {
			this.isShowExpirationError = this.data.document.dateExpiration < this.data.document.dateDelivrance;
		}
	}

	/**
	 * Contrôle si une erreur est présente pour les dates
	 */
	isError(): boolean {
		return this.isShowDelivranceError || this.isShowExpirationError || this.isLieuError();
	}

	/** Contrôle si une erreur est présente sur la saisie du lieu */
	isLieuError(): boolean {
		//Si on n'a pas lieu non existant et qu'une ville a été saisie
		return !this.isLieuNonExistant && (this.ville != null && this.ville.id == null);
	}

	/**
	 * Event changement date de délivrance
	 * @param dateDelivrance date de délivrance
	 */
	onDateDelivranceChange(dateDelivrance: Date) {
		this.data.document.dateDelivrance = dateDelivrance;
		this.checkError();
	}

	/**
	 * Event changement date d'expiration
	 * @param dateExpiration date d'expiration
	 */
	onDateExpirationChange(dateExpiration: Date) {
		this.data.document.dateExpiration = dateExpiration;
		this.checkError();
	}


	/**
	 * Evènement quand l'utilisateur coche lieu inconnu.
	 * @param matCheckboxChange le param de l'event MatCheckboxChange
	 */
	onIsLieuNonExistantChange(matCheckboxChange: MatCheckboxChange) {
		//Si l'utilisateur coche la case
		if (matCheckboxChange.checked) {
			//On récupère la valeur du composant ville pour l'intégrer dans lieuDelivrance selon que ce soit une valeur sélectionnée ou saisie
			//Afin que l'utilisateur ne perde pas sa saisie quand les composants sont cachés/visibles
			this.data.document.lieuDelivrance = this.ville?.libelle ?? this.ville;
			this.pays = {
				id: this.ville?.pays?.idPays ?? null,
				libelle: this.ville?.pays?.libelle ?? null
			};
		}
	}
}
