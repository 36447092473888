<div class="with-floating-button">
    <div class="content">
        <page-header
                [title]="'admin.voyages.fraisAgence.title' | translate"
                [listeItems]="listeTabItems"
                (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.FRAIS_AGENCE">
            <frais-agence-list></frais-agence-list>
        </div>
    </div>
</div>