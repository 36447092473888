<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.niveauAlerte === NiveauAlerte.WARNING,'danger': data.niveauAlerte === NiveauAlerte.ERROR }">
        <i class="material-icons">mail_outline</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ getTitle() }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.analytique.enveloppes.reference'"></strong>
                <span>{{ data.reference || ('admin.entreprise.analytique.enveloppes.aucune' | translate) }}</span>
            </li>
            <li *ngIf="data.exercice">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.exercice'"></strong>
                <span>{{ data.exercice }}</span>
            </li>
            <li *ngIf="data.dateDebut">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.debut'"></strong>
                <span>{{ data.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateFin">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.fin'"></strong>
                <span>{{ data.dateFin | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.montant !== null && data.codeDevise">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantTotal'"></strong>
                <span>{{ data.montant | montant:data.codeDevise }}</span>
            </li>
            <li *ngIf="data.montantEngage !== null && data.codeDevise">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantEngage'"></strong>
                <span>{{ data.montantEngage | montant:data.codeDevise }}</span>
            </li>
            <li *ngIf="data.montantDisponible !== null && data.codeDevise">
                <strong [translate]="'admin.entreprise.analytique.enveloppes.montantDisponible'"></strong>
                <span>{{ data.montantDisponible | montant:data.codeDevise }}</span>
            </li>
            <li *ngIf="data.niveauAlerte !== NiveauAlerte.NO_CONTROL" class="clickable" [ngClass]="{ 'back-warning': data.niveauAlerte === NiveauAlerte.WARNING, 'back-danger': data.niveauAlerte === NiveauAlerte.ERROR }" (click)="showAlerte()">
                <strong [translate]="'workflow.alerte.title'"></strong>
                <span [translate]="'workflow.alerte.niveau.' + data.niveauAlerte"></span>
            </li>
        </ul>
    </div>
</div>