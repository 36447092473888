import {Component, OnDestroy, OnInit} from '@angular/core';
import {RecapObjet} from "@domain/dashboard/recap-objet";
import {DashboardService} from "../../dashboard.service";
import {filter, first} from "rxjs/operators";
import {ListView} from "@domain/common/list-view";
import {ObjetsListItemComponent} from "./objets-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypeProfil} from "@domain/user/user";
import {Subscription} from "rxjs";

/**
 * Liste des recaps d'objets du Dashboard
 */
@Component({
    selector: 'objets-list',
    templateUrl: './objets-list.component.html'
})
export class ObjetsListComponent implements OnInit,OnDestroy {

    /** Souscription au changement d'utilisateur */
    userSouscription: Subscription;

    /** Liste des récaps de mes Objets */
    liste: ListView<RecapObjet,ObjetsListItemComponent>;

    /**
     * Constructeur
     *
     * @param store le store
     * @param translateService le service de traduction
     * @param dashboardService le service du Dashboard
     */
    constructor(private store: Store<AppState>,
                private translateService: TranslateService,
                private dashboardService: DashboardService) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Type de liste en fonction de l'utilisateur connecté
        this.userSouscription = this.store.select(state => state.session.user).pipe(filter(value => !!value)).subscribe(user => {
            let title: string;
            if (user.fonction == TypeProfil.COLLABORATEUR) {
                //Si l'utilisateur est connecté en tant que collaborateur le titre du cadre sera "Mes Objets"
                title = 'dashboard.listeObjets.title';
            } else {
                //Sinon ce sera "Objets"
                title = 'dashboard.listeObjets.titleNonCollab';
            }

            //Initialisation de la liste
            this.liste = new ListView<RecapObjet, ObjetsListItemComponent>({
                title: this.translateService.instant(title),
                component: ObjetsListItemComponent,
                isFrontendList: true,
                isDashboardList: true
            });

            //On passe la liste en chargement
            this.liste.isLoading = true;

            //Chargement des récaps
            this.dashboardService.getRecapObjets().pipe(first()).subscribe(data => {
                this.liste.data = {
                    listeResultats: data,
                    numPage: 0,
                    nbObjetsParPage: 0,
                    nbObjetsDansPage: data.length,
                    nbPagesTotal: 1,
                    nbObjetsTotal: data.length
                }

                //Fin du chargement
                this.liste.isLoading = false;
            });
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        this.userSouscription?.unsubscribe();
    }
}
