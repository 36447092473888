import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AppState } from "@domain/appstate";
import { TypeCodeErreur } from "@domain/common/http/result";
import { Pays } from "@domain/geographie/pays";
import { Devise } from "@domain/settings/devise";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DevisesService } from "@services/admin/devises/devises.service";
import { ConfirmService } from "@share/component/confirmation/confirm.service";
import { FloatingButtonAction, TypeAction } from "@share/component/floating-button/floating-button";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import { ToastrService } from "ngx-toastr";
import { filter } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

/**
 * Composant d'affichage des informations d'une devise et de création d'une devise
 */
@Component({
    host: {'data-test-id': 'devise-infos'},
    templateUrl: './devise-infos.component.html'
})
export class DeviseInfosComponent implements OnInit {
    /** Boolean pour savoir si faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Devise à afficher sur la page */
    devise: Devise;

    @ViewChild('form') form: NgForm;

    /** Liste des pays associés à la devise */
    listePays: Pays[];

    /** Liste des différents onglets disponibles dans le menu "Devise" de la bibliothèque */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglet = OngletsInfos;

    /** Liste des actions possibles pour le floatingButton en bas à droite */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param store Le store de l'appli
     * @param translateService Service de traduction
     * @param devisesService Service de gestion de la devise
     * @param activatedRoute Route d'accès à cette page
     * @param confirmService Service de confirmation pour la suppression
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param matDialog Boîte de dialogue
     * @param router Router de l'application pour naviguer entre les pages
     */
    constructor(private store: Store<AppState>,
                private translateService: TranslateService,
                private devisesService: DevisesService,
                private activatedRoute: ActivatedRoute,
                private confirmService: ConfirmService,
                private toastrService: ToastrService,
                private matDialog: MatDialog,
                private router: Router) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Longueur de l'url pour récupérer le code de la devise
        let length = this.activatedRoute.snapshot.url.length;
        //Récupération du code de la devise
        let deviseCode: string = this.activatedRoute.snapshot.url[length - 1].path;

        //Permet de générer la liste des différents onglets disponibles
        this.listeTabItems = [{
            code: OngletsInfos.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.devises.onglets.generalites')
        }];

        //Sélection de l'onglet Généralités par défaut
        this.selectedItem = this.listeTabItems[0];
        this.selectedItem.selected = true;

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.SECONDARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.save(),
        }]);

        //Si le code de la devise est "0" alors on est en création
        if (deviseCode === "0"){
            this.isCreation = true;
            //Initialisation d'une nouvelle devise (celle qui va être créé)
            this.devise = {
                code: "",
                libelle: "",
                active: true,
                parite: 0,
                commentaire: "",
                avanceEspece: false,
                avanceVirement: false,
                navance: "",
                nrendu: "",
            };
        } else {
            //Si le code de la devise n'est pas "0" alors on est en modification
            this.isCreation = false;

            //On ajoute l'action de suppression d'une devise quand on est en modification, pour ne pas l'avoir en création
            this.listeActions.next(
                [...this.listeActions.getValue(),
                    {
                    type: TypeAction.SECONDARY,
                    icone: 'nio icon-suppression',
                    libelle: 'global.actions.supprimer',
                    doAction: () => this.delete()
                }
            ]);

            //Récupération de la devise à modifier à l'aide du code
            this.devisesService.getDeviseByCode(deviseCode).subscribe(data => {
                //Initialisation de la liste des pays reliés à la devise
                this.listePays = data.data.listePays;
                //Initialisation de la devise
                this.devise = data.data.devise;
            });

            //Comme on est en modification, on ajoute un onglet "Taux" pour afficher les taux de conversion de la devise
            this.listeTabItems.push({
                code: OngletsInfos.TAUX,
                libelle: this.translateService.instant('admin.bibliotheque.devises.onglets.taux')
            });
        }
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedItemChange(selectedItem: PageHeaderItem) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = selectedItem;
    }

    /**
     * Sauvegarde des changements apportés à la devise
     */
    save() {
        if (this.form.valid) {
            //Sauvegarde des changements en base
            this.devisesService.saveDevise(this.isCreation, this.devise).subscribe(data => {
                //Si le code d'erreur du retour est différent de NO_ERROR alors c'est que la devise ne s'est pas enregistrée correctement dans la base de données
                if (data.codeErreur !== TypeCodeErreur.NO_ERROR) {
                    //Affichage d'un message d'erreur quand l'enregistrement de la devise en base n'a pas fonctionné correctement
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                } else {
                    //Affichage d'un message de succès si l'enregistrement de la devise en base s'est passé correctement
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    //Si on est en création alors on redirige vers la liste après avoir créé la devise, sinon on reste sur la page de la devise
                    if(this.isCreation) {
                        this.router.navigate(['Admin/Bibliotheque/Devises']);
                    }
                }
            });
        } else {
            //Affichage d'un message d'erreur indiquant que le formulaire n'est pas valide
            this.toastrService.error(this.translateService.instant('global.errors.formInvalid'));
        }
    }

    /**
     * Méthode appelée lors de la suppression de la devise sélectionnée
     */
    delete() {
        //Affichage de la confirmation de suppression de la devise
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //Suppression de la devise en base
                    this.devisesService.deleteDevise(this.devise.code).subscribe(data => {
                        //Si le code d'erreur du retour est différent de NO_ERROR alors c'est que la devise ne s'est pas supprimée correctement dans la base de données
                        if (data.codeErreur !== TypeCodeErreur.NO_ERROR) {
                            //Affichage d'un message d'erreur quand la suppression de la devise en base n'a pas fonctionné correctement
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        } else {
                            //Affichage d'un message de succès si la suppression de la devise en base s'est passée correctement
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                            //On retourne sur la liste des devises
                            this.router.navigate(['Admin/Bibliotheque/Devises']);
                        }
                    });
                }
            });
    }

    /**
     * Méthode appelée lors du clic sur le bouton de retour en arrière pour retourner vers la liste des devises
     */
    onGoBack() {
        //Navigation vers la liste
        this.router.navigate(['Admin/Bibliotheque/Devises']);
    }
}

/**
 * Enum pour les noms des différents onglets de la page Devises et de la page d'informations d'une devise
 */
export enum OngletsInfos {
    GENERALITES = "Généralités",
    TAUX = "Taux"
}