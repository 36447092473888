<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.analytique.enveloppes.reaffecter.title'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>

<form #form="ngForm">
    <div mat-dialog-content>
        <div class="row">
            <custom-input ngDefaultControl
                          id="cible"
                          name="cible"
                          [(ngModel)]="montantCible"
                          customType="autocomplete"
                          libelle="admin.entreprise.analytique.enveloppes.reaffecter.enveloppeCible"
                          autocompleteType="enveloppe"
                          optionValue="idMontant"
                          optionDisplay="libelle"
                          [autocompleteFilter]="{ idMontant: data.montant.idMontant}"
                          [lCol]="4"
                          [rCol]="6"
                          [idName]="'idMontant'"
                          [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl
                          id="montant"
                          name="montant"
                          [(ngModel)]="montantValue"
                          customType="montant"
                          libelle="admin.entreprise.analytique.enveloppes.reaffecter.montantReaffectation"
                          [lCol]="4"
                          [rCol]="6"
                          [required]="true"
                          [errorMessage]="isMontantInvalid() ? ('admin.entreprise.analytique.enveloppes.reaffecter.erreurDisponible' | translate) : ''">
            </custom-input>
        </div>
    </div>
    <div class="d-flex flex-row-reverse">
        <button mat-flat-button color="primary" (click)="onReaffecter()" [disabled]="form.invalid">
            <span *ngIf="!isSaving" [translate]="'global.actions.ajouter'"></span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
    </div>
</form>

