<mat-card>
    <mat-card-title><span [translate]="'workflow.roles.detail.role'"></span></mat-card-title>
    <mat-card-content>
        <form>
            <fieldset>
                <!-- Libellé et Code -->
                <div class="row">
                    <!-- Libellé -->
                    <custom-input ngDefaultControl name="libelle"
                                  libelle="workflow.roles.libelle"
                                  customType="input"
                                  [required]="true"
                                  [(ngModel)]="role.libelle"
                                  [maxlength]="50"></custom-input>
                    <!-- Code -->
                    <custom-input name="code"
                                  customType="input"
                                  libelle="workflow.roles.detail.code"
                                  [required]="true"
                                  [(ngModel)]="role.code"
                                  [rCol]="2"
                                  [maxlength]="5" ngDefaultControl></custom-input>
                </div>

                <!-- Profil et Auto validation -->
                <div class="row">
                    <!-- Profil -->
                    <custom-input name="profil"
                                  customType="select"
                                  libelle="workflow.roles.detail.profil"
                                  [required]="true"
                                  [(ngModel)]="role.fonction"
                                  [isTranslated]="false"
                                  [readonly]="isDisableProfilChange()"
                                  optionValue="val"
                                  optionDisplay="libelle"
                                  (onChange)="onProfilChange()"
                                  [selectOptions]="profilsAttribuablesListe" ngDefaultControl></custom-input>

                    <!-- AutoValidation -->
                    <custom-input name="autoValidation"
                                  *ngIf="role.fonction != null && ![ProfilsAttribuables.COLLABORATEUR, ProfilsAttribuables.SOUS_ADMINISTRATEUR, ProfilsAttribuables.FOURNISSEUR].includes($any(role.fonction))"
                                  customType="checkbox"
                                  libelle="workflow.roles.detail.autoValidation"
                                  postLibelle="workflow.roles.detail.autoValidationLabel"
                                  [ngModel]="role.autoValidation"
                                  [(customModel)]="role.autoValidation" ngDefaultControl
                    ></custom-input>
                </div>

                <!-- gestionnaires et délégable -->
                <div class="row" *ngIf="role.fonction != null && role.fonction !== ProfilsAttribuables.COLLABORATEUR && role.fonction !== ProfilsAttribuables.FOURNISSEUR">
                    <!-- gestionnaires -->
                    <custom-input name="gestionnaires"
                                  customType="select"
                                  libelle="workflow.roles.detail.gestionnaires"
                                  [(ngModel)]="role.typeGetionnaire"
                                  [isTranslated]="false"
                                  optionValue="val"
                                  optionDisplay="libelle"
                                  [postTooltip]="getTooltip() | translate"
                                  [selectOptions]="listeOptionsGestionnaires" ngDefaultControl></custom-input>

                    <!-- délégable -->
                    <custom-input name="delegable"
                                  *ngIf="role.fonction === ProfilsAttribuables.ASSISTANT || role.fonction === ProfilsAttribuables.RESPONSABLE"
                                  customType="checkbox"
                                  libelle="workflow.roles.detail.delegable"
                                  postLibelle="workflow.roles.detail.delegableLabel"
                                  [ngModel]="role.delegable"
                                  [(customModel)]="role.delegable" ngDefaultControl></custom-input>
                </div>

                <!-- par défaut -->
                <div class="row" *ngIf="role.fonction != null && role.fonction === ProfilsAttribuables.COLLABORATEUR">
                    <!-- par défaut -->
                    <custom-input name="parDefaut"
                                  customType="checkbox"
                                  libelle="workflow.roles.detail.parDefaut"
                                  postLibelle="workflow.roles.detail.parDefautLabel"
                                  [readonly]="isDisableCheckDefaut()"
                                  [ngModel]="role.defaut"
                                  [(customModel)]="role.defaut" ngDefaultControl
                                  [postTooltip]="(isLastRoleCollab() ? 'workflow.roles.detail.parDefautPostLibelle' : '') | translate">
                    </custom-input>
                </div>

                <!-- Commentaire -->
                <div class="row">
                    <!-- Commentaire -->
                    <custom-input name="commentaire"
                                  customType="textarea"
                                  libelle="workflow.roles.detail.commentaire"
                                  maxlength="500"
                                  rCol="10"
                                  [(ngModel)]="role.commentaire" ngDefaultControl></custom-input>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>
