/** Type de prestation travel */
export enum TypePresta {
    AVION = 'AVION',
    TRAIN = 'TRAIN',
    HOTEL = 'HOTEL',
    VOITURE_DE_LOCATION = 'VOITURE_DE_LOCATION',
    VOITURE_SOCIETE = 'VOITURE_SOCIETE',
    VOITURE_PERSONNELLE = 'VOITURE_PERSONNELLE',
    DOCUMENT = 'DOCUMENT',
    AVION_TRAIN = 'AVION_TRAIN',
    AVANCE = 'AVANCE',
    TRANSPORT_1 = 'TRANSPORT_1',
    TRANSPORT_2 = 'TRANSPORT_2',
    TRANSPORT_3 = 'TRANSPORT_3',
    SERVICE_1 = 'SERVICE_1',
    SERVICE_2 = 'SERVICE_2',
    SERVICE_3 = 'SERVICE_3',
    AUTRE = 'AUTRE'
}

/** Type de prestation travel pour les référentiels */
export enum TypePrestaReferentiels {
    AVION = 0,
    TRAIN = 1,
    HOTEL = 2,
    VOITURE_DE_LOCATION = 3,
    VOITURE_SOCIETE = 4,
    VOITURE_PERSONNELLE = 5,
    DOCUMENT = 6,
    AVION_TRAIN = 7,
    AVANCE = 8,
    TRANSPORT_1 = 9,
    TRANSPORT_2 = 10,
    TRANSPORT_3 = 11,
    SERVICE_1 = 12,
    SERVICE_2 = 13,
    SERVICE_3 = 14
}

/** Extension de l'énumération sur le type de prestation  */
export namespace TypePresta {
    /**
     * Méthode qui revoie la clé de traduction correspondant au TypePresta en paramètre
     */
    export function cleTraduction(typePresta: TypePresta): string {
        switch (typePresta) {
            case this.AVION:
                return 'od.voyage.travel.avion';
            case this.TRAIN:
                return 'od.voyage.travel.train';
            case this.HOTEL:
                return 'od.voyage.travel.hotel';
            case this.VOITURE_DE_LOCATION:
                return 'od.voyage.travel.voitureLoc';
            case this.DOCUMENT:
                return 'od.voyage.travel.visa';
            case this.AUTRE:
                return 'od.voyage.travel.autre';
            default:
                return '';
        }
    }
}
