<mat-card>
    <mat-card-title>
        <span translate="ndf.generalites.generationAutomatiqueFrais"></span>
    </mat-card-title>
    <mat-card-content>
        <document-uploader 
            [context]="'NDF'" 
            (onDocumentUploaded)="onItemUploaded($event)"
            [idObjet]="ndf.idNDF"
            [isOcr]="true"
            [settings]="settings">
        </document-uploader>
    </mat-card-content>
</mat-card>