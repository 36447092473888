import {Component,Input,OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {Orga} from "@domain/admin/entreprise/analytique/orga";

/**
 * Composant d'affichage d'un service dans l'organigramme
 */
@Component({
    host: {'data-test-id': 'analytique-organigramme-item'},
    selector: 'analytique-organigramme-item',
    templateUrl: './analytique-organigramme-item.component.html'
})
export class AnalytiqueOrganigrammeItemComponent extends ListViewItem<Orga> implements OnInit {

    /** Elément courant */
    @Input() data: Orga;

    /** Ensemble des différents identifiants parents de cet item */
    @Input() chemin: string = "";

    /** Permet de savoir si l'item est ouvert ou non, pour savoir s'il faut afficher les sous services */
    _isOuvert: boolean = false;

    /** Liste des sous services */
    listeSousOrga: Orga[];

    /**
     * Constructeur
     *
     * @param analytiqueService Service de gestion des informations sur l'analytique
     * @param router Router de l'application
     */
    constructor(
        private analytiqueService: AnalytiqueService,
        private router: Router
    ) {
        super();
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Ré-affichage des sous-services affichés le cas échéant
        if (this.analytiqueService.isOrgaUnfolded(this.data.idOrga)) {
            this.showChild();
        }
    }

    /**
     * Méthode appelée lors de la sélection d'un service
     */
    onSelect() {
        //Navigation vers la page d'informations du service
        this.router.navigate([`Admin/Entreprise/Analytique/Services/${this.data.idOrga}`]);
    }

    /**
     * Getter permettant de savoir si les sous-services sont affichés ou non
     */
    get isOuvert(): boolean { return this._isOuvert; }

    /**
     * Setter permettant de savoir si les sous-services sont affichés ou non.<br>
     * Note : lance une requête back pour récupérer les services à afficher
     */
    set isOuvert(isOuvert: boolean) {
        this._isOuvert = isOuvert;
        this.analytiqueService.setOrgaFoldedUnfolded(this.data.idOrga,isOuvert);
    }

    /**
     * Affichage, ou non, des sous services du service courant
     */
    showChild() {
        //Si les sous services sont déjà affichés
        if (this.isOuvert) {
            //On replie le service sélectionné
            this.isOuvert = false;
        } else {
            //Sinon on affiche les sous services
            this.isOuvert = true;
            //Récupération des sous services si on ne les a pas encore récupérés
            if (!this.listeSousOrga) {
                //Récupération des sous services
                this.analytiqueService.getChild(this.data.idOrga).subscribe(data => {
                    this.listeSousOrga = data.data.listeSousServices;
                });
            }
        }
    }
}