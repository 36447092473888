import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Prestation} from "@domain/prestation/prestation";

/**
 * Composant d'un item de la liste des prestations autorisées
 */
@Component({
	host: {'data-test-id': 'prestation-list-item'},
	templateUrl: './prestation-list-item.component.html'
})
export class PrestationListItemComponent extends ListViewItem<Prestation> {
}
