<mat-card>
    <mat-card-title><span [translate]="'admin.parametres.demat.ocr.cadreJustificatif'"></span></mat-card-title>

    <please-wait class="content" *ngIf="!this.settings"></please-wait>

    <mat-card-content>
        <document-uploader *ngIf="this.settings"
                [fileUploaderCustom]="fileUploader"
                [title]="'admin.parametres.demat.ocr.deposerJustificatif'"
                (onDocumentUploaded)="onItemUploaded($event)"
                (onDocumentsAdded)="onDocumentsAdded($event)"
                [settings]="settings">
        </document-uploader>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="isProcessing || fluxOcr">
    <mat-card-title><span [translate]="'admin.parametres.demat.ocr.cadreRetourOCR'"></span></mat-card-title>
    <mat-card-content>
        <please-wait *ngIf="isProcessing"></please-wait>
        <form *ngIf="!isProcessing" name="demat-ocr" autocomplete="off">
            <div class="row">
                <div class="col-lg-7">
                    <mat-tab-group [dynamicHeight]="false" animationDuration="0ms" [(selectedIndex)]="tabIndex">
                        <mat-tab label="{{ 'admin.parametres.demat.ocr.ocrDetail' | translate }}">
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="nature" libelle="admin.parametres.demat.ocr.nature" [ngModel]="fluxOcr?.label"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="3" disabled="true" name="montant" libelle="admin.parametres.demat.ocr.montant"
                                              [ngModel]="fluxOcr?.amountWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.amountWithConfidence)"></custom-input>
                                <custom-input ngDefaultControl customType="input" lCol="2" rCol="3" disabled="true" name="devise" libelle="admin.parametres.demat.ocr.devise"
                                              [ngModel]="fluxOcr?.currencyWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.currencyWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="pays" libelle="admin.parametres.demat.ocr.pays"
                                              [ngModel]="fluxOcr?.countryWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.countryWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="ville" libelle="admin.parametres.demat.ocr.ville"
                                              [ngModel]="fluxOcr?.cityWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.cityWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="3" disabled="true" name="date" libelle="admin.parametres.demat.ocr.date"
                                              [ngModel]="dateOcrStr"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.dateWithConfidence)"></custom-input>
                                <custom-input ngDefaultControl customType="input" lCol="2" rCol="3" disabled="true" name="heure" libelle="admin.parametres.demat.ocr.heure"
                                              [ngModel]="fluxOcr?.timeWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.timeWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="3" disabled="true" name="quantite" libelle="admin.parametres.demat.ocr.quantite"
                                              [ngModel]="fluxOcr?.volumeWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.volumeWithConfidence)"></custom-input>
                                <custom-input ngDefaultControl customType="input" lCol="2" rCol="3" disabled="true" name="unite" libelle="admin.parametres.demat.ocr.unite"
                                              [ngModel]="fluxOcr?.volumeUnityWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.volumeUnityWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="invites" libelle="admin.parametres.demat.ocr.invites"
                                              [ngModel]="fluxOcr?.guestsWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.guestsWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="tif" libelle="admin.parametres.demat.ocr.tif"
                                              [ngModel]="fluxOcr?.tifWithConfidence?.value"
                                              [postTooltip]="getTooltipIndiceConfiance(fluxOcr?.tifWithConfidence)"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="vendeurNom" libelle="admin.parametres.demat.ocr.vendeurNom"
                                              [ngModel]="fluxOcr?.seller?.name"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="vendeurAdresse" libelle="admin.parametres.demat.ocr.vendeurAdresse"
                                              [ngModel]="fluxOcr?.seller?.address"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="vendeurLocalisation" libelle="admin.parametres.demat.ocr.vendeurLocalisation"
                                              [ngModel]="fluxOcr?.seller?.location"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="vendeurLatitude" libelle="admin.parametres.demat.ocr.vendeurLatitude"
                                              [ngModel]="fluxOcr?.seller?.latitude"></custom-input>
                            </div>
                            <div class="row">
                                <custom-input ngDefaultControl customType="input" lCol="4" rCol="8" disabled="true" name="vendeurLongitude" libelle="admin.parametres.demat.ocr.vendeurLongitude"
                                              [ngModel]="fluxOcr?.seller?.longitude"></custom-input>
                            </div>
                        </mat-tab>
                        <mat-tab label="{{ 'admin.parametres.demat.ocr.ocrFlux' | translate }}">
                            <ngx-codemirror *ngIf="tabIndex == 1"
                                class="d-flex flex-grow-1"
                                name="rawJSON"
                                [(ngModel)]="fluxOcr.rawJSON"
                                [options]="codeMirrorOptions"
                            ></ngx-codemirror>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div class="col-lg-5 thumb-wrapper" [ngSwitch]="thumbnail.type">
                    <thumb *ngSwitchCase="ThumbnailType.IMAGE" [file]="thumbnail.file"></thumb>
                    <span *ngSwitchCase="ThumbnailType.PDF" class="material-icons-outlined">picture_as_pdf</span>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
