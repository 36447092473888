import { Component,Input } from '@angular/core';

@Component({
	selector: 'referentiel-externe-item',
	templateUrl: './referentiel-externe-item.component.html'
})
export class ReferentielExterneItemComponent {
	/** Référence externe **/
	@Input() referenceExterne: any;

	/** Enfants de la référence externe **/
	@Input() listeChildrenReferenceExterne?: Array<any> | null;

	/** Indicateur d'enfant **/
	@Input() isChild: boolean = false;

	/** Indicateur d'ouverture **/
	public isOpened: boolean = false;

	/**
	 * Constructeur
	 */
	constructor() {}

	/**
	 * Ouverture / Fermeture de l'élément
	 */
	toggleItem() {
		//Mise à jour de l'indicateur
		this.isOpened = !this.isOpened;
	}
}
