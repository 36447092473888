import {Injectable} from "@angular/core";
import * as moment from "moment";

/**
 * Service utilitaire sur les dates
 */
@Injectable()
export class DateUtilsService {

    /**
     * Comparaison entre 2 dates. Les dates peuvent être des objets Moment, Date ou tout autre valeur acceptée par le constructeur de Moment.
     *
     * @param d1 Date 1
     * @param d2 Date 2
     * @return -1 Si d1 < d2, 0 si d1 == d2, 1 si d1 > d2
     */
    public static compareDates(d1: any, d2: any): number {
        //Obtention d'objets moment
        const m1 = moment.isMoment(d1) ? d1 : moment(d1);
        const m2 = moment.isMoment(d2) ? d2 : moment(d2);

        //Comparaison et retour du résultat
        if (m1.isBefore(m2)) return -1;
        else if (m1.isSame(m2)) return 0;
        else if (m1.isAfter(m2)) return 1;
        else return undefined;
    }

}
