import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ParticipantExterne,TypeExterne} from "@domain/participant/participant-externe";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant d'un item de la liste des externes
 */
@Component({
	host: {'data-test-id': 'utilisateurs-externes-list-item'},
	templateUrl: './utilisateurs-externes-list-item.component.html'
})
export class UtilisateursExternesListItemComponent extends ListViewItem<ParticipantExterne> {
	/** Options supplémentaires */
	@Input() extraOptions: {
		open: (externe: ParticipantExterne) => void
	}

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param confirmService le service de confirmation
	 * @param userService le service de gestion des utilisateurs
	 * @param toastrService le toaster
	 */
	constructor(
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService
	) {
		super();
	}

	/**
	 * Définition du titre de l'item
	 */
	getTitle(): string {
		//Si nom ou prénom est défini
		if (this.data.nom || this.data.prenom) {
			//On renvoie "nom prénom" (on trim au cas où l'un des deux n'est pas défini pour virer l'espace)
			return (`${this.data.nom} ${this.data.prenom}`).trim();
		} else {
			//Sinon, on renvoie "Non défini"
			return this.translateService.instant('admin.entreprise.utilisateurs.externes.nonDefini')
		}
	}

	/**
	 * Récupère le libellé de l'origine
	 */
	getLibelleOrigine(): string {
		//Switch sur le type
		switch (this.data.type) {
			case TypeExterne.ADMINISTRATEUR:
				return this.translateService.instant('admin.entreprise.utilisateurs.externes.administrateur');
			case TypeExterne.UTILISATEUR:
				return this.translateService.instant('admin.entreprise.utilisateurs.externes.utilisateur');
			default:
				return null;
		}
	}

	/**
	 * Copie de cet externe
	 */
	copy(): void {
		//On copie l'externe courant
		let externe: ParticipantExterne = Object.assign({},this.data);

		//On supprime l'ID et on remet à 0 le nom, le prénom et le téléphone
		delete externe.idParticipantExterne;
		externe.nom = "";
		externe.prenom = "";
		externe.telephone = "";

		//Ouverture de l'externe
		this.extraOptions.open(externe);
	}

	/**
	 * Suppression de cet externe
	 */
	delete(): void {
		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service
				this.userService.deleteExterne(this.data.idParticipantExterne)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du result
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Rafraîchissement de la liste
							this.liste.refresh();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}