import {TranslateService} from '@ngx-translate/core';

import {Filter, TypeFilter} from '../../../../domain/common/list-view';
import {AutocompleteOptions} from '../autocomplete';

/**
 * Options des fournisseurs
 */
export default class FournisseurOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     * @param filter filtre
     * @returns l'URL
     */
    url(filter: any): any {
        //URL
        return `/controller/Facture/listeFournisseurs`;
    }

    /**
     * Affichage d'un élément
     * @param item élément à afficher
     * @returns affichage
     */
    displayItem(item: any): any { return item?.raisonSociale || ''; }

    /**
     * Filtres disponibles
     * @param translateService service de traduction
     * @returns la liste des filtres
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.raisonSociale'),
            clef: 'raisonSociale',
            isDefault: true
        }]
    }

    /**
     * Liste des filtres statiques
     */
    listeStaticFilters(filter: any) {
        //Retour de la liste des filtres statiques
        return [{
            clef: 'isActif',
            valeur: 'true',
            type: TypeFilter[TypeFilter.BOOLEAN]
        }];
    }
}