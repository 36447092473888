import {Component,OnDestroy,OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {AppStatut,EtapeMajBdd,MigrationsService} from "@services/admin/maintenance/migrations.service";
import {first} from "rxjs/operators";
import {Subscription} from "rxjs";
import {KeyStatutApplication} from "@domain/admin/statut-application/statut-application";

/**
 * Composant d'affichage de la popup de migration
 */
@Component({
    host: {'data-test-id': 'log'},
    templateUrl: './migration-bdd-popup.component.html',
    styleUrls: ['migration-bdd-popup.component.scss']
})
export class MigrationBddPopupComponent implements OnInit,OnDestroy {

    /** Statut de l'application (migration) */
    appStatut: AppStatut;

    /** Etape en cours */
    etape: string;

    /** Progression */
    progression: number;

    /** Souscription */
    souscriptions: Subscription[] = [];

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean;

    /** Backup statut précédent */
    backupStatut: KeyStatutApplication = KeyStatutApplication.EN_LIGNE;

    /**
     * Constructeur
     */
    constructor(
        protected matDialogRef: MatDialogRef<MigrationBddPopupComponent>,
        private migrationsService: MigrationsService
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Abonnement à la mise à jour de la maintenance en cours
        this.souscriptions.push(this.migrationsService.isMaintenanceEnCours$.subscribe((isMaintenanceEnCours) => {
            //Mise à jour de la maintenance en cours
            this.isMaintenanceEnCours = isMaintenanceEnCours;
        }));

        //Abonnement à la mise à jour du statut (migration)
        this.souscriptions.push(this.migrationsService.appStatut$.subscribe((statut) => {
            //Demande de mise à jour du statut de l'appli
            this.migrationsService.checkAppliStatut();

            //Mise à jour du statut (migration)
            this.appStatut = statut;

            //Personnalisation de la progression
            if (statut.etape == EtapeMajBdd.UPGRADE_ETAPE_BUILD) {
                //Mise à jour de la progression
                this.progression = 25;

                //Mise à jour de l'étape
                this.etape = 'admin.maintenance.migrations.popup.1of4';
            } else if (statut.etape == EtapeMajBdd.UPGRADE_ETAPE_COMPARE) {
                //Mise à jour de la progression
                this.progression = 50;

                //Mise à jour de l'étape
                this.etape = 'admin.maintenance.migrations.popup.2of4';
            } else if (statut.etape == EtapeMajBdd.UPGRADE_ETAPE_GENERATE_SQL) {
                //Mise à jour de la progression
                this.progression = 75;

                //Mise à jour de l'étape
                this.etape = 'admin.maintenance.migrations.popup.3of4';
            } else if (statut.etape == EtapeMajBdd.UPGRADE_ETAPE_EXECUTE) {
                //Mise à jour de la progression
                this.progression = 100;

                //Mise à jour de l'étape
                this.etape = 'admin.maintenance.migrations.popup.4of4';
            }

            //Si la MAJ est terminée
            if (this.appStatut.isBddUpToDate || ((this.backupStatut == KeyStatutApplication.PRE_MIGRATION || this.backupStatut == KeyStatutApplication.MIGRATION) && statut.applicationStatutAdmin.statut != KeyStatutApplication.PRE_MIGRATION && statut.applicationStatutAdmin.statut != KeyStatutApplication.MIGRATION)) {
                //Fermeture de la popup
                this.matDialogRef.close();
            }

            //Sauvegarde du statut
            this.backupStatut = statut.applicationStatutAdmin.statut as KeyStatutApplication;
        }));
    }

    /**
     * Mise à jour de la base de données
     */
    upgradeBdd(): void {
        //Exécution de la MAJ puis rafraichissement du statut
        this.migrationsService.upgradeBdd().pipe(first()).subscribe(() => {
            //Demande de mise à jour du statut de l'appli
            this.migrationsService.checkAppliStatut();
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Parcours des souscriptions
        for (const sub of this.souscriptions) {
            //Désabonnement
            sub.unsubscribe();
        }
    }
}
