<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.title.' + (userContrat.idContrat ? 'modification' : 'creation')"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="libelle"
                    name="libelle"
                    [(ngModel)]="userContrat.libelle"
                    customType="input"
                    libelle="admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.libelle"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="dateDebut"
                    name="dateDebut"
                    customType="date"
                    [ngModel]="userContrat.dateDebut"
                    [(customModel)]="userContrat.dateDebut"
                    libelle="admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.dateDebut"
                    [dateMax]="userContrat.dateFin"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="reference"
                    name="reference"
                    [(ngModel)]="userContrat.reference"
                    customType="input"
                    libelle="admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.reference">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="dateFin"
                    name="dateFin"
                    customType="date"
                    [ngModel]="userContrat.dateFin"
                    [(customModel)]="userContrat.dateFin"
                    libelle="admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.dateFin"
                    [dateMin]="userContrat.dateDebut"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="franchiseAller"
                    name="franchiseAller"
                    customType="number"
                    [(ngModel)]="userContrat.franchiseAller"
                    libelle="admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.franchiseAller"
                    [nbDecimales]="2"
                    suffix="km">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="franchiseAllerRetour"
                    name="franchiseAllerRetour"
                    customType="number"
                    [(ngModel)]="userContrat.franchiseAllerRetour"
                    libelle="admin.entreprise.utilisateurs.detail.metier.userContratDetailPopin.franchiseAllerRetour"
                    [nbDecimales]="2"
                    suffix="km">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="warn" *ngIf="userContrat.idContrat" (click)="onDelete()" [disabled]="isPending"><span [translate]="'global.actions.supprimer'"></span></button>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="form?.invalid || isPending"><span [translate]="'global.actions.enregistrer'"></span></button>
</div>