import { NgModule } from '@angular/core';


import { CoreComponentsModule } from '../core-components.module';
import { PleaseWaitComponent } from './please-wait.component';
import { PleaseWaitDialogComponent } from './please-wait-dialog.component';
import { PleaseWaitService } from './please-wait.service';

@NgModule({
    imports: [CoreComponentsModule],
    declarations: [PleaseWaitComponent,PleaseWaitDialogComponent],
    exports: [PleaseWaitComponent,PleaseWaitDialogComponent],
    entryComponents: [PleaseWaitDialogComponent],
    providers: [PleaseWaitService]
})
export class PleaseWaitModule {}