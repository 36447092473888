<h1 mat-dialog-title>
    <span>{{ (this.data.options?.title ? this.data.options.title : 'confirmation.title') | translate }}</span>
</h1>
<div mat-dialog-content style="white-space:pre-line">
    <span *ngIf="message">{{message}}</span>
    <ng-container *ngIf="templateRef"><ng-container *ngTemplateOutlet="templateRef"></ng-container></ng-container>
</div>
<div mat-dialog-actions>
    <button *ngIf="!data.options || data.options.type !== 'ok'" mat-stroked-button class="m-r-10" [mat-dialog-close]="false">{{ boutonAnnuler | translate }}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">{{ boutonConfirmer | translate }}</button>
</div>
