import {Component,OnInit} from '@angular/core';

import {ListViewItem} from '@domain/common/list-view/list-view-item';
import {EntityAttribute} from './options/entity-attribute';
import {TranslateService} from "@ngx-translate/core";
import {ListItem} from "@domain/common/list-view/list-item";
import {DatePipe} from "@angular/common";
import {MontantPipe} from "@share/pipe/montant";

@Component({
    templateUrl: './autocomplete-search-item.component.html'
})
export class AutocompleteSearchItemComponent<T extends ListItem> extends ListViewItem<T> implements OnInit {
    /** Liste des propriétés pour lesquelles il faut afficher un badge 'clé:valeur' **/
    attributes: Array<EntityAttribute>;

    constructor(public translateService: TranslateService,public datePipe: DatePipe,public montantPipe: MontantPipe) {
        super();
    }

    ngOnInit() {
        this.attributes = this.extraOptions?.listDisplayedAttributes ? this.extraOptions?.listDisplayedAttributes(this.data,this.translateService) : null;
    }
}
