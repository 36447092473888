import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Type d'un véhicule (référentiel de type véhicule)
 */
export class TypeVehicule implements ListItem {
    idReferentiel: number;
    libelle?: string;
    code?: string;
    ordre?: number;
    type?: string;
    icone?: string;
    readOnly?: boolean = false;
    defaut?: boolean = false;

    getKey(): number {
        return this.idReferentiel;
    }

    /**
     * Constructeur
     *
     * @param dto DTO ou objet source à copier
     */
    constructor(dto?: any | TypeVehicule) {
        if (dto) {
            //Copie
            Object.assign(this, dto);
        }
    }
}