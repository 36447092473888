<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.reporting.infosReporting.parametres.edition.' + (isCreation ? 'creation' : 'modification')"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input name="type"
                          id="type"
                          libelle="admin.bibliotheque.reporting.infosReporting.parametres.type.title"
                          lCol="2"
                          rCol="4"
                          customType="select"
                          [selectOptions]="typeOptions"
                          optionValue="type"
                          optionDisplay="libelle"
                          (onChange)="onTypeChange()"
                          [(ngModel)]="reportParam.type"
                          ngDefaultControl
                          [required]="true">
            </custom-input>
            <custom-input ngDefaultControl
                          id="obligatoire"
                          name="obligatoire"
                          [ngModel]="reportParam.obligatoire"
                          [(customModel)]="reportParam.obligatoire"
                          customType="checkbox"
                          lCol="2"
                          rCol="4"
                          libelle="admin.bibliotheque.reporting.infosReporting.parametres.obligatoire.title">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl
                          id="libelle"
                          name="libelle"
                          [(ngModel)]="reportParam.libelle"
                          customType="input"
                          lCol="2"
                          rCol="4"
                          libelle="admin.bibliotheque.reporting.infosReporting.parametres.libelle"
                          [required]="true">
            </custom-input>
            <custom-input ngDefaultControl
                          id="name"
                          name="name"
                          [(ngModel)]="reportParam.name"
                          customType="input"
                          lCol="2"
                          rCol="4"
                          libelle="admin.bibliotheque.reporting.infosReporting.parametres.nom"
                          [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl
                          id="commentaire"
                          name="commentaire"
                          [(ngModel)]="reportParam.commentaire"
                          customType="input"
                          lCol="2"
                          rCol="10"
                          libelle="admin.bibliotheque.reporting.infosReporting.parametres.commentaire">
            </custom-input>
        </div>
        <div class="row" *ngIf="reportParam.type == ReportParamType.TYPE_LISTE_CHOIX_UNIQUE || reportParam.type == ReportParamType.TYPE_LISTE_CHOIX_MULTIPLE">
            <custom-input ngDefaultControl
                          id="sql"
                          name="sql"
                          [(ngModel)]="reportParam.sql"
                          customType="textarea"
                          lCol="2"
                          rCol="10"
                          libelle="admin.bibliotheque.reporting.infosReporting.parametres.sql"
                          [required]="true">
            </custom-input>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary"
                    (click)="save()"
                    [disabled]="form.invalid">
                <span [translate]="'global.actions.enregistrer'"></span>
            </button>
        </div>
    </form>
</div>
