import {ListItem} from "@domain/common/list-view/list-item";
import {Unite} from "@domain/vehicule/unite";

/**
 * Item de la liste des puissances fiscales
 */
export class PuissanceFiscale implements ListItem {
    idPuissance?: number;
    libelle?: string;
    idUnite?: number;
    unite?: Unite;

    getKey(): number {
        return this.idPuissance;
    }

    /**
     * Constructeur
     *
     * @param dto DTO ou objet source à copier
     */
    constructor(dto?: any | PuissanceFiscale) {
        if (dto) {
            //Copie
            Object.assign(this, dto);
        }
    }
}