<mat-card id="blocAnalytique">
    <mat-card-title>
        <span [ngClass]="{ 'error': !isValid() }">
            <mat-icon class="m-r-10" *ngIf="!isValid()" [matTooltip]="'analytique.repartitionInvalide' | translate">error_outline</mat-icon>
            <span [translate]="'analytique.title'"></span>
        </span>
        <ng-container *ngIf="canModifier">
            <span class="actions" *ngIf="canSaisie && (!settings?.nbRepartitionsMax || listeAnalytiques.length < settings?.nbRepartitionsMax)">
                <mat-icon (click)="showLigne()">add</mat-icon>
            </span>            
        </ng-container>        
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item" *ngFor="let analytique of listeAnalytiques; let idxAnalytique = index;">
                    <div class="lvi-content">
                        <div *ngIf="listeAnalytiques.length > 1" class="avatar">{{ analytique.pourcentage | number }}</div>
                        <div *ngIf="listeAnalytiques.length <= 1" class="avatar">{{ analytique.orga.numService | slice: 0:3 }}</div>
                        <div class="lvi-body">
                            <div class="title">
                                <a [routerLink]="" (click)="canModifier && canSaisie && showLigne(analytique,idxAnalytique)">{{ (analytique.orga.numService ? (analytique.orga.numService + ' - ') : '') + analytique.orga.libelle + (analytique.orga.code ? (' (' + analytique.orga.code + ')') : '') }}</a>
                            </div>
                            <ul class="lvi-attrs">
                                <li *ngIf="typeEntite.codeProjet">
                                    <strong [translate]="'analytique.dossier'"></strong>
                                    <span *ngIf="analytique.dossier">{{ (analytique.dossier.reference ? (analytique.dossier.reference + ' - ') : '') + analytique.dossier.libelle + (analytique.code1 ? (' (' + analytique.code1 + ')') : '') }}</span>
                                    <span *ngIf="!analytique.dossier" [translate]="'liste.nonRenseigne'"></span>
                                </li>
                                <li *ngIf="typeEntite.axe5">
                                    <strong>{{ settings?.libelleAxe5 || ('analytique.axe5' | translate) }}</strong>
                                    <span *ngIf="analytique.axe5">{{ (analytique.axe5.reference ? (analytique.axe5.reference + ' - ') : '') + analytique.axe5.libelle + (analytique.code1 ? (' (' + analytique.code1 + ')') : '') }}</span>
                                    <span *ngIf="!analytique.axe5" [translate]="'liste.nonRenseigne'"></span>
                                </li>
                                <li *ngIf="typeEntite.axe6">
                                    <strong>{{ settings?.libelleAxe6 || ('analytique.axe6' | translate) }}</strong>
                                    <span *ngIf="analytique.axe6">{{ (analytique.axe6.reference ? (analytique.axe6.reference + ' - ') : '') + analytique.axe6.libelle + (analytique.code1 ? (' (' + analytique.code1 + ')') : '') }}</span>
                                    <span *ngIf="!analytique.axe6" [translate]="'liste.nonRenseigne'"></span>
                                </li>
                                <li *ngIf="typeEntite.field5 && getFieldParam('OPERATION_1') as operation">
                                    <strong>{{ operation.libelle }}</strong>
                                    <span *ngIf="analytique.code1">{{ analytique.code1 }}</span>
                                    <span *ngIf="!analytique.code1" [translate]="'liste.nonRenseigne'"></span>
                                </li>
                                <li *ngIf="typeEntite.field6 && getFieldParam('OPERATION_2') as operation">
                                    <strong>{{ operation.libelle }}</strong>
                                    <span *ngIf="analytique.code2">{{ analytique.code2 }}</span>
                                    <span *ngIf="!analytique.code2" [translate]="'liste.nonRenseigne'"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="empty" *ngIf="!listeAnalytiques?.length"><span [translate]="'liste.noData'"></span></div>
            </div>
        </div>
    </mat-card-content>
</mat-card>