import {Component,Input} from '@angular/core';
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Composant d'affichage de l'onglet outils (admin)
 *
 * @author Angeline Ha
 * @date 30/08/2023
 */
@Component({
	host: {'data-test-id': 'admin-outils'},
	selector: 'outils',
	templateUrl: './admin-outils.component.html'
})
export class AdminOutilsComponent {

	/** Accès à l'enum dans la vue */
	readonly TypePortee = TypePortee;

	/** Identifiant de l'objet */
	@Input() idObjet: number;

	/** Type de l'objet wf */
	@Input() typePortee: TypePortee;

	/** Validité de l'objet */
	@Input() isObjetValide: boolean;
}