<fieldset ngModelGroup="adresse-generalites">
<!-- Cadre Généralités -->
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.adresse.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="voie"
                                  name="voie"
                                  [(ngModel)]="adresse.rue"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.voie"
                                  [required]="true">
                    </custom-input>
                    <custom-input
                            ngDefaultControl
                            id="libelle"
                            name="libelle"
                            [(ngModel)]="adresse.libelle"
                            customType="input"
                            libelle="admin.bibliotheque.geographie.adresse.libelle">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="ville"
                                  name="ville"
                                  [idName]="'id'"
                                  optionValue="id"
                                  (onChange)="onVilleChange();"
                                  [(ngModel)]="selectedVille"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  optionDisplay="libelle"
                                  [autocompleteFilter]="{listeTypes: ['VILLE']}"
                                  libelle="admin.bibliotheque.geographie.adresse.ville"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="code"
                                  name="code"
                                  [(ngModel)]="adresse.code"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.code">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="codePostal"
                                  name="codePostal"
                                  [(ngModel)]="adresse.codePostal"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.cp">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="complement"
                                  name="complement"
                                  [(ngModel)]="adresse.complement"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.complement">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="pays"
                                  name="pays"
                                  [idName]="'id'"
                                  optionValue="id"
                                  (onChange)="onVilleChange();"
                                  [(ngModel)]="selectedPays"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  optionDisplay="libelle"
                                  [autocompleteFilter]="{listeTypes: ['PAYS']}"
                                  libelle="admin.bibliotheque.geographie.adresse.pays"
                                  [disabled]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="latitude"
                                  name="latitude"
                                  [(ngModel)]="adresse.latitude"
                                  [nbDecimales]="8"
                                  customType="number"
                                  libelle="admin.bibliotheque.geographie.adresse.latitude">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="region"
                                  name="region"
                                  [idName]="'id'"
                                  optionValue="id"
                                  (onChange)="onVilleChange();"
                                  [(ngModel)]="selectedRegion"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  optionDisplay="libelle"
                                  [autocompleteFilter]="{listeTypes: ['REGION_ADMINISTRATIVE']}"
                                  libelle="admin.bibliotheque.geographie.adresse.region"
                                  [disabled]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="longitude"
                                  name="longitude"
                                  [(ngModel)]="adresse.longitude"
                                  [nbDecimales]="8"
                                  customType="number"
                                  libelle="admin.bibliotheque.geographie.adresse.longitude">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="departement"
                                  name="departement"
                                  [idName]="'id'"
                                  optionValue="id"
                                  (onChange)="onVilleChange();"
                                  [(ngModel)]="selectedDepartement"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  optionDisplay="libelle"
                                  [autocompleteFilter]="{listeTypes: ['DEPARTEMENT_ADMINSITRATIF']}"
                                  libelle="admin.bibliotheque.geographie.adresse.departement"
                                  [disabled]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="visibilite"
                                  name="visibilite"
                                  [(ngModel)]="adresse.visibilites"
                                  (ngModelChange)="onVisibilitesChange()"
                                  customType="multiselect"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  libelle="admin.bibliotheque.geographie.adresse.visibilite"
                                  [selectOptions]="listeVisibilitesMultiselect">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>

<!-- Cadre Compléments -->
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.adresse.complements.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="attribut1"
                                  name="attribut1"
                                  [(ngModel)]="adresse.attribut1"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.complements.attribut1">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="attribut2"
                                  name="attribut2"
                                  [(ngModel)]="adresse.attribut2"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.complements.attribut2">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="attribut3"
                                  name="attribut3"
                                  [(ngModel)]="adresse.attribut3"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.complements.attribut3">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="attribut4"
                                  name="attribut4"
                                  [(ngModel)]="adresse.attribut4"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.adresse.complements.attribut4">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>

<!-- Cadre Propriétaires -->
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="listview">
                <div class="listview-body">
                    <div class="listview-item">
                        <div class="lvi-content">
                            <div class="avatar">
                                <i class="nio icon-collaborateurs"></i>
                            </div>
                            <div class="lvi-body">
                                <div class="title">
                                    <ng-container [ngSwitch]="adresse.origine">
                                        <a *ngSwitchCase="0" class="label" [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.administrateur'"></a>
                                        <a *ngSwitchCase="1" class="label" [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.administrateur'"></a>
                                        <ng-container *ngSwitchCase="2">
                                            <a class="label">{{ (adresse.user?.nom?.toUpperCase() + ' ' + adresse.user?.prenom).trim() }}</a>
                                            <ul class="lvi-attrs">
                                                <li *ngIf="adresse.user?.matricule"><strong [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.matricule'"></strong>{{ adresse.user?.matricule }}</li>
                                            </ul>
                                        </ng-container>
                                        <a *ngSwitchCase="3" class="label" [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.import'"></a>
                                        <a *ngSwitchCase="4" class="label" [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.distance'"></a>
                                        <a *ngSwitchDefault class="label" [translate]="'admin.bibliotheque.geographie.adresse.proprietaires.administrateur'"></a>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</fieldset>