<div class="with-floating-button">
    <div class="content" *ngIf="departement">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.geographie.departement.title' | translate: {libelle: departement.libelle}" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.departement.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="departement">
                <departement-generalites [departement]="departement"></departement-generalites>
            </form>
        </div>
        <floating-button [listeActions]="listeActions"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!departement"></please-wait>
</div>