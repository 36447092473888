/**
 * (géo)localisation
 */
export interface Localisation {
    id?: number;
    idAdresse?: number;
    idUser?: number;
    adresse?: string;
    pays?: string;
    region?: string;
    departement?: string;
    ville?: string;
    localite?: string;
    rue?: string;
    numero?: string;
    codePostal?: string;
    longitude?: number;
    latitude?: number;
    codePays?: string;
    codePays3?: string;
    idPays?: number;
    origine?: Origine;
    listeVisibilite?: Array<Visibilite>;

    //flag hors entity, utilisé seulement pour afficher le sous-bloc
    isDisplayed?: boolean;
    //Libellé affiché dans l'autocomplete
    libelle?:string;
}

/** Origine des adresses ({@link com.notilus.data.geographie.Adresse}) */
export enum Origine {
    ORIGINE_ADMIN = 1,
    ORIGINE_USER = 2,
    ORIGINE_IMPORT = 3,
    ORIGINE_DISTANCE = 4,
}

/** Filtres de visibilités ({@link com.notilus.data.geographie.Adresse}) */
export enum Visibilite {
    FILTRE_V_NF = 1,
    FILTRE_V_HE = 2,
    FILTRE_V_TV = 3,
    FILTRE_V_TV_PERSO = 35,
    FILTRE_V_TV_PRO = 36,
    FILTRE_V_TV_LOC = 37,
    FILTRE_V_TV_TAXI = 38,
    FILTRE_V_TV_AUTRE = 39,
    FILTRE_V_ADM = 4,
    FILTRE_V_PROFIL = 5,
}
