import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {EnveloppeDto} from '@domain/admin/entreprise/analytique/enveloppeDto';
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {AlerteComponent} from "@components/workflow/alerte.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {SettingsGlobalState} from "@domain/settings/settings";

/**
 * Item de la liste d'enveloppes
 *
 * @author Tom JEAMMET
 * @date 23/02/2023
 */
@Component({
    host: {'data-test-id': 'enveloppe-item'},
    selector: 'enveloppe-item',
    templateUrl: './enveloppe-item.component.html'
})
export class EnveloppeItemComponent extends ListViewItem<EnveloppeDto> {
    /** Extra options de la liste */
    extraOptions: {
        settings: SettingsGlobalState
    }

    /** Niveau d'alerte */
    readonly NiveauAlerte = NiveauAlerte;

    /**
     * Constructeur
     *
     * @param router Router de l'application
     * @param matDialog le gestionnaire de popin
     * @param translateService le moteur de traduction
     */
    constructor(
        private router: Router,
        private matDialog: MatDialog,
        private translateService: TranslateService
    ) {
        super();
    }

    /**
     * Méthode appelée lors de la sélection d'une enveloppe budgétaire
     */
    onSelect() {
        //Navigation vers la page d'informations du référentiel
        this.router.navigate(['Admin/Entreprise/Analytique/Enveloppes/' + this.data.idEnveloppe]);
    }

    /**
     * Rétourne le titre de l'item
     * @return le titre
     */
    getTitle(): string {
        let title = "";
        if (!!this.data.reference) {
            title = this.data.reference + " - ";
        }

        title += this.data.libelle;

        return title;
    }

    /**
     * Affichage de la popin de la liste des alertes
     */
    showAlerte(): void {
        let pourcentage: number;

        //Calcul du pourcentage à afficher
        if (this.data.niveauAlerte === NiveauAlerte.WARNING) {
            pourcentage = this.data.alerteEdited ? (this.data.pourcentageNonBloquant || this.extraOptions.settings.budgetNiveauNonBloquant) : this.extraOptions.settings.budgetNiveauNonBloquant;
        } else {
            pourcentage = this.data.alerteEdited ? (this.data.pourcentageBloquant || this.extraOptions.settings.budgetNiveauBloquant) : this.extraOptions.settings.budgetNiveauBloquant;
        }

        //Création de l'alerte
        const alerte: Alerte = new Alerte({
            niveau: this.data.niveauAlerte,
            titre: this.translateService.instant('workflow.notification.alerte.title.warning'),
            message: this.translateService.instant('admin.entreprise.analytique.enveloppes.erreurConso',{'pourcentage': pourcentage})
        });

        //Affichage de la popin de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: [alerte]
            }
        });
    }
}