<div class="organigramme-container">
    <div class="organigramme-row">
        <div class="list-view-item-hover">
            <div class="lvi-content">
                <div class="avatar">
                    <span>{{ orga.libelle | slice: 0:3 }}</span>
                </div>
                <div class="lvi-body" (click)="showChild()">
                    <div class="title">
                        <a class="label" (click)="onSelect()">{{ orga.libelle }}</a>
                    </div>
                    <ul class="lvi-attrs">
                        <li *ngIf="orga.code">
                            <strong [translate]="'admin.entreprise.analytique.organigramme.code'"></strong>
                            <span>{{ orga.code }}</span>
                        </li>
                        <li>
                            <strong [translate]="'admin.entreprise.analytique.organigramme.affectable'"></strong>
                            <span>{{ (orga.affectable ? 'global.oui' : 'global.non' ) | translate }}</span>
                        </li>
                        <li>
                            <strong [translate]="'admin.entreprise.analytique.organigramme.imputable'"></strong>
                            <span>{{ (orga.imputable ? 'global.oui' : 'global.non' ) | translate }}</span>
                        </li>
                        <li>
                            <strong [translate]="'admin.entreprise.analytique.organigramme.actif'"></strong>
                            <span>{{ (orga.isActif ? 'global.oui' : 'global.non' ) | translate }}</span>
                        </li>
                    </ul>
                </div>
                <div class="lvi-actions">
                    <button mat-icon-button *ngIf="orga.nbChildren > 0">
                        <mat-icon *ngIf="isOuvert" color="primary" [matTooltip]="'od.voyage.replier' | translate" (click)="showChild()">keyboard_arrow_up</mat-icon>
                        <mat-icon *ngIf="!isOuvert" color="primary" [matTooltip]="'od.voyage.deplier' | translate" (click)="showChild()">keyboard_arrow_down</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isOuvert && listeSousOrga && listeSousOrga.length > 0" [ngStyle]="{'padding': '10px 0px'}">
            <analytique-organigramme-item-sous-orga *ngFor="let sousOrga of listeSousOrga" [niveau]="niveau + 1" [orga]="sousOrga"></analytique-organigramme-item-sous-orga>
        </div>
    </div>
</div>
