import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {first,tap} from "rxjs/operators";
import {BehaviorSubject,Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {LotArchivage} from "@domain/demat/lot-archivage";
import {PieceJointeParam,TypeEntiteParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {TypeDocument} from "@domain/admin/parametre/demat/type-document";
import * as moment from "moment";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";
import {LotListItem,Periode} from "@domain/comptabilite/lot";

/**
 * Service de gestion de la dématérialisation à valeur probante.
 *
 * @author François Turin
 * @date 16/06/2023
 */
@Injectable()
export class DematService {
	/** Les lots dont la période est antérieure au 01/01/2019 ne doivent pas pouvoir être archivés */
	private static readonly dateLimiteArchivage: moment.Moment = moment('2019-01-01');

	/** Indique si un lot est en attente de synchronisation */
	private _hasLotEnAttente: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public hasLotEnAttente$: Observable<boolean> = this._hasLotEnAttente.asObservable();

	/** Indique si la licence ARCHIVAGE est présente */
	private _isDematPresent: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param http pour permettre de réaliser les requêtes
	 */
	constructor(private http: HttpClient) {
	};

	/**
	 * Permet d'afficher un message d'information, sous les onglets, si un lot est en attente de synchronisation.
	 *
	 * @param hasLotEnAttente true si un lot est en attente de synchro
	 */
	set hasLotEnAttente(hasLotEnAttente: boolean) {
		this._hasLotEnAttente.next(hasLotEnAttente);
	}

	/**
	 * @return true si un lot est en attente de synchronisation.
	 */
	get hasLotEnAttente(): boolean {
		return this._hasLotEnAttente.getValue();
	}

	get isDematPresent(): boolean {
		return this._isDematPresent;
	}

	/**
	 * Chargement du statut du module Dématérialisation.
	 */
	loadStatutDemat(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/consultStatut`,[])
			.pipe(first(),tap(result => {
				this._isDematPresent = !!result.data.licence.ARCHIVAGE;
			}));
	};

	/**
	 * Teste la connexion au DematHub et à Azure Blob Storage.
	 */
	tester(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/testConnexion`,[]).pipe(first());
	}

	/**
	 * Indique si le lot peut être archivé.
	 */
	isArchivageAutorise(lot: LotArchivage | LotListItem): boolean {
		return (!lot.statutArchivage || lot.statutArchivage === StatutArchivage.ECHEC) && this.isDateLimiteDepassee(lot);
	}

	/**
	 * Indique si le lot est lié à une période budgétaire postérieure au 01/01/2019.
	 */
	isDateLimiteDepassee(lot: LotArchivage | LotListItem): boolean {
		if (this.isLotArchivage(lot)) {
			return moment(lot.dateDebut).isSameOrAfter(DematService.dateLimiteArchivage);
		} else {
			return moment((lot.periode as Periode).dateDebut).isSameOrAfter(DematService.dateLimiteArchivage);
		}
	}

	/**
	 * Vérifie si l'objet représentant un lot est de type LotArchivage
	 *
	 * @param lot Objet à vérifier
	 */
	isLotArchivage(lot : LotArchivage | LotListItem): lot is LotArchivage {
		return (lot as LotArchivage) !== undefined;
	}

	/**
	 * Envoi des lots pour Demat.
	 *
	 * @param listeLots liste des lots à synchroniser
	 */
	archiverLots(listeLots : Array<LotArchivage|LotListItem>): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/synchroniserLots`,listeLots.map(lot => lot.idLot)).pipe(first());
	}

	/**
	 * Envoi d'un lot pour Demat.
	 *
	 * @param idLot id du lot à synchroniser
	 */
	archiverLot(idLot: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/DematHubConfig/synchroniserLots`,[idLot]).pipe(first());
	}

	/**
	 * Ajoute une extension
	 * @param extensionName le nom de l'extension
	 */
	addExtension(extensionName: string): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/PieceJointe/addExtension`, extensionName).pipe(first());
	}

	/**
	 * Supprime une extension
	 * @param idExtension l'id de l'extension
	 */
	deleteExtension(idExtension: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/PieceJointe/deleteExtension/${idExtension}`).pipe(first());
	}

	/**
	 * Chargement des paramètres des pièces jointes
	 */
	loadPieceJointeParams(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/PieceJointe/loadParams`,null).pipe(first());
	}

	/**
	 * Sauvegarde des paramètres des pièces jointes
	 */
	savePieceJointeParams(listePieceJointeParams: PieceJointeParam[], listePieceJointePath: string): Observable<Result> {
		const body = {
			pieceJointeParams: listePieceJointeParams,
			pieceJointeLocalPath: listePieceJointePath
		}

		return this.http.put<Result>(`${environment.baseUrl}/controller/PieceJointe/saveParams`,body).pipe(first());
	}

	/**
	 * Sauvegarde des types entités liés aux pièces jointes
	 *
	 * @param idPortee Identifiant de la portée
	 * @param listeTypeEntiteParamsToSave Liste des id des éléments à sauvegarder
	 * @param listeTypeEntiteParamsToDelete Liste des id des éléments à supprimer
	 */
	saveTypeEntiteParam(idPortee: string,listeTypeEntiteParamsToSave: number[],listeTypeEntiteParamsToDelete: number[]) {
		return this.http.put<Result>(`${environment.baseUrl}/controller/PieceJointe/saveTypeEntite/${idPortee}`,[listeTypeEntiteParamsToSave,listeTypeEntiteParamsToDelete]).pipe(first());
	}

	/**
	 * Suppression d'un filtre pièce-jointe
	 */
	deleteFiltrePieceJointe(typeEntiteParam: TypeEntiteParam): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/PieceJointe/deleteTypeEntite/${typeEntiteParam.idPortee}/${typeEntiteParam.idTypeEntite}`).pipe(first());
    }

	/**
	 * Sauvegarde le typeDocument
	 * @param typeDocument le typeDocument à sauvegarder
	 * @return un observable avec un result
	 */
	saveTypeDocument(typeDocument: TypeDocument): Observable<Result> {
		return this.http.put<Result>(`${environment.baseUrl}/controller/PieceJointe/saveTypeDocument`, typeDocument) .pipe(first());
	}

	/**
	 * Sauvegarde le typeDocument
	 * @param id l'identifiant à effacer
	 * @return un observable avec un result
	 */
	deleteTypeDocument(id: number): Observable<Result> {
		return this.http.delete<Result>(`${environment.baseUrl}/controller/PieceJointe/deleteTypeDocument/${id}`).pipe(first());
	}

	/**
	 * Chargement du détail de l'archivage du lot
	 *
	 * @param idLot Identifiant du lot pour lequel récupérer le détail de l'archivage
	 */
	getLotArchivage(idLot: number) {
		return this.http.post<Result<{lotArchivage: LotArchivage}>>(`${environment.baseUrl}/controller/DematHubConfig/loadLotArchivage/${idLot}`,null).pipe(first());
	}

	/**
	 * Vérifie la présence d'un lot en cours (= en attente) de synchronisation
	 */
	checkHasLotEnAttente() {
		return this.http.post<Result<{hasLotEnAttente: boolean}>>(`${environment.baseUrl}/controller/DematHubConfig/hasLotEnAttente`,null)
			.subscribe(result => {
				this.hasLotEnAttente = result.data.hasLotEnAttente;
			});
	}
}
