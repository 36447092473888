import {Component,Inject,OnInit} from '@angular/core';
import {OdPaysRisque, ODService} from "@components/od/od.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

/**
 * Composant de la popup affichant le détail des pays à risque d'un OD
 *
 * @author Angeline Ha
 * @date 30/08/2023
 */
@Component({
	host: {'data-test-id': 'popup-detail-pays-risque'},
	templateUrl: './popup-detail-pays-risque.component.html'
})
export class PopupDetailPaysRisqueComponent implements OnInit{
	/** Indicateur de chargement */
	isLoading: boolean = false;

	/** Liste des pays à risque liés */
	listePays: OdPaysRisque[];

	/**
	 * Constructeur
	 *
	 * @param odService Service de l'OD
	 * @param data
	 */
	constructor(private odService: ODService,
				@Inject(MAT_DIALOG_DATA) public data: {idObjet: number}) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de la liste des pays à risque liés à cet od
		this.odService.getPaysRisque(this.data.idObjet).subscribe( listePays => this.listePays = listePays);
	}

}
