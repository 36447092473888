import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Prestation} from "@domain/prestation/prestation";
import {PrestationListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-filtres/prestations-autorisees-popin/prestation-list-item/prestation-list-item.component";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Result} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant de la popin de visualisation des prestations autorisées
 */
@Component({
	host: {'data-test-id': 'prestations-autorisees-popin'},
	templateUrl: './prestations-autorisees-popin.component.html',
	styles: [
		'::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
		'::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
	]
})
export class PrestationsAutoriseesPopinComponent implements OnInit {
	/** Liste des prestations autorisées */
	listePrestation: ListView<Prestation,PrestationListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la ref à la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idUser: number },
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<PrestationsAutoriseesPopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listePrestation = new ListView<Prestation,PrestationListItemComponent>({
			uri: `/controller/User/getListePrestationsAutorisees/${this.data.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.filtres.prestationsAutoriseesPopin.title'),
			component: PrestationListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listePrestation,
			listeActions: [
				{
					icon: 'close',
					onPress: () => this.matDialogRef.close()
				}
			]
		});
	}
}
