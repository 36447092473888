<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons">phone_iphone</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.manufacturer }} {{ data.model }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.mobilite.listeDevices.deniereConnexion'"></strong>
                <span>{{ data.lastCheckDate | date:'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.mobilite.listeDevices.actif'"></strong>
                <span [translate]="'confirmation.' + (data.actif ? 'oui' : 'non')"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button *ngIf="data.actif" mat-menu-item (click)="switchActivation()" translate="admin.entreprise.utilisateurs.detail.mobilite.listeDevices.desactiver"></button>
            <button *ngIf="!data.actif" mat-menu-item (click)="switchActivation()" translate="admin.entreprise.utilisateurs.detail.mobilite.listeDevices.activer"></button>
            <button mat-menu-item (click)="delete()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>