import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {Filter} from "@domain/common/list-view";
import {DatePipe} from "@angular/common";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";
import {EnveloppeLineItemComponent} from "@share/component/autocomplete/options/enveloppe-line-item";
import {EnveloppeSearchItemComponent} from "@share/component/autocomplete/options/enveloppe-search-item";
import {MontantPipe} from "@share/pipe/montant";

/**
 * Options des dossiers
 */
export default class EnveloppeOptions implements AutocompleteOptions {
    /**
     * Récupération de l'URL
     */
    url(filter: any): string {
        //URL
        return `/controller/Budget/listeEnveloppeReaffectation?idMontant=${filter?.idMontant}`
    }

    /**
     * Composant d'affichage d'une ligne dans la popin de recherche
     */
    searchItemComponent() {
        return EnveloppeSearchItemComponent;
    }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() {
        return EnveloppeLineItemComponent;
    }

    /**
     * Affichage d'un élément
     */
    displayItem(item: MontantDto,translateService: TranslateService,datePipe: DatePipe,montantPipe: MontantPipe): string {
        if (item == null) {
            return null;
        }

        return (`${item.enveloppe?.libelleEnveloppe} - ${datePipe.transform(item.exercice?.dateDebut,'shortDate')} - ${datePipe.transform(item.exercice?.dateFin,'shortDate')} - ${montantPipe.transform(item.montant,item.codeDevise)}`);
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.libelle'),
            clef: 'enveloppe.libelle',
            isDefault: true
        }]
    }
}