import {ListItem} from "@domain/common/list-view/list-item";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";

/**
 * Classe représentant une tâche de maintenance
 */
export class MaintenanceTask implements ListItem {
    idTask: number;
    taskName: string;
    displayName: string;
    date: Date;
    resultat: MaintenanceTaskResult;
    log: string;
    version: string;
    build: string;
    frequence: MaintenanceTaskFrequence;
    description: string;
    ordre: number;
    phase: MaintenanceTaskPhase;
    listeAlertes: ListeAlertes;

    constructor(dto?: any) {
        if (dto) {
            this.idTask = dto.idTask;
            this.taskName = dto.taskName;
            this.displayName = MaintenanceTask.trimName(dto.taskName);
            if (dto.date) this.date = new Date(dto.date);
            this.resultat = dto.resultat as MaintenanceTaskResult;
            this.log = dto.log;
            this.version = dto.version;
            this.build = dto.build;
            this.frequence = dto.frequence as MaintenanceTaskFrequence;
            this.description = dto.description;
            this.ordre = dto.ordre;
            this.phase = dto.phase as MaintenanceTaskPhase;
            if (this.resultat === MaintenanceTaskResult.FAIL) this.listeAlertes = { niveau: 2 } as ListeAlertes;
        }
    }

    getKey(): number {
        return this.idTask;
    }

    /**
     * Purge du nom de la tâche pour affichage
     *
     * @param taskName  nom complet de la tâche
     */
    static trimName(taskName: string): string {
        //Purge du nom
        return taskName?.replace('com.notilus.upgrader.task.','')?.replace('pre.','');
    }
}

/**
 * Enum représentant le résultat d'une tâche
 */
export enum MaintenanceTaskResult {
    SUCCESS = 0,
    FAIL = 1
}

/**
 * Enum représentant la fréquence d'une tâche
 */
export enum MaintenanceTaskFrequence {
    ONCE = 'ONCE',
    EVERY_TIME = 'EVERY_TIME'
}

/**
 * Enum représentant la phase d'une tâche
 */
export enum MaintenanceTaskPhase {
    PRE_TASK = 'PRE_TASK',
    POST_TASK = 'POST_TASK'
}
