import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot,CanActivate,Router,UrlTree} from '@angular/router';

import {LoginService} from '../../share/login/login.service';
import {LoginType} from "@domain/security/auth";
import {Observable} from "rxjs";

/**
 * Gardien pour l'accès via SSO
 */
@Injectable()
export class SsoGuardProvider implements CanActivate {
	/**
	 * Constructeur
	 */
	constructor(private router: Router,private loginService: LoginService) {

	}

	/**
	 * Activation du composant.
	 *
	 * @param route route qu'on veut atteindre, dont on vérifie le droit d'activation
	 */
	canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
		//Récupération du mode d'authentification
		return this.loginService.loadAuth().map(value => {
			//Si le mode d'authentification n'est pas de type SSO, on n'a rien à faire ici -> go login classique
			if (value?.loginType === LoginType.LOCAL) {
				//Renvoi de l'URL pour redirection vers l'écran de connexion
				return this.router.parseUrl('/Login');
			} else {
				//Sinon c'est OK
				return true;
			}
		});
	}
}
