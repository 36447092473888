import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {AnalytiqueAxe6ItemComponent} from "@components/admin/entreprise/analytique/axe6/analytique-axe6-item/analytique-axe6-item.component";
import {Axe6DTO} from "@domain/admin/entreprise/analytique/axe6Dto";
import {ListView, TypeComparaison, TypeFilter} from "@domain/common/list-view";
import {Sorting} from "@domain/common/list-view/sorting";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {BehaviorSubject} from "rxjs";

/**
 * Composant d'affichage de la liste des référentiels Axe 6
 */
@Component({
	host: {'data-test-id': 'analytique-axe6'},
	selector: 'analytique-axe6',
	templateUrl: './analytique-axe6.component.html'
})
export class AnalytiqueAxe6Component implements OnInit {
	/** Liste des référentiels Axe 6 */
	listeAxe6: ListView<Axe6DTO,AnalytiqueAxe6ItemComponent>;

	/** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param router le routeur Angular
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Création de la liste des référentiels
		this.listeAxe6 = new ListView<Axe6DTO,AnalytiqueAxe6ItemComponent>({
			uri: `/controller/Analytique/getListeAxe6Result`,
			title: this.translateService.instant('admin.entreprise.analytique.axe6.title'),
			component: AnalytiqueAxe6ItemComponent,
			isFilter: true,
			defaultOrder: 'reference',
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('admin.entreprise.analytique.axe6.libelle'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'reference',
					title: this.translateService.instant('admin.entreprise.analytique.axe6.reference'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'code1',
					title: this.translateService.instant('admin.entreprise.analytique.axe6.code1'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'code2',
					title: this.translateService.instant('admin.entreprise.analytique.axe6.code2'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'dateDebut',
					title: this.translateService.instant('admin.entreprise.analytique.axe6.dateOuverture'),
					isDefault: true,
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				},{
					clef: 'dateFin',
					title: this.translateService.instant('admin.entreprise.analytique.axe6.dateFermeture'),
					isDefault: true,
					type: TypeFilter[TypeFilter.DATE],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				}
			]
		});

		//Définition des colonnes de tri
		this.listeAxe6.columns = [
			{key: 'reference',title: 'admin.entreprise.analytique.axe6.reference'},
			{key: 'code1',title: 'admin.entreprise.analytique.axe6.code1'},
			{key: 'code2',title: 'admin.entreprise.analytique.axe6.code2'},
			{key: 'dateDebut',title: 'admin.entreprise.analytique.axe6.dateOuverture'},
			{key: 'dateFin',title: 'admin.entreprise.analytique.axe6.dateFermeture'}
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeAxe6.sorting = new Sorting(this.listeAxe6.columns,"reference");

		//Persistence des filtres
		this.listeAxe6.isPersistFilters = true;
		this.listeAxe6.className = 'AnalytiqueAxe6Component';

        //Initialisation des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.create()
        }]);
	}

	/**
	 * Méthode appelée lors de la création d'un nouveau service
	 */
	create() {
		this.router.navigate(['Admin/Entreprise/Analytique/Axe6/-1']);
	}
}
