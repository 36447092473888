/**
 * Classe représentant les caractéristiques d'un champ additionnel
 */
export interface Field {
    champ?: string;
    libelle?: string;
    masque?: string;
    tailleMinimale?: number;
    obligatoire?: boolean;
    actif?: boolean;
    fixe?: boolean;
}

/**
 * Classe représentant un objet utilisant les fields
 */
export class FieldContainer {
    field1: string;
    field2: string;
    field3: string;
    field4: string;
    
    /**
     * Constructeur
     * @param dto DTO contenant les attributs field1 ... field4
     */
    constructor(dto: any) {
        this.field1 = dto?.field1;
        this.field2 = dto?.field2;
        this.field3 = dto?.field3;
        this.field4 = dto?.field4;
    }
    
    /* Acesseurs */
    get myField1(): string { return this.field1; }
    get myField2(): string { return this.field2; }
    get myField3(): string { return this.field3; }
    get myField4(): string { return this.field4; }
    
    set myField1(field1: string) { this.field1 = field1; }
    set myField2(field2: string) { this.field2 = field2; }
    set myField3(field3: string) { this.field3 = field3; }
    set myField4(field4: string) { this.field4 = field4; }
}

/**
 * Classe permettant de gérer les "fields" d'un objet pour les zones prédéfinies.
 * Ce wrapper permet de modifier directement l'instance d'un objet passé en paramètre du constructeur.
 * Si on utilise directement la classe "FieldContainer" définie au-dessus les valeurs sont modifiées par copie et non par référence, et donc l'objet initial n'est pas mis à jour.
 */
export class FieldContainerWrapper extends FieldContainer {
    /** Objet contenant les attributs field1 ... field4 */
    instance: {field1: string,field2: string,field3: string,field4: string};
    
    /**
     * Constructeur
     * @param instance Instance de classe contenant les attributs field1 ... field4
     */
    constructor(instance: {field1: string,field2: string,field3: string,field4: string}) {
        super(null);
        this.instance = instance;
    }
    
    /* Accesseurs */
    get myField1(): string { return this.instance.field1; }
    get myField2(): string { return this.instance.field2; }
    get myField3(): string { return this.instance.field3; }
    get myField4(): string { return this.instance.field4; }

    set myField1(field1: string) { this.instance.field1 = field1; }
    set myField2(field2: string) { this.instance.field2 = field2; }
    set myField3(field3: string) { this.instance.field3 = field3; }
    set myField4(field4: string) { this.instance.field4 = field4; }
}
