/**
 * Association des contextes avec leur portée
 */
const mapPorteeByContext = {
    'NDF': 'NF',
    'NDF_V': 'NF',
    'FRAIS': 'NF',
    'OD': 'OD',
    'OM_PERMANENT': 'OT',
    'FACTURE': 'FC',
    'FACTURE_RELEVE': 'FC',
    'AVANCE': 'AV',
    'PROFIL': 'PV',
    'PROFIL_COMPTE': 'PV',
    'PROFIL_VEHICULE': 'PV',
    'PROFIL_VEHICULE_CARTE_GRISE': 'PV',
    'PROFIL_VEHICULE_ASSURANCE': 'PV',
    'PROFIL_VEHICULE_AUTORISATION': 'PV',
    'PROFIL_CARTE': 'PV',
    'PROFIL_DOCUMENT': 'PV'
};

/**
 * Association des contextes avec leur portée pour le delete
 */
const mapPorteeDeleteByContext = {
    'NDF': 'NDF',
}

/** Types d'image **/
const IMAGE_TYPES: Array<string> = ['image/jpeg','image/png','image/jpg','image/gif','image/bmp'];

export { mapPorteeByContext,mapPorteeDeleteByContext,IMAGE_TYPES };

/**
 * Description d'un élément du menu
 */
export interface FileDownload {
    file: Blob;
    filename: string;
}
