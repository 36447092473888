<div class="with-floating-button">
    <div class="content">
        <page-header
                [title]="'admin.entreprise.populations.title' | translate"
                [selectedIndex]="selectedIndex"
                [listeItems]="listeTabItems"
                (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <list-view *ngIf="listePopulations" [liste]="listePopulations"></list-view>
        <floating-button [listeActions]="listeActions"></floating-button>
    </div>
</div>