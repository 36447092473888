import {Component,Input,OnInit} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Statut} from "@domain/workflow/statut";
import {Condition} from "@domain/workflow/condition";

/**
 * Item de la liste des statuts
 */
@Component({
	host: {'data-test-id': 'statut-list-item'},
	templateUrl: './statut-list-item.component.html'
})
export class StatutListItemComponent extends ListViewItem<Statut> implements OnInit {
	/** L'alerte courante */
	@Input() extraOptions: {
		alerte: Condition
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de isSelected
		this.data.isSelected = this.extraOptions.alerte.listeStatuts?.map(s => s.idStatut).includes(this.data.idStatut);
	}
}
