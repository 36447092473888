import {Component,EventEmitter,Input,OnDestroy,OnInit,Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {Od} from "@domain/od/od";
import {TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {ODService} from "../../../od.service";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {OdFraisFraisItemComponent} from "./od-frais-frais-item.component";
import {OdFraisAddComponent} from "./creation/od-frais-add.component";
import {SettingsODState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {TypePortee} from "@domain/workflow/workflow";
import {OdTypeFrais} from "@domain/od/frais/od-type-frais";
import {forkJoin,Subscription} from "rxjs";
import {first} from "rxjs/operators";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";

/**
 * Composant d'affichage de la liste des frais rattachés à un OD
 *
 * @author Laurent Convert
 * @date 08/12/2021
 */
@Component({
	selector: 'od-frais-ndf',
	templateUrl: './od-frais-frais.component.html'
})
export class OdFraisFraisComponent implements OnInit,OnDestroy {
    /** Portée de l'objet */
    readonly portee: TypePortee = TypePortee.OD;

    /** Ordre de mission */
    @Input() od: Od = null;

    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /** Indicateur de modification spécifique au frais prev */
    @Input() canModifierFraisPrev: boolean;

    /** Indique un changement de frais prev */
    @Output()
    isFraisPrevChange = new EventEmitter<string>();

    /** Paramètres du type entité */
    typeEntiteParam: TypeEntiteParamOD;

    /** Paramétrage de l'OD */
    settings: SettingsODState;

    /** Données */
    liste: ListView<any,OdFraisFraisItemComponent>;

    /** Abonnement sur l'observable déclenché lors du chargement de la liste */
    subscription: Subscription;

    /**
     * Constructeur
     */
    constructor(private store: Store<AppState>,
                private odService: ODService,
                private translateService: TranslateService,
                private matDialog: MatDialog) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Sélection du paramétrage
        this.store.select(state => state.settings?.[this.portee]).pipe(filterFirstNotNull()).subscribe(settings => this.settings = settings);

        //Récupération des paramètres du type entité
        this.store.select(state => state.typeEntite?.[this.portee]).pipe(filterFirstNotNull()).subscribe((typeEntite) => {
            //Lecture des paramètres dans le store
            this.typeEntiteParam = typeEntite?.typeEntiteParam;
    
            //Définition de tous les types de frais possibles
            const listeOdTypeFrais: Array<OdTypeFrais> = [OdTypeFrais.frais_prev,OdTypeFrais.ndf,OdTypeFrais.avance];
            
            //Liste des types de frais autorisés seulement
            let listeTypes: Array<OdTypeFrais> = [];

            //Définition de l'action associée au bouton de création de frais.
            //La visibilité du bouton est déterminée une fois la liste chargée.
            const actionCreation = {
                icon: 'add',
                isVisible: () => false,
                onPress: () => this.openCreation(listeTypes)
            };
    
            //Vérification de la possibilité de création pour tous les types de frais
            forkJoin(listeOdTypeFrais.map(typeFrais => this.odService.canCreateElementFrais(this.od,typeFrais,this.typeEntiteParam).pipe(first())))
                .subscribe(canCreateElement => {
                    //Récupération et traitement des types autorisés
                    canCreateElement.forEach((value,idx) => {
                        if (value) {
                            //Ajout du type autorisé à la liste
                            listeTypes.push(listeOdTypeFrais[idx]);
                        }
                    });
                    
                    //Définition de la liste
                    this.liste = new ListView<any,OdFraisFraisItemComponent>({
                        uri: `/controller/OD/${this.od.idOd}/filtreFrais`,
                        title: this.translateService.instant('od.frais.frais.liste.title'),
                        component: OdFraisFraisItemComponent,
                        listeActions: [actionCreation],
                        isFilter: true,
                        listeFilters: [{
                                title: this.translateService.instant("filter.objet"),
                                clef: "title",
                                isDefault: true
                            },{
                                title: this.translateService.instant("filter.type"),
                                clef: "typeFrais",
                                typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
                                isDefault: false,
                                listeValues: [{
                                    code: OdTypeFrais.ndf,
                                    libelle: this.translateService.instant(OdTypeFrais.traduction(OdTypeFrais.ndf))
                                },{
                                    code: OdTypeFrais.avance,
                                    libelle: this.translateService.instant(OdTypeFrais.traduction(OdTypeFrais.avance))
                                },{
                                    code: OdTypeFrais.frais_prev,
                                    libelle: this.translateService.instant(OdTypeFrais.traduction(OdTypeFrais.frais_prev))
                                }]
                            }],
                        extraOptions: {
                            canModifier: this.canModifier,
                            canModifierFraisPrev: this.canModifierFraisPrev,
                            od: this.od,
                            settings: this.settings,
                            refreshListe: (message: string) => {this.refreshListe(message);}
                        }
                    });

                    //Abonnement au chargement de la liste
                    this.subscription = this.liste.loaded.pipe(first()).subscribe(value => {
                        //Si on n'a pas le droit d'avoir plusieurs NDF mais que le type est autorisé par le reste de la configuration
                        if (!this.settings.isMultiNdfAllowed && listeTypes.indexOf(OdTypeFrais.ndf) != -1) {
                            //On vérifie le nombre de ndf
                            if (this.liste.data.listeResultats.filter(r => r.type == OdTypeFrais.ndf ).length > 0) {
                                //Au moins une ndf déjà présente : on supprime le type de la liste
                                listeTypes.splice(listeTypes.indexOf(OdTypeFrais.ndf),1);
                            }
                        }

                        //Mise à jour de la visibilité du bouton
                        actionCreation.isVisible = () => this.canModifier && listeTypes.length > 0;
                    });
                });
        });
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Désabonnement (ça suffit le spam !)
        this.subscription?.unsubscribe();
    }

    /**
     * Ouvre la popin de choix du type de frais à créer
     */
    openCreation(listeTypes: Array<OdTypeFrais>): void {
        //Ouverture de la popin
        this.matDialog.open(OdFraisAddComponent, {
            data: {
                refreshListe: (message: string) => {this.refreshListe(message);},
                od: this.od,
                settings: this.settings,
                listeTypes: listeTypes,
            },
            minWidth: '100px'
        });
    }

    /**
     * Rafraichissement de la liste des frais
     */
    refreshListe(message: string): void {
        this.liste.refresh();
        this.isFraisPrevChange.emit(message);
    }

}
