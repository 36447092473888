import {ListItem} from "@domain/common/list-view/list-item";
import {ReportParam} from "@domain/reporting/reportParam";

/**
 * Classe pour la manipulation d'un rapport
 */
export class Report implements ListItem {
	id: number;
	idReport: number;
	libelle: string;
	fileName: string;
	userFile: string;
	actif: boolean;
	hasDateDebut: boolean;
	hasDateFin: boolean;
	idPortee: string;
	typeParc: number;
	type: number;
	idGroupe: number;
	groupeDefini: number;
	groupe: ReportGroup;
	format: number;
	nbRole: number;
	pdf: boolean;
	csv: boolean;
	html: boolean;
	excel: boolean;
	standard: boolean;
	qrcode: boolean;
	hugeFile: boolean;
	typeExport: string;
	reportParamList: ReportParam[]|any;
	paramsMap: any;
	libelleGroupe: string;
	groupeCustom: string;
	codeGroupe: string;
	listeGroupesCustom?: ReportGroup[];
	md5: string;

	getKey(): number {
		return this.id;
	}

	constructor(dto: any) {
		if (dto) {
			//Version light (liste)
			this.idReport = dto.idReport;
			this.libelle = dto.libelle;
			this.groupeDefini = dto.groupeDefini;
			this.groupeCustom = dto.groupeCustom;
			this.userFile = dto.userFile;
			this.actif = dto.actif;
			this.standard = dto.standard;
			this.nbRole = dto.nbRole;
			this.type = dto.type;
			this.idPortee = dto.idPortee;
		}
	}
}

/**
 * Groupe d'un rapport.
 */
export class ReportGroup {
	idGroupe: number;
	libelle: string;
}

/**
 * Enum pour le format de sortie des rapports
 */
export enum ReportFormat {
	PDF = 1,
	CSV = 2,
	HTML = 3,
	EXCEL = 4
}
