<div class="lvi-content action-box" [ngClass]="{'clickable': data.listeRoles.length > 1}" (click)="data.isOpened = !isOpened">
    <div class="avatar">
        <span [ngSwitch]="data.idAction">
            <i *ngSwitchCase="TypeAction.VALIDER" class="nio icon-validation icone"></i>
            <i *ngSwitchCase="TypeAction.REJETER" class="nio icon-rejet icone"></i>
            <i *ngSwitchCase="TypeAction.INVALIDER" class="nio icon-invalidation icone"></i>
            <i *ngSwitchCase="TypeAction.ANNULER" class="nio icon-annulation icone"></i>
            <i *ngSwitchCase="TypeAction.MODIFIER" class="nio icon-modification icone"></i>
            <i *ngSwitchCase="TypeAction.SUPPRIMER" class="nio icon-suppression icone"></i>
            <i *ngSwitchCase="TypeAction.EMETTRE" class="nio icon-emission icone"></i>
            <i *ngSwitchCase="TypeAction.ARCHIVER" class="nio icon-archive icone"></i>
            <i *ngSwitchCase="TypeAction.COMPTABILISER" class="nio icon-comptabilisation icone"></i>
            <i *ngSwitchCase="TypeAction.COMPLETER" class="nio icon-completion icone"></i>
            <i *ngSwitchCase="TypeAction.RESTITUER" class="nio icon-restitution icone"></i>
            <i *ngSwitchCase="TypeAction.CLOTURER" class="nio icon-cloture icone"></i>
            <i *ngSwitchCase="TypeAction.ATTRIBUER" class="nio icon-octroi icone"></i>
        </span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{libelleAction | translate}}</a>
        </div>
        <ul class="lvi-attrs row">
            <ng-container *ngIf="data.listeRoles.length === 1">
                <li><strong [translate]="'admin.ongletWF.cadreWF.roleTitle'"></strong>
                    <span *ngIf="data.listeRoles[0].code" [translate]="'admin.ongletWF.cadreWF.roleContent'" [translateParams]="{libelleRole: data.listeRoles[0].libelle, codeRole: data.listeRoles[0].code}"></span>
                    <span *ngIf="!data.listeRoles[0].code" [translate]="'admin.ongletWF.cadreWF.roleContentNoCode'" [translateParams]="{libelleRole: data.listeRoles[0].libelle}"></span></li>
                <li *ngIf="data.listeRoles[0].isRespBudg"><strong [translate]="'admin.ongletWF.cadreWF.respoBudg'"></strong><span [translate]="'global.oui'"></span></li>
            </ng-container>
            <li *ngIf="data.listeRoles.length > 1"><strong [translate]="'admin.ongletWF.cadreWF.rolesTitle'"></strong>
                <span [translate]="'admin.ongletWF.cadreWF.rolesContent'" [translateParams]="{nbRoles: data.listeRoles.length}"></span></li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="data.listeRoles.length > 1">
        <button mat-icon-button>
            <mat-icon *ngIf="isOpened" color="primary" [matTooltip]="'admin.ongletWF.cadreWF.replier' | translate">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOpened" color="primary" [matTooltip]="'admin.ongletWF.cadreWF.deplier' | translate">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>

<div class="roles-list" *ngIf="data.listeRoles.length > 1" [ngClass]="isOpened ? 'open' : 'closed'">
    <div class="listview-item ml-5" *ngFor="let role of data.listeRoles;">
        <div class="lvi-content">
            <div class="avatar">
                <span [ngSwitch]="role.fonction">
                        <i *ngSwitchCase="TypeProfil.COMPTABLE" class="nio icon-comptable icone"></i>
                        <i *ngSwitchCase="TypeProfil.RESPONSABLE" class="nio icon-responsable icone"></i>
                        <i *ngSwitchCase="TypeProfil.COLLABORATEUR" class="nio icon-collaborateurs icone"></i>
                        <i *ngSwitchCase="TypeProfil.ASSISTANT" class="nio icon-assistant icone"></i>
                        <i *ngSwitchCase="TypeProfil.SOUS_ADMINISTRATEUR" class="nio icon-sous_administrateur icone"></i>
                        <i *ngSwitchCase="TypeProfil.FOURNISSEUR" class="nio icon-fournisseur icone"></i>
                </span>
            </div>
            <div class="lvi-body">
                <div class="role-title">
                    <span>{{role.libelle}}</span>
                </div>
                <ul class="lvi-attrs row">
                    <li *ngIf="role.code"><strong [translate]="'admin.ongletWF.cadreWF.code'"></strong>{{role.code}}</li>
                    <li *ngIf="!role.code"><strong [translate]="'admin.ongletWF.cadreWF.code'"></strong>{{'admin.ongletWF.cadreWF.pasDeCode' | translate}}</li>
                    <li *ngIf="role.isRespBudg"><strong [translate]="'admin.ongletWF.cadreWF.respoBudg'"></strong><span [translate]="'global.oui'"></span></li>
                </ul>
            </div>
        </div>
    </div>
</div>
