import {Component,OnDestroy,OnInit} from '@angular/core';
import {KeyStatutApplication} from "@domain/admin/statut-application/statut-application";
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";
import {LoginService} from "@share/login/login.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";

/**
 * Composant allégé de visualisation du statut de l'application (pour les utilisateurs non-admin)
 */
@Component({
    selector: 'statut-application-user',
    templateUrl: './statut-application-user.component.html',
    styleUrls: ['./statut-application-user.component.scss']
})
export class StatutApplicationUserComponent implements OnInit, OnDestroy {
    /** Clés de statut de l'application */
    readonly KeyStatutApplication = KeyStatutApplication;

    /** Statut de l'application */
    statut: KeyStatutApplication;

    /** Progression */
    progression: number = 0.00;

    /** Souscriptions */
    private souscriptions: Subscription[] = [];

    /**
     * Constructeur
     */
    constructor(private router: Router,private statutApplicationUserService: StatutApplicationUserService,private loginService: LoginService) {}

    /**
     * Initialisation du composant
     */
    ngOnInit(): void  {
        //Initialisation du déverrouillage du rafraichissement du statut
        this.statutApplicationUserService.unlockRefresh();

        //Abonnement à la mise à jour du statut de l'application
        this.souscriptions.push(this.statutApplicationUserService.statut$.subscribe(statut => {
            //Stockage du statut de l'application
            this.statut = statut;

            //Si l'application est hors ligne
            if (statut == KeyStatutApplication.HORS_LIGNE) {
                //Forçage de la déconnexion
                this.loginService.logout();

                //Redirection vers l'écran de connexion
                this.router.navigate(['/Login']);
            }
        }));

        //Abonnement à mise à jour de la progression de la temporisation
        this.souscriptions.push(this.statutApplicationUserService.progression$.subscribe(progression => this.progression = progression));

        //Lancement de la surveillance du statut de l'application
        this.statutApplicationUserService.checkPublicApplicationStatus();
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Résiliation des souscriptions
        this.souscriptions.forEach(sub => sub.unsubscribe());
    }
}
