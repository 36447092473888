import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, FormControl } from '@angular/forms';

/** Validator pour le contrôle de carte */
@Directive({
   selector: '[creditCard]',
   //Ajout de la directive à la liste des validateurs existants
   providers: [
     { provide: NG_VALIDATORS, useExisting: CreditCardDirective, multi: true }
   ]
})
export class CreditCardDirective implements Validator {   
   validate(c: FormControl): ValidationErrors | null {
      //Contrôle de la validité de la carte
      return validateCcNumber(c);
   }
}

/**
 * Contrôle de la validité de carte
 * @param control FormControl testé
 */
function validateCcNumber(control: FormControl): ValidationErrors {
   const checkedValue = control.value;

   //Contrôle si la valeur contient que des chiffres
   //Et entre 13 et 19 chiffres
   const regex = new RegExp("^[0-9]{13,19}$");

   //Lance le contrôle regex et luhncheck
   if (!regex.test(checkedValue) || !luhnCheck(checkedValue)){
      return {creditCard: 'error'};
   }

   //Pas d'erreur on renvoie null
   return null;
}

/** Algorithme de contrôle de carte luhnCheck */
const luhnCheck = (val: string) => {
   let checksum = 0; //Total du checksum en cours d'exécution
   let j = 1; //Prend pour valeur 1 ou 2 alternativement

   //Traite chaque chiffre un par un en commençant par le dernier
   for (let i = val.length - 1; i >= 0; i--) {
     let calc = 0;
     //Extrait le prochain chiffre et multiplie par 1 ou 2 une fois sur deux.
     calc = Number(val.charAt(i)) * j;

     //Si le résultat est deux chiffres on ajoute un au checksum
     if (calc > 9) {
       checksum = checksum + 1;
       calc = calc - 10;
     }

     //Ajoute l'unité au checksum
     checksum = checksum + calc;

     //Inverse la valeur de j
     if (j == 1) {
       j = 2;
     } else {
       j = 1;
     }
   }
 
   //Contrôle si la valeur est divisible par 10
   return (checksum % 10) == 0;
}

