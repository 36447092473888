<h1 mat-dialog-title>
    <span translate>admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.title</span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form>
        <div class="row">
            <custom-input ngDefaultControl
                          id="isUpdateBdd"
                          name="isUpdateBdd"
                          lCol="3" rCol="9"
                          [ngModel]="isUpdateBdd"
                          [(customModel)]="isUpdateBdd"
                          customType="checkbox"
                          libelle="admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.bdd.label"
                          postLibelle="admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.bdd.tooltip">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl
                          id="isUpdateApp"
                          name="isUpdateApp"
                          lCol="3" rCol="9"
                          [ngModel]="isUpdateApp"
                          [(customModel)]="isUpdateApp"
                          customType="checkbox"
                          libelle="admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.maintenance.label"
                          postLibelle="admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.maintenance.tooltip">
            </custom-input>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary"
                    (click)="executeManualUpdate()">
                <span [translate]="'admin.maintenance.migrations.onglets.parametres.relancerMigrationPopup.confirmButton'"></span>
            </button>
        </div>
    </form>
</div>
