import {Component,Input} from '@angular/core';

import {environment} from '@environments/environment';
import {ImageViewerService} from '@share/component/image-viewer/image-viewer.service';
import {IMAGE_TYPES} from './document';
import {HttpParams} from "@angular/common/http";

@Component({
    selector: 'document-viewer',
    templateUrl: './document-viewer.component.html'
})
export class DocumentViewerComponent {
    /** Liste des documents */
    @Input() listeDocuments: Array<any>;

    /** Contexte des documents */
    @Input() context: string;

    /** Alignement à droite de l'image */
    @Input() isAlignRight: boolean = false;

    /** Affichage d'une puce de navigation pour chaque document */
    @Input() isShowBullets: boolean = false;

    /** Index courant */
    currentIndex: number = 0;

    /** Types d'image */
    readonly IMAGE_TYPES: Array<string> = IMAGE_TYPES;

    /**
     * Constructeur
     */
    constructor(private imageViewerService: ImageViewerService) {

    }

    /**
     * Récupération de l'URL de lecture d'un document
     */
    getUrlForDocument(document: any): string {
        let httpParams: HttpParams = new HttpParams();

        //Ajout du contexte aux paramètres HTTP le cas échéant
        if (!!this.context) {
            httpParams = httpParams.append('contexte',this.context);
        }

        //Demande au controller la miniature si le document en possède une
        if (!!document.thumbnailName) {
            httpParams = httpParams.append('preview','true');
        }

        //Génération de l'URL
        return `${environment.baseUrl}/controller/Document/readDocument/${document.idDocument}?${httpParams?.toString()}`;
    }

    /**
     * Affichage du document
     */
    showDocument() {
        let httpParams: HttpParams = new HttpParams();

        //Ajout du contexte aux paramètres HTTP le cas échéant
        if (!!this.context) {
            httpParams = httpParams.append('contexte',this.context);
        }

        //Vérification du type de fichiers
        if (this.IMAGE_TYPES.indexOf(this.listeDocuments[this.currentIndex].contentType) != -1) {
            //Visualisation de l'image
            this.imageViewerService.show(`${environment.baseUrl}/controller/Document/readDocument/${this.listeDocuments[this.currentIndex].idDocument}?${httpParams?.toString()}`,this.isAlignRight);
        } else {
            //Ouverture du document
            window.open(`${environment.baseUrl}/controller/Document/readDocument/${this.listeDocuments[this.currentIndex].idDocument}?${httpParams?.toString()}`);
        }
    }
}