import {NgModule} from "@angular/core";
import {HistoriqueArchivageComponent} from "./historique-archivage.component";
import {NdfDematListItemComponent} from "./ndf-list-item/ndf-demat-list-item.component";
import {HistoriqueLotListItemComponent} from "./historique-list-item/historique-lot-list-item.component";
import {ShareModule} from "@share/share.module";
import {TooltipModule} from "@share/directive/tooltip/tooltip.module";

/**
 * Module de l'historique de l'archivage des lots
 *
 * @author Laurent Convert
 * @date 09/08/2024
 */
@NgModule({
	imports:[ShareModule,TooltipModule],
	declarations: [HistoriqueArchivageComponent,NdfDematListItemComponent,HistoriqueLotListItemComponent],
	exports: [HistoriqueArchivageComponent]
})
export class HistoriqueArchivageModule {
}