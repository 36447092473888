import {TranslateService} from "@ngx-translate/core";
import {User} from "../user/user";

/**
 * Classe représentant les autorisations pour les véhicules
 */
export class Autorisation {
    id?: number;
    idVehiculeAutorisation?: number;
    idPa: number;
    dateDebut?: Date;
    dateFin?: Date;
    remarque?: string;
    dateApplication?: Date;
    nbPJ?: number;
    user?: User;

    constructor() {
        this.id = 0;
        this.idVehiculeAutorisation = 0;
    }
}

/**
 * Niveau d'autorisation d'un véhicule dans la NDF ou l'OD
 */
export enum NiveauAutorisation {
    NON_CONTROLE,
    NON_BLOQUANT,
    BLOQUANT
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace NiveauAutorisation {
    /**
     * Renvoie un tableau contenant tous les éléments de l'énuméré
     */
    export function values(): Array<NiveauAutorisation> {
        return [NiveauAutorisation.NON_CONTROLE,NiveauAutorisation.NON_BLOQUANT,NiveauAutorisation.BLOQUANT];
    }

    /**
     * Traduction des niveaux d'autorisation
     * Renvoi la chaîne traduite si le service est passé en paramètre et le code de traduction dans le cas contraire.
     *
     * @param nvAuto Niveau d'autorisation
     * @param translateService Service de traduction (facultatif, dans ce cas seul le code de traduction sera renvoyé au lieu de la chaîne traduite)
     */
    export function traduction(nvAuto: NiveauAutorisation,translateService?: TranslateService): string {
        let code: string;

        //Suivant le niveau d'autorisation
        switch (nvAuto) {
            case NiveauAutorisation.NON_CONTROLE:
                code = 'admin.entreprise.vehicules.parametres.approbation.nonControle';
                break;
            case NiveauAutorisation.NON_BLOQUANT:
                code = 'admin.entreprise.vehicules.parametres.approbation.nonBloquant';
                break;
            case NiveauAutorisation.BLOQUANT:
                code = 'admin.entreprise.vehicules.parametres.approbation.bloquant';
                break;
        }

        //Vérification du passage du service de traduction
        if (translateService) {
            //Traduction
            code = translateService.instant(code);
        }

        return code;
    }
}
