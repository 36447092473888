<div class="lvi-content">
    <div class="avatar">
        <span>{{ displayAvatar() }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="extraOptions.onSelect(data)">{{ data.libelle }}</a>
            <span *ngIf="data.defaut" class="material-icons" [matTooltip]="'admin.entreprise.referentiels.defaut' | translate" [matTooltipPosition]="'right'">info</span>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.referentiels.referentielFiltres.type'"></strong>
                <span>{{ data.type }}</span>
            </li>
            <li *ngIf="data.code">
                <strong [translate]="'admin.entreprise.referentiels.referentielFiltres.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li *ngIf="data.ordre">
                <strong [translate]="'admin.entreprise.referentiels.referentielFiltres.ordre'"></strong>
                <span>{{ data.ordre }}</span>
            </li>
        </ul>
    </div>
</div>
