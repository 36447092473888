import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {RgpdParam} from "@domain/admin/entreprise/rgpd-param";

/**
 * Service de gestion des RGPD
 */
@Injectable()
export class RgpdService {
    /**
     * Constructeur
     *
     * @param http pour permettre de réaliser les requêtes
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Récupération des paramètres rgpd
     */
    consultRgpdParams(): Observable<Result> {
        return this.http.get<Result>(`${environment.baseUrl}/controller/Rgpd/consultRgpdParam`).pipe(first());
    }

    /**
     * Sauvegarde des paramètres rgpd
     */
    saveRgpdParam(params: RgpdParam): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Rgpd/saveRgpdParam`,params);
    }
}