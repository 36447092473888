import {Component, Input, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {Report} from "@domain/reporting/report";
import {ListView, TypeComparaison} from "@domain/common/list-view";
import {ReportParamItemComponent} from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/report-param-item.component";
import {RoleItemComponent} from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/role-item.component";
import {ReportParam} from "@domain/reporting/reportParam";
import {Role} from "@domain/reporting/role";
import {Result} from "@domain/common/http/result";
import {ReportParamModifyComponent} from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/report-param-modify/report-param-modify.component";
import {ReportRoleCreateComponent} from "@components/admin/bibliotheque/reporting/reporting-infos/parametres/report-role-create/report-role-create.component";

/**
 * Composant d'affichage des paramètres d'un rapport
 */
@Component({
    host: {'data-test-id': 'reporting-parametres'},
    selector: 'parametres',
    templateUrl: './parametres.component.html'
})
export class ParametresComponent implements OnInit {
    /** Rapport sélectionné */
    @Input() report: Report;

    /** Booléen pour savoir si l'affichage du formulaire se fait en création ou en modification, pour le champ du code (lecture seule si on est en modification) */
    @Input() isCreation: boolean;

    /** Liste des paramètres */
    listeParam: ListView<ReportParam, ReportParamItemComponent>;

    /** Liste des rôles */
    listeRole: ListView<Role, RoleItemComponent>;

    /**
     * Constructeur
     */
    constructor(protected translateService: TranslateService,private matDialog: MatDialog) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Création de la liste des params
        this.listeParam = new ListView<ReportParam, ReportParamItemComponent>({
            uri: `/controller/Report/listeReportParam/${this.report.idReport}`,
            title: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.parametres.title'),
            component: ReportParamItemComponent,
            isSimple: true,
            mapResult: (result: Result) => result?.data?.listeReportParam?.map(p => new ReportParam(p)),
            extraOptions: {
                idReport: this.report.idReport,
                isStandard: this.report.standard,
                refresh: () => this.listeParam.refresh(),
                showParamDetails: (param: ReportParam) => this.showParamDetails(param)
            },
            listeActions: [{
                icon: 'add',
                isVisible: () => !this.report.standard,
                onPress: () => this.showParamDetails()
            }]
        });

        //Création de la liste des rôles
        this.listeRole = new ListView<Role, RoleItemComponent>({
            uri: `/controller/Report/listeRole/${this.report.idReport}`,
            title: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.filtres.title'),
            component: RoleItemComponent,
            listeFilters: [{
                clef: 'libelle',
                title: this.translateService.instant('admin.bibliotheque.reporting.infosReporting.filtres.libelle'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }],
            isFilter: true,
            isLocal: true,
            extraOptions: {
                idReport: this.report.idReport,
                refresh: () => this.listeRole.refresh()
            },
            listeActions: [{
                icon: 'add',
                onPress: () => this.addNewRole()
            }]
        });

        //Forçage du nombre par page
        this.listeRole.nbObjetsParPage = 5;

        //Message de liste vide
        this.listeRole.emptyMessage = 'admin.bibliotheque.reporting.infosReporting.filtres.tous';
    }

    /**
     * Affichage des détails d'un paramètre
     *
     * @param param     (optionnel) paramètre à afficher - création si null
     */
    showParamDetails(param?: ReportParam) {
        //Ouverture de la boîte de dialogue
        this.matDialog.open(ReportParamModifyComponent, {
            width: '60%',
            data: {
                idReport: this.report.idReport,
                reportParam: param
            }
        }).afterClosed().subscribe(refresh => {
            if (refresh) {
                //On met à jour la liste affichée
                this.listeParam.refresh();
            }
        });
    }

    /**
     * Ajout d'un nouveau rôle
     */
    addNewRole() {
        //Ouverture de la boîte de dialogue
        this.matDialog.open(ReportRoleCreateComponent, {
            width: '60%',
            data: {
                idReport: this.report.idReport
            }
        }).afterClosed().subscribe(refresh => {
            if (refresh) {
                //On met à jour la liste affichée
                this.listeRole.refresh();
            }
        });
    }
}
