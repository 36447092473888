import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { ShareModule } from '../../share/share.module';
import { SettingsEffects } from './settings.effects';
import { SettingsService } from './settings.service';

@NgModule({
    imports: [ShareModule,EffectsModule.forFeature([SettingsEffects])],
    declarations: [],
    providers: [SettingsService]
})
export class SettingsModule {}