<div class="lvi-content">
    <div class="avatar">{{ data.libelle.substring(0,3)}}</div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">
                <span *ngIf="data.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.code2.toLowerCase()"></span>
                {{ data.libelle }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code2">
                <strong [translate]="'pays.code2'"></strong>
                <span>{{ data.code2 }}</span>
            </li>

            <li *ngIf="data.code3">
                <strong [translate]="'pays.code3'"></strong>
                <span>{{ data.code3 }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deletePerimetre()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>