import {Component,ComponentFactoryResolver,ComponentRef,EventEmitter,Input,OnInit,Output,ReflectiveInjector,Type,ViewChild,ViewContainerRef} from '@angular/core';
import {ListView} from '@domain/common/list-view';
import {ListItem} from '@domain/common/list-view/list-item';
import {ListViewItem} from '@domain/common/list-view/list-view-item';

@Component({
    selector: 'list-view-item',
    templateUrl: './list-view-item.component.html'
})
export class ListViewItemComponent<T extends ListItem> implements OnInit {
    /** Elèment à afficher **/
    @Input() data: T;

    /** Affiche/cache l'item */
    @Input() toggleItem: (data: T) => void;

    /** Liste **/
    @Input() liste: ListView<T,any>;

    /** Composant d'affichage **/
    @Input() component: ListViewItem<T>;

    /** Options supplémentaires **/
    @Input() extraOptions: any;

    /** Interception de la suppression **/
    @Output() onRemove: EventEmitter<T> = new EventEmitter<T>();

    /** Récepteur de composant **/
    @ViewChild('holder',{ read: ViewContainerRef,static: true }) private holder: ViewContainerRef;

    /**
     * Constructeur
     */
    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        let component;

        //Création du composant
        component = this.createComponent(this.holder,<Type<T>><any>this.component);

        //Définition des données
        component.instance.liste = this.liste;
        component.instance.data = this.data;
        component.instance.extraOptions = this.extraOptions;

        //Définition de la fonction d'affichage de l'élément
        component.instance.toggleItem = this.toggleItem;

        //Ajout du composant au DOM
        this.holder.insert(component.hostView);
        
        //Interception de la suppression
        this.data.removeFromListView = () => this.onRemove.emit(this.data);
    }

    /**
     * Création du composant
     */
    createComponent(viewContainer: ViewContainerRef,type: Type<T>): ComponentRef<T> {
        let factory;
        let injector;

        //Récupération de la fabrique
        factory = this.componentFactoryResolver.resolveComponentFactory(type);
    
        //Récupération de l'injecteur
        injector = ReflectiveInjector.fromResolvedProviders([],viewContainer.parentInjector);
    
        //Création du composant
        return factory.create(injector);
    }
}