import {AutocompleteOptions} from "@share/component/autocomplete/autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {Filter} from "@domain/common/list-view";
import {ContexteReferentiel} from "@domain/admin/referentiels/contexte-referentiel";
import {TypeReferentiel} from "@domain/admin/referentiels/type-referentiel";
import {Referentiel} from "@domain/admin/referentiels/referentiel";

/**
 * Options de l'autocomplete des mots-clef
 */
export default class MotClefOptions implements AutocompleteOptions {
	/**
	 * URL
	 */
	url(): string {
		return `/controller/Referentiel/listeAllReferentiel?contextReferentiel=${ContexteReferentiel.UTILISATEUR}`;
	}

	/**
	 * Affichage d'un item
	 */
	displayItem(item: Referentiel): string {
		return item?.libelle ?? '';
	}

	/**
	 * Filtres statiques
	 */
	listeStaticFilters(): Array<Filter> {
		return [
			{
				clef: 'type',
				valeur: TypeReferentiel.MOTS_CLEFS
			}
		]
	}

	/**
	 * Filtres
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		return [
			{
				title: translateService.instant('filter.libelle'),
				clef: 'libelle',
				isDefault: true
			}
		];
	}
}