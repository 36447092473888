import {Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {PageHeaderItem} from "../../../share/component/page-header/page-header";
import {ListView} from "../../../domain/common/list-view";
import {ParticipantListItemComponent} from "../participant-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {SettingsNFState, SettingsODState} from "../../../domain/settings/settings";
import {ModeParticipants} from "../../../domain/settings/ns-params";
import {ModeParticipants as ModeParticipantsPrest} from "../../../domain/prestation/prestation";
import {Result, TypeCodeErreur} from "../../../domain/common/http/result";
import {ParticipantsService} from "../participants.service";
import {first} from "rxjs/operators";
import {Page} from "../../../domain/common/http/list-result";
import {ParticipantLien} from "../../../domain/participant/participant-lien";
import {LienOmUser} from "../../../domain/od/lien-om-user";
import {LienParticipant} from "../../../domain/ndf/lien-participant";
import {ODService} from "../../od/od.service";
import {ParticipantExterne} from "../../../domain/participant/participant-externe";
import {ParticipantAvecFavoris} from "../../../domain/participant/participant-avec-favoris";
import {PageHeaderComponent} from "../../../share/component/page-header/page-header.component";

/**
 * Popin d'ajout/création de participants
 *
 * @author Laurent Convert
 * @date 07/03/2022
 */
@Component({
	host: {'data-test-id': 'participants-popin.component'},
	templateUrl: './participants-popin.component.html',
	styleUrls: ['./participants-popin.component.scss']
})
export class ParticipantsPopinComponent implements OnInit, OnDestroy {
	/** Index de l'onglet sélectionné */
	tabIndex: number;

	/** Onglet sélectionné */
	selectedTab: PageHeaderItem = null;

	/** Liste des onglets */
	listeTabs: Array<PageHeaderItem>;

	/** Liste des participants à ajouter à la mission*/
	listeParticipantsCreation: ListView<ParticipantLien,ParticipantListItemComponent>;

	/** Évènement d'ajout aux favoris */
	eventAjouterFavoris: EventEmitter<ParticipantLien> = new EventEmitter<ParticipantLien>();

	/** Évènement de retrait des favoris */
	eventRetirerFavoris: EventEmitter<ParticipantLien> = new EventEmitter<ParticipantLien>();

	/** Évènement de retrait de l'objet */
	eventRetirerParticipant: EventEmitter<ParticipantLien> = new EventEmitter<ParticipantLien>();

	/** Référence vers le pageHeader contenant les onglets */
	@ViewChild("pageHeader", {static: true})
	pageHeader: PageHeaderComponent;

	/**
	 * Constructeur
	 */
    constructor(private translateService: TranslateService,
                private matDialogRef: MatDialogRef<any>,
                private toastrService: ToastrService,
                private participantService: ParticipantsService,
                private odService: ODService,
                @Inject(MAT_DIALOG_DATA) public data: {
					contexte: 'OD' | 'NDF_V',
					canModifier: boolean,
					idObjet: number,
					idUserObjet: number,
					idNDF: number,
					idPrestation: number,
                    idUser: number,
                    idTypeEntite: number,
                    participant: ParticipantLien,
                    listeParticipant: Array<ParticipantLien>,
                    settings: SettingsODState | SettingsNFState,
                    modeParticipant: ModeParticipants | ModeParticipantsPrest,
					eventSaveParticipant?: EventEmitter<Array<ParticipantLien>>
                }) {
    }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la liste des onglets
		this.listeTabs = [{
			code: 'PARTICIPANTS',
			libelle: this.translateService.instant('od.voyage.participants.title')
		}];

		//On affiche l'onglet de création si on a la gestion des participants externes et qu'il y a des champs affichables
		if (this.canAddParticipantExterne()) {
			this.listeTabs.push({
				code: 'CREATION',
				libelle: this.translateService.instant('od.voyage.participants.saisieParticipant'),
				selected: this.data.participant?.participantExterne != null
			});
		}

		//On souscrit aux évènements qu'on va passer en paramètre de la liste
		this.eventAjouterFavoris.subscribe((lien: ParticipantLien) => {
			this.participantService.ajouterFavoris(lien.participantInterne?.idUser || lien.participantExterne?.idParticipantExterne,this.data.idUserObjet,lien.participantInterne != null)
                .pipe(first())
                .subscribe((result: Result) => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //On note que l'utilisateur est favoris
                        lien.isFavoris = true;

                        //Message d'information
                        this.toastrService.success(this.translateService.instant('od.voyage.participants.ajoutFavSuccess'));
                    } else {
                        //Affichage d'un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
			});
		});

		//Gestion de l'évènement de retrait de l'attribut favori d'un participant
		this.eventRetirerFavoris.subscribe((lien: ParticipantLien) => {
			this.participantService.retirerFavoris(lien.participantInterne?.idUser || lien.participantExterne?.idParticipantExterne, this.data.idUserObjet, lien.participantInterne != null)
                .pipe(first())
                .subscribe((result: Result) => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //On note que l'utilisateur n'est plus favoris
                        lien.isFavoris = false;

                        //Message d'information
                        this.toastrService.success(this.translateService.instant('od.voyage.participants.retraitFavSuccess'));
                    } else {
                        //Affichage d'un message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
			});
		});

		//Gestion de l'évènement de retrait d'un participant de la mission
		this.eventRetirerParticipant.subscribe((lien: ParticipantLien) => {
				const isInterne = lien.participantInterne != null;
				//On récupère la liste sans le lien à retirer
				this.listeParticipantsCreation.data.listeResultats = this.listeParticipantsCreation.data.listeResultats.filter(p => isInterne && lien.participantInterne.idUser !== p.participantInterne?.idUser
					|| !isInterne && lien.participantExterne.idParticipantExterne !== p.participantExterne?.idParticipantExterne)
		});

		/** Création de la liste des participants */
		this.listeParticipantsCreation = new ListView<ParticipantLien, ParticipantListItemComponent>({
			component: ParticipantListItemComponent,
			isFrontendList: true,
			isFilter: false,
			extraOptions: {
				isFromPopup: true,
				canModifier: this.data.canModifier,
				eventRetirerParticipant: this.eventRetirerParticipant,
				eventAjouterFavoris: this.eventAjouterFavoris,
				eventRetirerFavoris: this.eventRetirerFavoris,
				showParticipant: this.showParticipant.bind(this),
				idUser: this.data.idUser,
				idUserObjet: this.data.idUserObjet,
				settings: this.data.settings
			},
			//Initialisation de la liste avec les participants existants
			data: this.data.listeParticipant ? { listeResultats: this.data.listeParticipant } as Page<ParticipantLien> : null
		});

	}

	/**
	 * Appelé à chaque clic sur un onglet
	 */
	onSelectedItemChange(selectedTab: PageHeaderItem) {
		//Changement d'onglet courant
		this.selectedTab = selectedTab;

		if (this.selectedTab?.code == 'PARTICIPANTS') {
			this.resetFormSaisie();
		}
	}

	/**
	 * Ajout d'un participant à la liste des participants à ajouter à la mission
	 *
	 * @param $event Participant à ajouter
	 */
	ajouterParticipant($event: ParticipantExterne | ParticipantAvecFavoris) {
		//Création d'un lien au bon type suivant le contexte
		const participant = this.data.contexte === 'OD' ? new LienOmUser($event) : new LienParticipant($event);

		//Si le participant est déjà dans la liste, on le retire
		this.listeParticipantsCreation.removeItem(participant);

		//On ajoute le nouveau participant à la liste
		this.listeParticipantsCreation.addItem(participant);

		//On change l'onglet sélectionné
		this.changeTab('PARTICIPANTS');
	}

	/**
	 * Réinitialisation du formulaire de saisie d'un participant externe
	 */
	private resetFormSaisie() {
		const part = new ParticipantExterne();
		this.data.participant = this.data.contexte == 'OD' ? new LienOmUser(part) : new LienParticipant(part);
	}

	/**
	 * Affiche le participant externe dans le formulaire de saisie
	 *
	 * @param data Les informations du participant externe
	 */
	showParticipant(data: ParticipantLien) {
		//Vérification que le participant est externe
		if (data?.participantExterne) {
			//Mise à jour du participant pour édition
			this.data.participant = Object.assign({},data);

			//On change l'onglet sélectionné
			this.changeTab('CREATION');
		}
	}

	/**
	 * Change l'onglet affiché
	 *
	 * @param code Code de l'onglet à afficher
	 */
	changeTab(code: string) {
		this.pageHeader.changeTab(code);
	}

	/**
	 * AJoute tous les participants de la liste à la mission
	 */
	save() {
		if (this.data.contexte === 'OD') {
			this.odService.ajouterParticipants(this.listeParticipantsCreation.data.listeResultats as LienOmUser[], this.data.idObjet, this.data.idUserObjet).pipe(first())
				.subscribe(() => {
					this.matDialogRef.close(true);
				});
		} else {
			this.data.eventSaveParticipant.emit(this.listeParticipantsCreation.data.listeResultats);
			this.matDialogRef.close(true);
		}
	}

	/**
	 * Vérifie la possibilité de création d'un participant externe
	 */
	canAddParticipantExterne(): boolean {
		return this.data.canModifier
			&& (![ModeParticipants.INTERNES,ModeParticipantsPrest.INTERNES].includes(this.data.modeParticipant))
			&& this.data.settings.canAddParticipantExterne
			&& this.data.settings.listeParamExternes?.some(param => (this.data.contexte == 'OD' && param.actifOm) || (this.data.contexte == 'NDF_V' && param.actifNdf))
	}

	/**
	 * Destruction du composant : désinscription des observables
	 */
	ngOnDestroy() {
		this.eventRetirerParticipant.unsubscribe();
		this.eventAjouterFavoris.unsubscribe();
		this.eventRetirerFavoris.unsubscribe();
	}
}
