import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Traduction} from "@domain/admin/bibliotheque/internationalisation/traduction";

/**
 * Composant pour afficher une traduction dans la liste des libellés
 */
@Component({
    host: {'data-test-id': 'libelle-item'},
    templateUrl: './libelle-item.component.html'
})
export class LibelleItemComponent extends ListViewItem<Traduction> {
}
