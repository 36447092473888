import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {OmpListComponent} from "@components/omp/omp-list/omp-list.component";
import {OdListComponent} from "@components/od/od-list/od-list.component";
import {VoyagesMissionsComponent} from "./voyages-missions.component";
import {WorkflowModule} from "@components/workflow/workflow.module";
import {AnalytiqueModule} from "@components/analytique/analytique.module";
import {DocumentModule} from "@components/document/document.module";
import {FicheCollaborateurModule} from "@components/fiche-collaborateur/fiche-collaborateur.module";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ReferentielExterneModule} from "@components/referentiel-externe/referentiel-externe.module";
import {ODModule} from "@components/od/od.module";
import {OMPModule} from "@components/omp/omp.module";

/**
 * Module de gestion des missions
 */
@NgModule({
    imports: [
        ShareModule,
        WorkflowModule,
        AnalytiqueModule,
        DocumentModule,
        FicheCollaborateurModule,
        PreviewAlerteModule,
        ReferentielExterneModule,
        ODModule,
        OMPModule
    ],
    declarations: [
        VoyagesMissionsComponent
    ],
    providers: [
        OmpListComponent,
        OdListComponent
    ]
})
export class VoyagesMissionsModule {
}
