import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Filter,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {LotAddNdfItemComponent} from "./lot-add-ndf-item.component";
import {LotAddFactureItemComponent} from "./lot-add-facture-item.component";
import {LotAddAvanceItemComponent} from "./lot-add-avance-item.component";
import {LotAvanceDTO,LotFactureDTO,LotItemDTO,LotItemType,LotNdfDTO} from '@domain/comptabilite/lot';
import {Result} from "@domain/common/http/result";
import {AppState} from '@domain/appstate';
import {Store} from '@ngrx/store';
import {TypePortee} from '@domain/workflow/workflow';
import {filter,first} from 'rxjs/operators';

@Component({
    host: {'data-test-id': 'lot-add-object'},
    templateUrl: './lot-add-object.component.html'
})
/**
 * Composant pour ajouter un objet au lot comptable
 */
export class LotAddObjectComponent implements OnInit {

    /** Liste des objets pour l'ajout */
    liste: ListView<LotNdfDTO|LotFactureDTO|LotAvanceDTO, ListViewItem<LotItemDTO>>;

    /** Flag permettant de signaler la sauvegarde */
    isSaving: boolean = false;

    /**
     * Constructeur
     */
    constructor(
        private store: Store<AppState>,
        private matDialogRef: MatDialogRef<LotAddObjectComponent,Array<number>>,
        @Inject(MAT_DIALOG_DATA) public data: { itemType: LotItemType,isLotCreation: boolean,listeNotes: Array<number>,listeAvances: Array<number>,listeFactures: Array<number> },
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        //Construction du filtre d'exclusion des éléments déjà sélectionnés le cas échéant
        const selectionFilter: Filter = this.buildSelectionFilter();
        const listeStaticFilters: Array<Filter> = selectionFilter ? [selectionFilter] : null;
        
        //Définition de la liste
        switch (this.data.itemType) {
            case LotItemType.NDF:
                this.liste = new ListView<LotNdfDTO, LotAddNdfItemComponent>({
                    uri: `/controller/NDF/listeNDFComptabiliser`,
                    title: this.translateService.instant('ndf.liste.title'),
                    isFilter: true,
                    defaultOrder: "-idNDF",
                    component: LotAddNdfItemComponent,
                    mapResult: (result: Result) => {
                        //Transformation des résultats en instance
                        result.data.result.listeResultats = result.data?.result.listeResultats.map(item => new LotNdfDTO(item));
                        return result.data?.result;
                    },
                    extraOptions: {
                        isLotCreation: this.data.isLotCreation
                    },
                    listeStaticFilters: listeStaticFilters,
                    listeFilters: [
                        {
                            //Ajout du filtre sur l'objet
                            clef: 'objet',
                            title: this.translateService.instant('ndf.liste.filtres.objet'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur l'id de la ndf
                            clef: 'idNDF',
                            title: this.translateService.instant('ndf.liste.filtres.id'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le numéro
                            clef: 'numeroPiece',
                            title: this.translateService.instant('ndf.liste.filtres.numero'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur l'OM
                            clef: 'od.idOd',
                            title: this.translateService.instant('ndf.liste.filtres.om'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur la date de MAJ
                            clef: 'dateMaj',
                            title: this.translateService.instant('ndf.liste.filtres.dateStatut'),
                            type: TypeFilter[TypeFilter.DATE]
                        }, {
                            //Ajout du filtre sur la devise
                            clef: 'devise.code',
                            title: this.translateService.instant('ndf.liste.filtres.devise'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le type entité
                            clef: 'typeEntite.code',
                            title: this.translateService.instant('ndf.liste.filtres.type'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le matricule
                            clef: 'user.matricule',
                            title: this.translateService.instant('ndf.liste.filtres.matricule'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le prénom
                            clef: 'user.prenom',
                            title: this.translateService.instant('ndf.liste.filtres.prenom'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le nom
                            clef: 'user.nom',
                            title: this.translateService.instant('ndf.liste.filtres.nom'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le montant remboursable
                            clef: 'montantRemboursable',
                            title: this.translateService.instant('ndf.liste.filtres.montantRemboursable'),
                            isDefault: true,
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }, {
                            //Ajout du filtre sur le montant à rembourser
                            clef: 'montantARembourser',
                            title: this.translateService.instant('ndf.liste.filtres.montantARembourser'),
                            isDefault: true,
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }
                    ]
                });
                //Sélection du paramétrage NDF lors de l'ouverture de la popup
                this.store.select(state => state.settings?.[TypePortee.NF]).pipe(filter(settings => !!settings), first()).subscribe(settings => {
                    //Ajout des valeurs aux filtres de liste nf (en mode ajout)
                    let typeFilterAdd = this.liste?.listeFilters?.find(f => f.clef === 'typeEntite.code');
                    if (!!typeFilterAdd) {
                        typeFilterAdd.listeValues = [...settings.typeEntiteList];
                    }
                });
                break;
            case LotItemType.FACTURE:
                this.liste = new ListView<LotFactureDTO, LotAddFactureItemComponent>({
                    uri: `/controller/Facture/listeFactureComptabiliser`,
                    title: this.translateService.instant('facture.liste.title'),
                    isFilter: true,
                    defaultOrder: "-idFacture",
                    component: LotAddFactureItemComponent,
                    mapResult: (result: Result) => {
                        //Transformation des résultats en instance
                        result.data.result.listeResultats = result.data?.result.listeResultats.map(item => new LotFactureDTO(item));
                        return result.data?.result;
                    },
                    extraOptions: {
                        isLotCreation: this.data.isLotCreation
                    },
                    listeStaticFilters: listeStaticFilters,
                    listeFilters: [
                        {
                            //Ajout du filtre sur le fournisseur
                            clef: 'factureReleve.fournisseur.raisonSociale',
                            title: this.translateService.instant('facture.liste.filtres.fournisseur'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        },
                        {
                            //Ajout du filtre sur le libellé de la facture
                            clef: 'libelle',
                            title: this.translateService.instant('facture.liste.filtres.objet'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        },
                        {
                            //Ajout du filtre sur le numéro
                            clef: 'numero',
                            title: this.translateService.instant('facture.liste.filtres.numero'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur la date
                            clef: 'date',
                            title: this.translateService.instant('facture.liste.filtres.date'),
                            type: TypeFilter[TypeFilter.DATE]
                        }, {
                            //Ajout du filtre sur la devise
                            clef: 'devise',
                            title: this.translateService.instant('facture.liste.filtres.devise'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le type
                            clef: 'type',
                            title: this.translateService.instant('facture.liste.filtres.type'),
                            isDefault: true,
                            listeValues: [{
                                code: 'FAC',
                                libelle: this.translateService.instant('facture.liste.filtres.typeFAC')
                            }, {
                                code: 'AVO',
                                libelle: this.translateService.instant('facture.liste.filtres.typeAVO')
                            }]
                        }, {
                            //Ajout du filtre sur le type
                            clef: 'typeEntite.code',
                            title: this.translateService.instant('facture.liste.filtres.type'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur la réconciliation
                            clef: 'listeLiensFacOD.idOd',
                            title: this.translateService.instant('facture.liste.filtres.reconciliation'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le matricule
                            clef: 'user.matricule',
                            title: this.translateService.instant('facture.liste.filtres.matricule'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le prénom
                            clef: 'user.prenom',
                            title: this.translateService.instant('facture.liste.filtres.prenom'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le nom
                            clef: 'user.nom',
                            title: this.translateService.instant('facture.liste.filtres.nom'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le relevé
                            clef: 'factureReleve.numero',
                            title: this.translateService.instant('facture.liste.filtres.releve'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le montant
                            clef: 'montant',
                            title: this.translateService.instant('facture.liste.filtres.montant'),
                            isDefault: true,
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }
                    ]
                });
                //Selection du paramétrage Facture lors de l'ouverture de la popup
                this.store.select(state => state.settings?.[TypePortee.FC]).pipe(filter(settings => !!settings), first()).subscribe(settings => {
                    //Ajout des valeurs aux filtres de liste fc (en mode ajout)
                    let typeFilterAdd = this.liste?.listeFilters?.find(f => f.clef === 'typeEntite.code');
                    if (!!typeFilterAdd) {
                        typeFilterAdd.listeValues = [ ...settings.typeEntiteList ];
                    }
                });
                break;
            case LotItemType.AVANCE:
                this.liste = new ListView<LotAvanceDTO, LotAddAvanceItemComponent>({
                    uri: `/controller/Avance/listeAvanceComptabiliser`,
                    title: this.translateService.instant('avance.liste.title'),
                    isFilter: true,
                    defaultOrder: "-idAvance",
                    component: LotAddAvanceItemComponent,
                    mapResult: (result: Result) => {
                        //Transformation des résultats en instance
                        result.data.result.listeResultats = result.data?.result.listeResultats.map(item => new LotAvanceDTO(item));
                        return result.data?.result;
                    },
                    extraOptions: {
                        isLotCreation: this.data.isLotCreation
                    },
                    listeStaticFilters: listeStaticFilters,
                    listeFilters: [
                        {
                            //Ajout du filtre sur l'objet
                            clef: 'libelleObjet',
                            title: this.translateService.instant('avance.liste.filtres.libelleObjet'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le numéro
                            clef: 'idAvance',
                            title: this.translateService.instant('avance.liste.filtres.numero'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur l'OM
                            clef: 'idOd',
                            title: this.translateService.instant('avance.liste.filtres.om'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur la date du statut
                            clef: 'dateStatut',
                            title: this.translateService.instant('avance.liste.filtres.dateStatut'),
                            type: TypeFilter[TypeFilter.DATE]
                        }, {
                            //Ajout du filtre sur le type
                            clef: 'nature',
                            title: this.translateService.instant('avance.liste.filtres.type'),
                            listeValues: [{
                                code: 'ESP',
                                libelle: this.translateService.instant('avance.liste.filtres.typeESP')
                            }, {
                                code: 'VIR',
                                libelle: this.translateService.instant('avance.liste.filtres.typeVIR')
                            }]
                        }, {
                            //Ajout du filtre sur la devise
                            clef: 'devise.code',
                            title: this.translateService.instant('avance.liste.filtres.devise'),
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le montant
                            title: this.translateService.instant("avance.liste.filtres.montant"),
                            clef: "montant",
                            type: TypeFilter[TypeFilter.DECIMAL]
                        }, {
                            //Ajout du filtre sur le matricule
                            clef: 'user.matricule',
                            title: this.translateService.instant('avance.liste.filtres.matricule'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le prénom
                            clef: 'user.prenom',
                            title: this.translateService.instant('avance.liste.filtres.prenom'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }, {
                            //Ajout du filtre sur le nom
                            clef: 'user.nom',
                            title: this.translateService.instant('avance.liste.filtres.nom'),
                            isDefault: true,
                            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                        }
                    ]
                });
                break;
        }
    }
    
    /**
     * Construit le filtre permettant d'exclure les objets déjà sélectionnés en fonction du type d'objet
     */
    private buildSelectionFilter(): Filter {
        let clef;
        let listeObjects: Array<number>;
        let filter: Filter;
        
        //Définition des valeurs du filtre suivant le type d'objet
        switch (this.data.itemType) {
            case LotItemType.NDF:
                clef = 'idNDF';
                listeObjects = this.data.listeNotes;
                break;
            case LotItemType.FACTURE:
                clef = 'idFacture';
                listeObjects = this.data.listeFactures;
                break;
            case LotItemType.AVANCE:
                clef = 'idAvance';
                listeObjects = this.data.listeAvances;
                break;
        }
    
        //Construction du filtre si au moins un objet a été sélectionné
        if (listeObjects?.length > 0) {
            //Construction du filtre
            filter = new Filter();
            filter.clef = clef;
            filter.type = TypeFilter[TypeFilter.LONG];
            filter.typeComparaison = TypeComparaison[TypeComparaison.NOT_IN];
            filter.listeObjects = listeObjects;
        }
        
        return filter;
    }

    /**
     * Ajout au lot
     */
    addToLot() {
        //Fermeture de la popup
        this.matDialogRef.close(this.liste?.data?.listeResultats?.filter(i => i.isSelected).map(lotItem => lotItem.getIdObjetWF()));
    }

    selectAll() {
        //Définition de la liste des éléments sélectionnés
        this.liste?.data?.listeResultats?.forEach(e => e.isSelected = true);
        //Mise à jour du compteur
        this.liste.nbSelectedItems = this.liste?.data?.listeResultats?.length || 0;
    }

    unselectAll() {
        //Définition de la liste des éléments désélectionnés
        this.liste?.data?.listeResultats?.forEach(e => e.isSelected = false);
        //Mise à jour du compteur
        this.liste.nbSelectedItems = 0;
    }
}
