import {NiveauAlerte} from "../common/alerte/alerte";

/**
 * Modèle représentant l'impact budgétaire d'un objet Workflow
 *
 * @author Laurent Convert
 * @date 06/04/2022
 */
export class SuiviBudget {
    idEnveloppe: number;
    libelle: string;
    montant: number;
    devise: string;
    reelConsomme: number;
    prevConsomme: number;
    totalConsomme: number;
    pourcentageNonBloquant: number;
    pourcentageBloquant: number;
    reelObjet: number;
    prevObjet: number;
    niveauAlerte: NiveauAlerte;
    listeIdVentilation: Array<number>;

    /**
     * Constructeur
     *
     * @param suivi Object source pour initialisation de l'instance
     */
    constructor(suivi?: SuiviBudget) {
        if (suivi) {
            Object.assign(this,suivi);
        }
    }

}