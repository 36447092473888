import {Component} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {WorkflowRolesService} from "@services/admin/workflow/workflow-roles.service";
import {RolesListeItemComponent} from "@components/admin/workflow/roles/roles/liste/roles-liste-item.component";

/**
 * Composant des rôles
 *
 * @author Laurent SCIMIA
 * @date 12/06/2023
 */
@Component({
	host: {'data-test-id': 'workflow-roles'},
	templateUrl: './workflow-roles.component.html',
	providers: [{provide: 'WORKFLOW_ROLE_SERVICE', useExisting: WorkflowRolesService}]
})
export class WorkflowRolesComponent {
	//Pour utilisation dans le HTML
	Onglets: typeof Onglets = Onglets;

	/** Liste des onglets disponibles */
	listeTabItems: Array<PageHeaderItem> = [{
		code: Onglets.ROLES,
		libelle: this.translateService.instant('workflow.roles.title'),
		url: 'Admin/Workflow/Roles/Roles'
	}];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	/**
	 * Constructeur
	 *
	 * @param translateService	Service de traduction
	 * @param service			Service des rôles
	 */
	constructor(private translateService: TranslateService,
				private service: WorkflowRolesService<RolesListeItemComponent>) {
	}

	/** Indique si on est dans le détail */
	get isInDetail() {
		return this.service.isInDetail;
	}
}

/** Enumération des onglets */
enum Onglets {
	ROLES = "ROLES"
}