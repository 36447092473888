import {Component,Input,OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ListView} from '@domain/common/list-view';
import {PreferenceAnalytique} from '@domain/profil/preferenceAnalytique';
import {AnalytiqueItemComponent} from './analytique-item.component';
import {AnalytiqueSaisie} from '../../../analytique/analytique';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";

/** Composant affichant la liste des items à sélectionner pour l'analytique */
@Component({
    host: {'data-test-id': 'analytique-select'},
    selector: 'analytique-select',
    templateUrl: './analytique-select.component.html'
})
export class AnalytiqueSelectComponent implements OnInit {

    /** Id du type entité */
    @Input() idTypeEntite: number;

    /** MIB de l'objet en cours */
    @Input() idMIB: number;

    /** Id du propriétaire de l'objet */
    @Input() idCollab: number;

    /** La liste affichée */
    liste: ListView<PreferenceAnalytique, AnalytiqueItemComponent>

    constructor(private store: Store<AppState>,public dialogRef: MatDialogRef<AnalytiqueSelectComponent>) { }

    ngOnInit(): void {
        let url = `/controller/PreferencesAnalytiques/getPreferenceAnalytiqueWithTypeEntite/${this.idTypeEntite}/${this.idMIB ?? 0}/${this.idCollab}`;

        //Sélection du paramétrage
        this.store.select(state => state.settings?.['Global']).subscribe(settings => {
            //Définition de la liste
            this.liste = new ListView<PreferenceAnalytique, AnalytiqueItemComponent>({
                uri: url,
                component: AnalytiqueItemComponent,
                isFilter: false,
                extraOptions: {
                    select: (preferenceAnalytique: PreferenceAnalytique) => this.select(preferenceAnalytique),
                    settings: settings
                }
            });
        });
	}

	/** Event qui lance la sélection et ferme la liste */
    select(preferenceAnalytique: PreferenceAnalytique) {       
        const analytique: AnalytiqueSaisie = {
            pourcentage: 100,
            orga: preferenceAnalytique.orga,
            dossier: preferenceAnalytique.dossier,
            axe5: preferenceAnalytique.axe5,
            axe6: preferenceAnalytique.axe6,
            code1: preferenceAnalytique.field5,
            code2: preferenceAnalytique.field6,
            isSelection: true,
            isRemoved: false
        }

        this.dialogRef.close(analytique);
    }
}
