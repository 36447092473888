/**
 * Énumération des différents types d'anomalie pouvant être retourné lors de l'import d'une facture
 *
 * @author Laurent Convert
 * @date 03/07/2023
 */
export enum FactureTypeAnomalie {
	SUCCESS = 0,
	ODINEX_AND_MATOK = 3,
	REJECT = 4, // Non repris en V10 mais conservé pour affichage (rétrocompatibilité)
	MATINEX_AND_ODOK = 5,
	COHODMAT = 6,
	NOCHARGE = 7,
	ODMATINEX = 8,
	ERR_DOUBLON = 9,
	ERR_STATUT = 10,
	ERR_NO_PRESTATION = 11,
	COLLABNOTDEFINED = 12,
	DONT_IMPORT = 13, // Non repris en V10 mais conservé pour affichage (rétrocompatibilité)
	MATINEX_AND_OD_NOT_USED = 14,
	ERR_DEJA_COMPTABILISE = 15,
	ERR_ORGAINEX = 16, // Non repris en V10 mais conservé pour affichage (rétrocompatibilité)
	ERR_DOSSINEX = 17, // Non repris en V10 mais conservé pour affichage (rétrocompatibilité)
	ODINEX_AND_MAT_NOT_USED = 18,
	ERR_BAD_OD_4_FACTURE = 19,
}
