import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {Action} from '../../domain/action';
import {environment} from '../../environments/environment';
import {AppState} from '../../domain/appstate';
import {Result} from '../../domain/common/http/result';
import {FIND_TAUX_FOR_PRESTATION, UPDATE_TAUX_FOR_PRESTATION} from '../../reducers/saisie';
import * as moment from 'moment';
import {SaisieStateTauxPrestation} from "../../domain/saisie/saisie";
import {PrestTaux} from "../../domain/prestation/prest-taux";

@Injectable()
export class SaisieEffects {
    /**
     * Constructeur
     */
    constructor(private actions$: Actions,private http: HttpClient,private store: Store<AppState>) {

    }

    /**
     * Chargement du paramétrage
     */
    @Effect({ dispatch: false })
    findTauxForPrestation$: Observable<Action<SaisieStateTauxPrestation>> = this.actions$.pipe(
        ofType(FIND_TAUX_FOR_PRESTATION),
        map((action: Action<SaisieStateTauxPrestation>) => {
            let key: string;

            //Vérification des paramètres
            if (!!action.payload.idPrestation && !!action.payload.idPays && !!action.payload.date) {
                //Création de la clé
                key = `${action.payload.idPrestation}_${action.payload.idPays}_${action.payload.idVille || 0}_${action.payload.date}`;

                //Sélection du paramétrage
                this.store.select(state => state.saisie?.tauxPrestation).pipe(first()).subscribe(tauxPrestation => {
                    //Chargement du paramétrage
                    this.http.post<Result>(`${environment.baseUrl}/controller/Settings/findTauxForPrestation/${action.payload.idPrestation}/${action.payload.idPays || 0}/${action.payload.idVille || 0}/${action.payload.date || moment().valueOf()}/${action.payload.idNdf || 0}`,null).pipe(
                        first(),
                        map(result => result?.data)
                    ).subscribe({
                        next: (data: { taux: PrestTaux, tauxSpeciaux: Array<PrestTaux>, coursParDevise: Map<string,number> }) => {
                            //Constitution du payload de mise à jour du state
                            const payload: SaisieStateTauxPrestation = Object.assign(
                                {},
                                action.payload,
                                { key: key },
                                data
                            );

                            //Mise à jour du taux
                            this.store.dispatch({
                                type: UPDATE_TAUX_FOR_PRESTATION,
                                payload: payload
                            });
                        }
                    });
                });
            } else {
                //Pas assez d'informations pour rechercher le taux : reset
                this.store.dispatch({
                    type: UPDATE_TAUX_FOR_PRESTATION,
                    payload: {}
                });
            }

            //Retour de l'action
            return action;
        })
    );
}
