import {Component} from "@angular/core";
import {AutocompleteSearchItemComponent} from "../autocomplete-search-item.component";
import {TypePrestation} from "@domain/prestation/prestation";

/**
 * Composant d'affichage d'une ligne dans la popup de recherche avancée 'Prestation frais'
 */
@Component({
	templateUrl: './prestation-frais-search-item.html',
	host: {'data-test-id': 'presation-frais-search-item'},
})
export class PrestationFraisSearchItemComponent extends AutocompleteSearchItemComponent<any> {
	getLibellePrestationType(typePresation: TypePrestation) {
		return TypePrestation.traductionShort(typePresation);
	}
}