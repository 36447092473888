import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { VoyagesReferentielsClassesInfosComponent } from "@components/admin/voyages/referentiels/classes/classes-infos/voyages-referentiels-classes-infos.component";
import { ClassesDto } from "@domain/admin/voyages/referentiels/classesDto";
import { TypesPrestationClasse } from '@domain/admin/voyages/referentiels/types-prestation-classe.enum';
import { ListViewItem } from "@domain/common/list-view/list-view-item";
import { TranslateService } from "@ngx-translate/core";

/**
 * Composant d'affichage d'une classe dans la liste
 */
@Component({
    host: {'data-test-id': 'voyages-referentiels-classes-item'},
    selector: 'voyages-referentiels-classes-item',
    templateUrl: './voyages-referentiels-classes-item.component.html'
})
export class VoyagesReferentielsClassesItemComponent extends ListViewItem<ClassesDto> implements OnInit {
    /** Liste des prestations possibles pour une classe */
    listePrestations = [{
        id: 0,
        type: TypesPrestationClasse.AVION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avion')
    },{
        id: 1,
        type: TypesPrestationClasse.TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.train')
    },{
        id: 2,
        type: TypesPrestationClasse.HOTEL,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.hotel')
    },{
        id: 3,
        type: TypesPrestationClasse.VOITURE_DE_LOCATION,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureLocation')
    },{
        id: 4,
        type: TypesPrestationClasse.VOITURE_SOCIETE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voitureSociete')
    },{
        id: 5,
        type: TypesPrestationClasse.VOITURE_PERSONNELLE,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.voiturePersonnelle')
    },{
        id: 7,
        type: TypesPrestationClasse.AVION_TRAIN,
        libelle: this.translateService.instant('admin.voyages.referentiels.typePrestation.avionTrain')
    }];

    /** Libelle de la prestation de la classe */
    prestationLibelle: string;

    /** Enum pour l'utiliser dans le template */
    typesPrestaEnum = TypesPrestationClasse;

    /** Type de la prestation de la classe, pour afficher l'icône correspondante dans l'avatar */
    avatar: string;

    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param matDialog Boîte de dialogue
     */
    constructor(
        private matDialog: MatDialog,
        protected translateService: TranslateService
    ) {
        super();
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération du type de prestation de la classe pour avoir le libellé et le type
        let presta = this.listePrestations.find(element => this.data.typePrestation === element.id);
        this.prestationLibelle = presta.libelle;
        this.avatar = presta.type;
    }

    /**
     * Méthode appelée lors de la sélection d'une classe.
     *
     * @param classeSelected Classe sélectionnée
     */
    openClasse(classeSelected: ClassesDto) {
        //Ouverture de la popup
        this.matDialog.open(VoyagesReferentielsClassesInfosComponent, {
            data: {
                idClasse: classeSelected.idClasse,
            },
            width: '80%'
        }).afterClosed().subscribe({
            next: refresh => {
                if (refresh) {
                    //Rafraichissement de la liste
                    this.liste.refresh();
                }
            }
        });
    }
}
