import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SettingsAVState} from "../../../domain/settings/settings";

@Component({
    selector: 'avance-historique-list-item',
    templateUrl: './avance-historique-list-item.component.html'
})
export class AvanceHistoriqueListItemComponent {

    /** Avance */
    @Input() avance: any;

    /** Historique */
    @Input() historique: any;

    /** Si l'élement est le premier de la liste */
    @Input() isFirst: boolean;

    /** Paramétrage de l'avance */
    @Input() settings: SettingsAVState;

    /** Affichage des infos détaillées */
    isFull: boolean = false;


    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService) {
    }

    /**
     * Fonction permettant de récuperer les noms de mouvement workflow par rapport à l'avance
     */
    getLibelle() {
        //Initialisation de la variable de retour
        let retour = null;

        //Dans le cas ou il s'agit de la création
        if (this.isFirst) {
            retour = this.translateService.instant('avance.historique.libelles.demande');
        }
        //Dans le cas de la restitution
        if (this.historique.idAction == 14) {
            retour = this.translateService.instant('avance.historique.libelles.restitution');
        }
        //Vérification de l'action
        switch (this.historique.soft_action) {
            case 'I':
                retour = this.translateService.instant('avance.historique.libelles.imputation');
                break;
            case 'L':
                retour = this.translateService.instant('avance.historique.libelles.lettrage');
                break;
            case 'M':
                retour = this.translateService.instant('avance.historique.libelles.lettrageResiduel');
                break;
            case 'O':
                retour = this.translateService.instant('avance.historique.libelles.octroiDE');
                break;
        }
        //Dans le cas de l'Octroi
        if (this.historique.idProcSuppl == 3503) {
            retour = this.translateService.instant('avance.historique.libelles.octroi');
        }
        return retour;
    }

    /**
     * Fonction permettant de récuperer le bon montant pour les mouvements workflow des avances
     */
    getMontant() {
        //Dans le cas ou il s'agit de la création
        if (this.isFirst || this.historique.idProcSuppl == 3503) {
            return this.avance.montant;
        } else {
            return this.historique.mont;
        }
    }

    /**
     * Fonction permettant d'ajouter le commentaire standard pour certain mouvements workflow
     */
    getRemarque() {
        let remarque = "";
        //Vérification des remarques par défaut
        if (this.historique.soft_action == 'I' || this.historique.soft_action == 'L') {
            remarque = this.translateService.instant('avance.historique.remarques.imputation', {numero: this.historique.idNdf});
        } else if (this.historique.idProcSuppl == 3503) {
            remarque = this.translateService.instant('avance.historique.remarques.tauxOctroi', {taux: this.settings.isTauxInverse ? (1 / this.avance.taux) : this.avance.taux});
        } else if (this.historique.idAction == 14) {
            remarque = this.translateService.instant('avance.historique.remarques.tauxRestitution', {taux: this.settings.isTauxInverse && this.historique.tauxMoyen ? (1 / this.historique.tauxMoyen) : this.historique.tauxMoyen});
        }
        //Ajout du champ remarque aux remarques par défaut avec saut à la ligne si présente
        if (remarque !== "") {
            return this.historique.remarque ? remarque + "\n" + this.historique.remarque : remarque;
        } else {
            return this.historique.remarque;
        }
    }


    /**
     * Permet d'afficher le détail de l'élément
     */
    toggleFullView() {
        this.isFull = !this.isFull;
    }

    /** Contrôle si on a un montant */
    checkMontant(): boolean {
        return this.getMontant() &&  !isNaN(Number(this.getMontant()));
    }
}
