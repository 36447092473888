<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'menu.admin.lotsComptables' | translate" [listeItems]="listeTabItems"
                     [hidden]="isInDetail"
                     [niveauAlerte]="(dematService.hasLotEnAttente$ | async) ? alerteSynchro.niveau : null">
            <preview-alerte *ngIf="alerteSynchro != null && (dematService.hasLotEnAttente$ | async)" [alerte]="alerteSynchro" [withIcon]="'info_outline'"></preview-alerte>
        </page-header>
    </div>
</div>

<!-- Liste des lots, identique a celle du comptable -->
<lot-list *ngIf="!isInDetail"></lot-list>

<!-- Détail d'un lot, identique à la visu du comptable mais sans droits -->
<router-outlet *ngIf="isInDetail"></router-outlet>