import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Periode} from "@domain/admin/comptabilite/periode";
import {MatDialog} from "@angular/material/dialog";
import {PeriodeEditComponent} from "@components/admin/comptabilite/exercices-periodes/exercices/exercice-detail-creation/popins/periode-edit/periode-edit.component";
import {Exercice} from "@domain/admin/comptabilite/exercice";
import {PeriodeDetailComponent} from "@components/admin/comptabilite/exercices-periodes/exercices/exercice-detail-creation/popins/periode-detail/periode-detail.component";

/**
 * Item de liste d'une période
 *
 * @author Alexis BOUVARD
 * @date 19/04/2023
 */
@Component({
	host: {'data-test-id': 'periode-list-item'},
	templateUrl: './periode-list-item.component.html'
})
export class PeriodeListItemComponent extends ListViewItem<Periode> {
	/** Options supplémentaires */
	@Input() extraOptions: {
		exercice: Exercice
	}

	/**
	 * Constructeur
	 *
	 * @param matDialog l'utilitaire des popins
	 */
	constructor(
		private matDialog: MatDialog
	) {
		//Constructeur parent
		super();
	}

	/**
	 * Ouverture d'une période pour édition
	 */
	editPeriode(): void {
		//Ouverture de la popin
		this.matDialog.open(PeriodeEditComponent,{
			data: {
				periode: this.data,
				exercice: this.extraOptions.exercice
			}
		}).afterClosed().subscribe((libelle: string) => {
			//Si un libellé est présent
			if (libelle) {
				//On écrase le libellé existant
				this.data.libelle = libelle;
			}
		});
	}

	/**
	 * Affichage de la popin de détail des objets à traiter
	 */
	showObjetsATraiter(): void {
		//Ouverture de la popin
		this.matDialog.open(PeriodeDetailComponent,{
			data: this.data
		});
	}
}
