<h1 mat-dialog-title>
    <span class="left">
        <span [translate]="'lieu.selectionLocalisation'"></span>
    </span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>
    <div *ngIf="!isLoading" class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngFor="let geolocalisation of listeGeolocalisation">
                <div class="lvi-content clickable" (click)="geolocalisation.isDisplayed = !geolocalisation.isDisplayed">
                    <div class="avatar">
                        <i class="material-icons-outlined">location_on</i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <span class="flag-icon mr-1" [ngClass]="'flag-icon-'+geolocalisation.codePays.toLowerCase()"></span>
                            <a [routerLink]="" (click)="selectLocalisation(geolocalisation); $event.stopPropagation();">{{ geolocalisation.adresse }}</a>
                        </div>
                        <ul class="lvi-attrs">
                            <li *ngIf="geolocalisation.departement"><strong>{{ 'lieu.departement' | translate }}</strong>{{ geolocalisation.departement }}</li>
                            <li *ngIf="geolocalisation.region"><strong>{{ 'lieu.region' | translate }}</strong>{{ geolocalisation.region }}</li>
                        </ul>
                    </div>
                    <div class="lvi-actions">
                        <button mat-icon-button>
                            <mat-icon *ngIf="geolocalisation.isDisplayed" color="primary">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="!geolocalisation.isDisplayed" color="primary">keyboard_arrow_down</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="geolocalisation.isDisplayed" class="p-20">
                    <form autocomplete="off">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="row">
                                    <label class="col-md-3">
                                        <span [translate]="'lieu.rue'"></span>
                                    </label>
                                    <div class="col-md-9">
                                        <div class="d-flex">
                                            <span>{{ geolocalisation.rue }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-md-3">
                                        <span [translate]="'lieu.codePostal'"></span>
                                    </label>
                                    <div class="col-md-9">
                                        <div class="d-flex">
                                            <span>{{ geolocalisation.codePostal }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-md-3">
                                        <span [translate]="'lieu.ville'"></span>
                                    </label>
                                    <div class="col-md-9">
                                        <div class="d-flex">
                                            <span>{{ geolocalisation.ville }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row">
                                    <label class="col-md-3">
                                        <span [translate]="'lieu.departement'"></span>
                                    </label>
                                    <div class="col-md-9">
                                        <div class="d-flex">
                                            <span>{{ geolocalisation.departement }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-md-3">
                                        <span [translate]="'lieu.region'"></span>
                                    </label>
                                    <div class="col-md-9">
                                        <div class="d-flex flex-row">
                                            <span>{{ geolocalisation.region }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-md-3">
                                        <span [translate]="'lieu.pays'"></span>
                                    </label>
                                    <div class="col-md-9">
                                        <div class="d-flex">
                                            <span>{{ geolocalisation.pays }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="empty" *ngIf="!listeGeolocalisation?.length"><span [translate]="'lieu.aucunResultat'"></span></div>
        </div>
    </div>
</div>