import {ListItem} from "../common/list-view/list-item";
import {OrgaDTO} from "../od/od";
import {User} from "@domain/user/user";
import {UserPipeTransform} from "@share/pipe/user.pipe";

/**
 * DTO User
 */
export class UserDto implements ListItem {

    idUser: number;
    nom: string;
    prenom: string;
    nomAcces?: string;
    matricule: string;
    fonctions?: string;
    actif?: boolean;

    orga?: OrgaDTO;

    getKey(): number {
        return this.idUser;
    }

    /**
     * Constructeur*
     *
     * @param dto DTO
     */
    constructor(dto?: UserDto | User) {
        //Vérification du passage d'un DTO
        if (dto) {
            //Vérification du type de DTO
            if (dto instanceof User) {
                //Copie uniquement des propriétés en commun avec le type de destination
                for (const key in Object.keys(this)) {
                    this[key] = dto[key];
                }
            } else {
                //Même type entre source et destination : copie intégrale
                Object.assign(this,dto);
            }
        }
    }

    /**
     * Retourne la chaîne formatée pour l'affichage de l'utilisateur.
     * Note : Passage par un getter pour pouvoir être utilisé dans un autocomplete (via custom-input)
     */
    get nomPrenomMatricule(): string {
        return UserPipeTransform({nom: this.nom,prenom: this.prenom,matricule: this.matricule});
    }

}