import {Component,Inject,OnInit,Type} from '@angular/core';
import {Facture} from "@domain/facture/facture";
import {FactureContentieux} from "@domain/facture/facture-contentieux";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {Level,LigneRapprochement} from "@domain/facture/ligne-rapprochement";
import {TypeCodeErreur} from "@domain/common/http/result";
import {catchError,finalize} from "rxjs/operators";
import {TypeNature} from "@domain/typecharge/type-nature";
import {Observable,of} from "rxjs";
import {TypeFact} from "@domain/facture/type-fact";
import {FactureService} from "@components/facture/facture.service";
import {DecimalPipe} from "@angular/common";

/**
 * Écarts entre les devis et les montants des factures
 *
 * @author Laurent Convert
 * @date 27/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-ecarts'},
    styleUrls: ['./facture-ecarts.component.scss'],
    templateUrl: './facture-ecarts.component.html'
})
export class FactureEcartsComponent implements OnInit {
    /* Déclaration pour accès dans le template */
    TypeNature = TypeNature;
    TypeFact = TypeFact;
    Level = Level;
    LigneRapprochement = LigneRapprochement;
    
    /** Liste des lignes de rapprochement */
    listeRapprochementNature: Array<LigneRapprochement>;
    
    /** Indicateur de chargement initial */
    isLoading: boolean;

    /**
     * Constructeur
     *
     * @param data Paramètres passés à l'ouverture de la popin
     * @param matDialogRef
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param factureService Service de gestion des factures
     * @param decimalPipe Formatage des nombres
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: {facture: Facture,contentieux: FactureContentieux},
                private matDialogRef: MatDialogRef<FactureEcartsComponent>,
                public translateService: TranslateService,
                private toastrService: ToastrService,
                private factureService: FactureService,
                private decimalPipe: DecimalPipe) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Début du chargement des données
        this.isLoading = true;
        
        //Récupération des données pour le niveau "NATURE"
        this.loadListeLignesRapprochement({
            idObjet: this?.data.facture.od.idOd,
            level: Level.NATURE
        }).pipe(finalize(() => this.isLoading = false)).subscribe(lignes => {
            //Mise à jour des lignes de rapprochement initiales (niveau NATURE)
            this.listeRapprochementNature = lignes;
        });
    }
    
    /**
     * Récupération de la liste des lignes de rapprochement en fonction du contexte décrit par le paramètre
     *
     * @param ligne Contexte des lignes de rapprochement à récupérer
     */
    private loadListeLignesRapprochement(ligne: LigneRapprochement): Observable<Array<LigneRapprochement>> {
        return this.factureService.loadRapprochement(ligne)
            .pipe(catchError(error => {
                //Affichage d'un toast d'erreur
                TypeCodeErreur.showError(error,this.translateService,this.toastrService);
        
                //Retour d'un résultat vide
                return of(null);
            }));
    }
    
    /**
     * Ouverture / fermeture du détail de la ligne
     *
     * @param ligneRapprochement La ligne en cours
     */
    toggle(ligneRapprochement: LigneRapprochement) {
        //Ouverture / fermeture du détail de la ligne
        ligneRapprochement.unfolded = !ligneRapprochement.unfolded;
        
        //Récupération des enfants le cas échéant lors de l'affichage du détail de la ligne
        if (ligneRapprochement.unfolded && !ligneRapprochement.childs) {
            //Construction de l'objet à envoyer au back
            let ligne: LigneRapprochement = {
                idObjet: ligneRapprochement.level == Level.FACTURE ? ligneRapprochement.idObjet : this?.data.facture.od.idOd,
                nature: ligneRapprochement.nature,
                level: ligneRapprochement.level == Level.NATURE ? Level.FACTURE : ligneRapprochement.level == Level.FACTURE ? Level.LIGNE_FACTURE : Level.NATURE
            };
            
            //Recherche
            this.loadListeLignesRapprochement(ligne).subscribe(lignes => {
                //Mise à jour des lignes de détail de la ligne courante
                ligneRapprochement.childs = lignes;
            });
        }
    }
    
    /**
     * Permet de spécifier le type d'un tableau dans le template
     */
    castArrayIn<T>(src: any,type:Type<T>): Array<T> {
        return src as (Array<T>);
    }
    
    /**
     * Vérification de la nécessité d'afficher le tooltip de détail pour une ligne de rapprochement
     */
    isShowTooltip(ligneRapprochement: LigneRapprochement): boolean {
        let isNatureInconnue;
        let isAlerteMontant;
        let isAlertePourcent;
        
        //Vérification du niveau
        if (ligneRapprochement.level == 'NATURE') {
            //Vérification de la nature de la ligne
            isNatureInconnue = ligneRapprochement.nature == TypeNature.INCONNUE;
            
            //Vérification de l'activation des tolérances et de l'existence d'un écart
            if ((ligneRapprochement.tolerance.active || isNatureInconnue) && ligneRapprochement.montantEcart !== null) {
                //Récupération de la présence d'alertes
                isAlerteMontant =  ligneRapprochement.montantEcart > ligneRapprochement.tolerance.toleranceMontant;
                isAlertePourcent =  ligneRapprochement.montantEcart > ligneRapprochement.tolerance.tolerance * ligneRapprochement.montantDevis;
                
                //Vérification des alertes à afficher
                if (isAlerteMontant || isAlertePourcent) {
                    //Retourne vrai
                    return true;
                }
            }
        }
        
        //Retourne faux dans les autres cas
        return false;
    };

    /**
     * Calcul et retourne le pourcentage formaté d'écart de la ligne.
     *
     * @param ligneRapprochement Ligne à la base du calcul
     */
    getPourcentEcart(ligneRapprochement: LigneRapprochement): string | undefined {
       if (ligneRapprochement.montantDevis != 0 && ligneRapprochement.montantEcart !== null) {
            return (ligneRapprochement.montantEcart >= 0 ? '+' : '')
                + this.decimalPipe.transform(100 / ligneRapprochement.montantDevis * ligneRapprochement.montantEcart,'1.0-0');
       } else {
           return undefined;
       }
    }
}
