import {ParamConnexionValeur} from "./param-connexion-valeur";
import {ParamConnexion} from "./param-connexion";
import {TravelHubSBTConfigUsed} from "./travel-hub-sbt-config-used";
import {ListItem} from "@domain/common/list-view/list-item";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";

/**
 * Représente la configuration faite par un utilisateur d'un paramètre de connexion
 *
 * @author Laurent Convert
 * @date 20/11/2023
 */
export class ParamConnexionUsed implements ListItem {
	idParamConnexionUsed: number;
	paramConnexion: ParamConnexion;
	sbtConfigUsed: TravelHubSBTConfigUsed;
	typeField: number;
	requete: string;
	valeur: string;
	paramConnexionValeur: ParamConnexionValeur;
	codeMapping: string;

	/** Liste des alertes */
	listeAlertes?: ListeAlertes;

	getKey(): number {
		return this.idParamConnexionUsed;
	}

	constructor(dto?: ParamConnexionUsed) {
		if (dto) {
			Object.assign(this,dto);
		}
	}
}