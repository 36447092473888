<div class="with-floating-button">
    <div class="content">
        <page-header
                [hidden]="isHeaderHidden"
                [title]="'workflow.conditions.title' | translate"
                [listeItems]="listeTabItems"
                [selectedTab]="selectedItem$.asObservable()"
                (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <router-outlet></router-outlet>
    </div>
</div>