import {Component,ElementRef,OnInit,ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

import {AppState} from '@domain/appstate';
import {Session} from '@domain/security/session';
import {INIT_LAYOUT} from '@reducers/layout';
import {SettingsGlobalState} from "@domain/settings/settings";

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {
    /** Référence vers le menu */
    @ViewChild('menuRef',{ static: true }) menuRef: ElementRef;

    /** Données */
    public session: Session = null;
    public settings: SettingsGlobalState;
    public isLayoutPostLogin: boolean;

    /**
     * Constructeur par défaut
     */
    constructor(public router: Router,private store: Store<AppState>) {

    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Sélection de la session
        this.store.select<Session>(s => s.session).subscribe(session => {
            //Mise à jour de la session
            this.session = session;

            //Suivant l'état de la session détermine si le layout post login doit être affiché
            this.isLayoutPostLogin = this.session.isLogged && !this.session.isPasswordExpired;

            //Vérification de l'authentification de l'utilisateur
            if (this.session.isLogged) {
                //Synchronisation des comptes et opérations
                this.store.dispatch({
                    type: INIT_LAYOUT,
                    payload: {
                        user: this.session.user
                    }
                });
                //Sélection du paramétrage
                this.store.select(state => state.settings?.['Global']).subscribe(settings => this.settings = settings);
            }
        });
    }
}
