import {Component,Inject,OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Log} from "@domain/admin/maintenance/log";
import {MaintenanceTask} from "@domain/admin/maintenance/maintenance-task";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";

/**
 * Composant d'affichage des logs
 */
@Component({
    host: {'data-test-id': 'log'},
    selector: 'log',
    templateUrl: './log.component.html',
    styleUrls: ['log.component.scss']
})
export class LogComponent implements OnInit {
    /** Log */
    log : Log = new Log();

    /** Log filtré pour affichage */
    displayLog: string;

    /** Bascules pour les checkboxes */
    isError: boolean = true;
    isDebug: boolean = true;
    isInfo: boolean = true;
    isWarning: boolean = true;

    /** Options pour l'affichage du log */
    codeMirrorOptions: any = {
        theme: 'material',
        mode: null,
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true,
        viewportMargin: Infinity
    };

    /**
     * Constructeur
     */
    constructor(
        protected matDialogRef: MatDialogRef<LogComponent>,
        private migrationsService: MigrationsService,
        @Inject(MAT_DIALOG_DATA) public data: { task: MaintenanceTask }
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération des logs
        this.migrationsService.getLog(this.data?.task?.idTask).subscribe((result) => {
            //Chargement des logs
            this.log = new Log(result?.data?.log);

            //Affichage des logs
            this.displayLog = this.log.getLog(true, true, true, true);
        });
    }

    /**
     * Mise à jour de l'affichage des logs
     */
    updateDisplay(): void {
        //Affichage des logs
        this.displayLog = this.log.getLog(this.isError, this.isDebug, this.isInfo, this.isWarning);
    }
}
