<form #form="ngForm">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.title'"></span>
        </mat-card-title>
        <mat-card-content>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="libelle"
                        name="libelle"
                        customType="input"
                        [(ngModel)]="zu.libelle"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.libelle"
                        [maxlength]="50"
                        [required]="true">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="actif"
                        name="actif"
                        customType="checkbox"
                        [ngModel]="zu.actif"
                        [(customModel)]="zu.actif"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.actif">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="portee"
                        name="portee"
                        customType="select"
                        [(ngModel)]="zu.idPortee"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.portee"
                        [postTooltip]="zu.hasData ? ('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.porteeTooltip' | translate) : null"
                        [selectOptions]="listePortee"
                        optionValue="code"
                        optionDisplay="libelle"
                        [required]="true"
                        [disabled]="zu.hasData">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="obligatoire"
                        name="obligatoire"
                        customType="checkbox"
                        [ngModel]="zu.obligatoire"
                        [(customModel)]="zu.obligatoire"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.obligatoire"
                        postLibelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.obligatoireTooltip">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="reference"
                        name="reference"
                        customType="input"
                        [(ngModel)]="zu.reference"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.reference"
                        [maxlength]="2000">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="code"
                        name="code"
                        customType="input"
                        [(ngModel)]="zu.code"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.code"
                        [postTooltip]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.codeTooltip' | translate"
                        [maxlength]="100">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="typeZone"
                        name="typeZone"
                        customType="select"
                        [(ngModel)]="zu.typeChamp"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.typeZone"
                        [postTooltip]="zu.hasData ? ('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.typeZoneTooltip' | translate) : null"
                        [selectOptions]="listeTypeZone"
                        optionValue="code"
                        optionDisplay="libelle"
                        (ngModelChange)="onTypeZoneChange()"
                        [required]="true"
                        [disabled]="zu.hasData">
                </custom-input>
                <custom-input
                        ngDefaultControl
                        id="ordre"
                        name="ordre"
                        customType="number"
                        [(ngModel)]="zu.ordre"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.ordre">
                </custom-input>
            </div>
            <div class="row">
                <custom-input
                        ngDefaultControl
                        id="description"
                        name="description"
                        customType="input"
                        [(ngModel)]="zu.description"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.infobulle">
                </custom-input>
                <custom-input
                        *ngIf="zu.typeChamp === TypeZoneUtilisateur.TEXTE"
                        ngDefaultControl
                        id="texteDefaut"
                        name="texteDefaut"
                        customType="input"
                        [(ngModel)]="zu.texteDefaut"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefaut">
                </custom-input>
                <custom-input
                        *ngIf="zu.typeChamp === TypeZoneUtilisateur.CHECKBOX"
                        ngDefaultControl
                        id="boolDefaut"
                        name="boolDefaut"
                        customType="checkbox"
                        [ngModel]="zu.boolDefaut"
                        [(customModel)]="zu.boolDefaut"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefaut"
                        [postLibelle]="'global.' + (zu.boolDefaut ? 'oui' : 'non')">
                </custom-input>
                <custom-input
                        *ngIf="zu.typeChamp === TypeZoneUtilisateur.MONTANT"
                        ngDefaultControl
                        id="montantDefaut"
                        name="montantDefaut"
                        customType="montant"
                        [(ngModel)]="zu.montantDefaut"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefaut">
                </custom-input>
                <custom-input
                        *ngIf="zu.typeChamp === TypeZoneUtilisateur.DATE"
                        ngDefaultControl
                        id="dateDefaut"
                        name="dateDefaut"
                        customType="date"
                        [ngModel]="zu.dateDefaut"
                        [(customModel)]="zu.dateDefaut"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefaut">
                </custom-input>
                <custom-input
                        *ngIf="zu.typeChamp === TypeZoneUtilisateur.HEURE"
                        ngDefaultControl
                        id="heureDefaut"
                        name="heureDefaut"
                        customType="heure"
                        [(ngModel)]="zu.heureDefaut"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefaut">
                </custom-input>
                <custom-input
                        *ngIf="[TypeZoneUtilisateur.LISTE_UNIQUE,TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE,TypeZoneUtilisateur.LISTE_MULTIPLE,TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE].includes(zu.typeChamp)"
                        ngDefaultControl
                        id="valeurDefautPlaceholder"
                        name="valeurDefautPlaceholder"
                        customType="input"
                        [(ngModel)]="zu.texteDefaut"
                        libelle="admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefaut"
                        [postTooltip]="'admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.configZoneParam.valeurDefautTooltip' | translate"
                        [disabled]="true">
                </custom-input>
            </div>
        </mat-card-content>
    </mat-card>
</form>

<list-view *ngIf="listeZuRef && zu.idZU > 0 && hasReferentiel()" [liste]="listeZuRef"></list-view>
<list-view *ngIf="listeFiltreZU && zu.idZU > 0" [liste]="listeFiltreZU"></list-view>