import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Result} from '@domain/common/http/result';
import {environment} from '@environments/environment';
import {Localisation} from "@domain/geolocalisation/localisation";
import {Adresse} from "@domain/profil/adresse";
import {first} from "rxjs/operators";

@Injectable()
export class AdresseService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) { }

	/**
	 * Recherche de la localisation
	 */
	public searchLocation(location: string): Observable<Array<Localisation>> {
		//Recherche de la localisation
		return this.http.post<Result>(`${environment.baseUrl}/controller/Map/searchLocation`,{
			rue: location
		}).pipe(map(result => result?.data?.listeLocalisations));
	}

	/**
	 * Recherche de la distance
	 */
	public findDistance(start: { lat: number,lng: number },end: { lat: number,lng: number },isAllerRetour?: boolean): Observable<number> {
		//Recherche de la localisation
		return this.http.post<Result>(`${environment.baseUrl}/controller/Map/findDistance/${isAllerRetour || false}`,{
			start,
			end
		}).pipe(map(result => result?.data?.distance));
	}

	/**
	 * Retourne la ville à partir de l'adresse
	 *
	 * @param adresse l'adresse
	 */
	getVilleFromAdress(adresse: Adresse): string {
		//Si on a une ville
		if (adresse?.ville != null) {
			return adresse.ville
		} else if (adresse?.adresseVille?.libelle) {
			//Si on a une adresseVille avec un libellé
			return adresse.adresseVille?.libelle;
		}

		return "";
	}

	/**
	 * Retourne le pays à partir de l'adresse
	 *
	 * @param adresse l'adresse
	 */
	getPaysWithPays(adresse: Adresse): string {
		//Si on a un pays
		if (adresse?.pays != null) {
			return adresse.pays
		} else if (adresse?.adresseVille?.pays?.libelle) {
			//Si on a une adresse ville avec un pays et un libellé
			return adresse.adresseVille?.pays?.libelle;
		}

		return "";
	}

	/**
	 * Retourne l'utilisateur lié à l'adresse
	 */
	getUser(idUser: number): Observable<Result> {
		return this.http.get<Result>(`${environment.baseUrl}/controller/AdresseAdmin/getUser/${idUser}`).pipe(first())
	}
}
