import {User} from "../user/user";
import {ParticipantExterne} from "../participant/participant-externe";
import {ParticipantAvecFavoris} from "../participant/participant-avec-favoris";
import {ParticipantLien} from "../participant/participant-lien";

/**
 * Classe de transfert des {@link LienOmUser} vers le front
 */
export class LienOmUser extends ParticipantLien {
	/** Identifiant de la liaison facture - ordre de mission */
	idLienOmUser: number;

	/** Participant interne */
	participantInterne: User;

	/** Indique si l'utilisateur N'EST PAS organisateur (ATTENTION : piège ! ici le booléen est inversé pour toujours plus de plaisir) */
	notOrganisateur: boolean;

	get id(): number { return this.idLienOmUser; }
	set id(id: number) { this.idLienOmUser = id; }

	/**
	 * Constructeur
	 * @param participant Participant utilisé pour initialiser les valeurs
	 */
	constructor(participant: ParticipantExterne | ParticipantAvecFavoris | LienOmUser) {
		super();

		//Le participant passé en paramètre vient de la création d'un participant externe
		if (participant && participant instanceof ParticipantExterne) {
			this.initFromParticipantExterne(participant);
		} else if (participant && ParticipantAvecFavoris.isParticipantAvecFavoris(participant)) {
			//Cas du participant avec favoris
			this.initFromParticipantAvecFavoris(participant);
		} else {
			//Le paramètre est un LienOmUser
			Object.assign(this, participant);
		}
	}
}
