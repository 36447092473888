import {NgModule} from '@angular/core';
import {Routes} from "@angular/router";
import {AdminGuardProvider} from '@core/security/admin-guard.provider';
import {ShareModule} from "@share/share.module";
import {ExercicesPeriodesExercicesComponent} from './exercices/exercices-periodes-exercices.component';
import {ComptabiliteExercicesPeriodesComponent} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.component";
import {ExercicesListItemComponent} from './exercices/exercice-list-item/exercices-list-item.component';
import {ExerciceDetailCreationComponent} from './exercices/exercice-detail-creation/exercice-detail-creation.component';
import {ComptabiliteExercicesPeriodesService} from "@components/admin/comptabilite/exercices-periodes/comptabilite-exercices-periodes.service";
import {PeriodeListItemComponent} from './exercices/exercice-detail-creation/periode-list-item/periode-list-item.component';
import {WorkflowModule} from "@components/workflow/workflow.module";
import {PeriodeEditComponent} from './exercices/exercice-detail-creation/popins/periode-edit/periode-edit.component';
import {PeriodeClotureComponent} from './exercices/exercice-detail-creation/popins/periode-cloture/periode-cloture.component';
import {PeriodeDetailComponent} from './exercices/exercice-detail-creation/popins/periode-detail/periode-detail.component';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ObjetATraiterListItemComponent} from './exercices/exercice-detail-creation/popins/periode-detail/objet-a-traiter-list-item/objet-a-traiter-list-item.component';
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Routes du menu admin Comptabilité / Exercices et périodes
 */
export const comptabiliteExercicesPeriodesRoutes: Routes = [
	{
		path: 'Exercices',
		component: ExercicesPeriodesExercicesComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_COMPTA_EXERCICE] }
	},{
		path: 'Exercice/:idExercice',
		component: ExerciceDetailCreationComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_COMPTA_EXERCICE] }
	},{
		path: '',
		redirectTo: 'Exercices',
		pathMatch: 'full'
	},{
		path: '**',
		redirectTo: 'Exercices'
	}
]

/**
 * Module du menu Comptabilité / Exercices et périodes
 */
@NgModule({
	imports: [
		ShareModule,
		WorkflowModule,
		PreviewAlerteModule
	],
	declarations: [
		ComptabiliteExercicesPeriodesComponent,
		ExercicesPeriodesExercicesComponent,
		ExercicesListItemComponent,
		ExerciceDetailCreationComponent,
		PeriodeListItemComponent,
		PeriodeEditComponent,
		PeriodeClotureComponent,
		PeriodeDetailComponent,
		ObjetATraiterListItemComponent
	],
	providers: [
		ComptabiliteExercicesPeriodesService
	]
})
export class ComptabiliteExercicesPeriodesModule {
}
