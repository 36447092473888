import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {environment} from '../../environments/environment';
import {AppState} from '../../domain/appstate';
import {Result} from '../../domain/common/http/result';
import {FIND_TYPE_ENTITE, UPDATE_TYPE_ENTITE} from "../../reducers/type-entite";
import {TypeEntite, TypeEntiteState} from "../../domain/typeentite/typeEntite";
import {Action, PayloadFindTypeEntite} from "../../domain/action";

/**
 * Effect pour le chargement d'un type entité dans le store
 */
@Injectable()
export class TypeEntiteEffects {
    /**
     * Constructeur
     */
    constructor(private actions$: Actions,private http: HttpClient,private store: Store<AppState>) { }

    /**
     * Chargement d'un type entité
     */
    @Effect({ dispatch: false })
    findTypeEntite$: Observable<Action<PayloadFindTypeEntite>> = this.actions$.pipe(
        ofType(FIND_TYPE_ENTITE),
        map((action: Action<PayloadFindTypeEntite>) => {
            //Sélection d'un type entité
            this.store.select(state => state.typeEntite).pipe(first()).subscribe((typeEntiteState: TypeEntiteState) => {
                //Récupération du type entité dans le store
                const typeEntite: TypeEntite = typeEntiteState[action.payload.portee];

                //Vérification de la nécessité de récupérer les données depuis le serveur
                if (action.payload.force || !typeEntite || typeEntite.idTypeEntite != action.payload.idTypeEntite) {
                    //Chargement d'un type entité
                    this.http.post<Result>(`${environment.baseUrl}/controller/TypeEntite/${action.payload.idTypeEntite}/${action.payload.portee}`,null).pipe(
                        first(),
                        map(result => result?.data?.typeEntite)
                    ).subscribe({
                        next: (data: TypeEntite) => {
                            //Mise à jour du type entité
                            this.store.dispatch({
                                type: UPDATE_TYPE_ENTITE,
                                payload: {
                                    portee: action.payload.portee,
                                    typeEntite: data
                                }
                            });
                        }
                    });
                }
            });

            //Retour de l'action
            return action;
        })
    );
}
