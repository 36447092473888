/**
 * Classe représentant les assurances pour les véhicules
 */
export class Assurance{
    id?: number;
    idPa: number;
    idVehiculeAssurance?: number;
    numContrat?: string;
    nbPJ?: number;
    dateDebut?: Date;
    dateFin?: Date;
    commentaire?: string;

    constructor() {
        this.id = 0;
        this.idVehiculeAssurance = 0;
    }
}
