<mat-form-field class="d-flex" [floatLabel]="floatLabel">
    <mat-label *ngIf="!!label">{{label | translate}}</mat-label>
    <mat-date-range-input #aze="matDateRangeInput"
            [rangePicker]="$any(picker)"
            [comparisonStart]="rangeDeb"
            [comparisonEnd]="rangeEnd"
            [min]="minDate"
            [max]="maxDate"
            [required]="required"
            [disabled]="disabled">
        <input matStartDate name="dateStartField" [(ngModel)]="maDate" (dateChange)="dateChange.emit($event.value);" [required]="required" [disabled]="disabled">
        <input matEndDate name="dateEndField" [disabled]="disabled">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker [startAt]="dateStart" [disabled]="disabled"></mat-date-range-picker>
</mat-form-field>
