<h1 mat-dialog-title>
    <span [translate]="'admin.voyages.travelhub.profilConnexion.aiguillage'"></span>
    <span [mat-dialog-close]=false><i class="zmdi zmdi-close"></i></span>
</h1>

<ng-container *ngIf="!isLoading; else pleaseWait;">
    <div mat-dialog-content>
        <form #form="ngForm" autocomplete="off">
            <div class="row">
                <custom-input ngDefaultControl
                      id="typeNature"
                      name="typeNature"
                      lCol="4" rCol="8"
                      customType="select"
                      [(ngModel)]="aiguillage.typeNature"
                      [selectOptions]="adminTravelhubService.listeTypeNature"
                      (onChange)="updateListeSBT()"
                      [required]="true"
                      optionValue="value"
                      optionDisplay="libelle"
                      libelle="admin.voyages.travelhub.profilConnexion.nature"></custom-input>
            </div>
            <div class="row">
                <custom-input ngDefaultControl
                      id="typeAiguillage"
                      name="typeAiguillage"
                      lCol="4" rCol="8"
                      customType="select"
                      [(ngModel)]="aiguillage.typeAiguillage"
                      [selectOptions]="listeTypeAiguillage"
                      (onChange)="updateListeSBT()"
                      [required]="true"
                      optionValue="value"
                      optionDisplay="libelle"
                      libelle="admin.voyages.travelhub.profilConnexion.aiguillage"></custom-input>
            </div>
            <ng-container *ngIf="aiguillage.typeNature != null && (aiguillage.typeAiguillage === TypeAiguillage.ONLINE || aiguillage.typeAiguillage === TypeAiguillage.OFFLINE)">
                <div class="row">
                    <custom-input ngDefaultControl
                          id="configuration"
                          name="configuration"
                          lCol="4" rCol="8"
                          customType="objectselect"
                          [(ngModel)]="aiguillage.travelHubSBTConfigUsed"
                          [selectOptions]="listeSBT"
                          [required]="listeSBT.length > 0"
                          (ngModelChange)="updateLibelle()"
                          optionValue="idSBTConfigUsed"
                          optionDisplay="libelle"
                          libelle="admin.voyages.travelhub.profilConnexion.configuration"></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                          id="fournisseur"
                          name="fournisseur"
                          lCol="4" rCol="8"
                          customType="input"
                          [ngModel]="aiguillage.travelHubSBTConfigUsed?.sbtConfig?.agence ? aiguillage.travelHubSBTConfigUsed.sbtConfig.agence + ' ' + aiguillage.travelHubSBTConfigUsed.libelle :  '-'"
                          [readonly]="true"
                          libelle="admin.voyages.travelhub.profilConnexion.fournisseur"></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                          id="libelle"
                          name="libelle"
                          lCol="4" rCol="8"
                          customType="input"
                          [(ngModel)]="aiguillage.libelle"
                          [required]="true"
                          maxlength="2000"
                          [postTooltip]="'admin.voyages.travelhub.profilConnexion.tooltipLibelleVisible' | translate"
                          libelle="admin.voyages.travelhub.profilConnexion.libelle"></custom-input>
                </div>
            </ng-container>
            <ng-container *ngIf="aiguillage.typeNature != null && aiguillage.typeAiguillage === TypeAiguillage.INTERNE">
                <div class="row">
                    <custom-input ngDefaultControl
                          id="workflow"
                          name="workflow"
                          lCol="4" rCol="8"
                          customType="objectselect"
                          [(ngModel)]="aiguillage.portee"
                          [selectOptions]="listePortee"
                          [required]="true"
                          optionValue="idPortee"
                          optionDisplay="libelle"
                          libelle="admin.voyages.travelhub.profilConnexion.workflow"></custom-input>
                </div>
            </ng-container>
        </form>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button color="warn" (click)="deleteAiguillage()" [disabled]="isProcessing" *ngIf="!isCreation">
            <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
        </button>
        <button mat-flat-button color="primary" [disabled]="form.invalid || isProcessing" (click)="saveAiguillage()">
            <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
            <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
        </button>
    </div>
</ng-container>

<ng-template #pleaseWait>
    <please-wait></please-wait>
</ng-template>