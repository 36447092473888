<div *ngIf="!!lotHeader && !isLoading; else tplLoading" class="with-floating-button">
    <div class="content">
        <page-header [title]="lotHeader.idLot === 0 ? ('lot.titleCreation' | translate) : ('lot.title' | translate:{ numero: lotHeader.idLot })"
            [listeItems]="listeTabItems"
            [extraInfo]="getStatutArchivage()"
            [statutArchivage]="lot?.statutArchivage"
            [settings]="settings"
            (onSelectedItemChange)="onSelectedItemChange($event)"
            [selectedIndex]="selectedIndex"
            (onGoBack)="onGoBack()">
        </page-header>

        <div *ngIf="canSeeDetail && isDetailLoaded" [hidden]="!isShowDetail">
            <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
                <lot-generalites [lot]="lot" [settings]="settings" [canModifier]="canModifier"></lot-generalites>
            </div>
            <div [hidden]="selectedItem?.code !== Onglets.NDF">
                <list-view [liste]="listeNDF" *ngIf="listeNDF"></list-view>
                <list-view #listeViewNDFCreation [liste]="listeNDFCreation" *ngIf="listeNDFCreation"></list-view>
            </div>
            <div [hidden]="selectedItem?.code !== Onglets.AVANCE">
                <list-view [liste]="listeAV" *ngIf="listeAV"></list-view>
                <list-view #listeViewAVCreation [liste]="listeAVCreation" *ngIf="listeAVCreation"></list-view>
            </div>
            <div [hidden]="selectedItem?.code !== Onglets.FACTURE">
                <list-view [liste]="listeFC" *ngIf="listeFC"></list-view>
                <list-view #listeViewFCCreation [liste]="listeFCCreation" *ngIf="listeFCCreation"></list-view>
            </div>
        </div>
        <router-outlet (activate)="isShowDetail = false;" (deactivate)="isShowDetail = true;"></router-outlet>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>
<ng-template #tplLoading>
    <please-wait class="content"></please-wait>
</ng-template>
