import {Component,ComponentFactory,ComponentFactoryResolver,ComponentRef,Input,OnInit,ReflectiveInjector,Type,ViewChild,ViewContainerRef} from '@angular/core';
import {ItemTitleComponent} from '@share/component/affichage-hierarchique/title-components/item-title/item-title.component';

/**
 * Composant générique permettant d'afficher un autre composant passé en paramètre
 *
 * @author Laurent SCIMIA
 * @date 01/12/2023
 */
@Component({
	host: {'data-test-id': 'component-holder'},
	selector: 'component-holder',
	template: '<template #holder></template>'
})
export class ComponentHolderComponent<T> implements OnInit {
	/** Composant à afficher */
	@Input() composant!: Type<ItemTitleComponent<T>>;

	/** Inputs pour le composant à afficher */
	@Input() inputs!: Record<string,{libelle: string, infos: T}>;

	/** Récepteur de composant */
	@ViewChild('holder',{read: ViewContainerRef,static: true}) private holder!: ViewContainerRef;


	/**
	 * Constructeur
	 *
	 * @param componentFactoryResolver Récupérateur de factory pour les composants (en clair, il permet d'initialiser un composant depuis le TS pour l'injecter dans la vue)
	 */
	constructor(private componentFactoryResolver: ComponentFactoryResolver) {
	}

	/** Initialisation */
	ngOnInit(): void {
		//Création du composant à injecter
		let component: ComponentRef<ItemTitleComponent<T>> = this.createComponent();

		//Ajout des inputs du composant
		component.instance.input = this.inputs;

		//Ajout du composant au DOM
		this.holder.insert(component.hostView);
	}

	/**
	 * Création du composant
	 */
	createComponent(): ComponentRef<ItemTitleComponent<T>> {
		let factory: ComponentFactory<ItemTitleComponent<T>>;
		let injector: ReflectiveInjector;

		//Récupération de la fabrique
		factory = this.componentFactoryResolver.resolveComponentFactory(this.composant);

		//Récupération de l'injecteur
		injector = ReflectiveInjector.fromResolvedProviders([],this.holder.parentInjector);

		//Création du composant
		return factory.create(injector);
	}
}