/**
 * Options transverses définies dans les paramètres des plans comptables
 */
export class OptionsTransverses {
    congesFeries: string;
    typeDateLotComptable: TypeDateLotComptable;
    paques: boolean;
    ascension: boolean;
    pentecote: boolean;
    compteTiersCollab: string;
    compteTiersFrn: string;
    compteTaxes: string;
    compteAvances: string;
    gainChange: string;
    perteChange: string;
    typeSpecifCompte: number;
}

/**
 * Enumération représentant la date du lot comptable
 */
export enum TypeDateLotComptable {
    DATE_JOUR = 'DATE_JOUR',
    PREMIER_JOUR_PERIODE = 'PREMIER_JOUR_PERIODE',
    DERNIER_JOUR_PERIODE = 'DERNIER_JOUR_PERIODE',
    DATE_A_SAISIR = 'DATE_A_SAISIR'
}

/**
 * Modèle d'un jour de la semaine
 */
export interface Jour {
    id: string,
    isConge: boolean
}

/**
 * Modèle des options de compte
 */
export interface OptionCompte {
    numero: string,
    libelle: String
}

/**
 * Modèles des options de spécification des avances
 */
export interface OptionSpeAvances {
    id: number,
    libelle: string
}

/**
 * Modèle des options de date d'un lot comptable
 */
export interface OptionDateLotComptable {
    type: TypeDateLotComptable,
    libelle: string
}