import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ProfilPreferenceAnalytiquesTypeEntiteListItemComponent} from "./profil-preference-analytiques-type-entite-list-item.component";
import {PreferenceAnalytique} from "@domain/profil/preferenceAnalytique";
import {SettingsGlobalState} from "@domain/settings/settings";
import {finalize,first} from "rxjs/operators";
import {Field} from "@domain/settings/field";
import {NgForm} from "@angular/forms";

/**
 * Composant d'ajout des préférences analytiques pour le profil
 */
@Component({
    selector: 'profil-preference-analytiques-add',
    templateUrl: './profil-preference-analytiques-add.component.html'
})
export class ProfilPreferenceAnalytiquesAddComponent implements OnInit {

    /** Indicateur de sauvegarde */
    isSaving: boolean = false;

    /** Indicateur de suppression */
    isDeleting: boolean = false;

    /** Indicateur pour refresh la liste à la sortie de la popup */
    willRefreshList: boolean = false;

    /** Liste des préférences analytiques */
    liste: ListView<TypeEntite, ProfilPreferenceAnalytiquesTypeEntiteListItemComponent>;

    /** TypeEntite sélectionné */
    selectedTypeEntite: TypeEntite;

    /** Préférence analytique à créer */
    preferenceAnalytique: PreferenceAnalytique = new PreferenceAnalytique();

    /** Référence vers le formulaire */
    @ViewChild("addPrefAnalytiqueForm")
    addPrefAnalytiqueForm?: NgForm;

    constructor(
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        private profilService: ProfilService,
        private toastrService: ToastrService,
        private matDialogRef: MatDialogRef<ProfilPreferenceAnalytiquesAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { idUser: number,pref?: PreferenceAnalytique,settings: SettingsGlobalState,isFromAdmin?: boolean }
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Préférence analytique pour édition
        if (this.data.pref) {
            this.selectedTypeEntite = this.data.pref.typeEntite;
            this.preferenceAnalytique = this.data.pref;
        } else {
            //Définition de la liste du type entité pour création d'une nouvelle ligne de préférence analytique
            this.liste = new ListView<TypeEntite, ProfilPreferenceAnalytiquesTypeEntiteListItemComponent>({
                uri: `/controller/ProfilUser/Analytique/listeTypeEntite/${this.data.idUser}`,
                title: this.translateService.instant('profilUser.prefAnalytique.add.listTitle'),
                component: ProfilPreferenceAnalytiquesTypeEntiteListItemComponent,
                isSimple: true,
                mapResult: (result: Result) => {
                    return result?.data?.listeTypeEntite?.data
                },
                extraOptions: {
                    selectType: (data: TypeEntite) => this.selectType(data)
                },
                listeFilters: []
            });
        }
    }

    /**
     * Selection du type entité dans la liste
     * @param type TypeEntite
     */
    selectType(type: TypeEntite) {
        this.selectedTypeEntite = type;
        this.preferenceAnalytique.idTypeEntite = type.idTypeEntite;
        this.preferenceAnalytique.typeEntite = type;
    }

    /**
     * Récupération d'un paramétrage analytique
     */
    getFieldParam(code: string): Field {
        //Récupération du champ
        return this.data.settings?.listeFieldParams?.find(p => p.champ == code);
    }

    /**
     * Récupération de l'expression régulière liée au masque
     */
    getPatternFor(operation: any): string {
        let pattern: string = '';
        let position: number = 0;

        //Définition du pattern
        pattern = '^';

        //Parcours du masque de saisie
        operation.masque.split('').forEach(i => {
            //Vérification du type de caractère
            if (i == '9')
                //Ajout du numérique au pattern
                pattern += '[0-9]';
            else if (i == 'Z')
                //Ajout de l'alpha-numérique au pattern
                pattern += '[A-Za-z]';
            else if (i == '?')
                //Ajout de l'alpha-numérique au pattern
                pattern += '[0-9A-Za-z]';
            else if (i == '*')
                //Ajout de l'alpha-numérique au pattern
                pattern += '[\S]';

            //Vérification de la saisie obligatoire
            if (position++ < operation.tailleMinimale)
                //Saisie obligatoire
                pattern += '{1}';
            else
                //Saisie facultative
                pattern += '{0,1}';
        });

        //Caractère de fin
        pattern += '$';

        return pattern;
    }

    /**
     * Sauvegarde de la préférence ananlytique
     */
    savePref() {
        //Activation du flag
        this.isSaving = true;

        //On remonte les ids dans l'objet
        this.preferenceAnalytique.idTypeEntite = this.selectedTypeEntite.idTypeEntite;
        this.preferenceAnalytique.idAxe5 = this.preferenceAnalytique.axe5?.idAxe5;
        this.preferenceAnalytique.idAxe6 = this.preferenceAnalytique.axe6?.idAxe6;
        this.preferenceAnalytique.idOrga = this.preferenceAnalytique.orga?.idOrga;
        this.preferenceAnalytique.idDossier = this.preferenceAnalytique.dossier?.idDossier;

        //Sauvegarde
        this.profilService.savePreferenceAnalytique(this.preferenceAnalytique,this.data.isFromAdmin ? this.data.idUser : null)
            .pipe(first(),finalize(() => this.isSaving = false))
            .subscribe({
                next: result => {
                    //Message d'information
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de l'écran
                    this.matDialogRef.close(true);
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }

    /**
     * Suppression de la préférence analytique
     */
    deletePref() {
        //Suppression en cours
        this.isDeleting = true;

        //Suppression
        this.profilService.deletePreferenceAnalytique(this.preferenceAnalytique.id)
            .pipe(first(),finalize(() => this.isDeleting = false))
            .subscribe({
                next: result => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message d'information
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));

                        //Fermeture de l'écran
                        this.matDialogRef.close(true);
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                }
        });

    }

    /**
     * Réinitialisation du lien avec le dossier
     */
    resetDossier() {
        this.preferenceAnalytique.idDossier = null;
        this.preferenceAnalytique.dossier = null;
    }

    /**
     * Réinitialisation du lien avec l'axe 5
     */
    resetAxe5() {
        this.preferenceAnalytique.idAxe5 = null;
        this.preferenceAnalytique.axe5 = null;
    }

    /**
     * Réinitialisation du lien avec l'axe 6
     */
    resetAxe6() {
        this.preferenceAnalytique.idAxe6 = null;
        this.preferenceAnalytique.axe6 = null;
    }

}
