<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.referentiels.creationTitle'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input name="Type" customType="select" libelle="admin.entreprise.referentiels.type"
                          [(ngModel)]="referentiel.type" (onChange)="changeType()"
                          optionValue="code" optionDisplay="libelle" [selectOptions]="listeTypeReferentiel" ngDefaultControl></custom-input>
        </div>

        <div *ngIf="showForm">
            <div class="row">
                <custom-input [(ngModel)]="referentiel.libelle"
                              id="libelle"
                              name="libelle"
                              #libelle="ngModel"
                              customType="input"
                              libelle="admin.entreprise.referentiels.libelle"
                              maxlength="50"
                              [required]="true"
                              ngDefaultControl>
                </custom-input>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="referentiel.code"
                              id="code"
                              name="code"
                              #code="ngModel"
                              customType="input"
                              libelle="admin.entreprise.referentiels.code"
                              ngDefaultControl>
                </custom-input>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="referentiel.ordre"
                              id="ordre"
                              name="ordre"
                              #ordre="ngModel"
                              customType="number"
                              min="0"
                              libelle="admin.entreprise.referentiels.ordre"
                              ngDefaultControl>
                </custom-input>
            </div>
            <div class="row">
                <custom-input *ngIf="referentiel.type === 'C'"
                              [ngModel]="referentiel.defaut"
                              [(customModel)]="referentiel.defaut"
                              id="isDefaut"
                              name="isDefaut"
                              customType="checkbox"
                              libelle="admin.entreprise.referentiels.isDefaut"
                              ngDefaultControl>
                </custom-input>
            </div>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary"
                    (click)="saveNewReferentiel()"
                    [disabled]="form.invalid">
                <span translate>global.actions.enregistrer</span>
            </button>
        </div>
    </form>

</div>