import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {GeographieView} from "@domain/geographie/geographieView";

/**
 * Composant d'ajout d'un pays à la devise
 */
@Component({
    host: {'data-test-id': 'devise-gestion-add-pays'},
    templateUrl: './devise-gestion-add-pays.component.html'
})
export class DeviseGestionAddPaysComponent {
    /** Modèle pour l'autocomplete du pays */
    saisiePays: GeographieView;

    /**
     * Constructeur
     *
     * @param dialogRef Référence de la boîte de dialogue
     */
    constructor(private dialogRef: MatDialogRef<DeviseGestionAddPaysComponent>) {}

    /**
     * Mise à jour de l'objet de saisie avec le pays sélectionné dans l'autocomplete
     */
    onPaysChange() {
        //On ferme la fenêtre en envoyant le pays au composant parent
        this.dialogRef.close(this.saisiePays.pays);
    }
}