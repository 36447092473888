<div class="lvi-content clickable" (click)="isOuvert = !isOuvert">
    <div class="avatar">{{ ventilation.pourcentage | number }}</div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ ventilation.orga.libelle + ' (' + ventilation.orga.numService + ')' }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="typeEntite.codeProjet"><strong [translate]="'analytique.dossier'"></strong>
                <span *ngIf="ventilation.dossier">{{ ventilation.dossier.libelle + (ventilation.dossier.reference ? ' (' + ventilation.dossier.reference + ')' : '') }}</span>
                <span *ngIf="!ventilation.dossier" [translate]="'liste.nonRenseigne'"></span></li>
            <li *ngIf="typeEntite.axe5"><strong>{{ globalSettings?.libelleAxe5 || ('analytique.axe5' | translate) }}</strong>
                <span *ngIf="ventilation.axe5">{{ ventilation.axe5.libelle + (ventilation.axe5.reference ? ' (' + ventilation.axe5.reference + ')' : '') }}</span>
                <span *ngIf="!ventilation.axe5" [translate]="'liste.nonRenseigne'"></span></li>
            <li *ngIf="typeEntite.axe6"><strong>{{ globalSettings?.libelleAxe6 || ('analytique.axe6' | translate) }}</strong>
                <span *ngIf="ventilation.axe6">{{ ventilation.axe6.libelle + (ventilation.axe6.reference ? ' (' + ventilation.axe6.reference + ')' : '') }}</span>
                <span *ngIf="!ventilation.axe6" [translate]="'liste.nonRenseigne'"></span></li>
            <li *ngIf="!!typeEntite.field5 && !!operation1"><strong>{{ operation1.libelle }}</strong>
                <span *ngIf="ventilation.code1">{{ ventilation.code1 }}</span>
                <span *ngIf="!ventilation.code1" [translate]="'liste.nonRenseigne'"></span></li>
            <li *ngIf="!!typeEntite.field6 && !!operation2"><strong>{{ operation2.libelle }}</strong>
                <span *ngIf="ventilation.code2">{{ ventilation.code2 }}</span>
                <span *ngIf="!ventilation.code2" [translate]="'liste.nonRenseigne'"></span></li>
            <li><strong *ngIf="ventilation.listeEnveloppes?.length > 1" [translate]="'admin.ongletWF.cadreBudget.enveloppes'"></strong>
                <strong *ngIf="ventilation.listeEnveloppes?.length <= 1" [translate]="'admin.ongletWF.cadreBudget.enveloppe'"></strong>
                <span *ngIf="ventilation.listeEnveloppes?.length > 0">{{ventilation.listeEnveloppes?.length}}</span>
                <span *ngIf="ventilation.listeEnveloppes?.length === 0" [translate]="'admin.ongletWF.cadreBudget.pasDEnveloppe'"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="ventilation.listeEnveloppes?.length > 0">
        <button mat-icon-button>
            <mat-icon *ngIf="isOuvert" color="primary" [matTooltip]="'admin.ongletWF.cadreWF.replier' | translate">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOuvert" color="primary" [matTooltip]="'admin.ongletWF.cadreWF.deplier' | translate">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="ventilation.listeEnveloppes?.length > 0">
    <div [ngClass]="isOuvert ? 'open' : 'closed'">
        <div *ngFor="let suivi of ventilation.listeEnveloppes">
            <enveloppe [suivi]="suivi"></enveloppe>
        </div>
    </div>
</div>