<div class="lvi-content" [class.clickable]="listeChildrenReferenceExterne?.length > 0 || referenceExterne?.description" (click)="(listeChildrenReferenceExterne?.length > 0 || referenceExterne?.description) && toggleItem()">
    <div class="avatar">{{ referenceExterne.code?.substring(0,3) || '?' }}</div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="">{{ referenceExterne.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'referentielExterne.valeur'"></strong><span *ngIf="referenceExterne.valeur">{{ referenceExterne.valeur }}</span><span *ngIf="!referenceExterne.valeur" [translate]="'liste.nonRenseigne'"></span></li>
            <li><strong [translate]="'referentielExterne.code'"></strong><span *ngIf="referenceExterne.code">{{ referenceExterne.code }}</span><span *ngIf="!referenceExterne.code" [translate]="'liste.nonRenseigne'"></span></li>
            <li *ngIf="referenceExterne.date"><strong [translate]="'referentielExterne.date'"></strong><span>{{ referenceExterne.date | date:'short' }}</span></li>
            <li><strong [translate]="'referentielExterne.dateReception'"></strong><span *ngIf="referenceExterne.dateCreation">{{ referenceExterne.dateCreation | date:'short' }}</span><span *ngIf="!referenceExterne.dateCreation" [translate]="'liste.nonRenseigne'"></span></li>
        </ul>
    </div>
    <div *ngIf="listeChildrenReferenceExterne?.length > 0 || referenceExterne?.description">
        <mat-icon *ngIf="isOpened" color="primary">keyboard_arrow_up</mat-icon>
        <mat-icon *ngIf="!isOpened" color="primary">keyboard_arrow_down</mat-icon>
    </div>
</div>
<div *ngIf="isOpened">
    <form autocomplete="off">
        <div *ngIf="referenceExterne.description" class="row mt-3">
            <label class="col-md-2"><span [translate]="'referentielExterne.description'"></span></label>
            <div class="col-md-10">
                <span>{{ referenceExterne.description }}</span>
            </div>
        </div>
        <div class="listview-item mt-1" *ngFor="let childReferenceExterne of listeChildrenReferenceExterne">
            <div class="mx-5">
                <referentiel-externe-item isChild="true" [referenceExterne]="childReferenceExterne"></referentiel-externe-item>
            </div>
        </div>
    </form>
</div>
