<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.villes.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="goToDetailVille()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.pays">
                <strong [translate]="'admin.bibliotheque.geographie.villes.pays'"></strong>
                <span class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.pays.code2.toLowerCase()"></span>
                <a class="label">{{ data.pays.libelle }}</a>
            </li>
            <li *ngIf="data.codeIATA">
                <strong [translate]="'admin.bibliotheque.geographie.villes.codeIata'"></strong>
                <span>{{ data.codeIATA }}</span>
            </li>
            <li *ngIf="data.departementAdmin">
                <strong [translate]="'admin.bibliotheque.geographie.villes.departement'"></strong>
                <span>{{ data.departementAdmin.libelle }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.villes.actif'"></strong>
                <span>{{ (data.actif ? 'global.oui' : 'global.non') | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="delete()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>
