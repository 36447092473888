import {Component,Inject,OnInit} from '@angular/core';
import {ParamConnexion} from "@domain/travel/param-connexion";
import {TravelHubSbtConfigUsedParamConnexion} from "@domain/travel/travel-hub-sbt-config-used-param-connexion";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {RefSbt} from "@domain/travel/ref-sbt";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {filter,finalize,first} from "rxjs/operators";
import {Rls} from "@domain/travel/rls";
import {ReferentielRlsComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/referentiels/rls/referentiel-rls.component";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";

/**
 * Popup de détails d'un référentiel de type RLS
 *
 * @author Laurent Convert
 * @date 22/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-rls-add'},
    templateUrl: './referentiel-rls-add.component.html',
    styleUrls: ['./referentiel-rls-add.component.scss']
})
export class ReferentielRlsAddComponent implements OnInit {
    /** RegExp pour la validation de la valeur suivant le nombre de caractères attendus */
    regExpValidatorValeur : RegExp;

    /** Le RLS en cours de visualisation */
    rls: Rls;

    /** Paramètre de connexion */
    sbtConfigUsedParam: TravelHubSbtConfigUsedParamConnexion;

    /** Paramètre de connexion */
    paramConnexion: ParamConnexion;

    /** Indicateur de chargement en cours */
    isLoading: boolean = true;

    /** Indicateur de suppression en cours */
    isDeleting: boolean = false;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Indicateur de traitement en cours (enregistrement ou suppression) */
    get isProcessing(): boolean {
        return this.isSaving || this.isDeleting;
    }

    /**
     * Constructeur
     *
     * @param data Données transmises à l'ouverture de la popup
     * @param matDialogRef Référence de la popup
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param confirmService Service de confirmation utilisateur via modale
     * @param translateService Service de traduction
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    referentiel: Rls,
                    sbt: RefSbt,
                    sbtConfigUsed: TravelHubSBTConfigUsed,
                    listeSBTConfigUsed: Array<TravelHubSbtConfigUsedParamConnexion>,
                    parent: ReferentielRlsComponent
                },
                private matDialogRef: MatDialogRef<ReferentielRlsAddComponent>,
                private adminTravelhubService: AdminTravelhubService,
                private confirmService: ConfirmService,
                private translateService: TranslateService
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Chargement du RLS
        this.adminTravelhubService.loadRls(this.data.referentiel,this.data.listeSBTConfigUsed)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(result => {
                //Récupération des données depuis le résultat
                this.paramConnexion = result.data.paramConnexion;

                //Création ?
                if (this.data.referentiel.idRLS > 0) {
                    //Création
                    this.rls = new Rls(result.data.rls);
                } else {
                    //Modification
                    this.rls = new Rls(this.data.referentiel);
                }

                //Recherche du paramètre
                this.sbtConfigUsedParam = this.adminTravelhubService.findConfigUsed(this.data.listeSBTConfigUsed,this.data.sbtConfigUsed);

                //Vérification des contraintes de validité du paramètre
                if (this.sbtConfigUsedParam.minParam > 0 || this.sbtConfigUsedParam.maxParam > 0) {
                    //Construction de la regexp
                    this.regExpValidatorValeur = new RegExp(`^\\w{${this.sbtConfigUsedParam.minParam},${this.sbtConfigUsedParam.maxParam > 0 ? this.sbtConfigUsedParam.maxParam : ''}}$`,'g');
                }
            });
    }

    /**
     * Suppression du RLS en cours d'affichage
     */
    deleteRls(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //Suppression en cours
                    this.isDeleting = true;

                    //Suppression
                    this.data.parent.deleteReferentiel(this.rls).pipe(first()).subscribe(isSuccess => {
                        //Vérification du succès de la suppression
                        if (isSuccess) {
                            //Fermeture de la popup
                            this.matDialogRef.close(true);
                        } else {
                            //Erreur lors de suppression, fin du traitement
                            this.isDeleting = false;
                        }
                    });
                }
            });
    }

    /**
     * Suppression du RLS en cours d'affichage
     */
    saveRls(): void {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement
        this.data.parent.saveReferentiel(this.rls).pipe(first()).subscribe(isSuccess => {
            //Vérification du succès de l'enregistrement
            if (isSuccess) {
                //Fermeture de la popup
                this.matDialogRef.close(true);
            } else {
                //Erreur lors d'enregistrement, fin du traitement
                this.isSaving = false;
            }
        });
    }
}