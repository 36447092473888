import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {Filter, TypeComparaison} from "../../../../domain/common/list-view";
import {TypeCarte} from "../../../../domain/profil/carteVoyage";
import {SearchType} from "@domain/common/list-view/sorting";


/**
 * Options des cartes travel hub
 */
export default class CarteTravelHubOptions implements AutocompleteOptions {

    /**
     * Affichage d'un élément
     */
    displayItem(item: any): string {
        return (item?.libelle || '') + (item?.reference ? ` (${item.reference})` : '');
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
            title: translateService.instant('filter.libelle'),
            clef: 'libelle',
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        }, {
            title: translateService.instant('filter.fournisseur'),
            clef: 'compagnie.libelle',
            isDefault: true,
            typeComparaison: TypeComparaison[TypeComparaison.LIKE]
        }, {
            title: translateService.instant('filter.typeCarte'),
            clef: 'typeCarte',
            isDefault: true,
            listeValues: [
                {code: TypeCarte.FIDELITE, libelle: translateService.instant('profilUser.carteAbonnement.FIDELITE') },
                {code: TypeCarte.ABONNEMENT, libelle: translateService.instant('profilUser.carteAbonnement.ABONNEMENT')}]
        }];
    }

    /**
     * Récupération de l'URL
     */
    url(filter: any): string {
        return `/controller/Profil/searchCarteVoyage?typePresta=${filter?.typePresta}`;
    }

    /**
     * Mode de recherche
     */
    searchType = SearchType.CONTAINS;
}
