import {Directive,Input} from '@angular/core';
import {AbstractControl,NG_VALIDATORS,ValidationErrors,Validator} from '@angular/forms';
import * as moment from 'moment';


/**
 *  Directive de validation qui permet de controler que l'input date est supérieur à une autre date en heure
 */
@Directive({
    selector: '[hourAfterOther]',
    providers: [{ provide: NG_VALIDATORS, useExisting: HourAfterOtherDirective, multi: true }]
})
export class HourAfterOtherDirective implements Validator {
    /** Si renseigné à true le validator ne renvoie pas d'erreur si la date est égale à la date du jour */
    @Input() isSameDateValid: boolean = false;

    /** L'autre champ heure à comparer à l'heure du composant */
    @Input() otherHour: string;

    /** Méthode qui effectue une validation synchrone par rapport au contrôle fourni. */
    validate(control: AbstractControl): ValidationErrors | null {
        return checkDate(control.value, this.otherHour, this.isSameDateValid);
    }
}

/**
 * Fonction de validation qui permet de contrôler l'input Hour
 *
 * @param current l'heure à controler
 * @param otherHour l'autre heure à contrôler
 * @param isSameDateValid option à true si la même heure est valide
 */
function checkDate(current: string, otherHour: string, isSameDateValid: boolean): ValidationErrors {

    //Si la date n'est pas null
    if (current != null && otherHour != null) {
        const currentMoment = moment(current,"HH:mm");
        const otherMoment = moment(otherHour,"HH:mm");

        //Si l'heure est supérieure à l'autre heure
        if (currentMoment.isAfter(otherMoment,'minute') || (isSameDateValid && currentMoment.isSame(otherMoment,'minute'))) {
            //Pas d'erreur
            return null;
        }
    } else {
        //Si pas de date pas d'erreur de comparaison
        return null;
    }

    //On renvoie une erreur
    return { after: 'error' };
}