import {Component,Input,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {FactureReferenceListItemComponent} from "@components/facture/detail/contentieux/facture-reference-list/facture-reference-list-item.component";
import {Facture} from "@domain/facture/facture";
import {TranslateService} from "@ngx-translate/core";
import {FactureContentieux} from "@domain/facture/facture-contentieux";
import {MatDialog} from "@angular/material/dialog";
import {FactureReferenceAddListComponent} from "@components/facture/detail/contentieux/facture-reference-list/facture-reference-add-list.component";
import {first} from "rxjs/operators";
import {Page} from "@domain/common/http/list-result";
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import {FactureService} from "@components/facture/facture.service";
import {FactureEcartsComponent} from "@components/facture/detail/contentieux/ecarts/facture-ecarts.component";
import {User} from "@domain/user/user";

/**
 * Liste des factures de référence du contentieux
 *
 * @author Laurent Convert
 * @date 20/01/2023
 */
@Component({
    host: {'data-test-id': 'facture-reference-list'},
    selector: 'facture-reference-list',
    templateUrl: './facture-reference-list.component.html'
})
export class FactureReferenceListComponent implements OnInit {
    /** Facture en cours */
    @Input() facture: Facture;
    
    /** Contentieux de la facture en cours */
    @Input() contentieux: FactureContentieux;

    /** Utilisateur connecté */
    @Input() user: User;

    /** Indicateur de modification */
    @Input() canModifier: boolean;
    
    /** Liste pour l'affichage des factures de référence */
    liste: ListView<Facture,FactureReferenceListItemComponent>;
    
    /** Liste des identifiants des factures de référence */
    listeFactureReference: Array<number> = [];

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private matDialog: MatDialog,
                private factureService: FactureService) {
    }
    
    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Mise à jour de la liste des identifiants des factures de référence
        this.updateListeFactureReference();
        
        //Définition de la liste
        this.liste = new ListView<Facture,FactureReferenceListItemComponent>({
            uri: this.factureService.getUriListFactureReference(this.facture,this.contentieux,this.user),
            title: this.translateService.instant('facture.factureRef.liste.title'),
            component: FactureReferenceListItemComponent,
            isFilter: false,
            listeFilters: [{
                //Ajout du filtre sur le numéro
                clef: 'numero',
                title: this.translateService.instant('facture.liste.filtres.numero'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }],
            mapRequest: (request: SearchSpec) => {
                //On force arbitrairement à 1000 éléments dans la page pour "désactiver" la pagination
                request.nbObjetsParPage = 1000;
                return request;
            },
            mapResult: (result: Page<Facture>): Page<Facture> => {
                //Transformation en instance de chaque item
                result.listeResultats = result.listeResultats.map(f => new Facture(f));
                return result;
            },
            extraOptions: {
                canModifier: this.canModifier,
                removeFactureRef: this.removeFactureRef.bind(this)
            },
            listeActions: [{
                //Bouton d'ouverture de la popin de visualisation des écarts
                icon: "search",
                onPress: () => { this.factureService.openEcarts(this.facture,this.contentieux,FactureEcartsComponent); },
                isVisible: () => !!this.facture.od && (this.facture.ecartDevis || this.facture.ecartDevisMontant)
            },{
                //Bouton d'ouverture de la popin de sélection des factures de référence
                icon: "add",
                onPress: () => this.openListeFactureReference(),
                isVisible: () => this.canModifier
            }]
        });
    }

    /**
     * Suppression d'un élément de la liste des factures de référence
     * @param facture Objet à enlever de la liste
     */
    private removeFactureRef(facture: Facture): void {
        //On s'assure que la facture est bien en modification, sinon on dégage !
        if (!this.canModifier) return;
        
        //Vérification de la suppression de la liste
        if (this.liste.removeItem(facture)) {
            //Recherche de l'identifiant de la facture de référence à enlever
            let toRemove = this.contentieux.listeFactureReference.findIndex(idFacture => idFacture == facture.idFacture);
            
            //Si l'identifiant a bien été trouvé, on le supprime
            if (toRemove >= 0) {
                this.contentieux.listeFactureReference.splice(toRemove,1);
            } else {
                //Recherche de l'identifiant du contentieux de référence à enlever
                toRemove = this.facture.listeContentieuxReference.findIndex(idContentieux => idContentieux === facture.contentieux?.idFactCont);

                //Si l'identifiant a bien été trouvé, on le supprime
                if (toRemove >= 0) {
                    this.facture.listeContentieuxReference.splice(toRemove,1);
                }
            }

            //Mise à jour de la liste des identifiants des factures de référence
            this.updateListeFactureReference();
        }
    }
    
    /**
     * Ouvre la popin de sélection des factures de référence
     */
    private openListeFactureReference() {
        //Ouverture de la popin
        this.matDialog.open(FactureReferenceAddListComponent,{
            data: {
                facture: this.facture,
                contentieux: this.contentieux
            }
        }).afterClosed()
            .pipe(first())
            .subscribe((listeFactureSelection: Array<Facture>) => {
                //Vérification du retour à la fermeture
                if (listeFactureSelection) {
                    //Initialisation du tableau le cas échéant
                    if (this.contentieux.listeFactureReference == null) {
                        this.contentieux.listeFactureReference = new Array<number>();
                    }

                    //Mise à jour de la liste des factures de référence sélectionnées
                    listeFactureSelection.map(factRef => factRef.idFacture).forEach(idFacture => {
                        if (!this.contentieux.listeFactureReference.includes(idFacture)) {
                            this.contentieux.listeFactureReference.push(idFacture);
                        }
                    })

                    //Rafraichissement de la liste
                    this.refresh();
                }
            });
    }

    /**
     * Rechargement de la liste des factures de référence
     */
    refresh() {
        //Mise à jour de l'uri de la liste en fonction de la facture
        this.liste.uri = this.factureService.getUriListFactureReference(this.facture,this.contentieux,this.user);

        //Mise à jour de la liste des identifiants des factures de référence
        this.updateListeFactureReference();
        
        //Rafraichissement de la liste
        this.liste.refresh();
    }

    /**
     * Mise à jour de la liste des identifiants des factures de référence
     */
    private updateListeFactureReference() {
        //Reset
        this.listeFactureReference.splice(0);

        //Mise à jour de la liste des identifiants des factures de référence
        if (this.contentieux?.listeFactureReference?.length > 0) {
            this.listeFactureReference.push(...this.contentieux.listeFactureReference);
        }
    }

    /**
     * Retourne True si au moins l'une des factures de référence sélectionnées est en litige, False sinon.
     */
    public hasFactureReferenceLitige(): boolean {
        return this.liste.data.listeResultats.some(factRef => !!factRef.contentieux?.litige);
    }

    /**
     * Retourne True si au moins l'une des factures de référence sélectionnées a l'alerte activée, False sinon.
     */
    public hasFactureReferenceAlerte(): boolean {
        return this.liste.data.listeResultats.some(factRef => !!factRef.contentieux?.alerte);
    }

}