import {AfterContentInit,Component,EventEmitter,Input,OnDestroy,Output} from '@angular/core';
import {RoleLong} from "@domain/workflow/roleLong";
import {CarteHierarchique} from "@share/component/affichage-hierarchique/CarteHierarchique";
import {TypeColonnesAAfficher} from "@share/component/affichage-hierarchique/affichage-hierarchique.component";
import {
	buildCarteBibliotheque,
	buildCarteComptabilite,
	buildCarteEntreprise,
	buildCarteGestionFrais,
	buildCarteGestionVoyage,
	buildCarteParametre,
	buildCarteWorkflow,
	DetailName
} from "@components/admin/workflow/roles/roles/detail/droits/carte-hierarchique-droits";
import {ItemHierarchique} from "@share/component/affichage-hierarchique/ItemHierarchique";
import {Subscription} from "rxjs";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Composant d'affichage de l'onglet des droits sous admin
 *
 * @author Laurent SCIMIA
 * @date 09/10/2023
 */
@Component({
	host: {'data-test-id': 'role-droits'},
	selector: 'role-droits',
	templateUrl: './role-droits.component.html',
	styleUrls: ['./role-droits.component.scss']
})
export class RoleDroitsComponent implements AfterContentInit,OnDestroy {
	/** Rôle ouvert */
	@Input() role: RoleLong;

	/** Liste contenant tous les droits disponibles sur la page mis à plat */
	@Input() listeAllDroits!: Array<ItemHierarchique>;
	@Output() listeAllDroitsChange: EventEmitter<Array<ItemHierarchique>> = new EventEmitter<Array<ItemHierarchique>>();

	/** Liste des souscriptions de la page */
	listeSouscriptions: Array<Subscription> = [];

	/** Liste des colonnes pour l'affichage hiérarchique */
	listeColonnes: Array<TypeColonnesAAfficher> = [
		{id: DetailName.ACCES,cleTraduction: 'workflow.roles.droits.colonnes.acces'},
		{id: DetailName.FILTRE,cleTraduction: 'workflow.roles.droits.colonnes.filtre'},
		{id: DetailName.INFORMATIONS,cleTraduction: 'workflow.roles.droits.colonnes.informations'}];

	/** Liste des cartes hiérarchiques */
	listeCartesHierarchiques: Array<CarteHierarchique>;

	/** Initialisation des cartes hiérarchiques contenant les droits */
	private iniCartesHierarchiques() {
		//Construction des arbres hiérarchiques
		let entrep = buildCarteEntreprise();
		let compt = buildCarteComptabilite();
		let bibli = buildCarteBibliotheque();
		let param = buildCarteParametre();

		//On récupère le droit des interfaces qui vient d'être créé dans les paramètres car on va le réinjecter dans les frais
		let itemDroitInterface = param.flatMap(arbre => arbre.listeBranches).find(item => item.identifiant == DroitAdmin.DROIT_INTERFACE_REF);

		//Suite de la construction des arbres hiérarchiques
		let frais = buildCarteGestionFrais(itemDroitInterface);
		let voyag = buildCarteGestionVoyage();
		let workf = buildCarteWorkflow();

		// Création des cartes hiérarchiques pour chaque menu
		this.listeCartesHierarchiques = [
			new CarteHierarchique('menu.admin.entreprise',entrep.liste,'business'),
			new CarteHierarchique('menu.admin.comptabilite',compt,'nio icon-comptabilisation'),
			new CarteHierarchique('menu.admin.bibliotheque',bibli,'topic'),
			new CarteHierarchique('menu.admin.parametres',param,'build'),
			new CarteHierarchique('menu.admin.frais',frais,'nio icon-frais'),
			new CarteHierarchique('menu.admin.voyage',voyag,'map'),
			new CarteHierarchique('menu.admin.workflow',workf,'account_tree')
		];

		// Récupération à plat de la liste de tous les droits
		entrep.liste.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));
		compt.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));
		bibli.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));
		param.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));
		frais.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));
		voyag.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));
		workf.forEach(arbre => this.listeAllDroits.push(...arbre.listeBranches));

		//Souscription à l'évènement déclenché lors du cochage d'un droit population
		this.listeSouscriptions.push(entrep.souscription);
	}

	/** Initialise l'affichage des droits en fonction des informations du rôle */
	initDroitsRolesInHierarchie() {
		//On parcourt la liste des droits du rôle (ie. tous ceux qui sont activés)
		this.role.listeRoleAdmins.forEach(roleA => {

			//On cherche dans la liste de tous les droits, celui qui correspond au droit du rôle
			let item = this.listeAllDroits.find(droit => {
				return roleA.droit == droit.identifiant;
			});

			//Si on a trouvé le droit correspondant (normalement oui !)
			if (!!item) {
				//Si le cochage du droit est géré par un subject
				if (!!item.listeInputsComposantsDetail[DetailName.ACCES]?.subject) {
					//On active le subject
					item.listeInputsComposantsDetail[DetailName.ACCES].subject.next(item);
				} else {
					//Si le cochage est géré en direct, on coche le droit
					item.checked = true;
				}
			}
		});
	}

	/** Après l'initialisation du contenu de la page */
	ngAfterContentInit(): void {
		//On construit les cartes de droits
		this.iniCartesHierarchiques();

		//On initialise la liste des droits en fonction du rôle
		this.initDroitsRolesInHierarchie();
	}

	/** À la destruction du composant */
	ngOnDestroy(): void {
		//On unsub tout ce qui doit l'être
		this.listeSouscriptions.forEach(sub => sub.unsubscribe());
	}
}
