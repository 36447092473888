import {Component,EventEmitter,Input,Output} from '@angular/core';
import {Od} from "@domain/od/od";

/**
 * Composant d'affichage de l'onglet "Frais" d'un OD
 */
@Component({
    selector: 'od-frais',
    templateUrl: './od-frais.component.html'
})
export class ODFraisComponent {

    /** Ordre de mission */
    @Input() od: Od = null;

    /** Indicateur de modification possible */
    @Input() canModifier: boolean = false;

    /** Indicateur de complétion possible */
    @Input() canCompleter: boolean = false;

    /** Indique un changement de frais prev */
    @Output() isFraisPrevChange = new EventEmitter<string>();

    /** Déclenche le rechargement de l'od */
    @Output() onDegrevementsChanged = new EventEmitter<void>();

    /**
     * Indique s'il est possible de modifier le frais prev.
     * Il est nécessaire de faire un contrôle complémentaire, car le frais prev est directement ouvert à partir de la liste sans charger les droits en base
     * @return true si on peut modifier un frais prev
     */
    canModifierFraisPrev(): boolean {
        //Si on peut modifier ou compléter l'od et spécifique modifier le frais prev
        return (this.canModifier || this.od?.canCompleter()) && this.od?.getMapAction()?.actionsSupplementaires?.canOdCreationFraisPrev?.possible;
    }

    /**
     * Indique si on peut modifier la facture prev
     * @return true si on peut modifier la facture prev
     */
    canModifierFacturePrev(): boolean {
        return (this.canModifier || this.od?.canCompleter()) && this.od?.getMapAction()?.actionsSupplementaires?.canOdCreationFacturePrev?.possible;
    }
}
