import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { WorkflowService } from "@components/workflow/workflow.service";
import { OmpNdf } from "@domain/omp/ompNdf";
import { TypeProfil } from '@domain/user/user';
import { TypePortee } from "@domain/workflow/workflow";
import { environment } from "@environments/environment";

@Component({
    templateUrl: './omp-ndf-list-item.component.html'
})
export class OMPNdfListItemComponent {
    /** Elément courant **/
    @Input() data: OmpNdf;

    /** Options supplémentaires **/
    @Input() extraOptions: any;

    /** Profil de l'utilisateur connecté **/
    fonction: TypeProfil;

    /** URL de base **/
    public baseUrl: string = environment.baseUrl;

    constructor(
        private router: Router,
        private workflowService: WorkflowService
    ) { }

    /**
     * Affichage des alertes de la mission liée
     */
    showListeAlertes(idObjet: number) {
        //Affichage de la popup de liste des alertes
        this.workflowService.showListeAlertes(TypePortee.NF,idObjet);
    }

    /**
     * Ouverture d'un OD
     */
    openOd(idOd: number): void {
        //Navigation vers l'élément
        this.router.navigate(['OD',idOd]);
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.data.user) { return null; }
        return (this.data.user?.nom ? this.data.user?.nom + ' ' : '')
                + (this.data.user.prenom ? this.data.user?.prenom : '');
    }

    /** Getter pour le mode Collaborateur */
    get isCollabMode(): boolean {
        return this.fonction == TypeProfil.COLLABORATEUR;
    }

}