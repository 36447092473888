import {Pipe, PipeTransform} from "@angular/core";
import {DecimalPipe} from "@angular/common";

/**
 * Pipe d'affichage d'une distance avec une précision de deux chiffres après la virgule et l'unité après
 */
@Pipe({
    name: 'distance'
})
export class DistancePipe implements PipeTransform {

    /**
     * Constructeur
     *
     * @param decimalPipe le pipe d'affichage d'un nombre décimal
     */
    constructor(private decimalPipe: DecimalPipe) { }

    /**
     * La méthode de transformation
     *
     * @param value la distance
     * @param unite l'unité
     */
    transform(value: number | string, unite: string): string {
        return this.decimalPipe.transform(value,'.2-2') + ' ' + unite;
    }
}