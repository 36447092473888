import {ListItem} from "../common/list-view/list-item";
import {TypeProfil} from "../user/user";

/**
 * Classe représentant un profil (utilisé pour la liste des profils)
 */
export class Profil implements ListItem {
	typeProfil: TypeProfil;

	/**
	 * Constructeur avec arguments
	 *
	 * @param typeProfil le type de profil
	 */
	constructor(typeProfil: TypeProfil) {
		this.typeProfil = typeProfil;
	}

	getKey(): number {
		return this.typeProfil.valueOf();
	}

	isSelected?: boolean;
}