<h1 mat-dialog-title>
    <span translate="budget.detail.title" [translateParams]="{libelle: enveloppe.libelleEnveloppe}"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div class="content">
    <page-header [title]="'budget.typeBudget.typeConsommation' | translate" [listeItems]="listeTabs" [niveauAlerte]="alerte?.niveau" (onSelectedItemChange)="onSelectedItemChange($event)" [extraInfo]="getMontantTotalImpute()">
        <preview-alerte *ngIf="settings && computeAlerte()" [alerte]="alerte"></preview-alerte>
    </page-header>
    <mat-dialog-content>
        <div *ngIf="selectedTab.code == typeBudget.PREVISIONNEL.toString()">
            <detail-enveloppe-list [enveloppe]="enveloppe" [typeBudget]="typeBudget.PREVISIONNEL"></detail-enveloppe-list>
        </div>
        <div *ngIf="selectedTab.code == typeBudget.REEL.toString()">
            <detail-enveloppe-list [enveloppe]="enveloppe" [typeBudget]="typeBudget.REEL"></detail-enveloppe-list>
        </div>
    </mat-dialog-content>
</div>
