<h1 mat-dialog-title>
    <span translate>admin.parametres.demat.extensions.dialog.extensionLibelle</span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    customType="input"
                    name="extension"
                    [(ngModel)]="extensionName"
                    lCol="3" rCol="8"
                    libelle="admin.parametres.demat.extensions.dialog.extensionLibelle"
                    [required]="true"
                    [maxlength]="2000"
                    (onChange)="onExtensionNameChange()"
            ></custom-input>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end">
    <!-- Bouton d'enregistrement-->
    <button mat-flat-button color="primary" (click)="save()" [disabled]="form.invalid || isSaving">
        <span *ngIf="!isSaving" translate>global.actions.enregistrer</span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>