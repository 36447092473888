<profil-donnees-infos-perso *ngIf="profilUser" [profilUser]="profilUser" [alertLevelInfoUser]="alertLevelInfoUser" (infosPersoUpdated)="rafraichirAlertes()"></profil-donnees-infos-perso>

<list-view *ngIf="listeTelephones" [liste]="listeTelephones"></list-view>
<list-view *ngIf="listeAdresse" [liste]="listeAdresse"></list-view>
<list-view *ngIf="listeDocument" [liste]="listeDocument"></list-view>
<list-view *ngIf="isShowCompteBancaire && listeCompteBancaire" [liste]="listeCompteBancaire"></list-view>
<list-view *ngIf="isShowCartePaiement && listeCartePaiement" [liste]="listeCartePaiement"></list-view>
<list-view *ngIf="listeCarteVoyage" [liste]="listeCarteVoyage"></list-view>
<list-view *ngIf="listeVehicule" [liste]="listeVehicule" id="listeVehicule"></list-view>
<list-view *ngIf="listeContactUrgence" [liste]="listeContactUrgence"></list-view>

<div class="pb-2 text-right">
    <button (click)="changeAccessStatus()" class="mr-2" color="alert" mat-stroked-button>
        <span [translate]="profilUser?.rgpdSuspendu ? 'profilUser.infoPerso.allowAccess' : 'profilUser.infoPerso.denyAccess'"></span>
    </button>
    <button (click)="exportXML()" mat-stroked-button class="mr-2" color="primary">
        <span [translate]="'profilUser.infoPerso.exporter'"></span>
    </button>
    <span *ngIf="isProfilConnexionConfigured() && canSeeSynchronisation()" [nioTooltip]="isShowToolTipInfoObligatoireSyncro() ? ('profilUser.infoPerso.synchroPV.infosObligatoiresTooltip' | translate) : null">
        <button mat-flat-button [disabled]="!profilVoyageur.saisieObligatoire.profilVoyageurValide"
                (click)="synchronizeProfil()"
                color="primary">
            <span [translate]="'profilUser.infoPerso.synchroPV.synchroniser'"></span>
        </button>
    </span>
</div>

<!--Template de la popup de synchro du profil voyageur -->
<ng-template #synchroTemplate>
    <div>
        <h1 mat-dialog-title>
            <span translate>profilUser.infoPerso.synchroPV.synchroniserTitle</span>
        </h1>
        <div mat-dialog-content class="d-flex align-items-center">
            <mat-spinner diameter="50"></mat-spinner>
            <strong class="p-t-10" translate>profilUser.infoPerso.synchroPV.statutEnCours</strong>
            <span [translate]="'liste.pleaseWait'"></span>
        </div>
    </div>
</ng-template>
