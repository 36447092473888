import {TranslateService} from '@ngx-translate/core';
import {Filter} from '@domain/common/list-view';
import {AutocompleteOptions} from '../autocomplete';

/**
 * Options des dossiers
 */
export default class Axe5Options implements AutocompleteOptions {
	/**
	 * Récupération de l'URL
	 */
	url(filter: any) {
		//URL
		return `/controller/AnalytiqueJson/listerAxe5?`
			+ `idCollab=${filter?.idCollab || ''}`
			+ `&idTypeEntite=${filter?.idTypeEntite || ''}`
			+ `&idOrga=${filter?.idOrga || ''}`
			+ `&idDossier=${filter?.idDossier || ''}`
			+ `&idMIB=${filter?.idMIB || ''}`;
	}

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any) {
		return (item?.reference ? `${item.reference} - ` : '') + (item?.libelle || '') + (item?.code1 ? ` (${item.code1})` : '');
	}

	/**
	 * Filtres disponibles
	 */
	listeFilters(translateService: TranslateService): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			title: translateService.instant('filter.libelle'),
			clef: 'libelle',
			isDefault: true
		},{
			title: translateService.instant('filter.reference'),
			clef: 'reference',
			isDefault: true
		}]
	}
}