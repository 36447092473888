import {ListItem} from "../../common/list-view/list-item";
import {Periode} from "./periode";

/**
 * Classe d'un exercice
 */
export class Exercice implements ListItem {
	idExercice: number;
	libelle: string;
	dateDebut: Date;
	dateFin: Date;
	closed: boolean;
	nbPeriodes: number;
	dateCloture: Date;
	prorataTaxe: number;
	listePeriode: Periode[];

	//Champs supplémentaires utilisés dans le front
	isLast: boolean;

	getKey(): number {
		return this.idExercice;
	}
}