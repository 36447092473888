import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Report} from "@domain/reporting/report";
import {TranslateService} from "@ngx-translate/core";
import {ReportingService} from "@components/reporting/reporting.service";

/**
 * Composant pour afficher un rapport dans la liste
 */
@Component({
    host: {'data-test-id': 'reporting-item'},
    templateUrl: './reporting-item.component.html'
})
export class ReportingItemComponent extends ListViewItem<Report>{

    constructor(public translateService: TranslateService,private reportingService: ReportingService) {
        super();
    }

    /**
     * Récupération de la valeur du groupe
     */
    groupe(): string {
        //Retour de la valeur du groupe
        return this.data.groupeDefini ? this.translateService.instant('admin.bibliotheque.reporting.groupe.' + this.data.groupeDefini) : this.data.groupeCustom;
    }

    /**
     * Récupération de la valeur de l'avatar
     */
    avatar(): string {
        //Retour de la valeur de l'avatar
        return this.translateService.instant('admin.bibliotheque.reporting.avatar.' + (this.data.type == 1 && this.data.idPortee !== undefined ? (`portee.${this.data.idPortee}`) : (`type.${this.data.type}`)));
    }

    /**
     * Extraction du rapport
     */
    onExtractionClick(): void {
        //Exécution
        this.reportingService.executeReportById(this.data.idReport);
    }
}
