<h1 mat-dialog-title>
    <span translate="admin.comptabilite.planComptable.parametres.popinDatesFixes.title"></span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<mat-dialog-content>
    <form>
        <div class="row">
            <custom-input ngDefaultControl
                          name="ajoutJour"
                          [(ngModel)]="newJourFerie"
                          customType="input"
                          lCol="4"
                          rCol="6"
                          [pattern]="'^[0-9]{2}\/[0-9]{2}$'"
                          [placeholder]="'admin.comptabilite.planComptable.parametres.popinDatesFixes.format' | translate"
                          libelle="admin.comptabilite.planComptable.parametres.popinDatesFixes.ajout"></custom-input>
            <a [routerLink]="" (click)="addJour()">
                <span class="material-icons align-middle py-3">add_circle</span>
            </a>
        </div>

        <div class="listview">
            <div class="listview-body">
                <div *ngFor="let date of data.listeJoursFeries" class="listview-item">
                    <div class="list-view-item-hover">
                        <div class="lvi-content">
                            <div class="avatar"><i class="material-icons-outlined">outdoor_grill</i></div>
                            <div class="lvi-body"><div class="title"><a>{{date.substring(0,2) + '/' + date.substring(2,4)}}</a></div></div>
                            <div class="lvi-actions">
                                <button mat-icon-button (click)="deleteJour(date)" [matTooltip]="'global.actions.supprimer' | translate">
                                    <i class="nio icon-suppression"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-stroked-button (click)="close()" [disabled]="isPending">
        <span *ngIf="!isPending" [translate]="'global.actions.annuler'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isPending"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="isPending" (click)="saveJoursFeries()">
        <span *ngIf="!isPending" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isPending"></mat-spinner>
    </button>
</div>