<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-ordre_de_mission"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.close(data)">{{ formatMission(data) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong translate>global.liste.filtres.typeEntite</strong>{{ data.typeEntite?.libelle }}</li>
            <li>
                <strong translate>od.liste.libelleVille</strong>
                <span *ngIf="data.pays" class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"></span>
                {{ (data.ville?.libelle ? data.ville.libelle + ', ' : '') + (data.pays?.libelle || '') }}
            </li>
            <li><strong translate>od.liste.filtres.depart_le</strong>{{ data.depart_le | date:'shortDate' }}</li>
            <li><strong translate>od.liste.filtres.retour_le</strong>{{ data.retour_le | date:'shortDate' }}</li>
        </ul>
    </div>
</div>
