import {Component,ElementRef,Input,OnInit,ViewChild} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ModeRemboursement} from "@domain/remboursement/mode-remboursement";
import {Collaborateur} from "@domain/user/collaborateur";

/**
 * Item de la liste des modes de remboursement
 */
@Component({
	host: {'data-test-id': 'mode-remb-list-item'},
	templateUrl: './mode-remb-list-item.component.html'
})
export class ModeRembListItemComponent extends ListViewItem<ModeRemboursement> implements OnInit {
	/** Options supplémentaires */
	@Input() extraOptions: {
		collab: Collaborateur,
	}

	@ViewChild('item') itemDiv: ElementRef<HTMLElement>;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de isSelected
		if (this.extraOptions.collab.modeRemb?.includes(this.data.idModeRemb?.toString())) {
			this.data.isSelected = true;
		}
	}

	/**
	 * Appelé à la sélection/désélection de l'item courant
	 */
	onSelect(): void {
		//Si on désélectionne ce mode de remboursement
		if (!this.data.isSelected) {
			//S'il est le mode par défaut
			if (this.data.idModeRemb === this.extraOptions.collab.idModeRemb) {
				//S'il existe un autre mode de remboursement que l'actuel
				if (this.liste.listeObjetsSectionnes.find(modeRemb => modeRemb.idModeRemb !== this.data.idModeRemb)) {
					//On le désigne par défaut
					this.extraOptions.collab.idModeRemb = this.liste.listeObjetsSectionnes.find(modeRemb => modeRemb.idModeRemb !== this.data.idModeRemb).idModeRemb;
				} else {
					//Suppression du mode de remboursement par défaut
					this.extraOptions.collab.idModeRemb = null;
				}
			}
		} else {
			//Si on le sélectionne et qu'il n'y en a pas par défaut
			if (this.extraOptions.collab.idModeRemb == null) {
				//On le désigne par défaut
				this.extraOptions.collab.idModeRemb = this.data.idModeRemb;
			}
		}

		//Mise à jour du champ modeRemb du collaborateur
		this.extraOptions.collab.modeRemb = this.liste.listeObjetsSectionnes.map(modeRemb => modeRemb.idModeRemb).join(' ');
	}

	/**
	 * Handler du clique sur le bouton default
	 */
	onDefaultSelect(): void {
		//Si le choix n'est pas coché
		if (!this.data.isSelected) {
			//On coche le choix pour le mettre par défaut via un fake click pour utiliser nSelector
			this.itemDiv.nativeElement.click();
		}

		//On assigne l'id par défaut
		this.extraOptions.collab.idModeRemb = this.data.idModeRemb
	}
}
