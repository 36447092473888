import {PeriodeDTO} from "@domain/exercice/periodeDTO";

/**
 * DTO d'un exercice
 */
export class ExerciceDto {
    idExercice: number;
    libelle: string;
    dateDebut: Date;
    dateFin: Date;
    listePeriode: PeriodeDTO[]
}