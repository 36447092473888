import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ObjetATraiter,TypeObjetATraiter} from "@domain/admin/comptabilite/objet-a-traiter";

/**
 * Item de liste d'un objet à traiter de la popin du détail d'une période
 *
 * @author Alexis Bouvard
 * @date 02/05/2023
 */
@Component({
	host: {'data-test-id': 'objet-a-traiter-list-item'},
	templateUrl: './objet-a-traiter-list-item.component.html'
})
export class ObjetATraiterListItemComponent extends ListViewItem<ObjetATraiter> {

	/** Import de l'énum pour le DOM */
	public TypeObjetATraiter = TypeObjetATraiter;
}
