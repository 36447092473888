import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {ListView,TypeFilter} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {ListeTrajet} from "@domain/Trajet/ListeTrajet";
import {TrajetListItemComponent} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet-list-item.component";
import {TrajetService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-trajet/trajet.service";

/**
 * Composant qui permet d'afficher l'onglet trajets dans les frais
 */
@Component({
    selector: 'ndf-frais-add-trajet',
    templateUrl: './ndf-frais-add-trajet.component.html',
    host: { 'data-test-id': 'ndf-frais-add-trajet' },
})
export class NdfFraisAddTrajetComponent implements OnInit {
    /** Output quand une selection est réalisée */
    @Output() selectTrajet = new EventEmitter<ListeTrajet>();

    /** Id de la prestation */
    @Input() idPrest: number;

    /** La liste affichée */
    liste: ListView<ListeTrajet,TrajetListItemComponent>;

    /** Le constructeur */
    constructor(private translateService: TranslateService, private trajetService: TrajetService) { }

    /** Event on init */
    ngOnInit(): void {
        //Définition de la liste
        this.liste = new ListView<ListeTrajet,TrajetListItemComponent>({
            uri: `/controller/Trajet/findByUser/` + this.idPrest,
            title: this.translateService.instant('ndf.trajet.title'),
            component: TrajetListItemComponent,
            defaultOrder: 'type,libelle',
            listeFilters: [{
                title: this.translateService.instant('ndf.trajet.filtre.libelle'),
                clef: 'libelle',
                type: TypeFilter[TypeFilter.STRING],
                isDefault: true
            },{
                title: this.translateService.instant('ndf.trajet.filtre.villeDepart'),
                clef: 'adresseDepart.ville',
                type: TypeFilter[TypeFilter.STRING],
                isDefault: true
            },{
                title: this.translateService.instant('ndf.trajet.filtre.paysDepart'),
                clef: 'adresseDepart.pays',
                type: TypeFilter[TypeFilter.STRING],
                isDefault: true
            },{
                title: this.translateService.instant('ndf.trajet.filtre.villeArrive'),
                clef: 'adresseArrivee.ville',
                type: TypeFilter[TypeFilter.STRING],
                isDefault: true
            },{
                title: this.translateService.instant('ndf.trajet.filtre.paysArrive'),
                clef: 'adresseArrivee.pays',
                type: TypeFilter[TypeFilter.STRING],
                isDefault: true
            }],
            extraOptions: {
                selectEvent: this.selectItem.bind(this),
                refreshEvent: this.refresh.bind(this)
            }
        });
    }

    /**
     * Méthode appelée lors de la selection d'un trajet
     * @param listeTrajet la liste des trajets
     */
    selectItem(listeTrajet: ListeTrajet): void {
        ///On retourne sur le premier onglet avec les données
        this.selectTrajet.emit(listeTrajet);
    }

    /**
     * Permet de recharger la liste
     */
    refresh(): void {
        this.liste.refresh();
    }
}