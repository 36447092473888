<div class="lvi-content">
    <div class="avatar" [nSelector]="{liste: liste,item: data,isNotSelectable: !extraOptions?.withSelection}">
        <i class="nio icon-facture align-middle"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="$event.stopPropagation();">{{ data.getLibelleObjet(translateService) }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'facture.factureRef.liste.type'"></strong>{{ TypeFact.traduction(data.type) | translate }}</li>
            <li><strong [translate]="'facture.factureRef.liste.typeEntite'"></strong>{{ data.typeEntite?.libelle }}</li>
            <li *ngIf="data.date"><strong [translate]="'facture.factureRef.liste.date'"></strong>{{ data.date?.toDate() | date:'shortDate' }}</li>
            <li *ngIf="data.libelleFournisseur"><strong [translate]="'facture.factureRef.liste.fournisseur'"></strong>{{ data.libelleFournisseur }}</li>
            <li [ngSwitch]="data.factureReleve.typeReconciliation">
                <strong [translate]="'facture.factureRef.liste.reconciliation'"></strong>
                <span *ngSwitchCase="TypeReconciliation.TYPE_RECONCILIATION_OM">{{ data.od | od }}</span>
                <span *ngSwitchCase="TypeReconciliation.TYPE_RECONCILIATION_COLLAB">{{ data.user | user }}</span>
            </li>
            <li *ngIf="data.contentieux?.litige || data.contentieux?.litigeLeve" [ngClass]="{'back-success' : data.contentieux.litigeLeve, 'back-danger' : data.contentieux.litige}">
                <strong [translate]="'facture.factureRef.liste.litige'"></strong>
                {{ 'facture.liste.columns.contentieux.litige.' + (data.contentieux.litige ? 'ouvert' : 'ferme') | translate }}
            </li>
        </ul>
    </div>
    <div *ngIf="extraOptions.withSelection" class="info">
        <div>
            <statut *ngIf="data.statut" [statut]="data.statut"></statut>
            <div class="text-right text-secondary font-weight-bold">
                {{ data.montant | montant:data.devise }}
            </div>
        </div>
    </div>
    <div *ngIf="!extraOptions.withSelection && extraOptions.canModifier" class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="extraOptions.removeFactureRef(data);" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>