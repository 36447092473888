import {Component, Input,TemplateRef,ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ODService} from "@components/od/od.service";
import {TypeCodeErreur } from '@domain/common/http/result';
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter} from "rxjs/operators";
import {
	PopupDetailPaysRisqueComponent
} from "@share/component/outils/niveaux-risque/popup-detail-pays-risque/popup-detail-pays-risque.component";

/**
 * Composant du cadre 'niveaux de risque' de l'onglet workflow
 *
 * @author Angeline Ha
 * @date 30/08/2023
 */
@Component({
	host: {'data-test-id': 'niveaux-risque'},
	selector: 'niveaux-risque',
	templateUrl: './niveaux-risque.component.html'
})
export class NiveauxRisqueComponent {

	/** Identifiant de l'objet */
	@Input() idObjet: number;

	/** Composant du template ref du message de confirmation */
	@ViewChild('messageConfirmation') messageConfirmation: TemplateRef<any>;

	/**
	 * Constructeur
	 *
	 * @param matDialog Boîte de dialogue
	 * @param confirmService
	 * @param odService
	 * @param translateService
	 * @param toastrService
	 */
	constructor(private matDialog: MatDialog,
				private confirmService: ConfirmService,
				private odService: ODService,
				private translateService: TranslateService,
				private toastrService: ToastrService) {
	}

	/**
	 * Affichage de la popup avec les infos sur les niveaux de risque
	 */
	showPopupDetails() {
		this.matDialog.open(PopupDetailPaysRisqueComponent, {
			data: {
				idObjet: this.idObjet
			}
		})
	}

	/**
	 * Mise à jour des pays à risque liés à l'OD
	 */
	updateOdPaysRisque() {
		this.odService.updatePaysRisque(this.idObjet).subscribe(result => {
			if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
				this.toastrService.success(this.translateService.instant('admin.ongletOutils.cadreNiveauxRisque.recalculPaysARisque.success'));
			} else {
				this.toastrService.error(this.translateService.instant('admin.ongletOutils.cadreNiveauxRisque.recalculPaysARisque.error'));
			}
		});
	}

	/**
	 * Affichage de la popup pour recalculer les niveaux de risque
	 */
	showPopupNiveauxRisque() {
		this.confirmService.showConfirm(this.messageConfirmation).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				this.updateOdPaysRisque();
			}
		})
	}

}
