import {TripField} from "./trip-field";
import {FieldUsed} from "@domain/travel/field-used";

/**
 * Représente la valeur appliquée à un trip field pour une configuration SBT
 *
 * @author Laurent Convert
 * @date 20/11/2023
 */
export class TripFieldUsed extends FieldUsed {
	idTripFieldUsed: number;
	tripField: TripField;

	constructor(dto?: FieldUsed) {
		super(dto);
	}

	getKey(): number {
		return this.idTripFieldUsed;
	}

	getId(): number {
		return this.idTripFieldUsed;
	}

	getCode(): string {
		return this.tripField?.code;
	}

	get field(): TripField {
		return this.tripField;
	}

	set field(field: TripField) {
		this.tripField = field;
	}
}