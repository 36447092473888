import {Component,Input} from '@angular/core';
import {Analytique} from "@components/analytique/analytique";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {SuiviBudget} from '@domain/budget/suivi-budget';
import {BudgetService} from '@components/budget/budget.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {filter,finalize,first} from 'rxjs/operators';
import {SettingsGlobalState} from "@domain/settings/settings";
import {TypePortee,TypePorteeInt} from "@domain/workflow/workflow";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TypeCodeErreur} from '@domain/common/http/result';

/**
 * Composant d'affichage de la répartition analytique admin
 */
@Component({
	host: {'data-test-id': 'repartition-analytique'},
	selector: 'repartition-analytique',
	templateUrl: './repartition-analytique.html'
})
export class RepartitionAnalytique {

	/** Liste des ventilations */
	@Input() listeVentilations: Array<Analytique>;

	/** Type entité */
	@Input() typeEntite: TypeEntite;

	/** Indique si l'objet est valide */
	@Input() isObjetValide: boolean;

	/** Identifiant de l'objet */
	@Input() idObjet: number;

	/** Portée de l'objet */
	@Input() portee: TypePortee;

	/** Identifiant de la portée de l'objet */
	private idTypeObjet: TypePorteeInt;

	/** Titre la portée de l'objet */
	private cleTradPortee: string;

	/** Etat du chargement */
	isLoading = false;

	/** Liste des suivis budgétaires */
	listeSuivi: Array<SuiviBudget>;

	/** Paramétrage global */
	@Input() globalSettings: SettingsGlobalState;

	/**
	 * Constructeur
	 *
	 * @param budgetService		Service du budget
	 * @param toastrService		Service pour afficher les messages de succès, d'erreurs, ...
	 * @param translateService	Service de traduction
	 * @param confirmService	Service de confirmation
	 */
	constructor(private budgetService: BudgetService,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private confirmService: ConfirmService,) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Chargement du suivi budgétaire
		this.loadSuiviBudget();
	}

	/**
	 * Chargement des données budgétaires relatives à l'objet
	 */
	loadSuiviBudget() {
		//Chargement en cours
		this.isLoading = true;
		this.idTypeObjet = TypePorteeInt[this.portee];

		//Chargement des données
		this.budgetService.loadSuiviBudget(this.idTypeObjet,this.idObjet)
			.pipe(first(),finalize(() => this.isLoading = false))
			.subscribe(
				(result) => {
					//Récupération de la liste des suivis
					this.listeSuivi = result.filter(s => s.montant > 0);
					this.matchEnveloppesToVentilation();
				},
				() => {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.chargement'));
				}
			);
	}

	/**
	 * Ajout des enveloppes correspondantes pour chaque ventilation
	 */
	matchEnveloppesToVentilation() {
		//Parcours des ventilations
		for (let ventilation of this.listeVentilations) {
			ventilation.listeEnveloppes = new Array<SuiviBudget>();

			//Parcours des suivis
			for (let suivi of this.listeSuivi) {

				//Parcours des ventilations du suivi
				suivi.listeIdVentilation.forEach((idVentilation) => {
					if (idVentilation === ventilation.id) {
						ventilation.listeEnveloppes.push(suivi);
					}
				});
			}
		}
	}

	/**
	 * Affichage de la popup du recalcul des données budgétaires
	 */
	showCalculDonneesBudgetPopup(): void {
		switch (this.portee) {
			case TypePortee.NF:
				this.cleTradPortee = 'ndf.title';
				break;
			case TypePortee.FC:
				this.cleTradPortee = 'facture.title';
				break;
			case TypePortee.AV:
				this.cleTradPortee = 'avance.title';
				break;
			case TypePortee.OD:
				this.cleTradPortee = 'od.title';
				break;
			case TypePortee.OT:
				this.cleTradPortee = 'omp.title';
				break;
		}

		this.confirmService.showConfirm(this.translateService.instant('admin.ongletWF.cadreBudget.messageConfirmation', {objet: this.translateService.instant(this.cleTradPortee,{numero: this.idObjet})}))
			.pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				this.recalculDonneesBudget();
			}
		});
	}

	/**
	 * Force le recalcul des données budgétaires de l'objet
	 */
	public recalculDonneesBudget() {

		//Recalcul des données budgétaires
		if (this.isObjetValide) {
			this.budgetService.synchroHistoBudget(this.portee,this.idObjet).subscribe({
				next: result => {
					if (result.codeErreur !== TypeCodeErreur.NO_ERROR) {
						//On affiche le toast erreur
						this.toastrService.error(this.translateService.instant('global.errors.recalculBudget'));
					} else {
						//Rechargement des données
						this.loadSuiviBudget();

						//On affiche le toast succès
						this.toastrService.success(this.translateService.instant('global.success.recalculBudget'));
					}
				},
				error: () => {
					//On affiche le toast erreur
					this.toastrService.error(this.translateService.instant('global.errors.recalculBudget'));
				}
			});
		} else {
			//On affiche le toast erreur
			this.toastrService.error(this.translateService.instant('global.errors.objetInvalide'));
		}
	}

}
