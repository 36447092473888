import {Component, OnInit} from "@angular/core";
import {LicenceService} from "@services/admin/entreprise/licence.service";
import {MatDialog} from "@angular/material/dialog";
import {Licence} from "@domain/admin/licence/licence";
import {ImportLicenceComponent} from "@components/admin/entreprise/description/licence/import/import-licence.component";
import {Result} from "@domain/common/http/result";

/**
 * Onglet Licence du menu Description de l'entreprise
 */
@Component({
    host: {'data-test-id': 'licence'},
    selector: 'licence',
    templateUrl: './licence.component.html'
})
export class LicenceComponent implements OnInit {
    /** Licence présente dans la table ns_licence, licence de l'entreprise */
    licence: Licence;

    /**
     * Constructeur
     *
     * @param licenceService Service permettant de récupérer et d'enregistrer la licence
     * @param matDialog Boite de dialogue permettant d'importer une licence
     */
    constructor(private licenceService: LicenceService,
                private matDialog: MatDialog) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Chargement de la licence présente en base de données
        this.licenceService.loadLicence().subscribe((res: Result) => {
            this.licence = res.data.licence;
        });
    }

    /**
     * Méthode pour gérer l'ouverture de la boite de dialogue pour l'import d'une licence et la mise à jour de la licence affichée lors de l'import d'une nouvelle
     */
    import() {
        //Ouverture de la boite de dialogue
        const dialog = this.matDialog.open(ImportLicenceComponent, {
            width: '50%'
        });

        //Lors de la fermeture de la fenêtre, on récupère la licence importée
        dialog.afterClosed().subscribe(data => {
            //Si une donnée est renvoyée à la fermeture de la boite de dialogue, on change la licence affichée par celle importée
            if (data) {
                this.licence = data;
            }
        })
    }
}
