import {Component,Input,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {LoginService} from '@share/login/login.service';
import {filter} from "rxjs/operators";
import {ProfilService} from "../profil.service";
import {InfosValidCgu} from "@domain/profil/infosValidCgu";

/**
 * Composant gérant l'acceptation des cgu
 */
@Component({
  selector: 'profil-cgu',
  templateUrl: './profil-cgu.component.html',
})
export class ProfilCguComponent implements OnInit {

  /** Information de validation de CGU */
  @Input()
  infoCgu: InfosValidCgu;

  /** CGU de l'application */
  cgu: any;

  constructor(
    private profilService : ProfilService,
    private loginService : LoginService,
    private confirmService: ConfirmService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.profilService.getLastCguVersionHTML().subscribe(res=>{
      this.cgu = res.data.cguHtml
    })
  }

  acceptCgu(): void {
    this.profilService.validerCgu(true).subscribe(res=>{
      this.profilService.getInfosValidCgu().subscribe(res=>{
        this.infoCgu = new InfosValidCgu(res.data);
      })
    });
  }

  refuseCgu(): void {
    //Ouverture de la popin de confirmation
    this.confirmService.showConfirm(this.translateService.instant('profilUser.cgu.popupConfirmRefusCGUs.text'))
    .pipe(filter(isConfirmed => isConfirmed === true))
    .subscribe({
      next: (isConfirmed) => {
        if (isConfirmed) {
          this.profilService.validerCgu(false).subscribe(
              () => {
                //Déconnexion
                this.loginService.logout();
              }
            );
        }
      }
    });
  }

}
