import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ListView} from '../../../../../domain/common/list-view';
import {SettingsODState} from '../../../../../domain/settings/settings';
import {ParticipantListItemComponent} from "../../../../participants/participant-list-item.component";
import {Od} from "../../../../../domain/od/od";
import {LienOmUser} from "../../../../../domain/od/lien-om-user";
import {MatDialog} from "@angular/material/dialog";
import {ODService} from "../../../od.service";
import {first} from "rxjs/operators";
import {Result, TypeCodeErreur} from "../../../../../domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ModeParticipants} from "../../../../../domain/settings/ns-params";
import {ParticipantsPopinComponent} from "../../../../participants/popin/participants-popin.component";
import {ParticipantLien} from "../../../../../domain/participant/participant-lien";
import {ODVoyageService} from "../od-voyage.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'od-participants-list',
    templateUrl: './od-participants-list.component.html'
})
export class ODParticipantsListComponent implements OnInit, OnDestroy {
    /** Ordre de mission */
    @Input() od: Od;

    /** Paramètres */
    @Input() settings: SettingsODState;

    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /** Mode de participant paramétré pour l'OD */
    @Input() modeParticipant: ModeParticipants;

    /** Évènement de retrait de la mission */
	eventRetirerMission: EventEmitter<LienOmUser> = new EventEmitter<LienOmUser>();

    /** Liste des participants */
    liste: ListView<LienOmUser, ParticipantListItemComponent>;

    /** Souscription à unsub à la destruction du composant */
    private souscription: Subscription;

    /**
     * Constructeur
     * @param translateService  Service de traduction
     * @param odService         Service des OD
     * @param toastrService     Service de Toast
     * @param matDialog         Service de popup
     * @param odVoyageService   Service de gestion du voyage
     */
    constructor(private translateService: TranslateService,
                private odService: ODService,
                private toastrService: ToastrService,
                private matDialog: MatDialog,
                private odVoyageService: ODVoyageService) {}

    /**
     * Initialisation
     */
    ngOnInit() {
        this.eventRetirerMission.subscribe((lien: ParticipantLien) => {
            //Ici, on vient de la liste des participants présents dans la mission, il faut appeler le back
				this.odService.retirerParticipant((lien as LienOmUser).idLienOmUser, this.od.idOd).pipe(first()).subscribe((result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        this.liste.refresh();
                    } else {
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
				});
        });

        //Définition de la liste
        this.liste = new ListView<LienOmUser, ParticipantListItemComponent>({
            uri: `/controller/Participant/filtreParticipants/${this.od.idOd}`,
            title: this.translateService.instant('od.voyage.participants.title'),
            component: ParticipantListItemComponent,
            listeFilters: [{
                clef: '*participantInterne.nom,*participantExterne.nom,*participantInterne.prenom,*participantExterne.prenom',
                title: this.translateService.instant('od.voyage.participants.title'),
                isDefault: true
            }],
            listeActions: [{
                icon: 'add',
                isVisible: () => this.canModifier,
                onPress: () => this.showParticipant(null)
            }],
            extraOptions: {
				eventRetirerParticipant: this.eventRetirerMission,
                canModifier: this.canModifier,
                showParticipant: this.showParticipant.bind(this),
                settings: this.settings,
                idUserObjet: this.od.user.idUser
            },
            isAffichageDeuxColonnes: true,
            isLocal: true
        });

        //Suite au chargement de la liste, on envoie au service de voyage l'information du nombre de participants
        this.souscription = this.liste.loaded.subscribe(() => {
            this.odVoyageService.hasParticipants = this.liste.data?.listeResultats?.length > 0;
        })
    }

    private showParticipant(participant: LienOmUser) {
        //Ouverture de la popup
        this.matDialog.open(ParticipantsPopinComponent,{
            data: {
                contexte: 'OD',
                canModifier: this.canModifier,
                idObjet: this.od.idOd,
                idUser: this.od.user.idUser,
                idTypeEntite: this.od.typeEntite.idTypeEntite,
                participant: participant,
                settings: this.settings,
                modeParticipant: this.modeParticipant,
                idUserObjet: this.od.user.idUser
            },
            minWidth: 1000,
            maxWidth: 1200,
            maxHeight: 800
        }).afterClosed().subscribe({
            next: data => {
                //Vérification du résultat
                if (data) {
                    //On refresh la liste des participants
                    this.liste.refresh();
                }
            }
        });
    }

    /**
     * A la suppression du composant
     */
    ngOnDestroy(): void {
        //On unsub
        this.souscription.unsubscribe();
    }
}
