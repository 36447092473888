import {Component,Inject,Input,OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TypePortee} from '@domain/workflow/workflow';
import {AutocompleteService} from "@share/component/autocomplete/autocomplete.service";
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {HttpClient,HttpParams} from "@angular/common/http";
import {environment} from "@environments/environment";
import {finalize,first} from "rxjs/operators";
import {Result} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {NDFCreationListComponent} from "./ndf-creation-item/ndf-creation-list.component";
import {Mission} from "@domain/ndf/mission";
import {TypeEntite} from "@domain/typeentite/typeEntite";

/**
 * Popin de la liste des différentes options de rattachement à la création d'une NDF
 */
@Component({
    host: {'data-test-id': 'ndf-creation'},
    templateUrl: './ndf-creation.component.html'
})
export class NDFCreationComponent implements OnInit {
    /** Déclaration pour accès dans le template */
    TypePortee = TypePortee
    
    /** Compteur du nombre d'od disponibles */
    countOd: number = 0;
    
    /** Compteur du nombre d'omp disponibles */
    countOt: number = 0;

    /** Compteur du nombre de type entité disponible pour la ndf */
    isTypeEntite: boolean = false;
    
    /** Indicateur de chargement */
    isLoading: boolean = true;

    /**
     * Constructeur
     */
    constructor(private translateService: TranslateService,
                private autocompleteService: AutocompleteService,
                private matDialogRef: MatDialogRef<NDFCreationComponent,{mission?: Mission,typeEntite?: TypeEntite}>,
                private http: HttpClient,
                private toastrService: ToastrService,
                private matDialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) private data: {idCollab: number}) {

    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Construction des paramètres de la requête HTTP de récupération des compteurs
        let params: HttpParams = new HttpParams();
    
        //Ajout de l'identifiant du collaborateur pour lequel l'utilisateur doit créér l'objet (cas du "connecté en tant que")
        if (this.data.idCollab) {
            params = params.append("idCollab",this.data.idCollab?.toString(10));
        }
        
        //Récupération du nombre de missions disponibles par portée
        this.http.post<Result>(`${environment.baseUrl}/controller/NDF/countMissionsForCreation?`+params.toString(),null)
            .pipe(first(),finalize(() => this.isLoading = false))
            .subscribe(
                (result: Result) => {
                    this.countOd = result.data[TypePortee.OD];
                    this.countOt = result.data[TypePortee.OT];
                    this.isTypeEntite = result.data.isTypeEntite;
                },() => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.chargement'));
                });
    }
    
    /**
     * Ouvre la liste des missions pour la portée en paramètre
     *
     * @param portee Portée sélectionnée
     */
    openListeMission(portee: TypePortee) {
        //Ouverture de la popin de sélection de mission
        this.matDialog.open(NDFCreationListComponent, {
            data: {
                portee: portee,
                idCollab: this.data.idCollab
            }
        }).afterClosed().subscribe({
            next: (item: Mission) => {
                //Fermeture de la popup avec sélection du type entité
                item && this.matDialogRef.close({
                    mission: item
                });
            }
        });
    }
    
    /**
     * Création d'une note de frais hors mission
     */
    selectNoMission() {
        //Affichage de la popup de sélection du type entité
        this.autocompleteService.showSearch({
            type: 'typeEntite',
            filter: {
                idPortee: TypePortee.NF,
                isRattachementOMNonObligatoire: true,
                idCollab: this.data.idCollab
            },
            dialogConfig: {
                minWidth: 600
            }
        }).subscribe({
            next: (item) => {
                //Fermeture de la popup avec sélection du type entité
                item && this.matDialogRef.close({
                    typeEntite: item
                });
            }
        });
    }
}