import {Component,Inject,OnInit} from '@angular/core';
import {TypeDocument} from "@domain/admin/parametre/demat/type-document";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage de la popin d'ajout d'un type de pièce jointe
 *
 * @author Lionel Gimenez
 * @date 02/10/2023
 */
@Component({
    host: {'data-test-id': 'type-dialog'},
    templateUrl: './type-dialog.component.html'
})
export class TypeDialogComponent implements OnInit {
    /** Modèle du composant pour le TypeDocument */
    typeItem: TypeDocument;

    /** Indication de sauvegarde en cours */
    isSaving = false;

    /** Select options pour le champ multi-select restriction */
    restrictionSelectOptions: { id: string, libelle: string }[] = [{
        id: "1", libelle: this.translateService.instant("admin.parametres.demat.types.ajout.objet.ndf")
    }, {
        id: "2", libelle: this.translateService.instant("admin.parametres.demat.types.ajout.objet.facture")
    }, {
        id: "3", libelle: this.translateService.instant("admin.parametres.demat.types.ajout.objet.om")
    }, {
        id: "4", libelle: this.translateService.instant("admin.parametres.demat.types.ajout.objet.omp")
    }, {
        id: "5", libelle: this.translateService.instant("admin.parametres.demat.types.ajout.objet.avance")
    }, {
        id: "6", libelle: this.translateService.instant("admin.parametres.demat.types.ajout.objet.pv")
    }];

    /** Modèle pour le champ multi-select restriction */
    listeRestrictions: { id: string, libelle: string }[]  = [];

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private dematService: DematService,
        private toastrService: ToastrService,
        private dialogRef: MatDialogRef<TypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            typeItem: TypeDocument,
        }
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //On récupère le modèle
        this.typeItem = this.data.typeItem;

        //Si typeObjet n'est pas null et n'est pas vide
        if (!!this.typeItem.typeObjet) {
            //On initialise le multiselect à partir d'un string du modèle
            this.typeItem.typeObjet
                .split(',')
                .forEach(id => this.listeRestrictions.push({
                    id: id,
                    libelle: this.restrictionSelectOptions.find( r => r.id === id).libelle
                }));
        }
    }

    /**
     * Handler du bouton sauvegarder
     * Sauvegarde le type document
     */
    onSave(): void {
        this.isSaving = true;
        let currentRestriction = "";

        //On reconstruit le string typeObjet à partir de restriction
        this.listeRestrictions.forEach(r => currentRestriction += r.id + ",");

        //Si on une valeur
        if (currentRestriction.length > 0) {
            //On efface la dernière virgule
            this.typeItem.typeObjet = currentRestriction.slice(0,currentRestriction.length-1);
        } else {
            this.typeItem.typeObjet = "";
        }

        //Sauvegarde le TypeDocument
        this.dematService.saveTypeDocument(this.typeItem)
            .pipe(first(),finalize(() => this.isSaving = false))
            .subscribe({
                next: (result: Result) => {
                    if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                        //Message de success
                        this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                        this.dialogRef.close(true);
                    } else {
                        //Message d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                    }
                },
                error: () => {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
                }
            });
    }
}