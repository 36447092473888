import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {TranslateModule} from '@ngx-translate/core';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {ProfilService} from "../../../profil/profil.service";
import {MatRadioModule} from "@angular/material/radio";
import {DroitAdmin} from "@core/security/droit-admin";
import {ReleveFactureModule} from "@components/facture/releve-facture.module";
import {NdfComponent} from "./ndf/ndf.component";
import {NdfFraisCartesComponent} from "./frais-cartes/ndf-frais-cartes.component";
import {FraisNdfComponent} from "./frais-ndf.component";
import {NDFModule} from "@components/ndf/ndf.module";

export const fraisNdfRoutes : Routes = [
    {
        path: 'Ndf',
        component: NdfComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_VISUALISATION_NDF] }
    }, {
        path: 'FraisCartes',
        component: NdfFraisCartesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_IMPORT_FACTURE] }
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Ndf'
    }, {
        path: '**',
        redirectTo: 'Ndf'
    }
];

@NgModule({
    imports: [ShareModule, PreviewAlerteModule, MatDividerModule, TranslateModule, MatRadioModule, ReleveFactureModule, NDFModule],
    declarations: [
        FraisNdfComponent,NdfComponent,NdfFraisCartesComponent
    ],
    entryComponents: [],
    exports: [
        NdfComponent,NdfFraisCartesComponent
    ],
    providers: [ProfilService, LoginService]
})
export class FraisNdfModule {
}
