<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.internationalisation.infosLibelle.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #saisieForm="ngForm">
        <div class="row">
            <custom-input ngDefaultControl lCol="2" rCol="10" [(ngModel)]="traduction.codeLangue"
                          name="codeLangue" customType="input"
                          libelle="admin.bibliotheque.internationalisation.infosLibelle.libelleCard.codeLangue"
                          [readonly]="true" [disabled]="false" [required]="false">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl lCol="2" rCol="10" [(ngModel)]="traduction.cleTraduction"
                          name="cleTraduction" customType="input"
                          libelle="admin.bibliotheque.internationalisation.infosLibelle.libelleCard.cleTraduction"
                          [readonly]="true" [disabled]="false" [required]="false">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl lCol="2" rCol="10" [ngModel]="(traduction.messageStandard ? traduction.messageStandard : ('admin.bibliotheque.internationalisation.infosLibelle.libelleCard.standardFallback' | translate))"
                          name="standard" customType="textarea"
                          libelle="admin.bibliotheque.internationalisation.infosLibelle.libelleCard.standard"
                          [readonly]="true" [disabled]="false" [required]="false">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl lCol="2" rCol="10" [(ngModel)]="traduction.messageCustom"
                          name="custom" customType="textarea"
                          libelle="admin.bibliotheque.internationalisation.infosLibelle.libelleCard.custom"
                          [placeholder]="'admin.bibliotheque.internationalisation.infosLibelle.libelleCard.placeholderCustom' | translate"
                          [readonly]="false" [disabled]="false" [required]="false">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="isLoading || saisieForm.invalid">
        <span *ngIf="!isLoading" translate>global.actions.enregistrer</span>
        <mat-spinner *ngIf="isLoading" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
