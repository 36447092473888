import {Component,Inject,ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ProfilService} from "../../profil.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Vehicule} from "@domain/vehicule/vehicule";
import {first} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {PuissanceFiscale} from "@domain/vehicule/puissancefiscale";
import {DocumentListComponent} from "../../../document/document-list.component";
import {SettingsGlobalState} from "@domain/settings/settings";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {DocumentService} from "../../../document/document.service";

/**
 * Composant d'ajout de véhicules
 */
@Component({
	selector: 'profil-donnees-add-vehicule',
	templateUrl: './profil-donnees-add-vehicule.component.html',
	providers: [DocumentService]
})
export class ProfilDonneesAddVehiculeComponent {
	/** Indicateur de sauvegarde */
	isSaving: boolean = false;

	/** Indicateur de suppression */
	isDeleting: boolean = false;

	/** Véhicule */
	vehicule: Vehicule = new Vehicule();

	/** Paramétrage */
	settings: SettingsGlobalState;

	/** Référence vers le composant de la liste des documents */
	@ViewChild('listeDocument')
	listeDocument?: DocumentListComponent;

	/**
	 * Constructeur
	 *
	 * @param translateService service de traduction
	 * @param profilService service pour la gestion du profil
	 * @param toastrService service de toast d'alerte
	 * @param store le store de l'application
	 * @param matDialogRef service de référence de la popup
	 * @param data données transmises à la popup
	 */
	constructor(
		private translateService: TranslateService,
		private profilService: ProfilService,
		private toastrService: ToastrService,
		private store: Store<AppState>,
		private matDialogRef: MatDialogRef<ProfilDonneesAddVehiculeComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { listePuissance: Array<PuissanceFiscale>,idUser?: number }
	) {
		//Sélection du paramétrage
		this.store.select(state => state.settings?.['Global']).subscribe(settings => this.settings = settings);
	}

	/**
	 * Sauvegarde d'un vehicule
	 */
	save() {
		//Indicateur de sauvegarde
		this.isSaving = true;

		//Sauvegarde du véhicule
		this.profilService.saveVehicule(this.vehicule,this.data.idUser).pipe(first()).subscribe({
			next: (result) => {
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					const data: { vehicule: Vehicule } = result.data;

					//Vérification de l'enregistrement
					if (data.vehicule) {
						//S'il y a des documents en attente de liaison avec l'objet
						if (this.listeDocument?.hasPendingDocumentToLink()) {
							//Liaison des documents
							this.listeDocument.linkAllPendingDocument(data.vehicule.id).subscribe((success) => {
								//Vérification du succès de l'opération
								if (success) {
									//Message d'information
									this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

									//Fermeture de l'écran
									this.matDialogRef.close(data);
								} else {
									//Message d'erreur
									this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
								}
							});
						} else {
							//Message d'information
							this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

							//Fermeture de l'écran
							this.matDialogRef.close(data);
						}
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
					}
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			}
		});
	}

	/**
	 * Formate le numéro d'immatriculation en supprimant tout ce qui n'est pas alphanumérique, et en passant tout en majuscule.
	 */
	formaterImmatriculation() {
		this.vehicule.immatriculation = this.vehicule.immatriculation.replace(/[^a-zA-Z0-9]/g,'').toUpperCase();
	}
}
