import {Component,Input,OnInit} from '@angular/core';
import {getStatutArchivageIcon,StatutArchivage} from "../../../domain/comptabilite/statut-archivage";
import {Ndf} from "../../../domain/ndf/ndf";
import {environment} from "../../../environments/environment";
import {NDFService} from "../ndf.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {DematNdf} from "@domain/ndf/demat-ndf";

/**
 * Cadre de l'archive à valeur probante (avp)
 *
 * @author Laurent Convert
 * @date 10/07/2024
 */
@Component({
	host: {'data-test-id': 'avp-details'},
	selector: 'avp-details',
	templateUrl: './avp-details.component.html'
})
export class AvpDetailComponent implements OnInit {
	/* Déclaration pour accès dans le template */
	protected readonly getStatutArchivageIcon = getStatutArchivageIcon;
	protected readonly StatutArchivage = StatutArchivage;
	protected readonly environment = environment;

	/** Ndf */
	@Input() ndf: Ndf;

	/** Détail de l'archivage */
	dematNdf: DematNdf;

	/** Nom de l'archive */
	archive: string;

	/**
	 * Constructeur
	 *
	 * @param ndfService Service de gestion des NDF
	 * @param translateService Service de traduction
	 * @param toastrService Service de notification
	 */
	constructor(private ndfService: NDFService,
				private translateService: TranslateService,
				private toastrService: ToastrService) {
	}

	/**
	 * Hook d'initialisation du composant
	 */
	ngOnInit() {
		//Récupération des données sur l'avp
		this.ndfService.getDematNdf(this.ndf.idNDF).subscribe(res => {
			//Vérification du retour
			if (res.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Récupération des données
				this.archive = res.data.archive;
				this.dematNdf = res.data.dematNdf;
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(res.codeErreur,this.translateService,this.toastrService);
			}
		});
	}
}