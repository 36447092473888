import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Condition} from "@domain/workflow/condition";
import {Router} from "@angular/router";
import {WorkflowConditionsService} from "@services/admin/workflow/workflow-conditions.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {filter,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Item de la liste des conditions de visibilité
 */
@Component({
	host: {'data-test-id': 'condition-visibilite-list-item'},
	templateUrl: './condition-visibilite-list-item.component.html'
})
export class ConditionVisibiliteListItemComponent extends ListViewItem<Condition> {
	/**
	 * Constructeur
	 *
	 * @param router le routeur Angular
	 * @param confirmService le service de confirmation
	 * @param translateService le moteur de traduction
	 * @param workflowConditionsService le service de gestion des conditions workflow
	 * @param toastrService le toaster
	 */
	constructor(
		private router: Router,
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private workflowConditionsService: WorkflowConditionsService,
		private toastrService: ToastrService
	) {
		super();
	}

	/**
	 * Clic sur une condition de visibilité
	 */
	onSelect(): void {
		//Navigation vers le détail de la conditions de visibilité
		this.router.navigate(['Admin/Workflow/Conditions/ConditionVisibilite',this.data.idCondition]);
	}

	/**
	 * Suppression d'une condition de visibilité
	 */
	onDelete(): void {
		//Confirmation de l'action
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Appel au service
				this.workflowConditionsService.deleteCondition(this.data.idCondition)
					.pipe(first())
					.subscribe((result: Result) => {
						//Vérification du résultat
						if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Suppression de l'élément de la listView
							(this.data as ListItem).removeFromListView();
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}
