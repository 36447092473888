import {ListeAlertes} from "../common/alerte/listeAlertes";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {TypePortee} from "../workflow/workflow";
import {MapAction} from "../workflow/mapAction";
import {Analytique} from "../../components/analytique/analytique";
import {TypeEntite,TypeEntiteNF} from "../typeentite/typeEntite";
import {TranslateService} from "@ngx-translate/core";
import {Lot} from "@domain/comptabilite/lot";
import {StatutArchivage} from "@domain/comptabilite/statut-archivage";
import {ZoneUtilisateur} from "@domain/zone-utilisateur/zone-utilisateur";
import {ZoneUtilisateurSaisie} from "@domain/zone-utilisateur/zone-utilisateur-saisie";

/**
 * Modèle représentant une NDF
 */
export class Ndf extends AbstractObjetWorkflow {
    idNDF: number;
    numeroPiece: string;
    od: any;
    idUser: number;
    user: any;
    idOrga: number;
    idSite: number;
    isUseAvances: boolean;
    cours: number;
    modeRemboursement: any;
    compte: any;
    typeEntite: any;
    periode: any;
    lot: Lot;
    statut: any;
    idStatut: number;
    objet: string;
    montantRemboursable: number;
    montantARembourser: number;
    montantEntreprise: number;
    remarque: string;
    devise: any;
    listeVentilations: Analytique[];
    NDFChainages: any[];
    dateEmis: Date;
    dateMaj: Date;
    dateCreation: Date;
    id_cv: number;
    id_valid: number;
    tsio: number;
    listeZU: ZoneUtilisateur[];
    listeZUSaisies: ZoneUtilisateurSaisie[];
    listeDocuments: any[];
    omPermanent: any;
    listeHistoWorkflow: MapAction;
    montantDepenses: number;
    statutArchivage: StatutArchivage;

    /* Informations supplémentaires */

    /** Liste des alertes levées sur l'objet */
    listeAlertes: ListeAlertes;

    constructor(dto?: any) {
        super();
        Object.assign(this, dto);
        if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
    }

    /**
     * Récupération de l'identifiant de l'objet
     * @returns l'identifiant de l'objet
     */
    getKey(): number {
        return this.idNDF;
    }

    getId(): number {
        return this.idNDF;
    }

    getPortee(): TypePortee {
        return TypePortee.NF;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getListeVentilation(): Array<Analytique> {
        return this.listeVentilations;
    }

    getTypeEntite(): TypeEntite {
        return this.typeEntite as TypeEntiteNF;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        return (this.user.nom ? this.user.nom + ' ' : '')
                + (this.user.prenom ? this.user.prenom : '');
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.numeroPiece || translateService.instant('ndf.liste.numeroNonDefini')) + ' - ' + (this.objet || translateService.instant('ndf.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return true;
    }
}
