import {NgModule} from '@angular/core';
import {StatutApplicationComponent} from "./statut-application.component";
import {StatutWorkflowService} from "@services/admin/statut-workflow/statut-workflow.service";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {StatutApplicationUserComponent} from "@share/layout/statut-application/statut-application-user.component";
import {StatutApplicationUserService} from "@share/layout/statut-application/statut-application-user.service";

@NgModule({
    declarations: [StatutApplicationComponent,StatutApplicationUserComponent],
    imports: [CommonModule,TranslateModule,MatIconModule,MatProgressBarModule],
    exports: [StatutApplicationComponent,StatutApplicationUserComponent],
    providers: [StatutWorkflowService,StatutApplicationUserService]
})
export class StatutApplicationModule {}
