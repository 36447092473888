import { NgModule } from '@angular/core';

import { ShareModule } from '../../share/share.module';
import { ReferentielExterneComponent } from './referentiel-externe.component';
import { ReferentielExterneItemComponent } from './referentiel-externe-item.component';
import { ReferentielExterneAddComponent } from './referentiel-externe-add.component';
import { ReferentielExterneService } from './referentiel-externe.service';

@NgModule({
	imports: [ShareModule],
	declarations: [ReferentielExterneComponent,ReferentielExterneItemComponent,ReferentielExterneAddComponent],
	exports: [ReferentielExterneComponent],
	providers: [ReferentielExterneService]
})
export class ReferentielExterneModule { }