import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {GeoLocalisation,TypeGeoLocalisation} from "@domain/geographie/geoLocalisation";
import {TranslateService} from "@ngx-translate/core";
import {GeographieView} from "@domain/geographie/geographieView";
import {AutocompleteComponent} from "@share/component/autocomplete/autocomplete.component";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {TypeCodeErreur} from "@domain/common/http/result";
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant de la popin pour ajouter un périmètre à un territoire
 */
@Component({
	host: {'data-test-id': 'territoire-perimetre-add'},
	templateUrl: './territoire-perimetre-add.component.html',
	providers: [GeographieService]
})
export class TerritoirePerimetreAddComponent implements OnInit {

	/** Liste des types de périmètres possibles */
	listeTypePerimetre: OptionTypePerimetre[];

	/** Type du périmètre à ajouter */
	typePerimetreSelected: TypeGeoLocalisation;

	/** Périmètre à ajouter */
	selectedPerimetre: GeoLocalisation;

	/** Objet géographie sélectionné dans l'autocomplete */
	selectedGeographie: GeographieView;

	/** Autocomplete Géographie */
	@ViewChild("autocompleteGeographie")
	autocompleteGeographie: AutocompleteComponent;

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean = false;

	/** Import de l'énum pour le dom */
	readonly TypeGeolocalisation = TypeGeoLocalisation;

	/**
	 * Constructeur
	 *
	 * @param translateService  Service de traduction
	 * @param geographieService Service géographie
	 * @param toastrService     Service de gestion des toasts
	 * @param matDialogRef      Service de référence de la popup
	 * @param data              Données injectées dans la boîte de dialogue
	 */
	constructor(private translateService: TranslateService,
				private geographieService: GeographieService,
				private toastrService: ToastrService,
				private matDialogRef: MatDialogRef<TerritoirePerimetreAddComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { idTerritoire: number }) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(){
		//Aucun périmètre sélectionné - on affiche les différents types de périmètres possibles
		this.typePerimetreSelected = null;

		//Définition des types de périmètres possibles
		this.listeTypePerimetre = [{
			id: TypeGeoLocalisation.PAYS,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.pays')
		},{
			id: TypeGeoLocalisation.REGION,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.region')
		},{
			id: TypeGeoLocalisation.DEPARTEMENT,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.departement')
		},{
			id: TypeGeoLocalisation.VILLE,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.ville')
		},{
			id: TypeGeoLocalisation.ZONE,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.zone')
		},]

		//Initialisation du périmètre sélectionné
		this.selectedPerimetre = {
			idGeoLocalisation: null,
			idTerritoire: this.data.idTerritoire,
			type: null,
			libelle: "",
			pays: null,
			regionAdmin: null,
			departementAdmin: null,
			ville: null,
			zone: null
		}
	}

	/**
	 * Indique si un type de périmètre est sélectionné
	 * Conditionne l'affichage de la liste des périmètres ou de l'autocomplete géographie
	 *
	 * @return true si un type de périmètre est sélectionné
	 */
	isSelectedTypePerimetre(): boolean {
		return this.typePerimetreSelected in TypeGeoLocalisation;
	}

	/**
	 * Enregistre le type de périmètre sélectionné dans la popup
	 * @param id identifiant du type de périmètre sélectionné
	 */
	selectTypePerimetre(id: TypeGeoLocalisation) {
		this.typePerimetreSelected = id;
	}

	/**
	 * Pour retourner au choix du type de périmètre
	 */
	goBack() {
		//Retour à l'écran de choix de type de périmètre
		this.typePerimetreSelected = null;

		//Désélectionne la geographie de l'autocomplete
		this.selectedGeographie = null;
	}

	/**
	 * Retourne le type de filtre utilisé par l'autocomplete en fonction du type de périmètre sélectionné
	 *
	 * @param typePerimetre Type de périmètre
	 * @return liste des filtres de l'autocomplete
	 */
	getAutoCompleteFilter(typePerimetre: TypeGeoLocalisation): Array<String> {
		let filter: String[] = Array<String>();

		switch (typePerimetre) {
			case TypeGeoLocalisation.PAYS:
				filter.push('PAYS');
				break;
			case TypeGeoLocalisation.REGION:
				filter.push('REGION_ADMINISTRATIVE');
				break;
			case TypeGeoLocalisation.DEPARTEMENT:
				filter.push('DEPARTEMENT_ADMINISTRATIF');
				break;
			case TypeGeoLocalisation.VILLE:
				filter.push('VILLE');
				break;
			case TypeGeoLocalisation.ZONE:
				filter.push('ZONE');
				break;
			default:
				filter.push('PAYS','REGION_ADMINISTRATIVE','DEPARTEMENT_ADMINISTRATIF','VILLE','ZONE');
		}

		return filter;
	}

	/**
	 * Retourne la traduction d'un type de périmètre
	 *
	 * @param typePerimetre Type de périmètre
	 * @return la traduction du type de périmètre
	 */
	translateTypePerimetre(typePerimetre: TypeGeoLocalisation): string {
		let typePerimetreTrad;

		switch (typePerimetre) {
			case TypeGeoLocalisation.ZONE:
				typePerimetreTrad = 'zone';
				break;
			case TypeGeoLocalisation.PAYS:
				typePerimetreTrad = 'pays';
				break;
			case TypeGeoLocalisation.REGION:
				typePerimetreTrad = 'region';
				break;
			case TypeGeoLocalisation.DEPARTEMENT:
				typePerimetreTrad = 'departement';
				break;
			case TypeGeoLocalisation.VILLE:
				typePerimetreTrad = 'ville';
				break;
			default:
				break;
		}

		return this.translateService.instant('admin.bibliotheque.geographie.territoire.perimetre.' + typePerimetreTrad);
	}

	/**
	 * Met à jour le périmètre à partir de l'objet géographie sélectionné
	 *
	 * @param geographie Objet géographie
	 */
	setGeographie(geographie: GeographieView): void {

		//Libellé
		this.selectedPerimetre.libelle = geographie.libelle;

		//Type et identifiant du type correspondant
		switch (geographie.type) {
			case TypeGeographie.PAYS:
				this.selectedPerimetre.type = TypeGeoLocalisation.PAYS;
				this.selectedPerimetre.idPays = geographie.id;
				break;
			case TypeGeographie.REGION_ADMINISTRATIVE:
				this.selectedPerimetre.type = TypeGeoLocalisation.REGION;
				this.selectedPerimetre.idRegionAdmin = geographie.id;
				break;
			case TypeGeographie.DEPARTEMENT_ADMINISTRATIF:
				this.selectedPerimetre.type = TypeGeoLocalisation.DEPARTEMENT;
				this.selectedPerimetre.idDepartementAdmin = geographie.id;
				break;
			case TypeGeographie.VILLE:
				this.selectedPerimetre.type = TypeGeoLocalisation.VILLE;
				this.selectedPerimetre.idVille = geographie.id;
				break;
			case TypeGeographie.ZONE:
				this.selectedPerimetre.type = TypeGeoLocalisation.ZONE;
				this.selectedPerimetre.idZone = geographie.id;
				break;
		}

	}

	/**
	 * Enregistrement du périmètre
	 */
	savePerimetre(): void {
		this.isSaving = true;

		//Enregistrement du périmètre
		this.geographieService.saveGeoLocalisation(this.selectedPerimetre).subscribe({
			next: result => {
				//Vérification de l'enregistrement
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de l'écran
					this.matDialogRef.close({success: true});
				} else {
					//Toast d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			},
			error: () => {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
			},
			complete: () => {
				this.isSaving = false;
			}
		});

	}
}

/**
 * Modèle des options de type de périmètres
 */
export interface OptionTypePerimetre {
	id: TypeGeoLocalisation,
	libelle: string
}