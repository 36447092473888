import {Injectable} from '@angular/core';

/**
 * Service de gestion unifiée de la persistence des données de session de l'application.
 */
@Injectable()
export class SessionStorageService {

    /**
     * Sauvegarde un élément dans le Session Storage
     * @param caller identifiant de l'appelant (composant, service, etc)
     * @param cle clé d'identification de l'objet
     * @param objet objet à stocker
     */
    save(caller: string, cle: string, objet: any): void {
        sessionStorage.setItem(this.buildKey(caller,cle), JSON.stringify(objet));
    }

    /**
     * Retourne l'élément correspondant à la clé fournie ou null si introuvable
     * @param caller identifiant de l'appelant (composant, service, etc)
     * @param cle clé d'identification de l'objet
     * @returns l'élément correspondant ou null si introuvable
     */
    fetch(caller: string, cle: string): string | null {
        return sessionStorage.getItem(this.buildKey(caller,cle));
    }

    /**
     * Efface un élément du Session Storage
     * @param caller identifiant de l'appelant (composant, service, etc)
     * @param cle clé d'identification de l'objet
     */
    remove(caller: string, cle: string): void {
        sessionStorage.removeItem(this.buildKey(caller,cle));
    }

    /**
     * Purge complète du Session Storage
     */
    clear(): void {
        sessionStorage.clear();
    }

    /**
     * Retourne l'identifiant effectif du Session Storage
     * @param caller identifiant de l'appelant (composant, service, etc)
     * @param cle clé d'identification de l'objet
     * @returns l'identifiant effectif
     */
    private buildKey(caller: string, cle: string): string {
        //Contrôles
        if (!caller || caller == '') { throw new Error('Fourniture d\'un appelant obligatoire'); }
        if (!cle || cle == '') { throw new Error('Fourniture d\'une clé obligatoire'); }

        //Retour
        return caller + '_' + cle;
    }
}
