<div [class.with-floating-button]="!fromAdmin">
    <div class="content" *ngIf="vehicule">
        <page-header [title]="'vehicule.title' | translate:{immatriculation: vehicule.immatriculation}"
                     [extraInfo]="getStatusLibelle(vehicule.statut)" [extraInfoTooltip]="motifRejet"
                     [listeItems]="listeTabItems"
                     [niveauAlerte]="listeAlertes?.niveau"
                     (onSelectedItemChange)="onSelectedItemChange($event)"
                     (onGoBack)="onGoBack()"
                     [isPending]="isSaving">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
        </page-header>
        <div *ngIf="selectedItem?.code == 'GENERALITES'">
            <mat-card>
                <mat-card-title>
                    <span class="left" [translate]="'vehicule.formulaire.label'"></span>
                </mat-card-title>
                <mat-card-content>
                    <form autocomplete="off" name="form_vehicule">
                        <fieldset>
                            <div class="row">
                                <custom-input #immatComponent
                                              [(ngModel)]="vehicule.immatriculation"
                                              (onChange)="vehicule.immatriculationChanged = true"
                                              (input)="formaterImmatriculation()"
                                              customType="input-obfuscated"
                                              libelle="vehicule.liste.immatriculation"
                                              maxlength="20"
                                              [postTooltip]="canModifier ? ('vehicule.formulaire.immatTooltip' | translate) : undefined"
                                              [canReadClear]="canReadClear"
                                              (onReadClearRequest)="getPlaqueImmatriculationVehiculeNonObfusquee()"
                                              name="immatriculation"
                                              ngDefaultControl
                                              [required]="true"
                                              [disabled]="!canModifier">
                                </custom-input>
                                <custom-input
                                        ngDefaultControl
                                        id="actif"
                                        name="actif"
                                        customType="checkbox"
                                        [ngModel]="vehicule.actif"
                                        [(customModel)]="vehicule.actif"
                                        libelle="vehicule.formulaire.actif"
                                        postLibelle="vehicule.formulaire.actifDescriptif"
                                        [disabled]="!fromProfil && !fromAdmin">
                                </custom-input>
                            </div>
                            <div class="row">
                                <custom-input
                                        ngDefaultControl
                                        id="marque"
                                        name="marque"
                                        customType="input"
                                        [(ngModel)]="vehicule.marque"
                                        libelle="vehicule.formulaire.marque"
                                        [required]="true"
                                        [disabled]="!canModifier">
                                </custom-input>
                                <custom-input
                                        ngDefaultControl
                                        id="puissanceFiscale"
                                        name="puissanceFiscale"
                                        customType="select"
                                        [(ngModel)]="vehicule.idPuissanceFiscale"
                                        libelle="vehicule.liste.puissanceFiscale"
                                        [selectOptions]="listePuissance"
                                        optionDisplay="libelle"
                                        optionValue="idPuissance"
                                        (onChange)="onPuissanceFiscaleChange()"
                                        [required]="true"
                                        [disabled]="!canModifier">
                                </custom-input>
                            </div>
                            <div class="row">
                                <custom-input
                                        ngDefaultControl
                                        id="modele"
                                        name="modele"
                                        customType="input"
                                        [(ngModel)]="vehicule.modele"
                                        libelle="vehicule.formulaire.modele"
                                        [required]="true"
                                        [disabled]="!canModifier">
                                </custom-input>
                                <label class="col-md-2">
                                    <span [translate]="'vehicule.formulaire.historiqueDistance'"></span>
                                </label>
                                <button mat-button color="primary" (click)="openHistoriqueDistance()" style="margin-bottom: 0.5rem">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                            <div class="row">
                                <custom-input
                                        ngDefaultControl
                                        id="assurance"
                                        name="assurance"
                                        customType="checkbox"
                                        [ngModel]="vehicule.assurePro"
                                        [(customModel)]="vehicule.assurePro"
                                        libelle="vehicule.formulaire.assurance"
                                        postLibelle="vehicule.formulaire.assuranceDescriptif"
                                        [disabled]="!canModifier">
                                </custom-input>
                            </div>
                        </fieldset>
                    </form>
                </mat-card-content>
            </mat-card>
            <vehicule-documents #vehiculeDocuments (onDocumentUploaded)="onDocumentUploaded($event)" *ngIf="vehicule.idPa" [canModifier]="canModifier" [settings]="this.settings" [vehicule]="vehicule" [isResponsableVehicule]="isResponsableVehicule" [canGestionDocuments]="canGestionDocuments"></vehicule-documents>
        </div>
        <div *ngIf="selectedItem?.code == 'COMPLEMENTS'">
            <document-list *ngIf="vehicule.idPa"
                           [context]="'PROFIL_VEHICULE'" [idObjetForPJ]="vehicule.idCollab"
                           [idObjet]="vehicule.idPa" [settings]="settings" [canAddDocument]="false" [canDeleteDocument]="false"
                           [uri]="'/controller/Document/searchVehiculeDocuments?id_vehicule='">
            </document-list>
        </div>
    </div>
    <please-wait class="content" *ngIf="!vehicule"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
</div>
