import {SynchroSBTConfigUser} from "../voyage/travel/synchro-sbt-config-user";

/**
 * Informations du profil voyageur renvoyées par /Profil/loadProfil.
 * @author François Turin
 */
export type ProfilVoyageur = {
	listeSynchro: SynchroSBTConfigUser[];
	listeAllSynchro: SynchroSBTConfigUser[];
	profilConnexionParDefaut: ProfilConnexionMinimal;
	profilConnexionCollab: ProfilConnexionMinimal;
	saisieObligatoire: SaisieObligatoire;
}

/**
 * Liste des saisies obligatoires manquantes.
 */
export type SaisieObligatoire = {
	profilUtilisateurValide: boolean;
	profilVoyageurValide: boolean;
	listeSaisieManquante: TypeSaisie[];
}

/**
 * Profil de connexion minimal (uniquement l'id)
 */
export class ProfilConnexionMinimal {
	idProfilConnexion: number;
	code: string;
}

/** DTO du profil de connexion */
export class ProfilConnexion extends ProfilConnexionMinimal {
	libelle: string;
	forceOffline: boolean;
	choixOnOff: boolean;
}

/**
 * Types de saisie.
 */
export enum TypeSaisie {
	INFORMATION_VOYAGEUR = "INFORMATION_VOYAGEUR",
	ADRESSE = "ADRESSE",
	TELEPHONE = "TELEPHONE",
	CONTACT_URGENCE = "CONTACT_URGENCE",
	PIECE_IDENTITE = "PIECE_IDENTITE",
	COMPTE_BANCAIRE = "COMPTE_BANCAIRE",
	VEHICULE = "VEHICULE"
}

/**
 * Résultat de la synchro du profil voyageur.
 */
export type ResultSynchro = {
	ok: boolean;
	message: string;
}
