<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">account_balance</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="this.extraOptions.open(data)">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'profilUser.compteBancaire.type'"></strong>
                <span>{{ data.typeCompte }}</span>
            </li>
            <li *ngIf="data.devise">
                <strong [translate]="'profilUser.compteBancaire.devise'"></strong>
                <span>{{ data.devise }}</span>
            </li>
            <li *ngIf="data.beneficiaire">
                <strong [translate]="'profilUser.compteBancaire.titulaire'"></strong>
                <span>{{ data.beneficiaire }}</span>
            </li>
            <li *ngIf="data.domiciliation">
                <strong [translate]="'profilUser.compteBancaire.domiciliation'"></strong>
                <span>{{ data.domiciliation }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.compteBancaire.default'"></strong>
                <span [translate]="data.defaut ? 'global.oui' : 'global.non'"></span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span [translate]="data.actif ? 'global.oui' : 'global.non'"></span>
            </li>
        </ul>
    </div>
</div>
