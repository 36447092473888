import {Prestation} from "@domain/prestation/prestation";
import {ListItem} from "@domain/common/list-view/list-item";
import {Nature} from "@domain/prestation/nature";
import {Taxe} from "@domain/facture/taxe";

/**
 * Représente une ligne de détail d'une facture.
 *
 * @author Laurent Convert
 * @date 12/01/2023
 */
export class FactureDepense implements ListItem {
    idDetFacture: number;
    idEntFacture: number;
    prestation: Prestation;
    montant: number;
    quantite: number;
    remarque: string;
    nature: Nature;
    taxe: boolean;
    taxePrimaire: number;
    taxeSecondaire: number;
    idTypeCharge: number;
    qteEssence: number;
    field1: string;
    field2: string;
    field3: string;
    field4: string;
    listeTaxe: Array<Taxe>;
    
    constructor(dto?:any) {
        if (dto) {
            Object.assign(this,dto);
        }
    }
    
    getKey(): number {
        return this.idDetFacture;
    }
    
}