<form #form="ngForm">
    <div *ngIf="(pieceJointeService.getPieceJointeParams() | async) as pieceJointeParams; else loading">
        <div *ngFor="let pieceJointeParam of pieceJointeParams; let i = index">
            <div *ngIf="pieceJointeParam.idPortee !== TypePorteeParam.PV">
                <param-portee
                        [pieceJointeParam]="pieceJointeParam"
                        [index]="i"
                ></param-portee>
                <filtre-param
                        [idPortee]="pieceJointeParam.idPortee"
                ></filtre-param>
            </div>
        </div>
    </div>
    <div>
        <stockage-local></stockage-local>
    </div>
    <floating-button [listeActions]="listeActions" [isPending]="pieceJointeService.getIsSaving() | async"></floating-button>
</form>

<!--Affiché si on a pas encore le modèle-->
<ng-template #loading>
    <please-wait class="content"></please-wait>
</ng-template>