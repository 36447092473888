import { Component,Input,OnInit } from '@angular/core';
import { ListView } from "../../../../domain/common/list-view";
import { DetailEnveloppeListItemComponent } from "./detail-enveloppe-list-item.component";
import { TypeBudget } from "../../../../domain/budget/type-budget.enum";
import { TranslateService } from "@ngx-translate/core";
import { TypeMontant } from "../../../../domain/budget/type-montant.enum";
import { Enveloppe } from "../../../../domain/budget/enveloppe";
import { DetailEnveloppe } from "../../../../domain/budget/detailEnveloppe";

@Component({
    selector: 'detail-enveloppe-list',
    templateUrl: './detail-enveloppe-list.component.html'
})
/**
 * Composant de détail d'une enveloppe
 */
export class DetailEnveloppeListComponent implements OnInit {

    /** L'enveloppe dont on veut le détail */
    @Input() enveloppe: Enveloppe;

    /** Le type de budget (réel ou prévisionnel) que l'on souhaite consulter */
    @Input() typeBudget: TypeBudget;

    /** Liste des lignes d'imputation de budget de l'enveloppe */
    listeImpBudg: ListView<DetailEnveloppe, DetailEnveloppeListItemComponent>;

    /**
     * Constructeur
     *
     * @param translateService le moteur de trad
     */
    constructor(private translateService: TranslateService) { }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        this.listeImpBudg = new ListView<DetailEnveloppe, DetailEnveloppeListItemComponent>({
            uri: '/controller/Budget/detailEnveloppe/' + this.enveloppe.idEnveloppe + '/montant/' + this.enveloppe.idBudget + '?typeBudget=' + this.typeBudget,
            title: this.translateService.instant("budget.detail.liste.title"),
            component: DetailEnveloppeListItemComponent,
            isFilter: true,
            listeFilters: [{
                title: this.translateService.instant("filter.typeMontant"),
                clef: "typeMontant",
                isDefault: true,
                listeValues: [{
                    code: TypeMontant.NDF.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.ndf")
                },{
                    code: TypeMontant.IJ.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.ij")
                },{
                    code: TypeMontant.FACTURE.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.facture")
                },{
                    code: TypeMontant.DOSSIER_VOYAGE.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.dossierVoyage")
                },{
                    code: TypeMontant.FRAIS_PREVISIONNEL.toString(),
                    libelle: this.translateService.instant("budget.typeMontant.fraisPrev")
                }]
            },{
                title: this.translateService.instant("filter.agent"),
                clef: "matriculeUser,nomUser,prenomUser",
                isDefault: true
            },{
                title: this.translateService.instant("filter.organisation"),
                clef: "numServ,libServ",
                isDefault: true
            },{
                title: this.translateService.instant("filter.dossier"),
                clef: "refDossier,libDossier",
                isDefault: true
            }]
        });
    }

}
