import {Component, Inject, OnInit} from '@angular/core';
import {OMPService} from "../../omp.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "../../../../share/component/confirmation/confirm.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {Transport, TypeTransport} from "../../../../domain/omp/transport";
import {PorteeConfort} from "../../../../domain/omp/porteeConfort";

@Component({
    selector: 'omp-transport-add',
    templateUrl: './omp-transport-add.component.html',
})
export class OMPTransportAddComponent implements OnInit {
    /** Déclaration pour accès direct dans le template */
    TypeTransport = TypeTransport;

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /** Liste des types de transport */
    listeTypeTransport: Array<any>;

    /**
     * Constructeur
     */
    constructor(private ompService: OMPService, private toastrService: ToastrService,private translateService: TranslateService,
                private confirmService: ConfirmService,private matDialogRef: MatDialogRef<any,any>,
                @Inject(MAT_DIALOG_DATA) public data: { transport: Transport,mapConfort: Array<any>,listeVehicule: Array<any>,canModifier: boolean }) {

    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        this.listeTypeTransport = [];
        let val: number;

        //Parcours de l'énumération des types de transport pour construire la liste utilisées par le select
        for (const [key, value] of Object.entries(TypeTransport)) {
            //Vérification que la valeur est bien un nombre
            if (!isNaN(val = Number(value))) {
                //Ajout d'un objet contenant les informations nécessaires à la liste
                this.listeTypeTransport.push({
                    name: `${key}`,
                    id: value,
                    libelle: this.ompService.translateTypeTransport(val)
                })
            }
        }
    }

    /**
     * Retourne la valeur affichée dans l'avatar de la liste des transports autorisés
     *
     * @param typeTransport Type de transport
     */
    getAvatarForTypeTransport(typeTransport: TypeTransport): string {
        return this.ompService.getAvatarForTypeTransport(typeTransport);
    }

    /**
     * Retourne le titre d'une ligne de transport autorisé (son type, traduit)
     *
     * @param typeTransport Type de transport
     */
    getTitleForTypeTransport(typeTransport: TypeTransport): string {
        return this.ompService.translateTypeTransport(typeTransport);
    }

    /**
     * Sélection du mode de transport par l'utilisateur
     *
     * @param typeTransport Type de transport
     */
    selectTypeTransport(typeTransport: TypeTransport): void {
        this.data.transport.type = typeTransport;
    }

    /**
     * Retourne True si le transport en cours d'édition a un type
     */
    transportHasType(): boolean {
        return this.data.transport.type && this.data.transport.type > 0
    }

    /**
     * Retour à la sélection du type de transport depuis la liste
     */
    goBack(): void {
        this.data.transport.type = undefined;
        this.data.transport.idVehicule = undefined;
        this.data.transport.idConfort = undefined;
        this.data.transport.attribut1 = undefined;
    }

    /**
     * Retourne la liste des conforts en fonction du type de transport (avion ou train).
     * Si le type ne possède aucun confort associé, une liste vide est retournée
     *
     * @param typeTranport Le type de transport
     */
    getListeConfortForTypeTransport(typeTranport: number): Array<any> {
        let listeConfort: Array<any>;
        let portee: string;

        //On détermine la portée en fonction du type de transport
        switch (typeTranport) {
            //Train
            case TypeTransport.TYPE_TRAIN:
                portee = PorteeConfort.TRAIN;
                break;
            //Avion
            case TypeTransport.TYPE_AVION:
                portee = PorteeConfort.AVION;
                break;
        }

        //Si une portée a bien été déterminée, récupération de la liste correspondante des conforts
        if (portee) {
            listeConfort = this.data.mapConfort[portee];
        }

        return listeConfort;
    }

    /**
     * Suppression du transport
     */
    deleteTransport(): void {
        //Demande de confirmation
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe({
                next: () => {
                    //Enregistrement en cours
                    this.isSaving = true;

                    //Suppression du transport
                    this.ompService.deleteTransport(this.data.transport).subscribe({
                        next: data => {
                            //Vérification de l'enregistrement
                            if (data.codeErreur == 0) {
                                //Message d'information
                                this.toastrService.success(this.translateService.instant('global.success.suppression'));

                                //Fermeture de l'écran
                                this.matDialogRef.close(data.data);
                            } else {
                                //Message d'erreur
                                this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                            }
                        },
                        complete: () => {
                            //Fin de l'enregistrement
                            this.isSaving = false;
                        }
                    });
                }
            });
    }

    /**
     * Enregistrement du transport
     */
    saveTransport(): void {
        //Enregistrement en cours
        this.isSaving = true;

        //Enregistrement du transport
        this.ompService.saveTransport(this.data.transport).subscribe({
            next: data => {
                //Vérification de l'enregistrement
                if (data.codeErreur === 0) {
                    //Message d'information
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Fermeture de l'écran
                    this.matDialogRef.close(data.data);
                } else
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
            },
            complete: () => {
                //Fin de l'enregistrement
                this.isSaving = false;
            }
        });

    }

}
