<!-- Entête -->
<h1 mat-dialog-title>
    <i class="nio icon-transfere clickable" (click)="onGoBack()"></i>
    <span>
        <span translate>ndf.frais.ajouterTournee.title</span><span>:&nbsp;</span>
    </span>
    <span class="actions" [mat-dialog-close]>
        <span><i class="zmdi zmdi-close"></i></span>
    </span>
</h1>

<div mat-dialog-content>
    <!--Stepper tournee-->
    <stepper-tournee
            [tournee]="tournee"
            [resetObs]="resetSubject.asObservable()"
            [etapesPersonnelles]="data.etapesPersonnelles"
            [gestionTempsGlobale]="data.gestionTempsGlobale"
            [gestionTempsDetaillee]="data.gestionTempsDetaillee"
            [quantiteModifiable]="data.quantiteModifiable"
            [canModifier]="data.canModifier"
    ></stepper-tournee>
</div>

<!-- Boutons bas de fenêtre -->
<div mat-dialog-actions>
    <span>
        <!-- Supprimer -->
        <button mat-stroked-button (click)="onEffacerChamps()" color="warn" class="m-r-10" [disabled]="!data.canModifier">
            <span translate>ndf.frais.ajouterTournee.effacerChamps</span>
        </button>
    </span>
    <span>
        <!-- Mémoriser -->
        <button mat-stroked-button (click)="onMemoriser()" color="primary" class="m-r-10" [disabled]="hasSubFormError() || !data.canModifier">
            <span translate>ndf.frais.ajouterTournee.memoriserTournee</span>
        </button>
    </span>
    <span>
        <!-- Enregistrer -->
        <button mat-flat-button (click)="onEnregistrer()" color="primary" class="m-r-10" [disabled]="hasSubFormError() || !data.canModifier">
            <span translate>global.actions.confirmer</span>
        </button>
    </span>
</div>