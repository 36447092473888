import {Component, Input} from "@angular/core";
import {ConfigLogging} from "@domain/admin/logs/config-logging";

/**
 * Composant de gestion du serveur de centralisation des logs
 */
@Component({
    host: {'data-test-id': 'serveur-centralisation'},
    selector: 'serveur-centralisation',
    templateUrl: './serveur-centralisation.component.html'
})
export class ServeurCentralisationComponent {
    /** Config */
    @Input() configLoging: ConfigLogging;
}
