/**
 * Classe représentant les paramètres de maintenance
 */
export class MaintenanceParam {
    idMaintenanceParam: number;
    migrationAuto: boolean = false;
    emailContactAdmin: string;
    emailContactNotilus: string;

    constructor(dto?: any) {
        if (dto) {
            this.idMaintenanceParam = dto.idMaintenanceParam;
            this.migrationAuto = dto.migrationAuto;
            this.emailContactAdmin = dto.emailContactAdmin;
            this.emailContactNotilus = dto.emailContactNotilus;
        }
    }
}