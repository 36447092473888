<mat-card>
    <mat-card-title>
        <span [translate]="'lot.generalites.title'"></span>
    </mat-card-title>
    <mat-card-content>
        <form autocomplete="off" #form="ngForm" *ngIf="lot && settings" name="avance_form">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <div class="col-md-6" matchChildrenHeight="row">
                        <div class="row">
                            <custom-input
                                ngDefaultControl
                                id="intitule"
                                name="intitule"
                                [(ngModel)]="lot.libelle"
                                customType="input"
                                libelle="lot.generalites.intitule"
                                lCol="4"
                                rCol="8"
                                [required]="true">
                            </custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="periode"
                                          name="periode"
                                          [(ngModel)]="lot.periode"
                                          (onChange)="onPeriodeChange()"
                                          customType="objectselect"
                                          [selectOptions]="settings?.listePeriodes"
                                          optionValue="idPeriode"
                                          optionDisplay="libelle"
                                          libelle="lot.generalites.periode"
                                          lCol="4"
                                          rCol="8"
                                          [disabled]="!canModifier"
                                          required="true">
                            </custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="dateLot"
                                          name="dateLot"
                                          libelle="lot.generalites.date"
                                          [ngModel]="lot.date"
                                          [(customModel)]="lot.date"
                                          customType="date"
                                          [required]="true"
                                          lCol="4"
                                          rCol="8"
                                          [disabled]="settings?.typeDateLot !== TypeDateLotComptable.DATE_A_SAISIR">
                            </custom-input>
                        </div>
                        <div *ngIf="lot.idLot !== 0" class="row">
                            <label class="col-md-4"><span [translate]="'lot.generalites.createur'"></span></label>
                            <div class="col-md-8 d-flex flex-row align-items-center">
                                <span *ngIf="lot.user">{{lot.user | user}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="listview">
                            <div class="listview-body">
                                <div class="listview-item">
                                    <div class="row no-gutters clickable" (click)="toggleDetail();">
                                        <ng-container *ngTemplateOutlet="lotMontant; context:{
                                            iconeName: 'icon-comptabilisation',
                                            isTotal: true,
                                            title: 'lot.generalites.total',
                                            montant: lot.montant,
                                            nombre: null
                                        }"></ng-container>
                                    </div>
                                    <div *ngIf="isOuvert" class="lvi-content px-0 mr-0 ml-5">
                                        <div class="listview flex-grow-1">
                                            <div class="listview-body">
                                                <div class="listview-item row no-gutters px-0 mr-0">
                                                    <ng-container *ngTemplateOutlet="lotMontant; context:{
                                                        iconeName: 'icon-note_de_frais',
                                                        isTotal: false,
                                                        title: 'lot.generalites.ndf',
                                                        montant: lot.montantNdf,
                                                        nombre: lot.nbNdf
                                                    }"></ng-container>
                                                </div>
                                                <div class="listview-item row no-gutters px-0 mr-0">
                                                    <ng-container *ngTemplateOutlet="lotMontant; context:{
                                                        iconeName: 'icon-avance_2',
                                                        isTotal: false,
                                                        title: 'lot.generalites.avance',
                                                        montant: lot.montantAvance,
                                                        nombre: lot.nbAvance
                                                    }"></ng-container>
                                                </div>
                                                <div class="listview-item row no-gutters px-0 mr-0">
                                                    <ng-container *ngTemplateOutlet="lotMontant; context:{
                                                        iconeName: 'icon-releve_de_facture',
                                                        isTotal: false,
                                                        title: 'lot.generalites.facture',
                                                        montant: lot.montantFacturesAvoirs,
                                                        nombre: lot.nbFacture
                                                    }"></ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>

<ng-template #lotMontant let-iconeName="iconeName" let-isTotal="isTotal" let-title="title" let-montant="montant" let-nombre="nombre">
    <div class="lvi-content" [ngClass]="{'col-12 pl-4':!isTotal, 'col-12':isTotal }">
        <div class="avatar" [nioTooltip]="title | translate" nioTooltipPosition="right">
            <ng-container>
                <i [ngClass]="iconeName" class="nio align-middle"></i>
            </ng-container>
        </div>
        <div class="lvi-body">
            <div class="title">
                <a>{{ title | translate }}</a>
                <span *ngIf="isTotal" class="material-icons" matTooltipPosition="right" [nioTooltip]="('lot.generalites.montantRemb'| translate: { montant:lot.montantNdf - lot.montantOpeCarte | montant: settings?.deviseEntreprise})+'\n'+('lot.generalites.montantOpeCarte'| translate: { montant:lot.montantOpeCarte | montant: settings?.deviseEntreprise})">info</span>
                <span *ngIf="iconeName === 'icon-releve_de_facture' && nombre" class="material-icons" matTooltipPosition="right" [matTooltip]="('lot.generalites.montantFacture'| translate: { montant:lot.montantFactures | montant: settings?.deviseEntreprise})+'\n'+('lot.generalites.montantAvoir'| translate: { montant:lot.montantAvoirs | montant: settings?.deviseEntreprise})">info</span>
            </div>
            <ul class="lvi-attrs">
                <li><strong [translate]="'lot.generalites.total'"></strong>{{ montant | montant: settings?.deviseEntreprise }}</li>
                <li *ngIf="!isTotal"><strong [translate]="'lot.generalites.nombre'"></strong>{{ nombre }}</li>
            </ul>
        </div>
        <div class="lvi-actions" *ngIf="isTotal">
            <button mat-icon-button>
                <mat-icon *ngIf="isOuvert" color="primary">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!isOuvert" color="primary">keyboard_arrow_down</mat-icon>
            </button>
        </div>
    </div>
</ng-template>
