import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {Zone} from "@domain/geographie/zone";
import {NgForm} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute,Router} from "@angular/router";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter, first} from "rxjs/operators";

/**
 * Composant d'une zone
 *
 * @author Angeline Ha
 * @date 07/11/2023
 */
@Component({
	host: {'data-test-id': 'zone'},
	selector: 'zone',
	templateUrl: './zone.component.html'
})
export class ZoneComponent implements OnInit, OnDestroy {
	/** Liste des différents onglets disponibles dans un Territoire */
	listeTabItems: Array<PageHeaderItem>;

	/** Onglet courant */
	selectedItem: PageHeaderItem;

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglets = Onglets;

	/** Zone à afficher */
	zone: Zone;

	/** Formulaire */
	@ViewChild('form') form: NgForm;

	/** Indique s'il faut afficher le formulaire en création ou en édition */
	isCreation: boolean;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);


	/**
	 * Constructeur
	 *
	 * @param geographieService	Service géographique
	 * @param translateService	Service des traductions
	 * @param router			Router
	 * @param route				Route active
	 * @param toastrService		Service des toasts
	 * @param confirmService	Service de confirmation
	 */
	constructor(private geographieService: GeographieService,
				private translateService: TranslateService,
				private router: Router,
				private route: ActivatedRoute,
				private toastrService: ToastrService,
				private confirmService: ConfirmService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit(): void {
		//Indique au composant géographie qu'on est dans le détail d'un objet
		this.geographieService.isShowDetail = true;

		//Initialisation des onglets
		this.listeTabItems = [{
			code: Onglets.GENERALITES,
			libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.generalites')
		}];

		//Sélection du premier onglet par défaut
		this.selectedItem = this.listeTabItems[0];

		//Récupération de l'identifiant de la zone
		this.route.params.pipe(first()).subscribe(params => {
			const idZone = params['idZone'];

			//Indique si on est en mode création
			this.isCreation = idZone === '0';

			//Définition de la liste des actions possibles
			this.defineListeActionsPossibles();

			if (this.isCreation) {
				//Création d'une nouvelle zone
				this.zone = {
					code: "",
					commentaire: "",
					idZone: 0,
					libelle: "",
					libelleNettoye: "",
				};
			} else {
				//Récupération de la zone
				this.geographieService.getZone(idZone).subscribe(result => {
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						this.zone = result.data.zone;
					} else {
						this.toastrService.error(this.translateService.instant('global.errors.chargement'))
					}
				});

				//Ajout de l'onglet Périmètres
				this.listeTabItems.push({
					code: Onglets.PERIMETRE,
					libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.perimetre')
				});
			}
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		this.geographieService.isShowDetail = false;
	}

	/**
	 * Définition de la valeur de la liste des actions possibles
	 */
	defineListeActionsPossibles() {
		//Liste des actions
		const listeActions: Array<FloatingButtonAction> = [{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			doAction: () => this.saveZone(),
			isDisabled: () => !this.form?.valid
		}];

		//Ajout de l'action de suppression lorsqu'on est en modification
		if (!this.isCreation) {
			listeActions.push({
				type: TypeAction.SECONDARY,
				icone: 'nio icon-suppression',
				libelle: 'global.actions.supprimer',
				doAction: () => this.deleteZone()
			});
		}

		this.listeActions.next(listeActions);
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem) {
		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour vers la liste des zones
	 */
	onGoBack() {
		this.router.navigate(['../..'],{relativeTo: this.route});
	}

	/**
	 * Sauvegarde de la zone
	 */
	saveZone() {
		this.geographieService.saveZone(this.zone).subscribe(data => {
			if (data.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Toast de succès
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'))

				if (this.isCreation) {
					//Récupération de l'identifiant
					this.zone.idZone = data.data.idZone;

					//On n'est plus en mode création
					this.isCreation = false;

					//Définition de la liste des actions possibles
					this.defineListeActionsPossibles();

					//Navigation avec le bon id
					this.router.navigate(['../' + data.data.idZone],{relativeTo: this.route});

					//Ajout de l'onglet périmètres
					this.listeTabItems.push({
						code: Onglets.PERIMETRE,
						libelle: this.translateService.instant('admin.bibliotheque.geographie.territoire.onglets.perimetre')
					});

					//Sélection de l'onglet Périmètres
					this.selectedItem = this.listeTabItems.find(tab => tab.code === Onglets.PERIMETRE);
					this.selectedItem.selected = true;
				}
			} else if (data.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
				//Toast d'erreur : le code renseigné est déjà associé à une autre zone
				this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.zone.codeDoublon'))
			} else {
				//Toast d'erreur
				this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))
			}
		})

	}

	/**
	 * Suppression de la zone
	 */
	deleteZone() {
		//Popup de confirmation
		this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.zone.confirmationSuppression'))
			.pipe(filter(isConfirmed => isConfirmed)).subscribe({ next: () => {
				//Suppression de la zone
				this.geographieService.deleteZone(this.zone.idZone).subscribe(result => {
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Retour vers la liste des zones
						this.onGoBack();

						//Toast de succès
						this.toastrService.success(this.translateService.instant('global.success.suppression'));
					} else {
						//Toast d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.suppression'));
					}
				})
			}})
	}

}

/**
 * Enum des différents onglets d'une zone
 */
export enum Onglets {
	GENERALITES = "Généralités",
	PERIMETRE = "Périmètre"
}
