import {ListItem} from "@domain/common/list-view/list-item";

/**
 * Cours d'une devise dans la liste des taux.
 */
export class CoursDeviseItem implements ListItem {
    codeDevise: string;
    dAppli: Date;
    taux: number;
    codeDeviseContrepartie: string;
    codeOrigine: number;

    getKey(): string {
        return this.codeDevise;
    }
}
