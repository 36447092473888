<mat-card>
    <mat-card-title-group>
        <mat-card-title>
            <span [translate]="'admin.maintenance.logs.serveurCard.title'"></span>
        </mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
        <div class="row">
            <custom-input ngDefaultControl
                          id="adresseIp"
                          name="adresseIp"
                          [(ngModel)]="configLoging.adresse"
                          customType="input"
                          libelle="admin.maintenance.logs.serveurCard.adresseIp"
                          [postTooltip]="'admin.maintenance.logs.serveurCard.tooltipConfDefaut' | translate">
            </custom-input>
            <custom-input ngDefaultControl
                          id="portUdp"
                          name="portUdp"
                          [(ngModel)]="configLoging.port"
                          customType="input"
                          [required]="!!configLoging.adresse"
                          [disabled]="!configLoging.adresse"
                          libelle="admin.maintenance.logs.serveurCard.portUdp">
            </custom-input>
        </div>
        <div class="row">
            <custom-input ngDefaultControl
                          id="actif"
                          name="actif"
                          [ngModel]="configLoging.actif"
                          [(customModel)]="configLoging.actif"
                          customType="checkbox"
                          libelle="admin.maintenance.logs.serveurCard.actif"
                          postLibelle="admin.maintenance.logs.serveurCard.actifPostLibelle">
            </custom-input>
        </div>
    </mat-card-content>
</mat-card>