import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoginComponent } from './login.component';
import { LoginService } from './login.service';
import { LoginEffects } from './login.effects';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from '../directive/autofocus/autofocus.module';
import { RecaptchaModule } from '../directive/recaptcha/recaptcha.module';
import { SessionStorageService } from '../../domain/common/services/session-storage.service';
import {PleaseWaitModule} from "../component/please-wait/please-wait.module";

@NgModule({
    imports: [BrowserModule, RouterModule, FormsModule, TranslateModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, EffectsModule.forFeature([LoginEffects]), AutoFocusModule, MatProgressSpinnerModule, RecaptchaModule, PleaseWaitModule],
    declarations: [LoginComponent],
    exports: [LoginComponent],
    providers: [LoginService,SessionStorageService]
})
export class LoginModule {}