import { Component,Input } from '@angular/core';
import { User } from "../../../../../../../domain/user/user";

/**
 * Composant d'affichage d'un user pour la popup d'émission des OD de participants
 *
 * @author Laurent SCIMIA
 * @date 11/02/2022
 */
@Component({
	templateUrl: './od-participant-popin-emission-item.component.html'
})
export class OdParticipantPopinEmissionItemComponent {
	/** User à afficher */
	@Input()
	data: User;
}
