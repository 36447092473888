<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="data.portee === typePortee.OD" class="nio icon-ordre_de_mission"></i>
        <i *ngIf="data.portee === typePortee.OT" class="nio icon-ordre_de_mission_permanent"></i>
        <i *ngIf="data.portee === typePortee.NF" class="nio icon-note_de_frais"></i>
        <i *ngIf="data.portee === typePortee.AV" class="nio icon-avance_2"></i>
        <i *ngIf="data.portee === typePortee.OP" class="nio icon-frais"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="dashboard-list-title" [routerLink]="" (click)="navigateTo()"><span>{{ getTitle() }}</span></a>
        </div>
        <ul class="lvi-attrs" *ngIf="data.nbATraiter == null">
            <ng-container *ngIf="data.portee !== typePortee.OP">
                <li><strong [translate]="'dashboard.listeObjets.attributs.enCreation'"></strong><span>{{ data.nbEnCreation }}</span></li>
                <li><strong [translate]="'dashboard.listeObjets.attributs.enValidation'"></strong><span>{{ data.nbEnValidation }}</span></li>
                <li><strong [translate]="'dashboard.listeObjets.attributs.valides'"></strong><span>{{ data.nbValides }}</span></li>
            </ng-container>
            <ng-container *ngIf="data.portee === typePortee.OP">
                <li><strong [translate]="'dashboard.listeObjets.attributs.fraisOCR'"></strong><span>{{ data.nbFraisOCR }}</span></li>
                <li><strong [translate]="'dashboard.listeObjets.attributs.fraisRefuses'"></strong><span>{{ data.nbFraisRefuses }}</span></li>
                <li><strong [translate]="'dashboard.listeObjets.attributs.fraisCarte'"></strong><span>{{ data.nbFraisCarte }}</span></li>
            </ng-container>
        </ul>
        <ul class="lvi-attrs" *ngIf="data.nbATraiter != null && data.nbATraiter >= 0">
            <li><strong [translate]="'dashboard.listeObjets.attributs.aTraiter'"></strong><span>{{ data.nbATraiter }}</span></li>
        </ul>
    </div>
</div>