import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Migration} from "@domain/admin/maintenance/migration";
import {MatDialog} from "@angular/material/dialog";
import {MigrationPopupComponent} from "@components/admin/maintenance/migrations/historique/migration-popup.component";

/**
 * Composant pour afficher une migration dans la liste
 */
@Component({
    host: {'data-test-id': 'migration-item'},
    templateUrl: './migration-item.component.html',
    styleUrls: ['./migration-item.component.scss']
})
export class MigrationItemComponent extends ListViewItem<Migration> {

    /**
     * Constructeur
     */
    constructor(protected matDialog: MatDialog) { super(); }

    /**
     * Ouverture de la popup pour la migration sélectionnée
     */
    openMigration(): void {
        //Ouverture de la popup de log
        this.matDialog.open(MigrationPopupComponent, {
            data: {
                migration: this.data
            },
            minWidth: 1200,
            maxWidth: 1200,
            minHeight: 600,
            maxHeight: 600
        });
    }
}
