import {NgModule} from '@angular/core';
import {LangueInfosComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/langue-infos.component";
import {ShareModule} from "@share/share.module";
import {LangueItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-item/langue-item.component";
import {InternationalisationComponent} from "./internationalisation.component";
import {LangueInfosConfigurationComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/configuration/langue-infos-configuration.component";
import {DocumentModule} from "@components/document/document.module";
import {JsonSelectorComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/configuration/json-selector.component";
import {LangueInfosLibelleComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/langue-infos-libelle.component";
import {LibelleItemComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/libelle-item.component";
import {PopupLibelleComponent} from "@components/admin/bibliotheque/internationalisation/langue-infos/libelles/popup-libelle.component";

@NgModule({
    imports: [ShareModule, DocumentModule],
    declarations: [
        InternationalisationComponent,
        LangueItemComponent,
        LibelleItemComponent,
        LangueInfosComponent,
        LangueInfosConfigurationComponent,
        LangueInfosLibelleComponent,
        PopupLibelleComponent,
        JsonSelectorComponent
    ],
    entryComponents: [LangueItemComponent, LibelleItemComponent],
    exports: [
        InternationalisationComponent
    ],
    providers: []
})
export class InternationalisationModule {
}
