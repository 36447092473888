import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PageHeaderItem } from "@share/component/page-header/page-header";

/**
 * Sous-menu compte-admin
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'compte-admin'},
    templateUrl: './compte-admin.component.html'
})
export class CompteAdminComponent {

    /** Onglet unique */
    listeTabItems: Array<PageHeaderItem> = [
        {
            code: null,
            libelle: this.translateService.instant('admin.maintenance.compteAdmin.onglets.compteAdmin'),
            url: 'Admin/Maintenance/CompteAdmin'
        }
    ];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    constructor(protected translateService: TranslateService) {}
}
