import {Directive,ElementRef,EventEmitter,HostBinding,HostListener,Input,OnInit,Output} from '@angular/core';
import {BehaviorSubject,forkJoin,interval,Observable,Subject} from 'rxjs';
import {finalize,first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {FileUploader,FileWithProgress,ProgressType} from './file-uploader';
import {FileUploaderService} from './file-uploader.service';
import {SettingsGlobalState} from '@domain/settings/settings';
import {Result} from '@domain/common/http/result';

@Directive({
    selector: '[fileUploader]',
    exportAs: 'fileUploader'
})
export class FileUploaderDirective implements OnInit {
    /** Uploader **/
    @Input('fileUploader') fileUploader: FileUploader;

    /** Paramétrage **/
    @Input('settings') settings: SettingsGlobalState;

    /** Taille max de la PJ */
    @Input('tailleMax') tailleMax: number;

    /** Id de l'objet associé au document */
    @Input('idObjet') idObjet: number = 0;

    /** Évènement généré lors du drop de fichiers **/
    @Output('onFilesDrop') onFilesDrop = new EventEmitter<Array<File>>();

    /** Évènement généré à la fin de l'ajout des fichiers (par drop ou par sélection) **/
    @Output('onAllItemsAdded') onAllItemsAdded = new EventEmitter<Array<File>>();

    /** Évènement généré lorsque le fichier sort de la zone de drag **/
    @Output('onDragLeave') onDragLeave$ = new EventEmitter<void>();

    /** Classe CSS ajoutée à l'élément lors du drag sur l'élément **/
    @HostBinding('class.active')
    isActif = false;

    /** Sélecteur de fichiers **/
    inputElement: HTMLInputElement;

    /** Temps pour le compte à rebours avant upload, en seconde */
    coutdownTime: number = 5;

    /**
     * Constructeur
     */
    constructor(private element: ElementRef,private fileUploaderService: FileUploaderService,private toastrService: ToastrService,private translateService: TranslateService) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Ajout de la classe
        this.element.nativeElement.classList.add('file-uploader');

        //Création du sélecteur de fichiers
        this.createFileInput();

        //Définition des méthodes de l'uploader
        this.wrapUploaderMethods();
    }

    /**
     * Création du sélecteur de fichiers
     */
    createFileInput() {
        //Création du sélecteur de fichier
        this.inputElement = document.createElement('input');

        //Définition du sélecteur de fichier
        this.inputElement.setAttribute('type','file');

        //Vérification de la possibilité d'envoyer plusieurs fichiers
        if (this.fileUploader.multiple !== false) {
            this.inputElement.setAttribute('multiple',null);
        }

        //Extensions autorisées
        if (this.settings.listeExtensions) {
            let strExtensions = "";
            this.settings.listeExtensions.forEach(ext => strExtensions += "." + ext + ",");
            this.inputElement.accept = strExtensions;
        }

        //Définition du style
        this.inputElement.style.display = 'none';

        //Interception de la sélection de fichiers
        this.inputElement.onchange = (event) => {
            //Parcours des fichiers sélectionnés
            for (let idxFile = 0;idxFile < this.inputElement.files.length;idxFile++) {            
                //Vérification du type de fichier
                if (this.inputElement.files.item(idxFile) instanceof File) {
                    //Vérification de la queue
                    this.fileUploader.queue = this.fileUploader.queue || [];

                    //Récupération de l'extension
                    const uploadedExtension = this.inputElement.files.item(idxFile).name.split('.').pop().toLowerCase();

                    //Récupération de la taille en ko
                    const size = this.inputElement.files.item(idxFile).size / 1024;

                    //Vérification de l'extension du fichier, si l'extension n'existe pas
                    if (!this.settings.listeExtensions.some(extension => extension.toLowerCase() === uploadedExtension)) {
                        this.toastrService.error(this.translateService.instant('global.errors.fileBlocked',{ fileName: this.inputElement.files.item(idxFile).name }));
                    } else if (this.settings.tailleMax != null &&  this.settings.tailleMax < size) {
                        //Si le fichier est trop gros
                        this.toastrService.error(this.translateService.instant('global.errors.tailleInvalide', { fileName: this.inputElement.files.item(idxFile).name }));
                    } else {
                        //Ajout du fichier à la queue
                        this.fileUploader.queue.push(this.inputElement.files.item(idxFile));

                        //Déclenchement de l'intercepteur de fichier ajouté
                        this.fileUploader.onItemAdded && this.fileUploader.onItemAdded(this.inputElement.files.item(idxFile));
                    }
                }
            }

            //Déclenchement de l'évènement d'ajout des fichiers dans la queue
            this.onAllItemsAdded.emit(this.fileUploader.queue);

            //Remise à zéro du sélecteur
            this.inputElement.value = '';
        };
        
        //Ajout du sélecteur de fichiers
        this.element.nativeElement.append(this.inputElement);
    }

    /**
     * Sélection d'un fichier via un clic sur le composant
     */
    @HostListener('click')
    onClick() {
        //Ouverture du sélecteur de fichiers
        this.inputElement.click();
    }

    /**
     * Gestion du drop
     */
    @HostListener('drop',['$event'])
    onDrop(event: DragEvent) {
        let listeFiles;

        //Lecture du DataTransfer
        const { dataTransfer } = event;

        //Annulation de l'évènement
        event.preventDefault();
        
        //Suppression de la classe CSS
        this.isActif = false;
        
        //Vérification des éléments transférés
        if (dataTransfer.items) {
            //Initialisation de la liste de fichiers
            listeFiles = [];
            
            //Parcours des éléments
            for (let idxItem = 0;idxItem < dataTransfer.items.length;idxItem++) {
                //Vérification du type d'élément droppé
                if (dataTransfer.items[idxItem].kind === 'file')
                    //Ajout du fichier dans la liste
                    listeFiles.push(dataTransfer.items[idxItem].getAsFile());
            }
            
            //Suppression des éléments du DataTransfer
            dataTransfer.items.clear();
            
            //Emission des fichiers
            this.onFilesDrop.emit(listeFiles);
        } else {
            //Récupération de la liste de fichiers
            listeFiles = dataTransfer.files;
            
            //Suppression des données du DataTransfer
            dataTransfer.clearData();

            //Emission des fichiers
            this.onFilesDrop.emit(Array.from(listeFiles));
        }
    }

    /**
     * Gestion du dragover
     */
    @HostListener('dragover',['$event'])
    onDragOver(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Arrêt de la propagation
        event.stopPropagation();
        
        //Ajout de la classe CSS indiquant la zone de drag and drop
        this.isActif = true;
    }

    /**
     * Gestion du dragleave
     */
    @HostListener('dragenter',['$event'])
    onDragenter(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Arrêt de la propagation
        event.stopPropagation();
    }

    /**
     * Gestion du dragleave
     */
    @HostListener('dragleave',['$event'])
    onDragLeave(event: DragEvent) {
        //Suppression de la classe CSS
        this.isActif = false;

        //Émission de l'évènement
        this.onDragLeave$.emit();
    }

    /**
     * Gestion du dragover sur le body
     */
    @HostListener('body:dragover',['$event'])
    onBodyDragOver(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Arrêt de la propagation
        event.stopPropagation();

        //Mise à jour de l'évènement
        event.dataTransfer.effectAllowed = 'none';
        event.dataTransfer.dropEffect = 'none';
    }

    /**
     * Gestion du drop sur le body
     */
    @HostListener('body:drop',['$event'])
    onBodyDrop(event: DragEvent) {
        //Annulation de l'événement
        event.preventDefault();

        //Mise à jour de l'évènement
        event.dataTransfer.effectAllowed = 'none';
        event.dataTransfer.dropEffect = 'none';
    }

    /**
     * Définition des méthodes de l'uploader
     */
    wrapUploaderMethods() {
        //Définition des méthodes de l'uploader
        this.fileUploader.addItem = this.onClick.bind(this);
        this.fileUploader.uploadAll = this.uploadAll.bind(this);
        this.fileUploader.uploadItem = this.uploadItem.bind(this);
        this.fileUploader.removeItem = this.removeItem.bind(this);
        this.fileUploader.deleteItem = this.deleteItem.bind(this);
        this.fileUploader.onItemAdded = this.onItemAdded.bind(this);
        this.fileUploader.removeItemByName = this.removeItemByName.bind(this);
    }

    /**
     * Upload de l'ensemble des fichiers
     */
    uploadAll(): Array<Observable<number>> {
        //Appel du service d'upload
        return this.fileUploaderService.upload(this.fileUploader,this.fileUploader.queue);
    }

    /**
     * Upload d'un fichier
     */
    uploadItem(idxItem: number): Array<Observable<number>> {
        let file: FileWithProgress;

        //Récupération du fichier
        file = this.fileUploader.queue[idxItem];
        file.ProgressType = ProgressType.Uploading;

        //Appel du service d'upload
        return this.fileUploaderService.upload(this.fileUploader,[file]).map(o => o.pipe(
            finalize(() => this.fileUploader.autoRemove !== false && this.fileUploader.removeItem(idxItem))
        ));
    }

    /**
     * Suppression du fichier sur le serveur
     * @param idxItem index du fichier    
     */
    deleteItem(idxItem): Observable<Result> {
        let file: FileWithProgress = this.fileUploader.queue[idxItem];

        return this.fileUploaderService.delete(this.fileUploader,file.idDocument,this.idObjet).pipe(
            finalize(() => this.fileUploader.removeItem(idxItem))
        );
    }

    /**
     * Suppression d'un élément
     */
    removeItem(idxItem: number) {
        //Suppression de l'élément
        let fileWithProgresses = this.fileUploader.queue.splice(idxItem,1);

        //Arrêt de la progression
        fileWithProgresses[0].ProgressType = ProgressType.None;
    }

    /**
     * Supression d'un élément par le nom
     */
    removeItemByName(name: string) {
        this.fileUploader.queue =  this.fileUploader.queue?.filter(f => f.name != name);
    }

    /**
     * Evénement lors de l'ajout d'un élement dans le file uploader
     * @param file le fichier ajouté
     */
    onItemAdded(file: FileWithProgress): void {
        if (this.fileUploader.isCountDown) {
           this.addCountdownToItem(file);
        }
    }

    /**
     * Ajout du compte à rebours
     * @param file le fichier ajouté
     */
    addCountdownToItem(file: FileWithProgress) {        
        const progress: Subject<number> = new BehaviorSubject<number>(100);

        file.ProgressType = ProgressType.Countdown;
        file.progress = progress.asObservable();

        //Mise en place du calcul de la barre de progression
        const sub = interval(10).subscribe((period) => {
            const progressValue = 100 - period / this.coutdownTime;

            if (file.ProgressType != ProgressType.Countdown) {
                //Un événement a annulé le compte à rebours
                progress.complete();
                sub.unsubscribe();
            } else if (progressValue <= 0) {
                //Compte à rebours terminé
                progress.complete();
                sub.unsubscribe();

                //On lance l'upload
                const fileIndex = this.fileUploader.queue.findIndex((f) => f === file);
                forkJoin(this.uploadItem(fileIndex)).pipe(first()).subscribe();
            } else {
                //Propagation de la progression
                progress.next(progressValue);
            }
        });
    }
}
