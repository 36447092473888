<div class="lvi-content">
    <div class="avatar">
        <i class="nio icon-ordre_de_mission"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="(data.idOd?.toString() || 'od.liste.numeroNonDefini') + ' - ' + (data.objet || 'od.liste.objetNonDefini')"></a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'od.liste.type'"></strong>{{ data.typeEntite }}</li>
            <li *ngIf="data.depart_le"><strong [translate]="'od.liste.depart_le'"></strong>{{ data.depart_le | date:'shortDate' }}<ng-container *ngIf="data.heureDepart">{{' ' + data.heureDepart }}</ng-container></li>
            <li *ngIf="data.retour_le"><strong [translate]="'od.liste.retour_le'"></strong>{{ data.retour_le | date:'shortDate' }}<ng-container *ngIf="data.heureRetour">{{' ' + data.heureRetour }}</ng-container></li>
            <li *ngIf="data.location" [matTooltip]="data.location + ', ' + data.pays.libelle.toUpperCase()">
                <strong [translate]="'od.liste.libelleVille'"></strong>
                <span *ngIf="data.pays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+data.pays.code2.toLowerCase()"></span>
                {{ data.location }}
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div *ngIf="settings?.deviseEntreprise" class="text-right text-secondary font-weight-bold">
                {{ data.montantProposition | montant:settings.deviseEntreprise }}
            </div>
        </div>
    </div>
</div>
