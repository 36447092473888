<h1 mat-dialog-title>
    <span translate>confirmation.title</span>
</h1>
<div mat-dialog-content>
    <div *ngIf="isLoading">
        <mat-spinner class="mx-auto mt-3" diameter="30"></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <form autocomplete="off" #nameForm="ngForm">
            <div class="row">
                <custom-input
                        ngDefaultControl
                        name="tourneeName"
                        customType="input"
                        libelle="ndf.frais.ajouterTournee.nameDialog"
                        [(ngModel)]="tournee.libelle"
                        [lCol]="5" [rCol]="7"
                        [required]
                ></custom-input>
            </div>
        </form>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button class="m-r-10" [mat-dialog-close] [disabled]="isLoading">
        {{'global.actions.annuler' | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="onConfirm()" [disabled]="tournee.libelle == '' || isLoading"  >
        {{ 'global.actions.confirmer' | translate }}
    </button>
</div>