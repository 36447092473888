import {Component,Input} from '@angular/core';
import {PreferenceAnalytique} from "@domain/profil/preferenceAnalytique";
import {MatDialog} from "@angular/material/dialog";
import {ProfilPreferenceAnalytiquesAddComponent} from "@components/profil/user/preferences-analytiques/profil-preference-analytiques-add.component";
import {SettingsGlobalState} from "@domain/settings/settings";
import {Field} from "@domain/settings/field";
import {AnalytiqueService} from "@components/analytique/analytique.service";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Composant pour une ligne de la liste des préférences analytiques
 */
@Component({
	selector: 'profil-preference-analytiques-list-item',
	templateUrl: './profil-preference-analytiques-list-item.component.html'
})
export class ProfilPreferenceAnalytiquesListItemComponent extends ListViewItem<PreferenceAnalytique> {
	/** Options supplémentaires */
	@Input() extraOptions: {
		settings: SettingsGlobalState,
		isAdmin?: boolean
	};

	/**
	 * Constructeur
	 *
	 * @param matDialog le gestionnaire de popin
	 * @param analytiqueService le service de gestion de l'analytique
	 */
	constructor(
		private matDialog: MatDialog,
		private analytiqueService: AnalytiqueService
	) {
		super();
	}

	/**
	 * Ouvre une préférence analytique pour édition
	 */
	showPreferenceAnalytique() {
		//Si l'on est en admin
		if (this.extraOptions.isAdmin) {
			//Vérification du paramètre de gestion par l'administrateur
			if (!this.extraOptions.settings.analytiqueAdminManagement) {
				return;
			}
		} else {
			//Vérification du paramètre de gestion par l'utilisateur
			if (!this.extraOptions.settings.analytiqueUserManagement) {
				return;
			}
		}

		//Affichage de la popup d'édition
		this.matDialog.open(ProfilPreferenceAnalytiquesAddComponent,{
			data: {
				idUser: this.data.idUser,
				pref: {...this.data},
				settings: this.extraOptions.settings
			},
			width: '80%'
		}).afterClosed().subscribe(reload => {
			//Test du besoin de rechargement de la liste à la fermeture de la popin
			if (reload) {
				//Rechargement
				this.liste.refresh();
			}
		});
	}

	/**
	 * Retourne le champ complémentaire configuré dans l'analytique à partir de son code
	 *
	 * @param code Le code du champ
	 */
	getFieldParam(code: string): Field {
		//Récupération du champ
		return this.analytiqueService.getFieldParam(this.extraOptions.settings,code);
	}
}
