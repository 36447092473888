<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.devises.infosDevise.gestionAvances.ajoutPays'"></span>
    <span [mat-dialog-close]="true"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form>
        <div class="row">
            <custom-input ngDefaultControl name="pays"
                          [(ngModel)]="saisiePays"
                          libelle="lieu.pays"
                          (onChange)="onPaysChange();"
                          optionDisplay="libelle"
                          rCol="4"
                          lCol="4"
                          customType="autocomplete"
                          autocompleteType="geographie"
                          [autocompleteFilter]="{ listeTypes: ['PAYS'] }"></custom-input>
        </div>
    </form>
</div>