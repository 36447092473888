import {Action} from '../domain/action';
import {SettingsState} from '../domain/settings/settings';

/**
 * Export des actions
 */
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';

/**
 * Création du reducer
 */
export function settingsReducer(state: SettingsState = {},action: Action<any>): SettingsState {
    //Vérification de l'action
    switch (action.type) {
    case UPDATE_SETTINGS:
        //Mise à jour du paramétrage
        state[action.payload.typePortee] = action.payload.settings;
        break;
    case CLEAR_SETTINGS:
        //Reset :)
        state = {};
        break;
    }

    //Retour de l'état
    return state;
}
