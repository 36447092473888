import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Compte} from '@domain/admin/comptabilite/compte';
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {OptionsTransverses} from "@domain/admin/comptabilite/optionsTransverses";
import {Observable, Subject} from 'rxjs';

/**
 * Service de gestion de Comptabilité / Plan comptable
 */
@Injectable({
    providedIn: 'root'
})
export class ComptabilitePlanComptableService {
    /**
     * Indique si une page de détail d'un compte comptable a été ouverte.
     * Cela permet de savoir s'il faut afficher l'écran Plan comptable ou non.
     */
    isInDetailCompte: boolean;

    /** Indique s'il faut refresh la liste des comptes */
    private _refreshListeComptes$: Subject<void> = new Subject<void>();
    
    /** Observable de l'indicateur de refresh */
    public refreshListeComptesObservable$: Observable<void> = this._refreshListeComptes$.asObservable();

    /**
     * Constructeur
     *
     * @param http client HTTP
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Indique qu'il faut refresh la liste des comptes
     */
    refreshListeComptes() {
        this._refreshListeComptes$.next();
    }

    /**
     * Récupère un compte comptable
     *
     * @param numero Id du compte
     * @return result contenant le compte
     */
    getCompte(numero: string) {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/getCompte/${numero}`, null).pipe(first());
    }

    /**
     * Récupère les caractéristiques des zones prédéfinies 'attribut'
     *
     * @return result contenant les caractéristiques
     */
    getListeFieldParam() {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/getListeFieldParam`, null).pipe(first());
    }

    /**
     * Récupère la configuration des paramètres du plan comptable
     *
     * @return result contenant les infos de l'onglet paramètres
     */
    getOptionsTransverses() {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/consultParametres`, null).pipe(first());
    }

    /**
     * Récupère la liste de tous les comptes comptables
     *
     * @return result contenant la liste des comptes
     */
    getListeComptes() {
        return this.http.post<Compte[]>(`${environment.baseUrl}/controller/Compte/getAllComptesComptables`, null).pipe(first());
    }

    /**
     * Enregistrement d'un compte comptable
     *
     * @param compte Compte comptable à enregistrer
     * @return result de l'enregistrement
     */
    saveCompte(compte: Compte) {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/saveCompte`, compte).pipe(first());
    }

    /**
     * Suppression d'un compte comptable
     *
     * @param numero Numéro du compte à supprimer
     * @return result de la suppression
     */
    deleteCompte(numero: string) {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Compte/deleteCompte/${numero}`).pipe(first());
    }

    /**
     * Enregistrement des jours fériés des paramètres
     *
     * @param congesFeries Jours fériés à enregistrer
     */
    saveCongesFeries(congesFeries: string) {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/saveCongesFeries`, congesFeries).pipe(first());
    }

    /**
     * Enregistrement des paramètres de plan comptable
     *
     * @param config Configuration à enregistrer
     */
    saveParametres(config: OptionsTransverses) {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Compte/saveParametre`, config).pipe(first());
    }
}
