import {Component, OnInit, ViewChild} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PageHeaderItem } from "@share/component/page-header/page-header";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {NgForm} from "@angular/forms";
import {ConfigLogging} from "@domain/admin/logs/config-logging";
import {LogsService} from "@services/admin/logs/logs.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Sous-menu gestion-logs
 *
 * @author Guillaume TRAVOT
 * @date 01/02/2024
 */
@Component({
    host: {'data-test-id': 'gestion-logs'},
    templateUrl: './gestion-logs.component.html'
})
export class GestionLogsComponent implements OnInit {

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Onglet unique */
    listeTabItems: Array<PageHeaderItem> = [
        {
            code: null,
            libelle: this.translateService.instant('admin.maintenance.logs.onglets.configLogs'),
            url: 'Admin/Maintenance/GestionLogs'
        }
    ];

    /** Onglet courant */
    selectedItem: PageHeaderItem = this.listeTabItems[0];

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Config */
    configLoging: ConfigLogging;

    /**
     * Constructeur
     */
    constructor(protected translateService: TranslateService,private logsService: LogsService,private toastrService: ToastrService) {}

    ngOnInit(): void {
        //Définition de la liste des actions possibles
        this.listeActions.next([
            {
                type: TypeAction.PRIMARY,
                icone: 'nio icon-sauvegarde',
                libelle: 'global.actions.enregistrer',
                isDisabled: () => !this.form?.valid,
                doAction: () => this.saveConfig()
            },{
                type: TypeAction.SECONDARY,
                icone: 'material-icons',
                iconeName: 'mail',
                libelle: 'admin.maintenance.logs.fluxButton',
                doAction: () => this.sendTestFeed()
            }
        ]);

        //Chargement de la configuration
        this.logsService.loadConfig().subscribe(config => this.configLoging = config);
    }

    /**
     * Envoi d'un flux UDP de test au serveur de logs
     */
    sendTestFeed(): void {
        //Envoi d'un test
        this.logsService.sendTestFeed(this.configLoging).subscribe(result => {
            //S'il n'y a pas d'erreur
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('admin.maintenance.logs.fluxSucces'));
            } else {
                //Toast d'échec
                this.toastrService.error(this.translateService.instant('admin.maintenance.logs.fluxEchec'));
            }
        }, error => {
            //Toast d'échec
            this.toastrService.error(this.translateService.instant('admin.maintenance.logs.fluxEchec'));
        });
    }

    /**
     * Enregistrement de la configuration du serveur de centralisation des logs
     */
    saveConfig(): void {
        //Demande d'enregistrement
        this.logsService.saveConfig(this.configLoging).subscribe(result => {
            //S'il n'y a pas d'erreur
            if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                //Toast de succès
                this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
            } else {
                //En cas d'erreur, on affiche un message
                TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
            }
        });
    }
}
