<div class="m-l-20 m-r-20">
    <div *ngIf="xmlUploader.file == null" class="alert clickable d-flex flex-row align-items-center justify-content-center m-t-20" [xmlUploader]="xmlUploader" [settings]="settings" (onXmlsDrop)="onXmlsDrop($event)">
        <i class="zmdi zmdi-hc-3x zmdi-download m-r-20"></i>
        <strong [translate]="'admin.entreprise.description.licence.depot'"></strong>
    </div>

    <div class="row justify-content-md-center" *ngIf="xmlUploader.file != null">
        <div class="col-md-3">
            <div class="thumbnail">
                <div class="content">
                    <span class="material-icons size-icon-import-licence">upload_file</span>
                </div>
                <div class="caption">
                    <h4>{{ xmlUploader.file.name }}</h4>
                </div>
                <div class="actions-wrapper">
                    <span class="actions">
                        <button mat-icon-button color="warn" (click)="xmlUploader.removeItem()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button *ngIf='!xmlUploader.file?.isUploaded' mat-icon-button color="primary" (click)="uploadItem()">
                            <mat-icon>done</mat-icon>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
