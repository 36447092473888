<div class="lvi-content list-view-item-hover py-3">
    <div class="avatar">
        <span>{{ data.libelle | slice: 0:3 }}</span>
    </div>
    <div class="lvi-body" (click)="showChild()">
        <div class="title">
            <a class="label" (click)="onSelect()">{{ (data.numService ? data.numService : 'admin.entreprise.analytique.organigramme.numeroNull' | translate) + ' - ' + data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.code">
                <strong [translate]="'admin.entreprise.analytique.organigramme.code'"></strong>
                <span>{{ data.code }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.affectable'"></strong>
                <span>{{ (data.affectable ? 'global.oui' : 'global.non' ) | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.imputable'"></strong>
                <span>{{ (data.imputable ? 'global.oui' : 'global.non' ) | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.analytique.organigramme.actif'"></strong>
                <span>{{ (data.isActif ? 'global.oui' : 'global.non' ) | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button *ngIf="data.nbChildren > 0">
            <mat-icon *ngIf="isOuvert" color="primary" [matTooltip]="'od.voyage.replier' | translate" (click)="showChild()">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isOuvert" color="primary" [matTooltip]="'od.voyage.deplier' | translate" (click)="showChild()">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="isOuvert" [ngStyle]="{'padding': '10px 0px'}">
    <analytique-organigramme-item-sous-orga *ngFor="let sousOrga of listeSousOrga" [niveau]="1" [orga]="sousOrga"></analytique-organigramme-item-sous-orga>
</div>