import {Unite} from "../prestation/unite";
import {EtapeTournee} from "./etape-tournee";

/**
 * Modèle d'une tournée
 */
export class Tournee {
    id: number;
    idTournee: number;
    libelle: string;
    unite: Unite;
    heureDebut: string;
    heureFin: string;
    distance: number;
    order: number;

    listeEtapes: EtapeTournee[];
}