import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {DepartementItem} from "@domain/admin/bibliotheque/geographie/departementItem";
import {ActivatedRoute, Router} from "@angular/router";
import {GeographieService} from "@services/admin/geographie/geographie.service";

/**
 * Composant d'un département dans la liste des départements
 *
 * @author Angeline Ha
 * @date 10/11/2023
 */
@Component({
    host: {'data-test-id': 'departement-item'},
    selector: 'departement-item',
    templateUrl: './departement-item.component.html'
})
export class DepartementItemComponent extends ListViewItem<DepartementItem> {

    /**
     * Constructeur
     *
     * @param router Router
     * @param route  Route active
     * @param geographieService Service de géographie
     */
    constructor(private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService) {
        super();
    }

    /**
     * Affichage du détail d'un département
     */
    goToDetailDepartement() {
        //Indique au service géographie qu'on affiche un détail
        this.geographieService.isShowDetail = true;

        //Navigue vers le détail
        this.router.navigate(["Departement", this.data.idDepartement], {relativeTo: this.route})
    }

}
