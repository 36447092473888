import {AfterViewInit,Component,Input,OnDestroy,OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {OMPOdListItemComponent} from "@components/omp/detail/od/omp-od-list-item.component";
import {OMPService} from "@components/omp/omp.service";
import {AppState} from '@domain/appstate';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Omp} from "@domain/omp/omp";
import {SettingsODState} from '@domain/settings/settings';
import {TypeEntiteParamOT} from "@domain/typeentite/typeEntiteParam";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from '@ngrx/store';
import {TranslateService} from "@ngx-translate/core";
import * as settingsActions from "@reducers/settings";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ToastrService} from "ngx-toastr";
import {combineLatest,Subject,Subscription} from 'rxjs';
import {filter,first} from "rxjs/operators";

@Component({
    selector: 'omp-od-list',
    templateUrl: './omp-od-list.component.html'
})
export class OMPOdListComponent implements OnInit, AfterViewInit, OnDestroy {
    /** Mission permanente */
    @Input() omp: Omp;

    /** Paramètres du type entité de la mission permanente **/
    @Input() typeEntiteParam: TypeEntiteParamOT;

    /** Indicateur de modification */
    @Input() canModifier: boolean;

    /** Données */
    liste: ListView<any, OMPOdListItemComponent>;

    /** Paramètres OD */
    odSettings: SettingsODState;

    /** Souscription au paramétrage OD */
    odSettingsSouscription: Subscription;

    /** Indicateur pour savoir si la liste a déjà été chargée */
    odListLoaded$: Subject<boolean> = new Subject();

    /**
     * Constructeur
     */
    constructor(
        private ompService: OMPService,
        private translateService: TranslateService,
        private router: Router,
        private pleaseWaitService: PleaseWaitService,
        private toastrService: ToastrService,
        private store: Store<AppState>
    ) {
    }

    ngOnInit(): void {

        //Vérification de la possibilité de création d'après les paramètres du type entité
        this.ompService.isUserCanCreateForTypeEntite(this.typeEntiteParam, TypePortee.OD)
            .pipe(first())
            .subscribe(userCanCreateForTypeEntite => {
                    //Définition de la liste
                    this.liste = new ListView<any, OMPOdListItemComponent>({
                        uri: `/controller/OMPermanent/filtreOD/${this.omp.idOMPermanent}`,
                        title: this.translateService.instant('omp.od.liste.title'),
                        component: OMPOdListItemComponent,
                        listeFilters: [
                            {
                                //Ajout du filtre sur l'objet
                                clef: 'objet',
                                title: this.translateService.instant('od.liste.filtres.objet'),
                                isDefault: true,
                                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                            }, {
                                //Ajout du filtre sur le numéro
                                clef: 'idOd',
                                title: this.translateService.instant('od.liste.filtres.id'),
                                isDefault: true,
                                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                            }, {
                                //Ajout du filtre sur la date de départ
                                clef: 'depart_le',
                                title: this.translateService.instant('od.liste.filtres.depart_le'),
                                type: TypeFilter[TypeFilter.DATE]
                            }, {
                                //Ajout du filtre sur la date de retour
                                clef: 'retour_le',
                                title: this.translateService.instant('od.liste.filtres.retour_le'),
                                type: TypeFilter[TypeFilter.DATE]
                            }, {
                                //Ajout du filtre sur le matricule
                                clef: 'user.matricule',
                                title: this.translateService.instant('od.liste.filtres.matricule'),
                                isDefault: true,
                                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                            }, {
                                //Ajout du filtre sur la destination
                                clef: 'pays.libelle,*ville.libelle',
                                title: this.translateService.instant('od.liste.filtres.lieu'),
                                isDefault: true,
                                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                            }, {
                                clef: 'typeEntite.code',
                                title: this.translateService.instant('ndf.liste.filtres.type'),
                                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                            }, {
                                clef: 'statut.code',
                                title: this.translateService.instant('ndf.liste.filtres.statut'),
                                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                            }
                        ],
                        listeActions: [{
                            icon: 'add',
                            isVisible: () => {
                                return this.canModifier //Objet en édition
                                    && this.canCreate //Paramétrage WF
                                    && userCanCreateForTypeEntite; //Paramétrage type entité
                            },
                            onPress: () => this.createOD()
                        }],
                        extraOptions: {
                            canModifier: this.canModifier,
                            showOD: this.showOD.bind(this),
                            settings: this.odSettings
                        }
                    });
                    this.odListLoaded$.next(true);
                }
            );
    }

    /**
     * Apres initialisation de la vue
     */
    ngAfterViewInit(): void {

        //Selection du paramétrage OD
        this.odSettingsSouscription = combineLatest([
            this.odListLoaded$,
            this.store.select(state => state.settings?.[TypePortee.OD])
        ])
            .pipe(filter(([loaded, settings]) => loaded === true && !!settings))
            .subscribe(([_, settings]) => {
                this.odSettings = settings;

                //Ajout des valeurs aux filtres de liste OD
                if (!!settings && !!this.liste) {
                    let statutFilter = this.liste.listeFilters?.find(f => f.clef === 'statut.code');
                    if (!!statutFilter) {
                        statutFilter.listeValues = [...settings.filtresStatut];
                    }
                    let typeFilter = this.liste.listeFilters?.find(f => f.clef === 'typeEntite.code');
                    if (!!typeFilter) {
                        typeFilter.listeValues = [...settings.typeEntiteList];
                    }
                    this.liste.extraOptions.settings = {...settings};
                }
            });

        //Chargement du paramétrage OD
        if (!this.odSettings) {
            this.store.dispatch({
                type: settingsActions.LOAD_SETTINGS,
                payload: TypePortee.OD
            });
        }
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy(): void {
        //On n'oublie pas de se désabonner
        this.odSettingsSouscription?.unsubscribe();
    }

    /** Getter pour la création */
    get canCreate(): boolean {
        return this.omp?.getMapAction()?.actionsSupplementaires?.canOmpCreationOd?.possible === true;
    }

    /**
     * Création d'un OD
     */
    createOD(): void {
        let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

        //Handler sur une erreur lors de la création
        const errorHandler = (err) => {
            //Affichage d'un message d'erreur
            this.toastrService.error(this.translateService.instant("omp.od.liste.errorCreation"));

            //Fermeture de la popup d'attente
            stopWaitingHandler.call(this);
        };

        //Handler de fermeture de la popup d'attente
        const stopWaitingHandler = () => {
            //Fermeture de la popup d'attente
            matDialogRef.close();
        }

        //Affichage de la popup d'attente
        matDialogRef = this.pleaseWaitService.show();

        //Création de l'OD
        this.ompService.createOD(this.omp).subscribe({
            next: idOd => {
                if (idOd && idOd > 0) {
                    this.showOD({idOd: idOd})
                        .then(stopWaitingHandler)
                        .catch(stopWaitingHandler);
                } else {
                    //Gestion de l'erreur
                    errorHandler.call(this);
                }
            },
            error: errorHandler
        });
    }

    /**
     * Affichage d'un OD
     *
     * @param od OD à afficher
     */
    showOD(od: { idOd: number }): Promise<boolean> {
        return this.router.navigate(['OD', od.idOd]);
    }

}
