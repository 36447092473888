<h1 mat-dialog-title>
    <span [translate]="'admin.bibliotheque.reporting.infosReporting.filtres.popup.title'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input ngDefaultControl
                          id="role"
                          name="role"
                          [(ngModel)]="role"
                          customType="autocomplete"
                          lCol="2"
                          rCol="10"
                          [required]="true"
                          libelle="admin.bibliotheque.reporting.infosReporting.filtres.popup.role"
                          optionValue="idRole"
                          idName="idRole"
                          optionDisplay="libelle"
                          autocompleteType="reportRole"
                          [autocompleteFilter]="{ idReport: data.idReport }">
            </custom-input>
        </div>
        <div class="d-flex flex-row-reverse">
            <button mat-flat-button color="primary"
                    (click)="save()"
                    [disabled]="form.invalid">
                <span [translate]="'admin.bibliotheque.reporting.infosReporting.filtres.popup.ajouter'"></span>
            </button>
        </div>
    </form>
</div>
