<fieldset ngModelGroup="iata-generalites">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.iata.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="iata.libelle"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.iata.libelle"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="actif"
                                  name="actif"
                                  [ngModel]="iata.actif"
                                  [(customModel)]="iata.actif"
                                  customType="checkbox"
                                  libelle="admin.bibliotheque.geographie.iata.actif">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="ville"
                                  name="ville"
                                  [idName]="'libelle'"
                                  optionValue="libelle"
                                  (onChange)="onVilleChange();"
                                  [(ngModel)]="selectedVille"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  optionDisplay="libelle"
                                  [autocompleteFilter]="{listeTypes: ['VILLE']}"
                                  libelle="admin.bibliotheque.geographie.iata.ville"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="type"
                                  name="type"
                                  [(ngModel)]="iata.type"
                                  customType="select"
                                  optionValue="id"
                                  optionDisplay="libelle"
                                  libelle="admin.bibliotheque.geographie.iata.type"
                                  [selectOptions]="listeTypes"
                    ></custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="latitude"
                                  name="latitude"
                                  [(ngModel)]="iata.latitude"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.iata.latitude">
                    </custom-input>
                    <custom-input *ngIf="iata.type===TypeIata.AEROPORT"
                                  ngDefaultControl
                                  id="codeIata"
                                  name="codeIata"
                                  [(ngModel)]="iata.codeIata"
                                  customType="input"
                                  [required]="true"
                                  maxlength="3"
                                  libelle="admin.bibliotheque.geographie.iata.codeIata">
                    </custom-input>
                    <custom-input *ngIf="iata.type===TypeIata.GARE"
                                  ngDefaultControl
                                  id="codeGare"
                                  name="codeGare"
                                  [(ngModel)]="iata.codeGare"
                                  customType="input"
                                  [required]="true"
                                  maxlength="5"
                                  libelle="admin.bibliotheque.geographie.iata.codeGare">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="longitude"
                                  name="longitude"
                                  [(ngModel)]="iata.longitude"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.iata.longitude">
                    </custom-input>
                    <custom-input *ngIf="iata.type===TypeIata.GARE"
                                  ngDefaultControl
                                  id="codeKds"
                                  name="codeKds"
                                  [(ngModel)]="iata.codeKDS"
                                  maxlength="20"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.iata.codeKds">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
