import {NgModule} from '@angular/core';


import {CoreComponentsModule} from '../core-components.module';
import {PreviewAlerteComponent} from "./preview-alerte.component";

@NgModule({
	imports: [CoreComponentsModule],
    declarations: [PreviewAlerteComponent],
    exports: [PreviewAlerteComponent]
})
export class PreviewAlerteModule {}
