import {Component,Input} from '@angular/core';
import {TypePortee} from '@domain/workflow/workflow';
import {Mission} from "@domain/ndf/mission";

/**
 * Ligne de la liste des missions sélectionnables à la création d'une NDF
 */
@Component({
    host: {'data-test-id': 'ndf-creation-item'},
    templateUrl: './ndf-creation-item.component.html'
})
export class NDFCreationItemComponent {
    /** Déclaration pour accès dans le template */
    typePortee = TypePortee;
    
    /** Elément courant */
    @Input() data: Mission;
}