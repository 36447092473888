import {Component} from '@angular/core';
import {ReferentielDto} from "@domain/admin/entreprise/referentielDto";
import {MatDialogRef} from "@angular/material/dialog";

/**
 * Composant pour créer un nouveau référentiel
 */
@Component({
    host: {'data-test-id': 'create-new-referentiel'},
    selector: 'create-new-referentiel',
    templateUrl: './create-new-referentiel.component.html'
})
export class CreateNewReferentielComponent {
    /** Liste de tous les types de référentiel disponibles pour le type UTILISATEUR */
    listeTypeReferentiel = [{
        type: 'CATEGORIE',
        code: 'C',
        context: 'UTILISATEUR',
        libelle: 'Catégorie',
        listeUsages: ['UTILISATEUR']
    },{
        type: 'STATUT_COL',
        code: 'E',
        context: 'UTILISATEUR',
        libelle: 'Statut Collaborateur',
        listeUsages: ['UTILISATEUR']
    },{
        type: 'MOTS_CLEFS',
        code: 'M',
        context: 'UTILISATEUR',
        libelle: 'Mot-clef',
        listeUsages: ['PRESTATION','UTILISATEUR','CODE_PROJET','REFERENTIEL_1','REFERENTIEL_2']
    },{
        type: 'QUALIFICATION',
        code: 'Q',
        context: 'UTILISATEUR',
        libelle: 'Qualification',
        listeUsages: ['UTILISATEUR']
    },{
        type: 'TITRE',
        code: 'T',
        context: 'UTILISATEUR',
        libelle: 'Titre',
        listeUsages: ['UTILISATEUR']
    }];

    /** Variable pour savoir si le formulaire de création doit s'afficher ou non. Il s'affiche seulement quand un type a été sélectionné par l'utilisateur */
    showForm: boolean = false;

    /** Le nouveau référentiel à créer */
    referentiel: ReferentielDto = new ReferentielDto();

    /**
     * Constructeur
     *
     * @param dialogRef Référence de la boîte de dialogue associée à ce composant
     */
    constructor(public dialogRef: MatDialogRef<CreateNewReferentielComponent>) {}

    /**
     * Méthode appelée lors du changement du type sélectionné, pour savoir s'il faut afficher le formulaire ou non (car si aucun type n'est sélectionné alors il ne faut pas l'afficher)
     */
    changeType() {
        this.showForm = this.referentiel.type != "";
    }

    /**
     * Méthode appelée pour sauvegarder le nouveau référentiel créé
     */
    saveNewReferentiel() {
        //Fermeture de la boîte de dialogue en renvoyant le référentiel à créer
        this.dialogRef.close(this.referentiel);
    }
}