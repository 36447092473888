import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {Axe6DTO} from "@domain/admin/entreprise/analytique/axe6Dto";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage d'un référentiel Axe 6 dans la liste de tous les référentiels
 */
@Component({
    host: {'data-test-id': 'analytique-axe6-item'},
    selector: 'analytique-axe6-item',
    templateUrl: './analytique-axe6-item.component.html'
})
export class AnalytiqueAxe6ItemComponent extends ListViewItem<Axe6DTO> {
    /**
     * Constructeur
     *
     * @param translateService Service de traduction
     * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
     * @param analytiqueService Service de gestion des informations sur l'analytique
     * @param router Router de l'application
     */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private analytiqueService: AnalytiqueService,
        private router: Router
    ) {
        super();
    }

    /**
     * Méthode appelée lors de la sélection d'un référentiel Axe 6
     */
    onSelect() {
        //Navigation vers la page d'informations du référentiel
        this.router.navigate(['Admin/Entreprise/Analytique/Axe6/' + this.data.idAxe6]);
    }
}