import {Component} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {VehiculeTypeListItemComponent} from "@components/admin/entreprise/vehicules/vehicules-types/vehicule-type-list-item/vehicule-type-list-item.component";
import {TypeReferentiel} from "@domain/admin/referentiels/type-referentiel";
import {VehiculeTypeDetailComponent} from "@components/admin/entreprise/vehicules/vehicules-types/vehicule-type-detail/vehicule-type-detail.component";
import {TypeVehicule} from "@domain/admin/entreprise/vehicules/typeVehicule";
import {Result} from "@domain/common/http/result";

/**
 * Page admin de Véhicules / Type de véhicules
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'vehicules-types'},
    templateUrl: './vehicules-types.component.html'
})
export class VehiculesTypesComponent {
    /** Liste des types */
    listeTypes: ListView<TypeVehicule,VehiculeTypeListItemComponent>;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /**
     * Constructeur
     *
     * @param matDialog Boîte de dialogue
     * @param translateService Service des traductions
     */
    constructor(private matDialog: MatDialog,
                private translateService: TranslateService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Création de la liste des types
        this.listeTypes = new ListView<TypeVehicule,VehiculeTypeListItemComponent>({
            uri: '/controller/VehiculeAdmin/listeTypeVehicule',
            title: this.translateService.instant('admin.entreprise.vehicules.types.title'),
            component: VehiculeTypeListItemComponent,
            mapResult: (result: Result) => result?.data?.listeTypeVehicule,
            isSimple: true,
            extraOptions: {
                openType: this.openType.bind(this)
            }
        });

        //Définition de la liste des actions
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-ajouter',
            libelle: 'global.actions.creer',
            doAction: () => this.openType()
        }]);
    }

    /**
     * Ouverture d'un type
     *
     * @param type Type. null => création
     */
    openType(type?: TypeVehicule) {
        //Ouverture du type
        this.matDialog.open(VehiculeTypeDetailComponent, {
            width: '60%',
            data: {
                type: new TypeVehicule(type ?? {idReferentiel: 0, type: TypeReferentiel["TYPE_VEHICULE"]})
            }
        }).afterClosed().subscribe((isReload: boolean) => {
            //Vérification du besoin de recharger la liste
            if (isReload) {
                //Rechargement
                this.listeTypes.refresh();
            }
        });
    }

}
