import {Component, Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CompteurDepenseListItem} from "@domain/ik/compteur-depense";

/**
 * Item de la liste représentant le compteur de la dépense
 */
@Component({
    host: { 'data-test-id': 'compteur-depense-list-item' },
    selector: 'app-compteur-depense-list-item',
    templateUrl: './compteur-depense-list-item.component.html'
})
export class CompteurDepenseListItemComponent extends ListViewItem<CompteurDepenseListItem> {

    /** Objet courant */
    @Input() data: CompteurDepenseListItem;

    /** Options supplémentaires */
    @Input() extraOptions: {
        unite: string,
        dateExecutionTheorique: Date,
        dateExecutionReel: Date
    };
}
