import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

/**
 *  Directive de validation qui permet de controler que l'input n'est pas égale à 0
 */
@Directive({
	selector: '[notZero]',
	providers: [{ provide: NG_VALIDATORS, useExisting: NotZeroDirective, multi: true }]
})
export class NotZeroDirective implements Validator {
	@Input() notZero: boolean = true;

	validate(control: AbstractControl): ValidationErrors | null {
		return notZeroValidator(this.notZero)(control);
	}
}

/** Fonction de validation qui permet de contrôler que l'input n'est pas égale à 0 */
export function notZeroValidator(isActive: boolean): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		return isActive && control.value && control.value == 0
			? { notZero: true }
			: null;
	};
}