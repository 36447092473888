import {Component,Inject,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {TypeEntiteParam,TypePorteeParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {FiltreDialogItemComponent} from "@components/admin/parametre/demat/piece-jointe/filtre-param/filtre-dialog/filtre-dialog-item/filtre-dialog-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {DematService} from "@services/admin/parametre/demat/demat.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Composant d'affichage de la popin des filtres
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Component({
    host: {'data-test-id': 'filtre-dialog'},
    selector: 'filtre-dialog-component',
    templateUrl: './filtre-dialog.component.html'
})

export class FiltreDialogComponent implements OnInit {
    typeEntiteParamListView : ListView<TypeEntiteParam, FiltreDialogItemComponent>;

    //Liste des types entités sélectionnés
    listeIdTypesEntiteToSave: Array<number> = new Array<number>();

    //Liste des types entités désélectionnés
    listeIdTypesEntiteToDelete: Array<number> = new Array<number>();

    constructor(
        private translateService: TranslateService,
        private dialogRef: MatDialogRef<FiltreDialogComponent>,
        private toastrService: ToastrService,
        private dematService: DematService,
        @Inject(MAT_DIALOG_DATA) public data: {
            idPortee: TypePorteeParam,
            listeIdInitiallySelected: Array<number>
        }
    ) {
    }

    /**
     * Hook Initialisation
     */
    ngOnInit() {
        //Création de la listeView
        this.typeEntiteParamListView = new ListView<TypeEntiteParam, FiltreDialogItemComponent>({
            component: FiltreDialogItemComponent,
            uri: `/controller/PieceJointe/listeTypeEntiteByPortee/${this.data.idPortee}`,
            isLocal: true,
            isFilter: true,
            defaultOrder: 'typeEntite.idTypeEntite',
            listeFilters: [
                {
                    //Filtre de type select sur le type
                    clef: 'libelle',
                    title: this.translateService.instant('admin.parametres.demat.pieceJointes.filtre.libelle'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                },{
                    clef: 'code',
                    title: this.translateService.instant('admin.parametres.demat.pieceJointes.filtre.code'),
                    isDefault: true,
                    typeComparaison: TypeComparaison[TypeComparaison.LIKE]
                }
            ]
        });

        this.typeEntiteParamListView.columns = [
            {key: 'libelle',title: 'admin.parametres.demat.pieceJointes.filtre.libelle'},
            {key: 'code',title: 'admin.parametres.demat.pieceJointes.filtre.code'},
        ]
    }

    /**
     * Handler d'enregistrement
     */
    onEnregistrer() {
        const listeTypeEntiteParam = this.typeEntiteParamListView.data.listeResultats;
        listeTypeEntiteParam.forEach(typeEntiteParam => {
                //Liste des éléments à enregistrer
                if (typeEntiteParam.isSelected && !this.data.listeIdInitiallySelected.includes(typeEntiteParam.idTypeEntite)) {
                    this.listeIdTypesEntiteToSave.push(typeEntiteParam.idTypeEntite)
                }

                //Liste des éléments à supprimer
                if (!typeEntiteParam.isSelected && this.data.listeIdInitiallySelected.includes(typeEntiteParam.idTypeEntite)) {
                    this.listeIdTypesEntiteToDelete.push(typeEntiteParam.idTypeEntite);
                }
            }
        );

        this.dematService.saveTypeEntiteParam(this.data.idPortee,this.listeIdTypesEntiteToSave,this.listeIdTypesEntiteToDelete)
            .subscribe((result: Result) => {
                if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                    //Fermeture de l'écran
                    this.dialogRef.close({refresh: true});
                } else {
                    //Message d'erreur
                    this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                }
            }
        );
    }
}