/**
 * Modèle représentant une action
 */
export interface FloatingButtonAction {
    /** Données **/
    type?: TypeAction,
    code?: string,
    icone: string,
    //Utilisé pour les icônes de material par exemple
    iconeName?: string,
    libelle: string,
    message?: () => string,
    doAction: () => void,
    isVisible?: () => boolean,
    isDisabled?: () => boolean,
    tooltip?: () => string,
}

/**
 * Type d'action
 */
export enum TypeAction {
    /** Enumération **/
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY'
}