<div *ngIf="config" class="content">
    <form #form="ngForm">
        <fieldset>
            <!-- Gestion des comptes -->
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.comptabilite.planComptable.parametres.comptes.title'"></span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          name="tiersCollab"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.tiersCollaborateur"
                                          [(ngModel)]="config.compteTiersCollab"
                                          customType="select"
                                          [selectOptions]="listeCompteTiers"
                                          optionValue="numero"
                                          optionDisplay="libelle"></custom-input>
                            <custom-input ngDefaultControl
                                          name="taxes"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.taxe"
                                          [(ngModel)]="config.compteTaxes"
                                          customType="select"
                                          [selectOptions]="listeCompteTaxe"
                                          optionValue="numero"
                                          optionDisplay="libelle"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          name="tiersFournisseurs"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.tiersFournisseurs"
                                          [(ngModel)]="config.compteTiersFrn"
                                          customType="select"
                                          [selectOptions]="listeCompteTiers"
                                          optionValue="numero"
                                          optionDisplay="libelle"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          name="speAvance"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.avanceSur"
                                          [(ngModel)]="config.typeSpecifCompte"
                                          customType="select"
                                          [selectOptions]="listeSpecificationAvances"
                                          optionValue="id"
                                          optionDisplay="libelle"></custom-input>
                            <custom-input ngDefaultControl
                                          name="gains"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.gain"
                                          [(ngModel)]="config.gainChange"
                                          customType="select"
                                          [selectOptions]="listeCompteGain"
                                          optionValue="numero"
                                          optionDisplay="libelle"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          name="compteAvances"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.compteDAvances"
                                          [(ngModel)]="config.compteAvances"
                                          customType="select"
                                          [selectOptions]="listeCompteTiers"
                                          optionValue="numero"
                                          optionDisplay="libelle"></custom-input>
                            <custom-input ngDefaultControl
                                          name="pertes"
                                          libelle="admin.comptabilite.planComptable.parametres.comptes.perte"
                                          [(ngModel)]="config.perteChange"
                                          customType="select"
                                          [selectOptions]="listeComptePerte"
                                          optionValue="numero"
                                          optionDisplay="libelle"></custom-input>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>

            <!-- Gestion des lots comptables -->
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.comptabilite.planComptable.parametres.lots.title'"></span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset>
                        <div class="row" *ngIf="config.typeDateLotComptable">
                            <custom-input ngDefaultControl
                                          name="dateLot"
                                          libelle="admin.comptabilite.planComptable.parametres.lots.date"
                                          [(ngModel)]="config.typeDateLotComptable"
                                          customType="select"
                                          [selectOptions]="listeDateLotComptable"
                                          optionValue="type"
                                          optionDisplay="libelle"></custom-input>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>

            <!-- Gestion des jours non ouvrés -->
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.comptabilite.planComptable.parametres.jours.title'"></span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="paques"
                                          name="paques"
                                          [ngModel]="config.paques"
                                          [(customModel)]="config.paques"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.paques"></custom-input>
                            <custom-input ngDefaultControl
                                          id="ascencion"
                                          name="ascencion"
                                          [ngModel]="config.ascension"
                                          [(customModel)]="config.ascension"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.ascencion"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="pentecote"
                                          name="pentecote"
                                          [ngModel]="config.pentecote"
                                          [(customModel)]="config.pentecote"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.pentecote"></custom-input>
                            <label class="col-md-2">
                                <span [translate]="'admin.comptabilite.planComptable.parametres.jours.fixes'"></span>
                            </label>
                            <button mat-button color="primary" (click)="openDatesFixes()" class="mb-2">
                                <span [translate]="'admin.comptabilite.planComptable.parametres.jours.joursFeries'" [translateParams]="{nbJoursFeries: nombreJoursFeries}"></span>
                                <mat-icon color="primary">search</mat-icon>
                            </button>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="lundi"
                                          name="lundi"
                                          [ngModel]="listeJours[0].isConge"
                                          [(customModel)]="listeJours[0].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.lundi"></custom-input>
                            <custom-input ngDefaultControl
                                          id="mardi"
                                          name="mardi"
                                          [ngModel]="listeJours[1].isConge"
                                          [(customModel)]="listeJours[1].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.mardi"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="mercredi"
                                          name="mercredi"
                                          [ngModel]="listeJours[2].isConge"
                                          [(customModel)]="listeJours[2].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.mercredi"></custom-input>
                            <custom-input ngDefaultControl
                                          id="jeudi"
                                          name="jeudi"
                                          [ngModel]="listeJours[3].isConge"
                                          [(customModel)]="listeJours[3].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.jeudi"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="vendredi"
                                          name="vendredi"
                                          [ngModel]="listeJours[4].isConge"
                                          [(customModel)]="listeJours[4].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.vendredi"></custom-input>
                            <custom-input ngDefaultControl
                                          id="samedi"
                                          name="samedi"
                                          [ngModel]="listeJours[5].isConge"
                                          [(customModel)]="listeJours[5].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.samedi"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="dimanche"
                                          name="dimanche"
                                          [ngModel]="listeJours[6].isConge"
                                          [(customModel)]="listeJours[6].isConge"
                                          customType="checkbox"
                                          libelle="admin.comptabilite.planComptable.parametres.jours.dimanche"></custom-input>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>
        </fieldset>
    </form>
    <floating-button [listeActions]="listeActions" [isPending]="isPending"></floating-button>
</div>
<please-wait class="content" *ngIf="!config"></please-wait>