import { Component } from "@angular/core";

/**
 * Onglet notification balises
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'notification-balises'},
    templateUrl: './notification-balises.component.html'
})
export class NotificationBalisesComponent {}
