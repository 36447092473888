import {Component,Input,OnChanges,SimpleChanges} from '@angular/core';
import {DisponibiliteFCType,DisponibiliteNDFType,PieceJointeParam,SelectOptionType,TypePorteeParam} from "@domain/admin/parametre/demat/piece-jointe-param";
import {TranslateService} from "@ngx-translate/core";
import {ParamPorteeService} from "@components/admin/parametre/demat/piece-jointe/param-portee/param-portee.service";
import {ControlContainer,NgForm} from '@angular/forms';

/**
 * Composant d'affichage de la page des paramètres de la portée.
 *
 * @author Lionel GIMENEZ
 * @date 18/09/2023
 *
 */
@Component({
	host: {'data-test-id': 'param-portee'},
	selector: 'param-portee',
	templateUrl: './param-portee.component.html',
	providers: [ParamPorteeService],
	viewProviders: [{provide: ControlContainer,useExisting: NgForm}]

})
export class ParamPorteeComponent implements OnChanges {
	/** Modèle du composant */
	@Input() pieceJointeParam: PieceJointeParam;

	/** Index courant */
	@Input() index: number;

	/** Option de la liste sélection type */
	typeOption: SelectOptionType;

	/** Option de la liste sélection libelle */
	libelleOption: SelectOptionType;

	/** Liste des types ou libellés pour le select actif */
	listeOptionActif: Array<any> = [
		{
			type: SelectOptionType.DESACTIVE,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.selectionOption.desactive")
		},
		{
			type: SelectOptionType.ACTIF,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.selectionOption.actif")
		},
		{
			type: SelectOptionType.ACTIF_OBLIGATOIRE,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.selectionOption.actifObligatoire")
		}
	];

	/** Liste des types ou libellés pour le select liés à la ndf */
	listeOptionsNdf: Array<any> = [
		{
			type: DisponibiliteNDFType.NOTE_ET_FRAIS,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.disponibleFraisOption.noteEtFrais")
		},
		{
			type: DisponibiliteNDFType.NOTE,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.disponibleFraisOption.note")
		},
		{
			type: DisponibiliteNDFType.FRAIS,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.disponibleFraisOption.frais")
		}
	];

	/** Liste des types ou libellés pour le select disponible */
	listeOptionsFacture: Array<any> = [
		{
			type: DisponibiliteFCType.RELEVE_ET_FACTURE,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.disponibleFactureOption.releveEtFacture")
		},
		{
			type: DisponibiliteFCType.RELEVE,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.disponibleFactureOption.releve")
		},
		{
			type: DisponibiliteFCType.FACTURE,
			libelle: this.translateService.instant("admin.parametres.demat.pieceJointes.param.disponibleFactureOption.facture")
		}
	];

	/** Taille max de PJ */
	readonly tailleMax: number = 15360;

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param paramPorteeService le service de gestion des paramètres des portées
	 */
	constructor(
		private translateService: TranslateService,
		private paramPorteeService: ParamPorteeService
	) {
	}

	/**
	 * Hook onChanges
	 *
	 * @param changes les changements
	 */
	ngOnChanges(changes: SimpleChanges) {
		//En cas de modification de pieceJointeParam
		if (changes.pieceJointeParam.currentValue != null) {
			const pieceJointeParam: PieceJointeParam = changes.pieceJointeParam.currentValue;

			//On récupère l'option pour le type et le libellé
			this.typeOption = this.paramPorteeService.getOption(pieceJointeParam.type,pieceJointeParam.typeObligatoire);
			this.libelleOption = this.paramPorteeService.getOption(pieceJointeParam.libelle,pieceJointeParam.libelleObligatoire);
		}
	}

	/**
	 * Handler qui intercepte le changement actif / inactif
	 */
	onActifChange(): void {
		//Si on désactive les PJ
		if (!this.pieceJointeParam.actif) {
			//Désactivation du type
			this.typeOption = SelectOptionType.DESACTIVE;
			this.onTypeChange();

			//Désactivation du libellé
			this.libelleOption = SelectOptionType.DESACTIVE;
			this.onLibelleChange();
		}
	}

	/**
	 * Handler qui intercepte le changement de type
	 */
	onTypeChange(): void {
		this.paramPorteeService.setTypePieceJointeBySelection(this.pieceJointeParam,this.typeOption);
	}

	/**
	 * Handler qui intercepte le changement de libellé
	 */
	onLibelleChange(): void {
		this.paramPorteeService.setLibellePieceJointeBySelection(this.pieceJointeParam,this.libelleOption);
	}

	/**
	 * Retourne le libellé du champ taille
	 */
	getTailleLibelle(): string {
		return this.translateService.instant("admin.parametres.demat.pieceJointes.param.taille") + " (" + this.tailleMax + ")";
	}

	/**
	 * Condition d'affichage du champ disponible
	 */
	isShowDisponible(): boolean {
		//Uniquement NDF et facture
		return this.pieceJointeParam.idPortee === TypePorteeParam.NF
			|| this.pieceJointeParam.idPortee === TypePorteeParam.FC
	}

	/**
	 * Retourne les options selon la portée courante
	 *
	 * @returns l'option de select correspondante
	 */
	getDisponibleOption() {
		return this.pieceJointeParam.idPortee === TypePorteeParam.NF
			? this.listeOptionsNdf
			: this.listeOptionsFacture
	}
}

