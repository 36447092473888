import {Component,OnInit} from '@angular/core';
import {ListView} from "../../../../domain/common/list-view";
import {PreferenceAnalytique} from "../../../../domain/profil/preferenceAnalytique";
import {ProfilPreferenceAnalytiquesListItemComponent} from "./profil-preference-analytiques-list-item.component";
import {ActivatedRoute,Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Session} from "../../../../domain/security/session";
import {first} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../domain/appstate";
import {ProfilPreferenceAnalytiquesAddComponent} from "./profil-preference-analytiques-add.component";
import {MatDialog} from "@angular/material/dialog";
import {User} from "../../../../domain/user/user";
import * as settingsActions from "../../../../reducers/settings";
import {SettingsGlobalState} from "../../../../domain/settings/settings";
import {forkJoin} from "rxjs";
import {Page} from "@domain/common/http/list-result";

/**
 * Composant de gestion des préférences analytiques pour le profil
 */
@Component({
    selector: 'profil-preferences-analytiques',
    templateUrl: './profil-preferences-analytiques.component.html',
})
export class ProfilPreferencesAnalytiquesComponent implements OnInit {

    /** Liste des appareils connectés */
    liste: ListView<PreferenceAnalytique, ProfilPreferenceAnalytiquesListItemComponent>;

    /** Utilisateur */
    user: User;

    /** Paramétrage */
    settings: SettingsGlobalState;

    constructor(
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private router: Router,
        private store: Store<AppState>,
        private matDialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        //Chargement du paramétrage
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: ['Global']
        });

        //Chargement des données utiles depuis le store
        forkJoin([
            this.store.select(state => state.settings?.Global).pipe(first(val => !!val)),
            this.store.select<Session>(s => s.session).pipe(first(val => !!val))
        ]).subscribe(params => {
            //Récupération des settings "global"
            this.settings = params[0];

            //Utilisateur connecté
            this.user = params[1].user;

            //Définition de la liste
            this.liste = new ListView<PreferenceAnalytique,ProfilPreferenceAnalytiquesListItemComponent>({
                uri: `/controller/ProfilUser/Analytique/getListePrefAnalytiques`,
                title: this.translateService.instant('profilUser.prefAnalytique.listTitle'),
                component: ProfilPreferenceAnalytiquesListItemComponent,
                isSimple: true,
                isFilter: false,
                mapResult: (result: Page<PreferenceAnalytique>) => {
                    return result.listeResultats
                },
                extraOptions: {
                    settings: this.settings
                },
                listeActions: [{
                    icon: "add",
                    onPress: () => this.addPrefAnalytique(),
                    isVisible: () => this.settings.analytiqueUserManagement
                }]
            });
        })
    }

    /**
     * Retour arrière
     */
    onGoBack() {
        //Navigation vers la liste
        this.router.navigate(['Profil']);
    }

    /**
     * Rafraichissement de la liste des préférences analytiques
     */
    private refreshListe() {
        this.liste.refresh();
    }

    /**
     * Ajout de préférences analytiques
     */
    private addPrefAnalytique() {
        this.matDialog.open(ProfilPreferenceAnalytiquesAddComponent, {
            data: {
                idUser: this.user.idUser,
                settings: this.settings
            },
            width: '80%'
        }).afterClosed().subscribe({
            next: (refresh: boolean) => {
                if (refresh) {
                    this.refreshListe()
                }
            }
        });
    }
}
