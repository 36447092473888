<div class="with-floating-button">
    <div class="content" *ngIf="compte">
        <page-header [title]="isCreation ? ('admin.comptabilite.planComptable.compteComptable.title.creation' | translate) : compte.libelle" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm">
                <compte-comptable-generalites [compte]="compte" [fieldContainer]="fieldContainer"></compte-comptable-generalites>
            </form>
        </div>
        <floating-button [listeActions]="listeActions" [isPending]="isPending"></floating-button>
    </div>
</div>