<div class="with-floating-button">
    <div class="content">
        <page-header [hidden]="isInDetail" [title]="'admin.comptabilite.planComptable.title' | translate" [listeItems]="listeTabItems" (onSelectedItemChange)="onSelectedItemChange($event)"></page-header>

        <div [hidden]="isInDetail || selectedItem?.code !== Onglets.COMPTES_COMPTABLES">
            <comptes-comptables></comptes-comptables>
        </div>

        <div [hidden]="isInDetail || selectedItem?.code !== Onglets.PARAMETRES">
            <plan-comptable-parametres></plan-comptable-parametres>
        </div>

        <router-outlet></router-outlet>

    </div>
</div>