<h1 mat-dialog-title>
    <span [translate]="'profilUser.adresses.add.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content class="py-1">
    <form autocomplete="off" #addAdresseForm="ngForm">
        <fieldset>
            <div class="row">
                <custom-input [(ngModel)]="data.adresse.type"
                    [selectOptions]="listeTypeAdresse"
                    customType="select"
                    libelle="profilUser.adresses.add.type"
                    name="typeAdresse"
                    ngDefaultControl
                    optionDisplay="libelle"
                    optionValue="type"
                    required>
                </custom-input>
                <label [translate]="'profilUser.infoContact.add.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox color="primary" [(ngModel)]="data.adresse.actif" name="actif"></mat-checkbox>
                </div>
            </div>
            <div class="row d-flex flex-row align-items-center" [ngClass]="{ 'has-error': adresse.invalid }">
                <label [for]="'lieuProfil'" class="col-md-2 required"><span [translate]="'profilUser.adresses.add.adresse'"></span></label>
                <div class="col-md-4">
                    <lieu #adresse="ngModel"
                          ngDefaultControl
                          name="lieuProfil"
                          [disabled]="false"
                          [required]="true"
                          [(ngModel)]="localisation"
                          [(lieu)]="localisation"
                          [typeEntiteParam]="typeEntiteParam"
                          [idPortee]="data.idPortee"
                          [idUser]="data.idUser"
                          [typeLieu]="data.typeLieu"
                          (lieuChange)="setLocalisation($event)">
                    </lieu>
                </div>
                <mat-icon class="warning ml-1" *ngIf="!localisation?.rue" matSuffix [nioTooltip]="'profilUser.adresses.add.adresseIncomplete' | translate" [nioTooltipPosition]="'right'">warning_amber</mat-icon>
            </div>
        </fieldset>
    </form>
</div>
<div align="end" mat-dialog-actions>
    <button (click)="deleteAdresse()" *ngIf="this.data.adresse.idAdresse != 0" [disabled]="isSaving || isDeleting" color="warn" mat-stroked-button>
        <span *ngIf="!isDeleting" translate>global.actions.supprimer</span>
        <mat-spinner *ngIf="isDeleting" class="m-5" diameter="28"></mat-spinner>
    </button>
    <button (click)="save()" [disabled]="isSaving || isDeleting || addAdresseForm.invalid" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="data.adresse.idAdresse == 0 ?'global.actions.ajouter' : 'global.actions.enregistrer'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
