import {NgModule} from '@angular/core';

import {AutocompleteComponent} from './autocomplete.component';
import {AutocompleteSearchComponent} from './autocomplete-search.component';
import {AutocompleteSearchItemComponent} from './autocomplete-search-item.component';
import {AutocompleteLineComponent} from './autocomplete-line.component';
import {AutocompleteLineItemComponent} from './autocomplete-line-item.component';
import {AutocompleteService} from './autocomplete.service';
import {CoreComponentsModule} from '../core-components.module';
import {ListViewModule} from '../list-view/list-view.module';

import {GeographieSearchItemComponent} from './options/geographie.search-item';
import {GeographieLineItemComponent} from './options/geographie.line-item';
import {ParticipantLineItemComponent} from "./options/participant-line-item.component";
import {AutocompleteValidatorDirective} from "./autocomplete.validator";
import {CustomInputValidatorDirective} from "@share/component/custom-input/custom-input.component";
import {OdLineItemComponent} from "@share/component/autocomplete/options/od-line-item.component";
import {PrestationFraisLineItemComponent} from "@share/component/autocomplete/options/prestation-frais-line-item.component";
import {PrestationFraisSearchItemComponent} from "@share/component/autocomplete/options/prestation-frais-search-item";
import {EnveloppeSearchItemComponent} from "@share/component/autocomplete/options/enveloppe-search-item";
import {EnveloppeLineItemComponent} from "@share/component/autocomplete/options/enveloppe-line-item";
import {MontantPipe} from "@share/pipe/montant";
import {ImportFactureCollabLineItem} from "@share/component/autocomplete/options/import-facture-collab/line-item/import-facture-collab.line-item";
import {ImportFactureCollabSearchItem} from "@share/component/autocomplete/options/import-facture-collab/search-item/import-facture-collab.search-item";
import {ImportFactureMissionSearchItem} from "@share/component/autocomplete/options/import-facture-mission/search-item/import-facture-mission.search-item";
import {TypeChargeLineItem} from "@share/component/autocomplete/options/type-charge/line-item/type-charge.line-item";
import {ImportFactureMissionLineItem} from "@share/component/autocomplete/options/import-facture-mission/line-item/import-facture-mission.line-item";
import {DeviseLineItemComponent} from "@share/component/autocomplete/options/devise.line-item";
import {DeviseSearchItemComponent} from "@share/component/autocomplete/options/devise.search-item";
import {StatutLineItemComponent} from "@share/component/autocomplete/options/statut-line-item.component";
import {StatutSearchItemComponent} from "@share/component/autocomplete/options/statut-search-item.component";
import {RoleSearchItemComponent} from "@share/component/autocomplete/options/role-search-item.component";
import {AdresseSearchItemComponent} from "@share/component/autocomplete/options/adresse.search-item";
import {AdresseLineItemComponent} from "@share/component/autocomplete/options/adresse.line-item";

@NgModule({
	imports: [
		CoreComponentsModule,
		ListViewModule
	],
	declarations: [
		AutocompleteComponent,
		AutocompleteSearchComponent,
		AutocompleteSearchItemComponent,
		AutocompleteLineComponent,
		AutocompleteLineItemComponent,
		ParticipantLineItemComponent,
		OdLineItemComponent,
		GeographieSearchItemComponent,
		GeographieLineItemComponent,
		AutocompleteValidatorDirective,
		CustomInputValidatorDirective,
		PrestationFraisLineItemComponent,
		PrestationFraisSearchItemComponent,
		ImportFactureCollabLineItem,
		ImportFactureCollabSearchItem,
		ImportFactureMissionLineItem,
		ImportFactureMissionSearchItem,
		TypeChargeLineItem,
        DeviseLineItemComponent,
        DeviseSearchItemComponent,
		StatutLineItemComponent,
		StatutSearchItemComponent,
		EnveloppeLineItemComponent,
		EnveloppeSearchItemComponent,
		RoleSearchItemComponent,
		AdresseSearchItemComponent,
		AdresseLineItemComponent
	],
	exports: [
		AutocompleteComponent,
		AutocompleteValidatorDirective,
		CustomInputValidatorDirective
	],
	entryComponents: [AutocompleteSearchItemComponent],
	providers: [
        AutocompleteService,
        MontantPipe]
})
export class AutocompleteModule {
}
