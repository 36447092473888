<list-view [liste]="liste" *ngIf="remoteActions && liste"></list-view>
<div *ngIf="!remoteActions" class="with-floating-button">
    <div class="content">
        <list-view [liste]="liste" *ngIf="liste"></list-view>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>

<!-- Template pour l'affichage de la popin de détail de l'alerte sur les montants -->
<ng-template #tplAlerteMontant let-ctx="ctx">
    <form>
        <!-- Titre/Message (transverse aux 2 alertes) -->
        <div class="row">
            <div class="col pb-1">{{ 'facture.liste.alerte.' + ctx.type + '.message' | translate }}</div>
        </div>
        <!-- Détail des montants de l'alerte de type 'factureMontantControle' -->
        <ng-container *ngIf="ctx.type === TypeAlerteEcartMontant.FACTURE_MONTANT_CONTROLE">
            <div class="row">
                <div class="col-md-auto">
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureMontantControle.montantFacture' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montant | montant:ctx.objet.devise }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureMontantControle.montantControle' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montantControle | montant:ctx.objet.devise }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- Détail des montants de l'alerte de type 'factureEcartDevis' -->
        <ng-container *ngIf="ctx.type === TypeAlerteEcartMontant.FACTURE_ECART_DEVIS">
            <div class="row">
                <div class="col-md-auto">
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureEcartDevis.montantFactures' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montantTotal | montant:ctx.objet.devise }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-auto">{{ 'facture.liste.alerte.factureEcartDevis.montantDevis' | translate }}</div>
                        <div class="col-md-auto text-right flex-grow-1">{{ ctx.objet.montantDevis | montant:ctx.objet.devise }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</ng-template>