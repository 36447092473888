import {Component} from "@angular/core";
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {FiltreAxeAnalytiqueDTO} from "@domain/admin/entreprise/analytique/FiltreAxeAnalytiqueDto";

/**
 * Composant d'affichage d'un filtre dans la liste des filtres disponibles
 */
@Component({
    host: {'data-test-id': 'analytique-filtre-item'},
    selector: 'analytique-filtre-item',
    templateUrl: './analytique-filtre-item.component.html'
})
export class AnalytiqueFiltreItemComponent extends ListViewItem<FiltreAxeAnalytiqueDTO> {

    /**
     * Méthode appelée lorsque l'utilisateur sélectionne le filtre affiché
     */
    onSelect() {
        //Emission de l'événement vers le composant parent pour indiquer que ce filtre a été choisi
        this.extraOptions.selectEvent(this.data);
    }


}