import {Component,OnInit} from '@angular/core';
import {PieceJointeExtension} from "@domain/admin/parametre/demat/piece-jointe-extension";
import {ListView} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {ExtensionListeItemComponent} from "@components/admin/parametre/demat/extension/extension-liste-item/extension-liste-item.component";
import {MatDialog} from "@angular/material/dialog";
import {ExtensionAddComponent} from "@components/admin/parametre/demat/extension/extension-add/extension-add.component";
import {Result} from "@domain/common/http/result";

/**
 * Composant d'affichage de l'onglet Extension de la page Dématérialisation
 *
 * @author Lionel Gimenez
 * @date 20/09/23
 */
@Component({
    selector: 'extension',
    templateUrl: './extension.component.html',
    host: {'data-test-id': 'extension'},
})
export class ExtensionComponent implements OnInit {
    //Liste du composant
    liste: ListView<PieceJointeExtension, ExtensionListeItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        private translateService: TranslateService,
        private matDialog: MatDialog
    ) {
    }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
        //Création de la liste des devises
        this.liste = new ListView<PieceJointeExtension, ExtensionListeItemComponent>({
            uri: `/controller/PieceJointe/showListeExtension`,
            title: this.translateService.instant('admin.parametres.demat.extensions.titleAutorise'),
            component: ExtensionListeItemComponent,
            isSimple: true,
            mapResult: (result: Result) => {
                return result.data?.extensions;
            },
            listeActions: [{
                icon: 'add',
                isVisible: () => true,
                onPress: () => this.showCreateExtensionDialog()
            }],
        });
    }

    /**
     * Affiche le popin pour la création d'une extension
     */
    showCreateExtensionDialog(): void {
        //Affichage de la popin
        this.matDialog.open(ExtensionAddComponent, null).afterClosed().subscribe({
            next: (dialogResult: {refresh: boolean}) => {
                //Si on doit rafraichir la liste
                if (dialogResult?.refresh) {
                    //Rafraichissement de la liste
                    this.liste.refresh();
                }
            }
        });
    }
}