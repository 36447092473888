import {Rule} from "@domain/rule/rule";
import {RuleFilter} from "@domain/rule/rule-filter";

/**
 * Modèle du détail d'une règle
 */
export class RuleDetail {
	/** Identifiant */
	idDetail: number;
	/** Rule mère */
	ruleGroupe: Rule;
	/** Position */
	position: number;
	/** Rule intégrée */
	rule: Rule;
	/** RuleFilter initial */
	filter: RuleFilter;
	/** RuleFilter cible (utilisé pour les comparaisons champ / champ) */
	filterCible: RuleFilter;
}