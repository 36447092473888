/**
 * Énumération des différents types de distance (unités)
 *
 * @author Laurent Convert
 * @date 25/04/2023
 */
export enum NatureOcr {

	NON_DEFINI = 'NON_DEFINI',
	REPAS = 'REPAS',
	PARKING = 'PARKING',
	TRANSPORT = 'TRANSPORT',
	HOTEL = 'HOTEL',
	CARBURANT = 'CARBURANT',
	FOURNITURE = 'FOURNITURE',
	PEAGE = 'PEAGE'

}
