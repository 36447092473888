import {ListItem} from '../common/list-view/list-item';
import {OrgaDTO} from "../od/od";
import {WFCreationPossible} from '../workflow/wf-creation-possible';
import {UserRoles} from "@domain/user/user-roles";
import {UserPipeTransform} from "@share/pipe/user.pipe";
import {LienRoleUser} from "@domain/workflow/lienRoleUser";
import {TranslateService} from "@ngx-translate/core";
import {DroitAdmin} from "@core/security/droit-admin";

/**
 * Modèle représentant un utilisateur
 */
export class User implements ListItem {
	/* Données */
	idUser: number;
	login?: string;
	canLogin?:boolean;
	nomAcces: string;
	passDate: Date;
	passExpirationDate?: Date;
	genre: TypeGenre;
	nom: string;
	prenom: string;
	matricule: string;
	password?: string;
	password2?: string;
	societe?: any;
	service?: any;
	actif: boolean;
	dateIn: Date;
	dateOut: Date;
	fonction?: TypeProfil;
	fonctions?: string;
	fonctionCollaborateur?: boolean;
	fonctionAssistant?: boolean;
	fonctionResponsable?: boolean;
	fonctionComptable?: boolean;
	fonctionFournisseur?: boolean;
	fonctionSousAdministrateur?: boolean;
	specificites: string[];
	listeDroits?: DroitAdmin[];
	locale?: string;
	creationsPossibles?: WFCreationPossible;
	email?: string;
	infosCollaborateur?: { avances: boolean };
	roles?: UserRoles;
	listeLienRoleUsers: LienRoleUser[];
	orga?: OrgaDTO;
	idOrga: number;

	/**
	 * Récupération de l'identifiant
	 */
	getKey() {
		return this.idUser;
	}

	/**
	 * Retourne la chaîne formatée pour l'affichage de l'utilisateur
	 */
	get nomPrenomMatricule(): string {
		return UserPipeTransform(this);
	}
}

/**
 * Type de genre d'un user
 */
export enum TypeGenre {
	MONSIEUR = 1,
	MADAME = 2,
	MADEMOISELLE = 3
}

/**
 * Enumération des types de profil
 */
export enum TypeProfil {
	/* Enumération */
	ADMINISTRATEUR = 0,
	COMPTABLE = 1,
	RESPONSABLE = 2,
	COLLABORATEUR = 3,
	ASSISTANT = 4,
	SOUS_ADMINISTRATEUR = 5,
	FOURNISSEUR = 6
}

/**
 * On merge un namespace dans l'énum pour lui ajouter des fonctions
 */
export namespace TypeProfil {
	/**
	 * Fonction de récupération du libellé d'un profil
	 *
	 * @param translateService le moteur de traduction
	 * @param typeProfil le profil
	 */
	export function getLibelle(translateService: TranslateService,typeProfil: TypeProfil) {
		switch (typeProfil) {
			case TypeProfil.COMPTABLE:
				return translateService.instant('profil.comptable');
			case TypeProfil.RESPONSABLE:
				return translateService.instant('profil.responsable');
			case TypeProfil.COLLABORATEUR:
				return translateService.instant('profil.collaborateur');
			case TypeProfil.ASSISTANT:
				return translateService.instant('profil.assistant');
			case TypeProfil.SOUS_ADMINISTRATEUR:
				return translateService.instant('profil.sousAdministrateur');
			case TypeProfil.FOURNISSEUR:
				return translateService.instant('profil.fournisseur');
		}
	}

	/**
	 * Renvoie la classe de l'icône associée au profil
	 *
	 * @param typeProfil Profil recherché
	 */
	export function getClasseIcone(typeProfil: TypeProfil): string {
		switch (typeProfil) {
			case this.COMPTABLE:
				return 'nio icon-comptable';
			case this.RESPONSABLE:
				return 'nio icon-responsable';
			case this.COLLABORATEUR:
				return 'nio icon-collaborateurs';
			case this.ASSISTANT:
				return 'nio icon-assistant';
			case this.SOUS_ADMINISTRATEUR:
				return 'nio icon-sous_administrateur';
			case this.FOURNISSEUR:
				return 'nio icon-fournisseur';
			default:
				break
		}
		return '';
	}
}
