import {Component, Input} from '@angular/core';
import {ListViewItem} from "../../../../domain/common/list-view/list-view-item";
import {MobileDevice} from "../../../../domain/profil/mobileDevice";

/**
 * Composant d'affichage en liste des périphériques mobiles
 */
@Component({
  selector: 'profil-app-mobile-list-item',
  templateUrl: './profil-app-mobile-list-item.component.html'
})
export class ProfilAppMobileListItemComponent extends ListViewItem<MobileDevice> {
  /** Elément courant */
  @Input() data: MobileDevice;
}
