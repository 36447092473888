import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPopupIgnoreFloatingButton } from "@share/component/popup/IPopupIgnoreFloatingButton";

@Component({
    selector: 'please-wait-dialog',
    templateUrl: './please-wait-dialog.component.html'
})
export class PleaseWaitDialogComponent implements IPopupIgnoreFloatingButton{
    /**
     * Constructeur
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: { title?: string }) {

    }

    idPopupIgnoreFloatingButton: string = "PleaseWaitDialogComponent";
}