import {Component,Input,OnInit} from '@angular/core';
import {ActionWf} from '@domain/admin/workflow/action-wf';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {ActionWfComponent} from "@components/admin/workflow/action-wf/action-wf.component";
import {TranslateService} from "@ngx-translate/core";
import {filter} from 'rxjs/operators';
import {TypeAction,TypePortee,TypePorteeUrlController} from "@domain/workflow/workflow";
import {AppState} from "@domain/appstate";
import {Store} from '@ngrx/store';
import {SettingsGlobalState,SettingsWFObjectState} from '@domain/settings/settings';
import {Analytique} from "@components/analytique/analytique";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {WorkflowService} from "@components/workflow/workflow.service";
import {TypeCodeErreur} from '@domain/common/http/result';
import {ToastrService} from "ngx-toastr";

/**
 * Composant de l'onglet workflow d'un objet workflow
 */
@Component({
	host: {'data-test-id': 'onglet-workflow'},
	selector: 'onglet-workflow',
	templateUrl: './onglet-workflow.component.html'
})
export class OngletWorkflowComponent implements OnInit {
	/** Liste de l'ensemble des actions wf possibles */
	listeActionsWf: ListView<ActionWf,ActionWfComponent>;

	/** Paramétrage de l'objet */
	settings: SettingsWFObjectState;

	/** Accès à l'enum dans la vue */
	readonly TypePortee = TypePortee;

	/** Indique si l'objet est valide */
	@Input() isObjetValide: boolean;

	/** Paramétrage global */
	@Input() globalSettings: SettingsGlobalState;

	/** Portée */
	@Input() portee: TypePortee;

	/** Portée url controller */
	porteeUrl: TypePorteeUrlController;

	/** Titre du type d'objet */
	private cleTradPortee: string;

	/** Liste des ventilations */
	@Input() listeVentilations: Array<Analytique>;

	/** Type entité */
	@Input() typeEntite: TypeEntite;

	/** Identifiant de l'objet */
	@Input() idObjet: number;

	/**
	 * Constructeur
	 *
	 * @param confirmService	Service de confirmation
	 * @param translateService	Service de traduction
	 * @param workflowService	Service du workflow
	 * @param toastrService		Service pour afficher les messages de succès, d'erreurs, ...
	 * @param store				Store de l'appli
	 */
	constructor(private confirmService: ConfirmService,
				private translateService: TranslateService,
				private workflowService: WorkflowService,
				private toastrService: ToastrService,
				private store: Store<AppState>) {
	}

	/**
	 * Initialisation du composant
	 */
	async ngOnInit(): Promise<void> {
		//Récupération des settings
		await this.initSettings();
		this.porteeUrl = TypePorteeUrlController[this.portee];

		//Définition de la liste des actions possibles
		this.listeActionsWf = new ListView<ActionWf,ActionWfComponent>({
			uri: `/controller/${this.porteeUrl}/${this.idObjet}/searchActions`,
			title: this.translateService.instant('admin.ongletWF.title'),
			component: ActionWfComponent,
			listeActions: [{
				icon: 'auto_fix_high',
				onPress: () => this.showCalculDonneesWfPopup(),
				isVisible: () => true
			}]
		});

		this.initFilters();
	}

	/**
	 * Initialise les paramètres.
	 */
	private async initSettings(): Promise<void> {
		return new Promise<void>((resolve,reject) => {
			//Sélection du paramétrage
			this.store.select(state => state.settings?.[this.portee]).pipe(filter(settings => !!settings)).subscribe(settings => {
				this.settings = settings;
				resolve();
			},() => reject());
		});
	}

	/**
	 * Initialise les filtres de la liste des actions.
	 */
	private initFilters(): void {
		//Initialisation des filtres actifs
		this.listeActionsWf.listeSelectedFilters = [];

		//Ajout des filtres (action, rôle)
		this.listeActionsWf.listeFilters = [
			{
				clef: 'idAction',
				title: this.translateService.instant('global.liste.filtres.actions.actions'),
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				isDefault: true,
				listeValues: [
					{
						code: TypeAction.VALIDER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.validation')
					},{
						code: TypeAction.REJETER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.rejet')
					},{
						code: TypeAction.INVALIDER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.invalidation')
					},{
						code: TypeAction.ANNULER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.annulation')
					},{
						code: TypeAction.SUPPRIMER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.suppression')
					},{
						code: TypeAction.EMETTRE.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.emission')
					},{
						code: TypeAction.ARCHIVER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.archive')
					},{
						code: TypeAction.COMPTABILISER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.comptabilisation')
					},{
						code: TypeAction.RESTITUER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.restitution')
					},{
						code: TypeAction.MODIFIER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.modification')
					},{
						code: TypeAction.CLOTURER.toString(),
						libelle: this.translateService.instant('admin.ongletWF.cadreWF.actions.cloture')
					}]
			},
			{
				clef: 'role.idRole',
				title: this.translateService.instant('global.liste.filtres.roles'),
				typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
				isDefault: true,
				listeValues: this.settings.filtresRole.map(role => {
					return {
						code: "" + role.idRole,
						libelle: role.libelle
					};
				})
			}
		]
	}

	/**
	 * Affichage de la popup de confirmation pour le recalcul des données wf
	 */
	showCalculDonneesWfPopup(): void {
		switch (this.portee) {
			case TypePortee.NF:
				this.cleTradPortee = 'ndf.title';
				break;
			case TypePortee.FC:
				this.cleTradPortee = 'facture.title';
				break;
			case TypePortee.AV:
				this.cleTradPortee = 'avance.title';
				break;
			case TypePortee.OD:
				this.cleTradPortee = 'od.title';
				break;
			case TypePortee.OT:
				this.cleTradPortee = 'omp.title';
				break;
		}

		this.confirmService.showConfirm(this.translateService.instant('admin.ongletWF.cadreWF.messageConfirmation', {objet: this.translateService.instant(this.cleTradPortee,{numero: this.idObjet})}))
			.pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				this.recalculDonneesWf()
			}
		});
	}

	/**
	 * Force le recalcul des données workflow de l'objet
	 */
	public recalculDonneesWf() {

		if (this.isObjetValide) {
			//Recalcul des donneés wf
			this.workflowService.synchroWorkflow(this.portee,this.idObjet).subscribe({
				next: result => {
					if (result.codeErreur !== TypeCodeErreur.NO_ERROR) {
						//On affiche le toast erreur
						this.toastrService.error(this.translateService.instant('global.errors.recalculWF'));
					} else {
						//Rechargement de l'objet notamment pour MAJ des droits workflow
						this.workflowService.needRefresh$.next();

						//On affiche le toast succès
						this.toastrService.success(this.translateService.instant('global.success.recalculWF'));
					}
				},
				error: () => {
					//On affiche le toast erreur
					this.toastrService.error(this.translateService.instant('global.errors.recalculWF'));
				}
			});
		} else {
			//On affiche le toast erreur
			this.toastrService.error(this.translateService.instant('global.errors.objetInvalide'));
		}
	}

}
