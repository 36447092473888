import {Component,EventEmitter,Input,OnInit,Output} from '@angular/core';
import {Od} from "@domain/od/od";
import {SettingsODState} from "@domain/settings/settings";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {ODService} from "../../../od.service";
import {TranslateService} from "@ngx-translate/core";
import {TypePortee} from "@domain/workflow/workflow";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {MatDialog} from "@angular/material/dialog";
import {OdFraisFacturesItemComponent} from "./od-frais-factures-item.component";
import {TypeEntiteParam,TypeEntiteParamOD} from "@domain/typeentite/typeEntiteParam";
import {OdTypeFacture} from "@domain/od/frais/od-type-facture";
import {OdFacturePrevisionnelleAddComponent} from "./creation/facture-previsionnelle/od-facture-previsionnelle-add.component";
import {TypeFactPrev} from "@domain/factureprev/type-fact-prev";
import {OdListeFactureItem} from "@domain/od/frais/od-liste-facture";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";

/**
 * Composant d'affichage de la liste des factures rattachées à un OD
 *
 * @author Laurent Convert
 * @date 26/10/2021
 */
@Component({
	selector: 'od-frais-factures',
	templateUrl: './od-frais-factures.component.html'
})
export class OdFraisFacturesComponent implements OnInit {
	/** Portée de l'objet */
	readonly portee: TypePortee = TypePortee.OD;

	/** Ordre de mission */
	@Input() od: Od = null;

	/** Indicateur de modification */
	@Input() canModifier: boolean;

	/** Indicateur de modification de facture prev */
	@Input() canModifierFacturePrev: boolean;

	/** Paramétrage de l'OD */
	settings: SettingsODState;

	/** Paramétrage de l'OD */
	typeEntiteParam: TypeEntiteParam;

	/** Données */
	liste: ListView<OdListeFactureItem,OdFraisFacturesItemComponent>;

	/** Indique un changement de frais prev */
    @Output()
    isFraisPrevChange = new EventEmitter<string>();

	/**
	 * Constructeur
	 */
	constructor(
		private store: Store<AppState>,
		private odService: ODService,
		private translateService: TranslateService,
		private matDialog: MatDialog
	) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Sélection du paramétrage
		this.store.select(state => state.settings?.[this.portee]).pipe(filterFirstNotNull()).subscribe(settings => this.settings = settings);
		
		//Récupération des paramètres du type entité
		this.store.select(state => state.typeEntite?.[this.portee]).pipe(filterFirstNotNull()).subscribe((typeEntite) => {
			//Lecture des paramètres dans le store
			this.typeEntiteParam = typeEntite?.typeEntiteParam;

			//Binding du rafraichissement de la liste
			const refreshListe = this.refreshListe.bind(this);

			//Définition de la liste
			this.liste = new ListView<OdListeFactureItem,OdFraisFacturesItemComponent>({
				uri: `/controller/OD/${this.od.idOd}/filtreFactures`,
				title: this.translateService.instant('od.frais.factures.liste.title'),
				component: OdFraisFacturesItemComponent,
				listeActions: [{
					icon: 'add',
					tooltip: this.translateService.instant(OdTypeFacture.traductionForCreation(OdTypeFacture.facture_prev)),
					isVisible: () => {
						return this.canModifierFacturePrev
							&& this.odService.canCreateElementFacture(this.od,OdTypeFacture.facture_prev,this.typeEntiteParam as TypeEntiteParamOD);
					},
					onPress: () => this.openNewFacturePrev()
				}],
				isFilter: true,
				listeFilters: [{
						title: this.translateService.instant("filter.objet"),
						clef: "title",
						isDefault: true
					},{
						title: this.translateService.instant("filter.type"),
						clef: "typeFacture",
						typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
						isDefault: false,
						listeValues: [{
							code: OdTypeFacture.facture,
							libelle: this.translateService.instant(OdTypeFacture.traduction(OdTypeFacture.facture))
						},{
							code: OdTypeFacture.facture_prev,
							libelle: this.translateService.instant(OdTypeFacture.traduction(OdTypeFacture.facture_prev))
						}]
					}],
				extraOptions: {
					canModifierFacturePrev: this.canModifierFacturePrev,
					od: this.od,
					settings: this.settings,
					refreshListe: (message: string) => {
						refreshListe();
						this.isFraisPrevChange.emit(message);
					}
				}
			});
		});
	}

	/**
	 * Ouvre la popin de création d'une facture prévisionnelle
	 */
	openNewFacturePrev(): void {
		//Initialisation des valeurs par défaut de la facture prévisionnelle
		let facturePrev = {
			idFacturePrev: 0,
			idOd: this.od.idOd,
			devise: this.settings.deviseEntreprise,
			typeFacture: TypeFactPrev.FACTURE
		};

		//Ouverture de la popin
		this.matDialog.open(OdFacturePrevisionnelleAddComponent, {
			data: {
				facturePrev: facturePrev,
				refreshListe: (message: string) => { this.liste.refresh(); this.isFraisPrevChange.emit(message); },
				od: this.od,
				settings: this.settings,
				canModifier: true //On est en création, on ne peut que modifier
			},
			minWidth: '100px'
		}).afterClosed().subscribe((res: {refresh: boolean, message: string}) => {
			//A la fermeture de la popin si on a des données, on rafraichit la liste
			if (res?.refresh) {
				this.refreshListe();
			}
		});
	}

	/**
	 * Rafraichissement de la liste des factures
	 */
	refreshListe(): void {
		this.liste.refresh();
	}

}

