<h1 mat-dialog-title>
    <span *ngIf="isChoixApprobateur && isMail" [translate]="'workflow.notification.title.approbateursDestinataires'"></span>
    <span *ngIf="isChoixApprobateur && !isMail" [translate]="'workflow.notification.title.approbateurs'"></span>
    <span *ngIf="!isChoixApprobateur && isMail" [translate]="'workflow.notification.title.destinataires'"></span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form *ngIf="!isLoading" autocomplete="off">
        <fieldset>
            <mat-tab-group dynamicHeight="dynamicHeight" animationDuration="500ms">
                <mat-tab *ngIf="isChoixApprobateur" [label]="'workflow.notification.approbateursTitle' | translate">
                    <mat-accordion [displayMode]="'flat'" [multi]="true">
                        <approbateur-list [listeApprobateur]="listeApprobateurs"
                                          (onApprobateurChange)="onApprobateurChange($event)"
                        ></approbateur-list>
                    </mat-accordion>
                </mat-tab>
                <mat-tab *ngIf="isMail" [label]="'workflow.notification.destinatairesTitle' | translate">
                    <mat-accordion [displayMode]="'flat'" [multi]="true">
                        <destinataire-list [isMailObligatoire]="isMailObligatoire"
                                           [listeDestinataires]="listeDestinataires"
                                           [selectAll$]="selectAll.asObservable()"
                        ></destinataire-list>
                    </mat-accordion>
                </mat-tab>
            </mat-tab-group>
        </fieldset>
    </form>
    <please-wait *ngIf="isLoading"></please-wait>
</div>
<div mat-dialog-actions *ngIf="!isLoading" style="justify-content: space-between;">
    <div>
        <button mat-stroked-button *ngIf="isSelectionDestinatairePossible"
                [hidden]="tabGroup?.selectedIndex !== 1"
                [matMenuTriggerFor]="listeActionsGlobal">
            {{ 'workflow.notification.boutonSelectionner' | translate }}
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
        </button>
    </div>
    <div>
        <button mat-stroked-button class="m-r-10" mat-dialog-close>{{ 'global.actions.annuler' | translate }}</button>
        <button mat-flat-button color="primary" [disabled]="isLoading || !isValid()" (click)="sendMail()">{{ 'global.actions.confirmer' | translate }}</button>
    </div>
</div>

<!-- Sélection / déselection globale -->
<mat-menu #listeActionsGlobal="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item translate (click)="selectAll.next(true)">workflow.notification.tousLesDestinataires</button>
        <button mat-menu-item translate (click)="selectAll.next(false)">workflow.notification.aucunDestinataire</button>
    </ng-template>
</mat-menu>