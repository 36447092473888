import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'header',
    templateUrl: './header.component.html'
})
export class HeaderComponent {
    /**
     * Constructeur
     */
    constructor(public router: Router) {}
}