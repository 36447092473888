import {Component,OnInit} from '@angular/core';
import {BehaviorSubject,Observable,of} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ListView,TypeFilter} from "@domain/common/list-view";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ComptabiliteModeRemboursementListItemComponent} from "@components/admin/comptabilite/mode-remboursement/list-item/comptabilite-mode-remboursement-list-item.component";
import {ModeRemb,TypeRemb} from "@domain/admin/comptabilite/mode-remb";
import {ToastrService} from "ngx-toastr";
import {ComptabiliteModeRemboursementService} from "@components/admin/comptabilite/mode-remboursement/comptabilite-mode-remboursement.service";
import {Compte} from "@domain/admin/comptabilite/compte";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ComptabiliteModeRemboursementAddComponent} from "@components/admin/comptabilite/mode-remboursement/creation/comptabilite-mode-remboursement-add.component";
import {MatDialog} from "@angular/material/dialog";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Page admin de Comptabilité / Modes de remboursement
 *
 * @author Laurent Convert
 * @date 15/06/2023
 */
@Component({
	host: {'data-test-id': 'comptabilite-mode-remboursement'},
	templateUrl: './comptabilite-mode-remboursement.component.html'
})
export class ComptabiliteModeRemboursementComponent implements OnInit {
	/** Liste des modes de remboursement */
	listeModeRemboursement: ListView<ModeRemb,ComptabiliteModeRemboursementListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Liste des différents onglets */
	listeTabItems: Array<PageHeaderItem> = [{
		code: OngletsInfos.MODES_REMBOURSEMENT,
		libelle: this.translateService.instant('admin.comptabilite.modeRemb.title.liste')
	}];

	/** Liste des comptes bancaires */
	listeCompte: Array<Compte>;

	/** Index de l'onglet courant */
	selectedIndex: number = 0;

	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
	 * @param matDialog Service des boites de dialogue material
	 * @param modeRembService Service des modes de remboursement
	 */
	constructor(private translateService: TranslateService,
				private toastrService: ToastrService,
				private matDialog: MatDialog,
				private modeRembService: ComptabiliteModeRemboursementService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Création de la liste des taux des modes de remboursement
		this.listeModeRemboursement = new ListView<ModeRemb,ComptabiliteModeRemboursementListItemComponent>({
			uri: `/controller/ModeRemb/listeModeRemb`,
			title: this.translateService.instant('admin.comptabilite.modeRemb.title.liste'),
			component: ComptabiliteModeRemboursementListItemComponent,
			mapResult: (result) => {
				//Transformation en instance de chaque item
				result.listeResultats = result.listeResultats.map(modeRemb => new ModeRemb(modeRemb));

				return result;
			},
			extraOptions: {
				openModeRemboursement: this.openModeRemboursement.bind(this)
			},
			isFilter: true,
			defaultOrder: 'libelle',
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('admin.comptabilite.modeRemb.libelle'),
					isDefault: true,
					type: TypeFilter[TypeFilter.STRING]
				}, {
					clef: 'typeRemb',
					title: this.translateService.instant('admin.comptabilite.modeRemb.type'),
					listeValues: TypeRemb.listeFilters(this.translateService)
				}, {
					clef: 'compte.numero,compte.libelle',
					title: this.translateService.instant('admin.comptabilite.modeRemb.compte'),
					isDefault: true,
					type: TypeFilter[TypeFilter.STRING]
				}, {
					clef: 'code',
					title: this.translateService.instant('admin.comptabilite.modeRemb.code'),
					isDefault: true,
					type: TypeFilter[TypeFilter.STRING]
				}, {
					clef: 'defaut',
					title: this.translateService.instant('admin.comptabilite.modeRemb.defaut'),
					type: TypeFilter[TypeFilter.BOOLEAN]
				}, {
					clef: 'isActif',
					title: this.translateService.instant('admin.comptabilite.modeRemb.actif'),
					type: TypeFilter[TypeFilter.BOOLEAN]
				}
			]
		});

		//Définition des colonnes de tri
		this.listeModeRemboursement.columns = [
			{ key: 'libelle', title: 'admin.comptabilite.modeRemb.libelle' },
			{ key: 'typeRemb', title: 'admin.comptabilite.modeRemb.type' },
			{ key: 'compte.numero,compte.libelle', title: 'admin.comptabilite.modeRemb.compte' },
			{ key: 'code', title: 'admin.comptabilite.modeRemb.code' },
			{ key: 'defaut', title: 'admin.comptabilite.modeRemb.defaut' },
			{ key: 'isActif', title: 'admin.comptabilite.modeRemb.actif' },
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeModeRemboursement.sorting = new Sorting(this.listeModeRemboursement.columns, "libelle");

		//Définition de la liste des actions
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-ajouter',
			libelle: 'global.actions.creer',
			doAction: () => this.openModeRemboursement()
		}]);
	}

	/**
	 * Ouverture du mode de remboursement
	 *
	 * @param modeRemb Mode de remboursement. null => création
	 */
	openModeRemboursement(modeRemb?: ModeRemb) {
		//Ouverture du mode de remboursement
		this.matDialog.open(ComptabiliteModeRemboursementAddComponent,{
			width: '60%',
			data: {
				modeRemb: new ModeRemb(modeRemb ?? {idModeRemb: 0,actif: true,defaut:false}),
				loadListeCompte: this.loadListeCompte.bind(this)
			}
		}).afterClosed().subscribe((isReload: boolean) => {
			//Vérification du besoin de recharger la liste
			if (isReload) {
				//Rechargement
				this.listeModeRemboursement.refresh();
			}
		});
	}

	/**
	 * Retourne la liste des comptes bancaires avec mise en cache local (charge la liste le cas échéant).
	 */
	private loadListeCompte(): Observable<Array<Compte>> {
		//Vérification que la liste n'a pas encore été chargée
		if (this.listeCompte == null) {
			//Récupération de la liste des comptes
			return this.modeRembService.getListeCompte().map(result => {
				//Vérification du retour
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Mise en "cache" et renvoi de la liste des comptes
					return this.listeCompte = result.data.listeCompte as Array<Compte>;
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);

					return null;
				}
			}, () => {
				//Message d'erreur
				TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
			});
		} else {
			//Renvoi de la liste déjà chargée
			return of(this.listeCompte);
		}
	}
}

/**
 * Enum pour les noms des différents onglets de la page des modes de remboursement
 */
export enum OngletsInfos {
	MODES_REMBOURSEMENT = "MODES_REMBOURSEMENT",
}