<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'success': data.uidArchive && data.nbPJIgnorees === 0,'danger': data.uidArchive && data.nbPJIgnorees > 0 }">
        <i class="nio icon-note_de_frais"></i>
    </div>
    <br/>
    <div class="lvi-body">
        <div class="title">
            <strong>
                <a href="{{ lienArchive }}" target="_blank" [matTooltip]="'admin.parametres.demat.archivage.detail.tooltipDownload' | translate">{{ data.numero }}</a>
            </strong>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'admin.parametres.demat.archivage.liste.proprietaire'"></strong>{{ data.proprietaire }}</li>
            <li><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJTotal'"></strong>{{ data.nbPJ }}</li>
            <li *ngIf="data.uidArchive"><strong [translate]="'admin.parametres.demat.archivage.liste.nbPJIgnorees'"></strong>{{ data.nbPJIgnorees }}</li>
            <li *ngIf="data.uidArchive"><strong [translate]="'admin.parametres.demat.archivage.liste.uidDocumentArchive'"></strong>{{ data.uidArchive }}</li>
        </ul>
    </div>
</div>
