import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {EntityWrapper} from "@domain/entite/EntityWrapper";
import {EntityWrapperMember} from "@domain/entite/EntityWrapperMember";
import {first} from "rxjs/operators";
import {EntiteService} from "@share/component/rule-builder/entite.service";

@Component({
	templateUrl: './business-data-popin.component.html',
	styleUrls: ['./business-data-popin.component.scss']
})
export class BusinessDataPopinComponent implements OnInit {
	/** Donnée métier */
	businessData: EntityWrapper;

	/** Liste des entités ouvertes */
	listeOpenedEntities: Array<EntityWrapper | EntityWrapperMember> = [];

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param entiteService le service de gestion des données métier
	 * @param dialogRef la référence pointant vers la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { entite: string,businessData: EntityWrapper,show1n: boolean },
		private entiteService: EntiteService,
		private dialogRef: MatDialogRef<BusinessDataPopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération des données en entrée de la popin
		this.businessData = Object.assign({},this.data.businessData);

		//Ajout de l'entité courante à la liste de celles ouvertes
		this.listeOpenedEntities.push(this.businessData);
	}

	/**
	 * Retour à l'entité sélectionnée
	 *
	 * @param index l'index jusqu'auquel retourner
	 */
	goBackToEntity(index: number): void {
		//Coupage de la liste des entités ouvertes
		this.listeOpenedEntities.length = index;
	}

	/**
	 * Liste des membres à afficher
	 */
	getListeMembres(): EntityWrapperMember[] {
		let entity = this.listeOpenedEntities[this.listeOpenedEntities.length - 1];

		if ((entity as EntityWrapper).listeMembres) {
			return (entity as EntityWrapper).listeMembres.filter(m => this.data.show1n || !m.collection);
		} else {
			return (entity as EntityWrapperMember).enclosedWrapper.listeMembres.filter(m => this.data.show1n || !m.collection);
		}
	}

	/**
	 * Sélection de l'élément
	 *
	 * @param member le member sélectionné
	 */
	selectItem(member: EntityWrapperMember): void {
		let startEntity: EntityWrapper;
		let path: string = "";
		let pathLibelle: string = "";

		//Vérification de la présence d'une sous-entité
		if (member.enclosedWrapper) {
			//Ouverture de l'entité
			this.openEntity(member);
		} else {
			//Parcourt des entités ouvertes
			this.listeOpenedEntities.forEach((entity: EntityWrapper) => {
				//Vérification de la présence de l'entité de départ
				if (!startEntity) {
					//Initialisation de l'entité de départ
					startEntity = entity;
				}

				//Construction du chemin
				path += (path ? '.' : '') + entity.code.substr(entity.code.lastIndexOf('.') + 1);
			});

			//Ajout de l'item courant
			path += (path ? '.' : '') + member.code.substr(member.code.lastIndexOf('.') + 1);

			//Vérification de la liste des entités ouvertes
			if (this.listeOpenedEntities.length > 1) {
				//Parcours des entités ouvertes
				for (const [index,entity] of this.listeOpenedEntities.entries()) {
					//On ne prend pas le premier niveau
					if (index > 0) {
						//Ajout du libellé
						pathLibelle += (pathLibelle === '' ? '' : ' > ') + entity.libelle;
					}
				}
			}

			//Définition du path et de son libellé
			member.path = path;
			member.pathLibelle = (pathLibelle != '' ? pathLibelle + ' > ' : '') + member.libelle;

			//Fermeture de la popup
			this.dialogRef.close(member);
		}
	}

	/**
	 * Ouverture d'une entité
	 *
	 * @param member l'entité
	 */
	openEntity(member: EntityWrapperMember): void {
		//Définition du chemin de l'entité recherchée
		let listePaths = this.listeOpenedEntities.map(e => e.code);

		//Vérification de l'entité
		if (member.enclosedWrapper) {
			//Ajout de l'entité dans le chemin
			listePaths.push(member.code);

			//Chargement des données métier de la sous-entité
			this.entiteService.loadSubEntity(this.data.entite,listePaths)
				.pipe(first())
				.subscribe((enclosedWrapper: EntityWrapper) => {
					//Vérification de la présence de l'entité
					if (enclosedWrapper) {
						//Récupération de l'entité
						member.enclosedWrapper = enclosedWrapper;

						//Ajout à la liste des entités ouvertes
						this.listeOpenedEntities.push(member);
					}
				});
		}
	}
}
