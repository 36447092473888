import {FicheCollaborateurModule} from './../fiche-collaborateur/fiche-collaborateur.module';
import {NgModule} from '@angular/core';
import {ShareModule} from "@share/share.module";
import {WorkflowModule} from "../workflow/workflow.module";
import {AnalytiqueModule} from "../analytique/analytique.module";
import {ReferentielExterneModule} from "../referentiel-externe/referentiel-externe.module";
import {DocumentModule} from "../document/document.module";
import {AvanceService} from "./avance.service";
import {AvanceComponent} from './avance.component';
import {AvanceGeneralitesComponent} from './generalite/avance-generalites.component';
import {AvanceHistoriqueListComponent} from './historique/avance-historique-list.component';
import {AvanceHistoriqueListItemComponent} from './historique/avance-historique-list-item.component';
import {AvanceDevisePipe} from './avance-devise.pipe';
import {AvanceListComponent} from './avance-list/avance-list.component';
import {AvanceListItemComponent} from './avance-list/avance-list-item/avance-list-item.component';
import {ZoneUtilisateurModule} from './../zone-utilisateur/zone-utilisateur.module';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ProprietaireModule} from "@share/component/proprietaire/proprietaire.module";
import {AdminOutilsModule} from "@share/component/outils/admin-outils.module";
import {AdminWorkflowModule} from "@components/admin/workflow/admin-workflow.module";

@NgModule({
    imports: [ShareModule, WorkflowModule, AnalytiqueModule, ReferentielExterneModule, DocumentModule, FicheCollaborateurModule, ZoneUtilisateurModule, PreviewAlerteModule, ProprietaireModule,AdminOutilsModule,AdminWorkflowModule],
    declarations: [AvanceListComponent, AvanceListItemComponent, AvanceComponent, AvanceGeneralitesComponent, AvanceHistoriqueListComponent, AvanceHistoriqueListItemComponent, AvanceDevisePipe],
    entryComponents: [AvanceListItemComponent],
    providers: [AvanceService]
})
export class AvanceModule {
}
