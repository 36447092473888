<div class="with-floating-button">
    <div class="content" *ngIf="adresse">
        <page-header *ngIf="!isCreation"
                     [title]="'admin.bibliotheque.geographie.adresse.title' | translate: {libelle: adresse.libelle? adresse.libelle : ''}"
                     [listeItems]="listeTabItems" (onGoBack)="onGoBack()">
        </page-header>
        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.adresse.titleCreation' | translate"
                     [listeItems]="listeTabItems"
                     (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="adresse">
                <adresse-generalites [adresse]="adresse" [isCreation]="isCreation"></adresse-generalites>
            </form>
        </div>

        <floating-button [listeActions]="listeActions"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!adresse"></please-wait>
</div>