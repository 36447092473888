import {Component,Inject,OnInit} from "@angular/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ExerciceDto} from "@domain/admin/entreprise/analytique/exerciceDto";
import {MontantDto} from "@domain/admin/entreprise/analytique/montantDto";
import * as moment from "moment";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";

/**
 * Composant d'affichage d'un montant d'une enveloppe budgétaire
 */
@Component({
    host: {'data-test-id': 'montant-edit'},
    selector: 'montant-edit',
    templateUrl: './montant-edit.component.html'
})
export class MontantEditComponent  implements OnInit {
	/** Montant courant */
	montant: MontantDto;

	/** Liste des exercices */
    listeExercice: ExerciceDto[];

    /** Flag permettant de signaler la sauvegarde */
    isSaving: boolean = false;

    /**
     * Constructeur
     */
    constructor(
        private enveloppeDetailService: EnveloppeDetailService,
        private matDialogRef: MatDialogRef<MontantEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {montant?: MontantDto}
    ) { }

    /**
     * Hook initialisation
     */
    ngOnInit(): void {
		//Récupération du montant
		this.montant = Object.assign({},this.data.montant);

		//Chargement des exercices
        this.enveloppeDetailService.listeExercices().subscribe(exercices => {
            //On affecte l'exercice on en profite pour trier la liste de date
            this.listeExercice = exercices
                .sort((exerciceA, exerciceB) => {
                    if (exerciceA.dateDebut < exerciceB.dateDebut) {
                        return 1;
                    }
                    if (exerciceA.dateDebut > exerciceB.dateDebut) {
                        return -1;
                    }
                    return 0;
                });

            //On affecte les périodes de l'année en cours si on est en création
			if (this.montant.idMontant === 0) {
                const nowDate = moment();
                const currentExercice = this.listeExercice.find(exercice =>
                    (moment(exercice.dateDebut).isBefore(nowDate) && moment(exercice.dateFin).isAfter(nowDate)));
				this.montant.exercice = new ExerciceDto();
				this.montant.exercice.listePeriode = currentExercice.listePeriode;
            } else {
				const currentExercice = this.listeExercice.find(exercice => exercice.idExercice == this.montant.exercice.idExercice);
				this.montant.exercice.listePeriode = currentExercice.listePeriode;
            }
        });
    }

	/**
	 * Validation du montant.
	 */
	public isValidMontant(): boolean {
		return this.montant.montant >= 0 && this.montant.montant >= (this.montant.montantEngage || 0);
	}

    /**
     * Sauvegarde le montant
     */
    saveMontant(): void {
		this.montant.idExercice = this.montant.exercice.idExercice;
		this.montant.idPeriodeDebut = this.montant.periodeDebut.idPeriode;
		this.montant.idPeriodeFin = this.montant.periodeFin.idPeriode;

        //Ferme la fenêtre en indiquant le montant
		this.matDialogRef.close(this.montant);
    }
}
