<div class="lvi-content">
    <div class="avatar" [innerHTML]="icone"></div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="extraOptions.parent.openField(data)">{{ code }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong translate>admin.voyages.travelhub.sbtConfig.parametres.valeur</strong>{{ valeur }}</li>
        </ul>
    </div>
    <div *ngIf="data.getId() > 0" class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deleteField()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
