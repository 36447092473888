import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ChampComplementaire} from "@domain/user/champ-complementaire";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {UtilisateursParametresService} from "@services/admin/entreprise/utilisateurs/utilisateurs-parametres.service";
import {filter,finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";

/**
 * Composant de la popin de modification d'un champ complémentaire
 */
@Component({
	host: {'data-test-id': 'champ-complementaire-popin'},
	templateUrl: './champ-complementaire-popin.component.html'
})
export class ChampComplementairePopinComponent implements OnInit {
	/** Champ complémentaire courant */
	champ: ChampComplementaire;

	/** Le champ est-il supprimable */
	canDelete: boolean = false;

	/** Indique si un traitement est en cours */
	isPending: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param toastrService le toaster
	 * @param translateService le moteur de traduction
	 * @param usersParamsService le service de gestion des paramètres utilisateurs
	 * @param matDialogRef la référence à cette popin
	 * @param confirmService le service de confirmation
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { champ: ChampComplementaire },
		private toastrService: ToastrService,
		private translateService: TranslateService,
		private usersParamsService: UtilisateursParametresService,
		private matDialogRef: MatDialogRef<ChampComplementairePopinComponent>,
		private confirmService: ConfirmService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération du champ en entrée
		this.champ = Object.assign({},this.data.champ);

		//Définition de canDelete
		this.canDelete = !!this.champ.name
	}

	/**
	 * Enregistrement du champ
	 */
	onSave(): void {
		//Chargement en cours
		this.isPending = true;

		//Vérification de la validité du champ complémentaire
		if (this.checkValidity(this.champ)) {
			//Appel au service d'enregistrement
			this.usersParamsService.saveChampComplementaire(this.champ)
				.pipe(first(),finalize(() => this.isPending = false))
				.subscribe((result: Result) => {
					//Vérification du result
					if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Toast succès
						this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

						//Fermeture de la popin avec demande de rechargement de l'écran précédent
						this.matDialogRef.close(true);
					} else {
						//Gestion de l'erreur
						TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
					}
				});
		} else {
			//Fin chargement
			this.isPending = false;
		}
	}

	/**
	 * Vérification de la validité du champ avant sa sauvegarde
	 *
	 * @param champ le champ à sauvegarder
	 */
	checkValidity(champ: ChampComplementaire): boolean {
		//Définition de la RegExp de validation du masque
		const regExp: RegExp = new RegExp("^([Z9?*]){1,}$");

		//Vérification du masque
		if (champ.masque.length < champ.longueurMini) {
			//La longueur du masque et la longueur minimale sont en conflit
			//Toast erreur
			this.toastrService.error(this.translateService.instant('admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.errors.length'));

			return false;
		} else if (!regExp.test(champ.masque)) {
			//Le masque ne respecte pas l'expréssion régulière
			//Toast erreur
			this.toastrService.error(this.translateService.instant('admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.errors.masque'));

			return false;
		}

		return true;
	}

	/**
	 * Suppression du champ
	 */
	onDelete(): void {
		//Affichage de la confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Chargement en cours
				this.isPending = true;

				//Appel au service
				this.usersParamsService.deleteChampComplementaire(this.champ)
					.pipe(first(),finalize(() => this.isPending = false))
					.subscribe((result: Result) => {
						//Vérification du result
						if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Toast succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Fermeture de la popin avec demande de rechargement de l'écran précédent
							this.matDialogRef.close(true);
						} else {
							//Gestion de l'erreur
							TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
						}
					});
			});
	}
}
