<div class="with-floating-button">
    <div class="content">
        <page-header [title]="'admin.bibliotheque.internationalisation.title' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglet.LANGUES">
            <list-view *ngIf="liste" [liste]="liste"></list-view>
        </div>
        <div *ngIf="selectedItem?.code === Onglet.MESSAGES">
            <!-- TODO: A supprimer quand l'impl�mentation v10 sera termin�e -->
            <custom-frame [frame]="'traductionMessages'" [isTitleHidden]="true"></custom-frame>
        </div>
        <div *ngIf="selectedItem?.code === Onglet.REFERENTIELS">
            <!-- TODO: A supprimer quand l'impl�mentation v10 sera termin�e -->
            <custom-frame [frame]="'traductionReferentiels'" [isTitleHidden]="true"></custom-frame>
        </div>
    </div>
    <floating-button [listeActions]="listeActions"></floating-button>
</div>
