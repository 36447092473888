export enum TypeRoleOption {
    PRECONISATION_VOYAGE=1,
    BON_COMMANDE_AVANCE=2,
    TABLES_DEVISE=3,
    CREA_FORM_SANTE=4,
    CREA_FORM_VOYAGE=5,
    ATTR_FORM_SANTE=6,
    ATTR_FORM_VOYAGE=7,
    SOUS_ADM=8,
    GESTION_ACCES=9,
    EXTR_AVNAT=10,
    EXTR_DADS=11,
    EXTR_BILLET_TRAIN=12,
    LETTRAGE_AVANCE=13,
    EXP_PREVISIONNEL=14,
    REAFFECTATION_BUDGETAIRE=15,
    OM_ACCESSIBLES=16,
    NDF_ACCESSIBLES=17,
    AVANCES_ACCESSIBLES=18,
    FACTURES_ACCESSIBLES=19,
    POINT_FOCAL=20,
    STAT_EXCEL=21,
    EXTRACTION_RECEPTIONS=22,
    TVTS=23,
    RIB=24,
    CARTES=25,
    EXTRACTION_TR=26,
    MODIF_ORGA=27,
    GESTION_EQUIPE=28,
    EXTRACTION_FORMATION=29,
    EXTRACTION_NDF=30,
    EXTRACTION_ABSENCE=31,
    EXTRACTION_NDF_PREVISIONNELLE=32,
    GARAGE=33,
    MISE_CONGES=34,
    EXTRACTION_SUIVI_NDF=35,
    RESPONSABLE_BUDGET=36,
    SUIVI_ABSENCE=37,
    SUIVI_BUDGETAIRE=38,
    SUIVI_ZU_OM=39,
    SUIVI_BUDGETAIRE_DETAILLE=40,
    ROBOT_WORKFLOW=41,
    GESTION_PIECES_JOINTES=42,
    IMPORT_RELEVE_OPE=43,
    CONTACT_NOTILUS=44,
    CONNEXION_POUR=45
}
