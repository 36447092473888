<h1 mat-dialog-title>
    <span [translate]="'ndf.frais.title'"></span>
    <span [mat-dialog-close]="null"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>
    <form autocomplete="off" #fraisAddForm="ngForm">
        <fieldset *ngIf="!isLoading" [disabled]="!data.canModifier">
            <div class="row">
                <div class="col-md-6">
                    <div class="row" [ngClass]="{ 'has-error': date.invalid }">
                        <label class="col-md-4 required">
                            <span [translate]="'ndf.frais.date'"></span>
                        </label>
                        <div class="col-md-8">
                            <date-range-custom 
                                #date="ngModel"
                                [ngModel]="maDate"
                                [(maDate)]="maDate"
                                name="date" ngDefaultControl
                                (maDateChange)="onDateChange();"
                                [disabled]="!data.canModifier"
                                [rangeDeb]="data.od?.depart_le"
                                [rangeEnd]="data.od?.retour_le"
                                [required]="true"
                                notNullValidator>
                            </date-range-custom>
                        </div>
                    </div>
                    <div class="row" [ngClass]="{ 'has-error': geographie.invalid }">
                        <label class="col-md-4 required">
                            <span [translate]="'ndf.frais.villePays'"></span>
                        </label>
                        <div class="col-md-8">
                            <autocomplete type="geographie" name="geographie" ngDefaultControl #geographie="ngModel"
                                          [filter]="{ listeTypes: ['PAYS','VILLE'],contexte: 'NDF' }"
                                          [(ngModel)]="data.fraisPrev.geographie"
                                          [floatLabel]="'never'" [label]="'ndf.frais.villePays' | translate" required="true"
                                          [disabled]="!data.canModifier"
                                          (onSelect)="onGeographieChange($event)"></autocomplete>
                        </div>
                    </div>
                    <div class="row" [ngClass]="{ 'has-error': prestation.invalid }">
                        <label class="col-md-4 required">
                            <span [translate]="'ndf.frais.prestation'"></span>
                        </label>
                        <div class="col-md-8">
                            <autocomplete type="prestation" name="prestation" required="true" ngDefaultControl #prestation="ngModel"
                                          [filter]="{ idPays: (data.fraisPrev.geographie?.pays?.idPays || data.fraisPrev.geographie?.ville?.pays?.idPays || null),idTypeEntite: data.od.typeEntite.idTypeEntite,typeObjet: TypePortee.OD,idObjet: data.od.idOd }"
                                          [(ngModel)]="data.fraisPrev.prestation"
                                          [floatLabel]="'never'" [label]="'ndf.frais.prestations' | translate"
                                          [disabled]="!data.canModifier"
                                          (onSelect)="onPrestationChange($event)"></autocomplete>
                        </div>
                    </div>
                    <div class="row" *ngIf="data.fraisPrev.prestation && data.fraisPrev.prestation.type !== undefined">
                        <label class="col-md-4"></label>
                        <div class="col-md-8">
                            <span *ngIf="data.fraisPrev.prestation.type == TypePrestation.REEL || data.fraisPrev.prestation.idPrestation == saisieState?.tauxPrestation?.idPrestation" 
                                [innerHtml]="'ndf.prestation.typeRemboursement.' + TypePrestation[data.fraisPrev.prestation.type]
                                    | translate:{ montant: (getTauxForPrestation() | montant:this.data.fraisPrev?.devise), unite: data.fraisPrev.prestation.unite?.libelle }">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row" [ngClass]="{ 'has-error': quantite.invalid }">
                        <label class="col-md-4" [ngClass]="{ 'required': data.fraisPrev.prestation?.quantiteObligatoire }">
                            <span [translate]="'ndf.frais.quantite'"></span>
                        </label>
                        <div class="col-md-4">
                            <mat-form-field class="d-flex">
                                <input name="quantite" matInput #quantite="ngModel" [(ngModel)]="data.fraisPrev.quantite" [nNumber]="2" [required]="data.fraisPrev.prestation?.quantiteObligatoire" [readonly]="!data.fraisPrev.prestation?.quantiteModifiable"/>
                                <span matSuffix *ngIf="data.fraisPrev.prestation?.unite" class="p-l-5">{{ data.fraisPrev.prestation.unite.libelle }}</span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" [ngClass]="{ 'has-error': montant.invalid }" *ngIf="data.fraisPrev.prestation?.type != TypePrestation.FORFAIT">
                        <label class="col-md-4 required">
                            <span [translate]="'ndf.frais.montant'"></span>
                        </label>
                        <div class="col-md-8">
                            <div class="d-flex">
                                <mat-form-field>
                                    <input name="montant" matInput #montant="ngModel" [(ngModel)]="data.fraisPrev.montant" nNumber="2" class="m-r-10" required/>
                                    <mat-select name="devise" [(ngModel)]="data.fraisPrev.devise" [disabled]="!data.canModifier" (selectionChange)="onDeviseChange($event)">
                                        <mat-option *ngFor="let devise of data.settings.listeDevises" [value]="devise.code">{{ devise.code }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="data.fraisPrev.prestation?.type == TypePrestation.FORFAIT">
                        <label class="col-md-4">
                            <span [translate]="'ndf.frais.montant'"></span>
                        </label>
                        <div class="col-md-8">
                            <div class="d-flex">
                                <mat-form-field>
                                    <span class="m-r-10">{{ (getMontantForfait() || 0) | number:'1.2-2' }}</span>
                                    <mat-select name="devise" [(ngModel)]="data.fraisPrev.devise" [disabled]="!data.canModifier" (selectionChange)="onDeviseChange($event)">
                                        <mat-option *ngFor="let devise of data.settings.listeDevises" [value]="devise.code">{{ devise.code }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.data.fraisPrev.prestation && saisieState?.tauxPrestation?.allTaux?.length > 0">
                        <label class="col-md-4">
                            <span [translate]="'ndf.frais.indemnite'"></span>
                        </label>
                        <div class="col-md-8">
                            <div class="d-flex">
                                <mat-form-field [floatLabel]="'never'">
                                    <mat-label>{{ 'od.frais.frais.creation.fraisPrev.aucuneIndemnite' | translate }}</mat-label>
                                    <mat-select name="taux" [(ngModel)]="data.fraisPrev.taux" [compareWith]="fraisService.compareTaux"
                                                [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: findTaux(data.fraisPrev.taux?.idTaux) }" [nioTooltipDisabled]="!data.fraisPrev.taux" nioTooltipPosition="right">
                                        <mat-option [value]="fraisService.prestTauxToFraisMission(taux)" *ngFor="let taux of saisieState?.tauxPrestation?.allTaux"
                                                    [nioTooltip]="tooltipTaux" [nioTooltipContext]="{ taux: taux }" nioTooltipPosition="right">
                                            {{ taux.libelleSpecial != null &&  taux.libelleSpecial != '' ?  taux.libelleSpecial : taux.libelleTerritoire }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" [ngClass]="{ 'has-error': remarque.invalid }">
                <label class="col-md-2" [ngClass]="{ 'required': prestation.model?.remarqueObligatoire }">
                    <span>{{ prestation?.model?.libelleRemarque || ('ndf.frais.remarque' | translate) }}</span>
                </label>
                <div class="col-md-10">
                    <mat-form-field class="d-flex">
                        <textarea matInput #remarque="ngModel" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" name="remarque" [(ngModel)]="data.fraisPrev.remarque" [required]="prestation.model?.remarqueObligatoire"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading">
    <button mat-stroked-button color="primary" (click)="deleteFraisPrev()" [disabled]="isProcessing || !data.canModifier" *ngIf="data.fraisPrev.idFraisPrev">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <button mat-flat-button color="primary" [disabled]="fraisAddForm.invalid || isProcessing || !data.canModifier" (click)="saveFraisPrev()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>
<ng-template #tooltipTaux let-taux="taux">
    <table>
        <tr *ngIf="taux.libelleSpecial">
            <td><label translate>frais.tooltipTaux.libelle</label></td>
            <td>{{ taux.libelleSpecial }}</td>
        </tr>
        <tr *ngIf="taux.libelleTerritoire">
            <td><label translate>frais.tooltipTaux.lieu</label></td>
            <td>{{ taux.libelleTerritoire }}</td>
        </tr>
        <tr>
            <td><label translate>frais.tooltipTaux.periode</label></td>
            <td>{{ fraisService.getPeriode(taux) }}</td>
        </tr>
        <tr>
            <td><label translate>frais.tooltipTaux.montant</label></td>
            <td>{{ taux.montant | montant:taux.devise }}</td>
        </tr>
    </table>
</ng-template>