import {ListeAlertes} from "../common/alerte/listeAlertes";
import {Od} from "../od/od";
import {MapAction} from "../workflow/mapAction";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {TypePortee} from "../workflow/workflow";
import {User} from "../user/user";
import { TranslateService } from "@ngx-translate/core";

export class Avance extends AbstractObjetWorkflow {
    idAvance: number;
    type: string;
    remarque: string;
    nature: any;
    montant: number;
    montantContrepartie: number;
    taux: number;
    dateSaisie: any;
    libelleObjet: string;
    devise: any;
    idOd: number;
    od: Od;
    compteCollaborateur: any;
    listeAvanceChainage: any[];
    listeZUSaisies: any[];
    listeZU: any[];
    listeAlertes: ListeAlertes;
    user: User;
    statut: any;
    listeHistoWorkflow: MapAction;

    constructor(dto?: any) {
        super();
        if (dto) {
            this.idAvance = dto.idAvance;
            this.type = dto.type;
            this.remarque = dto.remarque;
            this.nature = dto.nature;
            this.montant = dto.montant;
            this.montantContrepartie = dto.montantContrepartie;
            this.taux = dto.taux;
            this.dateSaisie = dto.dateSaisie;
            this.libelleObjet = dto.libelleObjet;
            this.devise = dto.devise;
            this.idOd = dto.idOd;
            if (dto.od) { this.od = new Od(dto.od); }
            this.compteCollaborateur = dto.compteCollaborateur;
            this.listeAvanceChainage = dto.listeAvanceChainage;
            this.listeZUSaisies = dto.listeZUSaisies;
            this.listeZU = dto.listeZU;
            this.listeAlertes = dto.listeAlertes as ListeAlertes;
            this.user = dto.user;
            this.statut = dto.statut;
            if (dto.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(dto.listeHistoWorkflow); }
            this.masque = dto.masque;
        } else {
            this.idAvance = 0;
            this.dateSaisie = Date.now();
            this.taux = 1;
        }
    }

    getId(): number {
        return this.idAvance;
    }

    getPortee(): TypePortee {
        return TypePortee.AV;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        if (!this.user) { return null; }
        return (this.user.nom ? this.user.nom + ' ' : '')
                + (this.user.prenom ? this.user.prenom : '');
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.user?.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.idAvance || translateService.instant('avance.liste.numeroNonDefini')) + ' - ' + (this.libelleObjet || translateService.instant('avance.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return true;
    }
}