<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.userEntreeSortiePopin.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="dateEntree"
                    name="dateEntree"
                    customType="date"
                    [ngModel]="user.dateIn"
                    [(customModel)]="user.dateIn"
                    libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.userEntreeSortiePopin.dateEntree"
                    [dateMax]="user.dateOut">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="actif"
                    name="actif"
                    customType="checkbox"
                    [ngModel]="user.actif"
                    [(customModel)]="user.actif"
                    [libelle]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.userEntreeSortiePopin.actif'">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="dateSortie"
                    name="dateSortie"
                    customType="date"
                    [ngModel]="user.dateOut"
                    [(customModel)]="user.dateOut"
                    libelle="admin.entreprise.utilisateurs.detail.generalites.infosGenerales.userEntreeSortiePopin.dateSortie"
                    [dateMin]="user.dateIn">
            </custom-input>
        </div>
        <p [translate]="'admin.entreprise.utilisateurs.detail.generalites.infosGenerales.userEntreeSortiePopin.text'"></p>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" [mat-dialog-close]><span [translate]="'global.actions.annuler'"></span></button>
    <button mat-flat-button color="primary" [mat-dialog-close]="user" [disabled]="form?.invalid"><span [translate]="'global.actions.confirmer'"></span></button>
</div>