import {Component,OnInit} from '@angular/core';
import {EntrepriseVehiculesService} from "@components/admin/entreprise/vehicules/entreprise-vehicules.service";
import {OptionsVehicules,SelectOptionConfiguration} from "@domain/admin/entreprise/vehicules/optionsVehicules";
import {TranslateService} from "@ngx-translate/core";
import {MessageNotif} from "@domain/notification/message-notif";
import {NiveauAutorisation} from "@domain/vehicule/autorisation";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {finalize} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

/**
 * Page admin de Véhicules / Paramètres
 *
 * @author Angeline Ha
 * @date 19/04/2024
 */
@Component({
    host: {'data-test-id': 'vehicules-parametres'},
    templateUrl: './vehicules-parametres.component.html'
})
export class VehiculesParametresComponent implements OnInit {
    /** Paramètres */
    parametres: OptionsVehicules;

    /** Liste des emails pour les notifications */
    listeEmailsNotif: Array<MessageNotif>

    /** Liste des options de configuration */
    listeOptionsConfig = SelectOptionConfiguration.values().map(config => {
        return {
            id: config.valueOf(),
            libelle: SelectOptionConfiguration.traduction(config,this.translateService)
        }
    });

    /** Liste des options de niveaux d'autorisation */
    listeOptionsNiveauAutorisation = NiveauAutorisation.values().map(nvAuto => {
        return {
            id: NiveauAutorisation[nvAuto],
            libelle: NiveauAutorisation.traduction(nvAuto,this.translateService)
        }
    });

    /* Valeurs initiales de la configuration des OD et NDF */
    initNdfApprobation: NiveauAutorisation;
    initNdfAutorisation: NiveauAutorisation;
    initOdApprobation: NiveauAutorisation;
    initOdAutorisation: NiveauAutorisation;

    /** Liste des actions possibles pour le floatingButton en bas à droite */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Indicateur de chargement */
    isLoading: boolean;

    /**
     * Constructeur
     *
     * @param vehiculeService
     * @param translateService
     * @param toastrService
     */
    constructor(private vehiculeService: EntrepriseVehiculesService,
                private translateService: TranslateService,
                private toastrService: ToastrService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération des paramètres
        this.vehiculeService.getParametres().subscribe(result => {
            //Paramètres
            this.parametres = new OptionsVehicules(result.data.optionsVehicules);

            //Liste des emails
            this.listeEmailsNotif = result.data.listeMessageNotif;

            //Sauvegarde des valeurs initiales de la configuration des OD et NDF
            this.initNdfApprobation = this.parametres.ndfApprobation;
            this.initNdfAutorisation = this.parametres.ndfAutorisation;
            this.initOdApprobation = this.parametres.odApprobation;
            this.initOdAutorisation = this.parametres.odAutorisation;
        });

        //Définition de la liste des actions possibles
        this.listeActions.next([{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveParametres(),
        }]);
    }

    /**
     * Mise à jour des booléens pour le certificat d'immatriculation
     */
    onCertificatImmatriculationChange(){
        switch (this.parametres.certifImmatriculationEtat) {
            case SelectOptionConfiguration.DESACTIVE:
                this.parametres.certifImmatriculation = false;
                this.parametres.certifImmatriculationObligatoire = false;
                break;
            case SelectOptionConfiguration.ACTIF:
                this.parametres.certifImmatriculation = true;
                this.parametres.certifImmatriculationObligatoire = false;
                break;
            case SelectOptionConfiguration.ACTIF_OBLIGATOIRE:
                this.parametres.certifImmatriculation = true;
                this.parametres.certifImmatriculationObligatoire = true;
                break;
        }
    }

    /**
     * Mise à jour des booléens pour l'assurance
     */
    onAssuranceChange(){
        switch (this.parametres.attestationAssuranceEtat) {
            case SelectOptionConfiguration.DESACTIVE:
                this.parametres.attestationAssurance = false;
                this.parametres.attestationObligatoire = false;
                break;
            case SelectOptionConfiguration.ACTIF:
                this.parametres.attestationAssurance = true;
                this.parametres.attestationObligatoire = false;
                break;
            case SelectOptionConfiguration.ACTIF_OBLIGATOIRE:
                this.parametres.attestationAssurance = true;
                this.parametres.attestationObligatoire = true;
                break;
        }
    }

    /**
     * Réinitialisation des valeurs de la configuration des OD et NDF
     */
    onApprobationChange(){
        //Si l'approbation a été décochée
        if (this.parametres.approbation) {
            this.parametres.ndfApprobation = this.initNdfApprobation;
            this.parametres.ndfAutorisation = this.initNdfAutorisation;
            this.parametres.odApprobation = this.initOdApprobation;
            this.parametres.odAutorisation = this.initOdAutorisation;
        }
    }

    /**
     * Réinitialisation des valeurs de la configuration des OD et NDF
     */
    onAutorisationChange(){
        //Si l'autorisation a été décochée
        if (this.parametres.approbation) {
            this.parametres.ndfAutorisation = this.initNdfAutorisation;
            this.parametres.odAutorisation = this.initOdAutorisation;
        }
    }

    /**
     * Enregistrement des paramètres
     */
    saveParametres() {
        //Enregistrement en cours
        this.isLoading = true;

        //Enregistrement des paramètres
        this.vehiculeService.saveParametres(this.parametres)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((result: Result) => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Message de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
                } else {
                    //Message d'erreur
                    TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
                }
            });
    }

}
