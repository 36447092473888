import {ListItem} from "../common/list-view/list-item";

/**
 * Compte bancaire
 */
export class CompteBancaire implements ListItem {
    actif?: boolean;
    beneficiaire?: string;
    bic?: string;
    defaut?: boolean;
    devise?: string;
    domiciliation?: string;
    id?: number;
    idCompte?: number;
    idPays?: number;
    idUser?: number;
    libelle?: string;
    nbPJ?: 1
    numero?: string;
    pays?: any;
    typeCompte?: string;

    getKey(): number {
        return this.idCompte;
    }

    constructor() {
        this.idCompte = 0;
        this.actif = true;
    }
}
