import {Component,Input} from '@angular/core';
import {AlerteFrais,ListeMesFrais,TypeFrais} from '@domain/ndf/liste-mes-frais';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {NdfFraisAddMesFraisService} from '../ndf-frais-add-mes-frais.service';
import {filter,first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {TypeCodeErreur} from '@domain/common/http/result';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {Subject} from 'rxjs';

/**
 * Item de la liste mes frais
 */
@Component({
    host: {'data-test-id': 'ndf-frais-add-mes-frais-item'},
    selector: 'ndf-frais-add-mes-frais-item',
    templateUrl: './ndf-frais-add-mes-frais-item.component.html'
})
export class NdfFraisAddMesFraisItemComponent extends ListViewItem<ListeMesFrais>  {

    public TypeFrais = TypeFrais;

    /** Input liste mes frais */
    @Input() data: ListeMesFrais;

    /** Options supplémentaires */
    @Input() extraOptions: {
        eventRefresh: Subject<void>
    }

    /** Indique si le chevron est ouvert */
    isChevronOpen: boolean = false;

    constructor(
            private ndfFraisAddMesFraisService: NdfFraisAddMesFraisService,
            private toastrService: ToastrService,
            private translateService: TranslateService,
            private confirmService: ConfirmService) {
        super();
    }

    /**
     * Evènement de suppression de l'item
     */
    public deleteFraisOcr(): void {
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                this.ndfFraisAddMesFraisService.deleteFraisAndFraisOcr(this.data.id).pipe(first()).subscribe({
                    next: result => {
                        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                            this.toastrService.success(this.translateService.instant('global.success.suppression'));
                        } else {
                            this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                        }

                        //Refresh de la liste
                        this.extraOptions.eventRefresh.next();
                    },
                    error: () => {
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'));
                    }
                });
            }
        });
    }

    /**
     * Récupère la valeur à traduire selon le type d'alerte
     */
    getAlerteTranslate(alerteType: AlerteFrais): string {
        switch(alerteType) {
            case AlerteFrais.ANOMALIE_DEPENSE_FUTURE:
                return this.translateService.instant("ndf.fraisPrev.erreur.depenseFuture");
            case AlerteFrais.ANOMALIE_MONTANT_NEGATIF:
                return this.translateService.instant("ndf.fraisPrev.erreur.montantNegatif");
            case AlerteFrais.ANOMALIE_OM_PERMANENT_DATE:
                return this.translateService.instant("ndf.fraisPrev.erreur.omPermanentDate");
            case AlerteFrais.ANOMALIE_PERIODE:
                return this.translateService.instant("ndf.fraisPrev.erreur.periode");
            case AlerteFrais.ANOMALIE_QUANTITE_AUTORISEE:
                return this.translateService.instant("ndf.fraisPrev.erreur.quantite");
            case AlerteFrais.ANOMALIE_OD_DATE:
                return this.translateService.instant("ndf.fraisPrev.erreur.odDate");
        }
    }

	/**
	 * Indique si une alerte est présente
	 */
	hasAlerte(): boolean {
		return this.data.listeAlertes?.listeAlertes.length > 0;
	}
}
