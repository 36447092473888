import {Component,OnInit} from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {PageHeaderItem} from '@share/component/page-header/page-header';
import {TranslateService} from '@ngx-translate/core';
import {first} from "rxjs/operators";
import {Location} from "@angular/common";

/**
 * Composant d'affichage du menu missions
 */
@Component({
	host: {'data-test-id': 'admin-missions'},
	templateUrl: './voyages-missions.component.html',
})
export class VoyagesMissionsComponent implements OnInit {

	/** Etat de chargement de l'onglet od */
	isOdListLoaded: boolean = false;

	/** Etat de chargement de l'onglet omp */
	isOmpListLoaded: boolean = false;

	/** Onglets disponibles sur la page Missions */
	protected readonly Tab = Tab;

	/** Constructeur */
	constructor(private translateService: TranslateService,
				private activatedRoute: ActivatedRoute,
				private location: Location,
				private router: Router) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		// Affichage de l'onglet souhaité (retour depuis od ou omp)
		this.activatedRoute.queryParams.pipe(first()).subscribe(queryParams => {
			const tabIndexParam = queryParams['tabIndex'];

			if (!!tabIndexParam) {
				const newTab = this.listeTabItems[tabIndexParam];

				if (!!newTab) {
					newTab.selected = true;
					this.onSelectedItemChange(newTab);
				}
			}
		});
	}

	/** Liste des onglets */
	listeTabItems: Array<PageHeaderItem> = [{
		code: Tab.LISTEOD,
		libelle: this.translateService.instant('admin.voyages.missions.listeOD')
	},{
		code: Tab.LISTEOMP,
		libelle: this.translateService.instant('admin.voyages.missions.listeOMP')
	}];

	/** Onglet sélectionné */
	private _selectedItem: PageHeaderItem;

	/** Getter de l'onglet sélectionné */
	get selectedItem(): PageHeaderItem {
		return this._selectedItem;
	}

	/**
	 * Setter de l'onglet sélectionné.
	 * Il peut être utile de le surcharger pour gérer le chargement des onglets<br>
	 * <strong>ATTENTION</strong> : en cas de surcharge, il faut également surcharger le getter (standard typescript)
	 */
	set selectedItem(item: PageHeaderItem) {
		//Edition de la variable privée
		this._selectedItem = item;
	}

	/**
	 * Changement d'onglet
	 * @param selectedItem nouvel onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		//index de l'onglet sélectionné
		const index: number = this.listeTabItems.indexOf(selectedItem);

		//Mise à jour de l'onglet sélectionné
		this.selectedItem = selectedItem;

		//booléen de chargement de l'onglet passé à true
		if (selectedItem.code === Tab.LISTEOD) {
			this.isOdListLoaded = true;
		} else if (selectedItem.code === Tab.LISTEOMP) {
			this.isOmpListLoaded = true;
		}

		//Mise à jour de l'url avec le bon index
		this.location.replaceState(this.router.url.split("?")[0],`?tabIndex=${index}`);
	}
}

/**
 * Enum des différents onglets disponibles sur la page
 */
export enum Tab {
	LISTEOD = 'LISTEOD',
	LISTEOMP = 'LISTEOMP',
}