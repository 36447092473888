import { Component,Input,OnInit,TemplateRef,ViewChild } from '@angular/core';
import { SettingsODState } from "../../../../../domain/settings/settings";
import { EngagementsFactures } from "../../../../../domain/od/engagements/factures/engagements-factures";
import { EngagementsFrais } from "../../../../../domain/od/engagements/frais/engagements-frais";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../domain/appstate";
import { TypePortee } from "../../../../../domain/workflow/workflow";
import { EngagementsResume } from "../../../../../domain/od/engagements/resume/engagements-resume";

/**
 * Template générique d'affichage d'un cadre de l'onglet des engagements
 *
 * @author Laurent Convert
 * @date 11/01/2022
 */
@Component({
    selector: 'od-engagements-card',
    templateUrl: './od-engagements-card.component.html'
})
export class OdEngagementsCardComponent implements OnInit {

    /** Template d'affichage de l'avatar */
    @Input("avatar") templateAvatar: TemplateRef<any>;

    /** Template d'affichage du prévisionnel */
    @Input("previsionnel") private _templatePrevisionnel?: TemplateRef<any>;

    /** Getter du template du prévisionnel. Retourne celui par défaut si non spécifié. */
    get templatePrevisionnel() {
        return this._templatePrevisionnel ? this._templatePrevisionnel : this.templatePrevisionnelDefault;
    }

    /** Template d'affichage du réel */
    @Input("reel") private _templateReel?: TemplateRef<any>;

    /** Getter du template du réel. Retourne celui par défaut si non spécifié. */
    get templateReel() {
        return this._templateReel ? this._templateReel : this.templateReelDefault;
    }

    /** Template d'affichage du prévisionnel */
    @ViewChild("previsionnel") templatePrevisionnelDefault: TemplateRef<any>;

    /** Template d'affichage du réel */
    @ViewChild("reel") templateReelDefault: TemplateRef<any>;

    /** Titre du mat-card */
    @Input() title: string;

    /** Engagements de type facture ou frais */
    @Input() engagements: EngagementsFactures | EngagementsFrais | EngagementsResume;

    /** Paramétrage de l'OD */
    settings: SettingsODState;

    /** Indicateur du chargement en cours */
    isLoading: boolean = false;

    /** Indique si le détail de la ligne est affiché */
    isOuvert: boolean = true;

    constructor(private store: Store<AppState>) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit(): void {
        //Sélection du paramétrage
        this.store.select(state => state.settings?.[TypePortee.OD]).subscribe(settings => this.settings = settings);
    }

    /**
     * Ouvre / Ferme le détail de la ligne
     */
    toggleDetail() {
        this.isOuvert = !this.isOuvert;
    }

}