import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {TypeProfil} from "@domain/user/user";
import {Filtre,TypeFiltre} from "@domain/admin/entreprise/user/filtre";
import {TranslateService} from "@ngx-translate/core";
import {AutocompleteType} from "@share/component/autocomplete/options/autocomplete-type";
import {setTimeout} from "core-js";
import {Dossier} from "@domain/admin/entreprise/analytique/dossier";
import {Referentiel} from "@domain/admin/referentiels/referentiel";
import {Orga} from "@domain/admin/entreprise/analytique/orga";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {EntrepriseUtilisateursService} from "@components/admin/entreprise/utilisateurs/entreprise-utilisateurs.service";
import {finalize,first} from "rxjs/operators";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Composant de la popin de création d'un filtre
 */
@Component({
	host: {'data-test-id': 'create-filtre-popin'},
	templateUrl: './create-filtre-popin.component.html'
})
export class CreateFiltrePopinComponent implements OnInit {
	/** Liste des types de filtres */
	listeTypeFiltre: Array<{ code: TypeFiltre,libelle: string }>;

	/** Type de filtre sélectionné */
	selectedType: TypeFiltre;

	/** Valeur du filtre sélectionné */
	selectedFiltre: Dossier | Referentiel | Orga | TypeEntite;

	/** Indicateur de traitement en cours */
	isPending: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param userService le service de gestion de l'utilisateur
	 * @param toastrService le toaster
	 * @param matDialogRef la ref de la popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idUser: number,fonction: TypeProfil.COLLABORATEUR | TypeProfil.RESPONSABLE },
		private translateService: TranslateService,
		private userService: EntrepriseUtilisateursService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<CreateFiltrePopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Initialisation de la liste des types de filtre
		this.listeTypeFiltre = this.getListeTypeFiltreByFonction(this.data.fonction);
	}

	/**
	 * Récupération de la liste des types de filtre possible en fonction du profil sur lequel on ajoute le filtre
	 *
	 * @param fonction le profil sur lequel ajouter un filtre
	 */
	getListeTypeFiltreByFonction(fonction: TypeProfil): Array<{ code: TypeFiltre,libelle: string }> {
		//Initialisation d'une liste contenant mot-clef et type entité
		let listeTypeFiltre: Array<{ code: TypeFiltre,libelle: string }> = [
			{
				code: TypeFiltre.M,
				libelle: TypeFiltre.getLibelle(TypeFiltre.M,this.translateService)
			},{
				code: TypeFiltre.T,
				libelle: TypeFiltre.getLibelle(TypeFiltre.T,this.translateService)
			}
		];

		//Si l'on est en mode collaborateur
		if (fonction === TypeProfil.COLLABORATEUR) {
			//On renvoie tel quel
			return listeTypeFiltre;
		} else {
			//Sinon, si l'on est en responsable, on ajoute organisation et code projet
			listeTypeFiltre.push(
				{
					code: TypeFiltre.O,
					libelle: TypeFiltre.getLibelle(TypeFiltre.O,this.translateService)
				},{
					code: TypeFiltre.D,
					libelle: TypeFiltre.getLibelle(TypeFiltre.D,this.translateService)
				}
			);

			return listeTypeFiltre;
		}
	}

	/**
	 * Méthode appelée à chaque changement de type
	 */
	onSelectedTypeChange(): void {
		const tmpType: TypeFiltre = this.selectedType;

		//On supprime le filtre courant s'il y en a déjà un
		delete this.selectedFiltre;

		//On supprime selectedType afin de recharger l'autocomplete (qui comporte un *ngIf(selectedType)) avec le nouveau autocompleteType
		delete this.selectedType;

		//setTimeout obligatoire pour que le passage de selectedType à null soit pris en compte
		setTimeout(() => {
			this.selectedType = tmpType;
		});
	}

	/**
	 * Récupération du type d'autocomplete en fonction du type de filtre sélectionné
	 */
	getAutocompleteType(): AutocompleteType {
		//Switch sur le type de filtre sélectionné
		switch (this.selectedType) {
			case TypeFiltre.D:
				return 'dossier'
			case TypeFiltre.M:
				return 'motClef'
			case TypeFiltre.O:
				return 'service'
			case TypeFiltre.T:
				return 'typeEntite'
		}
	}

	/**
	 * Récupération du nom de l'ID en fonction du type de filtre sélectionné
	 */
	getIdName(): string {
		//Switch sur le type de filtre sélectionné
		switch (this.selectedType) {
			case TypeFiltre.D:
				return 'idDossier'
			case TypeFiltre.M:
				return 'idReferentiel'
			case TypeFiltre.O:
				return 'idOrga'
			case TypeFiltre.T:
				return 'idTypeEntite'
		}
	}

	/**
	 * Enregistrement du filtre
	 */
	save(): void {
		//Construction du filtre
		let filtre: Filtre = new Filtre();

		//Définition des valeurs idUser et typeFiltre
		filtre.idUser = this.data.idUser;
		filtre.typeFiltre = this.selectedType;

		//Définition de l'ID en fonction du type
		switch (this.selectedType) {
			case TypeFiltre.D:
				filtre.idFiltre = (this.selectedFiltre as Dossier).idDossier
				break;
			case TypeFiltre.M:
				filtre.idFiltre = (this.selectedFiltre as Referentiel).id
				break;
			case TypeFiltre.O:
				filtre.idFiltre = (this.selectedFiltre as Orga).idOrga
				break;
			case TypeFiltre.T:
				filtre.idFiltre = (this.selectedFiltre as TypeEntite).idTypeEntite
				break;
		}

		//Sauvegarde en cours
		this.isPending = true;

		//Appel au service
		this.userService.addFiltre(filtre,this.data.fonction)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popin avec rechargement de liste
					this.matDialogRef.close(true);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}