<fieldset ngModelGroup="zone-generalites">
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.zone.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="zone.libelle"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.territoire.libelle"
                                  [required]="true">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="code"
                                  name="code"
                                  [(ngModel)]="zone.code"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.territoire.code"
                                  maxlength="2"
                                  rCol="4">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="commentaire"
                                  name="commentaire"
                                  [(ngModel)]="zone.commentaire"
                                  customType="textarea"
                                  rCol="10"
                                  libelle="admin.bibliotheque.geographie.territoire.commentaire"></custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
