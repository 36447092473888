import {NgModule} from '@angular/core';
import {WorkflowConditionsComponent} from './workflow-conditions.component';
import {Routes} from "@angular/router";
import {AlerteListComponent} from './alertes/liste/alerte-list.component';
import {AlerteListItemComponent} from './alertes/liste/item/alerte-list-item.component';
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {AlerteDetailComponent} from 'app/components/admin/workflow/conditions/alertes/detail/alerte-detail.component';
import {ShareModule} from "@share/share.module";
import {ConditionVisibiliteListComponent} from "./conditions-visibilite/liste/condition-visibilite-list.component";
import {SelectStatutsPopinComponent} from './alertes/detail/select-statuts-popin/select-statuts-popin.component';
import {SelectProfilsPopinComponent} from './alertes/detail/select-profils-popin/select-profils-popin.component';
import {StatutListItemComponent} from './alertes/detail/select-statuts-popin/statut-list-item/statut-list-item.component';
import {ProfilListItemComponent} from './alertes/detail/select-profils-popin/profil-list-item/profil-list-item.component';
import {AlerteTestComponent} from './alertes/detail/alerte-test/alerte-test.component';
import {ConditionVisibiliteListItemComponent} from '@components/admin/workflow/conditions/conditions-visibilite/liste/item/condition-visibilite-list-item.component';
import {ConditionVisibiliteDetailComponent} from 'app/components/admin/workflow/conditions/conditions-visibilite/detail/condition-visibilite-detail.component';
import {CodemirrorModule} from "@ctrl/ngx-codemirror";

/** Routes du menu Workflow - Conditions */
export const workflowConditionsRoutes: Routes = [
	{
		path: 'Alertes',
		component: AlerteListComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'Alerte/:idAlerte',
		component: AlerteDetailComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'ConditionsVisibilite',
		component: ConditionVisibiliteListComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: 'ConditionVisibilite/:idCondition',
		component: ConditionVisibiliteDetailComponent,
		canActivate: [AdminGuardProvider]
	},{
		path: '',
		pathMatch: 'full',
		redirectTo: 'Alertes'
	},{
		path: '**',
		redirectTo: 'Alertes'
	}
]

/**
 * Module du menu Workflow - Conditions
 */
@NgModule({
	declarations: [
		WorkflowConditionsComponent,
		AlerteListComponent,
		AlerteListItemComponent,
		AlerteDetailComponent,
		AlerteTestComponent,
		ConditionVisibiliteListComponent,
		SelectStatutsPopinComponent,
		SelectProfilsPopinComponent,
		StatutListItemComponent,
		ProfilListItemComponent,
		ConditionVisibiliteListItemComponent,
		ConditionVisibiliteDetailComponent
	],
	imports: [
		ShareModule,
		CodemirrorModule
	]
})
export class WorkflowConditionsModule {
}
