<div class="lvi-content">
    <div class="avatar">
        <i [hidden]="data.type !== typeContact[typeContact.CONTACT_URGENCE]" class="material-icons-outlined">phone</i>
        <i [hidden]="data.type === typeContact[typeContact.CONTACT_URGENCE]" class="material-icons-outlined">mail</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [translate]="'profilUser.infoContact.'+data.type" (click)="this.extraOptions.open(data)"></a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.numero">
                <strong [translate]="'profilUser.infoContact.numero'"></strong>
                <span>{{data.numeroBrut}}</span>
            </li>
            <li *ngIf="data.email">
                <strong [translate]="'profilUser.infoContact.email'"></strong>
                <span>{{data.email}}</span>
            </li>
            <li *ngIf="data.nom">
                <strong [translate]="'profilUser.infoContact.nom'"></strong>
                <span>{{data.nom}}</span>
            </li>
            <li *ngIf="data.prenom">
                <strong [translate]="'profilUser.infoContact.prenom'"></strong>
                <span>{{data.prenom}}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span *ngIf="data.actif" [translate]="'global.oui'"></span><span *ngIf="!data.actif" [translate]="'global.non'"></span>
            </li>
        </ul>
    </div>
</div>
