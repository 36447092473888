import {Component,OnDestroy,OnInit,ViewChild} from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {filter,finalize,first,mergeMap} from "rxjs/operators";
import {AdminTravelhubService} from "../../admin-travelhub.service";
import {ProfilConnexion} from "@domain/travelhub/profil-connexion";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {ProfilConnexionParam} from "@domain/travelhub/profil-connexion-param";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {BehaviorSubject,Observable,of,Subscription} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ListView} from "@domain/common/list-view";
import {AiguillageListItemComponent} from "./aiguillage/aiguillage-list-item/aiguillage-list-item.component";
import {Page} from "@domain/common/http/list-result";
import {NgForm} from "@angular/forms";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {MatDialog,MatDialogRef} from "@angular/material/dialog";
import {AiguillageAddComponent} from "./aiguillage/aiguillage-add/aiguillage-add.component";
import {NUM_SBT,TypeAiguillage,TypeNature} from "@domain/voyage/travel/constants";
import {Alerte,NiveauAlerte} from "@domain/common/alerte/alerte";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";

/**
 * Composant de création / modification d'un profil de connexion TH
 *
 * @author Laurent Convert
 * @date 12/03/2024
 */
@Component({
	host: {'data-test-id': 'profil-connexion-add'},
	templateUrl: './profil-connexion-add.component.html'
})
export class ProfilConnexionAddComponent implements OnInit,OnDestroy {
	/* Déclaration pour accès dans le template */
	protected readonly OngletsProfilConnexion = OngletsProfilConnexion;

	/** Liste des différents onglets */
	listeTabItems: Array<PageHeaderItem> = [{
		code: OngletsProfilConnexion.GENERALITES,
		libelle: this.translateService.instant('admin.voyages.travelhub.profilConnexion.onglets.generalites')
	}];

	/** Onglet courant */
	_selectedItem: PageHeaderItem;

	/* Accesseurs de l'onglet courant */
	get selectedItem(): PageHeaderItem { return this._selectedItem; }
	set selectedItem(pageHeaderItem: PageHeaderItem) {
		this._selectedItem = pageHeaderItem;
		this._selectedItem.isLoaded = true;
	}

	/** Profil de connexion courant */
	profilConnexion: ProfilConnexion;

	/** Identifiant du profil par défaut */
	idProfilConnexionDefaut: number;

	/** Liste des configurations de type ONLINE disponibles */
	listeOnlineSBTConfig: Array<TravelHubSBTConfigUsed> = [];

	/** Liste des paramètres du profil */
	listeAiguillage: ListView<ProfilConnexionParam,AiguillageListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Chargement de l'objet en cours */
	isLoading: boolean = true;

	/** Action en cours */
	isPending: boolean;

	/** Souscription au (re)chargement de la liste des aiguillages */
	listeAiguillageRefreshSub: Subscription;

	/** Accesseur permettant de savoir si on est en création ou non */
	get isCreation(): boolean { return this.profilConnexion?.idProfilConnexion === 0; }

	/** Formulaire de la page */
	@ViewChild('form') form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param router Le routeur angular
	 * @param route Route active
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 * @param translateService Service de traduction
	 * @param toastrService Service de notification
	 * @param confirmService Service de confirmation utilisateur via modale
	 * @param matDialog Boîte de dialogue
	 * @param pleaseWaitService Service d'affichage du loading
	 */
	constructor(private router: Router,
				private route: ActivatedRoute,
				private adminTravelhubService: AdminTravelhubService,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private confirmService: ConfirmService,
				private matDialog: MatDialog,
				private pleaseWaitService: PleaseWaitService,

	) {
	}

	/**
	 * initialisation du composant
	 */
	ngOnInit() {
		//récupération de l'ID de l'exercice dans l'URL
		this.route.params.pipe(
			first(),
			mergeMap((params) => {
				//Récupération du profil à partir de l'id
				return this.adminTravelhubService.loadProfilConnexion(params.id);
			}),
			finalize(() => this.isLoading = false)
		).subscribe(result => {
			//Vérification du résultat
			if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Récupération des données depuis le résultat
				this.profilConnexion = new ProfilConnexion(result.data.profilConnexion);
				this.idProfilConnexionDefaut = result.data.idProfilConnexionDefaut;

				//Définition de la liste des configurations ONLINE disponibles
				this.listeOnlineSBTConfig = this.getOnlineSBTConfig(result.data.listeAiguillageOnline);

				//Initialisation des onglets
				this.initListeTabItems();

				//Initialisation des actions
				this.initListeActions();

				//Cas de la création
				if (!this.isCreation) {
					//Initialisation de la liste des aiguillages
					this.initListeAiguillage();
				}
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_LOAD,this.translateService,this.toastrService);
		});
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Désabonnement
		this.listeAiguillageRefreshSub?.unsubscribe();
	}

	/**
	 * Récupération de la liste distincte des configurations SBT online
	 *
	 * @param params Liste des aiguillages online
	 */
	private getOnlineSBTConfig(params: Array<ProfilConnexionParam>): Array<TravelHubSBTConfigUsed> {
		const listeDistinctSBTConfig: Array<TravelHubSBTConfigUsed> = [];
		const configLibelles: Array<string> = [];

		//Récupération de la liste des configurations SBT online
		params?.map(param => param.travelHubSBTConfigUsed)
			.forEach((SBTConfigUsed) => {
				//Vérification de l'absence du libellé de la config dans la liste des libellés déjà rencontrés
				if (!configLibelles.some(lib => lib === SBTConfigUsed.libelle)) {
					//Ajout du libelle de la config courante à ceux déjà traités
					configLibelles.push(SBTConfigUsed.libelle);

					//Ajout de la configuration à la liste résultat
					listeDistinctSBTConfig.push(SBTConfigUsed);
				}
			});

		return listeDistinctSBTConfig;
	}

	/**
	 * Traitement particulier pour les aiguillages ayant une configuration SBT sur Egencia
	 * @return Retourne la liste des aiguillages manquants
	 */
	private checkSpecificEgencia(): Array<ProfilConnexionParam> {
		let listeMissingParam: Array<ProfilConnexionParam> = [];
		let missingParam: ProfilConnexionParam;
		let isParamCorrespondant: boolean;

		this.listeAiguillage.data.listeResultats.forEach(param => {
			//Vérification du SBT (Egencia) et de l'aiguillage
			if (param.travelHubSBTConfigUsed.sbtConfig.numSBT == NUM_SBT.EgenciaOff && param.typeAiguillage == TypeAiguillage.OFFLINE) {
				//Recherche du paramètre Online correspondant
				isParamCorrespondant = this.listeAiguillage.data.listeResultats.some(item => {
					//On vérifie s'il y a bien une configuration Egencia ONLINE (V1 ou V2) correspondant à la configuration OFFLINE (Egencia offline marche en duo avec Egencia online)
					return (item.travelHubSBTConfigUsed.sbtConfig.numSBT == NUM_SBT.Egencia || item.travelHubSBTConfigUsed.sbtConfig.numSBT == NUM_SBT.Egencia2) && item.typeAiguillage == TypeAiguillage.ONLINE && item.typeNature == param.typeNature;
				});

				//Vérification du paramètre
				if (!isParamCorrespondant) {
					missingParam = new ProfilConnexionParam();
					missingParam.isMissingParam = true;
					missingParam.idProfilConnexionParam = 0;
					missingParam.profilConnexion = param.profilConnexion;
					missingParam.typeNature = param.typeNature;
					missingParam.typeAiguillage = TypeAiguillage.ONLINE;

					//Ajout à la liste
					listeMissingParam.push(missingParam);
				}
			}
		});

		//Ajout des aiguillages manquants à la liste, en haut
		if (listeMissingParam.length > 0) {
			this.listeAiguillage.addItem(listeMissingParam,false);
		}

		return listeMissingParam;
	}

	/**
	 * Initialisation de la liste des actions disponibles
	 */
	private initListeActions(): void {
		//Enregistrement toujours disponible
		const listeActions: Array<FloatingButtonAction> = new Array<FloatingButtonAction>({
			type: TypeAction.PRIMARY,
			icone: 'nio icon-sauvegarde',
			libelle: 'global.actions.enregistrer',
			doAction: () => this.saveProfilConnexion(),
			isDisabled: () => this.form?.invalid
		});

		//Ajout des actions si l'objet existe déjà
		if (!this.isCreation) {
			listeActions.push({
				type: TypeAction.SECONDARY,
				icone: 'nio icon-suppression',
				libelle: 'global.actions.supprimer',
				doAction: () => this.deleteProfilConnexion(),
			});
		}

		//Mise à jour de la liste des actions
		this.listeActions.next(listeActions);
	}

	/**
	 * Initialisation des onglets
	 */
	private initListeTabItems(): void {
		//Vérification si l'onglet Usage doit être présent
		if (!this.isCreation && !this.listeTabItems.some(t => t.code === OngletsProfilConnexion.USAGE)) {
			//Ajout de l'onglet à la liste
			this.listeTabItems.push({
				code: OngletsProfilConnexion.USAGE,
				libelle: this.translateService.instant('admin.voyages.travelhub.profilConnexion.onglets.usage')
			});
		}

		//Vérification de l'onglet affiché
		if (this.selectedItem == null) {
			//Activation du 1er onglet
			this.selectedItem = this.listeTabItems[0];
		}
	}

	/**
	 * Initialisation de la liste des aiguillages
	 */
	private initListeAiguillage(): void {
		this.listeAiguillage = new ListView<ProfilConnexionParam,AiguillageListItemComponent>({
			uri: `/controller/ProfilConnexion/listeParam/${this.profilConnexion.idProfilConnexion}`,
			title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.aiguillage'),
			component: AiguillageListItemComponent,
			mapResult: (result: Page<ProfilConnexionParam>): Array<ProfilConnexionParam> => {
				result.listeResultats = result.listeResultats.map(fa => new ProfilConnexionParam(fa));
				return result.listeResultats;
			},
			extraOptions: {
				openAiguillage: this.openAiguillage.bind(this),
				deleteAiguillage: this.deleteAiguillage.bind(this),
			},
			listeActions: [{
				icon: 'add',
				onPress: this.addAiguillage.bind(this)
			}],
			isSimple: true
		});

		//Abonnement au (re)chargement de la liste des aiguillages
		this.listeAiguillageRefreshSub = this.listeAiguillage.loaded.subscribe(() => {
			//Récupération des aiguillages manquants le cas échéant
			let listeMissingParam = this.checkSpecificEgencia();

			//Mise à jour du niveau d'alerte de la liste des aiguillages
			this.listeAiguillage.alertLevel = listeMissingParam.length > 0 ? NiveauAlerte.ERROR : undefined;

			//Initialisation de la liste des alertes
			if (this.profilConnexion.listeAlertes == null) {
				this.profilConnexion.listeAlertes = new ListeAlertes();
			}

			//Suppression des alertes gérées ici et définies précédemment le cas échéant
			if (this.profilConnexion.listeAlertes.listeAlertes.length > 0) {
				this.profilConnexion.listeAlertes.remove(
					this.profilConnexion.listeAlertes.listeAlertes.filter(alerte => alerte.message === 'admin.voyages.travelhub.profilConnexion.alerteAiguillageOnlineManquant')
				);
			}

			//Ajout d'une alerte d'aiguillage manquant pour chaque
			listeMissingParam.forEach((param,idx) => {
				this.profilConnexion.listeAlertes.add(new Alerte({
					niveau: NiveauAlerte.ERROR,
					message: 'admin.voyages.travelhub.profilConnexion.alerteAiguillageOnlineManquant',
					traductionParams: {nature: this.translateService.instant(TypeNature.traduction(param.typeNature))}
				}),true);
			});
		});
	}

	/**
	 * Enregistrement du profil de connexion TH
	 */
	saveProfilConnexion(): void {
		let confirm$: Observable<boolean> = of(true);
		const isCreation = this.isCreation;

		//Demande de confirmation dans le cas où un profil par défaut existe déjà
		if (this.profilConnexion.defaut && this.idProfilConnexionDefaut > 0 && this.idProfilConnexionDefaut != this.profilConnexion.idProfilConnexion) {
			confirm$ = this.confirmService.showConfirm(this.translateService.instant('admin.voyages.travelhub.profilConnexion.confirmerRemplacementProfilDefaut'));
		}

		//Attente de la confirmation le cas échéant, sinon passage direct à l'enregistrement
		confirm$.pipe(filter(isConfirm => isConfirm)).subscribe(() => {
			//Enregistrement en cours
			this.isPending = true;

			//Enregistrement
			this.adminTravelhubService.saveProfilConnexion(this.profilConnexion)
				.pipe(finalize(() => this.isPending = false))
				.subscribe(result => {
					if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
						//Message de succès
						this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

						//Mise à jour de l'objet
						Object.assign(this.profilConnexion,result.data.profilConnexion);

						//Vérification de la liste des aiguillages
						if (this.listeAiguillage == null) {
							//Initialisation de la liste
							this.initListeAiguillage();
						} else {
							//Rechargement de la liste
							this.listeAiguillage.refresh();
						}

						//Initialisation des onglets
						this.initListeTabItems();

						//Initialisation des actions
						this.initListeActions();

						//Mise à jour de la route vers le nouveau profil dans le cas d'une création
						if (isCreation) {
							//Mise à jour de la route
							this.router.navigate(['../',this.profilConnexion.idProfilConnexion],{relativeTo: this.route});
						}
					} else if (result?.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.profilConnexion.messageProfilDoublon'));
					} else if (result?.codeErreur === TypeCodeErreur.ERROR_ALREADY_USED) {
						//Message d'erreur : Impossible de rendre inactif ce profil car il est utilisé par un ou des utilisateurs actifs
						this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.profilConnexion.messageProfilActifUtilise'));
					} else {
						//Message d'erreur
						TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
					}
				},() => {
					//Message d'erreur
					TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
				});
		});
	}

	/**
	 * Suppression du profil de connexion TH
	 */
	deleteProfilConnexion(): void {
		//Suppression en cours
		this.isPending = true;

		//Demande de confirmation
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed),
				mergeMap(() => this.adminTravelhubService.deleteProfilConnexion(this.profilConnexion.idProfilConnexion)),
				finalize(() => this.isPending = false)
			)
			.subscribe(result => {
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message de succès
					this.toastrService.success(this.translateService.instant('global.success.suppression'));

					//Retour à la liste
					this.router.navigate(['../' ],{relativeTo: this.route});
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			},() => {
				//Message d'erreur
				TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
			});
	}

	/**
	 * Ajout d'un aiguillage
	 */
	addAiguillage(): void {
		//Définition du nouvel aiguillage
		const aiguillage: ProfilConnexionParam = new ProfilConnexionParam();
		aiguillage.idProfilConnexionParam = 0;
		aiguillage.profilConnexion = this.profilConnexion;

		//Type d'aiguillage Offline forcé sur le profil
		if (this.profilConnexion.forceOffline) {
			aiguillage.typeAiguillage = TypeAiguillage.OFFLINE;
		}

		//Ouverte du nouvel aiguillage pour ajout
		this.openAiguillage(aiguillage);
	}

	/**
	 * Ouverture d'un aiguillage
	 *
	 * @param aiguillage Aiguillage à afficher
	 */
	openAiguillage(aiguillage: ProfilConnexionParam): void {
		//Dans le cas d'une ouverture d'un élément de la liste, on crée une copie pour éviter de modifier directement l'objet source
		if (aiguillage.idProfilConnexionParam > 0 || aiguillage.isMissingParam) {
			aiguillage = new ProfilConnexionParam(aiguillage);
		}

		//Ouverture de la popin de détail de l'aiguillage
		this.matDialog.open<AiguillageAddComponent,any,{isOnline: boolean,isOffline: boolean,isInterne: boolean,listeAiguillageOnline: Array<ProfilConnexionParam>}>(AiguillageAddComponent,{
				width: '600px',
				minWidth: 'auto',
				data: {
					aiguillage: aiguillage
				}
			}).afterClosed().subscribe((data) => {
				if (data) {
					//Mise à jour du profil de connexion suite à l'ajout / suppression d'un aiguillage
					this.profilConnexion.online = data.isOnline;
					this.profilConnexion.offline = data.isOffline;
					this.profilConnexion.interne = data.isInterne;

					//Mise à jour de la liste des configurations Online
					this.listeOnlineSBTConfig = this.getOnlineSBTConfig(data.listeAiguillageOnline);

					//Rechargement de la liste des aiguillages
					this.listeAiguillage.refresh();
				}
			});
	}

	/**
	 * Suppression de l'aiguillage
	 *
	 * @param idProfilConnexionParam Identifiant de l'aiguillage à supprimer
	 */
	deleteAiguillage(idProfilConnexionParam: number): void {
		let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Affichage de la popup d'attente
				matDialogRef = this.pleaseWaitService.show();

				//Suppression en base
				this.adminTravelhubService.deleteProfilConnexionParam(idProfilConnexionParam)
					.pipe(finalize(() => matDialogRef.close()))
					.subscribe(result => {
						//Vérification du résultat
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Message d'erreur
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Mise à jour du profil de connexion suite à l'ajout / suppression d'un aiguillage
							this.profilConnexion.online = result.data.isOnline;
							this.profilConnexion.offline = result.data.isOffline;
							this.profilConnexion.interne = result.data.isInterne;

							//Mise à jour de la liste des configurations Online
							this.listeOnlineSBTConfig = this.getOnlineSBTConfig(result.data.listeAiguillageOnline);

							//Rechargement de la liste des aiguillages
							this.listeAiguillage.refresh();
						} else {
							//Message d'erreur
							TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
						}
					},() => {
						//Message d'erreur
						TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
					});
			});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour à la liste des profils de connexion TH
	 */
	onGoBack(): void {
		this.router.navigate([AdminTravelhubService.URL_PROFIL_CONNEXION]);
	}

	/**
	 *
	 */
	isTabLoaded(code: string): boolean {
		return this.listeTabItems.find(tab => tab.code == code)?.isLoaded === true;
	}

}

/**
 * Enum pour les noms des différents onglets de la page Voyages/Travel Hub/Profil de connexion
 */
export enum OngletsProfilConnexion {
	GENERALITES = 'GENERALITES',
	USAGE = 'USAGE'
}