import {Component, Input, OnInit} from '@angular/core';
import {ListViewItem} from "../../../../domain/common/list-view/list-view-item";
import {Document} from "../../../../domain/profil/document";
import * as moment from "moment";

/**
 * Composant pour une ligne de la liste des documents
 */
@Component({
    selector: 'profil-donnees-document-list-item',
    templateUrl: './profil-donnees-document-list-item.component.html'
})
export class ProfilDonneesDocumentListItemComponent extends ListViewItem<Document> implements OnInit {
    /** Elément courant */
    @Input() data: Document;

    /** Options supplémentaires */
    @Input() extraOptions: any;

    /** État d'échéance proche */
    isSoonToExpire: boolean;

    /** État d'expiration */
    isExpired: boolean;

    /** Initialisation du composant */
    ngOnInit() {
        let momentToday: moment.Moment;
        let dateExpiration: moment.Moment;

        //Vérification de la date d'expiration
        if (this.data?.dateExpiration) {
            //Construction des objets Moment
            momentToday = moment().startOf('day');
            dateExpiration = moment(this.data.dateExpiration).startOf('day');

            //Calcul de l'état d'expiration
            this.isExpired = this.extraOptions?.settings?.alerteEchuActif && momentToday.isAfter(dateExpiration);
            this.isSoonToExpire = !this.isExpired && this.extraOptions?.settings?.alerteEcheanceActif && momentToday.add(this.extraOptions?.settings?.delaiAlerteEcheance, 'days').isSameOrAfter(dateExpiration);
        }
    }
}
