import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {Condition} from "@domain/workflow/condition";
import {ConditionVisibiliteListItemComponent} from "@components/admin/workflow/conditions/conditions-visibilite/liste/item/condition-visibilite-list-item.component";
import {BehaviorSubject} from 'rxjs';
import {FloatingButtonAction,TypeAction} from '@share/component/floating-button/floating-button';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {TypePortee} from "@domain/workflow/workflow";
import {Sorting} from "@domain/common/list-view/sorting";

/**
 * Composant de la liste des conditions de visibilité
 */
@Component({
	host: {'data-test-id': 'condition-visibilite-list'},
	templateUrl: './condition-visibilite-list.component.html'
})
export class ConditionVisibiliteListComponent implements OnInit {
	/** Liste des conditions de visibilité */
	listeConditions: ListView<Condition,ConditionVisibiliteListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param translateService le moteur de traduction
	 * @param router le routeur Angular
	 */
	constructor(
		private translateService: TranslateService,
		private router: Router
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des conditions de visibilité
		this.listeConditions = new ListView<Condition,ConditionVisibiliteListItemComponent>({
			title: this.translateService.instant('workflow.conditions.conditionsVisibilite.title'),
			uri: '/controller/Condition/getListeCondition',
			component: ConditionVisibiliteListItemComponent,
			defaultOrder: 'libelle',
			isFilter: true,
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('filter.libelle'),
					typeComparaison: TypeComparaison[TypeComparaison.LIKE],
					isDefault: true
				},{
					clef: 'idPortee',
					title: this.translateService.instant('filter.portee'),
					listeValues: [
						{
							code: TypePortee.AV,
							libelle: this.translateService.instant('portee.AV')
						},{
							code: TypePortee.DV,
							libelle: this.translateService.instant('portee.DV')
						},{
							code: TypePortee.FC,
							libelle: this.translateService.instant('portee.FC')
						},{
							code: TypePortee.NF,
							libelle: this.translateService.instant('portee.NF')
						},{
							code: TypePortee.OD,
							libelle: this.translateService.instant('portee.OD')
						},{
							code: TypePortee.OT,
							libelle: this.translateService.instant('portee.OT')
						}
					]
				}
			]
		});

		//Définition des colonnes de tri
		this.listeConditions.columns = [
			{key: 'libelle',title: 'filter.libelle'},
			{key: 'idPortee',title: 'filter.portee'}
		];

		//Ajout du tri de la liste
		this.listeConditions.sorting = new Sorting(this.listeConditions.columns,'libelle');

		//Persistence des filtres
		this.listeConditions.isPersistFilters = true;
		this.listeConditions.className = 'ConditionVisibiliteListComponent';

		//Définition de la liste des actions possibles
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-ajouter',
			libelle: 'global.actions.creer',
			doAction: () => this.createConditionVisibilite()
		}])
	}

	/**
	 * Appel à la création d'une condition de visibilité
	 */
	createConditionVisibilite(): void {
		//Redirection vers la page de création d'une condition de visibilité
		this.router.navigateByUrl('Admin/Workflow/Conditions/ConditionVisibilite/0');
	}
}
