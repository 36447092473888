<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': data.listeAlertes?.niveau == 1,'danger': data.listeAlertes?.niveau == 2,'masque': data.masque }" [nSelector]="{liste: liste, item: data}">
        <i class="nio icon-avance_2" style="vertical-align: middle;"></i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a [routerLink]="" (click)="navigateTo()">
                {{ data.idAvance || ('avance.liste.numeroNonDefini' | translate) }} - {{ data.libelleObjet || ('avance.liste.objetNonDefini' | translate) }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.idOd" (click)="openOd(data.idOd)" class="clickable">
                <strong [translate]="'avance.liste.om'"></strong>
                {{ data.idOd }}
                <mat-icon color="primary" style="font-size:1.1em;">open_in_new</mat-icon>
            </li>
            <li *ngIf="!isCollabMode"><strong [translate]="'avance.liste.collaborateur'"></strong>{{ data.getCollaborateur() }} - {{ data.user?.matricule }}</li>
            <li><strong [translate]="'avance.liste.type'"></strong>{{ ('avance.liste.type' + data.nature) | translate }}</li>
            <li *ngIf="data.dateStatut"><strong [translate]="'avance.liste.dateStatut'"></strong>{{ data.dateStatut | date:'shortDate' }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() > 1" [matTooltip]="data.getRoles()"><strong [translate]="'facture.liste.roles'"></strong>{{ data.getRolesCount() }}</li>
            <li *ngIf="!isCollabMode && data.getRolesCount() == 1"><strong [translate]="'facture.liste.role'"></strong>{{ data.getRoles() }}</li>
            <li *ngIf="data.listeAlertes" (click)="showListeAlertes()" class="clickable"
                [ngClass]="{'back-danger' : data.listeAlertes.niveau == 2, 'back-warning' : data.listeAlertes.niveau == 1}">
                <strong [translate]="'workflow.alerte.title'"></strong><span>{{ ('workflow.alerte.niveau.' + data.listeAlertes.niveau | translate) }}</span>
            </li>
        </ul>
    </div>
    <div class="info">
        <div>
            <statut [statut]="data.statut"></statut>
            <div class="text-right text-secondary font-weight-bold">
                {{ data.montantTotal | montant:data.devise }}
            </div>
        </div>
    </div>
</div>
