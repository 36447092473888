<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': isSoonToExpire,'danger': isExpired }">
        <i class="material-icons-outlined">card_travel</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="this.extraOptions.open(data)">{{data.carteReferentiel?.libelle}}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'profilUser.carteAbonnement.type'"></strong>
                <span>{{ getTypePrestationKey(data.carteReferentiel?.typePrestation) | translate }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.carteAbonnement.SUPPLIER'"></strong>
                <span>{{ data.carteReferentiel?.compagnie?.libelle }}</span>
            </li>
            <li *ngIf="data.dateValiditeDebut">
                <strong [translate]="'profilUser.carteAbonnement.debutValidite'"></strong>
                <span>{{ data.dateValiditeDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateValiditeFin">
                <strong [translate]="'profilUser.carteAbonnement.finValidite'"></strong>
                <span>{{ data.dateValiditeFin | date: 'shortDate' }}</span>
            </li>
            <li>
                <strong [translate]="'profilUser.actif.actif'"></strong>
                <span *ngIf="data.actif" [translate]="'global.oui'"></span><span *ngIf="!data.actif" [translate]="'global.non'"></span>
            </li>
            <li *ngIf="data.toDelete">
                <strong [translate]="'profilUser.document.toDelete'"></strong>
                <span [translate]="'profilUser.document.toDeleteInfo'"></span>
            </li>
            <li *ngIf="isSoonToExpire || isExpired" [ngClass]="{ 'back-warning': isSoonToExpire,'back-danger': isExpired }">
                <strong [translate]="'workflow.alerte.title'"></strong><span [translate]="'profilUser.carteAbonnement.alerte.' + (isExpired ? 'expired' : 'echeance')"></span>
            </li>
        </ul>
    </div>
</div>
