<ng-container>
    <fieldset>
        <div class="row">
            <label class="col-md-2 required" [ngClass]="{ 'has-error': origine.invalid, 'required': isFirstEtape  }">
                <span translate>ndf.frais.ajouterTournee.origine</span>
            </label>
            <div class="d-flex justify-content-center" [ngClass]="hasShowGestionHeureDetaillee() ? 'col-md-4': 'col-md-6'">
                <div [hidden]="!isFirstEtape">
                    <adresse
                            [name]="'origine'+index"
							#origine="ngModel"
                            [(ngModel)]="etape.localisationDepart"
                            (onSelect)="onLocalisationChange()"
                            [required]="true"
                            [panelWidth]="'500px'"
                            [disabled]="!canModifier"
                    ></adresse>
                </div>
                <div
                        *ngIf="!isFirstEtape"
                        class="pb-2">
                    <input matInput type="text" [value]="etape.localisationDepart?.libelle" [readonly]="true"/>
                </div>
            </div>
            <custom-input
                    *ngIf="hasShowGestionHeureDetaillee()"
                    ngDefaultControl
                    customType="heure"
                    [name]="'heureDebut'+index"
                    lCol="1"
                    rCol="2"
                    [libelle]="'ndf.frais.ajouterTournee.depart' | translate"
                    optionDisplay="libelle"
                    [(ngModel)]="etape.heureDebut"
                    [required]="true"
                    (onChange)="onHeureDebutChange()"
                    hourAfterOther
                    [isSameDateValid]="true"
                    [otherHour]="beforeEtapeheureFin"
                    [disabled]="!canModifier"
            ></custom-input>
            <custom-input
                    ngDefaultControl
                    customType="number"
                    [name]="'distance'+index"
                    lCol="1"
                    rCol="2"
                    [libelle]="('ndf.frais.ajouterTournee.distance' | translate)"
                    [(ngModel)]="etape.distance"
                    [required]="quantiteModifiable"
                    [readonly]="!quantiteModifiable"
                    [suffix]="unite.libelle"
                    [nbDecimales]="2"
                    [disabled]="!canModifier"
            ></custom-input>
        </div>
        <div class="row">
            <label class="col-md-2 required" [ngClass]="{ 'has-error': destination.invalid }">
                <span translate>ndf.frais.ajouterTournee.destination</span>
            </label>
            <div [ngClass]="hasShowGestionHeureDetaillee() ?'col-md-4': 'col-md-6'">
                <adresse
                        [name]="'destination'+index"
						#destination="ngModel"
                        [(ngModel)]="etape.localisationArrivee"
                        (onSelect)="onLocalisationChange(); onLocalisationArriveChanged();"
                        [panelWidth]="'500px'"
                        [required]="true"
                        [isSelectedValueRequired]="true"
                        [disabled]="!canModifier"
                ></adresse>
            </div>
            <custom-input
                    *ngIf="hasShowGestionHeureDetaillee()"
                    ngDefaultControl
                    customType="heure"
                    [name]="'heureFin'+index"
                    lCol="1"
                    rCol="2"
                    [libelle]="'ndf.frais.ajouterTournee.arrivee' | translate"
                    [(ngModel)]="etape.heureFin"
                    (onChange)="onHeureFinChange()"
                    [required]="true"
                    hourAfterOther
                    [isSameDateValid]="true"
                    [otherHour]="etape.heureDebut"
                    [disabled]="!canModifier"
            ></custom-input>
            <custom-input
                    *ngIf="etapesPersonnelles"
                    ngDefaultControl
                    customType="checkbox"
                    id="perso"
                    [name]="'perso'+index"
                    lCol="1"
                    rCol="2"
                    [libelle]="'ndf.frais.ajouterTournee.perso' | translate"
                    [ngModel]="etape.personnel" [(customModel)]="etape.personnel"
                    [postTooltip]="'ndf.frais.ajouterTournee.persoTooltip' | translate"
                    [disabled]="!canModifier"
            ></custom-input>
        </div>
    </fieldset>
</ng-container>
