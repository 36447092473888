import {Component} from "@angular/core";
import {DeviseItem} from "@domain/admin/bibliotheque/devises/deviseItem";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Composant pour afficher une devise dans la liste des devises
 */
@Component({
    host: {'data-test-id': 'devise-item'},
    templateUrl: './devise-item.component.html'
})
export class DeviseItemComponent extends ListViewItem<DeviseItem>{
}
