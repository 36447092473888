import { AnyARecord } from "dns";
import {ListItem} from "../common/list-view/list-item";
import {ListeAlertes} from "@domain/common/alerte/listeAlertes";


/**
 * Modèle représentant un élément de la liste mes frais
 */
export class ListeMesFrais implements ListItem {
    id: number;

    date: Date;
    ville: String;
    pays: String;
    montant: number;
    quantite: number;
    titre: String;
    devise: String;
    unite: String;
    type: TypeFrais;
    isSelected?: boolean;

    listeAlertes: ListeAlertes;

    getKey(): number {
        return this.id;
    }
}

/**
 * Enum des type de frais possibles
 */
export enum TypeFrais {
    frais = 0,
    rejete = 1,
    previsionnel = 2
}

/** 
 * Anomalie sur frais 
 */
export enum AlerteFrais {    
	ANOMALIE_PERIODE = "anomalie_periode",
	ANOMALIE_QUANTITE_AUTORISEE = "anomalie_quantite_autorisee", //Utiliser pour la fréquence
	ANOMALIE_MONTANT_NEGATIF = "anomalie_montant_negatif",
	ANOMALIE_DEPENSE_FUTURE = "anomalie_depense_futur",
	ANOMALIE_OM_PERMANENT_DATE = "anomalie_omp_date",
    ANOMALIE_OD_DATE = "anomalie_od_date"
}