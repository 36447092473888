import {Action} from '../domain/action';
import {SaisieState, SaisieStateTauxPrestation} from '../domain/saisie/saisie';

/**
 * Export des actions
 */
export const FIND_TAUX_FOR_PRESTATION = 'FIND_TAUX_FOR_PRESTATION';
export const UPDATE_TAUX_FOR_PRESTATION = 'UPDATE_TAUX_FOR_PRESTATION';

/**
 * Création du reducer
 */
export function saisieReducer(state: SaisieState = {},action: Action<SaisieStateTauxPrestation>): SaisieState {
    //Vérification de l'action
    switch (action.type) {
    case UPDATE_TAUX_FOR_PRESTATION:
        //Mise à jour de l'état
        state = {...state,tauxPrestation : action.payload};
        break;
    }

    //Retour de l'état
    return state;
}
