<div class="lvi-content">
    <div class="avatar" [ngClass]="statutClass">
        <mat-icon class="material-icons-outlined">{{ statutIcon }}</mat-icon>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label">{{ moduleItem.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.parametres.demat.modules.statut'"></strong>
                {{ statut | translate }}
            </li>
        </ul>
    </div>
</div>
