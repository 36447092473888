<div class="lvi-content">
  <div class="avatar" [ngClass]="{ 'warning': erreur }">
    <span>{{ pays.libelle | slice: 0:3 }}</span>
  </div>
  <div class="lvi-body">
    <div class="title">
      <a class="label">{{ pays.libelle }}</a>
    </div>
    <ul class="lvi-attrs">
      <li>
        <strong [translate]="'admin.bibliotheque.devises.infosDevise.gestionAvances.code2'"></strong>
        <span>{{ pays.code2 }}</span>
      </li>
      <li>
        <strong [translate]="'admin.bibliotheque.devises.infosDevise.gestionAvances.code3'"></strong>
        <span>{{ pays.code3 }}</span>
      </li>
      <li *ngIf="erreur" (click)="showListeAlertes()" class="clickable back-warning">
        <strong [translate]="'admin.bibliotheque.devises.infosDevise.taux.tagAlerte.title'"></strong><span [translate]="'admin.bibliotheque.devises.infosDevise.taux.tagAlerte.commentaire'"></span>
      </li>
    </ul>
  </div>
  <div class="lvi-actions">
    <button mat-icon-button [matMenuTriggerFor]="listeActions">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #listeActions="matMenu">
      <button mat-menu-item (click)="deletePays()" translate="global.actions.supprimer"></button>
    </mat-menu>
  </div>
</div>
