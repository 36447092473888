import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {User} from '@domain/user/user';

/**
 * Différents modes de cette popin
 */
export enum PasswordPopinMode {
	ACTIVATION,
	RESET_MDP
}

/**
 * Popin d'activation ou de reset du mot de passe d'un compte
 */
@Component({
	host: {'data-test-id': 'password-popin'},
	templateUrl: './password-popin.component.html'
})
export class PasswordPopinComponent {
	/** Import de l'énum pour le DOM */
	PasswordPopinMode = PasswordPopinMode;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popup
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { mode: PasswordPopinMode,user: User }
	) {
	}

}
