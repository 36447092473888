import {ListItem} from "../common/list-view/list-item";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {AbstractField} from "@domain/travel/abstract-field";

/**
 * CustomField / TripField configuré
 *
 * @author Laurent Convert
 * @date 19/12/2023
 */
export abstract class FieldUsed implements ListItem {
	sbtConfigUsed: TravelHubSBTConfigUsed;
	idValeur: number;
	valeur: string;
	requete: string;
	libelleCustomCode: string;

	/**
	 * Constructeur
	 *
	 * @param dto Source
	 */
	protected constructor(dto?: FieldUsed) {
		if (dto) {
			Object.assign(this,dto);
		}
	}

	/**
	 * Clé identifiant une ligne dans la liste
	 */
	abstract getKey(): number;

	/**
	 * Retourne l'identifiant du champ
	 */
	abstract getId(): number;

	/**
	 * Retourne le code du champ associé
	 */
	abstract getCode(): string;

	/**
	 * Getter du champ associé
	 */
	abstract get field(): AbstractField;

	/**
	 * Setter du champ associé
	 */
	abstract set field(field: AbstractField);
}