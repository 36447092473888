<div class="with-floating-button">
    <div class="content">
        <page-header [hidden]="isInDetail" [title]="'admin.bibliotheque.geographie.title' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)">
        </page-header>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.TERRITOIRES">
            <territoire-list></territoire-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.ZONES">
            <zone-list></zone-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.PAYS">
            <pays-list></pays-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.REGIONS">
            <region-list></region-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.DEPARTEMENTS">
            <departement-list></departement-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.VILLES">
            <ville-list></ville-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.ADRESSES">
            <adresse-geo-list></adresse-geo-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.DISTANCES">
            <distance-list></distance-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.IATA">
            <iata-list></iata-list>
        </div>

        <div *ngIf="!isInDetail && selectedItem?.code === Onglets.GEOLOCALISATION">
            <geolocalisation></geolocalisation>
        </div>
    </div>
</div>

<!-- Routeur pour afficher les pages enfants (le détail d'un objet géographie) -->
<router-outlet></router-outlet>
