import {Component} from '@angular/core';
import {AutocompleteLineItemComponent} from "../autocomplete-line-item.component";
import {TranslateService} from "@ngx-translate/core";
import {Od} from "@domain/od/od";

/**
 * Affichage d'une ligne de l'autocomplete des od
 *
 * @author Laurent Convert
 * @date 17/01/2023
 */
@Component({
	templateUrl: './od-line-item.component.html'
})
export class OdLineItemComponent extends AutocompleteLineItemComponent<Od> {
	
	/**
	 * Constructeur
	 *
	 * @param translateService Service de traduction
	 */
	constructor(public translateService: TranslateService) {
		super();
	}
	
}