import {ZoneUtilisateurSaisie} from './../zone-utilisateur/zone-utilisateur-saisie';
import {ZoneUtilisateur} from './../zone-utilisateur/zone-utilisateur';
import {OmZone} from "./omZone";
import {Analytique} from "../../components/analytique/analytique";
import {LieuOD} from "../lieu/lieuod";
import {ListeAlertes} from "../common/alerte/listeAlertes";
import {Statut} from "../workflow/statut";
import {TypeEntite,TypeEntiteOT} from "../typeentite/typeEntite";
import {GeographieView} from "../geographie/geographieView";
import {TypePortee} from "../workflow/workflow";
import {AbstractObjetWorkflow} from "../workflow/abstract-objet-workflow";
import {Transport} from "./transport";
import {MapAction} from "../workflow/mapAction";
import {Ville} from "../geographie/ville";
import {Pays} from "../geographie/pays";
import {TranslateService} from '@ngx-translate/core';
import { User } from '@domain/user/user';

/**
 * Ordre de mission permanent
 */
export class Omp extends AbstractObjetWorkflow {

    constructor(omp?:any) {
        super();
        Object.assign(this, omp);
        if (omp?.listeHistoWorkflow) { this.listeHistoWorkflow = new MapAction(omp.listeHistoWorkflow); }
    }

    idOMPermanent: number;
    idUser: number;
    idStatut: number;
    idTypeEntite: number;
    typeEntite: TypeEntite;
    objet: string;
    idVille: number;
    ville: Ville;
    idPays: number;
    pays: Pays;
    dateDebut: Date;
    dateFin: Date;
    heureDebut: string;
    heureFin: string;
    lieu: string;
    remarque: string;
    statut: Statut;
    listeTransports: Array<Transport>;
    listeZones: Array<OmZone>;
    listeAlertes: ListeAlertes;
    listeVentilations: Array<Analytique>;
    lieuDepart: number;
    lieuDepartOd: LieuOD;
    lieuRetour: number;
    lieuRetourOd: LieuOD;
    listeZU: ZoneUtilisateur[];
    listeZUSaisies: ZoneUtilisateurSaisie[];
    listeHistoWorkflow: MapAction;
    nbChilds: number;
    user: User;

    /* Attributs ajoutés lors du chargement d'un OMP pour faciliter l'utilisation dans les sous-composants */
    destination: GeographieView;

    getId(): number {
        return this.idOMPermanent;
    }

    getPortee(): TypePortee {
        return TypePortee.OT;
    }

    getListeAlertes(): ListeAlertes {
        return this.listeAlertes;
    }

    getListeVentilation(): Array<Analytique> {
        return this.listeVentilations;
    }

    getTypeEntite(): TypeEntite {
        return this.typeEntite as TypeEntiteOT;
    }

    setListeAlertes(listeAlertes: ListeAlertes): void {
        this.listeAlertes = listeAlertes;
    }

    getMapAction(): MapAction {
        return this.listeHistoWorkflow;
    }

    /**
     * Getter pour le collaborateur
     */
    getCollaborateur(): String {
        return null;
    }

    /**
     * Getter pour la liste de rôles format texte
     */
    getRoles(): string {
        return this.getMapAction()?.roles?.map(r => r.libelle).join("\n");
    }

    /**
     * Getter pour le nombre de rôles
     */
    getRolesCount(): number {
        return this.getMapAction()?.roles?.length;
    }

    /**
     * Getter pour l'ID Collab
     */
    getIdCollab(): number {
        return this.idUser;
    }

    /**
     * Getter pour l'intitulé de l'objet
     * @param translateService service de traduction
     */
    getLibelleObjet(translateService: TranslateService): string {
        return '' + (this.idOMPermanent || translateService.instant('omp.liste.numeroNonDefini')) + ' - ' + (this.objet || translateService.instant('omp.liste.objetNonDefini'));
    }

    /**
     * Getter pour la validité de l'objet
     */
    isValid(): boolean {
        //Hardcodé en attendant l'implémentation des données calculées
        return this.objet != null && this.destination != null;
    }
    
    /**
     * Retourne True si l'objet à au moins une mission ou une ndf liée, False sinon.
     */
    hasChilds(): boolean {
        return this.nbChilds > 0;
    }
}