import {EngagementsItem} from "../engagements-item";

/**
 * Engagements - Factures d'un OD
 */
export class EngagementsFacturesItem extends EngagementsItem {
    /** Type de l'item en cours */
    type: TypeEngagementFacture;

    /**
     * Constructeur
     *
     * @param item Item
     * @param isTotal Indicateur d'une ligne de total
     */
    constructor(item: EngagementsFacturesItem,isTotal?: boolean) {
        super(isTotal);
        Object.assign(this,item);
    }

    /**
     * Retourne le type de l'item courant
     */
    getType(): number {
        return this.type;
    }

    /**
     * Retourne la traduction
     *
     * @param previsionnel Indique si l'item est prévisionnel (sinon il est réel)
     */
    getTraduction(previsionnel?: boolean): string {
        //Vérification d'une ligne de total
        if (this.isTotal) {
            //Vérification de la colonne prévisionnelle ou non
            if (previsionnel) {
                //Prévisionnel
                return 'od.engagements.previsionnel';
            } else {
                //Réel
                return 'od.engagements.reel';
            }
        } else {
            //Libelle associé au type
            return 'facture.nature.' + TypeEngagementFacture[this.type]?.toLowerCase();
        }
    }
}

/**
 * Enumeration des types de facture
 */
export enum TypeEngagementFacture {
    INCONNUE = -1,
    TRAIN = 1,
    AVION = 2,
    HOTEL = 3,
    VOITURE = 4,
    DIVERS = 5,
    FRAIS = 6
}