<div *ngIf="!isLoaded">
    <please-wait class="content"></please-wait>
</div>

<div *ngIf="isLoaded">
    <!-- ce form est utilisé uniquement pour avoir le bon style -->
    <form autocomplete="off" name="form_collaborateur">

        <!-- Fiche collaborateur -->
        <mat-card>
            <mat-card-title>
                <span translate>ficheCollaborateur.title</span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <!-- Colonne de gauche -->
                    <div class="col-md-6">
                        <div class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.nom</span></label>
                            <div class="col-md-8"><span>{{fc.nom}}</span></div>
                        </div>
                        <div class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.prenom</span></label>
                            <div class="col-md-8"><span>{{fc.prenom}}</span></div>
                        </div>
                        <div *ngIf="fc.matricule" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.matricule</span></label>
                            <div class="col-md-8"><span>{{fc.matricule}}</span></div>
                        </div>
                        <div *ngIf="fc.email" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.email</span></label>
                            <div class="col-md-8"><span>{{fc.email}}</span></div>
                        </div>
                        <div *ngIf="fc.residencePersonnelle" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.residencePersonnelle</span></label>
                            <div class="col-md-8"><span>{{fc.residencePersonnelle}}</span></div>
                        </div>
                    </div>

                    <!-- Colonne de droite -->
                    <div class="col-md-6">
                        <div *ngIf="fc.societe" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.societe</span></label>
                            <div class="col-md-8"><span>{{fc.societe}}</span></div>
                        </div>
                        <div *ngIf="fc.service" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.service</span></label>
                            <div class="col-md-8"><span>{{fc.service}}</span></div>
                        </div>
                        <div *ngIf="fc.categorie" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.categorie</span></label>
                            <div class="col-md-8"><span>{{fc.categorie}}</span></div>
                        </div>
                        <div *ngIf="fc.titre" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.titre</span></label>
                            <div class="col-md-8"><span>{{fc.titre}}</span></div>
                        </div>
                        <div *ngIf="fc.residenceAdministrative" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.residenceAdministrative</span></label>
                            <div class="col-md-8"><span>{{fc.residenceAdministrative}} </span></div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <!-- Information complémentaires -->
        <mat-card *ngIf="fc.champ1 || fc.champ2 || fc.champ3 || fc.champ4 
            || fc.attribut1 || fc.attribut2 || fc.attribut3 || fc.attribut4">
            <mat-card-title>
                <span translate>ficheCollaborateur.informationComplementaires</span>
            </mat-card-title>
            <mat-card-content>
                <div class="row">
                    <!-- Colonne de gauche -->
                    <div class="col-md-6">
                        <div *ngIf="fc.champ1" class="row">
                            <label class="col-md-4"><span>{{fc.libelleChamp1}}</span></label>
                            <div class="col-md-8"><span>{{fc.champ1}}</span></div>
                        </div>
                        <div *ngIf="fc.champ2" class="row">
                            <label class="col-md-4"><span>{{fc.libelleChamp2}}</span></label>
                            <div class="col-md-8"><span>{{fc.champ2}}</span></div>
                        </div>
                        <div *ngIf="fc.champ2" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.attribut1</span></label>
                            <div class="col-md-8"><span>{{fc.attribut1}} </span></div>
                        </div>
                        <div *ngIf="fc.champ2" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.attribut2</span></label>
                            <div class="col-md-8"><span>{{fc.attribut2}} </span></div>
                        </div>
                    </div>
                            
                    <!-- Colonne de droite -->
                    <div class="col-md-6">
                        <div *ngIf="fc.champ1" class="row">
                            <label class="col-md-4"><span>{{fc.libelleChamp3}}</span></label>
                            <div class="col-md-8"><span>{{fc.champ3}}</span></div>
                        </div>
                        <div *ngIf="fc.champ2" class="row">
                            <label class="col-md-4"><span>{{fc.libelleChamp4}}</span></label>
                            <div class="col-md-8"><span>{{fc.champ4}}</span></div>
                        </div>
                        <div *ngIf="fc.champ2" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.attribut3</span></label>
                            <div class="col-md-8"><span>{{fc.attribut3}} </span></div>
                        </div>
                        <div *ngIf="fc.champ2" class="row">
                            <label class="col-md-4"><span translate>ficheCollaborateur.attribut4</span></label>
                            <div class="col-md-8"><span>{{fc.attribut4}} </span></div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>



