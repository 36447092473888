<!-- Panneau Indemnités -->
<od-frais-indemnites [od]="od" [canModifier]="canModifier" [canCompleter]="canCompleter" (onDegrevementsChanged)="onDegrevementsChanged.emit()"></od-frais-indemnites>

<!-- Panneau Notes de frais -->
<od-frais-ndf [od]="od"
              [canModifier]="canModifier || od?.canCompleter()"
              [canModifierFraisPrev]="canModifierFraisPrev()"
              (isFraisPrevChange)="isFraisPrevChange.emit($event);">
</od-frais-ndf>

<!-- Panneau Factures -->
<od-frais-factures
        [od]="od"
        [canModifier]="canModifier"
        [canModifierFacturePrev]="canModifierFacturePrev()"
        (isFraisPrevChange)="isFraisPrevChange.emit($event);"
></od-frais-factures>
