<div class="lvi-content" [ngClass]="{ clickable: data.listeChanges?.length > 0 }" (click)="data.listeChanges?.length > 0 ? isUnfolded = !isUnfolded : undefined">
    <div class="avatar">
        <i class="material-icons-outlined">history</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ ('admin.entreprise.utilisateurs.detail.historique.typeNature.' + data.nature) | translate }} : {{ data.reference }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.historique.origine'"></strong>
                <span [translate]="'admin.entreprise.utilisateurs.detail.historique.typeSourceLog.' + data.sourceLog"></span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.historique.date'"></strong>
                <span>{{ data.dateLog | date:'short' }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.historique.acteur'"></strong>
                <span>{{ data.acteur }}</span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.historique.typeModif'"></strong>
                <span [translate]="'admin.entreprise.utilisateurs.detail.historique.typeLog.' + data.typeLog"></span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.historique.zone'"></strong>
                <span [translate]="'admin.entreprise.utilisateurs.detail.historique.typeNature.' + data.nature"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="data.listeChanges?.length > 0">
        <button mat-icon-button>
            <mat-icon *ngIf="isUnfolded" color="primary">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!isUnfolded" color="primary">keyboard_arrow_down</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="isUnfolded" class="p-20">
    <form>
        <div *ngFor="let change of data.listeChanges">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <label class="col-md-3">
                            <span [translate]="'admin.entreprise.utilisateurs.detail.historique.clef.' + change.clef"></span>
                        </label>
                        <div class="col-md-9">
                            <span>{{ change.ancienneValeur }} --> {{ change.nouvelleValeur }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>