<h1 mat-dialog-title>
    <span translate>workflow.alerte.liste.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <div class="listview-item" *ngIf="data.alertes.isRejet">
                <div class="lvi-content">
                    <div class="avatar danger">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" [translate]="'vehicule.liste.alerte.rejet'" [translateParams]="{date: data.approbation?.dateAction | date: 'shortDate', collab: data.approbation?.user | user }"></div>
                            <div>{{data.approbation.motif}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listview-item" *ngIf="data.alertes.isMissingAssurance">
                <div class="lvi-content">
                    <div class="avatar danger">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" [translate]="'vehicule.liste.alerte.assuranceTitle'"></div>
                            <div [translate]="'vehicule.liste.alerte.assuranceBody'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listview-item" *ngIf="data.alertes.isMissingAutorisation">
                <div class="lvi-content">
                    <div class="avatar danger">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" [translate]="'vehicule.liste.alerte.autorisationTitle'"></div>
                            <div [translate]="'vehicule.liste.alerte.autorisationBody'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listview-item" *ngIf="data.alertes.isMissingCarteGrise">
                <div class="lvi-content">
                    <div class="avatar danger">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" [translate]="'vehicule.liste.alerte.carteGriseTitle'"></div>
                            <div [translate]="'vehicule.liste.alerte.carteGriseBody'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listview-item" *ngIf="data.alertes.isSoonToExpireAssurance">
                <div class="lvi-content">
                    <div class="avatar warning">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" [translate]="'vehicule.liste.alerte.assuranceTitle'"></div>
                            <div [translate]="'vehicule.liste.alerte.assuranceSoonBody'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listview-item" *ngIf="data.alertes.isSoonToExpireAutorisation">
                <div class="lvi-content">
                    <div class="avatar warning">
                        <i class="zmdi zmdi-alert-triangle"></i>
                    </div>
                    <div class="lvi-body">
                        <div class="title">
                            <div class="bold" [translate]="'vehicule.liste.alerte.autorisationTitle'"></div>
                            <div [translate]="'vehicule.liste.alerte.autorisationSoonBody'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
