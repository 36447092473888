<div class="lvi-content">
    <div class="avatar" [ngClass]="{ 'warning': alertes.isWarning(),'danger': alertes.isDanger() }">
        <i class="nio icon-voiture align-middle"></i>
    </div>
    <br/>
    <div class="lvi-body">
        <div class="title">
            <strong>
                <a [routerLink]="" (click)="navigateToVehicule(data)">{{ data.immatriculation }} - {{ data.marque }} {{ data.modele }}</a>
            </strong>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'vehicule.liste.user'"></strong>{{ data.user | user }}</li>
            <li><strong [translate]="'vehicule.liste.puissanceFiscale'"></strong>{{ data.puissanceFiscale?.libelle }}</li>
            <li><strong [translate]="'vehicule.liste.assurance'"></strong>{{ (data.assurePro ? 'global.oui' : 'global.non') | translate }}</li>
            <li translate><strong [translate]="'vehicule.liste.statut'"></strong>{{ getStatusLibelle(data.statut) }}</li>
            <li (click)="showListeWarning()" *ngIf=" alertes.isWarning() || alertes.isDanger()" [ngClass]="{ 'back-warning': alertes.isWarning(),'back-danger': alertes.isDanger() }" class="clickable">
                <strong [translate]="'workflow.alerte.title'"></strong><span [translate]="'vehicule.liste.alerte.documentManquant'"></span>
            </li>
        </ul>
    </div>
</div>
