import {Component,Inject,OnInit} from '@angular/core';
import {TourneeService} from "@components/ndf/ndf-details/ndf-frais/ndf-frais-add/ndf-frais-add-mes-frais/ndf-frais-add-tournee/tournee.service";
import {Tournee} from "@domain/Tournee/tournee";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {finalize} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

/**
 * Composant pour saisir le nom de la tournée
 */
@Component({
    host: {'data-test-id': 'name-dialog'},
    templateUrl: './name-dialog.component.html'
})
export class NameDialogComponent implements OnInit{
    /** La tournée */
    tournee: Tournee;

    /** Indique si un chargement est en cours */
    isLoading: boolean;

    /**
     * Constructeur
     */
    constructor(
        private tourneeService: TourneeService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private dialogRef: MatDialogRef<NameDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            tournee: Tournee
        }) {

    }

    /**
     * Initialisateur
     */
    ngOnInit(): void {
        this.tournee = this.data.tournee;

        //On initialise le libelle
        this.tournee.libelle = "";
    }

    /**
     * Handler du bouton confirmer
     */
    onConfirm() {
        this.isLoading = true;

        //On sauvegarde la tournée
        this.tourneeService.saveMemorized(this.tournee)
            .pipe(finalize(() =>{
                this.isLoading = false;
                this.dialogRef.close();
            }))
            .subscribe( {
                error: () => { this.toastrService.error(this.translateService.instant('global.errors.enregistrement'))}
            });
    }
}