import {Component, OnInit} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {ProfilAppMobileListItemComponent} from "./profil-app-mobile-list-item.component";
import {MobileDevice} from "@domain/profil/mobileDevice";
import {ProfilService} from "../../profil.service";
import {filter, first} from "rxjs/operators";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {Observable} from "rxjs/Rx";
import { BehaviorSubject,merge } from "rxjs";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";

/**
 * Composant de la page de gestion des périphériques mobiles
 */
@Component({
  selector: 'profil-app-mobile',
  templateUrl: './profil-app-mobile.component.html'
})
export class ProfilAppMobileComponent implements OnInit {

  /** Liste des onglets */
  listeTabItems: Array<PageHeaderItem>;

  /** Onglet sélectionné */
  selectedItem: PageHeaderItem = null;

  /** Liste des appareils connectés */
  liste: ListView<MobileDevice, ProfilAppMobileListItemComponent>;

  /** Liste des actions possibles */
  listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

  /** QR code d'appairage */
  qrCode: string;

  /** Flag pour désactiver le bouton flottant */
  isLoading: any;


  constructor(
      private activatedRoute: ActivatedRoute,
      private translateService: TranslateService,
      private router: Router,
      private profilService: ProfilService,
      private confirmService: ConfirmService,
      private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {

    //Définition des onglets
    this.listeTabItems = [{
      code: 'CONNECTED_DEVICES',
      libelle: this.translateService.instant('profilUser.navigation.mobileAppDevices')
    }, {
      code: 'CONNECT_DEVICE',
      libelle: this.translateService.instant('profilUser.navigation.mobileAddConnect')
    }];

    //Définition de la liste
    this.liste = new ListView<MobileDevice, ProfilAppMobileListItemComponent>({
      uri: `/controller/ProfilUser/searchMobile`,
      title: this.translateService.instant('profilUser.mobile.listTitle'),
      component: ProfilAppMobileListItemComponent,
      listeFilters: []
    });

    //Définition de la liste des actions
    this.listeActions.next([{
      type: TypeAction.SECONDARY,
      icone: 'nio icon-suppression',
      libelle: 'global.actions.supprimer',
      doAction: () => this.deleteMobileDevice(),
      isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected)
    }, {
      type: TypeAction.PRIMARY,
      icone: 'nio icon-validation',
      libelle: 'global.actions.valider',
      doAction: () => this.switchActivation(true),
      isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && !i.actif)
    }, {
      type: TypeAction.PRIMARY,
      icone: 'nio icon-rejet',
      libelle: 'global.actions.rejeter',
      doAction: () => this.switchActivation(false),
      isVisible: () => this.liste?.data?.listeResultats?.some(i => i.isSelected && i.actif)
    }]);

    //Chargement du QRCode
    this.profilService.loadQrCode().pipe(first()).subscribe(v => {
      this.qrCode = v.data?.token;
    });


  }

  /**
   * Changement d'onglet
   */
  onSelectedItemChange(selectedItem) {
    //Mise à jour de l'onglet sélectionné
    this.selectedItem = selectedItem;
  }

  /**
   * Retour arrière
   */
  onGoBack() {
    //Navigation vers la liste
    this.router.navigate(['Profil']);
  }

  /**
   * Suppression d'un device mobile
   */
  private deleteMobileDevice() {
    //On bloque les actions
    this.isLoading = true;

    //Création d'une liste d'observables pour les requêtes
    let listObservable: Array<Observable<any>> = [];

    //Message de confirmation
    this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
      next: () => {
        this.liste?.data?.listeResultats.filter(value => value.isSelected).forEach(value => {
          listObservable.push(this.profilService.deleteMobileDevice(value.idDevice));
        })
      }
    });

    //On resolve les observables ensemble
    merge(...listObservable).pipe(first()).subscribe({
      next: result => {
        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
          //Message de success
          this.toastrService.success(this.translateService.instant('global.success.suppression'));
        } else {
          //Message d'erreur
          this.toastrService.error(this.translateService.instant('global.errors.suppression'));
        }
        //Remise à zero du flag
        this.isLoading = false;
      }
    });
  }

  /**
   * Changement du statut d'activation d'un device
   * @param isActif true => activer / false => desactiver
   */
  private switchActivation(isActif: boolean) {
    //On bloque les actions
    this.isLoading = true;

    //Création d'une liste d'observables pour les requêtes
    let listObservable: Array<Observable<any>> = [];

    //Sauvegarde des devices
    this.liste?.data?.listeResultats.filter(value => value.isSelected && value.actif != isActif).forEach(value => {
      value.actif = !value.actif;
      listObservable.push(this.profilService.saveMobileDevice(value));
    });

    //On resolve les observables ensemble
    merge(...listObservable).pipe(first()).subscribe({
      next: result => {
        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
          //Message de success
          this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
        } else {
          //Message d'erreur
          this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
        }
        //Remise à zero du flag
        this.isLoading = false;
      }
    });
  }
}