import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Alerte, NiveauAlerte} from "@domain/common/alerte/alerte";
import {Proposition} from "@domain/od/proposition";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'popin-montant',
    templateUrl: 'od-proposition-montant-popin.component.html',
    styleUrls: ['od-proposition-montant-popin.component.scss']
})
export class PopinMontant {
    /** Enum pour accès dans le HTLM */
    NiveauAlerte = NiveauAlerte;

    /** Alerte à afficher si l'OD a une devise secondaire */
    alerte: Alerte;

    constructor(private translateService: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: { proposition: Proposition, devisePrimaire: string, deviseSecondaire?: string }) {
        //S'il y a une devise secondaire
        if (this.data.deviseSecondaire) {
            //On construit l'alerte
            this.alerte = {
                titre: null,
                message: this.translateService.instant('od.voyage.montantIndicatif', {devise: this.data.deviseSecondaire}),
                niveau: NiveauAlerte.WARNING
            }
        }
    }
}
