import {Component,Inject,OnInit} from '@angular/core';
import {ListView} from "@domain/common/list-view";
import {Compteur} from "@domain/ik/compteur";
import {CompteurListItemComponent} from "@components/admin/entreprise/utilisateurs/user-detail/tabs/user-metier/compteur-fiscal-popin/compteur-list-item/compteur-list-item.component";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {Result} from "@domain/common/http/result";

/**
 * Popin de l'historique des distances parcourues
 */
@Component({
	host: {'data-test-id': 'compteur-fiscal-popin'},
	templateUrl: './compteur-fiscal-popin.component.html',
	styles: [
		'::ng-deep .mat-dialog-without-margin .mat-dialog-container { padding: 0 }',
		'::ng-deep .mat-dialog-without-margin .mat-card { margin-bottom: 0 }'
	]
})
export class CompteurFiscalPopinComponent implements OnInit {
	/** Listes des compteurs */
	listeCompteur: ListView<Compteur,CompteurListItemComponent>;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param matDialogRef la ref à cette popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idUser: number,isBaremeMensuel: boolean,modeCalculIK: number },
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<CompteurFiscalPopinComponent>
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste
		this.listeCompteur = new ListView<Compteur,CompteurListItemComponent>({
			uri: `/controller/IK/showCompteurFiscal?idUser=${this.data.idUser}`,
			title: this.translateService.instant('admin.entreprise.utilisateurs.detail.metier.compteurFiscalPopin.title'),
			component: CompteurListItemComponent,
			isSimple: true,
			mapResult: (result: Result) => result?.data?.listeCompteur,
			listeActions: [
				{
					icon: 'close',
					onPress: () => this.matDialogRef.close()
				}
			],
			extraOptions: {
				isBaremeMensuel: this.data.isBaremeMensuel,
				modeCalculIK: this.data.modeCalculIK
			}
		});
	}
}
