import {TranslateService} from "@ngx-translate/core";

/**
 * Énumération des types de facture
 *
 * @author Laurent Convert
 * @date 18/01/2022
 */
export enum TypeFact {
    FACTURE = "FAC",
    AVOIR = "AVO",
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énuméré
 */
export namespace TypeFact {

    /**
     * Fonction de traduction des libellés de l'énuméré
     *
     * @param typeFact Le type de facture à traduire
     */
    export function traduction(typeFact: TypeFact): string {
        switch (typeFact) {
            case TypeFact.FACTURE: return 'od.frais.factures.types.facture';
            case TypeFact.AVOIR: return 'od.frais.factures.types.avoir';
        }
    }

    /**
     * Fonction de traduction des libellés de l'énuméré
     *
     * @param typeFact Le type de facture à traduire
     * @param translateService Service de traduction
     */
    export function icone(typeFact: TypeFact,translateService: TranslateService): string {
        return translateService.instant(traduction(typeFact)).substr(0,3);
    }
}