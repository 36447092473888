import { Component } from "@angular/core";

/**
 * Composant d'affichage de l'onglet voyages
 */
@Component({
    host: {'data-test-id': 'admin-voyages'},
    selector: 'admin-voyages',
    templateUrl: './admin-voyages.component.html'
})
export class AdminVoyagesComponent {
}