<mat-card>
    <mat-card-title>
        <span translate>od.generalites.faitGenerateur</span>
    </mat-card-title>
    <mat-card-content>
        <form autocomplete="off" name="form_generalites">
            <fieldset [disabled]="!canModifier">
                <div class="row">
                    <!-- Fait générateur -->
                    <label class="col-md-2 required" [ngClass]="{ 'has-error': faitGenerateur.invalid }"><span [translate]="'od.generalites.faitGenerateur'"></span></label>
                    <div class="col-md-4" [ngClass]="{ 'has-error': faitGenerateur.invalid }">
                        <mat-form-field class="d-flex" [floatLabel]="'never'">
                            <mat-select #faitGenerateur="ngModel" name="faitGenerateur" [(ngModel)]="od.idFaitGenerateur" required="true" [placeholder]="'global.input.choisissez' | translate"
                                        [disabled]="!canModifier">
                                <mat-option *ngFor="let fait of listeFaitGenerateurs" [value]="fait.idFait">{{ fait.libelle }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Date du fait -->
                    <label class="col-md-2 required" for="date_fait" [ngClass]="{ 'has-error': dateFait.invalid}"><span [translate]="'od.generalites.dateDuFait'"></span></label>
                    <div class="col-md-2" [ngClass]="{ 'has-error': dateFait.invalid}">
                        <mat-form-field class="d-flex">
                            <input id="date_fait" #dateFait="ngModel" name="date_fait" #date="ngModel" matInput [matDatepicker]="datePickerFait" [(ngModel)]="od.dateFaitGenerateur" required>
                            <mat-datepicker-toggle matSuffix [for]="datePickerFait"></mat-datepicker-toggle>
                            <mat-datepicker #datePickerFait [disabled]="!canModifier"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <!-- Référence du fait -->
                    <label class="col-md-2 required" for="referenceFait" [ngClass]="{ 'has-error': referenceFait.invalid }"><span [translate]="'od.generalites.refFait'"></span></label>
                    <div class="col-md-4" [ngClass]="{ 'has-error': referenceFait.invalid }">
                        <mat-form-field class="d-flex">
                            <input #referenceFait="ngModel" id="referenceFait" matInput name="reference_fait" [(ngModel)]="od.referenceFaitGenerateur" required/>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>
