<form autocomplete="off" name="form_zu">
    <div class="row ">
        <div class="col-md-6" *ngFor="let zu of listeZuModels; let index = index">
            <div class="row" *ngIf="zu.typeChamp != TypeZoneUtilisateur.CHECKBOX">
                <!-- Champ texte -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.TEXTE" ngDefaultControl [floatLabel]="floatLabel"
                    [(ngModel)]="zu.saisieTexte" customType="textarea" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle"
                    [required]="zu.obligatoire" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                    [readonly]="readonly" [maxlength]="200" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ montant -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.MONTANT" ngDefaultControl [floatLabel]="floatLabel"
                    [(ngModel)]="zu.saisieMontant" customType="montant" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle"
                    [required]="zu.obligatoire" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                    [readonly]="readonly" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ date -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.DATE" ngDefaultControl [floatLabel]="floatLabel"
                    [ngModel]="zu.saisieDate" [(customModel)]="zu.saisieDate" customType="date" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle"
                    [required]="zu.obligatoire" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                    [readonly]="readonly" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ heure -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.HEURE" ngDefaultControl [floatLabel]="floatLabel"
                    [(ngModel)]="zu.saisieHeure" customType="heure" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle"
                    [required]="zu.obligatoire" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                    [readonly]="readonly" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ liste unique -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_UNIQUE" ngDefaultControl [floatLabel]="floatLabel"
                    [(ngModel)]="zu.zuSelect" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle" [required]="zu.obligatoire"
                    optionValue="idZURef" optionDisplay="libelle" customType="objectselect" [isSelectAvecInput]="false"
                    [selectOptions]="zu.listeReferentiel" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)" 
                    [isSelectAvecVide]="true"
                    [readonly]="readonly" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ liste unique autre -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_UNIQUE_AUTRE" ngDefaultControl [floatLabel]="floatLabel"
                              [(ngModel)]="zu.zuSelect" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle" [required]="zu.obligatoire"
                              optionValue="idZURef" optionDisplay="libelle" customType="objectselect" [isSelectAvecInput]="true"
                              [selectInput]="zu.autreSelect" [selectOptions]="zu.listeReferentiel" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                              [isSelectAvecVide]="true" [readonly]="readonly"
                              [maxlength]="200" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ liste multiple -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_MULTIPLE" ngDefaultControl [floatLabel]="floatLabel"
                    [(ngModel)]="zu.listeZUSelects" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle"
                    [required]="zu.obligatoire" optionValue="idZURef" optionDisplay="libelle" customType="multiselect"
                    [isSelectAvecInput]="false" [selectOptions]="zu.listeReferentiel" [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                    [readonly]="readonly" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- champ liste multiple autre -->
                <custom-input *ngIf="zu.typeChamp == TypeZoneUtilisateur.LISTE_MULTIPLE_AUTRE" ngDefaultControl [floatLabel]="floatLabel"
                              [(ngModel)]="zu.listeZUSelects" [name]="'zu_'+zu.idZU" [libelle]="isFloatLabelAlways ? null : zu.libelle"
                              [required]="zu.obligatoire" optionValue="idZURef" optionDisplay="libelle" customType="multiselect"
                              [isSelectAvecInput]="true" [selectInput]="zu.autreSelect" [selectOptions]="zu.listeReferentiel"
                              [lCol]="isFloatLabelAlways ? 0 : 4" [rCol]="getColSize(zu)"
                              [maxlength]="200"
                              [readonly]="readonly" [placeholder]="isFloatLabelAlways ? zu.libelle : null">
                </custom-input>

                <!-- Icone tooltip -->
                <div class="col-md-1 p-l-0 align-content-center" *ngIf="zu.description">
                    <span class="material-icons-outlined" *ngIf="zu.description" [nioTooltip]="zu.description">
                        info
                    </span>
                </div>
            </div>

            <!-- Champ case à cocher -->
            <!-- Ce champ ne fait pas partie des customs inputs -->
            <div class="row" *ngIf="zu.typeChamp == TypeZoneUtilisateur.CHECKBOX">
                <ng-container *ngIf="floatLabel === 'never'; else tplCheckboxFloat;">
                    <div class="col-md-4">
                        <label class="p-t-15" [class.text-danger]="zu.obligatoire && !zu.saisieCheck">
                            <span>{{zu.libelle}}</span>
                            <span *ngIf="zu.obligatoire">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <div class="p-t-15 r-flex align-items-center">
                            <mat-checkbox color="primary" [name]="'zu_'+zu.idZU" [(ngModel)]="zu.saisieCheck" [disabled]="readonly"></mat-checkbox>
                            <!-- Icone tooltip -->
                            <span class="mb-2 p-l-15 material-icons-outlined" *ngIf="zu.description" [matTooltip]="zu.description">
                                info
                            </span>
                        </div>
                    </div>
                </ng-container>
                <ng-template #tplCheckboxFloat>
                    <div class="col-md-12">
                        <mat-form-field class="d-flex" appearance="none" [floatLabel]="floatLabel">
                            <mat-label [ngClass]="{ 'required': zu.obligatoire }">{{zu.libelle | translate}}</mat-label>
                            <mat-checkbox color="primary" [name]="'zu_'+zu.idZU" [(ngModel)]="zu.saisieCheck" [disabled]="readonly">{{ zu.description }}</mat-checkbox>
                            <!-- Input invisible présent uniquement pour faire le label flottant du mat-form-field -->
                            <input matInput class="cdk-visually-hidden" name="dummy" />
                        </mat-form-field>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</form>