<div class="with-floating-button">
    <div class="content" *ngIf="pays">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.geographie.pays.title' | translate: {libelle: pays.libelle}" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.pays.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="pays">
                <pays-generalites [pays]="pays" [isCreation]="isCreation"></pays-generalites>
            </form>
        </div>
        <floating-button [listeActions]="listeActions"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!pays"></please-wait>
</div>