import {Injectable,Pipe,PipeTransform} from '@angular/core';
import {Od} from "@domain/od/od";
import {TranslateService} from "@ngx-translate/core";

/**
 * Formatage d'un OD
 */
@Injectable({providedIn: 'root'})
@Pipe({name: 'od'})
export class OdPipe implements PipeTransform {
    
    constructor(private translateService: TranslateService) {
    }

    transform(od: Od): string {
        return OdPipeTransform(od,this.translateService);
    }

}

/**
 * Renvoie une chaine permettant d'identifier textuellement l'objet
 * 
 * @param od Objet
 * @param translateService Service de traduction
 */
export function OdPipeTransform(od: Od,translateService: TranslateService): string {
    if (od) {
        return '' + (od.idOd ?? translateService.instant('od.liste.numeroNonDefini')) + ' - ' + (od.objet || translateService.instant('od.liste.objetNonDefini'));
    } else {
        return '';
    }
}