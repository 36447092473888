import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CategorieVoyageur} from "@domain/travel/categorie-voyageur";
import {ReferentielCategorieVoyageurComponent} from "../referentiel-categorie-voyageur.component";
import {filter,finalize,first} from "rxjs/operators";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";

/**
 * Ligne de la liste du référentiel "Catégorie voyageur" de l'onglet "Référentiels" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'referentiel-categorie-voyageur-list-item'},
    templateUrl: './referentiel-categorie-voyageur-list-item.component.html'
})
export class ReferentielCategorieVoyageurListItemComponent extends ListViewItem<CategorieVoyageur>{
    /**
     * Constructeur
     *
     * @param pleaseWaitService Service d'affichage du loading
     * @param confirmService Service de confirmation utilisateur via modale
     * @param translateService Service de traduction
     */
    constructor(private pleaseWaitService: PleaseWaitService,
                private confirmService: ConfirmService,
                private translateService: TranslateService
    ) {
        super();
    }

    /** Options supplémentaires */
    @Input() public extraOptions: {
        parent: ReferentielCategorieVoyageurComponent,
    };

    /**
     * Suppression de la communauté
     */
    deleteCategorieVoyageur(): void {
        //Affichage de la confirmation de suppression
        this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
            .pipe(filter(isConfirmed => isConfirmed))
            .subscribe(() =>{
                //Modale de chargement
                let pleaseWaitRef = this.pleaseWaitService.show();

                //Suppression
                this.extraOptions.parent.deleteReferentiel(this.data)
                    .pipe(first(),finalize(() => pleaseWaitRef.close()))
                    .subscribe();
            });
    }

}