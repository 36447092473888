import {Component,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {SbtConfigComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config.component";
import {TypeConfig} from '@domain/voyage/travel/constants';
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {AlerteComponent} from "@components/workflow/alerte.component";
import {MatDialog} from "@angular/material/dialog";
import {NiveauAlerte} from "@domain/common/alerte/alerte";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant d'affichage d'une ligne de la liste des différentes configurations SBT
 *
 * @author Laurent Convert
 * @date 14/11/2023
 */
@Component({
    host: {'data-test-id': 'sbt-config-list-item'},
    templateUrl: './sbt-config-list-item.component.html'
})
export class SbtConfigListItemComponent extends ListViewItem<TravelHubSBTConfigUsed> {
    /* Déclaration pour accès dans le template */
    public readonly TypeConfig = TypeConfig;
    public readonly NiveauAlerte = NiveauAlerte;

    /**
     * Constructeur
     *
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param matDialog Boîte de dialogue
     * @param translateService Service de traduction
     */
    constructor(public adminTravelhubService: AdminTravelhubService, private matDialog: MatDialog, private translateService: TranslateService) {
        super();
    }

    /** Options supplémentaires */
    @Input() public extraOptions: {
        parent: SbtConfigComponent
    };

    /**
     * Affichage de la popup de la liste des alertes
     */
    showListeAlertes(): void {
        //Affichage de la popup de la liste des alertes
        this.matDialog.open(AlerteComponent,{
            data: {
                alertes: [...this.data.listeAlertes.listeAlertes].map(a => {
                    a.titre = this.translateService.instant(a.titre);
                    a.message = this.translateService.instant(a.message,a.traductionParams);
                    return a;
                })
            }
        });
    }

}