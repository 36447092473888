/**
 * Résultat d'une requête http
 */
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";

export interface Result<T = any> {
    /* Données */
    codeErreur: TypeCodeErreur;
    data: T;
}

/**
 * Enumération représentant les codes d'erreur
 */
export enum TypeCodeErreur {
    /* Enumération */
    NO_ERROR = 0,
    ERROR_SAVE = 1,
    ERROR_DELETE = 2,
    ERROR_DOWNLOAD = 3,
    ERROR_LOAD = 4,
    ERROR_LICENCE = 5,
    ERROR_DOUBLON = 6,
    ERROR_PERMISSION = 7,
    ERROR_ALREADY_USED = 900,
    ERROR_RGPD_SUSPENDED = 901,
    ERROR_DATE = 902,
    ERROR_VALIDATE_INPUT = 904,
}

/**
 * Extension de l'énumération TypeCodeErreur
 */
export namespace TypeCodeErreur {

    /**
     * Méthode d'affichage du message d'erreur associé au type.
     *
     * @param typeCodeErreur    Type de l'erreur dont on veut afficher le message
     * @param translateService  Service de traduction
     * @param toastrService     Service de Toast pour l'affichage
     */
    export function showError(typeCodeErreur: TypeCodeErreur, translateService: TranslateService, toastrService: ToastrService): void {
        let codeErreur;

        //On va chercher le libellé en fonction du code erreur
        switch (typeCodeErreur) {
            case TypeCodeErreur.ERROR_SAVE:
                codeErreur = 'global.errors.enregistrement';
                break;
            case TypeCodeErreur.ERROR_LOAD:
                codeErreur = 'global.errors.chargement';
                break;
            case TypeCodeErreur.ERROR_LICENCE:
                codeErreur = 'global.errors.quotaAtteint';
                break;
            case TypeCodeErreur.ERROR_PERMISSION:
                codeErreur = 'global.errors.permission';
                break;
            case TypeCodeErreur.ERROR_RGPD_SUSPENDED:
                codeErreur = 'profilUser.infoPerso.synchroPV.synchroImpossibleRGPD';
                break;
            case TypeCodeErreur.ERROR_DATE:
                codeErreur = 'global.errors.date';
                break;
            case TypeCodeErreur.ERROR_VALIDATE_INPUT:
                codeErreur = 'global.errors.formInvalid';
                break;
            case TypeCodeErreur.ERROR_DELETE:
                codeErreur = 'global.errors.suppression';
                break;
            case TypeCodeErreur.ERROR_ALREADY_USED:
                codeErreur = 'global.errors.utilise';
                break;
            default:
                codeErreur = 'global.errors.generic';
                break;
        }

        //On affiche l'erreur
        toastrService.error(translateService.instant(codeErreur));
    }


    /**
     * Indique si le code d'erreur est un type valide.
     *
     * @param code Code d'erreur à valider
     * @return {boolean} true si c'est un code d'erreur valide
     */
    export function isTypeCodeErreur(code: any): boolean {
        //On indique si le code envoyé fait partie des valeurs de l'enum TypeCodeErreur
        return Object.values(TypeCodeErreur).includes(code);
    }
}
