<div class="lvi-content">
    <div class="avatar">
        <i *ngIf="this.data.prestation.icone" class="material-icons">{{ data.prestation.icone }}</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a (click)="extraOptions.openDetail(data);">{{ data.prestation?.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li><strong [translate]="'facture.factureDepense.quantite'"></strong>{{ data.quantite | number:'1.2-2' }}</li>
            <li><strong [translate]="'facture.factureDepense.montant'"></strong>{{ data.montant | montant:extraOptions.facture.devise }}</li>
            <li><strong [translate]="'facture.factureDepense.taxes'"></strong>{{ data.taxePrimaire | montant:extraOptions.facture.devise }}</li>
            <li><strong *ngIf="!!data.nature" [translate]="'facture.factureDepense.nature'"></strong>{{ Nature.traduction(data.nature) | translate }}</li>
        </ul>
    </div>
</div>