<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons-outlined">groups</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onSelect()">
                {{ data.libelle }} {{ data.reference != null && data.reference != '' ? ' - ' + data.reference : '' }}
            </a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.reference">
                <strong [translate]="'admin.entreprise.populations.liste.reference'"></strong>
                <span>{{ data.reference }}</span>
            </li>
            <li *ngIf="data?.origine >= 0">
                <strong [translate]="'admin.entreprise.populations.liste.origine'"></strong>
                <span *ngIf="data.origine === Origine.ORIGINE_ADMINISTRATEUR" [translate]="'admin.entreprise.populations.liste.admin'"></span>
                <span *ngIf="data.origine === Origine.ORIGINE_IMPORT" [translate]="'admin.entreprise.populations.liste.import'"></span>
                <span *ngIf="data.origine === Origine.ORIGINE_USER" [translate]="'admin.entreprise.populations.liste.user'"></span>
            </li>
            <li *ngIf="data?.typeCreation >= 0">
                <strong [translate]="'admin.entreprise.populations.liste.typeCreation'"></strong>
                <span *ngIf="data.typeCreation === TypeCreation.TYPE_CREATION_STANDARD" [translate]="'admin.entreprise.populations.liste.standard'"></span>
                <span *ngIf="data.typeCreation === TypeCreation.TYPE_CREATION_VOLEE" [translate]="'admin.entreprise.populations.liste.volee'"></span>
            </li>
            <li *ngIf="data.actif != null">
                <strong [translate]="'admin.entreprise.populations.liste.actif'"></strong>
                <span>{{ (data.actif ? 'admin.entreprise.populations.liste.oui' : 'admin.entreprise.populations.liste.non' ) | translate }}</span>
            </li>
        </ul>
    </div>
</div>