import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {Tournee} from "@domain/Tournee/tournee";

/**
 * Service pour la gestion de la tournée
 */
@Injectable()
export class TourneeService {

    /**
     * Constructeur
     */
    constructor(private http: HttpClient) {
    }

    /**
     * Chargement d'une tournée
     *
     * @param idTournee id de la tournee
     * @param idPrestation id de la prestation
     */
    load(idTournee: number, idPrestation: number): Observable<Result> {
        return this.http.get<Result>(
            `${environment.baseUrl}/controller/Tournee/getTournee/${idTournee}/${idPrestation}`);
    }

    /**
     * Sauvegarde la mémorisation de la liste
     *
     * @param tournee la tournée courante
     */
    saveMemorized(tournee: Tournee): Observable<Result> {
        return this.http.put<Result>(
            `${environment.baseUrl}/controller/Tournee/saveMemorizedTournee`
            ,tournee);
    }
}