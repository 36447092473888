<mat-card>
  <mat-card-title>
            <span [translate]="'admin.bibliotheque.devises.infosDevise.deviseCard.title'">
            </span>
  </mat-card-title>
  <mat-card-content>
    <fieldset>
      <div class="row">
        <custom-input ngDefaultControl
                      id="code"
                      name="code"
                      [(ngModel)]="devise.code"
                      customType="input"
                      maxlength="3"
                      minlength="3"
                      libelle="admin.bibliotheque.devises.infosDevise.deviseCard.code"
                      [required]="true"
                      [readonly]="!isCreation"></custom-input>

        <custom-input ngDefaultControl
                      id="isActif"
                      name="isActif"
                      [ngModel]="devise.active"
                      [(customModel)]="devise.active"
                      customType="checkbox"
                      libelle="admin.bibliotheque.devises.infosDevise.deviseCard.actif"></custom-input>
      </div>
      <div class="row">
        <custom-input ngDefaultControl
                      id="libelle"
                      name="libelle"
                      [(ngModel)]="devise.libelle"
                      customType="input"
                      libelle="admin.bibliotheque.devises.infosDevise.deviseCard.libelle"
                      [required]="true"></custom-input>

        <custom-input ngDefaultControl
                      id="parite"
                      name="parite"
                      [(ngModel)]="devise.parite"
                      customType="select"
                      rCol="1"
                      [selectOptions]="optionsParite"
                      optionValue="code"
                      optionDisplay="libelle"
                      libelle="admin.bibliotheque.devises.infosDevise.deviseCard.parite"></custom-input>
      </div>
      <div class="row">
        <custom-input ngDefaultControl
                      id="commentaire"
                      name="commentaire"
                      [(ngModel)]="devise.commentaire"
                      customType="textarea"
                      rCol="10"
                      libelle="admin.bibliotheque.devises.infosDevise.deviseCard.commentaire"></custom-input>
      </div>
    </fieldset>
  </mat-card-content>
</mat-card>
