import {Component,Inject} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {RoleListe} from "@domain/workflow/roleListe";
import {TypeProfil} from "@domain/user/user";
import {Router} from "@angular/router";
import {WorkflowRolesService} from "@services/admin/workflow/workflow-roles.service";
import {TranslateService} from "@ngx-translate/core";
import {data} from "jquery";

/**
 * Item de liste des rôles
 *
 * @author Laurent SCIMIA
 * @date 12/06/2023
 */
@Component({
	host: {'data-test-id': 'roles-liste-item'},
	templateUrl: './roles-liste-item.component.html'
})
export class RolesListeItemComponent extends ListViewItem<RoleListe> {
	//Pour utilisation dans le HTML
	TypeProfil: typeof TypeProfil = TypeProfil;

	/**
	 * Constructeur
	 *
	 * @param router	Routeur Angular
	 * @param service	Service des rôles
	 */
	constructor(private router: Router,
				private translateService: TranslateService,
				@Inject('WORKFLOW_ROLE_SERVICE') private service: WorkflowRolesService<RolesListeItemComponent>) {
		super();
	}

	/** Ouverture du détail du rôle */
	onSelect() {
		this.router.navigate(['/Admin/Workflow/Roles/Roles/' + this.data.idRole]);
	}

	/**
	 * Récupère la classe de l'icône à afficher en fonction de la fonction
	 *
	 * @param fonction Fonction recherchée
	 */
	getIconeClass(fonction: number): string {
		return TypeProfil.getClasseIcone(TypeProfil[TypeProfil[fonction]]);
	}

	/**
	 * Récupère le libellé à afficher en fonction de la fonction
	 *
	 * @param fonction Fonction recherchée
	 */
	getTraductionProfil(fonction: number): string {
		return TypeProfil.getLibelle(this.translateService, TypeProfil[TypeProfil[fonction]]);
	}
}