import {TemplateRef} from "@angular/core";

/**
 * Alerte de PageHeader
 */
export class Alerte {
    niveau: NiveauAlerte;
    titre?: string;
    message?: string;
    //Icone à afficher
    icon?: string;
    /** Template angular à utiliser pour customiser ponctuellement l'affichage de l'alerte */
    template?: {tpl: TemplateRef<any>,ctx: any};
    type?: string;
    hasHtml?: boolean;
    traductionParams?: {};
    action?: () => void;

    /** Clef permettant d'identifier une alerte */
    getKey?(): string {
        return this.titre;
    }

    constructor(alerte?: Alerte) {
        if (alerte) {
            Object.assign(this,alerte);
        }
    }
}

/**
 * Niveau de l'alerte
 */
export enum NiveauAlerte {
    PROFIL_USER = -2,
    RGPD = -1,
    NO_CONTROL = 0,
    WARNING = 1,
    ERROR = 2,
    SUCCESS = 3
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré
 */
export namespace NiveauAlerte {

    /**
     * Retourne la classe CSS correspondent au niveau d'alerte
     *
     * @param niveau Le niveau d'alerte
     * @param back True pour mettre en couleur le fond d'un élément, False pour le texte
     */
    export function getClass(niveau: NiveauAlerte,back?: boolean): string {
        let css: string = '';

        //Vérification du niveau
        switch (niveau) {
            case NiveauAlerte.WARNING: css = 'warning'; break;
            case NiveauAlerte.ERROR:   css = 'danger'; break;
        }

        //Ajout de la classe 'back' suivant le paramètre
        return back && css ? 'back-' + css : css;
    }

}