import {ChangeDetectorRef,Component,Input,OnInit,TemplateRef,ViewChild} from '@angular/core';
import {Alerte} from "@domain/common/alerte/alerte";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'preview-alerte',
    templateUrl: './preview-alerte.component.html',
    styleUrls: ['./preview-alerte.component.scss']
})
export class PreviewAlerteComponent implements OnInit {
    /** Template de l'alerte */
    @ViewChild('alerteTemplate')
    public template: TemplateRef<any>;

    /** Alerte */
    @Input() alerte: Alerte;

    /** Active la traduction du titre et du message de l'alerte */
    @Input() translateAlerte: boolean = false;

    /** Indique si l'icone dois être grande */
    @Input() isLargeIcon: boolean = false;

    /** Titre de l'alerte */
    get titre(): string {
        let value;

        if (this.alerte?.type != null) {
            //Titre générique WF en fonction du type de l'alerte
            value = this.translateService.instant("workflow.alerte.type.titre." + this.alerte.type);
        } else if (this.alerte?.titre != null) {
            //Récupération du titre de l'alerte et traduction le cas échéant
            value = this.translateAlerte ? this.translateService.instant(this.alerte?.titre) : this.alerte?.titre;
        }

        return value;
    }

    /** Message de l'alerte */
    _message: string;
    @Input()
    set message(message: string) { this._message = message; }
    get message(): string {
        //Récupération du message défini à la main ou celui de l'alerte
        let value = this._message ?? this.alerte?.message;

        //Vérification du besoin de traduire le message
        if (!!value && typeof value == 'string' && this.translateAlerte) {
            //Traduction
            value = this.translateService.instant(value,this.alerte.traductionParams);
        } else if (!value && !!this.alerte?.type) {
            //Message générique WF en fonction du type de l'alerte
            value = this.translateService.instant("workflow.alerte.type.message." + this.alerte.type);
        }

        return value;
    }

    /** Affichage de l'icône alerte */
    @Input() withIcon: boolean | string = true;

    /** Affichage de l'icône RGPD */
    @Input() withRGPDIcon: boolean = false;

    /** Affichage de l'icône Accueil */
    @Input() withAccueilIcon: boolean = false;

    /** Affichage du message comme element html */
    @Input() htmlMessage: boolean = false;

    /**
     * Constructeur
     */
    constructor(private changeDetectorRef: ChangeDetectorRef,private translateService: TranslateService) {
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Détection des changements
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Retourne true si on doit afficher le message au format html
     */
    hasHtml(): boolean {
        return this.htmlMessage || this.alerte?.hasHtml;
    }

}
