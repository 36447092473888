/**
 * Mode de gestion des participants
 */
export enum ModeParticipants {
	TOUS = 0,
	INTERNES = 1,
	EXTERNES = 2,
	AUCUN = 3,
	DEFAUT = 4
}
