import {Component,Input,OnInit} from '@angular/core';
import {ReleveFacture} from "@domain/facture/releve-facture";
import {Filter,ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {FactureAnomalieListItemComponent} from "../facture-anomalie-list/facture-anomalie-list-item/facture-anomalie-list-item.component";
import {FactureErreurImport} from "@domain/facture/facture-erreur-import";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@domain/common/http/list-result";
import {FactureAnomalieDetailComponent} from "../facture-anomalie-detail.component";
import {MatDialog} from "@angular/material/dialog";
import {Sorting} from '@domain/common/list-view/sorting';
import {ListViewService} from "@share/component/list-view/list-view.service";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {ReleveFactureService} from "@components/facture/releve-facture.service";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {finalize} from "rxjs/operators";

/**
 * Composant d'affichage de la liste des anomalies (rejets d'import)
 *
 * @author Laurent Convert
 * @date 03/07/2023
 */
@Component({
	host: {'data-test-id': 'facture-anomalie-list'},
	selector: 'facture-anomalie-list',
	templateUrl: './facture-anomalie-list.component.html'
})
export class FactureAnomalieListComponent implements OnInit {
	/** Relevé facture */
	@Input() releveFacture: ReleveFacture = null;

	/** Liste des anomalies */
	liste: ListView<FactureErreurImport,FactureAnomalieListItemComponent>;

	/** Liste des actions possibles pour le floatingButton en bas à droite */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Action en cours  */
	isPending: boolean;

	/**
	 * Constructeur
	 *
	 * @param matDialog Référence à la popup elle-même
	 * @param translateService Service de traduction
	 * @param toastrService Service de distribution de toasts !
	 * @param listViewService Service des listes
	 * @param releveFactureService Service des relevés de facture
	 */
	constructor(private matDialog: MatDialog,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private listViewService: ListViewService<FactureErreurImport,FactureAnomalieListItemComponent>,
				private releveFactureService: ReleveFactureService) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Création de la liste des devises
		this.liste = new ListView<FactureErreurImport,FactureAnomalieListItemComponent>({
			uri: `/controller/Facture/${this.releveFacture.idFactureReleve}/listeFactureAnomalie`,
			title: this.translateService.instant('facture.releve.anomalie.title'),
			component: FactureAnomalieListItemComponent,
			mapResult: (result: Page<FactureErreurImport>): Page<FactureErreurImport> => {
				result.listeResultats = result.listeResultats.map(fa => new FactureErreurImport(fa));
				return result;
			},
			extraOptions: {
				//Bind de la méthode d'ouverture d'une erreur
				openAnomalie: this.openAnomalie.bind(this)
			},
			isFilter: true,
			defaultOrder: '-idErreurImport',
			listeFilters: [
				{
					clef: 'isMasque',
					title: this.translateService.instant('facture.releve.anomalie.filtres.masquees'),
					isDefault: false,
					isSelected: true,
					hiddenChip: true,
					valeur: 'false',
					onloadValue: 'false',
					type: TypeFilter[TypeFilter.BOOLEAN],
					typeComparaison: TypeComparaison[TypeComparaison.EQUAL],
					selectMethod: (filter: Filter) => {
						//Le filtre activé n'est visible que si on veut tout voir
						filter.hiddenChip = filter.valeur == 'false';
					},
					removeMethod: (filter: Filter) => {
						//Lors de la suppression du filtre, on le remet à son état par défaut : activé mais masqué avec la valeur 'false'
						filter.isSelected = true;
						filter.hiddenChip = true;
						filter.valeur = 'false';

						//Rafraichissement de l'affichage des filtres
						this.listViewService.refreshListeSelectedFilters(this.liste);
					},
				}, {
					clef: 'numeroFacture',
					title: this.translateService.instant('facture.releve.anomalie.numero'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				}
			]
		});

		//Persistence des filtres
		this.liste.isPersistFilters = true;

		//Nom de la classe
		this.liste.className = 'FactureAnomalieListComponent';

		//Rafraichissement des filtres sélectionnés par défaut
		this.listViewService.refreshListeSelectedFilters(this.liste);

		//Définition des colonnes de tri
		this.liste.columns = [
			{ key: 'idErreurImport', title: 'facture.releve.anomalie.filtres.triParDefaut' },
			{ key: 'numeroFacture', title: 'facture.releve.anomalie.numero' },
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.liste.sorting = new Sorting(this.liste.columns,this.liste.defaultOrder);
	}

	/**
	 * Initialisation de la liste des actions du floating button
	 */
	initListeActions() {
		//Définition de la liste des actions du bouton en bas à droite de l'écran
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-masquer',
			libelle: 'global.actions.masquer',
			doAction: () => this.masquerEnMasse(true),
			//Action de masquage visible si au moins l'un des éléments sélectionnés n'est pas encore masqué
			isVisible: () => this.liste.listeObjetsSectionnes?.length > 0 && this.liste.listeObjetsSectionnes.some(anomalie => !anomalie.masque)
		},{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-demasquer',
			libelle: 'global.actions.demasquer',
			doAction: () => this.masquerEnMasse(false),
			//Action de démasquage visible si tous les éléments sélectionnés sont masqués
			isVisible: () => this.liste.listeObjetsSectionnes?.length > 0 && this.liste.listeObjetsSectionnes.every(anomalie => anomalie.masque)
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-tout_selectionner',
			libelle: 'liste.actions.selectionnerElementsAffiches',
			doAction: () => this.selectAll(true),
			isVisible: () => this.liste?.data?.listeResultats.some(i => i.isSelected)
		},{
			type: TypeAction.SECONDARY,
			icone: 'nio icon-annuler_selection',
			libelle: 'liste.actions.toutDeselectionner',
			doAction: () => this.selectAll(false),
			isVisible: () => this.liste?.data?.listeResultats.some(i => i.isSelected)
		}]);
	}

	/**
	 * Affiche l'anomalie
	 *
	 * @param factureAnomalie Anomalie à afficher
	 */
	openAnomalie(factureAnomalie: FactureErreurImport) {
		//Ouverture de la popin
		this.matDialog.open<FactureAnomalieDetailComponent,any,boolean>(FactureAnomalieDetailComponent,{
			data: {
				idErreurImport: factureAnomalie.idErreurImport
			},
			minWidth: 1000
		}).afterClosed().subscribe(refresh => {
			//Vérification de la nécessité de rafraichir la liste
			if (refresh) {
				//Rafraichissement de la liste des factures importées en anomalies
				this.liste.refresh();

				//Rafraichissement de la liste des factures
				this.releveFactureService.refreshListeFactures();
			}
		});
	}

	/**
	 * Masque en masse les éléments sélectionnés
	 *
	 * @param masquer True : masquer. False : démasquer.
	 */
	masquerEnMasse(masquer: boolean): void {
		//Début du traitement
		this.isPending = true;

		//Lancement du traitement
		this.releveFactureService.hideError(masquer,this.getListeIdAnomalieSectionnees()).pipe(finalize(() => this.isPending = false)).subscribe(res => {
			//Vérification du résultat
			if (res?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Affichage du message de succès
				this.toastrService.success(this.translateService.instant('global.success.generic'));

				//Rafraichissement de la liste
				this.liste.refresh();
			} else {
				//Affichage d'un message d'erreur
				TypeCodeErreur.showError(res?.codeErreur,this.translateService,this.toastrService);
			}
		}, () => {
			//Affichage d'un message d'erreur générique
			TypeCodeErreur.showError(null,this.translateService,this.toastrService);
		});
	}

	/**
	 * Récupère la liste des identifiants des anomalies sélectionnées
	 */
	getListeIdAnomalieSectionnees(): Array<number> {
		return this.liste.listeObjetsSectionnes?.map(anomalie => anomalie.idErreurImport);
	}

	/**
	 * Sélection des éléments de la liste
	 *
	 * @param isSelect est-ce qu'on sélectionne ou désélectionne
	 */
	private selectAll(isSelect: boolean): void {
		this.liste?.selectAll(isSelect);
	}

}
