<fieldset>
    <mat-card>
        <mat-card-title>
            <span [translate]="'admin.bibliotheque.geographie.ville.title'" [translateParams]="{libelle: ''}"></span>
        </mat-card-title>
        <mat-card-content>
            <fieldset>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="libelle"
                                  name="libelle"
                                  [(ngModel)]="ville.libelle"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.ville.libelle"
                                  [required]="true"
                                  [maxlength]="200">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="actif"
                                  name="actif"
                                  [ngModel]="ville.actif"
                                  [(customModel)]="ville.actif"
                                  customType="checkbox"
                                  libelle="admin.bibliotheque.geographie.ville.actif">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="pays"
                                  name="pays"
                                  [idName]="'id'"
                                  optionValue="id"
                                  [(ngModel)]="selectedPays"
                                  libelle="admin.bibliotheque.geographie.ville.pays"
                                  (onChange)="onPaysChange();"
                                  optionDisplay="libelle"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  [required]="true"
                                  [autocompleteFilter]="{ listeTypes: ['PAYS'] }">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="libelleNettoye"
                                  name="libelleExterne"
                                  [(ngModel)]="ville.libelleNettoye"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.ville.libelleExterne"
                                  [postTooltip]="'admin.bibliotheque.geographie.ville.libelleExterneTooltip' | translate"
                                  [disabled]="true"
                                  [required]="true">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="region"
                                  name="region"
                                  [idName]="'id'"
                                  optionValue="id"
                                  [(ngModel)]="selectedRegion"
                                  libelle="admin.bibliotheque.geographie.ville.region"
                                  (onChange)="onRegionChange();"
                                  optionDisplay="libelle"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  [autocompleteFilter]="{ listeTypes: ['REGION_ADMINISTRATIVE'] }">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="departement"
                                  name="departement"
                                  [idName]="'id'"
                                  optionValue="id"
                                  [(ngModel)]="selectedDepartement"
                                  libelle="admin.bibliotheque.geographie.ville.departement"
                                  (onChange)="onDepartementChange();"
                                  optionDisplay="libelle"
                                  customType="autocomplete"
                                  autocompleteType="geographie"
                                  [autocompleteFilter]="{ listeTypes: ['DEPARTEMENT_ADMINISTRATIF'] }">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="codeIATA"
                                  name="codeIATA"
                                  [(ngModel)]="ville.codeIATA"
                                  customType="input"
                                  maxlength="3"
                                  libelle="admin.bibliotheque.geographie.ville.codeIata">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="longitude"
                                  name="longitude"
                                  [(ngModel)]="ville.longitude"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.ville.longitude">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="codeGare"
                                  name="codeGare"
                                  [(ngModel)]="ville.codeGare"
                                  customType="input"
                                  maxlength="5"
                                  libelle="admin.bibliotheque.geographie.ville.codeGare">
                    </custom-input>
                    <custom-input ngDefaultControl
                                  id="latitude"
                                  name="latitude"
                                  [(ngModel)]="ville.latitude"
                                  customType="input"
                                  libelle="admin.bibliotheque.geographie.ville.latitude">
                    </custom-input>
                </div>
                <div class="row">
                    <custom-input ngDefaultControl
                                  id="codeKDS"
                                  name="codeKDS"
                                  [(ngModel)]="ville.codeKDS"
                                  customType="input"
                                  maxlength="10"
                                  libelle="admin.bibliotheque.geographie.ville.codeKds">
                    </custom-input>
                </div>
            </fieldset>
        </mat-card-content>
    </mat-card>
</fieldset>
