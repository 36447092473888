import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ParticipantAvecFavoris} from "../../../../domain/participant/participant-avec-favoris";

/**
 * Onglet des participants internes dans la popin des participants d'un OD ou d'une Dépense
 *
 * @author Laurent Convert
 * @date 07/03/2022
 */
@Component({
	host: {'data-test-id': 'participants-popin-interne'},
	selector: 'participants-popin-interne',
	templateUrl: './participants-popin-interne.component.html'
})
export class ParticipantsPopinInterneComponent {
	/** Identifiant de l'OD / Dépense */
	@Input() idObjet: number;

	/** Identifiant de la ndf (dans le cas d'une dépense) */
	@Input() idNDF: number;

	/** Identifiant de la prestation (dans le cas d'une dépense) */
	@Input() idPrestation: number;

	/** Contexte OD / Dépense */
	@Input() contexte: 'OD' | 'NDF_V';

	/** Évènement d'ajout d'un participant */
	@Output() onParticipantCree: EventEmitter<ParticipantAvecFavoris> = new EventEmitter<ParticipantAvecFavoris>();

	/** Participant à ajouter */
	participant: string;

	/** Ajout d'un participant */
	addParticipant($event: ParticipantAvecFavoris) {
		//Vérification de la présence d'un participant (le "onSelect" de l'autocomplete est appelé lorsque le champ est vidé)
		if ($event) {
			//Ajout du participant
			this.onParticipantCree.emit($event);
		}
	}
}