<div class="with-floating-button">
    <div class="content" *ngIf="langue && !!listeVariant">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.internationalisation.infosLangue.title' | translate:{ libelle: langue.libelle }" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.internationalisation.infosLangue.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div *ngIf="!isCreation" [hidden]="selectedItem?.code !== Onglet.LIBELLES">
            <mat-spinner diameter="20" *ngIf="isPending"></mat-spinner>
            <langue-infos-libelle *ngIf="!isPending" [langue]="langue"></langue-infos-libelle>
        </div>

        <div [hidden]="selectedItem?.code !== Onglet.CONFIGURATION">
            <langue-infos-configuration [langue]="langue" [listeVariant]="listeVariant" [selectedFile]="selectedFile" (fileSelected)="onFileSelected($event)" [custom]="custom" (isFormValid)="isFormValid = $event"></langue-infos-configuration>

            <floating-button *ngIf="!!listeVariant" [isPending]="isPending" [listeActions]="listeActions"></floating-button>
        </div>
    </div>
    <mat-spinner diameter="20" *ngIf="!langue || !listeVariant"></mat-spinner>

</div>
