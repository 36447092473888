import {Component,OnDestroy,OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {Subscription} from "rxjs";
import {MaintenanceTask} from "@domain/admin/maintenance/maintenance-task";
import {ListView,TypeComparaison} from "@domain/common/list-view";
import {TaskItemComponent} from "@components/admin/maintenance/migrations/maintenance/task-item.component";
import {Page} from "@domain/common/http/list-result";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

/**
 * Composant d'affichage des logs
 */
@Component({
    host: {'data-test-id': 'log'},
    templateUrl: './liste-migration-popup.component.html',
    styleUrls: ['liste-migration-popup.component.scss']
})
export class ListeMigrationPopupComponent implements OnInit,OnDestroy {

    /** Liste des tâches en cours */
    listeTasks: string[];

    /** Souscription */
    souscriptions: Subscription[] = [];

    /** Maintenance en cours */
    isMaintenanceEnCours: boolean;

    /** Liste des tâches, pour utiliser le composant ListViewComponent */
    liste: ListView<MaintenanceTask, TaskItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        protected translateService: TranslateService,
        protected matDialogRef: MatDialogRef<ListeMigrationPopupComponent>,
        private migrationsService: MigrationsService,
        private router: Router
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Abonnement à la mise à jour de la maintenance en cours
        this.souscriptions.push(this.migrationsService.isMaintenanceEnCours$.subscribe((isMaintenanceEnCours) => {
            //Mise à jour de la maintenance en cours
            this.isMaintenanceEnCours = isMaintenanceEnCours;

            //Si la MAJ est terminée
            if (!this.isMaintenanceEnCours) {
                //Fermeture de la popup
                this.matDialogRef.close();
            }
        }));

        //Récupération des migrations actives
        this.listeTasks = this.migrationsService.getListeActiveMigrations();

        //Création de la liste des tâches
        this.liste = new ListView<MaintenanceTask, TaskItemComponent>({
            uri: `/controller/Maintenance/listeTask`,
            component: TaskItemComponent,
            mapResult: (result: Page<MaintenanceTask>): Page<MaintenanceTask> => {
                //Transformation en instance de chaque item puis tri par ordre d'exécution
                result.listeResultats = result.listeResultats.map(t => new MaintenanceTask(t)).sort((a, b) => {
                    //Récupération des index à comparer
                    const indexA = this.listeTasks.indexOf(a.taskName);
                    const indexB = this.listeTasks.indexOf(b.taskName);

                    //Si les deux éléments existent dans la liste de référence, on les compare par leur index
                    if (indexA !== -1 && indexB !== -1) { return indexA - indexB; }

                    //Si l'un des éléments n'existe pas dans la liste de référence, celui qui existe est priorisé
                    if (indexA !== -1) { return -1; }
                    if (indexB !== -1) { return 1; }

                    //Si aucun des deux éléments n'existe dans la liste de référence, leur ordre reste inchangé
                    return 0;
                });

                //Retour
                return result;
            },
            defaultOrder: 'taskName',
            nbObjetsParPage: 10000,
            extraOptions: { isPopup: true }
        });

        //Filtre par défaut de la liste
        this.liste.listeSelectedFilters = [{
            clef: 'taskName',
            typeComparaison: TypeComparaison[TypeComparaison.IN],
            listeObjects: this.migrationsService.getListeActiveMigrations()
        }];

        //Nom de la liste
        this.liste.className = "ListeMigrationPopupComponent";
    }

    /**
     * Navigation vers la page des migrations
     */
    navigateToMaintenance(): void {
        //Navigation vers la liste
        this.router.navigate(['Admin/Maintenance/Migrations/Maintenance']);

        //Fermeture de la popup
        this.matDialogRef.close();
    }

    /**
     * Destruction du composant
     */
    ngOnDestroy() {
        //Parcours des souscriptions
        for (const sub of this.souscriptions) {
            //Désabonnement
            sub.unsubscribe();
        }
    }
}
