import {Component} from '@angular/core';

import {AutocompleteSearchItemComponent} from '../autocomplete-search-item.component';
import {EnumEtat} from "@domain/workflow/etat";

/**
 * Composant d'affichage d'une ligne dans la popup de recherche avancée 'Statut'
 */
@Component({
	templateUrl: './statut-search-item.component.html',
})
export class StatutSearchItemComponent extends AutocompleteSearchItemComponent<any> {
	/** Accès à l'enum dans la vue */
	readonly EnumEtat = EnumEtat;
}
