<div class="r-flex">
    <div class="avatar">
        <mat-icon class="icon-centered">{{ prestation.icone }}</mat-icon>
    </div>
    <div class="body">
        <label>{{ prestation.libelle }}</label>
        <table *ngIf="prestation.vehicule != null || prestation.famille != null">
            <tr *ngIf="prestation.vehicule != null">
                <td><strong>{{ 'ndf.prestation.vehicule' | translate }}</strong></td>
                <td>{{ prestation.vehicule.immatriculation }}</td>
            </tr>
            <tr *ngIf="prestation.famille != null">
                <td><strong>{{ 'ndf.prestation.famille' | translate }}</strong></td>
                <td>{{ (prestation.famille.code ? prestation.famille.code + ' - ' : '') + prestation.famille.libelle }}</td>
            </tr>
        </table>
    </div>
</div>