/**
 * Représente un jour d'une semaine du calendrier des indemnités
 */
import { Regle } from "./regle";

export class IjWeekday {
    /** Données */
    dayOfWeek: number = 0;
    dateString: string = null;
    regles: Array<Regle>;
    ferie: boolean = false;

    date: string = null; //Numéro du jour dans le mois, formaté sur 2 chiffres

    /**
     * Constructeur
     *
     * @param dayOfWeek Jour de la semaine (du lundi au dimanche)
     */
    constructor(dayOfWeek?: number) {
        this.dayOfWeek = dayOfWeek;
    }
}