import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {first} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {TrajetLibelle} from "@domain/Trajet/TrajetLibelle";
import {TrajetType} from "@domain/Trajet/ListeTrajet";

/**
 * Service pour les trajets
 */
@Injectable({
    providedIn: "root",
})
export class TrajetService {

    /** Le constructeur */
    constructor(private http: HttpClient) {}

    /**
     * Met à jour le libellé
     * @param trajetLibelleDto
     */
    updateLibelle(trajetLibelleDto: TrajetLibelle): Observable<Result> {
        return this.http.post<Result>(`${environment.baseUrl}/controller/Trajet/updateLibelle`,trajetLibelleDto).pipe(
            first()
        );
    }

    /**
     * Supprime le trajet
     * @param type type du trajet
     * @param id id du trajet
     */
    delete(id: number, type: TrajetType):Observable<Result> {
        return this.http.delete<Result>(`${environment.baseUrl}/controller/Trajet/delete/${type}/${id}`).pipe(
            first()
        );
    }
}
