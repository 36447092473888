<div class="with-floating-button">
    <div class="content" *ngIf="ville">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.geographie.ville.title' | translate: {libelle: ville.libelle}" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.ville.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="ville">
                <ville-generalites [ville]="ville" [isCreation]="isCreation"></ville-generalites>
            </form>
        </div>

        <floating-button [listeActions]="listeActions" [isPending]="isSaving || isDeleting"></floating-button>
    </div>
    <mat-spinner diameter="28" *ngIf="!ville"></mat-spinner>
</div>