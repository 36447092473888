<mat-card>
    <mat-card-title>
        <span [translate]="'admin.ongletOutils.cadreNiveauxRisque.titre'"></span>
        <span class="actions clickable" (click)="showPopupDetails()" mat-icon-button>
            <mat-icon class="material-icons-outlined">search</mat-icon>
        </span>
    </mat-card-title>
    <mat-card-content>
        <div class="listview">
            <div class="listview-body">
                <div class="listview-item clickable" (click)="showPopupNiveauxRisque()">
                    <div class="list-view-item-hover">
                        <div class="lvi-content">
                            <div class="avatar"><i class="material-icons-outlined">health_and_safety</i></div>
                            <div class="lvi-body"><div class="title"><a [translate]="'admin.ongletOutils.cadreNiveauxRisque.actualiserListe'"></a></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>


<ng-template #messageConfirmation>
    <span [translate]="'admin.ongletOutils.cadreNiveauxRisque.popupRecalcul.messageConfirmation'"></span><strong>{{"admin.ongletOutils.cadreNiveauxRisque.popupRecalcul.procedure" | translate | uppercase}}</strong>
    <span> {{'admin.ongletOutils.pourLobjet' | translate}} "{{'od.title' | translate: {numero: idObjet} }}".</span><br>
    <span [translate]="'admin.ongletOutils.cadreNiveauxRisque.popupRecalcul.messageWarning'" class="error"></span>
</ng-template>
