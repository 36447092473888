<h1 mat-dialog-title>
    <span [translate]="'vehicule.formulaire.documents.label'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <div class="listview">
        <div class="listview-body">
            <div (click)="addDocument(TypeDocument.CARTE_GRISE)" *ngIf="data.settings.carteGriseActif && data.vehicule.listeCarteGrise.length == 0 && data.vehicule.statut !== Status.STATUT_NON_TRAITE" class="listview-item">
                <div class="lvi-content">
                    <div class="avatar" [translate]="'vehicule.avatar.carteGrise'"></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" [translate]="'vehicule.formulaire.documents.carteGrise.label'"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="addDocument(TypeDocument.ASSURANCE)" *ngIf="data.settings.assuranceActif && data.vehicule.statut !== Status.STATUT_NON_TRAITE" class="listview-item">
                <div class="lvi-content">
                    <div class="avatar" [translate]="'vehicule.avatar.assurance'"></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" [translate]="'vehicule.formulaire.documents.ajoutAssurance'"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div (click)="addDocument(TypeDocument.AUTORISATION)" *ngIf="data.settings.autorisationActif && data.isResponsableVehicule && data.vehicule.statut != Status.STATUT_BROUILLON" class="listview-item">
                <div class="lvi-content">
                    <div class="avatar" [translate]="'vehicule.avatar.autorisation'"></div>
                    <div class="lvi-body">
                        <div class="title">
                            <a [routerLink]="" [translate]="'vehicule.formulaire.documents.autorisation'"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
