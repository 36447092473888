import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {GeographieComponent} from "@components/admin/bibliotheque/geographie/geographie.component";
import {TerritoireItemComponent} from "@components/admin/bibliotheque/geographie/territoire/territoire-list/territoire-item/territoire-item.component";
import {TerritoireComponent} from "@components/admin/bibliotheque/geographie/territoire/details/territoire.component";
import {TerritoireListComponent} from "@components/admin/bibliotheque/geographie/territoire/territoire-list/territoire-list.component";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {Routes} from "@angular/router";
import {TerritoireGeneralitesComponent} from '@components/admin/bibliotheque/geographie/territoire/details/generalites/territoire-generalites.component';
import {TerritoirePerimetreComponent} from './territoire/details/territoire-perimetre/territoire-perimetre.component';
import {TerritoirePerimetreItemComponent} from './territoire/details/territoire-perimetre/territoire-perimetre-item/territoire-perimetre-item.component';
import {TerritoirePerimetreAddComponent} from './territoire/details/territoire-perimetre/territoire-perimetre-add/territoire-perimetre-add.component';
import {ZoneListComponent} from './zone/zone-list/zone-list.component';
import {ZoneItemComponent} from './zone/zone-list/zone-item/zone-item.component';
import {ZoneComponent} from './zone/details/zone.component';
import {ZoneGeneralitesComponent} from './zone/details/generalites/zone-generalites.component';
import {ZonePerimetreComponent} from './zone/details/perimetre/zone-perimetre.component';
import {ZonePerimetreItemComponent} from './zone/details/perimetre/zone-perimetre-item/zone-perimetre-item.component';
import {ZonePerimetreAddComponent} from './zone/details/perimetre/zone-perimetre-add/zone-perimetre-add.component';
import {PaysComponent} from "@components/admin/bibliotheque/geographie/pays/details/pays.component";
import {PaysGeneralitesComponent} from '@components/admin/bibliotheque/geographie/pays/details/generalites/pays-generalites.component';
import {PaysListComponent} from './pays/pays-list/pays-list.component';
import {PaysItemComponent} from './pays/pays-list/pays-item/pays-item.component';
import {RegionListComponent} from './regions/region-list/region-list.component';
import {RegionItemComponent} from './regions/region-list/region-item/region-item.component';
import {RegionComponent} from '@components/admin/bibliotheque/geographie/regions/details/region.component';
import {RegionGeneralitesComponent} from '@components/admin/bibliotheque/geographie/regions/details/generalites/region-generalites.component';
import {DepartementListComponent} from './departements/departement-list/departement-list.component';
import {DepartementItemComponent} from './departements/departement-list/departement-item/departement-item.component';
import {DepartementComponent} from '@components/admin/bibliotheque/geographie/departements/details/departement.component';
import {DepartementGeneralitesComponent} from '@components/admin/bibliotheque/geographie/departements/details/generalites/departement-generalites.component';
import {VilleListComponent} from './ville/ville-list/ville-list.component';
import {VilleItemComponent} from './ville/ville-list/ville-item/ville-item.component';
import {VilleComponent} from '@components/admin/bibliotheque/geographie/ville/details/ville.component';
import {VilleGeneralitesComponent} from '@components/admin/bibliotheque/geographie/ville/details/generalites/ville-generalites.component';
import {DroitAdmin} from "@core/security/droit-admin";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {AdresseListComponent} from './adresses/adresse-list/adresse-list.component';
import {AdresseItemComponent} from './adresses/adresse-list/adresse-item/adresse-item.component';
import {AdresseComponent} from '@components/admin/bibliotheque/geographie/adresses/details/adresse.component';
import {AdresseGeneralitesComponent} from '@components/admin/bibliotheque/geographie/adresses/details/generalites/adresse-generalites.component';
import {GeolocalisationComponent} from './geolocalisation/geolocalisation/geolocalisation.component';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {IataListComponent} from './iata/iata-list/iata-list.component';
import {IataItemComponent} from './iata/iata-list/iata-item/iata-item.component';
import {IataComponent} from '@components/admin/bibliotheque/geographie/iata/details/iata.component';
import {IataGeneralitesComponent} from '@components/admin/bibliotheque/geographie/iata/details/generalites/iata-generalites.component';
import {DistanceListComponent} from './distances/distance-list/distance-list.component';
import {DistanceItemComponent} from './distances/distance-list/distance-item/distance-item.component';
import {DistanceComponent} from '@components/admin/bibliotheque/geographie/distances/details/distance.component';
import {DistanceGeneralitesComponent} from '@components/admin/bibliotheque/geographie/distances/details/generalites/distance-generalites.component';
import {DistanceSelectLieuComponent} from '@components/admin/bibliotheque/geographie/distances/details/generalites/distance-select-lieu/distance-select-lieu.component';
import {LieuModule} from "@components/lieu/lieu.module";

export const adminGeographieRoutes: Routes = [
	{
		path: 'Territoire/:idTerritoire',
		component: TerritoireComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	},{
		path: 'Zone/:idZone',
		component: ZoneComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	},{
		path: 'Pays/:idPays',
		component: PaysComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	}, {
		path: 'Region/:idRegion',
		component: RegionComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	}, {
		path: 'Departement/:idDepartement',
		component: DepartementComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	}, {
		path: 'Ville/:idVille',
		component: VilleComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	}, {
		path: 'Adresse/:idAdresse',
		component: AdresseComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	}, {
		path: 'Iata/:idIataVille',
		component: IataComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_REF_GEOGRAPHIE] }
	}, {
		path: 'Geolocalisation',
		component: GeolocalisationComponent,
		canActivate: [AdminGuardProvider]
	}, {
		path: 'Distance/:idDistance',
		component: DistanceComponent,
		canActivate: [AdminGuardProvider]
	}
];

/**
 * Module des référentiels de la gestion des géographies
 */
@NgModule({
    imports: [ShareModule, LieuModule, PreviewAlerteModule],
	declarations: [
		GeographieComponent,
		TerritoireItemComponent,
		TerritoireComponent,
		TerritoireListComponent,
		TerritoireGeneralitesComponent,
		TerritoirePerimetreComponent,
		TerritoirePerimetreItemComponent,
		TerritoirePerimetreAddComponent,
		ZoneListComponent,
		ZoneItemComponent,
		ZoneComponent,
		ZoneGeneralitesComponent,
		ZonePerimetreComponent,
		ZonePerimetreItemComponent,
		ZonePerimetreAddComponent,
		PaysComponent,
		PaysGeneralitesComponent,
		PaysListComponent,
		PaysItemComponent,
        RegionListComponent,
        RegionItemComponent,
		RegionComponent,
		RegionGeneralitesComponent,
		DepartementListComponent,
		DepartementItemComponent,
		DepartementComponent,
		DepartementGeneralitesComponent,
		VilleListComponent,
		VilleItemComponent,
		VilleComponent,
		VilleGeneralitesComponent,
		AdresseListComponent,
		AdresseItemComponent,
		AdresseComponent,
		AdresseGeneralitesComponent,
		GeolocalisationComponent,
		IataListComponent,
		IataItemComponent,
		IataComponent,
		DistanceListComponent,
		DistanceItemComponent,
		DistanceComponent,
		DistanceGeneralitesComponent,
		DistanceSelectLieuComponent,
		IataGeneralitesComponent
	],
	exports: [
		GeographieComponent
	],
	providers: [
		GeographieService
	]
})
export class GeographieModule {
}
