import {Component,Input} from '@angular/core';
import {SuiviBudget} from '@domain/budget/suivi-budget';
import {NiveauAlerte} from "@domain/common/alerte/alerte";

/**
 * Composant d'affichage d'une enveloppe budgétaire
 */
@Component({
	host: {'data-test-id': 'enveloppe'},
	selector: 'enveloppe',
	templateUrl: './enveloppe.component.html'
})
export class EnveloppeComponent {

	/** Accès à l'enum dans la vue */
	NiveauAlerte = NiveauAlerte;

	/** Suivi budgétaire */
	@Input() suivi: SuiviBudget;
}
