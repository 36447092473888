import {Component,Inject,OnInit,Type,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {AbstractField} from "@domain/travel/abstract-field";
import {FieldUsed} from "@domain/travel/field-used";
import {FieldComponent} from "../field.component";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {filter,first,mapTo} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {Champ,FiltreChamp} from "@domain/travel/champ";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {
	ParamConnexionSelectionComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/selection/param-connexion-selection.component";
import {ParamConnexionChamp} from "@domain/travel/param-connexion-champ";
import {CodemirrorComponent} from "@ctrl/ngx-codemirror";
import {CustomFieldUsed} from "@domain/travel/custom-field-used";
import {TripFieldUsed} from "@domain/travel/trip-field-used";
import {NgModel} from "@angular/forms";
import {TypeParamConnexion} from "@domain/travel/param-connexion";

/**
 * Popin d'ajout d'un Field
 *
 * @author Laurent Convert
 * @date 18/12/2023
 */
@Component({
	host: {'data-test-id': 'field-add'},
	templateUrl: './field-add.component.html'
})
export class FieldAddComponent<U extends FieldUsed> implements OnInit {
	/* Déclaration pour accès dans le template */
	protected readonly TypeParamConnexion = TypeParamConnexion;
	protected readonly FiltreChamp = FiltreChamp;
	protected readonly AdminTravelhubService = AdminTravelhubService;

	/** Référence vers le composant CodeMirror (affiché dans le cas d'une valeur de type requête SQL) */
	@ViewChild("codeMirror")
	codeMirror: CodemirrorComponent;

	/** Référence vers le NgModel du composant géré par CodeMirror (affiché dans le cas d'une valeur de type requête SQL) */
	@ViewChild("codeMirror",{read: NgModel})
	valeurSql: NgModel;

	/** CustomField / TripField en cours de visualisation */
	fieldUsed: U;

	/** champ associé au CustomField / TripField */
	champ: Champ;

	/** Indicateur de suppression en cours */
	isDeleting: boolean = false;

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean = false;

	/** Indicateur de traitement en cours (enregistrement ou suppression) */
	get isProcessing(): boolean {
		return this.isSaving || this.isDeleting;
	}

	/** Options pour l'affichage de la requête SQL */
	codeMirrorOptions: any = {
		theme: 'material',
		mode: 'text/x-mssql',
		lineNumbers: true,
		lineWrapping: true,
	};

	/** Racine pour les traductions en fonction du type CustomField / TripField */
	rootTradField: string;

	/** Observable permettant de savoir si l'ouverture de la  popup est terminée (après la fin de l'animation).
	 * Nécessaire pour le rafraichissement de MirrorCode (dans le cas d'une valeur de type requête SQL) */
	opened$ = this.matDialogRef.afterOpened().pipe(mapTo(true));

	/**
	 * Constructeur
	 *
	 * @param data Données injectées à l'ouverture de la popin
	 * @param translateService Service de traduction
	 * @param confirmService Service de confirmation utilisateur via modale
	 * @param matDialogRef Référence de la popup
	 * @param matDialog Boîte de dialogue
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: {
			sbtConfigUsed: TravelHubSBTConfigUsed,
			field: U,
			listeField: Array<AbstractField>,
			parent: FieldComponent<U>
		},
		private translateService: TranslateService,
		private confirmService: ConfirmService,
		private matDialogRef: MatDialogRef<FieldAddComponent<U>>,
		private matDialog: MatDialog,
		private adminTravelhubService: AdminTravelhubService
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la racine pour les traductions
		let type: Type<CustomFieldUsed | TripFieldUsed> = this.data.field instanceof CustomFieldUsed ? CustomFieldUsed : TripFieldUsed;
		this.rootTradField = this.adminTravelhubService.getRootTradField(type);

		//CustomField / TripField en cours de visualisation
		this.fieldUsed = this.data.field;

		//Récupération du champ associé au CustomField / TripField
		this.champ = this.adminTravelhubService.findChamp(this.fieldUsed.idValeur);

		//Dans le cas d'une création et d'un seul choix possible pour le champ, on le sélectionne par défaut
		if (!this.fieldUsed.getId() && this.data.listeField.length === 1) {
			this.fieldUsed.field = this.data.listeField[0];
		}
	}

	/**
	 * Ouvre la popup permettant de choisir une valeur
	 */
	openValeur(): void {
		this.matDialog.open<ParamConnexionSelectionComponent,any,ParamConnexionChamp>(ParamConnexionSelectionComponent,{
			data: {
				champ: this.champ
			}
		}).afterClosed().subscribe(paramConnexionChamp => {
			if (paramConnexionChamp) {
				//Mémorisation du champ sélectionné
				this.champ = paramConnexionChamp.champ;

				//Mise à jour de l'identifiant du champ sélectionné
				this.fieldUsed.idValeur = this.champ.id;

				//Rafraichissement de codemirror (permet de forcer le calcul des gouttières et autres éléments de l'éditeur)
				if (paramConnexionChamp.champ.id === AdminTravelhubService.TYPE_FIELD_SQL) {
					setTimeout(() => {
						//Rafraichissement du composant CodeMirror
						this.codeMirror.codeMirror.refresh();
						//Activation du focus
						this.codeMirror.codeMirror.focus();
					});
				}
			}
		});
	}

	/**
	 * Suppression du Field en cours d'affichage
	 */
	deleteField(): void {
		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Suppression en cours
					this.isDeleting = true;

					//Suppression
					this.data.parent.deleteField(this.fieldUsed).pipe(first()).subscribe(isSuccess => {
						//Vérification du succès de la suppression
						if (isSuccess) {
							//Fermeture de la popup
							this.matDialogRef.close(true);
						} else {
							//Erreur lors de suppression, fin du traitement
							this.isDeleting = false;
						}
					});
				}
			});
	}

	/**
	 * Suppression du Field en cours d'affichage
	 */
	saveField(): void {
		//Enregistrement en cours
		this.isSaving = true;

		//Dans le cas d'un champ hors SQL / Texte libre, il n'y a pas de valeur fixe
		if (![AdminTravelhubService.TYPE_FIELD_VALEUR_FIXE,AdminTravelhubService.TYPE_FIELD_SQL].includes(this.fieldUsed.idValeur)) {
			this.fieldUsed.valeur = null;
			this.fieldUsed.requete = null;
		}

		//Enregistrement
		this.data.parent.saveField(this.fieldUsed).pipe(first()).subscribe(isSuccess => {
			//Vérification du succès de l'enregistrement
			if (isSuccess) {
				//Fermeture de la popup
				this.matDialogRef.close(true);
			} else {
				//Erreur lors d'enregistrement, fin du traitement
				this.isSaving = false;
			}
		});
	}
}