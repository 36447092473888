import {Component,Input,ViewChild} from "@angular/core";
import {ControlContainer,NgForm} from "@angular/forms";
import {Report} from "@domain/reporting/report";
import {SettingsGlobalState} from "@domain/settings/settings";
import {DocumentListComponent} from "@components/document/document-list.component";
import {ContextDocument} from "@domain/document/context-document";

/**
 * Composant de gestion des fichiers du reporting
 */
@Component({
    host: {'data-test-id': 'reporting-infos-fichier'},
    selector: 'reporting-infos-fichier',
    templateUrl: './reporting-infos-fichier.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ReportingInfosFichierComponent {
    /** Définition de la classe pour l'utilisation dans la page HTML */
    ContextDocument = ContextDocument;

    /** Rapport sélectionné */
    private _report: Report;
    @Input() set report(report: Report) {
        this._report = report;
        this.fileList = report.userFile && [{
            libelle: report.userFile,
            fichier: report.userFile,
            idDocument: report.idReport,
            md5: report.md5
        }] || null;
    }
    get report(): Report { return this._report; }

    /** Liste des fichiers */
    fileList: any[];

    /** Paramètres de la liste de documents */
    readonly settings: SettingsGlobalState = {
        listeExtensions: ["jrxml","zip"]
    } as SettingsGlobalState;

    /** Étiquettes personnalisées */
    readonly customTags: (data: any) => { libelle: string, value: string }[] = (data) => {
        return [{
            libelle: 'document.liste.md5',
            value: data.md5
        }]
    };

    /** Composant de liste des documents */
    @ViewChild('listeDocument') listeDocument: DocumentListComponent;
}
