import { Component,OnInit,ViewChild,TemplateRef,ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'list-view-header',
    templateUrl: './list-view-header.component.html'
})
export class ListViewHeaderComponent implements OnInit {
    /** Template du header **/
    @ViewChild('headerTemplate')
    public template: TemplateRef<any>;

    /**
     * Constructeur 
     */
    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    /**
     * Initialisation
     */
    ngOnInit() {
        //Détection des changements
        this.changeDetectorRef.detectChanges();
    }
}