import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {ProfilConnexion} from "@domain/travelhub/profil-connexion";
import {MatDialogRef} from "@angular/material/dialog";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {filter,finalize} from "rxjs/operators";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {AdminTravelhubService} from "../../admin-travelhub.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

/**
 * Composant d'affichage d'une ligne de la liste des profils de connexion
 *
 * @author Laurent Convert
 * @date 12/03/2024
 */
@Component({
	host: {'data-test-id': 'profil-connexion-list-item'},
	templateUrl: './profil-connexion-list-item.component.html'
})
export class ProfilConnexionListItemComponent extends ListViewItem<ProfilConnexion>{

	/**
	 * Constructeur
	 *
	 * @param router Le routeur angular
	 * @param translateService Service de traduction
	 * @param toastrService Service de notification
	 * @param confirmService Service de confirmation utilisateur via modale
	 * @param pleaseWaitService Service d'affichage du loading
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 */
	constructor(private router: Router,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private confirmService: ConfirmService,
				private pleaseWaitService: PleaseWaitService,
				private adminTravelhubService: AdminTravelhubService,
	) {
		super();
	}

	/**
	 * Ouverture du détail d'un profil de connexion
	 *
	 * @param idProfilConnexion Identifiant du profil de connexion à afficher
	 */
	openProfilConnexion(idProfilConnexion: number) {
		this.router.navigate([AdminTravelhubService.URL_PROFIL_CONNEXION,idProfilConnexion]);
	}

	/**
	 * Suppression du profil de connexion
	 *
	 * @param idProfilConnexion Identifiant du profil de connexion à supprimer
	 */
	deleteProfilConnexion(idProfilConnexion: number): void {
		let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe(() => {
				//Affichage de la popup d'attente
				matDialogRef = this.pleaseWaitService.show();

				//Suppression du profil en base
				this.adminTravelhubService.deleteProfilConnexion(idProfilConnexion)
					.pipe(finalize(() => matDialogRef.close()))
					.subscribe(result => {
						//Vérification du résultat
						if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
							//Message d'erreur
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Rechargement de la liste
							this.liste.refresh();
						} else {
							//Message d'erreur
							TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
						}
					},() => {
						//Message d'erreur
						TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
					});
				});
	}

	/**
	 * Duplication du profil de connexion
	 *
	 * @param idProfilConnexion Identifiant du paramètre à dupliquer
	 */
	copyProfilConnexion(idProfilConnexion: number): void  {
		let matDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

		//Affichage de la popup d'attente
		matDialogRef = this.pleaseWaitService.show();

		//Duplication du profil en base
		this.adminTravelhubService.copyProfilConnexion(idProfilConnexion)
			.pipe(finalize(() => matDialogRef.close()))
			.subscribe(result => {
				//Vérification du résultat
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'erreur
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Rechargement de la liste
					this.router.navigate([AdminTravelhubService.URL_PROFIL_CONNEXION,result.data.idProfilConnexion]);
				} else {
					//Message d'erreur
					TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
				}
			},() => {
				//Message d'erreur
				TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
			});
	}

}