import {ItemHierarchique,ItemHierarchiqueDetailType} from "@share/component/affichage-hierarchique/ItemHierarchique";
import {ArbreHierarchique} from "@share/component/affichage-hierarchique/ArbreHierarchique";
import {ItemTitleComponent} from "@share/component/affichage-hierarchique/title-components/item-title/item-title.component";
import {CheckboxDetailComponent} from "@share/component/affichage-hierarchique/detail-components/checkbox-detail/checkbox-detail.component";
import {FiltreDetailComponent} from "@share/component/affichage-hierarchique/detail-components/filtre-detail/filtre-detail.component";
import {Subject,Subscription} from "rxjs";
import {DroitAdmin} from "@core/security/droit-admin";

/** Énumération des noms de colonnes pour l'affichage des cartes hiérarchiques des droits */
export enum DetailName {
	ACCES = "acces",
	FILTRE = "filtre",
	INFORMATIONS = "informations"
}

/**
 * Méthode d'initialisation des colonnes de détail pour un élémént des cartes hiérarchiques des droits
 *
 * @param isFiltre					Indique si la colonne filtre doit être cochée pour l'élément
 * @param cleTradTooltip			Clé de traduction pour afficher le tooltip dans la colonne dédiée (si nécessaire)
 * @param subjectPopulationChecked	Subject permettant de savoir quand un des droits sur les populations a été check
 * @returns {{inputsRecord: Record<string, any>, composantRecord: Record<string, ItemHierarchiqueDetailType>}}
 */
export function buildListeComposantsDetail(isFiltre: boolean = false,cleTradTooltip?: string,subjectPopulationChecked?: Subject<any>): {
	composantRecord: Record<string,ItemHierarchiqueDetailType>,
	inputsRecord: Record<string,any>
} {
	let composantRecord: Record<string,ItemHierarchiqueDetailType> = {};
	let inputsRecord: Record<string,any> = {};

	//On ajoute le composant de la colonne accès (case à cocher)
	composantRecord[DetailName.ACCES] = CheckboxDetailComponent;
	inputsRecord[DetailName.ACCES] = subjectPopulationChecked ? {subject: subjectPopulationChecked} : null;

	//Si l'élément est filtré
	if (isFiltre) {
		//On ajoute le composant de la colonne filtre
		composantRecord[DetailName.FILTRE] = FiltreDetailComponent;
		inputsRecord[DetailName.FILTRE] = {icone: 'done',show: true};
	}

	//Si l'élément a un tooltip
	if (cleTradTooltip) {
		//On ajoute le composant de la colonne tooltip
		composantRecord[DetailName.INFORMATIONS] = FiltreDetailComponent;
		inputsRecord[DetailName.INFORMATIONS] = {
			show: true,
			icone: 'info',
			tooltip: cleTradTooltip
		}
	}

	//On renvoie la liste des composants de l'élément et leurs inputs
	return {composantRecord: composantRecord,inputsRecord: inputsRecord};
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Entreprise
 *
 * @returns {{liste: Array<ArbreHierarchique>, souscription: Subscription}}
 */
export function buildCarteEntreprise(): { liste: Array<ArbreHierarchique>,souscription: Subscription } {
	//Liste des tous les arbres du menu Entreprise
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre Analytique
	let listeBranchesAnalytiques: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_ORGANIGRAMME,{libelle: 'workflow.roles.droits.droit.adminOrga'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.organigramme')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_ANALYTIQUE_DOSSIER,{libelle: 'workflow.roles.droits.droit.gestionCodeProjet'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_ANALYTIQUE_AXE_5,{libelle: 'workflow.roles.droits.droit.gestionRef1'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_ANALYTIQUE_AXE_6,{libelle: 'workflow.roles.droits.droit.gestionRef2'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_BUDGET_ENVELOPPE,{libelle: 'workflow.roles.droits.droit.gestionEnvBudg'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.analytique',listeBranchesAnalytiques));

	//Arbre Description
	let listeBranchesDescription: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_LICENCE,{libelle: 'workflow.roles.droits.droit.gestionLicence'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_RGPD_GESTION,{libelle: 'workflow.roles.droits.droit.gestionRGPD'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.rgpd'))];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.description',listeBranchesDescription));

	//Sujet pour gérer la concurrence sur les deux droits de population
	let subjectPopulationChecked: Subject<ItemHierarchique> = new Subject<ItemHierarchique>();

	//Arbre Populations
	let listeBranchesPopulations: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_POPULATION_GESTION,{libelle: 'workflow.roles.droits.droit.gestionPopulation'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.gestionPopulation',subjectPopulationChecked),0),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_POPULATION_ADMIN,{libelle: 'workflow.roles.droits.droit.adminPopulation'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.adminPopulation',subjectPopulationChecked),1)];
	let arbre = new ArbreHierarchique('menu.admin.populations',listeBranchesPopulations);
	listeArbresHierarchiques.push(arbre);

	//Souscription à l'évènement déclenché lors du cochage d'un droit population
	let souscription = subjectPopulationChecked.subscribe(itemHierarchique => {
		handleExclusif(arbre,itemHierarchique);
	});

	//Arbre Référentiels
	let listeBranchesReferentiel: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_USER_REFERENTIEL,{libelle: 'workflow.roles.droits.droit.gestionRef'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.refEntreprise',listeBranchesReferentiel));

	//Arbre Utilisateurs
	let listeBranchesUtilisateur: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_USER_GESTION,{libelle: 'workflow.roles.droits.droit.gestionUser'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.gestionUser')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_HABILITATION_GESTION,{libelle: 'workflow.roles.droits.droit.gestionHabilitation'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_PROFIL_VOYAGEUR_GESTION,{libelle: 'workflow.roles.droits.droit.gestionProfilVoyageur'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_PV_CONTACT_URGENCE,{libelle: 'workflow.roles.droits.droit.gestionContact'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_PV_GESTION_DOCUMENT,{libelle: 'workflow.roles.droits.droit.gestionDocument'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_PV_CARTES_ABONNEMENT,{libelle: 'workflow.roles.droits.droit.gestionCarteAbo'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_USER_INFOS_BANCAIRES,{libelle: 'workflow.roles.droits.droit.gestionInfoBancaire'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_VEHICULE_GESTION,{libelle: 'workflow.roles.droits.droit.gestionVehicule'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_MOBILE_GESTION,{libelle: 'workflow.roles.droits.droit.gestionMobile'},buildListeComposantsDetail(true,'workflow.roles.droits.tooltip.infoPerso')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_USER_ANALYTIQUE,{libelle: 'workflow.roles.droits.droit.gestionAnalytique'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_GESTION_EXTERNE,{libelle: 'workflow.roles.droits.droit.gestionExterne'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_USER_ALERTE_PROFIL,{libelle: 'workflow.roles.droits.droit.gestionAlerteProfil'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.utilisateurs',listeBranchesUtilisateur));

	//Arbre Véhicules
	let listeBranchesVehicules: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_CONSULTATION_VEHICULE,{libelle: 'workflow.roles.droits.droit.consultationVehicules'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.consultationVehicules')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_GESTION_PARAMETRAGE_VEHICULES,{libelle: 'workflow.roles.droits.droit.gestionParametrageVehicules'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.gestionParametrageVehicules'))
	];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.vehicules',listeBranchesVehicules));

	//On renvoie la liste des arbres créés ainsi que la souscription pour gérer les populations
	return {liste: listeArbresHierarchiques,souscription: souscription};
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Comptabilité
 *
 * @returns {Array<ArbreHierarchique>}
 */
export function buildCarteComptabilite(): Array<ArbreHierarchique> {
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre Exercice et périodes
	let listeBranchesExercice: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_COMPTA_EXERCICE,{libelle: 'workflow.roles.droits.droit.gestionPeriode'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.exercicesEtPeriodes',listeBranchesExercice));

	//Arbre Modes de remboursement
	let listeBranchesRbt: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_MODE_REMBOURSEMENT,{libelle: 'workflow.roles.droits.droit.gestionRemb'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.modesDeRemboursement',listeBranchesRbt));

	//Arbre Plan comptable
	let listeBranchesPlanCpt: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_COMPTE_GESTION,{libelle: 'workflow.roles.droits.droit.gestionPlanComptable'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.planComptable',listeBranchesPlanCpt));

	//Arbre Lots comptables
	let listeBranchesLotsCpt: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_LOTS_COMPTABLES,{libelle: 'workflow.roles.droits.droit.lotsComptables'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_DEMATERIALISATION,{libelle: 'workflow.roles.droits.droit.demat'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.lotsComptables',listeBranchesLotsCpt));

	return listeArbresHierarchiques;
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Bibliothèque
 *
 * @returns {Array<ArbreHierarchique>}
 */
export function buildCarteBibliotheque(): Array<ArbreHierarchique> {
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre Devises
	let listeBranchesDevise: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_DEVISE_TAUX_CHANGE,{libelle: 'workflow.roles.droits.droit.tauxChange'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.devises',listeBranchesDevise));

	//Arbre Géographie
	let listeBranchesGeo: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_REF_GEOGRAPHIE,{libelle: 'workflow.roles.droits.droit.gestionRefGeo'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.geographie',listeBranchesGeo));

	//Arbre Internationalisation
	let listeBranchesInternational: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_INTERNATIONALISATION,{libelle: 'workflow.roles.droits.droit.gestionTrad'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.internationalisation',listeBranchesInternational));

	//Arbre Notifications
	let listeBranchesNotif: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_RELANCE_GESTION,{libelle: 'workflow.roles.droits.droit.gestionNotif'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.notifications',listeBranchesNotif));

	//Arbre Reporting
	let listeBranchesReporting: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_REPORTING,{libelle: 'workflow.roles.droits.droit.gestionRapport'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.reporting',listeBranchesReporting));

	return listeArbresHierarchiques;
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Paramètre
 *
 * @returns {Array<ArbreHierarchique>}
 */
export function buildCarteParametre(): Array<ArbreHierarchique> {
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre API
	let listeBranchesAPI: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_INTERFACE_COMPTE_API,{libelle: 'workflow.roles.droits.droit.controleAPI'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.api')),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_INTERFACE_REF,{libelle: 'workflow.roles.droits.droit.gestionInterface'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.api',listeBranchesAPI));

	//Arbre Authentification
	let listeBranchesAuth: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_COMPTES_VERROUILLES,{libelle: 'workflow.roles.droits.droit.gestionCompteVerr'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.authentification',listeBranchesAuth));

	//Arbre Mobilité
	let listeBranchesMobilite: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_CONFIG_MOBILE,{libelle: 'workflow.roles.droits.droit.gestionConf'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.mobilite',listeBranchesMobilite));

	return listeArbresHierarchiques;
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Frais
 *
 * @returns {Array<ArbreHierarchique>}
 * @param itemDroitInterface Item hiérarchique du droit des interfaces (droit identique mais présent à 2 endroits)
 */
export function buildCarteGestionFrais(itemDroitInterface: ItemHierarchique): Array<ArbreHierarchique> {
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre Avances
	let listeBranchesAvances: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_VISUALISATION_AVANCES,{libelle: 'workflow.roles.droits.droit.visualisationAvance'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.avances',listeBranchesAvances));

	//Arbre Factures
	let listeBranchesFactures: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_VISUALISATION_RELEVES_FACTURES,{libelle: 'workflow.roles.droits.droit.visualisationReleve'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_ENTREPRISE_FOURNISSEUR,{libelle: 'workflow.roles.droits.droit.gestionFour'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_GESTION_TOLERANCE,{libelle: 'workflow.roles.droits.droit.gestionTolerance'},buildListeComposantsDetail()),
		itemDroitInterface];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.factures',listeBranchesFactures));

	//Arbre Indemnités
	let listeBranchesIndemnites: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_IJ_GESTION,{libelle: 'workflow.roles.droits.droit.gestionIJ'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.indemnites',listeBranchesIndemnites));

	//Arbre NDF
	let listeBranchesNDF: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_VISUALISATION_NDF,{libelle: 'workflow.roles.droits.droit.visualisationNotes'},buildListeComposantsDetail()),
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_IMPORT_FACTURE,{libelle: 'workflow.roles.droits.droit.gestionImport'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.frais',listeBranchesNDF));

	//Arbre Prestations
	let listeBranchesPrestation: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_PRESTATION_NDF,{libelle: 'workflow.roles.droits.droit.gestionPresta'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.prestation',listeBranchesPrestation));

	//Arbre Référentiels
	let listeBranchesRef: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_FRAIS_PRESTATION,{libelle: 'workflow.roles.droits.droit.gestionRefPresta'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.refFrais',listeBranchesRef));

	//Arbre Taxes
	let listeBranchesTaxes: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_GESTION_TAXE,{libelle: 'workflow.roles.droits.droit.gestionTaxe'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.taxes',listeBranchesTaxes));

	return listeArbresHierarchiques;
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Voyage
 *
 * @returns {Array<ArbreHierarchique>}
 */
export function buildCarteGestionVoyage(): Array<ArbreHierarchique> {
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre Frais d'agence
	let listeBranchesFraisAgence: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_GESTION_FRAIS_AGENCE,{libelle: 'workflow.roles.droits.droit.gestionFraisAgence'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.fraisDagence',listeBranchesFraisAgence));

	//Arbre Missions
	let listeBranchesMissions: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_VISUALISATION_MISSIONS,{libelle: 'workflow.roles.droits.droit.visualisationMissions'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.missions',listeBranchesMissions));

	//Arbre Référentiels
	let listeBranchesRef: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_TRAVEL_REFERENTIEL,{libelle: 'workflow.roles.droits.droit.gestionRefVoyageur'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.refVoyages',listeBranchesRef));

	return listeArbresHierarchiques;
}

/**
 * Construction de tous les arbres hiérarchiques des droits pour le menu Workflow
 *
 * @returns {Array<ArbreHierarchique>}
 */
export function buildCarteWorkflow(): Array<ArbreHierarchique> {
	let listeArbresHierarchiques: Array<ArbreHierarchique> = [];

	//Arbre Contrôle
	let listeBranchesControle: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_CONTROLE_WORKFLOW,{libelle: 'workflow.roles.droits.droit.controleWF'},buildListeComposantsDetail(false,'workflow.roles.droits.tooltip.controleWorkflow'))];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.controle',listeBranchesControle));

	//Arbre Motifs
	let listeBranchesMotifs: Array<ItemHierarchique> = [
		new ItemHierarchique(ItemTitleComponent,DroitAdmin.DROIT_MOTIF_GESTION,{libelle: 'workflow.roles.droits.droit.gestionMotif'},buildListeComposantsDetail())];
	listeArbresHierarchiques.push(new ArbreHierarchique('menu.admin.motifs',listeBranchesMotifs));

	return listeArbresHierarchiques;
}

/**
 * Méthode de gestion des droits exclusifs.</br>
 * Si un droit est déjà coché et qu'on coche sur un autre droit de l'arbre, alors le premier est décoché pour cocher le second.</br>
 * Par exemple pour les droits admin/gestion des populations
 *
 * @param arbre			Arbre contenant les droits exclusifs
 * @param itemClique	Élément de l'arbre qui a été cliqué
 */
export function handleExclusif(arbre: ArbreHierarchique,itemClique: ItemHierarchique) {
	//Si on clique sur un élément qui était déjà coché
	if (itemClique.checked) {
		//On le décoche
		itemClique.checked = false;
		//On indique que les autres éléments ne sont plus inactifs
		arbre.listeBranches.forEach(item => item.disabled = false);
	} else {
		//Si on clique sur un élément qui n'était pas encore coché, on commence par le cocher
		itemClique.checked = true;

		//on parcourt toutes les branches de l'arbre
		arbre.listeBranches
			.filter(item => item != itemClique)//On exclut la branche sur laquelle on a cliqué
			.forEach(item => {
				//On marque que l'élément est désactivé
				item.disabled = true;
				//On s'assure qu'il n'est plus coché
				item.checked = false;
			});
	}
}
