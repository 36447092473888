import {AfterViewChecked, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "../../../../../../domain/user/user";
import {ListView} from "../../../../../../domain/common/list-view";
import {OdParticipantPopinEmissionItemComponent} from "./item/od-participant-popin-emission-item.component";
import {MapCreationParticipantType} from "../map-creation-participants.type";

/**
 * Popup de création/émission des OD pour les participants de la mission
 *
 * @author Laurent SCIMIA
 * @date 09/02/2022
 */
@Component({
	templateUrl: './od-participants-popin-emission.component.html'
})
export class OdParticipantsPopinEmissionComponent implements OnInit, AfterViewChecked {
	/** Checkbox qui indique si on doit créer les OD pour les participants */
	checkAllEmission: any = false;
	/** Checkbox qui indique si on doit émettre les OD pour les participants */
	checkAllCreation: any = false;

	/** Liste des participants internes à afficher */
	liste:ListView<User,OdParticipantPopinEmissionItemComponent>;

	/** true si on a déjà initialisé la liste */
	isInit: boolean = false;

	/** Constructeur */
	constructor(@Inject(MAT_DIALOG_DATA) public data: { listeUsers: Array<User>,isEmissionAutorisee: boolean },
	            private matDialogRef: MatDialogRef<OdParticipantsPopinEmissionComponent,MapCreationParticipantType>) {
	}

	/** Initialisation */
	ngOnInit() {
		//On crée la liste à afficher
		this.liste = new ListView<User, OdParticipantPopinEmissionItemComponent>({
			component: OdParticipantPopinEmissionItemComponent,
			isFrontendList: true,
			isFilter: false,
		});
	}

	/** Après que le contenu ait été checké */
	ngAfterViewChecked() {
		/* Si on n'est pas encore initialisé mais que la liste est enfin créée */
		if (!this.isInit && this.liste) {
			//On insère les données dans la liste
			this.liste.data = {
				listeResultats: this.data.listeUsers,
				nbObjetsParPage: 1000,
				nbObjetsDansPage: 0,
				nbObjetsTotal: 0,
				nbPagesTotal: 0,
				numPage: 0,
			};

			//On n'oublie pas d'indiquer que c'est bon c'est initialisé
			this.isInit = true;
		}
	}

	/** Validation de la popup */
	valider() {
		//On renvoie les cases cochées
		this.matDialogRef.close({isOk: true, isCheckAllEmission: this.checkAllEmission,isCheckAllCreation: this.checkAllCreation});
	}
}
