import {Component,Input,TemplateRef,ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TypeAction,TypePortee} from '@domain/workflow/workflow';
import {ConfirmService} from '@share/component/confirmation/confirm.service';
import {filter} from "rxjs/operators";
import {BudgetService} from "@components/budget/budget.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant de l'onglet gestion budgétaire de l'onglet outils - admin
 *
 * @author Angeline Ha
 * @date 30/08/2023
 */
@Component({
	host: {'data-test-id': 'gestion-budgetaire'},
	selector: 'gestion-budgetaire',
	templateUrl: './gestion-budgetaire.component.html'
})
export class GestionBudgetaireComponent {

	/** Accès à l'enum dans la vue */
	readonly ProcedureBudgetaire = ProcedureBudgetaire;
	readonly TypePortee = TypePortee;
	readonly TypeAction = TypeAction;

	/** Identifiant de l'objet */
	@Input() idObjet: number;

	/** Procédure à afficher */
	titreProcedure: string;

	/** Titre du type d'objet */
	cleTradPortee: string;

	/** Type de l'objet workflow */
	@Input() typePortee: TypePortee;

	/** Indique la validité de l'objet */
	@Input() isObjetValide: boolean;

	/** Composant du template ref du message de confirmation */
	@ViewChild('messageConfirmation') messageConfirmation: TemplateRef<any>;

	/**
	 * Constructeur
	 *
	 * @param matDialog Boîte de dialogue pour lancer une procédure budgétaire
	 * @param budgetService
	 * @param toastrService
	 * @param translateService
	 * @param confirmService
	 */
	constructor(private matDialog: MatDialog,
				private budgetService: BudgetService,
				private toastrService: ToastrService,
				private translateService: TranslateService,
				private confirmService: ConfirmService) {
	}

	/**
	 * Affichage de la popup qui permet de lancer une procédure budgétaire
	 *
	 * @param procedure	Type de procédure budgétaire à effectuer
	 */
	showPopupProcedure(procedure: ProcedureBudgetaire) {

		//Définition du type de procédure budgétaire
		switch (procedure) {
			case ProcedureBudgetaire.IMPUTATION:
				this.titreProcedure = "admin.ongletOutils.cadreGestionBudget.Imputation";
				break;
			case ProcedureBudgetaire.LIBERATION:
				this.titreProcedure = "admin.ongletOutils.cadreGestionBudget.Liberation";
				break;
			case ProcedureBudgetaire.CLOTURE:
				this.titreProcedure = "admin.ongletOutils.cadreGestionBudget.Cloture";
				break;
		}

		//Définition du titre de la portée
		switch (this.typePortee) {
			case TypePortee.NF:
				this.cleTradPortee = 'ndf.title';
				break;
			case TypePortee.FC:
				this.cleTradPortee = 'facture.title';
				break;
			case TypePortee.AV:
				this.cleTradPortee = 'avance.title';
				break;
			case TypePortee.OD:
				this.cleTradPortee = 'od.title';
				break;
			case TypePortee.OT:
				this.cleTradPortee = 'omp.title';
				break;
		}

		//Ouverture de la popup de confirmation
		this.confirmService.showConfirm(this.messageConfirmation).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				this.doActionBudget(procedure);
			}})
	}

	/**
	 * Lancement de la procédure budgétaire
	 */
	doActionBudget(procedure: ProcedureBudgetaire) {
		//On effectue la procédure sur des objets valides
		if (this.isObjetValide) {
			this.budgetService.doProcedureBudgetaire(this.idObjet,this.typePortee,procedure,0,false).subscribe(result => {
				if (result) {
					//Affichage du message de succès
					switch (procedure) {
						case ProcedureBudgetaire.IMPUTATION:
							this.toastrService.success(this.translateService.instant('admin.ongletOutils.recalculBudget.success',{procedure: "L'imputation budgétaire"}));
							break;
						case ProcedureBudgetaire.LIBERATION:
							this.toastrService.success(this.translateService.instant('admin.ongletOutils.recalculBudget.success',{procedure: "La libération budgétaire"}));
							break;
						case ProcedureBudgetaire.CLOTURE:
							this.toastrService.success(this.translateService.instant('admin.ongletOutils.recalculBudget.success',{procedure: "La clôture budgétaire"}));
							break;
					}

				} else {
					//Affichage du message d'erreur
					switch (procedure) {
						case ProcedureBudgetaire.IMPUTATION:
							this.toastrService.error(this.translateService.instant('admin.ongletOutils.recalculBudget.error',{procedure: "L'imputation budgétaire"}));
							break;
						case ProcedureBudgetaire.LIBERATION:
							this.toastrService.error(this.translateService.instant('admin.ongletOutils.recalculBudget.error',{procedure: "La libération budgétaire"}));
							break;
						case ProcedureBudgetaire.CLOTURE:
							this.toastrService.error(this.translateService.instant('admin.ongletOutils.recalculBudget.error',{procedure: "La clôture budgétaire"}));
							break;
					}
				}
			})

		} else {
			//L'objet n'est pas valide - message d'erreur
			this.toastrService.error(this.translateService.instant('admin.ongletOutils.recalculBudget.objetNonValide'));
		}
	};
}

/**
 * Enumération des types de procédures budgétaires
 */
export enum ProcedureBudgetaire {
	IMPUTATION = 1,
	LIBERATION = 2,
	CLOTURE = 3
}
