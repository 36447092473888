import {AfterViewInit,Component,EventEmitter,Input,OnInit,Output,ViewChild} from '@angular/core';
import {ControlContainer,NgForm,ValidationErrors} from "@angular/forms";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {TravelHubPrestation} from "@domain/travel/travel-hub-prestation";
import {TranslateService} from "@ngx-translate/core";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {TravelHubSBTConfig} from "@domain/travel/travel-hub-sbt-config";
import {TravelHubConfig} from "@domain/travel/travel-hub-config";
import {ToastrService} from "ngx-toastr";
import {CustomField} from "@domain/travel/custom-field";
import {TripField} from "@domain/travel/trip-field";
import {ParamConnexion} from "@domain/travel/param-connexion";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {CustomFieldUsed} from "@domain/travel/custom-field-used";
import {TripFieldUsed} from "@domain/travel/trip-field-used";
import {ParamConnexionValeur} from "@domain/travel/param-connexion-valeur";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {finalize,first,switchMap} from "rxjs/operators";
import {PleaseWaitService} from "@share/component/please-wait/please-wait.service";
import {MatDialogRef} from "@angular/material/dialog";
import {PleaseWaitDialogComponent} from "@share/component/please-wait/please-wait-dialog.component";
import {Observable,of} from "rxjs";
import {listeSBT} from "@domain/voyage/travel/constants";
import {CustomInputComponent} from "@share/component/custom-input/custom-input.component";

/**
 * Onglet "Généralités" de la page "Configuration SBT"
 *
 * @author Laurent Convert
 * @date 17/11/2023
 */
@Component({
    host: {'data-test-id': 'sbt-config-generalites'},
    selector: 'sbt-config-generalites',
    templateUrl: './sbt-config-generalites.component.html',
    styleUrls: ['./sbt-config-generalites.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SbtConfigGeneralitesComponent implements OnInit,AfterViewInit {
    /** Configuration SBT */
    @Input() sbtConfigUsed: TravelHubSBTConfigUsed;

    /** Liste de paramètres de connexions */
    @Input() listeParamConnexion: Array<ParamConnexion> = [];

    /* Listes de paramètres utilisés */
    @Input() listeParamConnexionUsed: Array<ParamConnexionUsed> = [];
    @Input() listeCustomFieldUsed: Array<CustomFieldUsed> = [];
    @Input() listeTripFieldUsed: Array<TripFieldUsed> = [];

    /* Liste de paramètres */
    @Input() listeValeurParamConnexion: Array<ParamConnexionValeur> = [];
    @Input() listeCustomField: Array<CustomField> = [];
    @Input() listeTripField: Array<TripField> = [];

    /* Liste des configurations TravelHub */
    @Input() listePrestaAutorisee: Array<TravelHubPrestation> = [];
    @Input() listeConfigTH: Array<TravelHubConfig> = [];
    @Input() listeConfigSBT: Array<TravelHubSBTConfig> = [];

    /** Émetteur d'un changement de SBT */
    @Output() sbtConfigChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("sbtConfigInput")
    sbtConfigInput: CustomInputComponent;

    /** SBT associé à la configuration SBT */
    sbtConfig: TravelHubSBTConfig;

    /** Liste des prestations autorisées pour le SBT sélectionné */
    prestationsAutorisees: Array<TravelHubPrestation> = [];

    /** Portail lié au SBT sélectionné */
    portail: string;

    /** Liste des SBT disponibles en fonction du de la config TH sélectionnée */
    listeConfigSBTFiltered: Array<TravelHubSBTConfig> = [];

    /**
     * Constructeur
     *
     * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
     * @param translateService Service de traduction
     * @param toastrService Service de notification
     * @param confirmService Service de confirmation utilisateur via modale
     * @param pleaseWaitService Service d'affichage du loading
     */
    constructor(public adminTravelhubService: AdminTravelhubService,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService,
                private pleaseWaitService: PleaseWaitService
    ) {
    }

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération du SBT dans un model indépendant pour pouvoir le manipuler en dehors de la configuration SBT en cours de modification
        this.sbtConfig = this.sbtConfigUsed.sbtConfig ? new TravelHubSBTConfig(this.sbtConfigUsed.sbtConfig) : null;

        //Rafraichissement de l'affichage
        this.refreshForm();
    }

    /**
     * Après l'initialisation de la vue
     */
    ngAfterViewInit() {
        setTimeout(() => {
            //Récupération du control associé au model du select de la configuration SBT
            let control = this.sbtConfigInput?.objectselect?.ngControl?.control;

            //Vérification de la récupération du control
            if (control) {
                //Définition du validator custom
                control.setValidators(control => {
                    //Si pas de numSBT, il faut synchroniser : KO
                    if (this.sbtConfig && !this.sbtConfig?.numSBT) {
                        return {'syncRequired': true} as ValidationErrors;
                    } else {
                        //Déjà synchronisé : OK
                        return null;
                    }
                });

                //On marque le control comme "touched" sinon le message ne s'affichera qu'une fois la configuration modifiée
                control.markAsTouched();

                //Lancement du contrôle de validité
                control.updateValueAndValidity({onlySelf: true,emitEvent: true});
            }
        });
    }

    /**
     * Traduction du libellé de la prestation
     *
     * @param prestation La prestation
     */
    getPrestationLibelle(prestation: TravelHubPrestation): string {
        const code = 'admin.voyages.travelhub.typePrestation.' + prestation.libelle;
        let trad = this.translateService.instant(code);
        if (trad !== code) {
            return trad;
        }
        return prestation.libelle;
    }


    /**
     * Récupération des détails (prestations, etc.) de la configuration SBT sélectionnée
     */
    synchronizeDetail(): void {
        let sbtConfigUsedCopy: TravelHubSBTConfigUsed;
        let waitDialogRef: MatDialogRef<PleaseWaitDialogComponent>;

        //Vérification de la configuration
        if (this.sbtConfigUsed && this.sbtConfigUsed.sbtConfig) {
            //Affichage d'une modale de chargement
            waitDialogRef = this.pleaseWaitService.show();

            //Duplication de la config
            sbtConfigUsedCopy = new TravelHubSBTConfigUsed(this.sbtConfigUsed);

            //Appel Ajax
            this.adminTravelhubService.synchroDetailSBT(sbtConfigUsedCopy)
                .pipe(finalize(() => waitDialogRef.close()))
                .subscribe(result => {
                    //Vérification du retour
                    if (result && result.data && result.data.isOk) {
                        //Mise à jour de la configuration SBT
                        this.sbtConfigUsed.sbtConfig = new TravelHubSBTConfig(result.data.sbtConfig);
                        this.sbtConfig = this.sbtConfigUsed.sbtConfig;

                        //Mise à jour des listes et rafraichissement de l'affichage
                        this.updateListesFromResult(result);

                        //Message de succès
                        this.toastrService.success(this.translateService.instant('admin.voyages.travelhub.sbtConfig.message.synchroSbtOk'));
                    } else {
                        //Message d'échec
                        this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.sbtConfig.message.synchroSbtKo'));
                    }
                }, () => {
                    //Message d'échec
                    this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.sbtConfig.message.synchroSbtKo'));
                });
        }
    }

    /**
     * Changement de configuration TravelHub
     *
     * @param value Configuration TravelHub sélectionnée
     */
    onChangeConfigTH(value: TravelHubConfig): void {
        //Recherche des config SBT possibles pour cette config TH sélectionnée
        let listeTravelHubSBTConfig = this.listeConfigSBT.filter(c => c.config.idConfig === value.idConfig);

        //Si une seule configuration SBT est possible, elle est sélectionnée par défaut
        if (listeTravelHubSBTConfig.length === 1) {
            //Association avec la seule configuration possible
            this.sbtConfigUsed.sbtConfig = new TravelHubSBTConfig(listeTravelHubSBTConfig[0]);
            this.sbtConfig = this.sbtConfigUsed.sbtConfig;
        } else {
            //Reset de la config SBT, mais on est obligé d'avoir un objet car c'est elle qui porte la configuration TH...
            this.sbtConfigUsed.sbtConfig = new TravelHubSBTConfig();
            this.sbtConfig = null;
        }

        //Mise à jour de la configuration TH
        this.sbtConfigUsed.sbtConfig.config = new TravelHubConfig(value);

        //Rafraichissement de l'affichage
        this.refreshForm();
    }

    /**
     * Changement de configuration SBT
     */
    onChangeConfigSBT(): void {
        let sbtConfigBackup: TravelHubSBTConfig;
        let sbtConfigUsedCopy: TravelHubSBTConfigUsed;

        if (this.sbtConfig && this.sbtConfigUsed.idSBTConfigUsed > 0) {
            //Sauvegarde du SBT précédent
            sbtConfigBackup = this.sbtConfigUsed.sbtConfig?.idSBTConfig > 0 ? new TravelHubSBTConfig(this.sbtConfigUsed.sbtConfig) : null;

            //Duplication de la config, avec le SBT sélectionné
            sbtConfigUsedCopy = new TravelHubSBTConfigUsed({...this.sbtConfigUsed,sbtConfig: this.sbtConfig});

            //Reset des paramètres
            this.refreshParametres(sbtConfigUsedCopy).pipe(first()).subscribe((res) => {
                //Vérification du retour
                if (res) {
                    //Émission du changement de SBT
                    this.sbtConfigChanged.next();
                } else {
                    //Reset du SBT avec la valeur précédente
                    this.sbtConfig = sbtConfigBackup;
                }
            });
        } else {
            //Mise à jour de la configuration avec le SBT sélectionné
            this.sbtConfigUsed.sbtConfig = new TravelHubSBTConfig(this.sbtConfig);

            //Mise à jour
            this.refreshForm();
        }
    }

    /**
     * Interception du changement de configuration sbt - Rafraichissement des paramètres
     *
     * @param sbtConfigUsed Configuration SBT sélectionnée
     */
    refreshParametres(sbtConfigUsed: TravelHubSBTConfigUsed): Observable<boolean> {
        //Demande de confirmation
        return this.confirmService.showConfirm(this.translateService.instant('admin.voyages.travelhub.sbtConfig.message.confirmResetSbtParams'))
            .pipe(switchMap((isConfirmed) => {
                if (isConfirmed) {
                    return this.adminTravelhubService.refreshParam(sbtConfigUsed).pipe(switchMap(result => {
                        //Vérification du code erreur
                        if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
                            //Message de succès
                            this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                            //Mise à jour de la configuration avec le SBT sélectionné
                            this.sbtConfigUsed.sbtConfig = sbtConfigUsed.sbtConfig;

                            //Mise à jour des listes de configuration TH et SBT disponibles
                            this.listeConfigTH = result.data.listeConfigTH.map(f => new TravelHubConfig(f));
                            this.listeConfigSBT = result.data.listeConfigSBT.map(f => new TravelHubSBTConfig(f));

                            //Mise à jour des listes à partir du résultat
                            this.updateListesFromResult(result);

                            //Construction de la liste des alertes
                            this.sbtConfigUsed.buildListeAlertes(result);

                            //Succès
                            return of(true);
                        } else {
                            //Message d'échec
                            this.toastrService.error(this.translateService.instant('admin.voyages.travelhub.sbtConfig.message.resetSbtParamsKo'));

                            //Erreur
                            return of(false);
                        }
                    }));
                } else {
                    //Annulation
                    return of(false);
                }
            }));
    }

    /**
     * Ajout / suppression le cas échéant des codes custom des CustomFields et des TripFields
     */
    updateCustomCodes(): void {
        let index;

        //Recherche de l'index du custom field correspondant au code custom dans la liste
        index = this.listeCustomField?.findIndex(cf => cf.idCustomField === -1);

        //Vérification des codes custom pour les custom fields
        if (this.sbtConfigUsed.sbtConfig?.customFieldLibre && index === -1) {
            //Ajout d'un choix dans la liste des custom fields
            this.listeCustomField.push(this.adminTravelhubService.getCustomCodeCF(this.sbtConfigUsed.sbtConfig));
        } else if (!this.sbtConfigUsed.sbtConfig?.customFieldLibre && index >= 0) {
            //Suppression du choix custom code
            this.listeCustomField.splice(index,1);
        }

        //Recherche de l'index du trip field correspondant au code custom dans la liste
        index = this.listeTripField?.findIndex(cf => cf.idTripField === -1);

        //Vérification des codes custom pour les trip fields
        if (this.sbtConfigUsed.sbtConfig?.tripFieldLibre && index === -1) {
            //Ajout d'un choix dans la liste des trip fields
            this.listeTripField.push(this.adminTravelhubService.getCustomCodeTF(this.sbtConfigUsed.sbtConfig));
        } else if (!this.sbtConfigUsed.sbtConfig?.tripFieldLibre && index >= 0) {
            //Suppression du choix custom code
            this.listeTripField.splice(index,1);
        }
    }

    /**
     * Mise à jour des données et des différentes listes à partir du résultat retourné par le back
     *
     * @param result Résultat retourné par le back
     */
    private updateListesFromResult(result: Result) {
        //Mise à jour des données de références
        this.listeParamConnexion = result.data.listeParamConnexion.map(f => new ParamConnexion(f));
        this.listeValeurParamConnexion = result.data.listeValeurParamConnexion.map(f => new ParamConnexionValeur(f));
        this.listeTripField = result.data.listeTripField.map(f => new TripField(f));
        this.listeCustomField = result.data.listeCustomField.map(f => new CustomField(f));

        //Mise à jour des données saisies
        this.listeParamConnexionUsed = result.data.listeParamConnexionUsed.map(f => new ParamConnexionUsed(f));
        this.listeTripFieldUsed = result.data.listeTripFieldUsed.map(f => new TripFieldUsed(f));
        this.listeCustomFieldUsed = result.data.listeCustomFieldUsed.map(f => new CustomFieldUsed(f));

        //Mise à jour de la liste des prestations disponibles
        this.listePrestaAutorisee = result.data.listePrestaAutorisee.map(f => new TravelHubPrestation(f));

        //Rafraichissement de l'affichage
        this.refreshForm();
    }

    /**
     * Rafraichissement des informations affichées
     */
    private refreshForm(): void {
        //Mise à jour des listes
        this.prestationsAutorisees = this.listePrestaAutorisee?.filter(p => p.sbtConfig.idSBTConfig === this.sbtConfigUsed.sbtConfig?.idSBTConfig)
                .map(f => new TravelHubPrestation(f));
        this.listeConfigSBTFiltered = this.listeConfigSBT?.filter(c => c.config?.idConfig === this.sbtConfigUsed?.sbtConfig?.config?.idConfig)
                .map(f => new TravelHubSBTConfig(f));

        //Si le SBT sélectionné est inactif il ne fait pas partie de la liste (non renvoyé par le back), il faut alors l'ajouter pour ne pas avoir une valeur vide
        if (this.sbtConfigUsed.sbtConfig?.actif === false) {
            //Ajout du SBT à la liste
            this.listeConfigSBTFiltered.push(this.sbtConfigUsed.sbtConfig);
        }

        //Recherche du portail
        this.portail = this.sbtConfigUsed.sbtConfig?.numSBT && (listeSBT.find(sbt => sbt.num_sbt === this.sbtConfigUsed.sbtConfig.numSBT)?.libelle) || '';

        //Gestion des codes custom pour les CustomFields et TripFields
        this.updateCustomCodes();
    }

}
