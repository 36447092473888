import {Component,Input,OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {PopupMouvementWfComponent} from "@share/component/outils/mouvement-wf/popup-mouvement-wf/popup-mouvement-wf.component";
import {TypePortee} from "@domain/workflow/workflow";
import {ODService} from "@components/od/od.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";

/**
 * Composant du cadre mouvement workflow de l'onglet outils - admin
 *
 * @author Angeline Ha
 * @date 30/08/2023
 */
@Component({
	selector: 'mouvement-wf',
	templateUrl: './mouvement-wf.component.html'
})
export class MouvementWfComponent implements OnInit{
	/** Accès à l'enum dans la vue */
	readonly TypePortee = TypePortee;

	/** Type de l'objet workflow */
	@Input() typePortee: TypePortee;

	/** Identifiant de l'objet */
	@Input() idObjet: number;

	/** Indique s'il y a un dossier voyage */
	hasDossierVoyage: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param matDialog Boîte de dialogue pour lancer un mouvement workflow
	 * @param odService Service des od
	 */
	constructor(private matDialog: MatDialog,
				private odService: ODService) {
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Si l'on est sur un od
		if (this.typePortee === TypePortee.OD) {
			//Vérification de la présence d'un dossier voyage
			this.odService.hasDossierVoyage(this.idObjet).subscribe((result: Result) => {
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					this.hasDossierVoyage = result.data.hasDossierVoyage;
				}
			})
		}
	}

	/**
	 * Affichage de la popup pour effectuer un mouvement workflow
	 *
	 * @param typePortee Portée de l'objet sur lequel effectuer le mouvement workflow
	 */
	showPopupMouvementWf(typePortee: TypePortee) {
		this.matDialog.open(PopupMouvementWfComponent, {
			data: {
				idObjet: this.idObjet,
				typePortee: typePortee
			}
		})
	}
}