<h1 mat-dialog-title>
    <span [translate]="'admin.voyages.travelhub.travelhub.details.title'"></span>
    <span [mat-dialog-close]="false"><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #form="ngForm">
        <fieldset>
            <div class="row">
                <!-- Libellé -->
                <custom-input
                        ngDefaultControl id="libelle" name="libelle"
                        customType="input" libelle="admin.voyages.travelhub.travelhub.libelle"
                        lCol="3" rCol="9"
                        [(ngModel)]="config.libelle"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Code client -->
                <custom-input
                        ngDefaultControl id="codeClient" name="codeClient"
                        customType="input" libelle="admin.voyages.travelhub.travelhub.codeClient"
                        lCol="3" rCol="9"
                        [(ngModel)]="config.codeClient"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- Mot de passe de connexion au TH -->
                <custom-input
                        ngDefaultControl id="motDePasse" name="motDePasse"
                        customType="input-obfuscated" libelle="admin.voyages.travelhub.travelhub.motDePasse"
                        lCol="3" rCol="9"
                        [(ngModel)]="config.password"
                        (onChange)="config.passwordChanged = true"
                        [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <!-- URL de connexion au TH -->
                <custom-input
                        ngDefaultControl id="url" name="url"
                        customType="input" libelle="admin.voyages.travelhub.travelhub.url"
                        lCol="3" rCol="9"
                        [(ngModel)]="config.url"
                        [required]="true">
                </custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions>
    <!-- Bouton de suppression -->
    <button mat-stroked-button color="warn" [disabled]="isProcessing" (click)="deleteConfig()" *ngIf="config.idConfig > 0">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <!-- Bouton de test -->
    <button mat-stroked-button color="primary" [disabled]="isProcessing" (click)="testConfig()">
        <span *ngIf="!isTesting" [translate]="'global.actions.tester'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isTesting"></mat-spinner>
    </button>
    <!-- Bouton d'enregistrement' -->
    <button mat-flat-button color="primary" [disabled]="form.invalid || isProcessing" (click)="saveConfig()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>