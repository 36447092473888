import {Compagnie} from "./compagnie";
import {TypeChampsCarte} from "./typeChampsCarte";
import {TypePresta} from "../travel/type-presta";

/**
 * Carte fidélité
 */
export class CarteTravel {
    actif?: boolean;
    compagnie?: Compagnie;
    custom?: boolean;
    dateCreated?: Date;
    dateUpdated?: Date;
    id?: number;
    idCarte?: number;
    idCarteTH?: number;
    libelle?: string;
    /** type de carte (Abonnement / Fidelite) */
    typeCarte?: string;
    /** Type de champ de la carte */
    typeChamp?: TypeChampsCarte;
    /** Type de la prestation */
    typePrestation?: TypePresta;
}
