<h1 mat-dialog-title>
    <span translate>facture.factureDepense.creation.title</span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <please-wait *ngIf="isLoading"></please-wait>
    <form autocomplete="off" #facturePrevAddForm="ngForm">
        <fieldset *ngIf="!isLoading" [disabled]="!data.canModifier">
            <div class="row">
                <!-- Prestation -->
                <custom-input ngDefaultControl name="prestation" libelle="facture.factureDepense.prestation"
                              customType="autocomplete" autocompleteType="prestation" [autocompleteFilter]="{ isFacturePrevisionnelle: false,typeObjet: TypePortee.FC,idObjet: data.factureDepense.idEntFacture }" optionDisplay="libelle" [idName]="'idPrestation'"
                              [disabled]="!data.canModifier" [(ngModel)]="prestation" [required]="true" (onChange)="onPrestationChange()"></custom-input>
                <!-- Quantité -->
                <custom-input ngDefaultControl name="quantite" customType="number" libelle="facture.factureDepense.quantite" [nbDecimales]="2" [suffix]="data.factureDepense.prestation?.unite?.libelle"
                              [(ngModel)]="data.factureDepense.quantite"
                              [disabled]="!data.canModifier || !data.factureDepense.prestation?.quantiteModifiable"
                              [required]="data.factureDepense.prestation?.quantiteObligatoire"></custom-input>
            </div>
            <div class="row">
                <!-- Nature de la prestation -->
                <custom-input ngDefaultControl name="nature" customType="select" libelle="facture.factureDepense.nature"
                              [selectOptions]="listeNature" optionIcone="icone" optionDisplay="libelle" optionValue="value" [isTranslated]="false"
                              [disabled]="!data.canModifier" [(ngModel)]="this.data.factureDepense.nature"></custom-input>
                <!-- Montant TTC -->
                <custom-input ngDefaultControl name="montant" customType="montant" libelle="facture.factureDepense.montantTTC"
                              [(ngModel)]="data.factureDepense.montant" [montantDevise]="data.facture.devise"
                              [required]="true" [disabled]="!data.canModifier"
                              (onBlur)="gestionTaxe('TTC')"></custom-input>
            </div>

            <div *ngIf="data.factureDepense.prestation && data.factureDepense.listeTaxe?.length > 0" class="row">
                <!-- Vide -->
                <div class="col-md-6"></div>

                <!-- Montant HT (visible seulement si au moins une taxe sur la prestation) -->
                <custom-input ngDefaultControl name="montantHT" customType="montant" libelle="facture.factureDepense.montantHT"
                              [(ngModel)]="montantHT" [montantDevise]="data.facture.devise"
                              [required]="true" [disabled]="!data.canModifier || isTauxMultiple"
                              (onBlur)="gestionTaxe('HT')"></custom-input>
            </div>

            <!-- Taxe(s) -->
            <div class="row" *ngFor="let taux of data.factureDepense.listeTaxe; let idxTaux = index;">
                <!-- Vide -->
                <div class="col-md-6"></div>

                <!-- Montant de la taxe -->
                <custom-input ngDefaultControl name="taux_{{ idxTaux }}" customType="number" nbDecimales="2" rCol="2"
                              [libelle]="'facture.factureDepense.tauxTaux'|translate:{taux:taux.taux|number:'1.3-3'}" isTranslated="true"
                              [(ngModel)]="taux.montantBase" [suffix]="data.canModifier ? tplBtnTaux : null"
                              [required]="true" [disabled]="!data.canModifier || !data.factureDepense.prestation.saisieTaxe"
                              (onBlur)="isTauxMultiple ? checkTaux(taux) : gestionTaxe('TAXE')"
                ></custom-input>

                <!-- Bouton de calcul auto du plafond / clear -->
                <ng-template #tplBtnTaux>
                    <button matSuffix mat-icon-button (click)="$event.stopPropagation(); calculTaxe(taux); computeMontantHT();">
                        <mat-icon *ngIf="taux.montantBase">close</mat-icon>
                        <mat-icon *ngIf="!taux.montantBase">edit</mat-icon>
                    </button>
                </ng-template>
            </div>

            <!-- Zone prédéfinie -->
            <zone-predefinie [listeFieldParam]="listeFieldParam" [fieldContainer]="fieldContainer"></zone-predefinie>

            <!-- Commentaire / remarque -->
            <div class="row">
                <custom-input ngDefaultControl name="remarque" customType="textarea" [libelle]="prestation?.libelleRemarque || ('facture.factureDepense.remarque' | translate)" rCol="10"
                              [(ngModel)]="data.factureDepense.remarque" [required]="data?.factureDepense?.prestation?.remarqueObligatoire" [disabled]="!data.canModifier"></custom-input>
            </div>
        </fieldset>
    </form>
</div>
<div mat-dialog-actions *ngIf="!isLoading && data.canModifier">
    <!-- Bouton de suppression -->
    <button mat-stroked-button color="primary" (click)="deleteFactureDepense()" [disabled]="isProcessing" *ngIf="data.factureDepense.idDetFacture > 0">
        <span *ngIf="!isDeleting" [translate]="'global.actions.supprimer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isDeleting"></mat-spinner>
    </button>
    <!-- Bouton d'enregistrement' -->
    <button mat-flat-button color="primary" [disabled]="facturePrevAddForm.invalid || isProcessing" (click)="saveFactureDepense()">
        <span *ngIf="!isSaving" [translate]="'global.actions.enregistrer'"></span>
        <mat-spinner class="m-5" diameter="28" *ngIf="isSaving"></mat-spinner>
    </button>
</div>