import {CurrencyPipe} from "@angular/common";
import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {AnalytiqueModule} from "./analytique/analytique.module";
import {AvanceModule} from "./avance/avance.module";
import {BudgetModule} from "./budget/budget.module";
import {CguModule} from "./cgu/cgu.module";
import {ChainageModule} from "./chainage/chainage.module";
import {ComptabiliteModule} from "./comptabilite/comptabilite.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {DelegationsModule} from "./delegations/delegations.module";
import {DocumentModule} from "./document/document.module";
import {ReleveFactureModule} from "./facture/releve-facture.module";
import {FicheCollaborateurModule} from "./fiche-collaborateur/fiche-collaborateur.module";
import {FraisModule} from "./frais/frais.module";
import {LieuModule} from "./lieu/lieu.module";
import {NDFModule} from "./ndf/ndf.module";
import {ODModule} from "./od/od.module";
import {OMPModule} from "./omp/omp.module";
import {ParticipantsModule} from "./participants/participants.module";
import {ProfilModule} from "./profil/profil.module";
import {ReferentielExterneModule} from "./referentiel-externe/referentiel-externe.module";
import {ReportingModule} from "./reporting/reporting.module";
import {SaisieModule} from "./saisie/saisie.module";
import {SettingsModule} from "./settings/settings.module";
import {TypeEntiteModule} from "./type-entite/type-entite.module";
import {VehiculeModule} from "./vehicule/vehicule.module";
import {ZonePredefinieModule} from "./zone-predefinie/zone-predefinie.module";
import {ZoneUtilisateurModule} from "./zone-utilisateur/zone-utilisateur.module";
import {FactureModule} from "@components/facture/facture.module";

@NgModule({
	providers: [CurrencyPipe],
	imports: [
		StoreModule,
		DashboardModule,
		NDFModule,
		ODModule,
		OMPModule,
		AnalytiqueModule,
		ReferentielExterneModule,
		SettingsModule,
		TypeEntiteModule,
		SaisieModule,
		ChainageModule,
		DocumentModule,
		LieuModule,
		BudgetModule,
		VehiculeModule,
		AvanceModule,
		ReportingModule,
		DelegationsModule,
		ReleveFactureModule,
		ProfilModule,
		ComptabiliteModule,
		FicheCollaborateurModule,
		ZoneUtilisateurModule,
		ZonePredefinieModule,
		ParticipantsModule,
		FraisModule,
		CguModule,
		FactureModule
	],
})
export class UserModule {}
