import {Component,Input,OnInit} from '@angular/core';
import {ImputationAvance,TypeImputation} from "@domain/avance/ImputationAvance";
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import {filterFirstNotNull} from "@share/utils/rxjs-custom-operator";
import {SettingsGlobalState} from "@domain/settings/settings";
import {NDFService} from "../ndf.service";

/**
 * Cadre "Avances imputées" sur le détail d'une NDF
 *
 * @author Laurent Convert
 * @date 04/04/2024
 */
@Component({
	host: {'data-test-id': 'imputation-avances'},
	selector: 'imputation-avances',
	templateUrl: './imputation-avances.component.html'
})
export class ImputationAvancesComponent implements OnInit {
	/* Déclaration pour accès dans le template */
	readonly TypeImputation = TypeImputation;

    /** Identifiant de la NDF */
    @Input()
    idNdf: number;

	/** Liste des avances imputées */
	listeImputation: Array<ImputationAvance>;

	/** Devise entreprise */
	deviseEntreprise: string;

	/**
	 * Construction
	 *
	 * @param ndfService Service des NDF
	 * @param store Le store de l'application
	 */
	constructor(private ndfService: NDFService,
				private store: Store<AppState>,
	) {
	}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
		//Récupération des settings depuis le store
		this.store.select(state => state.settings?.Global).pipe(filterFirstNotNull()).subscribe(
			(settings: SettingsGlobalState) => {
				//Récupération de la devise entreprise
				this.deviseEntreprise = settings.deviseEntreprise

				//Récupération de la liste des avances imputées et des résidus
				this.ndfService.getListeImputationAvance(this.idNdf).subscribe(listeImputation => {
					this.listeImputation = listeImputation;
				});
			});
    }
}