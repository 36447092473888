import {EnvironnementType} from "@components/admin/parametre/demat/module/demat-module.component";

/**
 * Module dans la liste des modules.
 *
 * @author François Turin
 * @date 16/06/2023
 */
export class ModuleItem {
	libelle: string;
	statut: boolean | string | EnvironnementType;
	type: TypeModule;

	constructor(typeModule: TypeModule) {
		this.type = typeModule;
	}

}

export enum TypeModule {
	LICENCE,ENV,MAIL,GED
}
