import {FicheCollaborateurModule} from './../fiche-collaborateur/fiche-collaborateur.module';
import {ZoneUtilisateurModule} from './../zone-utilisateur/zone-utilisateur.module';
import {NgModule} from '@angular/core';

import {ShareModule} from '@share/share.module';
import {AnalytiqueModule} from '../analytique/analytique.module';
import {DocumentModule} from "../document/document.module";
import {LieuModule} from "../lieu/lieu.module";

import {OMPService} from './omp.service';

import {OmpListComponent} from './omp-list/omp-list.component';
import {OmpListItemComponent} from './omp-list/omp-list-item/omp-list-item.component';
import {OMPComponent} from './detail/omp.component';
import {OMPGeneralitesComponent} from './detail/generalites/omp-generalites.component';
import {OMPTransportListComponent} from "./detail/transport/omp-transport-list.component";
import {OMPTransportAddComponent} from './detail/transport/omp-transport-add.component';
import {OMPZoneListComponent} from './detail/zone/omp-zone-list.component';
import {OMPZoneAddComponent} from './detail/zone/omp-zone-add.component';
import {OMPOdListComponent} from './detail/od/omp-od-list.component';
import {OMPOdListItemComponent} from './detail/od/omp-od-list-item.component';
import {OMPNdfListComponent} from './detail/ndf/omp-ndf-list.component';
import {OMPNdfListItemComponent} from './detail/ndf/omp-ndf-list-item.component';
import {ReferentielExterneModule} from "../referentiel-externe/referentiel-externe.module";
import {WorkflowModule} from '../workflow/workflow.module';
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {ProprietaireModule} from "@share/component/proprietaire/proprietaire.module";
import {AdminWorkflowModule} from "@components/admin/workflow/admin-workflow.module";
import {AdminOutilsModule} from "@share/component/outils/admin-outils.module";

@NgModule({
    imports: [ShareModule, WorkflowModule, AnalytiqueModule, DocumentModule, LieuModule, ReferentielExterneModule, FicheCollaborateurModule, ZoneUtilisateurModule, PreviewAlerteModule, ProprietaireModule,AdminOutilsModule,AdminWorkflowModule],
    declarations: [OmpListComponent, OmpListItemComponent, OMPComponent, OMPGeneralitesComponent, OMPTransportListComponent, OMPTransportAddComponent, OMPZoneListComponent, OMPZoneAddComponent, OMPOdListComponent, OMPOdListItemComponent, OMPNdfListComponent, OMPNdfListItemComponent],
    entryComponents: [OmpListItemComponent],
    exports: [
        OmpListComponent
    ],
    providers: [OMPService]
})
export class OMPModule {}
