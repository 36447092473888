<div *ngIf="statut == KeyStatutApplication.HORS_LIGNE_TEMPO" class="statut-container non-clickable locked">
    <mat-icon class="non-clickable">error_outline</mat-icon>
    <div class="statut-message non-clickable">
        <div class="message-principal">{{ 'admin.statut.application.horsLigne1' | translate }}</div>
        <div>{{ 'admin.statut.application.horsLigne2' | translate }}</div>
        <mat-progress-bar
                class="statut-bar"
                mode="buffer"
                [bufferValue]="100"
                [value]="progression">
        </mat-progress-bar>
    </div>
</div>
