/**
 * Classe des infos collaborateur
 */
export class CollabInfo {
	idUser: number;
	idStatut: number;
	idQualification: number;
	attribut1: string;
	attribut2: string;
	attribut3: string;
	attribut4: string;
}