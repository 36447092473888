import {ListItem} from "../common/list-view/list-item";
import {ParticipantExterne} from "./participant-externe";
import {User} from "../user/user";
import {ParticipantAvecFavoris, TypeParticipant} from "./participant-avec-favoris";

/**
 * Représente un participant interne ou externe dans la liste des participants d'un OD ou des invités d'une dépense
 */
export abstract class ParticipantLien implements ListItem {
    /** Participant externe */
    participantExterne: ParticipantExterne;

    /** Indique si l'utilisateur est en favoris (donnée ne venant pas du back) */
    isFavoris?: boolean;

    abstract get id(): number;
    abstract set id(id: number);

    abstract get participantInterne(): User;
    abstract set participantInterne(participantInterne: User);

    abstract get notOrganisateur(): boolean;
    abstract set notOrganisateur(notOrganisateur: boolean);

    getKey(): number {
        return this.participantInterne ? this.participantInterne.idUser : -this.participantExterne?.idParticipantExterne;
    }

    /**
     * Initialisation du lien à partir d'un participant externe
     *
     * @param participantExterne
     */
    initFromParticipantExterne(participantExterne: ParticipantExterne) {
        this.participantExterne = participantExterne;
        this.notOrganisateur = true;
    }

    /**
     * Initialisation du lien à partir d'un participant avec favoris
     *
     * @param participant
     */
    initFromParticipantAvecFavoris(participant: ParticipantAvecFavoris) {
        if (participant) {
            this.notOrganisateur = true;

            //Cas du participant interne
            if (participant.typeParticipant == TypeParticipant.INTERNE) {
                const user: User = new User();
                user.nom = participant.nom;
                user.prenom = participant.prenom;
                user.matricule = participant.matricule;
                user.idUser = participant.idParticipant;
                user.orga = {
                    libelle: participant.libelleService
                }

                this.participantInterne = user;
            } else {
                //Cas du participant externe
                const participantExterne: ParticipantExterne = new ParticipantExterne();
                participantExterne.nom = participant.nom;
                participantExterne.prenom = participant.prenom;
                participantExterne.idParticipantExterne = participant.idParticipant;
                participantExterne.societe = participant.libelleSociete;

                this.participantExterne = participantExterne;
            }

            this.isFavoris = participant.idUserOrigineFav != null;
        }
    }

}