import { Component } from "@angular/core";

/**
 * Onglet factures tolérances
 *
 * @author Guillaume TRAVOT
 * @date 21/11/2023
 */
@Component({
    host: {'data-test-id': 'factures-tolerances'},
    templateUrl: './factures-tolerances.component.html'
})
export class FacturesTolerancesComponent {}
