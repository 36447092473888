<mat-card>
    <mat-card-title-group>
        <mat-card-title>
            <span [translate]="'admin.maintenance.logs.fichierCard.title'"></span>
        </mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
        <div class="row">
            <custom-input ngDefaultControl
                       id="logFile"
                       name="logFile"
                       [(ngModel)]="configLoging.logFile"
                       customType="input"
                       libelle="admin.maintenance.logs.fichierCard.emplacement"
                       readonly="true">
            </custom-input>
        </div>
    </mat-card-content>
</mat-card>
