import {TemplateRef} from "@angular/core";

export class FillingRow {
	/** Template à afficher */
	template: TemplateRef<any> | null;

	/**
	 * true si le template doit prendre toute la ligne. false par défaut.<br>
	 * Ce paramètre n'est pas pris en compte pour les éléments de la colonne de droite.
	 * Pour afficher un élément sur toute une ligne il faut le mettre dans les éléments de gauche.
	 */
	isFullRow?: boolean = false;

	/** Constructeur */
	constructor(template: TemplateRef<any> | null, isFullRow: boolean = false) {
		this.template = template;
		this.isFullRow = isFullRow;
	}
}
