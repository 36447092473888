<h1 mat-dialog-title>
    <span [translate]="'profilUser.vehicule.add.title'"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form autocomplete="off" #vehiculeForm="ngForm" name="form_vehicule">
        <fieldset>
            <div class="row">
                <custom-input
                        [(ngModel)]="vehicule.immatriculation"
                        (input)="formaterImmatriculation()"
                        customType="input"
                        libelle="vehicule.liste.immatriculation"
                        [postTooltip]="'vehicule.formulaire.immatTooltip' | translate"
                        maxlength="20"
                        name="libelle"
                        ngDefaultControl
                        [required]="true">
                </custom-input>
                <label [translate]="'vehicule.formulaire.actif'" class="col-md-2"></label>
                <div class="col-md-4 d-flex flex-row align-items-center">
                    <mat-checkbox [(ngModel)]="vehicule.actif" color="primary" name="actif">{{'vehicule.formulaire.actifDescriptif' | translate}}</mat-checkbox>
                </div>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="vehicule.marque"
                              customType="input"
                              libelle="vehicule.formulaire.marque"
                              name="marque"
                              ngDefaultControl
                              [required]="true">
                </custom-input>
                <custom-input [(ngModel)]="vehicule.puissanceFiscale"
                              [selectOptions]="data.listePuissance"
                              customType="select"
                              libelle="vehicule.liste.puissanceFiscale"
                              name="puissanceVehicule"
                              ngDefaultControl
                              optionDisplay="libelle"
                              [required]="true">
                </custom-input>
            </div>
            <div class="row">
                <custom-input [(ngModel)]="vehicule.modele"
                              customType="input"
                              libelle="vehicule.formulaire.modele"
                              name="modele"
                              ngDefaultControl
                              [required]="true">
                </custom-input>
            </div>
            <div class="row align-items-center">
                <label class="col-md-2 text-right"><span [translate]="'vehicule.formulaire.assurance'"></span></label>
                <div class="col-md-4">
                    <mat-checkbox [(ngModel)]="vehicule.assurePro" color="primary" name="assure">
                        {{'vehicule.formulaire.assuranceDescriptif' | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </fieldset>
    </form>

</div>
<div align="end" mat-dialog-actions>
    <button (click)="save()" [disabled]="vehiculeForm.invalid || isSaving || isDeleting" color="primary" mat-flat-button>
        <span *ngIf="!isSaving" [translate]="'global.actions.ajouter'"></span>
        <mat-spinner *ngIf="isSaving" class="m-5" diameter="28"></mat-spinner>
    </button>
</div>
