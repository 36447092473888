import {NgModule} from "@angular/core";
import {MatDividerModule} from "@angular/material/divider";
import {Routes} from "@angular/router";
import {AnalytiqueParametresComponent} from '@components/admin/entreprise/analytique/analytique-parametres/analytique-parametres.component';
import {AnalytiqueFiltreItemComponent} from '@components/admin/entreprise/analytique/analytique-popup-ajout-filtres/analytique-filtre-item/analytique-filtre-item.component';
import {AnalytiquePopupAjoutFiltresComponent} from '@components/admin/entreprise/analytique/analytique-popup-ajout-filtres/analytique-popup-ajout-filtres.component';
import {AnalytiqueAxe5ItemComponent} from "@components/admin/entreprise/analytique/axe5/analytique-axe5-item/analytique-axe5-item.component";
import {AnalytiqueAxe5Component} from "@components/admin/entreprise/analytique/axe5/analytique-axe5.component";
import {AnalytiqueAxe5InfosComponent} from "@components/admin/entreprise/analytique/axe5/axe5-infos/analytique-axe5-infos.component";
import {AnalytiqueAxe6ItemComponent} from "@components/admin/entreprise/analytique/axe6/analytique-axe6-item/analytique-axe6-item.component";
import {AnalytiqueAxe6Component} from "@components/admin/entreprise/analytique/axe6/analytique-axe6.component";
import {AnalytiqueAxe6InfosComponent} from "@components/admin/entreprise/analytique/axe6/axe6-infos/analytique-axe6-infos.component";
import {AnalytiqueCodesProjetComponent} from "@components/admin/entreprise/analytique/codes-projet/analytique-codes-projet.component";
import {AnalytiqueCodesProjetInfosComponent} from "@components/admin/entreprise/analytique/codes-projet/codes-projet-infos/analytique-codes-projet-infos.component";
import {AnalytiqueCodesProjetItemComponent} from "@components/admin/entreprise/analytique/codes-projet/codes-projets-item/analytique-codes-projet-item.component";
import {EntrepriseAnalytiqueComponent} from '@components/admin/entreprise/analytique/entreprise-analytique.component';
import {AnalytiqueEnveloppesComponent} from "@components/admin/entreprise/analytique/enveloppes/analytique-enveloppes.component";
import {EnveloppeDetailsComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-details.component";
import {EnveloppeItemComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-item/enveloppe-item.component";
import {AnalytiqueOrganigrammeComponent} from "@components/admin/entreprise/analytique/organigramme/analytique-organigramme.component";
import {AnalytiqueOrganigrammeInfosComponent} from "@components/admin/entreprise/analytique/organigramme/organigramme-infos/analytique-organigramme-infos.component";
import {
    AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent
} from "@components/admin/entreprise/analytique/organigramme/organigramme-infos/organigramme-liste-utilisateur-item/organigramme-liste-utilisateur-item.component";
import {
    AnalytiqueOrganigrammeOrgaListeUtilisateurComponent
} from "@components/admin/entreprise/analytique/organigramme/organigramme-infos/organigramme-liste-utilisateur/organigramme-liste-utilisateur.component";
import {AnalytiqueOrganigrammeItemSousOrgaComponent} from "@components/admin/entreprise/analytique/organigramme/organigramme-item-sous-orga/analytique-organigramme-item-sous-orga.component";
import {AnalytiqueOrganigrammeItemComponent} from "@components/admin/entreprise/analytique/organigramme/organigramme-item/analytique-organigramme-item.component";
import {TranslateModule} from '@ngx-translate/core';
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {LoginService} from "@share/login/login.service";
import {ShareModule} from "@share/share.module";
import {DocumentModule} from "../../../document/document.module";
import {LieuModule} from "../../../lieu/lieu.module";
import {ProfilModule} from "../../../profil/profil.module";
import {ProfilService} from "../../../profil/profil.service";
import {MatRadioModule} from "@angular/material/radio";
import {EnveloppeMontantsComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-montants/enveloppe-montants.component";
import {MontantItemComponent} from "./enveloppes/enveloppe-details/enveloppe-montants/montant-item/montant-item.component";
import {MontantEditComponent} from "./enveloppes/enveloppe-details/enveloppe-montants/montant-edit/montant-edit.component";
import {EnveloppeDetailService} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-detail.service";
import {PageHeaderModule} from "@share/component/page-header/page-header.module";
import {EnveloppeConsommationComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-consommation/enveloppe-consommation.component";
import {EnveloppeConsommationItemComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-consommation/enveloppe-consommation-item.component";
import {AnalytiqueModule} from "@components/analytique/analytique.module";
import {MontantReaffectationComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-montants/reaffectation/montant-reaffectation.component";
import {EnveloppeGeneraliteComponent} from "@components/admin/entreprise/analytique/enveloppes/enveloppe-details/enveloppe-generalite/enveloppe-generalite.component";
import {DroitAdmin} from "@core/security/droit-admin";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";

export const entrepriseAnalytiqueRoutes : Routes = [
    {
        path: 'Services/:idService',
        component: AnalytiqueOrganigrammeInfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ORGANIGRAMME] }
    }, {
        path: 'Services',
        component: AnalytiqueOrganigrammeComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ORGANIGRAMME] }
    }, {
        path: 'Projets/:idCodeProjet',
        component: AnalytiqueCodesProjetInfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ANALYTIQUE_DOSSIER] }
    }, {
        path: 'Projets',
        component: AnalytiqueCodesProjetComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ANALYTIQUE_DOSSIER] }
    }, {
        path: 'Axe5/:idAxe5',
        component: AnalytiqueAxe5InfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ANALYTIQUE_AXE_5] }
    }, {
        path: 'Axe5',
        component: AnalytiqueAxe5Component,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ANALYTIQUE_AXE_5] }
    }, {
        path: 'Axe6/:idAxe6',
        component: AnalytiqueAxe6InfosComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ANALYTIQUE_AXE_6] }
    }, {
        path: 'Axe6',
        component: AnalytiqueAxe6Component,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_ANALYTIQUE_AXE_6] }
    }, {
        path: 'Enveloppes/:idEnveloppe/Consommation',
        component: EnveloppeDetailsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_BUDGET_ENVELOPPE] }
    }, {
        path: 'Enveloppes/:idEnveloppe/Montants',
        component: EnveloppeDetailsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_BUDGET_ENVELOPPE] }
    }, {
        path: 'Enveloppes/:idEnveloppe',
        component: EnveloppeDetailsComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_BUDGET_ENVELOPPE] }
    }, {
        path: 'Enveloppes',
        component: AnalytiqueEnveloppesComponent,
        canActivate: [AdminGuardProvider],
        data: { sousAdminCredentials: [DroitAdmin.DROIT_BUDGET_ENVELOPPE] }
    }, {
        path: 'Parametres',
        component: AnalytiqueParametresComponent,
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Services'
    }, {
        path: '**',
        redirectTo: 'Services'
    }
];

@NgModule({
    imports: [
        ShareModule,
        LieuModule,
        ProfilModule,
        DocumentModule,
        PreviewAlerteModule,
        MatDividerModule,
        TranslateModule,
        MatRadioModule,
        PageHeaderModule,
        ShareModule,
        AnalytiqueModule,
    ],
    declarations: [
        AnalytiqueParametresComponent,
        AnalytiqueOrganigrammeComponent,
        AnalytiqueOrganigrammeItemComponent,
        AnalytiqueOrganigrammeItemSousOrgaComponent,
        AnalytiqueOrganigrammeInfosComponent,
        AnalytiqueOrganigrammeOrgaListeUtilisateurComponent,
        AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent,
        AnalytiqueCodesProjetComponent,
        AnalytiqueCodesProjetItemComponent,
        AnalytiqueCodesProjetInfosComponent,
        AnalytiqueAxe5Component,
        AnalytiqueAxe5ItemComponent,
        AnalytiqueAxe5InfosComponent,
        AnalytiqueAxe6Component,
        AnalytiqueAxe6ItemComponent,
        AnalytiqueAxe6InfosComponent,
        AnalytiqueEnveloppesComponent,
        EnveloppeDetailsComponent,
        EnveloppeItemComponent,
        EntrepriseAnalytiqueComponent,
        AnalytiqueFiltreItemComponent,
        AnalytiquePopupAjoutFiltresComponent,
        EnveloppeGeneraliteComponent,
        EnveloppeMontantsComponent,
        EnveloppeConsommationComponent,
        EnveloppeConsommationItemComponent,
        MontantItemComponent,
        MontantEditComponent,
        MontantReaffectationComponent
    ],
    entryComponents: [],
    exports: [
        AnalytiqueParametresComponent,
        AnalytiqueOrganigrammeComponent,
        AnalytiqueOrganigrammeItemComponent,
        AnalytiqueOrganigrammeItemSousOrgaComponent,
        AnalytiqueOrganigrammeInfosComponent,
        AnalytiqueOrganigrammeOrgaListeUtilisateurComponent,
        AnalytiqueOrganigrammeOrgaListeUtilisateurItemComponent,
        AnalytiqueAxe5Component,
        AnalytiqueCodesProjetComponent,
        AnalytiqueCodesProjetItemComponent,
        AnalytiqueCodesProjetInfosComponent,
        AnalytiqueAxe5ItemComponent,
        AnalytiqueAxe6Component,
        AnalytiqueAxe6ItemComponent,
        AnalytiqueAxe6InfosComponent,
        AnalytiqueEnveloppesComponent,
        AnalytiqueFiltreItemComponent,
        AnalytiquePopupAjoutFiltresComponent,
        AnalytiqueEnveloppesComponent,
        EnveloppeMontantsComponent,
    ],
    providers: [
        ProfilService,
        LoginService,
        AnalytiqueService,
        EnveloppeDetailService,
    ]
})
export class EntrepriseAnalytiqueModule {
}
