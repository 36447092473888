import {Assurance} from "./assurance";
import {Autorisation} from "./autorisation";
import {CarteGrise} from "./cartegrise";
import {PuissanceFiscale} from "./puissancefiscale";
import {UserDto} from "../user/user-dto";
import {VehiculeApprobation} from "./vehiculeapprobation";
import {Compteur} from "./compteur";
import {ListItem} from "../common/list-view/list-item";
import {AlertesVehicule} from "./alertesVehicule";

/**
 * Enumération des statuts des véhicules
 */
export enum Status {
    /* Enumération */
    STATUT_NON_TRAITE = 0,
    STATUT_VALIDE = 1,
    STATUT_REFUSE = 2,
    STATUT_BROUILLON = 3
}

/**
 * Classe représentant les véhicules
 */
export class Vehicule implements ListItem {
    isOpened: boolean;
    actif?: boolean;
    assurePro?: boolean;
    carburant?: number;
    user?: UserDto;
    compteurEnCours?: number;
    compteurValide?: number;
    id?: number;
    idCollab?: number;
    idPa?: number;
    idPuissanceFiscale?: number;
    idType?: number;
    vehiculeApprobation?: VehiculeApprobation;
    immatriculation?: string;
    immatriculationChanged?: boolean;
    inError?: false;
    listeAssurance?: Array<Assurance>;
    listeAutorisation?: Array<Autorisation>;
    listeCarteGrise?: Array<CarteGrise>;
    listeCompteur?: Array<Compteur>;
    marque?: string;
    modele?: string;
    nbPj?: number;
    nbRattach?: number;
    puissanceFiscale?: PuissanceFiscale;
    statut?: Status;
    tauxCo2?: number;
    type?: number;
    libellePuissanceFiscale?: string;

    alertes: AlertesVehicule;

    getKey(): number {
        return this.idPa;
    }

    constructor() {
        this.id = 0;
        this.idPa = 0;
        this.actif = true;
        this.assurePro = false;
        this.statut = Status.STATUT_BROUILLON;

        this.alertes = new AlertesVehicule();
    }
}
