import {TypeRuleFilterOperator} from "@domain/rule/type-rule-filter-operator";
import {RuleFilterValue} from "@domain/rule/rule-filter-value";
import {TypeAgregation} from "@domain/rule/type-agregation";
import {EntityTypeValue} from "@share/component/rule-builder/rule-repository.service";
import {EntityWrapper} from "@domain/entite/EntityWrapper";

/**
 * Modèle d'un filtre de règle
 */
export class RuleFilter {
	/** Identifiant */
	idFilter: number;
	/** Champ du filter */
	filter: string;
	/** Type du filter */
	type: string;
	/** Libellé à afficher */
	libelle: string;
	/** Opérateur */
	operateur: TypeRuleFilterOperator;
	/** Liste des RuleFilterValue */
	listeValues: RuleFilterValue[];
	/** Type d'agrégation */
	typeAgregation: TypeAgregation;
	/** Ce filter est-il une énumération */
	enum: boolean;
	/** Type d'autocomplete */
	autocompleteType: string;
	/** Filtre de l'autocomplete */
	autocompleteFilter: string;
	/** Ce filter contient-il une Rule embarquée (relations 1 - n) */
	embeddedRule: boolean;
	/** La condition embarquée de ce filter est-elle un "not exists". Faux par défaut */
	notExists: boolean;

	//Données utilisées dans le front uniquement
	fieldType: EntityTypeValue;
	childEntity: EntityWrapper;
	childEntityLoaded: boolean;
}