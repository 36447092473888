import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {TypePortee} from "@domain/workflow/workflow";
import {Observable} from "rxjs";
import {Result} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {Condition} from "@domain/workflow/condition";

/**
 * Service de gestion du menu Workflow - Conditions
 */
@Injectable()
export class WorkflowConditionsService {
	/**
	 * Constructeur
	 *
	 * @param httpClient le client HTTP
	 */
	constructor(
		private httpClient: HttpClient
	) {
	}

	/**
	 * Définition de l'icône de liste en fonction de la portée
	 *
	 * @param idPortee la portée
	 */
	static getClasseIcone(idPortee: string): string {
		switch (idPortee) {
			case TypePortee.AV:
				return 'nio icon-avance_2';
			case TypePortee.DV:
				return 'nio icon-avion';
			case TypePortee.FC:
				return 'nio icon-releve_de_facture';
			case TypePortee.NF:
				return 'nio icon-note_de_frais';
			case TypePortee.OD:
				return 'nio icon-ordre_de_mission';
			case TypePortee.OT:
				return 'nio icon-ordre_de_mission_permanent';
		}
	}

	/**
	 * Chargement d'une alerte
	 *
	 * @param idAlerte l'identifiant de l'alerte
	 */
	loadAlerte(idAlerte: number): Observable<Result> {
		return this.httpClient.get<Result>(`${environment.baseUrl}/controller/Alerte/getAlerte/${idAlerte}`);
	}

	/**
	 * Enregistrement d'une alerte
	 *
	 * @param alerte l'alerte
	 */
	saveAlerte(alerte: Condition): Observable<Result> {
		return this.httpClient.put<Result>(`${environment.baseUrl}/controller/Alerte/saveAlerte`,alerte);
	}

	/**
	 * Suppression d'une alerte
	 *
	 * @param idAlerte l'identifiant de l'alerte
	 */
	deleteAlerte(idAlerte: number): Observable<Result> {
		return this.httpClient.delete<Result>(`${environment.baseUrl}/controller/Alerte/${idAlerte}`);
	}

	/**
	 * Chargeemnt d'une condition de visibilité
	 *
	 * @param idCondition l'identifiant de la condition de visibilité
	 */
	loadCondition(idCondition: number): Observable<Result> {
		return this.httpClient.get<Result>(`${environment.baseUrl}/controller/Condition/loadCondition/${idCondition}`);
	}

	/**
	 * Enregistrement d'une condition de visibilité
	 *
	 * @param condition la condition de visibilité
	 */
	saveCondition(condition: Condition): Observable<Result> {
		return this.httpClient.put<Result>(`${environment.baseUrl}/controller/Condition/saveCondition`,condition);
	}

	/**
	 * Suppression d'une condition de visibilité
	 *
	 * @param idCondition l'identifiant de la condition de visibilité
	 */
	deleteCondition(idCondition: number): Observable<Result> {
		return this.httpClient.delete<Result>(`${environment.baseUrl}/controller/Condition/deleteCondition?id_condition=${idCondition}`);
	}
}