import {Component, Input, OnInit} from '@angular/core';
import {Iata, OptionType, TypeIata} from "@domain/geographie/iata";
import {GeographieView} from "@domain/geographie/geographieView";
import {TypeGeographie} from "@domain/geographie/typeGeographie";
import {ControlContainer, NgForm} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

/**
 * Composant de l'onglet 'Généralités' d'un code IATA
 *
 * @author Angeline Ha
 * @date 13/02/2024
 */
@Component({
    host: {'data-test-id': 'iata-generalites'},
    selector: 'iata-generalites',
    templateUrl: './iata-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class IataGeneralitesComponent implements OnInit {
    /** Accès à l'enum dans la vue */
    readonly TypeIata = TypeIata;

    /** IATA */
    @Input() iata: Iata;

    /** Modèle pour l'autocomplete de la ville */
    selectedVille: GeographieView

    /** Liste de tous les types possibles */
    listeTypes: OptionType[];

    /**
     * Constructeur
     *
     * @param translateService Service des traductions
     */
    constructor(private translateService: TranslateService) {
    }

    /**
     * Initialisation
     */
    ngOnInit() {
        //Récupération de la ville pour l'autocomplete
        this.selectedVille = {
            id: this.iata.idVille,
            type: TypeGeographie.VILLE,
            libelle: this.iata.ville?.libelle,
            ville: this.iata.ville,
        }

        //Définition de la liste des types de iata possibles
        this.listeTypes = [
            {
                id: TypeIata.AEROPORT,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.iata.aeroport')
            }, {
                id: TypeIata.GARE,
                libelle: this.translateService.instant('admin.bibliotheque.geographie.iata.gare')
            }
        ]
    }

    /**
     * Mise à jour de l'objet de saisie avec la ville sélectionnée dans l'autocomplete
     */
    onVilleChange() {
        this.iata.idVille = this.selectedVille.id;
        this.iata.ville = this.selectedVille.ville;
    }

}
