import {AfterContentInit,Component,Input} from '@angular/core';
import {CarteHierarchique} from "@share/component/affichage-hierarchique/CarteHierarchique";
import {ArbreHierarchique,CheckStatus} from "@share/component/affichage-hierarchique/ArbreHierarchique";
import {TypePresta} from "@domain/travel/type-presta";
import cleTraduction = TypePresta.cleTraduction;
import {DetailName} from "@components/admin/workflow/roles/roles/detail/droits/carte-hierarchique-droits";

/**
 * Composant d'affichage des arbres hiérarchiques
 *
 * @author Laurent SCIMIA
 * @date 15/12/2023
 */
@Component({
	host: {'data-test-id': 'affichage-hierarchique'},
	selector: 'affichage-hierarchique',
	templateUrl: './affichage-hierarchique.component.html',
	styleUrls: ['./affichage-hierarchique.component.scss']
})
export class AffichageHierarchiqueComponent implements AfterContentInit {
	//Accès aux enums sur le template
	protected readonly CheckStatus = CheckStatus;
	protected readonly DetailName = DetailName;

	/** Liste des colonnes à afficher */
	@Input() listeColonnesAAfficher!: Array<TypeColonnesAAfficher>;

	/** Liste des arbres à afficher dans le cadre */
	@Input() listeCartesHierarchiques!: Array<CarteHierarchique>;

	/** Liste des identifiants des colonnes contextuelles du tableau, basées sur listeColonnesAAfficher */
	listeColonnes: Array<string>;

	/** Liste des identifiants de toutes les colonnes du tableau */
	listeColonnesEtendues: Array<string>;

	/** Liste des colonnes à afficher pour les enfants de l'arborescence */
	listeColonnesDetail: Array<string> = [];

	/** Après initialisation du contenu */
	ngAfterContentInit(): void {
		//On ajoute les colonnes standards à la liste des colonnes à afficher
		this.listeColonnes = this.listeColonnesAAfficher.map(col => col.id);
		this.listeColonnesEtendues = ['avatar','objet',...this.listeColonnes,'expand'];

		//On crée la liste des colonnes pour les enfants de l'arborescence
		this.listeColonnesEtendues.forEach(col => this.listeColonnesDetail.push(col + '_detail'));
	}

	/** Récupère la clé de traduction du titre d'une colonne */
	getCleTraduction(idColonne: string): string {
		//On cherche la colonne dans la liste des colonnes à afficher pour récupérer sa clé de traduction
		const strings = this.listeColonnesAAfficher
			.filter(col => col.id === idColonne)
			.map(col => col.cleTraduction);

		//Si on a bien trouvé une clé de traduction (ou plusieurs)
		if (strings.length > 0) {
			//On renvoie la première clé trouvée
			return strings[0];
		} else {
			//Si on ne trouve pas de clé de traduction, on n'envoie pas de traduction
			return '';
		}
	}

	/**
	 * Change l'état de sélection d'un arbre</br>
	 * Si tous les enfants sont sélectionnées, désélectionne tout, sinon sélectionne tout
	 *
	 * @param element Arbre à traiter
	 */
	switchCheckStatus(element: ArbreHierarchique) {
		//On vérifie si tous les enfants de l'arbre sont sélectionnés
		const isFullCheck: boolean = element.checkStatus() === CheckStatus.TOUS;

		//On agit sur tous les enfants
		element.listeBranches.forEach(item => {
			//Si on va décocher
			if (isFullCheck) {
				//On décoche
				item.checked = false;

				//On s'assure qu'il n'est pas désactivé
				item.disabled = false;
			} else if (item.priorite == null || item.priorite == 0) {
				//Si on veut tout cocher et que l'item n'a pas de priorité ou qu'il est prioritaire, on le check
				item.checked = true;
			} else if (item.priorite != null && item.priorite > 0) {
				//Si on veut tout cocher mais que l'item n'a pas la priorité, on le désactive (c'est l'option prioritaire qui sera cochée)
				item.disabled = true;
			}
		});
	}
}

/** Type à respecter pour la déclaration des colonnes à afficher */
export type TypeColonnesAAfficher = { id: string,cleTraduction: string };