import {Component,EventEmitter,HostListener,OnInit} from '@angular/core';
import {MontantPipe} from "@share/pipe/montant";
import {ListeDepenseFrais} from "@domain/ndf/liste-depense-frais";
import {MatDialog} from '@angular/material/dialog';
import {AlerteComponent} from '@components/workflow/alerte.component';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import roundHalfEven from "round-half-even";
import {ContextDocument} from "@domain/document/context-document";
import {SettingsNFState} from "@domain/settings/settings";
import {Ndf} from "@domain/ndf/ndf";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {Document} from "@domain/document/document";

@Component({
	host: {'data-test-id': 'ndf-frais-list-item'},
	templateUrl: './ndf-frais-list-item.component.html',
	styleUrls: ['./ndf-frais-list-item.component.scss']
})
export class NDFFraisListItemComponent extends ListViewItem<ListeDepenseFrais> implements OnInit {
	/* Déclaration pour accès dans le template */
	readonly ContextDocument = ContextDocument;

	/** Options supplémentaires */
	extraOptions: {
		canModifier: boolean,
		showFrais: (frais?: any) => void,
		settings: SettingsNFState,
		ndf: Ndf,
		checkFraisAlerte: EventEmitter<string>,
	};

	/** Montant remboursable convertit, utilisé quand on a une devise différente */
	montantRemboursableConverti: number;

	/** Toggle permettant d'afficher / masquer la zone de dépôt de fichier */
	showUploader: boolean;

	/**
	 * Constructeur
	 *
	 * @param montantPipe Directive de formatage des montants
	 * @param matDialog Boite de dialogue
	 * @param toastrService Service de notification
	 * @param translateService Service de traduction
	 */
	constructor(private montantPipe: MontantPipe,
	            private matDialog: MatDialog,
	            private toastrService: ToastrService,
	            private translateService: TranslateService,
	) {
		super();
	}

	/**
     * Initialisation
     */
    ngOnInit(): void {
        //Si la devise du frais n'est pas la même que celle de la NDF
        //On gère le cas particulier avec devise saisie != devise utilisateur
        if (this.data.operation && this.data.operation.devise?.code !== this.extraOptions.ndf.devise.code) {
			this.montantRemboursableConverti = roundHalfEven(this.data.operation.cours * this.data.montantRemboursable,2);
		}
    }

	/**
	 * Fonction de calcul du montant remboursable affiché dans la liste du frais
	 */
	getMontantRemboursable(): string {
		//Si la devise du frais est la même que celle de la NDF on doit avoir un montantRemboursableConverti null
		if (this.montantRemboursableConverti == null) {
			//On renvoie le montant remboursable dans sa devise d'origine
			return this.montantPipe.transform(this.data.montantRemboursable,this.data.operation ? this.data.operation.devise.code : this.data.deviseCodeFrais);
		} else {
			//Sinon on renvoie convertit le montant remboursable de la devise de la NDF
			return this.montantPipe.transform(this.montantRemboursableConverti,this.extraOptions.ndf.devise.code);
		}
	}

	/**
	 * Affichage de la popup de la liste des alertes
	 */
	showListeAlertes(): void {
		//Affichage de la popup de la liste des alertes
		this.matDialog.open(AlerteComponent,{
			data: {
				alertes: this.data.listeAlertes.listeAlertes
			}
		});
	}

	/**
	 * Retourne la devise de la dépense ou du frais
	 */
	getDeviseCode(): string {
		return this.data.operation?.devise?.code ?? this.data.deviseCodeFrais
	}

	/**
	 * Affichage de la zone de drag 'n drop
	 *
	 * @param $event Évènement déclenché lorsqu'un fichier entre dans l'élément
	 */
	@HostListener('dragenter',['$event'])
	showDropZone($event: Event) {
		//Annulation de l'évènement
		$event.preventDefault();

		//Vérification de la possibilité de modifier le frais
		if (this.extraOptions.canModifier) {
			//Affichage de la zone de drag 'n drop
			this.showUploader = true;
		}
	}

	/**
	 * Renvoie true si l'objet est un objet frais dans le back ou false si l'objet est un objet depense dans le back
	 */
	isFrais(): boolean {
		return this.data.idFrais != null && this.data.idFrais > 0;
	}

	/**
	 * Handler appelé lorsque toutes les pièces jointes ont été uploadées
	 *
	 * @param files Documents uploadés
	 */
	onAllPjUploaded(files: Array<Document>): void {
		if (files.length > 0) {
			//Initialisation de la liste des document le cas échéant
			if (!this.data.listeDocuments) {
				this.data.listeDocuments = [];
			}

			//Ajout des documents uploadés à la liste
			this.data.listeDocuments.push(...files);

			//Message de succès
			this.toastrService.success(this.translateService.instant('ndf.frais.messageAjoutDocument.' + (files.length > 1 ? 'plural' : 'one'),{count: files.length}));
		}

		//Masquage de la zone de drag 'n drop une fois terminé
		this.showUploader = false;
	}
}
