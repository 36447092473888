import {Component,Inject} from '@angular/core';
import {TypeFiltreZU} from "@domain/zone-utilisateur/filtre-zu";
import {TranslateService} from "@ngx-translate/core";
import {TypeEntite} from "@domain/typeentite/typeEntite";
import {Role} from "@domain/workflow/role";
import {AutocompleteType} from "@share/component/autocomplete/options/autocomplete-type";
import {setTimeout} from "core-js";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {ZonesUtilisateursService} from "@services/admin/zu/zones-utilisateurs.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {finalize,first} from "rxjs/operators";
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Composant de la popin d'ajout d'un filtre à une ZU
 */
@Component({
	host: {'data-test-id': 'add-filtre-zu-popin'},
	templateUrl: './add-filtre-zu-popin.component.html'
})
export class AddFiltreZuPopinComponent {
	/** Type de filtre sélectionné */
	selectedType: TypeFiltreZU;

	/** Liste des types de filtres */
	listeTypeFiltre: Array<{ code: TypeFiltreZU,libelle: string }> = [
		this.data.idPortee != TypePortee.AV && {
			code: TypeFiltreZU.TYPE_ENTITE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.typeFiltreZU.' + TypeFiltreZU.TYPE_ENTITE)
		},{
			code: TypeFiltreZU.ROLE,
			libelle: this.translateService.instant('admin.bibliotheque.zonesUtilisateurs.parametrables.detail.configuration.typeFiltreZU.' + TypeFiltreZU.ROLE)
		}
	].filter(i => !!i);

	/** Filtre sélectionné */
	selectedFiltre: TypeEntite | Role;

	/** Indicateur de traitement en cours */
	isPending: boolean = false;

	/**
	 * Constructeur
	 *
	 * @param data les données en entrée de la popin
	 * @param translateService le moteur de traduction
	 * @param zoneUtilisateursService service de gestion des ZU
	 * @param toastrService le toaster
	 * @param matDialogRef le ref à cette popin
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idZU: number,idPortee: string },
		private translateService: TranslateService,
		private zoneUtilisateursService: ZonesUtilisateursService,
		private toastrService: ToastrService,
		private matDialogRef: MatDialogRef<AddFiltreZuPopinComponent>
	) {
	}

	/**
	 * Méthode appelée à chaque changement de type
	 */
	onSelectedTypeChange(): void {
		const tmpType: TypeFiltreZU = this.selectedType;

		//On supprime le filtre courant s'il y en a déjà un
		delete this.selectedFiltre;

		//On supprime selectedType afin de recharger l'autocomplete (qui comporte un *ngIf(selectedType)) avec le nouveau autocompleteType
		delete this.selectedType;

		//setTimeout obligatoire pour que le passage de selectedType à null soit pris en compte
		setTimeout(() => {
			this.selectedType = tmpType;
		});
	}

	/**
	 * Récupération du type d'autocomplete en fonction du type de filtre sélectionné
	 */
	getAutocompleteType(): AutocompleteType {
		//Switch sur le type de filtre sélectionné
		switch (this.selectedType) {
			case TypeFiltreZU.TYPE_ENTITE:
				return 'typeEntite';
			case TypeFiltreZU.ROLE:
				return 'role';
		}
	}

	/**
	 * Récupération des filtres de l'autocomplete en fonction du type
	 */
	getAutocompleteFilter(): Record<string,any> {
		//Switch sur le type de filtre sélectionné
		switch (this.selectedType) {
			case TypeFiltreZU.TYPE_ENTITE:
				return {isAdmin: true,idPortee: this.data.idPortee}
			case TypeFiltreZU.ROLE:
				return {isCollab: false,fonction: 0}
		}
	}

	/**
	 * Récupération de l'idName en fonction du type de filtre sélectionné
	 */
	getIdName(): string {
		//Switch sur le type de filtre sélectionné
		switch (this.selectedType) {
			case TypeFiltreZU.TYPE_ENTITE:
				return 'idTypeEntite';
			case TypeFiltreZU.ROLE:
				return 'idRole';
		}
	}

	/**
	 * Ajout du filtre
	 */
	add(): void {
		//Chargement en cours
		this.isPending = true;

		//Détermination de l'identifiant de l'objet sélectionné
		let idObjet: number;
		switch (this.selectedType) {
			case TypeFiltreZU.TYPE_ENTITE:
				idObjet = (this.selectedFiltre as TypeEntite).idTypeEntite;
				break;
			case TypeFiltreZU.ROLE:
				idObjet = (this.selectedFiltre as Role).idRole;
				break;
		}

		//Appel au service d'ajout
		this.zoneUtilisateursService.addFiltre(this.data.idZU,idObjet,this.selectedType)
			.pipe(first(),finalize(() => this.isPending = false))
			.subscribe((result: Result) => {
				//Vérification du result
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Toast succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

					//Fermeture de la popin avec demande de refresh de la liste
					this.matDialogRef.close(true);
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			});
	}
}
