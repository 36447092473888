import {Adresse} from "@domain/profil/adresse";
import {Pays} from "@domain/geographie/pays";
import {ListItem} from "@domain/common/list-view/list-item";

/**
 * DTO d'une organisation
 */
export class Orga implements ListItem {
	idOrga: number;
	code: string;
	idParent: number;
	parent: Orga;
	position: number;
	numService: string;
	libelle: string;
	adresse: Adresse;
	dateFin: Date;
	isActif: boolean;
	pays: Pays;
	ville: string;
	rue: string;
	tsio: number;
	imputable: boolean;
	dateFinImputable: Date;
	affectable: boolean;
	dateFinAffectable: Date;
	etablissement: boolean; //isEtablissement
	nbUsers: number;
	nbChildren: number;
	attribut1: string;
	attribut2: string;
	attribut3: string;
	attribut4: string;
	idLoc: number;
	idLogo: number;
	nomLogo: string;
	tailleLogo: number;

	constructor(dto: any) {
		if (dto) {
			this.idOrga = dto.idOrga;
			this.code = dto.code;
			this.idParent = dto.idParent;
			this.parent = dto.parent;
			this.position = dto.position;
			this.numService = dto.numService;
			this.libelle = dto.libelle;
			this.adresse = dto.adresse;
			if (dto.dateFin) this.dateFin = new Date(dto.dateFin);
			this.isActif = dto.isActif;
			this.pays = dto.pays;
			this.ville = dto.ville;
			this.rue = dto.rue;
			this.tsio = dto.tsio;
			this.imputable = dto.imputable;
			if (dto.dateFinImputable) this.dateFinImputable = new Date(dto.dateFinImputable);
			this.affectable = dto.affectable
			if (dto.dateFinAffectable) this.dateFinAffectable = dto.dateFinAffectable;
			this.etablissement = dto.etablissement;
			this.nbUsers = dto.nbUsers;
			this.nbChildren = dto.nbChildren;
			this.attribut1 = dto.attribut1;
			this.attribut2 = dto.attribut2;
			this.attribut3 = dto.attribut3;
			this.attribut4 = dto.attribut4;
			this.idLoc = dto.idLoc;
		}
	}

	/**
	 * On fait un getter parce que dans le back ça ne s'appelle pas isActif mais isCache
	 */
	get cache(): boolean {
		return !this.isActif;
	}

	getKey(): number {
		return this.idOrga;
	}
}