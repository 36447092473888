<div *ngIf="orga">
    <page-header
            [title]="(isCreation ? 'admin.entreprise.analytique.organigramme.title' : 'admin.entreprise.analytique.organigramme.titleModification') | translate : { libelle: orga.numService + ' - ' + orga.libelle }"
            [listeItems]="listeTabItems"
            (onSelectedItemChange)="onSelectedItemChange($event)"
            (onGoBack)="onGoBack()">
    </page-header>

    <form #form="ngForm">
        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.entreprise.analytique.organigramme.infos.infosService'">
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset>
                        <div class="row" *ngIf="orga.isActif != null">
                            <custom-input ngDefaultControl
                                          id="libelle"
                                          name="libelle"
                                          [(ngModel)]="orga.libelle"
                                          customType="input"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.libelle"
                                          [required]="true">
                            </custom-input>
                            <custom-input ngDefaultControl
                                          id="isActif"
                                          name="isActif"
                                          [ngModel]="orga.isActif"
                                          [(customModel)]="orga.isActif"
                                          customType="checkbox"
                                          (customModelChange)="actifChange()"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.actif">
                            </custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="numService"
                                          name="numService"
                                          [(ngModel)]="orga.numService"
                                          customType="input"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.numero"
                                          [required]="true">
                            </custom-input>
                            <custom-input ngDefaultControl
                                          id="dateCloture"
                                          name="dateCloture"
                                          [(ngModel)]="orga.dateFin"
                                          [(customModel)]="orga.dateFin"
                                          (customModelChange)="updateActif($event)"
                                          customType="date"
                                          [disabled]="!orga.isActif"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.dateCloture">
                            </custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="code"
                                          name="code"
                                          [(ngModel)]="orga.code"
                                          customType="input"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.code">
                            </custom-input>
                            <label class="col-md-2 offset-md-2">
                                <span translate>admin.entreprise.analytique.organigramme.infos.adresse</span>
                            </label>
                            <div class="col-md-2">
                                <lieu ngDefaultControl
                                      name="adresse"
                                      [(ngModel)]="adresse"
                                      [(lieu)]="adresse"
                                      [idPortee]="TypePortee.ADM"
                                      (lieuChange)="setAdresse($event)"
                                      [onClear]="clearAdresse">
                                </lieu>
                            </div>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="parent"
                                          name="parent"
                                          [(ngModel)]="orga.parent"
                                          customType="autocomplete"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.serviceParent"
                                          optionValue="idOrga"
                                          optionDisplay="nomAffiche"
                                          autocompleteType="service"
                            ></custom-input>
                            <custom-input ngDefaultControl
                                          id="affected"
                                          name="affected"
                                          [(ngModel)]="libelleUtilisateursAffectes"
                                          customType="input"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.utilisateurs"
                                          [readonly]="true"></custom-input>
                            <a [routerLink]="" (click)="openUsers()">
                                <span class="material-icons align-middle py-3">open_in_new</span>
                            </a>
                        </div>
                        <div class="row" *ngIf="orga.imputable != null">
                            <custom-input ngDefaultControl
                                          id="imputable"
                                          name="imputable"
                                          [ngModel]="orga.imputable"
                                          [(customModel)]="orga.imputable"
                                          customType="checkbox"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.imputable"></custom-input>
                            <custom-input ngDefaultControl
                                          id="dateFinImputable"
                                          name="dateFinImputable"
                                          [ngModel]="orga.dateFinImputable"
                                          [(customModel)]="orga.dateFinImputable"
                                          customType="date"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.dateFinImputable"></custom-input>
                            <div class="py-3">
                                <mat-icon class="material-icons mb-2" [matTooltip]="'admin.entreprise.analytique.organigramme.infos.imputableToolTip' | translate"
                                          [matTooltipPosition]="'right'" inline="true">info_outline
                                </mat-icon>
                            </div>
                        </div>
                        <div class="row" *ngIf="orga.affectable != null">
                            <custom-input ngDefaultControl
                                          id="affectable"
                                          name="affectable"
                                          [ngModel]="orga.affectable"
                                          [(customModel)]="orga.affectable"
                                          customType="checkbox"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.affectable"></custom-input>
                            <custom-input ngDefaultControl
                                          id="dateFinAffectable"
                                          name="dateFinAffectable"
                                          [ngModel]="orga.dateFinAffectable"
                                          [(customModel)]="orga.dateFinAffectable"
                                          customType="date"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.dateFinAffectable"></custom-input>
                            <div class="py-3">
                                <mat-icon class="material-icons" [matTooltip]="'admin.entreprise.analytique.organigramme.infos.affectableToolTip' | translate"
                                          [matTooltipPosition]="'right'" inline="true">info_outline
                                </mat-icon>
                            </div>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>
            <document-list *ngIf="!orga.parent" #listeLogos
                           (onDocumentRemoved)="documentRemoved()"
                           [idObjet]="orga.idOrga"
                           [context]="ContextDocument.LOGO_ORGA"
                           [canDeleteDocument]="true"
                           [canAddDocument]="true"
                           [isSimpleDocumentList]="true"
                           [isFrontendList]="true"
                           [frontendList]="listeFichierLogo"
                           [isBasicFilePicker]="true"
                           [settings]="settings"
                           [title]="'admin.entreprise.analytique.organigramme.infos.logo'"
                           [uploadTitle]="'admin.entreprise.analytique.organigramme.infos.uploadLogo'">
            </document-list>
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.entreprise.analytique.organigramme.infos.infosComplementaires'">
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <fieldset *ngIf="orga">
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="attribut1"
                                          name="attribut1"
                                          [(ngModel)]="orga.attribut1"
                                          customType="input"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.attribut1"></custom-input>

                            <custom-input ngDefaultControl
                                          id="attribut2"
                                          name="attribut2"
                                          [(ngModel)]="orga.attribut2"
                                          customType="input"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.attribut2"></custom-input>
                        </div>
                        <div class="row">
                            <custom-input ngDefaultControl
                                          id="attribut3"
                                          name="attribut3"
                                          [(ngModel)]="orga.attribut3"
                                          customType="input"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.attribut3"></custom-input>
                            <custom-input ngDefaultControl
                                          id="attribut4"
                                          name="attribut4"
                                          [(ngModel)]="orga.attribut4"
                                          customType="input"
                                          lCol="4"
                                          rCol="2"
                                          libelle="admin.entreprise.analytique.organigramme.infos.attribut4"></custom-input>
                        </div>
                    </fieldset>
                </mat-card-content>
            </mat-card>
        </div>

        <div [hidden]="selectedItem?.code !== Onglets.SERVICES_LIES">
            <mat-card>
                <mat-card-title>
                    <span [translate]="'admin.entreprise.analytique.organigramme.onglets.servicesLies'">
                    </span>
                </mat-card-title>
                <mat-card-content>
                    <div class="listview" *ngIf="orga.parent">
                        <div class="listview-body">
                            <div class="listview-item active">
                                <div class="list-view-item-hover">
                                    <div class="lvi-content">
                                        <div class="avatar">
                                            <i class="material-icons-outlined">lan</i>
                                        </div>
                                        <div class="lvi-body">
                                            <div class="title">
                                                <a class="label" style="cursor:default">{{ orga.parent.libelle }}</a>
                                            </div>
                                            <ul class="lvi-attrs">
                                                <li *ngIf="orga.parent.code">
                                                    <strong [translate]="'admin.entreprise.analytique.organigramme.code'"></strong>
                                                    <span>{{ orga.parent.code }}</span>
                                                </li>
                                                <li>
                                                    <strong [translate]="'admin.entreprise.analytique.organigramme.affectable'"></strong>
                                                    <span>{{ (orga.parent.affectable ? 'global.oui' : 'global.non') | translate }}</span>
                                                </li>
                                                <li>
                                                    <strong [translate]="'admin.entreprise.analytique.organigramme.imputable'"></strong>
                                                    <span>{{ (orga.parent.imputable ? 'global.oui' : 'global.non') | translate }}</span>
                                                </li>
                                                <li>
                                                    <strong [translate]="'admin.entreprise.analytique.organigramme.actif'"></strong>
                                                    <span>{{ (orga.parent.isActif ? 'global.oui' : 'global.non') | translate }}</span>
                                                </li>
                                                <li>
                                                    <strong [translate]="'admin.entreprise.analytique.organigramme.typeLien'"></strong>
                                                    <span>{{ 'admin.entreprise.analytique.organigramme.parent' | translate }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="listview">
                        <div class="listview-body">
                            <div *ngFor="let service of listeSousServices">
                                <div class="listview-item active">
                                    <div class="list-view-item-hover">
                                        <div class="lvi-content">
                                            <div class="avatar">
                                                <i class="material-icons-outlined">lan</i>
                                            </div>
                                            <div class="lvi-body">
                                                <div class="title">
                                                    <a class="label" style="cursor:default">{{ service.libelle }}</a>
                                                </div>
                                                <ul class="lvi-attrs">
                                                    <li *ngIf="service.code">
                                                        <strong [translate]="'admin.entreprise.analytique.organigramme.code'"></strong>
                                                        <span>{{ service.code }}</span>
                                                    </li>
                                                    <li>
                                                        <strong [translate]="'admin.entreprise.analytique.organigramme.affectable'"></strong>
                                                        <span>{{ (service.affectable ? 'global.oui' : 'global.non') | translate }}</span>
                                                    </li>
                                                    <li>
                                                        <strong [translate]="'admin.entreprise.analytique.organigramme.imputable'"></strong>
                                                        <span>{{ (service.imputable ? 'global.oui' : 'global.non') | translate }}</span>
                                                    </li>
                                                    <li>
                                                        <strong [translate]="'admin.entreprise.analytique.organigramme.actif'"></strong>
                                                        <span>{{ (service.isActif ? 'global.oui' : 'global.non') | translate }}</span>
                                                    </li>
                                                    <li>
                                                        <strong [translate]="'admin.entreprise.analytique.organigramme.typeLien'"></strong>
                                                        <span>{{ 'admin.entreprise.analytique.organigramme.enfant' | translate }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>
<floating-button [listeActions]="listeActions"></floating-button>
