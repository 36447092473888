import {NgModule} from "@angular/core";
import {ShareModule} from "@share/share.module";
import {ComptabiliteLotsComptablesComponent} from "./comptabilite-lots-comptables.component";
import {ComptabiliteModule} from "../../../comptabilite/comptabilite.module";
import {LotComponent} from "../../../comptabilite/lot.component";
import {Routes} from "@angular/router";
import {AdminGuardProvider} from "@core/security/admin-guard.provider";
import {DroitAdmin} from "@core/security/droit-admin";
import {PreviewAlerteModule} from "@share/component/alerte/preview-alerte.module";
import {HistoriqueArchivageComponent} from "../../../comptabilite/archivage/historique-archivage.component";
import {Onglets} from "@components/comptabilite/lot.component";

/**
 * Routes du menu admin Comptabilité / Lots comptables
 */
export const comptabiliteLotsComptablesRoutes: Routes = [
	{
		path: 'Lot/:idLot',
		component: LotComponent,
		canActivate: [AdminGuardProvider],
		data: { sousAdminCredentials: [DroitAdmin.DROIT_LOTS_COMPTABLES,DroitAdmin.DROIT_DEMATERIALISATION] },
		children: [{
			path: 'Archivage',
			component: HistoriqueArchivageComponent,
			data: { sousAdminCredentials: [DroitAdmin.DROIT_DEMATERIALISATION], onglet: Onglets.ARCHIVAGE },
		}]
	}
];


/**
 * Module admin Lots comptables
 *
 * @author Laurent Convert
 * @date 01/08/2024
 */
@NgModule({
	imports: [ShareModule,ComptabiliteModule,PreviewAlerteModule],
	declarations: [ComptabiliteLotsComptablesComponent],
	providers: [],
	exports: []
})
export class ComptabiliteLotsComptablesModule {
}