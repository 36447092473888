import {Component} from "@angular/core";

/**
 * Composant vide
 *
 * @author Laurent Convert
 * @date 23/08/2023
 */
@Component({
	template: ''
})
export class BlankComponent {

}