import {Component} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CompteurDetail} from "@domain/ik/compteur-detail";

/**
 * Item de la liste de détail d'un compteur sur une période
 */
@Component({
	host: {'data-test-id': 'compteur-detail-list-item'},
	templateUrl: './compteur-detail-list-item.component.html'
})
export class CompteurDetailListItemComponent extends ListViewItem<CompteurDetail> {
}
