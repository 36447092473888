import {Component, Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {CalculIkDetail} from "@domain/ik/calcul-ik-detail";
import {DecimalPipe} from "@angular/common";

/**
 * Item de la liste du détail du calcul de l'IK
 */
@Component({
    host: { 'data-test-id': 'detail-calcul-ik-list-item' },
    selector: 'app-detail-calcul-ik-list-item',
    templateUrl: './detail-calcul-ik-list-item.component.html'
})
export class DetailCalculIkListItemComponent extends ListViewItem<CalculIkDetail> {

    /** Objet courant */
    @Input() data: CalculIkDetail;

    /**
     * Constructeur
     *
     * @param decimalPipe le pipe pour l'affichage des nombres décimaux
     */
    constructor(private decimalPipe: DecimalPipe) {
        super();
    }

    /**
     * Fonction qui renvoie le titre d'un item
     */
    getTitle() {
        return this.data.debut + " - " + this.data.fin + " (" + this.decimalPipe.transform(this.data.taux,'1.2-2') + " " + this.data.devisePresta + "/" + this.data.unite + ")";
    }
}
