import {Component,Input} from '@angular/core';

import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {WorkflowService} from "../../../../workflow/workflow.service";
import {OdFrais} from "../../../../../domain/od/frais/od-liste-frais";
import {TypePortee} from "../../../../../domain/workflow/workflow";
import {NatureAvance} from '../../../../../domain/avance/nature-avance';
import {ODService} from "../../../od.service";
import {OdFraisPrevisionnelAddComponent} from "./creation/frais-previsionnel/od-frais-previsionnel-add.component";
import {MatDialog} from "@angular/material/dialog";
import {NiveauAlerte} from "../../../../../domain/common/alerte/alerte";

/**
 * Composant d'affichage d'une ligne de la liste des frais rattachés à un OD
 *
 * @author Laurent Convert
 * @date 08/12/2021
 */
@Component({
    templateUrl: './od-frais-frais-item.component.html'
})
export class OdFraisFraisItemComponent {
    /** Déclaration pour accès direct depuis le template */
    TypePortee = TypePortee;
    NatureAvance = NatureAvance;
    NiveauAlerte = NiveauAlerte;

    /** Elément courant */
    @Input() data: OdFrais;

    /** Options supplémentaires */
    @Input() extraOptions: any;

    /** URL de base **/
    baseUrl: string = environment.baseUrl;

    /**
     * Constructeur
     */
    constructor(private router: Router,
                private workflowService: WorkflowService,
                private odService: ODService,
                private matDialog: MatDialog) {
    }

    /**
     * Affichage de la ndf
     */
    showNDF() {
        this.odService.showNDF(this.data?.ndf);
    }

    /**
     * Affichage de l'avance
     */
    showAvance() {
        this.odService.showAvance(this.data?.avance);
    }

    /**
     * Affichage du frais prévisionnel
     */
    showFraisPrev() {
        this.matDialog.open(OdFraisPrevisionnelAddComponent,{
            data: {
                fraisPrev: this.data?.fraisPrev,
                od: this.extraOptions.od,
                settings: this.extraOptions.settings,
                canModifier: this.extraOptions?.canModifierFraisPrev
            },
            hasBackdrop: true,
            disableClose: true
        }).afterClosed().subscribe((res: {refresh: boolean, message: string}) => {
            if (res?.refresh) {
                this.extraOptions.refreshListe(res?.message);
            }
        });
    }

    /**
     * Affichage des alertes de l'objet lié
     */
    showListeAlertes(portee: TypePortee,idObjet: number) {
        //Affichage de la popup de liste des alertes
        this.workflowService.showListeAlertes(portee,idObjet);
    }

}
