import {ListItem} from "../common/list-view/list-item";

/**
 * Classes des différents objets utilisés et retournés par le back lors d'une simulation d'action WF.<br>
 * Utilisés lors des actions WF pour récupérer les approbateurs et les destinataires des notifications.
 *
 * @author Laurent Convert
 * @date 21/03/2022
 */

/**
 * Représente les approbateurs pour le retour json de la simulation wf.
 */
export class Approbateur implements ListItem {
    /** True s'il s'agit de l'objet principal */
    principal: boolean;

    /** Le type d'objet concerné */
    typeObjet: string;

    /** L'id de l'objet concerné */
    idObjet: string;

    /** Liste des approbateurs possibles */
    users: Array<WFUser>;


    /* Variables définies et utilisées uniquement sur le front */

    /** Liste des approbateurs associés à l'approbateur courant  */
    approbateurs: Array<WFUser>;

    getKey(): string {
        return [this.principal,this.typeObjet,this.idObjet].join(',');
    }
}

/**
 * Représente un destinataire pour le retour json de la simulation wf
 */
export class Destinataire {
    /** True s'il s'agit de l'objet principal */
    principal: boolean;

    /** Le Type d'objet concerné */
    typeObjet: string;

    /** Le libellé de l'action effectuée sur l'objet */
    libelleAction: string;

    /** L'id de l'objet concerné */
    idObjet: string;

    /** True si les notifications sont activées sur l'objet */
    isMail: boolean;

    /** True s'il la sélection manuelle est possible */
    manuel: boolean;

    /** True si la sélection est obligatoire */
    obligatoire: boolean;

    /** Liste des mails/notifications */
    mail: Array<Mail>;
}

/**
 * Représente un mail pour le retour json de la simulation wf
 */
export class Mail {
    /** Id du rôle utilisateur */
    idRole: number;

    /** Libellé du rôle utilisateur */
    libelleRole: string;

    /** Titre de la notification/du mail */
    titre: string;

    /** True si la sélection du destinataire est manuelle */
    manuel: boolean;

    /** Liste des destinataires possibles */
    users: Array<WFUser>;
}

/**
 * Représente un utilisateur pour le retour json de la simulation wf
 */
export class WFUser implements ListItem {
    /** Id du rôle utilisateur */
    idRole: number;

    /** Libellé du rôle utilisateur */
    libelleRole: string;

    /** Identifiant de l'utilisateur */
    idUser: number;

    /** Nom de l'utilisateur */
    nom: string;

    /** Prénom de l'utilisateur */
    prenom: string;

    /** Matricule de l'utilisateur */
    matricule: string;

    /** True si l'utilisateur est proposé grâce à une délégation */
    isDelegue: boolean;

    /** Date de début de la délégation */
    dateDebutDelegation: Date;

    /** Date de fin de la délégation */
    dateFinDelegation: Date;

    /** 0 Titulaire / 1 suppléant */
    priorite: number;

    /** True si l'utilisateur est sélectionnable manuellement */
    isManuel: boolean;

    /** True si l'auto-validation est autorisée */
    isAutoValidationAllowed: boolean;


    /* Variables définies et utilisées uniquement sur le front */

    /** Priorité */
    prioriteKey: Priorite;

    /** Niveau utilisé pour trier en fonction de la priorité */
    prioriteForFilter: number;

    /** Identifiant de la priorité */
    idPriorite: number;

    /** Créateur de l'objet */
    creator: boolean;

    /** Indicateur de sélection de l'élément */
    isSelected: boolean;

    /** Indique si l'élément est filtré (qu'il ne correspond pas à la recherche et ne doit pas être affiché) */
    isFiltre: boolean;

    /** Récupération de l'identifiant de l'objet */
    getKey(): string {
        return [this.idRole,this.idUser].join(';');
    }
}

/**
 * Priorité du destinataire
 */
export enum Priorite {
    createur = 'createur',
    titulaire = 'titulaire',
    suppleant = 'suppleant',
}

/** Création d'un namespace pour ajouter des fonctions supplémentaires sur l'énuméré */
export namespace Priorite {
    /**
     * Retourne toutes les valeurs de l'énuméré
     */
    export function values(): Array<Priorite> {
        return [Priorite.createur,Priorite.titulaire,Priorite.suppleant];
    }

    /**
     * Retourne le niveau de filtre en fonction de la priorité
     *
     * @param priorite La priorité
     */
    export function getLevel(priorite: Priorite): number {
        switch (priorite) {
            case Priorite.createur:
                return 1;
            case Priorite.titulaire:
                return 2;
            case Priorite.suppleant:
                return 3;
            default:
                return 4;
        }
    }
}
