<div class="lvi-content">
    <div class="avatar">
        <span [translate]="'admin.bibliotheque.geographie.adresses.avatar'"></span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a *ngIf="data.rue" class="label" (click)="goToDetailAdresse()">{{ data.rue }}</a>
            <a *ngIf="!data.rue" class="label" (click)="goToDetailAdresse()" [translate]="'admin.bibliotheque.geographie.adresses.noRue'" [translateParams]="{id: data.idAdresse}"></a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.ville">
                <strong [translate]="'admin.bibliotheque.geographie.adresses.ville'"></strong>
                <span>{{ data.ville }}</span>
            </li>
            <li *ngIf="data.pays">
                <strong [translate]="'admin.bibliotheque.geographie.adresses.pays'"></strong>
                <span *ngIf="data.codePays" class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.codePays.toLowerCase()"></span>
                <span *ngIf="data.adressePays?.code2" class="flag-icon mr-1" [ngClass]="'flag-icon-'+ data.adressePays.code2.toLowerCase()"></span>
                <span>{{ data.pays }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.adresses.localisee'"></strong>
                <span *ngIf="data.latitude !== 0 && data.longitude !== 0" [translate]="'global.oui'"></span>
                <span *ngIf="data.latitude === 0 || data.longitude === 0" [translate]="'global.non'"></span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.geographie.adresses.origine'"></strong>
                <ng-container [ngSwitch]="data.origine">
                    <span *ngSwitchCase=Origine.ORIGINE_ADMIN [translate]="'admin.bibliotheque.geographie.adresses.admin'"></span>
                    <span *ngSwitchCase=Origine.ORIGINE_USER [translate]="'admin.bibliotheque.geographie.adresses.user'"></span>
                    <span *ngSwitchCase=Origine.ORIGINE_IMPORT [translate]="'admin.bibliotheque.geographie.adresses.import'"></span>
                    <span *ngSwitchCase=Origine.ORIGINE_DISTANCE [translate]="'admin.bibliotheque.geographie.adresses.distance'"></span>
                    <span *ngSwitchDefault [translate]="'admin.bibliotheque.geographie.adresses.admin'"></span>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="deleteAdresse()" [matTooltip]="'global.actions.supprimer' | translate">
            <i class="nio icon-suppression"></i>
        </button>
    </div>
</div>
