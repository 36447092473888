import {Component} from '@angular/core';

import {AutocompleteSearchItemComponent} from '../autocomplete-search-item.component';

/**
 * Composant d'affichage d'une ligne dans la popup de recherche avancée 'Devise'
 */
@Component({
    host: {'data-test-id': 'devise-search-item'},
    templateUrl: './devise.search-item.html',
})
export class DeviseSearchItemComponent extends AutocompleteSearchItemComponent<any> {
}