import {ListItem} from "@domain/common/list-view/list-item";
import {TranslateService} from "@ngx-translate/core";

/**
 * Item de liste d'un filtre pour l'onglet "Filtres" de l'Admin utilisateur
 */
export class Filtre implements ListItem {
	idUser: number;
	idFiltre: number;
	typeFiltre: TypeFiltre;
	libelle: string;
	actif: boolean;

	getKey(): string {
		return this.idUser + this.idFiltre + this.typeFiltre;
	}
}

/**
 * Énumération des types de filtres possibles
 */
export enum TypeFiltre {
	D = "D",
	M = "M",
	O = "O",
	T = "T",
	W = "W"
}

/**
 * Création d'un namespace pour ajouter des fonctions supplémentaires à l'énum
 */
export namespace TypeFiltre {
	/**
	 * Récupération du libellé
	 *
	 * @param typeFiltre le type de filtre
	 * @param translateService le moteur de traduction
	 */
	export function getLibelle(typeFiltre: TypeFiltre,translateService: TranslateService) {
		return translateService.instant(`admin.entreprise.utilisateurs.detail.filtres.typeFiltre.${typeFiltre}`)
	}
}