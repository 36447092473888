import {Component,Inject,OnInit,ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialog,MatDialogRef} from "@angular/material/dialog";
import {ParamConnexionUsed} from "@domain/travel/param-connexion-used";
import {AdminTravelhubService} from "@components/admin/voyages/travelhub/admin-travelhub.service";
import {NotionMetier,ParamConnexion,TransmisPour,TypeCommunaute,TypeControle,TypeParamConnexion,TypeProfilFullfilment,TypeReasonCode,TypeRLS,TypeRLV} from "@domain/travel/param-connexion";
import {Champ,FiltreChamp} from "@domain/travel/champ";
import {filter,first} from "rxjs/operators";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {TranslateService} from "@ngx-translate/core";
import {ParamConnexionComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion.component";
import {ParamConnexionValeur} from "@domain/travel/param-connexion-valeur";
import {
	ParamConnexionSelectionComponent
} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/param-connexion/param-connexion-add/selection/param-connexion-selection.component";
import {ParamConnexionChamp} from "@domain/travel/param-connexion-champ";
import {CodemirrorComponent} from "@ctrl/ngx-codemirror";
import {PostTooltipNio} from "@share/component/custom-input/custom-input.component";

/**
 * Détail d'une ligne du cadre "Paramètres de connexion"
 *
 * @author Laurent Convert
 * @date 05/12/2023
 */
@Component({
	host: {'data-test-id': 'param-connexion-add'},
	templateUrl: './param-connexion-add.component.html'
})
export class ParamConnexionAddComponent implements OnInit {
	/* Déclaration pour accès dans le template */
	public readonly TransmisPour = TransmisPour;
	public readonly TypeParamConnexion = TypeParamConnexion;
	public readonly TypeControle = TypeControle;
	public readonly FiltreChamp = FiltreChamp;
	public readonly AdminTravelhubService = AdminTravelhubService;

	/** Indicateur de suppression en cours */
	isDeleting: boolean = false;

	/** Indicateur d'enregistrement en cours */
	isSaving: boolean = false;

	/** Indicateur de traitement en cours (enregistrement ou suppression) */
	get isProcessing(): boolean {
		return this.isSaving || this.isDeleting;
	}

	/** Paramètre de connexion */
	selectedParamConnexion: ParamConnexionUsed;

	/** Valeur du paramètre */
	champ: Champ;

	/** Liste des valeurs sélectionnables dans le cas d'un paramètre de type LIST_VALEUR */
	listeValeurParamConnexionFiltered: Array<ParamConnexionValeur>;

	/** Choix d'une valeur booléenne */
	readonly listeOuiNon: Array<NotionMetierValueSelection> = [
		{value: '1', display: this.translateService.instant('global.oui')},
		{value: '0', display: this.translateService.instant('global.non')}
	]

	/** Liste des ReasonCode */
	readonly listeTypeReasonCode: Array<NotionMetierValueSelection> = TypeReasonCode.values()
		.map(e => { return {value: e,display: this.translateService.instant(TypeReasonCode.traduction(e))}});

	/** Liste des ProfilFullfilment */
	readonly listeTypeProfilFullfilment: Array<NotionMetierValueSelection> = TypeProfilFullfilment.values()
		.map(e => { return {value: e,display: this.translateService.instant(TypeProfilFullfilment.traduction(e))}});

	/** Liste des types de saisie du RLV */
	readonly listeTypeRLV: Array<NotionMetierValueSelection> = TypeRLV.values()
		.map(e => { return {value: e,display: this.translateService.instant(TypeRLV.traduction(e))}});

	/** Liste des communautés */
	readonly listeTypeCommunaute: Array<NotionMetierValueSelection> = TypeCommunaute.values()
		.map(e => { return {value: e,display: this.translateService.instant(TypeCommunaute.traduction(e))}});

	/** Liste des types de saisie du RLS */
	readonly listeTypeRLS: Array<NotionMetierValueSelection> = TypeRLS.values()
		.map(e => { return {value: e,display: this.translateService.instant(TypeRLS.traduction(e))}});

	/** Liste des valeurs disponibles suivant la notion métier du paramètre */
	listeValeurNotionMetier: Array<NotionMetierValueSelection> = null;

	/** RegExp pour la validation de la valeur suivant le nombre de caractères attendus */
	regExpValidatorValeur : RegExp;

	/** Tooltip à afficher en fonction de la règle de validation pour la valeur fixe */
	tooltipValeurFixe: PostTooltipNio = null;

	/** Options pour l'affichage de la requête SQL */
	codeMirrorOptions: any = {
		theme: 'material',
		mode: 'text/x-mssql',
		lineNumbers: true,
		lineWrapping: true,
	};

	@ViewChild("codeMirror")
	codeMirror: CodemirrorComponent;

	/**
	 * Constructeur
	 */
	constructor(@Inject(MAT_DIALOG_DATA) public data: {
			paramConnexion: ParamConnexionUsed,
			listeParamConnexion: Array<ParamConnexion>,
			listeValeurParamConnexion: Array<ParamConnexionValeur>,
			parent: ParamConnexionComponent
		},
		private adminTravelhubService: AdminTravelhubService,
		private confirmService: ConfirmService,
		private translateService: TranslateService,
		private matDialogRef: MatDialogRef<ParamConnexionAddComponent>,
		private matDialog: MatDialog,
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		this.selectedParamConnexion = this.data.paramConnexion;

		//Initialisation du formulaire en fonction du code du paramètre
		this.updateForm();
	}

	/**
	 * Handler du changement de code du paramètre
	 */
	onChangeCode(): void {
		//Reset des différents attributs associés à la valeur
		this.resetValeur();

		//Reset des listes définies le cas échéant suivant le paramètre précédent
		this.listeValeurNotionMetier = null;
		this.listeValeurParamConnexionFiltered = null;

		//Construction de la regexp de validation le cas échéant
		this.buildValidator();

		//Mise à jour du formulaire en fonction du paramètre
		this.updateForm();
	}

	/**
	 * Reset des différents attributs associés à la valeur
	 */
	resetValeur(): void {
		this.selectedParamConnexion.paramConnexionValeur = null;
		this.selectedParamConnexion.valeur = null;
		this.selectedParamConnexion.requete = null;
		this.selectedParamConnexion.codeMapping = null;
		this.selectedParamConnexion.typeField = null;
	}

	/**
	 * Construction de la regexp de validation
	 */
	buildValidator(): void {
		if (this.selectedParamConnexion.paramConnexion.min > 0 || this.selectedParamConnexion.paramConnexion.max > 0) {
			//Contrainte sur le nombre de caractères
			this.regExpValidatorValeur = new RegExp(`^.{${this.selectedParamConnexion.paramConnexion.min},${this.selectedParamConnexion.paramConnexion.max > 0 ? this.selectedParamConnexion.paramConnexion.max : ''}}$`,'g');
		} else if (this.selectedParamConnexion.paramConnexion.regex != null) {
			//Contrainte à partir d'une regexp existante
			this.regExpValidatorValeur = new RegExp(this.selectedParamConnexion.paramConnexion.regex);
		} else {
			//Pas de contrainte
			this.regExpValidatorValeur = null;
		}

		//S'il y a une règle de validation
		if (this.regExpValidatorValeur != null) {
			//Définition du tooltip
			this.tooltipValeurFixe = {
				//Valeur du tooltip à afficher
				content: this.translateService.instant(
					this.selectedParamConnexion?.paramConnexion?.min == this.selectedParamConnexion?.paramConnexion?.max ? 'admin.voyages.travelhub.sbtConfig.referentiel.tooltip.controleString.strict'
					: this.selectedParamConnexion?.paramConnexion?.min > 0 && this.selectedParamConnexion?.paramConnexion?.max > 0 ? 'admin.voyages.travelhub.sbtConfig.referentiel.tooltip.controleString.plage'
					: this.selectedParamConnexion?.paramConnexion?.min > 0 ? 'admin.voyages.travelhub.sbtConfig.referentiel.tooltip.controleString.min'
					: this.selectedParamConnexion?.paramConnexion?.max > 0 ? 'admin.voyages.travelhub.sbtConfig.referentiel.tooltip.controleString.max'
					: '',
					{
						min: this.selectedParamConnexion?.paramConnexion?.min,
						max: this.selectedParamConnexion?.paramConnexion?.max
					}
				)
			};
		} else {
			//Pas de règle de validation, pas de tooltip
			this.tooltipValeurFixe = null;
		}
	}

	/**
	 * Mise à jour du formulaire en fonction du paramètre
	 */
	updateForm(): void {
		if (this.selectedParamConnexion.paramConnexion != null) {
			//Si le paramètre est associé à un champ...
			if (!!this.selectedParamConnexion.typeField) {
				//...on le récupère
				this.champ = this.adminTravelhubService.findChamp(this.selectedParamConnexion.typeField);
			}

			//Définition des listes de valeurs suivant le cas
			if (this.selectedParamConnexion.paramConnexion.typeParamConnexion == TypeParamConnexion.LISTE_VALEURS) {
				//Liste des champs en fonction du code sélectionné
				this.listeValeurParamConnexionFiltered = this.data.listeValeurParamConnexion.filter(p => p.paramConnexion.idParamConnexion === this.selectedParamConnexion.paramConnexion.idParamConnexion);
			} else if (this.selectedParamConnexion.paramConnexion.notionMetier != null || this.selectedParamConnexion.paramConnexion.code == AdminTravelhubService.PARAM_CODE_RLV) {
				//Liste de valeurs déterminées par la notion métier découlant du code sélectionné
				this.listeValeurNotionMetier = this.selectedParamConnexion.paramConnexion.notionMetier == NotionMetier.ReasonCode ? this.listeTypeReasonCode
					: this.selectedParamConnexion.paramConnexion.notionMetier == NotionMetier.ProfilFullfilment ? this.listeTypeProfilFullfilment
					: this.selectedParamConnexion.paramConnexion.notionMetier == NotionMetier.Communaute ? this.listeTypeCommunaute
					: this.selectedParamConnexion.paramConnexion.notionMetier == NotionMetier.Rls ? this.listeTypeRLS
					: this.selectedParamConnexion.paramConnexion.code == AdminTravelhubService.PARAM_CODE_RLV ? this.listeTypeRLV
					: null;
			}
		}
	}

	/**
	 * Ouvre la popup permettant de choisir une valeur
	 */
	openValeur(): void {
		this.matDialog.open<ParamConnexionSelectionComponent,any,ParamConnexionChamp>(ParamConnexionSelectionComponent,{
			data: {
				champ: this.champ
			}
		}).afterClosed().subscribe(paramConnexionChamp => {
			if (paramConnexionChamp) {
				//Reset des différents attributs associés à la valeur
				this.resetValeur();

				//Mémorisation du champ sélectionné
				this.champ = paramConnexionChamp.champ;

				//Mise à jour du paramètre avec le champ sélectionné
				this.selectedParamConnexion.typeField = paramConnexionChamp.champ.id;

				//Mise à jour du formulaire en fonction du paramètre
				this.updateForm();

				//Rafraichissement de codemirror (permet de forcer le calcul des gouttières et autres éléments de l'éditeur)
				if (this.selectedParamConnexion.typeField === AdminTravelhubService.TYPE_FIELD_SQL) {
					this.codeMirror.codeMirror.refresh();
				}
			}
		});
	}

	/**
	 * Suppression du paramètre en cours d'affichage
	 */
	deleteParamConnexion(): void {
		//Affichage de la confirmation de suppression
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation'))
			.pipe(filter(isConfirmed => isConfirmed))
			.subscribe({
				next: () => {
					//Suppression en cours
					this.isDeleting = true;

					//Suppression
					this.data.parent.deleteParamConnexion(this.selectedParamConnexion).pipe(first()).subscribe(isSuccess => {
						//Vérification du succès de la suppression
						if (isSuccess) {
							//Fermeture de la popup
							this.matDialogRef.close(true);
						} else {
							//Erreur lors de suppression, fin du traitement
							this.isDeleting = false;
						}
					});
				}
			});
	}

	/**
	 * Suppression du paramètre en cours d'affichage
	 */
	saveParamConnexion(): void {
		//Enregistrement en cours
		this.isSaving = true;

		//Enregistrement
		this.data.parent.saveParamConnexion(this.selectedParamConnexion).pipe(first()).subscribe(isSuccess => {
			//Vérification du succès de l'enregistrement
			if (isSuccess) {
				//Fermeture de la popup
				this.matDialogRef.close(true);
			} else {
				//Erreur lors d'enregistrement, fin du traitement
				this.isSaving = false;
			}
		});
	}
}

export type NotionMetierValueSelection = {
	value: boolean | string,
	display: string
}
