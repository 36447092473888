import {Component, Input} from '@angular/core';
import {SettingsAVState} from "../../../domain/settings/settings";

@Component({
    selector: 'avance-historique-list',
    templateUrl: './avance-historique-list.component.html'
})
export class AvanceHistoriqueListComponent {
    /** Avance */
    @Input() avance: any;

    /** Paramétrage de l'avance */
    @Input() settings: SettingsAVState;

}
