export class InfoVoyageur {
    nom: string;
    nomUsage: string;
    idVilleNaissance: number;
    villeNaissance: any;
    idPaysNationalite: number;
    paysNationalite: any;
    dateNaissance: Date;
    newVille: boolean = false;
    codePostal: string;
    ville: string;
    idPaysNaissance: number;
    paysNaissance: any;
}
