import {ListeAlertes} from "../common/alerte/listeAlertes";

/**
 * Classe représentant les alertes pour les véhicules
 */
export class AlertesVehicule {
    /** Pas d'assurance valide */
    isMissingAssurance: boolean;

    /** Pas de carte grise valide */
    isMissingCarteGrise: boolean;

    /** Pas d'autorisation valide */
    isMissingAutorisation: boolean;

    /** Assurance arrivant à échéance bientôt */
    isSoonToExpireAssurance: boolean;

    /** Autorisation arrivant à échéance bientôt */
    isSoonToExpireAutorisation: boolean;

    /** Véhicule rejeté par l'approbateur */
    isRejet: boolean;

    /** Fonction de construction de la liste des alertes du véhicule à partir de l'objet récapitulatif */
    toListeAlertes: () => ListeAlertes;

    /** Retourne True si au moins un document est bientôt périmé, False sinon */
    isWarning(): boolean {
        return !this.isDanger() && (this.isSoonToExpireAssurance || this.isSoonToExpireAutorisation);
    }

    /** Retourne True si le véhicule est rejeté ou qu'un document manque, False sinon */
    isDanger(): boolean {
        return this.isRejet || this.isMissingAssurance || this.isMissingCarteGrise || this.isMissingAutorisation;
    }
}