<mat-card>
    <mat-card-title>
        <span translate>admin.voyages.travelhub.profilConnexion.title</span>
    </mat-card-title>
    <mat-card-content>
        <form #form="ngForm" autocomplete="off">
            <fieldset>
                <div class="row">
                    <!-- Libellé -->
                    <custom-input
                            ngDefaultControl id="libelle" name="libelle"
                            customType="input" libelle="admin.voyages.travelhub.profilConnexion.libelle"
                            maxlength="2000"
                            [(ngModel)]="profilConnexion.libelle"
                            [required]="true">
                    </custom-input>

                    <!-- Actif -->
                    <custom-input
                            ngDefaultControl id="actif" name="actif"
                            customType="checkbox" libelle="admin.voyages.travelhub.profilConnexion.actif"
                            postLibelle="admin.voyages.travelhub.profilConnexion.actifDesc"
                            [disabled]="profilConnexion.nbUser > 0 || profilConnexion.defaut"
                            [ngModel]="profilConnexion.actif"
                            [(customModel)]="profilConnexion.actif">
                    </custom-input>
                </div>
                <div class="row">
                    <!-- Code -->
                    <custom-input
                            ngDefaultControl id="code" name="code"
                            customType="input" libelle="admin.voyages.travelhub.profilConnexion.code"
                            maxlength="2000"
                            [(ngModel)]="profilConnexion.code"
                            [required]="true">
                    </custom-input>

                    <!-- Par défaut -->
                    <custom-input
                            ngDefaultControl id="defaut" name="defaut"
                            customType="checkbox" libelle="admin.voyages.travelhub.profilConnexion.defaut"
                            postLibelle="admin.voyages.travelhub.profilConnexion.defautDesc"
                            [postTooltip]="'admin.voyages.travelhub.profilConnexion.tooltipProfilParDefaut' | translate"
                            (onChange)="profilConnexion.actif = true"
                            [ngModel]="profilConnexion.defaut"
                            [(customModel)]="profilConnexion.defaut">
                    </custom-input>
                </div>

                <ng-container *ngIf="!isCreation">
                    <div class="row">
                        <!-- Synchronisation -->
                        <custom-input
                                ngDefaultControl id="synchro" name="synchro"
                                customType="checkbox" libelle="admin.voyages.travelhub.profilConnexion.synchro"
                                postLibelle="admin.voyages.travelhub.profilConnexion.synchroDesc"
                                [readonly]="true"
                                [ngModel]="true"
                                [customModel]="true">
                        </custom-input>

                        <!-- Offline -->
                        <custom-input
                                ngDefaultControl id="offline" name="offline"
                                customType="checkbox" libelle="admin.voyages.travelhub.profilConnexion.offline"
                                postLibelle="admin.voyages.travelhub.profilConnexion.offlineDesc"
                                [postTooltip]="'admin.voyages.travelhub.profilConnexion.tooltipForcerAiguillageOffline' | translate"
                                [disabled]="!profilConnexion.offline || profilConnexion.choixOnOff"
                                [ngModel]="profilConnexion.forceOffline"
                                [(customModel)]="profilConnexion.forceOffline">
                        </custom-input>
                    </div>
                    <div class="row">
                        <!-- Configuration online -->
                        <custom-input
                                ngDefaultControl id="configOnline" name="configOnline"
                                customType="objectselect" libelle="admin.voyages.travelhub.profilConnexion.configOnline"
                                [(ngModel)]="profilConnexion.travelHubSBTConfigUsed"
                                [selectOptions]="listeOnlineSBTConfig"
                                [postTooltip]="'admin.voyages.travelhub.profilConnexion.tooltipConfigOnline' | translate"
                                optionValue="idSBTConfigUsed"
                                optionDisplay="libelle"
                                isSelectAvecVide="true">
                        </custom-input>

                        <!-- Canal de réservation -->
                        <custom-input
                                ngDefaultControl id="choixOnOff" name="choixOnOff"
                                customType="checkbox" libelle="admin.voyages.travelhub.profilConnexion.canalReservation"
                                postLibelle="admin.voyages.travelhub.profilConnexion.canalReservationDesc"
                                [disabled]="profilConnexion.forceOffline || !profilConnexion.offline || !profilConnexion.online"
                                [ngModel]="profilConnexion.choixOnOff"
                                [(customModel)]="profilConnexion.choixOnOff">
                        </custom-input>
                    </div>
                </ng-container>
            </fieldset>
        </form>
    </mat-card-content>
</mat-card>