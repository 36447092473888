import {CarteTravel} from "./carteTravel";
import {ListItem} from "../common/list-view/list-item";

/**
 * Carte de voyage
 */
export class CarteVoyage implements ListItem {
    isOpened: boolean;
    actif?: boolean;
    carteReferentiel?: CarteTravel;
    dateValiditeDebut?: Date;
    dateValiditeFin?: Date;
    id?: number;
    idCarte?: number;
    idUser?: number;
    isActif?: boolean;
    nbPJ?: number;
    numero?: string;
    synchroTH?: boolean;
    titulaire?: string;
    toDelete?: boolean;

    getKey(): number {
        return this.idCarte;
    }

    constructor() {
        this.idCarte = 0;
        this.actif = true;
        this.carteReferentiel = new CarteTravel();
    }
}

/**
 * Enumération du type de carte voyage
 */
export enum TypeCarte {
    FIDELITE = "0",
    ABONNEMENT = "1"
}
