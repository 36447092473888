import {Component,Inject,OnInit} from "@angular/core";
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {MigrationsService} from "@services/admin/maintenance/migrations.service";
import {Migration, MigrationDetail} from "@domain/admin/maintenance/migration";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {TranslateService} from "@ngx-translate/core";
import {ListView} from "@domain/common/list-view";
import {TaskItemComponent} from "@components/admin/maintenance/migrations/maintenance/task-item.component";
import {MaintenanceTask} from "@domain/admin/maintenance/maintenance-task";
import {MigrationDetailItemComponent} from "@components/admin/maintenance/migrations/historique/migration-detail-item.component";
import {MatTabChangeEvent} from "@angular/material/tabs";

/**
 * Enum pour les noms des différents onglets de la popup
 */
export enum Onglets {
    PRE_MIGRATION = "PreMigration",
    MIGRATION = "Migration",
    POST_MIGRATION = "PostMigration"
}

/**
 * Composant d'affichage des logs d'une migration
 */
@Component({
    host: {'data-test-id': 'migration-popup'},
    templateUrl: './migration-popup.component.html',
    styleUrls: ['migration-popup.component.scss']
})
export class MigrationPopupComponent implements OnInit {
    /** Enum des onglets */
    protected readonly Onglets = Onglets;

    /** Migration */
    migration: Migration;

    /** Liste des différents onglets disponibles dans le menu migrations */
    listeTabItems: Array<PageHeaderItem> = [
        {
            code: Onglets.PRE_MIGRATION,
            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.historique.preMigration')
        }, {
            code: Onglets.MIGRATION,
            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.historique.migration')
        }, {
            code: Onglets.POST_MIGRATION,
            libelle: this.translateService.instant('admin.maintenance.migrations.onglets.historique.postMigration')
        }
    ];

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Liste des pré-migrations */
    listePreMigration: ListView<MaintenanceTask, TaskItemComponent>;

    /** Liste des mises à jour BDD */
    listeBddUpgrade: ListView<MigrationDetail, MigrationDetailItemComponent>;

    /** Liste des post-migrations */
    listePostMigration: ListView<MaintenanceTask, TaskItemComponent>;

    /**
     * Constructeur
     */
    constructor(
        protected matDialogRef: MatDialogRef<MigrationPopupComponent>,
        protected translateService: TranslateService,
        private migrationsService: MigrationsService,
        @Inject(MAT_DIALOG_DATA) public data: { migration: Migration }
    ) {}

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        //Récupération des détails de la migration
        this.migrationsService.getMigrationDetails(this.data?.migration?.idUpgrade).subscribe(migration => {
            //Stockage de la migration
            this.migration = migration;

            //Filtrage des onglets inutiles
            this.listeTabItems = this.listeTabItems.filter(tab => {
                if (!migration?.preMigration && tab.code == Onglets.PRE_MIGRATION) return false;
                if (!migration?.bddUpgrade && tab.code == Onglets.MIGRATION) return false;
                if (!migration?.postMigration && tab.code == Onglets.POST_MIGRATION) return false;
                return true;
            });

            //Onglet par défaut
            this.selectedItem = this.listeTabItems[0];

            //S'il y a des pré-migrations
            if (migration.preMigration) {
                //Création de la liste
                this.listePreMigration = new ListView<MaintenanceTask, TaskItemComponent>({
                    component: TaskItemComponent,
                    isFrontendList: true,
                    isFilter: false,
                    extraOptions: {
                        isHistorique: true,
                        isNestedPopup: true
                    }
                });

                //On insère les données dans la liste
                this.listePreMigration.data = {
                    listeResultats: this.migration.listePreTask,
                    nbObjetsParPage: 1000,
                    nbObjetsDansPage: 0,
                    nbObjetsTotal: 0,
                    nbPagesTotal: 0,
                    numPage: 0,
                };
            }

            //S'il y a des MAJ BDD
            if (migration.bddUpgrade) {
                //Création de la liste
                this.listeBddUpgrade = new ListView<MigrationDetail, MigrationDetailItemComponent>({
                    component: MigrationDetailItemComponent,
                    isFrontendList: true,
                    isFilter: false
                });

                //On insère les données dans la liste
                this.listeBddUpgrade.data = {
                    listeResultats: this.migration.listeBddUpgradeDetail,
                    nbObjetsParPage: 1000,
                    nbObjetsDansPage: 0,
                    nbObjetsTotal: 0,
                    nbPagesTotal: 0,
                    numPage: 0,
                };
            }

            //S'il y a des post-migrations
            if (migration.postMigration) {
                //Création de la liste
                this.listePostMigration = new ListView<MaintenanceTask, TaskItemComponent>({
                    component: TaskItemComponent,
                    isFrontendList: true,
                    isFilter: false,
                    extraOptions: {
                        isHistorique: true,
                        isNestedPopup: true
                    }
                });

                //On insère les données dans la liste
                this.listePostMigration.data = {
                    listeResultats: this.migration.listePostTask,
                    nbObjetsParPage: 1000,
                    nbObjetsDansPage: 0,
                    nbObjetsTotal: 0,
                    nbPagesTotal: 0,
                    numPage: 0,
                };
            }
        });
    }

    /**
     * Changement d'onglet
     *
     * @param selectedItem Onglet sélectionné
     */
    onSelectedTabChange(selectedItem: MatTabChangeEvent) {
        //Mise à jour de l'onglet sélectionné
        this.selectedItem = this.listeTabItems[selectedItem.index];
    }
}
