import { AutocompleteOptions } from "../autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { Filter,TypeComparaison,TypeFilter } from "@domain/common/list-view";
import { SearchType } from "@domain/common/list-view/sorting";
import { Od } from "@domain/od/od";
import { TypePortee } from "@domain/workflow/workflow";
import { OdLineItemComponent } from "@share/component/autocomplete/options/od-line-item.component";
import { EntityAttribute } from "@share/component/autocomplete/options/entity-attribute";
import * as moment from "moment";
import { UserPipeTransform } from "@share/pipe/user.pipe";
import { OdPipeTransform } from "@share/pipe/od.pipe";

/**
 * Options de l'autocomplete de recherche d'un OD pour l'utilisateur courant
 */
export default class OdOptions implements AutocompleteOptions {
    /** Mode de recherche */
    searchType = SearchType.CONTAINS;
    
    /**
     * Affichage d'un élément
     */
    displayItem(item: Od,translateService?: TranslateService): string {
        return OdPipeTransform(item,translateService);
    }
    
    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() { return OdLineItemComponent; }

    /** Ordre de tri des résultats */
    getOrderBy() {
        return "-idOd";
    }

    /**
     * Filtres disponibles
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        //Retour de la liste des filtres
        return [{
                //Ajout du filtre sur le numéro
                clef: 'idOd',
                title: translateService.instant('od.liste.filtres.id'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            },{
                //Ajout du filtre sur l'objet
                clef: 'objet',
                title: translateService.instant('od.liste.filtres.objet'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            },{
                //Ajout du filtre sur la date de départ
                clef: 'depart_le',
                title: translateService.instant('od.liste.filtres.depart_le'),
                type: TypeFilter[TypeFilter.DATE]
            }, {
                //Ajout du filtre sur la date de retour
                clef: 'retour_le',
                title: translateService.instant('od.liste.filtres.retour_le'),
                type: TypeFilter[TypeFilter.DATE]
            },{
                //Ajout du filtre sur le matricule
                clef: 'user.matricule,user.nom,user.prenom',
                title: translateService.instant('od.liste.collaborateur'),
                isDefault: true,
                typeComparaison: TypeComparaison[TypeComparaison.LIKE]
            }];
    }
    
    /**
     * Liste les propriétés de l'objet pour lesquelles il faut afficher un badge 'clé:valeur'
     */
    listDisplayedAttributes(item: Od): Array<EntityAttribute> {
        const listeAttributs: Array<EntityAttribute> = [];
    
        listeAttributs.push({
                //Ajout du collaborateur
                clef: 'od.liste.collaborateur',
                valeur: UserPipeTransform(item.user)
            },{
                //Ajout du type entité
                clef: 'global.liste.filtres.typeEntite',
                valeur: item.typeEntite?.libelle
            });
        
        //Ajout de la date/heure de départ et de retour si présents
        if (!!item.depart_le || !!item.retour_le) {
            listeAttributs.push({
                    clef: 'od.liste.filtres.depart_le',
                    valeur: moment(item.depart_le).format("DD/MM/YYYY")
                },{
                    clef: 'od.liste.filtres.retour_le',
                    valeur: moment(item.retour_le).format("DD/MM/YYYY")
                });
        }
        
        return listeAttributs;
    }

    /**
     * Récupération de l'URL
     */
    url(filter: any): string {
        if (filter?.portee == TypePortee.FC) {
            return `/controller/Facture/filtreMissionsForCreation`;
        }
    }

    /**
     * Icône à afficher dans l'avatar
     * @param item Objet de la ligne à afficher
     */
    getIcon(item: any): string {
        return '<i class="nio align-middle icon-ordre_de_mission"></i>';
    }

}