import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss']
})
export class SplashComponent {
    /**
     * Constructeur
     */
    constructor(public router: Router) {
        
    }
}