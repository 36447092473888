import { Component, Input, OnInit } from '@angular/core';
import { RowDataInfos } from "./row-data";

/**
 * Composant d'affichage d'une row bootstrap.<br>
 * <br>
 * Soit en SIMPLE colonne (col-md-2/col-md-10) soit en DOUBLE colonne (col-md-2/col-md-4/col-md-2/col-md-4)<br>
 * Par défaut l'affichage se fait en SIMPLE<br>
 * Pour l'affichage SIMPLE, ce sont les données de dataGauche qui sont utilisées.
 * <br>
 * En affichage DOUBLE, si un des deux paramètres (dataGauche/dataDroite) n'a pas de valeur, col-md-6 vide le remplace<br>
 * En affichage DOUBLE, si aucun des paramètres n'a de valeur, la ligne ne s'affichera pas<br>
 * <br>
 * En affichage SIMPLE, si dataGauche n'a pas de valeur, la ligne ne s'affichera pas
 *
 * @param dataGauche Données de la colonne de gauche ("traduction" et "valeur")
 * @param dataDroite Données de la colonne de droite ("traduction" et "valeur")
 * @param isAffichageDouble Indique s'il s'agit d'un affichage SIMPLE (false) ou DOUBLE (true) (SIMPLE(false) par défaut)
 */
@Component({
    selector: 'row-data',
    templateUrl: './row-data.component.html'
})
export class RowDataComponent implements OnInit {
    /**
     * Données pour la colonne de gauche
     * @param traduction code de la traduction à afficher (ex: global.actions.continuer)
     * @param valeur valeur à afficher
     */
    @Input() dataGauche: RowDataInfos;

    /**
     * Données pour la colonne de droite
     * @param traduction code de la traduction à afficher (ex: global.actions.continuer)
     * @param valeur valeur à afficher
     */
    @Input() dataDroite: RowDataInfos;

    /**
     * Type d'affichage. SIMPLE pour des colonnes bootstrap 2/10, DOUBLE pour des colonnes 2/4/2/4
     * @type {false}
     */
    @Input() isAffichageDouble: boolean;

    ngOnInit() {
        //Si on a des données pour 2 colonnes
        if (this.dataGauche && this.dataDroite) {
            //On force l'affichage en double
            this.isAffichageDouble = true;
        } else if (this.isAffichageDouble == null) {//Si le type d'affichage n'est pas spécifié

            //Si on a que les données de la colonne de droite
            if (!this.dataGauche && this.dataDroite) {
                //On devine un affichage double avec du vide sur la colonne de gauche
                this.isAffichageDouble = true;
            } else if (this.dataGauche && !this.dataDroite) {//Si on a que les données de la colonne de gauche
                //On force l'affichage simple
                this.isAffichageDouble = false;
            }
        }
    }
}
