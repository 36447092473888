import {Injectable} from '@angular/core';
import {HttpClient,HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Exercice} from "@domain/admin/comptabilite/exercice";
import {Result} from "@domain/common/http/result";
import {first,map} from "rxjs/operators";
import {environment} from '@environments/environment';
import {Periode} from '@domain/admin/comptabilite/periode';
import {Page} from "@domain/common/http/list-result";
import {SearchSpec} from "@domain/common/list-view/searchSpec";
import * as moment from "moment";

/**
 * Service de gestion des exercices et des périodes
 *
 * @author Alexis BOUVARD
 * @date 19/04/2023
 */
@Injectable({
	providedIn: 'root'
})
export class ComptabiliteExercicesPeriodesService {
	/**
	 * Constructeur
	 *
	 * @param http le client HTTP
	 */
	constructor(
		private http: HttpClient
	) {
	}

	/**
	 * Chargement d'un exercice
	 *
	 * @param idExercice l'ID de l'exercice
	 */
	loadExercice(idExercice: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Exercice/consultExercice?idExercice=${idExercice}`,null);
	}

	/**
	 * Sauvegarde d'un exercice
	 *
	 * @param exercice l'exercice à sauvegarder
	 */
	saveExercice(exercice: Exercice): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Exercice/saveExercice`,exercice);
	}

	/**
	 * Suppression d'un exercice
	 *
	 * @param idExercice l'ID de l'exercice
	 */
	deleteExercice(idExercice: number): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Exercice/deleteExercice?idExercice=${idExercice}`,null);
	}

	/**
	 * Récupération de l'année du dernier exercice existant
	 */
	getLastExerciceYear(): Observable<number> {
		//Définition des critères de recherches
		const searchSpec: SearchSpec = {
			numPage: 0,
			nbObjetsParPage: 1,
			defaultOrder: '-idExercice'
		};

		//Appel HTTP
		return this.http.post<Page<Exercice>>(`${environment.baseUrl}/controller/Exercice/getListeExercice`,searchSpec).pipe(
			first(),
			map(result => moment(result.listeResultats[0].dateDebut).get('year'))
		);
	}

	/**
	 * Sauvegarde d'une période
	 *
	 * @param periode la période à sauvegarder
	 */
	savePeriode(periode: Periode): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Exercice/savePeriode`,periode);
	}

	/**
	 * Méthode de clôture/déclôture d'une liste de périodes
	 *
	 * @param idExercice l'exercice qui contient les périodes
	 * @param listePeriodes la liste des ID des périodes à clore/déclore
	 * @param isCloture true si c'est une clôture, false pour une déclôture
	 * @param idPeriodeCible la période sur laquelle reporter les éléments qui bloquent la clôture
	 */
	doCloturePeriodes(idExercice: number,listePeriodes: number[],isCloture: boolean,idPeriodeCible: number): Observable<Result> {
		//Construction des paramètres HTTP
		const params: HttpParams = new HttpParams()
			.set('idExercice',idExercice.toString())
			.set('listePeriodes[]',listePeriodes.toString())
			.set('isCloture',String(isCloture))
			.set('idPeriodeCible',idPeriodeCible.toString())
			.set('isClotureExercice',String(true))

		return this.http.post<Result>(`${environment.baseUrl}/controller/Exercice/doCloturePeriodes`,null,{params: params});
	}

	/**
	 * Récupération de la liste des périodes ouvertes
	 */
	getListePeriodesOuvertes(): Observable<Result> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Exercice/listePeriodesOuvertes`,null);
	}
}
