<div class="lvi-content list-view-item-hover" [ngClass]="{ 'danger': data.isMissingParam }">
    <div class="avatar" *ngIf="TypeNature.icone(data.typeNature) as iconTypeNature" [ngClass]="{ 'danger': data.isMissingParam }">
        <i [class]="iconTypeNature.cssClass">{{ iconTypeNature.icon }}</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="openAiguillage(data)">{{ data.isMissingParam ? ('admin.voyages.travelhub.profilConnexion.aiguillageManquant' | translate) : data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.voyages.travelhub.profilConnexion.aiguillage'"></strong>
                <span>{{ TypeAiguillage.traduction(data.typeAiguillage) | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.voyages.travelhub.profilConnexion.nature'"></strong>
                <span>{{ TypeNature.traduction(data.typeNature) | translate }}</span>
            </li>
            <li *ngIf="!!data.travelHubSBTConfigUsed">
                <strong [translate]="'admin.voyages.travelhub.profilConnexion.configuration'"></strong>
                <span>{{ data.travelHubSBTConfigUsed?.libelle }}</span>
            </li>
        </ul>
    </div>
    <div *ngIf="!data.isMissingParam" class="lvi-actions">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="deleteAiguillage(data.idProfilConnexionParam)" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
