<div class="lvi-content">
    <div class="avatar">
        <i class="material-icons">calendar_month</i>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="onClick()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li *ngIf="data.dateDebut">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercices.liste.dateDebut'"></strong>
                <span>{{ data.dateDebut | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.dateFin">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercices.liste.dateFin'"></strong>
                <span>{{ data.dateFin | date: 'shortDate' }}</span>
            </li>
            <li *ngIf="data.nbPeriodes">
                <strong [translate]="'admin.comptabilite.exercicesPeriodes.exercices.liste.periodes'"></strong>
                <span>{{ data.nbPeriodes }}</span>
            </li>
        </ul>
    </div>
    <div class="info" *ngIf="data.closed">
        <mat-icon [matTooltip]="'admin.comptabilite.exercicesPeriodes.exercices.liste.cloture' | translate">lock</mat-icon>
    </div>
</div>
