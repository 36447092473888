import {Component,EventEmitter,Input} from '@angular/core';
import {ListViewItem} from "@domain/common/list-view/list-view-item";
import {Approbateur,WFUser} from "@domain/workflow/evenement";

/**
 * Composant d'affichage d'une ligne de la liste des approbateurs
 *
 * @author Laurent Convert
 * @date 18/04/2024
 */
@Component({
	host: {'data-test-id': 'approbateur-list-item'},
	templateUrl: './approbateur-list-item.component.html'
})
export class ApprobateurListItemComponent extends ListViewItem<Approbateur> {
	/** Options supplémentaires */
	@Input() extraOptions: {
		onApprobateurChange: EventEmitter<{approbateur: WFUser,listeApprobateurs: Approbateur}>,
	}

	/**
	 * Handler de la sélection de l'approbateur
	 *
	 * @param approbateur Approbateur sélectionné
	 */
	selectApprobateur(approbateur: WFUser): void {
		this.extraOptions.onApprobateurChange.emit({
			approbateur: approbateur,
			listeApprobateurs: this.data
		});
	}

	/**
	 * Renvoi True si au moins un approbateur n'est pas filtré
	 */
	hasApprobateursVisible(): boolean {
		return this.data.approbateurs.some(d => !d.isFiltre);
	}

	/**
	 * Renvoie la liste des approbateurs visibles (non filtrés)
	 */
	get listeVisibleApprobateurs(): Array<WFUser> {
		return this.data.approbateurs.filter(u => !u.isFiltre);
	}

}