import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@domain/appstate";
import * as settingsActions from "@reducers/settings";

/**
 * Accueil de la partie administration
 *
 * @author Laurent Convert
 * @date 05/10/2022
 */
@Component({
    host: {'data-test-id': 'admin'},
    selector: 'admin',
    templateUrl: './admin.component.html',
    //TODO LCO : ADMIN_V10_BETA : à supprimer post BETA V10
    styleUrls: ['./ribbon.scss','./admin.component.scss']
})
export class AdminComponent {

    constructor(private store: Store<AppState>,) {

    }

    ngOnInit() {
        this.store.dispatch({
            type: settingsActions.LOAD_SETTINGS,
            payload: ['Global']
        });
    }
}