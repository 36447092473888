<div class="with-floating-button">
    <div class="content" *ngIf="region">
        <page-header *ngIf="!isCreation" [title]="'admin.bibliotheque.geographie.region.title' | translate: {libelle: region.libelle}" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <page-header *ngIf="isCreation" [title]="'admin.bibliotheque.geographie.region.titleCreation' | translate" [listeItems]="listeTabItems"
                     (onSelectedItemChange)="onSelectedItemChange($event)" (onGoBack)="onGoBack()">
        </page-header>

        <div [hidden]="selectedItem?.code !== Onglets.GENERALITES">
            <form #form="ngForm" *ngIf="region">
                <region-generalites [region]="region"></region-generalites>
            </form>
        </div>
        <floating-button [listeActions]="listeActions" [isPending]="isSaving"></floating-button>
    </div>
    <please-wait class="content" *ngIf="!region"></please-wait>
</div>