import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {Distance} from "@domain/geographie/distance";
import {NgForm} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction, TypeAction} from "@share/component/floating-button/floating-button";
import {ActivatedRoute, Router} from "@angular/router";
import {GeographieService} from "@services/admin/geographie/geographie.service";
import {TranslateService} from "@ngx-translate/core";
import {TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {filter, finalize} from "rxjs/operators";
import {Origine} from "@domain/geolocalisation/localisation";

/**
 * Composant d'affichage du détail d'une distance
 *
 * @author Angeline Ha
 * @date 02/02/2024
 */
@Component({
    host: {'data-test-id': 'distance'},
    templateUrl: './distance.component.html'
})
export class DistanceComponent implements OnInit, OnDestroy {

    /** Liste des différents onglets disponibles dans une Distance */
    listeTabItems: Array<PageHeaderItem>;

    /** Onglet courant */
    selectedItem: PageHeaderItem;

    /** Référence vers l'enum pour l'utiliser dans le template */
    Onglets = Onglets;

    /** Distance à afficher */
    distance: Distance;

    /** Formulaire */
    @ViewChild('form') form: NgForm;

    /** Indique s'il faut afficher le formulaire en création ou en édition */
    isCreation: boolean;

    /** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

    /** Indicateur d'enregistrement en cours */
    isSaving: boolean = false;

    /**
     * Constructeur
     *
     * @param router            Router
     * @param route             Route active
     * @param geographieService Service géographie
     * @param translateService  Service de traduction
     * @param toastrService     Service de toast
     * @param confirmService    Service de confirmation
     */
    constructor(private router: Router,
                private route: ActivatedRoute,
                private geographieService: GeographieService,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private confirmService: ConfirmService) {
    }

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Indique au composant géographie qu'on affiche le détail d'une distance
        this.geographieService.isShowDetail = true;

        //Longueur de l'url pour récupérer l'identifiant de la distance
        let length = this.route.snapshot.url.length;
        //Récupération de l'identifiant de la distance
        let distanceId: number = Number(this.route.snapshot.url[length - 1].path);

        //Indique si on est en mode création
        this.isCreation = distanceId === 0;

        //Initialisation des onglets
        this.listeTabItems = [{
            code: Onglets.GENERALITES,
            libelle: this.translateService.instant('admin.bibliotheque.geographie.distance.onglets.generalites')
        }];

        //Sélection du premier onglet par défaut
        this.selectedItem = this.listeTabItems[0];

        //Définition de la liste des actions possibles
        this.defineListeActionsPossibles();

        //Récupération de la distance
        if (this.isCreation) {
            //Création d'une nouvelle distance
            this.distance = new Distance({
                idDistance: 0,
                idDepart: null,
                idArrivee: null,
                idVilleDepart: 0,
                idIataDepart: 0,
                idVilleArrivee: 0,
                idIataArrivee: 0,
                distance: null,
                idUnite: null,
                unite: null,
                origine: Origine.ORIGINE_ADMIN,
                strDepart: "",
                strArrivee: "",
                libelle: ""
            });
        } else {
            //Récupération de la distance existante
            this.geographieService.getDistanceById(distanceId).subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    this.distance = result.data.distance;
                    this.reinitIdLieux();
                } else {
                    this.distance = null;

                    //Message d'erreur
                    TypeCodeErreur.showError(result.codeErreur, this.translateService, this.toastrService);
                }
            });
        }
    }

    /**
     * Destruction
     */
    ngOnDestroy() {
        //Indique au composant géographie qu'on n'affiche plus le détail d'une distance
        this.geographieService.isShowDetail = false;
    }

    /**
     * Définition de la valeur de la liste des actions possibles
     */
    defineListeActionsPossibles() {
        //Liste des actions
        const listeActions: Array<FloatingButtonAction> = [{
            type: TypeAction.PRIMARY,
            icone: 'nio icon-sauvegarde',
            libelle: 'global.actions.enregistrer',
            doAction: () => this.saveDistance(),
            isDisabled: () => !this.form?.valid
        }];

        //Ajout de l'action de suppression lorsqu'on est en modification
        if (!this.isCreation) {
            listeActions.push({
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                doAction: () => this.deleteDistance()
            });
        }

        this.listeActions.next(listeActions);
    }

    /**
     * Retour vers la liste des distances
     */
    onGoBack() {
        this.router.navigate(['../..'], {relativeTo: this.route});
    }

    /**
     * Reinitialisation des identifiants des lieux (ville, iata) de départ et d'arrivée
     */
    reinitIdLieux() {
        this.distance.idVilleDepart = 0;
        this.distance.idIataDepart = 0;
        this.distance.idVilleArrivee = 0;
        this.distance.idIataArrivee = 0;
    }

    /**
     * Enregistrement de la distance
     */
    saveDistance() {
        this.isSaving = true;
        this.geographieService.saveDistance(this.distance)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(result => {
                if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                    //Toast de succès
                    this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

                    //Remise à zéro des identifiants
                    this.reinitIdLieux();

                    //Récupération des identifiants de départ et d'arrivée
                    this.distance.idDepart = result.data.idDepart;
                    this.distance.idArrivee = result.data.idArrivee;

                    if (this.isCreation) {
                        //Récupération de l'identifiant de la distance
                        this.distance.idDistance = result.data.idDistance;

                        //On n'est plus en mode création
                        this.isCreation = false;

                        //Ajout de l'action de suppression
                        this.defineListeActionsPossibles();

                        //Navigation avec le bon id
                        this.router.navigate(['../' + result.data.idDistance], {relativeTo: this.route});
                    }
                } else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
                    this.toastrService.error(this.translateService.instant('admin.bibliotheque.geographie.distance.errorDoublon'))
                } else {
                    //Toast d'erreur
                    TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE, this.translateService, this.toastrService);
                }
            });
    }

    /**
     * Suppression de la distance
     */
    deleteDistance() {
        //Popup de confirmation
        this.confirmService.showConfirm(this.translateService.instant('admin.bibliotheque.geographie.distance.confirmSuppression'))
            .pipe(filter(isConfirmed => isConfirmed)).subscribe({
            next: () => {
                //Suppression de la distance
                this.geographieService.deleteDistance(this.distance.idDistance).subscribe(result => {
                    if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
                        //Retour vers la liste des distances
                        this.onGoBack();

                        //Toast de succès
                        this.toastrService.success(this.translateService.instant('global.success.suppression'))
                    } else {
                        //Toast d'erreur
                        this.toastrService.error(this.translateService.instant('global.errors.suppression'))
                    }
                })
            }
        })
    }
}

/**
 * Enum des différents onglets d'une distance
 */
export enum Onglets {
    GENERALITES = "Généralités"
}
