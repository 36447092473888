import {Component, Input} from "@angular/core";
import {Devise} from "@domain/settings/devise";
import {ControlContainer, NgForm} from "@angular/forms";

/**
 * Composant d'affichage des informations du cadre "Devise"
 */
@Component({
    host: {'data-test-id': 'devise-infos-generalites'},
    selector: 'devise-infos-generalites',
    templateUrl: './devise-infos-generalites.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DeviseInfosGeneralitesComponent {
    /** Devise sélectionnée */
    @Input() devise: Devise;

    /** Booléen pour savoir si l'affichage du formulaire se fait en création ou en modification, pour le champ du code (lecture seule si on est en modification) */
    @Input() isCreation: boolean;

    /** Tableau des parités disponibles */
    optionsParite = [
        {code: 0,libelle: '1'},
        {code: 1,libelle: '10'},
        {code: 2,libelle: '100'},
        {code: 3,libelle: '1000'},
        {code: 4,libelle: '10000'},
        {code: 5,libelle: '100000'},
        {code: 6,libelle: '1000000'}
    ];
}