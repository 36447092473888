import { Directive,Output,EventEmitter,ElementRef,NgZone,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { environment } from '../../../environments/environment';

@Directive({
	selector: '[recaptcha]',
	exportAs: 'reCaptcha'
})
export class RecaptchaDirective {
	/** Evènement en cas de récupération d'un token **/
	@Output() onRecaptchaSuccess = new EventEmitter<string>();

	/** Identifiant du captcha **/
	private readonly scriptId = 'script-recaptcha';

	/** Identifiant du widget **/
	widgetId: number | null = null;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private ngZone: NgZone,@Inject(DOCUMENT) private readonly document: Document) {}

	/**
	 * Initialisation de la directive
	 */
	ngOnInit() {
		//Initialisation du captcha
		this.registerCaptchaCallback();

		//Ajout du script au DOM
		this.loadScript();
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Réinitialisation de l'identifiant du widget
		this.widgetId = null;
	}

	/**
	 * Initialisation du captcha
	 */
	private registerCaptchaCallback() {
		//Création de la méthode de callback appelée après le chargement du script
		(window as any).recaptchaCallback = this.onLoadCallback.bind(this);
	}

	private onLoadCallback() {
		//Définition du widget
		this.widgetId = this.renderCaptcha({
			sitekey: environment.recaptchaKey,
			callback: this.onSuccessCallback.bind(this)
		});
	}

	/**
	 * Création du script reCaptcha
	 */
	private loadScript() {
		let script;

		//Vérification de la présence du script
		if (!this.document.getElementById(this.scriptId)) {
			//Création du script
			script = this.document.createElement('script');

			//Définition du script
			script.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit';
			script.id = this.scriptId;
			script.async = true;
			script.defer = true;

			//Ajout du script au DOM
			this.document.body.appendChild(script);
		} else
			//Affichage du captcha
			this.onLoadCallback();

	}

	/**
	 * Remise à zéro du captcha
	 */
	reset() {
		//Remise à zéro du captcha
		(window as any).grecaptcha.reset();
	}

	/**
	 * Recaptcha réussi avec succès
	 */
	private onSuccessCallback(token: string) {
		//Mise en cycle
		this.ngZone.run(() => {
			//Emission du token
			this.onRecaptchaSuccess.emit(token);
		});
	}

	/**
	 * Affichage du captcha
	 */
	private renderCaptcha(config: any): number {
		//Affichage du captcha dans le DOM
		return (window as any).grecaptcha.render(this.elementRef.nativeElement,config);
	}

}