import {Component,OnInit} from '@angular/core';
import {ListView,TypeComparaison,TypeFilter} from "@domain/common/list-view";
import {Page} from "@domain/common/http/list-result";
import {Sorting} from "@domain/common/list-view/sorting";
import {ProfilConnexion} from "@domain/travelhub/profil-connexion";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "@environments/environment";
import {ProfilConnexionListItemComponent} from "./profil-connexion-list-item/profil-connexion-list-item.component";
import {BehaviorSubject} from "rxjs";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {Router} from "@angular/router";
import {AdminTravelhubService} from "../admin-travelhub.service";

/**
 * Onglet 'Profil de connexion' du module d'administration 'Voyages/TravelHub'
 *
 * @author Laurent Convert
 * @date 29/02/2024
 */
@Component({
	host: {'data-test-id': 'profil-connexion'},
	templateUrl: './profil-connexion.component.html'
})
export class ProfilConnexionComponent implements OnInit {

	/** Liste des profils de connexion */
	listeProfilConnexion: ListView<ProfilConnexion,ProfilConnexionListItemComponent>;

	/** Liste des actions possibles */
	listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/**
	 * Constructeur
	 *
	 * @param router Le routeur angular
	 * @param translateService Service de traduction
	 */
	constructor(private router: Router,
				private translateService: TranslateService,
	) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Création de la liste
		this.listeProfilConnexion = new ListView<ProfilConnexion,ProfilConnexionListItemComponent>({
			uri: `${environment.baseUrl}/controller/ProfilConnexion/listeProfilConnexion`,
			title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.title'),
			component: ProfilConnexionListItemComponent,
			mapResult: (result: Page<ProfilConnexion>): Page<ProfilConnexion> => {
				result.listeResultats = result.listeResultats.map(pc => new ProfilConnexion(pc));
				return result;
			},
			isFilter: true,
			defaultOrder: 'libelle',
			listeFilters: [
				{
					clef: 'libelle',
					title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.libelle'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'code',
					title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.code'),
					isDefault: true,
					typeComparaison: TypeComparaison[TypeComparaison.LIKE]
				},{
					clef: 'isOnline',
					title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.online'),
					type: TypeFilter[TypeFilter.BOOLEAN]
				},{
					clef: 'isOffline',
					title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.offline'),
					type: TypeFilter[TypeFilter.BOOLEAN]
				},{
					clef: 'isInterne',
					title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.interne'),
					type: TypeFilter[TypeFilter.BOOLEAN]
				},{
					clef: 'isActif',
					title: this.translateService.instant('admin.voyages.travelhub.profilConnexion.actif'),
					type: TypeFilter[TypeFilter.BOOLEAN]
				}
			]
		});

		//Persistence des filtres
		this.listeProfilConnexion.isPersistFilters = true;
		this.listeProfilConnexion.className = 'ProfilConnexionComponent';

		//Définition des colonnes de tri
		this.listeProfilConnexion.columns = [
			{ key: 'libelle', title: 'admin.voyages.travelhub.profilConnexion.libelle' },
			{ key: 'code', title: 'admin.voyages.travelhub.profilConnexion.code' },
			{ key: 'isOnline', title: 'admin.voyages.travelhub.profilConnexion.online' },
			{ key: 'isOffline', title: 'admin.voyages.travelhub.profilConnexion.offline' },
			{ key: 'isInterne', title: 'admin.voyages.travelhub.profilConnexion.interne' },
			{ key: 'isActif', title: 'admin.voyages.travelhub.profilConnexion.actif' },
		];

		//Ajout du tri de la liste selon l'ordre voulu
		this.listeProfilConnexion.sorting = new Sorting(this.listeProfilConnexion.columns,this.listeProfilConnexion.defaultOrder);

		//Définition de la liste des actions
		this.listeActions.next([{
			type: TypeAction.PRIMARY,
			icone: 'nio icon-ajouter',
			libelle: 'global.actions.creer',
			doAction: () => this.createNewProfilConnexion(),
		}]);
	}

	/**
	 * Création d'un nouveau profil de connexion
	 */
	private createNewProfilConnexion(): void {
		this.router.navigate([AdminTravelhubService.URL_PROFIL_CONNEXION,0]);
	}

}