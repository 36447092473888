<div class="with-floating-button">
    <div class="content" *ngIf="objetWorkflow">
        <page-header
                [title]=" isAdmin ? objetWorkflow.idOd.toString() : ('od.title' | translate: { numero: objetWorkflow.idOd })"
                [extraInfo]="objetWorkflow.statut.libelle"
                [listeItems]="listeTabItems"
                [niveauAlerte]="listeAlertes?.niveau"
                (onSelectedItemChange)="onSelectedItemChange($event)"
                (onGoBack)="onGoBack()"
                (onExtraInfoClick)="showChainage()"
                [compactMode]="isAdmin"
                [isPending]="isSaving || isLoading">
            <preview-alerte #alerte *ngFor="let alerte of listeAlertes?.listeAlertes" [alerte]="alerte"></preview-alerte>
        </page-header>

        <!-- Onglet des généralités -->
        <div [hidden]="selectedItem?.code !== 'GENERALITES'">
            <!-- Cadre généralité -->
            <od-generalites #odGeneralite [od]="objetWorkflow" [canModifier]="canModifier" [settings]="$any(settings)" [typeEntiteParam]="typeEntiteParam"></od-generalites>

            <!-- Cadre du fait générateur -->
            <od-generalite-fait-generateur *ngIf="typeEntiteParam.gestionFaitGenerateur" [od]="objetWorkflow" [canModifier]="canModifier" [listeFaitGenerateurs]="listeFaitGenerateurs"
                                           [typeEntiteParam]="typeEntiteParam"></od-generalite-fait-generateur>

            <!-- Cadre analytique -->
            <analytique
				#analytique="analytique"
                [listeAnalytiques]="objetWorkflow.listeVentilations" [typeEntite]="objetWorkflow.typeEntite"
                [idMIB]="objetWorkflow.periode?.idPeriode"
                [settings]="settings"
                [canModifier]="canModifier" [canSaisie]="canModifierAnalytique"
                [preferenceAnalytiqueCount]="preferenceAnalytiqueCount"
                [idCollab]="objetWorkflow.getIdCollab()"
                (onModification)="buildListeAlertes()"
                [isOrgaFixe]="settings.isOrgaFixe">
            </analytique>

            <!-- Cadre enveloppes budgétaires -->
            <suivi-budget [idTypeObjet]="TypePorteeInt.OD" [idObjet]="objetWorkflow.idOd"></suivi-budget>
        </div>

        <!-- Onglet voyage -->
        <div *ngIf="isVoyageLoaded && isOngletVoyageActif" [hidden]="selectedItem?.code != 'VOYAGE'">
            <od-voyage [od]="objetWorkflow" [aiguillageOd]="aiguillageOd" [canModifier]="canModifier" [canCompleter]="canCompleter"
                       [settings]="$any(settings)" [devise]="settings.deviseEntreprise" [modeParticipant]="modeParticipant" (etapesChanged)="onSynchroNeeded()"
                       [isVoyageAutorise]="isVoyageAutorise"></od-voyage>
        </div>

        <!-- Onglet frais -->
        <div *ngIf="selectedItem?.code == 'FRAIS'">
            <od-frais [od]="objetWorkflow" [canModifier]="canModifier" [canCompleter]="canCompleter" (isFraisPrevChange)="onSynchroNeeded($event)"
                      (onDegrevementsChanged)="onDegrevementsChanged()"></od-frais>
        </div>

        <!-- Onglet engagements -->
        <div *ngIf="selectedItem?.code == 'ENGAGEMENTS'">
            <od-engagements [od]="objetWorkflow"></od-engagements>
        </div>

        <!-- Onglet fiche collaborateur -->
        <div *ngIf="selectedItem?.code == 'FICHECOLLABORATEUR'">
            <fiche-collaborateur [idCollaborateur]="idCollaborateur"></fiche-collaborateur>
        </div>

        <!-- Onglet compléments -->
        <div *ngIf="selectedItem?.code == 'COMPLEMENTS'">
            <document-list
                    [idObjet]="objetWorkflow.idOd"
                    [idObjetForPJ]="objetWorkflow.idOd"
                    [idCollaborateur]="idCollaborateur"
                    [context]="'OD'"
                    [settings]="settings"
                    [canAddDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
                    [canDeleteDocument]="(canModifier || canCompleter) && settingsService.isPjParamForTypeEntite(settings,objetWorkflow.getTypeEntite().idTypeEntite)"
            ></document-list>

            <!-- Liste des référentiels externes -->
            <referentiel-externe *ngIf="settings?.isReferentielExterneAvailable" [typeReferentiel]="portee" [idObject]="objetWorkflow.idOd"></referentiel-externe>
        </div>

        <!-- Onglet workflow -->
        <div *ngIf="selectedItem?.code === OngletsAdmin.WORKFLOW">
            <onglet-workflow
                    [isObjetValide]="isValid()"
                    [portee]="TypePortee.OD"
                    [listeVentilations]="objetWorkflow.listeVentilations"
                    [typeEntite]="objetWorkflow.typeEntite"
                    [globalSettings]="settings"
                    [idObjet]="objetWorkflow.idOd">
            </onglet-workflow>
        </div>

        <!-- Onglet outils -->
        <div *ngIf="selectedItem?.isLoaded" [hidden]="selectedItem?.code != 'OUTILS'">
            <outils [idObjet]="objetWorkflow.idOd" [typePortee]="TypePortee.OD" [isObjetValide]="isValid()"></outils>
        </div>
    </div>
    <please-wait class="content" *ngIf="objetWorkflow === undefined"></please-wait>
    <floating-button [listeActions]="listeActions" [isPending]="isSaving || isLoading"></floating-button>
</div>
