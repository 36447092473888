/**
 * DTO envoyé pour la fiche collaborateur
 */
export class FicheCollaborateur {
    idCollaborateur: number;
    nom: string;
    prenom: string;
    email: string;
    matricule: string;
    residencePersonnelle: string;
    societe: string;
    service: string;
    categorie: String;
    titre: string;
    residenceAdministrative: string;

    libelleChamp1: string;
    libelleChamp2: string;
    libelleChamp3: string;
    libelleChamp4: string;

    champ1: string;
    champ2: string;
    champ3: string;
    champ4: string;

    attribut1: string;
    attribut2: string;
    attribut3: string;
    attribut4: string;
}