<div class="lvi-content">
    <div #item class="avatar" [nSelector]="{ liste: liste, item: data }" (click)="onSelect()">
        {{ data.code || data.libelle.substring(0, 3) }}
    </div>
    <div class="lvi-body">
        <div class="title">
            <a>{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.selectModeRembPopin.liste.selectionne'"></strong>
                <span [translate]="data.isSelected ? 'confirmation.oui' : 'confirmation.non'"></span>
            </li>
            <li>
                <strong [translate]="'admin.entreprise.utilisateurs.detail.metier.selectModeRembPopin.liste.modeDefaut'"></strong>
                <span [translate]="data.idModeRemb === extraOptions.collab.idModeRemb ? 'confirmation.oui' : 'confirmation.non'"></span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions">
        <button mat-icon-button (click)="extraOptions.collab.idModeRemb = data.idModeRemb" *ngIf="data.idModeRemb === extraOptions.collab.idModeRemb" disabled>
            <mat-icon [matTooltip]="'admin.entreprise.utilisateurs.detail.metier.selectModeRembPopin.liste.modeDefaut' | translate">
                star
            </mat-icon>
        </button>
        <button mat-icon-button (click)="onDefaultSelect()" *ngIf="data.idModeRemb !== extraOptions.collab.idModeRemb">
            <mat-icon [matTooltip]="'admin.entreprise.utilisateurs.detail.metier.selectModeRembPopin.liste.makeDefault' | translate">
                star_outline
            </mat-icon>
        </button>
    </div>
</div>