import {NgModule} from '@angular/core';
import {NotZeroDirective} from '../validator/not-zero-directive';
import {NotNullValidatorDirective} from "./not-null-directive";
import {CreditCardDirective} from '../validator/credit-card-directive';
import {DateAfterNowDirective} from '../validator/date-before-now-directive'
import {HourAfterOtherDirective} from "@share/directive/validator/hour-after-other.directive";
import {NumberSuperiorOtherDirective} from "@share/directive/validator/number-superior-other";
import {RegexDirective} from "@share/directive/validator/regex-directive";

@NgModule({
	imports: [],
	declarations: [NotZeroDirective, CreditCardDirective, DateAfterNowDirective,NotNullValidatorDirective,
		HourAfterOtherDirective,
		NumberSuperiorOtherDirective,
		RegexDirective
	],
	exports: [NotZeroDirective, CreditCardDirective, DateAfterNowDirective,NotNullValidatorDirective,
		HourAfterOtherDirective,
		NumberSuperiorOtherDirective,
		RegexDirective
	],
	providers: []
})
export class ValidatorModule { }

