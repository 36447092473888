import { Component,Input } from '@angular/core';
import { ListViewItem } from "../../../../domain/common/list-view/list-view-item";
import { TypeMontant } from "../../../../domain/budget/type-montant.enum";
import { TypeBudget } from "../../../../domain/budget/type-budget.enum";
import { TranslateService } from "@ngx-translate/core";
import { DetailEnveloppe } from "../../../../domain/budget/detailEnveloppe";

@Component({
    templateUrl: './detail-enveloppe-list-item.component.html'
})
export class DetailEnveloppeListItemComponent extends ListViewItem<DetailEnveloppe> {

    /** Ligne d'imputation courante */
    @Input() data: DetailEnveloppe;

    /** On injecte l'énum des TypeMontant */
    typeMontant = TypeMontant;
    /** On injecte l'énum des TypeBudget */
    typeBudget = TypeBudget;

    /** Indique si la ligne est affichée en mode étendu ou non */
    isOpened: boolean = false;

    /**
     * Constructeur
     *
     * @param translateService moteur de trad
     */
    constructor(private translateService: TranslateService) {
        //Héritage
        super();
    }

    /**
     * Renvoie le libellé d'un typeMontant
     */
    getLibelleTypeMontant(): String {
        switch (this.data.typeMontant) {
            case TypeMontant.NDF:
                return this.translateService.instant("budget.typeMontant.ndf");
            case TypeMontant.IJ:
                return this.translateService.instant("budget.typeMontant.ij");
            case TypeMontant.FACTURE:
                return this.translateService.instant("budget.typeMontant.facture");
            case TypeMontant.DOSSIER_VOYAGE:
                return this.translateService.instant("budget.typeMontant.dossierVoyage");
            case TypeMontant.FRAIS_PREVISIONNEL:
                return this.translateService.instant("budget.typeMontant.fraisPrev");
        }
    }
}
