import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {environment} from "@environments/environment";
import {EntityWrapper} from "@domain/entite/EntityWrapper";
import {map} from "rxjs/operators";
import {TypePortee} from "@domain/workflow/workflow";

/**
 * Service de gestion des entités
 */
@Injectable()
export class EntiteService {
	/**
	 * Constructeur
	 *
	 * @param http le client HTTP
	 * @param translateService le moteur de traduction
	 * @param toastrService le toaster
	 */
	constructor(
		private http: HttpClient,
		private translateService: TranslateService,
		private toastrService: ToastrService
	) {
	}

	/**
	 * Récupère l'entité en fonction de la portée
	 *
	 * @param idPortee la portée
	 */
	static getEntiteByPortee(idPortee: TypePortee): string {
		switch (idPortee) {
			case TypePortee.AV:
				return 'com.notilus.data.avance.Avance';
			case TypePortee.DV:
				return 'com.notilus.data.od.DossierVoyage';
			case TypePortee.FC:
				return 'com.notilus.data.facture.Facture';
			case TypePortee.NF:
				return 'com.notilus.data.ndf.NDF';
			case TypePortee.OD:
				return 'com.notilus.data.od.OD';
			case TypePortee.OT:
				return 'com.notilus.data.om.OMPermanent';
		}
	}

	/**
	 * Chargement des business data d'une entité
	 *
	 * @param entite l'entité
	 */
	loadBusinessData(entite: string): Observable<EntityWrapper> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entite/loadBusinessData?entite=${entite}`,null).pipe(
			map((result: Result) => {
				//Vérification du résultat de la requête
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					return result.data.businessData;
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			}),
			map((entite: EntityWrapper) => {
				//Traduction de l'entité
				this.translateEntity(entite);

				return entite;
			})
		);
	}

	/**
	 * Chargement des données métier d'une sous-entité
	 *
	 * @param entite l'entité
	 * @param listePaths chemin vers cette entité
	 */
	loadSubEntity(entite: string,listePaths: string[]): Observable<EntityWrapper> {
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entite/loadSubEntity?entite=${entite}`,listePaths).pipe(
			map((result: Result) => {
				//Vérification du résultat de la requête
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					return result.data.enclosedWrapper
				} else {
					//Gestion de l'erreur
					TypeCodeErreur.showError(result.codeErreur,this.translateService,this.toastrService);
				}
			}),
			map((entite: EntityWrapper) => {
				//Traduction de l'entité
				this.translateEntity(entite);

				return entite;
			})
		);
	}

	/**
	 * Traduction du libellé et des membres d'une entité
	 *
	 * @param entite l'entité
	 */
	translateEntity(entite: EntityWrapper): void {
		if (entite) {
			//Définition du libellé
			entite.libelle = this.translateEntityCode(entite.code);

			//Traduction des libellés des membres
			this.translateEntityMembers(entite);
		}
	}

	/**
	 * Récupère le libellé de l'entité avec sa clé de traduction
	 *
	 * @param code la clé de traduction de l'entité
	 */
	translateEntityCode(code: string): string {
		return this.translateService.instant('businessData.entites.' + code);
	}

	/**
	 * Récupère le libellé d'un attribut avec la clé de traduction
	 *
	 * @param key la clé de traduction
	 */
	translateAttrKey(key: string): string {
		return this.translateService.instant('businessData.attributs.' + key);
	}

	/**
	 * Traduction des membres de la donnée métier
	 *
	 * @param businessData la donnée métier
	 */
	translateEntityMembers(businessData: EntityWrapper) {
		//Parcourt des membres de l'entité
		businessData.listeMembres?.forEach(membre => {
			//Ajout du libellé du membre (attribut)
			membre.libelle = this.translateAttrKey(membre.translationKey);

			//Vérification de la présence d'une sous-entité
			if (membre.enclosedWrapper) {
				//Ajout du libellé sur l'entité du membre
				membre.enclosedWrapper.libelle = this.translateEntityCode(membre.enclosedWrapper.code);

				//Appel récursif
				this.translateEntityMembers(membre.enclosedWrapper);
			}
		});
	}
}