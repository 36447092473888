import {Component,Input,OnInit,Type} from '@angular/core';
import {FieldUsed} from "@domain/travel/field-used";
import {AbstractField} from "@domain/travel/abstract-field";
import {ListView} from "@domain/common/list-view";
import {TranslateService} from "@ngx-translate/core";
import {Page} from "@domain/common/http/list-result";
import {TravelHubSBTConfigUsed} from "@domain/travel/travel-hub-sbt-config-used";
import {MatDialog} from "@angular/material/dialog";
import {FieldAddComponent} from "./field-add/field-add.component";
import {Observable} from "rxjs";
import {AdminTravelhubService} from "../../../../admin-travelhub.service";
import {Result,TypeCodeErreur} from "@domain/common/http/result";
import {ToastrService} from "ngx-toastr";
import {FieldListItemComponent} from "@components/admin/voyages/travelhub/sbt-config/sbt-config-add/parametres/field/field-list-item/field-list-item.component";
import {TripFieldUsed} from "@domain/travel/trip-field-used";
import {CustomFieldUsed} from "@domain/travel/custom-field-used";
import {TripField} from "@domain/travel/trip-field";
import {CustomField} from "@domain/travel/custom-field";

/**
 * Composant générique des cadres "Trip Fields" et "Custom Fields"
 *
 * @author Laurent Convert
 * @date 05/12/2023
 */
@Component({
	host: {'data-test-id': 'field'},
	selector: 'field',
	templateUrl: './field.component.html'
})
export class FieldComponent<U extends FieldUsed> implements OnInit {
	/** Configuration SBT */
	@Input() sbtConfigUsed: TravelHubSBTConfigUsed;

	/** Liste des Fields disponibles */
	@Input() listeField: Array<AbstractField> = [];

	/** Liste des Fields configurés */
	@Input() listeFieldUsed: Array<FieldUsed> = [];

	/** Type de field (CustomField / TripField) */
	@Input() type: Type<U>;

	/** ListView des Fields */
	liste: ListView<FieldUsed,FieldListItemComponent<U>>;

	/** Racine pour les traductions (en fonction du type) */
	private rootTradField: string;

	/** Liste des champs disponibles pour le SBT */
	private listeFieldFiltered: Array<AbstractField> = [];

	/**
	 * Constructeur
	 *
	 * @param adminTravelhubService Service de gestion du module d'administration du TravelHub
	 * @param translateService Service de traduction
	 * @param toastrService Service de notification
	 * @param matDialog Boîte de dialogue
	 */
	constructor(private adminTravelhubService: AdminTravelhubService,
				private translateService: TranslateService,
				private toastrService: ToastrService,
				private matDialog: MatDialog) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de la racine pour les traductions
		this.rootTradField = this.adminTravelhubService.getRootTradField(this.type);

		//Initialisation de la liste des paramètres
		this.liste = new ListView<FieldUsed,FieldListItemComponent<U>>({
			title: this.translateService.instant(`${this.rootTradField}.title`),
			component: FieldListItemComponent,
			isFrontendList: true,
			showPagination: false,
			listeActions: [{
				icon: "add",
				onPress: () => this.addField(),
				isVisible: () => {
					return this.listeFieldFiltered.length > 0 && this.liste?.data.nbObjetsTotal < this.adminTravelhubService.getMaxField(this.type,this.sbtConfigUsed)
				}
			}],
			extraOptions: {
				parent: this
			},
			data: {
				listeResultats: [],
				numPage: 0,
				nbPagesTotal: 1,
				nbObjetsTotal: 0,
				nbObjetsDansPage: 0,
				nbObjetsParPage: 0
			} as Page<FieldUsed>
		});
	}

	/**
	 * Rafraichissement de la liste
	 */
	refreshListe(result?: Result): void {
		let listeFieldUsed;
		let listeField;

		if (result) {
			//Suivant le type de field
			switch (this.type.name) {
				case TripFieldUsed.name:
					//Récupération de la liste des champs disponibles et configurés
					listeFieldUsed = result.data.listeTripFieldUsed.map(f => new TripFieldUsed(f));
					listeField = result.data.listeTripField.map(f => new TripField(f));

					//Ajout du champ personnalisé le cas échéant
					if (this.sbtConfigUsed.sbtConfig.tripFieldLibre) {
						listeField.push(this.adminTravelhubService.getCustomCode(this.type,this.sbtConfigUsed.sbtConfig));
					}

					break;
				case CustomFieldUsed.name:
					//Récupération de la liste des champs disponibles et configurés
					listeFieldUsed = result.data.listeCustomFieldUsed.map(f => new CustomFieldUsed(f));
					listeField = result.data.listeCustomField.map(f => new CustomField(f));

					//Ajout du champ personnalisé le cas échéant
					if (this.sbtConfigUsed.sbtConfig.customFieldLibre) {
						listeField.push(this.adminTravelhubService.getCustomCode(this.type,this.sbtConfigUsed.sbtConfig));
					}

					break;
				default:
					break;
			}

			//Mise à jour de la liste des champs configurés
			this.listeFieldUsed?.splice(0);
			if (listeFieldUsed?.length > 0) {
				this.listeFieldUsed?.push(...listeFieldUsed);
			}

			//Mise à jour de la liste des champs de référence
			this.listeField?.splice(0);
			if (listeField?.length > 0) {
				this.listeField?.push(...listeField);
			}
		}

		//Ajout des champs déjà configurés à la listview
		this.liste?.clear();
		this.listeFieldUsed?.forEach(p => {
			this.liste.addItem(p,true);
		});

		//Récupération de la liste des champs disponibles pour le SBT
		this.listeFieldFiltered = this.listeField.filter(tf => tf.sbtConfig?.idSBTConfig === this.sbtConfigUsed.sbtConfig.idSBTConfig);
	}

	/**
	 * Ajout d'un Field
	 */
	addField(): void {
		this.openField(null);
	}

	/**
	 * Ouverture d'un Field
	 *
	 * @param fieldUsed Le Field à afficher
	 */
	openField(fieldUsed?: FieldUsed): void {
		//Obtention d'une copie en créant une nouvelle instance du Field
		let field = this.adminTravelhubService.buildField(this.type,fieldUsed);
		field.sbtConfigUsed = this.sbtConfigUsed;

		//Vérification du code custom
		if (!field.field && field.libelleCustomCode != null) {
			//Application du code custom
			field.field = this.adminTravelhubService.getCustomCode(this.type,this.sbtConfigUsed.sbtConfig);
		}

		//Ouverture de la popup
		this.matDialog.open(FieldAddComponent,{
			width: '40%',
			data: {
				field: field,
				sbtConfigUsed: this.sbtConfigUsed,
				listeField: this.listeFieldFiltered,
				parent: this
			}
		});
	}

	/**
	 * Enregistrement d'un Field
	 *
	 * @param field Le Field à enregistrer
	 */
	saveField(field: FieldUsed): Observable<boolean> {
		return this.adminTravelhubService.saveField(field).map(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.enregistrement'));

				//Rafraichissement de la liste
				this.refreshListe(result);

				return true;
			} else if (result?.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
				//Message spécial erreur doublon
				this.toastrService.error(this.translateService.instant(`admin.voyages.travelhub.sbtConfig.parametres.paramConnexion.erreurDoublon`));
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_SAVE,this.translateService,this.toastrService);
		});
	}

	/**
	 * Suppression d'un paramètre de connexion
	 *
	 * @param field Le paramètre de connexion à enregistrer
	 */
	deleteField(field: FieldUsed): Observable<boolean> {
		return this.adminTravelhubService.deleteField(field).map(result => {
			//Vérification du résultat
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message de succès
				this.toastrService.success(this.translateService.instant('global.success.suppression'));

				//Rafraichissement de la liste
				this.refreshListe(result);

				return true;
			} else {
				//Message d'erreur
				TypeCodeErreur.showError(result?.codeErreur,this.translateService,this.toastrService);
			}
		},() => {
			//Message d'erreur
			TypeCodeErreur.showError(TypeCodeErreur.ERROR_DELETE,this.translateService,this.toastrService);
		});
	}
}