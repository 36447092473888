import {Component, OnInit} from "@angular/core";
import {UserDto} from "@domain/user/user-dto";
import {ListViewItem} from "@domain/common/list-view/list-view-item";

/**
 * Composant pour afficher un profil restreint
 */
@Component({
    host: {'data-test-id': 'profils-restreints-user-item'},
    selector: 'profils-restreints-user-item',
    templateUrl: './profils-restreints-user-item.component.html'
})
export class ProfilsRestreintsUserItemComponent extends ListViewItem<UserDto> implements OnInit {
    /** Ensemble des fonctions de l'utilisateur */
    profils: string = '';

    /**
     * Initialisation du composant
     */
    ngOnInit() {
        if (this.data) {
            //Transformation de la chaine de charactères de "fonctions", qui est un ensemble de nombres en une chaine avec des sigles, pour que ça soit compréhensible
            for(let char of this.data.fonctions) {
                //Pour chaque valeur (1, 2, ...) je me suis reporté au TypeProfil dans User
                if(char.toString() === "1") {
                    this.profils += "CPT ";
                } else if (char.toString() === "2") {
                    this.profils += "RSP ";
                } else if (char.toString() === "3") {
                    this.profils += "COL ";
                } else if (char.toString() === "4") {
                    this.profils += "AST ";
                } else if (char.toString() === "5") {
                    this.profils += "SAD ";
                } else if (char.toString() === "6") {
                    this.profils += "FRN ";
                }
            }
        }
    }
}