import { Component } from "@angular/core";

/**
 * Composant d'affichage de l'onglet frais
 */
@Component({
    host: {'data-test-id': 'admin-frais'},
    selector: 'admin-frais',
    templateUrl: './admin-frais.component.html'
})
export class AdminFraisComponent {
}