import { NgModule } from "@angular/core";
import { FraisReferentielItemComponent } from "@components/admin/frais/referentiel/frais-referentiel-item/frais-referentiel-item.component";
import { FraisReferentielComponent } from "@components/admin/frais/referentiel/frais-referentiel.component";
import { FraisReferentielInfosIconesComponent } from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-infos-icones.component";
import { FraisReferentielInfosComponent } from "@components/admin/frais/referentiel/referentiel-infos/frais-referentiel-infos.component";
import { ShareModule } from "@share/share.module";

/**
 * Module des référentiels de la gestion des frais
 */
@NgModule({
    imports: [ShareModule],
    declarations: [
        FraisReferentielComponent,
        FraisReferentielItemComponent,
        FraisReferentielInfosComponent,
        FraisReferentielInfosIconesComponent
    ],
    entryComponents: [FraisReferentielItemComponent],
    exports: [
        FraisReferentielComponent,
        FraisReferentielItemComponent,
        FraisReferentielInfosComponent,
        FraisReferentielInfosIconesComponent
    ],
    providers: []
})
export class FraisReferentielModule {
}
