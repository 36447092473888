/**
 * Informations de signature des CGU pour l'utilisateur courant
 *
 * @author Laurent Convert
 * @date 21/12/2022
 */
export class InfosValidCgu {
    cguDisabledByParam: boolean;
    dateAcceptCgu: Date;
    userIsCollab: boolean;
    isCguLastVersionValid: boolean;
    isSecretaire: boolean;
    nomAcceptCgu: string;
    userIsValidator: boolean;
    //Indique si les CGU pour l'utilisateur connecté ont été validées par l'administrateur
    adminIsValidator: boolean;
    
    constructor(infos: InfosValidCgu) {
        Object.assign(this,infos);
    }
}