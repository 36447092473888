import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer, NgForm} from "@angular/forms";
import {GeographieView} from '@domain/geographie/geographieView';
import {TypeGeographie} from '@domain/geographie/typeGeographie';
import {Ville} from "@domain/geographie/ville";

/**
 * Composant de l'onglet Généralités d'une ville
 *
 * @author Angeline Ha
 * @date 28/11/2023
 */
@Component({
    host: {'data-test-id': 'ville-generalites'},
    selector: 'ville-generalites',
    templateUrl: './ville-generalites.component.html',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class VilleGeneralitesComponent implements OnInit {

    /** Ville */
    @Input() ville: Ville;

    /** Indique si on est en mode création */
    @Input() isCreation: boolean;

    /** Modèle pour l'autocomplete du pays */
    selectedPays: GeographieView;

    /** Modèle pour l'autocomplete de la région */
    selectedRegion: GeographieView;

    /** Modèle pour l'autocomplete du département */
    selectedDepartement: GeographieView;

    /**
     * Initialisation
     */
    ngOnInit(): void {
        //Récupération du pays pour l'autocomplete
        this.selectedPays = {
            id: this.ville.pays?.idPays,
            type: TypeGeographie.PAYS,
            libelle: this.ville.pays?.libelle,
            pays: this.ville.pays
        };

        //Récupération de la région pour l'autocomplete
        this.selectedRegion = {
            id: this.ville.regionAdmin?.idRegion,
            type: TypeGeographie.REGION_ADMINISTRATIVE,
            libelle: this.ville.regionAdmin?.libelle,
            pays: this.ville.regionAdmin?.pays
        };

        //Récupération du département pour l'autocomplete
        this.selectedDepartement = {
            id: this.ville.departementAdmin?.idDepartement,
            type: TypeGeographie.DEPARTEMENT_ADMINISTRATIF,
            libelle: this.ville.departementAdmin?.libelle,
            pays: this.ville.departementAdmin?.regionAdmin?.pays
        };
    }

    /**
     * Mise à jour de l'objet de saisie avec le pays sélectionné dans l'autocomplete
     */
    onPaysChange() {
        this.ville.pays.idPays = this.selectedPays?.id;
    }

    /**
     * Mise à jour de l'objet de saisie avec la région sélectionnée dans l'autocomplete
     */
    onRegionChange() {
        this.ville.idRegion = this.selectedRegion?.id;
    }

    /**
     * Mise à jour de l'objet de saisie avec le département sélectionné dans l'autocomplete
     */
    onDepartementChange() {
        this.ville.idDepartement = this.selectedDepartement?.id;
    }

}