import {Component,OnInit,ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute,Router} from "@angular/router";
import {FiltreAxeAnalytiqueDTO} from "@domain/admin/entreprise/analytique/FiltreAxeAnalytiqueDto";
import {Axe5} from "@domain/admin/entreprise/analytique/axe5";
import {TypeCodeErreur} from "@domain/common/http/result";
import {TranslateService} from "@ngx-translate/core";
import {AnalytiqueService} from "@services/admin/entreprise/analytique.service";
import {AdresseService} from "@share/component/adresse/adresse.service";
import {ConfirmService} from "@share/component/confirmation/confirm.service";
import {FloatingButtonAction,TypeAction} from "@share/component/floating-button/floating-button";
import {PageHeaderItem} from "@share/component/page-header/page-header";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import {filter,finalize,first} from "rxjs/operators";
import {AnalytiquePopupAjoutFiltresComponent} from "../../analytique-popup-ajout-filtres/analytique-popup-ajout-filtres.component";

/**
 * Enum des différents onglets disponibles sur la page axe5
 */
export enum OngletsAxe5 {
	REFERENTIEL1 = "Référentiel 1",
}

/**
 * Enum des types de filtres possibles (Mot-clef, Service, Centre de coût, Type entité)
 */
export enum FiltreTypesAxe5 {
	MC = "MC",
	SE = "SE",
	TE = "TE",
	CC = "CC"
}

/**
 * Composant d'affichage des informations relatives à un référentiel Axe 5
 */
@Component({
	host: {'data-test-id': 'analytique-axe5-infos'},
	selector: 'analytique-axe5-infos',
	templateUrl: './analytique-axe5-infos.component.html'
})
export class AnalytiqueAxe5InfosComponent implements OnInit {
	/** Indique si le formulaire a été ouvert en création d'axe 5 ou en modification */
	isCreation: boolean = false;

	/** Le référentiel Axe 5 à représenter sur la page */
	axe5: Axe5;

	/** Liste des actions possibles */
    listeActions: BehaviorSubject<Array<FloatingButtonAction>> = new BehaviorSubject<Array<FloatingButtonAction>>(null);

	/** Référence vers l'enum pour l'utiliser dans le template */
	Onglet = OngletsAxe5;

	/** Liste des différents onglets disponibles dans le sous-menu axe5 */
	listeTabItems: Array<PageHeaderItem> = [{
		code: OngletsAxe5.REFERENTIEL1,
		libelle: this.translateService.instant('admin.entreprise.analytique.axe5.infos.onglets.referentiel1')
	}];

	/** Onglet courant */
	selectedItem: PageHeaderItem = this.listeTabItems[0];

	/** Liste des filtres associés au référentiel axe 5 */
	listeFiltre: Array<FiltreAxeAnalytiqueDTO> = new Array<FiltreAxeAnalytiqueDTO>();

	/** Types des filtres */
	filtreTypes = FiltreTypesAxe5;

	/** Pour permettre le pending sur le floating button */
	isSaving: boolean;

	/** Identifiant de l'axe 5 à afficher */
	idAxe5: number = 0;

	/** Formulaire de la page */
	@ViewChild('form')
	form: NgForm;

	/**
	 * Constructeur
	 *
	 * @param activatedRoute Route d'accès à cette page
	 * @param confirmService Service de confirmation
	 * @param translateService Service de traduction
	 * @param matDialog Boîte de dialogue
	 * @param toastrService Service pour afficher les messages de succès, d'erreurs, ...
	 * @param analytiqueService Service de gestion des informations sur l'analytique
	 * @param adresseService Service de gestion des adresses
	 * @param router Router de l'application pour naviguer entre les pages
	 */
	constructor(private activatedRoute: ActivatedRoute,
				private confirmService: ConfirmService,
				private translateService: TranslateService,
				private matDialog: MatDialog,
				private toastrService: ToastrService,
				private analytiqueService: AnalytiqueService,
				private adresseService: AdresseService,
				private router: Router) {
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'id de l'axe 5
        this.activatedRoute.params.pipe(first()).subscribe(params => {
            this.onInit(params['idAxe5']);
        });
	}

	/**
	 * Méthode appelée lorsque l'id de l'axe 5 a été récupéré dans l'URL
	 *
	 * @param idAxe5
	 */
	onInit(idAxe5) {
		this.idAxe5 = Number(idAxe5);

		//On est en mode création si l'id indiqué dans l'URL est -1 sinon on est en mode modification
		this.isCreation = idAxe5 === "-1";

        //Définition de la liste des actions possibles
        this.listeActions.next([
            {
                type: TypeAction.PRIMARY,
                icone: 'nio icon-sauvegarde',
                libelle: 'global.actions.enregistrer',
                isDisabled: () => !this.form?.valid,
                doAction: () => this.save()
            },{
                type: TypeAction.SECONDARY,
                icone: 'nio icon-suppression',
                libelle: 'global.actions.supprimer',
                isVisible: () => !this.isCreation,
                doAction: () => this.delete()
            }
        ]);

		//Si on est en mode création, on initialise un nouvel axe 5
		if (this.isCreation) {
			//Initialisation du nouvel axe 5
			this.axe5 = {
				idAxe5: 0,
				reference: "",
				libelle: "",
				code1: "",
				code2: "",
				actif: true
			};
		} else {
			//Récupération de l'axe 5 possédant l'identifiant récupéré
			this.analytiqueService.getAxe5(this.idAxe5).subscribe(data => {
				this.axe5 = data.data.axe5;

				//Récupération de la liste des filtres associés à l'axe 5
				this.analytiqueService.getListeFilteAxe5(this.axe5).subscribe(data => {
					this.listeFiltre = data.data.liste_filtre;
				});
			});
		}
	}

	/**
	 * Méthode permettant l'ajout d'un filtre au référentiel Axe 5
	 */
	addFiltre() {
		//Affichage de la popup d'ajout d'un filtre
		this.matDialog.open(AnalytiquePopupAjoutFiltresComponent,{
			width: '40%',
			data: {
				idAxe: this.idAxe5,
				title: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.addFiltreTitle'),
				options: [{
					libelle: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.cc'),
					valeur: 2,
					avatar: "CC",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.ccListeTitre')
				},{
					libelle: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.mc'),
					valeur: 10,
					avatar: "MC",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.mcListeTitre')
				},{
					libelle: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.se'),
					valeur: 1,
					avatar: "SE",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.seListeTitre')
				},{
					libelle: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.te'),
					valeur: 9,
					avatar: "TE",
					libelleListe: this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.teListeTitre')
				}]
			}
		}).afterClosed().subscribe(data => {
			//Si la fenêtre est fermée après l'ajout d'un filtre, alors on l'ajoute au référentiel
			if (data !== true) {
				//On cherche la présence ou non du filtre à ajouter, dans la liste des filtres déjà associé au référentiel
				if (this.listeFiltre.findIndex(p => p.idFiltre === data.idFiltre) === -1) {
					//S'il n'est pas déjà présent, alors on l'associe au référentiel et on l'enregistre en base
					this.analytiqueService.saveFiltre(data,0).subscribe(res => {
						if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
							//Si le résultat contient une erreur, alors on affiche un message d'erreur d'enregistrement
							this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
						} else {
							//Sinon affichage d'un message de succès
							this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
							//Et ajout du filtre dans la liste des filtres
							this.listeFiltre.push(data);
						}
					})
				} else {
					//Si le filtre est déjà associé au référentiel, alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.axe5.infos.filtre.filtreDejaPresent'));
				}
			}
		});
	}

	/**
	 * Suppression d'un filtre de la liste des filtres associés à l'axe 5
	 *
	 * @param filtre Filtre à supprimer
	 */
	deleteFiltre(filtre) {
		//Confirmation de la suppression du filtre
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Suppression du lien entre le filtre et l'axe 5 en base
				this.analytiqueService.deleteFiltre(filtre,0).subscribe(res => {
					//Le 0 comme typeAxe signifie que l'utilisateur supprime un filtre pour un Axe 5
					if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
						//Si le résultat contient un code d'erreur, on affiche un message d'erreur
						this.toastrService.error(this.translateService.instant('global.errors.suppression'));
					} else {
						//Sinon affichage d'un message de succès
						this.toastrService.success(this.translateService.instant('global.success.suppression'));

						//Et suppression du filtre dans la liste des filtres de l'axe 5
						this.listeFiltre.splice(this.listeFiltre.findIndex(p => p.idFiltre === filtre.idFiltre),1)
					}
				})
			}
		});
	}

	/**
	 * Méthode permettant l'enregistrement en base de l'axe 5
	 */
	save() {
		//Chargement en cours
		this.isSaving = true;

		//Lorsqu'on récupère un axe 5 déjà créé alors ce champ ci-dessous est égal à 0, ce qui dérange lors de l'enregistrement, donc une mise à null est nécessaire
		if (this.axe5.idAxe5 === 0) {
			this.axe5.idAxe5 = null;
		}

		//Enregistrement en base du référentiel modifié
		this.analytiqueService.saveAxe5(this.axe5)
			.pipe(first(),finalize(() => this.isSaving = false))
			.subscribe(result => {
				if (result.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Si aucune erreur n'est renvoyée, alors on affiche un message de succès
					this.toastrService.success(this.translateService.instant('global.success.enregistrement'));
				} else if (result.codeErreur === TypeCodeErreur.ERROR_DOUBLON) {
					//Si une erreur doublon est renvoyée, alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('admin.entreprise.analytique.axe5.infos.erreurDoublon'));
				} else {
					//Si une erreur est renvoyée, alors on affiche un message d'erreur
					this.toastrService.error(this.translateService.instant('global.errors.enregistrement'));
				}
			});
	}

	/**
	 * Méthode permettant la suppression d'un référentiel Axe 5 en base
	 */
	delete() {
		//Confirmation de la suppression du référentiel
		this.confirmService.showConfirm(this.translateService.instant('global.suppression.confirmation')).pipe(filter(isConfirmed => isConfirmed)).subscribe({
			next: () => {
				//Chargement en cours
				this.isSaving = true;

				//Suppression du référentiel en base
				this.analytiqueService.deleteAxe5(this.axe5.idAxe5)
					.pipe(first(),finalize(() => this.isSaving = false))
					.subscribe(res => {
						if (res.codeErreur !== TypeCodeErreur.NO_ERROR) {
							//Si le résultat contient une erreur, alors on affiche un message d'erreur
							this.toastrService.error(this.translateService.instant('global.errors.suppression'));
						} else {
							//Sinon, on affiche un message de succès
							this.toastrService.success(this.translateService.instant('global.success.suppression'));

							//Et on redirige vers la liste des référentiels Axe 5
							this.onGoBack();
						}
					});
			}
		});
	}

	/**
	 * Changement d'onglet
	 *
	 * @param selectedItem Onglet sélectionné
	 */
	onSelectedItemChange(selectedItem: PageHeaderItem): void {
		this.selectedItem = selectedItem;
	}

	/**
	 * Retour à la page précédente
	 */
	onGoBack(): void {
		this.router.navigateByUrl('Admin/Entreprise/Analytique/Axe5');
	}
}
