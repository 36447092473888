<h1 mat-dialog-title>
    <span [translate]="'admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.title'" [translateParams]="{ numero: champ.numero }"></span>
    <span [mat-dialog-close]><i class="zmdi zmdi-close"></i></span>
</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="intitule"
                    name="intitule"
                    customType="input"
                    [(ngModel)]="champ.name"
                    libelle="admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.intitule"
                    maxlength="10"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="tailleMini"
                    name="tailleMini"
                    customType="number"
                    [(ngModel)]="champ.longueurMini"
                    libelle="admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.tailleMini"
                    [required]="true">
            </custom-input>
        </div>
        <div class="row">
            <custom-input
                    ngDefaultControl
                    id="masque"
                    name="masque"
                    customType="input"
                    [(ngModel)]="champ.masque"
                    libelle="admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.masque"
                    maxlength="2000"
                    [required]="true">
            </custom-input>
            <custom-input
                    ngDefaultControl
                    id="obligatoire"
                    name="obligatoire"
                    customType="checkbox"
                    [ngModel]="champ.oblig"
                    [(customModel)]="champ.oblig"
                    libelle="admin.entreprise.utilisateurs.parametres.champsComplementaires.popin.obligatoire">
            </custom-input>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button *ngIf="canDelete" mat-stroked-button color="warn" (click)="onDelete()" [disabled]="isPending">
        <span [translate]="'global.actions.supprimer'"></span>
    </button>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="form.invalid || isPending">
        <span [translate]="'global.actions.enregistrer'"></span>
    </button>
</div>