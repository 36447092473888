<div class="lvi-content">
    <div class="avatar">
        <span>{{ avatar() }}</span>
    </div>
    <div class="lvi-body">
        <div class="title">
            <a class="label" (click)="showParamDetails()">{{ data.libelle }}</a>
        </div>
        <ul class="lvi-attrs">
            <li>
                <strong [translate]="'admin.bibliotheque.reporting.infosReporting.parametres.nom'"></strong>
                <span>{{ data.name }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.reporting.infosReporting.parametres.obligatoire.title'"></strong>
                <span>{{ 'global.' + (data.obligatoire ? 'oui' : 'non') | translate }}</span>
            </li>
            <li>
                <strong [translate]="'admin.bibliotheque.reporting.infosReporting.parametres.type.title'"></strong>
                <span>{{ ('admin.bibliotheque.reporting.infosReporting.parametres.type.' + data.type) | translate }}</span>
            </li>
            <li *ngIf="!!data.commentaire" [matTooltip]="data.commentaire">
                <strong [translate]="'admin.bibliotheque.reporting.infosReporting.parametres.commentaire'"></strong>
                <span>{{ (data.commentaire.length>50)? (data.commentaire | slice:0:50)+'...':(data.commentaire) }}</span>
            </li>
        </ul>
    </div>
    <div class="lvi-actions" *ngIf="!extraOptions.isStandard">
        <button mat-icon-button [matMenuTriggerFor]="listeActions">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #listeActions="matMenu">
            <button mat-menu-item (click)="delete()" translate="global.actions.supprimer"></button>
        </mat-menu>
    </div>
</div>
