import {Compagnie} from "@domain/profil/compagnie";

/**
 * Carte voyageur
 */
export class Carte {
    actif?: boolean;
    compagnie?: Compagnie;
    custom?: boolean;
    dateCreated?: Date;
    dateUpdated?: Date;
    id?: number;
    idCarte?: number;
    idCarteTH?: number;
    libelle?: string;
    /** type de carte (Abonnement / Fidelite / Fournisseur) */
    typeCarte?: string;
    /** Type de champ de la carte */
    typeChamp?: string;
    /** Type de la prestation */
    typePrestation?: string;
    code: string;
}

/**
 * Enum pour les différents types de prestation
 */
export enum TypesPrestation {
    AVION = 'AVION',
    TRAIN = 'TRAIN',
    HOTEL = 'HOTEL',
    VOITURE_DE_LOCATION = 'VOITURE_DE_LOCATION',
    VOITURE_SOCIETE = 'VOITURE_SOCIETE',
    VOITURE_PERSONNELLE = 'VOITURE_PERSONNELLE',
    DOCUMENT = 'DOCUMENT',
    AVION_TRAIN = 'AVION_TRAIN',
    AVANCE = 'AVANCE',
    TRANSPORT_1 = 'TRANSPORT_1',
    TRANSPORT_2 = 'TRANSPORT_2',
    TRANSPORT_3 = 'TRANSPORT_3',
    SERVICE_1 = 'SERVICE_1',
    SERVICE_2 = 'SERVICE_2',
    SERVICE_3 = 'SERVICE_3'
}

/**
 * Enum pour les différents types de cartes
 */
export enum TypesCarte {
    FIDELITE = 'FIDELITE',
    ABONNEMENT = 'ABONNEMENT',
    SUPPLIER = 'SUPPLIER'
}

/**
 * Enum pour les différents types de champs
 */
export enum TypesChamp {
    NUM = 'NUM',
    NUM_DU_AU = 'NUM_DU_AU',
    NUM_DU_AU_DE_A = 'NUM_DU_AU_DE_A'
}