import {AutocompleteOptions} from "../autocomplete";
import {TranslateService} from "@ngx-translate/core";
import {Filter} from '../../../../domain/common/list-view';
import {ParticipantLineItemComponent} from "./participant-line-item.component";
import {ParticipantAvecFavoris} from "@domain/participant/participant-avec-favoris";

/**
 * Options de l'autocomplete des participants
 */
export default class ParticipantOptions implements AutocompleteOptions {

    /**
     * Ce que l'on affiche dans l'autocomplete
     *
     * @param item l'utilisateur
     * @return Chaîne formatée à afficher dans le dropdown de résultats
     */
    displayItem(item: ParticipantAvecFavoris): string {
        let result: string;

        if (item) {
            //TODO LCO : gestion des participants sans nom / prénom ? (sinon affiche undefined - undefined)
            result = item.nom + " " + item.prenom;

            if (item.matricule) {
                result += " (" + item.matricule + ")";
            }
        }
        return result;
    }

    /**
     * Composant d'affichage d'une ligne de l'autocomplete
     */
    lineItemComponent() { return ParticipantLineItemComponent; }

    /**
     * Les filtres de l'autocomplete
     *
     * @param translateService service de traduction
     */
    listeFilters(translateService: TranslateService): Array<Filter> {
        return [{
            title: translateService.instant('filter.nom'),
            clef: 'nom',
            isDefault: true
        },{
            title: translateService.instant('filter.prenom'),
            clef: 'prenom',
            isDefault: true
        },{
            title: translateService.instant('filter.matricule'),
            clef: 'matricule',
            isDefault: true
        }];
    }

    /**
     * Endpoint back à appeler pour la recherche d'un participant
     *
     * @param filter critères de recherche
     * Paramètres du filtre :
     *   - contexte     :  OD / NDF_V (Dépense)
     *   - idObjet      :  Identifiant de l'OD / Dépense
     *   - idNdf        : Identifiant de la NDF (si contexte Dépense)
     *   - idPrestation : Identifiant de la prestation (si contexte Dépense)
     */
    url(filter: { contexte: 'OD' | 'NDF_V',idObjet: number,idNDF?: number,idPrestation?:number }): string {
        if (filter.contexte == 'NDF_V') {
            return `/controller/Participant/filtreParticipantsAvecFavori/${filter.contexte}/${filter.idNDF}/${filter.idObjet}/${filter.idPrestation}`;
        } else {
            return `/controller/Participant/filtreParticipantsAvecFavori/${filter.contexte}/${filter.idObjet}`;
        }
    }
}
